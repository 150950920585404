/** 
 @copyright : ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
 @author     : Shiv Charan Panjeta 

 All Rights Reserved.
 Proprietary and confidential :  All information contained herein is, and remains
 the property of ToXSL Technologies Pvt. Ltd. and its partners.
 Unauthorized copying of this file, via any medium is strictly prohibited.
*/

import { useEffect } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';

function MessageNotification(props) {
  const { messageNotificationList, isLoading } = props;

  useEffect(() => {}, []);

  return (
    <>
      {messageNotificationList.length > 0 ? (
        messageNotificationList.map((data, key) => (
          <Dropdown.Item key={key} href={data?.userLink}>
            <div className="notifi-dis-card d-flex align-items-center">
              <span className="notifi-icn">
                {data?.user_image != '' ? (
                  <img src={data?.user_image} alt={data?.username} width="50" />
                ) : (
                  <img
                    src={require('../../../assets/images/default-male-avatar.png')}
                    alt={data?.username}
                    width="50"
                  />
                )}
              </span>
              <div className="notifi-dis">
                <h4>
                  {data?.userNameShort} ({data?.newMessagesCount})
                </h4>
                <p>{data?.message_text} </p>
                <span className="time-slot">{data?.time}</span>
              </div>
            </div>
          </Dropdown.Item>
        ))
      ) : (
        <>
          {isLoading ? (
            <div className="notifi-dis-card d-flex align-items-center">
              <i className="fa fa-spinner"></i>
            </div>
          ) : (
            'You do not have any new messages'
          )}
        </>
      )}
    </>
  );
}

export default MessageNotification;
