/**
 *  @copyright : ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
 * @author     : Shiv Charan Panjeta
 * All Rights Reserved.
 * Proprietary and confidential :  All information contained herein is, and remains
 * the property of ToXSL Technologies Pvt. Ltd. and its partners.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */

import moment from 'moment/moment';
import { useEffect, useState } from 'react';
import { Button, Container } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { useNavigate, useParams } from 'react-router-dom';
import AsyncSelect from 'react-select/async';
import Loader from '../../components/commoncomponents/loader/loader-large';
import { ZERO } from '../../globals/constants';
import { emailRegex, restrictAlpha } from '../../globals/helper';
import useDocumentTitle from '../../globals/useDocumentTitle';
import * as adminServices from '../../services/AuthServices';
import showNotification from '../../services/NotificationService';
export default function AddInstitute(props) {
  useDocumentTitle('Add Institutes | AlmaBay');
  const { state } = props;
  const navigate = useNavigate();
  const { id } = useParams();

  const [instituteDetail, setInstituteDetail] = useState({
    name: '',
    estyear: '',
    shortcode: '',
    description: '',
    admin_email: '',
    address: '',
    city: '',
    phone: '',
    fax: '',
    website: '',
    institute_type: '',
    campus_type: '',
    ownership: '',
    ownership_group_id: '',
    affiliate_id: '',
    logo_img: '',
    cover_image: ''
  });

  const [locationState, setLocationState] = useState('');
  const [clicked, setClicked] = useState(false);
  const [loading, setLoading] = useState(false);
  const [affiliateId, setAffiliateId] = useState('');
  const [inputMessage, setInputMessage] = useState('Please enter atleast 1 character');
  const [savedImage, setSavedImage] = useState(false);
  const [coverImage, setCoverImage] = useState(false);
  const [ownerId, setOwnerId] = useState('');
  const [loadInpMsg, setloadInpMsg] = useState(false);
  const validUrlRegex = new RegExp(
    /^((https?|ftp|smtp):\/\/)?(www.)?[a-z0-9]+\.[a-z]+(\/[a-zA-Z0-9#]+\/?)*$/
  );

  const validateFax = new RegExp(/^\+?[0-9]{6,}$/);
  useEffect(() => {
    if (id) {
      getInstituteDetail(id);
    }
    if (state === 'initial') {
      resetState();
    }
  }, [state]);

  const resetState = () => {
    setInstituteDetail({
      name: '',
      estyear: '',
      shortcode: '',
      description: '',
      admin_email: '',
      address: '',
      city: '',
      phone: '',
      fax: '',
      website: '',
      institute_type: '',
      campus_type: '',
      ownership: '',
      ownership_group_id: '',
      affiliate_id: '',
      logo_img: '',
      cover_image: ''
    });
  };

  const getInstituteDetail = (id) => {
    setLoading(true);
    adminServices.singleInstituteDetail(id).then((res) => {
      setLoading(false);
      if (res?.status === 200) {
        const data = res?.data?.data?.institute_data;
        const {
          name,
          estyear,
          shortcode,
          description,
          admin_email,
          address,
          city,
          phone,
          fax,
          website,
          institute_type,
          campus_type,
          ownership,
          ownership_group_id,
          affiliate_id,
          logo,
          cover_image
        } = data;

        setInstituteDetail({
          name,
          estyear,
          institute_id: id,
          shortcode,
          description,
          admin_email,
          address,
          city: data?.city,
          phone,
          fax,
          website,
          institute_type,
          campus_type,
          ownership,
          ownership_group_id,
          affiliate_id,
          logo_img: logo,
          cover_image
        });
        getAffiliatedDetailAccordintToIns(affiliate_id);
        getOwnerName(ownership_group_id);
        setSavedImage(data?.logo || '');
        setCoverImage(data?.cover_image || '');
        setLocationState({ id: data?.city, label: data?.location, value: data?.location });
      }
    });
  };

  const getAffiliatedDetailAccordintToIns = (id) => {
    adminServices.getAffiliatedIns(id).then((res) => {
      if (res?.status === 200) {
        let tempArr = [];
        const data = res?.data?.data;
        data?.map((data) => {
          tempArr.push({
            id: data?.institute_id,
            label: data?.name,
            value: data?.name
          });
        });
        setAffiliateId(tempArr);
      }
    });
  };

  const getOwnerName = (id) => {
    adminServices.getAffiliatedIns(id).then((res) => {
      if (res?.status === 200) {
        let tempArr = [];
        const data = res?.data?.data;
        data?.map((data) => {
          tempArr.push({
            id: data?.institute_id,
            label: data?.name,
            value: data?.name
          });
        });
        setOwnerId(tempArr);
      }
    });
  };

  const validate = (value) => {
    let errors = {};

    if (!value.name) {
      errors.name = 'This field is required';
    }
    if (!value.estyear) {
      errors.estyear = 'This field is required';
    }
    if (value.estyear > moment().format('YYYY')) {
      errors.estyear = 'Invalid year';
    }
    if (!value.shortcode) {
      errors.shortcode = 'This field is required';
    }
    if (!value.description) {
      errors.description = 'This field is required';
    }
    if (!value.admin_email) {
      errors.admin_email = 'This field is required';
    } else if (!emailRegex.test(value.admin_email)) {
      errors.admin_email = 'please enter valid email';
    }
    if (!value.address) {
      errors.address = 'This field is required';
    }
    if (!value.city) {
      errors.city = 'This field is required';
    }
    if (value.fax && !validateFax.test(value.fax)) {
      errors.fax = 'please enter valid  fax';
    }
    if (value.website && !validUrlRegex.test(value.website)) {
      errors.website = 'please enter valid  Website URL ';
    }
    if (!value.institute_type) {
      errors.institute_type = 'This field is required';
    }
    if (value.institute_type == 'institute' && !value.campus_type) {
      errors.campus_type = 'This field is required';
    }
    if (!value.affiliate_id) {
      errors.affiliate_id = 'This field is required';
    }
    if (!value.ownership) {
      errors.ownership = 'This field is required';
    }
    if (!value.logo_img) {
      errors.logo_img = 'This field is required';
    }
    return errors;
  };

  const onChangeHandler = (e) => {
    const { name, value, type } = e.target;
    if (type === 'file') {
      let file = e?.target?.files[0];
      if (!file?.type?.includes('image')) {
        showNotification('danger', 'Invalid file');
      } else {
        setInstituteDetail({ ...instituteDetail, [name]: file });
      }
    } else {
      setInstituteDetail({ ...instituteDetail, [name]: value });
    }
  };

  const submitHandler = (e) => {
    e.preventDefault();
    setClicked(true);
    if (!Object.keys(validate(instituteDetail)).length) {
      let formData = new FormData();
      for (let i in instituteDetail) {
        formData.append(`${i}`, instituteDetail[i]);
      }
      adminServices.createInstitute(formData).then((res) => {
        if (res?.status === 200) {
          showNotification('success', res?.data?.message);
          navigate('/admin/institute/showAll');
        }
      });
    }
  };

  const updateHandler = (e) => {
    e.preventDefault();
    setClicked(true);
    if (!Object.keys(validate(instituteDetail)).length) {
      let formData = new FormData();
      for (let i in instituteDetail) {
        formData.append(`${i}`, instituteDetail[i]);
      }
      adminServices.updateInstitute(formData).then((res) => {
        if (res?.status === 200) {
          showNotification('success', res?.data?.message);
          navigate('/admin/institute/showAll');
        }
      });
    }
  };

  const loadAffiliatedOpt = (inputValue) => {
    return adminServices.getAffiliatedTo(inputValue).then((res) => {
      if (res?.status === 200) {
        let tempArr = [];
        if (res?.data?.data) {
          const data = res?.data?.data;
          data?.map((data) => {
            tempArr.push({
              id: data?.institute_id,
              label: data?.name,
              value: data?.name
            });
          });
        }
        return tempArr;
      }
    });
  };

  const loadCityOpt = (inputValue) => {
    if (inputValue.length >= 2) {
      setloadInpMsg(true);
      return adminServices.getLocationDropDown(inputValue).then((res) => {
        setloadInpMsg(false);
        setInputMessage('Searching...');
        if (res?.status === 200) {
          let tempArr = [];
          if (res?.data?.data) {
            const data = res?.data?.data;
            data?.map((data) => {
              tempArr.push({
                id: data?.id,
                label: data?.location,
                value: data?.location
              });
            });
          }

          if (res?.data?.data?.length == ZERO) {
            setInputMessage('no matches found ..');
          }
          return tempArr;
        }
      });
    }
  };
  const handleOwnerGroupChange = (e) => {
    setInstituteDetail({
      ...instituteDetail,
      ['ownership_group_id']: e?.id
    });
    setOwnerId(e);
  };
  //institute location search validation
  const inputMessageHandler = (e) => {
    if (e?.length !== 1 && e?.length < 1) {
      setInputMessage('Please enter 2 or more character');
    }
  };
  return (
    <>
      <div className="dash-main-content">
        <Container fluid>
          <div className="dash-page-content">
            <div className="dash-page-title">
              <Row>
                <Col xs={12} sm={12} md={12}>
                  <div className="dash-title-bar d-flex align-items-center justify-content-between">
                    <div className="title-lft d-flex align-items-center">
                      <div className="dash-icn">
                        <i className="fas fa-university"></i>
                      </div>
                      <div className="dash-title-dis">
                        <h5>
                          Institute <span>Details</span>
                        </h5>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="dash-content-card">
              <div className="cmmn-form">
                <Form>
                  <Row>
                    <Col md={4}>
                      <Form.Group className="mb-3" controlId="formBasicName">
                        <Form.Label>
                          Institute Name <sup className="text-danger">*</sup>
                        </Form.Label>
                        <Form.Control
                          onChange={onChangeHandler}
                          value={instituteDetail?.name}
                          name="name"
                          type="text"
                          placeholder="Enter the name of Institute/School.Eg: Carmel Convent"
                        />
                        <div
                          className={`${
                            clicked && validate(instituteDetail).name ? '' : 'd-none'
                          } danger-frm `}>
                          {clicked && validate(instituteDetail).name}
                        </div>
                      </Form.Group>
                    </Col>
                    <Col md={4}>
                      <Form.Group className="mb-3" controlId="formBasicName">
                        <Form.Label>
                          Establishment Year <sup className="text-danger">*</sup>
                        </Form.Label>
                        <Form.Control
                          onChange={onChangeHandler}
                          value={instituteDetail?.estyear}
                          name="estyear"
                          type="text"
                          maxLength={4}
                          onKeyPress={restrictAlpha}
                          placeholder="Enter year of estiblishment. e.g 1985"
                        />
                        <div
                          className={`${
                            clicked && validate(instituteDetail).estyear ? '' : 'd-none'
                          } danger-frm `}>
                          {clicked && validate(instituteDetail).estyear}
                        </div>
                      </Form.Group>
                    </Col>
                    <Col md={4}>
                      <Form.Group className="mb-3" controlId="formBasicName">
                        <Form.Label>
                          Institute ShortCode <sup className="text-danger">*</sup>
                        </Form.Label>
                        <Form.Control
                          onChange={onChangeHandler}
                          value={instituteDetail?.shortcode}
                          name="shortcode"
                          type="text"
                          maxLength={6}
                          // onKeyPress={(e) => {
                          //   restrictAlpha(e);
                          // }}
                          placeholder="Enter the shotcode for Institute/School.Eg: CESA"
                        />
                        <div
                          className={`${
                            clicked && validate(instituteDetail).shortcode ? '' : 'd-none'
                          } danger-frm `}>
                          {clicked && validate(instituteDetail).shortcode}
                        </div>
                      </Form.Group>
                    </Col>

                    <Col md={12}>
                      <Form.Group className="mb-3" controlId="formBasicDesi" name="description">
                        <Form.Label>
                          Description <sup className="text-danger">*</sup>
                        </Form.Label>
                        <Form.Control
                          as="textarea"
                          placeholder="Enter the description of Institute."
                          rows={3}
                          name="description"
                          onChange={onChangeHandler}
                          value={instituteDetail?.description}
                        />
                        <div
                          className={`${
                            clicked && validate(instituteDetail).description ? '' : 'd-none'
                          } danger-frm `}>
                          {clicked && validate(instituteDetail).description}
                        </div>
                      </Form.Group>
                    </Col>

                    <Col md={4}>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>
                          Admin email <sup className="text-danger">*</sup>
                        </Form.Label>
                        <Form.Control
                          onChange={onChangeHandler}
                          value={instituteDetail?.admin_email}
                          name="admin_email"
                          type="email"
                          placeholder="Enter the email id of institute admin.Eg: admin@almabay.com"
                        />
                        <div
                          className={`${
                            clicked && validate(instituteDetail).admin_email ? '' : 'd-none'
                          } danger-frm `}>
                          {clicked && validate(instituteDetail).admin_email}
                        </div>
                      </Form.Group>
                    </Col>
                    <Col md={4}>
                      <Form.Group className="mb-3" controlId="formBasicName">
                        <Form.Label>
                          Address <sup className="text-danger">*</sup>
                        </Form.Label>
                        <Form.Control
                          value={instituteDetail?.address}
                          onChange={onChangeHandler}
                          name="address"
                          type="text"
                          placeholder="Enter the address of institute."
                        />
                      </Form.Group>
                      <div
                        className={`${
                          clicked && validate(instituteDetail).address ? '' : 'd-none'
                        } danger-frm `}>
                        {clicked && validate(instituteDetail).address}
                      </div>
                    </Col>

                    <Col md={4}>
                      <Form.Group className="mb-3" controlId="formBasicName">
                        <Form.Label>
                          Location<sup className="text-danger">*</sup>
                        </Form.Label>
                        <AsyncSelect
                          isClearable={false}
                          getOptionLabel={(e) => e.label}
                          getOptionValue={(e) => e.value}
                          loadOptions={loadCityOpt}
                          isDisabled={false}
                          isLoading={false}
                          isRtl={false}
                          isSearchable={true}
                          className="basic-single mt-1"
                          name="city"
                          value={locationState}
                          onChange={(e) => {
                            setInstituteDetail({ ...instituteDetail, ['city']: e?.id });
                            setLocationState(e);
                          }}
                          onInputChange={(e) => {
                            inputMessageHandler(e);
                          }}
                          noOptionsMessage={() => inputMessage}
                          components={{
                            DropdownIndicator: () => null,
                            IndicatorSeparator: () => null,
                            LoadingIndicator: () => null
                          }}
                        />
                        {loadInpMsg && (
                          <div className="spinner-filter">
                            <i className="fa fa-spinner fa-pulse me-2 "></i>
                          </div>
                        )}
                        <div
                          className={`${
                            clicked && validate(instituteDetail).city ? '' : 'd-none'
                          } danger-frm `}>
                          {clicked && validate(instituteDetail).city}
                        </div>
                      </Form.Group>
                    </Col>
                    <Col md={4}>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Phone</Form.Label>
                        <Form.Control
                          onChange={onChangeHandler}
                          value={instituteDetail?.phone}
                          name="phone"
                          type="email"
                          maxLength={10}
                          onKeyPress={(e) => {
                            restrictAlpha(e);
                          }}
                          placeholder="Enter the landline number."
                        />
                      </Form.Group>
                    </Col>
                    <Col md={4}>
                      <Form.Group className="mb-3" controlId="formBasicName">
                        <Form.Label>Fax</Form.Label>
                        <Form.Control
                          onChange={onChangeHandler}
                          name="fax"
                          value={instituteDetail?.fax}
                          type="text"
                          onKeyPress={restrictAlpha}
                          maxLength={25}
                          placeholder="Enter the Fax number."
                        />
                      </Form.Group>
                      <div
                        className={`${
                          clicked && validate(instituteDetail).fax ? '' : 'd-none'
                        } danger-frm `}>
                        {clicked && validate(instituteDetail).fax}
                      </div>
                    </Col>
                    <Col md={4}>
                      <Form.Group className="mb-3" controlId="formBasicName">
                        <Form.Label>Website</Form.Label>
                        <Form.Control
                          onChange={onChangeHandler}
                          name="website"
                          value={instituteDetail?.website}
                          type="text"
                          placeholder="Enter the institute website.Eg: http://www.almabay.com"
                        />
                      </Form.Group>
                      <div
                        className={`${
                          clicked && validate(instituteDetail).website ? '' : 'd-none'
                        } danger-frm `}>
                        {clicked && validate(instituteDetail).website}
                      </div>
                    </Col>

                    <Col md={4}>
                      <Form.Group className="mb-3" controlId="formBasicState">
                        <Form.Label>
                          Institute Type<sup className="text-danger">*</sup>
                        </Form.Label>
                        <Form.Select
                          aria-label="Default select example"
                          value={instituteDetail?.institute_type}
                          onChange={onChangeHandler}
                          name="institute_type">
                          <option value="">Select the Institute Type</option>
                          <option value="institute">Institute</option>
                          <option value="university">University</option>
                          <option value="education_board">Education Board</option>
                        </Form.Select>
                        <div
                          className={`${
                            clicked && validate(instituteDetail).institute_type ? '' : 'd-none'
                          } danger-frm `}>
                          {clicked && validate(instituteDetail).institute_type}
                        </div>
                      </Form.Group>
                    </Col>
                    {instituteDetail?.institute_type === 'institute' ? (
                      <Col md={4}>
                        <Form.Group className="mb-3" controlId="formBasicState">
                          <Form.Label>
                            Campus Type<sup className="text-danger">*</sup>
                          </Form.Label>
                          <Form.Select
                            aria-label="Default select example"
                            value={instituteDetail?.campus_type}
                            onChange={onChangeHandler}
                            name="campus_type">
                            <option value="">Select the Campus Type</option>
                            <option value="constituent_campus">Constituent campus</option>
                            <option value="affiliated_institution">Affiliated institute</option>
                          </Form.Select>
                          <div
                            className={`${
                              clicked && validate(instituteDetail).campus_type ? '' : 'd-none'
                            } danger-frm `}>
                            {clicked && validate(instituteDetail).campus_type}
                          </div>
                        </Form.Group>
                      </Col>
                    ) : (
                      ''
                    )}
                    <Col md={4}>
                      <Form.Group
                        className="mb-3"
                        onChange={onChangeHandler}
                        controlId="formBasicState"
                        name="affiliate_id">
                        <Form.Label>
                          Affiliated to<sup className="text-danger">*</sup>
                        </Form.Label>
                        <AsyncSelect
                          isClearable={true}
                          isRtl={false}
                          noOptionsMessage={() => inputMessage}
                          placeholder="Select Affiliated Institute"
                          getOptionLabel={(e) => e.label}
                          getOptionValue={(e) => e.value}
                          loadOptions={loadAffiliatedOpt}
                          className="basic-single mt-1"
                          name="affiliate_id"
                          // id="affiliate_id"
                          value={affiliateId}
                          onChange={(e) => {
                            setAffiliateId(e);
                            setInstituteDetail({ ...instituteDetail, ['affiliate_id']: e?.id });
                          }}
                        />
                        <div
                          className={`${
                            clicked && validate(instituteDetail).affiliate_id ? '' : 'd-none'
                          } danger-frm `}>
                          {clicked && validate(instituteDetail).affiliate_id}
                        </div>
                      </Form.Group>
                    </Col>

                    <Col md={4}>
                      <Form.Group className="mb-3" controlId="formBasicState">
                        <Form.Label>
                          Owner Ship<sup className="text-danger">*</sup>
                        </Form.Label>
                        <Form.Select
                          aria-label="Default select example"
                          onChange={onChangeHandler}
                          value={instituteDetail?.ownership}
                          name="ownership">
                          <option>Select the Owner Ship</option>
                          <option value="goverment">Goverment</option>
                          <option value="private">Private</option>
                        </Form.Select>
                        <div
                          className={`${
                            clicked && validate(instituteDetail).ownership ? '' : 'd-none'
                          } danger-frm `}>
                          {clicked && validate(instituteDetail).ownership}
                        </div>
                      </Form.Group>
                    </Col>
                    <Col md={4}>
                      <Form.Group
                        className="mb-3"
                        controlId="formBasicState"
                        onChange={onChangeHandler}
                        name="ownership_group_id">
                        <Form.Label>Owner Group</Form.Label>
                        <AsyncSelect
                          isClearable
                          isRtl={false}
                          getOptionLabel={(e) => e.label}
                          getOptionValue={(e) => e.value}
                          loadOptions={loadAffiliatedOpt}
                          value={ownerId}
                          className="basic-single mt-1"
                          name="ownership_group_id"
                          onChange={handleOwnerGroupChange}
                          // onChange={(e) => {
                          //   setInstituteDetail({
                          //     ...instituteDetail,
                          //     ['ownership_group_id']: e.id
                          //   });
                          // }}
                        />
                      </Form.Group>
                    </Col>
                    <Col md={4}>
                      <Form.Group className="mb-3" controlId="formBasicState">
                        <Form.Label>
                          Logo Image<sup className="text-danger">*</sup>
                        </Form.Label>
                        <Form.Control
                          onChange={onChangeHandler}
                          name="logo_img"
                          accept="image/*"
                          type="file"
                        />
                        <div
                          className={`${
                            clicked && validate(instituteDetail).logo_img ? '' : 'd-none'
                          } danger-frm `}>
                          {clicked && validate(instituteDetail).logo_img}
                        </div>
                      </Form.Group>
                      {savedImage && id && (
                        <div className="d-flex flex-wrap align-item-center">
                          <div className="border me-2">
                            <img
                              width="100px"
                              height="100px"
                              src={
                                instituteDetail?.logo_img?.type?.includes('image')
                                  ? URL.createObjectURL(instituteDetail?.logo_img)
                                  : process.env.REACT_APP_API_DOMAIN +
                                    'common/uploads/institute_logo/' +
                                    savedImage
                              }
                              alt="img1"
                            />
                          </div>
                        </div>
                      )}
                    </Col>
                    <Col md={4}>
                      <Form.Group className="mb-3" controlId="formBasicState">
                        <Form.Label>Cover Image</Form.Label>
                        <Form.Control
                          onChange={onChangeHandler}
                          name="cover_image"
                          accept="image/*"
                          type="file"
                        />
                      </Form.Group>
                      {coverImage && id && (
                        <div className="d-flex flex-wrap align-item-center">
                          <div className="border me-2">
                            <img
                              width="100px"
                              height="100px"
                              src={
                                instituteDetail?.cover_image?.type?.includes('image')
                                  ? URL.createObjectURL(instituteDetail?.cover_image)
                                  : process.env.REACT_APP_API_DOMAIN +
                                    'common/uploads/institute_logo/' +
                                    coverImage
                              }
                              alt="img1"
                            />
                          </div>
                        </div>
                      )}
                    </Col>
                  </Row>
                  <div className="form-btns d-flex align-items-center mt-3">
                    {id ? (
                      <button
                        type="button"
                        className="green_button_admin"
                        onClick={(e) => {
                          updateHandler(e);
                        }}>
                        Submit
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="green_button_admin"
                        onClick={(e) => {
                          submitHandler(e);
                        }}>
                        Submit
                      </button>
                    )}
                    <strong>OR</strong>
                    <Button
                      className="main-btn-red"
                      onClick={() => {
                        navigate(-1);
                      }}>
                      Cancel
                    </Button>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </Container>
      </div>
      {loading ? <Loader /> : ''}
    </>
  );
}
