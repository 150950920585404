import { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { getClubPageDetail } from '../../../services/InstituteServices';
import Loader from '../../commoncomponents/loader/loader-large';

function AboutClub() {
    const [list, setList] = useState('');
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        getPageDetailIns();
    }, []);

    const getPageDetailIns = () => {
        setLoading(true)
        getClubPageDetail().then((res) => {
            setLoading(false)
            if (res?.status == 200) {
                let data = res?.data?.details
                setList(data);
            }
        });
    };

    return (
        <>
            {loading ? <Loader /> : <>
                <div className="wrappermain_outer bggreylight">
                    <section
                        className="breadcrum-banner"
                        style={{
                            backgroundImage: `url(${list?.banner_file})`
                        }}
                    >
                        {/* /for static image using a link  */}
                        <Container>
                            <Row>
                                <Col sm={12}>
                                    <h1 className="Text-white">{list?.banner_title || 'About'}</h1>
                                </Col>
                            </Row>
                        </Container>
                    </section>
                    {list ? (
                        <section className="abt-institute py-5">
                            <Container>
                                <div className="abt-institute-inr mb-3">
                                    {list?.institute_name && <h4>{list?.institute_name}</h4>}
                                    {list?.description && (
                                        <p dangerouslySetInnerHTML={{ __html: list?.description }}></p>
                                    )}
                                </div>
                                {list?.multiple_image && list?.multiple_image?.length ? (
                                    <div className="abt-gallery py-5">
                                        <Row>
                                            {list?.multiple_image?.map((data, index) => (
                                                <Col key={index} lg={3} md={6}>
                                                    <div className="abt-gallery-card">
                                                        <img src={data?.multiple_image} alt="gallery" />
                                                    </div>
                                                </Col>
                                            ))}
                                        </Row>
                                    </div>
                                ) : (
                                    <></>
                                )}

                                {list?.desc_one_title && (
                                    <div className="our-vision-sec py-5">
                                        <Row className="align-items-center">
                                            <Col lg={6} md={12}>
                                                {list?.desc_one_title && (
                                                    <h3 className="wavey-heading-dark main-hd mb-5">{list?.desc_one_title}:</h3>
                                                )}
                                                <div className="our-vision-lft">
                                                    {list?.desc_one && (
                                                        <span dangerouslySetInnerHTML={{ __html: list?.desc_one }} />
                                                    )}
                                                </div>
                                            </Col>
                                            <Col lg={6} md={12}>
                                                <div className="our-vision-img">
                                                    <img
                                                        src={
                                                            list?.sport_image
                                                                ? process.env.REACT_APP_API_URL + list?.sport_image
                                                                : require('../../../assets/images/institute-img/vision.png')
                                                        }
                                                        alt="vision"
                                                    />
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                )}

                                {list?.desc_two_title && (
                                    <div className="sport-sec py-5">
                                        <h3 className="wavey-heading-dark main-hd mb-5">{list?.desc_two_title}:</h3>
                                        <Row>
                                            <Col lg={5} md={12}></Col>
                                            <Col lg={7} md={12}>
                                                <div className="sport-sec-discr">
                                                    {list?.desc_two && (
                                                        <p dangerouslySetInnerHTML={{ __html: list?.desc_two }}></p>
                                                    )}
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                )}
                            </Container>
                        </section>
                    ) : (
                        <div className={loading ? "d-none" : "text-center nodata bg-white"}>
                            <img src={require('../../../assets/images/nodata.png')} alt="no_data" />
                            <p>Data Not Found</p>
                        </div>
                    )}
                </div>
                {/* <div className="lgn-link py-4">
                    <Container>
                        <div className="logn-lnk-inr">
                            <p>
                                <Link to="/institute-register">Register</Link> /{' '}
                                <Link to="/institute-login">Login</Link> to browse members by Year, Name, Company,
                                Profession or Location
                            </p>
                        </div>
                    </Container>
                </div> */}
            </>}
        </>
    );
}

export default AboutClub;
