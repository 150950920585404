/** @copyright : ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
 @author     : Shiv Charan Panjeta 

 All Rights Reserved.
 Proprietary and confidential :  All information contained herein is, and remains
 the property of ToXSL Technologies Pvt. Ltd. and its partners.
 Unauthorized copying of this file, via any medium is strictly prohibited.
*/
import { useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
import Polls from 'react-polls';
import { ZERO } from '../../globals/constants';
import { hitPollApi } from '../../globals/helper';
import showNotification from '../../services/NotificationService';
import { addPollResponse, showallQuestions } from '../../services/UserServices';
import Loader from './loader/loader-large.jsx';

const pollAnswers1 = [
  { option: 'Yes', votes: 24 },
  { option: 'No', votes: 3 },
  { option: "I don't know", votes: 1 }
];
const pollStyles1 = {
  questionSeparator: true,
  questionSeparatorWidth: 'question',
  questionBold: true,
  align: 'center'
};

const pollAnswers2 = [
  { option: 'React', votes: 5 },
  { option: 'Vue', votes: 2 },
  { option: 'Angular', votes: 1 }
];

/*eslint-disable */

const ReactPollComponnet = () => {
  const [pollAns1, setPollAns1] = useState([...pollAnswers1]);
  const [pollAns2, setPollAns2] = useState([...pollAnswers2]);
  const [allPollQuestions, setAllPollQuestions] = useState([]);
  const [loading, setLoading] = useState();
  const [totalCount, setTotalCount] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  useEffect(() => {
    getQuestionsListData(10, currentPage);
  }, []);

  const getQuestionsListData = (limit = 10, page = '1') => {
    setLoading(true);
    showallQuestions(limit, page).then((res) => {
      setLoading(false);
      if (res?.status === 200) {
        setLoading(false);
        setAllPollQuestions(res?.data?.data ?? []);
        setTotalCount(res?.data?.total_questions);
      } else {
        setLoading(false);
      }
    });
  };

  const addResponseData = (id, data) => {
    setLoading(true);
    let body = {
      ques_id: id,
      ans: data[0]?.id,
      path: '/user/response-poll'
    };
    addPollResponse(body).then((res) => {
      setLoading(false);
      if (res?.status === 200) {
        setLoading(false);
        showNotification('success', res?.data?.message);
        getQuestionsListData(10, currentPage);
        hitPollApi();
      } else {
        setLoading(false);
        showNotification('err', res?.data?.message);
      }
    });
  };

  const handleVote = (voteAnswer, pollAnswers, pollNumber) => {
    console.log(voteAnswer);
    setLoading(true);
    const newPollAnswers = pollAnswers?.map((answer) => {
      setLoading(false);
      if (answer.option === voteAnswer) answer.votes++;

      getQuestionsListData(10, currentPage);

      return answer;
    });

    if (pollNumber === 1) {
      setPollAns1(newPollAnswers);
    } else {
      setPollAns2(newPollAnswers);
    }
  };

  useEffect(() => {
    // autoAddVotes(pollAns1, 1);
    // autoAddVotes(pollAns2, 2);
  }, []);

  const autoAddVotes = (pollAnswers, pollNumber) => {
    setTimeout(() => {
      const choseAnswer = parseInt(Math.random() * 2, 10);
      handleVote(pollAnswers[choseAnswer].option, pollAnswers, pollNumber);
      autoAddVotes(pollAnswers, pollNumber);
      getQuestionsListData(10, currentPage);
    }, Math.random() * 5000);
  };

  //Pagination HandleChange
  const handlePageChange = (a) => {
    getQuestionsListData(10, a?.selected + 1);
    setCurrentPage(a.selected + 1);
  };

  useEffect(() => {
    let elements = document.getElementsByClassName('styles_question__3qxRo');
    if (elements) {
      Array.from(elements).forEach((ele) => {
        // ele.removeChild()
        //  let child = ele.childNodes();

        let html = ele.innerHTML;

        let updatedHtml = html.split('sepsduiok');

        const div = document.createElement('div');
        div.classList.add('flexer');
        let firstChild = document.createElement('p');
        firstChild.innerHTML = updatedHtml[0];
        div.appendChild(firstChild);
        if (updatedHtml.length == 2) {
          let secondChild = document.createElement('p');
          secondChild.innerHTML = updatedHtml[1];
          div.appendChild(secondChild);
        }
        ele.replaceWith(div);
      });
    }
  });

  return (
    <>
      <main className="main">
        {Object.keys(allPollQuestions)?.length != ZERO ? (
          Object.keys(allPollQuestions)?.map((check, index) => {
            return (
              <>
                <div id={`id${index}`}>
                  <Polls
                    question={
                      allPollQuestions[check]?.ques_description
                        ? `${allPollQuestions[check]?.ques_title}` +
                          'sepsduiok' +
                          `${allPollQuestions[check]?.ques_description}`
                        : allPollQuestions[check]?.ques_title
                    }
                    answers={allPollQuestions[check]?.ans_title?.map((ans) => {
                      return {
                        option: ans?.option,
                        votes: ans?.vote,
                        id: ans?.id
                      };
                    })}
                    onVote={(voteAnswer) => {
                      handleVote(
                        voteAnswer,
                        pollAns2,
                        allPollQuestions?.[check]?.ans_id,
                        allPollQuestions?.[check]?.ques_id,
                        allPollQuestions?.[check]?.ans_title?.filter(
                          (data) => data?.option == voteAnswer
                        )
                      );
                      addResponseData(
                        allPollQuestions?.[check]?.ques_id,
                        allPollQuestions?.[check]?.ans_title?.filter(
                          (data) => data?.option == voteAnswer
                        )
                      );
                    }}
                    customStyles={pollStyles1}
                  />
                  <h4 className="totalVoteCount">
                    Total Vote : {allPollQuestions[check]?.vote_count}
                  </h4>
                </div>
              </>
            );
          })
        ) : (
          <p>No Data Found</p>
        )}
      </main>
      {totalCount > 10 && (
        <div className="react-page d-flex">
          <ReactPaginate
            previousLabel={currentPage > 0 ? '<' : ''}
            nextLabel={'>'}
            breakLabel={'...'}
            pageCount={Math.ceil(totalCount / 10)}
            breakClassName={'break-me'}
            pageRangeDisplayed={3}
            onPageChange={handlePageChange}
            activeClassName={'page-link active'}
          />
        </div>
      )}
      {loading ? <Loader /> : ''}
    </>
  );
};
export default ReactPollComponnet;
