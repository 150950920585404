/**
@copyright  :   ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
@author     :   Shiv Charan Panjeta 
 
All Rights Reserved.
Proprietary and confidential :  All information contained here in is, and remains
the property of ToXSL Technologies Pvt. Ltd. and it's partners.
Unauthorized copying of this file, via any medium is strictly prohibited.
*/

import moment from 'moment/moment';
import { useEffect, useState } from 'react';
import { Button, Container, Modal } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Table from 'react-bootstrap/Table';
import ReactPaginate from 'react-paginate';
import Loader from '../../components/commoncomponents/loader/loader-large';
import useDocumentTitle from '../../globals/useDocumentTitle';
import * as adminServices from '../../services/AuthServices';
import NoDataFound from '../../components/commoncomponents/NoDataFound';

export default function Feedback() {
  useDocumentTitle('Manage Feedback | AlmaBay');
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [viewDtaa, setViewData] = useState('');
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [record, setRecord] = useState('');
  const [searchedData, setSearchData] = useState({
    feedId: '',
    feedName: '',
    feedEmail: '',
    feedMobile: '',
    feedSubject: ''
  });

  const [currentPage, setCurrentPage] = useState(0);

  useEffect(() => {
    apiCallget();
  }, []);

  const apiCall = (
    feedId = '',
    feedName = '',
    feedEmail = '',
    feedMobile = '',
    feedSubject = ''
  ) => {
    setLoading(true);
    let body = {
      feedId,
      feedName,
      feedEmail,
      feedMobile,
      feedSubject
    };
    adminServices.getFeedbackListSearch(body).then((res) => {
      if (res?.status === 200) {
        setLoading(false);
        setList(res?.data?.detail?.viewlist);
        setRecord(res?.data?.detail?.totalRecords);
      }
    });
  };

  const onChangeSearch = (e) => {
    const { name, value } = e.target;
    setSearchData({ ...searchedData, [name]: value });
  };

  const searchHandler = (e) => {
    e.preventDefault();
    apiCall(
      searchedData.feedId,
      searchedData.feedName,
      searchedData.feedEmail,
      searchedData.feedMobile,
      searchedData.feedSubject
    );
  };

  const handleShow = (data) => {
    setShow(true);
    setViewData(data);
  };

  const apiCallget = (page = 1) => {
    setLoading(true);
    setCurrentPage(page);
    adminServices.getFeedbackList(page).then((res) => {
      if (res?.status == 200) {
        setLoading(false);
        setList(res?.data?.data);
        setRecord(res?.data?.pager?.total);
      }
    });
  };

  const handlePageClick = (a) => {
    let currentPage = a?.selected + 1;
    apiCallget(currentPage);
  };

  return (
    <>
      <div className="dash-main-content">
        <Container fluid>
          <div className="dash-page-content">
            <div className="dash-page-title">
              <Row>
                <Col xs={12} sm={12} md={12}>
                  <div className="dash-title-bar d-flex align-items-center justify-content-between">
                    <div className="title-lft d-flex align-items-center">
                      <div className="dash-icn">
                        <i className="fas fa-comments"></i>
                      </div>
                      <div className="dash-title-dis">
                        <h5>
                          Feedback <span> Listing</span>
                        </h5>
                        <h5>
                          Total Record(s): <span>{record}</span>
                        </h5>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="dash-content-card">
              <div className="table-responsive">
                <Table className="custom-table-data" striped bordered hover>
                  <thead>
                    <tr>
                      <th>Id</th>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Mobile</th>
                      <th>Subject</th>
                      <th>Date Created</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="search-table">
                      <td>
                        <Form.Control
                          type="text"
                          value={searchedData?.feedId}
                          name="feedId"
                          placeholder="ID"
                          onChange={(e) => {
                            onChangeSearch(e);
                          }}
                        />
                      </td>
                      <td>
                        <Form.Control
                          type="text"
                          value={searchedData?.feedName}
                          name="feedName"
                          placeholder="Name"
                          onChange={(e) => {
                            onChangeSearch(e);
                          }}
                        />
                      </td>
                      <td>
                        <Form.Control
                          type="text"
                          value={searchedData?.feedEmail}
                          name="feedEmail"
                          placeholder="Email"
                          onChange={(e) => {
                            onChangeSearch(e);
                          }}
                        />
                      </td>
                      <td>
                        <Form.Control
                          type="text"
                          value={searchedData?.feedMobile}
                          name="feedMobile"
                          placeholder="Mobile"
                          onChange={(e) => {
                            onChangeSearch(e);
                          }}
                        />
                      </td>
                      <td>
                        <Form.Select
                          aria-label="Default select example"
                          value={searchedData?.feedSubject}
                          name="feedSubject"
                          placeholder="feedSubject"
                          onChange={(e) => {
                            onChangeSearch(e);
                          }}>
                          <option value=""> - Select Subject - </option>
                          <option value="Share an idea">Share an idea</option>
                          <option value="Ask a question">Ask a question</option>
                          <option value="Report an issue">Report an issue</option>
                          <option value="Just say Hello">Just say Hello</option>
                        </Form.Select>
                      </td>
                      <td></td>
                      <td className="serac-btns text-center">
                        <button
                          type="button"
                          className="green_button_admin"
                          onClick={(e) => {
                            searchHandler(e);
                          }}>
                          Search
                        </button>
                      </td>
                    </tr>
                    {list && list?.length ? (
                      list?.map((data, index) => {
                        return (
                          <tr key={index}>
                            <td>{data?.id}</td>
                            <td>{data?.name}</td>
                            <td>{data?.email}</td>
                            <td>{data?.mobile}</td>
                            <td>{data?.subject}</td>
                            <td>{moment(data?.created).format('Do MMM, YYYY')}</td>
                            <td>
                              <span
                                role="presentation"
                                className="text-primary  mx-5 pointer"
                                onClick={() => {
                                  handleShow(data);
                                }}
                                title="View Details">
                                <i className="fas fa-search-plus"></i>
                              </span>
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr className="text-center">
                        <td colSpan={9}>
                          <NoDataFound />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
                <div className="react-page d-flex">
                  {record / 10 > 1 ? (
                    <ReactPaginate
                      previousLabel={currentPage > 0 ? '<' : ''}
                      nextLabel=">"
                      onPageChange={handlePageClick}
                      pageCount={record / 10}
                      activeClassName={'page-link active'}
                    />
                  ) : (
                    ''
                  )}
                </div>
              </div>
            </div>
          </div>
          {/* modal */}
          <Modal show={show} onHide={handleClose} animation={false}>
            <Modal.Header closeButton>
              <Modal.Title>View Feedback Details</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="dash-content-card">
                <div className="view-detail-content">
                  <Row>
                    <Col md={12}>
                      <p>
                        <strong>Name : </strong>
                        {viewDtaa?.name}
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <p>
                        <strong>Email : </strong>
                        {viewDtaa?.email}
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <p>
                        <strong>Phone : </strong>
                        {viewDtaa?.mobile}
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <p>
                        <strong>Subject : </strong>
                        {viewDtaa?.subject}
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <p>
                        <strong> Message : </strong>
                        {viewDtaa?.message}
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <p>
                        <strong>Date Created : </strong>
                        {moment(viewDtaa?.created).format('Do MMM , YYYY')}
                      </p>
                    </Col>
                  </Row>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        </Container>
      </div>

      {loading ? <Loader /> : ''}
    </>
  );
}
