/**
@copyright  :   ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
@author     :   Shiv Charan Panjeta 
 
All Rights Reserved.
Proprietary and confidential :  All information contained here in is, and remains
the property of ToXSL Technologies Pvt. Ltd. and it's partners.
Unauthorized copying of this file, via any medium is strictly prohibited.
*/

import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';
import * as TemplateServices from '../../../services/TemplateServices';
import Loader from '../../../components/commoncomponents/loader/loader-large';
import ReactPaginate from 'react-paginate';
import showNotification from '../../../services/NotificationService';
import { Alert } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { ZERO } from '../../../globals/constants';
import NoDataFound from '../../../components/commoncomponents/NoDataFound';
export default function TokenTemplate({ selectedTab }) {
  const isAlert = useSelector((state) => state.alert);
  const [chekcedId, setCheckedId] = useState([]);
  const [allId, setAllId] = useState([]);
  const [totalRecords, setTotalRecord] = useState(0);
  const navigate = useNavigate();
  const [allTokenList, setAllTokenList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [messageView, setMessageView] = useState('');
  const [showalert, setShowAlert] = useState(false);
  const [alert, setAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState('');
  const [showalert1, setShowAlert1] = useState(false);
  const [checkId, setChecked] = useState(false);
  const [msgState, setMsgState] = useState('');
  const [action, setAction] = useState('');
  useEffect(() => {
    if (selectedTab == 'token') {
      getTokenList();
    }
  }, [selectedTab]);
  useEffect(() => {
    const timeId = setTimeout(() => {
      setAlert(false);
      setAlertMsg('');
      setShowAlert(false);
      setMessageView('');
      setShowAlert1(false);
    }, 3000);
    return () => {
      clearTimeout(timeId);
    };
  }, [alert, messageView, showalert]);

  useEffect(() => {
    if (isAlert?.showAlertBox) {
      setAlertMsg(isAlert?.message);
      setAlert(true);
    }
  }, []);
  /**
   * Get All Token List
   */
  const handleMultipleCheckBox = (id) => {
    if (chekcedId.includes(id)) {
      let filterId = chekcedId.filter((data) => data !== id);
      setCheckedId(filterId);
    } else {
      setCheckedId([...chekcedId, id]);
    }
  };

  const getTokenList = (page = '1', state_id = '', applicable_for) => {
    setLoading(true);

    TemplateServices.getTemplateTokenList(page, state_id, applicable_for)
      .then((resp) => {
        if (resp?.status === 200) {
          setAllTokenList(resp?.data?.data);
          setTotalRecord(resp?.data?.pager?.total);
          setAllId(resp?.data?.data?.map((data) => data?.id));
          setMsgState(resp?.data?.message);
          setLoading(false);
        } else {
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  const getApplicable = (type) => {
    switch (type) {
      case '1':
        return 'Institute';
      case '2':
        return 'Student';
      case '3':
        return 'Company';
      default:
        return 'N/A';
    }
  };
  const handleSelectAll = (e) => {
    if (e.target.checked === true) {
      setChecked(true);
      setCheckedId(allId);
    } else {
      setCheckedId([]);
      setChecked(false);
    }
  };

  // for action update particular id
  const handleStatusUpdate = (id, value) => {
    const formData = new FormData();
    formData.append('ids', id);
    formData.append('action', value);
    TemplateServices.getMultiTokenSelect(formData).then((res) => {
      if (res?.status === 200) {
        showNotification('success', res?.data?.message);
        getTokenList();
      }
    });
  };
  const handlePageClick = (a) => {
    setCheckedId([]);
    let page = a?.selected + 1;
    setCurrentPage(page);
    getTokenList(page);
    setChecked(false);
  };

  const handelDelete = (id) => {
    setLoading(true);
    TemplateServices.delTemplateToken(id).then((resp) => {
      if (resp?.status == 200) {
        setLoading(false);
        showNotification('success', resp?.data?.message);
        getTokenList(currentPage);
      } else {
        setLoading(false);
      }
    });
  };
  const globalStateHandler = () => {
    setShowAlert(true);
  };
  const handlePerformAction = (e) => {
    if (chekcedId.length !== 0) {
      setShowAlert(false);
      let body = {
        ids: chekcedId,
        action: e.target.value
      };
      let formData = new FormData();
      for (let i in body) {
        formData.append(`${i}`, body[i]);
      }
      TemplateServices.getMultiTokenSelect(formData).then((res) => {
        if (res?.status === 200) {
          setMessageView(res?.data?.message);
          setShowAlert1(true);
          getTokenList();
          setCheckedId([]);
        }
      });
    } else {
      globalStateHandler();
    }
  };

  const getStatus = (state_id) => {
    switch (state_id) {
      case '0':
        return 'Disable';
      case '1':
        return 'Enable';

      default:
        return 'Delete';
    }
  };
  return (
    <div className="manage-temp-content">
      <div className="manage-temp-hd d-flex align-items-center justify-content-between mb-3">
        <h4>Template Token</h4>

        <div className="manage-temp-btn">
          {checkId == true ? (
            <div className="head-instite-sec">
              <Form.Select
                className=""
                aria-label="Default select example"
                value={action}
                onChange={(e) => {
                  setAction(e.target.value);
                  handlePerformAction(e.target.value);
                }}>
                <option>Choose Action</option>
                <option value="enable">Enable</option>
                <option value="disable">Disable</option>
                <option value="delete">Delete</option>
              </Form.Select>
            </div>
          ) : (
            ''
          )}

          <Link to="/admin/AddManageTemplate" state={selectedTab}>
            Add New
          </Link>
        </div>
      </div>

      <Alert variant={'danger'} className={`${showalert ? '' : 'd-none'}`}>
        Please select any record
      </Alert>
      <Alert variant={'success'} className={`${showalert1 ? '' : 'd-none'}`}>
        {messageView}
      </Alert>
      <div className="table-responsive">
        <Table className="custom-table-data" striped bordered hover>
          <thead>
            <tr>
              {msgState == !'Data not found' || allTokenList?.length > 0 ? (
                <th>
                  <Form.Check
                    type="checkbox"
                    id="checkbox"
                    label=""
                    checked={allId?.length !== 0 && allId?.length === chekcedId?.length}
                    onChange={handleSelectAll}
                  />
                </th>
              ) : (
                <th></th>
              )}

              <th>Sr. No.</th>
              <th>Info</th>
              <th>Applicable For</th>
              <th>Attribute</th>
              <th>Status</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr className="search-table">
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td>
                {' '}
                <Form.Select
                  aria-label="Default select example"
                  name="status"
                  onChange={(e) => {
                    getTokenList(1, e.target.value);
                  }}>
                  <option value="">- Select Status -</option>
                  <option value="1">Enable</option>
                  <option value="0">Disable</option>
                  <option value="2">Delete</option>
                </Form.Select>
              </td>
              <td></td>
            </tr>
            {allTokenList && allTokenList?.length != ZERO ? (
              allTokenList?.map((data, index) => {
                return (
                  <tr key={index}>
                    <td>
                      <Form.Check
                        type="checkbox"
                        id={`id${data?.id}`}
                        checked={chekcedId?.includes(data?.id)}
                        onChange={() => handleMultipleCheckBox(data?.id)}
                        label=""
                      />
                    </td>
                    <td>{data?.id}</td>
                    <td>{data?.title}</td>
                    <td>{getApplicable(data?.applicable_for)}</td>
                    <td>{data?.attribute}</td>
                    <td>
                      {' '}
                      <Form.Select
                        aria-label="Default select example"
                        value={data?.state_id}
                        onChange={(e) => {
                          handleStatusUpdate(data.id, e.target.value == 1 ? 'enable' : 'disable');
                        }}>
                        <option>Select Status</option>
                        <option value="1">Enable</option>
                        <option value="0">Disable</option>
                        <option value="2">Delete</option>
                      </Form.Select>
                    </td>

                    <td>
                      <Form.Select
                        aria-label="Default select example"
                        onChange={(e) => {
                          if (e.target.value == '1') {
                            navigate(`/admin/AddManageTemplate?id=${data?.id}`);
                          }
                          if (e.target.value == '2') {
                            handelDelete(data?.id);
                          }
                        }}>
                        <option hidden>Action</option>
                        <option value="1">Edit</option>
                        <option value="2">Delete</option>
                      </Form.Select>
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan={7} className="text-center">
                  <NoDataFound />
                </td>
              </tr>
            )}
          </tbody>
        </Table>
        <div className="react-page d-flex">
          {totalRecords > 10 ? (
            <ReactPaginate
              previousLabel={currentPage > 0 ? '<' : ''}
              nextLabel=">"
              onPageChange={handlePageClick}
              activeClassName={'page-link active'}
              pageCount={Math.ceil(totalRecords / 10)}
            />
          ) : (
            ''
          )}
        </div>
      </div>
      {loading ? <Loader /> : <></>}
    </div>
  );
}
