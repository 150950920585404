import moment from 'moment/moment';
import { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import useDocumentTitle from '../../../globals/useDocumentTitle';
import { getSingleJobDetail } from '../../../services/JobServices';
import { getJobCategoriesDropdown } from '../../../services/UserServices';
import FooterMain from '../../commoncomponents/FooterMain';
import HeaderInner from '../../commoncomponents/HeaderInner';

function JobsDetailOut() {
  const { id } = useParams();
  const [detail, setDetail] = useState('');
  useDocumentTitle(`${detail?.job_title}|AlmaBay`);
  const [category, setCategory] = useState([]);
  const [state, setState] = useState([]);

  useEffect(() => {
    getJobDetails(id);
    getJobCategory();
  }, [id]);

  const getJobDetails = (id) => {
    getSingleJobDetail(id).then((res) => {
      if (res?.status === 200) {
        setDetail(res?.data?.data);
        setState(res?.data?.states);
      }
    });
  };

  const getJobCategory = () => {
    getJobCategoriesDropdown().then((res) => {
      if (res?.status === 200) {
        setCategory(res?.data?.data);
      }
    });
  };

  return (
    <>
      <HeaderInner />
      <section className="job-outr-sec py-5">
        <div className="container">
          <div className="job-inr-card">
            <Row>
              <Col md={12} lg={3}>
                <div className="jobs-by-loction mb-4">
                  <h4 className="mb-3">Jobs by Location</h4>
                  <ul className="job-loct-list">
                    {state?.length !== 0 ? (
                      <>
                        {state?.map((data, index) => {
                          return (
                            <li key={index}>
                              <Link to={`/jobs/search/location/${data?.location}`}>
                                Jobs in {data?.city}
                              </Link>
                            </li>
                          );
                        })}
                      </>
                    ) : (
                      <></>
                    )}
                  </ul>
                </div>
                <div className="jobs-by-loction mb-4">
                  <h4 className="mb-3">Jobs by Category</h4>
                  <ul className="job-loct-list">
                    {category?.length !== 0 ? (
                      <>
                        {category?.map((data, index) => {
                          return (
                            <li key={index}>
                              <Link to={`/jobs/search/category/${data?.id}`}>{data?.name}</Link>
                            </li>
                          );
                        })}
                      </>
                    ) : (
                      <></>
                    )}
                  </ul>
                </div>
              </Col>
              <Col md={12} lg={6}>
                <div className="Job-inr-dis">
                  <div className="job-inr-hd mb-3">
                    <h4 className="">JOB DETAILS</h4>
                  </div>
                  <div>
                    <div className="job-detail-hd mb-3">
                      <div className="job-hd-lft">
                        <h3>{detail?.job_title}</h3>
                        <p>{detail?.company_name}</p>
                        {/* <p>Posted By: dfavo</p> */}
                      </div>
                    </div>
                    <div className="mb-4">
                      <ul className="job-recomm-addrs">
                        {detail?.city_name && (
                          <li>
                            <i className="fas fa-map-marker-alt" />
                            {detail?.city_name}
                          </li>
                        )}
                        {detail?.hr_email && (
                          <li>
                            <i className="fa fa-envelope" /> {detail?.hr_email}
                          </li>
                        )}
                        {detail?.min_ctc && detail?.max_ctc && (
                          <li>
                            <i className="fas fa-briefcase" />
                            CTC:{detail?.min_ctc}-{detail?.max_ctc}
                          </li>
                        )}
                        {detail?.open_positions && (
                          <li>
                            <i className="fas fa-users" />
                            Positions open: {detail?.open_positions}
                          </li>
                        )}
                        {detail?.min_exp && detail?.max_exp && (
                          <li>
                            <i className="fas fa-briefcase" />
                            Experience:{detail?.min_exp}-{detail?.max_exp}
                          </li>
                        )}

                        {detail?.job_type && (
                          <li>
                            <i className="fas fa-hourglass-end" />
                            Job Type: {detail?.job_type}
                          </li>
                        )}
                      </ul>
                    </div>
                    <div className="job-detail-dis">
                      <h5>Job description</h5>
                      <div>
                        <p
                          dangerouslySetInnerHTML={{
                            __html: detail?.job_info
                          }}
                        ></p>
                      </div>
                    </div>
                    <div className="job-post d-flex align-items-center justify-content-between">
                      <p>POSTED ON: {moment(detail?.created_on).format('YYYY-MM-DD h:mm:ss')}</p>
                      <p>
                        POSTED BY:{' '}
                        {detail?.added_by == '0' ? detail?.user?.first_name : detail?.added_by}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="job-dtl-btn-card d-flex align-items-center justify-content-between mt-3 mb-4">
                  <Link to="/login" state={`/jobs/details/${id}`}>
                    Login to Apply
                  </Link>
                  <Link to="/jobs/search">Back to Search Page</Link>
                </div>
              </Col>
              <Col md={12} lg={3}>
                <div className="on-amblay-card mb-3">
                  <h5>On Almabay, you can find where your friends went after college</h5>
                  <Link className="main-btn mt-2" to="/login">
                    LOGIN
                  </Link>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </section>
      <FooterMain className={'colour-bg'} />
    </>
  );
}

export default JobsDetailOut;
