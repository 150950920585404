/**
 * @copyright : ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
@author    : Shiv Charan Panjeta 
 
All Rights Reserved.
Proprietary and confidential :  All information contained here in is, and remains
the property of ToXSL Technologies Pvt. Ltd. and it's partners.
Unauthorized copying of this file, via any medium is strictly prohibited.
 */
import { useEffect, useState } from 'react';
import moment from 'moment';
import { truncate } from '../../../../globals/helper';
import { getUpcomingEvent } from '../../../../services/InstituteServices';

function UpcomingCard() {
  const [upcomingEvent, setUpcomingEvent] = useState([]);

  useEffect(() => {
    getDetails();
  }, []);
  /**
   * for list of events
   */
  const getDetails = () => {
    getUpcomingEvent().then((res) => {
      if (res?.status === 200) {
        setUpcomingEvent(res?.data?.data);
      }
    });
  };

  return (
    <>
      <div className="upcome-card-detail">
        <h4 className="upcome-hd-sec">Upcoming Events</h4>
        <ul className="upcome-card-lst">
          {upcomingEvent && upcomingEvent?.length !== 0 ? (
            upcomingEvent?.map((data, index) => (
              <li key={index}>
                <div className="upcome d-flex align-items-center">
                  <div className="upcom-date">
                    <h4>{moment(data?.from_event_date).format('MMM')}</h4>
                    <p> {moment(data?.from_event_date).format('DD')}</p>
                  </div>
                  <div className="upcome-card-dis">
                    <h4>{data?.name}</h4>
                    <p>{truncate(data?.decp, 60)}</p>
                  </div>
                </div>
              </li>
            ))
          ) : (
            <li>Data Not Found</li>
          )}
        </ul>
      </div>
    </>
  );
}

export default UpcomingCard;
