import { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Alert } from 'react-bootstrap';
import { emailRegex, wordToNum } from '../../globals/helper';
import { addContectus } from '../../services/AuthServices';
import { Form } from 'react-bootstrap';
import { useSelector } from 'react-redux';
export default function ContactUsModal({ isForm, setLoading, setIsForm, previousClass }) {
  const isAlert = useSelector((state) => state.alert);
  const [addcontect, setAddContect] = useState({
    name: '',
    email: '',
    phone_skype: '',
    message: '',
    saying_id: ''
  });
  const [alert, setAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState('');
  useEffect(() => {
    const timeId = setTimeout(() => {
      setAlert(false);
      setAlertMsg('');
    }, 3000);
    return () => {
      clearTimeout(timeId);
    };
  }, [alert]);

  useEffect(() => {
    if (isAlert?.showAlertBox) {
      setAlertMsg(isAlert?.message);
      setAlert(true);
    }
  }, []);

  const [clicked, setClicked] = useState(false);
  const validate = () => {
    let errors = {};
    if (!addcontect?.name.trim()) {
      errors.name = 'This field is required.';
    }
    if (!addcontect?.email.trim()) {
      errors.email = 'This field is required.';
    } else if (!emailRegex.test(addcontect?.email)) {
      errors.email = 'please enter valid email';
    }
    if (!addcontect?.phone_skype.trim()) {
      errors.phone_skype = 'This field is required.';
    }
    if (!addcontect?.message.trim()) {
      errors.message = 'This field is required.';
    }
    return errors;
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setAddContect({ ...addcontect, [name]: value });
  };

  const handleSubmit = () => {
    setClicked(true);
    if (!Object.keys(validate()).length) {
      setLoading(true);
      setClicked(false);
      let body = {
        name: addcontect?.name,
        email: addcontect?.email,
        phone_skype: addcontect?.phone_skype,
        message: addcontect?.message,
        saying_id: wordToNum(previousClass)
      };
      addContectus(body).then((res) => {
        setLoading(false);
        setAlert(true);
        setAlertMsg(res?.data?.message);
        setAddContect({
          name: '',
          email: '',
          phone_skype: '',
          message: '',
          saying_id: ''
        });
      });
    }
  };
  const resetHandler = (e) => {
    e.preventDefault();
    setIsForm('d-none');
    setAddContect({
      name: '',
      email: '',
      phone_skype: '',
      message: '',
      saying_id: ''
    });
  };
  return (
    <div className="contact-frm-sec mb-5">
      <Col lg={12}>
        <div className={`form-fields one ${isForm}`} id="contact-form">
          <Alert variant={'success'} className={`${alert ? '' : 'd-none'}`}>
            {alertMsg}
          </Alert>

          <form>
            <div className="form-group">
              <Row>
                <Col xs={3} className="text-end">
                  <label htmlFor="yourName">Name</label>
                </Col>
                <Col xs={9} className="mb-3">
                  <Form.Control
                    type="text"
                    className="form-control"
                    id="yourmessage"
                    name="name"
                    onChange={handleChange}
                    value={addcontect?.name}
                  />
                  <div className={`${clicked && validate().name ? '' : 'd-none'} danger-frm `}>
                    {clicked && validate().name}
                  </div>
                </Col>

                <Col xs={3} className="text-end">
                  <label htmlFor="yourEmail">Email address</label>
                </Col>
                <Col xs={9} className="mb-3">
                  <Form.Control
                    type="text"
                    className="form-control"
                    id="yourmessage"
                    name="email"
                    onChange={handleChange}
                    value={addcontect?.email}
                  />
                  <div className={`${clicked && validate().email ? '' : 'd-none'} danger-frm `}>
                    {clicked && validate().email}
                  </div>
                </Col>

                <Col xs={3} className="text-end">
                  <label htmlFor="yourPhone">Phone/ Skype ID</label>
                </Col>
                <Col xs={9} className="mb-3">
                  <Form.Control
                    type="text"
                    className="form-control"
                    id="yourmessage"
                    name="phone_skype"
                    onChange={handleChange}
                    value={addcontect?.phone_skype}
                  />
                  <div
                    className={`${clicked && validate().phone_skype ? '' : 'd-none'} danger-frm `}
                  >
                    {clicked && validate().phone_skype}
                  </div>
                </Col>

                <Col xs={3} className="text-end">
                  <label htmlFor="yourmessage">Message</label>
                </Col>
                <Col xs={9} className="mb-3">
                  <Form.Control
                    type="text"
                    className="form-control"
                    id="yourmessage"
                    name="message"
                    onChange={handleChange}
                    value={addcontect?.message}
                    rows={5}
                  />
                  <div className={`${clicked && validate().message ? '' : 'd-none'} danger-frm `}>
                    {clicked && validate().message}
                  </div>
                </Col>
              </Row>
            </div>
          </form>
          <Col xs={9} sm={{ offset: 3 }} className="text-end">
            <button
              type="reset"
              className="btn-custom btn-light-gray btn-small me-2"
              id="cancel-form"
              onClick={(e) => resetHandler(e)}
            >
              Cancel
            </button>
            <button type="submit" className="btn-custom btn-blue btn-small" onClick={handleSubmit}>
              Submit
            </button>
          </Col>
        </div>
      </Col>
    </div>
  );
}
