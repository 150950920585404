import HeaderInner from '../../commoncomponents/HeaderInner';
import FooterMain from '../../commoncomponents/FooterMain';
import { Link } from 'react-router-dom';
import useDocumentTitle from '../../../globals/useDocumentTitle';
import TopTabbings from '../user-pages/TopTabbings';
import { useSelector } from 'react-redux';
function ReturnPolicy() {
  useDocumentTitle('Return Policy | AlmaBay');
  const isloggedIn = useSelector((state) => state?.user?.isloggedIn);
  return (
    <>
      <HeaderInner state={'none'} />
      {isloggedIn ? (
        <div className="header-lnk-feeds">
          <div className="container">
            <TopTabbings />
          </div>
        </div>
      ) : (
        ''
      )}
      <section className="terms-condition py-5">
        <div className="container">
          <div className="terms-condition-inr">
            <div className="title-card mb-2">
              <h3 className="title-hd">Return Policy</h3>
            </div>
            <p>
              Guys, Almabay.com, wants you to have complete peace of mind before and after you
              purchase anything from us. We understand that people are unsure about purchasing
              anything online since they cannot see the quality and fit before purchasing that is
              why we have designed the guidelines in a way that helps customers purchase anything
              from Almabay.com without having to worry about after purchase problems. Following
              conditions must be satisfied for being applicable for return:
            </p>
            <ul className="styled-list">
              <li>
                The product being returned must be{' '}
                <b>UNUSED and all the Tags and Labels MUST BE INTACT</b>
              </li>
              <li>Our team will check the product before approving it for replacement or return</li>
              <li>You must send us the product back WITHIN 15 DAYS from the date of delivery</li>
            </ul>
            <h4>The Exchange Policy</h4>
            <p>The entire refund / return procedure takes about 6-7 working days</p>
            <p>I have received the Wrong product. What to Do?</p>
            <ul className="styled-list">
              <li>
                If you have received the wrong product from Almabay.com then just drop us an email
                at{' '}
                <Link
                  to="javascript:void(0)"
                  onClick={() => (window.location = 'mailto:info@almabay.com')}
                >
                  info@almabay.com
                </Link>{' '}
                and send the order back to us via any courier on the following mentioned address.
              </li>
              <li>
                Please put a note inside mentioning your ORDER ID and problem with the product.
              </li>
              <li>
                We will check the product if it is unused and meets the above mentioned requirements
                and will send you the new products.
              </li>
            </ul>
            <p>The size does not fit me. Can I exchange it with another size or another product?</p>
            <ul className="styled-list">
              <li>
                Definitely. If the size does not fit you then just send the product back to us on
                the following address via any courier
              </li>
              <li>
                Please put a note inside the package mentioning your ORDER ID and the new size you
                want as replacement.
              </li>
              <li>
                As soon as we receive the package from your side, we will send you the replacement.
              </li>
              <li>
                The courier charges from your location to our location have to be bourne by the
                customer only.
              </li>
            </ul>
            <h4>Address for Sending the Courier Back</h4>
            <p>#441, Level 4, Tricity Plaza, Peer Muchalla, Zirakpur, Punjab - 140603</p>
            <p>Call Us on: +91-99155-18124</p>
            <p>
              For any other query or doubt, feel free to write to us on{' '}
              <Link
                to="javascript:void(0)"
                onClick={() => (window.location = 'mailto:info@almabay.com')}
              >
                info@almabay.com
              </Link>
            </p>
            <p> Happy Shopping!</p>
          </div>
        </div>
      </section>
      <FooterMain className={'colour-bg'} />
    </>
  );
}

export default ReturnPolicy;
