import { Link } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import HeaderInner from '../../commoncomponents/HeaderInner';
import FooterMain from '../../commoncomponents/FooterMain';
import { useState } from 'react';
import Loader from '../../commoncomponents/loader/loader-large';
import useDocumentTitle from '../../../globals/useDocumentTitle';
import ContactUsModal from '../../commoncomponents/ContactUsModal';

function ContactUs() {
  useDocumentTitle(
    'Alumni Engagement Portal | Interns Job Search Portal, Manage Professional Identity alumni online web portal | AlmaBay'
  );
  const [previousClass, setPreviosClass] = useState('one');
  const [loading, setLoading] = useState(false);
  const [isForm, setIsForm] = useState('d-none');
  const [showLowerForm, setShowLowerForm] = useState(false);
  const classHandler = (id) => {
    setIsForm(id);
    document.getElementById('contact-form').classList.replace(previousClass || ' ', id);
    setPreviosClass(id);
    setShowLowerForm(false);
  };
  const showForm = () => {
    setShowLowerForm(true);
  };

  return (
    <>
      <HeaderInner />
      <section className="breddcrum py-5">
        <div className="container">
          <Row className="align-items-center">
            <Col md={8} lg={8}>
              <div className="bread-title text-center text-lg-start text-md-start">
                <h2>Contact us</h2>
              </div>
            </Col>
            <Col md={4} lg={4}>
              <div className="bread-rt  text-center text-lg-end text-md-end ">
                <Link className="main-btn" to="/faq">
                  Read FAQs
                </Link>
              </div>
            </Col>
          </Row>
        </div>
      </section>
      <section className="contact-sec py-5">
        <div className="container">
          <div className="contact-frm-sec mb-5">
            <div>
              <h1 className="text-center contactUs-heading">
                This is how great partnerships get started
              </h1>
            </div>
            <div>
              <h4 className="text-center contactUs-SubHeading">Are you saying?</h4>
            </div>
            <Row>
              <Col lg={2}>
                <div className="d-flex d-lg-block text-center h-100 hover-contact-cards">
                  <input
                    type="radio"
                    name="contact-us-radio"
                    id="firstoption"
                    className="d-block d-lg-none me-2 me-lg-0"
                    onChange={() => classHandler('one')}
                    checked={isForm === 'one' ? true : false}
                  />
                  <label htmlFor="firstoption">
                    {' '}
                    We are an institute and want to start our alumni body{' '}
                  </label>
                </div>
              </Col>
              <Col lg={2}>
                <div className="d-flex d-lg-block text-center h-100 hover-contact-cards">
                  <input
                    onChange={() => classHandler('two')}
                    type="radio"
                    name="contact-us-radio"
                    id="secondoption"
                    className="d-block d-lg-none me-2 me-lg-0"
                    checked={isForm === 'two' ? true : false}
                  />
                  <label htmlFor="secondoption">
                    {' '}
                    We have an alumni body but it is not active{' '}
                  </label>
                </div>
              </Col>
              <Col lg={2}>
                <div className="d-flex d-lg-block text-center h-100 hover-contact-cards">
                  <input
                    onChange={() => classHandler('three')}
                    type="radio"
                    name="contact-us-radio"
                    id="thirdoption"
                    className="d-block d-lg-none me-2 me-lg-0"
                    checked={isForm === 'three' ? true : false}
                  />
                  <label htmlFor="thirdoption">
                    We are an alumni association who want to increase membership
                  </label>
                </div>
              </Col>
              <Col lg={2}>
                <div className="d-flex d-lg-block text-center h-100 hover-contact-cards">
                  <input
                    onChange={() => classHandler('four')}
                    type="radio"
                    name="contact-us-radio"
                    id="forthoption"
                    className="d-block d-lg-none me-2 me-lg-0"
                    checked={isForm === 'four' ? true : false}
                  />
                  <label htmlFor="forthoption">We want to have great alumni events </label>
                </div>
              </Col>
              <Col lg={2}>
                <div className="d-flex d-lg-block text-center h-100 hover-contact-cards">
                  <input
                    onChange={() => classHandler('five')}
                    type="radio"
                    name="contact-us-radio"
                    id="fifthoption"
                    className="d-block d-lg-none me-2 me-lg-0"
                    checked={isForm === 'five' ? true : false}
                  />
                  <label htmlFor="fifthoption">
                    We want to start chapters of alumni body across the globe{' '}
                  </label>
                </div>
              </Col>
              <Col lg={2}>
                <div className="d-flex d-lg-block text-center h-100 hover-contact-cards">
                  <input
                    onChange={() => classHandler('six')}
                    type="radio"
                    name="contact-us-radio"
                    id="sixthoption"
                    className="d-block d-lg-none me-2 me-lg-0"
                    checked={isForm === 'six' ? true : false}
                  />
                  <label htmlFor="sixthoption">
                    I am an alumnus who want to start an alumni body
                  </label>
                </div>
              </Col>

              {!showLowerForm && (
                <ContactUsModal
                  isForm={isForm}
                  setLoading={setLoading}
                  setIsForm={setIsForm}
                  previousClass={previousClass}
                />
              )}
            </Row>
          </div>

          <div className="text-center">
            <strong>
              Want to say Hello?{' '}
              <div
                role="presentation"
                onClick={() => {
                  classHandler('seven');
                  showForm();
                }}
                name="contact-us-radio"
                id="seventhoption"
              >
                <label htmlFor="seventhoption" className="text-decoration-underline text-warning">
                  Click here
                </label>
              </div>
            </strong>
            <br />
            We love to hear from you
          </div>
          {showLowerForm && (
            <ContactUsModal
              isForm={isForm}
              setLoading={setLoading}
              setIsForm={setIsForm}
              previousClass={previousClass}
            />
          )}

          <div className="contact-details py-5">
            <Row>
              <Col md={4} lg={4}>
                <div className="cntct-left-detail">
                  <h4 className=" mb-3">Contact Details</h4>
                  <div className="cntct-media d-flex algin-items-top mb-3">
                    <i className="fa fa-phone me-2" aria-hidden="true"></i>
                    <p>+91-98729-67891</p>
                  </div>
                  <div className="cntct-media d-flex algin-items-top">
                    <i className="fas fa-map-marker-alt me-2"></i>
                    <p>
                      #441, Level 4, Tricity Plaza, Peer Muchalla, Zirakpur, Punjab 140603 - India
                    </p>
                  </div>
                </div>
              </Col>
              <Col md={8} lg={8}>
                <div className="contact-map">
                  <iframe
                    title="contact-map"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3432.0599401676523!2d76.85807907631366!3d30.660440589121674!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390f94ed557d767f%3A0xb667563103641761!2sAlmaBay%20Networks%20Private%20Limited!5e0!3m2!1sen!2sin!4v1680938643664!5m2!1sen!2sin"
                    width="100%"
                    height="300"
                    // style="border:0;"
                    allowfullscreen=""
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                  ></iframe>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </section>
      <FooterMain className={'colour-bg'} />
      {loading ? <Loader /> : ''}
    </>
  );
}

export default ContactUs;
