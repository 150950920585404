function ChapterCard({ details, type }) {
  return (
    <>
      <div className="chapter-card-detail">
        <h4 className="chapter-hd-sec">{type} Details</h4>
        <div className="chapter-card-dis">
          <h4>{details?.name}</h4>
          <p>{details?.about}</p>
        </div>
        {/* <div className="chapter-ftr-profile d-flex align-items-center">
          <div className="chapter-ftr-icn">
            <img
              src={require('../../../../assets/images/institute-img/chapter-img.png')}
              alt="club"
            />
          </div>
          <div className="Chaptr-ftr-rt">
            <h4>Amit Sharma</h4>
            <p>Admin</p>
          </div>
        </div> */}
      </div>
    </>
  );
}

export default ChapterCard;
