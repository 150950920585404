/**
@copyright : ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
@author     : Shiv Charan Panjeta 
 
All Rights Reserved.
Proprietary and confidential :  All information contained herein is, and remains
the property of ToXSL Technologies Pvt. Ltd. and its partners.
Unauthorized copying of this file, via any medium is strictly prohibited.
*/

import { useRef, useState } from 'react';

import { Button } from 'react-bootstrap';
import EmailEditor from 'react-email-editor';
import { useNavigate } from 'react-router-dom';
import * as index from '../../components/commoncomponents/SvgIcons/allIcons.jsx';
import Loader from '../../components/commoncomponents/loader/loader-large.jsx';
import { replaceStringFormat } from '../../globals/helper.js';
import showNotification from '../../services/NotificationService.js';
import { updateEmailTemplateDetail } from '../../services/TemplateServices.js';
import sample from './TemplateConfig/template_2_config.json';

const BuildEmailTemplate = ({ id, idAdd, savedDesign, tokenList }) => {
  const [loading, setLoading] = useState(false);
  const [showTokenList, setShowTokenList] = useState(false);
  const emailEditorRef = useRef(null);
  const idToken = id ? id : idAdd;
  let navigate = useNavigate();

  // API CODE

  const exportHtmlUpdate = () => {
    setLoading(true);
    emailEditorRef?.current?.editor.exportHtml((data) => {
      const { design, html } = data;
      let formData = new FormData();
      formData.append('id', idToken);
      formData.append('html', html);
      formData.append('html_template', JSON.stringify(design));
      updateEmailTemplateDetail(formData)
        .then((res) => {
          if (res?.status == 200) {
            setLoading(false);
            showNotification('success', res?.data?.message);
            navigate('/admin/ManageTemplate', {
              state: {
                active: 'email'
              }
            });
          } else {
            setLoading(false);
          }
        })
        .catch((error) => {
          setLoading(false);
          console.error(error);
        });
    });
  };

  const onDesignLoad = (data) => {
    console.log('onDesignLoad', data);
  };

  // with options
  const onLoad = () => {
    emailEditorRef?.current?.editor.addEventListener('design:loaded', onDesignLoad);
    emailEditorRef?.current?.editor.loadDesign(savedDesign ? JSON.parse(savedDesign) : sample);
  };

  const onReady = () => {
    console.log('onReady');
  };

  /**
   * Copy To ClipBoard
   * @param {String} text
   */
  function copyToClipBoard(text) {
    showNotification('success', 'Token Copied successfully');
    navigator.clipboard.writeText(replaceStringFormat(text));
  }
  // API END

  return (
    <div>
      <div className="container-fluid">
        <div className="admin_outer">
          <div className="admin-right">
            <div className="cont">
              <div className="col-row">
                {' '}
                <div className="w100">
                  {' '}
                  <div className="empdiv"></div>
                </div>
              </div>
              <div className="col-row  mr_normal_Margin_b mr_normal_padding_l mr_normal_padding_r flex-wrap justify_content_space_between breadcrumbs-responsive">
                <div className="w60">
                  <div className="fonts_inner justify_content_space_between">
                    <div className="breadcrumbs">
                      <h4 className="medium">Build Your Template</h4>

                      <p className="p15">
                        <span>Dashboard</span> / <span>Add Email Template</span>{' '}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="">
                  <div
                    onClick={() => {
                      navigate(-1);
                    }}
                    className="btn-blue1 border_0 btn height45 border-radius5 text-center text-white flex align-item-center d-sm-none">
                    <span className="mr_xsmall_Margin_r">
                      {' '}
                      <index.BackArrow />{' '}
                    </span>{' '}
                    Back
                  </div>
                </div>
              </div>
              <div className="w100">
                <div className="dashboard-card mr_large_padding">
                  <EmailEditor ref={emailEditorRef} onReady={onLoad} onLoad={onReady} />
                </div>
              </div>

              <div className="bg-blue4 mr_normal_padding  margin-bottom-50">
                <div className="fonts_inner d-flex align-item-center">
                  {tokenList?.length !== 0
                    ? tokenList?.map((data, index) => {
                        return (
                          <button
                            type="button"
                            key={index}
                            title="COPY"
                            className="green_button_admin mt-4 m-1"
                            onClick={() => copyToClipBoard(data?.title)}>
                            {data?.title}
                          </button>
                        );
                      })
                    : ''}
                </div>
              </div>
              <div className="bg-blue4 mr_normal_padding  margin-bottom-50">
                <div className="fonts_inner justify_content_right align-item-center">
                  <div
                    onClick={() => setShowTokenList(true)}
                    className="btn-default1 btn buttn-small border-radius5 text-center text-white border-default mr_normal_Margin_r">
                    VIEW TOKEN LIST
                  </div>

                  <div className="form-btns d-flex align-items-center mt-4">
                    {id ? (
                      <button
                        type="button"
                        className="green_button_admin"
                        onClick={exportHtmlUpdate}>
                        Update
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="green_button_admin"
                        onClick={exportHtmlUpdate}>
                        Save
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {loading ? <Loader /> : null}
    </div>
  );
};

export default BuildEmailTemplate;
