/**
@copyright  :   ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
@author     :   Shiv Charan Panjeta 
 
All Rights Reserved.
Proprietary and confidential :  All information contained here in is, and remains
the property of ToXSL Technologies Pvt. Ltd. and it's partners.
Unauthorized copying of this file, via any medium is strictly prohibited.
*/

import moment from 'moment';
import { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import DefaultEvent from '../../../assets/images/default_event.png';
import Loader from '../../../components/commoncomponents/loader/loader-large';
import * as adminServices from '../../../services/AuthServices';

export default function ViewManageActivity() {
  const location = useLocation();
  const navigate = useNavigate();
  const event_id = new URLSearchParams(location?.search).get('event_id');
  const id = new URLSearchParams(location?.search).get('id');
  const [loading, setLoading] = useState(false);
  const [eventDetail, setEventDetail] = useState('');

  useEffect(() => {
    getDetail();
  }, []);

  const getDetail = () => {
    setLoading(true);
    adminServices
      .getViewManageActivity(event_id, id)
      .then((res) => {
        if (res?.status === 200) {
          setLoading(false);
          const data = res?.data?.data;
          setEventDetail(data);
        }
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className="dash-main-content">
      <Container fluid>
        <div className="dash-page-content">
          <div className="dash-page-title">
            <Row>
              <Col xs={12} sm={12} md={12}>
                <div className="dash-title-bar d-flex align-items-center justify-content-between">
                  <div className="title-lft d-flex align-items-center">
                    <div className="dash-icn">
                      <i className="fas fa-file-word"></i>
                    </div>
                    <div className="dash-title-dis">
                      <h5>View Activity</h5>
                    </div>
                  </div>
                  <div className="title-rt-btn ">
                    <Link className="add-btn-grn" to="#" onClick={() => navigate(-1)}>
                      Back To Listing
                    </Link>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <div className="dash-content-card">
            <div className="view-detail-content">
              <Row>
                <Col md={12}></Col>
              </Row>
              <Row>
                <Col md={12}>
                  <p>
                    <strong>Title:</strong>
                    {eventDetail?.detail?.title}
                  </p>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <p>
                    <strong>Description:</strong>
                    {eventDetail?.detail?.description}
                  </p>
                </Col>
              </Row>

              <Row>
                <Col md={12}>
                  <p>
                    <strong>Activity Date: From </strong>
                    {moment(eventDetail?.detail?.from_event_date).format('DD-MM-YYYY hh:mm:ss')}
                    <strong> To </strong>
                    {eventDetail?.detail?.to_event_date == '0000-00-00 00:00:00'
                      ? ' N/A '
                      : moment(eventDetail?.detail?.to_event_date).format('Do MMMM YYYY')}{' '}
                  </p>
                </Col>
                <div className="d-flex flex-wrap align-item-center">
                  <div className="border me-2">
                    <img
                      width="100px"
                      height="100px"
                      src={
                        eventDetail?.image_url !== ''
                          ? process.env.REACT_APP_API_DOMAIN + eventDetail?.image_url
                          : DefaultEvent
                      }
                      alt="img1"
                    />
                  </div>
                </div>
                <Col md={12}>
                  <p>
                    <strong>Status: </strong>
                    {eventDetail?.detail?.status == 1 ? ' Enable' : ' Disable'}
                  </p>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </Container>
      {loading && <Loader />}
    </div>
  );
}
