import { useFormik } from 'formik';
import moment from 'moment';
import { useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import AsyncSelect from 'react-select/async';
import * as yup from 'yup';
import { getUser, saveStepOne } from '../../../../services/InstituteServices';
import { citiesDropDown } from '../../../../services/UserServices';
import './steps.scss';

function Step1() {
  const { userId } = useParams();
  const navigate = useNavigate();
  const phoneRegExp = /^(\+\d{1,3}[- ]?)?\d{10}$/;
  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
    setFieldTouched
  } = useFormik({
    initialValues: {
      dob: '',
      address: '',
      relationship: '',
      gender: 'male',
      mobile: '',
      website: ''
    },
    validationSchema: yup.object().shape({
      dob: yup.string().required().label('DOB'),
      mobile: yup
        .string()
        .required()
        .label('Phone number')
        .matches(phoneRegExp, 'Invalid mobile number')
        .trim(),
      address: yup.object().shape({
        value: yup.string().required('This field is required'),
        label: yup.string().required()
      }),
      website: yup.string().url('Must be a valid URL')
    }),
    onSubmit: async (values) => {
      const payload = new FormData();
      payload.append('user_id', userId);
      payload.append('dob', values.dob);
      payload.append('gender', values.gender);
      payload.append('marital_status', values.relationship);
      payload.append('location', values.address?.value);
      payload.append('mobile', values.mobile);
      payload.append('website', values.website);
      payload.append('step', 1);
      try {
        await saveStepOne(payload);
        navigate('/institute-register/step/2/' + userId);
      } catch (error) {
        console.error(error);
      }
    }
  });

  useEffect(() => {
    (async () => {
      try {
        const response = await getUser(userId);
        setFieldValue('address', {
          label: response.data?.data?.city,
          value: response.data?.data?.city_id
        });
      } catch (error) {
        console.error(error);
      }
    })();
  }, [userId]);

  const loadLocationOptions = async (inputValue) => {
    const res = inputValue && (await citiesDropDown(inputValue));
    let array = await res?.data?.data?.map((data) => {
      return {
        label: data?.city,
        value: data?.city_id
      };
    });
    return array;
  };

  return (
    <>
      <section className="steps-area step-one-area pt-5 pb-5">
        <div className="row">
          <div className="col-sm-4 col-lg-3 left-area left-side">
            <div className="about-you">
              <h1 className="mb-0">About You</h1>
              <h6>Basic information</h6>
              <p>Your basic information let others find you more easily.</p>
            </div>
          </div>

          <div className="col-sm-8 col-lg-9 right-area">
            <div className="steps-block">
              <ul className="nav nav-tabs">
                <li className="current">
                  <span className="step-count">Step 1</span>
                  <span className="step-label">General information</span>
                </li>
                <li className="career-goals">
                  <Link to={'/institute-register/step/2/' + userId}>
                    <span className="step-count">Step 2</span>
                    <span className="step-label">Career Goals</span>
                  </Link>
                </li>
                <li>
                  <Link to={'/institute-register/step/3/' + userId}>
                    <span className="step-count">Step 3</span>
                    <span className="step-label">Educational Information</span>
                  </Link>
                </li>
                <li>
                  <Link to={'/institute-register/step/4/' + userId}>
                    <span className="step-count">Step 4</span>
                    <span className="step-label">Professional Information</span>
                  </Link>
                </li>
                <li>
                  <Link to={'/institute-register/step/5/' + userId}>
                    <span className="step-count">Step 5</span>
                    <span className="step-label">Upload profile picture</span>
                  </Link>
                </li>
              </ul>
            </div>
            <div className="data-box registeration-form">
              <form>
                <div className="form-inner clearfix">
                  <div className="row">
                    <div className="col-sm-4">
                      <div className="form-group">
                      <label for="month">
                            Date of birth <span className="required-field">*</span>
                          </label>
                        <div className="date-cal-card">
                          <div className=" w-100 input-group">
                            <input
                              type="date"
                              className="form-control hasDatepicker tooltipstered"
                              name="dob"
                              id="dob"
                              value={values.dob}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              max={moment().subtract(15, 'y').format('YYYY-MM-DD')}
                              placeholder="MM / DD / YY"
                            />
                          </div>
                          <span className="month-icon"></span>
                        </div>
                        <p className="text-danger">{touched.dob && errors.dob}</p>
                      </div>
                      
                    </div>
                    <div className="col-sm-4">
                      <div className="form-group">
                        <label for="gender">
                          Gender <span className="required-field">*</span>
                        </label>
                        <select
                          name="gender"
                          value={values.gender}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className="form-control tooltipstered"
                          id="gender">
                          <option value="male" selected="selected">
                            Male
                          </option>
                          <option value="female">Female</option>
                          <option value="other">Other</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <div className="form-group">
                        <label for="relationship">Relationship Status</label>
                        <select
                          name="relationship"
                          value={values.relationship}
                          id="marital_status"
                          className="form-control tooltipstered"
                          onChange={handleChange}
                          onBlur={handleBlur}>
                          <option value="">-Select-</option>
                          <option value="single">Single</option>
                          <option value="in_relation">In a relationship</option>
                          <option value="engaged">Engaged</option>
                          <option value="married">Married</option>
                          <option value="open_relationship">In an open relationship</option>
                          <option value="complicated">It's complicated</option>
                          <option value="separated">Separated</option>
                          <option value="divorced">Divorced</option>
                          <option value="widowed">Widowed</option>
                        </select>
                      </div>
                    </div>

                    <div className="col-sm-4">
                      <div className="form-group">
                        <label for="location">
                          Where you are currently located? <span className="required-field">*</span>
                        </label>
                        <AsyncSelect
                          isSearchable={true}
                          loadOptions={loadLocationOptions}
                          className="basic-single mt-1"
                          name="city"
                          value={values.address}
                          onChange={(e) => {
                            setFieldValue('address', e);
                          }}
                          onBlur={() => {
                            setFieldTouched('address', true);
                          }}
                          placeholder="Select Current location"
                        />
                        <span className="address-icon"></span>
                        <p className="text-danger">{touched.address && errors.address?.value}</p>
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <div className="form-group">
                        <label for="mobile">
                          Mobile<span className="required-field">*</span>
                        </label>
                        <input
                          type="text"
                          id="mobile"
                          name="mobile"
                          value={values.mobile}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className="form-control tooltipstered"
                          placeholder="XXXXXXXXX"
                        />
                        <span className="mobile-icon"></span>
                        <p className="text-danger">{touched.mobile && errors.mobile}</p>
                      </div>
                    </div>

                    <div className="col-sm-4">
                      <div className="form-group">
                        <label for="website">Website</label>
                        <input
                          type="text"
                          id="website"
                          name="website"
                          className="form-control tooltipstered"
                          value={values.website}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          placeholder="www.example.com"
                        />
                        <span className="website-icon"></span>
                        <p className="text-danger">{touched.website && errors.website}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="gray-footer clearfix">
                  <span className="float-start mandatory-label">
                    ( <span className="sign">*</span> ) Mandatory fields
                  </span>
                  <span className="float-end">
                    <button type="button" onClick={handleSubmit} className="add-btn-grn">
                      Save &amp; Next
                    </button>
                  </span>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Step1;
