/**
@copyright  :   ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
@author     :   Shiv Charan Panjeta 
 
All Rights Reserved.
Proprietary and confidential :  All information contained here in is, and remains
the property of ToXSL Technologies Pvt. Ltd. and it's partners.
Unauthorized copying of this file, via any medium is strictly prohibited.
*/

import { useEffect, useState } from 'react';
import { Alert, Button, Container } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Table from 'react-bootstrap/Table';
import ReactPaginate from 'react-paginate';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import NoDataFound from '../../components/commoncomponents/NoDataFound';
import Loader from '../../components/commoncomponents/loader/loader-large';
import useDocumentTitle from '../../globals/useDocumentTitle';
import { getAllFestJobList, setMultipleJobsInFestStatus } from '../../services/AuthServices';

export default function ListJob() {
  useDocumentTitle('AlmaBay');
  const [chekcedId, setCheckedId] = useState([]);
  const [allId, setAllId] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [orderBy, setOrderBy] = useState('ASC');
  const [shortOrderBy, setShortOrderBy] = useState('');
  const [totalCount, setTotalCount] = useState(0);
  const [list, setList] = useState([]);
  const [response, setResponse] = useState('');
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [filterState, setFilterState] = useState({
    job_id: '',
    job_title: '',
    job_company_name: '',
    job_status: ''
  });

  const [showalert, setShowAlert] = useState(false);
  const location = useLocation();
  let festData = location?.state;

  useEffect(() => {
    apiCall();
  }, []);

  useEffect(() => {
    const timeId = setTimeout(() => {
      // After 3 seconds set the show value to false
      setShowAlert(false);
    }, 3000);

    return () => {
      clearTimeout(timeId);
    };
  }, [showalert]);

  const apiCall = (festId, page = 1, order = 'ASC', sortField = '') => {
    setCurrentPage(page);
    setLoading(true);
    let body = {
      job_id: filterState?.job_id,
      job_status: filterState?.job_status,
      job_company_name:
        filterState?.job_company_name !== undefined ? filterState?.job_company_name.trim() : '',
      job_title: filterState?.job_title.trim()
    };

    if (festData) {
      getAllFestJobList(
        page,
        festData?.fest_id ? festData?.fest_id : festData?.id,
        festData?.user_id ? festData?.user_id : '',
        body
      ).then((res) => {
        setLoading(false);
        if (res?.status === 200) {
          setList(res?.data?.detail);
          setResponse(res?.data?.pager);
          setAllId(res?.data?.data?.viewlist?.map((data) => data?.id));
          setTotalCount(res?.data?.detail?.totalRecords);
        }
      });
    } else {
      getAllFestJobList(page, festId, '', body, order, sortField).then((res) => {
        setLoading(false);
        if (res?.status === 200) {
          setList(res?.data?.detail);
          setResponse(res?.data?.pager);
          setAllId(res?.data?.detail?.viewlist?.map((data) => data?.id));
          setTotalCount(res?.data?.detail?.totalRecords);
        }
      });
    }
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilterState({
      ...filterState,
      [name]: value
    });
  };

  const handleMultipleCheckBox = (id) => {
    if (chekcedId.includes(id)) {
      let filterId = chekcedId.filter((data) => data !== id);
      setCheckedId(filterId);
    } else {
      setCheckedId([...chekcedId, id]);
    }
  };

  const handleSelectAll = (e) => {
    if (e.target.checked === true) {
      setCheckedId(allId);
    } else {
      setCheckedId([]);
    }
  };

  const handlePageClick = (e) => {
    setCheckedId([]);
    setCurrentPage('', e.selected + 1);
    apiCall('', e.selected + 1, orderBy, shortOrderBy);
  };

  const sortHandler = (e, type) => {
    e.preventDefault();
    let currentSort = orderBy === 'ASC' ? 'DESC' : 'ASC';
    setOrderBy(currentSort);
    setShortOrderBy(type);
    apiCall('', '', currentSort, type);
  };

  const globalStateHandler = () => {
    setShowAlert(true);
  };
  const handlePerformAction = (e) => {
    if (chekcedId.length !== 0) {
      setShowAlert(false);
      let body = {
        id: chekcedId.toString(),
        status: e.target.value
      };
      let formData = new FormData();
      for (let i in body) {
        formData.append(`${i}`, body[i]);
      }
      setMultipleJobsInFestStatus(formData).then((res) => {
        if (res?.status === 200) {
          apiCall();
        }
      });
    } else {
      globalStateHandler();
    }
  };

  const handleNavigatetoApplicants = (e, data) => {
    e.preventDefault();
    navigate('/admin/jobfest/applicants', { state: data });
  };

  return (
    <>
      <div className="dash-main-content">
        <Container fluid>
          <div className="dash-page-content">
            <div className="dash-page-title">
              <Row>
                <Col xs={12} sm={12} md={12}>
                  <div className="dash-title-bar d-flex align-items-center justify-content-between">
                    <div className="title-lft d-flex align-items-center">
                      <div className="dash-icn">
                        <i className="fas fa-briefcase"></i>
                      </div>
                      <div className="dash-title-dis">
                        {festData ? (
                          <>
                            {festData?.user_id ? (
                              <h5>
                                Jobs Applied By{' '}
                                <span>
                                  {festData?.first_name} {festData?.last_name}
                                </span>{' '}
                                in <span>{festData?.JobFest_Title}</span>
                              </h5>
                            ) : (
                              <h5>
                                Jobs Listing in <span>{festData?.title}</span>
                              </h5>
                            )}
                          </>
                        ) : (
                          <h5>
                            All Jobs <span>Listing</span>
                          </h5>
                        )}

                        <h5>
                          Total Record(s):<span>{list?.totalRecords}</span>
                        </h5>
                      </div>
                    </div>
                    <div className="title-rt-btn d-flex align-items-center">
                      <Link className="add-btn-grn" to="/admin/jobfest/job/create">
                        Add New Job
                      </Link>
                      <div className="head-instite-sec ms-4">
                        <Form.Select
                          aria-label="Default select example"
                          onChange={(e) => {
                            handlePerformAction(e);
                          }}>
                          <option>Choose Action</option>
                          <option value="1">Enable</option>
                          <option value="0">Disable</option>
                        </Form.Select>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="dash-content-card">
              <Alert variant={'danger'} className={`${showalert ? '' : 'd-none'}`}>
                Please select any record
              </Alert>
              <div className="table-responsive">
                <Table className="custom-table-data" striped bordered hover>
                  <thead>
                    <tr>
                      <th>
                        <Form.Check
                          type="checkbox"
                          label=""
                          checked={
                            allId?.length !== 0 ? allId?.length === chekcedId?.length : false
                          }
                          onChange={handleSelectAll}
                        />
                      </th>
                      <th>
                        <Link
                          to="#"
                          onClick={(e) => {
                            sortHandler(e, 'id');
                          }}>
                          Id
                          <img src={require('../../assets/images/sort.png')} alt="sort" />
                        </Link>
                      </th>
                      <th>
                        <Link
                          to="#"
                          onClick={(e) => {
                            sortHandler(e, 'job_title');
                          }}>
                          Job Title
                          <img src={require('../../assets/images/sort.png')} alt="sort" />
                        </Link>
                      </th>
                      <th>
                        <Link
                          to="#"
                          onClick={(e) => {
                            sortHandler(e, 'company');
                          }}>
                          Company
                          <img src={require('../../assets/images/sort.png')} alt="sort" />
                        </Link>
                      </th>
                      <th>
                        <Link
                          to="#"
                          onClick={(e) => {
                            sortHandler(e, 'job_fest');
                          }}>
                          Job Fest
                          <img src={require('../../assets/images/sort.png')} alt="sort" />
                        </Link>
                      </th>
                      <th>Posted On</th>
                      <th>Applicants</th>
                      <th>
                        <Link
                          to="#"
                          onClick={(e) => {
                            sortHandler(e, 'fest_status');
                          }}>
                          Status
                          <img src={require('../../assets/images/sort.png')} alt="sort" />
                        </Link>
                      </th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="search-table">
                      <td></td>
                      <td>
                        <Form.Control
                          type="text"
                          placeholder="Id"
                          name="job_id"
                          onChange={handleFilterChange}
                        />
                      </td>
                      <td>
                        <Form.Control
                          type="text"
                          placeholder="Job Title"
                          name="job_title"
                          onChange={handleFilterChange}
                        />
                      </td>
                      <td>
                        <Form.Control
                          type="text"
                          placeholder="Company Name"
                          name="job_company_name"
                          onChange={handleFilterChange}
                        />
                      </td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td>
                        <Form.Select
                          aria-label="Default select example"
                          name="job_status"
                          onChange={handleFilterChange}>
                          <option>Action</option>
                          <option value="0">Disabled</option>
                          <option value="1">Enabled</option>
                        </Form.Select>
                      </td>
                      <td className="serac-btns text-center">
                        <Button className="main-btn-grn" type="button" onClick={apiCall}>
                          Search
                        </Button>
                      </td>
                    </tr>
                    {list?.viewlist?.length !== 0 ? (
                      <>
                        {list?.viewlist?.map((data, index) => {
                          return (
                            <tr key={index}>
                              <td>
                                <Form.Check
                                  type="checkbox"
                                  label=""
                                  id={`id${data?.id}`}
                                  checked={chekcedId?.includes(data?.id)}
                                  onChange={() => handleMultipleCheckBox(data?.id)}
                                />
                              </td>
                              <td>{data?.id}</td>
                              <td>{data?.job_title}</td>
                              <td>{data?.name}</td>
                              <td>
                                <Link to="" onClick={() => apiCall(data?.fest_id)}>
                                  {data?.title}
                                </Link>
                              </td>
                              <td>{data?.created_date}</td>
                              {data?.countApply != 0 ? (
                                <td>
                                  <Link to="" onClick={(e) => handleNavigatetoApplicants(e, data)}>
                                    {data?.countApply}Applicants(s)
                                  </Link>
                                </td>
                              ) : (
                                <tr>
                                  <td colSpan={8}>"No Applicants(s)"</td>
                                </tr>
                              )}

                              <td>{data?.status == 1 ? 'Enabled' : 'Disabled'}</td>
                              <td>
                                <Link
                                  className="search-btn"
                                  to=""
                                  onClick={(e) => {
                                    e.preventDefault();
                                    navigate('/admin/jobfest/job/jobDetail', { state: data?.id });
                                  }}>
                                  <i className="fas fa-search-plus" title="VIEW"></i>
                                </Link>

                                <Link
                                  className="edit-btn"
                                  to=""
                                  onClick={(e) => {
                                    e.preventDefault();
                                    navigate('/admin/jobfest/job/create', { state: data?.id });
                                  }}>
                                  <i className="fas fa-edit" title="EDIT"></i>
                                </Link>
                              </td>
                            </tr>
                          );
                        })}{' '}
                      </>
                    ) : (
                      <tr>
                        <td colSpan={8}>
                          <NoDataFound text="No Jobs Found" />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
                <div className="react-page d-flex">
                  {totalCount > 30 ? (
                    <ReactPaginate
                      previousLabel={'<'}
                      nextLabel=">"
                      forcePage={currentPage - 1}
                      onPageChange={handlePageClick}
                      pageCount={Math.ceil(totalCount / 30)}
                      activeClassName={'page-link active'}
                    />
                  ) : (
                    ''
                  )}
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
      {loading ? <Loader /> : ''}
    </>
  );
}
