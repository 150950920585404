import { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { Link, useLocation } from 'react-router-dom';
// import * as adminService from '../../services/AuthServices';
import { getSocialPageCatDetail } from '../../../services/AuthServices';

export default function CategoryViewDetails() {
  const [catDetail, setCatDetail] = useState('');
  const location = useLocation();
  const data = location.state;
  useEffect(() => {
    if (data?.id) {
      getDetail(data?.id);
    }
  }, []);

  const getDetail = (id) => {
    getSocialPageCatDetail(id).then((res) => {
      if (res?.status === 200) {
        const data = res?.data?.data?.catInfo;
        setCatDetail(data);
      }
    });
  };

  return (
    <div className="dash-main-content">
      <Container fluid>
        <div className="dash-page-content">
          <div className="dash-page-title">
            <Row>
              <Col xs={12} sm={12} md={12}>
                <div className="dash-title-bar d-flex align-items-center justify-content-between">
                  <div className="title-lft d-flex align-items-center">
                    <div className="dash-title-dis">
                      <h5>
                        <span>View Page Category Details</span>
                      </h5>
                    </div>
                  </div>
                  <div className="title-rt-btn ">
                    <Link className="add-btn-grn" to="/admin/pages/list_cats">
                      Back To Listing
                    </Link>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <div className="dash-content-card">
            <div className="view-detail-content">
              <Row>
                <Col md={12}>
                  <p>
                    <strong>Category Name :</strong>
                    {catDetail?.name}
                  </p>
                </Col>
              </Row>

              <Row>
                <Col md={12}>
                  <p>
                    <strong>Category Level :</strong>
                    {catDetail?.parentName}
                  </p>
                </Col>
              </Row>

              <Row>
                <Col md={12}>
                  <p>
                    <strong>Status :</strong>
                    {catDetail?.active == 1 ? 'Enable' : 'Disable'}
                  </p>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}
