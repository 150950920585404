import moment from 'moment';
import { useEffect, useState } from 'react';
import {
  Col,
  Container,
  Dropdown,
  Form,
  Modal,
  OverlayTrigger, 
  Popover,
  Row
} from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import useDocumentTitle from '../../../globals/useDocumentTitle';
import showNotification from '../../../services/NotificationService';
import * as APISERVICES from '../../../services/SocialServices';
import HeaderLogin from '../../commoncomponents/HeaderLogin';
import GroupDiscover from '../../commoncomponents/newsfeeds/GroupDiscover';
import NewsSidebar from '../../commoncomponents/newsfeeds/NewsSidebar';
import SuggestMentor from '../../commoncomponents/newsfeeds/SuggestMentor';
import Upcoming from '../../commoncomponents/newsfeeds/Upcoming';
import WhatsNew from '../../commoncomponents/newsfeeds/WhatsNew';
import msgStyles from '../../webpages/user-pages/message.module.scss';
import SocialShare from './SocialShare';
import TopTabbings from './TopTabbings';

function CampusnewsDetails() {
  useDocumentTitle('Campus Detail | AlmaBay');
  const [detail, setDetail] = useState('');
  const [commentDetail, setCommentDetail] = useState([]);
  const [addComment, setAddComment] = useState('');
  const [loading, setLoading] = useState(false);
  const [currentId, setCurrentId] = useState('');
  const userDetail = useSelector((state) => state?.user?.details);
  const [shareModelShow, setShareModelShow] = useState(false);
  const [socialShareData, setSocialShareData] = useState(false);
  const [modalImg, setModalImg] = useState('');
  const shareModelClose = () => {
    setShareModelShow(false);
  };
  const { id } = useParams();

  useEffect(() => {
    getArticleDetails();
  }, []);

  const getArticleDetails = () => {
    setLoading(true);
    APISERVICES.getSingleCampusDetail(id).then((res) => {
      if (res?.status === 200) {
        setLoading(false);
        setDetail(res?.data?.data);
        setCurrentId(res?.data?.data?.user_id);
      } else {
        setLoading(false);
      }
    });

    APISERVICES.getCampusNewsComment(id).then((res) => {
      if (res?.status === 200) {
        setCommentDetail(res?.data?.data);
      }
    });
  };

  const commentHandler = (e) => {
    e.preventDefault();
    let formData = new FormData();
    if (addComment) {
      formData.append('news_id', id);
      formData.append('news_comment', addComment);
      APISERVICES.addCampusNewsCommnet(formData).then((res) => {
        if (res?.status == 200) {
          getArticleDetails();
          setAddComment('');
          showNotification('success', res?.data?.message);
        }
      });
    } else {
      showNotification('danger', "can't post blank comment");
    }
    //api call
  };

  const deleteHandler = (id, e) => {
    e.preventDefault();
    if (confirm('Do you want to delete your comment ?') == true) {
      APISERVICES.deleteCampusNewsComment(id).then((res) => {
        if (res?.status === 200) {
          getArticleDetails();
          showNotification('success', 'Comment removed successfully');
        }
      });
    } else {
      return;
    }
  };

  const HelpFullHandler = () => {
    let body = {
      news_id: id
    };
    APISERVICES.markCampusHelpFull(body).then((res) => {
      if (res?.status === 200) {
        showNotification('succcess', res?.data?.message);
        getArticleDetails();
      }
    });
  };

  const renderPopover = (props) => {
    let popOverData = props?.popper?.state?.options?.data;

    return (
      <Popover className="group-loader-card" id={`popover-basic`} {...props}>
        {/* <Popover.Header as="h3">Popover right</Popover.Header> */}
        <Popover.Body>
          <div className="loader-tooltip">
            <div className="loader-cover">
              <img
                src={process.env.REACT_APP_API_URL + popOverData?.users?.avtar}
                alt="img"
                className="loader-image"
              />
            </div>
            <div className="people-know-loader-dis d-flex align-items-top">
              <div className="people-knw-top d-flex align-items-center">
                <div className="people-icn me-3">
                  <img
                    src={process.env.REACT_APP_API_URL + popOverData?.users?.avtar}
                    alt="img"
                    className="loader-image"
                  />
                </div>
                <div className="people-hd">
                  <h3>{popOverData?.users?.first_name + ' ' + popOverData?.users?.last_name}</h3>
                </div>
              </div>
            </div>
            <div className="people-knw-dis-popover pt-2">
              <p>
                <i className="fas fa-graduation-cap me-1"></i> Studied at{' '}
                <Link to="#">{popOverData?.userInfo[1]?.institute_name}</Link> in{' '}
                <Link to="#">{popOverData?.userInfo[1]?.to_year}</Link>
              </p>
              {/**have to add key for this, key not added from backend */}
              <p>
                <i className="fas fa-briefcase me-1"></i>Worked as{' '}
                <Link to="#">{popOverData?.userInfo[2]?.designation}</Link> {' at '}{' '}
                {/**have to add key for this, key not added from backend */}
                <Link to="#">{popOverData?.userInfo[2]?.organisation}</Link>{' '}
                {/**have to add key for this, key not added from backend */}
              </p>
            </div>
          </div>
        </Popover.Body>
      </Popover>
    );
  };

  const showModal = (src) => {
    let modal = document.getElementById('myModal');
    modal.style.display = 'block';
    setModalImg(src);
  };

  return (
    <>
      <HeaderLogin />
      <div className="header-lnk-feeds">
        <div className="container">
          <TopTabbings state={'social'} />
        </div>
      </div>
      <section className="news-feed-sec bg-grey py-4">
        <Container>
          <Row>
            <Col xs={12} md={2}>
              <NewsSidebar state="1" current="campus news" />
            </Col>
            <Col xs={12} md={7}>
              <div className="articals_div">
                <div className="single_artical_card p-3">
                  <div className="d-flex align-items-center mb-3">
                    <div className="media_img">
                      <img
                        src={process.env.REACT_APP_API_URL + detail?.institute_logo}
                        alt="user_img"
                        className="me-3"
                      />
                    </div>
                    <div className="media-body">
                      <h4>
                        <Link className="org-color" to="#">
                          {detail?.title}
                        </Link>
                      </h4>
                    </div>
                  </div>
                  <div>
                    <ul className="news-inr-lst">
                      <li>
                        <i className="fas fa-university me-1"></i> {detail?.institute_name}
                      </li>
                      <li>
                        <i className="far fa-clock me-1"></i>Added{' '}
                        {moment(detail?.date_created).fromNow()}
                      </li>
                    </ul>
                  </div>
                  <div>
                    <p
                      className="description"
                      dangerouslySetInnerHTML={{ __html: detail?.description }}></p>
                  </div>
                  {detail?.attachment?.url && (
                    <div className="single-artical-img">
                      <img
                        onClick={() => {
                          showModal(process.env.REACT_APP_API_URL + detail?.attachment?.url);
                        }}
                        src={process.env.REACT_APP_API_URL + detail?.attachment?.url}
                        // className="mb-2"
                        className={msgStyles.myImg}
                        id="myImg"
                        alt="article-img"
                      />
                    </div>
                  )}

                  <div className="navigation-border">
                    <ul className="article-cmmnt-lnks d-flex ps-2">
                      <li className="active-helpful pe-3">
                        {detail?.is_self_liked ? (
                          <>
                            <i className="fa fa-heart "></i> Helpful{' '}
                            <span>
                              ({detail?.total_likes_count == 0 ? 0 : detail?.total_likes_count})
                            </span>
                          </>
                        ) : (
                          <Link to="javascript:void(0)" onClick={HelpFullHandler}>
                            <i className="fa fa-heart "></i> Helpful{' '}
                            <span>
                              ({detail?.total_likes_count == 0 ? 0 : detail?.total_likes_count})
                            </span>
                          </Link>
                        )}
                      </li>
                      <li className="share-drop-btn">
                        <Dropdown>
                          <Dropdown.Toggle className="pe-3" id="dropdown-basic">
                            <Link
                              onClick={() => {
                                setShareModelShow(true);
                                setSocialShareData(detail);
                              }}>
                              <i className="fa fa-share"></i> Share
                            </Link>
                          </Dropdown.Toggle>
                          {/* <Dropdown.Menu className="p-2">
                            <Dropdown.Item href="#/action-1">Facebook</Dropdown.Item>
                            <Dropdown.Item href="#/action-2">Twitter</Dropdown.Item>
                            <Dropdown.Item href="#/action-3">Google</Dropdown.Item>
                            <Dropdown.Item href="#/action-3">Linkedin</Dropdown.Item>
                          </Dropdown.Menu> */}
                        </Dropdown>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="articals_div ">
                <div className="leave-cmmnt-card-outr">
                  <h5 className="mb-2">Comments</h5>
                  {commentDetail?.length == 0 && (
                    <p>This News has been not commented yet, be the first to do it.</p>
                  )}
                  {commentDetail?.length !== 0 &&
                    commentDetail?.map((data, index) => {
                      return (
                        <div className="leave-cmmnt-lst" key={index}>
                          <>
                            <div className="leave-cmmnt-card bg-white d-flex align-items-top p-3 mb-2">
                              {userDetail?.id === data?.users?.user_id ? (
                                <Link
                                  className="close-btn"
                                  to="#"
                                  onClick={(e) => deleteHandler(data?.id, e)}>
                                  <i className="fas fa-times"></i>
                                </Link>
                              ) : userDetail?.id == currentId ? (
                                <Link
                                  className="close-btn"
                                  to="#"
                                  onClick={(e) => {
                                    deleteHandler(data?.id, e);
                                  }}>
                                  <i className="fas fa-times"></i>
                                </Link>
                              ) : (
                                ''
                              )}

                              <div className="leave-cmmnt-icn">
                                <OverlayTrigger
                                  trigger={['hover', 'focus']}
                                  placement="bottom"
                                  delay={{ show: 500, hide: 1000 }}
                                  data={data}
                                  popperConfig={{
                                    data
                                  }}
                                  shouldUpdatePosition={true}
                                  overlay={renderPopover}>
                                  <img
                                    src={process.env.REACT_APP_API_URL + data?.users?.avtar}
                                    alt="user_img"
                                  />
                                </OverlayTrigger>
                              </div>
                              <div className="leave-cmmnt-media">
                                <h4>
                                  <Link to={`/user/timeline/${data?.users?.encryption_id}`}>
                                    {data?.users?.first_name}
                                  </Link>
                                  <span>{moment(data?.added_on).fromNow()}</span>
                                </h4>
                                <div className="mt-2">
                                  <p>{data.QComment}</p>
                                </div>
                              </div>
                            </div>
                          </>
                        </div>
                      );
                    })}

                  <div className="leave-cmmnt-frm p-3 bg-white">
                    <h4 className="mb-3">Leave A comment</h4>
                    <Form.Group className="" controlId="exampleForm.ControlTextarea1">
                      <Form.Control
                        as="textarea"
                        placeholder="Write a comment"
                        rows={5}
                        onChange={(e) => setAddComment(e.target.value)}
                        value={addComment}
                      />
                    </Form.Group>
                    <div className="cmmnt-btn mt-4">
                      <button className="accnt-btn" type="button" onClick={commentHandler}>
                        Comment
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <Col xs={12} md={3}>
              <div>
                <SuggestMentor />
              </div>
              <div>
                <GroupDiscover />
              </div>
              <div>
                <WhatsNew />
              </div>
              <div>
                <Upcoming />
              </div>
            </Col>
          </Row>
        </Container>
        <Modal show={shareModelShow} onHide={shareModelClose}>
          <Modal.Header closeButton>
            <Modal.Title>share to</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <SocialShare
              facebookOptions={{
                url: process.env.REACT_APP_DOMAIN + 'user/jobs-details' + '/' + socialShareData.id,
                quote: socialShareData?.job_title
              }}
              twitterOptions={{
                url: process.env.REACT_APP_DOMAIN + 'user/jobs-details' + '/' + socialShareData.id,
                title: socialShareData?.job_title,
                hashtags: ['jobs', 'trends']
              }}
              linkedInOptions={{
                title: socialShareData?.job_title,
                summary: socialShareData?.job_info,
                url: process.env.REACT_APP_DOMAIN + 'user/jobs-details' + '/' + socialShareData.id,
                source:
                  process.env.REACT_APP_DOMAIN + 'user/jobs-details' + '/' + socialShareData.id
              }}
            />
          </Modal.Body>
        </Modal>
      </section>
      <div id="myModal" className={msgStyles.modal}>
        <div className={msgStyles.fancyboxSkin}>
          <div
            onClick={() => {
              let modal = document.getElementById('myModal');
              modal.style.display = 'none';
            }}
            className={msgStyles.close}>
            <span>
              <i className="fas fa-times"></i>
            </span>
          </div>
          <img src={modalImg} className={msgStyles.modalContent} id="img01" />
          <div id="caption" className={msgStyles.fancyboxTitle}>
            {/* <span></span> */}
          </div>
        </div>
      </div>
      {/* {loading ? <Loader /> : null} */}
    </>
  );
}

export default CampusnewsDetails;
