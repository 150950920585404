import { useEffect, useState } from 'react';
import { Col, Container, Row, Tab, Table, Tabs } from 'react-bootstrap';
import ReactPaginate from 'react-paginate';
import { useLocation, useNavigate } from 'react-router-dom';
import Loader from '../../components/commoncomponents/loader/loader-large';
import { getSingleCronJobDetail, logDetail } from '../../services/AuthServices';

let per_page = '';
let total = '';
const CronJobsDetails = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { id } = location?.state;
  const [cronJobDetail, setCronJobDetail] = useState(null);
  const [jobLogDetail, setJobLogDetail] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);

  useEffect(() => {
    /**
     * an IIFE to invoke after effect runs and runs right after invocation
     */
    (async () => {
      try {
        setLoading(true);
        const response = await getSingleCronJobDetail(id);
        const logResp = await logDetail(id, page, per_page);
        if (response?.status === 200 && logResp.status === 200) {
          setLoading(false);
          setCronJobDetail(response.data?.detail);
          setJobLogDetail(logResp.data?.data);
          per_page = logResp.data?.pager?.perPage;
          total = logResp.data?.pager?.total;
        }
      } catch (error) {
        setLoading(false);
        console.error(error);
      }
    })();
  }, [page]);

  /**
   * a switch case to display status of the logs
   * @param {*} state_id
   * @returns
   */
  const logStatus = (state_id) => {
    switch (state_id) {
      case '0':
        return 'Pending';
      case '1':
        return 'Completed';
      case '2':
        return 'In-Progress';
      case '3':
        return 'Deleted';
      case '4':
        return 'Failed';
      default:
        return;
    }
  };

  /**
   * a function to navigate to see detail of individual log
   * @param {*} id
   */
  const getLogDetail = (log) => {
    navigate(`/admin/scheduler/cronjob/log/detail`, {
      state: { id: log?.id, cronjob: cronJobDetail?.id + ': ' + cronJobDetail?.command }
    });
  };

  return (
    <>
      <div className="dash-main-content">
        <Container fluid>
          <div className="dash-page-content">
            <div className="dash-page-title">
              <Row>
                <Col xs={12} sm={12} md={12}>
                  <div className="dash-title-bar d-flex align-items-center justify-content-between">
                    <div className="title-lft d-flex align-items-center">
                      <div className="dash-icn">
                        <i className="fas fa-suitcase"></i>
                      </div>
                      <div className="dash-title-dis">
                        <h5>Cronjobs</h5>
                        <h5>{/* Total Record(s): <span>{planList?.count}</span> */}</h5>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
            {/** single cronjob detail starts */}
            <div className="dash-content-card">
              <div className="table-responsive">
                <Table className="custom-table-data" striped bordered hover>
                  <tr className="cronjob_detail_table">
                    <th>ID</th>
                    <td>{cronJobDetail?.id}</td>
                  </tr>
                  <tr className="cronjob_detail_table">
                    <th>Title</th>
                    <td>{cronJobDetail?.title}</td>
                  </tr>
                  <tr className="cronjob_detail_table">
                    <th>Command</th>
                    <td>{cronJobDetail?.command}</td>
                  </tr>
                  <tr className="cronjob_detail_table">
                    <th>Type</th>
                    <td>Default</td>
                  </tr>
                  <tr className="cronjob_detail_table">
                    <th>Created On</th>
                    <td>{cronJobDetail?.created_on}</td>
                  </tr>
                </Table>
              </div>
            </div>
            {/** single cron job detail ends */}
            {/** cronjob log starts */}
            <Tabs defaultActiveKey={1} id="uncontrolled-tab-example">
              <Tab eventKey={1} title="Logs">
                <div className="dash-content-card">
                  <div className="table-responsive">
                    <Table className="custom-table-data" striped bordered hover>
                      <thead>
                        <tr>
                          <th>ID</th>
                          <th>Cronjob</th>
                          <th>State</th>
                          <th>Scheduled On</th>
                          <th>Executed On</th>
                          <th>Created On</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {jobLogDetail?.length > 0 ? (
                          jobLogDetail?.map((log, index) => {
                            return (
                              <tr key={index}>
                                <td>{log?.id}</td>
                                <td>
                                  {cronJobDetail?.id}: {cronJobDetail?.command}
                                </td>
                                <td>{logStatus(log?.state_id)}</td>
                                <td>{log?.scheduled_on}</td>
                                <td>{log?.executed_on}</td>
                                <td>{log?.created_on}</td>
                                <td>
                                  <i
                                    onClick={() => {
                                      getLogDetail(log);
                                    }}
                                    className="far fa-eye pointer"
                                  ></i>
                                </td>
                              </tr>
                            );
                          })
                        ) : (
                          <tr>
                            <td className="text-center" colSpan={7}>
                              Data not found!!
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  </div>
                  {/** pagination starts */}
                  {Math.ceil(total / 10) > 1 && (
                    <div className="react-page d-flex justify-content-center">
                      <ReactPaginate
                        previousLabel={'Prev'}
                        nextLabel={'Next'}
                        activeClassName={'page-link active'}
                        onPageChange={(props) => {
                          setPage(props.selected + 1);
                        }}
                        pageCount={Math.ceil(total / 10)}
                      />
                    </div>
                  )}
                  {/** pagination ends */}
                </div>
              </Tab>
              {/* <Tab eventKey={2} title="Activities">
                <div className="dash-content-card">
                  <div className="table-responsive">
                    <Table className="custom-table-data" striped bordered hover>
                      <thead>
                        <tr>
                          <th>ID</th>
                          <th>Content</th>
                          <th>Created On</th>
                          <th>Created By</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </div>
              </Tab> */}
            </Tabs>
            {/** cronjob log ends */}
          </div>
        </Container>
      </div>
      {loading && <Loader />}
    </>
  );
};

export default CronJobsDetails;
