/** @copyright : ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
 @author     : Shiv Charan Panjeta 

 All Rights Reserved.
 Proprietary and confidential :  All information contained herein is, and remains
 the property of ToXSL Technologies Pvt. Ltd. and its partners.
 Unauthorized copying of this file, via any medium is strictly prohibited.
*/
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { useEffect, useState } from 'react';
import { Button, Container } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Loader from '../../../components/commoncomponents/loader/loader-large';
import * as adminServices from '../../../services/AuthServices';
import showNotification from '../../../services/NotificationService';

export default function Layout() {
  const navigate = useNavigate();
  const [serachParam] = useSearchParams();
  const [detail, setDetail] = useState({
    title: '',
    sub_title: '',
    slider_banner_img: '',
    about_img: ''
  });
  const [loading, setLoading] = useState(false);
  const [clicked, setClicked] = useState(false);
  const [attachment, setAttachMent] = useState('');
  const [pickLayout, setPickLayout] = useState(1);
  const [singlefile, setSingleFile] = useState('');
  const [singlefilePreview, setSingleFilePreview] = useState('');

  useEffect(() => {
    getDetail();
  }, []);

  const getDetail = () => {
    setLoading(true);
    adminServices.getHomepageLayout().then((res) => {
      setLoading(false);
      if (res?.status === 200) {
        const data = res?.data?.data ? res.data.data : {};
        if (data) {
          setDetail({
            id: data?.id,
            institute_id: data?.institute_id || '',
            title: data?.title || '',
            sub_title: data?.sub_title || ''
          });
        }
        setPickLayout(data?.type_id || 1);
        setAttachMent(data?.slider_banner_img);
        setSingleFile(data?.slider_banner_img);
      }
    });
  };

  const onChangeHandler = (e) => {
    const { name, value } = e?.target;
    setDetail({ ...detail, [name]: value });
  };

  const submitHandler = (e) => {
    e.preventDefault();
    setClicked(true);

    setLoading(true);
    let formData = new FormData();
    formData.append('title', detail?.title);
    formData.append('sub_title', detail?.sub_title);
    formData.append('type_id', pickLayout);
    if (singlefile) {
      formData.append('slider_banner_img', singlefile);
    }
    adminServices.addHomepageLayout(formData).then((res) => {
      setLoading(false);
      if (res?.status === 200) {
        showNotification('success', res?.data?.message);
      }
    });
  };

  const handleFile = (e) => {
    if (e.target.files[0]) {
      setSingleFile(e.target.files[0]);
      setSingleFilePreview(URL.createObjectURL(e.target.files[0]));
    }
  };

  return (
    <>
      <div className="dash-main-content">
        <Container fluid>
          <div className="dash-page-content">
            <div className="image_radio_select mb-4">
              <Row>
                <Col lg={3} md={4} sm={6}>
                  <Form.Control
                    type="radio"
                    id="first-img-selct"
                    name="inst_imgselect"
                    className="d-none"
                    value={pickLayout}
                    checked={pickLayout == 1}
                    onChange={() => setPickLayout(1)}
                  />
                  <Form.Label htmlFor="first-img-selct">
                    <img
                      src={require('../../../assets/images/layout_1.jpg')}
                      alt="imng"
                      className="w-100"
                    />
                  </Form.Label>
                </Col>
                <Col lg={3} md={4} sm={6}>
                  <Form.Control
                    type="radio"
                    id="second-img-selct"
                    name="inst_imgselect"
                    className="d-none"
                    value={pickLayout}
                    checked={pickLayout == 2}
                    onChange={() => setPickLayout(2)}
                  />
                  <Form.Label htmlFor="second-img-selct">
                    <img
                      src={require('../../../assets/images/layout_2.jpg')}
                      alt="imng"
                      className="w-100"
                    />
                  </Form.Label>
                </Col>
                <Col lg={3} md={4} sm={6}>
                  <Form.Control
                    type="radio"
                    id="third-img-selct"
                    name="inst_imgselect"
                    className="d-none"
                    value={pickLayout}
                    checked={pickLayout == 3}
                    onChange={() => setPickLayout(3)}
                  />
                  <Form.Label htmlFor="third-img-selct">
                    <img
                      src={require('../../../assets/images/layout_3.jpg')}
                      alt="imng"
                      className="w-100"
                    />
                  </Form.Label>
                </Col>
              </Row>
            </div>

            <div className="dash-page-title">
              <Row>
                <Col xs={12} sm={12} md={12}>
                  <div className="dash-title-bar d-flex align-items-center justify-content-between">
                    <div className="title-lft d-flex align-items-center">
                      <div className="dash-title-dis">
                        <h5>Add Homepage Layout</h5>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="dash-content-card">
              <div className="cmmn-form">
                <Form>
                  {/* <Form.Group className="mb-3" controlId="formBasicName">
                                        <Form.Label>
                                            Select Institute <sup className="text-danger">*</sup>
                                        </Form.Label>
                                        <AsyncSelect
                                            className="basic-single"
                                            classNamePrefix="select"
                                            isSearchable={true}
                                            name="institute_id"
                                            value={value}
                                            loadOptions={loadInstituteOptions}
                                            onChange={(e) => {
                                                setValue(e), setDetail({ ...detail, ['institute_id']: e.value });
                                            }}
                                            placeholder="Search By Institute Name"
                                        />
                                        <div
                                            className={`${clicked && validate(detail)?.institute_id ? '' : 'd-none'
                                                } danger-frm `}>
                                            {clicked && validate(detail)?.institute_id}
                                        </div>
                                    </Form.Group> */}
                  <Form.Group className="mb-3" controlId="formBasicState">
                    <Form.Label>Title</Form.Label>
                    <Form.Control
                      type="text"
                      name="title"
                      value={detail?.title}
                      onChange={(e) => {
                        onChangeHandler(e);
                      }}
                      placeholder="Enter title "
                    />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formBasicName">
                    <Form.Label>Description</Form.Label>
                    {/* ck editor */}
                    <CKEditor
                      name="job_info"
                      editor={ClassicEditor}
                      data={detail?.sub_title}
                      config={{
                        toolbar: [
                          'bold',
                          'italic',
                          'numberedList',
                          'bulletedList',
                          'outdent',
                          'indent'
                        ]
                      }}
                      onChange={(event, editor) => {
                        const data = editor.getData();
                        setDetail((previous) => {
                          return {
                            ...previous,
                            ['sub_title']: data
                          };
                        });
                      }}
                    />
                    {/* end */}
                  </Form.Group>

                  <Form.Group controlId="formFile" className="attach-file-card">
                    <Form.Label>Banner Image</Form.Label>
                    <Form.Control
                      // multiple={pickLayout !== 1 ? false : true}
                      type="file"
                      name="slider_banner_img"
                      accept=".png,.jpg,.jpeg"
                      onChange={(e) => {
                        handleFile(e);
                      }}
                    />
                    <span className="attach-file-inr">
                      <i className="fas fa-paperclip me-2"></i>
                      Attach File
                    </span>
                  </Form.Group>

                  <Row>
                    <Col xs={2} sm={2} md={2}>
                      <div className=" d-flex">
                        {detail?.id && singlefile && !singlefilePreview ? (
                          <img
                            height={'100px'}
                            width={'100px'}
                            src={process.env.REACT_APP_API_DOMAIN + attachment}
                            alt="img"
                          />
                        ) : singlefile ? (
                          <img
                            height={'100px'}
                            width={'100px'}
                            src={singlefilePreview}
                            alt="img"
                            className="me-2"
                          />
                        ) : (
                          ''
                        )}
                      </div>
                    </Col>
                  </Row>

                  <div className="form-btns d-flex align-items-center mt-5">
                    <button
                      type="button"
                      className="green_button_admin"
                      onClick={(e) => {
                        submitHandler(e);
                      }}>
                      Save
                    </button>
                    <strong>OR</strong>
                    <Button
                      className="main-btn-red"
                      onClick={() => {
                        navigate(-1);
                      }}>
                      Cancel
                    </Button>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </Container>
      </div>

      {loading ? <Loader /> : ''}
    </>
  );
}
