import moment from 'moment';
import { useEffect, useState } from 'react';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import { Link, useLocation } from 'react-router-dom';
import { getReceivedRef, getRequestedRef, jobManageStatus } from '../../../services/JobServices';
import FooterMain from '../../commoncomponents/FooterMain';
import HeaderLogin from '../../commoncomponents/HeaderLogin';
import NewsSidebar from '../../commoncomponents/newsfeeds/NewsSidebar';
import { ReferralSkelton } from '../../commoncomponents/skelton/allskelton';
import TopTabbings from './TopTabbings';
import { Button } from 'react-bootstrap';
import showNotification from '../../../services/NotificationService';
import { ONE, TWO } from '../../../globals/constants';

function Referrals() {
  let location = useLocation();
  const activeTab = location?.state;
  const [requestedReferral, setRequestedReferral] = useState([]);
  const [recievedReferral, setRecievedReferral] = useState([]);

  const [loading, setLoading] = useState(false);
  const [totalReq, setTotalReq] = useState();
  const [totalRes, setTotalRes] = useState();
  /**tab 2 Referral Received state */
  const [totalRefferalReq, setTotalReferralReq] = useState();
  const [totalJobs, setTotalJobs] = useState();
  /** end */
  const [active, setActive] = useState(activeTab || 'first');
  useEffect(() => {
    getRefRequested();
    getRefReceived();
  }, []);

  const getRefRequested = () => {
    setLoading(true);
    getRequestedRef().then((res) => {
      if (res?.status === 200) {
        setLoading(false);
        setRequestedReferral(res?.data?.data ?? []);
        setTotalReq(res?.data?.totalJobs);
        setTotalRes(res?.data?.totalRequests);
      }
    });
  };

  const getRefReceived = () => {
    setLoading(true);
    getReceivedRef().then((res) => {
      setLoading(false);
      if (res?.status === 200) {
        setRecievedReferral(res?.data?.data ?? []);
        setTotalReferralReq(res?.data?.totalRequests);
        setTotalJobs(res?.data?.totalJobs);
      }
    });
  };

  const downloadHandler = (name, id) => {
    window.open(
      `${process.env.REACT_APP_API_DOMAIN}jobs/resumeDownload?resume_name=${name}&user_id=${id}`
    );
  };

  const jobManageAcceptDecline = (job_id, user_id, req_id, type, decision) => {
    setLoading(true);
    jobManageStatus(job_id, user_id, req_id, type, decision).then((res) => {
      setLoading(false);
      if (res?.status === 200) {
        showNotification('success', res?.data?.message);
        getRefReceived();
      }
    });
  };
  {
    /* 0-Pending, 1-Approved, 2-Declined, 3-Declined Due to Job Expire */
  }
  const statusName = (key) => {
    switch (key) {
      case '0':
        return 'Pending';
      case '1':
        return 'Approved';
      case '2':
        return 'Declined';
      case '3':
        return 'Declined';
    }
  };
  {
    /* css - > 0-Pending (Grey), 1-Approved (Green), 2-Declined (Red), 3-Declined Due to Job Expire (Red)*/
  }
  const StatusIdColor = (statusName) => {
    switch (statusName) {
      case '0':
        return 'status-pending';
      case '1':
        return 'status-approved';
      case '2':
        return 'status-declined';
      case '3':
        return 'status-declined';
    }
  };

  return (
    <>
      <HeaderLogin />
      <div className="header-lnk-feeds">
        <div className="container">
          <TopTabbings state={'feed'} />
        </div>
      </div>
      <section className="feed-sec bg-grey py-4">
        <div className="container">
          <Row>
            <Col xs={12} lg={3} xl={2}>
              <NewsSidebar state="0" current="referrals" />
            </Col>
            <Col xs={12} lg={9} xl={10} className="mb-4">
              <div className="recommend bg-white">
                <Tab.Container id="left-tabs-example" defaultActiveKey={active}>
                  <Nav className="mb-2" variant="pills">
                    <Nav.Item>
                      <Nav.Link
                        eventKey="first"
                        onClick={(e) => {
                          e.preventDefault();
                          getRefRequested(e);
                          setActive('first');
                        }}>
                        Referral Requested
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        eventKey="second"
                        onClick={(e) => {
                          e.preventDefault();
                          getRefReceived(e);
                          setActive('second');
                        }}>
                        Referral Received
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                  <Tab.Content>
                    <Tab.Pane eventKey="first">
                      <div className="recommend-list p-4">
                        <div className="recomm-job-hd mb-4">
                          {requestedReferral?.length ? (
                            <p>
                              You have sent {totalReq} Referral requests in {totalRes} jobs
                            </p>
                          ) : (
                            <p>You haven't requested any referral yet.</p>
                          )}
                        </div>
                        {loading ? (
                          <ReferralSkelton />
                        ) : requestedReferral && requestedReferral?.length ? (
                          requestedReferral?.map((data, index) => (
                            <div className="recommnd-card" key={index}>
                              <div className="recommnd-card-hd">
                                <h5>
                                  You have {requestedReferral?.length} referral requests for
                                  <Link to="#"> {data?.job_name}</Link>
                                </h5>
                              </div>
                              <div className="refferal-card">
                                <div className="referral-top-card">
                                  <div className="d-flex align-items-top">
                                    <Link to="#" className="referal-icn me-3">
                                      <img
                                        src={require('../../../assets/images/default-male-avatar.png')}
                                        alt="profile"
                                      />
                                    </Link>
                                    <div className="referaal-tp-dis ">
                                      <div className="d-flex align-items-start justify-content-between">
                                        <div>
                                          <h4>
                                            <Link to="#">{data?.referred_from_name}</Link>
                                          </h4>
                                          <p>{data?.message}</p>
                                        </div>

                                        <span className={StatusIdColor(data?.status)}>
                                          {statusName(data?.status)}
                                        </span>
                                      </div>

                                      <div className="requested-dis">
                                        <p>
                                          <b>Requested on:</b>{' '}
                                          {moment(data?.created).format('MMM DD,YYYY')}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))
                        ) : (
                          <></>
                        )}
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="second">
                      <div className="recommend-list p-4">
                        <div className="recomm-job-hd mb-4">
                          {recievedReferral?.length ? (
                            <p>
                              You have {totalRefferalReq} pending referral requests in {totalJobs}{' '}
                              jobs
                            </p>
                          ) : (
                            <p>You haven't received any requests.</p>
                          )}
                        </div>
                        {loading ? (
                          <ReferralSkelton />
                        ) : recievedReferral && recievedReferral?.length ? (
                          recievedReferral?.map((data, index) => (
                            <div className="recommnd-card" key={index}>
                              <div className="recommnd-card-hd">
                                <h5>
                                  You have {recievedReferral?.length} referral request for
                                  <Link to="#"> {data?.job_name}</Link>
                                </h5>
                              </div>
                              <div className="refferal-card">
                                <div className="referral-top-card">
                                  <div className="d-flex align-items-top">
                                    <Link
                                      to={`/user/timeline/${data?.hashed_id}`}
                                      className="referal-icn me-3">
                                      <img
                                        src={require('../../../assets/images/default-male-avatar.png')}
                                        alt="profile"
                                      />
                                    </Link>
                                    <div className="referaal-tp-dis ">
                                      <div className="d-flex align-items-start justify-content-between">
                                        <div>
                                          <h4>
                                            <Link to={`/user/timeline/${data?.hashed_id}`}>
                                              {data?.referred_to_name}
                                            </Link>
                                          </h4>
                                          <p>{data?.message}</p>
                                        </div>

                                        <span className={StatusIdColor(data?.status)}>
                                          {statusName(data?.status)}
                                        </span>
                                      </div>

                                      <div className="requested-dis">
                                        <a
                                          className=""
                                          onClick={(e) => {
                                            e.preventDefault();
                                            downloadHandler(data?.resume?.name, data?.hashed_id);
                                          }}
                                          href="#"
                                          rel="noreferrer">
                                          Download Resume
                                        </a>
                                        {data?.status == '1' ||
                                        data?.status == '2' ||
                                        data?.status == '3' ? (
                                          ''
                                        ) : (
                                          <div>
                                            <Button
                                              className="main-btn-grn m-1"
                                              onClick={() =>
                                                jobManageAcceptDecline(
                                                  data?.job_hash_id,
                                                  data?.user_hashed_id,
                                                  data?.request_hashed_id,
                                                  'request',
                                                  ONE
                                                )
                                              }>
                                              Approve
                                            </Button>
                                            <Button
                                              className="main-btn-grn m-2"
                                              onClick={() =>
                                                jobManageAcceptDecline(
                                                  data?.job_hash_id,
                                                  data?.user_hashed_id,
                                                  data?.request_hashed_id,
                                                  'request',
                                                  TWO
                                                )
                                              }>
                                              Decline
                                            </Button>
                                          </div>
                                        )}

                                        <p>
                                          <b>Requested on:</b>{' '}
                                          {moment(data?.created).format('MMM DD,YYYY')}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))
                        ) : (
                          <></>
                        )}
                      </div>
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </div>
            </Col>
          </Row>
        </div>
      </section>
      <FooterMain className={'colour-bg'} />
    </>
  );
}

export default Referrals;
