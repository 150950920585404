/**
@copyright  :   ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
@author     :   Shiv Charan Panjeta 
 
All Rights Reserved.
Proprietary and confidential :  All information contained here in is, and remains
the property of ToXSL Technologies Pvt. Ltd. and it's partners.
Unauthorized copying of this file, via any medium is strictly prohibited.
*/

import moment from 'moment';
import { useEffect, useState } from 'react';
import { Container, OverlayTrigger, Popover } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Table from 'react-bootstrap/Table';
import ReactPaginate from 'react-paginate';
import { Link, useNavigate } from 'react-router-dom';
import NoDataFound from '../../components/commoncomponents/NoDataFound';
import useDocumentTitle from '../../globals/useDocumentTitle';
import * as ADMINSERVICES from '../../services/AuthServices';

export default function ExportReport() {
  useDocumentTitle('Manage Reports | AlmaBay');
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [currentPage, setCurrentPage] = useState(0);
  const [reportList, setReportList] = useState([]);
  const [record, setRecord] = useState('');
  const [viewData, setViewData] = useState();

  let navigate = useNavigate();

  useEffect(() => {
    reportsListing();
  }, []);

  const truncate = (str, n) => {
    return str?.length > n ? str.substr(0, n - 1) + '...' : str;
  };

  const reportsListing = (pageNo = '1') => {
    setCurrentPage(pageNo);
    ADMINSERVICES.exportUserReport(pageNo).then((resp) => {
      if (resp.status === 200) {
        setReportList(resp?.data?.detail);
        setRecord(resp?.data?.pager?.total);
      }
    });
  };

  //paginate

  const handlePageClick = (a) => {
    let currentPage = a?.selected + 1;
    reportsListing(currentPage);
  };

  const viewUserDetails = (id) => {
    navigate(`/admin/users/view/${id}`);
  };
  const renderPopover = (props) => {
    let popOverData = props?.popper?.state?.options?.data;

    return (
      <Popover className="group-loader-card" id={`popover-basic`} {...props}>
        {/* <Popover.Header as="h3">Popover right</Popover.Header> */}
        <Popover.Body>{popOverData?.attribute}</Popover.Body>
      </Popover>
    );
  };

  const state = (value) => {
    switch (value) {
      case '0':
        return 'Pending';
      case '1':
        return 'In progress';
      case '2':
        return 'Completed';
    }
  };
  return (
    <>
      <div className="dash-main-content">
        <Container fluid>
          <div className="dash-page-content">
            <div className="dash-page-title">
              <Row>
                <Col xs={12} sm={12} md={12}>
                  <div className="dash-title-bar d-flex align-items-center justify-content-between">
                    <div className="title-lft d-flex align-items-center">
                      <div className="dash-icn">
                        <i className="fas fa-file"></i>
                      </div>
                      <div className="dash-title-dis">
                        <h5>
                          Export <span>Listing</span>
                        </h5>
                        <h5>
                          Total Record(s): <span>{record ? record : '0'}</span>
                        </h5>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="dash-content-card">
              <div className="table-responsive">
                <Table className="custom-table-data" striped bordered hover>
                  <thead>
                    <tr>
                      <th>Id</th>
                      <th>Label</th>
                      <th>Percentage</th>
                      <th>Applicable For</th>
                      <th>Requested On</th>
                      <th>State</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {reportList && reportList != 0 ? (
                      reportList?.map((data, index) => {
                        return (
                          <tr key={index}>
                            <td>{data?.id}</td>

                            <td>
                              <OverlayTrigger
                                trigger={['hover', 'focus']}
                                placement="bottom"
                                delay={{ show: 500, hide: 500 }}
                                data={data}
                                popperConfig={{
                                  data
                                }}
                                shouldUpdatePosition={true}
                                overlay={renderPopover}>
                                <Link to="#" id={data?.id}>
                                  {truncate(data?.attribute, 30)}
                                </Link>
                              </OverlayTrigger>
                            </td>
                            <td>{data?.percentage}%</td>
                            <td>{data?.model_type}</td>
                            <td>{moment(data?.created_on).format('lll')}</td>
                            <td>{state(data?.state_id)}</td>
                            <td>
                              <Form.Select
                                aria-label="Default select example"
                                onChange={() => {
                                  data?.percentage === '100' && window.location.assign(data?.name);
                                }}>
                                <option> - Select - </option>
                                {data?.percentage == '100' && <option value="1">Download</option>}
                              </Form.Select>
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr className="text-center">
                        <td colSpan={9}>
                          <NoDataFound />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
                <div className="react-page d-flex">
                  {record / 10 > 1 ? (
                    <ReactPaginate
                      previousLabel={currentPage > 0 ? '<' : ''}
                      nextLabel=">"
                      onPageChange={handlePageClick}
                      pageCount={record / 10}
                      activeClassName={'page-link active'}
                    />
                  ) : (
                    ''
                  )}
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>View Reports Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="report-view">
            <Table className="custom-table-vertical" striped hover>
              <tbody>
                <tr>
                  <th>Posted By</th>
                  <td>:</td>
                  <td>{viewData?.name}</td>
                </tr>
                <tr>
                  <th>Text</th>
                  <td>:</td>
                  <td>Text</td>
                </tr>
                <tr>
                  <th>Posted On</th>
                  <td>:</td>
                  <td>{moment(viewData?.timestamp).format('lll')}</td>
                </tr>
              </tbody>
            </Table>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
