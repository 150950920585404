import { useEffect, useState } from 'react';
import { Col, Form, Modal, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { resumeExtension } from '../../../globals/helper';
import * as APISERVICES from '../../../services/JobServices';
import showNotification from '../../../services/NotificationService';
import * as APISERVICE from '../../../services/UserServices';
import FooterMain from '../../commoncomponents/FooterMain';
import HeaderLogin from '../../commoncomponents/HeaderLogin';
import Loader from '../../commoncomponents/loader/loader-large';
import NewsSidebar from '../../commoncomponents/newsfeeds/NewsSidebar';
import ApplyForm from '../common-professional-comp/ApplyForm';
import RecomendModal from '../common-professional-comp/RecomendModal';
import TopTabbings from './TopTabbings';
import useDocumentTitle from '../../../globals/useDocumentTitle';
import { shareLinkAction } from '../../../redux/action';

function JobDetails() {
  const { id } = useParams();
  const location = useLocation();
  const result = useSelector((state) => state?.shareLink?.links);
  const dispatch = useDispatch();

  const userDetail = useSelector((state) => state?.user?.details);
  const [detail, setDetail] = useState('');

  useDocumentTitle(`${detail.job_title ? detail.job_title : 'Almabay Job Search'} | AlmaBay`);
  const [similarJobs, setSimilarJobs] = useState([]);
  const [applyForm, setApplyForm] = useState({
    to: ' ',
    subject: ' ',
    message: ' ',
    resume: ' '
  });
  const [savedResume, setSavedResume] = useState('');
  const [jobId, setJobId] = useState('');
  const [jobTitle, setJobTitle] = useState('');
  const [isChecked, setIsChecked] = useState(false);
  const [fileSizeValid, setFileSizeValid] = useState(false);
  const [resumeSelect, setResumeSelect] = useState(false);
  const [loading, setLoading] = useState(false);
  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);
  const [referalModelShow, setReferalModelShow] = useState(false);
  const [showReferralForm, setShowReferralForm] = useState(false);
  const [refDetail, setRefDetail] = useState({});
  const navigate = useNavigate();
  const [show3, setShow3] = useState(false);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);
  const [searchParams, setSearchParams] = useSearchParams();
  const [showDropdown, setShowDropdown] = useState(false);
  const data = searchParams.get('posted');
  const [recommentDetail, setRecommentDetail] = useState({
    preSelectedOptions: '',
    referralMsg: '',
    resumeOpt: '',
    resume: ''
  });
  const [isClicked, setIsClicked] = useState(false);
  useEffect(() => {
    getJobDetails(id);
    getSimilarJobs(id);

    getUserDetail();

    dispatch(shareLinkAction(id));
  }, [id]);

  const getJobDetails = (id) => {
    setLoading(true);
    APISERVICES.getSingleJobDetail(id).then((res) => {
      setLoading(false);
      if (res?.status === 200) {
        setDetail(res?.data?.data);
      }
    });
  };

  const getSimilarJobs = (id) => {
    APISERVICES.getSimilarJobList(id).then((res) => {
      if (res?.status === 200) {
        setSimilarJobs(res?.data?.data);
      }
    });
  };

  const getUserDetail = () => {
    APISERVICE.userBasicInfo().then((res) => {
      if (res?.status === 200) {
        setSavedResume(res?.data?.resume);
        setApplyForm({ ...applyForm, ['resume']: res?.data?.resume });
      }
    });
  };

  const onApplyStateHandler = (data) => {
    setJobId(data?.id);
    setJobTitle(data?.job_title);
    setApplyForm({
      to: data?.user
        ? data?.user?.first_name
          ? data?.user?.first_name + ' ' + data?.user?.last_name
          : data?.user?.username
        : data?.job_company_meta,
      subject: `Alma - Job application for ${data?.job_title}`,
      message: '',
      resume: savedResume
    });
    handleShow2();
  };

  const onChangeHandler = (e, rid = false) => {
    if (rid) {
      setApplyForm({ ...applyForm, ['resume']: savedResume });
    } else {
      const { name, value, type } = e.target;
      if (type == 'file') {
        let file = e.target.files[0];
        let fileSize = file.size / 1024;
        if (fileSize > 500) {
          setResumeSelect(false);
          setFileSizeValid(true);
          alert('Resume file size should be less than 500KB');
        } else {
          setFileSizeValid(false);
          if (resumeExtension.includes(e.target.files[0].type)) {
            setResumeSelect(true);
            setApplyForm({ ...applyForm, [name]: file });
          } else {
            setResumeSelect(false);
            showNotification('danger', 'invalid format');
          }
        }
      } else {
        setApplyForm({ ...applyForm, [name]: value });
      }
    }
  };

  const submitHandler = () => {
    let formData = new FormData();
    for (let i in applyForm) {
      formData.append(`${i}`, applyForm[i]);
    }
    APISERVICES.applyForJob(jobId, formData).then((res) => {
      if (res?.status === 200) {
        handleClose2();
        showNotification('success', res?.data?.message);
        navigate('/jobs');
      }
    });
  };
  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Direct referral increases the chance of getting a job.
    </Tooltip>
  );

  const recommendHandler = (e, rid = false) => {
    if (rid) {
      setRecommentDetail({ ...recommentDetail, ['resume']: savedResume });
    } else {
      const { name, value, type } = e.target;
      if (type == 'file') {
        let file = e.target.files[0];
        let fileSize = file.size / 1024;

        if (fileSize > 500) {
          setResumeSelect(false);
          setFileSizeValid(true);
          alert('Resume file size should be less than 500KB');
        } else {
          setFileSizeValid(false);
          if (resumeExtension.includes(e.target.files[0].type)) {
            setResumeSelect(true);
            setRecommentDetail({ ...recommentDetail, [name]: file });
          } else {
            setResumeSelect(false);
            alert(
              'Resume file format is not valid, kindly attach a valid file e.g doc,docx,rtf,odt,pdf'
            );
          }
        }
      } else {
        setRecommentDetail({ ...recommentDetail, [name]: value });
      }
    }
  };

  const referalModelClose = () => {
    setReferalModelShow(false);
  };
  const handleRequested = (data) => {
    setRecommentDetail({
      ...recommentDetail,
      ['preSelectedOptions']: data?.user_id
    });
    setShowReferralForm(true);
  };

  const fetchReferralDetail = (id) => {
    setReferalModelShow(true);
    setShowReferralForm(false);
    APISERVICES.getReferralDetail(id).then((res) => {
      if (res?.status == 200) {
        if (res?.data?.detail) {
          setRefDetail(res?.data?.detail);
        }
      }
    });
  };

  const validate = (values) => {
    let errors = {};
    if (!values.referralMsg?.trim()) {
      errors.referralMsg = 'Message field is empty or invalid input has been entered.';
    }
    if (recommentDetail?.resumeOpt == 'attach') {
      if (!values.resume.name) {
        errors.resume = 'Please attach your latest resume.';
      }
    }

    return errors;
  };

  const sendRefferal = (e, id) => {
    e.preventDefault();
    setIsClicked(true);
    if (!Object.keys(validate(recommentDetail)).length) {
      let formData = new FormData();
      for (let i in recommentDetail) {
        formData.append(`${i}`, recommentDetail[i]);
      }
      //recJobId
      APISERVICES.sendReferral(id, formData).then((res) => {
        if (res?.status == 200) {
          showNotification('success', res?.data?.message);
          setIsClicked(false);
          setReferalModelShow(false);
        }
      });
    }
  };
  const onRecommendClick = (e, id) => {
    e.preventDefault();
    setJobId(id);
    handleShow3();
  };

  const closeJobHandler = (e) => {
    e.preventDefault();
    if (confirm('Do you want to close job?') == true) {
      APISERVICES.closeJob(id).then((res) => {
        if (res?.status === 200) {
          showNotification('success', res?.data?.message);
          getJobDetails(id);
          setShowDropdown(!showDropdown);
        }
      });
    } else {
      return;
    }
  };

  return (
    <>
      <HeaderLogin />
      <div className="header-lnk-feeds">
        <div className="container">
          <TopTabbings state={'feed'} />
        </div>
      </div>
      <section className="feed-sec bg-grey py-4">
        <div className="container">
          <Row>
            <Col xs={12} lg={2}>
              <NewsSidebar state="0" current="" />
            </Col>
            <Col xs={12} lg={9} xl={7} className="mb-4">
              <div className="job-detail bg-white p-4" style={{ position: 'relative' }}>
                <div className="d-flex align-items-center justify-content-between">
                  <h3>{detail?.job_title}</h3>
                  {data === 'posted' && (
                    <i
                      onClick={() => setShowDropdown(!showDropdown)}
                      className="fas fa-chevron-down"></i>
                  )}
                </div>
                {showDropdown && (
                  <div className="d-flex align-items-center justify-content-between">
                    <div></div>
                    {detail.status == 1 ? (
                      <div
                        className="d-flex flex-column"
                        style={{ position: 'absolute', top: '55px', right: '27px' }}>
                        <span
                          onClick={(e) => closeJobHandler(e)}
                          style={{
                            border: '1px solid #e7e7e7',
                            width: '150px',
                            padding: '5px',
                            background: 'white',
                            cursor: 'pointer'
                          }}>
                          Close Job
                        </span>
                        <span
                          onClick={() => navigate(`/jobs/edit?id=${id}`)}
                          style={{
                            border: '1px solid #e7e7e7',
                            width: '150px',
                            padding: '5px',
                            background: 'white',
                            cursor: 'pointer'
                          }}>
                          Edit Job
                        </span>
                      </div>
                    ) : (
                      <div
                        className="d-flex flex-column"
                        style={{ position: 'absolute', top: '55px', right: '27px' }}>
                        <span
                          style={{
                            border: '1px solid #e7e7e7',
                            width: '150px',
                            padding: '5px',
                            background: 'white',
                            cursor: 'pointer'
                          }}>
                          Job Closed
                        </span>
                      </div>
                    )}
                  </div>
                )}
                <div className="job-detail-hd d-flex align-items-center justify-content-between mb-3">
                  <div className="job-hd-lft">
                    <h5>Company: {detail?.company_name}</h5>
                    <p>
                      Posted By:{' '}
                      {detail?.user?.username
                        ? detail?.user?.username
                        : detail?.user?.first_name || detail?.job_company_meta}
                      {/* {detail?.job_company_meta || detail?.user?.first_name || detail?.user?.username || detail?.username} */}
                    </p>
                  </div>

                  <div className="social-round">
                    <ul>
                      <li>
                        <Link target="_blank" className="facebook" to={result.facebook}>
                          <i className="fab fa-facebook-f"></i>
                        </Link>
                      </li>
                      <li>
                        <Link target="_blank" className="twitter" to={result.twitter}>
                          <i className="fab fa-twitter"></i>
                        </Link>
                      </li>

                      <li>
                        <Link target="_blank" className="linkdin" to={result.linkdin}>
                          <i className="fab fa-linkedin-in"></i>
                        </Link>
                      </li>
                    </ul>
                  </div>

                  {/* <SocialShare
                    facebookOptions={{
                      url: process.env.REACT_APP_DOMAIN + 'user/jobs-details' + '/' + id,
                      quote: detail?.job_title
                    }}
                    twitterOptions={{
                      url: process.env.REACT_APP_DOMAIN + 'user/jobs-details' + '/' + id,
                      title: detail?.job_title,
                      hashtags: ['jobs', 'trends']
                    }}
                    linkedInOptions={{
                      title: detail?.job_title,
                      summary: detail?.job_info,
                      url: process.env.REACT_APP_DOMAIN + 'user/jobs-details' + '/' + id,
                      source: process.env.REACT_APP_DOMAIN + 'user/jobs-details' + '/' + id
                    }}
                  /> */}
                </div>
                <div className="job-btn mt-2 mb-4">
                  {detail?.userid == userDetail?.id ? (
                    <></>
                  ) : !detail?.is_applied ? (
                    <Link
                      className="apply-btn"
                      onClick={() => {
                        onApplyStateHandler(detail);
                      }}>
                      <i className="fas fa-check me-1"></i>Apply
                    </Link>
                  ) : (
                    <Link className="">
                      <i className="fas fa-check me-1"></i>Already Applied
                    </Link>
                  )}
                  <Link
                    to="#"
                    onClick={(e) => {
                      onRecommendClick(e, detail?.id);
                    }}>
                    <i className="fas fa-thumbs-up me-1"></i>Recommend
                  </Link>
                </div>
                <div className="mb-4">
                  <ul className="job-recomm-addrs">
                    {detail?.city_name && (
                      <li title="Location">
                        <i className="fas fa-map-marker-alt"></i>
                        {detail?.city_name}
                      </li>
                    )}

                    {detail?.contact_number && (
                      <li title="Contact number">
                        <i className="fa fa-phone"></i>
                        {detail?.contact_number}
                      </li>
                    )}
                    {detail?.hr_email && (
                      <li title="Email ID">
                        <i className="fa fa-envelope"></i>
                        {detail?.hr_email}
                      </li>
                    )}

                    <li title="Positions open">
                      <i className="fas fa-users"></i>Positions open: {detail?.open_positions}
                    </li>
                    {detail?.max_ctc > 0 && detail?.max_ctc ? (
                      <li title="Salary Package">
                        <i className="fas fa-rupee-sign"></i>CTC :
                        {detail?.max_ctc ? detail?.min_ctc + '-' + detail?.max_ctc + `(Lacs)` : ''}
                      </li>
                    ) : (
                      ''
                    )}
                    <li title="Experience required">
                      <i className="fas fa-briefcase"></i>Experience:{detail?.min_exp} -{' '}
                      {detail?.max_exp} yrs.
                    </li>

                    <li title={detail?.job_types}>
                      <i className="fas fa-hourglass-end"></i>Job Type: {detail?.job_types}
                    </li>
                  </ul>
                </div>
                <div className="job-detail-dis">
                  <h4>
                    <strong>Description:</strong>
                  </h4>
                  <div dangerouslySetInnerHTML={{ __html: detail?.job_info }} />
                  {detail?.key_skills?.length > 0 ? (
                    <div className="key-skill">
                      <h4>Key Skills</h4>
                      <ul className="key-skill-tag">
                        {detail?.key_skills?.length > 0 &&
                          detail?.key_skills?.map((data) => <li key={data?.id}>{data?.name}</li>)}
                      </ul>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
                {detail?.userid !== userDetail?.id &&
                  detail.job_referred == 1 &&
                  detail?.jobAppliedStatus != 'already_applied' && (
                    <div className="refferal-flex">
                      <Link
                        to={`javascript:void(0)`}
                        className="refferal-link"
                        onClick={() => {
                          fetchReferralDetail(detail?.id);
                        }}>
                        Request for referral
                      </Link>
                      <OverlayTrigger
                        placement="top"
                        delay={{ show: 250, hide: 400 }}
                        overlay={renderTooltip}>
                        <i className="fa fa-question-circle"></i>
                      </OverlayTrigger>
                    </div>
                  )}
              </div>
            </Col>
            <Col xl={{ span: 3, offset: 0 }} lg={{ span: 9, offset: 2 }}>
              <div className="similar-job-card bg-white p-2">
                <h4 className="social-hd">Similar jobs</h4>
                <ul className="similar-job-lst">
                  {similarJobs && similarJobs?.length
                    ? similarJobs?.map((data, index) => (
                        <li key={index}>
                          <Link to={`/jobs/jobs-details/${data?.job_id}`}>
                            <h4>{data?.job_title}</h4>
                            <p>
                              <i className="fas fa-map-marker-alt"></i> {data?.city}
                            </p>
                          </Link>
                        </li>
                      ))
                    : 'No Similar Job Found'}
                </ul>
              </div>
            </Col>
          </Row>
        </div>
      </section>
      {/* recommended job modal */}
      <RecomendModal handleClose3={handleClose3} show3={show3} jobId={jobId} />
      {/* end of modal */}
      <FooterMain className={'colour-bg'} />
      {/* form */}
      <ApplyForm
        onChangeHandler={onChangeHandler}
        setIsChecked={setIsChecked}
        isChecked={isChecked}
        submitHandler={submitHandler}
        applyForm={applyForm}
        show2={show2}
        handleClose2={handleClose2}
        jobTitle={jobTitle}
        resumeSelect={resumeSelect}
        fileSizeValid={fileSizeValid}
        getUserDetail={getUserDetail}
      />
      <Modal show={referalModelShow} onHide={referalModelClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Request for referral</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="request-referal">
            {refDetail && refDetail?.length && refDetail[0]?.professionFriends?.length ? (
              refDetail?.map((data, index) => (
                <div className="request-ref d-flex align-items-top" key={index}>
                  <Link className="me-3" to={`javascript:void(0)`}>
                    <img
                      src={require('../../../assets/images/default-male-avatar.png')}
                      alt="img"
                    />
                  </Link>
                  <div className="ref-dis">
                    <div className="d-flex align-items-center justify-content-between">
                      <h4> {data?.professionFriends[0]?.name}</h4>
                      <div className="ref-rt-link d-flex align-items-center">
                        <i className="fas fa-users ms-1"></i>
                        <i className="fas fa-info ms-3"></i>
                      </div>
                    </div>
                    <p>
                      {data?.professionFriends[0]?.designation} (
                      {data?.professionFriends[0]?.year_from +
                        '-' +
                        data?.professionFriends[0]?.year_to}
                      )
                    </p>
                    <p>
                      <b>Location: </b>
                      {data?.professionFriends[0]?.location}
                    </p>
                    {showReferralForm ? (
                      <>
                        {' '}
                        <div className="ref-msg">
                          <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Control
                              as="textarea"
                              name="referralMsg"
                              type="text"
                              placeholder="Message"
                              onChange={(e) => {
                                recommendHandler(e, false);
                              }}
                            />
                            <div
                              className={`${
                                isClicked && validate(recommentDetail)?.referralMsg ? '' : 'd-none'
                              } danger-frm `}>
                              {isClicked && validate(recommentDetail)?.referralMsg}
                            </div>
                          </Form.Group>
                        </div>
                        <div>
                          {['radio'].map((type) => (
                            <div key={`default-${type}`} className="mb-3">
                              <div className="max-card">
                                <Form.Check
                                  type={type}
                                  id={`default-${type}`}
                                  label="Attach latest resume"
                                  name="group1"
                                  onChange={(e) => {
                                    setIsChecked(true);
                                    setRecommentDetail({
                                      ...recommentDetail,
                                      ['resumeOpt']: 'attach'
                                    });
                                  }}
                                />

                                {isChecked ? (
                                  <div className="upload-resume-frm mt-2">
                                    <Form.Group controlId="formFile" className="mb-3">
                                      <Form.Control
                                        type="file"
                                        accept="application/vnd.oasis.opendocument.text,application/*"
                                        name="resume"
                                        onChange={(e) => {
                                          recommendHandler(e, false);
                                        }}
                                      />
                                    </Form.Group>
                                  </div>
                                ) : (
                                  ''
                                )}
                                <p className="ms-4">Max upload size is 500kb</p>
                                <div
                                  className={`${
                                    isClicked && validate(recommentDetail)?.resume ? '' : 'd-none'
                                  } danger-frm `}>
                                  {isClicked && validate(recommentDetail)?.resume}
                                </div>
                              </div>

                              <div className="resume-card-link">
                                <Form.Check
                                  checked
                                  type={type}
                                  label="Use"
                                  id={`default-${type}`}
                                  name="group1"
                                  onChange={(e) => {
                                    setRecommentDetail({ ...recommentDetail, ['resumeOpt']: '' });
                                    setIsChecked(false);
                                    recommendHandler(e, true);
                                  }}
                                />
                                <a
                                  className="ms-4"
                                  href={`${process.env.REACT_APP_API_DOMAIN}jobs/resumeDownload?resume_name=${data?.jobResume?.name}&user_id=${data?.jobResume?.ID}`}
                                  rel="noreferrer">
                                  {data?.jobResume?.name}
                                </a>
                              </div>
                            </div>
                          ))}
                        </div>
                      </>
                    ) : (
                      <>
                        <button
                          className="main-btn mb-3"
                          type="button"
                          onClick={() => {
                            handleRequested(data?.professionFriends[0]);
                          }}>
                          REQUEST REFERRAL
                        </button>
                      </>
                    )}
                  </div>
                </div>
              ))
            ) : (
              <>At the moment, we can't find any referer for this job. Please check back later.</>
            )}

            {refDetail[0]?.professionFriends?.length ? (
              <button
                className="ref-blk-btn"
                onClick={(e) => {
                  sendRefferal(e, refDetail[0]?.jobInfo?.id);
                }}>
                <i className="fas fa-check me-1"></i> Ask for Referral
              </button>
            ) : (
              <></>
            )}
          </div>
        </Modal.Body>
      </Modal>
      {/* loader */}
      {loading ? <Loader /> : ''}
    </>
  );
}

export default JobDetails;
