import * as api from '../utils/requests';

export const newList = async (page = '') => {
  return await api.getReq(`/news/list?page=${page}`);
};

export const newAdd = async () => {
  return await api.PostReq(`/news/add`);
};

export const newEdit = async (id) => {
  return await api.PostReq(`/news/edit`);
};

export const newsDelete = async (id = '') => {
  return await api.delReq(`/news/delete?id=${id}`);
};

export const getWhatsNew = async () => {
  return await api.getReq(`/news/list`);
};

export const getUpcommingEvent = async () => {
  return await api.getReq(`/event/upcoming-list`);
};
export const getWhatsNewDetail = async (id) => {
  return await api.getReq(`news/show?id=${id}`);
};
export const addWhatsNewsComment = async (content_type, body) => {
  return await api.PostReq(`/news/commentOnArticle?content_type=${content_type}`, body);
};
export const removeNewsComment = async (id) => {
  return await api.delReq(`/news/removeArticleComment?id=${id}`);
};

export const getCommentList = async (id) => {
  return await api.getReq(`/news/commentList?id=${id}`);
};
export const getInviteList = async (event_id = '', search = '') => {
  return await api.getReq(`/event/getInviteeListForEvent?event_id=${event_id}&search=${search}`);
};

export const SearchWhatsNewInfo = async (body) => {
  return await api.PostReq(`/news/SearchWhatsNewInfo`, body);
};
