/**
@copyright  :   ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
@author     :   Shiv Charan Panjeta 
 
All Rights Reserved.
Proprietary and confidential :  All information contained here in is, and remains
the property of ToXSL Technologies Pvt. Ltd. and it's partners.
Unauthorized copying of this file, via any medium is strictly prohibited.
*/

import { useSelector } from 'react-redux';
import { Navigate, Route, Routes } from 'react-router-dom';
import CommunicationLogsDetailedReport from '../../components/webpages/communication/CommunicationLogsDetailedReport';
import CommunicationPage from '../../components/webpages/communication/CommunicationPage';
import ManageCommunicationsLogs from '../../components/webpages/communication/ManageCommunicationsLogs';
import useDocumentTitle from '../../globals/useDocumentTitle';
import CrashReport from '../../super-admin/App/CrashReport';
import SingleReportView from '../../super-admin/App/SingleReportView';
import Footer from '../../super-admin/CommonComponents/Footer';
import Header from '../../super-admin/CommonComponents/Header';
import SideBar from '../../super-admin/CommonComponents/Sidebar';
import AddMenuList from '../../super-admin/CommonComponents/webSettings/AddMenuList';
import AddQuickLinks from '../../super-admin/CommonComponents/webSettings/AddQuickLinks';
import ChidMenuList from '../../super-admin/CommonComponents/webSettings/ChidMenuList';
import EditChildMenuList from '../../super-admin/CommonComponents/webSettings/EditChildMenuList';
import Layout from '../../super-admin/CommonComponents/webSettings/Layout';
import MenuList from '../../super-admin/CommonComponents/webSettings/MenuList';
import QuickLinks from '../../super-admin/CommonComponents/webSettings/QuickLinks';
import AddAdmins from '../../super-admin/CompanyManage/AddAdmins';
import AddCompany from '../../super-admin/CompanyManage/AddCompany';
import AddLeaders from '../../super-admin/CompanyManage/AddLeaders';
import FeaturedCompanies from '../../super-admin/CompanyManage/FeaturedCompanies';
import ListCompanies from '../../super-admin/CompanyManage/ListCompanies';
import ManagLeaders from '../../super-admin/CompanyManage/ManagLeaders';
import ManageAdmins from '../../super-admin/CompanyManage/ManageAdmins';
import AddBanners from '../../super-admin/InstituteManage/AddBanners';
import AddCommunicationPlanForInstitute from '../../super-admin/InstituteManage/AddCommunicationPlanForInstitute';
import AddContactPerson from '../../super-admin/InstituteManage/AddContactPerson';
import AddCourses from '../../super-admin/InstituteManage/AddCourses';
import AddDepartments from '../../super-admin/InstituteManage/AddDepartments';
import AddGallery from '../../super-admin/InstituteManage/AddGallery';
import AddInstitute from '../../super-admin/InstituteManage/AddInstitute';
import AdminPanelContent from '../../super-admin/InstituteManage/AdminPanelContent';
import AdminPanelContentAdd from '../../super-admin/InstituteManage/AdminPanelContentAdd';
import CommunicationPlanForInstitute from '../../super-admin/InstituteManage/CommunicationPlanForInstitute';
import ContactPerson from '../../super-admin/InstituteManage/ContactPerson';
import DomainConfig from '../../super-admin/InstituteManage/DomainConfig';
import GalleryList from '../../super-admin/InstituteManage/GalleryList';
import ListCourses from '../../super-admin/InstituteManage/ListCourses';
import ListDepartments from '../../super-admin/InstituteManage/ListDepartments';
import ListInstitutes from '../../super-admin/InstituteManage/ListInstitute';
import OpenPageContent from '../../super-admin/InstituteManage/OpenPageContent';
import UniversityList from '../../super-admin/InstituteManage/UniversityList';
import ViewContactPerson from '../../super-admin/InstituteManage/ViewContactPerson';
import Testimonial from '../../super-admin/InstituteManage/testimonial/Testimonial';
import TestimonialAdd from '../../super-admin/InstituteManage/testimonial/TestimonialAdd';
import AddJobFest from '../../super-admin/JobFestManage/AddJobFest';
import AddJobManage from '../../super-admin/JobFestManage/AddJobManage';
import JobFestjobDetail from '../../super-admin/JobFestManage/JobFestjobDetail';
import ListJob from '../../super-admin/JobFestManage/ListJob';
import ListJobFest from '../../super-admin/JobFestManage/ListJobFest';
import ListofApplicants from '../../super-admin/JobFestManage/ListofApplicants';
import ViewjobDetails from '../../super-admin/JobFestManage/ViewjobDetails';
import AddJob from '../../super-admin/JobManage/AddJob';
import AppliedDetails from '../../super-admin/JobManage/AppliedDetails';
import AppliedJobs from '../../super-admin/JobManage/AppliedJobs';
import JobDetials from '../../super-admin/JobManage/JobDetials';
import ListJobs from '../../super-admin/JobManage/ListJobs';
import ResumeSearch from '../../super-admin/JobManage/ResumeSearch';
import AddCity from '../../super-admin/Location/AddCity';
import AddCountry from '../../super-admin/Location/AddCountry';
import AddState from '../../super-admin/Location/AddState';
import City from '../../super-admin/Location/City';
import Country from '../../super-admin/Location/Country';
import State from '../../super-admin/Location/State';
import ViewCity from '../../super-admin/Location/ViewCity';
import ViewCountry from '../../super-admin/Location/ViewCountry';
import ViewState from '../../super-admin/Location/ViewState';
import AddEmailTemplate from '../../super-admin/ManageTemplate/AddEmailTemplate';
import AddManageTemplate from '../../super-admin/ManageTemplate/AddManageTemplate';
import AddSMSTemplate from '../../super-admin/ManageTemplate/AddSMSTemplate';
import AddcvTemplate from '../../super-admin/ManageTemplate/AddcvTemplate';
import AddcvtokenTemplate from '../../super-admin/ManageTemplate/AddcvtokenTemplate';
import AddofferTemplate from '../../super-admin/ManageTemplate/AddofferTemplate';
import AddtemplateNotifi from '../../super-admin/ManageTemplate/AddtemplateNotifi';
import AddwhatsappBusiness from '../../super-admin/ManageTemplate/AddwhatsappBusiness';
import AddwhatsappTemplate from '../../super-admin/ManageTemplate/AddwhatsappTemplate';
import ManageTemplate from '../../super-admin/ManageTemplate/ManageTemplate';
import AddCampusnews from '../../super-admin/Others/AddCampusnews';
import AddNewsNew from '../../super-admin/Others/AddNews';
import AddWhatsnews from '../../super-admin/Others/AddWhatsnews';
import AddCMsPage from '../../super-admin/Others/CMSPages/AddPage';
import CmsDetailView from '../../super-admin/Others/CMSPages/CmsDetailView';
import ListCMsPages from '../../super-admin/Others/CMSPages/ListCMsPages';
import ViewCampusNews from '../../super-admin/Others/CMSPages/ViewCampusNews';
import ViewWhatNews from '../../super-admin/Others/CMSPages/ViewWhatNews';
import CompousNews from '../../super-admin/Others/CompousNews';
import Contact from '../../super-admin/Others/Contact';
import ExportReport from '../../super-admin/Others/ExportReport';
import FAQ from '../../super-admin/Others/FAQ';
import Feedback from '../../super-admin/Others/Feedback';
import Import from '../../super-admin/Others/Import';
import ImportReport from '../../super-admin/Others/ImportReport';
import News from '../../super-admin/Others/News';
import Recruitment from '../../super-admin/Others/Recruitment';
import Reports from '../../super-admin/Others/Reports';
import ViewNewsDetail from '../../super-admin/Others/ViewNewsDetail';
import WhatsNew from '../../super-admin/Others/WhatsNew';
import AddRoles from '../../super-admin/Settings/AddRoles';
import AddadminUser from '../../super-admin/Settings/AddadminUser';
import ChangePassword from '../../super-admin/Settings/ChangePassword';
import ManageAdminUsers from '../../super-admin/Settings/ManageAdminUsers';
import ManageEmail from '../../super-admin/Settings/ManageEmail';
import RolesView from '../../super-admin/Settings/Role/RolesView';
import Roles from '../../super-admin/Settings/Roles';
import ViewReportDetails from '../../super-admin/Settings/ViewReportDetails';
import AddCategory from '../../super-admin/Shop/Category/AddCategory';
import CategoryList from '../../super-admin/Shop/Category/CategoryList';
import ViewCategory from '../../super-admin/Shop/Category/ViewCategory';
import OrderCreate from '../../super-admin/Shop/Orders/OrderCreate';
import OrderList from '../../super-admin/Shop/Orders/OrderList';
import OrderStatus from '../../super-admin/Shop/Orders/OrderStatus';
import OrdersView from '../../super-admin/Shop/Orders/OrdersView';
import AddImageProduct from '../../super-admin/Shop/Products/AddImageProduct';
import AddProduct from '../../super-admin/Shop/Products/AddProduct';
import ListProduct from '../../super-admin/Shop/Products/ListProduct';
import ShowImages from '../../super-admin/Shop/Products/ShowImages';
import AddChapter from '../../super-admin/Social/Chapters/AddChapter';
import ListChapters from '../../super-admin/Social/Chapters/ListChapters';
import AddActivity from '../../super-admin/Social/Events/AddActivity';
import AddEvent from '../../super-admin/Social/Events/AddEvent';
import AddEventInvitation from '../../super-admin/Social/Events/AddEventInvitation';
import AddEventPage from '../../super-admin/Social/Events/AddEventPage';
import AddEventSponsor from '../../super-admin/Social/Events/AddEventSponsor';
import AddTicket from '../../super-admin/Social/Events/AddTicket';
import EventBooking from '../../super-admin/Social/Events/EventBooking';
import EventBookingDetails from '../../super-admin/Social/Events/EventBookingDetails';
import EventSponsors from '../../super-admin/Social/Events/EventSponsors';
import EventStat from '../../super-admin/Social/Events/EventStat';
import EventTicket from '../../super-admin/Social/Events/EventTicket';
import ListEvents from '../../super-admin/Social/Events/ListEvents';
import ManageActivity from '../../super-admin/Social/Events/ManageAcitivity';
import Participants from '../../super-admin/Social/Events/Participants';
import SponsorRequest from '../../super-admin/Social/Events/SponsorRequest';
import ViewBookingDetails from '../../super-admin/Social/Events/ViewBookingDetails';
import ViewEventDetail from '../../super-admin/Social/Events/ViewEventDetail';
import ViewManageActivity from '../../super-admin/Social/Events/ViewManageActivity';
import ViewSponsorDetails from '../../super-admin/Social/Events/ViewSponsorDetails';
import AddGroup from '../../super-admin/Social/Groups/AddGroup';
import ListGroup from '../../super-admin/Social/Groups/ListGroup';
import MemberList from '../../super-admin/Social/MemberList';
import AddPage from '../../super-admin/Social/Pages/AddPage';
import AddmanageCategory from '../../super-admin/Social/Pages/AddmanageCategory';
import CategoryViewDetails from '../../super-admin/Social/Pages/CategoryViewDetails';
import ListPages from '../../super-admin/Social/Pages/ListPage';
import ManageCategory from '../../super-admin/Social/Pages/ManageCategory';
import ManagePages from '../../super-admin/Social/Pages/ManagePages';
import SuperAdminDashboard from '../../super-admin/SuperAdminDashboard';
import AddEducation from '../../super-admin/UserManage/AddEducation';
import AddManageCoupons from '../../super-admin/UserManage/AddManageCoupons';
import AddPrivilegeCard from '../../super-admin/UserManage/AddPrivilegeCard';
import AddUser from '../../super-admin/UserManage/AddUser';
import AddWork from '../../super-admin/UserManage/AddWork';
import TodayBirthdays from '../../super-admin/UserManage/BirthdayUser/TodayBirthdays';
import UpcomingBirthdays from '../../super-admin/UserManage/BirthdayUser/UpcomingBirthdays';
import DeletedUseres from '../../super-admin/UserManage/DeletedUseres';
import ListFaculty from '../../super-admin/UserManage/ListFaculty';
import ListUsers from '../../super-admin/UserManage/ListUsers';
import ManageCoupons from '../../super-admin/UserManage/ManageCoupons';
import PrivilegeCardPanel from '../../super-admin/UserManage/PrivilegeCardPanel';
import UserView from '../../super-admin/UserManage/ViewUser';
import AddAluminiImg from '../../super-admin/blogs/AddAluminiImg';
import AddBlog from '../../super-admin/blogs/AddBlog';
import Blog from '../../super-admin/blogs/Blog';
import ClubAboutPage from '../../super-admin/club/AboutClub';
import AddClub from '../../super-admin/club/AddClub';
import AddClubBlogs from '../../super-admin/club/AddClubBlogs';
import AddClubContactPerson from '../../super-admin/club/AddClubContactPerson';
import AddClubGallery from '../../super-admin/club/AddClubGallery';
import AddClubNews from '../../super-admin/club/AddClubNews';
import ClubBlog from '../../super-admin/club/ClubBlogs';
import ClubContactPerson from '../../super-admin/club/ClubContactPerson';
import ClubDomainConfig from '../../super-admin/club/ClubDomain';
import ClubGallery from '../../super-admin/club/ClubGallery';
import ClubList from '../../super-admin/club/ClubList';
import ClubNews from '../../super-admin/club/ClubNews';
import ClubViewContactPerson from '../../super-admin/club/ClubViewContactPersn';
import ManageBanner from '../../super-admin/club/ManageBanner';
import AddCommunicationPlan from '../../super-admin/communication/AddCommunicationPlan';
import CommunicationList from '../../super-admin/communication/CommunicationList';
import AddCronJobs from '../../super-admin/scheduler/AddCronJobs';
import CronJobsDetails from '../../super-admin/scheduler/CronJobsDetails';
import Cronjobs from '../../super-admin/scheduler/Cronjobs';
import LogDetail from '../../super-admin/scheduler/LogDetail';
import ListUsersVerification from '../../super-admin/UserManage/ListUsersVerification';

function AdminRoutes() {
  useDocumentTitle('AlmaBay');
  const userDetail = useSelector((state) => state?.user?.details);

  return (
    <>
      {userDetail?.is_admin && (
        <>
          <div className="dash-wrapper">
            <Header />
            <SideBar state="dashboard" />
            <Routes>
              {/* without login routes */}
              <Route path="/" element={<Navigate to="/admin/user/welcome" replace />} />
              <Route path="/*" element={<Navigate to="/admin/user/welcome" replace />} />
              {/* with login routes */}
              {/* Dashboard */}
              <Route path="/user/welcome" element={<SuperAdminDashboard />} />
              {/* Shop */}
              {/* shops/ Category*/}
              <Route path="/categories/create" element={<AddCategory />} />
              <Route path="/categories" element={<CategoryList />} />
              {/* shops/ orders*/}
              <Route path="/orders" element={<OrderList />} />
              <Route path="/orders/status" element={<OrderStatus />} />
              <Route path="/shops/order/create" element={<OrderCreate />} />
              <Route path="/shops/order/view" element={<OrdersView />} />
              {/* shops/ Product*/}
              <Route path="/products/create" element={<AddProduct />} />
              <Route path="/products" element={<ListProduct />} />
              <Route path="/shops/product/showImages" element={<ShowImages />} />
              <Route path="/shops/product/add-image" element={<AddImageProduct />} />
              {/* end */}
              {/*User Manage */}
              <Route path="/users/create" element={<AddUser />} />
              <Route path="/users/deletedUsers" element={<DeletedUseres />} />
              <Route path="/users/showallusers" element={<ListUsers />} />
              <Route path="/users/showVerifyUsers" element={<ListUsersVerification />} />
              <Route path="/users/view/:id" element={<UserView />} />
              {/*User Manage /birthday user  */}
              <Route path="/users/birthdays/today" element={<TodayBirthdays />} />
              <Route path="/users/birthdays/upcoming" element={<UpcomingBirthdays />} />
              <Route path="/users/showallfaculty" element={<ListFaculty />} />
              <Route path="/users/privilege_card" element={<PrivilegeCardPanel />} />
              <Route path="/users/add_privilege_card" element={<AddPrivilegeCard />} />
              <Route path="/users/coupons/" element={<ManageCoupons />} />
              <Route path="/user-manage/add_manage-coupons" element={<AddManageCoupons />} />
              <Route path="/users/education" element={<AddEducation />} />
              <Route path="/users/edit-education" element={<AddEducation />} />
              <Route path="/users/work/:user_id" element={<AddWork />} />
              <Route path="/users/edit-work/:user_id/:work_id" element={<AddWork />} />
              {/* end */}
              {/*Company Manage*/}
              <Route path="/companies/add" element={<AddCompany />} />
              <Route path="/companies/edit/:id" element={<AddCompany />} />
              <Route path="/companies/showlist" element={<ListCompanies />} />
              <Route path="/companies/jobshomepage" element={<FeaturedCompanies />} />
              <Route path="/companies/manage_admins/:id" element={<ManageAdmins />} />
              <Route path="/companies/manage_leaders/:id/:tim_id" element={<ManagLeaders />} />
              <Route path="/companies/add_admins/:id" element={<AddAdmins />} />
              <Route path="/companies/add_leaders/:id/:tim_id" element={<AddLeaders />} />
              {/* end */}
              {/* Social */}
              <Route path="/groups/manage/:grp_id" element={<MemberList state="group" />} />
              <Route path="/chapter/manage/:grp_id" element={<MemberList state="chapter" />} />
              {/*Social/event */}
              <Route path="/event/create" element={<AddEvent />} />
              <Route path="/event" element={<ListEvents />} />
              <Route path="/event/view" element={<ViewEventDetail />} />
              <Route path="/event/EventTicket" element={<EventTicket />} />
              <Route path="/event/AddTicket" element={<AddTicket />} />
              <Route path="/event/edit_ticket/:event_id/:ticket_id" element={<AddTicket />} />
              <Route path="/event/EventBooking" element={<EventBooking />} />
              <Route path="/event/SponsorRequest" element={<SponsorRequest />} />
              <Route
                path="/event/sponsorRequestView/:event_id/:id"
                element={<ViewSponsorDetails />}
              />
              <Route path="/event/EventSponsors" element={<EventSponsors />} />
              <Route path="/event/AddEventSponsor" element={<AddEventSponsor />} />
              <Route path="/event/editEventSponsor/:event_id/:id" element={<AddEventSponsor />} />
              <Route path="/event/EventStat" element={<EventStat />} />
              <Route path="/event/ManageActivity" element={<ManageActivity />} />
              <Route path="/event/activity_user" element={<Participants />} />
              <Route path="/event/ViewManageActivity" element={<ViewManageActivity />} />
              <Route path="/event/AddActivity" element={<AddActivity />} />
              <Route path="/event/AddEventInvitation" element={<AddEventInvitation />} />
              <Route path="/event/eventcreatepage" element={<AddEventPage />} />
              <Route path="/event/bookings/:id" element={<EventBookingDetails />} />
              <Route path="/event/view_booking/:id" element={<ViewBookingDetails />} />
              {/*Social/group */}
              <Route path="/groups/create" element={<AddGroup />} />
              <Route path="/groups" element={<ListGroup />} />
              {/*Social/pages  */}
              <Route path="/pages/add_page" element={<AddPage />} />
              <Route path="/social/pages/edit_page/:id" element={<AddPage />} />
              <Route path="/pages" element={<ListPages />} />
              <Route path="/pages/manage/:id" element={<ManagePages />} />
              <Route path="/pages/list_cats" element={<ManageCategory />} />
              <Route path="/pages/add_mang_cats" element={<AddmanageCategory />} />
              <Route path="/pages/view" element={<CategoryViewDetails />} />
              {/*Social/Chapter */}
              <Route path="/chapters/create" element={<AddChapter />} />
              <Route path="/chapters" element={<ListChapters />} />
              {/* end */}
              {/* JOb  Manage */}
              <Route path="/jobs/create" element={<AddJob />} />
              <Route path="/jobs" element={<ListJobs />} />
              <Route path="/jobs/resume" element={<ResumeSearch />} />
              <Route path="/jobs/detials" element={<JobDetials />} />
              <Route path="/jobs/applied_jobs" element={<AppliedJobs />} />
              <Route path="/jobs/applied_details" element={<AppliedDetails />} />
              {/* end */}
              {/* Institute Manage */}
              <Route path="/institute" element={<AddInstitute state="initial" />} />
              <Route path="/edit-institute/:id" element={<AddInstitute />} />
              <Route path="/institute/showAll" element={<ListInstitutes />} />
              <Route path="/institute/listDepartments" element={<ListDepartments />} />
              <Route path="/institute/edit-departments/:id/:depId" element={<AddDepartments />} />
              <Route path="/institute/add-departments/:id" element={<AddDepartments />} />
              <Route path="/institute/list-courses" element={<ListCourses />} />
              <Route path="/institute/courses/:depId" element={<ListCourses />} />
              <Route path="/institute/add-courses/:id" element={<AddCourses />} />
              <Route path="/institute/edit-courses/:id/:course_id" element={<AddCourses />} />
              <Route path="/institute/universities" element={<UniversityList />} />
              <Route path="/institute/domain-config/:ins_id" element={<DomainConfig />} />
              <Route
                path="/institute/communication-plan-for-institute"
                element={<CommunicationPlanForInstitute />}
              />
              <Route
                path="/institute/add-communication-plan-for-institute"
                element={<AddCommunicationPlanForInstitute />}
              />
              <Route path="/add-contact-person" element={<AddContactPerson />} />
              <Route path="/edit-contact-person/:id" element={<AddContactPerson />} />
              <Route path="/contact-person" element={<ContactPerson />} />
              <Route path="/view-contact-person" element={<ViewContactPerson />} />
              {/* end */}
              {/* others */}
              <Route path="/forms/feedback" element={<Feedback />} />
              <Route path="/forms/contact" element={<Contact />} />
              <Route path="/forms/recruitment" element={<Recruitment />} />
              <Route path="/forms/faq" element={<FAQ />} />
              <Route path="/forms/reports" element={<Reports />} />
              <Route path="/forms/exportReport" element={<ExportReport />} />
              <Route path="/forms/importReport" element={<ImportReport />} />
              {/* cms */}
              <Route path="/cms/create" element={<AddPage />} />
              <Route path="/cms" element={<ListPages />} />
              <Route path="/cms/view" element={<CmsDetailView />} />

              <Route path="/cms/list/create" element={<AddCMsPage />} />
              <Route path="/cms/list" element={<ListCMsPages />} />
              <Route path="/import" element={<Import />} />
              <Route path="/news" element={<WhatsNew />} />
              <Route path="/news/index/news" element={<News />} />

              {/* <Route path="/news/index/campus_news" element={<CompousNews />} /> */}
              <Route path="/news/index/add_campus_news" element={<AddCampusnews />} />
              <Route path="/news/index/view_campus_news" element={<ViewCampusNews />} />
              <Route path="/news/index/add-news" element={<AddNewsNew />} />

              <Route path="/news/index/what-add-news" element={<AddWhatsnews />} />
              <Route path="/news/index/campus_news" element={<CompousNews />} />
              <Route path="/news/index/view_what_news" element={<ViewWhatNews />} />
              <Route path="/news/index/view_news" element={<ViewNewsDetail />} />

              {/* end */}
              {/* LOCATION */}
              <Route path="/location/city" element={<City />} />
              <Route path="/location/add-city" element={<AddCity />} />
              <Route path="/location/edit-city" element={<AddCity />} />
              <Route path="/location/view-city" element={<ViewCity />} />
              <Route path="/location/country" element={<Country />} />
              <Route path="/location/add-country" element={<AddCountry />} />
              <Route path="/location/edit-country" element={<AddCountry />} />
              <Route path="/location/view-country" element={<ViewCountry />} />
              <Route path="/location/state" element={<State />} />
              <Route path="/location/add-state" element={<AddState />} />
              <Route path="/location/edit-state" element={<AddState />} />
              <Route path="/location/view-state" element={<ViewState />} />
              {/* end */}
              {/* polls*/}
              {/* <Route path="/polls/student" element={<Student/>} /> */}
              {/* polls*/}
              {/* JOb Fest  Manage components */}
              <Route path="/jobfest/create" element={<AddJobFest />} />
              <Route path="/jobfest/job/create" element={<AddJobManage />} />
              <Route path="/jobfest/view-detail" element={<ViewjobDetails />} />
              <Route path="/jobfest" element={<ListJobFest />} />
              <Route path="/jobfest/joblist" element={<ListJob />} />
              <Route path="/jobfest/applicants" element={<ListofApplicants />} />
              <Route path="/jobfest/job/jobDetail" element={<JobFestjobDetail />} />
              {/* end */}
              {/* app */}
              <Route path="/app" element={<CrashReport />} />
              <Route path="/app/view/:id" element={<SingleReportView />} />
              {/* end */}
              {/* Manage Communication Template */}
              <Route path="/ManageTemplate" element={<ManageTemplate />} />
              <Route path="/AddManageTemplate" element={<AddManageTemplate />} />
              <Route path="/AddtemplateNotifi" element={<AddtemplateNotifi />} />
              <Route path="/AddSMSTemplate" element={<AddSMSTemplate />} />
              <Route path="/add-whatsapp-template" element={<AddwhatsappTemplate />} />
              <Route path="/AddwhatsappBusiness" element={<AddwhatsappBusiness />} />
              <Route path="/AddofferTemplate" element={<AddofferTemplate />} />
              <Route path="/add-cvtoken-template" element={<AddcvtokenTemplate />} />
              <Route path="/add-cv-template" element={<AddcvTemplate />} />
              <Route path="/AddEmailTemplate" element={<AddEmailTemplate />} />
              {/* end */}
              {/* setting */}
              <Route path="/users/showAllAdmins" element={<ManageAdminUsers />} />
              <Route path="/users/email" element={<ManageEmail />} />
              <Route path="/users/roles" element={<Roles />} />
              <Route path="/users/roleview" element={<RolesView />} />
              <Route path="/users/changePassword" element={<ChangePassword />} />
              <Route path="/users/add-adminUsers" element={<AddadminUser />} />
              <Route path="/users/email-view/:id" element={<ViewReportDetails />} />
              <Route path="/users/add-roles" element={<AddRoles />} />
              <Route path="/view/category" element={<ViewCategory />} />
              {/* end */}
              {/* Testimonial */}
              <Route path="/add-testimonial" element={<TestimonialAdd />} />
              <Route path="/edit-testimonial" element={<TestimonialAdd />} />
              <Route path="/testimonial" element={<Testimonial />} />
              {/* content /template select  */}
              <Route path="/website-content" element={<AdminPanelContent />} />
              <Route path="/add-website-content" element={<AdminPanelContentAdd />} />
              <Route path="/edit-website-content" element={<AdminPanelContentAdd />} />
              <Route path="/gallery" element={<GalleryList />} />
              <Route path="/add-gallery" element={<AddGallery />} />

              {/* end */}
              {/* web setting */}
              <Route path="/homepage-layout" element={<Layout />} />
              <Route path="/menu-list" element={<MenuList />} />
              <Route path="/child-menu-list/:id" element={<ChidMenuList />} />
              <Route path="/add-menu-list" element={<AddMenuList />} />
              <Route path="/edit-child-menu-list" element={<EditChildMenuList />} />

              <Route path="/communication" element={<ManageCommunicationsLogs />} />
              <Route path="/communication/new-communication" element={<CommunicationPage />} />
              <Route
                path="/communication/communication-logs-detailed-report"
                element={<CommunicationLogsDetailedReport />}
              />
              {/* end */}
              {/* blog */}
              <Route path="/add/blog" element={<AddBlog />} />
              <Route path="/edit/blog" element={<AddBlog />} />
              <Route path="/blog" element={<Blog />} />
              {/* end */}
              {/* changes */}
              {/* <Route path="/add-banner" element={<AddBanners />} /> */}

              {/* alumini images background*/}
              <Route path="/add/alumini-images" element={<AddAluminiImg />} />
              {/* end */}
              {/* Manage Club */}

              <Route path="/club/blog/add/:club_id" element={<AddClubBlogs />} />
              <Route path="/club/blog/edit/:club_id/:id" element={<AddClubBlogs />} />
              <Route path="/club/blog/:club_id" element={<ClubBlog />} />

              {/*  */}
              <Route path="/club/about/page/:club_id" element={<ClubAboutPage />} />
              <Route path="/club/banner/:club_id" element={<ManageBanner />} />
              {/* as per discussed with client event section will not be there */}
              {/* <Route path="/club/event/add" element={<AddClubEvents />} />
              <Route path="/club/event/edit" element={<AddClubEvents />} />
              <Route path="/club/events/:club_id" element={<ClubEvents />} />
              <Route path="/club/events/view" element={<ViewClubEventDetail />} /> */}

              <Route path="/club/news/add/:club_id" element={<AddClubNews />} />
              <Route path="/club/news/edit/:club_id/:id" element={<AddClubNews />} />
              <Route path="/club/news/:club_id" element={<ClubNews />} />
              {/*  */}
              <Route path="/club/gallery/:club_id" element={<ClubGallery />} />
              <Route path="/club/edit-gallery/:club_id/:id" element={<AddClubGallery />} />
              <Route path="/club/add-gallery/:club_id" element={<AddClubGallery />} />

              <Route path="/club/contact-person/:club_id" element={<ClubContactPerson />} />
              <Route path="/club/add-contact-person/:club_id" element={<AddClubContactPerson />} />
              <Route
                path="/club/edit-contact-person/:club_id/:id"
                element={<AddClubContactPerson />}
              />
              <Route
                path="/club/view-contact-person/:club_id/:id"
                element={<ClubViewContactPerson />}
              />

              <Route path="/add-club" element={<AddClub />} />
              <Route path="/edit-club" element={<AddClub />} />
              <Route path="/club" element={<ClubList />} />
              <Route path="/club/domain-config/:club_id" element={<ClubDomainConfig />} />
              <Route path="/manage-communication/plan" element={<CommunicationList />} />
              {/** CRONJOB ROUTE STARTS */}
              <Route path="/scheduler/cronjob" element={<Cronjobs />} />
              <Route path="/scheduler/cronjob/add" element={<AddCronJobs />} />
              <Route path="/scheduler/cronjob/detail" element={<CronJobsDetails />} />
              <Route path="/scheduler/cronjob/log/detail" element={<LogDetail />} />
              {/** CRONJOB ROUTE ENDS */}
              <Route path="/manage-communication/add-plan" element={<AddCommunicationPlan />} />
              <Route path="/communication-plans" element={<CommunicationPlanForInstitute />} />
              <Route path="/quick-links" element={<QuickLinks />} />
              <Route path="/quick-links/add" element={<AddQuickLinks />} />
              <Route path="/quick-links/edit/:id" element={<AddQuickLinks />} />
              {/* end */}
              {/* add open page content */}
              <Route path="/add-open-page-content" element={<OpenPageContent />} />
              <Route
                path="/communication/communication-logs-report"
                element={<ManageCommunicationsLogs />}
              />
              <Route path="/communication" element={<CommunicationPage />} />
              <Route
                path="/communication/communication-logs-detailed-report"
                element={<CommunicationLogsDetailedReport />}
              />
              {/* end */}
            </Routes>
          </div>
          <Footer />
        </>
      )}
    </>
  );
}

export default AdminRoutes;
