import { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Iframe from 'react-iframe';
import { restrictAlpha } from '../../../globals/constants';
import { emailRegex } from '../../../globals/helper';
import { getClubContactDetail, getInTouchClub } from '../../../services/InstituteServices';
import showNotification from '../../../services/NotificationService';

function ContactUs() {
    const [state, setState] = useState({
        name: '',
        email: '',
        message: '',
        phone_number: ''
    });
    const [clicked, setClicked] = useState(false);
    const [loading, setLoading] = useState(true);
    const [detail, setDetail] = useState('');

    useEffect(() => {
        contactDetail();
    }, []);

    const contactDetail = () => {
        getClubContactDetail().then((res) => {
            setLoading(false)
            if (res?.status == 200) {
                setDetail(res?.data?.data);
            }
        });
    };

    const onChangeHandler = (e) => {
        const { name, value } = e.target;
        setState({ ...state, [name]: value });
    };

    const validate = (value) => {
        let err = {};
        if (!value.name.trim()) {
            err.name = 'This field is required';
        }
        if (!value.email.trim()) {
            err.email = 'This field is required';
        } else if (!emailRegex.test(value?.email)) {
            err.email = 'please enter valid email';
        }
        if (!value.phone_number) {
            err.phone_number = 'This field is required';
        } else if (value.phone_number?.length < 10) {
            err.phone_number = 'Please enter valid phone number';
        }

        return err;
    };

    const submiHandler = (e) => {
        e.preventDefault();
        //api call
        setClicked(true);
        if (!Object.keys(validate(state))?.length) {
            setLoading(true);
            getInTouchClub(state).then((res) => {
                setLoading(false);
                setClicked(false);
                if (res?.status == 200) {
                    setState({
                        name: '',
                        email: '',
                        message: '',
                        phone_number: ''
                    });
                    showNotification('success', res?.data?.message);
                }
            });
        }
    };

    return (
        <div className="wrappermain_outer">
            <section
                className="breadcrum-banner"
                style={{
                    backgroundImage: `url(${process.env.REACT_APP_API_URL + detail?.banner_image})`
                }}
            >
                <Container>
                    <Row>
                        <Col sm={12}>
                            <h1 className="Text-white">Contact Us</h1>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className="inst-contact-sec py-5">
                <Container>
                    <div className="inst-cntact-inr">
                        <Row>
                            <Col lg={7} md={12}>
                                {/* {(instituteDetail && instituteDetail?.address) ||
                                    instituteDetail?.admin_email ||
                                    instituteDetail?.phone ? (
                                    <div className="contact-lft-card mb-4">
                                        <h4>Contact Us</h4>

                                        <ul className="contact-dtl-list">
                                            {instituteDetail?.address && (
                                                <li>
                                                    <i className="fas fa-map-marker-alt"></i>
                                                    {instituteDetail?.address}
                                                </li>
                                            )}
                                            {instituteDetail?.admin_email && (
                                                <li>
                                                    <i className="fas fa-envelope"></i>
                                                    {instituteDetail?.admin_email}
                                                </li>
                                            )}
                                            {instituteDetail?.phone && (
                                                <li>
                                                    <i className="fas fa-phone"></i>
                                                    {instituteDetail?.phone}
                                                </li>
                                            )}
                                        </ul>
                                        <br />
                                        <div className="cntct-social">
                                            <Link to="#">
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                                                    <path
                                                        d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 
                                            225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z"
                                                    />
                                                </svg>
                                            </Link>
                                            <Link to="#">
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                                    <path
                                                        d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 
                                            141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 
                                            74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 
                                            27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 
                                            36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 
                                            184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 
                                            34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 
                                            9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 
                                            9 132.1s2.7 102.7-9 132.1z"
                                                    />
                                                </svg>
                                            </Link>
                                            <Link to="#">
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                                    <path
                                                        d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 
                                            54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 
                                            42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z"
                                                    />
                                                </svg>
                                            </Link>
                                            <Link to="#">
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                                    <path
                                                        d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 
                                                    298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 
                                                    0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 
                                                    1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 
                                                    7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 
                                                    51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 
                                                    30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 
                                                    41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z"
                                                    />
                                                </svg>
                                            </Link>
                                        </div>
                                    </div>
                                ) : (
                                    <div className="text-center nodata">
                                        <img src={require('../../../assets/images/nodata.png')} alt="no-data" />
                                        <p>Data Not Found</p>
                                    </div>
                                )} */}

                                {detail ? (
                                    <div className="contact-lft-card mb-4">
                                        <h4>Contact person</h4>
                                        <h4 className="mt-4">{detail?.name}</h4>
                                        <p>{detail?.designation}</p>
                                        <ul className="contact-dtl-list">
                                            {detail?.address && (
                                                <li>
                                                    <i className="fas fa-map-marker-alt"></i>
                                                    {detail?.address}
                                                </li>
                                            )}
                                            {detail?.email && (
                                                <li>
                                                    <i className="fas fa-envelope"></i>
                                                    {detail?.email}
                                                </li>
                                            )}
                                            {detail?.contact && (
                                                <li>
                                                    <i className="fas fa-phone"></i>
                                                    {detail?.contact}
                                                </li>
                                            )}
                                        </ul>
                                    </div>
                                ) : <>
                                    <div className={loading ? "d-none" : "text-center nodata"}>
                                        <img src={require('../../../assets/images/nodata.png')} alt="no_data" />
                                        <p>Data Not Found</p>
                                    </div>
                                </>}
                            </Col>
                            <Col lg={5} md={12}>
                                <div className="cntct-frm-card">
                                    <h4 className="cntct-frm-hd">Get in Touch</h4>
                                    <Form>
                                        <Form.Group className="mb-3" controlId="formBasicName">
                                            <Form.Control
                                                type="text"
                                                name="name"
                                                value={state?.name}
                                                onChange={onChangeHandler}
                                                placeholder="Name *"
                                            />
                                            <div
                                                className={`${clicked && validate(state).name ? '' : 'd-none'} danger-frm `}
                                            >
                                                {clicked && validate(state).name}
                                            </div>
                                        </Form.Group>
                                        <Form.Group className="mb-3" controlId="formBasicemail">
                                            <Form.Control
                                                type="email"
                                                name="email"
                                                value={state?.email}
                                                onChange={onChangeHandler}
                                                placeholder="Email *"
                                            />
                                            <div
                                                className={`${clicked && validate(state).email ? '' : 'd-none'
                                                    } danger-frm `}
                                            >
                                                {clicked && validate(state).email}
                                            </div>
                                        </Form.Group>
                                        <Form.Group className="mb-3" controlId="formBasicNumber">
                                            <Form.Control
                                                type="text"
                                                name="phone_number"
                                                value={state?.phone_number}
                                                onKeyPress={restrictAlpha}
                                                onChange={onChangeHandler}
                                                placeholder="Phone Number *"
                                                maxLength={10}
                                            />
                                            <div
                                                className={`${clicked && validate(state).phone_number ? '' : 'd-none'
                                                    } danger-frm `}
                                            >
                                                {clicked && validate(state).phone_number}
                                            </div>
                                        </Form.Group>

                                        <Form.Group className="mb-3" controlId="formBasicNumber">
                                            <Form.Control
                                                as="textarea"
                                                type="text"
                                                name="message"
                                                value={state?.message}
                                                onChange={onChangeHandler}
                                                placeholder="Description "
                                            />
                                        </Form.Group>

                                        <Button
                                            className={`frm-btn w-100 ${loading ? 'blk' : ''}`}
                                            type="button"
                                            onClick={(e) => {
                                                loading ? e.preventDefault() : submiHandler(e);
                                            }}
                                        >
                                            Submit
                                        </Button>
                                    </Form>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </section>

            <section className="inst-map-card">
                <div className="event-map">
                    <Iframe
                        src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d54887.55600926899!2d76.72573620969241!3d30.70512157580728!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sin!4v1678359246122!5m2!1sen!2sin"
                        width="100%"
                        height="320px"
                        className=""
                        display="block"
                        position="relative"
                    />
                </div>
            </section>
        </div>
    );
}

export default ContactUs;
