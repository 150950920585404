import { useEffect, useState } from 'react';
import { Card, Container } from 'react-bootstrap';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { getInsNewsView } from '../../../services/InstituteServices';

function NewsDetail() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [newsDetail, setNewsDetail] = useState([]);

  useEffect(() => {
    if (id) {
      //fetching id from slug i.e.. 13-title
      getNews(id?.split('-')[0]);
    }
  }, []);

  // single detail based on id
  const getNews = (id) => {
    getInsNewsView(id).then((res) => {
      if (res?.status == 200) {
        setNewsDetail(res?.data?.data);
      }
    });
  };

  return (
    <div className="wrappermain_outer bggreylight">
      <section className="news-details blog-news-details">
        <Container>
          <button
            className="btn btn-back mb-4"
            type="button"
            onClick={() => {
              navigate(-1);
            }}
          >
            Back
          </button>
          <Card className="bg-white">
            <Card.Body>
              <div className="single_detail_card">
                <h1 className="mb-4">{newsDetail?.details?.title}</h1>

                {newsDetail?.details?.attachment ? (
                  <div className="text-center">
                    <img
                      src={
                        process.env.REACT_APP_API_DOMAIN +
                        newsDetail?.details?.url +
                        '.' +
                        newsDetail?.details?.extension
                      }
                      alt="news-detail"
                      className="mb-0 "
                    />
                    {/* <div className='image-bottom'>
                                        <p>
                                            Proud Moment for School
                                        </p>
                                    </div> */}
                  </div>
                ) : (
                  <></>
                )}

                <div dangerouslySetInnerHTML={{ __html: newsDetail?.details?.description }} />
                <div className="d-flex align-items-center justify-content-between navigators">
                  {newsDetail?.pre ? (
                    <Link
                      onClick={() => getNews(newsDetail?.pre?.id)}
                      className="previous position-relative"
                    >
                      <i className="fas fa-chevron-left previous-icon"></i>
                      <p className="mb-0">Prev</p>
                      <span className="title">{newsDetail?.pre?.title}</span>
                    </Link>
                  ) : (
                    ''
                  )}

                  {newsDetail?.next ? (
                    <Link
                      className="next position-relative text-end"
                      onClick={() => getNews(newsDetail?.next?.id)}
                    >
                      <i className="fas fa-chevron-right next-icon"></i>
                      <p className="mb-0">Next</p>
                      <span className="title">{newsDetail?.next?.title}</span>
                    </Link>
                  ) : (
                    ''
                  )}
                </div>
              </div>
            </Card.Body>
          </Card>
        </Container>
      </section>
    </div>
  );
}

export default NewsDetail;
