/**
@copyright  :   ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
@author     :   Shiv Charan Panjeta 
 
All Rights Reserved.
Proprietary and confidential :  All information contained here in is, and remains
the property of ToXSL Technologies Pvt. Ltd. and it's partners.
Unauthorized copying of this file, via any medium is strictly prohibited.
*/
import { useEffect, useState } from 'react';
import { Button, Container } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Pagination from 'react-bootstrap/Pagination';
import Row from 'react-bootstrap/Row';
import Table from 'react-bootstrap/Table';
import { Link, useNavigate } from 'react-router-dom';
import { truncate } from '../../../globals/helper';
import {
  ViewSingleEventDetail,
  eventStatsList,
  eventStatsSelected
} from '../../../services/AuthServices';
import showNotification from '../../../services/NotificationService';

export default function EventStat() {
  const [showalert, setShowAlert] = useState(false);
  const [chekcedId, setCheckedId] = useState([]);
  const eventId = new URLSearchParams(location?.search).get('eventId');
  const navigate = useNavigate();
  const [allId, setAllId] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [action, setAction] = useState('');
  const [loading, setLoading] = useState(false);
  const [searchData, setSearchData] = useState({
    user_id: '',
    user_name: '',
    user_email: '',
    user_mobile: '',
    user_status: ''
  });
  const [eventDetail, setEventDetail] = useState('');

  useEffect(() => {
    apiCall();
    if (eventId) {
      getDetail(eventId);
    }
  }, []);

  const getDetail = (id) => {
    ViewSingleEventDetail(id).then((res) => {
      if (res?.status === 200) {
        const data = res?.data?.data?.details;
        setEventDetail(data);
      }
    });
  };

  const [list, setList] = useState([]);
  const apiCall = (
    page,
    user_id = '',
    user_name = '',
    user_email = '',
    user_mobile = '',
    user_status = ''
  ) => {
    const body = {
      event_id: eventId,
      page,
      user_id,
      user_name,
      user_mobile,
      user_email,
      user_status
    };
    eventStatsList(body).then((resp) => {
      if (resp?.status === 200) {
        setList(resp?.data?.detail?.viewlist);
        setAllId(resp?.data?.detail.viewlist.map((data) => data?.id));
      }
    });
  };

  const handleSelectAll = (e) => {
    if (e.target.checked === true) {
      setCheckedId(allId);
    } else {
      setCheckedId([]);
    }
  };

  const searchHandler = (e) => {
    e.preventDefault();
    apiCall(
      currentPage,
      searchData.user_id,
      searchData.user_name,
      searchData.user_email,
      searchData.user_mobile,
      searchData.user_status
    );
  };

  const onChangeSearch = (e) => {
    const { name, value } = e.target;
    setSearchData({ ...searchData, [name]: value });
  };

  let active = 2;
  let items = [];
  for (let number = 1; number <= 5; number++) {
    items.push(
      <Pagination.Item key={number} active={number === active}>
        {number}
      </Pagination.Item>
    );
  }

  const resetHandler = (e) => {
    e.preventDefault();
    setSearchData({
      user_id: '',
      user_name: '',
      user_email: '',
      user_mobile: '',
      user_status: ''
    });
    apiCall();
  };

  const globalStateHandler = () => {
    setShowAlert(true);
  };

  const handleAction = (e) => {
    if (chekcedId?.length !== 0) {
      setAction(e.target.value);
      const body = {
        event_id: eventId,
        ids: chekcedId?.toString(),
        action: e.target.value
      };
      setLoading(true);
      eventStatsSelected(body).then((resp) => {
        setLoading(false);
        if (resp.status === 200) {
          document.getElementById('select1').value = '';
          apiCall();
          showNotification('success', resp?.data?.message);
        }
      });
    } else {
      globalStateHandler();
    }
  };

  const handlecheckbox = (id) => {
    if (chekcedId?.includes(id)) {
      let filterId = chekcedId.filter((data) => data !== id);
      setCheckedId(filterId);
    } else {
      setCheckedId([...chekcedId, id]);
    }
  };

  return (
    <div className="dash-main-content">
      <Container fluid>
        <div className="dash-page-content">
          <div className="dash-page-title">
            <Row>
              <Col xs={12} sm={12} md={12}>
                <div className="dash-title-bar d-flex align-items-center justify-content-between">
                  <div className="title-lft d-flex align-items-center">
                    <div className="dash-icn">
                      <i className="fa fa-users"></i>
                    </div>
                    <div className="dash-title-dis">
                      <h5>List of User Involved in {truncate(eventDetail?.name, 20)}</h5>
                      <span>event</span>
                      <h5>
                        Total Record(s): <span>{list?.length}</span>
                      </h5>
                    </div>
                  </div>
                  <div className="title-rt-btn d-flex align-items-center">
                    <button
                      type="button"
                      className="add-btn-grn mx-2"
                      onClick={() =>
                        navigate(`/admin/event/AddEventInvitation?adminEventId=${eventId}`)
                      }>
                      Send Invitations
                    </button>

                    <div className="head-instite-sec">
                      <Form.Select
                        aria-label="Default select example "
                        onChange={handleAction}
                        id="select1">
                        <option value="">Choose Action</option>
                        <option value="remove">Remove Action</option>
                      </Form.Select>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <div className="dash-content-card">
            <div className="table-responsive">
              <Table className="custom-table-data" striped bordered hover>
                <thead>
                  <tr>
                    <th>
                      <Form.Check
                        type="checkbox"
                        id="checkbox"
                        name="checkall"
                        checked={allId?.length !== 0 ? allId?.length === chekcedId?.length : false}
                        onChange={(e) => {
                          handleSelectAll(e);
                        }}
                        label=""
                      />
                    </th>
                    <th>
                      <Link to="">
                        Id
                        {/* <img src={require('../../assets/images/sort.png')} alt="sort" /> */}
                      </Link>
                    </th>
                    <th>
                      <Link to="">
                        Names
                        {/* <img src={require('../../assets/images/sort.png')} alt="sort" /> */}
                      </Link>
                    </th>
                    <th>
                      <Link to="">
                        Email
                        {/* <img src={require('../../assets/images/sort.png')} alt="sort" /> */}
                      </Link>
                    </th>
                    <th>
                      <Link to="">
                        Contact No.
                        {/* <img src={require('../../assets/images/sort.png')} alt="sort" /> */}
                      </Link>
                    </th>
                    <th>
                      <Link to="">
                        Activity
                        {/* <img src={require('../../assets/images/sort.png')} alt="sort" /> */}
                      </Link>
                    </th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="search-table">
                    <td></td>
                    <td>
                      <Form.Control
                        type="text"
                        placeholder="id"
                        value={searchData?.user_id}
                        name="user_id"
                        onChange={(e) => {
                          onChangeSearch(e);
                        }}
                      />
                    </td>
                    <td>
                      <Form.Control
                        type="text"
                        placeholder="name"
                        value={searchData?.user_name}
                        name="user_name"
                        onChange={(e) => {
                          onChangeSearch(e);
                        }}
                      />
                    </td>
                    <td>
                      <Form.Control
                        type="text"
                        placeholder="Email"
                        value={searchData?.user_email}
                        name="user_email"
                        onChange={(e) => {
                          onChangeSearch(e);
                        }}
                      />
                    </td>
                    <td>
                      <Form.Control
                        type="text"
                        placeholder="Mobile"
                        value={searchData?.user_mobile}
                        name="user_mobile"
                        onChange={(e) => {
                          onChangeSearch(e);
                        }}
                      />
                    </td>
                    <td>
                      <Form.Select
                        aria-label="Default select example"
                        value={searchData?.status}
                        name="user_status"
                        onChange={(e) => {
                          onChangeSearch(e);
                        }}>
                        <option> - Select - </option>
                        <option value="0"> Invited</option>
                        <option value="1"> Going </option>
                        <option value="2"> Interested </option>
                      </Form.Select>
                    </td>
                    <td className="serac-btns">
                      <Button
                        className="main-btn-grn"
                        type="button"
                        onClick={(e) => {
                          searchHandler(e);
                        }}>
                        Search
                      </Button>
                      <Button
                        className="main-btn-red ms-2"
                        onClick={(e) => {
                          resetHandler(e);
                        }}>
                        Reset
                      </Button>
                    </td>
                  </tr>
                  {list && list?.length ? (
                    list?.map((data, index) => {
                      return (
                        <tr key={index}>
                          <td>
                            <Form.Check
                              type="checkbox"
                              id="checkbox"
                              label=""
                              checked={chekcedId?.includes(data?.id)}
                              onClick={() => handlecheckbox(data?.id)}
                            />
                          </td>
                          <td>{data?.id ? data?.id : 'Not Available'}</td>
                          <td>{data?.name ? data?.name : 'Not Available'} </td>
                          <td>{data?.email ? data?.email : 'Not Available'}</td>
                          <td>{data?.mobile ? data?.mobile : 'Not Available'} </td>
                          <td>
                            {data?.status == '0'
                              ? 'Invited'
                              : data?.status == '1'
                              ? 'Going'
                              : ' Interested'}
                          </td>

                          <td>
                            <span
                              role="presentation"
                              className="text-primary  mx-5"
                              onClick={() => {
                                navigate(`/admin/users/view/${data?.id}`);
                              }}>
                              <i className="fas fa-search-plus"></i>
                            </span>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr className="text-center">Data Not Found</tr>
                  )}
                </tbody>
              </Table>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}
