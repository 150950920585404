/**
@copyright : ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
@author     : Shiv Charan Panjeta 
 
All Rights Reserved.
Proprietary and confidential :  All information contained herein is, and remains
the property of ToXSL Technologies Pvt. Ltd. and its partners.
Unauthorized copying of this file, via any medium is strictly prohibited.
*/

import { useEffect, useState } from 'react';
import { Alert, Container } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Table from 'react-bootstrap/Table';
import ReactPaginate from 'react-paginate';
import { useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Loader from '../../components/commoncomponents/loader/loader-large';
import useDocumentTitle from '../../globals/useDocumentTitle';
import * as adminServices from '../../services/AuthServices';
import showNotification from '../../services/NotificationService';

let intervalId;

function ManageEmail() {
  useDocumentTitle('Manage Email');
  const navigate = useNavigate();
  const isAlert = useSelector((state) => state?.alert);
  const location = useLocation();
  const [currentPage, setCurrentPage] = useState(1);
  const [orderBy, setOrderBy] = useState('DESC');
  const [shortOrderBy, setShortOrderBy] = useState('admin_id');
  const [list, setList] = useState([]);
  const [totalRecords, setTotalRecord] = useState(0);
  const [loading, setLoading] = useState(false);
  const [chekcedId, setCheckedId] = useState([]);
  const [allId, setAllId] = useState([]);
  const [showalert, setShowAlert] = useState(false);
  const [alert, setAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState('');
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    apiCall();
  }, []);

  useEffect(() => {
    const timeId = setTimeout(() => {
      setAlert(false);
    }, 3000);
    return () => {
      clearTimeout(timeId);
    };
  }, [alert]);

  useEffect(() => {
    if (isAlert?.showAlertBox) {
      setAlert(true);
    }
  }, []);

  const apiCall = (page = 1) => {
    setLoading(true);
    adminServices.getEmailQueueList(page).then((res) => {
      setLoading(false);
      if (res?.status === 200) {
        setList(res?.data?.data?.List);

        setTotalRecord(res?.data?.count);
        setAllId(res?.data?.data?.List?.map((data) => data?.id));
      }
    });
  };

  // ACTIONS

  const handledeleteEmailQueue = (id) => {
    adminServices.deleteEmailQueue(id).then((res) => {
      if (res?.status === 200) {
        document.getElementById('select-action').value = '';
        setAlert(true);
        setAlertMsg(res?.data?.message);
        // showNotification('success', res?.data?.message);
        apiCall();
      }
    });
  };

  const selectHandler = (value, id) => {
    switch (value) {
      case 'edit':
        navigate(`/admin/users/add-adminUsers`, { state: id });
        break;
      case 'view':
        //navigate('', { state: id })
        break;
      case '1':
        // ViewData(id);
        // handleShow();
        navigate(`/admin/users/email-view/${id}`, { state: id });
        break;

      case '2':
        handledeleteEmailQueue(id);
        break;
      default:
        break;
    }
  };

  const sortHandler = (e, type) => {
    e.preventDefault();
    let currentSort = orderBy === 'ASC' ? 'DESC' : 'ASC';
    setOrderBy(currentSort);
    setShortOrderBy(type);
    apiCall(currentPage, orderBy, shortOrderBy);
  };
  const handlePageClick = (a) => {
    let page = a?.selected + 1;
    setCurrentPage(page);
    apiCall(page, orderBy, shortOrderBy);
  };

  const handleMultipleCheckBox = (id) => {
    if (chekcedId.includes(id)) {
      let filterId = chekcedId.filter((data) => data !== id);
      setCheckedId(filterId);
    } else {
      setCheckedId([...chekcedId, id]);
    }
  };

  const handleSelectAll = (e) => {
    if (e.target.checked === true) {
      setCheckedId(allId);
    } else {
      setCheckedId([]);
    }
  };
  const globalStateHandler = () => {
    setShowAlert(true);
    intervalId = setInterval(function () {
      setShowAlert(false);
    }, 5000);

    setTimeout(function () {
      clearInterval(intervalId);
    }, 3000);
  };

  const handlePerformAction = (e) => {
    if (chekcedId.length !== 0) {
      setShowAlert(false);
      let body = {
        ids: chekcedId.toString(),
        selectedAction: e.target.value
      };
      let formData = new FormData();
      for (let i in body) {
        formData.append(`${i}`, body[i]);
      }
      adminServices.setMultipleAdminStatus(formData).then((res) => {
        if (res?.status === 200) {
          document.getElementById('select').value = '';
          setCheckedId([]);
          apiCall();
        }
      });
    } else {
      globalStateHandler();
    }
  };

  const statusName = (key) => {
    switch (key) {
      case '0':
        return 'Pending';
      case '1':
        return 'Send';
      case '2':
        return 'Failed';
    }
  };

  // END ACTIONS

  return (
    <>
      <div className="dash-main-content">
        <Container fluid>
          <div className="dash-page-content">
            <div className="dash-page-title">
              <Row>
                <Col xs={12} sm={12} md={12}>
                  <div className="dash-title-bar d-flex align-items-center justify-content-between">
                    <div className="title-lft d-flex align-items-center">
                      <div className="dash-icn">
                        <i className="fas fa-building"></i>
                      </div>
                      <div className="dash-title-dis">
                        <h5>
                          Manage <span>Emails</span>
                        </h5>

                        <h5>
                          Total Record(s): <span> {totalRecords} </span>
                        </h5>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="dash-content-card">
              <Alert variant={'success'} className={`${alert ? '' : 'd-none'}`}>
                {alertMsg}
              </Alert>

              <div className="table-responsive">
                <Table className="custom-table-data" striped bordered hover>
                  <thead>
                    <tr>
                      <th>
                        <Link
                          to=""
                          onClick={(e) => {
                            sortHandler(e, 'admin_id');
                          }}>
                          Id
                        </Link>
                      </th>
                      <th>
                        <Link
                          to=""
                          onClick={(e) => {
                            sortHandler(e, 'username');
                          }}>
                          {' '}
                          Subject
                        </Link>
                      </th>
                      <th> Send on </th>

                      <th>
                        <Link
                          to=""
                          onClick={(e) => {
                            sortHandler(e, 'admin_status');
                          }}>
                          Status
                          <img src={require('../../assets/images/sort.png')} alt="sort" />
                        </Link>
                      </th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {/* loop */}
                    {list && list?.length ? (
                      list?.map((data, index) => (
                        <tr key={index}>
                          <td> {data?.id} </td>
                          <td> {data?.subject} </td>
                          <td> {data?.send_date} </td>

                          <td> {statusName(data?.status)} </td>

                          <td>
                            <Form.Select
                              key={index}
                              aria-label="Default select example"
                              id="select-action"
                              onChange={(e) => {
                                selectHandler(e.target.value, data?.id);
                              }}>
                              <option value="">Action</option>

                              <option value="2">Delete</option>
                              <option value="1">View</option>
                            </Form.Select>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr className="text-center">
                        <td colSpan={9}>Data Not Found</td>{' '}
                      </tr>
                    )}
                  </tbody>
                </Table>
                <div className="react-page d-flex">
                  {totalRecords > 10 ? (
                    <ReactPaginate
                      previousLabel={currentPage > 0 ? '<' : ''}
                      nextLabel=">"
                      onPageChange={handlePageClick}
                      activeClassName={'page-link active'}
                      pageCount={Math.ceil(totalRecords / 10)}
                    />
                  ) : (
                    ''
                  )}
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
      {loading ? <Loader /> : ''}
    </>
  );
}

export default ManageEmail;
