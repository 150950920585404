import { Row } from 'react-bootstrap';
import { Col } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import { Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import NoDataFound from '../../commoncomponents/NoDataFound';
import { getGroupMembers } from '../../../services/SocialServices';

function GroupPeopleList({
  peopleList,
  loading,
  addMember,
  handleLoadMore,
  peopleCount,
  membersName,
  setMembersName,
  setLoading,
  setPeopleCount,
  setPeopleList,
  timeline_id,
  offset,
  showBackColour,
  clickedId
}) {
  return (
    <div className="likes-tab-sec">
      <div className="likes-top-hd d-flex align-items-center justify-content-between">
        <div>
          <Link to="#">People List</Link>
        </div>
        <div>
          <Form.Group className="search-card" controlId="formBasicEmail">
            <Form.Control
              value={membersName}
              onChange={async (event) => {
                setMembersName(event.target.value);
                try {
                  let body = {
                    type: 'add_members'
                  };
                  const res = await getGroupMembers(body, timeline_id, event.target.value, '');
                  if (res?.status === 200) {
                    setPeopleList(res?.data?.data ?? []);
                    setPeopleCount(res?.data?.data?.total_count ?? 0);
                    setLoading(false);
                  } else {
                    setLoading(false);
                  }
                } catch (error) {}
              }}
              name="membersName"
              type="search"
              placeholder="Search"
            />
            <Button className="search-btn">
              <i className="fas fa-search"></i>
            </Button>
          </Form.Group>
        </div>
      </div>
      {loading ? (
        <>
          {' '}
          <div className="like-page-card bg-white p-3  loading-skeleton">
            {Array.from(Array(3), function (index) {
              return (
                <Row>
                  <Col md={6}>
                    <div className="like-card-detail member-card-detail d-flex">
                      <div className="like-card-img">
                        <Link to="#">
                          <img
                            src={require('../../../assets/images/default-male-avatar.png')}
                            alt="img"
                          />
                        </Link>
                      </div>
                      <div className="like-card-dis">
                        <h4></h4>
                        <p></p>
                        <Link to="" className="mentr-add-member"></Link>
                      </div>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="like-card-detail member-card-detail d-flex">
                      <div className="like-card-img">
                        <Link to="#">
                          <img
                            src={require('../../../assets/images/default-male-avatar.png')}
                            alt="img"
                          />
                        </Link>
                      </div>
                      <div className="like-card-dis">
                        <h4></h4>
                        <p></p>
                        <Link to="" className="mentr-add-member"></Link>
                      </div>
                    </div>
                  </Col>
                </Row>
              );
            })}
          </div>
        </>
      ) : (
        <>
          {' '}
          {peopleList?.peoples?.length > 0 && !loading ? (
            <>
              <div className="like-page-card bg-white p-3 ">
                <Row>
                  {peopleList?.peoples?.map((data, index) => {
                    return (
                      <Col md={6} key={index}>
                        <div
                          className="like-card-detail member-card-detail d-flex"
                          style={{
                            backgroundColor:
                              showBackColour && clickedId == data?.id && 'rgba(255,0,0,0.1)'
                          }}
                          id={`people${data?.id}`}>
                          <div className="like-card-img">
                            <Link to="#">
                              <img
                                src={
                                  data?.avatar_url
                                    ? data?.avatar_url
                                    : require('../../../assets/images/default-male-avatar.png')
                                }
                                alt="img"
                              />
                            </Link>
                          </div>
                          <div className="like-card-dis">
                            <h4>
                              {data?.first_name} {data?.last_name}
                            </h4>
                            <p>
                              {data?.work_experience?.length !== 0
                                ? `Working as ${data?.work_experience[0]?.designation}  at  ${data?.work_experience[0]?.organisation}`
                                : ''}
                            </p>
                            {showBackColour ? (
                              <Link to="" className="mentr-add-member blk">
                                Add Member
                              </Link>
                            ) : (
                              <Link
                                to=""
                                className="mentr-add-member"
                                onClick={(e) => {
                                  addMember(e, data?.id);
                                }}>
                                Add Member
                              </Link>
                            )}
                          </div>
                        </div>
                      </Col>
                    );
                  })}
                  {peopleList?.peoples?.length > 0 &&
                    peopleCount != peopleList?.peoples?.length && (
                      <div className="load-more-btn text-center">
                        <Button variant="secondary" className="read-more" onClick={handleLoadMore}>
                          Load More
                        </Button>
                      </div>
                    )}
                </Row>
              </div>
            </>
          ) : (
            <NoDataFound />
          )}
        </>
      )}
    </div>
  );
}

export default GroupPeopleList;
