import { useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { Alert } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';

import { useState } from 'react';
// import { loadEventCategoryOptions } from '../../../../globals/helper';
// import { loadCityOpt } from '../../../../super-admin/AdminHelper';
import showNotification from '../../../../services/NotificationService';
import { getInviteList } from '../../../../services/NewsServices';
import { sendEventInvite } from '../../../../services/SocialServices';

function InvitePeopleModal(props) {
  const { handleClose3, show3, handleClose, eventId, isloggedIn } = props;
  const [list, setList] = useState([]);
  const [showalert, setShowalert] = useState(false);
  const [prevStateLeft, setPrevStateLeft] = useState([]);
  const [prevStateRight, setPrevStateRight] = useState([]);

  const [selectedInviteList, setSelectedInviteList] = useState([]);
  const [inviteList, setInviteList] = useState([]);

  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (isloggedIn) {
      getListToInvite();
    }
  }, [show3, isloggedIn]);

  const addRecommendHandler = (data) => {
    let arr = [...selectedInviteList];
    let temp = inviteList.filter((val) => val.id != data?.id);
    arr.push(data);
    setInviteList(temp);
    setPrevStateLeft(temp);
    setSelectedInviteList(arr);
    setPrevStateRight(arr);
    setShowalert(false);
  };

  const removeRecommendHandler = (data) => {
    let temp = selectedInviteList.filter((val) => val.id != data?.id);
    let tempArr = [...inviteList];
    tempArr.push(data);
    setInviteList(tempArr);
    setSelectedInviteList(temp);
    setPrevStateRight(temp);
    setPrevStateLeft(tempArr);
  };
  const searchHandlerRight = (e) => {
    const { value } = e.target;
    if (!value?.trim()) {
      setInviteList(prevStateLeft);
      return;
    } else {
      let temp = prevStateLeft.filter((val) =>
        val.name.toLocaleLowerCase().includes(value.toLocaleLowerCase())
      );
      setInviteList(temp);
    }
  };

  const searchHandlerLeft = (e) => {
    const { value } = e.target;
    if (!value?.trim()) {
      setSelectedInviteList(prevStateRight);
      return;
    } else {
      let temp = prevStateRight.filter((val) =>
        val.name.toLocaleLowerCase().includes(value.toLocaleLowerCase())
      );
      setSelectedInviteList(temp);
    }
  };
  const getListToInvite = () => {
    setLoading(true);
    setTimeout(() => {
      getInviteList(eventId).then((res) => {
        if (res?.status === 200) {
          setLoading(false);
          setInviteList(res?.data?.data ?? []);
        }
      });
    }, 1500);
  };

  const handleEventInivite = () => {
  
    let ids = [];
    selectedInviteList.map((data) => {
      return ids.push(data?.id);
    });
    if (selectedInviteList.length) {
      let formData = new FormData();
      formData.append('event_id', eventId);
      formData.append('ids[]', ids);
      sendEventInvite(formData).then((res) => {
        if (res?.status === 200) {
          // getEventDetail();
          setSelectedInviteList([]);

          showNotification('success', res?.data?.message);
        }
      });
    }
  };

  return (
    <Modal show={show3} onHide={handleClose3} className="recommend-modal">
      <Modal.Body>
        {loading ? (
          <>
            {' '}
            <Form>
              {' '}
              <div className="loader-modal h-100 d-flex align-items-center justify-content-center">
                <img src={require('../../../../assets/images/Loading_icon-small.gif')} alt="img" />
              </div>
            </Form>
          </>
        ) : (
          <>
            <Modal.Header closeButton>
              <Modal.Title>
                Recommend this job to your friends that you think are suitable
              </Modal.Title>
            </Modal.Header>
            <div className="recommend-lst-card">
              <Alert variant={'danger'} className={`${showalert ? '' : 'd-none'}`}>
                {/* {errorMessage} */}
              </Alert>
              <h4 className="text-orange text-center mb-3">SELECT FRIENDS FROM LIST</h4>
              <div className="recomm-compare-lst d-flex align-item-center justify-content-between mb-2">
                <div className="search-lst-card">
                  <div className="search-lst-frm">
                    <Form.Group className="mb-0" controlId="formBasicEmail">
                      <Form.Control
                        type="search"
                        placeholder="Search..."
                        onChange={(e) => {
                          searchHandlerRight(e);
                        }}
                      />
                    </Form.Group>
                  </div>
                  <div className="search-lst">
                    <ul>
                      {inviteList && inviteList?.length ? (
                        inviteList?.map((data, index) => (
                          <li
                            key={index}
                            role="presentation"
                            tabIndex={index}
                            onClick={() => {
                              addRecommendHandler(data, index);
                            }}
                          >
                            {data?.name}
                          </li>
                        ))
                      ) : (
                        <li>No Friend to Recommend</li>
                      )}
                    </ul>
                  </div>
                </div>
                <span className="compare-btn">
                  <i className="fas fa-exchange-alt"></i>
                </span>
                <div className="search-lst-card">
                  <div className="search-lst-frm">
                    <Form.Group className="mb-0" controlId="formBasicEmail">
                      <Form.Control
                        type="search"
                        placeholder="Search..."
                        onChange={(e) => {
                          searchHandlerLeft(e);
                        }}
                      />
                    </Form.Group>
                  </div>
                  <div className="search-lst">
                    <ul>
                      {selectedInviteList &&
                        selectedInviteList?.map((data, index) => {
                          return (
                            <li
                              role="presentation"
                              tabIndex={index}
                              onClick={() => {
                                removeRecommendHandler(data);
                              }}
                              key={index}
                            >
                              <div>
                                <img src={data?.img} width="100px" height="50px" alt="people" />
                              </div>
                              {data?.name}
                            </li>
                          );
                        })}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <Button
              className="recomm-btn"
              onClick={() => {
                handleEventInivite();
              }}
            >
              <i className="far fa-check-circle me-2"></i>Send Invite
            </Button>
          </>
        )}
      </Modal.Body>
    </Modal>
  );
}

export default InvitePeopleModal;
