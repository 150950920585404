/*
@copyright : ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
@author     : Shiv Charan Panjeta 
 
All Rights Reserved.
Proprietary and confidential :  All information contained herein is, and remains
the property of ToXSL Technologies Pvt. Ltd. and its partners.
Unauthorized copying of this file, via any medium is strictly prohibited.
*/
import axios from 'axios';
import URL from '../globals/config';
import showNotification from '../services/NotificationService';
import * as session from '../utils/session';

/**Create a instance of axios with a custom config */
export const http = axios.create({
  baseURL: URL,
  headers: {
    'Content-Type': 'application/json'
  }
});

export const http_multipart = axios.create({
  baseURL: URL,
  headers: {
    'Content-Type': 'multipart/form-data'
  }
});

// htmlFor multipart/form-data

/**Add a request interceptor */
http_multipart.interceptors.request.use(
  function (config) {
    const token = session.getToken();

    if (token) {
      config.headers['authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

/**Add a response interceptor */
http_multipart.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response?.status === 417) {
      showNotification('danger', error.response.data.message);
      // return error.response;
      // sessionStorage.clear();
    }
    if (error.response?.status === 401) {
      showNotification('danger', error.response.data.message);
      window.location.href = '/';
      // localStorage.clear();
      // sessionStorage.clear();
    }
    if (error.response?.status === 400) {
      showNotification('danger', error.response.data.message);
      // return error.response;
      // showNotification("danger", error.response.data.message);
    }
    if (error.response?.status === 500) {
      if (session.getToken()) {
        showNotification('danger', error.response.data.message, 'random');
      }
      // return error.response;
      // showNotification("danger", error.response.data.message);
    }
    if (error.response?.status === 404) {
      showNotification('danger', error.response.data.message);
      // showNotification("danger", error.response.data.message);
    }

    return Promise.reject(error);
  }
);
