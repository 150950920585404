import { useEffect, useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Container } from 'react-bootstrap';
import HeaderLogin from '../../../commoncomponents/HeaderLogin';
import TopTabbings from '../TopTabbings';
import NewsSidebar from '../../../commoncomponents/newsfeeds/NewsSidebar';
import FooterMain from '../../../commoncomponents/FooterMain';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import moment from 'moment';
import { userEvents } from '../../../../services/UserServices';
import { useNavigate } from 'react-router-dom';
import useDocumentTitle from '../../../../globals/useDocumentTitle';
import { useSelector } from 'react-redux';

function EventCalendar() {
  useDocumentTitle('List of Events | AlmaBay');
  const navigate = useNavigate();
  const [myEventsList, setmyEventsList] = useState([]);
  const localizer = momentLocalizer(moment);
  const isloggedIn = useSelector((state) => state?.user?.isloggedIn);

  /* eslint-disable */

  useEffect(() => {
    eventList();
  }, []);

  const eventList = () => {
    userEvents().then((resp) => {
      let eventList = [];
      if (resp?.status === 200) {
        resp?.data?.data?.main_content.forEach((data) => {
          eventList.push({
            title: `${data?.name} [${data?.event_privacy}]`,
            start: new Date(data?.from_event_date),
            end: new Date(data?.to_event_date),
            id: data?.id
          });
        });
        setmyEventsList(eventList);
      }
    });
  };

  const showEventDetails = (event) => {
    // navigate(`/user/events-detail/${event?.id}/overview`);
    navigate(`/user/events`);
  };

  return (
    <>
      <HeaderLogin />
      <div className="header-lnk-feeds">
        <div className="container">
          <TopTabbings state={'social'} />
        </div>
      </div>
      <section className="feed-sec bg-grey">
        <Container>
          <Row>
            <Col xs={12} md={2}>
              <NewsSidebar state="social" current="page" />
            </Col>
            <Col xs={12} lg={9} md={12}>
              <div className="events_listing p-3 mt-3 ">
                <Row className="border-bottom pb-2 mb-4">
                  <Col md={6} xs={6}>
                    <h4 className="heading">Events</h4>
                  </Col>
                </Row>
                <div className="events-page-list">
                  <Row>
                    <Calendar
                      views={['month', 'week', 'day']}
                      defaultView="month"
                      localizer={localizer}
                      startAccessor="start"
                      endAccessor="end"
                      style={{ height: '75vh' }}
                      events={myEventsList}
                      onSelectEvent={(event) => {
                        showEventDetails(event);
                      }}
                    />
                  </Row>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      {!isloggedIn && <FooterMain className={'colour-bg'} />}
    </>
  );
}

export default EventCalendar;
