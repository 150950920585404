/**
 * @copyright : ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
   @author     : Shiv Charan Panjeta 

  All Rights Reserved.
  Proprietary and confidential :  All information contained herein is, and remains
  the property of ToXSL Technologies Pvt. Ltd. and its partners.
  Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 */

import { useState, useEffect, useRef } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import HeaderLogin from '../../commoncomponents/HeaderLogin';
import TopTabbings from './TopTabbings';
import { Link, useLocation } from 'react-router-dom';
import NewsSidebar from '../../commoncomponents/newsfeeds/NewsSidebar';
import Form from 'react-bootstrap/Form';

import * as AuthServices from '../../../services/AuthServices';
var createReactClass = require('create-react-class');
import Loader from '../../commoncomponents/loader/loader-large';
import { getMedia } from '../../../globals/helper';
import msgStyles from './message.module.scss';
import moment from 'moment';
import showNotification from '../../../services/NotificationService';

let $ = require('jquery');

var document_title = document.title;

function MessagesPage() {
  const modalRef = useRef();
  const imgRef = useRef();
  const closeRef = useRef();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState([]);
  const [response, setResponse] = useState('');
  const [messageRecipients, setMessageRecipients] = useState('');
  const [messagesResponse, setMessagesResponse] = useState([]);
  const [timelineIdRep, setTimelineIdRep] = useState('');
  const [recipient_id_rep, setRecipientIdRep] = useState('');
  const [recipient_name, setRecipientName] = useState('');
  const [recipient_url, setRecipientUrl] = useState('');
  const [reply_ability, setReplyAbility] = useState('');
  const [recipientsExist, setRecipientsExist] = useState(true);
  const [loadingPreviousMsgs, setLoadingPreviousMsgs] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [showViewMore, setShowViewMore] = useState(false);
  const [encryptId, setEncryptId] = useState('');
  const [modalImg, setModalImg] = useState('');
  const chatBoxRef = [];
  const [chatBox, setChatBox] = useState([]);
  const rooms = [];
  var chat = [];
  const chatting = [];
  var chatArchive = '';

  var section = '';
  var timeline_id = '';
  var url = window.location.href;

  if (url.indexOf('#') === -1) {
    url = url.split('/');
    section = url[3];
    timeline_id = url[url.length - 1] != '' ? url[url.length - 1] : 0;
  } else {
    url = url.split('#');
    url = url[0].split('/');
    section = url[3];
    timeline_id = url[url.length - 1] != '' ? url[url.length - 1] : 0;
  }

  // Here - default  timeline_id=messages and section=user getting from URL  /user/messages

  useEffect(() => {
    callDefault();
  }, []);

  useEffect(() => {
    if (recipient_id_rep) {
      const interval = setInterval(() => {
        SK_getNewMessages();
      }, 9000);

      const intervalUserList = setInterval(() => {
        //SK_updateRecipients();
      }, 30000);

      return () => {
        clearInterval(interval);
        clearInterval(intervalUserList);
      };
    }
  }, [recipient_id_rep]);

  // API CODE

  const callDefault = () => {
    setLoading(true);

    AuthServices.SK_getMessageRecipients(timeline_id, section).then((res) => {
      if (res?.status === 200) {
        setLoading(false);

        // List of users > right sidebar

        setMessageRecipients(res?.data?.data?.message_recipients);

        // set timeline_id => timelineIdRep

        setTimelineIdRep(res?.data?.data?.timeline_id);

        if (res?.data?.data?.message_recipients.length > 0) {
          if (res?.data?.data?.recipient_id > 0) {
            setRecipientIdRep(res?.data?.data?.recipient_id);
            SK_getMessages(res?.data?.data?.recipient_id);
          } else {
            SK_getMessages(
              location?.state !== null ? location?.state : res?.data?.data?.message_recipients[0].id
            );
          }
        } else {
          setRecipientsExist(false);
        }
      }
    });
  };

  /**
   * Get message - one to one
   *
   * @param {*} recipient_id
   */

  const SK_getMessages = (recipient_id = '') => {
    setShowLoader(true);
    setShowViewMore(true);

    setLoading(true);

    $('#message_recipient_' + recipient_id)
      .find('.update-alert')
      .hide();
    $('#message_recipient_' + recipient_id)
      .find('.last_message')
      .removeClass('strong');

    // Service to return all reviewslist

    //var getMessages = "";
    //var messages = "";

    var url = `ajax/socialAjax?t=message&a=load_conversation&recipient_id=${recipient_id}&timeline_id=${timeline_id}`;

    AuthServices.callAjaxGetMethod(url).then((res) => {
      setLoading(false);

      if (res?.status === 200) {
        //getMessages = res?.data;
        //messages = res?.data?.messages;
        setResponse(res?.data?.data);
        setMessagesResponse(res?.data?.data?.messages);
        if (res?.data?.data?.messages?.length == 0) {
          setShowViewMore(false);
        }
        setRecipientIdRep(res?.data?.data?.recipient_id);
        setRecipientName(res?.data?.data?.recipient_name);
        setRecipientUrl(res?.data?.data?.recipient_url);
        setReplyAbility(res?.data?.data?.reply_ability_status);
      } else {
        var text_messages_container = $('.text-messages-container');
        text_messages_container.scrollTop(text_messages_container.prop('scrollHeight'));
        $('.message-textarea').focus();
      }
    });
  };

  /**
   * Load more
   * @param {*} recipient_id
   */

  const SK_getPreviousMessages = (recipient_id) => {
    setLoadingPreviousMsgs(true);

    $('#message_recipient_' + recipient_id)
      .find('.update-alert')
      .hide();
    var before_message_id = $('.text-messages-wrapper .message-block:eq(0) .msg-content').attr(
      'data-message-id'
    );
    var url = '/ajax/socialAjax?t=message&a=load_previous_conversation';
    var dataObject = {
      recipient_id: recipient_id_rep,
      timeline_id: timelineIdRep,
      before_message_id: before_message_id
    };

    AuthServices.callAjaxGetMethod(url, dataObject).then((res) => {
      setLoadingPreviousMsgs(false);

      if (res?.status === 200) {
        if (res?.data?.messages?.length == 0) {
          setShowViewMore(false);
        } else {
          Object.keys(res?.data?.messages.reverse()).map((index) => {
            var date_wrapper = res?.data?.messages[index];

            Object.keys(date_wrapper).map((date) => {
              var messages = date_wrapper[date];
              var search = false;
              var desired_key = date;

              Object.keys(messagesResponse).map((k) => {
                var main_date_wrapper = messagesResponse[k];

                Object.keys(main_date_wrapper).map((key) => {
                  var value = main_date_wrapper[key];

                  if (key == desired_key) {
                    search = true;

                    Object.keys(messages.reverse()).map((ky) => {
                      var message = messages[ky];

                      var new_messages = message.messages;
                      var timeline = message.account;

                      var firstMessageBy = messagesResponse[k][key][0].account.id;
                      if (firstMessageBy == timeline.id) {
                        new_messages.push.apply(
                          new_messages,
                          messagesResponse[k][key][0]['messages']
                        );
                        messagesResponse[k][key][0].messages = new_messages;

                        setMessagesResponse(messagesResponse);
                      } else {
                        var new_messages_block = [];
                        new_messages_block[0] = message;
                        new_messages_block.push.apply(new_messages_block, messagesResponse[k][key]);
                        messagesResponse[k][key] = new_messages_block;

                        setMessagesResponse(messagesResponse);
                      }
                    });
                  } // end if
                }); // main_date_wrapper
              }); // messagesResponse

              if (search == false) {
                var new_date_block = [];
                new_date_block[0] = date_wrapper;
                new_date_block.push.apply(new_date_block, messagesResponse);
                setMessagesResponse(new_date_block);
              }
            });
          }); // main loop
        } // end else

        var text_messages_container = $('.text-messages-container');
        var ammount =
          $('#message_' + before_message_id).offset().top -
          text_messages_container.offset().top +
          text_messages_container.scrollTop();
        text_messages_container.animate({ scrollTop: ammount }, 100);
      } // end if status 200
    });
  };

  const SK_getNewMessages = function () {
    var url = '/ajax/socialAjax?t=message&a=load_new_conversation';
    var dataObject = { recipient_id: recipient_id_rep, timeline_id: timelineIdRep };

    // Service to return all reviewslist

    AuthServices.callAjaxGetMethod(url, dataObject).then((res) => {
      if (res?.status === 200) {
        if (res?.data?.messages?.length != 0) {
          // need to setmessagesResponse

          Object.keys(res?.data?.messages).map((date_wrapper_key) => {
            var date_wrapper = res?.data?.messages[date_wrapper_key];

            Object.keys(date_wrapper).map((date) => {
              var search = false;
              var desired_key = date;

              var messages = date_wrapper[desired_key];

              // Same date msg

              console.log('new messages', messages);

              Object.keys(messagesResponse).map((k) => {
                var main_date_wrapper = messagesResponse[k];

                Object.keys(main_date_wrapper).map((key) => {
                  console.log(key, desired_key);

                  if (key == desired_key) {
                    search = true;

                    // INNER LOOP

                    console.log('INNER DATE', messages);

                    Object.keys(messages).map((ky) => {
                      var message = messages[ky]; // Message array - only use in else part

                      console.log('new messages loop', message);

                      var new_messages = message.messages;
                      var timeline = message.account;

                      var lastMessageBy =
                        messagesResponse[k][key][messagesResponse[k][key].length - 1].account.id;

                      console.log(lastMessageBy + '--' + timeline.id);

                      if (lastMessageBy == timeline.id) {
                        // last date array messages

                        let lastmessage =
                          messagesResponse[k][key][messagesResponse[k][key].length - 1].messages;

                        let set_new_message = messagesResponse[k][key][
                          messagesResponse[k][key].length - 1
                        ].messages.push.apply(
                          messagesResponse[k][key][messagesResponse[k][key].length - 1].messages,
                          new_messages
                        );

                        console.log('LOAD IF', Object.values(set_new_message));

                        //setMessagesResponse([set_new_message]);
                        // setMessagesResponse(refvar => ({
                        //   ...refvar,
                        //   [ id ]: e.target.value
                        // }))
                        //const newArray = [...messagesResponse, {...result, id:3}];
                        //setMessagesResponse(newArray);

                        setMessagesResponse((messagesResponse) => [
                          ...messagesResponse,
                          set_new_message
                        ]);
                      } else {
                        console.log('LOAD ELSE');

                        var set_new_message = messagesResponse[k][key].push.apply(
                          messagesResponse[k][key],
                          [message]
                        );

                        setMessagesResponse([...messagesResponse, set_new_message]);
                      }
                    });
                  }
                });
              });

              if (search == false) {
                console.log('LOAD OUT', messagesResponse);

                //setMessagesResponse(messagesResponse => [...messagesResponse, [messages]]);

                var new_date_block = [];
                new_date_block[desired_key] = messages;

                //let set_new_message = messagesResponse.push.apply(messagesResponse, new_date_block);
                //setMessagesResponse(set_new_message);

                // setMessagesResponse(messagesResponse => [...messagesResponse, new_date_block]);
                // let isExist = false;
                // let replaceIndex;
                // for (let index = 0; index < messagesResponse.length; index++) {
                //   const item = messagesResponse[index];
                //   if (Object.keys(item).find(desired_key)) {
                //     isExist = true;
                //     replaceIndex = index;
                //     break;

                //   }
                // }
                // console.log("isExist", isExist, replaceIndex)
                // if (isExist) {

                // }

                setMessagesResponse((messagesResponse) => [
                  ...messagesResponse,
                  { [desired_key]: messages }
                ]);

                //setMessagesResponse(set_new_message); // running
              }
            });
          }); // end main loop

          var timer_scroll = setTimeout(() => {
            var text_messages_container = $('.text-messages-container');
            var myDiv = document.getElementById('message_inner_chat_id');
            myDiv.scroll(0, text_messages_container.prop('scrollHeight'));
          }, 1000);

          //clearTimeout(timer_scroll);

          // const timer = setTimeout(() => {

          //   var text_messages_container = $('.text-messages-container');
          //   var textarea_wrapper = $('.message-textarea');

          //   var ammount = $(".text-messages-wrapper .text-message-wrapper:last-child .media-body>.msg-content:last-child").offset().top - text_messages_container.offset().top + text_messages_container.scrollTop();
          //   var bottom_space = text_messages_container.prop('scrollHeight') - ammount;

          //   if (bottom_space && bottom_space > 200) {
          //     text_messages_container.animate({ scrollTop: text_messages_container.prop('scrollHeight') }, 300);

          //   } else {
          //     text_messages_container.animate({ scrollTop: text_messages_container.prop('scrollHeight') }, 300);
          //   }

          //   if (!textarea_wrapper.is(':focus')) {
          //     document.title = '(New!) ' + document_title;
          //   }

          // }, 1000);

          SK_updateRecipients();
        }
      }
    });
  };

  const myFormRef = useRef(null); // ref => { current: null }

  const SK_sendMessageForm = (e) => {
    document.title = document_title;

    if (e.keyCode == 13 && e.shiftKey == 0) {
      e.preventDefault();
      SK_sendMessage('typeText');
    }
  };

  const SK_uploadMessageForm = (e) => {
    document.title = document_title;
    //
    let file = e.target.files[0];
    let fileSize = file.size / 1024;

    if (fileSize > 500) {
      alert('File size should be less than 1 mb');
    } else {
      SK_sendMessage('typeFile');
    }
  };

  const SK_updateRecipients = () => {
    var queryTypedData = $('.recipient-search').val();

    var url = '/ajax/socialAjax?t=search&a=recipients';

    var dataObject = {
      active: recipient_id_rep,
      timeline_id: timelineIdRep,
      q: queryTypedData,
      without_html: '1'
    };

    AuthServices.callAjaxGetMethod(url, dataObject).then((res) => {
      if (res?.status === 200) {
        if (res?.data?.new_messages_count > 0) {
          setMessageRecipients(res?.data?.message_recipients);
        }
      }
    });
  };

  function updateObjects(object, addValue, k, key) {
    //return objects.map((object) => {
    Object.keys(object).forEach((property) => {
      object[property] = addValue;
    });

    return object;
    //});
  }

  /**
   *
   * Send message
   *
   */

  const SK_sendMessage = (type) => {
    var submitting = true;

    setLoading(true);
    var formData = new FormData($('.send-message-form')[0]);

    if (type != 'typeFile') {
      for (var pair of formData.entries()) {
        if (pair[0] == 'text' && pair[1]?.trim() == '') {
          showNotification('danger', "Message can't be blank.");
          setLoading(false);
          return;
        }
      }
    }

    AuthServices.postMessage(formData).then((res) => {
      submitting = false;

      setLoading(false);

      if (res?.status === 200) {
        var search = false;
        var messagesResp;

        Object.keys(res?.data?.messages).map((value) => {
          var desired_key = value;

          var messagesResp = res?.data?.messages[value][0].messages;
          var timeline = res?.data?.messages[value][0].account;

          const messagesResponseNew = messagesResponse.map((v, k) => {
            Object.keys(v).map((key) => {
              if (key == desired_key) {
                search = true;
                var lastMessageBy =
                  messagesResponse[k][key][messagesResponse[k][key].length - 1].account.id;
                if (lastMessageBy == timeline.id) {
                  let newlyAddedMsg1 = messagesResponse[k][key][
                    messagesResponse[k][key].length - 1
                  ].messages.push.apply(
                    messagesResponse[k][key][messagesResponse[k][key].length - 1].messages,
                    messagesResp
                  );

                  //setMessagesResponse([res?.data?.messages]);
                  setMessagesResponse([...messagesResponse]);

                  // if (messagesResponse?.length !== 0) {
                  //   setMessagesResponse([...messagesResponse]);
                  // } else {
                  //   setMessagesResponse([res?.data?.messages]);
                  // }
                } else {
                  // var newlyAddedMsg2 = messagesResponse[k][key].push.apply(
                  //   messagesResponse[k][key],
                  //   res?.data?.messages[value]
                  // );

                  //setMessagesResponse([newlyAddedMsg2]);

                  var set_new_message = messagesResponse[k][key].push.apply(
                    messagesResponse[k][key],
                    res?.data?.messages[value]
                  );

                  setMessagesResponse([...messagesResponse, set_new_message]);

                  //let messagesResponseTemp = messagesResponse[k][key];

                  //let objectResp = updateObjects(messagesResponse[k][key], res?.data?.messages, k, key);

                  //setMessagesResponse(messagesResponseTemp => [messagesResponseTemp, res?.data?.messages]);
                }
              }
            });
          });
        });

        // Direct Message append

        if (search == false) {
          //$scope.messages[$scope.messages.length] = d.messages;

          setMessagesResponse((messagesResponse) => [...messagesResponse, res?.data?.messages]);
        }

        const timer = setTimeout(() => {
          $('.send-message-form')[0].reset();
          $('.message-textarea').val('').focus();

          var text_messages_container = $('.text-messages-container');
          var myDiv = document.getElementById('message_inner_chat_id');
          myDiv.scroll(0, text_messages_container.prop('scrollHeight'));
        }, 1000);

        //clearTimeout(timer);

        SK_updateRecipients();
      } else {
        $('#showMsg').html(res?.detail).removeClass('hide alert-success').addClass('alert-danger');
      }
    });
  };

  // Use in search

  var searching = true;

  // Service to return all reviewslist

  const SK_getRecipients = (searchData) => {
    var queryTypedData = $('.recipient-search').val();

    var url = '/ajax/socialAjax?t=search&a=search_recipients';
    var dataObject = {
      active: recipient_id_rep,
      timeline_id: timelineIdRep,
      q: queryTypedData,
      without_html: '1'
    };

    AuthServices.callAjaxGetMethod(url, dataObject).then((res) => {
      if (res?.status === 200) {
        searching = false;

        setMessageRecipients(res?.data.message_recipients);
      }
    });
  };

  var typingTimer;
  var doneTypingInterval = 400;

  const inputChanged = (e) => {
    SK_getRecipients(e.target.value);
  };

  const showModal = (src) => {
    let modal = document.getElementById('myModal');
    modal.style.display = 'block';
    setModalImg(src);
  };

  // END API
  return (
    <>
      <HeaderLogin />
      <div className="header-lnk-feeds">
        <div className="container">
          <TopTabbings state={'social'} />
        </div>
      </div>
      <section className="news-feed-sec messages-sec bg-grey py-4 ">
        <Container>
          <Row>
            <Col xs={12} md={12} lg={2}>
              <NewsSidebar state="1" current="messages" />
            </Col>
            <Col xs={12} md={12} lg={7}>
              <div className="message-chat-main box  text-messages-container" id="containerDiv">
                <div className="message_header">
                  Conversation with
                  <Link to={recipient_url}> {recipient_name} </Link>
                </div>

                <div className="message_inner_chat" id="message_inner_chat_id">
                  {/* Messages  */}

                  {showViewMore ? (
                    <div
                      className="view-more-wrapper cursor-hand bold-500"
                      role="none"
                      onClick={() => SK_getPreviousMessages(recipient_id_rep)}>
                      {loadingPreviousMsgs ? (
                        <i className="fa fa-spinner fa-spin"></i>
                      ) : (
                        <span>View More</span>
                      )}
                    </div>
                  ) : (
                    <></>
                  )}

                  <div className="text-messages-wrapper">
                    {messagesResponse && messagesResponse.length > 0 ? (
                      messagesResponse.map((data, index) => (
                        <div className="message-block" key={index}>
                          {Object.keys(data).map((item) => {
                            return (
                              <>
                                <div className="block">
                                  <div className="date-sep">
                                    <span className="date-val">{item}</span>
                                  </div>

                                  {data[item]?.map((chat) => {
                                    return (
                                      <>
                                        <div
                                          className={
                                            chat?.account.id == timelineIdRep
                                              ? 'one-message text-message-wrapper user-message-wrapper'
                                              : 'one-message text-message-wrapper'
                                          }>
                                          <div className="media">
                                            <div
                                              className={
                                                chat?.account?.id == timelineIdRep
                                                  ? 'media-left pull-right message-right'
                                                  : 'media-left pull-left message-left'
                                              }>
                                              <a
                                                href={
                                                  '/user/timeline/' + chat?.account?.encrypted_id
                                                }
                                                target="_blank"
                                                rel="noreferrer">
                                                <img
                                                  alt={chat?.account.name}
                                                  className="img-circle"
                                                  src={getMedia(chat?.account?.mediaObject)?.url}
                                                />
                                              </a>
                                            </div>

                                            <div className="media-body">
                                              {chat?.messages?.map((message) => {
                                                return (
                                                  <>
                                                    <div
                                                      className="msg-content mb-2"
                                                      id={`message_${message.id}`}
                                                      data-message-id={message.id}>
                                                      <div
                                                        className="msg"
                                                        data-toggle="tooltip"
                                                        data-placement={
                                                          chat?.account?.id == timelineIdRep
                                                            ? 'left'
                                                            : 'right'
                                                        }
                                                        title={moment(message.time * 1000).format(
                                                          'ddd hh:mm:ss a'
                                                        )}>
                                                        <span className="msg-text">
                                                          {' '}
                                                          {message?.text}{' '}
                                                        </span>

                                                        {message?.media_id > 0 ? (
                                                          <div
                                                            className="photo-wrapper"
                                                            style={{ textAlign: 'left' }}>
                                                            <img
                                                              ref={imgRef}
                                                              id="myImg"
                                                              onClick={() => {
                                                                showModal(
                                                                  message?.media?.complete_url
                                                                );
                                                              }}
                                                              className={msgStyles.myImg}
                                                              src={message?.media?.complete_url}
                                                              alt={chat?.account.name}
                                                            />
                                                          </div>
                                                        ) : (
                                                          <></>
                                                        )}
                                                      </div>
                                                    </div>
                                                  </>
                                                );
                                              })}
                                            </div>
                                          </div>
                                        </div>
                                      </>
                                    );
                                  })}
                                </div>
                              </>
                            );
                          })}
                        </div>
                      ))
                    ) : (
                      <div className="no-wrapper">No Messages</div>
                    )}
                  </div>

                  {/* END Messages  */}

                  {/* <div className="time_bar_border">
                    <span> November 16, 2022</span>
                  </div>

                  <div className="message-left">
                    <div className="message_text">
                      <p>
                        Heartiest Congratulations on your work anniversary. Wish you a great success
                        ahead.
                      </p>
                    </div>
                    <div className="msg_profile_img">
                      <img
                        src={require('../../../assets/images/engage-icon.jpg')}
                        alt="engage icon"
                      />
                    </div>
                  </div>

                  <div className="message-right">
                    <div className="msg_profile_img">
                      <img
                        src={require('../../../assets/images/engage-icon.jpg')}
                        alt="engage icon"
                      />
                    </div>
                    <div className="message_text">
                      <p>
                        Heartiest Congratulations on your work anniversary. Wish you a great success
                        ahead.
                      </p>
                      <div className="img_upload">
                        <img src={require('../../../assets/images/flat-panel.jpg')} alt="upload" />
                      </div>
                    </div>
                  </div>

                  <div className="time_bar_border">
                    <span> November 16, 2022</span>
                  </div>

                  <div className="message-left">
                    <div className="message_text">
                      <p>
                        Heartiest Congratulations on your work anniversary. Wish you a great success
                        ahead.
                      </p>
                    </div>
                    <div className="msg_profile_img">
                      <img
                        src={require('../../../assets/images/engage-icon.jpg')}
                        alt="engage icon"
                      />
                    </div>
                  </div> */}
                </div>

                <div className="message_footer textarea-container">
                  <Form ref={myFormRef} className="d-flex send-message-form position-relative">
                    <Form.Group className="w-100" controlId="exampleForm.ControlTextarea1">
                      <Form.Control
                        as="textarea"
                        rows={3}
                        placeholder={
                          reply_ability == true ? 'Write a message...' : 'Write a message...' //'You cannot reply to this conversation'
                        }
                        onKeyDown={SK_sendMessageForm}
                        onFocus={SK_sendMessageForm}
                        name="text"
                        controlId="message-textarea"
                      />
                      <input
                        className="message-photo-input hidden"
                        name="photos[]"
                        type="file"
                        accept="image/jpeg,image/png"
                        onChange={SK_uploadMessageForm}
                      />
                      <input name="timeline_id" value={timelineIdRep} type="hidden" />
                      <input
                        id="recipient-id"
                        name="recipient_id"
                        value={recipient_id_rep}
                        type="hidden"
                      />
                    </Form.Group>

                    <div className={`options-wrapper`}>
                      <i
                        className="fa fa-camera progress-icon cursor-hand"
                        role="button"
                        tabIndex="0"
                        onClick={() => $('.message-photo-input').click()}></i>
                    </div>
                  </Form>
                </div>
              </div>
            </Col>
            <Col xs={12} md={12} lg={3}>
              <div className="messages-usr-lst message-recipient-wrapper">
                <div className="message-srch-card">
                  <Form.Group className="mb-0 search-card" controlId="formBasicSearch">
                    <Form.Control
                      type="search"
                      placeholder="Search"
                      className="recipient-search"
                      onKeyUp={inputChanged}
                    />
                    <button className="search-btn" onClick={() => $('.recipient-search').focus()}>
                      <i className="fas fa-search"></i>
                    </button>
                  </Form.Group>
                </div>
                <ul className="message-usr">
                  {messageRecipients && messageRecipients.length > 0 ? (
                    messageRecipients.map((recipient, index) => {
                      return (
                        <li key={index}>
                          <div
                            role="none"
                            id={`message_recipient_${recipient.id}`}
                            className="recipient-list cursor-hand media innerAll half"
                            onClick={() => {
                              SK_getMessages(
                                location?.state !== null ? location?.state : recipient?.id
                              );
                              setEncryptId(recipient?.encrypted_id);
                            }}>
                            <table border="0" width="100%" cellSpacing="0" cellPadding="0">
                              <tr>
                                <td width="50px" align="left" valign="middle">
                                  <div className="media-object pull-left hidden-phone">
                                    <img
                                      src={getMedia(recipient?.mediaObject)?.url}
                                      width="38px"
                                      height="38px"
                                      className="recipient-avatar allow-user-tultip img-circle"
                                      alt={recipient?.name}
                                      data-userid={recipient?.id}
                                      data-type={recipient?.type}
                                    />
                                  </div>
                                </td>
                                <td align="left" valign="middle">
                                  <div className="media-body">
                                    <span className="name-max-width">
                                      <span
                                        className="bold-500 strong text-overflow-hidden"
                                        title={recipient?.name}>
                                        {' '}
                                        {recipient?.name}{' '}
                                      </span>

                                      {
                                        // (recipient?.last_messaged_by == timelineIdRep) ?
                                        //   <span className="last_message text-overflow-hidden" >
                                        //     You : {recipient?.message_text}
                                        //   </span>
                                        //   :
                                        //   <></>
                                      }

                                      {
                                        // (recipient?.last_messaged_by != timelineIdRep) ?
                                        //   <span className={recipient.msg_seen == '0' ? 'last_message text-overflow-hidden strong' : 'last_message text-overflow-hidden'} >
                                        //     {recipient?.message_text}
                                        //   </span>
                                        //   :
                                        //   <></>
                                      }
                                    </span>

                                    <i
                                      className={
                                        recipient?.online
                                          ? 'fa fa-circle online-icon active '
                                          : 'fa fa-circle online-icon'
                                      }></i>

                                    {recipient?.message_unread_count > 0 ? (
                                      <span>{recipient?.message_unread_count}</span>
                                    ) : (
                                      <></>
                                    )}
                                  </div>
                                </td>
                              </tr>
                            </table>
                          </div>
                        </li>
                      );
                    })
                  ) : (
                    <li>
                      <div className="recipient-list cursor-hand media innerAll half">
                        <strong>Sorry ! No recipients found.</strong>
                      </div>
                    </li>
                  )}

                  {/* <li>
                    <Link to="#">
                      <div className="messg-usr-detail active d-flex align-items-center justify-content-between">
                        <div className="message-dis d-flex align-items-center">
                          <div className="message-icn">
                            <img
                              src={require('../../../assets/images/default-male-avatar.png')}
                              alt="img"
                            />
                          </div>
                          <div className="user-message-higlight">
                            <h4>Nishant Kango</h4>
                            <p>Image in message</p>
                          </div>
                        </div>
                        <span className="chat-active"></span>
                      </div>
                    </Link>
                  </li> */}
                </ul>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <div ref={modalRef} id="myModal" className={msgStyles.modal}>
        <div className={msgStyles.fancyboxSkin}>
          <div
            ref={closeRef}
            onClick={() => {
              let modal = document.getElementById('myModal');
              modal.style.display = 'none';
            }}
            className={msgStyles.close}>
            <span>
              <i className="fas fa-times"></i>
            </span>
          </div>
          <img src={modalImg} className={msgStyles.modalContent} id="img01" />
          <div id="caption" className={msgStyles.fancyboxTitle}>
            {/* <span></span> */}
          </div>
        </div>
      </div>

      {loading ? <Loader /> : ''}
    </>
  );
}

export default MessagesPage;
