/** @copyright : ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
 @author     : Shiv Charan Panjeta 

 All Rights Reserved.
 Proprietary and confidential :  All information contained herein is, and remains
 the property of ToXSL Technologies Pvt. Ltd. and its partners.
 Unauthorized copying of this file, via any medium is strictly prohibited.
*/

import { useEffect, useState } from 'react';
import { Button, Container } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { useLocation, useNavigate } from 'react-router-dom';
import { addRole, getRoleDetail, updateRole } from '../../services/AuthServices';
import showNotification from '../../services/NotificationService';

export default function Roles() {
  const [roleDetail, setRoleDeatil] = useState({
    role_name: '',
    status: ''
  });
  const [clicked, setClicked] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const id = location.state;

  useEffect(() => {
    if (id) {
      getDetail(id);
    }
  }, []);
  const getDetail = (id) => {
    getRoleDetail(id).then((res) => {
      if (res?.status === 200) {
        const data = res?.data?.data;
        setRoleDeatil({
          role_name: data?.role_name,
          status: data?.status,
          id: data?.id
        });
      }
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setRoleDeatil({ ...roleDetail, [name]: value });
  };

  const validate = () => {
    let errors = {};
    if (roleDetail?.role_name == '') {
      errors.role_name = 'Please enter role name ';
    }
    if (roleDetail?.status == '') {
      errors.status = 'Please select status';
    }

    return errors;
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    setClicked(true);

    if (!Object.keys(validate()).length) {
      let formData = new FormData();
      for (let i in roleDetail) {
        formData.append(`${i}`, roleDetail[i]);
      }
      if (!id) {
        addRole(formData).then((res) => {
          if (res?.status === 200) {
            showNotification('success', res?.data?.data);
            navigate('/admin/users/roles');
            setRoleDeatil({
              role_name: '',
              status: ''
            });
          }
        });
      } else {
        updateRole(formData).then((res) => {
          if (res?.status === 200) {
            showNotification('success', res?.data?.data);
            navigate('/admin/users/roles');
            setRoleDeatil({
              role_name: '',
              status: ''
            });
          }
        });
      }
    }
  };

  const handleCancel = () => {
    navigate('/admin/users/roles');
    setRoleDeatil({
      role_name: '',
      status: ''
    });
  };

  return (
    <div className="dash-main-content">
      <Container fluid>
        <div className="dash-page-content">
          <div className="dash-page-title">
            <Row>
              <Col xs={12} sm={12} md={12}>
                <div className="dash-title-bar d-flex align-items-center justify-content-between">
                  <div className="title-lft d-flex align-items-center">
                    <div className="dash-title-dis">
                      <i className="fa fa-users mt-1 me-2"></i>{' '}
                      <h5>{id ? 'Edit Role' : 'Add Role'}</h5>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <div className="dash-content-card">
            <div className="cmmn-form">
              <Form>
                <Row>
                  <Col md={4}>
                    <Form.Group className="mb-3" controlId="formBasicName">
                      <Form.Label>
                        First Name <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Role Name"
                        name="role_name"
                        onChange={handleChange}
                        value={roleDetail?.role_name}
                      />
                      <div className={'danger-frm'}>{clicked && validate().role_name}</div>
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group className="mb-3" controlId="formBasicState">
                      <Form.Label>
                        Select Status <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Select
                        aria-label="Default select example"
                        name="status"
                        onChange={handleChange}
                        value={roleDetail?.status}>
                        <option value="">Select Status</option>
                        <option value="0">Enable</option>
                        <option value="1">Disable</option>
                      </Form.Select>
                      <div className={'danger-frm'}>{clicked && validate().status}</div>
                    </Form.Group>
                  </Col>
                  <Col md={4}></Col>
                </Row>
                <div className="form-btns d-flex align-items-center mt-3">
                  <Button className="main-btn-grn" type="button" onClick={handleSubmit}>
                    {id ? 'Edit' : 'Save'}
                  </Button>
                  <strong>OR</strong>
                  <Button className="main-btn-red" type="button" onClick={handleCancel}>
                    Cancel
                  </Button>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}
