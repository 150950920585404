import { useEffect, useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link } from 'react-router-dom';
import showNotification from '../../services/NotificationService';
import * as adminServices from '../../services/AuthServices';
import { logout } from '../../redux/action';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Button from 'react-bootstrap/Button';
import SideBar from './Sidebar';
import { useSelector } from 'react-redux';

export default function Header() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isloggedIn = useSelector((state) => state?.user?.isloggedIn);

  useEffect(() => {
    if (!isloggedIn) {
      localStorage.clear();
      navigate('/admin');
    }
  }, []);

  const logOutHandler = (e) => {
    e.preventDefault();
    adminServices.adminLogout().then((res) => {
      if (res?.status === 200) {
        localStorage.clear();
        dispatch(logout());
        showNotification('success', res?.data?.message);
        navigate('/admin');
      }
    });
  };

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <div className="dash-header">
      <Row className="align-items-center">
        <Col sm={6} md={6} xs={6}>
          <div className="dash-logo">
            <Link to="/admin">
              <img src={require('../../assets/images/almabay-logo-dash.png')} alt="img" />
            </Link>
          </div>
        </Col>
        <Col sm={6} md={6} xs={6}>
          <div className="dash-logout d-flex align-items-center justify-content-end">
            <Button
              className="mobile-side-bar me-4 d-block d-lg-none"
              variant="primary"
              onClick={handleShow}
            >
              <i className="fas fa-bars"></i>
            </Button>
            <Link
              to="#"
              onClick={(e) => {
                logOutHandler(e);
              }}
            >
              <i className="fas fa-sign-out-alt me-2"></i>
              Logout
            </Link>
          </div>
        </Col>
      </Row>
      <Offcanvas show={show} onHide={handleClose} className="mobile-side-cntnt">
        <Offcanvas.Body>
          <SideBar />
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
}
