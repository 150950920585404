import { useState, useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import HeaderLogin from '../../commoncomponents/HeaderLogin';
import TopTabbings from './TopTabbings';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import SuggestMentor from '../../commoncomponents/newsfeeds/SuggestMentor';
import GroupDiscover from '../../commoncomponents/newsfeeds/GroupDiscover';
import WhatsNew from '../../commoncomponents/newsfeeds/WhatsNew';
import Upcoming from '../../commoncomponents/newsfeeds/Upcoming';
import NewsSidebar from '../../commoncomponents/newsfeeds/NewsSidebar';
import showNotification from '../../../services/NotificationService';
import Form from 'react-bootstrap/Form';
import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';
import * as APISERVICE from '../../../services/MentorArticlesServices';
import TinyMceEditor from '../../commoncomponents/TinyMceEditor';
import useDocumentTitle from '../../../globals/useDocumentTitle';
import { MiniFooter } from '../../MiniFooter';
import { isArray } from 'jquery';
import AsyncSelect from 'react-select/async';

function AskQuestion() {
  useDocumentTitle('AlmaBay');
  const [detail, setDetail] = useState({
    title: '',
    description: '',
    tag_fields: ''
  });
  const [toSendAttach, setToSendAttch] = useState([]);
  const [fileName, setFileName] = useState([]);
  const [attachedFiles, setAttachedFiles] = useState([]);
  const [tagField, setTagField] = useState([]);
  const [selectedTagField, setSelectedTagField] = useState([]);
  const navigate = useNavigate();
  const [isClicked, setIsClicked] = useState(false);
  const [searchParams] = useSearchParams();
  const [loading, setLoading] = useState(false);
  const id = searchParams.get('id');
  const [mentorList, setMentorList] = useState([]);
  const [removeAskMentor, setRemoveAskMentor] = useState(true);
  const [privacy, setPrivacy] = useState('first');

  const [askingList, setAskingList] = useState([]);
  const [listData, setListData] = useState([]);

  useEffect(() => {
    if (id) {
      getDetail();
    }
    getTagFieldList();
    getMentorList();
  }, []);

  const getDetail = () => {
    APISERVICE.questionDetail(id).then((res) => {
      if (res?.status === 200) {
        const { description, tag_fields, title, topics_name, docs, privacy_type } =
          res.data.detail.question[0];
        setDetail({
          title,
          description,
          tag_fields
        });
        let arr = [];
        // tag_fields?.split(',')?.map((id, index) => {
        //   let topic = topics_name?.split(',');
        //   arr.push({ id: id, value: topic[index], label: topic[index] });
        // });

        tag_fields?.map((data) => {
          return arr.push({ id: data?.id, value: data?.name, label: data?.name });
        });
        setSelectedTagField(arr);
        setAttachedFiles(docs);
        setPrivacy(privacy_type == 'private' ? 'second' : 'first');

        let mentorId = res.data.detail.question[0]?.mentor_ids?.split(',');
        APISERVICE.getUserMentor().then((res) => {
          if (res?.status === 200) {
            // for (var i = 0; i <= mentorId?.length; i++) {
            //   result = res?.data?.detail?.mentors?.filter((data) => data?.user_id == mentorId[i]);
            // }
            let result = res?.data?.detail?.mentors.filter((item) =>
              mentorId.includes(item?.user_id)
            );
            let mentoList = res?.data?.detail?.mentors.filter(
              (item) => !mentorId.includes(item?.user_id)
            );

            setAskingList(result);
            setMentorList(mentoList);
          }
        });
      }
    });
  };

  const getTagFieldList = () => {
    APISERVICE.getTopicList().then((res) => {
      if (res?.status === 200) {
        let arr = [];
        res?.data?.data?.map((data) => {
          arr.push({ id: data?.id, value: data?.name, label: data?.name });
        });
        setTagField(arr);
      }
    });
  };

  const onChangeHandler = (e, select = false, name = '', data = '') => {
    if (select) {
      if (name == 'description') {
        setDetail({ ...detail, [name]: data });
      } else {
        let selectedOpt = [];
        e?.map((data) => {
          selectedOpt.push(data?.value);
        });
        setSelectedTagField(e);
        setDetail({ ...detail, [name]: selectedOpt.join() });
      }
    } else {
      const { name, value, type } = e.target;
      if (type === 'file') {
        setFileName(e.target.files[0]);
        let fileData = e.target.files;
        for (let i = 0; i < fileData.length; i++) {
          toSendAttach.push(fileData[i]);
        }
        setToSendAttch(toSendAttach);
      } else {
        setDetail({ ...detail, [name]: value });
      }
    }
  };

  const validate = (value) => {
    let errors = {};
    if (!value.title.trim()) {
      errors.title = 'This field is required';
    } else if (value.title.trim().length < 5) {
      errors.title = 'Title must contain 6 character';
    }
    if (!value.description) {
      errors.description = 'This field is required';
    }

    // if (privacy !== 'second') {
    //   if (!value.tag_fields) {
    //     errors.tag_fields = 'This field is required';
    //   }
    // }

    return errors;
  };

  const submitHandler = (e) => {
    e.preventDefault();
    setIsClicked(true);

    if (!Object.keys(validate(detail)).length) {
      // if (detail?.tag_fields != '') {
      if (selectedTagField.length < 1 && privacy === 'first') {
        showNotification('danger', 'Kindly tag your question to make public or select mentors');
        setLoading(false);
        return;
      } else {
        setLoading(true);
        let formData = new FormData();
        for (let i in detail) {
          if (privacy == 'second' && i == 'tag_fields') {
            // do not include
          } else {
            formData.append(`${i}`, detail[i]);
          }
        }
        if (privacy == 'second') {
          let mentorIds = askingList.map((item) => item.user_id).join(',');
          formData.append(`mentor_id`, mentorIds);
        }

        formData.append(`content_type`, 'question');

        for (let i in toSendAttach) {
          formData.append(`qdocs[]`, toSendAttach[i]);
        }
        APISERVICE.askAQuestion(formData).then((res) => {
          if (res?.status === 200) {
            setLoading(false);
            showNotification('success', res?.data?.message);
            navigate(`/user/viewanswer/${res?.data?.question_id}`);
          }
        });
      }

      // }
      //  else {
      // showNotification('danger', 'Kindly tag your question to make public or select mentors');
      // setLoading(false);
      // }
    }
  };

  const updateHandler = (e) => {
    e.preventDefault();
    setIsClicked(true);
    if (!Object.keys(validate(detail)).length) {
      let formData = new FormData();

      if (privacy == 'second') {
        formData.append(`title`, detail?.title);
        let mentorIds = askingList.map((item) => item.user_id).join(',');
        formData.append(`mentor_id`, mentorIds);
        formData.append('description', detail?.description);
      } else {
        formData.append('description', detail?.description);
        formData.append(
          'tag_fields',

          isArray(detail?.tag_fields)
            ? detail?.tag_fields?.map((data) => data?.name)
            : detail?.tag_fields
        );
        formData.append(`title`, detail?.title);
      }
      for (let i in toSendAttach) {
        formData.append(`qdocs[]`, toSendAttach[i]);
      }
      formData.append(`content_type`, 'question');
      APISERVICE.updateMyQuestion(id, formData).then((res) => {
        if (res?.status === 200) {
          showNotification('success', res?.data?.detail);
          navigate('/user/qa');
        }
      });
    } else {
      return;
    }
  };

  const deleteAttachmentHandler = (e, id) => {
    e.preventDefault();
    // //mentorship/qaFeeds/deleteAttachment?id=30
    APISERVICE.deleteAttachmentFile(id).then((res) => {
      if (res?.status === 200) {
        showNotification('success', res?.data?.message);
        getDetail();
      }
    });
  };

  //Mentor List

  const getMentorList = () => {
    APISERVICE.getUserMentor().then((res) => {
      if (res?.status === 200) {
        setMentorList(res?.data?.detail?.mentors);
        setListData(res?.data?.detail?.mentors);
      }
    });
  };

  const handleAskMentor = (data) => {
    if (!askingList.find((item) => item.user_id == data.user_id)) {
      setAskingList([...askingList, data]);

      // remove from other list
      let removeIndex = mentorList.findIndex((item) => item.user_id == data.user_id);
      setMentorList([...mentorList.slice(0, removeIndex), ...mentorList.slice(removeIndex + 1)]);
    } else {
      let result = askingList.filter((item) => item.user_id !== data.user_id);
      setAskingList(result);
      setMentorList([data, ...mentorList]);
    }
  };
  const handleRemovAskMentor = (data) => {
    if (!askingList.find((item) => item.user_id == data.user_id)) {
      setAskingList([...askingList, data]);

      // remove from other list
      let removeIndex = mentorList.findIndex((item) => item.user_id == data.user_id);
      setMentorList([...mentorList.slice(0, removeIndex), ...mentorList.slice(removeIndex + 1)]);
    } else {
      let result = askingList.filter((item) => item.user_id !== data.user_id);
      setAskingList(result);
      setMentorList([data, ...mentorList]);
    }
  };

  const handleSearch = (event) => {
    let value = event.target.value.toLowerCase();
    if (value != '') {
      let result = mentorList.filter((data) => {
        return data.first_name.search(value) != -1 || data?.last_name.search(value) != -1;
      });
      setMentorList(result);
    } else {
      setMentorList(listData);
    }
  };

  // load options using API call
  const loadOptions = async (inputValue = 'Mohali') => {
    return await APISERVICE.getTopicList(inputValue).then((res) => {
      if (res?.status === 200) {
        let tempArr = [];
        if (res?.data?.data) {
          const data = res?.data?.data;
          data?.map((data) => {
            tempArr.push({ id: data?.id, value: data?.name, label: data?.name });
          });
        }
        return tempArr;
      }
    });
  };

  return (
    <>
      <HeaderLogin />
      <div className="header-lnk-feeds">
        <div className="container">
          <TopTabbings state={'mentor'} />
        </div>
      </div>
      <section className="news-feed-sec bg-grey py-4">
        <Container>
          <Row>
            <Col xs={12} lg={2} md={12}>
              <NewsSidebar state="2" current="askquestion" />
            </Col>
            <Col xs={12} lg={7} md={12}>
              <div className="write-article-frm bg-white p-3 mb-2">
                <h4 className="mb-3">{id ? 'Update Question' : 'Ask Question'}</h4>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Control
                    type="text"
                    value={detail?.title}
                    name="title"
                    onChange={onChangeHandler}
                    placeholder="Enter Question Title..."
                  />
                  <div
                    className={`${
                      isClicked && validate(detail)?.title ? '' : 'd-none'
                    } danger-frm `}
                  >
                    {isClicked && validate(detail)?.title}
                  </div>
                </Form.Group>

                <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                  <TinyMceEditor
                    onChangeHandler={onChangeHandler}
                    description={detail?.description}
                    placeholder={'Question Description...'}
                  />
                  <div
                    className={`${
                      isClicked && validate(detail)?.description ? '' : 'd-none'
                    } danger-frm `}
                  >
                    {isClicked && validate(detail)?.description}
                  </div>
                </Form.Group>

                <div className="attach-card-inr d-flex justify-content-between mb-3">
                  <div>
                    <div>
                      <Form.Group
                        controlId="formFile"
                        className="attach-file-card attach-file-yellow"
                      >
                        <Form.Control
                          type="file"
                          name="attach_files"
                          onChange={onChangeHandler}
                          accept=".png,.jpg,.jpeg"
                          multiple={true}
                        />
                        <span className="attach-file-inr">
                          <i className="fas fa-paperclip me-2"></i>
                          Attach Files
                        </span>
                      </Form.Group>
                    </div>
                    <span className="attach-file-name">{fileName?.name}</span>
                  </div>
                  {id ? (
                    <div className="attach-file-lst">
                      <ul>
                        {attachedFiles &&
                          attachedFiles?.map((data, index) => (
                            <li key={index}>
                              <i className="fas fa-paperclip"></i>
                              <a href={data?.doc + data?.remarks} target="__blank">
                                Attached on
                              </a>
                              <span>({data?.added_on}) |</span>
                              <Link
                                classname="trash-btn"
                                to="#"
                                onClick={(e) => {
                                  deleteAttachmentHandler(e, data?.id, data?.doc_of_id);
                                }}
                              >
                                <i className="fas fa-trash-alt"></i>
                              </Link>
                            </li>
                          ))}
                        <div
                          className={`${
                            isClicked && validate(detail)?.tag_fields ? '' : 'd-none'
                          } danger-frm `}
                        >
                          {isClicked && validate(detail)?.tag_fields}
                        </div>
                      </ul>
                    </div>
                  ) : (
                    ''
                  )}
                </div>
                <div className="ask-tabs">
                  <Tab.Container
                    id="left-tabs-example"
                    onSelect={(e) => {
                      setPrivacy(e);
                    }}
                    activeKey={privacy}
                  >
                    <Row>
                      <Col sm={12}>
                        <Nav variant="pills">
                          <Nav.Item>
                            <Nav.Link eventKey="first">Ask in Public</Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link eventKey="second">Ask your Mentor</Nav.Link>
                          </Nav.Item>
                        </Nav>
                      </Col>
                      <Col sm={12}>
                        <Tab.Content>
                          <Tab.Pane eventKey="first">
                            <div className="select-topic-card">
                              <Form.Label className="mb-0 topic-hd">Select Topics</Form.Label>
                              <AsyncSelect
                                isMulti
                                name="tag_fields"
                                className="basic-multi-select mt-3"
                                classNamePrefix="select"
                                loadOptions={loadOptions}
                                value={selectedTagField}
                                onChange={(e) => onChangeHandler(e, true, 'tag_fields')}
                                defaultOptions
                                placeholder="Add Tags to your question."
                              />
                            </div>
                          </Tab.Pane>
                          <Tab.Pane eventKey="second">
                            <div className="ask-mentor">
                              <Form.Group className="mt-2 mb-4" controlId="formBasicSearch">
                                <Form.Control
                                  type="search"
                                  placeholder="Search..."
                                  onChange={handleSearch}
                                />
                                <span>You have {mentorList?.length} Mentors</span>
                              </Form.Group>
                              <div className="assign-to-lst">
                                <h4 className="mb-4">Asking to</h4>
                                {askingList?.length !== 0 ? (
                                  <>
                                    {' '}
                                    <Row>
                                      {askingList?.map((data, index) => {
                                        return (
                                          <Col lg={6} md={12} sm={12} xs={12} key={index}>
                                            <Link
                                              onClick={(e) => {
                                                e.preventDefault();

                                                handleRemovAskMentor(data);
                                              }}
                                            >
                                              <div className="assign-to-card d-flex">
                                                <span className="assign-prof-icn me-3">
                                                  <img
                                                    src={
                                                      data?.mediaObject?.path +
                                                      '.' +
                                                      data?.mediaObject?.extension
                                                    }
                                                    alt="user_img"
                                                  />
                                                </span>

                                                <div className="assign-to-dis">
                                                  <h4>
                                                    {data?.first_name} {data?.last_name}
                                                  </h4>
                                                  <p></p>
                                                </div>
                                              </div>
                                            </Link>
                                          </Col>
                                        );
                                      })}
                                    </Row>
                                  </>
                                ) : (
                                  <p>
                                    You have not selected any mentor yet. Kindly select from
                                    following list.
                                  </p>
                                )}
                              </div>
                              <div className="assign-to-lst">
                                <h4 className="mb-4">More mentor in your list</h4>
                                <Row>
                                  {removeAskMentor ? (
                                    mentorList && mentorList ? (
                                      mentorList?.map((data, index) => {
                                        return (
                                          <Col lg={6} md={12} sm={12} xs={12} key={index}>
                                            <Link
                                              onClick={(e) => {
                                                e.preventDefault();
                                                handleAskMentor(data);
                                              }}
                                            >
                                              <div className="assign-to-card d-flex">
                                                <span className="assign-prof-icn me-3">
                                                  <img
                                                    src={
                                                      data?.mediaObject?.path +
                                                      '.' +
                                                      data?.mediaObject?.extension
                                                    }
                                                    alt="user_img"
                                                  />
                                                </span>
                                                <div className="assign-to-dis">
                                                  <h4>
                                                    {' '}
                                                    {data?.first_name} {data?.last_name}
                                                  </h4>
                                                  <p></p>
                                                </div>
                                              </div>
                                            </Link>
                                          </Col>
                                        );
                                      })
                                    ) : (
                                      <div>No mentor found</div>
                                    )
                                  ) : (
                                    <></>
                                  )}
                                </Row>
                              </div>
                            </div>
                          </Tab.Pane>
                        </Tab.Content>
                      </Col>
                    </Row>
                  </Tab.Container>
                </div>
                <div className="cmmnt-btn text-end mt-3 px-2 pt-3">
                  {id ? (
                    <button className="accnt-btn" type="button" onClick={(e) => updateHandler(e)}>
                      Update
                    </button>
                  ) : (
                    <button
                      className={`${!loading ? 'accnt-btn' : 'accnt-btn blk'}`}
                      type="button"
                      onClick={(e) => {
                        loading ? '' : submitHandler(e);
                      }}
                    >
                      Submit
                    </button>
                  )}
                </div>
              </div>
            </Col>
            <Col lg={3} md={12}>
              <div>
                <SuggestMentor />
              </div>
              <div>
                <GroupDiscover />
              </div>
              <div>
                <WhatsNew />
              </div>
              <div>
                <Upcoming />
              </div>
              <MiniFooter />
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}

export default AskQuestion;
