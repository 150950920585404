import { useEffect, useState } from 'react';
import { Form, Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import * as APISERVICES from '../../../services/SocialServices';
import Button from 'react-bootstrap/Button';
import showNotification from '../../../services/NotificationService';

function WorkAnniversary() {
  const [anniversaryData, setAnniversaryData] = useState([]);
  const handleClose3 = () => setShow3(false);
  const [show3, setShow3] = useState(false);
  const [name, setName] = useState('');
  const [message, setMessage] = useState(
    'Heartiest Congratulations on your work anniversary. Wish you a great success ahead.'
  );

  useEffect(() => {
    getAnniverseries();
  }, []);

  const getAnniverseries = () => {
    APISERVICES.getAllWorkAnniversy().then((res) => {
      if (res?.status === 200) {
        setAnniversaryData(res?.data?.data);
      }
    });
  };

  const handleSkip = (i) => {
    let filterNews = anniversaryData.filter((data, index) => index !== i);
    setAnniversaryData(filterNews);
  };

  const validate = () => {
    if (message == '') {
      return showNotification('danger', 'required field');
    }
    // return;
  };

  const handleChange = (e) => {
    const { value } = e.target;
    setMessage(value);
  };
  const handleSubmit = () => {
    if (validate()) {
      let formData = new FormData();
      formData.append('message', message);

      // API call

      // sendMessageFormConnectionPage(formData).then((res) => {
      //   if (res?.status === 200) {
      //     showNotification('success', res?.data?.message);
      //     handleClose3();
      //   }
      // });
    }
  };
  return (
    <>
      {anniversaryData !== undefined && anniversaryData?.length !== 0 && (
        <div className="suggest-mentor people-know bg-white p-3 mb-3">
          <div className="d-flex align-items-top justify-content-between flex-wrap">
            <h4>Work Anniversary</h4>
          </div>
          <ul className="mentor-ship-lst">
            {anniversaryData?.length !== 0 ? (
              anniversaryData?.map((data, index) => {
                return (
                  <li key={index}>
                    <div className="mentor-card d-flex">
                      <Link to="/" className="mentor-icn me-2">
                        <img
                          src={
                            data?.image_url
                              ? process.env.REACT_APP_API_URL + data?.image_url
                              : require('../../../assets/images/default-male-avatar.png')
                          }
                          alt="img"
                        />
                      </Link>
                      <div className="mentor-rt-dis">
                        <h4>
                          <Link to="#">{data?.name}</Link>
                        </h4>
                        <p>Completed {data?.year_to - data?.year_from} years at</p>
                        <p>{data?.organisation_name}</p>
                        <ul className="mentr-action-btn">
                          <li>
                            <Link
                              to=""
                              onClick={() => {
                                setName(data?.name);
                                setShow3(true);
                              }}
                            >
                              Say Congrats
                            </Link>
                          </li>
                          <li>
                            <Button className="skip-btn" onClick={() => handleSkip(index)}>
                              Skip
                            </Button>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </li>
                );
              })
            ) : (
              <>
                <div className="upcoming_events p-3">
                  <div className="text-center">
                    <img src={require('../../../assets/images/small-error.png')} alt="img" />
                  </div>
                  <p className="text-center">Data not found.</p>
                </div>
              </>
            )}
          </ul>
        </div>
      )}
      <Modal show={show3} onHide={handleClose3} className="custom">
        <Modal.Header closeButton>
          Send your message to {name} | Be specific while sending message and get instant response.
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formBasicEmail">
              <Form.Label>
                Message <span>*</span>
              </Form.Label>
              <Form.Control
                as="textarea"
                rows={5}
                value={message}
                type="text"
                placeholder="Enter your message here"
                name="text"
                onChange={handleChange}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleSubmit}>
            Send Message
          </Button>
          <Button variant="secondary" onClick={handleClose3}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default WorkAnniversary;
