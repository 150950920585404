/**
@copyright  :   ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
@author     :   Shiv Charan Panjeta 
 
All Rights Reserved.
Proprietary and confidential :  All information contained here in is, and remains
the property of ToXSL Technologies Pvt. Ltd. and it's partners.
Unauthorized copying of this file, via any medium is strictly prohibited.
*/

import { useState } from 'react';
import { Button, Container } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { Link, useNavigate } from 'react-router-dom';
import useDocumentTitle from '../../../globals/useDocumentTitle';
import * as adminServices from '../../../services/AuthServices';
import showNotification from '../../../services/NotificationService';

export default function AddEventPage() {
  useDocumentTitle('Add New Event Page | AlmaBay');
  const [checkFieldRequired, setCheckFieldRequired] = useState('no');
  const [detail, setDetail] = useState({
    field_name: '',
    field_type: ''
  });
  const navigate = useNavigate();
  const onChangeHandler = (e) => {
    const { name, value } = e.target;
    setDetail({ ...detail, [name]: value });
  };

  const submitHandler = (e) => {
    e.preventDefault();
    let body = {
      field_name: detail?.field_name,
      field_type: detail?.field_type,
      field_required: checkFieldRequired
    };
    adminServices.addEventPage(body).then((res) => {
      if (res?.status === 200) {
        showNotification('success', res?.data?.message);
        navigate(-1);
      }
    });
  };

  return (
    <div className="dash-main-content">
      <Container fluid>
        <div className="dash-page-content">
          <div className="dash-page-title">
            <Row>
              <Col xs={12} sm={12} md={12}>
                <div className="dash-title-bar d-flex align-items-center justify-content-between">
                  <div className="title-lft d-flex align-items-center">
                    <div className="dash-icn">
                      <i className="fas fa-calendar-alt"></i>
                    </div>
                    <div className="dash-title-dis">
                      <h5>Add Event Page</h5>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <div className="dash-content-card">
            <div className="cmmn-form">
              <Form>
                <Row>
                  <Col md={12}>
                    <Form.Group className="mb-3" controlId="formBasicName">
                      <Form.Label>Field Name</Form.Label>
                      <Form.Control
                        type="text"
                        onChange={(e) => {
                          onChangeHandler(e);
                        }}
                        name="field_name"
                        placeholder="Enter Field Name"
                      />
                    </Form.Group>
                  </Col>
                  <Col md={12}>
                    <Form.Group className="mb-3" controlId="formBasicName">
                      <Form.Label>Field Type</Form.Label>
                      <Form.Select
                        aria-label="Default select example"
                        name="field_type"
                        onChange={(e) => {
                          onChangeHandler(e);
                        }}>
                        <option value="text">Text</option>
                        <option value="radio">Radio</option>
                        <option value="checkbox">Checkbox</option>
                        <option value="password">Password</option>
                        <option value="select">Select</option>
                        <option value="date">Date</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col md={12}>
                    <Form.Group className="mb-3" controlId="formBasicCheckbox">
                      <Form.Label>Required</Form.Label>
                      <Form.Check
                        type="checkbox"
                        label="yes"
                        name="field_required"
                        onChange={() => setCheckFieldRequired('yes')}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={12}>
                    <Link className="add-mre-grey mt-2 mb-2" to="#">
                      Add More
                    </Link>
                  </Col>
                </Row>
                <div className="form-btns d-flex align-items-center mt-3">
                  <button type="button" className="green_button_admin" onClick={submitHandler}>
                    Save
                  </button>
                  <strong>OR</strong>
                  <Button
                    className="main-btn-red"
                    onClick={(e) => {
                      navigate(-1);
                    }}>
                    Cancel
                  </Button>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}
