/**
@copyright  :   ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
@author     :   Shiv Charan Panjeta 
 
All Rights Reserved.
Proprietary and confidential :  All information contained here in is, and remains
the property of ToXSL Technologies Pvt. Ltd. and it's partners.
Unauthorized copying of this file, via any medium is strictly prohibited.
*/
import { useEffect, useState } from 'react';
import { Button, Container } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Table from 'react-bootstrap/Table';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { getEventTicketList, globalStateTicketChange } from '../../../services/AuthServices';
import NoDataFound from '../../../components/commoncomponents/NoDataFound';
let intervalId;
export default function EventTicket() {
  const [chekcedId, setCheckedId] = useState([]);
  const location = useLocation();
  const evt_id = location.state;
  const [allId, setAllId] = useState([]);
  const [showalert, setShowAlert] = useState(false);
  const [list, setList] = useState([]);
  const [metaData, setMetaData] = useState(0);

  const navigate = useNavigate();
  useEffect(() => {
    apiCall();
  }, []);

  const [searchedData, setSearchData] = useState({
    id: '',
    page: '',
    order: '',
    ticket_id: '',
    ticket_name: '',
    ticket_type: '',
    ticket_status: ''
  });

  const apiCall = (
    event_id,
    id = '',
    page = '',
    order = 'DESC',
    ticket_id = '',
    ticket_name = '',
    ticket_type = '',
    ticket_status = ''
  ) => {
    let body = {
      event_id: evt_id,
      id,
      page,
      order,
      ticket_id,
      ticket_name,
      ticket_type,
      ticket_status
    };
    getEventTicketList(body).then((resp) => {
      if (resp?.status == 200) {
        setList(resp?.data?.detail?.viewlist);
        setMetaData(resp?.data?.detail?.totalRecords);
        if (resp?.data?.detail?.viewlist) {
          setAllId(resp?.data?.detail?.viewlist?.map((data) => data?.id));
        }
      }
    });
  };

  const handleAddNavigate = (e) => {
    e.preventDefault();
    navigate('/admin/event/AddTicket', { state: evt_id });
  };

  const searchHandler = () => {
    apiCall(
      searchedData.evt_id,
      '',
      '',
      '',
      searchedData.ticket_id,
      searchedData.ticket_name,
      searchedData.ticket_type,
      searchedData.ticket_status
    );
  };

  const resetHandler = (e) => {
    e.preventDefault();
    setSearchData({
      ticket_id: '',
      ticket_name: '',
      ticket_type: '',
      ticket_status: ''
    });
    apiCall();
  };

  const onChangeSearch = (e) => {
    const { name, value } = e.target;
    setSearchData({ ...searchedData, [name]: value });
  };

  const handleMultipleCheckBox = (id) => {
    if (chekcedId.includes(id)) {
      let filterId = chekcedId.filter((data) => data !== id);
      setCheckedId(filterId);
    } else {
      setCheckedId([...chekcedId, id]);
    }
  };

  const handleSelectAll = (e) => {
    if (e.target.checked === true) {
      setCheckedId(allId);
    } else {
      setCheckedId([]);
    }
  };

  const globalActionHandler = (e) => {
    if (chekcedId.length !== 0) {
      setShowAlert(false);
      let body = {
        ids: chekcedId.join(),
        action: e.target.value
      };
      if (confirm('do you want to continue ?') === true) {
        globalStateTicketChange(body).then((res) => {
          if (res?.status === 200) {
            setCheckedId([]);
            document.getElementById('global').value = '';
            apiCall();
          }
        });
      } else {
        return;
      }
    } else {
      setShowAlert(true);
      alertHandler();
    }
  };

  const alertHandler = () => {
    clearInterval(intervalId);
    intervalId = setInterval(function () {
      setShowAlert(false);
    }, 5000);
  };

  return (
    <div className="dash-main-content">
      <Container fluid>
        <div className="dash-page-content">
          <div className="dash-page-title">
            <Row>
              <Col xs={12} sm={12} md={12}>
                <div className="dash-title-bar d-flex align-items-center justify-content-between">
                  <div className="title-lft d-flex align-items-center">
                    <div className="dash-icn">
                      <i className="fa fa-users"></i>
                    </div>
                    <div className="dash-title-dis">
                      <h5>
                        Event Tickets <span>Listing</span>
                      </h5>
                      <h5>
                        Total Record(s): <span>{metaData}</span>
                      </h5>
                    </div>
                  </div>
                  <div className="title-rt-btn d-flex align-items-center">
                    <Link
                      className="add-btn-grn mx-2"
                      onClick={(e) => {
                        handleAddNavigate(e);
                      }}>
                      Add Ticket
                    </Link>
                    <div className="head-instite-sec">
                      <Form.Select
                        aria-label="Default select example"
                        id="global"
                        onChange={(e) => {
                          globalActionHandler(e);
                        }}>
                        <option value="">Choose Action</option>
                        <option value="active">Active </option>
                        <option value="inactive">Inactive</option>
                        <option value="delete">Delete</option>
                      </Form.Select>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <div className="dash-content-card">
            <div className="table-responsive">
              <Table className="custom-table-data" striped bordered hover>
                <thead>
                  <tr>
                    <th>
                      <Form.Check
                        type="checkbox"
                        id="checkbox"
                        name="checkall"
                        checked={allId?.length ? allId?.length === chekcedId?.length : false}
                        onChange={(e) => handleSelectAll(e)}
                        label=""
                      />
                    </th>
                    <th>
                      <Link to="">Id</Link>
                    </th>
                    <th>
                      <Link to="">Ticket Name</Link>
                    </th>
                    <th>
                      <Link to="">Quantity</Link>
                    </th>
                    <th>
                      <Link to="">
                        Limit
                        {/* <img src={require('../../assets/images/sort.png')} alt="sort" /> */}
                      </Link>
                    </th>
                    <th>
                      <Link to="">
                        Ticket Type
                        {/* <img src={require('../../assets/images/sort.png')} alt="sort" /> */}
                      </Link>
                    </th>
                    <th>
                      <Link to="">
                        Price
                        {/* <img src={require('../../assets/images/sort.png')} alt="sort" /> */}
                      </Link>
                    </th>
                    <th>
                      <Link to="">
                        Status
                        {/* <img src={require('../../assets/images/sort.png')} alt="sort" /> */}
                      </Link>
                    </th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="search-table">
                    <td></td>
                    <td>
                      <Form.Control
                        type="text"
                        value={searchedData.ticket_id}
                        name="ticket_id"
                        placeholder="Id"
                        onChange={(e) => {
                          onChangeSearch(e);
                        }}
                      />
                    </td>
                    <td>
                      <Form.Control
                        type="text"
                        value={searchedData?.ticket_name}
                        name="ticket_name"
                        placeholder="ticket name"
                        onChange={(e) => {
                          onChangeSearch(e);
                        }}
                      />
                    </td>
                    <td></td>
                    <td></td>
                    <td>
                      <Form.Select
                        aria-label="Default select example"
                        type="text"
                        value={searchedData?.ticket_type}
                        name="ticket_type"
                        onChange={(e) => {
                          onChangeSearch(e);
                        }}>
                        <option value=""> - Select - </option>
                        <option value={'FREE'}> Free </option>
                        <option value={'PAID'}> Paid </option>
                      </Form.Select>
                    </td>
                    <td></td>
                    <td>
                      <Form.Select
                        aria-label="Default select example"
                        value={searchedData?.ticket_status}
                        name="ticket_status"
                        onChange={(e) => {
                          onChangeSearch(e);
                        }}>
                        <option value=""> - Select - </option>
                        <option value="1"> Active</option>
                        <option value="0"> Inactive </option>
                      </Form.Select>
                    </td>
                    <td className="serac-btns" colSpan={2}>
                      <Button
                        className="main-btn-grn"
                        onClick={(e) => {
                          searchHandler(e);
                        }}>
                        Search
                      </Button>
                      <Button
                        className="main-btn-red ms-2"
                        onClick={(e) => {
                          resetHandler(e);
                        }}>
                        Reset
                      </Button>
                    </td>
                  </tr>
                  {/* mapping /iteration for list */}
                  {list && list?.length ? (
                    list?.map((data, index) => {
                      return (
                        <tr key={index}>
                          <td>
                            <Form.Check
                              type="checkbox"
                              name="id"
                              id={`id${data?.id}`}
                              checked={chekcedId?.includes(data?.id)}
                              onChange={() => handleMultipleCheckBox(data?.id)}
                              label=""
                            />
                          </td>
                          <td>{data?.id}</td>
                          <td>{data?.ticket_name} </td>
                          <td>{data?.quantity}</td>
                          <td>{data?.limit}</td>
                          <td>{data?.ticket_type}</td>
                          <td>{data?.price}</td>
                          <td>{data?.status == 1 ? 'Active' : 'Inactive'}</td>
                          <td align="center" className="action">
                            <Link
                              to={`/admin/event/edit_ticket/${data?.event_id}/${data?.id}`}
                              title="EDIT">
                              <i className="fa fa-edit"></i>
                            </Link>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan={9}>
                        <NoDataFound text="No Event Tickets Found" />
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}
