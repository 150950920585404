import { useEffect, useState } from 'react';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import Loader from '../../components/commoncomponents/loader/loader-large';
import { communicationConstants } from '../../globals/constants';
import useDocumentTitle from '../../globals/useDocumentTitle';
import {
  addCommunicationPlan,
  editCommunicationPlan,
  singleCommunicationPlan
} from '../../services/AuthServices';
import showNotification from '../../services/NotificationService';

const AddCommunicationPlan = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;
  const [addPlanDetails, setAddPlanDetails] = useState({
    planName: '',
    planDuration: '',
    planType: '',
    numberOfMessages: null,
    status: ''
  });
  const [errorValues, setErrorValues] = useState({});
  const [loading, setLoading] = useState(false);

  const { planName, planDuration, planType, numberOfMessages, status } = addPlanDetails;

  useEffect(() => {
    if (state !== null) {
      (async () => {
        try {
          setLoading(true);
          const response = await singleCommunicationPlan(state);
          if (response?.status === 200) {
            setLoading(false);
            setAddPlanDetails({
              planName: response?.data?.detail?.plan_name,
              planDuration: response?.data?.detail?.plan_duration,
              planType: response?.data?.detail?.type_id,
              numberOfMessages: response?.data?.detail?.messages_count,
              status: response?.data?.detail?.status_id
            });
          }
        } catch (error) {
          setLoading(false);
          console.error(error);
        }
      })();
    }
  }, [state]);

  const validation = () => {
    let errors = {};
    if (!planName?.trim()) {
      errors.planName = 'Plan name is required';
    } else if (!/^[a-zA-Z\s]*$/.test(planName)) {
      errors.planName = 'Plan name must be alphabet';
    }

    if (!planDuration) {
      errors.planDuration = 'Plan duration is required';
    }

    if (!planType) {
      errors.planType = 'Plan type is required';
    }

    if (!numberOfMessages?.trim()) {
      errors.numberOfMessages = 'No. of messages is required';
    } else if (isNaN(numberOfMessages)) {
      errors.numberOfMessages = 'No. of messages must be numeric';
    }

    if (state !== null) {
      if (!status) {
        errors.status = 'Status is required';
      }
    }
    return Object.keys(errors).length > 0 ? errors : null;
  };

  const handleChange = (e) => {
    e.preventDefault();
    setAddPlanDetails((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value
    }));
    setErrorValues((prevState) => ({
      ...prevState,
      [e.target.name]: undefined
    }));
  };

  const handleAdd = async (e) => {
    e.preventDefault();
    let errors = validation();
    if (errors) {
      setErrorValues(errors);
    } else {
      let body = {
        plan_name: planName,
        plan_duration: planDuration,
        type_id: planType,
        messages_count: numberOfMessages,
        status_id: communicationConstants.ENABLE
      };
      try {
        const response = await addCommunicationPlan(body);
        if (response?.status === 200) {
          showNotification('success', response.data.message);
          navigate(`/admin/manage-communication/plan`);
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    let errors = validation();
    if (errors) {
      setErrorValues(errors);
    } else {
      let body = {
        plan_name: planName,
        plan_duration: planDuration,
        type_id: planType,
        messages_count: numberOfMessages,
        status_id: status
      };
      try {
        const response = await editCommunicationPlan(state, body);
        if (response?.status === 200) {
          showNotification('success', response.data.message);
          navigate(`/admin/manage-communication/plan`);
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  return (
    <>
      <div className="dash-main-content">
        <Container fluid>
          <div className="dash-page-content">
            <div className="dash-page-title">
              <Row>
                <Col xs={12} sm={12} md={12}>
                  <div className="dash-title-bar d-flex align-items-center justify-content-between">
                    <div className="title-lft d-flex align-items-center">
                      <div className="icons_head">
                        <i className="fas fa-bars"></i>
                      </div>
                      {useDocumentTitle('Add Communication Plan | AlmaBay')}
                      <span style={{ fontSize: '20px', fontWeight: 'bold' }} className="ms-3">
                        {state !== null ? `Edit Communication Plan` : `Add Communication Plan`}
                      </span>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="dash-content-card">
              <div className="cmmn-form">
                <Form>
                  <Row>
                    <Col xs={12} md={6} lg={6}>
                      <Form.Group className="mb-3" controlId="formBasicPassword">
                        <Form.Label>
                          Plan Name <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter Plan Name"
                          name="planName"
                          autoComplete="Off"
                          value={planName}
                          onChange={(e) => {
                            handleChange(e);
                          }}
                        />
                        {errorValues.planName && (
                          <span className="error_message">{errorValues.planName}</span>
                        )}
                      </Form.Group>
                    </Col>
                    <Col xs={12} md={6} lg={6}>
                      <Form.Group className="mb-3" controlId="formBasicPassword">
                        <Form.Label>
                          Plan Duration <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Select
                          aria-label="Default select example"
                          name="planDuration"
                          value={planDuration}
                          onChange={(e) => {
                            handleChange(e);
                          }}
                        >
                          <option value="">Select Plan Duration</option>
                          <option value={communicationConstants.MONTHLY}>Monthly</option>
                          <option value={communicationConstants.YEARLY}>Yearly</option>
                        </Form.Select>
                        {errorValues.planDuration && (
                          <span className="error_message">{errorValues.planDuration}</span>
                        )}
                      </Form.Group>
                    </Col>
                    <Col xs={12} md={6} lg={6}>
                      <Form.Group className="mb-3" controlId="formBasicPassword">
                        <Form.Label>
                          Plan Type <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Select
                          aria-label="Default select example"
                          name="planType"
                          value={planType}
                          onChange={(e) => {
                            handleChange(e);
                          }}
                        >
                          <option value="">Select Plan Type</option>
                          <option value={communicationConstants.EMAIL}>Email</option>
                          <option value={communicationConstants.SMS}>SMS</option>
                        </Form.Select>
                        {errorValues.planType && (
                          <span className="error_message">{errorValues.planType}</span>
                        )}
                      </Form.Group>
                    </Col>
                    <Col xs={12} md={6} lg={6}>
                      <Form.Group className="mb-3" controlId="formBasicPassword">
                        <Form.Label>
                          No. of Messages <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          type="number"
                          placeholder="Enter no. of messages"
                          name="numberOfMessages"
                          autoComplete="Off"
                          value={numberOfMessages}
                          onChange={(e) => {
                            handleChange(e);
                          }}
                        />
                        {errorValues.numberOfMessages && (
                          <span className="error_message">{errorValues.numberOfMessages}</span>
                        )}
                      </Form.Group>
                    </Col>
                    {state !== null && (
                      <Col xs={12} md={6} lg={6}>
                        <Form.Group className="mb-3" controlId="formBasicPassword">
                          <Form.Label>
                            Status <span className="text-danger">*</span>
                          </Form.Label>
                          <Form.Select
                            aria-label="Default select example"
                            name="status"
                            value={status}
                            onChange={(e) => {
                              handleChange(e);
                            }}
                          >
                            <option value="">Select Status</option>
                            <option value={communicationConstants.ENABLE}>Enable</option>
                            <option value={communicationConstants.DISABLE}>Disable</option>
                            <option value={communicationConstants.DELETE}>Delete</option>
                          </Form.Select>
                          {errorValues.status && (
                            <span className="error_message">{errorValues.status}</span>
                          )}
                        </Form.Group>
                      </Col>
                    )}
                  </Row>
                  {state !== null ? (
                    <Button
                      onClick={(e) => {
                        handleUpdate(e);
                      }}
                      type="submit"
                      className="main-btn-grn"
                    >
                      Update
                    </Button>
                  ) : (
                    <Button
                      onClick={(e) => {
                        handleAdd(e);
                      }}
                      type="submit"
                      className="main-btn-grn"
                    >
                      Add
                    </Button>
                  )}
                </Form>
              </div>
            </div>
          </div>
        </Container>
      </div>
      {loading && <Loader />}
    </>
  );
};

export default AddCommunicationPlan;
