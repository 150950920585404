import { useEffect, useRef } from 'react';
/**
 * A custom infinite scroller hook
 * Just pass lastElement as a ref to look at it  and a call triggered when element is visible inside dom (container)  ...! :)
 * @param {*} lastElement
 * @param {*} onIntersecting
 */
const useInfiniteScroller = (lastElement, onIntersecting) => {
  const observer = useRef(
    new IntersectionObserver((entries) => {
      const first = entries[0];
      if (first.isIntersecting) {
        // function to trigger when attached last elemennt visible inside the dom
        onIntersecting();
      }
    })
  );

  useEffect(() => {
    const currentElement = lastElement;
    const currentObserver = observer.current;
    if (currentElement) {
      currentObserver.observe(currentElement);
    }

    return () => {
      if (currentElement) {
        currentObserver.unobserve(currentElement);
      }
    };
  }, [lastElement]);
};

export default useInfiniteScroller;
