import { useEffect, useState } from 'react';
import { Col, Container, Form, Row, Table } from 'react-bootstrap';
import ReactPaginate from 'react-paginate';
import { Link, useNavigate } from 'react-router-dom';
import Loader from '../../../components/commoncomponents/loader/loader-large';
import { deleteQuickLink, positionQuickLink, quickLinkList } from '../../../services/AuthServices';
import showNotification from '../../../services/NotificationService';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

const QuickLinks = () => {
  const navigate = useNavigate();
  const [linksList, setLinksList] = useState([]);
  const [count, setCount] = useState(null);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);

  useEffect(() => {
    getQuickLinkList();
  }, []);

  /**
   * function to get quick links list
   */
  const getQuickLinkList = async () => {
    try {
      setLoading(true);
      const response = await quickLinkList(page);
      if (response?.status === 200) {
        setLoading(false);
        setLinksList(response.data?.data);
        setCount(response.data?.totalCount);
      }
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  /**
   * function to perform edit and delete functionality
   * @param {*} value
   * @param {*} list
   */
  const handleChange = async (value, list) => {
    if (value === 'edit') {
      navigate(`/admin/quick-links/edit/${list?.id}`, { state: list });
    }
    if (value === 'delete') {
      try {
        const response = await deleteQuickLink(list?.id);
        if (response?.status === 200) {
          showNotification('success', response?.data?.message);
          setLinksList(
            linksList?.filter((data) => {
              return data?.id !== list?.id;
            })
          );
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  /**
   * a switch case statement to display state
   * @param {*} state
   * @returns
   */
  const quickLinkListState = (state) => {
    switch (state) {
      case '1':
        return 'Active';
      case '0':
        return 'In-active';
      default:
        return;
    }
  };

  const handleDragEnd = async (result) => {
    try {
      let body = {
        source: result?.source?.index,
        destination: result?.destination?.index
      };
      const response = await positionQuickLink(body);
      if (response?.status === 200) {
        if (!result.destination) return;
        const items = Array.from(linksList);
        const [reorderedItems] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItems);
        setLinksList(items);
        showNotification('success', response?.data?.message);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <div className="dash-main-content">
        <Container fluid>
          <div className="dash-page-content">
            <div className="dash-page-title">
              <Row>
                <Col xs={12} sm={12} md={12}>
                  <div className="dash-title-bar d-flex align-items-center justify-content-between">
                    <div className="title-lft d-flex align-items-center">
                      <div className="dash-icn">
                        <i className="fas fa-bars"></i>
                      </div>
                      <div className="dash-title-dis">
                        <h5>
                          Quicklinks <span>Listing</span>
                        </h5>
                        <h5>
                          Total Links(s): <span>{count}</span>
                        </h5>
                      </div>
                    </div>
                    <div className="title-rt-btn d-flex align-items-center">
                      <Link className="green_button_admin mx-2" to="/admin/quick-links/add">
                        Add Links
                      </Link>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="dash-content-card">
              <div className="table-responsive">
                <Table className="custom-table-data" striped bordered hover>
                  <thead>
                    <tr>
                      {/* <th>
                        <Form.Check type="checkbox" id="checkbox" name="checkall" label="" />
                      </th> */}
                      <th>
                        Id
                        {/* <img src={require('../../../assets/images/sort.png')} alt="sort" /> */}
                      </th>
                      <th>
                        Name
                        {/* <img src={require('../../../assets/images/sort.png')} alt="sort" /> */}
                      </th>
                      <th>
                        Slug
                        {/* <img src={require('../../../assets/images/sort.png')} alt="sort" /> */}
                      </th>
                      <th>State</th>
                      <th>Created At</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <DragDropContext onDragEnd={handleDragEnd}>
                    <Droppable droppableId="link_list">
                      {(provided) => {
                        return (
                          <tbody ref={provided.innerRef} {...provided.droppableProps}>
                            {linksList?.length > 0 ? (
                              linksList?.map((list, index) => {
                                return (
                                  <Draggable key={list?.id} draggableId={list?.id} index={index}>
                                    {(provided) => (
                                      <tr
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        key={index}>
                                        {/* <th>
                                          <Form.Check
                                            type="checkbox"
                                            id="checkbox"
                                            name="checkall"
                                            label=""
                                          />
                                        </th> */}
                                        <td>{list?.id}</td>
                                        <td>{list?.name}</td>
                                        <td>{list?.slug}</td>
                                        <td>{quickLinkListState(list?.state_id)}</td>
                                        <td>{list?.created_at}</td>
                                        <td>
                                          <Form.Select
                                            onChange={(e) => {
                                              handleChange(e.target.value, list);
                                            }}
                                            aria-label="Default select example"
                                            name="status">
                                            <option value="">Select</option>
                                            <option value={'edit'}>Edit</option>
                                            <option value={'delete'}>Delete</option>
                                          </Form.Select>
                                        </td>
                                      </tr>
                                    )}
                                  </Draggable>
                                );
                              })
                            ) : (
                              <tr>
                                <td className="text-center" colSpan={10}>
                                  Data not found
                                </td>
                              </tr>
                            )}
                            {provided.placeholder}
                          </tbody>
                        );
                      }}
                    </Droppable>
                  </DragDropContext>
                </Table>
              </div>
              {Math.ceil(count / 10) > 1 && (
                <div className="react-page d-flex justify-content-center">
                  <ReactPaginate
                    previousLabel={'Prev'}
                    nextLabel={'Next'}
                    activeClassName={'page-link active'}
                    onPageChange={(props) => {
                      setPage(props.selected + 1);
                    }}
                    pageCount={Math.ceil(count / 10)}
                  />
                </div>
              )}
            </div>
          </div>
        </Container>
      </div>
      {loading && <Loader />}
    </>
  );
};

export default QuickLinks;
