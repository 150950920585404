/*
@copyright : ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
@author    : Shiv Charan Panjeta 
 
All Rights Reserved.
Proprietary and confidential :  All information contained here in is, and remains
the property of ToXSL Technologies Pvt. Ltd. and it's partners.
Unauthorized copying of this file, via any medium is strictly prohibited.
*/

import { Link, useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Dropdown from 'react-bootstrap/Dropdown';
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { useSelector } from 'react-redux';
import WhatsNew from '../../commoncomponents/newsfeeds/WhatsNew';
import Upcoming from '../../commoncomponents/newsfeeds/Upcoming';
import CampusnewsText from '../../commoncomponents/newsfeeds/CampusnewsText';
import ArticalBlogs from '../../commoncomponents/newsfeeds/ArticalBlogs';
import { Container } from 'react-bootstrap';
import { useRef } from 'react';
import SimilarCompany from '../../commoncomponents/newsfeeds/SimilarCompany';
import {
  getCompanyAboutById,
  getCompanyDetailsById,
  getJobsByCompanyName,
  getJobListCompany,
  getProfessionalCompanyInfo,
  getProfessionalRecent,
  getCompanyAdminConnectionsForAdmin,
  addAdminConnection,
  getConnectionsForLeader,
  addLeaderConnection,
  getCompanyPhotos,
  addRecentPost,
  compnayPostComment,
  companyPostLikeUnlike,
  deleteComanyPost
} from '../../../services/CompanyServices';
import { truncate, useOnClickOutside } from '../../../globals/helper';
import showNotification from '../../../services/NotificationService';
import { MiniFooter } from '../../MiniFooter';
import Loader from '../../commoncomponents/loader/loader-large';

import CKEditorComponent from './PostComponets/CKEditorComponent';
import PostBottomMedia from './PostComponets/PostBottomMedia';
import HeaderInner from '../../commoncomponents/HeaderInner';

function WithOutLoginCompanyDetail() {
  const { id } = useParams();
  const state = new URLSearchParams(location?.search).get('state');

  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [companyData, setCompanyData] = useState({});
  const [loading, setLoading] = useState(false);
  const [jobInfo, setJobInfo] = useState({});
  const [jobOpen, setJobOpen] = useState([]);
  const [jobView, setJobview] = useState([]);
  const [adminIds, setAdminIds] = useState('');
  const [leaderIds, setLeaderIds] = useState('');
  const [follow, setFollow] = useState(false);
  const [role, setRole] = useState(false);
  const [active, setActive] = useState('first');
  const [adminDropDown, setadminDropDown] = useState([]);
  const [leaderDropDown, setLeaderDropDown] = useState([]);
  const [timelineId, settimelineId] = useState(false);

  useEffect(() => {
    getCompanyInfo();

    getAdminConnectionsForLeader();
    getLeaderDropDowm();

    if (id) {
      fetchCompanyDetailsById();
      jobOpening();
    }
  }, [state]);

  const jobOpening = () => {
    setActive(state ? state : 'first');
    setLoading(true);
    getJobListCompany().then((res) => {
      setLoading(false);
      if (res?.status === 200) {
        setJobOpen(res?.data?.jobsList);
      }
    });
  };

  // const getJobList = () => {
  //   getJoinedJob().then((res) => {
  //   if(res?.status === 200){
  //     setJobList(res?.data?.data)
  //   }})}

  const fetchCompanyDetailsById = () => {
    setLoading(true);
    getCompanyDetailsById(id).then((resp) => {
      setLoading(false);
      setCompanyData(resp?.data?.data);
      if (state == 'company-jobs') {
        setActive('second');
      } else if (state == 'recent-update') {
        setActive('third');
      } else if (state == 'post-new-job ') {
        setActive('sixth');
      } else if (state == 'edit-update ') {
        setActive('seventh');
      }
    });
  };

  const getCompanyInfo = () => {
    setLoading(true);
    getProfessionalCompanyInfo(id).then((resp) => {
      setLoading(false);
      if (resp?.status === 200) {
        setJobview(resp?.data?.companyInfo);
        setFollow(resp?.data?.is_following);
        setRole(resp?.data?.admin);
        settimelineId(resp?.data?.companyInfo?.timeline_id);
      }
    });
  };

  // Add Admin Drop Down
  const getAdminConnectionsForLeader = () => {
    setLoading(true);
    getCompanyAdminConnectionsForAdmin().then((resp) => {
      setLoading(false);
      if (resp?.status === 200) {
        if (resp?.data?.data) {
          let arr = [];
          resp?.data?.data?.map((data) => {
            arr.push({ label: data?.name, value: data?.id });
          });
          setadminDropDown(arr);
        }
      }
    });
  };

  const addConenctionAsAdmin = (e) => {
    e.preventDefault();
    let body = {
      company_id: jobView?.timeline_id,
      organisation_page_admin: adminIds.map((data) => data?.value).join()
    };
    addAdminConnection(body).then((resp) => {
      if (resp?.status == 200) {
        showNotification('success', resp?.data?.detail);
      }
    });
  };

  //  Add Leader
  const getLeaderDropDowm = () => {
    setLoading(true);
    getConnectionsForLeader(jobView?.timeline_id).then((resp) => {
      setLoading(false);
      if (resp?.status == 200) {
        if (resp?.data?.data) {
          let arr = [];
          resp?.data?.data?.map((data) => {
            arr.push({ label: data?.name, value: data?.id });
          });
          setLeaderDropDown(arr);
        }
      }
    });
  };

  const addConenctionAsLeader = (e) => {
    e.preventDefault();
    let body = {
      company_id: jobView?.timeline_id,
      organisation_leaders: leaderIds?.map((data) => data?.value).join()
    };
    addLeaderConnection(body).then((resp) => {
      if (resp?.status == 200) {
        showNotification('success', resp?.data?.detail);
      }
    });
  };

  return (
    <>
      <HeaderInner />

      <section className="company-detail-cover py-0">
        <img
          src={
            jobView?.cover_url
              ? jobView?.cover_url
              : require('../../../assets/images/default-mentor-cover.jpg')
          }
          alt="detail-cover"
        />
      </section>
      <section className="company-detail-sec">
        <Container>
          <div className="company-detail-card d-flex align-items-top mb-4">
            <div className="company-lft-logo me-3">
              <Link to="#">
                <img
                  src={
                    jobView?.logo
                      ? jobView?.logo
                      : require('../../../assets/images/default-mentor-cover.jpg')
                  }
                  alt="detail-cover"
                />
              </Link>
            </div>

            <div className="company-rt-dis">
              <div className="d-flex align-items-center justify-content-between">
                <h3>{jobView?.name}</h3>
                {jobView?.followers != 0 ? (
                  <div className="follower pointer" role="presentation">
                    {jobView?.followers <= 1 ? (
                      <p>{jobView?.followers} Follower</p>
                    ) : (
                      <p>{jobView?.followers} Followers</p>
                    )}
                    {/* <h4>{jobView?.followers}</h4> */}
                  </div>
                ) : (
                  ''
                )}
              </div>
              <ul className="corporate-lst">
                <li>{handleShowInstiType(jobView?.institute_type)}</li>
                <li>{jobView?.company_size} + Employees</li>
                <li>{jobView?.city}</li>
              </ul>
              <div className="web-dis">
                <span>Websites</span>{' '}
                <a className="org-color" target="_blank" href={jobView?.website} rel="noreferrer">
                  {/* {jobView?.website} */}
                  <a target="_blank" href={'http://' + jobView?.website} rel="noreferrer">
                    <p>{jobView?.website}</p>
                  </a>
                </a>
              </div>
              <div className="web-dis">
                <span>Address</span>{' '}
                <Link to="#">
                  {jobView?.address},{jobView?.city} {','} {jobView?.state} {','} {jobView?.pincode}
                </Link>
              </div>

              <div className="company-follow d-flex align-items-center mt-3">
                <Button className="follow-btn-grey" onClick={() => navigate('/login')}>
                  Follow
                </Button>

                {role === true ? (
                  <div className="post-job-drop ms-2">
                    <Dropdown>
                      <Dropdown.Toggle variant="success" id="dropdown-basic">
                        More Option
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item
                          eventKey="sixth"
                          onClick={() => {
                            handleClose();
                            setActive('sixth');
                          }}>
                          Post Job
                        </Dropdown.Item>
                        <Dropdown.Item
                          eventKey="seventh"
                          onClick={() => {
                            handleClose();
                            setActive('seventh');
                          }}>
                          Edit Details
                        </Dropdown.Item>
                        <Dropdown.Item
                          eventKey="eigth"
                          onClick={() => {
                            handleClose();
                            setActive('eigth');
                          }}>
                          Add Leaders
                        </Dropdown.Item>
                        <Dropdown.Item
                          eventKey="ninth"
                          onClick={() => {
                            handleClose();
                            setActive('ninth');
                          }}>
                          Add Admins
                        </Dropdown.Item>
                        <Dropdown.Item
                          eventKey="tenth"
                          onClick={() => {
                            handleClose();
                            setActive('tenth');
                          }}>
                          Manage Admins
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>

          <div className="company-tab-dis pb-4">
            <Row>
              <Tab.Container
                id="left-tabs-example"
                activeKey={show == true ? '' : active}
                transition={true}>
                <Col xs={12} sm={12} md={12} lg={2}>
                  <div className="company-tab-lft">
                    <Nav variant="pills" className="flex-column">
                      <Nav.Item>
                        <Nav.Link
                          eventKey="first"
                          onClick={() => {
                            handleClose();
                            setActive('first');
                          }}>
                          Overview
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link
                          eventKey="second"
                          onClick={() => {
                            handleClose();
                            setActive('second');
                          }}>
                          Jobs
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item eventKey="third">
                        <Nav.Link
                          eventKey="third"
                          onClick={() => {
                            handleClose();
                            setActive('third');
                          }}>
                          Recent Updates
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link
                          eventKey="fourth"
                          onClick={() => {
                            handleClose();
                            setActive('fourth');
                          }}>
                          Photos
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link
                          eventKey="fifth"
                          onClick={() => {
                            handleClose();
                            setActive('fifth');
                          }}>
                          Videos
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </div>
                </Col>
                <Col xs={12} sm={12} md={12} lg={7}>
                  <div className="company-tab-rt mb-4">
                    <Tab.Content>
                      <Tab.Pane eventKey="first">
                        <AboutTabContent companyId={id} />
                        <h4 className="mt-2">Recent Updates</h4>
                        <RecentUpdateTabContent
                          companyId={id}
                          role={role}
                          timelineId={timelineId}
                        />
                      </Tab.Pane>
                      <Tab.Pane eventKey="second">
                        <JobTabContent companyId={id} />
                      </Tab.Pane>
                      <Tab.Pane eventKey="third">
                        <RecentUpdateTabContent
                          companyId={id}
                          role={role}
                          timelineId={timelineId}
                        />
                      </Tab.Pane>
                      <Tab.Pane eventKey="fourth">
                        <PhotosTabContent />
                      </Tab.Pane>
                      <Tab.Pane eventKey="fifth">
                        <VideosTabContent />
                      </Tab.Pane>
                    </Tab.Content>
                  </div>
                </Col>
              </Tab.Container>
              <Col xs={12} sm={12} md={12} lg={3}>
                <div>
                  <SimilarCompany />
                </div>
                <div>
                  <ArticalBlogs />
                </div>
                <div>
                  <CampusnewsText />
                </div>
                <div>
                  <WhatsNew />
                </div>
                <div>
                  <Upcoming />
                </div>
                <MiniFooter />
              </Col>
            </Row>
          </div>
        </Container>
      </section>
      {loading ? <Loader /> : null}
      {/* <FooterMain className={'colour-bg'} /> */}
    </>
  );
}

const JobTabContent = ({ companyId }) => {
  const { id } = useParams();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [jobs, setJobs] = useState([]);
  const [totalJobs, setTotalJobs] = useState(0);
  const [jobList, setJobList] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    // getAddJobList();
    if (companyId) {
      getJobsByCompanyDetails();
    }
  }, []);

  const getJobsByCompanyDetails = () => {
    setLoading(true);
    getJobsByCompanyName(companyId).then((resp) => {
      setLoading(false);
      setJobs(resp?.data?.data?.jobsList?.jobsList);
      setTotalJobs(resp?.data?.data?.jobsList?.total_jobs);
    });
  };

  // const getJobList = () => {
  //   setLoading(true);
  //   getProfessionalJobList(id).then((resp) => {
  //     setLoading(false);
  //     if (resp?.status === 200) {
  //       setJobList(resp?.data);
  //     }
  //   });
  // };

  //  jobs
  return (
    <>
      <div className="job-list bg-white">
        <div className="job-list-hd d-flex align-items-center justify-content-between">
          <h4>Jobs List</h4>
          <h5>
            <b>{totalJobs}</b> Jobs <b> World Wide </b>
          </h5>
        </div>
        <div className="job-list-view">
          {jobs && jobs?.length ? (
            jobs?.map((job) => {
              return (
                <div className="job-list-card d-flex align-items-top" key={job.id}>
                  <div className="job-list-icn me-3">
                    <img
                      src={require('../../../assets/images/hall-ticketcompany-logo-default.png')}
                      alt="detail-cover"
                    />
                  </div>
                  <div className="job-list-dis">
                    <div className="d-flex align-=items-center justify-content-between">
                      <h4>{job?.job_title}</h4>
                      <Button
                        className="apply-btn"
                        onClick={() => {
                          navigate(`/login`);
                        }}>
                        <img
                          className="me-1"
                          src={require('../../../assets/images/apply-icon.png')}
                          alt="apply"
                        />
                        Quick Apply
                      </Button>
                      <Modal
                        show={show}
                        onHide={handleClose}
                        backdrop="static"
                        keyboard={false}
                        className="apply-job-modal">
                        <Modal.Header closeButton>
                          <Modal.Title>Sr. Associates-Customer Service</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <Form>
                            <Form.Group className="mb-3" controlId="formBasicTo">
                              <Form.Label>To</Form.Label>
                              <Form.Control type="text" placeholder="Dell" value="Dell" readOnly />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicSubject">
                              <Form.Label>Subject</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="Dell"
                                value="Alma - Job application for Sr. Associates-Customer Service"
                                readOnly
                              />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicMessage">
                              <Form.Label>Message</Form.Label>
                              <Form.Control
                                as="textarea"
                                placeholder="Write your content here.."
                                rows={3}
                              />
                            </Form.Group>

                            <Form.Label>Resume</Form.Label>
                            {['radio'].map((type) => (
                              <div key={`inline-${type}`} className="mb-3">
                                <div className="resume-upload-sec mb-2">
                                  <Form.Check
                                    label="Attach latest resume"
                                    name="group1"
                                    type={type}
                                    id="attach"
                                  />
                                  <div className="upload-resume-frm mt-2">
                                    <Form.Group controlId="formFile" className="mb-3">
                                      <Form.Control type="file" name="resume" />
                                    </Form.Group>
                                  </div>
                                  <p>Max upload size is 500KB</p>
                                </div>
                                <div className="resume-upload-sec d-flex align-items-center mb-2">
                                  <Form.Check
                                    label="Use"
                                    name="group1"
                                    type={type}
                                    id={`inline-${type}-2`}
                                    defaultChecked
                                  />
                                  <Link className="ms-2" to="#">
                                    Resume-card
                                  </Link>
                                </div>
                              </div>
                            ))}

                            <Button className="job-apply d-block mt-2">
                              <i className="far fa-check-circle me-1"></i> Apply Now
                            </Button>
                          </Form>
                        </Modal.Body>
                      </Modal>
                    </div>
                    <h5>{job?.city}</h5>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: truncate(job?.job_info, 250)
                      }}></p>
                    <Link to={`/jobs/details/${job?.id}`} className="more-btn">
                      more
                    </Link>
                  </div>
                </div>
              );
            })
          ) : (
            <div className="p-5 text-center">
              <img
                src={require('../../../assets/images/small-error.png')}
                width="105px"
                alt="img"
              />
              <p>"No Jobs Posted Yet"</p>
            </div>
          )}
        </div>
      </div>
      {loading ? <Loader /> : null}
    </>
  );
};
// end job

// about start
const AboutTabContent = ({ companyId }) => {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [companyData, setCompanyData] = useState();
  const [jobView, setJobview] = useState([]);
  const [showDetail, setShowDetail] = useState(true);
  useEffect(() => {
    getCompanyInfo();
    if (companyId) {
      fetchCompanyAbout();
    }
  }, []);

  const getCompanyInfo = () => {
    setLoading(true);
    getProfessionalCompanyInfo(id).then((resp) => {
      setLoading(false);
      if (resp?.status === 200) {
        setJobview(resp?.data?.companyInfo);
      }
    });
  };

  const fetchCompanyAbout = () => {
    setLoading(true);
    getCompanyAboutById().then((resp) => {
      setLoading(false);
      setCompanyData(resp?.data?.data);
    });
  };

  return (
    <>
      <div className="about-us p-4">
        {/* <p>{jobView?.description}</p> */}
        {jobView?.description?.length < 700 ? (
          <>
            <h4>About Us</h4>
            <div dangerouslySetInnerHTML={{ __html: jobView?.description }} />
            <div className="spealist-dis">
              <h5>Specialties</h5>
              <p>{jobView?.specialties}</p>
            </div>
            <Row>
              <Col md={6}>
                <div className="inner-box">
                  <h4>Headquarters</h4>
                  <p>
                    {jobView?.city}/{jobView?.state}
                  </p>
                </div>
              </Col>
              <Col md={6}>
                <div className="inner-box">
                  <h4>Company Size</h4>
                  <p>{jobView?.company_size} Employees</p>
                </div>
              </Col>
              <Col md={6}>
                <div className="inner-box">
                  <h4>Website</h4>
                  <p>{jobView?.website}</p>
                </div>
              </Col>
              <Col md={6}>
                <div className="inner-box">
                  <h4>Industry</h4>
                  <p>{jobView?.company_industry}</p>
                </div>
              </Col>
              <Col md={6}>
                <div className="inner-box">
                  <h4>Founded</h4>
                  <p>{jobView?.estyear}</p>
                </div>
              </Col>
              <Col md={6}>
                <div className="inner-box">
                  <h4>Type</h4>
                  <p>{handleShowInstiType(jobView?.institute_type)}</p>
                </div>
              </Col>
              <Col md={6}>
                <div className="inner-box"></div>
              </Col>
              <Col md={6}>
                <div className="inner-box"></div>
              </Col>
              <Col md={6}>
                <div className="inner-box"></div>
              </Col>
            </Row>{' '}
          </>
        ) : (
          <>
            {' '}
            {showDetail ? (
              <>
                <h4>About Us</h4>
                <div
                  dangerouslySetInnerHTML={{ __html: truncate(jobView?.description, 700) }}
                />{' '}
                <button className="show-dtls-btn" onClick={() => setShowDetail(false)}>
                  Show Details <i className="fas fa-angle-down ms-1"></i>
                </button>
              </>
            ) : (
              <>
                <h4>About Us</h4>
                <div dangerouslySetInnerHTML={{ __html: jobView?.description }} />
                <div className="spealist-dis">
                  <h5>Specialties</h5>
                  <p>{jobView?.specialties}</p>
                </div>
                <Row>
                  <Col md={6}>
                    <div className="inner-box">
                      <h4>Headquarters</h4>
                      <p>
                        {jobView?.city}/{jobView?.state}
                      </p>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="inner-box">
                      <h4>Company Size</h4>
                      <p>{jobView?.company_size} Employees</p>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="inner-box">
                      <h4>Website</h4>
                      <p>{jobView?.website}</p>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="inner-box">
                      <h4>Industry</h4>
                      <p>{jobView?.company_industry}</p>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="inner-box">
                      <h4>Founded</h4>
                      <p>{jobView?.estyear}</p>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="inner-box">
                      <h4>Type</h4>
                      <p>{handleShowInstiType(jobView?.institute_type)}</p>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="inner-box"></div>
                  </Col>
                  <Col md={6}>
                    <div className="inner-box"></div>
                  </Col>
                  <Col md={6}>
                    <div className="inner-box"></div>
                  </Col>
                </Row>{' '}
                <button className="show-dtls-btn less-detail" onClick={() => setShowDetail(true)}>
                  Hide Details <i className="fas fa-angle-up ms-1"></i>
                </button>
              </>
            )}
          </>
        )}
      </div>

      {loading ? <Loader /> : null}
    </>
  );
};

// about end

//recent start
const RecentUpdateTabContent = ({ companyId, role, timelineId }) => {
  const [recentUpdates, setRecentUpdates] = useState({});
  const [show, setShow] = useState(false);
  const [recentData, setRecentData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showComment, setShowComment] = useState([]);
  const userDetail = useSelector((state) => state?.user?.details);
  const [latestPost, setLatestPost] = useState('');
  const [selectedFile, setSelectedFile] = useState([]);
  const [isSelected, setIsSelected] = useState(false);
  const [selectedData, setSelectedData] = useState([]);
  const [multiSelectData, setMultiSelectData] = useState([]);
  const [singleSelectName, setSingleSelectName] = useState('');
  const [recipientId, setrecipientId] = useState('');
  const ref = useRef();
  const [commentId, setCommentId] = useState('');
  const [show2, setShow2] = useState(false);
  const handleShow2 = () => setShow2(true);
  const [checkLike, setCheckLike] = useState([]);
  const [modalComment, setModalComment] = useState('');
  const [postComment, setPostComment] = useState('');
  useOnClickOutside(ref, () => setShow(false));
  const [multiSelectName, setmultiSelectName] = useState([]);
  useEffect(() => {
    getRecentDetails();
    // getCompanyInfo();
    if (companyId) {
      fetchCompanyRecentUpdates();
    }
  }, []);
  const fetchCompanyRecentUpdates = () => {
    setLoading(true);
    getCompanyAboutById().then((resp) => {
      setLoading(false);
      setRecentUpdates(resp?.data?.data);
    });
  };

  const getRecentDetails = () => {
    setLoading(true);
    getProfessionalRecent(companyId).then((resp) => {
      setLoading(false);
      if (resp?.status === 200) {
        setRecentData(resp?.data?.data?.stories);
      }
    });
  };

  const checkLatestpost = (e) => {
    setLoading(true);
    e.preventDefault();
    let formData = new FormData();
    formData.append(`text`, latestPost.trim());
    formData.append(`timeline_id`, timelineId);
    formData.append(`post_privacy[]`, 5);
    formData.append(`recipient_id`, recipientId);
    for (let i in selectedFile) {
      formData.append(`photos[]`, selectedFile[i]);
    }
    addRecentPost(formData).then((res) => {
      setLoading(false);
      if (res?.status === 200) {
        showNotification('success', res?.data?.message);
        setSelectedFile([]);
        setIsSelected(false);
        setSelectedData([]);
        setMultiSelectData([]);
        setmultiSelectName([]);
        setSingleSelectName('');
        setLatestPost('');
        getRecentDetails();
        setrecipientId('');
      }
    });
  };

  const makePost = (e) => {
    if (latestPost?.trim() !== '' || selectedFile.length !== 0) {
      checkLatestpost(e);
    } else {
      showNotification(
        'danger',
        'This status update appears to be blank. Please write something or attach photo to update your status.'
      );
    }
  };

  const deletedPost = (id) => {
    setLoading(true);
    if (confirm('Do you want to delete your post ?') == true) {
      deleteComanyPost(id).then((res) => {
        setLoading(false);
        if (res?.status === 200) {
          showNotification('success', res?.data?.message);
          getRecentDetails();
        }
      });
    } else {
      return;
    }
  };

  const deletedPostComent = (id) => {
    setLoading(true);
    if (confirm('Do you want to delete your comment  ?') == true) {
      deleteComanyPost(id).then((res) => {
        setLoading(false);
        if (res?.status === 200) {
          showNotification('success', res?.data?.message);
          getRecentDetails();
        }
      });
    } else {
      return;
    }
  };

  const getPostLike = (e, id) => {
    e.preventDefault();
    setLoading(true);
    companyPostLikeUnlike(id).then((res) => {
      setLoading(false);
      if (res?.status === 200) {
        showNotification('success', res?.data?.message);
        setCheckLike(res?.data?.data);
        getRecentDetails();
      }
    });
  };

  const getPostCommentLike = (e, id) => {
    e.preventDefault();
    setLoading(true);
    companyPostLikeUnlike(id).then((res) => {
      setLoading(false);
      if (res?.status === 200) {
        showNotification('success', res?.data?.message);
        setCheckLike(res?.data?.data);
        getRecentDetails();
      }
    });
  };

  const isPostcomment = (id) => {
    compnayPostComment(id, {
      text: postComment,
      timeline_id: timelineId
    }).then((res) => {
      if (res?.status === 200) {
        showNotification('success', res?.data?.message);
        setPostComment('');
        setModalComment('');
        getRecentDetails();
      }
    });
  };

  return (
    <>
      {role === true ? (
        <div className="write-on-wall mb-3">
          <h4>
            <i className="fas fa-edit me-2"></i> What's going on?
          </h4>
          <CKEditorComponent
            latestPost={latestPost}
            setLatestPost={setLatestPost}
            isToolBar={false}
            placeholder={'Write something... #hashtags'}
          />
          {isSelected && (
            <div className="upload-img-detail d-flex align-items-center justify-content-between">
              <p>
                {selectedFile?.length > 1
                  ? `${selectedFile.length} photo(s) selected`
                  : `${selectedFile.length} photo selected`}
              </p>
              <Link
                to="#"
                onClick={() => {
                  setIsSelected(false);
                  setSelectedFile([]);
                }}>
                <i className="fas fa-times"></i>
              </Link>
            </div>
          )}
          <div className="write-wall-ftr d-flex align-items-center justify-content-between">
            <PostBottomMedia setSelectedFile={setSelectedFile} setIsSelected={setIsSelected} />
            <div className="wall-fltr-rght d-flex align-items" ref={ref}>
              <button
                className="accnt-btn ms-2"
                onClick={(e) => {
                  makePost(e);
                }}>
                <i className="fas fa-plus me-1"></i> Post{' '}
              </button>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
      {recentData ? (
        recentData?.map((data, index) => {
          return (
            <div className="pofile-upload-card" key={index}>
              <div className="profile-upload-sec d-flex align-items-top mb-3">
                <span className="social-profile-icn me-3">
                  <img
                    alt="img"
                    src={
                      data?.publisher?.mediaObject?.path
                        ? data?.publisher?.mediaObject?.path +
                          '.' +
                          data?.publisher?.mediaObject?.extension
                        : require('../../../assets/images/birth6.jpg')
                    }
                  />
                </span>
                <div className="social-profile-detail d-flex align-items-top justify-content-between ">
                  <div className="social-profile-name">
                    <h4>
                      <Link to="#">
                        {data?.publisher?.first_name ? data?.publisher?.first_name : 'NA'}
                      </Link>{' '}
                    </h4>
                    <p>
                      <i className="far fa-clock me-2"></i>
                      {data?.timestamp ? data?.timestamp : 'NA'}
                      <i className="fas fa-users ms-2" title="shared with everyone"></i>{' '}
                    </p>
                  </div>
                  <div className="drop-ellipse-actn">
                    {role === true ? (
                      <Dropdown className="ellipse-drop-card ms-3">
                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                          <i className="fas fa-ellipsis-v"></i>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item
                            href="#/action-1"
                            onClick={() => {
                              deletedPost(data?.id);
                            }}>
                            Delete Post
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    ) : (
                      <></>
                    )}
                    WithOutLoginCompanyUpdates WithOutLoginCompanyUpdates WithOutLoginCompanyUpdates
                  </div>
                </div>
              </div>
              {(data?.text !== null || data?.text == '') && (
                <div
                  className="profile-top-dis"
                  dangerouslySetInnerHTML={{
                    __html: data?.text,
                    navigate: '/user/hashtag/:{data?.text}'
                  }}></div>
              )}
              {data?.media_id != '0' ? (
                <div className="post_img d-flex flex-wrap">
                  <Link
                    className="collage_card"
                    id="img1"
                    onClick={() => handleShow2(data, index)}
                    key={index}>
                    {' '}
                    <img
                      // key={image?.id}
                      src={data?.media?.length ? data?.media[0]?.media_path : ''}
                      alt="img"
                    />
                  </Link>
                </div>
              ) : (
                <></>
              )}
              <div className="profile-upload-ftr">
                <div className="profile-likes-count d-flex align-items-center justify-content-between">
                  <div className="likes-count">
                    <Link>{data?.likes_count} Likes</Link>
                    <Link to="#">{data?.shares_count} Shares</Link>
                  </div>
                  <div className="cmmnts-count">{data?.comments?.comments_count} Comments</div>
                </div>
                <div className="profile-like-btns">
                  <ul>
                    <li>
                      <Link
                        to="#"
                        title="Like"
                        onClick={(e) => {
                          e.preventDefault();
                          getPostLike(data?.comments?.id);
                        }}>
                        <i className="fas fa-thumbs-up me-2"></i>
                        {data?.likes}
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="#"
                        title="Comments"
                        // onClick={(e) => {
                        //   e.preventDefault();
                        //   // getComment(data.id, e);
                        //   setShowComment([...showComment, data?.id]);
                        // }}
                      >
                        <i className="fas fa-comments me-2"></i>
                        {/* {data?.comments} */}
                      </Link>
                    </li>
                    <li>
                      <Link to="#" title="Share">
                        <i className="fas fa-share me-2"></i>
                        {data?.shares}
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>

              <div className={`${data?.comments?.data ? 'profile-upload-cmmnt mt-3' : 'd-none'}  `}>
                {data?.comments?.data?.length ? (
                  data?.comments?.data?.map((data, index) => (
                    <div key={index} className="profile-cmmnt-lst d-flex align-items-top mb-3">
                      <span className="social-profile-icn me-3">
                        <img
                          src={
                            data?.publisher?.mediaObject?.path
                              ? data?.publisher?.mediaObject?.path +
                                '.' +
                                data?.publisher?.mediaObject?.extension
                              : require('../../../assets/images/birth6.jpg')
                          }
                          alt="img"
                        />
                      </span>

                      <div className="profile-cmmnt-user ">
                        <div className="d-flex align-items-top justify-content-between">
                          <Link to="#">{data?.publisher?.first_name}</Link>
                          <div className="profile-cmmnt-time d-flex align-items-center ">
                            <span>{data?.latest_comment?.timestamp}</span>
                            <Dropdown className="ellipse-drop-card ms-3">
                              <Dropdown.Toggle variant="success" id="dropdown-basic">
                                <i className="fas fa-ellipsis-v"></i>
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                <Dropdown.Item
                                  href="#"
                                  onClick={(e) => {
                                    deletedPostComent(e, data?.timeline_id);
                                  }}>
                                  Delete comment
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                        </div>
                        <div className="profile-cmmnt-dis">
                          <p>{data?.text}</p>
                          <div className="cmmnt-likes d-flex align-items-center">
                            <Link
                              to="#"
                              // onClick={(e) => {
                              //   getPostCommentLike(e, data?.id);
                              // }}
                              onClick={() => {
                                getPostLike(data?.comments?.id);
                              }}>
                              <i className="fas fa-thumbs-up me-1 "></i>
                              <span
                              // id={'like_counts_' + data?.id}
                              // is_self_like={true ? true : false}
                              >
                                {data?.likeCount}
                              </span>
                            </Link>
                            likes
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <></>
                )}
                {showComment?.includes(data?.id) ? (
                  <div className="profile-cmmnt-input d-flex align-items-top">
                    <span className="social-profile-icn me-3">
                      <img
                        src={
                          data?.publisher?.mediaObject?.path
                            ? data?.publisher?.mediaObject?.path +
                              '.' +
                              data?.publisher?.mediaObject?.extension
                            : require('../../../assets/images/birth6.jpg')
                        }
                        alt="img"
                      />
                    </span>
                    <div className="cmmnt-card">
                      <Form.Control
                        type="text"
                        onChange={(e) => {
                          setPostComment(e.target.value);
                          setCommentId(e.target.name);
                        }}
                        name={data?.id}
                        // onChange={handleCommentChange}
                        onKeyPress={(e) => {
                          e.key == 'Enter' ? isPostcomment(data?.id) : '';
                        }}
                        // value={data?.id == commentId ? postComment : ''}
                        placeholder="Write a comment... Press Enter to post"
                      />
                    </div>
                  </div>
                ) : (
                  ''
                )}
                <div className="profile-cmmnt-input d-flex align-items-top">
                  <span className="social-profile-icn me-3">
                    <img
                      src={
                        data?.publisher?.mediaObject?.path
                          ? data?.publisher?.mediaObject?.path +
                            '.' +
                            data?.publisher?.mediaObject?.extension
                          : require('../../../assets/images/birth6.jpg')
                      }
                      alt="img"
                    />
                  </span>
                  <div className="cmmnt-card">
                    <Form.Control
                      type="text"
                      onChange={(e) => {
                        setPostComment(e.target.value);
                        setCommentId(e.target.name);
                      }}
                      name={data?.id}
                      // onChange={handleCommentChange}
                      onKeyPress={(e) => {
                        e.key == 'Enter' ? isPostcomment(data?.id, 1, e) : '';
                      }}
                      value={postComment}
                      placeholder="Write a comment... Press Enter to post"
                    />
                  </div>
                </div>
              </div>
            </div>
          );
        })
      ) : (
        <div className="p-5 text-center">
          <img src={require('../../../assets/images/small-error.png')} alt="img" />
          <p>We can't find any update for you right now. Please check back later.</p>
        </div>
      )}

      {loading ? <Loader /> : null}
    </>
  );
};
//  recent end

const PhotosTabContent = ({ companyId }) => {
  const [photos, setPhotos] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (companyId) {
      fetchCompanyPhotos();
    }
  }, []);

  const fetchCompanyPhotos = () => {
    setLoading(true);
    getCompanyPhotos().then((resp) => {
      setLoading(false);
      setPhotos(resp?.data?.data?.data);
    });
  };

  return (
    <>
      <div className="job-list bg-white">
        <div className="job-list-hd d-flex align-items-center justify-content-between">
          <h4>Photos </h4>
        </div>
        <div className="job-list-view">
          {photos && photos?.length ? (
            photos?.map((data) => {
              return (
                <div className="job-list-card d-flex align-items-top" key={data.id}>
                  <div className="job-list-icn me-3">
                    <img
                      src={
                        data?.logo
                          ? data?.logo
                          : require('../../../assets/images/default-mentor-cover.jpg')
                      }
                      alt="detail-cover"></img>
                  </div>
                  <div className="job-list-dis">
                    <div className="d-flex align-=items-center justify-content-between">
                      <h4>{data?.name}</h4>
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            // <div className="events_listing p-3">
            <div className="p-5 text-center">
              <img
                src={require('../../../assets/images/small-error.png')}
                width="105px"
                alt="img"
              />
              <p>We can't find any photos for you right now. Please check back later.</p>
            </div>
            // </div>
          )}
        </div>
      </div>
      {loading ? <Loader /> : null}
    </>
  );
};

const VideosTabContent = ({ companyId }) => {
  const [videos, setvideos] = useState({});
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (companyId) {
      fetchCompanyVideos();
    }
  }, []);
  const fetchCompanyVideos = () => {
    setLoading(true);
    getCompanyAboutById().then((resp) => {
      setLoading(false);
      setvideos(resp?.data?.data);
    });
  };
  return (
    <div className="events_listing p-3">
      <div className="p-5 text-center">
        <img src={require('../../../assets/images/small-error.png')} width="105px" alt="img" />
        <p>We can't find any videos for you right now. Please check back later.</p>
      </div>
    </div>
  );
};

const handleShowInstiType = (key) => {
  switch (key) {
    case 'company':
      return 'Corporate';

    case 'ngo':
      return 'Non Government';
    case 'npo':
      return 'Non Profit';

    case 'gov_agency':
      return 'Govt Agencies';

    case 'professionals':
      return 'Professionals';

    case 'entrepreneur':
      return 'Entrepreneur';

    default:
      return 'N/A';
  }
};

export default WithOutLoginCompanyDetail;
