/** 
 @copyright : ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
 @author     : Shiv Charan Panjeta 

 All Rights Reserved.
 Proprietary and confidential :  All information contained herein is, and remains
 the property of ToXSL Technologies Pvt. Ltd. and its partners.
 Unauthorized copying of this file, via any medium is strictly prohibited.
*/
import { useState } from 'react';
import { Link } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import { useRef } from 'react';
import { useOnClickOutside } from '../../../globals/helper';
import { useEffect } from 'react';
import * as APISERVICES from '../../../services/SocialServices';

function PrivacyDropDown(props) {
  const [show, setShow] = useState(false);
  const { setPrivacySelection } = props;

  const [singleSelectName, setSingleSelectName] = useState('Everyone');
  const [multiSelectName, setmultiSelectName] = useState([]);
  const [selectedData, setSelectedData] = useState(['4']);
  const [multiSelectData, setMultiSelectData] = useState([]);
  const [privacyList, setPrivacyList] = useState('');
  const ref = useRef();

  useOnClickOutside(ref, () => setShow(false));

  useEffect(() => {
    isPrivacylist();
    setPrivacySelection(multiSelectData?.length !== 0 ? multiSelectData : selectedData);
  }, [multiSelectData, selectedData]);

  const isPrivacylist = () => {
    APISERVICES.postPrivacyList().then((res) => {
      if (res?.status == 200) {
        let arr = [];
        let data = res?.data?.data;
        for (let i in data) {
          arr.push({ id: i, label: data[i], value: data[i] });
        }
        setPrivacyList(arr);
      }
    });
  };

  const settingPrivacySelect = (data) => {
    if (data?.id == 0 || data?.id == 4) {
      setMultiSelectData([]);
      setmultiSelectName([]);
      setSingleSelectName(data?.value);
      if (selectedData.includes(data?.id)) {
        setSelectedData([]);
        setSingleSelectName('');
      } else {
        setSelectedData([data?.id]);
      }
    } else {
      setSelectedData([]);
      setSingleSelectName('');
      if (multiSelectData.includes(data?.id)) {
        let filterData = multiSelectData?.filter((item) => item !== data?.id);
        let filterName = multiSelectName?.filter((item) => item !== data?.value);

        setMultiSelectData(filterData);
        setmultiSelectName(filterName);
      } else {
        setMultiSelectData([...multiSelectData, data?.id]);
        setmultiSelectName([...multiSelectName, data?.value]);
      }
    }
  };

  const privacyDropClassSingle = (data) => {
    if (selectedData?.length == 0) {
      return '';
    } else {
      if (selectedData?.includes(data?.id)) {
        return 'selected';
      } else {
        return 'disabled-component';
      }
    }
  };
  const privacyDropClassMulti = (data) => {
    if (multiSelectData?.length == 0) {
      return '';
    } else {
      if (multiSelectData?.includes(data?.id)) {
        return 'selected';
      } else {
        return '';
      }
    }
  };

  const selectedValue = () => {
    let name;
    if (multiSelectData?.length !== 0 || singleSelectName !== '') {
      if (multiSelectName?.length !== 0) {
        name = multiSelectName?.map((name) => name).join(',');

        return <Form.Control type="text" value={name} onClick={() => setShow(!show)} />;
      } else {
        return <Form.Control type="text" value={singleSelectName} onClick={() => setShow(!show)} />;
      }
    } else {
      return <Form.Control type="text" value={'Nothing selected'} onClick={() => setShow(!show)} />;
    }
  };
  return (
    <div className="wall-ftr-rt" ref={ref}>
      <Form.Group className="mb-0" controlId="formBasictext">
        {selectedValue()}
      </Form.Group>
      {show && (
        <ul className="wall-lst">
          {privacyList?.length !== 0 ? (
            privacyList?.map((data, index) => {
              return (
                <li key={index}>
                  <Link
                    className={
                      multiSelectData?.length !== 0
                        ? privacyDropClassMulti(data)
                        : privacyDropClassSingle(data)
                    }
                    onClick={() => settingPrivacySelect(data, index)}>
                    {data?.value}
                  </Link>
                </li>
              );
            })
          ) : (
            <li>
              <Link className="selected disabled-component">No data found</Link>
            </li>
          )}
        </ul>
      )}
    </div>
  );
}

export default PrivacyDropDown;
