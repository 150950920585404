/**
@copyright  :   ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
@author     :   Shiv Charan Panjeta 
 
All Rights Reserved.
Proprietary and confidential :  All information contained here in is, and remains
the property of ToXSL Technologies Pvt. Ltd. and it's partners.
Unauthorized copying of this file, via any medium is strictly prohibited.
*/

import moment from 'moment';
import { useEffect, useState } from 'react';
import { Alert, Button, Container } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Table from 'react-bootstrap/Table';
import ReactPaginate from 'react-paginate';
import { Link, useNavigate } from 'react-router-dom';
import Loader from '../../components/commoncomponents/loader/loader-large';
import useDocumentTitle from '../../globals/useDocumentTitle';
import { getJobfestList, setMultipleJobsFestStatus } from '../../services/AuthServices';
import showNotification from '../../services/NotificationService';

export default function ListJobFest() {
  useDocumentTitle('Manage Job Fest | AlmaBay');
  const components = {
    DropdownIndicator: null
  };
  const [chekcedId, setCheckedId] = useState([]);
  const [allId, setAllId] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [orderBy, setOrderBy] = useState('ASC');
  const [shortOrderBy, setShortOrderBy] = useState('id');
  const [list, setList] = useState([]);
  const [response, setResponse] = useState('');
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [searchedData, setSearchedData] = useState({
    fest_id: '',
    fest_title: '',
    fest_loc: '',
    fest_status: ''
  });

  const [showalert, setShowAlert] = useState(false);
  useEffect(() => {
    apiCall();
  }, []);

  useEffect(() => {
    const timeId = setTimeout(() => {
      // After 3 seconds set the show value to false
      setShowAlert(false);
    }, 3000);

    return () => {
      clearTimeout(timeId);
    };
  }, [showalert]);

  const apiCall = (order = 'DESC', sortField = 'fest_id') => {
    setLoading(true);
    let body = {
      fest_id: searchedData?.fest_id,
      fest_title: searchedData?.fest_title,
      fest_loc: searchedData?.fest_loc,
      fest_status: searchedData?.fest_status
    };
    getJobfestList(body, order, sortField).then((res) => {
      setLoading(false);
      if (res?.status === 200) {
        setList(res?.data?.detail?.viewlist);
        setResponse(res?.data?.pager);
        setAllId(res?.data?.detail?.viewlist?.map((data) => data?.id));
      }
    });
  };

  const onChangeSearch = (e) => {
    const { name, value } = e.target;
    setSearchedData({ ...searchedData, [name]: value });
  };

  const searchHandler = (e) => {
    e.preventDefault();
    apiCall(
      currentPage,
      searchedData.fest_id,
      searchedData.fest_title,
      searchedData.fest_loc,
      searchedData.fest_status
    );
  };

  const resetHandler = (e) => {
    setSearchedData({
      fest_id: '',
      fest_title: '',
      fest_loc: '',
      fest_status: ''
    });
    apiCall();
  };

  const handlePageClick = (a) => {
    let page = a?.selected + 1;
    setCheckedId([]);
    apiCall(
      page,
      searchedData.fest_id,
      searchedData.fest_title,
      searchedData.fest_loc,
      searchedData.fest_status
    );
  };
  const handleNaviagte = (e, id) => {
    e.preventDefault();
    navigate('/admin/jobfest/view-detail', { state: id });
  };
  const handleNaviagteEdit = (e, id) => {
    e.preventDefault();
    navigate('/admin/jobfest/create', { state: id });
  };
  const handleNavigatetoApplicants = (e, data) => {
    e.preventDefault();
    navigate('/admin/jobfest/applicants', { state: data });
  };

  const handleMultipleCheckBox = (id) => {
    if (chekcedId.includes(id)) {
      let filterId = chekcedId.filter((data) => data !== id);
      setCheckedId(filterId);
    } else {
      setCheckedId([...chekcedId, id]);
    }
  };

  const handleSelectAll = (e) => {
    if (e.target.checked === true) {
      setCheckedId(allId);
    } else {
      setCheckedId([]);
    }
  };

  const sortHandler = (e, type) => {
    e.preventDefault();
    let currentSort = orderBy === 'ASC' ? 'DESC' : 'ASC';
    setOrderBy(currentSort);
    setShortOrderBy(type);
    apiCall(currentSort, type);
  };
  const globalStateHandler = () => {
    setShowAlert(true);
  };
  const handlePerformAction = (e) => {
    const result = window.confirm('Do you want to continue');
    if (result) {
      if (chekcedId.length !== 0) {
        setShowAlert(false);
        let body = {
          id: chekcedId.toString(),
          status: e.target.value
        };
        let formData = new FormData();
        for (let i in body) {
          formData.append(`${i}`, body[i]);
        }
        setMultipleJobsFestStatus(formData).then((res) => {
          if (res?.status === 200) {
            apiCall();
            setCheckedId([]);
            if (e.target.value == '0') {
              showNotification('success', 'Selected Job Fest has been disabled successfully');
            } else {
              showNotification('success', 'Selected Job Fest been enabled successfully');
            }
          }
        });
      } else {
        globalStateHandler();
      }
    }
  };
  return (
    <>
      <div className="dash-main-content">
        <Container fluid>
          <div className="dash-page-content">
            <div className="dash-page-title">
              <Row>
                <Col xs={12} sm={12} md={12}>
                  <div className="dash-title-bar d-flex align-items-center justify-content-between">
                    <div className="title-lft d-flex align-items-center">
                      <div className="dash-icn">
                        <i className="fas fa-briefcase"></i>
                      </div>
                      <div className="dash-title-dis">
                        <h5>
                          Job Fest <span>Listing</span>
                        </h5>
                        <h5>
                          Total Record(s):<span>{list?.length}</span>
                        </h5>
                      </div>
                    </div>
                    <div className="title-rt-btn">
                      <Link className="green_button_admin mx-2" to="/admin/jobfest/create">
                        Add Job Fest
                      </Link>
                      <div className="head-instite-sec ms-4">
                        <Form.Select
                          aria-label="Default select example"
                          onChange={(e) => {
                            handlePerformAction(e);
                          }}>
                          <option>Choose Action</option>
                          <option value="1">Enable</option>
                          <option value="0">Disable</option>
                        </Form.Select>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="dash-content-card">
              <Alert variant={'danger'} className={`${showalert ? '' : 'd-none'}`}>
                Please select any record
              </Alert>
              <div className="table-responsive">
                <Table className="custom-table-data" striped bordered hover>
                  <thead>
                    <tr>
                      <th>
                        <Form.Check
                          type="checkbox"
                          id="checkbox"
                          label=""
                          checked={
                            allId?.length !== 0 ? allId?.length === chekcedId?.length : false
                          }
                          onChange={handleSelectAll}
                        />
                      </th>
                      <th>
                        <Link
                          to="#"
                          onClick={(e) => {
                            sortHandler(e, 'fest_id');
                          }}>
                          Id
                          <img src={require('../../assets/images/sort.png')} alt="sort" />
                        </Link>
                      </th>
                      <th>
                        <Link
                          to="#"
                          onClick={(e) => {
                            sortHandler(e, 'fest_title');
                          }}>
                          Title
                          <img src={require('../../assets/images/sort.png')} alt="sort" />
                        </Link>
                      </th>
                      <th>
                        <Link
                          to="#"
                          onClick={(e) => {
                            sortHandler(e, 'location');
                          }}>
                          Location
                          <img src={require('../../assets/images/sort.png')} alt="sort" />
                        </Link>
                      </th>
                      <th>Time Period</th>
                      <th>Applicants</th>
                      <th>
                        <Link
                          to="#"
                          onClick={(e) => {
                            sortHandler(e, 'fest_status');
                          }}>
                          Status
                          <img src={require('../../assets/images/sort.png')} alt="sort" />
                        </Link>
                      </th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="search-table">
                      <td></td>
                      <td>
                        <Form.Control
                          type="text"
                          placeholder="Id"
                          value={searchedData.fest_id}
                          name="fest_id"
                          onChange={(e) => {
                            onChangeSearch(e);
                          }}
                        />
                      </td>
                      <td>
                        <Form.Control
                          type="text"
                          placeholder="Title"
                          value={searchedData.fest_title}
                          name="fest_title"
                          onChange={(e) => {
                            onChangeSearch(e);
                          }}
                        />
                      </td>
                      <td>
                        <Form.Control
                          type="text"
                          placeholder="Location"
                          value={searchedData.fest_loc}
                          name="fest_loc"
                          onChange={(e) => {
                            onChangeSearch(e);
                          }}
                        />
                      </td>
                      <td></td>
                      <td></td>
                      <td>
                        <Form.Select
                          aria-label="Default select example"
                          value={searchedData?.fest_status}
                          name="fest_status"
                          onChange={(e) => {
                            onChangeSearch(e);
                          }}>
                          <option value="">Select</option>
                          <option value={1}>Enable</option>
                          <option value={2}>Disable</option>
                        </Form.Select>
                      </td>
                      <td className="serac-btns" colSpan={2}>
                        <Button
                          className="green_button_admin"
                          type="button"
                          onClick={(e) => {
                            searchHandler(e);
                          }}>
                          Search
                        </Button>
                        <Button
                          className="main-btn-red ms-2"
                          onClick={(e) => {
                            resetHandler(e);
                          }}>
                          Reset
                        </Button>
                      </td>
                    </tr>

                    {list && list?.length ? (
                      list?.map((data, index) => (
                        <tr key={index}>
                          <td>
                            <Form.Check
                              type="checkbox"
                              label=""
                              id={`id${data?.id}`}
                              checked={chekcedId?.includes(data?.id)}
                              onChange={() => handleMultipleCheckBox(data?.id)}
                            />
                          </td>
                          <td>{data?.id}</td>
                          <td>{data?.title}</td>
                          <td>{data?.city}</td>
                          <td>
                            <p className="mb-0">
                              <b>Start :</b>
                              {moment(data?.start_date).format('ll')}
                            </p>
                            <p className="mb-0">
                              <b>End :</b>
                              {moment(data?.end_date).format('ll')}
                            </p>
                          </td>
                          {data?.totalCount > 0 ? (
                            <td>
                              <Link to="" onClick={(e) => handleNavigatetoApplicants(e, data)}>
                                View {data?.totalCount} Applicant(s)
                              </Link>
                            </td>
                          ) : (
                            <td>No Applicants</td>
                          )}

                          <td>{data?.status == 1 ? 'Enable' : 'Disable'}</td>
                          <td>
                            <Link
                              className="search-btn"
                              to="#"
                              onClick={(e) => handleNaviagte(e, data?.id)}>
                              <i className="fas fa-search-plus" title="VIEW"></i>
                            </Link>

                            <Link
                              className="edit-btn"
                              to="#"
                              onClick={(e) => handleNaviagteEdit(e, data?.id)}>
                              <i className="fas fa-edit" title="EDIT"></i>
                            </Link>

                            <Link
                              className="edit-btn"
                              to=""
                              onClick={(e) => {
                                e.preventDefault();
                                navigate('/admin/jobfest/joblist', { state: data });
                              }}>
                              <i className="fas fa-briefcase" title="View Jobs"></i>
                            </Link>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr className="text-center">
                        <td colSpan={9}>Data Not Found</td>
                      </tr>
                    )}
                  </tbody>
                </Table>
                <div className="react-page d-flex">
                  {response / 10 > 1 ? (
                    <ReactPaginate
                      previousLabel={currentPage > 0 ? '<' : ''}
                      nextLabel=">"
                      onPageChange={handlePageClick}
                      activeClassName={'page-link active'}
                      pageCount={response / 10}
                    />
                  ) : (
                    ''
                  )}
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
      {loading ? <Loader /> : ''}
    </>
  );
}
