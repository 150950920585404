/**
@copyright  :   ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
@author     :   Shiv Charan Panjeta 
 
All Rights Reserved.
Proprietary and confidential :  All information contained here in is, and remains
the property of ToXSL Technologies Pvt. Ltd. and it's partners.
Unauthorized copying of this file, via any medium is strictly prohibited.
*/

import { useEffect, useState } from 'react';
import { Button, Container } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Table from 'react-bootstrap/Table';
import ReactPaginate from 'react-paginate';
import { Link, useNavigate, useParams } from 'react-router-dom';
import NoDataFound from '../../components/commoncomponents/NoDataFound';
import Loader from '../../components/commoncomponents/loader/loader-large';
import useDocumentTitle from '../../globals/useDocumentTitle';
import * as ADMINSERVICES from '../../services/AuthServices';
import showNotification from '../../services/NotificationService';

export default function ClubNews() {
  useDocumentTitle('Manage News | AlmaBay');
  const { club_id } = useParams();
  useEffect(() => {
    apiCall();
  }, []);
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(0);
  const [record, setRecord] = useState(0);
  const [loading, setLoading] = useState(false);

  const [newsDetail, setNewsDetail] = useState([]);
  const [detail, setDetail] = useState({
    id: '',
    title: '',
    status: ''
  });

  const apiCall = (title = '', id = '') => {
    setLoading(true);
    // let pageNo = page_number ? page_number : 0;
    // setCurrentPage(pageNo);
    let body = {
      club_id,
      title,
      id
    };
    ADMINSERVICES.getClubNews(body).then((resp) => {
      setLoading(false);
      if (resp?.status === 200) {
        setNewsDetail(resp?.data?.details);
        setRecord(resp?.data?.counts);
      }
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setDetail({ ...detail, [name]: value.trim() });
  };

  const handleSearch = () => {
    apiCall(detail.title, detail.id);
  };

  //paginate

  const handlePageClick = (a) => {
    let currentPage = a?.selected + 1;
    apiCall(detail.title, detail.id, currentPage);
  };

  const selectHandler = (value, id) => {
    switch (value) {
      case 'view':
        navigate(`/admin/news/index/view_news?id=${id}`);
        break;
      case 'edit':
        navigate(`/admin/club/news/edit/${club_id}/${id}`);
        break;
      case 'delete':
        handleDelete(club_id, id);
        break;
      default:
        break;
    }
  };

  const resetHandler = (e) => {
    e.preventDefault();
    setDetail({
      id: '',
      title: '',
      status: ''
    });
    apiCall();
  };

  /**
   * delete based on club_id and id
   * @param {*} id
   */
  const handleDelete = (club_id, id) => {
    setLoading(true);
    ADMINSERVICES.deleteClubNews(id).then((res) => {
      if (res?.status == 200) {
        document.getElementById('drop_down').value = '';
        showNotification('success', res?.data?.message);
        apiCall();
      }
    });
  };

  return (
    <>
      <div className="dash-main-content">
        <Container fluid>
          <div className="dash-page-content">
            <div className="dash-page-title">
              <Row>
                <Col xs={12} sm={12} md={12}>
                  <div className="dash-title-bar d-flex align-items-center justify-content-between">
                    <div className="title-lft d-flex align-items-center">
                      <div className="dash-icn">
                        <i className="fas fa-envelope"></i>
                      </div>
                      <div className="dash-title-dis">
                        <h5>
                          Club News <span>Listing</span>
                        </h5>
                        <h5>
                          Total Record(s): <span> {record ? record : ''} </span>
                        </h5>
                      </div>
                    </div>
                    <div className="title-rt-btn">
                      <Link className="green_button_admin" to={`/admin/club/news/add/${club_id}`}>
                        Add Club News
                      </Link>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="dash-content-card">
              <div className="table-responsive">
                <Table className="custom-table-data" striped bordered hover>
                  <thead>
                    <tr>
                      <th>Id</th>
                      <th>title</th>
                      <th>Image</th>
                      <th>Status</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="search-table">
                      <td>
                        <Form.Control
                          type="text"
                          placeholder="Id"
                          name="id"
                          onChange={(e) => handleChange(e)}
                          value={detail.id}
                        />
                      </td>
                      <td>
                        <Form.Control
                          type="text"
                          placeholder="Title"
                          name="title"
                          onChange={(e) => handleChange(e)}
                          value={detail.title}
                        />
                      </td>
                      <td></td>
                      <td></td>
                      <td className="serac-btns text-center">
                        <Button
                          className="main-btn-grn"
                          onClick={(e) => {
                            handleSearch(e);
                          }}>
                          Search
                        </Button>
                        <Button
                          className="main-btn-red ms-2"
                          onClick={(e) => {
                            resetHandler(e);
                          }}>
                          Reset
                        </Button>
                      </td>
                    </tr>
                    {newsDetail && newsDetail?.length ? (
                      newsDetail?.map((data, index) => (
                        <tr key={index}>
                          <td>{data?.id || 'NA'}</td>
                          <td>{data?.title || 'NA'}</td>
                          <td>
                            {data?.attachment && (
                              <img src={data?.attachment} alt="img" width="180" height="125" />
                            )}
                          </td>
                          <td>{data?.status && data?.status == 1 ? 'Disable' : 'Enable'} </td>
                          <td>
                            <Form.Select
                              aria-label="Default select example"
                              onChange={(e) => {
                                selectHandler(e.target.value, data?.id);
                              }}
                              id="drop_down">
                              <option value="">Action</option>
                              <option value="edit">Edit</option>
                              <option value="delete">Delete</option>
                            </Form.Select>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr className="text-center">
                        <td colSpan={9}>
                          <NoDataFound />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
                {Math.ceil(record / 10) > 1 ? (
                  <div className="react-page d-flex justify-content-center">
                    <ReactPaginate
                      previousLabel={currentPage > 0 ? '<' : ''}
                      nextLabel={record > 0 ? '>' : ''}
                      onPageChange={handlePageClick}
                      activeClassName={'page-link active'}
                      pageCount={Math.ceil(record / 10)}
                    />
                  </div>
                ) : (
                  ''
                )}
              </div>
            </div>
          </div>
        </Container>
      </div>
      {loading ? <Loader /> : ''}
    </>
  );
}
