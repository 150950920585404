import moment from 'moment';
import { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import Iframe from 'react-iframe';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { getInsEventView } from '../../../services/InstituteServices';
import Loader from '../../commoncomponents/loader/loader-large';
import eventDummy from "../../../assets/images/event.png"

function EventDetailInstitute() {
  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [eventDetail, setEventDetail] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (id) {
      //fetching id from slug i.e.. 13-title
      getNews(id?.split('-')[0]);
    }
  }, []);

  const getNews = (id) => {
    getInsEventView(id).then((res) => {
      setLoading(false)
      if (res?.status == 200) {
        setEventDetail(res?.data?.data?.details);
      }
    });
  };

  return (
    <>
      <div className="wrappermain_outer bggreylight">
        <section className="event-page-sec py-5">
          <Container className="p-0">
            <button
              className="btn btn-back mb-4 mt-5"
              type="button"
              onClick={() => {
                navigate(-1);
              }}>
              Back
            </button>
            <div className="event-page-inr">
              <div className="event-page-banner">

                <img
                  src={eventDetail?.event_pic ?
                    process.env.REACT_APP_API_DOMAIN +
                    'common/uploads/event/' +
                    eventDetail?.event_pic : eventDummy
                  }
                  alt="Event"
                />

                <div className="event-banner-ftr d-flex align-items-center justify-content-between">
                  <h4>{eventDetail?.category_name}</h4>

                  <Link
                    to="/institute-login"
                    state={`/user/events-detail/${location?.state}/overview`}>
                    Join Now
                  </Link>
                </div>
              </div>
              <div className="event-card-disc bg-white">
                <div className="event-card-spec mb-3">
                  <h4>Detailed</h4>
                  <p>{eventDetail?.decp}</p>
                </div>
                <div className="event-card-spec mb-3">
                  <h4>Title:</h4>
                  <p>{eventDetail?.title || eventDetail?.name}</p>
                </div>
                {/* as per client feedback */}
                {/* <div className="event-card-spec mb-3">
                <h4>Speaker</h4>
                <p>
                  {eventDetail?.decp}
                </p>
              </div> */}
                <div className="event-card-spec mb-3">
                  <h4>Event Date:</h4>
                  <p>
                    {moment(eventDetail?.from_event_date).format('DD MMM YYYY - HH:mm')}{' '}
                    {eventDetail?.to_event_date != '0000-00-00 00:00:00'
                      ? `-to- ${moment(eventDetail?.to_event_date).format('DD MMM YYYY - HH:mm')}`
                      : ''}
                  </p>
                </div>
                <div className="event-card-spec mb-3">
                  <h4>Venue</h4>
                  <p>
                    {eventDetail?.venue},{eventDetail?.location_name}
                  </p>
                </div>
                {/* <div className="event-card-spec mb-3">
                <h4>Event Date:</h4>
                <p>{moment(eventDetail?.to_event_date).format("DD MMM YYYY - HH:mm")}</p>
              </div> */}
              </div>
              <div className="event-map">
                <Iframe
                  src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d54887.55600926899!2d76.72573620969241!3d30.70512157580728!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sin!4v1678359246122!5m2!1sen!2sin"
                  width="100%"
                  height="320px"
                  className=""
                  display="block"
                  position="relative"
                />
              </div>
            </div>
          </Container>
        </section>
      </div>
      <div className="lgn-link py-4">
        <Container>
          <div className="logn-lnk-inr">
            <p>
              <Link to="/institute-register">Register</Link> /{' '}
              <Link to="/institute-login">Login</Link> to browse members by Year, Name, Company,
              Profession or Location
            </p>
          </div>
        </Container>
      </div>
      {loading ? <Loader /> : <></>}
    </>
  );
}

export default EventDetailInstitute;
