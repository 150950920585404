// @copyright : ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
// @author     : Shiv Charan Panjeta

// All Rights Reserved.
// Proprietary and confidential :  All information contained herein is, and remains
// the property of ToXSL Technologies Pvt. Ltd. and its partners.
// Unauthorized copying of this file, via any medium is strictly prohibited.

import { useEffect, useState } from 'react';
import { Button, Container } from 'react-bootstrap';
import Alert from 'react-bootstrap/Alert';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Table from 'react-bootstrap/Table';
import ReactPaginate from 'react-paginate';
import { Link, useNavigate } from 'react-router-dom';
import AsyncSelect from 'react-select/async';
import Loader from '../../components/commoncomponents/loader/loader-large';
import { STATUS_DELETE, STATUS_DISABLE, STATUS_ENABLE } from '../../globals/constants';
import { statusName } from '../../globals/helper';
import useDocumentTitle from '../../globals/useDocumentTitle';
import * as adminServices from '../../services/AuthServices';
import showNotification from '../../services/NotificationService';

export default function UniversityList() {
  useDocumentTitle('Manage University | AlmaBay');
  const [list, setList] = useState([]);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [searchedData, setSearchData] = useState({
    sortfield: ' institute_id',
    institute_id: '',
    institute_name: '',
    institute_affiliate: '',
    institute_email: '',
    institute_city: '',
    institute_phone: '',
    created_by: '',
    institute_status: '',
    institute_type: ''
  });
  const [response, setResponse] = useState(0);
  const [sortType, setSortType] = useState('DESC');
  const [sortField, setSortField] = useState('institute_id');
  const [currentPage, setCurrentPage] = useState(1);
  const [showalert, setShowAlert] = useState(false);
  const [checkedId, setCheckedId] = useState([]);
  const [allId, setAllId] = useState([]);
  const [globalMsg, setGlobalMsg] = useState('Please Select Any Record');
  useEffect(() => {
    apiCall();
  }, []);

  const apiCall = (
    page = '',
    sortfield = '',
    order = sortType,
    institute_id = '',
    institute_name = '',
    institute_affiliate = '',
    institute_email = '',
    institute_phone = '',
    institute_city = '',
    institute_status = '',
    created_by = '',
    institute_type = 'university'
  ) => {
    setLoading(true);
    let pageNo = page ? page : 0;
    setCurrentPage(pageNo);
    let body = {
      page,
      sortfield,
      order,
      institute_id,
      institute_name,
      institute_affiliate,
      institute_email,
      institute_phone,
      institute_city,
      institute_status,
      created_by,
      institute_type
    };
    adminServices.getUniversityList(body).then((res) => {
      setLoading(false);
      if (res?.status === 200) {
        setList(res?.data?.data?.viewlist);
        setAllId(res?.data?.data?.viewlist?.map((data) => data?.institute_id));
        setResponse(res?.data?.data?.totalRecords);
      } else {
        showNotification(res?.data?.message);
      }
    });
  };

  const loadCityOpt = (inputValue) => {
    return adminServices.getLocationDropDown(inputValue).then((res) => {
      if (res?.status === 200) {
        let tempArr = [];
        if (res?.data?.data) {
          const data = res?.data?.data;
          data?.map((data) => {
            tempArr.push({
              id: data?.id,
              label: data?.location,
              value: data?.location
            });
          });
        }
        return tempArr;
      }
    });
  };

  const loadAffiliatedOpt = (inputValue) => {
    return adminServices.getAffiliatedTo(inputValue).then((res) => {
      if (res?.status === 200) {
        let tempArr = [];
        if (res?.data?.data) {
          const data = res?.data?.data;
          data?.map((data) => {
            tempArr.push({
              id: data?.institute_id,
              label: data?.name,
              value: data?.name
            });
          });
        }
        return tempArr;
      }
    });
  };

  const stateHandler = (value, id) => {
    setLoading(true);
    let status = value;
    adminServices.cityUpdateStatus(id, status).then((res) => {
      setLoading(false);
      if (res?.status === 200) {
        document.getElementById('select').value = '';
        apiCall();
      }
    });
  };

  const globalStateHandler = () => {
    setShowAlert(true);
    setInterval(function () {
      setShowAlert(false);
    }, 3000);
  };

  const sortHandler = (e, type) => {
    e.preventDefault();
    let currentSort = sortType === 'ASC' ? 'DESC' : 'ASC';
    setSortType(currentSort);
    setSortField(type);
    apiCall(
      currentPage,
      sortField,
      //currentSort
      sortType,
      searchedData.institute_id,
      searchedData.institute_name,
      searchedData.institute_affiliate,
      searchedData.institute_email,
      searchedData.institute_phone,
      searchedData.institute_city,
      searchedData.institute_status,
      searchedData.created_by
    );
  };

  const onChangeSearch = (e) => {
    const { name, value } = e.target;
    setSearchData({ ...searchedData, [name]: value });
  };

  const searchHandler = (page = '') => {
    apiCall(
      page,
      sortField,
      sortType,
      searchedData.institute_id,
      searchedData.institute_name,
      searchedData.institute_affiliate?.id,
      searchedData.institute_email,
      searchedData.institute_phone,
      searchedData.institute_city?.id,
      searchedData.institute_status,
      searchedData.created_by
    );
  };

  const handlePageClick = (a) => {
    setCheckedId([]);
    let page = a?.selected + 1;
    searchHandler(page);
  };

  const resetHandler = (e) => {
    e.preventDefault();
    setSearchData({
      institute_id: '',
      institute_name: '',
      institute_affiliate: '',
      institute_email: '',
      institute_city: '',
      institute_phone: '',
      created_by: '',
      institute_status: ''
    });
    setSortType('DESC');
    setSortField('');
    apiCall();
  };

  const selectHandler = (value, id) => {
    switch (value) {
      case 'view':
        navigate('/admin/users/showallusers', { state: id });
        break;
      case 'edit':
        navigate(`/admin/edit-institute/${id}`);
        break;
      case 'addEvent':
        navigate(`/admin/event/create`);
        break;
      case 'instituteList':
        navigate(`/admin/institute/showAll`);
        break;
      default:
        break;
    }
  };

  const handleSelectAll = (e) => {
    if (e.target.checked === true) {
      setCheckedId(allId);
    } else {
      setCheckedId([]);
    }
  };

  const globalActionHandler = (e) => {
    if (checkedId.length !== 0) {
      setLoading(true);
      setShowAlert(true);
      let body = {
        ids: checkedId.join(),
        action: e.target.value
      };
      if (confirm('Are you sure you want to change state  ?') === true) {
        adminServices.univerisityMultiAction(body).then((res) => {
          setLoading(false);
          setShowAlert(false);
          if (res?.status === 200) {
            setGlobalMsg(res?.data?.message);
            setShowAlert(true);
            setCheckedId([]);
            alertHandler();
            document.getElementById('global').value = '';
            apiCall();
          }
        });
      } else {
        return;
      }
    } else {
      setShowAlert(true);
      alertHandler();
    }
  };

  const handleMultipleCheckBox = (id) => {
    if (checkedId.includes(id)) {
      let filterId = checkedId.filter((data) => data !== id);
      setCheckedId(filterId);
    } else {
      setCheckedId([...checkedId, id]);
    }
  };

  let intervalId;

  const alertHandler = () => {
    clearInterval(intervalId);
    intervalId = setInterval(function () {
      setShowAlert(false);
    }, 5000);
  };

  return (
    <>
      <div className="dash-main-content">
        <Container fluid>
          <div className="dash-page-content">
            <div className="dash-page-title">
              <Row>
                <Col xs={12} sm={12} md={12}>
                  <div className="dash-title-bar d-flex align-items-center justify-content-between">
                    <div className="title-lft d-flex align-items-center">
                      <div className="dash-icn">
                        <i className="fas fa-university"></i>
                      </div>
                      <div className="dash-title-dis">
                        <h5>
                          University <span>Listing</span>
                        </h5>
                        <h5>
                          Total Record(s): <span>{response}</span>
                        </h5>
                      </div>
                    </div>
                    <div className="title-rt-btn">
                      <div className="head-instite-sec">
                        <Form.Select
                          aria-label="Default select example"
                          // onChange={(e) => {
                          //   globalStateHandler(e);
                          // }}
                          id="global"
                          onChange={(e) => {
                            globalActionHandler(e);
                          }}>
                          <option>Choose Action</option>
                          <option value={STATUS_ENABLE}>Enable</option>
                          <option value={STATUS_DISABLE}>Disable</option>
                          <option value={STATUS_DELETE}>Delete</option>
                        </Form.Select>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="dash-content-card">
              <Alert variant={'success'} className={`${showalert ? '' : 'd-none'}`}>
                {globalMsg}
              </Alert>

              <div className="table-responsive">
                <Table className="custom-table-data" striped bordered hover>
                  <thead>
                    <tr>
                      <th>
                        <Form.Check
                          type="checkbox"
                          id="checkbox"
                          name="checkall"
                          checked={allId?.length === checkedId?.length}
                          onChange={(e) => {
                            handleSelectAll(e);
                          }}
                          label=""
                        />
                      </th>
                      <th>
                        <Link
                          to=""
                          onClick={(e) => {
                            sortHandler(e, 'name');
                          }}>
                          Id
                          <img src={require('../../assets/images/sort.png')} alt="sort" />
                        </Link>
                      </th>
                      <th>
                        <Link
                          to=""
                          onClick={(e) => {
                            sortHandler(e, 'name');
                          }}>
                          University Name
                          <img src={require('../../assets/images/sort.png')} alt="sort" />
                        </Link>
                      </th>
                      <th>
                        <Link
                          to=""
                          onClick={(e) => {
                            sortHandler(e, 'institute_affiliate');
                          }}>
                          Affiliate To
                          <img src={require('../../assets/images/sort.png')} alt="sort" />
                        </Link>
                      </th>
                      <th>
                        <Link
                          to=""
                          onClick={(e) => {
                            sortHandler(e, 'institute_email');
                          }}>
                          Email Id
                          <img src={require('../../assets/images/sort.png')} alt="sort" />
                        </Link>
                      </th>
                      <th>
                        <Link
                          to=""
                          onClick={(e) => {
                            sortHandler(e, 'institute_city');
                          }}>
                          City
                          <img src={require('../../assets/images/sort.png')} alt="sort" />
                        </Link>
                      </th>
                      <th>
                        <Link
                          to=""
                          onClick={(e) => {
                            sortHandler(e, 'created_by');
                          }}>
                          Created By
                          <img src={require('../../assets/images/sort.png')} alt="sort" />
                        </Link>
                      </th>
                      <th>
                        <Link
                          to=""
                          onClick={(e) => {
                            sortHandler(e, 'institute_status');
                          }}>
                          Status
                          <img src={require('../../assets/images/sort.png')} alt="sort" />
                        </Link>
                      </th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="search-table">
                      <td></td>
                      <td>
                        <Form.Control
                          onChange={onChangeSearch}
                          name="institute_id"
                          type="text"
                          placeholder="Id"
                        />
                      </td>
                      <td>
                        <Form.Control
                          onChange={onChangeSearch}
                          name="institute_name"
                          type="text"
                          placeholder="Name"
                        />
                      </td>
                      <td>
                        <AsyncSelect
                          isClearable
                          getOptionLabel={(e) => e.label}
                          getOptionValue={(e) => e.value}
                          loadOptions={loadAffiliatedOpt}
                          className="basic-single"
                          name="institute_affiliate"
                          onChange={(e) => {
                            setSearchData({ ...searchedData, ['institute_affiliate']: e });
                          }}
                        />
                      </td>
                      <td>
                        <Form.Control
                          onChange={onChangeSearch}
                          name="institute_email"
                          type="text"
                          placeholder="Email"
                        />
                      </td>
                      <td>
                        <AsyncSelect
                          isClearable={true}
                          getOptionLabel={(e) => e.label}
                          getOptionValue={(e) => e.value}
                          loadOptions={loadCityOpt}
                          className="basic-single"
                          name="institute_city"
                          onChange={(e) => {
                            setSearchData({ ...searchedData, ['institute_city']: e });
                          }}
                        />
                      </td>
                      <td>
                        <Form.Select
                          aria-label="Default select example"
                          onChange={onChangeSearch}
                          name="created_by">
                          <option>Select</option>
                          <option value="1">Admin</option>
                          <option value="0">Users</option>
                        </Form.Select>
                      </td>
                      <td>
                        <Form.Select
                          aria-label="Default select example"
                          onChange={onChangeSearch}
                          name="institute_status">
                          <option>Select</option>
                          <option value="1">Enable</option>
                          <option value="0">Disable</option>
                        </Form.Select>
                      </td>
                      <td className="serac-btns text-center" colSpan={2}>
                        <button
                          type="button"
                          className="green_button_admin"
                          onClick={() => searchHandler()}>
                          Search
                        </button>
                        <Button
                          className="main-btn-red ms-2"
                          onClick={(e) => {
                            resetHandler(e);
                          }}>
                          Reset
                        </Button>
                      </td>
                    </tr>
                    {list && list?.length ? (
                      list?.map((data, index) => (
                        <>
                          <tr key={index}>
                            <td>
                              <Form.Check
                                type="checkbox"
                                name="id"
                                id={`id${data?.institute_id}`}
                                checked={checkedId?.includes(data?.institute_id)}
                                onChange={() => handleMultipleCheckBox(data?.institute_id)}
                                label=""
                              />
                            </td>
                            <td>{data?.institute_id}</td>
                            <td>{data?.name}</td>
                            <td>{data?.affiliate_name}</td>
                            <td>{data?.admin_email}</td>
                            <td>{data?.city}</td>
                            <td>{data?.created_by == 1 ? 'Admin' : 'User'}</td>
                            <td>{statusName(data?.status)}</td>
                            <td>
                              <Form.Select
                                aria-label="Default select example"
                                onChange={(e) => {
                                  selectHandler(e.target.value, data?.institute_id);
                                }}>
                                <option>Action</option>
                                <option value="view">View Alumni</option>
                                <option value="edit">Edit</option>
                                <option value="addEvent">Add Event</option>
                                <option value="instituteList">Institute List</option>
                              </Form.Select>
                            </td>
                          </tr>
                        </>
                      ))
                    ) : (
                      <tr className="text-center">
                        <td colSpan={9}>Data Not Found</td>
                      </tr>
                    )}
                  </tbody>
                </Table>
                <div className="react-page d-flex">
                  {Math.ceil(response / 10) > 1 ? (
                    <ReactPaginate
                      previousLabel={currentPage > 0 ? '<' : ''}
                      nextLabel=">"
                      onPageChange={handlePageClick}
                      pageCount={response / 10}
                      activeClassName={'page-link active'}
                      forcePage={currentPage}
                    />
                  ) : (
                    ''
                  )}
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>

      {loading ? <Loader /> : ''}
    </>
  );
}
