import { Link } from 'react-router-dom';
import { useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import HeaderLogin from '../../commoncomponents/HeaderLogin';
import FooterMain from '../../commoncomponents/FooterMain';
import TopTabbings from './TopTabbings';
import GroupDiscover from '../../commoncomponents/newsfeeds/GroupDiscover';
import CampusnewsText from '../../commoncomponents/newsfeeds/CampusnewsText';
import ArticalBlogs from '../../commoncomponents/newsfeeds/ArticalBlogs';
import WhatsNew from '../../commoncomponents/newsfeeds/WhatsNew';
import Upcoming from '../../commoncomponents/newsfeeds/Upcoming';
import CampusNews from '../../commoncomponents/newsfeeds/CampusNews';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

function PeopleKnowPage() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <HeaderLogin />
      <div className="header-lnk-feeds">
        <div className="container">
          <TopTabbings state={'social'} />
        </div>
      </div>
      <section className="feed-sec bg-grey py-4">
        <div className="container">
          <Row>
            <Col xs={12} lg={12}>
              <div className="find-friend-count mb-4">
                <span className="friend-count-icn">
                  <i className="fas fa-user-plus"></i>
                </span>
                <h3>People You May Know </h3>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={12} lg={9} md={12}>
              <div className="bg-white p-4">
                <Row>
                  <Col lg={4}>
                    <div className="people-knw-card">
                      <Link to="#">
                        <div className="people-knw-img">
                          <img
                            src={require('../../../assets/images/mentorsdirectory-listview-3.png')}
                            alt="people"
                          />

                          {['top'].map((placement) => (
                            <OverlayTrigger
                              key={placement}
                              placement={placement}
                              overlay={
                                <Tooltip id={`tooltip-${placement}`}>
                                  Chandigarh, Chandigarh, India
                                </Tooltip>
                              }
                            >
                              <span className="people-loc">
                                <i className="fas fa-map-marker-alt"></i>
                              </span>
                            </OverlayTrigger>
                          ))}
                        </div>
                        <div className="knw-card-dis">
                          <h4>kanwar Vinod</h4>
                          <p>Chandigarh, Chandigarh, India</p>
                          <Link className="add-frnd-btn" to="#">
                            Add Friend
                          </Link>
                        </div>
                      </Link>
                    </div>
                  </Col>
                  <Col lg={4}>
                    <div className="people-knw-card">
                      <Link to="#">
                        <div className="people-knw-img">
                          <img
                            src={require('../../../assets/images/mentorsdirectory-listview-3.png')}
                            alt="people"
                          />

                          {['top'].map((placement) => (
                            <OverlayTrigger
                              key={placement}
                              placement={placement}
                              overlay={
                                <Tooltip id={`tooltip-${placement}`}>
                                  Chandigarh, Chandigarh, India
                                </Tooltip>
                              }
                            >
                              <span className="people-loc">
                                <i className="fas fa-map-marker-alt"></i>
                              </span>
                            </OverlayTrigger>
                          ))}
                        </div>
                        <div className="knw-card-dis">
                          <h4>kanwar Vinod</h4>
                          <p>Chandigarh, Chandigarh, India</p>
                          <Link className="add-frnd-btn" to="#">
                            Add Friend
                          </Link>
                        </div>
                      </Link>
                    </div>
                  </Col>
                  <Col lg={4}>
                    <div className="people-knw-card">
                      <Link to="#">
                        <div className="people-knw-img">
                          <img
                            src={require('../../../assets/images/mentorsdirectory-listview-3.png')}
                            alt="people"
                          />

                          {['top'].map((placement) => (
                            <OverlayTrigger
                              key={placement}
                              placement={placement}
                              overlay={
                                <Tooltip id={`tooltip-${placement}`}>
                                  Chandigarh, Chandigarh, India
                                </Tooltip>
                              }
                            >
                              <span className="people-loc">
                                <i className="fas fa-map-marker-alt"></i>
                              </span>
                            </OverlayTrigger>
                          ))}
                        </div>
                        <div className="knw-card-dis">
                          <h4>kanwar Vinod</h4>
                          <p>Chandigarh, Chandigarh, India</p>
                          <Link className="add-frnd-btn" to="#">
                            Add Friend
                          </Link>
                        </div>
                      </Link>
                    </div>
                  </Col>
                  <Col lg={4}>
                    <div className="people-knw-card">
                      <Link to="#">
                        <div className="people-knw-img">
                          <img
                            src={require('../../../assets/images/mentorsdirectory-listview-3.png')}
                            alt="people"
                          />

                          {['top'].map((placement) => (
                            <OverlayTrigger
                              key={placement}
                              placement={placement}
                              overlay={
                                <Tooltip id={`tooltip-${placement}`}>
                                  Chandigarh, Chandigarh, India
                                </Tooltip>
                              }
                            >
                              <span className="people-loc">
                                <i className="fas fa-map-marker-alt"></i>
                              </span>
                            </OverlayTrigger>
                          ))}
                        </div>
                        <div className="knw-card-dis">
                          <h4>kanwar Vinod</h4>
                          <p>Chandigarh, Chandigarh, India</p>
                          <Link className="add-frnd-btn" to="#">
                            Add Friend
                          </Link>
                        </div>
                      </Link>
                    </div>
                  </Col>
                  <Col lg={4}>
                    <div className="people-knw-card">
                      <Link to="#">
                        <div className="people-knw-img">
                          <img
                            src={require('../../../assets/images/mentorsdirectory-listview-3.png')}
                            alt="people"
                          />

                          {['top'].map((placement) => (
                            <OverlayTrigger
                              key={placement}
                              placement={placement}
                              overlay={
                                <Tooltip id={`tooltip-${placement}`}>
                                  Chandigarh, Chandigarh, India
                                </Tooltip>
                              }
                            >
                              <span className="people-loc">
                                <i className="fas fa-map-marker-alt"></i>
                              </span>
                            </OverlayTrigger>
                          ))}
                        </div>
                        <div className="knw-card-dis">
                          <h4>kanwar Vinod</h4>
                          <p>Chandigarh, Chandigarh, India</p>
                          <Link className="add-frnd-btn" to="#">
                            Add Friend
                          </Link>
                        </div>
                      </Link>
                    </div>
                  </Col>
                  <Col lg={4}>
                    <div className="people-knw-card">
                      <Link to="#">
                        <div className="people-knw-img">
                          <img
                            src={require('../../../assets/images/mentorsdirectory-listview-3.png')}
                            alt="people"
                          />

                          {['top'].map((placement) => (
                            <OverlayTrigger
                              key={placement}
                              placement={placement}
                              overlay={
                                <Tooltip id={`tooltip-${placement}`}>
                                  Chandigarh, Chandigarh, India
                                </Tooltip>
                              }
                            >
                              <span className="people-loc">
                                <i className="fas fa-map-marker-alt"></i>
                              </span>
                            </OverlayTrigger>
                          ))}
                        </div>
                        <div className="knw-card-dis">
                          <h4>kanwar Vinod</h4>
                          <p>Chandigarh, Chandigarh, India</p>
                          <Link className="add-frnd-btn" to="#">
                            Add Friend
                          </Link>
                        </div>
                      </Link>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col xs={12} lg={3} md={12}>
              <div>
                <GroupDiscover />
              </div>
              <div>
                <CampusNews />
              </div>
              <div>
                <CampusnewsText />
              </div>
              <div>
                <ArticalBlogs />
              </div>
              <div>
                <WhatsNew />
              </div>
              <div>
                <Upcoming />
              </div>
            </Col>
          </Row>
        </div>
      </section>
      <FooterMain className={'colour-bg'} />
    </>
  );
}

export default PeopleKnowPage;
