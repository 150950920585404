/**
@copyright  :   ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
@author     :   Shiv Charan Panjeta 
 
All Rights Reserved.
Proprietary and confidential :  All information contained here in is, and remains
the property of ToXSL Technologies Pvt. Ltd. and it's partners.
Unauthorized copying of this file, via any medium is strictly prohibited.
*/
import { useState } from 'react';
import { Alert, Button, Container } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { Link } from 'react-router-dom';
import AsyncSelect from 'react-select/async';
import { UploadSheetExtension } from '../../globals/helper';
import useDocumentTitle from '../../globals/useDocumentTitle';
import {
  getInsDepartDrop,
  getInstituteDrop,
  getSamapleDoc,
  uploadSheets
} from '../../services/AuthServices';
import showNotification from '../../services/NotificationService';

export default function Import() {
  useDocumentTitle('Upload File | AlmaBay');
  const [importData, setImportData] = useState({
    sheet_type: '',
    Institute: '',
    Department: '',
    location_mode: 'city',
    upload_sheet: ''
  });
  const [sampleLink, setSampleLink] = useState('');
  const [instituteSearch, setInstituteSearch] = useState(null);
  const [showalert, setShowAlert] = useState(false);

  const handleChange = (e, defaultName) => {
    if (defaultName == 'Institute' || defaultName == 'Department') {
      setImportData({
        ...importData,
        [defaultName]: e
      });
    } else {
      const { name, value, type } = e.target;
      if (value !== '') {
        getSamapleDoc(value).then((res) => {
          if (res?.status == 200) {
            setSampleLink(res?.data?.path);
          }
        });
      }

      if (type == 'file') {
        let file = e.target.files[0];
        setImportData({
          ...importData,
          [name]: file
        });
      } else {
        setImportData({
          ...importData,
          [name]: value
        });
      }
    }
  };

  const downloadSample = (e) => {
    e.preventDefault();
    window.open(sampleLink);
  };

  const loadInstituteOptions = async (inputValue) => {
    return getInstituteDrop(inputValue).then((res) => {
      if (res?.status === 200) {
        let tempArr = [];
        if (res?.data?.length) {
          const data = res?.data;
          data?.map((data) => {
            tempArr.push({
              id: data?.institute_id,
              label: data?.institutename,
              value: data?.institutename
            });
          });
        }
        return tempArr;
      }
    });
  };
  const loadDepartmentOption = (inputValue) => {
    return getInsDepartDrop(inputValue).then((res) => {
      if (res?.status === 200) {
        let tempArr = [];
        if (res?.data?.length) {
          const data = res?.data;
          data?.map((data) => {
            tempArr.push({
              id: data?.institute_id,
              label: data?.institutename,
              value: data?.institutename
              // logo: DefaultLogo
            });
          });
        }
        return tempArr;
      }
    });
  };
  const validation = () => {
    // if (ind == index) {
    let errrObj = {};
    if (importData?.sheet_type == '') {
      errrObj.sheet_type = 'Please Select Sheet Type';
    }

    if (
      (importData?.sheet_type == 'department' || importData?.sheet_type == 'course') &&
      importData?.Institute == ''
    ) {
      errrObj.Institute = 'Please Select Institute';
    }

    if (importData?.sheet_type == 'course' && importData?.Department == '') {
      errrObj.Department = 'Please Select Department';
    }
    if (importData?.upload_sheet == '') {
      errrObj.upload_sheet = 'Please Upload Sheet';
    }
    if (!UploadSheetExtension.includes(importData?.upload_sheet?.type)) {
      errrObj.upload_sheet = 'Please upload only .xls,xlsx extention file';
    }
    return errrObj;
  };

  const handleSubmit = () => {
    setShowAlert(true);
    if (!Object.keys(validation()).length) {
      setShowAlert(false);
      let body = {
        sheet_type: importData?.sheet_type,
        Institute:
          importData?.sheet_type == 'department' || importData?.sheet_type == 'course'
            ? importData?.Institute?.id
            : '',
        Department: importData?.sheet_type == 'course' ? importData?.Department?.id : '',
        location_mode: importData?.sheet_type == 'locations' ? importData?.location_mode : '',
        upload_sheet: importData?.upload_sheet
      };

      let formData = new FormData();
      Object.keys(body).map((key) => {
        formData.append(key, body[key]);
      });

      uploadSheets(body).then((resp) => {
        if (resp?.status === 200) {
          setShowAlert(false);
          showNotification('success', resp?.data?.message);
          setImportData({
            sheet_type: '',
            Institute: '',
            Department: '',
            location_mode: 'city',
            upload_sheet: ''
          });
        }
      });
    }
  };

  return (
    <div className="dash-main-content">
      <Container fluid>
        <div className="dash-page-content">
          <div className="dash-page-title">
            <Row>
              <Col xs={12} sm={12} md={12}>
                <div className="dash-title-bar d-flex align-items-center justify-content-between">
                  <div className="title-lft d-flex align-items-center">
                    <div className="dash-icn">
                      <i className="fas fa-file-pdf"></i>
                    </div>
                    <div className="dash-title-dis">
                      <h5>Upload Sheet</h5>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <div className="dash-content-card">
            <Alert
              variant={'danger'}
              className={`${showalert && Object.keys(validation()).length !== 0 ? '' : 'd-none'}`}>
              {validation()?.sheet_type ||
                validation()?.upload_sheet ||
                validation()?.Institute ||
                validation()?.Department}
            </Alert>
            <div className="cmmn-form">
              <Form>
                <Row>
                  <div className="d-flex">
                    <Col md={4}>
                      <Form.Group className="mb-3" controlId="formBasicName">
                        <Form.Label>Select Type</Form.Label>
                        <Form.Select
                          aria-label="Default select example"
                          name="sheet_type"
                          onChange={handleChange}
                          value={importData?.sheet_type}>
                          <option value=""> - Select - </option>
                          <option value="users">Users</option>
                          <option value="user_verification">User Verification</option>
                          <option value="institute_user">Institute User</option>
                          <option value="jobs">Jobs</option>
                          <option value="institute">Institues</option>
                          <option value="department">Departments</option>
                          <option value="course">Courses</option>
                          <option value="locations">Locations</option>
                          <option value="">Products</option>
                          <option value="">Product Alternate Images</option>
                        </Form.Select>
                      </Form.Group>
                    </Col>
                    {importData?.sheet_type !== '' && (
                      <strong>
                        {' '}
                        <Link to="#" onClick={downloadSample}>
                          Download Sample Format
                        </Link>
                      </strong>
                    )}
                  </div>
                </Row>
                {(importData?.sheet_type == 'department' || importData?.sheet_type == 'course') && (
                  <Row>
                    <Col md={4}>
                      <Form.Group className="mb-3" controlId="formBasicName">
                        <Form.Label>Institue list</Form.Label>
                        <AsyncSelect
                          cacheOptions
                          className="basic-single"
                          classNamePrefix="select"
                          onMenuClose={() => setInstituteSearch(null)}
                          isSearchable={true}
                          isClearable={true}
                          name="Institute"
                          menuIsOpen={instituteSearch !== null ? true : false}
                          loadOptions={loadInstituteOptions}
                          placeholder="Select Institute"
                          onInputChange={(newValue) => setInstituteSearch(newValue)}
                          onChange={(e) => handleChange(e, 'Institute')}
                          value={importData?.Institute}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                )}
                {importData?.sheet_type == 'course' && (
                  <Row>
                    <Col md={4}>
                      <Form.Group className="mb-3" controlId="formBasicName">
                        <Form.Label>Department list</Form.Label>
                        <AsyncSelect
                          isSearchable={true}
                          name="Department"
                          isClearable={true}
                          placeholder="Select Department"
                          loadOptions={loadDepartmentOption}
                          onChange={(e) => handleChange(e, 'Department')}
                          value={importData?.Department}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                )}

                {importData?.sheet_type == 'locations' && (
                  <Row>
                    <Col md={4}>
                      <Form.Group className="mb-3" controlId="formBasicName">
                        <Form.Label>Location Type</Form.Label>
                        <Form.Select
                          aria-label="Default select example"
                          name="location_mode"
                          onChange={handleChange}
                          value={importData?.location_mode}>
                          <option value="city">City</option>
                          <option value="state">State</option>
                          <option value="country">Country</option>
                        </Form.Select>
                      </Form.Group>
                    </Col>
                  </Row>
                )}

                <Row>
                  <Col md={4}>
                    <Form.Group controlId="formFile" className="mb-3">
                      <Form.Label>Upload File </Form.Label>
                      <Form.Control type="file" onChange={handleChange} name="upload_sheet" />
                    </Form.Group>
                    (Only xls or xlsx File)
                  </Col>
                </Row>

                <div className="form-btns d-flex align-items-center mt-3">
                  <button type="button" className="green_button_admin" onClick={handleSubmit}>
                    Save
                  </button>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}
