/**
 * @copyright : ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
@author    : Shiv Charan Panjeta 
 
All Rights Reserved.
Proprietary and confidential :  All information contained here in is, and remains
the property of ToXSL Technologies Pvt. Ltd. and it's partners.
Unauthorized copying of this file, via any medium is strictly prohibited.
*/

import { useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { registerUsers } from '../../../services/AuthServices';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import {
  ALPHABET_REGEX,
  LENGTH_REGEX,
  NUMBER_REGEX,
  PASSWORD_VALID_REGEX
} from '../../../globals/constants';
import { loginInfo } from '../../../redux/action';
export default function RegisterCheckoutPage({ handleFormData, formData, setLoginMark }) {
  const [checkCred, setCheckCred] = useState(false);

  let navigate = useNavigate();

  const dispatch = useDispatch();
  const loginValidate = (values) => {
    let errors = {};

    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    const passwordStrength = /.*[A-z]/ && /.*\d/ && /.{8,}$/;
    if (values.first_name == '') {
      errors.first_name = 'Please enter your first name';
    }
    if (values.last_name == '') {
      errors.last_name = 'Please enter your last name';
    }
    if (values.email == '') {
      errors.email = 'Please enter your email';
    } else if (!regex.test(values.email)) {
      errors.email = 'Please enter valid email';
    }
    if (values.password == '') {
      errors.password = 'Please enter your password';
    } else if (!passwordStrength.test(values.password)) {
      errors.password = `Password doesn't match minimum requirements`;
    }

    return errors;
  };

  const submitFormData = (e) => {
    e.preventDefault();
    setCheckCred(true);
    if (
      !loginValidate(formData)?.first_name &&
      !loginValidate(formData)?.last_name &&
      !loginValidate(formData)?.email &&
      !loginValidate(formData)?.password
    ) {
      let body = {
        first_name: formData?.first_name,
        email: formData?.email,
        last_name: formData?.last_name,
        password: formData?.password
      };
      registerUsers(body).then((res) => {
        if (res?.status === 200) {
          setLoginMark(true);
          dispatch(loginInfo(res?.data?.data));
          localStorage.setItem('token', res?.data?.access_token);
          localStorage.setItem('userDetail', JSON.stringify(res?.data?.data));
          navigate(`/shop/catalog`);
        }
      });
    }
  };

  const rules = [
    {
      label: 'Must be minimum of 8 characters',
      pattern: LENGTH_REGEX
    },
    {
      label: 'Include a number',
      pattern: NUMBER_REGEX
    },
    {
      label: 'Include an alphabet',
      pattern: ALPHABET_REGEX
    }
  ];

  const popover = (
    <Popover id="popover-basic" className="register-popover">
      <Popover.Header as="h3">Password Criteria</Popover.Header>
      {rules?.map((passwordCriteria, index) => {
        const cn =
          formData?.password && formData?.password.match(passwordCriteria.pattern) ? 'true' : '';
        return (
          <Popover.Body className={cn} key={index}>
            {' '}
            <span className="left-icon">
              <i className="fas fa-check"></i>
            </span>{' '}
            {passwordCriteria?.label}
          </Popover.Body>
        );
      })}
    </Popover>
  );
  return (
    <div>
      <h3>Log In / Register</h3>
      <p>We will send order details to this email address</p>
      {/* <h5>Please enter your email address</h5> */}
      <Row>
        <Col md={6}>
          <Form.Group className="mb-4">
            <Form.Control
              type="text"
              placeholder="First Name"
              className="rounded-0"
              name="first_name"
              value={formData?.first_name}
              onChange={handleFormData('first_name')}
            />

            {checkCred && loginValidate(formData).first_name ? (
              <span
                className={
                  `${checkCred && loginValidate(formData).first_name}` ? 'login-error' : 'd-none'
                }>
                {checkCred && loginValidate(formData)?.first_name}
              </span>
            ) : (
              ''
            )}
          </Form.Group>
        </Col>

        <Col md={6}>
          <Form.Group className="mb-4">
            <Form.Control
              type="text"
              placeholder="Last Name"
              className="rounded-0"
              name="last_name"
              value={formData?.last_name}
              onChange={handleFormData('last_name')}
            />

            {checkCred && loginValidate(formData).last_name ? (
              <span
                className={
                  `${checkCred && loginValidate(formData).last_name}` ? 'login-error' : 'd-none'
                }>
                {checkCred && loginValidate(formData)?.last_name}
              </span>
            ) : (
              ''
            )}
          </Form.Group>
        </Col>

        <Col xs={12}>
          <Form.Group className="mb-4">
            <Form.Control
              type="text"
              placeholder="Email address"
              className="rounded-0"
              name="email"
              value={formData?.email}
              onChange={handleFormData('email')}
              autoComplete="off"
            />
            {checkCred && loginValidate(formData).email ? (
              <span
                className={
                  `${checkCred && loginValidate(formData).email}` ? 'login-error' : 'd-none'
                }>
                {checkCred && loginValidate(formData)?.email}
              </span>
            ) : (
              ''
            )}
          </Form.Group>
        </Col>
        <Col xs={12}>
          <OverlayTrigger trigger="click" placement="right" overlay={popover}>
            <Form.Group className="mb-4">
              <Form.Control
                type="password"
                placeholder="Password (8 or more characters)"
                className="rounded-0"
                name="password"
                value={formData?.password}
                onChange={handleFormData('password')}
                pattern={PASSWORD_VALID_REGEX.source}
              />
              {checkCred && loginValidate(formData).password ? (
                <span
                  className={
                    `${checkCred && loginValidate(formData).password}` ? 'login-error' : 'd-none'
                  }>
                  {checkCred && loginValidate(formData)?.password}
                </span>
              ) : (
                ''
              )}
            </Form.Group>
          </OverlayTrigger>
          <Button
            className="btn btn-primary btn-sm"
            name="checkoutSubmit"
            onClick={(e) => {
              submitFormData(e);
            }}>
            Continue
          </Button>
        </Col>
      </Row>
    </div>
  );
}
