import { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { getClubList } from '../../../services/InstituteServices';
import Loader from '../../commoncomponents/loader/loader-large';

function ClubPage() {
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    getList();
  }, []);

  const getList = () => {
    getClubList().then((res) => {
      setLoading(false)
      if (res?.status == 200) {
        setList(res?.data?.data);
      }
    });
  };

  return (
    <>
      <div className="wrappermain_outer bggreylight">
        <section
          className="breadcrum-banner"
          style={{
            backgroundImage: `url(${list?.banner?.url
              ? process.env.REACT_APP_API_URL + list?.banner?.url
              : '/static/media/breadcrum-banner-img.1a6b2ed71ebb8768883c.png'
              })`
          }}
        >
          <Container>
            <Row>
              <Col sm={12}>
                <h1 className="Text-white">{list?.banner?.name || 'Clubs'}</h1>
              </Col>
            </Row>
          </Container>
        </section>
        <section className="club-sec py-5">
          <Container>
            <div className="club-sec-inr">
              <Row>
                {list?.list && list?.list?.length ? (
                  list?.list?.map((data, index) => (
                    <Col lg={4} md={4} xs={12} key={index}>
                      <Link to={`/club-detail/${data?.encrypted_key}`}>
                        <div className="club-card">
                          <div className="club-img">
                            <img
                              src={
                                data?.cover_image
                                  ? process.env.REACT_APP_API_DOMAIN +
                                  'common/uploads/institute_logo/' +
                                  data?.cover_image
                                  : require('../../../assets/images/institute-img/club-1.png')
                              }
                              alt="club"
                            />
                            <span className="club-icn">
                              <img
                                src={
                                  data?.logo
                                    ? process.env.REACT_APP_API_DOMAIN +
                                    'common/uploads/institute_logo/' +
                                    data?.logo
                                    : require('../../../assets/images/institute-img/club-1.png')
                                }
                                alt="club"
                              />
                            </span>
                          </div>
                          <div className="club-disc">
                            {/* <p>1999-2000</p> */}
                            <h4>{data?.name}</h4>
                            <p className="close-club">
                              {data?.club_privacy == "open" ? "Open" : data?.club_privacy == "secret" ? "Secret" : "Closed"} Club{' '}
                              {data?.verified == "1" ? <span className="verified-icn ms-1">
                                <img
                                  src={require('../../../assets/images/institute-img/verfied.png')}
                                  alt="club"
                                />
                              </span> : <></>}
                            </p>
                          </div>
                        </div>
                      </Link>
                    </Col>
                  ))
                ) : (
                  <div className="text-center nodata">
                    <img src={require('../../../assets/images/nodata.png')} alt="no-data" />
                    <p>Data Not Found</p>
                  </div>
                )}
              </Row>
            </div>
          </Container>
        </section>
      </div>
      <div className="lgn-link py-4">
        <Container>
          <div className="logn-lnk-inr">
            <p>
              <Link to="/institute-register">Register</Link> / <Link to="/institute-login">Login</Link> to browse
              members by Year, Name, Company, Profession or Location
            </p>
          </div>
        </Container>
      </div>
      {loading ? <Loader /> : <></>}
    </>
  );
}

export default ClubPage;
