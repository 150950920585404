/** @copyright : ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
 @author     : Shiv Charan Panjeta 

 All Rights Reserved.
 Proprietary and confidential :  All information contained herein is, and remains
 the property of ToXSL Technologies Pvt. Ltd. and its partners.
 Unauthorized copying of this file, via any medium is strictly prohibited.
*/
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import ReactPlayer from 'react-player';
import { Link } from 'react-router-dom';
import { getGallery } from '../../../../services/InstituteServices';

function AllVideos() {
  const [list, setList] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    getGallery('video').then((res) => {
      if (res?.status === 200) {
        setList(res?.data?.data);
      }
    });
  };

  return (
    <>
      <Row>
        {list && list?.length ? (
          list?.map((data, index) => (
            <Col sm={6} md={4} key={index}>
              <Link
                to={`/videos-view/${data?.media_id}`}
                className="h-100 single-img-content text-center video-link">
                {data?.thumbnail ? (
                  <img
                    src={data?.thumbnail ? process.env.REACT_APP_API_DOMAIN + data?.thumbnail : ''}
                    alt="img"
                    width="100%"
                    className="mb-3 "
                  />
                ) : (
                  <ReactPlayer
                    height="250"
                    width="300"
                    url={data?.video_list[0]?.url || data?.video_list[0]?.url_link}
                    className="mb-3"
                  />
                )}
                {/* <img src={data?.thumbnail ? process.env.REACT_APP_API_DOMAIN + data?.thumbnail : ""} alt='img' width="100%" className='mb-3 ' /> */}

                {/* <img src={data?.thumbnail ? process.env.REACT_APP_API_DOMAIN + data?.thumbnail : ""} alt='img' width="100%" className='mb-3 ' /> */}
                <p className="mb-1">{data?.title}</p>
                <p className="text-muted mb-4">
                  Videos: <span className="text-black me-3">{data?.video_list?.length}</span> &nbsp;
                  Date:{' '}
                  <span className="text-black">{moment(data?.created).format('DD MMM YYYY')}</span>{' '}
                </p>
              </Link>
            </Col>
          ))
        ) : (
          <Col sm={12} md={4} lg={3}>
            <div className="text-center nodata">
              <img src={require('../../../../assets/images/nodata.png')} alt="" />
              <p>Data Not Found</p>
            </div>
          </Col>
        )}
      </Row>
    </>
  );
}

export default AllVideos;
