import { Carousel, Container } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import useDocumentTitle from '../../../globals/useDocumentTitle';
import FooterMain from '../../commoncomponents/FooterMain';
import HeaderInner from '../../commoncomponents/HeaderInner';
import HeaderLogin from '../../commoncomponents/HeaderLogin';
import OuterFindMentore from './OuterFindMentore';
import { subDomain } from '../../../globals/helper';

function MentorsDirectory() {
  if (subDomain()) {
    useDocumentTitle(subDomain());
  } else {
    useDocumentTitle(
      'Mentors Directory | AlmaBay'
    );
  }
  const navigate = useNavigate();

  const isloggedIn = useSelector((state) => state?.user?.isloggedIn);

  return (
    <>
      {!isloggedIn ? <HeaderInner /> : <HeaderLogin />}
      <section className="mentors-banner position-relative">
        <Container>
          <div className="pt-5">
            <Carousel>
              <Carousel.Item>
                <h2 className="text-center banner-heading">Chance to give back</h2>
                <h6 className="banner-title text-center text-white">
                  {' '}
                  Opportunities for achievers to give back to society.
                </h6>
                <p className="text-center describtion text-white">
                  If you believe that you can make meaningful contribution in someone’s life and
                  want to give back to your community and society.
                </p>
                <div className="applying text-white py-5">
                  <p className="text-center text-white">
                    If you have achieved tremendous success in your chosen career. We look forward
                    to have you on board and provide a platform where next generation can ---from
                    your experience and wisdom. We recognize and award best mentors. Come be part of
                    AlmaBay mentors society which boast of some of the prominent names in their
                    chosen fields.
                  </p>
                </div>
                <div className="signup-mentor-btn text-center mb-5">
                  <button className="btn btn-blue me-3">Sign up as a mentor</button>
                  <button className="btn btn-orange" onClick={() => navigate('/contact-us')}>
                    Call us to know more
                  </button>
                </div>
              </Carousel.Item>

              <Carousel.Item>
                <h2 className="text-center banner-heading">AlmaBay recognized mentors</h2>
                <h6 className="banner-title text-center text-white">
                  Get mentorship from achievers whose achievements have to be recognized and
                  verified by AlmaBay.
                </h6>
                <p className="text-center describtion text-white">
                  AlmaBay help you to reconnect with achievers who have a proven track record.{' '}
                </p>
                <div className="applying text-white py-5">
                  <p className="text-center text-white">
                    Team AlmaBay has contacted and listed some proven personalities who have been
                    recognized as the best and with track record of supporting and nurturing young
                    talent. We always put those mentors at the top of suggestion list to improve
                    your chances of finding the right mentors.
                  </p>
                </div>
              </Carousel.Item>

              <Carousel.Item>
                <h2 className="text-center banner-heading"> Find the Right Mentors</h2>
                <h6 className="banner-title text-center text-white">
                  AlmaBay helps you to get in touch with achievers and believers.
                </h6>
                <p className="text-center describtion text-white">
                  AlmaBay mentoring platform list some of the most prominent personalities of
                  (almost) every field.
                </p>
                <div className="applying text-white py-5">
                  <p className="text-center text-white">
                    It helps you to discover mentors based on area of expertise and geographical
                    location. Our advanced algorithm will help you identify mentors who is most
                    likely to get with you and believe in your potential.
                  </p>
                </div>

                <div className="signup-mentor-btn text-center mb-5">
                  <button className="btn btn-orange" onClick={() => navigate('/login')}>
                    Sign-up Now
                  </button>
                </div>
              </Carousel.Item>
            </Carousel>
          </div>
          <div className="intro-scroller">
            <Link to="#mobile">
              <img src={require('../../../assets/images/mouse.png')} alt="Down Arrow" />
            </Link>
          </div>
        </Container>
      </section>

      <section id="mobile" className="jobfair-infomation py-5">
        <Container>
          <Row>
            <Col md={12}>
              <h2 className="text-center mx-auto mb-5">
                We will meet you where you are and take you where you want to go Faster.
              </h2>
            </Col>
            <Col sm={4}>
              <div className="text-center">
                <img
                  src={require('../../../assets/images/mentorsdirectory-listview-1.png')}
                  className="rounded-circle"
                  alt="img"
                />
                <p>Get the guidance from the best in their chosen fields.</p>
              </div>
            </Col>
            <Col sm={4}>
              <div className="text-center">
                <img
                  src={require('../../../assets/images/mentorsdirectory-listview-2.png')}
                  className="rounded-circle"
                  alt="img"
                />
                <p>
                  Build a network with established professionals while at the start of your career.
                </p>
              </div>
            </Col>
            <Col sm={4}>
              <div className="text-center">
                <img
                  src={require('../../../assets/images/mentorsdirectory-listview-3.png')}
                  className="rounded-circle"
                  alt="img"
                />
                <p>
                  The registration process will take you less than 5 minutes & you will be granted
                  full access to the virtual fair.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      {/* 
      <FindMentors /> */}
      <OuterFindMentore />

      <FooterMain className={'colour-bg'} />
    </>
  );
}

export default MentorsDirectory;
