/**
 * @copyright : ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
@author    : Shiv Charan Panjeta 
 
All Rights Reserved.
Proprietary and confidential :  All information contained here in is, and remains
the property of ToXSL Technologies Pvt. Ltd. and it's partners.
Unauthorized copying of this file, via any medium is strictly prohibited.
*/

import moment from 'moment';
import { useEffect, useState } from 'react';
import { Button, Card, Col, Container, Row } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import BlogBackground from '../../../assets/images/blog_background.png';
import instituteBanner from '../../../assets/images/institute-landing-banner.png';
import { ABOUT } from '../../../globals/constants';
import { truncate } from '../../../globals/helper';
import {
  checkDomain,
  getAluminiBackground,
  getBlogDetail,
  getHomepageLayout,
  getInsEventList,
  getInsNews,
  getJobList,
  getPageDetail,
  getTestimonial,
  quickLinks
} from '../../../services/InstituteServices';

function InstituteLandingTwo() {
  /**
   * internal css
   */
  var blogSectionBackground = {
    backgroundImage: `URL(${BlogBackground})`,
    padding: `50px 0`,
    backgroundRepeate: `no-repeate`,
    backgroundSize: `cover`
  };
  /**end */
  const [state, setState] = useState();
  const [list, setList] = useState([]);
  const [quickLinksList, setQuickLinksList] = useState([]);
  const [blogList, setBlogList] = useState([]);
  const [newsList, setNewsList] = useState([]);
  const [eventList, setEventList] = useState([]);
  const [testimonialList, setTestimonialList] = useState([]);
  const [layoutDetail, setLayoutDetail] = useState('');
  const [about, setAbout] = useState('');
  const [aluminiImg, setAluminiImg] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    checkApi();
    getInfo();
    getAluminiImages();
  }, []);

  const checkApi = () => {
    checkDomain().then((res) => {
      if (res?.status === 200) {
        setState(res?.data?.details);
      }
    });

    getHomepageLayout().then((res) => {
      if (res?.status == 200) {
        setLayoutDetail(res?.data?.data);
      }
    });
  };

  /**
   * get all info related to homepage layout
   */
  const getInfo = () => {
    getPageDetail(ABOUT).then((res) => {
      if (res?.status == 200) {
        let data = res?.data?.details ? res?.data?.details[0] : [];
        setAbout(data);
      }
    });

    getJobList().then((res) => {
      if (res?.status == 200) {
        setList(res?.data?.data?.list || []);
      }
    });

    getBlogDetail().then((res) => {
      if (res?.status == 200) {
        setBlogList(res?.data?.data?.details);
      }
    });

    getInsNews().then((res) => {
      if (res?.status == 200) {
        setNewsList(res?.data?.data);
      }
    });

    getInsEventList().then((res) => {
      if (res?.status == 200) {
        setEventList(res?.data?.data?.list);
      }
    });

    getTestimonial().then((res) => {
      if (res?.status == 200) {
        setTestimonialList(res?.data?.data);
      }
    });

    quickLinks().then((res) => {
      if (res?.status == 200) {
        setQuickLinksList(res?.data?.data);
      }
    });
  };

  /**
   * get alumini images for background coverImg
   */
  const getAluminiImages = () => {
    getAluminiBackground().then((res) => {
      if (res?.status == 200) {
        let arr = [];
        let data = res?.data?.data;
        if (res?.data?.data?.length) {
          data?.map((data) => {
            arr.push(process.env.REACT_APP_API_DOMAIN + data?.url + '.' + data?.extension);
          });
          setAluminiImg(arr);
        }
      }
    });
  };

  return (
    <>
      <section className="institue-banner-landing">
        <Swiper pagination={true} modules={[Pagination]} className="mySwiper">
          <SwiperSlide>
            <Col sm={12}>
              <div className="banner-img">
                <img
                  src={
                    layoutDetail?.slider_banner_img
                      ? process.env.REACT_APP_API_URL + layoutDetail?.slider_banner_img
                      : instituteBanner
                  }
                  alt=""
                />
              </div>
              <Row className="jusitfy-content-center">
                <Col lg={6} xs={12} sm={12} md={10}>
                  <div className="banner-content text-center">
                    <h1>{layoutDetail?.title || 'PERFECT PLACE TO CONNECT WITH BATCHMATES!'}</h1>
                    {layoutDetail?.sub_title ? (
                      <span dangerouslySetInnerHTML={{ __html: layoutDetail?.sub_title }} />
                    ) : (
                      'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.'
                    )}
                    {/* <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p> */}
                  </div>
                </Col>
              </Row>
            </Col>
          </SwiperSlide>
        </Swiper>
      </section>
      {about && about?.description && (
        <section className="institution_welcome">
          <Container>
            <Row>
              <Col lg={5}>
                <img
                  src={
                    about?.banner_file
                      ? process.env.REACT_APP_API_URL + about?.banner_file
                      : require('../../../assets/images/harikrishan_school.png')
                  }
                  height="440px"
                  width="440px"
                  alt="img"
                />
              </Col>
              <Col lg={7}>
                <div className="institution_welcome_content">
                  <h5>Welcome to</h5>
                  <h3>{state?.name}</h3>
                  <span dangerouslySetInnerHTML={{ __html: truncate(about?.description, 600) }} />
                  <Button
                    onClick={() => {
                      navigate('/about-institute');
                    }}
                    className="btn p-2">
                    Explore
                  </Button>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      )}

      <section className={`our_alumni_section `}>
        <Container fluid className="p-0">
          <div className="our_alumni_content text-center">
            <p className="mb-0">Our</p>
            <h3>Alumni</h3>
            <Button
              className="btn py-2 px-3"
              onClick={() => {
                navigate('/alumni');
              }}>
              See All &nbsp;
              <svg
                width="12"
                height="13"
                viewBox="0 0 12 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M5.62886 1.05806C5.87293 0.813981 6.26863 0.813981 6.51272 1.05806L11.5127 6.05808C11.7568 6.30216 11.7568 6.69783 11.5127 6.94191L6.51272 11.9419C6.26863 12.186 5.87293 12.186 5.62886 11.9419C5.38478 11.6978 5.38478 11.3022 5.62886 11.0581L10.1869 6.5L5.62886 1.94194C5.38478 1.69786 5.38478 1.30213 5.62886 1.05806Z"
                  fill="#6F6F6F"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M0.628857 1.05806C0.872932 0.813981 1.26863 0.813981 1.51272 1.05806L6.51271 6.05808C6.7568 6.30216 6.7568 6.69783 6.51271 6.94191L1.51272 11.9419C1.26863 12.186 0.872932 12.186 0.628857 11.9419C0.384782 11.6978 0.384782 11.3022 0.628857 11.0581L5.18688 6.5L0.628857 1.94194C0.384782 1.69786 0.384782 1.30213 0.628857 1.05806Z"
                  fill="#6F6F6F"
                />
              </svg>
            </Button>
          </div>
          <ul className="alumni-list d-flex align-items-center flex-wrap">
            {aluminiImg && aluminiImg?.length ? (
              aluminiImg?.slice(0, 22)?.map((data, index) => (
                <li key={index}>
                  <img src={data} alt="img" />
                </li>
              ))
            ) : (
              <>
                <li>
                  <img
                    src={require('../../../assets/images/institute-img/alumni-1.png')}
                    alt="img"
                  />
                </li>
                <li>
                  <img
                    src={require('../../../assets/images/institute-img/alumni-2.png')}
                    alt="img"
                  />
                </li>
                <li>
                  <img
                    src={require('../../../assets/images/institute-img/alumni-3.png')}
                    alt="img"
                  />
                </li>
                <li>
                  <img
                    src={require('../../../assets/images/institute-img/alumni-4.png')}
                    alt="img"
                  />
                </li>
                <li>
                  <img
                    src={require('../../../assets/images/institute-img/alumni-5.png')}
                    alt="img"
                  />
                </li>
                <li>
                  <img
                    src={require('../../../assets/images/institute-img/alumni-6.png')}
                    alt="img"
                  />
                </li>
                <li>
                  <img
                    src={require('../../../assets/images/institute-img/alumni-7.png')}
                    alt="img"
                  />
                </li>
                <li>
                  <img
                    src={require('../../../assets/images/institute-img/alumni-8.png')}
                    alt="img"
                  />
                </li>
                <li>
                  <img
                    src={require('../../../assets/images/institute-img/alumni-9.png')}
                    alt="img"
                  />
                </li>

                <li>
                  <img
                    src={require('../../../assets/images/institute-img/alumni-10.png')}
                    alt="img"
                  />
                </li>
                <li>
                  <img
                    src={require('../../../assets/images/institute-img/alumni-11.png')}
                    alt="img"
                  />
                </li>
                <li>
                  <img
                    src={require('../../../assets/images/institute-img/alumni-12.png')}
                    alt="img"
                  />
                </li>
                <li>
                  <img
                    src={require('../../../assets/images/institute-img/alumni-13.png')}
                    alt="img"
                  />
                </li>
                <li>
                  <img
                    src={require('../../../assets/images/institute-img/alumni-1.png')}
                    alt="img"
                  />
                </li>
                <li>
                  <img
                    src={require('../../../assets/images/institute-img/alumni-2.png')}
                    alt="img"
                  />
                </li>
                <li>
                  <img
                    src={require('../../../assets/images/institute-img/alumni-3.png')}
                    alt="img"
                  />
                </li>
              </>
            )}
          </ul>
        </Container>
      </section>

      <section className="news-update-section position-relative p-0 bg-white">
        <Container fluid>
          <Row>
            <Col lg={7} md={6} sm={12}>
              <div className="news p-5">
                <div className="d-flex align-items-center justify-content-between mb-3">
                  <h3 className="main-title">News & Updates</h3>
                  <Link to="/news">
                    See All <i className="fas fa-angle-double-right ms-2"></i>{' '}
                  </Link>
                </div>

                {newsList?.list && newsList?.list?.length ? (
                  newsList?.list?.slice(0, 5)?.map((data, index) => (
                    <Card
                      key={index}
                      className={`${
                        index == 0 ? 'mt-4' : ''
                      } py-2 bg-transparent border-bottom pointer`}
                      role="presentation"
                      onClick={() => {
                        navigate(`/news-detail-page/${data?.slug}`);
                      }}>
                      <Card.Body>
                        <h3 className="subtitle">
                          {moment(data?.date_modified).format('MMM DD,YYYY')}
                        </h3>
                        <h4>{data?.title}</h4>
                      </Card.Body>
                    </Card>
                  ))
                ) : (
                  <Card className="mt-4 py-2 bg-transparent border-bottom">
                    <Card.Body>
                      <h4>No Data Found at this moment</h4>
                    </Card.Body>
                  </Card>
                )}
              </div>
            </Col>
            <Col lg={5} md={6} sm={12} className="pe-lg-0">
              <div className="position-relative quick-link-box">
                <div className="quick-links">
                  <div className="d-flex align-items-center justify-content-between mb-3">
                    <h3>Quick Links</h3>
                    {/* <Link>
                      See All <i className="fas fa-angle-double-right ms-2"></i>{' '}
                    </Link> */}
                  </div>
                  <ul className="quick-link-list">
                    {quickLinksList?.length > 0 &&
                      quickLinksList?.map((list, index) => {
                        return (
                          <li key={index}>
                            <Link to={list?.slug?.includes('home') ? list?.slug : '/' + list?.slug}>
                              <i className="fas fa-chevron-right me-3"></i>
                              {list?.name}
                            </Link>
                          </li>
                        );
                      })}
                  </ul>
                </div>
                <div className="quick-link-bottom">
                  <div className="d-flex align-items-center">
                    {state?.logo && (
                      // <Link to="/">
                      <img
                        src={
                          process.env.REACT_APP_API_DOMAIN +
                          'common/uploads/institute_logo/' +
                          state?.logo
                        }
                        alt="logo"
                        width={'100px'}
                      />
                      // </Link>
                    )}
                    <h3 className="institue-title">
                      {state?.name}
                      {/* Sri Guru Harkrishan Senior <br /> Secondary Public School */}
                    </h3>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="blog-section" style={blogSectionBackground}>
        <Container>
          <div className="d-flex align-items-center justify-content-between mb-3">
            <h2 className="text-white">Our Blogs</h2>
            <Link to={'/blog'} className="text-white">
              See All <i className="fas fa-angle-double-right ms-2"></i>{' '}
            </Link>
          </div>
          <Row>
            {blogList && blogList?.length ? (
              blogList?.slice(0, 4)?.map((data, index) => (
                <Col sm={12} md={4} lg={3} key={index}>
                  <Card className="mb-3 mb-lg-0">
                    {/* <img src={require('../../../assets/images/institute-blog-img.png')} alt="img" /> */}
                    <img
                      src={
                        data?.image
                          ? process.env.REACT_APP_API_DOMAIN + 'common/uploads/blog/' + data?.image
                          : require('../../../assets/images/institute-blog-img.png')
                      }
                      alt="img"
                    />
                    <Card.Body>
                      <h3 className="subtitle">
                        {data?.created_on ? moment(data?.created_on).format('MMM DD,YYYY') : ''}
                      </h3>
                      <h4 className="card-title">{data?.title}</h4>
                      <span
                        className="card-text"
                        dangerouslySetInnerHTML={{ __html: truncate(data?.description, 100) }}
                      />
                      <Link to={`/blog-details/${data?.slug}`} className="btn explore mt-0">
                        Explore
                      </Link>
                    </Card.Body>
                  </Card>
                </Col>
              ))
            ) : (
              <Col sm={12} md={4} lg={3}>
                <div className="text-center nodata text-white">
                  <img src={require('../../../assets/images/nodata.png')} alt="nodata" />
                  <p>Data Not Found</p>
                </div>
              </Col>
            )}
          </Row>
        </Container>
      </section>

      <section className="events-section p-0 bg-white">
        <Container fluid className="px-0">
          <Row>
            <Col sm={12} md={6} lg={6} className="pe-sm-0">
              <div className="events-box rounded-0">
                <div className="d-flex align-items-center justify-content-between mb-3">
                  <h2>Events</h2>
                  <Link to="/event-institute">
                    See All <i className="fas fa-angle-double-right ms-2"></i>{' '}
                  </Link>
                </div>
                <ul className="events-list">
                  {eventList && eventList?.length ? (
                    eventList?.slice(0, 5)?.map((data, index) => (
                      <li key={index}>
                        <div
                          className="d-flex align-items-center mb-4 pointer"
                          role="presentation"
                          onClick={() => {
                            navigate(`/event-detail-institute/${data?.slug}`);
                          }}>
                          <div className="event-date-box">
                            <h4 className="month">{moment(data?.from_event_date).format('MMM')}</h4>
                            <p className="date">{moment(data?.from_event_date).format('DD')}</p>
                          </div>
                          <div className="event-name-box">
                            <h3>{data?.name}</h3>
                            <p>{data?.venue}</p>
                          </div>
                        </div>
                      </li>
                    ))
                  ) : (
                    <li>Data Not Found</li>
                  )}
                </ul>
              </div>
            </Col>
            <Col sm={12} md={6} lg={6}>
              <div className="news-update-section p-0">
                <div className="news p-5">
                  <div className="d-flex align-items-center justify-content-between mb-3">
                    <h3 className="main-title">Job Board</h3>
                    {list?.length > 0 && (
                      <Link to="/jobs-board">
                        See All <i className="fas fa-angle-double-right ms-2"></i>{' '}
                      </Link>
                    )}
                  </div>
                  {list && list?.length ? (
                    list?.slice(0, 5)?.map((data, index) => (
                      <Card className="mt-4 py-2 bg-transparent border-bottom" key={index}>
                        <h3 className="subtitle">{data?.job_title}</h3>
                        <h4>{data?.companyName}</h4>
                      </Card>
                    ))
                  ) : (
                    <div className="text-center nodata">
                      <img src={require('../../../assets/images/nodata.png')} alt="nodata" />
                      <p>Data Not Found</p>
                    </div>
                  )}
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="testimonial-section p-0">
        <Container fluid className="px-0">
          <Row>
            <Col sm={12} lg={6}>
              <div className="p-3 p-lg-5 pe-lg-0">
                <h4 className="testimonials-title">Few Words from our Alumni</h4>
                <p>
                  Alumni Association has been an exceptional effort by the Sri Guru Harkrishan
                  Senior Secondary Public School to provide a lifelong link for each one of us to
                  stay connected. Through various activities and events, it commits to providing an
                  out-of-the-world experience for everyone.
                </p>

                <div className="inst-testi-slider">
                  <Swiper
                    spaceBetween={50}
                    slidesPerView={1}
                    modules={[Navigation]}
                    navigation={true}>
                    {testimonialList && testimonialList?.length ? (
                      testimonialList?.map((data, index) => (
                        <SwiperSlide key={index}>
                          <div className="testimonial-cont-box">
                            <div className="mb-4">
                              {/*  <img src={require('../../../assets/images/testimonial-slider-img1.png')} alt="img" /> */}
                              <img
                                src={
                                  data?.image_file
                                    ? process.env.REACT_APP_API_URL +
                                      'common/uploads/institute_banner/' +
                                      data?.image_file
                                    : require('../../../assets/images/testimonial-slider-img1.png')
                                }
                                alt="img"
                              />
                              <h2 className="client-name">{data?.name}</h2>
                              <p className="client-ocupation">{data?.designation}</p>
                            </div>
                            <p className="client-feedback mb-4">{data?.description}</p>
                          </div>
                        </SwiperSlide>
                      ))
                    ) : (
                      <>No Data Found</>
                    )}
                  </Swiper>
                </div>
              </div>
            </Col>
            <Col sm={12} lg={6} className="ps-sm-0">
              <div className="testimonial-img py-5 position-relative h-100 w-100">
                <div className="cont-box">
                  <h3>Gallery</h3>
                  <Link to="/gallery" className="btn see-all mt-0">
                    Explore <i className="fas fa-angle-double-right ms-2"></i>{' '}
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}

export default InstituteLandingTwo;
