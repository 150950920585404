import { useState } from 'react';
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import MultiAsynSelect from '../MultiSelectWithAsyn/MultiAsynSelect';
import * as APISERVICES from '../../../services/MentorArticlesServices';
import * as APISERVICE from '../../../services/ProfileServices';
import showNotification from '../../../services/NotificationService';
import { useSelector } from 'react-redux';
import { checkLogin } from '../../../services/AuthServices';
import { useDispatch } from 'react-redux';
import { loginInfo } from '../../../redux/action';

export default function MemberShipPOPUP({ handleClosePopUp }) {
  const userDetail = useSelector((state) => state?.user?.details);
  const dispatch = useDispatch();

  const [showProvide, setshowProvide] = useState(true);
  const [seekMentorShip, setSeekMentorShip] = useState([]);
  const [provideMentorShip, setproviodeMentorShip] = useState([]);

  const loadSeekMentoOptions = (inputValue) => {
    return APISERVICES.getTopicList(inputValue).then((res) => {
      if (res?.status === 200) {
        let tempArr = [];
        if (res?.data?.data) {
          const data = res?.data?.data;
          data?.map((data) => {
            tempArr.push({
              id: data?.id,
              label: data?.name,
              value: data?.name
            });
          });
        }
        return tempArr;
      }
    });
  };

  const mentorshipUpdateExpertise = (e) => {
    e.preventDefault();
    let arr = [];
    seekMentorShip?.length &&
      seekMentorShip?.map((data) => {
        arr.push(data?.id);
      });
    APISERVICE.postMentorshipExpertise({ provide_mentorship_in: arr.join() }).then((res) => {
      if (res?.status === 200) {
        showNotification('success', res?.data?.message);
        checkUserToken();
        setshowProvide(true);
        setSeekMentorShip([]);
      }
    });
  };

  const mentorshipUpdateKeenLearn = (e) => {
    e.preventDefault();
    let arr1 = [];
    provideMentorShip?.length &&
      provideMentorShip?.map((data) => {
        arr1.push(data?.id);
      });
    APISERVICE.postMentorshipKeenToLearn({ seek_mentorship_in: arr1.join() }).then((res) => {
      if (res?.status === 200) {
        showNotification('success', res?.data?.message);
        checkUserToken();
        handleSkip();
        setproviodeMentorShip([]);
      }
    });
  };
  const checkUserToken = () => {
    checkLogin().then((res) => {
      if (res?.status === 200) {
        //save data on redux
        dispatch(loginInfo(res?.data?.data));
        localStorage.setItem('userDetail', JSON.stringify(res.data?.data));
      }
    });
  };

  const handleSkip = () => {
    setshowProvide(false);
  };

  return (
    <>
      {userDetail?.provide_mentorship && userDetail?.seek_mentorship ? (
        <></>
      ) : (
        <>
          {' '}
          {(userDetail?.provide_mentorship && !userDetail?.seek_mentorship) || showProvide ? (
            <div className="membership-card p-4 mb-4">
              <h4>Add mentorship areas where you provide mentorship</h4>
              <p>This helps mentees find you as mentor</p>
              <Link className="close-btn" to="" onClick={(e) => handleClosePopUp(e)}>
                <i className="fas fa-times"></i>
              </Link>
              <div className="d-flex align-items-center flex-wrap ">
                <MultiAsynSelect
                  isMulti={true}
                  value={provideMentorShip}
                  setValue={setproviodeMentorShip}
                  loadOptions={loadSeekMentoOptions}
                  placeholder={`Add areas where you provide mentorship`}
                  className="mt-4"
                />
                <div className="mentorship-btn d-flex align-items-center mt-4">
                  <Button
                    variant="primary"
                    className="ms-2 save-btn"
                    onClick={(e) => mentorshipUpdateKeenLearn(e)}>
                    SAVE
                  </Button>
                  <Button variant="outline-primary" className="ms-2 skip-btn" onClick={handleSkip}>
                    SKIP
                  </Button>
                </div>
              </div>
            </div>
          ) : (
            <div className="membership-card p-4 mb-4">
              <h4>Add mentorship areas where you need mentors</h4>
              <p>Adding mentorship areas help find best mentors for you</p>
              <Link className="close-btn" to="" onClick={(e) => handleClosePopUp(e)}>
                <i className="fas fa-times"></i>
              </Link>
              <div className="d-flex align-items-center flex-wrap">
                <div className="mt-4">
                  <MultiAsynSelect
                    isMulti={true}
                    value={seekMentorShip}
                    setValue={setSeekMentorShip}
                    loadOptions={loadSeekMentoOptions}
                    placeholder={`Add areas where you seek mentorship`}
                  />
                </div>
                <div className="mentorship-btn d-flex align-items-center mt-4">
                  <Button
                    variant="primary"
                    className="ms-2 save-btn"
                    type="button"
                    onClick={(e) => mentorshipUpdateExpertise(e)}>
                    SAVE
                  </Button>
                  <Button
                    variant="outline-primary"
                    className="ms-2 skip-btn"
                    type="button"
                    onClick={(e) => handleClosePopUp(e)}>
                    SKIP
                  </Button>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
}
