/**
@copyright  :   ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
@author     :   Shiv Charan Panjeta 
 
All Rights Reserved.
Proprietary and confidential :  All information contained here in is, and remains
the property of ToXSL Technologies Pvt. Ltd. and it's partners.
Unauthorized copying of this file, via any medium is strictly prohibited.
*/
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { getJobCategoriesDropdown } from '../../../services/UserServices';

function JobCategoryList(props) {
  const { callApi, handleResetPageNo, state } = props;
  // let [searchParams, setSearchParams] = useSearchParams();
  const [category, setCategory] = useState([]);
  useEffect(() => {
    getJobCategory();
  }, []);

  const getJobCategory = () => {
    getJobCategoriesDropdown().then((res) => {
      if (res?.status === 200) {
        setCategory(res?.data?.data);
      }
    });
  };

  return (
    <div className="job-category p-2">
      <div className="job-cat-hd">
        <h4>
          <i className="fas fa-list me-2"></i>Job Categories
        </h4>
      </div>
      <div className="job-cat-list">
        <ul>
          {category &&
            category?.map((data, index) =>
              data?.totalJobs !== 0 ? (
                <li key={index}>
                  <Link
                    to={{ pathname: `/jobs/search/category/${data?.id}`, state: state ? state : 4 }}
                    target={'_blank'}
                    rel="noopener noreferrer"
                    onClick={(e) => {
                      e.preventDefault();
                      callApi(data?.id, '', true);
                      window.open(`/jobs/search/category/${data?.id}`, {
                        state: state ? state : 4
                      });
                      handleResetPageNo && handleResetPageNo();
                    }}>
                    {data?.name}
                  </Link>
                  <span>{data?.totalJobs}</span>
                </li>
              ) : (
                ''
              )
            )}
        </ul>
      </div>
    </div>
  );
}

export default JobCategoryList;
