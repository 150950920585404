import { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { getAlumni } from '../../../services/InstituteServices';
import Loader from '../../commoncomponents/loader/loader-large';

function Alumni() {
  const [alumni, setAlumni] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getInfo();
  }, []);

  const getInfo = () => {
    getAlumni().then((res) => {
      setLoading(false)
      if (res?.status == 200) {
        setAlumni(res?.data?.result?.list);
      }
    });
  };

  return (
    <>
      <div className="wrappermain_outer">
        <section className="breadcrum-banner">
          <Container>
            <Row>
              <Col sm={12}>
                <h1 className="Text-white">Alumni</h1>
              </Col>
            </Row>
          </Container>
        </section>

        <section className="alumni-detail">
          <Container>
            <Row>
              {alumni && alumni?.length ? (
                alumni?.map((data, index) => (
                  <Col sm={12} md={4} lg={3} key={index}>
                    <div className="p-2">
                      <div className="single-detail-box text-center">
                        <img
                          // src={require('../../../assets/images/alumnis-img.png')}
                          src={
                            data?.url && data?.extension
                              ? process.env.REACT_APP_API_DOMAIN +
                              data?.url + "." + data?.extension : data?.url && !data?.extension
                                ? process.env.REACT_APP_API_DOMAIN +
                                data?.url
                                : require('../../../assets/images/alumnis-img.png')
                          }
                          alt="icon"
                          width="100px"
                          className="mb-3"
                        />
                        <h4 className="mb-1">{data?.to_year}</h4>
                        <h6 className="mb-1">{data?.name}</h6>
                        <p className="mb-0">{data?.designation || ''}</p>
                      </div>
                    </div>
                  </Col>
                ))
              ) : (
                <div className={loading ? "d-none" : "text-center nodata"}>
                  <img src={require('../../../assets/images/nodata.png')} alt="no_data" />
                  <p>Data Not Found</p>
                </div>
              )}
            </Row>
          </Container>
        </section>
      </div>
      <div className="lgn-link py-4">
        <Container>
          <div className="logn-lnk-inr">
            <p className="text-center py-5">
              <Link to="institute-register">Register</Link>/ <Link to="/institute-login">Login</Link> to browse members by Year, Name,
              Company, Profession or Location
            </p>
          </div>
        </Container>
      </div>
      {loading ? <Loader /> : <></>}
    </>
  );
}

export default Alumni;
