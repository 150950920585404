/**
@copyright  :   ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
@author     :   Shiv Charan Panjeta 
 
All Rights Reserved.
Proprietary and confidential :  All information contained here in is, and remains
the property of ToXSL Technologies Pvt. Ltd. and it's partners.
Unauthorized copying of this file, via any medium is strictly prohibited.
*/
import { useEffect, useState } from 'react';
import { Button, Container } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { useLocation, useNavigate } from 'react-router-dom';
import AsyncSelect from 'react-select/async';
import CKEditorComponent from '../../components/webpages/user-pages/PostComponets/CKEditorComponent';
import { getCTCDropDown, getOpeningDropDown } from '../../globals/helper';
import useDocumentTitle from '../../globals/useDocumentTitle';
import {
  addJobInFest,
  getFestJobDetail,
  getJobFestDrop,
  getJobFestLocationDropDown,
  updateJobInFest
} from '../../services/AuthServices';
import showNotification from '../../services/NotificationService';
import { loadAllInstituteOptions, loadJobCategoryOption } from '../AdminHelper';
import { ZERO } from '../../globals/constants';

export default function AddJob() {
  useDocumentTitle('Add New Job | AlmaBay');
  const components = {
    DropdownIndicator: null
  };
  const [jobfest, setJobFest] = useState('');
  const [jobDescription, setJobDescription] = useState('');
  const [openingData, setOpeningData] = useState([]);
  const [remainingJobDetail, setRemainingJobDetail] = useState({
    title: '',
    openPosition: '',
    minWorkExp: '',
    maxWorkExp: '',
    minCTC: '',
    maxCTC: '',
    hrEmail: '',
    contactNumber: ''
  });
  const [clicked, setClicked] = useState(false);
  const [jobCategory, setJobCategory] = useState('');
  const [hiringSearch, setHiringSearch] = useState('');
  const [locationSearch, setLocationSearch] = useState('');
  const [categorySearch, setCategorySearch] = useState('');
  const [jobLocation, setJobLocation] = useState('');
  const [hiringFor, setHiringFor] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  let id = location?.state;

  useEffect(() => {
    getOpeningDropDown(setOpeningData);

    if (id) {
      getDetail(id);
    }
  }, []);

  const getDetail = (id) => {
    getFestJobDetail(id).then((res) => {
      if (res?.status === 200) {
        const data = res?.data?.data;
        setRemainingJobDetail({
          title: data?.job_title,
          openPosition: data?.open_positions,
          minWorkExp: data?.min_exp,
          maxWorkExp: data?.max_exp,
          minCTC: data?.min_ctc,
          maxCTC: data?.max_ctc,
          hrEmail: data?.hr_email,
          contactNumber: data?.contact_number
        });
        setJobDescription(data?.job_info);
        setJobCategory({
          id: data?.job_category,
          label: data?.Job_Category_Name,
          value: data?.Job_Category_Name
        });

        setHiringFor({
          id: data?.company_id,
          label: data?.company_name,
          value: data?.company_name
        });
        setJobFest({
          id: data?.fest_id,
          label: data?.title,
          value: data?.title
        });
        setJobLocation({
          id: data?.location,
          label: data?.city,
          value: data?.city
        });
      }
    });
  };

  const loadJobFestOption = (inputValue) => {
    return getJobFestDrop(inputValue).then((res) => {
      if (res?.status === 200) {
        let tempArr = [];
        if (res?.data?.data) {
          let data = res?.data?.data;
          data?.map((data) => {
            tempArr.push({
              id: data?.id,
              label: data?.title,
              value: data?.title
            });
          });
        }
        return tempArr;
      }
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setRemainingJobDetail({
      ...remainingJobDetail,
      [name]: value
    });
  };

  const validate = () => {
    let errors = {};
    if (jobfest == '') {
      errors.jobfest = 'Please select job fest';
    }
    if (remainingJobDetail?.title == '') {
      errors.title = 'Please enter job title';
    }

    if (remainingJobDetail?.minWorkExp == '') {
      errors.minWorkExp = 'Please enter minimum experience';
    }
    if (remainingJobDetail?.maxWorkExp == '') {
      errors.maxWorkExp = 'Please enter maximum experience';
    }
    if (remainingJobDetail?.maxWorkExp <= remainingJobDetail?.minWorkExp) {
      errors.maxWorkExp = 'max exp should be greater than min exp';
    }
    if (remainingJobDetail?.minCTC == '') {
      errors.minCTC = 'Please enter minimum CTC';
    }
    if (remainingJobDetail?.maxCTC == '') {
      errors.maxCTC = 'Please enter maximum CTC';
    }
    if (remainingJobDetail?.maxCTC <= remainingJobDetail?.minCTC) {
      errors.maxCTC = 'max CTC should be greater than min CTC';
    }
    if (hiringFor == '') {
      errors.company = 'Please enter company hiring for';
    }
    // if (jobLocation == '') {
    //   errors.jobLoc = 'Please select location';
    // }
    return errors;
  };

  const handleSubmit = () => {
    setClicked(true);
    if (!Object.keys(validate()).length) {
      let body = {
        job_fest: jobfest?.id,
        job_title: remainingJobDetail?.title,
        company_id: hiringFor?.id,
        job_desc: jobDescription,
        job_cat: jobCategory?.id,
        positions: remainingJobDetail?.openPosition,
        min_exp: remainingJobDetail?.minWorkExp,
        max_exp: remainingJobDetail?.maxWorkExp,
        min_ctc: remainingJobDetail?.minCTC,
        max_ctc: remainingJobDetail?.maxCTC,
        company_name: hiringFor?.value,
        location: jobLocation?.id || '',
        hr_email: remainingJobDetail?.hrEmail,
        contact_num: remainingJobDetail?.contactNumber
      };

      let formData = new FormData();
      for (let i in body) {
        formData.append(`${i}`, body[i]);
      }
      if (id) {
        updateJobInFest(id, formData).then((res) => {
          if (res?.status === 200) {
            showNotification('success', res?.data?.message);

            navigate('/admin/jobfest/joblist');
          }
        });
      } else {
        addJobInFest(formData).then((res) => {
          if (res?.status === 200) {
            showNotification('success', res?.data?.message);

            navigate('/admin/jobfest/joblist');
          }
        });
      }
    }
  };

  const loadJObLocationOption = async (inputValue) => {
    return getJobFestLocationDropDown(inputValue).then((res) => {
      if (res?.status === 200) {
        let tempArr = [];
        if (res?.data?.predictions) {
          let data = res?.data?.predictions;
          data?.map((data) => {
            tempArr.push({
              id: data?.place_id,
              label: data?.description,
              value: data?.description
            });
          });
        }
        return tempArr;
      }
    });
  };

  return (
    <div className="dash-main-content">
      <Container fluid>
        <div className="dash-page-content">
          <div className="dash-page-title">
            <Row>
              <Col xs={12} sm={12} md={12}>
                <div className="dash-title-bar d-flex align-items-center justify-content-between">
                  <div className="title-lft d-flex align-items-center">
                    <div className="dash-icn">
                      <i className="fas fa-briefcase"></i>
                    </div>
                    <div className="dash-title-dis">
                      <h5>
                        {id ? 'Edit' : 'Add'}
                        <span> Job</span>
                      </h5>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <div className="dash-content-card">
            <div className="cmmn-form">
              <Form>
                <Row>
                  <Col md={12}>
                    <Form.Group className="mb-3" controlId="formBasicName">
                      <Form.Label>
                        Job Fest<span className="text-danger ">*</span>
                      </Form.Label>
                      <AsyncSelect
                        cacheOptions
                        defaultOptions
                        value={jobfest}
                        getOptionLabel={(e) => e.label}
                        getOptionValue={(e) => e.value}
                        loadOptions={loadJobFestOption}
                        className="basic-single mt-1"
                        name="location"
                        onChange={setJobFest}
                      />
                      <div className={'danger-frm px-0 '}>{clicked && validate().jobfest}</div>
                    </Form.Group>
                  </Col>
                  <Col md={12}>
                    <Form.Group className="mb-3" controlId="formBasicName">
                      <Form.Label>
                        Job Title <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Use complete words."
                        name="title"
                        onChange={handleChange}
                        value={remainingJobDetail?.title}
                      />
                      <div className={'danger-frm px-0'}>{clicked && validate().title}</div>
                    </Form.Group>
                  </Col>

                  <Col md={12}>
                    <Form.Group className="mb-3" controlId="formBasicDesi">
                      <Form.Label>Job Description </Form.Label>
                      <CKEditorComponent
                        isToolBar={true}
                        setLatestPost={setJobDescription}
                        latestPost={jobDescription}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={12}>
                    <Form.Group className="mb-3" controlId="formBasicName">
                      <Form.Label>Job Category</Form.Label>
                      <AsyncSelect
                        cacheOptions
                        defaultOptions
                        value={jobCategory}
                        menuIsOpen={categorySearch !== '' ? true : false}
                        getOptionLabel={(e) => e.label}
                        getOptionValue={(e) => e.value}
                        onInputChange={(newValue) => setCategorySearch(newValue)}
                        loadOptions={loadJobCategoryOption}
                        className="basic-single mt-1"
                        name="location"
                        onChange={setJobCategory}
                      />
                    </Form.Group>
                  </Col>

                  <Col md={12}>
                    <Form.Group className="mb-3" controlId="formBasicName">
                      <Form.Label>Open positions</Form.Label>
                      <Form.Select
                        aria-label="Default select example"
                        name="openPosition"
                        value={remainingJobDetail?.openPosition}
                        onChange={handleChange}>
                        {openingData?.length !== ZERO ? (
                          <>
                            {openingData?.map((data, index) => {
                              return (
                                <option value={data?.value} key={index}>
                                  {data?.label}
                                </option>
                              );
                            })}
                          </>
                        ) : (
                          <></>
                        )}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Row>
                    <Form.Label>
                      Work experience(in yrs)<span className="text-danger">*</span>
                    </Form.Label>
                    <Col md={4}>
                      <Form.Group className="mb-3" controlId="formBasicName">
                        <Form.Select
                          aria-label="Default select example"
                          name="minWorkExp"
                          onChange={handleChange}
                          value={remainingJobDetail?.minWorkExp}>
                          <option>Minimum</option>
                          <option value="0">0</option>
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                          <option value="5">5</option>
                          <option value="6">6</option>
                          <option value="7">7</option>
                          <option value="8">8</option>
                          <option value="9">9</option>
                          <option value="10">10</option>
                        </Form.Select>
                        <div className={'danger-frm px-0'}>{clicked && validate().minWorkExp}</div>
                      </Form.Group>
                    </Col>
                    <Col md={1}>
                      <div className="to-lst">to</div>
                    </Col>
                    <Col md={4}>
                      <Form.Group className="mb-3" controlId="formBasicName">
                        <Form.Select
                          aria-label="Default select example"
                          name="maxWorkExp"
                          onChange={handleChange}
                          value={remainingJobDetail?.maxWorkExp}>
                          <option>Maximum</option>
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                          <option value="5">5</option>
                          <option value="6">6</option>
                          <option value="7">7</option>
                          <option value="8">8</option>
                          <option value="9">9</option>
                          <option value="10">10</option>
                          <option value="11">10+</option>
                        </Form.Select>
                        <div className={'danger-frm px-0'}>{clicked && validate().maxWorkExp}</div>
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row>
                    <Form.Label>
                      CTC<span className="text-danger">*</span>
                    </Form.Label>
                    <Col md={4}>
                      <Form.Group className="mb-3" controlId="formBasicName">
                        <Form.Select
                          aria-label="Default select example"
                          name="minCTC"
                          value={remainingJobDetail?.minCTC}
                          onChange={handleChange}>
                          <option>Minimum</option>
                          <option value="0">0 Lacs</option>
                          {getCTCDropDown()?.map((data, index) => {
                            return (
                              <option value={data} key={index}>
                                {data} Lacs
                              </option>
                            );
                          })}
                        </Form.Select>
                        <div className={'danger-frm px-0'}>{clicked && validate().minCTC}</div>
                      </Form.Group>
                    </Col>
                    <Col md={1}>
                      <div className="to-lst">to</div>
                    </Col>
                    <Col md={4}>
                      <Form.Group className="mb-3" controlId="formBasicName">
                        <Form.Select
                          aria-label="Default select example"
                          name="maxCTC"
                          value={remainingJobDetail?.maxCTC}
                          onChange={handleChange}>
                          <option>Maximum</option>

                          {getCTCDropDown()?.map((data, index) => {
                            return (
                              <option value={data} key={index}>
                                {data} Lacs
                              </option>
                            );
                          })}
                          <option value="51">50+ Lacs</option>
                        </Form.Select>
                        <div className={'danger-frm px-0'}>{clicked && validate().maxCTC}</div>
                      </Form.Group>
                    </Col>
                  </Row>

                  <Col md={12}>
                    <Form.Group className="mb-3" controlId="formBasicName">
                      <Form.Label>
                        Company hiring for <span className="text-danger">*</span>
                      </Form.Label>
                      <AsyncSelect
                        components={components}
                        cacheOptions
                        defaultOptions
                        value={hiringFor}
                        menuIsOpen={hiringSearch !== '' ? true : false}
                        getOptionLabel={(e) => e.label}
                        getOptionValue={(e) => e.value}
                        loadOptions={loadAllInstituteOptions}
                        onInputChange={(newValue) => setHiringSearch(newValue)}
                        className="basic-single mt-1"
                        name="location"
                        onChange={setHiringFor}
                      />
                      <div className={'danger-frm px-0'}>{clicked && validate().company}</div>
                    </Form.Group>
                  </Col>

                  <Col md={12}>
                    <Form.Group className="mb-3" controlId="formBasicName">
                      <Form.Label>
                        Location
                        {/* <span className="text-danger">*</span> */}
                      </Form.Label>
                      <AsyncSelect
                        cacheOptions
                        defaultOptions
                        value={jobLocation}
                        menuIsOpen={locationSearch !== '' ? true : false}
                        getOptionLabel={(e) => e.label}
                        getOptionValue={(e) => e.value}
                        loadOptions={loadJObLocationOption}
                        onInputChange={(newValue) => setLocationSearch(newValue)}
                        className="basic-single mt-1"
                        name="location"
                        onChange={setJobLocation}
                      />
                      {/* <div className={'danger-frm px-0'}>{clicked && validate().jobLoc}</div> */}
                    </Form.Group>
                  </Col>

                  <Col md={12}>
                    <Form.Group className="mb-3" controlId="formBasicName">
                      <Form.Label>HR email </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter the email ID to whom user can contact directly"
                        name="hrEmail"
                        value={remainingJobDetail?.hrEmail}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>

                  <Col md={12}>
                    <Form.Group className="mb-3" controlId="formBasicName">
                      <Form.Label>Contact number </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Mobile number to contact"
                        name="contactNumber"
                        maxLength={10}
                        value={remainingJobDetail?.contactNumber}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <div className="form-btns d-flex align-items-center mt-3">
                  <Button className="main-btn-grn" type="button" onClick={handleSubmit}>
                    Save
                  </Button>
                  <strong>OR</strong>
                  <Button
                    className="main-btn-red"
                    onClick={() => navigate('/admin/jobfest/joblist')}>
                    Cancel
                  </Button>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}
