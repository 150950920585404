/**  @copyright : ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
@author     : Shiv Charan Panjeta < shiv@toxsl.com
All Rights Reserved.
Proprietary and confidential :  All information contained herein is, and remains
the property of ToXSL Technologies Pvt. Ltd. and its partners.
Unauthorized copying of this file, via any medium is strictly prohibited.
**/
import AsyncSelect from 'react-select/async';

export default function MultiAsynSelect({
  isMulti,
  value,
  setValue,
  loadOptions,
  placeholder,
  inputMsg,
  setInputMsg
}) {


  return inputMsg ? (
    <AsyncSelect
      isSearchable
      isClearable={true}
      isMulti={isMulti}
      cacheOptions
      defaultOptions
      value={value}
      getOptionLabel={(e) => e.label}
      getOptionValue={(e) => e.value}
      loadOptions={loadOptions}
      className="basic-single"
      name="location"
      onChange={setValue}
      placeholder={placeholder ? placeholder : ''}
      onInputChange={(newValue) => {
        newValue?.length <= 2 && setInputMsg('Please enter atleasst 2 characters');
      }}
      noOptionsMessage={() => (inputMsg ? inputMsg : '')}
      components={{
        DropdownIndicator: () => null,
        IndicatorSeparator: () => null,
        LoadingIndicator: () => null
      }}
    />
  ) : (
    <AsyncSelect
      isSearchable
      isClearable={true}
      isMulti={isMulti}
      cacheOptions
      defaultOptions
      value={value}
      getOptionLabel={(e) => e.label}
      getOptionValue={(e) => e.value}
      loadOptions={loadOptions}
      className="basic-single"
      name="location"
      onChange={setValue}
      placeholder={placeholder ? placeholder : ''}
    />
  );
}

// export default MultiAsynSelect;
