import { useEffect, useState } from 'react';
import { Col, Container, Row, Table } from 'react-bootstrap';
import ReactPaginate from 'react-paginate';
import { Link, useNavigate } from 'react-router-dom';
import NoDataFound from '../../components/commoncomponents/NoDataFound';
import Loader from '../../components/commoncomponents/loader/loader-large';
import { getCronJob } from '../../services/AuthServices';

const Cronjobs = () => {
  const navigate = useNavigate();
  const [cronJob, setCronJob] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRecords, setTotalRecord] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  useEffect(() => {
    /**
     * an IIFE to invoke after effect runs and runs right after invocation
     */
    (async () => {
      try {
        setLoading(true);
        const response = await getCronJob(currentPage);
        if (response?.status === 200) {
          setLoading(false);
          setCronJob(response?.data?.data);
          setTotalRecord(response?.data?.pager?.total);
          // showNotification('success', response.data?.message);
        }
      } catch (error) {
        setLoading(false);
        console.error(error);
      }
    })();
  }, [currentPage]);

  /**
   * a switch case to display status of the cronjob
   * @param {*} state_id
   * @returns
   */
  const jobStatus = (state_id) => {
    switch (state_id) {
      case '0':
        return 'Disable';
      case '1':
        return 'Active';
      case '0':
        return 'Deleted';
      default:
        return;
    }
  };

  /**
   * a function to navigate to see detail of individual cronjob
   * @param {*} id
   */
  const viewDetail = (id) => {
    return (title) => {
      navigate(`/admin/scheduler/cronjob/detail`, {
        state: { id: id, title: title }
      });
    };
  };
  const handlePageClick = (a) => {
    let page = a?.selected + 1;
    setCurrentPage(page);
  };

  return (
    <>

      <div className="dash-main-content">
        <Container fluid>
          <div className="dash-page-content">
            <div className="dash-page-title">
              <Row>
                <Col xs={12} sm={12} md={12}>
                  <div className="dash-title-bar d-flex align-items-center justify-content-between">
                    <div className="title-lft d-flex align-items-center">
                      <div className="dash-icn">
                        <i className="fas fa-suitcase"></i>
                      </div>
                      <div className="dash-title-dis">
                        <h5>Cronjobs</h5>
                        <h5>{/* Total Record(s): <span>{planList?.count}</span> */}</h5>
                      </div>
                    </div>
                    <div className="title-rt-btn d-flex align-items-center">
                      <Link className="add-btn-grn mx-2" to="/admin/scheduler/cronjob/add">
                        Add
                      </Link>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="dash-content-card">
              <div className="table-responsive">
                <Table className="custom-table-data" striped bordered hover>
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Title</th>
                      <th>Command</th>
                      {/* <th>When</th> */}
                      <th>Type</th>
                      {/* <th>Logs</th> */}
                      <th>State</th>
                      <th>Created On</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {cronJob?.length > 0 ? (
                      cronJob?.map((jobs, index) => {
                        return (
                          <tr key={index}>
                            <td>{jobs?.id}</td>
                            <td>{jobs?.title}</td>
                            <td>{jobs?.command}</td>
                            {/* <td>*****</td> */}
                            <td>Default</td>
                            {/* <td>3138</td> */}
                            <td>{jobStatus(jobs?.state_id)}</td>
                            <td>{jobs?.created_on}</td>
                            <td>
                              <i
                                onClick={() => {
                                  viewDetail(jobs?.id)(jobs?.title);
                                }}
                                className="far fa-eye pointer"
                              ></i>
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td className="text-center" colSpan={7}>
                          <NoDataFound text="No Cronjobs Found" />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
                <div className="react-page d-flex justify-content-center">
                  {totalRecords > 10 ? (
                    <ReactPaginate
                      previousLabel={currentPage > 0 ? 'Prev' : 'Next'}
                      nextLabel="Next"
                      onPageChange={handlePageClick}
                      activeClassName={'page-link active'}
                      pageCount={Math.ceil(totalRecords / 10)}
                    />
                  ) : (
                    ''
                  )}
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>

      {loading && <Loader />}
    </>
  );
};

export default Cronjobs;
