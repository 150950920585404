import { useEffect, useRef } from 'react';
import moment from 'moment';
import showNotification from '../services/NotificationService';
import { getCountryList } from '../services/ShopServices';
import {
  getEventCategoryDrop,
  PageLikeUnLike,
  chapterLeaveAdmin,
  getAttemptedPollResponse
} from '../services/SocialServices';
import { insituteListingDropDown, insituteMembers } from '../services/TemplateServices';
import * as APISERVICES from '../services/UserServices';


export function useOnClickOutside(ref, handler) {
  useEffect(() => {
    const listener = (event) => {
      // Do nothing if clicking ref's element or descendent elements
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }
      handler(event);
    };

    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);

    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [ref, handler]);
}

/**
 *  Image Url
 * @param {*} str
 * @param {*} n
 * @returns
 */

export const getMedia = (mediaObject, imageSize = '') => {
  var w, f, d, s;

  const THUMB_SIZES = [64, 320, 800];

  function checkAdult(age) {
    return age >= w;
  }

  f = JSON.parse(JSON.stringify(mediaObject));

  if (f.served_from == 'media_table' || imageSize == '') {
    f.url = f.path + '.' + f.extension;
  } else {
    d = THUMB_SIZES.filter(checkAdult)[0];
    f.url = f.path + '_' + s + '_' + d + '.' + f.extension;
  }

  return f;
};

const userProfilePic = (mid, w, h, o, type) => {
  if (o === undefined) {
    o = false;
  }
  if (type === undefined) {
    type = 'user';
  }

  var url = '/smedia/index/' + mid + '/' + w + '/' + h + '/' + o + '/' + type;

  let defaultSrc = ''; //'/common/img/Loading_pymk_icon.png'
  let defaultSrcHeight = 100;

  APISERVICES.userProfilePic(url).then((res) => {
    if (res?.status === 200) {
      defaultSrc = res?.data?.detail[0]?.path;
    }
  });

  return defaultSrc;
};

export const mediaServeImage = (mid, w, h, o, type) => {
  return userProfilePic(mid, w, h, o, type);
};

export const truncate = (str, n) => {
  return str?.length > n ? str.substr(0, n - 1) + '...' : str;
};
('');

export const strongPassRegx = new RegExp(
  /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/i
);

export const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;

export const imageExtension = ['image/jpg', 'image/jpeg', 'image/png'];

export const resumeExtension = [
  'application/msword',
  'application/vnd.oasis.opendocument.text',
  'application/xml',
  'application/rtf',
  'application/pdf',
  'application/doc',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/odt'
];

export const restrictAlpha = (e) => {
  const re = /[0-9A-F:]+/g;
  if (!re.test(e.key)) {
    e.preventDefault();
  }
};

export const restrictNum = (e) => {
  const re = /[0-9.:]+/g;
  if (re.test(e.key)) {
    e.preventDefault();
  }
};

// export const restrictNum = (e) => {
//   const re = /[0-9A-F:]+/g;
//   if (re.test(e.key)) {
//   e.preventDefault();
//   }
//   };

export const websiteRegex =
  //eslint-disable-next-line
  /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g;

export const useFocus = () => {
  const htmlElRef = useRef(null);
  const setFocus = () => {
    htmlElRef.current && htmlElRef.current.focus();
  };

  return [htmlElRef, setFocus];
};
export const disableFutureDates = () => {
  let dtToday = new Date();
  let month = dtToday.getMonth() + 1;
  let day = dtToday.getDate();
  let year = dtToday.getFullYear();
  if (month < 10) month = '0' + month.toString();
  if (day < 10) day = '0' + day.toString();
  let maxDate = year + '-' + month + '-' + day;
  return maxDate;
};

export const disablePastDates = () => {
  let dtToday = new Date();
  let month = dtToday.getMonth() + 1;
  let day = dtToday.getDate();
  let year = dtToday.getFullYear();
  if (month < 10) month = '0' + month.toString();
  if (day < 10) day = '0' + day.toString();
  let maxDate = year + '-' + month + '-' + day;
  return maxDate;
};
export const disableFutureMonth = () => {
  let dtToday = new Date();
  let month = dtToday.getMonth() + 1;
  let day = dtToday.getDate();
  let year = dtToday.getFullYear();
  if (month < 10) month = '0' + month.toString();
  if (day < 10) day = '0' + day.toString();
  let maxDate = year + '-' + month;
  return maxDate;
};

export const createSelectDropDownList = (data, rid = false) => {
  let arr = [];

  if (data?.length) {
    if (rid) {
      data?.map((data) => {
        arr.push({
          id: data.id,
          label: data.name,
          value: data.name
        });
      });
    } else {
      data?.map((data) => {
        arr.push({
          id: data.id,
          label: (
            <div>
              <img
                src={
                  process.env.REACT_APP_API_URL + 'common/uploads/emoticon/' + data?.name + '.png'
                }
                alt="logo"
                height="30px"
                width="30px"
              />
              {`\u00A0\u00A0\u00A0${data.name}`}
            </div>
          ),
          value: data.name,
          logo: data?.logo
        });
      });
    }
  }

  return arr;
};

export const createSelectDropDownWithOutLogo = (data, rid = false) => {
  let arr = [];

  if (data?.length) {
    if (rid) {
      data?.map((data) => {
        arr.push({
          id: data.id,
          label: data.name,
          value: data.name
        });
      });
    } else {
      data?.map((data) => {
        arr.push({
          id: data.id,
          label: data?.name,
          value: data.name,
          logo: data?.logo
        });
      });
    }
  }

  return arr;
};

export const createMapSelectDropDownList = (data, rid = false) => {
  let arr = [];

  if (data?.length) {
    if (rid) {
      data?.map((data) => {
        arr.push({
          id: data.id,
          label: data.name,
          value: data.name
        });
      });
    } else {
      data?.map((data) => {
        arr.push({
          id: data.id,
          label: (
            <div>
              {/* <img
                src={process.env.REACT_APP_API_URL + 'common/uploads/emoticon/' + data?.name + ".png"}
                alt="logo"
                height="30px"
                width="30px"
              /> */}
              {`${data.name}`}
            </div>
          ),
          value: data.name,
          logo: data?.logo
        });
      });
    }
  }

  return arr;
};

export const getYearDropDown = (setState) => {
  let arr1 = [];
  let min = 1900;
  let max = new Date().getFullYear();

  for (max; max >= min; max--) {
    arr1.push({ label: max, value: max });
  }
  setState(arr1);
};

export const getProficiencyImage = (key) => {
  switch (key) {
    case 1:
      return require('../assets/images/Excellent.png');
    case 2:
      return require('../assets/images/very-good.png');
    case 3:
      return require('../assets/images/good.png');
    case 4:
      return require('../assets/images/Fair.png');
    case 6:
      return require('../assets/images/need-improvement.png');
    default:
      return require('../assets/images/Excellent.png');
  }
};

export const addFriendHandle = (e, id, renderFunction, setIsLoading) => {
  setIsLoading(true);
  e.preventDefault();
  let body = {
    following_id: id
  };
  let formData = new FormData();
  for (let i in body) {
    formData.append(`${i}`, body[i]);
  }
  APISERVICES.addFriend(formData).then((res) => {
    setIsLoading(false);
    if (res?.status === 200) {
      showNotification('success', 'Friend request sent Successfully message');
      renderFunction();
    } else {
      showNotification('success', res?.data?.message);
    }
  });
};

export const defaultContent = () => {
  return {
    output:
      '<p>Hi,</p><p>Alma Bay (nishantkango@yahoo.co.in) has invited you on AlmaBay - An Alumni Engagement Platform.</p><p>Get connected with your Alma Mater and old friends on Almabay.com.</p><p>Wonderful platform for professional and social networking!</p><p>Almabay helps you in :</p>'
  };
};

//0-disable, 1-enable, 2-delete
export const statusName = (key) => {
  switch (key) {
    case '0':
      return 'Disable';
    case '1':
      return 'Enable';
    case '2':
      return 'Delete';
  }
};

export const site_url = (stringurl = '') => {
  return process.env.REACT_APP_DOMAIN + stringurl;
};

export const base_url = (stringurl = '') => {
  return process.env.REACT_APP_DOMAIN + stringurl;
};

export const base_api_url = (stringurl = '') => {
  return process.env.REACT_APP_API_DOMAIN + stringurl;
};

export const getOpeningDropDown = (setState) => {
  let arr1 = [];
  let min = 1;
  let max = 200;

  for (min; max >= min; min++) {
    arr1.push({ label: min, value: min });
  }
  setState(arr1);
};

export const getCTCDropDown = () => {
  let arr1 = [];
  let n = 50;

  for (var i = 1; i <= n; i++) {
    arr1.push(i);
  }
  return arr1;
};

export const replaceStringFormat = (tokeString) => {
  return ' [' + tokeString + '] ';
};

// export const  validateFax =(checkField) => {
//   if (checkField.value.length > 0) {
//       var faxRegEx = /^\+?[0-9]{6,}$/;
//       if (!checkField.value.match(faxRegEx)) {
//           return false;
//       }
//   }
//   return true;
// }


export const filterPassedTime = (time) => {
  const currentDate = new Date();
  const selectedDate = new Date(time);

  return currentDate.getTime() < selectedDate.getTime();
};

export const loadEventCategoryOptions = (inputValue) => {
  return getEventCategoryDrop(inputValue).then((res) => {
    if (res?.status === 200) {
      let tempArr = [];
      if (res?.data?.results) {
        const data = res?.data?.results;
        data?.map((data, index) => {
          tempArr.push({
            id: data?.id,
            label: data?.name,
            value: data?.name
          });
        });
      }
      return tempArr;
    }
  });
};

export const LikeDisLikePage = (e, id, renderFunction, type) => {
  e.preventDefault();
  if (confirm('Do you want unlike this page?') == true) {
    let body = {
      following_id: id
    };
    let formData = new FormData();
    for (let i in body) {
      formData.append(`${i}`, body[i]);
    }
    PageLikeUnLike(formData).then((res) => {
      if (res?.status === 200) {
        showNotification(
          'success',
          type == 'like' ? 'Page liked successfully' : 'Page unliked successfully'
        );
        renderFunction();
      } else {
        showNotification('err', res?.data?.message);
      }
    });
  } else return;
};

export const leaveAdminChapter = (e, id, renderFunction) => {
  if (confirm('Do you want to leave from the Chapter?') == true) e.preventDefault();
  let body = {
    chapter_id: id
  };
  let formData = new FormData();
  for (let i in body) {
    formData.append(`${i}`, body[i]);
  }
  chapterLeaveAdmin(formData).then((res) => {
    if (res?.status === 200) {
      showNotification('success', res?.data?.message);
      renderFunction();
    } else {
      showNotification('success', res?.data?.message);
    }
  });
};

export const SK_source = () => {
  return base_api_url('ajax/socialAjax');
};

export const base64_encode = (str) => {
  return str;
};

export const uri_string = () => {
  return window.location.href;
};

export const sleepDelay = (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

/******
 * Send message confirm login
 */

export const sendMessageConfirmLogin = (login) => {
  return false;
  //code
  // var sendMessage = bootbox.dialog({
  //   title: "Send your message to your mentor. | <small>Be specific while sending message and get instant response.</small>",
  //   message: '<div className="row"> You need to login first before sending message to this mentor, <a href=' + login + '>Click Here</a></div>',
  //   buttons: {
  //     success: {
  //       label: "Ok",
  //       className: "btn btn-primary btn-sm",
  //       callback: function () {
  //         window.location.href = login;
  //       }
  //     },
  //     danger: {
  //       label: "Cancel",
  //       className: "btn-default btn-sm",
  //       callback: function () {
  //         //toastr.success("Sending new message to your mentor aborted.");
  //       }
  //     }
  //   },
  //   onEscape: function () {

  //   }
  // });
};

export const NumberWithdecimal = (evt, value) => {
  var data = value;
  if ((evt.charCode >= 48 && evt.charCode <= 57) || evt.charCode == 46 || evt.charCode == 0) {
    // if (data.indexOf(".") > -1) {
    //   if (evt.charCode == 46) evt.preventDefault();
    // }
  } else evt.preventDefault();
};

export const numWithdecimal = new RegExp(/^\d*\.?\d+$/);

export const showBirthMonth = (key) => {
  switch (key) {
    case '1':
      return 'January';

    case '2':
      return 'February';

    case '3':
      return 'March';

    case '4':
      return 'April';

    case '5':
      return 'May';

    case '6':
      return 'June';

    case '7':
      return 'July';

    case '8':
      return 'August';

    case '9':
      return 'September';

    case '10':
      return 'October';

    case '11':
      return 'November';

    case '12':
      return 'December';
  }
};

export const privacy = (data) => {
  switch (data) {
    case '4':
      return 'Shared with Everyone';
    case '0':
      return 'Shared with Only Me';
    case '1':
      return 'Shared with Friends';
    case '2':
      return 'Shared with Mentors';
    case '3':
      return 'Shared with Mentees';
    case '1,2':
      return 'Shared with Friends and Mentors';
    case '2,1':
      return 'Shared with Friends and Mentors';
    case '1,3':
      return 'Shared with Friends and Mentees';
    case '3,1':
      return 'Shared with Mentors and Mentees';
    case '2,3':
      return 'Shared with Mentors and Mentees';
    case '3,2':
      return 'Shared with Friends and Mentees';
    case '1,2,3':
      return 'Shared with Friends, Mentors and Mentees';
    case '1,3,2':
      return 'Shared with Friends, Mentors and Mentees';
    case '2,1,3':
      return 'Shared with Friends, Mentors and Mentees';
    case '2,3,1':
      return 'Shared with Friends, Mentors and Mentees';
    case '3,1,2':
      return 'Shared with Friends, Mentors and Mentees';
    case '3,2,1':
      return 'Shared with Friends, Mentors and Mentees';
    default:
      break;
  }
};

export const loadCountryOption = async (inputValue) => {
  return getCountryList(inputValue).then((res) => {
    if (res?.status === 200) {
      let tempArr = [];
      if (res?.data?.data) {
        let data = res?.data?.data;

        data?.map((data) => {
          tempArr.push({
            id: data?.country_id,
            label: data?.name,
            value: data?.name
          });
        });
      }
      return tempArr;
    }
  });
};

/**Insitute dropdown */

export const loadInsituteOption = async (inputValue) => {
  return insituteListingDropDown(inputValue).then((res) => {
    if (res?.status === 200) {
      let tempArr = [];
      if (res?.data?.detail) {
        let data = res?.data?.detail;

        data?.map((data) => {
          tempArr.push({
            id: data?.campus_id,
            label: data?.name,
            value: data?.name
          });
        });
      }
      return tempArr;
    }
  });
};

/**members dropdown */

export const loadMembersOption = async (inputValue) => {
  return insituteMembers(inputValue).then((res) => {
    if (res?.status === 200) {
      let tempArr = [];
      if (res?.data?.detail?.users) {
        let data = res?.data?.detail?.users;

        data?.map((data) => {
          tempArr.push({
            id: data?.user_id,
            label: data?.name,
            value: data?.name
          });
        });
      }
      return tempArr;
    }
  });
};

export const UploadSheetExtension = [
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
];

export function newAbortSignal(timeoutMs) {
  const abortController = new AbortController();
  setTimeout(() => abortController.abort(), timeoutMs || 0);
  return abortController.signal;
}

export const videoExtension = ['video/mp4', 'video/mov'];

export const getWorkDropDown = () => {
  let arr1 = [];
  let n = 10;

  for (var i = 0; i <= n; i++) {
    arr1.push(i);
  }
  return arr1;
};

export const getCTCDropDownWithZero = () => {
  let arr1 = [];
  let n = 50;

  for (var i = 0; i <= n; i++) {
    arr1.push(i);
  }
  return arr1;
};

export const getWorkDropDownWithOutZero = () => {
  let arr1 = [];
  let n = 10;

  for (var i = 1; i <= n; i++) {
    arr1.push(i);
  }
  return arr1;
};

export const restrictSpecialChar = (e) => {
  const re = /[^a-z0-9]/gi;
  if (re.test(e.key)) {
    e.preventDefault();
  }
};

export const loadGetLocationTags = async (inputValue) => {
  return APISERVICES.getLocationTags(inputValue).then((res) => {
    if (res?.status === 200) {
      let tempArr = [];
      if (res?.data?.results) {
        const data = res?.data?.results;
        data?.map((data) => {
          tempArr.push({
            id: data?.id,
            label: data?.name,
            value: data?.name
          });
        });
      }
      return tempArr;
    }
  });
};

export const hitPollApi = () => {
  getAttemptedPollResponse().then((res) => {
    if (res?.status == 200) {
      localStorage.setItem('react-polls', JSON.stringify(res?.data?.data));
    }
  });
};
export const wordToNum = (str) => {
  const legend = ['zero', 'one', 'two', 'three', 'four', 'five', 'six', 'seven'];
  return str
    .toLowerCase()
    .split(' ')
    .reduce((acc, val) => {
      const index = legend.indexOf(val);
      return acc * 10 + index;
    }, 0);
};

export const getTime = (date) => {
  let result = moment(date).fromNow();
  const now = moment();
  const days = now.diff(date, 'days');
  const weeks = now.diff(date, 'weeks');
  if (days >= 7) {
    if (days <= 13) {
      result = `a week ago`;
    } else if (days > 13 && days <= 25) {
      result = `${weeks} weeks ago`;
    }
  }
  return result;
};

export const subDomain= ()=>{
  const URL = window.location.host;
  const  sub_domain = URL.split('.')[0];
  return sub_domain
}

