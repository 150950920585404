/**
@copyright  :   ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
@author     :   Shiv Charan Panjeta 
 
All Rights Reserved.
Proprietary and confidential :  All information contained here in is, and remains
the property of ToXSL Technologies Pvt. Ltd. and it's partners.
Unauthorized copying of this file, via any medium is strictly prohibited.
*/
import { useState } from 'react';
import { Button, Container } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Pagination from 'react-bootstrap/Pagination';
import Row from 'react-bootstrap/Row';
import Table from 'react-bootstrap/Table';
import { Link } from 'react-router-dom';

export default function EventBooking() {
  let active = 2;
  let items = [];
  for (let number = 1; number <= 5; number++) {
    items.push(
      <Pagination.Item key={number} active={number === active}>
        {number}
      </Pagination.Item>
    );
  }

  const [isClearable, setIsClearable] = useState(true);
  const [isSearchable, setIsSearchable] = useState(true);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRtl, setIsRtl] = useState(false);
  return (
    <div className="dash-main-content">
      <Container fluid>
        <div className="dash-page-content">
          <div className="dash-page-title">
            <Row>
              <Col xs={12} sm={12} md={12}>
                <div className="dash-title-bar d-flex align-items-center justify-content-between">
                  <div className="title-lft d-flex align-items-center">
                    <div className="dash-icn">
                      <i className="fa fa-users"></i>
                    </div>
                    <div className="dash-title-dis">
                      <h5>
                        Event Tickets <span>Bookings</span>
                      </h5>
                      <h5>
                        Total Record(s): <span>14967</span>
                      </h5>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <div className="dash-content-card">
            <div className="table-responsive">
              <Table className="custom-table-data" striped bordered hover>
                <thead>
                  <tr>
                    <th>
                      <Form.Check type="checkbox" id="checkbox" label="" />
                    </th>
                    <th>
                      <Link to="">
                        Booking Id
                        {/* <img src={require('../../assets/images/sort.png')} alt="sort" /> */}
                      </Link>
                    </th>
                    <th>
                      <Link to="">
                        User Id
                        {/* <img src={require('../../assets/images/sort.png')} alt="sort" /> */}
                      </Link>
                    </th>
                    <th>
                      <Link to="">
                        Buyer Name
                        {/* <img src={require('../../assets/images/sort.png')} alt="sort" /> */}
                      </Link>
                    </th>
                    <th>
                      <Link to="">
                        Buyer Mobile
                        {/* <img src={require('../../assets/images/sort.png')} alt="sort" /> */}
                      </Link>
                    </th>
                    <th>
                      <Link to="">
                        Quantity
                        {/* <img src={require('../../assets/images/sort.png')} alt="sort" /> */}
                      </Link>
                    </th>
                    <th>
                      <Link to="">
                        Amount
                        {/* <img src={require('../../assets/images/sort.png')} alt="sort" /> */}
                      </Link>
                    </th>
                    <th>
                      <Link to="">
                        Status
                        {/* <img src={require('../../assets/images/sort.png')} alt="sort" /> */}
                      </Link>
                    </th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="search-table">
                    <td></td>
                    <td>
                      <Form.Control type="text" placeholder="Booking ID" />
                    </td>
                    <td>
                      <Form.Control type="text" placeholder="User ID" />
                    </td>
                    <td>
                      <Form.Control type="text" placeholder="Name" />
                    </td>
                    <td>
                      <Form.Control type="text" placeholder="Mobile" />
                    </td>
                    <td></td>
                    <td></td>
                    <td>
                      <Form.Select aria-label="Default select example">
                        <option> - Select - </option>
                        <option value="1"> Success </option>
                        <option value="2"> Failed </option>
                      </Form.Select>
                    </td>
                    <td className="serac-btns">
                      <Button className="main-btn-grn">Search</Button>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Form.Check type="checkbox" id="checkbox" label="" />
                    </td>
                    <td>43948</td>
                    <td>1323456</td>
                    <td>jack Smat </td>
                    <td>9855055012</td>
                    <td>Free</td>
                    <td>12000</td>
                    <td>Successs</td>
                    <td>
                      <Form.Select aria-label="Default select example">
                        <option>Action</option>
                        <option value="1">Edit</option>
                        <option value="2">View</option>
                      </Form.Select>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Form.Check type="checkbox" id="checkbox" label="" />
                    </td>
                    <td>43948</td>
                    <td>1323456</td>
                    <td>jack Smat </td>
                    <td>9855055012</td>
                    <td>Free</td>
                    <td>12000</td>
                    <td>Failed</td>
                    <td>
                      <Form.Select aria-label="Default select example">
                        <option>Action</option>
                        <option value="1">Edit</option>
                        <option value="2">View</option>
                      </Form.Select>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Form.Check type="checkbox" id="checkbox" label="" />
                    </td>
                    <td>43948</td>
                    <td>1323456</td>
                    <td>jack Smat </td>
                    <td>9855055012</td>
                    <td>Free</td>
                    <td>12000</td>
                    <td>Successs</td>
                    <td>
                      <Form.Select aria-label="Default select example">
                        <option>Action</option>
                        <option value="1">Edit</option>
                        <option value="2">View</option>
                      </Form.Select>
                    </td>
                  </tr>
                </tbody>
              </Table>
              <Pagination>{items}</Pagination>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}
