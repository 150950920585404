import { Link } from 'react-router-dom';

function ProfileSidebar(props) {
  const { state, head } = props;
  return (
    <>
      <div className="account-sidebar">
        <div className={`${head === 'account' ? 'active' : ''}`}>
          <h2>
            <Link to="#">My Account</Link>
          </h2>
          <ul>
            <li>
              <Link
                to="/user/profile/basic-details"
                className={`${state === 'basic' ? 'active' : ''}`}
              >
                Basic Details
              </Link>
            </li>
            <li>
              <Link
                to="/user/profile/contact-details"
                className={`${state === 'contact' ? 'active' : ''}`}
              >
                Contact Details
              </Link>
            </li>
            <li>
              <Link
                to="/user/profile/work-details"
                className={`${state === 'work' ? 'active' : ''}`}
              >
                Work
              </Link>
            </li>
            <li>
              <Link
                to="/user/profile/education-details"
                className={`${state === 'education' ? 'active' : ''}`}
              >
                Education
              </Link>
            </li>
            <li>
              <Link
                to="/user/profile/account-notifications"
                className={`${state === 'notification' ? 'active' : ''}`}
              >
                Notifications
              </Link>
            </li>
          </ul>
        </div>
        <div className={`${head === 'life' ? 'active' : ''}`}>
          <h2 className="">
            <Link to="">Life Skills</Link>
          </h2>
          <ul>
            <li>
              <Link
                className={`${state === 'tech' ? 'active' : ''}`}
                to="/user/profile/technical-skills"
              >
                Technical Skills
              </Link>
            </li>
            <li>
              <Link
                className={`${state === 'business' ? 'active' : ''}`}
                to="/user/profile/business-skills"
              >
                Business Skills
              </Link>
            </li>
            <li>
              <Link
                className={`${state === 'inter' ? 'active' : ''}`}
                to="/user/profile/interpersonal-skills"
              >
                Interpersonal Skills
              </Link>
            </li>
            <li>
              <Link
                className={`${state === 'career' ? 'active' : ''}`}
                to="/user/profile/career-goals"
              >
                Career Goals
              </Link>
            </li>
            <li>
              <Link
                className={`${state === 'swot' ? 'active' : ''}`}
                to="/user/profile/swot-analysis"
              >
                Swot Analysis
              </Link>
            </li>
          </ul>
        </div>
        <div className={`${head === 'privacy' ? 'active' : ''}`}>
          <h2 className="">
            <Link to="#">Privacy Settings</Link>
          </h2>
          <ul>
            <li>
              <Link
                className={`${state === 'change' ? 'active' : ''}`}
                to="/user/profile/change-password"
              >
                Change Password
              </Link>
            </li>
            <li>
              <Link
                className={`${state === 'privacy' ? 'active' : ''}`}
                to="/user/profile/privacy-settings"
              >
                Privacy Settings
              </Link>
            </li>
            <li>
              <Link
                className={`${state === 'invite' ? 'active' : ''}`}
                to="/user/profile/change-invite-code"
              >
                Change Invite Code
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
}

export default ProfileSidebar;
