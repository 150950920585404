import { useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import * as APISERVICES from '../../../services/JobServices';
import showNotification from '../../../services/NotificationService';
import NoDataFound from '../NoDataFound';

function FollowCompany() {
  const [companySuggest, setCompanySuggest] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const sliderRef = useRef();

  useEffect(() => {
    CompanySuggestion();
  }, []);

  /* eslint-disable */
  const CompanySuggestion = (offSet, follow, id) => {
    setLoading(true);
    if (follow === true) {
      let data = companySuggest.filter((item) => item?.timeline_id !== id);
      setCompanySuggest(data);
    } else {
      APISERVICES.CompanySuggestionsWidget(offSet).then((res) => {
        setLoading(false);
        if (res?.status === 200) {
          if (companySuggest.length > 0) {
            setCompanySuggest([...companySuggest, ...res?.data?.company_pages]);
          } else {
            setCompanySuggest([...res?.data?.company_pages]);
          }
        }
      });
    }
  };
  const handleFollowCompany = (e, id) => {
    setLoading(true);
    e.preventDefault();
    const body = {
      following_id: id
      // type: 'company'
    };
    APISERVICES.followCompany(body).then((res) => {
      setLoading(false);
      if (res?.status == 200) {
        showNotification('success', 'Company follow successfully');
        CompanySuggestion('', true, id);
      } else {
        showNotification('danger', 'Something went wrong');
      }
    });
  };

  const companyType = (insitute_type) => {
    if (insitute_type == 'institute' || insitute_type == 'college') {
      return 'Educational Institute';
    } else if (insitute_type == 'company') {
      return 'Corporate';
    } else if (insitute_type == 'ngo') {
      return 'Non Government';
    } else if (insitute_type == 'npo') {
      return 'Non Profit';
    } else if (insitute_type == 'gov_agency') {
      return 'Government Agencies';
    } else if (insitute_type == 'professionals') {
      return 'Professionals';
    } else if (insitute_type == 'entrepreneur') {
      return 'Entrepreneur';
    } else {
      return 'Others';
    }
  };

  const handleSlideNext = (e) => {
    e.preventDefault();
    sliderRef.current.swiper.slideNext();
    CompanySuggestion(companySuggest?.length);
  };

  const handleSlidePrev = (e) => {
    e.preventDefault();
    sliderRef.current.swiper.slidePrev();
  };

  return (
    <>
      <div className="suggest-mentor artical-blog-card bg-white p-3 mb-3">
        <div className="d-flex align-items-top justify-content-between flex-wrap">
          <h4>Follow Company</h4>
          {companySuggest?.length > 1 && (
            <div className="follow-slide-arrow">
              <Link
                to={`javascript:void(0)`}
                className="p-2 h3"
                onClick={(e) => handleSlidePrev(e)}
              >
                <i className="fa fa-angle-left"></i>
              </Link>
              <Link
                to={`javascript:void(0)`}
                className="p-2 h3"
                onClick={(e) => handleSlideNext(e)}
              >
                <i className="fa fa-angle-right"></i>
              </Link>
            </div>
          )}
        </div>
        <div className="follow-company-card">
          {loading &&
            Array.from(Array(1), (index) => {
              return <Shimmer key={index} />;
            })}
          <Swiper spaceBetween={50} slidesPerView={1} ref={sliderRef}>
            {!loading && companySuggest?.length !== 0 ? (
              companySuggest?.map((data, index) => (
                <SwiperSlide key={index}>
                  <div className="follow-card-slide">
                    <div className="follow-comp-img mb-3">
                      <Link
                        // to={`/jobs/company-details/${data.id}`}
                        onClick={(e) => {
                          e.preventDefault();
                          navigate(`/company/index/${data.username}`, { state: data });
                        }}
                      >
                        <img
                          src={
                            data?.avatar_url
                              ? data?.avatar_url
                              : require('../../../assets/images/birth6.jpg')
                          }
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null; // prevents looping
                            currentTarget.src = require("../../../assets/images/birth6.jpg")
                          }}
                          alt="logo"
                        />
                      </Link>
                    </div>
                    <div className="follow-company-dis">
                      <h4>
                        <Link
                          // to={`/jobs/company-details/${data.id}`}
                          onClick={(e) => {
                            e.preventDefault();
                            navigate(`/company/index/${data.username}`, { state: data });
                          }}
                        >
                          {data?.name}
                        </Link>
                      </h4>
                      <p>{companyType(data?.institute_type)}</p>
                      <p>{data?.company_size} Employees</p>
                      <p>{data?.city}</p>
                      <span>{data?.count} Followers</span>
                    </div>
                    <Link
                      onClick={(e) => handleFollowCompany(e, data?.timeline_id)}
                      className="wht-btn-grey mt-2 mb-2"
                    >
                      Follow
                    </Link>
                  </div>
                </SwiperSlide>
              ))
            ) : (
              <>
                {!loading && companySuggest.length == 0 && (
                  <NoDataFound text="Company not found!!" />
                )}
              </>
            )}
          </Swiper>
        </div>
      </div>
    </>
  );
}

export default FollowCompany;

const Shimmer = () => {
  return (
    <div className="follow-card-slide follow-skelton loading-skeleton">
      <div className="follow-comp-img mb-3">
        <Link to="#">
          <img src={require('../../../assets/images/birth6.jpg')} alt="logo" />
        </Link>
      </div>
      <div className="follow-company-dis">
        <h4>
          <Link to="#">Company Name</Link>
        </h4>
        <p>Comapny Type</p>
        <p>Employees</p>
        <p>Amritsar</p>
        <span>Followers</span>
      </div>
      <Link className="wht-btn-grey mt-2 mb-2">Follow</Link>
    </div>
  );
};
