/**
 * @copyright : ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
@author    : Shiv Charan Panjeta 
 
All Rights Reserved.
Proprietary and confidential :  All information contained here in is, and remains
the property of ToXSL Technologies Pvt. Ltd. and it's partners.
Unauthorized copying of this file, via any medium is strictly prohibited.
 */

import moment from 'moment';
import { useEffect, useState } from 'react';
import { Col, Container, Placeholder, Row, Table } from 'react-bootstrap';
import ReactPaginate from 'react-paginate';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { consumptionReport, getLogReport } from '../../../services/AuthServices';
import CustomModal from '../../commoncomponents/CustomModal';
import Loader from '../../commoncomponents/loader/loader-large';

const ManageCommunicationsLogs = () => {
  const navigate = useNavigate();
  const instituteDetails = useSelector((state) => state?.user?.details);
  const [show, setShow] = useState(false);
  const [reportList, setReportList] = useState([]);
  const [totalCount, setTotalCount] = useState(null);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [emailStat, setEmailStat] = useState(null);
  const [smsStat, setSmsStat] = useState(null);

  useEffect(() => {
    logReport();
  }, [page]);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const logReport = async () => {
    try {
      setLoading(true);
      const response = await getLogReport(page);
      if (response?.status === 200) {
        setLoading(false);
        setReportList(response.data?.data);
        setTotalCount(response.data?.total);
      }
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  const consumptionStat = async () => {
    try {
      setLoading(true);
      const response = await consumptionReport(instituteDetails?.id);
      if (response?.status === 200) {
        setLoading(false);
        setEmailStat(response.data?.Email);
        setSmsStat(response.data?.Sms);
      }
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  const detailedReportPage = (id) => {
    navigate('/admin/communication/communication-logs-detailed-report', { state: id });
  };

  const messageStatus = (state) => {
    switch (state) {
      case '0':
        return 'Pending';
      case '1':
        return 'In-Progress';
      case '2':
        return 'Completed';
      case '3':
        return 'Failed';
      default:
        return;
    }
  };

  const typeStatus = (state) => {
    switch (state) {
      case '3':
        return 'Email';
      case '1':
        return 'SMS';
      case '2':
        return 'Notification';
      // case '3':
      //   return 'Failed';
      default:
        return;
    }
  };

  return (
    <>
      <div className="dash-main-content">
        <Container fluid>
          <div className="dash-page-content">
            <div className="dash-page-title">
              <Row>
                <Col xs={12} sm={12} md={12}>
                  <div className="dash-title-bar d-flex align-items-center justify-content-between">
                    <div className="title-lft d-flex align-items-center">
                      <div className="dash-icn">
                        <i className="fas fa-comments"></i>
                      </div>
                      <div className="dash-title-dis">
                        <h5>Manage Communications</h5>
                        <h5>{/* Total Record(s): <span>{planList?.data?.length}</span> */}</h5>
                      </div>
                    </div>
                    <div className="title-rt-btn d-flex align-items-center">
                      <Link
                        className="add-btn-grn mx-2"
                        to="/admin/communication/new-communication">
                        New Communication
                      </Link>
                      <Link
                        onClick={() => {
                          handleShow();
                          consumptionStat();
                        }}
                        className="add-btn-grn mx-2"
                        to="">
                        Consumption Stat
                      </Link>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="dash-content-card">
              <div className="table-responsive">
                <Table className="custom-table-data" responsive striped bordered hover>
                  <thead>
                    <tr>
                      <th>Sn.</th>
                      <th>Info</th>
                      <th>Template Type</th>
                      <th>Status</th>
                      <th>Communication Start Date</th>
                      <th>Delivered</th>
                      <th>Target Audience</th>
                    </tr>
                  </thead>
                  <tbody>
                    {reportList?.map((data, index) => {
                      return (
                        <tr
                          key={data?.id}
                          style={{ cursor: 'pointer' }}
                          onClick={() => {
                            detailedReportPage(data?.id);
                          }}>
                          <td>{index + 1}</td>
                          <td>{data?.admin_email}</td>
                          <td>{typeStatus(data?.type_id)}</td>
                          <td>{messageStatus(data?.state_id)}</td>
                          <td>{moment(data?.created_at).format('LLL')}</td>
                          <td>{data?.delieverd_to}</td>
                          <td>{data?.target_audience_count}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>
              {Math.ceil(totalCount / 10) > 1 && (
                <div className="react-page d-flex justify-content-center">
                  <ReactPaginate
                    previousLabel={'Prev'}
                    nextLabel={'Next'}
                    activeClassName={'page-link active'}
                    onPageChange={(props) => {
                      setPage(props.selected + 1);
                    }}
                    pageCount={Math.ceil(totalCount / 10)}
                  />
                </div>
              )}
            </div>
          </div>
        </Container>
      </div>

      {/** MODAL STARTS */}
      <CustomModal show={show} handleClose={handleClose} title="Consumption Stats">
        <Table className="custom-table-data" responsive striped bordered hover>
          <Placeholder animation="wave" as={'thead'}>
            <Placeholder xs={6} />
          </Placeholder>
          <thead>
            <tr>
              <th>Statistics</th>
              <th>Allocated</th>
              <th>Consumed</th>
              <th>Credit Left</th>
              <th>Billable</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Email</td>
              <td>{emailStat?.allocated}</td>
              <td>{emailStat?.consumed}</td>
              <td>{emailStat?.credit_left}</td>
              <td>{emailStat?.billable}</td>
            </tr>
            <tr>
              <td>SMS</td>
              <td>{smsStat?.allocated}</td>
              <td>{smsStat?.consumed}</td>
              <td>{smsStat?.credit_left}</td>
              <td>{smsStat?.billable}</td>
            </tr>
          </tbody>
        </Table>
      </CustomModal>
      {/** MODAL ENDS */}

      {/** LOADER */}
      {loading && <Loader />}
    </>
  );
};

export default ManageCommunicationsLogs;
