import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { useEffect, useState } from 'react';
import {
  Button,
  CloseButton,
  Col,
  Container,
  Dropdown,
  Modal,
  Row,
  TabPane
} from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import Select from 'react-select';
import { imageExtension, showBirthMonth, truncate } from '../../../globals/helper';
import useDocumentTitle from '../../../globals/useDocumentTitle';
import { loginInfo } from '../../../redux/action';
import * as AuthServices from '../../../services/AuthServices';
import { checkLogin } from '../../../services/AuthServices';
import { getTopicList } from '../../../services/MentorArticlesServices';
import showNotification from '../../../services/NotificationService';
import * as APISERVICES from '../../../services/ProfileServices';
import * as APISERVICE from '../../../services/UserServices';
import HeaderLogin from '../../commoncomponents/HeaderLogin';
import GroupDiscover from '../../commoncomponents/newsfeeds/GroupDiscover';
import SuggestMentor from '../../commoncomponents/newsfeeds/SuggestMentor';
import Upcoming from '../../commoncomponents/newsfeeds/Upcoming';
import WhatsNew from '../../commoncomponents/newsfeeds/WhatsNew';
import EducationForm from '../../commoncomponents/profile/EducationForm';
import WorkForm from '../../commoncomponents/profile/WorkForm';
import ProfileRecentUpdate from './ProfileRecentUpdate';

import $ from 'jquery';
import 'jquery-ui-bundle';
import 'jquery-ui-bundle/jquery-ui.css';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { NOT_FOUND_TEXT } from '../../../globals/constants';
import { unFollowCompany } from '../../../services/CompanyServices';
import { MiniFooter } from '../../MiniFooter';
import HeaderInner from '../../commoncomponents/HeaderInner';
import NoDataFound from '../../commoncomponents/NoDataFound';
import Loader from '../../commoncomponents/loader/loader-large';
import TopTabbings from './TopTabbings';

function MyProfile() {
  useDocumentTitle('AlmaBay');
  const wall_user_id = useParams()?.wall_user_id;
  const [isClearable, setIsClearable] = useState(true);
  const [isSearchable, setIsSearchable] = useState(true);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRtl, setIsRtl] = useState(false);
  const [checkProfile, setCheckProfile] = useState('');
  const [imagePreview, setImagePreview] = useState(false);
  const [companyList, setCompanyList] = useState([]);
  const [articleList, setArticleList] = useState([]);
  const [profileFollowing, setProfileFollowing] = useState([]);
  const [showSummary, setShowSummary] = useState(true);
  const [summary, setSummary] = useState('');
  const [addExpertise, setAddExpertise] = useState([]);
  const [showExpertise, setShowExpertise] = useState(false);
  const [tagField, setTagField] = useState([]);
  const [selectedTagField, setSelectedTagField] = useState([]);
  const [selectedKeenTag, setSelectedKeenTag] = useState([]);
  const [showKeenLearn, setShowKeenLearn] = useState(false);
  const [isEditBackground, setIsEditBackground] = useState(false);
  const [loading, setLoading] = useState(false);
  const [timelineId, setTimelineId] = useState(0);
  const [mentorProfilePic, setMentorProfilePic] = useState('');
  const [mentorsData, setMentorsData] = useState([]);
  const isloggedInData = useSelector((state) => state?.user);
  const isloggedIn = useSelector((state) => state?.user?.isloggedIn);
  const [showLoading, setShowLoading] = useState(true);
  const [showLoadMore, setShowLoadMore] = useState(true);
  const [showLoadingResult, setShowLoadingResult] = useState(false);
  const [offsetVal, setOffsetVal] = useState(0);
  const [removing, setRemoving] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showMore, setShowMore] = useState(false);
  const [showMore1, setShowMore1] = useState(false);
  const [showMoreSummary, setShowMoreSummary] = useState(false);
  const [pageNum, setPageNum] = useState(1);
  const [showCompanyButton, setShowCompanyButton] = useState(true);
  const [showFollowerButton, setShowFollowerButton] = useState(true);
  const [offset, setOffSet] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [showMessagePopup, setShowMessagePopup] = useState(false);

  useEffect(() => {
    // current login user id
    setTimelineId(isloggedInData?.details?.id);

    isMentorCompanyList();
    // isMentorshipArticle();

    $(document).ready(function () {
      var window_width = $(window).width();
      $('.cover-wrapper').height(window_width * 0.23);
      $('.blank_div').height(window_width * 0.15);
      $('.cover-bg-controls').css('margin-top', window_width * 0.06 + 'px');
    });
  }, []);

  useEffect(() => {
    isMentorshipProfile();
    checkProfileFollowing();
    getMentorsDirectoryList();
  }, []);

  const topicsOptions = [
    { id: 1, value: 'Academic advising', label: 'Academic advising' },
    { id: 2, value: 'Performance Arts', label: 'Performance Arts' },
    { id: 3, value: 'Brief therapy', label: 'Brief therapy' },
    { id: 4, value: 'Career coaching', label: 'Career coaching' },
    { id: 5, value: 'Christian counseling', label: 'Christian counseling' }
  ];

  const skillOptions = [
    { id: 1, value: 'Everyone', label: 'Everyone' },
    { id: 2, value: 'Only Me', label: 'Only Me' },
    { id: 3, value: 'Friends', label: 'Friends' },
    { id: 4, value: 'Mentors', label: 'Mentors' },
    { id: 5, value: 'Mentees', label: 'Mentees' }
  ];

  const Expertiseptions = [
    { id: 1, value: 'Academic advising', label: 'Academic advising' },
    { id: 2, value: 'Co-coaching', label: 'Co-coaching' },
    { id: 3, value: 'Performance Arts', label: 'Performance Arts' },
    { id: 4, value: 'Brief therapy', label: 'Brief therapy' },
    { id: 5, value: 'Christian counseling', label: 'Christian counseling' }
  ];

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  /**
   *   'articles' => $articles,
       'educationInfo' => $educationInfo,
       'professionalInfo' => $professionalInfo,
       'mentorshipAreas' => $mentorshipAreas,
       'mentorMenteeRelation' => $mentorMenteeRelationStatus,
       'loggedInUser' => $uid,
   */

  const isMentorshipProfile = () => {
    setLoading(true);

    APISERVICES.getMentorshipProfile(wall_user_id).then((res) => {
      setLoading(false);

      if (res?.status === 200) {
        let arr = [];
        let arr1 = [];

        setCheckProfile(res?.data);
        setArticleList(res?.data?.articles);
        // Mentor Avatar
        setMentorProfilePic(res?.data?.mentorInfo?.user_image);

        setSummary(res?.data?.summary);
        if (res?.data?.mentorshipAreas?.provide_mentorship_in) {
          res?.data?.mentorshipAreas?.provide_mentorship_in.map((data, index) => {
            arr.push({ id: data?.id, value: data?.text, label: data?.text });
          });
        }
        setSelectedTagField(arr);

        if (res?.data?.mentorshipAreas?.seek_mentorship_in) {
          res?.data?.mentorshipAreas?.seek_mentorship_in.map((data, index) => {
            arr1.push({ id: data?.id, value: data?.text, label: data?.text });
          });
        }
        setSelectedKeenTag(arr1);
      }
    });

    getTopicList().then((res) => {
      if (res?.status === 200) {
        let arr = [];
        res?.data?.data?.map((data) => {
          addExpertise.push(data?.id);
          arr.push({ id: data?.id, value: data?.name, label: data?.name });
        });
        setAddExpertise(addExpertise);
        setTagField(arr);
      } else {
        let arr1 = [];
        res?.data?.data?.map((data) => {
          addExpertise.push(data?.id);
          arr1.push({ id: data?.id, value: data?.name, label: data?.name });
        });
        setAddExpertise(addExpertise);
        setTagField(arr1);
      }
    });
  };

  /**
   * Following
   *
   * @param {*} offset
   * @param {*} limit
   * @param {*} userid
   *
   */

  const getMentorsDirectoryList = (offsetValue) => {
    setLoading(true);
    APISERVICES.getMentorsDirectoryList(offsetValue ? offsetValue : offset, 6, wall_user_id).then(
      (res) => {
        setLoading(false);
        setShowLoading(false);
        if (res?.status === 200) {
          if (offsetValue) {
            setMentorsData([...mentorsData, ...res?.data?.detail]);
          } else {
            setMentorsData(res?.data?.detail);
          }

          if (res?.data?.detail?.length < 6) {
            setShowFollowerButton(false);
            isMentorshipProfile();
            checkProfileFollowing();
          }
        }
      }
    );
  };

  const resquestHandler = (e, id, type) => {
    e.preventDefault();
    if (type == 'follow') {
      let body = {
        following_id: id
      };
      APISERVICE.followFriend(body).then((res) => {
        if (res?.status === 200) {
          isMentorshipProfile();
        } else {
          showNotification('success', res?.data?.message);
        }
      });
    } else {
      var r = confirm('Do you want to remove Mentor?');
      if (r) {
        let body = {
          user_id: id
        };
        APISERVICE.unfollowMentor(body).then((res) => {
          if (res?.status === 200) {
            isMentorshipProfile();
            checkProfileFollowing();
            getMentorsDirectoryList();
          } else {
            showNotification('success', res?.data?.message);
          }
        });
      }
    }
  };

  const isMentorCompanyList = (page) => {
    APISERVICES.getMentorshipFollowCompany(page ? page : pageNum, wall_user_id).then((res) => {
      if (res?.status === 200) {
        if (page > 1) {
          setCompanyList([...companyList, ...res?.data?.data] ?? []);
        } else {
          setCompanyList(res?.data?.data ?? []);
        }
        setTotalCount(res?.data?.pager?.total);
        // if (res?.data?.pager?.pageCount == page) {
        //   setShowCompanyButton(false);
        // }
      } else {
        setShowCompanyButton(false);
        setCompanyList([]);
      }
    });
  };

  // const isMentorshipArticle = () => {
  //   APISERVICES.getMentorshipArticleList().then((res) => {
  //     if (res?.status === 200) {
  //       setArticleList(res?.data?.data);
  //     }
  //   });
  // };

  const imageChangeHandler = (e) => {
    let file = e?.target?.files[0];
      let minfileSize =100000 // 100kb
    if (imageExtension.includes(file.type)) {
      if (file.size > minfileSize) {
        showNotification('danger', `File size should not be greater than 100 kb`);
      }
      else{
       
        var url = '/ajax/socialAjax?t=avatar&a=new';
        setLoading(true);

      //var formData = new FormData($('.cover-form')[0]);
     
      let formData = new FormData();
      formData.append('image', file);
      formData.append('timeline_id', timelineId);

      AuthServices.callAjaxGetMethod(url, formData, true).then((responseText) => {
        setLoading(false);

        if (responseText?.status === 200) {
          setMentorProfilePic(responseText?.data?.data);
        }
      });

      }
      
    } else {
      showNotification('danger', 'invalid format !');
    }
  };

  const splitDate = (data, index) => {
    let date = data?.split(' ');
    let arr = date[0]?.split('-');

    return arr[index];
  };

  const checkProfileFollowing = () => {
    APISERVICES.getMentorshipUserFollowing().then((res) => {
      if (res?.status === 200) {
        setProfileFollowing(res?.data?.data);
        // resquestHandler();
        isMentorshipProfile();
      }
    });
  };

  const mentorshipSummary = (e) => {
    e?.preventDefault();
    APISERVICES.postMentorshipProfileSummary({ about: summary }).then((res) => {
      if (res?.status === 200) {
        showNotification('success', res?.data?.data);
        setShowSummary(true);
        setSummary('');
        isMentorshipProfile();
      }
    });
  };

  const mentorshipUpdateExpertise = (e) => {
    e.preventDefault();
    let arr = [];
    selectedTagField?.length &&
      selectedTagField?.map((data) => {
        arr.push(data?.id);
      });
    APISERVICES.postMentorshipExpertise({ provide_mentorship_in: arr.join() }).then((res) => {
      if (res?.status === 200) {
        showNotification('success', res?.data?.data);
        checkUserToken();
        setAddExpertise('');
        isMentorshipProfile();
        setShowExpertise(false);
      }
    });
  };

  const mentorshipUpdateKeenLearn = (e) => {
    e.preventDefault();
    let arr1 = [];
    selectedKeenTag?.length &&
      selectedKeenTag?.map((data) => {
        arr1.push(data?.id);
      });
    APISERVICES.postMentorshipKeenToLearn({ seek_mentorship_in: arr1.join() }).then((res) => {
      if (res?.status === 200) {
        checkUserToken();
        showNotification('success', res?.data?.data);
        setAddExpertise('');
        isMentorshipProfile();
        setShowKeenLearn(false);
      }
    });
  };

  const checkUserToken = () => {
    checkLogin().then((res) => {
      if (res?.status === 200) {
        //save data on redux
        dispatch(loginInfo(res?.data?.data));
      }
    });
  };

  const hadleNavigate = (e) => {
    e.preventDefault();
    navigate(`/mentors/questions/${wall_user_id}`, { state: true });
  };

  // BG COVER

  function SK_coverOptions() {
    var cover_wrapper = $('.cover-wrapper');
    var cover_position_input = $('.cover-position');
    var cover_image = cover_wrapper.find('img');

    cover_wrapper.addClass('edit');
    cover_wrapper.find('.cover-bg-upload').addClass('hide');
    cover_wrapper.find('.cover-bg-controls-edit').removeClass('hide');
    cover_image.draggable({
      scroll: false,
      axis: 'y',
      cursor: 's-resize',
      drag: function (event, ui) {
        var y1 = cover_wrapper.height();
        var y2 = cover_wrapper.find('img').height();
        if (ui.position.top >= 0) {
          ui.position.top = 0;
        } else if (ui.position.top <= y1 - y2) {
          ui.position.top = y1 - y2;
        }
      },
      stop: function (event, ui) {
        $('.cover-position').val(ui.position.top);
        cover_image.css('top', ui.position.top);
      }
    });
  }

  const SK_changeCover = (upload_field) => {
    var exts = ['jpg', 'jpeg', 'png'];

    upload_field = upload_field.target;

    if (upload_field.files[0]) {
      if (!imageExtension.includes(upload_field.files[0].type)) {
        showNotification('danger', 'Please use PNG or JPG types of images');
        return false;
      }

      //var get_ext = file.split('.');
      //get_ext = get_ext.reverse();

      // if ($.inArray(get_ext[0].toLowerCase(), exts) < 0) {
      //   toastr.error("Please use PNG or JPG types of images");
      //   return false;
      // }

      if (upload_field.files && upload_field.files[0]) {
        var reader = new FileReader();

        reader.onload = function (e) {
          //$('.cover-wrapper').find('img').attr('src', e.target.result).css('top', '0px');
          $('.cover-wrapper').find('img').attr('src', e.target.result);
        };

        reader.readAsDataURL(upload_field.files[0]);
      }
    }
  };

  const SK_saveCover = () => {
    let upload_field = $('.cover-image-input').val();
    let old_position = $('.cover-bg-position-backup').val();
    let new_position = $('.cover-position').val();

    if (upload_field != '' || new_position != old_position) {
      //$('form.cover-form').submit();
      var url = '/ajax/socialAjax?t=cover&a=change_mentor_cover';

      var formData = new FormData($('.cover-form')[0]);

      AuthServices.callAjaxGetMethod(url, formData, true).then((responseText) => {
        if (responseText?.status === 200) {
          isMentorshipProfile();
          $('.cover-wrapper .cover-progress').fadeOut('fast', function () {
            $(this).addClass('hide').html('').css('display', '');
          });

          if ($('.cover-image-input').val() != '') {
            $('.cover-bg-src-backup').val(
              responseText.mentor_cover_url + '?' + new Date().getTime()
            );
          }

          $('.cover-bg-position-backup').val(responseText.mentor_cover_position);
          $('.coverbg_id').val(responseText.mentor_cover_id);
          $('.cover-bg-cancel-button').trigger('click');
        }
      });
    } else {
      $('.cover-bg-cancel-button').trigger('click');
    }
  };

  const [coverRemoved, setCoverRemoved] = useState(false);

  const SK_removeCover = () => {
    var cover_id = $('.coverbg_id').val();

    if (cover_id == 0) {
      return false;
    }

    var url = '/ajax/socialAjax?t=cover&a=remove_mentor_cover';
    setLoading(true);

    var formData = new FormData($('.cover-form')[0]);

    AuthServices.callAjaxGetMethod(url, formData, true).then((responseText) => {
      setLoading(false);

      if (responseText?.status == 200) {
        setCoverRemoved(true);
        $('.coverbg_id').val(responseText.mentor_cover_id);
        $('.cover-bg-src-backup').val(responseText.mentor_cover_url + '?' + new Date().getTime());
        $('.cover-bg-position-backup').val(responseText.mentor_cover_position);
        $('.cover-bg-cancel-button').trigger('click');
      }
    });
  };

  const workedTime = (data) => {
    var from_date = new Date(data.year_from + '-' + data.month_from);
    if (data.currently_working_here == '1' || data.year_to == '0') {
      var end_date = new Date();
    } else {
      end_date = new Date(data.year_to + '-' + data.month_to);
    }
    var diff_date = new Date(end_date - from_date);
    var no_of_years = diff_date.toISOString().slice(0, 4) - 1970;
    var no_of_months = diff_date.getMonth();
    var no_of_days = diff_date.getDate();
    if (no_of_years > 0 || no_of_months > 0) {
      var text = '(' + no_of_years + ' ';
      text += no_of_years > 1 ? 'years ' : 'year ';
      text += no_of_months;
      text += no_of_months > 1 ? ' months)' : ' month)';
      return text;
    } else {
      return '';
    }
  };

  const handleLoadmoreCompany = () => {
    setPageNum(pageNum + 1);
    isMentorCompanyList(pageNum + 1);
  };
  const handleLoadMore = () => {
    // setIsScrollable(true);
    setOffSet(offset + 6);
    getMentorsDirectoryList(offset + 6);
  };

  const sendMessageWithOutlogin = (e) => {
    e.preventDefault();
    setShowMessagePopup(true);
  };

  const handleUnfollowCompany = (e, id) => {
    e.preventDefault();
    const confirm = window.confirm('Do you want unfollow this company?');
    const body = {
      following_id: id,
      type: 'company'
    };
    if (confirm === true) {
      unFollowCompany(body).then((res) => {
        if (res?.status === 200) {
          isMentorCompanyList();
        }
      });
    }
  };
  const [key, setKey] = useState('first');
  return (
    <>
      {!isloggedIn ? <HeaderInner /> : <HeaderLogin />}
      {isloggedIn ? (
        <div className="header-lnk-feeds">
          <div className="container">
            {' '}
            <TopTabbings state={'social'} />{' '}
          </div>
        </div>
      ) : (
        ''
      )}

      <section className="profile-cover-image cover-wrapper cover-bg-images" id="cover-background">
        {coverRemoved ? (
          <img
            className="cover-bg-image"
            src={require('../../../assets/images/default-mentor-cover.jpg')}
            alt="img"
          />
        ) : (
          <img
            className="cover-bg-image"
            src={checkProfile?.mentorInfo?.mentor_cover_url}
            alt="cover"
            style={{ top: checkProfile?.mentorInfo?.mentor_cover_position + 'px' }}
          />
        )}

        <input
          type="hidden"
          className="cover-bg-src-backup"
          value={checkProfile?.mentorInfo?.mentor_cover_url}
        />
        <input
          type="hidden"
          className="cover-bg-position-backup"
          value={checkProfile?.mentorInfo?.mentor_cover_position}
        />
        <input
          type="hidden"
          className="coverbg_id"
          value={checkProfile?.mentorInfo?.mentor_cover_id}
        />
        <div className="cover-progress hide"></div>

        {isloggedIn && isloggedInData?.details?.id == checkProfile?.mentorInfo?.id ? (
          isEditBackground ? (
            <>
              <form className="cover-form hidden" method="post" encType="multipart/form-data">
                <input
                  className="cover-image-input hidden"
                  id="coverImageFile"
                  type="file"
                  name="image"
                  accept="image/jpeg,image/png"
                  onChange={SK_changeCover}
                />

                <input
                  className="cover-position"
                  name="position"
                  value={checkProfile?.mentorInfo?.mentor_cover_position}
                  type="hidden"
                />
                <input name="timeline_id" value={timelineId} type="hidden" />
                <input
                  name="MentorCoverId"
                  value={checkProfile?.mentorInfo?.mentor_cover_id}
                  type="hidden"
                />
              </form>

              <span className="prifile-change-btn">
                <div className="cover-bg-controls-edit">
                  <button
                    className="cover-bg-cancel-button"
                    title="Cancel"
                    type="button"
                    onClick={() => {
                      setIsEditBackground(false);
                      $('.cover-wrapper')
                        .find('img')
                        .attr('src', checkProfile?.mentorInfo?.mentor_cover_url);
                    }}
                  >
                    <i className="fas fa-times" aria-hidden="true"></i>
                  </button>
                  <span
                    role="none"
                    className="cover-bg-change-button"
                    onClick={() => SK_removeCover()}
                  >
                    Remove Image
                  </span>
                  <label htmlFor="coverImageFile" className="cover-bg-change-button">
                    Change Image
                  </label>
                  <span
                    role="none"
                    className="cover-bg-save-button"
                    type="button"
                    onClick={() => SK_saveCover()}
                  >
                    Save
                  </span>
                </div>
              </span>
            </>
          ) : (
            <Link
              title="Use of JPG and PNG types of images"
              to="#"
              role="none"
              className="prifile-change-btn"
              onClick={() => {
                setIsEditBackground(true);
                SK_coverOptions();
              }}
            >
              <i className="fas fa-camera"></i> Edit Background
            </Link>
          )
        ) : (
          <></>
        )}
      </section>
      <section className="news-feed-sec bg-grey">
        <Container>
          <div className="my-profile-outr">
            <Row>
              <Col xs={12} lg={9} md={12}>
                <div className="my-profile-wall">
                  <div className="mypro-top bg-white d-flex">
                    <div className="mypro-icn">
                      {imagePreview ? (
                        <img src={imagePreview} alt="img" />
                      ) : (
                        <>
                          {mentorProfilePic && mentorProfilePic != '' ? (
                            <img src={mentorProfilePic} alt="img" />
                          ) : (
                            <img
                              src={require('../../../assets/images/default-male-avatar.png')}
                              alt="img"
                            />
                          )}
                        </>
                      )}

                      {isloggedIn && isloggedInData?.details?.id == checkProfile?.mentorInfo?.id ? (
                        <div className="profile-upload-card">
                          <Form.Control
                            type="file"
                            onChange={(e) => {
                              imageChangeHandler(e);
                            }}
                          />
                          <i className="fas fa-camera"></i>
                          Update Profile Picture
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                    <div className="mypro-rt-dis d-flex align-items-top justify-content-between p-4">
                      <div className="mypro-lft-inr">
                        <h3 title={`${checkProfile?.mentorInfo?.name}`}>
                          {truncate(checkProfile?.mentorInfo?.name, 20)}
                        </h3>

                        {checkProfile?.professionalInfo &&
                        checkProfile?.professionalInfo?.length > 0 ? (
                          <p>
                            {checkProfile?.professionalInfo
                              ? checkProfile?.professionalInfo[0]?.designation +
                                ' at ' +
                                checkProfile?.professionalInfo[0]?.organisation
                              : NOT_FOUND_TEXT}
                          </p>
                        ) : (
                          ''
                        )}

                        <ul className="prof-detail-lst">
                          <li
                            title={
                              checkProfile?.educationInfo
                                ? checkProfile?.educationInfo[0]?.institute_name
                                : NOT_FOUND_TEXT
                            }
                          >
                            <span>Education</span>
                            {checkProfile?.educationInfo
                              ? truncate(checkProfile?.educationInfo[0]?.institute_name, 40)
                              : NOT_FOUND_TEXT}
                          </li>

                          <li
                            title={
                              checkProfile?.professionalInfo
                                ? checkProfile?.professionalInfo[0]?.organisation
                                : NOT_FOUND_TEXT
                            }
                          >
                            <span>Current</span>
                            {checkProfile?.professionalInfo &&
                            checkProfile?.professionalInfo?.length > 0
                              ? truncate(checkProfile?.professionalInfo[0]?.organisation, 40)
                              : NOT_FOUND_TEXT}
                          </li>

                          <li title={checkProfile?.mentorInfo?.website}>
                            <span>Website</span>
                            <a
                              target="_blank"
                              href={checkProfile?.mentorInfo?.website}
                              rel="noreferrer"
                            >
                              {checkProfile?.mentorInfo?.website
                                ? checkProfile?.mentorInfo?.website
                                : NOT_FOUND_TEXT}
                            </a>
                          </li>

                          <li>
                            {isloggedIn &&
                            isloggedInData &&
                            isloggedInData?.details?.id != checkProfile?.mentorInfo?.id ? (
                              <>
                                {checkProfile?.mentorMenteeRelation &&
                                checkProfile?.mentorMenteeRelation.length > 0 &&
                                checkProfile?.mentorMenteeRelation[0]?.active == 1 ? (
                                  <div className="job-fair-bottom">
                                    <Dropdown className="mt-2">
                                      <Dropdown.Toggle variant="success" id="dropdown-basic">
                                        {removing ? (
                                          <i className="fa fa-spinner fa-spin"></i>
                                        ) : (
                                          <i className="fa fa-check me-2"></i>
                                        )}
                                        Following
                                      </Dropdown.Toggle>

                                      <Dropdown.Menu>
                                        <Dropdown.Item onClick={(e) => hadleNavigate(e)}>
                                          Send Message
                                        </Dropdown.Item>
                                        <Dropdown.Item href="#/action-2">
                                          Ask Question
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          href="#/action-3"
                                          onClick={(e) =>
                                            resquestHandler(
                                              e,
                                              checkProfile?.mentorInfo?.id,
                                              'unfollow'
                                            )
                                          }
                                        >
                                          Unfollow Mentor
                                        </Dropdown.Item>
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </div>
                                ) : (
                                  <>
                                    {' '}
                                    <button
                                      className="wht-btn"
                                      type="button"
                                      onClick={(e) =>
                                        resquestHandler(e, checkProfile?.mentorInfo?.id, 'follow')
                                      }
                                    >
                                      <span className="btn-select-value">
                                        {removing ? (
                                          <i className="fa fa-spinner fa-spin"></i>
                                        ) : (
                                          <></>
                                        )}
                                        Follow Mentor
                                      </span>
                                      <span className="btn-select-arrow fa fa-plus"></span>
                                    </button>
                                  </>
                                )}
                              </>
                            ) : (
                              <>
                                {!isloggedIn ? (
                                  <>
                                    <div className="profile-follow-know">
                                      <Link
                                        className="follow-mentor-btn me-3"
                                        to="/login"
                                        state={`/mentors/profile/` + wall_user_id}
                                        // to={'/login?url=' + base64_encode(uri_string())}
                                      >
                                        Follow Mentor
                                        <i className="fas fa-plus"></i>
                                      </Link>

                                      <div
                                        className="col-md-4"
                                        title={checkProfile?.mentorInfo?.name}
                                      >
                                        <div className="know-mentor">
                                          Know {checkProfile?.mentorInfo?.first_name}
                                        </div>
                                        <Link
                                          to="#"
                                          mentor={checkProfile?.mentorInfo?.id}
                                          onClick={(e) => sendMessageWithOutlogin(e)}
                                          className="send-message"
                                        >
                                          Send Message
                                        </Link>
                                      </div>
                                    </div>
                                  </>
                                ) : (
                                  <></>
                                )}
                              </>
                            )}
                          </li>
                        </ul>
                      </div>
                      <div className="mypro-rt-list">
                        <ul>
                          <li>
                            {timelineId == checkProfile?.mentorInfo?.id ? (
                              <Link to="/user/mentees" target={'_blank'}>
                                {checkProfile?.mentorInfo?.followers}
                                <span>Followers</span>
                              </Link>
                            ) : (
                              <Link to={`javascript:void(0)`}>
                                {checkProfile?.mentorInfo?.followers}
                                <span>Followers</span>
                              </Link>
                            )}
                          </li>
                          <li>
                            {timelineId == checkProfile?.mentorInfo?.id ? (
                              <Link to="/user/mentors" target={'_blank'}>
                                {checkProfile?.mentorInfo?.followings}
                                <span>Followings</span>
                              </Link>
                            ) : (
                              <Link to={`javascript:void(0)`}>
                                {checkProfile?.mentorInfo?.followings}
                                <span>Followings</span>
                              </Link>
                            )}
                          </li>
                          <li>
                            <Link
                              to={'/mentors/answers/' + checkProfile?.mentorInfo?.encrypted_id}
                              target={'_blank'}
                            >
                              {checkProfile?.mentorInfo?.answers}
                              <span>Answers</span>
                            </Link>
                          </li>
                          <li>
                            <Link to={`javascript:void(0)`}>
                              {checkProfile?.mentorInfo?.views}
                              <span>Views</span>
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="profile-action">
                    <ul>
                      {isloggedIn &&
                        isloggedInData?.details?.id !== checkProfile?.mentorInfo?.id && (
                          <>
                            {' '}
                            <li>
                              <Link
                                to={`/mentors/profile/${isloggedInData?.details?.encrypted_id}`}
                                target="_blank"
                              >
                                My Profile
                              </Link>
                            </li>
                            <li>
                              <Link to="/user/myArticles" target="_blank">
                                My Articles
                              </Link>
                            </li>
                          </>
                        )}

                      <li>
                        <Link to={isloggedIn ? '/user/writeArticle' : '/login'} target="_blank">
                          Write an Article
                        </Link>
                      </li>
                      <li>
                        <Link to="" target="_blank" onClick={(e) => hadleNavigate(e)}>
                          Questions
                        </Link>
                      </li>
                    </ul>
                  </div>
                  {/* Article cards */}
                  {articleList && articleList.length > 0 && (
                    <div className="article-card bg-white p-4">
                      <div className="article-hd mb-4 d-flex align-items-center justify-content-between">
                        <h4>Article</h4>

                        <Link
                          to={'/mentors/articles/' + checkProfile?.mentorInfo?.encrypted_id}
                          target="_blank"
                        >
                          See More
                        </Link>
                      </div>

                      {articleList &&
                        articleList?.slice(0, 3)?.map((data, index) => (
                          <div
                            className="article-detail-card d-flex align-items-center justify-content-between mb-4"
                            key={index}
                          >
                            <Link to={'/user/articleComment/' + data.id} target="_blank">
                              <div className="article-lft d-flex align-items-top">
                                <div className="custom-calender">
                                  <span>{moment(data?.added_on).format('MMMM')}</span>
                                  <span>{splitDate(data?.added_on, 2)}</span>
                                  <span>{splitDate(data?.added_on, 0)}</span>
                                </div>
                                <div className="article-lft-dis">
                                  <h4>{truncate(data?.title, 50)}</h4>
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: truncate(data?.description, 50)
                                    }}
                                  />
                                </div>
                              </div>
                            </Link>
                            <div className="article-rt-btn">
                              <Link to={'/user/articleComment/' + data.id} target="_blank">
                                <i className="far fa-share-square"></i>
                              </Link>
                            </div>
                          </div>
                        ))}
                    </div>
                  )}

                  {mentorsData?.length !== 0 && (
                    <div className="following-card bg-white p-4 mt-4">
                      <div className="article-hd mb-3 d-flex align-items-center justify-content-between">
                        <h4>Following</h4>
                      </div>

                      <div className="follow-lst-card">
                        {mentorsData?.length > 0 &&
                          mentorsData?.map((g, index) => {
                            return (
                              <div key={index} className="follow-card">
                                <Link
                                  to={'/mentors/profile/' + g?.encrypted_id}
                                  onClick={(e) => e.preventDefault()}
                                  className="follow-card-icn me-3"
                                >
                                  {g?.smedia_model_image ? (
                                    <img
                                      src={g?.smedia_model_image[0]?.path}
                                      className="media-object"
                                      alt=""
                                    />
                                  ) : (
                                    <img
                                      src={require('../../../assets/images/default-male-avatar.png')}
                                      alt="img"
                                    />
                                  )}
                                </Link>

                                <div className="follow-card-dis">
                                  <h4>
                                    <Link
                                      to={'/mentors/profile/' + g?.encrypted_id}
                                      target="_blank"
                                      title={g?.name}
                                    >
                                      {g?.name}
                                    </Link>
                                  </h4>

                                  <p id={'followMentor-' + g?.id}>
                                    {g?.count} {g?.count > 1 ? 'Followers' : 'Follower'}
                                  </p>

                                  {isloggedInData?.details ? (
                                    <>
                                      {g.mentorMenteeRelation ? (
                                        <Link
                                          to="#"
                                          id={'unfollows-' + g.id}
                                          className={'mentor-' + g?.id}
                                          onClick={(e) => resquestHandler(e, g?.id, 'unfollow')}
                                        >
                                          {/* <i className="fa fa-spinner fa-spin hide"></i> */}
                                          Unfollow Mentor
                                        </Link>
                                      ) : (
                                        <Link
                                          to="#"
                                          id={'follows-' + g.id}
                                          className={'mentor-' + g.id}
                                          onClick={(e) => resquestHandler(e, g?.id, 'follow')}
                                        >
                                          {/* <i className="fa fa-spinner fa-spin hide"></i>
                            <i className="fa fa-plus progress-icon"></i> */}

                                          <span className="mentor-text">Follow Mentor</span>
                                        </Link>
                                      )}
                                    </>
                                  ) : (
                                    <>
                                      {g.mentorMenteeRelation ? (
                                        <Link
                                          to="/login"
                                          id={'unfollows-' + g.id}
                                          state={`/mentors/profile/` + wall_user_id}
                                          className={'mentor-' + g.id}
                                          // onClick={(e) => resquestHandler(e, g?.id, 'unfollow')}
                                        >
                                          {/* <i className="fa fa-spinner fa-spin hide"></i> */}
                                          <span className="mentor-text">Unfollow Mentor</span>
                                        </Link>
                                      ) : (
                                        <Link
                                          to="/login"
                                          state={`/mentors/profile/` + wall_user_id}
                                          id={'follows-' + g.id}
                                          className={'mentor-' + g.id}
                                          // onClick={(e) => resquestHandler(e, g?.id, 'follow')}
                                        >
                                          {/* <i className="fa fa-spinner fa-spin hide"></i>
                            <i className="fa fa-plus progress-icon"></i> */}
                                          <span className="mentor-text">Follow Mentor</span>
                                        </Link>
                                      )}
                                    </>
                                  )}
                                </div>
                              </div>
                            );
                          })}
                      </div>
                      {showFollowerButton ? (
                        <div className="load-more-btn text-center">
                          <Button
                            variant="secondary"
                            className="read-more"
                            onClick={handleLoadMore}
                          >
                            Load More
                          </Button>
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  )}

                  {companyList?.length !== 0 && (
                    <div className="following-card bg-white p-4 mt-4">
                      <div className="article-hd mb-3 d-flex align-items-center justify-content-between">
                        <h4>Companies</h4>
                      </div>
                      <div className="follow-lst-card">
                        {companyList &&
                          companyList.map((data, index) => (
                            <div className="follow-card" key={index}>
                              <Link
                                to={`/company/index/${data?.username}`}
                                target="_blank"
                                className="follow-card-icn me-3"
                              >
                                <img
                                  src={process.env.REACT_APP_API_DOMAIN + data?.logo}
                                  alt="img"
                                />
                              </Link>
                              <div className="follow-card-dis">
                                <h4>
                                  <Link
                                    target="_blank"
                                    title={data?.name}
                                    to={`/company/index/${data?.username}`}
                                  >
                                    {data?.name}
                                  </Link>
                                </h4>
                                <p>{data?.followers} Followers</p>
                                <Link onClick={(e) => handleUnfollowCompany(e, data?.timeline_id)}>
                                  Unfollow
                                </Link>
                              </div>
                            </div>
                          ))}
                      </div>
                      <div className="load-more-btn text-center">
                        {totalCount !== companyList?.length ? (
                          <Button
                            variant="secondary"
                            className="read-more"
                            onClick={handleLoadmoreCompany}
                          >
                            Load More
                          </Button>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  )}
                </div>
                <div className="profile-wall-summary mt-4">
                  <Tab.Container
                    id="left-tabs-example"
                    defaultActiveKey="first"
                    onSelect={(k) => setKey(k)}
                  >
                    <div className="mb-4 bg-white">
                      <Nav variant="pills">
                        <Nav.Item className={`${key !== 'first' && 'profile-background'}`}>
                          <Nav.Link eventKey="first">Background</Nav.Link>
                        </Nav.Item>
                        <Nav.Item className={`${key !== 'second' && 'profile-background'}`}>
                          <Nav.Link eventKey="second">Mentorship Area</Nav.Link>
                        </Nav.Item>
                        {isloggedIn ? (
                          <Nav.Item className={`${key !== 'third' && 'profile-background'}`}>
                            <Nav.Link eventKey="third">Recent Updates</Nav.Link>
                          </Nav.Item>
                        ) : (
                          ''
                        )}
                      </Nav>
                    </div>
                    <div>
                      <Tab.Content>
                        <Tab.Pane eventKey="first">
                          {' '}
                          {showSummary ? (
                            <div className="summary-card bd-bottom-org activee">
                              {' '}
                              <div className="summary-card-hd d-flex align-items-center justify-content-between mb-3">
                                <h4>Summary</h4>

                                <div>
                                  {isloggedIn &&
                                  isloggedInData?.details?.id == checkProfile?.mentorInfo?.id ? (
                                    <Link
                                      className="me-2 editt_btn"
                                      to="#"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        setShowSummary(false);
                                      }}
                                    >
                                      <i className="fas fa-pencil-alt"></i>
                                    </Link>
                                  ) : (
                                    <></>
                                  )}
                                </div>
                              </div>
                              {summary != '' ? (
                                <>
                                  {' '}
                                  <div className="summar-dis">
                                    {!showMoreSummary ? (
                                      <div
                                        dangerouslySetInnerHTML={{ __html: truncate(summary, 675) }}
                                      />
                                    ) : (
                                      <div dangerouslySetInnerHTML={{ __html: summary }} />
                                    )}
                                  </div>
                                  {summary?.length > 675 && (
                                    <>
                                      {' '}
                                      {showMoreSummary ? (
                                        <button
                                          className="show_more_profile w-100 border-0"
                                          onClick={() => setShowMoreSummary(false)}
                                        >
                                          Show Less <i className="fas fa-angle-up ms-1"></i>
                                        </button>
                                      ) : (
                                        <button
                                          className="show_more_profile w-100 border-0"
                                          onClick={() => setShowMoreSummary(true)}
                                        >
                                          Show More <i className="fas fa-angle-down ms-1"></i>
                                        </button>
                                      )}
                                    </>
                                  )}
                                  <div className="summry-dis-edit mt-3">
                                    <Form.Group controlId="exampleForm.ControlTextarea1">
                                      <textarea
                                        type="text"
                                        className="w-100"
                                        placeholder="Write here.."
                                        rows="5"
                                        name="about"
                                      ></textarea>
                                    </Form.Group>
                                  </div>
                                </>
                              ) : (
                                <NoDataFound text="No summary avaliable at the moment" />
                              )}
                            </div>
                          ) : (
                            <div className="summary-card bd-bottom-org active">
                              <div className="summary-card-hd d-flex align-items-center justify-content-between mb-3">
                                <h4>Summary</h4>
                                <div>
                                  <Link
                                    to="#"
                                    className="me-2 correct_btn "
                                    onClick={(e) => {
                                      e.preventDefault();
                                      mentorshipSummary();
                                    }}
                                  >
                                    <i className="fas fa-check"></i>
                                  </Link>

                                  <Link
                                    to="javascript:void(0)"
                                    className="me-2 cross_btn"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      setShowSummary(true);
                                    }}
                                  >
                                    <i className="fas fa-times"></i>
                                  </Link>
                                </div>
                              </div>
                              <div className="">
                                <CKEditor
                                  name="description"
                                  editor={ClassicEditor}
                                  data={summary}
                                  onReady={(editor) => {
                                    // You can store the "editor" and use when it is needed.
                                  }}
                                  config={{
                                    toolbar: [
                                      'heading',
                                      'bold',
                                      'italic',
                                      'numberedList',
                                      'bulletedList',
                                      'outdent',
                                      'indent'
                                    ]
                                  }}
                                  onChange={(event, editor) => {
                                    const data = editor.getData();
                                    setSummary(data);
                                  }}
                                  onBlur={(event, editor) => {}}
                                  onFocus={(event, editor) => {}}
                                />
                                {/* <div dangerouslySetInnerHTML={{ __html: checkProfile?.summary }}
                                /> */}
                              </div>
                            </div>
                          )}
                          <div className="experice-card-profile bd-bottom-org">
                            {isloggedIn &&
                            isloggedInData?.details?.id == checkProfile?.mentorInfo?.id ? (
                              <WorkForm myprofie={true} />
                            ) : (
                              <>
                                <div className="experice-card-hd d-flex align-items-center justify-content-between">
                                  <h4>Experience</h4>
                                  {/* <Link to="#">+ Add an Educational Information</Link> */}
                                </div>
                                <div className="work-lst-sec">
                                  {/* <div className="work-lst-overlay">
                                      <Link to="/user/profile/work-details">
                                        <i className="fas fa-trash-alt"></i>
                                      </Link>
                                      <Link to="/user/profile/work-details">
                                        <i className="fas fa-pencil-alt"></i>
                                      </Link>
                                    </div> */}

                                  {showMore1 ? (
                                    <>
                                      {' '}
                                      {checkProfile?.professionalInfo &&
                                      checkProfile?.professionalInfo.length !== 0 ? (
                                        checkProfile?.professionalInfo?.map?.((data, index) => (
                                          <>
                                            <div className="work-lst-sec">
                                              <div className="work-lst-card px-3 py-4">
                                                <div className="m-0 align-items-center row">
                                                  <div className="col-lg-10 col-10">
                                                    <div className="work-lft">
                                                      {data?.designation !== '' && (
                                                        <h4>{data?.designation}</h4>
                                                      )}
                                                      {data.company_page == null ||
                                                      data.company_page == '' ? (
                                                        <h5>{data?.organisation}</h5>
                                                      ) : (
                                                        <Link
                                                          to={`/company/index/${data?.company_page}`}
                                                        >
                                                          {' '}
                                                          <h5>{data?.organisation}</h5>
                                                        </Link>
                                                      )}
                                                      {data?.currently_working_here == '1' ? (
                                                        <h6>
                                                          {data.month_from | data?.monthName},{' '}
                                                          {data.year_from} - PRESENT{' '}
                                                          <span>{workedTime(data)}</span>
                                                          {data.city_name != '' &&
                                                            data.city_name !== null && (
                                                              <span> | {data?.city_name}</span>
                                                            )}
                                                        </h6>
                                                      ) : (
                                                        <h6>
                                                          {' '}
                                                          {showBirthMonth(data?.month_from)},{' '}
                                                          {data.year_from} -{' '}
                                                          {showBirthMonth(data.month_to)},{' '}
                                                          {data.year_to}{' '}
                                                          <span>{workedTime(data)}</span>
                                                          {data.city_name != '' &&
                                                            data.city_name !== null && (
                                                              <span> | {data?.city_name}</span>
                                                            )}
                                                        </h6>
                                                      )}
                                                    </div>
                                                  </div>
                                                  {data?.logo != null && (
                                                    <div className="col-lg-2 col-2">
                                                      <div className="work-icn">
                                                        <img
                                                          className="img-responsive"
                                                          // src={require('../../../assets/images/account-manage-icon.png')}
                                                          src={
                                                            process.env.REACT_APP_API_URL +
                                                            'common/uploads/institute_logo/' +
                                                            data?.logo
                                                          }
                                                          alt="img"
                                                        />
                                                      </div>
                                                    </div>
                                                  )}
                                                </div>
                                              </div>
                                            </div>
                                          </>
                                        ))
                                      ) : (
                                        <>
                                          <NoDataFound text="No Professional details are avaliable at the moment." />
                                        </>
                                      )}
                                    </>
                                  ) : (
                                    <>
                                      {' '}
                                      {checkProfile?.professionalInfo &&
                                      checkProfile?.professionalInfo.length !== 0 ? (
                                        checkProfile?.professionalInfo
                                          ?.slice(0, 2)
                                          ?.map?.((data, index) => (
                                            <>
                                              <div className="work-lst-sec">
                                                <div className="work-lst-card px-3 py-4">
                                                  <div className="m-0 align-items-center row">
                                                    <div className="col-lg-10 col-10">
                                                      <div className="work-lft">
                                                        {data?.designation !== '' && (
                                                          <h4>{data?.designation}</h4>
                                                        )}
                                                        {data.company_page == null ||
                                                        data.company_page == '' ? (
                                                          <h5>{data?.organisation}</h5>
                                                        ) : (
                                                          <Link
                                                            to={`/company/index/${data?.company_page}`}
                                                          >
                                                            {' '}
                                                            <h5>{data?.organisation}</h5>
                                                          </Link>
                                                        )}

                                                        {data?.currently_working_here == '1' ? (
                                                          <h6>
                                                            {data.month_from | data?.monthName},{' '}
                                                            {data.year_from} - PRESENT{' '}
                                                            <span>{workedTime(data)}</span>
                                                            {data.city_name != '' &&
                                                              data.city_name !== null && (
                                                                <span> | {data?.city_name}</span>
                                                              )}
                                                          </h6>
                                                        ) : (
                                                          <h6>
                                                            {' '}
                                                            {showBirthMonth(data?.month_from)},{' '}
                                                            {data.year_from} -{' '}
                                                            {showBirthMonth(data.month_to)},{' '}
                                                            {data.year_to}{' '}
                                                            <span>{workedTime(data)}</span>
                                                            {data.city_name != '' &&
                                                              data.city_name !== null && (
                                                                <span> | {data?.city_name}</span>
                                                              )}
                                                          </h6>
                                                        )}
                                                      </div>
                                                    </div>
                                                    {data?.logo != null && (
                                                      <div className="col-lg-2 col-2">
                                                        <div className="work-icn">
                                                          <img
                                                            className="img-responsive"
                                                            // src={require('../../../assets/images/account-manage-icon.png')}
                                                            src={
                                                              process.env.REACT_APP_API_URL +
                                                              'common/uploads/institute_logo/' +
                                                              data?.logo
                                                            }
                                                            alt="img"
                                                          />
                                                        </div>
                                                      </div>
                                                    )}
                                                  </div>
                                                </div>
                                              </div>
                                            </>
                                          ))
                                      ) : (
                                        <>
                                          {' '}
                                          <NoDataFound text="No Educational details are avaliable at the moment." />
                                        </>
                                      )}
                                    </>
                                  )}
                                  {checkProfile?.professionalInfo?.length > 2 && (
                                    <>
                                      {' '}
                                      {showMore1 ? (
                                        <button
                                          className="show_more_profile w-100 border-0"
                                          onClick={() => setShowMore1(false)}
                                        >
                                          Show Less <i className="fas fa-angle-up ms-1"></i>
                                        </button>
                                      ) : (
                                        <button
                                          className="show_more_profile w-100 border-0"
                                          onClick={() => setShowMore1(true)}
                                        >
                                          Show More <i className="fas fa-angle-down ms-1"></i>
                                        </button>
                                      )}
                                    </>
                                  )}
                                </div>
                              </>
                            )}
                          </div>
                          <div className="experice-card-profile education-card">
                            {isloggedIn &&
                            isloggedInData?.details?.id == checkProfile?.mentorInfo?.id ? (
                              <EducationForm />
                            ) : (
                              <>
                                <div className="experice-card-hd d-flex align-items-center justify-content-between">
                                  <h4>Education</h4>
                                  {/* <Link to="#">+ Add an Educational Information</Link> */}
                                </div>
                                <div className="work-lst-sec">
                                  {/* <div className="work-lst-overlay">
                                      <Link to="/user/profile/work-details">
                                        <i className="fas fa-trash-alt"></i>
                                      </Link>
                                      <Link to="/user/profile/work-details">
                                        <i className="fas fa-pencil-alt"></i>
                                      </Link>
                                    </div> */}

                                  {showMore ? (
                                    <>
                                      {' '}
                                      {checkProfile?.educationInfo &&
                                      checkProfile?.educationInfo.length !== 0 ? (
                                        checkProfile?.educationInfo?.map?.((data, index) => (
                                          <>
                                            <div className="work-lst-card px-3 py-4">
                                              <Row className="m-0 align-items-center">
                                                <Col lg={10} xs={10}>
                                                  <div className="work-lft">
                                                    {data.course_name != '' &&
                                                      data.course_name !== null &&
                                                      data.currently_studing_here == '1' && (
                                                        <h4>
                                                          Currently Studying in
                                                          {data?.course_name}
                                                          {data?.to_year == 0 ? (
                                                            <></>
                                                          ) : (
                                                            <> in {data?.to_year}</>
                                                          )}
                                                        </h4>
                                                      )}
                                                    {data.course_name != '' &&
                                                      data.course_name !== null &&
                                                      data.currently_studing_here != '1' && (
                                                        <h4>
                                                          Studied {data.course_name} in{' '}
                                                          {data.to_year}
                                                        </h4>
                                                      )}
                                                    {data?.company_page == null ||
                                                    data?.company_page == '' ? (
                                                      <h5>{data?.institute_name}</h5>
                                                    ) : (
                                                      <>
                                                        {' '}
                                                        <Link
                                                          to={`/company/index/${data.company_page}`}
                                                          target="_blank"
                                                        >
                                                          {' '}
                                                          <h5>{data?.institute_name}</h5>
                                                        </Link>
                                                      </>
                                                    )}
                                                    <h6>
                                                      {data?.currently_studing_here == '1' ? (
                                                        <span>PRESENT | {data?.city_name}</span>
                                                      ) : (
                                                        <span>
                                                          {data?.to_year} | {data?.city_name}
                                                        </span>
                                                      )}
                                                    </h6>
                                                    {/* <p>{data?.state_name}</p> */}
                                                  </div>
                                                </Col>
                                                <Col lg={2} xs={2}>
                                                  <div className="work-icn">
                                                    <img
                                                      className="img-responsive"
                                                      // src={require('../../../assets/images/account-manage-icon.png')}
                                                      src={
                                                        process.env.REACT_APP_API_URL +
                                                        'common/uploads/institute_logo/' +
                                                        data?.logo
                                                      }
                                                      alt="img"
                                                    />
                                                  </div>
                                                </Col>
                                              </Row>
                                            </div>
                                          </>
                                        ))
                                      ) : (
                                        <>
                                          <NoDataFound text="No Educational details are avaliable at the moment." />
                                        </>
                                      )}
                                    </>
                                  ) : (
                                    <>
                                      {' '}
                                      {checkProfile?.educationInfo &&
                                      checkProfile?.educationInfo.length !== 0 ? (
                                        checkProfile?.educationInfo
                                          ?.slice(0, 2)
                                          ?.map?.((data, index) => (
                                            <>
                                              <div className="work-lst-card px-3 py-4">
                                                <Row className="m-0 align-items-center">
                                                  <Col lg={10} xs={10}>
                                                    <div className="work-lft">
                                                      {data.course_name != '' &&
                                                        data.course_name !== null &&
                                                        data.currently_studing_here == '1' && (
                                                          <h4>
                                                            Currently Studying in
                                                            {data?.course_name}
                                                            {data?.to_year == 0 ? (
                                                              <></>
                                                            ) : (
                                                              <> in {data?.to_year}</>
                                                            )}
                                                          </h4>
                                                        )}
                                                      {data.course_name != '' &&
                                                        data.course_name !== null &&
                                                        data.currently_studing_here != '1' && (
                                                          <h4>
                                                            Studied {data.course_name} in{' '}
                                                            {data.to_year}
                                                          </h4>
                                                        )}
                                                      {data?.company_page == null ||
                                                      data?.company_page == '' ? (
                                                        <h5>{data?.institute_name}</h5>
                                                      ) : (
                                                        <>
                                                          {' '}
                                                          <Link
                                                            to={`/company/index/${data.company_page}`}
                                                            target="_blank"
                                                          >
                                                            {' '}
                                                            <h5>{data?.institute_name}</h5>
                                                          </Link>
                                                        </>
                                                      )}
                                                      <h6>
                                                        {data?.currently_studing_here == '1' ? (
                                                          <span>PRESENT | {data?.city_name}</span>
                                                        ) : (
                                                          <span>
                                                            {data?.to_year} | {data?.city_name}
                                                          </span>
                                                        )}
                                                      </h6>
                                                      {/* <p>{data?.state_name}</p> */}
                                                    </div>
                                                  </Col>
                                                  <Col lg={2} xs={2}>
                                                    <div className="work-icn">
                                                      <img
                                                        className="img-responsive"
                                                        // src={require('../../../assets/images/account-manage-icon.png')}
                                                        src={
                                                          process.env.REACT_APP_API_URL +
                                                          'common/uploads/institute_logo/' +
                                                          data?.logo
                                                        }
                                                        alt="img"
                                                      />
                                                    </div>
                                                  </Col>
                                                </Row>
                                              </div>
                                            </>
                                          ))
                                      ) : (
                                        <>
                                          {' '}
                                          <NoDataFound text="No Educational details are avaliable at the moment." />
                                        </>
                                      )}
                                    </>
                                  )}

                                  {checkProfile?.educationInfo?.length > 2 && (
                                    <>
                                      {' '}
                                      {showMore ? (
                                        <button
                                          className="show_more_profile w-100 border-0"
                                          onClick={() => setShowMore(false)}
                                        >
                                          Show Less <i className="fas fa-angle-up ms-1"></i>
                                        </button>
                                      ) : (
                                        <button
                                          className="show_more_profile w-100 border-0"
                                          onClick={() => setShowMore(true)}
                                        >
                                          Show More <i className="fas fa-angle-down ms-1"></i>
                                        </button>
                                      )}
                                    </>
                                  )}
                                </div>
                              </>
                            )}
                          </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="second">
                          <div className="expertise-card">
                            <div
                              className={`${
                                showExpertise ? 'summary-card active' : 'summary-card'
                              }`}
                            >
                              <div className="summary-card-hd d-flex align-items-center justify-content-between mb-3">
                                <h4>Expertise in</h4>
                                <div>
                                  {isloggedIn &&
                                    isloggedInData?.details?.id == checkProfile?.mentorInfo?.id && (
                                      <>
                                        {' '}
                                        <Link
                                          className="me-2 editt_btn"
                                          to="#"
                                          onClick={(e) => {
                                            e.preventDefault();
                                            setShowExpertise(true);
                                          }}
                                        >
                                          <i className="fas fa-pencil-alt" title="Edit"></i>
                                        </Link>
                                        <Link
                                          to="#"
                                          title="Save"
                                          className="me-2 correct_btn"
                                          onClick={mentorshipUpdateExpertise}
                                        >
                                          <i className="fas fa-check"></i>
                                        </Link>
                                        <Link
                                          to="javascript:void(0)"
                                          onClick={(e) => {
                                            e.preventDefault();
                                            setShowExpertise(false);
                                          }}
                                          className="me-2 cross_btn"
                                        >
                                          <i className="fas fa-times"></i>
                                        </Link>
                                      </>
                                    )}
                                </div>
                              </div>
                              <div className="summar-dis">
                                <div className="summar-add-list">
                                  {/* {expertise_in} */}
                                  <ul>
                                    {checkProfile?.mentorshipAreas?.provide_mentorship_in?.map(
                                      (data, index) => (
                                        <li key={index}>{data?.text}</li>
                                      )
                                    )}
                                  </ul>
                                </div>
                              </div>
                              <div className="summry-dis-edit mt-3">
                                <Select
                                  isMulti
                                  name="tag_fields"
                                  className="basic-multi-select mt-3"
                                  classNamePrefix="select"
                                  options={tagField}
                                  value={selectedTagField}
                                  onChange={(e) => setSelectedTagField(e)}
                                  placeholder="Add areas where you provide mentorship ."
                                />
                              </div>
                            </div>
                            <div
                              className={`${
                                showKeenLearn ? 'summary-card active' : 'summary-card'
                              }`}
                            >
                              <div className="summary-card-hd d-flex align-items-center justify-content-between mb-3">
                                <h4>Keen to learn</h4>
                                <div>
                                  {isloggedIn &&
                                    isloggedInData?.details?.id == checkProfile?.mentorInfo?.id && (
                                      <>
                                        {' '}
                                        <Link
                                          className="me-2 editt_btn"
                                          to="#"
                                          onClick={(e) => {
                                            e.preventDefault();
                                            setShowKeenLearn(true);
                                          }}
                                        >
                                          <i className="fas fa-pencil-alt" title="Edit"></i>
                                        </Link>
                                        <Link
                                          to="#"
                                          title="Save"
                                          className="me-2 correct_btn"
                                          onClick={(e) => {
                                            mentorshipUpdateKeenLearn(e);
                                          }}
                                        >
                                          <i className="fas fa-check"></i>
                                        </Link>
                                        <Link
                                          to="javascript:void(0)"
                                          onClick={(e) => {
                                            e.preventDefault();
                                            setShowKeenLearn(false);
                                          }}
                                          className="me-2 cross_btn"
                                        >
                                          <i className="fas fa-times"></i>
                                        </Link>
                                      </>
                                    )}
                                </div>
                              </div>
                              <div className="summar-dis">
                                <div className="summar-add-list">
                                  <ul>
                                    {checkProfile?.mentorshipAreas?.seek_mentorship_in?.map(
                                      (data, index) => (
                                        <li key={index}>{data?.text}</li>
                                      )
                                    )}
                                  </ul>
                                </div>
                              </div>
                              <div className="summry-dis-edit mt-3">
                                <Select
                                  isMulti
                                  name="tag_fields"
                                  className="basic-multi-select"
                                  classNamePrefix="select"
                                  options={tagField}
                                  value={selectedKeenTag}
                                  onChange={(e) => setSelectedKeenTag(e)}
                                  placeholder="Add areas where you seek mentorship."
                                />
                              </div>
                            </div>
                          </div>
                        </Tab.Pane>
                        <TabPane eventKey="third">
                          <ProfileRecentUpdate />
                        </TabPane>
                      </Tab.Content>
                    </div>
                  </Tab.Container>
                </div>
              </Col>
              {isloggedIn ? (
                <Col xs={12} lg={3} md={12}>
                  <div>
                    <SuggestMentor
                      isMentorshipProfile={isMentorshipProfile}
                      getMentorsDirectoryList={getMentorsDirectoryList}
                    />
                  </div>
                  <div>
                    <GroupDiscover />
                  </div>
                  <div>
                    <WhatsNew />
                  </div>
                  <div>
                    <Upcoming />
                  </div>
                  <MiniFooter />
                </Col>
              ) : (
                ''
              )}
            </Row>
          </div>
        </Container>
      </section>

      {loading ? <Loader /> : ''}

      <Modal show={showMessagePopup}>
        <Modal.Header>
          <div className="d-flex">
            <h5> Send your message to your mentor.</h5>|
            <p>Be specific while sending message and get instant response</p>
          </div>
          <CloseButton onClick={() => setShowMessagePopup(false)} />
        </Modal.Header>
        <Modal.Body>
          You need to login first before sending message to this mentor,{' '}
          <Link to="/login">Click Here</Link>
        </Modal.Body>
        <Modal.Footer>
          {' '}
          <Button variant="primary" type="button" onClick={() => navigate('/login')}>
            Ok
          </Button>
          <Button className="wht-btn" onClick={() => setShowMessagePopup(false)}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default MyProfile;
