//  @copyright : ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
// @author     : Shiv Charan Panjeta

// All Rights Reserved.
// Proprietary and confidential :  All information contained herein is, and remains
// the property of ToXSL Technologies Pvt. Ltd. and its partners.
// Unauthorized copying of this file, via any medium is strictly prohibited.
import { useEffect, useState } from 'react';
import { Button, Container } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { addBanner, getBannerImage, getHeaderList } from '../../services/AuthServices';
import showNotification from '../../services/NotificationService';

export default function AddBanners() {
  const [clicked, setClicked] = useState(false);
  const [bannerFile, setBannerFile] = useState('');
  const [bannerTitle, setBannerTitle] = useState('');
  const [pageType, setPageType] = useState('');
  const [menuOption, setMenuOption] = useState([]);
  const [singlefilePreview, setSingleFilePreview] = useState('');
  const [searchParams] = useSearchParams();
  const id = searchParams.get('id');

  const navigate = useNavigate();

  useEffect(() => {
    if (id) {
      setPageType(id);
      getDetail(id);
    }
    getMenuList();
  }, []);

  const getDetail = (id) => {
    getBannerImage(id).then((res) => {
      if (res?.status == 200) {
        const { name, url } = res?.data?.data ? res.data.data : {};
        setBannerFile(url || '');
        setBannerTitle(name || '');
      }
    });
  };

  const getMenuList = () => {
    getHeaderList().then((res) => {
      if (res?.status === 200) {
        setMenuOption(res?.data?.data);
      }
    });
  };

  const validate = () => {
    let errors = {};
    // if (value == '') {
    //   errors.institutename = 'This field is required';
    // }
    if (bannerTitle == '') {
      errors.banner_title = 'This field is required';
    }
    if (pageType == '') {
      errors.pageType = 'This field is required';
    }
    if (bannerFile == '') {
      errors.bannerFile = 'This field is required';
    }

    return errors;
  };

  const submitHandler = (e) => {
    e.preventDefault();
    setClicked(true);
    if (!Object.keys(validate()).length) {
      let body = {
        banner_image: bannerFile,
        title: bannerTitle,
        type: pageType
      };
      addBanner(body).then((res) => {
        if (res?.status === 200) {
          showNotification('success', res?.data?.message);
          navigate(-1);
        }
      });
    }
  };

  const handleFile = (e) => {
    setBannerFile(e.target.files[0]);
    setSingleFilePreview(URL.createObjectURL(e.target.files[0]));
  };

  return (
    <div className="dash-main-content">
      <Container fluid>
        <div className="dash-page-content">
          <div className="dash-page-title">
            <Row>
              <Col xs={12} sm={12} md={12}>
                <div className="dash-title-bar d-flex align-items-center justify-content-between">
                  <div className="title-lft d-flex align-items-center">
                    <div className="dash-icn">
                      <i className="fas fa-university"></i>
                    </div>
                    <div className="dash-title-dis">
                      <h5>
                        Add <span>Banner</span>
                      </h5>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <div className="dash-content-card">
            <div className="cmmn-form">
              <Form>
                <Row>
                  {' '}
                  {/* <Col md={4}>
                      {' '}
                      <Form.Group className="mb-3" controlId="formBasicName">
                        <Form.Label>
                          Select Institute <sup className="text-danger">*</sup>
                        </Form.Label>
                        {

                        }
                        <AsyncSelect
                          className="basic-single"
                          classNamePrefix="select"
                          isSearchable={true}
                          name="institute_id"
                          value={value}
                          loadOptions={loadInstituteOptions}
                          onChange={(e) => {
                            setValue(e);
                          }}
                          placeholder="Search By Institute Name"
                        />
                        <div
                          className={`${clicked && validate()?.institutename ? '' : 'd-none'
                            } danger-frm `}>
                          {clicked && validate()?.institutename}
                        </div>
                      </Form.Group>
                    </Col> */}
                  <Col md={4}>
                    {' '}
                    <Form.Group className="mb-3" controlId="formBasicState">
                      <Form.Label>
                        Banner Title
                        <sup className="text-danger">*</sup>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="banner_title"
                        value={bannerTitle}
                        onChange={(e) => {
                          setBannerTitle(e.target.value);
                        }}
                        placeholder="Enter banner title"
                      />
                      <div
                        className={`${
                          clicked && validate()?.banner_title ? '' : 'd-none'
                        } danger-frm `}>
                        {clicked && validate()?.banner_title}
                      </div>
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group className="mb-3" controlId="formBasicName">
                      <Form.Label>
                        Page Type <sup className="text-danger">*</sup>
                      </Form.Label>
                      <Form.Select
                        aria-label="Default select example"
                        name="privacy"
                        value={pageType}
                        onChange={(e) => {
                          setPageType(e.target.value);
                        }}>
                        <option value="">Select</option>
                        {/* dynamic  */}
                        {menuOption &&
                          menuOption?.length &&
                          menuOption?.map((data, index) => (
                            <option key={index} value={data?.type_id}>
                              {data?.name}
                            </option>
                          ))}
                      </Form.Select>
                      <div
                        className={`${
                          clicked && validate()?.pageType ? '' : 'd-none'
                        } danger-frm `}>
                        {clicked && validate()?.pageType}
                      </div>
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    {' '}
                    <Form.Group controlId="formFile" className="mb-3">
                      <Form.Label>
                        Attach Banner File <sup className="text-danger">*</sup>
                      </Form.Label>
                      <Form.Control
                        type="file"
                        name="image"
                        accept=".png,.jpg,.jpeg"
                        onChange={(e) => {
                          handleFile(e);
                        }}
                      />
                      <div className={'danger-frm'}>{clicked && validate().bannerFile}</div>
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col xs={2} sm={2} md={2}>
                    <div className=" d-flex">
                      {bannerFile && !singlefilePreview ? (
                        <img
                          height={'100px'}
                          width={'100px'}
                          src={process.env.REACT_APP_API_DOMAIN + bannerFile}
                          alt="img"
                        />
                      ) : bannerFile ? (
                        <img
                          height={'100px'}
                          width={'100px'}
                          src={singlefilePreview}
                          alt="img"
                          className="me-2"
                        />
                      ) : (
                        ''
                      )}
                    </div>
                  </Col>
                </Row>

                <div className="form-btns d-flex align-items-center mt-3">
                  <Button className="main-btn-grn" onClick={submitHandler}>
                    Save
                  </Button>
                  <strong>OR</strong>
                  <Button
                    className="main-btn-red"
                    onClick={() => {
                      navigate(-1);
                    }}>
                    Cancel
                  </Button>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}
