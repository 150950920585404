import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import showNotification from '../../services/NotificationService';
import { addFeedback } from '../../services/AuthServices';
import { emailRegex, restrictAlpha } from '../../globals/helper';
import Loader from './loader/loader-large';

function FooterMain(props) {
  const { className, state } = props;
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [clicked, setClicked] = useState(false);
  const [excludeClass, setExcludeClass] = useState(false);
  useEffect(() => {
    if (location.pathname === '/login') {
      setExcludeClass(true);
    }
  }, [location.pathname]);

  const [addFeedBack, setAddFeedback] = useState({
    name: '',
    email: '',
    subject: 'Share an idea',
    mobile: '',
    message: ''
  });

  const validate = () => {
    let errors = {};
    if (!addFeedBack.name.trim()) {
      errors.name = 'This field is required.';
    }
    if (!addFeedBack.email.trim()) {
      errors.email = 'This field is required.';
    } else if (!emailRegex.test(addFeedBack.email)) {
      errors.email = 'please enter valid email';
    }
    if (!addFeedBack.mobile.trim()) {
      errors.mobile = 'This field is required';
    } else if (addFeedBack.mobile.length <= 9) {
      errors.mobile = 'please enter atleast 10 digit';
    }
    if (!addFeedBack.message.trim()) {
      errors.message = 'This field is required.';
    }
    return errors;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setAddFeedback({ ...addFeedBack, [name]: value });
  };

  const handleSubmit = () => {
    setClicked(true);
    if (!Object.keys(validate()).length) {
      setLoading(true);
      setClicked(false);
      addFeedback(addFeedBack).then((res) => {
        showNotification('success', res?.data?.detail);
        setLoading(false);
        handleClose();
      });
    }
  };

  return (
    <>
      <section className={`${className} footer-card pd-35`}>
        <div className="container">
          <Row>
            <Col xs={12} sm={6} lg={2}>
              <div className="ftr-lnks">
                <ul>
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <Link to="/about-us">About</Link>
                  </li>
                  <li>
                    <Link to="/terms_of_use">Terms of Use</Link>
                  </li>
                </ul>
              </div>
            </Col>
            <Col xs={12} sm={6} lg={2}>
              <div className="ftr-lnks">
                <ul>
                  <li>
                    <Link to="/jobs/search">Browse Jobs</Link>
                  </li>
                  <li>
                    <Link to="/Faq">FAQs</Link>
                  </li>
                  <li>
                    <Link to="/privacy_policy">Privacy Policy</Link>
                  </li>
                </ul>
              </div>
            </Col>
            <Col xs={12} sm={6} lg={2}>
              <div className="ftr-lnks">
                <ul>
                  <li>
                    <Link to="/advancesearch/directory/A">Institute Directory</Link>
                  </li>
                  <li>
                    <Link to="/contact-us">Contact Us</Link>
                  </li>
                  <li>
                    <Link to="/sitemap">Sitemap</Link>
                  </li>
                </ul>
              </div>
            </Col>
            <Col xs={12} sm={6} lg={3}>
              <div className="ftr-lnks">
                <ul>
                  <li>
                    <Link to="/mentors-directory">Mentors Directory</Link>
                  </li>
                  <li>
                    <Link to="/login">Login</Link>
                  </li>
                  <li>
                    <Link to="/smart-solutions">Smart Campus Solutions</Link>
                  </li>
                </ul>
              </div>
            </Col>
            <Col xs={12} sm={6} lg={2}>
              <div className="ftr-lnks">
                <ul>
                  <li>
                    <Link to="/home/recruitments">Recruiters</Link>
                  </li>
                  <li>
                    <a href="https://blog.almabay.com" rel="noreferrer" target={'_blank'}>
                      Blog
                    </a>
                  </li>
                  <li>
                    <a href="./pdf/almabay-brochure.pdf" rel="noreferrer" target="_blank">
                      Brochure
                    </a>
                  </li>
                </ul>
              </div>
            </Col>
            <Col xs={12} sm={6} lg={2}>
              <div className="ftr-lnks">
                <ul>
                  <li>
                    <Link to="/events">Events</Link>
                  </li>
                  <li>
                    <Link to="/what-is-almabay">What is AlmaBay</Link>
                  </li>
                </ul>
              </div>
            </Col>
            <Col xs={12} sm={6} lg={3}>
              <div className="ftr-lnks">
                <ul>
                  <li>
                    <Link to="/campus-brand">Campus Brand Ambassador</Link>
                  </li>
                  <li>
                    <Link to="/shop">Alma Store</Link>
                  </li>
                </ul>
              </div>
            </Col>
            <Col xs={12} sm={6} lg={2}>
              <div className="ftr-lnks">
                <ul>
                  <li>
                    <a
                      href="https://channelpartner.almabay.com/"
                      target={'_blank'}
                      rel="noreferrer"
                    >
                      Channel Partners
                    </a>
                  </li>
                  <li>
                    <Link to="/demovideo">Demo Video</Link>
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
        </div>
        <div className="cr-section">
          <Row>
            <Col lg={12}>
              <div
                className={`copy-right  py-1 ${!excludeClass && 'text-center'}  ${
                  excludeClass && 'copyright-special'
                } `}
              >
                <p>AlmaBay © {new Date().getFullYear()}. All Rights Reserved.</p>
              </div>
              <div className={`ftr-note py-1 text-center ${state ? state : ''}`}>
                <p>Note: AlmaBay is a registered trademark of AlmaBay Networks Pvt Ltd.</p>
              </div>
            </Col>
          </Row>
        </div>
      </section>
      <div className={`feedback-card ${state ? state : ''}`}>
        <Link to="/invite">Invite Friends</Link>
        <Link to="" onClick={handleShow}>
          Feedback
        </Link>
      </div>
      <Modal show={show} onHide={handleClose} className="feedback-modal ">
        <Modal.Header closeButton>
          <Modal.Title>Share your feedback</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group as={Row} className="mb-4" controlId="formHorizontalName">
              <Form.Label column sm={3}>
                Your Name<sup className="text-danger">*</sup>
              </Form.Label>
              <Col sm={9}>
                <Form.Control
                  type="text"
                  name="name"
                  // value={addFeedBack?.name}
                  onChange={handleChange}
                />
                <div className={`${clicked && validate().name ? '' : 'd-none'} danger-frm `}>
                  {clicked && validate().name}
                </div>
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-4" controlId="formHorizontalEmail">
              <Form.Label column sm={3}>
                Email address <sup className="text-danger">*</sup>
              </Form.Label>

              <Col sm={9}>
                <Form.Control
                  type="email"
                  // value={addFeedBack?.email}
                  placeholder="info@example.com"
                  name="email"
                  onChange={handleChange}
                />
                <div className={`${clicked && validate().email ? '' : 'd-none'} danger-frm `}>
                  {clicked && validate().email}
                </div>
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-4" controlId="formHorizontalMobile">
              <Form.Label column sm={3}>
                Mobile No. <sup className="text-danger">*</sup>
              </Form.Label>

              <Col sm={9}>
                <Form.Control
                  type="text"
                  onKeyPress={restrictAlpha}
                  placeholder="XXXXXXXXXX"
                  name="mobile"
                  maxLength={15}
                  onChange={handleChange}
                />
                <div className={`${clicked && validate().mobile ? '' : 'd-none'} danger-frm `}>
                  {clicked && validate().mobile}
                </div>
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-4" controlId="formHorizontalName">
              <Form.Label column sm={3}>
                I want to
              </Form.Label>
              <Col sm={9}>
                <Form.Select
                  aria-label="Default select example"
                  name="subject"
                  onChange={handleChange}
                >
                  <option value="Share an idea">Share an idea</option>
                  <option value="Ask a question">Ask a question</option>
                  <option value="Report an issue">Report an issue</option>
                  <option value="Just say Hello">Just say Hello</option>
                </Form.Select>
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-4" controlId="formHorizontalName">
              <Form.Label column sm={3}>
                Message<sup className="text-danger">*</sup>
              </Form.Label>

              <Col sm={9}>
                <Form.Control
                  as="textarea"
                  // placeholder="Your question"
                  style={{ height: '100px' }}
                  name="message"
                  onChange={handleChange}
                />
                <div className={`${clicked && validate().message ? '' : 'd-none'} danger-frm `}>
                  {clicked && validate().message}
                </div>
              </Col>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleSubmit}>
            Submit
          </Button>
          {/* <Button variant="primary" onClick={handleClose}>
            Close
          </Button> */}
        </Modal.Footer>
      </Modal>
      {loading ? <Loader /> : ''}

      {/* <button className={`scroll-top-card ${state ? state : ''}`}>
        <i className="fas fa-angle-up"></i>
      </button> */}
    </>
  );
}

export default FooterMain;
