// @copyright : ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
// @author     : Shiv Charan Panjeta

// All Rights Reserved.
// Proprietary and confidential :  All information contained herein is, and remains
// the property of ToXSL Technologies Pvt. Ltd. and its partners.
// Unauthorized copying of this file, via any medium is strictly prohibited.

import { useEffect, useState } from 'react';
import { Col, Offcanvas, Row, Tab } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { Link, useNavigate } from 'react-router-dom';
// import * as APISERVICE from '../../../services/JobServices';
import GoogleMapReact from 'google-map-react';
import Nav from 'react-bootstrap/Nav';
import * as APISERVICE from '../../services/JobServices';
// import { mapStyle } from '../../../super-admin/map/mapStyle';
import { useDispatch, useSelector } from 'react-redux';

import { MarkerWithLabel } from '@googlemaps/markerwithlabel';
import { createMapSelectDropDownList, getYearDropDown } from '../../globals/helper';
import useDocumentTitle from '../../globals/useDocumentTitle';
import {
  adminALumniList,
  getAllAlumniList,
  getAppendableJobList,
  getUserJobShortList,
  toggleJobShortList
} from '../../redux/action';
import * as JOBAPISERVICES from '../../services/UserServices';
// import useInfiniteScroller from './useInfiniteScroller';
import useInfiniteScroller from '../../../src/components/webpages/user-pages/useInfiniteScroller';
import { getUsersMap } from '../../services/AuthServices';
import showNotification from '../../services/NotificationService';

// global variables

var markers = [];
var map = null;
var circle = null;
var distanceWidget;
var radiusWidget;
function AdminAlumniMap({
  type,
  instituteId,
  yearList,
  instituteList,
  setYearId,
  setInstituteId,
  yearId
}) {
  if (type === 'Jobsmap' || type == undefined) {
    useDocumentTitle('Jobs Map | AlmaBay');
  } else {
    useDocumentTitle('Alumni Map | AlmaBay');
  }
  const isloggedIn = useSelector((state) => state?.user?.isloggedIn);
  const [isRadialSearchActive, setIsRadialSearchActive] = useState(false);
  const [instId, setInstId] = useState('');
  const [alumniList, setAlumniList] = useState();
  const [alumniCard, setAlumniCard] = useState(true);
  const [isRadias, setIsRadius] = useState(true);
  const [ID, setID] = useState('');
  const allIds = alumniList?.alumnisByInstitute?.find((elem) => {
    return elem?.institute_id === ID;
  });
  const [unit, setUnit] = useState('km');
  const [radius, setRadius] = useState(20); // default seems in km according to new code
  const [currentPosition, setCurrentPosition] = useState({});
  const [isPosition, setIsPosition] = useState(false);
  const [mapState, setMapState] = useState(null);
  const [mapsState, setMapsState] = useState(null);
  let dispatch = useDispatch();
  const cluster = useSelector((state) => state?.cluster);
  const { jobList, jobListCount, shortList, shortListCount, alumnisList, alumnisListCount } =
    cluster;
  const [activeSection, setActiveSection] = useState([]);
  const [alumniName, setAlumniName] = useState('');
  const [jobLocation, setJobLocation] = useState([]);
  const [locationSearch, setLocationSearch] = useState(null);
  const [instituteName, setInstituteName] = useState([]);
  const [instituteSearch, setInstituteSearch] = useState(null);
  const [company, setCompany] = useState([]);
  const [companySearch, setCompanySearch] = useState(null);
  const [yearOptions, setYearOptions] = useState([]);
  const [gradYear, setGradYear] = useState([]);
  const [yearSearch, setYearSearch] = useState('');
  const [skillsOption, setSkillsOption] = useState([]);
  const [companyOption, setCompanyOption] = useState([]);
  const [industryOption, setIndustryOption] = useState([]);
  const [industryName, setIndustryName] = useState([]);
  const [industrySearch, setIndustrySearch] = useState(null);
  const [businessSkill, setBusinessSkill] = useState([]);
  const [interPersonalSkill, setInterPersonalSkill] = useState([]);
  const [businessSearch, setBusinessSearch] = useState(null);
  const [personalSearch, setPersonalSearch] = useState(null);
  const [selectedIndustryOption, setSelectedIndustryOption] = useState([]);
  const [minExp, setMinExp] = useState('');
  const [minCtc, setMinCtc] = useState('');
  const [maxCtc, setMaxCtc] = useState('');
  const [maxExp, setMaxExp] = useState('');
  const [selectedSkillsOption, setSelectedSkillsOption] = useState([]);
  const [skillsFilterId, setSkillsFilterId] = useState('');
  const [selectedCompanyOption, setSelectedCompanyOption] = useState([]);
  const [jobDetails, setJobDetails] = useState(null);
  const [jobDetailsVisible, setJobDetailsVisible] = useState(false);
  const [tooltip, setTooltip] = useState(false);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [mapOptions, setMapOptions] = useState({
    // styles: mapStyle,
    minZoom: 2,
    // minZoomOverride: true,
    maxZoom: 12,
    rotateControl: true,
    mapTypeControl: false,
    streetViewControl: false
  });
  let aluminies = useSelector((state) => state.cluster?.adminAlumniList?.alumniList ?? []);
  let totalAluminies = useSelector((state) => state.cluster?.adminAlumniList?.total);
  const [placeClicked, setplaceClicked] = useState(null);
  const handleRadialSearch = (e) => {
    e.preventDefault();
    getResult(map.getZoom()); // get results
  };
  const onRadiusChange = (radd) => {
    setRadius(radd);
  };

  const navigate = useNavigate();

  /**
   * useEffect HOOK
   */
  useEffect(() => {
    if (type === 'Alumnimap') {
      allAlumnisList();
      getYearDropDown(setYearOptions);
    }
    getOptionsList();
  }, []);
  useEffect(() => {
    getLocation.then((position) => {
      setIsPosition(true);
      setCurrentPosition({
        lat: JSON.parse(position.coords.latitude),
        lng: JSON.parse(position.coords.longitude)
      });
    });
  }, [radius]);

  /**
   * get alumni list
   */
  const allAlumnisList = async () => {
    try {
      const response = await JOBAPISERVICES.alumnisData();
      if (response?.status === 200) {
        setAlumniList(response?.data?.detail);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const [isZooming, setIsZooming] = useState(false);

  let getLocation = new Promise(function (myResolve, myReject) {
    // "Producing Code" (May take some time)
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        // when successful
        myResolve(position);
      });
    } else {
      myReject(); // when error
    }
  });

  function checkBounds(map) {
    var latNorth = map.getBounds().getNorthEast().lat();
    var latSouth = map.getBounds().getSouthWest().lat();
    var newLat;

    if (latNorth < 85 && latSouth > -85) /* in both side -> it's ok */ return;
    else {
      if (latNorth > 85 && latSouth < -85) /* out both side -> it's ok */ return;
      else {
        if (latNorth > 85)
          newLat = map.getCenter().lat() - (latNorth - 85); /* too north, centering */
        if (latSouth < -85)
          newLat = map.getCenter().lat() - (latSouth + 85); /* too south, centering */
      }
    }
    if (newLat) {
      var newCenter = new window.google.maps.LatLng(newLat, map.getCenter().lng());
      map.setCenter(newCenter);
    }
  }

  const getOptionsList = () => {
    APISERVICE.getKeyWords().then((res) => {
      if (res?.status === 200) {
        const data = res?.data?.data;
        setSkillsOption(createMapSelectDropDownList(data));
      }
    });
    JOBAPISERVICES.getJobCompanyDropdown().then((res) => {
      if (res?.status === 200) {
        let data = res?.data?.data;
        setCompanyOption(createMapSelectDropDownList(data));
      }
    });

    JOBAPISERVICES.getJobIndustriesDropdown().then((res) => {
      if (res?.status === 200) {
        let data = res?.data?.data;
        setIndustryOption(createMapSelectDropDownList(data));
      }
    });
  };
  const clearMarkers = (markers) => {
    markers.forEach((marker) => {
      marker.setMap(null);
    });
    markers = [];
  };

  const createMarker = (map, result, text = '', yearId, instituteId) => {
    var labelsClass = 'marker_label_job_map';
    var icon = {
      url: require('../../assets/images/big-marker.png')
    };
    if (result.count < 10) {
      icon.scaledSize = new window.google.maps.Size(40, 40);
      labelsClass += ' lt10';
    } else if (result.count >= 10 && result.count < 100) {
      icon.scaledSize = new window.google.maps.Size(45, 45);
      labelsClass += ' lt100';
    } else if (result.count >= 100 && result.count < 500) {
      icon.scaledSize = new window.google.maps.Size(55, 55);
      labelsClass += ' lt500';
    } else if (result.count >= 500 && result.count < 1000) {
      icon.scaledSize = new window.google.maps.Size(60, 60);
      labelsClass += ' lt1000';
    } else if (result.count >= 1000 && result.count < 5000) {
      icon.scaledSize = new window.google.maps.Size(68, 68);
      labelsClass += ' lt5000';
    } else if (result.count >= 5000 && result.count < 10000) {
      icon.scaledSize = new window.google.maps.Size(72, 72);
      labelsClass += ' lt10000';
    }
    let marker = new MarkerWithLabel({
      position: {
        lat: Number(result.latitude),
        lng: Number(result.longitude)
      },
      map: map,
      labelContent:
        '<span title="Total Jobs in ' + result['name'] + text + '">' + result.count + '</span>',
      icon: icon,
      labelAnchor: { x: 7, y: 28 },
      title: result['name'],
      labelClass: labelsClass
    });
    marker.addListener('click', () => {
      handleMarkerClick(result, yearId, instituteId);
    });

    return marker;
  };

  const handleSearch = async () => {
    // api call will do validation later
    // get data based on zoom level
    let zoomLevel = map.getZoom();
    let newPlaceType = 'country';
    if (zoomLevel < 5) {
      newPlaceType = 'country';
    } else if (zoomLevel > 4 && zoomLevel < 7) {
      newPlaceType = 'state';
    } else if (zoomLevel > 6) {
      newPlaceType = 'city';
    }

    // let locationId = jobLocation.id;
    // let companiesIds = selectedCompanyOption.map((item) => item.id).join(',');
    // let skillsIds = selectedSkillsOption.map((skill) => skill.id).join(',');
    // let industryIds = selectedIndustryOption.map((industry) => industry.id).join(',');
    // let resp = await JOBAPISERVICES.getUserJobsForMap(
    //   newPlaceType,
    //   locationId,
    //   companiesIds,
    //   skillsIds,
    //   industryIds,
    //   minExp,
    //   maxExp,
    //   minCtc,
    //   maxCtc
    //   //   // radiusSearch = '',
    //   //   ''
    //   //   // radius
    // );
    // let data = await getJobsData(
    //   newPlaceType,
    //   locationId,
    //   companiesIds,
    //   skillsIds,
    //   industryIds,
    //   minExp,
    //   maxExp,
    //   minCtc,
    //   maxCtc,
    //   // radiusSearch = '',
    //   ''
    //   // radius
    // );
    // if (data) {
    //   // clear markers
    //   clearMarkers(markers);
    // }

    // let joblist = data.jobs ?? [];
    // joblist.forEach((job) => {
    //   markers.push(createMarker(map, job));
    // });

    getResult(map.getZoom());
  };
  const createRadialSearch = () => {
    if (circle) {
      circle.setMap(null);
    }
    // create a circle
    distanceWidget = new DistanceWidget(map, radius, currentPosition);

    // Set the distance property value, default to 20km.
    // window.google.maps.setOptions({ minZoom: 8, zoom: 10 });
    setMapOptions({
      ...mapOptions,
      minZoom: 8,
      zoom: 10
    });
    map.setOptions({ minZoom: 8, zoom: 10 });
    distanceWidget.addListener('distance_changed', function () {
      let radd = distanceWidget.get('distance').toFixed();

      onRadiusChange(radd);
    });
    distanceWidget.addListener('position_changed', function () {
      let radius = distanceWidget.get('distance').toFixed();
    });
    setIsRadialSearchActive(true);
  };

  const getResult = async (zoom) => {
    let newPlaceType = 'country';
    if (zoom < 5) {
      newPlaceType = 'country';
    } else if (zoom > 4 && zoom < 7) {
      newPlaceType = 'state';
    } else if (zoom > 6) {
      newPlaceType = 'city';
    }

    let locationId = jobLocation?.map((item) => item.id).join(',');
    let companiesIds =
      selectedCompanyOption && selectedCompanyOption.map((item) => item.id).join(',');
    let skillsIds = selectedSkillsOption && selectedSkillsOption.map((skill) => skill.id).join(',');
    let industryIds =
      selectedIndustryOption && selectedIndustryOption.map((industry) => industry.id).join(',');

    // lat and long of center
    let latitude = isRadialSearchActive ? radiusWidget.get('center').lat() : '';
    let longitude = isRadialSearchActive ? radiusWidget.get('center').lng() : '';

    /**
     * ALUMNI MAP VARIABLES
     */

    let resp;
    if (type === 'Jobsmap' || type == undefined) {
      resp = await getUsersMap(newPlaceType, yearId, instituteId, company);

      let data = resp.data.data;
      if (data) {
        // clear markers
        clearMarkers(markers);
      }

      let joblist = data ?? [];

      joblist.forEach((job) => {
        markers.push(createMarker(map, job, '', yearId, instituteId));
      });
    }
  };
  const [zoomLevel, setZoomLevel] = useState(null);
  const onZoomLevelChanged = (zoom) => {
    setZoomLevel(zoom);
  };

  useEffect(() => {
    if (zoomLevel) {
      getResult(zoomLevel); // just call
    }
  }, [zoomLevel]);

  const updateCircleRadius = (rad) => {
    // calculate sizer position from center of the circle
    let center = radiusWidget.get('center');
    let distance = rad;
    let latLongFromCenter = window.google.maps.geometry.spherical.computeOffset(
      center,
      distance * 1000,
      90
    );
    radiusWidget.set('sizer_position', latLongFromCenter);
    radiusWidget.setDistance();
    setRadius(rad);
  };
  const resetRadialSearch = () => {
    setIsRadialSearchActive(false); // effect will trigger
    // set circle map to  nulll
    if (circle) {
      clearMarkers(markers);
      circle.setMap(null);
      map.setOptions({
        minZoom: 2,
        // initial scale
        maxZoom: 12,
        zoom: 4
      });
      setMapOptions({
        ...mapOptions,
        minZoom: 2,
        // initial scale
        maxZoom: 12,
        zoom: 4
      });
    }
  };

  const handleMarkerClick = async (result, data) => {
    setplaceClicked(result.name);
    if (type === 'Alumnimap') {
      let body = {
        place: result.name.trim(),
        pt: getMapViewPointBasedOnZoom(),
        // filters and other stuff
        alumni_name: alumniName,
        institute: instituteName && instituteName?.map((item) => item.id).join(','),
        passoutYear: gradYear && gradYear?.map((item) => item.value).join(','),
        company: company && company?.map((item) => item.id).join(','),
        location: jobLocation && jobLocation?.map((item) => item.id).join(','),
        category: industryName && industryName?.map((item) => item.id).join(','),
        skillset: selectedSkillsOption && selectedSkillsOption.map((skill) => skill.id).join(','),
        BusinessSkillset: businessSkill && businessSkill?.map((item) => item.id).join(','),
        PersonalSkillset: interPersonalSkill && interPersonalSkill?.map((item) => item.id).join(',')
      };
      let response = await dispatch(getAllAlumniList(body));
      if (response.data) {
        setActiveSection([...activeSection, 'ALUMNIS']);
      }
    }

    // await dispatch(getUserJobShortList());       //

    if (type === 'Jobsmap' || !type) {
      let body = {
        place: result.name.trim(),
        pt: getMapViewPointBasedOnZoom(),
        // filters and other stuff
        passout_year: yearId,
        institute: instId,
        company: ''
      };

      let resp = await dispatch(adminALumniList(body));
      if (resp.data) {
        // active section
        setActiveSection([...activeSection, 'ADMIN']);
        setShow(true);
      }
    }
  };

  const getMapViewPointBasedOnZoom = () => {
    let zoom = map.getZoom();
    let newPlaceType = 'country';
    if (zoom < 5) {
      newPlaceType = 'country';
    } else if (zoom > 4 && zoom < 7) {
      newPlaceType = 'state';
    } else if (zoom > 6) {
      newPlaceType = 'city';
    }
    return newPlaceType;
  };

  const [lastElement, setLastElement] = useState(null);
  const [shouldLoadMoreButtonVisible, setShouldLoadMoreButtonVisible] = useState(false);

  const onIntersecting = () => {
    setShouldLoadMoreButtonVisible(true);
  };

  const handleLoadMore = () => {
    // load more
    let body = {
      place: 'India',
      pt: getMapViewPointBasedOnZoom()
      // filters and other stuff
    };
    dispatch(getAppendableJobList(body));
  };
  useInfiniteScroller(lastElement, onIntersecting);

  const addAndRemoveFromShortList = async (data, status) => {
    // dispatch
    await dispatch(toggleJobShortList(data.id, status));
    //  await  dispatch(getUserJobShortList());
  };
  const addAndRemoveFromShortList2 = async (data, status) => {
    // dispatch
    await dispatch(toggleJobShortList(data.id, status));
    await dispatch(getUserJobShortList());
    //  await  dispatch(getUserJobShortList());
  };

  const displayJobDetail = (data) => {
    APISERVICE.getSingleJobDetail(data.id).then((res) => {
      setJobDetails(res.data.data);
      setJobDetailsVisible(true);
    });
  };
  const onCloseJob = (state) => {
    setJobDetailsVisible(state);
  };

  const resquestHanderFriend = async (e, id, type) => {
    e.preventDefault();
    let body = {
      following_id: id
    };
    if (type == 'add') {
      try {
        const response = await JOBAPISERVICES.addFriend(body);
        if (response?.status === 200) {
          handleMarkerClick();
        } else {
          showNotification('success', response?.data?.message);
        }
      } catch (error) {
        console.error(error);
      }
    }

    if (type == 'remove') {
      if (confirm('Do you want to remove your friend?') === true) {
        try {
          const response = await JOBAPISERVICES.addFriend(body);
          if (response?.status === 200) {
            handleMarkerClick();
          } else {
            showNotification('success', response?.data?.message);
          }
        } catch (error) {
          console.error(error);
        }
      }
    }

    if (type == 'requested') {
      if (confirm('Do you want to remove request?') === true) {
        try {
          const response = await JOBAPISERVICES.addFriend(body);
          if (response?.status === 200) {
            handleMarkerClick();
          } else {
            showNotification('success', response?.data?.message);
          }
        } catch (error) {
          console.error(error);
        }
      }
    }
    // else {

    //   let body = {
    //     userData: id
    //   };
    //   removeFriend(body).then((res) => {
    //     setLoading(false);
    //     if (res?.status == 200) {
    //       handleShowlike(e, postId);
    //     } else {
    //       showNotification('success', res?.data?.message);
    //     }
    //   });
    // }
  };
  const getJobsMap = (year_id = '', ins_id = 0) => {
    getUsersMap('', year_id, ins_id == 0 ? '' : ins_id).then((res) => {
      if (res?.status === 200) {
        const data = res?.data?.data;
        if (data) {
          // clear markers
          clearMarkers(markers);
        }

        data.forEach((job) => {
          markers.push(createMarker(map, job));
        });
      }
    });
  };

  const handleClick = (e, id) => {
    e.preventDefault();
    navigate(`/admin/users/view/${id}`);
  };
  return (
    <>
      {/* <HeaderShop/> */}
      {/* {getToken() ? <HeaderLogin /> : <HeaderInner />} */}

      <div className="map-hd">
        <Row>
          <Col className="mb-3" md={4}>
            <Form.Select
              aria-label="Default select example"
              onChange={(e) => {
                setYearId(e.target.value);
                getJobsMap(e.target.value, instituteId);
              }}
              name="yearId">
              <option value="">-Select Year List-</option>
              {yearList &&
                Object.values(yearList)
                  .reverse()
                  .map((data, index) => {
                    return (
                      <option key={index} value={data?.yearId}>
                        {data}
                      </option>
                    );
                  })}
            </Form.Select>
          </Col>

          <Col md={4}>
            <Form.Select
              aria-label="Default select example"
              onChange={(e) => {
                setInstituteId(e.target.value);
                setInstId(e.target.value);
                getJobsMap(yearId, e.target.value);
              }}>
              {instituteList &&
                instituteList?.map((data, index) => {
                  return (
                    <option key={index} value={data?.id}>
                      {data?.name}
                    </option>
                  );
                })}
            </Form.Select>
          </Col>
        </Row>
      </div>
      <div className="map-view position-relative">
        <section className="jobs_map">
          <div className="jobmaps_inner">
            <div className="map_sort_filter">
              {/* help filter */}

              <div
                className={`help_map ${activeSection.includes('HELP') ? '' : 'job-map-model-hide'}`}
                //style={{ display: 'none' }}
              >
                <div onClick={() => setActiveSection([])} className="help-close-icon">
                  <div className="fa fa-times "> </div>
                </div>
                <h4>
                  Search and locate on our interactive Jobs Map. Click on the location or zoom into
                  an area and you can see the number of jobs and location of those jobs available on
                  a map.
                </h4>
              </div>

              {/* <div className="map-alumni-list">
              <div className="map-alumni-hd">
                <h4>
                  <span>3</span>Alumni in Australia
                </h4>
              </div>
              <ul>
                <li>
                  <div className="map-alumni-card d-flex align-items-top">
                    <div className="map-alumni-img me-3">
                      <img
                        src={require('../../../assets/images/hall-ticketcompany-logo-default.png')}
                        alt="shortlist"
                      />
                    </div>
                    <div className="map-alumni-dis">
                      <h4>
                        <Link>kylie jenner</Link>
                      </h4>
                      <p>
                        {' '}
                        <i className="fas fa-map-marker-alt me-2"></i>Drouin, Victoria, Australia
                      </p>
                      <button className="add-frnd">Add Friend</button>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="map-alumni-card d-flex align-items-top">
                    <div className="map-alumni-img me-3">
                      <img
                        src={require('../../../assets/images/hall-ticketcompany-logo-default.png')}
                        alt="shortlist"
                      />
                    </div>
                    <div className="map-alumni-dis">
                      <h4>
                        <Link>kylie jenner</Link>
                      </h4>
                      <p>
                        {' '}
                        <i className="fas fa-map-marker-alt me-2"></i>Drouin, Victoria, Australia
                      </p>
                      <button className="add-frnd">Add Friend</button>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="map-alumni-card d-flex align-items-top">
                    <div className="map-alumni-img me-3">
                      <img
                        src={require('../../../assets/images/hall-ticketcompany-logo-default.png')}
                        alt="shortlist"
                      />
                    </div>
                    <div className="map-alumni-dis">
                      <h4>
                        <Link>kylie jenner</Link>
                      </h4>
                      <p>
                        {' '}
                        <i className="fas fa-map-marker-alt me-2"></i>Drouin, Victoria, Australia
                      </p>
                      <button className="add-frnd">Add Friend</button>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="map-alumni-card d-flex align-items-top">
                    <div className="map-alumni-img me-3">
                      <img
                        src={require('../../../assets/images/hall-ticketcompany-logo-default.png')}
                        alt="shortlist"
                      />
                    </div>
                    <div className="map-alumni-dis">
                      <h4>
                        <Link>kylie jenner</Link>
                      </h4>
                      <p>
                        {' '}
                        <i className="fas fa-map-marker-alt me-2"></i>Drouin, Victoria, Australia
                      </p>
                      <button className="add-frnd">Add Friend</button>
                    </div>
                  </div>
                </li>
              </ul>
            </div> */}

              {/* shortlisted filter  */}
              <Offcanvas
                show={show}
                onHide={handleClose}
                className={`alumni-maps`}
                placement={'end'}>
                <Offcanvas.Body>
                  <div className="alumni-slide">
                    <div className="alumni-hd d-flex align-items-center justify-content-between">
                      <h4>
                        {totalAluminies} alumni connected in {placeClicked}
                      </h4>
                      <Link to="/admin/users/showAllUsers">View all</Link>
                    </div>
                    <div className="alumni-slide-list">
                      <ul>
                        <li>
                          <Link to="#">
                            {show
                              ? aluminies?.map((data, index) => {
                                  return (
                                    <>
                                      <div
                                        className="list-dis d-flex align-items-center mb-3"
                                        key={index}
                                        onClick={(e) => {
                                          handleClick(e, data?.user_id);
                                        }}>
                                        <div className="list-media me-2">
                                          <img
                                            src={
                                              data?.url
                                                ? process.env.REACT_APP_API_DOMAIN +
                                                  data?.url +
                                                  '.' +
                                                  data.extension
                                                : require('../../assets/images/default-male-avatar.png')
                                            }
                                            onError={({ currentTarget }) => {
                                              currentTarget.onerror = null; // prevents looping
                                              currentTarget.src = require('../../assets/images/default-male-avatar.png');
                                            }}
                                            alt="alumni"
                                          />
                                        </div>
                                        <div className="list-data">
                                          <h4>{data?.first_name} </h4>
                                          <p>{data?.companyname}</p>
                                        </div>
                                      </div>
                                    </>
                                  );
                                })
                              : ''}
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </Offcanvas.Body>
              </Offcanvas>
            </div>

            <div className="job_map_view job-map-model-hide">
              <iframe
                src="https://www.google.com/maps/place/AlmaBay+Networks+Private+Limited/@30.660436,76.860654,16z/data=!4m5!3m4!1s0x0:0xb667563103641761!8m2!3d30.660436!4d76.860654?hl=en-GB"
                width="100%"
                height="100%"
                allowFullScreen=""
                loading="lazy"
                title="googleFrame"></iframe>
            </div>
          </div>
          {isRadialSearchActive && (
            <div className="mr_large_padding_t map-bottom-card z_index">
              <Button
                onClick={() => {
                  resetRadialSearch();
                }}
                className="rounded-circle position-absolute close-radius">
                <i className="fas fa-times"></i>
              </Button>

              <div className="bg-white manage_jobs_radius mr_normal_padding">
                <h4 className="map-hd-card">No job(s) avaliable. Increase radius to find Jobs.</h4>

                <div className="flex align-items_flex-end">
                  <div className="radius-frm d-flex align-items-center justify-content-center">
                    <div className=" mr_small_padding_l mr_small_padding_r w50 me-4">
                      <label className="me-2" htmlFor="radius">
                        Radius
                      </label>
                      <input
                        type={'text'}
                        className="w100 block radius-input"
                        value={unit == 'km' ? Math.round(radius) : Math.round(radius / 1.6)}
                        name="radius"
                        id="radius"
                        onChange={(e) => {
                          if (unit === 'km') {
                            updateCircleRadius(Number(e.target.value));
                            return;
                          } else {
                            updateCircleRadius(Number(e.target.value * 1.6));
                            return;
                          }
                        }}
                        autoComplete="off"
                      />
                    </div>
                    <div className="rd-frm-select  mr_small_padding_l mr_small_padding_r w50">
                      <label className="me-2" htmlFor="unit">
                        Units
                      </label>
                      <select
                        onChange={(e) => setUnit(e.target.value)}
                        name="unit"
                        id="unit"
                        value={unit}
                        className="w100 block rd-frm-select">
                        <option value={``}>Select</option>
                        <option value="km">Kilometer</option>
                        <option value="miles">Miles</option>
                      </select>
                    </div>
                    <div className="btn-default1 btn border-radius5 text-center text-white border-default mr_normal_Margin_l">
                      <Button
                        variant="primary"
                        onClick={(e) => {
                          handleRadialSearch(e);
                        }}>
                        Search
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          <Row>
            <Col lg={12}>
              <div style={{ height: '100vh', width: '100%' }}>
                <UserJobMap
                  mapOptions={mapOptions}
                  isPosition={isPosition}
                  currentPosition={currentPosition}
                  setMapState={setMapState}
                  setMapsState={setMapsState}
                  setIsZooming={setIsZooming}
                  jobLocation={jobLocation}
                  onZoomLevelChanged={onZoomLevelChanged}
                  // onRadiusChange={onRadiusChange}
                  radius={radius}
                  handleMarkerClick={handleMarkerClick}
                  instId={instId}
                  type={type}
                />
              </div>
            </Col>
          </Row>
          {jobDetailsVisible && <JobDetail jobDetails={jobDetails} onCloseJob={onCloseJob} />}
          {type === 'Alumnimap' && alumniCard && (
            <div className="found-network-card">
              <div className="alumni-count">
                <Link onClick={() => setAlumniCard(false)} className="alumni-close" to="#">
                  <i className="fas fa-times"></i>
                </Link>
                <h4>{alumniList && alumniList?.totalAlumnis[0].total}</h4>
                <p>Alumni found in your network</p>
              </div>
              <div className="alumni-collz-list">
                <ul>
                  {alumniList?.alumnisByInstitute?.map((elem, index) => {
                    let withLocationCount = Number(elem?.with_location);
                    let withoutLocationCount = Number(elem?.without_location);
                    if (withLocationCount > 1000) {
                      withLocationCount = Number((withLocationCount / 1000).toFixed(1));
                    }
                    if (withoutLocationCount > 1000) {
                      withoutLocationCount = Number((withoutLocationCount / 1000).toFixed(1));
                    }
                    return (
                      <li key={index}>
                        <div className="alumni-collz-card d-flex align-items-center">
                          {tooltip && elem?.institute_id == allIds?.institute_id && (
                            <div className="hasTooltip">
                              <div>
                                Total Alumni :
                                <strong className="ng-binding sky" title="8453">
                                  {elem?.total}
                                </strong>
                              </div>
                              <div>
                                Alumni with Location :
                                <strong title="8453" className="ng-binding yellow">
                                  {withLocationCount > 1000
                                    ? withLocationCount
                                    : elem?.with_location}
                                </strong>
                              </div>
                              <div>
                                Alumni without Location :
                                <strong title="5823" className="ng-binding grey">
                                  {withoutLocationCount > 1000
                                    ? withoutLocationCount
                                    : elem?.without_location}
                                </strong>
                              </div>
                            </div>
                          )}
                          <h4>
                            {withLocationCount > 1000 ? withLocationCount : elem?.with_location}
                          </h4>

                          <div
                            onMouseLeave={() => {
                              setTooltip(false);
                              setID('');
                            }}
                            onMouseEnter={() => {
                              setTooltip(true);
                              setID(elem?.institute_id);
                            }}
                            className="alumni-collz-dis">
                            <h5>{elem?.name}</h5>
                            <p>
                              <i className="fas fa-map-marker-alt me-1"></i>
                              {elem?.city}
                            </p>
                          </div>
                        </div>
                      </li>
                    );
                  })}
                </ul>
              </div>
              <div className="already-connected">
                <p>0 Alumni are already connected </p>
              </div>
            </div>
          )}
        </section>
      </div>
    </>
  );
}

export default AdminAlumniMap;
// End of Google Function //

//Start of circle Draw Function on google map //
/**
 * A distance widget that will display a circle that can be resized and will
 * provide the radius in km.
 *
 * @param {google.maps.Map} map The map on which to attach the distance widget.
 *
 * @constructor
 */
function DistanceWidget(map, radius, currentPosition) {
  map.setCenter(currentPosition);
  this.set('map', map);
  this.set('position', map.getCenter());

  var marker = new window.google.maps.Marker({
    icon: {
      url: require('../../assets/images/center-nod.png'),
      scaledSize: new window.google.maps.Size(22, 22),
      anchor: new window.google.maps.Point(8, 12)
    },
    title: 'Move me!',
    draggable: true,
    cursor: 'all-scroll'
  });

  // Bind the marker map property to the DistanceWidget map property
  marker.bindTo('map', this);

  // Bind the marker position property to the DistanceWidget position
  // property
  marker.bindTo('position', this);

  marker.bindTo('center', this, 'position');
  // Create a new radius widget
  radiusWidget = new RadiusWidget(radius);

  // Bind the radiusWidget map to the DistanceWidget map
  radiusWidget.bindTo('map', this);

  // Bind the radiusWidget center to the DistanceWidget position
  radiusWidget.bindTo('center', this, 'position');

  // Bind to the radiusWidgets' distance property
  this.bindTo('distance', radiusWidget);

  // Bind to the radiusWidgets' bounds property
  this.bindTo('bounds', radiusWidget);
}

/**
 * A radius widget that add a circle to a map and centers on a marker.
 *
 * @constructor
 */

function RadiusWidget(radius) {
  circle = new window.google.maps.Circle({
    strokeWeight: 1,
    strokeColor: '#FBAE38',
    strokeOpacity: 0.9,
    fillColor: '#FFCD81',
    draggable: true
  });

  // Set the distance property value, default to 20km.
  this.set('distance', radius);

  // Bind the RadiusWidget bounds property to the circle bounds property.
  this.bindTo('bounds', circle);

  // Bind the circle center to the RadiusWidget center property
  circle.bindTo('center', this);

  // Bind the circle map to the RadiusWidget map
  circle.bindTo('map', this);

  // Bind the circle radius property to the RadiusWidget radius property
  circle.bindTo('radius', this);

  this.addSizer();
}

const UserJobMap = ({
  isPosition,
  currentPosition,
  setMapState,
  setMapsState,
  setIsZooming,
  onZoomLevelChanged,
  mapOptions,
  handleMarkerClick,
  type,
  instId
}) => {
  useEffect(() => {
    if (type === 'Jobsmap' || type == undefined) {
      getUsersMap().then((resp) => {
        if (resp?.status == 200 && resp.data.data) {
          clearMarkers(markers);
          const joblist = resp.data.data;

          joblist.forEach((job, i) => {
            markers.push(createMarker(map, job, '', instId));
          });
        }
      });
    }
  }, []);

  const clearMarkers = (markers) => {
    markers.forEach((marker) => {
      marker.setMap(null);
    });
    markers = [];
  };

  const createMarker = (map, result, text = '') => {
    var labelsClass = 'marker_label_job_map';
    var icon = {
      url: require('../../assets/images/big-marker.png')
    };
    if (result.count < 10) {
      icon.scaledSize = new window.google.maps.Size(40, 40);
      labelsClass += ' lt10';
    } else if (result.count >= 10 && result.count < 100) {
      icon.scaledSize = new window.google.maps.Size(45, 45);
      labelsClass += ' lt100';
    } else if (result.count >= 100 && result.count < 500) {
      icon.scaledSize = new window.google.maps.Size(55, 55);
      labelsClass += ' lt500';
    } else if (result.count >= 500 && result.count < 1000) {
      icon.scaledSize = new window.google.maps.Size(60, 60);
      labelsClass += ' lt1000';
    } else if (result.count >= 1000 && result.count < 5000) {
      icon.scaledSize = new window.google.maps.Size(68, 68);
      labelsClass += ' lt5000';
    } else if (result.count >= 5000 && result.count < 10000) {
      icon.scaledSize = new window.google.maps.Size(72, 72);
      labelsClass += ' lt10000';
    }
    let marker = new MarkerWithLabel({
      position: {
        lat: Number(result.latitude),
        lng: Number(result.longitude)
      },
      map: map,
      labelContent: '<span title="' + result['name'] + '">' + result.count + '</span>',
      icon: icon,
      labelAnchor: { x: 7, y: 28 },
      title: result['name'],
      labelClass: labelsClass
    });
    marker.addListener('click', () => {
      handleMarkerClick(result);
    });

    return marker;
  };

  function checkBounds(map) {
    var latNorth = map.getBounds().getNorthEast().lat();
    var latSouth = map.getBounds().getSouthWest().lat();
    var newLat;

    if (latNorth < 85 && latSouth > -85) /* in both side -> it's ok */ return;
    else {
      if (latNorth > 85 && latSouth < -85) /* out both side -> it's ok */ return;
      else {
        if (latNorth > 85)
          newLat = map.getCenter().lat() - (latNorth - 85); /* too north, centering */
        if (latSouth < -85)
          newLat = map.getCenter().lat() - (latSouth + 85); /* too south, centering */
      }
    }
    if (newLat) {
      var newCenter = new window.google.maps.LatLng(newLat, map.getCenter().lng());
      map.setCenter(newCenter);
    }
  }

  const handleApiLoaded = async (mapp, maps) => {
    DistanceWidget.prototype = new window.google.maps.MVCObject();
    RadiusWidget.prototype = new window.google.maps.MVCObject();

    RadiusWidget.prototype.addSizer = function () {
      var sizer = new window.google.maps.Marker({
        icon: {
          url: require('../../assets/images/resizer.png'),
          scaledSize: new window.google.maps.Size(24, 24),
          anchor: new window.google.maps.Point(13, 13)
        },
        title: 'Drag me!',
        draggable: true,
        cursor: 'e-resize',
        raiseOnDrag: false
      });

      sizer.bindTo('map', this);
      sizer.bindTo('position', this, 'sizer_position');
      var me = this;
      window.google.maps.event.addListener(sizer, 'drag', function () {
        me.setDistance();
      });
    };

    /**
     * Update the center of the circle and position the sizer back on the line.
     *
     * Position is bound to the DistanceWidget so this is expected to change when
     * the position of the distance widget is changed.
     */
    RadiusWidget.prototype.center_changed = function () {
      var bounds = this.get('bounds');
      // Bounds might not always be set so check that it exists first.
      if (bounds) {
        var lng = bounds.getNorthEast().lng();
        // Put the sizer at center, right on the circle.
        var position = new window.google.maps.LatLng(this.get('center').lat(), lng);
        this.set('sizer_position', position);
      }
    };

    /**
     * Calculates the distance between two latlng locations in km.
     * @see http://www.movable-type.co.uk/scripts/latlong.html
     *
     * @param {google.maps.LatLng} p1 The first lat lng point.
     * @param {google.maps.LatLng} p2 The second lat lng point.
     * @return {number} The distance between the two points in km.
     * @private
     */
    RadiusWidget.prototype.distanceBetweenPoints_ = function (p1, p2) {
      if (!p1 || !p2) {
        return 0;
      }
      var R = 6371; // Radius of the Earth in km
      var dLat = ((p2.lat() - p1.lat()) * Math.PI) / 180;
      var dLon = ((p2.lng() - p1.lng()) * Math.PI) / 180;
      var a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos((p1.lat() * Math.PI) / 180) *
          Math.cos((p2.lat() * Math.PI) / 180) *
          Math.sin(dLon / 2) *
          Math.sin(dLon / 2);
      var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      var d = R * c;
      return d;
    };

    /**
     * Set the distance of the circle based on the position of the sizer.
     */
    RadiusWidget.prototype.setDistance = function () {
      // As the sizer is being dragged, its position changes.  Because the
      // RadiusWidget's sizer_position is bound to the sizer's position, it will
      // change as well.
      var pos = this.get('sizer_position');
      var center = this.get('center');
      var distance = this.distanceBetweenPoints_(center, pos);
      if (distance >= 20) {
        // Set the distance property for any objects that are bound to it
        this.set('distance', distance);
      } else {
        // Set the distance property for any objects that are bound to it
        this.set('distance', 20);
        var bounds = this.get('bounds');
        var lng = bounds.getNorthEast().lng();

        // Put the sizer at center, right on the circle.
        var position = new window.google.maps.LatLng(this.get('center').lat(), lng);
        this.set('sizer_position', position);
      }
    };

    /**
     * Update the radius when the distance has changed.
     */
    RadiusWidget.prototype.distance_changed = function () {
      if (this.get('distance') >= 20) {
        this.set('radius', this.get('distance') * 1000);
      } else {
        this.set('radius', 20000);
      }
    };
    // eslint-disable-next-line no-console
    map = mapp; // asign to global map var
    setMapState(map);
    setMapsState(maps);

    map.addListener('center_changed', function () {
      checkBounds(map);
    });
    map.addListener('idle', function () {
      // on first load
    });
    map.addListener('zoom_changed', () => {
      // get data based on zoom level
      setIsZooming(true);

      onZoomLevelChanged(map.getZoom());
    });

    map.addListener('click', (e) => {
      // setActiveSection([]); // just set to blank to hide the dropdown
    });
  };
  /**
   * RETURN DISTANCE BETWEEN THE MARKER AND MOUSE POINTER
   * @param {*} pt
   * @param {*} mp
   * @returns
   */
  const distanceToMouse = (pt, mp) => {
    if (pt && mp) {
      return Math.sqrt((pt.x - mp.x) * (pt.x - mp.x) + (pt.y - mp.y) * (pt.y - mp.y));
    }
  };

  return (
    isPosition && (
      <GoogleMapReact
        bootstrapURLKeys={{
          key: process.env.REACT_APP_GOOGLE_MAP_KEY,
          language: 'en',
          region: 'US',
          libraries: ['places', 'geometry', 'drawing', 'visualization']
        }}
        defaultCenter={{ lat: currentPosition.lat, lng: currentPosition.lng }}
        defaultZoom={11}
        zoom={4}
        distanceToMouse={distanceToMouse}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={async ({ map, maps }) => {
          // intialize global map var
          handleApiLoaded(map, maps);
        }}
        options={mapOptions}></GoogleMapReact>
    )
  );
};

const JobDetail = ({ jobDetails, onCloseJob }) => {
  return (
    <div className="shorlist-job-card">
      <div className="shortlist-hd d-flex">
        <h3>PHP / Laravel developer</h3>
        <div className="d-flex flex-grow-1 justify-content-end">
          <Button
            onClick={() => {
              onCloseJob(false);
            }}>
            <i className="fa fa-times"></i>
          </Button>
        </div>
      </div>
      <div className="shortlist-cntnt-card">
        <Tab.Container id="left-tabs-example" defaultActiveKey="first">
          <div className="">
            <Nav variant="pills" className="">
              <Nav.Item>
                <Nav.Link eventKey="first">Job Details</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="second">Your Referral</Nav.Link>
              </Nav.Item>
            </Nav>
            <Tab.Content>
              <Tab.Pane eventKey="first">
                <div className="short-job-outr">
                  <div className="post-job-hd d-flex align-items-center">
                    <div className="short-job-icn">
                      <img src={require('../../assets/images/toss-logo.png')} alt="job" />
                    </div>
                    <div className="short-job-dis">
                      <h4>Company:{jobDetails?.company_name} </h4>

                      <p>
                        Posted By: <span>{jobDetails?.user?.first_name}</span>
                      </p>
                      <p>
                        Posted On: <span>{jobDetails?.created_on}</span>
                      </p>
                    </div>
                  </div>
                  <div className="short-lst-cntct">
                    <Link to="#">
                      <i className="fas fa-map-marker-alt me-2"></i> {jobDetails?.city_name}{' '}
                    </Link>
                    <Link to="#">
                      <i className="fas fa-envelope me-2"></i> {jobDetails?.hr_email}
                    </Link>
                  </div>
                  <div className="expec-card">
                    <ul>
                      <li>
                        <h4>
                          {jobDetails?.min_exp}-{jobDetails?.max_exp} (yrs)
                        </h4>
                        <p>Experience</p>
                      </li>
                      <li>
                        <h4>
                          {jobDetails?.min_ctc}-{jobDetails?.max_ctc} (Lacs)
                        </h4>
                        <p>Package</p>
                      </li>
                      <li>
                        <h4>{jobDetails?.open_positions}</h4>
                        <p>Openings</p>
                      </li>
                    </ul>
                  </div>
                  <div className="short-disc">
                    <h3>{jobDetails?.job_title}</h3>
                    <p dangerouslySetInnerHTML={{ __html: jobDetails?.job_info }}></p>
                    {/* <h4>Job Description PHP / Laravel developer duties and responsibilities:-</h4> */}
                    {/* <ul>
                      <li>
                        Develop, record and maintain cutting edge web-based PHP applications on
                        portal plus premium service platforms
                      </li>
                      <li>
                        Build innovative, state-of-the-art applications and collaborate with the
                        User Experience (UX) team
                      </li>
                      <li>
                        Ensure HTML, CSS, and shared JavaScript is valid and consistent across
                        applications
                      </li>
                      <li>
                        Prepare and maintain all applications utilizing standard development tools
                      </li>
                      <li>
                        Utilize backend data services and contribute to increase existing data
                        services API
                      </li>
                      <li>
                        Lead the entire web application development life cycle right from concept
                        stage to delivery and post launch support
                      </li>
                      <li>
                        Convey effectively with all task progress, evaluations, suggestions,
                        schedules along with technical and process issues
                      </li>
                      <li>
                        Document the development process, architecture, and standard components
                      </li>
                      <li>
                        Coordinate with co-developers and keeps project manager well informed of the
                        status of development effort and serves as liaison between development staff
                        and project manager
                      </li>
                      <li>Keep abreast of new trends and best practices in web development</li>
                    </ul> */}
                    {/* <h4>PHP / Laravel developer requirements and qualifications:-</h4>
                    <ul>
                      <li>
                        B.Tech/BCA/MCA degree in Computer Science, Engineering, or similar relevant
                        field
                      </li>
                      <li>
                        In depth knowledge of object-oriented PHP and Laravel 7+ PHP Framework
                      </li>
                      <li>
                        Hands on experience with SQL schema design, SOLID principles, REST API
                        design
                      </li>
                      <li>Software testing (PHPUnit, PHPSpec, Behat)</li>
                      <li>MySQL profiling and query optimization</li>
                      <li>Creative and efficient problem solver</li>
                    </ul> */}
                    {/* <h4>Salary Package:-</h4> */}
                    {/* <ul>
                      <li>CTC- 250000 PA</li>
                    </ul> */}

                    {/* <h4>Joining - Immediate </h4>

                    <h3 className="mt-4">Key Skills</h3>
                    <ul className="keyskill-card">
                      <li>Php developer</li>
                      <li>SQL</li>
                      <li>php frameworks</li>
                    </ul> */}
                  </div>
                </div>
              </Tab.Pane>

              <Tab.Pane eventKey="second"></Tab.Pane>
            </Tab.Content>
          </div>
        </Tab.Container>
      </div>
      <div className="short-ftr-btn">
        <ul>
          <li>
            <Link to="">Apply Now</Link>
          </li>
          <li>
            <Link to="">Recommend</Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

//REACT_APP_API_DOMAIN = 'http://192.168.13.139/web-almabay/'
