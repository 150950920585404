import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { useState } from 'react';
import { sendMessageFormConnectionPage } from '../../../services/MentorArticlesServices';
import showNotification from '../../../services/NotificationService';

function SendMessageModal({ handleClose3, show3, mentorId, setMentorId }) {
  const [detail, setDetail] = useState({
    subject: '',
    text: ''
  });
  const [clicked, setClicked] = useState(false);
  const [loading, setLoading] = useState(false);
  const timelineId = JSON.parse(localStorage.getItem('userDetail'))?.id;

  const handleChange = (e) => {
    const { name, value } = e.target;
    setDetail({ ...detail, [name]: value });
  };

  const validate = (value) => {
    let errors = {};
    if (!value?.subject?.trim()) {
      errors.subject = 'Message subject is required field';
      // showNotification('danger', 'Message subject is required field');
    }
    if (!value?.text?.trim()) {
      errors.text = 'Message body is required field';
      // showNotification('danger', 'Message body is required field');
    }
    return errors;
  };

  const submitHandler = (e) => {
    e.preventDefault();
    setClicked(true);
    if (!Object.keys(validate(detail))?.length) {
      setLoading(true);
      let formData = new FormData();
      for (let i in detail) {
        formData.append(`${i}`, detail[i]);
      }
      formData.append('recipient_id', mentorId);
      formData.append('timeline_id', timelineId);

      sendMessageFormConnectionPage(formData).then((res) => {
        setLoading(false);
        if (res?.status === 200) {
          showNotification('success', res?.data?.message);
          handleClose3();
          setMentorId && setMentorId('');
          setDetail({
            subject: '',
            text: ''
          });
        }
      });
    }
  };

  const handleCloseModal = () => {
    handleClose3();
    setDetail({
      subject: '',
      text: ''
    });
  };

  return (
    <>
      <Modal show={show3} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          Send your message to your mentor. | Be specific while sending message and get instant
          response.
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formBasicEvent">
              <Form.Label>
                Subject <span>*</span>
              </Form.Label>
              <Form.Control
                type="text"
                value={detail?.subject}
                placeholder="Your message is about"
                name="subject"
                onChange={handleChange}
              />
              <div
                className={`${
                  clicked && validate(detail)?.subject ? '' : 'd-none'
                } danger-frm px-0`}
              >
                {clicked && validate(detail).subject}
              </div>
            </Form.Group>
            <Form.Group controlId="formBasicEmail">
              <Form.Label>
                Message <span>*</span>
              </Form.Label>
              <Form.Control
                as="textarea"
                rows={5}
                value={detail?.text}
                type="text"
                placeholder="Enter your message here"
                name="text"
                onChange={handleChange}
              />
              <div
                className={`${clicked && validate(detail)?.text ? '' : 'd-none'} danger-frm px-0`}
              >
                {clicked && validate(detail).text}
              </div>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={loading ? '' : submitHandler}>
            Send Message
          </Button>
          <Button variant="secondary" onClick={handleClose3}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default SendMessageModal;
