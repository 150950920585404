import * as api from '../utils/requests';

export const checkDomain = async () => {
  return await api.getReq(`/user/checkInstituteDomain`);
};

export const getHomepageLayout = async () => {
  return await api.getReq(`/admin/institute/getSliderFromToken`);
};

export const instituteLogin = async (body) => {
  return await api.PostReq(`/auth/institutelogin`, body);
};
export const instituteRegister = async (body) => {
  return await api.PostReq(`/institute/instituteUserRegister`, body);
};

export const getBlogDetail = async () => {
  return await api.getReq(`/blogs/list`);
};

export const getClubBlogDetail = async () => {
  return await api.getReq(`/institute/clubBlogDetails`);
};

export const getTagsData = async () => {
  return await api.getReq(`/blogs/userlistTags`);
};

export const getClubBlogTags = async () => {
  return await api.getReq(`/institute/clubUserListTags`);
};

export const getDatesData = async () => {
  return await api.getReq(`/blogs/blogMonth`);
};

export const getClubBlogsDates = async () => {
  return await api.getReq(`/institute/clubBlogArchive`);
};

export const getSingleBlogDetail = async (id) => {
  return await api.getReq(`/blogs/blogView?id=${id}`);
};

export const getClubSingleBlogDetail = async (id) => {
  return await api.getReq(`/institute/clubSingleBlogDetails?id=${id}`);
};
export const getJobList = async (
  job_type = '',
  skills = '',
  industry = '',
  max_ctc = '',
  max_exp = '',
  min_ctc = '',
  min_exp = '',
  company_type = '',
  location = ''
) => {
  return await api.getReq(
    `/jobs/browse-job?job_type=${job_type}&skills=${skills}&industry=${industry}&max_ctc=${max_ctc}&max_exp=${max_exp}&min_ctc=${min_ctc}&min_exp=${min_exp}&company_type=${company_type}&location=${location}`
  );
};

//http://192.168.13.139/web-almabay/jobs/browse-job

// for filter dropdown
export const getJobCategory = async () => {
  return await api.getReq(`/institute/jobCategory`);
};

export const getJobKeyword = async () => {
  return await api.getReq(`/institute/jobKeyword`);
};

export const getInsNews = async () => {
  return await api.getReq(`/institute/instituteNews`);
};

export const getClubInsNews = async () => {
  return await api.getReq(`/institute/clubNewsDetails`);
};

export const getInsNewsView = async (id) => {
  return await api.getReq(`/institute/newsView?id=${id}`);
};

export const getClubInsNewsView = async (id) => {
  return await api.getReq(`institute/clubSingleNewsDetails?id=${id}`);
};

export const getArchive = async () => {
  return await api.getReq(`/institute/newsMonth`);
};

export const getClubNewsArchive = async () => {
  return await api.getReq(`/institute/clubNewsArchive`);
};

export const getClubNewsTags = async () => {
  return await api.getReq(`/institute/clubUserListNewsTags`);
};

export const getEventsMonth = async () => {
  return await api.getReq(`/institute/eventsMonth`);
};

export const getInsEventList = async () => {
  return await api.getReq(`/institute/instituteEventList`);
};

export const getInsEventView = async (id) => {
  return await api.getReq(`/institute/eventsView?id=${id}`);
};

export const getTestimonial = async () => {
  return await api.getReq(`/institute/instituteList`);
};

export const getMentors = async () => {
  return await api.getReq(`/institute/mentorship`);
};

export const getClubMembers = async () => {
  return await api.getReq(`/institute/clubmembers`);
};

export const getAlumni = async () => {
  return await api.getReq(`/institute/allAlumin`);
};

export const getInTouch = async (body) => {
  return await api.PostReq(`/institute/instituteContactUs`, body);
};

export const getInTouchClub = async (body) => {
  return await api.PostReq(`/institute/clubContactUs`, body);
};

export const getContactDetail = async () => {
  return await api.getReq(`/institute/contactPerson`);
};

export const getClubContactDetail = async () => {
  return await api.getReq(`/institute/clubContactPerson`);
};

export const getProductList = async () => {
  return await api.getReq(`/institute/instituteProducts`);
};

export const getPageDetail = async (type) => {
  return await api.getReq(`institute/pageDetails?type_id=${type}`);
};

export const getClubPageDetail = async () => {
  return await api.getReq(`/institute/clubPageDetails`);
};
/**
 * club api's for institute
 * @param {*} body
 * @returns
 */

export const getClubList = async () => {
  return await api.getReq(`/clubs/clubList`);
};

export const getClubDetail = async (id) => {
  return await api.getReq(`/clubDetail?id=${id}`);
};

export const editClubDetail = async (body) => {
  return await api.PostReq(`/clubs/edit_club`, body);
};

export const addClub = async (body) => {
  return await api.PostReq(`/clubs/add_club`, body);
};

// club >upcoming event

export const getUpcomingEvent = async () => {
  return await api.getReq(`/institute/upcomingInstituteEvents`);
};

// department and course list api based on domain

export const getDepartmentOpt = async () => {
  return await api.getReq(`/institute/instituteDepartment`);
};

export const getCourseOpt = async (id) => {
  return await api.getReq(`/institute/instituteCourse?id=${id}`);
};
//add_privacy=&chapter_privacy=&timeline_post_privacy=&institue_id=&location=&admin_id=
export const chapterMap = (
  q = '',
  pt = '',
  add_privacy = '',
  chapter_privacy = '',
  timeline_post_privacy = '',
  institue_id = '',
  location = '',
  admin_id = '',
  radiusSearch = '',
  radiusData = ''
) => {
  return api.getReq(
    `/user/chapterMapData?q=${q}&pt=${pt}&filter=${encodeURIComponent(
      `add_privacy=${add_privacy}&chapter_privacy=${chapter_privacy}&timeline_post_privacy=${timeline_post_privacy}&institue_id=${institue_id}&location=${location}&admin_id=${admin_id}`
    )}&radiusSearch=${radiusSearch}&radiusData=${radiusData}`
  );
};

export const chapterList = (
  page = 1,
  place = '',
  q = '',
  pt = '',
  add_privacy = '',
  chapter_privacy = '',
  timeline_post_privacy = '',
  institue_id = '',
  location = '',
  admin_id = ''
) => {
  return api.getReq(
    `/user/listChapter?page=${page}&place=${place}&q=${q}&pt=${pt}&filter=${encodeURIComponent(
      `add_privacy=${add_privacy}&chapter_privacy=${chapter_privacy}&timeline_post_privacy=${timeline_post_privacy}&institue_id=${institue_id}&location=${location}&admin_id=${admin_id}`
    )}`
  );
};

export const singleChapterDetail = (id) => {
  return api.getReq(`/user/chapterPost?id=${id}`);
};

export const contactPersonListing = (page = '1', title, name, email, status) => {
  return api.getReq(
    `/admin/institute/contactPerson/list?page=${page}&designation=${title}&name=${name}&email=${email}&status=${status}`
  );
};

export const addContactPerson = (body) => {
  return api.PostReq(`/admin/institute/contactPerson/add`, body);
};
export const viewContactPerson = (id) => {
  return api.getReq(`/admin/institute/contactPerson/view?id=${id}`);
};
export const deleteContactPerson = (id) => {
  return api.delReq(`/admin/institute/contactPerson/delete?id=${id}`);
};
export const updateContactPerson = (id, body) => {
  return api.PostReq(`/admin/institute/contactPerson/edit?id=${id}`, body);
};
export const multiActionContactPerson = (body) => {
  return api.PostReq(`/admin/institute/contactPerson/multiAction`, body);
};

/*manage club contact person */
export const contactClubPersonList = (body) => {
  return api.PostReq(`/institute/listClubContactPerson`, body);
};
export const addClubContactPerson = (body) => {
  return api.PostReq(`/institute/addContactPerson`, body);
};
export const viewClubContactPerson = (id) => {
  return api.getReq(`/institute/viewClubContactPerson?id=${id}`);
};
export const deleteClubContactPerson = (id, club_id) => {
  return api.delReq(`/institute/deleteClubContact?id=${id}&club_id=${club_id}`);
};
export const updateClubContactPerson = (id, body) => {
  return api.PostReq(`/institute/editClubContactPerson?id=${id}`, body);
};
export const multiActionClubContactPerson = (body) => {
  return api.PostReq(`/admin/institute/contactPerson/multiAction`, body);
};
/*end */

// get navbar menu's
export const getNavbarmenu = () => {
  return api.getReq(`/institute/menu-items`);
};

/**
 * gallery - api's
 */

export const getGallery = (type = '') => {
  return api.getReq(`/institute/gallery/list?type=${type}`);
};

export const getClubGallery = (type = '') => {
  return api.getReq(`/institute/clubGalleryList?type=${type}`);
};

export const getGalleryArchive = () => {
  return api.getReq(`/institute/galleryArchive`);
};

export const getClubGalleryArchive = () => {
  return api.getReq(`/institute/clubGalleryArchive`);
};

export const getClubGalleryBanner = () => {
  return api.getReq(`/institute/getGalleryDetails`);
};

export const getAllGallery = () => {
  return api.getReq(`/institute/gallery/all`);
};
export const getAllClubGallery = () => {
  return api.getReq(`/institute/clubGalleryAll`);
};

export const getMediaData = (id) => {
  return api.getReq(`/institute/galleryView?media_id=${id}`);
};

export const getClubMediaData = (id) => {
  return api.getReq(`/institute/clubGalleryView?media_id=${id}`);
};

export const quickLinks = () => {
  return api.getReq(`/institute/institute-quick-list`);
};

export const getAluminiBackground = () => {
  return api.getReq(`/institute/alumnilist`);
};

// get open page detail

export const getOpenPageContent = async (id) => {
  return await api.getReq(`/institute/institute-open-page?slug=${id}`);
};

export const getClubCountDetail = async (id) => {
  return await api.getReq(`/clubs/getCountDetail?id=${id}`);
};
export const getClubTimelineDetail = async (id) => {
  return await api.getReq(`/clubs/getDomainStories?id=${id}&section=clubs`);
};

export const checkClubDomain = async (id) => {
  return await api.getReq(`/institute/checkClubDomain?id=${id}`);
};

export const getCountOfChapter = (id) => {
  return api.getReq(`/institute/getCountChapterDetail?id=${id}`);
};

/*************** STEP FORM STARTS ***************/

export const getUser = (id) => {
  return api.getReq(`/institute/getUser?user_id=${id}`);
};

export const saveStepOne = (payload) => {
  return api.PostReq(`/institute/step1`, payload);
};

export const saveStepTwo = (payload) => {
  return api.PostReq(`/institute/step2`, payload);
};

export const saveStepThree = (payload) => {
  return api.PostReq(`/institute/step3`, payload);
};

export const saveStepFour = (payload) => {
  return api.PostReq(`/institute/step4`, payload);
};

export const saveStepFive = (payload) => {
  return api.PostReq(`/institute/step5`, payload);
};

/*************** STEP FORM ENDS ***************/
