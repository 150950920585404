/*
@copyright : ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
@author    : Shiv Charan Panjeta 
 
All Rights Reserved.
Proprietary and confidential :  All information contained here in is, and remains
the property of ToXSL Technologies Pvt. Ltd. and it's partners.
Unauthorized copying of this file, via any medium is strictly prohibited.
*/

export const ERRORMSG = 'Something went wrong.';
export const NO_RECORDS_MESSAGE = 'No entries are found';
export const LOGOUT = 'Are you sure you want to log out?';
export const SIGNOUT = 'logout Successfully';
export const FAIL = 'Email or Password are not valid';
export const STATUS_OK = 200;
export const FORBIDDEN = 403;
export const NOTFOUND = 404;
export const FIFTEEN = 15;
export const EIGHTEEN = 18;

export const NOT_FOUND_TEXT = 'N/A';

export const ONE = 1;
export const TWO = 2;
export const PERPAGE = 10;
export const PERPAGE_TEN = 10;

export const STATE_ACTIVE = 1;
export const STATE_INACTIVE = 0;
export const STATE_DELETED = 2;

export const ROLE_ADMIN = 0; // Super admin

export const ROLE_MENTOR = 1; // Sub Admin
export const OPEN = 0;
export const CLOSED = 1;
export const SECRET = 2;

export const OPEN_CH = 0;
export const CLOSED_CH = 1;
export const SECRET_CH = 2;

//job-type
export const Full_Time = 0;
export const Part_Time = 1;
export const Intern = 2;

export const filePath = 'common/uploads/institute_logo/';

// shortlisted jobs
export const SHORTLIST_JOB = 1;

// *************************** TEMPLATE
// TEMPLATE APPLICABLE FOR

export const TEMPLATE_APPLICABLE_FOR_STUDENT = 2;
export const TEMPLATE_APPLICABLE_FOR_INSTITUTE = 1;
export const TEMPLATE_APPLICABLE_FOR_COMPANY = 3;

export const TEMPLATE_FORMAT_CAT1 = 1;
export const TEMPLATE_FORMAT_CAT2 = 2;
export const TEMPLATE_FORMAT_CAT3 = 3;
export const TEMPLATE_WP_CAT1 = 1;
export const TEMPLATE_WP_CAT2 = 2;

// Email attachment Document type

export const TEMPLATE_DOCUMENT_TYPE_PDF = 1;
export const TEMPLATE_DOCUMENT_TYPE_DOC = 2;

export const YES = 1;
export const NO = 0;
export const ZERO = 0;
export const USERINTERESTED = 2;
export const USERGOING = 1;
export const USER_GOING_OR_NOT_GOING = 3;
export const PARTICIPATE = 1;
export const NOTPARTICIPATE = 0;
export const restrictAlpha = (e) => {
  const re = /[0-9A-F:]+/g;
  if (!re.test(e.key)) {
    e.preventDefault();
  }
};

export const STATUS_ENABLE = 'enable';
export const STATUS_DISABLE = 'disable';
export const STATUS_DELETE = 'delete';

export const JOB_STATUS_ENABLE = 1;
export const JOB_STATUS_DISABLE = 0;
export const JOB_STATUS_DELETE = 2;
export const JOB_APPLIED = 1;
export const JOB_NON_APPLIED = 0;

export const ALPHABET_REGEX = new RegExp(/.*[A-z]/);
export const NUMBER_REGEX = new RegExp(/.*\d/);
export const LENGTH_REGEX = new RegExp(/.{8,}$/);
export const EMAIL_LOGIN_REGEX = new RegExp(/^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i);
export const PASSWORD_VALID_REGEX = new RegExp(
  `^(?=${[LENGTH_REGEX.source, NUMBER_REGEX.source, ALPHABET_REGEX.source].join(')(?=')}).*$`
);
export const BACKSLASH_REGEX = new RegExp('\\\\?');
export const isAttributes = '31a1f0736fb264a56e10314130984cd4';
export const noAttributes = '07814cd1acbc100cb6961808643436b5';

export const post_publisher_soundcloud_placeholder = 'What are you listening?';
export const post_publisher_youtube_placeholder = 'What are you watching?';
export const post_publisher_googlemap_placeholder = 'Where are you?';
export const onKeyPressOnInput = (e, renderFunction) => {
  if (e.which === 13 || e.keyCode === 13) {
    e.preventDefault();
    renderFunction(e);
  } else return true;
};
export const ABOUT = 1;
export const NETWORK = 2;
export const MESSAGE = 3;
export const MENTOR_STUDENT = 4;

export const constantType = Object.freeze({
  PROMOTIONAL: 1,
  TRANSACTIONAL: 2,

  EDIT: '1',
  DELETE: '2',

  NOTIFICATION_ACTIVE: '1',
  NOTIFICATION_INACTIVE: '0',
  NOTIFICATION_DEL: '2'
});

export const communicationConstants = Object.freeze({
  MONTHLY: '1',
  YEARLY: '2',

  EMAIL: '3',
  SMS: '2',

  DISABLE: '0',
  ENABLE: '1',
  DELETE: '2'
});

export const cronjobConstants = Object.freeze({
  TYPE_ID: '1',

  STATE_INACTIVE: '0',
  STATE_ACTIVE: '1',
  STATE_DELETED: '2'
});

export const commonConstants = Object.freeze({
  ACTIVE: '1',
  INACTIVE: '0'
});

export const eventPrivacy = Object.freeze([
  {
    id: 1,
    name: 'Private'
  },
  {
    id: 2,
    name: 'Public'
  }
]);

export const PHOTOS = 'photo';
export const VIDEOS = 'video';
export const ALBUMS = 'album';
export const PUBLIC = 1;
export const PRIVATE = 2;
