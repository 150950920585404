/** @copyright : ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
 @author     : Shiv Charan Panjeta 

 All Rights Reserved.
 Proprietary and confidential :  All information contained herein is, and remains
 the property of ToXSL Technologies Pvt. Ltd. and its partners.
 Unauthorized copying of this file, via any medium is strictly prohibited.
*/

import { useEffect, useState } from 'react';
import { Alert, Button, Container } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import useDocumentTitle from '../../globals/useDocumentTitle';
import { changePassword } from '../../services/AuthServices';
import showNotification from '../../services/NotificationService';

export default function ChangePassword() {
  useDocumentTitle('Change Password | AlmaBay');
  const [passwordDetail, setPasswordDetail] = useState({
    admin_name: useSelector((state) => state?.user?.details)?.admin_name,
    admin_email: useSelector((state) => state?.user?.details)?.admin_email,
    old_pass: '',
    new_pass: '',
    new_pass2: ''
  });
  const [clicked, setClicked] = useState(false);

  const navigate = useNavigate();
  const [show, setShow] = useState(false);

  // On componentDidMount set the timer
  useEffect(() => {
    const timeId = setTimeout(() => {
      // After 3 seconds set the show value to false
      setShow(false);
    }, 3000);

    return () => {
      clearTimeout(timeId);
    };
  }, [show]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPasswordDetail({
      ...passwordDetail,
      [name]: value
    });
  };

  const validate = () => {
    let errors = {};
    if (passwordDetail?.admin_name == '') {
      errors.admin_name = 'Please enter username';
    }
    if (passwordDetail?.admin_email == '') {
      errors.admin_email = 'Please enter email';
    }
    if (passwordDetail?.old_pass.trim() == '') {
      errors.old_pass = 'Please enter old password';
    } else if (passwordDetail?.old_pass?.length < 4) {
      errors.old_pass = 'Please enter atleast 4 characters';
    }
    if (passwordDetail?.new_pass.trim() == '') {
      errors.new_pass = 'Please enter new password';
    } else if (passwordDetail?.new_pass?.length < 4) {
      errors.new_pass = 'Please enter atleast 4 characters';
    }
    if (passwordDetail?.new_pass2.trim() == '') {
      errors.new_pass2 = 'Please enter confirm new password';
    } else if (passwordDetail?.new_pass2?.length < 4) {
      errors.new_pass2 = 'Please enter atleast 4 characters';
    }
    if (passwordDetail?.new_pass !== passwordDetail?.new_pass2) {
      errors.new_pass2 = 'Password should match';
    }

    return errors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setClicked(true);

    if (!Object.keys(validate()).length) {
      let formData = new FormData();
      for (let i in passwordDetail) {
        formData.append(`${i}`, passwordDetail[i]);
      }

      changePassword(formData).then((res) => {
        if (res?.status === 200) {
          showNotification('success', res?.data?.data);
          setShow(true);
          // navigate('/admin/users/roles');
        }
      });
    }
  };

  return (
    <div className="dash-main-content">
      <Container fluid>
        <div className="dash-page-content">
          <div className="dash-page-title">
            <Row>
              <Col xs={12} sm={12} md={12}>
                <div className="dash-title-bar d-flex align-items-center justify-content-between">
                  <div className="title-lft d-flex align-items-center">
                    <div className="dash-title-dis">
                      <h5>Administrator Settings</h5>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <div className="dash-content-card">
            <Alert variant={'success'} className={`${show ? '' : 'd-none'}`}>
              Password has been changed successfully
            </Alert>
            <div className="cmmn-form">
              <Form>
                <Form.Group className="mb-3" controlId="formBasicName">
                  <Form.Label>
                    Username <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Name"
                    name="admin_name"
                    onChange={handleChange}
                    value={passwordDetail?.admin_name}
                  />
                  {clicked && validate()?.admin_name ? (
                    <div className={'danger-frm'}>{clicked && validate().admin_name}</div>
                  ) : (
                    <></>
                  )}
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>
                    Email<span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="info@almabay.com"
                    name="admin_email"
                    onChange={handleChange}
                    value={passwordDetail?.admin_email}
                  />

                  {clicked && validate()?.admin_email ? (
                    <div className={'danger-frm'}>{clicked && validate().admin_email}</div>
                  ) : (
                    <></>
                  )}
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label>
                    Old Password<span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type="Password"
                    placeholder="Old Password"
                    name="old_pass"
                    onChange={handleChange}
                  />
                  {clicked && validate()?.old_pass ? (
                    <div className={'danger-frm'}>{clicked && validate().old_pass}</div>
                  ) : (
                    <></>
                  )}
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label>
                    New Password<span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type="Password"
                    placeholder="New Password"
                    name="new_pass"
                    onChange={handleChange}
                  />
                  {clicked && validate()?.new_pass ? (
                    <div className={'danger-frm'}>{clicked && validate().new_pass}</div>
                  ) : (
                    <></>
                  )}
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label>
                    Confirm New Password<span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type="Password"
                    placeholder="Confirm New Password"
                    name="new_pass2"
                    onChange={handleChange}
                  />
                  {clicked && validate()?.new_pass2 ? (
                    <div className={'danger-frm'}>{clicked && validate().new_pass2}</div>
                  ) : (
                    <></>
                  )}
                </Form.Group>
                <div className="form-btns d-flex align-items-center mt-5">
                  <Button className="main-btn-grn" type="button" onClick={handleSubmit}>
                    Save
                  </Button>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}
