/**
@copyright  :   ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
@author     :   Shiv Charan Panjeta 
 
All Rights Reserved.
Proprietary and confidential :  All information contained here in is, and remains
the property of ToXSL Technologies Pvt. Ltd. and it's partners.
Unauthorized copying of this file, via any medium is strictly prohibited.
*/

import { useEffect, useState } from 'react';
import { Alert, Button, Container } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Table from 'react-bootstrap/Table';
import ReactPaginate from 'react-paginate';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import NoDataFound from '../../../components/commoncomponents/NoDataFound';
import Loader from '../../../components/commoncomponents/loader/loader-large';
import useDocumentTitle from '../../../globals/useDocumentTitle';
import {
  deleteCategoryList,
  getShopCategoryList,
  remaionActionCategoryList,
  setMultipleCategoryStatus
} from '../../../services/AuthServices';

function CategoryList() {
  useDocumentTitle('Manage Categories | AlmaBay');
  const isAlert = useSelector((state) => state.alert);
  const [chekcedId, setCheckedId] = useState([]);
  const [allId, setAllId] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [orderBy, setOrderBy] = useState('DESC');
  const [shortOrderBy, setShortOrderBy] = useState('id');
  const [totalCount, setTotalCount] = useState('');
  const [list, setList] = useState([]);
  const navigate = useNavigate();
  const [response, setResponse] = useState();
  const [loading, setLoading] = useState(false);
  const [massageView, setMassageView] = useState('');
  const [filterState, setFilterState] = useState({
    catId: '',
    catName: '',
    catSlug: '',
    catLevel: '',
    catStatus: ''
  });

  const [showalert, setShowAlert] = useState(false);
  const [record, setRecord] = useState();
  const [alert, setAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState('');
  const [searchIsClicked, setSearchIsClicked] = useState(false);

  useEffect(() => {
    const timeId = setTimeout(() => {
      setAlert(false);
      setAlertMsg('');
    }, 3000);
    return () => {
      clearTimeout(timeId);
    };
  }, [alert]);

  useEffect(() => {
    if (isAlert?.showAlertBox) {
      setAlertMsg(isAlert?.message);
      setAlert(true);
    }
  }, []);

  useEffect(() => {
    apiCall();
  }, []);

  useEffect(() => {
    const timeId = setTimeout(() => {
      // After 3 seconds set the show value to false
      setShowAlert(false);
      setMassageView('');
    }, 3000);

    return () => {
      clearTimeout(timeId);
    };
  }, [showalert]);

  const apiCall = (type, currentSort, page = 1) => {
    setLoading(true);
    let body = {
      catId: filterState?.catId,
      catName: filterState?.catName.trim(),
      catSlug: filterState?.catSlug.trim(),
      catLevel: filterState?.catLevel,
      catStatus: filterState?.catStatus,
      sortfield: type ? type : shortOrderBy,
      order: currentSort ? currentSort : orderBy,
      page
    };
    getShopCategoryList(body).then((res) => {
      setLoading(false);
      if (res?.status === 200) {
        setList(res?.data?.data?.data);
        setResponse(res?.data?.per_page);
        setAllId(res?.data?.data?.data?.map((data) => data?.id));
        setTotalCount(res?.data?.data?.total_rows);
      }
    });
  };

  const resetApiCall = () => {
    setLoading(true);
    let body = {
      catId: '',
      catName: '',
      catSlug: '',
      catLevel: '',
      catStatus: '',
      sortfield: shortOrderBy,
      order: orderBy,
      page: currentPage
    };
    getShopCategoryList(body).then((res) => {
      setLoading(false);
      if (res?.status === 200) {
        setList(res?.data?.data?.data);
        setResponse(res?.data?.data?.pager);
        setAllId(res?.data?.data?.data?.map((data) => data?.id));
        setTotalCount(res?.data?.data?.total_rows);
      }
    });
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilterState({
      ...filterState,
      [name]: value
    });
  };

  const handlePageClick = (a) => {
    let page = a?.selected + 1;
    setCurrentPage(page);
    apiCall(shortOrderBy, orderBy, page);
  };

  const sortHandler = (e, type) => {
    e.preventDefault();
    let currentSort = orderBy === 'ASC' ? 'DESC' : 'ASC';
    setOrderBy(currentSort);
    setShortOrderBy(type);
    apiCall(type, currentSort);
  };
  const globalStateHandler = () => {
    setShowAlert(true);
    // setShowAlert1(true);
  };
  const handlePerformAction = (e) => {
    if (chekcedId.length !== 0) {
      setShowAlert(false);

      let body = {
        ids: chekcedId,
        selectedAction: e.target.value
      };

      setMultipleCategoryStatus(body).then((res) => {
        if (res?.status === 200) {
          setMassageView(res?.data?.message);
          setShowAlert(true);

          apiCall();
          setCheckedId([]);
          // setCheckedId1([]);
        }
      });
    } else {
      globalStateHandler();
    }
  };

  const selectHandler = (value, id) => {
    switch (value) {
      case 'edit':
        navigate(`/admin/categories/create`, { state: id });
        break;
      case 'view':
        navigate('/admin/view/category', { state: id });
        break;
      case 'enable':
        handleAction(id, value);
        break;
      case 'disable':
        handleAction(id, value);
        break;
      case 'delImg':
        handleAction(id, value);
        break;
      case 'delete':
        handleDelete(id);
        break;
      default:
        break;
    }
  };

  const handleDelete = (id) => {
    if (confirm('Are you sure you want to delete the record?') === true) {
      deleteCategoryList(id).then((resp) => {
        if (resp?.status == 200) {
          document.getElementById('select').value == '';
          setAlert(true);
          setAlertMsg('Category has been deleted successfully');
          apiCall();
        }
      });
    } else {
      return;
    }
  };

  const handleAction = (id, option) => {
    if (option == 'delImg') {
      if (confirm('Are you sure you want to delete uploaded Image?') === true) {
        remaionActionCategoryList(id, option).then((resp) => {
          if (resp?.status == 200) {
            document.getElementById('select').value == '';
            setAlert(true);
            setAlertMsg(`Image has been successfully deteted of Catgeory id-${id}`);
            apiCall();
          }
        });
      } else {
        return;
      }
    } else if (option === 'enable' || option === 'disable') {
      remaionActionCategoryList(id, option).then((resp) => {
        if (resp?.status == 200) {
          document.getElementById('select').value == '';
          setAlert(true);
          setAlertMsg(`Catgeory id-${id} has been ${option} successfully`);
          apiCall();
        }
      });
    } else {
      remaionActionCategoryList(id, option).then((resp) => {
        if (resp?.status == 200) {
          document.getElementById('select').value == '';
          setAlert(true);
          setAlertMsg(resp?.data?.message);
          apiCall();
        }
      });
    }
  };

  const handleResetFilter = () => {
    setFilterState({
      catId: '',
      catName: '',
      catSlug: '',
      catLevel: '',
      catStatus: '',
      sortfield: '',
      order: ''
    });
    resetApiCall();
  };

  /**
   * function call after check all
   * @param {*} e
   */
  const handleSelectAll = (e) => {
    if (e.target.checked === true) {
      allId.forEach((id) => {
        if (!chekcedId.includes(id)) {
          setCheckedId((prev) => {
            return [...prev, id];
          });
        }
      });
    } else {
      setCheckedId([
        ...chekcedId.filter((id) => {
          return allId.indexOf(id) == -1;
        })
      ]);
    }
  };

  /**
   * function for individual chechbox
   * @param {*} e
   * @param {*} id
   */
  const handleMultipleCheckBox = (e, id) => {
    if (chekcedId.includes(id)) {
      setCheckedId(
        chekcedId.filter((data) => {
          return data !== id;
        })
      );
    } else {
      setCheckedId([...chekcedId, id]);
    }
  };

  return (
    <>
      <div className="dash-main-content">
        <Container fluid>
          <div className="dash-page-content">
            <div className="dash-page-title">
              <Row>
                <Col xs={12} sm={12} md={12}>
                  <div className="dash-title-bar d-flex align-items-center justify-content-between">
                    <div className="title-lft d-flex align-items-center">
                      {/* <div className="dash-icn">
                          <i className="fa fa-users"></i>
                        </div> */}
                      <div className="dash-title-dis">
                        <h5>
                          Categories <span>Listing</span>
                        </h5>
                        <h5>
                          Total Record(s): <span>{totalCount ? totalCount : '0'} </span>
                        </h5>
                      </div>
                    </div>
                    <div className="title-rt-btn d-flex align-items-center">
                      <Link className="green_button_admin mx-2" to="/admin/categories/create">
                        Add New Category
                      </Link>
                      <div className="head-instite-sec ">
                        <Form.Select
                          className=""
                          aria-label="Default select example "
                          onChange={handlePerformAction}>
                          <option>Choose Action</option>
                          <option value="enable">Enable</option>
                          <option value="disable">Disable</option>
                          <option value="del">Delete</option>
                        </Form.Select>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="dash-content-card">
              <Alert variant={'success'} className={`${alert ? '' : 'd-none'}`}>
                {alertMsg}
              </Alert>
              <Alert
                variant={massageView ? 'success' : 'danger'}
                className={`${showalert ? '' : 'd-none'}`}>
                {massageView ? massageView : 'Please select any record'}
              </Alert>

              <div className="table-responsive">
                <Table className="custom-table-data" striped bordered hover>
                  <thead>
                    <tr>
                      <th>
                        <Form.Check
                          type="checkbox"
                          id="checkbox"
                          label=""
                          checked={
                            allId?.every((elem) => {
                              return chekcedId.includes(elem);
                            }) && list?.length > 0
                              ? true
                              : false
                          }
                          onChange={(e) => handleSelectAll(e)}
                        />
                      </th>
                      <th className="id-width">
                        <Link
                          to="#"
                          onClick={(e) => {
                            sortHandler(e, 'id');
                          }}>
                          Id
                          <img src={require('../../../assets/images/sort.png')} alt="sort" />
                        </Link>
                      </th>
                      <th>
                        <Link
                          to=""
                          onClick={(e) => {
                            sortHandler(e, 'cat_name');
                          }}>
                          Category Name
                          <img src={require('../../../assets/images/sort.png')} alt="sort" />
                        </Link>
                      </th>
                      <th>
                        <Link
                          to=""
                          onClick={(e) => {
                            sortHandler(e, 'cat_slug');
                          }}>
                          Slug
                          <img src={require('../../../assets/images/sort.png')} alt="sort" />
                        </Link>
                      </th>
                      {/* <th>
                        <Link
                          to=""
                          onClick={(e) => {
                            sortHandler(e, 'cat_level');
                          }}>
                          Level
                          <img src={require('../../../assets/images/sort.png')} alt="sort" />
                        </Link>
                      </th> */}
                      <th>
                        <Link
                          to=""
                          onClick={(e) => {
                            sortHandler(e, 'status');
                          }}>
                          Status
                          <img src={require('../../../assets/images/sort.png')} alt="sort" />
                        </Link>
                      </th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="search-table">
                      <td></td>
                      <td>
                        <Form.Control
                          type="text"
                          placeholder="Id"
                          name="catId"
                          value={filterState?.catId}
                          onChange={handleFilterChange}
                        />
                      </td>
                      <td>
                        <Form.Control
                          type="text"
                          placeholder="category Name"
                          name="catName"
                          value={filterState?.catName}
                          onChange={handleFilterChange}
                        />
                      </td>
                      <td>
                        <Form.Control
                          type="text"
                          placeholder="slug"
                          name="catSlug"
                          value={filterState?.catSlug}
                          onChange={handleFilterChange}
                        />
                      </td>
                      {/* <td>
                        <Form.Control
                          type="text"
                          placeholder="level"
                          name="catLevel"
                          value={filterState?.catLevel}
                          onChange={handleFilterChange}
                        />
                      </td> */}
                      <td>
                        <Form.Select
                          aria-label="Default select example"
                          name="catStatus"
                          value={filterState?.catStatus}
                          onChange={handleFilterChange}>
                          <option value="">- Select Status -</option>
                          <option value="1">Enable</option>
                          <option value="0">Disable</option>
                        </Form.Select>
                      </td>
                      <td className="serac-btns text-center">
                        <button
                          type="button"
                          className="green_button_admin"
                          onClick={() => {
                            apiCall(shortOrderBy, orderBy), setSearchIsClicked(true);
                          }}>
                          Search
                        </button>
                        <Button
                          className="main-btn-red ms-2"
                          onClick={() => {
                            setSearchIsClicked(false);
                            handleResetFilter();
                          }}>
                          Reset
                        </Button>
                      </td>
                    </tr>
                    {list?.length !== 0 ? (
                      <>
                        {list?.map((item, index) => {
                          return (
                            <tr key={index}>
                              <td>
                                <Form.Check
                                  type="checkbox"
                                  label=""
                                  id={`id${item?.id}`}
                                  name={item?.cat_name}
                                  checked={chekcedId?.includes(item?.id)}
                                  onChange={(e) => {
                                    handleMultipleCheckBox(e, item?.id);
                                  }}
                                />
                              </td>
                              <td>{item?.id}</td>
                              <td>{item?.cat_name}</td>
                              <td>{item?.cat_slug}</td>
                              {/* <td>{item?.cat_level == 0 ? 'Root' : item?.cat_level}</td> */}
                              <td>{item?.status == 1 ? 'Enable' : 'Disable'}</td>
                              <td>
                                <Form.Select
                                  aria-label="Default select example"
                                  id="select"
                                  onChange={(e) => {
                                    selectHandler(e.target.value, item?.id);
                                  }}
                                  value="">
                                  <option value="">Action</option>
                                  <option value={item?.status == 1 ? 'disable' : 'enable'}>
                                    {item?.status == 1 ? 'Disable' : 'Enable'}
                                  </option>
                                  <option value="edit">Edit</option>
                                  <option value="view">View</option>
                                  <option value="delImg">Delete Image Only</option>
                                  <option value="delete">Delete</option>
                                </Form.Select>
                              </td>
                            </tr>
                          );
                        })}
                      </>
                    ) : (
                      <tr className="text-center">
                        <td colSpan={9}>
                          <NoDataFound />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
                {/* for hiding pagination when search */}
                {searchIsClicked ? (
                  <></>
                ) : (
                  <>
                    {' '}
                    <div className="react-page d-flex">
                      {Math.ceil(totalCount / 10) > 1 ? (
                        <ReactPaginate
                          previousLabel={currentPage > 0 ? '<' : ''}
                          nextLabel=">"
                          onPageChange={handlePageClick}
                          pageCount={Math.ceil(totalCount / 10)}
                          activeClassName={'page-link active'}
                        />
                      ) : (
                        ''
                      )}
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </Container>
      </div>
      {loading ? <Loader /> : ''}
    </>
  );
}

export default CategoryList;
