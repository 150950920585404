import { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { Link, useNavigate, useParams } from 'react-router-dom';
import ReactStars from 'react-stars';
import { ZERO } from '../../../globals/constants';
import { truncate } from '../../../globals/helper';
import useDocumentTitle from '../../../globals/useDocumentTitle';
import {
  getCatalogueProducts,
  shopAllBrowseInstitutes,
  shopAllInstitutes,
  shopFilterProducts
} from '../../../services/ShopServices';
import FooterGrey from '../../commoncomponents/FooterGrey';
import HeaderShop from '../../commoncomponents/HeaderShop';
import NoDataFound from '../../commoncomponents/NoDataFound';
import Loader from '../../commoncomponents/loader/loader-large';
import NewsLetter from './NewsLetter';

function ShopCatalog() {
  useDocumentTitle('Product Listing | AlmaBay');
  let { shortcode } = useParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [browseInstitute, setBrowseInstitute] = useState([]);
  const [allInstitute, setAllInstitute] = useState([]);
  const [instituteId, setInstituteId] = useState('');
  const [stateName, setStateName] = useState();
  const [activeClassState, setIsActiveClassState] = useState('lst-view');
  const [CatalogueProducts, setCatalogueProducts] = useState();
  const [institueCatalog, setInstituteCatalog] = useState();
  const [tempFilterdBrowseInstitute, setTempFilterdBrowseInstitute] = useState([]);
  const [isOpen, setIsopen] = useState(true);
  const ToggleSidebar = () => {
    isOpen === true ? setIsopen(false) : setIsopen(true);
  };
  useEffect(() => {
    getAllProducts();
  }, []);
  useEffect(() => {
    getAllBrowseInstitute();
    getShopAllInstitutes();
  }, [shortcode]);
  // get list of Browse Institutes
  const getAllBrowseInstitute = () => {
    shopAllBrowseInstitutes().then((res) => {
      if (res?.status === 200) {
        setBrowseInstitute(res?.data?.data);
      }
    });
  };

  // list of all institutes
  const getShopAllInstitutes = () => {
    shopAllInstitutes().then((resp) => {
      if (resp?.status === 200) {
        setAllInstitute(resp?.data?.data);
      }
    });
  };
  //Get Catalogue products

  const getAllProducts = async (institute_id = instituteId) => {
    setIsLoading(true);
    getCatalogueProducts(institute_id)
      .then((res) => {
        setIsLoading(false);
        if (res?.status === 200) {
          setIsLoading(false);
          setCatalogueProducts(res?.data?.data?.productInfo);
          setTempFilterdBrowseInstitute(res?.data?.data?.productInfo);
          setInstituteCatalog(res?.data?.data?.instituteInfo);
          setInstituteId(res?.data?.data?.selectedInstitute);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };
  const getFilterProducts = async (
    code = shortcode ? shortcode : 'DAV',
    institute_id = instituteId
  ) => {
    setIsLoading(true);
    shopFilterProducts(code, institute_id)
      .then((res) => {
        setIsLoading(false);
        if (res?.status === 200) {
          setIsLoading(false);
          setStateName(res?.data?.data?.productOf);
          setInstituteId(res?.data?.data?.selectedInstitute);
          // filter on change
          navigate(`/shop/products/${code}`);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  const filterHandler = (item) => {
    if (typeof item === 'string') {
      getFilterProducts(item, '');
    } else if (typeof item === 'object') {
      let temp = '';
      for (let i in item) {
        temp = item[i].split('/')[2];
      }
      getFilterProducts(temp, '');
    }
  };
  const handleInstituteChange = (e) => {
    e.preventDefault();
    setInstituteId(e.target.value);
    getAllProducts(e.target.value);
  };

  const handleInstitute = () => {
    shopAllInstitutes().then((resp) => {
      if (resp?.status === 200) {
        const data = Object.keys(resp?.data?.data);
        const order = data?.map((item) => {
          return item;
        });
        return order;
      }
    });
  };
  const handleKeyPress = (e) => {
    const keyVal = e.keyCode;
    if (
      keyVal === 191 ||
      keyVal === 190 ||
      keyVal === 220 ||
      keyVal === 110 ||
      keyVal === 219 ||
      keyVal === 221 ||
      keyVal === 106 ||
      keyVal === 107 ||
      keyVal === 109 ||
      keyVal === 111 ||
      keyVal === 48 ||
      keyVal === 57
    ) {
      e.preventDefault();
    }
  };
  const sortRecord = (stype, searchText = '') => {
    let updatedFilter = tempFilterdBrowseInstitute;

    if (stype == 1) {
      updatedFilter = tempFilterdBrowseInstitute.sort((p1, p2) => p1.name.localeCompare(p2.name));
    } else if (stype == 2) {
      updatedFilter = tempFilterdBrowseInstitute.sort((p1, p2) => p1.price - p2.price);
    } else if (stype == 3) {
      updatedFilter = tempFilterdBrowseInstitute.sort((p1, p2) => p2.price - p1.price);
    }

    if (searchText.trim() && searchText != '') {
      updatedFilter = tempFilterdBrowseInstitute.filter((item) => {
        return item?.name?.toLowerCase().search(searchText.toLowerCase()) !== -1;
      });
    }

    setCatalogueProducts(updatedFilter);
  };

  return (
    <>
      <HeaderShop />
      <section className="breadcrum py-4">
        <Container>
          <Row className="align-items-center">
            <Col md={8}>
              <Breadcrumb>
                <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                <Breadcrumb.Item href="/shop">Alma Store</Breadcrumb.Item>
                <Breadcrumb.Item active>
                  {shortcode?.charAt(0)?.toUpperCase() + shortcode?.slice(1)
                    ? shortcode?.charAt(0)?.toUpperCase() + shortcode?.slice(1)
                    : 'Catalog'}
                </Breadcrumb.Item>
              </Breadcrumb>
            </Col>
            <Col md={4}>
              {tempFilterdBrowseInstitute == null ? (
                <div className="bread-search">
                  <Form.Group className="mb-0" controlId="formBasicEmail">
                    <Form.Control type="text" placeholder={'Search Within Catalog'} />
                  </Form.Group>
                </div>
              ) : (
                <div className="bread-search">
                  <Form.Group className="mb-0" controlId="formBasicEmail">
                    <Form.Control
                      type="text"
                      placeholder={'Search Within Catalog'}
                      onChange={(e) => sortRecord('search', e.target.value)}
                      onKeyDown={(e) => handleKeyPress(e)}
                    />
                  </Form.Group>
                </div>
              )}
            </Col>
          </Row>
        </Container>
      </section>

      <section className="product-view-sec py-3">
        <Container>
          <div className="product-view-card">
            <Row>
              <Col lg={3} md={12}>
                <div className="product-lft-list">
                  <div className="product-lft-hd" role="presentation" onClick={ToggleSidebar}>
                    <h4>BROWSE INSTITUTES</h4>
                  </div>
                  <div
                    className={isOpen == true ? 'institute-lst-card active' : 'institute-lst-card'}>
                    {(browseInstitute && browseInstitute?.length !== ZERO) || null ? (
                      browseInstitute?.map((item, index) => {
                        return (
                          <ul key={index}>
                            <li>
                              <Link
                                onClick={() => {
                                  filterHandler(item);
                                }}>
                                {Object.keys(item)}
                              </Link>
                            </li>
                          </ul>
                        );
                      })
                    ) : (
                      <NoDataFound text="Sorry, we are unable to finding any product. You can explore other exciting features of AlmaBay." />
                    )}
                  </div>
                </div>
              </Col>
              <Col lg={9} md={12}>
                <div className="product-count-views">
                  <div className="product-count-hdr d-flex align-items-center justify-content-between mb-3">
                    <div className="product-count-hd">
                      <h4>{stateName ? stateName : shortcode}</h4>
                    </div>

                    {CatalogueProducts?.length !== ZERO ? (
                      <div className="product-hd-btn">
                        <Link
                          className={activeClassState == 'lst-view' ? 'active' : ''}
                          to="#"
                          onClick={(e) => {
                            e?.preventDefault();
                            let element = document.getElementById('lst-id');
                            element.classList.remove('list-blk');
                            setIsActiveClassState('lst-view');
                          }}>
                          <i className="fas fa-th-large"></i>
                        </Link>
                        <Link
                          className={activeClassState == 'list-blk' ? 'active' : ''}
                          to="#"
                          onClick={(e) => {
                            e?.preventDefault();
                            let element = document.getElementById('lst-id');
                            element.classList.add('list-blk');
                            setIsActiveClassState('list-blk');
                          }}>
                          <i className="fas fa-list"></i>
                        </Link>
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                  <div className="institue-search d-flex align-items-center justify-content-between mb-4">
                    <div className="inst-search-frm d-flex align-items-center">
                      <Form.Label className="me-1">
                        <b>Institute:</b>
                      </Form.Label>
                      <select
                        className="institute_filter"
                        value={instituteId}
                        onChange={(e) => handleInstituteChange(e)}>
                        {allInstitute && Object.keys(allInstitute)?.length !== ZERO ? (
                          Object.keys(allInstitute).map((list, i) => {
                            return (
                              <option value={list} key={i} onClick={handleInstitute}>
                                {allInstitute[list]}
                              </option>
                            );
                          })
                        ) : (
                          <NoDataFound text="Sorry, we are unable to finding any product. You can explore other exciting features of AlmaBay." />
                        )}
                      </select>
                    </div>
                    <div className="sort-card d-flex align-items-center">
                      <Form.Label className="me-1">
                        <b>Sort:</b>
                      </Form.Label>
                      {tempFilterdBrowseInstitute == null ? (
                        <Form.Select aria-label="Default select example">
                          <option value="">Select</option>
                          <option value={1}>A-Z</option>
                          <option value={2}>Price: Min to Max</option>
                          <option value={3}>Price: Max to Min</option>
                        </Form.Select>
                      ) : (
                        <Form.Select
                          aria-label="Default select example"
                          onChange={(e) => {
                            sortRecord(e.target.value);
                          }}>
                          <option value="">Select</option>
                          <option value={1}>A-Z</option>
                          <option value={2}>Price: Min to Max</option>
                          <option value={3}>Price: Max to Min</option>
                        </Form.Select>
                      )}
                    </div>
                  </div>
                  <div className="product-count-grid">
                    <Row className="lst-view" id="lst-id">
                      {CatalogueProducts && CatalogueProducts?.length !== ZERO ? (
                        CatalogueProducts?.map((item, index) => {
                          return (
                            <Col lg={3} md={6} key={index}>
                              <div className="product-card">
                                <div className="product-img">
                                  <Link to={`/shop/details/${item.id}`} state={shortcode}>
                                    <img
                                      src={
                                        process.env.REACT_APP_API_DOMAIN +
                                        'common/uploads/products/' +
                                        item?.cover_image
                                      }
                                      alt=""
                                    />
                                  </Link>
                                </div>
                                <div className="product-dis">
                                  <Link to={`/shop/details/${item.id}`} state={shortcode}>
                                    <ul className="rating-star">
                                      <li>
                                        <ReactStars
                                          className="star_rating"
                                          size={20}
                                          count={5}
                                          value={item?.ratings[0]?.ratings_score}
                                          color2={'#f7ad1c'}
                                          edit={false}
                                        />
                                      </li>
                                      <li>
                                        {' '}
                                        {item?.ratings[0] ? `(${item?.ratings[0]?.COUNT})` : ''}
                                      </li>
                                    </ul>
                                  </Link>
                                  <Link to={`/shop/details/${item.id}`}>
                                    <h4 className="productItemName">{truncate(item.name, 30)}</h4>
                                  </Link>

                                  <h3>
                                    <i className="fas fa-rupee-sign me-1"> {item?.price} </i>
                                  </h3>
                                  <Link
                                    className="wht-btn"
                                    to={`/shop/details/${item.id}`}
                                    state={shortcode}>
                                    Buy Now
                                  </Link>
                                </div>
                              </div>
                            </Col>
                          );
                        })
                      ) : (
                        <NoDataFound text="Sorry, we are unable to finding any product. You can explore other exciting features of AlmaBay." />
                      )}
                    </Row>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </section>
      <section className="institute-view py-5">
        <Container>
          <div className="institute-inr">
            <Row>
              {institueCatalog && institueCatalog != ZERO ? (
                institueCatalog?.map((data, index) => {
                  return (
                    <Col lg={3} md={6} key={index}>
                      <div className="inst-view-card">
                        <Link to={`/shop/products/${data?.shortcode}`} state={data?.name}>
                          <div className="inst-view-icn">
                            <Link onClick={() => filterHandler(data?.institute_id)}>
                              <img
                                src={
                                  process.env.REACT_APP_API_DOMAIN +
                                  '/common/uploads/institute_logo/' +
                                  data?.logo
                                }
                                alt="img"
                              />
                            </Link>
                          </div>
                          <div className="inst-view-dis">
                            <h4>{data?.name}</h4>
                          </div>
                        </Link>
                      </div>
                    </Col>
                  );
                })
              ) : (
                <NoDataFound text="No Insitute found" />
              )}
            </Row>
          </div>
        </Container>
      </section>
      <NewsLetter />

      <FooterGrey state={'state'} />
      {isLoading ? <Loader /> : <></>}
    </>
  );
}

export default ShopCatalog;
