/**  @copyright : ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
@author     : Shiv Charan Panjeta < shiv@toxsl.com
All Rights Reserved.
Proprietary and confidential :  All information contained herein is, and remains
the property of ToXSL Technologies Pvt. Ltd. and its partners.
Unauthorized copying of this file, via any medium is strictly prohibited.
**/
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import ReactDatePicker from 'react-datepicker';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import Loader from '../../components/commoncomponents/loader/loader-large';
import { emailRegex, getYearDropDown, restrictAlpha } from '../../globals/helper';
import useDocumentTitle from '../../globals/useDocumentTitle';
import { ShowAlert } from '../../redux/action';
import * as adminServices from '../../services/AuthServices';
import showNotification from '../../services/NotificationService';
import Footer from '../CommonComponents/Footer';
import Header from '../CommonComponents/Header';
import SideBar from '../CommonComponents/Sidebar';
import { EIGHTEEN, FIFTEEN } from '../../globals/constants';
export default function AddUser() {
  const [yearOptions, setYearOptions] = useState();
  const [searchParams, setSearchParams] = useSearchParams();
  const type = searchParams.get('type');
  const location = useLocation();
  const id = location.state;
  const navigate = useNavigate();
  const [insId, setInsId] = useState('');
  const dispatch = useDispatch();
  const [startDate, setStartDate] = useState(null);
  const dobYear = new Date(startDate).getFullYear() + EIGHTEEN;
  const [detail, setDetail] = useState({
    first_name: '',
    last_name: '',
    gender: '',
    relationship_status: '',
    city: '',
    email: '',
    mobile: '',
    website: '',
    institute_name: '',
    qualification_type: '',
    to_year: '',
    currently_studing_here: 0,
    user_qualification_id: ''
  });

  const [clicked, setClicked] = useState(false);
  const [loading, setLoading] = useState(false);
  const [instituteList, setInstituteList] = useState([]);
  const [locationId, setLocationId] = useState('');
  const [inputMessage, setInputMessage] = useState('Please enter atleast 2 characters');
  const [loadOpt, setLoadOpt] = useState(false);
  useEffect(() => {
    getInstituteDropdownData();
    getYearDropDown(setYearOptions);
    if (id) {
      getDetail(id);
    }
  }, []);

  const urlTest = new RegExp(
    /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?/gm
  );

  const validate = () => {
    let error = {};
    if (detail?.first_name == '') {
      error.first_name = 'Please enter first name';
    }
    if (detail?.last_name == '') {
      error.last_name = 'Please enter last name';
    }
    if (detail.gender == '') {
      error.gender = 'Please select gender';
    }
    if (startDate == '' || startDate == null) {
      error.dob = 'Please select dob';
    }
    if (detail?.city == '') {
      error.city = 'Please enter your current Location';
    }
    if (detail?.email == '') {
      error.email = 'Please enter your email.';
    }
    if (!emailRegex.test(detail.email)) {
      error.email = 'Please enter valid email';
    }
    // if (!detail.mobile) {
    //   error.mobile = 'Please enter your mobile number';
    // } else if (detail.mobile.length <= 9) {
    //   error.mobile = 'Please enter atleast 10 digit';
    // }

    if (detail?.mobile) {
      if (detail?.mobile.length < 10) {
        error.mobile = 'Please enter 10 digit  mobile no';
      }
      // else if (detail?.mobile.length > 10) {
      //   error.mobile = 'Please enter valid mobile no';
      // }
    }

    //   if (detail?.mobile.length < 10) {
    //     error.mobile = 'Please enter valid mobile no';
    //   } else if (detail?.mobile.length > 10) {
    //     error.mobile = 'Please enter valid mobile no';
    //   }
    // // }

    if (detail.website == '') {
      error.website = 'Please enter URL';
    }
    if (detail?.institute_name == '') {
      error.institute_name = 'Please enter your Institute Name';
    }
    if (!urlTest.test(detail?.website)) {
      error.website = 'Please enter valid URL';
    }
    if (detail?.institute_name == '') {
      error.institute_name = 'Please enter institute name';
    }

    if (detail?.currently_studing_here == 0) {
      if (detail?.currently_studing_here == 0 && detail.to_year == '') {
        error.to_year = 'Please enter your Passout Batch.';
      } else if (+detail?.to_year < dobYear) {
        error.to_year = 'Please select Passout batch greater than 18 years of DOB ';
      }
    }

    return error;
  };

  const onChangeHandler = (e) => {
    const { name, value } = e.target;
    setDetail({ ...detail, [name]: value });
  };

  const getDetail = (id) => {
    setLoading(true);
    let body = {
      id: id
    };
    adminServices.userDetail(body).then((res) => {
      setLoading(false);
      if (res?.status === 200) {
        const data = res?.data?.detail;

        setDetail({
          user_id: id,
          first_name: data?.user?.first_name,
          last_name: data?.user?.last_name,
          gender: data?.user?.gender,
          email: data?.user?.email,
          mobile: data?.user?.mobile,
          proffesion_type: data?.user?.proffesion_type,
          website: data?.user?.website,
          institute_name: data?.school_info[0]?.institute_id,
          to_year: data?.school_info[0]?.to_year,
          relationship_status: data?.user?.marital_status,
          city: data?.user?.current_city_id,
          user_qualification_id: data?.school_info[0]?.user_qualification_id,
          currently_studing_here: data?.school_info[0]?.currently_studing_here
            ? data?.school_info[0]?.currently_studing_here
            : 0
        });

        setStartDate(data?.user?.birthday);
        setInsId({
          id: data?.school_info[0]?.institute_id,
          label: data?.school_info[0]?.institute_name,
          name: data?.school_info[0]?.institute_name
        });
        setLocationId({
          id: data?.user?.current_city_id,
          label: data?.user?.current_city,
          value: data?.user?.current_city
        });
      }
    });
  };

  const submitHandler = (e) => {
    e.preventDefault();
    setClicked(true);

    if (!Object.keys(validate()).length) {
      setClicked(false);
      let formData = new FormData();
      for (let i in detail) {
        formData.append(`${i}`, detail[i]);
      }
      formData.append('dob', moment(startDate).format('YYYY-MM-DD'));
      adminServices.addUser(formData).then((res) => {
        if (res?.status === 200) {
          // showNotification('success', res?.data?.detail);
          navigate('/admin/users/showallusers');
          dispatch(ShowAlert());
        }
      });
    }
  };

  const updateHandler = (e) => {
    e.preventDefault();
    setClicked(true);

    if (!Object.keys(validate()).length) {
      setLoading(true);
      let formData = new FormData();
      for (let i in detail) {
        formData.append(`${i}`, detail[i]);
      }
      formData.append('dob', moment(startDate).format('YYYY-MM-DD'));
      adminServices.updateUser(detail?.user_id, formData).then((res) => {
        if (res?.status === 200) {
          showNotification('success', res?.data?.detail);
          navigate('/admin/users/showallusers');
          setLoading(false);
        }
      });
    }
  };

  const getInstituteDropdownData = () => {
    adminServices.getInstituteDropdown().then((res) => {
      let arr = [];
      if (res?.status === 200) {
        let data = res?.data?.data?.allInstitutes;
        for (let i in data) {
          arr.push({ id: i, label: data[i], name: data[i] });
        }
      }
      setInstituteList(arr);
    });
  };

  const loadCityOpt = async (inputValue) => {
    if (inputValue?.length >= 2) {
      setLoadOpt(true);
      return adminServices.getLocationDropDown(inputValue).then((res) => {
        setLoadOpt(false);
        if (res?.status === 200) {
          let tempArr = [];
          if (res?.data?.data) {
            const data = res?.data?.data;
            data?.map((data) => {
              tempArr.push({
                id: data?.id,
                label: data?.location,
                value: data?.location
              });
            });
          }

          if (res?.data?.data == '') {
            setInputMessage('No matches found ');
          }
          return tempArr;
        }
      });
    }
  };

  const inputMessageHandler = (e) => {
    if (e?.length !== 2 && e?.length < 2) {
      setInputMessage('Please enter atleast 2 characters.');
    }
  };

  return (
    <>
      <div className="dash-main-content">
        <Container fluid>
          <div className="dash-page-content">
            <div className="dash-page-title">
              <Row>
                <Col xs={12} sm={12} md={12}>
                  <div className="dash-title-bar d-flex align-items-center justify-content-between">
                    <div className="title-lft d-flex align-items-center">
                      <div className="icons_head">
                        <i className="fa fa-user"></i>
                      </div>
                      <div className="dash-title-dis">
                        {id
                          ? useDocumentTitle('Edit User | AlmaBay')
                          : useDocumentTitle('Add  User | AlmaBay')}

                        {<h5>{id ? 'Edit' : 'Add'} {type ? "Faculty" : "User"}</h5>}
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="dash-content-card">
              <div className="cmmn-form">
                <Form>
                  <Row>
                    <Col xs={12} md={6} lg={4}>
                      <Form.Group className="mb-3" controlId="formBasicPassword">
                        <Form.Label>
                          First Name <sup className="text-danger">*</sup>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter First Name"
                          value={detail?.first_name}
                          onChange={(e) => {
                            onChangeHandler(e);
                          }}
                          name="first_name"
                        />
                        <div
                          className={`${clicked && validate().first_name ? '' : 'd-none'
                            } danger-frm `}>
                          {clicked && validate().first_name}
                        </div>
                      </Form.Group>
                    </Col>

                    <Col xs={12} md={6} lg={4}>
                      <Form.Group className="mb-3" controlId="formBasicPassword">
                        <Form.Label>
                          Last Name <sup className="text-danger">*</sup>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter Last Name"
                          value={detail?.last_name}
                          onChange={(e) => {
                            onChangeHandler(e);
                          }}
                          name="last_name"
                        />
                        <div
                          className={`${clicked && validate().last_name ? '' : 'd-none'
                            } danger-frm `}>
                          {clicked && validate().last_name}
                        </div>
                      </Form.Group>
                    </Col>

                    <Col xs={12} md={6} lg={4}>
                      <Form.Group className="mb-3">
                        <Form.Label>
                          Select Gender
                          <sup className="text-danger">*</sup>
                        </Form.Label>
                        <Form.Select
                          value={detail?.gender}
                          name="gender"
                          onChange={(e) => {
                            onChangeHandler(e);
                          }}>
                          <option value="">Select Gender</option>
                          <option value="male">Male</option>
                          <option value="female">Female</option>
                          <option value="other">Other</option>
                        </Form.Select>
                        <div
                          className={`${clicked && validate().gender ? '' : 'd-none'} danger-frm `}>
                          {clicked && validate().gender}
                        </div>
                      </Form.Group>
                    </Col>

                    <Col xs={12} md={6} lg={4}>
                      <Form.Group className="mb-3 position-relative date-card" controlId="formBasicPassword">
                        <Form.Label htmlFor="datePicker">
                          Date of birth <sup className="text-danger">*</sup>
                        </Form.Label>
                        <ReactDatePicker
                          className="form-control w-100"
                          // selected={startDate}
                          onKeyDown={(e) => {
                            e.preventDefault();
                          }}
                          // openToDate={new Date(moment("01-01-2023").subtract(10, "years"))}
                          openToDate={new Date('2000-01-01')}
                          // minDate={moment().subtract(150, "years")._d}
                          // maxDate={moment().subtract(10, "years")._d}
                          onChange={(date) => setStartDate(date)}
                          // filterDate={filterFutureTime}
                          placeholderText="Enter date of birth"
                          value={startDate == null ? '' : moment(startDate).format('DD-MM-YYYY')}
                        />
                        <span style={{ position: 'absolute', right: 15, top: 35 }}>
                          <i className="fas fa-calendar-week"></i>
                        </span>
                        <div className={`${clicked && validate().dob ? '' : 'd-none'} danger-frm `}>
                          {clicked && validate().dob}
                        </div>
                      </Form.Group>
                    </Col>

                    <Col xs={12} md={6} lg={4}>
                      <Form.Group className="mb-3" controlId="formBasicPassword">
                        <Form.Label>Relationship Status</Form.Label>
                        <Form.Select
                          aria-label="Default select example"
                          value={detail?.relationship_status}
                          name="relationship_status"
                          onChange={(e) => {
                            onChangeHandler(e);
                          }}>
                          <option value="">Select Relationship Status</option>
                          <option value="single">Single</option>
                          <option value="in_relation">In a relationship</option>
                          <option value="engaged">Engaged</option>
                          <option value="married">Married</option>
                          <option value="open_relationship">In an open relationship</option>
                          <option value="complicated">It's complicated</option>
                          <option value="separated">Separated</option>
                          <option value="divorced">Divorced</option>
                          <option value="widowed">Widowed</option>
                        </Form.Select>
                      </Form.Group>
                    </Col>

                    <Col xs={12} md={6} lg={4}>
                      <Form.Group className="mb-3" controlId="formBasicPassword">
                        <Form.Label>
                          Current Location<sup className="text-danger">*</sup>
                        </Form.Label>
                        <AsyncSelect
                          isClearable={true}
                          getOptionLabel={(e) => e.label}
                          getOptionValue={(e) => e.value}
                          loadOptions={loadCityOpt}
                          className="basic-single mt-1"
                          name="city"
                          placeholder="Enter your Current Location"
                          onChange={(e) => {
                            setDetail({ ...detail, ['city']: e?.id });
                            setLocationId(e);
                          }}
                          value={locationId}
                          components={{
                            DropdownIndicator: () => null,
                            IndicatorSeparator: () => null,
                            LoadingIndicator: () => null
                          }}
                          onInputChange={(e) => {
                            inputMessageHandler(e);
                          }}
                          noOptionsMessage={() => inputMessage}
                        />
                        {loadOpt && (
                          <div
                            className="d-flex"
                            style={{ justifyContent: 'flex-end', marginTop: '-20px' }}>
                            <i className="fa fa-spinner fa-pulse me-2"></i>
                          </div>
                        )}

                        <div
                          className={`${clicked && validate().city ? '' : 'd-none'} danger-frm `}>
                          {clicked && validate().city}
                        </div>
                      </Form.Group>
                    </Col>

                    <Col xs={12} md={6} lg={4}>
                      <Form.Group className="mb-3 " controlId="formBasicPassword">
                        <Form.Label>
                          Email <sup className="text-danger">*</sup>
                        </Form.Label>
                        <Form.Control
                          type="email"
                          placeholder="Enter Email"
                          value={detail?.email}
                          onChange={(e) => {
                            onChangeHandler(e);
                          }}
                          name="email"
                        />
                        <div
                          className={`${clicked && validate().email ? '' : 'd-none'} danger-frm `}>
                          {clicked && validate().email}
                        </div>
                      </Form.Group>
                    </Col>

                    <Col xs={12} md={6} lg={4}>
                      <Form.Group className="mb-3" controlId="formBasicPassword">
                        <Form.Label>Mobile</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter mobile number"
                          value={detail?.mobile}
                          onChange={(e) => {
                            onChangeHandler(e);
                          }}
                          maxLength="10"
                          onKeyDown={(e) => restrictAlpha(e)}
                          name="mobile"
                        />
                        <div
                          className={`${clicked && validate().mobile ? '' : 'd-none'} danger-frm `}>
                          {clicked && validate().mobile}
                        </div>
                      </Form.Group>
                    </Col>

                    <Col xs={12} md={6} lg={4}>
                      <Form.Group className="mb-3" controlId="formBasicPassword">
                        <Form.Label>
                          Website <sup className="text-danger">*</sup>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter website name"
                          value={detail?.website}
                          onChange={(e) => {
                            onChangeHandler(e);
                          }}
                          name="website"
                        />
                        <div
                          className={`${clicked && validate().website ? '' : 'd-none'
                            } danger-frm `}>
                          {clicked && validate().website}
                        </div>
                      </Form.Group>
                    </Col>

                    <Col xs={12} md={6} lg={6}>
                      <Form.Group className="mb-3">
                        <Form.Label>
                          Institute Name <sup className="text-danger">*</sup>
                        </Form.Label>
                        <Select
                          className="basic-single mb-3"
                          isDisabled={false}
                          isLoading={false}
                          isClearable={false}
                          isRtl={false}
                          placeholder="- Select institute - "
                          isSearchable={true}
                          name="institute_name"
                          options={instituteList}
                          getOptionValue={(option) => option?.id}
                          value={insId}
                          onChange={(e) => {
                            setDetail({ ...detail, ['institute_name']: e?.id });
                            setInsId(e);
                          }}
                        />
                        <div
                          className={`${clicked && validate().institute_name ? '' : 'd-none'
                            } danger-frm `}>
                          {clicked && validate().institute_name}
                        </div>
                      </Form.Group>
                    </Col>
                    {detail?.currently_studing_here == 0 ? (
                      <Col xs={12} md={6} lg={6}>
                        <Form.Group className="mb-3" controlId="formBasicPassword">
                          <Form.Label>
                            Passout Batch <sup className="text-danger">*</sup>
                          </Form.Label>
                          <Form.Select
                            aria-label="Default select example"
                            value={detail?.to_year}
                            onChange={(e) => {
                              onChangeHandler(e);
                            }}
                            name="to_year">
                            <option value="">- Select Year -</option>
                            {yearOptions &&
                              yearOptions.map((data, index) => (
                                <option key={index} value={data?.to_year}>
                                  {data?.value}
                                </option>
                              ))}
                          </Form.Select>
                          <div
                            className={`${clicked && validate().to_year ? '' : 'd-none'
                              } danger-frm `}>
                            {clicked && validate().to_year}
                          </div>
                        </Form.Group>
                      </Col>
                    ) : (
                      ''
                    )}
                  </Row>
                  <Col xs={12} md={6} lg={6}>
                    <Form.Check
                      id="checkone"
                      label="Currently studying here"
                      checked={detail?.currently_studing_here == '1' ? true : false}
                      onChange={(e) => {
                        setDetail({
                          ...detail,
                          ['currently_studing_here']: e.target.checked ? 1 : 0
                        });
                      }}
                    />
                  </Col>

                  <div className="form-btns d-flex align-items-center mt-4">
                    {id ? (
                      <button
                        className="green_button_admin"
                        type="button"
                        onClick={(e) => {
                          updateHandler(e);
                        }}>
                        Save
                      </button>
                    ) : (
                      <button
                        className="green_button_admin"
                        type="button"
                        onClick={(e) => {
                          submitHandler(e);
                        }}>
                        Save
                      </button>
                    )}
                    <strong>OR</strong>
                    <Button
                      className="main-btn-red"
                      onClick={() => {
                        navigate(-1);
                      }}>
                      Cancel
                    </Button>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </Container>
      </div>
      {loading ? <Loader /> : ''}
    </>
  );
}
