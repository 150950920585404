import { Route, Routes } from 'react-router-dom';
import AdminRole from '../../components/webpages/user-pages/AdminRole';
import CompanyDetails from '../../components/webpages/user-pages/CompanyDetails';
import CompanyPage from '../../components/webpages/user-pages/CompanyPage';
import FresherJob from '../../components/webpages/user-pages/FresherJob';
import Internships from '../../components/webpages/user-pages/Internships';
import JobApplication from '../../components/webpages/user-pages/JobApplication';
import JobDetails from '../../components/webpages/user-pages/JobDetails';
import JobFair from '../../components/webpages/user-pages/JobFair';
import JobFairDetail from '../../components/webpages/user-pages/JobFairDetail';
import JobfairApplication from '../../components/webpages/user-pages/JobfairApplication';
import JobfairDetails from '../../components/webpages/user-pages/JobfairDetails';
import JobinNetwork from '../../components/webpages/user-pages/JobinNetwork';
import JobpostPage from '../../components/webpages/user-pages/JobpostPage';
import JobsApplied from '../../components/webpages/user-pages/JobsApplied';
import LoginFeeds from '../../components/webpages/user-pages/LoginFeeds';
import PeopleKnowPage from '../../components/webpages/user-pages/PeopleKnowPage';
import PostedJob from '../../components/webpages/user-pages/PostedJob';
import Recommends from '../../components/webpages/user-pages/Recommends';
import Referrals from '../../components/webpages/user-pages/Referrals';
import SearchJob from '../../components/webpages/user-pages/SearchJob';
import SettingGeneral from '../../components/webpages/user-pages/SettingGeneral';
import SettingPrivacy from '../../components/webpages/user-pages/SettingPrivacy';

function JobsRoutes() {
  return (
    <Routes>
      {/* profesional */}
      <Route path="/" element={<LoginFeeds />} />
      <Route path="/search/internship" element={<Internships />} />
      <Route path="/search/freshers" element={<FresherJob />} />
      <Route path="/search/network" element={<JobinNetwork />} />
      <Route path="/search/category/:id" element={<JobinNetwork />} />
      <Route path="/add" element={<JobpostPage />} />
      <Route path="/edit" element={<JobpostPage />} />
      <Route path="/search" element={<SearchJob />} />
      <Route path="/search/brand/:comp_id" element={<SearchJob />} />
      <Route path="/posted" element={<PostedJob />} />
      <Route path="/applied" element={<JobsApplied />} />
      <Route path="/recommends" element={<Recommends />} />
      <Route path="/people_know_page" element={<PeopleKnowPage />} />
      <Route path="/admin-role" element={<AdminRole />} />
      <Route path="/setting-privacy" element={<SettingPrivacy />} />
      <Route path="/setting-general" element={<SettingGeneral />} />
      <Route path="/jobs-details/:id" element={<JobDetails />} />
      {/* same client  */}
      <Route path="/details/:id" element={<JobDetails />} />
      <Route path="/referrals" element={<Referrals />} />
      {/* <Route path="/checkout-page" element={<CheckoutPage />} /> */}
      <Route path="/job-application/:id" element={<JobApplication />} />
      {/* <Route path="/company-details" element={<CompanyDetails />} /> */}
      <Route path="/companies/" element={<CompanyPage />} />
      <Route path="/fair" element={<JobFair />} />
      <Route path="/company-details/:id" element={<CompanyDetails />} />
      {/* <Route path="/company/index/:name" element={<CompanyDetails />} /> */}
      <Route path="/fair-detail/:id" element={<JobFairDetail />} />
      <Route path="/jobfair" element={<JobfairApplication />} />
      <Route path="/jobfair-details" element={<JobfairDetails />} />
    </Routes>
  );
}

export default JobsRoutes;
