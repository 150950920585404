import * as api from '../utils/requests';

export const getPostList = async (id, page = '') => {
  return await api.getReq(`/post/list?id=${id}&page=${page}`);
};

export const getPostComment = async (id) => {
  return await api.getReq(`/post/comments?id=${id}`);
};

export const getLikeUnlike = async (id) => {
  return await api.getReq(`/post/like-unlike?id=${id}`);
};

export const deletePost = async (id) => {
  return await api.delReq(`/post/delete?id=${id}`);
};

export const postComment = async (id, body) => {
  return await api.PostReq(`/post/add-comment?id=${id}`, body);
};

export const postLatest = async (body) => {
  return await api.PostReq(`/post/add?t=post&a=new&without_html=1`, body);
};

export const postLatestmultiple = async (body) => {
  return await api.PostReq(`/smedia/ImageDetails?t=post&a=new&without_html=1`, body);
};

export const postPrivacyList = async () => {
  return await api.getReq(`/post/privacy-list`);
};

export const postFollowUnfollow = async (id) => {
  return await api.getReq(`/post/follow-unfollow?id=${id}`);
};

export const addCampusNews = async (body) => {
  return await api.PostReq(`/campus/news-add`, body);
};

export const getAllCampusNews = async (pageNo) => {
  return await api.getReq(`/campus/news-list?page=${pageNo}`);
};

export const getSingleCampusDetail = async (id) => {
  return await api.getReq(`/campus/show?id=${id}`);
};

export const getAllBirthDay = async () => {
  return await api.getReq(`/user/birthday-list`);
};

export const uploadAlbumImage = async (body) => {
  return await api.PostReq(`/album/upload-photos`, body);
};

export const getCampusNewsComment = async (id) => {
  return await api.getReq(`campus/news/comment/list?id=${id}`);
};

export const addCampusNewsCommnet = async (body) => {
  return await api.PostReq(`/campus/news/comment/add`, body);
};

export const deleteCampusNewsComment = async (id) => {
  return await api.delReq(`/campus/news/comment/delete?id=${id}`);
};

export const getAllWorkAnniversy = async () => {
  return await api.getReq(`/user/getAnniversaryList`);
};

// /campus/mark-helpful
export const markCampusHelpFull = async (body) => {
  return await api.PostReq(`/campus/mark-helpful`, body);
};

export const getSinglePostDetail = async (id) => {
  return await api.getReq(`/post/detail?post_id=${id}`);
};

export const getEventCategoryDrop = async (search = '') => {
  return await api.getReq(`/event/life_skills_api/getEventCategories?q=${search}`);
};
export const getPagesCategories = async (search) => {
  return await api.getReq(`/page/pages/get_page_categories`);
};

export const addPage = async (body) => {
  return await api.PostReq(`/page/createPage`, body);
};

export const addEvents = async (body) => {
  return await api.PostReq(`/event/events`, body);
};

export const getEvents = async (page, params) => {
  return await api.getReq(`/event/userEventsList?filter=${params}&page=${page}`);
};

export const getUsersMap = async (country, city) => {
  return await api.getReq(`/admin/users/map?pt=${country}&place=${city}`);
};

export const getPagesYouManage = async (filter = '', offset = '') => {
  return await api.getReq(`/page/managePages?filter=${filter}&offset=${offset}&limit=6`);
};

export const getUserLikePages = async (search = '', offset = '') => {
  return await api.getReq(`page/userLikePages?filter=${search}&offset=${offset}&limit=6`);
};
export const getDescoverPages = async (page = '', offset = '', limit = 12) => {
  return await api.getReq(`/page/discover?page=${page}&offset=${offset}&limit=${limit}`);
};

export const getSocialPageDetail = async (id) => {
  return await api.getReq(`/page/show?id=${id}`);
};

export const addSocialPagePost = async (body) => {
  return await api.PostReq(`/page/createPost?t=post&a=new&without_html=1`, body);
};

export const getSocialPages = async (id, page, type) => {
  return await api.getReq(
    `/user/getStories?id=${id}&section=pages&timeline=0&type=${type}&page=${page}`
  );
};

export const getSocialPagePost = async (id, type = '', search = '', page = '') => {
  return await api.getReq(`/page/posts?id=${id}&type=${type}&search=${search}&page=${page}`);
};

export const PageLikeUnLike = async (body) => {
  return await api.PostReq(`/page/likeUnlike?t=follow&a=follow`, body);
};
export const beSponser = async (body) => {
  return await api.PostReq(`event/becomeSponsor`, body);
};
export const addActivity = async (body) => {
  return await api.PostReq(`/event/addActivity`, body);
};
export const addNewSponser = async (body) => {
  return await api.PostReq(`event/addSponsor`, body);
};

export const chapterLeaveAdmin = async (body) => {
  return await api.PostReq(`chapter/leaveAdmin?t=chapter&a=remove_admin`, body);
};

export const addSponser = async (body) => {
  return await api.PostReq(`event/becomeSponsor`, body);
};
export const getEventDetail = async (id) => {
  return await api.getReq(`/event/eventDetails?event_id=${id}`);
};

export const getEventPeople = async (body) => {
  return await api.PostReq(`/event/getEventPeopleList`, body);
};
export const getEventActivityDetail = async (event_id, id) => {
  return await api.getReq(`/event/detail_activity?event_id=${event_id}&id=${id}`);
};

// export const getSocialChapterDetail = async (id) => {
//   return await api.getReq(`/chapter/show?id=${id}`);
// };

export const getSocialChapterDetail = async (id) => {
  return await api.getReq(`/chapter/show?id=${id}`);
};

export const addSocialChapterPost = async (body) => {
  return await api.PostReq(`/chapter/createPost?t=post&a=new&without_html=1`, body);
};

export const SocialChapterSetting = async (body) => {
  return await api.PostReq(`/chapter/settings?t=chapter&a=update_settings`, body);
};

// export const discoverChapters = async (page = '') => {
//   return await api.getReq(`/chapter/discover?page=${page}`);
// };
export const discoverChapters = async (offset = '') => {
  return await api.getReq(`/chapter/userChapterSuggestions?offset=${offset}&limit=6`);
};

// export const getSocialChapterPost = async (id, type = '', search = '', page = '') => {
//   return await api.getReq(`/chapter/posts?id=${id}&type=${type}&search=${search}&page=${page}`);
// };

export const getSocialChapterPost = async (id, page, type) => {
  return await api.getReq(
    `/user/getStories?id=${id}&section=chapters&timeline=0&type=${type}&page=${page}`
  );
};
export const getSocialEventPost = async (id, page, type) => {
  return await api.getReq(
    `/user/getStories?id=${id}&section=events&timeline=0&type=${type}&page=${page}`
  );
};
export const getSocialGroupPost = async (id, page, type) => {
  return await api.getReq(
    `/user/getStories?id=${id}&section=groups&timeline=0&type=${type}&page=${page}`
  );
};
export const getJobFair = async (
  page = '',
  location = '',
  industry_search = '',
  company_search = '',
  eligibility_search = ''
) => {
  return await api.getReq(
    `/jobfair/getJobFairData?page=${page}&location=${location}&industry_search=${industry_search}&company_search=${company_search}&eligibility_search=${eligibility_search}`
  );
};

export const getJobFairDetail = async (id) => {
  return await api.getReq(`/jobfair/details?id=${id}`);
};

export const socialGroupLeave = async (body) => {
  return await api.PostReq(`/group/leaveGroup?t=group&a=remove_admin`, body);
};

export const SocialGroupSetting = async (body) => {
  return await api.PostReq(`/group/UpdateSettings?t=group&a=update_settings`, body);
};

export const socialPageSetting = async (body) => {
  return await api.PostReq(`/page/settings?t=page&a=update`, body);
};
export const getPagePeopleList = async (body, id, search = '', offset = '') => {
  return await api.PostReq(
    `/page/getPeopleList?id=${id}&q=${search}&offset=${offset}&limit=${12}`,
    body
  );
};

export const socialPageAddAdmin = async (body) => {
  return await api.PostReq(`/page/addAdmin?t=page&a=add_admin`, body);
};

export const socialPageRemoveAdmin = async (body) => {
  return await api.PostReq(`/page/removeAdmin?t=page&a=remove_admin`, body);
};
export const getPeopleListForChepterAdd = async (id, q = '', body) => {
  return await api.PostReq(`/chapter/getPeopleList?id=${id}&offset=${0}&limit=${12}&q=${q}`, body);
};

export const addMemberforChapter = async (body) => {
  return await api.PostReq(`/chapter/addMember?t=chapter&a=add_member`, body);
};

export const getSocialGroupDiscover = async (page = '') => {
  return await api.getReq(`/user/group-discover?page=${page}`);
};

export const getSponerDetail = async (event_id, id) => {
  return await api.getReq(`/event/detail_sponsor?event_id=${event_id}&id=${id}`);
};
export const deleteSponsor = async (body) => {
  return await api.PostReq(`/event/deleteSponsor`, body);
};

export const getEventActivityImage = async (mid, type) => {
  return await api.getReq(`/smedia/ImageDetails?type=${type}&mid=${mid}`);
};

export const getGroupPeopleList = async (id, search = '', offset = '', body) => {
  return await api.PostReq(
    `/group/getPeopleList?id=${id}&q=${search}&offset=${offset}&limit=${12}`,
    body
  );
};

export const userGroupSuggestions = (offset = '', limit = '') => {
  return api.getReq(`/group/userGroupSuggestions?offset=${offset}&limit=${limit}`);
};

export const getGroupSliderData = async () => {
  return await api.getReq(`/group/groupsPageinstituteslider`);
};

export const socialGroupAddMember = async (body) => {
  return await api.PostReq(`/group/add-group-members?t=group&a=add_member`, body);
};

export const socialGroupJoin = async (body) => {
  return await api.PostReq(`/group/join`, body);
};
export const removeMember = async (body) => {
  return await api.PostReq(`/chapter/removeMember?t=chapter&a=remove_member`, body);
};

export const getGroupMembers = async (body, id, search = '', offset = '') => {
  return await api.PostReq(
    `/group/getPeopleList?id=${id}&q=${search}&offset=${offset}&limit=12`,
    body
  );
};
// group/checkIfAdmin?id=455064cb89399ae889b0c3c14fb5bac5

export const checkIfMemeberIsAdmin = async (id) => {
  return await api.getReq(`/group/checkIfAdmin?id=${id}`);
};

export const addAdminForGroup = async (body) => {
  return await api.PostReq(`/group/add-group-admins?t=group&a=add_admin`, body);
};

export const groupDiscover = async () => {
  return await api.getReq(`/user/group-discover`);
};

export const updateGenralSetting = async (body) => {
  return await api.PostReq(`/page/general_settings?t=page&a=update`, body);
};

export const socialRemoveGroupAdmin = async (body) => {
  return await api.PostReq(`/group/remove-group-admins?t=group&a=remove_admin`, body);
};

export const removeGroupMember = async (body) => {
  return await api.PostReq(`/group/remove-group-members?t=group&a=remove_member`, body);
};

export const acceptRejectMemberRequest = async (id, type) => {
  return await api.getReq(`/group/accept-reject?id=${id}&type=${type}`);
};

// album/photos/////

export const socialCreateAlbum = async (body) => {
  return await api.PostReq(`/album/create?t=album&a=create`, body);
};

export const getAllSocialAlbums = async () => {
  return await api.getReq(`/album/all`);
};

export const getSingleAlbum = async (albumId) => {
  return await api.getReq(`/album/editAlbum?id=${albumId}`);
};
export const getAlbumDetail = async (albumId) => {
  return await api.getReq(`/album/view?id=${albumId}`);
};

export const uploadImageInAlbum = async (body, setProgress) => {
  return await api.PostReqFile(`/album/photos/upload?t=album&a=upload`, body, setProgress);
};

export const deleteImageAlbum = async (postId) => {
  return await api.getReq(
    `/album/photos/delete?t=post&a=delete&images=image&album_type=album&post_id=${postId}`
  );
};

export const deleteAlbum = async (albumId) => {
  return await api.getReq(`/album/remove?t=album&a=delete&album_id=${albumId}`);
};
export const socialChapterJoin = async (body) => {
  return await api.PostReq(`/chapter/join`, body);
};

export const socialUpdateAlbum = async (body) => {
  return await api.PostReq(`/album/update?t=album&a=edit_album`, body);
};
export const getChapterPostList = async (page) => {
  return await api.getReq(`/chapter/posts?page=${page}`);
};

export const leaveJoinedChapter = async (id) => {
  return await api.delReq(`/chapter/leave?id=${id}`);
};

export const getChapterActivityImage = async (mid, pid) => {
  return await api.getReq(
    `/smedia/ImageDetails?type=post_image&mid=${mid}&w=500&mt=a&o=false&pid=${pid}`
  );
};

export const getChapterActivityImageMutiple = async (body) => {
  return await api.PostReq(`/user/getAlbumImages`, body);
};
export const reportPost = async (id) => {
  return await api.getReq(`/post/report?t=post&a=report&post_id=${id}`);
};
export const sharePost = async (post_id) => {
  return await api.getReq(`/user/sharePost?t=post&a=share&post_id=${post_id}&without_html=1`);
};

export const getStories = async (id) => {
  return await api.getReq(`user/getStories?id=${id}&section=group&timeline=0&type=all`);
};

export const getMentorStories = async (id) => {
  return await api.getReq(`user/getStories?id=${id}&section=mentors&timeline=0&type=all`);
};

export const getHashTagPostList = async (page, term = '') => {
  return await api.getReq(`/user/hashtag?team=${page}&term=${term}`);
};
export const userEventActivity = async (event_id, activity_type) => {
  return await api.PostReq(
    `/event/userActivity?event_id=${event_id}&activity_type=${activity_type}`
  );
};
export const sendEventInvite = async (body) => {
  return await api.PostReq(`/event/sendInvite`, body);
};

export const removeInvite = async (body) => {
  return await api.PostReq(`/event/removeInvite`, body);
};

export const userParticipate = async (event_id, activity_id, activity_type) => {
  return await api.PostReq(
    `event/userParticipationOnActivity?event_id=${event_id}&activity_id=${activity_id}&activity_type=${activity_type}`
  );
};

export const deleteActivity = async (id, event_id) => {
  return await api.PostReq(`/event/deleteEventActivity?id=${id}&event_id=${event_id}`);
};
export const shareEventOnSocial = async (body) => {
  return await api.PostReq(`/event/shareEventOnSocial`, body);
};
export const getEventRecipients = async (q, type = 'groups') => {
  return await api.getReq(`/event/getEventRecipientsOfUser?q=${q}&type=${type}`);
};

export const jobFairCompaniesJOb = async (body) => {
  return await api.PostReq(`/jobfair/joblisting`, body);
};

export const adminRolesPages = async (id = '') => {
  return await api.getReq(`/page/admin_roles?id=${id}`);
};
export const updateEvent = async (id, body) => {
  return await api.PostReq(`/event/edit?id=${id}`, body);
};

export const getEventTickets = async (event_id) => {
  return await api.getReq(`/event/eventTickets?event_id=${event_id}`);
};
// /jobfair/submitJobApplication

export const jobFairApplyJobs = async (body) => {
  return await api.PostReq(`/jobfair/submitJobApplication`, body);
};

export const getApplicationOfFair = async () => {
  return await api.getReq(`/user/jobfair`);
};

export const getLikedByList = async (postId) => {
  return await api.getReq(`/ajaxSocialAjax?t=post&a=like_window&without_html=1&post_id=${postId}`);
};
//member remove

//member remove
export const removeMemberPages = async (body) => {
  return await api.PostReq(`/page/removeMember?t=page&a=remove_member`, body);
};

export const getSocialTimeLineStories = async (id, page = '') => {
  return await api.getReq(
    `/user/timelineStory/getStories?encryption_id=${id}&section=user&timeline=0&type=all&page=${page}`
  );
};

export const getTimeLineSocial = async (page) => {
  return await api.getReq(`/user/getStories?id=user&section=user&timeline=0&type=all&page=${page}`);
};

export const shareSharedPost = async (post_id) => {
  return await api.getReq(`/user/shareSharedPost?t=post&a=share-shared-story&post_id=${post_id}`);
};

export const getPublisherBox = async () => {
  return await api.getReq(`/user/getStoryPublisherBox?id=user&section=user&timeline=0&type=all`);
};

export const getHallTicket = async (id) => {
  return await api.getReq(`/jobfair/jobfairTicket/getjobHallTicket?jobHallTicket=${id}`);
};

export const getSharedByList = async (postId) => {
  return await api.getReq(
    `company/likeCompanyPost?t=post&post_id=${postId}&a=share_window&without_html=1`
  );
};

export const getAttemptedPollResponse = async () => {
  return await api.getReq(`poll/getResponses`);
};

export const youtubeVideo = (q) => {
  return api.getReq(`/ajax/socialAjax?t=youtube_search&without_html=1&q=${q}`);
};

export const getUserSuggestedFriendsApi = async (type = '', offset = '') => {
  return await api.getReq(`/user/suggestedFriends?type=${type}&offset=${offset}&limit=10`);
};

export const getEventBookingTicket = async (event_id, body) => {
  return await api.PostReq(`/event/ticketBooking?event_id=${event_id}`, body);
};

export const getEventSummaryDetails = async (encrypted_order_id) => {
  return await api.getReq(`/event/proceed_to_pay?encrypted_order_id=${encrypted_order_id}`);
};
export const bookEventTickets = async (body) => {
  return await api.PostReq(`/event/BookTickets`, body);
};

export const deleteFileFromPost = (post_id, file_id) => {
  return api.delReq(`/user/deleteFile?post_id=${post_id}&media_id=${file_id}`);
};

export const addAdminForChapter = async (body) => {
  return await api.PostReq(`/chapter/addAdmin?t=chapter&a=add_admin`, body);
};

export const removeAdminForChapter = async (body) => {
  return await api.PostReq(`/chapter/removeAdmin?t=chapter&a=remove_admin`, body);
};

export const userClubSuggestions = (offset = '', limit = '') => {
  return api.getReq(`/clubs/userClubSuggestions?offset=${offset}&limit=${limit}`);
};

export const socialClubJoin = async (body) => {
  return await api.PostReq(`/clubs/join`, body);
};

export const socialClubLeave = async (body) => {
  return await api.PostReq(`/clubs/leaveClub?t=club&a=remove_admin`, body);
};

export const SocialClubSetting = async (body) => {
  return await api.PostReq(`/clubs/UpdateSettings?t=club&a=update_settings`, body);
};

export const socialClubAddMember = async (body) => {
  return await api.PostReq(`/clubs/addMember?t=club&a=add_member`, body);
};

export const getClubMembers = async (body, id, search = '', offset = '') => {
  return await api.PostReq(
    `/clubs/getPeopleList?id=${id}&q=${search}&offset=${offset}&limit=12`,
    body
  );
};

export const addAdminForClub = async (body) => {
  return await api.PostReq(`/clubs/addAdmin?t=club&a=add_admin`, body);
};
export const acceptRejectClubMemberRequest = async (id, type) => {
  return await api.getReq(`/clubs/accept-reject?id=${id}&type=${type}`);
};

export const removeClubMember = async (body) => {
  return await api.PostReq(`/clubs/removeMember?t=club&a=remove_member`, body);
};

export const addSocialClubPost = async (body) => {
  return await api.PostReq(`/clubs/createPost?t=post&a=new&without_html=1`, body);
};

export const getSocialClubPost = async (id, page, type) => {
  return await api.getReq(
    `/user/getStories?id=${id}&section=clubs&timeline=0&type=${type}&page=${page}`
  );
};
