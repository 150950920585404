import { Link, useNavigate } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import HeaderInner from '../../commoncomponents/HeaderInner';
import FooterMain from '../../commoncomponents/FooterMain';
import { Carousel, Container } from 'react-bootstrap';
import useDocumentTitle from '../../../globals/useDocumentTitle';
import EventsPage from '../user-pages/Events/EventsPage';

function Events() {
  useDocumentTitle('List of Events | AlmaBay');
  const navigate = useNavigate();
  return (
    <>
      <HeaderInner />
      <div className="greybg_content">
        <section className="events_section position-relative">
          <Carousel>
            <Carousel.Item>
              <div className="events-banner1 p-5">
                <Container>
                  <h2 className="text-center banner-heading">Let’s make event magic</h2>
                  <h6 className="banner-title text-center text-white">
                    Sign up and create your first event.
                  </h6>
                  <p className="text-center describtion text-white">
                    AlmaBay a comprehensive event management platform to create, plan, manage and
                    promote events effectively.
                  </p>
                  <div className="applying text-white py-5">
                    <p className="text-center text-white">
                      {' '}
                      Be it alumni events, education conferences, or organizing job fairs, Almabay’s
                      event management platform enables you to automate manual tasks, increase
                      efficiency, and streamline processes within your budget.{' '}
                    </p>
                  </div>
                  {/* <div className="signup-mentor-btn text-center mb-5">
                    <button
                      className="btn btn-orange text-uppercase"
                      onClick={() => navigate('/register')}
                    >
                      Create Events
                    </button>
                  </div> */}
                </Container>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className="events-banner2 p-5">
                <Container>
                  <h2 className="text-center banner-heading">Sponsors</h2>
                  <h6 className="banner-title text-center text-white">
                    Engage Your Target Market Through Event Sponsorship
                  </h6>
                  <p className="text-center describtion text-white">
                    We make it easy to find and sponsor events to engage with your brand’s target
                    audience.
                  </p>
                  <div className="applying text-white py-5">
                    <p className="text-center text-white">
                      {' '}
                      Secure transaction – Your privacy is protected and only Sponsors can initiate
                      negotiations with organizers. Connect with Organizers - When ready to begin
                      the negotiation process, you can connect with event organizers directly. Find
                      event – Search thousands of events and filter by events category, location,
                      budget and keywords.{' '}
                    </p>
                  </div>
                  {/* <div className="signup-mentor-btn text-center mb-5">
                    <Link to="/register">
                      <button className="btn btn-orange text-uppercase">Signup For Sponsors</button>
                    </Link>
                  </div> */}
                </Container>
              </div>
            </Carousel.Item>
          </Carousel>
          <div className="intro-scroller">
            <a href="#mobile">
              <img src={require('../../../assets/images/mouse.png')} alt="Down Arrow" />
            </a>
          </div>
        </section>
        <section id="mobile" className="jobfair-infomation py-5">
          <Container>
            <Row>
              <Col md={12}>
                <h2 className="text-center mx-auto mb-5">
                  Create customized online event registration pages that match your brand perfectly.
                </h2>
              </Col>
              <Col sm={4}>
                <div className="text-center">
                  <img
                    src={require('../../../assets/images/mentorsdirectory-listview-1.png')}
                    className="rounded-circle"
                    alt=""
                  />
                  <p>
                    Create customized online event registration pages that match your brand
                    perfectly. Create your event’s listing and attract premium sponsors
                  </p>
                </div>
              </Col>
              <Col sm={4}>
                <div className="text-center">
                  <img
                    src={require('../../../assets/images/mentorsdirectory-listview-2.png')}
                    className="rounded-circle"
                    alt="img"
                  />
                  <p>Stay organized and manage all your events and attendee access online.</p>
                </div>
              </Col>
              <Col sm={4}>
                <div className="text-center">
                  <img
                    src={require('../../../assets/images/mentorsdirectory-listview-3.png')}
                    className="rounded-circle"
                    alt="img"
                  />
                  <p>
                    Use our powerful social media promotion and marketing tools to increase
                    attendance and enhance attendee engagement.
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <section className="py-5">
          <Container>
            <EventsPage />
          </Container>
        </section>
      </div>
      <FooterMain className={'colour-bg'} />
    </>
  );
}

export default Events;
