/*
@copyright : ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
@author    : Shiv Charan Panjeta 
 
All Rights Reserved.
Proprietary and confidential :  All information contained here in is, and remains
the property of ToXSL Technologies Pvt. Ltd. and it's partners.
Unauthorized copying of this file, via any medium is strictly prohibited.
*/

import moment from 'moment/moment';
import { useEffect, useRef, useState } from 'react';
import { Button, Col, Dropdown, Row, Spinner } from 'react-bootstrap';
import CloseButton from 'react-bootstrap/CloseButton';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Polls from 'react-polls';
import { useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import docImg from '../../../../assets/images/doc1.png';
import odsImg from '../../../../assets/images/ods.png';
import odtImg from '../../../../assets/images/odt.png';
import pdfImg from '../../../../assets/images/pdf1.png';
import pptImg from '../../../../assets/images/ppt.png';
import xlsImg from '../../../../assets/images/xls.png';
import { privacy, truncate } from '../../../../globals/helper';
import showNotification from '../../../../services/NotificationService';
import * as APISERVICES from '../../../../services/SocialServices';
import { reportPost } from '../../../../services/SocialServices';
import { addFriend, addPollResponse } from '../../../../services/UserServices';
import NoDataFound from '../../../commoncomponents/NoDataFound';

let $ = require('jquery');

function ChapterPostLayout(props) {
  /* eslint-disable */
  const {
    checkPost,
    getPostListData,
    totalPages,
    onPostListData,
    pageNum,
    isPostLoading,
    onIncrementPageNo,
    setCheckPost,
    setLoadingState,
    loadingState,
    allData,
    disableInputBox,
    setDisableInputBox,
    deleteFile,
    type,
    setPageNum
  } = props;

  const [checkComment, setCheckComment] = useState([]);
  const [checkLike, setCheckLike] = useState([]);
  const [checkFollow, setCheckFollow] = useState();
  const [postComment, setPostComment] = useState([]);
  const [modalComment, setModalComment] = useState('');
  const [modalImage, setModalImage] = useState([]);
  const [clickedImage, setClickedImage] = useState();
  const userDetail = useSelector((state) => state?.user?.details);
  const [show, setShow] = useState(false);
  const [showComment, setShowComment] = useState([]);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);
  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const [postId, setPostId] = useState('');
  const [loading, setLoading] = useState(false);
  const [commentId, setCommentId] = useState('');
  const [shareList, setShareList] = useState([]);
  const [peopleLikedData, setPeopleLikedData] = useState([]);
  const [peopleShareData, setPeopleShareData] = useState([]);
  const [commentLoader, setCommentLoader] = useState(false);
  const [showPrev, setshowPrev] = useState([]);
  const [showDelete, setShowDelete] = useState(false);
  const [singlePostId, setSinglePostId] = useState('');
  const [comments, setComments] = useState([]);

  const pollStyles1 = {
    questionSeparator: true,
    questionSeparatorWidth: 'question',
    questionBold: true,
    align: 'center'
  };

  useEffect(() => {
    if (postId !== '') {
      getPostSingleDetail(postId);
    }
  }, []);

  const handleShow2 = (e, data, id) => {
    e.preventDefault();
    setShow2(true);

    setClickedImage(id);
    getPostSingleDetail(data?.id);
    setPostId(data?.id);
  };
  const getPostSingleDetail = (id) => {
    setLoading(true);
    APISERVICES.getSinglePostDetail(id).then((res) => {
      if (res?.status === 200) {
        setLoading(false);
        setModalImage(res?.data?.data);
      } else {
        setLoading(false);
      }
    });
  };
  const getComment = (id, e) => {
    e.preventDefault();
    APISERVICES.getPostComment(id).then((res) => {
      if (res?.status === 200) {
        setCheckComment(res?.data?.data);
      }
    });
  };
  const getPostLike = (e, id, type) => {
    setLoadingState('');
    e.preventDefault();

    APISERVICES.getLikeUnlike(id).then((res) => {
      if (res?.status === 200) {
        showNotification('success', res?.data?.message);
        setCheckLike(res?.data?.data);
        if (type == 'modal') {
          getPostSingleDetail(postId);
        }
        setPageNum(1);
        getPostListData(true);
      }
    });
  };

  const getCommentLike = (e, id, type) => {
    setLoadingState('');
    e.preventDefault();
    APISERVICES.getLikeUnlike(id).then((res) => {
      if (res?.status === 200) {
        showNotification('success', res?.data?.message);
        setCheckLike(res?.data?.data);
        getPostListData(true);
        if (type == 'modal') {
          getPostSingleDetail(postId);
        }
      }
    });
  };

  const deletedPost = () => {
    setLoadingState('');
    APISERVICES.deletePost(singlePostId).then((res) => {
      if (res?.status === 200) {
        showNotification('success', res?.data?.message);
        setShowDelete(false);
        let filterData = checkPost?.filter((data) => data?.id !== singlePostId);
        setCheckPost(filterData);
      }
    });
  };

  const deleteComment = (e, id) => {
    setLoadingState('');
    e.preventDefault();
    if (confirm('Do you want to delete your comment ?') == true) {
      APISERVICES.deletePost(id).then((res) => {
        if (res?.status === 200) {
          showNotification('success', 'Comment removed successfully');
          getPostListData(true);
        }
      });
    } else {
      return;
    }
  };

  const isPostcomment = (id, type, e) => {
    e.preventDefault();
    setModalComment('');
    setLoadingState('');
    setDisableInputBox(true);
    APISERVICES.postComment(id, {
      text: type == 1 ? postComment : modalComment,
      recipient_id: ''
    }).then((res) => {
      if (res?.status === 200) {
        let tempArr = [];
        tempArr.push(res.data?.data);
        setComments(
          checkPost?.map((data) => {
            if (data?.id === res.data?.data?.post_id) {
              return {
                ...data,
                comments: tempArr
              };
            }
            return data;
          })
        );

        showNotification('success', res?.data?.message);
        setPostComment('');
        setDisableInputBox(false);
        if (type == 0) {
          getPostSingleDetail(type == 1 ? id : postId);
        }
        setPageNum(1);
        getPostListData(true);
      }
    });
  };

  const handleReportPost = (e, id) => {
    setLoadingState('');
    e.preventDefault();
    APISERVICES.reportPost(id).then((resp) => {
      if (resp?.status === 200) {
        showNotification('success', resp?.data?.data);
        getPostListData(true);
      }
    });
  };

  const isFollowUnfollow = (e, id, type) => {
    setLoadingState('');
    e.preventDefault();
    APISERVICES.postFollowUnfollow(id).then((res) => {
      if (res?.status === 200) {
        showNotification('success', res?.data?.data);
        setCheckFollow('');
        getPostListData(true);
        if (type == 'modal') {
          getPostSingleDetail(postId);
        }
      }
    });
  };

  // intersection

  const [lastElement, setLastElement] = useState(null);
  const observer = useRef(
    new IntersectionObserver((entries) => {
      const first = entries[0];
      if (first.isIntersecting) {
        onIncrementPageNo();
      }
    })
  );

  useEffect(() => {
    const currentElement = lastElement;
    const currentObserver = observer.current;
    if (currentElement) {
      setLoadingState('');
      currentObserver.observe(currentElement);
    }

    return () => {
      if (currentElement) {
        setLoadingState('');
        currentObserver.unobserve(currentElement);
      }
    };
  }, [lastElement]);

  const handlePostShare = (e, data) => {
    e.preventDefault();
    setLoadingState('');
    if (data?.via_type == '') {
      APISERVICES.sharePost(data?.id).then((res) => {
        if (res?.status === 200) {
          showNotification(
            'success',
            data.shares_count == 1
              ? 'Post has been unshared successfully'
              : 'Post has been shared successfully'
          );
          if (data.shares_count == 1) {
            getPostListData('unshare', data?.post_id);
          } else {
            getPostListData('share');
          }
        }
      });
    } else {
      APISERVICES.shareSharedPost(data?.id).then((res) => {
        if (res?.status === 200) {
          getPostListData('share');
          showNotification('success', res?.data?.message);
        }
      });
    }
  };

  const handleShowlike = (e, pageId) => {
    setPostId(pageId);
    e.preventDefault();
    handleShow();
    setLoading(true);
    APISERVICES.getLikedByList(pageId).then((res) => {
      if (res?.status === 200) {
        setPeopleLikedData(res?.data?.data?.like_data);
        setLoading(false);
      } else {
        setLoading(false);
      }
    });
  };

  const handleShowShare = (e, pageId) => {
    setPostId(pageId);
    e.preventDefault();
    handleShow1();
    setLoading(true);
    APISERVICES.getSharedByList(pageId).then((res) => {
      if (res?.status === 200) {
        setPeopleShareData(res?.data?.data?.share_data);
        setLoading(false);
      } else {
        setLoading(false);
      }
    });
  };

  const resquestHandler = (e, id, type) => {
    e.preventDefault();
    setLoading(true);
    if (type == 'add') {
      let body = {
        following_id: id
      };
      addFriend(body).then((res) => {
        setLoading(false);
        if (res?.status == 200) {
          handleShowlike(e, postId);
        } else {
          showNotification('success', res?.data?.message);
        }
      });
    }

    if (type == 'remove') {
      if (confirm('Are you sure you want to unfriend?') === true) {
        let body = {
          following_id: id
        };
        addFriend(body).then((res) => {
          setLoading(false);
          if (res?.status == 200) {
            handleShowlike(e, postId);
          } else {
            showNotification('success', res?.data?.message);
          }
        });
      }
    }
    if (type == 'requested') {
      if (confirm('Do you want to remove request?') === true) {
        let body = {
          following_id: id
        };
        addFriend(body).then((res) => {
          setLoading(false);
          if (res?.status == 200) {
            handleShowlike(e, postId);
          } else {
            showNotification('success', res?.data?.message);
          }
        });
      }
    }
    // else {

    //   let body = {
    //     userData: id
    //   };
    //   removeFriend(body).then((res) => {
    //     setLoading(false);
    //     if (res?.status == 200) {
    //       handleShowlike(e, postId);
    //     } else {
    //       showNotification('success', res?.data?.message);
    //     }
    //   });
    // }
  };

  // ******* poll functionality ****************** start ********** //
  const pollAnswers1 = [
    { option: 'Yes', votes: 24 },
    { option: 'No', votes: 3 },
    { option: "I don't know", votes: 1 }
  ];
  const pollAnswers2 = [
    { option: 'React', votes: 5 },
    { option: 'Vue', votes: 2 },
    { option: 'Angular', votes: 1 }
  ];
  const [pollAns1, setPollAns1] = useState([...pollAnswers1]);
  const [pollAns2, setPollAns2] = useState([...pollAnswers2]);
  useEffect(() => {
    let elements = document.getElementsByClassName('styles_question__3qxRo');
    if (elements) {
      Array.from(elements).forEach((ele) => {
        let html = ele.innerHTML;

        let updatedHtml = html.split('sepsduiok');

        const div = document.createElement('div');
        div.classList.add('flexer');
        let firstChild = document.createElement('p');
        firstChild.innerHTML = updatedHtml[0];
        div.appendChild(firstChild);
        if (updatedHtml.length == 2) {
          let secondChild = document.createElement('p');
          secondChild.innerHTML = updatedHtml[1];
          div.appendChild(secondChild);
        }
        ele.replaceWith(div);
      });
    }
  });

  const addResponseData = (id, data) => {
    setLoading(true);
    let body = {
      ques_id: id,
      ans: data[0]?.id,
      path: '/user'
    };
    addPollResponse(body).then((res) => {
      setLoading(false);
      if (res?.status === 200) {
        setLoading(false);
        showNotification('success', res?.data?.message);
        getPostListData();
        window.location.href = '/user';
      } else {
        setLoading(false);
        showNotification('err', res?.data?.message);
      }
    });
  };

  const handleVote = (voteAnswer, pollAnswers, pollNumber) => {
    setLoading(true);
    const newPollAnswers = pollAnswers?.map((answer) => {
      setLoading(false);
      if (answer.option === voteAnswer) answer.votes++;

      return answer;
    });

    if (pollNumber === 1) {
      setPollAns1(newPollAnswers);
    } else {
      setPollAns2(newPollAnswers);
    }
  };

  const autoAddVotes = (pollAnswers, pollNumber) => {
    setTimeout(() => {
      const choseAnswer = parseInt(Math.random() * 2, 10);
      handleVote(pollAnswers[choseAnswer].title, pollAnswers, pollNumber);
      autoAddVotes(pollAnswers, pollNumber);
      getQuestionsListData(10, currentPage);
    }, Math.random() * 5000);
  };
  // ******* poll functionality ****************** end  ********** //

  /**
   * load previous comment
   * @param {*} data
   */
  const loadPreviousComments = (data) => {
    setCommentLoader(true);
    setTimeout(() => {
      if (showPrev.includes(data.id)) {
        let result = showPrev.filter((item) => item !== data.id);
        setshowPrev(result);
        setCommentLoader(false);
      } else {
        setshowPrev([...showPrev, data.id]);
        setCommentLoader(false);
      }
    }, 500);
  };

  return (
    <>
      {isPostLoading && loadingState !== '' ? (
        <>
          {' '}
          {Array.from(Array(3), function (index) {
            return <Shimmer />;
          })}
        </>
      ) : (
        <>
          {' '}
          {checkPost?.length > 0 ? (
            checkPost?.map((data, index) => {
              return (
                <>
                  <div className="pofile-upload-card" key={index} ref={setLastElement}>
                    <div className="profile-upload-sec d-flex align-items-top mb-3">
                      <Link to={`/user/timeline/${data?.publisher?.encrypted_id}`}>
                        <span className="social-profile-icn me-3">
                          <img
                            alt="img"
                            src={
                              data?.publisher?.mediaObject
                                ? data?.publisher?.mediaObject?.path +
                                  '.' +
                                  data?.publisher?.mediaObject?.extension
                                : require('../../../../assets/images/birth4.png')
                            }
                          />
                        </span>
                      </Link>
                      <div className="social-profile-detail d-flex align-items-top justify-content-between ">
                        <div className="social-profile-name">
                          <h4>
                            <Link to={`/user/timeline/${data?.publisher?.encrypted_id}`}>
                              {data?.publisher?.name}
                            </Link>
                            {data?.recipient && (
                              <>
                                <i className="fas fa-long-arrow-alt-right"></i>
                                <Link to={`javascript:void(0)`}>&nbsp;{data.recipient.name}</Link>
                              </>
                            )}
                            {data?.activity_text == '' ? (
                              <>
                                {data?.is_share ? (
                                  <span
                                    dangerouslySetInnerHTML={{
                                      __html: data?.is_share
                                    }}
                                  />
                                ) : (
                                  ''
                                )}
                              </>
                            ) : (
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: data?.activity_text
                                }}
                              />
                            )}
                          </h4>
                          <p>
                            <i className="far fa-clock me-2"></i>
                            <span title={data?.timestamp}>
                              {moment(
                                new Date(data?.timestamp)
                                  .toISOString()
                                  .toLocaleString(undefined, { timeZone: 'Asia/Kolkata' })
                              ).fromNow()}
                            </span>
                            <i
                              className="fas fa-users ms-2"
                              title={privacy(data.privacy_response?.toString())}></i>
                          </p>
                        </div>
                        <div className="drop-ellipse-actn">
                          <Dropdown className="ellipse-drop-card ms-3">
                            <Dropdown.Toggle variant="success" id="dropdown-basic">
                              <i className="fas fa-ellipsis-v"></i>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              {userDetail?.id == data?.timeline_id ? (
                                <Dropdown.Item
                                  title="Remove"
                                  onClick={(e) => {
                                    setSinglePostId(data?.id);
                                    setShowDelete(true);
                                  }}>
                                  Delete Post
                                </Dropdown.Item>
                              ) : (
                                <Dropdown.Item
                                  title="Report"
                                  onClick={async (e) => {
                                    try {
                                      const response = await reportPost(data?.id);
                                      if (response?.status === 200) {
                                        showNotification('success', response.data.message);
                                      }
                                    } catch (error) {
                                      showNotification('success', response.data.message);
                                    }
                                    setSinglePostId(data?.id);
                                  }}>
                                  Report Post
                                </Dropdown.Item>
                              )}

                              {data?.followed ? (
                                <Dropdown.Item
                                  href="#/action-1"
                                  onClick={(e) => {
                                    isFollowUnfollow(e, data?.id);
                                  }}>
                                  Unfollow this post
                                </Dropdown.Item>
                              ) : (
                                <Dropdown.Item
                                  href="#/action-1"
                                  onClick={(e) => {
                                    isFollowUnfollow(e, data?.id);
                                  }}>
                                  Follow this post
                                </Dropdown.Item>
                              )}
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </div>
                    </div>
                    {/* "profile-top-dis */}
                    {data?.poll_id && (
                      <>
                        <div>
                          <Polls
                            question={
                              data?.question?.description
                                ? `${data?.question?.title}` +
                                  'sepsduiok' +
                                  `${data?.question?.description}`
                                : data?.question?.title
                              // data?.question?.title
                            }
                            answers={data?.answers.map((ans) => {
                              return {
                                option: ans?.title,
                                votes: ans?.vote,
                                id: ans?.id
                              };
                            })}
                            onVote={(voteAnswer) => {
                              handleVote(
                                voteAnswer,
                                pollAns2,
                                data?.answers,
                                data?.question?.id,
                                data?.answers?.filter((data) => data?.title == voteAnswer)
                              );
                              addResponseData(
                                data?.question?.id,
                                data?.answers?.filter((data) => data?.title == voteAnswer)
                              );
                            }}
                            customStyles={pollStyles1}
                          />
                          <h4 className="totalVoteCount">Total Vote : {data?.votes}</h4>
                        </div>
                      </>
                    )}

                    {(data?.text !== null || data?.text == '') && (
                      <pre
                        style={{ fontFamily: 'Lato', fontSize: 14 }}
                        className="profile-top-dis"
                        dangerouslySetInnerHTML={{
                          __html: data?.text
                        }}></pre>
                    )}
                    {data?.images_path?.length !== 0 && (
                      <div className="post_img d-flex flex-wrap">
                        {data?.images_path?.map((image, index) => {
                          return (
                            <Link
                              to={`javascript:void(0)`}
                              className="collage_card"
                              id="img1"
                              onClick={(e) => handleShow2(e, data, index)}
                              key={index}>
                              {' '}
                              <img src={image} alt="img" />
                            </Link>
                          );
                        })}
                      </div>
                    )}

                    {data?.files?.length !== 0 && (
                      <div className="post_img d-flex flex-wrap">
                        {data?.files?.map((files, index) => {
                          const pdfFile = files?.file_name?.includes('pdf');
                          const spreadSheetFile =
                            files?.file_name?.includes('xlsx') || files?.file_name?.includes('xls');
                          const odtFile = files?.file_name?.includes('odt');
                          const odsFile = files?.file_name?.includes('ods');
                          const docFile =
                            files?.file_name?.includes('doc') || files?.file_name?.includes('docx');
                          const pptFile =
                            files?.file_name?.includes('ppt') || files?.file_name?.includes('pptx');
                          if (pdfFile) {
                            return (
                              <div className="pdffile_card">
                                <img src={pdfImg} alt="" />
                                <div className="overlay">
                                  <p className="ng-binding">{truncate(files?.file_name, 10)}</p>
                                  <div
                                    className={
                                      data?.files?.length > 1
                                        ? 'd-flex justify-content-between bottomrow'
                                        : 'd-flex justify-content-center bottomrow'
                                    }>
                                    <a href={files?.file_path} download>
                                      <i className="fa fa-download"></i>
                                    </a>
                                    {data?.files?.length > 1 &&
                                      data?.publisher?.encrypted_id ===
                                        userDetail?.encrypted_id && (
                                        <a
                                          className="pull-right ng-scope"
                                          onClick={() => {
                                            deleteFile(files?.post_id, files?.media_id);
                                          }}
                                          href={`javascript:void(0)`}>
                                          <i className="fa fa-trash progress-icon"></i>
                                        </a>
                                      )}
                                  </div>
                                </div>
                              </div>
                            );
                          } else if (spreadSheetFile) {
                            return (
                              <div className="pdffile_card">
                                <img src={xlsImg} alt="" />
                                <div className="overlay">
                                  <p className="ng-binding">{truncate(files?.file_name, 10)}</p>
                                  <div
                                    className={
                                      data?.files?.length > 1
                                        ? 'd-flex justify-content-between bottomrow'
                                        : 'd-flex justify-content-center bottomrow'
                                    }>
                                    <a href={files?.file_path} download>
                                      <i className="fa fa-download"></i>
                                    </a>
                                    {data?.files?.length > 1 &&
                                      data?.publisher?.encrypted_id ===
                                        userDetail?.encrypted_id && (
                                        <a
                                          className="pull-right ng-scope"
                                          onClick={() => {
                                            deleteFile(files?.post_id, files?.media_id);
                                          }}
                                          href={`javascript:void(0)`}>
                                          <i className="fa fa-trash progress-icon"></i>
                                        </a>
                                      )}
                                  </div>
                                </div>
                              </div>
                            );
                          } else if (docFile) {
                            return (
                              <div className="pdffile_card">
                                <img src={docImg} alt="" />
                                <div className="overlay">
                                  <p className="ng-binding">{truncate(files?.file_name, 10)}</p>
                                  <div
                                    className={
                                      data?.files?.length > 1
                                        ? 'd-flex justify-content-between bottomrow'
                                        : 'd-flex justify-content-center bottomrow'
                                    }>
                                    <a href={files?.file_path} download>
                                      <i className="fa fa-download"></i>
                                    </a>
                                    {data?.files?.length > 1 &&
                                      data?.publisher?.encrypted_id ===
                                        userDetail?.encrypted_id && (
                                        <a
                                          className="pull-right ng-scope"
                                          onClick={() => {
                                            deleteFile(files?.post_id, files?.media_id);
                                          }}
                                          href={`javascript:void(0)`}>
                                          <i className="fa fa-trash progress-icon"></i>
                                        </a>
                                      )}
                                  </div>
                                </div>
                              </div>
                            );
                          } else if (pptFile) {
                            return (
                              <div className="pdffile_card">
                                <img src={pptImg} alt="" />
                                <div className="overlay">
                                  <p className="ng-binding">{truncate(files?.file_name, 10)}</p>
                                  <div
                                    className={
                                      data?.files?.length > 1
                                        ? 'd-flex justify-content-between bottomrow'
                                        : 'd-flex justify-content-center bottomrow'
                                    }>
                                    <a href={files?.file_path} download>
                                      <i className="fa fa-download"></i>
                                    </a>
                                    {data?.files?.length > 1 &&
                                      data?.publisher?.encrypted_id ===
                                        userDetail?.encrypted_id && (
                                        <a
                                          className="pull-right ng-scope"
                                          onClick={() => {
                                            deleteFile(files?.post_id, files?.media_id);
                                          }}
                                          href={`javascript:void(0)`}>
                                          <i className="fa fa-trash progress-icon"></i>
                                        </a>
                                      )}
                                  </div>
                                </div>
                              </div>
                            );
                          } else if (odsFile) {
                            return (
                              <div className="pdffile_card">
                                <img src={odsImg} alt="" />
                                <div className="overlay">
                                  <p className="ng-binding">{truncate(files?.file_name, 10)}</p>
                                  <div
                                    className={
                                      data?.files?.length > 1
                                        ? 'd-flex justify-content-between bottomrow'
                                        : 'd-flex justify-content-center bottomrow'
                                    }>
                                    <a href={files?.file_path} download>
                                      <i className="fa fa-download"></i>
                                    </a>
                                    {data?.files?.length > 1 &&
                                      data?.publisher?.encrypted_id ===
                                        userDetail?.encrypted_id && (
                                        <a
                                          className="pull-right ng-scope"
                                          onClick={() => {
                                            deleteFile(files?.post_id, files?.media_id);
                                          }}
                                          href={`javascript:void(0)`}>
                                          <i className="fa fa-trash progress-icon"></i>
                                        </a>
                                      )}
                                  </div>
                                </div>
                              </div>
                            );
                          } else if (odtFile) {
                            return (
                              <div className="pdffile_card">
                                <img src={odtImg} alt="" />
                                <div className="overlay">
                                  <p className="ng-binding">{truncate(files?.file_name, 10)}</p>
                                  <div
                                    className={
                                      data?.files?.length > 1
                                        ? 'd-flex justify-content-between bottomrow'
                                        : 'd-flex justify-content-center bottomrow'
                                    }>
                                    <a href={files?.file_path} download>
                                      <i className="fa fa-download"></i>
                                    </a>
                                    {data?.files?.length > 1 &&
                                      data?.publisher?.encrypted_id ===
                                        userDetail?.encrypted_id && (
                                        <a
                                          className="pull-right ng-scope"
                                          onClick={() => {
                                            deleteFile(files?.post_id, files?.media_id);
                                          }}
                                          href={`javascript:void(0)`}>
                                          <i className="fa fa-trash progress-icon"></i>
                                        </a>
                                      )}
                                  </div>
                                </div>
                              </div>
                            );
                          }
                        })}
                      </div>
                    )}

                    <div className="profile-upload-ftr">
                      <div className="profile-likes-count d-flex align-items-center justify-content-between">
                        <div className="likes-count">
                          <Link onClick={(e) => handleShowlike(e, data?.id)}>
                            {data?.likes_count} Likes
                          </Link>
                          {data?.via_type == '' && (
                            <Link to="#" onClick={(e) => handleShowShare(e, data?.id)}>
                              {data?.shares_count} Shares
                            </Link>
                          )}
                        </div>
                        <div className="cmmnts-count">
                          {data?.comments?.comments_count} Comments
                        </div>
                      </div>
                      <div className="profile-like-btns">
                        <ul>
                          <li>
                            <Link
                              to="javascript:void(0)"
                              title="Like"
                              onClick={(e) => {
                                getPostLike(e, data.id);
                              }}>
                              <i className="fas fa-thumbs-up me-2"></i>
                              Like
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="javascript:void(0)"
                              title="Comment"
                              onClick={(e) => {
                                getComment(data.id, e);
                                setShowComment([...showComment, data?.id]);
                              }}>
                              <i className="fas fa-comments me-2"></i>
                              Comment
                            </Link>
                          </li>
                          {data?.poll_id ? (
                            <></>
                          ) : (
                            <li>
                              <Link
                                to="javascript:void(0)"
                                onClick={(e) => handlePostShare(e, data)}
                                title={data?.shared_by_current_user ? 'Unshare' : 'Share'}>
                                <i className="fas fa-share me-2"></i>
                                Share
                              </Link>
                            </li>
                          )}
                        </ul>
                      </div>
                    </div>

                    {data?.comments?.data?.length !== 0 ? (
                      <div className="profile-upload-cmmnt mt-3">
                        {data?.comments?.data?.length > 3 &&
                          !commentLoader &&
                          !showPrev.includes(data.id) && (
                            <div
                              onClick={() => {
                                loadPreviousComments(data);
                              }}
                              className="text-center mb-3">
                              <span className="load_comment">Load previous comments</span>
                            </div>
                          )}
                        {commentLoader && (
                          <div
                            style={{ justifyContent: 'center', marginBottom: '20px' }}
                            className="d-flex">
                            <Spinner />
                          </div>
                        )}
                        {!showPrev.includes(data.id) ? (
                          <>
                            {data?.comments?.data
                              ?.sort((a, b) => b.id - a.id)
                              ?.slice(0, 3)
                              ?.map((commentData, index) => {
                                return (
                                  <>
                                    <div
                                      className="profile-cmmnt-lst d-flex align-items-top mb-3"
                                      key={index}>
                                      <span className="social-profile-icn me-3">
                                        <img src={commentData?.user_image} alt="img" />
                                      </span>

                                      <div className="profile-cmmnt-user ">
                                        <div className="d-flex align-items-top justify-content-between">
                                          <Link to="javascript:void(0)">
                                            {commentData?.full_name}
                                          </Link>
                                          <div className="profile-cmmnt-time d-flex align-items-center ">
                                            <span>
                                              {moment(
                                                new Date(commentData?.timestamp)
                                                  .toISOString()
                                                  .toLocaleString(undefined, {
                                                    timeZone: 'Asia/Kolkata'
                                                  })
                                              ).fromNow()}
                                            </span>

                                            <Dropdown className="ellipse-drop-card ms-3">
                                              <Dropdown.Toggle
                                                variant="success"
                                                id="dropdown-basic">
                                                <i className="fas fa-ellipsis-v"></i>
                                              </Dropdown.Toggle>
                                              <Dropdown.Menu>
                                                {commentData?.timeline_id == userDetail?.id && (
                                                  <Dropdown.Item
                                                    href="#/action-1"
                                                    onClick={(e) => {
                                                      deleteComment(e, commentData?.id);
                                                    }}>
                                                    Delete comment
                                                  </Dropdown.Item>
                                                )}
                                              </Dropdown.Menu>
                                            </Dropdown>
                                          </div>
                                        </div>
                                        <div className="profile-cmmnt-dis">
                                          <p>{commentData?.text}</p>
                                          <div className="cmmnt-likes d-flex align-items-center">
                                            <Link
                                              to="#"
                                              onClick={(e) => {
                                                getCommentLike(e, commentData?.id);
                                              }}>
                                              <i className="fas fa-thumbs-up me-1"></i>Like{' '}
                                              <span>{commentData?.likeCount}</span>
                                            </Link>
                                            likes
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                );
                              })}
                          </>
                        ) : (
                          <>
                            {data?.comments?.data
                              ?.sort((a, b) => a.id - b.id)
                              ?.map((commentData, index) => {
                                return (
                                  <>
                                    <div
                                      className="profile-cmmnt-lst d-flex align-items-top mb-3"
                                      key={index}>
                                      <span className="social-profile-icn me-3">
                                        <img src={commentData?.user_image} alt="img" />
                                      </span>

                                      <div className="profile-cmmnt-user ">
                                        <div className="d-flex align-items-top justify-content-between">
                                          <Link to="javascript:void(0)">
                                            {commentData?.full_name}
                                          </Link>
                                          <div className="profile-cmmnt-time d-flex align-items-center ">
                                            <span>{commentData?.timestamp}</span>
                                            <Dropdown className="ellipse-drop-card ms-3">
                                              <Dropdown.Toggle
                                                variant="success"
                                                id="dropdown-basic">
                                                <i className="fas fa-ellipsis-v"></i>
                                              </Dropdown.Toggle>
                                              <Dropdown.Menu>
                                                {commentData?.timeline_id == userDetail?.id && (
                                                  <Dropdown.Item
                                                    href="#/action-1"
                                                    onClick={(e) => {
                                                      deleteComment(e, commentData?.id);
                                                    }}>
                                                    Delete comment
                                                  </Dropdown.Item>
                                                )}
                                              </Dropdown.Menu>
                                            </Dropdown>
                                          </div>
                                        </div>
                                        <div className="profile-cmmnt-dis">
                                          <p>{commentData?.text}</p>
                                          <div className="cmmnt-likes d-flex align-items-center">
                                            <Link
                                              to="#"
                                              onClick={(e) => {
                                                getCommentLike(e, commentData?.id);
                                              }}>
                                              <i className="fas fa-thumbs-up me-1"></i>Like{' '}
                                              <span>{commentData?.likeCount}</span>
                                            </Link>
                                            likes
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                );
                              })}
                          </>
                        )}
                      </div>
                    ) : (
                      <></>
                    )}

                    {showComment?.includes(data?.id) ? (
                      <div className="profile-upload-cmmnt mt-3">
                        <div className="profile-cmmnt-input d-flex align-items-top">
                          <span className="social-profile-icn me-3">
                            <img
                              src={
                                userDetail?.avtar
                                  ? process.env.REACT_APP_API_DOMAIN + '/' + userDetail?.avtar
                                  : require('../../../../assets/images/default-male-avatar.png')
                              }
                              alt="img"
                            />
                          </span>
                          <div className="cmmnt-card">
                            <Form.Control
                              type="text"
                              readOnly={disableInputBox}
                              onChange={(e) => {
                                setPostComment(e.target.value);
                                setCommentId(e.target.name);
                              }}
                              autoComplete="off"
                              style={
                                disableInputBox ? { cursor: 'not-allowed' } : { cursor: 'text' }
                              }
                              name={data?.id}
                              onKeyDown={(e) => {
                                if (e.key == 'Enter') {
                                  setModalComment('');
                                  isPostcomment(data?.id, 1, e);
                                }
                              }}
                              value={data?.id == commentId ? postComment : ''}
                              placeholder="Write a comment... Press Enter to post"
                            />
                          </div>
                        </div>
                      </div>
                    ) : (
                      ''
                    )}
                  </div>

                  {/* modal for image view  */}
                </>
              );
            })
          ) : (
            <NoDataFound text={type !== 'all' && 'No Videos/Photos to show at the moment'} />
          )}
        </>
      )}

      {loadingState !== '' && totalPages == pageNum && (
        <div className="text-center">
          <img src={require('../../../../assets/images/loaderinner.gif')} alt="img" />
        </div>
      )}
      <Modal show={show} onHide={handleClose} className="likes-modal">
        <Modal.Body className="modal-lst">
          {loading ? (
            <>
              <div className="loader-modal">
                <img src={require('../../../../assets/images/Loading_icon-small.gif')} alt="img" />
              </div>
            </>
          ) : (
            <>
              {' '}
              <div className="modal-hd d-flex align-items-center justify-content-between">
                <h4>
                  <i className="fas fa-share me-2"></i> People who liked this{' '}
                </h4>
                <CloseButton onClick={handleClose} />
              </div>
              <div className="likes-user-lst">
                {peopleLikedData?.length !== 0 ? (
                  <ul>
                    {peopleLikedData?.map((peopleData, index) => {
                      return (
                        <li key={index}>
                          <div className="lst-user-card d-flex align-items-center justify-content-between">
                            <div className="user-like-card d-flex align-items-center">
                              <span className="like-user-icn me-2">
                                <img
                                  src={
                                    peopleData?.mediaObject
                                      ? peopleData?.mediaObject?.path +
                                        '.' +
                                        peopleData?.mediaObject?.extension
                                      : require('../../../../assets/images/default-male-avatar.png')
                                  }
                                  alt="img"
                                />
                              </span>
                              <h4>
                                <Link to={`/user/timeline/${peopleData?.encrypted_id}`}>
                                  {peopleData?.name}
                                </Link>
                              </h4>
                            </div>
                            {peopleData?.id !== userDetail?.id && (
                              <>
                                {peopleData?.unfollow && (
                                  <Link
                                    className="wht-btn-grey "
                                    to=""
                                    onClick={(e) => resquestHandler(e, peopleData?.id, 'remove')}>
                                    Unfriend
                                  </Link>
                                )}
                                {peopleData?.requested && (
                                  <Link
                                    className="wht-btn-grey "
                                    to=""
                                    onClick={(e) =>
                                      resquestHandler(e, peopleData?.id, 'requested')
                                    }>
                                    Requested
                                  </Link>
                                )}
                                {peopleData?.follow == 1 && (
                                  <Link
                                    className="wht-btn-grey "
                                    to=""
                                    onClick={(e) => resquestHandler(e, peopleData?.id, 'add')}>
                                    Add Friend
                                  </Link>
                                )}
                              </>
                            )}
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                ) : (
                  <>
                    <h4 className="justify-content-center">No liked yet!</h4>
                  </>
                )}
              </div>
            </>
          )}
        </Modal.Body>
      </Modal>
      <Modal
        show={show2}
        onHide={handleClose2}
        backdrop="static"
        keyboard={false}
        className="timeline-modal"
        size="xl">
        <Modal.Body>
          {loading ? (
            <div className="loader-modal">
              <img src={require('../../../../assets/images/Loading_icon-small.gif')} alt="img" />
            </div>
          ) : (
            <div className="timeline-sec">
              <Row className="m-0">
                <Col className="p-0" md={8} sm={8}>
                  <div className="time-line-card bg-blck">
                    <Swiper
                      initialSlide={clickedImage}
                      spaceBetween={50}
                      slidesPerView={1}
                      modules={[Navigation]}
                      navigation={true}>
                      {Array.isArray(modalImage?.files) ? (
                        <>
                          {modalImage?.files?.map((item, index) => {
                            return (
                              <SwiperSlide key={index}>
                                <div className="timeline-slide-images">
                                  <img src={item} alt="img" />
                                </div>
                              </SwiperSlide>
                            );
                          })}
                        </>
                      ) : (
                        <>
                          {' '}
                          <SwiperSlide>
                            <div className="timeline-slide-images">
                              <img src={modalImage?.files} alt="img" />
                            </div>
                          </SwiperSlide>
                        </>
                      )}
                    </Swiper>
                  </div>
                </Col>
                <Col className="p-0" md={4} sm={4}>
                  <div className="time-line-cmmnts">
                    <Link
                      className="close-modal-btn"
                      to="javascript:void(0)"
                      onClick={handleClose2}>
                      <i className="fas fa-times"></i>
                    </Link>
                    <div className="pofile-upload-card">
                      <div className="profile-upload-sec d-flex align-items-top mb-3">
                        <span className="social-profile-icn me-3">
                          <img
                            src={
                              modalImage
                                ? process.env.REACT_APP_API_DOMAIN + modalImage?.user?.avtar
                                : require('../../../../assets/images/default-male-avatar.png')
                            }
                            alt="img"
                          />
                        </span>
                        <div className="social-profile-detail d-flex align-items-top justify-content-between ">
                          <div className="social-profile-name">
                            <h4>
                              <Link to="#">{modalImage?.user?.first_name}</Link> updated his profile
                              picture{' '}
                            </h4>
                            <p>
                              <i className="far fa-clock me-2"></i>
                              {modalImage?.timestamp}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="profile-upload-ftr">
                        <div className="profile-likes-count d-flex align-items-center justify-content-between">
                          <div className="likes-count">
                            <Link to="#">{modalImage?.likes} Likes</Link>
                            <Link to="#">{modalImage?.shares} Shares</Link>
                          </div>
                        </div>
                        <div className="profile-like-btns">
                          <ul>
                            <li>
                              <Link
                                to="#"
                                title="Like"
                                onClick={(e) => {
                                  getPostLike(e, modalImage?.id, 'modal');
                                }}>
                                <i className="fas fa-thumbs-up me-2"></i>Like
                              </Link>
                            </li>
                            <li>
                              <Link to="#" title="Share">
                                <i className="fas fa-share me-2"></i>Share
                              </Link>
                            </li>
                            <li>
                              {modalImage?.is_follow == 1 ? (
                                <Link
                                  to="#"
                                  title="follow this post"
                                  onClick={(e) => {
                                    isFollowUnfollow(e, modalImage?.id, 'modal');
                                  }}>
                                  Unfollow this post
                                </Link>
                              ) : (
                                <Link
                                  to="#"
                                  title="follow this post"
                                  onClick={(e) => {
                                    isFollowUnfollow(e, modalImage?.id, 'modal');
                                  }}>
                                  Follow this post
                                </Link>
                              )}
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="profile-upload-cmmnt mt-3">
                        {modalImage?.latest_comment?.length ? (
                          <>
                            {modalImage?.latest_comment?.map((data, index) => (
                              <>
                                <div
                                  className="profile-cmmnt-lst d-flex align-items-top mb-3"
                                  key={index}>
                                  <span className="social-profile-icn me-3">
                                    <img
                                      src={process.env.REACT_APP_API_DOMAIN + data?.user?.avtar}
                                      alt="img"
                                    />
                                  </span>
                                  <div className="profile-cmmnt-user ">
                                    <div className="d-flex align-items-top justify-content-between">
                                      <Link to="#">{data?.user?.first_name}</Link>

                                      <div className="profile-cmmnt-time d-flex align-items-center ">
                                        <span>{data?.latest_comment?.timestamp}Comment</span>
                                      </div>
                                    </div>
                                    <div className="profile-cmmnt-dis">
                                      <p>{data?.text}</p>
                                      <div className="cmmnt-likes d-flex align-items-center">
                                        <Link
                                          to="#"
                                          onClick={(e) => {
                                            getCommentLike(e, data?.id, 'modal');
                                          }}>
                                          <i className="fas fa-thumbs-up me-1"></i>
                                          {/* {data?.likes} */}
                                          {checkLike?.count}
                                        </Link>
                                        likes
                                      </div>
                                    </div>
                                  </div>
                                  {data?.user?.id === userDetail?.id ? (
                                    <Link
                                      className="close-btn"
                                      to="javascript:void(0)"
                                      onClick={() => {
                                        deletedPost(data?.id);
                                      }}>
                                      <i className="fas fa-times"></i>
                                    </Link>
                                  ) : (
                                    <>
                                      {' '}
                                      <></>
                                    </>
                                  )}
                                </div>
                              </>
                            ))}
                          </>
                        ) : (
                          <></>
                        )}

                        <div className="profile-cmmnt-input d-flex align-items-top">
                          <span className="social-profile-icn me-3">
                            <img
                              src={process.env.REACT_APP_API_DOMAIN + modalImage?.user?.avtar}
                              alt="img"
                            />
                          </span>
                          <div className="cmmnt-card">
                            <Form.Control
                              type="text"
                              onChange={(e) => {
                                setModalComment(e.target.value);
                              }}
                              onKeyPress={(e) => {
                                e.key == 'Enter' ? isPostcomment(modalImage?.id, 0, e) : '';
                              }}
                              value={modalComment}
                              placeholder="Write a comment... Press Enter to post"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          )}
        </Modal.Body>
      </Modal>

      {/* Share Modal */}
      <Modal
        show={show1}
        onHide={handleClose1}
        backdrop="static"
        keyboard={false}
        className="likes-modal">
        <Modal.Body>
          {loading ? (
            <>
              <div className="loader-modal">
                <img src={require('../../../../assets/images/Loading_icon-small.gif')} alt="img" />
              </div>
            </>
          ) : (
            <>
              {' '}
              <div className="modal-hd d-flex align-items-center justify-content-between">
                <h4>
                  <i className="fas fa-share me-2"></i> People who share this{' '}
                </h4>
                <CloseButton onClick={handleClose1} />
              </div>
              <div className="likes-user-lst">
                {peopleShareData?.length !== 0 ? (
                  <ul>
                    {peopleShareData?.map((peopleData, index) => {
                      return (
                        <li key={index}>
                          <div className="lst-user-card d-flex align-items-center justify-content-between">
                            <div className="user-like-card d-flex align-items-center">
                              <span className="like-user-icn me-2">
                                <img
                                  src={
                                    peopleData?.mediaObject
                                      ? peopleData?.mediaObject?.path +
                                        '.' +
                                        peopleData?.mediaObject?.extension
                                      : require('../../../../assets/images/default-male-avatar.png')
                                  }
                                  alt="img"
                                />
                              </span>
                              <h4>
                                <Link to={`/user/timeline/${peopleData?.encrypted_id}`}>
                                  {peopleData?.name}
                                </Link>
                              </h4>
                            </div>
                            {peopleData?.id !== userDetail?.id && (
                              <>
                                {peopleData?.unfollow && (
                                  <Link
                                    className="wht-btn-grey "
                                    to=""
                                    onClick={(e) => resquestHandler(e, peopleData?.id, 'remove')}>
                                    Unfriend
                                  </Link>
                                )}
                                {peopleData?.requested && (
                                  <Link
                                    className="wht-btn-grey "
                                    to=""
                                    onClick={(e) =>
                                      resquestHandler(e, peopleData?.id, 'requested')
                                    }>
                                    Requested
                                  </Link>
                                )}
                                {peopleData?.follow == 1 && (
                                  <Link
                                    className="wht-btn-grey "
                                    to=""
                                    onClick={(e) => resquestHandler(e, peopleData?.id, 'add')}>
                                    Add Friend
                                  </Link>
                                )}
                              </>
                            )}
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                ) : (
                  <>
                    <h4 className="justify-content-center">No Share yet!</h4>
                  </>
                )}
              </div>
            </>
          )}
        </Modal.Body>
      </Modal>

      <Modal show={showDelete} onHide={() => setShowDelete(false)}>
        <Modal.Header>
          <span>Delete Post?</span>
          <i
            style={{ cursor: 'pointer' }}
            onClick={() => setShowDelete(false)}
            className="fas fa-times"></i>
        </Modal.Header>
        <Modal.Body>
          <Button className="me-2" variant="secondary" onClick={() => setShowDelete(false)}>
            Cancel
          </Button>
          <Button variant="primary" type="button" onClick={deletedPost}>
            Delete
          </Button>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ChapterPostLayout;

const Shimmer = () => {
  return (
    <div className="pofile-upload-card loading-skeleton profile-upload-loader">
      <div className="profile-upload-cmmnt mt-3">
        <div className="profile-cmmnt-lst d-flex align-items-top mb-3">
          <span className="social-profile-icn me-3">
            <img src={require('../../../../assets/images/birth4.jpg')} alt="img" />
          </span>
          <div className="profile-cmmnt-user ">
            <div className="d-flex align-items-top justify-content-between">
              <Link to="#">Coordinator</Link>
              <div className="profile-cmmnt-time d-flex align-items-center ">
                <span />
                <div className="ellipse-drop-card ms-3 dropdown">
                  <button
                    type="button"
                    id="dropdown-basic"
                    aria-expanded="false"
                    className="dropdown-toggle btn btn-success">
                    <i className="fas fa-ellipsis-v" />
                  </button>
                </div>
              </div>
            </div>
            <div className="profile-cmmnt-dis">
              <p>ffffff</p>
              <div className="cmmnt-likes d-flex align-items-center">
                <Link to="">
                  <i className="fas fa-thumbs-up me-1" />
                  Like <span>1</span>
                  likes
                </Link>
              </div>
            </div>
            <div className="profile-cmmnt-dis">
              <p>ffffff</p>
              <div className="cmmnt-likes d-flex align-items-center">
                <Link to="">
                  <i className="fas fa-thumbs-up me-1" />
                  Like <span>1</span>
                  likes
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
