/**
@copyright  :   ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
@author     :   Shiv Charan Panjeta 
 
All Rights Reserved.
Proprietary and confidential :  All information contained here in is, and remains
the property of ToXSL Technologies Pvt. Ltd. and it's partners.
Unauthorized copying of this file, via any medium is strictly prohibited.
*/
import { useEffect, useState } from 'react';
import { Button, Container } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Table from 'react-bootstrap/Table';
import { Link } from 'react-router-dom';
import AsyncSelect from 'react-select/async';
import NoDataFound from '../../components/commoncomponents/NoDataFound';
import Loader from '../../components/commoncomponents/loader/loader-large';
import useDocumentTitle from '../../globals/useDocumentTitle';
import {
  getCompaniesCityDropDown,
  getJobResume,
  getResumaSearchAttribute
} from '../../services/AuthServices';
export default function ResumeSearch() {
  useEffect(() => {
    getKeywordsAttributes();
  }, []);

  const [location, setLocation] = useState([]);

  const [jobtitle, setJobtitle] = useState([]);
  const [loading, setLoading] = useState(false);
  useDocumentTitle('Resume Search | AlmaBay');
  const [keyWordOption, setKeyWordOption] = useState([]);
  const [clicked, setClicked] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [selectedkeyWord, setSelectedKeyword] = useState('');
  const [list, setList] = useState([]);

  const getKeywordsAttributes = (value) => {
    setLoading(true);
    getResumaSearchAttribute(value ? value : inputValue).then((res) => {
      setLoading(false);
      if (res?.status === 200) {
        let tempArr = [];
        if (res?.data?.detail) {
          let data = res?.data?.detail;
          data?.map((data) => {
            tempArr.push({
              id: data?.id,
              label: data?.text,
              value: data?.text
            });
          });
        }
        setKeyWordOption(tempArr);
      }
    });
  };

  const getKeyWordDrop = (value) => {
    return getResumaSearchAttribute(value ? value : inputValue).then((res) => {
      if (res?.status === 200) {
        let tempArr = [];
        if (res?.data?.detail) {
          let data = res?.data?.detail;
          data?.map((data) => {
            tempArr.push({
              id: data?.id,
              label: data?.text,
              value: data?.text
            });
          });
        }
        return tempArr;
      }
    });
  };

  const loadCompanyCities = (inputValue) => {
    return getCompaniesCityDropDown(inputValue).then((res) => {
      if (res?.status === 200) {
        let tempArr = [];
        if (res?.data?.data) {
          let data = res?.data?.data;
          data?.map((data) => {
            tempArr.push({
              id: data?.city_id,
              label: data?.city,
              value: data?.city_id
            });
          });
        }
        return tempArr;
      }
    });
  };

  const getResumeList = (e) => {
    // e.preventDefault();
    if (jobtitle?.length) {
      let body = {
        location: location?.id,
        //location: 1433,
        jobs_keywords: jobtitle?.map((data) => data?.value).join()
      };
      setLoading(true);
      return getJobResume(body).then((res) => {
        setLoading(false);
        setList(res?.data?.data?.resumeList);
        // showNotification('success', res?.data?.message);
      });
    } else {
      alert('Please enter job keywords');
    }
  };

  const downloadHandler = (e, url) => {
    e.preventDefault();
    window.open(url);
  };
  return (
    <div className="dash-main-content">
      <Container fluid>
        <div className="dash-page-content">
          <div className="dash-page-title">
            <Row>
              <Col xs={12} sm={12} md={12}>
                <div className="dash-title-bar d-flex align-items-center justify-content-between">
                  <div className="title-lft d-flex align-items-center">
                    <div className="icons_head">
                      <i className="fa fa-search"></i>
                    </div>
                    <div className="dash-title-dis">
                      <h5>
                        Resume <span> Search</span>
                      </h5>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <div className="dash-content-card">
            <div className="table-responsive">
              <Table
                style={{ height: 300 }}
                className="custom-table-data resume_search mb-0"
                striped
                bordered
                hover>
                <thead>
                  <tr>
                    <th>
                      <Form.Label>
                        {' '}
                        Keywords <span className="text-danger">*</span>
                      </Form.Label>
                    </th>
                    <th>
                      <Form.Label>Location</Form.Label>
                    </th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <Form.Group as={Row} className="mb-4" controlId="formHorizontalKeywords">
                        <AsyncSelect
                          isMulti
                          isSearchable={true}
                          // name="keywords"
                          loadOptions={getKeyWordDrop}
                          value={jobtitle}
                          onChange={(e) => {
                            // onChangeHandler(e, option);
                            setJobtitle(e);
                          }}
                          placeholder="Select skillset"
                        />
                      </Form.Group>
                      {/* )} */}
                      {/* <div className={'danger-frm'}>{clicked && validate().jobtitle}</div> */}
                    </td>

                    <td>
                      {/* <option value="">City</option> */}
                      <Form.Group className="mb-3" controlId="formBasicPassword">
                        <AsyncSelect
                          // isMulti
                          isSearchable={true}
                          // name="keywords"
                          loadOptions={loadCompanyCities}
                          // value={selectedkeyWord}
                          // onChange={(e, option) => {
                          //   onChangeHandler(e, option);
                          //   setSelectedKeyword(e);
                          // }}
                          value={location}
                          onChange={(e) => {
                            // onChangeHandler(e, option);
                            setLocation(e);
                          }}
                          placeholder="LOCATION"
                        />
                      </Form.Group>
                    </td>
                    <td>
                      <button
                        type="button"
                        variant="primary"
                        className="green_button_admin btn btn-primary"
                        onClick={(e) => {
                          getResumeList(e);
                        }}>
                        Search
                      </button>
                    </td>
                  </tr>
                  {list && list?.length ? (
                    <tr>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Resume</th>
                    </tr>
                  ) : (
                    ''
                  )}
                  {list && list?.length ? (
                    list?.map((data, index) => (
                      <tr key={index}>
                        <td>{data?.name}</td>
                        <td>{data?.email}</td>
                        <td>
                          <Link
                            type="button"
                            rel="noreferrer"
                            className="green_button_admin btn btn-primary"
                            onClick={(e) => {
                              e.preventDefault();
                              downloadHandler(e, data?.resumeName);
                            }}>
                            Download Existing Resume
                          </Link>
                          {/* {data?.resumeName} */}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <NoDataFound />
                  )}
                </tbody>
              </Table>
            </div>
          </div>
        </div>
      </Container>
      {loading ? <Loader /> : ''}
    </div>
  );
}
