import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { truncate } from '../../../globals/helper';
import { followMentor } from '../../../services/MentorArticlesServices';
import showNotification from '../../../services/NotificationService';
import * as APISERVICES from '../../../services/ProfileServices';
import NoDataFound from '../NoDataFound';
import { OverlayTrigger, Popover } from 'react-bootstrap';

function SuggestMentor() {
  const [checkSuggestion, setCheckSuggestion] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [clickId, setClickId] = useState(null);
  const [showCard, setShowCard] = useState(false);
  const [showButtonLoader, setShowButtonLoader] = useState(false);

  useEffect(() => {
    isMentorshipSuggestion();
  }, []);

  useEffect(() => {
    const timeId = setTimeout(() => {
      setShowCard(false);
    }, 1000);
    return () => {
      clearTimeout(timeId);
    };
  }, [showCard]);

  const isMentorshipSuggestion = (stopShimmer) => {
    if (stopShimmer !== 'stopLoading') {
      setIsLoading(true);
    }
    APISERVICES.getMentorshipMentorSuggestion().then((res) => {
      if (res?.status === 200) {
        setIsLoading(false);
        setCheckSuggestion(res?.data?.detail);
      }
    });
  };

  const handleSkip = (i) => {
    let filterdata = checkSuggestion.filter((data, index) => index !== i);
    setCheckSuggestion(filterdata);
  };

  const addMentor = (e, id) => {
    setShowButtonLoader(true);
    e.preventDefault();
    let body = {
      following_id: id
    };
    let formData = new FormData();
    for (let i in body) {
      formData.append(`${i}`, body[i]);
    }
    followMentor(formData).then((res) => {
      if (res?.status === 200) {
        setShowButtonLoader(false);
        isMentorshipSuggestion('stopLoading');
        setShowCard(true);
        // isMentorshipProfile();
        // getMentorsDirectoryList();
      } else {
        showNotification('success', res?.data?.message);
      }
    });
  };

  const renderPopover = (props) => {
    let popOverData = props?.popper?.state?.options?.data
      ? props?.popper?.state?.options?.data
      : props?.popper?.state?.options?.data;
    return (
      <Popover className="group-loader-card" id={`popover-basic`} {...props}>
        <Popover.Body>
          <div className="loader-tooltip">
            <div className="loader-cover">
              <img
                src={
                  popOverData?.userInfo?.avatar?.complete_url
                    ? popOverData?.userInfo?.avatar?.complete_url
                    : popOverData?.userInfo?.avatar_url
                }
                alt="img"
                className="loader-image"
              />
            </div>
            <div className="people-know-loader-dis d-flex align-items-top">
              <div className="people-knw-top d-flex align-items-center">
                <div className="people-icn me-3">
                  <img
                    src={
                      popOverData?.image_url
                        ? `${process.env.REACT_APP_API_URL}${popOverData?.image_url}.${popOverData?.image_format}`
                        : require('../../../assets/images/default-male-avatar.png')
                    }
                    alt="img"
                    className="loader-image"
                  />
                </div>
                <div className="people-hd">
                  <h3>{popOverData?.name}</h3>
                </div>
              </div>
            </div>
            <div className="people-knw-dis-popover">
              {/* <p>
                <i className="fas fa-graduation-cap me-1"></i> Studied at{' '}
                <Link to="#">SD COLLEGE Chandigarh</Link> in <Link to="#">2005</Link>
              </p> */}
              <p>
                <i className="fas fa-briefcase me-1"></i>Worked as
                <Link to="#">
                  {popOverData?.designation
                    ? popOverData?.designation + 'at' + popOverData?.organisation
                    : popOverData?.organisation}
                </Link>
              </p>
              <div className="popover-inr-btn">
                <button className="popovr-blk-btn me-1">Add Friend</button>
                <button
                  className="popovr-yellow-btn"
                  onClick={(e) => {
                    addMentor(e, popOverData?.ID);
                    setClickId(popOverData?.ID);
                  }}>
                  Follow Mentor
                </button>
              </div>
            </div>
          </div>
        </Popover.Body>
      </Popover>
    );
  };

  return (
    <>
      {checkSuggestion?.length > 0 ? <>
        <div className="suggest-mentor bg-white p-3 mb-3">
          {showCard ? (
            <>
              <div className="mt-4 mb-3 d-flex align-items-center flex-column">
                <i className="fa fa-check-circle" style={{ fontSize: '30px', color: '#f7ad1c' }}></i>
                <br></br>
                <span>Mentor Added successfully</span>
              </div>
            </>
          ) : (
            <>
              {' '}
              <h4>Suggested Mentors</h4>
              <ul className="mentor-ship-lst">
                {isLoading &&
                  Array.from(Array(2), (index) => {
                    return <Shimmer key={index} />;
                  })}
                {!isLoading && checkSuggestion?.length !== 0 ? (
                  checkSuggestion?.map((data, index) => {
                    return (
                      <li key={index}>
                        <div className="mentor-card d-flex">
                          <Link className="mentor-icn time-line-hover me-2">
                            <OverlayTrigger
                              trigger={['hover', 'focus']}
                              placement="bottom"
                              delay={{ show: 500, hide: 1500 }}
                              data={data}
                              popperConfig={{
                                data
                              }}
                              shouldUpdatePosition={true}
                              overlay={renderPopover}>
                              <img
                                src={
                                  data?.userInfo?.avatar?.complete_url
                                    ? data?.userInfo?.avatar?.complete_url
                                    : data?.userInfo?.avatar_url
                                }
                                alt="img"
                              />
                            </OverlayTrigger>
                          </Link>
                          <div className="mentor-rt-dis">
                            <h4>
                              <Link
                                onClick={() => {
                                  if (index == 0) {
                                    window.open(`${window.location.origin}/user/timeline/${data?.userInfo?.encrypted_id}`)
                                  } else {
                                    handleSkip(index - 1, 1)
                                  }
                                }}
                              >
                                {data?.name}
                              </Link>
                            </h4>
                            <p>
                              {data?.designation
                                ? truncate(data?.designation + ' at ' + data?.organisation, 20)
                                : truncate(data?.organisation, 20)}
                            </p>
                            <ul className="mentr-action-btn">
                              <li>
                                <Link
                                  to="#"
                                  onClick={(e) => {
                                    addMentor(e, data?.ID);
                                    setClickId(data?.ID);
                                  }}>
                                  {showButtonLoader && data?.ID === clickId ? (
                                    <span>
                                      <i className="fa fa-spinner fa-spin me-2"></i> Follow Mentor
                                    </span>
                                  ) : (
                                    'Follow Mentor'
                                  )}
                                </Link>
                              </li>
                              <li>
                                <Link to="#" onClick={() => handleSkip(index)}>
                                  Skip
                                </Link>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </li>
                    );
                  })
                ) : (
                  <>
                    {!isLoading && checkSuggestion.length == 0 && (
                      <NoDataFound text="No Mentor suggestions avaliable for you." />
                    )}
                  </>
                )}
              </ul>
            </>
          )}
        </div>
      </> : <></>}

    </>
  );
}

export default SuggestMentor;

const Shimmer = () => {
  return (
    <li>
      <div className="mentor-card d-flex mentor-loader loading-skeleton">
        <Link to="#" className="mentor-icn me-2">
          <img src={require('../../../assets/images/default-male-avatar.png')} alt="img" />
        </Link>
        <div className="mentor-rt-dis">
          <h4>
            <Link to="#">xDvddfv</Link>
          </h4>
          <p>xDvddfv</p>

          <ul className="mentr-action-btn">
            <li>
              <Link to="" className="ml-2">
                Add Friend
              </Link>
            </li>
            <li>
              <Link to="#">Skip</Link>
            </li>
          </ul>
        </div>
      </div>
    </li>
  );
};
