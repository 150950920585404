import { useEffect } from 'react';
import { useState } from 'react';
import { Form } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import { ZERO } from '../../../globals/constants';
import showNotification from '../../../services/NotificationService';
import {
  getPagePeopleList,
  socialPageAddAdmin,
  socialPageRemoveAdmin
} from '../../../services/SocialServices';

import NoDataFound from '../../commoncomponents/NoDataFound';
import { useSelector } from 'react-redux';

function PageAdminRoles({ pageId, allDetail }) {
  const timeline_id = useParams()?.encrypted_id;
  const [clickId, setClickId] = useState(null);
  const userDetail = useSelector((state) => state?.user?.details);
  useEffect(() => {
    if (timeline_id) {
      getPeopleList();
      getPeopleListMembers();
    }
  }, [timeline_id]);
  const [adminList, setAdminList] = useState([]);
  const [adminSuggesting, setAdminSuggesting] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [adminEditorState, setAdminEditorState] = useState('admin');

  const getPeopleList = () => {
    setLoading(true);
    let body = {
      type: 'admins'
    };

    getPagePeopleList(body, timeline_id).then((resp) => {
      setLoading(false);
      if (resp?.status === 200) {
        setLoading(false);
        setAdminList(resp?.data?.data?.peoples);
      }
    });
  };

  const getPeopleListMembers = () => {
    setLoading(true);
    let body = {
      type: 'add_admins'
    };

    getPagePeopleList(body, timeline_id).then((resp) => {
      setLoading(false);
      if (resp?.status === 200) {
        setLoading(false);
        setAdminSuggesting(resp?.data?.data?.peoples);
      }
    });
  };

  //Make Admin and Make Editor

  const addAdmin = (e, id, value) => {
    e.preventDefault();
    let body = {
      page_id: pageId,
      admin_id: id,
      admin_role: value ? value : adminEditorState
    };
    let formData = new FormData();
    for (let i in body) {
      formData.append(`${i}`, body[i]);
    }

    socialPageAddAdmin(formData).then((res) => {
      if (res?.status === 200) {
        showNotification('success', res?.data?.message);
        getPeopleList();
        getPeopleListMembers();
        document.getElementById('role').value = 'admin';
      } else {
        showNotification('err', res?.data?.message);
      }
    });
  };

  // // Remove

  const handleRemove = (e, id) => {
    setClickId(id);
    setIsLoading(true);
    e.preventDefault();
    let body = {
      page_id: pageId,
      admin_id: id
    };
    if (confirm('Do you want to Remove member ?') == true) {
      setLoading(true);
      socialPageRemoveAdmin(body).then((resp) => {
        setLoading(false);
        if (resp?.status == 200) {
          setClickId();
          setIsLoading(false);
          showNotification('success', resp?.data?.message);
          getPeopleList(timeline_id);
          getPeopleListMembers(timeline_id);
        }
      });
    } else {
      return;
    }
  };
  return (
    <>
      {' '}
      <div className="admin-role-frnt bg-white mb-3">
        <h4 className="admin-role-hd">Admin Roles</h4>
        {adminList?.length !== ZERO ? (
          adminList?.map((data, index) => {
            return (
              <ul className="admin-role-lst" key={index}>
                <li className="d-flex align-items-center justify-content-between">
                  <div className="admin-role-pro d-flex align-items-center">
                    <Link to={`/user/timeline/${data?.encrypted_id}`} className="admin-icn me-2">
                      <img
                        src={
                          data?.mediaObject
                            ? data?.mediaObject?.path + '.' + data?.mediaObject?.extension
                            : data?.avatar_url
                        }
                        alt="admin"
                      />
                    </Link>
                    <h4>
                      <Link to={`/user/timeline/${data?.encrypted_id}`}>
                        {data?.first_name} {''}
                        {data?.last_name}
                      </Link>
                    </h4>
                  </div>
                  <div className="role-drop d-flex align-items-center">
                    <Form.Select
                      aria-label="Default select example"
                      onChange={(e) => addAdmin(e, data?.id, e.target.value)}
                      // onClick={(e) => {
                      //   addAdmin(e, data?.id), setIsLoading;
                      //   setClickId(data?.id);
                      // }}
                      value={data?.role}
                    >
                      <option value="editor">Editor</option>
                      <option value="admin">Admin</option>
                    </Form.Select>
                    {data?.encrypted_id !== allDetail?.user?.encrypted_id && (
                      <>
                        {' '}
                        {data?.id == clickId && isLoading ? (
                          <button
                            className="wht-btn-grey ms-2"
                            onClick={(e) => handleRemove(e, data?.id)}
                          >
                            Removing...
                          </button>
                        ) : (
                          <button
                            className="wht-btn-grey ms-2"
                            onClick={(e) => handleRemove(e, data?.id)}
                          >
                            Remove
                          </button>
                        )}
                      </>
                    )}
                  </div>
                </li>
              </ul>
            );
          })
        ) : (
          <NoDataFound text="No Admins Found" />
        )}
        <div className="editor-dis">
          <p>
            Editors have same abilities as Admins except they cannot add or remove any admins or
            editors.
          </p>
        </div>
      </div>
      <div className="admin-role-frnt bg-white mb-3">
        <h4 className="admin-role-hd">Add admins</h4>
        {adminSuggesting?.length !== ZERO ? (
          adminSuggesting?.map((data, index) => {
            return (
              <ul className="admin-role-lst" key={index}>
                <li className="d-flex align-items-center justify-content-between">
                  <div className="admin-role-pro d-flex align-items-center">
                    <Link to={`/user/timeline/${data?.encrypted_id}`} className="admin-icn me-2">
                      <img
                        src={
                          data?.mediaObject
                            ? data?.mediaObject?.path + '.' + data?.mediaObject?.extension
                            : data?.avatar_url
                        }
                        alt="admin"
                      />
                    </Link>
                    <h4>
                      <Link to={`/user/timeline/${data?.encrypted_id}`}>{data?.name}</Link>
                    </h4>
                  </div>
                  <div className="role-drop d-flex align-items-center">
                    <Form.Select
                      aria-label="Default select example"
                      onChange={(e) => setAdminEditorState(e.target.value)}
                      id="role"
                    >
                      <option value="admin">Admin</option>
                      <option value="editor">Editor</option>
                    </Form.Select>

                    <button className="wht-btn-grey ms-2" onClick={(e) => addAdmin(e, data?.id)}>
                      Add
                    </button>
                  </div>
                </li>
              </ul>
            );
          })
        ) : (
          <NoDataFound text="You have added all your friends as admins of this Page." />
        )}
      </div>
      {/* {loading ? <Loader /> : ''} */}
    </>
  );
}

export default PageAdminRoles;
