/**
@copyright  :   ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
@author     :   Shiv Charan Panjeta 
 
All Rights Reserved.
Proprietary and confidential :  All information contained here in is, and remains
the property of ToXSL Technologies Pvt. Ltd. and it's partners.
Unauthorized copying of this file, via any medium is strictly prohibited.
*/
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Alert, Button, Col, Container, Form, Row, Table } from 'react-bootstrap';
import ReactPaginate from 'react-paginate';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import Loader from '../../../components/commoncomponents/loader/loader-large';
import { restrictAlpha } from '../../../globals/helper';
import useDocumentTitle from '../../../globals/useDocumentTitle';
import * as adminServices from '../../../services/AuthServices';
import showNotification from '../../../services/NotificationService';
import Footer from '../../CommonComponents/Footer';
import Header from '../../CommonComponents/Header';
import SideBar from '../../CommonComponents/Sidebar';
import NotAllowed from '../../NotAllowed';

export default function ListEvents() {
  useDocumentTitle('Manage Events | AlmaBay');
  const [list, setList] = useState([]);
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [orderBy, setOrderBy] = useState('DESC');
  const [shortOrderBy, setShortOrderBy] = useState('id');
  const [chekcedId, setCheckedId] = useState([]);
  const [record, setRecord] = useState('');
  const [messageView, setMessageView] = useState('');
  const [searchData, setSearchData] = useState({
    event_id: '',
    event_name: '',
    event_venue: '',
    event_date: ''
  });
  const [eventStatus, setEventStatus] = useState('');
  const [response, setResponse] = useState('');
  const [action, setAction] = useState('');
  const [allId, setAllId] = useState([]);
  const [showalert, setShowAlert] = useState(false);
  const [showErrorComponent, setShowErrorComponent] = useState(false);

  const roleType = useSelector((state) => state?.user?.details?.admin_type);
  const paths = useSelector((state) => state?.adminPaths?.paths);

  useEffect(() => {
    apiCall();
    return () => {
      setShowErrorComponent(false);
    };
  }, []);

  useEffect(() => {
    const timeId = setTimeout(() => {
      // After 3 seconds set the show value to false
      setShowAlert(false);
      setMessageView('');
    }, 3000);
    return () => {
      clearTimeout(timeId);
    };
  }, [showalert]);

  const apiCall = (page = 1) => {
    setLoading(true);

    const body = {
      id: '',
      page: page,
      order: 'DESC',
      sortfield: 'id',
      event_id: '',
      event_name: '',
      event_venue: '',
      event_status: ''
    };

    setCurrentPage(page);

    adminServices.eventList(body).then((resp) => {
      setLoading(false);
      if (resp?.status === 200) {
        setLoading(false);
        setCheckedId([]);
        setList(resp?.data?.detail.viewlist);
        setRecord(resp?.data?.detail?.totalRecords);
        setAllId(resp?.data?.detail.viewlist.map((data) => data?.id));
      }
    });
  };

  const onChangeSearch = (e) => {
    const { name, value } = e.target;
    setSearchData({ ...searchData, [name]: value });
  };

  const searchHandler = (id = '', page = 1, order = 'DESC', sortfield = 'id') => {
    setLoading(true);
    const body = {
      id,
      page,
      order,
      sortfield,
      event_id: searchData.event_id,
      event_name: searchData.event_name.trim(),
      event_venue: searchData.event_venue.trim(),
      event_status: eventStatus
    };
    setCurrentPage(page);

    adminServices.eventList(body).then((resp) => {
      setLoading(false);
      if (resp.status === 200) {
        setList(resp?.data?.detail.viewlist);
        setRecord(resp?.data?.detail?.totalRecords);
        setAllId(resp?.data?.detail?.viewlist.map((data) => data?.id));
      }
    });
  };

  // const handlePageClick = (a) => {
  //   let page = a?.selected;
  //   apiCall(page, searchData.event_id, searchData.event_name, searchData.event_venue);
  // };

  const handlePageClick = (a) => {
    setCheckedId([]);
    let currentPage = a?.selected + 1;
    searchHandler('', currentPage);
  };

  const sortHandler = (e, type) => {
    e.preventDefault();
    let currentSort = orderBy === 'ASC' ? 'DESC' : 'ASC';
    setOrderBy(currentSort);
    setShortOrderBy(type);
    searchHandler(
      '',
      1,
      currentSort,
      type,
      searchData.event_id,
      searchData.event_name,
      searchData.event_venue,
      eventStatus
    );
    setCurrentPage(1);
  };

  const selectHandler = (value, id) => {
    switch (value) {
      case 'edit':
        navigate(`/admin/event/create?event-id=${id}`);
        break;
      case 'view':
        navigate('/admin/event/view', { state: id });
        break;
      case 'sendInvitation':
        navigate(`/admin/event/AddEventInvitation?adminEventId=${id}`);
        break;
      case 'eventState':
        navigate(`/admin/event/EventStat?eventId=${id}`);
        break;
      case 'manageActivity':
        navigate(`/admin/event/ManageActivity?event_id=${id}`);
        break;
      case 'manageSponsors':
        navigate(`/admin/event/SponsorRequest?eventId=${id}`, { state: id });
        break;
      case 'sponsorRequest':
        navigate(`/admin/event/EventSponsors?eventId=${id}`);
        break;
      case 'manage_tickets':
        navigate('/admin/event/EventTicket', { state: id });
        break;

      case 'booking':
        navigate(`/admin/event/bookings/${id}`, { state: id });
        break;
      default:
        break;
    }
  };

  const globalStateHandler = () => {
    setShowAlert(true);
  };

  const handleAction = (e) => {
    if (chekcedId?.length !== 0) {
      setAction(e.target.value);
      const body = {
        ids: chekcedId?.toString(),
        action: e.target.value
      };
      setLoading(true);
      adminServices.eventSelected(body).then((resp) => {
        setLoading(false);
        if (resp.status === 200) {
          setMessageView(resp?.data?.message);
          document.getElementById('select1').value = '';
          apiCall();
          showNotification('success', resp?.data?.message);
        }
      });
    } else {
      globalStateHandler();
    }
  };

  const resetHandler = (e) => {
    e.preventDefault();
    setSearchData({
      event_id: '',
      event_name: '',
      event_venue: '',
      event_date: '',
      event_status: ''
    });
    // setSortType('DESC');
    apiCall();
  };

  const handleClick = () => {
    if (paths?.includes('admin/event/create')) {
      navigate('/admin/event/create');
    } else {
      setShowErrorComponent(true);
    }
  };

  /**
   * function call after check all
   * @param {*} e
   */
  const handleSelectAll = (e) => {
    if (e.target.checked === true) {
      allId.forEach((id) => {
        if (!chekcedId.includes(id)) {
          setCheckedId((prev) => {
            return [...prev, id];
          });
        }
      });
    } else {
      setCheckedId([
        ...chekcedId.filter((id) => {
          return allId.indexOf(id) == -1;
        })
      ]);
    }
  };

  /**
   * function for individual chechbox
   * @param {*} e
   * @param {*} id
   */
  const handleMultipleCheckBox = (e, id) => {
    if (chekcedId.includes(id)) {
      setCheckedId(
        chekcedId.filter((data) => {
          return data !== id;
        })
      );
    } else {
      setCheckedId([...chekcedId, id]);
    }
  };

  return showErrorComponent ? (
    <NotAllowed />
  ) : (
    <>
      <div className="dash-main-content">
        <Container fluid>
          <div className="dash-page-content">
            <div className="dash-page-title">
              <Row>
                <Col xs={12} sm={12} md={12}>
                  <div className="dash-title-bar d-flex align-items-center justify-content-between">
                    <div className="title-lft d-flex align-items-center">
                      <div className="dash-icn">
                        <i className="fa fa-users"></i>
                      </div>
                      <div className="dash-title-dis">
                        <h5>
                          Event <span>Listing</span>
                        </h5>
                        {record > 0 && (
                          <h5>
                            Total Record(s): <span>{record}</span>
                          </h5>
                        )}
                      </div>
                    </div>

                    <div className="title-rt-btn d-flex align-items-center">
                      <button
                        type="button"
                        className="green_button_admin mx-2"
                        // to={
                        //   paths?.includes('admin/event/create') ? '/admin/event/create' : '/admin'
                        // }
                        onClick={() => {
                          handleClick();
                        }}>
                        Add New Event
                      </button>
                      {roleType == '3' && (
                        <Link
                          className="green_button_admin mx-2"
                          to={
                            paths?.includes('admin/event/create')
                              ? '/admin/add-banner?id=7'
                              : '/admin'
                          }>
                          Add Banner
                        </Link>
                      )}

                      {/* enable,disable,del,delImg */}
                      <div className="head-instite-sec">
                        <Form.Select
                          aria-label="Default select example "
                          onChange={handleAction}
                          id="select1">
                          <option value="">Choose Action</option>
                          <option value="enable">Enable</option>
                          <option value="disable">Disable</option>
                          <option value="del">Delete</option>
                          {/* <option value="delImg">Delete Image Only </option> */}
                        </Form.Select>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="dash-content-card">
              <Alert variant={'danger'} className={`${showalert ? '' : 'd-none'}`}>
                {messageView ? messageView : 'Please select any record'}
              </Alert>
              <div className="table-responsive">
                <Table className="custom-table-data" striped bordered hover>
                  <thead>
                    <tr>
                      <th>
                        <Form.Check
                          type="checkbox"
                          id="checkbox"
                          label=""
                          checked={
                            allId?.every((elem) => {
                              return chekcedId.includes(elem);
                            }) && list?.length > 0
                              ? true
                              : false
                          }
                          onChange={(e) => handleSelectAll(e)}
                        />
                      </th>
                      <th>
                        <Link
                          to=""
                          onClick={(e) => {
                            sortHandler(e, 'id');
                          }}>
                          Id
                          <img src={require('../../../assets/images/sort.png')} alt="sort" />
                        </Link>
                      </th>
                      <th>
                        <Link
                          to=""
                          onClick={(e) => {
                            sortHandler(e, 'name');
                          }}>
                          Name
                          <img src={require('../../../assets/images/sort.png')} alt="sort" />
                        </Link>
                      </th>
                      <th>
                        <Link to="">Venue</Link>
                      </th>
                      <th>
                        <Link to="">Event Dates</Link>
                      </th>
                      <th>
                        <Link
                          to=""
                          onClick={(e) => {
                            sortHandler(e, 'status');
                          }}>
                          Status
                          <img src={require('../../../assets/images/sort.png')} alt="sort" />
                        </Link>
                      </th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="search-table">
                      <td></td>
                      <td>
                        <Form.Control
                          type="text"
                          placeholder="Id"
                          value={searchData.event_id}
                          name="event_id"
                          onChange={(e) => {
                            onChangeSearch(e);
                          }}
                          onKeyPress={restrictAlpha}
                        />
                      </td>
                      <td>
                        <Form.Control
                          type="text"
                          placeholder="name"
                          value={searchData.event_name}
                          name="event_name"
                          onChange={(e) => {
                            onChangeSearch(e);
                          }}
                        />
                      </td>
                      <td>
                        <Form.Control
                          type="email"
                          placeholder="Venue"
                          value={searchData.event_venue}
                          name="event_venue"
                          onChange={(e) => {
                            onChangeSearch(e);
                          }}
                        />
                      </td>
                      <td>
                        <div className="d-flex align-items-center"></div>
                      </td>
                      <td>
                        <Form.Select
                          aria-label="Default select example"
                          name="status"
                          value={eventStatus}
                          onChange={(e) => {
                            setEventStatus(e.target.value);
                          }}>
                          <option value=""> - Select - </option>
                          <option value={1}>Enable</option>
                          <option value={0}>Disable</option>
                        </Form.Select>
                      </td>
                      <td className="serac-btns" colSpan={2}>
                        <button
                          type="button"
                          className="green_button_admin"
                          onClick={(e) => {
                            searchHandler();
                          }}>
                          Search
                        </button>

                        {/* <Button
                          className="main-btn-red ms-2"
                          onClick={(e) => {
                            resetHandler(e);
                          }}>
                          Reset
                        </Button> */}
                      </td>
                    </tr>
                    {list && list?.length ? (
                      list?.map((data, index) => {
                        return (
                          <tr key={index}>
                            <td>
                              <Form.Check
                                type="checkbox"
                                label=""
                                id={`id${data?.id}`}
                                name={data?.name}
                                checked={chekcedId?.includes(data?.id)}
                                onChange={(e) => {
                                  handleMultipleCheckBox(e, data?.id);
                                }}
                              />
                            </td>
                            <td>{data.id}</td>
                            <td>{data?.name} </td>
                            <td>{data?.venue}</td>
                            {data?.to_event_date == '0000-00-00 00:00:00' ? (
                              <>{moment(data.from_event_date).format('Do MMMM YYYY')}</>
                            ) : (
                              <>
                                {' '}
                                <td>
                                  {' '}
                                  {moment(data.from_event_date).format('Do MMMM YYYY')}-
                                  {moment(data.to_event_date).format('Do MMMM YYYY')}{' '}
                                </td>
                              </>
                            )}

                            <td>{data.status == '1' ? 'Enable' : 'Disable'}</td>
                            <td>
                              <Form.Select
                                aria-label="Default select example"
                                onChange={(e) => {
                                  selectHandler(e.target.value, data?.id);
                                }}>
                                <option>Action</option>
                                {paths?.includes('admin/event/create') && (
                                  <option value="edit">Edit</option>
                                )}

                                <option value="view">View</option>
                                <option value="sendInvitation">Send Invitation</option>
                                <option value="eventState">Event Stats</option>
                                <option value="manageActivity">Manage Activities</option>
                                <option value="manageSponsors">Manage Sponsors</option>
                                <option value="sponsorRequest">Sponsors Request</option>
                                <option value="manage_tickets">Manage Tickets</option>
                                <option value="booking">Bookings</option>
                              </Form.Select>
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr className="text-center">
                        <td colSpan={9}>Data Not Found</td>{' '}
                      </tr>
                    )}
                  </tbody>
                </Table>

                <div className="react-page d-flex">
                  {Math.ceil(record / 10) > 1 ? (
                    <ReactPaginate
                      previousLabel={'<'}
                      nextLabel=">"
                      onPageChange={handlePageClick}
                      pageCount={record / 10}
                      activeClassName={'page-link active'}
                      forcePage={currentPage - 1}
                    />
                  ) : (
                    ''
                  )}
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
      {loading ? <Loader /> : <></>}
    </>
  );
}
