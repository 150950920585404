/**
 * @copyright : ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
@author    : Shiv Charan Panjeta 
 
All Rights Reserved.
Proprietary and confidential :  All information contained here in is, and remains
the property of ToXSL Technologies Pvt. Ltd. and it's partners.
Unauthorized copying of this file, via any medium is strictly prohibited.
 */

import moment from 'moment';
import { useEffect, useState } from 'react';
import { Button, Card, Col, Container, Row } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import instituteBanner from '../../../assets/images/institute-landing-banner.png';
import RightIcon from '../../../assets/images/right_arrow_symbol.png';
import { truncate } from '../../../globals/helper';
import {
  checkDomain,
  getClubBlogDetail,
  getClubInsNews,
  getClubPageDetail
} from '../../../services/InstituteServices';

function InstituteLanding() {
  const [state, setState] = useState();
  const [blogList, setBlogList] = useState([]);
  const [newsList, setNewsList] = useState([]);
  const [layoutDetail, setLayoutDetail] = useState('');
  const [about, setAbout] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    checkApi();
    getInfo();
  }, []);

  const checkApi = () => {
    checkDomain().then((res) => {
      if (res?.status === 200) {
        setState(res?.data?.details);
      }
    });

    // getHomepageLayout().then((res) => {
    //     if (res?.status == 200) {
    //         setLayoutDetail(res?.data?.data);
    //     }
    // });
  };

  // to get informations
  const getInfo = () => {
    getClubPageDetail().then((res) => {
      if (res?.status == 200) {
        let data = res?.data?.details;
        setAbout(data);
      }
    });

    getClubBlogDetail().then((res) => {
      if (res?.status == 200) {
        setBlogList(res?.data?.details);
      }
    });

    getClubInsNews().then((res) => {
      if (res?.status == 200) {
        setNewsList(res?.data?.details);
      }
    });
  };

  return (
    <>
      <Container>
        <section className="institue-banner ">
          <img
            width={'100%'}
            className="bannerimgnew"
            src={
              state?.Home_content?.banner_file ? state?.Home_content?.banner_file : instituteBanner
            }
            alt=""
          />
          <Row>
            <Col md={8} lg={6} sm={12}>
              <div className="banner-cont">
                <h3 className="banner-title">
                  {state?.Home_content?.title || 'PERFECT PLACE TO CONNECT WITH BATCHMATES!'}
                </h3>
                {layoutDetail?.sub_title ? (
                  <span dangerouslySetInnerHTML={{ __html: layoutDetail?.sub_title }} />
                ) : (
                  'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.'
                )}
              </div>
            </Col>
          </Row>
        </section>
      </Container>

      {about && about?.description && (
        <section className="institution_welcome">
          <Container>
            <Row>
              <Col lg={7}>
                <div className="institution_welcome_content pt-3">
                  <h5>Welcome to</h5>
                  <h3>{state?.name}</h3>
                  <span dangerouslySetInnerHTML={{ __html: truncate(about?.description, 600) }} />
                  <Button
                    onClick={() => {
                      navigate('/club/about');
                    }}
                    className="btn p-2">
                    Explore
                  </Button>
                </div>
              </Col>
              <Col lg={5}>
                {/* {about?.multiple_image} */}
                {about?.multiple_image && about?.multiple_image?.length ? (
                  <>
                    {' '}
                    <div className="d-flex justify-content-between">
                      {about?.multiple_image?.slice(0, 2)?.map((data, index) => (
                        <div key={index} className="p-2">
                          <img src={data?.multiple_image} width="100%" className="" alt="img" />
                        </div>
                      ))}
                    </div>
                    <div className="d-flex justify-content-between">
                      {about?.multiple_image?.slice(2, 4)?.map((data, index) => (
                        <div key={index} className="p-2">
                          <img src={data?.multiple_image} width="100%" className="" alt="img" />
                        </div>
                      ))}
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </Col>
            </Row>
          </Container>
        </section>
      )}

      <section className="news-update-section position-relative">
        <Container fluid>
          <Row>
            <Col lg={8} md={6} sm={12} className="offset-lg-2 offset-md-0 ps-lg-5">
              <div className="news ps-lg-2">
                <div className="d-flex align-items-center justify-content-between mb-3">
                  <h3 className="main-title">News & Updates</h3>
                  {newsList?.length > 0 ? (
                    <Link to="/club/news">
                      See All <img src={RightIcon} alt="logo" className="ms-3" />
                    </Link>
                  ) : (
                    <></>
                  )}
                </div>
                {newsList && newsList?.length ? (
                  newsList?.slice(0, 5)?.map((data, index) => (
                    <Card
                      key={index}
                      className="mt-4 pt-2 pointer"
                      role="presentation"
                      onClick={() => {
                        navigate(`/club/news-detail/${data?.slug}`);
                      }}>
                      <Card.Body>
                        <h3 className="subtitle">
                          {moment(data?.date_modified).format('MMM DD,YYYY')}
                        </h3>
                        <h4>{data?.title}</h4>
                      </Card.Body>
                    </Card>
                  ))
                ) : (
                  <Card className="mt-4 pt-2 text-center nodata bg-white">
                    <Card.Body>
                      <img src={require('../../../assets/images/nodata.png')} alt="img" />
                      <p>No Data Found at this moment</p>
                    </Card.Body>
                  </Card>
                )}
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="blog-section mb-2">
        <Container>
          <div className="d-flex align-items-center justify-content-between mb-3">
            <h2>Our Blogs</h2>
            {blogList?.length ? (
              <Link to={'/club/blogs'}>
                See All <img src={RightIcon} alt="logo" className="ms-3" />
              </Link>
            ) : (
              <></>
            )}
          </div>
          <Row>
            {blogList && blogList?.length ? (
              blogList?.slice(0, 4)?.map((data, index) => (
                <Col sm={12} md={4} lg={3} key={index}>
                  <Card className="mb-3 mb-lg-0">
                    {/* <img src={require('../../../assets/images/institute-blog-img.png')} alt="img" /> */}
                    <img
                      src={data?.image}
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src = require('../../../assets/images/institute-blog-img.png');
                      }}
                      alt="img"
                    />
                    <Card.Body>
                      <h3 className="subtitle">
                        {data?.created_on ? moment(data?.created_on).format('MMM DD,YYYY') : ''}
                      </h3>
                      <h4 className="card-title">{data?.title}</h4>
                      {/* <p className="card-text">{}</p> */}
                      <span
                        className="card-text"
                        dangerouslySetInnerHTML={{ __html: truncate(data?.description, 100) }}
                      />
                      <Link to={`/club/blog-details/${data?.slug}`} className="btn explore mt-0">
                        Explore
                      </Link>
                    </Card.Body>
                  </Card>
                </Col>
              ))
            ) : (
              <div className="text-center nodata bg-white">
                <img src={require('../../../assets/images/nodata.png')} alt="img" />
                <p>Data Not Found</p>
              </div>
            )}
          </Row>
        </Container>
      </section>
      {/* <section className="testimonial-section">
                <Container>
                    <Row>
                        <Col sm={12} md={12} lg={12} className="px-0">
                            <div className="testimonial-img">
                                <div className="cont-box">
                                    <h3>Gallery</h3>
                                    <Link to="/gallery" className="btn see-all mt-0">
                                        See All <img src={RightIcon} alt="logo" className="ms-3" />
                                    </Link>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section> */}
    </>
  );
}

export default InstituteLanding;
