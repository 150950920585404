import { Link } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { Container } from 'react-bootstrap';
import { useGoogleLogin } from '@react-oauth/google';
import { useState } from 'react';
import CKEditorComponent from '../../webpages/user-pages/PostComponets/CKEditorComponent';
import { defaultContent } from '../../../globals/helper';
import HeaderLogin from '../../commoncomponents/HeaderLogin';
import HeaderInner from '../../commoncomponents/HeaderInner';
import { useSelector } from 'react-redux';
import LandingBackground from '../../../assets/images/landing_one_banner.png';
import useDocumentTitle from '../../../globals/useDocumentTitle';

function InviteFriends() {
  useDocumentTitle('Almabay');
  const [searchFriend, setSearchFriend] = useState('');
  const [show, setShow] = useState(false);
  const [inviteContent, setInviteComment] = useState(defaultContent().output);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const googlelogin = useGoogleLogin({
    onSuccess: (tokenResponse) => console.log(tokenResponse)
  });

  const searchOnClick = () => {
    handleShow();
  };

  /**
   * toggle header after checking whether user is logged in or not
   */
  const isLoggedIn = useSelector((state) => {
    return state?.user?.isloggedIn;
  });

  var bannerSectionStyle = {
    backgroundImage: `URL(${LandingBackground})`,
    backgroundSize: `cover`
  };

  return (
    <>
      {isLoggedIn ? <HeaderLogin /> : <HeaderInner />}
      <section className="invite-friend pt-3 pb-5">
        <Container>
          <div className="invite-frnd-member p-5 mb-4" style={bannerSectionStyle}>
            <div className="invite-frnd-inr">
              <Row>
                <Col lg={6} md={12}>
                  <div className="invite-frnd-lft">
                    <p>Invite your friends to like</p>
                    <h4>Creating a new page</h4>
                  </div>
                </Col>
                <Col lg={6} md={12}>
                  <div className="invite-member">
                    <p>2 Members</p>
                    <ul className="member-list">
                      <li>
                        <Link to="#">
                          <img src={require('../../../assets/images/person.png')} alt="user" />
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          <img src={require('../../../assets/images/person.png')} alt="user" />
                        </Link>
                      </li>
                      <li className="end-card">
                        <Link to="#">+11</Link>
                      </li>
                    </ul>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
          <div className="invite-frnd-inr">
            <Row>
              <Col md={6} sm={6} xs={12}>
                <div className="find-friends-card">
                  <div className="find-friend-search d-flex align-items-center justify-content-between mb-4">
                    <Link className="find-frnd-icn" to="/">
                      <img src={require('../../../assets/images/person.png')} alt="user" />
                    </Link>
                    <div className="invite-frnd-dis">
                      <h3>Search and Invite Friends</h3>
                      <p>Invite your friends to stay connected</p>
                    </div>
                  </div>
                  <div className="invite-srch-list">
                    <Form.Group
                      className="invite-search"
                      controlId="formBasicEmail"
                      onChange={(e) => setSearchFriend(e.target.value)}
                    >
                      <div className="srch-inr-card">
                        <Form.Control type="text" placeholder="e.g. abc@xyz.com" />

                        <span className="srch-btn">
                          {searchFriend == '' ? (
                            <i className="fas fa-search"></i>
                          ) : (
                            <Link to="" onClick={() => searchOnClick()}>
                              {' '}
                              <i className="fas fa-plus-circle"></i>
                            </Link>
                          )}
                        </span>
                      </div>
                    </Form.Group>
                  </div>
                </div>
              </Col>
              <Col md={3} sm={3} xs={12}>
                <div className="google-card">
                  <Link to="" onClick={() => googlelogin()}>
                    <img src={require('../../../assets/images/google-plus.png')} alt="google" />
                  </Link>
                </div>
              </Col>
              <Col md={3} sm={3} xs={12}>
                <div className="yahoo-card">
                  <Link to="/">
                    <img src={require('../../../assets/images/yahoo.png')} alt="yahoo" />
                  </Link>
                </div>
              </Col>
            </Row>
          </div>
          <div className="invite-lst-card mt-3">
            <Row>
              <Col lg={3}>
                <div className="invite-card-inr">
                  <button className="close-btn">
                    <i className="fas fa-times"></i>
                  </button>
                  <span className="invite-user">
                    <img src={require('../../../assets/images/yahoo.png')} alt="yahoo" />
                  </span>
                  <h4>userone </h4>
                  <p>userone@g.in</p>
                  <button className="invite-yellow">Re-Invite</button>
                </div>
              </Col>
            </Row>
          </div>
          <div className="in-frnd-lst bg-white py-5 mt-4">
            <img
              className="mb-5"
              src={require('../../../assets/images/blank-logo.png')}
              alt="Blank"
            />
            <h4>Scroll through your list of friends, invite them and get connected.</h4>
            <p>
              Almabay makes it easier to catch up and share with your friends, family, teammates and
              ex- batchmates. May it be baby, marriage, book publications or a new venture.
            </p>
          </div>
        </Container>
      </section>

      <Link title="Invite All" className="invite-check">
        <i className="fas fa-check"></i>
      </Link>

      <Modal show={show} onHide={handleClose} className="feedback-modal">
        <Modal.Header closeButton>
          <Modal.Title>Send Invitation to {searchFriend}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CKEditorComponent
            latestPost={inviteContent}
            setLatestPost={setInviteComment}
            isToolBar={true}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleClose}>
            Send Invite
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default InviteFriends;
