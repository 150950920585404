import moment from 'moment';
import { useEffect, useState } from 'react';
import { Container, Table } from 'react-bootstrap';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { emailQueueDetail } from '../../services/AuthServices';

export default function ViewReportDetails() {
  const [viewData, setViewData] = useState('');
  const [content, setContent] = useState('');
  const { id } = useParams();
  let navigate = useNavigate();
  useEffect(() => {
    if (id) {
      ViewDataFunc();
    }
  }, []);
  const ViewDataFunc = () => {
    emailQueueDetail(id).then((res) => {
      if (res?.status === 200) {
        setViewData(res?.data?.data?.List);
        setContent(res?.data?.data?.List?.message);
      }
    });
  };

  return (
    <div className="dash-main-content">
      <Container fluid>
        <div className="dash-page-content">
          <div className="dash-page-title">
            <div className="dash-title-bar d-flex align-items-center justify-content-between">
              <div className="title-lft d-flex align-items-center">
                <div className="dash-icn">
                  <i className="fa fa-file"></i>
                </div>
                <div className="dash-title-dis">
                  <h5>View Report Details</h5>
                </div>
              </div>
              <div>
                <div className="title-rt-btn ">
                  <Link
                    className="add-btn-grn"
                    to="#"
                    onClick={(e) => {
                      e.preventDefault();
                      navigate(-1);
                    }}>
                    Back To Listing
                  </Link>
                </div>
              </div>
            </div>
            <div className="dash-content-card">
              <Table className="custom-table-vertical table-bordered">
                <tbody>
                  <tr>
                    <th>Subject </th>
                    <td>:</td>
                    <td>{viewData?.subject}</td>
                  </tr>

                  <tr>
                    <th>Sent On</th>
                    <td>:</td>
                    <td>{moment(viewData?.send_date).format('lll')}</td>
                  </tr>
                  <tr>
                    <th>sent By</th>
                    <td>:</td>
                    <td>{viewData?.from_name}</td>
                  </tr>
                  <tr>
                    <th>Text</th>
                    <td>:</td>
                    <td>
                      <span dangerouslySetInnerHTML={{ __html: viewData?.message }} />
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}
