import { useEffect, useRef, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { followCompanySuggestion } from '../../../services/CompanyServices';
import * as APISERVICES from '../../../services/JobServices';

function SimilarCompany() {
  const [companySuggest, setCompanySuggest] = useState([]);
  const { id } = useParams();

  const sliderRef = useRef();

  useEffect(() => {
    CompanySuggestion();
  }, []);

  const CompanySuggestion = (offset) => {
    APISERVICES.jobCompanySuggestions(id, offset).then((res) => {
      if (res?.status === 200) {
        if (res?.data?.company_pages) {
          let data = res?.data?.company_pages ? res.data.company_pages : []
          setCompanySuggest([...companySuggest, ...data]);
        }
      }
    });
  };

  const handleSlideNext = (e) => {
    e.preventDefault();
    sliderRef.current.swiper.slideNext();
    CompanySuggestion(companySuggest?.length + 2);
  };

  const handleSlidePrev = (e) => {
    e.preventDefault();
    sliderRef.current.swiper.slidePrev();
  };

  const handleShowInstiType = (key) => {
    switch (key) {
      case 'company':
        return 'Corporate';

      case 'ngo':
        return 'Non Government';
      case 'npo':
        return 'Non Profit';

      case 'gov_agency':
        return 'Govt Agencies';

      case 'professionals':
        return 'Professionals';

      case 'entrepreneur':
        return 'Entrepreneur';

      default:
        return 'N/A';
    }
  };

  const followCompany = async (e, id) => {
    e.preventDefault();
    try {
      let body = {
        following_id: id
      };
      const response = await followCompanySuggestion(body);
      if (response?.status === 200) {
        CompanySuggestion();
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <div className="suggest-mentor artical-blog-card bg-white p-3 mb-3">
        <div className="d-flex align-items-top justify-content-between flex-wrap">
          <h4>Similar Companies</h4>
          <div className="follow-slide-arrow">
            <Link to="#" className="p-2 " onClick={(e) => handleSlidePrev(e)}>
              <i className="fa fa-angle-left"></i>
            </Link>
            <Link to="#" className="p-2 " onClick={(e) => handleSlideNext(e)}>
              <i className="fa fa-angle-right"></i>{' '}
            </Link>
          </div>
        </div>
        <div className="follow-company-card">
          <Swiper
            spaceBetween={50}
            ref={sliderRef}
            slidesPerView={1}
            modules={[Navigation]}
            navigation={true}
          >
            {companySuggest && companySuggest?.length
              ? companySuggest?.map((data, index) => {
              
                return (
                  <SwiperSlide key={index}>
                    <div className="follow-card-slide">
                      <div className="follow-comp-img mb-3">
                        <Link to="/">
                          <img
                            src={require('../../../assets/images/mentorsdirectory-listview-2.png')}
                            alt="user_img"
                          />
                        </Link>
                      </div>
                      <div className="follow-company-dis">
                        <h4>
                          <Link to="/">{data?.name}</Link>
                        </h4>
                        <p>{handleShowInstiType(data?.institute_type)}</p>
                        <p>{data?.company_size} Employees</p>
                        <p>{data?.city}</p>
                        <span>{data?.followers}</span>
                      </div>
                      <Link
                        onClick={(e) => {
                          followCompany(e, data?.timeline_id);
                        }}
                        to={`javascript:void(0)`}
                        className="wht-btn-grey mt-2 mb-2"
                      >
                        Follow
                      </Link>
                    </div>
                  </SwiperSlide>
                );
              })
              : 'No similar Companies found'}
          </Swiper>
        </div>
      </div>
    </>
  );
}

export default SimilarCompany;
