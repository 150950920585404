/**
@copyright  :   ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
@author     :   Shiv Charan Panjeta 
 
All Rights Reserved.
Proprietary and confidential :  All information contained here in is, and remains
the property of ToXSL Technologies Pvt. Ltd. and it's partners.
Unauthorized copying of this file, via any medium is strictly prohibited.
*/

import moment from 'moment';
import { useEffect, useState } from 'react';
import { Col, Form, Modal, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import { useSelector } from 'react-redux';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { resumeExtension, truncate } from '../../../globals/helper';
import useDocumentTitle from '../../../globals/useDocumentTitle';
import * as APISERVICES from '../../../services/JobServices';
import showNotification from '../../../services/NotificationService';
import * as APISERVICE from '../../../services/UserServices';
import { loadJObLocationOption } from '../../../super-admin/AdminHelper';
import FooterMain from '../../commoncomponents/FooterMain';
import HeaderLogin from '../../commoncomponents/HeaderLogin';
import { NetworkSimmer } from '../../commoncomponents/skelton/allskelton';
import ApplyForm from '../common-professional-comp/ApplyForm';
import JobCategoryList from '../common-professional-comp/JobCategoryList';
import JobFilterSalary from '../common-professional-comp/JobFilterSalary';
import RecomendModal from '../common-professional-comp/RecomendModal';
import SocialShare from './SocialShare';
import TopTabbings from './TopTabbings';

function JobinNetwork() {
  useDocumentTitle('Browse Jobs | AlmaBay');
  const { state } = useLocation();
  const [refDetail, setRefDetail] = useState([]);
  const { id } = useParams();
  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);
  const [list, setList] = useState();
  const [applyForm, setApplyForm] = useState({
    to: ' ',
    subject: ' ',
    message: ' ',
    resume: ' '
  });
  const [savedResume, setSavedResume] = useState('');
  const [jobId, setJobId] = useState('');
  const [jobTitle, setJobTitle] = useState('');
  const [isChecked, setIsChecked] = useState(false);
  const [resumeSelect, setResumeSelect] = useState(false);
  const [fileSizeValid, setFileSizeValid] = useState(false);
  const navigate = useNavigate();
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [skillsFilterId, setSkillsFilterId] = useState('');
  const [companyFilterId, setCompanyFilterId] = useState('');
  const [industryFilterId, setIndustryFilterId] = useState('');
  const [locationFilterId, setLocationFilterId] = useState('');
  const [minCtc, setMinCtc] = useState('');
  const [maxCtc, setMaxCtc] = useState('');
  const [minExp, setMinExp] = useState('');
  const [maxExp, setMaxExp] = useState('');
  const [show3, setShow3] = useState(false);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);
  const [techSkill, setTechSkill] = useState([]);
  const [techSearch, setTechSearch] = useState('');
  const [jobLocation, setJobLocation] = useState([]);
  const [locationSearch, setLocationSearch] = useState(null);
  const [totalJobs, setTotalJobs] = useState(null);
  const [pageNum, setPageNum] = useState(1);
  const [loadInpMsg, setloadInpMsg] = useState(false);

  const techSkillLabel = techSkill.map((data) => {
    return data.label;
  });

  const locationID = jobLocation.map((data) => {
    return data.id;
  });

  const [inputMsg, setInputMsg] = useState('Please enter atleast 2 characters.');

  useEffect(() => {
    if (id) {
      getSearchedFilterData(id);
    } else {
      getNetworkJob();
      getUserDetail();
    }
  }, [id]);

  //get job-in-network lists

  const getNetworkJob = () => {
    setLoading(true);
    // location=&company=&skillset=fico&category=&min_exp=&max_exp=&min_ctc=&max_ctc=

    let location = locationID.toString();
    let company = companyFilterId;
    let skillset = techSkillLabel.toString();
    let category = industryFilterId;
    let min_exp = minExp;
    let max_exp = maxExp;
    let min_ctc = minCtc;
    let max_ctc = maxCtc;

    APISERVICES.getJobInNetwork(
      locationFilterId,
      company,
      skillset,
      category,
      min_exp,
      max_exp,
      min_ctc,
      max_ctc
    ).then((res) => {
      setLoading(false);
      if (res?.status === 200) {
        setList(res?.data?.detail?.results);
        setTotalJobs(res?.data?.pager?.total);

        // setCount(res?.data?.pager);
      }
    });
  };

  const getSearchedFilterData = (cat_id = '', page) => {
    if (cat_id) {
      navigate(`/jobs/search/category/${cat_id}`, { state: state ? state : 4 });
      if (!page) {
        setLoading(true);
      }

      APISERVICES.getSearchedJob(
        state,
        skillsFilterId,
        companyFilterId,
        industryFilterId,
        locationFilterId,
        cat_id,
        minExp,
        maxExp,
        minCtc,
        maxCtc,
        page ? page : pageNum
      ).then((res) => {
        setLoading(false);
        if (res?.status === 200) {
          if (page) {
            setList([...list, ...res?.data?.data]);
            // setTotalJobs(res?.data?.detail?.total);
          } else {
            setList(res?.data?.data);
          }

          setTotalJobs(res?.data?.pager?.total);
          // setCount(res?.data?.pager);
        }
      });
    } else {
      if (
        cat_id ||
        skillsFilterId ||
        companyFilterId ||
        industryFilterId ||
        locationFilterId ||
        minExp ||
        maxExp ||
        minCtc ||
        maxCtc
      ) {
        setLoading(true);
        let state = cat_id ? '' : 'networks';
        APISERVICES.getSearchedJob(
          state,
          skillsFilterId,
          companyFilterId,
          industryFilterId,
          locationFilterId,
          cat_id,
          minExp,
          maxExp,
          minCtc,
          maxCtc,
          page ? page : pageNum
        ).then((res) => {
          setLoading(false);

          if (res?.status === 200) {
            setList(res?.data?.detail?.results);
            setTotalJobs(res?.data?.pager?.total);
          }
        });
      } else {
        showNotification('danger', 'Kindly select at least one filter condition to proceed');
      }
    }
  };

  const advanceJobSearch = () => {
    if (skillsFilterId || companyFilterId || industryFilterId || locationFilterId) {
      setLoading(true);
      let body = {
        skills: skillsFilterId,
        companies: companyFilterId,
        industry: industryFilterId,
        // location: locationFilterId,
        jof: 'jobs',
        job_type: 'network',
        max_ctc: maxCtc,
        max_exp: maxExp,
        min_ctc: minCtc,
        min_exp: minExp,
        offset: 0,
        type: ''
      };
      APISERVICE.advanceSearchForJob(body).then((res) => {
        setLoading(false);
        if (res?.status === 200) {
          setList(res?.data?.detail);
        }
      });
    } else {
      showNotification('danger', 'Kindly select at least one filter condition to proceed');
    }
  };

  const getUserDetail = () => {
    APISERVICE.userBasicInfo().then((res) => {
      if (res?.status === 200) {
        setSavedResume(res?.data?.resume);
        setApplyForm({ ...applyForm, ['resume']: res?.data?.resume });
      }
    });
  };

  //on-Click Apply button to open_modal

  const onApplyStateHandler = (data) => {
    setJobId(data?.id);
    setJobTitle(data?.job_title);
    setApplyForm({
      to: data?.postedby,
      subject: `Alma - Job application for ${data?.job_title}`,
      message: '',
      resume: savedResume
    });
    handleShow2();
  };

  const onChangeHandler = (e, rid = false) => {
    if (rid) {
      setApplyForm({ ...applyForm, ['resume']: savedResume });
    } else {
      const { name, value, type } = e.target;
      if (type == 'file') {
        let file = e.target.files[0];
        let fileSize = file.size / 1024;

        if (fileSize > 500) {
          setResumeSelect(false);
          setFileSizeValid(true);
          alert('Resume file size should be less than 500KB');
        } else {
          setFileSizeValid(false);
          if (resumeExtension.includes(e.target.files[0].type)) {
            setResumeSelect(true);
            setApplyForm({ ...applyForm, [name]: file });
          } else {
            setResumeSelect(false);
            alert(
              'Resume file format is not valid, kindly attach a valid file e.g doc,docx,rtf,odt,pdf'
            );
          }
        }
      } else {
        setApplyForm({ ...applyForm, [name]: value });
      }
    }
  };

  const [recJobId, setRecJobId] = useState('');
  const [recommentDetail, setRecommentDetail] = useState({
    preSelectedOptions: '',
    referralMsg: '',
    resumeOpt: '',
    resume: ''
  });

  // handler for recomment image
  const recommendHandler = (e, rid = false) => {
    if (rid) {
      setRecommentDetail({ ...recommentDetail, ['resume']: savedResume });
    } else {
      const { name, value, type } = e.target;
      if (type == 'file') {
        let file = e.target.files[0];
        let fileSize = file.size / 1024;

        if (fileSize > 500) {
          setResumeSelect(false);
          setFileSizeValid(true);
          alert('Resume file size should be less than 500KB');
        } else {
          setFileSizeValid(false);
          if (resumeExtension.includes(e.target.files[0].type)) {
            setResumeSelect(true);
            setRecommentDetail({ ...recommentDetail, [name]: file });
          } else {
            setResumeSelect(false);
            alert(
              'Resume file format is not valid, kindly attach a valid file e.g doc,docx,rtf,odt,pdf'
            );
          }
        }
      } else {
        setRecommentDetail({ ...recommentDetail, [name]: value });
      }
    }
  };

  const submitHandler = () => {
    let formData = new FormData();
    for (let i in applyForm) {
      formData.append(`${i}`, applyForm[i]);
    }
    APISERVICES.applyForJob(jobId, formData).then((res) => {
      if (res?.status === 200) {
        handleClose2();
        showNotification('success', res?.data?.message);
      }
    });
  };

  const [shareModelShow, setShareModelShow] = useState(false);
  const [socialShareData, setSocialShareData] = useState(false);
  const [referalModelShow, setReferalModelShow] = useState(false);
  const [showReferralForm, setShowReferralForm] = useState(false);
  const [isClicked, setIsClicked] = useState(false);
  const shareModelClose = () => {
    setShareModelShow(false);
  };
  const referalModelClose = () => {
    setReferalModelShow(false);
  };
  const handleRequested = (data) => {
    setRecommentDetail({
      ...recommentDetail,
      ['preSelectedOptions']: data?.user_id
    });
    setShowReferralForm(true);
  };

  const fetchReferralDetail = (id) => {
    setRecJobId(id);
    setReferalModelShow(true);
    setShowReferralForm(false);
    APISERVICES.getReferralDetail(id).then((res) => {
      if (res?.status == 200) {
        if (res?.data?.detail) {
          setRefDetail(res?.data?.detail);
        }
      }
    });
  };
  const validate = (values) => {
    let errors = {};
    if (!values.referralMsg?.trim()) {
      errors.referralMsg = 'Message field is empty or invalid input has been entered.';
    }
    if (recommentDetail?.resumeOpt == 'attach') {
      if (!values.resume.name) {
        errors.resume = 'Please attach your latest resume.';
      }
    }

    return errors;
  };

  const sendRefferal = (e, id) => {
    e.preventDefault();
    setIsClicked(true);
    if (!Object.keys(validate(recommentDetail)).length) {
      let formData = new FormData();
      for (let i in recommentDetail) {
        formData.append(`${i}`, recommentDetail[i]);
      }
      //recJobId
      APISERVICES.sendReferral(id, formData).then((res) => {
        if (res?.status == 200) {
          showNotification('success', res?.data?.message);
          setIsClicked(false);
          setReferalModelShow(false);
        }
      });
    }
  };

  const onRecommendClick = (e, id) => {
    e.preventDefault();
    setJobId(id);
    handleShow3();
  };

  const loadTechOptions = async (inputValue) => {
    if (inputValue.length >= 2) {
      setloadInpMsg(true);

      return APISERVICE.getTechnicalSkillDrop(inputValue).then((res) => {
        setloadInpMsg(false);
        setInputMsg('searching...');
        if (res?.status === 200) {
          let data = res?.data?.data;
          let arr = [];
          data?.map((data) => {
            arr.push({ label: data.name, value: data.id });
          });
          if (data?.length == 0) {
            setInputMsg('no matches found');
          }
          return arr;
        }
      });
    }
  };

  const loggedinUserID = useSelector((state) => state?.user?.details?.encrypted_id);

  const handleLoadMore = (e) => {
    e.preventDefault();
    setPageNum(pageNum + 1);
    getSearchedFilterData(id, pageNum + 1);
  };

  const handleResetPageNo = () => {
    setPageNum(1);
  };

  return (
    <>
      <HeaderLogin />
      <div className="header-lnk-feeds">
        <div className="container">
          <TopTabbings state={'feed'} />
        </div>
      </div>
      <section className="feed-sec bg-grey py-4">
        <div className="container">
          <Row>
            {/* search-filter */}
            <Col xs={12} lg={3} md={12}>
              <JobFilterSalary
                setSkillsFilterId={setSkillsFilterId}
                setCompanyFilterId={setCompanyFilterId}
                setIndustryFilterId={setIndustryFilterId}
                setLocationFilterId={setLocationFilterId}
                apicall={advanceJobSearch}
                resetCall={getNetworkJob}
                setMinCtc={setMinCtc}
                setMaxCtc={setMaxCtc}
                setMinExp={setMinExp}
                setMaxExp={setMaxExp}
                minExp={minExp}
                maxExp={maxExp}
                minCtc={minCtc}
                maxCtc={maxCtc}
                job_type="network"
                getNetworkJob={getNetworkJob}
                techSkill={techSkill}
                setTechSkill={setTechSkill}
                techSearch={techSearch}
                setTechSearch={setTechSearch}
                loadTechOptions={loadTechOptions}
                loadJObLocationOption={loadJObLocationOption}
                jobLocation={jobLocation}
                setJobLocation={setJobLocation}
                locationSearch={locationSearch}
                setLocationSearch={setLocationSearch}
                inputMsg={inputMsg}
                setInputMsg={setInputMsg}
                loadInpMsg={loadInpMsg}
              />
            </Col>

            <Col xs={12} lg={6} md={12}>
              <div className="job-recomm-card bg-white">
                {/* type = "1" --> intern-ship 
                    type = "2" --> fresherjob
                    type = "3" --> jobnetwork
                    type ="4" --> search  */}
                {id ? (
                  <h4>
                    {state == '1' || state == '2' || state == '4'
                      ? 'Filtered Jobs Results'
                      : 'Filtered Jobs in your Network'}
                  </h4>
                ) : (
                  <h4 style={{ fontSize: '17px' }}>
                    {state == '3' ? 'Filtered Jobs Results' : 'Search Jobs in your Network'}
                  </h4>
                )}
                <h6 className="intern-count-hd pt-3 px-3">
                  Latest <b>{list?.length} jobs </b> for you.
                </h6>
                <div className="job-recommd-lst p-3">
                  {/* loop for card repeat */}

                  {loading ? (
                    <>
                      <NetworkSimmer />
                      <NetworkSimmer />
                      <NetworkSimmer />
                      <NetworkSimmer />
                    </>
                  ) : (
                    <>
                      {list && list?.length ? (
                        list?.map((data, index) => {
                          return (
                            <div key={index} className="job-recomm-card-inr network-job-card">
                              <div className="top-time-line mb-3 d-flex align-items-center justify-content-between">
                                {id ? (
                                  <p title={moment(data?.created_on).format('YYYY-MM-DD HH:MM:SS')}>
                                    <i className="far fa-clock me-1"></i> Added on:&nbsp;
                                    {data?.date_time}
                                  </p>
                                ) : (
                                  <p title={moment(data?.date_time).format('YYYY-MM-DD HH:MM:SS')}>
                                    <i className="far fa-clock me-1"></i> Added on:&nbsp;
                                    {moment(data?.date_time).fromNow()}
                                  </p>
                                )}
                                {state != 2 && (
                                  <div className="network_job_posted_by">
                                    Posted By:
                                    <Link
                                      to={`/user/timeline/${
                                        id ? data?.user?.encrypt_userid : data?.encrypt_userid
                                      }`}>
                                      <span>{id ? data?.user?.first_name : data?.postedby}</span>
                                    </Link>
                                  </div>
                                )}
                              </div>
                              <div className="job-recomm-dis">
                                <h4>
                                  <Link to={`/jobs/jobs-details/${data?.id}`} target="_blank">
                                    {data?.job_title}
                                  </Link>
                                </h4>
                                <h6
                                  style={{
                                    margin: ' 0 0 5px',
                                    fontWeight: 700,
                                    color: ' #4d4d4d'
                                  }}>
                                  {data?.company}
                                </h6>
                                {id ? (
                                  <>
                                    {' '}
                                    <p>
                                      <strong>Keyskills:</strong>{' '}
                                      {/* {truncate(data?.job_keyword_meta?.split('|')?.join(), 50)} */}
                                      {data?.key_skills
                                        ? data?.key_skills?.map((item) => {
                                            return <span>{item?.name}, </span>;
                                          })
                                        : []?.map((item) => {
                                            return <span>{item?.name}, </span>;
                                          })}
                                    </p>
                                  </>
                                ) : (
                                  <>
                                    {' '}
                                    <p>
                                      <strong>Keyskills:</strong>{' '}
                                      {/* {truncate(data?.job_keyword_meta?.split('|')?.join(), 50)} */}
                                      {data?.key_skill
                                        ? data?.key_skill?.map((item) => {
                                            return <span>{item?.name}, </span>;
                                          })
                                        : []?.map((item) => {
                                            return <span>{item?.name}, </span>;
                                          })}
                                    </p>
                                  </>
                                )}

                                <ul className="job-recomm-addrs">
                                  {data?.state ? (
                                    <li>
                                      <i title="Location" className="fas fa-map-marker-alt"></i>
                                      {data?.state}
                                    </li>
                                  ) : (
                                    ''
                                  )}

                                  <li>
                                    <i title="Positions open" className="fas fa-users"></i>Positions
                                    Open: {data?.open_positions}
                                  </li>
                                  {data?.max_exp > 0 && data?.max_exp ? (
                                    <li>
                                      Experience :{data.min_exp + '-' + data.max_exp + `(yrs)`}
                                    </li>
                                  ) : (
                                    ''
                                  )}
                                  {data?.max_ctc != '0' ? (
                                    <li>
                                      <i title="CTC" className="fas fa-rupee-sign"></i>CTC :
                                      {data?.max_ctc
                                        ? data?.min_ctc + '-' + data?.max_ctc + `(Lacs)`
                                        : ''}
                                    </li>
                                  ) : (
                                    ''
                                  )}
                                </ul>
                                <div>
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: truncate(data?.job_info, 200)
                                    }}
                                  />
                                </div>
                                <Link
                                  className="view-detail-btn"
                                  to={`/jobs/jobs-details/${data?.id}`}
                                  state={{ data: data }}
                                  target="_blank">
                                  View in Detail
                                </Link>
                              </div>
                              <div className="job-recommd-ftr d-flex align-items-center justify-content-between">
                                <div>
                                  <ul>
                                    {data?.encrypt_userid == loggedinUserID ? (
                                      ''
                                    ) : (
                                      <li>
                                        {!data?.is_applied ? (
                                          <Link
                                            className="apply-btn"
                                            onClick={() => {
                                              onApplyStateHandler(data);
                                            }}>
                                            <i className="fas fa-check me-1"></i>Apply
                                          </Link>
                                        ) : (
                                          <Link className=" applied-btn">
                                            <i
                                              to={`/jobs/search/category/type${data?.id}`}
                                              className="fas fa-check me-1"></i>
                                            Already Applied
                                          </Link>
                                        )}

                                        {/* pop-up */}
                                      </li>
                                    )}
                                    <li>
                                      <Link
                                        to="#"
                                        onClick={(e) => {
                                          onRecommendClick(e, data?.id);
                                        }}>
                                        <i className="fas fa-thumbs-up me-1"></i>Recommend
                                      </Link>
                                    </li>
                                    <li>
                                      <Link
                                        onClick={() => {
                                          setShareModelShow(true);
                                          setSocialShareData(data);
                                        }}>
                                        <i className="fas fa-share me-1"></i> Share
                                      </Link>
                                    </li>
                                  </ul>
                                </div>

                                {data.job_referred == '1' && data?.self_added !== true ? (
                                  <Link
                                    className="referral-btn"
                                    to={`javascript:void(0)`}
                                    onClick={() => {
                                      fetchReferralDetail(data?.id);
                                    }}>
                                    <span>Request for referral</span>
                                    {['top'].map((placement) => (
                                      <OverlayTrigger
                                        key={placement}
                                        placement={placement}
                                        overlay={
                                          <Tooltip id={`tooltip-${placement}`}>
                                            Direct referral increases the chances of getting a job.
                                          </Tooltip>
                                        }>
                                        <i className="fas fa-question-circle ms-2"></i>
                                      </OverlayTrigger>
                                    ))}
                                  </Link>
                                ) : (
                                  ''
                                )}
                              </div>
                            </div>
                          );
                        })
                      ) : (
                        <div className="upcoming_events p-3">
                          <div className="text-center">
                            <img
                              src={require('../../../assets/images/small-error.png')}
                              alt="img"
                            />
                          </div>
                          <p className="text-center">Data not found.</p>
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>
              {totalJobs !== undefined && list?.length !== totalJobs && (
                <div className="load-more-btn text-center">
                  <Button variant="secondary" className="read-more" onClick={handleLoadMore}>
                    Load More
                  </Button>
                </div>
              )}
            </Col>
            <Col xs={12} lg={3} md={12}>
              <JobCategoryList
                callApi={getSearchedFilterData}
                handleResetPageNo={handleResetPageNo}
                state="3"
              />
            </Col>
          </Row>
        </div>
      </section>
      <Modal show={shareModelShow} onHide={shareModelClose}>
        <Modal.Header closeButton>
          <Modal.Title>share to</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <SocialShare
            facebookOptions={{
              url: process.env.REACT_APP_DOMAIN + 'user/jobs-details' + '/' + socialShareData.id,
              quote: socialShareData?.job_title
            }}
            twitterOptions={{
              url: process.env.REACT_APP_DOMAIN + 'user/jobs-details' + '/' + socialShareData.id,
              title: socialShareData?.job_title,
              hashtags: ['jobs', 'trends']
            }}
            linkedInOptions={{
              title: socialShareData?.job_title,
              summary: socialShareData?.job_info,
              url: process.env.REACT_APP_DOMAIN + 'user/jobs-details' + '/' + socialShareData.id,
              source: process.env.REACT_APP_DOMAIN + 'user/jobs-details' + '/' + socialShareData.id
            }}
          />
        </Modal.Body>
      </Modal>

      {/* recommended job modal */}
      <RecomendModal handleClose3={handleClose3} show3={show3} jobId={jobId} />
      {/* end of modal */}
      <FooterMain className={'colour-bg'} />
      {/* modal form */}
      <ApplyForm
        onChangeHandler={onChangeHandler}
        setIsChecked={setIsChecked}
        isChecked={isChecked}
        submitHandler={submitHandler}
        applyForm={applyForm}
        show2={show2}
        handleClose2={handleClose2}
        jobTitle={jobTitle}
        resumeSelect={resumeSelect}
        fileSizeValid={fileSizeValid}
        getNetworkJob={getNetworkJob}
        getUserDetail={getUserDetail}
      />
      <Modal show={referalModelShow} onHide={referalModelClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Request for referral</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="request-referal">
            {refDetail && refDetail?.length && refDetail[0]?.professionFriends?.length ? (
              refDetail?.map((data, index) => (
                <div className="request-ref d-flex align-items-top" key={index}>
                  <Link className="me-3" to={`javascript:void(0)`}>
                    <img
                      src={require('../../../assets/images/default-male-avatar.png')}
                      alt="img"
                    />
                  </Link>
                  <div className="ref-dis">
                    <div className="d-flex align-items-center justify-content-between">
                      <h4> {data?.professionFriends[0]?.name}</h4>
                      <div className="ref-rt-link d-flex align-items-center">
                        <i className="fas fa-users ms-1"></i>
                        <i className="fas fa-info ms-3"></i>
                      </div>
                    </div>
                    <p>
                      {data?.professionFriends[0]?.designation} (
                      {data?.professionFriends[0]?.year_from +
                        '-' +
                        data?.professionFriends[0]?.year_to}
                      )
                    </p>
                    <p>
                      <b>Location: </b>
                      {data?.professionFriends[0]?.location}
                    </p>
                    {showReferralForm ? (
                      <>
                        {' '}
                        <div className="ref-msg">
                          <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Control
                              as="textarea"
                              name="referralMsg"
                              type="text"
                              placeholder="Message"
                              onChange={(e) => {
                                recommendHandler(e, false);
                              }}
                            />
                            <div
                              className={`${
                                isClicked && validate(recommentDetail)?.referralMsg ? '' : 'd-none'
                              } danger-frm `}>
                              {isClicked && validate(recommentDetail)?.referralMsg}
                            </div>
                          </Form.Group>
                        </div>
                        <div>
                          {['radio'].map((type) => (
                            <div key={`default-${type}`} className="mb-3">
                              <div className="max-card">
                                <Form.Check
                                  type={type}
                                  id={`default-${type}`}
                                  label="Attach latest resume"
                                  name="group1"
                                  onChange={(e) => {
                                    setIsChecked(true);
                                    setRecommentDetail({
                                      ...recommentDetail,
                                      ['resumeOpt']: 'attach'
                                    });
                                  }}
                                />
                                {isChecked ? (
                                  <div className="upload-resume-frm mt-2">
                                    <Form.Group controlId="formFile" className="mb-3">
                                      <Form.Control
                                        type="file"
                                        accept="application/vnd.oasis.opendocument.text,application/*"
                                        name="resume"
                                        onChange={(e) => {
                                          recommendHandler(e, false);
                                        }}
                                      />
                                    </Form.Group>
                                  </div>
                                ) : (
                                  ''
                                )}
                                <p className="ms-4">Max upload size is 500kb</p>
                                <div
                                  className={`${
                                    isClicked && validate(recommentDetail)?.resume ? '' : 'd-none'
                                  } danger-frm `}>
                                  {isClicked && validate(recommentDetail)?.resume}
                                </div>
                              </div>

                              <div className="resume-card-link">
                                <Form.Check
                                  checked
                                  type={type}
                                  label="Use"
                                  id={`default-${type}`}
                                  name="group1"
                                  onChange={(e) => {
                                    setRecommentDetail({ ...recommentDetail, ['resumeOpt']: '' });
                                    setIsChecked(false);
                                    recommendHandler(e, true);
                                  }}
                                />
                                <a
                                  className="ms-4"
                                  href={`${process.env.REACT_APP_API_DOMAIN}jobs/resumeDownload?resume_name=${data?.jobResume?.name}&user_id=${data?.jobResume?.ID}`}
                                  rel="noreferrer">
                                  {data?.jobResume?.name}
                                </a>
                              </div>
                            </div>
                          ))}
                        </div>
                      </>
                    ) : (
                      <>
                        <button
                          className="main-btn mb-3"
                          type="button"
                          onClick={() => {
                            handleRequested(data?.professionFriends[0]);
                          }}>
                          REQUEST REFERRAL
                        </button>
                      </>
                    )}
                  </div>
                </div>
              ))
            ) : (
              <>At the moment, we can't find any referer for this job. Please check back later.</>
            )}

            {refDetail[0]?.professionFriends?.length ? (
              <button
                className="ref-blk-btn"
                onClick={(e) => {
                  sendRefferal(e, refDetail[0]?.jobInfo?.id);
                }}>
                <i className="fas fa-check me-1"></i> Ask for Referral
              </button>
            ) : (
              <></>
            )}
          </div>
        </Modal.Body>
      </Modal>
      {/* end */}
      {/* loader */}
      {/* {loading ? <Loader /> : ''} */}
    </>
  );
}

export default JobinNetwork;
