import CheckboxTree from 'react-checkbox-tree';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';

// const nodes = [
//   {
//     value: 'mars',
//     label: 'Mars',
//     children: [
//       { value: 'phobos', label: 'Phobos', children: [] },
//       { value: 'deimos', label: 'Deimos', children: [] }
//     ]
//   },
//   {
//     value: 'earth',
//     label: 'Earth',
//     children: [
//       {
//         value: 'USA',
//         label: 'USA',
//         children: [
//           { value: 'newyork', label: 'New York' },
//           { value: 'sanfran', label: 'San Francisco' }
//         ]
//       },
//       { value: 'China', label: 'China' }
//     ]
//   }
// ];

const RolesViewTree = ({ allModules, tree, setTree }) => {
  return (
    <div>
      <CheckboxTree
        nodes={allModules}
        checked={tree.checked}
        expanded={tree.expanded}
        onCheck={(checked) => {
          setTree({ ...tree, checked: checked });
        }}
        onExpand={(expanded, targetNode) => {
          setTree({
            ...tree,
            expanded: expanded,
            tragetNodeValue: [targetNode.value],
            targetNodeChecked: targetNode.checked,
            targetNodeExpanded: targetNode.expanded,
            targetNodeIsChild: targetNode.isChild
          });
        }}
        showNodeIcon={false}
        iconsClass="fa5"
        checkModel={'all'}
        nativeCheckboxes={true}
      />
    </div>
  );
};

export default RolesViewTree;
