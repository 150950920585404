/** 
@copyright : ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
@author     : Shiv Charan Panjeta 
 
All Rights Reserved.
Proprietary and confidential :  All information contained herein is, and remains
the property of ToXSL Technologies Pvt. Ltd. and its partners.
Unauthorized copying of this file, via any medium is strictly prohibited.
*/
import { newAbortSignal } from '../globals/helper';
import * as req from './http';

/**USING WITH MULTIPART-FORMDATA */
export const PostReq = async (path, body) => {
  /**request for post method */
  return await req.http_multipart
    .post(path, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.error(err);
    });
};

export const getReq = async (path, body = '') => {
  /**request for get method */

  if (body == '') {
    return await req.http_multipart
      .get(path)
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.error(err);
      });
  } else {
    return await req.http_multipart
      .get(path, { params: body })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.error(err);
      });
  }
};

const timer = (path) => {
  if (path?.includes('country')) {
    return 20000;
  } else if (path?.includes('state')) {
    return 20000;
  } else {
    return 20000;
  }
};

export const mapGetReq = async (path, body = '') => {
  /**request for map get method */

  if (body == '') {
    return await req.http_multipart
      .get(path, {
        signal: newAbortSignal(timer(path))
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.error(err);
      });
  } else {
    return await req.http_multipart
      .get(path, { params: body, signal: newAbortSignal(timer(path)) })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.error(err);
      });
  }
};

export const putReq = async (path, body) => {
  /**request for put method */
  return await req.http_multipart
    .put(path, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.error(err);
    });
};

export const delReq = async (path, body) => {
  /**request for delete method */
  return await req.http_multipart
    .delete(path, body)
    .then((resp) => {
      // showNotification("danger", resp?.data?.message);
      return resp;
    })
    .catch((err) => {
      console.error(err);
    });
};

export const PostReqFile = async (path, body, setProgress) => {
  /**request for post method */
  return await req.http_multipart
    .post(path, body, {
      onUploadProgress: (progressEvent) => {
        let percentComplete = progressEvent.loaded / progressEvent.total;
        percentComplete = parseInt(percentComplete * 100);
        setProgress(percentComplete);
      }
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.error(err);
    });
};
