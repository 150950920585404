import { useEffect, useState } from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Dropdown from 'react-bootstrap/Dropdown';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import { Link, useNavigate } from 'react-router-dom';
import { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { CLOSED, OPEN, SECRET } from '../../../globals/constants';
import { truncate } from '../../../globals/helper';
import useDocumentTitle from '../../../globals/useDocumentTitle';
import * as APISERVICES from '../../../services/MentorArticlesServices';
import showNotification from '../../../services/NotificationService';
import {
  getGroupSliderData,
  getSocialGroupDiscover,
  socialClubJoin,
  socialClubLeave,
  socialGroupJoin,
  userClubSuggestions
} from '../../../services/SocialServices';
import {
  addFriend,
  createClub,
  followFriend,
  getManageClubs,
  getUserClubs,
  leaveGroupFromWidget
} from '../../../services/UserServices';
import { MiniFooter } from '../../MiniFooter';
import HeaderLogin from '../../commoncomponents/HeaderLogin';
import MultiAsynSelect from '../../commoncomponents/MultiSelectWithAsyn/MultiAsynSelect';
import ArticalBlogs from '../../commoncomponents/newsfeeds/ArticalBlogs';
import CampusNews from '../../commoncomponents/newsfeeds/CampusNews';
import CampusnewsText from '../../commoncomponents/newsfeeds/CampusnewsText';
import FollowCompany from '../../commoncomponents/newsfeeds/FollowCompany';
import PeopleKnow from '../../commoncomponents/newsfeeds/PeopleKnow';
import Upcoming from '../../commoncomponents/newsfeeds/Upcoming';
import WhatsNew from '../../commoncomponents/newsfeeds/WhatsNew';
import TopTabbings from './TopTabbings';
import { CompanySimmer } from '../../commoncomponents/skelton/allskelton';
function ClubPage() {
  useDocumentTitle('List of Clubs | AlmaBay');
  const navigate = useNavigate();
  const [userSuggestions, setUserSuggestions] = useState([]);
  const [groupDiscover, setGroupDiscover] = useState([]);
  const [isActive, setIsActive] = useState(false);
  const handleClick = (event) => {
    setIsActive((current) => !current);
    setIsActive1(false);
  };

  const [isActive1, setIsActive1] = useState(false);
  const handleClick2 = (event) => {
    setIsActive1((current) => !current);
    setIsActive(false);
  };

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // state for form handling
  const [groupName, setGroupName] = useState('');
  const [peopleName, setPeopleName] = useState([]);
  const [manageGroup, setManageGroup] = useState([]);
  const [joinGroup, setJoinGroup] = useState([]);
  const [totalGroups, setTotalGroups] = useState(null);
  const [joinedGroups, setJoinedGroups] = useState(null);
  const [topSilder, setTopSilder] = useState([]);
  const [privacy, setPrivacy] = useState(CLOSED);
  const [clicked, setClicked] = useState(false);
  const [loading, setLoading] = useState(false);
  const [discover, setDiscover] = useState([]);
  const [lastElement, setLastElement] = useState(null);
  const [shouldLoadMoreButtonVisible, setShouldLoadMoreButtonVisible] = useState(true);
  const [shouldLoadMoreButtonVisibleJoined, setShouldLoadMoreButtonVisibleJoined] = useState(true);
  const [showData, setShowData] = useState(false);
  const [isScrollable, setIsScrollable] = useState(false);
  const [offset, setOffSet] = useState(6);
  const [chapterInstitute, setChapterInstitute] = useState([]);
  let friendIds = peopleName?.map((data) => data?.id);
  const [isLoading, setIsLoading] = useState(false);
  const [clickedId, setClickedId] = useState('');
  const [buttonLoader, setButtonLoader] = useState(false);
  const [isntibuttonLoader, setInstiButtonLoader] = useState(false);
  const [inputMsg, setInputMsg] = useState('Please enter atleasst 2 characters');
  const [loadInput, setLoadInput] = useState(false);
  const [disable, setDisable] = useState(false);
  const [instiData, setInstiData] = useState('');

  useEffect(() => {
    getChapterDiscover();
    getTopSilder();
    getManageGroupList();
    getUserGroupList();

    return () => {
      getGroupDiscoverList();
    };
  }, []);

  const loadFriendOptions = async (inputValue) => {
    if (inputValue.length >= 2) {
      setLoadInput(true);
      return APISERVICES.getAllFriends(inputValue).then((res) => {
        setLoadInput(false);

        if (res?.status === 200) {
          let tempArr = [];
          if (res?.data?.data === 'data not found') {
            setInputMsg('no match found');
          }

          if (res?.data?.data) {
            const data = res?.data?.data;
            data?.map((data) => {
              tempArr.push({
                id: data?.id,
                label: data?.name,
                value: data?.name
              });
            });
          }

          return tempArr;
        }
      });
    }
  };

  const handleSelectPrivacy = (e) => {
    setPrivacy(e);
    let returndiv = document.getElementById(e);
    document.getElementById('replace').innerHTML = returndiv.innerHTML;
  };

  const validate = () => {
    let errors = {};
    if (groupName === '') {
      errors.groupName = 'Club name is Required.';
    }
    return errors;
  };

  const addGroup = (e) => {
    e.preventDefault();
    setClicked(true);
    setLoading(true);
    if (!Object.keys(validate()).length) {
      let formData = new FormData();
      formData.append(`club_name`, groupName);
      formData.append(`club_privacy`, privacy == 0 ? 'open' : privacy == 1 ? 'closed' : 'secret');
      formData.append(`friendid`, friendIds.toString());
      createClub(formData).then((res) => {
        if (res?.status === 200) {
          getManageGroupList();
          handleClose();
          setLoading(false);
          showNotification('success', res?.data?.message);
          navigate(`/user/club-detail/${res?.data?.club_data?.encrypted_id}`);
        } else {
          setLoading(false);
          showNotification('success', res?.data?.message);
        }
      });
    } else {
      setLoading(false);
      return;
    }
  };

  //manange Groups
  const getManageGroupList = (search, offSet, isTrue) => {
    setLoading(true);
    getManageClubs(search, offSet ? offSet : '')
      .then((res) => {
        setLoading(false);
        if (res?.status === 200) {
          if (isTrue == true) {
            setManageGroup([...manageGroup, ...res.data.data] ?? []);
          } else {
            setManageGroup(res?.data?.data ?? []);
          }
          if (res?.data?.data?.length < 6) {
            setShouldLoadMoreButtonVisible(false);
          }
          setJoinedGroups(res?.data?.total_groups);
        } else {
          setShouldLoadMoreButtonVisible(false);
          setManageGroup([]);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const getGroupDiscoverList = () => {
    setLoading(true);
    getSocialGroupDiscover()
      .then((res) => {
        setLoading(false);
        if (res?.status === 200) {
          setDiscover(res?.data?.data || []);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };
  //Group join by user
  const getUserGroupList = (search, offset, isTrue) => {
    getUserClubs(search, offset ? offset : '').then((res) => {
      if (res?.status === 200) {
        if (isTrue) {
          setJoinGroup([...joinGroup, ...res?.data?.data] ?? []);
        } else {
          setJoinGroup(res.data.data ?? []);
        }

        if (res.data.data?.length !== 6) {
          setShouldLoadMoreButtonVisibleJoined(false);
        }

        setTotalGroups(res.data.total_groups ?? null);
      }
    });
  };

  const handleAddNavigate = (e, id, value) => {
    e.preventDefault();
    navigate(`/user/club-detail/${id}`, { state: value });
  };

  const leaveGroup = (e, id) => {
    e.preventDefault();
    let body = {
      club_id: id
    };
    let formData = new FormData();
    for (let i in body) {
      formData.append(`${i}`, body[i]);
    }
    if (confirm('Do you want to leave from club ?') == true) {
      socialClubLeave(formData).then((res) => {
        if (res?.status === 200) {
          showNotification('success', 'Club left successfully');
          getManageGroupList();
          getUserGroupList();
        } else {
          showNotification('success', res?.data?.message);
        }
      });
    }
  };

  const handleLoadMore = () => {
    setIsScrollable(true);
    setOffSet(manageGroup?.length + 6);
    getManageGroupList('', manageGroup?.length, true);
  };

  const handleSearch = (search) => {
    if (search !== '') {
      setShouldLoadMoreButtonVisible(false);
    } else {
      setShouldLoadMoreButtonVisible(true);
    }
    getManageGroupList(search, '', false);
  };

  const handleJoinGroup = (e, id) => {
    setButtonLoader(true);
    setClickedId(id);
    e.preventDefault();
    let body = {
      following_id: id
    };
    let formData = new FormData();
    for (let i in body) {
      formData.append(`${i}`, body[i]);
    }
    socialClubJoin(formData).then((res) => {
      if (res?.status === 200) {
        showNotification('success', res?.data?.message);
        userClubSuggestions()?.then((response) => {
          try {
            if (response?.status === 200) {
              setLoading(false);
              setUserSuggestions(response?.data?.data ?? []);
              setButtonLoader(false);
            }
          } catch (error) {
            setLoading(false);
            console.error(error);
          }
        });
        getUserGroupList();
      } else {
        showNotification('success', res?.data?.message);
      }
    });
  };

  const handleJoinInstitueGroup = (e, id) => {
    e.preventDefault();
    setClickedId(id);
    setInstiButtonLoader(true);
    let body = {
      following_id: id
    };
    let formData = new FormData();
    for (let i in body) {
      formData.append(`${i}`, body[i]);
    }
    socialGroupJoin(formData).then((res) => {
      if (res?.status === 200) {
        getChapterDiscover();
      } else {
        showNotification('success', res?.data?.message);
      }
    });
  };

  // coppied from chapterCover start

  const getChapterDiscover = () => {
    setLoading(true);
    APISERVICES.groupDiscoverlist()
      .then((res) => {
        setLoading(false);
        if (res?.status === 200) {
          setGroupDiscover(res?.data?.data || []);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const handleJoin = (discoverSlider) => {
    setIsLoading(true);

    setInstiData(discoverSlider);
    APISERVICES.similierClubInstitute(discoverSlider?.institute_id)
      .then((res) => {
        setLoading(false);
        if (res?.status === 200) {
          setChapterInstitute(res?.data?.data ?? []);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  const getTopSilder = () => {
    getGroupSliderData()
      .then((res) => {
        if (res?.status === 200) {
          setTopSilder(res?.data?.data);
          handleJoin(res?.data?.data[0]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const leaveGroupsJoined = (e, id) => {
    e.preventDefault();
    let body = {
      following_id: id
    };
    let formData = new FormData();
    for (let i in body) {
      formData.append(`${i}`, body[i]);
    }
    if (confirm('Do you want to leave from club?') == true) {
      leaveGroupFromWidget(formData).then((res) => {
        if (res?.status === 200) {
          showNotification('success', 'Club left successfully');
          getUserGroupList();
        } else {
          showNotification('success', res?.data?.message);
        }
      });
    }
  };
  // coppied from chapterCover end

  const renderPopover = (props) => {
    let popOverData = props?.popper?.state?.options?.data;

    return (
      <Popover className="group-loader-card" id={`popover-basic`} {...props}>
        <Popover.Body>
          <div className="loader-tooltip">
            <div className="loader-cover">
              <img
                src={popOverData?.cover_path ? popOverData?.cover_path : popOverData?.avatar_path}
                alt="img"
                className={
                  popOverData?.avatar_path?.includes('default-page-avatar.png') &&
                    !popOverData?.cover_path
                    ? 'loader-image containloader'
                    : 'loader-image'
                }
              />
              <div className="loader-image-text">
                {' '}
                <h3>{popOverData?.name}</h3>
              </div>
            </div>
            <div className="p-3">
              <p>
                <span style={{ textTransform: 'capitalize' }}>
                  {popOverData?.group_privacy} Club
                </span>
              </p>
              <p>{popOverData?.category_name}</p>
              {popOverData?.count !== 0 &&
                (popOverData?.count == 1 ? (
                  <>
                    {' '}
                    <p>{popOverData?.count} member</p>
                  </>
                ) : (
                  <p>{popOverData?.count} members</p>
                ))}
              <button
                className="join-btn mt-2"
                onClick={(e) => handleJoinGroup(e, popOverData?.id)}>
                Joined
              </button>
            </div>
          </div>
        </Popover.Body>
      </Popover>
    );
  };

  const renderDiscoverPopover = (props) => {
    let popOverData = props?.popper?.state?.options?.data;

    return (
      <Popover className="group-loader-card" id={`popover-basic`} {...props}>
        <Popover.Body>
          <div className="loader-tooltip">
            <div className="loader-cover">
              <img src={popOverData?.avatar_path} alt="img" className="loader-image" />
              <div className="loader-image-text">
                {' '}
                <h3>{popOverData?.name}</h3>
              </div>
            </div>
            <div className="p-3">
              <p>
                <span style={{ textTransform: 'capitalize' }}>
                  {popOverData?.group_privacy} Club
                </span>
              </p>
              <p>{popOverData?.category_name}</p>
              {popOverData?.count !== 0 &&
                (popOverData?.count == 1 ? (
                  <>
                    {' '}
                    <p>{popOverData?.count} member</p>
                  </>
                ) : (
                  <p>{popOverData?.count} members</p>
                ))}
              <button
                className="join-btn mt-2"
                onClick={(e) => handleJoinGroup(e, popOverData?.id)}>
                Join
              </button>
            </div>
          </div>
        </Popover.Body>
      </Popover>
    );
  };

  const renderUserPopover = (props) => {
    if (props?.show) {
      setDisable(true);
    } else {
      setDisable(false);
    }
    let popOverData = props?.popper?.state?.options?.data;

    return (
      <Popover className="group-loader-card" id={`popover-basic`} {...props}>
        <Popover.Body>
          <div className="loader-tooltip">
            <div className="loader-cover">
              <img
                src={
                  popOverData?.cover_url
                    ? popOverData?.cover_url
                    : require('../../../assets/images/default-cover.png')
                }
                alt="img"
                className="loader-image"
              />
            </div>
            <div className="people-know-loader-dis d-flex align-items-top">
              <div className="people-knw-top d-flex align-items-center">
                <div className="people-icn me-3">
                  <img
                    src={
                      popOverData?.avatar_url
                        ? popOverData?.avatar_url
                        : require('../../../assets/images/default-male-avatar.png')
                    }
                    alt="img"
                    className="loader-image"
                  />
                </div>
                <div className="people-hd">
                  <h3>{popOverData?.name}</h3>
                </div>
              </div>
            </div>
            <div className="people-knw-dis-popover pt-3">
              {popOverData?.designation && (
                <p>
                  <i className="fas fa-briefcase me-1"></i>Worked as
                  <Link to="#">{popOverData?.designation}</Link> {' at '}
                  <Link to="#">{popOverData?.organisation}</Link>
                </p>
              )}

              <div className="popover-inr-btn">
                <button
                  className="popovr-blk-btn me-1"
                  onClick={(e) => {
                    let body = {
                      following_id: popOverData?.id
                    };
                    if (confirm('Do you want to remove friend ?') === true) {
                      addFriend(body).then((res) => {
                        setLoading(false);
                        if (res?.status == 200) {
                          // fetchDescoverPages();
                        } else {
                          showNotification('success', res?.data?.message);
                        }
                      });
                    }
                  }}>
                  Unfriend
                </button>
                {popOverData?.is_mentor ? (
                  <button
                    className="popovr-yellow-btn"
                    onClick={(e) => resquestHandler(e, popOverData?.id, 'unfolllow')}>
                    Unfollow Mentor
                  </button>
                ) : (
                  <button
                    className="popovr-yellow-btn"
                    onClick={(e) => resquestHandler(e, popOverData?.id, 'folllow')}>
                    Follow Mentor
                  </button>
                )}
              </div>
            </div>
          </div>
        </Popover.Body>
      </Popover>
    );
  };
  const resquestHandler = (e, id, type) => {
    e.preventDefault();
    setLoading(true);
    if (type == 'unfolllow') {
      let body = {
        following_id: id
      };
      followFriend(body).then((res) => {
        setLoading(false);
        if (res?.status == 200) {
          showNotification('success', 'Mentor unfollowed succesfully');
          userClubSuggestions()?.then((response) => {
            try {
              if (response?.status === 200) {
                setLoading(false);
                setUserSuggestions(response?.data?.data ?? []);
                setButtonLoader(false);
              }
            } catch (error) {
              setLoading(false);
              console.error(error);
            }
          });
        }
      });
    } else {
      let body = {
        following_id: id
      };
      followFriend(body).then((res) => {
        setLoading(false);
        if (res?.status == 200) {
          showNotification('success', 'Mentor followed succesfully');
          userClubSuggestions()?.then((response) => {
            try {
              if (response?.status === 200) {
                setLoading(false);
                setUserSuggestions(response?.data?.data ?? []);
                setButtonLoader(false);
              }
            } catch (error) {
              setLoading(false);
              console.error(error);
            }
          });
        }
      });
    }
  };
  const handleJoinGroupSearch = (search) => {
    getUserGroupList(search);
  };

  return (
    <>
      <HeaderLogin className={'colour-bg'} state={'d-none'} />
      <div className="header-lnk-feeds">
        <div className="container">
          <TopTabbings state={'social'} />
        </div>
      </div>
      <section className="feed-sec bg-grey">
        <Tab.Container id="left-tabs-example" defaultActiveKey="first">
          <div className="company-tab-top bg-white mb-3">
            <div className="container">
              <Row className="align-items-center">
                <Col sm={6} md={6} xs={7}>
                  <div className="d-flex align-items-center">
                    <Nav variant="pills">
                      <Nav.Item>
                        <Nav.Link
                          onClick={() => {
                            getUserGroupList();
                            getManageGroupList();
                          }}
                          eventKey="first">
                          Clubs
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link
                          onClick={async () => {
                            setLoading(true);
                            try {
                              const response = await userClubSuggestions();
                              if (response?.status === 200) {
                                setLoading(false);
                                setUserSuggestions(response?.data?.data ?? []);
                              }
                            } catch (error) {
                              setLoading(false);
                              console.error(error);
                            }
                          }}
                          eventKey="second">
                          Discover
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </div>
                </Col>
                <Col sm={6} md={6} xs={5}>
                  <div className="text-end">
                    <Button className="create-group-btn" onClick={handleShow}>
                      + Create Club
                    </Button>
                  </div>
                </Col>
              </Row>
            </div>
          </div>

          <div className="container">
            <Row>
              <Col xs={12} lg={8} xl={8} className="mb-4">
                <Tab.Content>
                  <Tab.Pane eventKey="first">
                    <div className="company-lft-dis mb-3">
                      <div className="company-lst bg-white p-4">
                        <div className="company-hd flex-wrap d-flex align-items-center justify-content-between mb-4">
                          <h4>CLUBS YOU MANAGE</h4>
                          <div
                            className={isActive1 ? 'comp-hd-search blk-search' : 'comp-hd-search'}>
                            <Link onClick={handleClick2} to="#">
                              <i className="fas fa-search"></i>
                            </Link>
                            <Form.Group className="search-card" controlId="formBasicEmail">
                              <Form.Control
                                type="search"
                                placeholder="Search Clubs"
                                onChange={(e) => handleSearch(e.target.value)}
                              />
                            </Form.Group>
                          </div>
                        </div>
                        {loading ? (
                          <>
                            <div className="company-follow">
                              {Array.from(Array(6), () => {
                                return <CompanySimmer />;
                              })}
                            </div>
                          </>
                        ) : (
                          <>
                            {' '}
                            <div
                              className={
                                isScrollable
                                  ? 'company-follow compnay-card-scroll'
                                  : 'company-follow'
                              }>
                              {manageGroup && manageGroup.length ? (
                                manageGroup?.map((data, index) => {
                                  return (
                                    <>
                                      <div
                                        className="company-follow-card d-flex justify-content-between"
                                        key={index}
                                        ref={setLastElement}>
                                        <div className="d-flex align-items-top">
                                          <div className="company-icn me-3">
                                            <Link
                                              target="_blank"
                                              to={`/user/club-detail/${data?.encrypted_id}`}>
                                              <OverlayTrigger
                                                trigger={['hover', 'focus']}
                                                placement="top"
                                                delay={{ show: 250, hide: 400 }}
                                                data={data}
                                                popperConfig={{
                                                  data
                                                }}
                                                shouldUpdatePosition={true}
                                                overlay={renderPopover}>
                                                <img
                                                  src={
                                                    data?.cover_path
                                                      ? data?.cover_path
                                                      : data?.avatar_path
                                                  }
                                                  alt="avatar"
                                                />
                                              </OverlayTrigger>
                                            </Link>
                                          </div>
                                          <div className="company-discr">
                                            <h4>
                                              <Link
                                                to="#"
                                                onClick={(e) => {
                                                  handleAddNavigate(e, data?.encrypted_id);
                                                }}
                                                title={data?.name}>
                                                {truncate(data?.name, 20)}{' '}
                                              </Link>
                                            </h4>
                                          </div>
                                        </div>
                                        <Dropdown>
                                          <Dropdown.Toggle variant="success" id="dropdown-basic">
                                            <i className="fas fa-ellipsis-v"></i>
                                          </Dropdown.Toggle>
                                          <Dropdown.Menu>
                                            <Dropdown.Item
                                              href="#/group-setting"
                                              onClick={(e) => {
                                                handleAddNavigate(
                                                  e,
                                                  data?.encrypted_id,
                                                  'settings'
                                                );
                                              }}>
                                              Edit Club settings
                                            </Dropdown.Item>
                                            <Dropdown.Item onClick={(e) => leaveGroup(e, data?.id)}>
                                              Leave Club
                                            </Dropdown.Item>
                                          </Dropdown.Menu>
                                        </Dropdown>
                                      </div>
                                    </>
                                  );
                                })
                              ) : (
                                <tr className="text-center">No Clubs Found</tr>
                              )}
                            </div>
                            {shouldLoadMoreButtonVisible && joinedGroups != manageGroup.length && (
                              <div className="load-more-btn text-center">
                                <Button
                                  variant="secondary"
                                  className="read-more"
                                  onClick={handleLoadMore}>
                                  Load More
                                </Button>
                              </div>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                    <div className="company-lft-dis mb-3">
                      <div className="company-lst bg-white p-4">
                        <div className="company-lft-dis">
                          <div className="company-hd flex-wrap d-flex align-items-center justify-content-between mb-4">
                            <h4>CLUBS YOU JOINED</h4>
                            <div
                              className={isActive ? 'comp-hd-search blk-search' : 'comp-hd-search'}>
                              <Link onClick={handleClick} to="#">
                                <i className="fas fa-search"></i>
                              </Link>
                              <Form.Group className="search-card" controlId="formBasicEmail">
                                <Form.Control
                                  type="search"
                                  placeholder="Search Clubs"
                                  onChange={(e) => handleJoinGroupSearch(e.target.value)}
                                />
                              </Form.Group>
                            </div>
                          </div>

                          {/* <GroupsListSkeleton /> */}
                        </div>
                        {loading ? (
                          <>
                            {' '}
                            <div className="company-follow">
                              {Array.from(Array(6), () => {
                                return <CompanySimmer />;
                              })}
                            </div>
                          </>
                        ) : (
                          <>
                            {' '}
                            <div
                              className={
                                isScrollable
                                  ? 'company-follow compnay-card-scroll'
                                  : 'company-follow'
                              }>
                              {joinGroup?.length !== 0 ? (
                                <>
                                  {joinGroup?.map((data, index) => {
                                    return (
                                      <>
                                        <div
                                          className="company-follow-card d-flex justify-content-between"
                                          key={index}>
                                          <div
                                            className="d-flex align-items-top"
                                            role="presentation"
                                            onClick={() =>
                                              window.open(`/user/club-detail/${data?.ID}`, '_blank')
                                            }>
                                            <div className="company-icn me-3">
                                              <Link to="#">
                                                <OverlayTrigger
                                                  trigger={['hover', 'focus']}
                                                  placement="top"
                                                  delay={{ show: 250, hide: 400 }}
                                                  data={data}
                                                  popperConfig={{
                                                    data
                                                  }}
                                                  shouldUpdatePosition={true}
                                                  overlay={renderPopover}>
                                                  <img
                                                    src={
                                                      data?.cover_path
                                                        ? data?.cover_path
                                                        : data?.avatar_path
                                                    }
                                                    alt=""
                                                  />
                                                </OverlayTrigger>
                                              </Link>
                                            </div>
                                            <div className="company-discr">
                                              <h4>
                                                <Link to="#" title={data?.name}>
                                                  {truncate(data?.name, 20)}
                                                </Link>
                                              </h4>
                                              {/* <Link to="#">(Requested)</Link> */}
                                            </div>
                                          </div>
                                          <Dropdown>
                                            <Dropdown.Toggle variant="success" id="dropdown-basic">
                                              <i className="fas fa-ellipsis-v"></i>
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                              <Dropdown.Item
                                                href="#/action-1"
                                                onClick={() => {
                                                  navigate(
                                                    `/user/club-detail/${data?.ID}?eventKey=first`,
                                                    {
                                                      state: data?.id
                                                    }
                                                  );
                                                }}>
                                                Check Club's Posts
                                              </Dropdown.Item>
                                              <Dropdown.Item
                                                href="#/action-2"
                                                onClick={(e) => leaveGroupsJoined(e, data?.id)}>
                                                Leave Club
                                              </Dropdown.Item>
                                            </Dropdown.Menu>
                                          </Dropdown>
                                        </div>
                                      </>
                                    );
                                  })}
                                </>
                              ) : (
                                <>No Clubs Found</>
                              )}
                            </div>
                            {shouldLoadMoreButtonVisibleJoined && (
                              <div className="load-more-btn text-center">
                                <Button
                                  variant="secondary"
                                  className="read-more"
                                  onClick={() => {
                                    setIsScrollable(true);
                                    setOffSet(joinGroup?.length + 6);
                                    getUserGroupList('', joinGroup?.length, true);
                                  }}>
                                  Load More
                                </Button>
                              </div>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  </Tab.Pane>

                  <Tab.Pane eventKey="second">
                    <div className="company-lst bg-white p-4 mb-3">
                      <div className="chapter-slide-card">
                        <Swiper
                          spaceBetween={10}
                          slidesPerView={4}
                          modules={[Navigation]}
                          navigation={true}>
                          {topSilder && topSilder?.length ? (
                            topSilder?.map((discoverSlider) => {
                              return (
                                <SwiperSlide key={discoverSlider?.id}>
                                  <div className="chapter-card">
                                    <Link
                                      to="#"
                                      onClick={() => {
                                        handleJoin(discoverSlider);
                                        setShowData(true);
                                      }}>
                                      <img
                                        src={process.env.REACT_APP_API_URL + discoverSlider?.logo}
                                        alt=""
                                      />
                                      <div className="chapter-card">
                                        <div>
                                          <h4>{discoverSlider?.qualification_type}</h4>
                                          <p>{discoverSlider?.name}</p>
                                        </div>
                                      </div>
                                    </Link>
                                  </div>
                                </SwiperSlide>
                              );
                            })
                          ) : (
                            <tr className="text-center">No data found </tr>
                          )}
                        </Swiper>
                      </div>
                    </div>

                    {showData ? (
                      <>
                        {isLoading ? (
                          <>
                            {' '}
                            <div className="company-lst bg-white p-4 mb-3">
                              <div className="company-follow">
                                {Array.from(Array(6), () => {
                                  return <CompanySimmer />;
                                })}
                              </div>
                            </div>
                          </>
                        ) : (
                          <>
                            {' '}
                            <div className="company-lst bg-white p-4 mb-3">
                              <div className="row mb-3">
                                <div className="col-sm-8">
                                  <div className="heading ins-name">{instiData?.name}</div>
                                </div>
                                <div className="col-sm-4 text-right">
                                  <span
                                    className="close-btn  d-flex justify-content-end "
                                    onClick={() => setShowData(false)}>
                                    <img
                                      src={require('../../../assets/images/close-btn.png')}
                                      alt="close"
                                    />
                                  </span>
                                </div>
                              </div>
                              <div className="company-follow">
                                {chapterInstitute && chapterInstitute?.length !== 0 ? (
                                  chapterInstitute?.map((data) => {
                                    return (
                                      <div
                                        className="company-follow-card d-flex justify-content-between"
                                        key={data?.id}>
                                        <div className="d-flex align-items-top">
                                          <div className="company-icn me-3">
                                            <Link to="#">
                                              <OverlayTrigger
                                                trigger={['hover', 'focus']}
                                                placement="top"
                                                delay={{ show: 250, hide: 1500 }}
                                                data={data}
                                                popperConfig={{
                                                  data
                                                }}
                                                shouldUpdatePosition={true}
                                                overlay={renderDiscoverPopover}>
                                                <img src={data?.avatar_path}></img>
                                              </OverlayTrigger>
                                            </Link>
                                          </div>
                                          <div className="company-discr">
                                            <h4>
                                              <Link to="#">{data?.name}</Link>
                                            </h4>
                                            <p>
                                              {data?.friendcount !== 0 &&
                                                `${data?.friendcount} friends-`}
                                              {data?.count !== 0 && `${data?.count} Members`}
                                            </p>
                                          </div>
                                          <div className="company-discr">
                                            <h4>
                                              <Link to="#">{data?.institute_type}</Link>
                                            </h4>
                                            <p>{data?.total_members}</p>
                                          </div>
                                        </div>
                                        <Button
                                          className="follow-btn-grey"
                                          onClick={(e) => handleJoinInstitueGroup(e, data?.id)}>
                                          {clickedId == data?.id && isntibuttonLoader ? (
                                            <i className="fa fa-spinner fa-spin"></i>
                                          ) : (
                                            <>
                                              <i className="fas fa-plus me-1"></i> Join
                                            </>
                                          )}
                                        </Button>
                                      </div>
                                    );
                                  })
                                ) : (
                                  <div className="w-100">
                                    <div className="text-center w-100">
                                      No Clubs associated with institute at the moment{' '}
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          </>
                        )}
                      </>
                    ) : (
                      <></>
                    )}
                    <div className="company-lst bg-white p-4">
                      <div className="company-hd flex-wrap d-flex align-items-center justify-content-between mb-4">
                        <h4>MORE SUGGESTIONS</h4>
                      </div>
                      {loading ? (
                        <>
                          {' '}
                          <div className="company-follow">
                            {Array.from(Array(6), () => {
                              return <CompanySimmer />;
                            })}
                          </div>
                        </>
                      ) : (
                        <>
                          {' '}
                          <div className="company-follow">
                            {userSuggestions && userSuggestions?.length ? (
                              userSuggestions?.map((data) => {
                                return (
                                  <div
                                    className="company-follow-card d-flex justify-content-between"
                                    key={data?.id}>
                                    <div className="d-flex align-items-top">
                                      <div className="company-icn me-3">
                                        <Link target="_blank" to={`/user/club-detail/${data?.ID}`}>
                                          <OverlayTrigger
                                            trigger={['hover', 'focus']}
                                            placement="top"
                                            delay={{ show: 250, hide: 1500 }}
                                            data={data}
                                            popperConfig={{
                                              data
                                            }}
                                            shouldUpdatePosition={true}
                                            overlay={renderDiscoverPopover}>
                                            <img src={data?.avatar_path} alt="img" />
                                          </OverlayTrigger>
                                        </Link>
                                      </div>
                                      <div className="company-discr">
                                        <h4>
                                          <Link
                                            target="_blank"
                                            to={`/user/club-detail/${data?.ID}`}>
                                            {data?.name}
                                          </Link>
                                        </h4>
                                        <p>
                                          {data?.friendcount !== 0 &&
                                            `${data?.friendcount} friends-`}
                                          {data?.count !== 0 && `${data?.count} Members`}
                                        </p>
                                        {data?.user_data && data?.user_data?.length !== 0 ? (
                                          <>
                                            {data?.user_data?.map((data) => {
                                              return (
                                                <OverlayTrigger
                                                  trigger={['hover', 'focus']}
                                                  placement="bottom"
                                                  delay={{ show: 500, hide: 1500 }}
                                                  data={data}
                                                  popperConfig={{
                                                    data
                                                  }}
                                                  shouldUpdatePosition={true}
                                                  overlay={renderUserPopover}>
                                                  <img
                                                    className="media-object-userimage"
                                                    src={
                                                      data?.avatar_url
                                                        ? data?.avatar_url
                                                        : require('../../../assets/images/default-male-avatar.png')
                                                    }
                                                  />
                                                </OverlayTrigger>
                                              );
                                            })}
                                          </>
                                        ) : (
                                          <></>
                                        )}
                                      </div>
                                    </div>
                                    <Button
                                      className="follow-btn-grey"
                                      onClick={(e) => handleJoinGroup(e, data?.id)}>
                                      {clickedId == data?.id && buttonLoader ? (
                                        <i className="fa fa-spinner fa-spin"></i>
                                      ) : (
                                        <>
                                          <i className="fas fa-plus me-1"></i> Join
                                        </>
                                      )}
                                    </Button>
                                  </div>
                                );
                              })
                            ) : (
                              <tr className="text-center">Data Not Found</tr>
                            )}
                          </div>
                        </>
                      )}
                    </div>
                  </Tab.Pane>
                </Tab.Content>
              </Col>
              <Col xs={12} lg={4} xl={3}>
                <div>
                  <PeopleKnow />
                </div>
                <div>
                  <FollowCompany />
                </div>
                <div>
                  <CampusNews />
                </div>
                <div>
                  <CampusnewsText />
                </div>
                <div>
                  <ArticalBlogs />
                </div>
                <div>
                  <WhatsNew />
                </div>
                <div>
                  <Upcoming />
                </div>
                <MiniFooter />
              </Col>
            </Row>
          </div>
        </Tab.Container>
      </section>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        className="create-group-modal">
        <Modal.Header closeButton>
          <Modal.Title>Create New Club</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {loading ? (
            <Form>
              {' '}
              <div className="loader-modal">
                <img src={require('../../../assets/images/Loading_icon-small.gif')} alt="img" />
              </div>
            </Form>
          ) : (
            <Form>
              <Form.Group className="mb-3" controlId="formBasicName">
                <Form.Label style={{ color: '#000' }}>Name your club</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Club name"
                  onChange={(e) => setGroupName(e.target.value)}
                  autoComplete="off"
                />
                <div className={'danger-frm'}>{clicked && validate().groupName}</div>
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label style={{ color: '#000' }}>Add some people</Form.Label>

                <MultiAsynSelect
                  isMulti={true}
                  setValue={setPeopleName}
                  loadOptions={loadFriendOptions}
                  placeholder="Enter names or email addresses"
                  setInputMsg={setInputMsg}
                  inputMsg={inputMsg}
                />
                {loadInput && (
                  <div
                    style={{
                      position: 'absolute',
                      right: '35px',
                      marginTop: '-30px'
                    }}>
                    <i className="fa fa-spinner fa-pulse me-2"></i>
                  </div>
                )}
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicPrivacy">
                <Form.Label style={{ color: '#000' }}>Select privacy</Form.Label>
                <div className="select-privacy">
                  <Dropdown onSelect={handleSelectPrivacy} defaultShow={0}>
                    <Dropdown.Toggle
                      id="dropdown-basic"
                    // onClick={() => setPrivacy(CLOSED)}
                    >
                      <div className="select-privacy-card d-flex align-items-center" id="replace">
                        <span className="privacy-icn">
                          <i className="fas fa-lock"></i>
                        </span>
                        <div className="privacy-dis">
                          <h4>Closed Club</h4>
                          <p>
                            Anyone can find the group and see who’s in it. Only members can see
                            posts.
                          </p>
                        </div>
                      </div>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item eventKey={OPEN}>
                        <div className="select-privacy-card d-flex align-items-center" id={0}>
                          <span className="privacy-icn">
                            <i className="fas fa-globe-americas"></i>
                          </span>
                          <div className="privacy-dis">
                            <h4>Open Club</h4>
                            <p>Anyone can see the group, its members and their posts.</p>
                          </div>
                        </div>
                      </Dropdown.Item>
                      <Dropdown.Item eventKey={CLOSED}>
                        <div className="select-privacy-card d-flex align-items-center" id={1}>
                          <span className="privacy-icn">
                            <i className="fas fa-lock"></i>
                          </span>
                          <div className="privacy-dis">
                            <h4>Closed group</h4>
                            <p>
                              Anyone can find the group and see who’s in it. Only members can see
                              posts.
                            </p>
                          </div>
                        </div>
                      </Dropdown.Item>
                      <Dropdown.Item eventKey={SECRET}>
                        <div className="select-privacy-card d-flex align-items-center" id={2}>
                          <span className="privacy-icn">
                            <i className="fas fa-shield-alt"></i>
                          </span>
                          <div className="privacy-dis">
                            <h4>Secret Club</h4>
                            <p>Only members can find the group and see posts.</p>
                          </div>
                        </div>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </Form.Group>
              <div className="text-end">
                <Button variant="primary" type="button" onClick={addGroup}>
                  Create
                </Button>
              </div>
            </Form>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ClubPage;
