/**
@copyright  :   ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
@author     :   Shiv Charan Panjeta 
 
All Rights Reserved.
Proprietary and confidential :  All information contained here in is, and remains
the property of ToXSL Technologies Pvt. Ltd. and it's partners.
Unauthorized copying of this file, via any medium is strictly prohibited.
*/
import { useState } from 'react';
import { Button, Form } from 'react-bootstrap';

function InvitePeople({ handleSearch, search, handleEventInivite, InviteList, loadingBtn }) {
  const [buttonState, setButtonState] = useState(null);
  return (
    <>
      {/* new */}
      <div className="invite-srch-card mb-3">
        <div className="invite-srch-hd">
          <h4>Invite People</h4>
        </div>
        <div className="invite-srch">
          <Form>
            <Form.Group controlId="formFile" className="mb-3">
              <Form.Control
                type="text"
                placeholder="search"
                name="search"
                value={search}
                controlId="formBasicEmail"
                onKeyPress={(e) => {
                  e.key === 'Enter' && e.preventDefault();
                }}
                onChange={(e) => handleSearch(e)}
              />
            </Form.Group>
            <button className="active-srch">
              <i className="fas fa-search"></i>
            </button>
          </Form>
        </div>

        <ul className="upcome-card-lst">
          {InviteList && InviteList?.length ? (
            InviteList?.slice(0, 3)?.map((data, index) => (
              <li key={index}>
                <div className="invite-people-card d-flex align-items-center justify-content-between">
                  <div className="invite-dis-lft d-flex align-items-center">
                    <span className="invite-img">
                      <img src={data?.img} alt="people" />
                    </span>
                    <p>{data?.name}</p>
                  </div>
                  {loadingBtn && buttonState == data?.id ? (
                    <Button key={index} className="sent-btn">
                      Sent
                    </Button>
                  ) : (
                    <Button
                      className="invite-btn"
                      onClick={(e) => {
                        setButtonState(data?.id);
                        handleEventInivite(e, data?.id);
                      }}>
                      Invite
                    </Button>
                  )}
                </div>
              </li>
            ))
          ) : (
            <div className="text-center">
              <img src={require('../../../assets/images/small-error.png')} alt="img" />
              <p className="text-center">No more people to be invite ..</p>
            </div>
          )}
        </ul>
      </div>
    </>
  );
}

export default InvitePeople;
