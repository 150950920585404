import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { truncate } from '../../../globals/helper';
import { getCampusNews } from '../../../services/CampusServices';
import { Transition } from 'react-transition-group';

function CampusNews() {
  const [newList, setNewList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [inProp, setInProp] = useState('');
  const duration = 500;

  useEffect(() => {
    (async function () {
      setLoading(true);
      try {
        const response = await getCampusNews();
        if (response?.status === 200) {
          setNewList(response.data?.message);
          setLoading(false);
        }
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    })();
  }, []);

  const handleSkip = (id) => {
    setInProp(id);
    setTimeout(() => {
      let filterNews = newList.filter((data) => data?.id !== id);
      setNewList(filterNews);
    }, 500);
  };

  const defaultStyle = {
    transition: `transform ${duration}ms ease-out`

    // opacity: 0
  };

  const transitionStyles = {
    entering: { opacity: 0 },
    entered: { opacity: 1 },
    exiting: { transform: 'translateX(-100%)', opacity: 1 },
    exited: { opacity: 0 }
  };

  return (
    <>
      <div className="whats-new-sec bg-white p-3 mb-3" style={{ overflow: 'hidden' }}>
        <div className="d-flex align-items-top justify-content-between flex-wrap">
          <h4>Campus News</h4>
          <Link className="more-btn" to="/user/campus/news_listing" target="_blank">
            More
          </Link>
        </div>
        <div className="whats-nw-card">
          <ul className="group-card-lst whats-nw-card-lst">
            {loading && (
              <>
                {Array.from(Array(3), function (index) {
                  return <Shimmer key={index} />;
                })}
              </>
            )}
            {!loading && newList?.length > 0 ? (
              newList?.slice(0, 3)?.map((data, index) => {
                return (
                  <li key={index}>
                    <Transition in={inProp == data?.id ? false : true} timeout={300}>
                      {(state) => (
                        <div
                          className="mentor-card discover-card-inr d-flex"
                          style={{
                            ...defaultStyle,
                            ...transitionStyles[state]
                          }}>
                          <Link
                            to={`/user/campus-detail/ ${data?.id} `}
                            className="mentor-icn me-2"
                            target="_blank">
                            <img
                              src={
                                data?.institute_logo
                                  ? process.env.REACT_APP_API_URL + '/' + data?.institute_logo
                                  : require('../../../assets/images/default-male-avatar.png')
                              }
                              onError={({ currentTarget }) => {
                                currentTarget.onerror = null; // prevents looping
                                currentTarget.src = require("../../../assets/images/default-male-avatar.png")
                              }}
                              alt="img"
                            />
                          </Link>
                          <div className="mentor-rt-dis">
                            <h4>
                              <Link to={`/user/campus-detail/${data?.id}`}>
                                {data?.title ?? 'NA'}
                              </Link>
                            </h4>
                            <span
                              dangerouslySetInnerHTML={{ __html: truncate(data?.description, 50) }}
                            />
                          </div>
                          <button className="close-btn" onClick={() => handleSkip(data?.id)}>
                            <i className="fas fa-times"></i>
                          </button>
                        </div>
                      )}
                    </Transition>
                  </li>
                );
              })
            ) : (
              <>
                {!loading && newList?.length === 0 && (
                  <p style={{ textAlign: 'center' }}>No more campus news for you at the moment.</p>
                )}
              </>
            )}
          </ul>
        </div>
      </div>
    </>
  );
}

export default CampusNews;

const Shimmer = () => {
  return (
    <li>
      <div className="mentor-card discover-card-inr whats-nw-loader loading-skeleton d-flex">
        <Link to="#" className="mentor-icn me-2">
          <img src={require('../../../assets/images/default-male-avatar.png')} alt="img" />
        </Link>
        <div className="mentor-rt-dis">
          <h4>
            <Link to="#">sacasc scac</Link>
          </h4>
          <span>vfvj v fkjnjrkr r</span>
        </div>
        <button className="close-btn">
          <i className="fas fa-times"></i>
        </button>
      </div>
    </li>
  );
};
