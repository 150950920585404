/**
 * The withErrorBoundary HOC takes a functional component as an argument,
 * and returns a new functional component that wraps the original component.
 * The new component uses the useState hook to keep track of any errors that occur,
 * and if an error occurs, it will render a fallback UI.
 * The wrapped component is then rendered inside a try-catch block,
 * so that any errors that occur in it will be caught and handled by the HOC.
 */

import { useState } from 'react';

function withErrorBoundary(WrappedComponent) {
  return function ErrorBoundary(props) {
    const [error, setError] = useState(null);

    if (error) {
      return <div>An error occurred: {error.toString()}</div>;
    }

    try {
      return <WrappedComponent {...props} />;
    } catch (err) {
      setError(err);
    }
  };
}

export default withErrorBoundary;
