import MultiRangeSlider from 'multi-range-slider-react';
import { useEffect, useState } from 'react';
import { Accordion, Card, Col, Container, Form, Row } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Link, useNavigate } from 'react-router-dom';
import { getJobList, getJobKeyword, getJobCategory } from '../../../services/InstituteServices';
import { getTime, truncate } from '../../../globals/helper';
import AsyncSelect from 'react-select/async';
import { getLocationTags } from '../../../services/UserServices';
import Loader from '../../commoncomponents/loader/loader-large';

function JobsBoard() {
  const navigate = useNavigate();
  const [pager, setPager] = useState('');
  const [list, setList] = useState([]);
  const [jobDetail, setJobDetail] = useState('');
  const [inputMessage, setInputMessage] = useState('Please enter atleast 2 characters.');
  const [jobCategory, setJobCategory] = useState([]);
  const [categoryId, setCategoryId] = useState([]);
  const [jobKeyword, setJobKeyword] = useState([]);
  const [keywordId, setKeywordId] = useState([]);
  const [experience, setExperience] = useState({
    min: 0,
    max: 0
  });
  const [ctc, setCtc] = useState({
    min: 0,
    max: 0
  });
  const [jobTypeID, setJobTypeId] = useState([]);
  const [companyTypeID, setCompanyTypeId] = useState([]);
  const [locationId, setLocationId] = useState('');

  const [loading, setLoading] = useState(true);
  // for pop-up modal
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    getInfo();
    getJobListData();
  }, []);

  /**
   * function to get job list based on institute domain
   */
  const getJobListData = () => {
    getJobList(
      jobTypeID,
      keywordId.join(),
      categoryId.join(),
      ctc.max,
      experience.max,
      ctc.min,
      experience.min,
      companyTypeID.join(),
      locationId
    ).then((res) => {
      setLoading(false)
      if (res?.status == 200) {
        setList(res?.data?.data || []);
        setPager(res?.data?.pager);
      }
    });
  };
  /**
   * function to get job filter options
   */
  const getInfo = () => {

    getJobCategory().then((res) => {
      if (res?.status == 200) {
        let data = res?.data?.data?.allCategories ? res?.data?.data?.allCategories : [];
        setJobCategory(data);
      }
    });

    getJobKeyword().then((res) => {
      if (res?.status == 200) {
        let data = res?.data?.data ? res?.data?.data : [];
        setJobKeyword(data);
      }
    });
  };

  //load options using API call
  const loadGetLocationTags = async (inputValue) => {
    if (inputValue?.length > 1) {
      return getLocationTags(inputValue, '1', jobTypeID?.join()).then((res) => {
        if (res?.status === 200) {
          let tempArr = [];
          if (res?.data?.results) {
            const data = res?.data?.results;
            data?.map((data) => {
              tempArr.push({
                id: data?.id,
                label: data?.name,
                value: data?.name
              });
            });
          }
          if (res?.data?.results == '') {
            setInputMessage('no matches found ..');
          }
          return tempArr;
        }
      });
    }
  };

  const inputMessageHandler = (e) => {
    if (e?.length !== 1 && e?.length < 1) {
      setInputMessage('Please enter atleast 2 characters.');
    }
  };

  const handleCategoryCheckBox = (e, id) => {
    let arr = [...categoryId];
    if (e.target.checked) {
      //push
      arr.push(id);
      setCategoryId(arr);
    } else {
      //pop that id
      let result = categoryId.filter((data) => data != id);
      setCategoryId(result);
    }
  };

  const handleSkillCheckBox = (e, id) => {
    let arr = [...keywordId];
    if (e.target.checked) {
      //push
      arr.push(id);
      setKeywordId(arr);
    } else {
      //pop that id
      let result = keywordId.filter((data) => data != id);
      setKeywordId(result);
    }
  };

  /**
   * using function for checkbox>in filter >for multi checks id's
   * @param {*} e
   * @param {*} type
   */

  const checkBoxHandler = (e, type) => {
    if (type == 'job') {
      if (e.target.checked) {
        setJobTypeId(e.target.id);
      } else {
        //pop that id
        let result = jobTypeID.filter((data) => data != e.target.id);
        setJobTypeId(result);
      }
    }
    if (type == 'company') {
      let arr = [...companyTypeID];
      if (e.target.checked) {
        //push
        arr.push(e.target.id);
        setCompanyTypeId(arr);
      } else {
        //pop that id
        let result = companyTypeID.filter((data) => data != e.target.id);
        setCompanyTypeId(result);
      }
    }
  };

  return (
    <>
      <div className="wrappermain_outer">
        <section
          className="breadcrum-banner"
          style={{
            backgroundImage: `url(${list?.banner
              ? process.env.REACT_APP_API_URL + list?.banner?.url
              : '/static/media/breadcrum-banner-img.1a6b2ed71ebb8768883c.png'
              })`
          }}
        >
          <Container>
            <Row>
              <Col sm={12}>
                <h1 className="Text-white">{list?.banner?.name || 'Job Board'}</h1>
              </Col>
            </Row>
          </Container>
        </section>
        {/* filter */}
        <section className="jobs-section pt-5">
          <Container>
            <Row>
              <Col lg={3}>
                <div className="filter_container">
                  <div className="d-flex justify-content-between">
                    <h4 className="filter_heading ">Filter By</h4>
                    <Button
                      variant="primary"
                      className="filter-btn d-flex"
                      type="button"
                      onClick={getJobListData}
                    >
                      Apply
                    </Button>
                  </div>
                  <div>
                    {/* filter */}
                    <Accordion defaultActiveKey={0}>
                      <Accordion.Item className="mb-3" eventKey="0">
                        <Accordion.Header>Industry</Accordion.Header>
                        <Accordion.Body>
                          <Form>
                            {jobCategory &&
                              jobCategory?.map((data, index) => (
                                <div
                                  key={index}
                                  className="single_checkbox d-flex align-items-center"
                                >
                                  <Form.Check
                                    id={data?.id}
                                    onChange={(e) => {
                                      handleCategoryCheckBox(e, data?.id);
                                    }}
                                    label=""
                                  />
                                  <p className="mb-1">
                                    {data?.name}
                                    {/* <span>({data?.totalJobs})</span>{' '} */}
                                  </p>
                                </div>
                              ))}
                          </Form>
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item className="mb-3" eventKey="1">
                        <Accordion.Header>Skills</Accordion.Header>
                        <Accordion.Body>
                          <Form>
                            {jobKeyword &&
                              jobKeyword?.map((data, index) => (
                                <div
                                  className="single_checkbox d-flex align-items-center"
                                  key={index}
                                >
                                  <Form.Check
                                    id={data?.id}
                                    label=""
                                    onChange={(e) => handleSkillCheckBox(e, data?.id)}
                                  />
                                  <p className="mb-1">{data?.text}</p>
                                </div>
                              ))}
                          </Form>
                        </Accordion.Body>
                      </Accordion.Item>

                      <Accordion.Item className="mb-3" eventKey="8">
                        <Accordion.Header>Experience</Accordion.Header>
                        <Accordion.Body>
                          <Form>
                            <div className="single_checkbox d-flex align-items-center">
                              <MultiRangeSlider
                                min={0}
                                max={10}
                                step={1}
                                minValue={experience.min}
                                maxValue={experience.max}
                                onInput={(e) => {
                                  setExperience({
                                    min: e.minValue,
                                    max: e.maxValue
                                  });
                                }}
                                className="w-100"
                              />
                            </div>
                          </Form>
                        </Accordion.Body>
                      </Accordion.Item>

                      <Accordion.Item className="mb-3" eventKey="9">
                        <Accordion.Header>Salary</Accordion.Header>
                        <Accordion.Body>
                          <Form>
                            <div className="single_checkbox d-flex align-items-center">
                              <MultiRangeSlider
                                min={0}
                                max={20}
                                step={1}
                                minValue={ctc.min}
                                maxValue={ctc.max}
                                onInput={(e) => {
                                  setCtc({
                                    min: e.minValue,
                                    max: e.maxValue
                                  });
                                }}
                                className="w-100"
                              />
                            </div>
                          </Form>
                        </Accordion.Body>
                      </Accordion.Item>

                      <Accordion.Item className="mb-3" eventKey="2">
                        <Accordion.Header>Job Type</Accordion.Header>
                        <Accordion.Body>
                          <Form>
                            <div className="single_checkbox d-flex align-items-center">
                              <Form.Check
                                id="0"
                                label=""
                                name="myCheckbox"
                                type="radio"
                                onChange={(e) => {
                                  checkBoxHandler(e, 'job');
                                }}
                              />
                              <p className="mb-1">Full Time </p>
                            </div>
                            <div className="single_checkbox d-flex align-items-center">
                              <Form.Check
                                id="1"
                                label=""
                                name="myCheckbox"
                                type="radio"
                                onChange={(e) => {
                                  checkBoxHandler(e, 'job');
                                }}
                              />
                              <p className="mb-1">Part Time </p>
                            </div>
                            <div className="single_checkbox d-flex align-items-center">
                              <Form.Check
                                id="2"
                                label=""
                                name="myCheckbox"
                                type="radio"
                                onChange={(e) => {
                                  checkBoxHandler(e, 'job');
                                }}
                              />
                              <p className="mb-1">Internship </p>
                            </div>
                          </Form>
                        </Accordion.Body>
                      </Accordion.Item>

                      <Accordion.Item className="mb-3" eventKey="3">
                        <Accordion.Header>Company type</Accordion.Header>
                        <Accordion.Body>
                          <Form>
                            <div className="single_checkbox d-flex align-items-center">
                              <Form.Check
                                id="Corporates"
                                label=""
                                onChange={(e) => {
                                  checkBoxHandler(e, 'company');
                                }}
                              />
                              <p className="mb-1">Corporates </p>
                            </div>
                            <div className="single_checkbox d-flex align-items-center">
                              <Form.Check
                                id="Educational Institute"
                                label=""
                                onChange={(e) => {
                                  checkBoxHandler(e, 'company');
                                }}
                              />
                              <p className="mb-1">Educational Institute </p>
                            </div>
                            <div className="single_checkbox d-flex align-items-center">
                              <Form.Check
                                id="Non-Govt Oraganisation"
                                label=""
                                onChange={(e) => {
                                  checkBoxHandler(e, 'company');
                                }}
                              />
                              <p className="mb-1">Non-Govt Oraganisation </p>
                            </div>
                            <div className="single_checkbox d-flex align-items-center">
                              <Form.Check
                                id="Non Profit Organisation"
                                label=""
                                onChange={(e) => {
                                  checkBoxHandler(e, 'company');
                                }}
                              />
                              <p className="mb-1">Non Profit Organisation </p>
                            </div>
                          </Form>
                          {/* <span className='view_more'>View More</span> */}
                        </Accordion.Body>
                      </Accordion.Item>

                      <Accordion.Item className="mb-3" eventKey="4">
                        <Accordion.Header>Location</Accordion.Header>
                        <Accordion.Body>
                          <AsyncSelect
                            isMulti
                            defaultOptions
                            placeholder="Search..e.g Delhi, Pune etc..."
                            getOptionLabel={(e) => e.label}
                            getOptionValue={(e) => e.value}
                            loadOptions={loadGetLocationTags}
                            className="basic-single mt-1 w-auto"
                            name="location"
                            onChange={(e) => {
                              setLocationId(e.map((data) => data?.id).join());
                            }}
                            onInputChange={(e) => {
                              inputMessageHandler(e);
                            }}
                            noOptionsMessage={() => inputMessage}
                          />
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </div>
                </div>
              </Col>
              <Col lg={9}>
                {list?.list?.length ? (
                  <p className="result">
                    {' '}
                    {pager?.total} Result <span className="ms-3">{list?.list?.length}</span>
                  </p>
                ) : (
                  ''
                )}
                {list?.list && list?.list?.length ? (
                  list?.list?.map((data, index) => (
                    <Card key={index} className="single-job-card">
                      <Card.Header className="bg-white">
                        <div
                          role="presentation"
                          className="pointer"
                          onClick={() => {
                            handleShow();
                            setJobDetail(data);
                          }}
                        >
                          <h5> {data?.job_title}</h5>
                          <p>{data?.company_name || data?.companyName}</p>
                        </div>
                        {data?.company_image ? (
                          <div className="jobcard-img pointer">
                            <img
                              role="presentation"
                              onClick={() => {
                                handleShow();
                                setJobDetail(data);
                              }}
                              src={
                                data?.company_image
                                  ? process.env.REACT_APP_API_DOMAIN +
                                  'common/uploads/institute_logo/' +
                                  data?.company_image
                                  : require('../../../assets/images/intitute-jobboard-img.png')
                              }
                              alt="img"
                              height="50px"
                              width="50px"
                            />
                          </div>
                        ) : (
                          <></>
                        )}
                      </Card.Header>
                      <Card.Body>
                        <div className="d-flex align-items-center mb-3 job-discription">
                          <span>
                            <svg
                              width="20"
                              height="20"
                              viewBox="0 0 20 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M13.3337 5.83333C13.3337 5.05836 13.3337 4.67087 13.2485 4.35295C13.0173 3.49022 12.3434 2.81635 11.4807 2.58518C11.1628 2.5 10.7753 2.5 10.0003 2.5C9.22532 2.5 8.83782 2.5 8.51991 2.58518C7.65722 2.81635 6.98334 3.49022 6.75217 4.35295C6.66699 4.67087 6.66699 5.05836 6.66699 5.83333M4.33366 17.5H15.667C16.6004 17.5 17.0672 17.5 17.4237 17.3183C17.7372 17.1586 17.9922 16.9036 18.152 16.59C18.3337 16.2335 18.3337 15.7667 18.3337 14.8333V8.5C18.3337 7.56657 18.3337 7.09987 18.152 6.74335C17.9922 6.42974 17.7372 6.17477 17.4237 6.01499C17.0672 5.83333 16.6004 5.83333 15.667 5.83333H4.33366C3.40023 5.83333 2.93353 5.83333 2.57701 6.01499C2.2634 6.17477 2.00843 6.42974 1.84865 6.74335C1.66699 7.09987 1.66699 7.56657 1.66699 8.5V14.8333C1.66699 15.7667 1.66699 16.2335 1.84865 16.59C2.00843 16.9036 2.2634 17.1586 2.57701 17.3183C2.93353 17.5 3.40024 17.5 4.33366 17.5Z"
                                stroke="black"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </span>

                          <p className="ms-2 mb-0">{data?.min_exp + '-' + data?.max_exp} years</p>
                        </div>
                        {data?.location != 0 ? (
                          <div className="d-flex align-items-center mb-3 job-discription">
                            <span>
                              <svg
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M15.625 8.125C15.625 6.63316 15.0324 5.20242 13.9775 4.14752C12.9226 3.09263 11.4918 2.5 10 2.5C8.50816 2.5 7.07742 3.09263 6.02252 4.14752C4.96763 5.20242 4.375 6.63316 4.375 8.125C4.375 10.4325 6.22125 13.44 10 17.0425C13.7788 13.44 15.625 10.4325 15.625 8.125ZM10 18.75C5.41625 14.5837 3.125 11.0413 3.125 8.125C3.125 6.30164 3.84933 4.55295 5.13864 3.26364C6.42795 1.97433 8.17664 1.25 10 1.25C11.8234 1.25 13.572 1.97433 14.8614 3.26364C16.1507 4.55295 16.875 6.30164 16.875 8.125C16.875 11.0413 14.5837 14.5837 10 18.75Z"
                                  fill="black"
                                />
                                <path
                                  d="M10 10C10.4973 10 10.9742 9.80246 11.3258 9.45083C11.6775 9.09919 11.875 8.62228 11.875 8.125C11.875 7.62772 11.6775 7.15081 11.3258 6.79917C10.9742 6.44754 10.4973 6.25 10 6.25C9.50272 6.25 9.02581 6.44754 8.67417 6.79917C8.32254 7.15081 8.125 7.62772 8.125 8.125C8.125 8.62228 8.32254 9.09919 8.67417 9.45083C9.02581 9.80246 9.50272 10 10 10ZM10 11.25C9.1712 11.25 8.37634 10.9208 7.79029 10.3347C7.20424 9.74866 6.875 8.9538 6.875 8.125C6.875 7.2962 7.20424 6.50134 7.79029 5.91529C8.37634 5.32924 9.1712 5 10 5C10.8288 5 11.6237 5.32924 12.2097 5.91529C12.7958 6.50134 13.125 7.2962 13.125 8.125C13.125 8.9538 12.7958 9.74866 12.2097 10.3347C11.6237 10.9208 10.8288 11.25 10 11.25Z"
                                  fill="black"
                                />
                              </svg>
                            </span>
                            <p className="ms-2 mb-0">{data?.city}</p>
                          </div>
                        ) : (
                          <></>
                        )}
                        <div className="d-flex align-items-center mb-3 job-discription">
                          <span>
                            <svg
                              width="20"
                              height="20"
                              viewBox="0 0 20 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M14.9996 5.83333H13.1579C12.9974 5.22649 12.7138 4.65917 12.3246 4.16667H14.9996C15.2206 4.16667 15.4326 4.07887 15.5889 3.92259C15.7451 3.76631 15.8329 3.55435 15.8329 3.33333C15.8329 3.11232 15.7451 2.90036 15.5889 2.74408C15.4326 2.5878 15.2206 2.5 14.9996 2.5H5.83295C5.61193 2.5 5.39997 2.5878 5.24369 2.74408C5.08741 2.90036 4.99961 3.11232 4.99961 3.33333C4.99961 3.55435 5.08741 3.76631 5.24369 3.92259C5.39997 4.07887 5.61193 4.16667 5.83295 4.16667H8.74961C9.29981 4.1686 9.83824 4.32612 10.3027 4.62103C10.7672 4.91594 11.1388 5.33622 11.3746 5.83333H5.83295C5.61193 5.83333 5.39997 5.92113 5.24369 6.07741C5.08741 6.23369 4.99961 6.44565 4.99961 6.66667C4.99961 6.88768 5.08741 7.09964 5.24369 7.25592C5.39997 7.4122 5.61193 7.5 5.83295 7.5H11.6663C11.5664 8.19217 11.2211 8.8254 10.6934 9.28429C10.1657 9.74318 9.49061 9.99718 8.79128 10H5.83295C5.79415 9.9961 5.75507 9.9961 5.71628 10C5.66106 9.9914 5.60484 9.9914 5.54961 10C5.49281 10.0193 5.43939 10.0475 5.39128 10.0833L5.29128 10.1417C5.24563 10.1829 5.20632 10.2306 5.17461 10.2833C5.1467 10.3203 5.12163 10.3593 5.09961 10.4C5.09219 10.4497 5.09219 10.5003 5.09961 10.55C5.04023 10.6331 5.00556 10.7313 4.99961 10.8333C4.99571 10.8721 4.99571 10.9112 4.99961 10.95C4.99101 11.0052 4.99101 11.0614 4.99961 11.1167C5.01896 11.1735 5.04708 11.2269 5.08295 11.275C5.08295 11.275 5.08295 11.3417 5.14128 11.375L10.1413 17.2083C10.2849 17.3763 10.4894 17.4803 10.7098 17.4975C10.9301 17.5147 11.1483 17.4437 11.3163 17.3C11.4842 17.1563 11.5883 16.9518 11.6055 16.7315C11.6227 16.5111 11.5516 16.293 11.4079 16.125L7.64961 11.6667H8.74961C9.89633 11.6714 11.0032 11.2461 11.8517 10.4748C12.7002 9.70339 13.2287 8.64198 13.3329 7.5H14.9996C15.2206 7.5 15.4326 7.4122 15.5889 7.25592C15.7451 7.09964 15.8329 6.88768 15.8329 6.66667C15.8329 6.44565 15.7451 6.23369 15.5889 6.07741C15.4326 5.92113 15.2206 5.83333 14.9996 5.83333Z"
                                fill="black"
                              />
                            </svg>
                          </span>

                          <p className="ms-2 mb-0">
                            {data?.max_ctc != 0 ? (
                              <>{data?.min_ctc + '-' + data?.max_ctc} Lacs</>
                            ) : (
                              <>Not Disclosed</>
                            )}{' '}
                          </p>
                        </div>
                        <div className="d-flex align-items-center mb-3 job-discription">
                          <span>
                            <svg
                              width="20"
                              height="20"
                              viewBox="0 0 20 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M16.6663 11.6663V5.83301C16.6663 4.4523 15.5471 3.33301 14.1663 3.33301H5.83301C4.4523 3.33301 3.33301 4.4523 3.33301 5.83301V14.1663C3.33301 15.5471 4.4523 16.6663 5.83301 16.6663H11.2497M16.6663 11.6663L11.2497 16.6663M16.6663 11.6663H12.9163C11.9958 11.6663 11.2497 12.4125 11.2497 13.333V16.6663"
                                stroke="black"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M6.66699 6.66699H13.3337"
                                stroke="black"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M6.66699 10H10.0003"
                                stroke="black"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </span>
                          <p
                            className="ms-2 mb-0"
                            dangerouslySetInnerHTML={{ __html: truncate(data?.job_info, 100) }}
                          ></p>
                          {/* <p className='ms-2 mb-0'>We have urgent openings for the position of Territory Manager with most of the leading life Insuran....</p> */}
                        </div>
                        <p>{data?.jobKeys?.map((data) => data?.name)?.join(',')}</p>

                        <p>
                          Posted: <span>{getTime(data?.date_time)}</span>
                          {data?.applicaiton_count ? (
                            <>
                              Job Applicants: <span>{data?.applicaiton_count}</span>
                            </>
                          ) : (
                            ''
                          )}{' '}
                        </p>
                      </Card.Body>
                    </Card>
                  ))
                ) : (
                  <div className="text-center nodata">
                    <img src={require('../../../assets/images/nodata.png')} alt="" />
                    <p>Data Not Found</p>
                  </div>
                )}
              </Col>
            </Row>
          </Container>
        </section>

        {/* pop-up modal for job detail */}
        <Modal show={show} onHide={handleClose}>
          <Modal.Body>
            <div className="job-requirement-detail">
              <h3>{jobDetail?.job_title}</h3>
              <div dangerouslySetInnerHTML={{ __html: jobDetail?.job_info }} />
              {/* <h4>Responsibilities</h4>
                            <div dangerouslySetInnerHTML={{ __html: jobDetail?.job_info }} /> */}
              {jobDetail?.jobKeys?.length ? (
                <>
                  <h4>Skills</h4>
                  <ul>
                    {jobDetail?.jobKeys?.map((data, index) => (
                      <li key={index}>{data?.name}</li>
                    ))}
                  </ul>
                </>
              ) : (
                <></>
              )}

              <p>Experience:{jobDetail?.min_exp + '-' + jobDetail?.max_exp} years</p>
              {/* <p>Education: Any Graduate</p> */}
              <p>CTC: {jobDetail?.min_ctc + '-' + jobDetail?.max_ctc} Lacs</p>
              {jobDetail?.location != 0 ? <p>Location: {jobDetail?.city}</p> : <></>}
              {jobDetail?.hr_email ? <p>Email your CV at {jobDetail?.hr_email}</p> : ''}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="primary"
              onClick={() => {
                navigate('/institute-login', {
                  state: `/jobs/jobs-details/${jobDetail?.id}`
                });
              }}
            >
              Apply Now
            </Button>
          </Modal.Footer>
        </Modal>
        {/* end */}
      </div>
      <div className="lgn-link py-4">
        <Container>
          <div className="logn-lnk-inr">
            <p>
              <Link to="/institute-register">Register</Link> /{' '}
              <Link to="/institute-login">Login</Link> to browse members by Year, Name, Company,
              Profession or Location
            </p>
          </div>
        </Container>
      </div>
      {loading ? <Loader /> : <></>}
    </>
  );
}

export default JobsBoard;
