/**
@copyright  :   ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
@author     :   Shiv Charan Panjeta 
 
All Rights Reserved.
Proprietary and confidential :  All information contained here in is, and remains
the property of ToXSL Technologies Pvt. Ltd. and it's partners.
Unauthorized copying of this file, via any medium is strictly prohibited.
*/
import { useEffect, useState } from 'react';
import { Button, Container } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Table from 'react-bootstrap/Table';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { getJobFestApplicantList } from '../../services/AuthServices';
import showNotification from '../../services/NotificationService';

function ListofApplicants() {
  const location = useLocation();
  const navigate = useNavigate();
  const data = location?.state;
  const [list, setList] = useState('');
  const [searchPara, setSearchPara] = useState({
    id: '',
    name: '',
    email: ''
  });

  useEffect(() => {
    apiCall();
  }, []);

  const apiCall = () => {
    getJobFestApplicantList(
      '',
      data?.fest_id ? data?.fest_id : data?.id,
      data?.job_title ? data?.id : '',
      searchPara
    ).then((res) => {
      if (res?.status === 200) {
        setList(res?.data?.detail);
        // setResponse(res?.data?.pager);
      }
    });
  };

  const handleSearchParam = (e) => {
    const { name, value } = e.target;
    setSearchPara({
      ...searchPara,
      [name]: value
    });
  };

  const downloadResume = (data) => {
    showNotification('success', 'Download');
    window.location.assign(
      process.env.REACT_APP_API_DOMAIN + 'common/uploads/resume/' + data?.resume
    );
  };
  return (
    <div className="dash-main-content">
      <Container fluid>
        <div className="dash-page-content">
          <div className="dash-page-title">
            <Row>
              <Col xs={12} sm={12} md={12}>
                <div className="dash-title-bar d-flex align-items-center justify-content-between">
                  <div className="title-lft d-flex align-items-center">
                    <div className="dash-icn">
                      <i className="fa fa-users"></i>
                    </div>
                    <div className="dash-title-dis">
                      {data?.job_title ? (
                        <h5>
                          List of Applicants for <span>{data?.job_title}</span> Job in{' '}
                          <span>{data?.title}</span>
                        </h5>
                      ) : (
                        <h5>
                          List of Applicants for <span>{data?.title}</span>
                        </h5>
                      )}

                      <h5>
                        Total Record(s):<span>{list?.totalRecords}</span>
                      </h5>
                    </div>
                  </div>
                  <div className="title-rt-btn d-flex align-items-center">
                    <Link to="/admin/jobfest/joblist" className="text-black fw-bold">
                      Back to Listing
                    </Link>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <div className="dash-content-card">
            <div className="table-responsive">
              <Table className="custom-table-data" striped bordered hover>
                <thead>
                  <tr>
                    <th>
                      <Link to="#">Image</Link>
                    </th>
                    <th>
                      <Link to="#">User ID</Link>
                    </th>
                    <th>
                      <Link to="#">Applicant Name</Link>
                    </th>

                    <th>Email</th>
                    <th>Mobile</th>
                    <th>Applied Date</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="search-table">
                    <td></td>
                    <td>
                      <Form.Control
                        type="text"
                        placeholder="User ID"
                        name="id"
                        onChange={handleSearchParam}
                      />
                    </td>
                    <td>
                      <Form.Control
                        type="text"
                        placeholder="Applicant Name"
                        name="name"
                        onChange={handleSearchParam}
                      />
                    </td>
                    <td>
                      <Form.Control
                        type="text"
                        placeholder="Email"
                        name="email"
                        onChange={handleSearchParam}
                      />
                    </td>
                    <td></td>
                    <td></td>

                    <td className="serac-btns text-center">
                      <Button className="main-btn-grn" type="button" onClick={apiCall}>
                        Search
                      </Button>
                    </td>
                  </tr>
                  {list?.viewlist?.length !== 0 ? (
                    <>
                      {list?.viewlist?.map((listdata, index) => {
                        return (
                          <tr key={index}>
                            <td>
                              <img
                                width="40px"
                                height="40px"
                                src="https://img1.almabay.com/641/zvfea_641_67e103b0761e60683e83c559be18d40c_s_64.jpg"
                                alt="image1"
                              />
                            </td>
                            <td>{listdata?.user_id}</td>
                            <td>
                              {listdata?.first_name} {listdata?.last_name}
                            </td>
                            <td>{listdata?.email}</td>

                            <td>{listdata?.mobile}</td>
                            <td>{listdata?.appliedOn}</td>
                            <td>
                              <Link
                                className="search-btn"
                                to=""
                                title="VIEW"
                                onClick={(e) => {
                                  e.preventDefault();
                                  navigate(`/admin/users/view/${listdata?.user_id}`);
                                }}>
                                <i className="fas fa-search-plus"></i>
                              </Link>
                              {/* /admin/jobfest/joblist */}
                              {data?.job_title ? (
                                <Link
                                  className="edit-btn"
                                  to=""
                                  title="DOWNLOAD RESUME"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    downloadResume(listdata);
                                  }}>
                                  <i className="fa fa-download"></i>
                                </Link>
                              ) : (
                                <Link
                                  className="edit-btn"
                                  to=""
                                  onClick={(e) => {
                                    e.preventDefault();
                                    navigate('/admin/jobfest/joblist', { state: listdata });
                                  }}>
                                  <i className="fa fa-briefcase"></i>
                                </Link>
                              )}
                            </td>
                          </tr>
                        );
                      })}
                    </>
                  ) : (
                    <>No data found</>
                  )}
                </tbody>
              </Table>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}

export default ListofApplicants;
