import React, { useState } from 'react';
import { useEffect } from 'react';
import { Col, Container, Form, Row, Table } from 'react-bootstrap';
import { deleteMenuList, getChildMenuList } from '../../../services/AuthServices';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import showNotification from '../../../services/NotificationService';

const ChidMenuList = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  // const id = location?.state;
  const [childMenuList, setChildMenuList] = useState([]);
  const [parentData, setParentData] = useState([]);
  useEffect(() => {
    allChildMenuList();
  }, []);

  const allChildMenuList = async () => {
    try {
      const response = await getChildMenuList(id);
      if (response.status === 200) {
        setChildMenuList(response.data?.data ?? []);
        setParentData(response?.data?.parent_data ?? []);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleChange = async (id, value, menu) => {
    if (value == 0) {
      try {
        const response = await deleteMenuList(id, value);
        if (response.status === 200) {
          showNotification('success', response.data?.message);
          allChildMenuList();
        }
      } catch (error) {
        console.error(error);
      }
    }
    if (value == 1) {
      navigate(`/admin/edit-child-menu-list/`, { state: { menu: menu, parentData: parentData } });
    }
  };

  return (
    <div className="dash-main-content">
      <Container fluid>
        <div className="dash-page-content">
          <div className="dash-page-title">
            <Row>
              <Col xs={12} sm={12} md={12}>
                <div className="dash-title-bar d-flex align-items-center justify-content-between">
                  <div className="title-lft d-flex align-items-center">
                    <div className="dash-icn">
                      <i className="fas fa-bars"></i>
                    </div>
                    <div className="dash-title-dis">
                      <h5>
                        Child Menu <span>Listing</span>
                      </h5>
                      <h5>{/* Total User(s): <span>{response}</span> */}</h5>
                    </div>
                    {/* <div className="title-rt-btn d-flex align-items-center">
                      <Link className="green_button_admin mx-2" to="/admin/add-open-page-content">
                        Add Content
                      </Link>
                      <Link className="green_button_admin mx-2" to="/admin/add-menu-list">
                        Add Menu
                      </Link>
                    </div> */}
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <div className="dash-content-card">
            <div className="table-responsive">
              <Table className="custom-table-data" striped bordered hover>
                <thead>
                  <tr>
                    <th>Id</th>
                    <th>Name</th>
                    <th>Slug</th>
                    {/* <th>State</th> */}
                    <th>Created At</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {childMenuList?.length > 0 ? (
                    childMenuList?.map((menu, i) => {
                      return (
                        <tr key={i}>
                          <td>{menu?.menu_id}</td>
                          <td>{menu?.name}</td>
                          <td>{menu?.slug}</td>
                          {/* <td></td> */}
                          <td>{menu?.created_at}</td>
                          <td>
                            <Form.Select
                              onChange={(e) => {
                                handleChange(menu?.menu_id, e.target.value, menu);
                              }}>
                              <option value={''}>Select</option>
                              <option value={'1'}>Edit</option>
                              <option value={'0'}>Delete</option>
                            </Form.Select>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td className="text-center" colSpan={10}>
                        Data not found
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default ChidMenuList;
