/**
 * @copyright : ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
   @author     : Shiv Charan Panjeta 

  All Rights Reserved.
  Proprietary and confidential :  All information contained herein is, and remains
  the property of ToXSL Technologies Pvt. Ltd. and its partners.
  Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 */
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { useEffect, useState } from 'react';
import { Button, Container } from 'react-bootstrap';
import Alert from 'react-bootstrap/Alert';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import AsyncSelect from 'react-select/async';
import Loader from '../../components/commoncomponents/loader/loader-large';
import { imageExtension } from '../../globals/helper';
import * as adminServices from '../../services/AuthServices';
import showNotification from '../../services/NotificationService';

let intervalId;
export default function AdminPanelContentAdd() {
  const navigate = useNavigate();
  const location = useLocation();
  const [serachParam] = useSearchParams();
  const id = serachParam.get('id');
  const [detail, setDetail] = useState({
    institute_id: '',
    name: '',
    description: '',
    designation: '',
    vision_desc: '',
    sports_desc: '',
    expected_desc: '',
    notexpected_desc: '',
    bannerTitle: '',
    desc_one: '',
    desc_two: ''
  });
  const [value, setValue] = useState('');
  const [loading, setLoading] = useState(false);
  const [clicked, setClicked] = useState(false);
  const [attachment, setAttachMent] = useState('');
  const [previewImg, setPreviewImg] = useState([]);
  const [pickLayout, setPickLayout] = useState(1);
  const [singlefile, setSingleFile] = useState('');
  const [singlefilePreview, setSingleFilePreview] = useState('');
  const [bannerFile, setBannerFile] = useState();
  const [bannerPreview, setBannerPreview] = useState('');
  const [bannerFile1, setBannerFile1] = useState();
  const [showalert, setShowAlert] = useState(false);
  const [globalMsg, setGlobalMsg] = useState('');
  const roleType = useSelector((state) => state?.user?.details);

  useEffect(() => {
    if (id) {
      getDetail(id);
    }
  }, []);

  const getDetail = (id) => {
    setLoading(true);
    adminServices.getSinglePageDetail(id).then((res) => {
      setLoading(false);
      if (res?.status === 200) {
        const data = res?.data?.details ? res?.data?.details[0] : {};
        setDetail({
          ...detail,
          institute_id: data?.institute_id,
          name: data?.title,
          description: data?.description,
          designation: data?.designation,
          vision_desc: data?.desc_one,
          sports_desc: data?.desc_two,
          expected_desc: data?.expected_desc,
          notexpected_desc: data?.notexpected_desc,
          bannerTitle: data?.banner_title,
          desc_one: data?.desc_one_title,
          desc_two: data?.desc_two_title
        });
        setPickLayout(data?.type_id);
        if (data?.type_id == 1) {
          let arr = [];
          let temp = [];
          // attachments
          data?.multiple_image?.map((data) => {
            arr.push(data?.url);
            temp.push({ url: process.env.REACT_APP_API_DOMAIN + data?.url, id: data?.id });
          });
          setPreviewImg(temp);
          setAttachMent(arr);
        }
        if (data?.type_id != 1) {
          setSingleFile(data?.image_file);
        }
        setBannerFile(data?.banner_file);
        setBannerFile1(data?.banner_file);
        setValue({ label: data?.institute_name, value: data?.institute_id });
      }
    });
  };

  const onChangeHandler = (e, defaultName, data) => {
    if (
      defaultName == 'description' ||
      defaultName == 'vision_desc' ||
      defaultName == 'sports_desc' ||
      defaultName == 'notexpected_desc' ||
      defaultName == 'expected_desc'
    ) {
      setDetail({ ...detail, [defaultName]: data });
    } else {
      const { name, value } = e.target;
      setDetail({ ...detail, [name]: value });
    }
  };

  const validate = () => {
    let errors = {};

    if (detail?.description == '') {
      errors.description = 'This field is required';
    }
    if (pickLayout == 3 || pickLayout == 4) {
      if (!detail?.name.trim()) {
        errors.name = 'This field is required';
      }
    }
    if (pickLayout)
      if (!detail?.institute_id && !roleType?.institute_id) {
        errors.institute_id = 'This field is required';
      }
    return errors;
  };

  const submitHandler = (e) => {
    e.preventDefault();
    setClicked(true);
    if (!Object.keys(validate(detail)).length) {
      let body = {
        institute_id: roleType?.institute_id || detail?.institute_id,
        title: detail?.name,
        description: detail?.description,
        designation: detail?.designation,
        desc_one: detail?.vision_desc,
        desc_two: detail?.sports_desc,
        expected_desc: detail?.expected_desc,
        notexpected_desc: detail?.notexpected_desc,
        type_id: pickLayout,
        banner_title: detail?.bannerTitle,
        desc_one_title: detail?.desc_one,
        desc_two_title: detail?.desc_two
      };

      let formData = new FormData();
      for (let i in body) {
        formData.append(`${i}`, body[i]);
      }
      if (attachment || singlefile || bannerFile) {
        if (pickLayout == 1) {
          for (let i in attachment) {
            formData.append('multiple_image[]', attachment[i]);
          }
        } else {
          formData.append('image_file', singlefile);
        }
        formData.append('banner_file', bannerFile);
      }
      adminServices.addContent(formData).then((res) => {
        if (res?.status === 200) {
          showNotification('success', res?.data?.message);
          navigate(-1);
        }
      });
    }
  };

  const updateHandler = (e) => {
    e.preventDefault();
    setClicked(true);
    if (!Object.keys(validate(detail)).length) {
      let formData = new FormData();
      let body = {
        institute_id: detail?.institute_id,
        title: detail?.name,
        description: detail?.description,
        designation: detail?.designation,
        desc_one: detail?.vision_desc,
        desc_two: detail?.sports_desc,
        expected_desc: detail?.expected_desc,
        notexpected_desc: detail?.notexpected_desc,
        type_id: pickLayout,
        banner_title: detail?.bannerTitle,
        desc_one_title: detail?.desc_one,
        desc_two_title: detail?.desc_two
      };
      for (let i in body) {
        formData.append(`${i}`, body[i]);
      }
      if (attachment || singlefile || bannerFile || bannerFile1) {
        if (pickLayout == 1) {
          for (let i in attachment) {
            formData.append('multiple_image[]', attachment[i]);
          }
          formData.append('sport_image', bannerFile1);
        } else {
          formData.append('image_file', singlefile);
        }
        formData.append('banner_file', bannerFile);
      }
      adminServices.editContent(formData, id).then((res) => {
        if (res?.status === 200) {
          showNotification('success', res?.data?.message);
          navigate(-1);
        }
      });
    }
  };

  const loadInstituteOptions = (inputValue) => {
    return adminServices.getInstituteDrop(inputValue).then((res) => {
      if (res?.status === 200) {
        let tempArr = [];
        if (res?.data?.length) {
          const data = res?.data;
          data?.map((data) => {
            tempArr.push({
              label: data?.institutename,
              value: data?.institute_id
            });
          });
        }
        return tempArr;
      }
    });
  };

  const handleFile = (e) => {
    if (pickLayout != 1) {
      setSingleFile(e.target.files[0]);
      setSingleFilePreview(URL.createObjectURL(e.target.files[0]));
    } else {
      if (e.target.files.length > 4) {
        alert('You can only upload a maximum of 4 files');
        document.getElementById('multi_img').value = '';
      } else {
        for (let i = 0; i < e.target.files.length; i++) {
          if (imageExtension.includes(e.target.files[i]?.type)) {
            const arr = [];
            const targetfile = e?.target?.files;
            const targetfileobj = [...targetfile];
            targetfileobj.map((file) => {
              arr.push({ url: URL.createObjectURL(file), id: '' });
            });
            setAttachMent([...attachment, ...targetfileobj]);
            setPreviewImg([...previewImg, ...arr]);
          } else {
            showNotification('danger', 'Please upload valid extension');
          }
        }
      }
    }
  };

  /**
   * delete selected image >for about page
   */
  const deleteHandler = (e, id, index) => {
    e.preventDefault();
    let temp = false;
    if (confirm('Are you sure you want to delete this ?')) {
      let arr = [...previewImg];
      let tempArr = [...attachment];
      temp = true;
      if (id) {
        adminServices.deleteAboutImage(id).then((res) => {
          if (res?.status == 200) {
            setGlobalMsg(res?.data?.message);
            setShowAlert(true);
            alertHandler();
          }
        });
      }

      if (index > -1) {
        arr.splice(index, 1);
        tempArr.splice(index, 1);
        setPreviewImg(arr);
        setAttachMent(tempArr);
      }
      if (!arr?.length) {
        setPreviewImg('');
        setAttachMent('');
      }
    } else {
      return;
    }
  };

  /**
   * alert handler to show alert notification on deleting image
   */
  const alertHandler = () => {
    clearInterval(intervalId);
    intervalId = setInterval(function () {
      setShowAlert(false);
    }, 5000);
  };

  return (
    <>
      <div className="dash-main-content">
        <Container fluid>
          <div className="dash-page-content">
            <div className="image_radio_select mb-4">
              <Row>
                {id ? (
                  <></>
                ) : (
                  <>
                    <Col lg={3} md={4} sm={6}>
                      <Form.Control
                        type="radio"
                        id="first-img-selct"
                        name="inst_imgselect"
                        className="d-none"
                        value={pickLayout}
                        checked={pickLayout == 1}
                        onChange={() => setPickLayout(1)}
                      />
                      <Form.Label htmlFor="first-img-selct">
                        <img
                          src={require('../../assets/images/About.png')}
                          alt="imng"
                          className="w-100"
                        />
                      </Form.Label>
                    </Col>

                    <Col lg={3} md={4} sm={6}>
                      <Form.Control
                        type="radio"
                        id="second-img-selct"
                        name="inst_imgselect"
                        className="d-none"
                        value={pickLayout}
                        checked={pickLayout == 2}
                        onChange={() => setPickLayout(2)}
                      />
                      <Form.Label htmlFor="second-img-selct">
                        <img
                          src={require('../../assets/images/Networking.jpg')}
                          alt="imng"
                          className="w-100"
                        />
                      </Form.Label>
                    </Col>

                    <Col lg={3} md={4} sm={6}>
                      <Form.Control
                        type="radio"
                        id="third-img-selct"
                        name="inst_imgselect"
                        className="d-none"
                        value={pickLayout}
                        checked={pickLayout == 3}
                        onChange={() => setPickLayout(3)}
                      />
                      <Form.Label htmlFor="third-img-selct">
                        <img
                          src={require('../../assets/images/Principal Message.jpg')}
                          alt="imng"
                          className="w-100"
                        />
                      </Form.Label>
                    </Col>

                    <Col lg={3} md={4} sm={6}>
                      <Form.Control
                        type="radio"
                        id="forth-img-selct"
                        name="inst_imgselect"
                        className="d-none"
                        value={pickLayout}
                        checked={pickLayout == 4}
                        onChange={() => setPickLayout(4)}
                      />
                      <Form.Label htmlFor="forth-img-selct">
                        <img
                          src={require('../../assets/images/mentor.png')}
                          alt="imng"
                          className="w-100"
                        />
                      </Form.Label>
                    </Col>

                    <Col lg={3} md={4} sm={6}>
                      <Form.Control
                        type="radio"
                        id="img-selct"
                        name="inst_imgselect"
                        className="d-none"
                        value={pickLayout}
                        checked={pickLayout == 5}
                        onChange={() => {
                          setPickLayout(5);
                          navigate(`/admin/add-open-page-content`);
                        }}
                      />
                      <Form.Label htmlFor="img-selct" className="addbox">
                        <img
                          src={require('../../assets/images/plus-icon.png')}
                          alt="imng"
                          className="w-100"
                        />
                        {/* <i className="w-100" class="fas fa-plus"></i> */}
                      </Form.Label>
                    </Col>
                  </>
                )}
              </Row>
            </div>

            <div className="dash-page-title">
              <Row>
                <Col xs={12} sm={12} md={12}>
                  <div className="dash-title-bar d-flex align-items-center justify-content-between">
                    <div className="title-lft d-flex align-items-center">
                      <div className="dash-title-dis">
                        <h5>{id ? 'Edit Content' : 'Add Content'}</h5>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="dash-content-card">
              <Alert variant={'danger'} className={`${showalert ? '' : 'd-none'}`}>
                {globalMsg}
              </Alert>
              <div className="cmmn-form">
                <Form>
                  {roleType?.admin_type == '3' ? (
                    ''
                  ) : (
                    <Form.Group className="mb-3" controlId="formBasicName">
                      <Form.Label>
                        Select Institute <sup className="text-danger">*</sup>
                      </Form.Label>
                      <AsyncSelect
                        className="basic-single"
                        classNamePrefix="select"
                        isSearchable={true}
                        name="institute_id"
                        value={value}
                        loadOptions={loadInstituteOptions}
                        onChange={(e) => {
                          setValue(e), setDetail({ ...detail, ['institute_id']: e.value });
                        }}
                        placeholder="Search By Institute Name"
                      />
                      <div
                        className={`${
                          clicked && validate(detail)?.institute_id ? '' : 'd-none'
                        } danger-frm `}>
                        {clicked && validate(detail)?.institute_id}
                      </div>
                    </Form.Group>
                  )}

                  <Form.Group className="mb-3" controlId="formBasicState">
                    <Form.Label>Banner Title</Form.Label>
                    <Form.Control
                      type="text"
                      name="bannerTitle"
                      value={detail?.bannerTitle}
                      onChange={(e) => {
                        onChangeHandler(e);
                      }}
                      placeholder="Enter banner title"
                    />
                  </Form.Group>

                  <Form.Group controlId="formFile" className="attach-file-card mb-3">
                    <Form.Control
                      type="file"
                      name="image"
                      accept=".png,.jpg,.jpeg"
                      onChange={(e) => {
                        setBannerFile(e.target.files[0]);
                        setBannerPreview(URL.createObjectURL(e.target.files[0]));
                      }}
                    />
                    <span className="attach-file-inr">
                      <i className="fas fa-paperclip me-2"></i>
                      Attach Banner File
                    </span>
                    <div className={'danger-frm'}>{clicked && validate().attachment}</div>
                  </Form.Group>
                  {/* preview */}
                  <div className=" d-flex">
                    {id && bannerFile && !bannerPreview ? (
                      <img
                        height={'100px'}
                        width={'100px'}
                        src={process.env.REACT_APP_API_DOMAIN + bannerFile}
                        alt="img"
                      />
                    ) : bannerPreview ? (
                      <img
                        height={'100px'}
                        width={'100px'}
                        src={bannerPreview}
                        alt="img"
                        className="me-2"
                      />
                    ) : (
                      ''
                    )}
                  </div>

                  {(pickLayout == 3 || pickLayout == 4) && (
                    <Form.Group className="mb-3" controlId="formBasicState">
                      <Form.Label>
                        {pickLayout == 3 ? 'Name' : 'Title'}
                        <sup className="text-danger">*</sup>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="name"
                        value={detail?.name}
                        onChange={(e) => {
                          onChangeHandler(e);
                        }}
                        placeholder={pickLayout == 3 ? 'Enter Name' : 'Enter Title'}
                      />
                      <div
                        className={`${
                          clicked && validate(detail)?.name ? '' : 'd-none'
                        } danger-frm `}>
                        {clicked && validate(detail)?.name}
                      </div>
                    </Form.Group>
                  )}

                  <Form.Group className="mb-3" controlId="formBasicName">
                    <Form.Label>
                      Description<sup className="text-danger">*</sup>
                    </Form.Label>
                    {/* ck editor */}
                    <CKEditor
                      name="job_info"
                      editor={ClassicEditor}
                      // data={add?.job_desc}
                      data={detail?.description}
                      // onReady={(editor) => {
                      //   // You can store the "editor" and use when it is needed.
                      // }}
                      config={{
                        toolbar: [
                          'bold',
                          'italic',
                          'numberedList',
                          'bulletedList',
                          'outdent',
                          'indent',
                          'blockQuote'
                        ]
                      }}
                      onChange={(event, editor) => {
                        const data = editor.getData();
                        setDetail((previous) => ({
                          ...previous,
                          ['description']: data
                        }));
                      }}
                    />
                    {/* end */}
                    <div
                      className={`${
                        clicked && validate(detail)?.description ? '' : 'd-none'
                      } danger-frm `}>
                      {clicked && validate(detail)?.description}
                    </div>
                  </Form.Group>

                  <Form.Group controlId="formFile" className="attach-file-card">
                    <Form.Control
                      multiple={pickLayout != 1 ? false : true}
                      type="file"
                      name="image"
                      accept=".png,.jpg,.jpeg"
                      id="multi_img"
                      onChange={(e) => {
                        handleFile(e);
                      }}
                    />
                    <span className="attach-file-inr">
                      <i className="fas fa-paperclip me-2"></i>
                      Attach File(s)
                    </span>
                    <div className={'danger-frm'}>{clicked && validate().attachment}</div>
                  </Form.Group>
                  <Row>
                    {pickLayout == 1 ? (
                      id && previewImg ? (
                        previewImg?.map((data, index) => (
                          <div className="image-area mb-2" key={index}>
                            <img src={data?.url} alt="Preview" />
                            <a
                              className="remove-image"
                              onClick={(e) => {
                                deleteHandler(e, data?.id, index);
                              }}
                              href="#"
                              style={{ display: 'inline' }}>
                              &#215;
                            </a>
                          </div>
                        ))
                      ) : previewImg ? (
                        previewImg?.map((data, index) => {
                          return (
                            <div className="image-area mb-2" key={index}>
                              <img src={data?.url} alt="Preview" />
                              <a
                                className="remove-image"
                                onClick={(e) => {
                                  deleteHandler(e, data?.id, index);
                                }}
                                href="#"
                                style={{ display: 'inline' }}>
                                &#215;
                              </a>
                            </div>
                          );
                        })
                      ) : (
                        ''
                      )
                    ) : (
                      <div className=" d-flex">
                        {id && singlefile && !singlefilePreview ? (
                          <img
                            height={'100px'}
                            width={'100px'}
                            src={process.env.REACT_APP_API_DOMAIN + singlefile}
                            alt="img"
                          />
                        ) : singlefile ? (
                          <img
                            height={'100px'}
                            width={'100px'}
                            src={singlefilePreview}
                            alt="img"
                            className="me-2"
                          />
                        ) : (
                          ''
                        )}
                      </div>
                    )}
                  </Row>

                  {pickLayout == 1 && (
                    <>
                      {' '}
                      <Form.Group className="mb-3" controlId="formBasicState">
                        <Form.Label>Description One Title</Form.Label>
                        <Form.Control
                          type="text"
                          name="desc_one"
                          value={detail?.desc_one}
                          onChange={(e) => {
                            onChangeHandler(e);
                          }}
                          placeholder="Enter banner title"
                        />
                      </Form.Group>
                      <Form.Group className="mb-3" controlId="formBasicName">
                        <Form.Label>Description One</Form.Label>
                        {/* ck editor */}

                        <CKEditor
                          name="job_info"
                          editor={ClassicEditor}
                          data={detail?.vision_desc ? detail?.vision_desc : ''}
                          config={{
                            toolbar: [
                              'bold',
                              'italic',
                              'numberedList',
                              'bulletedList',
                              'outdent',
                              'indent',
                              'blockQuote'
                            ]
                          }}
                          onChange={(event, editor) => {
                            const data = editor.getData();
                            // onChangeHandler(event, 'vision_desc', data);
                            setDetail((previous) => ({
                              ...previous,
                              ['vision_desc']: data
                            }));
                          }}
                        />

                        {/* end */}
                      </Form.Group>
                      {/* check */}
                      <Form.Group controlId="formFile" className="attach-file-card mb-3">
                        <Form.Control
                          type="file"
                          name="image"
                          accept=".png,.jpg,.jpeg"
                          onChange={(e) => {
                            setBannerFile1(e.target.files[0]);
                          }}
                        />
                        <span className="attach-file-inr">
                          <i className="fas fa-paperclip me-2"></i>
                          Attach Image
                        </span>
                        <div className={'danger-frm'}>{clicked && validate().attachment}</div>
                      </Form.Group>
                      <Form.Group className="mb-3" controlId="formBasicState">
                        <Form.Label>Description Two Title</Form.Label>
                        <Form.Control
                          type="text"
                          name="desc_two"
                          value={detail?.desc_two}
                          onChange={(e) => {
                            onChangeHandler(e);
                          }}
                          placeholder="Enter banner title"
                        />
                      </Form.Group>
                      <Form.Group className="mb-3" controlId="formBasicName">
                        <Form.Label>Description Two</Form.Label>
                        {/* ck editor */}

                        <CKEditor
                          name="job_info"
                          editor={ClassicEditor}
                          data={detail?.sports_desc ? detail?.sports_desc : ''}
                          config={{
                            toolbar: [
                              'bold',
                              'italic',
                              'numberedList',
                              'bulletedList',
                              'outdent',
                              'indent',
                              'blockQuote'
                            ]
                          }}
                          onChange={(event, editor) => {
                            const data = editor.getData();
                            // onChangeHandler(event, 'sports_desc', data);
                            setDetail((previous) => ({
                              ...previous,
                              ['sports_desc']: data
                            }));
                          }}
                        />

                        {/* end */}
                      </Form.Group>
                    </>
                  )}

                  <div className="form-btns d-flex align-items-center mt-5">
                    {id ? (
                      <Button
                        className="main-btn-grn"
                        onClick={(e) => {
                          updateHandler(e);
                        }}>
                        Update
                      </Button>
                    ) : (
                      <Button
                        className="main-btn-grn"
                        onClick={(e) => {
                          submitHandler(e);
                        }}>
                        Save
                      </Button>
                    )}
                    <strong>OR</strong>
                    <Button
                      className="main-btn-red"
                      onClick={(e) => {
                        navigate(-1);
                      }}>
                      Cancel
                    </Button>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </Container>
      </div>
      {loading ? <Loader /> : ''}
    </>
  );
}
