import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Container } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { Button } from 'react-bootstrap';
import Header from '../../CommonComponents/Header';
import SideBar from '../../CommonComponents/Sidebar';
import Footer from '../../CommonComponents/Footer';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import { useState } from 'react';
import {
  downloadOrderInvoice,
  shopOrderDetail,
  shopSaveOrder
} from '../../../services/AuthServices';
import showNotification from '../../../services/NotificationService';
import DatePicker from 'react-datepicker';

export default function OrdersView() {
  const location = useLocation();
  const id = location.state;
  const [orderDetail, setOrderDetail] = useState('');
  const [orders, setOrders] = useState([]);
  const [mangeOrderStatus, setManageOrderStatus] = useState([]);
  const [orderForm, setOrderForm] = useState({
    tracking_id: '',
    tracking_url: '',
    shipped_through: '',
    order_status: '',
    message: '',
    deliveryDate: ''
  });
  const [clicked, setClicked] = useState(false);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setOrderForm({ ...orderForm, [name]: value });
  };

  useEffect(() => {
    if (id) {
      getDetail();
    }
  }, []);

  const getDetail = () => {
    shopOrderDetail(id).then((res) => {
      if (res?.status === 200) {
        const data = res?.data?.data?.orderInfo;
        const orderData = res?.data?.data?.orderItemInfo;
        const status = res?.data?.data?.ManageOrders;
        setOrderDetail(data);
        setOrders(orderData);
        setManageOrderStatus(status);
        setOrderForm({
          tracking_id: data?.tracking_id,
          tracking_url: data?.tracking_url,
          shipped_through: data?.shipped_through,
          order_status: data?.order_status,
          message: data?.message,
          deliveryDate: data?.deliveryDate ? new Date(data?.deliveryDate) : new Date()
        });
      }
    });
  };

  const validate = () => {
    let errors = {};
    if (orderForm?.tracking_id == '' || orderForm?.tracking_id == null) {
      errors.tracking_id = 'This field is required.';
    }
    if (orderForm?.tracking_url == '' || orderForm?.tracking_url == null) {
      errors.tracking_url = 'This field is required.';
    }
    if (orderForm?.shipped_through == '' || orderForm?.shipped_through == null) {
      errors.shipped_through = 'This field is required.';
    }
    if (orderForm?.deliveryDate == '' || orderForm?.deliveryDate == null) {
      errors.deliveryDate = 'This field is required.';
    }

    return errors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setClicked(true);

    if (!Object.keys(validate()).length) {
      let formData = new FormData();
      formData.append(`orderId`, id);
      formData.append(`tracking_id`, orderForm?.tracking_id);
      formData.append(`tracking_url`, orderForm?.tracking_url);
      formData.append(`shipped_through`, orderForm?.shipped_through);
      formData.append(`delivered_date`, orderForm?.deliveryDate);
      formData.append(`order_status`, orderForm?.order_status);
      formData.append(`message`, orderForm?.message);

      shopSaveOrder(formData).then((res) => {
        if (res?.status === 200) {
          showNotification('success', res?.data?.message);

          // dispatch(ShowAlert());
        }
      });
    }
  };
  // order api
  const handleDownloadInvoice = (e) => {
    e.preventDefault();
    downloadOrderInvoice(id).then((res) => {
      if (res?.status === 200) {
        showNotification('success', res?.data?.message);
      }
    });
  };

  return (
    <div className="dash-main-content">
      <Container fluid>
        <div className="dash-page-content">
          <div className="dash-page-title">
            <Row>
              <Col xs={12} sm={12} md={12}>
                <div className="dash-title-bar d-flex align-items-center justify-content-between">
                  <div className="title-lft d-flex align-items-center">
                    <div className="dash-title-dis">
                      <h5>
                        View Order Details{' '}
                        <Link className="text-dark" to="/admin/orders">
                          Back To Listing
                        </Link>
                      </h5>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <div className="dash-content-card">
            <div className="cmmn-form">
              <Row>
                <Col md={6}>
                  <p className="m-0">
                    <strong>Order No: </strong> #{orderDetail?.orderId} <strong>Email: </strong>{' '}
                  </p>
                  <p className="m-0">
                    <strong>Placed On</strong> {orderDetail?.orderOn}, Rs.
                    {orderDetail?.grand_total}
                  </p>
                </Col>
                <Col md={6}>
                  <Link className="text-dark" to="#" onClick={handleDownloadInvoice}>
                    {' '}
                    Download
                  </Link>
                </Col>

                <Col className="mt-3" md={12}>
                  <strong>Order items:</strong>
                  <table
                    className="view_order_table"
                    align="center"
                    width="100%"
                    border={1}
                    cellPadding={2}
                    cellSpacing={0}
                  >
                    <tbody>
                      <tr height={35}>
                        <th>Sr. No.</th>
                        <th>Product Information</th>
                        <th>Price</th>
                        <th>Quantity</th>
                        <th>Subtotal</th>
                      </tr>
                      {orders.length !== 0 ? (
                        orders?.map((item, index) => {
                          return (
                            <tr align="center" height={25} key={index}>
                              <td>{index + 1}</td>
                              <td>
                                <div className="col1">
                                  <Link to="#0">
                                    <img
                                      width={100}
                                      src={require('../../../assets/images/work-1.png')}
                                      alt="I Am The Pride Of The Gurukul - A5 Diary"
                                    />
                                  </Link>
                                  {item?.name} - {item?.code}
                                  <p>Color: Orange</p>{' '}
                                </div>
                              </td>
                              <td>Rs.{item?.price}</td>
                              <td>{item?.quantity}</td>
                              <td>Rs.{item?.price * item?.quantity}</td>
                            </tr>
                          );
                        })
                      ) : (
                        <>No orders added yet </>
                      )}
                    </tbody>
                  </table>
                </Col>

                <Col md={4} className="mt-3">
                  <strong>Billing Address: </strong>
                  <hr />
                  <br />
                  <strong>Landmark:</strong>
                  {orderDetail?.landmark}
                  <br />
                  <strong>Mobile:</strong>
                  {orderDetail?.mobile} <br />
                  <strong>Pincode:</strong> {orderDetail?.pincode}
                </Col>
                <Col md={4} className="mt-3">
                  <strong>Shipping Address: </strong>
                  <hr />
                  <br />
                  <strong>Landmark:</strong> {orderDetail?.ship_landmark}
                  <br />
                  <strong>Mobile:</strong>
                  {orderDetail?.ship_mobile} <br />
                  <strong>Pincode:</strong> {orderDetail?.ship_pincode}
                </Col>
                <Col md={4} className="mt-3">
                  <strong>Payment Information: </strong>
                  <hr />
                  <strong>Payment Method:</strong>
                  {orderDetail?.payment_method == 1
                    ? 'COD'
                    : 'Credit Card/Debit Card/NetBanking'}{' '}
                  <br />
                  <strong>Shipping Rate:</strong> Rs.{orderDetail?.shipping_rate}
                  <br />
                  <strong>Grand Total:</strong> Rs.{orderDetail?.grand_total}
                  <br />
                </Col>

                <Col md={6} className="mt-3">
                  <Form>
                    <Form.Group className="mb-3" controlId="formBasicEmail1">
                      <Form.Label>Tracking Id: </Form.Label>
                      <Form.Control
                        type="text"
                        name="tracking_id"
                        value={orderForm?.tracking_id}
                        onChange={handleChange}
                      />
                      <div className={'danger-frm'}>{clicked && validate().tracking_id}</div>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicEmail2">
                      <Form.Label>Tracking URL: </Form.Label>
                      <Form.Control
                        type="text"
                        name="tracking_url"
                        value={orderForm?.tracking_url}
                        onChange={handleChange}
                      />
                      <div className={'danger-frm'}>{clicked && validate().tracking_url}</div>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicEmail3">
                      <Form.Label>Shipped Through: </Form.Label>
                      <Form.Control
                        type="text"
                        name="shipped_through"
                        value={orderForm?.shipped_through}
                        onChange={handleChange}
                      />{' '}
                      <div className={'danger-frm'}>{clicked && validate().shipped_through}</div>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicEmail4">
                      <Form.Label>Delivered Date: </Form.Label>
                      {/* <Form.Control type="date" name="deliveryDate" onChange={handleChange} /> */}
                      <DatePicker
                        className="date-card form-control"
                        selected={orderForm?.deliveryDate}
                        onChange={(date) =>
                          setOrderForm({ ...orderForm, deliveryDate: date ? date : new Date() })
                        }
                        value={orderForm?.deliveryDate}
                        showTimeSelect
                        dateFormat="MM-dd-yyyy h:mm aa"
                      />
                      <div className={'danger-frm'}>{clicked && validate().deliveryDate}</div>
                    </Form.Group>
                  </Form>
                </Col>

                <Col md={6} className="mt-3">
                  <Form>
                    <Form.Group className="mb-3" controlId="formBasicEmail5">
                      <Form.Label>Change Order Status: </Form.Label>
                      <Form.Select
                        name="order_status"
                        onChange={handleChange}
                        value={orderForm?.order_status}
                      >
                        {mangeOrderStatus?.length !== 0 &&
                          mangeOrderStatus?.map((item, index) => {
                            return (
                              <option value={item?.id} key={index}>
                                {item?.order_full}
                              </option>
                            );
                          })}
                      </Form.Select>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicEmail6">
                      <Form.Label>Message: </Form.Label>
                      <Form.Control
                        type="text"
                        as="textarea"
                        name="message"
                        value={orderForm?.message}
                        onChange={handleChange}
                      />
                    </Form.Group>

                    <Button className="add-btn-grn" type="button" onClick={handleSubmit}>
                      Update
                    </Button>
                  </Form>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}
