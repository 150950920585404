/**
@copyright  :   ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
@author     :   Shiv Charan Panjeta 
 
All Rights Reserved.
Proprietary and confidential :  All information contained here in is, and remains
the property of ToXSL Technologies Pvt. Ltd. and it's partners.
Unauthorized copying of this file, via any medium is strictly prohibited.
*/

import { useEffect, useState } from 'react';
import { Button, Container } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { useLocation, useNavigate } from 'react-router-dom';
import AsyncSelect from 'react-select/async';
import CKEditorComponent from '../../components/webpages/user-pages/PostComponets/CKEditorComponent';
import useDocumentTitle from '../../globals/useDocumentTitle';
import {
  addJobFest,
  editJobFest,
  getJobFestDetail,
  getLocationDropDown
} from '../../services/AuthServices';
import showNotification from '../../services/NotificationService';

export default function AddJobFest() {
  useDocumentTitle('Add New Job Fest | AlmaBay');
  const [festDetail, setFestDetail] = useState({
    title: '',
    venue: '',
    max_job_limit: '',
    start_date: '',
    end_date: '',
    min_eligibility: '2013',
    reporting_time: '0:00'
  });
  const [banner, setBanner] = useState([]);
  const [locationId, setLocationId] = useState('');
  const [jobLocation, setJobLocation] = useState('');
  const [clicked, setClicked] = useState(false);
  const [jobDescription, setJobDescription] = useState('');
  const [bannerImages, setBannerEditImage] = useState([]);
  const [loadOpt, setLoadOpt] = useState(false);
  const [inputMessage, setInputMessage] = useState('Please enter atleast 2 characters');
  const navigate = useNavigate();
  const location = useLocation();
  let id = location?.state;

  useEffect(() => {
    if (id) {
      getDetail(id);
    }
  }, []);

  const getDetail = (id) => {
    getJobFestDetail(id).then((res) => {
      if (res?.status === 200) {
        const data = res?.data?.data?.details;
        setFestDetail({
          title: data?.title,
          venue: data?.venue,
          max_job_limit: data?.max_job_limit,
          start_date: data?.start_date,
          end_date: data?.end_date,
          min_eligibility: data?.min_eligibility,
          reporting_time: data?.reporting_time
        });
        setJobDescription(data?.details);
        setJobLocation({
          id: data?.city_id,
          label: data?.city,
          value: data?.city_id
        });
        setBannerEditImage(res?.data?.data?.images);
      }
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFestDetail({
      ...festDetail,
      [name]: value
    });
  };

  const validate = () => {
    let errors = {};
    if (festDetail?.title == '') {
      errors.title = 'Please enter title';
    }
    if (festDetail?.venue == '') {
      errors.venue = 'Please enter venue';
    }
    if (jobLocation == '') {
      errors.location = 'Please select location';
    }
    if (festDetail?.start_date == '') {
      errors.startDate = 'Please enter start date';
    }
    if (festDetail?.end_date == '') {
      errors.endDate = 'Please enter end date';
    }
    return errors;
  };

  const handleFile = (e) => {
    const targetfile = e?.target?.files;
    const targetfileobj = [...targetfile];
    setBanner(targetfileobj);
  };

  const handleSubmit = () => {
    setClicked(true);
    if (!Object.keys(validate()).length) {
      let formData = new FormData();
      formData.append(`title`, festDetail?.title);
      formData.append(`venue`, festDetail?.venue);
      formData.append(`details`, jobDescription);
      formData.append(`location`, jobLocation?.id);
      formData.append(`max_job_limit`, festDetail?.max_job_limit);
      formData.append(`start_date`, festDetail?.start_date);
      formData.append(`end_date`, festDetail?.end_date);
      formData.append(`min_eligibility`, festDetail?.min_eligibility);
      formData.append(`reporting_time`, festDetail?.reporting_time);
      if (banner?.length !== 0) {
        [...banner]?.map((data) => {
          if (!data?.type?.includes('image')) {
            return showNotification('danger', 'Invalid file type', 'ID');
          } else {
            // const targetfile = e?.target?.files;
            // const targetfileobj = [...targetfile];
            // setBanner(targetfileobj);
            for (let i in banner) {
              formData.append(`banner_image[]`, banner[i]);
            }
          }
        });
      }
      if (id) {
        editJobFest(id, formData).then((res) => {
          if (res?.status === 200) {
            showNotification('success', res?.data?.message);

            navigate('/admin/jobfest');
            setFestDetail({
              title: '',
              venue: '',
              details: '',
              max_job_limit: '',
              start_date: '',
              end_date: '',
              min_eligibility: '',
              reporting_time: ''
            });
          }
        });
      } else {
        addJobFest(formData).then((res) => {
          if (res?.status === 200) {
            showNotification('success', res?.data?.message);

            navigate('/admin/jobfest');
            setFestDetail({
              title: '',
              venue: '',
              details: '',
              max_job_limit: '',
              start_date: '',
              end_date: '',
              min_eligibility: '',
              reporting_time: ''
            });
          }
        });
      }
    }
  };
  /* eslint-disable */

  const loadCityOpt = async (inputValue) => {
    if (inputValue?.length >= 2) {
      setLoadOpt(true);
      return getLocationDropDown(inputValue).then((res) => {
        setLoadOpt(false);
        if (res?.status === 200) {
          let tempArr = [];
          if (res?.data?.data) {
            const data = res?.data?.data;
            data?.map((data) => {
              tempArr.push({
                id: data?.id,
                label: data?.location,
                value: data?.location
              });
            });
          }

          if (res?.data?.data == '') {
            setInputMessage('No matches found ');
          }
          return tempArr;
        }
      });
    }
  };

  const inputMessageHandler = (e) => {
    if (e?.length !== 2 && e?.length < 2) {
      setInputMessage('Please enter atleast 2 characters.');
    }
  };

  return (
    <div className="dash-main-content">
      <Container fluid>
        <div className="dash-page-content">
          <div className="dash-page-title">
            <Row>
              <Col xs={12} sm={12} md={12}>
                <div className="dash-title-bar d-flex align-items-center justify-content-between">
                  <div className="title-lft d-flex align-items-center">
                    <div className="dash-icn">
                      <i className="fas fa-briefcase"></i>
                    </div>
                    <div className="dash-title-dis">
                      <h5>{id ? 'Edit Job Fest' : 'Add Job Fest'}</h5>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <div className="dash-content-card">
            <div className="cmmn-form">
              <Form>
                <Row>
                  <Col md={12}>
                    <Form.Group className="mb-3" controlId="formBasicName">
                      <Form.Label>
                        Title<span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Use complete words."
                        name="title"
                        onChange={handleChange}
                        value={festDetail?.title}
                      />
                      <div className={'danger-frm px-0'}>{clicked && validate().title}</div>
                    </Form.Group>
                  </Col>

                  <Col md={12}>
                    <Form.Group className="mb-3" controlId="formBasicName">
                      <Form.Label>
                        Venue<span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Venue"
                        name="venue"
                        onChange={handleChange}
                        value={festDetail?.venue}
                      />
                      <div className={'danger-frm px-0'}>{clicked && validate().venue}</div>
                    </Form.Group>
                  </Col>

                  <Col md={12}>
                    <Form.Group className="mb-3" controlId="formBasicDesi">
                      <Form.Label>Details </Form.Label>
                      <CKEditorComponent
                        isToolBar={true}
                        setLatestPost={setJobDescription}
                        latestPost={jobDescription}
                      />
                    </Form.Group>
                  </Col>

                  <Col md={12}>
                    <Form.Group className="mb-3" controlId="formBasicDesi">
                      <Form.Label>
                        Location
                        <span className="text-danger">*</span>
                      </Form.Label>
                      {/* <AsyncSelect
                        isClearable
                        cacheOptions
                        defaultOptions
                        value={jobLocation}
                        menuIsOpen={locationSearch !== '' ? true : false}
                        getOptionLabel={(e) => e.label}
                        getOptionValue={(e) => e.value}
                        loadOptions={loadJObLocationOption}
                        onInputChange={(newValue) => setLocationSearch(newValue)}
                        className="basic-single"
                        name="location"
                        onChange={setJobLocation}
                      /> */}
                      <AsyncSelect
                        isClearable={true}
                        getOptionLabel={(e) => e.label}
                        getOptionValue={(e) => e.value}
                        loadOptions={loadCityOpt}
                        className="basic-single mt-1"
                        name="city"
                        placeholder="Enter your Current Location"
                        onChange={(e) => {
                          // setDetail({ ...detail, ['city']: e?.id });
                          setLocationId(e);
                          setJobLocation(e)
                        }}
                        value={jobLocation}
                        components={{
                          DropdownIndicator: () => null,
                          IndicatorSeparator: () => null,
                          LoadingIndicator: () => null
                        }}
                        onInputChange={(e) => {
                          inputMessageHandler(e);
                        }}
                        noOptionsMessage={() => inputMessage}
                      />
                      {loadOpt && (
                        <div
                          className="d-flex"
                          style={{ justifyContent: 'flex-end', marginTop: '-20px' }}>
                          <i className="fa fa-spinner fa-pulse me-2"></i>
                        </div>
                      )}
                      <div className={'danger-frm px-0'}>{clicked && validate().location}</div>
                    </Form.Group>
                  </Col>

                  <Col md={12}>
                    <Form.Group className="mb-3" controlId="formBasicDesi">
                      <Form.Label>Maximum job limit</Form.Label>
                      <Form.Select
                        aria-label="Default select example"
                        name="max_job_limit"
                        onChange={handleChange}
                        value={festDetail?.max_job_limit}>
                        <option>Maximum job limit</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="6">6</option>
                        <option value="7">7</option>
                        <option value="8">8</option>
                        <option value="9">9</option>
                        <option value="10">10</option>
                        <option value="11">11</option>
                        <option value="12">12</option>
                        <option value="13">13</option>
                        <option value="14">14</option>
                        <option value="15">15</option>
                        <option value="16">16</option>
                        <option value="17">17</option>
                        <option value="18">18</option>
                        <option value="19">19</option>
                        <option value="20">20</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>

                  <Col md={12}>
                    <Form.Group className="mb-3 " controlId="formBasicName">
                      <Form.Label>
                        Start Date <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="date"
                        placeholder="Enter Start Date"
                        name="start_date"
                        id="start-date"
                        onChange={handleChange}
                        value={festDetail?.start_date}
                        min={new Date().toISOString()?.split('T')[0]}
                        max={festDetail?.end_date}
                        onKeyDown={(e) => e.preventDefault()}
                      />
                      <label htmlFor="start-date">
                        {festDetail?.start_date ? festDetail?.start_date : 'Enter start date'}
                      </label>
                      <div className={'danger-frm px-0'}>{clicked && validate()?.startDate}</div>
                    </Form.Group>
                  </Col>

                  <Col md={12}>
                    <Form.Group className="mb-3 " controlId="formBasicName">
                      {/* job-festdate to make complete input clickable */}
                      <Form.Label>
                        End Date <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="date"
                        placeholder="Enter End Date"
                        name="end_date"
                        onChange={handleChange}
                        value={festDetail?.end_date}
                        min={festDetail?.start_date}
                        onKeyDown={(e) => e.preventDefault()}
                      />
                      <label htmlFor="start-date">
                        {festDetail?.end_date ? festDetail?.end_date : 'Enter end date'}
                      </label>
                      <div className={'danger-frm px-0'}>{clicked && validate()?.endDate}</div>
                    </Form.Group>
                  </Col>

                  <Col md={12}>
                    <Form.Group className="mb-3" controlId="formBasicDesi">
                      <Form.Label>Minimum Eligibility (Passout Year)</Form.Label>
                      <Form.Select
                        aria-label="Default select example"
                        name="min_eligibility"
                        onChange={handleChange}
                        value={festDetail?.min_eligibility}>
                        <option value="2013">2013</option>
                        <option value="2014">2014</option>
                        <option value="2015">2015</option>
                        <option value="2016">2016</option>
                        <option value="2017">2017</option>
                        <option value="2018">2018</option>
                        <option value="2019">2019</option>
                        <option value="2020">2020</option>
                        <option value="2021">2021</option>
                        <option value="2022">2022</option>
                        <option value="2023">2023</option>
                        <option value="2024">2024</option>
                        <option value="2025">2025</option>
                        <option value="2026">2026</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>

                  <Col md={12}>
                    <Form.Group className="mb-3" controlId="formBasicDesi">
                      <Form.Label>Reporting Time</Form.Label>
                      <Form.Select
                        aria-label="Default select example"
                        name="reporting_time"
                        onChange={handleChange}
                        value={festDetail?.reporting_time}>
                        <option value="0:00">12:00AM</option>
                        <option value="0:30">12:30AM</option>
                        <option value="1:00">1:00AM</option>
                        <option value="1:30">1:30AM</option>
                        <option value="2:00">2:00AM</option>
                        <option value="2:30">2:30AM</option>
                        <option value="3:00">3:00AM</option>
                        <option value="3:30">3:30AM</option>
                        <option value="4:00">4:00AM</option>
                        <option value="4:30">4:30AM</option>
                        <option value="5:00">5:00AM</option>
                        <option value="5:30">5:30AM</option>
                        <option value="6:00">6:00AM</option>
                        <option value="6:30">6:30AM</option>
                        <option value="7:00">7:00AM</option>
                        <option value="7:30">7:30AM</option>
                        <option value="8:00">8:00AM</option>
                        <option value="8:30">8:30AM</option>
                        <option value="9:00">9:00AM</option>
                        <option value="9:30">9:30AM</option>
                        <option value="10:00">10:00AM</option>
                        <option value="10:30">10:30AM</option>
                        <option value="11:00">11:00AM</option>
                        <option value="11:30">11:30AM</option>
                        <option value="12:00">12:00PM</option>
                        <option value="12:30">12:30PM</option>
                        <option value="13:00">1:00PM</option>
                        <option value="13:30">1:30PM</option>
                        <option value="14:00">2:00PM</option>
                        <option value="14:30">2:30PM</option>
                        <option value="15:00">3:00PM</option>
                        <option value="15:30">3:30PM</option>
                        <option value="16:00">4:00PM</option>
                        <option value="16:30">4:30PM</option>
                        <option value="17:00">5:00PM</option>
                        <option value="17:30">5:30PM</option>
                        <option value="18:00">6:00PM</option>
                        <option value="18:30">6:30PM</option>
                        <option value="19:00">7:00PM</option>
                        <option value="19:30">7:30PM</option>
                        <option value="20:00">8:00PM</option>
                        <option value="20:30">8:30PM</option>
                        <option value="21:00">9:00PM</option>
                        <option value="21:30">9:30PM</option>
                        <option value="22:00">10:00PM</option>
                        <option value="22:30">10:30PM</option>
                        <option value="23:00">11:00PM</option>
                        <option value="23:30">11:30PM</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>

                  <Col md={12}>
                    <Form.Group controlId="formFileMultiple" className="mb-3">
                      <Form.Label>Banner</Form.Label>
                      <Form.Control type="file" accept="image/*" multiple onChange={handleFile} />
                    </Form.Group>
                  </Col>
                </Row>
                {id && bannerImages?.length !== 0 && (
                  <div className="d-flex flex-wrap align-item-center">
                    {bannerImages?.map((item, index) => {
                      return (
                        <div className="border me-2" key={index}>
                          <img
                            width="100px"
                            height="100px"
                            src={process.env.REACT_APP_API_DOMAIN + item}
                            alt="img1"
                          />
                        </div>
                      );
                    })}
                  </div>
                )}

                <div className="form-btns d-flex align-items-center mt-3">
                  <Button className="main-btn-grn" type="button" onClick={handleSubmit}>
                    Save
                  </Button>
                  <strong>OR</strong>
                  <Button className="main-btn-red" onClick={() => navigate('/admin/jobfest')}>
                    Cancel
                  </Button>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}
