import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { Alert, Col, Dropdown, Row } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import Button from 'react-bootstrap/Button';
import CloseButton from 'react-bootstrap/CloseButton';

import setMinutes from 'date-fns/setMinutes';
import setHours from 'date-fns/setHours';
import { useEffect, useState } from 'react';
import { filterPassedTime, loadEventCategoryOptions } from '../../../../globals/helper';
import AsyncSelect from 'react-select/async';
import { addEvents } from '../../../../services/SocialServices';
import { getLocationDropDown } from '../../../../services/AuthServices';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';

function CreateEventModal(props) {
  const { handleClose2, show, show2, handleClose, renderFunction } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [inputMsg, setinputMsg] = useState('Please enter atleast 2 characters.');
  const [messageView, setMessageView] = useState('');
  const [showalert, setShowAlert] = useState(false);
  const [eventDetail, setEventDetail] = useState({
    image: '',
    name: '',
    decp: '',
    venue: '',
    city: '',
    category_id: '',
    from_event_date: '',
    to_event_date: '',
    event_privacy: '',
    invite_friends: false
  });
  const [showEndDate, setShowEndDate] = useState(false);
  const [clicked, setClicked] = useState(false);
  const [load, setLoad] = useState(false);
  let navigate = useNavigate();
  const handleChange = (e, defaultName) => {
    if (defaultName) {
      if (
        defaultName == 'event_privacy' ||
        defaultName == 'from_event_date' ||
        defaultName == 'to_event_date'
      ) {
        setEventDetail({
          ...eventDetail,
          [defaultName]: e
        });
      }
      if (defaultName == 'category_id') {
        setEventDetail({
          ...eventDetail,
          [defaultName]: e?.id
        });
      }
      if (defaultName == 'city') {
        setEventDetail({
          ...eventDetail,
          [defaultName]: e?.id
        });
      }
    } else {
      var { name, value, type, checked } = e.target;
      if (type === 'file') {
        let file = e.target.files[0];
        setEventDetail({
          ...eventDetail,
          [name]: file
        });
      } else {
        if (name == 'invite_friends') {
          setEventDetail({
            ...eventDetail,
            [name]: checked
          });
        } else {
          setEventDetail({
            ...eventDetail,
            [name]: value
          });
        }
      }
    }
  };

  const validate = () => {
    let errors = {};
    if (eventDetail?.name == '') {
      errors.name = 'This field is required';
    }
    if (eventDetail?.category_id == '') {
      errors.category_id = 'This field is required';
    }
    if (eventDetail?.venue == '') {
      errors.venue = 'This field is required';
    }
    if (eventDetail?.city == '') {
      errors.city = 'This field is required';
    }
    if (eventDetail?.from_event_date == '') {
      errors.from_event_date = 'This field is required';
    } else if (eventDetail?.from_event_date.getTime() < new Date().getTime()) {
      errors.from_event_date = 'Please select valid time';
    }
    if (showEndDate === true) {
      if (showEndDate === true && eventDetail?.to_event_date == '') {
        errors.to_event_date = 'This field is required';
      } else if (eventDetail?.from_event_date >= eventDetail?.to_event_date) {
        errors.to_event_date = 'End-date should not be greater or equal to start-date';
      }
    }

    if (eventDetail?.decp == '') {
      errors.decp = 'This field is required';
    }
    return errors;
  };

  useEffect(() => {
    const timeId = setTimeout(() => {
      // After 3 seconds set the show value to false
      setShowAlert(false);
      setMessageView('');
    }, 3000);

    return () => {
      clearTimeout(timeId);
    };
  }, [showalert]);

  const submitHandler = (e) => {
    e.preventDefault();
    setClicked(true);
    if (!Object.keys(validate()).length) {
      setIsLoading(true);
      let formData = new FormData();
      let body = {
        image: eventDetail?.image,
        name: eventDetail?.name,
        decp: eventDetail?.decp,
        venue: eventDetail?.venue,
        city: eventDetail?.city,
        category_id: eventDetail?.category_id,
        event_privacy: eventDetail?.event_privacy == 0 ? 'public' : 'private',
        from_event_date: moment(eventDetail?.from_event_date).format('YYYY-MM-DD HH:mm:ss'),
        to_event_date:
          eventDetail?.to_event_date !== ''
            ? moment(eventDetail?.to_event_date).format('YYYY-MM-DD HH:mm:ss')
            : '',
        invite_friends: eventDetail?.invite_friends
      };

      for (let i in body) {
        formData.append(`${i}`, body[i]);
      }
      addEvents(formData).then((resp) => {
        if (resp?.status === 200) {
          renderFunction();
          setShowAlert(true);
          setMessageView(resp?.data?.data?.message);
          setTimeout(() => {
            navigate(`/user/events-detail/${resp?.data?.data?.data}/overview`);
          }, 2000);
          setIsLoading(false);
        }
      });
    }
  };

  const loadCityOpt = async (inputValue) => {
    if (inputValue?.length == 2 || inputValue?.length > 2) {
      setinputMsg('searching');
      setLoad(true);
      return getLocationDropDown(inputValue).then((res) => {
        setLoad(false);
        if (res?.status === 200) {
          let tempArr = [];
          if (res?.data?.data) {
            const data = res?.data?.data;
            data?.map((data) => {
              tempArr.push({
                id: data?.id,
                label: data?.location,
                value: data?.location
              });
            });
          }
          if (res?.data?.data == '') {
            setinputMsg('No matches found');
          }
          return tempArr;
        }
      });
    }
  };
  const inputMessageHandler = (e) => {
    if (e?.length !== 2 && e?.length < 2) {
      setinputMsg('Please enter atleast 2 characters.');
    }
  };

  return (
    <>
      <Modal show={show2} onHide={handleClose2} className="event-add-modal" size="">
        <Modal.Body>
          <>
            <Alert
              variant={messageView ? 'success' : 'danger'}
              className={`${showalert ? '' : 'd-none'}`}>
              {messageView ? messageView : 'No Update'}
            </Alert>
            <div className="event-modal-hd d-flex align-items-center justify-content-between ">
              <div className="event-modal-drop modal-dialog-scrollable">
                <Dropdown onSelect={(e) => handleChange(e, 'event_privacy')}>
                  {eventDetail?.event_privacy == 0 ? (
                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                      <i className="fas fa-globe-europe me-2"></i>
                      Create Public Event
                    </Dropdown.Toggle>
                  ) : (
                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                      <i className="fas fa-envelope me-2"></i>
                      Create Private Event
                    </Dropdown.Toggle>
                  )}

                  <Dropdown.Menu>
                    <Dropdown.Item eventKey={0}>
                      <div className="event-public d-flex align-items-top">
                        <i className="fas fa-globe-europe me-3"></i>
                        <div className="event-pub-dis">
                          <h4>Public Event</h4>
                          <p>
                            Anyone will be able to see your event and search for it, even if you
                            aren't friends
                          </p>
                        </div>
                      </div>
                    </Dropdown.Item>
                    <Dropdown.Item eventKey={1}>
                      <div className="event-public d-flex align-items-top">
                        <i className="fas fa-envelope me-3"></i>
                        <div className="event-pub-dis">
                          <h4>Private Event</h4>
                          <p>
                            Only invited guests will see your event. You can choose to let guests
                            invite friends
                          </p>
                        </div>
                      </div>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              <div className="event-close">
                <CloseButton onClick={handleClose2} />
              </div>
            </div>
            <div className="event-text-hd">
              {eventDetail?.event_privacy == 0 ? (
                <p>
                  Anyone will be able to see your event and search for it, even if you aren't
                  friends
                </p>
              ) : (
                <p>
                  Only invited guests will see your event. You can choose to let guests invite
                  friends
                </p>
              )}
            </div>
            <Form>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label className="fw-bold text-black">Event Photo</Form.Label>
                <div className="event-pic">
                  <Form.Control
                    type="file"
                    placeholder="Upload Photo"
                    name="image"
                    accept="image/*"
                    onChange={handleChange}
                  />
                  <span className="camera-icn">
                    <i className="fas fa-camera"></i>
                  </span>
                </div>
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label className="fw-bold text-black">Event Category</Form.Label>
                <sup className="text-danger">*</sup>
                <AsyncSelect
                  cacheOptions
                  defaultOptions
                  getOptionLabel={(e) => e.label}
                  getOptionValue={(e) => e.value}
                  loadOptions={loadEventCategoryOptions}
                  className="basic-single mt-1"
                  placeholder="Select Event Category"
                  onChange={(e) => handleChange(e, 'category_id')}
                />
                <div className={'danger-frm'}>{clicked && validate().category_id}</div>
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicEvent">
                <Form.Label className="fw-bold text-black">Event Name</Form.Label>
                <sup className="text-danger">*</sup>
                <Form.Control
                  type="text"
                  placeholder="Add a short, clear name"
                  name="name"
                  onChange={handleChange}
                />
                <div className={'danger-frm'}>{clicked && validate().name}</div>
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label className="fw-bold text-black">Event Venue</Form.Label>
                <sup className="text-danger">*</sup>
                <Form.Control
                  type="text"
                  placeholder="Include a place or address"
                  name="venue"
                  onChange={handleChange}
                />
                <div className={'danger-frm'}>{clicked && validate().venue}</div>
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label className="fw-bold text-black">Event City</Form.Label>
                <sup className="text-danger">*</sup>
                <div className="spinner-wrapper">
                  <AsyncSelect
                    cacheOptions
                    // defaultOptions
                    getOptionLabel={(e) => e.label}
                    getOptionValue={(e) => e.value}
                    loadOptions={loadCityOpt}
                    className="basic-single mt-1"
                    placeholder="Select Event City"
                    onChange={(e) => handleChange(e, 'city')}
                    noOptionsMessage={() => inputMsg}
                    onInputChange={(e) => inputMessageHandler(e)}
                    components={{
                      DropdownIndicator: () => null,
                      IndicatorSeparator: () => null,
                      LoadingIndicator: () => null
                    }}
                  />
                </div>
                {load && (
                  <div
                    style={{
                      justifyContent: 'flex-end',
                      display: 'flex',
                      marginTop: '-24px',
                      marginRight: '5px'
                    }}>
                    <i className="fa fa-spinner fa-pulse me-2"></i>
                  </div>
                )}
                <div className={'danger-frm'}>{clicked && validate().city}</div>
              </Form.Group>
              <Row className="d-flex align-items-end">
                <Col md={8}>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label className="fw-bold text-black">Start Date-Time</Form.Label>
                    <sup className="text-danger">*</sup>
                    <div className="d-flex align-items-center">
                      <DatePicker
                        placeholderText="Select Start Date-Time"
                        className="date-card"
                        selected={eventDetail?.from_event_date}
                        onChange={(e) => handleChange(e, 'from_event_date')}
                        showTimeSelect
                        // excludeTimes={[
                        //   setHours(setMinutes(new Date(), 0), 17),
                        //   setHours(setMinutes(new Date(), 30), 18),
                        //   setHours(setMinutes(new Date(), 30), 19),
                        //   setHours(setMinutes(new Date(), 30), 17)
                        // ]}
                        filterTime={filterPassedTime}
                        minDate={new Date()}
                        dateFormat="MM-dd-yyyy h:mm aa"
                      />
                    </div>
                    <div className={'danger-frm'}>{clicked && validate().from_event_date}</div>
                  </Form.Group>
                </Col>
                <Col md={4}>
                  <Button className="end-date" onClick={() => setShowEndDate(!showEndDate)}>
                    {showEndDate ? '-' : '+'} End Date-Time
                  </Button>
                </Col>
              </Row>
              {showEndDate && (
                <Row className="d-flex align-items-bottom">
                  <Col md={8}>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label className="fw-bold text-black">End Date-Time</Form.Label>
                      <sup className="text-danger">*</sup>
                      <div className="d-flex">
                        <DatePicker
                          placeholderText="Select End Date-Time"
                          className="date-card"
                          selected={eventDetail?.to_event_date}
                          onChange={(e) => handleChange(e, 'to_event_date')}
                          showTimeSelect
                          filterTime={filterPassedTime}
                          minDate={eventDetail?.from_event_date}
                          dateFormat="MM-dd-yyyy h:mm aa"
                        />
                      </div>
                      <div className={'danger-frm'}>{clicked && validate().to_event_date}</div>
                    </Form.Group>
                  </Col>
                </Row>
              )}

              <Form.Group className="mb-3" controlId="formBasicDiscription">
                <Form.Label className="fw-bold text-black">Description</Form.Label>
                <sup className="text-danger">*</sup>
                <Form.Control
                  as="textarea"
                  placeholder="Tell people more about the event"
                  rows={3}
                  name="decp"
                  onChange={handleChange}
                />
                <div className={'danger-frm'}>{clicked && validate().decp}</div>
              </Form.Group>
              {eventDetail?.event_privacy == 1 && (
                <Form.Group className="mb-3" controlId="formBasicCheckbox">
                  <Form.Check
                    type="checkbox"
                    label="Guests can invite friends"
                    value={eventDetail?.invite_friends}
                    name="invite_friends"
                    onChange={handleChange}
                  />
                </Form.Group>
              )}
            </Form>
          </>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={submitHandler}>
            {isLoading ? (
              <span>
                <i className="fa fa-spinner fa-spin me-2"></i>Create Event
              </span>
            ) : (
              'Create Event'
            )}
          </Button>

          <Button variant="secondary" onClick={handleClose2}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default CreateEventModal;
