import moment from 'moment';
import { useEffect, useState } from 'react';
import { Col, Container, Modal, Row } from 'react-bootstrap';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { FreeMode, Navigation, Thumbs } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { getClubMediaData } from '../../../services/InstituteServices';

function ImageDetailView() {
    const [show, setShow] = useState(false);
    const [preview, setPreview] = useState('');
    const [thumbsSwiper] = useState(null);
    const handleClose = () => setShow(false);

    const navigate = useNavigate();
    const { id } = useParams();
    const [list, setList] = useState([]);

    useEffect(() => {
        getData(id);
    }, []);

    const handleShow = (e, data) => {
        e.preventDefault();
        setPreview(data);
        setShow(true);
    };

    const getData = (id) => {
        //api call
        getClubMediaData(id).then((res) => {
            if (res?.status === 200) {
                setList(res?.data?.data || []);
            }
        });
    };

    return (
        <div className="wrappermain_outer">
            <section className="gallery_view_section">
                <Container>
                    <Row>
                        <Col sm={12}>
                            <button
                                className="btn btn-back mb-4"
                                onClick={() => {
                                    navigate(-1);
                                }}
                            >
                                Back
                            </button>
                            <h2 className="gallery-view-heading text-center">
                                {list?.album_details?.title || ''}
                            </h2>
                            <p className="text-muted text-center">
                                Photos: <span>{list?.list?.length || 0}</span> &nbsp;{' '}
                                {list?.album_details?.location ? (
                                    <>
                                        Location: <span>{list?.album_details?.location}</span>
                                    </>
                                ) : (
                                    <></>
                                )}{' '}
                                &nbsp; Date:{' '}
                                <span>{moment(list?.album_details?.created).format('DD MMM YYYY')}</span>
                            </p>
                        </Col>
                    </Row>

                    <Row className="five-colmn">
                        {list?.list && list?.list?.length ? (
                            list?.list?.map((data, index) => (
                                <Col key={index}>
                                    <Link
                                        to="#"
                                        className="d-block"
                                        onClick={(e) => {
                                            handleShow(e, data);
                                        }}
                                    >
                                        <img src={data?.url} alt="wonder" />
                                    </Link>
                                </Col>
                            ))
                        ) : (
                            <>No Data Found</>
                        )}
                    </Row>
                </Container>
            </section>
            {/* modal */}
            <Modal show={show} onHide={handleClose} className="gallery_modal">
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                    <div className="image-view mb-4">
                        <Swiper
                            style={{
                                '--swiper-navigation-color': '#fff',
                                '--swiper-pagination-color': '#fff'
                            }}
                            spaceBetween={10}
                            navigation={true}
                            thumbs={{
                                swiper: thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null
                            }}
                            modules={[FreeMode, Navigation, Thumbs]}
                            className="mySwiper2"
                        >
                            {/* <SwiperSlide>
                                <a
                                    data-fancybox="gallery"
                                    href='#'
                                    className='text-center'>
                                    <img
                                        src={process.env.REACT_APP_API_URL + preview?.url + "." + preview?.extension}
                                        alt="img"
                                    />
                                    <p className='text-black'>{preview?.title}</p>
                                </a>
                            </SwiperSlide> */}
                            <SwiperSlide>
                                <a data-fancybox="gallery" href="#" className="text-center">
                                    <img src={preview?.url} alt="img" />
                                    <p className="text-black">{preview?.title || ''}</p>
                                </a>
                            </SwiperSlide>
                        </Swiper>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
}

export default ImageDetailView;
