/** @copyright : ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
 @author     : Shiv Charan Panjeta 

 All Rights Reserved.
 Proprietary and confidential :  All information contained herein is, and remains
 the property of ToXSL Technologies Pvt. Ltd. and its partners.
 Unauthorized copying of this file, via any medium is strictly prohibited.
*/
import { Route, Routes } from 'react-router-dom';
import AboutClub from '../../components/webpages/club-pages/About.jsx';
import ClubBlog from '../../components/webpages/club-pages/Blogs.jsx';
import ClubBlogDetail from '../../components/webpages/club-pages/ClubBlogDetail.jsx';
import ClubGallery from '../../components/webpages/club-pages/ClubGallery.jsx';
import ContactUs from '../../components/webpages/club-pages/ContactUs.jsx';
import ClubHome from '../../components/webpages/club-pages/Home.jsx';
import ImageDetailView from '../../components/webpages/club-pages/ImageDetailView.jsx';
import ClubMembers from '../../components/webpages/club-pages/Members.jsx';
import ClubNews from '../../components/webpages/club-pages/News.jsx';
import ClubNewsDetail from '../../components/webpages/club-pages/NewsDetail.jsx';
import VideoDetailView from '../../components/webpages/club-pages/VideoDetailView.jsx';
import PageNotFound from '../../components/webpages/institute-pages/PageNotFound';
import PageNotfound from '../../components/webpages/user-pages/PageNotfound';

function ClubRoutes() {
  return (
    <>
      <Routes>
        <Route path="/contact" element={<ContactUs />} />
        <Route path="/about" element={<AboutClub />} />
        <Route path="/blogs" element={<ClubBlog />} />
        <Route path="/news" element={<ClubNews />} />
        <Route path="/members" element={<ClubMembers />} />
        {/* <Route path="/events" element={<ClubEvents />} /> */}
        {/* <Route path="/event-detail/:id" element={<ClubEventDetail />} /> */}
        <Route path="/blog-details/:id" element={<ClubBlogDetail />} />
        <Route path="/news-detail/:id" element={<ClubNewsDetail />} />
        <Route path="/home" element={<ClubHome />} />
        <Route path="/gallery" element={<ClubGallery />} />
        <Route path="/gallery-view/:id" element={<ImageDetailView />} />
        <Route path="/videos-view/:id" element={<VideoDetailView />} />
        <Route path="/error-page" element={<PageNotFound />} />
        <Route path="/*" element={<PageNotfound />} />
      </Routes>
    </>
  );
}

export default ClubRoutes;
