import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { imageExtension } from '../../../globals/helper';
import useDocumentTitle from '../../../globals/useDocumentTitle';
import { HideAlbumManager } from '../../../redux/action';
import showNotification from '../../../services/NotificationService';
import * as APISERVICES from '../../../services/SocialServices';
import { userCreateAlbum } from '../../../services/UserServices';
import HeaderLogin from '../../commoncomponents/HeaderLogin';
import PrivacyDropDown from '../../commoncomponents/PrivacySelector/PrivacyDropDown';
import NewsSidebar from '../../commoncomponents/newsfeeds/NewsSidebar';
import TopTabbings from './TopTabbings';

function CreateAlbum() {
  useDocumentTitle('Almabay');
  const location = useLocation();
  const navigate = useNavigate();
  const albumId = useParams()?.albumId;
  const userId = new URLSearchParams(location.search).get('id');
  const dispatch = useDispatch();
  const [privacySelection, setPrivacySelection] = useState([]);
  const [name, setName] = useState(null);
  const [description, setDescription] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);
  const [clicked, setClicked] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchSingleAlbumData();
  }, [albumId]);

  const fetchSingleAlbumData = () => {
    APISERVICES.getSingleAlbum(albumId).then((res) => {
      if (res?.status == 200) {
        let data = res?.data?.data?.sk?.album_info[0];
        setName(data?.name);
        setDescription(data?.descr);
      }
    });
  };

  const handleFile = (e) => {
    if (imageExtension?.includes(e.target.files[0].type)) {
      const targetfile = e?.target?.files;
      const targetfileobj = [...targetfile];
      setSelectedFile(targetfileobj);
    } else {
      showNotification('danger', 'Please upload valid extension');
    }
  };

  const validate = () => {
    let error = {};
    if (name == null || name == '') {
      error.name = 'Album name can not be blank';
    }
    if (!albumId) {
      if (!selectedFile || selectedFile == null) {
        error.selectedFile = 'Please select atleast 1 photo';
      }
    }

    if (privacySelection?.length == 0) {
      error.privacySelection = 'Please select atleast one privacy';
    }
    return error;
  };

  const addAlbum = (e) => {
    e.preventDefault();
    setClicked(true);
    if (!Object.keys(validate()).length) {
      setLoading(true);
      if (userId) {
        let formData = new FormData();
        formData.append(`album_name`, name);
        formData.append(`album_descr`, description);
        formData.append(`post_privacy`, privacySelection.toString());

        for (let i in selectedFile) {
          formData.append(`photos[]`, selectedFile[i]);
        }

        userCreateAlbum(formData).then((res) => {
          if (res?.status === 200) {
            setLoading(false);
            showNotification('success', res?.data?.message);
            navigate(`/user/albums/${userId}`);
          }
        });
      } else {
        let formData = new FormData();
        formData.append(`album_name`, name);
        formData.append(`album_descr`, description);
        formData.append(`post_privacy`, privacySelection.toString());

        for (let i in selectedFile) {
          formData.append(`photos[]`, selectedFile[i]);
        }

        APISERVICES.socialCreateAlbum(formData).then((res) => {
          if (res?.status === 200) {
            setLoading(false);
            showNotification('success', res?.data?.message);
            navigate('/user/albums');
          }
        });
      }
    } else {
      return;
    }
  };

  const UpdateAlbum = (e) => {
    e.preventDefault();
    setClicked(true);
    if (!Object.keys(validate()).length) {
      setLoading(true);
      let formData = new FormData();
      formData.append(`album_id`, albumId);
      formData.append(`album_name`, name);
      formData.append(`album_descr`, description);
      formData.append(`post_privacy`, privacySelection.toString());

      for (let i in selectedFile) {
        formData.append(`photos[]`, selectedFile[i]);
      }

      APISERVICES.socialUpdateAlbum(formData).then((res) => {
        if (res?.status === 200) {
          setLoading(false);
          showNotification('success', res?.data?.message);
          if (userId) {
            navigate(`/user/collection/${albumId}/${userId}`);
            dispatch(HideAlbumManager());
          } else {
            navigate('/user/albums');
          }
        }
      });
    }
  };

  return (
    <>
      <HeaderLogin />
      <div className="header-lnk-feeds">
        <div className="container">
          <TopTabbings state={'social'} />
        </div>
      </div>
      <section className="feed-sec bg-grey py-4">
        <div className="container">
          <Row>
            <Col xs={12} lg={2} md={12}>
              <NewsSidebar state="1" current="album" />
            </Col>
            <Col xs={12} lg={6} md={12}>
              <div className="album-page bg-white p-0">
                <div className="module-hd d-flex align-items-center justify-content-between mb-2">
                  <h4>{albumId ? 'Edit an album' : 'Create an album'}</h4>
                </div>
                <div className="create-album-card p-3">
                  <Form>
                    <Form.Group className="mb-3" controlId="formBasicName">
                      <Form.Label>Name:</Form.Label>
                      <sub className="text-danger">*</sub>
                      <Form.Control
                        type="text"
                        placeholder="Name of album"
                        name="name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        autoComplete="off"
                      />
                      <div className={'danger-frm'}>{clicked && validate().name}</div>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicDiscription">
                      <Form.Label>Description:</Form.Label>
                      <Form.Control
                        as="textarea"
                        type="text"
                        placeholder="description"
                        rows={4}
                        name="description"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                      />
                    </Form.Group>
                    {albumId ? (
                      <></>
                    ) : (
                      <>
                        {' '}
                        <Form.Group controlId="formFileMultiple" className="mb-3">
                          <Form.Label>Upload Photos:</Form.Label>
                          <sub className="text-danger">*</sub>
                          <Form.Control type="file" multiple onChange={handleFile} />
                          <div className={'danger-frm'}>{clicked && validate().selectedFile}</div>
                        </Form.Group>
                      </>
                    )}
                    <Form.Group className="mb-3" controlId="formBasicDiscription">
                      <Form.Label>Privacy:</Form.Label>
                      <PrivacyDropDown setPrivacySelection={setPrivacySelection} />
                      <div className={'danger-frm'}>{clicked && validate().privacySelection}</div>
                    </Form.Group>
                    {albumId ? (
                      <>
                        {' '}
                        <div className="d-flex">
                          {' '}
                          <div className="px-2">
                            <Button
                              className="main-btn mt-3"
                              type="button"
                              onClick={(e) => UpdateAlbum(e)}>
                              Update Album
                            </Button>
                          </div>
                          {''}
                          <div className="px-2">
                            {' '}
                            <Button
                              className="main-btn mt-3"
                              type="button"
                              onClick={() => navigate(`/user/album/${albumId}`)}>
                              Edit Photos
                            </Button>
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        {' '}
                        <Button
                          disabled={loading}
                          className="main-btn mt-3"
                          type="button"
                          onClick={addAlbum}>
                          {loading ? 'Processing' : 'Create Album'}
                          {loading && <i className="fas fa-circle-notch fa-spin ms-2"></i>}
                        </Button>
                      </>
                    )}
                  </Form>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </section>
      {/* <FooterMain className={'colour-bg'} /> */}
    </>
  );
}

export default CreateAlbum;
