import { useState, useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import HeaderLogin from '../../commoncomponents/HeaderLogin';
import TopTabbings from './TopTabbings';
import { Link, useNavigate } from 'react-router-dom';
import SuggestMentor from '../../commoncomponents/newsfeeds/SuggestMentor';
import GroupDiscover from '../../commoncomponents/newsfeeds/GroupDiscover';
import WhatsNew from '../../commoncomponents/newsfeeds/WhatsNew';
import Upcoming from '../../commoncomponents/newsfeeds/Upcoming';
import NewsSidebar from '../../commoncomponents/newsfeeds/NewsSidebar';
import showNotification from '../../../services/NotificationService';
import Form from 'react-bootstrap/Form';
import * as APISERVICE from '../../../services/MentorArticlesServices';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { useParams } from 'react-router-dom';
import { MiniFooter } from '../../MiniFooter';

function EditAnswerPage() {
  const navigate = useNavigate();
  const [isClicked, setIsClicked] = useState(false);
  const [addComment, setAddComment] = useState('');
  const [toSendAttach, setToSendAttch] = useState([]);
  const [attachedFiles, setAttachedFiles] = useState([]);
  const [fileName, setFileName] = useState([]);
  const { id } = useParams();

  useEffect(() => {
    getAnswerDetail();
  }, []);

  const getAnswerDetail = () => {
    APISERVICE.singleAnswerDetail(id).then((res) => {
      if (res?.status === 200) {
        setAddComment(res?.data?.data?.answer_body);
        setAttachedFiles(res?.data?.data?.attachments);
      }
    });
  };
  const validate = (value) => {
    let errors = {};
    if (!value.trim()) {
      errors.description = 'This field is required';
    }
    return errors;
  };

  const submitHandler = (e) => {
    e.preventDefault();
    setIsClicked(true);
    if (!Object.keys(validate(addComment)).length) {
      let formData = new FormData();
      for (let i in toSendAttach) {
        formData.append(`attach_files[]`, toSendAttach[i]);
      }
      formData.append('answer_body', addComment);
      APISERVICE.editAnswer(id, formData).then((res) => {
        if (res?.status === 200) {
          showNotification('success', res?.data?.message);
          navigate(-1);
        }
      });
    } else {
      return;
    }
  };

  const deleteAttachmentHandler = (e, id) => {
    e.preventDefault();
    APISERVICE.deleteAttachmentFile(id).then((res) => {
      if (res?.status === 200) {
        showNotification('success', res?.data?.message);
        getAnswerDetail();
      }
    });
  };

  const onChangeHandler = (e) => {
    setFileName(e.target.files[0]);
    let fileData = e.target.files;
    for (let i = 0; i < fileData.length; i++) {
      toSendAttach.push(fileData[i]);
    }
    setToSendAttch(toSendAttach);
  };

  return (
    <>
      <HeaderLogin />
      <div className="header-lnk-feeds">
        <div className="container">
          <TopTabbings state={'mentor'} />
        </div>
      </div>
      <section className="news-feed-sec bg-grey py-4">
        <Container>
          <Row>
            <Col xs={12} md={2}>
              <NewsSidebar state="2" />
            </Col>
            <Col xs={12} md={7}>
              <div className="leave-cmmnt-frm bg-white">
                <h4 className="answer-quest mb-2">
                  {id ? 'Edit Answer Question' : 'Answer Question'}
                </h4>
                <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                  <CKEditor
                    name="description"
                    editor={ClassicEditor}
                    data={addComment}
                    onReady={(editor) => {
                      // You can store the "editor" and use when it is needed.
                    }}
                    config={{
                      toolbar: [
                        'bold',
                        'italic',
                        'numberedList',
                        'bulletedList',
                        'outdent',
                        'indent'
                      ]
                    }}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      setAddComment(data);
                    }}
                    onBlur={(event, editor) => {}}
                    onFocus={(event, editor) => {}}
                  />
                </Form.Group>
                <div
                  className={`${
                    isClicked && validate(addComment)?.description ? '' : 'd-none'
                  } danger-frm `}
                >
                  {isClicked && validate(addComment)?.description}
                </div>
                <div className="px-3 pt-2 attach-card-inr d-flex justify-content-between">
                  <div>
                    <div>
                      <Form.Group controlId="formFile" className="attach-file-card">
                        <Form.Control
                          type="file"
                          name="attach_files"
                          onChange={onChangeHandler}
                          accept=".png,.jpg,.jpeg"
                          multiple={true}
                        />
                        <span className="attach-file-inr">
                          <i className="fas fa-paperclip me-2"></i>
                          Attach Files
                        </span>
                      </Form.Group>
                    </div>
                    <span className="attach-file-name">{fileName?.name}</span>
                  </div>
                  {id ? (
                    <div className="attach-file-lst">
                      <ul>
                        {attachedFiles &&
                          attachedFiles?.map((data, index) => (
                            <li key={index}>
                              <i className="fas fa-paperclip"></i>
                              <a href={data?.document} target="__blank">
                                Attached on
                              </a>
                              <span>({data?.added_on}) |</span>
                              <Link
                                classname="trash-btn"
                                to="#"
                                onClick={(e) => {
                                  deleteAttachmentHandler(e, data?.id, data?.doc_of_id);
                                }}
                              >
                                <i className="fas fa-trash-alt"></i>
                              </Link>
                            </li>
                          ))}
                      </ul>
                    </div>
                  ) : (
                    ''
                  )}
                </div>
                <div className="cmmnt-btn text-end mt-3 px-2 pt-3">
                  <button
                    className={'accnt-btn mb-3'}
                    type="button"
                    onClick={(e) => {
                      submitHandler(e);
                    }}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </Col>
            <Col md={3}>
              <div>
                <SuggestMentor />
              </div>
              <div>
                <GroupDiscover />
              </div>
              <div>
                <WhatsNew />
              </div>
              <div>
                <Upcoming />
              </div>
              <MiniFooter />
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}

export default EditAnswerPage;
