import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import HeaderInner from '../../commoncomponents/HeaderInner';
import FooterMain from '../../commoncomponents/FooterMain';
import useDocumentTitle from '../../../globals/useDocumentTitle';

function DemoVideo() {
  useDocumentTitle(
    'Almabay Job Search Portal for Alumni.  Social Networking to get connected with your Alma Mater. Order Memorabilia from our E-Store | AlmaBay'
  );
  return (
    <>
      <HeaderInner />
      {/* <section className="breddcrum py-5">
        <div className="container">
          <Row className="align-items-center">
            <Col md={8} lg={8}>
              <div className="bread-title">
                <h2>Demo Video</h2>
              </div>
            </Col>
          </Row>
        </div>
      </section> */}
      <section className="demo-video-sec py-5">
        <div className="container">
          <Row>
            <Col md={{ span: 8, offset: 2 }}>
              <div className="demo-video-inr">
                <div className="title-card mb-4">
                  <h3 className="title-hd">AlmaBay Product Walkthrough Video</h3>
                </div>
                <iframe
                  width="100%"
                  height="550"
                  src="https://www.youtube.com/embed/TmehKck65C0"
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
            </Col>
          </Row>
        </div>
      </section>
      <FooterMain className={'colour-bg'} />
    </>
  );
}

export default DemoVideo;
