import { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import HeaderInner from '../../commoncomponents/HeaderInner';
import FooterMain from '../../commoncomponents/FooterMain';
import Form from 'react-bootstrap/Form';
import * as APISERVICES from '../../../services/AuthServices';
import { truncate, useOnClickOutside } from '../../../globals/helper';
import useDocumentTitle from '../../../globals/useDocumentTitle';
import { filePath } from '../../../globals/constants';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';

function InstituteDirectory() {
  useDocumentTitle(
    'Connect to Your Alumni | Institute Updates |  Mentorship Programs | Order Memorabilia Now! | AlmaBay'
  );
  const [list, setList] = useState([]);
  const [searchList, setSearchList] = useState([]);
  const { sort } = useParams();
  const [selectedSort, setSelectedSort] = useState(sort);
  //on click out-side
  const ref = useRef();
  const [isActive, setIsActive] = useState(false);
  const navigate = useNavigate();
  useOnClickOutside(ref, () => setIsActive(false));

  useEffect(() => {
    getInstituteDirectory('', '', selectedSort);
  }, []);

  const getInstituteDirectory = (page = '', search = '', selectedSort, rid = false) => {
    setSelectedSort(selectedSort);
    let body = {
      searchtxt: search ? search : selectedSort,
      institue_type: '',
      city: ''
    };
    let formData = new FormData();
    for (let i in body) {
      formData.append(`${i}`, body[i]);
    }
    APISERVICES.instituteDirectory(page, body).then((res) => {
      if (res?.status === 200) {
        if (rid === false) {
          setList(res?.data?.detail);
          navigate(`/advancesearch/directory/${selectedSort}`);
          setIsActive(false);
          setSearchList([]);
        } else {
          setSearchList(res?.data?.detail);
        }
      }
    });
  };

  const searchHandler = (e) => {
    setIsActive(true);
    if (e?.target?.value?.trim()) {
      getInstituteDirectory('', e.target.value, '', true);
    } else {
      getInstituteDirectory('', '', 'A');
    }
  };

  return (
    <>
      <HeaderInner />
      <section className="institute-sec pt-3 pb-5">
        <div className="container">
          <div className="institute-inr-card">
            <Row>
              <Col md={12} lg={9}>
                <div className="institute-lft-card">
                  <div className="directory-hd d-flex align-items-center justify-content-between border-bottom">
                    <h5>Institute's Directory:</h5>
                    <Form className="position-relative">
                      <Form.Group controlId="exampleForm.ControlInput1D">
                        <Form.Control
                          type="search"
                          placeholder="Find institute by name"
                          onChange={(e) => {
                            searchHandler(e);
                          }}
                        />
                      </Form.Group>
                      <div
                        ref={ref}
                        className={`${isActive && searchList?.length ? 'auto-results' : 'd-none'}`}
                      >
                        {isActive && searchList?.length ? (
                          searchList?.map((data, index) => (
                            <>
                              <ul key={index}>
                                <li>
                                  <Link to="#">{data?.name}</Link>
                                </li>
                              </ul>
                            </>
                          ))
                        ) : (
                          <></>
                        )}
                      </div>
                    </Form>
                  </div>
                  <div className="alpha-pagination d-flex align-items-center">
                    <span className="alpha-directory-hd mr-5">Search Alphabatically:</span>
                    <ul>
                      <li>
                        <Link
                          className={`${selectedSort === 'A' ? 'active' : ''}`}
                          to=""
                          onClick={() => {
                            getInstituteDirectory('', '', 'A');
                          }}
                        >
                          A
                        </Link>
                      </li>
                      <li>
                        <Link
                          to=""
                          className={`${selectedSort === 'B' ? 'active' : ''}`}
                          onClick={() => {
                            getInstituteDirectory('', '', 'B');
                          }}
                        >
                          B
                        </Link>
                      </li>
                      <li>
                        <Link
                          to=""
                          className={`${selectedSort === 'C' ? 'active' : ''}`}
                          onClick={() => {
                            getInstituteDirectory('', '', 'C');
                          }}
                        >
                          C
                        </Link>
                      </li>
                      <li>
                        <Link
                          to=""
                          className={`${selectedSort === 'D' ? 'active' : ''}`}
                          onClick={() => {
                            getInstituteDirectory('', '', 'D');
                          }}
                        >
                          D
                        </Link>
                      </li>
                      <li>
                        <Link
                          to=""
                          className={`${selectedSort === 'E' ? 'active' : ''}`}
                          onClick={() => {
                            getInstituteDirectory('', '', 'E');
                          }}
                        >
                          E
                        </Link>
                      </li>
                      <li>
                        <Link
                          to=""
                          className={`${selectedSort === 'F' ? 'active' : ''}`}
                          onClick={() => {
                            getInstituteDirectory('', '', 'F');
                          }}
                        >
                          F
                        </Link>
                      </li>
                      <li>
                        <Link
                          to=""
                          className={`${selectedSort === 'G' ? 'active' : ''}`}
                          onClick={() => {
                            getInstituteDirectory('', '', 'G');
                          }}
                        >
                          G
                        </Link>
                      </li>
                      <li>
                        <Link
                          to=""
                          className={`${selectedSort === 'H' ? 'active' : ''}`}
                          onClick={() => {
                            getInstituteDirectory('', '', 'H');
                          }}
                        >
                          H
                        </Link>
                      </li>
                      <li>
                        <Link
                          to=""
                          className={`${selectedSort === 'I' ? 'active' : ''}`}
                          onClick={() => {
                            getInstituteDirectory('', '', 'I');
                          }}
                        >
                          I
                        </Link>
                      </li>
                      <li>
                        <Link
                          to=""
                          className={`${selectedSort === 'J' ? 'active' : ''}`}
                          onClick={() => {
                            getInstituteDirectory('', '', 'J');
                          }}
                        >
                          J
                        </Link>
                      </li>
                      <li>
                        <Link
                          to=""
                          className={`${selectedSort === 'K' ? 'active' : ''}`}
                          onClick={() => {
                            getInstituteDirectory('', '', 'K');
                          }}
                        >
                          K
                        </Link>
                      </li>
                      <li>
                        <Link
                          to=""
                          className={`${selectedSort === 'L' ? 'active' : ''}`}
                          onClick={() => {
                            getInstituteDirectory('', '', 'L');
                          }}
                        >
                          L
                        </Link>
                      </li>
                      <li>
                        <Link
                          to=""
                          className={`${selectedSort === 'M' ? 'active' : ''}`}
                          onClick={() => {
                            getInstituteDirectory('', '', 'M');
                          }}
                        >
                          M
                        </Link>
                      </li>
                      <li>
                        <Link
                          to=""
                          className={`${selectedSort === 'N' ? 'active' : ''}`}
                          onClick={() => {
                            getInstituteDirectory('', '', 'N');
                          }}
                        >
                          N
                        </Link>
                      </li>
                      <li>
                        <Link
                          to=""
                          className={`${selectedSort === 'O' ? 'active' : ''}`}
                          onClick={() => {
                            getInstituteDirectory('', '', 'O');
                          }}
                        >
                          O
                        </Link>
                      </li>
                      <li>
                        <Link
                          to=""
                          className={`${selectedSort === 'P' ? 'active' : ''}`}
                          onClick={() => {
                            getInstituteDirectory('', '', 'P');
                          }}
                        >
                          P
                        </Link>
                      </li>
                      <li>
                        <Link
                          to=""
                          className={`${selectedSort === 'Q' ? 'active' : ''}`}
                          onClick={() => {
                            getInstituteDirectory('', '', 'Q');
                          }}
                        >
                          Q
                        </Link>
                      </li>

                      <li>
                        <Link
                          to=""
                          className={`${selectedSort === 'R' ? 'active' : ''}`}
                          onClick={() => {
                            getInstituteDirectory('', '', 'R');
                          }}
                        >
                          R
                        </Link>
                      </li>
                      <li>
                        <Link
                          to=""
                          className={`${selectedSort === 'S' ? 'active' : ''}`}
                          onClick={() => {
                            getInstituteDirectory('', '', 'S');
                          }}
                        >
                          S
                        </Link>
                      </li>
                      <li>
                        <Link
                          to=""
                          className={`${selectedSort === 'T' ? 'active' : ''}`}
                          onClick={() => {
                            getInstituteDirectory('', '', 'T');
                          }}
                        >
                          T
                        </Link>
                      </li>
                      <li>
                        <Link
                          to=""
                          className={`${selectedSort === 'U' ? 'active' : ''}`}
                          onClick={() => {
                            getInstituteDirectory('', '', 'U');
                          }}
                        >
                          U
                        </Link>
                      </li>
                      <li>
                        <Link
                          to=""
                          className={`${selectedSort === 'V' ? 'active' : ''}`}
                          onClick={() => {
                            getInstituteDirectory('', '', 'V');
                          }}
                        >
                          V
                        </Link>
                      </li>
                      <li>
                        <Link
                          to=""
                          className={`${selectedSort === 'W' ? 'active' : ''}`}
                          onClick={() => {
                            getInstituteDirectory('', '', 'W');
                          }}
                        >
                          W
                        </Link>
                      </li>
                      <li>
                        <Link
                          to=""
                          className={`${selectedSort === 'X' ? 'active' : ''}`}
                          onClick={() => {
                            getInstituteDirectory('', '', 'X');
                          }}
                        >
                          X
                        </Link>
                      </li>
                      <li>
                        <Link
                          to=""
                          className={`${selectedSort === 'Y' ? 'active' : ''}`}
                          onClick={() => {
                            getInstituteDirectory('', '', 'Y');
                          }}
                        >
                          Y
                        </Link>
                      </li>
                      <li>
                        <Link
                          to=""
                          className={`${selectedSort === 'Z' ? 'active' : ''}`}
                          onClick={() => {
                            getInstituteDirectory('', '', 'Z');
                          }}
                        >
                          Z
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <div className="institute-hd">
                    <h4>Institutes starting with '{selectedSort}'</h4>
                  </div>
                  <div className="institute-lst">
                    <Row className="institute-lst-card">
                      {list &&
                        list?.map((data, index) => (
                          <Col md={6} lg={6} key={index}>
                            <div className="institute-media-card d-flex align-items-top">
                              <div className="institute-icn me-2">
                                <Link to="#">
                                  <img
                                    src={
                                      data?.logo
                                        ? process.env.REACT_APP_API_DOMAIN + filePath + data?.logo
                                        : require('../../../assets/images/google-play-btn.png')
                                    }
                                    alt="Img"
                                  />
                                </Link>
                              </div>

                              <div className="institute-disc">
                                <h4>
                                  <Link to="#">{truncate(data?.name, 45)}</Link>
                                </h4>
                                <p>{truncate(data?.description, 90)}</p>
                                <p>
                                  <span>
                                    <i className="fa-sharp fa-solid fa-location-dot"></i>
                                  </span>
                                  {data?.address}
                                </p>
                              </div>
                            </div>
                          </Col>
                        ))}
                    </Row>
                  </div>
                </div>
              </Col>
              <Col md={12} lg={3}>
                <div className="on-amblay-card mb-3">
                  <h5>On Almabay, you can find where your friends went after college</h5>
                  <Link className="main-btn mt-2" to="/login">
                    LOGIN
                  </Link>
                </div>
                <div className="quck-lnk-card">
                  <h5 className="mb-2">Quick Links</h5>
                  <ul>
                    <li>
                      <Link to="/">Home</Link>
                    </li>
                    <li>
                      <Link to="#">Find your Institute</Link>
                    </li>
                    <li>
                      <Link to="/jobs/search">Browse Jobs</Link>
                    </li>
                  </ul>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </section>
      <FooterMain className={'colour-bg'} />
    </>
  );
}

export default InstituteDirectory;
