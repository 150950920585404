import * as api from '../utils/requests';
/**
 * GET ALL THE STUDENT TOKEN
 * @returns
 */
export const getStudentToken = async (type = '') => {
  return await api.getReq(`/admin/template/token/templateAttribute?type=${type}`);
};
/**
 * GET ALL THE INSTITUTE TOKEN
 * @returns
 */
export const getInstituteToken = async () => {
  return await api.getReq(`-----`);
};
/**
 * GET ALL THE COMPANY TOKEN
 * @returns
 */
export const getCompanyToken = async () => {
  return await api.getReq(`------`);
};
/**
 * ADD TEMPLATE TOKEN
 * @param {Object} body
 * @returns
 */
export const addTemplateToken = async (body) => {
  return await api.PostReq(`/admin/template/token/create`, body);
};
/**
 * GET TEMPLATE TOKEN LIST
 * @returns
 */
export const getTemplateTokenList = async (page = '', state_id = '', applicable_for = '') => {
  return await api.getReq(
    `/admin/template/token/tokenInfoList?page=${page}&state_id=${state_id}&applicable_for=${applicable_for}`
  );
};
export const getMultiTokenSelect = async (body) => {
  return await api.PostReq(`/admin/template/token/multiAction`, body);
};
/**
 * DELETE TEMPLATE TOKEN
 */
export const delTemplateToken = async (id = '') => {
  return await api.delReq(`/admin/template/token/deleteToken?id=${id}`);
};
/**
 * GET TEMPLATE TOKEN LIST BY APPLICABLE FOR
 * @param {Number} applicable_for_type
 * @returns
 */
export const getTemplateTokenListByApplicable = async (applicable_for_type) => {
  return await api.getReq(`/admin/template/token/tokenList?applicable_for=${applicable_for_type}`);
};
/**
 * ADD NOTIFICATION TEMPLATE
 * @param {Object} body
 * @returns
 */
export const addNotificationTemplate = async (body) => {
  return await api.PostReq(`/admin/template/notification/create`, body);
};
/**
 * GET TEMPLATE NOTIFICATION LIST
 * @returns
 */
export const getTemplateNotiifcationList = async (page = '', state_id = '') => {
  return await api.getReq(
    `/admin/template/notification/notificationList?page=${page}&state_id=${state_id}`
  );
};

/**
 * multiple notification template handler
 * @param {*} body
 * @returns
 */
export const multiNotificationTemplate = (body) => {
  return api.PostReq(`/admin/template/notification/multiAction`, body);
};

/**
 * DELETE TEMPLATE Notifcation
 */
export const delTemplateNotiifcation = async (id = '') => {
  return await api.delReq(`/admin/template/notification/deleteNotification?id=${id}`);
};
/**
 * ADD SMS TEMPLATE
 * @param {Object} body
 * @returns
 */
export const addSMSTemplate = async (body) => {
  return await api.PostReq(`/admin/template/sms/create`, body);
};
/**
 * GET TEMPLATE SMS LIST
 * @returns
 */
export const getTemplateSmsList = async (page = '', state_id = '') => {
  return await api.getReq(`/admin/template/sms/smsList?page=${page}&state_id=${state_id}`);
};
/**
 * DELETE TEMPLATE SMS
 */
export const delTemplateSMS = async (id = '') => {
  return await api.delReq(`/admin/template/sms/deleteSms?id=${id}`);
};
/**
 * ADD Email TEMPLATE
 * @param {Object} body
 * @returns
 */
export const addEmailTemplate = async (body) => {
  return await api.PostReq(`/admin/template/email/create`, body);
};
/**
 * GET TEMPLATE EMAIL LIST
 * @returns
 */
export const getTemplateEmailList = async (page = '', state_id = '') => {
  return await api.getReq(`/admin/template/email/emailList?page=${page}&state_id=${state_id}`);
};
/**
 * DELETE TEMPLATE EMAIL
 */
export const delTemplateEmail = async (id = '') => {
  return await api.delReq(`/admin/template/email/deleteEmail?id=${id}`);
};
/**
 * GET SINGLE TOKEN DETAIL
 * @param {Number} id
 * @returns
 */
export const getSingleTokenDetail = async (id) => {
  return await api.getReq(`/admin/template/token/tokenDetail?id=${id}`);
};
/**
 * UPDATE TEMPLATE TOKEN DETAIL
 * @param {Object} body
 * @returns
 */
export const updateTokenDetail = async (body) => {
  return await api.PostReq(`/admin/template/token/update`, body);
};
/**
 * TO GET SINGLE NOTIFICATION DETAIL
 * @param {Number} id
 * @returns
 */
export const getSingleNotificationDetail = async (id) => {
  return await api.getReq(`/admin/template/notification/notificationDetail?id=${id}`);
};

/**
 * TO GET SINGLE SMS DETAIL
 * @param {Number} id
 * @returns
 */
export const getSingleSMSTemplateDetail = async (id) => {
  return await api.getReq(`/admin/template/sms/smsDetail?id=${id}`);
};
/**
 * UPDATE TEMPLATE NOTIFICATION DETAIL
 * @param {Object} body
 * @returns
 */
export const updateNotificationTemplateDetail = async (body) => {
  return await api.PostReq(`/admin/template/notification/update`, body);
};
/**
 * UPDATE TEMPLATE SMS DETAIL
 * @param {Object} body
 * @returns
 */
export const updateSMSTemplateDetail = async (body) => {
  return await api.PostReq(`/admin/template/sms/update`, body);
};

/**ACTION UPDATE*/
export const multiSMSTemplate = (body) => {
  return api.PostReq(`/admin/template/sms/multiAction`, body);
};
/**
 * TO GET SINGLE EMAIL DETAIL
 * @param {Number} id
 * @returns
 */
export const getSingleEmailTemplateDetail = async (id) => {
  return await api.getReq(`/admin/template/email/emailDetail?id=${id}`);
};

/**
 * UPDATE TEMPLATE EMAIL DETAIL
 * @param {Object} body
 * @returns
 */
export const updateEmailTemplateDetail = async (body) => {
  return await api.PostReq(`/admin/template/email/update`, body);
};
export const insituteListingDropDown = async (search = '') => {
  return await api.PostReq(`/admin/template/notification/getInstituteListing?search=${search}`);
};
export const insituteMembers = async (institute_id = '', search = '') => {
  return await api.PostReq(
    `/admin/template/notification/getAllUsersListing?search=${search}&institute_id=${institute_id}`
  );
};

export const insituteMembersOnEdit = async (member_id = '', search = '') => {
  return await api.PostReq(
    `/admin/template/notification/getAllUsersListing?search=${search}&user_id=${member_id}`
  );
};

export const addWhatsAppTemplate = async (body) => {
  return await api.PostReq(`/admin/template/whatsaap/create`, body);
};

export const listWhatsAppTemplateList = async (page, state_id) => {
  return await api.getReq(
    `/admin/template/whatsaap/whatsaapList?page=${page}&state_id=${state_id}`
  );
};

export const deleteWhatsApp = async (id) => {
  return await api.delReq(`/admin/template/whatsaap/deleteWhatsaap?id=${id}`);
};

export const getEmailToken = async (id) => {
  return await api.getReq(`/admin/template/email/emailTokenList?id=${id}`);
};

export const getMultiActionEmail = async (body) => {
  return await api.PostReq(`/admin/template/email/multiAction`, body);
};

export const getWhatsAppDetail = async (id) => {
  return await api.getReq(`/admin/template/whatsaap/whatsaapDetail?id=${id}`);
};

export const addWhatsAppBusinessTemplate = async (body) => {
  return await api.PostReq(`/admin/template/whatsaapBusiness/create`, body);
};

export const getWhatsAppBusinessListing = async (page, state_id) => {
  return await api.getReq(
    `/admin/template/whatsaapBusiness/whatsaapBusinessList?page=${page}&state_id=${state_id}`
  );
};
export const deleteWhatsaapBusinessListing = async (id) => {
  return await api.delReq(`/admin/template/whatsaapBusiness/deleteWhatsaapBusiness?id=${id}`);
};
export const getDetailWhatsAppBusiness = async (id) => {
  return await api.getReq(`/admin/template/whatsaapBusiness/whatsaapBusinessDetail?id=${id}`);
};
export const updateWhatsApp = async (body) => {
  return await api.PostReq(`/admin/template/whatsaap/update`, body);
};
export const updateWhatsAppBusiness = async (body) => {
  return await api.PostReq(`/admin/template/whatsaapBusiness/update`, body);
};
export const multiactionWhatsAppBusiness = async (body) => {
  return await api.PostReq(`/admin/template/whatsaapBusiness/multiAction`, body);
};
export const multiActionWhatsApp = async (body) => {
  return await api.PostReq(`/admin/template/whatsaap/multiAction`, body);
};
