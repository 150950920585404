import { useEffect, useState } from 'react';
import { Row } from 'react-bootstrap';
import { Col } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import { Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { ZERO } from '../../../../globals/constants';
import showNotification from '../../../../services/NotificationService';
import { getPagePeopleList, socialPageAddAdmin } from '../../../../services/SocialServices';
import NoDataFound from '../../../commoncomponents/NoDataFound';

function PagePeopleList({ pageIdEncrypted, pageId }) {
  const [peopleList, setPeopleList] = useState([]);

  useEffect(() => {
    if (pageIdEncrypted) {
      getDetail();
    }
  }, []);

  const getDetail = (q = '') => {
    let body = {
      type: 'members'
    };
    getPagePeopleList(body, pageIdEncrypted, '', '').then((res) => {
      if (res?.status === 200) {
        const data = res?.data?.data?.peoples;
        setPeopleList(data);
      }
    });
  };

  const addAdmin = (e, id, type) => {
    e.preventDefault();
    let body = {
      page_id: pageId,
      admin_id: id,
      admin_role: type
    };
    let formData = new FormData();
    for (let i in body) {
      formData.append(`${i}`, body[i]);
    }
    socialPageAddAdmin(formData).then((res) => {
      if (res?.status === 200) {
        showNotification('success', res?.data?.message);
        getDetail(pageIdEncrypted);
      } else {
        showNotification('success', res?.data?.message);
      }
    });
  };

  return (
    <div className="likes-tab-sec">
      <div className="likes-top-hd flex-wrap d-flex align-items-center justify-content-between">
        <div>
          <Link to="#">People List</Link>
        </div>
        <div>
          <Form.Group className="search-card" controlId="formBasicEmail">
            <Form.Control type="search" placeholder="Search" />
            <Button className="search-btn">
              <i className="fas fa-search"></i>
            </Button>
          </Form.Group>
        </div>
      </div>
      {peopleList?.length !== ZERO ? (
        <>
          {' '}
          <div className="like-page-card bg-white p-3">
            <Row>
              {peopleList?.map((data, index) => {
                return (
                  <Col md={6} key={index}>
                    <div className="like-card-detail member-card-detail d-flex">
                      <div className="like-card-img">
                        <Link to={`/user/timeline/${data?.encrypted_id}`}>
                          <img
                            src={
                              data?.avatar_url
                                ? data?.avatar_url
                                : require('../../../../assets/images/default-male-avatar.png')
                            }
                            alt="img"
                          />
                        </Link>
                      </div>
                      <div className="like-card-dis">
                        <h4>
                          {data?.first_name} {data?.last_name}
                        </h4>
                        <p>
                          {data?.work_experience?.length !== 0
                            ? 'Working as Server Administrator at AlmaBay Networks Pvt Ltd'
                            : ''}
                        </p>
                        {data?.page_admin !== 'admin' ? (
                          <ul className="mentr-action-btn">
                            <li>
                              <Link to="#" onClick={(e) => addAdmin(e, data?.id, 'admin')}>
                                Add Admin
                              </Link>
                            </li>
                            <li>
                              <Link to="#" onClick={(e) => addAdmin(e, data?.id, 'admin')}>
                                Add Editor
                              </Link>
                            </li>
                          </ul>
                        ) : (
                          <li>
                            <Link to="#" onClick={(e) => addAdmin(e, data?.id, 'admin')}>
                              Add Editor
                            </Link>
                          </li>
                        )}
                      </div>
                    </div>
                  </Col>
                );
              })}
            </Row>
          </div>
        </>
      ) : (
        <NoDataFound text="No member found" />
      )}
    </div>
  );
}

export default PagePeopleList;
