import { useEffect, useState } from 'react';
import { Row } from 'react-bootstrap';
import { Col } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import { Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { truncate } from '../../../../globals/helper';

import {
  addMemberforChapter,
  getPeopleListForChepterAdd
} from '../../../../services/SocialServices';
import NoDataFound from '../../../commoncomponents/NoDataFound';

function ChapterPeopleList(props) {
  const { id, chapter_id } = props;
  const [showBackColour, setShowBackColour] = useState(false);
  const [clickedId, setClickedId] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getPeopleList();
  }, []);

  const [list, setList] = useState([]);
  const handleAdd = (data) => {
    setClickedId(data?.id);
    let body = {
      chapter_id: chapter_id,
      member_id: data?.user_id
    };
    addMemberforChapter(body).then((resp) => {
      if (resp?.status === 200) {
        setShowBackColour(true);
        getPeopleList('stopLoading');
      }
    });
  };

  const getPeopleList = (q = '') => {
    let body = {
      type: 'add_members'
    };
    if (q != 'stopLoading') {
      setLoading(true);
    }

    getPeopleListForChepterAdd(id, q == 'stopLoading' ? '' : q, body).then((resp) => {
      if (resp?.status === 200) {
        setLoading(false);
        setList(resp?.data?.data?.peoples);
        setShowBackColour(false);
      }
    });
  };

  return (
    <div className="likes-tab-sec">
      <div className="likes-top-hd flex-wrap d-flex align-items-center justify-content-between">
        <div>
          <Link to="#">People List</Link>
        </div>
        <div>
          <Form.Group className="search-card" controlId="formBasicEmail">
            <Form.Control
              type="search"
              placeholder="Search"
              onChange={(e) => getPeopleList(e.target.value)}
            />
            <Button className="search-btn" type="button">
              <i className="fas fa-search"></i>
            </Button>
          </Form.Group>
        </div>
      </div>
      {loading ? (
        <>
          {' '}
          <div className="like-page-card bg-white p-3  loading-skeleton">
            {Array.from(Array(3), function (index) {
              return (
                <Row>
                  <Col md={6}>
                    <div className="like-card-detail member-card-detail d-flex">
                      <div className="like-card-img">
                        <Link to="#">
                          <img
                            src={require('../../../../assets/images/default-male-avatar.png')}
                            alt="img"
                          />
                        </Link>
                      </div>
                      <div className="like-card-dis">
                        <h4></h4>
                        <p></p>
                        <Link to="" className="mentr-add-member"></Link>
                      </div>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="like-card-detail member-card-detail d-flex">
                      <div className="like-card-img">
                        <Link to="#">
                          <img
                            src={require('../../../../assets/images/default-male-avatar.png')}
                            alt="img"
                          />
                        </Link>
                      </div>
                      <div className="like-card-dis">
                        <h4></h4>
                        <p></p>
                        <Link to="" className="mentr-add-member"></Link>
                      </div>
                    </div>
                  </Col>
                </Row>
              );
            })}
          </div>
        </>
      ) : (
        <>
          {' '}
          <div className="like-page-card bg-white p-3">
            {}
            <Row>
              {list && list?.length ? (
                list?.map((data, index) => {
                  return (
                    <>
                      <Col md={6}>
                        <div
                          className="like-card-detail member-card-detail d-flex"
                          key={index}
                          style={{
                            backgroundColor:
                              showBackColour && clickedId == data?.id && 'rgba(255,0,0,0.1)'
                          }}>
                          <div className="like-card-img">
                            <Link to="#">
                              {/* <img
                            src={require('../../../../assets/images/default-male-avatar.png')}
                            alt="img"
                          /> */}
                              <img src={data?.thumbnail_url} alt="img" width="180" height="125" />
                            </Link>
                          </div>
                          <div className="like-card-dis">
                            <div className="like-card-dis">
                              <h4>{data?.first_name ? data?.first_name : 'NA'}</h4>
                              <p>{data?.about ? truncate(data?.about, 75) : 'NA'}</p>
                            </div>
                            {showBackColour ? (
                              <Link to="" className="mentr-add-member blk">
                                Add Member
                              </Link>
                            ) : (
                              <Link
                                to=""
                                className="mentr-add-member"
                                onClick={(e) => {
                                  e.preventDefault();
                                  handleAdd(data);
                                }}>
                                Add Member
                              </Link>
                            )}
                          </div>
                        </div>
                      </Col>
                    </>
                  );
                })
              ) : (
                <NoDataFound />
              )}
            </Row>
          </div>
        </>
      )}
    </div>
  );
}

export default ChapterPeopleList;
