import { Link } from 'react-router-dom';
import Dropdown from 'react-bootstrap/Dropdown';
import { Col, Form, InputGroup, OverlayTrigger, Row } from 'react-bootstrap';

/**Recommended jobs Skelton Loader**/
const arr = [1, 2, 3];
export function LoginSkelton() {
  return (
    <>
      {arr?.map((data) => (
        <div key={data} className="job-recomm-card-inr loading-skeleton">
          <div className="top-time-line mb-3">
            <p>
              <i className="fas fa-user me-1"></i>{' '}
              <a className="fst-name" href="/jobs/search/brand/41544">
                {' '}
                leavecasa travel pvt ltd
              </a>{' '}
              posted a job under <a href="/jobs/search/category/22"> iOS Developer</a> category
            </p>
            <span>
              <i className="far fa-clock me-1"></i> 8 months ago
            </span>
          </div>
          <div className="job-recomm-dis">
            <h4>
              <a href="/jobs/jobs-details/16276">iOS Developer</a>
            </h4>
            <ul className="job-recomm-addrs">
              <li>
                <i className="fas fa-map-marker-alt"></i>Location: delhi
              </li>
              <li>
                <i className="fas fa-users"></i>Positions open: 5
              </li>
              <li>
                <i className="fas fa-rupee-sign"></i>CTC: 2 - 3 (Lacs)
              </li>
              <li>
                <i className="fas fa-briefcase"></i>Experience:0 - 1 (yrs).
              </li>
              <li>
                <i className="fas fa-hourglass-end"></i>Job Type: Full Time
              </li>
            </ul>
            <span>
              <p>
                To ensure success as an iOS developer, you should have a strong working knowledge of
                iOS Frameworks, be proficient in Objective-C &amp; Swift, and be able to work as
                part of a team. Ultimately, an...
              </p>
            </span>
            <a className="view-detail-btn" href="/jobs/jobs-details/16276">
              View Details
            </a>
          </div>
          <div className="job-recommd-ftr">
            <ul>
              <li>
                <a className="apply-btn" href="/jobs">
                  <i className="fas fa-check me-1"></i>Apply
                </a>
              </li>
              <li>
                <a href="/jobs">
                  <i className="fas fa-thumbs-up me-1"></i>Recommend
                </a>
              </li>
              <li>
                <a href="/jobs">
                  <i className="fas fa-share me-1"></i> Share
                </a>
              </li>
            </ul>
          </div>
        </div>
      ))}
    </>
  );
}

/**Referral Requested Skelton Loader**/

export function ReferralSkelton() {
  return (
    <>
      <div className="refferal-card referal-skelton loading-skeleton">
        <div className="referral-top-card">
          <div className="d-flex align-items-top">
            <Link to="#" className="referal-icn me-3">
              <img src={require('../../../assets/images/default-male-avatar.png')} alt="profile" />
            </Link>
            <div className="referaal-tp-dis ">
              <div className="d-flex align-items-start justify-content-between">
                <div>
                  <h4>
                    <Link to="#">Coordinator</Link>
                  </h4>
                  <p>HI, Can you please refer me for this job?</p>
                </div>

                <span className="status-pending">Pending</span>
              </div>
              <div className="requested-dis">
                <p>
                  <b>Requested on:</b> Aug 19, 2015
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

/***Posted Job Skelton Loader***/
export function PostedJobSkelton() {
  return (
    <>
      <div className="job-recomm-card-inr internship-card-inr loading-skeleton">
        <div className="job-recomm-dis">
          <h4>
            <a href="/jobs/jobs-details/16294">checking posted Application</a>
          </h4>
          <p> DAV College, </p>
          <ul className="job-recomm-addrs">
            <li>
              <i className="fas fa-map-marker-alt"></i>
            </li>
            <li>
              <i className="fas fa-users"></i>Positions Open: 4
            </li>
            <li>
              <i className="far fa-clock"></i>Yesterday
            </li>
            <li>
              <i className="fas fa-briefcase"></i>Experience: 3 to 3 (yrs)
            </li>
            <li>
              <i className="fas fa-hourglass-start"></i>Job Type: Full Time
            </li>
          </ul>
          <div>
            <p>checking posted Application</p>
          </div>
          <a className="org-color" href="/jobs/jobs-details/16294">
            View in Detail
          </a>
        </div>
        <div className="job-recommd-ftr">
          <ul>
            <li>
              <a className="apply-btn" href="/jobs/posted">
                View Applications (2)
              </a>
            </li>
            <li>
              <a className="close-btn" href="/jobs/posted">
                Close Job
              </a>
            </li>
            <li>
              <a href="/jobs/posted">
                <i className="fas fa-thumbs-up me-1"></i>Recommend
              </a>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
}

/***Jobs Applied Skelton Loader***/

export function JobsAppliedSkelton() {
  return (
    <>
      <div className="job-recomm-card-inr jobs-applied-card loading-skeleton">
        <div className="job-recomm-dis applied-dis">
          <a className="main-title" href="/jobs/jobs-details/16295">
            new
          </a>
          <h5> DAV College, </h5>
          <div className="d-flex align-items-center justify-content-between part-card">
            <ul className="job-recomm-addrs mt-3 mb-3">
              <li>
                <i className="fas fa-map-marker-alt"></i>
              </li>
              <li>
                <i className="fas fa-phone-alt"></i>8569856956
              </li>
              <li>
                <i className="fas fa-envelope"></i>hr123@gmail.com
              </li>
              <li>
                <i className="fas fa-users"></i>Positions open: 2
              </li>
              <li>
                <i className="far fa-clock"></i>Mar 22,2023
              </li>
            </ul>
            <span className="full-tag">Part Time</span>
          </div>
          <div className="applied-hd-card ">
            <div>
              <h4>Objectives of this Role</h4>
              <div>
                <p>
                  <strong>ob Descriptio</strong>
                </p>
              </div>
            </div>
          </div>
          <a className="view-dtl-btn" href="/jobs/jobs-details/16295">
            View in Detail
          </a>
          <div className="applide-ftr d-flex align-items-center flex-wrap justify-content-between">
            <p>Applied on: Mar 22,2023</p>
            <span className="applied-tag">Pending</span>
          </div>
        </div>
      </div>
    </>
  );
}

/***Search Job Skelton Loader***/

export function SearchjobSkelton() {
  return (
    <>
      <div className="job-recomm-card-inr network-job-card loading-skeleton">
        <div className="top-time-line mb-3 d-flex align-items-center justify-content-between">
          <p>
            <i className="far fa-clock me-1"></i> Added on: 6 years ago
          </p>
          <p>
            Posted By:<a href="/jobs/search"> Coordinator</a>
          </p>
        </div>
        <div className="job-recomm-dis">
          <h4>
            <a href="/jobs/jobs-details/7329">
              Manager - Quality Control (hod) - Grinding Unit - Solapur
            </a>
          </h4>
          <p>Zuari Cement Limited</p>
          <p>
            FriendsSkelton
            <strong>Keyskills:</strong> quality control management,production,raw materia...
          </p>
          <ul className="job-recomm-addrs">
            <li>
              <i className="fas fa-map-marker-alt"></i>Solapur
            </li>
            <li>
              <i className="fas fa-users"></i>Positions: 1
            </li>
            <li>
              <i className="fas fa-rupee-sign"></i>Exp :8-11(yrs)
            </li>
          </ul>
          <div>
            <div>
              <p>1. Managing overall Quality Control Activities in Cement Grinding Unit.</p>
              <p>
                2. Encouraging safety environment and ensuring safety measures with all respects.
              </p>
              <p>3. Effective utilization o...</p>
            </div>
          </div>
          <a className="view-detail-btn" href="/jobs/jobs-details/7329">
            View Details
          </a>
        </div>
        <div className="job-recommd-ftr d-flex align-items-center justify-content-between">
          <div>
            <ul>
              <li>
                <a href="/jobs/search">
                  <i className="fas fa-thumbs-up me-1"></i>Recommend
                </a>
              </li>
              <li>
                <a href="/jobs/search">
                  <i className="fas fa-share me-1"></i> Share
                </a>
              </li>
            </ul>
          </div>
          <a className="referral-btn" href="/jobs/search">
            Request for referral<i className="fas fa-question-circle ms-2"></i>
          </a>
        </div>
      </div>
    </>
  );
}

/***Friend, Mentees, Mentor Skelton Loader***/

export function FriendsSkelton() {
  return (
    <>
      <div className="mentees-pro-card mentee-skelton loading-skeleton my-2">
        <div className="mentees-img">
          <img src={require('../../../assets/images/default-male-avatar.png')} alt="" />
        </div>
        <div className="mentees-dis">
          <h4>John Doe</h4>

          <p>Ludhiana</p>
          <Dropdown className="mt-2">
            <Dropdown.Toggle variant="success" id="dropdown-basic">
              Your friend
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item href="#/action-1">View Profile</Dropdown.Item>
              <Dropdown.Item href="#/action-2">Send Message</Dropdown.Item>
              <Dropdown.Item href="#/action-3">Remove Follower</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
    </>
  );
}

export function ImageSkelton() {
  return (
    <>
      <div className="mentees-pro-card mentee-skelton loading-skeleton my-2">
        <div className="mentees-img">
          <img src={require('../../../assets/images/default-male-avatar.png')} alt="" />
        </div>
      </div>
    </>
  );
}
export const MentorshipSkelton = () => {
  return (
    <>
      <div className="single_artical_card leave-ques-card single-artical-loader loading-skeleton p-3">
        <div className="d-flex align-items-center mb-3">
          <Link className="media_img" to="#">
            <img
              className="me-3"
              src={require('../../../assets/images/birthday-profile-1.jpg')}
              alt="img"
            />
          </Link>
          <div className="media-body">
            <h4>
              <Link className="user-link" to="#">
                Shruti Pathania
              </Link>
              <span> asked from </span>
              <Link className="user-link" to="#">
                Coordinator1 Coordinator2
              </Link>
            </h4>
            <p>2016-04-19 11:23:12</p>
          </div>
        </div>
        <div>
          <h4 className="title">
            <Link to="#">Around 27,000 trees are cut down each day</Link>
          </h4>
          <p>
            The world has over 3.04 trillion trees in the world. However, 27,000 of them are cut
            down daily to make toilet paper. This translates to about 9.8 million trees annually.
            One single recycled edition of the New York Times newspaper could save 75,000 tree
          </p>
        </div>
        <div>
          <Link className="read-card" to="#">
            Read more...
          </Link>
        </div>
        <div className="navigation-border">
          <ul className="article-cmmnt-lnks d-flex ps-2">
            <li className="active-helpful pe-3">
              <Link to="#" className="pe-0 ">
                <i className="fa fa-heart "></i>Helpful <span>23</span>
              </Link>
            </li>
            <li>
              <Link to="#" className="pe-3">
                <i className="fa fa-comment"></i>
                <span>23</span>
              </Link>
            </li>
            <li className="share-drop-btn">
              <Dropdown>
                <Dropdown.Toggle className="pe-3" id="dropdown-basic">
                  <i className="fa fa-share"></i> Share
                </Dropdown.Toggle>
                <Dropdown.Menu className="p-2">
                  <Dropdown.Item href="#/action-1">Facebook</Dropdown.Item>
                  <Dropdown.Item href="#/action-2">Twitter</Dropdown.Item>
                  <Dropdown.Item href="#/action-3">Google</Dropdown.Item>
                  <Dropdown.Item href="#/action-3">Linkedin</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export const CompanySimmer = () => {
  return (
    <>
      <div className="company-follow-card d-flex justify-content-between company-follow-loader loading-skeleton">
        <div className="d-flex align-items-top">
          <div className="company-icn me-3">
            <Link to="#">
              <img src={require('../../../assets/images/fortis.jpg')} alt="Company" />
            </Link>
          </div>
          <div className="company-discr">
            <h4>
              <Link to="#">Company Name</Link>
            </h4>
            <p>112 Followers</p>
          </div>
        </div>
        <Dropdown>
          <Dropdown.Toggle variant="success" id="dropdown-basic">
            <i className="fas fa-ellipsis-v"></i>
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item>Post New Job</Dropdown.Item>
            <Dropdown.Item>Edit Details</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </>
  );
};

export const GroupsListSkeleton = () => {
  return (
    <div className="manage-group-loader loading-skeleton">
      <div className={'company-follow'}>
        <div className="company-follow-card d-flex justify-content-between">
          <div className="d-flex align-items-top">
            <div className="company-icn me-3">
              <Link
                target="_blank"
              // to={`/user/group-detail/${data?.encrypted_id}`}
              // state={value}
              // onClick={(e) => {
              //   handleAddNavigate(e, data?.encrypted_id);
              // }}
              >
                <OverlayTrigger
                  trigger={['hover', 'focus']}
                  placement="top"
                  delay={{ show: 250, hide: 400 }}
                  // data={data}
                  // popperConfig={{
                  //   data
                  // }}
                  shouldUpdatePosition={true}
                // overlay={renderPopover}
                >
                  <img
                    src={require('../../../assets/images/default-male-avatar.png')}
                    alt="avatar"
                  />
                </OverlayTrigger>
              </Link>
            </div>
            <div className="company-discr">
              <h4>
                <Link to="#"> </Link>
              </h4>
            </div>
          </div>
          <Dropdown>
            <Dropdown.Toggle variant="success" id="dropdown-basic">
              <i className="fas fa-ellipsis-v"></i>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item href="#/group-setting"></Dropdown.Item>
              <Dropdown.Item></Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
    </div>
  );
};

export const Members = () => {
  return (
    <div className="member-loader loading-skeleton">
      <Col md={6}>
        <div className="like-card-detail member-card-detail d-flex">
          <div className="like-card-img">
            <Link to="#">
              <img src={require('../../../assets/images/default-male-avatar.png')} alt="img" />
            </Link>
          </div>
          <div className="like-card-dis">
            <h4> </h4>
            <p> </p>
            <ul className="mentr-action-btn">
              <li>
                <Link to="#"> </Link>
              </li>

              <li>
                <Link to="#"> </Link>
              </li>
            </ul>
            <ul className="mentr-action-btn">
              <li>
                <Link to="#"> </Link>
              </li>
            </ul>
            <ul className="mentr-action-btn">
              <li>
                <Link to="#"> </Link>
              </li>
              <li>
                <Link to="#"> </Link>
              </li>
            </ul>
          </div>
        </div>
      </Col>
    </div>
  );
};

export const ProfileSimmer = () => {
  return (
    <>
      <div className="technical-loader loading-skeleton">
        <Row className={'form-group active '}>
          <Col sm={7} xs={7}>
            <label htmlFor="firstname"> </label>
          </Col>
          <Col sm={5} xs={5}>
            <div className="d-flex align-items-center justify-content-end ">
              <span className="technical-rt-hd me-3"> </span>
              <span className="technical-rt-icn me-3">
                <img src={''} alt="" />
              </span>

              <Link to="" className="me-2 correct_btn"></Link>
              <Link to="" className="me-2 cross_btn">
                <i className="fas fa-times"></i>
              </Link>
              <Link to="#" className="me-2 trash_btn">
                <i className="far fa-trash-alt"></i>
              </Link>
              <Link to="">
                <i className="fa fa-chevron-right"></i>
              </Link>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export const ContactSimmer = () => {
  return (
    <>
      <div className="technical-loader loading-skeleton">
        <Row className={'form-group active '}>
          <Col sm={7} xs={7}>
            <label htmlFor="firstname"> </label>
          </Col>
          <Col sm={5} xs={5}>
            <div className="d-flex align-items-center justify-content-end ">
              <span className="technical-rt-hd me-3"> </span>
              <span className="technical-rt-icn me-3">
                <img src={''} alt="" />
              </span>

              <Link to="" className="me-2 correct_btn"></Link>
              <Link to="" className="me-2 cross_btn">
                <i className="fas fa-times"></i>
              </Link>
              <Link to="#" className="me-2 trash_btn">
                <i className="far fa-trash-alt"></i>
              </Link>
              <Link to="">
                <i className="fa fa-chevron-right"></i>
              </Link>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export const NotificationSimmer = () => {
  return (
    <>
      <div className="work-lst-card social-loader loading-skeleton px-2 py-3">
        <Row className="m-2 align-items-center ">
          <Col lg={10} xs={10}>
            <div className="work-lft noti-lft">
              <h4>New Message Notification</h4>
            </div>
          </Col>
          <Col lg={2} xs={2}>
            <div className="work-icn social-chk">
              <Form.Check />
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export const TechnicalSimmer = () => {
  return (
    <>
      <div className="technical-loader loading-skeleton">
        <Row className={'form-group active '}>
          <Col sm={7} xs={7}>
            <label htmlFor="firstname"> </label>
          </Col>

          <Col sm={5} xs={5}>
            <div className="d-flex align-items-center justify-content-end ">
              <span className="technical-rt-hd me-3"> </span>
              <span className="technical-rt-icn me-3">
                <img src={''} alt="" />
              </span>

              <Link to="" className="me-2 correct_btn"></Link>
              <Link to="" className="me-2 cross_btn">
                <i className="fas fa-times"></i>
              </Link>
              <Link to="#" className="me-2 trash_btn">
                <i className="far fa-trash-alt"></i>
              </Link>
              <Link to="">
                <i className="fa fa-chevron-right"></i>
              </Link>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export const CareerFormSimmer = () => {
  return (
    <>
      <div className="career_goal-loader loading-skeleton ">
        <Row className="form-group">
          <Col sm={10} xs={10}>
            <label className="" htmlFor="firstname">

            </label>
          </Col>
          <Col sm={2} xs={2}>
            <span className="green-frm-check d-none">
              <i className="fas fa-check"></i>
            </span>
            <Link
              to=""
              className="me-2 correct_btn"
            // onClick={submitHandler}
            >
              {/* {isLoading ? (
              <i className="fas fa-spinner fa-spin"></i>
            ) : (
              <i className="fas fa-check"></i>
            )} */}
            </Link>
            <Link to="" className="me-2 cross_btn">
              <i className="fas fa-times"></i>
            </Link>
            <Link to="">
              <i className="fa fa-chevron-right"></i>
            </Link>
          </Col>
          <Col sm={12} xs={12}>
            <div className="card-frm-detail">
              <p> </p>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export const FresherJobSimmer = () => {
  return (
    <>
      <div className="fresher-jobs-shimmer loading-skeleton">
        <div className="job-recomm-card-inr internship-card-inr">
          <div className="top-time-line mb-3">
            <p>
              <i className="far fa-clock me-1"></i>
            </p>
          </div>
          <div className="job-recomm-dis">
            <Link to="#"></Link>
            <h4>
              <Link
                to={` `}
              // onClick={(e) => {
              //   e.preventDefault();
              // }}
              ></Link>
            </h4>
            <p> </p>
            <p>
              <strong> </strong>{' '}
            </p>
            <ul className="job-recomm-addrs">
              <li>
                <i className="fas fa-map-marker-alt"></i>
              </li>
              <li>
                <i className="fas fa-users"></i>
              </li>
            </ul>
            <h2 className="innerhtml"> </h2>

            <p> </p>
            <Link className="view-detail-btn" to={` `} target="_blank"></Link>
          </div>
          <div className="job-recommd-ftr">
            <ul>
              <li>
                <Link to="#">
                  <i className="fas fa-thumbs-up me-1"></i>
                </Link>
              </li>
              <li>
                <Link>
                  <i className="fas fa-share me-1"></i>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export const NetworkSimmer = () => {
  return (
    <>
      <div className="network-jobs-shimmer loading-skeleton">
        <div className="job-recomm-card-inr internship-card-inr">
          <div className="top-time-line mb-3 d-flex align-items-center justify-content-between">
            <p>
              <i className="far fa-clock me-1"></i>
            </p>
            <p>
              <Link to="#"> </Link>
            </p>
          </div>
          <div className="job-recomm-dis">
            <Link to="#"></Link>
            <h4>
              <Link
                to={` `}
              // onClick={(e) => {
              //   e.preventDefault();
              // }}
              ></Link>
            </h4>
            <p> </p>
            <p>
              <strong> </strong>{' '}
            </p>
            <ul className="job-recomm-addrs">
              <li>
                <i className="fas fa-map-marker-alt"></i>
              </li>
              <li>
                <i className="fas fa-users"></i>
              </li>
            </ul>
            <Link className="view-detail-btn" to={` `} target="_blank"></Link>
          </div>
          <div className="job-recommd-ftr">
            <ul>
              {/* {data?.self_added ? (
                              ''
                            ) : ( */}
              <li>
                <Link className="apply-btn">
                  <i className="fas fa-check me-1"></i>
                </Link>

                {/* pop-up */}
              </li>
              {/* )} */}

              <li>
                <Link to="#">
                  <i className="fas fa-thumbs-up me-1"></i>
                </Link>
              </li>
              <li>
                <Link>
                  <i className="fas fa-share me-1"></i>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export const BusinessFormSimmer = () => {
  return (
    <>
      <div className="technical-loader loading-skeleton">
        <Row className={'form-group active '}>
          <Col sm={7} xs={7}>
            <label htmlFor="firstname"> </label>
          </Col>

          <Col sm={5} xs={5}>
            <div className="d-flex align-items-center justify-content-end ">
              <span className="technical-rt-hd me-3"> </span>
              <span className="technical-rt-icn me-3">
                <img src={''} alt="" />
              </span>

              <Link to="" className="me-2 correct_btn"></Link>
              <Link to="" className="me-2 cross_btn">
                <i className="fas fa-times"></i>
              </Link>
              <Link to="#" className="me-2 trash_btn">
                <i className="far fa-trash-alt"></i>
              </Link>
              <Link to="">
                <i className="fa fa-chevron-right"></i>
              </Link>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export const InterpersonalFormSimmer = () => {
  return (
    <>
      <div className="technical-loader loading-skeleton">
        <Row className={'form-group active '}>
          <Col sm={7} xs={7}>
            <label htmlFor="firstname"> </label>
          </Col>

          <Col sm={5} xs={5}>
            <div className="d-flex align-items-center justify-content-end ">
              <span className="technical-rt-hd me-3"> </span>
              <span className="technical-rt-icn me-3">
                <img src={''} alt="" />
              </span>

              <Link to="" className="me-2 correct_btn"></Link>
              <Link to="" className="me-2 cross_btn">
                <i className="fas fa-times"></i>
              </Link>
              <Link to="#" className="me-2 trash_btn">
                <i className="far fa-trash-alt"></i>
              </Link>
              <Link to="">
                <i className="fa fa-chevron-right"></i>
              </Link>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export const TechincalFormSimmer = () => {
  return (
    <>
      <div className="technical-loader loading-skeleton">
        <Row className={'form-group active '}>
          <Col sm={7} xs={7}>
            <label htmlFor="firstname"> </label>
          </Col>

          <Col sm={5} xs={5}>
            <div className="d-flex align-items-center justify-content-end ">
              <span className="technical-rt-hd me-3"> </span>
              <span className="technical-rt-icn me-3">
                <img src={''} alt="" />
              </span>

              <Link to="" className="me-2 correct_btn"></Link>
              <Link to="" className="me-2 cross_btn">
                <i className="fas fa-times"></i>
              </Link>
              <Link to="#" className="me-2 trash_btn">
                <i className="far fa-trash-alt"></i>
              </Link>
              <Link to="">
                <i className="fa fa-chevron-right"></i>
              </Link>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export const RecommendSentSimmer = () => {
  return (
    <>
      <div className="recommended-jobs-shimmer loading-skeleton">
        <div className="recommnd-card">
          <div className="recommnd-card-hd d-flex align-items-center justify-content-between">
            <h5>
              <Link to={` `}></Link>
            </h5>
            <span className="internship-tag"></span>
          </div>
          <div className="recomm-view-card d-flex flex-wrap align-items-center align-items-stretch">
            <div className="recomm-icn">
              <Link to={` `}>
                <img
                  src={require('../../../assets/images/default-male-avatar.png')}
                  alt="profile"
                />
              </Link>
            </div>
            <div className="recomm-view-name">
              <h4>
                <Link to={` `}></Link>
              </h4>
            </div>
            <div className="recomm-view-date">
              <p> </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export const RecommandRecievedSimmer = () => {
  return (
    <>
      <div className="recommended-jobs-shimmer loading-skeleton">
        <div className="recommnd-card">
          <div className="recommnd-card-hd d-flex align-items-center justify-content-between">
            <h5>
              <Link to={` `}></Link>
            </h5>
            <span className="internship-tag"></span>
          </div>
          <div className="recomm-view-card d-flex flex-wrap align-items-center align-items-stretch">
            <div className="recomm-icn">
              <Link to={` `}>
                <img
                  src={require('../../../assets/images/default-male-avatar.png')}
                  alt="profile"
                />
              </Link>
            </div>
            <div className="recomm-view-name">
              <h4>
                <Link to={` `}></Link>
              </h4>
            </div>
            <div className="recomm-view-date">
              <p> </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
