import { useEffect, useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import { useNavigate } from 'react-router-dom';
import AsyncSelect from 'react-select/async';
import { getCompanySpecialityDropDown } from '../../../services/AuthServices';
import showNotification from '../../../services/NotificationService';
import {
  isValidURL,
  loadCompanyCities,
  loadCompanySpecialitiesOptions,
  loadMainCompanyIndustries
} from '../../../super-admin/AdminHelper';
import { editCompanyFromUser, getProfessionalCompanyInfo } from '../../../services/CompanyServices';
import Loader from '../../commoncomponents/loader/loader-large';
import CKEditorComponent from './PostComponets/CKEditorComponent';

export default function UpdateCompanyDetails({ id }) {
  const [loading, setLoading] = useState(false);
  const [clicked, setClicked] = useState(false);
  const navigate = useNavigate();

  const [values, setValues] = useState({
    name: '',
    description: '',
    _wysihtml5_mode: '1',
    institute_type: 'company',
    company_size: '',
    website: '',
    industry_id: '',
    functional_areas: '',
    estyear: '',
    address: '',
    city: '',
    pincode: ''
  });

  useEffect(() => {
    getCompanyInfo();
  }, []);

  const getCompanyInfo = () => {
    setLoading(true);
    getProfessionalCompanyInfo(id).then((resp) => {
      setLoading(false);
      if (resp?.status === 200) {
        let data = resp?.data?.companyInfo;
        setValues({
          id: data?.timeline_id,
          name: data.name,
          description: data.description,
          _wysihtml5_mode: '1',
          institute_type: 'company',
          company_size: data.company_size,
          website: data.website,
          industry_id: data.industry_id,
          functional_areas: data.functional_areas,
          estyear: data.estyear,
          address: data.address,
          city: data.city_id,
          pincode: data.pincode
        });
        setDescription(data.description);
        setMainIndustry({
          id: data.industry_id,
          label: data.company_industry,
          value: data.industry_id
        });
        setJobLocation({
          id: data?.city_id,
          label: data?.city,
          value: data?.city_id
        });

        if (data.functional_areas !== '') {
          data.functional_areas.split(',').forEach((area) => {
            if (area) {
              getCompanySpecialityDropDown('', area).then((resp) => {
                let specialityData = resp.data[0];
                setCompanySpecialTies((prev) => {
                  return [
                    ...prev,
                    {
                      id: specialityData?.id,
                      value: specialityData?.id,
                      label: specialityData?.name
                    }
                  ];
                });
              });
            }
          });
        }
      }
    });
  };

  const [locationSearch, setLocationSearch] = useState('');
  const [jobLocation, setJobLocation] = useState('');
  const [mainIndustrySearch, setMainIndustrySearch] = useState('');
  const [mainIndustry, setMainIndustry] = useState('');
  const [companySpecialTies, setCompanySpecialTies] = useState([]);
  const [companySpecialtiesSearch, setCompanySpecialtiesSearch] = useState('');
  const [description, setDescription] = useState('');

  let yearOptions = [];

  for (let i = new Date().getFullYear(); i >= 2000; i--) {
    yearOptions.push(i);
  }

  const handleChange = (e) => {
    setValues({
      ...values,
      [e.target.name]: e.target.value
    });
  };

  const validation = (values) => {
    // if (ind == index) {
    let errrObj = {};
    if (values.name == '') {
      errrObj.name = 'Please enter name';
    }
    if (description == '') {
      errrObj.description = 'Please enter description';
    }
    if (values.company_size == '') {
      errrObj.company_size = 'Please select company size';
    }
    if (values.website == '') {
      errrObj.website = 'Please enter company website url';
    } else if (!isValidURL(values.website)) {
      errrObj.website = 'Please enter valid company website url';
    }
    if (mainIndustry == '') {
      errrObj.industry_id = 'Please select company industry';
    }
    if (companySpecialTies.length == 0) {
      errrObj.functional_areas = 'Please enter company functional areas';
    }
    if (values.estyear == '') {
      errrObj.estyear = 'Please select company year founded"';
    }
    if (values.address == '') {
      errrObj.address = 'Please select company address';
    }
    if (jobLocation == '') {
      errrObj.city = 'Please select company location';
    }
    if (values.pincode == '') {
      errrObj.pincode = 'Please enter company postal code';
    }
    return errrObj;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setClicked(true);
    if (!Object.keys(validation(values)).length) {
      let body = {
        description: description,
        name: values.name,
        company_size: values.company_size,
        website: values.website,
        industry_id: mainIndustry.id,
        functional_areas: companySpecialTies?.map((data) => data.id).join(','),
        estyear: values.estyear,
        address: values.address,
        city: jobLocation.id,
        pincode: values.pincode,
        _wysihtml5_mode: '1',
        institute_type: 'company'
      };
      editCompanyFromUser(values.id, body).then((resp) => {
        if (resp?.status === 200) {
          showNotification('success', resp?.data?.message);
        }
      });
    }
  };

  //ompany option

  const CompanytypeOptions = [
    { id: 1, value: 'Corporate' },
    { id: 2, value: 'Educational Institute' },
    { id: 3, value: 'Non-Govt Oraganisation' },
    { id: 4, value: 'Non Profit Organisation' },
    { id: 5, value: 'Govt Agency' }
  ];

  return (
    <>
      <div className="post-job more-option-detail bg-white">
        <h4> Company About </h4>
        <div className="p-4">
          <Form.Group as={Row} className="mb-3" controlId="formPlaintextEmail">
            <Form.Label column sm="12">
              Company Title <sup className="text-danger">*</sup>
            </Form.Label>
            <Col sm="12">
              <Form.Control
                name="name"
                type="text"
                placeholder="Enter name"
                value={values.name}
                // value={companyView?.name}
                onChange={handleChange}
              />
              <div className={'danger-frm'}>{clicked && validation(values)?.name}</div>
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-3" controlId="formPlaintextEmail">
            <Form.Label column sm="12">
              Company Description <sup className="text-danger">*</sup>
            </Form.Label>
            <Col sm="12">
              <Form.Group className="mb-3" controlId="formBasicPassword">
                <CKEditorComponent
                  isToolBar={true}
                  value={values?.description}
                  // value={companyView?.description}
                  setLatestPost={setDescription}
                  latestPost={description}
                />
                <div className={'danger-frm'}>{clicked && validation(values)?.description}</div>
              </Form.Group>
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-4" controlId="formHorizontalType">
            <Form.Label column sm={4}>
              Company Type <sup className="text-danger">*</sup>
            </Form.Label>
            <Col sm={8}>
              <Form.Control
                as="select"
                name="company_type"
                // value={companyView?.ownership}
                value={values?.company_type}
                // value={postJobDetail?.company_type}
                onChange={handleChange}
              >
                {CompanytypeOptions &&
                  CompanytypeOptions?.map((data, index) => (
                    <option key={index} value={data?.value}>
                      {data?.value}
                    </option>
                  ))}
              </Form.Control>
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-3" controlId="formPlaintextEmail">
            <Form.Label column sm="4">
              Company Size <sup className="text-danger">*</sup>
            </Form.Label>
            <Col sm="8">
              <Form.Select
                aria-label="Default select example"
                value={values.company_size}
                // value={companyView?.company_size}
                onChange={(e) => {
                  setValues({
                    ...values,
                    company_size: e.target.value
                  });
                }}
              >
                <option>Select Company Size</option>
                <option value="1-10">1-10 Employees</option>
                <option value="11-50">11-50 Employees</option>
                <option value="51-200">51-200 Employees</option>
                <option value="201-500">201-500 Employees</option>
                <option value="501-1000">501-1000 Employees</option>
                <option value="1001-5000">1001-5000 Employees</option>
                <option value="5001-10000">5001-10000 Employees</option>
              </Form.Select>
            </Col>
            <div className={'danger-frm'}>{clicked && validation(values)?.company_size}</div>
          </Form.Group>
          <Form.Group as={Row} className="mb-3" controlId="formPlaintextEmail">
            <Form.Label column sm="4">
              Company Website URL <sup className="text-danger">*</sup>
            </Form.Label>
            <Col sm="8">
              <Form.Control
                name="website"
                type="text"
                placeholder="Enter name"
                value={values.website}
                // value={companyView?.website}
                onChange={handleChange}
              />
              <div className={'danger-frm'}>{clicked && validation(values)?.website}</div>
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-3" controlId="formPlaintextEmail">
            <Form.Label column sm="4">
              Main Company Industry <sup className="text-danger">*</sup>
            </Form.Label>
            <Col sm="8">
              <AsyncSelect
                cacheOptions
                defaultOptions
                value={mainIndustry}
                getOptionLabel={(e) => e.label}
                getOptionValue={(e) => e.value}
                loadOptions={loadMainCompanyIndustries}
                onInputChange={(newValue) => setMainIndustrySearch(newValue)}
                className="basic-single mt-1"
                name="industry_id"
                onChange={setMainIndustry}
              />
              <div className={'danger-frm'}>{clicked && validation(values)?.industry_id}</div>
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-3" controlId="formPlaintextEmail">
            <Form.Label column sm="12">
              Company Functional Areas <sup className="text-danger">*</sup>
            </Form.Label>
            <Col sm="12">
              <AsyncSelect
                cacheOptions
                defaultOptions
                value={companySpecialTies}
                getOptionLabel={(e) => e.label}
                getOptionValue={(e) => e.value}
                isMulti
                loadOptions={loadCompanySpecialitiesOptions}
                onInputChange={(newValue) => setCompanySpecialtiesSearch(newValue)}
                className="basic-single mt-1"
                name="location"
                onChange={setCompanySpecialTies}
              />
            </Col>
            <div className={'danger-frm'}>{clicked && validation(values)?.functional_areas}</div>
          </Form.Group>
          <Form.Group as={Row} className="mb-3" controlId="formPlaintextEmail">
            <Form.Label column sm="4">
              Year Founded <sup className="text-danger">*</sup>
            </Form.Label>
            <Col sm="8">
              <Form.Select
                aria-label="Default select example"
                value={parseInt(values.estyear)}
                // value={companyView?.estyear}
                onChange={(e) => {
                  setValues({
                    ...values,
                    estyear: e.target.value
                  });
                }}
              >
                <option>Select year founded in</option>
                {yearOptions.map((year) => {
                  return (
                    <option key={year} value={year.toString()}>
                      {year}
                    </option>
                  );
                })}
              </Form.Select>{' '}
            </Col>
            <div className={'danger-frm'}>{clicked && validation(values)?.estyear}</div>
          </Form.Group>
        </div>
      </div>
      <div className="post-job more-option-detail bg-white">
        <h4> Company Location </h4>
        <div className="p-4">
          <Form.Group as={Row} className="mb-3" controlId="formPlaintextEmail">
            <Form.Label column sm="4">
              Company Address <sup className="text-danger">*</sup>
            </Form.Label>
            <Col sm="8">
              <Form.Control
                type="text"
                placeholder="Enter company address"
                value={values.address}
                // value={companyView?.address}
                name="address"
                onChange={handleChange}
              />
              <div className={'danger-frm'}>{clicked && validation(values)?.address}</div>
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-3" controlId="formPlaintextEmail">
            <Form.Label column sm="4">
              City <sup className="text-danger">*</sup>
            </Form.Label>
            <Col sm="8">
              <AsyncSelect
                cacheOptions
                defaultOptions
                value={jobLocation}
                getOptionLabel={(e) => e.label}
                getOptionValue={(e) => e.value}
                loadOptions={loadCompanyCities}
                onInputChange={(newValue) => setLocationSearch(newValue)}
                menuIsOpen={locationSearch !== '' ? true : false}
                className="basic-single mt-1"
                name="location"
                onChange={setJobLocation}
              />
              <div className={'danger-frm'}>{clicked && validation(values)?.city}</div>
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-3" controlId="formPlaintextEmail">
            <Form.Label column sm="4">
              Pin Code <sup className="text-danger">*</sup>
            </Form.Label>
            <Col sm="8">
              <Form.Control
                type="text"
                placeholder="Enter postal code"
                value={values.pincode}
                // value={companyView?.pincode}
                name="pincode"
                onChange={(e) => {
                  const re = /^[0-9\b]+$/;
                  // if value is not blank, then test the regex
                  if (e.target.value === '' || re.test(e.target.value)) {
                    handleChange(e);
                  }
                }}
              />
              <div className={'danger-frm'}>{clicked && validation(values)?.pincode}</div>
            </Col>
          </Form.Group>
          <div className="accnt-lft-btn d-flex align-items-center justify-content-end">
            <button className="accnt-btn " type="button" onClick={handleSubmit}>
              Save Details
            </button>
          </div>
        </div>
      </div>
      {loading ? <Loader /> : null}
    </>
  );
}
