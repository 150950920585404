import React, { useCallback, useEffect, useState } from 'react';
import { Container, TabContent } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import Cropper from 'react-easy-crop';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { getCroppedImg } from '../../../globals/canvasUtils';
import { post_publisher_youtube_placeholder } from '../../../globals/constants';
import { truncate } from '../../../globals/helper';
import useDebounce from '../../../globals/useDebounce';
import useDocumentTitle from '../../../globals/useDocumentTitle';
import { deleteMediaFileAction, youtubeVideoAction } from '../../../redux/action';
import showNotification from '../../../services/NotificationService';
import {
  SocialChapterSetting,
  addAdminForChapter,
  addSocialChapterPost,
  getPeopleListForChepterAdd,
  getPublisherBox,
  getSocialChapterDetail,
  getSocialChapterPost,
  removeAdminForChapter,
  removeMember,
  socialChapterJoin
} from '../../../services/SocialServices';
import { changeCoverImage, removeCoverImage } from '../../../services/UserServices';
import { MiniFooter } from '../../MiniFooter';
import HeaderLogin from '../../commoncomponents/HeaderLogin';
import MembersCard from '../../commoncomponents/newsfeeds/MembersCard';
import NewsSidebar from '../../commoncomponents/newsfeeds/NewsSidebar';
import Upcoming from '../../commoncomponents/newsfeeds/Upcoming';
import WhatsNew from '../../commoncomponents/newsfeeds/WhatsNew';
import ChapterPeopleList from './PostComponets/ChapterPeopleList';
import ChapterPostLayout from './PostComponets/ChapterPostLayout';
import PostBottomMedia from './PostComponets/PostBottomMedia';
import TopTabbings from './TopTabbings';
import NoDataFound from '../../commoncomponents/NoDataFound';

/*eslint-disable */

function ChapterDetail() {
  useDocumentTitle('Almabay');
  const [searchParms, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();

  const event_id = searchParms.get('eventKey');
  const [clicked, setClicked] = useState(false);
  const [showPeople, setShowPeople] = useState(false);
  const handleClose = () => {
    setShowPeople(false);
  };
  const handleShow = () => setShowPeople(true);

  const [latestPost, setLatestPost] = useState('');
  const [type, setType] = useState('all');

  const [checkPost, setCheckPost] = useState([]);
  const [selectedFile, setSelectedFile] = useState([]);
  const [isSelected, setIsSelected] = useState(false);

  const [loading, setLoading] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [pageNum, setPageNum] = useState(1);
  const [chapterData, setChapterData] = useState('');
  const id = useParams()?.encrypted_id;
  const userDetail = useSelector((state) => state?.user?.details);
  const [active, setActive] = useState(event_id || 'first');
  const [list, setList] = useState([]);

  const [editBackGround, setEditBackGround] = useState(false);
  const [selectedImage, setSelectedImage] = useState('');
  const [recipientId, setrecipientId] = useState('');
  const [memberCount, setMemberCount] = useState('');
  const [userType, setUserType] = useState('members');
  const [peoepleIds, setPeopleIds] = useState([]);
  const [memberLoad, setMemberLoad] = useState(false);
  const [emojiList, setEmojiList] = useState([]);
  const [showEmoji, setShowEmoji] = useState(false);
  const [uploadFiles, setUploadFiles] = useState([]);
  const [isSelectedFile, setIsSelectedFile] = useState(false);
  const [postLoading, setPostLoading] = useState(false);
  const [soundCloud, setSoundCloud] = useState(false);
  const [youtubeVideoCloud, setYoutubeVideoCloud] = useState(false);
  const [postGoogleMapState, setPostGoogleMapState] = useState(false);
  const [loadingState, setLoadingState] = useState('loading');

  const result = useSelector((state) => state?.youtubeVideoList?.videos);
  const [disableInputBox, setDisableInputBox] = useState(false);
  const [search, setSearch] = useState('');
  const [followStatus, setFollowStatus] = useState('');
  const [groupAdmin, setGroupAdmin] = useState(false);
  const [totalData, setTotalData] = useState('');

  useEffect(() => {
    getDetail(id);
    getPostListData();
    getPeopleList(userType);
    getEmojiIcons();
  }, [id, type]);

  useEffect(() => {
    if (pageNum <= totalPages) {
      getPostListData();
    }
  }, [pageNum]);

  // useEffect(() => {
  //   if (active === 'first') {
  //     setType('all');
  //   } else if (active === 'second') {

  //     setType('videos');
  //     setCheckPost([]);
  //   } else if (active === 'third') {

  //     setType('photos');
  //     setCheckPost([]);
  //   }
  //   //  else {

  //   //   setType('all');
  //   // }
  // }, [active]);

  const getDetail = () => {
    setLoading(true);
    getSocialChapterDetail(id).then((res) => {
      setLoading(false);
      if (res?.status === 200) {
        const data = res?.data?.data?.group;
        setChapterData(data);
        setSetting({
          name: data?.name,
          about: data?.about,
          group_privacy: data?.chapter_privacy,
          add_privacy: data?.add_privacy,
          timeline_post_privacy: data?.timeline_post_privacy
        });
        setrecipientId(data?.id);
        setFollowStatus(res?.data?.data?.follow_status);
        setGroupAdmin(res?.data?.data?.admin);
      }
    });
  };

  const getPeopleList = (type, q = '') => {
    if (q != 'stopLoading') {
      setMemberLoad(true);
    }

    let body = {
      type: type ? type : userType
    };
    getPeopleListForChepterAdd(id, q == 'stopLoading' ? '' : q, body).then((resp) => {
      if (resp.statusText === 'OK') {
        setMemberCount(resp?.data?.data);
        setUserType(resp?.data?.data?.count_members !== 0 ? 'members' : 'admins');
        setPeopleIds(resp?.data?.data?.peoples?.map((people) => people?.encrypted_id));
        setList(resp?.data?.data?.peoples);

        setMemberLoad(false);
      } else {
        setMemberLoad(false);
      }
    });
  };

  const getPostListData = async (isTrue, postId) => {
    if (isTrue !== 'delete' || isTrue !== 'share') {
      setLoading(true);
      setDisableInputBox(true);
      setPostLoading(true);
    }
    await getSocialChapterPost(id, pageNum, type).then((res) => {
      setLoadingState('');
      setPostLoading(false);
      setLoading(false);
      if (res?.status === 200) {
        setPostLoading(false);
        setLoading(false);
        setDisableInputBox(false);
        if (isTrue === true) {
          let arra1 = checkPost.map((item) => {
            const item2 = res.data?.data?.stories?.find((i2) => i2.id == item.id);
            return item ? { ...item, ...item2 } : item2;
          });
          setCheckPost(arra1);
          setPostLoading(false);
          setLatestPost('');
        } else {
          if (res?.data?.data) {
            setCheckPost(
              pageNum > 1 ? [...checkPost, ...res?.data?.data?.stories] : res?.data?.data?.stories
            );
          } else {
            setCheckPost([]);
          }

          if (isTrue == 'share') {
            let checkPostIDs = checkPost?.map((data) => data?.id);

            let arra1 = checkPost.map((item) => {
              const item2 = res.data?.data?.stories?.find((i2) => i2.id == item.id);
              return item ? { ...item, ...item2 } : item2;
            });
            getSocialChapterPost(id, 1, type).then((res2) => {
              let filterData = res2.data?.data?.stories.filter((item) => {
                return !checkPostIDs.includes(item?.id);
              });
              setCheckPost([...filterData, ...arra1]);
            });
          }

          if (isTrue == 'unshare') {
            let arra1 = checkPost
              .map((item) => {
                const item2 = res.data?.data?.stories?.find((i2) => i2.id == item.id);
                return item ? { ...item, ...item2 } : item2;
              })
              ?.filter((data) => !(data?.post_id == postId && data?.via_type == 'share'));
            setCheckPost(arra1);
          }

          if (isTrue == 'makePost') {
            setCheckPost(res?.data?.data?.stories);
          }
        }

        setTotalPages(Math.ceil(res?.data?.data?.total_records / 10));
        setTotalData(res?.data?.data?.total_records);
      } else {
        setLoading(false);
        setDisableInputBox(false);
      }
    });
  };

  const [setting, setSetting] = useState({
    name: '',
    about: '',
    group_privacy: 'members',
    add_privacy: '',
    timeline_post_privacy: 'members',
    timeline_id: ''
  });

  const validate = () => {
    let errors = {};
    if (!setting.name.trim()) {
      errors.name = 'This field is required.';
    }

    return errors;
  };

  const handleSave = () => {
    setClicked(true);
    let body = {
      name: setting?.name,
      about: setting?.about,
      group_privacy: setting?.group_privacy,
      add_privacy: setting?.add_privacy,
      timeline_post_privacy: setting?.timeline_post_privacy,
      timeline_id: chapterData?.id
    };
    if (!Object.keys(validate()).length) {
      SocialChapterSetting(body).then((resp) => {
        if (resp?.status === 200) {
          showNotification('success', resp?.data?.message);
          getDetail();
        }
      });
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSetting({ ...setting, [name]: value });
  };

  const checkLatestpost = (e) => {
    e.preventDefault();
    setPostLoading(true);

    let formData = new FormData();
    formData.append(`text`, latestPost.trim());
    formData.append(`recipient_id`, recipientId);
    formData.append(`timeline_id`, '');
    formData.append(`post_privacy[]`, 5);
    for (let i in selectedFile) {
      formData.append(`photos[]`, selectedFile[i]);
    }
    for (let i in uploadFiles) {
      formData.append('files[]', uploadFiles[i]);
    }
    addSocialChapterPost(formData).then((res) => {
      if (res?.status === 200) {
        showNotification('success', res?.data?.message);
        setSelectedFile([]);
        setUploadFiles([]);
        setIsSelected(false);
        setIsSelectedFile(false);
        setLatestPost('');
        getPostListData('makePost');
        setPageNum(1);
      }
    });
  };

  const makePost = (e) => {
    if (latestPost?.trim() !== '' || selectedFile.length !== 0 || uploadFiles.length !== 0) {
      checkLatestpost(e);
    } else {
      showNotification(
        'danger',
        'This status update appears to be blank. Please write something or attach photo to update your status.'
      );
    }
  };

  const onIncrementPageNo = () => {
    setPageNum((no) => no + 1);
  };

  const handleRemove = (data) => {
    let body = {
      chapter_id: id,
      member_id: data?.id
    };
    if (confirm('Do you want to Remove member ?') == true) {
      setLoading(true);
      removeMember(body).then((resp) => {
        setLoading(false);
        if (resp?.status == 200) {
          showNotification('success', resp?.data?.message);
          getPeopleList(userType);
        }
      });
    } else {
      return;
    }
  };
  const [imageSrc, setImageSrc] = React.useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [rotation, setRotation] = useState(0);
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);

  const showCroppedImage = useCallback(
    async (croppedArea, croppedAreaPixels) => {
      try {
        const croppedImage = await getCroppedImg(imageSrc, croppedAreaPixels);
        setCroppedImage(croppedImage);
      } catch (e) {
        console.error(e);
      }
    },
    [imageSrc, croppedAreaPixels]
  );

  const onClose = useCallback(() => {
    setCroppedImage(null);
  }, []);

  const onFileChange = async (e) => {
    let files = e?.target?.files;
    if (files && files?.length > 0) {
      const targetfileobj = [...files];
      targetfileobj?.map(async (data) => {
        if (
          !data?.type?.includes('image') &&
          (data?.type?.includes('application') ||
            data?.type?.includes('audio') ||
            data?.type?.includes('video'))
        ) {
          showNotification('danger', ' Please use PNG or JPG types of images ', 'random');
          setImageSrc('');
          setSelectedImage('');
          return;
        } else {
          const file = files[0];
          let imageDataUrl = await readFile(file);
          setSelectedImage(file);
          setImageSrc(imageDataUrl);
        }
      });
    }
  };

  const handeSaveImage = (e) => {
    e.preventDefault();
    let formData = new FormData();
    formData.append(`image`, selectedImage);
    formData.append(`position`, crop?.y);
    formData.append(`timeline_id`, chapterData?.id);
    if (selectedImage != '') {
      changeCoverImage(formData).then((res) => {
        if (res?.status == 200) {
          getDetail();
          setSelectedImage('');
          setEditBackGround(false);
        }
      });
    } else {
      setEditBackGround(false);
    }
  };

  const removeImage = (e) => {
    e.preventDefault();
    let formData = new FormData();

    formData.append(`timeline_id`, chapterData?.id);
    removeCoverImage(formData).then((res) => {
      if (res?.status == 200) {
        getDetail();
        setEditBackGround(false);
      }
    });
  };

  const checkIsFollower = () => {
    if (peoepleIds?.length == 0) {
      return true;
    }
    if (peoepleIds?.includes(userDetail?.encrypted_id)) {
      return true;
    } else {
      return false;
    }
  };

  const getEmojiIcons = () => {
    getPublisherBox().then((res) => {
      if (res?.status == 200) {
        let arr = [];
        let data = res?.data?.data?.emoticons;
        for (let i in data) {
          arr.push({ id: i, label: data[i], value: data[i] });
        }
        setEmojiList(arr);
      } else {
        setEmojiList([]);
      }
    });
  };

  const toggleMediaGroupFun = (chosen_input_selector = '') => {
    setSoundCloud(false);
    setYoutubeVideoCloud(false);
    setPostGoogleMapState(false);

    if (chosen_input_selector == 'soundcloud-wrapper') {
      setSoundCloud(true);
    } else if (chosen_input_selector == 'youtube-wrapper') {
      setYoutubeVideoCloud(!youtubeVideoCloud);
    } else if (chosen_input_selector == 'google-map-wrapper') {
      setPostGoogleMapState(true);
    }
  };
  const newParagraph = (e) => {
    if (e.key === 'Enter') {
    }
  };

  const debouncedSearchTerm = useDebounce(search, 500);

  useEffect(() => {
    if (debouncedSearchTerm) {
      dispatch(youtubeVideoAction(search));
    } else {
      dispatch(youtubeVideoAction());
    }
  }, [debouncedSearchTerm]);

  const deleteFile = async (post_id, media_id) => {
    const message = window.confirm('Are you sure you want to remove this file?');
    if (message) {
      dispatch(deleteMediaFileAction(post_id, media_id));
      getPostListData();
    }
  };

  const handleActive = () => {
    handleClose();
    setActive('fourth');
    getDetail();
    getPeopleList(userType);
  };

  const handleAddAdmin = (e, adminId) => {
    e.preventDefault();
    let body = {
      chapter_id: chapterData?.id,
      admin_id: adminId
    };

    addAdminForChapter(body).then((res) => {
      if (res?.status == 200) {
        showNotification('success', res?.data?.message);
        getPeopleList(userType, 'stopLoading');
      }
    });
  };
  const handleRemoveAdmin = (e, adminId) => {
    e.preventDefault();
    let body = {
      chapter_id: chapterData?.id,
      admin_id: adminId
    };

    removeAdminForChapter(body).then((res) => {
      if (res?.status == 200) {
        showNotification('success', res?.data?.message);
        getPeopleList(userType, 'stopLoading');
      }
    });
  };

  /**
   *
   * @param {*} e
   * @param {*} id
   */
  const handleJoinChapter = (e, id) => {
    e.preventDefault();
    let body = {
      following_id: id
    };
    let formData = new FormData();
    for (let i in body) {
      formData.append(`${i}`, body[i]);
    }

    socialChapterJoin(formData).then((res) => {
      if (res?.status === 200) {
        getDetail();
      } else {
        showNotification('success', res?.data?.message);
      }
    });
  };

  return (
    <>
      <HeaderLogin />
      <div className="header-lnk-feeds">
        <div className="container">
          <TopTabbings state={'social'} />
        </div>
      </div>
      <section className="feed-sec bg-grey">
        <Container>
          <Row>
            <Col xs={12} md={2}>
              <NewsSidebar state="social" current="chapter" />
            </Col>
            <Col md={10}>
              <div className="page-detail-sec py-3">
                <div className="page-detial-cover">
                  {selectedImage !== '' ? (
                    <div className="cropContainer">
                      {' '}
                      <Cropper
                        image={imageSrc}
                        crop={crop}
                        zoom={zoom}
                        onCropChange={setCrop}
                        onCropComplete={showCroppedImage}
                        onZoomChange={setZoom}
                        showGrid={false}
                        objectFit={'horizontal-cover'}
                      />
                    </div>
                  ) : (
                    <img
                      src={
                        chapterData?.actual_cover_url
                          ? chapterData?.actual_cover_url
                          : require('../../../assets/images/default-cover.png')
                      }
                      alt="cover"
                      style={{
                        top:
                          +chapterData?.cover_position < 0
                            ? chapterData?.cover_position * 2 + 'px'
                            : -chapterData?.cover_position / 2 + 'px'
                      }}
                    />
                  )}

                  <div className="cover-disc d-flex align-items-center justify-content-between">
                    <div>
                      <h4>
                        <Link to="#">
                          {chapterData?.first_name} {chapterData?.last_name}
                        </Link>
                      </h4>
                      <p style={{ textTransform: 'capitalize' }} className="verified-check">
                        {chapterData?.chapter_privacy} Chapter
                        {chapterData?.verified == 1 && (
                          <span className="verification ms-2 mt-1">
                            <i className="fa fa-check fa-stack-1x fa-inverse"></i>
                            <i className="fas fa-certificate crfict"></i>
                          </span>
                        )}
                      </p>
                    </div>

                    {!groupAdmin && chapterData?.chapter_privacy == 'open' && (
                      <>
                        {followStatus == 'not-following' && (
                          <Button
                            className="join-btn"
                            onClick={(e) => handleJoinChapter(e, chapterData?.id)}>
                            Join
                          </Button>
                        )}
                        {followStatus == 'requested' && (
                          <Button className="join-btn">Requested </Button>
                        )}
                        {followStatus == 'following' && (
                          <Button
                            className="join-btn"
                            // onClick={(e) => leaveClubJoined(e, groupDetail?.id)}
                          >
                            Leave Chapter
                          </Button>
                        )}
                      </>
                    )}
                  </div>

                  {editBackGround == false ? (
                    <>
                      {' '}
                      {memberCount?.admin && (
                        <Link
                          to="#"
                          className="page-cover-edit"
                          onClick={() => setEditBackGround(true)}>
                          <i className="fas fa-camera me-2"></i> Edit Background
                        </Link>
                      )}
                    </>
                  ) : (
                    <ul className="page-cover-function">
                      <li>
                        <Link to="" onClick={(e) => handeSaveImage(e)}>
                          Save
                        </Link>
                      </li>
                      <li>
                        <span className="change-img-card">Change Image</span>
                        <Form.Control type="file" onChange={onFileChange} />
                      </li>
                      <li>
                        <Link to="#" onClick={(e) => removeImage(e)}>
                          Remove Image
                        </Link>
                      </li>
                      <li>
                        <Link to="#" onClick={() => setEditBackGround(false)}>
                          <i className="fas fa-times"></i>
                        </Link>
                      </li>
                    </ul>
                  )}
                </div>
                <div className="pages-detail-tabs">
                  <Tab.Container
                    id="left-tabs-example"
                    activeKey={showPeople == true ? '' : active}>
                    <div className="pages-detail-tabhd d-flex align-items-center justify-content-between mb-3">
                      <div>
                        <Nav variant="pills" className="">
                          <Nav.Item>
                            <Nav.Link
                              eventKey="first"
                              onClick={() => {
                                handleClose();
                                setType('all');
                                setActive('first');
                                setPageNum(1);
                              }}>
                              Discussion
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link
                              eventKey="second"
                              onClick={() => {
                                handleClose();
                                setActive('second');
                                setType('videos');
                                setPageNum(1);
                              }}>
                              Videos
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link
                              eventKey="third"
                              onClick={() => {
                                handleClose();
                                setActive('third');
                                setType('photos');
                                setPageNum(1);

                                // getPeopleList()
                              }}>
                              Photos
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link
                              eventKey="fourth"
                              onClick={() => {
                                handleClose();
                                setActive('fourth');
                                setType('all');
                                getDetail();
                                getPeopleList(userType);
                              }}>
                              Members
                            </Nav.Link>
                          </Nav.Item>
                          {memberCount?.admin == true && (
                            <Nav.Item>
                              <Nav.Link
                                eventKey="fifth"
                                onClick={() => {
                                  handleClose();
                                  setActive('fifth');
                                  setType('all');
                                  setPageNum(1);
                                }}>
                                Settings
                              </Nav.Link>
                            </Nav.Item>
                          )}

                          <Nav.Item>
                            <Nav.Link
                              eventKey="sixth"
                              as={Link}
                              target="_blank"
                              to={`/chapters/ginvite/${id}`}
                              onClick={() => {
                                handleClose();
                                setType('all');
                                setActive('sixth');
                              }}>
                              Invite
                            </Nav.Link>
                          </Nav.Item>
                        </Nav>
                      </div>
                      {chapterData?.add_privacy == 'admins' && memberCount?.admin == true && (
                        <div className="text-end">
                          <Button
                            className="create-group-btn"
                            onClick={() => {
                              handleShow();
                              setActive('');
                              getPeopleList('add_members');
                            }}>
                            + Add Members
                          </Button>
                        </div>
                      )}
                      {chapterData?.add_privacy == 'members' && checkIsFollower() && (
                        <div className="text-end">
                          <Button
                            className="create-group-btn"
                            onClick={() => {
                              handleShow();
                              setActive('');
                              getPeopleList('add_members');
                            }}>
                            + Add Members
                          </Button>
                        </div>
                      )}
                    </div>
                    {showPeople ? (
                      <>
                        <TabContent>
                          <ChapterPeopleList
                            id={chapterData?.encrypted_id}
                            chapter_id={chapterData?.id}
                          />
                        </TabContent>
                      </>
                    ) : (
                      <div className="detail-tab-cntnt">
                        <Tab.Content>
                          <Tab.Pane eventKey="first">
                            <Row>
                              <Col xs={12} md={8}>
                                {chapterData?.add_privacy == 'admins' &&
                                  memberCount?.admin == true && (
                                    <div className="write-on-wall mb-3">
                                      <h4>
                                        <i className="fas fa-edit me-2"></i> What's going on?
                                      </h4>
                                      <textarea
                                        rows={5}
                                        style={{ width: '100%', resize: 'none' }}
                                        placeholder={'Write something... #hashtags'}
                                        onChange={(e) => {
                                          setLatestPost(e.target.value);
                                        }}
                                        disabled={postLoading}
                                        onKeyDown={(e) => newParagraph(e)}
                                        value={latestPost}></textarea>
                                      {soundCloud && (
                                        <div className="upload-img-detail d-flex align-items-center justify-content-between">
                                          <i className="fa fa-music"></i>
                                          <input
                                            className="soundcloud-input"
                                            type="text"
                                            autoFocus
                                            // onKeyUp={() => SK_searchSoundcloud()}
                                            placeholder={post_publisher_soundcloud_placeholder}
                                          />
                                          {/* <div className="input-result-wrapper"></div> */}
                                        </div>
                                      )}
                                      {/* https://www.youtube.com/watch?v=Z4Kgs9XiMGQ */}
                                      {youtubeVideoCloud && (
                                        <div className="upload-img-detail d-flex align-items-center justify-content-start">
                                          <i className="fa fa-film me-2"></i>
                                          <input
                                            className="youtube-input"
                                            type="text"
                                            onChange={(e) => {
                                              setSearch(e.target.value);
                                            }}
                                            placeholder={post_publisher_youtube_placeholder}
                                          />
                                          <div className="input-result-wrapper"></div>
                                        </div>
                                      )}
                                      {postGoogleMapState && (
                                        <div className="upload-img-detail d-flex align-items-center justify-content-between">
                                          <i className="fa fa-map-marker "></i>
                                          <input
                                            className="youtube-input"
                                            type="text"
                                            // onKeyUp={() => SK_searchYoutube()}
                                            placeholder={post_publisher_youtube_placeholder}
                                          />

                                          {/* <input id="autocomplete" className="google-map-input" name="google_map_name" type="text" value="" placeholder="<?php echo $this->lang->line('post_publisher_googlemap_placeholder'); ?>" apply-google-places ></input> */}
                                        </div>
                                      )}

                                      {isSelected && (
                                        <div className="upload-img-detail d-flex align-items-center justify-content-between">
                                          <p>
                                            {selectedFile?.length > 1
                                              ? `${selectedFile.length} photo(s) selected`
                                              : `${selectedFile.length} photo selected`}
                                          </p>
                                          <Link
                                            to="#"
                                            onClick={() => {
                                              setIsSelected(false);
                                              setIsSelectedFile(false);
                                              setSelectedFile([]);
                                              setUploadFiles([]);
                                            }}>
                                            <i className="fas fa-times"></i>
                                          </Link>
                                        </div>
                                      )}

                                      {isSelectedFile && (
                                        <div className="upload-img-detail d-flex align-items-center justify-content-between">
                                          <p>
                                            {uploadFiles?.length > 1
                                              ? `${uploadFiles.length} file(s) selected`
                                              : `${uploadFiles.length} file selected`}
                                          </p>
                                          <Link
                                            to="#"
                                            onClick={() => {
                                              setIsSelected(false);
                                              setIsSelectedFile(false);
                                              setSelectedFile([]);
                                              setUploadFiles([]);
                                            }}>
                                            <i className="fas fa-times"></i>
                                          </Link>
                                        </div>
                                      )}
                                      {showEmoji && (
                                        <div className="upload-img-detail d-flex align-items-center justify-content-between">
                                          {emojiList?.length !== 0 && (
                                            <>
                                              {emojiList?.map((data) => {
                                                return (
                                                  <Link
                                                    to="#"
                                                    onClick={(e) => {
                                                      e.preventDefault();
                                                      setLatestPost(latestPost + data?.id);
                                                    }}>
                                                    <img src={data?.value} />
                                                  </Link>
                                                );
                                              })}
                                            </>
                                          )}
                                        </div>
                                      )}
                                      {result.length > 0 && youtubeVideoCloud ? (
                                        <div className="input_result_wrapper">
                                          {result.map((item, i) => {
                                            return (
                                              <div className="api_data_wrapper">
                                                <table
                                                  border={0}
                                                  width={'100%'}
                                                  cellSpacing={0}
                                                  cellPadding={0}>
                                                  <tbody>
                                                    <tr>
                                                      <td
                                                        width={'40%'}
                                                        align="left"
                                                        valign="middle">
                                                        <img
                                                          className="thumbnail"
                                                          width={32}
                                                          height={32}
                                                          src={item.snippet.thumbnails.high.url}
                                                          alt="Youtube"
                                                        />
                                                      </td>
                                                      <td align="left" valign="middle">
                                                        <div className="name">
                                                          {item.snippet.title}
                                                        </div>
                                                      </td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </div>
                                            );
                                          })}
                                        </div>
                                      ) : (
                                        search != '' && <span>No Result Found</span>
                                      )}

                                      <div className="write-wall-ftr d-flex align-items-center justify-content-between">
                                        <PostBottomMedia
                                          setSelectedFile={setSelectedFile}
                                          setUploadFiles={setUploadFiles}
                                          setIsSelected={setIsSelected}
                                          setIsSelectedFile={setIsSelectedFile}
                                          setShowEmoji={setShowEmoji}
                                          showEmoji={showEmoji}
                                          toggleMediaGroup={toggleMediaGroupFun}
                                        />
                                        <div className="wall-fltr-rght d-flex align-items">
                                          <button
                                            style={
                                              postLoading
                                                ? { cursor: 'not-allowed' }
                                                : { cursor: 'pointer' }
                                            }
                                            disabled={postLoading}
                                            className="accnt-btn ms-2"
                                            onClick={(e) => {
                                              makePost(e);
                                            }}>
                                            <i className="far fa-arrow-alt-circle-right me-1"></i>
                                            {postLoading ? 'Processing' : 'Post'}
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                {chapterData?.add_privacy == 'members' && checkIsFollower() && (
                                  <div className="write-on-wall mb-3">
                                    <h4>
                                      <i className="fas fa-edit me-2"></i> What's going on?
                                    </h4>
                                    <textarea
                                      rows={5}
                                      style={{ width: '100%', resize: 'none' }}
                                      placeholder={'Write something... #hashtags'}
                                      onChange={(e) => {
                                        setLatestPost(e.target.value);
                                      }}
                                      disabled={postLoading}
                                      onKeyDown={(e) => newParagraph(e)}
                                      value={latestPost}></textarea>
                                    {soundCloud && (
                                      <div className="upload-img-detail d-flex align-items-center justify-content-between">
                                        <i className="fa fa-music"></i>
                                        <input
                                          className="soundcloud-input"
                                          type="text"
                                          autoFocus
                                          // onKeyUp={() => SK_searchSoundcloud()}
                                          placeholder={post_publisher_soundcloud_placeholder}
                                        />
                                        {/* <div className="input-result-wrapper"></div> */}
                                      </div>
                                    )}
                                    {youtubeVideoCloud && (
                                      <div className="upload-img-detail d-flex align-items-center justify-content-start">
                                        <i className="fa fa-film me-2"></i>
                                        <input
                                          className="youtube-input"
                                          type="text"
                                          onChange={(e) => {
                                            setSearch(e.target.value);
                                          }}
                                          placeholder={post_publisher_youtube_placeholder}
                                        />
                                        <div className="input-result-wrapper"></div>
                                      </div>
                                    )}
                                    {postGoogleMapState && (
                                      <div className="upload-img-detail d-flex align-items-center justify-content-between">
                                        <i className="fa fa-map-marker "></i>
                                        <input
                                          className="youtube-input"
                                          type="text"
                                          // onKeyUp={() => SK_searchYoutube()}
                                          placeholder={post_publisher_youtube_placeholder}
                                        />

                                        {/* <input id="autocomplete" className="google-map-input" name="google_map_name" type="text" value="" placeholder="<?php echo $this->lang->line('post_publisher_googlemap_placeholder'); ?>" apply-google-places ></input> */}
                                      </div>
                                    )}
                                    {isSelected && (
                                      <div className="upload-img-detail d-flex align-items-center justify-content-between">
                                        <p>
                                          {selectedFile?.length > 1
                                            ? `${selectedFile.length} photo(s) selected`
                                            : `${selectedFile.length} photo selected`}
                                        </p>
                                        <Link
                                          to="#"
                                          onClick={() => {
                                            setIsSelected(false);
                                            setIsSelectedFile(false);
                                            setSelectedFile([]);
                                            setUploadFiles([]);
                                          }}>
                                          <i className="fas fa-times"></i>
                                        </Link>
                                      </div>
                                    )}

                                    {isSelectedFile && (
                                      <div className="upload-img-detail d-flex align-items-center justify-content-between">
                                        <p>
                                          {uploadFiles?.length > 1
                                            ? `${uploadFiles.length} file(s) selected`
                                            : `${uploadFiles.length} file selected`}
                                        </p>
                                        <Link
                                          to="#"
                                          onClick={() => {
                                            setIsSelected(false);
                                            setIsSelectedFile(false);
                                            setSelectedFile([]);
                                            setUploadFiles([]);
                                          }}>
                                          <i className="fas fa-times"></i>
                                        </Link>
                                      </div>
                                    )}
                                    {showEmoji && (
                                      <div className="upload-img-detail d-flex align-items-center justify-content-between">
                                        {emojiList?.length !== 0 && (
                                          <>
                                            {emojiList?.map((data) => {
                                              return (
                                                <Link
                                                  to="#"
                                                  onClick={(e) => {
                                                    e.preventDefault();
                                                    setLatestPost(latestPost + data?.id);
                                                  }}>
                                                  <img src={data?.value} />
                                                </Link>
                                              );
                                            })}
                                          </>
                                        )}
                                      </div>
                                    )}
                                    {result?.length > 0 && youtubeVideoCloud ? (
                                      <div className="input_result_wrapper">
                                        {result.map((item, i) => {
                                          return (
                                            <div className="api_data_wrapper">
                                              <table
                                                border={0}
                                                width={'100%'}
                                                cellSpacing={0}
                                                cellPadding={0}>
                                                <tbody>
                                                  <tr>
                                                    <td width={'40%'} align="left" valign="middle">
                                                      <img
                                                        className="thumbnail"
                                                        width={32}
                                                        height={32}
                                                        src={item.snippet.thumbnails.high.url}
                                                        alt="Youtube"
                                                      />
                                                    </td>
                                                    <td align="left" valign="middle">
                                                      <div className="name">
                                                        {item.snippet.title}
                                                      </div>
                                                    </td>
                                                  </tr>
                                                </tbody>
                                              </table>
                                            </div>
                                          );
                                        })}
                                      </div>
                                    ) : (
                                      search != '' && <span>No Result Found</span>
                                    )}
                                    <div className="write-wall-ftr d-flex align-items-center justify-content-between">
                                      <PostBottomMedia
                                        setSelectedFile={setSelectedFile}
                                        setUploadFiles={setUploadFiles}
                                        setIsSelected={setIsSelected}
                                        setIsSelectedFile={setIsSelectedFile}
                                        setShowEmoji={setShowEmoji}
                                        showEmoji={showEmoji}
                                        toggleMediaGroup={toggleMediaGroupFun}
                                      />
                                      <div className="wall-fltr-rght d-flex align-items">
                                        <button
                                          style={
                                            postLoading
                                              ? { cursor: 'not-allowed' }
                                              : { cursor: 'pointer' }
                                          }
                                          disabled={postLoading}
                                          className="accnt-btn ms-2"
                                          onClick={(e) => {
                                            makePost(e);
                                          }}>
                                          <i className="far fa-arrow-alt-circle-right me-1"></i>
                                          {postLoading ? 'Processing' : 'Post'}
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                )}
                                <ChapterPostLayout
                                  isPostLoading={postLoading}
                                  checkPost={checkPost}
                                  getPostListData={getPostListData}
                                  totalPages={totalPages}
                                  onIncrementPageNo={onIncrementPageNo}
                                  pageNum={pageNum}
                                  setCheckPost={setCheckPost}
                                  setLoadingState={setLoadingState}
                                  loadingState={loadingState}
                                  disableInputBox={disableInputBox}
                                  setDisableInputBox={setDisableInputBox}
                                  deleteFile={deleteFile}
                                  type={type}
                                  setPageNum={setPageNum}
                                />
                              </Col>
                              <Col xs={12} md={4}>
                                <div>
                                  <MembersCard
                                    chapterData={chapterData}
                                    handleActive={handleActive}
                                  />
                                </div>
                                <div>
                                  <WhatsNew />
                                </div>
                                <div>
                                  <Upcoming />
                                </div>
                                <MiniFooter />
                              </Col>
                            </Row>
                          </Tab.Pane>
                          <Tab.Pane eventKey="second">
                            <ChapterPostLayout
                              isPostLoading={postLoading}
                              checkPost={checkPost}
                              getPostListData={getPostListData}
                              totalPages={totalPages}
                              onIncrementPageNo={onIncrementPageNo}
                              pageNum={pageNum}
                              setCheckPost={setCheckPost}
                              setLoadingState={setLoadingState}
                              loadingState={loadingState}
                              disableInputBox={disableInputBox}
                              setDisableInputBox={setDisableInputBox}
                              deleteFile={deleteFile}
                              type={type}
                              setPageNum={setPageNum}
                            />
                          </Tab.Pane>
                          <Tab.Pane eventKey="third">
                            <ChapterPostLayout
                              isPostLoading={postLoading}
                              checkPost={checkPost}
                              getPostListData={getPostListData}
                              totalPages={totalPages}
                              onIncrementPageNo={onIncrementPageNo}
                              pageNum={pageNum}
                              setCheckPost={setCheckPost}
                              setLoadingState={setLoadingState}
                              loadingState={loadingState}
                              disableInputBox={disableInputBox}
                              setDisableInputBox={setDisableInputBox}
                              deleteFile={deleteFile}
                              type={type}
                              setPageNum={setPageNum}
                            />
                          </Tab.Pane>
                          <Tab.Pane eventKey="fourth">
                            <div className="likes-tab-sec">
                              <div className="likes-top-hd d-flex align-items-center justify-content-between me-2">
                                <div>
                                  {memberCount?.count_members !== 0 && (
                                    <Link
                                      className={`${userType === 'members' && 'active'} me-2`}
                                      to="#"
                                      onClick={() => {
                                        setUserType('members');
                                        getPeopleList('members');
                                      }}>
                                      Members <span>{memberCount?.count_members}</span>
                                    </Link>
                                  )}

                                  {memberCount?.admin == true && memberCount?.count_admins > 0 && (
                                    <Link
                                      className={`${userType === 'admins' && 'active'} me-2`}
                                      to="#"
                                      onClick={() => {
                                        setUserType('admins');
                                        getPeopleList('admins');
                                      }}>
                                      Admins <span>{memberCount?.count_admins}</span>
                                    </Link>
                                  )}
                                  {memberCount?.admin == true &&
                                    memberCount?.count_requests > 0 && (
                                      <Link
                                        className={`${userType === 'requests' && 'active'}`}
                                        to="#"
                                        onClick={() => {
                                          setUserType('requests');
                                          getPeopleList('requests');
                                        }}>
                                        Requests <span>{memberCount?.count_requests}</span>
                                      </Link>
                                    )}
                                </div>
                                <div>
                                  <Form.Group className="search-card" controlId="formBasicEmail">
                                    <Form.Control
                                      type="search"
                                      placeholder="Search"
                                      onChange={(e) => getPeopleList(userType, e.target.value)}
                                    />
                                    <Button className="search-btn" type="button">
                                      <i className="fas fa-search"></i>
                                    </Button>
                                  </Form.Group>
                                </div>
                              </div>
                              {memberLoad ? (
                                <>
                                  {' '}
                                  <div className="like-page-card bg-white p-3  loading-skeleton">
                                    {Array.from(Array(3), function (index) {
                                      return (
                                        <Row>
                                          <Col md={6}>
                                            <div className="like-card-detail member-card-detail d-flex">
                                              <div className="like-card-img">
                                                <Link to="#">
                                                  <img
                                                    src={require('../../../assets/images/default-male-avatar.png')}
                                                    alt="img"
                                                  />
                                                </Link>
                                              </div>
                                              <div className="like-card-dis">
                                                <h4></h4>
                                                <p></p>
                                                <Link to="" className="mentr-add-member"></Link>
                                              </div>
                                            </div>
                                          </Col>
                                          <Col md={6}>
                                            <div className="like-card-detail member-card-detail d-flex">
                                              <div className="like-card-img">
                                                <Link to="#">
                                                  <img
                                                    src={require('../../../assets/images/default-male-avatar.png')}
                                                    alt="img"
                                                  />
                                                </Link>
                                              </div>
                                              <div className="like-card-dis">
                                                <h4></h4>
                                                <p></p>
                                                <Link to="" className="mentr-add-member"></Link>
                                              </div>
                                            </div>
                                          </Col>
                                        </Row>
                                      );
                                    })}
                                  </div>
                                </>
                              ) : (
                                <>
                                  {' '}
                                  <div className="like-page-card bg-white p-3">
                                    <Row>
                                      {list &&
                                        list?.map((data, index) => {
                                          return (
                                            <>
                                              <Col md={6}>
                                                <div
                                                  className="like-card-detail member-card-detail d-flex"
                                                  key={index}>
                                                  <div className="like-card-img">
                                                    <Link
                                                      to={'/user/timeline/' + data?.encrypted_id}>
                                                      <img
                                                        src={
                                                          data?.avatar?.complete_url
                                                            ? data?.avatar?.complete_url
                                                            : require('../../../assets/images/default-male-avatar.png')
                                                        }
                                                        alt="img"
                                                      />
                                                    </Link>
                                                  </div>
                                                  <div className="like-card-dis">
                                                    <Link
                                                      to={'/user/timeline/' + data?.encrypted_id}>
                                                      {' '}
                                                      {data?.first_name}
                                                    </Link>

                                                    <p>
                                                      {' '}
                                                      <span
                                                        dangerouslySetInnerHTML={{
                                                          __html: truncate(data?.about, 75)
                                                        }}
                                                      />
                                                    </p>
                                                    {/*** Not implemented in client site  ***/}

                                                    {/* {memberCount?.admin == true &&
                                                      userType == 'members' && (
                                                        <>
                                                          <ul className="mentr-action-btn">
                                                            {data?.group_admin != true && (
                                                              <>
                                                                <li>
                                                                  <Link
                                                                    to="#"
                                                                    onClick={(e) =>
                                                                      handleAddAdmin(e, data?.id)
                                                                    }>
                                                                    Make Admin
                                                                  </Link>
                                                                </li>
                                                              </>
                                                            )}
                                                            <li>
                                                              <Link
                                                                onClick={async () => {
                                                                  try {
                                                                    let body = {
                                                                      chapter_id: chapterData.id,
                                                                      member_id: data.id
                                                                    };
                                                                    const response =
                                                                      await removeMember(body);
                                                                    if (response?.status == 200) {
                                                                      getPeopleList(
                                                                        'members',
                                                                        'stopLoading'
                                                                      );
                                                                    }
                                                                  } catch (error) { }
                                                                }}
                                                                to="#">
                                                                Remove Member
                                                              </Link>
                                                            </li>
                                                          </ul>
                                                        </>
                                                      )} */}
                                                    {/* {memberCount?.admin == true &&
                                                      userType == 'admins' && (
                                                        <>
                                                          {' '}
                                                          <ul className="mentr-action-btn">
                                                            <li>
                                                              <Link
                                                                to="#"
                                                                onClick={(e) => {
                                                                  handleRemoveAdmin(e, data?.id);
                                                                }}>
                                                                Remove Admin
                                                              </Link>
                                                            </li>
                                                          </ul>
                                                        </>
                                                      )} */}
                                                    {memberCount?.admin == true &&
                                                      userType == 'requests' && (
                                                        <>
                                                          <ul className="mentr-action-btn">
                                                            <li>
                                                              <Link
                                                                to="#"
                                                                className="me-2"
                                                                // onClick={() => {
                                                                //   handleRemove(data);
                                                                // }}
                                                              >
                                                                Accept
                                                              </Link>
                                                              <Link
                                                                to="#"
                                                                // onClick={() => {
                                                                //   handleRemove(data);
                                                                // }}
                                                              >
                                                                Reject
                                                              </Link>
                                                            </li>
                                                          </ul>
                                                        </>
                                                      )}
                                                  </div>
                                                </div>
                                              </Col>
                                            </>
                                          );
                                        })}
                                    </Row>
                                  </div>
                                </>
                              )}
                            </div>
                          </Tab.Pane>

                          <Tab.Pane eventKey="fifth">
                            <div className="group-settings-card bg-white p-4">
                              <h4 className="mb-5">Chapter settings</h4>
                              <div className="post-job-form">
                                <Form>
                                  <Form.Group
                                    as={Row}
                                    className="mb-4"
                                    controlId="formPlaintextEmail">
                                    <Form.Label column sm="3">
                                      Username:
                                    </Form.Label>
                                    <Col sm="9">
                                      <Form.Control
                                        type="text"
                                        defaultValue={chapterData?.username}
                                        readOnly
                                      />
                                    </Col>
                                  </Form.Group>

                                  <Form.Group
                                    as={Row}
                                    className="mb-4"
                                    controlId="formPlaintextPassword">
                                    <Form.Label column sm="3">
                                      Name:
                                    </Form.Label>
                                    <Col sm="9">
                                      <Form.Control
                                        type="text"
                                        defaultValue={chapterData?.name}
                                        placeholder="St Stephen School - Alumni Gro"
                                        Name="name"
                                        onChange={handleChange}
                                      />
                                    </Col>

                                    <div
                                      className={`${
                                        clicked && validate().name ? '' : 'd-none'
                                      } danger-frm `}>
                                      {clicked && validate().name}
                                    </div>
                                  </Form.Group>
                                  <Form.Group
                                    as={Row}
                                    className="mb-4"
                                    controlId="formPlaintextPassword">
                                    <Form.Label column sm="3">
                                      About:
                                    </Form.Label>
                                    <Col sm="9">
                                      <Form.Control
                                        type="text"
                                        as="textarea"
                                        name="about"
                                        defaultValue={chapterData?.about}
                                        onChange={handleChange}
                                        placeholder="Write about your chapter..."
                                      />
                                    </Col>
                                  </Form.Group>
                                  <Form.Group
                                    as={Row}
                                    className="mb-4"
                                    controlId="formPlaintextPassword">
                                    <Form.Label column sm="3">
                                      Privacy:
                                    </Form.Label>
                                    <Col sm="9">
                                      <div className="privacy-radio-card">
                                        {['radio'].map((type) => (
                                          <div key={`inline-${type}`} className="mb-3">
                                            <div className="privacy-inr-card d-flex align-items-top mb-2">
                                              <Form.Check
                                                type={type}
                                                name="group_privacy"
                                                checked={setting?.group_privacy === 'open'}
                                                value="open"
                                                onChange={handleChange}
                                              />

                                              <div className="privacy-radio-dis ms-3" name="open">
                                                <h4>
                                                  Open chapter{' '}
                                                  <i className="fas fa-globe-asia ms-1"></i>
                                                </h4>
                                                <p>Anyone can see and join the chapter .</p>
                                              </div>
                                            </div>
                                            <div className="privacy-inr-card d-flex align-items-top mb-2">
                                              <Form.Check
                                                type={type}
                                                name="group_privacy"
                                                value="closed"
                                                checked={setting?.group_privacy == 'closed'}
                                                onChange={handleChange}
                                              />
                                              <div className="privacy-radio-dis ms-2" name="closed">
                                                <h4>
                                                  Closed chapter{' '}
                                                  <i className="fas fa-lock ms-1"></i>
                                                </h4>
                                                <p>
                                                  Anyone can see and request to join the chapter .
                                                  Requests can be accepted or declined by admins.
                                                </p>
                                              </div>
                                            </div>
                                            <div className="privacy-inr-card d-flex align-items-top mb-2">
                                              <Form.Check
                                                type={type}
                                                name="group_privacy"
                                                value="secret"
                                                checked={setting?.group_privacy == 'secret'}
                                                onChange={handleChange}
                                              />
                                              <div className="privacy-radio-dis ms-2" name="secret">
                                                <h4>
                                                  Secret chapter{' '}
                                                  <i className="fas fa-shield-alt ms-1"></i>
                                                </h4>
                                                <p>Only members can access the chapter.</p>
                                              </div>
                                            </div>
                                          </div>
                                        ))}
                                      </div>
                                    </Col>
                                  </Form.Group>
                                  <Form.Group
                                    as={Row}
                                    className="mb-4"
                                    controlId="formPlaintextPassword">
                                    <Form.Label column sm="3">
                                      Who can add members of this chapter?:
                                    </Form.Label>
                                    <Col sm="9">
                                      <Form.Select
                                        aria-label="Default select example"
                                        Name="add_privacy"
                                        value={setting?.add_privacy}
                                        onChange={handleChange}>
                                        <option value="members">Members</option>
                                        <option value="admins">Admins</option>
                                      </Form.Select>
                                    </Col>
                                  </Form.Group>
                                  <Form.Group
                                    as={Row}
                                    className="mb-4"
                                    controlId="formPlaintextPassword">
                                    <Form.Label column sm="3">
                                      Who can post on this chapter:
                                    </Form.Label>
                                    <Col sm="9">
                                      <Form.Select
                                        aria-label="Default select example"
                                        Name="timeline_post_privacy"
                                        value={setting?.timeline_post_privacy}
                                        onChange={handleChange}>
                                        <option value="members">Members</option>
                                        <option value="admins">Admins</option>
                                      </Form.Select>
                                    </Col>
                                  </Form.Group>
                                  <Form.Group as={Row} className="mb-4">
                                    <Form.Label column sm="3"></Form.Label>
                                    <Col sm="9">
                                      <Button
                                        className="main-btn-grn"
                                        onClick={(e) => {
                                          handleSave(e);
                                        }}>
                                        Save changes
                                      </Button>
                                    </Col>
                                  </Form.Group>
                                </Form>
                              </div>
                            </div>
                          </Tab.Pane>
                        </Tab.Content>
                      </div>
                    )}
                  </Tab.Container>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}

/**
 *
 * @param {*} file
 * @returns
 */
function readFile(file) {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => resolve(reader.result), false);
    reader.readAsDataURL(file);
  });
}

export default ChapterDetail;
