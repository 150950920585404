import { useState, useEffect } from 'react';
import { Form, Col, Container, Dropdown, Row, Popover, OverlayTrigger } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import HeaderLogin from '../../commoncomponents/HeaderLogin';
import TopTabbings from './TopTabbings';
import SuggestMentor from '../../commoncomponents/newsfeeds/SuggestMentor';
import GroupDiscover from '../../commoncomponents/newsfeeds/GroupDiscover';
import WhatsNew from '../../commoncomponents/newsfeeds/WhatsNew';
import Upcoming from '../../commoncomponents/newsfeeds/Upcoming';
import NewsSidebar from '../../commoncomponents/newsfeeds/NewsSidebar';
import * as APISERVICES from '../../../services/MentorArticlesServices';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import showNotification from '../../../services/NotificationService';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { useLocation } from 'react-router-dom';
import HeaderInner from '../../commoncomponents/HeaderInner';

function MentorQuestionDetail() {
  const [detail, setDetail] = useState('');
  const [commentDetail, setCommentDetail] = useState([]);
  const [addComment, setAddComment] = useState('');
  const userDetail = useSelector((state) => state?.user?.details);
  const [toSendAttach, setToSendAttch] = useState([]);
  const [fileName, setFileName] = useState('');
  const location = useLocation();
  const isloggedIn = useSelector((state) => state?.user?.isloggedIn);
  const [showErrorMsg, setShowErrorMsg] = useState(false);

  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    getArticleDetails();
  }, []);

  const getArticleDetails = () => {
    APISERVICES.questionDetail(id).then((res) => {
      if (res?.status === 200) {
        setDetail(res?.data?.detail?.question);
        setCommentDetail(res?.data?.detail?.answers);
      }
    });

    // APISERVICES.getAnswersLists(id).then((res) => {
    //   if (res?.status === 200) {
    //         setCommentDetail(res?.data?.data);
    //   }
    // });
  };

  const commentHandler = (e) => {
    e.preventDefault();
    if (isloggedIn) {
      let formData = new FormData();
      if (addComment) {
        formData.append('q_id', id);
        formData.append('answer_body', addComment);
        for (let i in toSendAttach) {
          formData.append(`attach_files[]`, toSendAttach[i]);
        }
        APISERVICES.addArticleAnswer(formData).then((res) => {
          if (res?.status === 200) {
            getArticleDetails();
            setAddComment('');
            setToSendAttch([]);
            setFileName('');
            showNotification('success', res?.data?.message);
          }
        });
      } else {
        showNotification('danger', "can't post blank answer");
      }
    } else {
      setShowErrorMsg(true);
    }

    //api call
  };
  const deleteHandler = (id) => {
    if (confirm('Do you want to delete your answer ?') == true) {
      APISERVICES.deleteMyQuestion(id).then((res) => {
        if (res?.status === 200) {
          showNotification('danger', res?.data?.message);
          navigate('/user/qa');
        }
      });
    } else {
      return;
    }
  };

  const deleteAnsHandler = (data) => {
    if (confirm('Do you want to delete your answer ?') == true) {
      let body = {
        q_id: data?.q_id,
        answer_id: data?.id,
        answered_by: data?.answered_by,
        type: 'remove'
      };
      APISERVICES.deleteMyQuestionAns(body).then((res) => {
        if (res?.status === 200) {
          showNotification('danger', res?.data?.message);
          getArticleDetails();
        }
      });
    } else {
      return;
    }
  };

  const HelpFullHandler = (e, id) => {
    e.preventDefault();
    if (isloggedIn) {
      let body = {
        q_id: id,
        answer_id: '0',
        type: 'like'
      };
      let formData = new FormData();

      for (let i in body) {
        formData.append(`${i}`, body[i]);
      }
      APISERVICES.articleHelpful(formData).then((res) => {
        if (res?.status === 200) {
          showNotification('success', 'Marked as helpful, Success!');
          getArticleDetails();
        }
      });
    } else {
      navigate('/login');
    }
  };

  const AnswerLikeHandler = (questionId, ansId, e) => {
    e.preventDefault();
    if (isloggedIn) {
      const body = {
        q_id: questionId,
        answer_id: ansId,
        type: 'like'
      };
      APISERVICES.mentorshipAnswerLike(body).then((res) => {
        if (res?.status === 200) {
          showNotification('success', 'Marked as helpful, Success!');
          getArticleDetails();
        }
      });
    } else {
      navigate('/login');
    }
  };

  const onChangeHandler = (e) => {
    setFileName(e.target.files[0]);
    let fileData = e.target.files;
    for (let i = 0; i < fileData.length; i++) {
      toSendAttach.push(fileData[i]);
    }
    setToSendAttch(toSendAttach);
  };

  const renderPopover = (props) => {
    let popOverData = props?.popper?.state?.options?.data?.asked_by
      ? props?.popper?.state?.options?.data?.asked_by
      : props?.popper?.state?.options?.data?.answered_bye;

    return (
      <Popover className="group-loader-card" id={`popover-basic`} {...props}>
        {/* <Popover.Header as="h3">Popover right</Popover.Header> */}
        <Popover.Body>
          <div className="loader-tooltip">
            <div className="loader-cover">
              <img
                src={
                  popOverData?.cover_url ? popOverData?.cover_url : popOverData?.actual_cover_url
                }
                alt="img"
                className="loader-image"
              />
            </div>
            <div className="people-know-loader-dis d-flex align-items-top">
              <div className="people-knw-top d-flex align-items-center">
                <div className="people-icn me-3">
                  <img
                    src={
                      popOverData?.avatar?.complete_url
                        ? popOverData?.avatar?.complete_url
                        : popOverData?.avatar_url
                    }
                    alt="img"
                    className="loader-image"
                  />
                </div>
                <div className="people-hd">
                  <h3>{popOverData?.name}</h3>
                </div>
              </div>
            </div>
            <div className="people-knw-dis-popover">
              <p>
                <i className="fas fa-graduation-cap me-1"></i> Studied at{' '}
                <Link to="#">SD COLLEGE Chandigarh</Link> in <Link to="#">2005</Link>
              </p>
              {/**have to add key for this, key not added from backend */}
              <p>
                <i className="fas fa-briefcase me-1"></i>Worked as{' '}
                <Link to="#">Software developer</Link> {' at '}{' '}
                {/**have to add key for this, key not added from backend */}
                <Link to="#">TCS PVT LTD</Link>{' '}
                {/**have to add key for this, key not added from backend */}
              </p>
            </div>
          </div>
        </Popover.Body>
      </Popover>
    );
  };

  return (
    <>
      {!isloggedIn ? <HeaderInner /> : <HeaderLogin />}
      {isloggedIn ? (
        <div className="header-lnk-feeds">
          <div className="container">
            <TopTabbings state={'mentor'} />
          </div>
        </div>
      ) : (
        ''
      )}
      <section className="news-feed-sec bg-grey py-4">
        <Container>
          <Row>
            {isloggedIn && (
              <Col xs={12} lg={2} md={12}>
                <NewsSidebar state="2" />
              </Col>
            )}

            <Col xs={12} lg={isloggedIn ? 7 : 12} md={12}>
              {detail && detail
                ? detail?.map((data, index) => {
                    return (
                      <div className="articals_div " key={index}>
                        <div className="single_artical_card leave-ques-card p-3">
                          <div className="d-flex align-items-center mb-3">
                            <div className="drop-ellipse-actn">
                              {location?.state == 'answer-req' ||
                              userDetail?.id !== data?.asked_by?.user_id ? (
                                <></>
                              ) : (
                                <Dropdown className="ellipse-drop-card ms-3">
                                  <Dropdown.Toggle variant="success" id="dropdown-basic">
                                    <i className="fas fa-angle-down"></i>
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu>
                                    <Dropdown.Item
                                      as={Link}
                                      to={`/user/askquestion?id=${data?.id}`}
                                    >
                                      Edit{' '}
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      href="#"
                                      onClick={() => {
                                        deleteHandler(data?.id);
                                      }}
                                    >
                                      Delete{' '}
                                    </Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              )}
                            </div>
                            <Link
                              to={`/user/timeline/${data?.asked_by?.encrypted_id}`}
                              className="media_img"
                            >
                              {isloggedIn ? (
                                <OverlayTrigger
                                  trigger={['hover', 'focus']}
                                  placement="bottom"
                                  delay={{ show: 500, hide: 1000 }}
                                  data={data}
                                  popperConfig={{
                                    data
                                  }}
                                  shouldUpdatePosition={true}
                                  overlay={renderPopover}
                                >
                                  <img
                                    src={
                                      data?.asked_by?.avatar?.complete_url
                                        ? data?.asked_by?.avatar?.complete_url
                                        : data?.asked_by?.avatar_url
                                    }
                                    alt="img"
                                    className="me-3"
                                  />
                                </OverlayTrigger>
                              ) : (
                                <img
                                  src={
                                    data?.asked_by?.avatar?.complete_url
                                      ? data?.asked_by?.avatar?.complete_url
                                      : data?.asked_by?.avatar_url
                                  }
                                  alt="img"
                                  className="me-3"
                                />
                              )}
                            </Link>
                            <div className="media-body">
                              <h4>
                                <Link
                                  to={`/user/timeline/${data?.asked_by?.encrypted_id}`}
                                  className="user-link"
                                >
                                  {data?.asked_by?.name}
                                </Link>
                                {isloggedIn && (
                                  <>
                                    {' '}
                                    {location?.state == 'answer-req' ? (
                                      <></>
                                    ) : (
                                      <span>
                                        {data?.content_type === 'question'
                                          ? 'asked a question'
                                          : 'shared an article'}
                                      </span>
                                    )}
                                  </>
                                )}
                              </h4>
                              <p>{moment(data?.added_on).fromNow()}</p>
                            </div>
                          </div>
                          <div>
                            <h4 className="title">{data?.title}</h4>
                            <p className="mb-0">
                              <b>Description:</b>
                            </p>
                            <span dangerouslySetInnerHTML={{ __html: data?.description }} />
                            {location?.state == 'answer-req' ? (
                              <></>
                            ) : (
                              <>
                                {data?.tag_fields?.length > 0 && (
                                  <div className="topic-card mt-3 d-flex align-items-center">
                                    <h4>Topics:</h4>

                                    <div className="topics-lst">
                                      {data?.tag_fields
                                        ? data?.tag_fields?.map((data, index) => (
                                            <span className="ms-2" key={index}>
                                              {data?.name}
                                            </span>
                                          ))
                                        : ''}
                                    </div>
                                  </div>
                                )}
                              </>
                            )}
                          </div>
                          <div className="navigation-border">
                            <ul className="article-cmmnt-lnks d-flex ps-2">
                              <li className="active-helpful pe-3">
                                {data?.liked_by_current_user ? (
                                  data?.likes?.map((data) => (
                                    <>
                                      <Link className="active-helpful" title="You marked as helpful">
                                        <i className="fa fa-heart "></i>
                                        Helpful
                                      </Link>

                                      <span>({data?.likes}) </span>
                                    </>
                                  ))
                                ) : (
                                  <Link
                                    to="#"
                                    className="pe-0"
                                    onClick={(e) => {
                                      HelpFullHandler(e, data?.id);
                                    }}
                                  >
                                    {data?.likes?.map((data) => (
                                      <Link title="Mark as helpful">
                                        <i className="fa fa-heart "></i> Helpful
                                        <span>({data?.likes}) </span>
                                      </Link>
                                    ))}
                                  </Link>
                                )}
                              </li>
                              <li className="share-drop-btn">
                                <Dropdown>
                                  <Dropdown.Toggle className="pe-3" id="dropdown-basic">
                                    <i className="fa fa-share"></i> Share
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu className="p-2">
                                    <Dropdown.Item
                                      as={Link}
                                      target="_blank"
                                      to={`https://www.facebook.com/sharer/sharer.php?u=${
                                        process.env.REACT_APP_DOMAIN +
                                        'user/viewanswer' +
                                        '/' +
                                        data?.id
                                      }`}
                                    >
                                      Facebook
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      as={Link}
                                      target="_blank"
                                      to={`https://twitter.com/intent/tweet?url=${
                                        process.env.REACT_APP_DOMAIN +
                                        'user/viewanswer' +
                                        '/' +
                                        data?.id
                                      }`}
                                    >
                                      Twitter
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      as={Link}
                                      target="_blank"
                                      to={`https://plus.google.com/share?url=${
                                        process.env.REACT_APP_DOMAIN +
                                        'user/viewanswer' +
                                        '/' +
                                        data?.id
                                      }`}
                                    >
                                      Google
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      as={Link}
                                      target="_blank"
                                      to={`https://www.linkedin.com/shareArticle?url=${
                                        process.env.REACT_APP_DOMAIN +
                                        'user/viewanswer' +
                                        '/' +
                                        data?.id
                                      }&mini=true&title=${data?.title}&source=AlmaBay`}
                                    >
                                      Linkedin
                                    </Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </li>
                              {id && data?.docs?.length > 0 ? (
                                <li className="share-drop-btn attach-btn-drop">
                                  <Dropdown>
                                    <Dropdown.Toggle className="pe-3" id="dropdown-basic">
                                      <i className="fas fa-file"></i> Attachments(
                                      {data?.docs?.length})
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className="p-2">
                                      {data?.docs &&
                                        data?.docs?.map((data, index) => (
                                          <Dropdown.Item
                                            key={index}
                                            href={
                                              process.env.REACT_APP_API_DOMAIN +
                                              data?.document.substring(1) +
                                              data?.remarks
                                            }
                                            target="__blank"
                                          >
                                            {data?.remarks}
                                          </Dropdown.Item>
                                        ))}
                                    </Dropdown.Menu>
                                  </Dropdown>
                                </li>
                              ) : (
                                ''
                              )}
                            </ul>
                          </div>
                        </div>
                      </div>
                    );
                  })
                : ''}

              <div className="articals_div  ">
                {commentDetail &&
                  commentDetail?.map((data, index) => {
                    return (
                      <>
                        <div className="single_artical_card p-3 leave-ques-card" key={index}>
                          {userDetail?.id === data?.answered_bye?.id ? (
                            <>
                              <div className="drop-ellipse-actn">
                                <Dropdown className="ellipse-drop-card ms-3">
                                  <Dropdown.Toggle variant="success" id="dropdown-basic">
                                    <i className="fas fa-angle-down"></i>
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu>
                                    <Dropdown.Item
                                      href="#"
                                      onClick={() => {
                                        navigate(`/user/edit-answer/${data?.id}`);
                                      }}
                                    >
                                      Edit
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      href="#"
                                      onClick={() => {
                                        deleteAnsHandler(data);
                                      }}
                                    >
                                      Delete
                                    </Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </div>
                            </>
                          ) : (
                            <></>
                          )}

                          <div className="d-flex align-items-center mb-3">
                            <Link
                              to={`/user/timeline/${data?.answered_bye?.encrypted_id}`}
                              className="media_img me-3"
                            >
                              {isloggedIn ? (
                                <OverlayTrigger
                                  trigger={['hover', 'focus']}
                                  placement="bottom"
                                  delay={{ show: 500, hide: 1000 }}
                                  data={data}
                                  popperConfig={{
                                    data
                                  }}
                                  shouldUpdatePosition={true}
                                  overlay={renderPopover}
                                >
                                  <img
                                    src={
                                      data?.answered_bye?.avatar?.complete_url
                                        ? data?.answered_bye?.avatar?.complete_url
                                        : data?.answered_bye?.avatar_url
                                    }
                                    alt="img"
                                    className="me-3"
                                  />
                                </OverlayTrigger>
                              ) : (
                                <img
                                  src={
                                    data?.answered_bye?.avatar?.complete_url
                                      ? data?.answered_bye?.avatar?.complete_url
                                      : data?.answered_bye?.avatar_url
                                  }
                                  alt="img"
                                  className="me-3"
                                />
                              )}
                            </Link>
                            <div className="media-body">
                              <h6 className="mb-1">
                                Answered by:
                                <Link
                                  className="org-color"
                                  to={`/user/timeline/${data?.answered_bye?.encrypted_id}`}
                                >
                                  {' '}
                                  {data?.answered_bye?.name}
                                </Link>
                              </h6>
                              <p> {moment(data?.added_time).fromNow()}</p>
                            </div>
                            {/* attach */}
                          </div>
                          <div>
                            <span dangerouslySetInnerHTML={{ __html: data?.answer_body }} />
                          </div>
                          {data?.docs.length > 0 ? (
                            <Dropdown>
                              <Dropdown.Toggle className="pe-3" id="dropdown-basic">
                                <i className="fas fa-file"></i> Attachments({data?.docs.length})
                              </Dropdown.Toggle>
                              <Dropdown.Menu className="p-2">
                                {data?.docs &&
                                  data?.docs?.map((data, index) => (
                                    <Dropdown.Item
                                      key={index}
                                      href={
                                        process.env.REACT_APP_API_DOMAIN +
                                        data?.document.substring(1) +
                                        data?.remarks
                                      }
                                      target="__blank"
                                    >
                                      {data?.remarks}
                                    </Dropdown.Item>
                                  ))}
                              </Dropdown.Menu>
                            </Dropdown>
                          ) : (
                            ''
                          )}

                          <div className="navigation-border">
                            <ul className="article-cmmnt-lnks d-flex ps-2">
                              <li className="active-helpful pe-3">
                                {data?.liked_by_current_user ? (
                                  data?.likes?.map((item) => (
                                    <Link className='active-helpful' title="You marked as helpful">
                                      <i className="fa fa-heart "></i> Helpful
                                      <span>({item?.likes}) </span>
                                    </Link>
                                  ))
                                ) : (
                                  <Link
                                    to="#"
                                    className=""
                                    onClick={(e) => {
                                      AnswerLikeHandler(data?.q_id, data?.id, e);
                                    }}
                                  >
                                    {data?.likes?.map((item) => (
                                      <Link title="Mark as helpful">
                                        <i className="fa fa-heart "></i> Helpful
                                        <span>({item?.likes}) </span>
                                      </Link>
                                    ))}
                                  </Link>
                                )}
                              </li>
                              {userDetail?.id === data?.answered_bye?.id ? (
                                <li className="share-drop-btn">
                                  <Dropdown>
                                    <Dropdown.Toggle className="pe-3" id="dropdown-basic">
                                      <i className="fa fa-share"></i> Share
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className="p-2">
                                      <Dropdown.Item href="#">Facebook</Dropdown.Item>
                                      <Dropdown.Item href="#">Twitter</Dropdown.Item>
                                      <Dropdown.Item href="#">Google</Dropdown.Item>
                                      <Dropdown.Item href="#">Linkedin</Dropdown.Item>
                                    </Dropdown.Menu>
                                  </Dropdown>
                                </li>
                              ) : (
                                <></>
                              )}
                            </ul>
                          </div>
                        </div>
                      </>
                    );
                  })}
              </div>
              <div className="leave-cmmnt-card-outr">
                {/* <h5 className="mb-3">Comments</h5> */}
                <div className="leave-cmmnt-frm bg-white">
                  <h4 className="answer-quest mb-2">Answer Question</h4>
                  <Form.Group className="" controlId="exampleForm.ControlTextarea1">
                    <CKEditor
                      name="description"
                      data={addComment}
                      editor={ClassicEditor}
                      onReady={(editor) => {
                        // You can store the "editor" and use when it is needed.
                      }}
                      config={
                        ({
                          toolbar: [
                            'bold',
                            'italic',
                            'numberedList',
                            'bulletedList',
                            'outdent',
                            'indent'
                          ]
                        },
                        { placeholder: 'Type your Answer here' })
                      }
                      onChange={(event, editor) => {
                        const data = editor.getData();
                        setAddComment(data);
                      }}
                      onBlur={(event, editor) => {}}
                      onFocus={(event, editor) => {}}
                    />
                  </Form.Group>
                  <div className="px-3 pt-2 attach-card-inr d-flex justify-content-between">
                    <div>
                      <div>
                        <Form.Group controlId="formFile" className="mb-3 attach-file-card">
                          <Form.Control
                            type="file"
                            name="attach_files"
                            onChange={onChangeHandler}
                            accept=".png,.jpg,.jpeg"
                            multiple={true}
                          />
                          <span className="attach-file-inr">
                            <i className="fas fa-paperclip me-2"></i>
                            Attach Files
                            <span className="attach-file-name"></span>
                          </span>
                        </Form.Group>
                      </div>
                      {isloggedIn && <span className="attach-file-name">{fileName?.name}</span>}
                    </div>
                    <div className="cmmnt-btn mt-2 px-2 pb-2">
                      <button
                        className="accnt-btn"
                        onClick={(e) => {
                          commentHandler(e);
                        }}
                      >
                        SUBMIT
                      </button>
                    </div>
                  </div>
                </div>
                {showErrorMsg ? (
                  <p className="text-danger"> Please Sign in or Sign up to answer.</p>
                ) : (
                  ''
                )}
              </div>
            </Col>
            {isloggedIn && (
              <Col xs={12} lg={3} md={12}>
                <div>
                  <SuggestMentor />
                </div>
                <div>
                  <GroupDiscover />
                </div>
                <div>
                  <WhatsNew />
                </div>
                <div>
                  <Upcoming />
                </div>
              </Col>
            )}
          </Row>
        </Container>
      </section>
    </>
  );
}

export default MentorQuestionDetail;
