/**
@copyright  :   ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
@author     :   Shiv Charan Panjeta 
 
All Rights Reserved.
Proprietary and confidential :  All information contained here in is, and remains
the property of ToXSL Technologies Pvt. Ltd. and it's partners.
Unauthorized copying of this file, via any medium is strictly prohibited.
*/
import { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { getJobDetail } from '../../services/AuthServices';
import Loader from '../../components/commoncomponents/loader/loader-large';

export default function JobDetials() {
  const [jobDetail, setJobDetail] = useState('');
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  let id = location?.state;
  const navigate = useNavigate();

  useEffect(() => {
    if (id) {
      getDetail(id);
    }
  }, []);

  const getDetail = (id) => {
    setLoading(true);
    getJobDetail(id).then((res) => {
      if (res?.status === 200) {
        const data = res?.data?.data;
        setJobDetail(data);
        setLoading(false);
      }
    });
  };
  return (
    <div className="dash-main-content">
      <Container fluid>
        <div className="dash-page-content">
          <div className="dash-page-title">
            <Row>
              <Col xs={12} sm={12} md={12}>
                <div className="dash-title-bar d-flex align-items-center justify-content-between">
                  <div className="title-lft d-flex align-items-center">
                    <div className="dash-icn">
                      <i className="fa fa-file"></i>
                    </div>
                    <div className="d-flex align-items-center justify-content-between">
                      <div className="dash-title-dis">
                        <h5>
                          Job <span>Details</span>
                        </h5>
                      </div>
                    </div>
                  </div>
                  <div className="title-rt-btn">
                    <Link
                      className="text-dark font-weight-bold"
                      to=""
                      onClick={(e) => {
                        e.preventDefault();
                        navigate(-1);
                      }}>
                      Back To Listing
                    </Link>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          {loading === false && (
            <div className="dash-content-card">
              <div className="view_detials_content">
                <div className="mb-3">
                  <strong>Job Title:</strong> {jobDetail?.details?.job_title}
                </div>

                <div className="mb-3">
                  <strong>Job Posted By:</strong>{' '}
                  {jobDetail?.details?.added_by == 1 && jobDetail?.details?.userid == 1
                    ? 'Admin '
                    : jobDetail?.details?.first_name}
                </div>

                <div className="detials_description">
                  <strong>Short Description: </strong>
                  <p dangerouslySetInnerHTML={{ __html: jobDetail?.details?.job_info }}></p>
                </div>
                <div className="mb-3">
                  <strong>Job Categroy:</strong> {jobDetail?.details?.jobCategory?.name}
                </div>
                {jobDetail?.jobs_keywords?.length !== 0 && (
                  <div className="mb-3">
                    <strong>key Skills:</strong>{' '}
                    {jobDetail?.jobs_keywords
                      ?.map((data) => {
                        return data?.text;
                      })
                      .join()}
                    {/* {jobDetail?.details?.job_keyword_meta?.replace(/\|/g, ',')} */}
                  </div>
                )}

                <div className="mb-3">
                  <strong>Open positions:</strong> {jobDetail?.details?.open_positions}
                </div>
                <div className="mb-3">
                  <strong>Work experience:</strong> {jobDetail?.details?.min_exp}-
                  {jobDetail?.details?.max_exp}
                </div>
                <div className="mb-3">
                  <strong>Company hiring for:</strong> {jobDetail?.details?.job_title}
                </div>
                {jobDetail?.details?.job_location_meta && (
                  <div className="mb-3">
                    <strong>Location:</strong> {jobDetail?.details?.job_location_meta}
                  </div>
                )}

                <div className="mb-3">
                  <strong>HR Email:</strong> {jobDetail?.details?.hr_email}
                </div>
                <div className="mb-3">
                  <strong>Contact Number:</strong> {jobDetail?.details?.contact_number}
                </div>
                {/* <div className="mb-3">
                  <strong>
                    Allow job finder to get referred from other people working in the same company:
                  </strong>{' '}
                  {jobDetail?.details?.job_title}
                </div> */}
              </div>
            </div>
          )}
        </div>
      </Container>
      {loading && <Loader />}
    </div>
  );
}
