import { Container } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import BussinessGraphicImage from '../../assets/images/recruiter-business-strategy-graphic.png';
import FooterMain from '../commoncomponents/FooterMain';
import HeaderRecuirter from './HeaderRecuirter';
import useDocumentTitle from '../../globals/useDocumentTitle';
import { subDomain } from '../../globals/helper';

const RecruiterSolutions = () => {
  if (subDomain()) {
    useDocumentTitle(subDomain());
  } else {
    useDocumentTitle('Recruiter Solutions | AlmaBay');
  }
  
  return (
    <>
      <HeaderRecuirter />
      <section className="recuirter-title">
        <Container>
          <div className="recuirter-title-inr py-5">
            <h4>RECRUITER SOLUTIONS</h4>
          </div>
        </Container>
      </section>
      <section className="recuirter-card py-5">
        <Container>
          <div className="recuirter-card-inr">
            <Row>
              <Col md={7} xs={12} sm={7}>
                <div className="recuirter-lft-dis">
                  <div className="recuirter-dis-card">
                    <h4>Global Approach</h4>
                    <p>
                      We at AlmaBay Consultants truly believe in the interconectivity of people and
                      conpanies across the globe. Our infrastructure helps us provide solutions to
                      our clients that start in one region, but leads to a wider solution applicable
                      in different situations.
                    </p>
                  </div>
                  <div className="recuirter-dis-card">
                    <h4>Value</h4>
                    <p>
                      With our programme, your organisation can be assured of value-based
                      streamlining of core activities with most of the HR functions being managed by
                      us, thus, improving overall efficiency. Our clients have seen high percentage
                      reductions in cycle time, early turnover, average reducing cots and business
                      efort in the hiring process.
                    </p>
                  </div>
                  <div className="recuirter-dis-card">
                    <h4>End to end recruitment</h4>
                    <p>
                      Our decicated team of recruitment advocates and consultants ensure that you
                      get the best talent through precisely monitored logistics, and in the process,
                      providing you a platform where all positions in your organisation are filled
                      up without much delay.
                    </p>
                  </div>
                  <div className="recuirter-dis-card">
                    <h4>Candidate sourcing solutions</h4>
                    <p>
                      Being a core part of our recruitment solutions, candidate sourcing solutions
                      provide the top talent through the following means: Market research and
                      analytics, social media and diversity strategies, postings and ad placement,
                      database miniand and resume screning, pipeline creation and management,
                      outbound email and calling campaigns
                    </p>
                  </div>
                  <div className="recuirter-dis-card">
                    <h4>Candidate assessment and selection</h4>
                    <p>
                      AlmaBay Consultants' validated pre-employment testing are proven to: Improve
                      on-the-job and trainig performance, Decrease turnover, Identify candidates
                      with required “select for” skills, Pin-point “gems” in a large candidate pool,
                      Reduce costs associalted with poor-selection decisions, Improve on-the-job
                      safety behavior.
                    </p>
                  </div>
                  <div className="recuirter-dis-card">
                    <h4>Point recruitment solutions</h4>
                    <p>
                      We also offer more targeted solutions that address the major challenges of
                      candidate sourcing, candidate assessment and selection, recruitment
                      administration support, and recruitment technology.
                    </p>
                  </div>
                </div>
              </Col>
              <Col md={5} xs={12} sm={5}>
                <div className="recuirter-rt-img">
                  <img src={BussinessGraphicImage} alt="" />
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </section>
      <FooterMain className={'colour-bg'} />
    </>
  );
};

export default RecruiterSolutions;
