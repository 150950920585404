/**  @copyright : ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
@author     : Shiv Charan Panjeta < shiv@toxsl.com
All Rights Reserved.
Proprietary and confidential :  All information contained herein is, and remains
the property of ToXSL Technologies Pvt. Ltd. and its partners.
Unauthorized copying of this file, via any medium is strictly prohibited.
**/
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { accessRoutes, loginInfo, logout } from '../../redux/action';
import * as adminServices from '../../services/AuthServices';
import showNotification from '../../services/NotificationService';

export default function SideBar() {
  let details = JSON.parse(localStorage.getItem('userDetail'));
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [currentPageId, setCurrentPageId] = useState('');
  const [previosIdChild, setPreviosIdChild] = useState('01');
  const [sidebar, setSidebar] = useState([]);
  const [previosId, setPreviosId] = useState(
    location.pathname == '/admin/user/welcome' ? '01' : ''
  );

  useEffect(() => {
    checkUserToken();
    adminServices.sidebarAccess().then((res) => {
      if (res?.status == 200) {
        if (res?.data?.data?.show_modules?.super_admin_sideBar?.length) {
          setSidebar(res?.data?.data?.show_modules?.super_admin_sideBar[0]);

          let arrayPath1 = res?.data?.data?.show_modules?.super_admin_sideBar[0]
            ?.map((item) => item?.child?.map((data) => data?.info_url_link))
            ?.flat();
          let arrayPath2 = res?.data?.data?.show_modules?.super_admin_sideBar[0]?.map((item) =>
            item?.child?.map((data) =>
              data?.child?.map((secondChild) => secondChild?.info_url_link)?.flat()
            )
          );
          dispatch(accessRoutes([...arrayPath1, ...arrayPath2?.flat(2)]));
        }
      }
    });
  }, [details?.is_admin]);

  const logOutHandler = (e) => {
    e.preventDefault();
    adminServices.adminLogout().then((res) => {
      if (res?.status === 200) {
        showNotification('success', res?.data?.message);
        localStorage.clear();
        dispatch(logout());
        navigate('/admin');
      }
    });
  };

  const checkUserToken = () => {
    adminServices.checkLoginAdmin().then((res) => {
      if (res?.status === 200) {
        //save data on redux
        dispatch(loginInfo(res?.data?.data));
      } else {
        // If in case-Token not matched then clear > stored local storage
        localStorage.clear();
        dispatch(logout());
        navigate('/login');
      }
    });
  };

  /**
   * current page display class
   */
  const addRemoveClass = (e, id) => {
    setCurrentPageId(id);
    document.getElementById(id).classList.toggle('active');
    if (document.getElementById(id)) {
      document.getElementById(id).scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
    if (currentPageId && id != currentPageId) {
      document.getElementById(currentPageId).classList.remove('active');
    }
  };

  /**add class for parent node and remove previous node class if new element/parent node get clicked */
  const addRemoveClassParent = (e, id) => {
    setPreviosId(id);
    document.getElementById(id).classList.toggle('active');
    if (document.getElementById(id)) {
      document.getElementById(id).scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
    if (previosId && id != previosId) {
      document.getElementById(previosId).classList.remove('active');
    }
  };

  /**add class active for clicked element and remove the previous class */

  const addRemoveClassChild = (e, id) => {
    setPreviosIdChild(id);
    document.getElementById(id).classList.toggle('active');
    if (document.getElementById(id)) {
      document.getElementById(id).scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
    if (previosIdChild && id != previosIdChild) {
      document.getElementById(previosIdChild).classList.remove('active');
    }
  };

  /* eslint-disable */
  const roleBasedSidebar = (list) => {
    return list?.map((data, index) => (
      <li key={index}>
        <Link
          to="javascript:void(0);"
          id={data?.info_id}
          title={data?.info_name}
          onClick={(e) => {
            addRemoveClassParent(e, data?.info_id);
          }}
          className={location.pathname == '/' + data?.info_url_link ? 'active' : ''}>
          <i className={`fas fa-${data?.info_class_name} me-2`}></i>
          <span className="side-bar-title">{data?.info_name}</span>
          <span className="side-arrow">
            <i className="fas fa-caret-down"></i>
          </span>
        </Link>
        {data?.child?.length ? (
          <ul className="sub-menu">
            {data?.child?.map((sub, index) => {
              const infoURL = () => {
                if (
                  sub?.info_name === 'Communication Plans' &&
                  details?.access_type === 'institute'
                ) {
                  return sub?.info_url_link == 'javascript:void(0);'
                    ? '#'
                    : '/' + 'admin/communication';
                } else {
                  return sub?.info_url_link == 'javascript:void(0);'
                    ? '#'
                    : '/' + sub?.info_url_link;
                }
              };

              return (
                <li
                  key={index}
                  id={sub?.info_id}
                  className={location.pathname == '/' + sub?.info_url_link ? 'active' : ''}>
                  <Link
                    title={sub?.info_name}
                    id={sub?.info_id}
                    to={infoURL()}
                    onClick={(e) => {
                      addRemoveClass(e, sub?.info_id);
                    }}>
                    <i
                      onClick={() => {
                        document.getElementById(sub.info_id).classList.toggle('active');
                      }}
                      className={`fas fa-${sub?.info_class_name} me-2`}></i>
                    <span className="side-bar-title">{sub?.info_name}</span>
                    {sub?.child?.length ? (
                      <span className="side-arrow">
                        <i
                          onClick={() => {
                            document.getElementById(sub.info_id).classList.toggle('active');
                          }}
                          className="fas fa-caret-down"></i>
                      </span>
                    ) : (
                      <></>
                    )}
                  </Link>
                  {sub?.child?.length ? (
                    <ul className="sub-menu">
                      {sub?.child?.length &&
                        sub?.child?.map((data, index) => (
                          <li
                            className={
                              location.pathname == '/' + data?.info_url_link ? 'active' : ''
                            }
                            onClick={(e) => {
                              addRemoveClassChild(e, data?.info_id);
                            }}
                            id={data?.info_id}
                            key={index}>
                            <Link title={data?.info_name} to={'/' + data?.info_url_link}>
                              <i className={`fas fa-${data?.info_class_name} me-2`}></i>
                              <span className="side-bar-title">{data?.info_name}</span>
                            </Link>
                          </li>
                        ))}
                    </ul>
                  ) : (
                    ''
                  )}
                </li>
              );
            })}
          </ul>
        ) : (
          ''
        )}
      </li>
    ));
  };

  return (
    <div className="dash-side-bar">
      <div className="dash-wel d-flex align-items-center justify-content-between">
        <div className="dah-wel-dis d-flex align-items-center">
          <div className="login-icn">
            <img src={require('../../assets/images/default-admin-avatar.png')} alt="img" />
          </div>
          <div className="login-dis">
            <p>Welcome</p>
            <span className="text-warning">{details?.username}</span>
          </div>
        </div>
        <Link className="side-bar-close d-block d-lg-none">
          <i className="fas fa-times"></i>
        </Link>
        <Link
          className="d-none d-md-none d-lg-block"
          to="#"
          onClick={() => {
            document.getElementById('bars_colapse').classList.toggle('sidebar_closed');
          }}>
          <i className="fas fa-bars"></i>
        </Link>
      </div>
      <div className="side-bar-list">
        <ul className="side-bar-links">
          <li>
            <Link
              to="/admin/user/welcome"
              id="01"
              title="Dashboard"
              className={previosId === '01' ? 'active' : ''}
              onClick={(e) => {
                e.target.classList.add('active');
                if (previosId) {
                  document.getElementById(previosId).classList.remove('active');
                }
                setPreviosId('01');
              }}>
              <i className="fas fa-tachometer-alt me-2"></i>
              <span className="side-bar-title">Dashboard</span>
            </Link>
          </li>
          {/*  <li className="active"> */}

          {roleBasedSidebar(sidebar)}
          <li className="">
            <Link
              title="Settings"
              to="#"
              id="setting"
              onClick={(e) => {
                addRemoveClassParent(e, 'setting');
              }}>
              <i
                className="fas fa-cogs me-2"
                onClick={(e) => {
                  document.getElementById('setting').classList.toggle('Active');
                }}></i>
              <span className="side-bar-title">Settings</span>
              <span className="side-arrow">
                <i
                  className="fas fa-caret-down"
                  onClick={(e) => {
                    document.getElementById('setting').classList.toggle('Active');
                  }}></i>
              </span>
            </Link>
            <ul className="sub-menu">
              {details?.admin_type == 1 && (
                <>
                  <li className={''} id="Manage_Admin_Users">
                    <Link
                      title="Manage Admin Users"
                      to="/admin/users/showAllAdmins"
                      onClick={(e) => {
                        addRemoveClass(e, 'Manage_Admin_Users');
                      }}>
                      <i className="fas fa-list me-2"></i>
                      <span className="side-bar-title">Manage Admin Users</span>
                    </Link>
                  </li>
                  <li className={''} id="Manage_Template">
                    <Link
                      title="Manage Template"
                      to="/admin/ManageTemplate"
                      onClick={(e) => {
                        addRemoveClass(e, 'Manage_Template');
                      }}>
                      <i className="fas fa-list me-2"></i>
                      <span className="side-bar-title">Manage Template</span>
                    </Link>
                  </li>
                  <li className={''} id="roles">
                    <Link
                      title="Roles"
                      to="/admin/users/roles"
                      onClick={(e) => {
                        addRemoveClass(e, 'roles');
                      }}>
                      <i className="fas fa-key me-2"></i>
                      <span className="side-bar-title">Roles</span>
                    </Link>
                  </li>
                  <li className={''} id="email_queue">
                    <Link
                      title="Email Queue"
                      to="/admin/users/email"
                      onClick={(e) => {
                        addRemoveClass(e, 'email_queue');
                      }}>
                      <i className="fas fa-key me-2"></i>
                      <span className="side-bar-title">Email Queue</span>
                    </Link>
                  </li>
                </>
              )}
              <li className={''} id="Change_Password">
                <Link
                  title="Change Password"
                  to="/admin/users/changePassword"
                  onClick={(e) => {
                    addRemoveClass(e, 'email_queue');
                  }}>
                  <i className="fas fa-key me-2"></i>
                  <span className="side-bar-title">Change Password</span>
                </Link>
              </li>

              <li>
                <Link title="Logout" to="#" onClick={logOutHandler}>
                  <i className="fas fa-sign-out-alt me-2"></i>
                  <span className="side-bar-title">Logout</span>
                </Link>
              </li>
            </ul>
          </li>
        </ul>{' '}
      </div>
    </div>
  );
}
