/** @copyright : ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
 @author     : Shiv Charan Panjeta 

 All Rights Reserved.
 Proprietary and confidential :  All information contained herein is, and remains
 the property of ToXSL Technologies Pvt. Ltd. and its partners.
 Unauthorized copying of this file, via any medium is strictly prohibited.
*/
import { useEffect, useState } from 'react';
import { Col, Modal, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { getClubGallery, getGallery } from '../../../services/InstituteServices';
import { Swiper, SwiperSlide } from 'swiper/react';
import { FreeMode, Navigation, Thumbs } from 'swiper';

function ImagesContent() {
  const [list, setList] = useState([]);
  const [show, setShow] = useState(false);
  const [preview, setPreview] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    getClubGallery('photo').then((res) => {
      if (res?.status === 200) {
        setList(res?.data?.data);
      }
    });
  };

  /**
   *
   * @param {e on clicking} e
   * @param {data of selected image} data
   */
  const handleShow = (e, data) => {
    e.preventDefault();
    setPreview(data);
    setShow(true);
  };

  /**
   * close modal function
   */
  const handleClose = () => setShow(false);

  return (
    <>
      <Row>
        {list && list?.length ? (
          list?.map((data, index) => (
            <Col key={index} sm={6} md={4}>
              <Link
                className="h-100 single-img-content text-center"
                to="#"
                onClick={(e) => {
                  handleShow(e, data);
                }}>
                <img src={data?.url} alt="img" width="100%" className="mb-3" />
                <p>{data?.title}</p>
              </Link>
            </Col>
          ))
        ) : (
          <Col sm={12} md={4} lg={3}>
            <div className="text-center nodata">
              <img src={require('../../../assets/images/nodata.png')} alt="" />
              <p>Data Not Found</p>
            </div>
          </Col>
        )}
      </Row>
      {/* photo's view modal */}
      <Modal show={show} onHide={handleClose} className="gallery_modal">
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="image-view mb-4">
            <Swiper
              style={{
                '--swiper-navigation-color': '#fff',
                '--swiper-pagination-color': '#fff'
              }}
              spaceBetween={10}
              navigation={true}
              modules={[FreeMode, Navigation, Thumbs]}
              className="mySwiper2">
              <SwiperSlide>
                <a data-fancybox="gallery" href="#" className="text-center">
                  <img src={preview?.url} alt="img" />
                  <p className="text-black">{preview?.title}</p>
                </a>
              </SwiperSlide>
            </Swiper>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ImagesContent;
