import { Link } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';
import { useState, useEffect } from 'react';
import * as APISERVICES from '../../../services/ShopServices';
import Loader from '../loader/loader-large';

function BrowseInstitue() {
  const [logoList, setLogoList] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    getLogoList();
  }, []);

  const getLogoList = () => {
    setLoading(true);
    APISERVICES.instituteLogo().then((res) => {
      setLoading(false);
      if (res?.status === 200) {
        setLoading(false);
        setLogoList(res?.data?.list);
      }
    });
  };
  return (
    <>
      <div className="institute-links-blk">
        <Swiper
          spaceBetween={30}
          slidesPerView={5}
          navigation={true}
          modules={[Navigation]}
          // onSlideChange={() => }
          // onSwiper={(swiper) => }
          breakpoints={{
            320: {
              slidesPerView: 1
            },
            576: {
              slidesPerView: 1
            },
            768: {
              slidesPerView: 3
            },
            992: {
              slidesPerView: 4
            },
            1200: {
              slidesPerView: 5
            }
          }}
        >
          {logoList &&
            logoList?.map((data, index) => {
              return (
                <SwiperSlide key={index}>
                  <Link to={`/shop/products/${data?.shortcode}`} state={data?.name}>
                    <img src={process.env.REACT_APP_API_DOMAIN + data?.logo} alt="institute" />
                  </Link>
                </SwiperSlide>
              );
            })}
        </Swiper>
      </div>
      {loading ? <Loader /> : ''}
    </>
  );
}

export default BrowseInstitue;
