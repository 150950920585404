import {
  getAdminAccessType,
  getAdminList,
  getAdminRoleDrop,
  getAllInstituteDrop,
  getDepartmentDrop,
  getDepartmentInstituteDrop,
  getInstituteListing,
  getJobCategoryDrop,
  getLocationDropDown,
  getUniversityDrop,
  getGroupInstituteList,
  getPageCategoryDrop,
  getCategoryDrop,
  getCompaniesCityDropDown,
  getJobIndustryDropDown,
  getCompanySpecialityDropDown
} from '../services/AuthServices';
import { citiesDropDown, getJobCompanyDropdown } from '../services/UserServices';

export const getAllAdminAccessType = (setState) => {
  getAdminAccessType().then((res) => {
    if (res?.status === 200) {
      setState(res?.data?.data);
    }
  });
};
export const getAllAdminRole = (setState) => {
  getAdminRoleDrop().then((res) => {
    if (res?.status === 200) {
      setState(res?.data?.data?.roles);
    }
  });
};

export const loadUniOptions = (inputValue) => {
  return getUniversityDrop(inputValue).then((res) => {
    if (res?.status === 200) {
      let tempArr = [];
      if (res?.data?.data) {
        const data = Object.entries(res?.data?.data).map(([key, value]) => ({ key, value }));
        data?.map((data) => {
          tempArr.push({
            id: data?.key,
            label: data?.value,
            value: data?.value
          });
        });
      }
      return tempArr;
    }
  });
};

export const loadAllInstituteOptions = (inputValue) => {
  return getAllInstituteDrop(inputValue).then((res) => {
    if (res?.status === 200) {
      let tempArr = [];
      if (res?.data?.data) {
        const data = Object.entries(res?.data?.data).map(([key, value]) => ({ key, value }));
        data?.map((data, index) => {
          tempArr.push({
            id: data?.key,
            label: data?.value,
            value: data?.value
          });
        });
      }
      return tempArr;
    }
  });
};

export const loadAllCategoryList = (inputValue) => {
  return getPageCategoryDrop(inputValue).then((res) => {
    if (res?.status === 200) {
      let tempArr = [];
      if (res?.data?.message) {
        const data = Object.entries(res?.data?.data).map(([key, value]) => ({ key, value }));
        data?.map((data, index) => {
          tempArr.push({
            id: data?.key,
            label: data?.value,
            value: data?.value
          });
        });
      }
      return tempArr;
    }
  });
};

export const loadDepInstituteOptions = (inputValue) => {
  return getDepartmentInstituteDrop(inputValue).then((res) => {
    if (res?.status === 200) {
      let tempArr = [];
      if (res?.data?.data) {
        const data = res?.data?.data;
        data?.map((data, index) => {
          tempArr.push({
            id: data?.institute_id,
            label: data?.name,
            value: data?.name
          });
        });
      }
      return tempArr;
    }
  });
};

export const loadDeprtmentOptions = (inputValue, additional) => {
  return getDepartmentDrop(inputValue, additional).then((res) => {
    if (res?.status === 200) {
      let tempArr = [];
      if (res?.data?.data) {
        const data = res?.data?.data;
        data?.map((data, index) => {
          tempArr.push({
            id: data?.institute_id,
            label: data?.name,
            value: data?.name
          });
        });
      }
      return tempArr;
    }
  });
};

export const loadJobCategoryOption = (inputValue) => {
  return getJobCategoryDrop(inputValue).then((res) => {
    if (res?.status === 200) {
      let tempArr = [];
      if (res?.data?.data) {
        let data = res?.data?.data?.allCategories;
        data?.map((data) => {
          tempArr.push({
            id: data?.id,
            label: data?.name,
            value: data?.id
          });
        });
      }
      return tempArr;
    }
  });
};

export const loadJobHiringOption = async (inputValue) => {
  return getJobCategoryDrop(inputValue).then((res) => {
    if (res?.status === 200) {
      let tempArr = [];
      if (res?.data?.data) {
        let data = res?.data?.data?.allCategories;
        data?.map((data) => {
          tempArr.push({
            id: data?.id,
            label: data?.name,
            value: data?.id
          });
        });
      }
      return tempArr;
    }
  });
};

export const loadJObLocationOption = async (inputValue) => {
  if (inputValue?.length > 1) {
    return citiesDropDown(inputValue).then((res) => {
      if (res?.status === 200) {
        let tempArr = [];
        if (res?.data?.data) {
          let data = res?.data?.data;
          data?.map((data) => {
            tempArr.push({
              id: data?.city_id,
              label: data?.city,
              value: data?.city_id
            });
          });
        }
        return tempArr;
      }
    });
  }
};

export const loadCompanyListOption = async (inputValue) => {
  return getJobCompanyDropdown(inputValue).then((res) => {
    if (res?.status === 200) {
      let tempArr = [];
      if (res?.data?.data) {
        let data = res?.data?.data;
        data?.map((data) => {
          tempArr.push({
            id: data?.id,
            label: data?.name,
            value: data?.name
          });
        });
      }
      return tempArr;
    }
  });
};

export const loadCityOpt = async (inputValue) => {
  return getLocationDropDown(inputValue).then((res) => {
    if (res?.status === 200) {
      let tempArr = [];
      if (res?.data?.data) {
        const data = res?.data?.data;
        data?.map((data) => {
          tempArr.push({
            id: data?.id,
            label: data?.location,
            value: data?.location
          });
        });
      }
      return tempArr;
    }
  });
};

export const loadAllInstituteOptionsForGroup = (inputValue) => {
  return getInstituteListing(inputValue).then((res) => {
    if (res?.status === 200) {
      let tempArr = [];

      if (res?.data?.message) {
        const data = res?.data?.message;

        data?.map((data, index) => {
          tempArr.push({
            id: data?.institute_id,
            label: data?.name,
            value: data?.name
          });
        });
      }
      return tempArr;
    }
  });
};

export const loadAllInstituteOptionsForAdmin = (inputValue) => {
  return getAdminList(inputValue).then((res) => {
    if (res?.status === 200) {
      let tempArr = [];
      if (res?.data?.data) {
        const data = res?.data?.data;
        data?.map((data, index) => {
          tempArr.push({
            id: data?.id,
            label: data?.name,
            value: data?.name
          });
        });
      }
      return tempArr;
    }
  });
};

export const getUserInstituteList = (inputValue) => {
  return getGroupInstituteList(inputValue).then((res) => {
    if (res?.status === 200) {
      let tempArr = [];
      if (res?.data?.message) {
        const data = res?.data?.message;
        data?.map((data, index) => {
          tempArr.push({
            id: data?.institute_id,
            label: data?.name,
            value: data?.name
          });
        });
      }
      return tempArr;
    }
  });
};

export const getCategoryOptions = (inputValue) => {
  return getCategoryDrop(inputValue).then((res) => {
    if (res?.status === 200) {
      let tempArr = [];
      if (res?.data?.data) {
        const data = res?.data?.data;
        data?.map((data, index) => {
          tempArr.push({
            id: data?.id,
            label: data?.cat_name,
            value: data?.cat_name
          });
        });
      }
      return tempArr;
    }
  });
};

export const loadCompanyCities = (inputValue) => {
  return getCompaniesCityDropDown(inputValue).then((res) => {
    if (res?.status === 200) {
      let tempArr = [];
      if (res?.data?.data) {
        let data = res?.data?.data;
        data?.map((data) => {
          tempArr.push({
            id: data?.city_id,
            label: data?.city,
            value: data?.city_id
          });
        });
      }
      return tempArr;
    }
  });
};

export const loadMainCompanyIndustries = (inputValue) => {
  return getJobIndustryDropDown(inputValue).then((res) => {
    if (res?.status === 200) {
      let tempArr = [];

      if (res.data) {
        let data = res?.data;
        data?.map((data) => {
          tempArr.push({
            id: data?.id,
            label: data?.name,
            value: data?.id
          });
        });
      }
      return tempArr;
    }
  });
};
export const loadCompanySpecialitiesOptions = (inputValue) => {
  return getCompanySpecialityDropDown(inputValue).then((res) => {
    if (res?.status === 200) {
      let tempArr = [];
      if (res.data) {
        let data = res?.data;
        data?.map((data) => {
          tempArr.push({
            id: data?.id,
            label: data?.name,
            value: data?.id
          });
        });
      }
      return tempArr;
    }
  });
};

// url chekck function

/* eslint-disable no-useless-escape */

export const isValidURL = (url) => {
  if (url == '' || url == null) {
    return false;
  } else {
    var expression =
      /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi;
    var regex = new RegExp(expression);
    var t = 'www.google.com';

    if (url.match(regex)) {
      return true;
    } else {
      return false;
    }
  }
};

// Regex to check valid URL
