/**  @copyright : ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
@author     : Shiv Charan Panjeta < shiv@toxsl.com
All Rights Reserved.
Proprietary and confidential :  All information contained herein is, and remains
the property of ToXSL Technologies Pvt. Ltd. and its partners.
Unauthorized copying of this file, via any medium is strictly prohibited.
**/
import { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import ProgressBar from 'react-bootstrap/ProgressBar';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import Table from 'react-bootstrap/Table';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import AsyncCreatableSelect from 'react-select/async-creatable';
import CreatableSelect from 'react-select/creatable';
import Loader from '../../components/commoncomponents/loader/loader-large';
import { imageExtension } from '../../globals/helper';
import useDocumentTitle from '../../globals/useDocumentTitle';
import * as adminServices from '../../services/AuthServices';
import showNotification from '../../services/NotificationService';
import { loadJObLocationOption } from '../AdminHelper';

export default function UserView() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [detail, setDetail] = useState('');
  const [loading, setLoading] = useState(false);
  useDocumentTitle(`${detail?.user?.name}| Almabay`);

  const [imagePreview, setImagePreview] = useState(false);
  // for career-goals
  const [industryList, setIndustryList] = useState('');
  const [industryId, setIndustryId] = useState('');
  const [functionalAreaList, setFunctionalAreaList] = useState('');
  const [functionalId, setFunctionalId] = useState('');

  const [seekMentorId, setSeekMentorId] = useState('');
  const [provideMentorShipId, setProvideMentorShipId] = useState('');

  const [clicked, setClicked] = useState(false);

  const [careerDetail, setCareerDetail] = useState({
    id: id,
    seek_mentorship_in: '',
    provide_mentorship_in: '',
    industry_id: '',
    functional_area_id: ''
  });

  const [techSkills, setTechSkills] = useState('');
  const [businessSkills, setBusinessSkills] = useState('');
  const [interSkills, setInterSkills] = useState('');
  const [jobLocation, setJobLocation] = useState('');
  const paths = useSelector((state) => state?.adminPaths?.paths);

  useEffect(() => {
    if (id) {
      getDetail(id);
    }
  }, []);

  const getDetail = () => {
    setLoading(true);
    adminServices.userDetail({ id: id }).then((res) => {
      setLoading(false);
      if (res?.status === 200) {
        setDetail(res?.data?.detail);
      }
    });
  };

  const getUserWorkDetail = () => {
    // setLoading(true);
    adminServices.userWorkDetail(id).then((res) => {
      // setLoading(false);
      if (res?.status === 200) {
        // setDetail(res?.data?.detail);
        let list = res?.data[0];
        setCareerDetail({
          id: id,
          seek_mentorship_in: list?.seek_mentorship_in
            ?.map((data) => {
              return data?.text;
            })
            .join(),
          provide_mentorship_in: list?.provide_mentorship_in
            ?.map((data) => {
              return data?.text;
            })
            .join(),
          industry_id: list?.industry_data?.id,
          functional_area_id: list?.functional_area_id
            ?.map((data) => {
              return data?.id.split('~')[0];
            })
            .join(),
          location_id: list?.location_id
            ?.map((data) => {
              return data?.id;
            })
            .join()
        });
        if (list?.location_id?.length) {
          let arr = [];
          list?.location_id?.map((data) => {
            arr.push({
              id: data?.id,
              label: data?.text,
              value: data?.id
            });
          });
          setJobLocation(arr);
        }
        if (list?.seek_mentorship_in?.length) {
          let arr = [];
          list?.seek_mentorship_in?.map((data) =>
            arr.push({
              label: data?.text,
              value: data?.id
            })
          );
          setSeekMentorId(arr);
        }
        if (list?.provide_mentorship_in?.length) {
          let arr = [];
          list?.provide_mentorship_in?.map((data) =>
            arr.push({
              label: data?.text,
              value: data?.id
            })
          );
          setProvideMentorShipId(arr);
        }
        if (list?.industry_data) {
          setIndustryId({ label: list?.industry_data?.text, value: list?.industry_data?.id });
        }
        if (list?.functional_area_id.length) {
          let arr = [];
          list?.functional_area_id?.map((data) =>
            arr.push({
              label: data?.text,
              value: data?.id.split('~')[0]
            })
          );
          setFunctionalId(arr);
        }
      }
    });
  };

  const deleteHandler = (qualification_id) => {
    let body = {
      qualification_id: qualification_id
    };
    if (confirm('Do you want to remove qualification?') == true) {
      adminServices.deleteUserEducationDetails(body).then((res) => {
        if (res?.status === 200) {
          showNotification('danger', res?.data?.message);
          getDetail(id);
        }
      });
    } else {
      return;
    }
  };

  const deleteWorkDetailHandler = (user_profession_id) => {
    let body = {
      user_profession_id: user_profession_id
    };
    if (confirm('Do you want to remove work?') == true) {
      adminServices.deleteUserWorkDetail(body).then((res) => {
        if (res?.status === 200) {
          showNotification('danger', res?.data?.message);
          getDetail(id);
        }
      });
    } else {
      return;
    }
  };

  /***
   * functionality for career-goals
   */
  const industryDropDown = () => {
    adminServices.getJobIndustryDropDown().then((res) => {
      if (res?.status === 200) {
        let list = res?.data;
        let tempArr = [];
        list?.map((data) => {
          tempArr.push({
            label: data?.name,
            value: data?.id
          });
        });
        setIndustryList(tempArr);
      }
    });
  };

  const loadMentorShipOption = (inputValue) => {
    return adminServices.getMentorShipDropDown(inputValue).then((res) => {
      if (res?.status === 200) {
        let tempArr = [];
        if (res?.data?.data?.length) {
          const data = res?.data?.data;
          data?.map((data) => {
            tempArr.push({
              id: data?.id,
              label: data?.name,
              value: data?.name
            });
          });
        }
        return tempArr;
      }
    });
  };

  const functionalArea = () => {
    adminServices.getFunctionalAreaDropDown().then((res) => {
      if (res?.status === 200) {
        let list = res?.data;
        let tempArr = [];
        list?.map((data) => {
          tempArr.push({
            label: data?.name,
            value: data?.id
          });
        });
        setFunctionalAreaList(tempArr);
      }
    });
  };

  //validate
  const validate = (value) => {
    let errors = {};
    if (!value?.industry_id) {
      errors.industry_id = 'This field is required.';
    }
    if (!value?.functional_area_id) {
      errors.functional_area_id = 'This field is required.';
    }
    if (!value?.location_id) {
      errors.location_id = 'This field is required.';
    }
    return errors;
  };

  const onChangeHandler = (e, option) => {
    if (option.name == 'industry_id') {
      setCareerDetail({ ...careerDetail, [option.name]: e.value });
    } else {
      let arr = [];
      e.map((data) => {
        arr.push(data.value);
      });
      setCareerDetail({ ...careerDetail, [option.name]: arr.join() });
    }
  };

  const submitHandler = (e) => {
    e.preventDefault();
    setClicked(true);
    if (!Object.keys(validate(careerDetail)).length) {
      setClicked(false);
      adminServices.addCareerGoals(careerDetail).then((res) => {
        if (res?.status === 200) {
          showNotification('success', res?.data?.message);
          getDetail(id);
        }
      });
    }
  };

  /**
   * image ->handler/api call
   */

  const imageChangeHandler = (e) => {
    let file = e?.target?.files[0];
    if (imageExtension.includes(file.type)) {
      setImagePreview(URL.createObjectURL(e?.target?.files[0]));
      let formData = new FormData();
      formData.append('image', file);
      adminServices.updateUserAvatar(id, formData).then((res) => {
        if (res?.status === 200) {
          showNotification('success', res?.data?.message);
          getDetail(id);
        }
      });
    } else {
      showNotification('danger', 'invalid format !');
    }
  };

  // users skills data
  const getUserSkills = (id) => {
    adminServices.userSkills(id, 'TECHNICAL').then((res) => {
      if (res?.status === 200) {
        setTechSkills(res?.data?.data);
      }
    });

    adminServices.userSkills(id, 'BUSINESS').then((res) => {
      if (res?.status === 200) {
        setBusinessSkills(res?.data?.data);
      }
    });

    adminServices.userSkills(id, 'INTERPERSONAL').then((res) => {
      if (res?.status === 200) {
        setInterSkills(res?.data?.data);
      }
    });
  };

  const showClassName = (value) => {
    switch (value) {
      case '100':
        return '';
      case '80':
        return 'ng-hide-vgood';
      case '60':
        return 'ng-hide-good';
      case '40':
        return 'ng-hide-fair';
      case '20':
        return 'ng-hide';
      default:
        return '';
    }
  };

  return (
    <>
      <div className="dash-main-content">
        <Container fluid>
          <div className="dash-page-content">
            <div className="dash-page-title">
              <Row className="align-items-end">
                <Col xs={12} sm={12} md={12}>
                  <div className="dash-title-bar d-flex align-items-center justify-content-between">
                    <div className="title-lft d-flex align-items-center">
                      <div className="dash-icn">
                        <i className="fas fa-user"></i>
                      </div>
                      <div className="dash-title-dis">
                        <h5>
                          {detail?.user?.name} <span>Details</span>
                        </h5>
                      </div>
                    </div>
                    <div className="title-rt-btn d-flex align-items-center">
                      <Link
                        className="add-btn-grn"
                        to="#"
                        onClick={(e) => {
                          e.preventDefault();
                          navigate(-1);
                        }}
                      >
                        Back To Listing
                      </Link>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="dash-content-card">
              <div className="view-usr-details">
                <div className="view-usr-img">
                  <div className="img-usr-outr">
                    <span className="view-usr-icn">
                      {imagePreview ? (
                        <img src={imagePreview} alt="img" />
                      ) : (
                        <img
                          src={
                            detail?.user?.avatar?.complete_url
                              ? detail?.user?.avatar?.complete_url
                              : detail?.user?.avatar_url
                          }
                          alt="img"
                        />
                      )}
                      {/* <img src={require('../../assets/images/default-male-avatar.png')} alt="img" /> */}
                    </span>
                    <span className="camera-icn">
                      <Form.Control type="file" onChange={imageChangeHandler} accept="image/*" />
                      <i className="fas fa-camera"></i>
                    </span>
                  </div>
                </div>
                <div className="View-user-detail">
                  <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                    <Row>
                      <Col sm={12}>
                        <Nav variant="pills" className="view-usr-tabs mb-4">
                          <Nav.Item>
                            <Nav.Link eventKey="first">
                              <i className="fas fa-user-alt me-1"></i> Personal
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link eventKey="second">
                              <i className="fas fa-graduation-cap me-1"></i>Academic
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link eventKey="third">
                              <i className="fas fa-briefcase me-1"></i>Profession
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link
                              onClick={() => {
                                if (id) {
                                  getUserSkills(id);
                                }
                              }}
                              eventKey="fourth"
                            >
                              <i className="fas fa-cogs me-1"></i>Skills
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link
                              onClick={() => {
                                if (id) {
                                  industryDropDown();
                                  functionalArea();
                                  getUserWorkDetail();
                                }
                              }}
                              eventKey="five"
                            >
                              <i className="fas fa-cogs me-1"></i>Career Goals
                            </Nav.Link>
                          </Nav.Item>
                        </Nav>
                      </Col>
                      <Col sm={12}>
                        <Tab.Content className="view-usr-cntnt">
                          <Tab.Pane eventKey="first">
                            <div className="user-view-personal">
                              <div className="view-usr-hd d-flex align-items-center justify-content-between mb-3">
                                <h4>
                                  <i className="fas fa-info-circle me-1"></i>Personal Details
                                </h4>
                                {paths?.includes('admin/users/create') ? (
                                  <Link
                                    className="edit-btn-blue"
                                    to="#"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      navigate('/admin/users/create', {
                                        state: detail?.user?.user_id
                                      });
                                    }}
                                  >
                                    <i className="fas fa-edit me-2"></i>
                                    Edit
                                  </Link>
                                ) : (
                                  <Link
                                    className="edit-btn-blue"
                                    to="#"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      navigate('/admin');
                                    }}
                                  >
                                    <i className="fas fa-edit me-2"></i>
                                    Edit
                                  </Link>
                                )}
                              </div>
                              <div className="user-vertical-detail">
                                <Table className="custom-table-vertical" bordered>
                                  <tbody>
                                    <tr>
                                      <th>First Name</th>
                                      <td>
                                        {detail?.user?.first_name
                                          ? detail?.user?.first_name
                                          : 'Not Available'}
                                      </td>
                                      <th>Last Name</th>
                                      <td>
                                        {detail?.user?.last_name
                                          ? detail?.user?.last_name
                                          : 'Not Available'}
                                      </td>
                                    </tr>
                                    <tr>
                                      <th>Username</th>
                                      <td>
                                        {detail?.user?.username
                                          ? detail?.user?.username
                                          : 'Not Available'}
                                      </td>
                                      <th>Gender</th>
                                      <td>
                                        {detail?.user?.gender
                                          ? detail?.user?.gender
                                          : 'Not Available'}
                                      </td>
                                    </tr>
                                    <tr>
                                      <th>Date of Birth</th>
                                      <td>
                                        {detail?.user?.dob ? detail?.user?.dob : 'Not Available'}
                                      </td>
                                      <th>Relationship Status</th>
                                      <td>
                                        {detail?.user?.marital_status
                                          ? detail?.user?.marital_status
                                          : 'Not Available'}
                                      </td>
                                    </tr>
                                    <tr>
                                      <th>Location</th>
                                      <td>
                                        {detail?.user?.current_city
                                          ? detail?.user?.current_city
                                          : 'Not Available'}{' '}
                                      </td>
                                      <th>Mobile</th>
                                      <td>
                                        {detail?.user?.mobile
                                          ? detail?.user?.mobile
                                          : 'Not Available'}
                                      </td>
                                    </tr>
                                    <tr>
                                      <th>Email</th>
                                      <td>
                                        {detail?.user?.email
                                          ? detail?.user?.email
                                          : 'Not Available'}
                                      </td>
                                      <th>Website</th>
                                      <td>
                                        {detail?.user?.website
                                          ? detail?.user?.website
                                          : 'Not Available'}
                                      </td>
                                    </tr>
                                    <tr>
                                      <th>Hobbies</th>
                                      <td>{detail?.user?.hobbies || 'Not Available'}</td>
                                    </tr>
                                    <tr>
                                      <th>Interest</th>
                                      <td>{detail?.user?.interests || 'Not Available'}</td>
                                    </tr>
                                  </tbody>
                                </Table>
                              </div>
                            </div>
                          </Tab.Pane>
                          <Tab.Pane eventKey="second">
                            <div className="user-view-personal">
                              <div className="view-usr-hd d-flex align-items-center justify-content-between mb-3">
                                <h4>
                                  <i className="fas fa-info-circle me-1"></i>Education Details
                                </h4>
                                <Link
                                  className="edit-btn-blue"
                                  to="#"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    navigate(`/admin/users/education`, { state: id });
                                  }}
                                >
                                  + Add Education
                                </Link>
                              </div>
                              {detail?.school_info && detail?.school_info?.length ? (
                                detail?.school_info &&
                                detail?.school_info?.map((data, index) => {
                                  return (
                                    <div className="user-vertical-detail mb-3" key={index}>
                                      <div className="usr-top-action">
                                        <Link
                                          className="edit-grey-btn"
                                          to="#"
                                          onClick={(e) => {
                                            e.preventDefault();
                                            navigate('/admin/users/edit-education', {
                                              state: data
                                            });
                                          }}
                                        >
                                          <i className="fas fa-pen me-1"></i>Edit
                                        </Link>
                                        <Link
                                          className="edit-grey-btn ms-1"
                                          onClick={() => {
                                            deleteHandler(data?.user_qualification_id);
                                          }}
                                        >
                                          <i className="fas fa-trash-alt me-1"></i> Delete
                                        </Link>
                                      </div>
                                      <Table className="custom-table-vertical" bordered>
                                        <tbody>
                                          <tr>
                                            <th>Institute Name </th>
                                            <td>
                                              {data?.institute_name ? data?.institute_name : '-'}
                                            </td>
                                            <th>Department Name</th>
                                            <td>
                                              {data?.department_name == '0'
                                                ? 'Not Available'
                                                : data?.department_name}
                                            </td>
                                          </tr>
                                          <tr>
                                            <th>Course Name</th>
                                            <td>
                                              {data?.course_name
                                                ? data?.course_name
                                                : 'Not Available'}
                                            </td>
                                            <th>Registration Number</th>
                                            <td>
                                              {data?.reg_id !== '0'
                                                ? data?.reg_id
                                                : 'Not Available'}
                                            </td>
                                          </tr>
                                          <tr>
                                            <th>Passout Batch</th>
                                            <td>
                                              {data?.currently_studing_here == '1'
                                                ? 'Present'
                                                : data?.to_year}
                                            </td>
                                          </tr>
                                        </tbody>
                                      </Table>
                                    </div>
                                  );
                                })
                              ) : (
                                <tr>
                                  <td className="align-items-center" aria-colspan={7}>
                                    Data Not Found
                                  </td>
                                </tr>
                              )}
                            </div>
                          </Tab.Pane>
                          <Tab.Pane eventKey="third">
                            <div className="user-view-personal">
                              <div className="view-usr-hd d-flex align-items-center justify-content-between mb-3">
                                <h4>
                                  <i className="fas fa-info-circle me-1"></i>Professional Details
                                </h4>
                                <Link className="edit-btn-blue" to={`/admin/users/work/${id}`}>
                                  + Add Work
                                </Link>
                              </div>
                              {detail && detail?.work_info?.length ? (
                                detail?.work_info &&
                                detail?.work_info?.map((data, index) => {
                                  return (
                                    <div className="user-vertical-detail mb-3" key={index}>
                                      <div className="usr-top-action">
                                        <Link
                                          className="edit-grey-btn"
                                          to="#"
                                          onClick={(e) => {
                                            e.preventDefault();
                                            navigate(
                                              `/admin/users/edit-work/${id}/${data?.user_profession_id}`,
                                              { state: data }
                                            );
                                          }}
                                        >
                                          <i className="fas fa-pen me-1"></i>Edit
                                        </Link>
                                        <Link
                                          className="edit-grey-btn ms-1"
                                          to="#"
                                          onClick={(e) => {
                                            e.preventDefault();
                                            deleteWorkDetailHandler(data?.user_profession_id);
                                          }}
                                        >
                                          <i className="fas fa-trash-alt me-1"></i> Delete
                                        </Link>
                                      </div>
                                      <Table className="custom-table-vertical" bordered>
                                        <tbody>
                                          <tr>
                                            <th> Organisation Name</th>
                                            <td>
                                              {data?.organisation
                                                ? data?.organisation
                                                : 'Not Available'}
                                            </td>
                                          </tr>
                                          <tr>
                                            <th>Designation/Position</th>
                                            <td>
                                              {data?.designation
                                                ? data?.designation
                                                : 'Not Available'}
                                            </td>
                                          </tr>
                                          <tr>
                                            <th>Time Period</th>
                                            <td>
                                              {data?.year_from} to{' '}
                                              {data?.currently_working_here
                                                ? 'Present'
                                                : data?.year_to}
                                            </td>
                                          </tr>
                                        </tbody>
                                      </Table>
                                    </div>
                                  );
                                })
                              ) : (
                                <tr>
                                  <td className="text-center">Data Not Found</td>
                                </tr>
                              )}
                            </div>
                          </Tab.Pane>
                          <Tab.Pane eventKey="fourth">
                            <div className="user-view-personal mb-4">
                              <div className="view-usr-hd d-flex align-items-center justify-content-between mb-3">
                                <h4>
                                  <i className="fas fa-info-circle me-1"></i>Technical Skills
                                </h4>
                              </div>
                              <div className="tab-skill-sec">
                                <Row>
                                  {techSkills && techSkills?.length ? (
                                    techSkills?.map((data, index) => (
                                      <Col md={4} key={index}>
                                        <div className="tab-skill-card mb-4">
                                          <Form.Label>
                                            {data?.skill_name ? data?.skill_name : 'Not Available'}
                                          </Form.Label>
                                          <ProgressBar className={showClassName(data?.value)}>
                                            <ProgressBar
                                              striped
                                              className="progress-bar-improvement"
                                              now={data?.value / 5}
                                              key={3}
                                            />
                                            <ProgressBar
                                              striped
                                              className="progress-bar-fair"
                                              now={data?.value / 5}
                                              key={1}
                                            />
                                            <ProgressBar
                                              className="progress-bar-good"
                                              now={data?.value / 5}
                                              key={2}
                                            />
                                            <ProgressBar
                                              className="progress-bar-vgood"
                                              now={data?.value / 5}
                                              key={2}
                                            />
                                            <ProgressBar
                                              className="progress-bar-excellent"
                                              now={data?.value / 5}
                                              key={2}
                                            />
                                          </ProgressBar>
                                        </div>
                                      </Col>
                                    ))
                                  ) : (
                                    <h2 className="no-data-found col1">
                                      No Technical Skills Avaliable
                                    </h2>
                                  )}
                                </Row>
                              </div>
                            </div>
                            <div className="user-view-personal mb-4">
                              <div className="view-usr-hd d-flex align-items-center justify-content-between mb-3">
                                <h4>
                                  <i className="fas fa-info-circle me-1"></i>Business Skills
                                </h4>
                              </div>
                              <div className="tab-skill-sec">
                                <Row>
                                  {businessSkills && businessSkills?.length ? (
                                    businessSkills?.map((data, index) => (
                                      <Col md={4} key={index}>
                                        <div className="tab-skill-card mb-4">
                                          <Form.Label>
                                            {data?.skill_name ? data?.skill_name : 'Not Available'}
                                          </Form.Label>
                                          <ProgressBar className={showClassName(data?.value)}>
                                            <ProgressBar
                                              striped
                                              className="progress-bar-improvement"
                                              now={data?.value / 5}
                                              key={3}
                                            />
                                            <ProgressBar
                                              striped
                                              className="progress-bar-fair"
                                              now={data?.value / 5}
                                              key={1}
                                            />
                                            <ProgressBar
                                              className="progress-bar-good"
                                              now={data?.value / 5}
                                              key={2}
                                            />
                                            <ProgressBar
                                              className="progress-bar-vgood"
                                              now={data?.value / 5}
                                              key={2}
                                            />
                                            <ProgressBar
                                              className="progress-bar-excellent"
                                              now={data?.value / 5}
                                              key={2}
                                            />
                                          </ProgressBar>
                                        </div>
                                      </Col>
                                    ))
                                  ) : (
                                    <h2 className="no-data-found col1">
                                      No Business Skills Avaliable
                                    </h2>
                                  )}
                                </Row>
                              </div>
                              {/* <Link className="expand-btn" to="#">
                                Expand
                                <i className="fas fa-angle-double-right ms-1"></i>
                              </Link> */}
                            </div>
                            <div className="user-view-personal mb-4">
                              <div className="view-usr-hd d-flex align-items-center justify-content-between mb-3">
                                <h4>
                                  <i className="fas fa-info-circle me-1"></i>Interpersonal Personal
                                  Skills
                                </h4>
                              </div>
                              <div className="tab-skill-sec">
                                <Row>
                                  {interSkills && interSkills?.length ? (
                                    interSkills?.map((data, index) => (
                                      <Col md={4} key={index}>
                                        <div className="tab-skill-card mb-4">
                                          <Form.Label>
                                            {data?.skill_name ? data?.skill_name : 'Not Available'}
                                          </Form.Label>
                                          <ProgressBar className={showClassName(data?.value)}>
                                            <ProgressBar
                                              striped
                                              className="progress-bar-improvement"
                                              now={data?.value / 5}
                                              key={3}
                                            />
                                            <ProgressBar
                                              striped
                                              className="progress-bar-fair"
                                              now={data?.value / 5}
                                              key={1}
                                            />
                                            <ProgressBar
                                              className="progress-bar-good"
                                              now={data?.value / 5}
                                              key={2}
                                            />
                                            <ProgressBar
                                              className="progress-bar-vgood"
                                              now={data?.value / 5}
                                              key={2}
                                            />
                                            <ProgressBar
                                              className="progress-bar-excellent"
                                              now={data?.value / 5}
                                              key={2}
                                            />
                                          </ProgressBar>
                                        </div>
                                      </Col>
                                    ))
                                  ) : (
                                    <h2 className="no-data-found col1">
                                      No Inter-Personal Skills Avaliable
                                    </h2>
                                  )}
                                </Row>
                              </div>
                            </div>
                          </Tab.Pane>
                          {/* Career and goals (form/view-on-form-ediatble) */}
                          <Tab.Pane eventKey="five">
                            <div className="user-view-personal mb-4">
                              <div className="view-usr-hd d-flex align-items-center justify-content-between mb-3">
                                <h4>
                                  <i className="fas fa-info-circle me-1"></i>User Career Goals
                                </h4>
                              </div>
                              <div className="cmmn-form">
                                <Form>
                                  <Row>
                                    <Col xs={12} sm={12} md={12}>
                                      <Form.Group className="mb-3" controlId="formBasicEmail">
                                        <Form.Label>I seek Mentorship In:</Form.Label>
                                        <AsyncCreatableSelect
                                          isMulti
                                          cacheOptions
                                          className="basic-single"
                                          classNamePrefix="select"
                                          isSearchable={true}
                                          name="seek_mentorship_in"
                                          loadOptions={loadMentorShipOption}
                                          placeholder="Add areas where you seek mentorship."
                                          onChange={(e, option) => {
                                            onChangeHandler(e, option);
                                            setSeekMentorId(e);
                                          }}
                                          value={seekMentorId}
                                        />
                                      </Form.Group>
                                    </Col>
                                    <Col xs={12} sm={12} md={12}>
                                      <Form.Group className="mb-3" controlId="formBasicEmail">
                                        <Form.Label>I provide Mentorship In:</Form.Label>
                                        <AsyncCreatableSelect
                                          isMulti
                                          cacheOptions
                                          className="basic-single"
                                          classNamePrefix="select"
                                          isSearchable={true}
                                          name="provide_mentorship_in"
                                          loadOptions={loadMentorShipOption}
                                          placeholder="Add areas where you provide mentorship."
                                          onChange={(e, option) => {
                                            onChangeHandler(e, option);
                                            setProvideMentorShipId(e);
                                          }}
                                          value={provideMentorShipId}
                                        />
                                      </Form.Group>
                                    </Col>
                                    <Col xs={12} sm={12} md={12}>
                                      <Form.Group className="mb-3" controlId="formBasicEmail">
                                        <Form.Label>
                                          Industry:<sup className="text-danger">*</sup>
                                        </Form.Label>
                                        <Select
                                          className="basic-single"
                                          classNamePrefix="select"
                                          isSearchable
                                          name="industry_id"
                                          options={industryList}
                                          value={industryId}
                                          onChange={(e, option) => {
                                            setIndustryId(e);
                                            onChangeHandler(e, option);
                                          }}
                                          placeholder={'Select your industry.'}
                                        />
                                        <div
                                          className={`${clicked && validate(careerDetail).industry_id
                                            ? ''
                                            : 'd-none'
                                            } danger-frm `}
                                        >
                                          {clicked && validate(careerDetail).industry_id}
                                        </div>
                                      </Form.Group>
                                    </Col>

                                    <Col xs={12} sm={12} md={12}>
                                      <Form.Group className="mb-3" controlId="formBasicEmail">
                                        <Form.Label>
                                          Functional Area:<sup className="text-danger">*</sup>
                                        </Form.Label>
                                        <CreatableSelect
                                          isMulti
                                          className="basic-single"
                                          classNamePrefix="select"
                                          isSearchable
                                          value={functionalId}
                                          options={functionalAreaList}
                                          onChange={(e, option) => {
                                            setFunctionalId(e);
                                            onChangeHandler(e, option);
                                          }}
                                          name="functional_area_id"
                                          placeholder="Select functional area."
                                        />
                                        <div
                                          className={`${clicked && validate(careerDetail).functional_area_id
                                            ? ''
                                            : 'd-none'
                                            } danger-frm `}
                                        >
                                          {clicked && validate(careerDetail).functional_area_id}
                                        </div>
                                      </Form.Group>
                                    </Col>

                                    <Col xs={12} sm={12} md={12}>
                                      <Form.Group className="mb-3" controlId="formBasicEmail">
                                        <Form.Label>
                                          Preferred Job Location:
                                          <sup className="text-danger">*</sup>
                                        </Form.Label>
                                        <AsyncSelect
                                          isClearable
                                          isMulti
                                          cacheOptions
                                          defaultOptions
                                          value={jobLocation}
                                          getOptionLabel={(e) => e.label}
                                          getOptionValue={(e) => e.value}
                                          loadOptions={loadJObLocationOption}
                                          className="basic-single"
                                          name="location_id"
                                          onChange={(e) => {
                                            setJobLocation(e);
                                            setCareerDetail({
                                              ...careerDetail,
                                              ['location_id']: e.map((data) => data?.id).join()
                                            });
                                          }}
                                          placeholder="Select location"
                                        />
                                        <div
                                          className={`${clicked && validate(careerDetail).location_id
                                            ? ''
                                            : 'd-none'
                                            } danger-frm `}
                                        >
                                          {clicked && validate(careerDetail).location_id}
                                        </div>
                                      </Form.Group>
                                    </Col>
                                    <Col xs={12} sm={12} md={12}>
                                      <Link className="edit-btn-blue mt-3" onClick={submitHandler}>
                                        {' '}
                                        Save Career Goals
                                      </Link>
                                    </Col>
                                  </Row>
                                </Form>
                              </div>
                            </div>
                          </Tab.Pane>
                        </Tab.Content>
                      </Col>
                    </Row>
                  </Tab.Container>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
      {loading ? <Loader /> : ''}
    </>
  );
}
