import { useEffect, useState } from 'react';
import { Col, Form, InputGroup, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import AsyncCreatableSelect from 'react-select/async-creatable';
import { createSelectDropDownList, disableFutureMonth } from '../../../globals/helper';
import showNotification from '../../../services/NotificationService';
import * as APISERVICES from '../../../services/UserServices';
// import DefaultLogo from '../../assets/images/institute-dummy.jpg';
import DefaultLogo from '../../../assets/images/institute-dummy.jpg';

function WorkForm(props) {
  const { myprofie } = props;
  const [jobLocation, setJobLocation] = useState([]);
  const [locationSearch, setLocationSearch] = useState(null);
  const [showForm, setShowForm] = useState(false);
  const [workList, setWorkList] = useState([]);
  const [selectedId, setSelectedId] = useState('');
  const [companySearch, setCompanySearch] = useState('');
  const [company, setCompany] = useState('');
  const [monthFrom, setMonthFrom] = useState('');
  const [monthTo, setMonthTo] = useState('');
  const [loadInput, setLoadInput] = useState(false);

  const [detail, setDetail] = useState({
    institute_type: '',
    company_name: '',
    industry_name: '',
    functional_area: '',
    level_name: '',
    position: '',
    city: '',
    job_description: '',
    year_from: '',
    year_to: '',
    month_from: '',
    month_to: '',
    currently_working_here: false,
    user_profession_id: ''
  });

  const [organizationList, setOrganizationList] = useState([]);
  const [selectedOrganization, setSelectedOrganization] = useState('');

  const [companyList, setCompanyList] = useState([]);

  const [industryList, setIndustryList] = useState([]);
  const [selectedIndustry, setSelectedIndustry] = useState('');

  const [departmentList, setDepartmentList] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState('');

  const [gradeList, setGradeList] = useState([]);
  const [selectedGrade, setSelectedGrade] = useState('');

  const [location, setLocation] = useState([]);
  const [orgType, setOrgType] = useState('');

  const [isClicked, setIsClicked] = useState(false);

  useEffect(() => {
    getList();
    getAllOptions();
    getLocationDetail();
  }, []);

  useEffect(() => {
    if (selectedOrganization?.label) {
      industryOptions(orgType);
    }
  }, [orgType]);

  const getLocationDetail = () => {
    APISERVICES.locationDetail().then((res) => {
      if (res?.status === 200) {
        setLocation(res?.data?.data?.predictions);
      }
    });
  };

  const handleOrgType = (org_type) => {
    switch (org_type) {
      case 'Govt Agencies':
        return setOrgType('gov_agency');
      case 'Educational Institute':
        return setOrgType('institute');
      case 'Entrepreneur':
        return setOrgType('entrepreneur');
      case 'Corporate':
        return setOrgType('company');
      case 'Non Government':
        return setOrgType('ngo');
      case 'Non Profit':
        return setOrgType('npo');

      default:
        setOrgType(org_type);
    }
  };

  const getList = () => {
    APISERVICES.userWorkDetails().then((res) => {
      if (res?.status === 200) {
        setWorkList(res?.data?.data);
      }
    });
  };

  const onEditHandler = (data) => {
    const {
      user_profession_id,
      level_name,
      position,
      city,
      job_description,
      year_from,
      year_to,
      month_from,
      month_to,
      currently_working_here,
      org_type,
      institute_name,
      industry_name,
      industry_id,
      functional_area_name,
      functional_area,
      level_id,
      institute_id,
      logo,
      state_name,
      image_url
    } = data;
    setDetail({
      institute_type: org_type,
      company_name: institute_name,
      industry_name: industry_name,
      functional_area: functional_area_name,
      level_name,
      position,
      city,
      job_description,
      year_from,
      year_to,
      month_from,
      month_to,
      currently_working_here: currently_working_here == 'No' ? false : true,
      user_profession_id
    });
    setSelectedOrganization({ label: org_type, value: org_type });
    setCompany({
      label: institute_name,
      value: institute_name,
      id: institute_id,
      logo: logo ? process.env.REACT_APP_API_DOMAIN + logo : DefaultLogo
    });
    setSelectedIndustry({ label: industry_name, value: industry_name, id: industry_id });
    setSelectedDepartment({
      label: functional_area_name,
      value: functional_area,
      id: functional_area
    });
    setJobLocation({
      label: state_name,
      value: state_name,
      id: city
    });

    setSelectedGrade({ label: level_name, value: level_name, id: level_id });
    setMonthFrom(`${year_from}-${month_from}`);
    setMonthTo(year_to + '-' + month_to);
    handleOrgType(org_type);
  };

  const onChangeHandler = (e, select = false, name = '') => {
    if (select) {
      if (name == 'industry_name') {
        setDetail({ ...detail, [name]: e?.id });
      }

      if (name == 'functional_area') {
        setDetail({ ...detail, [name]: e?.id });
      }

      if (name == 'institute_type') {
        setDetail({ ...detail, [name]: e?.value });
        handleOrgType(e?.value);
      } else if (name == 'company_name') {
        setDetail({ ...detail, [name]: e?.value });
      } else if (name == 'city') {
        setDetail({ ...detail, [name]: e?.id });
      } else {
        setDetail({ ...detail, [name]: e?.value });
      }
    } else if (name == 'city') {
      setDetail({ ...detail, [name]: e?.id });
    } else {
      const { name, value } = e?.target ? e.target : {};
      setDetail({ ...detail, [name]: value });
    }
  };

  const dateChangeHandler = (e) => {
    const { name, value } = e.target;
    let vals = new Date(value);
    let month = vals.getMonth() + 1;
    let year = vals.getFullYear();
    let list = { ...detail };
    if (name === 'month_from') {
      list['year_from'] = year;
      list['month_from'] = month;
      setMonthFrom(e.target.value);
    } else {
      if (!list.year_from) {
        showNotification('danger', 'check');
      } else {
        setMonthTo(e.target.value);
        list['year_to'] = year;
        list['month_to'] = month;
      }
    }
    setDetail(list);
  };

  const getAllOptions = () => {
    APISERVICES.getOrganizationDropdown().then((res) => {
      if (res?.status === 200) {
        let data = res?.data?.data;
        let arr = [];
        for (let i in data) {
          arr.push({ label: data[i], value: data[i] });
        }
        setOrganizationList(arr);
      }
    });

    APISERVICES.getJobCategoriesDropdown().then((res) => {
      if (res?.status === 200) {
        let data = res?.data?.data;
        setDepartmentList(createSelectDropDownList(data, true));
      }
    });
    APISERVICES.getJobIndustriesDropdown().then((res) => {
      if (res?.status === 200) {
        let data = res?.data?.data;
        setIndustryList(createSelectDropDownList(data, true));
      }
    });

    APISERVICES.getWorkLevelDropdown().then((res) => {
      if (res?.status === 200) {
        let data = res?.data?.data;
        setGradeList(createWorkLevelDropDownList(data));
      }
    });
  };

  const industryOptions = (orgType) => {
    APISERVICES.getOrganizationsDropdown(orgType, '').then((res) => {
      if (res?.status === 200) {
        let data = res?.data?.data;
        setCompanyList(createSelectDropDownList(data, true));
      }
    });
  };

  const createWorkLevelDropDownList = (data, rid = false) => {
    let arr = [];

    if (data?.length) {
      if (rid) {
        data?.map((data) => {
          arr.push({
            id: data.id,
            label: data.name,
            value: data.name
          });
        });
      } else {
        data?.map((data) => {
          arr.push({
            id: data.id,
            label: data.name,
            value: data.name,
            logo: data?.logo
          });
        });
      }
    }

    return arr;
  };

  const resetInitialValue = () => {
    setDetail({
      institute_type: '',
      company_name: '',
      industry_name: '',
      functional_area: '',
      level_name: '',
      position: '',
      city: '',
      job_description: '',
      year_from: '',
      year_to: '',
      month_from: '',
      month_to: '',
      currently_working_here: false
    });
    setSelectedOrganization('');
    setSelectedIndustry('');
    setSelectedDepartment('');
    setSelectedGrade('');
    setMonthFrom('');
    setMonthTo('');
  };

  const deleteHandler = (id) => {
    if (confirm('Do you want to remove your work?') === true) {
      APISERVICES.deleteWorkDetail(id).then((res) => {
        if (res?.status === 200) {
          showNotification('success', res?.data?.message);
          if (id == selectedId) {
            setShowForm(false);
          }
          getList();
        }
      });
    } else {
      return;
    }
  };

  const validate = (values) => {
    let errors = {};
    if (!values.institute_type) {
      errors.institute_type = 'This field is required.';
    }
    if (company == '') {
      errors.company_name = 'This field is required.';
    }
    if (!values.industry_name) {
      errors.industry_name = 'This field is required.';
    }
    if (selectedDepartment == '') {
      errors.functional_area = 'This field is required.';
    }
    if (selectedGrade == '') {
      errors.level_name = 'This field is required.';
    }
    if (!values.position) {
      errors.position = 'This field is required.';
    }
    if (!values.city) {
      errors.city = 'This field is required.';
    }
    if (!values.month_from) {
      errors.month = 'This field is required.';
    }
    if (!values.currently_working_here && values.year_to < values.year_from) {
      errors.month = 'To date should be greater date from start date';
    }
    if (
      !values.currently_working_here &&
      values.month_to < values.month_from &&
      values.year_to < values.year_from
    ) {
      errors.month = 'To date should be greater date from start date';
    }
    return errors;
  };

  const submitHandler = (e) => {
    e.preventDefault();
    setIsClicked(true);

    if (!Object.keys(validate(detail)).length) {
      const body = {
        institute_type: detail?.institute_type,
        company_name: company?.id + '~' + company?.label,
        industry_name: detail?.industry_name,
        functional_area: selectedDepartment?.id + '~' + selectedDepartment?.label,
        level_name: selectedGrade?.id,
        position: detail?.position,
        city: detail?.city,
        job_description: detail?.job_description,
        year_from: detail?.year_from,
        year_to: detail?.year_to,
        month_from: detail?.month_from,
        month_to: detail?.month_to,
        currently_working_here: detail?.currently_working_here,
        user_profession_id: detail.user_profession_id
      };
      let formData = new FormData();
      for (let i in body) {
        formData.append(`${i}`, body[i]);
      }
      APISERVICES.addWorkDetail(formData).then((res) => {
        if (res?.status === 200) {
          showNotification('success', res?.data?.message);
          setShowForm(false);
          resetInitialValue();
          getList();
          setDetail({
            institute_type: '',
            company_name: '',
            industry_name: '',
            functional_area: '',
            level_name: '',
            position: '',
            city: '',
            job_description: '',
            year_from: '',
            year_to: '',
            month_from: '',
            month_to: '',
            currently_working_here: false,
            user_profession_id: ''
          });
          setSelectedDepartment('');
          setSelectedOrganization('');
          setSelectedIndustry('');
          setCompany('');
          setSelectedGrade('');
          setJobLocation('');
          setIsClicked(false);
        }
      });
    }
  };

  const updateHandler = (e) => {
    e.preventDefault();
    setIsClicked(true);
    const body = {
      formType: 'work',
      institute_type: detail?.institute_type,
      company_name: company?.id + '~ ' + company?.label,
      industry: selectedIndustry?.id,
      functional_area: selectedDepartment?.id + '~' + selectedDepartment?.label,
      level_name: selectedGrade?.id + '~' + selectedGrade?.label,
      position: detail?.position,
      city: detail?.city,
      job_description: detail?.job_description,
      year_from: detail?.year_from,
      year_to: detail?.year_to,
      month_from: detail?.month_from,
      month_to: detail?.month_to,
      currently_working_here: detail?.currently_working_here,
      user_profession_id: detail.user_profession_id
    };
    if (!Object.keys(validate(detail)).length) {
      let formData = new FormData();
      for (let i in body) {
        formData.append(`${i}`, body[i]);
      }

      APISERVICES.updateWorkDetail(formData).then((res) => {
        if (res?.status === 200) {
          showNotification('success', res?.data?.message);
          setShowForm(false);
          resetInitialValue();
          getList();
          setDetail({
            institute_type: '',
            company_name: '',
            industry_name: '',
            functional_area: '',
            level_name: '',
            position: '',
            city: '',
            job_description: '',
            year_from: '',
            year_to: '',
            month_from: '',
            month_to: '',
            currently_working_here: false,
            user_profession_id: ''
          });
          setSelectedDepartment('');
          setSelectedOrganization('');
          setSelectedIndustry('');
          setCompany('');
          setSelectedGrade('');
          setJobLocation('');
          setIsClicked(false);
        }
      });
    }
  };

  const loadcompanyDropdown = async (inputValue) => {
    if (inputValue?.length > 1) {
      return APISERVICES.getOrganizationsDropdown(orgType, inputValue).then((res) => {
        if (res?.status === 200) {
          let tempArr = [];
          let data = res?.data?.data;
          if (res?.data?.data) {
            data?.map((data) => {
              tempArr.push({
                id: data?.id,
                label: data?.name,
                value: data?.name,
                logo: data?.logo
                  ? process.env.REACT_APP_API_DOMAIN +
                    '/common/uploads/institute_logo/' +
                    data?.logo
                  : DefaultLogo
              });
            });
          } else {
            tempArr.push({
              id: new Date.now(),
              label: data?.inputValue,
              value: data?.inputValue,
              logo: data?.logo
                ? process.env.REACT_APP_API_DOMAIN + '/common/uploads/institute_logo/' + data?.logo
                : DefaultLogo
            });
          }
          return tempArr;
        }
      });
    }
  };

  const dropdownText = () => {
    if (companySearch?.length > 2) {
      return 'Result not found';
    } else if (!company) {
      return (
        <div>
          {/* <i className="fa fa-spinner fa-pulse me-2"></i> */}
          <span>Please enter atleast 2 characters</span>
        </div>
      );
    } else {
      return;
    }
  };

  const dropdownTextForLocation = () => {
    if (locationSearch?.length > 2) {
      return 'no matches found';
    } else if (!location) {
      return (
        <div>
          {/* <i className="fa fa-spinner fa-pulse me-2"></i> */}
          <span>Please enter atleast 2 characters</span>
        </div>
      );
    } else {
      return;
    }
  };

  const loadJObLocationOption = async (inputValue) => {
    if (inputValue?.length >= 2) {
      setLoadInput(true);
      return APISERVICES.citiesDropDown(inputValue).then((res) => {
        setLoadInput(false);
        if (res?.status === 200) {
          let tempArr = [];
          if (res?.data?.data) {
            let data = res?.data?.data;
            data?.map((data) => {
              tempArr.push({
                id: data?.city_id,
                label: data?.city,
                value: data?.city
              });
            });
          }
          if (res?.data?.data == '') {
            setLoadInput(false);
          }
          return tempArr;
        }
      });
    }
  };

  return (
    <div className="detail_form_div">
      <form className="contact-details" id="">
        <h2>{myprofie ? 'Experience' : 'Working details'}</h2>
        <div className="work-lst-sec">
          {workList && workList.length ? (
            workList?.map((data, index) => (
              <div className="work-lst-card px-3 py-4" key={index}>
                <div className="work-lst-overlay">
                  <Link
                    to={`javascript:void(0)`}
                    onClick={() => {
                      deleteHandler(data?.user_profession_id);
                    }}>
                    <i className="fas fa-trash-alt"></i>
                  </Link>
                  <Link
                    to={`javascript:void(0)`}
                    onClick={(e) => {
                      e.preventDefault();
                      onEditHandler(data);
                      setShowForm(!showForm);
                      setSelectedId(data?.user_profession_id);
                    }}>
                    <i className="fas fa-pencil-alt"></i>
                  </Link>
                </div>
                <Row className="m-0 align-items-center">
                  <Col lg={10} xs={10}>
                    <div className="work-lft">
                      <h4>{data?.designation}</h4>
                      <h5>{data?.organisation}</h5>
                      <p>{data?.city_name}</p>
                      <p>
                        <span>
                          {data?.month_from_name}, {data?.year_from} -
                        </span>{' '}
                        {data?.currently_working_here != 'No' ? (
                          'present'
                        ) : (
                          <>
                            {data?.month_to_name}, {data?.year_to}
                          </>
                        )}
                      </p>
                    </div>
                  </Col>
                  <Col lg={2} xs={2}>
                    {data?.logo && (
                      <div className="work-icn">
                        <img
                          className="img-responsive"
                          src={
                            data?.logo
                              ? process.env.REACT_APP_API_URL + data?.logo
                              : require('../../../assets/images/account-manage-icon.png')
                          }
                          alt="img"
                        />
                      </div>
                    )}
                  </Col>
                </Row>
              </div>
            ))
          ) : (
            <></>
          )}
          {!showForm ? (
            <div className="work-place-btn text-end  px-3 py-3">
              <Link
                to={`javascript:void(0)`}
                onClick={(e) => {
                  e.preventDefault();
                  setShowForm(true);
                  setSelectedId('');
                }}>
                + Add a Workplace
              </Link>
            </div>
          ) : (
            <></>
          )}
        </div>
        {showForm ? (
          <div id="work_form">
            <h2>Fill Working Information</h2>
            <Row className="form-group">
              <Col sm={10} xs={10}>
                <label className="" htmlFor="firstname">
                  Organization Type
                </label>
                <sup className="text-danger">*</sup>
              </Col>
              <Col sm={2} xs={2}>
                <Link to={`javascript:void(0)`} onClick={(e) => e.preventDefault()}>
                  <i className="fa fa-chevron-right" title="EDIT"></i>
                </Link>
              </Col>
              <Col sm={12} xs={12}>
                <Select
                  id="firstname1"
                  className="basic-single mt-3 mb-3"
                  classNamePrefix="select"
                  isClearable={false}
                  isSearchable={true}
                  placeholder="Select organisation type"
                  onChange={(e) => {
                    onChangeHandler(e, true, 'institute_type');
                    setSelectedOrganization(e);
                  }}
                  options={organizationList}
                  value={selectedOrganization}
                  name="institute_type"
                />
                <div
                  className={`${
                    isClicked && validate(detail).institute_type ? '' : 'd-none'
                  } danger-frm `}>
                  {isClicked && validate(detail).institute_type}
                </div>
              </Col>
            </Row>
            <Row className="form-group">
              <Col sm={10} xs={10}>
                <label className="" htmlFor="firstname">
                  Enter name of your company
                </label>
                <sup className="text-danger">*</sup>
              </Col>
              <Col sm={2} xs={2}>
                <Link to={`javascript:void(0)`} onClick={(e) => e.preventDefault()}>
                  <i className="fa fa-chevron-right" title="EDIT"></i>
                </Link>
              </Col>
              <Col sm={12} xs={12}>
                <AsyncCreatableSelect
                  noOptionsMessage={() => dropdownText()}
                  isClearable={true}
                  onMenuClose={() => setCompanySearch(null)}
                  placeholder={`Select organization name from list`}
                  value={company}
                  blurInputOnSelect
                  __isNew__
                  formatOptionLabel={(ins) => (
                    <div className="ins-option">
                      <img src={ins.logo} alt="logo" />
                      <span>{ins.label}</span>
                    </div>
                  )}
                  getOptionLabel={(e) => e.label}
                  getOptionValue={(e) => e.value}
                  loadOptions={loadcompanyDropdown}
                  onInputChange={(newValue) => {
                    setCompanySearch(newValue);
                  }}
                  className="basic-single mt-1"
                  name="company_name"
                  onChange={(e) => {
                    onChangeHandler(e, true, 'company_name');
                    setCompany(() => e);
                  }}
                  components={{
                    DropdownIndicator: () => null,
                    IndicatorSeparator: () => null,
                    LoadingIndicator: () => null
                  }}
                />
                <div
                  className={`${
                    isClicked && validate(detail).company_name ? '' : 'd-none'
                  } danger-frm `}>
                  {isClicked && validate(detail).company_name}
                </div>
              </Col>
            </Row>
            <Row className="form-group">
              <Col sm={10} xs={10}>
                <label className="" htmlFor="firstname">
                  Industry
                </label>
                <sup className="text-danger">*</sup>
              </Col>
              <Col sm={2} xs={2}>
                <Link to={`javascript:void(0)`} onClick={(e) => e.preventDefault()}>
                  <i className="fa fa-chevron-right" title="EDIT"></i>
                </Link>
              </Col>
              <Col sm={12} xs={12}>
                <Select
                  className="basic-single mt-3 mb-3"
                  classNamePrefix="select"
                  isClearable={true}
                  isSearchable={true}
                  options={industryList}
                  name="industry_name"
                  placeholder="Select Industry from list"
                  value={selectedIndustry}
                  onChange={(e) => {
                    onChangeHandler(e, true, 'industry_name');
                    setSelectedIndustry(e);
                  }}
                />
                <div
                  className={`${
                    isClicked && validate(detail).industry_name ? '' : 'd-none'
                  } danger-frm `}>
                  {isClicked && validate(detail).industry_name}
                </div>
              </Col>
            </Row>
            <Row className="form-group">
              <Col sm={10} xs={10}>
                <label className="" htmlFor="firstname">
                  Department/Functional Area
                </label>
                <sup className="text-danger">*</sup>
              </Col>
              <Col sm={2} xs={2}>
                <Link to={`javascript:void(0)`} onClick={(e) => e.preventDefault()}>
                  <i className="fa fa-chevron-right" title="EDIT"></i>
                </Link>
              </Col>
              <Col sm={12} xs={12}>
                <Select
                  className="basic-single mt-3 mb-3"
                  classNamePrefix="select"
                  isClearable={true}
                  isSearchable={true}
                  options={departmentList}
                  name="functional_area"
                  placeholder="Select Department/Functional area"
                  value={selectedDepartment}
                  onChange={(e) => {
                    onChangeHandler(e, true, 'functional_area');
                    setSelectedDepartment(e);
                  }}
                />
                <div
                  className={`${
                    isClicked && validate(detail).functional_area ? '' : 'd-none'
                  } danger-frm `}>
                  {isClicked && validate(detail).functional_area}
                </div>
              </Col>
            </Row>
            <Row className="form-group">
              <Col sm={10} xs={10}>
                <label className="" htmlFor="firstname">
                  Grade/Level
                </label>
                <sup className="text-danger">*</sup>
              </Col>
              <Col sm={2} xs={2}>
                <Link to={`javascript:void(0)`} onClick={(e) => e.preventDefault()}>
                  <i className="fa fa-chevron-right" title="EDIT"></i>
                </Link>
              </Col>
              <Col sm={12} xs={12}>
                <Select
                  className="basic-single mt-3 mb-3"
                  classNamePrefix="select"
                  isClearable={true}
                  isSearchable={true}
                  options={gradeList}
                  name="level_name"
                  placeholder="Select Grade/Level from list"
                  value={selectedGrade}
                  onChange={(e) => {
                    onChangeHandler(e, true, 'level_name');
                    setSelectedGrade(e);
                  }}
                />
                <div
                  className={`${
                    isClicked && validate(detail).level_name ? '' : 'd-none'
                  } danger-frm `}>
                  {isClicked && validate(detail).level_name}
                </div>
              </Col>
            </Row>
            <Row className="form-group">
              <Col sm={10} xs={10}>
                <label className="" htmlFor="firstname">
                  Designation/Position
                </label>
                <sup className="text-danger">*</sup>
              </Col>
              <Col sm={2} xs={2}></Col>
              <Col sm={12} xs={12}>
                <InputGroup className="w-100">
                  <Form.Control
                    placeholder="Enter your designation"
                    aria-label="date"
                    aria-describedby="basic-addon1"
                    type="text"
                    name="position"
                    value={detail?.position}
                    onChange={(e) => {
                      onChangeHandler(e);
                    }}
                  />
                </InputGroup>
                <div
                  className={`${
                    isClicked && validate(detail).position ? '' : 'd-none'
                  } danger-frm `}>
                  {isClicked && validate(detail).position}
                </div>
              </Col>
            </Row>
            <Row className="form-group">
              <Col sm={10} xs={10}>
                <label className="" htmlFor="location">
                  Location
                </label>
                <sup className="text-danger">*</sup>
              </Col>
              <Col sm={2} xs={2}>
                <Link to={`javascript:void(0)`} onClick={(e) => e.preventDefault()}>
                  <i className="fa fa-chevron-right" title="EDIT"></i>
                </Link>
              </Col>
              <Col sm={12} xs={12} className="position-relative">
                <AsyncSelect
                  noOptionsMessage={() => dropdownTextForLocation()}
                  isClearable={true}
                  onMenuClose={() => setLocationSearch(null)}
                  value={jobLocation}
                  blurInputOnSelect
                  getOptionLabel={(e) => e.label}
                  getOptionValue={(e) => e.value}
                  loadOptions={loadJObLocationOption}
                  onInputChange={(newValue) => setLocationSearch(newValue)}
                  className="basic-single mt-1"
                  name="city"
                  onChange={(e) => {
                    onChangeHandler(e, true, 'city');
                    setJobLocation(() => e);
                  }}
                  placeholder="Enter your Job location"
                  components={{
                    DropdownIndicator: () => null,
                    IndicatorSeparator: () => null,
                    LoadingIndicator: () => null
                  }}
                />
                {loadInput && (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      position: 'absolute',
                      right: '38px',
                      top: '13px'
                    }}>
                    <i className="fa fa-spinner fa-pulse me-2"></i>
                  </div>
                )}
                <div
                  className={`${isClicked && validate(detail).city ? '' : 'd-none'} danger-frm `}>
                  {isClicked && validate(detail).city}
                </div>
              </Col>
            </Row>
            <Row className="form-group">
              <Col sm={12} xs={12}>
                <label className="" htmlFor="firstname">
                  Description
                </label>
              </Col>
              <Col sm={12} xs={12}>
                <textarea
                  type="text"
                  className="w-100 border-0"
                  placeholder="Write about your profession..."
                  rows="5"
                  name="job_description"
                  onChange={(e) => {
                    onChangeHandler(e);
                  }}
                  value={detail?.job_description}></textarea>
              </Col>
            </Row>
            <Row>
              <Col sm={12} xs={12}>
                <label className="mt-4 px-5" htmlFor="firstname">
                  Time Period
                </label>
              </Col>
              <Col lg={6} sm={6} xs={12}>
                <Row className="form-group date-pading">
                  <Col sm={3} xs={12}>
                    <label className="" htmlFor="firstname">
                      From
                    </label>
                  </Col>
                  <Col sm={9} xs={12}>
                    <div className="date-cal-card">
                      <InputGroup className="w-100">
                        <Form.Control
                          placeholder="MM/YY"
                          aria-label="date"
                          aria-describedby="basic-addon1"
                          type="month"
                          name="month_from"
                          max={disableFutureMonth()}
                          defaultValue={monthFrom}
                          value={monthFrom}
                          onChange={(e) => {
                            dateChangeHandler(e);
                          }}
                        />
                      </InputGroup>
                      <Link to={`javascript:void(0)`} className="calender"></Link>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <div
                      className={`${
                        isClicked && validate(detail).month ? '' : 'd-none'
                      } danger-frm `}>
                      {isClicked && validate(detail).month}
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col lg={6} sm={6} xs={12}>
                <Row className="form-group date-pading">
                  <Col sm={3} xs={12}>
                    <label className="" htmlFor="firstname">
                      To
                    </label>
                  </Col>
                  {!detail.currently_working_here ? (
                    <Col sm={9} xs={12}>
                      <div className="date-cal-card">
                        <InputGroup className="w-100">
                          <Form.Control
                            placeholder="MM/YY"
                            aria-label="date"
                            aria-describedby="basic-addon1"
                            type="month"
                            name="month_to"
                            value={monthTo}
                            min={monthFrom}
                            onChange={(e) => {
                              dateChangeHandler(e);
                            }}
                          />
                        </InputGroup>
                        <Link to={`javascript:void(0)`} className="calender"></Link>
                      </div>
                    </Col>
                  ) : (
                    'Present'
                  )}
                </Row>
                <Row>
                  <Col md={12}>
                    <div
                      className={`${
                        isClicked && validate(detail).month ? '' : 'd-none'
                      } danger-frm `}>
                      {isClicked && validate(detail).month}
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="m-0 align-items-center py-3 px-4">
              <Col lg={6} sm={6} xs={12}>
                <div className="accnt-rt-check">
                  <Form.Check
                    inline
                    label="I currently working here."
                    name="currently_working_here"
                    onChange={(e) => {
                      setDetail({ ...detail, [e.target.name]: e.target.checked });
                    }}
                    checked={detail?.currently_working_here}
                  />
                </div>
              </Col>
              <Col lg={6} sm={6} xs={12}>
                <div className="accnt-lft-btn d-flex align-items-center justify-content-end">
                  {/* blk */}
                  {selectedId ? (
                    <button
                      className="accnt-btn txt-transfrm "
                      type="button"
                      onClick={updateHandler}>
                      Update
                    </button>
                  ) : (
                    <button
                      className="accnt-btn txt-transfrm "
                      type="button"
                      onClick={submitHandler}>
                      Save
                    </button>
                  )}
                  <button
                    className="wht-btn txt-transfrm ms-2"
                    onClick={() => {
                      resetInitialValue();
                      setShowForm(false);
                      setCompany('');
                      setJobLocation('');
                      setIsClicked(false);
                    }}>
                    Cancel
                  </button>
                </div>
              </Col>
            </Row>
          </div>
        ) : (
          <></>
        )}
      </form>
    </div>
  );
}

export default WorkForm;
