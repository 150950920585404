import { useEffect, useState } from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { ZERO } from '../../../globals/constants';
import { truncate } from '../../../globals/helper';
import showNotification from '../../../services/NotificationService';
import * as APISERVICES from '../../../services/SocialServices';
import { addFriend } from '../../../services/UserServices';
import NoDataFound from '../NoDataFound';

function PeopleKnow() {
  const [peopleData1, setPeopleData1] = useState([]);
  const [peopleData2, setPeopleData2] = useState([]);
  const [isLoading] = useState(false);
  const [clickId, setClickId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showCard, setShowCard] = useState(false);
  const [showButtonLoader, setShowButtonLoader] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    getPeopleYouKNowData();
  }, []);

  useEffect(() => {
    const timeId = setTimeout(() => {
      setShowCard(false);
    }, 1000);
    return () => {
      clearTimeout(timeId);
    };
  }, [showCard]);

  const getPeopleYouKNowData = (stopShimmer) => {
    if (stopShimmer !== 'stopLoading') {
      setLoading(true);
    }
    APISERVICES.getUserSuggestedFriendsApi().then((res) => {
      if (res?.status === 200) {
        setLoading(false);
        let data = res?.data?.data;
        if (data?.length > 10) {
          let number = data?.length / 2;
          setPeopleData1(data?.slice(0, number));
          setPeopleData2(data?.slice(number, data?.length));
        } else {
          setPeopleData1(res?.data?.data);
        }
      }
    });
  };

  const handleSkip = (i, type) => {
    if (type == 1) {
      let filterdata = peopleData1.filter((data, index) => index !== i);
      setPeopleData1(filterdata);
    } else {
      let filterdata = peopleData2.filter((data, index) => index !== i);
      setPeopleData2(filterdata);
    }
  };

  const renderPopover = (props) => {
    let popOverData = props?.popper?.state?.options?.data1
      ? props?.popper?.state?.options?.data1
      : props?.popper?.state?.options?.data2;
    return (
      <Popover className="group-loader-card" id={`popover-basic`} {...props}>
        {/* <Popover.Header as="h3">Popover right</Popover.Header> */}
        <Popover.Body>
          <div className="loader-tooltip">
            <div className="loader-cover">
              <img
                src={
                  popOverData?.image_url
                    ? `${process.env.REACT_APP_API_URL}${popOverData?.image_url}.${popOverData?.image_format}`
                    : require('../../../assets/images/default-male-avatar.png')
                }
                alt="img"
                className="loader-image"
              />
            </div>
            <div className="people-know-loader-dis d-flex align-items-top">
              <div className="people-knw-top d-flex align-items-center">
                <div className="people-icn me-3">
                  <img
                    src={
                      popOverData?.image_url
                        ? `${process.env.REACT_APP_API_URL}${popOverData?.image_url}.${popOverData?.image_format}`
                        : require('../../../assets/images/default-male-avatar.png')
                    }
                    alt="img"
                    className="loader-image"
                  />
                </div>
                <div className="people-hd">
                  <h3>{popOverData?.name}</h3>
                </div>
              </div>
            </div>
            <div className="people-knw-dis-popover pt-3">
              {/* <p>
                <i className="fas fa-graduation-cap me-1"></i> Studied at{' '}
                <Link to="#">SD COLLEGE Chandigarh</Link> in <Link to="#">2005</Link>
              </p> */}
              <p>
                <i className="fas fa-briefcase me-1"></i>Worked as
                <Link to="#">{popOverData?.designation}</Link> {' at '}
                <Link to="#">{popOverData?.organisation}</Link>
              </p>
              <div className="popover-inr-btn">
                <button
                  className="popovr-blk-btn me-1"
                  onClick={(e) => addFriendHandle(e, popOverData?.ID)}>
                  Add Friend
                </button>
                <button className="popovr-yellow-btn">Follow Mentor</button>
              </div>
            </div>
          </div>
        </Popover.Body>
      </Popover>
    );
  };

  /**
   *
   * @param {*} e
   * @param {*} id
   */
  const addFriendHandle = (e, id) => {
    setShowButtonLoader(true);
    e.preventDefault();
    let body = {
      following_id: id
    };
    let formData = new FormData();
    for (let i in body) {
      formData.append(`${i}`, body[i]);
    }
    addFriend(formData).then((res) => {
      if (res?.status === 200) {
        setShowButtonLoader(false);
        getPeopleYouKNowData('stopLoading');
        setShowCard(true);
      } else {
        showNotification('success', res?.data?.message);
      }
    });
  };

  return (
    <>
      {
        peopleData1?.length > 0 && <div className="suggest-mentor people-know bg-white p-3 mb-3">
          {showCard ? (
            <>
              {' '}
              <div className="mt-4 mb-3 d-flex align-items-center flex-column">
                <i className="fa fa-check-circle" style={{ fontSize: '30px', color: '#f7ad1c' }}></i>
                <br></br>
                <span>Friend Request Sent Successfully</span>
              </div>
            </>
          ) : (
            <>
              {' '}
              <div className="d-flex align-items-top justify-content-between flex-wrap">
                <h4>People you may know</h4>
                <Link className="more-btn" target="_blank" to="/user/pmky">
                  More
                </Link>
              </div>
              {/* <Shimmer /> */}
              {loading &&
                Array.from(Array(2), (index) => {
                  return (
                    <ul className="mentor-ship-lst" key={index}>
                      <Shimmer />
                    </ul>
                  );
                })}
              {!loading && peopleData1?.length !== 0 ? (
                <>
                  <ul className="mentor-ship-lst">
                    {peopleData1?.map((data1, index) => {
                      return (
                        <li key={index}>
                          <div className="mentor-card d-flex ">
                            <Link to="#" className="mentor-icn me-2">
                              <OverlayTrigger
                                trigger={['hover', 'focus']}
                                placement="bottom"
                                delay={{ show: 500, hide: 1500 }}
                                data={data1}
                                popperConfig={{
                                  data1
                                }}
                                shouldUpdatePosition={true}
                                overlay={renderPopover}>
                                <img
                                  src={
                                    data1?.image_url
                                      ? process.env.REACT_APP_API_URL +
                                      `${data1?.image_url}.${data1?.image_format}`
                                      : require('../../../assets/images/default-male-avatar.png')
                                  }
                                  alt="img"
                                />
                              </OverlayTrigger>
                            </Link>
                            <div className="mentor-rt-dis">
                              <h4>
                                <Link
                                  // target="_blank"
                                  to="#"
                                  onClick={() => {
                                    if (index == 0) {
                                      window.open(`${window.location.origin}/user/timeline/${data1?.id}`)
                                    } else {
                                      handleSkip(index - 1, 1)
                                    }
                                  }}
                                >
                                  {data1?.name}
                                </Link>
                              </h4>
                              {data1?.designation && (
                                <p>
                                  {data1?.designation} at {truncate(data1?.organisation, 5)}
                                </p>
                              )}

                              <ul className="mentr-action-btn">
                                <li>
                                  <Link
                                    to=""
                                    className="ml-2"
                                    onClick={(e) => {
                                      addFriendHandle(e, data1?.ID);
                                      setClickId(data1?.id);
                                    }}>
                                    {showButtonLoader && clickId == data1?.id ? (
                                      <span>
                                        <i className="fa fa-spinner fa-spin me-2"></i> Add Friend
                                      </span>
                                    ) : (
                                      'Add Friend'
                                    )}
                                  </Link>
                                </li>
                                <li>
                                  <Link to="" onClick={() => handleSkip(index, 1)}>
                                    Skip
                                  </Link>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                  {peopleData2?.length !== ZERO ? (
                    <>
                      <ul className="mentor-ship-lst">
                        {peopleData2?.map((data2, index) => {
                          return (
                            <li key={index}>
                              <div className="mentor-card d-flex">
                                <Link to="#" className="mentor-icn me-2">
                                  <OverlayTrigger
                                    trigger={['hover', 'focus']}
                                    placement="bottom"
                                    delay={{ show: 500, hide: 1500 }}
                                    data={data2}
                                    popperConfig={{
                                      data2
                                    }}
                                    shouldUpdatePosition={true}
                                    overlay={renderPopover}>
                                    <img
                                      src={
                                        data2?.image_url
                                          ? `${process.env.REACT_APP_API_URL}${data2?.image_url}.${data2?.image_format}`
                                          : require('../../../assets/images/default-male-avatar.png')
                                      }
                                      alt="img"
                                    />
                                  </OverlayTrigger>
                                </Link>
                                <div className="mentor-rt-dis">
                                  <h4>
                                    <Link target="_blank" to={`/user/timeline/${data2?.id}`}>
                                      {data2?.name}
                                    </Link>
                                  </h4>
                                  {data2?.designation && (
                                    <p>
                                      {data2?.designation} at {truncate(data2?.organisation, 5)}
                                    </p>
                                  )}

                                  <ul className="mentr-action-btn">
                                    <li>
                                      {data2?.id === clickId && isLoading ? (
                                        <i className="fas fa-spinner fa-spin"></i>
                                      ) : (
                                        <></>
                                      )}
                                      <Link
                                        to=""
                                        className="ml-2"
                                        onClick={(e) => {
                                          addFriendHandle(e, data2?.ID);
                                          setClickId(data2?.id);
                                        }}>
                                        {showButtonLoader && clickId == data2?.id ? (
                                          <span>
                                            <i className="fa fa-spinner fa-spin me-2"></i> Add Friend
                                          </span>
                                        ) : (
                                          'Add Friend'
                                        )}
                                      </Link>
                                    </li>
                                    <li>
                                      <Link to="" onClick={() => handleSkip(index, 2)}>
                                        Skip
                                      </Link>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </li>
                          );
                        })}
                      </ul>
                    </>
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <>
                  {!loading && peopleData1.length == 0 && peopleData2.length == 0 && (
                    <NoDataFound text="No people suggestions avaliable for you" />
                  )}
                </>
              )}
            </>
          )}
        </div>
      }

    </>
  );
}

export default PeopleKnow;

const Shimmer = () => {
  return (
    <li>
      <div className="mentor-card d-flex mentor-loader loading-skeleton">
        <Link to="#" className="mentor-icn me-2">
          <img src={require('../../../assets/images/default-male-avatar.png')} alt="img" />
        </Link>
        <div className="mentor-rt-dis">
          <h4>
            <Link to="#">xDvddfv</Link>
          </h4>
          <p>xDvddfv</p>

          <ul className="mentr-action-btn">
            <li>
              <Link to="" className="ml-2">
                Add Friend
              </Link>
            </li>
            <li>
              <Link to="#">Skip</Link>
            </li>
          </ul>
        </div>
      </div>
    </li>
  );
};
