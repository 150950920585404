import { UNAUTH_USER } from '../action';

const intitalState = {
  isloggedIn:
    localStorage.getItem('userDetail') && localStorage.getItem('userDetail') != undefined && JSON.parse(localStorage.getItem('userDetail'))
      ? true
      : false,
  details:
    localStorage.getItem('userDetail') !== undefined
      ? JSON.parse(localStorage.getItem('userDetail'))
      : null
};

const userReducer = (state = intitalState, action) => {
  switch (action.type) {
    case 'login':
      return {
        ...state,
        isloggedIn: true,
        details: action.payload
      };
    case 'logout':
      return {
        ...state,
        isloggedIn: false,
        details: null
      };
    case UNAUTH_USER: {
      return {
        ...state,
        isloggedIn: false,
        details: null
      };
    }
    default:
      return state;
  }
};

export default userReducer;
