import { useState, useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import HeaderLogin from '../../commoncomponents/HeaderLogin';
import TopTabbings from './TopTabbings';
import SuggestMentor from '../../commoncomponents/newsfeeds/SuggestMentor';
import GroupDiscover from '../../commoncomponents/newsfeeds/GroupDiscover';
import WhatsNew from '../../commoncomponents/newsfeeds/WhatsNew';
import Upcoming from '../../commoncomponents/newsfeeds/Upcoming';
import NewsSidebar from '../../commoncomponents/newsfeeds/NewsSidebar';
import showNotification from '../../../services/NotificationService';
import Form from 'react-bootstrap/Form';
import Select from 'react-select';
import * as APISERVICE from '../../../services/MentorArticlesServices';
import TinyMceEditor from '../../commoncomponents/TinyMceEditor';
import useDocumentTitle from '../../../globals/useDocumentTitle';
import { MiniFooter } from '../../MiniFooter';
import Loader from '../../commoncomponents/loader/loader-large';

function WriteArticle() {
  useDocumentTitle('AlmaBay');
  const [loading, setLoading] = useState(false);
  const [detail, setDetail] = useState({
    title: '',
    description: '',
    tag_fields: ''
  });

  const [toSendAttach, setToSendAttch] = useState([]);
  const [attachedFiles, setAttachedFiles] = useState([]);
  const [tagField, setTagField] = useState([]);
  const [selectedTagField, setSelectedTagField] = useState([]);
  const navigate = useNavigate();
  const [isClicked, setIsClicked] = useState(false);
  const [searchParams] = useSearchParams();
  const [fileName, setFileName] = useState([]);
  const [load, setLoad] = useState(false);
  const id = searchParams.get('id');
  const [buttonLoader, setButtonLoader] = useState(false);

  useEffect(() => {
    if (id) {
      getDetail();
    }
    getTagFieldList();
  }, []);

  const getDetail = () => {
    setLoading(true);
    APISERVICE.articleDetail(id).then((res) => {
      if (res?.status === 200) {
        setLoading(false);
        const result = res?.data?.data;

        let arr = [];
        let topicName = res?.data?.data?.topics_name?.split(',');
        result?.tag_fields?.split(',')?.map((data, index) => {
          arr.push({ id: data, label: topicName[index], value: topicName[index] });
        });
        setDetail({
          title: result?.title,
          description: result?.description,
          tag_fields: arr.map((data) => data?.id).join()
        });
        setSelectedTagField(arr);

        setAttachedFiles(res.data.data?.attachments);
      }
    });
  };

  const getTagFieldList = () => {
    setLoad(true);

    APISERVICE.getTopicList().then((res) => {
      setLoad(false);
      if (res?.status === 200) {
        let arr = [];
        res?.data?.data?.map((data) => {
          arr.push({ id: data?.id, value: data?.name, label: data?.name });
        });
        setTagField(arr);
      }
    });
  };

  const onChangeHandler = (e, select = false, name = '', data = '') => {
    if (select) {
      if (name == 'description') {
        setDetail({ ...detail, [name]: data });
      } else {
        let selectedOpt = [];
        e?.map((data) => {
          selectedOpt.push(data?.id);
        });
        setSelectedTagField(e);
        setDetail({ ...detail, [name]: selectedOpt.join() });
      }
    } else {
      const { name, value, type } = e.target;
      if (type === 'file') {
        // const fileExtension = e?.target?.files[0]?.type.split('/')[1];
        // const validExtensions = ['png', 'jpeg', 'jpg', 'csv', 'jpeg'];
        // if (!validExtensions.includes(fileExtension)) {
        //   showNotification('danger', 'Please select png, csv, jpeg, jpg format');
        //   return false;
        // }

        setFileName(e.target.files[0]);
        let fileData = e.target.files;
        for (let i = 0; i < fileData.length; i++) {
          toSendAttach.push(fileData[i]);
        }
        setToSendAttch(toSendAttach);
      } else {
        setDetail({ ...detail, [name]: value });
      }
    }
  };

  const validate = (value) => {
    let errors = {};
    if (!value.title.trim()) {
      errors.title = 'This field is required';
    } else if (value.title.trim().length < 5) {
      errors.title = 'Title must contain 6 character';
    }
    if (!value.description.trim()) {
      errors.description = 'This field is required';
    }
    return errors;
  };

  const submitHandler = (e) => {
    e.preventDefault();
    setIsClicked(true);
    if (!Object.keys(validate(detail)).length) {
      setButtonLoader(true);
      if (detail?.tag_fields != '') {
        let formData = new FormData();
        for (let i in detail) {
          formData.append(`${i}`, detail[i]);
        }
        for (let i in toSendAttach) {
          formData.append(`attach_files[]`, toSendAttach[i]);
        }
        APISERVICE.addArticle(formData).then((res) => {
          if (res?.status === 200) {
            setButtonLoader(false);
            showNotification('success', 'Article Posted Successfully');
            navigate(`/user/mentor-article/${res?.data?.data?.id}`);
          } else {
            showNotification('danger', res?.data?.message?.attach_files);
          }
        });
      } else {
        setButtonLoader(false);
        showNotification('danger', 'Kindly tag your question to make public or select mentors');
      }
    } else {
      return;
    }
  };

  const updateHandler = (e) => {
    e.preventDefault();
    setIsClicked(true);
    if (!Object.keys(validate(detail)).length) {
      setButtonLoader(true);
      if (detail?.tag_fields != '') {
      
        let formData = new FormData();
        for (let i in detail) {
          formData.append(`${i}`, detail[i]);
        }
        for (let i in toSendAttach) {
          formData.append(`attach_files[]`, toSendAttach[i]);
        }
        APISERVICE.updateMyArticle(id, formData).then((res) => {
          if (res?.status === 200) {
            setButtonLoader(false);
            showNotification('success', 'Article updated successfully');
            navigate('/user/my-articles');
          }
        });
      } 
      else {
        setButtonLoader(false);
        showNotification('danger', 'Kindly tag your question to make public or select mentors');
      }
      
      }
      else {
        return;
      }

  };

  const deleteAttachmentHandler = (e, id) => {
    e.preventDefault();
    APISERVICE.deleteAttachmentFile(id).then((res) => {
      if (res?.status === 200) {
        showNotification('success', res?.data?.message);
        getDetail();
      }
    });
  };

  return (
    <>
      <HeaderLogin />
      <div className="header-lnk-feeds">
        <div className="container">
          <TopTabbings state={'mentor'} />
        </div>
      </div>
      <section className="news-feed-sec bg-grey py-4">
        <Container>
          <Row>
            <Col xs={12} md={12} lg={2}>
              <NewsSidebar state="2" current="writeArticle" />
            </Col>
            <Col xs={12} md={12} lg={7}>
              <div className="write-article-frm bg-white p-3">
                <h4 className="mb-3">{id ? 'Edit Article' : 'Write An Article'}</h4>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Control
                    value={detail?.title}
                    name="title"
                    onChange={onChangeHandler}
                    type="text"
                    placeholder="Add a title.."
                  />
                  <div
                    className={`${
                      isClicked && validate(detail)?.title ? '' : 'd-none'
                    } danger-frm px-0`}>
                    {isClicked && validate(detail)?.title}
                  </div>
                </Form.Group>

                <Form.Group className="mb-2" controlId="exampleForm.ControlTextarea1">
                  <TinyMceEditor
                    onChangeHandler={onChangeHandler}
                    description={detail?.description}
                    placeholder={'Article Description.'}
                  />
                  <div
                    className={`${
                      isClicked && validate(detail)?.description ? '' : 'd-none'
                    } danger-frm px-0`}>
                    {isClicked && validate(detail)?.description}
                  </div>
                </Form.Group>

                <div className="attach-card-inr d-flex justify-content-between">
                  <div>
                    <div>
                      <Form.Group controlId="formFile" className="mb-2 attach-file-card">
                        <Form.Control
                          type="file"
                          name="attach_files"
                          onChange={onChangeHandler}
                          accept=".png,.jpg,.jpeg"
                          multiple={true}
                        />
                        <span className="attach-file-inr">
                          <i className="fas fa-paperclip me-2"></i>
                          Attach Files
                          <span className="attach-file-name">{detail?.attach_files?.name}</span>
                        </span>
                      </Form.Group>
                    </div>
                    <span className="attach-file-name">{fileName?.name}</span>
                  </div>
                  {id ? (
                    <div className="attach-file-lst">
                      <ul>
                        {attachedFiles &&
                          attachedFiles?.map((data, index) => (
                            <li key={index}>
                              <i className="fas fa-paperclip"></i>
                              <a href={data?.document} target="__blank">
                                Attached on
                              </a>
                              <span>({data?.added_on}) |</span>
                              <Link
                                title="Remove"
                                classname="trash-btn"
                                to="#"
                                onClick={(e) => {
                                  deleteAttachmentHandler(e, data?.id, data?.doc_of_id);
                                }}>
                                <i className="fas fa-trash-alt"></i>
                              </Link>
                            </li>
                          ))}
                      </ul>
                    </div>
                  ) : (
                    ''
                  )}
                </div>
                <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                  <div className="select-topic-card">
                    <Form.Label className="mb-0 topic-hd">Add Topics</Form.Label>

                    <div style={{ display:"relative" }}>
                      <Select
                        isMulti
                        name="colors"
                        className="basic-multi-select mt-3"
                        classNamePrefix="select"
                        options={tagField}
                        value={selectedTagField}
                        onChange={(e) => onChangeHandler(e, true, 'tag_fields')}
                        placeholder="Click here to select tags, selecting tag will notify related users about your article."
                        noOptionsMessage={() => 'Searching'}
                      />
                    </div>
                    {load && (
                        <span style={{ right: '362px', marginTop:'-20px' , position:"absolute" }}>
                        <i className="fa fa-spinner fa-pulse me-2"></i>
                      </span>
                    )}
                   
                  </div>
                </Form.Group>
                <div className="cmmnt-btn text-end mt-3 border-top  pt-3">
                  {id ? (
                    <button className="accnt-btn" type="button" onClick={updateHandler}>
                      {buttonLoader ? (
                        <span>
                          <i className="fa fa-spinner fa-spin me-2"></i>Update
                        </span>
                      ) : (
                        'Update'
                      )}
                    </button>
                  ) : (
                    <button className="accnt-btn" type="button" onClick={submitHandler}>
                      {buttonLoader ? (
                        <span>
                          <i className="fa fa-spinner fa-spin me-2"></i>Submit
                        </span>
                      ) : (
                        'Submit'
                      )}
                    </button>
                  )}
                </div>
              </div>
            </Col>
            <Col lg={3} md={12}>
              <div>
                <SuggestMentor />
              </div>
              <div>
                <GroupDiscover />
              </div>
              <div>
                <WhatsNew />
              </div>
              <div>
                <Upcoming />
              </div>
              <MiniFooter />
            </Col>
          </Row>
        </Container>
        {/* {loading ? <Loader /> : null} */}
      </section>
    </>
  );
}

export default WriteArticle;
