function PageNotFound() {
  return (
    <div className="wrappermain_outer">
      <div className="not-found">
        <h1>404</h1>
        <h3>This URL is not valid..!</h3>
      </div>
    </div>
  );
}

export default PageNotFound;
