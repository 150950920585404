/** @copyright : ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
 @author     : Shiv Charan Panjeta 

 All Rights Reserved.
 Proprietary and confidential :  All information contained herein is, and remains
 the property of ToXSL Technologies Pvt. Ltd. and its partners.
 Unauthorized copying of this file, via any medium is strictly prohibited.
*/

import { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { useLocation } from 'react-router-dom';
// import { getRoleList } from '../../services/AuthServices';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Loader from '../../../components/commoncomponents/loader/loader-large';
import {
  addRole,
  getRoleDetail,
  getRoleView,
  multiActionOnPermission,
  updateRole
} from '../../../services/AuthServices';
import showNotification from '../../../services/NotificationService';
import RolesViewTree from '../RolesViewTree';

let $ = require('jquery');

export default function RolesView() {
  const [loading, setLoading] = useState(false);
  const [tree, setTree] = useState({
    checked: [],
    expanded: [],
    tragetNodeValue: [],
    targetNodeChecked: false,
    targetNodeExpanded: false,
    targetNodeIsChild: false
  });

  const [list, setList] = useState([]);
  const [allModules, setAllModules] = useState([]);

  const [response, setResponse] = useState('');

  const [roleDetail, setRoleDeatil] = useState({
    role_name: '',
    status: ''
  });
  const [clicked, setClicked] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const id = location.state;

  useEffect(() => {
    if (id) {
      getDetail(id);
    }
  }, []);

  const getDetail = (id) => {
    setLoading(true);

    getRoleDetail(id).then((res) => {
      if (res?.status === 200) {
        const data = res?.data?.data;
        setRoleDeatil({
          role_name: data?.role_name,
          status: data?.status,
          id: data?.id
        });
      }
    });

    getRoleView(id).then((res) => {
      setLoading(false);
      if (res?.status === 200) {
        setList(res?.data?.data);
        setAllModules(res?.data?.data?.allModules);
        setTree((prev) => ({
          ...prev,
          checked: res?.data?.data?.checked?.map((data) => {
            return data?.permission_id;
          })
        }));
      }
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setRoleDeatil({ ...roleDetail, [name]: value });
  };

  const validate = () => {
    let errors = {};
    if (roleDetail?.role_name == '') {
      errors.role_name = 'Please enter role name ';
    }
    if (roleDetail?.status == '') {
      errors.status = 'Please select status';
    }

    return errors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setClicked(true);

    if (!Object.keys(validate()).length) {
      allModules;
      let formData = new FormData();
      for (let i in roleDetail) {
        formData.append(`${i}`, roleDetail[i]);
      }
      if (!id) {
        addRole(formData).then((res) => {
          if (res?.status === 200) {
            showNotification('success', res?.data?.data);
            navigate('/admin/users/roles');
            setRoleDeatil({
              role_name: '',
              status: ''
            });
          }
        });
      } else {
        updateRole(formData).then((res) => {
          if (res?.status === 200) {
            showNotification('success', res?.data?.data);
            navigate('/admin/users/roles');
            setRoleDeatil({
              role_name: '',
              status: ''
            });
          }
        });
      }
    }
  };

  const addpermissions = () => {
    var ids = [];
    $('input[name="module_ids[]"]:checked').each(function (i) {
      ids[i] = $(this).val();
    });

    if (tree.checked.length == 0) {
      $('html, body').animate({ scrollTop: 0 }, 500);
      $('.ajaxMessage').text('Please Select Any Record').addClass('alert alert-danger');

      return false;
    }

    var roleid = $('#roleid').val();

    let formData = new FormData();

    formData.append('roleid', id);
    formData.append(
      'ids',
      tree.targetNodeChecked || tree.expanded || !tree.targetNodeIsChild
        ? [
            ...new Set(
              tree.checked.concat(tree.expanded.length > 1 ? tree.expanded : tree.tragetNodeValue)
            )
          ].toString()
        : [...new Set(tree.checked)].toString()
    );
    formData.append('expanded', [...new Set(tree.expanded)].toString());

    multiActionOnPermission(formData).then((res) => {
      if (res?.data?.statusCode == 200) {
        $('html, body').animate({ scrollTop: 0 }, 300);
        $('.ajaxMessage').text(res?.data?.detail).addClass('alert alert-success');
        setTimeout(() => {
          $('.ajaxMessage').text('').removeClass('alert alert-success');
        }, 2000);
      }
    });
  };

  return (
    <>
      <div className="dash-main-content">
        <Container fluid>
          <div className="dash-page-content">
            <div className="dash-page-title">
              <Row>
                <Col xs={12} sm={12} md={12}>
                  <div className="dash-title-bar d-flex align-items-center justify-content-between">
                    <div className="title-lft d-flex align-items-center">
                      <div className="dash-icn">
                        <i className="fa fa-user"></i>
                      </div>
                      <div className="dash-title-dis">
                        <h5>{roleDetail?.role_name}'s Role Details</h5>
                      </div>
                    </div>
                    <div className="title-rt-btn">
                      <button
                        type="button"
                        className="green_button_admin"
                        onClick={() => navigate(`/admin/users/add-roles`, { state: id })}>
                        Edit Role
                      </button>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="dash-content-card">
              <div className="ajaxMessage"></div>

              <div className="tab-content clearfix">
                <div className="tab-pane active" id="personal-details">
                  <div className="row">
                    <div className="col1">
                      <strong>
                        Select Privileges <span className="error"></span>
                      </strong>
                      {/* <ul
                            className="checktree-root"
                            dangerouslySetInnerHTML={{ __html: allModules }}></ul> */}
                      <RolesViewTree allModules={allModules} tree={tree} setTree={setTree} />
                    </div>
                  </div>
                </div>
              </div>

              <div className="form-btns d-flex align-items-center mt-3">
                <input type="hidden" id="roleid" name="roleid" value={roleDetail?.id} />
                <button
                  className="green_button_admin"
                  type="button"
                  onClick={() => addpermissions()}>
                  Save
                </button>
              </div>
            </div>
          </div>
        </Container>
      </div>
      {loading ? <Loader /> : null}
    </>
  );
}
