/**
 * @copyright : ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
@author    : Shiv Charan Panjeta 
 
All Rights Reserved.
Proprietary and confidential :  All information contained here in is, and remains
the property of ToXSL Technologies Pvt. Ltd. and it's partners.
Unauthorized copying of this file, via any medium is strictly prohibited.
 * 
 */

import { Link } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  deleteAlbum,
  deleteImageAlbum,
  getAlbumDetail,
  getLikeUnlike,
  getSinglePostDetail,
  postComment,
  postFollowUnfollow,
  uploadImageInAlbum
} from '../../../services/SocialServices';
import { Modal, Row, Col } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import showNotification from '../../../services/NotificationService';
import { useNavigate } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';
import { useDispatch } from 'react-redux';
import { HideAlbumManager } from '../../../redux/action';
import moment from 'moment';

function AlbumManager() {
  const [selectedPreview, setSelectedPreview] = useState([]);
  const [showDelete, setShowDelete] = useState(false);
  const [show2, setShow2] = useState(false);
  const [loading, setLoading] = useState(false);
  const [clickedImage, setClickedImage] = useState('');
  const handleClose2 = () => setShow2(false);
  const [postId, setPostId] = useState('');
  const [modalImage, setModalImage] = useState([]);
  const [modalComment, setModalComment] = useState('');
  const [progress, setProgress] = useState(0);
  const [showProgress, setShowProgress] = useState(false);
  const [deleteType, setDeleteType] = useState('');
  const [imgPostId, setImgPostId] = useState('');
  const { userId, albumId } = useParams();
  const userDetail = useSelector((state) => state?.user?.details);
  const [albumData, setAlbumData] = useState({});
  const [albumPics, setAlbumPics] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    fetchSingleAlbumData();
  }, [albumId]);

  const fetchSingleAlbumData = () => {
    getAlbumDetail(albumId).then((res) => {
      if (res?.status == 200) {
        setAlbumData(res?.data?.data?.sk?.album);
        setAlbumPics(res?.data?.data?.sk?.photos);
      }
    });
  };

  const handleFile = (e) => {
    const arr = [];
    const targetfile = e?.target?.files;
    const targetfileobj = [...targetfile];
    targetfileobj.map((file) => {
      arr.push(URL.createObjectURL(file));
    });
    setSelectedPreview(arr);
    setShowProgress(true);
    let formData = new FormData();
    formData.append(`album_id`, albumId);
    for (let i in targetfile) {
      formData.append(`photos[]`, targetfile[i]);
    }
    uploadImageInAlbum(formData, setProgress).then((res) => {
      if (res?.status == 200) {
        setShowProgress(false);
      }
    });
  };

  const handleDeleteImage = (e) => {
    e.preventDefault();
    deleteImageAlbum(imgPostId).then((res) => {
      if (res?.status == 200) {
        fetchSingleAlbumData();
        setShowDelete(false);
      }
    });
  };

  const handleDeleteAlbum = (e) => {
    e.preventDefault();
    deleteAlbum(albumId).then((res) => {
      if (res?.status == 200) {
        showNotification('success', res?.data?.message);
        setShowDelete(false);
        dispatch(HideAlbumManager());
        navigate(`/user/albums/${userId}`);
      }
    });
  };

  const handleGetComments = (i) => {
    let id = albumPics?.filter((data, index) => {
      return index == i;
    });
    setPostId(id[0]?.post_id);
    getSinglePostDetail(id[0]?.post_id).then((res) => {
      if (res?.status === 200) {
        setModalImage(res?.data?.data);
      }
    });
  };
  const getPostSingleDetail = (id) => {
    setLoading(true);
    getSinglePostDetail(id).then((res) => {
      if (res?.status === 200) {
        setLoading(false);
        setModalImage(res?.data?.data);
      } else {
        setLoading(false);
      }
    });
  };

  const isPostcomment = (id, type) => {
    postComment(id, {
      text: type == 1 ? '' : modalComment,
      recipient_id: ''
    }).then((res) => {
      if (res?.status === 200) {
        showNotification('success', res?.data?.message);
        setModalComment('');
        getPostSingleDetail(postId);
      }
    });
  };

  const isFollowUnfollow = (e, id, type) => {
    e.preventDefault();
    postFollowUnfollow(id).then((res) => {
      if (res?.status === 200) {
        showNotification('success', res?.data?.data);

        if (type == 'modal') {
          getPostSingleDetail(postId);
        }
      }
    });
  };

  const getPostLike = (e, id) => {
    e.preventDefault();
    getLikeUnlike(id).then((res) => {
      if (res?.status === 200) {
        getPostSingleDetail(postId);
        showNotification('success', res?.data?.message);
      }
    });
  };

  return (
    <>
      <div className="album-page bg-white p-0">
        <div className="module-hd d-flex align-items-center justify-content-between mb-3">
          <h4>
            Album Manager <i className="fas fa-users me-2"></i>
          </h4>
        </div>
        <div className="album-time-hd d-flex align-items-center justify-content-between py-2 px-3 mb-2">
          <h5>
            ALbum of the year
            <span className="drag-card ms-2">Drag and drop files or click below to upload</span>
          </h5>
          <div>
            {userDetail?.encrypted_id == userId && (
              <>
                {' '}
                <Link className="me-3" to={`/user/edit_album/${albumId}?id=${userId}`}>
                  <i className="fas fa-plus me-2"></i>
                  Edit Album
                </Link>
                <Link
                  to="#"
                  onClick={() => {
                    setShowDelete(true);
                    setDeleteType('album');
                  }}>
                  <i className="fas fa-times me-2"></i>
                  Delete Album
                </Link>
              </>
            )}
          </div>
        </div>
        <div className="album-time-hd d-flex align-items-center  py-2 px-3 mb-2">
          <i className="far fa-file-alt"></i> <div className="px-2">{albumData?.descr}</div>
        </div>
        <div className="p-3">
          <div className="album-drop-zone">
            {userDetail?.encrypted_id == userId && (
              <Form.Group controlId="formFileMultiple" className="drop-card">
                <Form.Control type="file" multiple onChange={handleFile} />
              </Form.Group>
            )}

            {albumPics?.length !== 0 || selectedPreview?.length !== 0 ? (
              <>
                {' '}
                <div className="album-img-lst">
                  <ul>
                    {albumPics?.map((data, index) => {
                      return (
                        <li key={data?.postid}>
                          <Link
                            className="album-img-card"
                            to="#"
                            onClick={(e) => {
                              e.preventDefault();
                              handleGetComments(index);
                              setClickedImage(index);
                              setShow2(true);
                            }}>
                            <img src={data?.complete_url} alt="img" />
                          </Link>
                          {userDetail?.encrypted_id == userId && (
                            <Link
                              className="album-img-del"
                              to="#"
                              onClick={() => {
                                setShowDelete(true);
                                setDeleteType('image');
                                setImgPostId(data?.postid);
                              }}>
                              <i className="fas fa-trash-alt"></i>
                            </Link>
                          )}
                        </li>
                      );
                    })}
                    {selectedPreview?.length !== 0 &&
                      selectedPreview?.map((data, index) => {
                        return (
                          <li key={index + 1}>
                            <Link className="album-img-card" to="#">
                              <img src={data} alt="img" />
                            </Link>
                            <Link className="album-img-del" to="#">
                              <i className="fas fa-trash-alt"></i>
                            </Link>
                            {showProgress && (
                              <div>
                                <progress
                                  className="progress-bar-card"
                                  value={progress}
                                  max="100"
                                />
                              </div>
                            )}
                          </li>
                        );
                      })}
                  </ul>
                </div>
              </>
            ) : (
              <>
                <div className="dz-default dz-message">
                  <span>Drop files here to upload</span>
                </div>
              </>
            )}
          </div>
        </div>
        <span className="drag-card ms-3 mb-3">Drag and drop files or click below to upload</span>
      </div>

      <Modal show={showDelete} onHide={() => setShowDelete(false)}>
        <Modal.Header>
          {deleteType == 'album'
            ? 'Delete Album' + '?'
            : ' Are you sure you want to delete this image ?'}
        </Modal.Header>
        <Modal.Body>
          <Button variant="secondary" onClick={() => setShowDelete(false)}>
            Close
          </Button>{' '}
          <Button
            variant="primary"
            type="button"
            onClick={deleteType == 'album' ? handleDeleteAlbum : handleDeleteImage}>
            Delete
          </Button>
        </Modal.Body>
      </Modal>

      <Modal
        show={show2}
        onHide={show2}
        backdrop="static"
        keyboard={false}
        className="timeline-modal"
        size="xl">
        <Modal.Body>
          {loading ? (
            <div className="loader-modal">
              <img src={require('../../../assets/images/Loading_icon-small.gif')} alt="img" />
            </div>
          ) : (
            <div className="timeline-sec">
              <Row className="m-0">
                <Col className="p-0" md={8} sm={8}>
                  <div className="time-line-card bg-blck">
                    <Swiper
                      initialSlide={clickedImage}
                      spaceBetween={50}
                      slidesPerView={1}
                      modules={[Navigation]}
                      navigation={true}
                      onSlideChange={(swiper) => handleGetComments(swiper?.activeIndex)}>
                      {albumPics?.map((item, index) => {
                        return (
                          <SwiperSlide key={index} id={item?.id}>
                            <div className="timeline-slide-images">
                              <img src={item?.complete_url} alt="img" />
                            </div>
                          </SwiperSlide>
                        );
                      })}
                    </Swiper>
                  </div>
                </Col>
                <Col className="p-0" md={4} sm={4}>
                  <div className="time-line-cmmnts">
                    <Link className="close-modal-btn" to="#" onClick={handleClose2}>
                      <i className="fas fa-times"></i>
                    </Link>
                    <div className="pofile-upload-card">
                      <div className="profile-upload-sec d-flex align-items-top mb-3">
                        <span className="social-profile-icn me-3">
                          <img
                            src={require('../../../assets/images/default-male-avatar.png')}
                            alt="img"
                          />
                        </span>
                        <div className="social-profile-detail d-flex align-items-top justify-content-between ">
                          <div className="social-profile-name">
                            <h4>
                              <Link
                                to={`/user/timeline/${modalImage?.user?.encrypt_userid}`}
                                onClick={handleClose2}>
                                {modalImage?.user?.first_name}
                              </Link>{' '}
                              updated his profile picture
                            </h4>
                            <p>
                              <i className="far fa-clock me-2"></i>
                              {modalImage?.timestamp}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="profile-upload-ftr">
                        <div className="profile-likes-count d-flex align-items-center justify-content-between">
                          <div className="likes-count">
                            <Link to="#">{modalImage?.likes} Likes</Link>
                            <Link to="#">{modalImage?.shares} Shares</Link>
                          </div>
                        </div>
                        <div className="profile-like-btns">
                          <ul>
                            <li>
                              <Link
                                to=""
                                title="Like"
                                onClick={(e) => {
                                  getPostLike(e, modalImage?.id);
                                }}>
                                <i className="fas fa-thumbs-up me-2"></i>Like
                              </Link>
                            </li>
                            <li>
                              <Link to="#" title="Share">
                                <i className="fas fa-share me-2"></i>Share
                              </Link>
                            </li>
                            <li>
                              {modalImage?.is_follow == 1 ? (
                                <Link
                                  to="#"
                                  title="Unfollow this post"
                                  onClick={(e) => {
                                    isFollowUnfollow(e, modalImage?.id, 'modal');
                                  }}>
                                  Unfollow this post
                                </Link>
                              ) : (
                                <Link
                                  to="#"
                                  title="follow this post"
                                  onClick={(e) => {
                                    isFollowUnfollow(e, modalImage?.id, 'modal');
                                  }}>
                                  Follow this post
                                </Link>
                              )}
                            </li>
                          </ul>
                        </div>
                      </div>

                      <div className="profile-upload-cmmnt mt-3">
                        {modalImage?.latest_comment?.length ? (
                          <>
                            {modalImage?.latest_comment?.map((data) => {
                              return (
                                <>
                                  <div className="profile-cmmnt-lst d-flex align-items-top mb-3">
                                    <span className="social-profile-icn me-3">
                                      <img
                                        src={process.env.REACT_APP_API_DOMAIN + data?.user?.avtar}
                                        alt="img"
                                      />
                                    </span>
                                    <div className="profile-cmmnt-user ">
                                      <div className="d-flex align-items-top justify-content-between">
                                        <Link to="#">{data?.user?.first_name}</Link>

                                        <div className="profile-cmmnt-time d-flex align-items-center ">
                                          <span>
                                            {/* {data?.latest_comment?.timestamp} */}
                                            {
                                              <span>
                                                {console.log(
                                                  moment(new Date(JSON.parse(data.time)))
                                                    .format('YYYY-MM-DD hh:mm:ss')
                                                    .toString()
                                                )}
                                                {/* {moment(
                                                  new Date(data?.time)
                                                    .toISOString()
                                                    .toLocaleString(undefined, {
                                                      timeZone: 'Asia/Kolkata'
                                                    })
                                                ).fromNow()} */}
                                              </span>
                                            }
                                            Comment
                                          </span>
                                        </div>
                                      </div>
                                      <div className="profile-cmmnt-dis">
                                        <p>{data?.text}</p>
                                        <div className="cmmnt-likes d-flex align-items-center">
                                          <Link to="#">
                                            <i className="fas fa-thumbs-up me-1"></i>
                                            {data?.likes}
                                          </Link>
                                          likes
                                        </div>
                                      </div>
                                    </div>
                                    {data?.user?.id === userDetail?.id ? (
                                      <Link className="close-btn" to="#">
                                        <i className="fas fa-times"></i>
                                      </Link>
                                    ) : (
                                      <>
                                        {' '}
                                        <></>
                                      </>
                                    )}
                                  </div>
                                </>
                              );
                            })}
                          </>
                        ) : (
                          <></>
                        )}
                        <div className="profile-cmmnt-input d-flex align-items-top">
                          <span className="social-profile-icn me-3">
                            <img
                              src={process.env.REACT_APP_API_DOMAIN + modalImage?.user?.avtar}
                              alt="img"
                            />
                          </span>
                          <div className="cmmnt-card">
                            <Form.Control
                              type="text"
                              onChange={(e) => {
                                setModalComment(e.target.value);
                              }}
                              onKeyPress={(e) => {
                                e.key == 'Enter' ? isPostcomment(modalImage?.id, 0) : '';
                              }}
                              value={modalComment}
                              placeholder="Write a comment... Press Enter to post"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
}

export default AlbumManager;
