import HeaderInner from '../../commoncomponents/HeaderInner';
import FooterMain from '../../commoncomponents/FooterMain';
import useDocumentTitle from '../../../globals/useDocumentTitle';
import TopTabbings from '../user-pages/TopTabbings';
import { useSelector } from 'react-redux';

function DeliveryInformation() {
  useDocumentTitle('Delivery Information | AlmaBay');
  const isloggedIn = useSelector((state) => state?.user?.isloggedIn);
  return (
    <>
      <HeaderInner />
      {isloggedIn ? (
        <div className="header-lnk-feeds">
          <div className="container">
            <TopTabbings />
          </div>
        </div>
      ) : (
        ''
      )}

      <section className="terms-condition py-5">
        <div className="container">
          <div className="terms-condition-inr">
            <div className="title-card mb-4">
              <h3 className="title-hd">Delivery Information</h3>
              <h4>Courier Charges, Timing and Tracking</h4>
            </div>

            <h4>Shipping Policy</h4>
            <p>
              ALMABAY try to provide you one of the best online memorabilia shopping experience that
              is why we give you the shortest time for delivery through First Flight courier
              service, which delivers in maximum 6-7 working days. Usually it takes less the
              mentioned time. For all the unreachable places via courier services, we deliver
              through Indian Post. We make sure that your package reaches you without any damages to
              the packaging outside and your ordered product inside.
            </p>
            <h4>Delivery Time</h4>
            <p>
              Orders from all over India are delivered within 6-7 working days, and International
              orders are delivered in 10 to 15 working days. Delivery time may vary depending upon
              the shipping address. Your tracking id will be sent on email id you provide at the
              time of order.
            </p>
            <h4>Shipping Charges</h4>
            <p>
              Within India, there is a flat shipping charge of INR 60. To know about the shipping
              charges outside India, contact +91-98729-67891.
            </p>
          </div>
        </div>
      </section>
      <FooterMain className={'colour-bg'} />
    </>
  );
}

export default DeliveryInformation;
