/**
@copyright  :   ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
@author     :   Shiv Charan Panjeta 
All Rights Reserved.
Proprietary and confidential :  All information contained here in is, and remains
the property of ToXSL Technologies Pvt. Ltd. and it's partners.
Unauthorized copying of this file, via any medium is strictly prohibited.
*/

import React, { useEffect, useState } from 'react';
import { Col, Container, Row, Table } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import { getCrashDetail } from '../../services/AuthServices';

const SingleReportView = () => {
  // get id from params using useParams hook
  const { id } = useParams();
  const [crashDetail, setCrashDetail] = useState(null);

  useEffect(() => {
    // using an IIFE to fetch crash detail from server
    (async () => {
      const response = await getCrashDetail(id);
      if (response?.status === 200) {
        // setting fetched data in local state
        // can use that state data further
        setCrashDetail(response.data?.data?.list);
      }
    })();
  }, []);
  return (
    <div className="dash-main-content">
      <Container fluid>
        <div className="dash-page-content">
          <div className="dash-page-title">
            <Row>
              <Col xs={12} sm={12} md={12}>
                <div className="dash-title-bar d-flex align-items-center justify-content-between">
                  <div className="dash-title-dis">
                    <h5>View Crash Details</h5>
                  </div>
                  <div className="title-rt-btn">
                    <Link className="green_button_admin" to="/admin/app">
                      Back To Listing
                    </Link>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <div className="dash-content-card">
            <div className="table-responsive">
              <Table className="custom-table-vertical" striped hover>
                <tbody>
                  <tr>
                    <th>ID</th>
                    <td>:</td>
                    <td>{crashDetail?.id}</td>
                  </tr>
                  <tr>
                    <th>User ID</th>
                    <td>:</td>
                    <td>{crashDetail?.user_id}</td>
                  </tr>
                  <tr>
                    <th>Device Info</th>
                    <td>:</td>
                    <td>{crashDetail?.device_info}</td>
                  </tr>
                  <tr>
                    <th>Crash Info</th>
                    <td>:</td>
                    <td>{crashDetail?.crash_info}</td>
                  </tr>
                  <tr>
                    <th>Added On</th>
                    <td>:</td>
                    <td>{crashDetail?.added_on}</td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default SingleReportView;
