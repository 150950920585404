/**
@copyright  :   ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
@author     :   Shiv Charan Panjeta 
 
All Rights Reserved.
Proprietary and confidential :  All information contained here in is, and remains
the property of ToXSL Technologies Pvt. Ltd. and it's partners.
Unauthorized copying of this file, via any medium is strictly prohibited.
*/

import moment from 'moment';
import { useEffect, useState } from 'react';
import { Button, Container, Modal } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Table from 'react-bootstrap/Table';
import ReactPaginate from 'react-paginate';
import Loader from '../../components/commoncomponents/loader/loader-large';
import useDocumentTitle from '../../globals/useDocumentTitle';
import * as ADMINSERVICES from '../../services/AuthServices';
export default function FAQ() {
  useDocumentTitle('Manage Faq | AlmaBay');
  useEffect(() => {
    apiCall();
  }, []);
  const [viewData, setViewData] = useState('');
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = (data) => {
    setShow(true);
    setViewData(data);
  };
  const [loading, setLoading] = useState(false);
  const [faqList, setFaqList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [record, setRecord] = useState('');
  const [detail, setDetail] = useState({
    faq_email: '',
    faq_id: '',
    question: ''
  });

  const apiCall = (page = '1') => {
    setCurrentPage(page);
    ADMINSERVICES.getFaqList(page).then((res) => {
      if (res?.status === 200) {
        setFaqList(res?.data?.data);
        setRecord(res?.data?.pager?.total);
      }
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setDetail({ ...detail, [name]: value.trim() });
  };

  const handlePageClick = (a) => {
    let currentPage = a?.selected + 1;
    apiCall(currentPage);
  };

  const handleSearch = (e) => {
    let body = {
      faq_id: detail.faq_id,
      faq_email: detail.faq_email,
      question: detail.question
    };
    ADMINSERVICES.addFaq(body, currentPage).then((res) => {
      if (res?.status === 200) {
        setFaqList(res?.data?.detail?.viewlist);
        setRecord(res?.data?.detail?.totalRecords);
      }
    });
  };

  return (
    <>
      <div className="dash-main-content">
        <Container fluid>
          <div className="dash-page-content">
            <div className="dash-page-title">
              <Row>
                <Col xs={12} sm={12} md={12}>
                  <div className="dash-title-bar d-flex align-items-center justify-content-between">
                    <div className="title-lft d-flex align-items-center">
                      <div className="dash-icn">
                        <i className="fas fa-question-circle"></i>
                      </div>
                      <div className="dash-title-dis">
                        <h5>
                          User FAQ <span>Listing</span>
                        </h5>
                        <h5>
                          Total Record(s): <span> {record ? record : ''} </span>
                        </h5>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="dash-content-card">
              <div className="table-responsive">
                <Table className="custom-table-data" striped bordered hover>
                  <thead>
                    <tr>
                      <th>Id</th>
                      <th>Email</th>
                      <th>Question</th>
                      <th>Date Created</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="search-table">
                      <td>
                        <Form.Control
                          type="text"
                          placeholder="ID"
                          onChange={handleChange}
                          name="faq_id"
                        />
                      </td>
                      <td>
                        <Form.Control
                          type="text"
                          placeholder="Email"
                          onChange={handleChange}
                          name="faq_email"
                        />
                      </td>
                      <td></td>
                      <td></td>
                      <td className="serac-btns text-center">
                        <button type="button" className="green_button_admin" onClick={handleSearch}>
                          Search
                        </button>
                      </td>
                    </tr>
                    {faqList && faqList?.length ? (
                      faqList?.map((data, index) => (
                        <tr key={index}>
                          <td> {data?.id} </td>
                          <td>{data?.email} </td>
                          <td>{data.question ? data.question : 'not available'} </td>
                          <td>{moment(data?.date).format('Do MMMM YYYY')}</td>

                          <td>
                            <span
                              role="presentation"
                              onClick={() => {
                                handleShow(data);
                              }}>
                              <i className="fas fa-search-plus text-primary  mx-5"></i>
                            </span>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr className="text-center">
                        <td colSpan={9}>Data Not Found</td>
                      </tr>
                    )}
                  </tbody>
                </Table>

                <div className="react-page d-flex">
                  {record / 10 > 1 ? (
                    <ReactPaginate
                      previousLabel={currentPage > 0 ? '<' : ''}
                      nextLabel=">"
                      onPageChange={handlePageClick}
                      pageCount={record / 10}
                      activeClassName={'page-link active'}
                    />
                  ) : (
                    ''
                  )}
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>View Faq Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="view-detail-content">
            <Row>
              <Col md={12}>
                <p>
                  <strong>Description : </strong>
                  {viewData?.email}
                </p>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <p>
                  <strong>Question : </strong>
                  {viewData?.question}
                </p>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <p>
                  <strong>Date created : </strong>
                  {moment(viewData?.date).format('Do MMMM YYYY')}
                </p>
              </Col>
            </Row>
          </div>
        </Modal.Body>
      </Modal>

      {loading ? <Loader /> : ''}
    </>
  );
}
