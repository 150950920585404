import { useEffect, useImperativeHandle, forwardRef, useState } from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Accordion from 'react-bootstrap/Accordion';
import Select from 'react-select';
import { Button } from 'react-bootstrap';
import AsyncSelect from 'react-select/async';
import Form from 'react-bootstrap/Form';
import * as adminServices from '../../services/AuthServices';
import { getYearDropDown } from '../../globals/helper';
import {
  getTechnicalSkillDrop,
  getBusinessSkillDrop,
  getInterpersonalSkillDrop
} from '../../services/UserServices';

// eslint-disable-next-line react/display-name
const FilterBy = forwardRef((props, ref) => {
  const { handleClose, show, type, globalFilter } = props;

  const [yearOptions, setYearOptions] = useState();

  const [userType] = useState([
    {
      label: 'Student',
      value: 'student'
    },
    {
      label: 'Alumni',
      value: 'alumni'
    }
  ]);

  const [campusType] = useState([
    {
      label: 'Constituent Campus',
      value: 'constituent_campus'
    },
    {
      label: 'Affiliated institution',
      value: 'affiliated_institution'
    }
  ]);

  const qualification = [
    {
      value: 'high school',
      label: 'High School'
    },
    {
      value: 'secondary school',
      label: 'Sr. Secondary School'
    },
    {
      value: 'bachelors degree',
      label: 'Bachelors Degree'
    },
    {
      value: 'masters degree',
      label: 'Masters Degree'
    },
    {
      value: 'doctorate',
      label: 'Doctorate/Phd'
    },
    {
      value: 'others',
      label: 'Others'
    }
  ];

  const [state, setState] = useState('');
  const [value, setValue] = useState('');
  // const [state, setState] = useState("")
  // const [state, setState] = useState("")
  // const [state, setState] = useState("")

  useEffect(() => {
    getYearDropDown(setYearOptions);
  }, []);

  const onChangeHandler = (e, option) => {
    setState({ ...state, [option.name]: e.value });
  };

  const multiSelectHandler = (e, option) => {
    setState({ ...state, [option.name]: e.map((data) => data?.value).join() });
  };

  const resetHandler = (e) => {
    setState('');
    setValue('');
    globalFilter();
  };

  useImperativeHandle(ref, () => ({
    resetHandler: resetHandler
  }));

  const searchHandler = () => {
    globalFilter(new URLSearchParams(state).toString());
  };

  const loadInstituteOptions = (inputValue) => {
    return adminServices.getInstituteDrop(inputValue).then((res) => {
      if (res?.status === 200) {
        let tempArr = [];
        if (res?.data?.length) {
          const data = res?.data;
          data?.map((data) => {
            tempArr.push({
              label: data?.institutename,
              value: data?.institute_id
            });
          });
        }
        return tempArr;
      }
    });
  };

  const loadDepartmentOptions = (inputValue) => {
    return adminServices.getInsDepartDrop(inputValue).then((res) => {
      if (res?.status === 200) {
        let tempArr = [];
        if (res?.data?.length) {
          const data = res?.data;
          data?.map((data) => {
            tempArr.push({
              label: data?.institutename,
              value: data?.institutename
            });
          });
        }
        return tempArr;
      }
    });
  };

  const loadSpecializationOpt = (inputValue = '') => {
    return adminServices.specializationDropDown(inputValue).then((res) => {
      if (res?.status === 200) {
        let list = res?.data;
        let arr = [];
        list?.map((data) => {
          arr.push({ label: data?.name, value: data?.name });
        });
        return arr;
      }
    });
  };

  const loadProgramOption = (inputValue = '') => {
    return adminServices.userEducationDropDown('', '', inputValue).then((res) => {
      if (res?.status === 200) {
        let list = res?.data;
        let arr = [];
        list?.slice(0, 100)?.map((data) => {
          arr.push({ label: data?.program_name, value: data?.program_name });
        });
        return arr;
      }
    });
  };

  const loadFieldOfStudyOpt = (inputValue = '') => {
    return adminServices.fieldOfStudyDropDown('', '', inputValue).then((res) => {
      if (res?.status === 200) {
        let list = res?.data?.data;
        let arr = [];
        list?.slice(0, 100)?.map((data) => {
          arr.push({ label: data?.name, value: data?.name });
        });
        return arr;
      }
    });
  };

  const loadCompanyOption = (inputValue) => {
    return adminServices.getCompanyDropDown(inputValue).then((res) => {
      if (res?.status === 200) {
        let tempArr = [];
        if (res?.data?.data?.length) {
          const data = res?.data?.data;
          data?.map((data) => {
            tempArr.push({
              label: data?.name,
              value: data?.id
            });
          });
        }
        return tempArr;
      }
    });
  };

  const getIndustryOption = (inputValue = '') => {
    return adminServices.getJobIndustryDropDown(inputValue).then((res) => {
      if (res?.status === 200) {
        let list = res?.data;
        let arr = [];
        list?.map((data) => {
          arr.push({ label: data?.name, value: data?.id });
        });
        return arr;
      }
    });
  };

  const loadCityOpt = (inputValue = '') => {
    return adminServices.getCompaniesCityDropDown(inputValue).then((res) => {
      if (res?.status === 200) {
        let list = res.data.data;
        let arr = [];
        list?.map((data) => {
          arr.push({ label: data?.city, value: data?.city_id });
        });
        return arr;
      }
    });
  };

  const loadTechSkillsOpt = (inputValue = '') => {
    return getTechnicalSkillDrop(inputValue).then((res) => {
      if (res?.status === 200) {
        let list = res?.data?.data;
        let arr = [];
        list?.map((data) => {
          arr.push({ label: data.name, value: data.id });
        });
        return arr;
      }
    });
  };

  const loadBussinessOpt = (inputValue = '') => {
    return getBusinessSkillDrop(inputValue).then((res) => {
      if (res?.status === 200) {
        let list = res?.data?.data;
        let arr = [];
        list?.map((data) => {
          arr.push({ label: data.name, value: data.id });
        });
        return arr;
      }
    });
  };

  const loadInterOpt = (inputValue = '') => {
    return getInterpersonalSkillDrop(inputValue).then((res) => {
      if (res?.status === 200) {
        let list = res?.data?.data;
        let arr = [];
        list?.map((data) => {
          arr.push({ label: data.name, value: data.id });
        });
        return arr;
      }
    });
  };

  /**
   * campus_type=&institute=19254&qualification=&department=&program=&field=&specialization=&passoutYear=&company=&category=&level=&designation=&mobile=&location=&skillset=&BusinessSkillset=&PersonalSkillset=&user_id=&name=&email=&reg_date=&to_reg_date=&status=
   */

  return (
    <Offcanvas show={show} onHide={handleClose} placement={'end'} className="user-filter-rt">
      <Offcanvas.Body>
        <div className="user-filter-outr">
          <div className="user-filter-hd d-flex align-items-center justify-content-between">
            <h4>Filter By</h4>
            <Button className="search-btn-filter" onClick={searchHandler}>
              Search
            </Button>
          </div>
          <div className="user-filter-list">
            <Accordion defaultActiveKey="0">
              {type === 'faculty' ? (
                <></>
              ) : (
                <Accordion.Item className="mb-2" eventKey="0">
                  <Accordion.Header>User Type</Accordion.Header>
                  <Accordion.Body>
                    <Select
                      className="basic-single"
                      classNamePrefix="select"
                      name="user-type"
                      options={userType}
                      value={value['user-type']}
                      placeholder="Select User Type"
                      onChange={(e, option) => {
                        setValue({ ...value, [option.name]: e });
                        onChangeHandler(e, option);
                      }}
                    />
                  </Accordion.Body>
                </Accordion.Item>
              )}
              <Accordion.Item className="mb-2" eventKey="2">
                <Accordion.Header>Campus Type</Accordion.Header>
                <Accordion.Body>
                  <Select
                    className="basic-single"
                    classNamePrefix="select"
                    isSearchable={true}
                    options={campusType}
                    name="campus_type"
                    value={value?.campus_type}
                    placeholder="Select Campus Type"
                    onChange={(e, option) => {
                      setValue({ ...value, [option.name]: e }), onChangeHandler(e, option);
                    }}
                  />
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item className="mb-2" eventKey="3">
                <Accordion.Header>Institute</Accordion.Header>
                <Accordion.Body>
                  <AsyncSelect
                    className="basic-single"
                    classNamePrefix="select"
                    isSearchable={true}
                    name="institute"
                    value={value?.institute}
                    loadOptions={loadInstituteOptions}
                    onChange={(e, option) => {
                      setValue({ ...value, [option.name]: e }), onChangeHandler(e, option);
                    }}
                    placeholder="Search By Institute Name"
                  />
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item className="mb-2" eventKey="4">
                <Accordion.Header>Qualification Level</Accordion.Header>
                <Accordion.Body>
                  <Select
                    className="basic-single"
                    classNamePrefix="select"
                    isSearchable={true}
                    name="qualification"
                    value={value?.qualification}
                    options={qualification}
                    placeholder="Select qualification type"
                    onChange={(e, option) => {
                      setValue({ ...value, [option.name]: e }), onChangeHandler(e, option);
                    }}
                  />
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item className="mb-2" eventKey="5">
                <Accordion.Header>Departments</Accordion.Header>
                <Accordion.Body>
                  <AsyncSelect
                    isMulti
                    className="basic-single"
                    classNamePrefix="select"
                    isSearchable={true}
                    name="department"
                    value={value?.department}
                    loadOptions={loadDepartmentOptions}
                    onChange={(e, option) => {
                      setValue({ ...value, [option.name]: e }), multiSelectHandler(e, option);
                    }}
                    placeholder="Search By Department Name"
                  />
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item className="mb-2" eventKey="6">
                <Accordion.Header>Programs</Accordion.Header>
                <Accordion.Body>
                  <AsyncSelect
                    isMulti
                    className="basic-single"
                    classNamePrefix="select"
                    isSearchable={true}
                    name="program"
                    value={value?.program}
                    loadOptions={loadProgramOption}
                    placeholder="Search By Program Name"
                    onChange={(e, option) => {
                      setValue({ ...value, [option.name]: e }), multiSelectHandler(e, option);
                    }}
                  />
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item className="mb-2" eventKey="7">
                <Accordion.Header>Field of Study</Accordion.Header>
                <Accordion.Body>
                  <AsyncSelect
                    isMulti
                    className="basic-single"
                    classNamePrefix="select"
                    isSearchable={true}
                    name="field"
                    value={value?.field}
                    loadOptions={loadFieldOfStudyOpt}
                    placeholder="Search By Field of Study Name"
                    onChange={(e, option) => {
                      setValue({ ...value, [option.name]: e }), multiSelectHandler(e, option);
                    }}
                  />
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item className="mb-2" eventKey="8">
                <Accordion.Header>Specialization</Accordion.Header>
                <Accordion.Body>
                  <AsyncSelect
                    isMulti
                    className="basic-single"
                    classNamePrefix="select"
                    isSearchable={true}
                    name="specialization"
                    value={value?.specialization}
                    loadOptions={loadSpecializationOpt}
                    placeholder="Search By Specialization Name"
                    onChange={(e, option) => {
                      setValue({ ...value, [option.name]: e }), multiSelectHandler(e, option);
                    }}
                  />
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item className="mb-2" eventKey="9">
                <Accordion.Header>Passout Batch</Accordion.Header>
                <Accordion.Body>
                  <Select
                    className="basic-single"
                    classNamePrefix="select"
                    isSearchable={true}
                    placeholder="Passout Batch"
                    value={value?.passoutYear}
                    name="passoutYear"
                    options={yearOptions}
                    onChange={(e, option) => {
                      setValue({ ...value, [option.name]: e }), multiSelectHandler(e, option);
                    }}
                  />
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item className="mb-2" eventKey="10">
                <Accordion.Header>Work Organization</Accordion.Header>
                <Accordion.Body>
                  <AsyncSelect
                    isMulti
                    className="basic-single"
                    classNamePrefix="select"
                    isSearchable={true}
                    name="company"
                    value={value?.company}
                    loadOptions={loadCompanyOption}
                    onChange={(e, option) => {
                      setValue({ ...value, [option.name]: e }), multiSelectHandler(e, option);
                    }}
                    placeholder="Search By Work Organization"
                  />
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item className="mb-2" eventKey="11">
                <Accordion.Header>Industry Name</Accordion.Header>
                <Accordion.Body>
                  <AsyncSelect
                    isMulti
                    className="basic-single"
                    classNamePrefix="select"
                    isSearchable={true}
                    name="category"
                    value={value?.category}
                    loadOptions={getIndustryOption}
                    onChange={(e, option) => {
                      setValue({ ...value, [option.name]: e }), multiSelectHandler(e, option);
                    }}
                    placeholder="Search By Industry Name"
                  />
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item className="mb-2" eventKey="12">
                <Accordion.Header>Mobile</Accordion.Header>
                <Accordion.Body>
                  {/* input */}
                  <Form.Control
                    type="text"
                    name="mobile"
                    placeholder="Search By Mobile Number"
                    onChange={(e) => {
                      setState({ ...state, ['mobile']: e.target.value });
                    }}
                    value={state?.mobile}
                  />
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item className="mb-2" eventKey="13">
                <Accordion.Header>Location</Accordion.Header>
                <Accordion.Body>
                  <AsyncSelect
                    isMulti
                    className="basic-single"
                    classNamePrefix="select"
                    isSearchable={true}
                    placeholder="Location"
                    name="location"
                    value={value?.location}
                    loadOptions={loadCityOpt}
                    onChange={(e, option) => {
                      setValue({ ...value, [option.name]: e }), multiSelectHandler(e, option);
                    }}
                  />
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item className="mb-2" eventKey="14">
                <Accordion.Header>Technical Skills</Accordion.Header>
                <Accordion.Body>
                  <AsyncSelect
                    isMulti
                    className="basic-single"
                    classNamePrefix="select"
                    isSearchable={true}
                    name="skillset"
                    value={value?.skillset}
                    loadOptions={loadTechSkillsOpt}
                    onChange={(e, option) => {
                      setValue({ ...value, [option.name]: e }), multiSelectHandler(e, option);
                    }}
                    placeholder="Search By Technical Skills"
                  />
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item className="mb-2" eventKey="15">
                <Accordion.Header>Business Skills</Accordion.Header>
                <Accordion.Body>
                  <AsyncSelect
                    isMulti
                    className="basic-single"
                    classNamePrefix="select"
                    isSearchable={true}
                    name="BusinessSkillset"
                    value={value?.BusinessSkillset}
                    loadOptions={loadBussinessOpt}
                    onChange={(e, option) => {
                      setValue({ ...value, [option.name]: e }), multiSelectHandler(e, option);
                    }}
                    placeholder="Search By Business Skills"
                  />
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item className="mb-2" eventKey="16">
                <Accordion.Header>Inter-Personal Skills</Accordion.Header>
                <Accordion.Body>
                  <AsyncSelect
                    isMulti
                    className="basic-single"
                    classNamePrefix="select"
                    name="PersonalSkillset"
                    isSearchable={true}
                    value={value?.PersonalSkillset}
                    loadOptions={loadInterOpt}
                    onChange={(e, option) => {
                      setValue({ ...value, [option.name]: e }), multiSelectHandler(e, option);
                    }}
                    placeholder="Search By Inter-Personal Skills"
                  />
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
          <div className="filter-search-btn">
            <Button className="search-btn" onClick={searchHandler}>
              Search
            </Button>
          </div>
        </div>
      </Offcanvas.Body>
    </Offcanvas>
  );
});

export default FilterBy;
