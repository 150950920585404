/**
* @copyright : ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
*  @author    : Shiv Charan Panjeta  

* All Rights Reserved.
* Proprietary and confidential :  All information contained here in is, and remains
* the property of ToXSL Technologies Pvt. Ltd. and it's partners.
* Unauthorized copying of this file, via any medium is strictly prohibited.
*/

import { Component } from 'react';

class HelperClass extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: ''
    };
    this.handleEvent = this.handleEvent.bind(this);
    this.checkDomain = this.checkDomain.bind(this);
  }

  handleEvent() {
    console.log(this.props);
  }

  /**
   * Here we are checking > is institute or not
   */

  checkDomain = () => {
    const URL = window.location.host;
    let sub_domain = URL.split('.')[0];

    if (
      sub_domain != 'localhost:3000' &&
      sub_domain != 'localhost:5555' &&
      sub_domain != 'localhost:5557' &&
      sub_domain != 'localhost:5558' &&
      sub_domain != 'localhost:81' &&
      sub_domain != 'almabay' &&
      sub_domain != 'almabay.co.in' &&
      sub_domain != 'almabay.co' &&
      sub_domain != 'localhost'
    ) {
      return true;
    } else {
      return false;
    }
  };
}

export default HelperClass;
