/** @copyright : ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
 @author     : Shiv Charan Panjeta 

 All Rights Reserved.
 Proprietary and confidential :  All information contained herein is, and remains
 the property of ToXSL Technologies Pvt. Ltd. and its partners.
 Unauthorized copying of this file, via any medium is strictly prohibited.
*/

import { Route, Routes } from 'react-router-dom';
import AllOrders from '../../components/webpages/store-page/AllOrders';
import OrderDetails from '../../components/webpages/store-page/OrderDetails';
import Shop from '../../components/webpages/store-page/Shop';
import ShopCatalog from '../../components/webpages/store-page/ShopCatalog';
import ShopDetails from '../../components/webpages/store-page/ShopDetails';
import ShopView from '../../components/webpages/store-page/ShopView';
import CheckoutPage from '../../components/webpages/user-pages/CheckoutPage';
import ShoppingCart from '../../components/webpages/user-pages/ShoppingCart';
import PageNotfound from '../../components/webpages/user-pages/PageNotfound';

function StoreRoutes() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Shop />} />
        <Route path="/details/:id" element={<ShopDetails />} />
        <Route path="/products/:shortcode" element={<ShopView />} />
        <Route path="/catalog" element={<ShopCatalog />} />
        <Route path="/cart" element={<ShoppingCart />} />
        <Route path="/checkoutShipping" element={<CheckoutPage />} />
        <Route path="/orders" element={<AllOrders />} />
        <Route path="/orderdetail/:id" element={<OrderDetails />} />
        <Route path="*" element={<PageNotfound />} />
      </Routes>
    </>
  );
}

export default StoreRoutes;
