/**
@copyright  :   ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
@author     :   Shiv Charan Panjeta 
 
All Rights Reserved.
Proprietary and confidential :  All information contained here in is, and remains
the property of ToXSL Technologies Pvt. Ltd. and it's partners.
Unauthorized copying of this file, via any medium is strictly prohibited.
*/

import { useEffect, useState } from 'react';
import { Alert, Button, Container } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Table from 'react-bootstrap/Table';
import ReactPaginate from 'react-paginate';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import Loader from '../../../components/commoncomponents/loader/loader-large';
import useDocumentTitle from '../../../globals/useDocumentTitle';
import {
  deleteImage,
  getShopProductList,
  globalActionChangeProduct,
  productSingleAction
} from '../../../services/AuthServices';
import showNotification from '../../../services/NotificationService';

export default function ListProduct() {
  useDocumentTitle('Manage Products | AlmaBay');
  const isAlert = useSelector((state) => state.alert);
  const [chekcedId, setCheckedId] = useState([]);
  const [allId, setAllId] = useState([]);
  const [massageView, setMassageView] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [orderBy, setOrderBy] = useState('DESC');
  const [shortOrderBy, setShortOrderBy] = useState('id');
  const [showalert1, setShowAlert1] = useState(false);
  const [list, setList] = useState([]);
  const [totalRecords, setTotalRecord] = useState('');
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [filterState, setFilterState] = useState({
    proId: '',
    name: '',
    code: '',
    feature_product: '',
    soldout: '',
    category_id: '',
    attributes: '',
    status: ''
  });
  const [showalert, setShowAlert] = useState(false);
  const [alert, setAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState('');

  useEffect(() => {
    const timeId = setTimeout(() => {
      setAlert(false);
      setAlertMsg('');
    }, 3000);
    return () => {
      clearTimeout(timeId);
    };
  }, [alert]);

  useEffect(() => {
    if (isAlert?.showAlertBox) {
      setAlertMsg(isAlert?.message);
      setAlert(true);
    }
  }, []);

  useEffect(() => {
    apiCall();
  }, []);

  useEffect(() => {
    const timeId = setTimeout(() => {
      // After 3 seconds set the show value to false
      setShowAlert(false);
      setShowAlert1(false);
      document.getElementById('global').value = '';
    }, 3000);

    return () => {
      clearTimeout(timeId);
    };
  }, [showalert, showalert1]);

  const apiCall = (page = 1, type, currentSort) => {
    setCurrentPage(page);
    setLoading(true);
    let body = {
      proId: filterState?.proId,
      name: filterState?.name,
      code: filterState?.code,
      feature_product: filterState?.feature_product,
      soldout: filterState?.soldout,
      category_id: filterState?.category_id,
      attributes: filterState?.attributes,
      status: filterState?.status,
      sortfield: type ? type : shortOrderBy,
      order: currentSort ? currentSort : orderBy,
      page: page
    };
    getShopProductList(body).then((res) => {
      setLoading(false);
      if (res?.status === 200) {
        setList(res?.data?.data?.viewlist);
        setTotalRecord(res?.data?.data?.total_records);
        setAllId(res?.data?.data?.viewlist?.map((data) => data?.id));
      }
    });
  };

  const resetApiCall = () => {
    setLoading(true);
    let body = {
      proId: '',
      name: '',
      code: '',
      feature_product: '',
      soldout: '',
      category_id: '',
      attributes: '',
      status: '',
      sortfield: shortOrderBy,
      order: orderBy,
      page: currentPage
    };
    getShopProductList(body).then((res) => {
      setLoading(false);

      if (res?.status === 200) {
        setList(res?.data?.data?.viewlist);
        setTotalRecord(res?.data?.data?.total_records);
        setAllId(res?.data?.data?.viewlist?.map((data) => data?.id));
      }
    });
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilterState({
      ...filterState,
      [name]: value
    });
  };

  const handleMultipleCheckBox = (id) => {
    if (chekcedId.includes(id)) {
      let filterId = chekcedId.filter((data) => data !== id);
      setCheckedId(filterId);
    } else {
      setCheckedId([...chekcedId, id]);
    }
  };

  const handleSelectAll = (e) => {
    if (e.target.checked === true) {
      setCheckedId(allId);
    } else {
      setCheckedId([]);
    }
  };

  const handlePageClick = (a) => {
    let page = a?.selected + 1;
    setCurrentPage(page);
    setCheckedId([]);
    apiCall(page, shortOrderBy, orderBy);
  };

  const sortHandler = (e, type) => {
    e.preventDefault();
    let currentSort = orderBy === 'ASC' ? 'DESC' : 'ASC';
    setOrderBy(currentSort);
    setShortOrderBy(type);
    apiCall('', type, currentSort);
  };
  const globalStateHandler = () => {
    setShowAlert(true);
  };
  const handlePerformAction = (e) => {
    if (chekcedId.length !== 0) {
      setShowAlert(false);
      let body = {
        ids: chekcedId,
        selectedAction: e.target.value
      };
      let formData = new FormData();
      for (let i in body) {
        formData.append(`${i}`, body[i]);
      }
      globalActionChangeProduct(formData).then((res) => {
        if (res?.status === 200) {
          document.getElementById('global').value = '';
          setMassageView(res?.data?.message);
          setShowAlert1(true);
          apiCall();
          setCheckedId([]);
        }
      });
    } else {
      globalStateHandler();
    }
  };

  const selectHandler = (id, value) => {
    switch (value) {
      case 'edit':
        navigate(`/admin/products/create`, { state: id });
        break;
      case 'showImages':
        navigate('/admin/shops/product/showImages', { state: id });
        break;
      case 'disable':
        handleAction(id, value);
        break;
      case 'enable':
        handleAction(id, value);
        break;
      case 'delImg':
        handleImage(id, value);
        break;
      case 'del':
        handleAction(id, value);
        break;
      default:
        break;
    }
  };

  const handleImage = (id, option) => {
    deleteImage(id, option).then((resp) => {
      if (resp?.status == 200) {
        apiCall();
        showNotification('success', resp?.data?.message);

        document.getElementById(`select${id}`).value == '';
      }
    });
  };

  const handleAction = (id, option) => {
    productSingleAction(id, option).then((resp) => {
      if (resp?.status == 200) {
        document.getElementById(`select${id}`).value = '';
        setAlert(true);
        setAlertMsg(resp?.data?.message);
        apiCall();
      }
    });
  };

  const handleResetFilter = () => {
    setFilterState({
      proId: '',
      name: '',
      code: '',
      feature_product: '',
      soldout: '',
      category_id: '',
      attributes: '',
      status: '',
      sortfield: '',
      order: ''
    });
    resetApiCall();
    setCurrentPage(1);
  };

  return (
    <>
      <div className="dash-main-content">
        <Container fluid>
          <div className="dash-page-content">
            <div className="dash-page-title">
              <Row>
                <Col xs={12} sm={12} md={12}>
                  <div className="dash-title-bar d-flex align-items-center justify-content-between">
                    <div className="title-lft d-flex align-items-center">
                      {/* <div className="dash-icn">
                        <i className="fa fa-users"></i>
                      </div> */}
                      <div className="dash-title-dis">
                        <h5>
                          Products <span>Listing</span>
                        </h5>
                        <h5>
                          Total Record(s): <span>{totalRecords}</span>
                        </h5>
                      </div>
                    </div>
                    <div className="title-rt-btn d-flex align-items-center">
                      <Link className="green_button_admin mx-2" to="/admin/products/create">
                        Add New Product
                      </Link>
                      <div className="head-instite-sec">
                        <Form.Select
                          className=""
                          id="global"
                          aria-label="Default select example "
                          onChange={handlePerformAction}>
                          <option value="">Choose Action</option>
                          <option value="enable">Enable</option>
                          <option value="disable">Disable</option>
                          <option value="del">Delete</option>
                        </Form.Select>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="dash-content-card">
              <Alert variant={'success'} className={`${alert ? '' : 'd-none'}`}>
                {alertMsg}
              </Alert>

              <Alert variant={'danger'} className={`${showalert ? '' : 'd-none'}`}>
                Please select any record
              </Alert>
              <Alert variant={'success'} className={`${showalert1 ? '' : 'd-none'}`}>
                {massageView}
              </Alert>
              <div className="table-responsive">
                <Table className="custom-table-data" striped bordered hover>
                  <thead>
                    <tr>
                      <th>
                        <Form.Check
                          type="checkbox"
                          id="checkbox"
                          label=""
                          checked={allId?.length !== 0 && allId?.length === chekcedId?.length}
                          onChange={handleSelectAll}
                        />
                      </th>
                      <th>
                        <Link
                          to=""
                          onClick={(e) => {
                            sortHandler(e, 'id');
                          }}>
                          Id
                          <img src={require('../../../assets/images/sort.png')} alt="sort" />
                        </Link>
                      </th>
                      <th>
                        <Link
                          to=""
                          onClick={(e) => {
                            sortHandler(e, 'name');
                          }}>
                          Name
                          <img src={require('../../../assets/images/sort.png')} alt="sort" />
                        </Link>
                      </th>
                      <th>
                        <Link
                          to=""
                          onClick={(e) => {
                            sortHandler(e, 'code');
                          }}>
                          Code
                          <img src={require('../../../assets/images/sort.png')} alt="sort" />
                        </Link>
                      </th>
                      <th>
                        <Link
                          to=""
                          onClick={(e) => {
                            sortHandler(e, 'soldout');
                          }}>
                          Sold Out
                          <img src={require('../../../assets/images/sort.png')} alt="sort" />
                        </Link>
                      </th>
                      <th>Feature Product</th>
                      <th>Cover Image</th>
                      <th>Alt Image</th>
                      <th>Attributes</th>
                      <th>Status</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="search-table">
                      <td></td>
                      <td>
                        <Form.Control
                          type="text"
                          placeholder="Id"
                          name="proId"
                          onChange={handleFilterChange}
                          value={filterState?.proId}
                        />
                      </td>
                      <td>
                        <Form.Control
                          type="text"
                          placeholder="Name"
                          name="name"
                          onChange={handleFilterChange}
                          value={filterState?.name}
                        />
                      </td>
                      <td>
                        <Form.Control
                          type="text"
                          placeholder="Code"
                          name="code"
                          onChange={handleFilterChange}
                          value={filterState?.code}
                        />
                      </td>
                      <td>
                        <Form.Select
                          aria-label="Default select example"
                          name="soldout"
                          onChange={handleFilterChange}
                          value={filterState?.soldout}>
                          <option value="">- Select -</option>
                          <option value="Yes">Yes</option>
                          <option value="No">No</option>
                        </Form.Select>
                      </td>
                      <td>
                        <Form.Select
                          aria-label="Default select example"
                          name="feature_product"
                          onChange={handleFilterChange}
                          value={filterState?.feature_product}>
                          <option value="">- Select Out -</option>
                          <option value="1">Yes</option>
                          <option value="0">No</option>
                        </Form.Select>
                      </td>

                      <td></td>
                      <td></td>
                      <td>
                        <Form.Select
                          aria-label="Default select example"
                          name="attributes"
                          onChange={handleFilterChange}
                          value={filterState?.attributes}>
                          <option value="">- Select -</option>
                          <option value="Yes">Yes</option>
                          <option value="No">No</option>
                        </Form.Select>
                      </td>
                      <td>
                        <Form.Select
                          aria-label="Default select example"
                          name="status"
                          onChange={handleFilterChange}
                          value={filterState?.status}>
                          <option value="">- Select Status -</option>
                          <option value="1">Enable</option>
                          <option value="0">Disable</option>
                        </Form.Select>
                      </td>
                      <td className="serac-btns text-center">
                        <button
                          className="green_button_admin m-1"
                          type="button"
                          onClick={() => apiCall(1, '')}>
                          Search
                        </button>
                        <Button className="main-btn-red" type="button" onClick={handleResetFilter}>
                          Reset
                        </Button>
                      </td>
                    </tr>
                    {list?.length !== 0 ? (
                      <>
                        {list?.map((item, index) => {
                          return (
                            <tr key={index}>
                              <td>
                                <Form.Check
                                  type="checkbox"
                                  id={`id${item?.id}`}
                                  checked={chekcedId?.includes(item?.id)}
                                  onChange={() => handleMultipleCheckBox(item?.id)}
                                  label=""
                                />
                              </td>
                              <td>{item?.id}</td>
                              <td>{item?.name}</td>
                              <td>{item?.code}</td>
                              <td>{item?.soldout}</td>
                              <td>{item?.feature_product == 1 ? 'Yes' : 'No'}</td>
                              <td>
                                <div className="td-inner-img">
                                  {item?.cover_image ? (
                                    <img src={item?.cover_image} alt="img" width="75" height="75" />
                                  ) : (
                                    ''
                                  )}
                                </div>
                              </td>
                              <td>0</td>
                              <td>{item?.attributes}</td>
                              <td>{item?.status == 1 ? 'Enable' : 'Disable'}</td>
                              <td>
                                <Form.Select
                                  aria-label="Default select example"
                                  id={`select${item?.id}`}
                                  onChange={(e) => selectHandler(item?.id, e.target.value)}>
                                  <option value="">Action</option>
                                  <option value={item?.status == 1 ? 'disable' : 'enable'}>
                                    {item?.status == 1 ? 'Disable' : 'Enable'}
                                  </option>{' '}
                                  <option value="edit">Edit</option>
                                  <option value="showImages">View Images</option>
                                  <option value="delImg">Delete Image Only</option>
                                  <option value="del">Delete</option>
                                </Form.Select>
                              </td>
                            </tr>
                          );
                        })}
                      </>
                    ) : (
                      <tr className="text-center">
                        <td colSpan={11}>No product found</td>
                      </tr>
                    )}
                  </tbody>
                </Table>
                <div className="react-page d-flex">
                  {totalRecords > 10 ? (
                    <ReactPaginate
                      previousLabel={currentPage > 0 ? '<' : ''}
                      nextLabel=">"
                      onPageChange={handlePageClick}
                      activeClassName={'page-link active'}
                      pageCount={Math.ceil(totalRecords / 10)}
                      forcePage={currentPage - 1}
                    />
                  ) : (
                    ''
                  )}
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
      {loading ? <Loader /> : ''}
    </>
  );
}
