import { useEffect } from 'react';
import { Button, Form } from 'react-bootstrap';

import Loader from '../../commoncomponents/loader/loader-large';
import { useSelector } from 'react-redux';
export default function PaymentStore({
  setPaymentMode,
  loading,
  totalPrice,
  cartProductsReview,
  saveOrderFunction
}) {
  const isloggedIn = useSelector((state) => state?.user?.isloggedIn);
  useEffect(() => {
    if (isloggedIn) {
      cartProductsReview();
    }
  }, [isloggedIn]);

  return (
    <div className="make-payment-card">
      <h4>Make Payment</h4>
      <p>You Pay : {totalPrice ? `Rs. ${totalPrice} /-` : ''}</p>
      <div className="paymnt-hd">
        <h4>Payment Method</h4>
        <Form>
          {['radio'].map((type) => (
            <div key={`inline-${type}`} className="mb-0 p-3">
              <Form.Check
                label="COD(Cash On Delivery)"
                name="group1"
                type={type}
                id={`inline-${`1`}-1`}
                className="mb-3"
                onChange={() => setPaymentMode('1')}
                checked
              />
              <Form.Check
                label="Credit Card/ Debit Card/ Net Banking"
                name="group1"
                type={type}
                id={`inline-${`2`}-2`}
                className="mb-3"
                onChange={() => setPaymentMode('2')}
              />
            </div>
          ))}
        </Form>
        {/* <ReCAPTCHA sitekey='https://www.google.com/recaptcha/api/siteverify?secret=6LeMjgYTAAAAAG-fuNKvR1N4EgUn4wJx03NN6d_l' onChange={onChange} />, */}
      </div>
      <div className="text-start mt-3">
        <Button variant="primary" className="save-btn" onClick={(e) => saveOrderFunction(e)}>
          Place Order
        </Button>
      </div>

      {loading ? <Loader /> : ''}
    </div>
  );
}
