import { useState } from 'react';
import { Link } from 'react-router-dom';
import { FacebookShareButton, LinkedinShareButton, TwitterShareButton } from 'react-share';

const SocialShare = ({
  facebookOptions,
  twitterOptions,
  linkedInOptions,
  wrapperClassNames,
  eventmodule
}) => {
  const [facebookStyle, setFacebookStyle] = useState(true);
  const [twitterStyle, setTwitterStyle] = useState(true);
  const [linkedIn, setLinkedInStyle] = useState(true);
  return (
    <div className={`social-round ${wrapperClassNames}`}>
      <ul>
        <li
          className={eventmodule && facebookStyle ? 'facebook-icon-events' : ''}
          onMouseOver={() => setFacebookStyle(false)}
          onMouseLeave={() => {
            setFacebookStyle(true);
          }}
        >
          <Link className="facebook" target="_blank">
            <FacebookShareButton
              // url={`https://www.almabay.com/jobs/details/16268`}
              url={facebookOptions.url}
              target="_blank"
              quote={facebookOptions.quote}
            >
              <i className="fab fa-facebook-f"></i>
            </FacebookShareButton>
          </Link>
        </li>
        <li
          className={eventmodule && twitterStyle ? 'facebook-icon-events' : ''}
          onMouseOver={() => setTwitterStyle(false)}
          onMouseLeave={() => {
            setTwitterStyle(true);
          }}
        >
          <Link className="twitter">
            <TwitterShareButton
              className="twitter"
              url={twitterOptions.url}
              title={twitterOptions?.title}
              hashtags={twitterOptions?.hashtags}
            >
              <i className="fab fa-twitter"></i>
            </TwitterShareButton>
          </Link>
        </li>
        {/* <li>
          <Link className="google" to="#">
              <a
                href="https://plus.google.com/share?url=//www.w3docs.com/learn-javascript/google-share.html"
                onClick="javascript:window.open(this.href, '', 'menubar=no,toolbar=no,height=600,width=600');return false;">
                <img src="https://www.gstatic.com/images/icons/gplus-64.png" alt="Share on Google+" />
              </a>
            </Link>
        </li> */}
        <li
          className={eventmodule && linkedIn ? 'facebook-icon-events' : ''}
          onMouseOver={() => setLinkedInStyle(false)}
          onMouseLeave={() => {
            setLinkedInStyle(true);
          }}
        >
          <Link className="linkdin">
            <LinkedinShareButton
              className="linkdin"
              title={linkedInOptions.title}
              summary={linkedInOptions?.summary}
              url={linkedInOptions?.url}
              source={linkedInOptions?.source}
            >
              <i className="fab fa-linkedin-in"></i>
            </LinkedinShareButton>
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default SocialShare;
