import moment from 'moment';
import { useEffect, useState } from 'react';
import { Button, Col, Container, Dropdown, Row } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { truncate } from '../../../globals/helper';
import useDocumentTitle from '../../../globals/useDocumentTitle';
import showNotification from '../../../services/NotificationService';
import * as APISERVICES from '../../../services/SocialServices';
import { MiniFooter } from '../../MiniFooter';
import HeaderLogin from '../../commoncomponents/HeaderLogin';
import NoDataFound from '../../commoncomponents/NoDataFound';
import ArticalBlogs from '../../commoncomponents/newsfeeds/ArticalBlogs';
import CampusnewsText from '../../commoncomponents/newsfeeds/CampusnewsText';
import FollowCompany from '../../commoncomponents/newsfeeds/FollowCompany';
import GroupDiscover from '../../commoncomponents/newsfeeds/GroupDiscover';
import NewsSidebar from '../../commoncomponents/newsfeeds/NewsSidebar';
import PeopleKnow from '../../commoncomponents/newsfeeds/PeopleKnow';
import Upcoming from '../../commoncomponents/newsfeeds/Upcoming';
import WhatsNew from '../../commoncomponents/newsfeeds/WhatsNew';
import TopTabbings from './TopTabbings';
import useInfiniteScroller from './useInfiniteScroller';

function CampusNews() {
  useDocumentTitle('Campus News | AlmaBay');
  const navigate = useNavigate();
  const [news, setNews] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageNum, setPageNum] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [hasMore, setHasMore] = useState(true);

  useEffect(() => {
    if (pageNum <= totalPages) {
      getCampusNews();
    }
  }, [pageNum]);

  const getCampusNews = (isFalse) => {
    // if (hasMore) {
    setLoading(true);
    APISERVICES.getAllCampusNews(pageNum).then((res) => {
      if (res?.status === 200) {
        setLoading(false);
        if (isFalse) {
          let arra1 = news.map((item) => {
            const item2 = res.data.message?.find((i2) => i2.id == item.id);
            return item ? { ...item, ...item2 } : item2;
          });
          setNews(arra1);
        } else {
          setNews([...news, ...res.data.message]);
        }

        setTotalPages(res?.data?.pager.pageCount);
        setHasMore(res?.data?.pager.hasMore);
      } else {
        setLoading(false);
      }
    });
    // }
  };

  const HelpFullHandler = (e, data) => {
    e?.preventDefault();
    let body = {
      news_id: data?.id
    };
    APISERVICES.markCampusHelpFull(body).then((res) => {
      if (res?.status === 200) {
        showNotification('success', res?.data?.message);
        getCampusNews(true);
      }
    });
  };
  const [lastElement, setLastElement] = useState(null);
  const [shouldLoadMoreButtonVisible, setShouldLoadMoreButtonVisible] = useState(false);

  const onIntersecting = () => {
    setShouldLoadMoreButtonVisible(true);
  };
  const handleLoadMore = () => {
    setPageNum((no) => no + 1);
  };
  useInfiniteScroller(lastElement, onIntersecting);

  return (
    <>
      <HeaderLogin />
      <div className="header-lnk-feeds">
        <div className="container">
          <TopTabbings state={'social'} />
        </div>
      </div>
      <section className="news-feed-sec bg-grey py-4">
        <Container>
          <Row>
            <Col xs={12} md={2}>
              <NewsSidebar state="1" current="campus news" />
            </Col>
            <Col xs={12} md={7}>
              <div className="articals_div">
                {loading &&
                  Array.from(Array(3), (index) => {
                    return <Shimmer key={index} />;
                  })}
                {news?.length !== 0 && !loading ? (
                  <>
                    {news?.map((data, index) => {
                      return (
                        <div className="single_artical_card p-3" key={index} ref={setLastElement}>
                          <div className="d-flex align-items-center mb-3">
                            <div className="media-body">
                              <h4>
                                <Link to={`/user/campus-detail/${data?.id}`}>{data?.title}</Link>
                              </h4>
                              <ul className="news-inr-lst">
                                <li>
                                  <i className="fas fa-university me-1"></i>
                                  {data?.institute_name}
                                </li>
                                <li title={data?.date_created}>
                                  <i className="far fa-clock me-1"></i>Added{' '}
                                  <span>{moment(data?.date_created).fromNow()}</span>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div>
                            <p
                              dangerouslySetInnerHTML={{
                                __html: truncate(data?.description, 100)
                              }}></p>
                          </div>
                          <div>
                            <Link className="read-more" to={`/user/campus-detail/${data?.id}`}>
                              Read more...
                            </Link>
                          </div>
                          <div className="navigation-border">
                            <ul className="article-cmmnt-lnks d-flex ps-2">
                              <li className="active-helpful pe-3">
                                {data?.is_self_liked == true ? (
                                  <>
                                    <i className="fa fa-heart"></i> Helpful
                                    <span>({data?.total_likes_count})</span>
                                  </>
                                ) : (
                                  <Link
                                    to="#"
                                    onClick={(e) => {
                                      {
                                        HelpFullHandler(e, data);
                                      }
                                    }}>
                                    <i className="fa fa-heart "></i> Helpful
                                    <span>
                                      ({data?.total_likes_count == 0 ? 0 : data?.total_likes_count})
                                    </span>
                                  </Link>
                                )}
                              </li>
                              <li className="pe-3">
                                <Link to={'/user/campus-detail/' + data?.id}>
                                  <i className="fa fa-comment"></i> Comments
                                  <span> ({data?.total_comment_count})</span>
                                </Link>
                              </li>
                              <li className="share-drop-btn" key={index}>
                                <Dropdown>
                                  <Dropdown.Toggle className="pe-3" id="dropdown-basic">
                                    <i className="fa fa-share"></i> Share
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu className="p-2">
                                    <Dropdown.Item
                                      as={Link}
                                      target="_blank"
                                      to={`https://www.facebook.com/sharer/sharer.php?u=${process.env.REACT_APP_DOMAIN +
                                        'user/campus-detail' +
                                        '/' +
                                        data?.id
                                        }`}>
                                      Facebook
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      as={Link}
                                      target="_blank"
                                      to={`https://twitter.com/intent/tweet?url=${process.env.REACT_APP_DOMAIN +
                                        'user/campus-detail' +
                                        '/' +
                                        data?.id
                                        }`}>
                                      Twitter
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      as={Link}
                                      target="_blank"
                                      to={`https://plus.google.com/share?url=${process.env.REACT_APP_DOMAIN +
                                        'user/campus-detail' +
                                        '/' +
                                        data?.id
                                        }`}>
                                      Google
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      as={Link}
                                      target="_blank"
                                      to={`https://www.linkedin.com/shareArticle?url=${process.env.REACT_APP_DOMAIN +
                                        'user/campus-detail' +
                                        '/' +
                                        data?.id
                                        }&mini=true&title=${data?.title}&source=AlmaBay`}>
                                      Linkedin
                                    </Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </li>
                            </ul>
                          </div>
                        </div>
                      );
                    })}

                    {shouldLoadMoreButtonVisible && (
                      <div className="load-more-btn  text-center">
                        <Button
                          // to="#"
                          onClick={() => {
                            handleLoadMore();
                          }}
                          className="read-more">
                          View more
                        </Button>
                      </div>
                    )}
                  </>
                ) : (
                  <>{!loading && Array.isArray(news) && <NoDataFound text="No Campus News Avaliable at the moment." />}</>
                )}
              </div>
            </Col>
            <Col md={3}>
              <div>
                <PeopleKnow />
              </div>
              <div>
                <GroupDiscover />
              </div>
              <div>
                <FollowCompany />
              </div>

              <div>
                <ArticalBlogs />
              </div>
              <CampusnewsText />
              <div>
                <WhatsNew />
              </div>
              <div>
                <Upcoming />
              </div>
              <MiniFooter />
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}

export default CampusNews;

const Shimmer = () => {
  return (
    <div className="articals_div loading-skeleton">
      <div className="single_artical_card p-3">
        <div className="d-flex align-items-center mb-3">
          <div className="media-body">
            <h4>
              <a href="/jobs/jobs-details/7329"></a>
            </h4>
            <ul className="news-inr-lst">
              <li>
                <i className="fas fa-university me-1"></i>
              </li>
              <li>
                <i className="far fa-clock me-1"></i>
              </li>
            </ul>
          </div>
        </div>
        <div>
          <p></p>
        </div>
        <div>
          <Link className="read-more" to={`#`}></Link>
        </div>
        <div className="navigation-border">
          <ul className="article-cmmnt-lnks d-flex ps-2">
            <li className="active-helpful pe-3">
              <>
                <i className="fa fa-heart"></i>
              </>
            </li>
            <li className=" active-helpful pe-3">
              <i className=" active-helpfulfa fa-comment"></i>
              {/* <span> ({data?.total_comment_count})</span> */}
            </li>
            <li className="share-drop-btn">
              <Dropdown>
                <Dropdown.Toggle className="pe-3" id="dropdown-basic">
                  <li className=" active-helpful pe-3">
                    <i className="fa fa-share"></i>
                  </li>
                </Dropdown.Toggle>
              </Dropdown>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};
