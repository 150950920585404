import { useState, useEffect } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import HeaderLogin from '../../commoncomponents/HeaderLogin';
import TopTabbings from './TopTabbings';
import { useNavigate, useParams } from 'react-router-dom';
import NewsSidebar from '../../commoncomponents/newsfeeds/NewsSidebar';
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';
import WhatsNew from '../../commoncomponents/newsfeeds/WhatsNew';
import Upcoming from '../../commoncomponents/newsfeeds/Upcoming';
import PeopleKnow from '../../commoncomponents/newsfeeds/PeopleKnow';
import FollowCompany from '../../commoncomponents/newsfeeds/FollowCompany';
import WorkAnniversary from '../../commoncomponents/newsfeeds/WorkAnniversary';
import CampusNews from '../../commoncomponents/newsfeeds/CampusNews';
import CampusnewsText from '../../commoncomponents/newsfeeds/CampusnewsText';
import ArticalBlogs from '../../commoncomponents/newsfeeds/ArticalBlogs';
import GroupDiscover from '../../commoncomponents/newsfeeds/GroupDiscover';
import useDocumentTitle from '../../../globals/useDocumentTitle';
import { MiniFooter } from '../../MiniFooter';
import { bookEventTickets, getEventSummaryDetails } from '../../../services/SocialServices';
import { ZERO } from '../../../globals/constants';
import NoDataFound from '../../commoncomponents/NoDataFound';
import { restrictAlpha } from '../../../globals/helper';
import showNotification from '../../../services/NotificationService';
import Loader from '../../commoncomponents/loader/loader-large';

function EventTicketSummary() {
  useDocumentTitle('AlmaBay');
  let navigate = useNavigate();
  const [orderSummary, setOrderSummary] = useState([]);
  const [totalAmount, setTotalAmount] = useState();
  const [ticketBuyerDetails, setTicketBuyerDetails] = useState();
  const [eventDetail, setEventDetails] = useState();
  const [loading, setLoading] = useState(false);
  const [clicked, setClicked] = useState(false);

  const { orderId } = useParams();
  useEffect(() => {
    eventSummaryFunc(orderId);
  }, []);
  const eventSummaryFunc = () => {
    setLoading(true);
    getEventSummaryDetails(orderId).then((res) => {
      if (res?.status === 200) {
        setLoading(false);

        setEventDetails(res?.data?.detail?.event_detail[0]);
        setOrderSummary(res?.data?.detail?.order_summary);
        setTotalAmount(res?.data?.detail?.total_amount);
        const data = res?.data?.detail?.sk?.user;

        setTicketBuyerDetails({
          encrypted_order_id: orderId,
          first_name: data?.first_name,
          last_name: data?.last_name,
          email_address: data?.email,
          mobile: data?.mobile,
          placeOrder: ''
        });
      } else {
        showNotification('err', res?.detail);
        setLoading(false);
      }
    });
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    if (e.target.name == 'mobile') {
      if (e.target.value.length > 10) {
        return false;
      } else {
        setTicketBuyerDetails({ ...ticketBuyerDetails, [name]: value });
      }
    }
    setTicketBuyerDetails({ ...ticketBuyerDetails, [name]: value });
  };

  const validate = () => {
    let errors = {};
    if (ticketBuyerDetails?.first_name === '') {
      errors.first_name = 'First name is required.';
    }
    if (ticketBuyerDetails?.last_name === '') {
      errors.last_name = 'Last name is required.';
    }
    if (ticketBuyerDetails?.email_address === '') {
      errors.email_address = 'Email is required.';
    }
    if (ticketBuyerDetails?.mobile === '') {
      errors.mobile = 'Mobile number is required.';
    }
    return errors;
  };

  const completeFunc = (e) => {
    setClicked(true);
    e.preventDefault();
    if (!Object.keys(validate()).length) {
      setLoading(true);
      setClicked(false);
      bookEventTickets(ticketBuyerDetails).then((res) => {
        if (res?.status === 200) {
          setLoading(false);
          navigate(`/user/completed`);
        } else {
          showNotification('err', res?.data?.detail);
          setLoading(false);
        }
      });
    }
  };

  return (
    <>
      <HeaderLogin />
      <div className="header-lnk-feeds">
        <div className="container">
          <TopTabbings state={'social'} />
        </div>
      </div>
      <section className="news-feed-sec bg-grey py-4">
        <Container>
          <Row>
            <Col xs={12} lg={2}>
              <NewsSidebar state="1" current="job-fair-hall-tickets" />
            </Col>
            <Col xs={12} lg={9} xl={7} className="mb-4">
              <div className="event-ticket-summary d-flex align-items-top p-4 bg-white mb-3">
                <div className="event-ticket-img">
                  <img src={require('../../../assets/images/ticket-event.png')} alt="JobFair" />
                </div>
                <div className="event-ticket-dis">
                  <h2>{eventDetail?.name} </h2>
                  <ul className="public-event">
                    <li>Public Event</li>
                    <li>Confrerence</li>
                    <li>Ludhiana</li>
                  </ul>
                  <div className="event-date-dis">
                    <p className="mb-3">
                      Date <span> Sun, 16 Jul 17 09:00 AM-Mon, 17 Jul 23 05:00 PM </span>
                    </p>
                    <p className="mb-3">
                      Address <span> {eventDetail?.city_name} </span>
                    </p>
                  </div>
                </div>
              </div>
              <div className="order-ticket-summary  bg-white mb-3">
                <h4 className="p-3 border-bottom">Order Summary</h4>
                <div className="order-summary table-responsive">
                  <Table striped>
                    <thead>
                      <tr>
                        <th>Ticket Name</th>
                        <th>Type</th>
                        <th>Price</th>
                        <th>Quantity</th>
                        <th>Sub Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      {orderSummary?.length !== ZERO ? (
                        orderSummary?.map((data, index) => {
                          return (
                            <tr key={index}>
                              <td>{data?.ticket_name}</td>
                              <td>{data?.ticket_type}</td>
                              <td>
                                <i className="fas fa-rupee-sign me-1"></i>
                                {data?.ticket_price}
                              </td>
                              <td>{data?.quantity}</td>
                              <td>
                                <i className="fas fa-rupee-sign me-1"></i>
                                {data?.total_price}
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <NoDataFound />
                      )}
                    </tbody>
                  </Table>
                </div>
                <div className="order-total d-flex align-item-center justify-content-end px-3 py-2">
                  <h5>
                    Order total:{' '}
                    <span>
                      <i className="fas fa-rupee-sign me-1"></i> {totalAmount}
                    </span>
                  </h5>
                </div>
              </div>
              <div className="order-ticket-summary bg-white mb-3">
                <h4 className="mb-3 border-bottom p-3">Ticket Buyer</h4>
                <div className="ticket-buyer-detail p-3">
                  <Form>
                    <Form.Group as={Row} className="mb-4" controlId="formPlaintextEmail">
                      <Form.Label column sm="3">
                        First Name <span className="text-danger">*</span>
                      </Form.Label>
                      <Col sm="9">
                        <Form.Control
                          type="text"
                          value={ticketBuyerDetails?.first_name}
                          name="first_name"
                          onChange={handleChange}
                        />
                        <div
                          className={`${
                            clicked && validate().first_name ? '' : 'd-none'
                          } danger-frm `}
                        >
                          {clicked && validate().first_name}
                        </div>
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-4" controlId="formPlaintextEmail">
                      <Form.Label column sm="3">
                        Last Name <span className="text-danger">*</span>
                      </Form.Label>
                      <Col sm="9">
                        <Form.Control
                          type="text"
                          value={ticketBuyerDetails?.last_name}
                          name="last_name"
                          onChange={handleChange}
                        />
                        <div
                          className={`${
                            clicked && validate().last_name ? '' : 'd-none'
                          } danger-frm `}
                        >
                          {clicked && validate().last_name}{' '}
                        </div>
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-4" controlId="formPlaintextEmail">
                      <Form.Label column sm="3">
                        Email Address <span className="text-danger">*</span>
                      </Form.Label>
                      <Col sm="9">
                        <Form.Control
                          type="text"
                          value={ticketBuyerDetails?.email_address}
                          name="email_address"
                          onChange={handleChange}
                        />
                        <div
                          className={`${
                            clicked && validate().email_address ? '' : 'd-none'
                          } danger-frm `}
                        >
                          {clicked && validate().email_address}{' '}
                        </div>
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-4" controlId="formPlaintextEmail">
                      <Form.Label column sm="3">
                        Mobile <span className="text-danger">*</span>
                      </Form.Label>
                      <Col sm="9">
                        <Form.Control
                          type="text"
                          onKeyPress={(e) => restrictAlpha(e)}
                          value={ticketBuyerDetails?.mobile ? ticketBuyerDetails?.mobile : ''}
                          name="mobile"
                          onChange={handleChange}
                        />
                        <div
                          className={`${clicked && validate().mobile ? '' : 'd-none'} danger-frm `}
                        >
                          {clicked && validate().mobile}{' '}
                        </div>
                      </Col>
                    </Form.Group>
                  </Form>
                </div>
              </div>
              <div className="order-ticket-summary  bg-white mb-5">
                <h4 className="p-3 border-bottom">Make Payment</h4>

                <h5 className="p-3">
                  You Pay :{' '}
                  <span>
                    <i className="fas fa-rupee-sign me-1"></i> {totalAmount}
                  </span>
                </h5>
              </div>
              <div className="d-flex align-items-center justify-content-end p-2">
                <Button to="#" className="proceed-btn" onClick={(e) => completeFunc(e)}>
                  Book Tickets
                </Button>
              </div>
            </Col>
            <Col xl={{ span: 3, offset: 0 }} lg={{ span: 9, offset: 2 }}>
              <div>
                <PeopleKnow />
              </div>
              <div>
                <WorkAnniversary />
              </div>
              <div>
                <GroupDiscover />
              </div>
              <div>
                <FollowCompany />
              </div>
              <div>
                <CampusNews />
              </div>
              <div>
                <ArticalBlogs />
              </div>
              <div>
                <CampusnewsText />
              </div>
              <div>
                <WhatsNew />
              </div>
              <div>
                <Upcoming />
              </div>
              <MiniFooter />
            </Col>
          </Row>
        </Container>
      </section>
      {loading ? <Loader /> : ''}
    </>
  );
}

export default EventTicketSummary;
