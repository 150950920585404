//  @copyright : ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
// @author     : Shiv Charan Panjeta

// All Rights Reserved.
// Proprietary and confidential :  All information contained herein is, and remains
// the property of ToXSL Technologies Pvt. Ltd. and its partners.
// Unauthorized copying of this file, via any medium is strictly prohibited.

import { useEffect, useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Footer from '../../CommonComponents/Footer';
import SideBar from '../../CommonComponents/Sidebar';
import Header from '../../CommonComponents/Header';
import { Container } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { Button } from 'react-bootstrap';
import Pagination from 'react-bootstrap/Pagination';
import Select from 'react-select';
import * as adminServices from '../../../services/AuthServices';
import showNotification from '../../../services/NotificationService';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
export default function AddmanageCategory() {
  const navigate = useNavigate();
  const location = useLocation();
  const data = location.state;
  const [detail, setDetail] = useState({
    name: '',
    category_id: ''
  });
  const [categoryOption, setCategoryOption] = useState([]);
  const [categoryId, setCategoryId] = useState([]);

  useEffect(() => {
    getCategoryOption();
    if (data?.id) {
      getDetail(data?.id);
    }
  }, []);

  const [clicked, setClicked] = useState(false);

  const getDetail = (id) => {
    adminServices.getSocialPageCatDetail(id).then((res) => {
      if (res?.status === 200) {
        const data = res?.data?.data?.catInfo;
        setDetail({
          name: data?.name,
          category_id: data?.category_id
        });
        setCategoryId({ value: data?.category_id, label: data?.parentName });
      }
    });
  };

  const onChangHandler = (e, name = '') => {
    if (name) {
      setDetail({
        ...detail,
        [name]: e.value
      });
    } else {
      const { name, value } = e.target;
      setDetail({
        ...detail,
        [name]: value
      });
    }
  };
  const validate = (value) => {
    let error = {};
    if (!value.name.trim()) {
      error.name = ' This field is required.';
    }
    if (!value.category_id) {
      error.category_id = 'Select Category .';
    }
    return error;
  };

  const submitHandler = (e) => {
    e.preventDefault();
    setClicked(true);
    if (!Object.keys(validate(detail)).length) {
      setClicked(false);
      adminServices.addSocialCat(detail).then((res) => {
        if (res?.status === 200) {
          showNotification('success', res?.data?.message);
          navigate('/admin/pages/list_cats');
        }
      });
    }
  };

  const updateHandler = (e) => {
    e.preventDefault();
    setClicked(true);
    if (!Object.keys(validate(detail)).length) {
      let body = {
        id: data?.id,
        name: detail?.name,
        category_id: detail?.category_id
      };
      adminServices.updateSocialPageCat(body).then((res) => {
        if (res?.status === 200) {
          showNotification('success', res?.data?.message);
          navigate(-1);
        }
      });
    }
  };

  let active = 2;
  let items = [];
  for (let number = 1; number <= 5; number++) {
    items.push(
      <Pagination.Item key={number} active={number === active}>
        {number}
      </Pagination.Item>
    );
  }

  const getCategoryOption = () => {
    adminServices.getPageCategoryDrop().then((res) => {
      if (res?.status === 200) {
        let tempArr = [];
        if (res?.data?.message) {
          Object.entries(res?.data?.message)
            .map(([key, value]) => ({ key, value }))
            ?.map((insideData) => {
              tempArr.push({
                label: insideData?.key,
                options: insideData?.value?.flatMap((x) => {
                  return {
                    label: Object.values(x).join(),
                    value: Object.keys(x).join()
                  };
                })
              });
            });
        }
        setCategoryOption(tempArr);
      }
    });
  };

  return (
    <div className="dash-main-content">
      <Container fluid>
        <div className="dash-page-content">
          <div className="dash-page-title">
            <Row>
              <Col xs={12} sm={12} md={12}>
                <div className="dash-title-bar d-flex align-items-center justify-content-between">
                  <div className="title-lft d-flex align-items-center">
                    {/* <div className="dash-icn">
                        <i className="fas fa-users"></i>
                      </div> */}
                    <div className="dash-title-dis">
                      {data?.id ? <h5>Edit Page Category</h5> : <h5>Add Page Category</h5>}
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <div className="dash-content-card">
            <div className="cmmn-form">
              <Form>
                <Row>
                  <Col md={12}>
                    <Form.Group className="mb-3" controlId="formBasicName">
                      <Form.Label>
                        Name<span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter the category page name."
                        name="name"
                        value={detail?.name}
                        onChange={(e) => {
                          onChangHandler(e);
                        }}
                      />
                    </Form.Group>
                    {clicked && validate(detail).name}
                  </Col>
                  <Col md={12}>
                    <Form.Group className="mb-3" controlId="formBasicName">
                      <Form.Label>Select Category Level</Form.Label>
                      <Select
                        className="basic-single"
                        classNamePrefix="select"
                        isDisabled={false}
                        isLoading={false}
                        isClearable={false}
                        isRtl={false}
                        isSearchable={true}
                        options={categoryOption}
                        value={categoryId}
                        onChange={(e) => {
                          setCategoryId(e);
                          setDetail({ ...detail, ['category_id']: e.value });
                        }}
                      />
                    </Form.Group>
                    {clicked && validate(detail).category_id}
                  </Col>
                </Row>
                <div className="form-btns d-flex align-items-center mt-3">
                  {data?.id ? (
                    <Button
                      className="main-btn-grn"
                      onClick={(e) => {
                        updateHandler(e);
                      }}>
                      Update
                    </Button>
                  ) : (
                    <Button className="main-btn-grn" onClick={submitHandler}>
                      Save
                    </Button>
                  )}

                  <strong>OR</strong>
                  <Button
                    className="main-btn-red"
                    onClick={() => navigate('/admin/pages/list_cats')}>
                    Cancel
                  </Button>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}
