/**
@copyright  :   ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
@author     :   Shiv Charan Panjeta 
 
All Rights Reserved.
Proprietary and confidential :  All information contained here in is, and remains
the property of ToXSL Technologies Pvt. Ltd. and it's partners.
Unauthorized copying of this file, via any medium is strictly prohibited.
*/
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { useEffect, useState } from 'react';
import { Button, Container } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import AsyncSelect from 'react-select/async';
import AsyncCreatableSelect from 'react-select/async-creatable';
import Loader from '../../components/commoncomponents/loader/loader-large';
import * as adminServices from '../../services/AuthServices';
import showNotification from '../../services/NotificationService';

export default function AddState() {
  const navigate = useNavigate();
  const location = useLocation();
  const id = location.state;
  const [detail, setDetail] = useState({
    institute_id: '',
    title: '',
    description: '',
    tag_id: ''
  });
  const [value, setValue] = useState('');
  const [tagValue, setTagValue] = useState('');
  const [loading, setLoading] = useState(false);
  const [clicked, setClicked] = useState(false);
  const [attachment, setAttachMent] = useState('');
  const [previewImg, setPreviewImg] = useState(false);
  const userDetail = useSelector((state) => state?.user?.details);

  useEffect(() => {
    if (id) {
      getDetail(id.id);
    }
  }, []);

  const getDetail = (id) => {
    setLoading(true);
    adminServices.viewBlog(id).then((res) => {
      setLoading(false);
      if (res?.status === 200) {
        const data = res?.data?.data?.details;
        setDetail({
          id: data?.id,
          institute_id: data?.institute_id,
          title: data?.title,
          description: data?.description,
          tag_id: res?.data?.data?.taglsit?.map((data) => data?.id).join()
        });
        if (data?.image) {
          setAttachMent(data?.image);
        }
        setValue({ label: data?.name, value: data?.institute_id });
        if (res?.data?.data?.taglsit?.length) {
          let arr = [];
          res?.data?.data?.taglsit?.map((data) => {
            arr.push({
              label: data?.title,
              value: data?.id
            });
          });
          setTagValue(arr);
        }
      }
    });
  };

  const onChangeHandler = (e) => {
    const { name, value } = e.target;
    setDetail({ ...detail, [name]: value });
  };

  const validate = (value) => {
    let errors = {};
    if (!value?.title.trim()) {
      errors.title = 'This field is required';
    }
    if (!value?.description.trim()) {
      errors.description = 'This field is required';
    }
    if (!value?.institute_id && !userDetail?.institute_id) {
      errors.institute_id = 'This field is required';
    }
    return errors;
  };

  const submitHandler = (e) => {
    e.preventDefault();
    setClicked(true);
    if (!Object.keys(validate(detail)).length) {
      let formData = new FormData();
      for (let i in detail) {
        formData.append(`${i}`, detail[i]);
      }
      if (attachment) {
        formData.append('image', attachment);
      }
      if (userDetail?.institute_id) {
        formData.append('institute_id', userDetail?.institute_id);
      }
      adminServices.addBlog(formData).then((res) => {
        if (res?.status === 200) {
          showNotification('success', res?.data?.message);
          navigate(-1);
        }
      });
    }
  };

  const updateHandler = (e) => {
    e.preventDefault();
    setClicked(true);
    if (!Object.keys(validate(detail)).length) {
      let formData = new FormData();
      for (let i in detail) {
        formData.append(`${i}`, detail[i]);
      }
      if (attachment) {
        formData.append('image', attachment);
      }
      adminServices.editBlog(formData).then((res) => {
        if (res?.status === 200) {
          showNotification('success', res?.data?.message);
          navigate(-1);
        }
      });
    }
  };

  const loadInstituteOptions = (inputValue) => {
    return adminServices.getInstituteDrop(inputValue).then((res) => {
      if (res?.status === 200) {
        let tempArr = [];
        if (res?.data?.length) {
          const data = res?.data;
          data?.map((data) => {
            tempArr.push({
              label: data?.institutename,
              value: data?.institute_id
            });
          });
        }
        return tempArr;
      }
    });
  };

  const loadTagsOption = (inputValue) => {
    return adminServices.getTagList(inputValue, detail?.institute_id).then((res) => {
      if (res?.status === 200) {
        let tempArr = [];
        if (res?.data?.details?.list?.length) {
          const data = res?.data?.details?.list;
          data?.map((data) => {
            tempArr.push({
              label: data?.title,
              value: data?.id
            });
          });
        }
        return tempArr;
      }
    });
  };

  return (
    <>
      <div className="dash-main-content">
        <Container fluid>
          <div className="dash-page-content">
            <div className="dash-page-title">
              <Row>
                <Col xs={12} sm={12} md={12}>
                  <div className="dash-title-bar d-flex align-items-center justify-content-between">
                    <div className="title-lft d-flex align-items-center">
                      <div className="dash-title-dis">
                        <h5>{!id ? 'Add' : 'Edit'} Blog</h5>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="dash-content-card">
              <div className="cmmn-form">
                <Form>
                  {userDetail?.admin_type == '3' ? (
                    <></>
                  ) : (
                    <Form.Group className="mb-3" controlId="formBasicName">
                      <Form.Label>
                        Select Institute <sup className="text-danger">*</sup>
                      </Form.Label>
                      <AsyncSelect
                        className="basic-single"
                        classNamePrefix="select"
                        isSearchable={true}
                        name="institute_id"
                        value={value}
                        loadOptions={loadInstituteOptions}
                        onChange={(e) => {
                          setValue(e), setDetail({ ...detail, ['institute_id']: e.value });
                        }}
                        placeholder="Search By Institute Name"
                      />
                      <div
                        className={`${
                          clicked && validate(detail)?.institute_id ? '' : 'd-none'
                        } danger-frm `}>
                        {clicked && validate(detail)?.institute_id}
                      </div>
                    </Form.Group>
                  )}

                  <Form.Group className="mb-3" controlId="formBasicState">
                    <Form.Label>
                      Title<sup className="text-danger">*</sup>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="title"
                      value={detail?.title}
                      onChange={(e) => {
                        onChangeHandler(e);
                      }}
                      placeholder="Enter title "
                    />
                    <div
                      className={`${
                        clicked && validate(detail)?.title ? '' : 'd-none'
                      } danger-frm `}>
                      {clicked && validate(detail)?.title}
                    </div>
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formBasicName">
                    <Form.Label>
                      Description<sup className="text-danger">*</sup>
                    </Form.Label>
                    {/* <Form.Control
                      type="text"
                      value={detail?.description}
                      onChange={(e) => {
                        onChangeHandler(e);
                      }}
                      name="description"
                      placeholder="Enter description"
                    /> */}
                    <CKEditor
                      name="job_info"
                      editor={ClassicEditor}
                      data={detail?.description}
                      config={{
                        toolbar: [
                          'heading',
                          '|',
                          'fontSize',
                          'fontFamily',
                          '|',
                          'bold',
                          'italic',
                          'underline',
                          'strikethrough',
                          'highlight',
                          '|',
                          'alignment',
                          '|',
                          'numberedList',
                          'bulletedList',
                          '|',
                          'indent',
                          'outdent',
                          '|',
                          'todoList',
                          'blockQuote',
                          '|',
                          'undo',
                          'redo'
                        ]
                      }}
                      onChange={(_, editor) => {
                        const data = editor.getData();
                        setDetail((previous) => {
                          return {
                            ...previous,
                            ['description']: data
                          };
                        });
                      }}
                    />
                    <div
                      className={`${
                        clicked && validate(detail)?.description ? '' : 'd-none'
                      } danger-frm `}>
                      {clicked && validate(detail)?.description}
                    </div>
                  </Form.Group>

                  {value ? (
                    <Form.Group className="mb-3" controlId="formBasicName">
                      <Form.Label>
                        Select Tags <sup className="text-danger"></sup>
                      </Form.Label>
                      <AsyncCreatableSelect
                        isMulti
                        className="basic-single"
                        classNamePrefix="select"
                        isSearchable={true}
                        name="tag_id"
                        value={tagValue}
                        loadOptions={loadTagsOption}
                        onChange={(e) => {
                          setTagValue(e),
                            setDetail({
                              ...detail,
                              ['tag_id']: e.map((data) => data.value).join()
                            });
                        }}
                        placeholder="Search By Tag Name"
                      />
                    </Form.Group>
                  ) : (
                    <></>
                  )}

                  <Form.Group controlId="formFile" className="attach-file-card">
                    <Form.Control
                      type="file"
                      name="image"
                      accept=".png,.jpg,.jpeg"
                      onChange={(e) => {
                        setAttachMent(e.target.files[0]);
                        setPreviewImg(URL.createObjectURL(e.target.files[0]));
                      }}
                    />
                    <span className="attach-file-inr">
                      <i className="fas fa-paperclip me-2"></i>
                      Attach Files
                    </span>
                    <div className={'danger-frm'}>{clicked && validate().attachment}</div>
                  </Form.Group>
                  <Row>
                    <Col xs={2} sm={2} md={2}>
                      <div className="">
                        {id && attachment && !previewImg ? (
                          <img
                            height={'100px'}
                            width={'100px'}
                            src={
                              process.env.REACT_APP_API_DOMAIN + 'common/uploads/blog/' + attachment
                            }
                            alt="img"
                          />
                        ) : attachment ? (
                          <img height={'100px'} width={'100px'} src={previewImg} alt="img" />
                        ) : (
                          ''
                        )}
                      </div>
                    </Col>
                  </Row>
                  <div className="form-btns d-flex align-items-center mt-5">
                    {id ? (
                      <Button
                        className="main-btn-grn"
                        onClick={(e) => {
                          updateHandler(e);
                        }}>
                        Update
                      </Button>
                    ) : (
                      <Button
                        className="main-btn-grn"
                        onClick={(e) => {
                          submitHandler(e);
                        }}>
                        Save
                      </Button>
                    )}
                    <strong>OR</strong>
                    <Button
                      className="main-btn-red"
                      onClick={(e) => {
                        navigate(-1);
                      }}>
                      Cancel
                    </Button>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </Container>
      </div>
      {loading ? <Loader /> : ''}
    </>
  );
}
