const intialState = {
  showAlertBox: false,
  message: ''
};

const alertReducer = (state = intialState, action) => {
  switch (action.type) {
    case 'showDrop':
      return {
        ...state,
        showAlertBox: true,
        message: action.payload
      };
    case 'hideDrop':
      return {
        ...state,
        showAlertBox: false,
        message: ''
      };

    default:
      return state;
  }
};

export default alertReducer;
