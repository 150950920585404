const initialState = {
  list: {}
};

export const userConnectionDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'USER_CONNECTIONS_DATA':
      return {
        ...state,
        list: action.payload
      };

    default:
      return state;
  }
};
