import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import FooterMain from '../../commoncomponents/FooterMain';
import useDocumentTitle from '../../../globals/useDocumentTitle';
import * as APISERVICES from '../../../services/AuthServices';
import { useState } from 'react';
import showNotification from '../../../services/NotificationService';
import { subDomain } from '../../../globals/helper';

function ForgotPassword() {
  if (subDomain()) {
    useDocumentTitle(subDomain());
  } else {
    useDocumentTitle('AlmaBay');
  }

  const [clicked, setClicked] = useState(false);
  const emailValidate = new RegExp(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/);
  const [details, setDetails] = useState({
    email: ''
  });

  const handlePassword = (e) => {
    setClicked(true);

    e.preventDefault();
    const body = {
      email: details.email
    };

    if (!Object.keys(validate()).length) {
      APISERVICES.forgotPasssword(body).then((res) => {
        setClicked(false);

        if (res?.status === 200) {
          showNotification('danger', res?.data?.message);
        }
      });
    }
  };
  const handleChange = (e, name = '') => {
    if (name) {
      setDetails({
        ...details,
        [name]: e.value
      });
    } else {
      const { name, value } = e.target;
      setDetails({
        ...details,
        [name]: value
      });
    }
  };

  const validate = () => {
    let errors = {};
    if (details?.email == '') {
      errors.email = 'Please enter your email.';
    } else if (!emailValidate.test(details?.email)) {
      errors.email = 'Please enter Valid email';
    }
    return errors;
  };

  return (
    <>
      <section className="login-card py-5 bg-blue align-items-center d-flex">
        <div className="container">
          <Row>
            <Col md={{ span: 4, offset: 4 }}>
              <div className="lgn-frm-card">
                <h4 className="mb-3">Find your AlmaBay account</h4>
                <p className="mb-4">
                  Enter your email address below, we'll send an password reset link to your email.
                </p>
                <div className="lgn-frm">
                  <Form>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Control
                        type="email"
                        placeholder="Enter email"
                        name="email"
                        value={details.email}
                        onChange={handleChange}
                      />

                      <div className={'danger-frm'}>{clicked && validate()?.email}</div>
                    </Form.Group>

                    <div className="frm-btn d-flex align-items-center mt-4">
                      <Button className="cmmn-btn" onClick={(e) => handlePassword(e)}>
                        Reset password
                      </Button>
                    </div>
                  </Form>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </section>
      <div className="footer-outr">
        <FooterMain className={'colour-bg'} />
      </div>
    </>
  );
}

export default ForgotPassword;
