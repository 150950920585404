import { useState, useEffect } from 'react';
import { Col, Container, Dropdown, Row } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import HeaderLogin from '../../commoncomponents/HeaderLogin';
import TopTabbings from './TopTabbings';
import SuggestMentor from '../../commoncomponents/newsfeeds/SuggestMentor';
import GroupDiscover from '../../commoncomponents/newsfeeds/GroupDiscover';
import WhatsNew from '../../commoncomponents/newsfeeds/WhatsNew';
import Upcoming from '../../commoncomponents/newsfeeds/Upcoming';
import showNotification from '../../../services/NotificationService';
import * as APISERVICES from '../../../services/MentorArticlesServices';
import useDocumentTitle from '../../../globals/useDocumentTitle';
import { MiniFooter } from '../../MiniFooter';
import { truncate } from '../../../globals/helper';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { MentorshipSkelton } from '../../commoncomponents/skelton/allskelton';
import QuestionSideBar from './QuestionSidebar';
import { getMentorshipProfileQuestions } from '../../../services/ProfileServices';
import { useSelector } from 'react-redux';
import HeaderInner from '../../commoncomponents/HeaderInner';

function MentorAnswers() {
  useDocumentTitle('AlmaBay');
  const [answerRequest, setAnswerRequest] = useState([]);
  const [loading, setLoading] = useState(false);
  const [listData, setListData] = useState([]);
  const [pageNum, setPageNum] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const loginUserDetails = JSON.parse(localStorage.getItem('userDetail'));
  const navigate = useNavigate();
  const { mentorId } = useParams();
  const isloggedIn = useSelector((state) => state?.user?.isloggedIn);
  const [mentorInfo, setMentorInfo] = useState('');

  useEffect(() => {
    isMentorshipProfile();
  }, []);

  // This commented code is in use --------------

  //   useEffect(() => {
  //     if (pageNum <= totalPages) {
  //       getAnswerRequestsList();
  //     }
  //   }, [pageNum]);

  //   const getAnswerRequestsList = (isTrue) => {
  //     setLoading(true);
  //     APISERVICES.answerListMentorship(pageNum).then((res) => {
  //       setLoading(false);
  //       if (res?.status === 200) {
  //         if (isTrue) {
  //           let arra1 = answerRequest.map((item) => {
  //             const item2 = res.data?.detail?.find((i2) => i2.id == item.id);
  //             return item ? { ...item, ...item2 } : item2;
  //           });
  //           setAnswerRequest(arra1);
  //         } else {
  //           setAnswerRequest([...answerRequest, ...res.data.detail]);
  //         }
  //         setListData(res?.data?.detail); // user for searching
  //         setTotalPages(res?.data?.totalResult && Math.ceil(res?.data?.totalResult / 20));
  //       }
  //     });
  //   };

  const HelpFullHandler = (e, id) => {
    e.preventDefault();
    const body = {
      q_id: id,
      answer_id: 0,
      type: 'like'
    };

    let formData = new FormData();

    for (let i in body) {
      formData.append(`${i}`, body[i]);
    }
    APISERVICES.mentorshipQaFeedLike(formData).then((res) => {
      if (res?.status === 200) {
        showNotification('success', 'Marked as helpful, Success!');
        isMentorshipProfile();
      }
    });
  };

  const handleSearch = (e) => {
    let value = e.target.value.toLowerCase();
    if (value !== '') {
      let result = listData.filter((data) => {
        return (
          data.title.toLowerCase().includes(value) ||
          data?.description.toLowerCase().includes(value)
        );
      });
      setAnswerRequest(result);
    } else {
      setAnswerRequest(listData);
    }
  };

  const handleNavigate = (e, data) => {
    e.preventDefault();
    if (data?.content_type === 'question') {
      navigate(`/user/viewanswer/${data?.id}`, { state: 'answer-req' });
    } else {
      navigate(`/user/mentor-article/${data?.id}`);
    }
  };

  const loadMore = (e) => {
    e.preventDefault();
    setPageNum((no) => no + 1);
  };

  const isMentorshipProfile = () => {
    const body = {
      uid: mentorId,
      content_type: 'answers'
    };
    getMentorshipProfileQuestions(body).then((res) => {
      if (res?.status === 200) {
        setAnswerRequest(res?.data?.detail);
        setMentorInfo(res?.data?.mentorInfo);
      }
    });
  };

  return (
    <>
      {!isloggedIn ? <HeaderInner /> : <HeaderLogin />}
      {isloggedIn ? (
        <div className="header-lnk-feeds">
          <div className="container">
            <TopTabbings state={'mentor'} />
          </div>
        </div>
      ) : (
        ''
      )}
      <section className="news-feed-sec bg-grey py-4">
        <Container>
          <Row>
            <Col xs={12} md={3}>
              <QuestionSideBar />
            </Col>

            <Col xs={12} md={isloggedIn ? 6 : 9}>
              <div className="articals_div">
                {loading ? (
                  <>
                    <MentorshipSkelton />
                    <MentorshipSkelton />
                    <MentorshipSkelton />
                  </>
                ) : answerRequest && answerRequest?.length > 0 ? (
                  answerRequest?.map((data, index) => {
                    return (
                      <div className="single_artical_card leave-ques-card p-3" key={index}>
                        <div className="d-flex align-items-center mb-3">
                          <Link
                            className="media_img"
                            to={`/user/timeline/${data?.asked_by?.encrypted_id}`}
                          >
                            <img
                              src={
                                data?.asked_by?.avatar?.complete_url
                                  ? data?.asked_by?.avatar?.complete_url
                                  : data?.asked_by?.avatar_url
                              }
                              alt="user_img"
                              className="me-3"
                            />
                          </Link>
                          <div className="media-body">
                            <h4>
                              <Link
                                className="user-link"
                                to={`/mentors/profile/${data?.asked_by?.encrypted_id}`}
                              >
                                {data?.asked_by?.name}
                              </Link>
                              <span>asked it, answered by </span>
                              <Link className="user-link" to={`/mentors/profile/${mentorId}`}>
                                {mentorInfo?.name}
                              </Link>
                            </h4>
                            <p>{moment(data?.added_on).fromNow()}</p>
                          </div>
                        </div>
                        <div>
                          <h4 className="title">
                            <Link to="" onClick={(e) => handleNavigate(e, data)}>
                              {data?.title}
                            </Link>
                          </h4>
                          <span
                            dangerouslySetInnerHTML={{ __html: truncate(data?.description, 500) }}
                          />
                        </div>
                        <div>
                          <Link
                            className="read-more"
                            to="#"
                            onClick={(e) => handleNavigate(e, data)}
                          >
                            Read more...
                          </Link>
                        </div>
                        <div className="navigation-border">
                          <ul className="article-cmmnt-lnks d-flex ps-2">
                            <li className="active-helpful pe-3">
                              {data?.liked_by_current_user ? (
                                <>
                                  <i className="fa fa-heart "></i> Helpful{' '}
                                  <span>({data?.likes})</span>
                                </>
                              ) : (
                                <Link
                                  to="#"
                                  className="pe-0 "
                                  onClick={(e) => {
                                    HelpFullHandler(e, data?.id);
                                  }}
                                >
                                  <i className="fa fa-heart "></i>Helpful{' '}
                                  <span>({data?.likes})</span>
                                </Link>
                              )}
                            </li>
                            <li>
                              <Link
                                to={`${
                                  data?.content_type === 'question'
                                    ? `/user/viewanswer/${data?.id}`
                                    : `/user/mentor-article/${data?.id}`
                                }`}
                                className="pe-3"
                              >
                                <i className="fa fa-comment"></i> {'  '}{' '}
                                {data?.content_type === 'question' ? 'Answers' : 'Comments'}{' '}
                                <span>
                                  (
                                  {data?.content_type === 'question'
                                    ? data?.answers
                                    : data?.comments}
                                  )
                                </span>
                              </Link>
                            </li>
                            <li className="share-drop-btn">
                              <Dropdown>
                                <Dropdown.Toggle className="pe-3" id="dropdown-basic">
                                  <i className="fa fa-share"></i> Share
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="p-2">
                                  <Dropdown.Item href="#/action-1">Facebook</Dropdown.Item>
                                  <Dropdown.Item href="#/action-2">Twitter</Dropdown.Item>
                                  <Dropdown.Item href="#/action-3">Google</Dropdown.Item>
                                  <Dropdown.Item href="#/action-3">Linkedin</Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            </li>
                          </ul>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div className="p-5 bg-white text-center">
                    <img
                      src={require('../../../assets/images/small-error.png')}
                      width="105px"
                      alt="img"
                    />
                    <p>We do not have answer request for you at the moment.</p>
                  </div>
                )}

                {pageNum + 1 < totalPages && (
                  <div className="load-more-btn py-3 text-center">
                    <Link to="#" className="read-more" onClick={(e) => loadMore(e)}>
                      Load More
                    </Link>
                  </div>
                )}
              </div>
            </Col>

            {isloggedIn ? (
              <Col xs={12} lg={3} md={12}>
                <div>
                  <SuggestMentor />
                </div>
                <div>
                  <GroupDiscover />
                </div>
                <div>
                  <WhatsNew />
                </div>
                <div>
                  <Upcoming />
                </div>
                <MiniFooter />
              </Col>
            ) : (
              ''
            )}
          </Row>
        </Container>
      </section>
    </>
  );
}

export default MentorAnswers;
