/**
@copyright  :   ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
@author     :   Shiv Charan Panjeta 
 
All Rights Reserved.
Proprietary and confidential :  All information contained here in is, and remains
the property of ToXSL Technologies Pvt. Ltd. and it's partners.
Unauthorized copying of this file, via any medium is strictly prohibited.
*/
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { Link, useLocation } from 'react-router-dom';
import { ViewSingleEventDetail } from '../../../services/AuthServices';
import defaultEvent from '../../../assets/images/event.png';

export default function ViewEventDetail() {
  const [eventDetail, setEventDetail] = useState('');
  const [city, setcity] = useState('');
  const location = useLocation();
  let id = location?.state;

  useEffect(() => {
    if (id) {
      getDetail(id);
    }
  }, []);

  const getDetail = (id) => {
    ViewSingleEventDetail(id).then((res) => {
      if (res?.status === 200) {
        const data = res?.data?.data?.details;
        setEventDetail(data);
        setcity(res?.data?.data);
      }
    });
  };

  return (
    <div className="dash-main-content">
      <Container fluid>
        <div className="dash-page-content">
          <div className="dash-page-title">
            <Row>
              <Col xs={12} sm={12} md={12}>
                <div className="dash-title-bar d-flex align-items-center justify-content-between">
                  <div className="title-lft d-flex align-items-center">
                    <div className="dash-icn">
                      <i className="fas fa-file-word"></i>
                    </div>
                    <div className="dash-title-dis">
                      <h5> View Event Details</h5>
                      {/* <h5>
                          Job in <span>job one</span>
                        </h5> */}
                    </div>
                  </div>
                  <div className="title-rt-btn ">
                    <Link className="green_button_admin" to="/admin/event">
                      Back To Listing
                    </Link>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <div className="dash-content-card">
            <div className="view-detail-content">
              <Row>
                <Col md={12}>
                  <p>
                    <strong>Privacy : </strong>
                    {eventDetail?.event_privacy}
                  </p>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <p>
                    <strong>Name of Event Activity:</strong> {eventDetail?.name}
                  </p>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <p>
                    <strong>Institute : </strong>
                    {city?.instituteName}
                  </p>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <p>
                    <strong>Category : </strong>
                    {city?.category_name}
                  </p>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <p>
                    <strong>User ID : </strong>
                    {eventDetail?.id}
                  </p>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <p>
                    <strong>Description : </strong>
                    {eventDetail?.decp}
                  </p>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <p>
                    <strong>Venue : </strong>
                    {eventDetail?.venue}
                  </p>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <p>
                    <strong>City : </strong>
                    {city?.city_name}
                  </p>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <p>
                    <strong>Event Date : </strong>
                    {moment(eventDetail?.from_event_date).format('Do MMMM YYYY')} {'  '}
                    <span>
                      {' '}
                      {'  '} <strong>from to :</strong>{' '}
                    </span>
                    {eventDetail?.to_event_date == '0000-00-00 00:00:00'
                      ? 'N/A'
                      : moment(eventDetail?.to_event_date).format('Do MMMM YYYY')}
                  </p>
                </Col>
                <div className="d-flex flex-wrap align-item-center">
                  <div className="border me-2">
                    <img
                      width="100px"
                      height="100px"
                      src={
                        city?.image_name !== 'No Image Found'
                          ? process.env.REACT_APP_API_DOMAIN +
                            'common/uploads/event/' +
                            city?.image_name
                          : defaultEvent
                      }
                      alt="img1"
                    />
                  </div>
                </div>
              </Row>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}
