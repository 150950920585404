import { Route, Routes } from 'react-router-dom';
import FindMentors from '../../components/webpages/user-pages/FindMentors';
import MentorShip from '../../components/webpages/user-pages/MentorShip';
import MyProfile from '../../components/webpages/user-pages/MyProfile';
import MyQuestion from '../../components/webpages/user-pages/MyQuestion';
import MentorAnswers from '../../components/webpages/user-pages/MentorAnswers';
import MentorQuestions from '../../components/webpages/user-pages/MentorQuestions';
import PageNotfound from '../../components/webpages/user-pages/PageNotfound';

function MentorsRoutes() {
  return (
    <Routes>
      <Route path="/directory" element={<FindMentors />} />
      <Route path="/profile" element={<MyProfile />} />
      <Route path="/profile/:wall_user_id" element={<MyProfile />} />
      <Route path="/qafeeds" element={<MentorShip />} />
      <Route path="/questions" element={<MyQuestion />} />
      <Route path="/questions/:mentorId" element={<MentorQuestions />} />
      <Route path="/articles/:mentorId" element={<MentorShip />} />{' '}
      <Route path="/answers/:mentorId" element={<MentorAnswers />} />
      <Route path="/*" element={<PageNotfound />} />
    </Routes>
  );
}

export default MentorsRoutes;
