/** @copyright : ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
 @author     : Shiv Charan Panjeta 
 All Rights Reserved.
 Proprietary and confidential :  All information contained herein is, and remains
 the property of ToXSL Technologies Pvt. Ltd. and its partners.
 Unauthorized copying of this file, via any medium is strictly prohibited.
*/
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import ReactPlayer from 'react-player';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { getMediaData } from '../../../services/InstituteServices';

function VideoDetailView() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [list, setList] = useState([]);

  useEffect(() => {
    getData(id);
  }, []);

  /**
   * get video list based on media_id
   * @param {media_id} id
   */
  const getData = (id) => {
    getMediaData(id).then((res) => {
      if (res?.status === 200) {
        setList(res?.data?.data);
      }
    });
  };

  return (
    <div className="wrappermain_outer">
      <section className="gallery_view_section">
        <Container>
          <Row>
            <Col sm={12}>
              <button
                className="btn btn-back mb-4"
                onClick={() => {
                  navigate(-1);
                }}>
                Back
              </button>
              <h2 className="gallery-view-heading text-center">
                {list?.album_details?.title || ''}
              </h2>
              <p className="/jobs-board-muted text-center">
                Videos: <span>{list?.list?.length || 0}</span> &nbsp;{' '}
                {list?.album_details?.location ? (
                  <>
                    Location: <span>{list?.album_details?.location} </span>
                  </>
                ) : (
                  <></>
                )}{' '}
                &nbsp; Date:{' '}
                <span>{moment(list?.album_details?.created).format('DD MMM YYYY')}</span>
              </p>
            </Col>
          </Row>

          <Row className="five-colmn">
            {list?.list && list?.list?.length ? (
              list?.list?.map((data, index) => (
                <Col key={index}>
                  <Link className="d-block">
                    <ReactPlayer
                      width="250"
                      height="500"
                      url={data?.url || data?.url_link}
                      controls
                    />
                  </Link>
                </Col>
              ))
            ) : (
              <>Data Not Found</>
            )}
          </Row>
        </Container>
      </section>
    </div>
  );
}

export default VideoDetailView;
