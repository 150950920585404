/**
@copyright : ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
@author    : Shiv Charan Panjeta 
All Rights Reserved.
Proprietary and confidential :  All information contained here in is, and remains
the property of ToXSL Technologies Pvt. Ltd. and it's partners.
Unauthorized copying of this file, via any medium is strictly prohibited.
* 
*/

import { useEffect, useState } from 'react';
import { Button, Container } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Table from 'react-bootstrap/Table';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import Select from 'react-select';
import NoDataFound from '../../components/commoncomponents/NoDataFound';
import Loader from '../../components/commoncomponents/loader/loader-large';
import * as adminService from '../../services/AuthServices';

export default function ListCourses() {
  const navigate = useNavigate();
  const location = useLocation();
  const id = location.state;
  const { depId } = useParams();
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [sortType, setSortType] = useState('DESC');
  const [sortField, setSortField] = useState('course_id');
  const [searchedData, setSearchedData] = useState({
    course_id: '',
    course_name: '',
    dept_id: ''
  });
  const [departmentList, setDepartmentList] = useState([]);
  const [departmentId, setDepartmentId] = useState('');

  useEffect(() => {
    if (id || depId) {
      getCourseList();
      getDepartmentDropDown(id);
    }
  }, []);

  const getCourseList = (
    instiId,
    page = 1,
    sortfield = 'course_id',
    order = 'DESC',
    course_id = '',
    course_name = '',
    dept_id = ''
  ) => {
    setLoading(true);
    adminService
      .getCourseList(
        instiId ? instiId : id,
        page,
        sortfield,
        order,
        course_id,
        course_name,
        dept_id ? dept_id : depId
      )
      .then((res) => {
        setLoading(false);
        if (res?.status === 200) {
          setList(res?.data?.data);
        }
      });
  };

  const getDepartmentDropDown = (id) => {
    adminService.departmentDropDown(id).then((res) => {
      if (res?.status === 200) {
        let list = res?.data?.data;
        let arr = [];
        list?.map((data) => {
          arr.push({ id: data?.institute_id, label: data?.name, value: data?.name });
        });
        setDepartmentList(arr);
      }
    });
  };

  const searchChangeHandler = (e) => {
    const { name, value } = e.target;
    setSearchedData({ ...searchedData, [name]: value });
  };

  const searchHandler = () => {
    getCourseList(
      id,
      1,
      sortField,
      sortType,
      searchedData.course_id,
      searchedData.course_name,
      searchedData.dept_id
    );
  };

  const stateHandler = (e, value) => {
    if (e === 'edit') {
      navigate(`/admin/institute/edit-courses/${id}/${value}`);
    } else {
      // adminService.getCourseList().then(res => {
      //   setLoading(false)
      //   if (res?.status === 200) {
      //     api call
      //   }
      // })
      return;
    }
  };

  const sortHandler = (e, type) => {
    e.preventDefault();
    let currentSort = sortType === 'ASC' ? 'DESC' : 'ASC';
    setSortType(currentSort);
    setSortField(type);
    getCourseList(
      id,
      1,
      type,
      currentSort,
      searchedData.course_id,
      searchedData.course_name,
      searchedData.dept_id
    );
  };

  const resetHandler = () => {
    setSearchedData({
      course_id: '',
      course_name: '',
      dept_id: ''
    });
    setDepartmentId({});
    getCourseList(id);
  };

  return (
    <>
      <div className="dash-main-content">
        <Container fluid>
          <div className="dash-page-content">
            <div className="dash-page-title">
              <Row>
                <Col xs={12} sm={12} md={12}>
                  <div className="dash-title-bar d-flex align-items-center justify-content-between">
                    <div className="title-lft d-flex align-items-center">
                      <div className="dash-icn">
                        <i className="fas fa-university"></i>
                      </div>
                      <div className="dash-title-dis">
                        <h5>
                          Listing of Courses in <span>{list?.title} </span>
                        </h5>
                        <h5>
                          Total Record(s): <span>{list?.totalRecords}</span>
                        </h5>
                      </div>
                    </div>
                    <div className="title-rt-btn">
                      <Link
                        className="green_button_admin"
                        to={'/admin/institute/add-courses/' + id}>
                        Add Course
                      </Link>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="dash-content-card">
              <div className="table-responsive">
                <Table className="custom-table-data" striped bordered hover>
                  <thead>
                    <tr>
                      <th>
                        <Link
                          to="#"
                          onClick={(e) => {
                            sortHandler(e, 'course_id');
                          }}>
                          Id
                          <img src={require('../../assets/images/sort.png')} alt="sort" />
                        </Link>
                      </th>
                      <th>
                        <Link
                          to="#"
                          onClick={(e) => {
                            sortHandler(e, 'course_name');
                          }}>
                          Course Name
                          <img src={require('../../assets/images/sort.png')} alt="sort" />
                        </Link>
                      </th>
                      <th>
                        <Select
                          className="basic-single"
                          classNamePrefix="select"
                          isDisabled={false}
                          isLoading={false}
                          isClearable={false}
                          isRtl={false}
                          isSearchable={true}
                          name="dept_id"
                          onChange={(e) => {
                            setDepartmentId(e);
                            setSearchedData({ ...searchedData, ['dept_id']: e.id });
                          }}
                          options={departmentList}
                          value={departmentId}
                        />
                      </th>
                      <th>
                        <Link
                          to="#"
                          onClick={(e) => {
                            sortHandler(e, 'status');
                          }}>
                          Status
                          <img src={require('../../assets/images/sort.png')} alt="sort" />
                        </Link>
                      </th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="search-table">
                      <td>
                        <Form.Control
                          type="text"
                          onChange={(e) => {
                            searchChangeHandler(e);
                          }}
                          name="course_id"
                          placeholder="Id"
                        />
                      </td>
                      <td>
                        <Form.Control
                          type="text"
                          onChange={(e) => {
                            searchChangeHandler(e);
                          }}
                          name="course_name"
                          placeholder="Course Name"
                        />
                      </td>
                      <td>
                        <Form.Control type="text" name="dept_id" placeholder="Department" />
                      </td>
                      <td></td>
                      <td className="serac-btns text-center" colSpan={2}>
                        <Button className="main-btn-grn" onClick={searchHandler}>
                          Search
                        </Button>
                        <Button className="main-btn-red ms-2" onClick={resetHandler}>
                          Reset
                        </Button>
                      </td>
                    </tr>
                    {list && list?.viewlist?.length ? (
                      list?.viewlist?.map((data, index) => (
                        <tr key={index}>
                          <td>{data?.course_id}</td>
                          <td>{data?.course_name}</td>
                          <td>{data?.name}</td>
                          <td>{data?.status == 1 ? 'Active' : 'InActive'}</td>
                          <td>
                            <Form.Select
                              aria-label="Default select example"
                              onChange={(e) => {
                                stateHandler(e.target.value, data?.course_id);
                              }}>
                              <option>Action</option>
                              <option value="edit">Edit</option>
                              <option value="inactive">Inactive</option>
                            </Form.Select>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr className="text-center">
                        <td colSpan={9}>
                          <NoDataFound />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
                {/* {currentPage > 0 ? (
                  <div className="react-page d-flex">
                    <ReactPaginate
                      previousLabel={currentPage > 0 ? '<' : ''}
                      nextLabel={response > 0 ? '>' : ''}
                      onPageChange={handlePageClick}
                      pageCount={response / 10}
                    />
                  </div>
                ) : (
                  ''
                )} */}
              </div>
            </div>
          </div>
        </Container>
      </div>
      {loading ? <Loader /> : ''}
    </>
  );
}
