import { useEffect, useRef, useState } from 'react';
import { Alert, Button, Container, Modal } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Table from 'react-bootstrap/Table';
import { Link, useNavigate } from 'react-router-dom';
import NoDataFound from '../../components/commoncomponents/NoDataFound';
import Loader from '../../components/commoncomponents/loader/loader-large';
import useDocumentTitle from '../../globals/useDocumentTitle';
import * as adminServices from '../../services/AuthServices';
import showNotification from '../../services/NotificationService';
import FilterBy from './FilterBy';

let intervalId;

export default function ListFaculty() {
  useDocumentTitle('Manage Faculty & Alumni | AlmaBay');
  const navigate = useNavigate();
  const childRef = useRef();
  const [list, setList] = useState([]);
  const [response, setResponse] = useState('');
  const [loading, setLoading] = useState(false);
  const [sortType, setSortType] = useState('DESC');
  const [sortField, setSortField] = useState('user_id');
  const [chekcedId, setCheckedId] = useState([]);
  const [allId, setAllId] = useState([]);
  const [showalert, setShowAlert] = useState(false);
  const [successAlert, setSusessAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState('');
  const [searchedData, setSearchData] = useState({
    user_id: '',
    first_name: '',
    email: '',
    date_created: '',
    date_modified: '',
    status: '',
    order: ''
  });

  const [currentPage, setCurrentPage] = useState(0);

  // for filter
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [isExportDialogOpen, setIsExportDialogOpen] = useState(false);
  const seq = ['email', 'gender', 'dob', 'institute_name', 'department_name', 'course_name'];

  const [selectedExportParameter, setSelectedExportParameter] = useState([]);
  const exportParmetersSelectionHandler = (id) => {
    if (selectedExportParameter.includes(id)) {
      let result = selectedExportParameter.filter((param) => param != id);
      setSelectedExportParameter(result);
    } else {
      setSelectedExportParameter(selectedExportParameter.concat(id));
    }
  };

  useEffect(() => {
    apiCall();
  }, []);

  const apiCall = (
    page = 1,
    sortfield = 'user_id',
    order = 'DESC',
    user_id = '',
    first_name = '',
    email = '',
    status = '',
    date_created = ''
  ) => {
    setLoading(true);
    setCurrentPage(page);
    adminServices
      .getfacultyList(page, sortfield, order, user_id, first_name, email, status, date_created)
      .then((res) => {
        setLoading(false);
        if (res?.status === 200) {
          setList(res?.data?.detail?.users);
          setAllId(res?.data?.detail?.users?.map((data) => data?.user_id));
          setResponse(res?.data?.detail?.total_users);
        }
      });
  };

  const onChangeSearch = (e) => {
    const { name, value } = e.target;
    setSearchData({ ...searchedData, [name]: value });
  };

  const handlePageClick = (a) => {
    setCheckedId([]);
    let page = a?.selected + 1;
    apiCall(
      page,
      sortField,
      sortType,
      searchedData.user_id,
      searchedData.first_name,
      searchedData.email,
      searchedData.status
    );
  };

  const searchHandler = (e) => {
    e.preventDefault();
    apiCall(
      1,
      sortField,
      sortType,
      searchedData.user_id,
      searchedData.first_name,
      searchedData.email,
      searchedData.status
    );
    setCurrentPage(1)
  };

  const sortHandler = (e, type) => {
    e.preventDefault();
    let currentSort = sortType === 'ASC' ? 'DESC' : 'ASC';
    setSortType(currentSort);
    setSortField(type);
    apiCall(
      currentPage,
      type,
      currentSort,
      searchedData.user_id,
      searchedData.first_name,
      searchedData.email,
      searchedData.status
    );
  };

  const handleExportDialogClose = () => {
    selectedExportParameter.sort(function (a, b) {
      return seq.indexOf(a) - seq.indexOf(b);
    });

    let body = {
      exportSelectedColumns: selectedExportParameter?.toString(),
      id: chekcedId?.toString()
    };
    if (chekcedId?.length > 0 && selectedExportParameter?.length > 0) {
      adminServices.exportUser(body).then((res) => {
        if (res?.status === 200) {
          setIsExportDialogOpen(false);
          showNotification('success', res.data.message);
          // window.open(process.env.REACT_APP_API_URL + res?.data?.filepath);
        }
      });
    } else if (chekcedId?.length == 0) {
      showNotification('danger', 'Please select atleast one user to export.');
    } else {
      showNotification('danger', 'Please select atleast one column to export.');
    }
  };

  const exportParmeters = [
    {
      id: 'course_name',
      label: 'Course Name'
    },
    {
      id: 'department_name',
      label: 'Department Name'
    },
    {
      id: 'dob',
      label: 'Date of Birth'
    },
    {
      id: 'email',
      label: 'Email'
    },
    {
      id: 'gender',
      label: 'Gender'
    },
    {
      id: 'institute_name',
      label: 'Institute'
    }
  ];
  const stateHandler = (value, id) => {
    setLoading(true);
    let body = {
      id: id
    };
    adminServices.deleteUserDetail(body).then((res) => {
      setLoading(false);
      if (res?.status === 200) {
        document.getElementById('select').value = '';
        apiCall();
      }
    });
  };

  const selectHandler = (value, id) => {
    switch (value) {
      case 'edit':
        navigate('/admin/users/create', { state: id });
        break;
      case 'view':
        navigate(`/admin/users/view/${id}`);
        break;
      case '0':
        stateHandler(value, id);
        break;
      case '2':
        stateHandler(value, id);
        break;
      default:
        break;
    }
  };

  const handleMultipleCheckBox = (id) => {
    if (chekcedId.includes(id)) {
      let filterId = chekcedId.filter((data) => data !== id);
      setCheckedId(filterId);
    } else {
      setCheckedId([...chekcedId, id]);
    }
  };

  const handleSelectAll = (e) => {
    if (e.target.checked === true) {
      setCheckedId(allId);
    } else {
      setCheckedId([]);
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setSusessAlert(false);
    }, 3000);

    return () => {
      clearTimeout(timer);
    };
  }, [successAlert]);

  const globalActionHandler = (e) => {
    const confirm = window.confirm('Do you want to continue?');

    if (confirm) {
      if (chekcedId.length !== 0) {
        let body = {
          id: chekcedId.join(),
          status: e.target.value
        };

        adminServices.globalStateChange(body).then((res) => {
          if (res?.status === 200) {
            setCheckedId([]);
            document.getElementById('global').value = '';
            setAlertMsg(res?.data?.message);
            setSusessAlert(true);
            apiCall();
          }
        });
      } else {
        setShowAlert(true);
        alertHandler();
      }
    }
  };

  const alertHandler = () => {
    clearInterval(intervalId);
    intervalId = setInterval(function () {
      setShowAlert(false);
    }, 5000);
  };

  /**
   * get filter data -api
   */
  const globalFilter = (data) => {
    let body = {
      type: data
    };
    adminServices.filterByFaculty(body).then((res) => {
      if (res?.status === 200) {
        setList(res?.data?.detail?.users);
        setResponse(res?.data?.detail?.total_users);
        setShow(false);
      }
    });
  };

  const resetHandler = (e) => {
    e.preventDefault();
    setSearchData({
      user_id: '',
      first_name: '',
      email: '',
      date_created: '',
      date_modified: '',
      status: '',
      order: ''
    });

    apiCall();
  };
  return (
    <>
      <div className="dash-main-content">
        <Container fluid>
          <div className="dash-page-content">
            <div className="dash-page-title">
              <Row>
                <Col xs={12} sm={12} md={12}>
                  <div className="dash-title-bar d-flex align-items-center justify-content-between">
                    <div className="title-lft d-flex align-items-center">
                      <div className="dash-icn">
                        <i className="fa fa-users"></i>
                      </div>
                      <div className="dash-title-dis">
                        <h5>
                          Faculty <span>Listing</span>
                        </h5>
                        {list?.length !== 0 && (
                          <h5>
                            Total Record(s): <span>{response}</span>
                          </h5>
                        )}
                      </div>
                    </div>

                    <div className="title-rt-btn d-flex align-items-center">
                      <Link className="green_button_admin mx-2" to="/admin/users/create?type=faculty">
                        Add Faculty
                      </Link>
                      <Link
                        className="green_button_admin mx-2"
                        to="#0"
                        onClick={() => {
                          setIsExportDialogOpen(true);
                          setSelectedExportParameter([]);
                        }}>
                        Export Faculty
                      </Link>
                      <div className="head-instite-sec">
                        <Form.Select
                          aria-label="Default select example"
                          id="global"
                          onChange={(e) => {
                            globalActionHandler(e);
                          }}>
                          <option value="">Choose Action</option>
                          <option value="approved">Approved</option>
                          <option value="pending">Pending</option>
                          <option value="rejected">Rejected</option>
                          <option value="delete">Delete</option>
                        </Form.Select>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="dash-content-card">
              <Alert variant={'danger'} className={`${showalert ? '' : 'd-none'}`}>
                Please Select Any Record
              </Alert>
              <Alert variant={'success'} className={`${successAlert ? '' : 'd-none'}`}>
                {alertMsg}
              </Alert>
              <div className="table-responsive">
                <Table className="custom-table-data" striped bordered hover>
                  <thead>
                    <tr>
                      <th>
                        <Form.Check
                          type="checkbox"
                          id="checkbox"
                          name="checkall"
                          checked={allId?.length ? allId?.length === chekcedId?.length : false}
                          onChange={handleSelectAll}
                          label=""
                        />
                      </th>
                      <th>
                        <Link
                          to="#"
                          onClick={(e) => {
                            e.preventDefault();
                            sortHandler(e, 'user_id');
                          }}>
                          Id
                          <img src={require('../../assets/images/sort.png')} alt="sort" />
                        </Link>
                      </th>
                      <th>
                        <Link
                          to="#"
                          onClick={(e) => {
                            sortHandler(e, 'first_name');
                          }}>
                          Name
                          <img src={require('../../assets/images/sort.png')} alt="sort" />
                        </Link>
                      </th>
                      <th>
                        <Link
                          to="#"
                          onClick={(e) => {
                            sortHandler(e, 'email');
                          }}>
                          Email
                          <img src={require('../../assets/images/sort.png')} alt="sort" />
                        </Link>
                      </th>
                      <th>
                        <Link to="#">Registration Date</Link>
                      </th>
                      <th>
                        <Link
                          to="#"
                          onClick={(e) => {
                            sortHandler(e, 'status');
                          }}>
                          Status
                          <img src={require('../../assets/images/sort.png')} alt="sort" />
                        </Link>
                      </th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="search-table">
                      <td></td>
                      <td>
                        <Form.Control
                          type="text"
                          value={searchedData.user_id}
                          name="user_id"
                          placeholder="Id"
                          onChange={(e) => {
                            onChangeSearch(e);
                          }}
                        />
                      </td>
                      <td>
                        <Form.Control
                          type="text"
                          value={searchedData.first_name}
                          name="first_name"
                          placeholder="Name"
                          onChange={(e) => {
                            onChangeSearch(e);
                          }}
                        />
                      </td>
                      <td>
                        <Form.Control
                          type="text"
                          value={searchedData.email}
                          name="email"
                          placeholder="email"
                          onChange={(e) => {
                            onChangeSearch(e);
                          }}
                        />
                      </td>
                      <td></td>
                      <td>
                        <Form.Select
                          aria-label="Default select example"
                          name="status"
                          value={searchedData?.status}
                          onChange={(e) => {
                            onChangeSearch(e);
                          }}>
                          <option value="">Select</option>
                          <option value={'pending'}>Pending</option>
                          <option value={'approved'}>Approved</option>
                          <option value={'rejected'}>Rejected</option>
                        </Form.Select>
                      </td>
                      <td className="serac-btns" colSpan={2}>
                        <button
                          type="button"
                          className="green_button_admin"
                          onClick={(e) => {
                            searchHandler(e);
                          }}>
                          Search
                        </button>
                        {/* <Button
                          className="main-btn-red ms-2"
                          onClick={(e) => {
                            resetHandler(e);
                          }}>
                          Reset
                        </Button> */}
                      </td>
                    </tr>
                    {list && list?.length ? (
                      list?.map((data, index) => {
                        return (
                          <tr key={index}>
                            <td>
                              {' '}
                              <Form.Check
                                type="checkbox"
                                name="id"
                                id={`id${data?.user_id}`}
                                checked={chekcedId?.includes(data?.user_id)}
                                onChange={() => handleMultipleCheckBox(data?.user_id)}
                                label=""
                              />
                            </td>
                            <td>{data?.user_id ? data?.user_id : 'NA'}</td>
                            <td>{data?.name ? data?.name : 'NA'}</td>
                            <td>{data?.email ? data?.email : 'NA'}</td>
                            <td>{data?.regOn ? data?.regOn : 'NA'}</td>
                            <td>{data?.status ? data?.status : 'NA'}</td>
                            <td>
                              <Form.Select
                                aria-label="Default select example"
                                id="select"
                                value=""
                                onChange={(e) => {
                                  selectHandler(e.target.value, data?.user_id);
                                }}>
                                <option value="">Action</option>
                                <option value="edit">Edit</option>
                                <option value="view">View</option>
                                <option
                                  value="2"
                                // onClick={() => deleteHandler(data?.user_id)}
                                >
                                  Delete
                                </option>
                              </Form.Select>
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan={8}>
                          <NoDataFound />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
                {/* <Pagination>{items}</Pagination> */}
              </div>
              <div className="user-lst-filter">
                <Button className="usr-filter-btn" onClick={handleShow}>
                  <img src={require('../../assets/images/filter.png')} alt="filterbtn" />
                </Button>
                <Button
                  className="filter-reset-btn"
                  onClick={(e) => {
                    resetHandler(e);
                  }}>
                  <img src={require('../../assets/images/reset.png')} alt="chatuser" />
                </Button>
              </div>
            </div>
            {/* filter for user list */}
            <FilterBy
              handleClose={handleClose}
              show={show}
              type="faculty"
              ref={childRef}
              globalFilter={globalFilter}
            />
            {/* end */}
          </div>
        </Container>
      </div>
      {/* Modal Start */}
      <Modal
        show={isExportDialogOpen}
        onHide={() => setIsExportDialogOpen(false)}
        contentClassName="export-model">
        <Modal.Header closeButton onClick={() => setIsExportDialogOpen(false)}>
          <Modal.Title>Select Column</Modal.Title>
        </Modal.Header>
        <Modal.Body className="export-body">
          {exportParmeters?.map((paramName, index) => {
            return (
              <Form.Check
                type="checkbox"
                id={`checkbox${index}`}
                key={index}
                name={paramName.id}
                checked={selectedExportParameter.includes(paramName.id)}
                label={paramName.label}
                className="export-model-label"
                onChange={(e) => exportParmetersSelectionHandler(e.target.name)}
              />
            );
          })}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={handleExportDialogClose}
            className="export-submit-btn">
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
      {loading ? <Loader /> : <></>}
    </>
  );
}
