import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Dropdown from 'react-bootstrap/Dropdown';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import { Link } from 'react-router-dom';
import HeaderLogin from '../../commoncomponents/HeaderLogin';
import TopTabbings from './TopTabbings';

import { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import * as APISERVICES from '../../../services/MentorArticlesServices';

import { OverlayTrigger, Popover } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import AsyncSelect from 'react-select/async';
import { CLOSED_CH, OPEN_CH, SECRET_CH } from '../../../globals/constants';
import { leaveAdminChapter } from '../../../globals/helper';
import useDocumentTitle from '../../../globals/useDocumentTitle';
import { addChaptersSocial, getPeopleForAddChapters } from '../../../services/AuthServices';
import showNotification from '../../../services/NotificationService';
import {
  discoverChapters,
  leaveJoinedChapter,
  socialChapterJoin
} from '../../../services/SocialServices';
import { loadJObLocationOption } from '../../../super-admin/AdminHelper';
import { MiniFooter } from '../../MiniFooter';
import ArticalBlogs from '../../commoncomponents/newsfeeds/ArticalBlogs';
import CampusNews from '../../commoncomponents/newsfeeds/CampusNews';
import FollowCompany from '../../commoncomponents/newsfeeds/FollowCompany';
import PeopleKnow from '../../commoncomponents/newsfeeds/PeopleKnow';
import Upcoming from '../../commoncomponents/newsfeeds/Upcoming';
import WhatsNew from '../../commoncomponents/newsfeeds/WhatsNew';
import { CompanySimmer } from '../../commoncomponents/skelton/allskelton';

function ChaptersDiscover() {
  /* eslint-disable */
  useDocumentTitle('List of Chapters | AlmaBay');
  const [isActive, setIsActive] = useState(false);
  const handleClick = (event) => {
    setIsActive((current) => !current);
    setIsActive1(false);
  };
  const [loading, setLoading] = useState(false);
  const [isActive1, setIsActive1] = useState(false);
  const handleClick2 = (event) => {
    setIsActive1((current) => !current);
    setIsActive(false);
  };

  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
    setClicked(false);
  };
  const handleShow = () => setShow(true);

  const [isClearable, setIsClearable] = useState(true);
  const [isSearchable, setIsSearchable] = useState(true);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRtl, setIsRtl] = useState(false);
  const [isActive2, setIsActive2] = useState(false);
  const [selected2, setIsSelected2] = useState('Choose one');
  const [chapterName, setChapterName] = useState('');
  const [chapter, setChapter] = useState([]);
  const [peopleName, setPeopleName] = useState([]);
  const [peopleNameSearch, setPeopleNameSearch] = useState('');
  const [privacy, setPrivacy] = useState('');
  const [clicked, setClicked] = useState(false);
  const [searchClicked, setSearchClicked] = useState(false);
  const [chapterDiscover, setChapterDiscover] = useState([]);
  const [topSilder, setTopSilder] = useState([]);
  const [chapterInstitute, setChapterInstitute] = useState([]);
  const [showData, setShowData] = useState(false);
  const navigate = useNavigate();
  const [chapterJoined, setChapterJoined] = useState([]);
  const [suggestion, setSuggestion] = useState([]);
  const [isScrollable, setIsScrollable] = useState(false);
  const [offset, setOffSet] = useState(6);
  const [shouldLoadMoreButtonVisible, setShouldLoadMoreButtonVisible] = useState(true);
  const [discoverdList, setDiscoveredList] = useState([]);
  const [locationSearch, setLocationSearch] = useState(null);
  const [jobLocation, setJobLocation] = useState('');
  const [manageChapterTotal, setManageChapterTotal] = useState(null);
  const [totaljoined, setTotalJoined] = useState('');
  const [isJoinScrollable, setIsJoinScrollable] = useState(false);
  const [instiData, setInstiData] = useState('');
  const [inputMsg, setInputMsg] = useState('Please enter atleast 2 characters');
  const [loadInput, setLoadInput] = useState(false);
  const [isClikedId, setIsClickedId] = useState('');
  const [likedLoader, setLikedLoader] = useState(false);
  const [discoverLoader, setDiscoverLoader] = useState(false);

  useEffect(() => {
    getChapterManage();
    getTopSilder();
    getJoinedChapterList();
  }, []);

  const option = [
    {
      label: 'Ram',
      value: 0
    },
    {
      label: 'Alma',
      value: 1
    }
  ];

  const validate = () => {
    let errors = {};
    if (chapterName === '') {
      errors.chapterName = 'Chapter name is Required.';
    }
    if (jobLocation === '' || jobLocation === null) {
      errors.jobLocation = 'Location is Required.';
    }
    return errors;
  };

  const getJoinedChapterList = (search, offset, istrue) => {
    APISERVICES.chapterJoinedChapterList(search, offset, istrue).then((res) => {
      if (res?.status === 200) {
        if (offset) {
          setChapterJoined([...chapterJoined, ...res?.data?.data] ?? []);
        } else {
          setChapterJoined(res?.data?.data ?? []);
        }
        setTotalJoined(res?.data?.totalChapaters);
      }
    });
  };

  const addChapter = (e) => {
    e.preventDefault();
    setClicked(true);
    if (!Object.keys(validate()).length) {
      let formData = new FormData();
      formData.append('chapter_name', chapterName);
      formData.append(
        'friendid',
        peopleName
          ?.map((name) => name?.value)
          .join(',')
          .toString()
      );
      formData.append('location', jobLocation.id);
      let prv = privacy == 0 ? 'open' : privacy == 1 ? 'closed' : 'secret';
      formData.append('chapter_privacy', prv);
      addChaptersSocial(formData).then((res) => {
        if (res?.status == 200) {
          showNotification('success', 'Chapters added successfully');
          getChapterManage();
          setChapterName(''); // reset fields
          setPeopleName([]);
          setPrivacy('');
          setShow(false); // close the dialog
          setClicked(false);
          navigate(`/user/chapter-detail/${res?.data?.chapter_data?.encrypted_id}`);
        }
      });
    }
  };

  const handleSelectPrivacy = (e) => {
    setPrivacy(e);
    let returndiv = document.getElementById(`role${e}`);
    document.getElementById('replace').innerHTML = returndiv.innerHTML;
  };

  const getChapterManage = (search, offSet, isTrue) => {
    if (isTrue == true) {
      setLoading(false);
    } else {
      setLoading(true);
    }

    APISERVICES.getChapter(search, offSet, isTrue).then((res) => {
      setLoading(false);
      if (res?.status === 200) {
        if (isTrue == true) {
          setChapter([...chapter, ...res?.data?.data] ?? []);
        } else {
          setChapter(res?.data?.data ?? []);
        }
        if (res?.data?.data?.length < 6 || res?.data?.data / FollowCompany == 6) {
          setShouldLoadMoreButtonVisible(false);
        }
        setManageChapterTotal(res.data?.total);
      } else {
        setShouldLoadMoreButtonVisible(false);
        setChapter([]);
      }
    });
  };

  const handleLoadMore = () => {
    setIsScrollable(true);
    setOffSet(chapter?.length + 6);
    getChapterManage('', chapter?.length, true);
  };

  const loadPeopleOptions = async (inputValue) => {
    if (inputValue.length >= 2) {
      setLoadInput(true);
      return getPeopleForAddChapters(inputValue).then((res) => {
        setLoadInput(false);
        if (res?.status === 200) {
          let tempArr = [];
          if (res?.data?.data) {
            let data = res?.data.data;
            data?.map((data) => {
              tempArr.push({
                id: data?.id,
                label: data?.name,
                value: data?.id
              });
            });
          }
          if (res?.data?.data == undefined) {
            setInputMsg('no matches found');
          }
          return tempArr;
        }
      });
    }
  };

  const getChapterDiscover = (type) => {
    type == 'Joined' ? setDiscoverLoader(false) : setDiscoverLoader(true);
    discoverChapters().then((res) => {
      setDiscoverLoader(false);
      if (res?.status === 200) {
        setDiscoveredList(res?.data?.data || []);
      }
    });
  };

  const getTopSilder = () => {
    setLoading(true);
    APISERVICES.chapterTopSlider().then((res) => {
      setLoading(false);
      if (res?.status === 200) {
        setTopSilder(res?.data?.data);
      }
    });
  };

  const handleJoin = (discoverSlider) => {
    setInstiData(discoverSlider);
    APISERVICES.similierChapterInstitute(discoverSlider?.institute_id).then((res) => {
      if (res?.status === 200) {
        setChapterInstitute(res?.data?.data);
        setShowData(true);
      }
    });
  };

  const handleJoinChapter = (e, id) => {
    e.preventDefault();
    let body = {
      following_id: id
    };
    let formData = new FormData();
    for (let i in body) {
      formData.append(`${i}`, body[i]);
    }
    setLoading(true);
    socialChapterJoin(formData).then((res) => {
      setLoading(false);
      getChapterDiscover('Joined');
      if (res?.status === 200) {
        showNotification('success', res?.data?.message);
      } else {
        showNotification('success', res?.data?.message);
      }
    });
  };

  const leaveJoinedChapters = (e, id) => {
    if (confirm('Do you want to leave from the Chapter?') == true) e.preventDefault();
    leaveJoinedChapter(id).then((res) => {
      if (res?.status === 200) {
        showNotification('success', res?.data?.message);
        getJoinedChapterList();
      } else {
        showNotification('success', res?.data?.message);
      }
    });
  };

  const renderPopover = (props) => {
    let popOverData = props?.popper?.state?.options?.data;

    return (
      <Popover className="group-loader-card" id={`popover-basic`} {...props}>
        <Popover.Body>
          <div className="loader-tooltip ">
            <div
              className="loader-cover"
              style={{ position: 'relative', padding: 0, margin: 0, top: 0, left: 0 }}>
              {}
              <img src={popOverData?.avatar_path} alt="img" className="loader-image" />
            </div>
            <div className="page-loader-head-sec chapter-loader-hd d-flex align-items-top p-3">
              <div className="page-loader-hd">
                <h3 className="mt-3">{popOverData?.name}</h3>
                <div className="loader-link-sec">
                  <p style={{ textTransform: 'capitalize' }} className="me-2">
                    <i className="fas fa-globe-asia me-1"></i>
                    {popOverData?.chapter_privacy} chapter
                  </p>

                  {popOverData?.count !== 0 &&
                    (popOverData?.count == 1 ? (
                      <>
                        {' '}
                        <p>{popOverData?.count} member</p>
                      </>
                    ) : (
                      <p>{popOverData?.count} members</p>
                    ))}
                </div>
                <button className="btn-joined">Joined</button>
              </div>
            </div>
          </div>
        </Popover.Body>
      </Popover>
    );
  };

  return (
    <>
      <HeaderLogin />
      <div className="header-lnk-feeds">
        <div className="container">
          <TopTabbings state={'social'} />
        </div>
      </div>
      <section className="feed-sec bg-grey">
        <Tab.Container id="left-tabs-example" defaultActiveKey="first">
          <div className="company-tab-top bg-white mb-3">
            <div className="container">
              <Row className="align-items-center">
                <Col sm={6} xs={7} md={6}>
                  <div className="d-flex align-items-center">
                    <Nav variant="pills">
                      <Nav.Item>
                        <Nav.Link
                          onClick={() => {
                            getChapterManage();
                            getJoinedChapterList();
                          }}
                          eventKey="first">
                          Chapters
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link
                          onClick={async () => {
                            setDiscoverLoader(true);
                            try {
                              const response = await discoverChapters();
                              if (response?.status === 200) {
                                setDiscoverLoader(false);
                                setDiscoveredList(response?.data?.data);
                              }
                            } catch (error) {
                              console.error(error);
                            }
                          }}
                          eventKey="second">
                          Discover
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </div>
                </Col>
                <Col sm={6} xs={5} md={6}>
                  <div className="text-end">
                    <Button className="create-group-btn" onClick={handleShow}>
                      + Create Chapter
                    </Button>
                  </div>
                </Col>
              </Row>
            </div>
          </div>

          <div className="container">
            <Row>
              <Col xs={12} lg={9}>
                <Tab.Content>
                  <Tab.Pane eventKey="first">
                    <div className="company-lft-dis mb-3">
                      <div className="company-lst bg-white p-4">
                        <div className="company-hd flex-wrap d-flex align-items-center justify-content-between mb-4">
                          <h4>CHAPTERS YOU MANAGE</h4>

                          <div
                            className={isActive1 ? 'comp-hd-search blk-search' : 'comp-hd-search'}>
                            <Link onClick={handleClick2} to="#">
                              <i className="fas fa-search"></i>
                            </Link>
                            <Form.Group className="search-card" controlId="formBasicSearch">
                              <Form.Control
                                type="search"
                                placeholder="Search Groups"
                                name="chapter_name"
                                onChange={(e) => {
                                  getChapterManage(e.target.value, '', '');
                                }}
                              />
                            </Form.Group>
                          </div>
                        </div>
                        {loading ? (
                          <>
                            {' '}
                            <div className="company-follow">
                              {Array.from(Array(6), () => {
                                return <CompanySimmer />;
                              })}
                            </div>
                          </>
                        ) : (
                          <>
                            {' '}
                            <div
                              className={
                                isScrollable
                                  ? 'company-follow compnay-card-scroll'
                                  : 'company-follow'
                              }>
                              {chapter && chapter?.length ? (
                                chapter?.map((data, index) => {
                                  return (
                                    <div
                                      className="company-follow-card d-flex justify-content-between"
                                      key={index}>
                                      <div
                                        className="d-flex align-items-top"
                                        onClick={() =>
                                          window.open(
                                            `/user/chapter-detail/${data?.encrypted_id}`,
                                            '_blank'
                                          )
                                        }>
                                        <div className="company-icn me-3">
                                          <Link to="#">
                                            <OverlayTrigger
                                              trigger={['hover', 'focus']}
                                              placement="auto"
                                              delay={{ show: 250, hide: 400 }}
                                              data={data}
                                              popperConfig={{
                                                data
                                              }}
                                              shouldUpdatePosition={true}
                                              overlay={renderPopover}>
                                              <img src={data?.avatar_path} alt="img" />
                                            </OverlayTrigger>
                                          </Link>
                                        </div>

                                        <div className="company-discr">
                                          <h4>
                                            <Link to="#">{data?.first_name}</Link>
                                          </h4>
                                        </div>
                                      </div>
                                      <Dropdown>
                                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                                          <i className="fas fa-ellipsis-v"></i>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                          <Dropdown.Item
                                            onClick={() => {
                                              navigate(
                                                `/user/chapter-detail/${data?.encrypted_id}?eventKey=fifth`,
                                                {
                                                  state: data?.id
                                                }
                                              );
                                            }}
                                            target="_blank">
                                            Edit Chapter Settings{' '}
                                          </Dropdown.Item>
                                          <Dropdown.Item
                                            href="#/action-2"
                                            onClick={(e) => {
                                              leaveAdminChapter(e, data.id, getChapterManage);
                                            }}>
                                            Leave Chapter
                                          </Dropdown.Item>
                                        </Dropdown.Menu>
                                      </Dropdown>
                                    </div>
                                  );
                                })
                              ) : (
                                <tr className="text-center">Data Not Found</tr>
                              )}
                            </div>
                            {manageChapterTotal != chapter?.length && chapter?.length !== 0 && (
                              <div className="load-more-btn text-center">
                                <Button
                                  variant="secondary"
                                  className="read-more"
                                  onClick={handleLoadMore}>
                                  Load More
                                </Button>
                              </div>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                    <div className="company-lft-dis">
                      <div className="company-lst bg-white p-4">
                        <div className="company-hd flex-wrap d-flex align-items-center justify-content-between mb-4">
                          <h4>CHAPTERS YOU JOINED</h4>
                          <div
                            className={isActive ? 'comp-hd-search blk-search' : 'comp-hd-search'}>
                            <Link onClick={handleClick} to="#">
                              <i className="fas fa-search"></i>
                            </Link>
                            <Form.Group className="search-card" controlId="formBasicEmail">
                              <Form.Control
                                type="search"
                                placeholder="Search Groups"
                                name="chapter_name"
                                onChange={(e) => {
                                  getJoinedChapterList(e.target.value, '', '');
                                }}
                              />
                            </Form.Group>
                          </div>
                        </div>

                        {loading ? (
                          <>
                            {' '}
                            <div className="company-follow">
                              {Array.from(Array(6), () => {
                                return <CompanySimmer />;
                              })}
                            </div>
                          </>
                        ) : (
                          <>
                            {' '}
                            <div
                              className={
                                isJoinScrollable
                                  ? 'company-follow compnay-card-scroll'
                                  : 'company-follow'
                              }>
                              {chapterJoined && chapterJoined?.length ? (
                                chapterJoined?.map((data, index) => {
                                  return (
                                    <div
                                      className="company-follow-card d-flex justify-content-between"
                                      key={index}>
                                      <div
                                        className="d-flex align-items-top"
                                        onClick={() =>
                                          window.open(`/user/chapter-detail/${data?.ID}`, '_blank')
                                        }>
                                        <div className="company-icn me-3">
                                          <Link to="#">
                                            <OverlayTrigger
                                              trigger={['hover', 'focus']}
                                              placement="auto"
                                              delay={{ show: 250, hide: 400 }}
                                              data={data}
                                              popperConfig={{
                                                data
                                              }}
                                              shouldUpdatePosition={true}
                                              overlay={renderPopover}>
                                              <img
                                                src={
                                                  data?.avatar_path
                                                    ? data?.avatar_path
                                                    : require('../../../assets/images/birth6.jpg')
                                                }
                                              />
                                            </OverlayTrigger>
                                          </Link>
                                        </div>
                                        <div className="company-discr">
                                          <h4>
                                            <Link to="#">{data?.name}</Link>
                                          </h4>
                                        </div>
                                      </div>
                                      <Dropdown>
                                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                                          <i className="fas fa-ellipsis-v"></i>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                          <Dropdown.Item
                                            href="#/action-1"
                                            onClick={() => {
                                              navigate(
                                                `/user/chapter-detail/${data?.ID}?eventKey=first`,
                                                {
                                                  state: data?.id
                                                }
                                              );
                                            }}
                                            target="_blank">
                                            Check chapter's posts
                                          </Dropdown.Item>
                                          <Dropdown.Item
                                            href="#/action-2"
                                            onClick={(e) => {
                                              leaveJoinedChapters(e, data.id);
                                            }}>
                                            Leave Chapter
                                          </Dropdown.Item>
                                        </Dropdown.Menu>
                                      </Dropdown>
                                    </div>
                                  );
                                })
                              ) : (
                                <tr className="text-center">Data Not Found</tr>
                              )}
                            </div>
                            {totaljoined != chapterJoined?.length &&
                              chapterJoined?.length !== 0 && (
                                <div className="load-more-btn text-center">
                                  <Button
                                    variant="secondary"
                                    className="read-more"
                                    onClick={() => {
                                      setIsJoinScrollable(true);
                                      setOffSet(chapterJoined?.length + 6);

                                      getJoinedChapterList('', chapterJoined?.length, true);
                                    }}>
                                    Load More
                                  </Button>
                                </div>
                              )}
                          </>
                        )}
                      </div>
                    </div>
                  </Tab.Pane>

                  <Tab.Pane eventKey="second">
                    <div className="company-lst bg-white p-4 mb-3">
                      <div className="chapter-slide-card">
                        <Swiper
                          spaceBetween={10}
                          slidesPerView={4}
                          modules={[Navigation]}
                          navigation={true}>
                          {topSilder && topSilder?.length ? (
                            topSilder?.map((discoverSlider) => {
                              return (
                                <SwiperSlide>
                                  <div className="chapter-card">
                                    <Link
                                      onClick={() => {
                                        handleJoin(discoverSlider);
                                      }}>
                                      <img
                                        src={process.env.REACT_APP_API_URL + discoverSlider?.logo}
                                        alt=""
                                      />
                                      <div className="chapter-card">
                                        <div>
                                          <h4>{discoverSlider?.qualification_type}</h4>
                                          <p>{discoverSlider?.name}</p>
                                        </div>
                                      </div>
                                    </Link>
                                  </div>
                                </SwiperSlide>
                              );
                            })
                          ) : (
                            <tr className="text-center">Data Not Found</tr>
                          )}
                        </Swiper>
                      </div>
                    </div>

                    {showData ? (
                      <>
                        {' '}
                        <div className="company-lst bg-white p-4 mb-3">
                          <div className="row mb-3">
                            <div className="col-sm-8">
                              <div className="heading ins-name">{instiData?.name}</div>
                            </div>
                            <div className="col-sm-4 text-right">
                              <span
                                className="close-btn d-flex justify-content-end  "
                                onClick={() => setShowData(false)}>
                                <img
                                  src={require('../../../assets/images/close-btn.png')}
                                  alt="close"
                                />
                              </span>
                            </div>
                          </div>
                          <div className="company-follow">
                            {chapterInstitute && chapterInstitute?.length !== 0 ? (
                              chapterInstitute?.map((data) => {
                                return (
                                  <div
                                    className="company-follow-card d-flex justify-content-between"
                                    key={data?.id}>
                                    <div className="d-flex align-items-top">
                                      <div className="company-icn me-3">
                                        <Link to="#">
                                          <OverlayTrigger
                                            trigger={['hover', 'focus']}
                                            placement="top"
                                            delay={{ show: 250, hide: 400 }}
                                            data={data}
                                            popperConfig={{
                                              data
                                            }}
                                            shouldUpdatePosition={true}
                                            overlay={renderPopover}>
                                            <img src={data?.avatar_path} alt="img" />
                                          </OverlayTrigger>
                                        </Link>
                                      </div>
                                      <div className="company-discr">
                                        <h4>
                                          <Link to="#">{data?.name}</Link>
                                        </h4>
                                        <p>{data?.total_members}</p>
                                      </div>
                                      <div className="company-discr">
                                        <h4>
                                          <Link to="#">{data?.institute_type}</Link>
                                        </h4>
                                        <p>{data?.total_members}</p>
                                      </div>
                                    </div>
                                    <Button
                                      className="follow-btn-grey"
                                      onClick={(e) => handleJoinChapter(e, data?.id)}>
                                      <i className="fas fa-plus me-1"></i>
                                      Join
                                    </Button>
                                  </div>
                                );
                              })
                            ) : (
                              <div className="w-100">
                                <div className="text-center w-100">
                                  No Chapters associated with institute at the moment{' '}
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                    <div className="company-lst bg-white p-4">
                      <div className="company-hd flex-wrap d-flex align-items-center justify-content-between mb-4">
                        <h4>MORE SUGGESTIONS</h4>
                      </div>
                      {discoverLoader ? (
                        <div className="company-follow">
                          {Array.from(Array(6), () => {
                            return <CompanySimmer />;
                          })}
                        </div>
                      ) : (
                        <>
                          {' '}
                          <div className="company-follow">
                            {discoverdList && discoverdList?.length ? (
                              discoverdList?.map((chapterCover) => {
                                return (
                                  <div
                                    className="company-follow-card d-flex justify-content-between"
                                    key={chapterCover?.id}>
                                    <div className="d-flex align-items-top">
                                      <div className="company-icn me-3">
                                        <Link to="#">
                                          <OverlayTrigger
                                            trigger={['hover', 'focus']}
                                            placement="top"
                                            delay={{ show: 250, hide: 400 }}
                                            data={chapterCover}
                                            popperConfig={{
                                              chapterCover
                                            }}
                                            shouldUpdatePosition={true}
                                            overlay={renderPopover}>
                                            <img src={chapterCover?.avatar_path} alt="img" />
                                          </OverlayTrigger>
                                        </Link>
                                      </div>
                                      <div className="company-discr">
                                        <h4>
                                          <Link to={`/user/chapter-detail/${chapterCover?.ID}`}>
                                            {chapterCover?.name}
                                          </Link>
                                        </h4>
                                        <p>{chapterCover?.total_members}</p>
                                      </div>
                                    </div>
                                    <Button
                                      className="follow-btn-grey"
                                      onClick={(e) => {
                                        handleJoinChapter(e, chapterCover?.id);
                                        setIsClickedId(chapterCover?.id);
                                        setLikedLoader(true);
                                      }}>
                                      {isClikedId == chapterCover?.id && likedLoader ? (
                                        <>
                                          <i className="fa fa-spinner fa-spin"></i>
                                        </>
                                      ) : (
                                        <>
                                          {' '}
                                          <i className="fas fa-plus me-1"></i>
                                          Join
                                        </>
                                      )}
                                    </Button>
                                  </div>
                                );
                              })
                            ) : (
                              <tr className="text-center">Data Not Found</tr>
                            )}
                          </div>
                        </>
                      )}
                    </div>
                  </Tab.Pane>
                </Tab.Content>
              </Col>
              <Col xs={12} lg={3} md={12}>
                <div>
                  <PeopleKnow />
                </div>
                {/* <div>
                  <WorkAnniversary />
                </div> */}
                <div>
                  <FollowCompany />
                </div>
                <div>
                  <CampusNews />
                </div>
                {/* <div>
                  <CampusnewsText />
                </div> */}
                <div>
                  <ArticalBlogs />
                </div>
                <div>
                  <WhatsNew />
                </div>
                <div>
                  <Upcoming />
                </div>
                <MiniFooter />
              </Col>
            </Row>
          </div>
        </Tab.Container>
      </section>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        className="create-group-modal">
        <Modal.Header closeButton>
          <Modal.Title>Create New Chapter</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="formBasicName">
              <Form.Label>Name your chapter</Form.Label>
              <Form.Control
                type="text"
                value={chapterName}
                placeholder="Enter Chapter name"
                onChange={(e) => setChapterName(e.target.value)}
                autoComplete="off"
              />
              <div className={'danger-frm'}>{clicked && validate().chapterName}</div>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Add some people</Form.Label>

              <AsyncSelect
                cacheOptions
                isMulti
                className="basic-single"
                classNamePrefix="select"
                isDisabled={isDisabled}
                isLoading={isLoading}
                isClearable={isClearable}
                isRtl={isRtl}
                isSearchable={isSearchable}
                name="colordscs"
                placeholder="Enter names or email addresses..."
                options={option}
                onChange={setPeopleName}
                defaultOptions
                value={peopleName}
                getOptionLabel={(e) => e.label}
                getOptionValue={(e) => e.value}
                loadOptions={loadPeopleOptions}
                onInputChange={(newValue) => {
                  setPeopleNameSearch(newValue);
                  newValue?.length <= 2 && setInputMsg('Please enter atleast 2 characters');
                }}
                noOptionsMessage={() => inputMsg}
                components={{
                  DropdownIndicator: () => null,
                  IndicatorSeparator: () => null,
                  LoadingIndicator: () => null
                }}
              />
              {loadInput && (
                <div
                  style={{
                    position: 'absolute',
                    right: '35px',
                    marginTop: '-30px'
                  }}>
                  <i className="fa fa-spinner fa-pulse me-2"></i>
                </div>
              )}
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicName">
              <Form.Label>Location</Form.Label>

              <AsyncSelect
                isClearable={true}
                onMenuClose={() => setLocationSearch(null)}
                value={jobLocation}
                menuIsOpen={locationSearch !== null ? true : false}
                getOptionLabel={(e) => e.label}
                getOptionValue={(e) => e.value}
                loadOptions={loadJObLocationOption}
                onInputChange={(newValue) => {
                  setLocationSearch(newValue);
                  newValue?.length <= 2 && setInputMsg('Please enter atleast 2 characters');
                }}
                noOptionsMessage={() => inputMsg}
                className="basic-single mt-1"
                name="location"
                placeholder={'Enter location'}
                onChange={(e) => {
                  setJobLocation(() => e);
                }}
                components={{
                  DropdownIndicator: () => null,
                  IndicatorSeparator: () => null,
                  LoadingIndicator: () => null
                }}
              />
              <div className={'danger-frm'}>{clicked && validate().jobLocation}</div>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicPrivacy">
              <Form.Label>Select privacy</Form.Label>
              <div className="select-privacy">
                <Dropdown onSelect={handleSelectPrivacy}>
                  <Dropdown.Toggle id="dropdown-basic">
                    <div className="select-privacy-card d-flex align-items-center" id="replace">
                      <span className="privacy-icn">
                        <i className="fas fa-lock"></i>
                      </span>
                      <div className="privacy-dis">
                        <h4>Closed chapter</h4>
                        <p>
                          Anyone can find the chapter and see who’s in it. Only members can see
                          posts.
                        </p>
                      </div>
                    </div>
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item href="#/action-1" eventKey={OPEN_CH}>
                      <div className="select-privacy-card d-flex align-items-center" id={`role0`}>
                        <span className="privacy-icn">
                          <i className="fas fa-globe-americas"></i>
                        </span>
                        <div className="privacy-dis">
                          <h4>Open Chapter</h4>
                          <p>Anyone can see the chapter, its members and their posts.</p>
                        </div>
                      </div>
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-2" eventKey={CLOSED_CH}>
                      <div className="select-privacy-card d-flex align-items-center" id={`role1`}>
                        <span className="privacy-icn">
                          <i className="fas fa-lock"></i>
                        </span>
                        <div className="privacy-dis">
                          <h4>Closed chapter</h4>
                          <p>
                            Anyone can find the chapter and see who’s in it. Only members can see
                            posts.
                          </p>
                        </div>
                      </div>
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-3" eventKey={SECRET_CH}>
                      <div className="select-privacy-card d-flex align-items-center" id={'role2'}>
                        <span className="privacy-icn">
                          <i className="fas fa-shield-alt"></i>
                        </span>
                        <div className="privacy-dis">
                          <h4>Secret Chapter</h4>
                          <p>Only members can find the chapter and see posts.</p>
                        </div>
                      </div>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </Form.Group>
            <div className="text-end">
              <Button variant="primary" type="button" onClick={addChapter}>
                Create
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ChaptersDiscover;
