/**
@copyright  :   ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
@author     :   Shiv Charan Panjeta 
 
All Rights Reserved.
Proprietary and confidential :  All information contained here in is, and remains
the property of ToXSL Technologies Pvt. Ltd. and it's partners.
Unauthorized copying of this file, via any medium is strictly prohibited.
*/
import { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import { getFestJobDetail } from '../../services/AuthServices';

function JobFestjobDetail() {
  const [jobDetail, setJobDetail] = useState('');
  const location = useLocation();
  const navigate = useNavigate();
  let id = location?.state;

  useEffect(() => {
    if (id) {
      getDetail(id);
    }
  }, []);

  const getDetail = (id) => {
    getFestJobDetail(id).then((res) => {
      if (res?.status === 200) {
        const data = res?.data?.data;
        setJobDetail(data);
      }
    });
  };
  return (
    <div className="dash-main-content">
      <Container fluid>
        <div className="dash-page-content">
          <div className="dash-page-title">
            <Row>
              <Col xs={12} sm={12} md={12}>
                <div className="dash-title-bar d-flex align-items-center justify-content-between">
                  <div className="title-lft d-flex align-items-center">
                    <div className="dash-icn">
                      <i className="fa fa-file"></i>
                    </div>
                    <div className="dash-title-dis">
                      <h5>
                        Details of <span>{jobDetail?.job_title}</span> Job in{' '}
                        <span>{jobDetail?.title}</span>
                      </h5>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <div className="dash-content-card">
            <div className="view_detials_content">
              <div className="mb-3">
                <strong>Job Title:</strong> {jobDetail?.job_title}
              </div>

              <div className="detials_description">
                <strong>Job Description: </strong>
                <p dangerouslySetInnerHTML={{ __html: jobDetail?.job_info }}></p>
              </div>
              <div className="mb-3">
                <strong>Company hiring for: </strong> {jobDetail?.company_name}
              </div>
              <div className="mb-3">
                <strong>Job Fest Name:</strong> {jobDetail?.title}
              </div>
              <div className="mb-3">
                <strong>Open positions:</strong> {jobDetail?.open_positions}
              </div>
              <div className="mb-3">
                <strong>Work experience:</strong> {jobDetail?.min_exp}-{jobDetail?.max_exp}
              </div>
              <div className="mb-3">
                <strong>Location:</strong> {jobDetail?.city}
              </div>

              <div className="mb-3">
                <strong>HR Email:</strong> {jobDetail?.hr_email}
              </div>
              <div className="mb-3">
                <strong>Contact Number:</strong> {jobDetail?.contact_number}
              </div>
              <div className="mb-3">
                <strong>Posted On:</strong> {jobDetail?.created_date}
              </div>
              <div className="mb-3">
                <strong>Status:</strong> {jobDetail?.status == 1 ? 'Enable' : 'Disable'}
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}

export default JobFestjobDetail;
