import { useFormik } from 'formik';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import AsyncSelect from 'react-select/async';
import AsyncCreatableSelect from 'react-select/async-creatable';
import * as yup from 'yup';
import companyDummy from '../../../../assets/images/company-dummy.png';
import instDummy from '../../../../assets/images/institute-dummy.jpg';
import apiBase from '../../../../globals/config';
import { getUser, saveStepFour } from '../../../../services/InstituteServices';
import {
  citiesDropDown,
  getJobCategoriesDropdown,
  getJobIndustriesDropdown,
  getOrganizationDropdown,
  getOrganizationsDropdown,
  getWorkLevelDropdown
} from '../../../../services/UserServices';
import './steps.scss';

function Step4() {
  const { userId } = useParams();
  const navigate = useNavigate();
  const [inputMsg, setInputMsg] = useState('Please enter atleast 2 characters');
  const [loadInput, setLoadInput] = useState(false);
  const [educationDetails, setEducationDetails] = useState([]);
  const [show1, setShow1] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        const response = await getUser(userId);
        if (response.status === 200) {
          setEducationDetails(response.data?.data?.user_profession);
        }
      } catch (error) {
        console.error(error);
      }
    })();
  }, [userId]);

  const {
    values,
    errors,
    touched,
    handleSubmit,
    setFieldValue,
    setFieldTouched,
    handleBlur,
    handleChange,
    resetForm
  } = useFormik({
    initialValues: {
      organizationType: '',
      companyName: '',
      industry: '',
      funcationalArea: '',
      gradeLevel: '',
      position: '',
      address: '',
      startDate: '',
      endDate: '',
      currentlyStydying: false
    },
    validationSchema: yup.object().shape({
      organizationType: yup.object().shape({
        value: yup.string().required('This field is required'),
        label: yup.string().required()
      }),
      companyName: yup.object().shape({
        value: yup.string().required('This field is required'),
        label: yup.string().required()
      }),
      industry: yup.object().shape({
        value: yup.string().required('This field is required'),
        label: yup.string().required()
      }),
      funcationalArea: yup.object().shape({
        value: yup.string().required('This field is required'),
        label: yup.string().required()
      }),
      gradeLevel: yup.object().shape({
        value: yup.string().required('This field is required'),
        label: yup.string().required()
      }),
      position: yup.string().required('This field is required'),
      address: yup.object().shape({
        value: yup.string().required('This field is required'),
        label: yup.string().required()
      }),
      startDate: yup.string().required('This field is required'),
      endDate: yup.string().when('currentlyStydying', {
        is: (value) => !value,
        then: () => yup.string().required('This field is required')
      })
    }),
    onSubmit: async (values) => {
      const payload = new FormData();
      payload.append('user_id', userId);
      payload.append('companyName', values.companyName?.value);
      payload.append('designation', values.position);
      payload.append('from_year', values.startDate?.split('-')[0]);
      payload.append('to_year', values.endDate?.split('-')[0]);
      payload.append('companyLocation', values.address?.value);
      payload.append('from_month', values.startDate?.split('-')[1]);
      payload.append('to_month', values.endDate?.split('-')[1]);
      payload.append('curr_work', values.currentlyStydying);
      payload.append('institute_type', values.organizationType?.value);
      payload.append('industry', values.industry?.value);
      payload.append('level_name', values.gradeLevel?.value);
      payload.append('functional_area', values.funcationalArea?.value);
      try {
        const resp = await saveStepFour(payload);
        if (resp.status === 200) {
          (async () => {
            try {
              const response = await getUser(userId);
              if (response.status === 200) {
                setEducationDetails(response.data?.data?.user_profession);
                setShow1(false);
                resetForm();
              }
            } catch (error) {
              console.error(error);
            }
          })();
        }
      } catch (error) {
        console.error(error);
      }
    }
  });

  const loadOrganizationOptions = async (input) => {
    const resp = await getOrganizationDropdown();
    const arr = await Object.entries(resp.data?.data).map((data) => {
      return { label: data[1], value: data[0] };
    });
    if (input) {
      let newArr = arr.filter((i) => i.label?.toLowerCase()?.includes(input?.toLowerCase()));
      return newArr;
    }
    return arr;
  };

  const laodCompanyOptions = async (inputVal) => {
    if (inputVal.length >= 2) {
      try {
        const res =
          inputVal &&
          (await getOrganizationsDropdown(
            values.organizationType?.value ? values.organizationType?.value : '',
            inputVal
          ));
        setLoadInput(false);
        if (res?.data?.data?.length) {
          let array = await res?.data?.data?.map((data) => {
            return {
              label: data?.id,
              value: data?.name,
              logo: data?.logo
                ? process.env.REACT_APP_API_DOMAIN + '/common/uploads/institute_logo/' + data?.logo
                : instDummy
            };
          });
          return array;
        } else {
          setInputMsg('No match found');
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoadInput(false);
      }
    }
  };

  const loadIndustryOptions = async (input) => {
    const resp = await getJobIndustriesDropdown();
    const arr = await resp.data?.data.map((data) => {
      return { label: data?.name, value: data?.id };
    });
    if (input) {
      let newArr = arr.filter((i) => i.label?.toLowerCase()?.includes(input?.toLowerCase()));
      return newArr;
    }
    return arr;
  };

  const loadGradeOptions = async (input) => {
    const resp = await getWorkLevelDropdown();
    const arr = await resp.data?.data.map((data) => {
      return { label: data?.name, value: data?.id };
    });
    if (input) {
      let newArr = arr.filter((i) => i.label?.toLowerCase()?.includes(input?.toLowerCase()));
      return newArr;
    }
    return arr;
  };

  const loadLocationOptions = async (inputValue) => {
    if (inputValue.length >= 2) {
      try {
        const res = inputValue && (await citiesDropDown(inputValue));
        setLoadInput(false);
        if (res.data?.data?.length) {
          let array = await res?.data?.data?.map((data) => {
            return {
              label: data?.city,
              value: data?.city_id
            };
          });
          return array;
        } else {
          setInputMsg('No match found');
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoadInput(false);
      }
    }
  };

  return (
    <>
      <section className="steps-area step-one-area pt-5 pb-5">
        <div className="row">
          <div className="col-sm-4 col-lg-3 left-area left-side">
            <div className="about-you">
              <h1 className="mb-0">About You</h1>
              <h6>Basic information</h6>
              <p>Your basic information let others find you more easily.</p>
            </div>
          </div>

          <div className="col-sm-8 col-lg-9 right-area">
            <div className="steps-block">
              <ul className="nav nav-tabs">
                <li>
                  <Link to={'/institute-register/step/1/' + userId}>
                    <span className="step-count">Step 1</span>
                    <span className="step-label">General information</span>
                  </Link>
                </li>
                <li>
                  <Link to={'/institute-register/step/2/' + userId}>
                    <span className="step-count">Step 2</span>
                    <span className="step-label">Career Goals</span>
                  </Link>
                </li>
                <li>
                  <Link to={'/institute-register/step/3/' + userId}>
                    <span className="step-count">Step 3</span>
                    <span className="step-label">Educational Information</span>
                  </Link>
                </li>
                <li className="current">
                  <span className="step-count">Step 4</span>
                  <span className="step-label">Professional Information</span>
                </li>
                <li>
                  <Link to={'/institute-register/step/5/' + userId}>
                    <span className="step-count">Step 5</span>
                    <span className="step-label">Upload profile picture</span>
                  </Link>
                </li>
              </ul>
            </div>
            <div id="mentor-profile">
              {educationDetails?.length > 0 &&
                educationDetails?.map((data) => {
                  return (
                    <div
                      className="inner-box data-box registeration-form p-0"
                      id="education-details">
                      <div className="form-inner clearfix custom-tab">
                        <div className="box animated clearfix ng-scope" id="school-112020">
                          <div className="box-content d-flex align-items-center justify-content-between">
                            <div className="pull-left">
                              <h4 className="degree-mention ng-binding ng-scope">
                                {/* {data?.currently_studing_here == 1 &&
                                'Currently Studying in ' + data?.course_name + ' at'}
                              {data?.year_to == 0 &&
                                'Studied ' + data?.course_name + ' at'} */}
                                {data?.designation}
                              </h4>
                              <h5 className="ng-binding ng-scope">{data?.organisation}</h5>

                              <h6>
                                <span className="ng-binding ng-scope">
                                  {/* {data?.currently_studing_here == 1 ? 'PRESENT' : data?.to_year} | */}
                                  {data?.city_name}
                                </span>
                              </h6>
                            </div>
                            <div className="pull-right ng-scope">
                              <img
                                className="logo ng-scope"
                                height={50}
                                width={50}
                                src={
                                  data?.logo !== null
                                    ? apiBase + 'common/uploads/institute_logo/' + data?.logo
                                    : companyDummy
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              <div className="gray-footer clearfix border-0">
                <div className="gray-link float-start">
                  <a
                    style={{
                      cursor: 'pointer'
                    }}
                    onClick={(e) => {
                      e.preventDefault();
                      // resetForm();
                      setShow1(!show1);
                    }}
                    className="add-Workplace education-workspace float-end">
                    + Add a workplace
                  </a>
                </div>
                {show1 ? (
                  <div className="data-box back-transparent p-0">
                    <form>
                      <div className="form-inner clearfix">
                        <div className="form-elements">
                          <div className="row">
                            <div className="col-md-6">
                              <div className="form-group">
                                <div className="form-group margin-none innerB">
                                  <label className="" for="Organization_type">
                                    Organization Type <span className="required-field">*</span>
                                  </label>
                                  <AsyncSelect
                                    defaultOptions
                                    className="basic-single mt-1"
                                    classNamePrefix="select"
                                    isSearchable={true}
                                    placeholder="Select organisation type"
                                    onChange={(e) => {
                                      setFieldValue('organizationType', e);
                                    }}
                                    onBlur={() => {
                                      setFieldTouched('organizationType', true);
                                    }}
                                    loadOptions={loadOrganizationOptions}
                                    name="organizationType"
                                    value={values.organizationType}
                                    components={{
                                      DropdownIndicator: () => null,
                                      IndicatorSeparator: () => null,
                                      LoadingIndicator: () => null
                                    }}
                                  />
                                  <p className="text-danger">
                                    {touched.organizationType && errors.organizationType?.value}
                                  </p>
                                </div>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="form-group">
                                <label for="companyName">
                                  Organisation Name <span className="required-field">*</span>
                                </label>
                                <AsyncCreatableSelect
                                  isSearchable={true}
                                  loadOptions={laodCompanyOptions}
                                  className="basic-single mt-1"
                                  value={values.companyName}
                                  onChange={(e) => {
                                    setFieldValue('companyName', e);
                                  }}
                                  onBlur={() => {
                                    setFieldTouched('companyName', true);
                                  }}
                                  formatOptionLabel={(ins) => {
                                    return (
                                      <div className="ins-option">
                                        <img src={ins.logo ? ins.logo : instDummy} alt="logo" />
                                        <span>{ins?.value}</span>
                                      </div>
                                    );
                                  }}
                                  placeholder="Select your company"
                                  onInputChange={(newValue) => {
                                    newValue?.length <= 2 &&
                                      setInputMsg('Please enter atleast 2 characters');
                                  }}
                                  noOptionsMessage={() => inputMsg}
                                  components={{
                                    DropdownIndicator: () => null,
                                    IndicatorSeparator: () => null,
                                    LoadingIndicator: () => null
                                  }}
                                />
                                <p className="text-danger">
                                  {touched.companyName && errors.companyName?.value}
                                </p>
                                {loadInput && (
                                  <div
                                    style={{
                                      display: 'flex',
                                      flexDirection: 'row-reverse',
                                      margin: 'auto',
                                      marginTop: '-25px'
                                    }}>
                                    <i className="fa fa-spinner fa-pulse me-2"></i>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-md-6">
                              <div className="form-group">
                                <div className="form-group margin-none innerB">
                                  <label className="" for="Organization_type">
                                    Industry <span className="required-field">*</span>
                                  </label>
                                  <AsyncSelect
                                    defaultOptions
                                    className="basic-single mt-1"
                                    classNamePrefix="select"
                                    isSearchable={true}
                                    placeholder="Select industry type"
                                    onChange={(e) => {
                                      setFieldValue('industry', e);
                                    }}
                                    onBlur={() => {
                                      setFieldTouched('industry', true);
                                    }}
                                    loadOptions={loadIndustryOptions}
                                    name="industry"
                                    value={values.industry}
                                    components={{
                                      DropdownIndicator: () => null,
                                      IndicatorSeparator: () => null,
                                      LoadingIndicator: () => null
                                    }}
                                  />
                                  <p className="text-danger">
                                    {touched.industry && errors.industry?.value}
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <div className="form-group margin-none innerB">
                                  <label className="" for="Organization_type">
                                    Department/Function/Stream{' '}
                                    <span className="required-field">*</span>
                                  </label>
                                  <AsyncCreatableSelect
                                    defaultOptions
                                    className="basic-single mt-1"
                                    classNamePrefix="select"
                                    isSearchable={true}
                                    placeholder="Select functional area type"
                                    onChange={(e) => {
                                      setFieldValue('funcationalArea', e);
                                    }}
                                    onBlur={() => {
                                      setFieldTouched('funcationalArea', true);
                                    }}
                                    loadOptions={loadIndustryOptions}
                                    name="funcationalArea"
                                    value={values.funcationalArea}
                                    components={{
                                      DropdownIndicator: () => null,
                                      IndicatorSeparator: () => null,
                                      LoadingIndicator: () => null
                                    }}
                                  />
                                  <p className="text-danger">
                                    {touched.funcationalArea && errors.funcationalArea?.value}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-md-6">
                              <div className="form-group">
                                <label for="companyName">
                                  Grade/Level <span className="required-field">*</span>
                                </label>
                                <AsyncCreatableSelect
                                  defaultOptions
                                  className="basic-single mt-1"
                                  classNamePrefix="select"
                                  isSearchable={true}
                                  placeholder="Select functional area type"
                                  onChange={(e) => {
                                    setFieldValue('gradeLevel', e);
                                  }}
                                  onBlur={() => {
                                    setFieldTouched('gradeLevel', true);
                                  }}
                                  loadOptions={loadGradeOptions}
                                  name="gradeLevel"
                                  value={values.gradeLevel}
                                  components={{
                                    DropdownIndicator: () => null,
                                    IndicatorSeparator: () => null,
                                    LoadingIndicator: () => null
                                  }}
                                />
                                <p className="text-danger">
                                  {touched.gradeLevel && errors.gradeLevel?.value}
                                </p>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label for="designation">
                                  Designation/Position <span className="required-field">*</span>
                                </label>
                                <input
                                  type="text"
                                  name="position"
                                  className="form-control"
                                  placeholder="Enter your designation"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.position}
                                />
                                <p className="text-danger">{touched.position && errors.position}</p>
                              </div>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-md-6">
                              <div className="form-group">
                                <label for="companyLocation">
                                  Location <span className="required-field">*</span>
                                </label>
                                <AsyncSelect
                                  isSearchable={true}
                                  loadOptions={loadLocationOptions}
                                  className="basic-single mt-1"
                                  name="city"
                                  value={values.address}
                                  onChange={(e) => {
                                    setFieldValue('address', e);
                                  }}
                                  onBlur={() => {
                                    setFieldTouched('address', true);
                                  }}
                                  placeholder="Select Current location"
                                  onInputChange={(newValue) => {
                                    newValue?.length <= 2 &&
                                      setInputMsg('Please enter atleast 2 characters');
                                  }}
                                  noOptionsMessage={() => inputMsg}
                                  components={{
                                    DropdownIndicator: () => null,
                                    IndicatorSeparator: () => null,
                                    LoadingIndicator: () => null
                                  }}
                                />
                                <p className="text-danger">
                                  {touched.address && errors.address?.value}
                                </p>
                                {loadInput && (
                                  <div
                                    style={{
                                      display: 'flex',
                                      flexDirection: 'row-reverse',
                                      margin: 'auto',
                                      marginTop: '-25px'
                                    }}>
                                    <i className="fa fa-spinner fa-pulse me-2"></i>
                                  </div>
                                )}
                                <span className="address-icon"></span>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="form-group">
                                <label>
                                  Time Period <span className="required-field">*</span>
                                </label>
                                <div className="row g-0 time-period-input">
                                  <div className="col-md-6">
                                    <div className="d-flex align-items-center">
                                      <div className="input-field pe-2">From</div>
                                      <div className="input-field">
                                        <span className="input-date">
                                          <input
                                            type="month"
                                            className="form-control"
                                            // readonly="readonly"
                                            placeholder="MM-YYYY"
                                            max={moment().format('YYYY-MM')}
                                            name="startDate"
                                            value={values.startDate}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                          />
                                        </span>
                                      </div>
                                    </div>
                                    <p className="text-danger">
                                      {touched.startDate && errors.startDate}
                                    </p>
                                  </div>

                                  <div className="col-md-6">
                                    <div className="d-flex align-items-center">
                                      <div className="input-field pe-2">To</div>
                                      {values.currentlyStydying === false ? (
                                        <div className="input-field">
                                          <span className="input-date">
                                            <input
                                              type="month"
                                              className="form-control"
                                              // readonly="readonly"
                                              placeholder="MM-YYYY"
                                              max={moment().format('YYYY-MM')}
                                              min={moment(values.startDate).format('YYYY-MM')}
                                              name="endDate"
                                              value={values.endDate}
                                              onChange={handleChange}
                                              onBlur={handleBlur}
                                            />
                                          </span>
                                        </div>
                                      ) : (
                                        <div className="col-md-4">
                                          <span className="present">Present</span>
                                        </div>
                                      )}
                                    </div>
                                    <p className="text-danger">
                                      {touched.endDate && errors.endDate}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-md-6 col-xs-12">
                              <div className="form-group">
                                <div className="custom-checkbox">
                                  <input
                                    type="checkbox"
                                    name="currentlyStydying"
                                    id="cbe1"
                                    className="currently_studing_here"
                                    value={values.currentlyStydying}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    // checked={values.currentlyStydying}
                                  />
                                  &nbsp;
                                  <label for="cb1">
                                    <b>I currently working here.</b>
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-12 col-xs-12">
                              <span className="float-end">
                                <button
                                  type="button"
                                  onClick={handleSubmit}
                                  className="add-btn-grn mb-3">
                                  Save
                                </button>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                    <span className="float-start mandatory-label m-3">
                      ( <span className="sign">*</span> ) Mandatory fields
                    </span>
                  </div>
                ) : null}
                <div className="gray-footer clearfix border-0">
                  <span className="float-end">
                    <button
                      onClick={() => {
                        navigate(`/institute-register/step/5/${userId}`);
                      }}
                      type="button"
                      className="add-btn-grn skip-btn me-2">
                      Skip
                    </button>
                    <button
                      onClick={() => {
                        navigate(`/institute-register/step/5/${userId}`);
                      }}
                      className="add-btn-grn"
                      type="button">
                      Next
                    </button>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Step4;
