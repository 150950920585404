/**
@copyright  :   ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
@author     :   Shiv Charan Panjeta 
 
All Rights Reserved.
Proprietary and confidential :  All information contained here in is, and remains
the property of ToXSL Technologies Pvt. Ltd. and it's partners.
Unauthorized copying of this file, via any medium is strictly prohibited.
*/
import { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import { getShopCatDetail } from '../../../services/AuthServices';

import useDocumentTitle from '../../../globals/useDocumentTitle';

function ViewCategory() {
  useDocumentTitle('View Category | AlmaBay');
  const location = useLocation();
  const [catDetail, setCatDetail] = useState('');
  const id = location?.state;
  useEffect(() => {
    if (id) {
      getDetail(id);
    }
  }, []);

  const getDetail = (id) => {
    getShopCatDetail(id).then((res) => {
      if (res?.status === 200) {
        const data = res?.data?.message?.catInfo;
        setCatDetail(data);
      }
    });
  };
  return (
    <div className="dash-main-content">
      <Container fluid>
        <div className="dash-page-content">
          <div className="dash-page-title">
            <Row>
              <Col xs={12} sm={12} md={12}>
                <div className="dash-title-bar d-flex align-items-center justify-content-between">
                  <div className="title-lft d-flex align-items-center">
                    <div className="dash-icn">
                      <i className="fa fa-file"></i>
                    </div>
                    <div className="dash-title-dis">
                      <h5>View Category Details</h5>
                    </div>
                  </div>
                  <div>
                    <div className="title-rt-btn ">
                      <Link className="add-btn-grn" to="/admin/categories">
                        Back To Listing
                      </Link>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <div className="dash-content-card">
            <div className="view_detials_content">
              <div className="mb-3">
                <strong>Category Name: </strong>
                {catDetail?.cat_name}
              </div>
              <div className="mb-3">
                <strong>Category Level: </strong>
                {catDetail?.cat_level != 0 ? catDetail?.cat_level : 'Root'}
              </div>
              {catDetail?.cat_discount ? (
                <div className="mb-3">
                  <strong>Discount (%): </strong>
                  {catDetail?.cat_discount}
                </div>
              ) : (
                <></>
              )}

              {catDetail?.cat_image ? (
                <div className="d-flex flex-wrap align-item-center">
                  <div className="border me-2">
                    <img width="100px" height="100px" src={catDetail?.cat_image} alt="img1" />
                  </div>
                </div>
              ) : (
                <></>
              )}

              <div className="mb-3">
                <strong>Status: </strong>
                {catDetail?.status != 0 ? 'Enable' : 'Disable'}
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}

export default ViewCategory;
