import Form from 'react-bootstrap/Form';
import { Link } from 'react-router-dom';
import showNotification from '../../../../services/NotificationService';

export default function PostBottomMedia(props) {
  /* eslint-disable */
  const {
    setSelectedFile,
    setUploadFiles,
    setIsSelected,
    setIsSelectedFile,
    setIsShowPoll,
    setShowEmoji,
    showEmoji,
    toggleMediaGroup
  } = props;
  const stateTimeline = props?.state;

  const handleFile = (files) => {
    const targetfileobj = [...files];
    targetfileobj?.map((data) => {
      if (
        !data?.type?.includes('image') &&
        (data?.type?.includes('application') ||
          data?.type?.includes('audio') ||
          data?.type?.includes('video'))
      ) {
        showNotification(
          'danger',
          'Sorry, attached image is invalid, allowed file formats are as follows: jpg, jpeg, png, gif',
          'random'
        );
        setSelectedFile([]);
        setIsSelected(false);
        return;
      } else if (data?.size / 1024 > 5120) {
        showNotification('danger', 'Sorry, Maximum file size limit is 5MB.', 'random');
        setSelectedFile([]);
        setIsSelected(false);
        return;
      } else {
        setSelectedFile(targetfileobj);
        setIsSelected(true);
      }
    });
  };

  const handleUplaodFiles = (files) => {
    const targetfileobj = [...files];
    targetfileobj?.map((data) => {
      if (
        !data?.type?.includes('application') &&
        (data?.type?.includes('image') ||
          data?.type?.includes('audio') ||
          data?.type?.includes('video'))
      ) {
        showNotification(
          'danger',
          'Sorry, attached file is invalid, allowed file formats are as follows: doc, docx, rtf, odt, txt, xml, xps, xls, xlsx, csv, ods, pdf, vcf, odp, pps, ppsx, ppt, pptx, pptm',
          'random'
        );
        setUploadFiles([]);
        setIsSelectedFile(false);
        return;
      } else if (data?.size / 1024 > 5120) {
        showNotification('danger', 'Sorry, Maximum file size limit is 5MB.', 'random');
        setUploadFiles([]);
        setIsSelectedFile(false);
        return;
      } else {
        setUploadFiles(targetfileobj);
        setIsSelectedFile(true);
      }
    });
  };

  return (
    <>
      <div className="wall-fltr-lft">
        <ul className="wall-ftr-actions">
          <li>
            <i className="fas fa-camera upload-card">
              <Form.Group className="upload-file" controlId="formFile">
                <Form.Control
                  type="file"
                  accept="image/*"
                  onChange={(e) => handleFile(e?.target?.files)}
                  multiple
                />{' '}
              </Form.Group>
            </i>
          </li>

          <li>
            <i className="fas fa-music" onClick={() => toggleMediaGroup('soundcloud-wrapper')}></i>
          </li>
          <li>
            <i className="fas fa-film" onClick={() => toggleMediaGroup('youtube-wrapper')}></i>
          </li>
          <li>
            <i className="fas fa-folder-open upload-card">
              <Form.Group className="upload-file" controlId="formFile">
                <Form.Control
                  type="file"
                  accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                  onChange={(e) => handleUplaodFiles(e?.target?.files)}
                  multiple
                />
              </Form.Group>{' '}
            </i>
          </li>
          <li>
            <i
              className="fas fa-map-marker-alt"
              onClick={() => toggleMediaGroup('google-map-wrapper')}
            ></i>
          </li>
          <li onClick={() => setShowEmoji(!showEmoji)}>
            <i className="far fa-smile"></i>
          </li>

          {stateTimeline == 'social-timeline' ? (
            <li>
              <Link
                className="social-polls"
                to="#"
                state="1"
                current="social"
                onClick={(e) => {
                  e.preventDefault();
                  setIsShowPoll(true);
                }}
              >
                <i className="fas fa-poll"></i>
              </Link>
            </li>
          ) : (
            ''
          )}
        </ul>
      </div>
    </>
  );
}
