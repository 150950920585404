//  @copyright : ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
// @author     : Shiv Charan Panjeta

// All Rights Reserved.
// Proprietary and confidential :  All information contained herein is, and remains
// the property of ToXSL Technologies Pvt. Ltd. and its partners.
// Unauthorized copying of this file, via any medium is strictly prohibited.
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { useEffect, useState } from 'react';
import { Button, Container } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import {
  addOpenPageContent,
  editOpenPageContent,
  getNewMenuList,
  getPageDetail
} from '../../services/AuthServices';
import showNotification from '../../services/NotificationService';
import { useNavigate } from 'react-router-dom';
import { useSearchParams } from 'react-router-dom';
import Loader from '../../components/commoncomponents/loader/loader-large';

export default function OpenPageContent() {
  const [clicked, setClicked] = useState(false);
  const [bannerFile, setBannerFile] = useState('');
  const [bannerTitle, setBannerTitle] = useState('');
  const [menuId, setMenuId] = useState('');
  const [menuOption, setMenuOption] = useState([]);
  const [singlefilePreview, setSingleFilePreview] = useState('');
  const [description, setDescription] = useState('');
  const [title, setTitle] = useState('');
  const [id, setId] = useState('');
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const [serachParam] = useSearchParams();
  const menu_id = serachParam.get('id');

  useEffect(() => {
    if (menu_id) {
      setMenuId(menu_id);
      getDetail(menu_id);
    }
    getMenuList();
  }, []);

  /**
   * get detail of selected slug from menu list
   * @param {*} id
   */
  const getDetail = (id) => {
    setLoading(true);
    getPageDetail(id).then((res) => {
      setLoading(false);
      if (res?.status == 200) {
        if (res?.data?.data?.id) {
          setId(res?.data?.data?.id);
          setTitle(res?.data?.data?.title);
          setBannerTitle(res?.data?.data?.banner_title);
          setDescription(res?.data?.data?.description || '');
          // setSingleFilePreview(res?.data?.data?.banner_img);
          setBannerFile(res?.data?.data?.banner_img);
        } else {
          setId('');
          setTitle('');
          setBannerTitle('');
          setDescription('');
          // setSingleFilePreview(""nner_img);
          setBannerFile('');
        }
      }
    });
  };

  /**
   * get menu list for dropdown option
   */

  const getMenuList = () => {
    getNewMenuList().then((res) => {
      if (res?.status === 200) {
        if (res?.data?.data != 'No Menu Item Found') {
          let data = res?.data?.data ? res?.data?.data : [];
          setMenuOption(data);
        }
      }
    });
  };

  const validate = () => {
    let errors = {};
    if (bannerTitle == '') {
      errors.banner_title = 'This field is required';
    }
    if (!title.trim()) {
      errors.title = 'This field is required';
    }
    if (menuId == '') {
      errors.menuId = 'This field is required';
    }
    if (bannerFile == '') {
      errors.bannerFile = 'This field is required';
    }
    if (description == '') {
      errors.bannerFile = 'This field is required';
    }

    return errors;
  };

  /**
   * for saving data >open pages content
   * @param {*} e
   */

  const submitHandler = (e) => {
    e.preventDefault();
    setClicked(true);
    if (!Object.keys(validate()).length) {
      let body = {
        banner_title: bannerTitle,
        title: title,
        banner_img: bannerFile,
        description: description,
        menu_id: menuId
      };
      addOpenPageContent(body).then((res) => {
        if (res?.status === 200) {
          showNotification('success', res?.data?.message);
          navigate('/admin/website-content');
        }
      });
    }
  };

  /**
   * update existing data based on id /selected menu
   */

  const updateHandler = (e) => {
    e.preventDefault();
    setClicked(true);
    if (!Object.keys(validate()).length) {
      let body = {
        banner_title: bannerTitle,
        title: title,
        banner_img: bannerFile,
        description: description,
        menu_id: menuId
      };
      editOpenPageContent(id, body).then((res) => {
        if (res?.status === 200) {
          showNotification('success', res?.data?.message);
          navigate('/admin/website-content');
        }
      });
    }
  };

  /**
   * for preview and selected file data
   * @param {*} e
   */

  const handleFile = (e) => {
    setBannerFile(e.target.files[0]);
    setSingleFilePreview(URL.createObjectURL(e.target.files[0]));
  };

  /**
   * api url and endpoints to upload image
   */
  const API_URL = process.env.REACT_APP_API_DOMAIN;
  const UPLOAD_ENDPOINT = 'institute/ckimage';
  /**
   * Upload Adapter
   * function used for saving image and return the saved image path
   */
  function uploadAdapter(loader) {
    return {
      upload: () => {
        return new Promise((resolve, reject) => {
          const body = new FormData();
          loader.file.then((file) => {
            body.append('file', file);
            fetch(`${API_URL}/${UPLOAD_ENDPOINT}`, {
              method: 'post',
              body: body
              // mode: "no-cors"
            })
              .then((res) => res.json())
              .then((res) => {
                resolve({
                  default: `${API_URL}/${res?.data}`
                });
              })
              .catch((err) => {
                reject(err);
              });
          });
        });
      }
    };
  }

  /**
   * used extra_plugin to insert image on ck editor
   * @param {*} editor
   */
  function uploadPlugin(editor) {
    editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
      return uploadAdapter(loader);
    };
  }

  return (
    <>
      <div className="dash-main-content">
        <Container fluid>
          <div className="dash-page-content">
            <div className="dash-page-title">
              <Row>
                <Col xs={12} sm={12} md={12}>
                  <div className="dash-title-bar d-flex align-items-center justify-content-between">
                    <div className="title-lft d-flex align-items-center">
                      <div className="dash-icn">
                        <i className="fas fa-university"></i>
                      </div>
                      <div className="dash-title-dis">
                        <h5>
                          Add <span>Page Content</span>
                        </h5>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="dash-content-card">
              <div className="cmmn-form">
                <Form>
                  <Row>
                    {' '}
                    <Col md={6}>
                      <Form.Group className="mb-3" controlId="formBasicName">
                        <Form.Label>
                          Menu List <sup className="text-danger">*</sup>
                        </Form.Label>
                        <Form.Select
                          aria-label="Default select example"
                          name="privacy"
                          value={menuId}
                          onChange={(e) => {
                            setMenuId(e.target.value);
                            getDetail(e.target.value);
                            setClicked(false);
                          }}>
                          <option value="">Select</option>
                          {/* dynamic  */}
                          {menuOption &&
                            menuOption?.length &&
                            menuOption?.map((data, index) => (
                              <option key={index} value={data?.menu_id}>
                                {data?.name}
                              </option>
                            ))}
                        </Form.Select>
                        <div
                          className={`${
                            clicked && validate()?.menuId ? '' : 'd-none'
                          } danger-frm `}>
                          {clicked && validate()?.menuId}
                        </div>
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      {' '}
                      <Form.Group className="mb-3" controlId="formBasicState">
                        <Form.Label>
                          Banner Title
                          <sup className="text-danger">*</sup>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="banner_title"
                          value={bannerTitle}
                          onChange={(e) => {
                            setBannerTitle(e.target.value);
                          }}
                          placeholder="Enter banner title"
                        />
                        <div
                          className={`${
                            clicked && validate()?.banner_title ? '' : 'd-none'
                          } danger-frm `}>
                          {clicked && validate()?.banner_title}
                        </div>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      {' '}
                      <Form.Group controlId="formFile" className="mb-3">
                        <Form.Label>
                          Attach Banner File <sup className="text-danger">*</sup>
                        </Form.Label>
                        <Form.Control
                          type="file"
                          name="image"
                          accept=".png,.jpg,.jpeg"
                          onChange={(e) => {
                            handleFile(e);
                          }}
                        />
                        <div className={'danger-frm'}>{clicked && validate().bannerFile}</div>
                      </Form.Group>
                    </Col>
                    <Col xs={2} sm={2} md={2}>
                      <div className=" d-flex">
                        {bannerFile && !singlefilePreview ? (
                          <img
                            height={'100px'}
                            width={'100px'}
                            src={process.env.REACT_APP_API_DOMAIN + bannerFile}
                            alt="img"
                          />
                        ) : bannerFile ? (
                          <img
                            height={'100px'}
                            width={'100px'}
                            src={singlefilePreview}
                            alt="img"
                            className="me-2"
                          />
                        ) : (
                          ''
                        )}
                      </div>
                    </Col>
                  </Row>{' '}
                  <Form.Group className="mb-3" controlId="formBasicState">
                    <Form.Label>
                      Title
                      <sup className="text-danger">*</sup>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="banner_title"
                      value={title}
                      onChange={(e) => {
                        setTitle(e.target.value);
                      }}
                      placeholder="Enter title"
                    />
                    <div
                      className={`${
                        clicked && validate()?.banner_title ? '' : 'd-none'
                      } danger-frm `}>
                      {clicked && validate()?.banner_title}
                    </div>
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formBasicName">
                    <Form.Label>
                      Description<sup className="text-danger">*</sup>
                    </Form.Label>
                    {/* ck editor */}
                    <CKEditor
                      name="job_info"
                      editor={ClassicEditor}
                      data={description}
                      config={{
                        extraPlugins: [uploadPlugin],
                        toolbar: [
                          'heading',
                          '|',
                          'fontSize',
                          'fontFamily',
                          '|',
                          'bold',
                          'italic',
                          'underline',
                          'strikethrough',
                          'highlight',
                          '|',
                          'alignment',
                          '|',
                          'numberedList',
                          'bulletedList',
                          '|',
                          'indent',
                          'outdent',
                          '|',
                          'todoList',
                          'link',
                          'blockQuote',
                          'imageUpload',
                          'mediaEmbed',
                          '|',
                          'undo',
                          'redo'
                        ]
                      }}
                      onChange={(event, editor) => {
                        const data = editor.getData();
                        setDescription(data);
                      }}
                    />
                    {/* end */}
                    <div
                      className={`${
                        clicked && validate(description)?.description ? '' : 'd-none'
                      } danger-frm `}>
                      {clicked && validate(description)?.description}
                    </div>
                  </Form.Group>
                  <div className="form-btns d-flex align-items-center mt-3">
                    {id ? (
                      <Button className="main-btn-grn" onClick={updateHandler}>
                        Update
                      </Button>
                    ) : (
                      <Button className="main-btn-grn" onClick={submitHandler}>
                        Save
                      </Button>
                    )}
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </Container>
      </div>
      {loading ? <Loader /> : <></>}
    </>
  );
}
