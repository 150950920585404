/**
@copyright  :   ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
@author     :   Shiv Charan Panjeta 
 
All Rights Reserved.
Proprietary and confidential :  All information contained here in is, and remains
the property of ToXSL Technologies Pvt. Ltd. and it's partners.
Unauthorized copying of this file, via any medium is strictly prohibited.
*/
import { useEffect, useState } from 'react';
import { Button, Container } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import Loader from '../../components/commoncomponents/loader/loader-large';
import { NumberWithdecimal, restrictNum } from '../../globals/helper';
import { ShowAlert } from '../../redux/action';
import * as adminServices from '../../services/AuthServices';

export default function AddCity() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const id = location.state;
  const [detail, setDetail] = useState({
    city_name: '',
    latitude: '',
    longitude: '',
    state_id: '',
    country_id: ''
  });

  const [loading, setLoading] = useState(false);
  const [stateList, setStateList] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const [clicked, setClicked] = useState(false);

  useEffect(() => {
    getDropdownData();
    if (id) {
      getDetail(id);
    }
  }, []);

  const getDetail = (id) => {
    setLoading(true);
    adminServices.cityDetail(id).then((res) => {
      setLoading(false);
      if (res?.status === 200) {
        const data = res?.data?.message;
        setDetail({
          city_id: id,
          city_name: data.city,
          latitude: data?.latitude,
          longitude: data?.longitude,
          state_id: data?.state?.state_id,
          country_id: data?.country_details?.country_id
        });
      }
    });
  };

  const getDropdownData = () => {
    adminServices.getStateDropdown().then((res) => {
      if (res?.status === 200) {
        setStateList(res?.data?.data);
      }
    });

    adminServices.getCountryDropdown().then((res) => {
      if (res?.status === 200) {
        setCountryList(res?.data?.data);
      }
    });
  };

  const onChangeHandler = (e) => {
    const { name, value } = e.target;
    setDetail({ ...detail, [name]: value });
  };

  const validate = (value) => {
    let errors = {};
    var letters = /^[A-Za-z]+$/;
    if (!value.city_name?.trim()) {
      errors.city_name = 'This field is required';
    }
    // else if (!value?.city_name.match(letters)) {
    //   errors.city_name = 'Letters only ';
    // }
    if (!value.latitude?.trim()) {
      errors.latitude = 'This field is required';
    }
    if (!value.longitude?.trim()) {
      errors.longitude = 'This field is required';
    }
    if (!value.state_id?.trim()) {
      errors.state_id = 'This field is required';
    }
    if (!value.country_id?.trim()) {
      errors.country_id = 'This field is required';
    }
    return errors;
  };

  const submitHandler = (e) => {
    e.preventDefault();
    setClicked(true);
    if (!Object.keys(validate(detail)).length) {
      let formData = new FormData();
      for (let i in detail) {
        formData.append(`${i}`, detail[i]);
      }
      adminServices.addCity(formData).then((res) => {
        if (res?.status === 200) {
          navigate('/admin/location/city');
          dispatch(ShowAlert('New city has been added successfully'));
        }
      });
    }
  };

  const updateHandler = (e) => {
    e.preventDefault();
    setClicked(true);
    if (!Object.keys(validate(detail)).length) {
      let formData = new FormData();
      for (let i in detail) {
        formData.append(`${i}`, detail[i]);
      }
      adminServices.updateCity(formData).then((res) => {
        if (res?.status === 200) {
          dispatch(ShowAlert(res?.data?.message));
          navigate('/admin/location/city');
        }
      });
    }
  };

  return (
    <>
      <div className="dash-main-content">
        <Container fluid>
          <div className="dash-page-content">
            <div className="dash-page-title">
              <Row>
                <Col xs={12} sm={12} md={12}>
                  <div className="dash-title-bar d-flex align-items-center justify-content-between">
                    <div className="title-lft d-flex align-items-center">
                      <div className="dash-title-dis">
                        <h5>{!id ? 'Add' : 'Edit'} City</h5>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="dash-content-card">
              <div className="cmmn-form">
                <Form>
                  <Form.Group className="mb-3" controlId="formBasicName">
                    <Form.Label>
                      City Name <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      value={detail?.city_name}
                      onChange={(e) => {
                        onChangeHandler(e);
                      }}
                      onKeyPress={(e) => restrictNum(e)}
                      name="city_name"
                      placeholder="Enter City Name"
                    />
                    <div
                      className={`${
                        clicked && validate(detail).city_name ? '' : 'd-none'
                      } danger-frm px-0 `}>
                      {clicked && validate(detail).city_name}
                    </div>
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formBasicName">
                    <Form.Label>
                      Latitude <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      value={detail?.latitude}
                      onChange={(e) => {
                        onChangeHandler(e);
                      }}
                      onKeyPress={(e) => NumberWithdecimal(e)}
                      name="latitude"
                      placeholder="Enter Latitude"
                    />
                    <div
                      className={`${
                        clicked && validate(detail).latitude ? '' : 'd-none'
                      } danger-frm px-0 `}>
                      {clicked && validate(detail).latitude}
                    </div>
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formBasicName">
                    <Form.Label>
                      Longitude <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      value={detail?.longitude}
                      onKeyPress={(e) => NumberWithdecimal(e)}
                      onChange={(e) => {
                        onChangeHandler(e);
                      }}
                      name="longitude"
                      placeholder="Enter Longitude"
                    />
                    <div
                      className={`${
                        clicked && validate(detail).longitude ? '' : 'd-none'
                      } danger-frm px-0 `}>
                      {clicked && validate(detail).longitude}
                    </div>
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formBasicState">
                    <Form.Label>
                      Select State<span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Select
                      aria-label="Default select example"
                      value={detail?.state_id}
                      onChange={(e) => {
                        onChangeHandler(e);
                      }}
                      name="state_id">
                      <option>-Select State-</option>
                      {stateList &&
                        stateList.map((data, index) => (
                          <option key={index} value={data?.state_id}>
                            {data?.state}
                          </option>
                        ))}
                    </Form.Select>
                    <div
                      className={`${
                        clicked && validate(detail).state_id ? '' : 'd-none'
                      } danger-frm px-0 `}>
                      {clicked && validate(detail).state_id}
                    </div>
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formBasicState">
                    <Form.Label>
                      Select Country<span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Select
                      aria-label="Default select example"
                      value={detail?.country_id}
                      onChange={(e) => {
                        onChangeHandler(e);
                      }}
                      name="country_id">
                      <option>-Select Country-</option>
                      {countryList &&
                        countryList.map((data, index) => (
                          <option value={data?.country_id} key={index}>
                            {data?.name}
                          </option>
                        ))}
                    </Form.Select>
                    <div
                      className={`${
                        clicked && validate(detail).country_id ? '' : 'd-none'
                      } danger-frm px-0 `}>
                      {clicked && validate(detail).country_id}
                    </div>
                  </Form.Group>
                  <div className="form-btns d-flex align-items-center mt-5">
                    {id ? (
                      <button
                        type="button"
                        className="green_button_admin"
                        onClick={(e) => {
                          updateHandler(e);
                        }}>
                        Update
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="green_button_admin"
                        onClick={(e) => {
                          submitHandler(e);
                        }}>
                        Save
                      </button>
                    )}
                    <strong>OR</strong>
                    <Button
                      className="main-btn-red"
                      onClick={() => {
                        navigate(-1);
                      }}>
                      Cancel
                    </Button>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </Container>
      </div>
      {loading ? <Loader /> : ''}
    </>
  );
}
