/**
@copyright  :   ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
@author     :   Shiv Charan Panjeta 
 
All Rights Reserved.
Proprietary and confidential :  All information contained here in is, and remains
the property of ToXSL Technologies Pvt. Ltd. and it's partners.
Unauthorized copying of this file, via any medium is strictly prohibited.
*/
import { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { Link, useLocation } from 'react-router-dom';
// import * as adminService from '../../services/AuthServices';
import { getJobFestDetail } from '../../services/AuthServices';

export default function ViewjobDetails() {
  const [jobFestDetail, setJobFestDetail] = useState('');
  const location = useLocation();
  let id = location?.state;

  useEffect(() => {
    if (id) {
      getDetail(id);
    }
  }, []);

  const getDetail = (id) => {
    getJobFestDetail(id).then((res) => {
      if (res?.status === 200) {
        const data = res?.data?.data?.details;
        setJobFestDetail(data);
      }
    });
  };

  return (
    <div className="dash-main-content">
      <Container fluid>
        <div className="dash-page-content">
          <div className="dash-page-title">
            <Row>
              <Col xs={12} sm={12} md={12}>
                <div className="dash-title-bar d-flex align-items-center justify-content-between">
                  <div className="title-lft d-flex align-items-center">
                    <div className="dash-icn">
                      <i className="fas fa-file-word"></i>
                    </div>
                    <div className="dash-title-dis">
                      <h5>
                        Details of <span> {jobFestDetail?.title}</span>
                      </h5>
                      {/* <h5>
                          Job in <span>job one</span>
                        </h5> */}
                    </div>
                  </div>
                  <div className="title-rt-btn ">
                    <Link className="add-btn-grn" to="/admin/jobfest">
                      Back To Listing
                    </Link>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <div className="dash-content-card">
            <div className="view-detail-content">
              <Row>
                <Col md={12}>
                  <p>
                    <strong>Title:</strong>
                    {jobFestDetail?.title}
                  </p>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <p>
                    <strong>Venue:</strong> {jobFestDetail?.venue}
                  </p>
                </Col>
              </Row>
              {jobFestDetail?.city && (
                <Row>
                  <Col md={12}>
                    <p>
                      <strong>City:</strong>
                      {jobFestDetail?.city}
                    </p>
                  </Col>
                </Row>
              )}

              <Row>
                <Col md={12}>
                  <p>
                    <strong>Start Date:</strong>
                    {jobFestDetail?.start_date}
                  </p>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <p>
                    <strong>End Date:</strong> {jobFestDetail?.end_date}
                  </p>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <p>
                    <strong>Status:</strong>
                    {jobFestDetail?.status == 1 ? 'Enable' : 'Disable'}
                  </p>
                </Col>
              </Row>
              {/* <Row>
                  <Col md={12}>
                    <strong>Job Description:</strong>
                    <p>
                      on title one description jon title one description jon title one description
                      jon title one description jon title one description jon title one description
                      jon title one description jon title one description jon title one description
                      jon title one description jon title one description jon title one description
                      jon title one description jon title one description jon title one description
                      jon title one description jon title one description jon title one description
                      jon title one description jon title one description jon title one description
                      jon title one description jon title one description jon title one description
                      jon title one description jon title one description jon title one description
                      jon title one description jon title one description jon title one description
                      jon title one description jon title one description jon title one description
                      jon title one description jon title one description jon title one description
                      jon title one description jon title one description jon title one description
                      jon title one description jon title one description jon title one description
                      jon title one description jon title one description jon title one description
                      jon title one description jon title one description jon title one description
                      jon title one description jon title one description jon title one description
                      jon title one description jon title one description jon title one description
                      jon title one description jon title one description jon title one description{' '}
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <p>
                      <strong>Company hiring for: </strong>jon title one
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <p>
                      <strong>Job Fest Name: </strong>job one
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <p>
                      <strong>Open positions:</strong>4
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <p>
                      <strong>Work experience: </strong>2 - 4
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <p>
                      <strong>Location: </strong>jon title one
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <p>
                      <strong>HR Email: </strong>jon title one
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <p>
                      <strong>Contact Number: </strong>5263784512
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <p>
                      <strong>Posted On: </strong>2023-01-31 19:16:25
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <p>
                      <strong>Status: </strong>Enable
                    </p>
                  </Col>
                </Row> */}
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}
