/** @copyright : ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
 @author     : Shiv Charan Panjeta 

 All Rights Reserved.
 Proprietary and confidential :  All information contained herein is, and remains
 the property of ToXSL Technologies Pvt. Ltd. and its partners.
 Unauthorized copying of this file, via any medium is strictly prohibited.
*/
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Col, Modal, Row } from 'react-bootstrap';
import ReactPlayer from 'react-player';
import { Link } from 'react-router-dom';
import { FreeMode, Navigation, Thumbs } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { getAllClubGallery } from '../../../services/InstituteServices';

function AllContent() {
  const [show, setShow] = useState(false);
  const [list, setList] = useState([]);
  const [preview, setPreview] = useState([]);

  const handleClose = () => setShow(false);

  const handleShow = (e, data) => {
    e.preventDefault();
    setPreview(data);
    setShow(true);
  };

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    getAllClubGallery().then((res) => {
      if (res?.status === 200) {
        setList(res?.data?.data || []);
      }
    });
  };

  return (
    <>
      <Row>
        {list?.photos?.length ? (
          <Col sm={12}>
            <p className="gallary-division-heading">Photos</p>
          </Col>
        ) : (
          <></>
        )}

        {list?.photos && list?.photos?.length ? (
          list?.photos?.map((data, index) => (
            <Col key={index} sm={6} md={4}>
              <Link
                className="h-100 single-img-content text-center "
                to="#"
                onClick={(e) => {
                  handleShow(e, data);
                }}>
                <img src={data?.url} alt="img" width="100%" className="mb-3" />
                <p>{data?.title}</p>
              </Link>
            </Col>
          ))
        ) : (
          <></>
        )}

        {list?.album?.length ? (
          <Col sm={12}>
            <p className="gallary-division-heading">Albums</p>
          </Col>
        ) : (
          <></>
        )}

        {list?.album && list?.album?.length ? (
          list?.album?.map((data, index) => (
            <Col sm={6} md={4} key={index}>
              <Link
                to={`/club/gallery-view/${data?.media_id}`}
                className="h-100 single-img-content text-center album-link">
                <img
                  src={
                    data?.album_list?.length
                      ? data?.album_list[0]?.url
                      : require('../../../assets/images/imageicon.png')
                  }
                  alt="img"
                  width="100%"
                  className="mb-3"
                />
                <p className="mb-1">{data?.title}</p>
                <p className="text-muted mb-4">
                  Photos: <span className="text-black me-3">{data?.album_list?.length}</span> &nbsp;
                  Date:{' '}
                  <span className="text-black">{moment(data?.created).format('DD MMM YYYY')}</span>{' '}
                </p>
              </Link>
            </Col>
          ))
        ) : (
          <Col sm={12}></Col>
        )}
        {list?.video?.length ? (
          <Col sm={12}>
            <p className="gallary-division-heading">Videos</p>
          </Col>
        ) : (
          <></>
        )}

        {list?.video && list?.video?.length ? (
          list?.video?.map((data, index) => (
            <Col key={index} sm={6} md={4}>
              <Link
                to={`/club/videos-view/${data?.media_id}`}
                className="single-img-content text-center video-link">
                {data?.thumbnail ? (
                  <img src={data?.thumbnail} alt="img" width="100%" />
                ) : (
                  <ReactPlayer
                    width="250"
                    height="500"
                    url={data?.video_list[0]?.url || data?.video_list[0]?.url_link}
                    className="mb-3"
                  />
                )}
                {/* <ReactPlayer url={data?.video_list[0]?.url} className='mb-3' /> */}
                {/* <img src={process.env.REACT_APP_API_URL + data?.thumbnail} alt='img' width="100%" className='mb-3' /> */}
                <p className="mb-1">{data?.title || ''}</p>
                <p className="text-muted mb-4">
                  Videos: <span className="text-black me-3">{data?.video_list?.length}</span> &nbsp;
                  Date:{' '}
                  <span className="text-black">{moment(data?.created).format('DD MMM YYYY')}</span>{' '}
                </p>
              </Link>
            </Col>
          ))
        ) : (
          <></>
        )}
      </Row>

      {/* no data found */}

      {!list?.video?.length && !list?.album?.length && !list?.photos?.length && (
        <Col sm={12} md={4} lg={3}>
          <div className="text-center nodata">
            <img src={require('../../../assets/images/nodata.png')} alt="" />
            <p>Data Not Found</p>
          </div>
        </Col>
      )}
      {/* photo's view modal */}
      <Modal show={show} onHide={handleClose} className="gallery_modal">
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="image-view mb-4">
            <Swiper
              style={{
                '--swiper-navigation-color': '#fff',
                '--swiper-pagination-color': '#fff'
              }}
              spaceBetween={10}
              navigation={true}
              modules={[FreeMode, Navigation, Thumbs]}
              className="mySwiper2">
              <SwiperSlide>
                <a data-fancybox="gallery" href="#" className="text-center">
                  <img src={preview?.url} alt="img" />
                  <p className="text-black">{preview?.title}</p>
                </a>
              </SwiperSlide>
            </Swiper>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default AllContent;
