import { Link, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import HeaderLogin from '../../commoncomponents/HeaderLogin';
import FooterMain from '../../commoncomponents/FooterMain';
import TopTabbings from './TopTabbings';
import NewsSidebar from '../../commoncomponents/newsfeeds/NewsSidebar';
import Dropdown from 'react-bootstrap/Dropdown';
import Accordion from 'react-bootstrap/Accordion';
import * as APISERVICES from '../../../services/MentorArticlesServices';
import Offcanvas from 'react-bootstrap/Offcanvas';
import AskQuestionModal from './AskQuestionModal';
import SendMessageModal from './SendMessageModal';
import * as index from '../../commoncomponents/skelton/allskelton';
import showNotification from '../../../services/NotificationService';
import useDocumentTitle from '../../../globals/useDocumentTitle';

function Mentors() {
  useDocumentTitle('List of Mentors | AlmaBay');
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleClose2 = () => setShow2(false);
  const handleClose3 = () => setShow3(false);
  const [show2, setShow2] = useState(false);
  const [show3, setShow3] = useState(false);

  const [mentorLocation, setMentorLocation] = useState([]); // Main State For Location
  const [locationSearch, setLocationSearch] = useState([]); // For Location search filter
  const [skillSearch, setSkillSearch] = useState([]); // For  search skill filter
  const [skill, setSkill] = useState([]);
  const [mentorshipSkill, setMentorshipSkill] = useState([]);
  const [mentorId, setMentorId] = useState('');
  const [mentorData, setMentorData] = useState([]); // Main Data State
  const [dataForSearch, setDataForSearch] = useState([]); // For Search purpose only
  const [industry, setIndustry] = useState([]); // Main state for industry
  const [searchIndustry, setSearchIndustry] = useState([]); // For Industry base filteration

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getMentorsData();
  }, []);

  const getMentorsData = (search) => {
    setLoading(true);
    APISERVICES.getUserMentors(search).then((res) => {
      if (res?.status === 200) {
        setLoading(false);
        setMentorLocation(res?.data?.detail?.all_locations); // Main state for all Locations
        setLocationSearch(res?.data?.detail?.all_locations); // For Location search filter
        setMentorshipSkill(res?.data?.detail?.all_skills);
        setSkillSearch(res?.data?.detail?.all_skills);

        setIndustry(res?.data?.detail?.all_industries); // Main State for all Industry
        setSearchIndustry(res?.data?.detail?.all_industries); // For Industry filteration purpose
        if (skill?.length > 0) {
          setMentorData(
            res?.data?.detail?.mentors?.filter((data) => {
              return skill?.includes(data?.skills?.pop()?.name);
            })
          );
        } else {
          setMentorData(res?.data?.detail?.mentors);
          setDataForSearch(res?.data?.detail?.mentors);
        }
      }
    });
  };

  const [arr2, setArr2] = useState([]);

  const handleSearchSkills = (e) => {
    let updatedListing = [...arr2];
    if (e?.target?.value) {
      updatedListing = [...arr1, e.target.value];
    } else {
      updatedListing.splice(arr1.indexOf(e.target.value), 1);
    }
    setArr2(updatedListing);
  };

  // const handleSearchSkills = (e) => {
  //   getMentorsData();
  //   if (skill?.includes(name)) {
  //     let filterSkills = skill.filter((data) => data !== name);
  //     setSkill(filterSkills);
  //   } else {
  //     setSkill([...skill, name]);
  //   }
  // };

  const handleRemoveMentor = (e, id) => {
    e.preventDefault();
    const body = {
      user_id: id
    };
    const confirm = window.confirm('Are your sure you want to remove your mentor?');
    if (confirm) {
      APISERVICES.removeMentor(body).then((res) => {
        if (res?.status == 200) {
          showNotification('success', 'Mentor removed successfully');
          getMentorsData();
        }
      });
    }
  };
  /**********Filteration by skills********** */
  const searchBySkills = (e) => {
    if (e?.target?.value) {
      let filterSkill = skillSearch?.filter((data) => {
        return data?.name.toLowerCase()?.includes(e?.target?.value?.toLowerCase());
      });

      setMentorshipSkill(filterSkill);
    } else {
      setMentorshipSkill(skillSearch);
    }
  };

  /**********Name Search Filter Starts***********/
  const searchByName = (e) => {
    if (e.target.value) {
      let filterData = dataForSearch?.filter((data) => {
        return data?.name?.toLowerCase()?.includes(e.target.value?.toLowerCase());
      });

      setMentorData(filterData);
    } else {
      setMentorData(dataForSearch);
    }
  };

  /****************Filteration by industy starts*****************/
  const searchByIndustry = (e) => {
    if (e?.target?.value) {
      let filterIndustry = searchIndustry?.filter((data) => {
        return data?.name?.toLowerCase()?.includes(e?.target?.value?.toLowerCase());
      });
      setIndustry(filterIndustry);
    } else {
      setIndustry(searchIndustry);
    }
  };

  const [arr, setArr] = useState([]);
  const handleIndustriesChange = (e) => {
    let updatedList = [...arr];
    if (e.target.checked) {
      updatedList = [...arr, e.target.value];
    } else {
      updatedList.splice(arr.indexOf(e.target.value), 1);
    }
    setArr(updatedList);
  };

  // useEffect(() => {
  //   if (arr.length > 0) {
  //     let filterData = dataForSearch?.filter((data) => {
  //       return arr?.includes(data?.industry ? data?.industry[0]?.industry : '');
  //     });
  //     setMentorData(filterData);
  //   } else {
  //     setMentorData(dataForSearch);
  //   }
  // }, [arr]);

  /*****************Search By Location Starts***************/
  const searchByLocation = (e) => {
    if (e?.target?.value) {
      let filterIndustry = locationSearch?.filter((data) => {
        return data?.city?.toLowerCase()?.includes(e?.target?.value?.toLowerCase());
      });
      setMentorLocation(filterIndustry);
    } else {
      setMentorLocation(locationSearch);
    }
  };

  const [arr1, setArr1] = useState([]);

  const handleLocationChange = (e) => {
    let updatedList = [...arr1];
    if (e.target.checked) {
      updatedList = [...arr1, e.target.value];
    } else {
      updatedList.splice(arr1.indexOf(e.target.value), 1);
    }
    setArr1(updatedList);
  };

  useEffect(() => {
    if (arr1.length > 0 || arr.length > 0 || arr2.length > 0) {
      let filterData = dataForSearch?.filter((data) => {
        return (
          arr1?.includes(data?.where_provide ? data?.where_provide[0]?.city : '') ||
          arr?.includes(data?.industry ? data?.industry[0]?.industry : '') ||
          arr2?.includes(data?.skills ? data?.skills[0]?.name : '')
        );
      });
      setMentorData(filterData);
    } else {
      setMentorData(dataForSearch);
    }
  }, [arr1, arr, arr2]);

  const [industryArr, setIndustryArr] = useState([]);
  const [mentorAreaArr, setMentorAreaArr] = useState([]);
  const [locationArr, setLocationArr] = useState([]);
  const [searchName, setsearchName] = useState('');

  useEffect(() => {
    APISERVICES.filtermentors(
      searchName,
      industryArr.toString(),
      mentorAreaArr.toString(),
      locationArr.toString()
    ).then((res) => {
      if (res?.status === 200) {
        setMentorData(res?.data?.detail?.mentors);
      }
    });
  }, [mentorAreaArr, industryArr, searchName, locationArr]);

  const getSearchedFilterData = (e, checkboxName) => {
    if (checkboxName == 'industry') {
      let updatedList = [...industryArr];
      if (e.target.checked) {
        updatedList = [...industryArr, e.target.value];
      } else {
        updatedList.splice(industryArr.indexOf(e.target.value), 1);
      }
      setIndustryArr(updatedList);
    }
    if (checkboxName == 'area') {
      let updatedList = [...mentorAreaArr];
      if (e.target.checked) {
        updatedList = [...mentorAreaArr, e.target.value];
      } else {
        updatedList.splice(mentorAreaArr.indexOf(e.target.value), 1);
      }
      setMentorAreaArr(updatedList);
    }
    if (checkboxName == 'loc') {
      let updatedList = [...locationArr];
      if (e.target.checked) {
        updatedList = [...locationArr, e.target.value];
      } else {
        updatedList.splice(locationArr.indexOf(e.target.value), 1);
      }
      setLocationArr(updatedList);
    }
  };

  return (
    <>
      <HeaderLogin />
      <div className="header-lnk-feeds">
        <div className="container">
          <TopTabbings state={'feed'} />
        </div>
      </div>
      <section className="feed-sec bg-grey py-4">
        <div className="container">
          <Row>
            <Col xs={12} lg={2} md={12}>
              <NewsSidebar state="4" current="mentors" />
            </Col>
            <Col xs={12} lg={7} md={12}>
              <div className="mentees-sec p-4 bg-white">
                <ul className="mentees-top-links mb-3">
                  <li>
                    <Link to="/user/friends">Friends</Link>
                  </li>
                  <li>
                    <Link className="active" to="/user/mentors">
                      Mentors
                    </Link>
                  </li>
                  <li>
                    <Link to="/user/mentees">Mentees</Link>
                  </li>
                </ul>
                {loading ? (
                  <>
                    <Row>
                      {Array.from(Array(6), () => {
                        return (
                          <Col md={4} sm={4} xs={6}>
                            <index.FriendsSkelton />
                          </Col>
                        );
                      })}
                    </Row>
                  </>
                ) : mentorData && mentorData?.length > 0 ? (
                  <div className="mentees-inr-card">
                    <Row>
                      {mentorData?.map((data, index) => {
                        return (
                          <Col lg={4} md={6} sm={4} xs={6} key={index}>
                            <div className="mentees-pro-card">
                              <div className="mentees-img">
                                <Link to={`/mentors/profile/${data?.encrpt_id}`}>
                                  <img
                                    src={
                                      data?.user_image
                                        ? data?.user_image
                                        : require('../../../assets/images/default-male-avatar.png')
                                    }
                                    alt="Profile Image"
                                  />
                                </Link>
                              </div>
                              <div className="mentees-dis">
                                <Link to={`/mentors/profile/${data?.encrpt_id}`}>
                                  <h4>{data?.name}</h4>
                                </Link>

                                <p>{data?.address}</p>
                                <Dropdown className="mt-2">
                                  <Dropdown.Toggle variant="success" id="dropdown-basic">
                                    Following
                                  </Dropdown.Toggle>

                                  <Dropdown.Menu>
                                    <Dropdown.Item
                                      onClick={() =>
                                        navigate(`/mentors/profile/${data?.encrpt_id}`)
                                      }
                                    >
                                      View mentor Profile
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      onClick={() => navigate(`/user/timeline/${data?.encrpt_id}`)}
                                    >
                                      View Social Profile
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      onClick={() => {
                                        setShow3(true);
                                        setMentorId(data?.user_id);
                                      }}
                                    >
                                      Send Message
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      onClick={() => {
                                        setShow2(true);
                                        setMentorId(data?.user_id);
                                      }}
                                    >
                                      Ask Question
                                    </Dropdown.Item>

                                    <Dropdown.Item
                                      onClick={(e) => handleRemoveMentor(e, data?.user_id)}
                                    >
                                      Unfollow Mentor
                                    </Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </div>
                            </div>
                          </Col>
                        );
                      })}
                    </Row>
                  </div>
                ) : (
                  <div className="upcoming_events p-3">
                    <div className="text-center">
                      <img src={require('../../../assets/images/small-error.png')} alt="img" />
                    </div>
                    <p className="text-center">
                      Oops, Mentors with current selection not available at the moment.
                    </p>
                  </div>
                )}
              </div>
            </Col>
            <Col lg={3} md={12}>
              <Button
                className="mobile-filter-btn d-block d-lg-none "
                variant="primary"
                onClick={handleShow}
              >
                <i className="fas fa-filter"></i>
              </Button>

              <Offcanvas show={show} onHide={handleClose} className="mobile-filter">
                <Offcanvas.Body>
                  <div className="fiter-card ">
                    <div className="filter-hd-card d-flex align-items-center justify-content-between">
                      <div className="filter-hd">
                        <h4>Filter By</h4>
                      </div>
                      <div className="filter-btn">
                        <Link to="#">
                          <i className="fas fa-filter"></i>
                        </Link>
                        <Link to="#">
                          <i className="fas fa-sync-alt"></i>
                        </Link>
                      </div>
                    </div>
                    <div className="filter-select-lst">
                      <Accordion defaultActiveKey={['0', '1', '2', '3']} alwaysOpen flush>
                        <Accordion.Item eventKey="0">
                          <Accordion.Header>By Name</Accordion.Header>
                          <Accordion.Body>
                            <Form.Group className="search-card" controlId="formBasicEmail">
                              <Form.Control
                                type="search"
                                placeholder="Search By Name"
                                onChange={(e) => setsearchName(e.target.value)}
                              />
                              <Button className="search-btn">
                                <i className="fas fa-search"></i>
                              </Button>
                            </Form.Group>
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1">
                          <Accordion.Header>Industry</Accordion.Header>
                          <Accordion.Body>
                            <Form.Group className="search-card" controlId="formBasicEmail">
                              <Form.Control
                                type="search"
                                placeholder="Search By Industry"
                                onChange={searchByIndustry}
                              />
                              <Button className="search-btn">
                                <i className="fas fa-search"></i>
                              </Button>
                            </Form.Group>
                            {industry?.map((data, index) => {
                              return (
                                <div key={index}>
                                  <Form.Check
                                    // type="checkbox"
                                    id={data?.id}
                                    value={data?.id}
                                    label={data?.name}
                                    onChange={(e) => getSearchedFilterData(e, 'industry')}
                                  />
                                </div>
                              );
                            })}
                          </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="2">
                          <Accordion.Header>Mentorship Area</Accordion.Header>
                          <Accordion.Body>
                            <Form.Group className="search-card" controlId="formBasicEmail">
                              <Form.Control
                                type="search"
                                placeholder="Search By Mentorship Areas"
                                onChange={searchBySkills}
                              />
                              <Button className="search-btn">
                                <i className="fas fa-search"></i>
                              </Button>
                            </Form.Group>
                            {mentorshipSkill?.map((data, index) => {
                              return (
                                <div className="overflow-auto" key={index}>
                                  <input
                                    type="checkbox"
                                    onChange={() => {
                                      handleSearchSkills(data?.name);
                                    }}
                                  />
                                  {data?.name}
                                </div>
                              );
                            })}
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="3">
                          <Accordion.Header>Location</Accordion.Header>
                          <Accordion.Body>
                            <Form.Group className="search-card" controlId="formBasicEmail">
                              <Form.Control
                                onChange={searchByLocation}
                                type="search"
                                placeholder="Search By Location"
                              />
                              <Button className="search-btn">
                                <i className="fas fa-search"></i>
                              </Button>
                            </Form.Group>
                            {mentorLocation?.map((data, index) => {
                              return (
                                <div key={index}>
                                  <Form.Check
                                    type="checkbox"
                                    id={data?.id}
                                    value={data?.city}
                                    label={data?.city}
                                    onChange={handleLocationChange}
                                  />
                                </div>
                              );
                            })}
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    </div>
                  </div>
                </Offcanvas.Body>
              </Offcanvas>

              <div className="fiter-card d-none d-lg-block">
                <div className="filter-hd-card d-flex align-items-center justify-content-between">
                  <div className="filter-hd">
                    <h4>Filter Results</h4>
                  </div>
                  {/* <div className="filter-btn">
                    <Link to="#">
                      <i className="fas fa-filter"></i>
                    </Link>
                    <Link to="#">
                      <i className="fas fa-sync-alt"></i>
                    </Link>
                  </div> */}
                </div>
                <div className="filter-select-lst">
                  <Accordion defaultActiveKey={['0', '1', '2', '3']} alwaysOpen flush>
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>By Name</Accordion.Header>
                      <Accordion.Body>
                        <Form.Group className="search-card" controlId="formBasicEmail">
                          <Form.Control
                            type="search"
                            placeholder="Search By Name"
                            onChange={(e) => setsearchName(e.target.value)}
                          />
                          <Button className="search-btn">
                            <i className="fas fa-search"></i>
                          </Button>
                        </Form.Group>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                      <Accordion.Header>Industry</Accordion.Header>
                      <Accordion.Body>
                        <Form.Group className="search-card" controlId="formBasicEmail">
                          <Form.Control
                            type="search"
                            placeholder="Search By Industry"
                            onChange={searchByIndustry}
                          />
                          <Button className="search-btn">
                            <i className="fas fa-search"></i>
                          </Button>
                        </Form.Group>
                        {industry?.map((data, index) => {
                          return (
                            <div key={index}>
                              <Form.Check
                                type="checkbox"
                                id={data?.id}
                                value={data?.id}
                                label={data?.name}
                                onChange={(e) => getSearchedFilterData(e, 'industry')}
                              />
                            </div>
                          );
                        })}
                      </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="2">
                      <Accordion.Header>Mentorship Area</Accordion.Header>
                      <Accordion.Body>
                        <Form.Group className="search-card" controlId="formBasicEmail">
                          <Form.Control
                            type="search"
                            onChange={searchBySkills}
                            placeholder="Search By Mentorship Areas"
                          />
                          <Button className="search-btn">
                            <i className="fas fa-search"></i>
                          </Button>
                        </Form.Group>

                        {mentorshipSkill?.map((data, index) => {
                          return (
                            <div key={index}>
                              {/* <input
                                type="checkbox"
                                id={data?.id}
                                value={data?.name}
                                label={data?.name}
                                onChange={(e) => {
                                  handleSearchSkills(e);
                                }}
                              /> */}
                              <Form.Check
                                type="checkbox"
                                id={data?.id}
                                value={data?.id}
                                label={data?.name}
                                onChange={(e) => getSearchedFilterData(e, 'area')}
                              />
                            </div>
                          );
                        })}
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="3">
                      <Accordion.Header>Location</Accordion.Header>
                      <Accordion.Body>
                        <Form.Group className="search-card" controlId="formBasicEmail">
                          <Form.Control
                            onChange={searchByLocation}
                            type="search"
                            placeholder="Search By Location"
                          />
                          <Button className="search-btn">
                            <i className="fas fa-search"></i>
                          </Button>
                        </Form.Group>
                        {mentorLocation?.map((data, index) => {
                          return (
                            <div key={index}>
                              <Form.Check
                                type="checkbox"
                                id={data?.city_id}
                                value={data?.city_id}
                                label={data?.city}
                                onChange={(e) => getSearchedFilterData(e, 'loc')}
                              />
                            </div>
                          );
                        })}
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </section>
      <FooterMain className={'colour-bg'} />

      {/* ask question  modal */}
      <AskQuestionModal
        handleClose2={handleClose2}
        show2={show2}
        mentorId={mentorId}
        setMentorId={setMentorId}
      />

      {/* Send Message Modal */}
      <SendMessageModal
        handleClose3={handleClose3}
        show3={show3}
        mentorId={mentorId}
        setMentorId={setMentorId}
      />
    </>
  );
}

export default Mentors;
