import moment from 'moment';
import { useEffect, useState } from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { getClubInsNews, getClubNewsArchive, getClubNewsTags } from '../../../services/InstituteServices';
import Loader from '../../commoncomponents/loader/loader-large';

function ClubNews() {
    const [newsList, setNewsList] = useState([]);
    const [archiveList, setArchiveList] = useState([]);
    const [tagList, setTagList] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        getInfo();
    }, []);

    // get list
    const getInfo = () => {
        getClubInsNews().then((res) => {
            setLoading(false)
            if (res?.status == 200) {
                setNewsList(res?.data);
            }
        });
        getClubNewsArchive().then((res) => {
            if (res?.status == 200) {
                setArchiveList(res?.data?.data?.output);
            }
        });
        getClubNewsTags().then((res) => {
            if (res?.status == 200) {
                setTagList(res?.data?.details);
            }
        });
    };

    return (
        <>
            <div className="wrappermain_outer bggreylight">
                <section
                    className="breadcrum-banner"
                    style={{
                        backgroundImage: `url(${newsList?.news_banner?.banner_file})`
                    }}
                >
                    <Container>
                        <Row>
                            <Col sm={12}>
                                <h1 className="Text-white">{newsList?.news_banner?.title || 'News'}</h1>
                            </Col>
                        </Row>
                    </Container>
                </section>

                <section className="gallery-section">
                    <Container>
                        <Row>
                            <Col sm={12}></Col>
                            <Col lg={8}>
                                <div className="news_conatiner blog_container">
                                    <ul>
                                        {newsList && newsList?.details?.length ? (
                                            newsList?.details?.map((data, index) => (
                                                <li className="mb-3" key={index}>
                                                    <Link to={`/club/news-detail/${data?.slug}`}>
                                                        <Card className="p-3">
                                                            <div className="media">
                                                                <div className="media-body">
                                                                    <p className="mb-0">
                                                                        {moment(data?.date_modified).format('MMM DD,YYYY')}
                                                                    </p>
                                                                    <h5 className="mb-0">{data?.title}</h5>
                                                                </div>
                                                            </div>
                                                        </Card>
                                                    </Link>
                                                </li>
                                            ))
                                        ) : (
                                            <div className={loading ? "d-none" : "text-center nodata"}>
                                                <img src={require('../../../assets/images/nodata.png')} alt="no_data" />
                                                <p>Data Not Found</p>
                                            </div>
                                        )}
                                    </ul>
                                </div>
                            </Col>
                            <Col lg={4}>
                                {archiveList?.length ? (
                                    <div className="archive-section mb-4">
                                        <h4>Archive</h4>
                                        <ul>
                                            {archiveList && archiveList?.length ? (
                                                archiveList?.map((month, index) => (
                                                    <li key={index}>
                                                        <Link>
                                                            {month?.month_name} {month?.year_name} ({month?.count})
                                                        </Link>
                                                    </li>
                                                ))
                                            ) : (
                                                <></>
                                            )}
                                        </ul>
                                    </div>
                                ) : (
                                    <></>
                                )}

                                <div className="tags_container mb-5">
                                    <h5>Tags</h5>
                                    <div className="d-flex flex-wrap">
                                        {tagList && tagList?.length ? (
                                            tagList?.map((data, index) => (
                                                <span className="single-tag m-2" key={index}>
                                                    {data?.title}
                                                </span>
                                            ))
                                        ) : (
                                            <div className={loading ? "d-none" : "text-center nodata"}>
                                                <img alt="blog" src={require('../../../assets/images/nodata.png')} />
                                                <p>Data Not Found</p>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </div>

            {/* <div className="lgn-link pb-4">
                <Container>
                    <div className="logn-lnk-inr">
                        <p>
                            <Link to="#">Register</Link> / <Link to="/institute-login">Login</Link> to browse
                            members by Year, Name, Company, Profession or Location
                        </p>
                    </div>
                </Container>
            </div> */}
            {loading ? <Loader /> : <></>}
        </>
    );
}

export default ClubNews;
