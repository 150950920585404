//  @copyright : ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
// @author     : Shiv Charan Panjeta

// All Rights Reserved.
// Proprietary and confidential :  All information contained herein is, and remains
// the property of ToXSL Technologies Pvt. Ltd. and its partners.
// Unauthorized copying of this file, via any medium is strictly prohibited.

import { useEffect, useState } from 'react';
import { Button, Container } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { useNavigate, useParams } from 'react-router-dom';
import Select from 'react-select';
import useDocumentTitle from '../../../globals/useDocumentTitle';
import {
  addSocialPage,
  editSocialPage,
  getPageAdminListDrop,
  getPageCategoryDrop,
  getSocialPageDetail
} from '../../../services/AuthServices';
import showNotification from '../../../services/NotificationService';

export default function AddPage() {
  useDocumentTitle('Add New Page | AlmaBay');
  const { id } = useParams();
  const [categoryOption, setCategoryOption] = useState([]);
  const [adminOption, setAdminOption] = useState([]);
  const [pageDetail, setPageDetail] = useState({
    page_category_id: '',
    page_name: '',
    page_username: '',
    page_verified: '',
    page_admin: '',
    page_about: ''
  });
  const [categoryId, setCategoryId] = useState([]);
  const [pageAdminState, setPageAdminState] = useState([]);
  const [clicked, setClicked] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    getCategoryOption();
    getAdminList();
    if (id) {
      getPageDetail(id);
    }
  }, []);

  const getPageDetail = (id) => {
    getSocialPageDetail(id).then((res) => {
      if (res?.status === 200) {
        const data = res?.data?.message;
        setPageDetail({
          page_category_id: data?.category_id,
          page_name: data?.name,
          page_username: data?.username,
          page_verified: data?.verified,
          page_admin: data?.page_admin,
          page_about: data?.about,
          id
        });

        setCategoryId({ value: data?.category_id, label: data?.category_name });
        setPageAdminState({ value: data?.page_admin, label: data?.admin_name });
      }
    });
  };

  const getCategoryOption = () => {
    getPageCategoryDrop().then((res) => {
      if (res?.status === 200) {
        let tempArr = [];
        let arr = [];
        if (res?.data?.message) {
          Object.entries(res?.data?.message)
            .map(([key, value]) => ({ key, value }))
            ?.map((insideData, index) => {
              tempArr.push({
                label: insideData?.key,
                options: insideData?.value?.flatMap((x) => {
                  return {
                    label: Object.values(x).join(),
                    value: Object.keys(x).join()
                  };
                })
              });
            });
        }
        setCategoryOption(tempArr);
      }
    });
  };

  const getAdminList = () => {
    getPageAdminListDrop().then((res) => {
      if (res?.status === 200) {
        const data = res?.data?.message?.users?.map((items) => {
          return {
            label: items?.name,
            value: items?.id
          };
        });
        setAdminOption(data);
      }
    });
  };

  const handleChange = (e, defaultName = '') => {
    if (defaultName == 'page_category_id' || defaultName == 'page_admin') {
      setPageDetail({
        ...pageDetail,
        [defaultName]: e.value
      });
    } else if (e.target.name == 'page_name') {
      setPageDetail({
        ...pageDetail,
        ['page_name']: e.target.value,
        ['page_username']: e.target.value?.replace(/ /g, '')
      });
    } else {
      const { name, value } = e.target;
      setPageDetail({
        ...pageDetail,
        [name]: value
      });
    }
  };

  const validate = () => {
    let errors = {};
    if (pageDetail?.page_name == '') {
      errors.name = 'Please enter page name';
    }
    if (pageDetail?.page_username == '') {
      errors.userName = 'Please enter username';
    }
    if (pageDetail?.page_admin == '') {
      errors.admin = 'Please select admin';
    }
    return errors;
  };

  const saveHandler = (e) => {
    e.preventDefault();
    setClicked(true);
    if (!Object.keys(validate()).length) {
      let formData = new FormData();
      for (let i in pageDetail) {
        formData.append(`${i}`, pageDetail[i]);
      }
      addSocialPage(formData).then((res) => {
        if (res?.status === 200) {
          showNotification('success', res?.data?.message);
          navigate('/admin/pages');
        }
      });
    }
  };

  const updateHandler = (e) => {
    e.preventDefault();
    setClicked(true);
    if (!Object.keys(validate()).length) {
      let formData = new FormData();
      for (let i in pageDetail) {
        formData.append(`${i}`, pageDetail[i]);
      }
      editSocialPage(formData).then((res) => {
        if (res?.status === 200) {
          showNotification('success', res?.data?.message);
          navigate('/admin/pages');
        }
      });
    }
  };
  //validate

  return (
    <div className="dash-main-content">
      <Container fluid>
        <div className="dash-page-content">
          <div className="dash-page-title">
            <Row>
              <Col xs={12} sm={12} md={12}>
                <div className="dash-title-bar d-flex align-items-center justify-content-between">
                  <div className="title-lft d-flex align-items-center">
                    <div className="dash-icn">
                      <i className="fas fa-flag"></i>
                    </div>
                    <div className="dash-title-dis">
                      {id ? (
                        <h5>
                          Update <span>Page</span>
                        </h5>
                      ) : (
                        <h5>
                          Add <span>Page</span>
                        </h5>
                      )}
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <div className="dash-content-card">
            <div className="cmmn-form">
              <Form>
                <Row>
                  <Col md={12}>
                    <Form.Group className="mb-3" controlId="formBasicName">
                      <Form.Label>Category:</Form.Label>
                      <Select
                        className="basic-single"
                        // value={pageDetail?.page_category_id}
                        classNamePrefix="select"
                        isDisabled={false}
                        isLoading={false}
                        isClearable={false}
                        isRtl={false}
                        isSearchable={true}
                        options={categoryOption}
                        value={categoryId}
                        onChange={(e) => {
                          setCategoryId(e);
                          setPageDetail({ ...pageDetail, ['page_category_id']: e.value });
                        }}
                      />
                    </Form.Group>
                  </Col>

                  <Col md={12}>
                    <Form.Group className="mb-3" controlId="formBasicName">
                      <Form.Label>
                        Name:<span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Name of your page"
                        name="page_name"
                        value={pageDetail?.page_name}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                      />
                      <div className={'danger-frm'}>{clicked && validate().name}</div>
                    </Form.Group>
                  </Col>

                  <Col md={12}>
                    <Form.Group className="mb-3" controlId="formBasicName">
                      <Form.Label>
                        Username:<span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Username, e.g. YouTubeOfficial"
                        name="page_username"
                        value={pageDetail?.page_username}
                        onChange={handleChange}
                        onKeyDown={(e) => {
                          if (e?.which == 32) {
                            e.preventDefault();
                          }
                        }}
                      />
                      <div className={'danger-frm'}>{clicked && validate().userName}</div>
                    </Form.Group>
                  </Col>

                  <Col md={12}>
                    <Form.Group className="mb-3" controlId="formBasicName">
                      <Form.Label>Verify Page:</Form.Label>
                      <Form.Select
                        aria-label="Default select example"
                        name="page_verified"
                        onChange={handleChange}
                        value={pageDetail?.page_verified}>
                        <option>Select</option>
                        <option value="1">Official</option>
                        <option value="0">Non-Official</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>

                  <Col md={12}>
                    <Form.Group className="mb-3" controlId="formBasicName">
                      <Form.Label>
                        Select Admin For Page: <span className="text-danger">*</span>
                      </Form.Label>
                      <Select
                        className="basic-single"
                        classNamePrefix="select"
                        value={pageAdminState}
                        isDisabled={false}
                        isLoading={false}
                        isClearable={false}
                        isRtl={false}
                        isSearchable={true}
                        options={adminOption}
                        onChange={(e) => {
                          setPageAdminState(e);
                          setPageDetail({ ...pageDetail, ['page_admin']: e.value });
                        }}
                        // onChange={(e) => {
                        //   handleChange(e?.value, 'page_admin');
                        // }}
                      />
                      <div className={'danger-frm'}>{clicked && validate().admin}</div>
                    </Form.Group>
                  </Col>

                  <Col md={12}>
                    <Form.Group className="mb-3" controlId="formBasicDesi">
                      <Form.Label>Description: </Form.Label>
                      <Form.Control
                        as="textarea"
                        placeholder="Write about your page..."
                        rows={3}
                        name="page_about"
                        value={pageDetail?.page_about}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <div className="form-btns d-flex align-items-center mt-3">
                  {id ? (
                    <button className="green_button_admin" type="button" onClick={updateHandler}>
                      Update
                    </button>
                  ) : (
                    <button className="green_button_admin" type="button" onClick={saveHandler}>
                      Save
                    </button>
                  )}
                  <strong>OR</strong>
                  <Button
                    className="main-btn-red"
                    onClick={() => {
                      navigate(-1);
                    }}>
                    Cancel
                  </Button>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}
