import { useEffect, useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Alert, Container, Modal, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import Header from '../../CommonComponents/Header';
import SideBar from '../../CommonComponents/Sidebar';
import Footer from '../../CommonComponents/Footer';
import { useNavigate } from 'react-router-dom';
import { exportOrder, getOrdersList, setMultipleOrderStatus } from '../../../services/AuthServices';
import ReactPaginate from 'react-paginate';
import useDocumentTitle from '../../../globals/useDocumentTitle';
import showNotification from '../../../services/NotificationService';

function OrderList() {
  useDocumentTitle('Manage Orders | AlmaBay');
  const [orderList, setOrderList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [orderBy, setOrderBy] = useState('DESC');
  const [shortOrderBy, setShortOrderBy] = useState('id');
  const [chekcedId, setCheckedId] = useState([]);
  const [allId, setAllId] = useState([]);
  const navigate = useNavigate();
  const [showalert, setShowAlert] = useState(false);
  const [massageView, setMassageView] = useState('');
  useEffect(() => {
    getListOfStatus();
  }, []);

  const getListOfStatus = (page = 1, currentSort, type) => {
    getOrdersList(page, currentSort ? currentSort : shortOrderBy, type ? type : orderBy).then(
      (res) => {
        if (res?.status === 200) {
          setOrderList(res?.data?.data);
          setAllId(res?.data?.data?.viewlist?.map((data) => data?.id));
        }
      }
    );
  };

  useEffect(() => {
    const timeId = setTimeout(() => {
      // After 3 seconds set the show value to false
      setShowAlert(false);
      setMassageView('');
    }, 3000);
  }, [showalert]);

  // const handleStatusUpdate = (id, value) => {
  //   orderStatusUpdate(id, value).then((res) => {
  //     if (res?.status === 200) {
  //       getListOfStatus();
  //     }
  //   });
  // };

  const orderStatus = (status) => {
    switch (status) {
      case 1:
        return 'Order Placed';

      case 2:
        return 'Payment Pending';

      case 3:
        return 'Delivered';

      case 4:
        return 'Cancelled';

      case 5:
        return 'Return/Refund';

      case 6:
        return 'Payment Failed';

      default:
        break;
    }
  };

  const sortHandler = (e, type) => {
    e.preventDefault();
    let currentSort = orderBy === 'ASC' ? 'DESC' : 'ASC';
    setOrderBy(currentSort);
    setShortOrderBy(type);
    getListOfStatus(currentPage, type, currentSort);
  };
  const handlePageClick = (a) => {
    let page = a?.selected + 1;
    setCurrentPage(page);
    setCheckedId([]);
    getListOfStatus(page, shortOrderBy, orderBy);
  };

  const handleMultipleCheckBox = (id) => {
    if (chekcedId.includes(id)) {
      let filterId = chekcedId.filter((data) => data !== id);
      setCheckedId(filterId);
    } else {
      setCheckedId([...chekcedId, id]);
    }
  };

  const handleSelectAll = (e) => {
    if (e.target.checked === true) {
      setCheckedId(allId);
    } else {
      setCheckedId([]);
    }
  };

  const selectHandler = (id, value) => {
    switch (value) {
      case 'view':
        navigate(`/admin/shops/order/view`, { state: id });
        break;
      default:
        break;
    }
  };

  const seq = ['id', 'status', 'payment_method', 'order_on', 'name'];
  const exportParmeters = [
    {
      id: 'id',
      label: 'Id'
    },
    {
      id: 'status',
      label: 'Status'
    },
    {
      id: 'payment_method',
      label: 'Payment Method'
    },
    {
      id: 'order_on',
      label: 'Order On'
    },
    {
      id: 'name',
      label: 'Name'
    }
  ];

  const [selectedExportParameter, setSelectedExportParameter] = useState([]);
  const [isExportDialogOpen, setIsExportDialogOpen] = useState(false);

  const exportParmetersSelectionHandler = (id) => {
    if (selectedExportParameter.includes(id)) {
      let result = selectedExportParameter.filter((param) => param != id);
      setSelectedExportParameter(result);
    } else {
      setSelectedExportParameter(selectedExportParameter.concat(id));
    }
  };

  const handleExportDialogClose = (e) => {
    selectedExportParameter.sort(function (a, b) {
      return seq.indexOf(a) - seq.indexOf(b);
    });

    let body = {
      exportSelectedColumns: selectedExportParameter.join(),
      'orderId[]': chekcedId?.toString()
    };

    exportOrder(body).then((res) => {
      if (res?.status == 200) {
        setIsExportDialogOpen(false);
        showNotification('success', res.data.message);

        // window.open(process.env.REACT_APP_API_URL + res?.data?.filepath);   //Cause crash on page
      }
    });
  };

  const globalStateHandler = () => {
    setShowAlert(true);
  };

  const handlePerformAction = (e) => {
    if (chekcedId.length !== 0) {
      setShowAlert(false);
      let body = {
        ids: chekcedId,
        selectedAction: e.target.value
      };
      let formData = new FormData();
      for (let i in body) {
        formData.append(`${i}`, body[i]);
      }
      setMultipleOrderStatus(formData).then((res) => {
        if (res?.status === 200) {
          setMassageView(res?.data?.message);
          getListOfStatus();
          setCheckedId([]);
          setShowAlert(true);
        }
      });
    } else {
      globalStateHandler();
    }
  };

  return (
    <>
      <div className="dash-main-content">
        <Container fluid>
          <div className="dash-page-content">
            <div className="dash-page-title">
              <Row>
                <Col xs={12} sm={12} md={12}>
                  <div className="dash-title-bar d-flex align-items-center justify-content-between">
                    <div className="title-lft d-flex align-items-center">
                      <div className="dash-icn">
                        <i className="fas fa-shopping-cart"></i>
                      </div>
                      <div className="dash-title-dis">
                        <h5>
                          Order <span>Listing</span>
                        </h5>
                        <h5>
                          Total Record(s): <span>{orderList?.totalRecords}</span>
                        </h5>
                      </div>
                    </div>

                    <div className="title-rt-btn d-flex align-items-center">
                      <Link
                        className="green_button_admin mx-2"
                        to="#0"
                        onClick={() => {
                          setIsExportDialogOpen(true);
                          setSelectedExportParameter([]);
                        }}>
                        Export Orders
                      </Link>
                      <div className="head-instite-sec">
                        <Form.Select
                          aria-label="Default select example "
                          onChange={handlePerformAction}>
                          <option>Choose Action</option>
                          <option value="placed">Placed </option>
                          <option value="pending_payment">Pending Payment </option>
                          <option value="delivered">Delivered</option>
                          <option value="cancelled">Cancelled </option>
                          <option value="refund"> Refund </option>
                          <option value="payment_failed">failed- Payment </option>
                        </Form.Select>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>

            <div className="dash-content-card">
              <Alert
                variant={massageView ? 'success' : 'danger'}
                className={`${showalert ? '' : 'd-none'}`}>
                {massageView ? massageView : 'Please select any record'}
              </Alert>
              <div className="table-responsive">
                <Table className="custom-table-data" striped bordered hover>
                  <thead>
                    <tr>
                      <th>
                        <Form.Check
                          type="checkbox"
                          id="checkbox"
                          label=""
                          checked={allId?.length !== 0 && allId?.length === chekcedId?.length}
                          onChange={handleSelectAll}
                        />
                      </th>
                      <th>
                        <Link
                          to=""
                          onClick={(e) => {
                            sortHandler(e, 'id');
                          }}>
                          Id
                          <img src={require('../../../assets/images/sort.png')} alt="sort" />
                        </Link>
                      </th>
                      <th>
                        <Link
                          to=""
                          onClick={(e) => {
                            sortHandler(e, 'user_id');
                          }}>
                          User Name
                          <img src={require('../../../assets/images/sort.png')} alt="sort" />
                        </Link>
                      </th>
                      <th>
                        <Link
                          to=""
                          onClick={(e) => {
                            sortHandler(e, 'grand_total');
                          }}>
                          Amount
                          <img src={require('../../../assets/images/sort.png')} alt="sort" />
                        </Link>
                      </th>
                      <th>
                        <Link to="">Payment Method</Link>
                      </th>
                      <th>
                        <Link to="">Order On</Link>
                      </th>

                      <th>
                        {' '}
                        <Link
                          to=""
                          onClick={(e) => {
                            sortHandler(e, 'order_status');
                          }}>
                          Status
                          <img src={require('../../../assets/images/sort.png')} alt="sort" />
                        </Link>
                      </th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  {orderList?.viewlist?.length !== 0 ? (
                    <>
                      {' '}
                      <tbody>
                        {orderList?.viewlist?.map((item, index) => {
                          return (
                            <tr key={index}>
                              <td>
                                <Form.Check
                                  type="checkbox"
                                  id={`id${item?.id}`}
                                  checked={chekcedId?.includes(item?.id)}
                                  onChange={() => handleMultipleCheckBox(item?.id)}
                                  label=""
                                />
                              </td>
                              <td>{item?.id}</td>
                              <td>{item?.username}</td>
                              <td>Rs.{item?.grand_total} </td>
                              <td>
                                {item?.order_status == 1
                                  ? 'COD'
                                  : 'Credit Card/Debit Card/NetBanking'}{' '}
                              </td>
                              <td>{item?.orderOn}</td>
                              <td>{orderStatus(+item?.order_status)}</td>

                              <td>
                                <Form.Select
                                  aria-label="Default select example"
                                  onChange={(e) => selectHandler(item?.id, e.target.value)}>
                                  <option>Action</option>
                                  <option value="view">View</option>
                                </Form.Select>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </>
                  ) : (
                    <>No data found</>
                  )}
                </Table>
              </div>
              <div className="react-page d-flex">
                {orderList?.totalRecords > 10 ? (
                  <ReactPaginate
                    previousLabel={currentPage > 0 ? '<' : ''}
                    nextLabel=">"
                    onPageChange={handlePageClick}
                    activeClassName={'page-link active'}
                    pageCount={Math.ceil(orderList?.totalRecords / 10)}
                  />
                ) : (
                  ''
                )}
              </div>
            </div>
          </div>
        </Container>
      </div>
      <Modal
        show={isExportDialogOpen}
        onHide={handleExportDialogClose}
        contentClassName="export-model">
        <Modal.Header closeButton onClick={() => setIsExportDialogOpen(false)}>
          <Modal.Title>Select Column</Modal.Title>
        </Modal.Header>
        <Modal.Body className="export-body">
          {exportParmeters?.map((paramName, index) => {
            return (
              <Form.Check
                type="checkbox"
                id={`checkbox${index}`}
                key={index}
                name={paramName.id}
                checked={selectedExportParameter.includes(paramName.id)}
                label={paramName.label}
                className="export-model-label"
                onChange={(e) => exportParmetersSelectionHandler(e.target.name)}
              />
            );
          })}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={handleExportDialogClose}
            className="export-submit-btn">
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default OrderList;
