import { SHORTLIST_JOB } from '../../globals/constants';
import {
  APPEND_PRIMARY_JOB_LIST,
  GET_ALL_JOB_LIST,
  TOGGLE_SHORTLIST_JOB,
  GET_ALL_SHORTLIST_JOB,
  GET_ALL_ALUMNI_LIST,
  ADMIN_ALUMNI_LIST,
  RESET_ALL_JOB_LIST
} from '../action';

const initialState = {
  state: false,
  data: [],
  jobList: [],
  jobListCount: 0,
  currentPage: 1,
  shortList: [],
  shortListCount: 0,
  alumnisList: [],
  alumnisListCount: 0,
  adminAlumniList: null,
  adminALumniListCount: 0
};

export const clusterReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'CLUSTER':
      return {
        ...state,
        state: true,
        data: Array.isArray(action.payload)
          ? action.payload.map(({ list }) => list)
          : [action.payload]
      };

    case GET_ALL_JOB_LIST: {
      return {
        ...state,
        jobList: action.payload.jobList.results,
        jobListCount: action.payload.jobList.total,
        shortList: action.payload.shortList.results,
        shortListCount: action.payload.shortList.total
      };
    }

    case GET_ALL_ALUMNI_LIST: {
      return {
        ...state,
        alumnisList: action.payload.alumniList,
        alumnisListCount: action.payload.total
      };
    }

    case ADMIN_ALUMNI_LIST: {
      return {
        ...state,
        adminAlumniList: action.payload,
        adminALumniListCount: 0
      };
    }

    case GET_ALL_SHORTLIST_JOB: {
      return {
        ...state,
        shortList: action.payload.shortList.results,
        shortListCount: action.payload.shortList.total
      };
    }
    case APPEND_PRIMARY_JOB_LIST: {
      return {
        ...state,
        jobList: [...state.jobList, ...action.payload.jobList.results],
        jobListCount: action.payload.jobList.total,
        currentPage: state.currentPage + 1
      };
    }
    case TOGGLE_SHORTLIST_JOB: {
      const { jobId, status } = action.payload;
      return {
        ...state,
        jobList: state.jobList.map((job) =>
          job.id == jobId
            ? {
                ...job,
                status: status
              }
            : job
        ),
        shortList:
          status == 1
            ? [
                { ...state.jobList.find((job) => job.id == jobId), status: SHORTLIST_JOB },
                ...state.shortList
              ]
            : state.shortList.filter((job) => job.id != jobId)
      };
    }
    case RESET_ALL_JOB_LIST: {
      return {
        ...state,
        jobList: [],
        jobListCount: 0,
        currentPage: 1
      };
    }
    default:
      return state;
  }
};
