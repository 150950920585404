import * as api from '../utils/requests';

export const instituteLogo = async () => {
  return await api.getReq(`/shop/institute-logo`);
};

export const campusProducts = async () => {
  return await api.getReq(`/product/campusProducts`);
};

export const featureProducts = async () => {
  return await api.getReq(`/product/featureProducts`);
};

export const productSingleDetail = async (id) => {
  return await api.getReq(`/shop/details?id=${id}`);
};
export const shopAllInstitutes = async () => {
  return await api.getReq(`/shop/all_institues`);
};
export const shopAllBrowseInstitutes = async () => {
  return await api.getReq(`/shop/all_browse_institutes`);
};

export const shopFilterProducts = async (code, institute_id = '') => {
  return await api.getReq(`/shop/productsFilter?code=${code}&institute_id=${institute_id}`);
};

export const productsReview = async (product_id) => {
  return await api.getReq(`/shop/reviews?product_id=${product_id}`);
};
export const createProductReview = async (body) => {
  return await api.PostReq(`/shop/productReviewsSubmit`, body);
};
export const addRatings = async (body) => {
  return await api.PostReq(`/shop/ratings`, body);
};
export const getCatalogueProducts = async (institute_id) => {
  return await api.getReq(`/shop/cataLog?institute_id=${institute_id}&order_by=asc`);
};
export const buyNowProducts = async (body) => {
  return await api.PostReq(`shop/add_to_cart`, body);
};
export const viewCartProducts = async (temp_user = '') => {
  return await api.getReq(`/shop/cart?temp_user=${temp_user}`);
};
export const viewCartProductsCheckout = async (body) => {
  return await api.PostReq(`/shop/reviewOrder`, body);
};
export const deleteCartProducts = async (cart_id) => {
  return await api.getReq(`/shop/deleteItem?cart_id=${cart_id}`);
};
export const manageCartProductsAction = async (body) => {
  return await api.PostReq(`/shop/manage_cart`, body);
};
export const confirmAddress = async (body) => {
  return await api.PostReq(`/shop/confirmAddress`, body);
};
export const emailAlreadyExist = async (email) => {
  return await api.getReq(`/shop/chkEmailExist?email=${email}`);
};
export const saveOrderStore = async (body) => {
  return await api.PostReq(`/shop/saveOrder`, body);
};
export const singleProductsUpdateCart = async (body) => {
  return await api.PostReq(`/shop/updateCart`, body);
};
export const myProductsOrders = async () => {
  return await api.getReq(`/shop/myOrders`);
};
export const myProductsOrdersDetail = async (order_id) => {
  return await api.getReq(`/shop/orderDetail?order_id=${order_id}`);
};
export const getCountryList = async (country_name) => {
  return await api.getReq(`/shop/countryList?country_name=${country_name}`);
};
export const getConfirmAddress = async () => {
  return await api.getReq(`shop/getConfirmAddress`);
};
export const getSearchAllInsitute = async (body) => {
  return await api.PostReq(`/shop/searchcollege`, body);
};

export const newsLetterSignup = async (body) => {
  return await api.PostReq(`/shop/newsletters`, body);
};
