import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Form from 'react-bootstrap/Form';
import { updateGenralSetting } from '../../../services/SocialServices';
import showNotification from '../../../services/NotificationService';

function PageGeneralSetting({ genSetting, setGenSetting, pageId, renderFunction }) {
  const handleChange = (e) => {
    const { name, value } = e.target;

    setGenSetting({ ...genSetting, [name]: value });
  };

  const handleSave = () => {
    const body = {
      username: genSetting.username,
      name: genSetting.name,
      about: genSetting?.about,
      address: genSetting?.address,
      timeline_id: pageId
    };
    updateGenralSetting(body).then((res) => {
      if (res?.status === 200) {
        showNotification('success', res?.data?.message);
        renderFunction();
      }
    });
  };

  return (
    <>
      {' '}
      <div className="add-event-activity bg-white">
        <div className="add-head-card">
          <h4 className="add-event-hd">General Settings</h4>
        </div>
        <div className="add-event-frm">
          <Form>
            <Form.Group as={Row} className="mb-3" controlId="formPlaintextEmail">
              <Form.Label column sm="2">
                Username
              </Form.Label>
              <Col sm="10">
                <Form.Control
                  type="text"
                  name="username"
                  onChange={handleChange}
                  value={genSetting?.username}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3" controlId="formPlaintextEmail">
              <Form.Label column sm="2">
                Name:
              </Form.Label>
              <Col sm="10">
                <Form.Control
                  type="text"
                  name="name"
                  value={genSetting?.name}
                  onChange={handleChange}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3" controlId="formPlaintextEmail">
              <Form.Label column sm="2">
                About:
              </Form.Label>
              <Col sm="10">
                <Form.Control
                  type="text"
                  as="textarea"
                  value={genSetting?.about}
                  name="about"
                  onChange={handleChange}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3" controlId="formPlaintextEmail">
              <Form.Label column sm="2">
                Address:
              </Form.Label>
              <Col sm="10">
                <Form.Control
                  type="text"
                  name="address"
                  value={genSetting?.address}
                  onChange={handleChange}
                />
              </Col>
            </Form.Group>

            <div className="accnt-lft-btn d-flex align-items-center justify-content-start">
              <button className="activity-btn" type="button" onClick={handleSave}>
                Save Changes
              </button>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
}

export default PageGeneralSetting;
