import { useState } from 'react';
import { Button, Col } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { Login } from '../../../services/AuthServices';
import { emailAlreadyExist } from '../../../services/ShopServices';
import { useDispatch } from 'react-redux';
import { loginInfo } from '../../../redux/action/index';
import { onKeyPressOnInput } from '../../../globals/constants';
export default function CheckoutPageEmailStep({
  nextStep,
  handleFormData,
  formData,
  setDisabledStep,
  activeTab,
  setLoginMark
}) {
  const [checkCred, setCheckCred] = useState(false);
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [loginDetails, setLoginDetails] = useState({
    email: '',
    password: ''
  });

  const loginValidate = (values) => {
    let errors = {};

    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    if (!values.email) {
      errors.email = 'Please enter your email';
    } else if (!regex.test(values.email)) {
      errors.email = 'Invalid email format';
    }

    return errors;
  };

  // onClick form submit-api call
  const submitFormData = (e) => {
    e.preventDefault();
    setCheckCred(true);
    if (!loginValidate(formData)?.email) {
      emailAlreadyExist(formData?.email).then((res) => {
        if (res?.status === 200) {
          if (res?.data?.message == 'email already registered') {
            setShow(true);
          } else {
            nextStep();
          }
        }
      });
    }
  };
  const loginValidates = (values) => {
    let errors = {};

    if (!values.password) {
      errors.password = 'Please enter your password';
    } else if (values.password.length < 6) {
      errors.password = 'Password must be more than 6 characters';
    }
    return errors;
  };

  const onChangeHandler = (e) => {
    const { name, value } = e.target;
    setLoginDetails({ ...loginDetails, [name]: value });
  };
  const LoginsubmitHandlers = (e) => {
    e.preventDefault();
    setCheckCred(true);
    if (!loginValidates(loginDetails).password && !loginValidate(formData).email) {
      let body = {
        email: loginDetails?.email ? loginDetails?.email : formData?.email,
        password: loginDetails?.password
      };
      Login(body).then((res) => {
        if (res?.status === 200) {
          activeTab('second');
          setLoginMark(true);
          dispatch(loginInfo(res?.data?.data));
          localStorage.setItem('token', res?.data?.access_token);
          localStorage.setItem('userDetail', JSON.stringify(res?.data?.data));
          setDisabledStep(true);
        } else {
          setShow(false);
        }
      });
    }
  };

  return (
    <div>
      {show == false ? (
        <>
          <h3>Log In / Register</h3>
          <p>We will send order details to this email address</p>
          <h5>Please enter your email address</h5>
          <Col xs={12}>
            <Form.Group className="mb-4">
              <Form.Control
                type="text"
                placeholder="Email address"
                className="rounded-0"
                value={formData?.email}
                name="email"
                onChange={handleFormData('email')}
                isInvalid={checkCred && loginValidate(formData)?.email}
                autoComplete="off"
                onKeyPress={(e) => onKeyPressOnInput(e, submitFormData)}
              />
              {checkCred && loginValidate(formData).email ? (
                <span
                  className={
                    `${checkCred && loginValidate(formData).email}` ? 'login-error' : 'd-none'
                  }
                >
                  {checkCred && loginValidate(formData)?.email}
                </span>
              ) : (
                ''
              )}
            </Form.Group>
            {show == true ? (
              ''
            ) : (
              <Button
                className="btn btn-primary btn-sm"
                name="checkoutSubmit"
                onClick={(e) => submitFormData(e)}
              >
                Continue
              </Button>
            )}
          </Col>
        </>
      ) : (
        ''
      )}

      {show == true ? (
        <div>
          <h3>Log In / Register</h3>
          <p>We will send order details to this email address</p>
          <h5>Please enter your email address</h5>
          <Col xs={12}>
            <Form.Group className="mb-4">
              <Form.Control
                type="text"
                placeholder="Type your email address"
                className="rounded-0"
                defaultValue={formData?.email}
                name="email"
                onChange={(e) => {
                  onChangeHandler(e);
                }}
                isInvalid={checkCred && loginValidates(loginDetails)?.email}
                autoComplete="off"
              />
              {checkCred && loginValidates(loginDetails).email ? (
                <span
                  className={
                    `${checkCred && loginValidates(loginDetails).email}` ? 'login-error' : 'd-none'
                  }
                >
                  {checkCred && loginValidates(loginDetails)?.email}
                </span>
              ) : (
                ''
              )}
            </Form.Group>
          </Col>
          <Col xs={12}>
            <h5>Please enter your password</h5>
            <Form.Group className="mb-4">
              <Form.Control
                type="password"
                placeholder="Type your password"
                className="rounded-0"
                name="password"
                onKeyPress={(e) => onKeyPressOnInput(e, LoginsubmitHandlers)}
                onChange={(e) => {
                  onChangeHandler(e);
                }}
                isInvalid={checkCred && loginValidates(loginDetails)?.password}
              />
              {checkCred && loginValidates(loginDetails)?.password ? (
                <span
                  className={
                    `${checkCred && loginValidates(loginDetails)?.password}`
                      ? 'login-error'
                      : 'd-none'
                  }
                >
                  {checkCred && loginValidates(loginDetails)?.password}
                </span>
              ) : (
                ''
              )}
            </Form.Group>

            <Button
              className="btn btn-primary btn-sm"
              name="checkoutSubmit"
              onClick={(e) => {
                LoginsubmitHandlers(e);
              }}
            >
              Login
            </Button>
          </Col>
        </div>
      ) : (
        ''
      )}
    </div>
  );
}
