/**
@copyright  :   ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
@author     :   Shiv Charan Panjeta 
 
All Rights Reserved.
Proprietary and confidential :  All information contained here in is, and remains
the property of ToXSL Technologies Pvt. Ltd. and it's partners.
Unauthorized copying of this file, via any medium is strictly prohibited.
*/
import { useEffect, useState } from 'react';
import { Button, Container } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { useNavigate } from 'react-router-dom';
import { AsyncPaginate } from 'react-select-async-paginate';
import CKEditorComponent from '../../components/webpages/user-pages/PostComponets/CKEditorComponent';
import { imageExtension } from '../../globals/helper';
import * as APISERVICES from '../../services/AuthServices';
import showNotification from '../../services/NotificationService';

export default function addWhatsnews() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [news_descp, setNews_descp] = useState('');
  const [skillId, setSkillId] = useState([]);

  const [clicked, setClicked] = useState(false);
  const [skillOptions, setSkillOptions] = useState([]);
  const [value, setValue] = useState([]);

  const [classes, setClasses] = useState([]);
  const [classesId, setClassesId] = useState([]);

  const [checkboxes, setCheckBoxes] = useState([
    'technical',
    'business',
    'interpersonal',
    'hobbies'
  ]);

  useEffect(() => {
    if (id) {
      apiCall(id);
    }
    getClasses();
    // getDropDownList();
  }, []);

  const [newData, setNewData] = useState({
    title: '',
    short_descp: '',
    tags: '',
    image: '',
    articleScope: '',
    audience_type: ''
  });

  const [newsImage, setNewImage] = useState('');

  const id = new URLSearchParams(location?.search).get('id');

  const validate = () => {
    let error = {};
    if (newData?.title == '') {
      error.title = 'Please enter news name';
    }
    if (newData?.short_descp == '') {
      error.short_descp = 'Please enter short description';
    }
    return error;
  };

  const handleChange = (e) => {
    if (e.target?.type == 'file') {
      let file = e?.target?.files[0];
      let type = e?.target?.files[0].type;
      if (imageExtension?.includes(type)) {
        setNewData({
          ...newData,
          [e?.target?.name]: file
        });
      } else {
        showNotification('danger', 'file format not supported !');
      }
    } else {
      const { name, value } = e?.target;
      setNewData({
        ...newData,
        [name]: value
      });
    }
  };

  const loadOptions = async (search, loadedOptions, { page }) => {
    try {
      const resp = await APISERVICES.getSkillsDropdown(search, page);
      setSkillOptions(resp?.data?.results ?? []);
      return {
        options: resp?.data?.results ?? [],
        hasMore: loadedOptions.length === resp?.data?.more ? false : true,
        additional: {
          page: search ? page + 1 : page + 1
        }
      };
    } catch (error) {
      console.log('error', error);
    }
  };

  const getClasses = () => {
    APISERVICES.getClasses().then((res) => {
      if (res?.status === 200) {
        setClasses(res?.data?.detail);
      }
    });
  };
  const handleSave = (e) => {
    e.preventDefault();
    setClicked(true);
    if (!Object.keys(validate()).length) {
      setIsLoading(true);
      let formData = new FormData();

      for (let i in newData) {
        formData.append(`${i}`, newData[i]);
      }
      formData.append('classes[]', classesId);
      formData.append('tags', value?.map((id) => id.name).join());
      formData.append('articleScope[]', checkboxes);
      formData.append('audience_type[]', newData?.audience_type);
      formData.append('news_descp', news_descp);

      APISERVICES.addOtherWhatNews(formData).then((res) => {
        setIsLoading(false);
        if (res?.status === 200) {
          showNotification('success', res?.data?.data);
          navigate('/admin/news');
        }
      });
    }
  };

  const updateHandler = (e) => {
    e.preventDefault();
    setClicked(true);
    if (!Object.keys(validate()).length) {
      let formData = new FormData();
      setIsLoading(true);
      for (let i in newData) {
        formData.append(`${i}`, newData[i]);
      }
      formData.append('id', id);
      formData.append('classes[]', classesId);
      formData.append('tags', value?.map((id) => id.name || id.label).join());
      formData.append('audience_type[]', newData?.audience_type);
      formData.append('news_descp', news_descp);
      formData.append('articleScope[]', checkboxes);

      APISERVICES.addEditNews(formData).then((res) => {
        setIsLoading(false);
        if (res?.status === 200) {
          navigate('/admin/news');
          showNotification('success', res?.data?.message);
        }
      });
    }
  };

  const apiCall = (id) => {
    APISERVICES.getWhatNewsView(id).then((res) => {
      if (res?.status === 200) {
        let data = res?.data?.data;
        setNewData({
          title: data?.title,
          short_descp: data?.short_descp,
          audience_type: data?.audience_type
        });
        setNewImage(data?.image_url);
        setNews_descp(data?.descp);
        setClassesId(data?.classes?.split(','));
        if (data?.tags) {
          let arr = [];

          data?.tags?.split(',')?.map((data) => {
            arr.push({ name: data });
          });
          setSkillId(arr);
          setValue(arr);
        }

        if (data?.scopes) {
          let arr = [];
          data?.scopes?.split(',')?.map((data) => {
            arr.push(data);
          });
          setCheckBoxes(arr);
        }
      }
    });
  };
  const handleCheckBoxSelection = (key) => {
    if (checkboxes.includes(key)) {
      let results = checkboxes.filter((box) => box != key);
      setCheckBoxes(results);
    } else {
      setCheckBoxes([...checkboxes, key]);
    }
  };

  const coursesCheckBox = (e, id) => {
    if (e.target.checked) {
      setClassesId([...classesId, id]);
    } else {
      let arr = [...classesId];
      let index = arr.indexOf(id);
      if (index > 0) {
        arr.splice(index, 1);
        setClassesId(arr);
      } else {
        return;
      }
    }
  };

  return (
    <div className="dash-main-content">
      <Container fluid>
        <div className="dash-page-content">
          <div className="dash-page-title">
            <Row>
              <Col xs={12} sm={12} md={12}>
                <div className="dash-title-bar d-flex align-items-center justify-content-between">
                  <div className="title-lft d-flex align-items-center">
                    <div className="dash-icn">
                      <i className="fas fa-newspaper"></i>
                    </div>
                    <div className="dash-title-dis">
                      <h5>Add Whats New Page</h5>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <div className="dash-content-card">
            <div className="cmmn-form">
              <Form>
                <Row>
                  <Col md={12}>
                    <Form.Group className="mb-3" controlId="formBasicName">
                      <Form.Label>
                        Title
                        <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="News Title here."
                        name="title"
                        value={newData?.title}
                        onChange={handleChange}
                      />
                      <div className={'danger-frm'}>{clicked && validate()?.title}</div>
                    </Form.Group>
                  </Col>
                  <Col md={12}>
                    <Form.Group className="mb-3" controlId="formBasicDesi">
                      <Form.Label>
                        Short Description
                        <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        as="textarea"
                        placeholder="Enter Short Description."
                        rows={3}
                        name="short_descp"
                        value={newData?.short_descp}
                        onChange={handleChange}
                      />
                      <div className={'danger-frm'}>{clicked && validate()?.short_descp}</div>
                    </Form.Group>
                  </Col>
                  <Col md={12}>
                    <Form.Group className="mb-3" controlId="formBasicDesi">
                      <Form.Label>
                        News
                        <span className="text-danger">*</span>
                      </Form.Label>
                      <CKEditorComponent
                        isToolBar={true}
                        setLatestPost={setNews_descp}
                        latestPost={news_descp}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={12}>
                    <Form.Group controlId="formFileMultiple" className="mb-3">
                      <Form.Label>
                        Attach Files{' '}
                        <sub className="ms-1">
                          <i className="fas fa-info-circle"></i> (Image / Docs.)
                        </sub>
                      </Form.Label>
                      <Form.Control
                        type="file"
                        onChange={handleChange}
                        accept="image/*"
                        name="image"
                      />
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    {id && newsImage ? (
                      <div className="d-flex flex-wrap align-item-center">
                        <div className="border me-2">
                          <img
                            width="100px"
                            height="100px"
                            src={process.env.REACT_APP_API_DOMAIN + newsImage}
                            alt="img1"
                          />
                        </div>
                      </div>
                    ) : (
                      ''
                    )}
                  </Col>

                  <Col md={12}>
                    <Form.Group controlId="formFileMultiple" className="mb-3">
                      <Form.Label>
                        Target Audience{' '}
                        <sub className="ms-1">
                          <i className="fas fa-info-circle"></i> (all=All Users, Students=Users
                          Currently studing some where, others=Passouts.)
                        </sub>
                      </Form.Label>
                      <div className="target-radio-card d-flex ">
                        <Form.Label className="me-2">Select Audience Type</Form.Label>
                        {['radio'].map((type) => (
                          <div key={`inline-${type}`} className="mb-2">
                            <Form.Check
                              inline
                              label="All"
                              name="audience_type"
                              value="all"
                              checked={newData?.audience_type == 'all'}
                              onChange={handleChange}
                              type={type}
                              id={`inline-${type}-1`}
                              details
                            />
                            <Form.Check
                              inline
                              label="Students"
                              name="audience_type"
                              value="students"
                              type={type}
                              onChange={handleChange}
                              checked={newData?.audience_type == 'students'}
                              id={`inline-${type}-2`}
                            />
                            <Form.Check
                              inline
                              label="Others"
                              name="audience_type"
                              type={type}
                              onChange={handleChange}
                              value="others"
                              checked={newData?.audience_type == 'others'}
                              id={`inline-${type}-3`}
                            />
                          </div>
                        ))}
                      </div>
                    </Form.Group>
                  </Col>

                  <h5>Select Class For</h5>
                  <Col md={12}>
                    <Form.Group className="mb-3" controlId="formBasicName">
                      <Form.Label>Students:</Form.Label>
                      <Col>
                        <div className="multiselect mb-3">
                          <div
                            className="select-options"
                            role="presentation"
                            onClick={() => {
                              document.getElementById('checkboxes').classList.toggle('show');
                            }}>
                            <h6 className="mb-0">---Click here to select User's Classes---</h6>
                          </div>
                          <div id="checkboxes">
                            <Form>
                              <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Control placeholder="Search..." />
                              </Form.Group>
                              <div className="d-flex flex-wrap align-items-center justify-content-between ">
                                {classes &&
                                  newData?.audience_type == 'others' &&
                                  classes.map((data) => (
                                    <>
                                      <div className="mb-3 w20">
                                        <Form.Check
                                          id={`${data?.id}`}
                                          label={`${data?.program_name}`}
                                          checked={classesId?.includes(data?.id)}
                                          onChange={(e) => {
                                            coursesCheckBox(e, data?.id);
                                          }}
                                        />
                                      </div>
                                    </>
                                  ))}
                              </div>
                            </Form>
                          </div>
                        </div>
                      </Col>
                    </Form.Group>
                  </Col>

                  <Col md={12}>
                    <Form.Group className="mb-3" controlId="formBasicName">
                      <Form.Label>
                        Tagging:
                        <sub className="ms-1">
                          <i className="fas fa-info-circle"></i> (Enter text tag e.g Computers,
                          Press Esc key for done tagging.)
                        </sub>
                      </Form.Label>

                      <AsyncPaginate
                        className="w100  p16 mr_normal_padding_l mr_medium_padding_r"
                        loadOptions={loadOptions}
                        id={Math.random()}
                        getOptionValue={(option) => {
                          return option.name;
                        }}
                        getOptionLabel={(option) => {
                          return option.name;
                        }}
                        value={value}
                        onChange={setValue}
                        additional={{
                          page: 1
                        }}
                        debounceTimeout={500}
                        isSearchable={true}
                        isMulti
                      />
                    </Form.Group>
                  </Col>

                  <Col md={12}>
                    <Form.Group controlId="formFileMultiple" className="mb-3">
                      <Form.Label>
                        Article reach scope:{' '}
                        <sub className="ms-1">
                          <i className="fas fa-info-circle"></i> (Keep checked all will show this
                          content to all users deal in following checklist.)
                        </sub>
                      </Form.Label>
                      <div className="target-radio-card">
                        {['checkbox'].map((type) => (
                          <div key={`inline-${type}`} className="mb-2">
                            <Form.Check
                              inline
                              label="TECHNICAL"
                              name="articleScope"
                              type={type}
                              // checked={newData?.articleScope == 'TECHNICAL'}
                              onChange={(e) => {
                                handleCheckBoxSelection('technical');
                              }}
                              value="technical"
                              checked={checkboxes.includes('technical')}
                              id={`inline-${type}-1`}
                            />
                            <Form.Check
                              inline
                              label="BUSINESS"
                              name="articleScope"
                              type={type}
                              value="business"
                              checked={checkboxes.includes('business')}
                              id={`inline-${type}-2`}
                              onChange={(e) => {
                                handleCheckBoxSelection('business');
                              }}
                            />
                            <Form.Check
                              inline
                              label="INTERPERSONAL"
                              name="articleScope"
                              type={type}
                              value="interpersonal"
                              checked={checkboxes.includes('interpersonal')}
                              id={`inline-${type}-3`}
                              onChange={(e) => {
                                handleCheckBoxSelection('interpersonal');
                              }}
                            />
                            <Form.Check
                              inline
                              label="HOBBIES"
                              name="articleScope"
                              value="hobbies"
                              checked={checkboxes.includes('hobbies')}
                              type={type}
                              id={`inline-${type}-3`}
                              onChange={(e) => {
                                handleCheckBoxSelection('hobbies');
                              }}
                            />
                          </div>
                        ))}
                      </div>
                    </Form.Group>
                  </Col>
                </Row>
                <div className="form-btns d-flex align-items-center mt-3">
                  {!id ? (
                    <Button
                      className="main-btn-grn"
                      onClick={(e) => {
                        handleSave(e);
                      }}>
                      Save
                    </Button>
                  ) : (
                    <Button className="main-btn-grn" onClick={updateHandler}>
                      Update
                    </Button>
                  )}
                  <strong>OR</strong>
                  <Button
                    className="main-btn-red"
                    onClick={() => {
                      navigate(-1);
                    }}>
                    Cancel
                  </Button>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}
