import { useEffect, useRef, useState } from 'react';
import { Alert, Button, Col, Container, Form, Modal, Row, Table } from 'react-bootstrap';
import ReactDatePicker from 'react-datepicker';
import ReactPaginate from 'react-paginate';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Loader from '../../components/commoncomponents/loader/loader-large';
import useDocumentTitle from '../../globals/useDocumentTitle';
import { HideAlert } from '../../redux/action';
import * as adminServices from '../../services/AuthServices';
import showNotification from '../../services/NotificationService';
import FilterBy from './FilterBy';
let intervalId;

export default function ListUsers() {
  const reduxShowAlert = useSelector((state) => state?.alert?.showAlertBox);
  const paths = useSelector((state) => state?.adminPaths?.paths);
  const instituteId = useLocation()?.state;
  useDocumentTitle('Manage Users | AlmaBay');
  const navigate = useNavigate();
  const childRef = useRef();
  const [list, setList] = useState([]);
  const [response, setResponse] = useState('');
  const [loading, setLoading] = useState(false);
  const [sortType, setSortType] = useState('DESC');
  const [sortField, setSortField] = useState('user_id');
  const [chekcedId, setCheckedId] = useState([]);
  const [allId, setAllId] = useState([]);
  const [showalert, setShowAlert] = useState(false);
  const [registerStartDate, setRegisterStartDate] = useState(null);
  const [registarEndDate, setRegisterEndDate] = useState(null);
  const dispatch = useDispatch();
  const [massageView, setMassageView] = useState('');
  const [deleteAlert, setDeleteAlert] = useState(false);
  const [searchedData, setSearchData] = useState({
    user_id: '',
    name: '',
    email: '',
    status: ''
  });
  const [currentPage, setCurrentPage] = useState(1);

  const [selectedExportParameter, setSelectedExportParameter] = useState([]);
  const exportParmetersSelectionHandler = (id) => {
    if (selectedExportParameter.includes(id)) {
      let result = selectedExportParameter.filter((param) => param != id);
      setSelectedExportParameter(result);
    } else {
      setSelectedExportParameter(selectedExportParameter.concat(id));
    }
  };

  useEffect(() => {
    if (instituteId != null) {
      apiCall('', '', '', '', '', '', '', '', '', instituteId);
    } else {
      apiCall();
    }
  }, [instituteId]);

  useEffect(() => {
    const timeId = setTimeout(() => {
      // After 3 seconds set the show value to false
      setShowAlert(false);
      setMassageView('');
    }, 3000);

    const timeId1 = setTimeout(() => {
      dispatch(HideAlert());
    }, 3000);
    const timeId2 = setTimeout(() => {
      setDeleteAlert(false);
    }, 3000);
    return () => {
      clearTimeout(timeId);
      clearTimeout(timeId1);
      clearTimeout(timeId2);
    };
  }, [showalert, deleteAlert]);

  const apiCall = (
    page = 1,
    sortfield = 'user_id',
    order = 'DESC',
    user_id = '',
    first_name = '',
    email = '',
    status = '',
    reg_date = null,
    to_reg_date = null,
    institute_id = ''
  ) => {
    setLoading(true);

    adminServices
      .getUserList(
        page,
        sortfield,
        order,
        user_id,
        first_name,
        email,
        status,
        reg_date,
        to_reg_date,
        institute_id
      )
      .then((res) => {
        setLoading(false);
        if (res?.status === 200) {
          setList(res?.data?.detail?.users);
          setAllId(res?.data?.detail?.users?.map((data) => data?.user_id));
          setResponse(res?.data?.detail?.total_users);
        }
      });
  };

  //test...

  const onChangeSearch = (e) => {
    const { name, value } = e.target;
    setSearchData({ ...searchedData, [name]: value });
  };

  const handlePageClick = (a) => {
    let page = a?.selected + 1;
    setCurrentPage(page);
    apiCall(
      page,
      sortField,
      sortType,
      searchedData.user_id,
      searchedData.first_name,
      searchedData.email,
      searchedData.status,
      registerStartDate,
      registarEndDate,
      instituteId === null ? '' : instituteId
    );
  };

  const searchHandler = (e) => {
    e.preventDefault();
    apiCall(
      1,
      sortField,
      sortType,
      searchedData.user_id,
      searchedData.first_name,
      searchedData.email,
      searchedData.status,
      registerStartDate,
      registarEndDate,
      instituteId == null ? '' : instituteId
    );
    setCurrentPage(1);
  };

  const resetHandler = (e) => {
    e.preventDefault();
    setSearchData({
      user_id: '',
      first_name: '',
      email: '',
      status: ''
    });
    setSortType('DESC');

    setRegisterStartDate(null);
    setRegisterEndDate(null);
    apiCall();
  };

  const sortHandler = (e, type) => {
    e.preventDefault();
    let currentSort = sortType === 'ASC' ? 'DESC' : 'ASC';
    setSortType(currentSort);
    setSortField(type);
    apiCall(
      currentPage,
      sortField,
      currentSort,
      searchedData.user_id,
      searchedData.first_name,
      searchedData.email,
      searchedData.status,
      registerStartDate,
      registarEndDate,
      instituteId === null ? '' : instituteId
    );
  };

  const stateHandler = (value, id) => {
    setLoading(true);
    let body = {
      id: id
    };
    adminServices.deleteUserDetail(body).then((res) => {
      setLoading(false);
      if (res?.status === 200) {
        document.getElementById('select').value = '';
        if (value == '2') {
          setDeleteAlert(true);
        }
        setList(
          list?.filter((data) => {
            return data.user_id != body.id;
          })
        );
        apiCall();
      }
    });
  };

  const selectHandler = (value, id) => {
    switch (value) {
      case 'edit':
        navigate('/admin/users/create', { state: id });
        break;
      case 'view':
        navigate(`/admin/users/view/${id}`);
        break;
      case '0':
        stateHandler(value, id);
        break;
      case '2':
        let confirm = window.confirm('Do you want to delete user?');
        if (confirm) {
          stateHandler(value, id);
        }
        break;
      default:
        break;
    }
  };

  const globalActionHandler = (e) => {
    if (chekcedId.length !== 0) {
      setShowAlert(false);
      let body = {
        id: chekcedId.join(),
        status: e.target.value
      };
      setLoading(true);
      if (confirm('Do you want to continue ?') === true) {
        adminServices.globalStateChange(body).then((res) => {
          setLoading(false);
          if (res?.status === 200) {
            setMassageView(res?.data?.message);
            setShowAlert(true);
            apiCall();
            setCheckedId([]);
            document.getElementById('global').value = '';
          } else {
            document.getElementById('global').value = '';
          }
        });
      } else {
        document.getElementById('global').value = '';
        return;
      }
    } else {
      document.getElementById('global').value = '';
      setShowAlert(true);
      alertHandler();
    }
  };

  const alertHandler = () => {
    clearInterval(intervalId);
    intervalId = setInterval(function () {
      setShowAlert(false);
    }, 5000);
  };
  // for filter
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [isExportDialogOpen, setIsExportDialogOpen] = useState(false);
  const seq = [
    'email',
    'gender',
    'mobile',
    'dob',
    'website',
    'name',
    'institute_name',
    'reg_id',
    'department_name',
    'course_name',
    'marital_status',
    'organisation',
    'designation',
    'year_to',
    'industry_name'
  ];

  const exportParmeters = [
    {
      id: 'email',
      label: 'email'
    },
    {
      id: 'gender',
      label: 'gender'
    },
    {
      id: 'mobile',
      label: 'mobile'
    },
    {
      id: 'dob',
      label: 'dob'
    },
    {
      id: 'website',
      label: 'website'
    },
    {
      id: 'name',
      label: 'name'
    },
    {
      id: 'institute_name',
      label: 'institute_name'
    },
    {
      id: 'reg_id',
      label: 'reg_id'
    },
    {
      id: 'department_name',
      label: 'department_name'
    },
    {
      id: 'course_name',
      label: 'course_name'
    },
    {
      id: 'marital_status',
      label: 'marital_status'
    },

    {
      id: 'organisation',
      label: 'organisation'
    },

    {
      id: 'designation',
      label: 'designation'
    },
    {
      id: 'year_to',
      label: 'year_to'
    },
    {
      id: 'industry_name',
      label: 'industry_name'
    }
  ];
  const handleExportDialogClose = () => {
    selectedExportParameter.sort(function (a, b) {
      return seq.indexOf(a) - seq.indexOf(b);
    });

    // let body = {
    //   exportSelectedColumns: exportParmeters
    //     .map((data) => {
    //       return data?.id;
    //     })
    //     .toString(),
    //   id: chekcedId?.toString()
    // };
    let body = {
      exportSelectedColumns: selectedExportParameter?.toString(),
      id: chekcedId?.toString()
    };

    // if (chekcedId?.length > 0 && selectedExportParameter?.length > 0) {
    adminServices.exportUser(body).then((res) => {
      if (res?.status === 200) {
        setIsExportDialogOpen(false);
        showNotification('success', res.data.message);
        // window.open(process.env.REACT_APP_API_URL + res?.data?.filepath);
      }
    });
    // }
    // else if (chekcedId?.length == 0) {
    //   showNotification('danger', 'Please select atleast one user to export.');
    // }
    //  else {
    //   showNotification('danger', 'Please select atleast one column to export.');
    // }
  };

  /**
   * get filter data -api
   */
  const globalFilter = (data) => {
    if (data) {
      let body = {
        filter: data
      };
      adminServices.filterByUser(body).then((res) => {
        if (res?.status === 200) {
          setList(res?.data?.detail?.users);
          setResponse(res?.data?.detail?.total_users);
          setShow(false);
        }
      });
    } else {
      apiCall();
      setShow(false);
    }
  };

  /**
   * function to select all checkbox
   * @param {*} e
   */
  const handleSelectAll = (e) => {
    if (e.target.checked === true) {
      allId.forEach((id) => {
        if (!chekcedId.includes(id)) {
          setCheckedId((prev) => {
            return [...prev, id];
          });
        }
      });
    } else {
      setCheckedId([
        ...chekcedId.filter((id) => {
          return allId.indexOf(id) == -1;
        })
      ]);
    }
  };

  /**
   * function to select each checkbox individually
   * @param {*} e
   * @param {*} id
   */
  const handleMultipleCheckBox = (e, id) => {
    if (chekcedId.includes(id)) {
      setCheckedId(
        chekcedId.filter((data) => {
          return data !== id;
        })
      );
    } else {
      setCheckedId([...chekcedId, id]);
    }
  };

  return (
    <>
      <div className="dash-main-content">
        <Container fluid>
          <div className="dash-page-content">
            <div className="dash-page-title">
              <Row>
                <Col xs={12} sm={12} md={12}>
                  <div className="dash-title-bar d-flex align-items-center justify-content-between">
                    <div className="title-lft d-flex align-items-center">
                      <div className="dash-icn">
                        <i className="fa fa-users"></i>
                      </div>
                      <div className="dash-title-dis">
                        <h5>
                          User <span>Listing</span>
                        </h5>
                        <h5>
                          Total User(s): <span>{response}</span>
                        </h5>
                      </div>
                    </div>

                    <div className="title-rt-btn d-flex align-items-center">
                      <Link
                        className="green_button_admin mx-2"
                        to={
                          paths?.includes('admin/users/create') ? '/admin/users/create' : '/admin'
                        }>
                        Add User
                      </Link>
                      <Link
                        className="green_button_admin mx-2"
                        to="#0"
                        onClick={() => {
                          setIsExportDialogOpen(true);
                          setSelectedExportParameter([]);
                        }}>
                        Export User
                      </Link>
                      <div className="head-instite-sec">
                        <Form.Select
                          aria-label="Default select example"
                          id="global"
                          onChange={(e) => {
                            globalActionHandler(e);
                          }}>
                          <option value="">Choose Action</option>
                          <option value="approved">Approved</option>
                          <option value="pending">Pending</option>
                          <option value="rejected">Rejected</option>
                          <option value="delete">Delete</option>
                        </Form.Select>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="dash-content-card">
              <Alert variant={'success'} className={`${reduxShowAlert ? '' : 'd-none'}`}>
                User has been added successfully
              </Alert>
              <Alert
                variant={massageView ? 'success' : 'danger'}
                className={`${showalert ? '' : 'd-none'}`}>
                {massageView ? massageView : 'Please select any record'}
              </Alert>
              <Alert variant={'success'} className={`${deleteAlert ? '' : 'd-none'}`}>
                User deleted successfully.
              </Alert>
              {/* <Alert variant={'danger'} className={`${showalert ? '' : 'd-none'}`}>
                Please Select Any Record
              </Alert> */}
              <div className="table-responsive">
                <Table className="custom-table-data" striped bordered hover>
                  <thead>
                    <tr>
                      <th>
                        <Form.Check
                          type="checkbox"
                          id="checkbox"
                          name="checkall"
                          checked={
                            allId?.every((elem) => {
                              return chekcedId?.includes(elem);
                            }) && list?.length > 0
                              ? true
                              : false
                          }
                          onChange={(e) => handleSelectAll(e)}
                          label=""
                        />
                      </th>
                      <th>
                        <Link
                          to="#"
                          onClick={(e) => {
                            sortHandler(e, 'user_id');
                          }}>
                          Id
                          <img src={require('../../assets/images/sort.png')} alt="sort" />
                        </Link>
                      </th>
                      <th>
                        <Link
                          to="#"
                          onClick={(e) => {
                            sortHandler(e, 'first_name');
                          }}>
                          Name
                          <img src={require('../../assets/images/sort.png')} alt="sort" />
                        </Link>
                      </th>
                      <th>
                        <Link
                          to="#"
                          onClick={(e) => {
                            sortHandler(e, 'email');
                          }}>
                          Email
                          <img src={require('../../assets/images/sort.png')} alt="sort" />
                        </Link>
                      </th>
                      <th>Register Date</th>
                      <th>
                        <Link
                          to="#"
                          onClick={(e) => {
                            sortHandler(e, 'status');
                          }}>
                          State
                          <img src={require('../../assets/images/sort.png')} alt="sort" />
                        </Link>
                      </th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="search-table">
                      <td></td>
                      <td>
                        <Form.Control
                          type="text"
                          value={searchedData.user_id}
                          name="user_id"
                          placeholder="ID"
                          onChange={(e) => {
                            onChangeSearch(e);
                          }}
                        />
                      </td>
                      <td>
                        <Form.Control
                          type="text"
                          value={searchedData.first_name}
                          name="first_name"
                          placeholder="Name"
                          onChange={(e) => {
                            onChangeSearch(e);
                          }}
                        />
                      </td>
                      <td>
                        <Form.Control
                          type="text"
                          value={searchedData.email}
                          name="email"
                          placeholder="Email"
                          onChange={(e) => {
                            onChangeSearch(e);
                          }}
                        />
                      </td>
                      <td>
                        <div className="d-flex">
                          <ReactDatePicker
                            selected={registerStartDate}
                            onChange={(date) => setRegisterStartDate(date)}
                            placeholderText="From Date"
                          />
                          <span className="mx-2">To</span>
                          <ReactDatePicker
                            selected={registarEndDate}
                            onChange={(date) => setRegisterEndDate(date)}
                            placeholderText="To Date"
                          />
                        </div>
                      </td>
                      <td>
                        <Form.Select
                          aria-label="Default select example"
                          name="status"
                          value={searchedData?.status}
                          onChange={(e) => {
                            onChangeSearch(e);
                          }}>
                          <option value="">Select</option>
                          <option value={'pending'}>Pending</option>
                          <option value={'approved'}>Approved</option>
                          <option value={'rejected'}>Rejected</option>
                        </Form.Select>
                      </td>
                      <td className="serac-btns" colSpan={2}>
                        <button
                          className="green_button_admin"
                          type="button"
                          onClick={(e) => {
                            searchHandler(e);
                          }}>
                          Search
                        </button>
                        {/* <Button
                          className="main-btn-red ms-2"
                          onClick={(e) => {
                            resetHandler(e);
                          }}>
                          Reset
                        </Button> */}
                      </td>
                    </tr>
                    {list && list?.length ? (
                      list?.map((data, index) => {
                        return (
                          <tr key={index}>
                            <td>
                              <Form.Check
                                type="checkbox"
                                // name="id"
                                // id={`id${data?.user_id}`}
                                // checked={chekcedId?.includes(data?.user_id)}
                                // onChange={() => handleMultipleCheckBox(data?.user_id)}
                                name={data?.name}
                                checked={chekcedId?.includes(data?.user_id)}
                                onChange={(e) => {
                                  handleMultipleCheckBox(e, data?.user_id);
                                }}
                                label=""
                              />
                            </td>
                            <td>{data?.user_id || 'NA'}</td>
                            <td>{data?.name || 'NA'}</td>
                            <td>{data?.email || 'NA'}</td>
                            <td>{data?.regOn || 'NA'}</td>
                            <td>{data?.status || 'NA'}</td>
                            <td>
                              <Form.Select
                                aria-label="Default select example"
                                id="select"
                                value={''}
                                onChange={(e) => {
                                  selectHandler(e.target.value, data?.user_id);
                                }}>
                                <option value="">Action</option>
                                {paths?.includes('admin/users/create') && (
                                  <option value="edit">Edit</option>
                                )}

                                <option value="view">View</option>
                                <option
                                  value="2"
                                  // onClick={() => deleteHandler(data?.user_id)}
                                >
                                  Delete
                                </option>
                              </Form.Select>
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr className="text-center">
                        <td colSpan={8}>Data Not Found.</td>
                      </tr>
                    )}
                  </tbody>
                </Table>
                {Math.ceil(response / 50) > 1 ? (
                  <div className="react-page d-flex justify-content-center">
                    <ReactPaginate
                      previousLabel={currentPage > 0 ? '<' : ''}
                      forcePage={currentPage - 1}
                      nextLabel={response > 0 ? '>' : ''}
                      onPageChange={handlePageClick}
                      activeClassName={'page-link active'}
                      pageCount={Math.ceil(response / 50)}
                    />
                  </div>
                ) : (
                  ''
                )}
              </div>
              <div className="user-lst-filter">
                <Button className="usr-filter-btn" onClick={handleShow}>
                  <img src={require('../../assets/images/filter.png')} alt="filterbtn" />
                </Button>
                <Button
                  className="filter-reset-btn"
                  onClick={() => {
                    childRef.current.resetHandler();
                  }}>
                  <img src={require('../../assets/images/reset.png')} alt="chatuser" />
                </Button>
              </div>
            </div>

            {/* filter for user list */}
            <FilterBy
              handleClose={handleClose}
              show={show}
              ref={childRef}
              globalFilter={globalFilter}
            />
            {/* end */}
          </div>
        </Container>
      </div>
      {loading ? <Loader /> : ''}
      <Modal
        show={isExportDialogOpen}
        onHide={() => setIsExportDialogOpen(false)}
        contentClassName="export-model">
        <Modal.Header closeButton onClick={() => setIsExportDialogOpen(false)}>
          <Modal.Title>Select Column</Modal.Title>
        </Modal.Header>
        <Modal.Body className="export-body">
          {exportParmeters?.map((paramName, index) => {
            return (
              <Form.Check
                type="checkbox"
                id={`checkbox${index}`}
                key={index}
                name={paramName.id}
                checked={selectedExportParameter.includes(paramName.id)}
                label={paramName.label}
                className="export-model-label"
                onChange={(e) => exportParmetersSelectionHandler(e.target.name)}
              />
            );
          })}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={handleExportDialogClose}
            className="export-submit-btn">
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
      {loading ? <Loader /> : ''}
    </>
  );
}
