import { useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { useState } from 'react';
import * as apiService from '../../../services/JobServices';
import showNotification from '../../../services/NotificationService';
import { Alert } from 'react-bootstrap';

function RecomendModal(props) {
  const { handleClose3, show3, jobId } = props;
  const [recommendedList, setRecommendedList] = useState([]);
  const [selectedRecommended, setSelectedRecommended] = useState([]);
  const [prevStateLeft, setPrevStateLeft] = useState([]);
  const [prevStateRight, setPrevStateRight] = useState([]);
  const [errorMessage] = useState('Please select atleast one person');
  const [showalert, setShowalert] = useState(false);

  useEffect(() => {
    if (jobId) {
      getRecommendList(jobId);
    }
  }, [jobId]);

  const getRecommendList = (id) => {
    // api call
    apiService
      .recommendList(id)
      .then((res) => {
        if (res?.status === 200) {
          setRecommendedList(res?.data?.data?.friendList ?? []);
          setPrevStateLeft(res?.data?.data?.friendList ?? []);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const addRecommendHandler = (data) => {
    let arr = [...selectedRecommended];
    let temp = recommendedList.filter((val) => val.id != data?.id);
    arr.push(data);
    setRecommendedList(temp);
    setPrevStateLeft(temp);
    setSelectedRecommended(arr);
    setPrevStateRight(arr);
    setShowalert(false);
  };

  const removeRecommendHandler = (data) => {
    let temp = selectedRecommended.filter((val) => val.id != data?.id);
    let tempArr = [...recommendedList];
    tempArr.push(data);
    setRecommendedList(tempArr);
    setSelectedRecommended(temp);
    setPrevStateRight(temp);
    setPrevStateLeft(tempArr);
  };

  const searchHandlerRight = (e) => {
    const { value } = e.target;
    if (!value?.trim()) {
      setRecommendedList(prevStateLeft);
      return;
    } else {
      let temp = prevStateLeft.filter((val) =>
        val.name.toLocaleLowerCase().includes(value.toLocaleLowerCase())
      );
      setRecommendedList(temp);
    }
  };

  const searchHandlerLeft = (e) => {
    const { value } = e.target;
    if (!value?.trim()) {
      setSelectedRecommended(prevStateRight);
      return;
    } else {
      let temp = prevStateRight.filter((val) =>
        val.name.toLocaleLowerCase().includes(value.toLocaleLowerCase())
      );
      setSelectedRecommended(temp);
    }
  };

  const sendRecommendHandler = async (e) => {
    e.preventDefault();
    try {
      if (!selectedRecommended.length) {
        setShowalert(true);
      } else {
        let body = {
          preSelectedOptions: selectedRecommended?.map((data) => data?.id).join()
        };
        const res = await apiService.sendRecommendation(jobId, body);
        if (res?.status === 200) {
          showNotification('success', res?.data?.message);
          setSelectedRecommended([]);
          handleClose3();
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Modal
      style={{ zIndex: '999999' }}
      show={show3}
      onHide={handleClose3}
      className="recommend-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title>Recommend this job to your friends that you think are suitable</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="recommend-lst-card">
          <Alert variant={'danger'} className={`${showalert ? '' : 'd-none'}`}>
            {errorMessage}
          </Alert>
          <h4 className="text-orange text-center mb-3">SELECT FRIENDS FROM LIST</h4>
          <div className="recomm-compare-lst d-flex align-item-center justify-content-between mb-2">
            <div className="search-lst-card">
              <div className="search-lst-frm">
                <Form.Group className="mb-0" controlId="formBasicEmail">
                  <Form.Control
                    type="search"
                    placeholder="Search..."
                    onChange={(e) => {
                      searchHandlerRight(e);
                    }}
                  />
                </Form.Group>
              </div>
              <div className="search-lst">
                <ul>
                  {recommendedList && recommendedList?.length ? (
                    recommendedList?.map((data, index) => (
                      <li
                        key={index}
                        role="presentation"
                        tabIndex={index}
                        onClick={() => {
                          addRecommendHandler(data, index);
                        }}
                      >
                        {data?.name}
                      </li>
                    ))
                  ) : (
                    <li>No Friend to Recommend</li>
                  )}
                </ul>
              </div>
            </div>
            <span className="compare-btn">
              <i className="fas fa-exchange-alt"></i>
            </span>
            <div className="search-lst-card">
              <div className="search-lst-frm">
                <Form.Group className="mb-0" controlId="formBasicEmail">
                  <Form.Control
                    type="search"
                    placeholder="Search..."
                    onChange={(e) => {
                      searchHandlerLeft(e);
                    }}
                  />
                </Form.Group>
              </div>
              <div className="search-lst">
                <ul>
                  {selectedRecommended?.map((data, index) => (
                    <li
                      role="presentation"
                      tabIndex={index}
                      onClick={() => {
                        removeRecommendHandler(data);
                      }}
                      key={index}
                    >
                      {data?.name}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
          <Button
            className="recomm-btn"
            onClick={(e) => {
              sendRecommendHandler(e);
            }}
          >
            <i className="far fa-check-circle me-2"></i>Send Recommendation
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default RecomendModal;
