import moment from 'moment';
import { useEffect, useState } from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { getArchive, getInsNews } from '../../../services/InstituteServices';
import Loader from '../../commoncomponents/loader/loader-large';

function NewsPage() {
  const [newsList, setNewsList] = useState([]);
  const [archiveList, setArchiveList] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getInfo();
  }, []);

  // get list
  const getInfo = () => {
    getInsNews().then((res) => {
      setLoading(false)
      if (res?.status == 200) {
        setNewsList(res?.data?.data);
      }
    });
    getArchive().then((res) => {
      if (res?.status == 200) {
        setArchiveList(res?.data?.data?.output);
      }
    });
  };

  return (
    <>
      <div className="wrappermain_outer bggreylight">
        <section
          className="breadcrum-banner"
          style={{
            backgroundImage: `url(${newsList?.banner
              ? process.env.REACT_APP_API_URL + newsList?.banner?.url
              : '/static/media/breadcrum-banner-img.1a6b2ed71ebb8768883c.png'
              })`
          }}
        >
          <Container>
            <Row>
              <Col sm={12}>
                <h1 className="Text-white">{newsList?.banner?.name || 'News'}</h1>
              </Col>
            </Row>
          </Container>
        </section>

        <section className="gallery-section">
          <Container>
            <Row>
              <Col sm={12}></Col>
              <Col lg={8}>
                <div className="news_conatiner blog_container">
                  <ul>
                    {newsList?.list && newsList?.list?.length ? (
                      newsList?.list?.map((data, index) => (
                        <li className="mb-3" key={index}>
                          <Link to={`/news-detail-page/${data?.slug}`}>
                            <Card className="p-3">
                              <div className="media">
                                <div className="media-body">
                                  <p className="mb-0">
                                    {moment(data?.date_modified).format('MMM DD,YYYY')}
                                  </p>
                                  <h5 className="mb-0">{data?.title}</h5>
                                </div>
                              </div>
                            </Card>
                          </Link>
                        </li>
                      ))
                    ) : (
                      <div className={loading ? "d-none" : "text-center nodata"}>
                        <img src={require('../../../assets/images/nodata.png')} alt="no_data" />
                        <p>Data Not Found</p>
                      </div>
                    )}
                  </ul>
                </div>
              </Col>
              <Col lg={4}>
                {archiveList?.length ? (
                  <div className="archive-section mb-4">
                    <h4>Archive</h4>
                    <ul>
                      {archiveList && archiveList?.length ? (
                        archiveList?.map((month, index) => (
                          <li key={index}>
                            <Link>
                              {month?.month_name} {month?.year_name} ({month?.count})
                            </Link>
                          </li>
                        ))
                      ) : (
                        <></>
                      )}
                    </ul>
                  </div>
                ) : (
                  <></>
                )}

                {/* <div className='tags_container mb-5'>
                                <h5>Tags</h5>
                                <div className='d-flex flex-wrap'>
                                    <span className='single-tag m-2'>
                                        Tag Name
                                    </span>
                                    <span className='single-tag m-2'>
                                        Tag Name
                                    </span>
                                    <span className='single-tag m-2'>
                                        Tag Name
                                    </span>
                                    <span className='single-tag m-2'>
                                        Tag Name
                                    </span>
                                    <span className='single-tag m-2'>
                                        Tag Name
                                    </span>
                                    <span className='single-tag m-2'>
                                        Tag Name
                                    </span>
                                    <span className='single-tag m-2'>
                                        Tag Name
                                    </span>
                                </div>
                            </div> */}
              </Col>
            </Row>
          </Container>
        </section>
      </div>

      <div className="lgn-link pb-4">
        <Container>
          <div className="logn-lnk-inr">
            <p>
              <Link to="/institute-register">Register</Link> / <Link to="/institute-login">Login</Link> to browse
              members by Year, Name, Company, Profession or Location
            </p>
          </div>
        </Container>
      </div>
      {loading ? <Loader /> : <></>}
    </>
  );
}

export default NewsPage;
