import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { Dropdown } from 'react-bootstrap';

import { useState } from 'react';
// import { loadEventCategoryOptions } from '../../../../globals/helper';
import AsyncSelect from 'react-select/async';
// import { loadCityOpt } from '../../../../super-admin/AdminHelper';
import showNotification from '../../../../services/NotificationService';
import { getEventRecipients, shareEventOnSocial } from '../../../../services/SocialServices';
import moment from 'moment';

function ShareSocialModal(props) {
  const { show, userId, handleClose4, dropdownButton, shareData } = props;

  const [text, setText] = useState('');
  const [timeline, setTimeline] = useState('timeline');
  const [recipientValue, setRecipientValue] = useState([]);
  const [ids, setIds] = useState([]);

  const handleShare = () => {
    const body = {
      event_id: userId,
      recipients: ids?.toString(),
      text: text,

      recipient_type: timeline
    };
    shareEventOnSocial(body).then((res) => {
      if (res?.status === 200) {
        handleClose4();
        showNotification('success', res?.data?.detail);
      }
    });
  };

  // load options using API call
  const loadOptions = (inputValue = 'Mohali') => {
    return getEventRecipients(inputValue).then((res) => {
      if (res?.status === 200) {
        let tempArr = [];
        if (res?.data?.result) {
          const data = res?.data.result;
          data?.map((data) => {
            tempArr.push({ id: data?.id, value: data?.id, label: data?.name });
          });
        }
        return tempArr;
      }
    });
  };

  const handleChangeSelect = (e) => {
    let selectedIds = [];
    e?.map((data) => {
      return selectedIds.push(data?.id);
    });
    setIds(selectedIds);
    setRecipientValue(e);
  };

  return (
    <Modal show={show} onHide={handleClose4} className="recommend-modal share-event-modal">
      <Modal.Header closeButton>
        <Modal.Title>
          <select className="me-2 email-icon" onChange={(e) => setTimeline(e.target.value)}>
            <option value="timeline">Share on your timeline</option>
            <option value="groups">Share in group</option>
          </select>
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div className="recommend-lst-card">
          <div className="recomm-compare-lst align-item-center justify-content-between mb-2">
            <div>
              <Form>
                {timeline === 'groups' ? (
                  <AsyncSelect
                    isMulti
                    name="tag_fields"
                    className="basic-multi-select mt-3"
                    classNamePrefix="select"
                    loadOptions={loadOptions}
                    value={recipientValue}
                    onChange={handleChangeSelect}
                    placeholder="Add Tags to your question."
                  />
                ) : (
                  ''
                )}
              </Form>
            </div>

            {/* code */}
          </div>
        </div>

        <div className="share-event-modal p-3">
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
              <Form.Control
                as="textarea"
                type="text"
                placeholder="Tell more people about the event .."
                name="text"
                onChange={(e) => setText(e.target.value)}
                rows={4}
              />
            </Form.Group>
          </Form>
          <div className="one-event-card">
            <div className="event-media-lft">
              <img src={shareData?.eventDetails?.avatar_url} alt="chatuser" />
            </div>
            <div className="event-media-rt">
              <div className="d-flex justify-content-between">
                <div>
                  <h4 className="titlefont">{shareData?.eventDetails?.name}</h4>
                  <ul className="public-event">
                    <li>{shareData?.eventDetails?.event_privacy} event</li>
                    <li> {shareData?.eventDetails?.category_name}</li>
                  </ul>
                </div>
                <span className="event-date-hd position-relative">
                  <h4>{moment(shareData?.eventDetails?.from_event_date).format('DD')}</h4>

                  <p>{moment(shareData?.eventDetails?.from_event_date).format('MMM')}</p>
                </span>
              </div>

              <div className="date-sec">
                <p className="date">Date</p>
                <p className="address-time">
                  {/* 13 Mar 2023 3:43 PM - 15 Jun 2023 3:43 PM */}
                  {moment(shareData?.eventDetails?.from_event_date).format('YYYY-MM-DD') +
                    ' ' +
                    moment(shareData?.eventDetails?.from_event_date).format('h:mm:ss')}
                  -
                  {moment(shareData?.eventDetails?.to_event_date).format('YYYY-MM-DD') +
                    ' ' +
                    moment(shareData?.eventDetails?.to_event_date).format('h:mm:ss')}
                </p>
              </div>
              <div className="date-sec">
                <p className="date">Address</p>
                <p className="address-time">{shareData?.eventDetails?.venue}</p>
              </div>
              <ul className="public-event mt-5">
                <li>{shareData?.eventStats?.interested_count} interested</li>
                <li>{shareData?.eventStats?.going_count} going</li>
                <li>{shareData?.eventStats?.invite_count} invited</li>
              </ul>
              <Dropdown className="mt-3">
                <Dropdown.Toggle className="blue-interest" variant="success" id="dropdown-basic">
                  <i className="fas fa-check me-2"></i>
                  {dropdownButton}
                  {/* {shareData?.eventStats?.going_count > 0 ? 'Going' : 'Interested'} */}
                </Dropdown.Toggle>
              </Dropdown>
            </div>
          </div>
        </div>
        <div className="search-lst-card">
          <div className="search-lst-frm"></div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-warning p-2" onClick={handleShare}>
          {' '}
          share
        </button>
        <button className="wht-btn-grey p-2" onClick={handleClose4}>
          {' '}
          cancel
        </button>
      </Modal.Footer>
    </Modal>
  );
}

export default ShareSocialModal;
