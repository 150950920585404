/**
@copyright  :   ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
@author     :   Shiv Charan Panjeta 
 
All Rights Reserved.
Proprietary and confidential :  All information contained here in is, and remains
the property of ToXSL Technologies Pvt. Ltd. and it's partners.
Unauthorized copying of this file, via any medium is strictly prohibited.
*/

import { Link, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import HeaderLogin from '../../commoncomponents/HeaderLogin';
import FooterMain from '../../commoncomponents/FooterMain';
import TopTabbings from './TopTabbings';
import Dropdown from 'react-bootstrap/Dropdown';
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import Form from 'react-bootstrap/Form';
import GroupDiscover from '../../commoncomponents/newsfeeds/GroupDiscover';
import WhatsNew from '../../commoncomponents/newsfeeds/WhatsNew';
import Upcoming from '../../commoncomponents/newsfeeds/Upcoming';
import PeopleKnow from '../../commoncomponents/newsfeeds/PeopleKnow';
import FollowCompany from '../../commoncomponents/newsfeeds/FollowCompany';
import CampusNews from '../../commoncomponents/newsfeeds/CampusNews';
import CampusnewsText from '../../commoncomponents/newsfeeds/CampusnewsText';
import ArticalBlogs from '../../commoncomponents/newsfeeds/ArticalBlogs';
import * as companyServices from '../../../services/CompanyServices';
import { filePath } from '../../../globals/constants';
import { useRef } from 'react';
import { useOnClickOutside } from '../../../globals/helper';
import useDocumentTitle from '../../../globals/useDocumentTitle';
import { MiniFooter } from '../../MiniFooter';
import showNotification from '../../../services/NotificationService';
import { CompanySimmer } from '../../commoncomponents/skelton/allskelton';
import NoDataFound from '../../commoncomponents/NoDataFound';

function CompanyPage() {
  useDocumentTitle('AlmaBay');
  const [isActive, setIsActive] = useState(false);
  const [manageLoading, setManageLoading] = useState(false);
  const [followLoading, setFollowLoading] = useState(false);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const handleClick = () => {
    setIsActive((current) => !current);
  };
  const ref = useRef();
  useOnClickOutside(
    ref,
    () => setIsActive(false),
    () => setFollowSearch('')
  );

  const [isActive1, setIsActive1] = useState(false);
  const handleClick2 = () => {
    setIsActive1((current) => !current);
  };
  const ref2 = useRef();
  useOnClickOutside(ref2, () => {
    setIsActive1(false);
    setManageSearch('');
  });
  const [isActive2, setIsActive2] = useState(false);

  const handleClick3 = () => {
    setIsActive2((current) => !current);
  };
  const [manageList, setManageList] = useState([]);
  const [followList, setFollowList] = useState([]);
  const [suggestedList, setSuggestedList] = useState([]);
  const [manageSearch, setManageSearch] = useState('');
  const [followSearch, setFollowSearch] = useState('');
  const [jobOpen, setJobOpen] = useState([]);

  useEffect(() => {
    getCompanyManagaeList();
    getJoinCompanyList();
    getDiscoverlist();
  }, []);

  const getCompanyManagaeList = (search) => {
    setManageLoading(true);
    companyServices.getManageCompany(search).then((res) => {
      setManageLoading(false);
      if (res?.status === 200) {
        setManageList(res?.data?.data?.userCompany ?? []);
      }
    });
  };

  const getJoinCompanyList = (search) => {
    setFollowLoading(true);
    companyServices.getJoinCompany(search).then((res) => {
      setFollowLoading(false);
      if (res?.status === 200) {
        setFollowList(res?.data?.data ?? []);
      }
    });
  };

  const getDiscoverlist = (search) => {
    setLoading(true);
    companyServices.getSuggestedCompany(search).then((res) => {
      if (res?.status === 200) {
        setLoading(false);
        setSuggestedList(res?.data?.data ?? []);
      }
    });
  };

  const handleUnfollowCompany = (id) => {
    const confirm = window.confirm('Do you want unfollow this company?');
    const body = {
      following_id: id,
      type: 'company'
    };
    if (confirm === true) {
      companyServices.unFollowCompany(body).then((res) => {
        if (res?.status === 200) {
          // setJobOpen(res?.data);
          getJoinCompanyList();
        }
      });
    }
    // const jobOpening = () => {
    //   companyServices.getJobListCompany().then((res) => {
    //     if (res?.status === 200) {
    //       setJobOpen(res?.data);
    //       getJoinCompanyList();
    //     }
    //   });
    // };
  };

  const jobOpening = () => {
    setLoading(true);
    companyServices.getJobListCompany().then((res) => {
      setLoading(false);
      if (res?.status === 200) {
        setJobOpen(res?.data);
      }
    });
  };

  const handleJoinCompany = (e, id) => {
    e.preventDefault();
    setLoading(true);
    let body = {
      following_id: id
    };
    companyServices.followCompanySuggestion(body).then((res) => {
      setLoading(false);
      if (res?.status === 200) {
        showNotification('success', 'Company follow successfully');
        getDiscoverlist();
      }
    });
  };

  return (
    <>
      <HeaderLogin className={'colour-bg'} state={'d-none'} />
      <div className="header-lnk-feeds">
        <div className="container">
          <TopTabbings state={'feed'} />
        </div>
      </div>
      <section className="feed-sec bg-grey">
        <Tab.Container id="left-tabs-example" defaultActiveKey="first">
          <div className="company-tab-top bg-white mb-3">
            <div className="container">
              <Row>
                <Col sm={12} xs={12} md={12}>
                  <Nav variant="pills">
                    <Nav.Item>
                      <Nav.Link
                        eventKey="first"
                        onClick={() => {
                          getCompanyManagaeList();
                          getJoinCompanyList();
                        }}>
                        Companies
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        eventKey="second"
                        onClick={() => {
                          getDiscoverlist();
                        }}>
                        Discover
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                </Col>
              </Row>
            </div>
          </div>

          <div className="container">
            <Row>
              <Col xs={12} md={8} xl={9} className="mb-4">
                <Tab.Content>
                  <Tab.Pane eventKey="first">
                    <div className="company-lft-dis mb-3">
                      <div className="company-lst bg-white p-4">
                        <div className="company-hd flex-wrap d-flex align-items-center justify-content-between mb-4">
                          <h4>COMPANIES YOU MANAGE</h4>
                          <div
                            className={isActive1 ? 'comp-hd-search blk-search' : 'comp-hd-search'}>
                            <Link onClick={handleClick2} to="#">
                              <i className="fas fa-search"></i>
                            </Link>
                            <Form.Group
                              className="search-card"
                              controlId="formBasicEmail"
                              ref={ref2}>
                              <Form.Control
                                type="search"
                                placeholder="Search Company"
                                name="name"
                                onChange={(e) => {
                                  getCompanyManagaeList(e.target.value);
                                  setManageSearch(e.target.value);
                                }}
                              />
                            </Form.Group>
                          </div>
                        </div>
                        {manageLoading && (
                          <div className="company-follow">
                            {Array.from(Array(6), () => {
                              return <CompanySimmer />;
                            })}
                          </div>
                        )}
                        <div className="company-follow">
                          {!manageLoading && manageList?.length !== 0 ? (
                            <>
                              {manageList?.map((data, index) => {
                                return (
                                  <div
                                    className="company-follow-card d-flex justify-content-between"
                                    key={index}>
                                    <div className="d-flex align-items-top">
                                      <div className="company-icn me-3">
                                        <Link
                                          to={'/jobs/company-details/' + data?.username}
                                          state={data}>
                                          <img
                                            target="_blank"
                                            src={
                                              data?.logo
                                                ? process.env.REACT_APP_API_DOMAIN +
                                                  filePath +
                                                  data?.logo
                                                : require('../../../assets/images/fortis.jpg')
                                            }
                                            alt=""
                                          />
                                        </Link>
                                      </div>
                                      <div className="company-discr">
                                        <h4>
                                          <Link
                                            to={'/jobs/company-details/' + data?.username}
                                            state={data}
                                            title={data?.name}>
                                            {data?.name}
                                          </Link>
                                        </h4>
                                        {data?.count != 0 ? (
                                          data?.count <= 1 ? (
                                            <p>{data?.count} Follower</p>
                                          ) : (
                                            <p>{data?.count} Followers</p>
                                          )
                                        ) : (
                                          ''
                                        )}
                                      </div>
                                    </div>
                                    <Dropdown>
                                      <Dropdown.Toggle variant="success" id="dropdown-basic">
                                        <i className="fas fa-ellipsis-v"></i>
                                      </Dropdown.Toggle>
                                      <Dropdown.Menu>
                                        <Dropdown.Item
                                          onClick={() =>
                                            navigate(
                                              `/jobs/company-details/${data?.username}?state=sixth`,
                                              { state: data }
                                            )
                                          }>
                                          Post New Job
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          onClick={() =>
                                            navigate(
                                              `/jobs/company-details/${data?.username}?state=seventh`,
                                              { state: data }
                                            )
                                          }>
                                          Edit Details
                                        </Dropdown.Item>
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </div>
                                );
                              })}
                            </>
                          ) : (
                            <>
                              {!manageLoading && manageList.length == 0 && (
                                <div className="d-flex w-100 justify-content-center">
                                  <NoDataFound text="No Company managed by you at the moment." />
                                </div>
                              )}
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="company-lft-dis">
                      <div className="company-lst bg-white p-4">
                        <div className="company-hd flex-wrap d-flex align-items-center justify-content-between mb-4">
                          <h4>COMPANIES YOU FOLLOW</h4>
                          <div
                            className={isActive ? 'comp-hd-search blk-search' : 'comp-hd-search'}>
                            <Link onClick={handleClick} to="#">
                              <i className="fas fa-search"></i>
                            </Link>
                            <Form.Group
                              className="search-card"
                              controlId="formBasicEmail"
                              ref={ref}>
                              <Form.Control
                                type="search"
                                placeholder="Search Company"
                                name="name"
                                onChange={(e) => {
                                  getJoinCompanyList(e.target.value);
                                  setManageSearch(e.target.value);
                                }}
                              />
                            </Form.Group>
                          </div>
                        </div>
                        {followLoading && (
                          <div className="company-follow">
                            {Array.from(Array(6), () => {
                              return <CompanySimmer />;
                            })}
                          </div>
                        )}
                        <div className="company-follow">
                          {!followLoading && followList?.length !== 0 ? (
                            <>
                              {followList?.map((data, index) => {
                                return (
                                  <div
                                    className="company-follow-card d-flex justify-content-between"
                                    key={index}>
                                    <div className="d-flex align-items-top">
                                      <div className="company-icn me-3">
                                        <Link
                                          to={'/jobs/company-details/' + data?.username}
                                          state={data}>
                                          <img
                                            src={
                                              data?.logo
                                                ? process.env.REACT_APP_API_DOMAIN + data?.logo
                                                : require('../../../assets/images/fortis.jpg')
                                            }
                                            alt=""
                                          />
                                        </Link>
                                      </div>
                                      <div className="company-discr" title={data?.name}>
                                        <h4>
                                          <Link
                                            to={'/jobs/company-details/' + data?.username}
                                            state={data}
                                            title={data?.name}>
                                            {data?.name}
                                          </Link>
                                        </h4>
                                        {/* <p>{data?.followers} Followers</p> */}
                                        {data?.followers != 0 ? (
                                          data?.followers <= 1 ? (
                                            <p>{data?.followers} Follower </p>
                                          ) : (
                                            <p>{data?.followers} Followers</p>
                                          )
                                        ) : (
                                          ''
                                        )}
                                      </div>
                                    </div>
                                    <Dropdown>
                                      <Dropdown.Toggle variant="success" id="dropdown-basic">
                                        <i className="fas fa-ellipsis-v"></i>
                                      </Dropdown.Toggle>
                                      <Dropdown.Menu>
                                        <Dropdown.Item
                                          onClick={() =>
                                            navigate(
                                              `/jobs/company-details/${data?.username}?state=second`,
                                              { state: data }
                                            )
                                          }>
                                          Job Openings
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          onClick={() =>
                                            navigate(
                                              `/jobs/company-details/${data?.username}?state=third`,
                                              { state: data }
                                            )
                                          }>
                                          Recent Updates
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          href="#/action-3"
                                          onClick={() => handleUnfollowCompany(data?.id)}>
                                          Unfollow Company
                                        </Dropdown.Item>
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </div>
                                );
                              })}{' '}
                              {}
                            </>
                          ) : (
                            <>
                              {!followLoading && manageList.length == 0 && (
                                <div className="d-flex w-100 justify-content-center">
                                  <NoDataFound text="No Company follows by you at the moment." />
                                </div>
                              )}
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </Tab.Pane>
                  {/* discover */}
                  <Tab.Pane eventKey="second">
                    <div className="company-lst bg-white p-4">
                      <div className="company-hd flex-wrap d-flex align-items-center justify-content-between mb-4">
                        <h4>COMPANIES SUGGESTIONS</h4>
                        <div className={isActive2 ? 'comp-hd-search blk-search' : 'comp-hd-search'}>
                          <Link onClick={handleClick3} to="">
                            <i className="fas fa-search"></i>
                          </Link>
                          <Form.Group className="search-card" controlId="formBasicEmail">
                            <Form.Control
                              type="search"
                              placeholder="Search Company"
                              name="name"
                              // onChange={(e) => setSuggestSearch(e.target.value)}
                              // onKeyUp={(e) => getDiscoverlist(e.target.value)}
                              onChange={(e) => {
                                getDiscoverlist(e.target.value);
                                setManageSearch(e.target.value);
                              }}
                            />
                          </Form.Group>
                        </div>
                      </div>
                      <div className="company-follow">
                        {loading && (
                          <>
                            {Array.from(Array(6), () => {
                              return <CompanySimmer />;
                            })}
                          </>
                        )}
                        {suggestedList?.length !== 0 && !loading ? (
                          <>
                            {suggestedList?.map((data, index) => {
                              return (
                                <>
                                  <div
                                    className="company-follow-card d-flex justify-content-between"
                                    key={index}>
                                    <div className="d-flex align-items-top">
                                      <div className="company-icn me-3">
                                        <Link
                                          to={'/jobs/company-details/' + data?.username}
                                          state={data}>
                                          <img
                                            src={
                                              data?.logo
                                                ? process.env.REACT_APP_API_DOMAIN + data?.logo
                                                : require('../../../assets/images/fortis.jpg')
                                            }
                                            alt=""
                                          />
                                        </Link>
                                      </div>
                                      <div className="company-discr" title={data?.name}>
                                        <h4>
                                          <Link
                                            to={'/jobs/company-details/' + data?.username}
                                            state={data}>
                                            {data?.name}
                                          </Link>
                                        </h4>
                                        {/* <p>{data?.followers} Followers</p> */}
                                        {data?.followers != 0 ? (
                                          data?.followers <= 1 ? (
                                            <p>{data?.followers} Follower</p>
                                          ) : (
                                            <p>{data?.followers} Followers</p>
                                          )
                                        ) : (
                                          ''
                                        )}
                                      </div>
                                    </div>
                                    <Button
                                      className="follow-btn-grey"
                                      onClick={(e) => handleJoinCompany(e, data?.id)}>
                                      <i className="fas fa-plus me-1"></i>
                                      Follow
                                    </Button>
                                  </div>
                                </>
                              );
                            })}
                          </>
                        ) : (
                          <>
                            {!loading && suggestedList.length === 0 && (
                              <div className="text-center">
                                <p>No Company suggestions found as per your search criteria</p>
                              </div>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  </Tab.Pane>
                </Tab.Content>
              </Col>
              <Col xs={12} md={4} xl={3}>
                <div>
                  <PeopleKnow />
                </div>

                {/* <div>
                  <WorkAnniversary />
                </div> */}
                <div>
                  <GroupDiscover />
                </div>
                <div>
                  <FollowCompany />
                </div>
                <div>
                  <CampusNews />
                </div>
                <div>
                  <CampusnewsText />
                </div>
                <div>
                  <ArticalBlogs />
                </div>
                <div>
                  <WhatsNew />
                </div>
                <div>
                  <Upcoming />
                </div>
                <MiniFooter />
              </Col>
            </Row>
          </div>
        </Tab.Container>
      </section>
      <FooterMain className={'colour-bg'} state={'d-none'} />
      {/* {loading ? <Loader /> : null} */}
    </>
  );
}

export default CompanyPage;
