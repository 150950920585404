/*
@copyright : ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
@author    : Shiv Charan Panjeta 
 
All Rights Reserved.
Proprietary and confidential :  All information contained here in is, and remains
the property of ToXSL Technologies Pvt. Ltd. and it's partners.
Unauthorized copying of this file, via any medium is strictly prohibited.
*/

import {
  addMenuList,
  deleteMenuList,
  getAlumniList,
  menuList,
  singleMenuListDetail
} from '../../services/AuthServices';
import { chapterList } from '../../services/InstituteServices';
import { jobShareLink } from '../../services/JobServices';
import showNotification from '../../services/NotificationService';
import { deleteFileFromPost, getPublisherBox, youtubeVideo } from '../../services/SocialServices';
import {
  addToShortList,
  getAlumniListing,
  getJobListing,
  getJobShortListOfUser,
  getNetworkJobListing
} from '../../services/UserServices';

export const GET_ALL_JOB_LIST = 'GETALLJOBLIST';
export const GET_ALL_ALUMNI_LIST = 'GETALLALUMNILIST';
export const GET_ALL_SHORTLIST_JOB = 'GETALLSHORTLISTJOB';
export const APPEND_PRIMARY_JOB_LIST = 'APPENDPRIMARYJOBLIST';
export const TOGGLE_SHORTLIST_JOB = 'TOGGLESHORTLISTJOB';
export const ADMIN_ALUMNI_LIST = 'ADMIN_ALUMNI_LIST';
export const RESET_ALL_JOB_LIST = 'RESETALLJOBLIST';
export const CHAPTER_LIST = 'CHAPTERLIST';
export const RESET_CHAPTER_LIST = 'RESETCHAPTERLIST';
export const CHAPTER_POST = 'CHPATER_POST';
export const ALL_CHAPTER_LIST = 'ALL_CHAPTER_LIST';

export const YOUTUBE_VIDEO_PENDING = 'YOUTUBE_VIDEO_PENDING';
export const YOUTUBE_VIDEO_SUCCESS = 'YOUTUBE_VIDEO_SUCCESS';
export const YOUTUBE_VIDEO_FAILED = 'YOUTUBE_VIDEO_FAILED';

export const SHARELINK_PENDING = 'SHARELINK_PENDING';
export const SHARELINK_SUCCESS = 'SHARELINK_SUCCESS';
export const SHARELINK_FAILED = 'SHARELINK_FAILED';

export const MENU_LIST_PENDING = 'MENU_LIST_PENDING';
export const MENU_LIST_SUCCESS = 'MENU_LIST_SUCCESS';
export const MENU_LIST_FAILED = 'MENU_LIST_FAILED';
export const MENU_LIST_DND = 'MENU_LIST_DND';

export const ADD_MENU_LIST_PENDING = 'ADD_MENU_LIST_PENDING';
export const ADD_MENU_LIST_SUCCESS = 'ADD_MENU_LIST_SUCCESS';
export const ADD_MENU_LIST_FAILED = 'ADD_MENU_LIST_FAILED';

export const SINGLE_MENU_LIST_DETAIL_PENDING = 'SINGLE_MENU_LIST_DETAIL_PENDING';
export const SINGLE_MENU_LIST_DETAIL_SUCCESS = 'SINGLE_MENU_LIST_DETAIL_SUCCESS';
export const SINGLE_MENU_LIST_DETAIL_FAILED = 'SINGLE_MENU_LIST_DETAIL_FAILED';

export const DELETE_MENU_LIST_PENDING = 'DELETE_MENU_LIST_PENDING';
export const DELETE_MENU_LIST_SUCCESS = 'DELETE_MENU_LIST_SUCCESS';
export const DELETE_MENU_LIST_FAILED = 'DELETE_MENU_LIST_FAILED';

export const DELETE_MEDIA_FILE_PENDING = 'DELETE_MEDIA_FILE_PENDING';
export const DELETE_MEDIA_FILE_SUCCESS = 'DELETE_MEDIA_FILE_SUCCESS';
export const DELETE_MEDIA_FILE_FAILED = 'DELETE_MEDIA_FILE_FAILED';
export const UNAUTH_USER = 'UNAUTH_USER';

export const loginInfo = (id) => {
  return {
    type: 'login',
    payload: id
  };
};

export const logout = (id) => {
  return {
    type: 'logout',
    payload: id
  };
};

export const markerCluster = (payload) => {
  return {
    type: 'CLUSTER',
    payload
  };
};

export const ShowAlert = (payload) => {
  return {
    type: 'showDrop',
    payload
  };
};
export const HideAlert = () => {
  return {
    type: 'hideDrop'
  };
};

// data should be obj and makesure pagenumber is present with offset
export const getAllJobList = (data, val) => {
  let {
    place,
    pt,
    page,
    offset,
    location,
    company,
    skillset,
    category,
    min_exp,
    max_exp,
    min_ctc,
    max_ctc
  } = { ...data };

  return async (dispatch, getState) => {
    // api call
    let resp;
    if (val) {
      resp = await getNetworkJobListing(place, pt);
    } else {
      resp = await getJobListing(place, pt);
    }

    dispatch({
      type: GET_ALL_JOB_LIST,
      payload: resp.data.data
    });

    return resp;
  };
};
// data should be obj and makesure pagenumber is present with offset
export const resetAllJobList = (data) => {
  return async (dispatch, getState) => {
    // api call
    dispatch({
      type: RESET_ALL_JOB_LIST
    });
  };
};

export const getAllAlumniList = (data) => {
  let {
    place,
    pt,
    page,
    offset,
    alumni_name = '',
    institute = '',
    passoutYear = '',
    company = '',
    location = '',
    category = '',
    skillset = '',
    BusinessSkillset = '',
    PersonalSkillset = ''
  } = { ...data };

  return async (dispatch, getState) => {
    // api call
    let resp = await getAlumniListing(
      place,
      pt,
      '',
      '',
      alumni_name,
      institute,
      passoutYear,
      company,
      location,
      category,
      skillset,
      BusinessSkillset,
      PersonalSkillset
    );

    dispatch({
      type: GET_ALL_ALUMNI_LIST,
      payload: resp.data.data
    });

    return resp;
  };
};

export const allChaptersList = (data) => {
  let {
    page = 1,
    place = '',
    q = '',
    pt = '',
    add_privacy = '',
    chapter_privacy = '',
    timeline_post_privacy = '',
    institue_id = '',
    location = '',
    admin_id = '',
    radiusSearch = '',
    radiusData = ''
  } = { ...data };

  return async (dispatch, getState) => {
    let resp = await chapterList(
      page,
      place,
      q,
      pt,
      add_privacy,
      chapter_privacy,
      timeline_post_privacy,
      institue_id,
      location,
      admin_id,
      radiusSearch,
      radiusData
    );

    dispatch({
      type: CHAPTER_LIST,
      payload: resp.data.data
    });
  };
};

export const resetChapterList = () => {
  return {
    type: RESET_CHAPTER_LIST
  };
};

export const adminALumniList = (data) => {
  let { place, pt, page, offset, passout_year, company, institue } = { ...data };
  return async (dispatch, getState) => {
    let resp = await getAlumniList(pt, place, passout_year, company, institue);
    dispatch({
      type: ADMIN_ALUMNI_LIST,
      payload: resp.data.details
    });
    return resp;
  };
};

// data should be obj and makesure pagenumber is present with offset
export const getUserJobShortList = () => {
  return async (dispatch, getState) => {
    let shortListJobResponse = await getJobShortListOfUser();
    dispatch({
      type: GET_ALL_SHORTLIST_JOB,
      payload: shortListJobResponse.data.data
    });
    return shortListJobResponse;
  };
};
// data should be obj and makesure pagenumber is present with offset
export const getAppendableJobList = (data) => {
  return async (dispatch, getState) => {
    let {
      place,
      pt,
      page,
      offset,
      location,
      company,
      skillset,
      category,
      min_exp,
      max_exp,
      min_ctc,
      max_ctc
    } = { ...data };

    let state = getState().cluster;
    let currentPage = state.currentPage + 1;

    // api call
    let resp = await getJobListing(place, pt, currentPage);
    dispatch({
      type: APPEND_PRIMARY_JOB_LIST,
      payload: resp.data.data
    });

    return resp;
  };
};
export const toggleJobShortList = (jobId, status) => {
  return async (dispatch, getState) => {
    let resp = await addToShortList(jobId, status);
    if (resp.data) {
      dispatch({
        type: TOGGLE_SHORTLIST_JOB,
        payload: {
          jobId,
          status
        }
      });
    }
  };
};

export const ShowAlbumManger = () => {
  return {
    type: 'showManager'
  };
};
export const HideAlbumManager = () => {
  return {
    type: 'hideManager'
  };
};

//institute info based on sub domain
export const _insInfo = (data) => {
  return {
    type: 'insInfo',
    payload: data
  };
};

export const userConnectionsData = (payload) => {
  return {
    type: 'USER_CONNECTIONS_DATA',
    payload
  };
};

export const emojiList = () => {
  return async (dispatch) => {
    try {
      const resp = await getPublisherBox();
      let arr = [];
      let data = resp?.data?.data?.emoticons;
      for (let i in data) {
        arr.push({ id: i, label: data[i], value: data[i] });
      }
      dispatch({
        type: 'EMOJI_LIST',
        payload: arr ?? []
        // message: resp.data.message
      });
      return resp;
    } catch (error) {}
  };
};

export const chapterPosts = (payload) => {
  return {
    type: 'CHPATER_POST',
    payload: payload
  };
};

export const youtubeVideoAction = (q = '') => {
  return async (dispatch) => {
    try {
      dispatch({
        type: YOUTUBE_VIDEO_PENDING,
        payload: []
      });
      const resp = await youtubeVideo(q);
      dispatch({
        type: YOUTUBE_VIDEO_SUCCESS,
        payload: resp.data.detail ?? []
      });
    } catch (error) {
      dispatch({
        type: YOUTUBE_VIDEO_FAILED,
        payload: error
      });
    }
  };
};

export const shareLinkAction = (id) => {
  let body = {
    job_id: id
  };
  return async (dispatch) => {
    try {
      dispatch({
        type: SHARELINK_PENDING,
        payload: {}
      });
      const resp = await jobShareLink(body);
      dispatch({
        type: SHARELINK_SUCCESS,
        payload: resp.data.data ?? {}
      });
    } catch (error) {
      dispatch({
        type: SHARELINK_FAILED,
        payload: error
      });
    }
  };
};

export const menuListAction = (sort, order, page) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: MENU_LIST_PENDING,
        payload: []
      });
      const resp = await menuList(sort, order, page);
      if (resp.status === 200) {
        dispatch({
          type: MENU_LIST_SUCCESS,
          payload: resp.data
        });
      }
      return resp;
    } catch (error) {
      dispatch({
        type: MENU_LIST_FAILED,
        payload: error
      });
    }
  };
};

export const dndMenuList = (payload) => {
  return {
    type: MENU_LIST_DND,
    payload
  };
};

export const addMenuListAction = (body, route) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: ADD_MENU_LIST_PENDING,
        payload: '',
        route: ''
      });
      const resp = await addMenuList(body);
      if (resp.status === 200) {
        showNotification('success', resp?.data?.message);
        dispatch({
          type: ADD_MENU_LIST_SUCCESS,
          payload: resp.data.message,
          route: route
        });
      }
      return resp;
    } catch (error) {
      dispatch({
        type: ADD_MENU_LIST_FAILED,
        payload: error,
        route: ''
      });
    }
  };
};

export const singleMenuListDetailAction = (id) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: SINGLE_MENU_LIST_DETAIL_PENDING,
        payload: ''
      });
      const resp = await singleMenuListDetail(id);
      if (resp.status === 200) {
        dispatch({
          type: SINGLE_MENU_LIST_DETAIL_SUCCESS,
          payload: resp.data.data
        });
      }
      return resp;
    } catch (error) {
      dispatch({
        type: SINGLE_MENU_LIST_DETAIL_FAILED,
        payload: error
      });
    }
  };
};

export const deleteMenuListAction = (id, value) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: DELETE_MENU_LIST_PENDING,
        payload: ''
      });
      const resp = await deleteMenuList(id, value);
      if (resp.status === 200) {
        showNotification('success', resp.data?.message);
        dispatch({
          type: DELETE_MENU_LIST_SUCCESS,
          payload: resp.data.message
        });
      }
      return resp;
    } catch (error) {
      dispatch({
        type: DELETE_MENU_LIST_FAILED,
        payload: error
      });
    }
  };
};

export const communicationListAction = (payload) => {
  return {
    type: 'COMMUNICATION_LIST',
    payload: payload
  };
};

export const accessRoutes = (payload) => {
  return {
    type: 'ACCESS_ROUTES',
    payload: payload
  };
};

export const allChapterList = (payload) => {
  return {
    type: ALL_CHAPTER_LIST,
    payload: payload
  };
};

export const deleteMediaFileAction = (post_id, media_id) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: DELETE_MEDIA_FILE_PENDING,
        payload: ''
      });
      const resp = await deleteFileFromPost(post_id, media_id);
      if (resp.status === 200) {
        showNotification('success', resp?.data?.message);
        dispatch({
          type: DELETE_MEDIA_FILE_SUCCESS,
          payload: resp.data.message
        });
      }
      return resp;
    } catch (error) {
      dispatch({
        type: DELETE_MEDIA_FILE_FAILED,
        payload: error
      });
    }
  };
};
