import { useFormik } from 'formik';
import { useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import AsyncSelect from 'react-select/async';
import * as yup from 'yup';
import { saveStepTwo } from '../../../../services/InstituteServices';
import { getTopicList } from '../../../../services/MentorArticlesServices';
import { citiesDropDown, getJobCategoriesDropdown } from '../../../../services/UserServices';
import './steps.scss';

function Step2() {
  const { userId } = useParams();
  const navigate = useNavigate();
  const [inputMsg, setInputMsg] = useState('Please enter atleast 2 characters');
  const [loadInput, setLoadInput] = useState(false);
  const { values, errors, touched, handleSubmit, setFieldValue, setFieldTouched } = useFormik({
    initialValues: {
      provideMentorship: [],
      seekMentorship: [],
      location: [],
      industry: '',
      department: []
    },
    validationSchema: yup.object().shape({
      location: yup.array().min(1).label('Preferred Job Location'),
      industry: yup.object().shape({
        value: yup.string().required('This field is required'),
        label: yup.string().required()
      }),
      department: yup.array().min(1).label('Department')
    }),
    onSubmit: async (values) => {
      const payload = new FormData();
      payload.append('user_id', userId);
      payload.append(
        'provide_mentorship_in',
        values.provideMentorship
          ?.map((data) => {
            return data?.value;
          })
          .toString()
      );
      payload.append(
        'seek_mentorship_in',
        values.seekMentorship
          ?.map((data) => {
            return data?.value;
          })
          .toString()
      );
      payload.append('industry_id', values.industry?.value);
      payload.append(
        'functional_area_id',
        values.department
          ?.map((data) => {
            return data?.value;
          })
          .toString()
      );
      payload.append(
        'location_id',
        values.location
          ?.map((data) => {
            return data?.value;
          })
          .toString()
      );
      try {
        const resp = await saveStepTwo(payload);
        if (resp.status === 200) {
          navigate('/institute-register/step/3/' + userId);
        }
      } catch (error) {
        console.error(error);
      }
    }
  });

  const loadMentorshipOptions = async (inputValue) => {
    const res = inputValue && (await getTopicList(inputValue));
    if (res?.data?.data?.length) {
      const newArr = res?.data?.data?.map((data) => {
        return { label: data?.name, value: data?.id };
      });
      return newArr;
    }
  };

  const loadIndyAndDeptOptions = async (input) => {
    const res = await getJobCategoriesDropdown();
    const arr = await res?.data?.data?.map((data) => {
      return { label: data?.name, value: data?.id };
    });
    if (input) {
      let newArr = arr.filter((i) => i.label?.toLowerCase()?.includes(input?.toLowerCase()));
      return newArr;
    }
    return arr;
  };

  const loadLocationOptions = async (inputValue) => {
    if (inputValue.length >= 2) {
      setLoadInput(true);
      try {
        const res = inputValue && (await citiesDropDown(inputValue));
        setLoadInput(false);
        if (res?.data?.data?.length) {
          let array = await res?.data?.data?.map((data) => {
            return {
              label: data?.city,
              value: data?.city_id
            };
          });
          return array;
        } else {
          setInputMsg('No match found');
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoadInput(false);
      }
    }
  };

  return (
    <>
      <section className="steps-area step-one-area pt-5 pb-5">
        <div className="row">
          <div className="col-sm-4 col-lg-3 left-area left-side">
            <div className="about-you">
              <h1 className="mb-0">About You</h1>
              <h6>Basic information</h6>
              <p>Your basic information let others find you more easily.</p>
            </div>
          </div>

          <div className="col-sm-8 col-lg-9 right-area">
            <div className="steps-block">
              <ul className="nav nav-tabs">
                <li>
                  <Link to={'/institute-register/step/1/' + userId}>
                    <span className="step-count">Step 1</span>
                    <span className="step-label">General information</span>
                  </Link>
                </li>
                <li className="current">
                  <span className="step-count">Step 2</span>
                  <span className="step-label">Career Goals</span>
                </li>
                <li>
                  <Link to={'/institute-register/step/3/' + userId}>
                    <span className="step-count">Step 3</span>
                    <span className="step-label">Educational Information</span>
                  </Link>
                </li>
                <li>
                  <Link to={'/institute-register/step/4/' + userId}>
                    <span className="step-count">Step 4</span>
                    <span className="step-label">Professional Information</span>
                  </Link>
                </li>
                <li>
                  <Link to={'/institute-register/step/5/' + userId}>
                    <span className="step-count">Step 5</span>
                    <span className="step-label">Upload profile picture</span>
                  </Link>
                </li>
              </ul>
            </div>
            <div className="data-box registeration-form">
              <form>
                <div className="form-inner clearfix">
                  <div className="one-company">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group">
                          <label for="provide_mentor_ship">Select fields you are mentor in</label>
                          <AsyncSelect
                            isMulti
                            // isSearchable={true}
                            loadOptions={loadMentorshipOptions}
                            className="basic-single mt-1"
                            name="city"
                            value={values.provideMentorship}
                            onChange={(e) => {
                              setFieldValue('provideMentorship', e);
                            }}
                            // onBlur={() => {
                            //   setFieldTouched('provideMentorship', true);
                            // }}
                            placeholder="Add areas where you provide mentorship"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group">
                          <label for="seek_mentor_ship">Topics you aspire to learn about?</label>
                          <AsyncSelect
                            isMulti
                            // isSearchable={true}
                            loadOptions={loadMentorshipOptions}
                            className="basic-single mt-1"
                            name="city"
                            value={values.seekMentorship}
                            onChange={(e) => {
                              setFieldValue('seekMentorship', e);
                            }}
                            // onBlur={() => {
                            //   setFieldTouched('provideMentorship', true);
                            // }}
                            placeholder="Add areas where you seek mentorship"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group">
                          <label for="industry_id">
                            Industry <span className="required-field">*</span>
                          </label>
                          <AsyncSelect
                            loadOptions={loadIndyAndDeptOptions}
                            defaultOptions
                            className="basic-single mt-1"
                            name="industry"
                            value={values.industry}
                            onChange={(e) => {
                              setFieldValue('industry', e);
                            }}
                            onBlur={() => {
                              setFieldTouched('industry', true);
                            }}
                            placeholder="Select your industry"
                          />
                          <p className="text-danger">
                            {touched.industry && errors.industry?.value}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group">
                          <label for="functional_area">
                            Department/Function/Stream <span className="required-field">*</span>
                          </label>
                          <AsyncSelect
                            isMulti={true}
                            loadOptions={loadIndyAndDeptOptions}
                            defaultOptions
                            className="basic-single mt-1"
                            name="department"
                            value={values.department}
                            onChange={(e) => {
                              setFieldValue('department', e);
                            }}
                            onBlur={() => {
                              setFieldTouched('department', true);
                            }}
                            placeholder="Select your Department/Function/Stream."
                          />
                          <p className="text-danger">{touched.department && errors.department}</p>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group">
                          <label for="location_id">
                            Preferred Job Location <span className="required-field">*</span>
                          </label>
                          <AsyncSelect
                            isMulti={true}
                            isSearchable={true}
                            loadOptions={loadLocationOptions}
                            className="basic-single mt-1"
                            name="city"
                            value={values.address}
                            onChange={(e) => {
                              setFieldValue('location', e);
                            }}
                            onBlur={() => {
                              setFieldTouched('location', true);
                            }}
                            placeholder="Select Current location"
                            onInputChange={(newValue) => {
                              newValue?.length <= 2 &&
                                setInputMsg('Please enter atleast 2 characters');
                            }}
                            noOptionsMessage={() => inputMsg}
                            components={{
                              DropdownIndicator: () => null,
                              IndicatorSeparator: () => null,
                              LoadingIndicator: () => null
                            }}
                          />
                          {loadInput && (
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'row-reverse',
                                margin: 'auto',
                                marginTop: '-25px'
                              }}>
                              <i className="fa fa-spinner fa-pulse me-2"></i>
                            </div>
                          )}
                          <p className="text-danger">{touched.location && errors.location}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="gray-footer clearfix">
                  <span className="float-start mandatory-label">
                    ( <span className="sign">*</span> ) Mandatory fields
                  </span>
                  <span className="float-end">
                    <button
                      type="button"
                      onClick={() => {
                        navigate(`/institute-register/step/3/${userId}`);
                      }}
                      className="add-btn-grn skip-btn me-2">
                      Skip
                    </button>
                    <button type="button" onClick={handleSubmit} className="add-btn-grn">
                      Save &amp; Next
                    </button>
                  </span>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Step2;
