import { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import showNotification from '../../../services/NotificationService';
import * as APISERVICES from '../../../services/UserServices';
import { NotificationSimmer } from '../skelton/allskelton';

function PrivacyForm() {
  const [detail, setDetail] = useState({
    message_privacy: '',
    comment_privacy: '',
    timeline_post_privacy: '',
    post_privacy: ''
  });
  const [loading, setLoading] = useState('');
  const [selectedMessagePrivacy, setSelectedMessagePrivacy] = useState('');
  const [selectedCommentPrivacy, setSelectedCommentPrivacy] = useState('');
  const [selectedTimeLinePostPrivacy, setSelectedTimeLinePostPrivacy] = useState([]);
  const [selectedPostPrivacy, setSelectedPostPrivacy] = useState([]);
  const [shortDropDown, setShortDropDown] = useState([]);
  const [privacyDropDown, setPrivacyDropDown] = useState([]);
  const [isMsgEdit, setIsMsgEdit] = useState(false);
  const [isCmntEdit, setIsCmntEdit] = useState(false);
  const [isTimeLineEdit, setIsTimelineEdit] = useState(false);
  const [isPostEdit, setIsPostEdit] = useState(false);

  useEffect(() => {
    getDropdownList();
    getPrivacyInfo();
  }, []);

  const getPrivacyInfo = () => {
    setLoading(true);
    APISERVICES.getPrivacydetail().then((res) => {
      setLoading(false);
      if (res?.status === 200) {
        const data = res?.data?.data;
        setSelectedCommentPrivacy({
          id: data.comment_privacy.privacy_option_id,
          label: data.comment_privacy.privacy_option_name,
          value: data.comment_privacy.privacy_option_name
        });
        setSelectedMessagePrivacy({
          id: data.message_privacy.privacy_option_id,
          label: data.message_privacy.privacy_option_name,
          value: data.message_privacy.privacy_option_name
        });
        let postArr = [];
        let timeArr = [];
        if (data?.post_privacy?.length) {
          let arr = [];
          data?.post_privacy?.map((data) => {
            postArr.push(data?.privacy_option_id);
            arr.push({
              id: data?.privacy_option_id,
              label: data?.privacy_option_name,
              value: data?.privacy_option_name
            });
          });
          setSelectedTimeLinePostPrivacy(arr);
        }

        if (data?.timeline_post_privacy?.length) {
          let arr = [];
          data?.timeline_post_privacy?.map((data) => {
            timeArr.push(data?.privacy_option_id);
            arr.push({
              id: data?.privacy_option_id,
              label: data?.privacy_option_name,
              value: data?.privacy_option_name
            });
          });
          setSelectedPostPrivacy(arr);
        }

        setDetail({
          message_privacy: data.message_privacy.privacy_option_id,
          comment_privacy: data.comment_privacy.privacy_option_id,
          timeline_post_privacy: timeArr.join(),
          post_privacy: postArr.join()
        });
      }
    });
  };

  const getDropdownList = () => {
    APISERVICES.getPrivacyDropdown().then((res) => {
      if (res?.status === 200) {
        let arr = [];
        let data = res?.data?.data;
        if (data?.length) {
          data?.map((data) => {
            arr.push({
              id: data.privacy_option_id,
              label: data.privacy_option_name,
              value: data.privacy_option_name
            });
          });
        }
        setPrivacyDropDown(arr);
      }
    });

    APISERVICES.getShortPrivacyOpt().then((res) => {
      if (res?.status === 200) {
        let arr = [];
        let data = res?.data?.data;
        if (data?.length) {
          data?.map((data) => {
            arr.push({
              id: data.privacy_option_id,
              label: data.privacy_option_name,
              value: data.privacy_option_name
            });
          });
        }
        setShortDropDown(arr);
      }
    });
  };

  const onChangeHandler = (e, name = '', multi = false) => {
    if (multi) {
      let arr = [];
      e.map((data) => {
        arr.push(data.id);
      });
      setDetail({ ...detail, [name]: arr.join() });
    } else {
      setDetail({ ...detail, [name]: e.id });
    }
  };

  const submitHandler = (e) => {
    e.preventDefault();
    let formData = new FormData();
    for (let i in detail) {
      formData.append(`${i}`, detail[i]);
    }
    APISERVICES.updatePrivacy(formData).then((res) => {
      if (res?.status === 200) {
        showNotification('success', res?.data?.message);
        setIsMsgEdit(false);
        setIsCmntEdit(false);
        setIsTimelineEdit(false);
        setIsPostEdit(false);
      }
    });
  };

  return (
    <>
      <div className="detail_form_div">
        <form className="contact-details" id="">
          <h2>Privacy Settings</h2>
          <div>
            {loading ? (
              <>
                <NotificationSimmer />
                <NotificationSimmer />
                <NotificationSimmer />
                <NotificationSimmer />
              </>
            ) : (
              <>
                <Row className={isMsgEdit ? 'form-group active' : 'form-group'}>
                  <Col sm={8} xs={8}>
                    <label htmlFor="firstname">Who can message you</label>
                  </Col>
                  <Col sm={4} xs={4}>
                    <div className="d-flex align-items-center justify-content-end">
                      <span className="friend-icon everyone me-2"></span>

                      <Link
                        to={`javascript:void(0)`}
                        title="Save"
                        className="me-2 correct_btn"
                        onClick={submitHandler}>
                        <i className="fas fa-check"></i>
                      </Link>
                      <Link
                        to={`javascript:void(0)`}
                        title="Cancel"
                        className="me-2 cross_btn"
                        onClick={(e) => {
                          e.preventDefault();
                          setIsMsgEdit(false);
                        }}>
                        <i className="fas fa-times"></i>
                      </Link>
                      <Link
                        title="Edit"
                        to={`javascript:void(0)`}
                        onClick={(e) => {
                          e.preventDefault();
                          setIsMsgEdit(true);
                        }}>
                        <i className="fa fa-chevron-right"></i>
                      </Link>
                    </div>
                  </Col>
                  <Col sm={12} xs={12}>
                    {isMsgEdit && (
                      <Select
                        className="basic-single mt-3"
                        classNamePrefix="select"
                        name="colordscs"
                        options={shortDropDown}
                        value={selectedMessagePrivacy}
                        onChange={(e) => {
                          setSelectedMessagePrivacy(e);
                          onChangeHandler(e, 'message_privacy', false);
                        }}
                      />
                    )}
                  </Col>
                </Row>
                <Row className={isCmntEdit ? 'form-group active' : 'form-group'}>
                  <Col sm={8} xs={8}>
                    <label htmlFor="firstname">Who can comment on your posts</label>
                  </Col>
                  <Col sm={4} xs={4}>
                    <div className="d-flex align-items-center justify-content-end">
                      <span className="friend-icon friends me-2"></span>

                      <Link
                        to={`javascript:void(0)`}
                        title="Save"
                        className="me-2 correct_btn"
                        onClick={submitHandler}>
                        <i className="fas fa-check"></i>
                      </Link>
                      <Link
                        to={`javascript:void(0)`}
                        title="Cancel"
                        className="me-2 cross_btn"
                        onClick={(e) => {
                          e.preventDefault();
                          setIsCmntEdit(false);
                        }}>
                        <i className="fas fa-times"></i>
                      </Link>
                      <Link
                        to={`javascript:void(0)`}
                        title="Edit"
                        onClick={(e) => {
                          e.preventDefault();
                          setIsCmntEdit(true);
                        }}>
                        <i className="fa fa-chevron-right"></i>
                      </Link>
                    </div>
                  </Col>
                  <Col sm={12} xs={12}>
                    {isCmntEdit && (
                      <Select
                        className="basic-single mt-3"
                        classNamePrefix="select"
                        isSearchable={true}
                        name="colordscs"
                        options={shortDropDown}
                        value={selectedCommentPrivacy}
                        onChange={(e) => {
                          onChangeHandler(e, 'comment_privacy', false);
                          setSelectedCommentPrivacy(e);
                        }}
                      />
                    )}
                  </Col>
                </Row>
                <Row className={isTimeLineEdit ? 'form-group active' : 'form-group'}>
                  <Col sm={8} xs={8}>
                    <label htmlFor="firstname">Who can post on your timeline</label>
                  </Col>
                  <Col sm={4} xs={4}>
                    <div className="d-flex align-items-center justify-content-end">
                      <span className="friend-icon only-me me-2"></span>
                      <Link
                        to={`javascript:void(0)`}
                        title="Save"
                        className="me-2 correct_btn"
                        onClick={submitHandler}>
                        <i className="fas fa-check"></i>
                      </Link>
                      <Link
                        to={`javascript:void(0)`}
                        title="Cancel"
                        className="me-2 cross_btn"
                        onClick={(e) => {
                          e.preventDefault();
                          setIsTimelineEdit(false);
                        }}>
                        <i className="fas fa-times"></i>
                      </Link>
                      <Link
                        to={`javascript:void(0)`}
                        title="Edit"
                        onClick={(e) => {
                          e.preventDefault();
                          setIsTimelineEdit(true);
                        }}>
                        <i className="fa fa-chevron-right"></i>
                      </Link>
                    </div>
                  </Col>
                  <Col sm={12} xs={12}>
                    {isTimeLineEdit && (
                      <Select
                        isMulti
                        className="basic-single mt-3"
                        classNamePrefix="select"
                        isClearable={false}
                        isSearchable={true}
                        options={privacyDropDown}
                        value={selectedTimeLinePostPrivacy}
                        onChange={(e) => {
                          onChangeHandler(e, 'timeline_post_privacy', true);
                          setSelectedTimeLinePostPrivacy(e);
                        }}
                      />
                    )}
                  </Col>
                </Row>
                <Row className={isPostEdit ? 'form-group active' : 'form-group'}>
                  <Col sm={8} xs={8}>
                    <label htmlFor="firstname">Who can see your posts</label>
                  </Col>
                  <Col sm={4} xs={4}>
                    <div className="d-flex align-items-center justify-content-end">
                      <span className="friend-icon mentors me-2"></span>
                      <Link
                        to={`javascript:void(0)`}
                        title="Save"
                        className="me-2 correct_btn"
                        onClick={submitHandler}>
                        <i className="fas fa-check"></i>
                      </Link>
                      <Link
                        to={`javascript:void(0)`}
                        title="Cancel"
                        className="me-2 cross_btn"
                        onClick={(e) => {
                          e.preventDefault();
                          setIsPostEdit(false);
                        }}>
                        <i className="fas fa-times"></i>
                      </Link>
                      <Link
                        to={`javascript:void(0)`}
                        title="Edit"
                        onClick={(e) => {
                          e.preventDefault();
                          setIsPostEdit(true);
                        }}>
                        <i className="fa fa-chevron-right"></i>
                      </Link>
                    </div>
                  </Col>
                  <Col sm={12} xs={12}>
                    {isPostEdit && (
                      <Select
                        isMulti
                        className="basic-single mt-3"
                        classNamePrefix="select"
                        isClearable={false}
                        isSearchable={true}
                        name="colordscs"
                        options={privacyDropDown}
                        value={selectedPostPrivacy}
                        onChange={(e) => {
                          onChangeHandler(e, 'post_privacy', true);
                          setSelectedPostPrivacy(e);
                        }}
                      />
                    )}
                  </Col>
                </Row>
              </>
            )}
          </div>
        </form>
      </div>
    </>
  );
}

export default PrivacyForm;
