const intialState = {
  showCollection: false
};

const albumManagerReducer = (state = intialState, action) => {
  switch (action.type) {
    case 'showManager':
      return {
        ...state,
        showCollection: true
      };
    case 'hideManager':
      return {
        ...state,
        showCollection: false
      };

    default:
      return state;
  }
};

export default albumManagerReducer;
