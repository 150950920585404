import { useEffect, useState } from 'react';
import { Button, Container } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Table from 'react-bootstrap/Table';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import Loader from '../../components/commoncomponents/loader/loader-large';
import { statusName } from '../../globals/helper';
import * as adminServices from '../../services/AuthServices';
import ReactPaginate from 'react-paginate';

export default function Blog() {
  const [list, setList] = useState([]);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [searchedData, setSearchData] = useState({
    country_id: '',
    country_code: '',
    country_name: ''
  });
  const [response, setResponse] = useState('');
  const [sortType, setSortType] = useState('DESC');
  const [sortField, setSortField] = useState('country_id');
  const [currentPage, setCurrentPage] = useState(1);
  const roleType = useSelector((state) => state?.user?.details?.admin_type);

  useEffect(() => {
    apiCall();
  }, []);

  const apiCall = (page = 1) => {
    setLoading(true);
    // setCurrentPage();
    adminServices.blogList(page).then((res) => {
      setLoading(false);
      if (res?.status === 200) {
        setList(res?.data?.data?.details);
        setResponse(res?.data?.data?.totalcount);
      }
    });
  };

  const selectHandler = (value, data) => {
    switch (value) {
      case 'edit':
        navigate('/admin/edit/blog', { state: data });
        break;
      case '2':
        stateHandler(value, data?.id);
        break;
      default:
        break;
    }
  };
  const handlePageClick = (a) => {
    let page = a?.selected + 1;
    setCurrentPage(page);
    apiCall(page);
  };

  const stateHandler = (value, id) => {
    setLoading(true);
    let status = value;
    adminServices.deleteTag(id, status).then((res) => {
      setLoading(false);
      if (res?.status === 200) {
        document.getElementById('select').value = '';
        apiCall();
      }
    });
  };

  const resetHandler = (e) => {
    e.preventDefault();
    setSearchData({
      country_id: '',
      country_code: '',
      country_name: ''
    });
    setSortType('DESC');
    setSortField('');
    apiCall();
  };

  const sortHandler = (e, type) => {
    e.preventDefault();
    let currentSort = sortType === 'ASC' ? 'DESC' : 'ASC';
    setSortType(currentSort);
    setSortField(type);
    apiCall(
      currentPage,
      type,
      currentSort,
      searchedData.country_id,
      searchedData.country_code,
      searchedData.country_name
    );
  };

  const onChangeSearch = (e) => {
    const { name, value } = e.target;
    setSearchData({ ...searchedData, [name]: value });
  };

  const searchHandler = (e) => {
    e.preventDefault();
    apiCall(
      currentPage,
      sortField,
      sortType,
      searchedData.country_id,
      searchedData.country_code,
      searchedData.country_name
    );
  };

  return (
    <>
      <div className="dash-main-content">
        <Container fluid>
          <div className="dash-page-content">
            <div className="dash-page-title">
              <Row>
                <Col xs={12} sm={12} md={12}>
                  <div className="dash-title-bar d-flex align-items-center justify-content-between">
                    <div className="title-lft d-flex align-items-center">
                      <div className="dash-icn">
                        <i className="fas fa-building"></i>
                      </div>
                      <div className="dash-title-dis">
                        <h5>
                          Blog <span>Listing</span>
                        </h5>
                        <h5>
                          Total Record(s): <span>{response}</span>
                        </h5>
                      </div>
                    </div>
                    <div className="title-rt-btn">
                      <Link className="green_button_admin" to={`/admin/add/blog`}>
                        Add New Blog
                      </Link>
                      {roleType == '3' && (
                        <Link className="green_button_admin mx-2" to="/admin/add-banner?id=5">
                          Add Banner
                        </Link>
                      )}
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="dash-content-card">
              <div className="table-responsive">
                <Table className="custom-table-data" striped bordered hover>
                  <thead>
                    <tr>
                      <th>
                        <Link
                          to="#"
                          onClick={(e) => {
                            sortHandler(e, 'country_id');
                          }}>
                          Id
                          <img src={require('../../assets/images/sort.png')} alt="sort" />
                        </Link>
                      </th>
                      <th>
                        <Link
                          to="#"
                          onClick={(e) => {
                            sortHandler(e, 'code');
                          }}>
                          Title
                          <img src={require('../../assets/images/sort.png')} alt="sort" />
                        </Link>
                      </th>
                      <th>
                        <Link
                          to="#"
                          onClick={(e) => {
                            sortHandler(e, 'name');
                          }}>
                          Institute Name
                          <img src={require('../../assets/images/sort.png')} alt="sort" />
                        </Link>
                      </th>
                      <th>Status</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="search-table">
                      <td>
                        <Form.Control
                          value={searchedData.country_id}
                          name="country_id"
                          onChange={(e) => {
                            onChangeSearch(e);
                          }}
                          type="text"
                          placeholder="Id"
                        />
                      </td>
                      <td>
                        <Form.Control
                          value={searchedData.country_code}
                          name="country_code"
                          onChange={(e) => {
                            onChangeSearch(e);
                          }}
                          type="text"
                          placeholder="Title"
                        />
                      </td>
                      <td>
                        <Form.Control
                          value={searchedData.country_name}
                          name="country_name"
                          onChange={(e) => {
                            onChangeSearch(e);
                          }}
                          type="text"
                          placeholder="Name"
                        />
                      </td>
                      <td></td>
                      <td className="serac-btns text-center">
                        <button
                          type="button"
                          className="green_button_admin"
                          onClick={(e) => {
                            searchHandler(e);
                          }}>
                          Search
                        </button>
                        {/* <Button
                          className="main-btn-red ms-2"
                          onClick={(e) => {
                            resetHandler(e);
                          }}>
                          Reset
                        </Button> */}
                      </td>
                    </tr>
                    {list && list?.length
                      ? list?.map((data, index) => (
                        <tr key={index}>
                          <td>{data?.id}</td>
                          <td>{data?.title}</td>
                          <td>{data?.name}</td>
                          <td>{statusName(data?.state_id)}</td>
                          <td>
                            <Form.Select
                              aria-label="Default select example"
                              value={''}
                              id="select"
                              onChange={(e) => {
                                selectHandler(e.target.value, data);
                              }}>
                              <option value="">Action</option>
                              <option value="edit">Edit</option>
                              {/* {data?.status === '0' ? (
                                <option value="1">Enable</option>
                              ) : (
                                <option value="0">Disable</option>
                              )} */}
                              <option value="2">Delete</option>
                            </Form.Select>
                          </td>
                        </tr>
                      ))
                      : ''}
                  </tbody>
                </Table>
                {response > 10 ? (
                  <div className="react-page d-flex">
                    <ReactPaginate
                      previousLabel={currentPage > 0 ? '<' : ''}
                      nextLabel={response > 0 ? '>' : ''}
                      onPageChange={handlePageClick}
                      activeClassName={'page-link active'}
                      pageCount={Math.ceil(response / 10)}
                    />
                  </div>
                ) : (
                  ''
                )}
              </div>
            </div>
          </div>
        </Container>
      </div>
      {loading ? <Loader /> : ''}
    </>
  );
}
