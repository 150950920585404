/** @copyright : ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
 @author     : Shiv Charan Panjeta 

 All Rights Reserved.
 Proprietary and confidential :  All information contained herein is, and remains
 the property of ToXSL Technologies Pvt. Ltd. and its partners.
 Unauthorized copying of this file, via any medium is strictly prohibited.
*/

import moment from 'moment';
import { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import useDocumentTitle from '../../../globals/useDocumentTitle';
import { myProductsOrdersDetail } from '../../../services/ShopServices';
import FooterGrey from '../../commoncomponents/FooterGrey';
import HeaderShop from '../../commoncomponents/HeaderShop';
import Loader from '../../commoncomponents/loader/loader-large';

function OrderDetails() {
  useDocumentTitle('View Details | AlmaBay');
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState([]);
  const { id } = useParams();
  useEffect(() => {
    getAllOrders();
  }, [id]);
  const getAllOrders = () => {
    setLoading(true);
    myProductsOrdersDetail(id).then((res) => {
      if (res?.status === 200) {
        setLoading(false);
        setList(res?.data?.data[0]);
      }
    });
  };

  const orderStatus = (status) => {
    switch (status) {
      case '1':
        return 'Order Placed';
      case '2':
        return 'Pending Payment';
      case '3':
        return 'Delivered';
      case '4':
        return 'Cancelled';
      case '5':
        return 'Refund';
      case '6':
        return 'Payment Failed';
    }
  };
  return (
    <>
      <HeaderShop />

      <section className="py-5">
        <Container>
          <Row>
            <Col sm={9}>
              <h3 className="heading mb-4">Orders Details</h3>
            </Col>
            <Col sm={3}></Col>

            <Col sm={12}>
              <div className="orders-main border-0">
                <div className="orders-header bg-transparent">
                  <Row>
                    <Col md={3}>
                      <div className=" shipment-detail">
                        <h3 className="custom-spacing"> ({orderStatus(list?.order_status)})</h3>
                        <h4 className="custom-spacing"> {moment(list?.created).format('llll')}</h4>
                      </div>
                    </Col>
                    <Col md={4}>
                      <div className=" shipment-detail">
                        <h3 className="custom-spacing">ORDER ID</h3>
                        <h4 className="custom-spacing">{list?.id}</h4>
                      </div>
                    </Col>

                    <Col lg={5}>
                      <div className=" shipment-detail">
                        <h3 className="custom-spacing">ORDER STATUS</h3>
                        <h4 className="custom-spacing">
                          <span className="status"> ({orderStatus(list?.order_status)})</span>
                        </h4>
                      </div>
                    </Col>
                  </Row>
                </div>
                <div className="orders-body">
                  <Row>
                    <Col sm="12">
                      <div className="custom-panel">
                        <Row>
                          <Col lg="3">
                            <div className="shipping-infomation">
                              <h5>shipping Address</h5>
                              <h6>{list?.ship_name} </h6>
                              <h6> {list?.ship_address} </h6>
                              <h6>
                                {list?.ship_landmark} {list?.ship_state} - {list?.ship_pincode}
                              </h6>
                              <h6>India</h6>
                              <h6>Landmark: {list?.ship_landmark}</h6>
                              <br />
                              <h6>Mobile: {list?.ship_mobile}</h6>
                            </div>
                          </Col>
                          <Col lg="3">
                            <div className="shipping-infomation">
                              <h5>Billing Address</h5>
                              <h6>{list?.bill_name} </h6>
                              <h6> {list?.bill_address} </h6>
                              <h6>
                                {list?.bill_landmark} {list?.bill_state} - {list?.bill_pincode}
                              </h6>
                              <h6>India</h6>
                              <h6>Landmark: {list?.bill_landmark}</h6>
                              <br />
                              <h6>Mobile: {list?.bill_mobile}</h6>
                            </div>
                          </Col>
                          <Col lg="3">
                            <div className="shipping-infomation">
                              <h5>Payment Method</h5>
                              <h6>
                                {list?.payment_method == 1
                                  ? 'COD(Cash On Delivery)'
                                  : 'Credit Card/ Debit Card/ Net Banking'}
                              </h6>
                            </div>
                          </Col>
                          <Col lg="3">
                            <div className="shipping-infomation d-flex w-100 justify-content-between">
                              <div className="order-summary">
                                <h5>Order Summary</h5>
                                <h6>Item(s) Subtotal:</h6>
                                <h6>Shipping:</h6>
                                <h6>Total:</h6>
                                <h6>Promotion Applied:</h6>
                                <h5>Grand Total:</h5>
                              </div>
                              <div className="total-detail">
                                <h5 className="mb-4"> </h5>
                                <h6>
                                  <span>₹</span> {list?.total}
                                </h6>
                                <h6>
                                  <span>₹</span> {list?.shipping_rate}
                                </h6>
                                <h6>
                                  <span>₹</span> {list?.grand_total}
                                </h6>
                                <h6>
                                  <span>₹</span> - 0.00
                                </h6>
                                <h5>
                                  <span>₹</span> {list?.grand_total}
                                </h5>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                    <Col>
                      <div className="border border-1 p-3">
                        <Row className="align-items-center">
                          {list?.products?.map((productList, index) => {
                            return (
                              <>
                                <Col lg={5} key={index}>
                                  <div className="media d-flex mb-2">
                                    <div className="media-left images-border">
                                      <img
                                        className="media-object"
                                        src={productList?.product_image}
                                        alt="pic"
                                        width="100px"
                                      />
                                    </div>
                                    <div className="media-body">
                                      <Link>
                                        <h1 className="media-heading">
                                          {productList?.product_name}
                                        </h1>
                                      </Link>
                                      <h2>Code: {productList?.product_code}</h2>
                                      <h2>Qty: {productList?.product_quantity} </h2>
                                      <h2>Rs. {productList?.product_price}</h2>
                                    </div>
                                  </div>
                                </Col>
                                <Col lg={4}>
                                  <div className="media">
                                    <div className="media-body">
                                      <h2>Item’s Subtotal </h2>
                                      <h2>
                                        <strong>Rs. {productList?.product_price}</strong>
                                      </h2>
                                    </div>
                                  </div>
                                </Col>
                                <Col lg={3}>
                                  <Link
                                    to={`/shop/details/${productList.product_id}`}
                                    className="btn dark-blu-btn rounded-0 w-100 mb-3">
                                    Write a product review
                                  </Link>
                                </Col>
                              </>
                            );
                          })}
                        </Row>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <FooterGrey />
      {loading ? <Loader /> : ''}
    </>
  );
}

export default OrderDetails;
