/**
@copyright  :   ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
@author     :   Shiv Charan Panjeta 
 
All Rights Reserved.
Proprietary and confidential :  All information contained here in is, and remains
the property of ToXSL Technologies Pvt. Ltd. and it's partners.
Unauthorized copying of this file, via any medium is strictly prohibited.
*/
import { useEffect, useState } from 'react';
import { Button, Container } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import { AsyncPaginate } from 'react-select-async-paginate';
import Loader from '../../components/commoncomponents/loader/loader-large';
import {
  TEMPLATE_APPLICABLE_FOR_INSTITUTE,
  TEMPLATE_DOCUMENT_TYPE_DOC,
  TEMPLATE_DOCUMENT_TYPE_PDF,
  TEMPLATE_FORMAT_CAT1,
  TEMPLATE_FORMAT_CAT2,
  TEMPLATE_FORMAT_CAT3
} from '../../globals/constants';
import { replaceStringFormat } from '../../globals/helper';
import { getInstAdminList, getInstituteList } from '../../services/AuthServices';
import showNotification from '../../services/NotificationService';
import {
  addWhatsAppTemplate,
  getTemplateTokenListByApplicable,
  getWhatsAppDetail,
  updateWhatsApp
} from '../../services/TemplateServices';

export default function AddwhatsappTemplate() {
  const [applicableForList, setApplicableForList] = useState([
    {
      id: TEMPLATE_APPLICABLE_FOR_INSTITUTE,
      name: 'INSTITUTE'
    }
  ]);
  const [documentTypeList, setDocumentTypeList] = useState([
    {
      id: TEMPLATE_DOCUMENT_TYPE_PDF,
      name: 'PDF'
    },
    {
      id: TEMPLATE_DOCUMENT_TYPE_DOC,
      name: 'DOCUMENT'
    }
  ]);

  const [instID, setInstID] = useState();
  const [assignMemberList, setAssignMemberList] = useState([]);
  const [assignMemberList2, setAssignMemberList2] = useState([]);
  const [value, setValue] = useState(0);

  const [applicableForOption, setApplicableForOption] = useState(false);
  const [applicableFor, setApplicableFor] = useState('');
  const [tokenList, setTokenList] = useState([]);
  const [tokenId, setTokenId] = useState('');
  const [loading, setLoading] = useState(false);
  const [content, setContent] = useState('');
  const [status, setStatus] = useState(1);
  const [templateTitle, setTemplateTitle] = useState('');
  const [format, setFormat] = useState('');
  const [tempCat, setTempCat] = useState('');
  const [selectedFile, setSelectedFile] = useState('');
  const [isSelectedFile, setIsSelectedFile] = useState(false);
  const [buttonType, setButtonType] = useState();
  const [campus, setCampus] = useState([]);
  const [documentType, setDocumentType] = useState('');
  const [selectToken, setSelectToken] = useState(false);
  const id = new URLSearchParams(location.search).get('id') ?? '';
  const applicableForFun = (applicable_for_type) => {
    setApplicableForOption(true);
    setApplicableFor(applicable_for_type);
    setLoading(true);
    getTemplateTokenListByApplicable(applicable_for_type).then((resp) => {
      if (resp?.status === 200) {
        setLoading(false);
        setTokenList(resp?.data?.data);
      } else {
        setApplicableForOption(false);
      }
    });
  };
  const onTokenSelect = (e) => {
    setSelectToken(true);
    setTokenId(e.target.value);
    let selected_token = e.target.options[e.target.selectedIndex].text;
    if (e.target.value != '') {
      let updatedContent = content + replaceStringFormat(selected_token);
      setContent(updatedContent);
    } else {
      setSelectToken(false);
    }
  };
  const loadOptions = async (search, loadedOptions, { page }) => {
    try {
      let body = {
        page
      };
      const resp = await getInstituteList(body);
      setCampus(resp?.data?.data?.viewlist ?? []);
      return {
        options: resp?.data?.data?.viewlist ?? [],
        hasMore: loadedOptions.length === resp?.data?.data?.totalRecords ? false : true,
        additional: {
          page: search ? page + 1 : page + 1
        }
      };
    } catch (error) {
      console.log('error', error);
    }
  };
  const insituteMembersList = async () => {
    const resp = await getInstAdminList(value.institute_id ? value?.institute_id : instID);
    if (resp?.status === 200) {
      if (value.institute_id) {
        showNotification('success', resp?.data?.message);
      }
      const newArray = resp?.data?.data?.map((item) => {
        return {
          id: item?.user_id,
          value: item?.user_id,
          label: item?.name
        };
      });
      setAssignMemberList(newArray);
      setAssignMemberList2(newArray);
    } else {
      setAssignMemberList2([]);
    }
  };
  const membersID = assignMemberList?.map((item) => {
    return item.id;
  });

  useEffect(() => {
    if (value.institute_id ? value?.institute_id : instID) {
      insituteMembersList();
    }
  }, [value.institute_id ? value?.institute_id : instID]);

  const handleChange = (val) => {
    setAssignMemberList(val);
  };
  const handleUploadFile = (e) => {
    if (!fileValidator(e.target.files[0])) {
      return false;
    }
    setSelectedFile(e.target.files[0]);
    setIsSelectedFile(true);
  };
  /**
   * FILE VALIDATOR
   * @param {Object} data
   * @returns
   */
  const fileValidator = (data) => {
    const fileExtension = data?.type?.split('/')[1];
    let validExtensions;
    if (fileExtension == 'pdf') {
      validExtensions = ['pdf'];
    } else {
      validExtensions = [
        'vnd.oasis.opendocument.text',
        'msword',
        'officedocument.wordprocessingml.document'
      ];
    }
    if (!validExtensions?.includes(fileExtension)) {
      showNotification('danger', `Please select ${fileExtension == 'pdf' ? 'pdf' : 'doc'} format`);
      return false;
    }
    return true;
  };
  const checkCredentials = () => {
    if (templateTitle.trim() === '' || templateTitle.trim().length <= 0) {
      showNotification('danger', 'Template title field required');
      return false;
    }
    if (applicableFor === '') {
      showNotification('danger', 'Applicable for field required');
      return false;
    }
    if (format === '') {
      showNotification('danger', 'Format field required');
      return false;
    }
    if (tempCat === '') {
      showNotification('danger', 'Template category field required');
      return false;
    }

    if (value == '') {
      showNotification('danger', 'Insitute field required');
      return false;
    }

    if (assignMemberList == '') {
      showNotification('danger', 'Assign user to field required');
      return false;
    }
    if (tokenId === '') {
      showNotification('danger', 'Token field required');
      return false;
    }
    return true;
  };
  let navigate = useNavigate();
  const saveWhatsAppTemplate = () => {
    if (checkCredentials()) {
      setLoading(true);
      let formData = new FormData();
      formData.append('title', templateTitle);
      formData.append('applicable_for', applicableFor);
      formData.append('format', format);
      formData.append('category_id', tempCat);
      formData.append('content', content);
      formData.append('file', selectedFile);
      formData.append('content_token', tokenId);
      formData.append('state_id', status);
      formData.append('campus_id', value.institute_id);
      formData.append('assign_user', membersID.toString());

      addWhatsAppTemplate(formData)
        .then((res) => {
          if (res?.status == 200) {
            setLoading(false);
            showNotification('success', res?.data?.message);
            navigate('/admin/ManageTemplate', {
              state: {
                active: 'whatsapp'
              }
            });
          } else {
            setLoading(false);
          }
        })
        .catch((err) => {
          setLoading(false);
        });
    }
  };

  const updateWhatsAppTemplate = () => {
    if (checkCredentials()) {
      setLoading(true);
      let formData = new FormData();
      formData.append('id', id);
      formData.append('title', templateTitle);
      formData.append('applicable_for', applicableFor);
      formData.append('format', format);
      formData.append('category_id', tempCat);
      formData.append('content', content);
      formData.append('file', selectedFile);
      formData.append('content_token', tokenId);
      formData.append('state_id', status);
      formData.append('campus_id', value.institute_id ? value.institute_id : instID);
      formData.append('assign_user', membersID.toString());

      updateWhatsApp(formData)
        .then((res) => {
          if (res?.status == 200) {
            setLoading(false);
            showNotification('success', res?.data?.message);
            navigate('/admin/ManageTemplate', {
              state: {
                active: 'whatsapp'
              }
            });
          } else {
            setLoading(false);
          }
        })
        .catch((err) => {
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    if (id) {
      getSMSTemplateDetail(id);
    }
  }, []);

  const getSMSTemplateDetail = (id) => {
    setLoading(true);
    getWhatsAppDetail(id).then((resp) => {
      if (resp?.status === 200) {
        setTemplateTitle(resp?.data?.detail?.title);
        setApplicableFor(resp?.data?.detail?.applicable_for);
        setButtonType(resp?.data?.detail?.button_type);
        setContent(resp?.data?.detail?.content);
        setTokenId(resp?.data?.detail?.content_token);
        setStatus(resp?.data?.detail?.state_id);
        setIsSelectedFile(true);
        applicableForFun(resp?.data?.detail?.applicable_for);
        setDocumentType(resp?.data?.detail?.document_type);
        setInstID(resp?.data?.detail?.campus_id);
        setValue({
          name: resp?.data?.detail?.campus_name
        });
        setLoading(false);
        setFormat(resp?.data?.detail?.format);
        setTempCat(resp?.data?.detail?.category_id);
      } else {
        setLoading(false);
      }
    });
  };

  return (
    <>
      <div className="dash-main-content">
        <Container fluid>
          <div className="dash-page-content">
            <div className="dash-page-title">
              <Row>
                <Col xs={12} sm={12} md={12}>
                  <div className="dash-title-bar d-flex align-items-center justify-content-between">
                    <div className="title-lft d-flex align-items-center">
                      <div className="dash-icn">
                        <i className="fas fa-briefcase"></i>
                      </div>
                      <div className="dash-title-dis">
                        {id ? <h5>Update WhatsApp Template</h5> : <h5>Add WhatsApp Template</h5>}
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="dash-content-card">
              <div className="cmmn-form">
                <Form>
                  <Row>
                    <Col md={12}>
                      <Form.Group className="mb-3" controlId="formBasicName">
                        <Form.Label>
                          Template Title<span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter Template Title"
                          onChange={(e) => setTemplateTitle(e.target.value)}
                          value={templateTitle}
                        />
                      </Form.Group>
                    </Col>

                    <Col md={12}>
                      <Form.Group className="mb-3" controlId="formBasicName">
                        <Form.Label>
                          Template Applicable for<span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Select
                          aria-label="Default select example"
                          onChange={(e) => applicableForFun(e.target.value)}
                          value={applicableFor}>
                          {applicableForOption == true || applicableFor ? (
                            ''
                          ) : (
                            <option>Select Template Applicable for</option>
                          )}
                          {applicableForList?.map((data, index) => (
                            <>
                              <option key={index} value={data?.id}>
                                {data?.name}
                              </option>
                            </>
                          ))}
                        </Form.Select>
                      </Form.Group>
                    </Col>
                    <h5 className="mt-3">WhatsApp Business Template</h5>
                    <Col md={12}>
                      <Form.Group className="mb-3" controlId="formBasicName">
                        <Form.Label>
                          Format<span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Select
                          aria-label="Default select example"
                          onChange={(e) => setFormat(e.target.value)}
                          value={format}>
                          {format ? '' : <option>Select Nature of Template </option>}
                          <option value={TEMPLATE_FORMAT_CAT1}>Category 1</option>
                          <option value={TEMPLATE_FORMAT_CAT2}>Category 2</option>
                          <option value={TEMPLATE_FORMAT_CAT3}>Category 3</option>
                        </Form.Select>
                      </Form.Group>
                    </Col>

                    <Col md={12}>
                      <Form.Group className="mb-3" controlId="formBasicName">
                        <Form.Label>
                          Template Category<span className="text-danger">*</span>{' '}
                        </Form.Label>
                        <Form.Select
                          aria-label="Default select example"
                          onChange={(e) => setTempCat(e.target.value)}
                          value={tempCat}>
                          <option>Select Category</option>
                          <option value="1">Promotional</option>
                          <option value="2">Personal</option>
                        </Form.Select>
                      </Form.Group>
                    </Col>

                    <Col md={12}>
                      <Form.Group className="mb-3" controlId="formBasicDesi">
                        <Form.Label>Content </Form.Label>
                        <Form.Control
                          as="textarea"
                          rows={3}
                          placeholder="Enter your Content"
                          onChange={(e) => setContent(e.target.value)}
                          value={content}
                        />
                      </Form.Group>
                    </Col>

                    <Col md={12}>
                      <Form.Group controlId="formFile" className="mb-3">
                        <div className="file-upload">
                          <Form.Control
                            type="file"
                            accept={
                              documentType == TEMPLATE_DOCUMENT_TYPE_PDF
                                ? 'image/*,.pdf'
                                : '.doc,.odt,.docx'
                            }
                            name="upload"
                            onChange={handleUploadFile}
                          />
                          <div className="file-upload-overlay">
                            <div>
                              <img
                                src={
                                  isSelectedFile
                                    ? documentType == TEMPLATE_DOCUMENT_TYPE_PDF
                                      ? require('../../assets/images/pdf.png')
                                      : require('../../assets/images/docx.png')
                                    : require('../../assets/images/imageicon.png')
                                }
                                alt="attachfile"
                              />
                              <h4>{`${
                                isSelectedFile
                                  ? selectedFile?.name
                                    ? selectedFile?.name
                                    : selectedFile
                                  : 'Attach File'
                              }`}</h4>
                            </div>
                          </div>
                        </div>
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group className="mb-3" controlId="formBasicName">
                        <Form.Label>
                          Institute <span className="text-danger">*</span>
                        </Form.Label>
                        <AsyncPaginate
                          className="w100  p16 mr_normal_padding_l mr_medium_padding_r"
                          loadOptions={loadOptions}
                          name="meal_id"
                          id={Math.random()}
                          getOptionValue={(option) => {
                            return option.institute_id;
                          }}
                          getOptionLabel={(option) => {
                            return option.name;
                          }}
                          value={value}
                          onChange={setValue}
                          additional={{
                            page: 1
                          }}
                          placeholder="Select Institute"
                          debounceTimeout={500}
                          isSearchable={true}
                        />
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group className="mb-3" controlId="formBasicName">
                        <Form.Label>
                          Assign User To <span className="text-danger">*</span>{' '}
                        </Form.Label>
                        <Select
                          isMulti
                          name="colors"
                          className="basic-multi-select"
                          classNamePrefix="select"
                          options={assignMemberList2}
                          placeholder="Select"
                          value={assignMemberList}
                          onChange={(e) => {
                            handleChange(e);
                          }}
                        />
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group className="mb-3" controlId="formBasicName">
                        <Form.Label>
                          Token<span className="text-danger">*</span>{' '}
                        </Form.Label>
                        <Form.Select
                          aria-label="Default select example"
                          onChange={(e) => onTokenSelect(e)}
                          value={tokenId}>
                          {selectToken == true || tokenId ? (
                            ''
                          ) : (
                            <option>Select Subject Token </option>
                          )}
                          {tokenList.length > 0 &&
                            tokenList?.map((data, index) => (
                              <>
                                <option key={index} value={data?.id}>
                                  {data?.title}
                                </option>
                              </>
                            ))}
                        </Form.Select>
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group className="mb-3" controlId="formBasicName">
                        <Form.Label>Status </Form.Label>
                        <Form.Select
                          aria-label="Default select example"
                          onChange={(e) => setStatus(e.target.value)}
                          value={status}>
                          <option> Select Status</option>
                          <option value="1">Enable</option>
                          <option value="2">Disable</option>
                        </Form.Select>
                      </Form.Group>
                    </Col>
                  </Row>
                  <div className="form-btns d-flex align-items-center mt-3">
                    {id ? (
                      <>
                        <Button className="main-btn-grn" onClick={updateWhatsAppTemplate}>
                          Update
                        </Button>
                        <strong>OR</strong>
                        <Button className="main-btn-red" onClick={() => navigate(-1)}>
                          Cancel
                        </Button>{' '}
                      </>
                    ) : (
                      <>
                        <Button className="main-btn-grn" onClick={saveWhatsAppTemplate}>
                          Save
                        </Button>
                        <strong>OR</strong>
                        <Button className="main-btn-red" onClick={() => navigate(-1)}>
                          Cancel
                        </Button>
                      </>
                    )}
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </Container>
      </div>

      {loading ? <Loader /> : ''}
    </>
  );
}
