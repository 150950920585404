import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Loader from '../../commoncomponents/loader/loader-large';
import * as APISERVICE from '../../../services/AuthServices';
import showNotification from '../../../services/NotificationService';
import { useDispatch, useSelector } from 'react-redux';
import { loginInfo, logout } from '../../../redux/action/index';
import { useNavigate } from 'react-router-dom';
import { Container } from 'react-bootstrap';

function AdminLoginPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userDetail = useSelector((state) => state?.user?.details);
  const [loginDetails, setLoginDetails] = useState({
    username: '',
    password: ''
  });

  const [loading, setLoading] = useState(false);
  const [checkCred, setCheckCred] = useState(false);

  useEffect(() => {
    if (userDetail?.is_admin) {
      navigate('/admin/user/welcome');
    } else {
      localStorage.clear();
      dispatch(logout());
      navigate('/admin');
    }
  }, []);

  //input-value
  const onChangeHandler = (e) => {
    const { name, value } = e.target;
    setLoginDetails({ ...loginDetails, [name]: value });
  };

  const validate = (values) => {
    let errors = {};
    if (!values.username) {
      errors.username = 'Please enter username';
    }
    if (!values.password) {
      errors.password = 'Please enter password';
    }
    return errors;
  };

  // onClick form submit-api call
  const submitHandler = (e) => {
    e.preventDefault();
    setCheckCred(true);
    if (!validate(loginDetails).password && !validate(loginDetails).username) {
      setLoading(true);
      let formData = new FormData();
      for (let i in loginDetails) {
        formData.append(`${i}`, loginDetails[i]);
      }
      APISERVICE.adminLogin(formData).then((res) => {
        setLoading(false);
        if (res?.status === 200) {
          showNotification('success', 'login successfully');
          localStorage.setItem('token', res?.data?.access_token);
          localStorage.setItem('userDetail', JSON.stringify(res?.data?.data));
          dispatch(loginInfo(res?.data?.data));
          navigate('/admin/user/welcome');
        }
      });
    }
  };

  return (
    <>
      {/* <Loader/> */}
      <section className="admin-login">
        <Container>
          <Row className="align-items-center justify-content-center">
            <Col md={4}>
              <div className="admin-login-card">
                <div className="admin-login-logo">
                  <Link to="#">
                    <img src={require('../../../assets/images/almabay-logo-dash.png')} alt="img" />
                  </Link>
                </div>
                <div className="login-body">
                  <Form>
                    <Form.Group className="mb-3 login-form-card" controlId="formBasicEmail">
                      <Form.Control
                        onChange={(e) => {
                          onChangeHandler(e);
                        }}
                        type="email"
                        name="username"
                        placeholder="Enter email"
                      />
                      {checkCred && validate(loginDetails)?.username ? (
                        <span className="login-error">
                          {checkCred && validate(loginDetails)?.username}
                        </span>
                      ) : (
                        ''
                      )}
                    </Form.Group>
                    <Form.Group className="mb-3 login-form-card" controlId="formBasicPassword">
                      <Form.Control
                        onChange={(e) => {
                          onChangeHandler(e);
                        }}
                        type="password"
                        name="password"
                        placeholder="Password"
                      />
                      {checkCred && validate(loginDetails)?.password ? (
                        <span className="login-error">
                          {checkCred && validate(loginDetails)?.password}
                        </span>
                      ) : (
                        ''
                      )}
                    </Form.Group>
                    <Button
                      className="login-btn"
                      type="submit"
                      onClick={(e) => {
                        submitHandler(e);
                      }}
                    >
                      Login
                    </Button>
                  </Form>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <div className="login-footer">
        <strong>
          <Link target="_blank" to="https://almabay.co.in">
            AlmaBay.com
          </Link>
          © {new Date().getFullYear()}.
        </strong>
        All rights reserved.
      </div>
      {loading ? <Loader /> : ''}
    </>
  );
}

export default AdminLoginPage;
