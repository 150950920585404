import { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Table from 'react-bootstrap/Table';
import ReactPaginate from 'react-paginate';
import { Link, useNavigate } from 'react-router-dom';
import Loader from '../../components/commoncomponents/loader/loader-large';
import { statusName, truncate } from '../../globals/helper';
import * as adminServices from '../../services/AuthServices';
import showNotification from '../../services/NotificationService';

export default function AdminPanelContent() {
  const [list, setList] = useState([]);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [searchedData, setSearchData] = useState({
    country_id: '',
    country_code: '',
    country_name: ''
  });
  const [response, setResponse] = useState(0);
  const [sortType, setSortType] = useState('DESC');
  const [sortField, setSortField] = useState('country_id');
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    apiCall();
  }, []);

  const apiCall = (page = 1) => {
    setLoading(true);
    // setCurrentPage();
    adminServices.getContentList(page).then((res) => {
      setLoading(false);
      if (res?.status === 200) {
        setList(res?.data?.data);
        setResponse(res?.data?.total_count);
      }
    });
  };

  const selectHandler = (value, data) => {
    switch (value) {
      case 'edit':
        if (data?.menu_id == 0) {
          navigate(`/admin/add-website-content?id=${data?.id}`);
        } else {
          navigate(`/admin/add-open-page-content?id=${data?.menu_id}`);
        }
        break;
      case '2':
        handleDelete(data?.id);
        break;
      default:
        break;
    }
  };

  const stateHandler = (value, id) => {
    setLoading(true);
    let status = value;
    adminServices.countryUpdateStatus(id, status).then((res) => {
      setLoading(false);
      if (res?.status === 200) {
        document.getElementById('select').value = '';
        apiCall();
      }
    });
  };

  const resetHandler = (e) => {
    e.preventDefault();
    setSearchData({
      country_id: '',
      country_code: '',
      country_name: ''
    });
    setSortType('DESC');
    setSortField('');
    apiCall();
  };

  const sortHandler = (e, type) => {
    e.preventDefault();
    let currentSort = sortType === 'ASC' ? 'DESC' : 'ASC';
    setSortType(currentSort);
    setSortField(type);
    apiCall(
      currentPage,
      type,
      currentSort,
      searchedData.country_id,
      searchedData.country_code,
      searchedData.country_name
    );
  };

  const onChangeSearch = (e) => {
    const { name, value } = e.target;
    setSearchData({ ...searchedData, [name]: value });
  };

  const searchHandler = (e) => {
    e.preventDefault();
    apiCall(
      currentPage,
      sortField,
      sortType,
      searchedData.country_id,
      searchedData.country_code,
      searchedData.country_name
    );
  };

  const handlePageClick = (a) => {
    let page = a?.selected + 1;
    setCurrentPage(page);
    apiCall(
      page,
      sortField,
      sortType,
      searchedData.country_id,
      searchedData.country_code,
      searchedData.country_name
    );
  };

  const showLayoutType = (key, menu_name) => {
    switch (key) {
      case 1:
        return 'About';
      case 2:
        return 'Networking';
      case 3:
        return 'Principal Message';
      case 4:
        return 'Mentor a student';
      default:
        return menu_name;
    }
  };
  const handleDelete = (id) => {
    adminServices.deletePageContent(id).then((res) => {
      if (res?.status == 200) {
        showNotification('success', res?.data?.message);
        apiCall();
      }
    });
  };

  return (
    <>
      <div className="dash-main-content">
        <Container fluid>
          <div className="dash-page-content">
            <div className="dash-page-title">
              <Row>
                <Col xs={12} sm={12} md={12}>
                  <div className="dash-title-bar d-flex align-items-center justify-content-between">
                    <div className="title-lft d-flex align-items-center">
                      <div className="dash-icn">
                        <i className="fas fa-building"></i>
                      </div>
                      <div className="dash-title-dis">
                        <h5>
                          Content <span>Listing</span>
                        </h5>
                        {list?.length !== 0 && (
                          <h5>
                            Total Record(s): <span>{response}</span>
                          </h5>
                        )}
                      </div>
                    </div>
                    <div className="title-rt-btn">
                      <Link className="green_button_admin" to={`/admin/add-website-content`}>
                        Add New Content
                      </Link>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="dash-content-card">
              <div className="table-responsive">
                <Table className="custom-table-data" striped bordered hover>
                  <thead>
                    <tr>
                      <th>
                        <Link to="#">Id</Link>
                      </th>
                      <th>
                        <Link to="#">Type</Link>
                      </th>

                      <th>
                        <Link to="#">Institute Name</Link>
                      </th>
                      <th>Description</th>
                      <th>Status</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {list && list?.length
                      ? list?.map((data, index) => (
                        <tr key={index}>
                          <td>{data?.id}</td>
                          <td>{showLayoutType(+data?.type_id, data?.menu_name)}</td>
                          <td>{data?.institute_name}</td>
                          <td>
                            <span
                              dangerouslySetInnerHTML={{
                                __html: truncate(data?.description, 50)
                              }}></span>
                          </td>
                          <td>{statusName(data?.state_id)}</td>
                          <td>
                            <Form.Select
                              aria-label="Default select example"
                              value={''}
                              id="select"
                              onChange={(e) => {
                                selectHandler(e.target.value, data);
                              }}>
                              <option value="">Action</option>
                              <option value="edit">Edit</option>
                              <option value="2">Delete</option>
                            </Form.Select>
                          </td>
                        </tr>
                      ))
                      : ''}
                  </tbody>
                </Table>
                {Math.ceil(response / 10) > 1 ? (
                  <div className="react-page d-flex justify-content-center">
                    <ReactPaginate
                      previousLabel={currentPage > 0 ? '<' : ''}
                      nextLabel={response > 0 ? '>' : ''}
                      onPageChange={handlePageClick}
                      activeClassName={'page-link active'}
                      pageCount={Math.ceil(response / 10)}
                    />
                  </div>
                ) : (
                  ''
                )}
              </div>
            </div>
          </div>
        </Container>
      </div>
      {loading ? <Loader /> : ''}
    </>
  );
}
