import { Link } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { useSelector } from 'react-redux';
import { addFeedback } from '../../services/AuthServices';
import { emailRegex, restrictAlpha } from '../../globals/helper';
import showNotification from '../../services/NotificationService';
import { useState } from 'react';

function FooterGrey(props) {
  const { state } = props;
  const isloggedIn = useSelector((state) => state?.user?.isloggedIn);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [clicked, setClicked] = useState(false);
  const [addFeedBack, setAddFeedback] = useState({
    name: '',
    email: '',
    subject: 'Share an idea',
    mobile: '',
    message: ''
  });
  const validate = () => {
    let errors = {};
    if (!addFeedBack.name.trim()) {
      errors.name = 'This field is required.';
    }
    if (!addFeedBack.email.trim()) {
      errors.email = 'This field is required.';
    } else if (!emailRegex.test(addFeedBack.email)) {
      errors.email = 'please enter valid email';
    }
    if (!addFeedBack.mobile.trim()) {
      errors.mobile = 'This field is required';
    } else if (addFeedBack.mobile.length <= 9) {
      errors.mobile = 'please enter atleast 10 digit';
    }
    if (!addFeedBack.message.trim()) {
      errors.message = 'This field is required.';
    }
    return errors;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setAddFeedback({ ...addFeedBack, [name]: value });
  };

  const handleSubmit = () => {
    setClicked(true);
    if (!Object.keys(validate()).length) {
      setClicked(false);
      addFeedback(addFeedBack).then((res) => {
        showNotification('success', res?.data?.detail);
        handleClose();
      });
    }
  };
  return (
    <>
      <section className="footer-card-grey py-5">
        <div className="container">
          <Row>
            <Col xs={12} sm={6} md={6} lg={3}>
              <div className="ftr-grey-lnks">
                <h4>Social</h4>
                <ul>
                  <li>
                    {isloggedIn ? (
                      <Link to="/mentors/directory">Find Alumni</Link>
                    ) : (
                      <Link to="/login">Find Alumni</Link>
                    )}
                  </li>
                  <li>
                    {isloggedIn ? (
                      <Link to="/user/alumnimap">Alumni Map</Link>
                    ) : (
                      <Link to="/login">Alumni Map</Link>
                    )}
                  </li>
                  <li>
                    <a href="https://blog.almabay.com" rel="noreferrer" target={'_blank'}>
                      Blog
                    </a>
                  </li>
                </ul>
              </div>
            </Col>
            <Col xs={12} sm={6} md={6} lg={3}>
              <div className="ftr-grey-lnks">
                <h4>Professional</h4>
                <ul>
                  <li>
                    <Link to="/jobs/search">Browse jobs </Link>
                  </li>
                  <li>
                    <Link to="/home/recruitments">Recruitment advocacy program</Link>
                  </li>
                  <li>
                    <Link to="/recruiter-solutions">Recruitment Solutions</Link>
                  </li>
                  <li>
                    <Link to="/user/jobsmap">Browse jobs on map </Link>
                  </li>
                </ul>
              </div>
            </Col>
            <Col xs={12} sm={6} md={6} lg={3}>
              <div className="ftr-grey-lnks">
                <h4>Alma Store</h4>
                <ul>
                  <li>
                    <Link to="/shop">Browse Memorabilia</Link>
                  </li>
                  <li>
                    <Link to="/delivery-information">Delivery Information</Link>
                  </li>
                  <li>
                    <Link to="/return-policy">Return Policy</Link>
                  </li>
                  {isloggedIn ? (
                    <li>
                      <Link to="/shop/orders">My Orders</Link>
                    </li>
                  ) : (
                    <li>
                      <Link to="/shop">My Orders</Link>
                    </li>
                  )}
                </ul>
              </div>
            </Col>
            <Col xs={12} sm={6} md={6} lg={3}>
              <div className="ftr-grey-lnks">
                <h4>Help</h4>
                <ul>
                  <li>
                    <Link to="/Faq">FAQ's</Link>
                  </li>
                  <li>
                    <Link to="/advancesearch/directory/A">Find Your Institute</Link>
                  </li>
                </ul>

                <div className="text-center mt-4">
                  <p className="m-0 darktext text-center">
                    <i className="fa fa-2x text-faded fa-phone"></i>
                    <br />
                    +91-82880-25661
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </section>
      <div className={`feedback-card ${state ? state : ''}`}>
        <Link to="/invite">Invite Friends</Link>
        <Link to="" onClick={handleShow}>
          Feedback
        </Link>
      </div>
      <Modal show={show} onHide={handleClose} className="feedback-modal ">
        <Modal.Header closeButton>
          <Modal.Title>Share your feedback</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group as={Row} className="mb-4" controlId="formHorizontalName">
              <Form.Label column sm={3}>
                Your Name<sup className="text-danger">*</sup>
              </Form.Label>
              <Col sm={9}>
                <Form.Control
                  type="text"
                  name="name"
                  // value={addFeedBack?.name}
                  onChange={handleChange}
                />
                <div className={`${clicked && validate().name ? '' : 'd-none'} danger-frm `}>
                  {clicked && validate().name}
                </div>
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-4" controlId="formHorizontalEmail">
              <Form.Label column sm={3}>
                Email address <sup className="text-danger">*</sup>
              </Form.Label>

              <Col sm={9}>
                <Form.Control
                  type="email"
                  // value={addFeedBack?.email}
                  placeholder="info@example.com"
                  name="email"
                  onChange={handleChange}
                />
                <div className={`${clicked && validate().email ? '' : 'd-none'} danger-frm `}>
                  {clicked && validate().email}
                </div>
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-4" controlId="formHorizontalMobile">
              <Form.Label column sm={3}>
                Mobile No. <sup className="text-danger">*</sup>
              </Form.Label>

              <Col sm={9}>
                <Form.Control
                  type="text"
                  onKeyPress={restrictAlpha}
                  placeholder="XXXXXXXXXX"
                  name="mobile"
                  maxLength={15}
                  onChange={handleChange}
                />
                <div className={`${clicked && validate().mobile ? '' : 'd-none'} danger-frm `}>
                  {clicked && validate().mobile}
                </div>
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-4" controlId="formHorizontalName">
              <Form.Label column sm={3}>
                I want to
              </Form.Label>
              <Col sm={9}>
                <Form.Select
                  aria-label="Default select example"
                  name="subject"
                  onChange={handleChange}
                >
                  <option value="Share an idea">Share an idea</option>
                  <option value="Ask a question">Ask a question</option>
                  <option value="Report an issue">Report an issue</option>
                  <option value="Just say Hello">Just say Hello</option>
                </Form.Select>
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-4" controlId="formHorizontalName">
              <Form.Label column sm={3}>
                Message<sup className="text-danger">*</sup>
              </Form.Label>

              <Col sm={9}>
                <Form.Control
                  as="textarea"
                  // placeholder="Your question"
                  style={{ height: '100px' }}
                  name="message"
                  onChange={handleChange}
                />
                <div className={`${clicked && validate().message ? '' : 'd-none'} danger-frm `}>
                  {clicked && validate().message}
                </div>
              </Col>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleSubmit}>
            Submit
          </Button>
          {/* <Button variant="primary" onClick={handleClose}>
            Close
          </Button> */}
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default FooterGrey;
