/**
@copyright  :   ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
@author     :   Shiv Charan Panjeta 
 
All Rights Reserved.
Proprietary and confidential :  All information contained here in is, and remains
the property of ToXSL Technologies Pvt. Ltd. and it's partners.
Unauthorized copying of this file, via any medium is strictly prohibited.
*/
import { useEffect, useState } from 'react';
import { Button, Container } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { restrictAlpha } from '../../../globals/helper';
import {
  addEventTicketList,
  getEventTicketDetails,
  updateEventTicketList
} from '../../../services/AuthServices';
import showNotification from '../../../services/NotificationService';
export default function AddTicket() {
  const navigate = useNavigate();
  const [clicked, setClicked] = useState(false);
  const location = useLocation();
  const [loding, setLoading] = useState(false);
  const { event_id, ticket_id } = useParams();
  const id = location.state;
  const [addData, setAddData] = useState({
    event_id: '',
    id: '',
    ticket_name: '',
    quantity: '',
    limit: '',
    ticket_type: '',
    price: ''
  });

  useEffect(() => {
    if (ticket_id) {
      getDetail();
    }
  }, []);

  const validate = () => {
    let error = {};
    if (!addData?.ticket_name.trim()) {
      error.ticket_name = 'This field is required.';
    }
    if (!addData?.quantity.trim()) {
      error.quantity = 'This field is required.';
    }
    if (addData?.limit > addData?.quantity) {
      error.limit = 'Limit should be equal or less than quantity';
    }
    if (addData?.ticket_type == '') {
      error.ticket_type = 'This field is required.';
    }
    if (addData?.ticket_type == 'PAID' && !addData?.price) {
      error.price = 'This field is required.';
    }
    return error;
  };

  const submitHandle = (e) => {
    e.preventDefault();
    setClicked(true);
    if (!Object.keys(validate(addData)).length) {
      setClicked(false);
      let body = {
        event_id: id,
        id: ticket_id,
        ticket_name: addData?.ticket_name,
        quantity: addData?.quantity,
        limit: addData?.limit,
        ticket_type: addData?.ticket_type,
        price: addData?.price
      };
      addEventTicketList(body).then((resp) => {
        if (resp?.status == 200) {
          showNotification('success', resp?.data?.message);
          navigate('/admin/event/EventTicket', { state: id });
        }
      });
    }
  };

  const updateHandler = (e) => {
    e.preventDefault();
    setClicked(true);
    if (!Object.keys(validate(addData)).length) {
      setClicked(false);
      let body = {
        event_id: event_id,
        id: ticket_id,
        ticket_name: addData?.ticket_name,
        quantity: addData?.quantity,
        limit: addData?.limit,
        ticket_type: addData?.ticket_type,
        price: addData?.price
      };
      updateEventTicketList(body).then((resp) => {
        if (resp?.status == 200) {
          showNotification('success', resp?.data?.data);
          navigate(`/admin/event/EventTicket`, { state: event_id });
        }
      });
    }
  };

  const onChangeHandler = (e) => {
    const { name, value } = e.target;
    setAddData({ ...addData, [name]: value });
  };

  const getDetail = () => {
    setLoading(true);
    getEventTicketDetails(event_id, ticket_id).then((res) => {
      setLoading(false);
      if (res?.status === 200) {
        const data = res?.data?.data?.details;
        setAddData({
          event_id: event_id,
          id: ticket_id,
          ticket_name: data?.ticket_name,
          quantity: data?.quantity,
          limit: data?.limit,
          ticket_type: data?.ticket_type,
          price: data?.price
        });
      }
    });
  };

  return (
    <div className="dash-main-content">
      <Container fluid>
        <div className="dash-page-content">
          <div className="dash-page-title">
            <Row>
              <Col xs={12} sm={12} md={12}>
                <div className="dash-title-bar d-flex align-items-center justify-content-between">
                  <div className="title-lft d-flex align-items-center">
                    <div className="dash-icn">
                      <i className="fas fa-university"></i>
                    </div>
                    <div className="dash-title-dis">
                      {ticket_id ? <h5>Upate Event Ticket</h5> : <h5>Add Event Ticket</h5>}
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <div className="dash-content-card">
            <div className="cmmn-form">
              <Form>
                <Row>
                  <Col md={12}>
                    <Form.Group className="mb-3" controlId="formBasicName">
                      <Form.Label>
                        Ticket Name <span className="text-danger">*</span>{' '}
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter ticket name"
                        name="ticket_name"
                        value={addData?.ticket_name}
                        onChange={(e) => {
                          onChangeHandler(e);
                        }}
                      />

                      <div
                        className={`${
                          clicked && validate(addData)?.ticket_name ? '' : 'd-none'
                        } danger-frm `}>
                        {clicked && validate(addData)?.ticket_name}
                      </div>
                    </Form.Group>
                  </Col>
                  <Col md={12}>
                    <Form.Group className="mb-3" controlId="formBasicName">
                      <Form.Label>
                        Quantity <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter quantity"
                        name="quantity"
                        value={addData?.quantity}
                        onKeyPress={restrictAlpha}
                        onChange={(e) => {
                          onChangeHandler(e);
                        }}
                      />
                      <div
                        className={`${
                          clicked && validate(addData)?.quantity ? '' : 'd-none'
                        } danger-frm `}>
                        {clicked && validate(addData)?.quantity}
                      </div>
                    </Form.Group>
                  </Col>
                  <Col md={12}>
                    <Form.Group className="mb-3" controlId="formBasicName">
                      <Form.Label>Limit</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter limit"
                        name="limit"
                        value={addData?.limit}
                        onKeyPress={restrictAlpha}
                        onChange={(e) => {
                          onChangeHandler(e);
                        }}
                      />
                      <div
                        className={`${
                          clicked && validate(addData)?.limit ? '' : 'd-none'
                        } danger-frm `}>
                        {clicked && validate(addData)?.limit}
                      </div>
                    </Form.Group>
                  </Col>
                  <Col md={12}>
                    <Form.Group className="mb-3" controlId="formBasicState">
                      <Form.Label>
                        Ticket Type <span className="text-danger">*</span>{' '}
                      </Form.Label>
                      <Form.Select
                        aria-label="Default select example"
                        name="ticket_type"
                        value={addData?.ticket_type}
                        onChange={(e) => {
                          onChangeHandler(e);
                        }}>
                        <option value="">Select</option>
                        <option value="FREE">Free</option>
                        <option value="PAID">Paid</option>
                      </Form.Select>
                      <div
                        className={`${
                          clicked && validate(addData)?.ticket_type ? '' : 'd-none'
                        } danger-frm `}>
                        {clicked && validate(addData)?.ticket_type}
                      </div>
                    </Form.Group>
                  </Col>
                  {addData?.ticket_type == 'PAID' ? (
                    <Col md={12}>
                      <Form.Group className="mb-3" controlId="formBasicName">
                        <Form.Label>Price</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter Price"
                          name="price"
                          value={addData?.price}
                          onKeyPress={restrictAlpha}
                          onChange={(e) => {
                            onChangeHandler(e);
                          }}
                        />
                        <div
                          className={`${
                            clicked && validate(addData)?.price ? '' : 'd-none'
                          } danger-frm `}>
                          {clicked && validate(addData)?.price}
                        </div>
                      </Form.Group>
                    </Col>
                  ) : (
                    ''
                  )}
                </Row>
                <div className="form-btns d-flex align-items-center mt-3">
                  {ticket_id ? (
                    <Button
                      className="main-btn-grn"
                      onClick={(e) => {
                        updateHandler(e);
                      }}>
                      Update
                    </Button>
                  ) : (
                    <Button
                      className="main-btn-grn"
                      onClick={(e) => {
                        submitHandle(e);
                      }}>
                      Save
                    </Button>
                  )}

                  <strong>OR</strong>
                  <Button className="main-btn-red" onClick={() => navigate(-1)}>
                    Cancel
                  </Button>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}
