function NoDataFound(props) {
  return (
    <div className="bg-white py-5">
      <div className=" text-center">
        <img src={require('../../assets/images/small-error.png')} width="105px" alt="img" />
        <p className="text-center mt-2 mb-0">{props?.text ? props?.text : 'No Data Found'}</p>
      </div>
    </div>
  );
}

export default NoDataFound;
