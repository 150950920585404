/** @copyright : ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
 @author     : Shiv Charan Panjeta 

 All Rights Reserved.
 Proprietary and confidential :  All information contained herein is, and remains
 the property of ToXSL Technologies Pvt. Ltd. and its partners.
 Unauthorized copying of this file, via any medium is strictly prohibited.
*/
import { Suspense, lazy, useEffect, useState } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import InstituteFooter from '../../components/commoncomponents/InstituteFooter';
import AboutInstitute from '../../components/webpages/institute-pages/AboutInstitute';
import Alumni from '../../components/webpages/institute-pages/Alumni';
import AppDownload from '../../components/webpages/institute-pages/AppDownload';
import BlogDetail from '../../components/webpages/institute-pages/BlogDetail';
import BlogPage from '../../components/webpages/institute-pages/BlogPage';
import ChapterDetails from '../../components/webpages/institute-pages/ChapterDetails';
import Chapters from '../../components/webpages/institute-pages/Chapters';
import ClubDetails from '../../components/webpages/institute-pages/ClubDetails';
import ClubPage from '../../components/webpages/institute-pages/ClubPage';
import ContactPage from '../../components/webpages/institute-pages/ContactPage';
import EventDetailInstitute from '../../components/webpages/institute-pages/EventDetailInstitute';
import EventInstitute from '../../components/webpages/institute-pages/EventInstitute';
import GalleryPage from '../../components/webpages/institute-pages/GalleryPage';
import ImageDetailView from '../../components/webpages/institute-pages/ImageDetailView';
import InstituteLogin from '../../components/webpages/institute-pages/InstituteLogin';
import InstituteRegister from '../../components/webpages/institute-pages/InstituteRegister';
import JobsBoard from '../../components/webpages/institute-pages/JobsBoard';
import MentorStudent from '../../components/webpages/institute-pages/MentorStudent';
import MentorshipInst from '../../components/webpages/institute-pages/MentorshipInst';
import MotivationalMemorabilia from '../../components/webpages/institute-pages/MotivationalMemorabilia';
import Networking from '../../components/webpages/institute-pages/Networking';
import NewsDetail from '../../components/webpages/institute-pages/NewsDetail';
import NewsPage from '../../components/webpages/institute-pages/NewsPage';
import OpenPage from '../../components/webpages/institute-pages/OpenPage';
import PageNotFound from '../../components/webpages/institute-pages/PageNotFound';
import PrincipalMessage from '../../components/webpages/institute-pages/PrincipalMessage';
import VideoDetailView from '../../components/webpages/institute-pages/VideoDetailView';
import PageNotfound from '../../components/webpages/user-pages/PageNotfound';
import { checkDomain } from '../../services/InstituteServices';
import Institute from '../../components/webpages/institute-pages/institute.jsx';
import Step1 from '../../components/webpages/user-pages/stepform/Step1.jsx';
import Step2 from '../../components/webpages/user-pages/stepform/Step2.jsx';
import Step3 from '../../components/webpages/user-pages/stepform/Step3.jsx';
import Step4 from '../../components/webpages/user-pages/stepform/Step4.jsx';
import Step5 from '../../components/webpages/user-pages/stepform/Step5.jsx';
import ClubRoutes from '../club-routes/ClubRoutes.js';
import ClubHeader from '../../components/webpages/club-pages/ClubHeader.jsx';
const InstituteHeader = lazy(() => import('../../components/commoncomponents/InstituteMainHeader'));

function InstituteRoutes() {
  const [isDomainExist, setIsDomainExist] = useState(false);
  const [isClub, setIsClub] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    checkApi();
  }, []);

  const checkApi = () => {
    checkDomain().then((res) => {
      if (res?.status === 200) {
        if (res?.data?.message == 'Data not found!') {
          navigate('/error-page');
        } else {
          setIsDomainExist(true);
          let detail = res?.data?.details;
          //redirect to club website or institute
          if (detail?.type == 'club') {
            setIsClub(true);
            if (location.pathname.startsWith('/club')) {
              navigate(location.pathname);
            } else {
              navigate('/club/home');
            }
          }
        }
      }
    });
  };

  return (
    <>
      {isDomainExist && (
        <Suspense fallback={<div className="institute-header header-top">Loading...</div>}>
          {' '}
          {!isClub ? <InstituteHeader /> : <ClubHeader />}{' '}
        </Suspense>
      )}
      <Routes>
        <Route path="/institute-login" element={<InstituteLogin />} />
        <Route path="/institute-register" element={<InstituteRegister />} />
        {/* render component on basis of delected layout  */}
        <Route path="/" element={<Institute />} />
        {/* end */}
        <Route path="/app-download" element={<AppDownload />} />
        <Route path="/mentorship" element={<MentorshipInst />} />
        <Route path="/gallery" element={<GalleryPage />} />
        <Route path="/gallery-view/:id" element={<ImageDetailView />} />
        <Route path="/videos-view/:id" element={<VideoDetailView />} />
        <Route path="/blog" element={<BlogPage />} />
        <Route path="/blog-details/:id" element={<BlogDetail />} />
        <Route path="/alumni" element={<Alumni />} />
        <Route path="/motivational-memo" element={<MotivationalMemorabilia />} />
        <Route path="/club" element={<ClubPage />} />
        <Route path="/club-detail/:id" element={<ClubDetails />} />
        {/* website content pages */}
        <Route path="/principal-message" element={<PrincipalMessage />} />
        <Route path="/networking" element={<Networking />} />
        <Route path="/mentor-student" element={<MentorStudent />} />
        {/* end */}
        <Route path="/contact" element={<ContactPage />} />
        <Route path="/news" element={<NewsPage />} />
        <Route path="/news-detail-page/:id" element={<NewsDetail />} />
        <Route path="/about-institute" element={<AboutInstitute />} />
        <Route path="/event-institute" element={<EventInstitute />} />
        <Route path="/event-detail-institute/:id" element={<EventDetailInstitute />} />
        <Route path="/chapter-details" element={<ChapterDetails />} />
        <Route path="/chapters" element={<Chapters />} />
        <Route path="/jobs-board" element={<JobsBoard />} />
        {/* open page */}
        <Route path="/pages/:id" element={<OpenPage />} />

        <Route path="/institute-register/step/1/:userId" element={<Step1 />} />
        <Route path="/institute-register/step/2/:userId" element={<Step2 />} />
        <Route path="/institute-register/step/3/:userId" element={<Step3 />} />
        <Route path="/institute-register/step/4/:userId" element={<Step4 />} />
        <Route path="/institute-register/step/5/:userId" element={<Step5 />} />
        {/* end */}
        <Route path="/error-page" element={<PageNotFound />} />
        <Route path="/club/*" element={<ClubRoutes />} />
        <Route path="/*" element={<PageNotfound />} />
      </Routes>
      {isDomainExist && <InstituteFooter />}
    </>
  );
}

export default InstituteRoutes;
