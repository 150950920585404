/**
@copyright  :   ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
@author     :   Shiv Charan Panjeta 
 
All Rights Reserved.
Proprietary and confidential :  All information contained here in is, and remains
the property of ToXSL Technologies Pvt. Ltd. and it's partners.
Unauthorized copying of this file, via any medium is strictly prohibited.
*/

import { Col, Row } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import Tab from 'react-bootstrap/Tab';
import moment from 'moment';
import * as constants from '../../../../globals/constants';
import showNotification from '../../../../services/NotificationService';
function EventsOverview({
  handleParticipate,
  handleDeleteSponsor,
  sponsorImage,
  sponsorList,
  setDetails,
  setSponserDetails,
  setSponsorImgPreview,
  isloggedIn,
  eventData,
  eventActivities,
  loginUser,
  image,
  userId,
  getEventPeopleList,
  handleDeleteActivity
}) {
  let navigate = useNavigate();
  return (
    <Tab.Pane eventKey="first">
      <>
        <div className="event-discussion bg-white ">
          <div className="event-time d-flex align-items-top">
            <span>
              <i className="fas fa-clock"></i>
            </span>
            {eventData?.to_event_date == '0000-00-00 00:00:00' ? (
              <div>
                <p>
                  {moment(eventData?.from_event_date).format('ddd')},
                  {moment(eventData?.from_event_date).format(' h:mm  A')}
                </p>
                <p>{moment(eventData?.from_event_date).format('DD MMM YYYY h:mm  A')}</p>
              </div>
            ) : (
              <div>
                <p>
                  {moment(eventData?.from_event_date).format('ddd')},
                  {moment(eventData?.from_event_date).format(' h:mm  A')} -
                  {moment(eventData?.to_event_date).format('ddd')}
                  {moment(eventData?.to_event_date).format(' h:mm A ')}
                </p>
                <p>
                  {moment(eventData?.from_event_date).format('  DD MMM YYYY h:mm  A')} -
                  {moment(eventData?.to_event_date).format('  DD MMM YYYY h:mm A ')}
                </p>
              </div>
            )}
          </div>
          <div className="event-time d-flex align-items-top">
            <span>
              <i className="fas fa-map-marker-alt"></i>
            </span>
            <div>
              <p> {eventData?.city_name} </p>
              <p>{eventData?.venue}</p>
            </div>
          </div>
          <div className="event-details">
            <h4>Details</h4>
            {eventData?.name}
            <div>{eventData?.decp}</div>
          </div>
        </div>
        {eventActivities?.length > 0 && (
          <div className="all-activity bg-white mt-3">
            <div className="all-activity-hd d-flex align-items-center justify-content-between">
              <h4> All Activities</h4>
              {isloggedIn ? (
                <Link
                  onClick={(e) => {
                    e.preventDefault();
                    setDetails({
                      title: '',
                      description: '',
                      from_event_date: '',
                      to_event_date: '',
                      image: ''
                    });
                    navigate(`/user/events-detail/${userId}/event-activity`);
                  }}>
                  {eventData?.encrypted_userid == loginUser ? '+ Add Activity' : ''}
                </Link>
              ) : (
                ''
              )}
            </div>

            {/* Event Activities list  */}

            {eventActivities && eventActivities
              ? eventActivities?.map((data, index) => {
                  return (
                    <div className="all-acitvity-card d-flex align-items-top" key={index}>
                      <div className="all-activity-lft me-3">
                        <div className="all-activity-img">
                          <img
                            src={image?.find((path) => path?.id == data.image)?.path}
                            alt="detail-cover"
                          />
                        </div>
                        {data?.participation == 0 ? (
                          <button
                            className="add-part-btn"
                            onClick={() => {
                              isloggedIn
                                ? handleParticipate(userId, data?.id, constants.PARTICIPATE)
                                : showNotification('danger', 'Please login!...');
                            }}>
                            + Participate
                          </button>
                        ) : (
                          <button
                            className="del-part-btn"
                            onClick={() => {
                              isloggedIn
                                ? handleParticipate(userId, data?.id, constants.NOTPARTICIPATE)
                                : showNotification('danger', 'Please login!...');
                            }}>
                            - Participate
                          </button>
                        )}
                      </div>
                      <div className="all-activity-dis">
                        {isloggedIn ? (
                          <div className="all-activity-top d-flex align-items-center justify-content-between">
                            <h4>{data.title} </h4>

                            <div className="all-activity-btn">
                              <Link
                                className="me-1"
                                title="View Participants"
                                onClick={(e) => {
                                  e.preventDefault();
                                  getEventPeopleList(e, data?.id, 'participants');
                                  navigate(`/user/events-detail/${userId}/participants-people`, {
                                    state: data.title
                                  });
                                }}>
                                {eventData?.encrypted_userid == loginUser ? (
                                  <i className="fas fa-users"></i>
                                ) : (
                                  ''
                                )}
                              </Link>
                              <Link
                                className="me-1"
                                title="Edit activity"
                                onClick={(e) => {
                                  e.preventDefault();
                                  navigate(
                                    `/user/events-detail/${userId}/event-activity/${data?.id}`
                                  );
                                }}>
                                {eventData?.encrypted_userid == loginUser ? (
                                  <i className="fas fa-edit"></i>
                                ) : (
                                  ''
                                )}
                              </Link>
                              <Link
                                className="me-1"
                                title="Delete activity"
                                to="#"
                                onClick={(e) => {
                                  e.preventDefault();
                                  handleDeleteActivity(e, data);
                                }}>
                                {eventData?.encrypted_userid == loginUser ? (
                                  <i className="fas fa-trash-alt"></i>
                                ) : (
                                  ''
                                )}
                              </Link>
                            </div>
                          </div>
                        ) : (
                          ''
                        )}

                        <ul>
                          {data?.to_event_date == '0000-00-00 00:00:00' ? (
                            <li>
                              <i className="far fa-clock me-1"></i>

                              {moment(data?.from_event_date).format('DD MMM YYYY h:mm  A')}
                            </li>
                          ) : (
                            <li>
                              <i className="far fa-clock me-1"></i>
                              {moment(data?.from_event_date).format('DD MMM YYYY h:mm  A')}-
                              {moment(data?.to_event_date).format('DD MMM YYYY h:mm  A')}
                            </li>
                          )}

                          <li>
                            <i className="fas fa-users"></i> {data?.total_participation}{' '}
                            Participants
                          </li>
                        </ul>
                        <p> {data.description}</p>
                      </div>
                    </div>
                  );
                })
              : 'No Data found'}
          </div>
        )}

        {/* Event Sponsor List */}
        {sponsorList?.length > 0 && (
          <div className="all-activity bg-white mt-3">
            {sponsorList && (
              <div className="all-activity-hd d-flex align-items-center justify-content-between">
                <h4>Event Sponsers</h4>
                {isloggedIn ? (
                  <Link
                    onClick={(e) => {
                      e.preventDefault();
                      setSponserDetails({
                        name: '',
                        image: ''
                      });
                      setSponsorImgPreview(null);
                      navigate(`/user/events-detail/${userId}/event-sponsor`);
                    }}>
                    {eventData?.encrypted_userid == loginUser ? '+ Add Sponsors' : ''}
                  </Link>
                ) : (
                  ''
                )}
              </div>
            )}

            {sponsorList && sponsorList
              ? sponsorList?.map((data, index) => {
                  return (
                    <div className="sponsors-card p-3 " key={index}>
                      <Row>
                        <Col lg={3}>
                          <div className="sponsors-card-inr">
                            <img
                              src={
                                sponsorImage?.find((path) => path?.id == data.sponser_logo)?.path
                              }
                              alt="detail-cover"
                            />
                            <div className="sponsors-overlay">
                              <h4> {data?.sponser_name} </h4>
                              {isloggedIn ? (
                                <div className="sposors-icn">
                                  <Link
                                    onClick={(e) => {
                                      e.preventDefault();
                                      navigate(
                                        `/user/events-detail/${userId}/event-sponsor/${data?.id}`
                                      );
                                    }}>
                                    {eventData?.encrypted_userid == loginUser ? (
                                      <i className="fas fa-edit"></i>
                                    ) : (
                                      ''
                                    )}
                                  </Link>
                                  <Link
                                    to="#"
                                    onClick={(e) => {
                                      handleDeleteSponsor(e, data?.id);
                                    }}>
                                    {eventData?.encrypted_userid == loginUser ? (
                                      <i className="fas fa-trash-alt"></i>
                                    ) : (
                                      ''
                                    )}
                                  </Link>
                                </div>
                              ) : (
                                ''
                              )}
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  );
                })
              : 'No data found'}
          </div>
        )}
      </>
    </Tab.Pane>
  );
}

export default EventsOverview;
