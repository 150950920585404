/**
@copyright  :   ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
@author     :   Shiv Charan Panjeta 
 
All Rights Reserved.
Proprietary and confidential :  All information contained here in is, and remains
the property of ToXSL Technologies Pvt. Ltd. and it's partners.
Unauthorized copying of this file, via any medium is strictly prohibited.
*/

import moment from 'moment/moment';
import { useEffect, useState } from 'react';
import { Button, Container } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Table from 'react-bootstrap/Table';
import ReactDatePicker from 'react-datepicker';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import { AsyncPaginate } from 'react-select-async-paginate';
import AsyncSelect from 'react-select/async';
import Loader from '../../../components/commoncomponents/loader/loader-large';
import { imageExtension, restrictAlpha } from '../../../globals/helper';
import useDocumentTitle from '../../../globals/useDocumentTitle';
import {
  ViewSingleEventDetail,
  addEvent,
  editAdminEvent,
  getEventCategory,
  getInstituteList,
  getLocationDropDown
} from '../../../services/AuthServices';
import showNotification from '../../../services/NotificationService';

export default function AddPage() {
  useDocumentTitle('Add New Event | AlmaBay');
  const location = useLocation();
  const Ins_id = location.state;
  const id = new URLSearchParams(location?.search).get('event-id');
  const userDetail = useSelector((state) => state?.user?.details);

  const components = {
    DropdownIndicator: null
  };
  const navigate = useNavigate();
  const [isEndDate, setIsEndDate] = useState(false);
  const [categoryOption, setCategoryOption] = useState([]);
  const [clicked, setClicked] = useState(false);
  const [loading, setLoading] = useState(false);
  const [details, setDetails] = useState({
    event_privacy: 'public',
    // institute_id: '',
    name: '',
    category_id: '',
    decp: '',
    venue: '',
    city: ''
  });
  const [fromEventDate, setFromEventDate] = useState(null);
  const [toEventDate, setToEventDate] = useState(null);
  const [catId, setCatId] = useState('');
  const [cityId, setCityId] = useState('');
  const [ticket, setTicket] = useState([]);
  const [value, setValue] = useState('');

  const [inputMessage, setInputMessage] = useState('Please enter atleast 2 characters');
  const [loadOpt, setLoadOpt] = useState(false);
  const [eventImage, setEventImage] = useState('');

  useEffect(() => {
    getCategoryOption();

    if (id) {
      getDetail(id);
    } else if (Ins_id || userDetail?.institute_id) {
      setDetails({
        event_privacy: 'public',
        institute_id: Ins_id || userDetail?.institute_id,
        name: '',
        category_id: '',
        decp: '',
        venue: '',
        city: '',
        from_event_date: '',
        to_event_date: ''
      });
      setFromEventDate(null);
      setToEventDate(null);
    }
  }, [id]);

  const getCategoryOption = () => {
    getEventCategory().then((res) => {
      if (res?.status === 200) {
        if (res?.data?.results) {
          let options = [];
          res?.data?.results?.map((data) => {
            return options.push({ value: data?.id, label: data?.name });
          });
          setCategoryOption(options);
        }
      }
    });
  };

  const loadCityOpt = async (inputValue) => {
    if (inputValue?.length >= 2) {
      setLoadOpt(true);
      return getLocationDropDown(inputValue).then((res) => {
        setLoadOpt(false);
        if (res?.status === 200) {
          let tempArr = [];
          if (res?.data?.data) {
            const data = res?.data?.data;
            data?.map((data) => {
              tempArr.push({
                id: data?.id,
                label: data?.location,
                value: data?.location
              });
            });
          }
          if (res?.data?.data == '') {
            setInputMessage('No matches found ');
          }
          return tempArr;
        }
      });
    }
  };

  const validate = () => {
    let error = {};
    if (details?.name == '') {
      error.name = 'This field is required.';
    }
    if (details?.category_id == '') {
      error.category_id = ' This field is required';
    }
    if (details?.decp == '') {
      error.decp = 'This field is required';
    }
    if (details?.venue == '') {
      error.venue = 'This field is required';
    }
    if (!details?.city) {
      error.city = 'This field is required';
    }
    if (!fromEventDate) {
      error.from_event_date = 'This field is required.';
    }
    if (isEndDate && toEventDate && toEventDate < fromEventDate) {
      error.to_event_date = 'End Date should be greater than Time Of Event';
    }
    return error;
  };

  const saveHandler = (e) => {
    e.preventDefault();
    setClicked(true);
    if (!Object.keys(validate()).length) {
      setLoading(true);
      setClicked(false);
      let formData = new FormData();
      for (let i in details) {
        formData.append(`${i}`, details[i]);
      }
      formData.append('Ticket', JSON.stringify(ticket));
      formData.append('from_event_date', moment(fromEventDate).format('YYYY-MM-DD HH:mm:ss'));
      formData.append('to_event_date', moment(toEventDate).format('YYYY-MM-DD HH:mm:ss'));
      if (value?.institute_id) {
        formData.append('institute_id', value?.institute_id);
      }
      addEvent(formData).then((res) => {
        setLoading(false);
        if (res?.status === 200) {
          showNotification('success', res?.data?.message);
          navigate('/admin/event');
        }
      });
    }
    1;
  };

  const updateEvent = () => {
    setClicked(true);
    if (!Object.keys(validate()).length) {
      setClicked(false);
      let formData = new FormData();
      for (let i in details) {
        formData.append(`${i}`, details[i]);
      }
      formData.append('from_event_date', moment(fromEventDate).format('YYYY-MM-DD HH:mm:ss'));
      formData.append('to_event_date', moment(toEventDate).format('YYYY-MM-DD HH:mm:ss'));
      formData.append('institute_id', value?.institute_id);
      setLoading(true);
      editAdminEvent(formData).then((res) => {
        setLoading(false);
        if (res?.status === 200) {
          showNotification('success', res?.data?.message);
          navigate('/admin/event');
        }
      });
    }
  };

  const handleChange = (e) => {
    if (e.target.type == 'file') {
      let file = e.target.files[0];
      let type = e.target.files[0].type;
      if (imageExtension.includes(type)) {
        setDetails({
          ...details,
          [e.target.name]: file
        });
        setEventImage(e.target.files[0]);
      } else {
        showNotification('danger', 'file format not supported !');
      }
    } else {
      const { name, value } = e.target;
      setDetails({
        ...details,
        [name]: value
      });
    }
  };

  const getDetail = (id) => {
    setLoading(true);
    ViewSingleEventDetail(id).then((res) => {
      if (res?.status === 200) {
        const data = res?.data?.data;
        setLoading(false);
        setDetails({
          id: data?.details?.id,
          event_privacy: data?.details?.event_privacy || 'public',
          institute_id: data?.details?.institute_id,
          name: data?.details?.name,
          category_id: data?.details?.category_id,
          decp: data?.details?.decp,
          venue: data?.details?.venue,
          city: data?.details?.city,
          image: data?.image_name != 'No Image Found' ? data?.image_name : ''
        });
        setValue({
          name: data?.instituteName,
          institute_id: data?.details?.institute_id
        });
        setFromEventDate(new Date(data?.details?.from_event_date));
        if (data?.details?.to_event_date && data?.details?.to_event_date != '0000-00-00 00:00:00') {
          setToEventDate(new Date(data?.details?.to_event_date));
        }
        if ('0000-00-00 00:00:00' == data?.details?.to_event_date) {
          setIsEndDate(false);
        } else {
          setIsEndDate(true);
        }
        setCityId({ id: data?.details?.city, label: data?.city_name, value: data?.city_name });
        setCatId({ label: data?.category_name, value: data?.details?.category_id });
      }
    });
  };

  const createTicket = (e, type) => {
    e.preventDefault();
    if (ticket.length) {
      if (type === 'free') {
        setTicket([
          ...ticket,
          {
            TicketType: 'FREE',
            TicketName: '',
            TicketPrice: '0',
            TicketQuantity: '',
            TicketLimit: ''
          }
        ]);
      } else {
        setTicket([
          ...ticket,
          {
            TicketType: 'PAID',
            TicketName: '',
            TicketPrice: '',
            TicketQuantity: '',
            TicketLimit: ''
          }
        ]);
      }
    } else {
      if (type === 'free') {
        setTicket([
          {
            TicketType: 'FREE',
            TicketQuantity: '',
            TicketPrice: '0',
            TicketName: '',
            TicketLimit: ''
          }
        ]);
      } else {
        setTicket([
          {
            TicketType: 'PAID',
            TicketName: '',
            TicketPrice: '',
            TicketQuantity: '',
            TicketLimit: ''
          }
        ]);
      }
    }
  };

  const deleteTicket = (e, index) => {
    e.preventDefault();
    let arr = [...ticket];
    arr.splice(index, 1);
    setTicket(arr);
  };

  const ticketHandler = (e, index) => {
    const { name, value } = e.target;
    const list = [...ticket];
    list[index][name] = value;
    setTicket(list);
  };

  const loadOptions = async (search, loadedOptions, { page }) => {
    try {
      let body = {
        page: page,
        institute_name: search
      };
      const resp = await getInstituteList(body);
      // setCampus(resp?.data?.data?.viewlist ?? []);
      return {
        options: resp?.data?.data?.viewlist ?? [],
        hasMore: loadedOptions.length === resp?.data?.data?.totalRecords ? false : true,
        additional: {
          page: search ? page : page + 1
        }
      };
    } catch (error) {
      console.log('error', error);
    }
  };

  const filterPassedTime = (time) => {
    const currentDate = new Date();
    const selectedDate = new Date(time);
    return currentDate.getTime() < selectedDate.getTime();
  };

  return (
    <>
      <div className="dash-main-content">
        <Container fluid>
          <div className="dash-page-content">
            <div className="dash-page-title">
              <Row>
                <Col xs={12} sm={12} md={12}>
                  <div className="dash-title-bar d-flex align-items-center justify-content-between">
                    <div className="title-lft d-flex align-items-center">
                      <div className="dash-icn">
                        <i className="fas fa-newspaper"></i>
                      </div>
                      <div className="dash-title-dis">
                        {id ? <h5>Edit Event</h5> : <h5>Add Event</h5>}
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="dash-content-card">
              <div className="cmmn-form">
                <Form>
                  <Row>
                    <Col md={12}>
                      <Form.Group className="mb-3" controlId="formBasicName">
                        <Form.Label>
                          Event Privacy <span className="text-danger">*</span>{' '}
                        </Form.Label>
                        <Form.Select
                          aria-label="Default select example"
                          name="event_privacy"
                          onChange={(e) => {
                            handleChange(e);
                          }}>
                          <option value="public">Public</option>
                          <option value="private">Private</option>
                        </Form.Select>
                      </Form.Group>
                    </Col>
                    {Ins_id || userDetail?.institute_id ? (
                      <></>
                    ) : (
                      <Col md={12}>
                        <Form.Group className="mb-3" controlId="formBasicName">
                          <Form.Label>Institute</Form.Label>
                          <AsyncPaginate
                            className="w100  p16 mr_normal_padding_l mr_medium_padding_r"
                            loadOptions={loadOptions}
                            name="meal_id"
                            id={Math.random()}
                            getOptionValue={(option) => {
                              return option.institute_id;
                            }}
                            getOptionLabel={(option) => {
                              return option.name;
                            }}
                            value={value}
                            onChange={setValue}
                            additional={{
                              page: 1
                            }}
                            placeholder="Select Institute"
                            debounceTimeout={500}
                            isSearchable={true}
                          />
                        </Form.Group>
                      </Col>
                    )}
                    <Col md={12}>
                      <Form.Group className="mb-3" controlId="formBasicName">
                        <Form.Label>
                          Name <span className="text-danger">*</span>{' '}
                        </Form.Label>
                        <Form.Control
                          type="text"
                          value={details?.name}
                          placeholder="Enter Name"
                          name="name"
                          onChange={handleChange}
                        />
                        <div className={'danger-frm'}>{clicked && validate().name}</div>
                      </Form.Group>
                    </Col>
                    <Col md={12}>
                      <Form.Group className="mb-3" controlId="formBasicName">
                        <Form.Label>
                          Category <span className="text-danger">*</span>{' '}
                        </Form.Label>
                        <Select
                          className="basic-single"
                          isSearchable={true}
                          options={categoryOption}
                          value={catId}
                          onChange={(e) => {
                            setCatId(e);
                            setDetails({ ...details, ['category_id']: e.value });
                          }}
                          placeholder="Select Event Category"
                        />
                        <div className={'danger-frm'}>{clicked && validate().category_id}</div>
                      </Form.Group>
                    </Col>
                    <Col md={12}>
                      <Form.Group className="mb-3" controlId="formBasicDesi">
                        <Form.Label>
                          Description<span className="text-danger">*</span>{' '}
                        </Form.Label>
                        <Form.Control
                          as="textarea"
                          placeholder="Enter Description"
                          rows={3}
                          name="decp"
                          value={details?.decp}
                          onChange={handleChange}
                        />
                        <div className={'danger-frm'}>{clicked && validate().decp}</div>
                      </Form.Group>
                    </Col>

                    <Col md={12}>
                      <Form.Group className="mb-3" controlId="formBasicDesi">
                        <Form.Label>
                          Venue <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          as="textarea"
                          placeholder="Enter Venue Details"
                          rows={3}
                          name="venue"
                          value={details?.venue}
                          onChange={handleChange}
                        />
                        <div className={'danger-frm'}>{clicked && validate().venue}</div>
                      </Form.Group>
                    </Col>

                    <Col md={12}>
                      <Form.Group className="mb-3" controlId="formBasicName">
                        <Form.Label>
                          City <span className="text-danger">*</span>{' '}
                        </Form.Label>

                        <AsyncSelect
                          isClearable={true}
                          getOptionLabel={(e) => e?.label}
                          getOptionValue={(e) => e?.value}
                          loadOptions={loadCityOpt}
                          value={cityId}
                          className="basic-single mt-1"
                          name="city"
                          onChange={(e) => {
                            setCityId(e);
                            setDetails({ ...details, ['city']: e?.id });
                          }}
                          placeholder="Select event city"
                          components={{
                            DropdownIndicator: () => null,
                            IndicatorSeparator: () => null,
                            LoadingIndicator: () => null
                          }}
                          noOptionsMessage={() => inputMessage}
                          onInputChange={(e) => {
                            e?.length < 2
                              ? setInputMessage('Please enter atleast 2 characters.')
                              : '';
                          }}
                        />
                        {loadOpt && (
                          <div className="spinner_position">
                            <i className="fa fa-spinner fa-pulse me-2"></i>
                          </div>
                        )}
                        <div className={'danger-frm'}>{clicked && validate().city}</div>
                      </Form.Group>
                    </Col>

                    <Col md={4}>
                      <Form.Group className="mb-3" controlId="formBasicName">
                        <Form.Label>
                          Date/Time Of Event <span className="text-danger">*</span>{' '}
                        </Form.Label>
                        <ReactDatePicker
                          placeholderText="Select Start Date-Time"
                          className="form-control"
                          selected={fromEventDate}
                          onChange={setFromEventDate}
                          showTimeSelect
                          minDate={moment().toDate()}
                          filterTime={filterPassedTime}
                          dateFormat="MM-dd-yyyy h:mm aa"
                        />
                        <div className={'danger-frm'}>{clicked && validate().from_event_date}</div>
                      </Form.Group>
                    </Col>

                    <Col md={4}>
                      {isEndDate ? (
                        <Form.Group className="mb-3" controlId="formBasicName">
                          <Form.Label>End Date Time</Form.Label>
                          <ReactDatePicker
                            placeholderText="Select End Date-Time"
                            className="form-control"
                            selected={toEventDate}
                            onChange={setToEventDate}
                            showTimeSelect
                            filterTime={filterPassedTime}
                            minDate={fromEventDate ? fromEventDate : new Date()}
                            dateFormat="MM-dd-yyyy h:mm aa"
                          />
                          <div className={'danger-frm'}>{clicked && validate().to_event_date}</div>
                        </Form.Group>
                      ) : (
                        <></>
                      )}
                    </Col>

                    <Col md={4}>
                      <Link
                        className="end-date-card mt-4"
                        to="#"
                        onClick={(e) => {
                          e.preventDefault();
                          setIsEndDate(!isEndDate);
                        }}>
                        + End Date-Time
                      </Link>
                    </Col>

                    <Col md={4}>
                      <Form.Group className="mb-3" controlId="formBasicName">
                        <Form.Label>Event Image</Form.Label>
                        <Form.Control
                          type="file"
                          placeholder=""
                          onChange={handleChange}
                          accept="image/*"
                          name="image"
                        />
                      </Form.Group>
                    </Col>

                    <Col md={4}>
                      {id && details?.image ? (
                        <div className="d-flex flex-wrap align-item-center">
                          <div className="border me-2">
                            <img
                              width="100px"
                              height="100px"
                              src={
                                eventImage
                                  ? URL.createObjectURL(eventImage)
                                  : process.env.REACT_APP_API_DOMAIN +
                                    'common/uploads/event/' +
                                    details?.image
                              }
                              alt="img1"
                            />
                          </div>
                        </div>
                      ) : (
                        // <img src={URL.createObjectURL(eventImage)} alt="" />
                        ''
                      )}
                    </Col>

                    {!id && ticket?.length !== 0 ? (
                      <Col md={12}>
                        <Form.Label> Create Tickets</Form.Label>
                        <Table className="add-event-chart" bordered hover>
                          <thead>
                            <tr>
                              <th>
                                Ticket name <span className="text-danger">*</span>{' '}
                              </th>
                              <th>
                                Quantity available <span className="text-danger">*</span>{' '}
                              </th>
                              <th>Limit</th>
                              <th>Price</th>
                              <th>Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            {ticket?.map((data, index) => (
                              <tr key={index}>
                                <td>
                                  <Form.Control
                                    type="text"
                                    name="TicketName"
                                    placeholder="Enter ticket name."
                                    onChange={(e) => ticketHandler(e, index)}
                                    value={data?.TicketName}
                                  />
                                </td>
                                <td>
                                  <Form.Control
                                    type="text"
                                    name="TicketQuantity"
                                    onKeyPress={restrictAlpha}
                                    placeholder="100"
                                    onChange={(e) => ticketHandler(e, index)}
                                    value={data?.TicketQuantity}
                                  />
                                </td>
                                <td>
                                  <Form.Control
                                    type="text"
                                    name="TicketLimit"
                                    onKeyPress={restrictAlpha}
                                    placeholder="10"
                                    onChange={(e) => ticketHandler(e, index)}
                                    value={data?.TicketLimit}
                                  />
                                </td>
                                <td>
                                  {data?.TicketPrice === '0' ? (
                                    'free'
                                  ) : (
                                    <div className="price-card">
                                      <Form.Control
                                        type="text"
                                        placeholder="0.00"
                                        onKeyPress={restrictAlpha}
                                        name="TicketPrice"
                                        onChange={(e) => ticketHandler(e, index)}
                                        value={data?.TicketPrice}
                                      />
                                      <span className="price-icn">
                                        <i className="fas fa-rupee-sign"></i>
                                      </span>
                                    </div>
                                  )}
                                </td>
                                <td>
                                  <Link
                                    className="trash-btn"
                                    to="#"
                                    onClick={(e) => {
                                      deleteTicket(e, index);
                                    }}>
                                    <i className="fas fa-trash-alt"></i>
                                  </Link>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </Col>
                    ) : (
                      ''
                    )}
                    <Col md={12}>
                      {id || ticket?.length ? '' : <Form.Label> Create Tickets</Form.Label>}
                    </Col>
                    <Col md={12}>
                      <div className="text-center">
                        {id ? '' : <p>What type of ticket would you like to start with?</p>}
                        {!id && (
                          <>
                            {' '}
                            <button
                              type="button"
                              className="green_button_admin m-2"
                              onClick={(e) => {
                                createTicket(e, 'free');
                              }}>
                              + FREE TICKET
                            </button>
                            <button
                              type="button"
                              className="green_button_admin m-2"
                              onClick={(e) => {
                                createTicket(e, 'paid');
                              }}>
                              + PAID TICKET
                            </button>
                          </>
                        )}
                      </div>
                    </Col>
                    <Col md={4}></Col>
                  </Row>
                  <div className="form-btns d-flex align-items-center mt-3">
                    {!id ? (
                      <button type="button" className="green_button_admin" onClick={saveHandler}>
                        Save
                      </button>
                    ) : (
                      <button type="button" className="green_button_admin" onClick={updateEvent}>
                        Update
                      </button>
                    )}
                    <strong>OR</strong>
                    <Button className="main-btn-red" onClick={() => navigate('/admin/event')}>
                      Cancel
                    </Button>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </Container>
      </div>
      {loading ? <Loader /> : ''}
    </>
  );
}
