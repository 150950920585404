import { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import HeaderLogin from '../../commoncomponents/HeaderLogin';
import NewsSidebar from '../../commoncomponents/newsfeeds/NewsSidebar';
import TopTabbings from './TopTabbings';
import { useParams } from 'react-router-dom';
import { jobFairCompaniesJOb } from '../../../services/SocialServices';
import { MiniFooter } from '../../MiniFooter';
import Loader from '../../commoncomponents/loader/loader-large';
import ArticalBlogs from '../../commoncomponents/newsfeeds/ArticalBlogs';
import CampusNews from '../../commoncomponents/newsfeeds/CampusNews';
import CampusnewsText from '../../commoncomponents/newsfeeds/CampusnewsText';
import FollowCompany from '../../commoncomponents/newsfeeds/FollowCompany';
import GroupDiscover from '../../commoncomponents/newsfeeds/GroupDiscover';
import PeopleKnow from '../../commoncomponents/newsfeeds/PeopleKnow';
import Upcoming from '../../commoncomponents/newsfeeds/Upcoming';
import WhatsNew from '../../commoncomponents/newsfeeds/WhatsNew';
import WorkAnniversary from '../../commoncomponents/newsfeeds/WorkAnniversary';

function JobfairDetails() {
  const { id } = useParams();
  const [appliedCompany, setappliedCompany] = useState([]);
  const [loading, setIsLoading] = useState(false);
  useEffect(() => {
    getCompaniesJob();
  }, []);
  const getCompaniesJob = () => {
    setIsLoading(true);
    let body = {
      festid: id,
      companyId: ''
    };
    jobFairCompaniesJOb(body).then((res) => {
      if (res?.status === 200) {
        setIsLoading(false);
        const data = res?.data?.detail?.company_jobs?.filter(
          (item) => item.applied_status === 'yes'
        );
        setappliedCompany(data);
      } else {
        setIsLoading(false);
      }
    });
  };

  return (
    <>
      <HeaderLogin />
      <div className="header-lnk-feeds">
        <div className="container">
          <TopTabbings state={'feed'} />
        </div>
      </div>
      <section className="news-feed-sec bg-grey py-4">
        <Container>
          <Row>
            <Col xs={12} md={2}>
              <NewsSidebar state="1" current="job-fair-hall-tickets" />
            </Col>
            <Col xs={12} md={7}>
              <div className="job_application">
                <div className="job_application_card bg-white mb-3">
                  <div className="job_application_head">
                    <h4>Jobfair Applications</h4>
                  </div>
                  {appliedCompany?.length !== 0 ? (
                    <>
                      {' '}
                      {appliedCompany?.map((data, index) => {
                        return (
                          <div className="job_application_details jobfair_detials" key={index}>
                            <h5 className="fw-bold">{data?.job_title} </h5>
                            <p dangerouslySetInnerHTML={{ __html: data?.job_info }}></p>
                          </div>
                        );
                      })}
                      <Link to="/jobs/jobfair">
                        <h5 className="fw-bold anchor_normal mx-2 mt-3">Back</h5>
                      </Link>
                    </>
                  ) : (
                    <div className="p-5 text-center">
                      <img
                        src={require('../../../assets/images/small-error.png')}
                        width="105px"
                        alt="img"
                      />
                      <p>No Job Application found at the moment.</p>
                    </div>
                  )}
                </div>
              </div>
            </Col>
            <Col md={3}>
              <div>
                <PeopleKnow />
              </div>
              <div>
                <WorkAnniversary />
              </div>
              <div>
                <GroupDiscover />
              </div>
              <div>
                <FollowCompany />
              </div>
              <div>
                <CampusNews />
              </div>
              <div>
                <ArticalBlogs />
              </div>
              <div>
                <CampusnewsText />
              </div>
              <div>
                <WhatsNew />
              </div>
              <div>
                <Upcoming />
              </div>
              <MiniFooter />
            </Col>
          </Row>
        </Container>
        {loading ? <Loader /> : null}
      </section>
    </>
  );
}

export default JobfairDetails;
