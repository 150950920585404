import { Col, Container, Row } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import HeaderLogin from '../../../../commoncomponents/HeaderLogin';
import WorkForm from '../../../../commoncomponents/profile/WorkForm';
import ProfileSidebar from '../../../../commoncomponents/profile/ProfileSidebar';
import TopTabbings from '../../TopTabbings';
import useDocumentTitle from '../../../../../globals/useDocumentTitle';

function WorkDetails() {
  useDocumentTitle('My Account | AlmaBay');
  const navigate = useNavigate();
  return (
    <>
      <HeaderLogin state={'d-none'} />
      <TopTabbings />
      <section className="account-info-header">
        <Container
          onClick={() => {
            navigate('/user/profile');
          }}
        >
          <h4>
            {' '}
            <span className="user-icon"></span> My Account
          </h4>
        </Container>
      </section>
      <section className="bg-grey">
        <ProfileSidebar state={'work'} head={'account'} />
        <div className="pt-4 pb-5">
          <Container>
            <Row>
              <Col lg={7} md={12}>
                <div className="account-form">
                  <div className="section_details_heading">
                    <h2>My Account</h2>
                    <p>
                      Your profile is the face of your skills on the social /professional networking
                      sites. If your profile is not impressive, not many people would like to be
                      associated with it. So, without giving it a second thought, get on with the
                      task at hand.
                    </p>
                  </div>
                  <div>
                    <WorkForm myprofile={false} />
                  </div>
                </div>
              </Col>
              <Col lg={5} md={12}>
                <div className="signing-almabay">
                  <div className="signing">
                    <img
                      className="img-responsive"
                      src={require('../../../../../assets/images/account-manage-icon.png')}
                      alt="img"
                    />
                    <h2 className="heading">Signing in to AlmaBay</h2>
                    <p> Get everything you need to build a career by filling in the details. </p>
                    <ul>
                      <li className="d-flex">
                        <div>
                          <span className="user-thumb">1</span>
                        </div>
                        <div className="article-post">
                          <span> Basic Details</span>
                          <p>Fill in your basic profile details and update them regularly.</p>
                        </div>
                      </li>
                      <li className="d-flex">
                        <div>
                          <span className="user-thumb">2</span>
                        </div>
                        <div className="article-post">
                          <span> Contact Details </span>
                          <p>
                            Fill in correct contact details and we will help you get right jobs,
                            mentors and notifications.
                          </p>
                        </div>
                      </li>
                      <li className="d-flex">
                        <div>
                          <span className="user-thumb">3</span>
                        </div>
                        <div className="article-post">
                          <span>Work</span>
                          <p>
                            To get hired update your work experience regularly and fill in the
                            maximum information. Your resume can be shown in multiple ways based on
                            work experience, Current Salary, Keywords, Skills, Designation, Company
                            name etc.
                          </p>
                        </div>
                      </li>
                      <li className="d-flex">
                        <div>
                          <span className="user-thumb">4</span>
                        </div>
                        <div className="article-post">
                          <span> Education </span>
                          <p>
                            Join your community members and get notification, updates and events by
                            filling in all the fields.
                          </p>
                        </div>
                      </li>
                      <li className="d-flex">
                        <div>
                          <span className="user-thumb">5</span>
                        </div>
                        <div className="article-post">
                          <span>Notification</span>
                          <p>Select activities that you want to be notified for.</p>
                        </div>
                      </li>
                    </ul>
                    <div className="mx-4">
                      <div className="terms-condition">
                        <ul className="d-flex mb-0 ps-0 ">
                          <li className="me-2 p-0">
                            <Link to="/privacy_policy" target="_blank">
                              Privacy Policy
                            </Link>
                          </li>
                          <li className="me-2 p-0">
                            <Link to="/terms_of_use" target="_blank">
                              Terms of Use
                            </Link>
                          </li>
                          <li className="p-0">
                            <Link to="/contact-us" target="_blank">
                              Contact Us
                            </Link>
                          </li>
                        </ul>
                      </div>
                      <div className="company-name">AlmaBay.com © {new Date().getFullYear()} </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </section>
    </>
  );
}

export default WorkDetails;
