import { useState, useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import {
  getJobFairDetail,
  jobFairApplyJobs,
  jobFairCompaniesJOb
} from '../../../services/SocialServices';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Link } from 'react-router-dom';
import Form from 'react-bootstrap/Form';

import HeaderLogin from '../../commoncomponents/HeaderLogin';
import TopTabbings from './TopTabbings';
import moment from 'moment/moment';
import Loader from '../../commoncomponents/loader/loader-large';
import { Modal } from 'react-bootstrap';
import showNotification from '../../../services/NotificationService';
import { restrictAlpha, restrictNum, truncate } from '../../../globals/helper';
import { useSelector } from 'react-redux';

function JobFairDetail() {
  /*eslint-disable */
  const { id } = useParams();
  const [fairDetail, setFairDetail] = useState();
  const [jobFestCompany, setJobFestCompany] = useState('');
  const [toggleCards, setToggleCards] = useState(false);
  const [companyJobs, setCompanyJobs] = useState([]);
  const [jobLength, setJobLength] = useState('');
  const [loading, setIsLoading] = useState(false);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [jobIds, setJobIds] = useState([]);
  const userDetail = useSelector((state) => state?.user?.details);
  const [applyDetail, setApplyDetail] = useState({
    email: '',
    UserName: '',
    PhoneNumber: ''
  });
  const [selectResume, setSelectResume] = useState('');
  const [existResume, setExistingResume] = useState(false);
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);
  const [showDetail, setShowDetail] = useState('');
  const [banner, setBanner] = useState([]);

  useEffect(() => {
    if (id) {
      getDetail(id);
      getCompaniesJob();
    }
  }, []);

  const getDetail = (id) => {
    getJobFairDetail(id).then((res) => {
      if (res?.status === 200) {
        const data = res?.data?.detail?.jobfestDetails;
        setFairDetail(data);
        setJobFestCompany(res?.data?.detail);
        setBanner(res?.data?.detail?.all_banner_image);
      }
    });
  };

  const getCompaniesJob = (companyId = '') => {
    setIsLoading(true);
    let body = {
      festid: id,
      companyId: companyId
    };
    jobFairCompaniesJOb(body).then((res) => {
      if (res?.status === 200) {
        setIsLoading(false);
        const data = res?.data?.detail?.company_jobs;
        setCompanyJobs(data);

        let appliedStatus = [];
        data?.map((item) => {
          if (item.applied_status === 'yes') {
            appliedStatus.push(item);
          }
          return setJobLength(appliedStatus?.length);
        });
      } else {
        setIsLoading(false);
      }
    });
  };

  const reportTimeHandle = (time) => {
    if (time) {
      let hours = time?.split(':')[0];
      let minutes = time?.split(':')[1];
      if (hours > 12) {
        let newhours = hours - 12;
        return newhours + ':' + minutes + ' p.m';
      }
      if (hours == 0) {
        return 12 + ':' + minutes + ' a.m';
      } else {
        return time + ' a.m';
      }
    } else {
      return;
    }
  };

  const handleCheckJobs = (id) => {
    if (jobIds?.includes(id)) {
      let filtered = jobIds?.filter((data) => data !== id);
      setJobIds(filtered);
    } else {
      setJobIds([...jobIds, id]);
    }
  };

  const handleOpenModal = () => {
    if (jobIds?.length !== 0) {
      handleShow();
      setApplyDetail({
        email: userDetail?.email,
        UserName: userDetail?.name,
        PhoneNumber: userDetail?.mobile
      });
    } else {
      showNotification('danger', 'Please select at least one job before applying.');
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setApplyDetail({ ...applyDetail, [name]: value });
  };

  const validate = () => {
    if (applyDetail?.email == '') {
      showNotification('danger', 'Email is required field.');
      return false;
    }
    if (applyDetail?.UserName == '') {
      showNotification('danger', 'Name is required field.');
      return false;
    }
    if (applyDetail?.PhoneNumber == '') {
      showNotification('danger', 'Phone Number is required field.');
      return false;
    }
    if (applyDetail?.PhoneNumber?.length < 10) {
      showNotification('danger', 'Please enter a valid phone number, e.g 88-9999-1111');
      return false;
    }
    if (selectResume == '' && existResume == false) {
      showNotification(
        'danger',
        'Resume upload is required field or Use My existing resume option.'
      );
      return false;
    }
    if (selectResume !== '' && existResume !== false) {
      showNotification(
        'danger',
        'Either you can upload new or allow us to use your existing resume.'
      );
      return false;
    }
    return true;
  };

  const handleSubmit = () => {
    if (validate()) {
      setIsLoading(true);
      let body = {
        email: applyDetail?.email,
        UserName: applyDetail?.UserName,
        PhoneNumber: applyDetail?.PhoneNumber,
        resume: selectResume,
        use_existing: existResume == true ? 'on' : '',
        JobListToApplyOn: jobIds?.toString(),
        fest: id
      };
      let formData = new FormData();
      for (let i in body) {
        formData.append(`${i}`, body[i]);
      }
      jobFairApplyJobs(formData).then((res) => {
        if (res?.status === 200) {
          setIsLoading(false);
          getCompaniesJob();
          handleClose();
          showNotification(
            'success',
            'Your application submitted successfully, check your emails for passing code and instructions.'
          );
          setApplyDetail({
            email: '',
            UserName: '',
            PhoneNumber: ''
          });
          setSelectResume('');
          setJobIds([]);
        } else {
          setIsLoading(false);
        }
      });
    }
  };

  const handleShowDetail = (e, data) => {
    e.preventDefault();
    handleShow1();
    setShowDetail(data);
  };

  return (
    <>
      <HeaderLogin />
      <div className="header-lnk-feeds">
        <div className="container">
          <TopTabbings />
        </div>
      </div>
      <div className="job-top-slide">
        <Container>
          <Swiper
            spaceBetween={30}
            slidesPerView={1}
            breakpoints={{
              320: {
                slidesPerView: 1
              },
              576: {
                slidesPerView: 1
              },
              768: {
                slidesPerView: 1
              },
              992: {
                slidesPerView: 1
              },
              1200: {
                slidesPerView: 1
              }
            }}
          >
            {banner &&
              banner.length > 0 &&
              banner.map((data) => {
                return (
                  <SwiperSlide>
                    <img
                      src={
                        process.env.REACT_APP_API_URL + 'common/uploads/job_fest_banners/' + data
                      }
                      alt="Banner-Img"
                    />
                  </SwiperSlide>
                );
              })}
          </Swiper>
        </Container>
      </div>
      <div className="job-detail-card py-5">
        <Container>
          <div className="job-fair-hd py-3">
            <h2>{fairDetail?.title}</h2>
            <h4>
              Jobfair creates opportunities for students from member institutions to participate in
              placement drives to be conducted across India. This initiative is supported by
              corporate in order to provide an opportunity for students to showcase themselves to
              organizations and obtain their dream campus placement offers.
            </h4>
          </div>
          <Row>
            <Col lg={4}>
              <div className="jobfair-top-box">
                <i className="jobbox-icon1"></i>
                <h1>{fairDetail?.total_companies > 0 ? fairDetail?.total_companies : 0}</h1>
                <h4>Employers Participating</h4>
              </div>
            </Col>
            <Col lg={4}>
              <div className="jobfair-top-box">
                <i className="jobbox-icon2"></i>
                <h1>{fairDetail?.total_jobs > 0 ? fairDetail?.total_jobs : 0}</h1>
                <h4>Jobs Available</h4>
              </div>
            </Col>
            <Col lg={4}>
              <div className="jobfair-top-box">
                <i className="jobbox-icon2"></i>
                <h1>{fairDetail?.total_openings > 0 ? fairDetail?.total_openings : 0}</h1>
                <h4>Positions Open</h4>
              </div>
            </Col>
          </Row>

          <Row className="mt-4">
            <Col lg={8} md={12}>
              <div className="employer-lft-card">
                {jobFestCompany?.jobfestCompanies?.length > 0 && (
                  <>
                    <div className="employer-tp-hd">
                      <h4>
                        List of Employers Participating{' '}
                        <Link
                          to="#"
                          onClick={(e) => {
                            e.preventDefault();
                            setToggleCards(true);
                          }}
                        >
                          (View All Jobs)
                        </Link>
                      </h4>
                    </div>
                    <div className="employer-lft-dis">
                      <ul>
                        {jobFestCompany?.jobfestCompanies?.length > 0 && (
                          <>
                            {' '}
                            {jobFestCompany?.jobfestCompanies?.map((data, index) => {
                              return (
                                <li
                                  key={index}
                                  onClick={() => {
                                    getCompaniesJob(data?.company_id);
                                    setToggleCards(true);
                                  }}
                                >
                                  <img
                                    src={
                                      data?.logo
                                        ? process.env.REACT_APP_API_URL +
                                        jobFestCompany?.logoPath +
                                        data?.logo
                                        : require('../../../assets/images/job-fair1.jpg')
                                    }
                                    alt="institute"
                                  />
                                </li>
                              );
                            })}
                          </>
                        )}
                      </ul>
                    </div>
                  </>
                )}
                <div className="job-fair-detail">
                  <h3>Details for Job Fair</h3>
                  <div dangerouslySetInnerHTML={{ __html: fairDetail?.details }}></div>
                </div>
              </div>
            </Col>
            <Col lg={4} md={12}>
              {toggleCards ? (
                <div className="jobfair-rt-details mt-4">
                  <div className="top-btn-jobfair">
                    <Link
                      to="#"
                      className="view-card mt-0 mb-2"
                      onClick={(e) => {
                        e.preventDefault();
                        setToggleCards(false);
                      }}
                    >
                      (View Fair Details)
                    </Link>
                  </div>
                  {companyJobs?.length > 0 && (
                    <div className="jobfair-rt-hd d-flex alig-items-center justify-content-between">
                      <h4>Jobs in All Companies</h4>

                      {jobLength !== companyJobs?.length && (
                        <button className="job-apply" onClick={handleOpenModal}>
                          Apply Now
                        </button>
                      )}
                    </div>
                  )}

                  <div className="jobfair-job-lst">
                    {companyJobs?.length > 0 ? (
                      <>
                        {' '}
                        {companyJobs?.map((data, index) => {
                          return (
                            <div className="jobfair-job-card mb-4" key={index}>
                              <div className="job-card-hd">
                                {data?.applied_status == 'no' ? (
                                  ['checkbox'].map((type) => (
                                    <div key={`default-${type}`} className="mb-3">
                                      <Form.Check
                                        type={type}
                                        id={`default-${type}`}
                                        label={data?.job_title}
                                        onChange={() => handleCheckJobs(data?.id)}
                                      />
                                    </div>
                                  ))
                                ) : (
                                  <>
                                    <sapn>
                                      <i
                                        className="fas fa-check-circle"
                                        style={{ color: 'green' }}
                                      ></i>{' '}
                                      {data?.job_title}
                                    </sapn>
                                  </>
                                )}
                              </div>
                              <h4>{data?.companyName}</h4>
                              <ul className="job-recomm-addrs">
                                <li>
                                  <i className="fas fa-map-marker-alt" />
                                  {data?.city}
                                </li>
                                {/* <li>
                                  <i className="fa fa-envelope" />
                                  {data?.hr_email}
                                </li> */}
                                {/* <li>
                                  <i className="fas fa-users" />
                                  Positions open: {data?.open_positions}
                                </li> */}
                                <li>
                                  <i className="fas fa-briefcase" />
                                  {/* Experience: */}
                                  {data?.min_exp} - {data?.max_exp} yrs.
                                </li>
                                {/* <li>
                                  <i className="fas fa-hourglass-end" />
                                  Job Type: Full Time
                                </li> */}
                                <li>
                                  <i className="fas fa-rupee-sign"></i>
                                  {/* Experience: */}
                                  {data?.ctc}
                                </li>
                              </ul>

                              <div
                                className="jobfair-inr-dis"
                                dangerouslySetInnerHTML={{
                                  __html: truncate(data?.job_info, 100)
                                }}
                              ></div>
                              {data?.job_info?.length > 100 ? (
                                <div className="job-fair-more">
                                  <Link to="" onClick={(e) => handleShowDetail(e, data)}>
                                    (more)
                                  </Link>
                                </div>
                              ) : (
                                <></>
                              )}
                            </div>
                          );
                        })}
                        {companyJobs?.length > 0 && (
                          <div className="jobfair-rt-hd d-flex alig-items-center justify-content-between">
                            <h4>Jobs in All Companies</h4>

                            {jobLength !== companyJobs?.length && (
                              <button className="job-apply" onClick={handleOpenModal}>
                                Apply Now
                              </button>
                            )}
                          </div>
                        )}
                      </>
                    ) : (
                      <>No jobs added yet </>
                    )}
                  </div>
                </div>
              ) : (
                <div className="job-fair-table">
                  {jobFestCompany?.jobfestCompanies?.length > 0 && (
                    <div className="top-btn-jobfair">
                      <Link
                        to="#"
                        className="view-card mt-0 mb-2"
                        onClick={(e) => {
                          e.preventDefault();
                          setToggleCards(true);
                        }}
                      >
                        (View All Jobs)
                      </Link>
                    </div>
                  )}
                  <ul className="job-fair-lst">
                    <li>
                      <div className="jobfair-inr-card d-flex align-items-center">
                        <i className="jobfair-r8-i1"></i>
                        <div className="job-table-dis">
                          <h4>Venue</h4>
                          <p>{fairDetail?.venue}</p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="jobfair-inr-card d-flex align-items-center">
                        <i className="jobfair-r8-i2"></i>
                        <div className="job-table-dis">
                          <h4>Location/Address</h4>
                          <p>{fairDetail?.loc}</p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="jobfair-inr-card d-flex align-items-center">
                        <i className="jobfair-r8-i3"></i>
                        <div className="job-table-dis">
                          <h4>Date</h4>
                          <p> {moment(fairDetail?.start_date).format('Do MMM YY')}</p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="jobfair-inr-card d-flex align-items-center">
                        <i className="jobfair-r8-i4"></i>
                        <div className="job-table-dis">
                          <h4>Reporting Time</h4>
                          {/* <p>{reportTimeHandle(fairDetail?.reporting_time)}</p> */}
                          {fairDetail?.reporting_time ? moment(fairDetail?.reporting_time, "HH:mm").format("hh:mm A") : ''}
                        </div>
                      </div>
                    </li>
                  </ul>
                  <div className="job-rt-map mt-2">
                    <iframe
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3432.0599401676523!2d76.85807907631366!3d30.660440589121674!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390f94ed557d767f%3A0xb667563103641761!2sAlmaBay%20Networks%20Private%20Limited!5e0!3m2!1sen!2sin!4v1680938643664!5m2!1sen!2sin"
                      width="100%"
                      height="300"
                      // style="border:0;"
                      allowfullscreen=""
                      loading="lazy"
                      referrerpolicy="no-referrer-when-downgrade"
                    ></iframe>

                    {/* <iframe
                      name="gMap"
                      width={'100%'}
                      height="400"
                      style={{ marginTop: 20 }}
                      src={`https://www.google.com/maps/embed/v1/place?q=${0},${0}&key=${process.env.REACT_APP_GOOGLE_MAP_KEY}`}></iframe> */}
                  </div>
                </div>
              )}
            </Col>
          </Row>
        </Container>
      </div>
      {loading ? <Loader /> : null}
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Job Fair | Application Form</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="apply-card-frm">
            <Form>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Email adddress(*):</Form.Label>
                <Col md={12}>
                  <Form.Control
                    type="text"
                    name="email"
                    onChange={handleChange}
                    value={applyDetail?.email}
                  />
                </Col>
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Name(*):</Form.Label>
                <Col md={12}>
                  <Form.Control
                    type="text"
                    name="UserName"
                    onChange={handleChange}
                    onKeyPress={restrictNum}
                    value={applyDetail?.UserName}
                  />
                </Col>
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Phone Number(*):</Form.Label>
                <Col md={12}>
                  <Form.Control
                    type="text"
                    name="PhoneNumber"
                    onChange={handleChange}
                    onKeyPress={restrictAlpha}
                    maxLength={15}
                    value={applyDetail?.PhoneNumber}
                  />
                </Col>
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Upload Resume:</Form.Label>
                <Col md={12}>
                  <Form.Control
                    type="file"
                    name="to"
                    onChange={(e) => setSelectResume(e.target.files[0])}
                  />
                </Col>
              </Form.Group>
              <h5 className="mb-2">OR</h5>
              <Form.Group className="mb-3 d-flex" controlId="formBasicEmail">
                <Form.Label className="me-2">Use My Existing Resume:</Form.Label>
                <Form.Check
                  type={'checkbox'}
                  id={`default22`}
                  onChange={() => setExistingResume(!existResume)}
                />
              </Form.Group>
              <div className="upload-resume-btn text-end ">
                <button className="wht-btn" type="button" onClick={handleSubmit}>
                  <i className="fas fa-check me-1"></i>Submit
                </button>
                <button className="wht-btn txt-transfrm  mx-2" type="button" onClick={handleClose}>
                  Cancel
                </button>
              </div>
            </Form>
          </div>
        </Modal.Body>
      </Modal>

      <Modal show={show1} onHide={handleClose1}>
        <Modal.Header closeButton>
          <Modal.Title>{showDetail?.job_title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="view-detail-content">
            <Row>
              <Col md={12}>
                <p>
                  <strong>Title: </strong>
                  {showDetail?.job_title}
                </p>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <p>
                  <strong>Experience: </strong>
                  {showDetail?.min_exp}-{showDetail?.max_exp} yrs
                </p>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <p>
                  <strong>Package: </strong>
                  {showDetail?.ctc}
                </p>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <p>
                  <strong>Location: </strong>
                  {showDetail?.city}
                </p>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <p>
                  <strong>Job Description: </strong>
                  <p dangerouslySetInnerHTML={{ __html: showDetail?.job_info }}></p>
                </p>
              </Col>
            </Row>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default JobFairDetail;
