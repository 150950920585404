import { Link } from 'react-router-dom';

function TopTabbings(props) {
  const { state } = props;
  return (
    <>
      <div className="header-lnk-feeds">
        <div className="container">
          <ul className="feed-hd-lnks mb-0">
            <li>
              <Link className={`${state === 'feed' ? 'active' : ''}`} to="/jobs">
                <span className="btn-icon">
                  <i className="custom-icon-professional"></i>
                </span>
                <span>Professional</span>
              </Link>
            </li>
            <li>
              <Link className={`${state === 'mentor' ? 'active' : ''}`} to="/mentors/qafeeds">
                <span className="btn-icon">
                  <i className="custom-icon-blog"></i>
                </span>
                <span>Mentorship</span>
              </Link>
            </li>
            <li>
              <Link className={`${state === 'social' ? 'active' : ''}`} to="/user">
                <span className="btn-icon">
                  <i className="custom-icon-social"></i>
                </span>
                <span>Social</span>
              </Link>
            </li>
            <li>
              <Link to="/shop">
                <span className="btn-icon">
                  <i className="custom-icon-shop"></i>
                </span>
                <span>Alma Store</span>
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
}

export default TopTabbings;
