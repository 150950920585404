/**
 * @copyright : ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
   @author     : Shiv Charan Panjeta 

  All Rights Reserved.
  Proprietary and confidential :  All information contained herein is, and remains
  the property of ToXSL Technologies Pvt. Ltd. and its partners.
  Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 */
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { useEffect, useState } from 'react';
import { Button, Container } from 'react-bootstrap';
import Alert from 'react-bootstrap/Alert';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { useSelector } from 'react-redux';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import Loader from '../../components/commoncomponents/loader/loader-large';
import { imageExtension } from '../../globals/helper';
import * as adminServices from '../../services/AuthServices';
import showNotification from '../../services/NotificationService';

let intervalId;
export default function ClubAboutPage() {
  const navigate = useNavigate();
  const { club_id } = useParams();

  const [detail, setDetail] = useState({
    club_id: club_id,
    description: '',
    banner_title: '',
    desc_one: '',
    desc_two: ''
  });
  const [loading, setLoading] = useState(false);
  const [clicked, setClicked] = useState(false);
  const [attachment, setAttachMent] = useState('');
  const [previewImg, setPreviewImg] = useState([]);
  const [pickLayout, setPickLayout] = useState(1);
  const [singlefile, setSingleFile] = useState('');
  const [singlefilePreview, setSingleFilePreview] = useState('');
  const [bannerFile, setBannerFile] = useState();
  const [bannerPreview, setBannerPreview] = useState('');
  const [showalert, setShowAlert] = useState(false);
  const [globalMsg, setGlobalMsg] = useState('');
  const roleType = useSelector((state) => state?.user?.details);
  const [id, setId] = useState(false);

  useEffect(() => {
    if (club_id) {
      getDetail(club_id);
    }
  }, []);

  const getDetail = () => {
    setLoading(true);
    let body = {
      club_id
    };
    adminServices.getSingleClubPageDetail(body).then((res) => {
      setLoading(false);
      if (res?.status === 200) {
        const data = res?.data?.details;
        if (data) {
          setDetail({
            ...detail,
            description: data?.description,
            name: data?.title,
            banner_title: data?.banner_title
          });
          if (data?.type_id == 1) {
            setId(data?.id);
            let arr = [];
            let temp = [];
            // attachments
            data?.multiple_image?.map((data) => {
              arr.push(data?.multiple_image);
              temp.push({ url: data?.multiple_image, id: data?.id });
            });
            setPreviewImg(temp);
            setAttachMent(arr);
          }
          setBannerFile(data?.banner_file);
        }
      }
    });
  };

  const onChangeHandler = (e, defaultName, data) => {
    if (
      defaultName == 'description' ||
      defaultName == 'vision_desc' ||
      defaultName == 'sports_desc' ||
      defaultName == 'notexpected_desc' ||
      defaultName == 'expected_desc'
    ) {
      setDetail({ ...detail, [defaultName]: data });
    } else {
      const { name, value } = e.target;
      setDetail({ ...detail, [name]: value });
    }
  };

  const validate = () => {
    let errors = {};

    if (detail?.description == '') {
      errors.description = 'This field is required';
    }
    if (pickLayout == 3 || pickLayout == 4) {
      if (!detail?.name.trim()) {
        errors.name = 'This field is required';
      }
    }
    if (pickLayout)
      if (!detail?.institute_id && !roleType?.institute_id) {
        errors.institute_id = 'This field is required';
      }
    return errors;
  };

  const submitHandler = (e) => {
    e.preventDefault();
    setClicked(true);
    if (!Object.keys(validate(detail)).length) {
      let body = {
        club_id: club_id,
        title: detail?.name,
        description: detail?.description,
        banner_title: detail?.banner_title
      };
      let formData = new FormData();
      for (let i in body) {
        formData.append(`${i}`, body[i]);
      }
      if (attachment || bannerFile) {
        if (attachment?.length) {
          for (let i in attachment) {
            formData.append('multiple_image[]', attachment[i]);
          }
        }
        formData.append('banner_file', bannerFile);
      }
      adminServices.addClubContent(formData).then((res) => {
        if (res?.status === 200) {
          showNotification('success', res?.data?.message);
          navigate(-1);
        }
      });
    }
  };

  const updateHandler = (e) => {
    e.preventDefault();
    setClicked(true);
    if (!Object.keys(validate(detail)).length) {
      let formData = new FormData();
      let body = {
        club_id: club_id,
        title: detail?.name,
        description: detail?.description,
        banner_title: detail?.banner_title
      };
      for (let i in body) {
        formData.append(`${i}`, body[i]);
      }
      if (attachment || bannerFile) {
        if (pickLayout == 1) {
          for (let i in attachment) {
            formData.append('multiple_image[]', attachment[i]);
          }
        }
        formData.append('banner_file', bannerFile);
      }
      adminServices.editClubContent(formData, id).then((res) => {
        if (res?.status === 200) {
          showNotification('success', res?.data?.message);
          navigate(-1);
        }
      });
    }
  };

  const handleFile = (e) => {
    if (pickLayout != 1) {
      setSingleFile(e.target.files[0]);
      setSingleFilePreview(URL.createObjectURL(e.target.files[0]));
    } else {
      if (e.target.files.length > 4) {
        alert('You can only upload a maximum of 4 files');
        document.getElementById('multi_img').value = '';
      } else {
        for (let i = 0; i < e.target.files.length; i++) {
          if (imageExtension.includes(e.target.files[i]?.type)) {
            const arr = [];
            const targetfile = e?.target?.files;
            const targetfileobj = [...targetfile];
            targetfileobj.map((file) => {
              arr.push({ url: URL.createObjectURL(file), id: '' });
            });
            setAttachMent([...attachment, ...targetfileobj]);
            setPreviewImg([...previewImg, ...arr]);
          } else {
            showNotification('danger', 'Please upload valid extension');
          }
        }
      }
    }
  };

  /**
   * delete selected image >for about page
   */
  const deleteHandler = (e, id, index) => {
    e.preventDefault();
    if (confirm('Are you sure you want to delete this ?')) {
      let arr = [...previewImg];
      let tempArr = [...attachment];
      if (id) {
        adminServices.deleteClubAboutImage(id).then((res) => {
          if (res?.status == 200) {
            setGlobalMsg(res?.data?.message);
            setShowAlert(true);
            alertHandler();
          }
        });
      }

      if (index > -1) {
        arr.splice(index, 1);
        tempArr.splice(index, 1);
        setPreviewImg(arr);
        setAttachMent(tempArr);
      }
      if (!arr?.length) {
        setPreviewImg('');
        setAttachMent('');
      }
    } else {
      return;
    }
  };

  /**
   * alert handler to show alert notification on deleting image
   */
  const alertHandler = () => {
    clearInterval(intervalId);
    intervalId = setInterval(function () {
      setShowAlert(false);
    }, 5000);
  };

  return (
    <>
      <div className="dash-main-content">
        <Container fluid>
          <div className="dash-page-content"></div>
          <div className="dash-page-title">
            <Row>
              <Col xs={12} sm={12} md={12}>
                <div className="dash-title-bar d-flex align-items-center justify-content-between">
                  <div className="title-lft d-flex align-items-center">
                    <div className="dash-title-dis">
                      <h5>{id ? 'Edit About Page Content' : 'Add About Page Content'}</h5>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <div className="dash-content-card">
            <Alert variant={'danger'} className={`${showalert ? '' : 'd-none'}`}>
              {globalMsg}
            </Alert>
            <div className="cmmn-form">
              <Form>
                <Form.Group className="mb-3" controlId="formBasicState">
                  <Form.Label>Banner Title</Form.Label>
                  <Form.Control
                    type="text"
                    name="banner_title"
                    value={detail?.banner_title}
                    onChange={(e) => {
                      onChangeHandler(e);
                    }}
                    placeholder="Enter banner title"
                  />
                </Form.Group>
                <Form.Group controlId="formFile" className="attach-file-card mb-3">
                  <Form.Control
                    type="file"
                    name="image"
                    accept=".png,.jpg,.jpeg"
                    onChange={(e) => {
                      setBannerFile(e.target.files[0]);
                      setBannerPreview(URL.createObjectURL(e.target.files[0]));
                    }}
                  />
                  <span className="attach-file-inr">
                    <i className="fas fa-paperclip me-2"></i>
                    Attach Banner File
                  </span>
                  <div className={'danger-frm'}>{clicked && validate().attachment}</div>
                </Form.Group>
                {/* preview */}
                <div className=" d-flex">
                  {id && bannerFile && !bannerPreview ? (
                    <img height={'100px'} width={'100px'} src={bannerFile} alt="img" />
                  ) : bannerPreview ? (
                    <img
                      height={'100px'}
                      width={'100px'}
                      src={bannerPreview}
                      alt="img"
                      className="me-2"
                    />
                  ) : (
                    ''
                  )}
                </div>
                {(pickLayout == 3 || pickLayout == 4) && (
                  <Form.Group className="mb-3" controlId="formBasicState">
                    <Form.Label>
                      {pickLayout == 3 ? 'Name' : 'Title'}
                      <sup className="text-danger">*</sup>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="name"
                      value={detail?.name}
                      onChange={(e) => {
                        onChangeHandler(e);
                      }}
                      placeholder={pickLayout == 3 ? 'Enter Name' : 'Enter Title'}
                    />
                    <div
                      className={`${
                        clicked && validate(detail)?.name ? '' : 'd-none'
                      } danger-frm `}>
                      {clicked && validate(detail)?.name}
                    </div>
                  </Form.Group>
                )}
                <Form.Group className="mb-3" controlId="formBasicName">
                  <Form.Label>
                    Description<sup className="text-danger">*</sup>
                  </Form.Label>
                  {/* ck editor */}
                  <CKEditor
                    name="job_info"
                    editor={ClassicEditor}
                    // data={add?.job_desc}
                    data={detail?.description}
                    // onReady={(editor) => {
                    //   // You can store the "editor" and use when it is needed.
                    // }}
                    config={{
                      toolbar: [
                        'bold',
                        'italic',
                        'numberedList',
                        'bulletedList',
                        'outdent',
                        'indent',
                        'blockQuote'
                      ]
                    }}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      setDetail((previous) => ({
                        ...previous,
                        ['description']: data
                      }));
                    }}
                  />
                  {/* end */}
                  <div
                    className={`${
                      clicked && validate(detail)?.description ? '' : 'd-none'
                    } danger-frm `}>
                    {clicked && validate(detail)?.description}
                  </div>
                </Form.Group>

                <Form.Group controlId="formFile" className="attach-file-card">
                  <Form.Control
                    multiple={pickLayout != 1 ? false : true}
                    type="file"
                    name="image"
                    accept=".png,.jpg,.jpeg"
                    id="multi_img"
                    onChange={(e) => {
                      handleFile(e);
                    }}
                  />
                  <span className="attach-file-inr">
                    <i className="fas fa-paperclip me-2"></i>
                    Attach File(s)
                  </span>
                  <div className={'danger-frm'}>{clicked && validate().attachment}</div>
                </Form.Group>
                <Row>
                  {pickLayout == 1 ? (
                    id && previewImg ? (
                      previewImg?.map((data, index) => (
                        <div className="image-area mb-2" key={index}>
                          <img src={data?.url} alt="Preview" />
                          <a
                            className="remove-image"
                            onClick={(e) => {
                              deleteHandler(e, data?.id, index);
                            }}
                            href="#"
                            style={{ display: 'inline' }}>
                            &#215;
                          </a>
                        </div>
                      ))
                    ) : previewImg ? (
                      previewImg?.map((data, index) => {
                        return (
                          <div className="image-area mb-2" key={index}>
                            <img src={data?.url} alt="Preview" />
                            <a
                              className="remove-image"
                              onClick={(e) => {
                                deleteHandler(e, data?.id, index);
                              }}
                              href="#"
                              style={{ display: 'inline' }}>
                              &#215;
                            </a>
                          </div>
                        );
                      })
                    ) : (
                      ''
                    )
                  ) : (
                    <div className=" d-flex">
                      {id && singlefile && !singlefilePreview ? (
                        <img
                          height={'100px'}
                          width={'100px'}
                          src={process.env.REACT_APP_API_DOMAIN + singlefile}
                          alt="img"
                        />
                      ) : singlefile ? (
                        <img
                          height={'100px'}
                          width={'100px'}
                          src={singlefilePreview}
                          alt="img"
                          className="me-2"
                        />
                      ) : (
                        ''
                      )}
                    </div>
                  )}
                </Row>
                <div className="form-btns d-flex align-items-center mt-5">
                  {id ? (
                    <Button
                      className="main-btn-grn"
                      onClick={(e) => {
                        updateHandler(e);
                      }}>
                      Update
                    </Button>
                  ) : (
                    <Button
                      className="main-btn-grn"
                      onClick={(e) => {
                        submitHandler(e);
                      }}>
                      Save
                    </Button>
                  )}
                  <strong>OR</strong>
                  <Button
                    className="main-btn-red"
                    onClick={() => {
                      navigate(-1);
                    }}>
                    Cancel
                  </Button>
                </div>
              </Form>
            </div>
          </div>
        </Container>
      </div>
      {loading ? <Loader /> : ''}
    </>
  );
}
