import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { useEffect, useState } from 'react';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { useNavigate } from 'react-router-dom';
import AsyncSelect from 'react-select/async';
import { createSelectDropDownList, restrictAlpha } from '../../../globals/helper';
import { getProfessionalCompanyInfo, postAJobInCompany } from '../../../services/CompanyServices';
import * as APISERVICE from '../../../services/JobServices';
import showNotification from '../../../services/NotificationService';
import { loadJObLocationOption } from '../../../super-admin/AdminHelper';

export default function CompanyAddJob(id) {
  const [categoryList, setCategoryList] = useState([]);

  const [minPackage, setMinimumPackage] = useState([]);
  const [maxPackage, setMaximumPackage] = useState([]);

  const [jobView, setJobview] = useState([]);
  const [follow, setFollow] = useState(false);
  const [role, setRole] = useState(false);
  const [loading, setLoading] = useState(false);
  const [clicked, setClicked] = useState(false);
  const [jobLocation, setJobLocation] = useState('');
  const [locationSearch, setLocationSearch] = useState(null);
  useEffect(() => {
    getCompanyInfo();
    getDropDowns();
  }, []);
  // Dropdown
  const navigate = useNavigate();
  const getCompanyInfo = () => {
    getProfessionalCompanyInfo(id?.companyId).then((resp) => {
      if (resp?.status === 200) {
        setJobview(resp?.data?.companyInfo);
        setFollow(resp?.data?.is_following);
        setRole(resp?.data?.admin);
      }
    });
  };
  const [selectedkeyWord, setSelectedKeyword] = useState('');
  const [add, setAdd] = useState({
    company_id: '',
    timeline_id: '',
    job_title: '',
    job_desc: '',
    job_cat: '',
    jobs_keywords: '',
    positions: '',
    min_exp: '',
    max_exp: '',
    min_ctc: '',
    max_ctc: '',
    location: '',
    hr_email: '',
    job_type: '',
    contact_num: '',
    allow_job_finder: ''
  });

  const validate = () => {
    let errors = {};
    if (!add?.job_title.trim()) {
      errors.job_title = 'Please enter job title';
    }
    if (!add?.job_desc.trim()) {
      errors.job_desc = 'Please enter job description';
    }

    // if (!add?.job_cat.trim()) {
    //   errors.job_cat = 'Please Select job category ';
    // }
    // if (!add?.min_ctc) {
    //   errors.min_ctc = 'Please enter minimum CTC';
    // }

    // if (!add?.max_ctc) {
    //   errors.max_ctc = 'Please enter maximum CTC';
    // } else
    if (add.max_ctc != '' && add.max_ctc < add.min_ctc) {
      errors.max_ctc = 'Max CTC should be greater then Min CTC';
    }
    if (add?.location === '') {
      errors.location = 'Please select location';
    }

    if (!add?.positions) {
      errors.positions = 'Please enter no of positions';
    }
    if (!add?.min_exp) {
      errors.min_exp = 'Please enter minimum experience';
    }
    if (!add?.max_exp) {
      errors.max_exp = 'Please enter maximum experience';
    } else if (add.max_exp < add.min_exp) {
      errors.max_exp = 'Max exp should be greater then Min exp';
    }

    if (!selectedkeyWord?.length) {
      errors.job_type = 'Please enter job keywords';
    }

    return errors;
  };

  const submitHandler = () => {
    setClicked(true);
    if (!Object.keys(validate(add)).length) {
      let body = {
        company_id: jobView?.institute_id,
        timeline_id: jobView?.timeline_id,
        job_title: add?.job_title,
        job_desc: add?.job_desc,
        job_cat: add?.job_cat,
        positions: add?.positions,
        min_exp: add?.min_exp,
        max_exp: add?.max_exp,
        min_ctc: add?.min_ctc,
        max_ctc: add?.max_ctc,
        location: add?.location,
        hr_email: add?.hr_email,
        job_type: add?.job_type,
        jobs_keywords: selectedkeyWord?.map((data) => data?.id).join(),
        contact_num: add?.contact_num,
        allow_job_finder: add?.allow_job_finder
      };
      postAJobInCompany(body).then((response) => {
        if (response?.status == 200) {
          setClicked(false);
          showNotification('success', 'job posted successfully');
          setAdd({
            job_title: '',
            job_desc: '',
            job_cat: '',
            jobs_keywords: '',
            positions: '',
            min_exp: '',
            max_exp: '',
            min_ctc: '',
            max_ctc: '',
            location: '',
            hr_email: '',
            job_type: '',
            contact_num: '',
            allow_job_finder: ''
          });
          setSelectedKeyword('');

        }
      });
    }
  };

  const getDropDowns = () => {
    APISERVICE.getJobCategory().then((res) => {
      if (res?.status === 200) {
        const data = res?.data?.data;
        setCategoryList(createSelectDropDownList(data));
      }
    });

    APISERVICE.getMinCtc().then((res) => {
      if (res?.status === 200) {
        setMinimumPackage(res?.data?.data);
      }
    });

    APISERVICE.getMaxCtc().then((res) => {
      if (res?.status === 200) {
        setMaximumPackage(res?.data?.data);
      }
    });
  };

  const loadKeywords = (inputValue) => {
    return APISERVICE.getKeyWordsNew(inputValue).then((res) => {
      if (res?.status === 200) {
        let tempArr = [];
        if (res?.data?.detail) {
          const data = res?.data?.detail;
          data?.map((data) => {
            tempArr.push({
              id: data?.id,
              label: data?.text,
              value: data?.id
            });
          });
        }
        return tempArr;
      }
    });
  };

  const PositionsOptions = [
    { id: 1, value: '1' },
    { id: 2, value: '2' },
    { id: 3, value: '3' },
    { id: 4, value: '4' },
    { id: 5, value: '5' },
    { id: 5, value: '6' },
    { id: 5, value: '7' },
    { id: 5, value: '8' },
    { id: 5, value: '9' },
    { id: 5, value: '10' }
  ];

  const WorkOptions = [
    { id: 1, value: '0' },
    { id: 2, value: '1' },
    { id: 3, value: '2' },
    { id: 4, value: '3' },
    { id: 5, value: '4' },
    { id: 6, value: '5' },
    { id: 7, value: '6' },
    { id: 8, value: '7' },
    { id: 9, value: '8' },
    { id: 10, value: '9' },
    { id: 11, value: '10' }
  ];

  const WorkOptions1 = [
    { id: 2, value: '1' },
    { id: 3, value: '2' },
    { id: 4, value: '3' },
    { id: 5, value: '4' },
    { id: 6, value: '5' },
    { id: 7, value: '6' },
    { id: 8, value: '7' },
    { id: 9, value: '8' },
    { id: 10, value: '9' },
    { id: 11, value: '10' }
  ];

  const JobtypeOptions = [
    { id: 0, value: 'Full Time' },
    { id: 1, value: 'Part Time' },
    { id: 3, value: 'Internship' }
  ];

  const onChangeHandler = (e, option) => {
    if (option) {
      if (option?.name == 'keywords') {
        let keyWordId = [];
        e?.map((data) => {
          keyWordId.push(data.id);
        });
        setAdd({ ...add, [option.name]: keyWordId.join() });
      } else {
        setAdd({ ...add, [option.name]: e.id });
      }
    } else {
      const { name, value } = e.target;
      setAdd({ ...add, [name]: value });
    }
  };

  return (
    <>
      <div className="job-recomm-card bg-white">
        <h4>Post Job </h4>
        <div className="post-job-form py-4 px-4">
          <Form>
            <Form.Group as={Row} className="mb-3" controlId="formPlaintextEmail">
              <Form.Label column sm="3">
                {' '}
                Job Title<sup className="text-danger">*</sup>{' '}
              </Form.Label>
              <Col sm="9">
                <Form.Control
                  type="text"
                  placeholder="Enter Job Title here."
                  name="job_title"
                  value={add?.job_title}
                  onChange={(e) => {
                    onChangeHandler(e);
                  }}
                />
                <div
                  className={`${clicked && validate(add).job_title ? '' : 'd-none'} danger-frm `}
                >
                  {clicked && validate(add).job_title}
                </div>
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3" controlId="formPlaintextEmail">
              <Form.Label column sm="12">
                {' '}
                Job Description<sup className="text-danger">*</sup>
              </Form.Label>
              <Col sm="12">
                {/* <Form.Control
                type="text"
                value={add?.job_desc}
                placeholder="Enter Job Description here."
                name="job_desc"
                onChange={(e) => {
                  onChangeHandler(e);
                }}
              /> */}
                <div>
                  <CKEditor
                    name="job_info"
                    editor={ClassicEditor}
                    data={add?.job_desc}
                    onReady={(editor) => {
                      // You can store the "editor" and use when it is needed.
                    }}
                    config={{
                      toolbar: [
                        'bold',
                        'italic',
                        'numberedList',
                        'bulletedList',
                        'outdent',
                        'indent'
                      ]
                    }}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      setAdd((previous) => {
                        return {
                          ...previous,
                          ['job_desc']: data
                        };
                      });
                    }}
                    onBlur={(event, editor) => { }}
                    onFocus={(event, editor) => { }}
                  />
                </div>
              </Col>
              <div className={`${clicked && validate(add).job_desc ? '' : 'd-none'} danger-frm `}>
                {clicked && validate(add).job_desc}
              </div>
            </Form.Group>

            <Form.Group as={Row} className="mb-4" controlId="formHorizontalCategory">
              <Form.Label column sm={4}>
                Job Category
              </Form.Label>
              <Col sm={8}>
                <Form.Control
                  as="select"
                  value={add?.job_cat}
                  name="job_cat"
                  onChange={(e) => {
                    onChangeHandler(e);
                  }}
                >
                  {/* api option */}
                  <option value="">Select category from list </option>
                  {categoryList &&
                    categoryList?.map((data, index) => (
                      <option key={index} value={data?.id}>
                        {data?.value}{' '}
                      </option>
                    ))}
                </Form.Control>
                {/* <div className={`${clicked && validate(add).job_cat ? '' : 'd-none'} danger-frm `}>
                {clicked && validate(add).job_cat}
              </div> */}
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-4" controlId="formHorizontalKeywords">
              <Form.Label column sm={4}>
                Keywords
                <sup className="text-danger">*</sup>
              </Form.Label>
              <Col sm={8}>
                <AsyncSelect
                  isMulti
                  className="basic-single"
                  classNamePrefix="select"
                  isSearchable={true}
                  name="jobs_keywords"
                  loadOptions={loadKeywords}
                  value={selectedkeyWord}
                  onChange={(e) => {
                    setSelectedKeyword(e);
                  }}
                  placeholder="Select skillset"
                />
                <div className={`${clicked && validate(add).job_type ? '' : 'd-none'} danger-frm `}>
                  {clicked && validate(add).job_type}
                </div>
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-4" controlId="formHorizontalPostions">
              <Form.Label column sm={4}>
                {' '}
                Open positions <sup className="text-danger">*</sup>{' '}
              </Form.Label>
              <Col sm={8}>
                <Form.Control
                  as="select"
                  value={add?.positions}
                  name="positions"
                  onChange={(e) => {
                    onChangeHandler(e);
                  }}
                >
                  <option value="">Select option</option>
                  {PositionsOptions &&
                    PositionsOptions?.map((data, index) => (
                      <option key={index} value={data?.value}>
                        {data?.value}
                      </option>
                    ))}
                </Form.Control>
                <div
                  className={`${clicked && validate(add).positions ? '' : 'd-none'} danger-frm `}
                >
                  {clicked && validate(add).positions}
                </div>
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-4" controlId="formHorizontalWork">
              <Form.Label column sm={4}>
                {' '}
                Work experience (in yrs)
                <sup className="text-danger">*</sup>
              </Form.Label>
              <Col sm={8}>
                <Row className="d-flex align-items-center">
                  <Col sm={5}>
                    <Form.Control
                      as="select"
                      name="min_exp"
                      value={add?.min_exp}
                      onChange={(e) => {
                        onChangeHandler(e);
                      }}
                    >
                      <option value={' '}>Minimum</option>
                      {WorkOptions &&
                        WorkOptions?.map((data, index) => (
                          <option key={index} value={data?.value}>
                            {data?.value}
                          </option>
                        ))}
                      <div
                        className={`${clicked && validate(add).positions ? '' : 'd-none'
                          } danger-frm `}
                      >
                        {clicked && validate(add).positions}
                      </div>
                    </Form.Control>
                  </Col>
                  <Col sm={1}>to</Col>
                  <Col sm={5}>
                    <Form.Control
                      as="select"
                      name="max_exp"
                      value={add?.max_exp}
                      onChange={(e) => {
                        onChangeHandler(e);
                      }}
                    >
                      <option value={' '}>Maximum</option>
                      {WorkOptions1 &&
                        WorkOptions1?.map((data, index) => (
                          <option key={index} value={data?.value}>
                            {data?.value}
                          </option>
                        ))}
                    </Form.Control>
                  </Col>
                  <div
                    className={`${clicked && validate(add).min_exp ? '' : 'd-none'} danger-frm `}
                  >
                    {clicked && validate(add).min_exp}
                  </div>
                  <div
                    className={`${clicked && validate(add).max_exp ? '' : 'd-none'} danger-frm `}
                  >
                    {clicked && validate(add).max_exp}
                  </div>
                </Row>
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-4" controlId="formHorizontalPassword">
              <Form.Label column sm={4}>
                CTC
              </Form.Label>
              <Col sm={8}>
                <Row className="d-flex align-items-center">
                  <Col sm={5}>
                    <Form.Control
                      as="select"
                      value={add?.min_ctc}
                      name="min_ctc"
                      onChange={(e) => {
                        onChangeHandler(e);
                      }}
                    >
                      <option value="">Minimum</option>
                      {maxPackage &&
                        maxPackage?.map((data, index) => (
                          <option key={index} value={data}>
                            {data} Lacs
                          </option>
                        ))}
                      {/* <div
                      className={`${
                        clicked && validate(add).positions ? '' : 'd-none'
                      } danger-frm `}>
                      {clicked && validate(add).positions}
                    </div> */}
                    </Form.Control>
                  </Col>
                  <Col sm={1}>to</Col>
                  <Col sm={5}>
                    <Form.Control
                      as="select"
                      name="max_ctc"
                      value={add?.max_ctc}
                      onChange={(e) => {
                        onChangeHandler(e);
                      }}
                    >
                      <option value="">Maximum</option>
                      {maxPackage &&
                        maxPackage?.map((data, index) => (
                          <option key={index} value={data}>
                            {data} Lacs
                          </option>
                        ))}
                    </Form.Control>
                  </Col>
                  {/* <div className={`${clicked && validate(add).min_ctc ? '' : 'd-none'} danger-frm `}>
                  {clicked && validate(add).min_ctc}
                </div> */}
                  <div
                    className={`${clicked && validate(add).max_ctc ? '' : 'd-none'} danger-frm `}
                  >
                    {clicked && validate(add).max_ctc}
                  </div>
                </Row>
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-4" controlId="formHorizontalLocation">
              <Form.Label column sm={4}>
                {' '}
                Location<sup className="text-danger">*</sup>
              </Form.Label>
              <Col sm={8}>
                <AsyncSelect
                  cacheOptions
                  onMenuClose={() => setLocationSearch(null)}
                  placeholder={`Mumbai, Kolkata, Delhi..`}
                  value={jobLocation}
                  menuIsOpen={locationSearch !== null ? true : false}
                  getOptionLabel={(e) => e.label}
                  getOptionValue={(e) => e.value}
                  loadOptions={loadJObLocationOption}
                  onInputChange={(newValue) => setLocationSearch(newValue)}
                  className="basic-single mt-1"
                  name="location"
                  onChange={(e) => {
                    console.log('e', e)
                    setJobLocation(() => e);
                    setAdd({ ...add, ['location']: e.id });
                  }}
                />
                <div className={`${clicked && validate(add).location ? '' : 'd-none'} danger-frm `}>
                  {clicked && validate(add).location}
                </div>
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-4" controlId="formHorizontalEmail">
              <Form.Label column sm={4}>
                HR email
              </Form.Label>
              <Col sm={8}>
                <Form.Control
                  type="text"
                  name="hr_email"
                  value={add?.hr_email}
                  // value={postJobDetail?.hr_email}
                  onChange={(e) => {
                    onChangeHandler(e);
                  }}
                  placeholder="Enter the email ID to whom user can contact directly"
                />
                {/* <div className={`${clicked && validate(add).hr_email ? '' : 'd-none'} danger-frm `}>
                {clicked && validate(add).hr_email}
              </div> */}
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-4" controlId="formHorizontalNumber">
              <Form.Label column sm={4}>
                Contact number
              </Form.Label>
              <Col sm={8}>
                <Form.Control
                  type="text"
                  value={add?.contact_num}
                  onKeyPress={restrictAlpha}
                  // value={postJobDetail?.contact_number}
                  name="contact_num"
                  onChange={(e) => {
                    onChangeHandler(e);
                  }}
                  placeholder="Mobile number to contact"
                />
                {/* <div
                className={`${clicked && validate(add).contact_num ? '' : 'd-none'} danger-frm `}>
                {clicked && validate(add).contact_num}
              </div> */}
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-4" controlId="formHorizontalJob">
              <Form.Label column sm={4}>
                Job Type
              </Form.Label>
              <Col sm={8}>
                <Form.Control
                  as="select"
                  name="job_type"
                  value={add?.job_type}
                  // value={postJobDetail?.job_type}
                  onChange={(e) => {
                    onChangeHandler(e);
                  }}
                >
                  <option value="">Select type</option>
                  {JobtypeOptions &&
                    JobtypeOptions?.map((data, index) => (
                      <option key={index} value={data?.id}>
                        {data?.value}
                      </option>
                    ))}
                </Form.Control>
                {/* <div className={`${clicked && validate(add).job_type ? '' : 'd-none'} danger-frm `}>
                {clicked && validate(add).job_type}
              </div> */}
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3" controlId="formHorizontalCheck">
              <Col sm={10}>
                <Form.Check
                  name="allow_job_finder"
                  checked={Number(add?.allow_job_finder)}
                  onChange={(e) => {
                    setAdd({
                      ...add,
                      [e.target.name]: e.target.checked ? '1' : '0'
                    });
                  }}
                  label="Allow job finder to get referred from other people working in the same company "
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
              <Col sm={12}>
                <div className="accnt-lft-btn d-flex align-items-center justify-content-end">
                  <button className="accnt-btn" type="button" onClick={submitHandler}>
                    Save Job
                  </button>
                </div>
              </Col>
            </Form.Group>
          </Form>
        </div>
      </div>
    </>
  );
}
