import { Link, useNavigate } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useEffect, useState } from 'react';
import * as APISERVICES from '../../../services/ShopServices';
import Loader from '../loader/loader-large';

function Memorialgrid(props) {
  const { type } = props;
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [productList, setProductList] = useState([]);
  useEffect(() => {
    getMotivationalMemo();
    if (type) {
      getSchoolAndCollege();
    }
  }, []);

  const getMotivationalMemo = () => {
    setLoading(true);
    APISERVICES.campusProducts().then((res) => {
      setLoading(false);
      if (res?.status === 200) {
        setLoading(false);
        setProductList(res?.data?.list);
      }
    });
  };
  const getSchoolAndCollege = () => {
    setLoading(true);
    APISERVICES.featureProducts().then((res) => {
      setLoading(false);
      if (res?.status === 200) {
        setLoading(false);
        setProductList(res?.data?.list);
      }
    });
  };

  const handleSingleView = (data) => {
    navigate(`/shop/details/${data?.id}`);
  };
  return (
    <>
      <div className="product-grid">
        <Row>
          {productList &&
            productList?.map((data) => (
              <Col md={4} lg={3} key={data?.id}>
                <div
                  role="presentation"
                  className="product-card"
                  onClick={() => handleSingleView(data)}
                >
                  <Link>
                    <div className="product-img" role="presentation">
                      <img src={process.env.REACT_APP_API_DOMAIN + data?.cover_image} alt="" />
                    </div>
                    <div className="product-dis">
                      <p>{data?.name ? data?.name : ''}</p>
                      <ul className="rating-star mb-2">
                        <li>
                          <i className="far fa-star"></i>
                        </li>
                        <li>
                          <i className="far fa-star"></i>
                        </li>
                        <li>
                          <i className="far fa-star"></i>
                        </li>
                        <li>
                          <i className="far fa-star"></i>
                        </li>
                        <li>
                          <i className="far fa-star"></i>
                          {/* to highlist use-far->fas */}
                        </li>
                      </ul>
                      <div className="product-price-card d-flex align-items-center">
                        <h4>&#8377;{data?.price} </h4>
                        {/* <del>&#8377;299.00</del> */}
                        {/* <span>33%</span> */}
                      </div>
                    </div>
                  </Link>
                </div>
              </Col>
            ))}
        </Row>
      </div>
      {loading ? <Loader /> : ''}
    </>
  );
}

export default Memorialgrid;
