/**
@copyright  :   ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
@author     :   Shiv Charan Panjeta 
 
All Rights Reserved.
Proprietary and confidential :  All information contained here in is, and remains
the property of ToXSL Technologies Pvt. Ltd. and it's partners.
Unauthorized copying of this file, via any medium is strictly prohibited.
*/
import { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { useLocation } from 'react-router-dom';
import * as APISERVICES from '../../../services/AuthServices';

export default function CmsDetailView() {
  const [detail, setDetail] = useState('');
  const location = useLocation();
  let id = location?.state;

  useEffect(() => {
    apiCall(id);
  }, []);

  const apiCall = (id) => {
    APISERVICES.cmsDetail(id).then((res) => {
      if (res?.status === 200) {
        setDetail(res?.data?.data);
      }
    });
  };

  return (
    <div className="dash-main-content">
      <Container fluid>
        <div className="dash-page-content">
          <div className="dash-page-title">
            <Row>
              <Col xs={12} sm={12} md={12}>
                <div className="dash-title-bar d-flex align-items-center justify-content-between">
                  <div className="title-lft d-flex align-items-center">
                    <div className="dash-title-dis">
                      <h5>View Cms Details</h5>
                    </div>
                  </div>
                  {/* <div className="title-rt-btn ">
                      <Link className="add-btn-grn" to="/admin/cms/list">
                        Back To Listing
                      </Link>
                    </div> */}
                </div>
              </Col>
            </Row>
          </div>
          <div className="dash-content-card">
            <div className="view-detail-content">
              <Row>
                <Col md={12}>
                  <p>
                    <strong>Page Title : </strong>
                    {detail?.page_title}
                  </p>
                </Col>
              </Row>

              <Row>
                <Col md={12}>
                  <p>
                    <strong>Page Slug : </strong>
                    {detail?.page_slug}
                  </p>
                </Col>
              </Row>

              <Row>
                <Col md={12}>
                  <p>
                    <strong>Page Content : </strong>
                    <span dangerouslySetInnerHTML={{ __html: detail?.page_content }} />
                  </p>
                </Col>
              </Row>

              <Row>
                <Col md={12}>
                  <p>
                    <strong>Meta Title : </strong>
                    {detail?.meta_title}
                  </p>
                </Col>
              </Row>

              <Row>
                <Col md={12}>
                  <p>
                    <strong>Meta keywords : </strong>
                    {detail?.meta_keywords}
                  </p>
                </Col>
              </Row>

              <Row>
                <Col md={12}>
                  <p>
                    <strong>Meta Description : </strong>
                    {detail?.meta_description}
                  </p>
                </Col>
              </Row>

              <Row>
                <Col md={12}>
                  <p>
                    <strong>Page Status : </strong>
                    {detail?.page_status == 1
                      ? 'Enable'
                      : detail?.page_status == 0
                      ? 'Disable'
                      : 'Delete'}
                  </p>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}
