import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import { Link, useNavigate } from 'react-router-dom';
import { resumeExtension, truncate } from '../../../globals/helper';
import useDocumentTitle from '../../../globals/useDocumentTitle';
import * as APISERVICES from '../../../services/JobServices';
import showNotification from '../../../services/NotificationService';
import * as APISERVICE from '../../../services/UserServices';
import HeaderLogin from '../../commoncomponents/HeaderLogin';
import { FresherJobSimmer } from '../../commoncomponents/skelton/allskelton';
import ApplyForm from '../common-professional-comp/ApplyForm';
import JobCategoryList from '../common-professional-comp/JobCategoryList';
import JobFilterSearch from '../common-professional-comp/JobFilterSearch';
import RecomendModal from '../common-professional-comp/RecomendModal';
import SocialShare from './SocialShare';
import TopTabbings from './TopTabbings';
import moment from 'moment';

function FresherJob() {
  useDocumentTitle('Browse Jobs | AlmaBay');

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const navigate = useNavigate();
  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  const [list, setList] = useState(false);

  const [applyForm, setApplyForm] = useState({
    to: ' ',
    subject: ' ',
    message: ' ',
    resume: ' '
  });
  const fresher_id = new URLSearchParams(location?.search).get('');
  const [savedResume, setSavedResume] = useState('');
  const [jobId, setJobId] = useState('');
  const [jobTitle, setJobTitle] = useState('');
  const [isChecked, setIsChecked] = useState(false);
  const [resumeSelect, setResumeSelect] = useState(false);
  const [fileSizeValid, setFileSizeValid] = useState(false);
  const [techSkill, setTechSkill] = useState([]);
  const [techSearch, setTechSearch] = useState('');
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(false);

  const [skillsFilterId, setSkillsFilterId] = useState('');
  const [companyFilterId, setCompanyFilterId] = useState('');
  const [industryFilterId, setIndustryFilterId] = useState('');
  const [locationFilterId, setLocationFilterId] = useState('');

  const [show3, setShow3] = useState(false);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);
  const [pageNum, setPageNum] = useState(0);
  const [shareModelShow, setShareModelShow] = useState(false);
  const [socialShareData, setSocialShareData] = useState(false);
  const shareModelClose = () => {
    setShareModelShow(false);
  };

  const [inputMsg, setInputMsg] = useState('Please enter atleast 2 characters.');
  const [loadInpMsg, setloadInpMsg] = useState(false);
  useEffect(() => {
    getFresherList();
    getUserDetail();
  }, []);

  const getFresherList = (category_id = '', exp_type = 'fresher', istrue, page) => {
    if (!page) {
      setLoading(true);
    }

    APISERVICES.getSearchedJob(
      exp_type,
      '',
      '',
      '',
      '',
      category_id,
      '',
      '',
      '',
      '',
      page ? page : pageNum
    ).then((res) => {
      setLoading(false);
      if (res?.status === 200) {
        if (page) {
          setList([...list, ...res?.data?.data]);
        } else {
          setList(res?.data?.data);
        }
        setCount(res?.data?.pager);
        if (istrue) {
          navigate(`/jobs/search/category/${category_id}`, { state: 2 });
        }
      }
    });
  };

  const skills = techSkill?.map((data) => {
    return data?.label;
  });

  const advanceJobSearch = () => {
    if (skills?.length !== 0 || companyFilterId || industryFilterId || locationFilterId) {
      setLoading(true);
      let body = {
        skills: skills?.toString(),
        companies: companyFilterId,
        industry: industryFilterId,
        location: locationFilterId,
        jof: 'jobs',
        job_type: 'fresher',
        max_ctc: '',
        max_exp: '',
        min_ctc: '',
        min_exp: '',
        offset: 0,
        type: ''
      };
      APISERVICE.advanceSearchForJob(body).then((res) => {
        setLoading(false);
        if (res?.status === 200) {
          setList(res?.data?.detail);
          setCount('');
        }
      });
    } else {
      showNotification('danger', 'Kindly select at least one filter condition to proceed');
    }
  };

  const getUserDetail = () => {
    APISERVICE.userBasicInfo().then((res) => {
      if (res?.status === 200) {
        setSavedResume(res?.data?.resume);
        setApplyForm({ ...applyForm, ['resume']: res?.data?.resume });
      }
    });
  };

  const onApplyStateHandler = (data) => {
    setJobId(data?.id);
    setJobTitle(data?.job_title);
    setApplyForm({
      to: data?.user?.username
        ? data?.user?.username
        : data?.user?.first_name + ' ' + data?.user?.last_name,
      subject: `Alma - Job application for ${data?.job_title}`,
      message: '',
      resume: savedResume
    });
    handleShow2();
  };

  const onChangeHandler = (e, rid = false) => {
    if (rid) {
      setApplyForm({ ...applyForm, ['resume']: savedResume });
    } else {
      const { name, value, type } = e.target;
      if (type == 'file') {
        let file = e.target.files[0];
        let fileSize = file.size / 1024;

        if (fileSize > 500) {
          setResumeSelect(false);
          setFileSizeValid(true);
          alert('Resume file size should be less than 500KB');
        } else {
          setFileSizeValid(false);
          if (resumeExtension.includes(e.target.files[0].type)) {
            setResumeSelect(true);
            setApplyForm({ ...applyForm, [name]: file });
          } else {
            setResumeSelect(false);
            alert(
              'Resume file format is not valid, kindly attach a valid file e.g doc,docx,rtf,odt,pdf'
            );
          }
        }
      } else {
        setApplyForm({ ...applyForm, [name]: value });
      }
    }
  };

  /**
   * apply for job submit handler and calling list api with recent page
   */
  const submitHandler = () => {
    let formData = new FormData();
    for (let i in applyForm) {
      formData.append(`${i}`, applyForm[i]);
    }
    APISERVICES.applyForJob(jobId, formData).then((res) => {
      if (res?.status === 200) {
        handleClose2();
        showNotification('success', res?.data?.message);
        getFresherList();
      }
    });
  };

  const onRecommendClick = (e, id) => {
    e.preventDefault();
    setJobId(id);
    handleShow3();
  };
  const onResetPageNum = () => {
    setPageNum(0);
  };

  const handleLoadMore = (e) => {
    e.preventDefault();
    setPageNum(pageNum + 1);
    getFresherList('', 'fresher', '', pageNum + 1);
  };

  const loadTechOptions = async (inputValue) => {
    if (inputValue.length >= 2) {
      setloadInpMsg(true);
      return APISERVICE.getTechnicalSkillDrop(inputValue).then((res) => {
        setloadInpMsg(false);
        setInputMsg('searching...');
        if (res?.status === 200) {
          let data = res?.data?.data;
          let arr = [];
          data?.map((data) => {
            arr.push({ label: data.name, value: data.id });
          });
          if (data?.length == 0) {
            setInputMsg('no matches found');
          }

          return arr;
        }
      });
    }
  };

  return (
    <>
      <HeaderLogin />
      <div className="header-lnk-feeds">
        <div className="container">
          <TopTabbings state={'feed'} />
        </div>
      </div>
      <section className="feed-sec bg-grey py-4">
        <div className="container">
          <Row>
            <JobFilterSearch
              advanceJobSearch={advanceJobSearch}
              setSkillsFilterId={setSkillsFilterId}
              setCompanyFilterId={setCompanyFilterId}
              setIndustryFilterId={setIndustryFilterId}
              setLocationFilterId={setLocationFilterId}
              getInternJobList={getFresherList}
              onResetPageNum={onResetPageNum}
              job_type="fresher"
              techSkill={techSkill}
              setTechSkill={setTechSkill}
              techSearch={techSearch}
              setTechSearch={setTechSearch}
              loadTechOptions={loadTechOptions}
              inputMsg={inputMsg}
              setInputMsg={setInputMsg}
              loadInpMsg={loadInpMsg}
            />
            <Col xs={12} lg={8} xl={6} className="mb-4">
              <div className="job-recomm-card bg-white">
                {fresher_id ? <h4>Filtered Jobs Results</h4> : <h4>Search Jobs for Freshers</h4>}
                <h6 className="intern-count-hd pt-3 px-3">
                  Latest <b>{list?.length} jobs </b> for you.
                </h6>
                <div className="job-recommd-lst p-3">
                  {loading ? (
                    <>
                      <FresherJobSimmer />
                      <FresherJobSimmer />
                      <FresherJobSimmer />
                      <FresherJobSimmer />
                    </>
                  ) : (
                    <>
                      {list && list?.length ? (
                        list?.map((data, index) => {
                          return (
                            <div key={index} className="job-recomm-card-inr  internship-card-inr">
                              <div className="top-time-line mb-3">
                                <p title={moment(data?.created_on).format('YYYY-MM-DD HH:MM:SS ')}>
                                  <i className="far fa-clock me-1"></i> Added on: {data?.date_time}
                                </p>
                              </div>
                              <div className="job-recomm-dis">
                                <h4>
                                  <Link to={`/jobs/jobs-details/${data?.id}`} target="_blank">
                                    {data?.job_title}
                                  </Link>
                                </h4>
                                <p>{data?.company_name}</p>
                                {data?.key_skills && data?.key_skills?.length !== 0 && (
                                  <p>
                                    <strong>Keyskills:</strong>{' '}
                                    {/* {truncate(data?.job_keyword_meta?.split('|')?.join(), 50)} */}
                                    {data?.key_skills
                                      ? data?.key_skills
                                          ?.map((item) => {
                                            return item?.name;
                                          })
                                          ?.join(',')
                                      : []?.map((item) => {
                                          return <span>{item?.name}, </span>;
                                        })}
                                  </p>
                                )}
                                <ul className="job-recomm-addrs">
                                  {data?.city_name ? (
                                    <li>
                                      <i title="location" className="fas fa-map-marker-alt"></i>
                                      {data?.city_name}
                                    </li>
                                  ) : (
                                    ''
                                  )}
                                  {data?.max_ctc != 0 ? (
                                    <li>
                                      <i title="CTC" className="fas fa-rupee-sign"></i>CTC :
                                      {data?.max_ctc
                                        ? data?.min_ctc + '-' + data?.max_ctc + `(Lacs)`
                                        : ''}
                                    </li>
                                  ) : (
                                    ''
                                  )}
                                  <li>
                                    <i title="Positions open" className="fas fa-users"></i>Positions
                                    Open: {data?.open_positions}
                                  </li>
                                  <li>
                                    <i title="Experience Required" className="fas fa-briefcase"></i>
                                    Experience:{data?.min_exp} - {data?.max_exp} (yrs).
                                  </li>
                                </ul>
                                <div>
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: truncate(data?.job_info, 200)
                                    }}
                                  />
                                </div>
                                <Link
                                  className="view-detail-btn"
                                  to={`/jobs/jobs-details/${data?.id}`}
                                  target="_blank">
                                  View Details
                                </Link>
                              </div>
                              <div className="job-recommd-ftr">
                                <ul>
                                  <li>
                                    {data?.self_added ? (
                                      ''
                                    ) : (
                                      <li>
                                        {!data?.is_applied ? (
                                          <Link
                                            className="apply-btn"
                                            onClick={() => {
                                              onApplyStateHandler(data);
                                            }}>
                                            <i className="fas fa-check me-1"></i>Apply
                                          </Link>
                                        ) : (
                                          <Link className="btn applied-btn">
                                            <i className="fas fa-check me-1"></i>Already Applied
                                          </Link>
                                        )}

                                        {/* pop-up */}
                                      </li>
                                    )}

                                    {/* pop-up */}
                                  </li>
                                  <li>
                                    <Link
                                      to="#"
                                      onClick={(e) => {
                                        onRecommendClick(e, data?.id);
                                      }}>
                                      <i className="fas fa-thumbs-up me-1"></i>Recommend
                                    </Link>
                                  </li>
                                  <li>
                                    <Link
                                      onClick={() => {
                                        setShareModelShow(true);
                                        setSocialShareData(data);
                                      }}>
                                      <i className="fas fa-share me-1"></i> Share
                                    </Link>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          );
                        })
                      ) : (
                        <div className="upcoming_events p-3">
                          <div className="text-center">
                            <img
                              src={require('../../../assets/images/small-error.png')}
                              alt="img"
                            />
                          </div>
                          <p className="text-center">Data not found.</p>
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>
              {list?.length !== count?.total && (
                <div className="load-more-btn text-center">
                  <Button variant="secondary" className="read-more" onClick={handleLoadMore}>
                    Load More
                  </Button>
                </div>
              )}
            </Col>
            <Col xl={{ span: 3, offset: 0 }} lg={{ span: 8, offset: 4 }}>
              {/*job  category lists */}
              <JobCategoryList callApi={getFresherList} state="2" />
            </Col>
          </Row>
        </div>
        <Modal show={shareModelShow} onHide={shareModelClose}>
          <Modal.Header closeButton>
            <Modal.Title>share to</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <SocialShare
              facebookOptions={{
                url: process.env.REACT_APP_DOMAIN + 'jobs/jobs-details' + '/' + socialShareData.id,
                quote: socialShareData?.job_title
              }}
              twitterOptions={{
                url: process.env.REACT_APP_DOMAIN + 'jobs/jobs-details' + '/' + socialShareData.id,
                title: socialShareData?.job_title,
                hashtags: ['jobs', 'trends']
              }}
              linkedInOptions={{
                title: socialShareData?.job_title,
                summary: socialShareData?.job_info,
                url: process.env.REACT_APP_DOMAIN + 'jobs/jobs-details' + '/' + socialShareData.id,
                source:
                  process.env.REACT_APP_DOMAIN + 'jobs/jobs-details' + '/' + socialShareData.id
              }}
            />
          </Modal.Body>
        </Modal>
      </section>
      {/* recommended job modal */}
      <RecomendModal handleClose3={handleClose3} show3={show3} jobId={jobId} />
      {/* end of modal */}
      {/* <FooterMain className={'colour-bg'} /> */}
      {/* apply form */}
      <ApplyForm
        onChangeHandler={onChangeHandler}
        setIsChecked={setIsChecked}
        isChecked={isChecked}
        submitHandler={submitHandler}
        applyForm={applyForm}
        show2={show2}
        handleClose2={handleClose2}
        jobTitle={jobTitle}
        resumeSelect={resumeSelect}
        fileSizeValid={fileSizeValid}
        getUserDetail={getUserDetail}
      />

      {/* {loading ? <Loader /> : <></>} */}
    </>
  );
}

export default FresherJob;
