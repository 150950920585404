import { useState, useEffect } from 'react';
import { Col, Container, Dropdown, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import HeaderLogin from '../../commoncomponents/HeaderLogin';
import TopTabbings from './TopTabbings';
import SuggestMentor from '../../commoncomponents/newsfeeds/SuggestMentor';
import GroupDiscover from '../../commoncomponents/newsfeeds/GroupDiscover';
import WhatsNew from '../../commoncomponents/newsfeeds/WhatsNew';
import Upcoming from '../../commoncomponents/newsfeeds/Upcoming';
import NewsSidebar from '../../commoncomponents/newsfeeds/NewsSidebar';
import * as APISERVICES from '../../../services/AuthServices';
import * as APISERVICE from '../../../services/UserServices';

import Loader from '../../commoncomponents/loader/loader-large';
import showNotification from '../../../services/NotificationService';
import { MiniFooter } from '../../MiniFooter';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import useDocumentTitle from '../../../globals/useDocumentTitle';

function FriendRequests() {
  useDocumentTitle('AlmaBay');
  const [friendReqList, setFriendReqList] = useState([]);
  const [sendedReqList, setSendedReqList] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    friendRequest();
    sendedRequests();
  }, []);

  const friendRequest = () => {
    setLoading(true);
    APISERVICES.getPendingReq().then((res) => {
      setLoading(false);
      if (res?.status === 200) {
        setFriendReqList(res?.data?.data ?? []);
      }
    });
  };

  const sendedRequests = () => {
    setLoading(true);
    APISERVICES.getSendedRequestList().then((res) => {
      setLoading(false);
      if (res?.status === 200) {
        setSendedReqList(res?.data?.data);
      }
    });
  };

  const confirmHandler = (id) => {
    APISERVICES.acceptFriendRequest({ follower_id: id }).then((res) => {
      if (res?.status === 200) {
        friendRequest();
        showNotification('success', res?.data?.message);
      }
    });
  };

  const deleteHandler = (id) => {
    if (confirm('Are you sure you want to delete request ?') === true) {
      APISERVICES.rejectFriendRequest({ follower_id: id }).then((res) => {
        if (res?.status === 200) {
          friendRequest();
          showNotification('success', res?.data?.message);
        }
      });
    } else {
      return;
    }
  };


  const cancelRequest = (e, id) => {
    if (confirm('Do you want to remove friend request?') === true) {
      e.preventDefault();
      let body = {
        following_id: id
      };
      let formData = new FormData();
      for (let i in body) {
        formData.append(`${i}`, body[i]);
      }
      APISERVICE.addFriend(formData).then((res) => {
        if (res?.status === 200) {
          sendedRequests();
        } else {
          showNotification('success', res?.data?.message);
        }
      });
    }
  };
  const renderPopover = (props) => {
    let popOverData = props?.popper?.state?.options?.data;

    return (
      <Popover className="group-loader-card" id={`popover-basic`} {...props}>
        {/* <Popover.Header as="h3">Popover right</Popover.Header> */}
        <Popover.Body>
          <div className="loader-tooltip">
            <div className="loader-cover">
              <img
                src={
                  popOverData?.actual_cover_url
                    ? popOverData?.actual_cover_url
                    : require('../../../assets/images/default-cover.png')
                }
                alt="img"
                className="loader-image"
              />
            </div>
            <div className="people-know-loader-dis d-flex align-items-top">
              <div className="people-knw-top d-flex align-items-center">
                <div className="people-icn me-3">
                  <img
                    src={
                      popOverData?.avatar?.complete_url
                        ? popOverData?.avatar?.complete_url
                        : popOverData?.avatar_url
                    }
                    alt="img"
                    className="loader-image"
                  />
                </div>
                <div className="people-hd">
                  <h3>{popOverData?.name}</h3>
                </div>
              </div>
            </div>
            <div className="people-knw-dis-popover">
              {popOverData?.CurrentSchoolInfo?.length !== 0
                ? popOverData?.CurrentSchoolInfo[0]?.institute_name && (
                  <p>
                    <>
                      {' '}
                      <i className="fas fa-graduation-cap me-1"></i>{' '}
                      {popOverData?.CurrentSchoolInfo[0]?.currently_studing_here == '0'
                        ? 'Studying at '
                        : 'Studied'}
                      <Link to="#"> {popOverData?.CurrentSchoolInfo[0]?.institute_name}</Link>
                      {popOverData?.CurrentSchoolInfo[0]?.currently_studing_here == '0' ? (
                        <></>
                      ) : (
                        <>
                          in <Link to="#">{popOverData?.to_year}</Link>
                        </>
                      )}
                    </>
                  </p>
                )
                : ''}
              {/**have to add key for this, key not added from backend */}
              {popOverData?.CurrentWorkInfo?.length !== 0 && (
                <>
                  {' '}
                  {popOverData?.CurrentWorkInfo[0]?.currently_working_here == 0 ? (
                    <p>
                      <i className="fas fa-briefcase me-1"></i>Working as{' '}
                      <Link to="#">{popOverData?.CurrentWorkInfo[0]?.designation}</Link>{' '}
                      {popOverData?.CurrentWorkInfo[0]?.industry_name && (
                        <>
                          {' '}
                          {' at '}
                          <Link to="#">{popOverData?.CurrentWorkInfo[0]?.industry_name}</Link>{' '}
                        </>
                      )}
                    </p>
                  ) : (
                    <p>
                      <i className="fas fa-briefcase me-1"></i>Worked as{' '}
                      <Link to="#">{popOverData?.CurrentWorkInfo[0]?.designation}</Link>{' '}
                      {popOverData?.CurrentWorkInfo[0]?.industry_name && (
                        <>
                          {' '}
                          {' at '}
                          <Link to="#">
                            {popOverData?.CurrentWorkInfo[0]?.industry_name}
                          </Link> in {popOverData?.CurrentWorkInfo[0]?.year_to}
                        </>
                      )}
                    </p>
                  )}
                </>
              )}
              <div className="popover-inr-btn">
                <button
                  className="popovr-blk-btn me-1"
                  onClick={(e) => cancelRequest(e, popOverData?.id)}
                >
                  Requested
                </button>
                <button className="popovr-yellow-btn">Follow Mentor</button>
              </div>
            </div>
          </div>
        </Popover.Body>
      </Popover>
    );
  };

  const renderReqPopover = (props) => {
    let popOverData = props?.popper?.state?.options?.data;

    return (
      <Popover className="group-loader-card" id={`popover-basic`} {...props}>
        {/* <Popover.Header as="h3">Popover right</Popover.Header> */}
        <Popover.Body>
          <div className="loader-tooltip">
            <div className="loader-cover">
              <img
                src={
                  popOverData?.actual_cover_url
                    ? popOverData?.actual_cover_url
                    : require('../../../assets/images/default-cover.png')
                }
                alt="img"
                className="loader-image"
              />
            </div>
            <div className="people-know-loader-dis d-flex align-items-top">
              <div className="people-knw-top d-flex align-items-center">
                <div className="people-icn me-3">
                  <img
                    src={
                      popOverData?.avatar?.complete_url
                        ? popOverData?.avatar?.complete_url
                        : popOverData?.avatar_url
                    }
                    alt="img"
                    className="loader-image"
                  />
                </div>
                <div className="people-hd">
                  <h3>{popOverData?.name}</h3>
                </div>
              </div>
            </div>
            <div className="people-knw-dis-popover">
              {popOverData?.CurrentSchoolInfo?.length !== 0
                ? popOverData?.CurrentSchoolInfo[0]?.institute_name && (
                  <p>
                    <>
                      {' '}
                      <i className="fas fa-graduation-cap me-1"></i>{' '}
                      {popOverData?.CurrentSchoolInfo[0]?.currently_studing_here == '0'
                        ? 'Studying at '
                        : 'Studied'}
                      <Link to="#"> {popOverData?.CurrentSchoolInfo[0]?.institute_name}</Link>
                      {popOverData?.CurrentSchoolInfo[0]?.currently_studing_here == '0' ? (
                        <></>
                      ) : (
                        <>
                          in <Link to="#">{popOverData?.to_year}</Link>
                        </>
                      )}
                    </>
                  </p>
                )
                : ''}
              {/**have to add key for this, key not added from backend */}
              {popOverData?.CurrentWorkInfo?.length !== 0 && (
                <>
                  {' '}
                  {popOverData?.CurrentWorkInfo[0]?.currently_working_here == 0 ? (
                    <p>
                      <i className="fas fa-briefcase me-1"></i>Working as{' '}
                      <Link to="#">{popOverData?.CurrentWorkInfo[0]?.designation}</Link>{' '}
                      {popOverData?.CurrentWorkInfo[0]?.industry_name && (
                        <>
                          {' '}
                          {' at '}
                          <Link to="#">{popOverData?.CurrentWorkInfo[0]?.industry_name}</Link>{' '}
                        </>
                      )}
                    </p>
                  ) : (
                    <p>
                      <i className="fas fa-briefcase me-1"></i>Worked as{' '}
                      <Link to="#">{popOverData?.CurrentWorkInfo[0]?.designation}</Link>{' '}
                      {popOverData?.CurrentWorkInfo[0]?.industry_name && (
                        <>
                          {' '}
                          {' at '}
                          <Link to="#">
                            {popOverData?.CurrentWorkInfo[0]?.industry_name}
                          </Link> in {popOverData?.CurrentWorkInfo[0]?.year_to}
                        </>
                      )}
                    </p>
                  )}
                </>
              )}
            </div>
          </div>
        </Popover.Body>
      </Popover>
    );
  };

  return (
    <>
      <HeaderLogin friendReqList={friendReqList} />
      <div className="header-lnk-feeds">
        <div className="container">
          <TopTabbings state={'social'} />
        </div>
      </div>
      <section className="news-feed-sec bg-grey py-4">
        <Container>
          <Row>
            <Col xs={12} lg={2}>
              <NewsSidebar state="2" />
            </Col>

            <Col xs={12} lg={9} xl={7} className="mb-4">
              {/* friend request sended card */}
              {/* reuested list */}
              {friendReqList?.length !== 0 && (
                <div className="friend-request p-3 bg-white mb-3">
                  <h4 className="mb-4">
                    Respond to Your {friendReqList?.length || 0} Friend Requests
                  </h4>
                  {friendReqList?.map((data, index) => (
                    <div
                      key={index}
                      className="friend-request-card d-flex align-items-center justify-content-between"
                    >
                      <div className="friend-request-detail d-flex align-items-top">
                        <Link
                          to={`/user/timeline/${data?.encrypted_id}`}
                          className="friend-request-icn me-3"
                        >
                          <OverlayTrigger
                            trigger={['hover', 'focus']}
                            placement="bottom"
                            delay={{ show: 500, hide: 1500 }}
                            data={data}
                            popperConfig={{
                              data
                            }}
                            shouldUpdatePosition={true}
                            overlay={renderPopover}
                          >
                            <img
                              src={
                                data?.avatar && data?.avatar?.complete_url
                                  ? data?.avatar?.complete_url
                                  : require('../../../assets/images/default-male-avatar.png')
                              }
                              alt="img"
                            />
                          </OverlayTrigger>
                        </Link>
                        <div className="fried-req-dis">
                          <h4>
                            <OverlayTrigger
                              trigger={['hover', 'focus']}
                              placement="bottom"
                              delay={{ show: 500, hide: 1500 }}
                              data={data}
                              popperConfig={{
                                data
                              }}
                              shouldUpdatePosition={true}
                              overlay={renderReqPopover}
                            >
                              <Link to={`/user/timeline/${data?.encrypted_id}`}>{data?.name}</Link>
                            </OverlayTrigger>
                          </h4>
                          {data?.CurrentWorkInfo?.length !== 0
                            ? data?.CurrentWorkInfo[0]?.industry_name && (
                              <p>
                                <i className="fas fa-suitcase me-2"></i>

                                {data?.CurrentWorkInfo[0]?.industry_name}
                              </p>
                            )
                            : ''}
                          {data?.CurrentSchoolInfo?.length !== 0
                            ? data?.CurrentSchoolInfo[0]?.institute_name && (
                              <p>
                                <i className="fas fa-graduation-cap me-2"></i>
                                {data?.CurrentSchoolInfo[0]?.currently_studing_here == '0'
                                  ? 'Studying at '
                                  : 'Studied'}
                                {data?.CurrentSchoolInfo[0]?.institute_name}
                              </p>
                            )
                            : ''}
                        </div>
                      </div>
                      <div className="">
                        <button
                          type="button"
                          className="btn btn-warning m-1"
                          onClick={() => {
                            confirmHandler(data?.user_id);
                          }}
                        >
                          Confirm
                        </button>
                        <button
                          type="button"
                          className="btn btn-light"
                          onClick={() => {
                            deleteHandler(data?.user_id);
                          }}
                        >
                          Delete Request
                        </button>
                      </div>
                    </div>
                  ))}
                </div>
              )}

              {/* Friend Request Sent */}
              {sendedReqList && sendedReqList?.length !== 0 ? (
                <div className="friend-request p-3 bg-white">
                  <h4 className="mb-4">Friend Request Sent</h4>
                  {sendedReqList?.map((data, index) => (
                    <div
                      key={index}
                      className="friend-request-card d-flex align-items-center justify-content-between"
                    >
                      <div className="friend-request-detail d-flex align-items-top">
                        <Link
                          to={`/user/timeline/${data?.encrypted_id}`}
                          className="friend-request-icn me-3"
                        >
                          <OverlayTrigger
                            trigger={['hover', 'focus']}
                            placement="bottom"
                            delay={{ show: 500, hide: 1500 }}
                            data={data}
                            popperConfig={{
                              data
                            }}
                            shouldUpdatePosition={true}
                            overlay={renderPopover}
                          >
                            <img
                              src={
                                data?.avatar && data?.avatar?.complete_url
                                  ? data?.avatar?.complete_url
                                  : require('../../../assets/images/default-male-avatar.png')
                              }
                              alt="img"
                            />
                          </OverlayTrigger>
                        </Link>
                        <div className="fried-req-dis">
                          <h4>
                            <OverlayTrigger
                              trigger={['hover', 'focus']}
                              placement="bottom"
                              delay={{ show: 500, hide: 1500 }}
                              data={data}
                              popperConfig={{
                                data
                              }}
                              shouldUpdatePosition={true}
                              overlay={renderPopover}
                            >
                              <Link to={`/user/timeline/${data?.encrypted_id}`}>{data?.name}</Link>
                            </OverlayTrigger>
                          </h4>
                          {data?.CurrentWorkInfo?.length !== 0
                            ? data?.CurrentWorkInfo[0]?.industry_name && (
                              <p>
                                <i className="fas fa-suitcase me-2"></i>

                                {data?.CurrentWorkInfo[0]?.industry_name}
                              </p>
                            )
                            : ''}
                          {data?.CurrentSchoolInfo?.length !== 0
                            ? data?.CurrentSchoolInfo[0]?.institute_name && (
                              <p>
                                <i className="fas fa-graduation-cap me-2"></i>
                                {data?.CurrentSchoolInfo[0]?.currently_studing_here == '0'
                                  ? 'Studying at '
                                  : 'Studied'}
                                {data?.CurrentSchoolInfo[0]?.institute_name}
                              </p>
                            )
                            : ''}
                        </div>
                      </div>
                      <div className="frien-req-btn">
                        <Dropdown>
                          <Dropdown.Toggle variant="success" id="dropdown-basic">
                            <i className="fas fa-user-plus me-1"></i> Friend Request Sent
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Item as={Link} to={`/user/timeline/${data?.encrypted_id}`}>
                              View Profile
                            </Dropdown.Item>
                            <Dropdown.Item onClick={(e) => cancelRequest(e, data?.id)}>
                              Cancel Request
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <>Data not found</>
              )}
            </Col>

            {/*sended request */}

            <Col xl={{ span: 3, offset: 0 }} lg={{ span: 9, offset: 2 }}>
              <div>
                <SuggestMentor />
              </div>
              <div>
                <GroupDiscover />
              </div>
              <div>
                <WhatsNew />
              </div>
              <div>
                <Upcoming />
              </div>
              <MiniFooter />
            </Col>
          </Row>
        </Container>
      </section>
      {/* loader */}
      {loading ? <Loader /> : <></>}
    </>
  );
}

export default FriendRequests;
