import { useState, useEffect } from 'react';
import { Col, Container, Dropdown, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import HeaderLogin from '../../commoncomponents/HeaderLogin';
import TopTabbings from './TopTabbings';
import WhatsNew from '../../commoncomponents/newsfeeds/WhatsNew';
import Upcoming from '../../commoncomponents/newsfeeds/Upcoming';
import Form from 'react-bootstrap/Form';
import Accordion from 'react-bootstrap/Accordion';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Button from 'react-bootstrap/Button';
import * as index from '../../commoncomponents/skelton/allskelton';
import useDocumentTitle from '../../../globals/useDocumentTitle';
import { getMentorDirectoryList, getMentorShipDropDown } from '../../../services/AuthServices';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import {
  citiesDropDown,
  followFriend,
  getAvatarImage,
  getIndustryDrop
} from '../../../services/UserServices';
import AsyncSelect from 'react-select/async';
import showNotification from '../../../services/NotificationService';
import { useNavigate } from 'react-router-dom';
import AskQuestionModal from './AskQuestionModal';
import SendMessageModal from './SendMessageModal';

function FindMentors() {
  useDocumentTitle('Mentors Directory | AlmaBay');

  const [loading, setLoading] = useState(false);
  const [list, setList] = useState([]);
  const [mentorType, setMentorType] = useState('all');
  const [name, setName] = useState('');
  const [industry, setIndustry] = useState([]);
  const [mentorShip, setMentorShip] = useState([]);
  const [location, setLocation] = useState([]);
  let industryIds = industry?.map((data) => data?.id);
  const mentorAreaIds = mentorShip?.map((data) => data?.id);
  const locationIds = location?.map((data) => data?.id);
  const [userImage, setUserImage] = useState('');
  const navigate = useNavigate();
  const [activeClassState, setIsActiveClassState] = useState('lst-view');
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleClose2 = () => setShow2(false);
  const [show2, setShow2] = useState(false);
  const handleShow2 = () => setShow2(true);
  const [showFilter, setShowFilter] = useState(false);
  const handleCloseFilter = () => setShowFilter(false);
  const handleShowFilter = () => setShowFilter(true);
  const [buttonLoader, setShowButtonLoader] = useState(true);
  const [followId, setFollowId] = useState('');
  const [mentorId, setMentorId] = useState('');
  const [imgLoading, setImgLoading] = useState(false);
  const [filterLoader, setFilterLoader] = useState(false);
  const [inputMsg, setInputMsg] = useState('Please enter atleast 2 characters');
  const [loadInput, setLoadInput] = useState(false);

  useEffect(() => {
    getMentorDirectory();
  }, []);

  const getMentorDirectory = () => {
    setLoading(true);
    getMentorDirectoryList(mentorType, name, industryIds, mentorAreaIds, locationIds).then(
      (res) => {
        if (res?.status === 200) {
          let arr = [];
          setList(res?.data?.detail);
          setShowButtonLoader(true);
          setLoading(false);
          setImgLoading(true);
          setFilterLoader(false);
          res?.data?.detail?.mentors?.map((data) => {
            getAvatarImage(data?.avatar_id).then((res) => {
              if (res?.status == 200) {
                setImgLoading(false);
                if (data?.avatar_id != 0) {
                  arr[data?.avatar_id] = res?.data?.detail[0].path;
                } else {
                  arr[data?.avatar_id] = res?.data?.detail;
                }
              }
              setUserImage({ ...userImage, arr });
            });
          });
        }
      }
    );
  };

  const loadIndustryOptions = async (inputValue) => {
    setLoading(true);
    return getIndustryDrop(inputValue).then((res) => {
      setLoading(false);
      if (res?.status === 200) {
        let tempArr = [];
        if (res?.data?.data) {
          const data = res?.data?.data;
          data?.map((data) => {
            tempArr.push({
              id: data?.id,
              label: data?.name,
              value: data?.name
            });
          });
        }
        return tempArr;
      }
    });
  };

  const loadMentorShipOption = (inputValue) => {
    return getMentorShipDropDown(inputValue).then((res) => {
      if (res?.status === 200) {
        let tempArr = [];
        const data = res?.data?.data;
        data?.map((item) => {
          tempArr.push({
            id: item?.id,
            label: item?.name,
            value: item?.name
          });
        });

        return tempArr;
      }
    });
  };

  const loadLocationOptions = async (inputValue = 'Mohali') => {
    setLoading(true);
    setLoading(true);
    if (inputValue.length >= 2) {
      return citiesDropDown(inputValue).then((res) => {
        setLoading(false);
        setLoadInput(false);
        if (res?.status === 200) {
          let tempArr = [];
          if (res?.data?.data) {
            const data = res?.data?.data;
            data?.map((data) => {
              tempArr.push({
                id: data?.city_id,
                label: data?.city,
                value: data?.city
              });
            });
          }
          if (res?.data?.data == '') {
            setInputMsg('no match found');
          }

          return tempArr;
        }
      });
    }
  };

  const handleReset = () => {
    setLoading(true);
    getMentorDirectoryList().then((res) => {
      setLoading(false);
      if (res?.status === 200) {
        setList(res?.data?.detail);
      }
    });
    setMentorType('all');
    setName('');
    setLocation([]);
    setMentorShip([]);
    setIndustry([]);
  };

  const resquestHandler = (e, id, type) => {
    setFollowId(id);

    if (type == 'unfollow') {
      if (confirm('Do you want to remove Mentor?') === true) {
        e.preventDefault();
        setShowButtonLoader(false);
        let body = {
          following_id: id
        };
        setLoading(true);
        followFriend(body).then((res) => {
          setLoading(false);
          if (res?.status == 200) {
            getMentorDirectory();
          } else {
            showNotification('success', res?.data?.message);
          }
        });
      } else {
        return;
      }
    } else {
      e.preventDefault();
      setShowButtonLoader(false);
      let body = {
        following_id: id
      };
      setLoading(true);
      followFriend(body).then((res) => {
        setLoading(false);
        if (res?.status == 200) {
          getMentorDirectory();
        } else {
          showNotification('success', res?.data?.message);
        }
      });
    }
  };

  const handleNavigateToMyProfie = (id) => {
    navigate(`/mentors/profile/${id}`);
  };
  return (
    <>
      <HeaderLogin />
      <div className="header-lnk-feeds">
        <div className="container">
          <TopTabbings state={'mentor'} />
        </div>
      </div>

      <section className="jobs-infomation py-5">
        <Container>
          <Row>
            <Col md={4}>
              <div className="mentor-count-card text-center">
                <img
                  src={require('../../../assets/images/participating.png')}
                  className="mb-3"
                  alt="img"
                />
                <h3>10,000+</h3>
                <h6>Mentors</h6>
              </div>
            </Col>
            <Col md={4}>
              <div className="mentor-count-card text-center">
                <img src={require('../../../assets/images/post.png')} className="mb-3" alt="img" />
                <h3>120+</h3>
                <h6>Mentorship Areas</h6>
              </div>
            </Col>
            <Col md={4}>
              <div className="mentor-count-card text-center">
                <img
                  src={require('../../../assets/images/jobs-open.png')}
                  className="mb-3"
                  alt="img"
                />
                <h3>100+</h3>
                <h6>Locations</h6>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="news-feed-sec bg-grey py-4">
        <Container>
          <Row>
            <Col xs={12} lg={3} md={12}>
              <Button
                className="mobile-filter-btn d-block d-lg-none "
                variant="primary"
                onClick={handleShowFilter}
              >
                <i className="fas fa-filter"></i>
              </Button>
              <Offcanvas show={showFilter} onHide={handleCloseFilter} className="mobile-filter">
                <Offcanvas.Body>
                  <div className="fiter-card">
                    <div className="filter-hd-card d-flex align-items-center justify-content-between">
                      <div className="filter-hd">
                        <h4>Filter result</h4>
                      </div>
                      <div className="filter-btn">
                        <Link to="#">
                          <i className="fas fa-filter"></i>
                        </Link>
                        <Link to="#">
                          <i className="fas fa-sync-alt"></i>
                        </Link>
                      </div>
                    </div>
                    <div className="filter-select-lst">
                      <Accordion defaultActiveKey={['0', '1', '2', '3', '4']} alwaysOpen flush>
                        <Accordion.Item eventKey="0">
                          <Accordion.Header>Mentor Type</Accordion.Header>
                          <Accordion.Body>
                            {['radio'].map((type) => (
                              <div key={`inline-${type}`}>
                                <Form.Check
                                  label="All Mentors"
                                  name="group1"
                                  type={type}
                                  id={`inline-${type}-1`}
                                  className="mb-1"
                                  onChange={() => setMentorType('all')}
                                />
                                <Form.Check
                                  label="Verified Mentors"
                                  name="group1"
                                  type={type}
                                  id={`inline-${type}-2`}
                                  className="mb-1"
                                  onChange={() => setMentorType('verified')}
                                />
                                <Form.Check
                                  label="Suggested Mentors"
                                  name="group1"
                                  type={type}
                                  id={`inline-${type}-3`}
                                  className="mb-1"
                                  onChange={() => setMentorType('suggested')}
                                />
                                <Form.Check
                                  label="Connected Mentors"
                                  name="group1"
                                  type={type}
                                  id={`inline-${type}-4`}
                                  className="mb-1"
                                  onChange={() => setMentorType('connected')}
                                />
                                <Form.Check
                                  label="Others Mentors"
                                  name="group1"
                                  type={type}
                                  id={`inline-${type}-5`}
                                  className="mb-1"
                                  onChange={() => setMentorType('others')}
                                />
                              </div>
                            ))}
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1">
                          <Accordion.Header>By Name</Accordion.Header>
                          <Accordion.Body>
                            <Form.Control
                              type="Name"
                              placeholder="Search By Name"
                              className="w-100 border-0 border-bottom border-grey"
                              onChange={(e) => setName(e.target.value)}
                              value={name}
                            />
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="2">
                          <Accordion.Header>Industry</Accordion.Header>
                          <Accordion.Body>
                            <AsyncSelect
                              isMulti
                              cacheOptions
                              defaultOptions
                              value={industry}
                              getOptionLabel={(e) => e.label}
                              getOptionValue={(e) => e.value}
                              loadOptions={loadIndustryOptions}
                              className="basic-single mt-1"
                              name="location"
                              onChange={setIndustry}
                              components={{
                                DropdownIndicator: () => null,
                                IndicatorSeparator: () => null
                              }}
                            />
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="3">
                          <Accordion.Header>Mentorship Area</Accordion.Header>
                          <Accordion.Body>
                            <AsyncSelect
                              isMulti
                              value={mentorShip}
                              getOptionLabel={(e) => e.label}
                              getOptionValue={(e) => e.value}
                              loadOptions={loadMentorShipOption}
                              className="basic-single mt-1"
                              onChange={setMentorShip}
                              components={{
                                DropdownIndicator: () => null,
                                IndicatorSeparator: () => null
                              }}
                            />
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="4">
                          <Accordion.Header>Location</Accordion.Header>
                          <Accordion.Body>
                            <AsyncSelect
                              isMulti
                              cacheOptions
                              defaultOptions
                              value={location}
                              // menuIsOpen={locationSearch !== '' ? true : false}
                              getOptionLabel={(e) => e.label}
                              getOptionValue={(e) => e.value}
                              loadOptions={loadLocationOptions}
                              // onInputChange={(newValue) => setLocationSearch(newValue)}
                              className="basic-single mt-1"
                              name="location"
                              onChange={setLocation}
                              noOptionsMessage={() => inputMsg}
                              onInputChange={(newValue) => {
                                newValue?.length <= 2 &&
                                  setInputMsg('Please enter atleast 2 characters');
                              }}
                              components={{
                                DropdownIndicator: () => null,
                                IndicatorSeparator: () => null,
                                LoadingIndicator: () => null
                              }}
                            />
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                      <div className="sider-bar-filter p-3 text-end">
                        <button
                          className="accnt-btn"
                          type="button"
                          onClick={() => {
                            getMentorDirectory();
                            setFilterLoader(true);
                          }}
                        >
                          Search {filterLoader ? <i className="fa fa-spinner fa-spin "></i> : ''}
                        </button>
                        <button className="dark-blu-btn ms-2" onClick={handleReset}>
                          Reset
                        </button>
                      </div>
                    </div>
                  </div>
                </Offcanvas.Body>
              </Offcanvas>
              <div className="fiter-card d-none d-lg-block">
                <div className="filter-hd-card d-flex align-items-center justify-content-between">
                  <div className="filter-hd">
                    <h4>Filter result</h4>
                  </div>
                  <div className="filter-btn">
                    <Link to="#">
                      <i className="fas fa-filter"></i>
                    </Link>
                    <Link to="#">
                      <i className="fas fa-sync-alt"></i>
                    </Link>
                  </div>
                </div>
                <div className="filter-select-lst">
                  <Accordion defaultActiveKey={['0', '1', '2', '3', '4']} alwaysOpen flush>
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>Mentor Type</Accordion.Header>
                      <Accordion.Body>
                        {['radio'].map((type) => (
                          <div key={`inline-${type}`}>
                            <Form.Check
                              label="All Mentors"
                              name="group1"
                              type={type}
                              id={`inline-${type}-1`}
                              className="mb-1"
                              onChange={() => setMentorType('all')}
                              checked={mentorType == 'all'}
                            />
                            <Form.Check
                              label="Verified Mentors"
                              name="group1"
                              type={type}
                              id={`inline-${type}-2`}
                              className="mb-1"
                              onChange={() => setMentorType('verified')}
                              checked={mentorType == 'verified'}
                            />
                            <Form.Check
                              label="Suggested Mentors"
                              name="group1"
                              type={type}
                              id={`inline-${type}-3`}
                              className="mb-1"
                              onChange={() => setMentorType('suggested')}
                              checked={mentorType == 'suggested'}
                            />
                            <Form.Check
                              label="Connected Mentors"
                              name="group1"
                              type={type}
                              id={`inline-${type}-4`}
                              className="mb-1"
                              checked={mentorType == 'connected'}
                              onChange={() => setMentorType('connected')}
                            />
                            <Form.Check
                              label="Others Mentors"
                              name="group1"
                              type={type}
                              id={`inline-${type}-5`}
                              className="mb-1"
                              checked={mentorType == 'others'}
                              onChange={() => setMentorType('others')}
                            />
                          </div>
                        ))}
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                      <Accordion.Header>By Name</Accordion.Header>
                      <Accordion.Body>
                        <Form.Control
                          type="Name"
                          placeholder="Search By Name"
                          className="w-100 border-0 border-bottom border-grey"
                          onChange={(e) => setName(e.target.value)}
                          value={name}
                        />
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                      <Accordion.Header>Industry</Accordion.Header>
                      <Accordion.Body>
                        <AsyncSelect
                          isMulti
                          cacheOptions
                          defaultOptions
                          value={industry}
                          getOptionLabel={(e) => e.label}
                          getOptionValue={(e) => e.value}
                          loadOptions={loadIndustryOptions}
                          className="basic-single mt-1"
                          name="location"
                          onChange={setIndustry}
                          components={{
                            DropdownIndicator: () => null,
                            IndicatorSeparator: () => null
                          }}
                          placeholder="Search By Industry"
                        />
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="3">
                      <Accordion.Header>Mentorship Area</Accordion.Header>
                      <Accordion.Body>
                        <AsyncSelect
                          isMulti
                          value={mentorShip}
                          getOptionLabel={(e) => e.label}
                          getOptionValue={(e) => e.value}
                          loadOptions={loadMentorShipOption}
                          className="basic-single mt-1"
                          onChange={setMentorShip}
                          components={{
                            DropdownIndicator: () => null,
                            IndicatorSeparator: () => null
                          }}
                          placeholder="Search By Mentorship Areas"
                        />
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="4">
                      <Accordion.Header>Location</Accordion.Header>
                      <Accordion.Body>
                        <AsyncSelect
                          isMulti
                          cacheOptions
                          defaultOptions
                          value={location}
                          // menuIsOpen={locationSearch !== '' ? true : false}
                          getOptionLabel={(e) => e.label}
                          getOptionValue={(e) => e.value}
                          loadOptions={loadLocationOptions}
                          // onInputChange={(newValue) => setLocationSearch(newValue)}
                          className="basic-single mt-1"
                          name="location"
                          onChange={setLocation}
                          noOptionsMessage={() => inputMsg}
                          onInputChange={(newValue) => {
                            newValue?.length <= 2 &&
                              setInputMsg('Please enter atleasst 2 characters');
                          }}
                          components={{
                            DropdownIndicator: () => null,
                            IndicatorSeparator: () => null,
                            LoadingIndicator: () => null
                          }}
                          placeholder="Search By Location"
                        />
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                  <div className="sider-bar-filter p-3 text-end">
                    <button
                      className="accnt-btn"
                      type="button"
                      onClick={() => {
                        getMentorDirectory();
                        setFilterLoader(true);
                      }}
                    >
                      Search {filterLoader ? <i className="fa fa-spinner fa-spin "></i> : ''}
                    </button>
                    <button className="dark-blu-btn ms-2" onClick={handleReset}>
                      Reset
                    </button>
                  </div>
                </div>
              </div>
            </Col>
            <Col xs={12} lg={6} md={12}>
              <div className="events_listing p-3 mb-3">
                <Row className="border-bottom pb-2">
                  <Col md={10} xs={10}>
                    <h5 className="heading">
                      Mentorship Providers|{' '}
                      <Link to="/user/mentors" className="more-btn">
                        {' '}
                        Click
                      </Link>{' '}
                      view your mentors.
                    </h5>
                  </Col>

                  <Col md={2} xs={2}>
                    <ul className="evnet-top-btn d-flex justify-content-end">
                      <li className="me-3">
                        <Link
                          title="Grid View"
                          to="#"
                          className={activeClassState == 'lst-view' ? 'active' : ''}
                          onClick={(e) => {
                            e?.preventDefault();
                            let element = document.getElementById('lst-id');
                            element.classList.remove('list-blk-card');
                            setIsActiveClassState('lst-view');
                          }}
                        >
                          {/* <img src={require('../../../../assets/images/thumb.png')} alt="img" /> */}

                          <i className="fas fa-th-large"></i>
                        </Link>
                      </li>
                      <li className="">
                        <Link
                          title="List View"
                          className={activeClassState == 'list-blk-card' ? 'active' : ''}
                          onClick={(e) => {
                            e?.preventDefault();
                            let element = document.getElementById('lst-id');
                            element.classList.add('list-blk-card');
                            setIsActiveClassState('list-blk-card');
                          }}
                        >
                          {/* <img src={require('../../../../assets/images/shorting.png')} alt="img" /> */}
                          <i className="fas fa-list"></i>
                        </Link>
                      </li>
                    </ul>
                  </Col>
                </Row>

                <div className="mentees-inr-card">
                  <div className="jobfair-inr-card">
                    <Row className="lst-view" id="lst-id">
                      {loading && buttonLoader ? (
                        <>
                          <Row>
                            {' '}
                            <Col md={4} sm={4} xs={6} key={index}>
                              {Array.from(Array(2), () => {
                                return <index.FriendsSkelton />;
                              })}
                            </Col>
                            <Col md={4} sm={4} xs={6} key={index}>
                              {Array.from(Array(2), () => {
                                return <index.FriendsSkelton />;
                              })}
                            </Col>
                            <Col md={4} sm={4} xs={6} key={index}>
                              {Array.from(Array(2), () => {
                                return <index.FriendsSkelton />;
                              })}
                            </Col>
                          </Row>
                        </>
                      ) : (
                        <>
                          {' '}
                          {list?.mentors?.length !== 0 ? (
                            <>
                              {list?.mentors?.map((data, index) => {
                                return (
                                  <Col md={4} sm={4} xs={6} key={index}>
                                    <div className="job-fair-card">
                                      <div
                                        className="job-fair-img"
                                        onClick={() =>
                                          handleNavigateToMyProfie(data?.encrypt_userid)
                                        }
                                      >
                                        {imgLoading ? (
                                          <div className="job-fair-img loading-skeleton">
                                            <img
                                              src={require('../../../assets/images/default-male-avatar.png')}
                                              alt=""
                                            />
                                          </div>
                                        ) : (
                                          <img
                                            src={userImage && userImage['arr'][data?.avatar_id]}
                                            // src={require('../../../assets/images/default-male-avatar.png')}
                                            alt="img"
                                          />
                                        )}

                                        <div className="job-fair-loc">
                                          {['top'].map((placement) => (
                                            <OverlayTrigger
                                              key={placement}
                                              placement={placement}
                                              overlay={
                                                <Tooltip id={`tooltip-${placement}`}>
                                                  {data?.mentees || data?.mentees != 0
                                                    ? 'Mentees:' + data?.mentees
                                                    : 'No Mentees'}
                                                </Tooltip>
                                              }
                                            >
                                              <span className="cale-icn">
                                                <i className="fa fa-graduation-cap"></i>
                                              </span>
                                            </OverlayTrigger>
                                          ))}
                                          {['top'].map((placement) => (
                                            <OverlayTrigger
                                              key={placement}
                                              placement={placement}
                                              overlay={
                                                <Tooltip id={`tooltip-${placement}`}>
                                                  {data?.location
                                                    ? data?.location
                                                    : 'Not Available'}
                                                </Tooltip>
                                              }
                                            >
                                              <span className="loc-icn">
                                                <i className="fas fa-map-marker-alt"></i>
                                              </span>
                                            </OverlayTrigger>
                                          ))}
                                        </div>
                                      </div>
                                      <div style={{ minHeight: '75px' }} className="job-fair-dis">
                                        <h4
                                          className="find-mentor-user"
                                          onClick={() =>
                                            handleNavigateToMyProfie(data?.encrypt_userid)
                                          }
                                        >
                                          {data?.name}
                                        </h4>
                                        {/* {data?.location && <p>{data?.location}</p>} */}

                                        {data?.mentor_status == 1 ? (
                                          <>
                                            {' '}
                                            <div className="job-fair-bottom">
                                              <Dropdown className="">
                                                <Dropdown.Toggle
                                                  variant="success"
                                                  id="dropdown-basic"
                                                >
                                                  Following
                                                  {buttonLoader
                                                    ? ''
                                                    : followId == data?.user_id && (
                                                        <i className="fa fa-spinner fa-spin me-2"></i>
                                                      )}
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu>
                                                  <Dropdown.Item
                                                    onClick={() =>
                                                      handleNavigateToMyProfie(data?.encrypt_userid)
                                                    }
                                                  >
                                                    View Profile
                                                  </Dropdown.Item>
                                                  <Dropdown.Item onClick={handleShow}>
                                                    Send Message
                                                  </Dropdown.Item>
                                                  <Dropdown.Item
                                                    onClick={() => {
                                                      handleShow2();
                                                      setMentorId(data?.user_id);
                                                    }}
                                                  >
                                                    Ask Question
                                                  </Dropdown.Item>
                                                  <Dropdown.Item
                                                    href="#/action-3"
                                                    onClick={(e) =>
                                                      resquestHandler(e, data?.user_id, 'unfollow')
                                                    }
                                                  >
                                                    Unfollow Mentor
                                                  </Dropdown.Item>
                                                </Dropdown.Menu>
                                              </Dropdown>
                                            </div>
                                          </>
                                        ) : (
                                          <>
                                            {' '}
                                            <div className="job-fair-bottom">
                                              {' '}
                                              <button
                                                className="wht-btn"
                                                type="button"
                                                onClick={(e) =>
                                                  resquestHandler(e, data?.user_id, 'follow')
                                                }
                                              >
                                                FOLLOW MENTOR{' '}
                                                {buttonLoader
                                                  ? ''
                                                  : followId == data?.user_id && (
                                                      <i className="fa fa-spinner fa-spin "></i>
                                                    )}
                                              </button>
                                            </div>
                                          </>
                                        )}
                                      </div>
                                    </div>
                                  </Col>
                                );
                              })}
                            </>
                          ) : (
                            <>
                              {!loading && (
                                <div className="p-5 text-center">
                                  <img
                                    src={require('../../../assets/images/small-error.png')}
                                    width="105px"
                                    alt="img"
                                  />
                                  <p>No Mentors found at the moment.</p>
                                </div>
                              )}
                            </>
                          )}
                        </>
                      )}
                    </Row>
                  </div>
                </div>
              </div>
            </Col>
            <Col xs={12} lg={3} md={12}>
              <div>
                <WhatsNew />
              </div>
              <div>
                <Upcoming />
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <AskQuestionModal
        handleClose2={handleClose2}
        show2={show2}
        setMentorId={setMentorId}
        mentorId={mentorId}
        // renderFunction={getEventList}
      />
      <SendMessageModal
        show3={show}
        // renderFunction={getEventList}
        handleClose3={handleClose}
        setMentorId={setMentorId}
        mentorId={mentorId}
      />
    </>
  );
}

export default FindMentors;
