/**
@copyright  :   ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
@author     :   Shiv Charan Panjeta 
 
All Rights Reserved.
Proprietary and confidential :  All information contained here in is, and remains
the property of ToXSL Technologies Pvt. Ltd. and it's partners.
Unauthorized copying of this file, via any medium is strictly prohibited.
*/
import { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { Link, useLocation } from 'react-router-dom';
import * as ADMINSERVICES from '../../../services/AuthServices';

export default function ViewCampusNews() {
  const [detail, setDetail] = useState('');
  const location = useLocation();
  let id = location?.state;

  useEffect(() => {
    apiCall(id);
  }, []);

  const apiCall = (id) => {
    ADMINSERVICES.getCampusNewsView(id).then((res) => {
      if (res?.status === 200) {
        setDetail(res?.data?.data?.info);
      }
    });
  };
  return (
    <div className="dash-main-content">
      <Container fluid>
        <div className="dash-page-content">
          <div className="dash-page-title">
            <Row>
              <Col xs={12} sm={12} md={12}>
                <div className="dash-title-bar d-flex align-items-center justify-content-between">
                  <div className="title-lft d-flex align-items-center">
                    <div className="dash-icn">
                      <i className="fas fa-file-word"></i>
                    </div>
                    <div className="dash-title-dis">
                      <h5>
                        Details of <span> Campus View</span>
                      </h5>
                      {/* <h5>
                          Job in <span>job one</span>
                        </h5> */}
                    </div>
                  </div>
                  <div className="title-rt-btn ">
                    <Link className="add-btn-grn" to="/admin/news/index/campus_news">
                      Back To Listing
                    </Link>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <div className="dash-content-card">
            <div className="view-detail-content">
              <Row>
                <Col md={12}>
                  <p>
                    <strong>Title:</strong>
                    {detail?.title}
                  </p>
                </Col>
              </Row>

              <Row>
                <Col md={12}>
                  <p>
                    <strong>Description:</strong>
                  </p>
                  <div
                    style={{ wordWrap: 'break-word' }}
                    dangerouslySetInnerHTML={{ __html: detail?.description }}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <p>
                    <strong>status:</strong>
                    {detail?.status == 1 ? 'Enable' : 'Disable'}
                  </p>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}
