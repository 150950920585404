/**
@copyright  :   ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
@author     :   Shiv Charan Panjeta 
 
All Rights Reserved.
Proprietary and confidential :  All information contained here in is, and remains
the property of ToXSL Technologies Pvt. Ltd. and it's partners.
Unauthorized copying of this file, via any medium is strictly prohibited.
*/
import * as apiServices from '../../services/AuthServices';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import Loader from '../../components/commoncomponents/loader/loader-large';
import showNotification from '../../services/NotificationService';
let $ = require('jquery');

function ReachAnalysis({ selectedTabName, getYearOfStablishMentValue }) {
  const [instituteId, setInstituteId] = useState('');
  const [yearId, setYearId] = useState('');
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [instituteList, setInstituteList] = useState([]);
  const [analysisLabels, setAnalysisLabel] = useState([]);
  const [almaBayCountDataSet, setAlmaBayCountDataSet] = useState([]);
  const [studentsDataSet, setStudentDataset] = useState([]);
  const [instData, setInstData] = useState();
  const [estYear, setEstYear] = useState([]);
  const [response, setResponse] = useState([]);
  const [detail, setDetail] = useState();

  useEffect(() => {
    if (selectedTabName == 'fourth') {
      //getInstituteList();
      // getInstituteDataByIdHandler();
      getInstituteDataEstYear();
    }
  }, [selectedTabName]);

  // User Institute

  const getInstituteList = () => {
    // apiServices.getAdminInstituteList().then((resp) => {
    //   if (resp?.status === 200) {
    //     const newArr = Object.entries(resp?.data?.data);
    //     const instList = newArr.map((item, i) => {
    //       return {
    //         id: item[0],
    //         name: item[1]
    //       };
    //     });
    //     setInstituteList(instList || []);
    //   }
    // });
  };

  const getInstituteDataByIdHandler = (id, year) => {
    var element = $('#formBasicInstituteValues').find('option:selected');
    var estyearSelectedValue = element.attr('data-estyear');

    setInstituteId(id);
    setInstData(estyearSelectedValue);

    $('#datepicker').val(estyearSelectedValue);

    apiServices.getInstituteAnalysisData(id, estyearSelectedValue).then((resp) => {
      resp?.data?.data?.map((data) => {
        if (!data) {
          return;
        } else {
          data.institute = id;
          data.estyear = estyearSelectedValue;
        }
      });

      setResponse(resp?.data?.data);
      // build graph data ...
      let labels = resp.data.data.map((item) => item.year);
      let almaCount = resp.data.data.map((item) => item.almabay_count);
      let studentDataSet = resp.data.data.map((item) => item.students);
      setAnalysisLabel(labels);
      setAlmaBayCountDataSet(almaCount);
      setStudentDataset(studentDataSet);
    });
  };

  // 4th TAB - Select Institute Data

  const getInstituteDataEstYear = () => {
    apiServices.getEstYear().then((resp) => {
      setEstYear(resp?.data?.institutes);

      setInstituteList(resp?.data?.institutes);
    });
  };

  // For graph
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top'
      },
      title: {
        display: false,
        text: 'Chart.js Bar Chart'
      }
    }
  };

  const data = {
    labels: analysisLabels,
    datasets: [
      {
        label: 'Students',
        data: studentsDataSet,
        backgroundColor: 'rgba(255, 99, 132, 0.5)'
      },
      {
        label: 'Almabay Actual Count',
        data: almaBayCountDataSet,
        backgroundColor: 'rgba(53, 162, 235, 0.5)'
      }
    ]
  };

  const handleChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...response];
    list[index][name] = value;
  };
  const handleSave = (e) => {
    apiServices.addAdminInstitute(JSON.stringify(response)).then((res) => {
      if (res?.status === 200) {
        showNotification('success', res?.data?.message);
        getInstituteDataEstYear();
      }
    });
  };
  return (
    <>
      <div className="reach-analysis">
        <div className="reach-analysis-hd mb-4">
          <Row>
            <Col md={6}>
              <Form.Group className="mb-3" controlId="formBasicInstituteValues">
                <Form.Label>Select Institute</Form.Label>
                <Form.Select
                  aria-label="Default select example"
                  onChange={(e) => {
                    getInstituteDataByIdHandler(
                      e.target.value,
                      $('option:selected', this).attr('data-estyear')
                    );
                  }}>
                  {instituteList &&
                    instituteList?.map((data, index) => {
                      return (
                        <option key={index} value={data?.institute_id} data-estyear={data?.estyear}>
                          {data?.name}
                        </option>
                      );
                    })}
                </Form.Select>
              </Form.Group>
            </Col>

            <Col md={6}>
              <Form.Group className="mb-3" controlId="datepicker">
                <Form.Label>Year of Establishment</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Year of Establishment"
                  disabled={true}
                  value={instData != 'NULL' ? instData?.estyear : ''}
                />
              </Form.Group>
            </Col>

            <Col xl={6} lg={12} md={12}>
              <div className="passout-box mt-4">
                <Row className="m-0">
                  <Col xl={6} lg={12} md={12}>
                    <ul className="list-unstyled p-0">
                      {analysisLabels?.map((data, index) => {
                        return (
                          <li key={index}>
                            <Form.Group className="mb-3" controlId="passoutyear">
                              <Form.Label>Passout Year</Form.Label>

                              <Form.Control type="text" disabled={true} value={data} />
                            </Form.Group>
                          </li>
                        );
                      })}
                    </ul>
                  </Col>
                  <Col xl={6} lg={12} md={12}>
                    <ul>
                      {studentsDataSet?.map((data, index) => {
                        return (
                          <li key={index}>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                              <Form.Label>Student Passed Out:</Form.Label>
                              <Form.Control
                                type="text"
                                name="students"
                                onChange={(e) => {
                                  handleChange(e, index);
                                }}
                                defaultValue={data}
                              />
                            </Form.Group>
                          </li>
                        );
                      })}
                    </ul>
                  </Col>
                </Row>
              </div>
            </Col>
            {analysisLabels?.length > 0 ? (
              <Col xl={6} lg={12} md={12}>
                <div className="dash-content-card mb-0">
                  <div className="pass-out-chart">
                    {analysisLabels.length > 0 && (
                      <Bar options={options} data={data} redraw={true} />
                    )}
                  </div>
                </div>
              </Col>
            ) : (
              ''
            )}

            <Col xs={12}>
              <div className="text-end mt-4">
                <button type="button" onClick={handleSave} className="main-btn-grn btn btn-primary">
                  Save Changes
                </button>
              </div>
            </Col>
          </Row>
        </div>
      </div>

      {loading ? <Loader /> : ''}
    </>
  );
}

export default ReachAnalysis;
