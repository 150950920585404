import moment from 'moment';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Alert, Col, Container, Dropdown, Row } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import DatePicker from 'react-datepicker';
import Cropper from 'react-easy-crop';
import { useSelector } from 'react-redux';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import defaultEvent from '../../../../assets/images/event.png';
import { getCroppedImg } from '../../../../globals/canvasUtils';
import * as constants from '../../../../globals/constants';
import {
  filterPassedTime,
  imageExtension,
  loadEventCategoryOptions
} from '../../../../globals/helper';
import useDocumentTitle from '../../../../globals/useDocumentTitle';
import { getInviteList } from '../../../../services/NewsServices';
import showNotification from '../../../../services/NotificationService';
import {
  addActivity,
  addNewSponser,
  deleteActivity,
  deleteSponsor,
  getEventActivityDetail,
  getEventActivityImage,
  getEventDetail,
  getEventPeople,
  getEventTickets,
  getSponerDetail,
  removeInvite,
  sendEventInvite,
  userEventActivity,
  userParticipate
} from '../../../../services/SocialServices';
import {
  changeEventCoverImage,
  changeEventImage,
  removeEventCoverImage
} from '../../../../services/UserServices';
import HeaderInner from '../../../commoncomponents/HeaderInner';
import HeaderLogin from '../../../commoncomponents/HeaderLogin';
import Loader from '../../../commoncomponents/loader/loader-large';
import CampusnewsText from '../../../commoncomponents/newsfeeds/CampusnewsText';
import FollowCompany from '../../../commoncomponents/newsfeeds/FollowCompany';
import GroupDiscover from '../../../commoncomponents/newsfeeds/GroupDiscover';
import InvitePeople from '../../../commoncomponents/newsfeeds/InvitePeople';
import LeftSidebarPrivacyPolicy from '../../../commoncomponents/newsfeeds/LeftSidebarPrivacyPolicy';
import Upcoming from '../../../commoncomponents/newsfeeds/Upcoming';
import WhatsNew from '../../../commoncomponents/newsfeeds/WhatsNew';
import SocialShare from '../SocialShare';
import TopTabbings from '../TopTabbings';
import CreateEventSponser from './CreateEventSponser';
import EditEventDetails from './EditEventDetails';
import EventDiscussions from './EventDiscussions';
import EventTickets from './EventTickets';
import EventsOverview from './EventsOverview';
import EventsParticipatePeople from './EventsParticipatePeople';
import InvitePeopleModal from './InvitePeopleModal';
import ShareSocialModal from './ShareSocialModal';

function EventsDetail() {
  useDocumentTitle('Almabay');
  const inputRef = useRef(null);
  const inputRefSponsor = useRef(null);
  const isloggedIn = useSelector((state) => state?.user?.isloggedIn);
  const navigate = useNavigate();
  const location = useLocation();
  const { userId, editId, state } = useParams();
  const loginUser = useSelector((state) => state?.user?.details)?.encrypted_id;
  const [showalert, setShowAlert] = useState(false);
  const [messageView, setMessageView] = useState('');
  const [eventDetail, setEventDetail] = useState({
    name: '',
    decp: '',
    venue: '',
    city: '',
    category_id: '',
    event_privacy: '',
    invite_friends: false
  });
  useEffect(() => {
    const timeId = setTimeout(() => {
      // After 3 seconds set the show value to false
      setShowAlert(false);
      setMessageView('');
    }, 3000);

    return () => {
      clearTimeout(timeId);
    };
  }, [showalert]);

  useEffect(() => {
    getEventDetailList();
    EventActivityImage();
    if (isloggedIn) {
      getListToInvite();
    }
  }, [isloggedIn]);

  useEffect(() => {
    if (editId && state == 'event-activity') {
      handleSingleActivity();
    } else if (editId && state == 'event-sponsor') {
      getDetailSponser();
    } else if (state == 'overview') {
      setActiveTab('first');
      getEventDetailList();
    } else if (state == 'event-tickets') {
      setActiveTab('five');
      ticketList();
    } else if (state == 'edit-event') {
      getEventDetailList();
      setActiveTab('seventh');
    } else if (state == 'event-discussions') {
      setActiveTab('second');
    } else if (state == 'invited-people') {
      getEventPeopleList('', '', 'invited');
      setActiveTab('eight');
    } else if (state == 'going-people') {
      getEventPeopleList('', '', 'going');
      setActiveTab('ninth');
    } else if (state == 'interested-people') {
      getEventPeopleList('', '', 'interested');
      setActiveTab('tenth');
    }
  }, [state, userId]);

  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const [show3, setShow3] = useState(false);
  const [show4, setShow4] = useState(false);
  const handleShow3 = () => setShow3(!show3);
  const handleShow4 = () => setShow4(!show4);
  const [showEndDate, setShowEndDate] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleClose2 = () => setShow2(false);
  const handleClose3 = () => setShow3(false);
  const handleClose4 = () => setShow4(false);
  const [InviteList, setInviteList] = useState([]);
  const [prevSponsorImg, setPrevSponsorImg] = useState(null);
  const [prevActivityImg, setPrevActivityImg] = useState(null);

  const handleShow2 = () => setShow2(true);

  const [clicked, setClicked] = useState(false);
  const [activeTab, setActiveTab] = useState('first');

  const [button, setButton] = useState();

  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);

  const [eventPeople, setEventPeople] = useState([]);

  const [shareData, setShareData] = useState([]);
  const [eventActivities, setEventActivities] = useState([]);
  const [sponsorList, setSponsorList] = useState([]);
  const [type1, setType1] = useState('');

  const [image, setImage] = useState([]);
  const [sponsorImage, setSponsorImage] = useState([]);
  const [details, setDetails] = useState({
    title: '',
    description: '',
    image: null
  });
  const [sponserDetails, setSponserDetails] = useState({
    name: '',
    image: ''
  });
  const [sponsorImgPreview, setSponsorImgPreview] = useState('');
  const [isAdmin, setIsAdmin] = useState('');
  const [id, setId] = useState('');
  const [eventId, setEventId] = useState('');
  const [eventData, setEventData] = useState('');
  const [eventStatus, setEventStatus] = useState('');
  const [goingButtonShow, setGoingButtonShow] = useState(true);
  const [interstedBtn, setInterstedBtn] = useState(true);
  const [notButton, setNotButton] = useState('');
  const [loading, setIsLoading] = useState(false);
  const [activityImgPreview, setActivityImgPreview] = useState('');
  const [bookEventTicketList, setBookEventTicketList] = useState([]);
  const [quantityUpdate, setQuantityUpdate] = useState([]);
  const [search, setSearch] = useState('');
  const [editBackGround, setEditBackGround] = useState(false);
  const [loadingBtn, setLoadingBtn] = useState(false);

  const getListToInvite = (q = '') => {
    getInviteList(userId, search).then((res) => {
      if (res?.status === 200) {
        setInviteList(res?.data?.data);
      }
    });
  };
  useEffect(() => {
    if (isloggedIn) {
      const getData = setTimeout(() => {
        getListToInvite();
      }, 2000);
      return () => clearTimeout(getData);
    }
  }, [search, isloggedIn]);

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  const handleSponserChange = (e) => {
    if (e.target.type == 'file') {
      let file = e.target.files[0];
      let type = e.target.files[0].type;
      if (imageExtension.includes(type)) {
        setSponserDetails({
          ...sponserDetails,
          [e.target.name]: file
        });
        setSponsorImgPreview(URL.createObjectURL(e?.target?.files[0]));
      } else {
        showNotification('danger', 'file format not supported !');
      }
    } else {
      const { name, value } = e.target;
      setSponserDetails({
        ...sponserDetails,
        [name]: value
      });
    }
  };

  const handleChange = (e) => {
    if (e.target.type == 'file') {
      let file = e.target.files[0];
      let type = e.target.files[0].type;
      if (imageExtension.includes(type)) {
        setDetails({
          ...details,
          [e.target.name]: file
        });
        setActivityImgPreview(URL.createObjectURL(e?.target?.files[0]));
      } else {
        showNotification('danger', 'file format not supported !');
      }
    } else {
      const { name, value } = e.target;
      setDetails({
        ...details,
        [name]: value
      });
    }
  };

  const validate = () => {
    let error = {};
    if (details?.title == '') {
      error.title = 'This field is required.';
    }

    if (details?.description == '') {
      error.description = 'This field is required.';
    }

    if (fromDate == null) {
      error.fromDate = 'This field is required.';
    }
    if (
      !moment(details?.from_event_date).format('DD MM YYY') ==
      moment(new Date()).format('DD MM YYY')
    ) {
      error.to_event_date = 'Past date not allowed';
    }

    return error;
  };
  const validateSponser = () => {
    let error = {};
    if (sponserDetails?.name == '') {
      error.name = 'This field is required.';
    }

    if (sponserDetails?.image == '') {
      error.image = 'This field is required.';
    }

    return error;
  };

  // Add activity
  const saveHandler = (e) => {
    e.preventDefault();
    setClicked(true);
    if (!Object.keys(validate()).length) {
      setClicked(false);
      let formData = new FormData();

      for (let i in details) {
        formData.append(`${i}`, details[i]);
      }
      formData.append(`event_id`, userId);
      formData.append(`id`, '');
      formData.append(`from_event_date`, moment(fromDate).format('YYYY-MM-DD HH:mm:ss'));
      formData.append(
        `to_event_date`,
        toDate !== '' ? moment(toDate).format('YYYY-MM-DD HH:mm:ss') : ''
      );
      addActivity(formData).then((res) => {
        if (res?.status === 200) {
          setMessageView(res?.data?.detail?.message);
          setShowAlert(true);
          setDetails({
            title: '',
            description: '',
            image: null
          });
          inputRef.current.value = null;
          setFromDate(null);
          setToDate(null);
        }
      });
    }
  };

  const [encrpytedId, setEncryptedId] = useState();

  const getEventDetailList = () => {
    setIsLoading(true);
    getEventDetail(userId).then((res) => {
      if (res?.status === 200) {
        setIsLoading(false);
        const data = res?.data?.result;
        setIsAdmin(res?.data?.result?.eventDetails?.is_admin);
        if (state === 'edit-event') {
          setEventDetail({
            name: data?.eventDetails?.name,
            decp: data?.eventDetails?.decp,
            venue: data?.eventDetails?.venue,
            event_privacy: data?.eventDetails?.event_privacy,

            city: {
              id: data?.eventDetails?.city,
              label: data?.eventDetails?.city_name,
              value: data?.eventDetails?.city_name
            },

            category_id: {
              id: data?.eventDetails?.category_id,
              label: data?.eventDetails?.category_name,
              value: data?.eventDetails?.category_name
            }
          });
          setFromDate(new Date(data?.eventDetails?.from_event_date));
          setToDate(new Date(data?.eventDetails?.to_event_date));
        }

        setShareData(data);
        setEncryptedId(data?.eventDetails?.encrypted_id);
        setEventActivities(data?.activities);
        setEventData(data?.eventDetails);

        setEventStatus(data?.eventStatus);
        if (data?.eventStatus?.going_count > 0) {
          setGoingButtonShow(false);
          setButton('Going');
          setNotButton('Not Going');
        }
        if (data?.eventStatus?.interested_count > 0) {
          setInterstedBtn(false);
          setButton('Interested');
          setNotButton('Not Interested');
          setGoingButtonShow(false);
        }

        //  need to get images here

        //sponsor images

        let sponsor_Image = data?.sponsors;
        sponsor_Image = data?.sponsors?.map((item) => item?.sponser_logo);
        sponsor_Image?.forEach((id) => {
          getEventActivityImage(id, 'event-sponsor').then((res) => {
            if (res?.status == 200) {
              setSponsorImage((prev) => [...res.data.detail, ...prev]);
            }
          });
        });

        let imagesId = data?.activities?.map((item) => item.image);
        imagesId?.forEach((id) => {
          getEventActivityImage(id, 'event-activity').then((imagesResponse) => {
            if (imagesResponse?.status == 200) {
              setImage((prev) => [...imagesResponse.data.detail, ...prev]);
            }
          });
        });
        setSponsorList(data?.sponsors);
      } else {
        setIsLoading(false);
      }
    });
  };

  // Activity Detail
  const handleSingleActivity = () => {
    getEventActivityDetail(userId, editId).then((res) => {
      if (res?.status === 200) {
        const data = res?.data?.detail;
        setDetails({
          title: data?.title,
          description: data?.description
        });
        setId(data?.id);
        setEventId(data?.event_id);
        setPrevActivityImg(data?.image);
        if (data?.from_event_date == '0000-00-00 00:00:00') {
          setFromDate(null);
        } else {
          setFromDate(new Date(data?.from_event_date));
        }

        if (data?.to_event_date == '0000-00-00 00:00:00') {
          setToDate(null);
        } else {
          setToDate(new Date(data?.to_event_date));
        }

        setActivityImgPreview(data?.activityImg);
        if (data?.to_event_date !== '') {
          setShowEndDate(true);
        }
      }
    });
  };

  // sponsorDetail
  const getDetailSponser = (e, id) => {
    getSponerDetail(userId, editId).then((res) => {
      if (res?.status === 200) {
        const data = res?.data?.detail;
        setSponserDetails({
          name: data?.sponser_name
          // image: data?.sponser_logo
        });
        setPrevSponsorImg(data?.sponser_logo);
        setSponsorImgPreview(data?.sponsorImg);

        setId(data?.id);
      }
    });
  };

  // delete Sponsor
  const handleDeleteSponsor = (e, id) => {
    e.preventDefault();
    const body = {
      sponsor_id: id,
      event_id: userId
    };
    const confirm = window.confirm('Do you want to delete sponsor');
    if (confirm) {
      deleteSponsor(body).then((res) => {
        if (res?.status == 200) {
          showNotification('danger', 'Sponsors deleted');
          getEventDetailList();
        }
      });
    }
  };

  // for Update activity
  const updateHandler = (e) => {
    setClicked(true);
    if (!Object.keys(validate()).length) {
      setClicked(false);
      let formData = new FormData();
      formData.append(`event_id`, userId);
      formData.append(`id`, id);
      formData.append(`from_event_date`, moment(fromDate).format('YYYY-MM-DD HH:mm:ss'));
      formData.append(
        `to_event_date`,
        toDate !== '' ? moment(toDate).format('YYYY-MM-DD HH:mm:ss') : ''
      );
      if (details?.image == undefined) {
        formData.append(`privious_image`, prevActivityImg);
      }
      for (let i in details) {
        formData.append(`${i}`, details[i]);
      }
      addActivity(formData).then((res) => {
        if (res?.status === 200) {
          setShowAlert(true);
          setMessageView(res?.data?.detail?.message);
          setTimeout(() => {
            navigate(`/user/events-detail/${eventId}/overview`);
          }, 2000);
          inputRef.current.value = null;
        }
      });
    }
  };
  // add sponsor
  const sponserHandler = (e) => {
    e.preventDefault();
    setClicked(true);
    if (!Object.keys(validateSponser()).length) {
      setClicked(false);
      let formData = new FormData();
      formData.append(`event_id`, userId);
      formData.append(`id`, '');

      for (let i in sponserDetails) {
        formData.append(`${i}`, sponserDetails[i]);
      }
      addNewSponser(formData).then((res) => {
        if (res?.status === 200) {
          setMessageView(res?.data?.detail?.message);
          setShowAlert(true);
          setSponserDetails({ name: '', image: '' });
          setSponsorImgPreview(null);
          inputRefSponsor.current.value = null;
        }
      });
    }
  };

  // Update Sponsor details

  const UpdateSponserHandler = (e) => {
    e.preventDefault();
    setClicked(true);
    if (!Object.keys(validateSponser()).length) {
      setClicked(false);
      let formData = new FormData();
      formData.append(`event_id`, userId);
      formData.append(`id`, editId);

      for (let i in sponserDetails) {
        formData.append(`${i}`, sponserDetails[i]);
      }
      if (sponserDetails?.image == undefined) {
        formData.append(`privious_image`, prevSponsorImg);
      }
      addNewSponser(formData).then((res) => {
        if (res?.status === 200) {
          showNotification('success', res?.data?.message);
          setSponserDetails({ name: '', image: '' });
          setSponsorImgPreview(null);
          inputRefSponsor.current.value = null;
        }
      });
    }
  };

  const handleSelectChange = (e) => {
    setActiveTab(e);
    if (e == 'third') {
      setDetails({
        title: '',
        description: '',
        from_event_date: '',
        to_event_date: '',
        image: ''
      });
    }
  };

  const getEventPeopleList = (e, activityId, type) => {
    setIsLoading(true);
    // e.preventDefault();
    const body = {
      event_id: userId,
      activity_id: activityId ? activityId : '',
      type: type
    };

    getEventPeople(body).then((res) => {
      if (res?.status === 200) {
        setIsLoading(false);

        setEventPeople(res?.data?.result);
        setType1(type);
      }
    });
  };
  const EventActivityImage = () => {
    getEventActivityImage().then((res) => {
      if (res?.status === 200) {
        // setImage(res?.data?.data?.details)
      }
    });
  };

  /**Code for invite people**/

  const handleEventInivite = async (e, id) => {
    e.preventDefault();
    setLoadingBtn(true);
    let formData = new FormData();
    formData.append('event_id', userId);
    formData.append('ids[]', id);
    let res = await sendEventInvite(formData);
    if (res?.status == 200) {
      setLoadingBtn(false);
      getEventDetailList();
      getListToInvite();
      getEventPeopleList(e, '', 'invited');
      showNotification('success', res?.data?.message);
    }
  };

  /**    section ends     **/

  /**  user goingButtonShow, interested in event     **/
  const handleUserActivity = (userId, activity_type) => {
    if (activity_type == 2) {
      setGoingButtonShow(false);
    }
    userEventActivity(userId, activity_type).then((res) => {
      if (res?.status === 200) {
        getEventDetailList();

        showNotification('success', res?.data?.message);
      }
    });
  };

  const handleRemoveInvite = (e, id) => {
    const body = {
      event_id: userId,
      user_id: id
    };
    removeInvite(body).then((res) => {
      if (res?.status === 200) {
        showNotification('success', res?.data?.data);
        getEventDetailList();
        getEventPeopleList(e, '', 'invited');
      }
    });
  };

  const handleParticipate = (userId, activity_id, activity_type) => {
    if (activity_type === 0) {
      const confirm = window.confirm('Are you sure you want to continue?');
      {
        if (confirm) {
          userParticipate(userId, activity_id, activity_type).then((res) => {
            if (res?.status === 200) {
              getEventDetailList();
              showNotification('success', res?.data?.data);
            }
          });
        }
      }
    }
    userParticipate(userId, activity_id, activity_type).then((res) => {
      if (res?.status === 200) {
        getEventDetailList();
        showNotification('success', res?.data?.data);
      }
    });
  };

  const handleDeleteActivity = (e, data) => {
    e.preventDefault();
    const confirm = window.confirm('Do you want to delete event activity?');
    if (confirm) {
      deleteActivity(data?.id, data?.event_id).then((res) => {
        if (res?.status === 200) {
          showNotification('danger', res?.data?.message);
          getEventDetailList();
        }
      });
    }
  };

  // For Event details
  const handleView = (e) => {
    e.preventDefault();
    setActiveTab('first');
    navigate(`/user/events-detail/${userId}/overview`);
    getEventDetailList();
  };

  // Get Tickets List

  const ticketList = () => {
    setIsLoading(true);
    getEventTickets(userId).then((res) => {
      if (res?.status === 200) {
        setIsLoading(false);
        setBookEventTicketList(res?.data?.detail ?? []);
        if (res?.data?.detail?.length > 0) {
          setQuantityUpdate(
            res?.data?.detail.map((item) => {
              return {
                ...item,
                quantity: 0
              };
            })
          );
        } else {
          setQuantityUpdate([]);
          setIsLoading(false);
        }
        setActiveTab('five');
      } else {
        setIsLoading(false);
      }
    });
  };

  const handleEventPeopleList = (e, ListType) => {
    e.preventDefault();
    if (isloggedIn && eventData?.encrypted_userid == loginUser) {
      if (ListType == 'interested') {
        getEventPeopleList(e, '', ListType);
        navigate(`/user/events-detail/${userId}/interested-people`);
      } else if (ListType == 'going') {
        getEventPeopleList(e, '', ListType);
        navigate(`/user/events-detail/${userId}/going-people`);
      } else if (ListType == 'invited') {
        getEventPeopleList(e, '', ListType);
        navigate(`/user/events-detail/${userId}/invited-people`);
      }
    }
  };

  const eventState = () => {
    if (state == 'overview') {
      return (
        <EventsOverview
          handleParticipate={handleParticipate}
          handleDeleteSponsor={handleDeleteSponsor}
          sponsorImage={sponsorImage}
          sponsorList={sponsorList}
          eventActivities={eventActivities}
          setDetails={setDetails}
          isloggedIn={isloggedIn}
          eventData={eventData}
          loginUser={loginUser}
          image={image}
          userId={userId}
          getEventPeopleList={getEventPeopleList}
          handleDeleteActivity={handleDeleteActivity}
          setSponserDetails={setSponserDetails}
          setSponsorImgPreview={setSponsorImgPreview}
        />
      );
    } else if (
      state == 'going-people' ||
      state == 'interested-people' ||
      state == 'invited-people' ||
      state == 'participants-people'
    ) {
      return (
        <EventsParticipatePeople
          handleRemoveInvite={handleRemoveInvite}
          type1={type1}
          state={state}
          location={location}
          handleView={handleView}
          eventPeople={eventPeople}
          getListToInvite={getListToInvite}
        />
      );
    } else if (state == 'edit-event') {
      return (
        <EditEventDetails
          loadEventCategoryOptions={loadEventCategoryOptions}
          setEventDetail={setEventDetail}
          eventDetail={eventDetail}
          setFromDate={setFromDate}
          fromDate={fromDate}
          toDate={toDate}
          setToDate={setToDate}
          userId={userId}
          getEventDetailList={getEventDetailList}
        />
      );
    } else if (state == 'event-tickets') {
      return (
        <EventTickets
          handleView={handleView}
          bookEventTicketList={bookEventTicketList}
          quantityUpdate={quantityUpdate}
          setQuantityUpdate={setQuantityUpdate}
          userId={userId}
        />
      );
    } else if (state == 'event-discussions') {
      return <EventDiscussions userId={userId} />;
    } else {
      return;
    }
  };

  const [imageSrc, setImageSrc] = React.useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [rotation, setRotation] = useState(0);
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const [selectedImage, setSelectedImage] = useState('');

  const showCroppedImage = useCallback(
    async (croppedArea, croppedAreaPixels) => {
      try {
        const croppedImage = await getCroppedImg(imageSrc, croppedAreaPixels);
        setCroppedImage(croppedImage);
      } catch (e) {
        console.error(e);
      }
    },
    [imageSrc, croppedAreaPixels]
  );

  const onClose = useCallback(() => {
    setCroppedImage(null);
  }, []);

  const onFileChange = async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      let imageDataUrl = await readFile(file);
      setSelectedImage(e.target.files[0]);
      setImageSrc(imageDataUrl);
    }
  };

  const handeSaveImage = (e) => {
    e.preventDefault();
    let formData = new FormData();
    formData.append(`image`, selectedImage);
    formData.append(`position`, crop?.y);
    formData.append(`event_id`, eventData?.id);
    if (selectedImage != '') {
      changeEventCoverImage(formData).then((res) => {
        if (res?.status == 200) {
          getEventDetailList();
          setSelectedImage('');
          setEditBackGround(false);
        }
      });
    } else {
      setEditBackGround(false);
    }
  };

  const removeImage = (e) => {
    e.preventDefault();
    let formData = new FormData();

    formData.append(`event_id`, eventData?.id);
    removeEventCoverImage(formData).then((res) => {
      if (res?.status == 200) {
        getEventDetailList();
        setEditBackGround(false);
      }
    });
  };

  const handleChangeProfileImage = (e) => {
    let file = e.target.files[0];
    let formData = new FormData();
    formData.append(`image`, file);
    formData.append('timeline_id', eventData?.id);
    formData.append('timeline_type', 'events');
    changeEventImage(formData).then((res) => {
      if (res?.status == 200) {
        getEventDetailList();
      }
    });
  };

  return (
    <>
      {!isloggedIn ? (
        <HeaderInner state={`/user/events-detail/${userId}/overview`} />
      ) : (
        <HeaderLogin />
      )}
      {isloggedIn ? (
        <div className="header-lnk-feeds">
          <div className="container">
            <TopTabbings state={'social'} />
          </div>
        </div>
      ) : (
        ''
      )}

      <section className="event-detail-cover">
        {selectedImage !== '' ? (
          <div className="cropContainer">
            {' '}
            <Cropper
              image={imageSrc}
              crop={crop}
              zoom={zoom}
              onCropChange={setCrop}
              onCropComplete={showCroppedImage}
              onZoomChange={setZoom}
              showGrid={false}
              objectFit={'horizontal-cover'}
            />
          </div>
        ) : (
          <img
            src={
              eventData?.cover_url
                ? eventData?.cover_url
                : require('../../../../assets/images/default-mentor-cover.jpg')
            }
            alt="img"
            style={{
              top:
                +eventData?.cover_position < 0
                  ? eventData?.cover_position * 2 + 'px'
                  : -eventData?.cover_position / 2 + 'px'
            }}
          />
        )}

        {editBackGround == false ? (
          <>
            {' '}
            {eventData?.encrypted_userid == loginUser && (
              <a className="event-cover-edit" onClick={() => setEditBackGround(true)}>
                <i className="fas fa-camera me-2"></i> Edit Background
              </a>
            )}
          </>
        ) : (
          <ul className="page-cover-function">
            <li>
              <Link to="" onClick={(e) => handeSaveImage(e)}>
                Save
              </Link>
            </li>
            <li>
              <span className="change-img-card">Change Image</span>
              <Form.Control type="file" onChange={onFileChange} />
            </li>
            <li>
              <Link to="#" onClick={(e) => removeImage(e)}>
                Remove Image
              </Link>
            </li>
            <li>
              <Link to="#" onClick={() => setEditBackGround(false)}>
                <i className="fas fa-times"></i>
              </Link>
            </li>
          </ul>
        )}
      </section>
      <section className="event-detail-dis">
        <Container>
          <div className="event-dis-head">
            <div className="event-dis-img me-3">
              <img src={eventData?.avatar_url ? eventData?.avatar_url : defaultEvent} alt="img" />
              {eventData?.encrypted_userid == loginUser && (
                <div className="profile-upload-card" onChange={handleChangeProfileImage}>
                  <Form.Control type="file" />
                  <i className="fas fa-camera"></i>
                  Update Profile Picture
                </div>
              )}
            </div>

            <div className="event-dis-rt">
              <h2>
                {' '}
                <Link title={eventData?.name}> {eventData?.name} </Link>{' '}
                {isloggedIn &&
                  (eventData?.encrypted_userid == loginUser ? (
                    <Button
                      title="Edit Event"
                      onClick={() => {
                        navigate(`/user/events-detail/${userId}/edit-event`);
                        getEventDetailList();
                        setActiveTab('seventh');
                      }}>
                      <i className="fas fa-edit "></i>
                    </Button>
                  ) : (
                    ''
                  ))}
                <Link className="share-btn bluecolor" onClick={handleShow} title="Share Event">
                  <i className="fas fa-share-alt"></i>
                </Link>
              </h2>
              <ul className="ecent-dis-lst mt-2">
                <li title="Event Privacy">
                  {' '}
                  {eventData?.event_privacy ? eventData?.event_privacy : 'Public Event'}{' '}
                </li>
                <li>
                  {' '}
                  <Link title="Event Category"> {eventData?.category_name} </Link>{' '}
                </li>
                <li>
                  {' '}
                  <Link title="Event City"> {eventData?.only_city} </Link>{' '}
                </li>
              </ul>
              <div className="web-detail">
                <span>Date</span>
                {eventData?.to_event_date == '0000-00-00 00:00:00' ? (
                  <p>
                    {moment(eventData?.from_event_date).format('ddd')},{' '}
                    {moment(eventData?.from_event_date).format(' DD MMM  YYYY, h:mm  A')}
                  </p>
                ) : (
                  <p>
                    {moment(eventData?.from_event_date).format('ddd')},{' '}
                    {moment(eventData?.from_event_date).format(' DD MMM  YYYY, h:mm  A')} -
                    {moment(eventData?.to_event_date).format('ddd')},{' '}
                    {moment(eventData?.to_event_date).format('DD MMM  YYYY, h:mm A ')}
                  </p>
                )}
              </div>
              <div className="web-detail">
                <span>Address</span>
                <Link title={eventData?.venue}>
                  <p>{eventData?.venue}</p>
                </Link>
              </div>

              <div className="event-hd-ftr mt-5 d-flex align-items-center justify-content-between">
                {isloggedIn ? (
                  <div className="event-hd-inr-drops d-flex align-items-center">
                    <Dropdown className="me-2 ">
                      <Dropdown.Toggle
                        className="invite-orange p-2 m-1"
                        variant="success"
                        id="dropdown-basic">
                        <i className="fas fa-envelope me-2"></i>
                        INVITE
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item onClick={handleShow3}>Invite People</Dropdown.Item>
                        <Dropdown.Item
                          onClick={() => navigate(`/pages/pinvite/${userId}`)}
                          target="_blank">
                          Invite by Email
                        </Dropdown.Item>
                        {eventData?.encrypted_userid !== loginUser && (
                          <Dropdown.Item onClick={handleShow4}>Share on Social</Dropdown.Item>
                        )}
                      </Dropdown.Menu>
                    </Dropdown>

                    {/* User Activity */}

                    {goingButtonShow && (
                      <a
                        className="invite-orange p-2 mx-2"
                        onClick={() => {
                          isloggedIn
                            ? handleUserActivity(eventData?.id, constants.USERGOING)
                            : navigate('/login', {
                                state: `/user/events-detail/${userId}/overview`
                              });
                          // setGoingButtonShow(false);
                        }}>
                        + GOING
                      </a>
                    )}

                    {interstedBtn && goingButtonShow ? (
                      <a
                        className="blue-interest p-2 m-1"
                        onClick={() => {
                          isloggedIn
                            ? handleUserActivity(eventData?.id, constants.USERINTERESTED)
                            : navigate('/login', {
                                state: `/user/events-detail/${userId}/overview`
                              });
                          // setGoingButtonShow(false);
                        }}>
                        INTERESTED
                      </a>
                    ) : (
                      <Dropdown className="me-2">
                        <Dropdown.Toggle
                          className="blue-interest p-2 m-1"
                          variant="success"
                          id="dropdown-basic">
                          <i className="fas fa-check me-2"></i>

                          {button}
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <Dropdown.Item
                            onClick={() =>
                              isloggedIn
                                ? handleUserActivity(eventData?.id, constants.USERGOING)
                                : navigate('/login', {
                                    state: `/user/events-detail/${userId}/overview`
                                  })
                            }>
                            <div>
                              {' '}
                              <i
                                style={
                                  button == 'Going'
                                    ? { visibility: 'visible' }
                                    : { visibility: 'hidden' }
                                }
                                className="fas fa-check me-2 icon-yellow"></i>
                              Going
                            </div>
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() => {
                              isloggedIn
                                ? handleUserActivity(eventData?.id, constants.USERINTERESTED)
                                : navigate('/login', {
                                    state: `/user/events-detail/${userId}/overview`
                                  });
                              setGoingButtonShow(false);
                            }}>
                            <div>
                              <div>
                                <i
                                  style={
                                    button == 'Interested'
                                      ? { visibility: 'visible' }
                                      : { visibility: 'hidden' }
                                  }
                                  className="fas fa-check me-2 icon-yellow "></i>
                                Interested
                              </div>
                            </div>
                          </Dropdown.Item>

                          <div style={{ borderBottom: '1px solid #BBBBBB' }}></div>
                          <Dropdown.Item
                            onClick={() => {
                              isloggedIn
                                ? handleUserActivity(
                                    eventData?.id,
                                    constants.USER_GOING_OR_NOT_GOING
                                  )
                                : navigate('/login', {
                                    state: `/user/events-detail/${userId}/overview`
                                  });

                              setGoingButtonShow(true);
                              setInterstedBtn(true);
                              setGoingButtonShow(true);
                            }}>
                            {/* Not Interested */}

                            <div>
                              <i
                                style={{ visibility: 'hidden' }}
                                className="fas fa-check me-2 icon-yellow "></i>

                              {notButton}
                            </div>
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    )}
                    <Dropdown className="activity-drop-down me-2" onSelect={handleSelectChange}>
                      {eventData?.encrypted_userid == loginUser ? (
                        <Dropdown.Toggle className="activity-drop-card" id="dropdown-basic">
                          <i className="fas fa-ellipsis-v"></i>
                        </Dropdown.Toggle>
                      ) : (
                        ''
                      )}

                      <Dropdown.Menu>
                        <Dropdown.Item
                          // href="#"
                          eventKey="third"
                          onClick={(e) => {
                            e.preventDefault();
                            setDetails({
                              title: '',
                              description: '',
                              from_event_date: '',
                              to_event_date: '',
                              image: ''
                            });
                            navigate(`/user/events-detail/${userId}/event-activity`);
                          }}>
                          <i className="fas fa-lightbulb me-3"></i>Add Activities
                        </Dropdown.Item>

                        <Dropdown.Item
                          href="#/action-2"
                          eventKey="fourth"
                          onClick={(e) => {
                            e.preventDefault();
                            navigate(`/user/events-detail/${userId}/event-sponsor`);
                          }}>
                          <i className="fas fa-money-bill me-3"></i>Add Sponsors
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>

                    <Button className="sponser-btn" onClick={handleShow2}>
                      + Be a Sponsor
                    </Button>
                  </div>
                ) : (
                  <div className="event-hd-inr-drops d-flex align-items-center">
                    <Link
                      to="/login"
                      state={`/user/events-detail/${userId}/overview`}
                      className="invite-orange p-2 m-1"
                      variant="success"
                      id="dropdown-basic">
                      <i className="fas fa-envelope me-2"></i>
                      INVITE
                    </Link>

                    {/* User Activity */}

                    {isloggedIn ? (
                      goingButtonShow && (
                        <a
                          className="invite-orange p-2 mx-2"
                          onClick={() => {
                            handleUserActivity(eventData?.id, constants.USERGOING);
                          }}>
                          + GOING
                        </a>
                      )
                    ) : (
                      <a
                        className="invite-orange p-2 mx-2"
                        onClick={() => {
                          navigate('/login', {
                            state: `/user/events-detail/${userId}/overview`
                          });
                        }}>
                        + GOING
                      </a>
                    )}

                    {isloggedIn ? (
                      interstedBtn && goingButtonShow ? (
                        <a
                          className="blue-interest p-2 m-1"
                          onClick={() => {
                            handleUserActivity(eventData?.id, constants.USERINTERESTED);
                          }}>
                          INTERESTED
                        </a>
                      ) : (
                        <Dropdown className="me-2">
                          <Dropdown.Toggle
                            className="blue-interest p-2 m-1"
                            variant="success"
                            id="dropdown-basic">
                            <i className="fas fa-check me-2"></i>

                            {button}
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            <Dropdown.Item
                              onClick={() =>
                                handleUserActivity(eventData?.id, constants.USERGOING)
                              }>
                              Going
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={() => {
                                isloggedIn
                                  ? handleUserActivity(eventData?.id, constants.USERINTERESTED)
                                  : navigate('/login', {
                                      state: `/user/events-detail/${userId}/overview`
                                    });
                                setGoingButtonShow(false);
                              }}>
                              Interested
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={() => {
                                handleUserActivity(
                                  eventData?.id,
                                  constants.USER_GOING_OR_NOT_GOING
                                );

                                setGoingButtonShow(true);
                                setInterstedBtn(true);
                                setGoingButtonShow(true);
                              }}>
                              {notButton}
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      )
                    ) : (
                      <a
                        className="blue-interest p-2 m-1"
                        onClick={() => {
                          navigate('/login', { state: `/user/events-detail/${userId}/overview` });
                        }}>
                        INTERESTED
                      </a>
                    )}

                    {isloggedIn ? (
                      <Dropdown className="activity-drop-down me-2" onSelect={handleSelectChange}>
                        <Dropdown.Toggle className="activity-drop-card" id="dropdown-basic">
                          <i className="fas fa-ellipsis-v"></i>
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <Dropdown.Item
                            // href="#"
                            eventKey="third"
                            onClick={(e) => {
                              e.preventDefault();
                              setDetails({
                                title: '',
                                description: '',
                                from_event_date: '',
                                to_event_date: '',
                                image: ''
                              });
                              navigate(`/user/events-detail/${userId}/event-activity`);
                            }}>
                            <i className="fas fa-lightbulb me-3"></i>Add Activities
                          </Dropdown.Item>

                          <Dropdown.Item
                            href="#/action-2"
                            eventKey="fourth"
                            onClick={(e) => {
                              e.preventDefault();
                              setSponserDetails({
                                name: '',
                                image: ''
                              });
                              setSponsorImgPreview(null);
                              navigate(`/user/events-detail/${userId}/event-sponsor`);
                            }}>
                            <i className="fas fa-money-bill me-3"></i>Add Sponsors
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    ) : (
                      ''
                    )}

                    <Button className="sponser-btn" onClick={handleShow2}>
                      + Be a Sponsor
                    </Button>
                  </div>
                )}
                <p className="public-rt-text">
                  <i className="fas fa-globe-asia me-2"></i>
                  Public - Hosted by
                  <Link
                    to={isloggedIn ? `/user/timeline/${eventData?.encrypted_userid}` : '/login'}>
                    {eventData?.username}
                  </Link>
                </p>
              </div>
            </div>
            <div className="event-date-card">
              <h4>{moment(eventData?.from_event_date).format('DD')} </h4>
              <p> {moment(eventData?.from_event_date).format('MMM')}</p>
            </div>
          </div>

          <div className="event-tabs-detail">
            <Row>
              <Tab.Container id="left-tabs-example" activeKey={activeTab}>
                <Col md={2}>
                  <div className="event-tab-card bg-white mb-3">
                    <Nav variant="pills" className="">
                      <Nav.Item>
                        {isloggedIn ? (
                          <Nav.Link
                            eventKey="first"
                            onClick={() => {
                              getEventDetailList();
                              navigate(`/user/events-detail/${userId}/overview`);
                              setActiveTab('first');
                            }}>
                            Details
                          </Nav.Link>
                        ) : (
                          <Nav.Link eventKey="first">Details</Nav.Link>
                        )}
                      </Nav.Item>
                      {isloggedIn ? (
                        <Nav.Item>
                          <Nav.Link
                            eventKey="second"
                            onClick={() => {
                              navigate(`/user/events-detail/${userId}/event-discussions`, {
                                state: encrpytedId
                              });
                              setActiveTab('second');
                            }}>
                            Discussion
                          </Nav.Link>
                        </Nav.Item>
                      ) : (
                        <Nav.Item>
                          <Nav.Link
                            eventKey="second"
                            onClick={() => {
                              navigate(`/login`);
                            }}>
                            Discussion
                          </Nav.Link>
                        </Nav.Item>
                      )}
                      {isloggedIn ? (
                        isAdmin == true ? (
                          ''
                        ) : (
                          <Nav.Item>
                            <Nav.Link
                              eventKey="five"
                              onClick={() => {
                                navigate(`/user/events-detail/${userId}/event-tickets`);
                                setActiveTab('five');
                              }}>
                              Book Tickets
                            </Nav.Link>
                          </Nav.Item>
                        )
                      ) : (
                        <Nav.Item>
                          <Nav.Link
                            eventKey="five"
                            onClick={() => {
                              navigate(`/login`);
                            }}>
                            Book Tickets
                          </Nav.Link>
                        </Nav.Item>
                      )}
                    </Nav>
                  </div>
                  <div className="event-guest bg-white">
                    <h4>Guests</h4>
                    <ul>
                      <li>
                        {isloggedIn && eventData?.encrypted_userid == loginUser ? (
                          <Link
                            to="#"
                            onClick={(e) => {
                              handleEventPeopleList(e, 'interested');
                              setActiveTab('eight');
                            }}>
                            <h4> {eventStatus?.interested_count} </h4>
                            <p>interested</p>
                          </Link>
                        ) : (
                          <Link to="#">
                            <h4> {eventStatus?.interested_count} </h4>
                            <p>interested</p>
                          </Link>
                        )}
                      </li>
                      <li>
                        {isloggedIn && eventData?.encrypted_userid == loginUser ? (
                          <Link
                            to="#"
                            onClick={(e) => {
                              handleEventPeopleList(e, 'going');
                              setActiveTab('ninth');
                            }}>
                            <h4> {eventStatus?.going_count} </h4>
                            <p>going</p>
                          </Link>
                        ) : (
                          <Link to="#">
                            <h4> {eventStatus?.going_count} </h4>
                            <p>going</p>
                          </Link>
                        )}
                      </li>
                      <li>
                        {isloggedIn && eventData?.encrypted_userid == loginUser ? (
                          <Link
                            to="#"
                            onClick={(e) => {
                              handleEventPeopleList(e, 'invited');
                              setActiveTab('tenth');
                            }}>
                            <h4> {eventStatus?.invite_count} </h4>
                            <p>invited</p>
                          </Link>
                        ) : (
                          <Link to="#">
                            <h4> {eventStatus?.invite_count} </h4>
                            <p>invited</p>
                          </Link>
                        )}
                      </li>
                    </ul>
                  </div>
                </Col>
                <Col md={12} lg={7}>
                  <Tab.Content>
                    {/*********************** End Edit-Event************************/}
                    {eventState()}
                    {/***********************Add activity************************/}
                    {state && state == 'event-activity' ? (
                      <div className="add-event-activity bg-white">
                        <div className="add-head-card d-flex align-items-center justify-content-between">
                          {editId ? (
                            <h4 className="add-event-hd">Update Event Activity</h4>
                          ) : (
                            <h4 className="add-event-hd">Add Event Activity</h4>
                          )}
                          <Link onClick={(e) => handleView(e)}>
                            <i className="fas fa-search-plus"></i>
                            View Details
                          </Link>
                        </div>
                        <div className="add-event-frm">
                          <Alert
                            variant={messageView ? 'success' : 'danger'}
                            className={`${showalert ? '' : 'd-none'}`}>
                            {messageView ? messageView : 'No Update'}
                          </Alert>
                          <Form>
                            <Row>
                              <Col lg={12}>
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                  <Form.Label>Title</Form.Label>
                                  <sup className="text-danger">*</sup>
                                  <Form.Control
                                    type="text"
                                    placeholder="Add a short, clear name"
                                    onChange={handleChange}
                                    name="title"
                                    value={details.title}
                                  />
                                  <div className={'danger-frm'}>{clicked && validate().title}</div>
                                </Form.Group>
                              </Col>
                              <Col lg={12}>
                                <Form.Group className="mb-3" serwr="formBasicEmail">
                                  <Form.Label>Description</Form.Label>
                                  <sup className="text-danger">*</sup>
                                  <Form.Control
                                    as="textarea"
                                    type="text"
                                    placeholder="Tell people more about the event"
                                    row="4"
                                    onChange={handleChange}
                                    name="description"
                                    value={details.description}
                                  />
                                  <div className={'danger-frm'}>
                                    {clicked && validate().description}
                                  </div>
                                </Form.Group>
                              </Col>
                              <Col lg={4}>
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                  <Form.Label>Start Date-Time </Form.Label>
                                  <sup className="text-danger">*</sup>
                                  <DatePicker
                                    placeholderText="Select Start Date-Time"
                                    className="date-card"
                                    selected={fromDate}
                                    filterTime={filterPassedTime}
                                    onChange={(date) => {
                                      setFromDate(date ? date : new Date());
                                    }}
                                    showTimeSelect
                                    minDate={new Date()}
                                    dateFormat="MM-dd-yyyy h:mm aa"
                                  />

                                  <div className={'danger-frm'}>
                                    {clicked && validate().fromDate}
                                  </div>
                                </Form.Group>
                              </Col>
                              <Col lg={4}>
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                  <Form.Label onClick={() => setShowEndDate(!showEndDate)}>
                                    {' '}
                                    {showEndDate ? '-' : '+'} End Date-Time{' '}
                                  </Form.Label>
                                  <sup className="text-danger">*</sup>
                                  {showEndDate && (
                                    <DatePicker
                                      placeholderText="Select End Date-Time"
                                      className="date-card"
                                      selected={toDate}
                                      filterTime={filterPassedTime}
                                      onChange={(date) => setToDate(date ? date : new Date())}
                                      showTimeSelect
                                      minDate={fromDate !== '' ? new Date(fromDate) : new Date()}
                                      dateFormat="MM-dd-yyyy h:mm aa"
                                    />
                                  )}

                                  <div className={'danger-frm'}>
                                    {clicked && validate().to_event_date}
                                  </div>
                                </Form.Group>
                              </Col>

                              <Col lg={12}>
                                <Form.Group controlId="formFile" className="mb-3">
                                  <Form.Label>Activity Image</Form.Label>
                                  <Form.Control
                                    ref={inputRef}
                                    type="file"
                                    onChange={handleChange}
                                    name="image"
                                  />
                                </Form.Group>
                              </Col>
                              {editId && activityImgPreview && (
                                <div>
                                  <img src={activityImgPreview} alt="act" />
                                </div>
                              )}
                              <Col lg={12}>
                                <div className="accnt-lft-btn d-flex align-items-center justify-content-end">
                                  {editId ? (
                                    <button
                                      className="activity-btn"
                                      type="button"
                                      onClick={() => updateHandler(details?.id)}>
                                      Update Activity
                                    </button>
                                  ) : (
                                    <button
                                      // onClick={resetFileInput}
                                      className="activity-btn"
                                      type="button"
                                      onClick={(e) => {
                                        saveHandler(e);
                                      }}>
                                      Add Activity
                                    </button>
                                  )}

                                  <button
                                    className="wht-btn ms-2"
                                    onClick={() =>
                                      navigate(`/user/events-detail/${userId}/overview`)
                                    }>
                                    Cancel
                                  </button>
                                </div>
                              </Col>
                            </Row>
                          </Form>
                        </div>
                      </div>
                    ) : state == 'event-sponsor' ? (
                      <div className="add-event-activity bg-white">
                        <div className="add-head-card d-flex align-items-center justify-content-between">
                          {!editId ? (
                            <h4 className="add-event-hd">Add Event Sponsors</h4>
                          ) : (
                            <h4 className="add-event-hd">Update Event Sponsors</h4>
                          )}
                          <Link onClick={(e) => handleView(e)}>
                            <i className="fas fa-search-plus"></i>
                            View Details
                          </Link>
                        </div>
                        <div className="add-event-frm">
                          <Alert
                            variant={messageView ? 'success' : 'danger'}
                            className={`${showalert ? '' : 'd-none'}`}>
                            {messageView ? messageView : 'No Update'}
                          </Alert>
                          <Form>
                            <Row>
                              <Col lg={12}>
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                  <Form.Label>Title</Form.Label>{' '}
                                  <sup className="text-danger">*</sup>
                                  <Form.Control
                                    type="text"
                                    placeholder="Add a short, clear name"
                                    onChange={handleSponserChange}
                                    name="name"
                                    value={sponserDetails.name}
                                  />
                                  <div className={'danger-frm'}>
                                    {clicked && validateSponser().name}
                                  </div>
                                </Form.Group>
                              </Col>
                              <Col lg={12}>
                                <Form.Group controlId="formFile" className="mb-3">
                                  <Form.Label>Sponsor Logo </Form.Label>{' '}
                                  <sup className="text-danger">*</sup>
                                  <Form.Control
                                    ref={inputRefSponsor}
                                    type="file"
                                    onChange={handleSponserChange}
                                    name="image"
                                  />
                                  <div className={'danger-frm'}>
                                    {clicked && validateSponser().image}
                                  </div>
                                  {sponsorImgPreview && (
                                    <div>
                                      <img src={sponsorImgPreview} alt="sponsor" />
                                    </div>
                                  )}
                                </Form.Group>
                              </Col>
                              <Col lg={12}>
                                <div className="accnt-lft-btn d-flex align-items-center justify-content-end">
                                  {!editId ? (
                                    <button
                                      className="activity-btn"
                                      type="button"
                                      onClick={sponserHandler}>
                                      Add Sponsor
                                    </button>
                                  ) : (
                                    <button
                                      className="activity-btn"
                                      type="button"
                                      onClick={UpdateSponserHandler}>
                                      Update Sponsor
                                    </button>
                                  )}

                                  <button
                                    className="wht-btn ms-2"
                                    onClick={() =>
                                      navigate(`/user/events-detail/${userId}/overview`)
                                    }>
                                    Cancel
                                  </button>
                                </div>
                              </Col>
                            </Row>
                          </Form>
                        </div>
                      </div>
                    ) : (
                      ''
                    )}
                    {/* </Tab.Pane> */}
                    <Tab.Pane eventKey="fourth"></Tab.Pane>
                  </Tab.Content>
                </Col>
              </Tab.Container>
              {isloggedIn ? (
                <Col md={12} lg={3}>
                  <div>
                    <InvitePeople
                      eventId={userId}
                      handleEventInivite={handleEventInivite}
                      InviteList={InviteList}
                      handleSearch={handleSearch}
                      search={search}
                      loadingBtn={loadingBtn}
                    />
                  </div>
                  <div>
                    <GroupDiscover />
                  </div>
                  <div>
                    <FollowCompany />
                  </div>
                  <div>
                    <CampusnewsText />
                  </div>
                  <div>
                    <Upcoming />
                  </div>
                </Col>
              ) : (
                <Col md={12} lg={3}>
                  <div>
                    <WhatsNew />
                  </div>

                  <div>
                    <Upcoming />
                  </div>
                  <div>
                    <LeftSidebarPrivacyPolicy />
                  </div>
                </Col>
              )}
            </Row>
          </div>
        </Container>
      </section>
      <Modal show={show} onHide={handleClose} contentClassName="share-model-events">
        <Modal.Header closeButton>
          <Modal.Title>Share Event On</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <SocialShare
            facebookOptions={{
              url:
                process.env.REACT_APP_DOMAIN + '/user/events-detail' + '/' + userId + `/overview`,
              quote: eventData?.name
            }}
            twitterOptions={{
              url:
                process.env.REACT_APP_DOMAIN + '/user/events-detail' + '/' + userId + `/overview`,
              title: eventData?.name,
              hashtags: ['events', 'trends']
            }}
            linkedInOptions={{
              title: eventData?.name,
              summary: eventData?.decp,
              url:
                process.env.REACT_APP_DOMAIN + '/user/events-detail' + '/' + userId + `/overview`,
              source:
                process.env.REACT_APP_DOMAIN + '/user/events-detail' + '/' + userId + `/overview`
            }}
            eventmodule={'eventModule'}
          />
        </Modal.Body>
      </Modal>
      {/* INVITE PEOPLE MODAL */}

      <InvitePeopleModal
        handleClose3={handleClose3}
        show={show}
        show3={show3}
        // renderFunction={getEventList}
        handleClose={handleClose}
        eventId={userId}
        isloggedIn={isloggedIn}
      />

      {/* share social modal */}

      <ShareSocialModal
        handleClose4={handleClose4}
        show={show4}
        handleClose={handleClose}
        userId={userId}
        getEventDetailList={getEventDetailList}
        shareData={shareData}
        dropdownButton={button}
      />

      {/* Add sponser modal */}

      <CreateEventSponser
        handleClose2={handleClose2}
        show={show}
        show2={show2}
        handleClose={handleClose}
        userId={userId}
      />
      {loading ? <Loader /> : null}
    </>
  );
}

function readFile(file) {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => resolve(reader.result), false);
    reader.readAsDataURL(file);
  });
}

export default EventsDetail;
