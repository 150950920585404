/**
@copyright  :   ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
@author     :   Shiv Charan Panjeta 
 
All Rights Reserved.
Proprietary and confidential :  All information contained here in is, and remains
the property of ToXSL Technologies Pvt. Ltd. and it's partners.
Unauthorized copying of this file, via any medium is strictly prohibited.
*/

import { useEffect, useState } from 'react';
import { Button, Container } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import { NumberWithdecimal, restrictAlpha } from '../../../globals/helper';
import useDocumentTitle from '../../../globals/useDocumentTitle';
import { ShowAlert } from '../../../redux/action';
import {
  addProductAdmin,
  getProductAttribute,
  getProductDetail
} from '../../../services/AuthServices';
import { getCategoryOptions, loadAllInstituteOptions } from '../../AdminHelper';
import showNotification from '../../../services/NotificationService';

export default function AddProduct() {
  const [productDetail, setProductDetail] = useState({
    name: '',
    category_id: '',
    institute_id: '',
    description: '',
    cover_image: '',
    code: '',
    price: '',
    spl_price: '',
    sort_order: '',
    feature_product: '',
    stock: '',
    soldout: '',
    weight: '',
    category: ''
  });
  const [clicked, setClicked] = useState(false);
  const [attribute, setAttribute] = useState({
    size: '',
    colour: ''
  });

  const [showSize, setShowSize] = useState(false);
  const [showColour, setShowColour] = useState(false);
  const [sizeOption, setSizeOption] = useState([]);
  const [colourOption, setcolourOption] = useState([]);
  const [colourIds, setColourIds] = useState([]);
  const [sizeIds, setSizeIds] = useState([]);

  const [proImg, setProImg] = useState('');
  const [preview, setPreview] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const id = location?.state;
  const dispatch = useDispatch();

  useEffect(() => {
    getSizeAttributes();
    getColourAttribute();
    if (id) {
      getDetail(id);
    }
  }, []);

  const getDetail = (id) => {
    getProductDetail(id).then((res) => {
      if (res?.status === 200) {
        const data = res?.data?.data;

        let sizearr = [];
        let colourArr = [];
        data?.attributes_data?.map((data) => {
          if (data?.attribute_group_id == 1) {
            sizearr.push(data);
          }
          if (data?.attribute_group_id == 2) {
            colourArr.push(data);
          }
        });

        let sizevalue = sizearr?.map((data) => {
          return {
            label: data?.slug,
            value: data?.id
          };
        });

        let colorValues = colourArr?.map((data) => {
          return {
            label: data?.slug,
            value: data?.id
          };
        });
        setSizeIds(sizevalue);
        setColourIds(colorValues);
        setShowColour(true);
        setShowSize(true);
        setProductDetail({
          name: data?.name,
          category_id: {
            id: data?.category_id,
            label: data?.category,
            value: data?.category
          },
          institute_id: { id: data?.institute_id, label: data?.institute, value: data?.institute },
          description: data?.description,
          cover_image: '',
          code: data?.code,
          price: data?.price,
          spl_price: data?.spl_price,
          sort_order: data?.sort_order,
          feature_product: data?.feature_product == 1 ? true : false,
          stock: data?.stock,
          soldout: data?.soldout !== 'No' ? true : false,
          weight: data?.weight
        });
        setProImg(data?.cover_image);
      }
    });
  };

  const handleChange = (e, defaultName) => {
    if (defaultName == 'category_id' || defaultName == 'institute_id') {
      setProductDetail({
        ...productDetail,
        [defaultName]: e
      });
    } else {
      const { name, value, type, checked } = e.target;
      if (type == 'file') {
        let file = e.target.files[0];
        if (file?.type?.includes('image')) {
          setPreview(URL.createObjectURL(e?.target?.files[0]));
          setProductDetail({
            ...productDetail,
            [name]: file
          });
        } else {
          setProductDetail({
            ...productDetail,
            ['cover_image']: ''
          });
          showNotification('danger', 'Invalid file format');
        }
      } else {
        if (name == 'feature_product' || name == 'soldout') {
          setProductDetail({
            ...productDetail,
            [name]: checked
          });
        } else {
          setProductDetail({
            ...productDetail,
            [name]: value
          });
        }
      }
    }
  };
  const getSizeAttributes = () => {
    getProductAttribute(1).then((res) => {
      if (res?.status === 200) {
        const data = res?.data?.data;
        if (sizeIds) {
          setSizeOption(
            data?.map((option) => {
              return {
                label: option?.name,
                value: option?.id
              };
            })
          );
        } else {
          setSizeOption(sizeIds);
        }
      }
    });
  };

  const getColourAttribute = () => {
    getProductAttribute(2).then((res) => {
      if (res?.status === 200) {
        const data = res?.data?.data;
        if (colourIds) {
          setcolourOption(
            data?.map((option) => {
              return {
                label: option?.name,
                value: option?.id
              };
            })
          );
        } else {
          setcolourOption(colourIds);
        }
      }
    });
  };

  const validate = () => {
    let errors = {};
    if (productDetail?.name == '') {
      errors.name = 'This field is required.';
    }
    if (productDetail?.category_id == '') {
      errors.category_id = 'Select Category';
    }

    if (productDetail?.stock == '') {
      errors.stock = 'This field is required.';
    }
    if (productDetail?.code == '') {
      errors.code = 'This field is required.';
    } else if (productDetail?.code?.length < 3) {
      errors.code = 'Product code should be more than 3 characters';
    }
    if (productDetail?.price == '') {
      errors.price = 'This field is required.';
    }
    if (productDetail?.weight == '') {
      errors.weight = 'This field is required.';
    }
    // if (attribute?.colour == '') {
    //   errors.colour = 'This field is required'
    // }

    return errors;
  };
  const handleSubmit = () => {
    setClicked(true);
    if (!Object.keys(validate()).length) {
      let formData = new FormData();
      formData.append(`category_id`, productDetail?.category_id?.id);
      formData.append(`institute_id`, productDetail?.institute_id?.id);
      formData.append(`name`, productDetail?.name);
      formData.append(`code`, productDetail?.code);
      formData.append(`description`, productDetail?.description);
      formData.append(`price`, productDetail?.price);
      formData.append(`spl_price`, productDetail?.spl_price);
      formData.append(`weight`, productDetail?.weight);
      formData.append(`stock`, productDetail?.stock);
      formData.append(`soldout`, productDetail?.soldout);
      formData.append(`feature_product`, productDetail?.feature_product);
      formData.append(`sort_order`, productDetail?.sort_order);
      formData.append(`cover_image`, productDetail?.cover_image);
      formData.append(`attributeGroup[1]`, sizeIds?.map((data) => data?.value).toString());
      formData.append(`attributeGroup[2]`, colourIds?.map((data) => data?.value).toString());
      if (id) {
        formData.append(`id`, id);
      }

      addProductAdmin(formData).then((res) => {
        if (res?.status === 200) {
          navigate('/admin/products');
          if (id) {
            dispatch(ShowAlert('Product has been edited successfully'));
          } else {
            dispatch(ShowAlert('Product has been added successfully'));
          }
        }
      });
    }
  };

  const handleAttribute = (value) => {
    if (value) {
      if (value == '1') {
        setAttribute({ ...attribute, size: value });
      } else {
        setAttribute({ ...attribute, colour: value });
      }
    }
  };

  const handleShowAttribute = () => {
    handleAttribute(productDetail?.attribute);
    if (productDetail?.attribute == '2') {
      setShowColour(true);
    }
    if (productDetail?.attribute == '1') {
      setShowSize(true);
    }
  };

  return (
    <div className="dash-main-content">
      <Container fluid>
        <div className="dash-page-content">
          <div className="dash-page-title">
            <Row>
              <Col xs={12} sm={12} md={12}>
                <div className="dash-title-bar d-flex align-items-center justify-content-between">
                  <div className="title-lft d-flex align-items-center">
                    {/* <div className="dash-icn">
                        <i className="fas fa-users-cog"></i>
                      </div> */}
                    <div className="dash-title-dis">
                      {id
                        ? useDocumentTitle('Edit Product | AlmaBay')
                        : useDocumentTitle('Add New Product | AlmaBay')}

                      {<h5>{id ? 'Edit' : 'Add'} Product</h5>}
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <div className="dash-content-card mb-4">
            <div className="cmmn-form">
              <Form>
                <Row>
                  <Col md={4}>
                    <Form.Group className="mb-3" controlId="formBasicName">
                      <Form.Label>
                        Name<span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter product name"
                        name="name"
                        value={productDetail?.name}
                        onChange={handleChange}
                      />
                      <div className={'danger-frm'}>{clicked && validate().name}</div>
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group className="mb-3" controlId="formBasicName">
                      <Form.Label>
                        Select Category <span className="text-danger">*</span>
                      </Form.Label>
                      <AsyncSelect
                        isClearable
                        value={productDetail?.category_id}
                        cacheOptions
                        defaultOptions
                        isSearchable
                        getOptionLabel={(e) => e.label}
                        getOptionValue={(e) => e.value}
                        loadOptions={getCategoryOptions}
                        className="basic-single mt-1"
                        placeholder="-- Select Category --"
                        onChange={(e) => handleChange(e, 'category_id')}
                      />

                      <div className={'danger-frm'}>{clicked && validate().category_id}</div>
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group className="mb-3" controlId="formBasicName">
                      <Form.Label>
                        Select Institute <span className="text-danger">*</span>
                      </Form.Label>
                      <AsyncSelect
                        isClearable
                        value={productDetail?.institute_id}
                        cacheOptions
                        defaultOptions
                        getOptionLabel={(e) => e.label}
                        getOptionValue={(e) => e.value}
                        loadOptions={loadAllInstituteOptions}
                        className="basic-single mt-1"
                        placeholder="- Select Institute -"
                        onChange={(e) => handleChange(e, 'institute_id')}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="mb-3" controlId="formBasicName">
                      <Form.Label>Description</Form.Label>
                      <Form.Control
                        as="textarea"
                        type="text"
                        name="description"
                        value={productDetail?.description}
                        placeholder="Enter product description."
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="mb-3" controlId="formBasicName">
                      <Form.Label>Product Cover Image </Form.Label>
                      <Form.Control
                        type="file"
                        name="cover_image"
                        accept=".png,.jpg,.jpeg"
                        onChange={handleChange}
                        onClick={(e) => {
                          e.target.value = '';
                        }}
                      />
                    </Form.Group>
                  </Col>

                  {preview ? (
                    <div className="d-flex flex-wrap align-item-center">
                      <div className="border me-2">
                        <img width="100px" height="100px" src={preview} alt="img1" />
                      </div>
                      <div className={'danger-frm'}>{clicked && validate().cover_image}</div>
                    </div>
                  ) : (
                    id &&
                    proImg && (
                      <div className="d-flex flex-wrap align-item-center">
                        <div className="border me-2">
                          <img
                            width="100px"
                            height="100px"
                            src={
                              process.env.REACT_APP_API_DOMAIN + 'common/uploads/products/' + proImg
                            }
                            alt="img1"
                          />
                        </div>
                        <div className={'danger-frm'}>{clicked && validate().cover_image}</div>
                      </div>
                    )
                  )}
                </Row>
              </Form>
            </div>
          </div>
          <div className="dash-content-card mb-4 p-0 tabs-grey">
            <div className="add-product-tabs dashboard-controls">
              <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                <Row>
                  <Col sm={12}>
                    <Nav variant="pills" className="pro-tabs-hd mb-4">
                      <Nav.Item>
                        <Nav.Link eventKey="first">GENERAL</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="second">INVENTORY</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="third">SHIPPING</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="fourth">ATTRIBUTES</Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </Col>
                  <Col sm={12}>
                    <Tab.Content className="px-4 py-4">
                      <Tab.Pane eventKey="first">
                        <div className="cmmn-form ">
                          <Form>
                            <Row>
                              <Col md={4}>
                                <Form.Group className="mb-3" controlId="formBasicName">
                                  <Form.Label>
                                    Code<span className="text-danger">*</span>
                                  </Form.Label>
                                  <Form.Control
                                    type="text"
                                    placeholder="Enter product code"
                                    name="code"
                                    maxLength={8}
                                    onChange={handleChange}
                                    value={productDetail?.code}
                                  />
                                  <div className={'danger-frm'}>{clicked && validate().code}</div>
                                </Form.Group>
                              </Col>
                              <Col md={4}>
                                <Form.Group className="mb-3" controlId="formBasicName">
                                  <Form.Label>
                                    Price<span className="text-danger">*</span>
                                  </Form.Label>
                                  <Form.Control
                                    type="text"
                                    placeholder="Enter product price"
                                    name="price"
                                    onChange={handleChange}
                                    onKeyPress={(e) => NumberWithdecimal(e)}
                                    value={productDetail?.price}
                                  />
                                  <div className={'danger-frm'}>{clicked && validate().price}</div>
                                </Form.Group>
                              </Col>
                              <Col md={4}>
                                <Form.Group className="mb-3 mt-2" controlId="formBasicName">
                                  <Form.Label>Special Price</Form.Label>
                                  <Form.Control
                                    type="text"
                                    placeholder="Enter the special price"
                                    name="spl_price"
                                    value={productDetail?.spl_price}
                                    onKeyPress={(e) => NumberWithdecimal(e)}
                                    onChange={handleChange}
                                  />
                                </Form.Group>
                              </Col>
                              <Col md={4}>
                                <Form.Group className="mb-3" controlId="formBasicName">
                                  <Form.Label>Sort Order</Form.Label>
                                  <Form.Control
                                    type="text"
                                    placeholder="Enter the sort order"
                                    name="sort_order"
                                    maxLength={256}
                                    value={productDetail?.sort_order}
                                    onKeyDown={(e) => restrictAlpha(e)}
                                    onChange={handleChange}
                                  />
                                </Form.Group>
                              </Col>
                              <Col md={4}>
                                <Form.Group className="mt-5" controlId="formBasicCheckbox">
                                  <Form.Check
                                    type="checkbox"
                                    label="Feature Product"
                                    name="feature_product"
                                    checked={productDetail?.feature_product}
                                    onChange={handleChange}
                                  />
                                </Form.Group>
                              </Col>
                            </Row>
                          </Form>
                        </div>
                      </Tab.Pane>
                      <Tab.Pane eventKey="second">
                        <div className="cmmn-form ">
                          <Form>
                            <Row>
                              <Col md={4}>
                                <Form.Group className="mb-3" controlId="formBasicName">
                                  <Form.Label>
                                    Stock<span className="text-danger">*</span>
                                  </Form.Label>
                                  <Form.Control
                                    type="text"
                                    placeholder="Enter product stock"
                                    name="stock"
                                    onChange={handleChange}
                                    value={productDetail?.stock}
                                    onKeyPress={(e) => NumberWithdecimal(e)}
                                  />
                                  <div className={'danger-frm'}>{clicked && validate().stock}</div>
                                </Form.Group>
                              </Col>
                              <Col md={4}>
                                <Form.Group className="mt-5" controlId="formBasicCheckbox">
                                  <Form.Check
                                    type="checkbox"
                                    label="Sold Out"
                                    name="soldout"
                                    checked={productDetail?.soldout}
                                    onChange={handleChange}
                                  />
                                </Form.Group>
                              </Col>
                            </Row>
                          </Form>
                        </div>
                      </Tab.Pane>
                      <Tab.Pane eventKey="third">
                        <div className="cmmn-form ">
                          <Form>
                            <Row>
                              <Col md={4}>
                                <Form.Group className="mb-3" controlId="formBasicName">
                                  <Form.Label>
                                    Weight<span className="text-danger">*</span>
                                  </Form.Label>
                                  <div className="d-flex align-items-center">
                                    <Form.Control
                                      type="text"
                                      placeholder="Enter the product weight"
                                      name="weight"
                                      onChange={handleChange}
                                      value={productDetail?.weight}
                                      onKeyPress={(e) => NumberWithdecimal(e)}
                                    />
                                    <span className="unit-text">Units</span>
                                  </div>
                                  <div className={'danger-frm'}>{clicked && validate().weight}</div>
                                </Form.Group>
                              </Col>
                            </Row>
                          </Form>
                        </div>
                      </Tab.Pane>
                      <Tab.Pane eventKey="fourth">
                        <div className="cmmn-form ">
                          <Form>
                            <Row>
                              <Col md={4}>
                                <Form.Group className="mb-4" controlId="formBasicName">
                                  <Form.Label>Choose Product Attribute</Form.Label>

                                  <div className="d-flex align-items-center">
                                    <Form.Select
                                      aria-label="Default select example"
                                      name="attribute"
                                      onChange={handleChange}>
                                      {attribute?.colour == '' && attribute?.size == '' && (
                                        <>
                                          {' '}
                                          <option value=""> -- Choose -- </option>
                                          <option value="1">Size</option>
                                          <option value="2">Color</option>
                                        </>
                                      )}
                                      {attribute?.colour !== '' && attribute?.size == '' && (
                                        <>
                                          {' '}
                                          <option value=""> -- Choose -- </option>
                                          <option value="1">Size</option>
                                        </>
                                      )}
                                      {attribute?.colour == '' && attribute?.size !== '' && (
                                        <>
                                          {' '}
                                          <option value=""> -- Choose -- </option>
                                          <option value="2">Color</option>
                                        </>
                                      )}
                                      {attribute?.colour !== '' && attribute?.size !== '' && (
                                        <>
                                          {' '}
                                          <option value=""> -- Choose -- </option>
                                        </>
                                      )}
                                    </Form.Select>
                                    <Button
                                      className="ms-2"
                                      type="button"
                                      onClick={handleShowAttribute}>
                                      Add
                                    </Button>
                                  </div>
                                </Form.Group>

                                {showSize && (
                                  <Col md={12}>
                                    <Form.Group as={Row} className="mb-3" controlId="formBasicName">
                                      <Form.Label column sm={2}>
                                        Size<span className="text-danger">*</span>
                                      </Form.Label>
                                      <Col sm={9}>
                                        <Select
                                          className="basic-single mb-3"
                                          classNamePrefix="select"
                                          isMulti
                                          value={sizeIds}
                                          options={sizeOption}
                                          isSearchable
                                          placeholder=" --  Select Category  --  "
                                          onChange={setSizeIds}
                                        />
                                        <div className={'danger-frm'}>
                                          {clicked && validate().category_id}
                                        </div>
                                      </Col>
                                    </Form.Group>
                                  </Col>
                                )}
                                {showColour && (
                                  <Col md={12}>
                                    <Form.Group as={Row} className="mb-3" controlId="formBasicName">
                                      <Form.Label column sm={2}>
                                        Colour<span className="text-danger">*</span>
                                      </Form.Label>
                                      <Col sm={9}>
                                        <Select
                                          className="basic-single mb-3"
                                          classNamePrefix="select"
                                          isSearchable
                                          value={colourIds}
                                          options={colourOption}
                                          isMulti
                                          placeholder=" --  Select Category  --  "
                                          onChange={setColourIds}
                                        />
                                        <div className={'danger-frm'}>
                                          {clicked && validate().category_id}
                                        </div>
                                      </Col>
                                    </Form.Group>
                                  </Col>
                                )}
                              </Col>
                            </Row>
                          </Form>
                        </div>
                      </Tab.Pane>
                    </Tab.Content>
                  </Col>
                </Row>
              </Tab.Container>
            </div>
          </div>
          <div className="form-btns d-flex align-items-center mt-5 mb-4">
            <button className="green_button_admin" type="button" onClick={handleSubmit}>
              Save
            </button>
            <strong className="ms-3 me-3">OR</strong>
            <Button className="main-btn-red" onClick={() => navigate('/admin/products')}>
              Cancel
            </Button>
          </div>
        </div>
      </Container>
    </div>
  );
}
