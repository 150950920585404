import { Link, useNavigate } from 'react-router-dom';
import HeaderLogin from '../../commoncomponents/HeaderLogin';
import FooterMain from '../../commoncomponents/FooterMain';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { checkLogin } from '../../../services/AuthServices';
import { loginInfo } from '../../../redux/action';
import Loader from '../../commoncomponents/loader/loader-large';

function PageNotfound() {
  const checkLoggedIn = useSelector((state) => {
    return state?.user?.isloggedIn;
  });
  const dipatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const token = searchParams.get('token');
  const isloggedIn = checkLoggedIn
  useEffect(() => {
    if (token) {
      localStorage.setItem('token', token);
      checkUserToken();
    } else if (isloggedIn) {
      navigate('/jobs');
    }
  }, [])

  const checkUserToken = () => {
    checkLogin().then((res) => {
      if (res?.status === 200) {
        //save data on redux
        dipatch(loginInfo(res?.data?.data));
        navigate('/jobs');
      }
    });
  };

  return (
    <>
      {token ? <Loader /> : <>
        {checkLoggedIn && <HeaderLogin />}
        <section className="error wrapper dark-bg">
          <div className="flex-container">
            <div className="text-center">
              <h1>
                <span className="fade-in" id="digit1">
                  4
                </span>
                <span className="fade-in" id="digit2">
                  0
                </span>
                <span className="fade-in" id="digit3">
                  4
                </span>
              </h1>
              <h3 className="fadeIn">PAGE NOT FOUND</h3>
              <Link to="/" className="add-btn-grn" name="button">
                Return To Home
              </Link>
            </div>
          </div>
        </section>
        {checkLoggedIn && <FooterMain className={'colour-bg'} />}
        {/* <FooterMain className={'colour-bg'} /> */}
      </>}
    </>
  );
}

export default PageNotfound;
