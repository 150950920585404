import { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Table from 'react-bootstrap/Table';
import ReactPaginate from 'react-paginate';
import { useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Loader from '../../components/commoncomponents/loader/loader-large';
import { statusName } from '../../globals/helper';
import * as adminServices from '../../services/AuthServices';

export default function ClubBlog() {
    const { club_id } = useParams();
    const [list, setList] = useState([]);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [searchedData, setSearchData] = useState({
        country_id: '',
        country_code: '',
        country_name: ''
    });
    const [response, setResponse] = useState('');
    const [sortType, setSortType] = useState('DESC');
    const [sortField, setSortField] = useState('country_id');
    const [currentPage, setCurrentPage] = useState(1);
    const roleType = useSelector((state) => state?.user?.details?.admin_type);

    useEffect(() => {
        apiCall();
    }, []);

    const apiCall = (page = currentPage, id = "", title = "") => {
        setLoading(true);
        let body = {
            club_id: club_id,
            title,
            id,
            page
        }
        adminServices.clubBlogList(body).then((res) => {
            setLoading(false);
            if (res?.status === 200) {
                setList(res?.data?.details);
                setResponse(res?.data?.counts);
            }
        });
    };

    const selectHandler = (value, data) => {
        switch (value) {
            case 'edit':
                navigate(`/admin/club/blog/edit/${club_id}/${data?.id}`, { state: data });
                break;
            case '2':
                stateHandler(value, data?.id);
                break;
            default:
                break;
        }
    };

    const handlePageClick = (a) => {
        let page = a?.selected + 1;
        setCurrentPage(page);
        apiCall(page,
            searchedData.country_id,
            searchedData.country_code);
    };

    const stateHandler = (value, id) => {
        setLoading(true);
        let status = value;
        adminServices.deleteClubBlog(id, status).then((res) => {
            setLoading(false);
            if (res?.status === 200) {
                document.getElementById('select').value = '';
                apiCall();
            }
        });
    };


    const sortHandler = (e, type) => {
        e.preventDefault();
        let currentSort = sortType === 'ASC' ? 'DESC' : 'ASC';
        setSortType(currentSort);
        setSortField(type);
        apiCall();
    };

    const onChangeSearch = (e) => {
        const { name, value } = e.target;
        setSearchData({ ...searchedData, [name]: value });
    };

    const searchHandler = (e) => {
        e.preventDefault();
        apiCall(
            currentPage,
            searchedData.country_id,
            searchedData.country_code
        );
    };

    return (
        <>
            <div className="dash-main-content">
                <Container fluid>
                    <div className="dash-page-content">
                        <div className="dash-page-title">
                            <Row>
                                <Col xs={12} sm={12} md={12}>
                                    <div className="dash-title-bar d-flex align-items-center justify-content-between">
                                        <div className="title-lft d-flex align-items-center">
                                            <div className="dash-icn">
                                                <i className="fas fa-building"></i>
                                            </div>
                                            <div className="dash-title-dis">
                                                <h5>
                                                    Blog <span>Listing</span>
                                                </h5>
                                                <h5>
                                                    Total Record(s): <span>{response}</span>
                                                </h5>
                                            </div>
                                        </div>
                                        <div className="title-rt-btn">
                                            <Link className="green_button_admin" to={`/admin/club/blog/add/${club_id}`}>
                                                Add New Blog
                                            </Link>
                                            {/* {roleType == '3' && (
                                                <Link className="green_button_admin mx-2" to="/admin/add-banner?id=5">
                                                    Add Banner
                                                </Link>
                                            )} */}
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        <div className="dash-content-card">
                            <div className="table-responsive">
                                <Table className="custom-table-data" striped bordered hover>
                                    <thead>
                                        <tr>
                                            <th>
                                                Id
                                            </th>
                                            <th>
                                                Title
                                            </th>
                                            <th>
                                                Club Name
                                            </th>
                                            <th>Status</th>
                                            <th>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr className="search-table">
                                            <td>
                                                <Form.Control
                                                    value={searchedData.country_id}
                                                    name="country_id"
                                                    onChange={(e) => {
                                                        onChangeSearch(e);
                                                    }}
                                                    type="text"
                                                    placeholder="Id"
                                                />
                                            </td>
                                            <td>
                                                <Form.Control
                                                    value={searchedData.country_code}
                                                    name="country_code"
                                                    onChange={(e) => {
                                                        onChangeSearch(e);
                                                    }}
                                                    type="text"
                                                    placeholder="Title"
                                                />
                                            </td>
                                            <td>

                                            </td>
                                            <td></td>
                                            <td className="serac-btns text-center">
                                                <button
                                                    type="button"
                                                    className="green_button_admin"
                                                    onClick={(e) => {
                                                        searchHandler(e);
                                                    }}>
                                                    Search
                                                </button>
                                            </td>
                                        </tr>
                                        {list && list?.length
                                            ? list?.map((data, index) => (
                                                <tr key={index}>
                                                    <td>{data?.id}</td>
                                                    <td>{data?.title}</td>
                                                    <td>{data?.club_name}</td>
                                                    <td>{statusName(data?.state_id)}</td>
                                                    <td>
                                                        <Form.Select
                                                            aria-label="Default select example"
                                                            value={''}
                                                            id="select"
                                                            onChange={(e) => {
                                                                selectHandler(e.target.value, data);
                                                            }}>
                                                            <option value="">Action</option>
                                                            <option value="edit">Edit</option>
                                                            <option value="2">Delete</option>
                                                        </Form.Select>
                                                    </td>
                                                </tr>
                                            ))
                                            : ''}
                                    </tbody>
                                </Table>
                                {response > 10 ? (
                                    <div className="react-page d-flex">
                                        <ReactPaginate
                                            previousLabel={currentPage > 0 ? '<' : ''}
                                            nextLabel={response > 0 ? '>' : ''}
                                            onPageChange={handlePageClick}
                                            activeClassName={'page-link active'}
                                            pageCount={Math.ceil(response / 10)}
                                        />
                                    </div>
                                ) : (
                                    ''
                                )}
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
            {loading ? <Loader /> : ''}
        </>
    );
}
