/** @copyright : ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
 @author     : Shiv Charan Panjeta 

 All Rights Reserved.
 Proprietary and confidential :  All information contained herein is, and remains
 the property of ToXSL Technologies Pvt. Ltd. and its partners.
 Unauthorized copying of this file, via any medium is strictly prohibited.
*/

import moment from 'moment/moment';
import { useEffect, useState } from 'react';
import { Button, Container } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { truncate } from '../../../globals/helper';
import useDocumentTitle from '../../../globals/useDocumentTitle';
import * as APISERVICES from '../../../services/JobServices';
import showNotification from '../../../services/NotificationService';
import { getTechnicalSkillDrop } from '../../../services/UserServices';
import { getToken } from '../../../utils/session';
import FooterMain from '../../commoncomponents/FooterMain';
import HeaderInner from '../../commoncomponents/HeaderInner';
import HeaderLogin from '../../commoncomponents/HeaderLogin';
import Loader from '../../commoncomponents/loader/loader-large';
import * as index from '../../commoncomponents/skelton/allskelton';
import JobFilterSalary from '../common-professional-comp/JobFilterSalary';

/* eslint-disable */

function BrowseJob() {
  useDocumentTitle(
    'Search For Latest Job Opportunities for Alumni | Freshers| Interns on Almabay | AlmaBay'
  );
  const { locationId, catId } = useParams();
  const [list, setList] = useState([]);
  const [logoList, setLogoList] = useState([]);
  const [skillsFilterId, setSkillsFilterId] = useState('');
  const [companyFilterId, setCompanyFilterId] = useState('');
  const [industryFilterId, setIndustryFilterId] = useState('');
  const [locationFilterId, setLocationFilterId] = useState('');
  const [minCtc, setMinCtc] = useState('');
  const [maxCtc, setMaxCtc] = useState('');
  const [minExp, setMinExp] = useState('');
  const [maxExp, setMaxExp] = useState('');
  const [techSearch, setTechSearch] = useState('');
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [total, setTotal] = useState();
  const isloggedIn = useSelector((state) => state?.user?.isloggedIn);
  const [techSkill, setTechSkill] = useState([]);
  const [inputMsg, setInputMsg] = useState('Please enter atleast 2 characters.');
  const [loadInpMsg, setloadInpMsg] = useState(false);

  useEffect(() => {
    getJobList();
    // getTopCompanyLogo();
    if (locationId || catId) {
      getSearchedFilterData();
    }
  }, []);

  const getJobList = (page = '') => {
    setLoading(true);
    APISERVICES.browseJob().then((res) => {
      setLoading(false);
      if (res?.status === 200) {
        if (page) {
          setList([...list, ...res?.data?.detail?.result]);
        } else {
          setList(res?.data?.detail?.result);
        }
        setLogoList(res?.data?.detail?.top_companies_list);

        setTotal(res?.data?.pager?.total);
      }
    });
  };

  // const getTopCompanyLogo = () => {
  //   APISERVICES.browseJob().then((res) => {
  //     if (res?.status === 200) {
  //     }
  //   });
  // };

  const getSearchedFilterData = () => {
    if (
      locationId ||
      catId ||
      techSkill?.length != 0 ||
      companyFilterId ||
      industryFilterId ||
      locationFilterId ||
      minExp ||
      maxExp ||
      minCtc ||
      maxCtc
    ) {
      setLoading(true);
      APISERVICES.filterJobs(
        'search',
        techSkill?.map((data) => data?.value)?.toString(),
        companyFilterId,
        industryFilterId,
        locationId ? locationId : locationFilterId,
        catId,
        minExp,
        maxExp,
        minCtc,
        maxCtc
      ).then((res) => {
        setLoading(false);
        if (res?.status === 200) {
          setList(res?.data?.data);
        }
      });
    } else {
      showNotification('danger', 'Kindly select at least one filter condition to proceed');
    }
  };

  const getFresherList = (category_id = ' ', exp_type = 'fresher') => {
    setLoading(true);
    APISERVICES.getSearchedJob(exp_type, '', '', '', '', category_id).then((res) => {
      setLoading(false);
      if (res?.status === 200) {
        setList(res?.data?.data);
        // setCount(res?.data?.pager);
      }
    });
  };
  //get job-in-network lists
  const getNetworkJob = (job_type = 'search') => {
    setLoading(true);
    APISERVICES.getSearchedJob(job_type).then((res) => {
      setLoading(false);
      if (res?.status === 200) {
        setList(res?.data?.data);
        // setCount(res?.data?.pager);
      }
    });
  };
  const handleLoadMore = (e) => {
    e.preventDefault();
    setCurrentPage(currentPage + 1);
    getJobList(currentPage + 1);
  };

  const loadTechOptions = async (inputValue) => {
    if (inputValue.length >= 2) {
      setloadInpMsg(true);
      return getTechnicalSkillDrop(inputValue).then((res) => {
        setloadInpMsg(false);
        setInputMsg('searching...');
        if (res?.status === 200) {
          let data = res?.data?.data;
          let arr = [];
          data?.map((data) => {
            arr.push({ label: data.name, value: data.id });
          });

          if (data?.length == 0) {
            setInputMsg('no matches found');
          }
          return arr;
        }
      });
    }
  };

  return (
    <>
      {getToken() ? <HeaderLogin /> : <HeaderInner />}
      <section className="job-companies">
        <Container>
          <div className="job-grid">
            <div className="job-companies-top mb-3">
              <h5>Top companies are hiring</h5>
            </div>
            <Row>
              {logoList &&
                logoList?.map((data, index) => (
                  <Col lg={2} md={6} xs={6} key={`${data?.id}+${index}`}>
                    {/* <Link className="job-co-card" to={`/company/index/${data?.name}`}> */}
                    <Link className="job-co-card" to={`/company/index/${data?.username}`}>
                      <img src={data?.logo} alt="" />
                    </Link>
                    {/* </Link> */}
                  </Col>
                ))}
            </Row>
          </div>
        </Container>
      </section>
      <section className="job-srch-sec py-5">
        <Container>
          <Row>
            <Col md={12} lg={3}>
              {/* <SideFilter /> */}
              {/* search-filter */}
              <JobFilterSalary
                setSkillsFilterId={setSkillsFilterId}
                setCompanyFilterId={setCompanyFilterId}
                setIndustryFilterId={setIndustryFilterId}
                setLocationFilterId={setLocationFilterId}
                apicall={getSearchedFilterData}
                resetCall={isloggedIn ? getNetworkJob : getSearchedFilterData}
                setMinCtc={setMinCtc}
                setMaxCtc={setMaxCtc}
                setMinExp={setMinExp}
                setMaxExp={setMaxExp}
                minExp={minExp}
                maxExp={maxExp}
                minCtc={minCtc}
                maxCtc={maxCtc}
                job_type="search"
                fromSection="browse-jobs"
                setTechSearch={setTechSearch}
                techSearch={techSearch}
                loadTechOptions={loadTechOptions}
                setTechSkill={setTechSkill}
                techSkill={techSkill}
                inputMsg={inputMsg}
                setInputMsg={setInputMsg}
                loadInpMsg={loadInpMsg}
              />
            </Col>
            <Col md={12} lg={6}>
              <div className="job-view-card">
                <div className="job-hd-dis d-flex align-items-center justify-content-between mb-3">
                  <h4>Recently posted jobs</h4>
                  {!isloggedIn && (
                    <p>
                      <b>{list?.length || 0}</b> jobs found here.
                    </p>
                  )}
                </div>
                <div className="job-listing">
                  {loading ? (
                    <div className="job-recomm-card bg-white">
                      <index.SearchjobSkelton />
                      <index.SearchjobSkelton />
                      <index.SearchjobSkelton />
                      <index.SearchjobSkelton />
                    </div>
                  ) : (
                    <>
                      {isloggedIn && (
                        <p>
                          <b>{list?.length || 0}</b> jobs found here.
                        </p>
                      )}

                      {list &&
                        list?.map((data, index) => (
                          <div className="job-lst-card" key={index}>
                            <div className="job-hd-title">
                              <a
                                href={`/jobs/details/${data?.id}`}
                                rel="noreferrer"
                                target="_blank">
                                <strong>{data?.job_title}</strong>
                              </a>
                              <p>{data?.job_company_meta}</p>
                            </div>
                            <ul className="job-loc">
                              {data?.job_location_meta ? <li>{data?.job_location_meta}</li> : ''}
                              <li>
                                <i className="fas fa-suitcase"></i>
                                Min:{data?.min_exp} - {data?.max_exp} yrs{' '}
                              </li>

                              <li>
                                Min:{data?.min_ctc} Lac - Max: {data?.max_ctc}Lac
                              </li>
                            </ul>

                            <div className="key-skill">
                              <h5>Keyskills</h5>
                              <p>
                                {data?.key_skills?.map((skills) => {
                                  return skills?.name;
                                })}
                              </p>
                            </div>
                            <div className="job-discr">
                              <h5>Job Description</h5>
                              {isloggedIn ? (
                                <p>
                                  <span
                                    dangerouslySetInnerHTML={{
                                      __html: truncate(data?.job_info, 500)
                                    }}></span>
                                </p>
                              ) : (
                                <p>
                                  <span
                                    dangerouslySetInnerHTML={{
                                      __html: truncate(data?.job_info, 200)
                                    }}></span>
                                  {data?.job_info?.length > 200 && (
                                    <a
                                      href={`/jobs/details/${data?.id}`}
                                      rel="noreferrer"
                                      target="_blank">
                                      View Details
                                    </a>
                                  )}
                                </p>
                              )}
                            </div>
                            <div className="job-post d-flex align-items-center justify-content-between">
                              <p>
                                <b>posted on:</b>{' '}
                                {isloggedIn
                                  ? data?.created_on
                                  : moment(data?.date_time).format(`MMM D,YYYY HH:MM:SS`)}
                              </p>
                              {(data?.userDetail?.name || data?.user?.first_name) && (
                                <p>
                                  <b>Posted by:</b>

                                  {isloggedIn ? data?.user?.first_name : data?.userDetail?.name}
                                </p>
                              )}
                            </div>
                          </div>
                        ))}
                    </>
                  )}
                </div>
              </div>
              {list?.length !== total && (
                <div className="load-more-btn text-center">
                  <Button variant="secondary" className="read-more" onClick={handleLoadMore}>
                    Load More
                  </Button>
                </div>
              )}
            </Col>
            <Col md={12} lg={3}>
              <div className="on-amblay-card mb-3">
                <h5>On Almabay, you can find where your friends went after college</h5>
                <Link className="main-btn mt-2" to="/login">
                  LOGIN
                </Link>
              </div>
              <div className="quck-lnk-card">
                <h5 className="mb-2">Quick Links</h5>
                <ul>
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <Link to="/advancesearch/directory/A">Find your Institute</Link>
                  </li>

                  <li>
                    <Link to="/jobs/search">Browse Jobs</Link>
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <FooterMain className={'colour-bg'} />
      {/* end */}
      {loading ? <Loader /> : ''}
    </>
  );
}

export default BrowseJob;
