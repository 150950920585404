import { Link } from 'react-router-dom';
import { useState } from 'react';
import Select from 'react-select';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import FooterMain from '../../commoncomponents/FooterMain';
import SocialMedia from '../../commoncomponents/socialMediaBtns/SocialMedia';

function RegisterPage() {
  const [isClearable] = useState(true);
  const [isSearchable] = useState(true);
  const [isDisabled] = useState(false);
  const [isLoading] = useState(false);
  const [isRtl] = useState(false);

  return (
    <>
      <section className="login-card py-5 bg-blue align-items-center d-flex">
        <div className="container">
          <Row>
            <Col md={{ span: 4, offset: 4 }}>
              <div className="lgn-frm-card">
                <h4 className="mb-4">Join us</h4>
                <div className="lgn-frm join-frm">
                  <Form>
                    <Row>
                      <Col md={6} lg={6}>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                          <Form.Control type="name" placeholder="First Name" />
                        </Form.Group>
                      </Col>
                      <Col md={6} lg={6}>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput2">
                          <Form.Control type="name" placeholder="Last Name" />
                        </Form.Group>
                      </Col>
                      <Col md={12} lg={12}>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput3">
                          <Form.Control type="email" placeholder="Email address" />
                        </Form.Group>
                      </Col>
                      <Col md={12} lg={12}>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput4">
                          <Form.Control
                            type="password"
                            placeholder="Password (8 or more characters)"
                          />
                        </Form.Group>
                      </Col>
                      <Col md={12} lg={12}>
                        <Select
                          className="basic-single mb-3"
                          classNamePrefix="select"
                          isDisabled={isDisabled}
                          isLoading={isLoading}
                          isClearable={isClearable}
                          isRtl={isRtl}
                          isSearchable={isSearchable}
                          name="colordscs"
                        />
                      </Col>
                      <Col md={12} lg={12}>
                        <Select
                          className="basic-single mb-3"
                          classNamePrefix="select"
                          isDisabled={isDisabled}
                          isLoading={isLoading}
                          isClearable={isClearable}
                          isRtl={isRtl}
                          isSearchable={isSearchable}
                          name="color"
                        />
                      </Col>
                      <Col md={12} lg={12}>
                        <div className="condition-card">
                          <p>
                            By clicking Join Now, you agree to AlmaBay's{' '}
                            <Link to="/terms_of_use">Terms of Use </Link>
                            and <Link to="privacypolicy">Privacy Policy</Link>
                          </p>
                        </div>
                      </Col>
                    </Row>
                    <div className="frm-btn mt-2">
                      <Button className="cmmn-btn">Join Now</Button>
                    </div>
                  </Form>
                </div>
                <SocialMedia />
                <div className="email-lnk-card mt-3 text-center">
                  <p className="mb-0">
                    Already a user?
                    <Link className="email-lnk" to="register">
                      Log In
                    </Link>
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </section>
      <FooterMain className={'colour-bg'} />
    </>
  );
}

export default RegisterPage;
