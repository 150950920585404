import { Form } from 'react-bootstrap';
import { addPagePrivacy } from '../../../services/JobServices';
import showNotification from '../../../services/NotificationService';

function PagePrivacySetting({ details, setDetails, pageId, renderFunction }) {
  const handleChange = (e) => {
    const { name, value } = e.target;

    setDetails({ ...details, [name]: value });
  };
  const handleSave = () => {
    const body = {
      timeline_post_privacy: details.timeline_post_privacy,
      message_privacy: details.message_privacy,
      timeline_id: pageId
    };
    addPagePrivacy(body).then((res) => {
      if (res?.status === 200) {
        renderFunction();
        showNotification('success', res?.data?.message);
      }
    });
  };

  return (
    <div className="admin-role-frnt bg-white mb-3">
      <h4 className="admin-role-hd">Privacy Settings</h4>
      <ul className="admin-role-lst">
        <li className="d-flex align-items-center justify-content-between">
          <div className="privacy-role-card d-flex align-items-center">
            <h5>Allow people to post on Page's timeline:</h5>
            <Form.Select
              aria-label="Default select example"
              onChange={handleChange}
              name="timeline_post_privacy"
              value={details?.timeline_post_privacy}
            >
              <option value="everyone">Yes</option>
              <option value="none">No</option>
            </Form.Select>
          </div>
        </li>
        <li className="d-flex align-items-center justify-content-between">
          <div className="privacy-role-card d-flex align-items-center">
            <h5>Allow people to send messages to Page:</h5>
            <Form.Select
              aria-label="Default select example"
              onChange={handleChange}
              name="message_privacy"
              value={details?.message_privacy}
            >
              <option value="everyone">Yes</option>
              <option value="none">No</option>
            </Form.Select>
          </div>
        </li>
      </ul>
      <div className="privay-ftr p-3">
        <button
          className="apply-btn"
          type="button"
          onClick={(e) => {
            handleSave();
          }}
        >
          Save Changes
        </button>
      </div>
    </div>
  );
}

export default PagePrivacySetting;
