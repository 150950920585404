import { useEffect, useRef, useState } from 'react';
import { Col, Form, InputGroup, Row, Spinner } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import {
  emailRegex,
  restrictAlpha,
  useOnClickOutside
} from '../../../globals/helper';
import showNotification from '../../../services/NotificationService';
import * as APISERVICES from '../../../services/UserServices';
import { ContactSimmer } from '../skelton/allskelton';

function ContactForm() {
  const ref = useRef();
  const [activeClass, setAcviteClass] = useState({
    email: false,
    mobile: false,
    website: false,
    facebook: false,
    linkedIn: false
  });
  const [userInfo, setUserInfo] = useState('');
  const [state, setState] = useState('');
  const [loading, setLoading] = useState(true);
  const [miniLoader, setMiniLoader] = useState(false);

  useOnClickOutside(ref, (e) => saveHandler(e));

  useEffect(() => {
    getUserInfo();
  }, []);

  const getUserInfo = () => {
    setLoading(true);
    APISERVICES.userBasicInfo().then((res) => {
      setLoading(false);
      if (res?.status === 200) {
        setUserInfo(res?.data?.contactInfo);
        setState(res?.data?.contactInfo);
      }
    });
  };

  const validate = (values) => {
    let errors = {};
    if (!values.email) {
      errors.email = 'please enter your email';
    } else if (!emailRegex.test(values.email)) {
      errors.email = 'please enter valid email';
    }
    if (!values.mobile) {
      errors.mobile = 'please enter your mobile number';
    } else if (values.mobile.length <= 9) {
      errors.mobile = 'please enter atleast 10 digit';
    }
    return errors;
  };

  const onChangeHandler = (e) => {
    const { name, value } = e.target;
    setUserInfo({ ...userInfo, [name]: value });
  };

  const saveHandler = (e) => {
    e.preventDefault();
    if (JSON.stringify(userInfo) !== JSON.stringify(state)) {
      if (!Object.keys(validate(userInfo)).length) {
        setMiniLoader(true)
        let formData = new FormData();
        for (let i in userInfo) {
          formData.append(`${i}`, userInfo[i] != null ? userInfo[i] : "");
        }
        APISERVICES.updateContactInfo(formData).then((res) => {
          setMiniLoader(false)
          resetClass();
          if (res?.status === 200) {
            showNotification('success', res?.data?.message);
            getUserInfo();
          }
        });
      }
    } else {
      resetClass();
    }
    //validate
  };

  const resetClass = () => {
    setAcviteClass({
      email: false,
      mobile: false,
      website: false,
      facebook: false,
      linkedIn: false
    });
  };

  return (
    <>
      <div className="detail_form_div" >
        <form className="contact-details" onSubmit={e => e.preventDefault()} ref={ref}>
          <h2>Contact details</h2>
          <div>
            {loading ? (
              <>
                <ContactSimmer />
                <ContactSimmer />
                <ContactSimmer />
                <ContactSimmer />
                <ContactSimmer />
              </>
            ) : (
              <>
                <Row className={`form-group ${activeClass.email ? 'active' : ''}`} id="email">
                  <Col sm={4} xs={12}>
                    <label className="" htmlFor="email">
                      Email<sup className="text-danger">*</sup>
                    </label>
                  </Col>
                  <Col sm={6} xs={8}>
                    {activeClass.email ? (
                      <>
                        <InputGroup className="w-100">
                          <Form.Control
                            placeholder="Enter your Email Id"
                            aria-label="Username"
                            aria-describedby="basic-addon1"
                            value={userInfo?.email}
                            name="email"
                            id="email"
                            onChange={(e) => {
                              onChangeHandler(e);
                            }}
                            autoComplete="off"
                          />
                        </InputGroup>
                        <div
                          className={`${activeClass.email && validate(userInfo).email ? '' : 'd-none'
                            } danger-frm `}
                        >
                          {activeClass.email && validate(userInfo).email}
                        </div>
                      </>
                    ) : (
                      <div
                        className="card-frm-detail"
                        role="presentation"
                        onClick={() => {
                          setAcviteClass({
                            email: true,
                            mobile: false,
                            website: false,
                            facebook: false,
                            linkedIn: false
                          });
                        }}
                      >
                        <p>{state?.email}</p>
                      </div>
                    )}
                  </Col>
                  <Col sm={2} xs={4}>
                    <Spinner animation="border" className={activeClass?.email && miniLoader ? 'me-2 d-block' : 'd-none'} size="sm" variant="warning" />

                    {activeClass?.email && !miniLoader && <>
                      <Link
                        to="#"
                        title="Cancel"
                        className="me-2 cross_btn"
                        onClick={() => {
                          setAcviteClass({
                            email: false,
                            mobile: false,
                            website: false,
                            facebook: false,
                            linkedIn: false
                          });
                        }}
                      >
                        <i className="fas fa-times"></i>
                      </Link>
                      <Link
                        to="#"
                        title="Save"
                        className="me-2 correct_btn"
                        onClick={(e) => {
                          saveHandler(e);
                        }}
                      >
                        <i className="fas fa-check"></i>
                      </Link>
                    </>}

                    <Link
                      to="#"
                      onClick={() => {
                        setAcviteClass({
                          email: true,
                          mobile: false,
                          website: false,
                          facebook: false,
                          linkedIn: false
                        });
                      }}
                    >
                      <i
                        role="presentation"
                        onClick={(e) => e.preventDefault()}
                        className="fa fa-chevron-right"
                        title="EDIT"
                      ></i>
                    </Link>
                  </Col>
                </Row>{' '}
                <Row className={`form-group ${activeClass.mobile ? 'active' : ''}`} id="mobile">
                  <Col sm={4} xs={12}>
                    <label className="" htmlFor="firstname">
                      Mobile<sup className="text-danger">*</sup>
                    </label>
                  </Col>
                  <Col sm={6} xs={8}>
                    {activeClass.mobile ? (
                      <>
                        <InputGroup className="w-100">
                          <Form.Control
                            placeholder="Enter your mobile number"
                            aria-label="Username"
                            aria-describedby="basic-addon1"
                            value={userInfo?.mobile}
                            onChange={(e) => {
                              onChangeHandler(e);
                            }}
                            name="mobile"
                            type="text"
                            // onKeyDown={}
                            onKeyPress={restrictAlpha}
                            maxLength={10}
                          />
                        </InputGroup>
                        <div
                          className={`${activeClass?.mobile && validate(userInfo)?.mobile ? '' : 'd-none'
                            } danger-frm `}
                        >
                          {activeClass?.mobile && validate(userInfo)?.mobile}
                        </div>
                      </>
                    ) : (
                      <div
                        className="card-frm-detail"
                        role="presentation"
                        onClick={() => {
                          setAcviteClass({
                            email: false,
                            mobile: true,
                            website: false,
                            facebook: false,
                            linkedIn: false
                          });
                        }}
                      >
                        <p>+91 {state?.mobile}</p>
                      </div>
                    )}
                  </Col>
                  <Col sm={2} xs={4}>
                    <Spinner animation="border" className={activeClass?.mobile && miniLoader ? 'me-2 d-block' : 'd-none'} size="sm" variant="warning" />
                    {activeClass?.mobile && !miniLoader &&
                      <>
                        <Link
                          to="#"
                          title="Cancel"
                          className="me-2 cross_btn"
                          onClick={() => {
                            setAcviteClass({
                              email: false,
                              mobile: false,
                              website: false,
                              facebook: false,
                              linkedIn: false
                            });
                          }}
                        >
                          <i className="fas fa-times"></i>
                        </Link>
                        <Link
                          to="#"
                          title="Save"
                          className="me-2 correct_btn"
                          onClick={(e) => {
                            saveHandler(e);
                          }}
                        >
                          <i className="fas fa-check"></i>
                        </Link>
                      </>}
                    <Link
                      to="#"
                      onClick={() => {
                        setAcviteClass({
                          email: false,
                          mobile: true,
                          website: false,
                          facebook: false,
                          linkedIn: false
                        });
                      }}
                    >
                      <i
                        role="presentation"
                        className="fa fa-chevron-right"
                        title="EDIT"
                        onClick={(e) => e.preventDefault()}
                      ></i>
                    </Link>
                  </Col>
                </Row>{' '}
                <Row className={`form-group ${activeClass.website ? 'active' : ''}`}>
                  <Col sm={4} xs={12}>
                    <label className="" htmlFor="website">
                      Website
                    </label>
                  </Col>
                  <Col sm={6} xs={8}>
                    {activeClass.website ? (
                      <>
                        <InputGroup className="w-100">
                          <Form.Control
                            placeholder="Enter your website"
                            aria-label="Username"
                            aria-describedby="basic-addon1"
                            value={userInfo?.website}
                            name="website"
                            id="website"
                            onChange={(e) => {
                              onChangeHandler(e);
                            }}
                          />
                        </InputGroup>
                        {/* <div
                      className={`${
                        activeClass?.website && validate(userInfo)?.website ? '' : 'd-none'
                      } danger-frm `}>
                      {activeClass?.website && validate(userInfo)?.website}
                    </div> */}
                      </>
                    ) : (
                      <div
                        className="card-frm-detail"
                        role="presentation"
                        onClick={() => {
                          setAcviteClass({
                            email: false,
                            mobile: false,
                            website: true,
                            facebook: false,
                            linkedIn: false
                          });
                        }}
                      >
                        <p>{state?.website}</p>
                      </div>
                    )}
                  </Col>
                  <Col sm={2} xs={4}>
                    <Spinner animation="border" className={activeClass?.website && miniLoader ? 'me-2 d-block' : 'd-none'} size="sm" variant="warning" />
                    {activeClass?.website && !miniLoader &&
                      <>
                        <Link
                          to="#"
                          title="Cancel"
                          className="me-2 cross_btn"
                          onClick={() => {
                            setAcviteClass({
                              email: false,
                              mobile: false,
                              website: false,
                              facebook: false,
                              linkedIn: false
                            });
                          }}
                        >
                          <i className="fas fa-times"></i>
                        </Link>
                        <Link
                          to="#"
                          title="Save"
                          className="me-2 correct_btn"
                          onClick={(e) => {
                            saveHandler(e);
                          }}
                        >
                          <i className="fas fa-check"></i>
                        </Link>
                      </>}
                    <Link
                      to="#"
                      onClick={() => {
                        setAcviteClass({
                          email: false,
                          mobile: false,
                          website: true,
                          facebook: false,
                          linkedIn: false
                        });
                      }}
                    >
                      <i
                        role="presentation"
                        onClick={(e) => e.preventDefault()}
                        className="fa fa-chevron-right"
                        title="EDIT"
                      ></i>
                    </Link>
                  </Col>
                </Row>
                <Row className={`form-group ${activeClass.facebook ? 'active' : ''}`}>
                  <Col sm={4} xs={12}>
                    <label className="" htmlFor="firstname">
                      Facebook Profile URL
                    </label>
                  </Col>
                  <Col sm={6} xs={8}>
                    {activeClass.facebook ? (
                      <InputGroup className="w-100">
                        <Form.Control
                          placeholder="Enter name of your facebook profile link"
                          aria-label="Username"
                          aria-describedby="basic-addon1"
                          value={userInfo?.facebook_id}
                          onChange={(e) => {
                            onChangeHandler(e);
                          }}
                          name="facebook_id"
                        />
                      </InputGroup>
                    ) : (
                      <div
                        className="card-frm-detail"
                        role="presentation"
                        onClick={() => {
                          setAcviteClass({
                            email: false,
                            mobile: false,
                            website: false,
                            facebook: true,
                            linkedIn: false
                          });
                        }}
                      >
                        <p>{state?.facebook_id}</p>
                      </div>
                    )}
                  </Col>
                  <Col sm={2} xs={4}>
                    <Spinner animation="border" className={activeClass?.facebook && miniLoader ? 'me-2 d-block' : 'd-none'} size="sm" variant="warning" />

                    {activeClass?.facebook && !miniLoader &&
                      <>
                        <Link
                          to="#"
                          title="Cancel"
                          className="me-2 cross_btn"
                          onClick={() => {
                            setAcviteClass({
                              email: false,
                              mobile: false,
                              website: false,
                              facebook: false,
                              linkedIn: false
                            });
                          }}
                        >
                          <i className="fas fa-times"></i>
                        </Link>
                        <Link
                          to="#"
                          title="Save"
                          className="me-2 correct_btn"
                          onClick={(e) => {
                            saveHandler(e);
                          }}
                        >
                          <i className="fas fa-check"></i>
                        </Link>
                      </>}
                    <Link
                      to="#"
                      onClick={() => {
                        setAcviteClass({
                          email: false,
                          mobile: false,
                          website: false,
                          facebook: true,
                          linkedIn: false
                        });
                      }}
                    >
                      <i
                        role="presentation"
                        className="fa fa-chevron-right"
                        title="EDIT"
                        onClick={(e) => e.preventDefault()}
                      ></i>
                    </Link>
                  </Col>
                </Row>
                <Row className={`form-group ${activeClass.linkedIn ? 'active' : ''}`}>
                  <Col sm={4} xs={12}>
                    <label className="" htmlFor="firstname">
                      LinkedIn Profile URL
                    </label>
                  </Col>
                  <Col sm={6} xs={8}>
                    {activeClass.linkedIn ? (
                      <InputGroup className="w-100">
                        <Form.Control
                          placeholder="Enter name of your LinkedIn profile link"
                          aria-label="Username"
                          aria-describedby="basic-addon1"
                          value={userInfo?.linked_in_id}
                          onChange={(e) => {
                            onChangeHandler(e);
                          }}
                          name="linked_in_id"
                        />
                      </InputGroup>
                    ) : (
                      <div
                        className="card-frm-detail"
                        role="presentation"
                        onClick={() => {
                          setAcviteClass({
                            email: false,
                            mobile: false,
                            website: false,
                            facebook: false,
                            linkedIn: true
                          });
                        }}
                      >
                        <p>{state?.linked_in_id}</p>
                      </div>
                    )}
                  </Col>
                  <Col sm={2} xs={4}>
                    <Spinner animation="border" className={activeClass?.linkedIn && miniLoader ? 'me-2 d-block' : 'd-none'} size="sm" variant="warning" />
                    {activeClass?.linkedIn && !miniLoader &&
                      <>
                        <Link
                          to="#"
                          title="Cancel"
                          className="me-2 cross_btn"
                          onClick={() => {
                            setAcviteClass({
                              email: false,
                              mobile: false,
                              website: false,
                              facebook: false,
                              linkedIn: false
                            });
                          }}
                        >
                          <i className="fas fa-times"></i>
                        </Link>
                        <Link
                          to="#"
                          title="Save"
                          className="me-2 correct_btn"
                          onClick={(e) => {
                            saveHandler(e);
                          }}
                        >
                          <i className="fas fa-check"></i>
                        </Link>
                      </>}
                    <Link
                      to="#"
                      onClick={() => {
                        setAcviteClass({
                          email: false,
                          mobile: false,
                          website: false,
                          facebook: false,
                          linkedIn: true
                        });
                      }}
                    >
                      <i
                        role="presentation"
                        className="fa fa-chevron-right"
                        title="EDIT"
                        onClick={(e) => e.preventDefault()}
                      ></i>
                    </Link>
                  </Col>
                </Row>
              </>
            )}
            {/* <Row className="loading-skeleton detail_form_loader form-group">
                  <Col sm={4} xs={12}>
                    <label className="" htmlFor="firstname">
                      First Name
                    </label>
                  </Col>
                  <Col sm={6} xs={8}>
                      <InputGroup>
                        <Form.Control
                          id="firstname"
                          placeholder="First Name"
                          value={userInfo?.first_name}
                          name="first_name"
                          aria-label="firstname"
                          aria-describedby="basic-addon1"
                        />
                      </InputGroup>
                  </Col>
                  <Col sm={2} xs={4}>
                    <Link
                      to="#"
                      className="me-2 correct_btn"
                      >
                      <i className="fas fa-check"></i>
                    </Link>
                    <Link
                      to="#"
                      className="me-2 cross_btn"
                      >
                      <i className="fas fa-times"></i>
                    </Link>
                    <Link
                      to="#"
                    >
                      <i className="fa fa-chevron-right"></i>
                    </Link>
                  </Col>
              </Row> */}
          </div>
        </form>
      </div >
    </>
  );
}

export default ContactForm;
