import { Link, useNavigate } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useEffect, useState } from 'react';
import * as APISERVICES from '../../../services/ShopServices';

function CollegeProductGrid() {
  const navigate = useNavigate();

  const [productList, setProductList] = useState([]);
  useEffect(() => {
    getSchoolAndCollege();
  }, []);

  const getSchoolAndCollege = () => {
    APISERVICES.featureProducts().then((res) => {
      if (res?.status === 200) {
        setProductList(res?.data?.list);
      }
    });
  };

  const handleSingleView = (data) => {
    navigate(`/shop/details/${data?.id}`);
  };

  return (
    <>
      <div className="product-grid">
        <Row>
          {productList &&
            productList?.map((data) => (
              <Col md={3} lg={3} key={data?.id}>
                <div
                  className="product-card"
                  role="presentation"
                  onClick={() => handleSingleView(data)}
                >
                  <Link to="#">
                    <div role="presentation" className="product-img">
                      <img src={process.env.REACT_APP_API_DOMAIN + data?.cover_image} alt="" />
                    </div>
                    <div className="product-dis">
                      <p>{data?.name}</p>
                      <ul className="rating-star mb-2">
                        <li>
                          <i className="far fa-star"></i>
                        </li>
                        <li>
                          <i className="far fa-star"></i>
                        </li>
                        <li>
                          <i className="far fa-star"></i>
                        </li>
                        <li>
                          <i className="far fa-star"></i>
                        </li>
                        <li>
                          <i className="far fa-star"></i>
                          {/* to highlist use-far->fas */}
                        </li>
                      </ul>
                      <div className="product-price-card d-flex align-items-center">
                        <h4>&#8377;{data?.price} </h4>
                        {/* <del>&#8377;299.00</del> */}
                        {/* <span>33%</span> */}
                      </div>
                    </div>
                  </Link>
                </div>
              </Col>
            ))}
        </Row>
      </div>
    </>
  );
}

export default CollegeProductGrid;
