/**
@copyright  :   ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
@author     :   Shiv Charan Panjeta 
 
All Rights Reserved.
Proprietary and confidential :  All information contained here in is, and remains
the property of ToXSL Technologies Pvt. Ltd. and it's partners.
Unauthorized copying of this file, via any medium is strictly prohibited.
*/
import { useEffect, useState } from 'react';
import { Button, Container } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import AsyncSelect from 'react-select/async';
import AsyncCreatableSelect from 'react-select/async-creatable';
import CreatableSearch from '../../components/commoncomponents/CreatableSearch';
import CKEditorComponent from '../../components/webpages/user-pages/PostComponets/CKEditorComponent';
import {
  emailRegex,
  getCTCDropDown,
  getOpeningDropDown,
  restrictAlpha
} from '../../globals/helper';
import useDocumentTitle from '../../globals/useDocumentTitle';
import {
  addJob,
  editJobManage,
  getCompanyDropDown,
  getJobDetail,
  getJobKeyWordDrop,
  getJobLocationCities
} from '../../services/AuthServices';
import showNotification from '../../services/NotificationService';
import { loadJObLocationOption, loadJobCategoryOption } from '../AdminHelper';

export default function AddJob() {
  useDocumentTitle('Add New Job | AlmaBay');
  const components = {
    DropdownIndicator: null,
    IndicatorSeparator: () => null,
    LoadingIndicator: () => null
  };
  const [inputValue, setInputValue] = useState('');
  const [keyWord, setkeyWord] = useState([]);
  let multiKeyWord = keyWord?.map((data) => data?.id);
  // let skillsId = keyWord?.map((data) => data?.id);
  const [jobDescription, setJobDescription] = useState('');
  const [openingData, setOpeningData] = useState([]);
  const [remainingJobDetail, setRemainingJobDetail] = useState({
    title: '',
    openPosition: '',
    minWorkExp: '',
    maxWorkExp: '',
    minCTC: '',
    maxCTC: '',
    hrEmail: '',
    contactNumber: '',
    jobType: '0',
    autoExpiry: false,
    expiryDate: '',
    jobFinder: true
  });
  const [clicked, setClicked] = useState(false);
  const [jobCategory, setJobCategory] = useState('');
  const [hiringSearch, setHiringSearch] = useState('');
  const [locationSearch, setLocationSearch] = useState('');
  const [keyWordOption, setKeyWordOption] = useState([]);
  const [jobLocation, setJobLocation] = useState('');
  const [hiringFor, setHiringFor] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const roleType = useSelector((state) => state?.user?.details);
  let id = location?.state;

  useEffect(() => {
    getOpeningDropDown(setOpeningData);
    getKeyWordDrop();
    if (id) {
      getDetail(id);
    }
  }, []);

  const getDetail = (id) => {
    getJobDetail(id).then((res) => {
      if (res?.status === 200) {
        const data = res?.data?.data;
        setRemainingJobDetail({
          title: data?.details?.job_title,
          openPosition: data?.details?.open_positions,
          minWorkExp: data?.details?.min_exp,
          maxWorkExp: data?.details?.max_exp,
          minCTC: data?.details?.min_ctc,
          maxCTC: data?.details?.max_ctc,
          hrEmail: data?.details?.hr_email,
          contactNumber: data?.details?.contact_number,
          jobType: data?.details?.job_type,
          autoExpiry: data?.details?.auto_expiry_check,
          expiryDate: data?.details?.expiry_date,
          jobFinder: true
        });
        setJobDescription(data?.details?.job_info);
        setJobCategory({
          id: data?.details?.jobCategory?.id,
          label: data?.details?.jobCategory?.name,
          value: data?.details?.jobCategory?.name
        });
        setJobLocation({
          id: data?.details?.location,
          label: data?.details?.job_location_meta,
          value: data?.details?.job_location_meta
        });
        setkeyWord(
          data?.jobs_keywords?.map((item) => {
            return {
              id: item?.id,
              label: item?.text,
              value: item?.text
            };
          })
        );
        setHiringFor({
          id: data?.details?.company?.institute_id,
          label: data?.details?.company?.name,
          value: data?.details?.company?.name
        });
      }
    });
  };

  useEffect(() => {
    if (jobLocation?.id) {
      citiesById(jobLocation?.id);
    }
  }, [jobLocation?.id]);

  const citiesById = () => {
    getJobLocationCities(jobLocation?.id).then((resp) => {
      setJobLocation(resp?.data?.data[0]?.city);
      setJobLocation({
        id: resp?.data?.data[0]?.city_id,
        label: resp?.data?.data[0]?.city,
        value: resp?.data?.data[0]?.city
      });
    });
  };

  const getKeyWordDrop = (value) => {
    return getJobKeyWordDrop(value ? value : inputValue).then((res) => {
      if (res?.status === 200) {
        let tempArr = [];

        let data = res?.data?.data?.allCategories;
        if (data?.length) {
          data?.slice(0, 20).map((data) => {
            tempArr.push({
              id: data?.id,
              label: data?.text,
              value: data?.text
            });
          });
        }
        return tempArr;
      }
    });
  };

  const handleChange = (e) => {
    const { name, value, checked } = e.target;
    if (name == 'autoExpiry' || name == 'jobFinder') {
      setRemainingJobDetail({
        ...remainingJobDetail,
        [name]: checked
      });
    } else {
      setRemainingJobDetail({
        ...remainingJobDetail,
        [name]: value
      });
    }
  };

  const validate = () => {
    let errors = {};
    if (remainingJobDetail?.title == '') {
      errors.title = 'Please enter job title';
    }
    if (jobDescription?.length == 0) {
      errors.jobDescription = 'Please enter job description';
    }
    if (keyWord?.length == 0) {
      errors.keyWord = 'Please enter job keywords';
    }
    if (remainingJobDetail?.minWorkExp == '') {
      errors.minWorkExp = 'Please enter minimum experience';
    }
    if (!remainingJobDetail?.contactNumber) {
      errors.contactNumber = 'Please enter valid Contact number';
    } else if (remainingJobDetail?.contactNumber?.length < 10) {
      errors.contactNumber = 'Please enter valid Contact number';
    }

    if (remainingJobDetail?.maxWorkExp == '') {
      errors.maxWorkExp = 'Please enter maximum experience';
    } else if (+remainingJobDetail?.maxWorkExp <= +remainingJobDetail?.minWorkExp) {
      errors.maxWorkExp = 'max exp should be greater than min exp';
    }
    if (remainingJobDetail?.maxCTC) {
      if (+remainingJobDetail?.maxCTC <= +remainingJobDetail?.minCTC) {
        errors.maxCTC = 'max CTC should be greater than min CTC';
      }
    }
    if (hiringFor?.length == 0 && roleType?.admin_type != '3') {
      errors.hiringFor = 'Please Select compnay ';
    }
    if (jobLocation?.length == 0) {
      errors.jobLocation = 'Please Select location ';
    }
    if (!remainingJobDetail?.hrEmail) {
      errors.email = 'Please enter email';
    } else if (
      remainingJobDetail?.hrEmail != '' &&
      !remainingJobDetail?.hrEmail?.match(emailRegex)
    ) {
      errors.email = 'Please enter valid email';
    }
    return errors;
  };
  const handleSubmit = () => {
    setClicked(true);
    if (!Object.keys(validate()).length) {
      if (id) {
        let body = {
          id: id,
          job_title: remainingJobDetail?.title,
          company_id: hiringFor?.value,
          job_desc: jobDescription,
          job_cat: jobCategory?.id,
          jobs_keywords: multiKeyWord?.toString(),
          positions: remainingJobDetail?.openPosition,
          min_exp: remainingJobDetail?.minWorkExp,
          max_exp: remainingJobDetail?.maxWorkExp,
          min_ctc: remainingJobDetail?.minCTC,
          max_ctc: remainingJobDetail?.maxCTC,
          company_name: hiringFor?.value,
          location: jobLocation?.id,
          hr_email: remainingJobDetail?.hrEmail,
          contact_num: remainingJobDetail?.contactNumber,
          job_type: remainingJobDetail?.jobType,
          allow_job_finder: remainingJobDetail?.jobFinder ? 1 : 0,
          auto_expiry_check: remainingJobDetail?.autoExpiry ? 1 : 0
          // keyword_id: skillsId?.toString()
        };
        let newBody;
        if (remainingJobDetail?.autoExpiry == true) {
          newBody = {
            ...body,
            expiry_date: remainingJobDetail?.expiryDate
          };
        } else {
          newBody = body;
        }
        let formData = new FormData();
        for (let i in newBody) {
          formData.append(`${i}`, newBody[i]);
        }

        editJobManage(formData).then((res) => {
          if (res?.status === 200) {
            showNotification('success', res?.data?.message);
            navigate('/admin/jobs');
          }
        });
      } else {
        let body = {
          job_title: remainingJobDetail?.title,
          company_id: hiringFor?.value || roleType?.institute_id,
          job_desc: jobDescription,
          job_cat: jobCategory?.id,
          jobs_keywords: multiKeyWord?.toString(),
          positions: remainingJobDetail?.openPosition,
          min_exp: remainingJobDetail?.minWorkExp,
          max_exp: remainingJobDetail?.maxWorkExp,
          min_ctc: remainingJobDetail?.minCTC,
          max_ctc: remainingJobDetail?.maxCTC,
          company_name: hiringFor?.value || roleType?.institute_id,
          location: jobLocation?.id,
          hr_email: remainingJobDetail?.hrEmail,
          contact_num: remainingJobDetail?.contactNumber,
          job_type: remainingJobDetail?.jobType,
          allow_job_finder: remainingJobDetail?.jobFinder ? 1 : 0,
          auto_expiry_check: remainingJobDetail?.autoExpiry ? 1 : 0
          // keyword_id: skillsId?.toString()
        };
        let newBody;
        if (remainingJobDetail?.autoExpiry == true) {
          newBody = {
            ...body,
            expiry_date: remainingJobDetail?.expiryDate
          };
        } else {
          newBody = body;
        }
        let formData = new FormData();
        for (let i in newBody) {
          formData.append(`${i}`, newBody[i]);
        }

        addJob(formData).then((res) => {
          if (res?.status === 200) {
            showNotification('success', res?.data?.message);
            navigate('/admin/jobs');
          }
        });
      }
    } else {
      let id = Object.keys(validate()) ? Object.keys(validate())[0] : '';
      if (document.getElementById(id)) {
        document.getElementById(id).scrollIntoView({ behavior: 'smooth', block: 'end' });
      } else {
        return;
      }
    }
  };

  const loadCompanyOption = (inputValue) => {
    if (inputValue.length >= 2) {
      return getCompanyDropDown(inputValue).then((res) => {
        if (res?.status === 200) {
          let tempArr = [];
          if (res?.data?.data?.length) {
            const data = res?.data?.data;
            data?.map((data) => {
              tempArr.push({
                label: data?.name,
                value: data?.id
              });
            });
          }
          return tempArr;
        }
      });
    }
  };

  const dropdownTextForCompanyHiring = () => {
    if (hiringFor?.length > 2) {
      return 'no matches found';
    } else if (!hiringFor) {
      return (
        <div>
          {/* <i className="fa fa-spinner fa-pulse me-2"></i> */}
          <span>Please enter atleast 2 characters</span>
        </div>
      );
    } else {
      return;
    }
  };

  return (
    <div className="dash-main-content">
      <Container fluid>
        <div className="dash-page-content">
          <div className="dash-page-title">
            <Row>
              <Col xs={12} sm={12} md={12}>
                <div className="dash-title-bar d-flex align-items-center justify-content-between">
                  <div className="title-lft d-flex align-items-center">
                    <div className="dash-icn">
                      <i className="fas fa-briefcase"></i>
                    </div>
                    <div className="dash-title-dis">
                      {id ? (
                        <h5>
                          Update <span>Job</span>
                        </h5>
                      ) : (
                        <h5>
                          Add <span>Job</span>
                        </h5>
                      )}
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <div className="dash-content-card">
            <div className="cmmn-form">
              <Form>
                <Row>
                  <Col md={12}>
                    <Form.Group className="mb-3" controlId="formBasicName">
                      <Form.Label>
                        Job Title<span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter job title"
                        name="title"
                        id="title"
                        onChange={handleChange}
                        value={remainingJobDetail?.title}
                      />
                      <div className={'danger-frm'}>{clicked && validate().title}</div>
                    </Form.Group>
                  </Col>

                  <Col md={12}>
                    <Form.Group id="jobDescription" className="mb-3" controlId="formBasicDesi">
                      <Form.Label>
                        Job Description <span className="text-danger">*</span>
                      </Form.Label>
                      <CKEditorComponent
                        isToolBar={true}
                        setLatestPost={setJobDescription}
                        latestPost={jobDescription}
                      />
                      <div className={'danger-frm'}>{clicked && validate().jobDescription}</div>
                    </Form.Group>
                  </Col>

                  <Col md={12}>
                    <Form.Group className="mb-3" controlId="formBasicName">
                      <Form.Label>Job Category</Form.Label>
                      <AsyncSelect
                        cacheOptions
                        defaultOptions
                        value={jobCategory}
                        getOptionLabel={(e) => e.label}
                        getOptionValue={(e) => e.value}
                        loadOptions={loadJobCategoryOption}
                        className="basic-single mt-1"
                        name="location"
                        onChange={setJobCategory}
                        placeholder="Select Category"
                      />
                    </Form.Group>
                  </Col>

                  <Col md={12}>
                    <Form.Group id="keyWord" className="mb-3" controlId="formBasicDesi">
                      <Form.Label>
                        Keywords <span className="text-danger">*</span>
                      </Form.Label>
                      <AsyncSelect
                        cacheOptions
                        defaultOptions
                        value={keyWord}
                        getOptionLabel={(e) => e.label}
                        getOptionValue={(e) => e.value}
                        inputValue={inputValue}
                        onChange={setkeyWord}
                        // placeholder={'Search here '}
                        isMulti={true}
                        loadOptions={getKeyWordDrop}
                        placeholder="Select skillset"
                      />
                      <div className={'danger-frm'}>{clicked && validate().keyWord}</div>
                    </Form.Group>
                  </Col>

                  <Col md={12}>
                    <Form.Group className="mb-3" controlId="formBasicName">
                      <Form.Label>Open positions</Form.Label>
                      <Form.Select
                        aria-label="Default select example"
                        name="openPosition"
                        value={remainingJobDetail?.openPosition}
                        onChange={handleChange}>
                        <option value="">Select no of Open positions</option>
                        {openingData?.length !== 0 ? (
                          <>
                            {openingData?.map((data, index) => {
                              return (
                                <option value={data?.value} key={index}>
                                  {data?.label}
                                </option>
                              );
                            })}
                          </>
                        ) : (
                          <></>
                        )}
                      </Form.Select>
                    </Form.Group>
                  </Col>

                  <Row>
                    <Form.Label>
                      Work experience(in yrs)<span className="text-danger">*</span>
                    </Form.Label>
                    <Col md={4}>
                      <Form.Group className="mb-3" controlId="formBasicName">
                        <Form.Select
                          aria-label="Default select example"
                          name="minWorkExp"
                          onChange={handleChange}
                          value={remainingJobDetail?.minWorkExp}>
                          <option>Minimum</option>
                          <option value="0">0</option>
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                          <option value="5">5</option>
                          <option value="6">6</option>
                          <option value="7">7</option>
                          <option value="8">8</option>
                          <option value="9">9</option>
                          <option value="10">10</option>
                        </Form.Select>
                        <div className={'danger-frm'}>{clicked && validate().minWorkExp}</div>
                      </Form.Group>
                    </Col>
                    <Col md={1}>
                      <div className="to-lst">to</div>
                    </Col>
                    <Col md={4}>
                      <Form.Group className="mb-3" controlId="formBasicName">
                        <Form.Select
                          aria-label="Default select example"
                          name="maxWorkExp"
                          onChange={handleChange}
                          value={remainingJobDetail?.maxWorkExp}>
                          <option>Maximum</option>
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                          <option value="5">5</option>
                          <option value="6">6</option>
                          <option value="7">7</option>
                          <option value="8">8</option>
                          <option value="9">9</option>
                          <option value="10">10</option>
                          <option value="11">10+</option>
                        </Form.Select>
                        <div className={'danger-frm'}>{clicked && validate().maxWorkExp}</div>
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row>
                    <Form.Label>CTC</Form.Label>
                    <Col md={4}>
                      <Form.Group className="mb-3" controlId="formBasicName">
                        <Form.Select
                          aria-label="Default select example"
                          name="minCTC"
                          value={remainingJobDetail?.minCTC}
                          onChange={handleChange}>
                          <option>Minimum</option>
                          <option value="0">0 Lacs</option>
                          {getCTCDropDown()?.map((data, index) => {
                            return (
                              <option value={data} key={index}>
                                {data} Lacs
                              </option>
                            );
                          })}
                        </Form.Select>
                      </Form.Group>
                    </Col>
                    <Col md={1}>
                      <div className="to-lst">to</div>
                    </Col>
                    <Col md={4}>
                      <Form.Group className="mb-3" controlId="formBasicName">
                        <Form.Select
                          aria-label="Default select example"
                          name="maxCTC"
                          value={remainingJobDetail?.maxCTC}
                          onChange={handleChange}>
                          <option>Maximum</option>
                          {getCTCDropDown()?.map((data, index) => {
                            return (
                              <option value={data} key={index}>
                                {data} Lacs
                              </option>
                            );
                          })}
                          <option value="51">50+ Lacs</option>
                        </Form.Select>
                        <div className={'danger-frm'}>{clicked && validate().maxCTC}</div>
                      </Form.Group>
                    </Col>
                  </Row>

                  {roleType?.admin_type == '3' ? (
                    <></>
                  ) : (
                    <Col md={12}>
                      <Form.Group className="mb-3" controlId="formBasicName">
                        <Form.Label>
                          Company hiring for <span className="text-danger">*</span>
                        </Form.Label>
                        <AsyncCreatableSelect
                          components={components}
                          blurInputOnSelect
                          onMenuClose={() => setLocationSearch(null)}
                          value={hiringFor}
                          menuIsOpen={hiringSearch !== '' ? true : false}
                          getOptionLabel={(e) => e.label}
                          getOptionValue={(e) => e.value}
                          loadOptions={loadCompanyOption}
                          onInputChange={(newValue) => setHiringSearch(newValue)}
                          className="basic-single mt-1"
                          name="location"
                          onChange={setHiringFor}
                          onCreateOption={(e) => {
                            setHiringFor({
                              label: e,
                              value: e
                            });
                          }}
                        />
                      </Form.Group>
                      <div className={'danger-frm'}>{clicked && validate().hiringFor}</div>
                    </Col>
                  )}

                  <Col md={12}>
                    <Form.Group className="mb-3" controlId="formBasicName">
                      <Form.Label>
                        Location <span className="text-danger">*</span>
                      </Form.Label>

                      <AsyncSelect
                        cacheOptions
                        defaultOptions
                        value={jobLocation}
                        menuIsOpen={locationSearch !== '' ? true : false}
                        getOptionLabel={(e) => e.label}
                        getOptionValue={(e) => e.value}
                        loadOptions={loadJObLocationOption}
                        onInputChange={(newValue) => setLocationSearch(newValue)}
                        className="basic-single mt-1"
                        name="location"
                        onChange={setJobLocation}
                      />
                    </Form.Group>

                    <div className={'danger-frm'}>{clicked && validate().jobLocation}</div>
                  </Col>

                  <Col md={12}>
                    <Form.Group className="mb-3" controlId="formBasicName">
                      <Form.Label>
                        HR email <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter the email ID to whom user can contact directly"
                        name="hrEmail"
                        value={remainingJobDetail?.hrEmail}
                        onChange={handleChange}
                      />
                      <div className={'danger-frm'}>{clicked && validate().email}</div>
                    </Form.Group>
                  </Col>

                  <Col md={12}>
                    <Form.Group className="mb-3" controlId="formBasicName">
                      <Form.Label>
                        Contact number <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Mobile number to contact"
                        name="contactNumber"
                        value={remainingJobDetail?.contactNumber}
                        onChange={handleChange}
                        onKeyPress={restrictAlpha}
                        maxLength={10}
                        max="10"
                        min="5"
                      />
                      <div className={'danger-frm'}>{clicked && validate().contactNumber}</div>
                    </Form.Group>
                  </Col>

                  <Col md={12}>
                    <Form.Group className="mb-3" controlId="formBasicName">
                      <Form.Label>Job Type</Form.Label>
                      <Form.Select
                        aria-label="Default select example"
                        name="jobType"
                        onChange={handleChange}
                        value={remainingJobDetail?.jobType}>
                        <option>Select job type </option>
                        <option value="0">Full Time</option>
                        <option value="1">Part Time</option>
                        <option value="2">Internship</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>

                  <Col md={12}>
                    <Form.Group className="mb-3" controlId="formBasicCheckbox1">
                      <Form.Check
                        type="checkbox"
                        label="Auto Expiry (By default job will be automatically expired in 30 days)"
                        name="autoExpiry"
                        checked={remainingJobDetail?.autoExpiry}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                  {remainingJobDetail?.autoExpiry && (
                    <Col md={6}>
                      <Form.Group className="mb-3" controlId="formBasicName">
                        <Form.Control
                          type="date"
                          min={new Date().toISOString()?.split('T')[0]}
                          placeholder=""
                          onKeyDown={(e) => {
                            e.preventDefault();
                          }}
                          name="expiryDate"
                          value={remainingJobDetail?.expiryDate}
                          onChange={handleChange}
                        />
                      </Form.Group>
                    </Col>
                  )}

                  <Col md={12}>
                    <Form.Group className="mb-3" controlId="formBasicCheckbox2">
                      <Form.Check
                        type="checkbox"
                        label=" Allow job finder to get referred from other people working in the same company"
                        name="jobFinder"
                        checked={remainingJobDetail?.jobFinder}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <div className="form-btns d-flex align-items-center mt-3">
                  <button className="green_button_admin" type="button" onClick={handleSubmit}>
                    Save
                  </button>
                  <strong>OR</strong>
                  <Button
                    className="main-btn-red"
                    type="button"
                    onClick={() => navigate('/admin/jobs')}>
                    Cancel
                  </Button>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}
