/**
@copyright  :   ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
@author     :   Shiv Charan Panjeta 
 
All Rights Reserved.
Proprietary and confidential :  All information contained here in is, and remains
the property of ToXSL Technologies Pvt. Ltd. and it's partners.
Unauthorized copying of this file, via any medium is strictly prohibited.
*/

import { useEffect, useState } from 'react';
import { Button, Container } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { numWithdecimal, restrictAlpha } from '../../../globals/helper';
import useDocumentTitle from '../../../globals/useDocumentTitle';
import { HideAlert, ShowAlert } from '../../../redux/action';
import {
  addShopCategory,
  getCategoryDropforShop,
  getShopCatDetail
} from '../../../services/AuthServices';
import showNotification from '../../../services/NotificationService';

function AddCategory() {
  const dispatch = useDispatch();
  const [clicked, setClicked] = useState(false);
  const [categoryOption, setCategoryOption] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const id = location?.state;
  const [savedImage, setSavedImage] = useState(false);
  const [categoryDetail, setCategroryDeatil] = useState({
    cat_name: '',
    cat_slug: '',
    cat_level: '0',
    cat_discount: '',
    cat_decp: '',
    sort_order: '',
    cat_image: ''
  });

  useEffect(() => {
    loadCategoryOptions();
    if (id) {
      getDetail(id);
    }
  }, []);

  const getDetail = (id) => {
    getShopCatDetail(id).then((res) => {
      if (res?.status === 200) {
        const data = res?.data?.message?.catInfo;
        setCategroryDeatil({
          cat_name: data?.cat_name,
          cat_slug: data?.cat_slug,
          cat_level: data?.cat_level,
          cat_discount: data?.cat_discount,
          cat_decp: data?.cat_decp,
          sort_order: data?.sort_order,
          cat_image: data?.cat_image,
          id: data?.id
        });
        setSavedImage(data?.cat_image || '');
      }
    });
  };
  const handleChange = (e) => {
    const { name, value, type } = e.target;
    if (type === 'file') {
      let file = e?.target?.files[0];
      setCategroryDeatil({ ...categoryDetail, [name]: file });
    } else {
      setCategroryDeatil({ ...categoryDetail, [name]: value });
    }
  };

  const loadCategoryOptions = () => {
    getCategoryDropforShop().then((res) => {
      if (res?.status === 200) {
        // let tempArr = [];
        // if (res?.data?.data) {
        //   const data = res?.data?.data;
        //   data?.map((data) => {
        //     tempArr.push({
        //       id: data?.key,
        //       label: data?.value,
        //       value: data?.value
        //     });
        //   });
        // }
        setCategoryOption(res?.data?.message?.allMainCats);
      }
    });
  };

  const validate = () => {
    let errors = {};
    if (categoryDetail?.cat_name === '') {
      errors.name = 'This field is required.';
    }
    if (categoryDetail?.cat_slug === '') {
      errors.slug = 'This field is required.';
    }

    return errors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setClicked(true);
    if (!Object.keys(validate()).length) {
      if (categoryDetail?.cat_discount !== '') {
        if (!numWithdecimal.test(categoryDetail?.cat_discount)) {
          showNotification('danger', 'Invalid discount input');
          return false;
        } else if (categoryDetail?.cat_discount > 100) {
          showNotification('danger', 'Max Discount can be upto 100%');
          return false;
        }
      }
      let formData = new FormData();
      for (let i in categoryDetail) {
        formData.append(`${i}`, categoryDetail[i]);
      }
      addShopCategory(formData).then((res) => {
        if (res?.status === 200) {
          navigate('/admin/categories');
          dispatch(ShowAlert(res?.data?.message));
        }
      });
    }
  };

  const backHandler = () => {
    dispatch(HideAlert(''));
    navigate('/admin/categories');
  };

  let firstElement = '<option value=0>  -- Root Level -- </option>';

  return (
    <div className="dash-main-content">
      <Container fluid>
        <div className="dash-page-content">
          <div className="dash-page-title">
            <Row>
              <Col xs={12} sm={12} md={12}>
                <div className="dash-title-bar d-flex align-items-center justify-content-between">
                  <div className="title-lft d-flex align-items-center">
                    {/* <div className="dash-icn">
                        <i className="fas fa-users-cog"></i>
                      </div> */}
                    <div className="dash-title-dis">
                      {id
                        ? useDocumentTitle('Edit Category | AlmaBay')
                        : useDocumentTitle('Add New Category | AlmaBay')}

                      {<h5>{id ? 'Edit' : 'Add'} Category</h5>}
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <div className="dash-content-card">
            <div className="cmmn-form">
              <Form>
                <Row>
                  <Col md={12}>
                    <Form.Group className="mb-3" controlId="formBasicName">
                      <Form.Label>
                        Name <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="cat_name"
                        placeholder="Enter the category name. Eg: Mobiles"
                        onChange={handleChange}
                        value={categoryDetail?.cat_name}
                      />
                      <div className={'danger-frm px-0'}>{clicked && validate().name}</div>
                    </Form.Group>
                  </Col>
                  <Col md={12}>
                    <Form.Group className="mb-3" controlId="formBasicName">
                      <Form.Label>
                        Slug <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter the category unique name"
                        name="cat_slug"
                        onChange={handleChange}
                        value={categoryDetail?.cat_slug}
                      />
                      <div className={'danger-frm px-0'}>{clicked && validate().slug}</div>
                    </Form.Group>
                  </Col>
                  <Col md={12}>
                    <Form.Group className="mb-3" controlId="formBasicName">
                      <Form.Label>Select Category Level</Form.Label>

                      <Form.Select
                        name="cat_level"
                        value={categoryDetail?.cat_level}
                        dangerouslySetInnerHTML={{
                          __html: firstElement + categoryOption
                        }}
                        onChange={handleChange}></Form.Select>
                      {/* <Select
                          className="basic-single mb-3"
                          classNamePrefix="select"
                          isDisabled={isDisabled}
                          isLoading={isLoading}
                          isClearable={isClearable}
                          isRtl={isRtl}
                          isSearchable={isSearchable}
                          name="course_name"
                        /> */}
                    </Form.Group>
                  </Col>
                  <Col md={12}>
                    <Form.Group className="mb-3" controlId="formBasicName">
                      <Form.Label>Discount (%)</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter the product discount."
                        name="cat_discount"
                        onChange={handleChange}
                        onKeyPress={restrictAlpha}
                        maxLength={3}
                        value={categoryDetail?.cat_discount}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={12}>
                    <Form.Group className="mb-3" controlId="formBasicName">
                      <Form.Label>Description</Form.Label>
                      <Form.Control
                        as="textarea"
                        type="text"
                        name="cat_decp"
                        placeholder="Enter Category description."
                        onChange={handleChange}
                        value={categoryDetail?.cat_decp}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={12}>
                    <Form.Group className="mb-3" controlId="formBasicName">
                      <Form.Label>Sort Order</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter the sort order"
                        name="sort_order"
                        maxLength={256}
                        onChange={handleChange}
                        value={categoryDetail?.sort_order}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={12}>
                    <Form.Group controlId="formFile" className="mb-3">
                      <Form.Label>Category Image</Form.Label>
                      {/* <Form.Control type="file" 
                      
                        name="cat_image" onChange={handleChange} /> */}
                      <Form.Control
                        // onChange={onChangeHandler}
                        onChange={handleChange}
                        name="logo_img"
                        accept="image/*"
                        type="file"
                      />
                    </Form.Group>

                    {savedImage && id && (
                      <div className="d-flex flex-wrap align-item-center">
                        <div className="border me-2">
                          <img width="100px" height="100px" src={savedImage} alt="img1" />
                        </div>
                      </div>
                    )}
                  </Col>
                </Row>
                <div className="form-btns d-flex align-items-center mt-3">
                  <button className="green_button_admin" type="button" onClick={handleSubmit}>
                    Save
                  </button>
                  <strong>OR</strong>
                  <Button className="main-btn-red" type="button" onClick={(e) => backHandler(e)}>
                    Cancel
                  </Button>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}

export default AddCategory;
