import { Link, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import HeaderLogin from '../../commoncomponents/HeaderLogin';
import NewsSidebar from '../../commoncomponents/newsfeeds/NewsSidebar';
import Dropdown from 'react-bootstrap/Dropdown';
import Accordion from 'react-bootstrap/Accordion';
import Offcanvas from 'react-bootstrap/Offcanvas';
import * as index from '../../commoncomponents/skelton/allskelton';
import * as APISERVICES from '../../../services/MentorArticlesServices';
import SendMessageModal from './SendMessageModal';
import TopTabbings from './TopTabbings';
import showNotification from '../../../services/NotificationService';
import useDocumentTitle from '../../../globals/useDocumentTitle';

function Mentees() {
  useDocumentTitle('List of Mentees | AlmaBay');
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [show3, setShow3] = useState(false);
  const handleClose3 = () => setShow3(false);

  const [MenteesLocation, setMenteesLocation] = useState([]);
  const [menteesData, setMenteesData] = useState([]);
  const [menteesIndustry, setMenteesIndustry] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchbyName, setSearchByName] = useState('');
  const [searchLocation, setSearchLocation] = useState('');
  const [cities, setCities] = useState([]);
  const [industries, setIndustries] = useState([]);
  const [seekMentorship, setSeekMentorship] = useState([]);
  const [mentorId, setMentorId] = useState('');
  const [skill, setSkill] = useState([]);

  useEffect(() => {
    getUserMentees();
  }, [cities, industries]);

  const getUserMentees = (search, searchByName = '') => {
    setLoading(true);
    APISERVICES.getUserMantees(search).then((res) => {
      if (res?.status === 200) {
        setLoading(false);
        setMenteesLocation(res?.data?.detail?.all_locations);
        setMenteesIndustry(res?.data?.detail?.all_industries);
        setSeekMentorship(res?.data?.detail?.all_skills);

        if (cities?.length > 0) {
          setMenteesData(
            res?.data?.detail?.mentees?.filter((data) => {
              return cities?.includes(data?.where_provide?.pop()?.city);
            })
          );
        } else if (industries?.length > 0) {
          setMenteesData(
            res?.data?.detail?.mentees?.filter((data) => {
              return industries?.includes(data?.industry?.pop()?.industry);
            })
          );
        } else if (skill?.length > 0) {
          setMenteesData(
            res?.data?.detail?.mentees?.filter((data) => {
              return skill?.includes(data?.skills?.pop()?.name);
            })
          );
        } else {
          setMenteesData(
            searchByName?.length > 0
              ? res?.data?.detail?.mentees?.filter((data) => {
                  return data?.name?.toLowerCase().includes(searchByName.toLowerCase());
                })
              : res?.data?.detail?.mentees
          );
          setSeekMentorship(res?.data?.detail?.all_skills);
        }
      }
    });
  };

  const handleSearch = () => {};
  const handleSearchByLocation = (e) => {
    getUserMentees(e.target.value);
    setSearchLocation(e.target.value);
  };
  const handleReset = () => {
    setSearchByName('');
  };

  const handleSearchCity = (city) => {
    getUserMentees();
    if (cities?.includes(city)) {
      let filterCity = cities.filter((data) => data !== city);
      setCities(filterCity);
    } else {
      setCities([...cities, city]);
    }
  };

  const handleIndustry = (industry) => {
    getUserMentees();
    if (industries?.includes(industry)) {
      let filterIndustry = industries.filter((data) => data !== industry);
      setIndustries(filterIndustry);
    } else {
      setIndustries([...industries, industry]);
    }
  };

  const handleSearchSkills = (name) => {
    getUserMentees();
    if (skill?.includes(name)) {
      let filterSkills = skill.filter((data) => data !== name);
      setSkill(filterSkills);
    } else {
      setSkill([...skill, name]);
    }
  };

  const handleSearchByName = (e) => {
    setSearchByName(e.target.value);
    getUserMentees('', e.target.value);
  };

  const handleRemoveMentee = (id) => {
    const body = {
      user_id: id
    };
    const confirm = window.confirm('Do you want to remove your mentee ?');
    if (confirm) {
      APISERVICES.removeMentee(body).then((res) => {
        if (res?.status == 200) {
          showNotification('success', 'Mentee removed successfully');
          getUserMentees();
        }
      });
    }
  };

  const [industryArr, setIndustryArr] = useState([]);
  const [mentorAreaArr, setMentorAreaArr] = useState([]);
  const [locationArr, setLocationArr] = useState([]);
  const [searchName, setsearchName] = useState('');

  useEffect(() => {
    APISERVICES.filtermentees(
      searchName,
      industryArr.toString(),
      mentorAreaArr.toString(),
      locationArr.toString()
    ).then((res) => {
      if (res?.status === 200) {
        setMenteesData(res?.data?.detail?.mentees);
      }
    });
  }, [mentorAreaArr, industryArr, searchName, locationArr]);

  const getSearchedFilterData = (e, checkboxName) => {
    if (checkboxName == 'industry') {
      let updatedList = [...industryArr];
      if (e.target.checked) {
        updatedList = [...industryArr, e.target.value];
      } else {
        updatedList.splice(industryArr.indexOf(e.target.value), 1);
      }
      setIndustryArr(updatedList);
    }
    if (checkboxName == 'area') {
      let updatedList = [...mentorAreaArr];
      if (e.target.checked) {
        updatedList = [...mentorAreaArr, e.target.value];
      } else {
        updatedList.splice(mentorAreaArr.indexOf(e.target.value), 1);
      }
      setMentorAreaArr(updatedList);
    }
    if (checkboxName == 'loc') {
      let updatedList = [...locationArr];
      if (e.target.checked) {
        updatedList = [...locationArr, e.target.value];
      } else {
        updatedList.splice(locationArr.indexOf(e.target.value), 1);
      }
      setLocationArr(updatedList);
    }
  };
  return (
    <>
      <HeaderLogin />
      <div className="header-lnk-feeds">
        <div className="container">
          <TopTabbings state={'feed'} />
        </div>
      </div>
      <section className="feed-sec bg-grey py-4">
        <div className="container">
          <Row>
            <Col xs={12} lg={2} md={12}>
              <NewsSidebar state="4" current="mentees" />
            </Col>
            <Col xs={12} lg={7} md={12}>
              <div className="mentees-sec p-4 bg-white">
                <ul className="mentees-top-links mb-3">
                  <li>
                    <Link to="/user/friends">Friends</Link>
                  </li>
                  <li>
                    <Link to="/user/mentors">Mentors</Link>
                  </li>
                  <li>
                    <Link className="active" to="/user/mentees">
                      Mentees
                    </Link>
                  </li>
                </ul>

                {loading ? (
                  <>
                    <Row>
                      <Col md={4} sm={4} xs={6}>
                        <index.FriendsSkelton />
                      </Col>
                      <Col md={4} sm={4} xs={6}>
                        <index.FriendsSkelton />
                      </Col>
                      <Col md={4} sm={4} xs={6}>
                        <index.FriendsSkelton />
                      </Col>
                      <Col md={4} sm={4} xs={6}>
                        <index.FriendsSkelton />
                      </Col>
                      <Col md={4} sm={4} xs={6}>
                        <index.FriendsSkelton />
                      </Col>
                      <Col md={4} sm={4} xs={6}>
                        <index.FriendsSkelton />
                      </Col>
                    </Row>
                  </>
                ) : menteesData && menteesData?.length > 0 ? (
                  <div className="mentees-inr-card">
                    <Row>
                      {menteesData?.map((data, index) => {
                        return (
                          <Col md={4} sm={4} xs={6} key={index}>
                            <div className="mentees-pro-card">
                              <div className="mentees-img">
                                <Link to={`/user/timeline/${data?.encrpt_id}`}>
                                  <img
                                    src={
                                      data?.user_image
                                        ? data?.user_image
                                        : require('../../../assets/images/default-male-avatar.png')
                                    }
                                    alt=""
                                  />
                                </Link>
                              </div>
                              <div className="mentees-dis">
                                <Link to={`/user/timeline/${data?.encrpt_id}`}>
                                  <h4>{data?.name}</h4>
                                </Link>
                                <p>{data?.address}</p>
                                <Dropdown className="mt-2">
                                  <Dropdown.Toggle variant="success" id="dropdown-basic">
                                    Your mentee
                                  </Dropdown.Toggle>

                                  <Dropdown.Menu>
                                    <Dropdown.Item
                                      onClick={() => navigate(`/user/timeline/${data?.encrpt_id}`)}
                                    >
                                      View Profile
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      onClick={() => {
                                        setShow3(true);
                                        setMentorId(data?.user_id);
                                      }}
                                    >
                                      Send Message
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      onClick={() => handleRemoveMentee(data?.user_id)}
                                    >
                                      Remove Mentee
                                    </Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </div>
                            </div>
                          </Col>
                        );
                      })}
                    </Row>
                  </div>
                ) : (
                  <div className="upcoming_events p-3">
                    <div className="text-center">
                      <img src={require('../../../assets/images/small-error.png')} alt="img" />
                    </div>
                    <p className="text-center">
                      Oops, Mentees with current selection not available at the moment.
                    </p>
                  </div>
                )}
              </div>
            </Col>
            <Col md={12} lg={3}>
              <Button
                className="mobile-filter-btn d-block d-lg-none "
                variant="primary"
                onClick={handleShow}
              >
                <i className="fas fa-filter"></i>
              </Button>
              <Offcanvas show={show} onHide={handleClose} className="mobile-filter">
                <Offcanvas.Body>
                  <div className="fiter-card ">
                    <div className="filter-hd-card d-flex align-items-center justify-content-between">
                      <div className="filter-hd">
                        <h4>Filter By</h4>
                      </div>
                      <div className="filter-btn">
                        <Link to="#">
                          <i className="fas fa-filter"></i>
                        </Link>
                        <Link to="#">
                          <i className="fas fa-sync-alt"></i>
                        </Link>
                      </div>
                    </div>
                    <div className="filter-select-lst">
                      <Accordion defaultActiveKey={['0', '1', '2', '3']} alwaysOpen flush>
                        <Accordion.Item eventKey="0">
                          <Accordion.Header>By Name</Accordion.Header>
                          <Accordion.Body>
                            <Form.Group className="search-card" controlId="formBasicEmail">
                              <Form.Control
                                type="search"
                                placeholder="Search By Name"
                                onChange={(e) => setsearchName(e.target.value)}
                              />
                              <Button className="search-btn">
                                <i className="fas fa-search"></i>
                              </Button>
                            </Form.Group>
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1">
                          <Accordion.Header>Industry</Accordion.Header>
                          <Accordion.Body>
                            <Form.Group className="search-card" controlId="formBasicEmail">
                              <Form.Control type="search" placeholder="Search By Industry" />
                              <Button className="search-btn">
                                <i className="fas fa-search"></i>
                              </Button>
                            </Form.Group>
                            {menteesIndustry?.map((data, index) => {
                              return (
                                <div key={index}>
                                  <input
                                    type="checkbox"
                                    onChange={() => {
                                      handleIndustry(data?.name);
                                    }}
                                  />

                                  {data?.name}
                                </div>
                              );
                            })}
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="2">
                          <Accordion.Header>Seek Mentorship Area</Accordion.Header>
                          <Accordion.Body>
                            <Form.Group className="search-card" controlId="formBasicEmail">
                              <Form.Control
                                type="search"
                                placeholder="Search By Mentorship Areas"
                              />
                              <Button className="search-btn">
                                <i className="fas fa-search"></i>
                              </Button>
                            </Form.Group>
                            {seekMentorship?.map((data, index) => {
                              return (
                                <div key={index}>
                                  <input
                                    type="checkbox"
                                    onChange={() => {
                                      handleSearchSkills(data?.name);
                                    }}
                                  />
                                  {data?.name}
                                </div>
                              );
                            })}
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="3">
                          <Accordion.Header>Current Location</Accordion.Header>
                          <Accordion.Body>
                            <Form.Group className="search-card" controlId="formBasicEmail">
                              <Form.Control
                                type="search"
                                onChange={handleSearchByLocation}
                                placeholder="Search By Current Location"
                              />
                              <Button className="search-btn">
                                <i className="fas fa-search"></i>
                              </Button>
                            </Form.Group>
                            {MenteesLocation?.map((data, index) => {
                              return (
                                <div key={index}>
                                  <input
                                    type="checkbox"
                                    onChange={() => {
                                      handleSearchCity(data?.city);
                                    }}
                                  />
                                  {data?.city}
                                </div>
                              );
                            })}
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                      <div className="sider-bar-filter p-3 text-end">
                        <button className="accnt-btn" type="button" onClick={handleSearch}>
                          Search
                        </button>
                        <button className="dark-blu-btn ms-2" onClick={handleReset}>
                          Reset
                        </button>
                      </div>
                    </div>
                  </div>
                </Offcanvas.Body>
              </Offcanvas>

              <div className="fiter-card d-none d-lg-block ">
                <div className="filter-hd-card d-flex align-items-center justify-content-between">
                  <div className="filter-hd">
                    <h4>Filter Results</h4>
                  </div>
                  {/* <div className="filter-btn">
                    <Link to="#">
                      <i className="fas fa-filter"></i>
                    </Link>
                    <Link to="#">
                      <i className="fas fa-sync-alt"></i>
                    </Link>
                  </div> */}
                </div>
                <div className="filter-select-lst">
                  <Accordion defaultActiveKey={['0', '1', '2', '3']} alwaysOpen flush>
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>By Name</Accordion.Header>
                      <Accordion.Body>
                        <Form.Group className="search-card" controlId="formBasicEmail">
                          <Form.Control
                            type="search"
                            placeholder="Search By Name"
                            onChange={(e) => setsearchName(e.target.value)}
                          />
                          <Button className="search-btn">
                            <i className="fas fa-search"></i>
                          </Button>
                        </Form.Group>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                      <Accordion.Header>Industry</Accordion.Header>
                      <Accordion.Body>
                        <Form.Group className="search-card" controlId="formBasicEmail">
                          <Form.Control type="search" placeholder="Search By Industry" />
                          <Button className="search-btn">
                            <i className="fas fa-search"></i>
                          </Button>
                        </Form.Group>
                        {menteesIndustry?.map((data, index) => {
                          return (
                            <div key={index}>
                              <input
                                type="checkbox"
                                value={data?.id}
                                onChange={(e) => getSearchedFilterData(e, 'industry')}
                              />

                              {data?.name}
                            </div>
                          );
                        })}
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                      <Accordion.Header>Seek Mentorship Area</Accordion.Header>
                      <Accordion.Body>
                        <Form.Group className="search-card" controlId="formBasicEmail">
                          <Form.Control type="search" placeholder="Search By Mentorship Areas" />
                          <Button className="search-btn">
                            <i className="fas fa-search"></i>
                          </Button>
                        </Form.Group>
                        {seekMentorship?.map((data, index) => {
                          return (
                            <div key={index}>
                              <input
                                type="checkbox"
                                value={data?.id}
                                onChange={(e) => getSearchedFilterData(e, 'area')}
                              />
                              {data?.name}
                            </div>
                          );
                        })}
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="3">
                      <Accordion.Header>Current Location</Accordion.Header>
                      <Accordion.Body>
                        <Form.Group className="search-card" controlId="formBasicEmail">
                          <Form.Control
                            type="search"
                            onChange={handleSearchByLocation}
                            placeholder="Search By Current Location"
                          />
                          <Button className="search-btn">
                            <i className="fas fa-search"></i>
                          </Button>
                        </Form.Group>
                        {MenteesLocation?.map((data, index) => {
                          return (
                            <div key={index}>
                              <input
                                type="checkbox"
                                value={data?.city_id}
                                onChange={(e) => getSearchedFilterData(e, 'loc')}
                              />
                              {data?.city}
                            </div>
                          );
                        })}
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </section>

      <SendMessageModal
        handleClose3={handleClose3}
        show3={show3}
        mentorId={mentorId}
        setMentorId={setMentorId}
      />
    </>
  );
}

export default Mentees;
