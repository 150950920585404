/** @copyright : ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
 @author     : Shiv Charan Panjeta 

 All Rights Reserved.
 Proprietary and confidential :  All information contained herein is, and remains
 the property of ToXSL Technologies Pvt. Ltd. and its partners.
 Unauthorized copying of this file, via any medium is strictly prohibited.
*/

//used to add dymamic content
import { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import { getOpenPageContent } from '../../../services/InstituteServices';
import NoDataFound from '../../commoncomponents/NoDataFound';
import Loader from '../../commoncomponents/loader/loader-large';

function OpenPage() {
  const [list, setList] = useState();
  const { id } = useParams();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (id) {
      getContent();
    }
  }, []);

  /**
   * get open page content based on slug
   */

  const getContent = () => {
    getOpenPageContent(id).then((res) => {
      setLoading(false);
      if (res?.status == 200) {
        setList(res?.data?.data);
      }
    });
  };

  return (
    <>
      {list ? (
        <>
          {' '}
          <div className="wrappermain_outer">
            <section
              className="breadcrum-banner"
              style={{
                backgroundImage: `url(${
                  list?.banner_img
                    ? process.env.REACT_APP_API_URL + list?.banner_img
                    : '/static/media/breadcrum-banner-img.1a6b2ed71ebb8768883c.png'
                })`
              }}>
              <Container>
                <Row>
                  <Col sm={12}>
                    <h1 className="Text-white">{list?.banner_title || ''}</h1>
                  </Col>
                </Row>
              </Container>
            </section>

            <section className="networking-section">
              <Container>
                <h5>{list?.title}</h5>
                {list?.description && (
                  <span dangerouslySetInnerHTML={{ __html: list?.description }} />
                )}
              </Container>
            </section>
          </div>
          <div className="lgn-link pb-4">
            <Container>
              <div className="logn-lnk-inr">
                <p>
                  <Link to="/institute-register">Register</Link> /{' '}
                  <Link to="/institute-login">Login</Link> to browse members by Year, Name, Company,
                  Profession or Location
                </p>
              </div>
            </Container>
          </div>
        </>
      ) : (
        <div className="wrappermain_outer">
          {' '}
          <NoDataFound />
        </div>
      )}
      {loading ? <Loader /> : <></>}
    </>
  );
}

export default OpenPage;
