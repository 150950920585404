import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';

function AppDownload() {
  return (
    <div className="wrappermain_outer">
      <Container>
        <Row>
          <Col md={12} lg={10} className="mx-auto">
            <div className="institute-appdownload">
              <div className="app-down-cont">
                <h3>Now Available On</h3>
                {/* <p>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor
                  incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
                </p> */}
                <div className="d-flex align-items-center justify-content-center">
                  <Link href="">
                    <img
                      src={require('../../../assets/images/app-store-btn.png')}
                      className="me-3"
                      alt="img"
                    />
                  </Link>
                  <Link href="">
                    <img src={require('../../../assets/images/google-play-btn.png')} alt="img" />
                  </Link>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <section>
        <div className="app-down-bottom">
          <Container>
            <Col md={12}>
              <img
                src={require('../../../assets/images/appdownload-img1.png')}
                className="me-3"
                alt="img"
              />
            </Col>
          </Container>
        </div>
      </section>
    </div>
  );
}

export default AppDownload;
