import { Link } from 'react-router-dom';
import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import HeaderLogin from '../../commoncomponents/HeaderLogin';
import FooterMain from '../../commoncomponents/FooterMain';
import TopTabbings from './TopTabbings';
import NewsSidebar from '../../commoncomponents/newsfeeds/NewsSidebar';
import { Container } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import Select from 'react-select';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';
import Modal from 'react-bootstrap/Modal';
import CloseButton from 'react-bootstrap/CloseButton';
import { Dropdown } from 'react-bootstrap';

import WhatsNew from '../../commoncomponents/newsfeeds/WhatsNew';
import Upcoming from '../../commoncomponents/newsfeeds/Upcoming';
import { MiniFooter } from '../../MiniFooter';

function SettingGeneral() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  const skillOptions = [
    { id: 1, value: 'Everyone', label: 'Everyone' },
    { id: 2, value: 'Only Me', label: 'Only Me' },
    { id: 3, value: 'Friends', label: 'Friends' },
    { id: 4, value: 'Mentors', label: 'Mentors' },
    { id: 5, value: 'Mentees', label: 'Mentees' }
  ];

  return (
    <>
      <HeaderLogin />
      <div className="header-lnk-feeds">
        <div className="container">
          <TopTabbings state={'social'} />
        </div>
      </div>
      <section className="feed-sec bg-grey">
        <Container>
          <Row>
            <Col xs={12} md={12} lg={2}>
              <NewsSidebar state="social" current="page" />
            </Col>
            <Col xs={12} md={12} lg={10}>
              <div className="page-detail-sec py-3">
                <div className="page-detial-cover">
                  <img src={require('../../../assets/images/pages-detail-cover.jpg')} alt="cover" />
                  <div className="cover-disc d-flex align-items-center justify-content-between">
                    <div className="cover-disc d-flex align-items-center justify-content-between">
                      <div>
                        <h4>
                          <Link to="#">St Stephen School - Alumni Group</Link>
                        </h4>
                        <p>Closed Group</p>
                      </div>
                      <div>
                        <Link className="main-btn like-btn" to="#">
                          Unlike
                        </Link>
                      </div>
                    </div>
                  </div>
                  <Link to="#" className="page-cover-edit">
                    <i className="fas fa-camera me-2"></i> Edit Background
                  </Link>
                  <ul className="page-cover-function d-none">
                    <li>
                      <Link to="#">Save</Link>
                    </li>
                    <li>
                      <span className="change-img-card">Change Image</span>
                      <Form.Control type="file" />
                    </li>
                    <li>
                      <Link to="#">Remove Image</Link>
                    </li>
                    <li>
                      <Link to="#">
                        <i className="fas fa-times"></i>
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="pages-detail-tabs">
                  <Tab.Container id="left-tabs-example" defaultActiveKey="eight">
                    <div className="pages-detail-tabhd mb-3">
                      <div>
                        <Nav variant="pills" className="">
                          <Nav.Item>
                            <Nav.Link eventKey="eight">General Settings</Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link eventKey="first">Discussion</Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link eventKey="second">Videos</Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link eventKey="third">Photos</Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link eventKey="fourth">Members</Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link eventKey="fifth">Settings</Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link eventKey="sixth">Invite</Nav.Link>
                          </Nav.Item>
                        </Nav>
                      </div>
                    </div>

                    <div className="detail-tab-cntnt">
                      <Tab.Content>
                        <Tab.Pane eventKey="eight">
                          <div className="add-event-activity bg-white">
                            <div className="add-head-card">
                              <h4 className="add-event-hd">General Settings</h4>
                            </div>
                            <div className="add-event-frm">
                              <Form>
                                <Form.Group
                                  as={Row}
                                  className="mb-3"
                                  controlId="formPlaintextEmail"
                                >
                                  <Form.Label column sm="2">
                                    Username
                                  </Form.Label>
                                  <Col sm="10">
                                    <Form.Control type="text" defaultValue="nonvipclients184798" />
                                  </Col>
                                </Form.Group>
                                <Form.Group
                                  as={Row}
                                  className="mb-3"
                                  controlId="formPlaintextEmail"
                                >
                                  <Form.Label column sm="2">
                                    Name:
                                  </Form.Label>
                                  <Col sm="10">
                                    <Form.Control type="text" defaultValue="NON VIP CLIENTS" />
                                  </Col>
                                </Form.Group>

                                <Form.Group
                                  as={Row}
                                  className="mb-3"
                                  controlId="formPlaintextEmail"
                                >
                                  <Form.Label column sm="2">
                                    About:
                                  </Form.Label>
                                  <Col sm="10">
                                    <Form.Control
                                      type="text"
                                      as="textarea"
                                      defaultValue="This is for non vip persons"
                                    />
                                  </Col>
                                </Form.Group>
                                <Form.Group
                                  as={Row}
                                  className="mb-3"
                                  controlId="formPlaintextEmail"
                                >
                                  <Form.Label column sm="2">
                                    Address:
                                  </Form.Label>
                                  <Col sm="10">
                                    <Form.Control type="text" defaultValue="" />
                                  </Col>
                                </Form.Group>

                                <div className="accnt-lft-btn d-flex align-items-center justify-content-end">
                                  <button className="activity-btn" type="button">
                                    Save Changes
                                  </button>
                                  <button className="wht-btn ms-2">Cancel</button>
                                </div>
                              </Form>
                            </div>
                          </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="first">
                          <Row>
                            <Col xs={12} md={8}>
                              <div className="write-on-wall mb-3">
                                <h4>
                                  <i className="fas fa-edit me-2"></i> What's going on?
                                </h4>
                                <textarea
                                  type="text"
                                  className="form-control"
                                  id="yourmessage"
                                  name="yourmessage"
                                  placeholder="Write something... #hashtags"
                                  rows={3}
                                ></textarea>
                                <div className="write-wall-ftr d-flex align-items-center justify-content-between">
                                  <div className="wall-fltr-lft">
                                    <ul className="wall-ftr-actions">
                                      <li>
                                        <i className="fas fa-camera upload-card">
                                          <Form.Group className="upload-file" controlId="formFile">
                                            <Form.Control type="file" />
                                          </Form.Group>
                                        </i>
                                      </li>
                                      <li>
                                        <i className="fas fa-music"></i>
                                      </li>
                                      <li>
                                        <i className="fas fa-film"></i>
                                      </li>
                                      <li>
                                        <i className="fas fa-folder-open upload-card">
                                          <Form.Group className="upload-file" controlId="formFile">
                                            <Form.Control type="file" />
                                          </Form.Group>
                                        </i>
                                      </li>
                                      <li>
                                        <i className="fas fa-map-marker-alt"></i>
                                      </li>
                                      <li>
                                        <i className="far fa-smile"></i>
                                      </li>
                                    </ul>
                                  </div>
                                  <div className="wall-fltr-rght d-flex align-items">
                                    <Select
                                      isMulti
                                      name="colors"
                                      className="basic-multi-select"
                                      classNamePrefix="select"
                                      options={skillOptions}
                                    />
                                    <button className="accnt-btn ms-2">
                                      <i className="fas fa-plus me-1"></i> Post
                                    </button>
                                  </div>
                                </div>
                              </div>
                              <div className="pofile-upload-card">
                                <div className="profile-upload-sec d-flex align-items-top mb-3">
                                  <span className="social-profile-icn me-3">
                                    <img
                                      src={require('../../../assets/images/default-male-avatar.png')}
                                      alt="img"
                                    />
                                  </span>
                                  <div className="social-profile-detail d-flex align-items-top justify-content-between ">
                                    <div className="social-profile-name">
                                      <h4>
                                        <Link to="#">user one</Link> updated his profile picture{' '}
                                      </h4>
                                      <p>
                                        <i className="far fa-clock me-2"></i>8 days ago{' '}
                                        <i
                                          className="fas fa-users ms-2"
                                          title="shared with everyone"
                                        ></i>{' '}
                                      </p>
                                    </div>
                                    <div className="drop-ellipse-actn">
                                      <Dropdown className="ellipse-drop-card ms-3">
                                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                                          <i className="fas fa-ellipsis-v"></i>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                          <Dropdown.Item href="#/action-1">
                                            Delete Post
                                          </Dropdown.Item>
                                          <Dropdown.Item href="#/action-1">
                                            Unfollow this post
                                          </Dropdown.Item>
                                        </Dropdown.Menu>
                                      </Dropdown>
                                    </div>
                                  </div>
                                </div>
                                <div className="profile-single-img mb-3">
                                  <img
                                    src={require('../../../assets/images/default-male-avatar.png')}
                                    alt="img"
                                  />
                                </div>
                                <div className="profile-upload-ftr">
                                  <div className="profile-likes-count d-flex align-items-center justify-content-between">
                                    <div className="likes-count">
                                      <Link onClick={handleShow}>
                                        10 Likes
                                        <Modal
                                          show={show}
                                          onHide={handleClose}
                                          backdrop="static"
                                          keyboard={false}
                                          className="likes-modal"
                                        >
                                          <Modal.Body>
                                            <div className="modal-hd d-flex align-items-center justify-content-between">
                                              <h4>
                                                <i className="fas fa-share me-2"></i> People who
                                                liked this{' '}
                                              </h4>
                                              <CloseButton variant="white" onClick={handleClose} />
                                            </div>
                                            <div className="likes-user-lst">
                                              <ul>
                                                <li>
                                                  <div className="lst-user-card d-flex align-items-center justify-content-between">
                                                    <div className="user-like-card d-flex align-items-center">
                                                      <span className="like-user-icn me-2">
                                                        <img
                                                          src={require('../../../assets/images/default-male-avatar.png')}
                                                          alt="img"
                                                        />
                                                      </span>
                                                      <h4>
                                                        <Link to="#">user one</Link>
                                                      </h4>
                                                    </div>
                                                    <Link className="wht-btn-grey " to="#">
                                                      Add as friend
                                                    </Link>
                                                  </div>
                                                </li>
                                                <li>
                                                  <div className="lst-user-card d-flex align-items-center justify-content-between">
                                                    <div className="user-like-card d-flex align-items-center">
                                                      <span className="like-user-icn me-2">
                                                        <img
                                                          src={require('../../../assets/images/default-male-avatar.png')}
                                                          alt="img"
                                                        />
                                                      </span>
                                                      <h4>
                                                        <Link to="#">user one</Link>
                                                      </h4>
                                                    </div>
                                                    <Link className="wht-btn-grey " to="#">
                                                      Add as friend
                                                    </Link>
                                                  </div>
                                                </li>
                                                <li>
                                                  <div className="lst-user-card d-flex align-items-center justify-content-between">
                                                    <div className="user-like-card d-flex align-items-center">
                                                      <span className="like-user-icn me-2">
                                                        <img
                                                          src={require('../../../assets/images/default-male-avatar.png')}
                                                          alt="img"
                                                        />
                                                      </span>
                                                      <h4>
                                                        <Link to="#">user one</Link>
                                                      </h4>
                                                    </div>
                                                    <Link className="wht-btn-grey " to="#">
                                                      Add as friend
                                                    </Link>
                                                  </div>
                                                </li>
                                                <li>
                                                  <div className="lst-user-card d-flex align-items-center justify-content-between">
                                                    <div className="user-like-card d-flex align-items-center">
                                                      <span className="like-user-icn me-2">
                                                        <img
                                                          src={require('../../../assets/images/default-male-avatar.png')}
                                                          alt="img"
                                                        />
                                                      </span>
                                                      <h4>
                                                        <Link to="#">user one</Link>
                                                      </h4>
                                                    </div>
                                                    <Link className="wht-btn-grey " to="#">
                                                      Add as friend
                                                    </Link>
                                                  </div>
                                                </li>
                                              </ul>
                                            </div>
                                          </Modal.Body>
                                        </Modal>
                                      </Link>
                                      <Link to="#" onClick={handleShow1}>
                                        10 Shares
                                        <Modal
                                          show={show1}
                                          onHide={handleClose1}
                                          backdrop="static"
                                          keyboard={false}
                                          className="likes-modal"
                                        >
                                          <Modal.Body>
                                            <div className="modal-hd d-flex align-items-center justify-content-between">
                                              <h4>
                                                <i className="fas fa-share me-2"></i> People who
                                                shared this
                                              </h4>
                                              <CloseButton variant="white" onClick={handleClose1} />
                                            </div>
                                            <div className="likes-user-lst">
                                              <ul>
                                                <li>
                                                  <div className="lst-user-card d-flex align-items-center justify-content-between">
                                                    <div className="user-like-card d-flex align-items-center">
                                                      <span className="like-user-icn me-2">
                                                        <img
                                                          src={require('../../../assets/images/default-male-avatar.png')}
                                                          alt="img"
                                                        />
                                                      </span>
                                                      <h4>
                                                        <Link to="#">user one</Link>
                                                      </h4>
                                                    </div>
                                                    <Link className="wht-btn-grey " to="#">
                                                      Add as friend
                                                    </Link>
                                                  </div>
                                                </li>
                                                <li>
                                                  <div className="lst-user-card d-flex align-items-center justify-content-between">
                                                    <div className="user-like-card d-flex align-items-center">
                                                      <span className="like-user-icn me-2">
                                                        <img
                                                          src={require('../../../assets/images/default-male-avatar.png')}
                                                          alt="img"
                                                        />
                                                      </span>
                                                      <h4>
                                                        <Link to="#">user one</Link>
                                                      </h4>
                                                    </div>
                                                    <Link className="wht-btn-grey " to="#">
                                                      Add as friend
                                                    </Link>
                                                  </div>
                                                </li>
                                                <li>
                                                  <div className="lst-user-card d-flex align-items-center justify-content-between">
                                                    <div className="user-like-card d-flex align-items-center">
                                                      <span className="like-user-icn me-2">
                                                        <img
                                                          src={require('../../../assets/images/default-male-avatar.png')}
                                                          alt="img"
                                                        />
                                                      </span>
                                                      <h4>
                                                        <Link to="#">user one</Link>
                                                      </h4>
                                                    </div>
                                                    <Link className="wht-btn-grey " to="#">
                                                      Add as friend
                                                    </Link>
                                                  </div>
                                                </li>
                                                <li>
                                                  <div className="lst-user-card d-flex align-items-center justify-content-between">
                                                    <div className="user-like-card d-flex align-items-center">
                                                      <span className="like-user-icn me-2">
                                                        <img
                                                          src={require('../../../assets/images/default-male-avatar.png')}
                                                          alt="img"
                                                        />
                                                      </span>
                                                      <h4>
                                                        <Link to="#">user one</Link>
                                                      </h4>
                                                    </div>
                                                    <Link className="wht-btn-grey " to="#">
                                                      Add as friend
                                                    </Link>
                                                  </div>
                                                </li>
                                              </ul>
                                            </div>
                                          </Modal.Body>
                                        </Modal>
                                      </Link>
                                    </div>
                                    <div className="cmmnts-count">10 comments</div>
                                  </div>
                                  <div className="profile-like-btns">
                                    <ul>
                                      <li>
                                        <Link to="#" title="Like">
                                          <i className="fas fa-thumbs-up me-2"></i>Like
                                        </Link>
                                      </li>
                                      <li>
                                        <Link to="#" title="Comments">
                                          <i className="fas fa-comments me-2"></i>Comments
                                        </Link>
                                      </li>
                                      <li>
                                        <Link to="#" title="Share">
                                          <i className="fas fa-share me-2"></i>Share
                                        </Link>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                                <div className="profile-upload-cmmnt mt-3">
                                  <div className="profile-cmmnt-lst d-flex align-items-top mb-3">
                                    <span className="social-profile-icn me-3">
                                      <img
                                        src={require('../../../assets/images/default-male-avatar.png')}
                                        alt="img"
                                      />
                                    </span>
                                    <div className="profile-cmmnt-user ">
                                      <div className="d-flex align-items-top justify-content-between">
                                        <Link to="#">user one</Link>
                                        <div className="profile-cmmnt-time d-flex align-items-center ">
                                          <span>3 years ago</span>
                                          <Dropdown className="ellipse-drop-card ms-3">
                                            <Dropdown.Toggle variant="success" id="dropdown-basic">
                                              <i className="fas fa-ellipsis-v"></i>
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                              <Dropdown.Item href="#/action-1">
                                                <i className="fas fa-trash-alt me-2"></i> Delete
                                                Comment
                                              </Dropdown.Item>
                                            </Dropdown.Menu>
                                          </Dropdown>
                                        </div>
                                      </div>
                                      <div className="profile-cmmnt-dis">
                                        <p>Congratulations............</p>
                                        <div className="cmmnt-likes d-flex align-items-center">
                                          <Link to="#">
                                            <i className="fas fa-thumbs-up me-1"></i>Likes
                                          </Link>
                                          - 10 Likes
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="profile-cmmnt-input d-flex align-items-top">
                                    <span className="social-profile-icn me-3">
                                      <img
                                        src={require('../../../assets/images/default-male-avatar.png')}
                                        alt="img"
                                      />
                                    </span>
                                    <div className="cmmnt-card">
                                      <Form.Control
                                        type="email"
                                        placeholder="Write a comment... Press Enter to post"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="pofile-upload-card">
                                <div className="profile-upload-sec d-flex align-items-top mb-3">
                                  <span className="social-profile-icn me-3">
                                    <img
                                      src={require('../../../assets/images/default-male-avatar.png')}
                                      alt="img"
                                    />
                                  </span>
                                  <div className="social-profile-detail d-flex align-items-top justify-content-between ">
                                    <div className="social-profile-name">
                                      <h4>
                                        <Link to="#">Vishal Sood</Link> added 3 new photos to the
                                        album <Link to="#">Timeline Photos</Link>{' '}
                                      </h4>

                                      <p>
                                        <i className="far fa-clock me-2"></i>8 days ago{' '}
                                        <i
                                          className="fas fa-users ms-2"
                                          title="shared with everyone"
                                        ></i>{' '}
                                      </p>
                                    </div>
                                    <div className="drop-ellipse-actn">
                                      <Dropdown className="ellipse-drop-card ms-3">
                                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                                          <i className="fas fa-ellipsis-v"></i>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                          <Dropdown.Item href="#/action-1">
                                            Delete Post
                                          </Dropdown.Item>
                                          <Dropdown.Item href="#/action-1">
                                            Unfollow this post
                                          </Dropdown.Item>
                                        </Dropdown.Menu>
                                      </Dropdown>
                                    </div>
                                  </div>
                                </div>
                                <div className="profile-top-dis">
                                  <p>
                                    Mmuniversity Mullana Ambala and Career Launcher signed an MOU to
                                    set up MMU-CL center of excellence. MMU-CL COE will help
                                    students of MMu and nearby institutes to get access to best
                                    mentors and teachers to prepare for different competetive exams
                                    like CAT, CLAT, employability tests for different government
                                    exams, GATE and GMAT. MMU-CL will be equipped with state of art
                                    infrastructure, classes delivered by best teachers of CL
                                    educate. Students of Ambala, Yamunanagar and nearby towns now
                                    dont have to travel to Chandigarh to get best preparation.
                                    <Link to="#"> Read more</Link>
                                  </p>
                                </div>
                                <div className="post_img d-flex flex-wrap">
                                  <Link className="collage_card" onClick={handleShow2}>
                                    {' '}
                                    <img
                                      src={require('../../../assets/images/card-1.jpg')}
                                      alt="img"
                                    />
                                  </Link>
                                  <Link className="collage_card" onClick={handleShow2}>
                                    {' '}
                                    <img
                                      src={require('../../../assets/images/card-2.jpg')}
                                      alt="img"
                                    />
                                  </Link>
                                  <Link className="collage_card" onClick={handleShow2}>
                                    {' '}
                                    <img
                                      src={require('../../../assets/images/card-4.jpg')}
                                      alt="img"
                                    />
                                  </Link>
                                  <Link className="collage_card" onClick={handleShow2}>
                                    {' '}
                                    <img
                                      src={require('../../../assets/images/card-5.jpg')}
                                      alt="img"
                                    />
                                  </Link>
                                  <Link className="collage_card" onClick={handleShow2}>
                                    {' '}
                                    <img
                                      src={require('../../../assets/images/card-6.jpg')}
                                      alt="img"
                                    />
                                  </Link>
                                  <Modal
                                    show={show2}
                                    onHide={handleClose2}
                                    backdrop="static"
                                    keyboard={false}
                                    className="timeline-modal"
                                    size="xl"
                                  >
                                    <Modal.Body>
                                      <div className="timeline-sec">
                                        <Row className="m-0">
                                          <Col className="p-0" md={8} sm={8}>
                                            <div className="time-line-card bg-blck">
                                              <Swiper
                                                spaceBetween={50}
                                                slidesPerView={1}
                                                modules={[Navigation]}
                                                navigation={true}
                                              >
                                                <SwiperSlide>
                                                  <div className="timeline-slide-images">
                                                    <img
                                                      src={require('../../../assets/images/card-1.jpg')}
                                                      alt="img"
                                                    />
                                                  </div>
                                                </SwiperSlide>
                                                <SwiperSlide>
                                                  <div className="timeline-slide-images">
                                                    <img
                                                      src={require('../../../assets/images/card-2.jpg')}
                                                      alt="img"
                                                    />
                                                  </div>
                                                </SwiperSlide>
                                                <SwiperSlide>
                                                  <div className="timeline-slide-images">
                                                    <img
                                                      src={require('../../../assets/images/card-3.jpg')}
                                                      alt="img"
                                                    />
                                                  </div>
                                                </SwiperSlide>
                                                <SwiperSlide>
                                                  <div className="timeline-slide-images">
                                                    <img
                                                      src={require('../../../assets/images/card-4.jpg')}
                                                      alt="img"
                                                    />
                                                  </div>
                                                </SwiperSlide>
                                                <SwiperSlide>
                                                  <div className="timeline-slide-images">
                                                    <img
                                                      src={require('../../../assets/images/card-5.jpg')}
                                                      alt="img"
                                                    />
                                                  </div>
                                                </SwiperSlide>
                                                <SwiperSlide>
                                                  <div className="timeline-slide-images">
                                                    <img
                                                      src={require('../../../assets/images/card-6.jpg')}
                                                      alt="img"
                                                    />
                                                  </div>
                                                </SwiperSlide>
                                              </Swiper>
                                            </div>
                                          </Col>
                                          <Col className="p-0" md={4} sm={4}>
                                            <div className="time-line-cmmnts">
                                              <div className="pofile-upload-card">
                                                <div className="profile-upload-sec d-flex align-items-top mb-3">
                                                  <span className="social-profile-icn me-3">
                                                    <img
                                                      src={require('../../../assets/images/default-male-avatar.png')}
                                                      alt="img"
                                                    />
                                                  </span>
                                                  <div className="social-profile-detail d-flex align-items-top justify-content-between ">
                                                    <div className="social-profile-name">
                                                      <h4>
                                                        <Link to="#">Userone</Link> updated his
                                                        profile picture{' '}
                                                      </h4>
                                                      <p>
                                                        <i className="far fa-clock me-2"></i>8 days
                                                        ago
                                                      </p>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className="profile-upload-ftr">
                                                  <div className="profile-likes-count d-flex align-items-center justify-content-between">
                                                    <div className="likes-count">
                                                      <Link to="#">10 Likes</Link>
                                                      <Link to="#">10 Shares</Link>
                                                    </div>
                                                  </div>
                                                  <div className="profile-like-btns">
                                                    <ul>
                                                      <li>
                                                        <Link to="#" title="Like">
                                                          <i className="fas fa-thumbs-up me-2"></i>
                                                          Like
                                                        </Link>
                                                      </li>
                                                      <li>
                                                        <Link to="#" title="Share">
                                                          <i className="fas fa-share me-2"></i>Share
                                                        </Link>
                                                      </li>
                                                      <li>
                                                        <Link to="#" title="follow this post">
                                                          Follow this post
                                                        </Link>
                                                      </li>
                                                    </ul>
                                                  </div>
                                                </div>
                                                <div className="profile-upload-cmmnt mt-3">
                                                  <div className="profile-cmmnt-lst d-flex align-items-top mb-3">
                                                    <span className="social-profile-icn me-3">
                                                      <img
                                                        src={require('../../../assets/images/default-male-avatar.png')}
                                                        alt="img"
                                                      />
                                                    </span>
                                                    <div className="profile-cmmnt-user ">
                                                      <div className="d-flex align-items-top justify-content-between">
                                                        <Link to="#">Userone</Link>
                                                      </div>
                                                      <div className="profile-cmmnt-dis">
                                                        <p>Congratulations............</p>
                                                        <div className="cmmnt-likes d-flex align-items-center justify-content-between">
                                                          <span>3 years ago</span>
                                                          <div>
                                                            <Link to="#">
                                                              <i className="fas fa-thumbs-up me-1"></i>
                                                              Likes
                                                            </Link>
                                                            - 10 Likes
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                    <Link className="close-btn" to="#">
                                                      <i className="fas fa-times"></i>
                                                    </Link>
                                                  </div>
                                                  <div className="profile-cmmnt-lst d-flex align-items-top mb-3">
                                                    <span className="social-profile-icn me-3">
                                                      <img
                                                        src={require('../../../assets/images/default-male-avatar.png')}
                                                        alt="img"
                                                      />
                                                    </span>
                                                    <div className="profile-cmmnt-user ">
                                                      <div className="d-flex align-items-top justify-content-between">
                                                        <Link to="#">Userone</Link>
                                                      </div>
                                                      <div className="profile-cmmnt-dis">
                                                        <p>Congratulations............</p>
                                                        <div className="cmmnt-likes d-flex align-items-center justify-content-between">
                                                          <span>3 years ago</span>
                                                          <div>
                                                            <Link to="#">
                                                              <i className="fas fa-thumbs-up me-1"></i>
                                                              Likes
                                                            </Link>
                                                            - 10 Likes
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                    <Link className="close-btn" to="#">
                                                      <i className="fas fa-times"></i>
                                                    </Link>
                                                  </div>
                                                  <div className="profile-cmmnt-lst d-flex align-items-top mb-3">
                                                    <span className="social-profile-icn me-3">
                                                      <img
                                                        src={require('../../../assets/images/default-male-avatar.png')}
                                                        alt="img"
                                                      />
                                                    </span>
                                                    <div className="profile-cmmnt-user ">
                                                      <div className="d-flex align-items-top justify-content-between">
                                                        <Link to="#">Userone</Link>
                                                      </div>
                                                      <div className="profile-cmmnt-dis">
                                                        <p>Congratulations............</p>
                                                        <div className="cmmnt-likes d-flex align-items-center justify-content-between">
                                                          <span>3 years ago</span>
                                                          <div>
                                                            <Link to="#">
                                                              <i className="fas fa-thumbs-up me-1"></i>
                                                              Likes
                                                            </Link>
                                                            - 10 Likes
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                    <Link className="close-btn" to="#">
                                                      <i className="fas fa-times"></i>
                                                    </Link>
                                                  </div>
                                                  <div className="profile-cmmnt-input d-flex align-items-top">
                                                    <span className="social-profile-icn me-3">
                                                      <img
                                                        src={require('../../../assets/images/default-male-avatar.png')}
                                                        alt="img"
                                                      />
                                                    </span>
                                                    <div className="cmmnt-card">
                                                      <Form.Control
                                                        type="email"
                                                        placeholder="Write a comment... Press Enter to post"
                                                      />
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </Col>
                                        </Row>
                                      </div>
                                    </Modal.Body>
                                  </Modal>
                                </div>
                                <div className="profile-upload-ftr">
                                  <div className="profile-likes-count d-flex align-items-center justify-content-between">
                                    <div className="likes-count">
                                      <Link to="#">10 Likes</Link>
                                      <Link to="#">10 Shares</Link>
                                    </div>
                                    <div className="cmmnts-count">10 comments</div>
                                  </div>
                                  <div className="profile-like-btns">
                                    <ul>
                                      <li>
                                        <Link to="#" title="Like">
                                          <i className="fas fa-thumbs-up me-2"></i>Like
                                        </Link>
                                      </li>
                                      <li>
                                        <Link to="#" title="Comments">
                                          <i className="fas fa-comments me-2"></i>Comments
                                        </Link>
                                      </li>
                                      <li>
                                        <Link to="#" title="Share">
                                          <i className="fas fa-share me-2"></i>Share
                                        </Link>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                                <div className="profile-upload-cmmnt mt-3">
                                  <div className="profile-cmmnt-lst d-flex align-items-top mb-3">
                                    <span className="social-profile-icn me-3">
                                      <img
                                        src={require('../../../assets/images/default-male-avatar.png')}
                                        alt="img"
                                      />
                                    </span>
                                    <div className="profile-cmmnt-user ">
                                      <div className="d-flex align-items-top justify-content-between">
                                        <Link to="#">Userone</Link>
                                        <div className="profile-cmmnt-time d-flex align-items-center ">
                                          <span>3 years ago</span>
                                          <Dropdown className="ellipse-drop-card ms-3">
                                            <Dropdown.Toggle variant="success" id="dropdown-basic">
                                              <i className="fas fa-ellipsis-v"></i>
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                              <Dropdown.Item href="#/action-1">
                                                <i className="fas fa-trash-alt me-2"></i> Delete
                                                Comment
                                              </Dropdown.Item>
                                            </Dropdown.Menu>
                                          </Dropdown>
                                        </div>
                                      </div>
                                      <div className="profile-cmmnt-dis">
                                        <p>Congratulations............</p>
                                        <div className="cmmnt-likes d-flex align-items-center">
                                          <Link to="#">
                                            <i className="fas fa-thumbs-up me-1"></i>Likes
                                          </Link>
                                          - 10 Likes
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="profile-cmmnt-input d-flex align-items-top">
                                    <span className="social-profile-icn me-3">
                                      <img
                                        src={require('../../../assets/images/default-male-avatar.png')}
                                        alt="img"
                                      />
                                    </span>
                                    <div className="cmmnt-card">
                                      <Form.Control
                                        type="email"
                                        placeholder="Write a comment... Press Enter to post"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="pofile-upload-card">
                                <div className="profile-upload-sec d-flex align-items-top mb-3">
                                  <span className="social-profile-icn me-3">
                                    <img
                                      src={require('../../../assets/images/default-male-avatar.png')}
                                      alt="img"
                                    />
                                  </span>
                                  <div className="social-profile-detail d-flex align-items-top justify-content-between ">
                                    <div className="social-profile-name">
                                      <h4>
                                        <Link to="#">Vishal Sood</Link> added 3 new photos to the
                                        album <Link to="#">Timeline Photos</Link>{' '}
                                      </h4>

                                      <p>
                                        <i className="far fa-clock me-2"></i>8 days ago{' '}
                                        <i
                                          className="fas fa-users ms-2"
                                          title="shared with everyone"
                                        ></i>{' '}
                                      </p>
                                    </div>
                                    <div className="drop-ellipse-actn">
                                      <Dropdown className="ellipse-drop-card ms-3">
                                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                                          <i className="fas fa-ellipsis-v"></i>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                          <Dropdown.Item href="#/action-1">
                                            Delete Post
                                          </Dropdown.Item>
                                          <Dropdown.Item href="#/action-1">
                                            Unfollow this post
                                          </Dropdown.Item>
                                        </Dropdown.Menu>
                                      </Dropdown>
                                    </div>
                                  </div>
                                </div>
                                <div className="post_img d-flex flex-wrap">
                                  <Link className="collage_card" onClick={handleShow2}>
                                    {' '}
                                    <img
                                      src={require('../../../assets/images/card-1.jpg')}
                                      alt="img"
                                    />
                                  </Link>
                                  <Link className="collage_card" onClick={handleShow2}>
                                    {' '}
                                    <img
                                      src={require('../../../assets/images/card-2.jpg')}
                                      alt="img"
                                    />
                                  </Link>
                                  <Link className="collage_card" onClick={handleShow2}>
                                    {' '}
                                    <img
                                      src={require('../../../assets/images/card-4.jpg')}
                                      alt="img"
                                    />
                                  </Link>
                                  <Link className="collage_card" onClick={handleShow2}>
                                    {' '}
                                    <img
                                      src={require('../../../assets/images/card-5.jpg')}
                                      alt="img"
                                    />
                                  </Link>
                                  <Link className="collage_card" onClick={handleShow2}>
                                    {' '}
                                    <img
                                      src={require('../../../assets/images/card-6.jpg')}
                                      alt="img"
                                    />
                                  </Link>
                                  <Modal
                                    show={show2}
                                    onHide={handleClose2}
                                    backdrop="static"
                                    keyboard={false}
                                    className="timeline-modal"
                                    size="xl"
                                  >
                                    <Modal.Body>
                                      <div className="timeline-sec">
                                        <Row className="m-0">
                                          <Col className="p-0" md={8} sm={8}>
                                            <div className="time-line-card bg-blck">
                                              <Swiper
                                                spaceBetween={50}
                                                slidesPerView={1}
                                                modules={[Navigation]}
                                                navigation={true}
                                              >
                                                <SwiperSlide>
                                                  <div className="timeline-slide-images">
                                                    <img
                                                      src={require('../../../assets/images/card-1.jpg')}
                                                      alt="img"
                                                    />
                                                  </div>
                                                </SwiperSlide>
                                                <SwiperSlide>
                                                  <div className="timeline-slide-images">
                                                    <img
                                                      src={require('../../../assets/images/card-2.jpg')}
                                                      alt="img"
                                                    />
                                                  </div>
                                                </SwiperSlide>
                                                <SwiperSlide>
                                                  <div className="timeline-slide-images">
                                                    <img
                                                      src={require('../../../assets/images/card-3.jpg')}
                                                      alt="img"
                                                    />
                                                  </div>
                                                </SwiperSlide>
                                                <SwiperSlide>
                                                  <div className="timeline-slide-images">
                                                    <img
                                                      src={require('../../../assets/images/card-4.jpg')}
                                                      alt="img"
                                                    />
                                                  </div>
                                                </SwiperSlide>
                                                <SwiperSlide>
                                                  <div className="timeline-slide-images">
                                                    <img
                                                      src={require('../../../assets/images/card-5.jpg')}
                                                      alt="img"
                                                    />
                                                  </div>
                                                </SwiperSlide>
                                                <SwiperSlide>
                                                  <div className="timeline-slide-images">
                                                    <img
                                                      src={require('../../../assets/images/card-6.jpg')}
                                                      alt="img"
                                                    />
                                                  </div>
                                                </SwiperSlide>
                                              </Swiper>
                                            </div>
                                          </Col>
                                          <Col className="p-0" md={4} sm={4}>
                                            <div className="time-line-cmmnts">
                                              <div className="pofile-upload-card">
                                                <div className="profile-upload-sec d-flex align-items-top mb-3">
                                                  <span className="social-profile-icn me-3">
                                                    <img
                                                      src={require('../../../assets/images/default-male-avatar.png')}
                                                      alt="img"
                                                    />
                                                  </span>
                                                  <div className="social-profile-detail d-flex align-items-top justify-content-between ">
                                                    <div className="social-profile-name">
                                                      <h4>
                                                        <Link to="#">Userone</Link> updated his
                                                        profile picture{' '}
                                                      </h4>
                                                      <p>
                                                        <i className="far fa-clock me-2"></i>8 days
                                                        ago
                                                      </p>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className="profile-upload-ftr">
                                                  <div className="profile-likes-count d-flex align-items-center justify-content-between">
                                                    <div className="likes-count">
                                                      <Link to="#">10 Likes</Link>
                                                      <Link to="#">10 Shares</Link>
                                                    </div>
                                                  </div>
                                                  <div className="profile-like-btns">
                                                    <ul>
                                                      <li>
                                                        <Link to="#" title="Like">
                                                          <i className="fas fa-thumbs-up me-2"></i>
                                                          Like
                                                        </Link>
                                                      </li>
                                                      <li>
                                                        <Link to="#" title="Share">
                                                          <i className="fas fa-share me-2"></i>Share
                                                        </Link>
                                                      </li>
                                                      <li>
                                                        <Link to="#" title="follow this post">
                                                          Follow this post
                                                        </Link>
                                                      </li>
                                                    </ul>
                                                  </div>
                                                </div>
                                                <div className="profile-upload-cmmnt mt-3">
                                                  <div className="profile-cmmnt-lst d-flex align-items-top mb-3">
                                                    <span className="social-profile-icn me-3">
                                                      <img
                                                        src={require('../../../assets/images/default-male-avatar.png')}
                                                        alt="img"
                                                      />
                                                    </span>
                                                    <div className="profile-cmmnt-user ">
                                                      <div className="d-flex align-items-top justify-content-between">
                                                        <Link to="#">Userone</Link>
                                                      </div>
                                                      <div className="profile-cmmnt-dis">
                                                        <p>Congratulations............</p>
                                                        <div className="cmmnt-likes d-flex align-items-center justify-content-between">
                                                          <span>3 years ago</span>
                                                          <div>
                                                            <Link to="#">
                                                              <i className="fas fa-thumbs-up me-1"></i>
                                                              Likes
                                                            </Link>
                                                            - 10 Likes
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                    <Link className="close-btn" to="#">
                                                      <i className="fas fa-times"></i>
                                                    </Link>
                                                  </div>
                                                  <div className="profile-cmmnt-lst d-flex align-items-top mb-3">
                                                    <span className="social-profile-icn me-3">
                                                      <img
                                                        src={require('../../../assets/images/default-male-avatar.png')}
                                                        alt="img"
                                                      />
                                                    </span>
                                                    <div className="profile-cmmnt-user ">
                                                      <div className="d-flex align-items-top justify-content-between">
                                                        <Link to="#">Userone</Link>
                                                      </div>
                                                      <div className="profile-cmmnt-dis">
                                                        <p>Congratulations............</p>
                                                        <div className="cmmnt-likes d-flex align-items-center justify-content-between">
                                                          <span>3 years ago</span>
                                                          <div>
                                                            <Link to="#">
                                                              <i className="fas fa-thumbs-up me-1"></i>
                                                              Likes
                                                            </Link>
                                                            - 10 Likes
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                    <Link className="close-btn" to="#">
                                                      <i className="fas fa-times"></i>
                                                    </Link>
                                                  </div>
                                                  <div className="profile-cmmnt-lst d-flex align-items-top mb-3">
                                                    <span className="social-profile-icn me-3">
                                                      <img
                                                        src={require('../../../assets/images/default-male-avatar.png')}
                                                        alt="img"
                                                      />
                                                    </span>
                                                    <div className="profile-cmmnt-user ">
                                                      <div className="d-flex align-items-top justify-content-between">
                                                        <Link to="#">Userone</Link>
                                                      </div>
                                                      <div className="profile-cmmnt-dis">
                                                        <p>Congratulations............</p>
                                                        <div className="cmmnt-likes d-flex align-items-center justify-content-between">
                                                          <span>3 years ago</span>
                                                          <div>
                                                            <Link to="#">
                                                              <i className="fas fa-thumbs-up me-1"></i>
                                                              Likes
                                                            </Link>
                                                            - 10 Likes
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                    <Link className="close-btn" to="#">
                                                      <i className="fas fa-times"></i>
                                                    </Link>
                                                  </div>
                                                  <div className="profile-cmmnt-input d-flex align-items-top">
                                                    <span className="social-profile-icn me-3">
                                                      <img
                                                        src={require('../../../assets/images/default-male-avatar.png')}
                                                        alt="img"
                                                      />
                                                    </span>
                                                    <div className="cmmnt-card">
                                                      <Form.Control
                                                        type="email"
                                                        placeholder="Write a comment... Press Enter to post"
                                                      />
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </Col>
                                        </Row>
                                      </div>
                                    </Modal.Body>
                                  </Modal>
                                </div>
                                <div className="profile-upload-ftr">
                                  <div className="profile-likes-count d-flex align-items-center justify-content-between">
                                    <div className="likes-count">
                                      <Link to="#">10 Likes</Link>
                                      <Link to="#">10 Shares</Link>
                                    </div>
                                    <div className="cmmnts-count">10 comments</div>
                                  </div>
                                  <div className="profile-like-btns">
                                    <ul>
                                      <li>
                                        <Link to="#" title="Like">
                                          <i className="fas fa-thumbs-up me-2"></i>Like
                                        </Link>
                                      </li>
                                      <li>
                                        <Link to="#" title="Comments">
                                          <i className="fas fa-comments me-2"></i>Comments
                                        </Link>
                                      </li>
                                      <li>
                                        <Link to="#" title="Share">
                                          <i className="fas fa-share me-2"></i>Share
                                        </Link>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                                <div className="profile-upload-cmmnt mt-3">
                                  <div className="profile-cmmnt-lst d-flex align-items-top mb-3">
                                    <span className="social-profile-icn me-3">
                                      <img
                                        src={require('../../../assets/images/default-male-avatar.png')}
                                        alt="img"
                                      />
                                    </span>
                                    <div className="profile-cmmnt-user ">
                                      <div className="d-flex align-items-top justify-content-between">
                                        <Link to="#">Userone</Link>
                                        <div className="profile-cmmnt-time d-flex align-items-center ">
                                          <span>3 years ago</span>
                                          <Dropdown className="ellipse-drop-card ms-3">
                                            <Dropdown.Toggle variant="success" id="dropdown-basic">
                                              <i className="fas fa-ellipsis-v"></i>
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                              <Dropdown.Item href="#/action-1">
                                                <i className="fas fa-trash-alt me-2"></i> Delete
                                                Comment
                                              </Dropdown.Item>
                                            </Dropdown.Menu>
                                          </Dropdown>
                                        </div>
                                      </div>
                                      <div className="profile-cmmnt-dis">
                                        <p>Congratulations............</p>
                                        <div className="cmmnt-likes d-flex align-items-center">
                                          <Link to="#">
                                            <i className="fas fa-thumbs-up me-1"></i>Likes
                                          </Link>
                                          - 10 Likes
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="profile-cmmnt-input d-flex align-items-top">
                                    <span className="social-profile-icn me-3">
                                      <img
                                        src={require('../../../assets/images/default-male-avatar.png')}
                                        alt="img"
                                      />
                                    </span>
                                    <div className="cmmnt-card">
                                      <Form.Control
                                        type="email"
                                        placeholder="Write a comment... Press Enter to post"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Col>
                            <Col xs={12} md={4}>
                              <div>
                                <WhatsNew />
                              </div>
                              <div>
                                <Upcoming />
                              </div>
                              <MiniFooter />
                            </Col>
                          </Row>
                        </Tab.Pane>
                        <Tab.Pane eventKey="second">
                          <p></p>
                        </Tab.Pane>
                        <Tab.Pane eventKey="third">
                          <Row>
                            <Col xs={12} md={8}>
                              <div className="pofile-upload-card">
                                <div className="profile-upload-sec d-flex align-items-top mb-3">
                                  <span className="social-profile-icn me-3">
                                    <img
                                      src={require('../../../assets/images/default-male-avatar.png')}
                                      alt="img"
                                    />
                                  </span>
                                  <div className="social-profile-detail d-flex align-items-top justify-content-between ">
                                    <div className="social-profile-name">
                                      <h4>
                                        <Link to="#">Alma Bay</Link>
                                        <i className="fas fa-arrow-right mx-2"></i>{' '}
                                        <Link to="#">St Stephen School</Link>added 5 new photos to
                                        the album
                                      </h4>
                                      <p>
                                        <i className="far fa-clock me-2"></i>27 days ago{' '}
                                      </p>
                                    </div>
                                    <div className="drop-ellipse-actn">
                                      <Dropdown className="ellipse-drop-card ms-3">
                                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                                          <i className="fas fa-ellipsis-v"></i>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                          <Dropdown.Item href="#/action-1">
                                            Delete Post
                                          </Dropdown.Item>
                                          <Dropdown.Item href="#/action-1">
                                            Unfollow this post
                                          </Dropdown.Item>
                                        </Dropdown.Menu>
                                      </Dropdown>
                                    </div>
                                  </div>
                                </div>
                                <div className="post_img d-flex flex-wrap">
                                  <Link className="collage_card" onClick={handleShow2}>
                                    {' '}
                                    <img
                                      src={require('../../../assets/images/card-1.jpg')}
                                      alt="img"
                                    />
                                  </Link>
                                  <Link className="collage_card" onClick={handleShow2}>
                                    {' '}
                                    <img
                                      src={require('../../../assets/images/card-2.jpg')}
                                      alt="img"
                                    />
                                  </Link>
                                  <Link className="collage_card" onClick={handleShow2}>
                                    {' '}
                                    <img
                                      src={require('../../../assets/images/card-4.jpg')}
                                      alt="img"
                                    />
                                  </Link>
                                  <Link className="collage_card" onClick={handleShow2}>
                                    {' '}
                                    <img
                                      src={require('../../../assets/images/card-5.jpg')}
                                      alt="img"
                                    />
                                  </Link>
                                  <Link className="collage_card" onClick={handleShow2}>
                                    {' '}
                                    <img
                                      src={require('../../../assets/images/card-6.jpg')}
                                      alt="img"
                                    />
                                  </Link>
                                  <Modal
                                    show={show2}
                                    onHide={handleClose2}
                                    backdrop="static"
                                    keyboard={false}
                                    className="timeline-modal"
                                    size="xl"
                                  >
                                    <Modal.Body>
                                      <div className="timeline-sec">
                                        <Row className="m-0">
                                          <Col className="p-0" md={8} sm={8}>
                                            <div className="time-line-card bg-blck">
                                              <Swiper
                                                spaceBetween={50}
                                                slidesPerView={1}
                                                modules={[Navigation]}
                                                navigation={true}
                                              >
                                                <SwiperSlide>
                                                  <div className="timeline-slide-images">
                                                    <img
                                                      src={require('../../../assets/images/card-1.jpg')}
                                                      alt="img"
                                                    />
                                                  </div>
                                                </SwiperSlide>
                                                <SwiperSlide>
                                                  <div className="timeline-slide-images">
                                                    <img
                                                      src={require('../../../assets/images/card-2.jpg')}
                                                      alt="img"
                                                    />
                                                  </div>
                                                </SwiperSlide>
                                                <SwiperSlide>
                                                  <div className="timeline-slide-images">
                                                    <img
                                                      src={require('../../../assets/images/card-3.jpg')}
                                                      alt="img"
                                                    />
                                                  </div>
                                                </SwiperSlide>
                                                <SwiperSlide>
                                                  <div className="timeline-slide-images">
                                                    <img
                                                      src={require('../../../assets/images/card-4.jpg')}
                                                      alt="img"
                                                    />
                                                  </div>
                                                </SwiperSlide>
                                                <SwiperSlide>
                                                  <div className="timeline-slide-images">
                                                    <img
                                                      src={require('../../../assets/images/card-5.jpg')}
                                                      alt="img"
                                                    />
                                                  </div>
                                                </SwiperSlide>
                                                <SwiperSlide>
                                                  <div className="timeline-slide-images">
                                                    <img
                                                      src={require('../../../assets/images/card-6.jpg')}
                                                      alt="img"
                                                    />
                                                  </div>
                                                </SwiperSlide>
                                              </Swiper>
                                            </div>
                                          </Col>
                                          <Col className="p-0" md={4} sm={4}>
                                            <div className="time-line-cmmnts">
                                              <div className="pofile-upload-card">
                                                <div className="profile-upload-sec d-flex align-items-top mb-3">
                                                  <span className="social-profile-icn me-3">
                                                    <img
                                                      src={require('../../../assets/images/default-male-avatar.png')}
                                                      alt="img"
                                                    />
                                                  </span>
                                                  <div className="social-profile-detail d-flex align-items-top justify-content-between ">
                                                    <div className="social-profile-name">
                                                      <h4>
                                                        <Link to="#">Userone</Link> updated his
                                                        profile picture{' '}
                                                      </h4>
                                                      <p>
                                                        <i className="far fa-clock me-2"></i>8 days
                                                        ago
                                                      </p>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className="profile-upload-ftr">
                                                  <div className="profile-likes-count d-flex align-items-center justify-content-between">
                                                    <div className="likes-count">
                                                      <Link to="#">10 Likes</Link>
                                                      <Link to="#">10 Shares</Link>
                                                    </div>
                                                  </div>
                                                  <div className="profile-like-btns">
                                                    <ul>
                                                      <li>
                                                        <Link to="#" title="Like">
                                                          <i className="fas fa-thumbs-up me-2"></i>
                                                          Like
                                                        </Link>
                                                      </li>
                                                      <li>
                                                        <Link to="#" title="Share">
                                                          <i className="fas fa-share me-2"></i>Share
                                                        </Link>
                                                      </li>
                                                      <li>
                                                        <Link to="#" title="follow this post">
                                                          Follow this post
                                                        </Link>
                                                      </li>
                                                    </ul>
                                                  </div>
                                                </div>
                                                <div className="profile-upload-cmmnt mt-3">
                                                  <div className="profile-cmmnt-lst d-flex align-items-top mb-3">
                                                    <span className="social-profile-icn me-3">
                                                      <img
                                                        src={require('../../../assets/images/default-male-avatar.png')}
                                                        alt="img"
                                                      />
                                                    </span>
                                                    <div className="profile-cmmnt-user ">
                                                      <div className="d-flex align-items-top justify-content-between">
                                                        <Link to="#">Userone</Link>
                                                      </div>
                                                      <div className="profile-cmmnt-dis">
                                                        <p>Congratulations............</p>
                                                        <div className="cmmnt-likes d-flex align-items-center justify-content-between">
                                                          <span>3 years ago</span>
                                                          <div>
                                                            <Link to="#">
                                                              <i className="fas fa-thumbs-up me-1"></i>
                                                              Likes
                                                            </Link>
                                                            - 10 Likes
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                    <Link className="close-btn" to="#">
                                                      <i className="fas fa-times"></i>
                                                    </Link>
                                                  </div>
                                                  <div className="profile-cmmnt-lst d-flex align-items-top mb-3">
                                                    <span className="social-profile-icn me-3">
                                                      <img
                                                        src={require('../../../assets/images/default-male-avatar.png')}
                                                        alt="img"
                                                      />
                                                    </span>
                                                    <div className="profile-cmmnt-user ">
                                                      <div className="d-flex align-items-top justify-content-between">
                                                        <Link to="#">Userone</Link>
                                                      </div>
                                                      <div className="profile-cmmnt-dis">
                                                        <p>Congratulations............</p>
                                                        <div className="cmmnt-likes d-flex align-items-center justify-content-between">
                                                          <span>3 years ago</span>
                                                          <div>
                                                            <Link to="#">
                                                              <i className="fas fa-thumbs-up me-1"></i>
                                                              Likes
                                                            </Link>
                                                            - 10 Likes
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                    <Link className="close-btn" to="#">
                                                      <i className="fas fa-times"></i>
                                                    </Link>
                                                  </div>
                                                  <div className="profile-cmmnt-lst d-flex align-items-top mb-3">
                                                    <span className="social-profile-icn me-3">
                                                      <img
                                                        src={require('../../../assets/images/default-male-avatar.png')}
                                                        alt="img"
                                                      />
                                                    </span>
                                                    <div className="profile-cmmnt-user ">
                                                      <div className="d-flex align-items-top justify-content-between">
                                                        <Link to="#">Userone</Link>
                                                      </div>
                                                      <div className="profile-cmmnt-dis">
                                                        <p>Congratulations............</p>
                                                        <div className="cmmnt-likes d-flex align-items-center justify-content-between">
                                                          <span>3 years ago</span>
                                                          <div>
                                                            <Link to="#">
                                                              <i className="fas fa-thumbs-up me-1"></i>
                                                              Likes
                                                            </Link>
                                                            - 10 Likes
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                    <Link className="close-btn" to="#">
                                                      <i className="fas fa-times"></i>
                                                    </Link>
                                                  </div>
                                                  <div className="profile-cmmnt-input d-flex align-items-top">
                                                    <span className="social-profile-icn me-3">
                                                      <img
                                                        src={require('../../../assets/images/default-male-avatar.png')}
                                                        alt="img"
                                                      />
                                                    </span>
                                                    <div className="cmmnt-card">
                                                      <Form.Control
                                                        type="email"
                                                        placeholder="Write a comment... Press Enter to post"
                                                      />
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </Col>
                                        </Row>
                                      </div>
                                    </Modal.Body>
                                  </Modal>
                                </div>
                                <div className="profile-upload-ftr">
                                  <div className="profile-likes-count d-flex align-items-center justify-content-between">
                                    <div className="likes-count">
                                      <Link to="#">10 Likes</Link>
                                      <Link to="#">10 Shares</Link>
                                    </div>
                                    <div className="cmmnts-count">10 comments</div>
                                  </div>
                                  <div className="profile-like-btns">
                                    <ul>
                                      <li>
                                        <Link to="#" title="Like">
                                          <i className="fas fa-thumbs-up me-2"></i>Like
                                        </Link>
                                      </li>
                                      <li>
                                        <Link to="#" title="Comments">
                                          <i className="fas fa-comments me-2"></i>Comments
                                        </Link>
                                      </li>
                                      <li>
                                        <Link to="#" title="Share">
                                          <i className="fas fa-share me-2"></i>Share
                                        </Link>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                                <div className="profile-upload-cmmnt mt-3">
                                  <div className="profile-cmmnt-lst d-flex align-items-top mb-3">
                                    <span className="social-profile-icn me-3">
                                      <img
                                        src={require('../../../assets/images/default-male-avatar.png')}
                                        alt="img"
                                      />
                                    </span>
                                    <div className="profile-cmmnt-user ">
                                      <div className="d-flex align-items-top justify-content-between">
                                        <Link to="#">Userone</Link>
                                        <div className="profile-cmmnt-time d-flex align-items-center ">
                                          <span>3 years ago</span>
                                          <Dropdown className="ellipse-drop-card ms-3">
                                            <Dropdown.Toggle variant="success" id="dropdown-basic">
                                              <i className="fas fa-ellipsis-v"></i>
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                              <Dropdown.Item href="#/action-1">
                                                <i className="fas fa-trash-alt me-2"></i> Delete
                                                Comment
                                              </Dropdown.Item>
                                            </Dropdown.Menu>
                                          </Dropdown>
                                        </div>
                                      </div>
                                      <div className="profile-cmmnt-dis">
                                        <p>Congratulations............</p>
                                        <div className="cmmnt-likes d-flex align-items-center">
                                          <Link to="#">
                                            <i className="fas fa-thumbs-up me-1"></i>Likes
                                          </Link>
                                          - 10 Likes
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="profile-cmmnt-input d-flex align-items-top">
                                    <span className="social-profile-icn me-3">
                                      <img
                                        src={require('../../../assets/images/default-male-avatar.png')}
                                        alt="img"
                                      />
                                    </span>
                                    <div className="cmmnt-card">
                                      <Form.Control
                                        type="email"
                                        placeholder="Write a comment... Press Enter to post"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="pofile-upload-card">
                                <div className="profile-upload-sec d-flex align-items-top mb-3">
                                  <span className="social-profile-icn me-3">
                                    <img
                                      src={require('../../../assets/images/default-male-avatar.png')}
                                      alt="img"
                                    />
                                  </span>
                                  <div className="social-profile-detail d-flex align-items-top justify-content-between ">
                                    <div className="social-profile-name">
                                      <h4>
                                        <Link to="#">Alma Bay</Link>
                                        <i className="fas fa-arrow-right mx-2"></i>{' '}
                                        <Link to="#">St Stephen School</Link>added 2 new photos to
                                        the album
                                      </h4>

                                      <p>
                                        <i className="far fa-clock me-2"></i>8 days ago{' '}
                                        <i
                                          className="fas fa-users ms-2"
                                          title="shared with everyone"
                                        ></i>{' '}
                                      </p>
                                    </div>
                                    <div className="drop-ellipse-actn">
                                      <Dropdown className="ellipse-drop-card ms-3">
                                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                                          <i className="fas fa-ellipsis-v"></i>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                          <Dropdown.Item href="#/action-1">
                                            Delete Post
                                          </Dropdown.Item>
                                          <Dropdown.Item href="#/action-1">
                                            Unfollow this post
                                          </Dropdown.Item>
                                        </Dropdown.Menu>
                                      </Dropdown>
                                    </div>
                                  </div>
                                </div>
                                <div className="post_img d-flex flex-wrap">
                                  <Link className="collage_card" onClick={handleShow2}>
                                    {' '}
                                    <img
                                      src={require('../../../assets/images/card-1.jpg')}
                                      alt="img"
                                    />
                                  </Link>
                                  <Link className="collage_card" onClick={handleShow2}>
                                    {' '}
                                    <img
                                      src={require('../../../assets/images/card-2.jpg')}
                                      alt="img"
                                    />
                                  </Link>

                                  <Modal
                                    show={show2}
                                    onHide={handleClose2}
                                    backdrop="static"
                                    keyboard={false}
                                    className="timeline-modal"
                                    size="xl"
                                  >
                                    <Modal.Body>
                                      <div className="timeline-sec">
                                        <Row className="m-0">
                                          <Col className="p-0" md={8} sm={8}>
                                            <div className="time-line-card bg-blck">
                                              <Swiper
                                                spaceBetween={50}
                                                slidesPerView={1}
                                                modules={[Navigation]}
                                                navigation={true}
                                              >
                                                <SwiperSlide>
                                                  <div className="timeline-slide-images">
                                                    <img
                                                      src={require('../../../assets/images/card-1.jpg')}
                                                      alt="img"
                                                    />
                                                  </div>
                                                </SwiperSlide>
                                                <SwiperSlide>
                                                  <div className="timeline-slide-images">
                                                    <img
                                                      src={require('../../../assets/images/card-2.jpg')}
                                                      alt="img"
                                                    />
                                                  </div>
                                                </SwiperSlide>
                                                <SwiperSlide>
                                                  <div className="timeline-slide-images">
                                                    <img
                                                      src={require('../../../assets/images/card-3.jpg')}
                                                      alt="img"
                                                    />
                                                  </div>
                                                </SwiperSlide>
                                                <SwiperSlide>
                                                  <div className="timeline-slide-images">
                                                    <img
                                                      src={require('../../../assets/images/card-4.jpg')}
                                                      alt="img"
                                                    />
                                                  </div>
                                                </SwiperSlide>
                                                <SwiperSlide>
                                                  <div className="timeline-slide-images">
                                                    <img
                                                      src={require('../../../assets/images/card-5.jpg')}
                                                      alt="img"
                                                    />
                                                  </div>
                                                </SwiperSlide>
                                                <SwiperSlide>
                                                  <div className="timeline-slide-images">
                                                    <img
                                                      src={require('../../../assets/images/card-6.jpg')}
                                                      alt="img"
                                                    />
                                                  </div>
                                                </SwiperSlide>
                                              </Swiper>
                                            </div>
                                          </Col>
                                          <Col className="p-0" md={4} sm={4}>
                                            <div className="time-line-cmmnts">
                                              <div className="pofile-upload-card">
                                                <div className="profile-upload-sec d-flex align-items-top mb-3">
                                                  <span className="social-profile-icn me-3">
                                                    <img
                                                      src={require('../../../assets/images/default-male-avatar.png')}
                                                      alt="img"
                                                    />
                                                  </span>
                                                  <div className="social-profile-detail d-flex align-items-top justify-content-between ">
                                                    <div className="social-profile-name">
                                                      <h4>
                                                        <Link to="#">Userone</Link> updated his
                                                        profile picture{' '}
                                                      </h4>
                                                      <p>
                                                        <i className="far fa-clock me-2"></i>8 days
                                                        ago
                                                      </p>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className="profile-upload-ftr">
                                                  <div className="profile-likes-count d-flex align-items-center justify-content-between">
                                                    <div className="likes-count">
                                                      <Link to="#">10 Likes</Link>
                                                      <Link to="#">10 Shares</Link>
                                                    </div>
                                                  </div>
                                                  <div className="profile-like-btns">
                                                    <ul>
                                                      <li>
                                                        <Link to="#" title="Like">
                                                          <i className="fas fa-thumbs-up me-2"></i>
                                                          Like
                                                        </Link>
                                                      </li>
                                                      <li>
                                                        <Link to="#" title="Share">
                                                          <i className="fas fa-share me-2"></i>Share
                                                        </Link>
                                                      </li>
                                                      <li>
                                                        <Link to="#" title="follow this post">
                                                          Follow this post
                                                        </Link>
                                                      </li>
                                                    </ul>
                                                  </div>
                                                </div>
                                                <div className="profile-upload-cmmnt mt-3">
                                                  <div className="profile-cmmnt-lst d-flex align-items-top mb-3">
                                                    <span className="social-profile-icn me-3">
                                                      <img
                                                        src={require('../../../assets/images/default-male-avatar.png')}
                                                        alt="img"
                                                      />
                                                    </span>
                                                    <div className="profile-cmmnt-user ">
                                                      <div className="d-flex align-items-top justify-content-between">
                                                        <Link to="#">Userone</Link>
                                                      </div>
                                                      <div className="profile-cmmnt-dis">
                                                        <p>Congratulations............</p>
                                                        <div className="cmmnt-likes d-flex align-items-center justify-content-between">
                                                          <span>3 years ago</span>
                                                          <div>
                                                            <Link to="#">
                                                              <i className="fas fa-thumbs-up me-1"></i>
                                                              Likes
                                                            </Link>
                                                            - 10 Likes
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                    <Link className="close-btn" to="#">
                                                      <i className="fas fa-times"></i>
                                                    </Link>
                                                  </div>
                                                  <div className="profile-cmmnt-lst d-flex align-items-top mb-3">
                                                    <span className="social-profile-icn me-3">
                                                      <img
                                                        src={require('../../../assets/images/default-male-avatar.png')}
                                                        alt="img"
                                                      />
                                                    </span>
                                                    <div className="profile-cmmnt-user ">
                                                      <div className="d-flex align-items-top justify-content-between">
                                                        <Link to="#">Userone</Link>
                                                      </div>
                                                      <div className="profile-cmmnt-dis">
                                                        <p>Congratulations............</p>
                                                        <div className="cmmnt-likes d-flex align-items-center justify-content-between">
                                                          <span>3 years ago</span>
                                                          <div>
                                                            <Link to="#">
                                                              <i className="fas fa-thumbs-up me-1"></i>
                                                              Likes
                                                            </Link>
                                                            - 10 Likes
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                    <Link className="close-btn" to="#">
                                                      <i className="fas fa-times"></i>
                                                    </Link>
                                                  </div>
                                                  <div className="profile-cmmnt-lst d-flex align-items-top mb-3">
                                                    <span className="social-profile-icn me-3">
                                                      <img
                                                        src={require('../../../assets/images/default-male-avatar.png')}
                                                        alt="img"
                                                      />
                                                    </span>
                                                    <div className="profile-cmmnt-user ">
                                                      <div className="d-flex align-items-top justify-content-between">
                                                        <Link to="#">Userone</Link>
                                                      </div>
                                                      <div className="profile-cmmnt-dis">
                                                        <p>Congratulations............</p>
                                                        <div className="cmmnt-likes d-flex align-items-center justify-content-between">
                                                          <span>3 years ago</span>
                                                          <div>
                                                            <Link to="#">
                                                              <i className="fas fa-thumbs-up me-1"></i>
                                                              Likes
                                                            </Link>
                                                            - 10 Likes
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                    <Link className="close-btn" to="#">
                                                      <i className="fas fa-times"></i>
                                                    </Link>
                                                  </div>
                                                  <div className="profile-cmmnt-input d-flex align-items-top">
                                                    <span className="social-profile-icn me-3">
                                                      <img
                                                        src={require('../../../assets/images/default-male-avatar.png')}
                                                        alt="img"
                                                      />
                                                    </span>
                                                    <div className="cmmnt-card">
                                                      <Form.Control
                                                        type="email"
                                                        placeholder="Write a comment... Press Enter to post"
                                                      />
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </Col>
                                        </Row>
                                      </div>
                                    </Modal.Body>
                                  </Modal>
                                </div>
                                <div className="profile-upload-ftr">
                                  <div className="profile-likes-count d-flex align-items-center justify-content-between">
                                    <div className="likes-count">
                                      <Link to="#">10 Likes</Link>
                                      <Link to="#">10 Shares</Link>
                                    </div>
                                    <div className="cmmnts-count">10 comments</div>
                                  </div>
                                  <div className="profile-like-btns">
                                    <ul>
                                      <li>
                                        <Link to="#" title="Like">
                                          <i className="fas fa-thumbs-up me-2"></i>Like
                                        </Link>
                                      </li>
                                      <li>
                                        <Link to="#" title="Comments">
                                          <i className="fas fa-comments me-2"></i>Comments
                                        </Link>
                                      </li>
                                      <li>
                                        <Link to="#" title="Share">
                                          <i className="fas fa-share me-2"></i>Share
                                        </Link>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                                <div className="profile-upload-cmmnt mt-3">
                                  <div className="profile-cmmnt-lst d-flex align-items-top mb-3">
                                    <span className="social-profile-icn me-3">
                                      <img
                                        src={require('../../../assets/images/default-male-avatar.png')}
                                        alt="img"
                                      />
                                    </span>
                                    <div className="profile-cmmnt-user ">
                                      <div className="d-flex align-items-top justify-content-between">
                                        <Link to="#">Userone</Link>
                                        <div className="profile-cmmnt-time d-flex align-items-center ">
                                          <span>3 years ago</span>
                                          <Dropdown className="ellipse-drop-card ms-3">
                                            <Dropdown.Toggle variant="success" id="dropdown-basic">
                                              <i className="fas fa-ellipsis-v"></i>
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                              <Dropdown.Item href="#/action-1">
                                                <i className="fas fa-trash-alt me-2"></i> Delete
                                                Comment
                                              </Dropdown.Item>
                                            </Dropdown.Menu>
                                          </Dropdown>
                                        </div>
                                      </div>
                                      <div className="profile-cmmnt-dis">
                                        <p>Congratulations............</p>
                                        <div className="cmmnt-likes d-flex align-items-center">
                                          <Link to="#">
                                            <i className="fas fa-thumbs-up me-1"></i>Likes
                                          </Link>
                                          - 10 Likes
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="profile-cmmnt-input d-flex align-items-top">
                                    <span className="social-profile-icn me-3">
                                      <img
                                        src={require('../../../assets/images/default-male-avatar.png')}
                                        alt="img"
                                      />
                                    </span>
                                    <div className="cmmnt-card">
                                      <Form.Control
                                        type="email"
                                        placeholder="Write a comment... Press Enter to post"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Col>
                            <Col xs={12} md={4}>
                              <div>
                                <WhatsNew />
                              </div>
                              <div>
                                <Upcoming />
                              </div>
                              <MiniFooter />
                            </Col>
                          </Row>
                        </Tab.Pane>
                        <Tab.Pane eventKey="fourth">
                          <div className="likes-tab-sec">
                            <div className="likes-top-hd d-flex align-items-center justify-content-between">
                              <div>
                                <Link to="#">
                                  Members <span>10</span>
                                </Link>
                                <Link className="ms-3" to="#">
                                  Admins <span>10</span>
                                </Link>
                                <Link className="ms-3" to="#">
                                  Requests <span>10</span>
                                </Link>
                              </div>
                              <div>
                                <Form.Group className="search-card" controlId="formBasicEmail">
                                  <Form.Control type="search" placeholder="Search" />
                                  <Button className="search-btn">
                                    <i className="fas fa-search"></i>
                                  </Button>
                                </Form.Group>
                              </div>
                            </div>
                            <div className="like-page-card bg-white p-3">
                              <Row>
                                <Col md={6}>
                                  <div className="like-card-detail member-card-detail d-flex">
                                    <div className="like-card-img">
                                      <Link to="#">
                                        <img
                                          src={require('../../../assets/images/default-male-avatar.png')}
                                          alt="img"
                                        />
                                      </Link>
                                    </div>
                                    <div className="like-card-dis">
                                      <h4>Alma Bay</h4>
                                      <p>
                                        Working as Server Administrator at AlmaBay Networks Pvt Ltd
                                      </p>
                                      <ul className="mentr-action-btn">
                                        <li>
                                          <Link to="#">Make Admin</Link>
                                        </li>
                                        <li>
                                          <Link to="#">Remove Member</Link>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </Col>
                                <Col md={6}>
                                  <div className="like-card-detail member-card-detail d-flex">
                                    <div className="like-card-img">
                                      <Link to="#">
                                        <img
                                          src={require('../../../assets/images/default-male-avatar.png')}
                                          alt="img"
                                        />
                                      </Link>
                                    </div>
                                    <div className="like-card-dis">
                                      <h4>Alma Bay</h4>
                                      <p>
                                        Working as Server Administrator at AlmaBay Networks Pvt Ltd
                                      </p>
                                      <ul className="mentr-action-btn">
                                        <li>
                                          <Link to="#">Make Admin</Link>
                                        </li>
                                        <li>
                                          <Link to="#">Remove Member</Link>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </Col>
                                <Col md={6}>
                                  <div className="like-card-detail member-card-detail d-flex">
                                    <div className="like-card-img">
                                      <Link to="#">
                                        <img
                                          src={require('../../../assets/images/default-male-avatar.png')}
                                          alt="img"
                                        />
                                      </Link>
                                    </div>
                                    <div className="like-card-dis">
                                      <h4>Alma Bay</h4>
                                      <p>
                                        Working as Server Administrator at AlmaBay Networks Pvt Ltd
                                      </p>
                                      <ul className="mentr-action-btn">
                                        <li>
                                          <Link to="#">Make Admin</Link>
                                        </li>
                                        <li>
                                          <Link to="#">Remove Member</Link>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </Col>
                                <Col md={6}>
                                  <div className="like-card-detail member-card-detail d-flex">
                                    <div className="like-card-img">
                                      <Link to="#">
                                        <img
                                          src={require('../../../assets/images/default-male-avatar.png')}
                                          alt="img"
                                        />
                                      </Link>
                                    </div>
                                    <div className="like-card-dis">
                                      <h4>Alma Bay</h4>
                                      <p>
                                        Working as Server Administrator at AlmaBay Networks Pvt Ltd
                                      </p>
                                      <ul className="mentr-action-btn">
                                        <li>
                                          <Link to="#">Make Admin</Link>
                                        </li>
                                        <li>
                                          <Link to="#">Remove Member</Link>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </Col>
                                <Col md={6}>
                                  <div className="like-card-detail member-card-detail d-flex">
                                    <div className="like-card-img">
                                      <Link to="#">
                                        <img
                                          src={require('../../../assets/images/default-male-avatar.png')}
                                          alt="img"
                                        />
                                      </Link>
                                    </div>
                                    <div className="like-card-dis">
                                      <h4>Alma Bay</h4>
                                      <p>
                                        Working as Server Administrator at AlmaBay Networks Pvt Ltd
                                      </p>
                                      <ul className="mentr-action-btn">
                                        <li>
                                          <Link to="#">Make Admin</Link>
                                        </li>
                                        <li>
                                          <Link to="#">Remove Member</Link>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </Col>
                              </Row>
                            </div>
                          </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="fifth">
                          <div className="group-settings-card bg-white p-4">
                            <h4 className="mb-5">Group Settings</h4>
                            <div className="post-job-form">
                              <Form>
                                <Form.Group
                                  as={Row}
                                  className="mb-4"
                                  controlId="formPlaintextEmail"
                                >
                                  <Form.Label column sm="3">
                                    Username:
                                  </Form.Label>
                                  <Col sm="9">
                                    <Form.Control type="text" defaultValue="TOSS_alumni_group" />
                                  </Col>
                                </Form.Group>

                                <Form.Group
                                  as={Row}
                                  className="mb-4"
                                  controlId="formPlaintextPassword"
                                >
                                  <Form.Label column sm="3">
                                    Name:
                                  </Form.Label>
                                  <Col sm="9">
                                    <Form.Control
                                      type="text"
                                      placeholder="St Stephen School - Alumni Gro"
                                    />
                                  </Col>
                                </Form.Group>
                                <Form.Group
                                  as={Row}
                                  className="mb-4"
                                  controlId="formPlaintextPassword"
                                >
                                  <Form.Label column sm="3">
                                    About:
                                  </Form.Label>
                                  <Col sm="9">
                                    <Form.Control
                                      type="text"
                                      as="textarea"
                                      placeholder="Write about your group..."
                                    />
                                  </Col>
                                </Form.Group>
                                <Form.Group
                                  as={Row}
                                  className="mb-4"
                                  controlId="formPlaintextPassword"
                                >
                                  <Form.Label column sm="3">
                                    Privacy:
                                  </Form.Label>
                                  <Col sm="9">
                                    <div className="privacy-radio-card">
                                      {['radio'].map((type) => (
                                        <div key={`inline-${type}`} className="mb-3">
                                          <div className="privacy-inr-card d-flex align-items-top mb-2">
                                            <Form.Check
                                              name="group1"
                                              type={type}
                                              id={`inline-${type}-1`}
                                            />
                                            <div className="privacy-radio-dis ms-3">
                                              <h4>
                                                Open Group{' '}
                                                <i className="fas fa-globe-asia ms-1"></i>
                                              </h4>
                                              <p>Anyone can see and join the group.</p>
                                            </div>
                                          </div>
                                          <div className="privacy-inr-card d-flex align-items-top mb-2">
                                            <Form.Check
                                              name="group1"
                                              type={type}
                                              id={`inline-${type}-2`}
                                            />
                                            <div className="privacy-radio-dis ms-2">
                                              <h4>
                                                Closed Group <i className="fas fa-lock ms-1"></i>
                                              </h4>
                                              <p>
                                                Anyone can see and request to join the group.
                                                Requests can be accepted or declined by admins.
                                              </p>
                                            </div>
                                          </div>
                                          <div className="privacy-inr-card d-flex align-items-top mb-2">
                                            <Form.Check
                                              name="group1"
                                              type={type}
                                              id={`inline-${type}-2`}
                                            />
                                            <div className="privacy-radio-dis ms-2">
                                              <h4>
                                                Secret Group{' '}
                                                <i className="fas fa-shield-alt ms-1"></i>
                                              </h4>
                                              <p>Only members can access the group.</p>
                                            </div>
                                          </div>
                                        </div>
                                      ))}
                                    </div>
                                  </Col>
                                </Form.Group>
                                <Form.Group
                                  as={Row}
                                  className="mb-4"
                                  controlId="formPlaintextPassword"
                                >
                                  <Form.Label column sm="3">
                                    Who can add members to this group?
                                  </Form.Label>
                                  <Col sm="9">
                                    <Form.Select aria-label="Default select example">
                                      <option>Open this select menu</option>
                                      <option value="1">One</option>
                                      <option value="2">Two</option>
                                      <option value="3">Three</option>
                                    </Form.Select>
                                  </Col>
                                </Form.Group>
                                <Form.Group
                                  as={Row}
                                  className="mb-4"
                                  controlId="formPlaintextPassword"
                                >
                                  <Form.Label column sm="3">
                                    Who can post on this group:
                                  </Form.Label>
                                  <Col sm="9">
                                    <Form.Select aria-label="Default select example">
                                      <option>Open this select menu</option>
                                      <option value="1">One</option>
                                      <option value="2">Two</option>
                                      <option value="3">Three</option>
                                    </Form.Select>
                                  </Col>
                                </Form.Group>
                                <Form.Group
                                  as={Row}
                                  className="mb-4"
                                  controlId="formPlaintextPassword"
                                >
                                  <Form.Label column sm="3"></Form.Label>
                                  <Col sm="9">
                                    <button className="accnt-btn" type="button">
                                      Save Changes
                                    </button>
                                  </Col>
                                </Form.Group>
                              </Form>
                            </div>
                          </div>
                        </Tab.Pane>
                      </Tab.Content>
                    </div>
                  </Tab.Container>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <FooterMain className={'colour-bg'} />
    </>
  );
}

export default SettingGeneral;
