import { Link } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Container } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import FooterGrey from '../../commoncomponents/FooterGrey';
import Table from 'react-bootstrap/Table';
import HeaderShop from '../../commoncomponents/HeaderShop';
import useDocumentTitle from '../../../globals/useDocumentTitle';
import {
  deleteCartProducts,
  manageCartProductsAction,
  singleProductsUpdateCart,
  viewCartProducts
} from '../../../services/ShopServices';
import Loader from '../../commoncomponents/loader/loader-large';
import { NumberWithdecimal } from '../../../globals/helper';
import { ONE, ZERO } from '../../../globals/constants';
import NoDataFound from '../../commoncomponents/NoDataFound';
import { Alert } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { HideAlert } from '../../../redux/action';
import NewsLetter from '../store-page/NewsLetter';
function ShoppingCart() {
  useDocumentTitle('Shopping Cart | AlmaBay');
  const [cartProductsState, setCartProductsState] = useState();
  const [cartTotal, setCartTotal] = useState();
  const [savePrice, setSavePrice] = useState();
  const [chekcedId, setCheckedId] = useState([]);
  const [totalCartCount, setTotalCartCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [quantityUpdate, setQuantityUpdate] = useState([]);
  const [allId, setAllId] = useState([]);
  const [showalert, setShowAlert] = useState(false);
  const reduxShowAlert = useSelector((state) => state?.alert?.showAlertBox);
  const [notification, setNotification] = useState(false);
  const dispatch = useDispatch();
  let getId = localStorage.getItem('user_id');
  const [state, setState] = useState('');

  useEffect(() => {
    cartProducts();
  }, [totalCartCount, getId]);
  useEffect(() => {
    const timeId = setTimeout(() => {
      // After 3 seconds set the show value to false
      setShowAlert(false);
      setNotification(false);
    }, 3000);
    const timeId1 = setTimeout(() => {
      // After 3 seconds set the show value to false
      dispatch(HideAlert());
    }, 3000);
    return () => {
      clearTimeout(timeId);
      clearTimeout(timeId1);
    };
  }, [showalert, reduxShowAlert, notification]);

  const globalStateHandler = () => {
    setShowAlert(true);
  };

  const cartProducts = () => {
    setLoading(true);
    viewCartProducts(getId).then((resp) => {
      setLoading(false);
      if (resp?.status == 200) {
        setLoading(false);
        setCartProductsState(resp?.data?.cartInfo);
        setTotalCartCount(resp?.data?.cartCount);
        if (resp?.data?.cartInfo?.length > 0) {
          setQuantityUpdate(
            resp?.data?.cartInfo.map((item) => {
              return Number(item.quantity);
            })
          );
        } else {
          setQuantityUpdate([]);
        }
        setCartTotal(resp?.data?.cart_total);
        setSavePrice(resp?.data?.saveprice);

        setAllId(resp?.data?.cartInfo?.map((data) => data?.id));
      } else {
        setNotification(resp?.data?.message);
      }
    });
  };

  const handleMultipleCheckBox = (id) => {
    if (chekcedId.includes(id)) {
      let filterId = chekcedId.filter((data) => data !== id);
      setCheckedId(filterId);
    } else {
      setCheckedId([...chekcedId, id]);
    }
  };

  const handleSelectAll = (e) => {
    if (e.target.checked === true) {
      setCheckedId(allId);
    } else {
      setCheckedId([]);
    }
  };

  const deleteProducts = (e, id) => {
    e.preventDefault();
    deleteCartProducts(id).then((resp) => {
      if (resp?.status == 200) {
        cartProducts();
        setState(resp?.data?.message);
        setNotification(resp?.data?.message);
      }
    });
  };

  const manageActionCart = (e) => {
    if (chekcedId.length !== 0) {
      setShowAlert(false);
      let result = [];
      chekcedId.forEach((ck) => {
        cartProductsState.forEach((item) => {
          if (ck == item.id) {
            result.push({
              id: item.id,
              qty: item.quantity
            });
          }
        });
      });

      let formData = new FormData();
      formData.append('action', e.target.value);
      formData.append('items', JSON.stringify(result));
      manageCartProductsAction(formData).then((resp) => {
        if (resp?.status == 200) {
          cartProducts(getId);
          setState(resp?.data?.message);
          setNotification(resp?.data?.message);
          setCheckedId([]);
        }
      });
    } else {
      globalStateHandler();
    }
  };

  /**update single product qunatity */

  const cartUpdate = (e, id, qty, index) => {
    setLoading(true);
    let item = [...quantityUpdate];
    setQuantityUpdate(item[index] == 0 ? qty : item[index]);
    let body = {
      id,
      quantity: item[index] == 0 ? qty : item[index]
    };

    singleProductsUpdateCart(body).then((resp) => {
      setLoading(false);
      if (resp?.status == 200) {
        setLoading(false);
        cartProducts();
        setNotification(resp?.data?.message);
      }
    });
  };

  const handleChange = (e, index) => {
    let item = [...quantityUpdate];
    item[index] = Number(e.target.value);
    setQuantityUpdate(item);
  };

  return (
    <>
      <HeaderShop state={state} deleteProductsFunc={deleteProducts} deleteState={'deleteState'} />

      <section className="shopping-top-btn bg-grey-light py-4">
        <Container>
          <div className="shopping-lft-btn">
            <Link className="white-btn me-2" to={{ pathname: `/shop/catalog`, state: 'catalog' }}>
              <i className="fas fa-arrow-left me-2"></i>
              Continue Shopping
            </Link>

            {totalCartCount && totalCartCount !== 0 ? (
              <>
                <Link className="main-btn" to="/shop/checkoutShipping">
                  <i className="fas fa-check me-2"></i>
                  Proceed to Checkout
                </Link>
              </>
            ) : (
              ''
            )}
          </div>
        </Container>
      </section>
      {totalCartCount && totalCartCount !== 0 ? (
        <section className="shopp-cart py-4">
          <Container>
            <div className="shopp-cart-inr">
              <div className="mb-3 d-flex align-items-center justify-content-between">
                <h3 className="shopp-cart-hd mb-4">
                  Shopping Cart <i className="fas fa-shopping-cart ms-2"></i>
                </h3>
                {chekcedId?.length !== ZERO ? (
                  <div className="with-select-lst">
                    <Form.Select aria-label="Default select example" onChange={manageActionCart}>
                      <option>Select</option>
                      <option value="delete">Delete</option>
                      <option value="update">Update</option>
                    </Form.Select>
                  </div>
                ) : (
                  ''
                )}
              </div>
              <div className="shopp-cart-list table-responsive">
                <Alert variant={'success'} className={`${notification ? '' : 'd-none'}`}>
                  {notification}
                </Alert>

                <Alert variant={'danger'} className={`${showalert ? '' : 'd-none'}`}>
                  Please select any record
                </Alert>
                <Table bordered hover>
                  <thead>
                    <tr>
                      <th>
                        <Form.Check
                          type="checkbox"
                          id="checkbox"
                          label=""
                          checked={allId?.length !== 0 && allId?.length === chekcedId?.length}
                          onChange={handleSelectAll}
                        />
                      </th>

                      <th>Sr.No.</th>
                      <th>Product Info</th>
                      <th>List Price</th>
                      <th>Selling Price</th>
                      <th>Qty</th>
                      <th>Subtotal</th>
                      <th>Delete</th>
                    </tr>
                  </thead>
                  <tbody>
                    {cartProductsState?.length != ZERO ? (
                      cartProductsState?.map((data, index) => {
                        return (
                          <tr key={index}>
                            <td>
                              <Form.Check
                                type="checkbox"
                                label=""
                                id={`id${data?.id}`}
                                name="id"
                                value={JSON.stringify(data)}
                                checked={chekcedId?.includes(data?.id)}
                                onChange={() => handleMultipleCheckBox(data?.id)}
                              />
                            </td>
                            <td>{index + ONE}</td>
                            <td>
                              <div className="cart-prd-view d-flex align-items-top">
                                <div className="cart-view-img me-3">
                                  <Link to={`/shop/details/${data?.product_id}`}>
                                    <img
                                      src={
                                        process.env.REACT_APP_API_DOMAIN +
                                        'common/uploads/products/' +
                                        data?.cover_image
                                      }
                                      alt="product-img"
                                    />
                                  </Link>
                                </div>
                                <div className="cart-view-dis">
                                  <h4>
                                    <Link to={`/shop/details/${data?.product_id}`}>
                                      {data?.name}
                                    </Link>
                                  </h4>
                                  {data?.attriGrpData?.map((attributes) => {
                                    return (
                                      <p>
                                        {attributes?.groupId == '1'
                                          ? `Size :` + ' ' + attributes?.Size
                                          : ''}
                                        &nbsp;
                                        {attributes?.groupId == '2'
                                          ? `Color :` + ' ' + attributes?.Color
                                          : ''}
                                      </p>
                                    );
                                  })}
                                </div>
                              </div>
                            </td>
                            <td>
                              <i className="fas fa-rupee-sign me-1"></i> {data?.full_price}
                            </td>
                            <td>
                              <i className="fas fa-rupee-sign me-1"></i> {data?.price}
                            </td>
                            <td>
                              <div className="cart-input d-flex align-items-center">
                                <Form.Control
                                  type="text"
                                  placeholder="1"
                                  onKeyPress={(e) => NumberWithdecimal(e)}
                                  name="quantity"
                                  value={quantityUpdate[index]}
                                  maxlength="4"
                                  onChange={(e) => {
                                    handleChange(e, index);
                                  }}
                                />

                                <Link
                                  className="refresh-btn ms-2"
                                  onClick={(e) => cartUpdate(e, data?.id, data?.quantity, index)}
                                >
                                  <i className="fas fa-sync"></i>
                                </Link>
                              </div>
                            </td>
                            <td>
                              <i className="fas fa-rupee-sign me-1"></i> {data?.subtotal}
                            </td>
                            <td>
                              <Link
                                className="cross-btn"
                                onClick={(e) => deleteProducts(e, data?.id)}
                              >
                                <i className="fas fa-times"></i>
                              </Link>
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <NoDataFound text="Your Shopping Cart is empty." />
                    )}
                  </tbody>
                </Table>
              </div>
            </div>
          </Container>
        </section>
      ) : (
        <NoDataFound text="Your Shopping Cart is empty." />
      )}
      {totalCartCount && totalCartCount !== 0 ? (
        <section className="cart-total bg-grey-light py-4">
          <Container>
            <div className="cart-total-inr">
              <Row className="justify-content-end">
                <Col md={4} xs={12} sm={4}>
                  <div className="cart-rt-dis">
                    <h5>
                      Subtotal {totalCartCount ? `(${totalCartCount}) items` : ' 0 items'}:{' '}
                      <span>
                        <i className="fas fa-rupee-sign me-1"></i>
                        {cartTotal}
                      </span>
                    </h5>
                    <h5>
                      Total savings:{' '}
                      <span>
                        <i className="fas fa-rupee-sign me-1"></i>
                        {savePrice}
                      </span>
                    </h5>

                    <Link
                      className="main-btn"
                      to={cartTotal ? '/shop/checkoutShipping' : '/shop/catalog'}
                    >
                      <i className="fas fa-check me-2"></i>
                      Proceed to Checkout
                    </Link>
                  </div>
                </Col>
              </Row>
            </div>
          </Container>
        </section>
      ) : (
        ''
      )}

      <NewsLetter />
      <FooterGrey />
      {loading ? <Loader /> : <></>}
    </>
  );
}

export default ShoppingCart;
