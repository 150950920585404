/**
@copyright  :   ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
@author     :   Shiv Charan Panjeta 
 
All Rights Reserved.
Proprietary and confidential :  All information contained here in is, and remains
the property of ToXSL Technologies Pvt. Ltd. and it's partners.
Unauthorized copying of this file, via any medium is strictly prohibited.
*/
import { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Pagination from 'react-bootstrap/Pagination';
import Row from 'react-bootstrap/Row';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import { AsyncPaginate } from 'react-select-async-paginate';
import {
  TEMPLATE_APPLICABLE_FOR_INSTITUTE,
  TEMPLATE_DOCUMENT_TYPE_DOC,
  TEMPLATE_DOCUMENT_TYPE_PDF
} from '../../globals/constants';
import { replaceStringFormat } from '../../globals/helper';
import { getInstituteList } from '../../services/AuthServices';
import showNotification from '../../services/NotificationService';
import { updateEmailTemplateDetail } from '../../services/TemplateServices';
export default function AddEmailBasicSettings({
  nextStep,
  activeTab,
  id,
  idAdd,
  templateTitle,
  setTemplateTitle,
  setApplicableFor,
  applicableFor,
  setContent,
  content,
  setTokenId,
  tokenId,
  setStatus,
  status,
  setNameOfTemplate,
  nameOfTemplate,
  setSelectedFile,
  selectedFile,
  setDocumentType,
  documentType,
  saveEmailTemplate,
  loading,
  setLoading,
  value,
  setValue,
  insituteMembersList,
  setAssignMemberList,
  setAssignMemberList2,
  assignMemberList,
  assignMemberList2,
  setInstID,
  instID,
  checkCredentials,
  membersID,
  isSelectedFile,
  setIsSelectedFile,
  applicableForFun,
  tokenList,
  setTokenList,
  emailLayoutTab,
  getEmailTemplateDetail,
  applicableForOption
}) {
  let active = 2;
  let items = [];
  for (let number = 1; number <= 5; number++) {
    items.push(
      <Pagination.Item key={number} active={number === active}>
        {number}
      </Pagination.Item>
    );
  }

  console.log('documentType, selected', documentType, isSelectedFile);

  const navigate = useNavigate();
  const [isRtl, setIsRtl] = useState(false);
  const [campus, setCampus] = useState([]);
  const [selectToken, setSelectToken] = useState(false);
  // const [instID, setInstID] = useState();

  // const [tokenList, setTokenList] = useState([]);

  // const [isSelectedFile, setIsSelectedFile] = useState(false);

  const [applicableForList, setApplicableForList] = useState([
    {
      id: TEMPLATE_APPLICABLE_FOR_INSTITUTE,
      name: 'INSTITUTE'
    }
  ]);
  const [documentTypeList, setDocumentTypeList] = useState([
    {
      id: TEMPLATE_DOCUMENT_TYPE_PDF,
      name: 'PDF'
    },
    {
      id: TEMPLATE_DOCUMENT_TYPE_DOC,
      name: 'DOCUMENT'
    }
  ]);
  // useEffect(() => {
  //   if (id) {
  //     getEmailTemplateDetail(id);
  //   }
  // }, []);

  /**
   * GET SINGLE EMAIL DETAIL
   * @param {*} page
   */

  const onTokenSelect = (e) => {
    setSelectToken(true);
    setTokenId(e.target.value);
    let selected_token = e.target.options[e.target.selectedIndex].text;
    if (e.target.value != '') {
      let updatedContent = content + replaceStringFormat(selected_token);

      setContent(updatedContent);
    } else {
      setSelectToken(false);
    }
  };

  /**
   * HANDLE TO SET IMAGE IN A STATE
   * @param {Object} e
   * @returns
   */
  const handleUploadFile = (e) => {
    if (!fileValidator(e.target.files[0])) {
      return false;
    }
    setSelectedFile(e.target.files[0]);
    setIsSelectedFile(true);
  };
  /**
   * FILE VALIDATOR
   * @param {Object} data
   * @returns
   */
  const fileValidator = (data) => {
    const fileExtension = data?.type?.split('/')[1];
    let validExtensions;
    if (documentType == TEMPLATE_DOCUMENT_TYPE_PDF) {
      validExtensions = ['pdf'];
    } else if (documentType == TEMPLATE_DOCUMENT_TYPE_DOC) {
      validExtensions = [
        'vnd.oasis.opendocument.text',
        'msword',
        'officedocument.wordprocessingml.document'
      ];
    }
    if (!validExtensions?.includes(fileExtension)) {
      showNotification(
        'danger',
        `Please select ${documentType == TEMPLATE_DOCUMENT_TYPE_PDF ? 'pdf' : 'doc'} format`
      );
      return false;
    }
    return true;
  };

  const updateEmailTemplate = () => {
    if (checkCredentials()) {
      setLoading(true);
      let formData = new FormData();
      formData.append('id', id);
      formData.append('title', templateTitle);
      formData.append('applicable_for', applicableFor);
      formData.append('template_name', nameOfTemplate);
      formData.append('subject', content);
      formData.append('document_file', selectedFile);
      formData.append('subject_token', tokenId);
      formData.append('document_type', documentType);
      formData.append('state_id', status);
      formData.append('campus_id', value.institute_id ? value.institute_id : instID);
      formData.append('assign_user', membersID.toString());
      updateEmailTemplateDetail(formData).then((res) => {
        if (res?.status == 200) {
          setLoading(false);
          showNotification('success', res?.data?.message);
          // navigate('/admin/ManageTemplate');

          if (emailLayoutTab == 1) {
            activeTab('third');
          } else {
            nextStep();
            activeTab('second');
          }
        } else {
          setLoading(false);
        }
      });
    }
  };

  const loadOptions = async (search, loadedOptions, { page }) => {
    try {
      let body = {
        page: page,
        institute_name: search
      };
      const resp = await getInstituteList(body);
      setCampus(resp?.data?.data?.viewlist ?? []);
      return {
        options: resp?.data?.data?.viewlist ?? [],
        hasMore: loadedOptions.length === resp?.data?.data?.totalRecords ? false : true,
        additional: {
          page: search ? page : page + 1
        }
      };
    } catch (error) {
      console.log('error', error);
    }
  };

  const handleChange = (val) => {
    setAssignMemberList(val);
  };

  useEffect(() => {
    if (value?.institute_id ? value?.institute_id : instID) {
      insituteMembersList();
    }
  }, [value?.institute_id ? value?.institute_id : instID]);

  return (
    <div className="cmmn-form">
      <Form>
        <Row>
          <Col md={12}>
            <Form.Group className="mb-3" controlId="formBasicName">
              <Form.Label>
                Template Title <span className="text-danger">*</span>
              </Form.Label>

              <Form.Control
                type="text"
                placeholder="Enter Template Title"
                onChange={(e) => setTemplateTitle(e.target.value)}
                value={templateTitle}
              />
            </Form.Group>
          </Col>

          <Col md={12}>
            <Form.Group className="mb-3" controlId="formBasicName">
              <Form.Label>
                Template Applicable for<span className="text-danger">*</span>
              </Form.Label>
              <Form.Select
                aria-label="Default select example"
                onChange={(e) => applicableForFun(e.target.value)}
                value={applicableFor}>
                {applicableForOption == true || applicableFor ? (
                  ''
                ) : (
                  <option> Select Template Applicable for </option>
                )}
                {applicableForList?.map((data, index) => (
                  <>
                    <option key={index} value={data?.id}>
                      {data?.name}
                    </option>
                  </>
                ))}
              </Form.Select>
            </Form.Group>
          </Col>

          <h5 className="mb-4 mt-3">Email Template Setting</h5>

          <Col md={12}>
            <Form.Group className="mb-3" controlId="formBasicName">
              <Form.Label>
                Name Of Template <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter name Of template"
                onChange={(e) => setNameOfTemplate(e.target.value)}
                value={nameOfTemplate}
              />
            </Form.Group>
          </Col>

          <Col md={12}>
            <Form.Group className="mb-3" controlId="formBasicName">
              <Form.Label>
                Subject <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter subject"
                onChange={(e) => setContent(e.target.value)}
                value={content}
              />
            </Form.Group>
          </Col>

          <Col md={12}>
            <Form.Group className="mb-3" controlId="formBasicName">
              <Form.Label>Subject Token</Form.Label>
              <Form.Select
                aria-label="Default select example"
                onChange={(e) => onTokenSelect(e)}
                value={tokenId}>
                {selectToken == true || tokenId ? '' : <option>Select Subject Token</option>}
                {tokenList.length > 0 &&
                  tokenList?.map((data, index) => {
                    return (
                      <>
                        <option key={index} value={data?.id}>
                          {data?.title}
                        </option>
                      </>
                    );
                  })}
              </Form.Select>
            </Form.Group>
          </Col>

          <Col md={12}>
            <Form.Group className="mb-3" controlId="formBasicName">
              <Form.Label>Document Type</Form.Label>
              <Form.Select
                aria-label="Default select example"
                onChange={(e) => {
                  setDocumentType(e.target.value);
                  setSelectedFile('');
                  setIsSelectedFile(false);
                }}
                value={documentType}>
                <option> Select Document Type </option>
                {documentTypeList?.map((data, index) => {
                  return (
                    <>
                      <option key={index} value={data?.id}>
                        {data?.name}
                      </option>
                    </>
                  );
                })}
              </Form.Select>
            </Form.Group>
          </Col>

          <Col md={12}>
            <Form.Group controlId="formFile" className="mb-3">
              <div className="file-upload">
                <Form.Control
                  type="file"
                  accept={
                    documentType == TEMPLATE_DOCUMENT_TYPE_PDF ? 'image/*,.pdf' : '.doc,.odt,.docx'
                  }
                  name="upload"
                  onChange={handleUploadFile}
                />
                <div className="file-upload-overlay">
                  <div>
                    <img
                      src={
                        isSelectedFile
                          ? documentType == TEMPLATE_DOCUMENT_TYPE_PDF
                            ? require('../../assets/images/pdf.png')
                            : require('../../assets/images/docx.png')
                          : require('../../assets/images/imageicon.png')
                      }
                      alt="attachfile"
                    />
                    <h4>{`${
                      isSelectedFile
                        ? selectedFile?.name
                          ? selectedFile?.name
                          : selectedFile
                        : 'Attach File'
                    }`}</h4>
                  </div>
                </div>
              </div>
            </Form.Group>
          </Col>

          <Col md={6}>
            <Form.Group className="mb-3" controlId="formBasicName">
              <Form.Label>
                Institute <span className="text-danger">*</span>
              </Form.Label>
              <AsyncPaginate
                className="w100  p16 mr_normal_padding_l mr_medium_padding_r"
                loadOptions={loadOptions}
                name="meal_id"
                id={Math.random()}
                getOptionValue={(option) => {
                  return option.institute_id;
                }}
                getOptionLabel={(option) => {
                  return option.name;
                }}
                value={value}
                onChange={setValue}
                additional={{
                  page: 1
                }}
                placeholder="Select Institute"
                debounceTimeout={500}
                isSearchable={true}
              />
            </Form.Group>
          </Col>

          <Col md={6}>
            <Form.Group className="mb-3" controlId="formBasicName">
              <Form.Label>
                Assign User To <span className="text-danger">*</span>
              </Form.Label>
              <Select
                isMulti
                name="colors"
                className="basic-multi-select"
                classNamePrefix="select"
                options={assignMemberList2}
                placeholder="Select"
                value={assignMemberList}
                onChange={(e) => {
                  handleChange(e);
                }}
              />
            </Form.Group>
          </Col>

          <Col md={12}>
            <Form.Group className="mb-3" controlId="formBasicName">
              <Form.Label>Status</Form.Label>
              <Form.Select
                aria-label="Default select example"
                onChange={(e) => setStatus(e.target.value)}
                value={status}>
                <option> Select Status</option>
                <option value="1">Enable</option>
                <option value="2">Disable</option>
              </Form.Select>
            </Form.Group>
          </Col>
        </Row>
        <div className="form-btns d-flex align-items-center mt-3">
          {id ? (
            <button type="button" className="green_button_admin" onClick={updateEmailTemplate}>
              Update
            </button>
          ) : (
            <button type="button" className="green_button_admin" onClick={saveEmailTemplate}>
              Save
            </button>
          )}

          <strong>OR</strong>
          <Button className="main-btn-red" onClick={() => navigate(-1)}>
            Cancel
          </Button>
        </div>
      </Form>
    </div>
  );
}
