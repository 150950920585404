/**
@copyright  :   ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
@author     :   Shiv Charan Panjeta 
 
All Rights Reserved.
Proprietary and confidential :  All information contained here in is, and remains
the property of ToXSL Technologies Pvt. Ltd. and it's partners.
Unauthorized copying of this file, via any medium is strictly prohibited.
*/
import { useEffect, useState } from 'react';
import { Button, Container } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Table from 'react-bootstrap/Table';
import ReactPaginate from 'react-paginate';
import { useNavigate, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import Loader from '../../../components/commoncomponents/loader/loader-large';
import { restrictAlpha } from '../../../globals/helper';
import * as adminServices from '../../../services/AuthServices';

export default function EventBookingDetails() {
  const [record, setRecord] = useState('');
  const [currentPage, setCurrentPage] = useState(0);
  const [orderBy, setOrderBy] = useState('DESC');
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const [searchData, setSearchData] = useState({
    event_id: '',
    user_id: '',
    name: '',
    mobile: '',
    status: ''
  });

  const [list, setList] = useState([]);
  const [eventStatus, setEventStatus] = useState('');
  const searchHandler = (e) => {
    e.preventDefault();
    setLoading(true);
    apiCall(
      1,
      'id',
      'DESC',
      id,
      searchData.user_id,
      searchData.name,
      searchData.mobile,
      searchData.status
    );
  };

  let navigate = useNavigate();
  const handleView = (e, bookingId) => {
    e.preventDefault();
    navigate(`/admin/event/view_booking/${bookingId}`);
  };
  useEffect(() => {
    apiCall();
  }, []);

  const apiCall = (
    page = 1,
    sortfield = '',
    order = '',
    event_id = id,
    user_id = '',
    name = '',
    mobile = '',
    status = ''
  ) => {
    setLoading(true);

    const body = {
      page,
      sortfield,
      order,
      event_id,
      user_id,
      name,
      mobile,
      status
    };
    let pageNo = page ? page : 0;
    setCurrentPage(pageNo);

    adminServices.adminEventTicketList(body).then((resp) => {
      setLoading(false);
      if (resp?.status === 200) {
        setLoading(false);
        setList(resp?.data?.detail.viewlist);
        setRecord(resp?.data?.detail?.totalRecords);
      }
    });
  };

  const onChangeSearch = (e) => {
    const { name, value } = e.target;
    setSearchData({ ...searchData, [name]: value });
  };

  const sortHandler = (e, type) => {
    e.preventDefault();
    let currentSort = orderBy === 'ASC' ? 'DESC' : 'ASC';
    setOrderBy(currentSort);
    searchHandler(
      1,
      currentSort,
      type,
      searchData.event_id,
      searchData.user_id,
      searchData.name,
      searchData.mobile,
      eventStatus
    );
    setCurrentPage(0);
  };

  const handlePageClick = (a) => {
    let currentPage = a?.selected + 1;
    apiCall(currentPage);
  };

  return (
    <>
      <div className="dash-main-content">
        <Container fluid>
          <div className="dash-page-content">
            <div className="dash-page-title">
              <Row>
                <Col xs={12} sm={12} md={12}>
                  <div className="dash-title-bar d-flex align-items-center justify-content-between">
                    <div className="title-lft d-flex align-items-center">
                      <div className="dash-icn">
                        <i className="fa fa-users"></i>
                      </div>
                      <div className="dash-title-dis">
                        <h5>
                          Event Tickets <span>Bookings</span>
                        </h5>
                        <h5>
                          Total Record(s): <span>{record}</span>
                        </h5>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="dash-content-card">
              <div className="table-responsive">
                <Table className="custom-table-data" striped bordered hover>
                  <thead>
                    <tr>
                      <th>
                        <Link
                          to=""
                          onClick={(e) => {
                            sortHandler(e, 'event_id');
                          }}>
                          Booking Id
                          <img src={require('../../../assets/images/sort.png')} alt="sort" />
                        </Link>
                      </th>
                      <th>
                        <Link
                          to=""
                          onClick={(e) => {
                            sortHandler(e, 'userId');
                          }}>
                          User Id
                          <img src={require('../../../assets/images/sort.png')} alt="sort" />
                        </Link>
                      </th>
                      <th>
                        <Link
                          to=""
                          onClick={(e) => {
                            sortHandler(e, 'name');
                          }}>
                          Buyer Name
                        </Link>
                      </th>
                      <th>
                        <Link
                          to=""
                          onClick={(e) => {
                            sortHandler(e, 'mobile');
                          }}>
                          Buyer Mobile
                        </Link>
                      </th>
                      <th>
                        <Link to="">Qunatity</Link>
                      </th>
                      <th>
                        <Link to="">Amount</Link>
                      </th>
                      <th>
                        <Link
                          to=""
                          onClick={(e) => {
                            sortHandler(e, 'status');
                          }}>
                          Status
                          <img src={require('../../../assets/images/sort.png')} alt="sort" />
                        </Link>
                      </th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="search-table">
                      <td>
                        <Form.Control
                          type="text"
                          placeholder="Booking Id"
                          value={searchData.event_id}
                          name="Booking Id"
                          onChange={(e) => {
                            onChangeSearch(e);
                          }}
                          onKeyPress={restrictAlpha}
                        />
                      </td>

                      <td>
                        <Form.Control
                          type="text"
                          placeholder="User Id"
                          value={searchData.user_id}
                          name="user_id"
                          onChange={(e) => {
                            onChangeSearch(e);
                          }}
                        />
                      </td>
                      <td>
                        <Form.Control
                          type="email"
                          placeholder="Name"
                          value={searchData.name}
                          name="name"
                          onChange={(e) => {
                            onChangeSearch(e);
                          }}
                        />
                      </td>
                      <td>
                        <Form.Control
                          type="email"
                          placeholder="Mobile"
                          value={searchData.mobile}
                          name="mobile"
                          onChange={(e) => {
                            onChangeSearch(e);
                          }}
                        />
                      </td>
                      <td></td>
                      <td>
                        <div className="d-flex align-items-center"></div>
                      </td>
                      <td>
                        <Form.Select
                          aria-label="Default select example"
                          name="status"
                          value={searchData?.event_status}
                          onChange={(e) => {
                            setEventStatus(e.target.value);
                          }}>
                          <option value="">Select</option>
                          <option value={1}>Enable</option>
                          <option value={0}>Disable</option>
                        </Form.Select>
                      </td>

                      <td className="serac-btns" colSpan={2}>
                        <Button
                          className="main-btn-grn"
                          onClick={(e) => {
                            searchHandler(e);
                          }}>
                          Search
                        </Button>
                      </td>
                    </tr>
                    {list && list?.length ? (
                      list?.map((data, index) => {
                        return (
                          <tr key={index}>
                            <td>{data.id}</td>
                            <td>{data?.user_id} </td>
                            <td>{data?.username}</td>
                            <td>{data?.mobile}</td>
                            <td>{data?.total_quantity}</td>
                            <td>
                              {data?.total_price ? (
                                <i className="fas fa-rupee-sign me-1"> {data?.total_price}</i>
                              ) : (
                                ''
                              )}
                            </td>
                            <td>{data.status == '1' ? 'Enable' : 'Disable'}</td>
                            <td>
                              <Link onClick={(e) => handleView(e, data?.id)}>
                                <i className="fas fa-search-plus ms-1"></i>
                              </Link>
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr className="text-center">
                        <td colSpan={9}>Data Not Found</td>{' '}
                      </tr>
                    )}
                  </tbody>
                </Table>

                <div className="react-page d-flex">
                  {record / 10 > 1 ? (
                    <ReactPaginate
                      previousLabel={currentPage > 0 ? '<' : ''}
                      nextLabel=">"
                      onPageChange={handlePageClick}
                      pageCount={record / 10}
                      activeClassName={'page-link active'}
                      forcePage={currentPage}
                    />
                  ) : (
                    ''
                  )}
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
      {loading ? <Loader /> : <></>}
    </>
  );
}
