/**
@copyright  :   ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
@author     :   Shiv Charan Panjeta 
 
All Rights Reserved.
Proprietary and confidential :  All information contained here in is, and remains
the property of ToXSL Technologies Pvt. Ltd. and it's partners.
Unauthorized copying of this file, via any medium is strictly prohibited.
*/

import moment from 'moment';
import { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Table from 'react-bootstrap/Table';
import ReactPaginate from 'react-paginate';
import NoDataFound from '../../components/commoncomponents/NoDataFound';
import useDocumentTitle from '../../globals/useDocumentTitle';
import * as ADMINSERVICES from '../../services/AuthServices';

export default function ImportReport() {
  useDocumentTitle('Manage Import Reports | AlmaBay');
  const [currentPage, setCurrentPage] = useState(0);
  const [reportList, setReportList] = useState([]);
  const [record, setRecord] = useState('');

  useEffect(() => {
    reportsListing();
  }, []);

  const reportsListing = (pageNo = '1') => {
    setCurrentPage(pageNo);
    ADMINSERVICES.importUserReport(pageNo).then((resp) => {
      if (resp?.status === 200) {
        setReportList(resp?.data?.detail);
        setRecord(resp?.data?.pager?.total);
      }
    });
  };

  //paginate

  const handlePageClick = (a) => {
    let currentPage = a?.selected + 1;
    reportsListing(currentPage);
  };

  const status = (value) => {
    switch (value) {
      case '0':
        return 'Pending';
      case '1':
        return 'In Progress';
      case '2':
        return 'Completed';
    }
  };

  return (
    <div className="dash-main-content">
      <Container fluid>
        <div className="dash-page-content">
          <div className="dash-page-title">
            <Row>
              <Col xs={12} sm={12} md={12}>
                <div className="dash-title-bar d-flex align-items-center justify-content-between">
                  <div className="title-lft d-flex align-items-center">
                    <div className="dash-icn">
                      <i className="fas fa-file"></i>
                    </div>
                    <div className="dash-title-dis">
                      <h5>
                        Import <span>Listing</span>
                      </h5>
                      <h5>
                        Total Record(s): <span>{record ? record : '0'}</span>
                      </h5>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <div className="dash-content-card">
            <div className="table-responsive">
              <Table className="custom-table-data" striped bordered hover>
                <thead>
                  <tr>
                    <th>Id</th>
                    <th>Applicable For</th>
                    <th>UserName</th>
                    <th>Action</th>
                    <th>Date and time</th>
                    <th>Status</th>
                    <th>Percentage</th>
                  </tr>
                </thead>
                <tbody>
                  {reportList && reportList != 0 ? (
                    reportList?.map((data, index) => {
                      return (
                        <tr key={index}>
                          <td>{data?.id}</td>
                          <td>{data?.sheet_type}</td>
                          <td>{data?.created_by_name}</td>
                          <td>Import</td>

                          <td>{moment(data?.created_on).format('lll')}</td>
                          <td>{status(data?.state_id)}</td>
                          <td> {data?.percentage ? data?.percentage + ` %` : '0 %'} </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr className="text-center">
                      <td colSpan={9}>
                        <NoDataFound />
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
              <div className="react-page d-flex">
                {record / 10 > 1 ? (
                  <ReactPaginate
                    previousLabel={currentPage > 0 ? '<' : ''}
                    nextLabel=">"
                    onPageChange={handlePageClick}
                    pageCount={record / 10}
                    activeClassName={'page-link active'}
                  />
                ) : (
                  ''
                )}
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}
