import { Form } from 'react-bootstrap';
import { Nav } from 'react-bootstrap';
import { Tab } from 'react-bootstrap';
import Dropdown from 'react-bootstrap/Dropdown';
import { Col, Row, Button } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import Loader from '../../../commoncomponents/loader/loader-large';
import { useSelector } from 'react-redux';
import { FriendsSkelton } from '../../../commoncomponents/skelton/allskelton';
import NoDataFound from '../../../commoncomponents/NoDataFound';

export default function UserFriends(props) {
  const navigate = useNavigate();
  const userDetail = useSelector((state) => state?.user?.details);
  const { userId } = useParams();

  const goToMessagePage = (id) => {
    navigate(`/user/messages`, { state: id });
  };

  return (
    <>
      <div className="friend-card-sec bg-white">
        <Tab.Container id="left-tabs-example" defaultActiveKey="friend1">
          {userDetail?.encrypted_id !== userId && (
            <Nav variant="pills" className="fried-card-tab mb-3">
              <Nav.Item>
                <Nav.Link eventKey="friend1">Friends</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="friend2">Mutual Friends</Nav.Link>
              </Nav.Item>
            </Nav>
          )}

          <Tab.Content>
            <Tab.Pane eventKey="friend1">
              <div className="frirnd-hd d-flex align-items-center justify-content-between p-2">
                <h4>Friends</h4>
                <Form.Group className="mb-0" controlId="formBasicEmail">
                  <Form.Control
                    type="text"
                    placeholder="Search"
                    onChange={(e) => props?.handleSearch(e.target.value)}
                  />
                </Form.Group>
              </div>
              {props?.friendsData?.length !== 0 ? (
                <>
                  <div className="mentees-inr-card p-3">
                    <Row>
                      {props?.friendsData?.map((friend, index) => {
                        return (
                          <Col md={3} sm={3} xs={6} key={index}>
                            <div
                              style={{ height: 250 }}
                              className="mentees-pro-card d-flex flex-column justify-content-between"
                            >
                              <div className="mentees-img">
                                <img
                                  src={
                                    friend?.avatar
                                      ? friend?.avatar?.complete_url
                                      : require('../../../../assets/images/default-male-avatar.png')
                                  }
                                  alt=""
                                />
                              </div>
                              <div className="mentees-dis">
                                <h4>{friend?.name}</h4>
                                {friend?.current_city && <p>{friend?.current_city}</p>}
                                {userDetail?.encrypted_id == friend?.encrypted_id ? (
                                  <Button className="blue-btn">You</Button>
                                ) : (
                                  <>
                                    {' '}
                                    <Dropdown className="mt-2">
                                      <Dropdown.Toggle variant="success" id="dropdown-basic">
                                        Your friend
                                      </Dropdown.Toggle>

                                      <Dropdown.Menu>
                                        <Dropdown.Item
                                          onClick={() => {
                                            navigate(`/user/timeline/${friend?.encrypted_id}`);
                                            props.setActiveTab('first');
                                          }}
                                          // href={}
                                        >
                                          View Profile
                                        </Dropdown.Item>
                                        <Dropdown.Item onClick={() => goToMessagePage(friend?.id)}>
                                          Send Message
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          onClick={(e) =>
                                            props.resquestHanderFriend(e, friend?.id, 'remove')
                                          }
                                        >
                                          Unfriend
                                        </Dropdown.Item>
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </>
                                )}
                              </div>
                            </div>
                          </Col>
                        );
                      })}
                    </Row>
                  </div>
                </>
              ) : (
                <>
                  {props?.friendsData?.length === 0 && !props?.loading && (
                    <NoDataFound text="Friends not found." />
                  )}
                </>
              )}
              {props?.loading && (
                <div className="p-4">
                  <Row>
                    {Array.from(Array(8), () => {
                      return (
                        <Col md={3} sm={3} xs={6}>
                          <FriendsSkelton />
                        </Col>
                      );
                    })}
                  </Row>
                </div>
              )}
            </Tab.Pane>
            <Tab.Pane eventKey="friend2">
              <div className="frirnd-hd d-flex align-items-center justify-content-between p-2">
                <h4>Mutual Friends</h4>
                <Form.Group className="mb-0" controlId="formBasicEmail">
                  <Form.Control type="text" placeholder="Search" />
                </Form.Group>
              </div>
              <div className="mentees-inr-card p-3">
                <Row>
                  {props?.mutualFriends?.length !== 0 ? (
                    <>
                      {' '}
                      {props?.mutualFriends?.map((mutual, index) => {
                        return (
                          <Col md={3} sm={3} xs={6} key={index}>
                            <div className="mentees-pro-card">
                              <div className="mentees-img">
                                <img
                                  src={
                                    mutual?.avatar?.complete_url
                                      ? mutual?.avatar?.complete_url
                                      : require('../../../../assets/images/default-male-avatar.png')
                                  }
                                  alt=""
                                />
                              </div>
                              <div className="mentees-dis">
                                <h4>{mutual?.name}</h4>
                                {mutual?.current_city && <p>{mutual?.current_city}</p>}
                                <Dropdown className="mt-2">
                                  <Dropdown.Toggle variant="success" id="dropdown-basic">
                                    Your friend
                                  </Dropdown.Toggle>

                                  <Dropdown.Menu>
                                    <Dropdown.Item href={`/user/timeline/${mutual?.encrypted_id}`}>
                                      View Profile
                                    </Dropdown.Item>
                                    <Dropdown.Item href="/user/messages" target="_blank">
                                      Send Message
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      onClick={(e) =>
                                        props.resquestHanderFriend(e, mutual?.id, 'remove')
                                      }
                                    >
                                      Unfriend
                                    </Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </div>
                            </div>
                          </Col>
                        );
                      })}
                    </>
                  ) : (
                    <>
                      {!props?.loading && props?.mutualFriends?.length == 0 && (
                        <NoDataFound text="Mutual friends not found." />
                      )}
                    </>
                  )}
                </Row>
              </div>
              {props?.loading && (
                <div className="p-4">
                  <Row>
                    {Array.from(Array(8), () => {
                      return (
                        <Col md={3} sm={3} xs={6}>
                          <FriendsSkelton />
                        </Col>
                      );
                    })}
                  </Row>
                </div>
              )}
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </div>
      {props?.isLoading ? <Loader /> : null}
    </>
  );
}
