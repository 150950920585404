import { useState, useEffect } from 'react';
import { Col, Container, Dropdown, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import HeaderLogin from '../../commoncomponents/HeaderLogin';
import TopTabbings from './TopTabbings';
import SuggestMentor from '../../commoncomponents/newsfeeds/SuggestMentor';
import GroupDiscover from '../../commoncomponents/newsfeeds/GroupDiscover';
import WhatsNew from '../../commoncomponents/newsfeeds/WhatsNew';
import Upcoming from '../../commoncomponents/newsfeeds/Upcoming';
import NewsSidebar from '../../commoncomponents/newsfeeds/NewsSidebar';
import Form from 'react-bootstrap/Form';
import { truncate } from '../../../globals/helper';
import showNotification from '../../../services/NotificationService';
import * as APISERVICES from '../../../services/MentorArticlesServices';
import { MiniFooter } from '../../MiniFooter';
import moment from 'moment/moment';
import { MentorshipSkelton } from '../../commoncomponents/skelton/allskelton';
import { OverlayTrigger, Popover } from 'react-bootstrap';

function MyAnswers() {
  const [loading, setLoading] = useState(false);
  const [myAnswer, setMyAnswer] = useState([]);
  const [listData, setListData] = useState([]);
  const [pageNum, setPageNum] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const loginUserDetails = JSON.parse(localStorage.getItem('userDetail'));
  useEffect(() => {
    if (pageNum <= totalPages) {
      getMyAnswers();
    }
  }, [pageNum]);

  const HelpFullHandler = (e, id) => {
    e.preventDefault();
    const body = {
      q_id: id,
      answer_id: 0,
      type: 'like'
    };

    let formData = new FormData();

    for (let i in body) {
      formData.append(`${i}`, body[i]);
    }
    APISERVICES.mentorshipQaFeedLike(formData).then((res) => {
      if (res?.status === 200) {
        showNotification('success', 'Marked as helpful, Success!');
        getMyAnswers(true);
      }
    });
  };

  const getMyAnswers = (isTrue) => {
    setLoading(true);
    APISERVICES.getMentorshipAnswer(pageNum).then((resp) => {
      setLoading(false);
      if (resp?.status === 200) {
        if (isTrue) {
          let arra1 = myAnswer.map((item) => {
            const item2 = resp.data?.detail?.find((i2) => i2.id == item.id);
            return item ? { ...item, ...item2 } : item2;
          });
          setMyAnswer(arra1);
        } else {
          setMyAnswer([...myAnswer, ...resp.data.detail]);
        }
        setListData(resp?.data?.detail); // user for searching
        setTotalPages(resp?.data?.totalRecords && Math.ceil(resp?.data?.totalRecords / 20));
      }
    });
  };

  const handleSearch = (e) => {
    let value = e.target.value.toLowerCase();
    if (value !== '') {
      let result = listData.filter((data) => {
        return (
          data.title.toLowerCase().includes(value) ||
          data?.description.toLowerCase().includes(value)
        );
      });
      setMyAnswer(result);
    } else {
      setMyAnswer(listData);
    }
  };

  const loadMore = (e) => {
    e.preventDefault();
    setPageNum((no) => no + 1);
  };

  const renderPopover = (props) => {
    let popOverData = props?.popper?.state?.options?.data?.asked_by
      ? props?.popper?.state?.options?.data?.asked_by
      : props?.popper?.state?.options?.data?.answered_bye;

    return (
      <Popover className="group-loader-card" id={`popover-basic`} {...props}>
        {/* <Popover.Header as="h3">Popover right</Popover.Header> */}
        <Popover.Body>
          <div className="loader-tooltip">
            <div className="loader-cover">
              <img
                src={
                  popOverData?.cover_url ? popOverData?.cover_url : popOverData?.actual_cover_url
                }
                alt="img"
                className="loader-image"
              />
            </div>
            <div className="people-know-loader-dis d-flex align-items-top">
              <div className="people-knw-top d-flex align-items-center">
                <div className="people-icn me-3">
                  <img
                    src={
                      popOverData?.avatar?.complete_url
                        ? popOverData?.avatar?.complete_url
                        : popOverData?.avatar_url
                    }
                    alt="img"
                    className="loader-image"
                  />
                </div>
                <div className="people-hd">
                  <h3>{popOverData?.name}</h3>
                </div>
              </div>
            </div>
            <div className="people-knw-dis-popover">
              <p>
                <i className="fas fa-graduation-cap me-1"></i> Studied at{' '}
                <Link to="#">SD COLLEGE Chandigarh</Link> in <Link to="#">2005</Link>
              </p>
              {/**have to add key for this, key not added from backend */}
              <p>
                <i className="fas fa-briefcase me-1"></i>Worked as{' '}
                <Link to="#">Software developer</Link> {' at '}{' '}
                {/**have to add key for this, key not added from backend */}
                <Link to="#">TCS PVT LTD</Link>{' '}
                {/**have to add key for this, key not added from backend */}
              </p>
            </div>
          </div>
        </Popover.Body>
      </Popover>
    );
  };

  return (
    <>
      <HeaderLogin />
      <div className="header-lnk-feeds">
        <div className="container">
          <TopTabbings state={'mentor'} />
        </div>
      </div>
      <section className="news-feed-sec bg-grey py-4">
        <Container>
          <Row>
            <Col xs={12} lg={2} md={12}>
              <NewsSidebar state="2" current="myAnswers" />
            </Col>
            <Col xs={12} lg={7} md={12}>
              <div className="articals_div ">
                <div className="serch-mentors-data mb-3">
                  <Form.Control
                    type="search"
                    placeholder="Search in mentor's data..."
                    onChange={handleSearch}
                  />
                </div>

                {/* Simmer loader */}
                {loading ? (
                  <>
                    {Array.from(Array(3), function () {
                      return <MentorshipSkelton />;
                    })}
                  </>
                ) : myAnswer?.length > 0 ? (
                  myAnswer?.map((data, index) => (
                    <div className="single_artical_card leave-ques-card p-3" key={index}>
                      <div className="d-flex align-items-center mb-3">
                        <Link
                          className="media_img"
                          to={`/user/timeline/${data?.asked_by?.encrypted_id}`}
                        >
                          {/* <img
                            src={
                              data?.asked_by?.avatar?.complete_url
                                ? data?.asked_by?.avatar?.complete_url
                                : data?.asked_by?.avatar_url
                            }
                            alt="user_img"
                            className="me-3"
                          /> */}
                          <OverlayTrigger
                            trigger={['hover', 'focus']}
                            placement="bottom"
                            delay={{ show: 500, hide: 1500 }}
                            data={data}
                            popperConfig={{
                              data
                            }}
                            shouldUpdatePosition={true}
                            overlay={renderPopover}
                          >
                            <img
                              src={
                                data?.asked_by?.avatar?.complete_url
                                  ? data?.asked_by?.avatar?.complete_url
                                  : data?.asked_by?.avatar_url
                              }
                              alt="user_img"
                            />
                          </OverlayTrigger>
                        </Link>
                        <div className="media-body">
                          <h4>
                            <Link
                              className="user-link"
                              to={`/user/timeline/${data?.asked_by?.encrypted_id}`}
                            >
                              {data?.asked_by?.name}
                            </Link>
                            <span>asked it, answered by </span>
                            <Link
                              className="user-link"
                              to={`/user/timeline/${loginUserDetails?.encrypted_id}`}
                            >
                              {loginUserDetails?.name}
                            </Link>
                          </h4>
                          <p title={data?.added_on}> {moment(data?.added_on).fromNow()}</p>
                        </div>
                      </div>
                      <div>
                        <h4 className="title">
                          <Link
                            to={`${
                              data?.content_type === 'question'
                                ? `/user/viewanswer/${data?.id}`
                                : `/user/mentor-article/${data?.id}`
                            }`}
                          >
                            {data?.title}
                          </Link>
                        </h4>
                        <span
                          dangerouslySetInnerHTML={{ __html: truncate(data?.description, 500) }}
                        />
                      </div>
                      <div>
                        <Link
                          className="read-more"
                          to={`${
                            data?.content_type === 'question'
                              ? `/user/viewanswer/${data?.id}`
                              : `/user/mentor-article/${data?.id}`
                          }`}
                        >
                          Read more...
                        </Link>
                      </div>

                      <div className="navigation-border">
                        <ul className="article-cmmnt-lnks d-flex ps-2">
                          <li className="active-helpful pe-3">
                            {data?.liked_by_current_user ? (
                              <Link className='active-helpful' title="You marked as helful">
                                <i className="fa fa-heart "></i> Helpful{' '}
                                <span>({data?.likes})</span>
                              </Link>
                            ) : (
                              <Link
                                to="#"
                                className="pe-0 "
                                onClick={(e) => {
                                  HelpFullHandler(e, data?.id);
                                }}
                                title="Mark as helpful"
                              >
                                <i className="fa fa-heart "></i> Helpful
                                <span>({data?.likes})</span>
                              </Link>
                            )}
                          </li>
                          <li>
                            <Link
                              to={`${
                                data?.content_type === 'question'
                                  ? `/user/viewanswer/${data?.id}`
                                  : `/user/mentor-article/${data?.id}`
                              }`}
                              className="pe-3"
                            >
                              <i className="fa fa-comment"></i> {'  '}{' '}
                              {data?.content_type === 'question' ? 'Answers' : 'Comments'}{' '}
                              <span>
                                (
                                {data?.content_type === 'question' ? data?.answers : data?.comments}
                                )
                              </span>
                            </Link>
                          </li>
                          <li className="share-drop-btn">
                            <Dropdown>
                              <Dropdown.Toggle className="pe-3" id="dropdown-basic">
                                <i className="fa fa-share"></i> Share
                              </Dropdown.Toggle>
                              <Dropdown.Menu className="p-2">
                                <Dropdown.Item
                                  as={Link}
                                  target="_blank"
                                  to={`https://www.facebook.com/sharer/sharer.php?u=${
                                    process.env.REACT_APP_DOMAIN +
                                    'user/viewanswer' +
                                    '/' +
                                    data?.id
                                  }`}
                                >
                                  Facebook
                                </Dropdown.Item>
                                <Dropdown.Item
                                  as={Link}
                                  target="_blank"
                                  to={`https://twitter.com/intent/tweet?url=${
                                    process.env.REACT_APP_DOMAIN +
                                    'user/viewanswer' +
                                    '/' +
                                    data?.id
                                  }`}
                                >
                                  Twitter
                                </Dropdown.Item>
                                <Dropdown.Item
                                  as={Link}
                                  target="_blank"
                                  to={`https://plus.google.com/share?url=${
                                    process.env.REACT_APP_DOMAIN +
                                    'user/viewanswer' +
                                    '/' +
                                    data?.id
                                  }`}
                                >
                                  Google
                                </Dropdown.Item>
                                <Dropdown.Item
                                  as={Link}
                                  target="_blank"
                                  to={`https://www.linkedin.com/shareArticle?url=${
                                    process.env.REACT_APP_DOMAIN +
                                    'user/viewanswer' +
                                    '/' +
                                    data?.id
                                  }&mini=true&title=${data?.title}&source=AlmaBay`}
                                >
                                  Linkedin
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </li>
                        </ul>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="p-5 bg-white text-center">
                    <img
                      src={require('../../../assets/images/small-error.png')}
                      width="105px"
                      alt="img"
                    />
                    <p>You have not posted any answer at the moment.</p>
                  </div>
                )}

                {pageNum + 1 < totalPages && (
                  <div className="load-more-btn py-3 text-center">
                    <Link to="#" className="read-more" onClick={(e) => loadMore(e)}>
                      Load More
                    </Link>
                  </div>
                )}
              </div>
            </Col>
            <Col lg={3} md={12}>
              <div>
                <SuggestMentor />
              </div>
              <div>
                <GroupDiscover />
              </div>
              <div>
                <WhatsNew />
              </div>
              <div>
                <Upcoming />
              </div>
              <MiniFooter />
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}

export default MyAnswers;
