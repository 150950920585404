/**
@copyright  :   ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
@author     :   Shiv Charan Panjeta 
 
All Rights Reserved.
Proprietary and confidential :  All information contained here in is, and remains
the property of ToXSL Technologies Pvt. Ltd. and it's partners.
Unauthorized copying of this file, via any medium is strictly prohibited.
*/

import { Container } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { Link } from 'react-router-dom';

export default function AppliedDetails() {
  return (
    <div className="dash-main-content">
      <Container fluid>
        <div className="dash-page-content">
          <div className="dash-page-title mb-3">
            <Row>
              <Col xs={12} sm={12} md={12}>
                <div className="dash-title-bar d-flex align-items-center justify-content-between">
                  <div className="title-lft d-flex align-items-center">
                    <div className="dash-icn">
                      <i className="fas fa-briefcase"></i>
                    </div>
                    <div className="dash-title-dis">
                      <h5>
                        Job &nbsp;
                        <span>Details</span>
                      </h5>
                    </div>
                  </div>
                  <div className="title-rt-btn d-flex align-items-center">
                    <Link className="add-btn-grn" to="/admin/jobfest/job/create">
                      Back To Listing
                    </Link>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <div className="dash-content-card applied-cntnt">
            <div className="apllied-card">
              <div className="applied-lft-dis">
                <div className="applied-dis">
                  <p>
                    <b>Job Title : </b>Web Application Developer with 5 years experience urgently
                    required
                  </p>
                  <p>
                    <b>Job Posted By : </b>Job application for Web Application Developer with 5
                    years experience urgently required
                  </p>
                  <p>
                    <b>Short Description : </b>Experience Desired: 4-5 years in PHP/MySQL Strong
                    background in AJAX, Core PHP, MySQL, JavaScript. Experience with any of the
                    frameworks desired – CakePHP, Codeigniter, Zend, Symfony framework. Should have
                    experience working on third party API integration, XML/Web Services, Payment
                    gateways. Experience with applications involving application security would be a
                    plus. Should be an excellent problem solver and code troubleshooter. Should take
                    responsibility and ownership of code. Excellent communication and interpersonal
                    skills is a must.
                  </p>
                  <p>
                    <b>Job Category : </b> Web Development
                  </p>
                  <p>
                    <b>Key Skills : </b> Cakephp php ajax api Yii codeigniter paypal wordpress
                    authorize.net skrill{' '}
                  </p>
                  <p>
                    <b>Open positions : </b> 2
                  </p>
                  <p>
                    <b>Work experience : </b> 4 - 6
                  </p>
                  <p>
                    <b>Company hiring for : </b> Vebific Technologies LLP
                  </p>
                  <p>
                    <b>Location : </b> Chandigarh
                  </p>
                  <p>
                    <b>HR Email : </b> careers@almabay.com
                  </p>
                  <p>
                    <b>Contact Number : </b> 8699022278
                  </p>
                  <p>
                    <b>
                      Allow job finder to get referred from other people working in the same company
                      :{' '}
                    </b>{' '}
                    Yes
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}
