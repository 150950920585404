/**
@copyright  :   ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
@author     :   Shiv Charan Panjeta 
 
All Rights Reserved.
Proprietary and confidential :  All information contained here in is, and remains
the property of ToXSL Technologies Pvt. Ltd. and it's partners.
Unauthorized copying of this file, via any medium is strictly prohibited.
*/
import { useEffect, useState } from 'react';
import { Button, Container } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Loader from '../../../components/commoncomponents/loader/loader-large';
import { addManageSponsors, detailsManageSponsors } from '../../../services/AuthServices';
import showNotification from '../../../services/NotificationService';

export default function AddEventSponsor() {
  const [clicked, setClicked] = useState(false);
  let navigate = useNavigate();
  const { event_id, id } = useParams();
  let location = useLocation();
  const [loading, setLoading] = useState(false);

  const [sponsorState, setSponsorState] = useState({
    sponser_name: '',
    image: '',
    event_id: location?.state
  });

  const validate = () => {
    let errors = {};
    if (sponsorState?.sponser_name == '') {
      errors.name = 'Please enter sponsor name';
    }
    if (!sponsorState?.image) {
      errors.image = 'Please upload an image';
    }
    return errors;
  };

  const handleChange = (e) => {
    const { name, value, type } = e.target;
    if (type === 'file') {
      let file = e?.target?.files[0];
      setSponsorState({ ...sponsorState, [name]: file });
    } else {
      setSponsorState({ ...sponsorState, [name]: value });
    }
  };

  useEffect(() => {
    if ((event_id, id)) {
      getDetail(event_id, id);
    }
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    setClicked(true);

    if (!Object.keys(validate()).length) {
      setLoading(true);
      let formData = new FormData();
      for (let i in sponsorState) {
        formData.append(`${i}`, sponsorState[i]);
      }

      addManageSponsors(formData).then((res) => {
        setLoading(false);
        if (res?.status === 200) {
          showNotification('success', res?.data?.message);
          navigate(-1);
        }
      });
    }
  };

  const updateHandler = (e) => {
    e.preventDefault();
    setClicked(true);

    let body = {
      sponser_name: sponsorState?.sponser_name,
      image: sponsorState?.image,
      event_id: sponsorState?.event_id,
      id: id
    };

    addManageSponsors(body).then((res) => {
      if (res?.status == 200) {
        showNotification('success', res?.data?.message);
        navigate(-1);
      }
    });
  };

  const getDetail = (event_id, id) => {
    detailsManageSponsors(event_id, id).then((res) => {
      if (res?.status === 200) {
        const data = res?.data?.data?.detail;
        setSponsorState({
          sponser_name: data?.sponser_name,
          image: '',
          event_id: event_id,
          id: id
        });
      }
    });
  };

  return (
    <>
      <div className="dash-main-content">
        <Container fluid>
          <div className="dash-page-content">
            <div className="dash-page-title">
              <Row>
                <Col xs={12} sm={12} md={12}>
                  <div className="dash-title-bar d-flex align-items-center justify-content-between">
                    <div className="title-lft d-flex align-items-center">
                      <div className="dash-icn">
                        <i className="fas fa-university"></i>
                      </div>
                      <div className="dash-title-dis">
                        {id ? <h5>Update Event Sponsor</h5> : <h5>Add Event Sponsor</h5>}
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="dash-content-card">
              <div className="cmmn-form">
                <Form>
                  <Row>
                    <Col md={12}>
                      <Form.Group className="mb-3" controlId="formBasicName">
                        <Form.Label>
                          Sponsor Name<span className="text-danger"> *</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter sponsor name"
                          name="sponser_name"
                          value={sponsorState?.sponser_name}
                          onChange={(e) => {
                            handleChange(e);
                          }}
                        />
                        <div className={'danger-frm'}>{clicked && validate().name}</div>
                      </Form.Group>
                    </Col>
                    <Col md={12}>
                      <Form.Group className="mb-3" controlId="formBasicName">
                        <Form.Label>
                          Sponsor Image <span className="text-danger"> *</span>
                        </Form.Label>
                        <Form.Control
                          type="file"
                          name="image"
                          accept="image/*"
                          value={sponsorState?.sponser_logo}
                          onChange={(e) => {
                            handleChange(e);
                          }}
                        />
                        <div className={'danger-frm'}>{clicked && validate().image}</div>
                      </Form.Group>
                    </Col>
                  </Row>
                  <div className="form-btns d-flex align-items-center mt-3">
                    {id ? (
                      <>
                        <Button className="main-btn-grn" type="button" onClick={updateHandler}>
                          Update
                        </Button>{' '}
                        <strong>OR</strong>
                        <Button
                          className="main-btn-red"
                          onClick={() => {
                            navigate(-1);
                          }}>
                          Cancel
                        </Button>
                      </>
                    ) : (
                      <>
                        <Button className="main-btn-grn" onClick={handleSubmit}>
                          Save
                        </Button>
                        <strong>OR</strong>
                        <Button
                          className="main-btn-red"
                          onClick={() => {
                            navigate(-1);
                          }}>
                          Cancel
                        </Button>
                      </>
                    )}
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </Container>
      </div>
      {loading ? <Loader /> : ''}
    </>
  );
}
