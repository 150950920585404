import * as api from '../utils/requests';

export const jobCategoryList = async () => {
  return await api.getReq(`/jobs/category-list`);
};

export const jobSkillSetList = async () => {
  return await api.getReq(`/jobs/skillset-list`);
};

export const jobDetail = async () => {
  return await api.getReq(`/jobs/detail`);
};

export const appliedJob = async () => {
  return await api.getReq(`/jobs/applied-job`);
};

export const filterJobs = async (
  job_type = '',
  skills = '',
  company = '',
  industry = [],
  location = '',
  category_id = '',
  min_exp = '',
  max_exp = '',
  min_ctc = '',
  max_ctc = '',
  offset = '',
  type = ''
) => {
  return await api.PostReq(
    `/jobs/advanceSearchingOneMoreTime?job_type=${job_type}&skills=${skills}&companies=${company}&industry=${industry}&location=${location}&category=${category_id}&min_exp=${min_exp}&max_exp=${max_exp}&min_ctc=${min_ctc}&max_ctc=${max_ctc}&offset=${offset}&type=${type}`
  );
};
export const browseJob = async (q = '', type = '', offset = 0, page = 1) => {
  return await api.getReq(`/jobs/advanceSearch?q=${q}&type=${type}&offset=${offset}&page=${page}`);
};

export const newBrowseJob = async (page) => {
  return await api.getReq(`/jobs/browse-job?page=${page}`);
};

export const jobTopCompanyList = async () => {
  return await api.getReq(`/jobs/top-company-list`);
};

export const jobCompanySuggestions = async (username = '', offset = 0) => {
  return await api.getReq(
    `/service/similar-company-pages-suggestion?username=${username}&type=similar-company-pages-suggestion&offset=${offset}&limit=2`
  );
};

export const CompanySuggestionsWidget = async (offset = 0) => {
  return await api.getReq(`/service/suggested-company-pages?offset=${offset}&limit=2`);
};

export const getSingleJobDetail = async (id) => {
  return await api.getReq(`/jobs/getJobDetails?id=${id}`);
};

export const getSimilarJobList = async (id) => {
  return await api.getReq(`/jobs/similar-jobs?id=${id}`);
};

export const getAppliedJobDetail = async (page) => {
  return await api.getReq(`/jobs/getAppliedJobs?page=${page}`);
};

export const getPostedJobs = async (page) => {
  return await api.getReq(`/jobs/posted-jobs?page=${page}`);
};

export const applyForJob = async (id, body) => {
  return await api.PostReq(`/jobs/apply-job?id=${id}`, body);
};

export const postAJob = async (body) => {
  return await api.PostReq(`/jobs/add-post`, body);
};

export const viewApplicationForJobs = async (id) => {
  return await api.getReq(`/jobs/posted-job-applications?id=${id}`);
};

export const closeJob = async (id) => {
  return await api.PostReq(`/jobs/close-job?id=${id}`);
};

export const editJob = async (id, body) => {
  return await api.PostReq(`/jobs/edit-post?id=${id}`, body);
};

export const acceptReject = async (id, body) => {
  return await api.PostReq(`/jobs/accept-reject-job?id=${id}`, body);
};

export const recommendList = async (id) => {
  return await api.getReq(`/jobs/recommendModel?job_id=${id}`);
};

export const sendRecommendation = async (id, body) => {
  return await api.PostReq(`/jobs/sendRecommendation?job_id=${id}`, body);
};

// drop-down options for post a job

export const getJobCategory = async (page) => {
  return await api.getReq(`/jobs/category-list?page=${page}`);
};

export const getKeyWords = async () => {
  return await api.getReq(`/jobs/skillset-list`);
};
export const jobIndustriesDetail = async (id = '') => {
  return await api.getReq(`/jobs/getindustrydetails?id=${id}`);
};

export const getKeyWordsNew = async (q = '') => {
  return await api.getReq(`/jobs/keywords?q=${q}`);
};

export const getSearchedJob = async (
  job_type = '',
  skills = '',
  company = '',
  industry = '',
  location = '',
  category_id = '',
  min_exp = '',
  max_exp = '',
  min_ctc = '',
  max_ctc = '',
  page = 1
) => {
  return await api.getReq(
    `/jobs/searchJobs?job_type=${job_type}&skills=${skills}&company=${company}&industry=${industry}&location=${location}&category=${category_id}&min_exp=${min_exp}&max_exp=${max_exp}&min_ctc=${min_ctc}&max_ctc=${max_ctc}&page=${page}`
  );
};

export const getBrandJobsList = async (comp_id = '') => {
  return await api.getReq(`/jobs/advanceSearch?company_id=${comp_id}&q=brand`);
};

export const getJobInNetwork = async (
  location,
  company,
  skillset,
  category,
  min_exp,
  max_exp,
  min_ctc,
  max_ctc
) => {
  return await api.getReq(
    `/jobs/advanceSearch?company_id=network&filter=${encodeURIComponent(
      `location=${location}&company=${company}&skillset=${skillset}&category=${category}&min_exp=${min_exp}&max_exp=${max_exp}&min_ctc=${min_ctc}&max_ctc=${max_ctc}`
    )}`
  );
};

//company_hiring_for
export const getCompanyHiring = async (q = '') => {
  return await api.getReq(`/jobs/company-hiring-list?search=${q}`);
};

export const getMinCtc = async () => {
  return await api.getReq(`/jobs/getMinCtcDropdown`);
};

export const getMaxCtc = async () => {
  return await api.getReq(`/jobs/getMaxCtcDropdown`);
};

/**
 * ! internship related api
 */

export const getJobForInternship = async (id = '', page) => {
  return await api.getReq(`/jobs/getJobsForInternship?category_id=${id}&page=${page}`);
};

export const getJobForFresher = async (id = '') => {
  return await api.getReq(`/jobs/getJobsForFreshers?category_id=${id}`);
};

export const addPagePrivacy = async (body) => {
  return await api.PostReq(`/page/privacy_settings?t=page&a=update`, body);
};

export const followCompany = async (body) => {
  return await api.PostReq(`/company/followCompany?t=follow&a=follow&without_html=1`, body);
};

export const getRecommendations = async (type = '') => {
  return await api.getReq(`/jobs/recommendations?page=1&type=${type}`);
};

// referral api's
export const getReferralDetail = async (id = '') => {
  return await api.getReq(`/jobs/requestReferral?id=${id}`);
};

export const sendReferral = async (job_id = '', body) => {
  return await api.PostReq(`/jobs/sendReferral?id=${job_id}`, body);
};

export const getRequestedRef = async () => {
  return await api.getReq(`/jobs/referralRequested`);
};

export const getReceivedRef = async () => {
  return await api.getReq(`/jobs/referralReceived`);
};

export const jobShareLink = (body) => {
  return api.PostReq(`/jobs/shareJob`, body);
};

export const jobManageStatus = (job_id = '', user_id = '', req_id = '', type = '', decision) => {
  return api.getReq(
    `/jobs/manageJobStatus?job_id=${job_id}&user_id=${user_id}&req_id=${req_id}&type=${type}&decision=${decision}`
  );
};
