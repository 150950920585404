import { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { Link, useNavigate } from 'react-router-dom';
import showNotification from '../../../services/NotificationService';
import { addPollQuestions } from '../../../services/UserServices';

function AddPollQuestion(props) {
  const { setIsShowPoll, getPostListData, setAttemptPoll } = props;
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [options, setOptions] = useState([]);
  const [clicked, setClicked] = useState(false);
  const [show, setShow] = useState(false);
  const navigate = useNavigate();

  const validate = () => {
    let errors = {};
    if (title === '') {
      errors.title = 'Question is required.';
    }
    return errors;
  };

  /**add questions */
  const addPoll = (e) => {
    e.preventDefault();
    setClicked(true);

    if (!Object.keys(validate()).length) {
      let formData = new FormData();
      formData.append(`title`, title);
      formData.append(`description`, description);
      let arr = [];
      Object.keys(options).forEach((key) => {
        arr.push(options[key]);
      });
      formData.append('options', arr.join());
      addPollQuestions(formData).then((res) => {
        if (res?.status === 200) {
          setIsShowPoll(false);
          // getPostListData();
          showNotification('success', res?.data?.message);
          window.location.href = '/user';
          // navigate(`/user/response-poll`);
        } else {
          showNotification('err', res?.data?.message);
        }
      });
    } else {
      return;
    }
  };

  const resetHandler = (e) => {
    e.preventDefault();
    setTitle('');
    setDescription('');
    document.getElementById('option').value = '';
    document.getElementById('option1').value = '';
    document.getElementById('option2').value = '';
    document.getElementById('option3').value = '';
    setOptions({});
  };

  const optionHandler = (e) => {
    const { name, value } = e.target;
    setOptions({ ...options, [name]: value });
  };

  return (
    <>
      <div className="bg-white poll-card mb-2">
        <div className="poll-hd d-flex align-items-center justify-content-between">
          <h4>Add Poll Question</h4>
          <a
            href="#"
            onClick={() => {
              setIsShowPoll(false);
            }}
          >
            <i className="fas fa-times"></i>
          </a>
        </div>
        <div className="add-poll-question p-4">
          <div className="add-poll-card mb-4">
            <Form className="mt-2">
              <Row>
                <Col lg={12} md={12} xs={12}>
                  <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <Form.Label>Add Question 1</Form.Label>
                    <div className="quesn-control">
                      <Form.Control
                        type="txt"
                        placeholder="Enter your question"
                        name="title"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        className="question_box"
                      />
                      {!show && (
                        <Link
                          to="#"
                          className="qusn-cntrl-btn d-flex align-items-center"
                          onClick={(e) => {
                            e.preventDefault();
                            setShow(true);
                          }}
                        >
                          <i className="fas fa-plus"></i>
                        </Link>
                      )}
                    </div>

                    {show === true ? (
                      <div className="ques-control position-relative">
                        <Form.Control
                          as={`textarea`}
                          className="w-100 mt-2"
                          onChange={(e) => setDescription(e.target.value)}
                          placeholder="Description"
                          name="description"
                          value={description}
                          rows={3}
                        />
                        <Link
                          to="#"
                          className="position-absolute pol_question_cross text-muted"
                          onClick={(e) => {
                            e.preventDefault();
                            setShow(false);
                          }}
                        >
                          <i className="fa fa-times" aria-hidden="true"></i>
                        </Link>{' '}
                      </div>
                    ) : (
                      ''
                    )}

                    <div className={'danger-frm'}>{clicked && validate().title}</div>
                  </Form.Group>
                </Col>
                <Col lg={6} md={6} xs={12}>
                  <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <Form.Label>Add Option 'A'</Form.Label>
                    <Form.Control
                      type="txt"
                      id="option"
                      placeholder="Option 'A'"
                      name="a"
                      onChange={(e) => optionHandler(e)}
                      autoComplete="off"
                    />
                  </Form.Group>
                </Col>
                <Col lg={6} md={6} xs={12}>
                  <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <Form.Label>Add Option 'B'</Form.Label>
                    <Form.Control
                      type="txt"
                      id="option1"
                      placeholder="Option 'B'"
                      name="b"
                      onChange={(e) => optionHandler(e)}
                      autoComplete="off"
                    />
                  </Form.Group>
                </Col>
                <Col lg={6} md={6} xs={12}>
                  <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <Form.Label>Add Option 'C'</Form.Label>
                    <Form.Control
                      type="txt"
                      id="option2"
                      placeholder="Option 'C'"
                      name="c"
                      onChange={(e) => optionHandler(e)}
                      autoComplete="off"
                    />
                  </Form.Group>
                </Col>
                <Col lg={6} md={6} xs={12}>
                  <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <Form.Label>Add Option 'D'</Form.Label>
                    <Form.Control
                      type="txt"
                      id="option3"
                      placeholder="Option 'D'"
                      name="d"
                      onChange={(e) => optionHandler(e)}
                      autoComplete="off"
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Form>
            <div className="poll-qustion-btn">
              <button
                className="poll-btn me-2"
                onClick={(e) => {
                  resetHandler(e);
                }}
              >
                Reset
              </button>
              <button className="poll-btn" onClick={addPoll}>
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddPollQuestion;
