//  @copyright : ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
// @author     : Shiv Charan Panjeta

// All Rights Reserved.
// Proprietary and confidential :  All information contained herein is, and remains
// the property of ToXSL Technologies Pvt. Ltd. and its partners.
// Unauthorized copying of this file, via any medium is strictly prohibited.
import { useEffect, useState } from 'react';
import { Button, Container } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import Loader from '../../components/commoncomponents/loader/loader-large';
import { emailRegex, restrictAlpha } from '../../globals/helper';
import * as adminServices from '../../services/AuthServices';
import {
  addContactPerson,
  updateContactPerson,
  viewContactPerson
} from '../../services/InstituteServices';
import showNotification from '../../services/NotificationService';

const AddContactPerson = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const roleType = useSelector((state) => state?.user?.details);
  const [loading, setLoading] = useState(false);
  const [clicked, setClicked] = useState(false);
  const [status, setStatus] = useState(1);
  const [instituteValue, setInstituteValue] = useState('');
  const [singlefile, setSingleFile] = useState('');
  const [singlefilePreview, setSingleFilePreview] = useState('');
  const [attachment, setAttachMent] = useState('');
  const [contactPersonDetails, setContactPersonDetails] = useState({
    address: '',
    name: '',
    designation: '',
    email: '',
    contact: ''
  });

  useEffect(() => {
    if (id) {
      getDetail(id);
    }
  }, []);

  const onChangeHandler = (e) => {
    const { name, value } = e.target;
    if (e.target.name == 'contact') {
      if (e.target.value?.length > 10) {
        return false;
      } else {
        setContactPersonDetails({ ...contactPersonDetails, [name]: value });
      }
    }
    setContactPersonDetails({ ...contactPersonDetails, [name]: value });
  };

  const loadInstituteOptions = async (inputValue) => {
    return adminServices.getInstituteDrop(inputValue).then((res) => {
      if (res?.status === 200) {
        let tempArr = [];
        if (res?.data?.length) {
          const data = res?.data;
          data?.map((data) => {
            tempArr.push({
              id: data?.institute_id,
              label: data?.institutename,
              value: data?.institutename
            });
          });
        }
        return tempArr;
      }
    });
  };

  const validate = (value) => {
    let errors = {};

    if (!value?.address) {
      errors.address = 'This field is required';
    }
    if (!value?.name) {
      errors.name = 'This field is required';
    }
    if (!value?.designation) {
      errors.designation = 'This field is required';
    }
    if (!value?.email) {
      errors.email = 'This field is required';
    } else if (!emailRegex.test(value.email)) {
      errors.email = 'please enter valid email';
    }
    if (!value?.contact) {
      errors.contact = 'This field is required';
    }
    if (!singlefile) {
      errors.singlefile = 'This field is required';
    }

    return errors;
  };

  const submitHandler = (e) => {
    e.preventDefault();
    setClicked(true);
    if (!Object.keys(validate(contactPersonDetails)).length) {
      setLoading(true);
      let body = {
        address: contactPersonDetails?.address,
        name: contactPersonDetails?.name,
        designation: contactPersonDetails?.designation,
        email: contactPersonDetails?.email,
        contact: contactPersonDetails?.contact,
        status: status,
        institute_id: roleType?.institute_id,
        banner_image: singlefile
      };
      addContactPerson(body).then((res) => {
        setLoading(false);
        if (res?.status === 200) {
          showNotification('success', res?.data?.message);
          navigate('/admin/contact-person');
        }
      });
    }
  };

  const getDetail = (id) => {
    viewContactPerson(id).then((res) => {
      if (res?.status === 200) {
        const data = res?.data?.data ? res.data.data[0] : [];
        setContactPersonDetails({
          address: data?.address,
          name: data?.name,
          designation: data?.designation,
          email: data?.email,
          contact: data?.contact
        });
        setStatus(data?.status);
        setAttachMent(data?.banner_image);
        setSingleFile(data?.banner_image);
        setInstituteValue({
          id: data?.institute_id,
          label: data?.institute_name,
          value: data?.institute_name
        });
      }
    });
  };

  const updateHandler = (e) => {
    e.preventDefault();
    setClicked(true);

    if (!Object.keys(validate(contactPersonDetails)).length) {
      let body = {
        address: contactPersonDetails?.address,
        name: contactPersonDetails?.name,
        designation: contactPersonDetails?.designation,
        email: contactPersonDetails?.email,
        contact: contactPersonDetails?.contact,
        status: status,
        institute_id: roleType?.institute_id,
        banner_image: singlefile
      };

      updateContactPerson(id, body).then((res) => {
        if (res?.status === 200) {
          showNotification('success', res?.data?.message);
          navigate('/admin/contact-person');
        }
      });
    }
  };

  const handleFile = (e) => {
    const file = e?.target?.files[0];
    const maxFileSize = 500000; // 50KB
    if (file) {
      if (file?.size > maxFileSize) {
        showNotification('danger', ` File size exceeded the limit of ${maxFileSize / 1000} KB`);
      } else {
        setSingleFile(e.target.files[0]);
        setSingleFilePreview(URL.createObjectURL(e?.target?.files[0]));
      }
    } else {
      setSingleFile();
      setSingleFilePreview();
    }
  };

  return (
    <>
      <div className="dash-main-content">
        <Container fluid>
          <div className="dash-page-content">
            <div className="dash-page-title">
              <Row>
                <Col xs={12} sm={12} md={12}>
                  <div className="dash-title-bar d-flex align-items-center justify-content-between">
                    <div className="title-lft d-flex align-items-center">
                      <div className="dash-icn">
                        <i className="fas fa-university"></i>
                      </div>
                      <div className="dash-title-dis">
                        <h5>
                          {id ? 'Edit' : 'Add'} <span> Contact Person</span>
                        </h5>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="dash-content-card">
              <div className="cmmn-form">
                <Form>
                  <Row>
                    <Col md={4}>
                      <Form.Group className="mb-3" controlId="formBasicName">
                        <Form.Label>
                          Name <sup className="text-danger">*</sup>
                        </Form.Label>
                        <Form.Control
                          name="name"
                          type="text"
                          placeholder="Enter name"
                          value={contactPersonDetails?.name}
                          onChange={(e) => {
                            onChangeHandler(e);
                          }}
                        />
                        <div
                          className={`${
                            clicked && validate(contactPersonDetails).name ? '' : 'd-none'
                          } danger-frm `}>
                          {clicked && validate(contactPersonDetails).name}
                        </div>
                      </Form.Group>
                    </Col>
                    <Col md={4}>
                      <Form.Group className="mb-3" controlId="formBasicName">
                        <Form.Label>
                          Designation <sup className="text-danger">*</sup>
                        </Form.Label>
                        <Form.Control
                          name="designation"
                          type="text"
                          placeholder="Enter designation"
                          value={contactPersonDetails?.designation}
                          onChange={(e) => {
                            onChangeHandler(e);
                          }}
                        />
                        <div
                          className={`${
                            clicked && validate(contactPersonDetails).designation ? '' : 'd-none'
                          } danger-frm `}>
                          {clicked && validate(contactPersonDetails).designation}
                        </div>
                      </Form.Group>
                    </Col>

                    <Col md={4}>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>
                          Email <sup className="text-danger">*</sup>
                        </Form.Label>
                        <Form.Control
                          name="email"
                          type="email"
                          value={contactPersonDetails?.email}
                          placeholder="Enter email"
                          onChange={(e) => {
                            onChangeHandler(e);
                          }}
                        />
                        <div
                          className={`${
                            clicked && validate(contactPersonDetails).email ? '' : 'd-none'
                          } danger-frm `}>
                          {clicked && validate(contactPersonDetails).email}
                        </div>
                      </Form.Group>
                    </Col>
                    <Col md={4}>
                      <Form.Group className="mb-3" controlId="formBasicName">
                        <Form.Label>
                          Address <sup className="text-danger">*</sup>
                        </Form.Label>
                        <Form.Control
                          name="address"
                          type="text"
                          placeholder="Enter the Address"
                          value={contactPersonDetails?.address}
                          onChange={(e) => {
                            onChangeHandler(e);
                          }}
                        />
                        <div
                          className={`${
                            clicked && validate(contactPersonDetails).address ? '' : 'd-none'
                          } danger-frm `}>
                          {clicked && validate(contactPersonDetails).address}
                        </div>
                      </Form.Group>
                    </Col>
                    <Col md={4}>
                      <Form.Group className="mb-3" controlId="formBasicName">
                        <Form.Label>
                          Contact <sup className="text-danger">*</sup>
                        </Form.Label>
                        <Form.Control
                          name="contact"
                          type="text"
                          value={contactPersonDetails?.contact}
                          placeholder="Enter contact"
                          onChange={(e) => {
                            onChangeHandler(e);
                          }}
                          onKeyDown={(e) => restrictAlpha(e)}
                        />
                        <div
                          className={`${
                            clicked && validate(contactPersonDetails).contact ? '' : 'd-none'
                          } danger-frm `}>
                          {clicked && validate(contactPersonDetails).contact}
                        </div>
                      </Form.Group>
                    </Col>

                    <Col md={4}>
                      <Form.Group className="mb-3" controlId="formBasicName">
                        <Form.Label>Status </Form.Label>
                        <Form.Select
                          aria-label="Default select example"
                          onChange={(e) => setStatus(e.target.value)}
                          value={status}>
                          <option>Select Status </option>
                          <option value="1">Enable</option>
                          <option value="0">Disable</option>
                        </Form.Select>
                      </Form.Group>
                    </Col>

                    <Col md={4}>
                      <Form.Group className="mb-3" controlId="formBasicName">
                        <Form.Label>
                          Banner Image<sup className="text-danger">*</sup>
                        </Form.Label>
                        <Form.Control
                          // multiple={pickLayout !== 1 ? false : true}
                          type="file"
                          name="slider_banner_img"
                          accept=".png,.jpg,.jpeg"
                          onChange={(e) => {
                            handleFile(e);
                          }}
                        />
                        <div className={'danger-frm'}>{clicked && validate().singlefile}</div>
                      </Form.Group>
                    </Col>

                    <Col xs={2} sm={2} md={2}>
                      <div className=" d-flex">
                        {id && singlefile && !singlefilePreview ? (
                          <img
                            height={'100px'}
                            width={'100px'}
                            src={process.env.REACT_APP_API_DOMAIN + attachment}
                            alt="img"
                          />
                        ) : singlefile ? (
                          <img
                            height={'100px'}
                            width={'100px'}
                            src={singlefilePreview}
                            alt="img"
                            className="me-2"
                          />
                        ) : (
                          ''
                        )}
                      </div>
                    </Col>
                  </Row>
                  <div className="form-btns d-flex align-items-center mt-3">
                    {id ? (
                      <Button
                        className="main-btn-grn"
                        onClick={(e) => {
                          updateHandler(e);
                        }}>
                        Update
                      </Button>
                    ) : (
                      <Button
                        className="main-btn-grn"
                        onClick={(e) => {
                          submitHandler(e);
                        }}>
                        Submit
                      </Button>
                    )}
                    <strong>OR</strong>
                    <Button
                      className="main-btn-red"
                      onClick={() => {
                        navigate(-1);
                      }}>
                      Cancel
                    </Button>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </Container>
      </div>
      {loading ? <Loader /> : ''}
    </>
  );
};

export default AddContactPerson;
