/**
@copyright  :   ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
@author     :   Shiv Charan Panjeta 
 
All Rights Reserved.
Proprietary and confidential :  All information contained here in is, and remains
the property of ToXSL Technologies Pvt. Ltd. and it's partners.
Unauthorized copying of this file, via any medium is strictly prohibited.
*/

import { useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { Col, Container, Form, Row, Table } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import Loader from '../../../components/commoncomponents/loader/loader-large';
import { deleteMenuListAction, dndMenuList, menuListAction } from '../../../redux/action';
import { positionMenu } from '../../../services/AuthServices';
import showNotification from '../../../services/NotificationService';

const MenuList = () => {
  // const
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const menuList = useSelector((state) => state.menuList);
  const [orderBy, setOrderBy] = useState('ASC');
  const [shortOrderBy, setShortOrderBy] = useState('position');
  const [checkedID, setCheckedID] = useState([]);
  const [childVal, setChildVal] = useState('');
  const [page, setPage] = useState(1);
  const [action, setAction] = useState('');

  useEffect(() => {
    handleDispatch();
  }, [page]);

  /**
   * function to checked all menu list
   * @param {*} e
   */
  const handleSelectAll = (e) => {
    if (e.target.checked === true) {
      menuList?.allID.forEach((id) => {
        if (!checkedID.includes(id)) {
          setCheckedID((prev) => [...prev, id]);
        }
      });
    } else {
      setCheckedID([
        ...checkedID.filter((id) => {
          return menuList.allID.indexOf(id) == -1;
        })
      ]);
    }
  };

  /**
   * function to check each list individually
   * @param {*} e
   * @param {*} id
   */
  const handleMultipleCheckBox = (e, id) => {
    if (checkedID.includes(id)) {
      let filterId = checkedID.filter((data) => data !== id);
      setCheckedID(filterId);
    } else {
      setCheckedID([...checkedID, id]);
    }
  };

  /**
   * function to trigger when onChange event fires
   * @param {*} value
   * @param {*} menu
   */
  const handleChange = async (value, menu) => {
    if (value == 'edit') {
      // navigate('/admin/add-menu-list', { state: { menu: menu, child: childVal } });
      if (menu?.state_id == 1) {
        navigate('/admin/add-menu-list', { state: menu });
      } else {
        showNotification('danger', 'You need to enable this menu item');
        setAction('');
      }
      setAction('');
    }
    if (value == 'view') {
      navigate(`/admin/child-menu-list/${menu?.menu_id}`, { state: menu?.menu_id });
    }
    if (value == '0' || value == '1' || value == '2') {
      await dispatch(
        deleteMenuListAction(childVal ? childVal?.split(',')[1] : menu?.menu_id, value)
      );
      // document.getElementById('selectdrop').value = '';
      // an action dispatch after delete, enable or disable
      // preventing an extra API to hit to get list of menu
      dispatch(
        dndMenuList(
          childVal
            ? menuList?.list?.map((item) => {
                return {
                  ...item,
                  chlid: item.chlid.filter((subMenu) => subMenu.menu_id !== childVal?.split(',')[1])
                };
              })
            : menuList?.list?.map((item) => {
                if (item.menu_id == menu?.menu_id) {
                  return {
                    ...item,
                    state_id: item.state_id == '2' ? '1' : '2'
                  };
                }
                return item;
              })
        )
      );
      setAction('');
    }
  };

  /**
   * a switch statement to display state of list
   * @param {*} state
   * @returns
   */
  const menuListState = (state) => {
    switch (state) {
      case '1':
        return 'Enabled';
      case '2':
        return 'Disabled';
      default:
        return;
    }
  };

  /**
   * dispatch action when menu list chnages after sorting
   * @param {*} sort
   */
  const handleDispatch = (sort) => {
    dispatch(menuListAction(sort ? sort : shortOrderBy, orderBy, page));
    setShortOrderBy(sort);
    setOrderBy(orderBy == 'ASC' ? 'DESC' : 'ASC');
  };

  /**
   * a function to control drag and drop
   * @param {*} result
   * @returns
   */
  const handleOnDragEnd = async (result) => {
    if (!result.destination) return;
    const items = Array.from(menuList?.list);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    dispatch(dndMenuList(items));

    try {
      let body = {
        source: result?.source?.index,
        destination: result?.destination?.index
      };
      const response = await positionMenu(body);
      if (response?.status === 200) {
        showNotification('success', response?.data?.message);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <div className="dash-main-content">
        <Container fluid>
          <div className="dash-page-content">
            <div className="dash-page-title">
              <Row>
                <Col xs={12} sm={12} md={12}>
                  <div className="dash-title-bar d-flex align-items-center justify-content-between">
                    <div className="title-lft d-flex align-items-center">
                      <div className="dash-icn">
                        <i className="fas fa-bars"></i>
                      </div>
                      <div className="dash-title-dis">
                        <h5>
                          Menu <span>Listing</span>
                        </h5>
                        <h5>{/* Total User(s): <span>{response}</span> */}</h5>
                      </div>
                    </div>
                    <div className="title-rt-btn d-flex align-items-center">
                      {/* <Link className="green_button_admin mx-2" to="/admin/add-open-page-content">
                        Add Content
                      </Link> */}
                      <Link className="green_button_admin mx-2" to="/admin/add-menu-list">
                        Add Menu
                      </Link>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="dash-content-card">
              <div className="table-responsive">
                <Table className="custom-table-data" striped bordered hover>
                  <thead>
                    <tr>
                      <th>
                        <Form.Check
                          type="checkbox"
                          id="checkbox"
                          name="checkall"
                          checked={
                            menuList?.allID?.every((elem) => {
                              return checkedID.includes(elem);
                            }) && menuList?.list?.length > 0
                              ? true
                              : false
                          }
                          onChange={(e) => handleSelectAll(e)}
                          label=""
                        />
                      </th>
                      <th>
                        <Link onClick={(e) => handleDispatch('menu_id')}>
                          Id
                          <img src={require('../../../assets/images/sort.png')} alt="sort" />
                        </Link>
                      </th>
                      <th>
                        <Link onClick={(e) => handleDispatch('name')}>
                          Name
                          <img src={require('../../../assets/images/sort.png')} alt="sort" />
                        </Link>
                      </th>
                      {/* <th>
                        <Link>
                          Child menu
                          <img src={require('../../../assets/images/sort.png')} alt="sort" />
                        </Link>
                      </th> */}
                      <th>
                        <Link onClick={(e) => handleDispatch('slug')}>
                          Slug
                          <img src={require('../../../assets/images/sort.png')} alt="sort" />
                        </Link>
                      </th>
                      <th>State</th>
                      <th>Created At</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <DragDropContext onDragEnd={handleOnDragEnd}>
                    <Droppable droppableId="menu_list">
                      {(provided) => {
                        return (
                          <tbody
                            className="characters"
                            ref={provided.innerRef}
                            {...provided.droppableProps}>
                            {menuList?.list?.length > 0 ? (
                              menuList?.list?.map((menu, index) => {
                                return (
                                  <Draggable
                                    key={menu?.menu_id}
                                    draggableId={menu?.menu_id}
                                    index={index}>
                                    {(provided) => (
                                      <tr
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        key={index}>
                                        <td>
                                          <Form.Check
                                            type="checkbox"
                                            name="id"
                                            checked={checkedID?.includes(menu?.menu_id)}
                                            onChange={(e) =>
                                              handleMultipleCheckBox(e, menu?.menu_id)
                                            }
                                            label=""
                                          />
                                        </td>
                                        <td>{menu?.menu_id}</td>
                                        <td style={{ textTransform: 'capitalize' }}>
                                          {menu?.name}
                                        </td>
                                        {/* <td>
                                          {menu?.chlid?.length > 0 && (
                                            <Form.Select
                                              onChange={(e) => {
                                                setChildVal(e.target.value);
                                              }}
                                              value={childVal}
                                              aria-label="Default select example"
                                              name="status">
                                              <option value={''}>Select</option>;
                                              {menu?.chlid?.map((data) => {
                                                return (
                                                  <option value={[data?.name, data?.menu_id]}>
                                                    {data?.name}
                                                  </option>
                                                );
                                              })}
                                            </Form.Select>
                                          )}
                                        </td> */}
                                        <td>{menu?.slug}</td>
                                        <td>{menuListState(menu?.state_id)}</td>
                                        <td>{menu?.created_at}</td>
                                        <td>
                                          <Form.Select
                                            onChange={(e) => {
                                              handleChange(e.target.value, menu);
                                              setChildVal(e.target.value);
                                              setAction(e.target.value);
                                            }}
                                            value={action}
                                            aria-label="Default select example"
                                            name="status">
                                            <option value="">Select</option>
                                            <option value={'view'}>View</option>
                                            <option value={'edit'}>Edit</option>
                                            {/* {childVal ? (
                                              <option value={'0'}>Delete</option>
                                            ) : */}
                                            {menu?.state_id == '1' ? (
                                              <option value={'2'}>Disable</option>
                                            ) : (
                                              <option value={'1'}>Enable</option>
                                            )}
                                          </Form.Select>
                                        </td>
                                      </tr>
                                    )}
                                  </Draggable>
                                );
                              })
                            ) : (
                              <tr>
                                <td className="text-center" colSpan={10}>
                                  Data not found
                                </td>
                              </tr>
                            )}
                            {provided.placeholder}
                          </tbody>
                        );
                      }}
                    </Droppable>
                  </DragDropContext>
                </Table>
              </div>
              {/* {Math.ceil(menuList?.totalCount / 10) > 1 && (
                <div className="react-page d-flex justify-content-center">
                  <ReactPaginate
                    previousLabel={'Prev'}
                    nextLabel={'Next'}
                    activeClassName={'page-link active'}
                    onPageChange={(props) => {
                      setPage(props.selected + 1);
                    }}
                    pageCount={Math.ceil(menuList?.totalCount / 10)}
                  />
                </div>
              )} */}
            </div>
          </div>
        </Container>
      </div>

      {menuList?.isPending && <Loader />}
    </>
  );
};

export default MenuList;
