/**
 * @copyright : ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
@author    : Shiv Charan Panjeta 
 
All Rights Reserved.
Proprietary and confidential :  All information contained here in is, and remains
the property of ToXSL Technologies Pvt. Ltd. and it's partners.
Unauthorized copying of this file, via any medium is strictly prohibited.
 */

import moment from 'moment';
import { useEffect, useState } from 'react';
import { Button, Card, Col, Container, Row } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import RightIcon from '../../../assets/images/right_arrow_symbol.png';
import RightIconWhite from '../../../assets/images/right_arrow_white.png';
import { truncate } from '../../../globals/helper';
import {
  checkDomain,
  getAluminiBackground,
  getBlogDetail,
  getHomepageLayout,
  getInsEventList,
  getInsNews,
  getJobList,
  getPageDetail,
  getTestimonial,
  quickLinks
} from '../../../services/InstituteServices';
import instituteBanner from '../../../assets/images/institute-landing-banner.png';
import { ABOUT } from '../../../globals/constants';

function InstituteLanding() {
  const [state, setState] = useState();
  const [quickLinksList, setQuickLinksList] = useState([]);
  const [list, setList] = useState([]);
  const [blogList, setBlogList] = useState([]);
  const [newsList, setNewsList] = useState([]);
  const [eventList, setEventList] = useState([]);
  const [testimonialList, setTestimonialList] = useState([]);
  const [layoutDetail, setLayoutDetail] = useState('');
  const [about, setAbout] = useState('');
  const [aluminiImg, setAluminiImg] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    checkApi();
    getInfo();
    getAluminiImages();
  }, []);

  const checkApi = () => {
    checkDomain().then((res) => {
      if (res?.status === 200) {
        setState(res?.data?.details);
      }
    });

    getHomepageLayout().then((res) => {
      if (res?.status == 200) {
        setLayoutDetail(res?.data?.data);
      }
    });
  };

  // to get informations
  const getInfo = () => {
    getPageDetail(ABOUT).then((res) => {
      if (res?.status == 200) {
        let data = res?.data?.details ? res?.data?.details[0] : [];
        setAbout(data);
      }
    });

    getJobList().then((res) => {
      if (res?.status == 200) {
        setList(res?.data?.data?.list || []);
      }
    });

    getBlogDetail().then((res) => {
      if (res?.status == 200) {
        setBlogList(res?.data?.data?.details);
      }
    });

    getInsNews().then((res) => {
      if (res?.status == 200) {
        setNewsList(res?.data?.data);
      }
    });

    getInsEventList().then((res) => {
      if (res?.status == 200) {
        setEventList(res?.data?.data?.list);
      }
    });

    getTestimonial().then((res) => {
      if (res?.status == 200) {
        setTestimonialList(res?.data?.data);
      }
    });

    quickLinks().then((res) => {
      if (res?.status == 200) {
        setQuickLinksList(res?.data?.data);
      }
    });
  };

  /**
   * get alumini images for background coverImg
   */
  const getAluminiImages = () => {
    getAluminiBackground().then((res) => {
      if (res?.status == 200) {
        let arr = [];
        let data = res?.data?.data;
        if (res?.data?.data?.length) {
          data?.map((data) => {
            arr.push(process.env.REACT_APP_API_DOMAIN + data?.url + '.' + data?.extension);
          });
          setAluminiImg(arr);
        }
      }
    });
  };

  return (
    <>
      <Container>
        <section className="institue-banner ">
          <img
            width={'100%'}
            className="bannerimgnew"
            src={
              layoutDetail?.slider_banner_img
                ? process.env.REACT_APP_API_URL + layoutDetail?.slider_banner_img
                : instituteBanner
            }
            alt=""
          />
          <Row>
            <Col md={8} lg={6} sm={12}>
              <div className="banner-cont">
                <h3 className="banner-title">
                  {layoutDetail?.title || 'PERFECT PLACE TO CONNECT WITH BATCHMATES!'}
                </h3>
                {layoutDetail?.sub_title ? (
                  <span dangerouslySetInnerHTML={{ __html: layoutDetail?.sub_title }} />
                ) : (
                  'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.'
                )}
              </div>
            </Col>
          </Row>
        </section>
      </Container>

      {about && about?.description && (
        <section className="institution_welcome">
          <Container>
            <Row>
              <Col lg={7}>
                <div className="institution_welcome_content pt-3">
                  <h5>Welcome to</h5>
                  <h3>{state?.name}</h3>
                  <span dangerouslySetInnerHTML={{ __html: truncate(about?.description, 600) }} />
                  <Button
                    onClick={() => {
                      navigate('/about-institute');
                    }}
                    className="btn p-2">
                    Explore
                  </Button>
                </div>
              </Col>
              <Col lg={5}>
                {/* {about?.multiple_image} */}
                {about?.multiple_image && about?.multiple_image?.length ? (
                  <>
                    {' '}
                    <div className="d-flex justify-content-between">
                      {about?.multiple_image?.slice(0, 2)?.map((data, index) => (
                        <div key={index} className="p-2">
                          <img
                            src={process.env.REACT_APP_API_URL + data?.url}
                            width="100%"
                            className=""
                            alt="img"
                          />
                        </div>
                      ))}
                    </div>
                    <div className="d-flex justify-content-between">
                      {about?.multiple_image?.slice(2, 4)?.map((data, index) => (
                        <div key={index} className="p-2">
                          <img
                            src={process.env.REACT_APP_API_URL + data?.url}
                            width="100%"
                            className=""
                            alt="img"
                          />
                        </div>
                      ))}
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </Col>
            </Row>
          </Container>
        </section>
      )}

      <section className="alumni-sec-card py-5">
        <Container>
          <div className="alumni-sec-inner">
            <div className="see_all-box">
              <h4>Alumni</h4>
              <Link to="/alumni">
                See All <img src={RightIconWhite} alt="logo" className="ms-3" />
              </Link>
            </div>
            <ul className="alumni-list">
              {aluminiImg && aluminiImg?.length ? (
                aluminiImg?.slice(0, 16)?.map((data, index) => (
                  <li key={index}>
                    <img src={data} alt="img" />
                  </li>
                ))
              ) : (
                <>
                  <li>
                    <img
                      src={require('../../../assets/images/institute-img/alumni-1.png')}
                      alt="img"
                    />
                  </li>
                  <li>
                    <img
                      src={require('../../../assets/images/institute-img/alumni-2.png')}
                      alt="img"
                    />
                  </li>
                  <li>
                    <img
                      src={require('../../../assets/images/institute-img/alumni-3.png')}
                      alt="img"
                    />
                  </li>
                  <li>
                    <img
                      src={require('../../../assets/images/institute-img/alumni-4.png')}
                      alt="img"
                    />
                  </li>
                  <li>
                    <img
                      src={require('../../../assets/images/institute-img/alumni-5.png')}
                      alt="img"
                    />
                  </li>
                  <li>
                    <img
                      src={require('../../../assets/images/institute-img/alumni-6.png')}
                      alt="img"
                    />
                  </li>
                  <li>
                    <img
                      src={require('../../../assets/images/institute-img/alumni-7.png')}
                      alt="img"
                    />
                  </li>
                  <li>
                    <img
                      src={require('../../../assets/images/institute-img/alumni-8.png')}
                      alt="img"
                    />
                  </li>
                  <li>
                    <img
                      src={require('../../../assets/images/institute-img/alumni-9.png')}
                      alt="img"
                    />
                  </li>

                  <li>
                    <img
                      src={require('../../../assets/images/institute-img/alumni-10.png')}
                      alt="img"
                    />
                  </li>
                  <li>
                    <img
                      src={require('../../../assets/images/institute-img/alumni-11.png')}
                      alt="img"
                    />
                  </li>
                  <li>
                    <img
                      src={require('../../../assets/images/institute-img/alumni-12.png')}
                      alt="img"
                    />
                  </li>
                  <li>
                    <img
                      src={require('../../../assets/images/institute-img/alumni-13.png')}
                      alt="img"
                    />
                  </li>
                  <li>
                    <img
                      src={require('../../../assets/images/institute-img/alumni-1.png')}
                      alt="img"
                    />
                  </li>
                  <li>
                    <img
                      src={require('../../../assets/images/institute-img/alumni-2.png')}
                      alt="img"
                    />
                  </li>
                  <li>
                    <img
                      src={require('../../../assets/images/institute-img/alumni-3.png')}
                      alt="img"
                    />
                  </li>
                </>
              )}
            </ul>
          </div>
        </Container>
      </section>

      <section className="news-update-section position-relative">
        <Container fluid>
          <Row>
            <Col lg={5} md={6} sm={12} className="offset-lg-2 offset-md-0 ps-lg-5">
              <div className="news ps-lg-2">
                <div className="d-flex align-items-center justify-content-between mb-3">
                  <h3 className="main-title">News & Updates</h3>
                  <Link to="/news">
                    See All <img src={RightIcon} alt="logo" className="ms-3" />
                  </Link>
                </div>
                {newsList?.list && newsList?.list?.length ? (
                  newsList?.list?.slice(0, 5)?.map((data, index) => (
                    <Card
                      key={index}
                      className="mt-4 pt-2 pointer"
                      role="presentation"
                      onClick={() => {
                        navigate(`/news-detail-page/${data?.slug}`);
                      }}>
                      <Card.Body>
                        <h3 className="subtitle">
                          {moment(data?.date_modified).format('MMM DD,YYYY')}
                        </h3>
                        <h4>{data?.title}</h4>
                      </Card.Body>
                    </Card>
                  ))
                ) : (
                  <Card className="mt-4 pt-2">
                    <Card.Body>
                      <h4>'No Data Found at this moment'</h4>
                    </Card.Body>
                  </Card>
                )}
              </div>
            </Col>
            <Col lg={5} md={6} sm={12} className="pe-lg-0">
              <div className="position-relative quick-link-box">
                <div className="quick-links">
                  <div className="d-flex align-items-center justify-content-between mb-3">
                    <h3>Quick Links</h3>
                    {/* <Link>
                      See All <img src={RightIcon} alt="logo" className="ms-3" />
                    </Link> */}
                  </div>
                  <ul className="quick-link-list">
                    {quickLinksList?.length > 0 &&
                      quickLinksList?.map((list, index) => {
                        return (
                          <li key={index}>
                            <Link to={list?.slug?.includes('home') ? list?.slug : '/' + list?.slug}>
                              <i className="fas fa-chevron-right me-3"></i>
                              {list?.name}
                            </Link>
                          </li>
                        );
                      })}
                  </ul>
                </div>
                <div className="quick-link-bottom">
                  <div className="d-flex align-items-center">
                    <Link to="#">
                      <img
                        src={
                          state?.logo
                            ? process.env.REACT_APP_API_DOMAIN +
                              'common/uploads/institute_logo/' +
                              state?.logo
                            : require('../../../assets/images/institute_logo.png')
                        }
                        alt="logo"
                        width="100px"
                      />
                    </Link>
                    <h3 className="institue-title">{state?.name}</h3>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="blog-section">
        <Container>
          <div className="d-flex align-items-center justify-content-between mb-3">
            <h2>Our Blogs</h2>
            <Link to={'/blog'}>
              See All <img src={RightIcon} alt="logo" className="ms-3" />
            </Link>
          </div>
          <Row>
            {blogList && blogList?.length ? (
              blogList?.slice(0, 4)?.map((data, index) => (
                <Col sm={12} md={4} lg={3} key={index}>
                  <Card className="mb-3 mb-lg-0">
                    {/* <img src={require('../../../assets/images/institute-blog-img.png')} alt="img" /> */}
                    <img
                      src={
                        data?.image
                          ? process.env.REACT_APP_API_DOMAIN + 'common/uploads/blog/' + data?.image
                          : require('../../../assets/images/institute-blog-img.png')
                      }
                      alt="img"
                    />
                    <Card.Body>
                      <h3 className="subtitle">
                        {data?.created_on ? moment(data?.created_on).format('MMM DD,YYYY') : ''}
                      </h3>
                      <h4 className="card-title">{data?.title}</h4>
                      {/* <p className="card-text">{}</p> */}
                      <span
                        className="card-text"
                        dangerouslySetInnerHTML={{ __html: truncate(data?.description, 100) }}
                      />
                      <Link to={`/blog-details/${data?.slug}`} className="btn explore mt-0">
                        Explore
                      </Link>
                    </Card.Body>
                  </Card>
                </Col>
              ))
            ) : (
              <Col sm={12} md={4} lg={3}>
                <div className="text-center nodata text-white">
                  <img src={require('../../../assets/images/nodata.png')} alt="img" />
                  <p>Data Not Found</p>
                </div>
              </Col>
            )}
          </Row>
        </Container>
      </section>

      <section className="events-section pb-5 mb-4">
        <Container>
          <Row>
            <Col sm={12} md={6} lg={6}>
              <div className="events-box">
                <div className="d-flex align-items-center justify-content-between mb-3">
                  <h2>Events</h2>
                  <Link to="/event-institute">
                    See All <img src={RightIconWhite} alt="logo" className="ms-3" />
                  </Link>
                </div>
                <ul className="events-list">
                  {eventList && eventList?.length ? (
                    eventList?.slice(0, 5)?.map((data, index) => (
                      <li key={index}>
                        <div
                          className="d-flex align-items-center mb-4 pointer"
                          role="presentation"
                          onClick={() => {
                            navigate(`/event-detail-institute/${data?.slug}`);
                          }}>
                          <div className="event-date-box">
                            <h4 className="month">{moment(data?.from_event_date).format('MMM')}</h4>
                            <p className="date">{moment(data?.from_event_date).format('DD')}</p>
                          </div>
                          <div className="event-name-box">
                            <h3>{data?.name}</h3>
                            <p>{data?.venue}</p>
                          </div>
                        </div>
                      </li>
                    ))
                  ) : (
                    <li>Data Not Found</li>
                  )}
                </ul>
              </div>
            </Col>
            <Col sm={12} md={6} lg={6}>
              <div className="job-board-box">
                <div className="d-flex align-items-center justify-content-between mb-3">
                  <h2>Job Board</h2>
                  {list?.length > 0 && (
                    <Link to="/jobs-board">
                      See All <img src={RightIcon} alt="logo" className="ms-3" />
                    </Link>
                  )}
                </div>
                {list && list?.length ? (
                  list?.slice(0, 3)?.map((data, index) => (
                    <Card key={index}>
                      <Card.Body>
                        <div className="d-flex align-items-center justify-content-between mb-3">
                          <div className="job-detail-box">
                            <h3>{data?.job_title}</h3>
                            <p>{data?.company_name || data?.companyName}</p>
                          </div>
                          {data?.company_image ? (
                            <div className="job-logo-box">
                              <img
                                src={
                                  data?.company_image
                                    ? process.env.REACT_APP_API_DOMAIN +
                                      'common/uploads/institute_logo/' +
                                      data?.company_image
                                    : require('../../../assets/images/intitute-jobboard-img.png')
                                }
                                alt="img"
                              />
                            </div>
                          ) : (
                            <></>
                          )}
                        </div>
                        <ul className="job-list">
                          <li>
                            {/* <img src={BreifcaseIcon} alt="logo" /> */}
                            <p>{data?.min_exp + '-' + data?.max_exp} years</p>
                          </li>
                          {data?.city != 0 ? (
                            <li>
                              {/* <img src={LocationIcon} alt="logo" /> */}
                              <p>{data?.city}</p>
                            </li>
                          ) : (
                            ''
                          )}
                          <li>
                            {/* <img src={RupeeIcon} alt="logo" /> */}
                            <p>Not Disclosed</p>
                          </li>
                        </ul>
                        {data?.applicaiton_count && (
                          <p className="posted-time">
                            Posted: {data?.date_time} Job Applicants: {data?.applicaiton_count}
                          </p>
                        )}
                      </Card.Body>
                    </Card>
                  ))
                ) : (
                  <div className="text-center nodata">
                    <img src={require('../../../assets/images/nodata.png')} alt="img" />
                    <p>Data Not Found</p>
                  </div>
                )}
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="testimonial-section">
        <Container>
          <Row>
            <Col sm={12} md={12} lg={6}>
              <h4 className="testimonials-title">Few Words from our Alumni</h4>
              <p>
                Alumni Association has been an exceptional effort by the Sri Guru Harkrishan Senior
                Secondary Public School to provide a lifelong link for each one of us to stay
                connected. Through various activities and events, it commits to providing an
                out-of-the-world experience for everyone.
              </p>

              <div className="inst-testi-slider">
                <Swiper
                  spaceBetween={50}
                  slidesPerView={1}
                  modules={[Navigation]}
                  navigation={true}>
                  {testimonialList && testimonialList?.length ? (
                    testimonialList?.map((data, index) => (
                      <SwiperSlide key={index}>
                        <div className="testimonial-cont-box">
                          <div className="mb-4">
                            {/*  <img src={require('../../../assets/images/testimonial-slider-img1.png')} alt="img" /> */}
                            <img
                              src={
                                data?.image_file
                                  ? process.env.REACT_APP_API_URL +
                                    'common/uploads/institute_banner/' +
                                    data?.image_file
                                  : require('../../../assets/images/testimonial-slider-img1.png')
                              }
                              alt="img"
                            />
                            <h2 className="client-name">{data?.name}</h2>
                            <p className="client-ocupation">{data?.designation}</p>
                          </div>
                          <p className="client-feedback mb-4">{data?.description}</p>
                        </div>
                      </SwiperSlide>
                    ))
                  ) : (
                    <>No Data Found</>
                  )}
                </Swiper>
              </div>
            </Col>
            <Col sm={12} md={12} lg={6} className="px-0">
              <div className="testimonial-img">
                <div className="cont-box">
                  <h3>Gallery</h3>
                  <Link to="/gallery" className="btn see-all mt-0">
                    See All <img src={RightIcon} alt="logo" className="ms-3" />
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}

export default InstituteLanding;
