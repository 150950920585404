/**
@copyright  :   ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
@author     :   Shiv Charan Panjeta 
 
All Rights Reserved.
Proprietary and confidential :  All information contained here in is, and remains
the property of ToXSL Technologies Pvt. Ltd. and it's partners.
Unauthorized copying of this file, via any medium is strictly prohibited.
*/

import { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { Link } from 'react-router-dom';

import { Button } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';

import moment from 'moment/moment';
import { useNavigate } from 'react-router';
import { imageExtension } from '../../../globals/helper';
import * as adminServices from '../../../services/AuthServices';
import showNotification from '../../../services/NotificationService';
export default function AddActivity() {
  const navigate = useNavigate();
  const [isEndDate, setIsEndDate] = useState(false);
  const [clicked, setClicked] = useState(false);

  const id = new URLSearchParams(location?.search).get('id');

  const event_id = new URLSearchParams(location?.search).get('event_id');

  const [details, setDetails] = useState({
    event_id: event_id,
    id: '',
    title: '',
    description: '',
    from_event_date: '',
    to_event_date: '',
    image: ''
  });

  useEffect(() => {
    if (id) {
      getDetail(id);
    }
    setDetails({ event_id: event_id, ...details });
  }, [id]);

  const validate = () => {
    let error = {};
    if (details?.title == '') {
      error.title = 'This field is required.';
    }
    if (details?.description == '') {
      error.description = ' This field is required';
    }
    if (details?.from_event_date == '') {
      error.from_event_date = ' This field is required';
    }
    return error;
  };

  const getDetail = () => {
    adminServices.getViewManageActivity(event_id, id).then((res) => {
      if (res?.status === 200) {
        const data = res?.data?.data?.detail;

        setDetails({
          event_id: event_id,
          id: id,
          title: data?.title,
          description: data?.description,
          from_event_date: data?.from_event_date,
          to_event_date: data?.to_event_date
        });
        if (data?.to_event_date !== '') {
          setIsEndDate(true);
        }
      }
    });
  };

  const saveHandler = (e) => {
    e.preventDefault();
    setClicked(true);
    if (!Object.keys(validate()).length) {
      setClicked(false);
      let formData = new FormData();
      for (let i in details) {
        formData.append(`${i}`, details[i]);
      }
      adminServices.addManageActivities(formData).then((res) => {
        if (res?.status === 200) {
          showNotification('success', res?.data?.detail?.message);
          navigate(`/admin/event/ManageActivity?event_id=${event_id}`);
        }
      });
    }
  };

  const updateEvent = () => {
    setClicked(true);
    if (!Object.keys(validate()).length) {
      setClicked(false);
      let formData = new FormData();
      for (let i in details) {
        formData.append(`${i}`, details[i]);
      }
      adminServices.addManageActivities(formData).then((res) => {
        if (res?.status === 200) {
          showNotification('success', res?.data?.detail?.message);
          navigate(`/admin/event/ManageActivity?event_id=${event_id}`);
        }
      });
    }
  };

  const handleChange = (e) => {
    if (e.target.type == 'file') {
      let file = e.target.files[0];
      let type = e.target.files[0].type;
      if (imageExtension.includes(type)) {
        setDetails({
          ...details,
          [e.target.name]: file
        });
      } else {
        showNotification('danger', 'file format not supported !');
      }
    } else {
      const { name, value } = e.target;
      setDetails({
        ...details,
        [name]: value
      });
    }
  };

  return (
    <div className="dash-main-content">
      <Container fluid>
        <div className="dash-page-content">
          <div className="dash-page-title">
            <Row>
              <Col xs={12} sm={12} md={12}>
                <div className="dash-title-bar d-flex align-items-center justify-content-between">
                  <div className="title-lft d-flex align-items-center">
                    <div className="dash-icn">
                      <i className="fas fa-newspaper"></i>
                    </div>
                    <div className="dash-title-dis">
                      <h5>Add Event Activity</h5>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <div className="dash-content-card">
            <div className="cmmn-form">
              <Form>
                <Row>
                  <Col md={12}>
                    <Form.Group className="mb-3" controlId="formBasicName">
                      <Form.Label>
                        Title <span className="text-danger">*</span>{' '}
                      </Form.Label>
                      <Form.Control
                        type="text"
                        value={details?.title}
                        placeholder="Enter Activity title"
                        name="title"
                        onChange={handleChange}
                      />
                      <div className={'danger-frm'}>{clicked && validate().title}</div>
                    </Form.Group>
                  </Col>

                  <Col md={12}>
                    <Form.Group className="mb-3" controlId="formBasicDesi">
                      <Form.Label>
                        Description <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        as="textarea"
                        placeholder="Enter Description"
                        rows={3}
                        name="description"
                        value={details?.description}
                        onChange={handleChange}
                      />
                      <div className={'danger-frm'}>{clicked && validate().description}</div>
                    </Form.Group>
                  </Col>

                  <Col md={4}>
                    <Form.Group className="mb-3" controlId="formBasicName">
                      <Form.Label>
                        Date/Time Of Event <span className="text-danger">*</span>{' '}
                      </Form.Label>
                      <Form.Control
                        type="date"
                        placeholder="Select Start Date-Time"
                        name="from_event_date"
                        onChange={handleChange}
                        min={moment().format('YYYY-MM-DD')}
                        value={moment(details?.from_event_date).format('YYYY-MM-DD')}
                      />
                      <div className={'danger-frm'}>{clicked && validate().from_event_date}</div>
                    </Form.Group>
                  </Col>

                  <Col md={4}>
                    {isEndDate ? (
                      <Form.Group className="mb-3" controlId="formBasicName">
                        <Form.Label>End Date Time</Form.Label>
                        <Form.Control
                          type="date"
                          placeholder="Select End Date-Time"
                          name="to_event_date"
                          onChange={handleChange}
                          min={moment(details.from_event_date).format('YYYY-MM-DD')}
                          value={moment(details?.to_event_date).format('YYYY-MM-DD')}
                        />
                      </Form.Group>
                    ) : (
                      <></>
                    )}
                  </Col>

                  <Col md={4}>
                    <Link
                      className="end-date-card mt-5"
                      to="#"
                      onClick={(e) => {
                        e.preventDefault();
                        setIsEndDate(!isEndDate);
                      }}>
                      + End Date-Time
                    </Link>
                  </Col>

                  <Col md={4}>
                    <Form.Group className="mb-3" controlId="formBasicName">
                      <Form.Label>Event Image</Form.Label>
                      <Form.Control
                        type="file"
                        placeholder=""
                        onChange={handleChange}
                        accept="image/*"
                        name="image"
                      />
                    </Form.Group>
                  </Col>

                  <Col md={4}>
                    {id && details?.image ? (
                      <div className="d-flex flex-wrap align-item-center">
                        <div className="border me-2">
                          <img
                            width="100px"
                            height="100px"
                            src={
                              process.env.REACT_APP_API_DOMAIN +
                              'common/uploads/event/' +
                              details?.image
                            }
                            alt="img1"
                          />
                        </div>
                      </div>
                    ) : (
                      ''
                    )}
                  </Col>
                </Row>
                <div className="form-btns d-flex align-items-center mt-3">
                  {!id ? (
                    <Button className="main-btn-grn" onClick={saveHandler}>
                      Save
                    </Button>
                  ) : (
                    <Button className="main-btn-grn" onClick={updateEvent}>
                      Update
                    </Button>
                  )}
                  <strong>OR</strong>
                  <Button
                    className="main-btn-red"
                    onClick={() => {
                      navigate(-1);
                    }}>
                    Cancel
                  </Button>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}
