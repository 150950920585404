/**
@copyright  :   ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
@author     :   Shiv Charan Panjeta 
 
All Rights Reserved.
Proprietary and confidential :  All information contained here in is, and remains
the property of ToXSL Technologies Pvt. Ltd. and it's partners.
Unauthorized copying of this file, via any medium is strictly prohibited.
*/

import { useEffect, useState } from 'react';
import { Button, Container } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Table from 'react-bootstrap/Table';
import ReactPaginate from 'react-paginate';
import { Link, useNavigate } from 'react-router-dom';
import NoDataFound from '../../components/commoncomponents/NoDataFound';
import Loader from '../../components/commoncomponents/loader/loader-large';
import useDocumentTitle from '../../globals/useDocumentTitle';
import * as ADMINSERVICES from '../../services/AuthServices';

export default function News() {
  useDocumentTitle('Manage News | AlmaBay');
  useEffect(() => {
    apiCall();
  }, []);

  const navigate = useNavigate();
  const [sortType, setSortType] = useState('DESC');
  const [sortField, setSortField] = useState('id');
  const [currentPage, setCurrentPage] = useState(1);
  const [record, setRecord] = useState(0);
  const [loading, setLoading] = useState(false);

  const [newsDetail, setNewsDetail] = useState([]);
  const [detail, setDetail] = useState({
    id: '',
    title: '',
    status: ''
  });

  const apiCall = (type = 'news', page_number = '1', sort_field = 'id', order = 'DESC') => {
    setLoading(true);
    let pageNo = page_number ? page_number : 1;
    setCurrentPage(pageNo);

    ADMINSERVICES.getOtherWhatNews(type, page_number, sort_field, order).then((resp) => {
      setLoading(false);
      if (resp?.status === 200) {
        setNewsDetail(resp?.data?.data?.viewlist);
        setRecord(resp?.data?.data?.totalRecords);
      }
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setDetail({ ...detail, [name]: value.trim() });
  };

  const sortHandler = (e, type) => {
    e.preventDefault();
    let currentSort = sortType === 'ASC' ? 'DESC' : 'ASC';
    setSortType(currentSort);
    setSortField(type);
    apiCall('news', currentPage, sortField, sortType);
  };

  const handleSearch = () => {
    setLoading(true);
    setCurrentPage(1);
    ADMINSERVICES.getOtherWhatNews(
      'news',
      1,
      'id',
      '',
      detail.id,
      detail.title,
      detail.status
    ).then((resp) => {
      if (resp?.status === 200) {
        setLoading(false);
        const data = resp?.data?.data?.viewlist;
        setRecord(resp?.data?.data?.totalRecords);
        setNewsDetail(data);
      }
    });
  };

  //paginate

  const handlePageClick = (a) => {
    let currentPage = a?.selected + 1;
    apiCall('news', currentPage);
  };

  const selectHandler = (value, id) => {
    switch (value) {
      case 'view':
        navigate(`/admin/news/index/view_news?id=${id}`);
        break;
      case 'edit':
        navigate(`/admin/news/index/add-news?id=${id}`);
        break;
      default:
        break;
    }
  };

  const resetHandler = (e) => {
    e.preventDefault();
    setDetail({
      id: '',
      title: '',
      status: ''
    });
    apiCall('news', 1, '', '', '');
  };

  return (
    <>
      <div className="dash-main-content">
        <Container fluid>
          <div className="dash-page-content">
            <div className="dash-page-title">
              <Row>
                <Col xs={12} sm={12} md={12}>
                  <div className="dash-title-bar d-flex align-items-center justify-content-between">
                    <div className="title-lft d-flex align-items-center">
                      <div className="dash-icn">
                        <i className="fas fa-envelope"></i>
                      </div>
                      <div className="dash-title-dis">
                        <h5>
                          News <span>Listing</span>
                        </h5>
                        <h5>
                          Total Record(s): <span> {record ? record : ''} </span>
                        </h5>
                      </div>
                    </div>
                    <div className="title-rt-btn">
                      <Link className="add-btn-grn" to="/admin/news/index/add-news">
                        Add News
                      </Link>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="dash-content-card">
              <div className="table-responsive">
                <Table className="custom-table-data" striped bordered hover>
                  <thead>
                    <tr>
                      <th>
                        <Link
                          to=""
                          onClick={(e) => {
                            sortHandler(e, 'id');
                          }}>
                          Id
                          <img src={require('../../assets/images/sort.png')} alt="sort" />
                        </Link>
                      </th>
                      <th>
                        <Link
                          to=""
                          onClick={(e) => {
                            sortHandler(e, 'title');
                          }}>
                          title
                          <img src={require('../../assets/images/sort.png')} alt="sort" />
                        </Link>
                      </th>
                      <th>Image</th>
                      <th>Status</th>

                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="search-table">
                      <td>
                        <Form.Control
                          type="text"
                          placeholder="Id"
                          name="id"
                          onChange={(e) => handleChange(e)}
                          value={detail.id}
                        />
                      </td>
                      <td>
                        <Form.Control
                          type="text"
                          placeholder="Title"
                          name="title"
                          onChange={(e) => handleChange(e)}
                          value={detail.title}
                        />
                      </td>
                      <td></td>
                      <td>
                        <Form.Select
                          aria-label="Default select example"
                          value={detail?.status}
                          name="status"
                          onChange={(e) => handleChange(e)}>
                          <option value=""> - Select Status - </option>
                          <option value="0">Enable</option>
                          <option value="1">Disable</option>
                          <option value="2">Delete</option>
                        </Form.Select>
                      </td>
                      <td className="serac-btns text-center">
                        <Button
                          className="main-btn-grn"
                          onClick={(e) => {
                            handleSearch(e);
                          }}>
                          Search
                        </Button>
                        <Button
                          className="main-btn-red ms-2"
                          onClick={(e) => {
                            resetHandler(e);
                          }}>
                          Reset
                        </Button>
                      </td>
                    </tr>
                    {newsDetail && newsDetail?.length ? (
                      newsDetail?.map((data, index) => (
                        <tr key={index}>
                          <td>{data?.id || 'NA'}</td>
                          <td>{data?.title || 'NA'}</td>
                          <td>
                            <img
                              src={
                                process.env.REACT_APP_API_DOMAIN +
                                'common/uploads/whatsnew/' +
                                data?.image
                              }
                              alt="img"
                              width="180"
                              height="125"
                            />
                          </td>
                          <td>{data?.status && data?.status == 1 ? 'Disable' : 'Enable'} </td>
                          <td>
                            <Form.Select
                              aria-label="Default select example"
                              onChange={(e) => {
                                selectHandler(e.target.value, data?.id);
                              }}>
                              <option>Action</option>
                              <option value="edit">Edit</option>
                              <option value="view">View</option>
                            </Form.Select>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr className="text-center">
                        <td colSpan={9}>
                          <NoDataFound />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
                {Math.ceil(record / 10) > 1 ? (
                  <div className="react-page d-flex justify-content-center">
                    <ReactPaginate
                      previousLabel={currentPage > 0 ? '<' : ''}
                      nextLabel={record > 0 ? '>' : ''}
                      onPageChange={handlePageClick}
                      activeClassName={'page-link active'}
                      pageCount={Math.ceil(record / 10)}
                      forcePage={parseInt(currentPage) - 1}
                    />
                  </div>
                ) : (
                  ''
                )}
              </div>
            </div>
          </div>
        </Container>
      </div>
      {loading ? <Loader /> : ''}
    </>
  );
}
