import { Link } from 'react-router-dom';
import HeaderLogin from '../../../commoncomponents/HeaderLogin';
import TopTabbings from '../TopTabbings';

export default function EventBookingCompleted() {
  return (
    <div>
      <HeaderLogin />
      <div className="header-lnk-feeds">
        <div className="container">
          <TopTabbings state={'social'} />
        </div>
      </div>
      <div className="right-payment text-center py-5">
        <span className="correct-icon">
          <i className="fas fa-check"></i>
        </span>
        <h3 className="mt-5">Thank You. Your tickets have been booked successfully.</h3>
        <p>Your Transaction ID for this transaction is #TRANSACTION948274.</p>
        <Link to="/user/events" className="btn btn-primary">
          Back to Events Listing
        </Link>
      </div>
    </div>
  );
}
