import { useEffect, useRef, useState } from 'react';
import { Button, Modal, OverlayTrigger, Tooltip, Form } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { resumeExtension, truncate } from '../../../globals/helper';
import useDocumentTitle from '../../../globals/useDocumentTitle';
import * as APISERVICES from '../../../services/JobServices';
import showNotification from '../../../services/NotificationService';
import * as APISERVICE from '../../../services/UserServices';
import HeaderLogin from '../../commoncomponents/HeaderLogin';
import ApplyForm from '../common-professional-comp/ApplyForm';
import JobCategoryList from '../common-professional-comp/JobCategoryList';
import JobFilterSearch from '../common-professional-comp/JobFilterSearch';
import RecomendModal from '../common-professional-comp/RecomendModal';
import SocialShare from './SocialShare';
import TopTabbings from './TopTabbings';
import moment from 'moment';

function Internships() {
  let stateFilter = 'jobFilterSearch';
  const location = useLocation();
  useDocumentTitle('Browse Jobs | AlmaBay');
  const { id } = useParams();

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  const [list, setList] = useState([]);
  const [count, setCount] = useState(0);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [jobsLoading, setJobsLoading] = useState(false);
  const [applyForm, setApplyForm] = useState({
    to: ' ',
    subject: ' ',
    message: ' ',
    resume: ' '
  });
  const [savedResume, setSavedResume] = useState('');
  const [jobId, setJobId] = useState('');
  const [jobTitle, setJobTitle] = useState('');
  const [isChecked, setIsChecked] = useState(false);
  const [resumeSelect, setResumeSelect] = useState(false);
  const [fileSizeValid, setFileSizeValid] = useState(false);
  const intern_id = new URLSearchParams(location?.search).get('');
  const [techSkill, setTechSkill] = useState([]);
  const [techSearch, setTechSearch] = useState('');
  const [skillsFilterId, setSkillsFilterId] = useState('');
  const [companyFilterId, setCompanyFilterId] = useState('');
  const [industryFilterId, setIndustryFilterId] = useState('');
  const [locationFilterId, setLocationFilterId] = useState('');
  const [hasMore, setHasMore] = useState(true);
  const [pageNum, setPageNum] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [lastElement, setLastElement] = useState(null);
  const [loadMoreButtonVisible, setLoadMoreButtonVisible] = useState(false);
  const [show3, setShow3] = useState(false);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);
  const [shareModelShow, setShareModelShow] = useState(false);
  const [socialShareData, setSocialShareData] = useState(false);
  const [inputMsg, setInputMsg] = useState('Please enter atleast 2 characters.');
  const [loadInpMsg, setloadInpMsg] = useState(false);
  const [referalModelShow, setReferalModelShow] = useState(false);
  const [showReferralForm, setShowReferralForm] = useState(false);
  const [refDetail, setRefDetail] = useState([]);
  const [recommentDetail, setRecommentDetail] = useState({
    preSelectedOptions: '',
    referralMsg: '',
    resumeOpt: '',
    resume: ''
  });

  const [isClicked, setIsClicked] = useState(false);
  const shareModelClose = () => {
    setShareModelShow(false);
  };
  const referalModelClose = () => {
    setReferalModelShow(false);
  };
  const skills = techSkill?.map((data) => {
    return data?.label;
  });

  useEffect(() => {
    getInternJobList();
    getUserDetail();
  }, []);

  useEffect(() => {
    if (pageNum <= totalPages) {
      getInternJobList();
    }
  }, [pageNum]);

  const getInternJobList = (isTrue) => {
    if (pageNum == 0) {
      setLoading(true);
      setJobsLoading(true);
    }

    APISERVICES.getJobForInternship('', pageNum).then((res) => {
      setLoading(false);
      setJobsLoading(false);
      if (res?.status === 200) {
        if (isTrue) {
          let arra1 = list?.map((item) => {
            const item2 = res.data?.data?.find((i2) => i2.id == item.id);
            return item ? { ...item, ...item2 } : item2;
          });
          setList(arra1);
        } else if (res?.data?.data) {
          setList([...list, ...res?.data?.data]);
        }

        setTotalPages(res?.data?.pager.pageCount);
        setHasMore(res?.data?.pager.hasMore);
        setCount(res?.data?.pager);
      }
    });
  };

  // it will run for search as we do not want to  append previous resposne
  const getInternJobListForDropdown = (id = '') => {
    setJobId(id);
    setLoading(true);
    APISERVICES.getJobForInternship(id, pageNum).then((res) => {
      setLoading(false);
      if (res?.status === 200) {
        setList(res.data.data);
        setTotalPages(res?.data?.pager.pageCount);
        setHasMore(res?.data?.pager.hasMore);
        setCount(res?.data?.pager);
        stateFilter == 'jobFilterSearch'
          ? ''
          : navigate(`/jobs/search/category/${id}`, { state: 1 });
      }
    });
  };

  const advanceJobSearch = () => {
    if (skills?.length !== 0 || companyFilterId || industryFilterId || locationFilterId) {
      setLoading(true);
      let body = {
        skills: skills?.toString(),
        companies: companyFilterId,
        industry: industryFilterId,
        location: locationFilterId,
        jof: 'jobs',
        job_type: 'internship',
        max_ctc: '',
        max_exp: '',
        min_ctc: '',
        min_exp: '',
        offset: 0,
        type: ''
      };
      APISERVICE.advanceSearchForJob(body).then((res) => {
        setLoading(false);
        if (res?.status === 200) {
          setList(res?.data?.detail);
          setCount('');
        }
      });
    } else {
      showNotification('danger', 'Kindly select at least one filter condition to proceed');
    }
  };

  const getUserDetail = () => {
    APISERVICE.userBasicInfo().then((res) => {
      if (res?.status === 200) {
        setSavedResume(res?.data?.resume);
        setApplyForm({ ...applyForm, ['resume']: res?.data?.resume });
      }
    });
  };

  const onApplyStateHandler = (e, data) => {
    e.preventDefault();
    setJobId(data?.id);
    setJobTitle(data?.job_title);
    setApplyForm({
      to: data?.user
        ? data?.user?.first_name + ' ' + data?.user?.last_name
        : data?.job_company_meta,
      subject: `Alma - Job application for ${data?.job_title}`,
      message: '',
      resume: savedResume
    });
    handleShow2();
  };

  const onChangeHandler = (e, rid = false) => {
    if (rid) {
      setApplyForm({ ...applyForm, ['resume']: savedResume });
    } else {
      const { name, value, type } = e.target;
      if (type == 'file') {
        let file = e.target.files[0];
        let fileSize = file.size / 1024;

        if (fileSize > 500) {
          setResumeSelect(false);
          setFileSizeValid(true);
          alert('Resume file size should be less than 500KB');
        } else {
          setFileSizeValid(false);
          if (resumeExtension.includes(e.target.files[0].type)) {
            setResumeSelect(true);
            setApplyForm({ ...applyForm, [name]: file });
          } else {
            setResumeSelect(false);
            alert(
              'Resume file format is not valid, kindly attach a valid file e.g doc,docx,rtf,odt,pdf'
            );
          }
        }
      } else {
        setApplyForm({ ...applyForm, [name]: value });
      }
    }
  };

  const submitHandler = () => {
    let formData = new FormData();
    for (let i in applyForm) {
      formData.append(`${i}`, applyForm[i]);
    }
    APISERVICES.applyForJob(jobId, formData).then((res) => {
      if (res?.status === 200) {
        handleClose2();
        showNotification('success', res?.data?.message);
        getInternJobList(true);
        onResetPageNum(); // reset page number
      }
    });
  };

  const observer = useRef(
    new IntersectionObserver((entries) => {
      const first = entries[0];

      if (first.isIntersecting) {
        // make loadmore button visible
        setLoadMoreButtonVisible(true);
      }
    })
  );

  useEffect(() => {
    const currentElement = lastElement;
    const currentObserver = observer.current;
    if (currentElement) {
      currentObserver.observe(currentElement);
    }

    return () => {
      if (currentElement) {
        currentObserver.unobserve(currentElement);
      }
    };
  }, [lastElement]);
  const loadMore = () => {
    setPageNum((no) => no + 1);
  };

  const onResetPageNum = () => {
    setPageNum(0);
  };

  const onRecommendClick = (e, id) => {
    e.preventDefault();
    setJobId(id);
    handleShow3();
  };

  const loadTechOptions = async (inputValue) => {
    if (inputValue.length >= 2) {
      setloadInpMsg(true);

      return APISERVICE.getTechnicalSkillDrop(inputValue).then((res) => {
        setloadInpMsg(false);
        if (res?.status === 200) {
          let data = res?.data?.data;
          let arr = [];
          data?.map((data) => {
            arr.push({ label: data.name, value: data.id });
          });

          if (data?.length == 0) {
            setInputMsg('no matches found');
          }
          return arr;
        }
      });
    }
  };
  // handler for recomment image
  const recommendHandler = (e, rid = false) => {
    if (rid) {
      setRecommentDetail({ ...recommentDetail, ['resume']: savedResume });
    } else {
      const { name, value, type } = e.target;
      if (type == 'file') {
        let file = e.target.files[0];
        let fileSize = file.size / 1024;

        if (fileSize > 500) {
          setResumeSelect(false);
          setFileSizeValid(true);
          alert('Resume file size should be less than 500KB');
        } else {
          setFileSizeValid(false);
          if (resumeExtension.includes(e.target.files[0].type)) {
            setResumeSelect(true);
            setRecommentDetail({ ...recommentDetail, [name]: file });
          } else {
            setResumeSelect(false);
            alert(
              'Resume file format is not valid, kindly attach a valid file e.g doc,docx,rtf,odt,pdf'
            );
          }
        }
      } else {
        setRecommentDetail({ ...recommentDetail, [name]: value });
      }
    }
  };

  const handleRequested = (data) => {
    setRecommentDetail({
      ...recommentDetail,
      ['preSelectedOptions']: data?.user_id
    });
    setShowReferralForm(true);
  };

  const fetchReferralDetail = (id) => {
    setReferalModelShow(true);
    setShowReferralForm(false);
    APISERVICES.getReferralDetail(id).then((res) => {
      if (res?.status == 200) {
        if (res?.data?.detail) {
          setRefDetail(res?.data?.detail);
        }
      }
    });
  };
  const validate = (values) => {
    let errors = {};
    if (!values.referralMsg?.trim()) {
      errors.referralMsg = 'Message field is empty or invalid input has been entered.';
    }
    if (recommentDetail?.resumeOpt == 'attach') {
      if (!values.resume.name) {
        errors.resume = 'Please attach your latest resume.';
      }
    }

    return errors;
  };

  const sendRefferal = (e, id) => {
    e.preventDefault();
    setIsClicked(true);
    if (!Object.keys(validate(recommentDetail)).length) {
      let formData = new FormData();
      for (let i in recommentDetail) {
        formData.append(`${i}`, recommentDetail[i]);
      }
      //recJobId
      APISERVICES.sendReferral(id, formData).then((res) => {
        if (res?.status == 200) {
          showNotification('success', res?.data?.message);
          setIsClicked(false);
          setReferalModelShow(false);
        }
      });
    }
  };
  return (
    <>
      <HeaderLogin />
      <div className="header-lnk-feeds">
        <div className="container">
          <TopTabbings state={'feed'} />
        </div>
      </div>
      <section className="feed-sec bg-grey py-4">
        <div className="container">
          <Row>
            {/* filter-component */}
            <JobFilterSearch
              advanceJobSearch={advanceJobSearch}
              setSkillsFilterId={setSkillsFilterId}
              setCompanyFilterId={setCompanyFilterId}
              setIndustryFilterId={setIndustryFilterId}
              setLocationFilterId={setLocationFilterId}
              getInternJobList={getInternJobList}
              onResetPageNum={onResetPageNum}
              job_type="internship"
              techSkill={techSkill}
              setTechSkill={setTechSkill}
              techSearch={techSearch}
              setTechSearch={setTechSearch}
              loadTechOptions={loadTechOptions}
              setInputMsg={setInputMsg}
              inputMsg={inputMsg}
              loadInpMsg={loadInpMsg}
              stateFilter="jobFilterSearch"
              getInternJobListForDropdown={getInternJobListForDropdown}
            />
            {/* end */}
            <Col xs={12} lg={8} xl={6} className="mb-4">
              <div className="job-recomm-card bg-white">
                {intern_id ? <h4> Filtered Jobs Results</h4> : <h4>Search Jobs for Internship</h4>}
                {list && list?.length ? (
                  <h6 className="intern-count-hd pt-3 px-3">
                    Latest <b>{list?.length} Internship opportunities</b> for you.
                  </h6>
                ) : (
                  <></>
                )}
                <div className="job-recommd-lst p-3">
                  {jobsLoading &&
                    Array.from(Array(3), (index) => {
                      return <Shimmer key={index} />;
                    })}
                  {!jobsLoading && list?.length !== 0 ? (
                    list?.map((data, index) => {
                      return (
                        <div
                          key={index}
                          className="job-recomm-card-inr internship-card-inr"
                          ref={setLastElement}>
                          <div className="top-time-line mb-3">
                            <p title={moment(data?.created_on).format('YYYY-MM-DD HH:MM:SS')}>
                              <i className="far fa-clock me-1"></i> Added on: {data?.date_time}
                            </p>
                          </div>
                          <div className="job-recomm-dis">
                            <Link
                              to="#"
                              onClick={(e) => {
                                e.preventDefault();
                              }}></Link>
                            <h4>
                              <Link
                                to={`/jobs/jobs-details/${data?.id}`}
                                // onClick={(e) => {
                                //   e.preventDefault();
                                // }}
                              >
                                {data?.job_title}
                              </Link>
                            </h4>
                            <p>{data?.company_name}</p>
                            {data?.key_skills && data?.key_skills?.length !== 0 && (
                              <p>
                                <strong>Keyskills:</strong>{' '}
                                {/* {truncate(data?.job_keyword_meta?.split('|')?.join(), 50)} */}
                                {data?.key_skills
                                  ? data?.key_skills
                                      ?.map((item) => {
                                        return item?.name;
                                      })
                                      ?.join(',')
                                  : []?.map((item) => {
                                      return <span>{item?.name}, </span>;
                                    })}
                              </p>
                            )}

                            <ul className="job-recomm-addrs">
                              {data?.city_name ? (
                                <li>
                                  <i title="Location" className="fas fa-map-marker-alt"></i>
                                  {data?.city_name}
                                </li>
                              ) : (
                                ''
                              )}
                              <li>
                                <i title="Positions open" className="fas fa-users"></i>Positions
                                Open: {data?.open_positions}
                              </li>
                            </ul>
                            <div>
                              <div
                                dangerouslySetInnerHTML={{ __html: truncate(data?.job_info, 200) }}
                              />
                            </div>

                            {data?.self_added !== true ? (
                              <Link
                                className="view-detail-btn"
                                to={`/jobs/jobs-details/${data?.id}`}
                                target="_blank">
                                View In Detail
                              </Link>
                            ) : (
                              ''
                            )}
                          </div>
                          <div className="job-recommd-ftr">
                            <ul>
                              {data?.self_added ? (
                                ''
                              ) : (
                                <li>
                                  {!data?.is_applied ? (
                                    <Link
                                      className="apply-btn"
                                      onClick={(e) => {
                                        onApplyStateHandler(e, data);
                                      }}>
                                      <i className="fas fa-check me-1"></i> Apply
                                    </Link>
                                  ) : (
                                    <Link className="btn applied-btn">
                                      <i className="fas fa-check me-1"></i>Already Applied
                                    </Link>
                                  )}

                                  {/* pop-up */}
                                </li>
                              )}

                              <li>
                                <Link
                                  to="#"
                                  onClick={(e) => {
                                    onRecommendClick(e, data?.id);
                                  }}>
                                  <i className="fas fa-thumbs-up me-1"></i>Recommend
                                </Link>
                              </li>
                              <li>
                                <Link
                                  onClick={(e) => {
                                    e.preventDefault();
                                    setShareModelShow(true);
                                    setSocialShareData(data);
                                  }}>
                                  <i className="fas fa-share me-1"></i> Share
                                </Link>
                              </li>
                              {data?.job_referred == 1 && (
                                <Link
                                  className="referral-btn"
                                  to={`javascript:void(0)`}
                                  onClick={() => {
                                    fetchReferralDetail(data?.id);
                                  }}>
                                  <span>Request for referral</span>
                                  {['top'].map((placement) => (
                                    <OverlayTrigger
                                      key={placement}
                                      placement={placement}
                                      overlay={
                                        <Tooltip id={`tooltip-${placement}`}>
                                          Direct referral increases the chances of getting a job.
                                        </Tooltip>
                                      }>
                                      <i className="fas fa-question-circle ms-2"></i>
                                    </OverlayTrigger>
                                  ))}
                                </Link>
                              )}
                            </ul>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <>
                      {!jobsLoading && list.length == 0 && (
                        <div className="upcoming_events p-3">
                          <div className="text-center">
                            <img
                              src={require('../../../assets/images/small-error.png')}
                              alt="img"
                            />
                          </div>
                          <p className="text-center">Data not found.</p>
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>
              {
                loadMoreButtonVisible && (
                  // list.length > 10 && (
                  <div className="load-more-btn py-3 text-center">
                    <Button variant="secondary" className="read-more" onClick={loadMore}>
                      Load More
                    </Button>
                  </div>
                )
                // )
              }
            </Col>

            <Col xl={{ span: 3, offset: 0 }} lg={{ span: 8, offset: 4 }}>
              {/* job category modal */}
              <JobCategoryList
                callApi={getInternJobListForDropdown}
                onResetPageNum={onResetPageNum}
                type="1"
              />
            </Col>
          </Row>
        </div>
      </section>
      {/* recommended job modal */}
      <RecomendModal handleClose3={handleClose3} show3={show3} jobId={jobId} />
      {/* end of modal */}
      {/* <FooterMain className={'colour-bg'} /> */}
      <ApplyForm
        onChangeHandler={onChangeHandler}
        setIsChecked={setIsChecked}
        isChecked={isChecked}
        submitHandler={submitHandler}
        applyForm={applyForm}
        show2={show2}
        handleClose2={handleClose2}
        jobTitle={jobTitle}
        resumeSelect={resumeSelect}
        fileSizeValid={fileSizeValid}
        getUserDetail={getUserDetail}
      />
      <Modal show={shareModelShow} onHide={shareModelClose}>
        <Modal.Header closeButton>
          <Modal.Title>Share this job</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="mb-2">Share it on</p>
          <SocialShare
            facebookOptions={{
              url: process.env.REACT_APP_DOMAIN + 'user/jobs-details' + '/' + socialShareData.id,
              quote: socialShareData?.job_title
            }}
            twitterOptions={{
              url: process.env.REACT_APP_DOMAIN + 'user/jobs-details' + '/' + socialShareData.id,
              title: socialShareData?.job_title,
              hashtags: ['jobs', 'trends']
            }}
            linkedInOptions={{
              title: socialShareData?.job_title,
              summary: socialShareData?.job_info,
              url: process.env.REACT_APP_DOMAIN + 'user/jobs-details' + '/' + socialShareData.id,
              source: process.env.REACT_APP_DOMAIN + 'user/jobs-details' + '/' + socialShareData.id
            }}
          />
        </Modal.Body>
      </Modal>

      <Modal show={referalModelShow} onHide={referalModelClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Request for referral</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="request-referal">
            {refDetail && refDetail?.length && refDetail[0]?.professionFriends?.length ? (
              refDetail?.map((data, index) => (
                <div className="request-ref d-flex align-items-top" key={index}>
                  <Link className="me-3" to={`javascript:void(0)`}>
                    <img
                      src={require('../../../assets/images/default-male-avatar.png')}
                      alt="img"
                    />
                  </Link>
                  <div className="ref-dis">
                    <div className="d-flex align-items-center justify-content-between">
                      <h4> {data?.professionFriends[0]?.name}</h4>
                      <div className="ref-rt-link d-flex align-items-center">
                        <i className="fas fa-users ms-1"></i>
                        <i className="fas fa-info ms-3"></i>
                      </div>
                    </div>
                    <p>
                      {data?.professionFriends[0]?.designation} (
                      {data?.professionFriends[0]?.year_from +
                        '-' +
                        data?.professionFriends[0]?.year_to}
                      )
                    </p>
                    <p>
                      <b>Location: </b>
                      {data?.professionFriends[0]?.location}
                    </p>
                    {showReferralForm ? (
                      <>
                        {' '}
                        <div className="ref-msg">
                          <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Control
                              as="textarea"
                              name="referralMsg"
                              type="text"
                              placeholder="Message"
                              onChange={(e) => {
                                recommendHandler(e, false);
                              }}
                            />
                            <div
                              className={`${
                                isClicked && validate(recommentDetail)?.referralMsg ? '' : 'd-none'
                              } danger-frm `}>
                              {isClicked && validate(recommentDetail)?.referralMsg}
                            </div>
                          </Form.Group>
                        </div>
                        <div>
                          {['radio'].map((type) => (
                            <div key={`default-${type}`} className="mb-3">
                              <div className="max-card">
                                <Form.Check
                                  type={type}
                                  id={`default-${type}`}
                                  label="Attach latest resume"
                                  name="group1"
                                  onChange={(e) => {
                                    setIsChecked(true);
                                    setRecommentDetail({
                                      ...recommentDetail,
                                      ['resumeOpt']: 'attach'
                                    });
                                  }}
                                />

                                {isChecked ? (
                                  <div className="upload-resume-frm mt-2">
                                    <Form.Group controlId="formFile" className="mb-3">
                                      <Form.Control
                                        type="file"
                                        accept="application/vnd.oasis.opendocument.text,application/*"
                                        name="resume"
                                        onChange={(e) => {
                                          recommendHandler(e, false);
                                        }}
                                      />
                                    </Form.Group>
                                  </div>
                                ) : (
                                  ''
                                )}
                                <p className="ms-4">Max upload size is 500kb</p>
                                <div
                                  className={`${
                                    isClicked && validate(recommentDetail)?.resume ? '' : 'd-none'
                                  } danger-frm `}>
                                  {isClicked && validate(recommentDetail)?.resume}
                                </div>
                              </div>

                              <div className="resume-card-link">
                                <Form.Check
                                  checked
                                  type={type}
                                  label="Use"
                                  id={`default-${type}`}
                                  name="group1"
                                  onChange={(e) => {
                                    setRecommentDetail({ ...recommentDetail, ['resumeOpt']: '' });
                                    setIsChecked(false);
                                    recommendHandler(e, true);
                                  }}
                                />
                                <a
                                  className="ms-4"
                                  href={`${process.env.REACT_APP_API_DOMAIN}jobs/resumeDownload?resume_name=${data?.jobResume?.name}&user_id=${data?.jobResume?.ID}`}
                                  rel="noreferrer">
                                  {data?.jobResume?.name}
                                </a>
                              </div>
                            </div>
                          ))}
                        </div>
                      </>
                    ) : (
                      <>
                        <button
                          className="main-btn mb-3"
                          type="button"
                          onClick={() => {
                            handleRequested(data?.professionFriends[0]);
                          }}>
                          REQUEST REFERRAL
                        </button>
                      </>
                    )}
                  </div>
                </div>
              ))
            ) : (
              <>At the moment, we can't find any referer for this job. Please check back later.</>
            )}

            {refDetail[0]?.professionFriends?.length ? (
              <button
                className="ref-blk-btn"
                onClick={(e) => {
                  sendRefferal(e, refDetail[0]?.jobInfo?.id);
                }}>
                <i className="fas fa-check me-1"></i> Ask for Referral
              </button>
            ) : (
              <></>
            )}
          </div>
        </Modal.Body>
      </Modal>

      {/* loader */}
      {/* {loading ? <Loader /> : null} */}
    </>
  );
}

export default Internships;

const Shimmer = () => {
  return (
    <div className="internship-jobs-shimmer loading-skeleton">
      <div className="job-recomm-card-inr internship-card-inr">
        <div className="top-time-line mb-3">
          <p>
            <i className="far fa-clock me-1"></i>
          </p>
        </div>
        <div className="job-recomm-dis">
          <Link to={`javascript:void(0)`}></Link>
          <h4>
            <Link to={`javascript:void(0)`}></Link>
          </h4>
          <p> </p>
          <p>
            <strong> </strong>{' '}
          </p>
          <ul className="job-recomm-addrs">
            <li>
              <i className="fas fa-map-marker-alt"></i>
            </li>
            <li>
              <i className="fas fa-users"></i>
            </li>
          </ul>
          <h2 className="innerhtml"> </h2>
          <h3 className="innerhtml"> </h3>
          <Link className="view-detail-btn" to={`javascript:void(0)`} target="_blank"></Link>
        </div>
        <div className="job-recommd-ftr">
          <ul>
            <li>
              <Link to={`javascript:void(0)`} className="apply-btn">
                <i className="fas fa-check me-1"></i>
              </Link>
            </li>

            <li>
              <Link to={`javascript:void(0)`}>
                <i className="fas fa-thumbs-up me-1"></i>
              </Link>
            </li>
            <li>
              <Link to={`javascript:void(0)`}>
                <i className="fas fa-share me-1"></i>
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};
