import { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import ProficiencyDrop from '../ProficiencyDropDown/ProficiencyDrop';
import * as APISERVICES from '../../../services/UserServices';
import { getProficiencyImage } from '../../../globals/helper';
import { useEffect } from 'react';
import showNotification from '../../../services/NotificationService';
import Autocomplete from '../Autosearch';
import { BusinessFormSimmer } from '../skelton/allskelton';

function BusinessForm() {
  const [isLoading, setIsLoading] = useState(false);
  const [Loading, setLoading] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [proficiency, setProficiency] = useState('');
  const [skillOptions, setSkillOptions] = useState([]);
  const [skillId, setSkillId] = useState('');
  const [isEdit, setIsEdit] = useState(false);
  const [businessListData, setBusinessListData] = useState([]);
  const [editId, setEditId] = useState('');

  useEffect(() => {
    getDropDownList();
    getList();
  }, []);

  const getList = () => {
    APISERVICES.getBusinessSkill().then((res) => {
      if (res?.status === 200) {
        const data = res?.data?.data.map((v) => ({ ...v, isEdit: false }));
        setBusinessListData(data);
      }
    });
  };

  const getDropDownList = (value) => {
    setLoading(true);
    APISERVICES.getBusinessSkillDrop(value).then((res) => {
      setLoading(false);
      if (res?.status === 200) {
        let data = res?.data?.data;
        let arr = [];
        data?.map((data) => {
          arr.push({ label: data.name, value: data.id });
        });
        setSkillOptions(arr);
      }
    });
  };

  const validation = () => {
    if (isEdit) {
      return true;
    } else {
      if (skillId == '') {
        showNotification('danger', 'Skill can not be blank');
        return false;
      }
      if (proficiency == '') {
        showNotification('danger', 'Proficiency can not be blank');
        return false;
      }
      return true;
    }
  };

  const submitHandler = (e) => {
    e.preventDefault();
    if (validation()) {
      setIsLoading(true);
      let detail = {
        keyword_id: skillId ? skillId?.label : editId?.keyword_name,
        proficiency_id: proficiency ? proficiency?.id : editId?.proficiency_id
      };
      let formData = new FormData();
      if (isEdit) {
        let newDetail = { ...detail, id: editId?.id };
        for (let i in newDetail) {
          formData.append(`${i}`, newDetail[i]);
        }
      } else {
        for (let i in detail) {
          formData.append(`${i}`, detail[i]);
        }
      }

      if (isEdit) {
        APISERVICES.updateBusinessInfo(formData).then((res) => {
          if (res?.status === 200) {
            showNotification('success', res?.data?.message);
            getList();
            setIsLoading(false);
          } else {
            setIsLoading(false);
          }
        });
      } else {
        APISERVICES.addBussinesSkills(formData).then((res) => {
          if (res?.status === 200) {
            showNotification('success', res?.data?.message);
            getList();
            setShowForm(false);
            setIsLoading(false);
          } else {
            setIsLoading(false);
          }
        });
      }
    }
  };

  const handleEdit = (e, data, index) => {
    e.preventDefault();
    setEditId(data);
    setShowForm(false)
    businessListData.forEach((a, i) => {
      if (i === index) {
        setIsEdit(true);
        businessListData[index].isEdit = true;
      } else {
        businessListData[i].isEdit = false;
      }
    });
  };

  const hideAlledit = (e) => {
    e.preventDefault();
    setIsEdit(false);
    businessListData.forEach((a, i) => {
      businessListData[i].isEdit = false;
    });
  };

  const deleteHandler = (e, id) => {
    if (confirm('Do you want to remove Skill-Proficiency ?') === true) {
      APISERVICES.deleteUserSkill(id).then((res) => {
        if (res?.status === 200) {
          showNotification('success', res?.data?.message);
          getList();
          setShowForm(false);
          hideAlledit(e);
        }
      });
    } else {
      return;
    }
  };

  return (
    <>
      <div className="detail_form_div">
        <form className="contact-details" id="">
          <h2>Business Skills</h2>

          {Loading ? (
            <>
              <BusinessFormSimmer />
              <BusinessFormSimmer />
              <BusinessFormSimmer />
              <BusinessFormSimmer />
              <BusinessFormSimmer />
            </>
          ) : (
            <>
              {businessListData?.length !== 0 ? (
                <>
                  {businessListData?.map((data, index) => {
                    return (
                      <>
                        <Row
                          key={index}
                          className={data?.isEdit ? 'form-group active ' : 'form-group'}
                        >
                          {!data?.isEdit && (
                            <Col sm={7} xs={7} onClick={(e) => handleEdit(e, data, index)}>
                              <label htmlFor="firstname">{data?.keyword_name}</label>
                            </Col>
                          )}

                          {data?.isEdit && (
                            <>
                              <Col sm={4} xs={4} onClick={(e) => handleEdit(e, data, index)}>
                                <Autocomplete
                                  placeholder="Select your skill"
                                  optionsData={skillOptions}
                                  value={data?.keyword_name}
                                  datafunction={(search) => getDropDownList(search)}
                                  onChange={(val) => setSkillId(val)}
                                />
                              </Col>
                              <Col sm={3} xs={3} onClick={(e) => handleEdit(e, data, index)}>
                                <ProficiencyDrop
                                  setState={setProficiency}
                                  state={{
                                    id: data.proficiency_id,
                                    label: data.proficiency_name,
                                    value: data.proficiency_name
                                  }}
                                  isEdit={isEdit}
                                />
                              </Col>
                            </>
                          )}

                          <Col sm={5} xs={5}>
                            <div className="d-flex align-items-center justify-content-end ">
                              <span className="technical-rt-hd me-3">{data?.proficiency_name}</span>
                              <span className="technical-rt-icn me-3">
                                <img src={getProficiencyImage(+data?.proficiency_id)} alt="" />
                              </span>

                              <Link
                                to=""
                                title="Save"
                                className="me-2 correct_btn"
                                onClick={submitHandler}
                              >
                                {isLoading ? (
                                  <i className="fas fa-spinner fa-spin"></i>
                                ) : (
                                  <i className="fas fa-check"></i>
                                )}
                              </Link>
                              <Link
                                to=""
                                title="Cancel"
                                className="me-2 cross_btn"
                                onClick={(e) => hideAlledit(e)}
                              >
                                <i className="fas fa-times"></i>
                              </Link>
                              <Link
                                to="#"
                                title="Remove"
                                className="me-2 trash_btn"
                                onClick={(e) => deleteHandler(e, data?.id)}
                              >
                                <i className="far fa-trash-alt"></i>
                              </Link>
                              <Link to="" onClick={(e) => handleEdit(e, data, index)}>
                                <i className="fa fa-chevron-right" title="Edit"></i>
                              </Link>
                            </div>
                          </Col>
                        </Row>
                      </>
                    );
                  })}
                </>
              ) : (
                <>
                  <div className="upcoming_events p-3">
                    <div className="text-center">
                      <img src={require('../../../assets/images/small-error.png')} alt="img" />
                    </div>
                    <p className="text-center">No Business Skills are added at the moment.</p>
                  </div>
                </>
              )}
              {!showForm && (
                <div className="work-place-btn text-end px-4 py-3">
                  <Link
                    to=""
                    onClick={(e) => {
                      setSkillId('');
                      setShowForm(true);
                      hideAlledit(e);
                    }}
                  >
                    + Add Skills
                  </Link>
                </div>
              )}
            </>
          )}

          {showForm ? (
            <Row className="form-group align-items-center active">
              <Col sm={5} xs={5}>
                <Autocomplete
                  placeholder="Select your skill"
                  optionsData={skillOptions}
                  value={skillId}
                  datafunction={(search) => getDropDownList(search)}
                  onChange={(val) => setSkillId(val)}
                />
              </Col>
              <Col sm={5} xs={5}>
                <ProficiencyDrop setState={setProficiency} isEdit={isEdit} />
              </Col>
              <Col sm={2} xs={2}>
                <div className="d-flex align-items-center justify-content-end">
                  <Link to="" title="Save" className="me-2 correct_btn" onClick={submitHandler}>
                    {isLoading ? (
                      <i className="fas fa-spinner fa-spin"></i>
                    ) : (
                      <i className="fas fa-check"></i>
                    )}
                  </Link>
                  <Link
                    to=""
                    title="Cancel"
                    className="me-2 cross_btn"
                    onClick={(e) => {
                      setShowForm(false);
                      hideAlledit(e);
                    }}
                  >
                    <i className="fas fa-times"></i>
                  </Link>
                </div>
              </Col>
            </Row>
          ) : (
            <></>
          )}
        </form>
      </div>
    </>
  );
}

export default BusinessForm;
