import { useState, useEffect } from 'react';
import { Col, Container, Dropdown, Row } from 'react-bootstrap';
import HeaderLogin from '../../commoncomponents/HeaderLogin';
import TopTabbings from './TopTabbings';
import Form from 'react-bootstrap/Form';
import useDocumentTitle from '../../../globals/useDocumentTitle';
import * as APISERVICES from '../../../services/NewsServices';
import { useSelector } from 'react-redux';
import { truncate } from '../../../globals/helper';
import { Link } from 'react-router-dom';
import HeaderInner from '../../commoncomponents/HeaderInner';

function NewsPage() {
  const [filter, setFilter] = useState();
  const [list, setList] = useState([]);
  const isloggedIn = useSelector((state) => state?.user?.isloggedIn);
  const [error, setError] = useState('');

  useDocumentTitle("What's New | AlmaBay");

  useEffect(() => {
    WhatsNewData();
  }, [filter]);

  /**
   *
   * Search News with login and  without login
   *
   */

  const WhatsNewData = () => {
    const body = {
      filter: filter
    };

    APISERVICES.SearchWhatsNewInfo(body).then((res) => {
      if (res?.status === 200) {
        if (Array.isArray(res?.data?.detail)) {
          setList(res?.data?.detail);
        } else {
          setList([]);
          setError('Please Sign in or Sign up to comment.');
        }
      }
    });
  };

  function getDate(time) {
    const d = new Date(time);
    const months = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December'
    ];

    return `${months[d.getMonth()]},${d.getFullYear()}`;
  }

  return (
    <>
      {isloggedIn ? (
        <>
          <HeaderLogin />
          <div className="header-lnk-feeds">
            <div className="container">
              <TopTabbings state={'mentor'} />
            </div>
          </div>
        </>
      ) : (
        <HeaderInner />
      )}

      <section className="news-feed-sec bg-grey py-4">
        <Container>
          <Row>
            <Col xs={12} lg={12} className="mb-4">
              <div className="articals_div">
                <div className="serch-mentors-data">
                  <Form.Control
                    type="search"
                    placeholder="Search for News..."
                    onChange={(e) => setFilter(e.target.value)}
                    value={filter}
                  />
                </div>

                {list && list?.length !== 0
                  ? list?.map((data, index) => {
                      return (
                        <div className="single_artical_card p-3" key={index}>
                          <div>
                            <div className="news-lst-hd d-flex align-items-center justify-content-between mb-2">
                              <Link className="read-more" to={`/news/show/${data?.id}`}>
                                <h4 className="title">{data?.content.page_title}</h4>
                              </Link>
                              <p>
                                <i className="far fa-clock me-1"></i> Added on{' '}
                                {getDate(data?.created_date)}
                              </p>
                            </div>

                            <p
                              dangerouslySetInnerHTML={{
                                __html: truncate(`${data?.content.page_content}`, 200)
                              }}
                            ></p>
                          </div>
                          <div>
                            <Link className="read-more" to={`/news/show/${data?.id}`}>
                              Read more...
                            </Link>
                          </div>
                          <div className="navigation-border">
                            <ul className="article-cmmnt-lnks d-flex ps-2">
                              <li className="active-helpful">
                                <Link to={`/news/show/${data?.id}`} className="pe-3">
                                  <i className="fa fa-comment"></i>Comments{'  '}
                                  <span>({data?.comments} )</span>
                                </Link>
                              </li>
                              <li className="share-drop-btn">
                                <Dropdown>
                                  <Dropdown.Toggle className="pe-3" id="dropdown-basic">
                                    <i className="fa fa-share"></i> Share
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu className="p-2">
                                    <Dropdown.Item
                                      as={Link}
                                      target="_blank"
                                      to={`https://www.facebook.com/sharer/sharer.php?u=${
                                        process.env.REACT_APP_DOMAIN + 'news/show' + '/' + data?.id
                                      }`}
                                    >
                                      Facebook
                                    </Dropdown.Item>

                                    <Dropdown.Item
                                      as={Link}
                                      target="_blank"
                                      to={`https://twitter.com/intent/tweet?url=${
                                        process.env.REACT_APP_DOMAIN + 'news/show' + '/' + data?.id
                                      }`}
                                    >
                                      Twitter
                                    </Dropdown.Item>

                                    <Dropdown.Item
                                      as={Link}
                                      target="_blank"
                                      to={`https://plus.google.com/share?url=${
                                        process.env.REACT_APP_DOMAIN + 'news/show' + '/' + data?.id
                                      }`}
                                    >
                                      Google
                                    </Dropdown.Item>

                                    <Dropdown.Item
                                      as={Link}
                                      target="_blank"
                                      to={`https://www.linkedin.com/shareArticle?url=${
                                        process.env.REACT_APP_DOMAIN + 'news/show' + '/' + data.id
                                      }&mini=true&title=${data?.content.page_title}&source=AlmaBay`}
                                    >
                                      Linkedin
                                    </Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </li>
                            </ul>
                          </div>
                        </div>
                      );
                    })
                  : ''}

                {/* <div className="single_artical_card p-3">
                  <div>
                    <div className="news-lst-hd d-flex align-items-center justify-content-between mb-2">
                      <h4 className="title">15 movies that inspire entrepreneurship</h4>
                      <p>
                        <i className="far fa-clock me-1"></i> Added 2016-12-14 T0 6:10:31+0530
                      </p>
                    </div>
                    <p>
                      1. Startup.com This 2001 documentary follows the rise and fall of a promising
                      startup called GoveWorks.com. Despite a lot of buzz and funding for the
                      company, the founders must deal with in-house bickering and getting too
                      inflated too fast.Why watch it? There are many takeaways from this documentar
                    </p>
                  </div>
                  <div>
                    <Link className="read-more" to="#">
                      Read more...
                    </Link>
                  </div>
                  <div className="navigation-border">
                    <ul className="article-cmmnt-lnks d-flex ps-2">
                      <li className="active-helpful">
                        <Link to="#" className="pe-3">
                          <i className="fa fa-comment"></i>Comments {'  '}
                          <span>(11)</span>
                        </Link>
                      </li>
                      <li className="share-drop-btn">
                        <Dropdown>
                          <Dropdown.Toggle className="pe-3" id="dropdown-basic">
                            <i className="fa fa-share"></i> Share
                          </Dropdown.Toggle>
                          <Dropdown.Menu className="p-2">
                            <Dropdown.Item href="#/action-1">Facebook</Dropdown.Item>
                            <Dropdown.Item href="#/action-2">Twitter</Dropdown.Item>
                            <Dropdown.Item href="#/action-3">Google</Dropdown.Item>
                            <Dropdown.Item href="#/action-3">Linkedin</Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="single_artical_card p-3">
                  <div>
                    <div className="news-lst-hd d-flex align-items-center justify-content-between mb-2">
                      <h4 className="title">15 movies that inspire entrepreneurship</h4>
                      <p>
                        <i className="far fa-clock me-1"></i> Added 2016-12-14 T0 6:10:31+0530
                      </p>
                    </div>
                    <p>
                      1. Startup.com This 2001 documentary follows the rise and fall of a promising
                      startup called GoveWorks.com. Despite a lot of buzz and funding for the
                      company, the founders must deal with in-house bickering and getting too
                      inflated too fast.Why watch it? There are many takeaways from this documentar
                    </p>
                  </div>
                  <div>
                    <Link className="read-more" to="#">
                      Read more...
                    </Link>
                  </div>
                  <div className="navigation-border">
                    <ul className="article-cmmnt-lnks d-flex ps-2">
                      <li className="active-helpful">
                        <Link to="#" className="pe-3">
                          <i className="fa fa-comment"></i>Comments {'  '}
                          <span>(11)</span>
                        </Link>
                      </li>
                      <li className="share-drop-btn">
                        <Dropdown>
                          <Dropdown.Toggle className="pe-3" id="dropdown-basic">
                            <i className="fa fa-share"></i> Share
                          </Dropdown.Toggle>
                          <Dropdown.Menu className="p-2">
                            <Dropdown.Item href="#/action-1">Facebook</Dropdown.Item>
                            <Dropdown.Item href="#/action-2">Twitter</Dropdown.Item>
                            <Dropdown.Item href="#/action-3">Google</Dropdown.Item>
                            <Dropdown.Item href="#/action-3">Linkedin</Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </li>
                    </ul>
                  </div>
                </div> */}
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}

export default NewsPage;
