import { useEffect, useState } from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getProductList } from '../../../services/InstituteServices';
import Loader from '../../commoncomponents/loader/loader-large';

function MotivationalMemorabilia() {
  const [list, setList] = useState([]);
  const insDetail = useSelector((state) => state?.insInfo?.data);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    productList();
  }, []);

  const productList = () => {
    setLoading(true)
    getProductList().then((res) => {
      setLoading(false)
      if (res?.status == 200) {
        setList(res?.data?.data);
      }
    });
  };

  return (
    <>
      <div className="wrappermain_outer">
        <section className="motivatinal_hero_banner pt-5 pb-5">
          <Container>
            <Row className="align-items-center">
              <Col lg={6}>
                <img
                  src={
                    list?.length
                      ? process.env.REACT_APP_API_URL +
                      'common/uploads/products/' +
                      list[0]?.cover_image
                      : require('../../../assets/images/intitute-motivational-img.png')
                  }
                  // src={require('../../../assets/images/intitute-motivational-img.png')}
                  alt="img"
                  width="100%"
                />
              </Col>
              <Col lg={6}>
                <div className="banner-info">
                  <img
                    className="mb-3"
                    src={
                      insDetail?.logo
                        ? process.env.REACT_APP_API_URL +
                        'common/uploads/institute_logo/' +
                        insDetail?.logo
                        : require('../../../assets/images/institute_logo.png')
                    }
                    alt="logo"
                  />
                  <h1 className="mb-3">Almater</h1>
                  <h3 className="mb-4">Exactly the way you wanted it!</h3>
                  <p>on stop for custimized merchandise get your t_shirt and hoodies customized </p>
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        <section className="motivation-products">
          <Container>
            <Row>
              <Col md={12}>
                <div className="text-center">
                  <h3>Exactly the way you wanted it!</h3>
                  <p>
                    AlmaBay one stop shop for customized merchandise Get your t-shirts and hoodies
                    customized
                  </p>
                </div>
              </Col>
              {/* <Col sm={2} md={4} lg={3}>
                            <Card className='mb-3 mb-lg-0'>
                                <img src={require('../../../assets/images/motivational-img1.png')} alt="img" />
                                <Card.Body>
                                    <h4 className='product-name'>
                                        Good Vs Great Satluj Heritage Association - Mug
                                    </h4>
                                    <p className='price'>
                                        ₹ 250.00
                                    </p>
                                    <ul className='rating'>
                                        <li><i className="fas fa-star"></i></li>
                                        <li><i className="fas fa-star"></i></li>
                                        <li><i className="fas fa-star"></i></li>
                                        <li><i className="fas fa-star"></i></li>
                                        <li><i className="fas fa-star"></i></li>
                                    </ul>
                                </Card.Body>
                            </Card>
                        </Col>
                     */}
              {list && list?.length ? (
                list?.map((data, index) => (
                  <Col key={index} sm={2} md={4} lg={3}>
                    <Card className="mb-3 mb-lg-0 h-100 productouter-card">
                      <div className="productimage">
                        <img
                          className="pointer"
                          role="presentation"
                          onClick={() => {
                            navigate(`/shop/details/${data?.id}`);
                          }}
                          src={
                            process.env.REACT_APP_API_URL +
                            'common/uploads/products/' +
                            data?.cover_image
                          }
                          alt="img"
                        />
                      </div>
                      <Card.Body>
                        <h4 className="product-name">{data?.name}</h4>
                        <p className="price">
                          ₹ {data?.price}
                          {/* <del className='ms-2'>₹ 199.00</del> <span className='discount ms-3'>33%</span> */}
                        </p>
                        <ul className="rating">
                          <li>
                            <i className="fas fa-star"></i>
                          </li>
                          <li>
                            <i className="fas fa-star"></i>
                          </li>
                          <li>
                            <i className="fas fa-star"></i>
                          </li>
                          <li>
                            <i className="fas fa-star"></i>
                          </li>
                          <li>
                            <i className="fas fa-star"></i>
                          </li>
                        </ul>
                      </Card.Body>
                    </Card>
                  </Col>
                ))
              ) : (
                <Col sm={2} md={4} lg={3}>
                  <div className="text-center nodata">
                    <img src={require('../../../assets/images/nodata.png')} alt="" />
                    <p>Data Not Found</p>
                  </div>
                </Col>
              )}
            </Row>
          </Container>
        </section>
      </div>
      {loading ? <Loader /> : <></>}
    </>
  );
}

export default MotivationalMemorabilia;
