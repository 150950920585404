import { YOUTUBE_VIDEO_FAILED, YOUTUBE_VIDEO_PENDING, YOUTUBE_VIDEO_SUCCESS } from '../action';

const initialState = {
  videos: [],
  isPending: false,
  hasError: ''
};

export const youtubeVideoReducer = (state = initialState, action) => {
  switch (action.type) {
    case YOUTUBE_VIDEO_PENDING:
      return {
        ...state,
        isPending: true
      };

    case YOUTUBE_VIDEO_SUCCESS:
      return {
        ...state,
        videos: action.payload,
        isPending: false
      };

    case YOUTUBE_VIDEO_FAILED:
      return {
        ...state,
        videos: [],
        hasError: action.payload
      };

    default:
      return state;
  }
};
