/**
 * @copyright : ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
   @author     : Shiv Charan Panjeta 
  All Rights Reserved.
  Proprietary and confidential :  All information contained herein is, and remains
  the property of ToXSL Technologies Pvt. Ltd. and its partners.
  Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 */

import React, { useEffect, useState } from 'react';
import { base_url, getMedia, sleepDelay } from '../../../globals/helper';
import * as AuthServices from '../../../services/AuthServices';
import * as chatService from '../../../services/chatService';
var createReactClass = require('create-react-class');
import ReactDOM from 'react-dom/client';
import io from 'socket.io-client';
import { Link } from 'react-router-dom';
let $ = require('jquery');

const ENDPOINT = process.env.REACT_APP_NODE_SOCKET_URL;
const socket = io(ENDPOINT, {
  forceNew: true,
  path: '/chat/socket.io/'
});

function FriendList() {
  const [list, setList] = useState([]);
  const [response, setResponse] = useState('');
  const [isActive, setIsActive] = useState('');
  //const [chatArchive, setChatArchive] = useState('');

  const chatBoxRef = [];

  const [chatBox, setChatBox] = useState([]);
  const rooms = [];
  var chat = [];
  var chatArchive = '';

  useEffect(() => {
    callDefault();
  }, []);

  // API CODE

  const callDefault = () => {
    AuthServices.userChatfollowers().then((res) => {
      if (res?.status === 200) {
        setResponse(res?.data || []);
        setList(res?.data || []);
      }
    });
  };

  /**
   *
   * @param {*} e
   */

  const searchUser = (e) => {
    const updatedList = response.filter((item) => {
      return item?.name?.toLowerCase().search(e.target.value.toLowerCase()) !== -1;
    });

    setList(updatedList);
  };

  const almabayChat = (data, chatArchive, socket) => {
    socket.emit('subscribe', data.room_id);

    var _showMessageDate = function (unixTimeStamp) {
      var date = new Date(unixTimeStamp);
      var hours = date.getHours();
      // minutes part from the timestamp
      var minutes = '0' + date.getMinutes();
      // seconds part from the timestamp
      var seconds = '0' + date.getSeconds();
      // will display time in 10:30:23 format
      return hours + ':' + minutes.substr(-2) + ':' + seconds.substr(-2);
    };

    var Message = createReactClass({
      displayName: 'Message',
      render: function render() {
        return React.createElement(
          'li',
          { className: 'message' },
          React.createElement(
            'div',
            { className: '' },
            React.createElement(
              'div',
              { className: '' },
              React.createElement(
                'div',
                { className: '' },
                React.createElement(
                  'ul',
                  { className: '' },
                  React.createElement(
                    'li',
                    { className: 'singleMessageList' },
                    React.createElement(
                      'div',
                      { className: 'userLeft' },
                      React.createElement(
                        'span',
                        { className: 'userLeft' },
                        this.props.user + ': '
                      ),
                      React.createElement(
                        'span',
                        { className: 'messageRight' },
                        this.props.text,
                        React.createElement(
                          'span',
                          { className: this.props.status + ' fa messageStatus' },
                          React.createElement(
                            'span',
                            { className: 'time' },
                            _showMessageDate(this.props.when)
                          )
                        )
                      )
                    )
                  )
                )
              )
            )
          )
        );
      }
    });
    var MessageList = createReactClass({
      displayName: 'MessageList',
      componentDidUpdate: function componentDidUpdate() {
        // During send message - scroll Up
        var text_chat_container = $('div#' + data.room_id + ' ul.messages');
        text_chat_container.scrollTop(text_chat_container.prop('scrollHeight'));
      },
      render: function render() {
        return React.createElement(
          'ul',
          { className: 'messages' },
          this.props?.messages &&
            this.props?.messages?.length > 0 &&
            this.props.messages?.map(function (message, i) {
              if (message.room_id == data.room_id) {
                return React.createElement(Message, {
                  key: i,
                  user: message.user,
                  text: message.message,
                  status: message.status,
                  when: message.date
                });
              }
            })
        );
      }
    });
    var MessageForm = createReactClass({
      displayName: 'MessageForm',
      getInitialState: function getInitialState() {
        return { text: '' };
      },
      handleSubmit: function handleSubmit(e) {
        e.preventDefault();
        var message = {
          user: this.props.user,
          text: this.state.text
        };
        this.props.onMessageSubmit(message);
        this.setState({ text: '' });
      },
      changeHandler: function changeHandler(e) {
        this.setState({ text: e.target.value });
      },
      render: function render() {
        return React.createElement(
          'div',
          { className: 'chatBoxCommentForm' },
          React.createElement(
            'form',
            { onSubmit: this.handleSubmit },
            React.createElement('input', {
              className: 'form-control',
              onChange: this.changeHandler,
              value: this.state.text
            })
          )
        );
      }
    });

    var ChatApp = createReactClass({
      displayName: 'ChatApp',
      getInitialState: function getInitialState() {
        return { users: [], messages: chatArchive, text: '' };
      },
      componentDidMount: function componentDidMount() {
        socket.on('send:message', this._messageRecieve);
        socket.on('user:join', this._userJoined);
        socket.on('user:left', this._userLeft);
        socket.on('change:name', this._userChangedName);
      },
      _initialize: function _initialize(data) {
        var users = data.users;
        var name = data.name;

        this.setState({ users: users, user: name });
      },
      _messageRecieve: function _messageRecieve(message) {
        var messages = this.state.messages ? this.state.messages : [];
        messages.push(message);
        this.setState({ messages: messages });
      },
      _userJoined: function _userJoined(data) {
        var _state = this.state;
        var users = _state.users;
        var messages = _state.messages;
        var name = data.name;
        users.push(name);
        messages.push({
          user: 'APLICATION BOT',
          text: name + ' Joined'
        });
        this.setState({ users: users, messages: messages });
      },
      _userLeft: function _userLeft(data) {
        var _state2 = this.state;
        var users = _state2.users;
        var messages = _state2.messages;
        var name = data.name;
        var index = users.indexOf(name);
        users.splice(index, 1);
        messages.push({
          user: 'APLICATION BOT',
          text: name + ' Left'
        });
        this.setState({ users: users, messages: messages });
      },
      _userChangedName: function _userChangedName(data) {
        var oldName = data.oldName;
        var newName = data.newName;
        var _state3 = this.state;
        var users = _state3.users;
        var messages = _state3.messages;
        var index = users.indexOf(oldName);
        users.splice(index, 1, newName);
        messages.push({
          user: 'APLICATION BOT',
          text: 'Change Name : ' + oldName + ' ==> ' + newName
        });
        this.setState({ users: users, messages: messages });
      },
      handleMessageSubmit: function handleMessageSubmit(message) {
        if (message.text.trim() !== '') {
          var d = new Date();
          var n = d.getTime();
          socket.emit('send', {
            room_id: data.room_id,
            user: data.current_user,
            id: data.id,
            message: message.text,
            date: n,
            status: 'sent',
            type: 'text',
            chat_in: 'one_to_one'
          });
        }
      },
      handleChangeName: function handleChangeName(newName) {
        var _this = this;
        var oldName = this.state.user;
        socket.emit('change:name', { name: newName }, function (result) {
          if (!result) {
            return alert('There was an error changing your name');
          }
          var users = _this.state.users;
          var index = users.indexOf(oldName);
          users.splice(index, 1, newName);
          _this.setState({ users: users, user: newName });
        });
      },
      render: function render() {
        return React.createElement(
          'div',
          null,
          React.createElement(MessageList, {
            messages: this.state.messages
          }),
          React.createElement(MessageForm, {
            onMessageSubmit: this.handleMessageSubmit,
            user: this.state.user
          })
        );
      }
    });

    const rootChatBox = ReactDOM?.createRoot(document?.getElementById(data?.room_id));
    if (rootChatBox) {
      rootChatBox.render(React.createElement(ChatApp, null));
    }
    //ReactDOM.createRoot(React.createElement(ChatApp, null), document.getElementById(data.room_id));
  };

  // Remove Chat box

  // const removeItem = (index) => {
  //   console.log('chatBox?.length', chatBox?.length)
  //   if (chatBox?.length > 0) {
  //     setChatBox([...chatBox?.slice(0, index), ...chatBox.slice(index + 1)]);
  //   }
  //   else{
  //     console.log("else ")
  //   }
  // };
  const removeItem = (index) => {
    setTimeout(() => {
      if (chatBox?.length > 0) {
        const filteredChatBox = chatBox.filter((_, idx) => idx !== index);
        setChatBox(filteredChatBox);
      }
    }, 100);
  };

  // Open Chat box

  const addChatBox = (id) => {
    if (!chatBoxRef.length) {
      socket.emit('subscribe', id.room_id);

      chatBoxRef.push(id);

      rooms.push(id.room_id);

      chat = chatService.list(id.room_id);
      chat
        .then(
          function (data) {
            chatArchive = data?.data;
          },
          function () {}
        )
        .finally(async function () {
          almabayChat(id, chatArchive, socket);

          await sleepDelay(2000);

          if (chatArchive != '') {
            var wtf = $('div#' + id.room_id + ' ul.messages');

            if (wtf) {
              //var height = wtf[0].scrollHeight;
              var text_chat_container = wtf;
              text_chat_container.scrollTop(text_chat_container.prop('scrollHeight'));
              //$('div#' + id.room_id + ' ul.messages').scrollTop(height);
            }
          }
        });
    } else {
      if (rooms.indexOf(id.room_id) == -1) {
        chatBoxRef.push(id);
        rooms.push(id.room_id);
        chat = chatService.list(id.room_id);
        chat
          .then(
            function (data) {
              chatArchive = data;
            },
            function () {}
          )
          .finally(async function () {
            almabayChat(id, chatArchive, socket);

            await sleepDelay(2000);

            var wtf = $('div#' + id.room_id + ' ul.messages');

            if (wtf) {
              var text_chat_container = wtf;
              text_chat_container.scrollTop(text_chat_container.prop('scrollHeight'));
            }
          });
      }
    }

    if (!chatBox.find((item) => item.id == id.id)) {
      setChatBox([...chatBox, id]);
    }
  };

  return (
    <>
      <div className="sidebar chat-side-bar" id="chat">
        <ul id="tab-list">
          <li id="user-tab" className={`tab ${isActive ? 'active' : 'inactive'}`}>
            <div className="container">
              <div className="row row-header">
                <div className="header" role="presentation" onClick={() => setIsActive(!isActive)}>
                  <div className="content">Chat</div>
                </div>
              </div>
              <div className="row row-body">
                <div className="body">
                  <div className="content">
                    <ul id="user-list" className="users">
                      {list && list?.length > 0 ? (
                        list?.map((data, index) => {
                          return (
                            <>
                              <li key={index}>
                                <Link
                                  to={'javascript:void(0)'}
                                  onClick={() => addChatBox(data)}
                                  title={data?.name}>
                                  <div className="chat-user d-flex align-items-center justify-content-between">
                                    <div className="chat-user-detail d-flex align-items-center">
                                      <div className="chat-icn me-2">
                                        <img
                                          src={getMedia(data?.userInfo?.mediaObject)?.url}
                                          alt="chatuser"
                                          onError={({ currentTarget }) => {
                                            currentTarget.onerror = null; // prevents looping
                                            currentTarget.src = require('../../../assets/images/birth4.png');
                                          }}
                                        />
                                      </div>
                                      <div className="chat-user-dis">
                                        <h4> {data?.name} </h4>
                                      </div>
                                    </div>
                                    <span className={'chat-active ' + data?.current_status}></span>
                                  </div>
                                </Link>
                              </li>
                            </>
                          );
                        })
                      ) : (
                        <>You don't have any friends at the moment.</>
                      )}
                    </ul>
                  </div>
                </div>
              </div>
              <div className="row row-footer">
                <div className="footer">
                  <div className="content">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search"
                      onChange={searchUser}
                    />
                  </div>
                </div>
              </div>
            </div>
          </li>

          {chatBox?.length > 0 ? (
            chatBox?.map((box, index) => (
              <li className="tab" key={index}>
                <div className="chat-container container">
                  <div className="row row-header">
                    <div className="header">
                      <div className="chat-name">
                        <a href={base_url() + 'user/timeline/' + box.id}>{box?.name}</a>
                      </div>
                      <div className="chat-close">
                        <span role="button" tabIndex={0} onClick={() => removeItem(index)}>
                          <i className="fa fa-times"></i>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="row row-body">
                    <div className="body">
                      <div className="" id={box?.room_id}></div>
                    </div>
                  </div>
                </div>
              </li>
            ))
          ) : (
            <div></div>
            // <></>
          )}
        </ul>
      </div>
    </>
  );
}

export default FriendList;
