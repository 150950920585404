import { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link } from 'react-router-dom';

function ApplyForm(props) {
  const {
    onChangeHandler,
    show2,
    handleClose2,
    fileSizeValid,
    resumeSelect,
    jobTitle,
    applyForm,
    submitHandler,
    isChecked,
    setIsChecked,
    getUserDetail,
    getNetworkJob
  } = props;

  const [clicked, setClicked] = useState(false);

  const validate = (values) => {
    let errors = {};
    if (!values.subject.trim()) {
      errors.subject = 'This field is required';
    }
    if (!values.message.trim()) {
      errors.message = 'This field is required';
    }
    if (isChecked && !resumeSelect) {
      errors.resume = 'Please attach your latest resume.';
    }
    return errors;
  };

  const ApllyHandler = (e) => {
    e.preventDefault();
    setClicked(true);
    if (!Object.keys(validate(applyForm)).length) {
      submitHandler();
      getUserDetail();
      getNetworkJob && getNetworkJob();
    } else {
      return;
    }
  };

  return (
    <>
      <Modal style={{ zIndex: '999999' }} show={show2} onHide={handleClose2}>
        <Modal.Header closeButton>
          <Modal.Title>Apply for '{jobTitle}'</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="apply-card-frm">
            <Form>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Row>
                  <Col md={3}>
                    <Form.Label>To:</Form.Label>
                  </Col>
                  <Col md={9}>
                    <Form.Control
                      type="text"
                      name="to"
                      value={applyForm?.to}
                      placeholder="Gurjit Singh"
                      readonly="readonly"
                    />
                  </Col>
                </Row>
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Row>
                  <Col md={3}>
                    <Form.Label>Subject:</Form.Label>
                  </Col>
                  <Col md={9}>
                    <Form.Control
                      type="text"
                      name="subject"
                      value={applyForm?.subject}
                      placeholder="Job application for Front End Executive"
                      onChange={(e) => {
                        onChangeHandler(e);
                      }}
                    />
                    <div
                      className={`${
                        clicked && validate(applyForm).subject ? '' : 'd-none'
                      } danger-frm `}>
                      {clicked && validate(applyForm).subject}
                    </div>
                  </Col>
                </Row>
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Row>
                  <Col md={3}>
                    <Form.Label>Message:</Form.Label>
                  </Col>
                  <Col md={9}>
                    <Form.Control
                      as="textarea"
                      name="message"
                      placeholder="Write your content here..."
                      onChange={(e) => {
                        onChangeHandler(e);
                      }}
                      rows={3}
                    />
                    <div
                      className={`${
                        clicked && validate(applyForm).message ? '' : 'd-none'
                      } danger-frm `}>
                      {clicked && validate(applyForm).message}
                    </div>
                  </Col>
                </Row>
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Row>
                  <Col md={3}>
                    <Form.Label>Resume:</Form.Label>
                  </Col>
                  <Col md={9}>
                    <div className="resume-upload-card">
                      {['radio'].map((type) => (
                        <div key={`inline-${type}`} className="mb-3">
                          <div className="resume-upload-sec mb-2">
                            <Form.Check
                              label="Attach latest resume"
                              name="group1"
                              type={type}
                              id="attach"
                              onChange={(e) => {
                                setIsChecked(true);
                                onChangeHandler(e, false);
                              }}
                            />
                            {isChecked ? (
                              <div className="upload-resume-frm mt-2">
                                <Form.Group controlId="formFile" className="mb-3">
                                  <Form.Control
                                    type="file"
                                    accept="application/vnd.oasis.opendocument.text,application/*"
                                    name="resume"
                                    onChange={(e) => onChangeHandler(e, false)}
                                  />
                                </Form.Group>
                              </div>
                            ) : (
                              ''
                            )}
                            <p className={`${fileSizeValid ? 'danger-frm' : ''}`}>
                              Max upload size is 500KB
                            </p>
                            {fileSizeValid ? (
                              ''
                            ) : (
                              <div
                                className={`${
                                  clicked && validate(applyForm).resume ? '' : 'd-none'
                                } danger-frm `}>
                                {clicked && validate(applyForm).resume}
                              </div>
                            )}
                          </div>
                          <div className="resume-upload-sec d-flex align-items-center mb-2">
                            <Form.Check
                              label="Use"
                              name="group1"
                              type={type}
                              id={`inline-${type}-2`}
                              defaultChecked
                              onChange={(e) => {
                                setIsChecked(false);
                                onChangeHandler(e, true);
                              }}
                            />
                            <Link className="ms-2" to="#">
                              Resume-card
                            </Link>
                          </div>
                        </div>
                      ))}
                    </div>
                  </Col>
                </Row>
              </Form.Group>
              <div className="upload-resume-btn text-center">
                <button className="accnt-btn" type="button" onClick={ApllyHandler}>
                  <i className="fas fa-check me-1"></i>Apply Now
                </button>
                <button className="wht-btn txt-transfrm  ms-2" type="button" onClick={handleClose2}>
                  <i className="far fa-times-circle me-1"></i>Cancel
                </button>
              </div>
            </Form>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ApplyForm;
