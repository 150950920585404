import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import CloseButton from 'react-bootstrap/CloseButton';
import { useEffect, useState } from 'react';
import showNotification from '../../../../services/NotificationService';
import { beSponser } from '../../../../services/SocialServices';
import { emailRegex, restrictAlpha } from '../../../../globals/helper';
import { Alert } from 'react-bootstrap';

function CreateEventSponser(props) {
  const { handleClose2, show2, userId } = props;
  const [showalert, setShowAlert] = useState(false);
  const [messageView, setMessageView] = useState('');
  const [sponserDetail, setSponserDetail] = useState({
    name: '',
    email: '',
    contact: '',
    company: '',
    message: ''
  });
  useEffect(() => {
    const timeId = setTimeout(() => {
      setShowAlert(false);
      setMessageView('');
    }, 3000);

    return () => {
      clearTimeout(timeId);
    };
  }, [showalert]);
  const [clicked, setClicked] = useState(false);

  const handleChange = (e) => {
    var { name, value } = e.target;
    setSponserDetail({ ...sponserDetail, [name]: value });
  };

  const validate = () => {
    let errors = {};
    var numberRegex = /^(?:[1-9]\d*|\d)$/;

    if (sponserDetail?.name == '') {
      errors.name = 'This field is required';
    }
    if (sponserDetail?.email == '') {
      errors.email = 'This field is required';
    } else if (!emailRegex.test(sponserDetail?.email)) {
      errors.email = 'Please enter valid email';
    }
    if (sponserDetail?.contact == '') {
      errors.contact = 'This field is required';
    }
    if (sponserDetail?.contact !== '' && sponserDetail?.contact?.length < 8) {
      errors.contact = 'Please enter atleast 7 characters';
    }

    if (sponserDetail?.contact !== '' && !numberRegex.test(sponserDetail?.contact)) {
      errors.contact = 'Please enter valid number';
    }
    return errors;
  };

  const submitHandler = (e) => {
    e.preventDefault();
    setClicked(true);
    if (!Object.keys(validate())?.length) {
      let formData = new FormData();
      formData.append(`event_id`, userId);

      for (let i in sponserDetail) {
        formData.append(`${i}`, sponserDetail[i]);
      }
      beSponser(formData).then((resp) => {
        if (resp?.status === 200) {
          setMessageView(resp?.data?.detail?.message);
          setShowAlert(true);
          setTimeout(() => {
            handleClose2();
          }, 2000);
        } else {
          showNotification('success', resp?.data?.detail?.message);
        }
      });
    }
  };

  return (
    <>
      <Modal show={show2} className="event-add-modal" size="">
        <Alert
          variant={messageView ? 'success' : 'danger'}
          className={`${showalert ? '' : 'd-none'}`}>
          {messageView ? messageView : 'No Update'}
        </Alert>

        <Modal.Body>
          <div className="event-modal-hd d-flex align-items-center justify-content-between ">
            <h4>Request to become a sponsor</h4>
            <div className="event-close">
              <CloseButton onClick={handleClose2} />
            </div>
          </div>
          <div className="event-modal-drop modal-dialog-scrollable">
            <Form>
              <Form.Group className="mb-3" controlId="formBasicEvent">
                <Form.Label> Name</Form.Label>
                <sup className="text-danger">*</sup>
                <Form.Control
                  type="text"
                  placeholder="Enter your name"
                  name="name"
                  onChange={handleChange}
                />
                <div className={'danger-frm'}>{clicked && validate().name}</div>
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Email</Form.Label>
                <sup className="text-danger">*</sup>
                <Form.Control
                  type="email"
                  placeholder="Enter your Email"
                  name="email"
                  onChange={handleChange}
                />
                <div className={'danger-frm'}>{clicked && validate().email}</div>
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicEvent">
                <Form.Label> Contact</Form.Label>
                <sup className="text-danger">*</sup>
                <Form.Control
                  type="text"
                  placeholder="Enter your Contact  number"
                  name="contact"
                  onChange={handleChange}
                  onKeyPress={restrictAlpha}
                  maxLength={10}
                />
                <div className={'danger-frm'}>{clicked && validate().contact}</div>
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicEvent">
                <Form.Label> Company</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter your Company  name"
                  name="company"
                  onChange={handleChange}
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicDiscription">
                <Form.Label>Message</Form.Label>
                <Form.Control
                  as="textarea"
                  placeholder="Write your message"
                  rows={3}
                  name="message"
                  onChange={handleChange}
                />
              </Form.Group>
            </Form>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={submitHandler}>
            Submit
          </Button>

          <Button variant="secondary" onClick={handleClose2}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default CreateEventSponser;
