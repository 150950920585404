import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { truncate } from '../../../globals/helper';
import { getWhatsNew } from '../../../services/NewsServices';
import { useSelector } from 'react-redux';
import { Transition } from 'react-transition-group';
import NoDataFound from '../NoDataFound';

function WhatsNew() {
  const [newList, setNewList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [inProp, setInProp] = useState('');
  const duration = 500;

  useEffect(() => {
    (async function () {
      try {
        setLoading(true);
        const response = await getWhatsNew();
        if (response?.status === 200) {
          setNewList(response?.data?.data);
          setLoading(false);
        }
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    })();
  }, []);

  const handleSkip = (e, id) => {
    e.preventDefault();
    setInProp(id);
    setTimeout(() => {
      let filterNews = newList.filter((data) => data?.id !== id);
      setNewList(filterNews);
    }, 500);
  };
  const isloggedIn = useSelector((state) => state?.user?.isloggedIn);
  const defaultStyle = {
    transition: `transform ${duration}ms ease-out`

    // opacity: 0
  };

  const transitionStyles = {
    entering: { opacity: 0 },
    entered: { opacity: 1 },
    exiting: { transform: 'translateX(-100%)', opacity: 1 },
    exited: { opacity: 0 }
  };

  return (
    <>
      <div className="whats-new-sec bg-white p-3 mb-3" style={{ overflow: 'hidden' }}>
        <div className="d-flex align-items-top justify-content-between flex-wrap">
          <h4>Whats New</h4>

          {!isloggedIn ? (
            <Link className="more-btn" to="#">
              <i className="far fa-calendar-alt me-1 "></i>
              More
            </Link>
          ) : (
            <Link className="more-btn" to="/user/news-page" target="_blank">
              More
            </Link>
          )}
        </div>
        <div className="whats-nw-card">
          <ul className="group-card-lst whats-nw-card-lst">
            {loading &&
              Array.from(Array(3), (index) => {
                return <Shimmer key={index} />;
              })}
            {!loading && newList?.length > 0 ? (
              newList?.slice(0, 3)?.map((data, index) => (
                <li key={index}>
                  <Transition in={inProp == data?.id ? false : true} timeout={300}>
                    {(state) => (
                      <div
                        className="mentor-card discover-card-inr d-flex"
                        style={{
                          ...defaultStyle,
                          ...transitionStyles[state]
                        }}>
                        <Link
                          target="_blank"
                          to={`/news/show/${data?.id}`}
                          className="mentor-icn me-2">
                          <img
                            src={
                              data?.image
                                ? process.env.REACT_APP_API_DOMAIN + data?.image
                                : require('../../../assets/images/default-male-avatar.png')
                            }
                            onError={({ currentTarget }) => {
                              currentTarget.onerror = null; // prevents looping
                              currentTarget.src = require("../../../assets/images/default-male-avatar.png")
                            }}
                            alt="img"
                          />
                        </Link>
                        <div className="mentor-rt-dis">
                          <h4>
                            <Link target="_blank" to={`/news/show/${data?.id}`}>
                              {data?.title}
                            </Link>
                          </h4>
                          <p>{truncate(data?.short_descp, 50)}</p>
                        </div>
                        <Link className="close-btn" to="#" onClick={(e) => handleSkip(e, data?.id)}>
                          <i className="fas fa-times"></i>
                        </Link>
                      </div>
                    )}
                  </Transition>
                </li>
              ))
            ) : (
              <>
                {!loading && newList?.length === 0 && (
                  <NoDataFound text="No more  news for you at the moment." />
                )}
              </>
            )}
          </ul>
        </div>
      </div>
    </>
  );
}

export default WhatsNew;

const Shimmer = () => {
  return (
    <li>
      <div className="mentor-card discover-card-inr whats-nw-loader loading-skeleton d-flex">
        <Link className="mentor-icn me-2">
          <img src={require('../../../assets/images/default-male-avatar.png')} alt="img" />
        </Link>
        <div className="mentor-rt-dis">
          <h4>
            <Link to="#">Job update</Link>
          </h4>
          <p>Updation for job</p>
        </div>
        <Link className="close-btn" to="#">
          <i className="fas fa-times"></i>
        </Link>
      </div>
    </li>
  );
};
