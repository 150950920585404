/**
@copyright  :   ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
@author     :   Shiv Charan Panjeta 
 
All Rights Reserved.
Proprietary and confidential :  All information contained here in is, and remains
the property of ToXSL Technologies Pvt. Ltd. and it's partners.
Unauthorized copying of this file, via any medium is strictly prohibited.
*/
import { useEffect, useState } from 'react';
import { Button, Container } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { detailsManageSponsors } from '../../../services/AuthServices';

export default function ViewSponsorDetails() {
  let location = useLocation();
  const navigate = useNavigate();
  const [details] = useState(location?.state);
  const { event_id, id } = useParams();
  const [detailsState, setStateDetails] = useState();
  useEffect(() => {
    if ((event_id, id)) {
      getPageDetail(event_id, id);
    }
  }, []);

  const getPageDetail = () => {
    detailsManageSponsors(event_id, id).then((res) => {
      if (res?.status === 200) {
        setStateDetails(res?.data?.data?.detail);
      }
    });
  };

  return (
    <div className="dash-main-content">
      <Container fluid>
        <div className="dash-page-content">
          <div className="dash-page-title">
            <Row>
              <Col xs={12} sm={12} md={12}>
                <div className="dash-title-bar d-flex align-items-center justify-content-between">
                  <div className="title-lft d-flex align-items-center">
                    <div className="dash-icn">
                      <i className="fas fa-university"></i>
                    </div>
                    <div className="dash-title-dis">
                      <h5>View Event Sponsor</h5>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <div className="dash-content-card">
            <div className="cmmn-form">
              <Form>
                <Row>
                  <Col md={12}>
                    <Form.Group className="mb-3" controlId="formBasicName">
                      <Form.Label>Sponsor Name </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter sponsor name"
                        name="sponser_name"
                        value={detailsState?.sponser_name}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={12}>
                    <Form.Group className="mb-3" controlId="formBasicName">
                      <Form.Label>Sponsor Image</Form.Label>
                      <Form.Control type="file" name="image" />
                    </Form.Group>
                  </Col>
                </Row>
                <div className="form-btns d-flex align-items-center mt-3">
                  <Button
                    className="main-btn-red"
                    onClick={(e) => {
                      e.preventDefault(), navigate(-1);
                    }}>
                    Back
                  </Button>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}
