/** @copyright : ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
 @author     : Shiv Charan Panjeta 

 All Rights Reserved.
 Proprietary and confidential :  All information contained herein is, and remains
 the property of ToXSL Technologies Pvt. Ltd. and its partners.
 Unauthorized copying of this file, via any medium is strictly prohibited.
*/
import { Component } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

class TinyMceEditor extends Component {
  render() {
    return (
      <CKEditor
        name="description"
        editor={ClassicEditor}
        data={this.props.description}
        config={{
          toolbar: ['bold', 'italic', 'numberedList', 'bulletedList', 'outdent', 'indent'],
          placeholder: this.props.placeholder ? this.props.placeholder : ''
        }}
        onChange={(event, editor) => {
          const data = editor.getData();
          this?.props?.onChangeHandler(event, true, 'description', data);
        }}
      />
    );
  }
}

export default TinyMceEditor;
