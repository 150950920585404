import { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import * as APISERVICES from '../../../services/UserServices';
import Spinner from 'react-bootstrap/Spinner';
import MultiAsynSelect from '../MultiSelectWithAsyn/MultiAsynSelect';
import showNotification from '../../../services/NotificationService';
import { useOnClickOutside } from '../../../globals/helper';
import { useRef } from 'react';
import { CareerFormSimmer } from '../skelton/allskelton';

function CareerForm() {
  const ref = useRef();
  const [seeMentorShip, setSeekMentorShip] = useState([]);
  const [provideMentorShip, setProvideMentorShip] = useState([]);
  const [functionalArea, setfunctionalArea] = useState([]);
  const [industry, setindustry] = useState('');
  const [location, setLocation] = useState([]);
  const [inputMsg, setInputMsg] = useState('Please enter atleast 2 characters');
  const [loadInput, setLoadInput] = useState(false);

  const [showSelects, setShowSelect] = useState({
    seek: false,
    provide: false,
    industry: false,
    functional: false,
    location: false
  });

  const [careerData, setCareerData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(false);

  useOnClickOutside(ref, (e) => submitHandler(e));

  useEffect(() => {
    getCareerData();
  }, []);

  const getCareerData = () => {
    setLoading(true);
    APISERVICES.getCareerSkill().then((res) => {
      setLoading(false);
      if (res?.status === 200) {
        setCareerData(res?.data?.data);

        if (res?.data?.data?.seek_mentorship?.length > 0) {
          setSeekMentorShip(
            res?.data?.data?.seek_mentorship?.map(
              ({ name: label, name: value, id: id, ...args }) => ({
                label,
                value,
                id,
                ...args
              })
            )
          );
        }

        if (res?.data?.data?.provide_mentorship?.length > 0) {
          setProvideMentorShip(
            res?.data?.data?.provide_mentorship?.map(
              ({ name: label, name: value, id: id, ...args }) => ({
                label,
                value,
                id,
                ...args
              })
            )
          );
        }

        if (res?.data?.data?.functional_area?.length > 0) {
          setfunctionalArea(
            res?.data?.data?.functional_area?.map(
              ({ name: label, name: value, id: id, ...args }) => ({
                label,
                value,
                id,
                ...args
              })
            )
          );
        }

        setindustry({
          id: res?.data?.data?.industry?.id,
          label: res?.data?.data?.industry?.name,
          value: res?.data?.data?.industry?.name
        });

        if (res?.data?.data?.location_id?.length) {
          setLocation(
            res?.data?.data?.location_id?.map(({ city: label, city: value, id: id, ...args }) => ({
              label,
              value,
              id,
              ...args
            }))
          );
        }
      }
    });
  };
  const loadSeekMentoOptions = (inputValue) => {
    return APISERVICES.getMentorShipDrop(inputValue).then((res) => {
      if (res?.status === 200) {
        let tempArr = [];
        if (res?.data?.data) {
          const data = res?.data?.data;
          data?.map((data) => {
            tempArr.push({
              id: data?.id,
              label: data?.name,
              value: data?.name
            });
          });
        }
        return tempArr;
      }
    });
  };

  const loadIndustryOptions = (inputValue) => {
    return APISERVICES.getIndustryDrop(inputValue).then((res) => {
      if (res?.status === 200) {
        let tempArr = [];
        if (res?.data?.data) {
          const data = res?.data?.data;
          data?.map((data) => {
            tempArr.push({
              id: data?.id,
              label: data?.name,
              value: data?.name
            });
          });
        }
        return tempArr;
      }
    });
  };

  const loadFunctionalAreaOptions = (inputValue) => {
    return APISERVICES.getFunctionalAreaDrop(inputValue).then((res) => {
      if (res?.status === 200) {
        let tempArr = [];
        if (res?.data?.data) {
          const data = res?.data?.data;
          data?.map((data) => {
            tempArr.push({
              id: data?.id,
              label: data?.name,
              value: data?.name
            });
          });
        }
        return tempArr;
      }
    });
  };
  const loadJobLocationOptions = async (inputValue = 'Punjab, India') => {
    if (inputValue?.length >= 2) {
      setLoadInput(true);
      return APISERVICES.citiesDropDown(inputValue).then((res) => {
        setLoadInput(false);
        if (res?.status === 200) {
          let tempArr = [];
          if (res?.data?.data) {
            const data = res?.data?.data;
            data?.map((data) => {
              tempArr.push({
                id: data?.city_id,
                label: data?.city,
                value: data?.city
              });
            });
          }
          if (res?.data?.data == '') {
            setInputMsg('No matches found');
          }
          return tempArr;
        }
      });
    }
  };

  const validation = () => {
    if (
      showSelects?.seek !== false &&
      careerData?.seek_mentorship?.length !== seeMentorShip?.length
    ) {
      return true;
    }
    if (
      showSelects?.functional !== false &&
      careerData?.functional_area?.length !== functionalArea?.length
    ) {
      return true;
    }
    if (showSelects?.industry !== false && careerData?.industry?.id !== industry?.id) {
      return true;
    }
    if (
      showSelects?.provide !== false &&
      careerData?.provide_mentorship?.length !== provideMentorShip?.length
    ) {
      return true;
    }
    if (showSelects?.location !== false) {
      return true;
    }
    return false;
  };

  const submitHandler = (e) => {
    e.preventDefault();
    setIsLoading(false);
    if (validation()) {
      let detail = {
        industry_id: industry?.id,
        functional_area_id:
          functionalArea?.length > 0
            ? functionalArea
              ?.map((data) => data?.id)
              .join(',')
              .toString()
            : '',
        location_id:
          location?.length > 0
            ? location
              ?.map((data) => data.id)
              .join(',')
              .toString()
            : '',
        seek_mentorship_in:
          seeMentorShip?.length > 0
            ? seeMentorShip
              ?.map((data) => data?.id)
              .join(',')
              .toString()
            : '',
        provide_mentorship_in:
          provideMentorShip?.length > 0
            ? provideMentorShip
              .map((data) => data?.id)
              .join(',')
              .toString()
            : ''
      };
      let formData = new FormData();
      for (let i in detail) {
        formData.append(`${i}`, detail[i]);
      }

      APISERVICES.addUpdateCareerInfo(formData).then((res) => {
        if (res?.status === 200) {
          setIsLoading(false);
          setShowSelect({
            seek: false,
            provide: false,
            industry: false,
            functional: false,
            location: false
          });
          getCareerData();
          showNotification('success', res?.data?.data);
        }
      });
    } else {
      setIsLoading(false);
      setShowSelect({
        seek: false,
        provide: false,
        industry: false,
        functional: false,
        location: false
      });
    }
  };

  const handleShowSelect = (e, key) => {
    e.preventDefault();
    const updatedShowSelect = Object.entries(showSelects).reduce((item, [currKey]) => {
      if (currKey === key) {
        item[currKey] = true;
      } else {
        item[currKey] = false;
      }

      return item;
    }, {});
    setShowSelect(updatedShowSelect);
  };

  const handleCloseSelect = (e) => {
    e.preventDefault();
    setShowSelect({
      seek: false,
      provide: false,
      industry: false,
      functional: false,
      location: false
    });
  };

  return (
    <>
      <div className="detail_form_div" ref={ref}>
        <form className="contact-details" id="">
          <h2>Career Goals</h2>
          <div>
            {loading ? (
              <>
                <CareerFormSimmer />
                <CareerFormSimmer />
                <CareerFormSimmer />
                <CareerFormSimmer />
                <CareerFormSimmer />
              </>
            ) : (
              <>
                <Row className={showSelects?.seek == true ? 'form-group active' : 'form-group'}>
                  <Col sm={10} xs={10}>
                    <label className="" htmlFor="firstname">
                      I seek Mentorship In
                    </label>
                  </Col>
                  <Col sm={2} xs={2}>
                    <span className="green-frm-check d-none">
                      <i className="fas fa-check"></i>
                    </span>

                    <Link
                      to=""
                      title="Cancel"
                      className="me-2 cross_btn"
                      onClick={(e) => handleCloseSelect(e)}>
                      <i className="fas fa-times"></i>
                    </Link>
                    <Link to="" title="Save" className="me-2 correct_btn" onClick={submitHandler}>
                      {isLoading ? (
                        <i className="fas fa-spinner fa-spin"></i>
                      ) : (
                        <i className="fas fa-check"></i>
                      )}
                    </Link>
                    <Link to="" onClick={(e) => handleShowSelect(e, 'seek')}>
                      <i className="fa fa-chevron-right" title="Edit"></i>
                    </Link>
                  </Col>
                  <Col sm={12} xs={12} onClick={(e) => handleShowSelect(e, 'seek')}>
                    {showSelects?.seek == false && (
                      <>
                        {seeMentorShip?.length !== 0 ? (
                          <div className="card-frm-detail">
                            <p>
                              {' '}
                              {seeMentorShip
                                ?.map((data) => {
                                  return data?.label;
                                })
                                ?.join(',')}
                            </p>
                          </div>
                        ) : (
                          <div className="card-frm-detail">
                            <p> Add Areas Where you seek...</p>
                          </div>
                        )}
                      </>
                    )}
                  </Col>
                  {showSelects?.seek && (
                    <Col sm={12} xs={12}>
                      <MultiAsynSelect
                        isMulti={true}
                        value={seeMentorShip}
                        setValue={setSeekMentorShip}
                        loadOptions={loadSeekMentoOptions}
                      />
                    </Col>
                  )}
                </Row>
                <Row className={showSelects?.provide == true ? 'form-group active' : 'form-group'}>
                  <Col sm={10} xs={8}>
                    <label className="" htmlFor="firstname">
                      I provide Mentorship In
                    </label>
                  </Col>
                  <Col sm={2} xs={4}>
                    <Spinner animation="border" className="d-none" size="sm" variant="warning" />
                    <span className="green-frm-check d-none">
                      <i className="fas fa-check"></i>
                    </span>

                    <Link
                      to=""
                      title="Cancel"
                      className="me-2 cross_btn"
                      onClick={(e) => handleCloseSelect(e)}>
                      <i className="fas fa-times"></i>
                    </Link>
                    <Link to="" title="Save" className="me-2 correct_btn" onClick={submitHandler}>
                      {isLoading ? (
                        <i className="fas fa-spinner fa-spin"></i>
                      ) : (
                        <i className="fas fa-check"></i>
                      )}
                    </Link>
                    <Link to="" onClick={(e) => handleShowSelect(e, 'provide')}>
                      <i className="fa fa-chevron-right" title="Edit"></i>
                    </Link>
                  </Col>
                  <Col sm={12} xs={12} onClick={(e) => handleShowSelect(e, 'provide')}>
                    {showSelects?.provide == false && (
                      <>
                        {provideMentorShip?.length !== 0 ? (
                          <div className="card-frm-detail">
                            <p>
                              {' '}
                              {provideMentorShip
                                ?.map((data) => {
                                  return data?.label;
                                })
                                ?.join(',')}
                            </p>
                          </div>
                        ) : (
                          <>
                            {' '}
                            <div className="card-frm-detail">
                              <p> Add Areas Where you provid...</p>
                            </div>
                          </>
                        )}
                      </>
                    )}
                  </Col>
                  {showSelects?.provide && (
                    <Col sm={12} xs={12}>
                      <MultiAsynSelect
                        isMulti={true}
                        value={provideMentorShip}
                        setValue={setProvideMentorShip}
                        loadOptions={loadSeekMentoOptions}
                      />
                    </Col>
                  )}
                </Row>



                <Row className={showSelects?.industry == true ? 'form-group active' : 'form-group'}>
                  <Col sm={10} xs={10}>
                    <label className="" htmlFor="firstname">
                      Industry
                    </label>
                  </Col>
                  <Col sm={2} xs={2}>
                    <Spinner animation="border" className="d-none" size="sm" variant="warning" />
                    <span className="green-frm-check d-none">
                      <i className="fas fa-check"></i>
                    </span>

                    <Link
                      to=""
                      title="Cancel"
                      className="me-2 cross_btn"
                      onClick={(e) => handleCloseSelect(e)}>
                      <i className="fas fa-times"></i>
                    </Link>
                    <Link to="" title="Save" className="me-2 correct_btn" onClick={submitHandler}>
                      {isLoading ? (
                        <i className="fas fa-spinner fa-spin"></i>
                      ) : (
                        <i className="fas fa-check"></i>
                      )}
                    </Link>
                    <Link to="" onClick={(e) => handleShowSelect(e, 'industry')}>
                      <i className="fa fa-chevron-right" title="Edit"></i>
                    </Link>
                  </Col>
                  <Col sm={12} xs={12} onClick={(e) => handleShowSelect(e, 'industry')}>
                    {showSelects?.industry == false && (
                      <>
                        {industry.label !== undefined ? (
                          <div className="card-frm-detail">
                            <p> {industry?.label}</p>
                          </div>
                        ) : (
                          <div className="card-frm-detail">
                            <p>Select Industry.</p>
                          </div>
                        )}
                      </>
                    )}
                  </Col>
                  {showSelects?.industry && (
                    <Col sm={12} xs={12}>
                      <MultiAsynSelect
                        isMulti={false}
                        value={industry}
                        setValue={setindustry}
                        loadOptions={loadIndustryOptions}
                      />
                    </Col>
                  )}
                </Row>

                
                <Row
                  className={showSelects?.functional == true ? 'form-group active' : 'form-group'}>
                  <Col sm={10} xs={10}>
                    <label className="" htmlFor="firstname">
                      Functional Area
                    </label>
                  </Col>
                  <Col sm={2} xs={2}>
                    <Spinner animation="border" className="d-none" size="sm" variant="warning" />
                    <span className="green-frm-check d-none">
                      <i className="fas fa-check"></i>
                    </span>

                    <Link
                      to=""
                      title="Cancel"
                      className="me-2 cross_btn"
                      onClick={(e) => handleCloseSelect(e)}>
                      <i className="fas fa-times"></i>
                    </Link>
                    <Link to="" title="Save" className="me-2 correct_btn" onClick={submitHandler}>
                      {isLoading ? (
                        <i className="fas fa-spinner fa-spin"></i>
                      ) : (
                        <i className="fas fa-check"></i>
                      )}
                    </Link>
                    <Link to="" onClick={(e) => handleShowSelect(e, 'functional')}>
                      <i className="fa fa-chevron-right" title="Edit"></i>
                    </Link>
                  </Col>
                  <Col sm={12} xs={12} onClick={(e) => handleShowSelect(e, 'functional')}>
                    {showSelects?.functional == false && (
                      <>
                        {functionalArea?.length !== 0 ? (
                          <div className="card-frm-detail">
                            <p>
                              {' '}
                              {functionalArea
                                ?.map((data) => {
                                  return data?.label;
                                })
                                ?.join(',')}
                            </p>
                          </div>
                        ) : (
                          <>
                            {' '}
                            <div className="card-frm-detail">
                              <p>Select functional area.</p>
                            </div>
                          </>
                        )}
                      </>
                    )}
                  </Col>
                  {showSelects?.functional && (
                    <Col sm={12} xs={12}>
                      <MultiAsynSelect
                        isMulti={true}
                        value={functionalArea}
                        setValue={setfunctionalArea}
                        loadOptions={loadFunctionalAreaOptions}
                      />
                    </Col>
                  )}
                </Row>

                <Row className={showSelects?.location == true ? 'form-group active' : 'form-group'}>
                  <Col sm={10} xs={10}>
                    <label className="" htmlFor="firstname">
                      Preferred job location
                    </label>
                  </Col>
                  <Col sm={2} xs={2}>
                    <Spinner animation="border" className="d-none" size="sm" variant="warning" />
                    <span className="green-frm-check d-none">
                      <i className="fas fa-check"></i>
                    </span>

                    <Link
                      to=""
                      title="Cancel"
                      className="me-2 cross_btn"
                      onClick={(e) => handleCloseSelect(e)}>
                      <i className="fas fa-times"></i>
                    </Link>
                    <Link to="" title="Save" className="me-2 correct_btn" onClick={submitHandler}>
                      {isLoading ? (
                        <i className="fas fa-spinner fa-spin"></i>
                      ) : (
                        <i className="fas fa-check"></i>
                      )}
                    </Link>
                    <Link to="" onClick={(e) => handleShowSelect(e, 'location')}>
                      <i className="fa fa-chevron-right" title="Edit"></i>
                    </Link>
                  </Col>
                  <Col sm={12} xs={12} onClick={(e) => handleShowSelect(e, 'location')}>
                    {showSelects?.location == false ? (
                      <>
                        {location ? (
                          <div className="card-frm-detail">
                            <p>
                              {' '}
                              {location
                                ?.map((data) => {
                                  return data?.label;
                                })
                                ?.join(' | ')}
                            </p>
                          </div>
                        ) : (
                          <div className="card-frm-detail">
                            <p>Add prefered locations</p>
                          </div>
                        )}
                      </>
                    ) : (
                      <>
                        <MultiAsynSelect
                          isClearable
                          isMulti={true}
                          value={location}
                          setValue={setLocation}
                          loadOptions={loadJobLocationOptions}
                          setInputMsg={setInputMsg}
                          inputMsg={inputMsg}
                        />
                        {loadInput && (
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'row-reverse',
                              marginTop: '-26px'
                            }}>
                            <i className="fa fa-spinner fa-pulse me-2"></i>
                          </div>
                        )}
                      </>
                    )}
                  </Col>
                </Row>
              </>
            )}
          </div>
        </form>
      </div>
    </>
  );
}

export default CareerForm;
