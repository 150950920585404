/**
@copyright  :   ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
@author     :   Shiv Charan Panjeta 
 
All Rights Reserved.
Proprietary and confidential :  All information contained here in is, and remains
the property of ToXSL Technologies Pvt. Ltd. and it's partners.
Unauthorized copying of this file, via any medium is strictly prohibited.
*/

import { useEffect, useState } from 'react';
import { Button, Container } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { useLocation, useNavigate } from 'react-router-dom';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import Loader from '../../../components/commoncomponents/loader/loader-large';
import useDocumentTitle from '../../../globals/useDocumentTitle';
import * as adminServices from '../../../services/AuthServices';
import showNotification from '../../../services/NotificationService';
import { loadAllInstituteOptions, loadJObLocationOption } from '../../AdminHelper';
import { useSelector } from 'react-redux';

export default function AddChapter() {
  useDocumentTitle('Add New Chapter | AlmaBay');
  const location = useLocation();
  const id = location.state;
  const [jobLocation, setJobLocation] = useState('');

  useEffect(() => {
    getAdminList();
    if (id) {
      getDetail(id);
    }
  }, []);

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [insId, setInsId] = useState('');
  const [adminId, setAdminId] = useState('');
  const [adminOption, setAdminOption] = useState([]);
  const [clicked, setClicked] = useState(false);
  const userDetail = useSelector((state) => state?.user?.details);
  const [locationSearch, setLocationSearch] = useState('');
  const [detail, setDetail] = useState({
    group_name: '',
    group_about: '',
    institute_id: '',
    group_admin: '',
    group_verified: '',
    chapter_privacy: ''
  });

  const citiesById = (id) => {
    adminServices.getJobLocationCities(id).then((resp) => {
      setJobLocation({
        id: resp?.data?.data[0]?.city_id,
        label: resp?.data?.data[0]?.city,
        value: resp?.data?.data[0]?.city
      });
    });
  };

  const getAdminList = () => {
    adminServices.getPageAdminListDrop().then((res) => {
      if (res?.status === 200) {
        const data = res?.data?.message?.users?.map((items) => {
          return {
            label: items?.name,
            value: items?.id
          };
        });
        setAdminOption(data);
      }
    });
  };

  const validate = () => {
    let error = {};
    if (detail?.group_name == '') {
      error.group_name = 'Please enter chapter name';
    }
    if (detail?.institute_id == '' && !userDetail?.institute_id) {
      error.institute_id = 'Please select institute';
    }
    if (detail?.group_admin == '') {
      error.group_admin = 'Please select chapter admin';
    }
    if (detail?.group_verified == '') {
      error.group_verified = 'Please select verify chapter';
    }
    if (detail?.chapter_privacy == '') {
      error.chapter_privacy = 'Please select chapter privacy';
    }
    return error;
  };

  const handleChange = (e, name = '') => {
    if (name) {
      setDetail({
        ...detail,
        [name]: e.value
      });
    } else {
      const { name, value } = e.target;
      setDetail({
        ...detail,
        [name]: value
      });
    }
  };

  const handleSave = (e) => {
    e.preventDefault();
    setClicked(true);
    if (!Object.keys(validate()).length) {
      if (userDetail?.institute_id) {
        detail.institute_id = userDetail?.institute_id;
      }
      setLoading(true);
      adminServices.addSocialChapter(detail).then((res) => {
        if (res?.status === 200) {
          setLoading(false);
          showNotification('success', res?.data?.message);
          navigate('/admin/chapters');
        }
      });
    }
  };

  const getDetail = () => {
    setLoading(true);
    adminServices.userChapterDetail(id).then((res) => {
      setLoading(false);
      if (res?.status === 200) {
        const data = res?.data?.data?.group;
        setDetail({
          id: id,
          group_name: data?.name,
          group_about: data?.about,
          institute_id: data?.institue_id,
          group_verified: data?.verified,
          group_admin: data?.admin_ids?.map((data) => data)?.join(),
          chapter_privacy: data?.chapter_privacy,
          location: data?.location
        });
        if (data?.location) {
          citiesById(data?.location);
        }
        setInsId({
          id: data?.institue_id,
          label: data?.institute_name,
          value: data?.institute_name
        });
        if (data?.admin_ids) {
          const ids = [];
          data?.admin_ids?.map((id) => {
            ids.push(id);
          });
          getSelectedAdmin(ids);
        }
      }
    });
  };

  const getSelectedAdmin = (id) => {
    adminServices.getAdminById(id).then((res) => {
      const arr = [];
      if (res?.status === 200) {
        let data = res?.data?.data;
        data?.map((data) => {
          arr.push({ label: data?.name, value: data?.id });
        });
      }
      setAdminId(arr);
    });
  };

  const updateHandler = (e) => {
    e.preventDefault();
    setClicked(true);
    if (!Object.keys(validate(detail)).length) {
      setLoading(true);
      adminServices.updateChapter(detail).then((res) => {
        setLoading(false);
        if (res?.status === 200) {
          showNotification('success', res?.data?.message);
          navigate(-1);
        }
      });
    }
  };

  return (
    <>
      <div className="dash-main-content">
        <Container fluid>
          <div className="dash-page-content">
            <div className="dash-page-title">
              <Row>
                <Col xs={12} sm={12} md={12}>
                  <div className="dash-title-bar d-flex align-items-center justify-content-between">
                    <div className="title-lft d-flex align-items-center">
                      <div className="dash-icn">
                        <i className="fas fa-users"></i>
                      </div>
                      <div className="dash-title-dis">
                        {id ? <h5>Update Chapter</h5> : <h5>Add Chapter</h5>}
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="dash-content-card">
              <div className="cmmn-form">
                <Form>
                  <Row>
                    <Col md={12}>
                      <Form.Group className="mb-3" controlId="formBasicName">
                        <Form.Label>
                          Name <sup className="text-danger">*</sup>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Name of your Chapter"
                          name="group_name"
                          value={detail?.group_name}
                          onChange={handleChange}
                        />
                        <div className={'danger-frm'}>{clicked && validate()?.group_name}</div>
                      </Form.Group>
                    </Col>

                    <Col md={12}>
                      <Form.Group className="mb-3" controlId="formBasicDesi">
                        <Form.Label>About </Form.Label>
                        <Form.Control
                          as="textarea"
                          placeholder="Write about your chapter..."
                          rows={3}
                          value={detail?.group_about}
                          name="group_about"
                          onChange={handleChange}
                        />
                      </Form.Group>
                    </Col>
                    {userDetail?.institute_id ? (
                      <></>
                    ) : (
                      <Col xs={12} md={6} lg={12}>
                        <Form.Group className="mb-3">
                          <Form.Label>
                            Institute Name <span className="text-danger">*</span>
                          </Form.Label>
                          <AsyncSelect
                            defaultOptions
                            className="basic-single"
                            classNamePrefix="select"
                            isSearchable={true}
                            name="institute_id"
                            value={insId}
                            loadOptions={loadAllInstituteOptions}
                            onChange={(e) => {
                              setInsId(e), setDetail({ ...detail, ['institute_id']: e.id });
                            }}
                            placeholder="Search By Institute Name"
                          />

                          <div className={'danger-frm'}>{clicked && validate()?.institute_id}</div>
                        </Form.Group>
                      </Col>
                    )}

                    <Col md={12}>
                      <Form.Group className="mb-3" controlId="formBasicName">
                        <Form.Label>
                          Location <span className="text-danger">*</span>
                        </Form.Label>

                        <AsyncSelect
                          cacheOptions
                          defaultOptions
                          value={jobLocation}
                          menuIsOpen={locationSearch !== '' ? true : false}
                          getOptionLabel={(e) => e.label}
                          getOptionValue={(e) => e.value}
                          loadOptions={loadJObLocationOption}
                          onInputChange={(newValue) => setLocationSearch(newValue)}
                          className="basic-single mt-1"
                          name="location"
                          onChange={(e) => {
                            setJobLocation(e);
                            setDetail({
                              ...detail,
                              ['location']: e.id
                            });
                          }}
                        />
                      </Form.Group>

                      <div className={'danger-frm'}>{clicked && validate().jobLocation}</div>
                    </Col>

                    <Col md={12}>
                      <Form.Group className="mb-3" controlId="formBasicName">
                        <Form.Label>
                          Select Admin For Chapter
                          <span className="text-danger">*</span>
                        </Form.Label>
                        <Select
                          isMulti
                          className="basic-single"
                          classNamePrefix="select"
                          isSearchable={true}
                          options={adminOption}
                          onChange={(e) => {
                            setDetail({
                              ...detail,
                              ['group_admin']: e.map((data) => data?.value).join()
                            });
                            setAdminId(e);
                          }}
                          value={adminId}
                        />
                        <div className={'danger-frm'}>{clicked && validate()?.group_admin}</div>
                      </Form.Group>
                    </Col>

                    <Col md={12}>
                      <Form.Group className="mb-3" controlId="formBasicName">
                        <Form.Label>
                          Verify Chapter <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Select
                          aria-label="Default select example"
                          name="group_verified"
                          value={detail?.group_verified}
                          onChange={handleChange}>
                          <option>Select</option>
                          <option value="1">Official</option>
                          <option value="0">Non-Official</option>
                        </Form.Select>
                        <div className={'danger-frm'}>{clicked && validate()?.group_verified}</div>
                      </Form.Group>
                    </Col>

                    <Col md={12}>
                      <Form.Label>
                        Privacy <span className="text-danger">*</span>
                        <div className={'danger-frm'}>{clicked && validate()?.chapter_privacy}</div>
                      </Form.Label>
                      <div>
                        {['radio'].map((type) => (
                          <div key={`inline-${type}`} className="privcacy-radio mb-3">
                            <div>
                              <Form.Check
                                label="Open Chapter"
                                name="chapter_privacy"
                                value="open"
                                type={type}
                                checked={detail?.chapter_privacy == 'open'}
                                onChange={(e) => {
                                  handleChange(e);
                                }}
                                id={`inline-${type}-1`}
                              />
                              <span className="radio-span">
                                Anyone can see and join the Chapter
                              </span>
                            </div>
                            <div>
                              <Form.Check
                                label="Closed Chapter"
                                name="chapter_privacy"
                                type={type}
                                value="closed"
                                checked={detail?.chapter_privacy == 'closed'}
                                // onChange={(e) => setPrivacy(e.target.value)}
                                onChange={(e) => {
                                  handleChange(e);
                                }}
                                id={`inline-${type}-2`}
                              />
                              <span className="radio-span">
                                Anyone can see and request to join the group. Requests can be
                                accepted or declined by admins
                              </span>
                            </div>
                            <div>
                              <Form.Check
                                label="Secret Chapter"
                                name="chapter_privacy"
                                checked={detail?.chapter_privacy == 'secret'}
                                type={type}
                                value="secret"
                                // onChange={(e) => setPrivacy(e.target.value)}
                                onChange={(e) => {
                                  handleChange(e);
                                }}
                                id={`inline-${type}-3`}
                              />
                              <span className="radio-span">Only members can access the group</span>
                            </div>
                          </div>
                        ))}
                      </div>
                    </Col>
                  </Row>
                  <div className="form-btns d-flex align-items-center mt-3">
                    {id ? (
                      <button
                        type="button"
                        className="green_button_admin"
                        onClick={(e) => {
                          updateHandler(e);
                        }}>
                        Update
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="green_button_admin"
                        onClick={(e) => {
                          handleSave(e);
                        }}>
                        Save
                      </button>
                    )}
                    <strong>OR</strong>
                    <Button
                      className="main-btn-red"
                      onClick={() => {
                        navigate(-1);
                      }}>
                      Cancel
                    </Button>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </Container>
      </div>
      {loading ? <Loader /> : ''}
    </>
  );
}
