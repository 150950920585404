/**
@copyright  :   ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
@author     :   Shiv Charan Panjeta 
 
All Rights Reserved.
Proprietary and confidential :  All information contained here in is, and remains
the property of ToXSL Technologies Pvt. Ltd. and it's partners.
Unauthorized copying of this file, via any medium is strictly prohibited.
*/

import { useEffect, useState } from 'react';
import { Button, Container } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Table from 'react-bootstrap/Table';
import { useLocation, useNavigate } from 'react-router';
import { Link } from 'react-router-dom';

import Loader from '../../../components/commoncomponents/loader/loader-large';
import * as adminServices from '../../../services/AuthServices';
import showNotification from '../../../services/NotificationService';

export default function Participants() {
  const [list, setList] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const [currentPage, setCurrentPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [orderBy, setOrderBy] = useState('DESC');
  const [shortOrderBy, setShortOrderBy] = useState('id');
  const [chekcedId, setCheckedId] = useState([]);

  const [searchData, setSearchData] = useState({
    event_id: '',
    title: ''
  });

  const [record, setrecord] = useState('');
  const [action, setAction] = useState('');
  const [allId, setAllId] = useState([]);

  const eventId = new URLSearchParams(location?.search).get('event_id');
  const id = new URLSearchParams(location?.search).get('id');
  const data = location?.state;

  useEffect(() => {
    apiCall();
  }, []);

  const apiCall = () => {
    setLoading(true);
    const body = {
      event_id: eventId,
      act_id: id,
      id: '',
      page: 1,
      order: '',
      user_id: '',
      user_name: '',
      user_email: '',
      user_mobile: ''
    };
    adminServices.getParticipantsList(body).then((resp) => {
      setLoading(false);
      if (resp.status === 200) {
        setLoading(false);
        setCheckedId([]);
        setList(resp?.data?.detail.viewlist);
        setAllId(resp?.data?.detail.viewlist.map((data) => data?.id));
        setrecord(resp?.data?.detail);
      }
    });
  };

  const onChangeSearch = (e) => {
    const { name, value } = e.target;
    setSearchData({ ...searchData, [name]: value });
  };

  const searchHandler = (e) => {
    setLoading(true);
    const body = {
      id: id,
      page: 1,
      order: orderBy ? orderBy : 'DESC',
      sortfield: shortOrderBy ? shortOrderBy : 'id',
      event_id: searchData.event_id,
      activity_title: searchData.title
    };

    adminServices.getManageActivities(body).then((resp) => {
      setLoading(false);
      if (resp.status === 200) {
        setList(resp?.data?.detail.viewlist);
        setAllId(resp?.data?.data?.viewlist.map((data) => data?.id));
      }
    });
  };

  const sortHandler = (e, type) => {
    e.preventDefault();
    let currentSort = orderBy === 'ASC' ? 'DESC' : 'ASC';
    setOrderBy(currentSort);
    setShortOrderBy(type);

    apiCall(currentPage, orderBy, shortOrderBy);
  };

  const handleSelectAll = (e) => {
    if (e.target.checked === true) {
      setCheckedId(allId);
    } else {
      setCheckedId([]);
    }
  };

  const handleAdd = (e) => {
    e.preventDefault();
    navigate(`/admin/event/AddEventInvitation?event_id=${eventId}&id=${id}`, { state: data });
  };

  const handlecheckbox = (id) => {
    if (chekcedId?.includes(id)) {
      let filterId = chekcedId.filter((data) => data !== id);
      setCheckedId(filterId);
    } else {
      setCheckedId([...chekcedId, id]);
    }
  };

  const selectManageActivities = (e) => {
    setAction(e.target.value);
    const body = {
      action: e.target.value,
      ids: chekcedId?.toString()
    };
    adminServices.selectedManageActivities(body).then((res) => {
      if (res?.status === 200) {
        showNotification('success', res?.data?.message);
      }
    });
  };

  return (
    <>
      <div className="dash-main-content">
        <Container fluid>
          <div className="dash-page-content">
            <div className="dash-page-title">
              <Row>
                <Col xs={12} sm={12} md={12}>
                  <div className="dash-title-bar d-flex align-items-center justify-content-between">
                    <div className="title-lft d-flex align-items-center">
                      <div className="dash-icn">
                        <i className="fa fa-users"></i>
                      </div>
                      <div className="dash-title-dis">
                        <h5>
                          List of Participants in<span>{data?.title}</span> activity
                        </h5>
                        <h5>
                          Total Record(s): <span> {record?.totalRecords} </span>
                        </h5>
                      </div>
                    </div>

                    <div className="title-rt-btn d-flex align-items-center">
                      <Link className="add-btn-grn mx-2" onClick={(e) => handleAdd(e)}>
                        Add Participants
                      </Link>
                      {/* enable,disable,del,delImg */}
                      <div className="head-instite-sec">
                        <Form.Select
                          aria-label="Default select example "
                          onChange={selectManageActivities}>
                          <option>Choose Action</option>
                          <option value="active">Remove Participants</option>
                        </Form.Select>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="dash-content-card">
              <div className="table-responsive">
                <Table className="custom-table-data" striped bordered hover>
                  <thead>
                    <tr>
                      <th>
                        <Form.Check
                          type="checkbox"
                          id="checkbox"
                          name="checkall"
                          checked={allId?.length ? allId?.length === chekcedId?.length : false}
                          onChange={(e) => {
                            handleSelectAll(e);
                          }}
                          label=""
                        />
                      </th>
                      <th>
                        <Link
                          to=""
                          onClick={(e) => {
                            sortHandler(e, 'id');
                          }}>
                          Id
                          <img src={require('../../../assets/images/sort.png')} alt="sort" />
                        </Link>
                      </th>
                      <th>
                        <Link
                          to=""
                          onClick={(e) => {
                            sortHandler(e, 'title');
                          }}>
                          Name
                          <img src={require('../../../assets/images/sort.png')} alt="sort" />
                        </Link>
                      </th>
                      <th>
                        <Link
                          to=""
                          onClick={(e) => {
                            sortHandler(e, 'title');
                          }}>
                          Email
                          <img src={require('../../../assets/images/sort.png')} alt="sort" />
                        </Link>
                      </th>
                      <th>
                        <Link
                          to=""
                          onClick={(e) => {
                            sortHandler(e, 'title');
                          }}>
                          Contact No.
                          <img src={require('../../../assets/images/sort.png')} alt="sort" />
                        </Link>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="search-table">
                      <td></td>
                      <td>
                        <Form.Control
                          type="text"
                          placeholder="Id"
                          value={searchData.event_id}
                          name="id"
                          onChange={(e) => {
                            onChangeSearch(e);
                          }}
                        />
                      </td>
                      <td>
                        <Form.Control
                          type="text"
                          placeholder="name"
                          value={searchData.user_name}
                          name="name"
                          onChange={(e) => {
                            onChangeSearch(e);
                          }}
                        />
                      </td>
                      <td>
                        <Form.Control
                          type="text"
                          placeholder="email"
                          value={searchData.user_email}
                          name="user_email"
                          onChange={(e) => {
                            onChangeSearch(e);
                          }}
                        />
                      </td>

                      <td>
                        <Form.Control
                          type="text"
                          placeholder="mobile"
                          value={searchData.user_mobile}
                          name="user_mobile"
                          onChange={(e) => {
                            onChangeSearch(e);
                          }}
                        />
                      </td>

                      <td className="serac-btns" colSpan={2}>
                        <Button
                          className="main-btn-grn"
                          onClick={(e) => {
                            searchHandler(e);
                          }}>
                          Search
                        </Button>
                      </td>
                    </tr>
                    {list && list?.length ? (
                      list?.map((data, index) => {
                        return (
                          <tr key={index}>
                            <td>
                              <Form.Check
                                type="checkbox"
                                id="checkbox"
                                label=""
                                checked={chekcedId?.includes(data?.id)}
                                onClick={() => handlecheckbox(data?.id)}
                              />
                            </td>
                            <td>{data.id}</td>
                            <td>{data?.name} </td>
                            <td>{data?.email} </td>
                            <td>{data?.mobile} </td>

                            <td align="center">
                              <Link
                                className="search-btn"
                                to="#"
                                // to="/admin/event/ViewManageActivity?id=${data?.id}"
                                onClick={(e) => {
                                  e.preventDefault();
                                  navigate(`/admin/users/view/${data?.id}`);
                                }}>
                                <i className="fas fa-search-plus" title="VIEW"></i>
                              </Link>
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr className="text-center">Data Not Found </tr>
                    )}
                  </tbody>
                </Table>
                {/* {response / 10 > 1 ? (
                    <ReactPaginate
                      previousLabel={currentPage > 0 ? '<' : ''}
                      nextLabel=">"
                      onPageChange={handlePageClick}
                      pageCount={response / 10}
                    />
                  ) : (
                    ''
                  )} */}
                {/* <Pagination>{items}</Pagination> */}
              </div>
            </div>
          </div>
        </Container>
      </div>
      {loading ? <Loader /> : <></>}
    </>
  );
}
