import { Link, useLocation, useNavigate } from 'react-router-dom';
import logo2 from '../../assets/images/almabay-logo-2.png';
import { Container, Dropdown, Nav, Navbar } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import FriendList from '../commoncomponents/chat/FriendList';
import showNotification from '../../services/NotificationService';
import { useSelector } from 'react-redux';
import { viewCartProducts } from '../../services/ShopServices';
import * as APISERVICES from '../../services/AuthServices';
import { truncate } from '../../globals/helper';
import { useDispatch } from 'react-redux';
import { logout } from '../../redux/action';
function HeaderInner(props) {
  // do not show some routes
  const postion = props;
  const state = props;
  let location = useLocation();
  let getId = localStorage.getItem('user_id');
  const [shouldDisplayMenues, setshoulDisplayMenues] = useState(true);
  const userDetail = useSelector((state) => state?.user?.details);

  useEffect(() => {
    if (location.pathname == '/advancesearch/directory') {
      setshoulDisplayMenues(false);
    }
  }, [location.pathname]);
  const isloggedIn = useSelector((state) => state?.user?.isloggedIn);
  const [totalCartCount, setTotalCartCount] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    cartProducts();
  }, []);

  const cartProducts = () => {
    viewCartProducts(getId).then((resp) => {
      if (resp?.status == 200) {
        setTotalCartCount(resp?.data?.cartCount);
      } else {
        showNotification('err', resp?.data?.message);
      }
    });
  };

  const dipatch = useDispatch();
  const logOutHandler = (e) => {
    e?.preventDefault();
    APISERVICES.logOut().then((res) => {
      if (res?.status === 200) {
        localStorage.clear();
        dipatch(logout());
        navigate('/login');
      }
    });
  };
  return (
    <>
      <Navbar expand="lg" className={`headcard-wht py-3 ${postion ? postion : ''}`}>
        <Container>
          <div className="header-wht-inr w-100 d-flex align-items-center justify-content-between flex-column flex-lg-row">
            <div className="w-100 w-lg-auto d-flex align-items-center justify-content-between">
              <Navbar.Brand href="/" className="head-logo">
                <img src={logo2} className="main-logo" alt="logo" />
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="basic-navbar-nav" className=" ms-auto" />
            </div>

            {isloggedIn ? (
              <div className="shop-hd-usr shop-page-hdr d-flex align-items-center justify-content-end">
                <div className="user-drop">
                  <div className="drop-notifi user-drop login-lst-drop">
                    <Dropdown>
                      <Dropdown.Toggle
                        id="dropdown-basic"
                        className="d-flex align-items-center text-muted"
                      >
                        <span className="user-pro-drop">
                          <img
                            src={
                              userDetail?.avtar
                                ? process.env.REACT_APP_API_DOMAIN + userDetail?.avtar
                                : require('../../assets/images/birth4.png')
                            }
                            onError={({ currentTarget }) => {
                              currentTarget.onerror = null; // prevents looping
                              currentTarget.src = require("../../assets/images/birth4.png")
                            }}
                            className="main-logo"
                            alt="logo"
                          />
                        </span>
                        <span className="ms-3">{truncate(userDetail?.name, 10)}</span>
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item as={Link} to={'/shop'}>
                          Alma Store
                        </Dropdown.Item>
                        <Dropdown.Item as={Link} to={'/shop/cart'}>
                          Cart ({totalCartCount})
                        </Dropdown.Item>
                        <Dropdown.Item as={Link} to={'#'}>
                          My Orders
                        </Dropdown.Item>
                        <Dropdown.Item as={Link} to={'/user/profile'}>
                          Account Settings
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#"
                          onClick={(e) => {
                            logOutHandler(e);
                          }}
                        >
                          Logout
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>
              </div>
            ) : (
              <Navbar.Collapse
                id="basic-navbar-nav"
                className={`w-100 ${!shouldDisplayMenues && 'justify-content-end'}`}
              >
                {shouldDisplayMenues && (
                  <Nav className="me-auto">
                    <Nav.Link href="/jobs/search">Browse Jobs</Nav.Link>
                    <Nav.Link href="/advancesearch/directory/A">Find Institute</Nav.Link>
                    <Nav.Link href="/shop">Alma Store</Nav.Link>
                    <Nav.Link href="https://blog.almabay.com" rel="noreferrer" target={'_blank'}>
                      Blog
                    </Nav.Link>
                  </Nav>
                )}

                <div className="header-inr-rt">
                  <div className="head-inr-login d-flex align-items-center justify-content-center justify-content-lg-end">
                    <p>Have an account?</p>


                    <Link

                      to={`/login`}
                      state={{ path: props.state, name: "event-details" }}
                      className="sign-in-btn">
                      Sign In
                    </Link>
                  </div>
                </div>
              </Navbar.Collapse>
            )}
          </div>

          {/* <div className="header-wht-inr  w-100">
            <Row className="align-items-center">
              <Col md={8} lg={8}>
                <div className="header-inr-lft d-flex align-items-center">
                  <Navbar.Brand href="#home" className="head-logo">
                    <Link to="/">
                      <img src={logo2} className="main-logo" alt="logo" />
                    </Link>
                  </Navbar.Brand>
                  <Navbar.Collapse id="basic-navbar-nav" className="head-inr-lnks">
                    <ul>
                      <li>
                        <Link to="/jobs/search">Browse Jobs</Link>
                      </li>
                      <li>
                        <Link to="/advancesearch/directory">Find Institute</Link>
                      </li>
                      <li>
                        <Link to="/shop">Alma Store</Link>
                      </li>
                      <li>
                        <a href="https://blog.almabay.com" rel="noreferrer" target={'_blank'}>
                          Blog
                        </a>
                      </li>
                    </ul>
                  </Navbar.Collapse>
                </div>
              </Col>
              <Col md={4} lg={4}>
                <div className="header-inr-rt">
                  <div className="head-inr-login d-flex align-items-center justify-content-center justify-content-lg-end">
                    <p>Have an account?</p>
                    <Link to="/login">Sign In</Link>
                  </div>
                </div>
              </Col>
            </Row>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
          </div> */}
        </Container>
      </Navbar>

      {userDetail && state === 'none' ? <FriendList /> : ''}
    </>
  );
}

export default HeaderInner;
