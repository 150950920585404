/**
@copyright  :   ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
@author     :   Shiv Charan Panjeta 
 
All Rights Reserved.
Proprietary and confidential :  All information contained here in is, and remains
the property of ToXSL Technologies Pvt. Ltd. and it's partners.
Unauthorized copying of this file, via any medium is strictly prohibited.
*/
import { useEffect, useState } from 'react';
import { Button, Container } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Pagination from 'react-bootstrap/Pagination';
import Row from 'react-bootstrap/Row';
import Table from 'react-bootstrap/Table';
import { Link } from 'react-router-dom';
import { truncate } from '../../../globals/helper';
import { ViewSingleEventDetail, getSponserRequestList } from '../../../services/AuthServices';

export default function EventSponsors() {
  let active = 2;
  let items = [];
  for (let number = 1; number <= 5; number++) {
    items.push(
      <Pagination.Item key={number} active={number === active}>
        {number}
      </Pagination.Item>
    );
  }
  const eventId = new URLSearchParams(location?.search).get('eventId');
  const [eventDetail, setEventDetail] = useState('');
  const [list, setList] = useState([]);
  const [record, setRecord] = useState([]);
  const [details, setDetails] = useState({
    user_name: '',
    user_email: '',
    user_mobile: '',
    user_status: ''
  });

  useEffect(() => {
    APICALL();
    if (eventId) {
      getDetail(eventId);
    }
  }, []);

  const getDetail = (id) => {
    ViewSingleEventDetail(id).then((res) => {
      if (res?.status === 200) {
        const data = res?.data?.data?.details;
        setEventDetail(data);
      }
    });
  };

  const APICALL = () => {
    const body = {
      event_id: 181623,
      id: '',
      page: '',
      order: '',
      user_id: '',
      user_name: '',
      user_email: '',
      user_mobile: '',
      user_status: ''
    };
    getSponserRequestList(body).then((res) => {
      if (res?.status === 200) {
        const data = res?.data?.detail?.viewlist;
        setList(data);
        setRecord(res?.data?.detail?.totalRecords);
      }
    });
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setDetails({ ...details, [name]: value });
  };

  const searchData = () => {
    const body = {
      event_id: 181623,
      id: details.id,
      page: 1,
      order: '',
      user_id: '',
      user_name: details.user_name,
      user_email: details.user_email,
      user_mobile: details.user_mobile,
      user_status: ''
    };
    getSponserRequestList(body).then((res) => {
      if (res?.status === 200) {
        const data = res?.data?.detail?.viewlist;
        setList(data);
        setRecord(res?.data?.detail?.totalRecords);
      }
    });
  };

  return (
    <div className="dash-main-content">
      <Container fluid>
        <div className="dash-page-content">
          <div className="dash-page-title">
            <Row>
              <Col xs={12} sm={12} md={12}>
                <div className="dash-title-bar d-flex align-items-center justify-content-between">
                  <div className="title-lft d-flex align-items-center">
                    <div className="dash-icn">
                      <i className="fa fa-users"></i>
                    </div>
                    <div className="dash-title-dis">
                      <h5>
                        Sponsorship Request for <span>{truncate(eventDetail?.name, 20)}</span> event
                      </h5>
                      <h5>
                        Total Record(s): <span>{record} </span>
                      </h5>
                    </div>
                  </div>
                  <div className="title-rt-btn d-flex align-items-center"></div>
                </div>
              </Col>
            </Row>
          </div>
          <div className="dash-content-card">
            <div className="table-responsive">
              <Table className="custom-table-data" striped bordered hover>
                <thead>
                  <tr>
                    <th>
                      <Form.Check type="checkbox" id="checkbox" label="" />
                    </th>

                    <th>
                      <Link to="">
                        Id
                        {/* <img src={require('../../assets/images/sort.png')} alt="sort" /> */}
                      </Link>
                    </th>
                    <th>
                      <Link to="">
                        Name
                        {/* <img src={require('../../assets/images/sort.png')} alt="sort" /> */}
                      </Link>
                    </th>
                    <th>
                      <Link to="">
                        Email
                        {/* <img src={require('../../assets/images/sort.png')} alt="sort" /> */}
                      </Link>
                    </th>
                    <th>
                      <Link to="">
                        ContactNo.
                        {/* <img src={require('../../assets/images/sort.png')} alt="sort" /> */}
                      </Link>
                    </th>
                    <th>
                      <Link to="">
                        Company
                        {/* <img src={require('../../assets/images/sort.png')} alt="sort" /> */}
                      </Link>
                    </th>
                    <th>
                      <Link to="">
                        Message
                        {/* <img src={require('../../assets/images/sort.png')} alt="sort" /> */}
                      </Link>
                    </th>

                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="search-table">
                    <td>
                      <Form.Check type="checkbox" id="checkbox" label="" />
                    </td>

                    <td>
                      <Form.Control
                        type="text"
                        placeholder="Id"
                        name="id"
                        onChange={handleChange}
                      />
                    </td>
                    <td>
                      <Form.Control
                        type="text"
                        placeholder="Name"
                        name="user_name"
                        onChange={handleChange}
                      />
                    </td>
                    <td>
                      <Form.Control
                        type="text"
                        placeholder="Email"
                        name="user_email"
                        onChange={handleChange}
                      />
                    </td>
                    <td>
                      <Form.Control
                        type="text"
                        placeholder="contact no."
                        name="user_mobile"
                        onChange={handleChange}
                      />
                    </td>
                    <td></td>
                    <td></td>
                    {/* <td>
                        <Form.Select aria-label="Default select example">
                          <option> -Select-</option>
                          <option value="1">Active</option>
                          <option value="2">Inactive</option>
                        </Form.Select>
                      </td> */}
                    <td className="serac-btns">
                      <Button className="main-btn-grn" onClick={searchData}>
                        Search
                      </Button>
                    </td>
                  </tr>
                  {list && list
                    ? list?.map((data, index) => {
                        return (
                          <tr key={index}>
                            <td>
                              <Form.Check type="checkbox" id="checkbox" label="" />
                            </td>

                            <td>{data?.id} </td>
                            <td>{data?.name} </td>
                            <td>{data?.email} </td>
                            <td>{data?.contact} </td>
                            <td>{data?.company} </td>
                            <td>{data?.message} </td>

                            <td>
                              {/* <Form.Select aria-label="Default select example">
                              <option>Action</option>
                              <option value="1">Edit</option>
                              <option value="2">View</option>
                            </Form.Select> */}
                            </td>
                          </tr>
                        );
                      })
                    : 'No data Found '}
                </tbody>
              </Table>
              {/* <Pagination>{items}</Pagination> */}
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}
