/**
@copyright  :   ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
@author     :   Shiv Charan Panjeta 
 
All Rights Reserved.
Proprietary and confidential :  All information contained here in is, and remains
the property of ToXSL Technologies Pvt. Ltd. and it's partners.
Unauthorized copying of this file, via any medium is strictly prohibited.
*/

import { useEffect, useState } from 'react';
import { Col, Container, Form, Row } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import useDocumentTitle from '../../../globals/useDocumentTitle';
import { addQuickLinks, editQuickLink, parentMenu } from '../../../services/AuthServices';

const AddQuickLinks = () => {
  useDocumentTitle('Add Quicklinks | AlmaBay');
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;

  const [links, setLinks] = useState({
    id: state === null ? '' : state?.menu_id,
    name: state === null ? '' : state?.name
  });
  const [errorValues, setErrorValues] = useState({});
  const [menuList, setMenuList] = useState([]);
  const { name, id } = links;

  useEffect(() => {
    // an IIFE to invoke right after invoke of effect hook
    (async () => {
      try {
        const response = await parentMenu();
        if (response?.status === 200) {
          setMenuList(response.data?.data);
        }
      } catch (error) {
        console.error(error);
      }
    })();
  }, []);

  /**
   * function to validate
   * @returns
   */
  const validation = () => {
    let errors = {};
    if (id === '') {
      errors.id = 'Name is required';
    } else if (!/^[0-9\s]*$/.test(id)) {
      errors.id = 'Name must be alphabet';
    }
    return Object.keys(errors).length > 0 ? errors : null;
  };

  /**
   * function to trigger when onChange event fires
   * @param {*} e
   */
  const handleChange = (e) => {
    const data = JSON.parse(e.target.value);
    e.preventDefault();
    setLinks((prevState) => ({
      ...prevState,
      name: data?.name,
      id: data?.id
    }));
    setErrorValues((prevState) => ({
      ...prevState,
      name: undefined,
      id: undefined
    }));
  };

  /**
   * submit function to update and add form
   * (using common function for both events)
   * @param {*} e
   */
  const handleSubmit = async (e) => {
    e.preventDefault();
    let errors = validation();
    if (errors) {
      setErrorValues(errors);
    } else {
      if (state !== null) {
        try {
          let body = {
            menu_id: id,
            name: name
          };
          const response = await editQuickLink(state?.id, body);
          if (response?.status === 200) {
            navigate('/admin/quick-links');
          }
        } catch (error) {
          console.error(error);
        }
      } else {
        try {
          let body = {
            menu_id: id,
            name: name
          };
          const response = await addQuickLinks(body);
          if (response?.status === 200) {
            navigate('/admin/quick-links');
          }
        } catch (error) {
          console.error(error);
        }
      }
    }
  };

  return (
    <div className="dash-main-content">
      <Container fluid>
        <div className="dash-page-content">
          <div className="dash-page-title">
            <Row>
              <Col xs={12} sm={12} md={12}>
                <div className="dash-title-bar d-flex align-items-center justify-content-between">
                  <div className="title-lft d-flex align-items-center">
                    <div className="icons_head">
                      <i className="fas fa-bars"></i>
                    </div>
                    <h5 className="ms-2">{state === null ? 'Add Quicklink' : 'Edit Quicklink'}</h5>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <div className="dash-content-card">
            <div className="cmmn-form">
              <Form>
                <Row>
                  <Col xs={12} md={6} lg={6}>
                    <Form.Group className="mb-3" controlId="formBasicPassword">
                      <Form.Label>
                        Name <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Select
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        value={JSON.stringify(links)}
                        aria-label="Default select example">
                        <option value={0}>Select</option>
                        {menuList?.map((data) => {
                          return (
                            <option
                              key={data?.menu_id}
                              value={JSON.stringify({ id: data?.menu_id, name: data?.name })}>
                              {data?.name}
                            </option>
                          );
                        })}
                      </Form.Select>
                      {errorValues.id && <span className="error_message">{errorValues.id}</span>}
                    </Form.Group>
                  </Col>
                </Row>
                <button
                  type="submit"
                  onClick={(e) => {
                    handleSubmit(e);
                  }}
                  className="green_button_admin">
                  Save
                </button>
              </Form>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default AddQuickLinks;
