import moment from 'moment';
import * as api from '../utils/requests';

export const Login = async (data) => {
  return await api.PostReq('/auth/login', data);
};

export const registerUsers = async (body) => {
  return await api.PostReq(`/auth/register`, body);
};

export const adminLogin = async (data) => {
  return await api.PostReq('/admin/auth-login', data);
};

export const adminLogout = async () => {
  return await api.getReq('/admin/auth-logout');
};

export const logOut = async () => {
  return await api.getReq('/auth/logout');
};

export const checkLogin = async () => {
  return await api.getReq('/auth/check');
};

export const checkLoginAdmin = () => {
  return api.getReq(`/admin/auth-check`);
};

export const changePassword = async (body) => {
  return await api.PostReq(`/admin/users/changePassword`, body);
};
export const changeUSerPassword = async (body) => {
  return await api.PostReq(`/auth/change-password`, body);
};

export const editProfile = async (data) => {
  return await api.PostReq('/auth/profile-update', data);
};

export const forgotPassword = async (body) => { };

// dashboard api
export const dashCount = async () => {
  return await api.getReq('/admin/users/welcome');
};

export const getBirthdayList = async (
  page = '',
  type = '',
  user_id = '',
  first_name = '',
  email = '',
  status = ''
) => {
  return await api.getReq(
    `/admin/users/birthday-listing?page=${page}&type=${type}&user_id=${user_id}&name=${first_name}&email=${email}&status=${status}`
  );
};

export const getAniversiryList = async (page) => {
  return await api.PostReq(`/admin/users/workAnniversaryUsersListing`, page);
};

/***
 * static pages api
 ***/

export const staticWebPages = async (page = '') => {
  return await api.getReq(`/webpages-pages?page=${page}`);
};

/**
 *
 * @param {search} page
 * @returns
 */

export const instituteDirectory = async (page = '', body) => {
  return await api.PostReq(`/list?page=${page}`, body);
};

/**
 * friend-request
 */

export const getFriendRequestList = async () => {
  return await api.getReq(
    `/user/friend-request-list?t=user&a=get_request_notifications&recipient_id=0`
  );
};

export const addFriend = async () => {
  return await api.getReq(`/user/addFriend`);
};

export const acceptFriendRequest = async (body) => {
  return await api.PostReq(`/user/accept-request?t=user&a=accept_request`, body);
};

export const rejectFriendRequest = async (body) => {
  return await api.PostReq(`/user/decline-request?t=user&a=decline_request`, body);
};

export const getSendedRequestList = async () => {
  return await api.getReq(`/user/user-send-request`);
};

export const getPendingReq = async () => {
  return await api.getReq(`/user/user-pending-request`);
};

export const userfollowers = async (page = '') => {
  return await api.getReq(`/chat/userfollowers?q=${page}`);
};

export const userChatfollowers = async (page = '') => {
  return await api.getReq(`/chat/chatuserfollowers?type=userfollowers&q=${page}`);
};

export const SK_getMessageRecipients = async (id = 0, section = '') => {
  //return await api.getReq(`/chat/user/getMessageRecipients/${id}/${section}`);

  return await api.getReq(`/chat/user/getMessageRecipients/messages/user`);
};

// Use in (/ajax/socialAjax) - get 2 user thread , search users in list

export const callAjaxGetMethod = async (url, body = '', isPost = false) => {
  if (isPost) {
    return await api.PostReq(url, body);
  } else {
    return await api.getReq(url, body);
  }
};

export const postMessage = async (body) => {
  return await api.PostReq(`ajax/socialAjax?t=message&a=send_new_message`, body);
};

// SETTINGS

// Admin/users/showAllAdmins

export const showAllAdmins = async (page, order, sortorder) => {
  return await api.getReq(
    `/admin/users/showAllAdmins?page=${page}&order=${order ? order : 'DESC'}&sortorder=${sortorder ? sortorder : 'admin_id'
    }`
  );
};

// Admin/users/roles

export const getRoleList = async (page = '') => {
  return await api.getReq(`/admin/users/roleList?page=${page}`);
};

export const getRoleDetail = async (id) => {
  return await api.getReq(`/admin/users/roleDetails?id=${id}`);
};

export const getRoleView = async (id) => {
  return await api.getReq(`/admin/users/roleView?id=${id}`);
};

export const addRole = async (body) => {
  return await api.PostReq(`/admin/users/roleAdd`, body);
};

export const updateRole = async (body) => {
  return await api.PostReq(`/admin/users/roleUpdate`, body);
};

export const multiActionOnPermission = async (body) => {
  return await api.PostReq(`/admin/users/multiActionOnPermission`, body);
};

//admin-location
export const getCityList = async (
  page = '',
  sortfield = '',
  order = '',
  city_id = '',
  city_name = '',
  state_name = '',
  country_name = ''
) => {
  return await api.getReq(
    `/admin/location/cityList?page=${page}&sortfield=${sortfield}&order=${order}&city_id=${city_id}&city_name=${city_name}&state_name=${state_name}&country_name=${country_name}`
  );
};

export const cityUpdateStatus = async (city_id = '', status = '') => {
  return await api.getReq(`/admin/location/cityStatusChange?city_id=${city_id}&status=${status}`);
};

export const addCity = async (body) => {
  return await api.PostReq(`/admin/location/cityAdd`, body);
};

export const updateCity = async (body) => {
  return await api.PostReq(`/admin/location/cityUpdate`, body);
};

export const cityDetail = async (city_id = '') => {
  return await api.getReq(`/admin/location/cityDetails?city_id=${city_id}`);
};

export const getStateList = async (
  page = '',
  sortfield = '',
  order = '',
  state_id = '',
  state_name = '',
  country_name = ''
) => {
  return await api.getReq(
    `/admin/location/stateList?page=${page}&sortfield=${sortfield}&order=${order}&state_id=${state_id}&state_name=${state_name}&country_name=${country_name}`
  );
};

export const stateUpdateStatus = async (state_id = '', status = '') => {
  return await api.getReq(
    `/admin/location/stateStatusChange?state_id=${state_id}&status=${status}`
  );
};

export const addState = async (body) => {
  return await api.PostReq(`/admin/location/stateAdd`, body);
};

export const updateState = async (body) => {
  return await api.PostReq(`/admin/location/stateUpdate`, body);
};

export const stateDetail = async (id) => {
  return await api.getReq(`/admin/location/stateDetails?state_id=${id}`);
};

export const getCountryList = async (
  page = '',
  sortfield = '',
  order = '',
  country_id = '',
  country_code = '',
  country_name = ''
) => {
  return await api.getReq(
    `/admin/location/countryList?page=${page}&sortfield=${sortfield}&order=${order}&country_id=${country_id}&country_code=${country_code}&country_name=${country_name}`
  );
};

export const countryUpdateStatus = async (country_id = '', status = '') => {
  return await api.getReq(
    `/admin/location/countryStatusChange?country_id=${country_id}&status=${status}`
  );
};

export const addCountry = async (body) => {
  return await api.PostReq(`/admin/location/countryAdd`, body);
};

export const updateCountry = async (body) => {
  return await api.PostReq(`/admin/location/countryUpdate`, body);
};

export const countryDetail = async (id) => {
  return await api.getReq(`/admin/location/countryDetails?country_id=${id}`);
};

// Admin Manegement

export const getAllAdminList = async () => {
  return await api.getReq(`/admin/users/showAllAdmins`);
};

//Institute Management
export const getInstituteList = async (body) => {
  return await api.PostReq(`/admin/institute/getInstituteListing`, body);
};

export const getContactList = async (body) => {
  return await api.getReq(`/admin/institute/contactPerson/list`);
};

export const instituteMultiAction = async (body) => {
  return await api.PostReq(`/admin/institute/multiAction`, body);
};
export const univerisityMultiAction = async (body) => {
  return await api.PostReq(`/admin/institute/multiActionUni`, body);
};

export const getUniversityList = async (body) => {
  return await api.PostReq(
    `/admin/institute/universityList?sortfield=${body?.sortfield}&order=${body?.order}`,
    body
  );
};

export const createInstitute = async (body) => {
  return await api.PostReq(`/admin/institute/createInstitute`, body);
};

export const updateInstitute = async (body) => {
  return await api.PostReq(`/admin/institute/editInstitute`, body);
};

export const singleInstituteDetail = async (institute_id = '') => {
  return await api.getReq(`/admin/institute/instituteDetail?institute_id=${institute_id}`);
};
// Institute - Course - Management

export const getCourseList = async (
  id,
  page,
  sortfield,
  order,
  course_id,
  course_name,
  dept_id
) => {
  return await api.getReq(
    `/admin/institute/coursesList?institute_id=${id}&page=${page}&sortfield=${sortfield}&order=${order}&course_id=${course_id}&course_name=${course_name}&dept_id=${dept_id}`
  );
};

export const addCourse = async (body) => {
  return await api.PostReq(`/admin/institute/add_course`, body);
};

export const editCourse = async (body) => {
  return await api.PostReq(`/admin/institute/edit_course`, body);
};

export const getCourseDetail = async (course_id) => {
  return await api.getReq(`/admin/institute/detail_course?course_id=${course_id}`);
};

// Institute - Department - Management
export const addDepartment = async (body) => {
  return await api.PostReq(`/admin/institute/add_department`, body);
};
export const editDepartment = async (body) => {
  return await api.PostReq(`/admin/institute/edit_department`, body);
};
export const getDepartmentList = async (body) => {
  return await api.PostReq(`/admin/institute/getDepartmentsList`, body);
};
export const getDepartmentDetail = async (institute_id = '', departement_id = '') => {
  return await api.getReq(
    `/admin/institute/detail_department?institute_id=${institute_id}&departement_id=${departement_id}`
  );
};
//department
export const departmentDropDown = async (institute_id = '') => {
  return await api.getReq(`/admin/dropdown/instituteDepartments?institute_id=${institute_id}`);
};
export const userEducationDropDown = async (institute_id = '', qualification = '', q = '') => {
  return await api.getReq(
    `/admin/life_skills_api/getUserEducationProgramsList?institute_id=${institute_id}&qualification=${qualification}&q=${q}`
  );
};
export const fieldOfStudyDropDown = async (institute_id = '', program = '', q = '') => {
  return await api.getReq(
    `/admin/user/getFieldOfStudyByProgramName?institute_id=${institute_id}&program=${program}&q=${q}`
  );
};
export const specializationDropDown = async (q = '', field = '') => {
  return await api.getReq(
    `/admin/life_skills_api/getSpecializationByFieldName?q=${q}&field=${field}`
  );
};

//dropdown

export const getCityDropdown = async () => {
  return await api.getReq(`/admin/dropdown/allCities`);
};
export const getStateDropdown = async () => {
  return await api.getReq(`/admin/dropdown/allStates`);
};
export const getCountryDropdown = async () => {
  return await api.getReq(`/admin/dropdown/allCountry`);
};

// /admin/dropdown/allAdminAccessType

export const getAdminAccessType = async () => {
  return await api.getReq(`/admin/dropdown/allAdminAccessType`);
};

export const getAdminRoleDrop = async () => {
  return await api.getReq(`/admin/dropdown/allRoles`);
};

export const getUniversityDrop = async () => {
  return await api.getReq(`/admin/dropdown/allUniversity`);
};

export const getAllInstituteDrop = async (search = '') => {
  return await api.getReq(`/admin/dropdown/allInstitues?q=${search}`);
};

export const getDepartmentInstituteDrop = async (search = '') => {
  return await api.getReq(`admin/institute/instituteList?q=${search}`);
};

export const getDepartmentDrop = async (search = '', institute_id) => {
  return await api.getReq(
    `admin/institute/instituteDepartments?institute_id=${institute_id}&q=${search}`
  );
};
// Admin user
export const addUser = async (body) => {
  return await api.PostReq(`/admin/users/create`, body);
};

export const addAdmin = async (body) => {
  return await api.PostReq(`/admin/users/createAdmin`, body);
};

export const getInstituteDropdown = async () => {
  return await api.getReq(`/admin/dropdown/institute-name`);
};

export const getReleshionshipDropdown = async () => {
  return await api.getReq(`/admin/dropdown/relationship-status`);
};
export const getLocationDropdown = async (q) => {
  return await api.getReq(`/admin/dropdown/current-location?q=${q}`);
};

export const getGenderDropdown = async () => {
  return await api.getReq(`/admin/dropdown/gender`);
};
export const getUserList = async (
  page = '',
  sortfield = '',
  order = '',
  user_id = '',
  first_name = '',
  email = '',
  status = '',
  reg_date = '',
  to_reg_date = '',
  institute_id = ''
) => {
  return await api.getReq(
    `/admin/users/list-users?page=${page}&institute_id=${institute_id === null ? '' : institute_id
    }&sortfield=${sortfield}&order=${order}&user_id=${user_id}&name=${first_name}&email=${email}&status=${status}&reg_date=${reg_date ? moment(reg_date).format('YYYY-MM-DD') : ''
    }&to_reg_date=${to_reg_date ? moment(to_reg_date).format('YYYY-MM-DD') : ''}`
  );
};

export const getUserVerificationList = async (
  page = '',
  sortfield = '',
  order = '',
  user_id = '',
  first_name = '',
  email = '',
  status = '',
  reg_date = '',
  to_reg_date = '',
  institute_id = ''
) => {
  return await api.getReq(
    `/admin/users/list-usersverification?page=${page}&institute_id=${institute_id}&sortfield=${sortfield}&order=${order}&user_id=${user_id}&name=${first_name}&email=${email}&status=${status}&reg_date=${reg_date ? moment(reg_date).format('YYYY-MM-DD') : ''
    }&to_reg_date=${to_reg_date ? moment(to_reg_date).format('YYYY-MM-DD') : ''}`
  );
};

export const getfacultyList = async (
  page = '',
  sortfield = '',
  order = '',
  user_id = '',
  first_name = '',
  email = '',
  status = '',
  date_created = ''
) => {
  return await api.getReq(
    `/admin/users/list-faculty?page=${page}&sortfield=${sortfield}&order=${order}&user_id=${user_id}&name=${first_name}&email=${email}&status=${status}&date_created=${date_created}`
  );
};

export const updateUser = async (id, body) => {
  return await api.PostReq(`/admin/users/edit?id=${id}`, body);
};

export const getDeletedUserList = async (
  page = '',
  sortfield = '',
  order = '',
  user_id = '',
  first_name = '',
  email = '',
  date_created = '',
  date_modified = '',
  status = ''
) => {
  return await api.getReq(
    `/admin/users/deleted-users?page=${page}&sortfield=${sortfield}&order=${order}&user_id=${user_id}&first_name=${first_name}&email=${email}&date_created=${date_created}&date_modified=${date_modified}&status=${status}`
  );
};

export const userDetail = async (body) => {
  return await api.PostReq(`/admin/users/getUserDetails`, body);
};

export const userWorkDetail = async (id) => {
  return await api.getReq(`/admin/life_skills_api/getUserCareerGoals?user_id=${id}`);
};

export const deleteUserEducationDetails = async (body) => {
  return await api.PostReq(`/admin/users/delete-education `, body);
};

export const deleteUserDetail = async (body) => {
  return await api.PostReq(`/admin/users/delete-user`, body);
};

export const globalStateChange = async (body) => {
  return await api.PostReq(`/admin/users/multiAction`, body);
};

export const setAdminStatus = async (admin_id = '', admin_status = '') => {
  return await api.getReq(
    `/admin/users/setStatus?admin_id=${admin_id}&admin_status=${admin_status}`
  );
};

export const userRestore = async (id = '') => {
  return await api.getReq(`/admin/users/restore-user?id=${id}`);
};

export const filterByUser = async (body, page = 1, sortfield = 'user_id', order = 'DESC') => {
  return await api.PostReq(
    `/admin/users/getAllUsersListing?page=${page}&sortfield=${sortfield}&order=${order}`,
    body
  );
};

export const filterByFaculty = async (body, page = 1, sortfield = 'user_id', order = 'DESC') => {
  return await api.PostReq(
    `/admin/users/getAllfaculityListing?page=${page}&sortfield=${sortfield}&order=${order}`,
    body
  );
};

export const adminDetail = async (admin_id = '') => {
  return await api.getReq(`/admin/users/adminDetail?admin_id=${admin_id}`);
};

// Dropdown - Work-detail(add)

export const addWorkDetail = async (id = '', body) => {
  return await api.PostReq(`/admin/users/add-work?id=${id}`, body);
};

export const editWorkDetail = async (id = '', user_profession_id = '', body) => {
  return await api.PostReq(
    `/admin/users/edit-work?id=${id}&user_profession_id=${user_profession_id}`,
    body
  );
};

export const deleteUserWorkDetail = async (body) => {
  return await api.PostReq(`/admin/users/delete-work`, body);
};

export const getOrganizationsDrop = async (q = '') => {
  return await api.getReq(`/admin/dropdown/organisation-type?q=${q}`);
};

//career-user management
export const getMentorShipDropDown = async (q = '') => {
  return await api.getReq(`/admin/life_skills_api/getMentorships?q=${q}`);
};

export const addCareerGoals = async (body) => {
  return await api.PostReq(`/admin/users/addUserCareerGoals`, body);
};

//company
export const getCompanyDropDown = async (q = '') => {
  return await api.getReq(`/admin/user/getOrganizationsList?q=${q}`);
};

export const getJobIndustryDropDown = async (q = '') => {
  return await api.getReq(`/admin/life_skills_api/getJobIndustries?q=${q}`);
};

export const getFunctionalAreaDropDown = async (q = '') => {
  return await api.getReq(`/admin/jobs/categories?q=${q}`);
};

export const getGradeDropDown = async (q = '') => {
  return await api.getReq(`/admin/user/getWorkLevel?q=${q}`);
};

// Company - Management - Featured

export const getCompanyTagList = async () => {
  return await api.getReq(`/admin/company/jobshomepage`);
};

export const addCompanyTagList = async (body) => {
  return await api.PostReq(`/admin/company/jobsHomePageSave`, body);
};

export const getTagsDropDown = async (q = '') => {
  return await api.getReq(`/admin/company/searchPage?q=${q}`);
};

// Dropdown - Institute

export const getAffiliatedTo = async (q = '', except_id = '', institute_id = '') => {
  return await api.getReq(
    `/admin/users/keyup/affiliated?q=${q}&except_id=${except_id}&institute_id=${institute_id}`
  );
};

export const getLocationDropDown = async (q = '') => {
  return await api.getReq(`/admin/users/keyup/location?q=${q}`);
};

export const updateAdmin = async (body) => {
  return await api.PostReq(`/admin/users/editAdmin`, body);
};

// /admin/users/selected_admins

export const setMultipleAdminStatus = async (body) => {
  return await api.PostReq(`/admin/users/selected_admins`, body);
};

export const getJobCategoryDrop = async (search = '') => {
  return await api.getReq(`/admin/dropdown/jobCategory?q=${search}`);
};
export const getJobKeyWordDrop = async (search = '') => {
  return await api.getReq(`/admin/dropdown/jobKeyword?q=${search}`);
};

export const getJobHiringForDrop = async (search = '') => {
  return await api.getReq(`/admin/dropdown/jobKeyword?q=${search}`);
};

// /dropdown/jobLocation
export const getJobLocationForDrop = async (search = '') => {
  return await api.getReq(`/admin/dropdown/jobLocation?q=${search}`);
};

export const getJobFestLocationDropDown = async (search = '') => {
  return await api.getReq(`/admin/users/jobfest/location?q=${search}`);
};

export const addJob = async (body) => {
  return await api.PostReq(`/admin/jobs/createJob`, body);
};

export const getJobList = async (body) => {
  return await api.PostReq(`/admin/jobs/getAllJobListing`, body);
};
export const getJobDetail = async (job_id = '') => {
  return await api.getReq(`/admin/jobs/jobDetails?job_id=${job_id}`);
};

// /admin/page/categoryList

export const getPageCategoryDrop = async () => {
  return await api.getReq(`/admin/page/categoryList`);
};

export const getSocialPageDetail = async (id) => {
  return await api.getReq(`/admin/detail_page?id=${id}`);
};

export const getPageAdminListDrop = async () => {
  return await api.getReq(`/admin/page/userList`);
};

export const addSocialPage = async (body) => {
  return await api.PostReq(`/admin/add_page`, body);
};
export const editSocialPage = async (body) => {
  return await api.PostReq(`/admin/edit_page`, body);
};
export const getSocialPageList = async (
  page = '',
  sortfield = '',
  order = '',
  pageid = '',
  page_name = '',
  page_status = '',
  page_verified = ''
) => {
  return await api.getReq(
    `/admin/pageList?page=${page}&sortfield=${sortfield}&order=${order}&pageid=${pageid}&page_name=${page_name}&page_status=${page_status}&page_verified=${page_verified}`
  );
};

// /admin/users/education

//add-rducation detail->(drop downs used)
export const getInstituteDrop = async (q = '') => {
  return await api.getReq(`/admin/life_skills_api/getInstituteList?q=${q}`);
};

export const getInsDepartDrop = async (q = 'a', institute_id = "") => {
  return await api.getReq(`/admin/life_skills_api/instituteDepartmentsList?q=${q}&institute_id=${institute_id}`);
};

export const getEduCourseDrop = async () => {
  return await api.getReq(`/admin/life_skills_api/getUserEducationCourseList`);
};

export const addEducationDetail = async (id = '', body) => {
  return await api.PostReq(`/admin/users/add-education?id=${id}`, body);
};

export const editEducationDetail = async (id = '', qualification_id = '', body) => {
  return await api.PostReq(
    `/admin/users/edit-education?id=${id}&qualification_id=${qualification_id}`,
    body
  );
};
// export const getPageAdminListDrop = async () => {
//   return await api.getReq(`/admin/page/userList`);
// };

// export const getPageAdminListDrop = async () => {
//   return await api.getReq(`/admin/page/userList`);
// };

// export const getPageAdminListDrop = async () => {
//   return await api.getReq(`/admin/page/userList`);
// };

// job fest

export const getJobfestList = async (body, order, sortField) => {
  return await api.PostReq(
    `/admin/jobfest/getAllJobFestListing?order=${order}&sortField=${sortField}`,
    body
  );
};

// map api

export const getUsersMap = async (pt = '', passout_year = '', institute = '', company = '') => {
  return await api.getReq(
    `/admin/users/map?pt=${pt}&filter=${encodeURIComponent(
      `passout_year=${passout_year}&institue=${institute}&company=${company}`
    )}`
  );
};

export const getAdminInstituteList = async () => {
  return await api.getReq(`/admin/dropdown/allInstitues?q=`);
};

//

export const getEstYear = async () => {
  return await api.getReq(`admin/users/adminInstituteList`);
};
export const getMapYearList = async () => {
  return await api.getReq(`admin/map/yearList`);
};

export const getAlumniList = async (
  pt = '',
  place = '',
  passout_year = '',
  company = '',
  institute = ''
) => {
  return await api.getReq(
    `/admin/users/listAlumnniprofile?pt=${pt}&place=${place}&filter=${encodeURIComponent(
      'passout_year=' + passout_year + '&company=' + company + '&institute=' + institute
    )}`
  );
};

export const addJobFest = async (body) => {
  return await api.PostReq(`/admin/users/jobfest/create`, body);
};
export const getJobFestDetail = async (job_id = '') => {
  return await api.getReq(`/admin/jobfest/details?id=${job_id}`);
};
export const editJobFest = async (id, body) => {
  return await api.PostReq(`/admin/users/jobfest/edit?id=${id}`, body);
};

export const getInstituteListing = async (q = '', id = '') => {
  return await api.getReq(`/admin/users/getInstituteList?q=${q}&selected=${id}`);
};

export const getAdminList = async (q = '') => {
  return await api.getReq(`/admin/users/adminList?q=${q}`);
};

export const setMultipleJobsStatus = async (body) => {
  return await api.PostReq(`/admin/jobs/selected_jobs`, body);
};

export const addJobInFest = async (body) => {
  return await api.PostReq(`/admin/jobfest/job/create`, body);
};

export const getJobFestDrop = async () => {
  return await api.getReq(`/admin/jobfest/list_job_fest`);
};

export const getJobLocationCities = async (id) => {
  return await api.getReq(`admin/jobs/cities?id=${id}`);
};

export const getJobFestApplicantList = async (page = '', festId = '', jobId = '', body) => {
  return await api.PostReq(
    `admin/jobfest/getJobFestApplicants?page=${page}&fest_id=${festId}&job_id=${jobId}`,
    body
  );
};

export const getAllFestJobList = async (
  page = '',
  festId = '',
  applicant_id = '',
  body = '',
  order,
  sortField
) => {
  return await api.PostReq(
    `admin/jobfest/getAlljobsListing/joblist?page=${page}&fest_id=${festId}&applicant_id=${applicant_id}&order=${order}&sortField=${sortField}`,
    body
  );
};

export const getFestJobDetail = async (job_id = '') => {
  return await api.getReq(`/admin/jobfest/job/details?id=${job_id}`);
};

export const updateJobInFest = async (id, body) => {
  return await api.PostReq(`/admin/jobfest/job/edit?id=${id}`, body);
};

export const setMultipleJobsFestStatus = async (body) => {
  return await api.PostReq(`/admin/jobfest/selectedJobFest`, body);
};
export const addEvent = async (body) => {
  return await api.PostReq(`/admin/event/add_event`, body);
};

export const eventList = async (body) => {
  return await api.PostReq(`/admin/event/allEventListing`, body);
};

export const ViewSingleEventDetail = async (id) => {
  return await api.getReq(`admin/event/view_event?id=${id}`);
};

export const addEventPage = async (body) => {
  return await api.PostReq(`/admin/event/eventcreatepage`, body);
};

export const viewEventBookingDetails = async (id, body) => {
  return await api.PostReq(`/admin/event/view_booking?id=${id}`, body);
};

// listgroup

export const adminGroup = async (body) => {
  return await api.PostReq(`/admin/groups/add_group`, body);
};

export const postAllGroupListing = async (body) => {
  return await api.PostReq(`/admin/groups/getAllGroupsListing`, body);
};

export const getGroupInstituteList = async (q = '') => {
  return await api.getReq(`/admin/users/getInstituteList?q=${q}`);
};
// get edit
export const getDetailGroup = async (id) => {
  return await api.getReq(`/admin/groups/detail_group?id=${id}`);
};

export const postGroupSelected = async (body) => {
  return await api.PostReq(`/admin/groups/selectedMembers`, body);
};
// edit post
export const postEditGroup = async (body) => {
  return await api.PostReq(`/admin/groups/edit_group`, body);
};

// chapter
export const getChaptersList = async (body) => {
  return await api.PostReq(`/admin/chapters/getAllChaptersListing`, body);
};

export const addSocialChapter = async (body) => {
  return await api.PostReq(`/admin/chapters/add_chapter`, body);
};

export const userChapterDetail = async (id) => {
  return await api.getReq(`/admin/chapters/detail_chapter?id=${id}`);
};

export const updateChapter = async (body) => {
  return await api.PostReq(`/admin/chapters/edit_chapter`, body);
};
export const setMultipleJobsInFestStatus = async (body) => {
  return await api.PostReq(`/admin/jobfest/selectedMultiple`, body);
};

export const getCategoryDropforShop = async () => {
  return await api.getReq(`/admin/categoryLevelsDropdown`);
};

export const editAdminEvent = async (body) => {
  return await api.PostReq(`/admin/event/edit_event`, body);
};

export const getEventCategory = async (q = '') => {
  return await api.getReq(`admin/life_skills_api/getEventCategories?q=${q}`);
};

export const eventSelected = async (body) => {
  return await api.PostReq(`/admin/event/selected `, body);
};

export const getMainCategory = async (
  page = '',
  sortfield = '',
  order = '',
  search_name = '',
  search_id = '',
  search_category_id = '',
  search_active = ''
) => {
  return await api.getReq(
    `/admin/pages/catmainList?page=${page}&sortfield=${sortfield}&order=${order}&search_name=${search_name}&search_id=${search_id}&search_category_id=${search_category_id}&search_active=${search_active}`
  );
};

export const addSocialCat = async (body) => {
  return await api.PostReq(`/admin/pages/createCategory`, body);
};

export const addShopCategory = async (body) => {
  return await api.PostReq(`/admin/category`, body);
};
export const getShopCategoryList = async (body) => {
  return await api.getReq(`/admin/categoryFilter`, body);
};
export const deleteCategoryList = async (id) => {
  return await api.delReq(`/admin/deleteCategory?id=${id}`);
};
export const deleteImage = async (id, opt) => {
  return await api.getReq(`/admin/productAction?id=${id}&opt=${opt}`);
};

export const getAdminById = async (id = '') => {
  return await api.getReq(`/admin/users/adminList?selected=${id}`);
};

export const updateUserAvatar = async (id = '', body) => {
  return await api.PostReq(`/admin/user/updateUserAvatar?id=${id}`, body);
};

export const globalStateChangeChapter = async (body) => {
  return await api.PostReq(`/admin/chapters/selected`, body);
};

export const getShopCatDetail = async (id = '') => {
  return await api.getReq(`/admin/viewCategory?id=${id}`);
};

export const remaionActionCategoryList = async (id, option) => {
  return await api.getReq(`/admin/categoryAction?id=${id}&opt=${option}`);
};
export const setMultipleCategoryStatus = async (body) => {
  return await api.PostReq(
    `/admin/selectedAction?ids=${body?.ids}&selectedAction=${body?.selectedAction}`
  );
};

export const setMultipleOrderStatus = async (body) => {
  return await api.PostReq(`/admin/mainMultiOrderAction`, body);
};

export const updateSocialPageCat = async (body) => {
  return await api.PostReq(`/admin/pages/editCategory`, body);
};

export const userSkills = async (id = '', type = '') => {
  return await api.getReq(
    `/admin/life_skills_api/getPublicUserProfileLifeSkillData?user_id=${id}&type=${type}`
  );
};

export const getNews = async (
  type = 'campus_news',
  page_number = 1,
  sort_field = 'id',
  order = '',
  id = '',
  title = ''
) => {
  return await api.getReq(
    `/admin/campus_news/index?type=${type}&page_number=${page_number}&sort_field=${sort_field}&order=${order}&id=${id}&title=${title}`
  );
};

export const addCampusNews = async (body) => {
  return await api.PostReq(`admin/campus_news/add`, body);
};

export const getCampusNewsView = async (id = '') => {
  return await api.getReq(`admin/campus_news/view?id=${id}`);
};

export const editCampusNews = async (body) => {
  return await api.PostReq(`/admin/campus_news/edit`, body);
};

export const getProductAttribute = async (id) => {
  return await api.PostReq(`admin/product/attributes?attributes=${id}`);
};
export const getResumaSearchAttribute = async (search) => {
  return await api.getReq(`/admin/jobs/keywords?q=${search}`);
};

export const getShopProductList = async (body) => {
  return await api.getReq(
    `/admin/products?proId=${body?.proId}&name=${body?.name}&code=${body?.code}&feature_product=${body?.feature_product
    }&soldout=${body?.soldout}&category_id=${body?.category_id}&attributes=${body?.attributes
    }&status=${body?.status}&order=${body?.order ? body?.order : ''}&sortfield=${body?.sortfield ? body?.sortfield : ''
    }&page=${body?.page ? body?.page : 1}`
  );
};

export const getInstituteAnalysisData = async (instituteId, year = '') => {
  return await api.getReq(
    `/admin/users/instituteAnalysisData?institution_id=${instituteId}&estyear=${year}`
  );
};

export const addAdminInstitute = async (body) => {
  return await api.PostReq(`/admin/users/adminInstituteListSave`, body);
};

export const getSocialPageCatDetail = async (id) => {
  return await api.getReq(`/admin/pages/category/view?id=${id}`);
};

export const globalStateChangeSocialPageCat = async (body) => {
  return await api.PostReq(`/admin/pages/category/selected`, body);
};

export const getFaqList = async (page) => {
  return await api.getReq(`/admin/faq/faqList?page=${page}`);
};
export const addFaq = async (body, page) => {
  return await api.PostReq(`/admin/faq/searchFaq?page=${page}`, body);
};
export const addFaqQuestion = async (body) => {
  return await api.PostReq(`faq/addFaq`, body);
};

export const addnews = async (body) => {
  return await api.PostReq(`/admin/news/add`, body);
};

export const addClubnews = async (body) => {
  return await api.PostReq(`/institute/addClubNews`, body);
};

export const addCompanyFromAdmin = async (body) => {
  return await api.PostReq(`/admin/company/add_company`, body);
};

export const editCompanyFromAdmin = async (body) => {
  return await api.PostReq(`/company/saveCompanyInfo`, body);
};

export const getCompaniesForAdmin = async (
  page,
  { search_companyname = '', institute_type = '', search_companyid = '' },
  { sort_field = 'id', order = 'DESC' }
) => {
  return await api.getReq(
    `/admin/company/getCompaniesListing?page_number=${page}&sort_field=${sort_field}&order=${order}&search_companyname=${search_companyname}&institute_type=${institute_type}&search_companyid=${search_companyid}`
  );
};

export const addCompanyActions = async (body) => {
  return await api.PostReq(`/admin/company/selected`, body);
};

//  other > feedback
export const getFeedbackList = async (page) => {
  return await api.getReq(`/admin/feedback/feedbackList?page=${page}`);
};

export const getFeedbackListSearch = async (body) => {
  return await api.PostReq(`/admin/feedback/getFeedbackSearch`, body);
};

export const addFeedback = async (body) => {
  return await api.PostReq(`/feedback/addFeedback`, body);
};

// other >contect
export const getContectList = async (page) => {
  return await api.getReq(`/admin/contact/contactList?page=${page}`);
};

export const getConteactListSearch = async (body) => {
  return await api.PostReq(`/admin/contact/getContactSearch`, body);
};

export const addContectus = async (body) => {
  return await api.PostReq(`/contact/addContactUs`, body);
};

//whatnew

export const getOtherWhatNews = async (
  type = 'whatsnew',
  page_number = 1,
  sort_field = 'id',
  order = 'DESC',
  id = '',
  title = '',
  status = ''
) => {
  return await api.getReq(
    `/admin/campus_news/index?type=${type}&page_number=${page_number}&sort_field=${sort_field}&order=${order}&id=${id}&title=${title}&status=${status}`
  );
};

export const getClubNews = async (body) => {
  return await api.PostReq(`/institute/getClubNews`, body);
};

export const addOtherWhatNews = async (body) => {
  return await api.PostReq(`/admin/whats_new/add`, body);
};
export const getAffiliatedIns = async (id) => {
  return await api.getReq(`/admin/users/keyup/affiliated?institute_id=${id}`);
};

export const getWhatNewsView = async (id = '') => {
  return await api.getReq(`/admin/whats_new/view?id=${id}`);
};
export const getNewsView = async (id = '') => {
  return await api.getReq(`/admin/news/view?id=${id}`);
};

export const getClubNewsView = async (body) => {
  return await api.PostReq(`/institute/getSingleClubNews`, body);
};

export const deleteClubNews = async (id) => {
  return await api.PostReq(`/institute/deleteClubNews?id=${id}`);
};

export const getTaggingSkillDrop = async (search = '') => {
  return await api.getReq(`/dropdown/getSkillsKeywords?search=${search}`);
};
export const getSkillsDropdown = async (q = 'a', pageNo = 1, pageLimit = 50) => {
  return await api.getReq(
    `/admin/life_skills_api/getGenTags?q=${q}&page=${pageNo}&limit=${pageLimit}`
  );
};

export const addEditNews = async (body) => {
  return await api.PostReq(`/admin/whats_new/edit`, body);
};
export const EditNews = async (body) => {
  return await api.PostReq(`/admin/news/edit`, body);
};
export const editClubNews = async (id, body) => {
  return await api.PostReq(`/institute/editClubNews?id=${id}`, body);
};

export const getClasses = async (type = 'others') => {
  return await api.getReq(`admin/campus_news/getClasses?type=${type}`);
};
// export const getClasses = async (type = 'others') => {
//   return await api.getReq(`admin/campus_news/getClasses?type=${type}`);
// };

// /admin/products/create
export const addProductAdmin = async (body) => {
  return await api.PostReq(`/admin/products/create`, body);
};

export const getCategoryDrop = async (search, body) => {
  return await api.PostReq(`/admin/CategoriesList?category_name=${search}`, body);
};
export const globalActionChangeProduct = async (body) => {
  return await api.PostReq(`/admin/multiProductAction`, body);
};

export const getProductDetail = async (id = '') => {
  return await api.getReq(`/admin/singleProductView?id=${id}`);
};

export const editJobManage = async (body) => {
  return await api.PostReq(`/admin/jobs/editJob`, body);
};

export const allMemberList = async (body) => {
  return await api.PostReq(`/admin/groups/getAllGroupMembersList`, body);
};

export const companyAdminsList = async (id = '') => {
  return await api.getReq(
    `/admin/companies/manage_admins?company_id=${id}&page_number= 1&sort_field=admin_id&order=desc`
  );
};
export const companyLeaderList = async (id = '') => {
  return await api.getReq(
    `/admin/companies/manage_leaders?company_id=${id}&page_number=1&sort_field=user_id&order=desc`
  );
};

//other recruitment

export const getRecruitmentList = async (page) => {
  return await api.getReq(`/admin/recruitments/recruitmentsList?page=${page}`);
};

export const getRecrutimentSearchList = async (body) => {
  return await api.PostReq(`/admin/recruitments/getRecruitmentSearch`, body);
};

//frontend recturiment

export const getRecruiterSearch = async (body) => {
  return await api.PostReq(`/recruitments/addRecruitments`, body);
};

export const getRecLocation = async () => {
  return await api.getReq(`/recruitments/getCountryList`);
};

/**Others reports list */
export const addOrderStatus = async (body) => {
  return await api.PostReq(`/admin/orders/status/create`, body);
};

export const getOrderStatusList = async (page, sortfield, order) => {
  return await api.getReq(
    `/admin/orders/status?page=${page}&sortfield=${sortfield}&order=${order}`
  );
};

export const orderStatusUpdate = async (id, opt) => {
  return await api.getReq(`/admin/orders/updateStatus?id=${id}&opt=${opt}`);
};

export const getOrdersList = async (page, sortfield, order) => {
  return await api.getReq(`/admin/orders?page=${page}&sortfield=${sortfield}&order=${order}`);
};
/**Others reports list */
// cms pages
export const getPageListForDropInCms = async () => {
  return await api.getReq(`/admin/cms/pageList`);
};

export const getTemplateListForDropInCms = async () => {
  return await api.getReq(`/admin/cms/pageTemplate`);
};

export const getCmsList = async () => {
  return await api.getReq(`/admin/cms/cmsList`);
};

export const searchPageDatalist = async (page, body) => {
  return await api.PostReq(`/admin/cms/searchPage?page=${page}`, body);
};

export const addCms = async (body) => {
  return await api.PostReq(`/admin/cms/addPage`, body);
};

export const updateCmspage = async (id, body) => {
  return await api.PostReq(`/admin/cms/editPage?id=${id}`, body);
};

export const cmsDetail = async (id = '') => {
  return await api.getReq(`/admin/cms/pageView?id=${id}`);
};

export const cmsUdateStatus = async (id = '', status) => {
  return await api.PostReq(`/admin/cms/deleteEnable?id=${id}`, status);
};

/**Others reports list */
export const reportsList = async (page) => {
  return await api.getReq(`/admin/report/reportList?page=${page}`);
};

export const actionReport = async (id, body) => {
  return await api.PostReq(`/admin/report/actionsReport?id=${id}`, body);
};

export const searchByReportId = async (body) => {
  return await api.PostReq(`/admin/report/searchReport`, body);
};

export const getManageActivities = async (body) => {
  return await api.PostReq(`admin/event/getEventActivities`, body);
};

export const addManageActivities = async (body) => {
  return await api.PostReq(`admin/event/add_activity`, body);
};

export const getViewManageActivity = async (event_id, id) => {
  return await api.getReq(`/admin/event/detail_activity?event_id=${event_id}&id=${id}`);
};

export const selectedManageActivities = async (body) => {
  return await api.PostReq(`/admin/event/selectedActivity`, body);
};

export const getParticipantsList = async (body) => {
  return await api.PostReq(`/admin/event/getActivityParticipantsList`, body);
};

export const addActivityInvitation = async (body) => {
  return await api.PostReq(`/admin/event/activity_invitation`, body);
};

export const shopOrderDetail = async (id = '') => {
  return await api.getReq(`/admin/orders/view?id=${id}`);
};

export const productSingleAction = async (id = '', opt = '') => {
  return await api.getReq(`/admin/productAction?id=${id}&opt=${opt}`);
};

export const getProductImages = async (id = '', page = '', sortfield = '', order = '') => {
  return await api.getReq(
    `/admin/products/showImages?id=${id}&page=${page}&sortfield=${sortfield}&order=${order}`
  );
};
// /admin/products/actionImg

export const productImageAction = async (id = '', opt = '') => {
  return await api.getReq(`/admin/products/actionImg?id=${id}&opt=${opt}`);
};

export const downloadOrderInvoice = async (id = '') => {
  return await api.getReq(`admin/orders/invoice?orderId=${id}`);
};
export const getEventTicketList = async (body) => {
  return await api.PostReq(`/admin/event/eventTickerList`, body);
};

export const addEventTicketList = async (body) => {
  return await api.PostReq(`/admin/event/add_ticket`, body);
};

export const getEventTicketDetails = async (event_Id, id) => {
  return await api.getReq(`/admin/event/detail_ticket?event_id=${event_Id}&id=${id}`);
};

export const updateEventTicketList = async (body) => {
  return await api.PostReq(`/admin/event/add_ticket`, body);
};

export const globalStateTicketChange = async (body) => {
  return await api.PostReq(`/admin/event/selectedTickets`, body);
};

export const getCompaniesCityDropDown = async (search, cityId = '') => {
  return await api.getReq(`/admin/company/jobs_cities?q=${search}&id=${cityId}`);
};
export const getJobResume = async (body) => {
  return await api.PostReq(`/admin/jobs/searchResume`, body);
};

export const getCompanySpecialityDropDown = async (search, id = '') => {
  return await api.getReq(`/admin/company/jobs_categories?q=${search}&id=${id}`);
};

export const getCompanyCompanyDetail = async (companyId) => {
  return await api.getReq(`/admin/company/detail_company?id=${companyId}`);
};

export const updateCompanyFromAdmin = async (body) => {
  return await api.PostReq(`/admin/company/edit_company`, body);
};
export const addManageSponsors = async (body) => {
  return await api.PostReq(`/admin/event/add_sponsor`, body);
};

export const listingManageSponsors = async (body) => {
  return await api.PostReq(`admin/event/getEventSponsorsList`, body);
};
export const detailsManageSponsors = async (event_id = '', id = '') => {
  return await api.getReq(`/admin/event/detail_sponsor?event_id=${event_id}&id=${id}`);
};

export const getUserAdminList = async (search) => {
  return await api.getReq(`/admin/users/adminList?q=${search}`);
};

export const globalStateChangeinPage = async (body) => {
  return await api.PostReq(`/admin/pages/selectedPage`, body);
};

export const getUserManagePages = async (body) => {
  return await api.getReq(`/admin/pageList?page=1&order=DESC&sortfield=id`);
};

export const getPeopleForAddChapters = async (userName) => {
  return await api.getReq(`/user/getfriendlist?query=${userName}`);
};
export const adminEventInvitation = async (body) => {
  return await api.PostReq(`/admin/event/send_invitation`, body);
};

export const addChaptersSocial = async (body) => {
  return await api.PostReq('/chapter/createChapter', body);
};

export const eventStatsList = async (body) => {
  return await api.PostReq(`/admin/event/getAllEventStats`, body);
};
export const eventStatsSelected = async (body) => {
  return await api.PostReq(`/admin/event/selectedEventUser`, body);
};

export const getSponserRequestList = async (body) => {
  return await api.PostReq('/admin/event/getEventSponsorRequests', body);
};

export const shopSaveOrder = async (body) => {
  return await api.PostReq('/admin/orders/saveOrder', body);
};

export const getMentorDirectoryList = async (
  type = 'all',
  name = '',
  category = '',
  area = '',
  location = ''
) => {
  return await api.getReq(
    `/mentorship/getMentorsDirectory?filter=${encodeURIComponent(
      `mentor-type=${type}&mentor-name=${name}&category=${category}&mentoship-areas=${area}&location=${location}&offset=`
    )}`
  );
};

export const getMentorDirectoryListOuter = async (filter = '') => {
  return await api.getReq(`/mentorship/getMentorsDirectory?filter=${filter}&offset=`);
};

export const selectedSponser = async (body) => {
  return await api.PostReq('/admin/event/selectedSponsor', body);
};

//forgot password

export const forgotPasssword = async (body) => {
  return await api.PostReq(
    `/user/forgotpass?url=${process.env.REACT_APP_DEFAULT_LOCAL + '/recover/change-passowrd'}`,
    body
  );
};

export const resetPasssword = async (token, body) => {
  return await api.PostReq(`/user/recover?token=${token}`, body);
};

/**
 * domain-config
 */

export const addDomain = async (body) => {
  return await api.PostReq(`/admin/instituteDomain/addDomain`, body);
};

export const addClubDomain = async (body) => {
  return await api.PostReq(`/institute/addClubDomain`, body);
};

export const editClubDomain = async (id, body) => {
  return await api.PostReq(`/institute/editClubDomain?id=${id}`, body);
};

export const editDomain = async (id, body) => {
  return await api.PostReq(`/admin/instituteDomain/updateDomain?id=${id}`, body);
};

export const getDomainDetail = async (id = '') => {
  return await api.getReq(`/user/domainDetail?institute_id=${id}`);
};

export const getClubDomainDetail = async (body) => {
  return await api.PostReq(`/institute/clubDomainDetail`, body);
};

export const getOrderExports = async (orderId) => {
  return await api.getReq(`/admin/orders/exportOrders?orderId=${orderId}`);
};

/**
 * blog
 */

export const blogList = async (page = 1) => {
  return await api.getReq(`/blogs/list?page=${page}`);
};

export const addBlog = async (body) => {
  return await api.PostReq(`/admin/blogs/add`, body);
};

export const editBlog = async (body) => {
  return await api.PostReq(`/admin/blogs/edit`, body);
};

export const viewBlog = async (id = '') => {
  return await api.getReq(`/admin/blogs/blogView?id=${id}`);
};

/**
 *
 * @param {*} body
 * @returns
 */

export const clubBlogList = async (body) => {
  return await api.PostReq(`/institute/getClubBlogs`, body);
};

export const addClubBlog = async (body) => {
  return await api.PostReq(`/institute/addClubBlogs`, body);
};

export const editClubBlog = async (id, body) => {
  return await api.PostReq(`/institute/editClubBlog?id=${id}`, body);
};

export const viewClubBlog = async (id = '') => {
  return await api.getReq(`/institute/viewClubBlog?id=${id}`);
};

/*end */
export const SK_registerMentor = async (body) => {
  return await api.PostReq(`/admin/blogs/blogView`, body);
};
export const getPagePeopleListing = async (page_id, type, search, offset, limit) => {
  return await api.PostReq(
    '/pages/getPeopleList/' + page_id + '?q=' + search + '&offset=' + offset + '&limit=' + limit,
    type
  );
};

/**
 * testimonial
 */

export const testimonialList = async (page) => {
  return await api.getReq(`/admin/testimonial/list?page=${page}`);
};

export const addTestimonial = async (body) => {
  return await api.PostReq(`/admin/testimonial/add`, body);
};

export const editTestimonial = async (body) => {
  return await api.PostReq(`/admin/testimonial/edit`, body);
};

export const viewTestimonial = async (id = '') => {
  return await api.getReq(`/admin/testimonial/details?id=${id}`);
};

export const deleteTestimonial = async (id = '') => {
  return await api.getReq(`/admin/testimonial/delete?id=${id}`);
};

// tags for blog

export const addAluminiImg = async (body) => {
  return await api.PostReq(`/admin/alumincreate`, body);
};

export const deleteAluminiImg = async (id) => {
  return await api.delReq(`/admin/deletesingleimage?id=${id}`);
};

export const editAluminiImg = async (body) => {
  return await api.PostReq(`/admin/edit-alumin`, body);
};

export const getAluminiImages = async () => {
  return await api.getReq(`/admin/details-alumin`);
};

export const getTagList = async (search = '', id = '') => {
  return await api.getReq(`/admin/blogs/listTags?institute_id=${id}&search=${search}`);
};

export const deleteTag = async (id) => {
  return await api.getReq(`/admin/blogs/deleteTags?id=${id}`);
};

export const deleteClubBlog = async (id) => {
  return await api.PostReq(`/institute/deleteClubBlog?id=${id}`);
};

export const removeFriend = async (body) => {
  return await api.PostReq(`/user/removeFriend`, body);
};

export const addAlternativeImages = async (id, imageId, body) => {
  return await api.PostReq(`/admin/product/addImages?id=${id}&imageId=${imageId}`, body);
};
export const getAlternativeImageDetail = async (editId, imageId) => {
  return await api.getReq(`/admin/product/editImage?id=${editId}&imageId=${imageId}`);
};

// content and it's type for institute

export const getContentList = async (page = '') => {
  return await api.PostReq(`/admin/institute/contentList?page=${page} `);
};

export const addContent = async (body) => {
  return await api.PostReq(`/admin/institute/addContent`, body);
};

export const addClubContent = async (body) => {
  return await api.PostReq(`/admin/institute/addClubContent`, body);
};

export const editContent = async (body, id) => {
  return await api.PostReq(`/admin/institute/editContent?id=${id}`, body);
};

export const editClubContent = async (body, id) => {
  return await api.PostReq(`/admin/institute/editClubContent?id=${id}`, body);
};

export const deleteAboutImage = async (id) => {
  return await api.delReq(`admin/deleteaboutsingleimage?id=${id}`);
};

export const deleteClubAboutImage = async (id) => {
  return await api.PostReq(`/admin/institute/deleteClubContentImages?id=${id}`);
};

// club

export const getClubList = async (body) => {
  return await api.PostReq(`/admin/clubs/getAllClubListing`, body);
};

export const addClub = async (body) => {
  return await api.PostReq(`/admin/clubs/add_club`, body);
};

export const getClubDetail = async (id) => {
  return await api.getReq(`/admin/clubs/detail_club?id=${id}`);
};

export const editClub = async (body) => {
  return await api.PostReq(`/admin/clubs/edit_club`, body);
};

export const postGroupMultiSelected = async (body) => {
  return await api.PostReq(`/admin/groups/selected`, body);
};

export const exportOrder = async (body) => {
  return await api.PostReq(`/admin/orders/exportOrdersList`, body);
};

export const exportUser = async (body) => {
  return await api.PostReq(`/admin/users/exportUserList`, body);
};

export const sidebarAccess = async () => {
  return await api.getReq(`/admin/users/sidebar`);
};

export const getSamapleDoc = async (type) => {
  return await api.getReq(`/admin/import/downloadSampleFile?type=${type}`);
};

export const uploadSheets = async (body) => {
  return await api.PostReq(`/admin/import/import`, body);
};
export const deletePageContent = async (pageId) => {
  return await api.getReq(`/admin/institute/deletePage?page_id=${pageId}`);
};
export const getSinglePageDetail = async (pageId) => {
  return await api.getReq(`/admin/institute/adminPageDetails?page_id=${pageId}`);
};

export const getSingleClubPageDetail = async (body) => {
  return await api.PostReq(`/admin/institute/clubAboutPageContent`, body);
};

export const addGallery = async (body) => {
  return await api.PostReq(`/admin/gallery/albums/create`, body);
};

export const editGallery = async (id, ins_id, body) => {
  return await api.PostReq(`/admin/gallery/albums/update?id=${id}&institute_id=${ins_id}`, body);
};

export const getGalleryList = async (page) => {
  return await api.getReq(`/admin/gallery/albums?page=${page}`);
};
export const deleteGallery = async (mediaId) => {
  return await api.delReq(`/admin/gallery/albums/delete?media_id=${mediaId}`);
};

export const deleteSingleImage = async (media_id, id) => {
  return await api.delReq(`/admin/gallery/albums/deletesingleimage?media_id=${media_id}&id=${id}`);
};

/**gallery for club  */
export const addClubGallery = async (body) => {
  return await api.PostReq(`/institute/clubGallery`, body);
};

export const editClubGallery = async (id, ins_id, body) => {
  return await api.PostReq(`/admin/gallery/albums/update?id=${id}&institute_id=${ins_id}`, body);
};

export const getClubGalleryList = async (body) => {
  return await api.PostReq(`/institute/getClubGallery`, body);
};

export const deleteClubGallery = async (mediaId) => {
  return await api.delReq(`/admin/gallery/albums/delete?media_id=${mediaId}`);
};

export const deleteClubSingleImage = async (media_id, id) => {
  return await api.delReq(`/admin/gallery/albums/deletesingleimage?media_id=${media_id}&id=${id}`);
};

/**end */

/**
 * /admin/homepage-layout >websetting
 */
export const addHomepageLayout = async (body) => {
  return await api.PostReq(`/admin/institute/addHomeSliderContent`, body);
};

export const getHomepageLayout = async () => {
  return await api.getReq(`/admin/institute/listHomeSliderContent`);
};

export const editHomepageLayout = async (id, body) => {
  return await api.PostReq(`/admin/institute/editHomeSliderContent?id=${id}`, body);
};

// /admin/gallery/albums
//Email queue
export const getEmailQueueList = async (page) => {
  return await api.getReq(`/admin/emailQueue/getEmailQueueList?page=${page}`);
};
export const deleteEmailQueue = async (id) => {
  return await api.delReq(`/admin/emailQueue/deleteEmailQueueList?id=${id}`);
};
export const emailQueueDetail = async (id) => {
  return await api.getReq(`/admin/emailQueue/getSingleEmailQueueList?id=${id}`);
};

export const exportUserReport = async (page = '') => {
  return await api.getReq(`/admin/export/file?page=${page}`);
};
export const getSingleDetailGallery = async (id = '') => {
  return await api.getReq(`/admin/gallery/albums/view?id=${id}`);
};

export const importUserReport = async (page = '') => {
  return await api.getReq(`/admin/import/file?page=${page}`);
};

export const menuList = (sort = '', order = '', page = '') => {
  return api.getReq(`/admin/menu-list?sort_field=${sort}&order=${order}&page=${page}`);
};

export const addMenuList = (body) => {
  return api.PostReq(`/admin/add-menu`, body);
};

export const singleMenuListDetail = (id) => {
  return api.getReq(`/admin/menu-item?menu_id=${id}`);
};

export const getChildMenuList = (id) => {
  return api.getReq(`/admin/getsubmenu?id=${id}`);
};

export const deleteMenuList = (id, state_id) => {
  return api.delReq(`/admin/menu-delete?menu_id=${id}&state_id=${state_id}`);
};

export const editSubMenu = (body) => {
  return api.PostReq(`/admin/edit-sub-menu`, body);
};

/** communication plan starts */

export const communicationList = (sort = '', order = '', page = '') => {
  return api.getReq(
    `/admin/communication/plan/planInfoList?sort_field=${sort}&order=${order}&page=${page}`
  );
};

export const addCommunicationPlan = (body) => {
  return api.PostReq(`/admin/communication/plan/create`, body);
};

export const editCommunicationPlan = (id, body) => {
  return api.PostReq(`/admin/communication/plan/update?id=${id}`, body);
};

export const singleCommunicationPlan = (id) => {
  return api.getReq(`/admin/communication/plan/planDetail?id=${id}`);
};

export const deleteCommunicationPlan = (id) => {
  return api.delReq(`/admin/communication/plan/deletePlan?id=${id}`);
};

/** communication plan ends */

export const communicationPlanList = (id, page = '') => {
  return api.getReq(`/admin/assign/plan/planInfoList?institute_id=${id}&page=${page}`);
};

export const communicationPlanListInst = () => {
  return api.getReq(`/admin/assign/plan/getInstituteAssignedPlans`);
};

export const assignPlan = (id, body) => {
  return api.PostReq(`/admin/assign/plan/create?institute_id=${id}`, body);
};

export const updateAssignedPlan = (id, body) => {
  return api.PostReq(`/admin/assign/plan/update?id=${id}`, body);
};

export const getHeaderList = () => {
  return api.getReq(`/admin/menu-list`);
};

export const getNewMenuList = () => {
  return api.getReq(`/admin/new-menu-list`);
};

export const getBannerImage = (id) => {
  return api.getReq(`/admin/institute/banner_list?type=${id}`);
};

export const getClubBannerImage = (body) => {
  return api.PostReq(`/admin/institute/clubHomePageContent`, body);
};

export const getStudentList = (search = '') => {
  return api.getReq(`/admin/communication-logs/getStudentsList?search=${search}`);
};

export const getAlumnisList = (search = '') => {
  return api.getReq(`/admin/communication-logs/getAlumniList?search=${search}`);
};

export const getFacultyList = (search = '') => {
  return api.getReq(`/admin/communication-logs/getFacultyList?search=${search}`);
};

export const getAllList = (search = '') => {
  return api.getReq(`/admin/communication-logs/getAllList?search=${search}`);
};

export const templateList = (communication) => {
  return api.getReq(`/admin/communication-logs/getAssignedTemplates?type=${communication}`);
};

export const sendCommunication = (body) => {
  return api.PostReq(`/admin/communication-logs/sendCommunications`, body);
};

export const addBanner = (body) => {
  return api.PostReq(`/admin/institute/add_page_banner`, body);
};

export const addClubBanner = (body) => {
  return api.PostReq(`/admin/institute/addClubHomeContent`, body);
};

export const editClubBanner = (id, body) => {
  return api.PostReq(`/admin/institute/editClubHomeContent?id=${id}`, body);
};

export const getInstAdminList = (id) => {
  return api.getReq(`/admin/institute/getInstituteTemplateAdmin?institute_id=${id}`);
};

export const adminEventTicketList = (body) => {
  return api.PostReq(`/admin/event/getEventBookings`, body);
};
export const getLogReport = (page = '') => {
  return api.getReq(`/admin/communication-logs/getAllLogsList?page=${page}`);
};

export const getDetailedLogReport = (id, page) => {
  return api.getReq(`/admin/communication-logs/logs/list?communicate_id=${id}&page=${page}`);
};

export const consumptionReport = (id) => {
  return api.getReq(`/admin/communication/stats?institute_id=${id}`);
};

export const emailReport = (id) => {
  return api.getReq(`/admin/communication/EmailStat?communicate_id=${id}`);
};

/**
 * admin panel->menu list >add
 * @returns
 */
export const addNewMenu = () => {
  return api.getReq(`/admin/microsite/openPageTemplate`);
};

/** CRON JOB API STARTS */
export const addCronJob = (body) => {
  return api.PostReq(`/admin/scheduler/cronjob/add`, body);
};

export const getCronJob = (page) => {
  return api.getReq(`/admin/scheduler/cronjob/list?page=${page}`);
};

export const getSingleCronJobDetail = (id) => {
  return api.getReq(`/admin/scheduler/cronjob/detail?id=${id}`);
};

export const logDetail = (id, page = '', per_page = '') => {
  return api.getReq(`/admin/scheduler/log/list?cronjob_id=${id}&page=${page}&per_page=${per_page}`);
};

export const getSinglelogDetail = (id) => {
  return api.getReq(`/admin/scheduler/log/detail?id=${id}`);
};
/** CRON JOB API ENDS */

export const positionMenu = (body) => {
  return api.PostReq(`/admin/position-menu`, body);
};

export const positionQuickLink = (body) => {
  return api.PostReq(`/admin/position-quick-link`, body);
};

export const parentMenu = () => {
  return api.getReq(`/admin/parent-menu`);
};

/** QUICK LINKS START */
export const quickLinkList = (page) => {
  return api.getReq(`/admin/quick-link-list?page=${page}`);
};

export const editQuickLink = (id, body) => {
  return api.PostReq(`/admin/edit-qucik-link?id=${id}`, body);
};

export const deleteQuickLink = (id) => {
  return api.getReq(`/admin/delete-quick-link?id=${id}`);
};

export const addQuickLinks = (body) => {
  return api.PostReq(`/admin/add-qucik-link`, body);
};
/** QUICK LINKS END */

// open page add
export const addOpenPageContent = (body) => {
  return api.PostReq(`/admin/microsite/openPageTemplate`, body);
};
/**
 * get page detail based on selected slug id
 * @param {slug_id} id
 * @returns
 */
export const getPageDetail = (id) => {
  return api.getReq(`/admin/get-page-menu-details?id=${id}`);
};

export const editOpenPageContent = (id, body) => {
  return api.PostReq(`/admin/microsite/editOpenPageTemplate?id=${id}`, body);
};

export const brithDayWish = (body) => {
  return api.PostReq(`/admin/users/sendWishesEmail`, body);
};

export const getComLogReport = (page = '') => {
  return api.getReq(`/admin/communication-logs/logs/list?page=${page}`);
};

export const WrokDayWish = (body) => {
  return api.PostReq(`/admin/users/sendWorkWishesEmail`, body);
};

export const getUserPageLiked = (pageId, page, sort = '', order = '', body) => {
  return api.PostReq(
    `/admin/page/getPageLikedUserList?pageId=${pageId}&sortfield=${sort}&order=${order}&page=${page}`,
    body
  );
};

/**
 * get crash list
 * @returns
 */
export const getCrashList = () => {
  return api.getReq(`/admin/users/crashReportList`);
};

/**
 * get single crash detail
 * @returns
 */
export const getCrashDetail = (id) => {
  return api.getReq(`/admin/users/crashReportView?id=${id}`);
};
