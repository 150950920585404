//  @copyright : ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
// @author     : Shiv Charan Panjeta

// All Rights Reserved.
// Proprietary and confidential :  All information contained herein is, and remains
// the property of ToXSL Technologies Pvt. Ltd. and its partners.
// Unauthorized copying of this file, via any medium is strictly prohibited.

import { useEffect, useState } from 'react';
import { Button, Container } from 'react-bootstrap';
import Alert from 'react-bootstrap/Alert';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Table from 'react-bootstrap/Table';
import ReactPaginate from 'react-paginate';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import Select from 'react-select';
import NoDataFound from '../../components/commoncomponents/NoDataFound';
import * as adminServices from '../../services/AuthServices';
import NotAllowed from '../NotAllowed';
import Loader from '../../components/commoncomponents/loader/loader-large';

export default function ClubList() {
  const navigate = useNavigate();
  const [response, setResponse] = useState(0);
  const roleType = useSelector((state) => state?.user?.details?.admin_type);
  const [insId, setInsId] = useState('');
  const [list, setList] = useState([]);
  const [instituteList, setInstituteList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchedData, setSearchData] = useState({
    group_id: '',
    group_name: '',
    group_institute: '',
    group_status: '',
    group_verified: ''
  });
  const [globalMsg] = useState('Please Select Any Record');
  const [showalert] = useState(false);
  const [showErrorComponent, setShowErrorComponent] = useState(false);
  const [loading, setLoading] = useState(true);
  const paths = useSelector((state) => state?.adminPaths?.paths);

  useEffect(() => {
    apiCall();
    getInstituteDropdownData();
  }, []);

  const onChangeSearch = (e) => {
    const { name, value } = e.target;
    setSearchData({ ...searchedData, [name]: value });
  };

  const apiCall = (
    page = '',
    sortfield = '',
    order = '',
    club_id = '',
    club_name = '',
    club_institute = '',
    club_status = '',
    group_verified = ''
  ) => {
    let pageNo = page ? page : 0;
    setCurrentPage(pageNo);
    let body = {
      page,
      sortfield,
      order,
      club_id,
      club_name,
      club_institute,
      club_status,
      group_verified
    };
    adminServices.getClubList(body).then((res) => {
      setLoading(false);
      if (res?.status === 200) {
        setList(res?.data?.data?.viewlist);
        setResponse(res?.data?.data?.totalRecords);
      }
    });
  };

  const getInstituteDropdownData = () => {
    adminServices.getInstituteDropdown().then((res) => {
      let arr = [];
      if (res?.status === 200) {
        let data = res?.data?.data?.allInstitutes;
        for (let i in data) {
          arr.push({ id: i, label: data[i], name: data[i] });
        }
      }
      setInstituteList(arr);
    });
  };

  const handleNaviagteEdit = (value, id) => {
    if (value === 'edit') {
      navigate('/admin/edit-club', { state: id });
    } else if (value == 'members') {
      navigate(`/admin/chapter/manage/${id}`, { state: 'club' });
    } else if (value == 'redirect') {
      navigate(`/admin/club/domain-config/${id}`);
    } else if (value == 'blog') {
      navigate(`/admin/club/blog/${id}`);
    } else if (value == 'news') {
      navigate(`/admin/club/news/${id}`);
    } else if (value == 'gallery') {
      navigate(`/admin/club/gallery/${id}`);
    } else if (value == 'person') {
      navigate(`/admin/club/contact-person/${id}`);
    } else if (value == 'about') {
      navigate(`/admin/club/about/page/${id}`);
    } else if (value == 'banner') {
      navigate(`/admin/club/banner/${id}`);
    }
  };

  const searchHandler = (page = '') => {
    apiCall(
      page,
      'id',
      'DESC',
      searchedData.group_id,
      searchedData.group_name,
      searchedData.group_institute,
      searchedData.group_status,
      searchedData.group_verified
    );
  };
  const handlePageClick = (a) => {
    let page = a?.selected + 1;
    searchHandler(page);
  };
  const resetHandler = (e) => {
    e.preventDefault();
    setSearchData({
      group_id: '',
      group_name: '',
      group_institute: '',
      group_status: '',
      group_verified: ''
    });
    setInsId('');
    apiCall();
  };

  const handleClick = () => {
    if (paths?.includes('admin/add-club')) {
      navigate('/admin/add-club');
    } else {
      setShowErrorComponent(true);
    }
  };

  return showErrorComponent ? (
    <NotAllowed />
  ) : (
    <div className="dash-main-content">
      <Container fluid>
        <div className="dash-page-content">
          <div className="dash-page-title">
            <Row>
              <Col xs={12} sm={12} md={12}>
                <div className="dash-title-bar d-flex align-items-center justify-content-between">
                  <div className="title-lft d-flex align-items-center">
                    <div className="dash-icn">
                      <i className="fas fa-users"></i>
                    </div>
                    <div className="dash-title-dis">
                      <h5>
                        Club <span>Listing</span>
                      </h5>
                    </div>
                  </div>

                  <div className="title-rt-btn d-flex align-items-center">
                    <Button
                      onClick={() => {
                        handleClick();
                      }}
                      className="green_button_admin mx-2"
                      // to="/admin/add-club"
                    >
                      Add New Club
                    </Button>
                    {roleType === '3' && (
                      <Link className="green_button_admin mx-2" to="/admin/add-banner?id=8">
                        Add Banner
                      </Link>
                    )}
                    {/* <div className="head-instite-sec">
                                                <Form.Select
                                                    aria-label="Default select example"
                                                    id="global"
                                                    onChange={(e) => {
                                                        globalActionHandler(e);
                                                    }}
                                                >
                                                    <option value="">Choose Action</option>
                                                    <option value="active">Active </option>
                                                    <option value="inactive">Inactive</option>
                                                    <option value="del">Delete</option>
                                                </Form.Select>
                                            </div> */}
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <div className="dash-content-card">
            <Alert variant={'success'} className={`${showalert ? '' : 'd-none'}`}>
              {globalMsg}
            </Alert>
            <div className="table-responsive">
              <Table className="custom-table-data" striped bordered hover>
                <thead>
                  <tr>
                    {/* <th>
                        <Form.Check
                          type="checkbox"
                          id="checkbox"
                          name="checkall"
                          checked={allId?.length === chekcedId?.length}
                          onChange={(e) => {
                            handleSelectAll(e);
                          }}
                          label=""
                        />
                      </th> */}
                    <th>Id</th>
                    <th>Club Name</th>
                    <th>Institute</th>
                    <th>Member</th>
                    <th>Status</th>
                    <th>Verified</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="search-table">
                    <td>
                      <Form.Control
                        type="text"
                        placeholder="Id"
                        name="group_id"
                        value={searchedData?.group_id}
                        onChange={(e) => {
                          onChangeSearch(e);
                        }}
                      />
                    </td>
                    <td>
                      <Form.Control
                        type="text"
                        placeholder="name"
                        name="group_name"
                        onChange={(e) => {
                          onChangeSearch(e);
                        }}
                        value={searchedData?.group_name}
                      />
                    </td>

                    <td>
                      <Select
                        className="basic-single"
                        isDisabled={false}
                        isLoading={false}
                        isClearable={false}
                        isRtl={false}
                        getOptionLabel={(e) => e.label}
                        isSearchable={true}
                        name="group_institute"
                        placeholder="Select Institute"
                        options={instituteList}
                        getOptionValue={(option) => option.id}
                        value={insId}
                        onChange={(e) => {
                          setSearchData({ ...searchedData, ['group_institute']: e.id });
                          setInsId(e);
                        }}
                      />
                    </td>
                    <td></td>

                    <td>
                      <Form.Select
                        aria-label="Default select example"
                        name="group_status"
                        value={searchedData?.group_status}
                        onChange={(e) => {
                          onChangeSearch(e);
                        }}>
                        <option value=""> - Select - </option>
                        <option value="1">Active</option>
                        <option value="0">Inactive</option>
                      </Form.Select>
                    </td>
                    <td>
                      <Form.Select
                        aria-label="Default select example"
                        name="group_verified"
                        value={searchedData?.verified}
                        onChange={(e) => {
                          onChangeSearch(e);
                        }}>
                        <option> - Select - </option>
                        <option value="yes">Yes</option>
                        <option value="no">No</option>
                      </Form.Select>
                    </td>
                    <td className="serac-btns text-center">
                      <Button
                        type="button"
                        onClick={() => {
                          searchHandler();
                        }}>
                        Search
                      </Button>
                      <Button
                        className="main-btn-red ms-2"
                        onClick={(e) => {
                          resetHandler(e);
                        }}>
                        Reset
                      </Button>
                    </td>
                  </tr>
                  {list && list.length !== 0 ? (
                    <>
                      {list?.map((data, index) => {
                        return (
                          <tr key={index}>
                            {/* <td>
                                <Form.Check
                                  type="checkbox"
                                  name="id"
                                  id={`id${data?.user_id}`}
                                  checked={chekcedId?.includes(data?.id)}
                                  onChange={() => handleMultipleCheckBox(data?.id)}
                                  label=""
                                />
                              </td> */}
                            <td>{data?.id}</td>
                            <td>{data?.name}</td>
                            <td>{data?.instituteName}</td>
                            <td>{data?.members_count}</td>
                            <td>
                              {data?.active == 1
                                ? 'Active'
                                : data?.active == 0
                                ? 'Inactive'
                                : 'Deleted'}
                            </td>
                            <td>{data?.verified == 1 ? 'yes' : 'No'}</td>
                            <td>
                              <Form.Select
                                aria-label="Default select example"
                                onChange={(e) => handleNaviagteEdit(e.target.value, data?.id)}>
                                <option>Action</option>
                                <option value="edit">Edit</option>
                                <option value="members">Manage Member</option>
                                <option value="blog">Manage Blog</option>
                                <option value="news">Manage News</option>
                                <option value="about">About Page</option>
                                <option value="gallery">Manage Gallery</option>
                                <option value="person">Contact Person</option>
                                <option value="banner">Manage Banners</option>
                                <option value="redirect">Domain Config</option>
                              </Form.Select>
                            </td>
                          </tr>
                        );
                      })}
                      {''}
                    </>
                  ) : (
                    <tr className="text-center">
                      <td colSpan={9}>
                        <NoDataFound />
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
              {Math.ceil(response / 10) > 1 ? (
                <div className="react-page d-flex justify-content-center">
                  <ReactPaginate
                    previousLabel={currentPage > 0 ? '<' : ''}
                    nextLabel={response > 0 ? '>' : ''}
                    onPageChange={handlePageClick}
                    activeClassName={'page-link active'}
                    pageCount={Math.ceil(response / 10)}
                  />
                </div>
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
      </Container>
      {loading ? <Loader /> : <></>}
    </div>
  );
}
