import { useEffect, useState } from 'react';
import HeaderInner from '../../commoncomponents/HeaderInner';
import FooterMain from '../../commoncomponents/FooterMain';
import * as APISERVICES from '../../../services/AuthServices';
import useDocumentTitle from '../../../globals/useDocumentTitle';
import Loader from '../../commoncomponents/loader/loader-large';

function TermsOfUsePage() {
  useDocumentTitle(
    'Almabay - Grow Professionally with your Almamater. Register to Seek Jobs and Mentors online.| AlmaBay'
  );
  const [content, setContent] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getTermsOfUse('terms_of_use');
  }, []);

  const getTermsOfUse = (page = '') => {
    setLoading(true);

    APISERVICES.staticWebPages(page).then((res) => {
      setLoading(false);
      if (res?.status == 200) {
        setContent(res?.data?.data?.page_content);
      }
    });
  };

  return (
    <>
      <HeaderInner />
      <section className="terms-condition py-5">
        <div className="container">
          <div className="terms-condition-inr">
            <div className="title-card mb-2">
              <h3 className="title-hd">Terms of use</h3>
            </div>
            <span dangerouslySetInnerHTML={{ __html: content }} />
          </div>
        </div>
      </section>
      <FooterMain className={'colour-bg'} />
      {loading ? <Loader /> : ''}
    </>
  );
}

export default TermsOfUsePage;
