//  @copyright : ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
// @author     : Shiv Charan Panjeta

// All Rights Reserved.
// Proprietary and confidential :  All information contained herein is, and remains
// the property of ToXSL Technologies Pvt. Ltd. and its partners.
// Unauthorized copying of this file, via any medium is strictly prohibited.

import { useEffect, useState } from 'react';
import { Button, Container } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { useNavigate, useParams } from 'react-router-dom';
import Select from 'react-select';
import * as adminService from '../../services/AuthServices';
import showNotification from '../../services/NotificationService';

export default function AddCourses() {
  const { id, course_id } = useParams();
  const navigate = useNavigate();

  const [departmentList, setDepartmentList] = useState([]);
  const [departmentId, setDepartmentId] = useState({});

  const [educationList, setEducationList] = useState([]);
  const [educationId, setEducationId] = useState({});

  const [fieldList, setFieldList] = useState('');
  const [fieldId, setFieldId] = useState({});

  const [specializationList, setSpecializationList] = useState([]);
  const [specializationId, setSpecializationId] = useState({});

  const [clicked, setClicked] = useState(false);
  const [courseDetail, setCourseDetail] = useState({
    department: '',
    qualification_type: '',
    program_name: '',
    field_of_study: '',
    specialization: ''
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (id) {
      getDepartmentDropDown();
      getEducationDropDown();
      getFieldOfStudyDropDown();
      getSpecializationDropDown();
    }
    if (course_id) {
      getCourseDetail(course_id);
    }
  }, []);

  const getCourseDetail = (course_id) => {
    adminService.getCourseDetail(course_id).then((res) => {
      if (res?.status === 200) {
        const data = res?.data?.data;

        setCourseDetail({
          course_id: course_id,
          department: data?.course[0].dept_id,
          qualification_type: data?.course[0].education_level,
          program_name: data?.course[0].program_name,
          field_of_study: data?.course[0]?.field_name,
          specialization: data?.course[0]?.name
        });
        getEducationDropDown(data?.course[0].education_level);
        getFieldOfStudyDropDown(data?.course[0].program_name);
        getSpecializationDropDown(data?.course[0]?.field_name);
        // values for select options
        setDepartmentId({
          id: data?.course[0].dept_id,
          label: data?.course[0].institute_name,
          value: data?.course[0].institute_name
        });
        setEducationId({
          id: data?.course[0].program_name,
          label: data?.course[0].program_name,
          value: data?.course[0].program_name
        });
        setFieldId({
          id: data?.course[0].field_name,
          label: data?.course[0].field_name,
          value: data?.course[0].field_name
        });
        setSpecializationId({
          id: data?.course[0]?.name,
          label: data?.course[0]?.name,
          value: data?.course[0]?.name
        });
      }
    });
  };

  const getDepartmentDropDown = () => {
    adminService.departmentDropDown(id).then((res) => {
      if (res?.status === 200) {
        let list = res?.data?.data;
        let arr = [];
        list?.map((data) => {
          arr.push({ id: data?.institute_id, label: data?.name, value: data?.name });
        });
        setDepartmentList(arr);
      }
    });
  };

  const getEducationDropDown = (qualification = '') => {
    adminService.userEducationDropDown(id, qualification).then((res) => {
      if (res?.status === 200) {
        let list = res?.data;
        let arr = [];
        list?.slice(0, 100)?.map((data) => {
          arr.push({ label: data?.program_name, value: data?.program_name });
        });
        setEducationList(arr);
      }
    });
  };

  const getFieldOfStudyDropDown = (program = '') => {
    adminService.fieldOfStudyDropDown(id, program).then((res) => {
      if (res?.status === 200) {
        let list = res?.data?.data;
        let arr = [];
        list?.map((data) => {
          arr.push({ label: data?.name, value: data?.name });
        });
        setFieldList(arr);
      }
    });
  };

  const getSpecializationDropDown = (field = '') => {
    adminService.specializationDropDown(id, field).then((res) => {
      if (res?.status === 200) {
        let list = res?.data;
        let arr = [];
        list?.map((data) => {
          arr.push({ label: data?.name, value: data?.name });
        });
        setSpecializationList(arr);
      }
    });
  };

  const onChangeHandler = (e, option) => {
    const list = { ...courseDetail };
    if (option.name === 'department') {
      list[option.name] = e.id;
    } else {
      list[option.name] = e.value;
    }
    setCourseDetail(list);
  };

  const validate = (value) => {
    let errors = {};
    if (!value.qualification_type) {
      errors.qualification_type = 'This field is required';
    }
    if (!value.program_name) {
      errors.program_name = 'This field is required';
    }
    return errors;
  };

  const submitHandler = (e) => {
    e.preventDefault();
    setClicked(true);
    if (!Object.keys(validate(courseDetail)).length) {
      setLoading(true);
      adminService.addCourse(courseDetail).then((res) => {
        if (res?.status === 200) {
          showNotification('success', res?.data?.message);
          navigate(-1);
        }
      });
    }
  };

  const updateHandler = (e) => {
    e.preventDefault();
    setClicked(true);
    if (!Object.keys(validate(courseDetail)).length) {
      setLoading(true);
      adminService.editCourse(courseDetail).then((res) => {
        if (res?.status === 200) {
          showNotification('success', res?.data?.message);
          navigate(-1);
        }
      });
    }
  };

  return (
    <div className="dash-main-content">
      <Container fluid>
        <div className="dash-page-content">
          <div className="dash-page-title">
            <Row>
              <Col xs={12} sm={12} md={12}>
                <div className="dash-title-bar d-flex align-items-center justify-content-between">
                  <div className="title-lft d-flex align-items-center">
                    <div className="dash-icn">
                      <i className="fas fa-university"></i>
                    </div>
                    <div className="dash-title-dis">
                      <h5>
                        Add Course in <span>institute five</span>
                      </h5>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <div className="dash-content-card">
            <div className="cmmn-form">
              <Form>
                <Row>
                  <Col md={6}>
                    <Form.Group className="mb-3" controlId="formBasicName">
                      <Form.Label>Select Department</Form.Label>
                      <Select
                        className="basic-single"
                        classNamePrefix="select"
                        isDisabled={false}
                        isLoading={false}
                        isClearable={false}
                        isRtl={false}
                        isSearchable={true}
                        name="department"
                        value={departmentId}
                        onChange={(e, option) => {
                          setDepartmentId(e);
                          onChangeHandler(e, option);
                        }}
                        options={departmentList}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="mb-3" controlId="formBasicName">
                      <Form.Label>
                        Education Level <sup className="text-danger">*</sup>
                      </Form.Label>
                      <Form.Select
                        aria-label="Default select example"
                        name="qualification_type"
                        onChange={(e) => {
                          setCourseDetail({
                            ...courseDetail,
                            ['qualification_type']: e.target.value
                          }),
                            getEducationDropDown(e?.target?.value);
                        }}>
                        <option>Select qualification type</option>
                        <option value="high school">High School</option>
                        <option value="secondary school">Sr. Secondary School</option>
                        <option value="bachelors degree">Bachelors Degree</option>
                        <option value="masters degree">Masters Degree</option>
                        <option value="doctorate">Doctorate/Phd</option>
                        <option value="others">Others</option>
                      </Form.Select>
                    </Form.Group>
                    <div
                      className={`${
                        clicked && validate(courseDetail).qualification_type ? '' : 'd-none'
                      } danger-frm `}>
                      {clicked && validate(courseDetail).qualification_type}
                    </div>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="mb-3" controlId="formBasicName">
                      <Form.Label>
                        Program Name <sup className="text-danger">*</sup>
                      </Form.Label>
                      <Select
                        className="basic-single"
                        classNamePrefix="select"
                        isDisabled={false}
                        isLoading={false}
                        isClearable={false}
                        isRtl={false}
                        isSearchable={true}
                        options={educationList}
                        value={educationId}
                        onChange={(e, option) => {
                          onChangeHandler(e, option), getFieldOfStudyDropDown(e.value);
                          setEducationId(e);
                        }}
                        name="program_name"
                      />
                    </Form.Group>
                    <div
                      className={`${
                        clicked && validate(courseDetail).program_name ? '' : 'd-none'
                      } danger-frm `}>
                      {clicked && validate(courseDetail).program_name}
                    </div>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="mb-3" controlId="formBasicName">
                      <Form.Label>Field of Study</Form.Label>
                      <Select
                        className="basic-single"
                        classNamePrefix="select"
                        isDisabled={false}
                        isLoading={false}
                        isClearable={false}
                        isRtl={false}
                        isSearchable={true}
                        value={fieldId}
                        onChange={(e, option) => {
                          onChangeHandler(e, option), getSpecializationDropDown(e.value);
                          setFieldId(e);
                        }}
                        options={fieldList}
                        name="field_of_study"
                      />
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="mb-3" controlId="formBasicName">
                      <Form.Label>Specialization</Form.Label>
                      <Select
                        className="basic-single"
                        classNamePrefix="select"
                        isDisabled={false}
                        isLoading={false}
                        isClearable={false}
                        isRtl={false}
                        isSearchable={true}
                        onChange={(e, option) => {
                          onChangeHandler(e, option);
                          setSpecializationId(e);
                        }}
                        options={specializationList}
                        value={specializationId}
                        name="specialization"
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <div className="form-btns d-flex align-items-center mt-3">
                  {course_id ? (
                    <Button
                      className="main-btn-grn"
                      onClick={(e) => {
                        loading ? e.preventDefault() : updateHandler(e);
                      }}>
                      Save
                    </Button>
                  ) : (
                    <Button
                      className="main-btn-grn"
                      onClick={(e) => {
                        loading ? e.preventDefault() : submitHandler(e);
                      }}>
                      Save
                    </Button>
                  )}
                  <strong>OR</strong>
                  <Button
                    className="main-btn-red"
                    onClick={() => {
                      navigate(-1);
                    }}>
                    Cancel
                  </Button>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}
