/**
@copyright  :   ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
@author     :   Shiv Charan Panjeta 
 
All Rights Reserved.
Proprietary and confidential :  All information contained here in is, and remains
the property of ToXSL Technologies Pvt. Ltd. and it's partners.
Unauthorized copying of this file, via any medium is strictly prohibited.
*/
import React, { useState } from 'react';
import { Button, Col, Form, Row, Tab } from 'react-bootstrap';
import AsyncSelect from 'react-select/async';
import { loadCityOpt } from '../../../../super-admin/AdminHelper';
import DatePicker from 'react-datepicker';
import { updateEvent } from '../../../../services/SocialServices';
import showNotification from '../../../../services/NotificationService';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { filterPassedTime } from '../../../../globals/helper';
export default function EditEventDetails({
  loadEventCategoryOptions,
  setEventDetail,
  eventDetail,
  setFromDate,
  fromDate,
  toDate,
  setToDate,
  userId,
  getEventDetailList
}) {
  const [clicked, setClicked] = useState(false);
  let navigate = useNavigate();

  //  onchange function for edit event

  const handleEventChange = (e, defaultName) => {
    if (defaultName) {
      if (
        defaultName == 'event_privacy' ||
        defaultName == 'from_event_date' ||
        defaultName == 'to_event_date'
      ) {
        setEventDetail({
          ...eventDetail,
          [defaultName]: e
        });
      }
      if (defaultName == 'event_privacy') {
        setEventDetail({
          ...eventDetail,
          [defaultName]: e.target.value
        });
      }
      if (defaultName == 'category_id') {
        setEventDetail({
          ...eventDetail,
          [defaultName]: e
        });
      }
      if (defaultName == 'city') {
        setEventDetail({
          ...eventDetail,
          [defaultName]: e
        });
      }
    } else {
      var { name, value, type, checked } = e.target;
      if (type === 'file') {
        let file = e.target.files[0];
        setEventDetail({
          ...eventDetail,
          [name]: file
        });
      } else {
        if (name == 'invite_friends') {
          setEventDetail({
            ...eventDetail,
            [name]: checked
          });
        } else {
          setEventDetail({
            ...eventDetail,
            [name]: value
          });
        }
      }
    }
  };

  const validateEditEvents = () => {
    let error = {};
    if (eventDetail?.event_privacy == '') {
      error.event_privacy = 'This field is required';
    }
    if (eventDetail?.category_id == '') {
      error.category_id = 'This field is required.';
    }
    if (eventDetail?.name == '') {
      error.name = 'This field is required.';
    }
    if (eventDetail?.venue == '') {
      error.venue = 'This field is required.';
    }
    if (eventDetail?.city == '') {
      error.city = 'This field is required.';
    }
    if (fromDate == null) {
      error.fromDate = 'This field is required.';
    } else if (fromDate.getTime() < new Date().getTime()) {
      error.fromDate = 'Please select valid time';
    }
    if (eventDetail?.decp == '') {
      error.decp = 'This field is required.';
    }

    return error;
  };

  const submitHandler = (e) => {
    e.preventDefault();
    setClicked(true);
    if (!Object.keys(validateEditEvents()).length) {
      setClicked(false);
      let formData = new FormData();
      let body = {
        name: eventDetail?.name,
        decp: eventDetail?.decp,
        venue: eventDetail?.venue,
        city: eventDetail?.city?.id,
        category_id: eventDetail?.category_id?.id,
        event_privacy: eventDetail?.event_privacy,
        from_event_date: moment(fromDate).format('YYYY-MM-DD HH:mm:ss'),
        to_event_date: toDate !== '' ? moment(toDate).format('YYYY-MM-DD HH:mm:ss') : ''
      };

      for (let i in body) {
        formData.append(`${i}`, body[i]);
      }
      updateEvent(userId, formData).then((resp) => {
        if (resp?.status === 200) {
          showNotification('success', resp?.data?.detail?.message);
          navigate(`/user/events-detail/${userId}/overview`);
          getEventDetailList();
        } else {
          showNotification('success', resp?.data?.detail?.message);
        }
      });
    }
  };
  return (
    <Tab.Pane eventKey="seventh">
      <div className="edit-events-frm bg-white ">
        <h4>Edit Event Details</h4>

        <Form className="p-4">
          <Row>
            <Col lg={12} md={12}>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Event Privacy</Form.Label> <sup className="text-danger">*</sup>
                <Form.Select
                  aria-label="Default select example"
                  value={eventDetail?.event_privacy}
                  onChange={(e) => {
                    handleEventChange(e, 'event_privacy');
                  }}>
                  <option value="">Open this select menu</option>
                  <option value="public">Public</option>
                  <option value="private">Private</option>
                </Form.Select>
                <div className={'danger-frm'}>{clicked && validateEditEvents().event_privacy}</div>
              </Form.Group>
            </Col>

            <Col lg={12} md={12}>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Event Category</Form.Label> <sup className="text-danger">*</sup>
                <AsyncSelect
                  cacheOptions
                  defaultOptions
                  getOptionLabel={(e) => e.label}
                  getOptionValue={(e) => e.value}
                  loadOptions={loadEventCategoryOptions}
                  placeholder="Select Event Category"
                  onChange={(e) => handleEventChange(e, 'category_id')}
                  value={eventDetail?.category_id}
                />
                <div className={'danger-frm'}>{clicked && validateEditEvents().category_id}</div>
              </Form.Group>
            </Col>

            <Col lg={12} md={12}>
              <Form.Group className="mb-3" controlId="formBasicEvent">
                <Form.Label>Event Name</Form.Label> <sup className="text-danger">*</sup>
                <Form.Control
                  type="text"
                  placeholder="Add a short, clear name"
                  name="name"
                  onChange={handleEventChange}
                  value={eventDetail?.name}
                />
                <div className={'danger-frm'}>{clicked && validateEditEvents().name}</div>
              </Form.Group>
            </Col>
            <Col lg={12} md={12}>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Event Venue</Form.Label> <sup className="text-danger">*</sup>
                <Form.Control
                  type="text"
                  placeholder="Include a place or address"
                  name="venue"
                  onChange={handleEventChange}
                  value={eventDetail?.venue}
                />
                <div className={'danger-frm'}>{clicked && validateEditEvents().venue}</div>
              </Form.Group>
            </Col>
            <Col lg={12} md={12}>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Event City</Form.Label> <sup className="text-danger">*</sup>
                <AsyncSelect
                  cacheOptions
                  defaultOptions
                  value={eventDetail?.city}
                  getOptionLabel={(e) => e.label}
                  getOptionValue={(e) => e.value}
                  loadOptions={loadCityOpt}
                  className="basic-single mt-1"
                  placeholder="Select Event City"
                  onChange={(e) => handleEventChange(e, 'city')}
                />
                <div className={'danger-frm'}>{clicked && validateEditEvents().city}</div>
              </Form.Group>
            </Col>

            <Col lg={4}>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Start Date-Time</Form.Label> <sup className="text-danger">*</sup>
                <div className="d-flex align-items-center">
                  <DatePicker
                    placeholderText="Select Start Date-Time"
                    className="date-card form-control"
                    selected={fromDate}
                    onChange={(date) => setFromDate(date)}
                    showTimeSelect
                    filterTime={filterPassedTime}
                    minDate={new Date()}
                    dateFormat="MM-dd-yyyy h:mm aa"
                    value={fromDate}
                  />
                </div>
                <div className={'danger-frm'}>{clicked && validateEditEvents().fromDate}</div>
              </Form.Group>
            </Col>

            <Col lg={4}>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>End Date-Time</Form.Label>
                <div className="d-flex">
                  <DatePicker
                    placeholderText="Select End Date-Time"
                    className="date-card form-control"
                    selected={toDate}
                    onChange={(date) => setToDate(date)}
                    showTimeSelect
                    filterTime={filterPassedTime}
                    minDate={fromDate}
                    dateFormat="MM-dd-yyyy h:mm aa"
                  />
                </div>

                <div className={'danger-frm'}>{clicked && validateEditEvents().toDate}</div>
              </Form.Group>
            </Col>
            <Col lg={12}>
              <Form.Group className="mb-3" controlId="formBasicDiscription">
                <Form.Label>Description</Form.Label> <sup className="text-danger">*</sup>
                <Form.Control
                  as="textarea"
                  placeholder="Tell people more about the event"
                  rows={4}
                  name="decp"
                  onChange={handleEventChange}
                  value={eventDetail?.decp}
                />
                <div className={'danger-frm'}>{clicked && validateEditEvents().decp}</div>
              </Form.Group>
            </Col>
            <Col lg={12}>
              {eventDetail?.event_privacy == 1 && (
                <Form.Group className="mb-3" controlId="formBasicCheckbox">
                  <Form.Check
                    type="checkbox"
                    label="Guests can invite friends"
                    value={eventDetail?.invite_friends}
                    name="invite_friends"
                    // onChange={handleChange}
                  />
                </Form.Group>
              )}
            </Col>
            <Col lg={12}>
              <div className="d-flex align-items-center justify-content-end">
                <Button
                  variant="secondary"
                  onClick={() => navigate(`/user/events-detail/${userId}/overview`)}>
                  Cancel
                </Button>
                <Button className="ms-1" variant="primary" onClick={submitHandler}>
                  Update Event
                </Button>
              </div>
            </Col>
          </Row>
        </Form>
      </div>
    </Tab.Pane>
  );
}
