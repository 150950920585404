//  @copyright : ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
// @author     : Shiv Charan Panjeta

// All Rights Reserved.
// Proprietary and confidential :  All information contained herein is, and remains
// the property of ToXSL Technologies Pvt. Ltd. and its partners.
// Unauthorized copying of this file, via any medium is strictly prohibited.

import { useEffect, useState } from 'react';
import { Button, Container } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Table from 'react-bootstrap/Table';
import { useParams } from 'react-router';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import NoDataFound from '../../components/commoncomponents/NoDataFound';
import Loader from '../../components/commoncomponents/loader/loader-large';
import * as adminServices from '../../services/AuthServices';
import showNotification from '../../services/NotificationService';

let intervalId;
export default function MemberList(props) {
  const { state } = props;
  const { grp_id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [chekcedId, setCheckedId] = useState([]);
  const [sortType, setSortType] = useState('DESC');
  const [sortField, setSortField] = useState('group_id');
  const [list, setList] = useState([]);
  const [allId, setAllId] = useState([]);
  const [action, setAction] = useState('');
  const [showalert, setShowAlert] = useState(false);
  const [loding, setLoading] = useState(false);
  const [groupData, setGroupData] = useState({
    id: '',
    name: '',
    email: ''
  });

  useEffect(() => {
    apiCall();
  }, [state]);

  const apiCall = (
    group_id = '',
    page = '',
    sortfield = '',
    order = '',
    id = '',
    name = '',
    email = ''
  ) => {
    setLoading(true);
    const body = {
      group_id: grp_id,
      page,
      sortfield,
      order,
      id,
      name,
      email
    };
    adminServices.allMemberList(body).then((resp) => {
      setLoading(false);
      if (resp?.status === 200) {
        setCheckedId([]);
        setList(resp?.data?.detail?.viewlist);
        setAllId(resp?.data?.detail?.viewlist.map((data) => data?.id));
      }
    });
  };

  const sortHandler = (e, type) => {
    e.preventDefault();
    let currentSort = sortType === 'ASC' ? 'DESC' : 'ASC';
    setSortType(currentSort);
    setSortType(type);
    apiCall(
      sortField,
      groupData.group_id,
      groupData.group_name,
      groupData.group_institute,
      groupData.group_status,
      groupData.group_verified
    );
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setGroupData({ ...groupData, [name]: value.trim() });
  };

  const globalActionHandler = (e) => {
    setAction(e.target.value);
    const body = {
      ids: chekcedId?.toString(),
      action: e.target.value,
      group_id: grp_id
    };
    setLoading(true);
    adminServices.postGroupSelected(body).then((resp) => {
      setLoading(false);
      if (resp?.status === 200) {
        showNotification('success', resp?.data?.detail);
        apiCall();
      }
    });
  };

  const handleSelectAll = (e) => {
    if (e.target.checked === true) {
      setCheckedId(allId);
    } else {
      setCheckedId([]);
    }
  };

  const handleMultipleCheckBox = (id) => {
    if (chekcedId.includes(id)) {
      let filterId = chekcedId.filter((data) => data !== id);
      setCheckedId(filterId);
    } else {
      setCheckedId([...chekcedId, id]);
    }
  };

  // const alertHandler = () => {
  //   clearInterval(intervalId);
  //   intervalId = setInterval(function () {
  //     setShowAlert(false);
  //   }, 5000);
  // };

  const selectHandler = (value, id) => {
    switch (value) {
      case 'edit':
        navigate(`/admin/groups/create`, { state: id });
        break;
      case 'manage':
        navigate('');
        break;
      default:
        break;
    }
  };

  const handleSearch = (e) => {
    setLoading(true);
    const body = {
      group_id: grp_id,
      page: '',
      sortfield: sortField,
      order: sortType,
      id: groupData?.id,
      name: groupData?.name,
      email: groupData?.email
    };
    adminServices.allMemberList(body).then((resp) => {
      setLoading(false);
      if (resp?.status === 200) {
        setList(resp?.data?.detail?.viewlist);
      }
    });
  };

  return (
    <>
      <div className="dash-main-content">
        <Container fluid>
          <div className="dash-page-content">
            <div className="dash-page-title">
              <Row>
                <Col xs={12} sm={12} md={12}>
                  <div className="dash-title-bar d-flex align-items-center justify-content-between">
                    <div className="title-lft d-flex align-items-center">
                      <div className="dash-icn">
                        <i className="fas fa-users"></i>
                      </div>
                      <div className="dash-title-dis">
                        <h5>
                          {state == 'group'
                            ? 'Group'
                            : location.state == 'club'
                            ? 'Club'
                            : 'Chapter'}
                          <span> Members Listing</span>
                        </h5>
                        <h5>
                          Total Record(s): <span>{list?.length}</span>
                        </h5>
                      </div>
                    </div>
                    <div className="title-rt-btn d-flex align-items-center">
                      {location.state == 'club' ? (
                        <></>
                      ) : (
                        <Link
                          className="add-btn-grn"
                          to={
                            props?.state == 'group'
                              ? '/admin/groups/create'
                              : '/admin/chapters/create'
                          }>
                          {props?.state == 'group' ? ' Add New Group' : ' Add New Chapter'}
                        </Link>
                      )}

                      <div className="head-instite-sec ms-4">
                        <Form.Select
                          aria-label="Default select example"
                          id="global"
                          onChange={globalActionHandler}>
                          <option>Choose Action</option>
                          <option value="active">Active</option>
                          <option value="inactive">Inactive</option>
                          <option value="remove">Remove Follower</option>
                          <option value="make_admin">Make Admin</option>
                          <option value="remove_admin">Remove Admin</option>
                        </Form.Select>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="dash-content-card">
              <div className="table-responsive">
                <Table className="custom-table-data" striped bordered hover>
                  <thead>
                    <tr>
                      <th>
                        <Form.Check
                          type="checkbox"
                          id="checkbox"
                          name="checkall"
                          checked={allId?.length ? allId?.length === chekcedId?.length : false}
                          onChange={(e) => {
                            handleSelectAll(e);
                          }}
                          label=""
                        />
                      </th>
                      <th>Id</th>
                      <th>Name</th>
                      <th>Email</th>

                      <th> Type</th>
                      <th>Status</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="search-table">
                      <td></td>
                      <td>
                        <Form.Control
                          type="text"
                          placeholder="id"
                          name="id"
                          onChange={(e) => handleChange(e)}
                          value={groupData.id}
                        />
                      </td>
                      <td>
                        <Form.Control
                          type="text"
                          placeholder="name"
                          name="name"
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          value={groupData.name}
                        />
                      </td>

                      <td>
                        <Form.Control
                          type="text"
                          placeholder="email"
                          name="email"
                          onChange={(e) => handleChange(e)}
                          value={groupData.email}
                        />
                      </td>
                      <td></td>
                      <td></td>
                      <td className="serac-btns text-center">
                        <Button
                          className="main-btn-grn"
                          type="button"
                          onClick={(e) => {
                            handleSearch(e);
                          }}>
                          Search
                        </Button>
                      </td>
                    </tr>
                    {list && list?.length ? (
                      list?.map((data, index) => {
                        return (
                          <tr key={index}>
                            <td>
                              <Form.Check
                                type="checkbox"
                                name="id"
                                onChange={() => handleMultipleCheckBox(data?.id)}
                                label=""
                                checked={chekcedId?.includes(data?.id)}
                                id={`id${data?.group_id}`}
                              />
                            </td>
                            <td>{data?.id || 'NA'}</td>
                            <td>
                              <Link to={`/admin/users/view/${data?.id}`}>{data?.name || 'NA'}</Link>
                            </td>
                            <td>{data?.email || 'NA'}</td>
                            <td>{data?.memberType == false ? 'Member' : 'Admin'}</td>
                            <td>
                              {data.active && data.active == 1 ? 'Active' : 'Inactive' || 'NA'}
                            </td>
                            <td>
                              <span
                                role="presentation"
                                className="text-primary  mx-5"
                                onClick={(e) => {
                                  e.preventDefault();
                                  navigate(`/admin/users/view/${data?.id}`);
                                }}>
                                <i className="fas fa-search-plus"></i>
                              </span>
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td className="text-center" colSpan={7}>
                          <NoDataFound />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
                {/* pagination */}
              </div>
            </div>
          </div>
        </Container>
      </div>
      {loding ? <Loader /> : ''}
    </>
  );
}
