import { useState, useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import HeaderLogin from '../../commoncomponents/HeaderLogin';
import TopTabbings from './TopTabbings';
import { Link } from 'react-router-dom';
import NewsSidebar from '../../commoncomponents/newsfeeds/NewsSidebar';

import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

import WhatsNew from '../../commoncomponents/newsfeeds/WhatsNew';
import Upcoming from '../../commoncomponents/newsfeeds/Upcoming';
import PeopleKnow from '../../commoncomponents/newsfeeds/PeopleKnow';
import FollowCompany from '../../commoncomponents/newsfeeds/FollowCompany';
import WorkAnniversary from '../../commoncomponents/newsfeeds/WorkAnniversary';
import CampusNews from '../../commoncomponents/newsfeeds/CampusNews';
import CampusnewsText from '../../commoncomponents/newsfeeds/CampusnewsText';
import ArticalBlogs from '../../commoncomponents/newsfeeds/ArticalBlogs';
import GroupDiscover from '../../commoncomponents/newsfeeds/GroupDiscover';
import * as APISERVICES from '../../../services/SocialServices';
import useDocumentTitle from '../../../globals/useDocumentTitle';
import { MiniFooter } from '../../MiniFooter';
import Loader from '../../commoncomponents/loader/loader-large';
import NoDataFound from '../../commoncomponents/NoDataFound';

function BirthdayPage() {
  useDocumentTitle('Birthdays | AlmaBay');
  const [birthdayData, setBirthdayData] = useState([]);
  const [nextMonthBirth, setNextMonthBirth] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getCampusNews();
  }, []);

  const getCampusNews = () => {
    setLoading(true);
    APISERVICES.getAllBirthDay().then((res) => {
      if (res?.status === 200) {
        setBirthdayData(res?.data?.data);
        setLoading(false);
        const data = Object.entries(res?.data?.data?.birthdayList?.next_month_birthday).map(
          ([key, value]) => ({ key, value })
        );
        setNextMonthBirth(data);
      }
    });
  };

  const showBirthMonth = (key) => {
    switch (key) {
      case '1':
        return 'January';

      case '2':
        return 'February';

      case '3':
        return 'March';

      case '4':
        return 'April';

      case '5':
        return 'May';

      case '6':
        return 'June';

      case '7':
        return 'July';

      case '8':
        return 'August';

      case '9':
        return 'September';

      case '10':
        return 'October';

      case '11':
        return 'November';

      case '12':
        return 'December';
    }
  };

  return (
    <>
      <HeaderLogin />
      <div className="header-lnk-feeds">
        <div className="container">
          <TopTabbings state={'social'} />
        </div>
      </div>
      <section className="news-feed-sec bg-grey py-4">
        <Container>
          <Row>
            <Col xs={12} lg={2}>
              <NewsSidebar state="1" current="birthday" />
            </Col>

            <Col xs={12} lg={9} xl={7} className="mb-4">
              <div className="birthday-sec">
                {birthdayData?.birthdayList?.today_birthday.length !== 0 && (
                  <div className="birthday-card bg-white mb-3">
                    <div className="birthday-card-hd">
                      <h4>Today's Birthdays</h4>
                    </div>
                    <div className="birthday-user">
                      {birthdayData?.birthdayList?.today_birthday.map((birthData, index) => {
                        return (
                          <ul key={index}>
                            <li>
                              {['top'].map((placement) => (
                                <OverlayTrigger
                                  key={placement}
                                  placement={placement}
                                  overlay={
                                    <Tooltip id={`tooltip-${placement}`}>
                                      {birthData?.name} ({birthData?.db})
                                    </Tooltip>
                                  }
                                >
                                  <Link to={`/user/timeline/${birthData?.encryption_id}`}>
                                    <img
                                      src={process.env.REACT_APP_API_URL + birthData?.profile_pic}
                                      alt="img"
                                    />
                                  </Link>
                                </OverlayTrigger>
                              ))}
                            </li>
                          </ul>
                        );
                      })}
                    </div>
                  </div>
                )}
                {birthdayData?.birthdayList?.upcoming_birthday.length !== 0 && (
                  <div className="birthday-card bg-white mb-3">
                    <div className="birthday-card-hd">
                      <h4>Upcoming Birthdays</h4>
                    </div>
                    <div className="birthday-user">
                      {birthdayData?.birthdayList?.upcoming_birthday.map((birthData, index) => {
                        return (
                          <ul key={index}>
                            <li>
                              {['top'].map((placement) => (
                                <OverlayTrigger
                                  key={placement}
                                  placement={placement}
                                  overlay={
                                    <Tooltip id={`tooltip-${placement}`}>
                                      {birthData?.name} ({birthData?.db})
                                    </Tooltip>
                                  }
                                >
                                  <Link to={`/user/timeline/${birthData?.encryption_id}`}>
                                    <img
                                      src={process.env.REACT_APP_API_URL + birthData?.profile_pic}
                                      alt="img"
                                    />
                                  </Link>
                                </OverlayTrigger>
                              ))}
                            </li>
                          </ul>
                        );
                      })}
                    </div>
                  </div>
                )}
                {nextMonthBirth.length !== 0 && (
                  <div className="birthday-card bg-white mb-3">
                    <div className="birthday-user">
                      {nextMonthBirth?.map((birthData, index) => {
                        return (
                          <>
                            {' '}
                            <div className="birthday-card-hd" key={index}>
                              <h4>
                                {showBirthMonth(birthData?.key?.split('-')?.pop())}'s Birthdays
                              </h4>
                            </div>
                            <ul>
                              {birthData?.value?.map((birthDetail, index) => {
                                return (
                                  <>
                                    {' '}
                                    <li>
                                      {['top'].map((placement) => (
                                        <OverlayTrigger
                                          key={placement}
                                          placement={placement}
                                          overlay={
                                            <Tooltip id={`tooltip-${placement}`}>
                                              {birthDetail?.name} ({birthDetail?.db})
                                            </Tooltip>
                                          }
                                        >
                                          <Link to={`/user/timeline/${birthDetail?.encryption_id}`}>
                                            <img
                                              src={
                                                process.env.REACT_APP_API_URL +
                                                birthDetail?.profile_pic
                                              }
                                              alt="img"
                                            />
                                          </Link>
                                        </OverlayTrigger>
                                      ))}
                                    </li>
                                  </>
                                );
                              })}
                            </ul>
                          </>
                        );
                      })}
                    </div>
                  </div>
                )}
                {birthdayData?.birthdayList?.today_birthday.length == 0 &&
                  birthdayData?.birthdayList?.upcoming_birthday.length == 0 &&
                  nextMonthBirth?.length == 0 && (
                    <>
                      <NoDataFound text="OOPS! We can't find any birthday of your friends. Try adding some friends." />
                    </>
                  )}
              </div>
            </Col>

            <Col xl={{ span: 3, offset: 0 }} lg={{ span: 9, offset: 2 }}>
              <div>
                <PeopleKnow />
              </div>
              <div>
                <WorkAnniversary />
              </div>
              <div>
                <GroupDiscover />
              </div>
              <div>
                <FollowCompany />
              </div>
              <div>
                <CampusNews />
              </div>
              <div>
                <ArticalBlogs />
              </div>
              <div>
                <CampusnewsText />
              </div>
              <div>
                <WhatsNew />
              </div>
              <div>
                <Upcoming />
              </div>
              <MiniFooter />
            </Col>
          </Row>
        </Container>
      </section>
      {loading ? <Loader /> : null}
    </>
  );
}

export default BirthdayPage;
