import * as api from '../utils/requests';

export const articleList = async (page) => {
  return await api.getReq(`/mentorship/qaFeeds/list?o=${page}`);
};

export const articleDetail = async (id) => {
  return await api.getReq(`/mentorship/article/detail?id=${id}`);
};

export const questionDetail = async (id) => {
  return await api.getReq(`/mentorship/qaFeeds/Detail?id=${id}`);
};
export const articleComments = async (id) => {
  return await api.getReq(`/mentorship/comment/list?id=${id}`);
};

export const articleAnswers = async (id) => {
  return await api.getReq(`/mentorship/comment/list?id=${id}`);
};

export const addArticleComment = async (body) => {
  return await api.PostReq(`/mentorship/comment/add`, body);
};

export const addArticleAnswer = async (body) => {
  return await api.PostReq(`/mentorship/answer/add`, body);
};

export const deleteArticleComment = async (id) => {
  return await api.delReq(`/mentorship/comment/delete?id=${id}`);
};

export const deleteMyArticle = async (id) => {
  return await api.delReq(`/mentorship/article/delete?id=${id}`);
};

export const deleteArticleAns = async (id) => {
  return await api.delReq(`/mentorship/answer/delete?id=${id}`);
};

export const articlesQuestionList = async () => {
  return await api.getReq(`/mentorship/my-article-question-list`);
};

export const articleHelpful = async (data) => {
  return await api.PostReq(`/mentorship/qaFeeds/add-like`, data);
};
// /mentorship/answer/add-like

export const articleAnswerLike = async (id) => {
  return await api.PostReq(`/mentorship/answer/add-like`, id);
};

export const getAnswersLists = (id) => {
  return api.getReq(`/mentorship/answer/list?id=${id}`);
};

export const askAQuestion = (body) => {
  return api.PostReq(`/mentorship/question/add`, body);
};

export const deleteAttachmentFile = (id) => {
  return api.delReq(`/mentorship/qaFeeds/deleteAttachment?id=${id}`);
};

export const myQuestionList = (page = '') => {
  return api.getReq(`/mentorship/question/list?o=${page}`);
};
export const myArticleList = async () => {
  return await api.getReq(`/mentorship/article/list`);
};

export const MyArticle = async (page = '') => {
  return await api.PostReq(`/user/mentorArticlesList?o=${page}`);
};

export const addArticle = async (data) => {
  return await api.PostReq(`/mentorship/article/add`, data);
};

export const getTopicList = async (q = '') => {
  return await api.getReq(`/mentorship/article/topic-list?q=${q}`);
};

export const getAnswerRequestList = async () => {
  return await api.getReq(`/answer/answer-requests`);
};

export const getMyAnswerList = async (search = '') => {
  return await api.getReq(`/mentorship/answer/my-answers?search=${search}`);
};

export const deleteMyQuestion = async (id) => {
  return await api.delReq(`/mentorship/qaFeeds/delete?id=${id}`);
};

export const deleteMyAnswer = async (id) => {
  return await api.delReq(`/mentorship/answer/delete?id=${id}`);
};

export const updateMyQuestion = async (id, body) => {
  return await api.PostReq(`/mentorship/question/edit?id=${id}`, body);
};

export const updateMyArticle = async (id, body) => {
  return await api.PostReq(`/mentorship/article/edit?id=${id}`, body);
};

export const editAnswer = async (id, body) => {
  return await api.PostReq(`/mentorship/answer/edit?id=${id}`, body);
};

export const singleAnswerDetail = async (id) => {
  return await api.getReq(`/mentorship/answer/detail?id=${id}`);
};

/**
 * right side-cards api
 */

export const getSuggestedMentors = async (id) => {
  return await api.getReq(`/mentorship/comment/list?id=${id}`);
};

export const getGroup = async () => {
  return await api.getReq(`group/userGroups`);
};

export const getDiscover = async () => {
  return await api.getReq(`/user/group-discover`);
};

export const getUpcoming = async () => {
  return await api.getReq(`/mentorship/comment/list`);
};

export const getMentorCount = async () => {
  return await api.getReq(`/user/getUserMentorsCount`);
};

export const getMenteesCount = async () => {
  return await api.getReq(`/user/getUserMenteesCount`);
};

export const getFriendsCount = async () => {
  return await api.getReq(`/user/getUserFriendsCount`);
};

// /mentorship/answer/answer-requests

export const getAllAnswerRequest = async () => {
  return await api.getReq(`/mentorship/answer/answer-requests`);
};
export const getAllFriends = async (search = '') => {
  return await api.getReq(`/user/searchFriendsByName?search=${search}`);
};
export const getAllMentor = async (search = '') => {
  return await api.getReq(`/user/searchMentorsByName?search=${search}`);
};
export const getAllMentees = async (search = '') => {
  return await api.getReq(`/user/searchMenteesByName?search=${search}`);
};
export const getUserFriends = async (search = '') => {
  return await api.getReq(`/user/getUserFriends?search=${search}`);
};

export const getUserMentors = async (search = '') => {
  return await api.getReq(`user/getUserMentors?search=${search}`);
};

export const getUserMantees = async (search = '') => {
  return await api.getReq(`/user/getUserMentees?search=${search}`);
};

export const questionMentorshipList = async (page, body) => {
  return await api.PostReq(`/mentorship/user/mentorQaListSuggestedQuestions`);
};
export const answerListMentorship = async (page = '', body) => {
  return await api.PostReq(`/mentorship/user/mentorQaListRequests?o=${page}`, body);
};

//social -> chapters

export const getChapter = async (filter = '', offset = '') => {
  return await api.getReq(`/chapter/manageChapters?filter=${filter}&offset=${offset}&limit=6`);
};

export const chapterdeleteAdmin = async (id) => {
  return await api.delReq(`/chapter/delete?id=${id}`);
};

export const chapterDiscover = async () => {
  return await api.getReq(`/chapter/discover`);
};

// export const chapterDiscover = async () => {
//   return await api.getReq(`/user/group-discover`);
// };

export const chapterTopSlider = async () => {
  return await api.getReq(`/chapter/discover-slider`);
};

export const similierChapterInstitute = async (chapter_id) => {
  return await api.getReq(`/chapter/similierChaptersInstitute?chapter_id=${chapter_id}`);
};

export const getUserMentor = async () => {
  return await api.getReq(`/user/getUserMentorsDirectory`);
};

export const chapterJoinedChapterList = async (filter = '', offset = '') => {
  return await api.getReq(`/chapter/userChapters?filter=${filter}&offset=${offset}&limit=6`);
};

export const groupDiscoverlist = async () => {
  return await api.getReq(`/user/group-discover`);
};

export const similierGroupInstitute = async (group_id) => {
  return await api.getReq(`/group/similierGroupsInstitute?group_id=${group_id}`);
};

export const getMentorshipAnswer = async (page = '') => {
  return await api.getReq(`/mentorship/answer/my-answers?o=${page}`);
};

export const mentorshipQaFeedLike = async (body) => {
  return await api.PostReq(`/mentorship/qaFeeds/add-like`, body);
};

export const mentorshipAnswerLike = async (body) => {
  return await api.PostReq(`/mentorship/answer/add-like`, body);
};

export const followMentor = async (body) => {
  return await api.PostReq(`/ajax/socialAjax?t=follow&a=mentor`, body);
};

export const removeFriend = async (body) => {
  return await api.PostReq(`user/removeFriend`, body);
};
export const removeMentor = async (body) => {
  return await api.PostReq(`/user/removeMentor`, body);
};
export const removeMentee = async (body) => {
  return await api.PostReq(`/user/removeMentee`, body);
};
export const deleteMyQuestionAns = async (body) => {
  return await api.PostReq(`mentorship/deleteQuestionAnswer`, body);
};

export const sendMessageFormConnectionPage = async (body) => {
  return await api.PostReq(`/user/sendMessage?t=message&a=send_message`, body);
};

export const filtermentors = async (
  name = '',
  industry = '',
  mentorshipArea = '',
  location = ''
) => {
  return await api.getReq(
    `/user/getUserMentors?name=${name}&industry=${industry}&mentorshipArea=${mentorshipArea}&location=${location}`
  );
};

export const filtermentees = async (
  name = '',
  industry = '',
  mentorshipArea = '',
  location = ''
) => {
  return await api.getReq(
    `/user/getUserMentees?name=${name}&industry=${industry}&mentorshipArea=${mentorshipArea}&location=${location}`
  );
};

export const filterfriend = async (name = '', location = '') => {
  return await api.getReq(`/user/getUserFriends?name=${name}&location_id=${location}`);
};
export const similierClubInstitute = async (institute_id) => {
  return await api.getReq(`/clubs/similarClubsInstitute?institute_id=${institute_id}`);
};
