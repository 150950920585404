/**
@copyright  :   ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
@author     :   Shiv Charan Panjeta 
 
All Rights Reserved.
Proprietary and confidential :  All information contained here in is, and remains
the property of ToXSL Technologies Pvt. Ltd. and it's partners.
Unauthorized copying of this file, via any medium is strictly prohibited.
*/
import { useEffect, useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Footer from '../CommonComponents/Footer';
import SideBar from '../CommonComponents/Sidebar';
import Header from '../CommonComponents/Header';
import { Alert, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import { Button } from 'react-bootstrap';
import * as adminServices from '../../services/AuthServices';
import { useNavigate } from 'react-router-dom';
import Loader from '../../components/commoncomponents/loader/loader-large';
import { restrictAlpha, statusName } from '../../globals/helper';
import ReactPaginate from 'react-paginate';
import useDocumentTitle from '../../globals/useDocumentTitle';
import { useSelector } from 'react-redux';

export default function City() {
  useDocumentTitle('Manage Cities | AlmaBay');
  const isAlert = useSelector((state) => state?.alert);
  const [list, setList] = useState([]);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [searchedData, setSearchData] = useState({
    city_id: '',
    city_name: '',
    state_name: '',
    country_name: ''
  });
  const [response, setResponse] = useState('');
  const [sortType, setSortType] = useState('DESC');
  const [sortField, setSortField] = useState('city_id');
  const [currentPage, setCurrentPage] = useState(0);
  const [alert, setAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState('');

  useEffect(() => {
    apiCall();
  }, []);

  useEffect(() => {
    const timeId = setTimeout(() => {
      setAlert(false);
      setAlertMsg('');
    }, 3000);
    return () => {
      clearTimeout(timeId);
    };
  }, [alert]);

  useEffect(() => {
    if (isAlert?.showAlertBox) {
      setAlertMsg(isAlert?.message);
      setAlert(true);
    }
  }, []);

  const apiCall = (
    page = '',
    sortfield = 'city_id',
    order = 'DESC',
    city_id = '',
    city_name = '',
    state_name = '',
    country_name = ''
  ) => {
    setLoading(true);
    setCurrentPage(page);
    adminServices
      .getCityList(page, sortfield, order, city_id, city_name, state_name, country_name)
      .then((res) => {
        setLoading(false);
        if (res?.status === 200) {
          setList(res?.data?.data);
          setResponse(res?.data?.pager);
        }
      });
  };

  const selectHandler = (value, id) => {
    switch (value) {
      case 'edit':
        navigate('/admin/location/edit-city', { state: id });
        break;
      case 'view':
        navigate('/admin/location/view-city', { state: id });
        break;
      case '0':
        stateHandler(value, id);
        break;
      case '1':
        stateHandler(value, id);
        break;
      case '2':
        stateHandler(value, id);
        break;
      default:
        break;
    }
  };

  const stateHandler = (value, id) => {
    setLoading(true);
    let status = value;
    adminServices.cityUpdateStatus(id, status).then((res) => {
      setLoading(false);
      if (res?.status === 200) {
        document.getElementById('select').value = '';
        if (value == 2 || value == 1 || value == 0) {
          setAlert(true);
          setAlertMsg(res?.data?.message);
        }
        apiCall();
      }
    });
  };

  const resetHandler = (e) => {
    e.preventDefault();
    setSearchData({
      city_id: '',
      city_name: '',
      state_name: '',
      country_name: ''
    });
    setSortType('DESC');
    setSortField('');
    apiCall();
  };

  const sortHandler = (e, type) => {
    e.preventDefault();
    let currentSort = sortType === 'ASC' ? 'DESC' : 'ASC';
    setSortType(currentSort);
    setSortField(type);
    apiCall(
      currentPage,
      type,
      currentSort,
      searchedData.city_id,
      searchedData.city_name,
      searchedData.state_name,
      searchedData.country_name
    );
  };

  const onChangeSearch = (e) => {
    const { name, value } = e.target;
    setSearchData({ ...searchedData, [name]: value });
  };

  const searchHandler = (e) => {
    e.preventDefault();
    apiCall(
      currentPage,
      sortField,
      sortType,
      searchedData.city_id,
      searchedData.city_name,
      searchedData.state_name,
      searchedData.country_name
    );
  };

  const handlePageClick = (a) => {
    let page = a?.selected + 1;
    apiCall(
      page,
      sortField,
      sortType,
      searchedData.city_id,
      searchedData.city_name,
      searchedData.state_name,
      searchedData.country_name
    );
  };

  return (
    <>
      <div className="dash-main-content">
        <Container fluid>
          <div className="dash-page-content">
            <div className="dash-page-title">
              <Row>
                <Col xs={12} sm={12} md={12}>
                  <div className="dash-title-bar d-flex align-items-center justify-content-between">
                    <div className="title-lft d-flex align-items-center">
                      <div className="dash-icn">
                        <i className="fas fa-building"></i>
                      </div>
                      <div className="dash-title-dis">
                        <h5>
                          City <span>Listing</span>
                        </h5>
                        <h5>
                          Total Record(s): <span>{response?.total}</span>
                        </h5>
                      </div>
                    </div>
                    <div className="title-rt-btn">
                      <Link className="green_button_admin" to="/admin/location/add-city">
                        Add New City
                      </Link>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="dash-content-card">
              <Alert variant={'success'} className={`${alert ? '' : 'd-none'}`}>
                {alertMsg}
              </Alert>
              <div className="table-responsive">
                <Table className="custom-table-data" striped bordered hover>
                  <thead>
                    <tr>
                      <th>
                        <Link
                          to="#"
                          onClick={(e) => {
                            sortHandler(e, 'city_id');
                          }}>
                          Id
                          <img src={require('../../assets/images/sort.png')} alt="sort" />
                        </Link>
                      </th>
                      <th>
                        <Link
                          to="#"
                          onClick={(e) => {
                            sortHandler(e, 'city');
                          }}>
                          City
                          <img src={require('../../assets/images/sort.png')} alt="sort" />
                        </Link>
                      </th>
                      <th>
                        <Link
                          to="#"
                          onClick={(e) => {
                            sortHandler(e, 'state_id');
                          }}>
                          State
                          <img src={require('../../assets/images/sort.png')} alt="sort" />
                        </Link>
                      </th>
                      <th>Country</th>
                      <th>Latitude</th>
                      <th>Longitude</th>
                      <th>Status</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="search-table">
                      <td>
                        <Form.Control
                          type="text"
                          value={searchedData.city_id}
                          name="city_id"
                          onChange={(e) => {
                            onChangeSearch(e);
                          }}
                          onKeyDown={(e) => restrictAlpha(e)}
                          placeholder="Id"
                        />
                      </td>
                      <td>
                        <Form.Control
                          type="text"
                          value={searchedData.city_name}
                          name="city_name"
                          onChange={(e) => {
                            onChangeSearch(e);
                          }}
                          placeholder="City Name"
                        />
                      </td>
                      <td>
                        <Form.Control
                          type="text"
                          value={searchedData.state_name}
                          name="state_name"
                          onChange={(e) => {
                            onChangeSearch(e);
                          }}
                          placeholder="State"
                        />
                      </td>
                      <td>
                        <Form.Control
                          type="text"
                          value={searchedData.country_name}
                          name="country_name"
                          onChange={(e) => {
                            onChangeSearch(e);
                          }}
                          placeholder="Country"
                        />
                      </td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td className="serac-btns text-center">
                        <button
                          type="button"
                          className="green_button_admin"
                          onClick={(e) => {
                            searchHandler(e);
                          }}>
                          Search
                        </button>
                        <Button
                          className="main-btn-red ms-2"
                          onClick={(e) => {
                            resetHandler(e);
                          }}>
                          Reset
                        </Button>
                      </td>
                    </tr>
                    {/* loop */}
                    {list && list?.length ? (
                      list?.map((data, index) => (
                        <tr key={index}>
                          <td>{data?.city_id}</td>
                          <td>{data?.city}</td>
                          <td>{data?.state?.state}</td>
                          <td>{data?.country_details?.name}</td>
                          <td>{data?.latitude}</td>
                          <td>{data?.longitude}</td>
                          <td>{statusName(data?.status)}</td>
                          <td>
                            <Form.Select
                              aria-label="Default select example"
                              id="select"
                              value={''}
                              onChange={(e) => {
                                selectHandler(e.target.value, data?.city_id);
                              }}>
                              <option value="">Action</option>
                              <option value="edit">Edit</option>
                              <option value="view">View</option>
                              {data?.status === '0' ? (
                                <option value="1">Enable</option>
                              ) : (
                                <option value="0">Disable</option>
                              )}
                              <option value="2">Delete</option>
                            </Form.Select>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr className="text-center">
                        <td colSpan={8}>Data Not Found.</td>
                      </tr>
                    )}
                  </tbody>
                </Table>
                {/* <div className='react-page d-flex'>
                  <li>First</li>
                  <ReactPaginate
                    previousLabel={currentPage > 0 ? "<" : ""}
                    nextLabel=">"
                    onPageChange={handlePageClick}
                    // pageRangeDisplayed={3}
                    // marginPagesDisplayed={4}
                    pageCount={(response.total / 10)}
                  />
                  <li>Last</li>
                </div> */}
                {response?.pageCount > 1 ? (
                  <div className="react-page d-flex">
                    <ReactPaginate
                      previousLabel={currentPage > 0 ? '<' : ''}
                      nextLabel={response?.total > 0 ? '>' : ''}
                      onPageChange={handlePageClick}
                      pageCount={response?.total / 10}
                      activeClassName={'page-link active'}
                    />
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </Container>
      </div>
      {loading ? <Loader /> : ''}
    </>
  );
}
