import { useEffect, useRef, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { useOnClickOutside } from '../../../globals/helper';
import useDocumentTitle from '../../../globals/useDocumentTitle';
import showNotification from '../../../services/NotificationService';
import * as APISERVICES from '../../../services/SocialServices';
import MemberShipPOPUP from '../../commoncomponents/MemberShipPOPup/MemberShipPOPUP';
import PostBottomMedia from './PostComponets/PostBottomMedia';
import MentorePostLayout from './PostComponets/MentorePostLayout';
import AddPollQuestion from '../user-pages/AddPollQuestion';
import {
  post_publisher_soundcloud_placeholder,
  post_publisher_youtube_placeholder
} from '../../../globals/constants';
import useDebounce from '../../../globals/useDebounce';
import { youtubeVideoAction } from '../../../redux/action';

function ProfileRecentUpdate() {
  /* eslint-disable */
  useDocumentTitle('AlmaBay');
  const { wall_user_id } = useParams();
  const dispatch = useDispatch();
  const [latestPost, setLatestPost] = useState('');
  const [privacyList, setPrivacyList] = useState('');
  const [checkPost, setCheckPost] = useState([]);
  const [selectedFile, setSelectedFile] = useState([]);
  const [isSelected, setIsSelected] = useState(false);
  const [show, setShow] = useState(false);
  const [selectedData, setSelectedData] = useState(['4']);
  const [multiSelectData, setMultiSelectData] = useState([]);
  const [singleSelectName, setSingleSelectName] = useState('Everyone');
  const [multiSelectName, setmultiSelectName] = useState([]);
  const [loading, setLoading] = useState(false);
  const [disableInputBox, setDisableInputBox] = useState(false);
  const [disableAddPost, setDisableAddPost] = useState(false);
  const [emojiList, setEmojiList] = useState([]);
  const [showEmoji, setShowEmoji] = useState(false);
  const [postLoading, setPostLoading] = useState(false);
  const ref = useRef();
  const [soundCloud, setSoundCloud] = useState(false);
  const [youtubeVideoCloud, setYoutubeVideoCloud] = useState(false);
  const [postGoogleMapState, setPostGoogleMapState] = useState(false);
  const [isShowPoll, setIsShowPoll] = useState(false);

  useOnClickOutside(ref, () => setShow(false));
  // const [multi, setMulti] = useState(false);

  const [showCard, setShowCard] = useState(true);
  const [totalPages, setTotalPages] = useState(0);
  const [pageNum, setPageNum] = useState(1);

  const isloggedIn = useSelector((state) => state?.user?.isloggedIn);
  const userDetail = useSelector((state) => state?.user?.details);
  const [search, setSearch] = useState('');

  const result = useSelector((state) => state?.youtubeVideoList?.videos);

  useEffect(() => {
    if (isloggedIn) {
      getPostListData();
      isPrivacylist();
    }
  }, []);

  useEffect(() => {
    const getEmojiIcons = () => {
      APISERVICES.getPublisherBox().then((res) => {
        if (res?.status == 200) {
          let arr = [];
          let data = res?.data?.data?.emoticons;
          for (let i in data) {
            arr.push({ id: i, label: data[i], value: data[i] });
          }
          setEmojiList(arr);
        } else {
          setEmojiList([]);
        }
      });
    };
    getEmojiIcons();
  }, []);

  const getPostListData = (isTrue, postId) => {
    if (isTrue !== 'delete' || isTrue !== 'share') {
      setLoading(true);
      setDisableInputBox(true);
      setDisableAddPost(true);
    }

    APISERVICES.getSocialTimeLineStories(wall_user_id, pageNum).then((res) => {
      if (res?.status === 200) {
        setLoading(false);
        setPostLoading(false);
        setDisableInputBox(false);
        setDisableAddPost(false);
        if (isTrue === true) {
          let arra1 = checkPost.map((item) => {
            const item2 = res.data?.data?.stories?.find((i2) => i2.id == item.id);
            return item ? { ...item, ...item2 } : item2;
          });
          setCheckPost(arra1);
          setPostLoading(false);
          setLatestPost('');
        } else {
          setCheckPost([...(checkPost ?? []), ...(res?.data?.data?.stories ?? [])]);
        }

        if (isTrue == 'share') {
          let checkPostIDs = checkPost?.map((data) => data?.id);

          let arra1 = checkPost.map((item) => {
            const item2 = res.data?.data?.stories?.find((i2) => i2.id == item.id);
            return item ? { ...item, ...item2 } : item2;
          });
          APISERVICES.getSocialTimeLineStories(wall_user_id, 1).then((res2) => {
            let filterData = res2.data?.data?.stories.filter((item) => {
              return !checkPostIDs.includes(item?.id);
            });
            setCheckPost([...filterData, ...arra1]);
          });
        }
        if (isTrue == 'unshare') {
          let arra1 = checkPost
            .map((item) => {
              const item2 = res.data?.data?.stories?.find((i2) => i2.id == item.id);
              return item ? { ...item, ...item2 } : item2;
            })
            ?.filter((data) => !(data?.post_id == postId && data?.via_type == 'share'));
          setCheckPost(arra1);
        }
        setTotalPages(Math.ceil(res?.data?.data?.total_records / 10));
      } else {
        setLoading(false);
        setDisableInputBox(false);
        setDisableAddPost(false);
      }
    });
  };

  const isPrivacylist = () => {
    APISERVICES.postPrivacyList().then((res) => {
      if (res?.status == 200) {
        let arr = [];
        let data = res?.data?.data;
        for (let i in data) {
          arr.push({ id: i, label: data[i], value: data[i] });
        }
        setPrivacyList(arr);
      }
    });
  };

  const checkLatestpost = (e) => {
    e.preventDefault();
    setDisableAddPost(true);
    setPostLoading(true);
    if (multiSelectData?.length !== 0 || selectedData.length !== 0) {
      let formData = new FormData();
      formData.append(`text`, latestPost.trim());
      formData.append(`recipient_id`, '');
      formData.append(`timeline_id`, userDetail?.id);
      formData.append(
        `post_privacy[]`,
        multiSelectData?.length !== 0 ? multiSelectData.toString() : selectedData.toString()
      );
      for (let i in selectedFile) {
        formData.append(`photos[]`, selectedFile[i]);
      }
      APISERVICES.postLatest(formData).then((res) => {
        if (res?.status === 200) {
          setPostLoading(false);
          showNotification('success', res?.data?.message);
          setSelectedFile([]);
          setIsSelected(false);
          setSelectedData([4]);
          setMultiSelectData([]);
          setmultiSelectName([]);
          setSingleSelectName('');
          setLatestPost('');
          APISERVICES.getSocialTimeLineStories(wall_user_id).then((res) => {
            if (res?.status === 200) {
              setLoading(false);
              setCheckPost(res?.data?.data?.stories);
              // setTotalPages(res?.data?.pager.pageCount);
              setDisableAddPost(false);
              setTotalPages(2);
            } else {
              setDisableAddPost(false);
              setLoading(false);
            }
          });
        }
      });
    } else {
      showNotification('danger', 'Please select privacy before publishing post');
    }
  };

  const makePost = (e) => {
    e.preventDefault();
    if (latestPost?.trim() !== '' || selectedFile.length !== 0) {
      checkLatestpost(e);
    } else {
      showNotification(
        'danger',
        'This status update appears to be blank. Please write something or attach photo to update your status.'
      );
    }
  };

  const settingPrivacySelect = (e, data, index) => {
    e.preventDefault();
    if (data?.id == 0 || data?.id == 4) {
      setMultiSelectData([]);
      setmultiSelectName([]);
      setSingleSelectName(data?.value);
      if (selectedData.includes(data?.id)) {
        setSelectedData([]);
        setSingleSelectName('');
      } else {
        setSelectedData([data?.id]);
      }
    } else {
      setSelectedData([]);
      setSingleSelectName('');
      if (multiSelectData.includes(data?.id)) {
        let filterData = multiSelectData?.filter((item) => item !== data?.id);
        let filterName = multiSelectName?.filter((item) => item !== data?.value);

        setMultiSelectData(filterData);
        setmultiSelectName(filterName);
      } else {
        setMultiSelectData([...multiSelectData, data?.id]);
        setmultiSelectName([...multiSelectName, data?.value]);
      }
    }
  };

  const privacyDropClassSingle = (data) => {
    if (selectedData?.length == 0) {
      return '';
    } else {
      if (selectedData?.includes(data?.id)) {
        return 'selected';
      } else {
        return 'disabled-component';
      }
    }
  };
  const privacyDropClassMulti = (data) => {
    if (multiSelectData?.length == 0) {
      return '';
    } else {
      if (multiSelectData?.includes(data?.id)) {
        return 'selected';
      } else {
        return '';
      }
    }
  };

  const selectedValue = () => {
    let name;
    if (multiSelectData?.length !== 0 || singleSelectName !== '') {
      if (multiSelectName?.length !== 0) {
        name = multiSelectName?.map((name) => name).join(',');

        return <Form.Control type="text" value={name} onClick={() => setShow(!show)} />;
      } else {
        return <Form.Control type="text" value={singleSelectName} onClick={() => setShow(!show)} />;
      }
    } else {
      return <Form.Control type="text" value={'Nothing selected'} onClick={() => setShow(!show)} />;
    }
  };

  const handleClosePopUp = (e) => {
    e.preventDefault();
    setShowCard(false);
  };

  const onPostListData = () => {
    getPostListData();
  };
  const onIncrementPageNo = () => {
    setPageNum((no) => no + 1);
  };
  const toggleMediaGroupFun = (chosen_input_selector = '') => {
    setSoundCloud(false);
    setYoutubeVideoCloud(false);
    setPostGoogleMapState(false);

    if (chosen_input_selector == 'soundcloud-wrapper') {
      setSoundCloud(true);
    } else if (chosen_input_selector == 'youtube-wrapper') {
      setYoutubeVideoCloud(true);
    } else if (chosen_input_selector == 'google-map-wrapper') {
      setPostGoogleMapState(true);
    }
  };
  const newParagraph = (e) => {
    if (e.key === 'Enter') {
    }
  };

  const debouncedSearchTerm = useDebounce(search, 500);

  useEffect(() => {
    if (debouncedSearchTerm) {
      dispatch(youtubeVideoAction(search));
    } else {
      dispatch(youtubeVideoAction());
    }
  }, [debouncedSearchTerm]);
  return (
    <>
      <section className="news-feed-sec bg-grey py-4">
        <Container>
          <Row>
            <Col xs={12} lg={9} xl={12} className="mb-4">
              {showCard ? (
                <>
                  <MemberShipPOPUP handleClosePopUp={handleClosePopUp} />
                </>
              ) : (
                <></>
              )}

              {/* post -social page- */}
              {!isShowPoll ? (
                <div className="write-on-wall mb-3">
                  <h4>
                    <i className="fas fa-edit me-2"></i> What's going on?
                  </h4>
                  <textarea
                    rows={5}
                    style={{ width: '100%', resize: 'none' }}
                    placeholder={'Write something... #hashtags'}
                    onChange={(e) => {
                      setLatestPost(e.target.value);
                    }}
                    disabled={postLoading}
                    onKeyDown={(e) => newParagraph(e)}
                    value={latestPost}
                  ></textarea>

                  {soundCloud && (
                    <div className="upload-img-detail d-flex align-items-center justify-content-between">
                      <i className="fa fa-music"></i>
                      <input
                        className="soundcloud-input"
                        type="text"
                        //   onKeyUp={() => SK_searchSoundcloud()}
                        placeholder={post_publisher_soundcloud_placeholder}
                      />
                      {/* <div className="input-result-wrapper"></div> */}
                    </div>
                  )}

                  {youtubeVideoCloud && (
                    <div className="upload-img-detail d-flex align-items-center justify-content-start">
                      <i className="fa fa-film me-2"></i>
                      <input
                        className="youtube-input"
                        type="text"
                        onChange={(e) => {
                          setSearch(e.target.value);
                        }}
                        placeholder={post_publisher_youtube_placeholder}
                      />
                      <div className="input-result-wrapper"></div>
                    </div>
                  )}

                  {postGoogleMapState && (
                    <div className="upload-img-detail d-flex align-items-center justify-content-between">
                      <i className="fa fa-map-marker "></i>
                      <input
                        className="youtube-input"
                        type="text"
                        //   onKeyUp={() => SK_searchYoutube()}
                        placeholder={post_publisher_youtube_placeholder}
                      />

                      {/* <input id="autocomplete" className="google-map-input" name="google_map_name" type="text" value="" placeholder="<?php echo $this->lang->line('post_publisher_googlemap_placeholder'); ?>" apply-google-places ></input> */}
                    </div>
                  )}

                  {/* <CKEditorComponent
                      latestPost={latestPost}
                      setLatestPost={setLatestPost}
                      isToolBar={false}
                      placeholder={'Write something... #hashtags'}
                    /> */}

                  {isSelected && (
                    <div className="upload-img-detail d-flex align-items-center justify-content-between">
                      <p>
                        {selectedFile?.length > 1
                          ? `${selectedFile.length} photo(s) selected`
                          : `${selectedFile.length} photo selected`}
                      </p>
                      <Link
                        to="#"
                        onClick={() => {
                          setIsSelected(false);
                          setSelectedFile([]);
                        }}
                      >
                        <i className="fas fa-times"></i>
                      </Link>
                    </div>
                  )}

                  {showEmoji && (
                    <div className="upload-img-detail d-flex align-items-center justify-content-between">
                      {emojiList?.length !== 0 && (
                        <>
                          {emojiList?.map((data) => {
                            return (
                              <Link
                                to="#"
                                onClick={(e) => {
                                  e.preventDefault();
                                  setLatestPost(latestPost + data?.id);
                                }}
                              >
                                <img src={data?.value} />
                              </Link>
                            );
                          })}
                        </>
                      )}
                    </div>
                  )}
                  {result.length > 0 && youtubeVideoCloud ? (
                    <div className="input_result_wrapper">
                      {result.map((item, i) => {
                        return (
                          <div className="api_data_wrapper">
                            <table border={0} width={'100%'} cellSpacing={0} cellPadding={0}>
                              <tbody>
                                <tr>
                                  <td width={'40%'} align="left" valign="middle">
                                    <img
                                      className="thumbnail"
                                      width={32}
                                      height={32}
                                      src={item.snippet.thumbnails.high.url}
                                      alt="Youtube"
                                    />
                                  </td>
                                  <td align="left" valign="middle">
                                    <div className="name">{item.snippet.title}</div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        );
                      })}
                    </div>
                  ) : (
                    search != '' && <span>No Result Found</span>
                  )}
                  {/* <textarea
                  type="text"
                  className="form-control"
                  id="yourmessage"
                  name="yourmessage"
                   onChange={(e) => {
                    setLatestPost(e.target.value);
                  }}
                  value={latestPost}
                  placeholder="Write something... #hashtags"
                  rows={3}
                ></textarea> */}
                  <div className="write-wall-ftr d-flex align-items-center justify-content-between">
                    <PostBottomMedia
                      setSelectedFile={setSelectedFile}
                      setIsSelected={setIsSelected}
                      state={'social-timeline'}
                      setShowEmoji={setShowEmoji}
                      showEmoji={showEmoji}
                      toggleMediaGroup={toggleMediaGroupFun}
                      setIsShowPoll={setIsShowPoll}
                    />
                    <div className="wall-fltr-rght d-flex align-items" ref={ref}>
                      <div className="wall-ftr-rt">
                        <Form.Group className="mb-0" controlId="formBasictext">
                          {selectedValue()}
                        </Form.Group>
                        {show && (
                          <ul className="wall-lst">
                            {privacyList?.length !== 0 ? (
                              privacyList?.map((data, index) => {
                                return (
                                  <li key={index}>
                                    <Link
                                      className={
                                        multiSelectData?.length !== 0
                                          ? privacyDropClassMulti(data)
                                          : privacyDropClassSingle(data)
                                      }
                                      onClick={(e) => settingPrivacySelect(e, data, index)}
                                    >
                                      {data?.value}
                                    </Link>
                                  </li>
                                );
                              })
                            ) : (
                              <li>
                                <Link className="selected disabled-component">No data found</Link>
                              </li>
                            )}
                          </ul>
                        )}
                      </div>
                      {/* <Select
                      name="colors"
                      className="basic-multi-select"
                      // isMulti={multi}
                      classNamePrefix="select"
                      options={privacyList}
                      value={selectedPrivacy}
                      onChange={(e) => {
                        setSelectedPrivacy(e);
                      }}
                    /> */}

                      <button
                        style={postLoading ? { cursor: 'not-allowed' } : { cursor: 'pointer' }}
                        disabled={postLoading}
                        className="accnt-btn ms-2"
                        onClick={(e) => {
                          makePost(e);
                        }}
                      >
                        <i className="far fa-arrow-alt-circle-right me-1"></i>
                        {postLoading ? 'Processing' : 'Post'}
                      </button>
                    </div>
                  </div>
                </div>
              ) : (
                <AddPollQuestion setIsShowPoll={setIsShowPoll} getPostListData={getPostListData} />
              )}
              <MentorePostLayout
                isPostLoading={loading}
                checkPost={checkPost}
                getPostListData={getPostListData}
                totalPages={totalPages}
                onIncrementPageNo={onIncrementPageNo}
                pageNum={pageNum}
                onPostListData={onPostListData}
                setCheckPost={setCheckPost}
                disableInputBox={disableInputBox}
                setDisableInputBox={setDisableInputBox}
              />
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}

export default ProfileRecentUpdate;
