import { useEffect, useState } from 'react';
import { Col, Container, Form, Row, Table } from 'react-bootstrap';
import ReactPaginate from 'react-paginate';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import Loader from '../../components/commoncomponents/loader/loader-large';
import { communicationListAction } from '../../redux/action';
import { communicationList, deleteCommunicationPlan } from '../../services/AuthServices';
import showNotification from '../../services/NotificationService';

const CommunicationList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const planList = useSelector((state) => state?.planList);
  const [value, setValue] = useState('');
  const [orderBy, setOrderBy] = useState('DESC');
  const [sortOrder, setSortOrder] = useState('id');
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const response = await communicationList(sortOrder, orderBy, page);
        if (response?.status === 200) {
          setLoading(false);
          dispatch(communicationListAction(response.data ?? []));
        }
      } catch (error) {
        setLoading(false);
        console.error(error);
      } finally {
        setLoading(false);
      }
    })();
  }, [orderBy, sortOrder, page]);

  const handleChange = async (value, id) => {
    if (value === 'edit') {
      navigate(`/admin/manage-communication/add-plan`, { state: id });
      setValue('');
    }
    if (value === 'delete') {
      const message = window.confirm('Are you sure you want to delete?');
      if (message) {
        try {
          const response = await deleteCommunicationPlan(id);
          if (response?.status === 200) {
            showNotification('success', response.data.message);
            setValue('');
            (async () => {
              try {
                const response = await communicationList();
                if (response?.status === 200) {
                  dispatch(communicationListAction(response.data.data ?? []));
                }
              } catch (error) {
                console.error(error);
              }
            })();
          }
        } catch (error) {
          console.error(error);
        }
      }
    }
  };

  const planDuration = (plan_duration) => {
    switch (plan_duration) {
      case '1':
        return 'Monthly';
      case '2':
        return 'Yearly';

      default:
        return;
    }
  };

  const planType = (type_id) => {
    switch (type_id) {
      case '3':
        return 'Email';
      case '2':
        return 'SMS';

      default:
        return;
    }
  };

  const planStatus = (status_id) => {
    switch (status_id) {
      case '0':
        return 'Disabled';
      case '1':
        return 'Enabled';
      case '2':
        return 'Deleted';

      default:
        return;
    }
  };

  return (
    <>
      <div className="dash-main-content">
        <Container fluid>
          <div className="dash-page-content">
            <div className="dash-page-title">
              <Row>
                <Col xs={12} sm={12} md={12}>
                  <div className="dash-title-bar d-flex align-items-center justify-content-between">
                    <div className="title-lft d-flex align-items-center">
                      <div className="dash-icn">
                        <i className="fas fa-comments"></i>
                      </div>
                      <div className="dash-title-dis">
                        <h5>Manage Communication Plan</h5>
                        <h5>
                          Total Record(s): <span>{planList?.count}</span>
                        </h5>
                      </div>
                    </div>
                    <div className="title-rt-btn d-flex align-items-center">
                      <Link className="add-btn-grn mx-2" to="/admin/manage-communication/add-plan">
                        Add Plan
                      </Link>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="dash-content-card">
              <div className="table-responsive">
                <Table className="custom-table-data" striped bordered hover>
                  <thead>
                    <tr>
                      {/* <th>
                          <Form.Check type="checkbox" id="checkbox" label="" />
                        </th> */}
                      <th
                        onClick={() => {
                          setSortOrder('id');
                          setOrderBy(orderBy === 'DESC' ? 'ASC' : 'DESC');
                        }}>
                        Id
                        <img src={require('../../assets/images/sort.png')} alt="sort" />
                      </th>
                      <th
                        onClick={() => {
                          setSortOrder('plan_name');
                          setOrderBy(orderBy === 'DESC' ? 'ASC' : 'DESC');
                        }}>
                        Name
                        <img src={require('../../assets/images/sort.png')} alt="sort" />
                      </th>
                      <th
                        onClick={() => {
                          setSortOrder('messages_count');
                          setOrderBy(orderBy === 'DESC' ? 'ASC' : 'DESC');
                        }}>
                        No. of Messages
                        <img src={require('../../assets/images/sort.png')} alt="sort" />
                      </th>
                      <th>Plan Duration</th>
                      <th>Plan Type</th>
                      <th>Status</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {planList?.data?.length > 0 ? (
                      planList?.data?.map((plans, index) => {
                        return (
                          <tr>
                            {/* <td>
                                <Form.Check type="checkbox" label="" />
                              </td> */}
                            <td>{plans.id}</td>
                            <td>{plans.plan_name}</td>
                            <td>{plans?.messages_count}</td>
                            <td>{planDuration(plans?.plan_duration)}</td>
                            <td>{planType(plans.type_id)}</td>
                            <td>{planStatus(plans.status_id)}</td>
                            <td>
                              <Form.Select
                                onChange={(e) => handleChange(e.target.value, plans.id)}
                                aria-label="Default select example"
                                name="value"
                                value={value}>
                                <option value="">Select</option>
                                <option value={'edit'}>Edit</option>
                                <option value={'delete'}>Delete</option>
                              </Form.Select>
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td className="text-center" colSpan={7}>
                          Data not found
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </div>
              {Math.ceil(planList?.count / 10) > 1 && (
                <div className="react-page d-flex justify-content-center">
                  <ReactPaginate
                    previousLabel={'Prev'}
                    nextLabel={'Next'}
                    activeClassName={'page-link active'}
                    onPageChange={(props) => {
                      setPage(props.selected + 1);
                    }}
                    pageCount={Math.ceil(planList?.count / 10)}
                  />
                </div>
              )}
            </div>
          </div>
        </Container>
      </div>
      {loading && <Loader />}
    </>
  );
};

export default CommunicationList;
