import { useState, useEffect } from 'react';
import { Col, Container, Dropdown, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import HeaderLogin from '../../commoncomponents/HeaderLogin';
import TopTabbings from './TopTabbings';
import SuggestMentor from '../../commoncomponents/newsfeeds/SuggestMentor';
import GroupDiscover from '../../commoncomponents/newsfeeds/GroupDiscover';
import WhatsNew from '../../commoncomponents/newsfeeds/WhatsNew';
import Upcoming from '../../commoncomponents/newsfeeds/Upcoming';
import NewsSidebar from '../../commoncomponents/newsfeeds/NewsSidebar';
import * as APISERVICES from '../../../services/MentorArticlesServices';
import useDocumentTitle from '../../../globals/useDocumentTitle';
import { MiniFooter } from '../../MiniFooter';
import moment from 'moment';
import { truncate } from '../../../globals/helper';
import showNotification from '../../../services/NotificationService';
import { MentorshipSkelton } from '../../commoncomponents/skelton/allskelton';
import NoDataFound from '../../commoncomponents/NoDataFound';

function QuestionforYou() {
  useDocumentTitle('AlmaBay');
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [questionList, setQuestionList] = useState([]);

  useEffect(() => {
    getQuestionsList();
  }, []);

  const getQuestionsList = () => {
    setLoading(true);
    APISERVICES.questionMentorshipList(page).then((res) => {
      setLoading(false);
      if (res?.status === 200) {
        setQuestionList(res?.data?.detail);
      }
    });
  };

  const HelpFullHandler = (e, id) => {
    e.preventDefault();
    const body = {
      q_id: id,
      answer_id: 0,
      type: 'like'
    };

    let formData = new FormData();

    for (let i in body) {
      formData.append(`${i}`, body[i]);
    }
    APISERVICES.mentorshipQaFeedLike(formData).then((res) => {
      if (res?.status === 200) {
        showNotification('success', 'Marked as helpful, Success!');
        getQuestionsList();
      }
    });
  };

  return (
    <>
      <HeaderLogin />
      <div className="header-lnk-feeds">
        <div className="container">
          <TopTabbings state={'mentor'} />
        </div>
      </div>
      <section className="news-feed-sec bg-grey py-4">
        <Container>
          <Row>
            <Col xs={12} md={2}>
              <NewsSidebar state="2" current="question-you" />
            </Col>
            <Col xs={12} md={7}>
              <div className="articals_div">
                {loading &&
                  Array.from(Array(3), () => {
                    return <MentorshipSkelton />;
                  })}
                {!loading && questionList.length !== 0 ? (
                  questionList?.map((data, index) => {
                    return (
                      <div className="single_artical_card leave-ques-card p-3" key={index}>
                        <div className="d-flex align-items-center mb-3">
                          <Link
                            className="media_img"
                            to={`/user/timeline/${data?.asked_by?.encrypted_id}`}
                          >
                            <img
                              src={
                                data?.asked_by?.avatar?.complete_url
                                  ? data?.asked_by?.avatar?.complete_url
                                  : data?.asked_by?.avatar_url
                              }
                              alt="user_img"
                              className="me-3"
                            />
                          </Link>
                          <div className="media-body">
                            <h4>
                              <Link
                                className="me-2 user-link"
                                to={`/user/timeline/${data?.asked_by?.encrypted_id}`}
                              >
                                {data?.asked_by?.name}
                              </Link>
                              {data?.content_type === 'question'
                                ? 'asked a question'
                                : 'shared an article'}
                            </h4>
                            <p>{moment(data?.added_on).fromNow()}</p>
                          </div>
                        </div>
                        <div>
                          <h4 className="title">
                            <Link
                              to={`${
                                data?.content_type === 'question'
                                  ? `/user/viewanswer/${data?.id}`
                                  : `/user/mentor-article/${data?.id}`
                              }`}
                            >
                              {data?.title}
                            </Link>
                          </h4>
                          <span
                            dangerouslySetInnerHTML={{ __html: truncate(data?.description, 500) }}
                          />
                        </div>
                        <div>
                          <Link
                            className="read-more"
                            to={`${
                              data?.content_type === 'question'
                                ? `/user/viewanswer/${data?.id}`
                                : `/user/mentor-article/${data?.id}`
                            }`}
                          >
                            Read more...
                          </Link>
                        </div>
                        <div className="navigation-border">
                          <ul className="article-cmmnt-lnks d-flex ps-2">
                            <li className="active-helpful me-3">
                              {data?.liked_by_current_user ? (
                                <>
                                  <i className="fa fa-heart "></i> Helpful{' '}
                                  <span>({data?.likes})</span>
                                </>
                              ) : (
                                <Link
                                  to="#"
                                  className="pe-0 "
                                  onClick={(e) => {
                                    HelpFullHandler(e, data?.id);
                                  }}
                                >
                                  <i className="fa fa-heart "></i>Helpful{' '}
                                  <span>({data?.likes})</span>
                                </Link>
                              )}
                            </li>
                            <li>
                              <Link
                                to={`${
                                  data?.content_type === 'question'
                                    ? `/user/viewanswer/${data?.id}`
                                    : `/user/mentor-article/${data?.id}`
                                }`}
                                className="pe-3"
                              >
                                <i className="fa fa-comment"></i> {'  '}{' '}
                                {data?.content_type === 'question' ? 'Answers' : 'Comments'}{' '}
                                <span>
                                  (
                                  {data?.content_type === 'question'
                                    ? data?.answers
                                    : data?.comments}
                                  )
                                </span>
                              </Link>
                            </li>
                            <li className="share-drop-btn">
                              <Dropdown>
                                <Dropdown.Toggle className="pe-3" id="dropdown-basic">
                                  <i className="fa fa-share"></i> Share
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="p-2">
                                  <Dropdown.Item href="#/action-1">Facebook</Dropdown.Item>
                                  <Dropdown.Item href="#/action-2">Twitter</Dropdown.Item>
                                  <Dropdown.Item href="#/action-3">Google</Dropdown.Item>
                                  <Dropdown.Item href="#/action-3">Linkedin</Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            </li>
                          </ul>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <>
                    {!loading && questionList.length == 0 && (
                      <NoDataFound text="We do not have questions suggestions for you at the moment." />
                    )}
                  </>
                )}
              </div>
            </Col>
            <Col md={3}>
              <div>
                <SuggestMentor />
              </div>
              <div>
                <GroupDiscover />
              </div>
              <div>
                <WhatsNew />
              </div>
              <div>
                <Upcoming />
              </div>
              <MiniFooter />
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}

export default QuestionforYou;
