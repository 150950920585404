import { Link } from 'react-router-dom';

function ArticalBlogs() {
  return (
    <>
      <div className="suggest-mentor artical-blog-card bg-white p-3 mb-3">
        <div className="d-flex align-items-top justify-content-between flex-wrap">
          <h4>Articles And Blogs</h4>
        </div>
        <div className="artical-text">
          <p>Share with the world what you are passionate about!</p>
          <p>
            Write a blog or an article on your favourite topic and Almabay will help you to reach
            out to millions of readers and make a star out of you
          </p>
        </div>
        <Link className="artical-btn" to="/user/writeArticle">
          Add Articles And Blogs
        </Link>
      </div>
    </>
  );
}

export default ArticalBlogs;
