/**
@copyright  :   ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
@author     :   Shiv Charan Panjeta 
 
All Rights Reserved.
Proprietary and confidential :  All information contained here in is, and remains
the property of ToXSL Technologies Pvt. Ltd. and it's partners.
Unauthorized copying of this file, via any medium is strictly prohibited.
*/

import { Route, Routes } from 'react-router-dom';
import RecruiterSolutions from '../../components/commoncomponents/RecruiterSolutions';
import NewsView from '../../components/commoncomponents/newsfeeds/NewsView';
import ForgotPassword from '../../components/webpages/auth-pages/ForgotPassword';
import RegisterPage from '../../components/webpages/auth-pages/RegisterPage';
import ResetPassword from '../../components/webpages/auth-pages/ResetPassword';
import AboutPage from '../../components/webpages/cms-public-pages/About';
import AlmabayCanHelp from '../../components/webpages/cms-public-pages/AlmabayCanHelp';
import BlogPage from '../../components/webpages/cms-public-pages/BlogPage';
import BrowseJob from '../../components/webpages/cms-public-pages/BrowseJob';
import CampusBrand from '../../components/webpages/cms-public-pages/CampusBrand';
import ContactUs from '../../components/webpages/cms-public-pages/ContactUs';
import DeliveryInformation from '../../components/webpages/cms-public-pages/DeliveryInformation';
import DemoVideo from '../../components/webpages/cms-public-pages/DemoVideo';
import Events from '../../components/webpages/cms-public-pages/Events';
import FaqPage from '../../components/webpages/cms-public-pages/FaqPage';
import InstituteDirectory from '../../components/webpages/cms-public-pages/InstituteDirectory';
import InviteFriends from '../../components/webpages/cms-public-pages/InviteFriends';
import JobsDetailOut from '../../components/webpages/cms-public-pages/JobsDetailOut';
import LandingPage from '../../components/webpages/cms-public-pages/LandingPage';
import MentorsDirectory from '../../components/webpages/cms-public-pages/MentorsDirectory';
import PrivacyPolicy from '../../components/webpages/cms-public-pages/PrivacyPolicy';
import Recruiters from '../../components/webpages/cms-public-pages/Recruiters';
import ReturnPolicy from '../../components/webpages/cms-public-pages/ReturnPolicy';
import SiteMap from '../../components/webpages/cms-public-pages/SiteMap';
import SmartSolutions from '../../components/webpages/cms-public-pages/SmartSolutions';
import TermsOfUsePage from '../../components/webpages/cms-public-pages/TermsOfUse';
import WhatIsAlmabay from '../../components/webpages/cms-public-pages/WhatIsAlmabay';
import CompanyDetails from '../../components/webpages/user-pages/CompanyDetails';
import EventsDetail from '../../components/webpages/user-pages/Events/EventsDetail';
import JobsMap from '../../components/webpages/user-pages/JobsMap';
import MentorArticleDetails from '../../components/webpages/user-pages/MentorArticleDetails';
import MentorQuestionDetail from '../../components/webpages/user-pages/MentorQuestionDetail';
import MyArticles from '../../components/webpages/user-pages/MyArticles';
import NewsPage from '../../components/webpages/user-pages/NewsPage';
import WithOutLoginCompanyDetail from '../../components/webpages/user-pages/WithOutLoginCompanyDetail';
import StoreRoutes from '../store-routes/StoreRoutes';
import RecuriterSolutions from '../../components/webpages/cms-public-pages/RecuriterSolutions';

function CmsPublicRoutes() {
  return (
    <Routes>
      <>
        <Route path="/" element={<LandingPage />} />
        <Route path="/shop/*" element={<StoreRoutes />} />
        <Route path="/about-us" element={<AboutPage />} />
        <Route path="/terms_of_use" element={<TermsOfUsePage />} />
        <Route path="/return-policy" element={<ReturnPolicy />} />
        <Route path="/delivery-information" element={<DeliveryInformation />} />
        <Route path="/privacy_policy" element={<PrivacyPolicy />} />
        <Route path="/faq" element={<FaqPage />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/campus-brand" element={<CampusBrand />} />
        <Route path="/demovideo" element={<DemoVideo />} />
        <Route path="/home/recruitments" element={<Recruiters />} />
        <Route path="/smart-solutions" element={<SmartSolutions />} />
        <Route path="/what-is-almabay" element={<WhatIsAlmabay />} />
        <Route path="/how-almabay-can-help-you" element={<AlmabayCanHelp />} />
        <Route path="/sitemap" element={<SiteMap />} />
        <Route path="/mentors-directory" element={<MentorsDirectory />} />
        <Route path="/events" element={<Events />} />
        <Route path="/events-detail/:userId/:state" element={<EventsDetail />} />
        <Route path="/events-detail/:userId/:state/:editId" element={<EventsDetail />} />
        <Route path="/blog" element={<BlogPage />} />
        <Route path="/company/index/:id" element={<CompanyDetails />} />
        <Route path="/myarticle" element={<MyArticles />} />
        <Route path="/recruiter-solutions" element={<RecuriterSolutions />} />
        <Route path="/pages/pinvite" element={<InviteFriends />} />
        <Route path="/pages/pinvite/:id" element={<InviteFriends />} />
        <Route path="/invite" element={<InviteFriends />} />
        <Route path="/advancesearch/directory/:sort" element={<InstituteDirectory />} />
        <Route path="/jobs-detail-out" element={<JobsDetailOut />} />
        <Route path="/news/show/:id" element={<NewsView />} />
        <Route path="/recruiter-solutions" element={<RecruiterSolutions />} />
        {/* <Route path="/groups/group/:encrypted_id" element={<GroupDetail />} /> */}
        <Route path="/news" element={<NewsPage />} />
        <Route path="/register" element={<RegisterPage />} />
        <Route path="/recover/password" element={<ForgotPassword />} />
        <Route path="/recover/change-passowrd/:token" element={<ResetPassword />} />
        <Route path="/jobs/search" element={<BrowseJob />} />
        <Route path="/jobs/details/:id" element={<JobsDetailOut />} />
        <Route path="/jobs/search/location/:locationId" element={<BrowseJob />} />
        <Route path="/jobs/search/category/:catId" element={<BrowseJob />} />
        <Route path="/company/index/:id" element={<WithOutLoginCompanyDetail />} />
        {/* User routes */}
        <Route path={`/user/jobsmap`} element={<JobsMap />} />
        <Route path={`/user/alumnimap`} element={<JobsMap />} />
        <Route path="/user/viewanswer/:id" element={<MentorQuestionDetail />} />
        <Route path="/user/mentor-article/:id" element={<MentorArticleDetails />} />
        <Route path="/user/articleComment/:id" element={<MentorArticleDetails />} />
        <Route path="/news/show/:id" element={<NewsView />} />
        <Route path="/news" element={<NewsPage />} />
      </>
    </Routes>
  );
}

export default CmsPublicRoutes;
