/**
@copyright  :   ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
@author     :   Shiv Charan Panjeta 
 
All Rights Reserved.
Proprietary and confidential :  All information contained here in is, and remains
the property of ToXSL Technologies Pvt. Ltd. and it's partners.
Unauthorized copying of this file, via any medium is strictly prohibited.
*/
import { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { Link, useLocation } from 'react-router-dom';
import * as APISERVICES from '../../../services/AuthServices';

export default function ViewCampusNews() {
  const [detail, setDetail] = useState('');
  const location = useLocation();
  const id = new URLSearchParams(location?.search).get('id');

  useEffect(() => {
    apiCall(id);
  }, []);

  const apiCall = (id) => {
    APISERVICES.getWhatNewsView(id).then((res) => {
      if (res?.status === 200) {
        let data = res?.data?.data;
        setDetail(data);
      }
    });
  };
  return (
    <div className="dash-main-content">
      <Container fluid>
        <div className="dash-page-content">
          <div className="dash-page-title">
            <Row>
              <Col xs={12} sm={12} md={12}>
                <div className="dash-title-bar d-flex align-items-center justify-content-between">
                  <div className="title-lft d-flex align-items-center">
                    <div className="dash-icn">
                      <i className="fas fa-file-word"></i>
                    </div>
                    <div className="dash-title-dis">
                      <h5>
                        {' '}
                        View What's New Details <span></span>
                      </h5>
                    </div>
                  </div>
                  <div className="title-rt-btn ">
                    <Link className="add-btn-grn" to="/admin/news">
                      Back To Listing
                    </Link>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <div className="dash-content-card">
            <div className="view-detail-content">
              <Row>
                <Col md={12}>
                  <p>
                    <strong>Title:</strong>
                    {detail?.title}
                  </p>
                </Col>
              </Row>

              <Row>
                <Col md={12}>
                  <p>
                    <strong>Short Description:</strong>
                    <span dangerouslySetInnerHTML={{ __html: detail?.descp }} />
                  </p>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <p>
                    <strong>image:</strong>
                    <div>
                      <img
                        src={process.env.REACT_APP_API_URL + detail?.image_url}
                        alt="news"
                        width="400px"
                        height="250px"
                      />
                    </div>
                  </p>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  {/* <p>
                      <strong>url:</strong>
                      {detail?.url}
                    </p> */}
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <p>
                    <strong>status:</strong>
                    {detail?.status == 1 ? 'Enable' : 'Disable'}
                  </p>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}
