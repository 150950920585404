import { useState, useEffect } from 'react';
import { Form, Col, Container, Dropdown, Row, Popover, OverlayTrigger } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import HeaderLogin from '../../commoncomponents/HeaderLogin';
import TopTabbings from './TopTabbings';
import SuggestMentor from '../../commoncomponents/newsfeeds/SuggestMentor';
import GroupDiscover from '../../commoncomponents/newsfeeds/GroupDiscover';
import WhatsNew from '../../commoncomponents/newsfeeds/WhatsNew';
import Upcoming from '../../commoncomponents/newsfeeds/Upcoming';
import NewsSidebar from '../../commoncomponents/newsfeeds/NewsSidebar';
import * as APISERVICES from '../../../services/MentorArticlesServices';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import showNotification from '../../../services/NotificationService';
import moment from 'moment';
import Loader from '../../commoncomponents/loader/loader-large';
import HeaderInner from '../../commoncomponents/HeaderInner';
import useDocumentTitle from '../../../globals/useDocumentTitle';
import { shareLinkAction } from '../../../redux/action';
import { useDispatch } from 'react-redux';

function MentorArticleDetails() {
  useDocumentTitle('article | AlmaBay');
  const dispatch = useDispatch();
  const result = useSelector((state) => state?.shareLink?.links);
  const [detail, setDetail] = useState([]);
  const [commentDetail, setCommentDetail] = useState([]);
  const [addComment, setAddComment] = useState('');
  const [currentId, setCurrentId] = useState('');
  const userDetail = useSelector((state) => state?.user?.details);
  const [loading, setLoading] = useState(false);
  const isloggedIn = useSelector((state) => state?.user?.isloggedIn);
  const [showErrorMsg, setShowErrorMsg] = useState(false);
  const navigate = useNavigate();

  const { id } = useParams();

  useEffect(() => {
    getArticleDetails();
  }, []);

  const getArticleDetails = () => {
    setLoading(true);
    APISERVICES.articleDetail(id).then((res) => {
      if (res?.status === 200) {
        setLoading(false);
        setDetail(res?.data?.data);
        // setDetail(res?.data?.detail?.question);
        // let currentUserId = res?.data?.detail?.question?.map((data) => data?.asked_by?.id);
        setCurrentId(res?.data?.data?.users?.user_id);
      } else {
        setLoading(false);
      }
    });

    APISERVICES.articleComments(id).then((res) => {
      if (res?.status === 200) {
        setCommentDetail(res?.data?.data);
      }
    });
  };

  const commentHandler = (e) => {
    e.preventDefault();
    if (isloggedIn) {
      let formData = new FormData();
      if (addComment) {
        formData.append('q_id', id);
        formData.append('QComment', addComment);
        APISERVICES.addArticleComment(formData).then((res) => {
          if (res?.status === 200) {
            getArticleDetails();
            setAddComment('');
            showNotification('success', res?.data?.message);
          }
        });
      } else {
        showNotification('danger', "can't post blank comment");
      }
    } else {
      setShowErrorMsg(true);
    }

    //api call
  };

  const deleteHandler = (id) => {
    if (confirm('Do you want to delete your comment ?') == true) {
      APISERVICES.deleteArticleComment(id).then((res) => {
        if (res?.status === 200) {
          getArticleDetails();
          showNotification('success', 'Comment removed successfully');
        }
      });
    } else {
      return;
    }
  };

  const HelpFullHandler = (e, id) => {
    e.preventDefault();
    if (isloggedIn) {
      let body = {
        q_id: id,
        answer_id: '0',
        type: 'like'
      };
      let formData = new FormData();

      for (let i in body) {
        formData.append(`${i}`, body[i]);
      }
      APISERVICES.articleHelpful(formData).then((res) => {
        if (res?.status === 200) {
          showNotification('success', 'Marked as helpful, Success!');
          getArticleDetails(true);
        }
      });
    }
  };
  const deleteArticleHandler = (id) => {
    if (confirm('Are you sure you want to delete article?') === true) {
      APISERVICES.deleteMyArticle(id).then((res) => {
        if (res?.status === 200) {
          navigate('/user/myArticles');
        }
      });
    } else {
      return;
    }
  };

  const renderPopover = (props) => {
    let popOverData = props?.popper?.state?.options?.detail?.users
      ? props?.popper?.state?.options?.detail?.users
      : props?.popper?.state?.options?.detail?.users;
    return (
      <Popover className="group-loader-card" id={`popover-basic`} {...props}>
        {/* <Popover.Header as="h3">Popover right</Popover.Header> */}
        <Popover.Body>
          <div className="loader-tooltip">
            <div className="loader-cover">
              <img
                src={
                  popOverData?.image_url
                    ? `${process.env.REACT_APP_API_URL}${popOverData?.avatar}`
                    : require('../../../assets/images/default-male-avatar.png')
                }
                alt="img"
                className="loader-image"
              />
            </div>
            <div className="people-know-loader-dis d-flex align-items-top">
              <div className="people-knw-top d-flex align-items-center">
                <div className="people-icn me-3">
                  <img
                    src={
                      popOverData?.image_url
                        ? `${process.env.REACT_APP_API_URL}${popOverData?.avatar}`
                        : require('../../../assets/images/default-male-avatar.png')
                    }
                    alt="img"
                    className="loader-image"
                  />
                </div>
                <div className="">
                  <h3>
                    {popOverData?.first_name} {popOverData?.last_name}{' '}
                  </h3>
                </div>
              </div>
            </div>
            <div className="people-knw-dis-popover">
              <p>
                <i className="fas fa-graduation-cap me-1"></i> Studied at{' '}
                <Link to="#">SD COLLEGE Chandigarh</Link> in <Link to="#">2005</Link>
              </p>
              {/* *have to add key for this, key not added from backend */}
              <p>
                <i className="fas fa-briefcase me-1"></i>Worked as{' '}
                <Link to="#">Software developer</Link> {' at '}{' '}
                {/* *have to add key for this, key not added from backend */}
                <Link to="#">TCS PVT LTD</Link>{' '}
                {/* *have to add key for this, key not added from backend */}
              </p>
            </div>
          </div>
        </Popover.Body>
      </Popover>
    );
  };

  return (
    <>
      {!isloggedIn ? <HeaderInner /> : <HeaderLogin />}
      {isloggedIn ? (
        <div className="header-lnk-feeds">
          <div className="container">
            <TopTabbings state={'mentor'} />
          </div>
        </div>
      ) : (
        ''
      )}
      <section className="news-feed-sec bg-grey py-4">
        <Container>
          <Row>
            {isloggedIn && (
              <Col xs={12} md={2}>
                <NewsSidebar />
              </Col>
            )}

            <Col xs={12} md={isloggedIn ? 7 : 12}>
              <div className="articals_div">
                <div className="single_artical_card  p-3">
                  <div className="d-flex justify-content-between mb-3">
                    <div className="d-flex align-items-center mb-3">
                      <Link
                        className="media_img"
                        to={isloggedIn ? `/user/timeline/${detail?.users?.encrypted_id}` : ''}>
                        <OverlayTrigger
                          trigger={['hover', 'focus']}
                          placement="bottom"
                          delay={{ show: 500, hide: 1500 }}
                          data={detail}
                          popperConfig={{
                            detail
                          }}
                          shouldUpdatePosition={true}
                          overlay={renderPopover}>
                          <img
                            src={process.env.REACT_APP_API_URL + detail?.users?.avatar}
                            alt="user_img"
                            className="me-3"
                          />
                        </OverlayTrigger>

                        {/* <img
                          src={process.env.REACT_APP_API_URL + detail?.users?.avatar}
                          alt="user_img"
                          className="me-3"
                        /> */}
                      </Link>
                      <div className="media-body">
                        <h4>
                          <Link
                            className="user-link"
                            to={isloggedIn ? `/user/timeline/${detail?.users?.encrption_id}` : ''}>
                            {detail?.users?.first_name} {detail?.users?.last_name}
                          </Link>
                        </h4>
                        <p>
                          <Link title={detail?.added_on}>
                            {moment(detail?.added_on).fromNow()}{' '}
                          </Link>{' '}
                        </p>
                      </div>
                    </div>
                    <div className="drop-ellipse-actn">
                      <Dropdown className="ellipse-drop-card ms-3">
                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                          <i className="fas fa-angle-down"></i>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item as={Link} to={`/user/writeArticle?id=${detail?.id}`}>
                            Edit{' '}
                          </Dropdown.Item>
                          <Dropdown.Item
                            href="#"
                            onClick={() => {
                              deleteArticleHandler(detail?.id);
                            }}>
                            Delete
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </div>

                  <div>
                    <h4 className="title">{detail?.title}</h4>
                    <p className="mb-0">
                      <b>Description:</b>
                    </p>
                    <span dangerouslySetInnerHTML={{ __html: detail?.description }} />
                    {detail?.topics_name ? (
                      <div className="topic-card mt-3 d-flex align-items-center">
                        <h4>Topics:</h4>
                        <div className="topics-lst">
                          {detail?.topics_name?.split(',')?.map((detail, index) => {
                            return <span key={index}>{detail}</span>;
                          })}
                        </div>
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                  <div className="navigation-border">
                    <ul className="article-cmmnt-lnks d-flex ps-2">
                      <li className=" pe-3">
                        {detail?.is_self_liked ? (
                          <Link style={{color:"orange"}} title="You marked as helpful">
                            <i className="fa fa-heart   "></i> Helpful{' '}
                            <span>({detail?.total_likes_count})</span>
                          </Link>
                        ) : (
                          <Link
                            to="#"
                            className="pe-3"
                            onClick={(e) => {
                              HelpFullHandler(e, detail?.id);
                            }}>
                            <Link title="Marked as helpful">
                              <i className="fa fa-heart  "></i> Helpful{' '}
                            </Link>{' '}
                            <span>({detail?.total_likes_count})</span>
                          </Link>
                        )}
                      </li>
                      <li className="share-drop-btn">
                        <Dropdown
                          onClick={() => {
                            dispatch(shareLinkAction(id));
                          }}>
                          <Dropdown.Toggle className="pe-3" id="dropdown-basic">
                            <i className="fa fa-share"></i> Share
                          </Dropdown.Toggle>
                          <Dropdown.Menu className="p-2">
                            <Dropdown.Item href={result.facebook}>Facebook</Dropdown.Item>
                            <Dropdown.Item href={result.twitter}>Twitter</Dropdown.Item>
                            <Dropdown.Item href={result.google_plus}>Google</Dropdown.Item>
                            <Dropdown.Item href={result.linkdin}>Linkedin</Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </li>
                      {id && detail?.attachments?.length > 0 ? (
                        <li className="share-drop-btn attach-btn-drop">
                          <Dropdown>
                            <Dropdown.Toggle className="pe-3" id="dropdown-basic">
                              <i className="fas fa-file"></i> Attachments(
                              {detail?.attachments?.length})
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="p-2">
                              {detail?.attachments &&
                                detail?.attachments?.map((item, index) => (
                                  <Dropdown.Item key={index} href={item?.document} target="__blank">
                                    {item?.remarks}
                                  </Dropdown.Item>
                                ))}
                            </Dropdown.Menu>
                          </Dropdown>
                        </li>
                      ) : (
                        ''
                      )}
                    </ul>
                  </div>
                </div>
              </div>

              <div className="articals_div ">
                <div className="leave-cmmnt-card-outr">
                  <h5 className="mb-3">Comments</h5>
                  <div className="leave-cmmnt-lst">
                    {commentDetail && commentDetail?.length
                      ? commentDetail?.map((data, index) => {
                          return (
                            <>
                              <div className="leave-cmmnt-card bg-white d-flex align-items-top p-3">
                                {userDetail?.id === data?.users?.user_id ? (
                                  <Link
                                    className="close-btn"
                                    to="#"
                                    onClick={() => {
                                      deleteHandler(data?.id);
                                    }}>
                                    <i className="fas fa-times"></i>
                                  </Link>
                                ) : userDetail?.id == currentId ? (
                                  <Link
                                    className="close-btn"
                                    to="#"
                                    onClick={() => {
                                      deleteHandler(data?.id);
                                    }}>
                                    <i className="fas fa-times"></i>
                                  </Link>
                                ) : (
                                  ''
                                )}
                                <div className="leave-cmmnt-icn">
                                  <img
                                    src={
                                      data?.users?.avtar
                                        ? process.env.REACT_APP_API_DOMAIN + data?.users?.avtar
                                        : require('../../../assets/images/default-male-avatar.png')
                                    }
                                    alt="user_img"
                                  />
                                </div>
                                <div className="leave-cmmnt-media">
                                  <h4>
                                    <Link to={`/user/timeline/${detail?.users?.encrption_id}`}>
                                      {data?.users?.first_name} {data?.users?.last_name}
                                    </Link>
                                    <span>{moment(data?.added_on).fromNow()}</span>
                                  </h4>
                                  <div dangerouslySetInnerHTML={{ __html: data?.QComment }} />
                                </div>
                              </div>
                            </>
                          );
                        })
                      : 'This article has been not commented yet, be the first to do it.'}
                  </div>
                  <div className="leave-cmmnt-frm p-3 bg-white">
                    <h4 className="mb-3">Leave A comment</h4>
                    <Form.Group className="" controlId="exampleForm.ControlTextarea1">
                      {/* <CKEditor
                        name="description"
                        data={addComment}
                        editor={ClassicEditor}
                        onReady={(editor) => {
                          // You can store the "editor" and use when it is needed.
                        }}
                        config={
                          ({
                            toolbar: [
                              'bold',
                              'italic',
                              'numberedList',
                              'bulletedList',
                              'outdent',
                              'indent'
                            ]
                          },
                          { placeholder: 'Write a comment...' })
                        }
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          setAddComment(data);
                        }}
                        onBlur={(event, editor) => {}}
                        onFocus={(event, editor) => {}}
                      /> */}

                      {/*   onclient side there is no ckeditor */}

                      <textarea
                        type="text"
                        className="form-control"
                        value={addComment}
                        onChange={(e) => setAddComment(e.target.value)}
                        placeholder="write a comment"
                        rows={5}></textarea>
                    </Form.Group>
                    <div className="cmmnt-btn mt-4">
                      <button
                        className="accnt-btn"
                        onClick={(e) => {
                          commentHandler(e);
                        }}>
                        Comment
                      </button>
                    </div>
                  </div>
                </div>
                {showErrorMsg ? (
                  <p className="text-danger"> Please Sign in or Sign up to answer.</p>
                ) : (
                  ''
                )}
              </div>
            </Col>
            {isloggedIn && (
              <Col xs={12} md={3}>
                <div>
                  <SuggestMentor />
                </div>
                <div>
                  <GroupDiscover />
                </div>
                <div>
                  <WhatsNew />
                </div>
                <div>
                  <Upcoming />
                </div>
              </Col>
            )}
          </Row>
        </Container>
      </section>
      {loading ? <Loader /> : null}
    </>
  );
}

export default MentorArticleDetails;
