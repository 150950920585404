/**
@copyright  :   ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
@author     :   Shiv Charan Panjeta 
 
All Rights Reserved.
Proprietary and confidential :  All information contained here in is, and remains
the property of ToXSL Technologies Pvt. Ltd. and it's partners.
Unauthorized copying of this file, via any medium is strictly prohibited.
*/

import moment from 'moment/moment';
import { useEffect, useState } from 'react';
import { Button, Container, Modal } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Table from 'react-bootstrap/Table';
import ReactPaginate from 'react-paginate';
import Loader from '../../components/commoncomponents/loader/loader-large';
import useDocumentTitle from '../../globals/useDocumentTitle';
import * as adminServices from '../../services/AuthServices';

export default function Contact() {
  useDocumentTitle('Manage Contacts | AlmaBay');
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [viewDtaa, setViewData] = useState('');
  const [loading, setLoading] = useState(false);
  const [record, setRecord] = useState('');
  const [list, setList] = useState([]);
  const [searchedData, setSearchData] = useState({
    contact_id: '',
    contact_email: '',
    contact_name: '',
    contact_phone_skype: ''
  });
  const [currentPage, setCurrentPage] = useState(0);

  useEffect(() => {
    apiCallGetList();
  }, []);

  const apiCall = (
    contact_id = '',
    contact_email = '',
    contact_name = '',
    contact_phone_skype = ''
  ) => {
    setLoading(true);
    let body = {
      contact_id,
      contact_email,
      contact_name,
      contact_phone_skype
    };
    adminServices.getConteactListSearch(body).then((res) => {
      setLoading(false);
      if (res?.status == 200) {
        setList(res?.data?.detail?.viewlist);
        setRecord(res?.data?.detail?.totalRecords);
      }
    });
  };

  const searchHandler = (e) => {
    e.preventDefault;
    apiCall(
      searchedData.contact_id,
      searchedData.contact_email,
      searchedData.contact_name,
      searchedData.contact_phone_skype
    );
  };

  const handleShow = (data) => {
    setShow(true);
    setViewData(data);
  };

  const onChangeSearch = (e) => {
    const { name, value } = e.target;
    setSearchData({ ...searchedData, [name]: value });
  };

  const apiCallGetList = (page = 1) => {
    setCurrentPage(page);
    adminServices.getContectList(page).then((res) => {
      if (res?.status == 200) {
        setList(res?.data?.data);
        setRecord(res?.data?.pager?.total);
      }
    });
  };

  const handlePageClick = (a) => {
    let currentPage = a?.selected + 1;
    apiCallGetList(currentPage);
  };

  return (
    <>
      <div className="dash-main-content">
        <Container fluid>
          <div className="dash-page-content">
            <div className="dash-page-title">
              <Row>
                <Col xs={12} sm={12} md={12}>
                  <div className="dash-title-bar d-flex align-items-center justify-content-between">
                    <div className="title-lft d-flex align-items-center">
                      <div className="dash-icn">
                        <i className="fas fa-comments"></i>
                      </div>
                      <div className="dash-title-dis">
                        <h5>
                          Contact <span> Listing</span>
                        </h5>
                        <h5>
                          Total Record(s):
                          <span> {record}</span>
                        </h5>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="dash-content-card">
              <div className="table-responsive">
                <Table className="custom-table-data" striped bordered hover>
                  <thead>
                    <tr>
                      <th>Id</th>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Phone/Skype ID</th>
                      <th>Date Created</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="search-table">
                      <td>
                        <Form.Control
                          type="text"
                          value={searchedData?.contact_id}
                          name="contact_id"
                          placeholder="id"
                          onChange={(e) => {
                            onChangeSearch(e);
                          }}
                        />
                      </td>
                      <td>
                        <Form.Control
                          type="text"
                          value={searchedData?.contact_name}
                          name="contact_name"
                          placeholder="name "
                          onChange={(e) => {
                            onChangeSearch(e);
                          }}
                        />
                      </td>
                      <td>
                        <Form.Control
                          type="text"
                          value={searchedData?.contact_email}
                          name="contact_email"
                          placeholder="email"
                          onChange={(e) => {
                            onChangeSearch(e);
                          }}
                        />
                      </td>
                      <td>
                        <Form.Control
                          type="text"
                          value={searchedData?.contact_phone_skype}
                          name="contact_phone_skype"
                          placeholder="phone"
                          onChange={(e) => {
                            onChangeSearch(e);
                          }}
                        />
                      </td>
                      <td></td>
                      <td className="serac-btns text-center">
                        <button
                          type="button"
                          className="green_button_admin"
                          onClick={(e) => {
                            searchHandler(e);
                          }}>
                          Search
                        </button>
                      </td>
                    </tr>
                    {list && list?.length ? (
                      list?.map((data, index) => {
                        return (
                          <tr key={index}>
                            <td>{data?.id}</td>
                            <td>{data?.name}</td>
                            <td>{data?.email}</td>
                            <td>{data?.phone_skype}</td>
                            <td>{moment(data?.created).format('Do MMM, YYYY')}</td>
                            <td>
                              <span
                                role="presentation"
                                onClick={() => {
                                  handleShow(data);
                                }}>
                                <i className="fas fa-search-plus"></i>
                              </span>
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr className="text-center">Data Not Found </tr>
                    )}
                  </tbody>
                </Table>
                <div className="react-page d-flex">
                  {record / 10 > 1 ? (
                    <ReactPaginate
                      previousLabel={currentPage > 0 ? '<' : ''}
                      nextLabel=">"
                      onPageChange={handlePageClick}
                      pageCount={record / 10}
                      activeClassName={'page-link active'}
                    />
                  ) : (
                    ''
                  )}
                </div>
              </div>
            </div>
          </div>
          {/* modal */}
          <Modal show={show} onHide={handleClose} animation={false}>
            <Modal.Header closeButton>
              <Modal.Title>View Contact Details</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="dash-content-card">
                <div className="view-detail-content">
                  <Row>
                    <Col md={12}>
                      <p>
                        <strong>Name : </strong>
                        {viewDtaa?.name}
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <p>
                        <strong>Email : </strong>
                        {viewDtaa?.email}
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <p>
                        <strong>Phone/Skype ID : </strong>
                        {viewDtaa?.phone_skype}
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <p>
                        <strong>Saying : </strong>
                        {viewDtaa?.saying_id}
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <p>
                        <strong> Message : </strong>
                        {viewDtaa?.message}
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <p>
                        <strong>Date Created : </strong>
                        {moment(viewDtaa?.created).format('Do,MMM,YYYY')}
                      </p>
                    </Col>
                  </Row>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        </Container>
      </div>
      {loading ? <Loader /> : ''}
    </>
  );
}
