import { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Pagination from 'react-bootstrap/Pagination';
import Row from 'react-bootstrap/Row';
import Table from 'react-bootstrap/Table';
import ReactPaginate from 'react-paginate';
import { Link, useNavigate } from 'react-router-dom';
import Loader from '../../components/commoncomponents/loader/loader-large';
import useDocumentTitle from '../../globals/useDocumentTitle';
import * as adminServices from '../../services/AuthServices';
export default function DeletedUseres() {
  useDocumentTitle('Manage Deleted Users | AlmaBay');
  const navigate = useNavigate();
  const [list, setList] = useState([]);

  const [totalRecords, setTotalRecords] = useState('');
  const [loading, setLoading] = useState(false);
  const [sortType, setSortType] = useState('ASC');
  const [sortField, setSortField] = useState('user_id');
  const [searchedData, setSearchData] = useState({
    user_id: '',
    name: '',
    email: '',
    Aemail: '',
    dob: '',
    gender: '',
    mobile: '',
    proffesion_type: '',
    website: '',
    status: ''
  });

  const [currentPage, setCurrentPage] = useState(0);
  useEffect(() => {
    apicall();
  }, []);
  const apicall = (
    page = '1',
    sortfield = 'user_id',
    order = 'ASC',
    user_id = '',
    first_name = '',
    email = '',
    status = ''
  ) => {
    setLoading(true);
    setCurrentPage(page);
    adminServices
      .getDeletedUserList(page, sortfield, order, user_id, first_name, email, status)
      .then((res) => {
        setLoading(false);
        if (res?.status === 200) {
          setList(res?.data?.detail?.viewlist);
          setTotalRecords(res?.data?.detail?.totalRecords);
        }
      });
  };

  const handlePageClick = (a) => {
    let page = a?.selected + 1;
    apicall(
      page,
      sortField,
      sortType,
      searchedData.user_id,
      searchedData.first_name,
      searchedData.email,
      searchedData.status
    );
  };

  const stateHandler = (value, id) => {
    setLoading(true);
    adminServices.userRestore(id).then((res) => {
      setLoading(false);
      if (res?.status === 200) {
        document.getElementById('select').value = '';
        apicall(currentPage);
      }
    });
  };

  const selectHandler = (value, id) => {
    switch (value) {
      case 'view':
        navigate(`/admin/users/view/${id}`);
        break;
      case 'restore':
        stateHandler(value, id);
        break;
      default:
        break;
    }
  };
  let active = 2;
  let items = [];
  for (let number = 1; number <= 5; number++) {
    items.push(
      <Pagination.Item key={number} active={number === active}>
        {number}
      </Pagination.Item>
    );
  }
  const sortHandler = (e, type) => {
    e.preventDefault();
    let currentSort = sortType === 'ASC' ? 'DESC' : 'ASC';
    setSortType(currentSort);
    setSortField(type);
    apicall(
      currentPage,
      type,
      currentSort,
      searchedData.user_id,
      searchedData.first_name,
      searchedData.email,
      searchedData.status
    );
  };

  return (
    <>
      <div className="dash-main-content">
        <Container fluid>
          <div className="dash-page-content">
            <div className="dash-page-title">
              <Row>
                <Col xs={12} sm={12} md={12}>
                  <div className="dash-title-bar d-flex align-items-center justify-content-between">
                    <div className="title-lft d-flex align-items-center">
                      <div className="dash-icn">
                        <i className="fa fa-users"></i>
                      </div>
                      <div className="dash-title-dis">
                        <h5>
                          Deleted User <span>Listing</span>
                        </h5>
                        <h5>
                          Total Users(s): <span>{totalRecords}</span>
                        </h5>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="dash-content-card">
              <div className="table-responsive">
                <Table className="custom-table-data" striped bordered hover>
                  <thead>
                    <tr>
                      <th>
                        <Link
                          to="#"
                          onClick={(e) => {
                            sortHandler(e, 'user_id');
                          }}
                        >
                          Id
                          <img src={require('../../assets/images/sort.png')} alt="sort" />
                        </Link>
                      </th>
                      <th>
                        <Link
                          to="#"
                          onClick={(e) => {
                            sortHandler(e, 'first_name');
                          }}
                        >
                          Name
                          <img src={require('../../assets/images/sort.png')} alt="sort" />
                        </Link>
                      </th>
                      <th>
                        <Link
                          to="#"
                          onClick={(e) => {
                            sortHandler(e, 'institute_name');
                          }}
                        >
                          Institute
                          <img src={require('../../assets/images/sort.png')} alt="sort" />
                        </Link>
                      </th>
                      <th>
                        <Link
                          to="#"
                          onClick={(e) => {
                            sortHandler(e, 'email');
                          }}
                        >
                          Email
                          <img src={require('../../assets/images/sort.png')} alt="sort" />
                        </Link>
                      </th>
                      <th>
                        <Link
                          to="#"
                          onClick={(e) => {
                            sortHandler(e, 'date_created');
                          }}
                        >
                          Registration Date
                          <img src={require('../../assets/images/sort.png')} alt="sort" />
                        </Link>
                      </th>
                      <th>
                        <Link
                          to="#"
                          onClick={(e) => {
                            sortHandler(e, 'date_modified');
                          }}
                        >
                          Deleted On
                          <img src={require('../../assets/images/sort.png')} alt="sort" />
                        </Link>
                      </th>
                      <th>
                        <Link
                          to="#"
                          onClick={(e) => {
                            sortHandler(e, 'status');
                          }}
                        >
                          State
                          <img src={require('../../assets/images/sort.png')} alt="sort" />
                        </Link>
                      </th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {list && list?.length ? (
                      list?.map((data, index) => {
                        return (
                          <tr key={index}>
                            <td>{data?.user_id || 'NA'}</td>
                            <td>{data?.name || 'NA'}</td>
                            <td>{data?.instituteName || 'NA'}</td>
                            <td>{data?.email || 'NA'}</td>
                            <td>{data?.regOn || 'NA'}</td>
                            <td>{data?.delOn || 'NA'}</td>
                            <td>{data?.status || 'NA'}</td>
                            <td>
                              <Form.Select
                                aria-label="Default select example"
                                id="select"
                                value={''}
                                onChange={(e) => {
                                  selectHandler(e.target.value, data?.user_id);
                                }}
                              >
                                <option value="">Action</option>
                                <option value="view">View</option>
                                <option value="restore">Restore</option>
                              </Form.Select>
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr className="text-center">
                        <td colSpan={8}>Data Not Found.</td>
                      </tr>
                    )}
                  </tbody>
                </Table>
                {totalRecords > 10 ? (
                  <div className="react-page d-flex">
                    <ReactPaginate
                      previousLabel={currentPage > 0 ? '<' : ''}
                      nextLabel={totalRecords > 0 ? '>' : ''}
                      onPageChange={handlePageClick}
                      activeClassName={'page-link active'}
                      pageCount={Math.ceil(totalRecords / 10)}
                    />
                  </div>
                ) : (
                  ''
                )}
              </div>
            </div>
          </div>
        </Container>
      </div>
      {loading ? <Loader /> : ''}
    </>
  );
}
