import { useState, useEffect } from 'react';
import { Col, Container, Dropdown, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import HeaderLogin from '../../commoncomponents/HeaderLogin';
import TopTabbings from './TopTabbings';
import SuggestMentor from '../../commoncomponents/newsfeeds/SuggestMentor';
import GroupDiscover from '../../commoncomponents/newsfeeds/GroupDiscover';
import WhatsNew from '../../commoncomponents/newsfeeds/WhatsNew';
import Upcoming from '../../commoncomponents/newsfeeds/Upcoming';
import * as APISERVICES from '../../../services/MentorArticlesServices';
import { truncate } from '../../../globals/helper';
import showNotification from '../../../services/NotificationService';
import QuestionSideBar from './QuestionSidebar';
import { useLocation, useParams } from 'react-router-dom';
import { MiniFooter } from '../../MiniFooter';
import moment from 'moment';
import { useNavigate } from 'react-router';
import { MentorshipSkelton } from '../../commoncomponents/skelton/allskelton';
import { getMentorshipProfileQuestions } from '../../../services/ProfileServices';
import { useSelector } from 'react-redux';
import HeaderInner from '../../commoncomponents/HeaderInner';

function MentorQuestions() {
  const [list, setList] = useState([]);
  const location = useLocation();
  const [listData, setListData] = useState([]); // used for searching
  const [pageNum, setPageNum] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { mentorId } = useParams();
  const [mentorInfo, setMentorInfo] = useState('');
  const isloggedIn = useSelector((state) => state?.user?.isloggedIn);

  useEffect(() => {
    isMentorshipProfile();
  }, []);

  //   useEffect(() => {
  //     if (pageNum <= totalPages) {
  //       getMyQuestionList();
  //     }
  //   }, [pageNum]);

  //   const getMyQuestionList = (isTrue) => {
  //     setLoading(true);
  //     APISERVICES.myQuestionList(pageNum).then((res) => {
  //       setLoading(false);
  //       if (res?.status === 200) {
  //         if (isTrue) {
  //           let arra1 = list.map((item) => {
  //             const item2 = res.data?.detail?.find((i2) => i2.id == item.id);
  //             return item ? { ...item, ...item2 } : item2;
  //           });
  //           setList(arra1);
  //         } else {
  //           setList([...list, ...res.data.detail]);
  //         }

  //         setListData(res?.data?.detail); // used for searching purpose
  //         setTotalPages(res?.data?.totalRecords && Math.ceil(res?.data?.totalRecords / 20));
  //       }
  //     });
  //   };

  const deleteHandler = (id) => {
    if (confirm('Are you sure you want to delete question?') === true) {
      APISERVICES.deleteMyQuestion(id).then((res) => {
        if (res?.status === 200) {
          showNotification('danger', res?.data?.message);
          isMentorshipProfile();
        }
      });
    } else {
      return;
    }
  };

  const HelpFullHandler = (e, id) => {
    e.preventDefault();
    let body = {
      q_id: id,
      answer_id: '0',
      type: 'like'
    };
    let formData = new FormData();

    for (let i in body) {
      formData.append(`${i}`, body[i]);
    }
    APISERVICES.articleHelpful(formData).then((res) => {
      if (res?.status === 200) {
        showNotification('success', 'Marked as helpful, Success!');
        // getMyQuestionList(true);
        isMentorshipProfile();
      }
    });
  };

  const handleSearch = (e) => {
    let value = e.target.value.toLowerCase();
    if (value !== '') {
      let result = listData.filter((data) => {
        return (
          data.title.toLowerCase().includes(value) ||
          data?.description.toLowerCase().includes(value)
        );
      });
      setList(result);
    } else {
      setList(listData);
    }
  };

  const handleNavigate = (e, data) => {
    e.preventDefault();
    if (data?.content_type === 'question') {
      // navigate(`/user/viewanswer/${data?.id}`, { state: 'answer-req' }); Testing issue to edit own question
      navigate(`/user/viewanswer/${data?.id}`);
    } else {
      navigate(`/user/mentor-article/${data?.id}`);
    }
  };

  const loadMore = (e) => {
    e.preventDefault();
    setPageNum((no) => no + 1);
  };

  const isMentorshipProfile = () => {
    const body = {
      uid: mentorId,
      content_type: 'questions'
    };
    getMentorshipProfileQuestions(body).then((res) => {
      if (res?.status === 200) {
        setList(res?.data?.detail);
        setMentorInfo(res?.data?.mentorInfo);
      }
    });
  };

  return (
    <>
      {!isloggedIn ? <HeaderInner /> : <HeaderLogin />}
      {isloggedIn ? (
        <div className="header-lnk-feeds">
          <div className="container">
            <TopTabbings state={'mentor'} />
          </div>
        </div>
      ) : (
        ''
      )}
      <section className="news-feed-sec bg-grey py-4">
        <Container>
          <Row>
            <Col xs={12} md={3}>
              <QuestionSideBar />
            </Col>
            <Col xs={12} md={isloggedIn ? 6 : 9}>
              <div className="articals_div">
                {/* Simmer loader */}
                {loading ? (
                  <>
                    <MentorshipSkelton />
                    <MentorshipSkelton />
                    <MentorshipSkelton />
                  </>
                ) : list && list.length > 0 ? (
                  list?.map((data, index) => {
                    return (
                      <div className="single_artical_card leave-ques-card p-3" key={index}>
                        <div className="d-flex align-items-center mb-3">
                          <div className="drop-ellipse-actn">
                            <Dropdown className="ellipse-drop-card ms-3">
                              <Dropdown.Toggle variant="success" id="dropdown-basic">
                                <i className="fas fa-angle-down"></i>
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                <Dropdown.Item as={Link} to={`/user/askquestion?id=${data?.id}`}>
                                  Edit{' '}
                                </Dropdown.Item>
                                <Dropdown.Item
                                  href="#"
                                  onClick={() => {
                                    deleteHandler(data?.id);
                                  }}
                                >
                                  Delete{' '}
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                          <Link
                            className="media_img"
                            to={`/user/timeline/${data?.asked_by?.encrypted_id}`}
                          >
                            <img
                              src={
                                data?.asked_by?.avatar?.complete_url
                                  ? data?.asked_by?.avatar?.complete_url
                                  : data?.asked_by?.avatar_url
                              }
                              alt="user_img"
                              className="me-3"
                            />
                          </Link>
                          <div className="media-body">
                            <h4>
                              <Link
                                className="user-link"
                                to={`/user/timeline/${data?.asked_by?.encrypted_id}`}
                              >
                                {data?.asked_by?.name}
                              </Link>
                              <span>
                                {data?.content_type === 'question' ? (
                                  data?.asked_from && data?.asked_from?.length != 0 ? (
                                    <>
                                      asked question from{' '}
                                      {data?.asked_from?.slice(0, 2)?.map((asked) => {
                                        return (
                                          <Link
                                            key={index}
                                            className="user-link"
                                            to={`/mentors/profile/${asked?.encrption_id}`}
                                          >
                                            {asked?.name}
                                            {data?.asked_from?.length > 1 ? ',' : ''}
                                          </Link>
                                        );
                                      })}
                                      {data?.asked_from?.length > 2 ? (
                                        <span title={data?.asked_from?.map((user) => user?.name)}>
                                          and {data?.asked_from?.length - 2} others
                                        </span>
                                      ) : (
                                        ''
                                      )}
                                    </>
                                  ) : (
                                    ' asked question'
                                  )
                                ) : (
                                  ' shared an article'
                                )}
                              </span>
                            </h4>
                            <p>{moment(data?.added_on).fromNow()}</p>
                          </div>
                        </div>
                        <div>
                          <h4 className="title">
                            <Link to="" onClick={(e) => handleNavigate(e, data)}>
                              {data?.title}
                            </Link>
                          </h4>
                          <span
                            dangerouslySetInnerHTML={{ __html: truncate(data?.description, 500) }}
                          />
                        </div>
                        <div>
                          <Link
                            className="read-more"
                            to="#"
                            onClick={(e) => handleNavigate(e, data)}
                          >
                            Read more...
                          </Link>
                        </div>
                        <div className="navigation-border">
                          <ul className="article-cmmnt-lnks d-flex ps-2">
                            <li className="active-helpful pe-3">
                              {data?.liked_by_current_user ? (
                                <>
                                  <i className="fa fa-heart "></i> Helpful{' '}
                                  <span>
                                    ({data?.likes}) {'  '}
                                  </span>
                                </>
                              ) : (
                                <Link
                                  to="#"
                                  className="pe-3"
                                  onClick={(e) => {
                                    HelpFullHandler(e, data?.id);
                                  }}
                                >
                                  <i className="fa fa-heart " title="Mark as helpful"></i> Helpful{' '}
                                  <span>
                                    ({data?.likes}) {'  '}
                                  </span>
                                </Link>
                              )}
                            </li>
                            <li>
                              <Link
                                to={`${
                                  data?.content_type === 'question'
                                    ? `/user/viewanswer/${data?.id}`
                                    : `/user/mentor-article/${data?.id}`
                                }`}
                                className="pe-3"
                              >
                                <i className="fa fa-comment"></i> {'  '}{' '}
                                {data?.content_type === 'question' ? 'Answers' : 'Comments'}{' '}
                                <span>({data?.answers || data?.comments})</span>
                              </Link>
                            </li>
                            <li className="share-drop-btn">
                              <Dropdown>
                                <Dropdown.Toggle className="pe-3" id="dropdown-basic">
                                  <i className="fa fa-share"></i> Share
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="p-2">
                                  <Dropdown.Item href="#/action-1">Facebook</Dropdown.Item>
                                  <Dropdown.Item href="#/action-2">Twitter</Dropdown.Item>
                                  <Dropdown.Item href="#/action-3">Google</Dropdown.Item>
                                  <Dropdown.Item href="#/action-3">Linkedin</Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            </li>
                          </ul>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div className="p-5 bg-white text-center">
                    <img
                      src={require('../../../assets/images/small-error.png')}
                      width="105px"
                      alt="img"
                    />
                    <p>You have not posted any questions at the moment.</p>
                  </div>
                )}

                {
                  // show this button only when last div is displayed and length is > 10
                }
                {
                  pageNum + 1 < totalPages && (
                    // list.length > 10 && (
                    <div className="load-more-btn py-3 text-center">
                      <Link to="#" className="read-more" onClick={(e) => loadMore(e)}>
                        Load More
                      </Link>
                    </div>
                  )
                  // )
                }
              </div>
            </Col>
            {isloggedIn && (
              <Col md={3}>
                <div>
                  <SuggestMentor />
                </div>
                <div>
                  <GroupDiscover />
                </div>
                <div>
                  <WhatsNew />
                </div>
                <div>
                  <Upcoming />
                </div>
                <MiniFooter />
              </Col>
            )}
          </Row>
        </Container>
      </section>
    </>
  );
}

export default MentorQuestions;
