/**
@copyright  :   ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
@author     :   Shiv Charan Panjeta 
 
All Rights Reserved.
Proprietary and confidential :  All information contained here in is, and remains
the property of ToXSL Technologies Pvt. Ltd. and it's partners.
Unauthorized copying of this file, via any medium is strictly prohibited.
*/

import { useEffect, useState } from 'react';
import { Button, Container } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { useNavigate, useParams } from 'react-router-dom';
import { AsyncPaginate } from 'react-select-async-paginate';
import CKEditorComponent from '../../components/webpages/user-pages/PostComponets/CKEditorComponent';
import { imageExtension } from '../../globals/helper';
import * as APISERVICES from '../../services/AuthServices';
import showNotification from '../../services/NotificationService';

export default function AddClubNews() {
  const { club_id, id } = useParams();
  const navigate = useNavigate();
  const [news, setNews] = useState('');
  const [clicked, setClicked] = useState(false);
  const [newsImage, setNewImage] = useState('');
  const [value, setValue] = useState([]);
  const [newData, setNewData] = useState({
    club_id: club_id,
    title: '',
    short_description: '',
    tag_id: ''
  });

  useEffect(() => {
    if (id) {
      apiCall(id);
    }
  }, []);

  /**
   * validation for mandatory field
   * @returns error {}
   */
  const validate = () => {
    let error = {};
    if (newData?.title == '') {
      error.title = 'This field is required.';
    }
    if (newData?.short_description == '') {
      error.short_description = 'This field is required.';
    }
    return error;
  };

  const loadOptions = async (search, loadedOptions, { page }) => {
    try {
      const resp = await APISERVICES.getSkillsDropdown(search, page);
      return {
        options: resp?.data?.results ?? [],
        hasMore: loadedOptions.length === resp?.data?.more ? false : true,
        additional: {
          page: search ? page + 1 : page + 1
        }
      };
    } catch (error) {
      console.log('error', error);
    }
  };

  const handleChange = (e) => {
    if (e.target.type == 'file') {
      let file = e.target.files[0];
      let type = e.target.files[0].type;
      if (imageExtension.includes(type)) {
        setNewData({
          ...newData,
          [e.target.name]: file
        });
      } else {
        showNotification('danger', 'file format not supported !');
      }
    } else {
      const { name, value } = e.target;
      setNewData({
        ...newData,
        [name]: value
      });
    }
  };

  const handleSave = (e) => {
    e.preventDefault();
    setClicked(true);
    if (!Object.keys(validate()).length) {
      let formData = new FormData();
      for (let i in newData) {
        formData.append(`${i}`, newData[i]);
      }
      formData.append('tag_id', value?.map((id) => id.name).join());
      formData.append('description', news);
      APISERVICES.addClubnews(formData).then((res) => {
        if (res?.status === 200) {
          showNotification('success', res?.data?.data);
          navigate(-1);
        }
      });
    }
  };

  const updateHandler = () => {
    let formData = new FormData();
    for (let i in newData) {
      formData.append(`${i}`, newData[i]);
    }
    formData.append('tag_id', value?.map((id) => id.name || id.label).join());
    formData.append('description', news);
    formData.append('club_id', club_id);
    APISERVICES.editClubNews(id, formData).then((res) => {
      setClicked(true);
      if (validate()) {
        setClicked(false);
        if (res?.status === 200) {
          showNotification('success', res?.data?.message);
          navigate(-1);
        }
      }
    });
  };

  const apiCall = () => {
    let body = {
      club_id,
      id
    };
    APISERVICES.getClubNewsView(body).then((res) => {
      if (res?.status === 200) {
        let data = res?.data?.details;
        setNewData({
          title: data?.title,
          short_description: data?.short_description
        });
        setNewImage(data?.attachment);
        setNews(data?.description);
        if (data?.tags_details) {
          let arr = [];
          data?.tags_details?.map((data) => {
            arr.push({ name: data.title, label: data.title, id: data.id });
          });
          setValue(arr);
        }
      }
    });
  };

  return (
    <div className="dash-main-content">
      <Container fluid>
        <div className="dash-page-content">
          <div className="dash-page-title">
            <Row>
              <Col xs={12} sm={12} md={12}>
                <div className="dash-title-bar d-flex align-items-center justify-content-between">
                  <div className="title-lft d-flex align-items-center">
                    <div className="dash-icn">
                      <i className="fas fa-newspaper"></i>
                    </div>
                    <div className="dash-title-dis">
                      <h5>{id ? 'Edit' : 'Add'} Club News</h5>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <div className="dash-content-card">
            <div className="cmmn-form">
              <Form>
                <Row>
                  <Col md={12}>
                    <Form.Group className="mb-3" controlId="formBasicName">
                      <Form.Label>
                        Title
                        <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="News Title here."
                        name="title"
                        value={newData?.title}
                        onChange={handleChange}
                      />
                      <div className={'danger-frm'}>{clicked && validate()?.title}</div>
                    </Form.Group>
                  </Col>
                  <Col md={12}>
                    <Form.Group className="mb-3" controlId="formBasicDesi">
                      <Form.Label>
                        Short Description
                        <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        as="textarea"
                        placeholder="Enter Short Description."
                        rows={3}
                        name="short_description"
                        value={newData?.short_description}
                        onChange={handleChange}
                      />
                      <div className={'danger-frm'}>{clicked && validate()?.short_description}</div>
                    </Form.Group>
                  </Col>
                  <Col md={12}>
                    <Form.Group className="mb-3" controlId="formBasicDesi">
                      <Form.Label>
                        News
                        <span className="text-danger">*</span>
                      </Form.Label>
                      <CKEditorComponent
                        isToolBar={true}
                        setLatestPost={setNews}
                        latestPost={news}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={12}>
                    <Form.Group controlId="formFileMultiple" className="mb-3">
                      <Form.Label>
                        Attach Files{' '}
                        <sub className="ms-1">
                          <i className="fas fa-info-circle"></i> (Image.)
                        </sub>
                      </Form.Label>
                      <Form.Control
                        type="file"
                        onChange={handleChange}
                        name="attachment"
                        accept="image/*"
                      />
                    </Form.Group>
                  </Col>
                  {/* <Col md={4}>
                                        {id && newsImage ? (
                                            <div className="d-flex flex-wrap align-item-center">
                                                <div className="border me-2">
                                                    <img
                                                        width="100px"
                                                        height="100px"
                                                        src={newsImage}
                                                        alt="img1"
                                                    />
                                                </div>
                                            </div>
                                        ) : (
                                            ''
                                        )}
                                    </Col> */}

                  <Col md={12}>
                    <Form.Group className="mb-3" controlId="formBasicName">
                      <Form.Label>
                        Tagging:
                        <sub className="ms-1">
                          <i className="fas fa-info-circle"></i> (Enter text tag e.g Computers,
                          Press Esc key for done tagging.)
                        </sub>
                      </Form.Label>
                      <AsyncPaginate
                        className="w100  p16 mr_normal_padding_l mr_medium_padding_r"
                        loadOptions={loadOptions}
                        id={Math.random()}
                        getOptionValue={(option) => {
                          return option.name;
                        }}
                        getOptionLabel={(option) => {
                          return option.name;
                        }}
                        value={value}
                        onChange={setValue}
                        additional={{
                          page: 1
                        }}
                        debounceTimeout={500}
                        isSearchable={true}
                        isMulti
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <div className="form-btns d-flex align-items-center mt-3">
                  {!id ? (
                    <Button
                      className="main-btn-grn"
                      onClick={(e) => {
                        handleSave(e);
                      }}>
                      Save
                    </Button>
                  ) : (
                    <Button className="main-btn-grn" onClick={updateHandler}>
                      Update
                    </Button>
                  )}
                  <strong>OR</strong>
                  <Button
                    className="main-btn-red"
                    onClick={() => {
                      navigate(-1);
                    }}>
                    Cancel
                  </Button>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}
