import { useState, useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import HeaderLogin from '../../commoncomponents/HeaderLogin';
import TopTabbings from './TopTabbings';
import WhatsNew from '../../commoncomponents/newsfeeds/WhatsNew';
import Upcoming from '../../commoncomponents/newsfeeds/Upcoming';
import Accordion from 'react-bootstrap/Accordion';
import Select from 'react-select';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { getJobFair } from '../../../services/SocialServices';
import useDocumentTitle from '../../../globals/useDocumentTitle';
import moment from 'moment/moment';
import { useNavigate } from 'react-router-dom';
import AsyncSelect from 'react-select/async';
import { getYearDropDown, truncate } from '../../../globals/helper';
import { citiesDropDown, companyDropdown } from '../../../services/UserServices';
import { getJobIndustryDropDown } from '../../../services/AuthServices';
import view from '../../../assets/images/view.png';
import sorting from '../../../assets/images/shorting.png';
import { ZERO } from '../../../globals/constants';
import defaultJobFair from "../../../assets/images/Jobfair.png"

function JobFair() {
  useDocumentTitle('Browse Jobs | AlmaBay');
  const colourStyles = {
    option: (styles, { isFocused }) => {
      return {
        ...styles,
        backgroundColor: isFocused ? '#eaeaea' : null,
        color: '#212529'
      };
    }
  };

  const [jobFairData, setJobFairData] = useState([]);
  const [state, setState] = useState('');
  const [mainIndustry, setMainIndustry] = useState([]);
  const industryIds = mainIndustry?.map(({ id }) => id);
  const [locationSearch, setLocationSearch] = useState('');
  const [yearOptions, setYearOptions] = useState([]);
  const [yearSearch, setYearSearch] = useState('');
  const [currentPage, setCurrentPage] = useState(0);
  const [year, setYear] = useState('');
  const [activeClassState, setIsActiveClassState] = useState('lst-view');
  const [jobLocation, setJobLocation] = useState('');
  const [companyName, setCompanyName] = useState([]);
  const [companySearch, setCompanySearch] = useState('');
  const [inputMessage, setInputMessage] = useState('Please enter atleast 2 characters.');
  const [loadLoc, setLoadLoc] = useState(false);
  const [loadInd, setLoadInd] = useState(false);
  const [loadComp, setLoadComp] = useState(false);
  const [loadMsg, setloadMsg] = useState('Searching...');
  const [viewStyle, setViewStyle] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    getDetail();

    getYearDropDown(setYearOptions);
  }, []);

  const getDetail = () => {
    getJobFair().then((res) => {
      if (res?.status === 200) {
        const data = res?.data?.detail?.getallJobFest;
        setState(res?.data?.detail);
        setJobFairData(data);
      }
    });
  };

  const handleSearch = () => {
    getJobFair(currentPage, jobLocation?.id, industryIds.toString(), '', year?.label).then(
      (res) => {
        if (res?.status === 200) {
          const data = res?.data?.detail?.getallJobFest;
          setJobFairData(data);
        }
      }
    );
  };

  const handleReset = () => {
    getJobFair().then((res) => {
      if (res?.status === 200) {
        const data = res?.data?.detail?.getallJobFest;
        setJobFairData(data);
      }
      setJobLocation('');
      setMainIndustry([]);
      setYear('');
      setCompanyName([]);
    });
  };

  const loadLocationOptions = async (inputValue) => {
    if (inputValue.length >= 2) {
      setInputMessage('searching');
      setLoadLoc(true);
      return citiesDropDown(inputValue).then((res) => {
        setLoadLoc(false);
        if (res?.status === 200) {
          let tempArr = [];
          if (res?.data?.data) {
            const data = res?.data?.data;
            data?.map((data) => {
              tempArr.push({
                id: data?.city_id,
                label: data?.city,
                value: data?.city
              });
            });
          }
          if (res?.data?.data == '') {
            setInputMessage('No matches found ');
          }
          return tempArr;
        }
      });
    }
  };
  const loadCompanyOptions = async (inputValue) => {
    if (inputValue.length >= 2) {
      // setInputMessage('searching...');
      setLoadComp(true);
      return companyDropdown(inputValue).then((res) => {
        setLoadComp(false);
        if (res?.status === 200) {
          let tempArr = [];
          if (res?.data?.results) {
            const data = res?.data?.results;
            data?.map((data) => {
              tempArr.push({
                id: data?.id,
                label: data?.name,
                value: data?.name
              });
            });
          }
          if (res?.data?.results == '') {
            setInputMessage('No matches found ');
          }

          return tempArr;
        }
      });
    }
  };

  const loadMainCompanyIndustries = (inputValue) => {
    setLoadInd(true);

    return getJobIndustryDropDown(inputValue).then((res) => {
      setLoadInd(false);
      if (res?.status === 200) {
        let tempArr = [];

        if (res.data) {
          let data = res?.data;
          data?.map((data) => {
            tempArr.push({
              id: data?.id,
              label: data?.name,
              value: data?.id
            });
          });
        }
        if (res == []) {
          setloadMsg('No matches found');
        }
        return tempArr;
      }
    });
  };

  const handleNavigate = (e, id) => {
    e.preventDefault();
    navigate(`/jobs/fair-detail/${id}`);
  };

  const inputMessageHandlerYear = (e) => {
    if (e?.length !== 2 && e?.length <= 2) {
      setInputMessage('Please enter atleast 2 characters.');
    } else if (!yearOptions?.includes(e)) {
      setInputMessage('No matches found');
    }
  };

  const inputMessageHandler = (e) => {
    if (e?.length < 2 && e?.length !== 2) {
      setInputMessage('Please enter atleast 2 characters.');
    }
  };

  const borderStyle = {
    control: (base) => ({
      ...base,
      border: '0 !important',
      // This line disable the  border
      boxShadow: '0 !important',
      '&:hover': {
        border: '0 !important'
      }
    })
  };

  return (
    <>
      <HeaderLogin />
      <div className="header-lnk-feeds">
        <div className="container">
          <TopTabbings />
        </div>
      </div>
      <section className="jobs-infomation py-5">
        <Container>
          <Row>
            <Col md={4}>
              <div className="text-center">
                <img
                  src={require('../../../assets/images/co-participating.png')}
                  className="mb-3"
                  alt="img"
                />
                <h3>{state?.companiesparticipating}</h3>
                <h6>Companies Participating</h6>
              </div>
            </Col>
            <Col md={4}>
              <div className="text-center">
                <img
                  src={require('../../../assets/images/jobs-avaliable.png')}
                  className="mb-3"
                  alt="img"
                />
                <h3>{state?.jobsavaliable}</h3>
                <h6>Jobs Available</h6>
              </div>
            </Col>
            <Col md={4}>
              <div className="text-center">
                <img
                  src={require('../../../assets/images/position-open.png')}
                  className="mb-3"
                  alt="img"
                />
                <h3>{state?.positionsopen}</h3>
                <h6>Positions Open</h6>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="bg-grey py-4">
        <Container>
          <Row>
            <Col xs={12} lg={4} xl={3}>
              <div className="fiter-card mb-4 mb-lg-0" id="filter-slidebar">
                <div className="filter-hd-card d-flex align-items-center justify-content-between">
                  <div className="filter-hd">
                    <h4>Filter Results</h4>
                  </div>
                  <div className="filter-btn d-flex">
                    <button className="dark-blu-btn" type="button" onClick={handleSearch}>
                      Search
                    </button>
                    <Link
                      className="d-block d-lg-none"
                      onClick={() => {
                        document
                          .getElementById('filter-slidebar')
                          .classList.toggle('sidebar_closed');
                      }}>
                      <i className="fas fa-bars org-color"></i>
                    </Link>
                  </div>
                </div>
                <div className="filter-select-lst d-none d-lg-block">
                  <Accordion defaultActiveKey={['0', '1', '2', '3']} alwaysOpen flush>
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>Location</Accordion.Header>
                      <Accordion.Body className="spinner-wrapper">
                        <AsyncSelect
                          cacheOptions
                          // defaultOptions
                          value={jobLocation}
                          // menuIsOpen={locationSearch !== '' ? true : false}
                          getOptionLabel={(e) => e.label}
                          getOptionValue={(e) => e.value}
                          loadOptions={loadLocationOptions}
                          components={{
                            DropdownIndicator: () => null,
                            IndicatorSeparator: () => null,
                            LoadingIndicator: () => null
                          }}
                          onInputChange={(newValue) => {
                            setLocationSearch(newValue);
                            inputMessageHandler(newValue);
                          }}
                          className="basic-single mt-1"
                          name="location"
                          onChange={setJobLocation}
                          placeholder="Search By Location"
                          noOptionsMessage={() => inputMessage}
                          styles={borderStyle}
                        />
                        {loadLoc && (
                          <div className="spinner-filter">
                            <i className="fa fa-spinner fa-pulse me-2 "></i>
                          </div>
                        )}
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                      <Accordion.Header>Industry</Accordion.Header>
                      <Accordion.Body className="spinner-wrapper">
                        <AsyncSelect
                          isMulti
                          cacheOptions
                          defaultOptions
                          value={mainIndustry}
                          getOptionLabel={(e) => e.label}
                          getOptionValue={(e) => e.value}
                          loadOptions={loadMainCompanyIndustries}
                          className="basic-single mt-1"
                          name="location"
                          onChange={setMainIndustry}
                          placeholder="Search By Industry"
                          components={{
                            DropdownIndicator: () => null,
                            IndicatorSeparator: () => null,
                            LoadingIndicator: () => null
                          }}
                          loadingMessage={() => loadMsg}
                          noOptionsMessage={() => loadMsg}
                        />
                        {loadInd && (
                          <div className="spinner-filter">
                            <i className="fa fa-spinner fa-pulse me-2 "></i>
                          </div>
                        )}
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                      <Accordion.Header>Company Name</Accordion.Header>
                      <Accordion.Body className="spinner-wrapper">
                        <AsyncSelect
                          isMulti
                          cacheOptions
                          isLoading={false}
                          // value={companyName}
                          getOptionLabel={(e) => e.label}
                          getOptionValue={(e) => e.value}
                          loadOptions={loadCompanyOptions}
                          className="basic-single mt-1"
                          name="location"
                          onChange={setCompanyName}
                          onInputChange={(newValue) => {
                            setCompanySearch(newValue);

                            inputMessageHandler(newValue);
                          }}
                          components={{
                            DropdownIndicator: () => null,
                            IndicatorSeparator: () => null,
                            LoadingIndicator: () => null
                          }}
                          styles={{
                            control: (base) => ({
                              ...base,
                              border: '0 !important',
                              // This line disable the  border
                              boxShadow: '0 !important',
                              '&:hover': {
                                border: '0 !important'
                              }
                            })
                          }}
                          // menuIsOpen={companySearch !== '' ? true : false}
                          placeholder="Search By Company"
                          noOptionsMessage={() => inputMessage}
                        // loadingMessage={() => }
                        />
                        {loadComp && (
                          <div className="spinner-filter">
                            <i className="fa fa-spinner fa-pulse me-2 "></i>
                          </div>
                        )}
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="3">
                      <Accordion.Header>Eligibility Criteria (Passout Year)</Accordion.Header>
                      <Accordion.Body className="spinner-wrapper">
                        <Select
                          isSearchable
                          isClearable
                          name="colors"
                          // menuIsOpen={yearSearch !== '' ? true : false}
                          className="basic-multi-select"
                          classNamePrefix="select"
                          options={yearSearch?.length > 2 ? yearOptions : []}
                          onInputChange={(newValue) => {
                            inputMessageHandlerYear(newValue);
                            setYearSearch(newValue);
                          }}
                          components={{
                            DropdownIndicator: () => null,
                            IndicatorSeparator: () => null,
                            LoadingIndicator: () => null
                          }}
                          styles={colourStyles}
                          placeholder="Search By Graduation Year"
                          value={year}
                          onChange={setYear}
                          noOptionsMessage={() => inputMessage}
                        />

                        {yearSearch.length > 0 && (
                          <div className="spinner-filter">
                            <i className="fa fa-spinner fa-pulse me-2 "></i>
                          </div>
                        )}
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                  <div className="sider-bar-filter p-3 text-end">
                    <button className="accnt-btn" type="button" onClick={handleSearch}>
                      Search
                    </button>
                    <button className="dark-blu-btn ms-2" type="button" onClick={handleReset}>
                      Reset
                    </button>
                  </div>
                </div>
              </div>
            </Col>

            <Col xs={12} lg={9} xl={6} className="mb-4">
              <div className="events_listing p-3">
                <div className="border-bottom pb-2 d-flex align-items-center justify-content-between">
                  <span
                    style={{
                      flex: 8,
                      fontSize: '18px',
                      margin: 0,
                      fontWeight: 'bold',
                      padding: '0 20px 10px'
                    }}>
                    Upcoming Jobs Fair Listing
                  </span>

                  {jobFairData?.length !== ZERO ? (
                    <Col md={2} xs={2}>
                      <ul className="evnet-top-btn d-flex justify-content-end">
                        <li className="me-3">
                          <Link
                            title="Grid View"
                            to="#"
                            className={activeClassState == 'lst-view' ? 'active' : ''}
                            onClick={(e) => {
                              e?.preventDefault();
                              let element = document.getElementById('lst-id');
                              element.classList.remove('list-blk-card');
                              setIsActiveClassState('lst-view');
                            }}>
                            <i className="fas fa-th-large"></i>
                          </Link>
                        </li>
                        <li className="">
                          <Link
                            title="List View"
                            className={activeClassState == 'list-blk-card' ? 'active' : ''}
                            onClick={(e) => {
                              e?.preventDefault();
                              let element = document.getElementById('lst-id');
                              element.classList.add('list-blk-card');
                              setIsActiveClassState('list-blk-card');
                            }}>
                            <i className="fas fa-list"></i>
                          </Link>
                        </li>
                      </ul>
                    </Col>
                  ) : (
                    ''
                  )}
                </div>
                {jobFairData?.length !== ZERO && (
                  <div className="job-fair-list mt-3">
                    <div className="jobfair-inr-card">
                      <Row className="lst-view" id="lst-id">
                        {jobFairData &&
                          jobFairData?.map((data, index) => {
                            return (
                              <Col lg={4} md={6} sm={6} xs={6} key={index}>
                                <div className="job-fair-card">
                                  <div className="job-fair-img">
                                    <Link to="#" onClick={(e) => handleNavigate(e, data?.id)}>
                                      <img
                                        src={
                                          data?.all_banner_image?.length > 0
                                            ? process.env.REACT_APP_API_URL +
                                            'common/uploads/job_fest_banners/' +
                                            data?.all_banner_image[0]
                                            : defaultJobFair
                                        }
                                        alt="JobFair"
                                      />
                                    </Link>
                                    <div className="job-fair-loc">
                                      {['top'].map((placement) => (
                                        <OverlayTrigger
                                          key={placement}
                                          placement={placement}
                                          overlay={
                                            <Tooltip id={`tooltip-${placement}`}>
                                              {moment(data?.start_date).format('Do MMM YY')} -{' '}
                                              {moment(data?.end_date).format('Do MMM YY')}
                                            </Tooltip>
                                          }>
                                          <span className="cale-icn">
                                            <i className="fas fa-calendar-alt"></i>
                                          </span>
                                        </OverlayTrigger>
                                      ))}
                                      {['top'].map((placement) => (
                                        <OverlayTrigger
                                          key={placement}
                                          placement={placement}
                                          overlay={
                                            <Tooltip id={`tooltip-${placement}`}>
                                              {data?.loc}
                                            </Tooltip>
                                          }>
                                          <span className="loc-icn">
                                            <i className="fas fa-map-marker-alt"></i>
                                          </span>
                                        </OverlayTrigger>
                                      ))}
                                    </div>
                                  </div>
                                  <div className="job-fair-dis">
                                    <h4>{data?.title}</h4>
                                    {['bottom'].map((placement) => (
                                      <OverlayTrigger
                                        key={placement}
                                        placement={placement}
                                        overlay={
                                          <Tooltip id={`tooltip-${placement}`}>
                                            {data?.cats}
                                          </Tooltip>
                                        }>
                                        <Link to="#">{truncate(data?.cats, 12)}... </Link>
                                      </OverlayTrigger>
                                    ))}
                                    <div className="event-card">
                                      <div className="event-page-dis text-start">
                                        <h4
                                          // onClick={(e) => handleNavigate(e, item?.id)}
                                          title={data?.name}>
                                          {data?.name}
                                        </h4>
                                        {/* <h6>{data?.category_name}</h6> */}
                                        <h6 title={data.city_name}>{data.city_name}</h6>

                                        <div className="d-flex justify-content-between">
                                          <p>
                                            <i className="fas fa-calendar-alt me-1"></i>
                                            {moment(data?.start_date).format('Do MMMM YYYY')} -
                                            {moment(data?.end_date).format('Do MMMM YYYY')}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                    <button
                                      className="wht-btn-grey"
                                      type="button"
                                      onClick={(e) => handleNavigate(e, data?.id)}>
                                      VIEW MORE
                                    </button>
                                  </div>
                                </div>
                              </Col>
                            );
                          })}
                      </Row>
                    </div>
                  </div>
                )}

                {jobFairData?.length === 0 && (
                  <div className="p-5 text-center">
                    <img
                      src={require('../../../assets/images/small-error.png')}
                      width="105px"
                      alt="img"
                    />
                    <p>No more Upcoming Jobs found as per your search criteria.</p>
                  </div>
                )}
              </div>
            </Col>

            <Col xl={{ span: 3, offset: 0 }} lg={{ span: 9, offset: 3 }}>
              <div>
                <WhatsNew />
              </div>
              <div>
                <Upcoming />
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}

export default JobFair;
