import { Link } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import HeaderInner from '../../commoncomponents/HeaderInner';
import FooterMain from '../../commoncomponents/FooterMain';
import { Container } from 'react-bootstrap';
import useDocumentTitle from '../../../globals/useDocumentTitle';

function SiteMap() {
  useDocumentTitle('Sitemap | AlmaBay');
  return (
    <>
      <HeaderInner />
      <section className="site-map py-5">
        <Container>
          <div className="page-hd mb-5">
            <h1 className="fw-bold">Sitemap</h1>
          </div>
          <div className="info-hd mb-5">
            <div className="site-lnk-title mb-4">
              <h4>AlmaBay Info</h4>
            </div>
            <Row>
              <Col md={4} sm={4}>
                <div className="site-map-lnks">
                  <h4 className="site-links-hd mb-3">About AlmaBay</h4>
                  <ul>
                    <li>
                      <Link to="/about-us">About Company</Link>
                    </li>
                    <li>
                      <Link to="/what-is-almabay">What is AlmaBay</Link>
                    </li>
                    <li>
                      <Link to="/how-almabay-can-help-you">How AlmaBay can help you?</Link>
                    </li>
                    <li>
                      <Link to="/Contactus">Contact Us</Link>
                    </li>
                  </ul>
                </div>
              </Col>
              <Col md={4} sm={4}>
                <div className="site-map-lnks">
                  <h4 className="site-links-hd mb-3">Help & Support</h4>
                  <ul>
                    <li>
                      <Link to="/faq">FAQ's</Link>
                    </li>
                    <li>
                      <Link to="/recover/password">Recover Password</Link>
                    </li>
                    <li>
                      <Link to="/demovideo">Demonstration Video</Link>
                    </li>
                    <li>
                      <Link to="/advancesearch/directory/A">Find Your Institute</Link>
                    </li>
                  </ul>
                </div>
              </Col>
              <Col md={4} sm={4}>
                <div className="site-map-lnks">
                  <h4 className="site-links-hd mb-3">Other</h4>
                  <ul>
                    <li>
                      <Link to="/terms_of_use">Terms of Use</Link>
                    </li>
                    <li>
                      <Link to="/privacy_policy">Privacy Policy</Link>
                    </li>
                    <li>
                      <Link to="/login">Login</Link>
                    </li>
                  </ul>
                </div>
              </Col>
            </Row>
          </div>
          <div className="info-hd mb-5">
            <div className="site-lnk-title mb-4">
              <h4>Services</h4>
            </div>
            <Row>
              <Col md={4} sm={4}>
                <div className="site-map-lnks">
                  <h4 className="site-links-hd mb-3">Social Services</h4>
                  <ul>
                    <li>
                      <Link to="/login">Find Alumni</Link>
                    </li>
                    <li>
                      <Link to="/login" state={`/user/alumnimap`}>
                        Browse Alumni on Map
                      </Link>
                    </li>
                  </ul>
                </div>
              </Col>
              <Col md={4} sm={4}>
                <div className="site-map-lnks">
                  <h4 className="site-links-hd mb-3">Professional Services</h4>
                  <ul>
                    <li>
                      <Link to="/jobs/search">Browse Jobs</Link>
                    </li>
                    <li>
                      <Link to="/home/recruitments">Recruitment Advocacy Program</Link>
                    </li>
                    <li>
                      <Link to="/recruiter-solutions">Recruitment Solutions</Link>
                    </li>
                    <li>
                      <Link to="/user/jobsmap">Browse Jobs on Map</Link>
                    </li>
                  </ul>
                </div>
              </Col>
              <Col md={4} sm={4}>
                <div className="site-map-lnks">
                  <h4 className="site-links-hd mb-3">Alma Store</h4>
                  <ul>
                    <li>
                      <Link to="/shop">Browse Memorabilia</Link>
                    </li>
                    <li>
                      <Link to="/delivery-information">Delivery Information</Link>
                    </li>
                    <li>
                      <Link to="/return-policy">Return Policy</Link>
                    </li>
                  </ul>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </section>
      <FooterMain className={'colour-bg'} />
    </>
  );
}

export default SiteMap;
