const PageNotFound = () => {
  return (
    <div style={{ height: '100vh' }} className="d-flex align-items-center justify-content-center">
      <div className="d-flex flex-column align-items-center">
        {/* <h1>404 Error</h1> */}
        <h1>Page Not Found</h1>
        <button
          onClick={() => {
            window.location.href = '/';
          }}
          style={{
            border: 'none',
            padding: '10px 20px',
            background: '#f7ad1c',
            color: 'white',
            marginTop: 20
          }}>
          Go to home
        </button>
      </div>
    </div>
  );
};

export default PageNotFound;
