import { useEffect, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import showNotification from '../../../services/NotificationService';
import * as APISERVICES from '../../../services/UserServices';
import { NotificationSimmer } from '../skelton/allskelton';

function NotificationForm() {
  const [socialSetting, setSocialSetting] = useState([]);
  const [professionalSetting, setProfessionalSetting] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getNotificationDetail();
  }, []);

  const getNotificationDetail = () => {
    setLoading(true);
    APISERVICES.getUserNotification().then((res) => {
      setLoading(false);
      if (res?.status === 200) {
        setSocialSetting(res?.data?.socialSettings);
        setProfessionalSetting(res?.data?.professionalSettings);
      }
    });
  };

  const checkHandler = (id, action) => {
    let newCheck = action == '0' ? '1' : '0';
    APISERVICES.updateUserNotification({ id, action: newCheck }).then((res) => {
      if (res?.status === 200) {
        // showNotification('success', res?.data?.message);
        // getNotificationDetail();
        return
      }
    });
  };

  return (
    <>
      <div className="detail_form_div">
        <p className="social-hd px-4 py-4">
          SELECT FOR WHAT ACTIVITY DO YOU WANT TO RECEIVE EMAILS
        </p>
        <form className="contact-details" id="">
          <h2>Social</h2>
          <div className="work-lst-sec">
            {loading ? (
              <>
                <NotificationSimmer />
                <NotificationSimmer />
                <NotificationSimmer />
                <NotificationSimmer />
                <NotificationSimmer />
              </>
            ) : (
              <>
                {socialSetting &&
                  socialSetting?.map((data, index) => (
                    <div className="work-lst-card px-2 py-3" key={index}>
                      <Row className="m-2 align-items-center">
                        <Col lg={10} xs={10}>
                          <div className="work-lft noti-lft">
                            <h4>{data?.type}</h4>
                          </div>
                        </Col>
                        <Col lg={2} xs={2}>
                          <div className="work-icn social-chk">
                            <Form.Check
                              defaultChecked={data?.action}
                              onChange={() => {
                                checkHandler(data?.id, data?.action);
                              }}
                            />
                          </div>
                        </Col>
                      </Row>
                    </div>
                  ))}

                <form className="contact-details" id="">
                  <h2>Professional</h2>
                  <div className="work-lst-sec">
                    {professionalSetting &&
                      professionalSetting?.map((data, index) => (
                        <div className="work-lst-card px-2 py-3" key={index}>
                          <Row className="m-2 align-items-center">
                            <Col lg={10} xs={10}>
                              <div className="work-lft noti-lft">
                                <h4>{data?.type}</h4>
                              </div>
                            </Col>
                            <Col lg={2} xs={2}>
                              <div className="work-icn social-chk">
                                <Form.Check
                                  defaultChecked={!data?.action}
                                  onChange={() => {
                                    checkHandler(data?.id, data?.action);
                                  }}
                                />
                              </div>
                            </Col>
                          </Row>
                        </div>
                      ))}
                  </div>
                </form>
              </>
            )}
          </div>
        </form>
      </div>
    </>
  );
}

export default NotificationForm;
