/**
@copyright : ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
@author    : Shiv Charan Panjeta 
All Rights Reserved.
Proprietary and confidential :  All information contained here in is, and remains
the property of ToXSL Technologies Pvt. Ltd. and it's partners.
Unauthorized copying of this file, via any medium is strictly prohibited.
* 
*/

import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import AdminRoutes from './all-routes/admin-routes/AdminRoutes';
import CmsPublicRoutes from './all-routes/cms-public-routes/CmsPublicRoutes';
import InstituteRoutes from './all-routes/institutes-routes/InstitutesRoutes';
import StoreRoutes from './all-routes/store-routes/StoreRoutes';
import JobsRoutes from './all-routes/user-routes/JobsRoutes';
import MentorsRoutes from './all-routes/user-routes/MentorsRoutes';
import UserRoutes from './all-routes/user-routes/userRoutes';
import HelperClass from './components/commoncomponents/common/HelperClass';
import AdminLoginPage from './components/webpages/auth-pages/AdminLoginPage';
import LoginPage from './components/webpages/auth-pages/LoginPage';
import ContactUs from './components/webpages/cms-public-pages/ContactUs';
import DeliveryInformation from './components/webpages/cms-public-pages/DeliveryInformation';
import FaqPage from './components/webpages/cms-public-pages/FaqPage';
import InstituteDirectory from './components/webpages/cms-public-pages/InstituteDirectory';
import InviteFriends from './components/webpages/cms-public-pages/InviteFriends';
import PrivacyPolicy from './components/webpages/cms-public-pages/PrivacyPolicy';
import Recruiters from './components/webpages/cms-public-pages/Recruiters';
import RecuriterSolutions from './components/webpages/cms-public-pages/RecuriterSolutions';
import ReturnPolicy from './components/webpages/cms-public-pages/ReturnPolicy';
import TermsOfUsePage from './components/webpages/cms-public-pages/TermsOfUse';

const AppRoutes = () => {
  const location = useLocation();
  const [isSubDomain, setIsSubdomain] = useState(false);
  const URL = window.location.host;
  let HelperClassObj = new HelperClass();

  useEffect(() => {
    window.scrollTo(0, 0);
    setIsSubdomain(HelperClassObj.checkDomain());
  }, [location, URL]);

  const isloggedIn = useSelector((state) => state?.user?.isloggedIn);
  const isAdmin = useSelector((state) => state?.user?.details?.is_admin);

  return (
    <>
      <Routes>
        {isloggedIn && (
          <>
            {isAdmin ? (
              <>
                <Route path="/admin/*" element={<AdminRoutes />} />
                <Route path="/" element={<AdminRoutes />} />
              </>
            ) : (
              <Route path="/admin/*" element={<Navigate to={'/jobs'} />} />
            )}
            <Route path="/" element={<Navigate to={'/jobs'} />} />
            <Route path="/auth/*" element={<Navigate to="/user/" replace />} />
            <Route path="/user/*" element={<UserRoutes />} />
            <Route path="/jobs/*" element={<JobsRoutes />} />
            <Route path="/mentors/*" element={<MentorsRoutes />} />
            <Route path="/shop/*" element={<StoreRoutes />} />
            <Route path="/invite" element={<InviteFriends />} />
            <Route path="/pages/pinvite/:id" element={<InviteFriends />} />
            <Route path="/privacy_policy" element={<PrivacyPolicy />} />
            <Route path="/terms_of_use" element={<TermsOfUsePage />} />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="/chapters/ginvite/:id" element={<InviteFriends />} />
            <Route path="/home/recruitments" element={<Recruiters />} />
            <Route path="/recruiter-solutions" element={<RecuriterSolutions />} />
            <Route path="/delivery-information" element={<DeliveryInformation />} />
            <Route path="/return-policy" element={<ReturnPolicy />} />
            <Route path="/faq" element={<FaqPage />} />
            <Route path="/advancesearch/directory/:sort" element={<InstituteDirectory />} />
          </>
        )}
        {!isloggedIn && !isSubDomain && (
          <>
            <Route path="/login" element={<LoginPage />} />
            <Route path="/admin" element={<AdminLoginPage />} />
            <Route path="/admin/*" element={<Navigate to={`/admin`} />} />
            {/* <Route path="/institute-login" element={<Navigate to={'/login'} />} /> */}

            <Route path="/*" element={<CmsPublicRoutes />} />
          </>
        )}
        {!isloggedIn && isSubDomain && <Route path="/*" element={<InstituteRoutes />} />}
      </Routes>
    </>
  );
};

export default AppRoutes;
