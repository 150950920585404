import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { getUpcommingEvent } from '../../../services/NewsServices';
import { truncate } from '../../../globals/helper';
import moment from 'moment/moment';
import { useSelector } from 'react-redux';
import NoDataFound from '../NoDataFound';

function Upcoming() {
  const [newList, setNewList] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getUpcomingEvents();
  }, []);

  const getUpcomingEvents = () => {
    setLoading(true);
    getUpcommingEvent()
      .then((res) => {
        if (res?.status === 200) {
          setNewList(res?.data?.data ?? []);
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const isloggedIn = useSelector((state) => state?.user?.isloggedIn);
  return (
    <>
      {/* new */}
      <div className="upcome-card-detail">
        <div className="whats-new-sec d-flex align-items-top justify-content-between">
          <h4>Upcoming Events</h4>
          {!isloggedIn ? (
            <Link className="more-btn" to="/events">
              <i className="far fa-calendar-alt me-1 "></i>
              More
            </Link>
          ) : (
            <Link className="more-btn" to="/user/events_calendar" target="_blank">
              <i className="far fa-calendar-alt me-1 "></i>
              More
            </Link>
          )}
        </div>
        <ul className="upcome-card-lst">
          {loading &&
            Array.from(Array(3), (index) => {
              return <Shimmer key={index} />;
            })}
          {newList?.length !== 0 && !loading ? (
            newList?.slice(0, 3)?.map((data, index) => (
              <li key={index}>
                <div className="upcome d-flex align-items-center">
                  <div className="upcom-date">
                    <h4>{moment(new Date(data?.from_event_date)).format('MMM')}</h4>
                    <p> {moment(new Date(data?.from_event_date)).format('DD')}</p>
                  </div>
                  <div className="upcome-card-dis">
                    <h4>{data?.name}</h4>
                    <p>{truncate(data?.short_decp, 30)}</p>
                  </div>
                </div>
              </li>
            ))
          ) : (
            <>
              {!loading && newList.length == 0 && (
                <NoDataFound text="No more upcoming events for you at the moment." />
              )}
            </>
          )}
        </ul>
      </div>
    </>
  );
}

export default Upcoming;

const Shimmer = () => {
  return (
    <li>
      <div className="upcming-event loading-skeleton">
        <div className="upcome d-flex align-items-center">
          <div className="upcom-date"></div>
          <div className="upcome-card-dis">
            <h4>Loading</h4>
            <h4>Loading</h4>
            <h4>Loading</h4>
          </div>
        </div>
      </div>
    </li>
  );
};
