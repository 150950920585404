/**
@copyright  :   ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
@author     :   Shiv Charan Panjeta 
 
All Rights Reserved.
Proprietary and confidential :  All information contained here in is, and remains
the property of ToXSL Technologies Pvt. Ltd. and it's partners.
Unauthorized copying of this file, via any medium is strictly prohibited.
*/
import { useState } from 'react';
import { Button } from 'react-bootstrap';
import Loader from '../../../components/commoncomponents/loader/loader-large';
import showNotification from '../../../services/NotificationService';
import { updateEmailTemplateDetail } from '../../../services/TemplateServices';
export default function SecondEmailTempLayout({ nextStep, activeTab, id, idAdd }) {
  const emailLayout = 'email';
  const idToken = id ? id : idAdd;
  const [loading, setLoading] = useState(false);

  const updateFunc = () => {
    setLoading(true);
    let formData = new FormData();
    formData.append('id', idToken);
    formData.append('email_layout', emailLayout);

    updateEmailTemplateDetail(formData).then((res) => {
      if (res?.status == 200) {
        activeTab('third');
        nextStep();
        setLoading(false);
        showNotification('success', res?.data?.message);
      }
    });
  };

  return (
    <div className="template-list">
      <div className="template-select-card">
        <ul>
          {/* <li className="active">
            <div className="select-card">
              <div className="select-img">
                <img src={require('../../../assets/images/temp_blank.png')} alt="chatuser" />
              </div>
              <div className="select-card-dis">
                <h4>Blank Layout</h4>
              </div>
            </div>
          </li> */}
          <li className="active">
            <div className="select-card">
              <div className="select-img">
                <img src={require(`../../../assets/images/temp_blank.png`)} alt="chatuser" />
              </div>
              <div className="select-card-dis">
                <h4>Email Layout</h4>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div className="form-btns d-flex align-items-center mt-4">
        {id ? (
          <button type="button" onClick={updateFunc} className="green_button_admin">
            Update
          </button>
        ) : (
          <button type="button" onClick={updateFunc} className="green_button_admin">
            Submit
          </button>
        )}
      </div>
      {loading ? <Loader /> : ''}
    </div>
  );
}
