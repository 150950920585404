import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { getPagePeopleList } from '../../../services/SocialServices';

function LikesCard(pageData) {
  const timeline_id = useParams()?.encrypted_id;
  const [peopleList, setPeopleList] = useState();

  useEffect(() => {
    getPeopleList('members');
  }, [timeline_id]);

  const getPeopleList = (members, q = '') => {
    let body = {
      type: members
    };

    getPagePeopleList(body, timeline_id, q).then((resp) => {
      if (resp?.status === 200) {
        setPeopleList(resp?.data?.data);
      }
    });
  };
  return (
    <>
      <div className="whats-new-sec bg-white p-3 mb-3">
        <div className="d-flex align-items-top justify-content-between flex-wrap">
          <h4>Page Likes</h4>

          <Link className="more-btn" to="#">
            {peopleList?.count_members ? peopleList?.count_members + ' Likes ' : ''}
          </Link>
        </div>
        <div className="membr-inr-card">
          {peopleList?.peoples !== 0 ? (
            peopleList?.peoples?.map((data, index) => {
              return (
                <ul className="member-lst-card" key={index}>
                  <li>
                    <Link to={`/user/timeline/${data?.encrypted_id}`}>
                      <img
                        src={
                          data?.avatar?.complete_url
                            ? data?.avatar?.complete_url
                            : require('../../../assets/images/default-male-avatar.png')
                        }
                        alt="img"
                      />
                    </Link>
                  </li>
                </ul>
              );
            })
          ) : (
            <></>
          )}
        </div>

        <div className="d-flex align-items-top justify-content-between flex-wrap">
          <h4>Page Category</h4>
        </div>
        <div className="membr-inr-card">
          <p>{pageData?.pageData?.category_name}</p>
        </div>
      </div>
    </>
  );
}

export default LikesCard;
