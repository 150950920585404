/**
  @copyright : ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
  @author    : Shiv Charan Panjeta 
  All Rights Reserved.
  Proprietary and confidential :  All information contained herein is, and remains
  the property of ToXSL Technologies Pvt. Ltd. and its partners.
  Unauthorized copying of this file, via any medium is strictly prohibited.
*/

import { MarkerWithLabel } from '@googlemaps/markerwithlabel';
import GoogleMapReact from 'google-map-react';
import { useEffect, useRef, useState } from 'react';
import {
  Button,
  Col,
  Form,
  Image,
  Nav,
  OverlayTrigger,
  Popover,
  Row,
  Tab,
  Tabs
} from 'react-bootstrap';
import Autocomplete from 'react-google-autocomplete';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import {
  createMapSelectDropDownList,
  getYearDropDown,
  resumeExtension
} from '../../../globals/helper';
import useDocumentTitle from '../../../globals/useDocumentTitle';
import {
  getAllAlumniList,
  getAllJobList,
  getAppendableJobList,
  getUserJobShortList,
  resetAllJobList,
  toggleJobShortList
} from '../../../redux/action';
import { getInstituteDrop } from '../../../services/AuthServices';
import * as APISERVICE from '../../../services/JobServices';
import showNotification from '../../../services/NotificationService';
import * as JOBAPISERVICES from '../../../services/UserServices';
import {
  loadCompanyListOption,
  loadJObLocationOption,
  loadMainCompanyIndustries
} from '../../../super-admin/AdminHelper';
import { mapStyle } from '../../../super-admin/map/mapStyle';
import { getToken } from '../../../utils/session';
import HeaderInner from '../../commoncomponents/HeaderInner';
import HeaderLogin from '../../commoncomponents/HeaderLogin';
import ApplyForm from '../common-professional-comp/ApplyForm';
import RecomendModal from '../common-professional-comp/RecomendModal';
import useInfiniteScroller from './useInfiniteScroller';

// global variables

let markers = [];
let map = null;
let circle = null;
let distanceWidget;
let radiusWidget;
function JobsMap({ type }) {
  if (type === 'Jobsmap' || type == undefined) {
    useDocumentTitle('AlmaBay');
  } else {
    useDocumentTitle('Alumni Map | AlmaBay');
  }
  const popupRef = useRef(null);
  const isloggedIn = useSelector((state) => state?.user?.isloggedIn);
  const [isRadialSearchActive, setIsRadialSearchActive] = useState(false);
  const [addressCoordinate, setAddressCoordinate] = useState({
    lat: null,
    lng: null
  });

  const [alumniList, setAlumniList] = useState();
  const [alumniCard, setAlumniCard] = useState(true);
  const [isRadias, setIsRadius] = useState(true);
  const [ID, setID] = useState('');
  const allIds = alumniList?.alumnisByInstitute?.find((elem) => {
    return elem?.institute_id === ID;
  });
  const [unit, setUnit] = useState('km');
  const [radius, setRadius] = useState(20); // default seems in km according to new code
  const [currentPosition, setCurrentPosition] = useState({
    lat: null,
    lng: null
  });
  const [isPosition, setIsPosition] = useState(false);
  const [mapState, setMapState] = useState(null);
  const [mapsState, setMapsState] = useState(null);
  let dispatch = useDispatch();
  const cluster = useSelector((state) => state?.cluster);
  const { jobList, jobListCount, shortList, shortListCount, alumnisList, alumnisListCount } =
    cluster;

  const [activeSection, setActiveSection] = useState([]);

  const [alumniName, setAlumniName] = useState('');
  const [jobLocation, setJobLocation] = useState([]);
  const [locationSearch, setLocationSearch] = useState(null);
  const [instituteName, setInstituteName] = useState([]);
  const [instituteSearch, setInstituteSearch] = useState(null);
  const [company, setCompany] = useState([]);
  const [companySearch, setCompanySearch] = useState(null);
  const [yearOptions, setYearOptions] = useState([]);
  const [gradYear, setGradYear] = useState([]);
  const [yearSearch, setYearSearch] = useState('');
  const [skillsOption, setSkillsOption] = useState([]);
  const [companyOption, setCompanyOption] = useState([]);
  const [industryOption, setIndustryOption] = useState([]);
  const [industryName, setIndustryName] = useState([]);
  const [industrySearch, setIndustrySearch] = useState(null);
  const [businessSkill, setBusinessSkill] = useState([]);
  const [interPersonalSkill, setInterPersonalSkill] = useState([]);
  const [businessSearch, setBusinessSearch] = useState(null);
  const [personalSearch, setPersonalSearch] = useState(null);
  const [selectedIndustryOption, setSelectedIndustryOption] = useState([]);
  const [minExp, setMinExp] = useState('');
  const [minCtc, setMinCtc] = useState('');
  const [maxCtc, setMaxCtc] = useState('');
  const [maxExp, setMaxExp] = useState('');
  const [selectedSkillsOption, setSelectedSkillsOption] = useState([]);
  const [skillsFilterId, setSkillsFilterId] = useState('');
  const [selectedCompanyOption, setSelectedCompanyOption] = useState([]);
  const [jobDetails, setJobDetails] = useState(null);
  const [jobDetailsVisible, setJobDetailsVisible] = useState(false);
  const [tooltip, setTooltip] = useState(false);
  const [techSkill, setTechSkill] = useState([]);
  const [techSearch, setTechSearch] = useState('');
  const [jobData, setJobData] = useState([]);
  const [isChecked, setIsChecked] = useState(false);
  const [show2, setShow2] = useState(false);
  const [show3, setShow3] = useState(false);
  const [applyForm, setApplyForm] = useState({
    to: ' ',
    subject: ' ',
    message: ' ',
    resume: ' '
  });
  const [savedResume, setSavedResume] = useState('');
  const [resumeSelect, setResumeSelect] = useState(false);
  const [fileSizeValid, setFileSizeValid] = useState(false);
  const [jobId, setJobId] = useState('');
  const [applied, setApplied] = useState(false);

  const handleShow2 = () => setShow2(true);
  const handleClose2 = () => setShow2(false);
  const handleShow3 = () => setShow3(true);
  const handleClose3 = () => setShow3(false);

  useEffect(() => {
    getUserDetail();
  }, []);

  const onRecommendClick = (e, id) => {
    e.preventDefault();
    setJobId(id);
    handleShow3();
  };

  const getUserDetail = () => {
    JOBAPISERVICES.userBasicInfo().then((res) => {
      if (res?.status === 200) {
        setSavedResume(res?.data?.resume);
        setApplyForm({ ...applyForm, ['resume']: res?.data?.resume });
      }
    });
  };

  const onChangeHandle = (e, rid = false) => {
    if (rid) {
      setApplyForm({ ...applyForm, ['resume']: savedResume });
    } else {
      const { name, value, type } = e.target;
      if (type == 'file') {
        let file = e.target.files[0];
        let fileSize = file.size / 1024;

        if (fileSize > 500) {
          setResumeSelect(false);
          setFileSizeValid(true);
          alert('Resume file size should be less than 500KB');
        } else {
          setFileSizeValid(false);
          if (resumeExtension.includes(e.target.files[0].type)) {
            setResumeSelect(true);
            setApplyForm({ ...applyForm, [name]: file });
          } else {
            setResumeSelect(false);
            alert(
              'Resume file format is not valid, kindly attach a valid file e.g doc,docx,rtf,odt,pdf'
            );
          }
        }
      } else {
        setApplyForm({ ...applyForm, [name]: value });
      }
    }
  };

  const submitHandler = () => {
    let formData = new FormData();
    for (let i in applyForm) {
      formData.append(`${i}`, applyForm[i]);
    }
    APISERVICE.applyForJob(jobId, formData).then((res) => {
      if (res?.status === 200) {
        setApplied(true);
        handleClose2();
        showNotification('success', 'Job Application has been sent Successfully.');
        displayJobDetail(jobId);
        // getJobList();
      }
    });
  };

  const onApplyStateHandler = (data) => {
    setJobId(data?.id);
    // setJobTitle(data?.job_title);
    // setJobDetailsVisible(false);
    setApplyForm({
      to: data?.user
        ? data?.user?.first_name + ' ' + data?.user?.last_name
        : data?.job_company_meta,
      subject: `Alma - Job application for ${data?.job_title}`,
      message: '',
      resume: savedResume
    });
    handleShow2();
  };
  const [mapOptions, setMapOptions] = useState({
    styles: mapStyle,
    minZoom: 2,
    // minZoomOverride: true,
    maxZoom: 12,
    rotateControl: true,
    mapTypeControl: false,
    streetViewControl: false,
    fullscreenControl: true,
    fullscreenControlOptions: {
      position: window.google.maps.ControlPosition.RIGHT_TOP
    },
    zoomControl: true,
    zoomControlOptions: {
      position: window.google.maps.ControlPosition.RIGHT_TOP
    }
  });
  const handleRadialSearch = (lat, lng) => {
    if (lat && lng) {
      // calculate sizer position from center of the circle
      circle.setCenter(new window.google.maps.LatLng(lat, lng));
      map.setCenter(new window.google.maps.LatLng(lat, lng));
      // e.preventDefault();
    } else {
      getResult(map.getZoom(), lat, lng); // get results
    }
  };
  const onRadiusChange = (radd) => {
    setRadius(radd);
  };
  const PackageOptions = [
    { id: 0, value: '0 Lacs', label: '0 Lacs' },
    {
      id: 1,
      value: '1 Lacs',
      label: '1 Lacs'
    },
    { id: 2, value: '2 Lacs', label: '2 Lacs' },
    { id: 3, value: '3 Lacs', label: '3 Lacs' },
    { id: 4, value: '4 Lacs', label: '4 Lacs' }
  ];
  const PackageOptions1 = [
    {
      id: 1,
      value: '1 Lacs',
      label: '1 Lacs'
    },
    { id: 2, value: '2 Lacs', label: '2 Lacs' },
    { id: 3, value: '3 Lacs', label: '3 Lacs' },
    { id: 4, value: '4 Lacs', label: '4 Lacs' }
  ];

  const WorkOptions1 = [
    { id: 0, value: '', label: 'Select' },
    { id: 1, value: 0, label: 0 },
    { id: 2, value: 1, label: 1 },
    { id: 3, value: 2, label: 2 },
    { id: 4, value: 3, label: 3 },
    { id: 5, value: 4, label: 4 },
    { id: 6, value: 5, label: 5 },
    { id: 7, value: 6, label: 6 },
    { id: 8, value: 7, label: 7 },
    { id: 9, value: 8, label: 8 },
    { id: 10, value: 9, label: 9 },
    { id: 1, value: 10, label: 10 }
  ];

  const WorkOptions2 = [
    { id: 0, value: '', label: 'Select' },
    { id: 1, value: 1, label: 1 },
    { id: 2, value: 2, label: 2 },
    { id: 3, value: 3, label: 3 },
    { id: 4, value: 4, label: 4 },
    { id: 5, value: 5, label: 5 },
    { id: 6, value: 6, label: 6 },
    { id: 7, value: 7, label: 7 },
    { id: 8, value: 8, label: 8 },
    { id: 9, value: 9, label: 9 },
    { id: 10, value: 10, label: 10 },
    { id: 11, value: 11, label: '10+' }
  ];

  const [isClicked, setIsClicked] = useState(false);
  /**
   * useEffect HOOK
   */
  useEffect(() => {
    if (type === 'Alumnimap') {
      allAlumnisList();
      getYearDropDown(setYearOptions);
    }
    getOptionsList();
  }, []);
  useEffect(() => {
    getLocation.then((position) => {
      setIsPosition(true);
      setCurrentPosition({
        lat: JSON.parse(position.coords.latitude),
        lng: JSON.parse(position.coords.longitude)
      });
    });
  }, [radius]);

  /**
   * get alumni list
   */
  const allAlumnisList = async () => {
    try {
      const response = await JOBAPISERVICES.alumnisData();
      if (response?.status === 200) {
        setAlumniList(response?.data?.detail);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const [isZooming, setIsZooming] = useState(false);

  let getLocation = new Promise(function (myResolve, myReject) {
    // "Producing Code" (May take some time)
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        // when successful
        myResolve(position);
      });
    } else {
      myReject(); // when error
    }
  });

  /**
   * FUNCTION TO CREATE CLUSTER
   * @param {*} map
   * @param {*} maps
   * @returns
   */
  const svg = window.btoa(`
      <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="22" cy="22" r="22" fill="#2B5270"/>
      </svg>
    `);

  function checkBounds(map) {
    let latNorth = map.getBounds().getNorthEast().lat();
    let latSouth = map.getBounds().getSouthWest().lat();
    let newLat;

    if (latNorth < 85 && latSouth > -85) /* in both side -> it's ok */ return;
    else {
      if (latNorth > 85 && latSouth < -85) /* out both side -> it's ok */ return;
      else {
        if (latNorth > 85)
          newLat = map.getCenter().lat() - (latNorth - 85); /* too north, centering */
        if (latSouth < -85)
          newLat = map.getCenter().lat() - (latSouth + 85); /* too south, centering */
      }
    }
    if (newLat) {
      let newCenter = new window.google.maps.LatLng(newLat, map.getCenter().lng());
      map.setCenter(newCenter);
    }
  }

  /**
   * RETURN DISTANCE BETWEEN THE MARKER AND MOUSE POINTER
   * @param {*} pt
   * @param {*} mp
   * @returns
   */
  const distanceToMouse = (pt, mp) => {
    if (pt && mp) {
      return Math.sqrt((pt.x - mp.x) * (pt.x - mp.x) + (pt.y - mp.y) * (pt.y - mp.y));
    }
  };

  const onChangeHandler = (e, option) => {
    let arr = [];

    if (option.name === 'skills') {
      e?.map((data) => {
        arr.push(data?.value);
      });
      setSkillsFilterId(arr.join());
    }
  };

  /**
   * get institute dropdown
   * @param {*} inputValue
   * @returns
   */
  const loadInstituteOptions = async (inputValue) => {
    return getInstituteDrop(inputValue).then((res) => {
      if (res?.status === 200) {
        let tempArr = [];
        if (res?.data?.length) {
          const data = res?.data;
          data?.map((data) => {
            tempArr.push({
              id: data?.institute_id,
              label: data?.institutename,
              value: data?.institutename
            });
          });
        }
        return tempArr;
      }
    });
  };
  const renderPopover = (props) => {
    let popOverData = props?.popper?.state?.options?.alumni;

    return (
      <Popover className="group-loader-card" id={`popover-basic`} {...props}>
        {/* <Popover.Header as="h3">Popover right</Popover.Header> */}
        <Popover.Body>
          <div className="loader-tooltip">
            <div className="loader-cover">
              <img
                src={
                  popOverData?.image_url
                    ? `${process.env.REACT_APP_API_URL}${popOverData?.image_url}.${popOverData?.image_format}`
                    : require('../../../assets/images/default-male-avatar.png')
                }
                alt="img"
                className="loader-image"
              />
            </div>
            <div className="people-know-loader-dis d-flex align-items-top">
              <div className="people-knw-top d-flex align-items-center">
                <div className="people-icn me-3">
                  <img
                    src={
                      popOverData?.image_url
                        ? `${process.env.REACT_APP_API_URL}${popOverData?.image_url}.${popOverData?.image_format}`
                        : require('../../../assets/images/default-male-avatar.png')
                    }
                    alt="img"
                    className="loader-image"
                  />
                </div>
                <div className="people-hd">
                  <h3>{popOverData?.name}</h3>
                </div>
              </div>
            </div>
            <div className="people-knw-dis-popover">
              {/* <p>
                <i className="fas fa-graduation-cap me-1"></i> Studied at{' '}
                <Link to="#">SD COLLEGE Chandigarh</Link> in <Link to="#">2005</Link>
              </p> */}
              {/* <p> */}
              <div style={{ margin: '10px' }}>
                <i className="fas fa-briefcase me-1"></i>Studied at
                <span style={{ color: '#fbae38' }}> {popOverData?.institute_name}</span>
              </div>
              {/* </p> */}
              <div className="popover-inr-btn">
                {popOverData?.follow_status == null && (
                  <button
                    className="popovr-blk-btn me-3"
                    onClick={(e) => resquestHanderFriend(e, popOverData?.user_id, 'add')}>
                    Add Friend
                  </button>
                )}
                {popOverData?.follow_status == 0 && (
                  <button
                    className="popovr-blk-btn me-3"
                    onClick={(e) => resquestHanderFriend(e, popOverData?.user_id, 'requested')}>
                    Requested
                  </button>
                )}
                {popOverData?.follow_status == 1 && (
                  <button
                    className="popovr-blk-btn me-3"
                    onClick={(e) => resquestHanderFriend(e, popOverData?.user_id, 'remove')}>
                    Unfriend
                  </button>
                )}
                {popOverData?.mentor_status == '' || popOverData?.mentor_status == null ? (
                  <button
                    onClick={(e) => resquestHanderFriend(e, popOverData?.user_id, 'mentor')}
                    className="popovr-yellow-btn">
                    Follow
                  </button>
                ) : (
                  <button
                    onClick={(e) => resquestHanderFriend(e, popOverData?.user_id, 'mentor')}
                    className="popovr-yellow-btn">
                    Unfollow
                  </button>
                )}
                {/* <button className="popovr-yellow-btn">Follow Mentor</button> */}
              </div>
            </div>
          </div>
        </Popover.Body>
      </Popover>
    );
  };
  /**
   * get company dropdown
   * @param {*} inputValue
   * @returns
   */
  const loadCompanyOption = async (inputValue = '') => {
    return JOBAPISERVICES.companyDropdown(inputValue, '', '').then((res) => {
      if (res?.status === 200) {
        let tempArr = [];
        if (res?.data?.results) {
          const data = res?.data?.results;
          data?.map((data) => {
            tempArr.push({
              id: data?.id,
              label: data?.name,
              value: data?.name
            });
          });
        }
        return tempArr;
      }
    });
  };

  /**
   * bussiness skills dropdown
   * @param {*} inputValue
   * @returns
   */
  const loadBusinessOptions = async (inputValue) => {
    return JOBAPISERVICES.getBusinessSkillDrop(inputValue).then((res) => {
      if (res?.status === 200) {
        let data = res?.data?.data;
        let arr = [];
        data?.map((data) => {
          arr.push({ label: data.name, value: data.id });
        });
        return arr;
      }
    });
  };

  /**
   * get personal skills dropdown
   * @param {*} inputValue
   * @returns
   */
  const loadPersonalOptions = async (inputValue) => {
    return JOBAPISERVICES.getInterpersonalSkillDrop(inputValue).then((res) => {
      if (res?.status === 200) {
        let data = res?.data?.data;
        let arr = [];
        data?.map((data) => {
          arr.push({ label: data.name, value: data.id });
        });
        return arr;
      }
    });
  };

  const getOptionsList = () => {
    APISERVICE.getKeyWords().then((res) => {
      if (res?.status === 200) {
        const data = res?.data?.data;
        setSkillsOption(createMapSelectDropDownList(data));
      }
    });
    JOBAPISERVICES.getJobCompanyDropdown().then((res) => {
      if (res?.status === 200) {
        let data = res?.data?.data;
        setCompanyOption(createMapSelectDropDownList(data));
      }
    });

    JOBAPISERVICES.getJobIndustriesDropdown().then((res) => {
      if (res?.status === 200) {
        let data = res?.data?.data;
        setIndustryOption(createMapSelectDropDownList(data));
      }
    });
  };
  const clearMarkers = (markers) => {
    markers.forEach((marker) => {
      marker.setMap(null);
    });
    markers = [];
  };

  const createMarker = (map, result, text = '', network, networkCount) => {
    if (network) {
      let icon = {
        url: require('../../../assets/images/network-marker.png')
      };
      let labelsClass = 'networkLabels';
      let labelContent =
        '<label title="Jobs in your Network' + text + '">' + result.count + '</label>';
      let mainClass = '';
      if (result.count < 10) {
        labelsClass += ' nw10';
        mainClass = '10';
      } else if (result.count >= 10 && result.count < 100) {
        labelsClass += ' nw100';
        mainClass = '100';
      } else if (result.count >= 100 && result.count < 500) {
        labelsClass += ' lt500';
        mainClass = '500';
      } else if (result.count >= 500 && result.count < 1000) {
        labelsClass += ' nw1000';
        mainClass = '1000';
      } else if (result.count >= 1000 && result.count < 5000) {
        labelsClass += ' nw5000';
        mainClass = '5000';
      } else if (result.count >= 5000 && result.count < 10000) {
        labelsClass += ' nw10000';
        mainClass = '10000';
      }

      if (networkCount < 10) {
        labelsClass += ' marker' + mainClass + 'w10';
        icon.scaledSize = new window.google.maps.Size(20, 20);
      } else if (networkCount >= 10 && networkCount < 100) {
        labelsClass += ' marker' + mainClass + 'w100';
        icon.scaledSize = new window.google.maps.Size(20, 20);
      } else if (networkCount >= 100 && networkCount < 500) {
        icon.scaledSize = new window.google.maps.Size(20, 20);
        labelsClass += ' marker' + mainClass + 'w500';
      } else if (networkCount >= 500 && networkCount < 1000) {
        labelsClass += ' marker' + mainClass + 'w1000';
        icon.scaledSize = new window.google.maps.Size(20, 20);
      } else if (networkCount >= 1000 && networkCount < 5000) {
        labelsClass += ' marker' + mainClass + 'w5000';
        icon.scaledSize = new window.google.maps.Size(20, 20);
      } else if (networkCount >= 5000 && networkCount < 10000) {
        labelsClass += ' marker' + mainClass + 'w10000';
        icon.scaledSize = new window.google.maps.Size(20, 20);
      }
      let marker = new MarkerWithLabel({
        position: {
          lat: Number(result.latitude),
          lng: Number(result.longitude)
        },
        map: map,
        labelContent: labelContent,
        icon: icon,
        labelAnchor: { x: 7, y: 28 },
        title: result['name'],
        labelClass: labelsClass
      });
      marker.addListener('click', () => {
        // handleMarkerClick(result);
        handleNetworkMarkerClick(result);
      });
      return marker;
    } else {
      let labelsClass = 'marker_label_job_map';
      let icon = {
        url: require('../../../assets/images/big-marker.png')
      };
      if (result.count < 10) {
        icon.scaledSize = new window.google.maps.Size(40, 40);
        labelsClass += ' lt10';
      } else if (result.count >= 10 && result.count < 100) {
        icon.scaledSize = new window.google.maps.Size(45, 45);
        labelsClass += ' lt100';
      } else if (result.count >= 100 && result.count < 500) {
        icon.scaledSize = new window.google.maps.Size(55, 55);
        labelsClass += ' lt500';
      } else if (result.count >= 500 && result.count < 1000) {
        icon.scaledSize = new window.google.maps.Size(60, 60);
        labelsClass += ' lt1000';
      } else if (result.count >= 1000 && result.count < 5000) {
        icon.scaledSize = new window.google.maps.Size(68, 68);
        labelsClass += ' lt5000';
      } else if (result.count >= 5000 && result.count < 10000) {
        icon.scaledSize = new window.google.maps.Size(72, 72);
        labelsClass += ' lt10000';
      } else {
        icon.scaledSize = new window.google.maps.Size(100, 100);
        labelsClass += ' gt10000';
      }

      let marker = new MarkerWithLabel({
        position: {
          lat: Number(result.latitude),
          lng: Number(result.longitude)
        },
        map: map,
        labelContent:
          type === 'Alumnimap'
            ? '<span title="Total Alumnis in ' +
              result['name'] +
              text +
              '">' +
              result.count +
              '</span>'
            : '<span title="Total Jobs in ' +
              result['name'] +
              text +
              '">' +
              result.count +
              '</span>',
        icon: icon,
        labelAnchor: { x: 7, y: 28 },
        title: result['name'],
        labelClass: labelsClass
      });
      marker.addListener('click', () => {
        handleMarkerClick(result);
      });

      return marker;
    }
  };

  const handleSearch = async () => {
    // api call will do validation later
    // get data based on zoom level
    let zoomLevel = map.getZoom();
    let newPlaceType = 'country';
    if (zoomLevel < 5) {
      newPlaceType = 'country';
    } else if (zoomLevel > 4 && zoomLevel < 7) {
      newPlaceType = 'state';
    } else if (zoomLevel > 6) {
      newPlaceType = 'city';
    }

    // let locationId = jobLocation.id;
    // let companiesIds = selectedCompanyOption.map((item) => item.id).join(',');
    // let skillsIds = selectedSkillsOption.map((skill) => skill.id).join(',');
    // let industryIds = selectedIndustryOption.map((industry) => industry.id).join(',');
    // let resp = await JOBAPISERVICES.getUserJobsForMap(
    //   newPlaceType,
    //   locationId,
    //   companiesIds,
    //   skillsIds,
    //   industryIds,
    //   minExp,
    //   maxExp,
    //   minCtc,
    //   maxCtc
    //   //   // radiusSearch = '',
    //   //   ''
    //   //   // radius
    // );
    // let data = await getJobsData(
    //   newPlaceType,
    //   locationId,
    //   companiesIds,
    //   skillsIds,
    //   industryIds,
    //   minExp,
    //   maxExp,
    //   minCtc,
    //   maxCtc,
    //   // radiusSearch = '',
    //   ''
    //   // radius
    // );
    // if (data) {
    //   // clear markers
    //   clearMarkers(markers);
    // }

    // let joblist = data.jobs ?? [];
    // joblist.forEach((job) => {
    //   markers.push(createMarker(map, job));
    // });

    getResult(map.getZoom());
  };
  const createRadialSearch = () => {
    if (circle) {
      circle.setMap(null);
    }
    // create a circle
    distanceWidget = new DistanceWidget(map, radius, currentPosition);

    // Set the distance property value, default to 20km.
    // window.google.maps.setOptions({ minZoom: 8, zoom: 10 });
    setMapOptions({
      ...mapOptions,
      minZoom: 8,
      zoom: 10
    });
    map.setOptions({ minZoom: 8, zoom: 10 });
    distanceWidget.addListener('distance_changed', function () {
      let radd = distanceWidget.get('distance').toFixed();

      onRadiusChange(radd);
    });
    distanceWidget.addListener('position_changed', function () {
      let radius = distanceWidget.get('distance').toFixed();
    });
    setIsRadialSearchActive(true);
  };

  const validation = () => {
    let errors = {};
    if (isClicked) {
      // if (
      //   jobLocation.length == 0 &&
      //   selectedCompanyOption.length == 0 &&
      //   techSkill.length == 0 &&
      //   industryName.length == 0 &&
      //   minExp.length == 0 &&
      //   maxExp.length == 0 &&
      //   minCtc.length == 0 &&
      //   maxCtc.length == 0
      // ) {
      //   errors.message = 'Kindly select at least one filter.';
      // }
      // if (jobData.length == 0) {
      //   errors.job = 'No Jobs Found as Per Your Search Criteria.';
      // }
    }
    return errors;
  };

  const getResult = async (zoom, lat, lng) => {
    let newPlaceType = 'country';
    if (zoom < 5) {
      newPlaceType = 'country';
    } else if (zoom > 4 && zoom < 7) {
      newPlaceType = 'state';
    } else if (zoom > 6) {
      newPlaceType = 'city';
    }

    let locationId = jobLocation?.map((item) => item.id).join(',');
    let companiesIds = company && company.map((item) => item.id).join(',');
    let skillsIds = techSkill && techSkill.map((skill) => skill.value).join(',');
    let industryIds = industryName && industryName.map((industry) => industry.id).join(',');

    // lat and long of center
    let latitude = isRadialSearchActive ? radiusWidget.get('center').lat() : '';
    let longitude = isRadialSearchActive ? radiusWidget.get('center').lng() : '';

    /**
     * ALUMNI MAP VARIABLES
     */

    let resp;
    setIsClicked(true);
    if (type === 'Jobsmap' || type == undefined) {
      resp = await JOBAPISERVICES.getUserJobsForMap(
        newPlaceType,
        locationId ?? '',
        companiesIds ?? '',
        skillsIds ?? '',
        industryIds ?? '',
        minExp,
        maxExp,
        minCtc,
        maxCtc,
        isRadialSearchActive ? 'true' : 'false',
        isRadialSearchActive
          ? encodeURIComponent(
              `radius=${radius < 20 ? 20 : radius}&latitude=${
                lat != undefined ? lat : latitude
              }&radius_units=0&longitude=${lng != undefined ? lng : longitude}`
            )
          : ''
      );

      let data = resp?.data?.data;
      if (data) {
        // clear markers
        clearMarkers(markers);
      }

      let joblist = data.jobs ?? [];
      setJobData(jobList ?? []);
      let networkJobs = data.networkJobs ?? [];
      joblist.forEach((job, i) => {
        markers.push(createMarker(map, job));
        for (let j = 0; j < networkJobs.length; j++) {
          if (networkJobs[j].name == job.name) {
            let netmarker = createMarker(map, networkJobs[j], '', true, job.count);
            markers.push(netmarker);
          }
        }
      });
    } else {
      let instIds = instituteName && instituteName?.map((item) => item.id).join(',');
      let years = gradYear && gradYear?.map((item) => item.value).join(',');
      let companyId = company && company?.map((item) => item.id).join(',');
      let locationID = jobLocation && jobLocation?.map((item) => item.id).join(',');
      let industryID = industryName && industryName?.map((item) => item.id).join(',');
      let technicalSkillsIds =
        selectedSkillsOption && selectedSkillsOption.map((skill) => skill.id).join(',');
      let businessSkillsIds = businessSkill && businessSkill?.map((item) => item.id).join(',');
      let personalSkillsIds =
        interPersonalSkill && interPersonalSkill?.map((item) => item.id).join(',');
      resp = await JOBAPISERVICES.getAlumniForMap(
        newPlaceType,
        alumniName,
        instIds ?? '',
        years ?? '',
        companyId ?? '',
        locationID ?? '',
        industryID,
        technicalSkillsIds,
        businessSkillsIds,
        personalSkillsIds,
        isRadialSearchActive ? 'true' : 'false',
        isRadialSearchActive
          ? encodeURIComponent(
              `radius=${
                radius > 20 ? 20 : radius
              }&latitude=${latitude}&radius_units=0&longitude=${longitude}`
            )
          : ''
      );

      // alumni-name=&institute=&passoutYear=&company=&location=&category=&skillset=&BusinessSkillset=&PersonalSkillset=

      let data = resp.data.data;
      if (data) {
        // clear markers
        clearMarkers(markers);
      }

      let joblist = data.alumni ?? [];
      setJobData(jobList);
      joblist.forEach((job) => {
        markers.push(createMarker(map, job));
      });
    }
  };
  const [zoomLevel, setZoomLevel] = useState(null);
  const onZoomLevelChanged = (zoom) => {
    setZoomLevel(zoom);
  };

  useEffect(() => {
    if (zoomLevel) {
      // signal: newAbortSignal(5000);
      getResult(zoomLevel); // just call
    }
  }, [zoomLevel, isRadialSearchActive]);

  const updateCircleRadius = (rad) => {
    // calculate sizer position from center of the circle
    let center = radiusWidget.get('center');
    let distance = rad;
    let latLongFromCenter = window.google.maps.geometry.spherical.computeOffset(
      center,
      distance * 1000,
      90
    );
    radiusWidget.set('sizer_position', latLongFromCenter);
    radiusWidget.setDistance();
    setRadius(rad);
  };
  const resetRadialSearch = () => {
    setIsRadialSearchActive(false); // effect will trigger
    // set circle map to  nulll
    if (circle) {
      clearMarkers(markers);
      circle.setMap(null);
      map.setOptions({
        minZoom: 2,
        // initial scale
        maxZoom: 12,
        zoom: 4
      });
      setMapOptions({
        ...mapOptions,
        minZoom: 2,
        // initial scale
        maxZoom: 12,
        zoom: 4
      });
    }
  };
  const [clickedPlace, setClickedPlace] = useState('');
  const handleNetworkMarkerClick = async (result) => {
    setClickedPlace(result.name.trim());
    // your stuff
    if (type === 'Jobsmap') {
      let body = {
        place: result.name.trim(),
        pt: getMapViewPointBasedOnZoom()
        // filters and other stuff
      };
      //reset exiting list if any
      dispatch(resetAllJobList());
      let resp = await dispatch(getAllJobList(body, true));
      if (resp.data) {
        // active section
        setActiveSection(['NETWORK']);
      }
    }
  };

  const handleMarkerClick = async (result, data) => {
    result && setClickedPlace(result?.name.trim());
    if (type === 'Alumnimap') {
      let body = {
        place: result ? result.name.trim() : clickedPlace,
        pt: getMapViewPointBasedOnZoom(),
        // filters and other stuff
        alumni_name: alumniName,
        institute: instituteName && instituteName?.map((item) => item.id).join(','),
        passoutYear: gradYear && gradYear?.map((item) => item.value).join(','),
        company: company && company?.map((item) => item.id).join(','),
        location: jobLocation && jobLocation?.map((item) => item.id).join(','),
        category: industryName && industryName?.map((item) => item.id).join(','),
        skillset: selectedSkillsOption && selectedSkillsOption.map((skill) => skill.id).join(','),
        BusinessSkillset: businessSkill && businessSkill?.map((item) => item.id).join(','),
        PersonalSkillset: interPersonalSkill && interPersonalSkill?.map((item) => item.id).join(',')
      };

      let response = await dispatch(getAllAlumniList(body));
      if (response.data) {
        setActiveSection([...activeSection, 'ALUMNIS']);
      }
    }
    // await dispatch(getUserJobShortList());       //

    if (type === 'Jobsmap' || type == undefined) {
      let body = {
        place: result.name.trim(),
        pt: getMapViewPointBasedOnZoom()
        // filters and other stuff
      };
      //reset exiting list if any
      dispatch(resetAllJobList());
      let resp = await dispatch(getAllJobList(body));
      if (resp.data) {
        // active section
        setActiveSection(['LISTS']);
      }
    }
  };

  const getMapViewPointBasedOnZoom = () => {
    let zoom = map.getZoom();
    let newPlaceType = 'country';
    if (zoom < 5) {
      newPlaceType = 'country';
    } else if (zoom > 4 && zoom < 7) {
      newPlaceType = 'state';
    } else if (zoom > 6) {
      newPlaceType = 'city';
    }
    return newPlaceType;
  };

  const [lastElement, setLastElement] = useState(null);
  const [shouldLoadMoreButtonVisible, setShouldLoadMoreButtonVisible] = useState(false);

  const onIntersecting = () => {
    setShouldLoadMoreButtonVisible(true);
  };

  const handleLoadMore = () => {
    // load more
    let body = {
      place: clickedPlace !== '' ? clickedPlace : 'India',
      pt: getMapViewPointBasedOnZoom()
      // filters and other stuff
    };
    dispatch(getAppendableJobList(body));
  };
  useInfiniteScroller(lastElement, onIntersecting);

  const addAndRemoveFromShortList = async (data, status) => {
    await dispatch(toggleJobShortList(data.id, status));

    // dispatch
    //  await  dispatch(getUserJobShortList());
  };
  const addAndRemoveFromShortList2 = async (data, status) => {
    // dispatch
    if (status == 0) {
      const message = window.confirm('Are you sure you want to remove shortlisted job?');
      if (message) {
        await dispatch(toggleJobShortList(data.id, status));
      }
    }
    // await dispatch(getUserJobShortList());
    //  await  dispatch(getUserJobShortList());
  };

  const displayJobDetail = (id) => {
    APISERVICE.getSingleJobDetail(id).then((res) => {
      setJobDetails(res.data.data);
      setJobDetailsVisible(true);
    });
  };
  const onCloseJob = (state) => {
    setJobDetailsVisible(state);
  };

  const resquestHanderFriend = async (e, id, type) => {
    e.preventDefault();
    let body = {
      following_id: id
    };
    if (type == 'add') {
      try {
        const response = await JOBAPISERVICES.addFriend(body);
        if (response?.status === 200) {
          handleMarkerClick();
        } else {
          showNotification('success', response?.data?.message);
        }
      } catch (error) {
        console.error(error);
      }
    }

    if (type == 'remove') {
      if (confirm('Do you want to remove your friend?') === true) {
        try {
          const response = await JOBAPISERVICES.addFriend(body);
          if (response?.status === 200) {
            handleMarkerClick();
          } else {
            showNotification('success', response?.data?.message);
          }
        } catch (error) {
          console.error(error);
        }
      }
    }

    if (type == 'requested') {
      if (confirm('Do you want to remove request?') === true) {
        try {
          const response = await JOBAPISERVICES.addFriend(body);
          if (response?.status === 200) {
            handleMarkerClick();
          } else {
            showNotification('success', response?.data?.message);
          }
        } catch (error) {
          console.error(error);
        }
      }
    }

    if (type == 'mentor') {
      // if (confirm('Do you want to remove request?') === true) {
      try {
        const response = await JOBAPISERVICES.addFriend(body);
        if (response?.status === 200) {
          handleMarkerClick();
        } else {
          showNotification('success', response?.data?.message);
        }
      } catch (error) {
        console.error(error);
      }
      // }
    }
    // else {

    //   let body = {
    //     userData: id
    //   };
    //   removeFriend(body).then((res) => {
    //     setLoading(false);
    //     if (res?.status == 200) {
    //       handleShowlike(e, postId);
    //     } else {
    //       showNotification('success', res?.data?.message);
    //     }
    //   });
    // }
  };

  const loadTechOptions = async (inputValue) => {
    return JOBAPISERVICES.getTechnicalSkillDrop(inputValue).then((res) => {
      if (res?.status === 200) {
        let data = res?.data?.data;
        let arr = [];
        data?.map((data) => {
          arr.push({ label: data.name, value: data.id });
        });
        return arr;
      }
    });
  };

  return (
    <>
      {/* <HeaderShop/> */}
      {getToken() ? <HeaderLogin /> : <HeaderInner />}
      <section className="jobs_map">
        <div className="jobmaps_inner">
          <div className="map_sort_filter">
            {/* help filter */}

            <div
              className={`help_map ${activeSection.includes('HELP') ? '' : 'job-map-model-hide'}`}
              //style={{ display: 'none' }}
            >
              <div onClick={() => setActiveSection([])} className="help-close-icon">
                <div className="fa fa-times "> </div>
              </div>
              <h4>
                Search and locate on our interactive Jobs Map. Click on the location or zoom into an
                area and you can see the number of jobs and location of those jobs available on a
                map.
              </h4>
            </div>

            {/* <div className="map-alumni-list">
              <div className="map-alumni-hd">
                <h4>
                  <span>3</span>Alumni in Australia
                </h4>
              </div>
              <ul>
                <li>
                  <div className="map-alumni-card d-flex align-items-top">
                    <div className="map-alumni-img me-3">
                      <img
                        src={require('../../../assets/images/hall-ticketcompany-logo-default.png')}
                        alt="shortlist"
                      />
                    </div>
                    <div className="map-alumni-dis">
                      <h4>
                        <Link>kylie jenner</Link>
                      </h4>
                      <p>
                        {' '}
                        <i className="fas fa-map-marker-alt me-2"></i>Drouin, Victoria, Australia
                      </p>
                      <button className="add-frnd">Add Friend</button>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="map-alumni-card d-flex align-items-top">
                    <div className="map-alumni-img me-3">
                      <img
                        src={require('../../../assets/images/hall-ticketcompany-logo-default.png')}
                        alt="shortlist"
                      />
                    </div>
                    <div className="map-alumni-dis">
                      <h4>
                        <Link>kylie jenner</Link>
                      </h4>
                      <p>
                        {' '}
                        <i className="fas fa-map-marker-alt me-2"></i>Drouin, Victoria, Australia
                      </p>
                      <button className="add-frnd">Add Friend</button>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="map-alumni-card d-flex align-items-top">
                    <div className="map-alumni-img me-3">
                      <img
                        src={require('../../../assets/images/hall-ticketcompany-logo-default.png')}
                        alt="shortlist"
                      />
                    </div>
                    <div className="map-alumni-dis">
                      <h4>
                        <Link>kylie jenner</Link>
                      </h4>
                      <p>
                        {' '}
                        <i className="fas fa-map-marker-alt me-2"></i>Drouin, Victoria, Australia
                      </p>
                      <button className="add-frnd">Add Friend</button>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="map-alumni-card d-flex align-items-top">
                    <div className="map-alumni-img me-3">
                      <img
                        src={require('../../../assets/images/hall-ticketcompany-logo-default.png')}
                        alt="shortlist"
                      />
                    </div>
                    <div className="map-alumni-dis">
                      <h4>
                        <Link>kylie jenner</Link>
                      </h4>
                      <p>
                        {' '}
                        <i className="fas fa-map-marker-alt me-2"></i>Drouin, Victoria, Australia
                      </p>
                      <button className="add-frnd">Add Friend</button>
                    </div>
                  </div>
                </li>
              </ul>
            </div> */}

            {/** ALL JOBS LIST */}
            <div
              className={`shortlist_map_area ${
                activeSection.includes('SHORTLISTED') ? '' : 'job-map-model-hide'
              }`}>
              <Tabs
                defaultActiveKey={'profile'}
                id="fill-tab-example"
                className="mb-3"
                fill
                onSelect={(k) => {
                  // if (k == 'shortlist_job') {
                  dispatch(getUserJobShortList());
                  // }
                }}>
                {isloggedIn && (type === 'Jobsmap' || type == undefined) ? (
                  <Tab
                    eventKey="profile"
                    // title={`${shortList?.length} Shortlisted`}
                    title={
                      <span>
                        <span className="heart-tab-icon "></span>
                        {shortList?.length} Shortlisted
                      </span>
                    }>
                    <div className="shortlist_top">
                      {/* <div className="head_shortlist">
                        <Link to="#">
                          <span className="heart-tab-icon"></span>
                          <strong className="filter_count">{shortListCount}</strong> Shortlisted
                        </Link>
                      </div> */}

                      <div className="shortlist-box">
                        {/* All over 3 job(s) shortlisted. You have not shortlisted any Job */}
                        {shortList?.length > 0
                          ? 'All over ' + shortList?.length + ' job(s) shortlisted'
                          : 'You have not shortlisted any Job'}
                      </div>
                      {shortList.map((job) => {
                        return (
                          <div
                            className="shortlist_box_content"
                            key={job.id}
                            role="presentation"
                            // onClick={() => {
                            //   displayJobDetail(job);
                            // }}
                          >
                            <div className="media_shorlist">
                              <img
                                src={require('../../../assets/images/hall-ticketcompany-logo-default.png')}
                                alt="shortlist"
                              />
                            </div>
                            <div className="content_shortlist">
                              <h4 className="d-flex justify-content-between align-items-start">
                                {' '}
                                <a
                                  style={{
                                    // fontFamily: 'robotomedium',
                                    color: ' #545454',
                                    fontSize: '16px',
                                    width: '73%',
                                    marginLeft: '10px',
                                    wordBreak: 'break-word',
                                    wordWrap: 'break-word'
                                  }}
                                  onClick={() => {
                                    displayJobDetail(job.id);
                                  }}
                                  href="javascript:void(0)">
                                  {job?.job_title}
                                </a>{' '}
                                {job?.status == '1' && (
                                  <a
                                    href="javascript:void(0)"
                                    onClick={async () => {
                                      addAndRemoveFromShortList2(job, 0);
                                    }}>
                                    <span className="heart-tab-icon "></span>
                                  </a>
                                )}
                              </h4>
                              <h5
                                style={{
                                  color: '#fbae38',
                                  fontSize: '14px',
                                  // fontFamily: 'roboto-light',
                                  marginLeft: '10px'
                                }}>
                                {job?.company}
                              </h5>
                              <div
                                style={{
                                  color: '#7e7e7e',
                                  fontSize: '14px',
                                  // fontFamily: 'roboto-light',
                                  marginLeft: '10px'
                                }}>
                                <span>{job?.city} </span> <span>{job.state}</span>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </Tab>
                ) : (
                  ''
                )}
              </Tabs>
            </div>

            {/* shortlisted filter  */}
            <div
              className={`shortlist_map_area ${
                activeSection.includes('LISTS') || activeSection.includes('ALUMNIS')
                  ? ''
                  : 'job-map-model-hide'
              }`}>
              <Tabs
                defaultActiveKey={type === 'Alumnimap' ? 'alumni-lst' : 'profile'}
                id="fill-tab-example"
                className="mb-3"
                fill
                // onSelect={(k) => {
                //   if (k == 'shortlist_job') {
                //     dispatch(getUserJobShortList());
                //   }
                // }}

                //             font-family: 'robotomedium';
                // color: #545454;
                // font-size: 16px;
                // width: 73%;
                // margin-left: 10px;
                // word-break: break-word;
                // word-wrap: break-word;

                //                 color: #fbae38;;
                // font-size: 14px;
                // font-family: 'roboto-light';
                // margin-left: 10px;

                //             color: #7e7e7e;
                // font-size: 14px;
                // font-family: 'roboto-light';
                // margin-left: 10px;
                // }
              >
                {isloggedIn && (type === 'Jobsmap' || type == undefined) ? (
                  <Tab
                    eventKey="shortlist_job"
                    // title={`${shortList?.length} Shortlisted`}
                    title={
                      <span>
                        <span className="heart-tab-icon "></span>
                        {shortList?.length} Shortlisted
                      </span>
                    }>
                    <div className="shortlist_top">
                      {/* <div className="head_shortlist">
                        <Link to="#">
                          <span className="heart-tab-icon"></span>
                          <strong className="filter_count">{shortListCount}</strong> Shortlisted
                        </Link>
                      </div> */}

                      <div className="shortlist-box">
                        {shortList?.length > 0
                          ? shortList?.length + ' job(s) shortlisted in ' + clickedPlace
                          : 'You have not shortlisted any Job in ' + clickedPlace}
                      </div>
                      {shortList.map((job) => {
                        return (
                          <div
                            className="shortlist_box_content"
                            key={job.id}
                            role="presentation"
                            // onClick={() => {
                            //   displayJobDetail(job);
                            // }}
                          >
                            <div className="media_shorlist">
                              <img
                                src={require('../../../assets/images/hall-ticketcompany-logo-default.png')}
                                alt="shortlist"
                              />
                            </div>
                            <div className="content_shortlist">
                              <h4 className="d-flex justify-content-between align-items-start">
                                {' '}
                                <a
                                  style={{
                                    // fontFamily: 'robotomedium',
                                    color: ' #545454',
                                    fontSize: '16px',
                                    width: '73%',
                                    marginLeft: '10px',
                                    wordBreak: 'break-word',
                                    wordWrap: 'break-word'
                                  }}
                                  onClick={() => {
                                    displayJobDetail(job.id);
                                  }}
                                  href="javascript:void(0)">
                                  {job?.job_title}
                                </a>{' '}
                                {job?.status == '1' && (
                                  <a
                                    href="javascript:void(0)"
                                    onClick={async () => {
                                      addAndRemoveFromShortList2(job, 0);
                                    }}>
                                    <span className="heart-tab-icon "></span>
                                  </a>
                                )}
                              </h4>
                              <h5
                                style={{
                                  color: '#fbae38',
                                  fontSize: '14px',
                                  // fontFamily: 'roboto-light',
                                  marginLeft: '10px'
                                }}>
                                {job?.company}
                              </h5>
                              <div
                                style={{
                                  color: '#7e7e7e',
                                  fontSize: '14px',
                                  // fontFamily: 'roboto-light',
                                  marginLeft: '10px'
                                }}>
                                <span>{job?.city} </span> <span>{job.state}</span>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </Tab>
                ) : (
                  ''
                )}

                {(type === 'Jobsmap' || type == undefined) && (
                  <Tab
                    eventKey="profile"
                    title={
                      <span>
                        <i className="fas fa-list"></i> &nbsp; {jobList?.length} Results
                      </span>
                    }>
                    <div className="shortlist_top">
                      {/* {isloggedIn ? (
                        <>
                          <div className="head_shortlist">
                            <Link to="#">
                              <span className="heart-tab-icon"></span>
                              <strong className="filter_count">{jobListCount}</strong> Found
                            </Link>
                          </div>
                        </>
                      ) : (
                        ''
                      )} */}

                      {/* <div className="shortlist-box">All over {jobListCount} job(s) Found.</div> */}
                      <div className="jobListBox">
                        {jobListCount} job(s) available in {clickedPlace}
                      </div>
                      {jobList?.map((job, index) => {
                        return (
                          <div
                            className="shortlist_box_content"
                            key={job.id}
                            ref={setLastElement}
                            role="presentation"
                            onClick={() => {
                              // displayJobDetail(job);
                            }}>
                            <div className="media_shorlist">
                              <img
                                src={require('../../../assets/images/hall-ticketcompany-logo-default.png')}
                                alt="shortlist"
                              />
                            </div>
                            <div className="content_shortlist">
                              <h4 className="d-flex justify-content-between align-items-start">
                                {' '}
                                <a
                                  style={{
                                    // fontFamily: 'robotomedium',
                                    color: ' #545454',
                                    fontSize: '16px',
                                    width: '73%',
                                    marginLeft: '10px',
                                    wordBreak: 'break-word',
                                    wordWrap: 'break-word'
                                  }}
                                  onClick={() => {
                                    displayJobDetail(job.id);
                                  }}
                                  href="javascript:void(0)">
                                  {job?.job_title}
                                </a>{' '}
                                {job?.status == '1' && (
                                  <a
                                    href="javascript:void(0)"
                                    onClick={() => addAndRemoveFromShortList(job, 0)}>
                                    <span className="heart-tab-icon "></span>
                                  </a>
                                )}
                                {(job?.status == '0' || job?.status == null) && (
                                  <a
                                    href="javascript:void(0)"
                                    onClick={() => addAndRemoveFromShortList(job, 1)}>
                                    <span className="heart-tab-icon-blank"></span>
                                  </a>
                                )}
                              </h4>

                              <h5
                                style={{
                                  color: '#fbae38',
                                  fontSize: '14px',
                                  // fontFamily: 'roboto-light',
                                  marginLeft: '10px'
                                }}
                                onClick={() => {
                                  displayJobDetail(job.id);
                                }}>
                                {job?.company}
                              </h5>
                              <div
                                style={{
                                  color: '#7e7e7e',
                                  fontSize: '14px',
                                  // fontFamily: 'roboto-light',
                                  marginLeft: '10px'
                                }}>
                                <span>{job?.city} </span> <span>{job.state}</span>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                      {jobListCount != jobList?.length && (
                        <div className="load-more-btn  text-center">
                          <Button
                            // to="#"
                            onClick={() => {
                              handleLoadMore();
                            }}
                            className="read-more">
                            View more
                          </Button>
                        </div>
                      )}
                    </div>
                  </Tab>
                )}

                {type === 'Alumnimap' && (
                  <Tab eventKey="alumni-lst" title="">
                    <div className="map-alumni-list">
                      <div className="allumni">
                        <h2 className="title">
                          <svg
                            aria-hidden="true"
                            focusable="false"
                            data-prefix="fas"
                            data-icon="list-ul"
                            role="img"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                            className="svg-inline--fa fa-list-ul fa-lg">
                            <path
                              fill="currentColor"
                              d="M64 144a48 48 0 1 0 0-96 48 48 0 1 0 0 96zM192 64c-17.7 0-32 14.3-32 32s14.3 32 32 32H480c17.7 0 32-14.3 32-32s-14.3-32-32-32H192zm0 160c-17.7 0-32 14.3-32 32s14.3 32 32 32H480c17.7 0 32-14.3 32-32s-14.3-32-32-32H192zm0 160c-17.7 0-32 14.3-32 32s14.3 32 32 32H480c17.7 0 32-14.3 32-32s-14.3-32-32-32H192zM64 464a48 48 0 1 0 0-96 48 48 0 1 0 0 96zm48-208a48 48 0 1 0 -96 0 48 48 0 1 0 96 0z"
                              className=""></path>
                          </svg>
                          <span className="total">{alumnisListCount}</span> Alumni in {clickedPlace}
                        </h2>
                        <span>{/* {alumnisListCount} Alumni in {clickedPlace} */}</span>
                      </div>
                      <ul>
                        {alumnisList?.map((alumni, index) => {
                          return (
                            <li key={index}>
                              <div className="map-alumni-card d-flex align-items-top">
                                <div className="map-alumni-img me-3">
                                  <OverlayTrigger
                                    trigger={['click']}
                                    placement="bottom"
                                    // delay={{ show: 500, hide: 15000 }}
                                    data={alumni}
                                    popperConfig={{
                                      alumni
                                    }}
                                    ref={popupRef}
                                    // onToggle={(props) => {

                                    // }}
                                    shouldUpdatePosition={true}
                                    overlay={renderPopover}>
                                    <Image
                                      // {...triggerHandler}
                                      // ref={ref}
                                      // roundedCircle
                                      src={require('../../../assets/images/hall-ticketcompany-logo-default.png')}
                                    />
                                  </OverlayTrigger>
                                  {/* <img
                                      src={require('../../../assets/images/hall-ticketcompany-logo-default.png')}
                                      alt="shortlist"
                                    /> */}
                                </div>
                                <div className="map-alumni-dis">
                                  <h4>
                                    <Link>{alumni?.first_name + ' ' + alumni?.last_name}</Link>
                                  </h4>
                                  <p>
                                    {' '}
                                    <i className="fas fa-map-marker-alt me-2"></i>
                                    {alumni?.location}
                                  </p>
                                  {alumni?.follow_status == null && (
                                    <button
                                      onClick={(e) =>
                                        resquestHanderFriend(e, alumni?.user_id, 'add')
                                      }
                                      className="add-frnd">
                                      Add Friend
                                    </button>
                                  )}
                                  {alumni?.follow_status == 0 && (
                                    <button
                                      onClick={(e) =>
                                        resquestHanderFriend(e, alumni?.user_id, 'requested')
                                      }
                                      className="add-frnd">
                                      Requested
                                    </button>
                                  )}
                                  {alumni?.follow_status == 1 && (
                                    <button
                                      onClick={(e) =>
                                        resquestHanderFriend(e, alumni?.user_id, 'remove')
                                      }
                                      className="add-frnd">
                                      Unfriend
                                    </button>
                                  )}
                                </div>
                              </div>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </Tab>
                )}
              </Tabs>
            </div>

            {/* NETWORK JOBS LIST */}
            <div
              className={`shortlist_map_area ${
                activeSection.includes('NETWORK') ? '' : 'job-map-model-hide'
              }`}>
              <Tabs
                defaultActiveKey={type === 'Alumnimap' ? 'alumni-lst' : 'profile'}
                id="fill-tab-example"
                className="mb-3"
                fill
                // onSelect={(k) => {
                //   if (k == 'shortlist_job') {
                //     dispatch(getUserJobShortList());
                //   }
                // }}
              >
                {isloggedIn && (type === 'Jobsmap' || type == undefined) ? (
                  <Tab
                    eventKey="shortlist_job"
                    title=<span>
                      <span className="heart-tab-icon "></span>
                      {shortList?.length} Shortlisted
                    </span>>
                    <div className="shortlist_top">
                      {/* <div className="head_shortlist">
                        <Link to="#">
                          <span className="heart-tab-icon"></span>
                          <strong className="filter_count">{shortListCount}</strong> Shortlisted
                        </Link>
                      </div> */}

                      <div className="shortlist-box">
                        {shortList?.length > 0
                          ? shortList?.length +
                            '  job(s) shortlisted in your network in ' +
                            clickedPlace
                          : 'You have not shortlisted any Job in your network in ' + clickedPlace}
                        {/* {shortListCount} job(s) shortlisted in {clickedPlace} */}
                      </div>
                      {shortList.map((job) => {
                        return (
                          <div
                            className="shortlist_box_content"
                            key={job.id}
                            role="presentation"
                            // onClick={() => {
                            //   displayJobDetail(job);
                            // }}
                          >
                            <div className="media_shorlist">
                              <img
                                src={require('../../../assets/images/hall-ticketcompany-logo-default.png')}
                                alt="shortlist"
                              />
                            </div>
                            <div className="content_shortlist">
                              <h4 className="d-flex justify-content-between align-items-start">
                                {' '}
                                <a
                                  style={{
                                    // fontFamily: 'robotomedium',
                                    color: ' #545454',
                                    fontSize: '16px',
                                    width: '73%',
                                    marginLeft: '10px',
                                    wordBreak: 'break-word',
                                    wordWrap: 'break-word'
                                  }}
                                  href="javascript:void(0)">
                                  {job?.job_title}
                                </a>{' '}
                                {job?.status == '1' && (
                                  <a
                                    href="javascript:void(0)"
                                    onClick={async () => {
                                      addAndRemoveFromShortList2(job, 0);
                                    }}>
                                    <span className="heart-tab-icon "></span>
                                  </a>
                                )}
                              </h4>
                              <h5
                                style={{
                                  color: '#fbae38',
                                  fontSize: '14px',
                                  // fontFamily: 'roboto-light',
                                  marginLeft: '10px'
                                }}
                                onClick={() => {
                                  displayJobDetail(job.id);
                                }}>
                                {job?.company}
                              </h5>
                              <div
                                style={{
                                  color: '#7e7e7e',
                                  fontSize: '14px',
                                  // fontFamily: 'roboto-light',
                                  marginLeft: '10px'
                                }}>
                                <span>{job?.city} </span> <span>{job.state}</span>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </Tab>
                ) : (
                  ''
                )}

                {(type === 'Jobsmap' || type == undefined) && (
                  <Tab
                    eventKey="profile"
                    // title={`${jobList?.length} ${icon()} Results`}
                    title={
                      <span>
                        <i className="fas fa-list"></i> &nbsp; {jobList?.length} Results
                      </span>
                    }>
                    <div className="shortlist_top">
                      {/* {isloggedIn ? (
                        <>
                          <div className="head_shortlist">
                            <Link to="#">
                              <span className="heart-tab-icon"></span>
                              <strong className="filter_count">{jobListCount}</strong> Found
                            </Link>
                          </div>
                        </>
                      ) : (
                        ''
                      )} */}

                      {/* <div className="shortlist-box">All over {jobListCount} job(s) Found.</div> */}
                      <div className="networkListBox">
                        {jobListCount} jobs (s) in your network in {clickedPlace}
                      </div>
                      {jobList?.map((job, index) => {
                        return (
                          <div
                            className="shortlist_box_content"
                            key={job.id}
                            ref={setLastElement}
                            role="presentation"
                            onClick={() => {
                              // displayJobDetail(job);
                            }}>
                            <div className="media_shorlist">
                              <img
                                src={require('../../../assets/images/hall-ticketcompany-logo-default.png')}
                                alt="shortlist"
                              />
                            </div>
                            <div className="content_shortlist">
                              <h4 className="d-flex justify-content-between align-items-start">
                                {' '}
                                <a
                                  style={{
                                    // fontFamily: 'robotomedium',
                                    color: ' #545454',
                                    fontSize: '16px',
                                    width: '73%',
                                    marginLeft: '10px',
                                    wordBreak: 'break-word',
                                    wordWrap: 'break-word'
                                  }}
                                  onClick={() => {
                                    displayJobDetail(job.id);
                                  }}
                                  href="javascript:void(0)">
                                  {job?.job_title}
                                </a>{' '}
                                {job?.status == '1' && (
                                  <a
                                    href="javascript:void(0)"
                                    onClick={() => addAndRemoveFromShortList(job, 0)}>
                                    <span className="heart-tab-icon "></span>
                                  </a>
                                )}
                                {(job?.status == '0' || job?.status == null) && (
                                  <a
                                    href="javascript:void(0)"
                                    onClick={() => addAndRemoveFromShortList(job, 1)}>
                                    <span className="heart-tab-icon-blank"></span>
                                  </a>
                                )}
                              </h4>

                              <h5
                                style={{
                                  color: '#fbae38',
                                  fontSize: '14px',
                                  // fontFamily: 'roboto-light',
                                  marginLeft: '10px'
                                }}>
                                {job?.company}
                              </h5>
                              <div
                                style={{
                                  color: '#7e7e7e',
                                  fontSize: '14px',
                                  // fontFamily: 'roboto-light',
                                  marginLeft: '10px'
                                }}>
                                <span>{job?.city} </span> <span>{job.state}</span>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                      {jobList?.length > 10 && shouldLoadMoreButtonVisible && (
                        <div className="load-more-btn  text-center">
                          <Button
                            // to="#"
                            onClick={() => {
                              handleLoadMore();
                            }}
                            className="read-more">
                            View more
                          </Button>
                        </div>
                      )}
                    </div>
                  </Tab>
                )}

                {type === 'Alumnimap' && (
                  <Tab eventKey="alumni-lst" title="">
                    <div className="map-alumni-list">
                      <div className="map-alumni-hd">
                        <span>
                          All over <span style={{ fontSize: '20px' }}>{alumnisListCount}</span>{' '}
                          alumni(s) Found.
                        </span>
                      </div>
                      <ul>
                        {alumnisList?.map((alumni, index) => {
                          return (
                            <li key={index}>
                              <div className="map-alumni-card d-flex align-items-top">
                                <div className="map-alumni-img me-3">
                                  <img
                                    src={require('../../../assets/images/hall-ticketcompany-logo-default.png')}
                                    alt="shortlist"
                                  />
                                </div>
                                <div className="map-alumni-dis">
                                  <h4>
                                    <Link>{alumni?.first_name + ' ' + alumni?.last_name}</Link>
                                  </h4>
                                  <p>
                                    {' '}
                                    <i className="fas fa-map-marker-alt me-2"></i>
                                    {alumni?.location}
                                  </p>
                                  {alumni?.follow_status == null && (
                                    <button
                                      onClick={(e) =>
                                        resquestHanderFriend(e, alumni?.user_id, 'add')
                                      }
                                      className="add-frnd">
                                      Add Friend
                                    </button>
                                  )}
                                  {alumni?.follow_status == 0 && (
                                    <button
                                      onClick={(e) =>
                                        resquestHanderFriend(e, alumni?.user_id, 'requested')
                                      }
                                      className="add-frnd">
                                      Requested
                                    </button>
                                  )}
                                  {alumni?.follow_status == 1 && (
                                    <button
                                      onClick={(e) =>
                                        resquestHanderFriend(e, alumni?.user_id, 'remove')
                                      }
                                      className="add-frnd">
                                      Unfriend
                                    </button>
                                  )}
                                </div>
                              </div>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </Tab>
                )}
              </Tabs>
            </div>

            {/* search form filter */}

            <div
              className={`search_map ${
                activeSection.includes('FILTER') ? '' : 'job-map-model-hide'
              }`}>
              {(type === 'Jobsmap' || type == undefined) && (
                <>
                  {' '}
                  {Object.keys(validation())?.length > 0 && (
                    <div
                      style={
                        isClicked
                          ? {
                              display: 'block',
                              padding: '10px 5px',
                              margin: '10px 0px',
                              backgroundColor: '#bd362f',
                              color: '#ffffff'
                            }
                          : {
                              display: 'none',
                              padding: '10px 5px',
                              margin: '10px 0px',
                              backgroundColor: '#bd362f',
                              color: '#ffffff'
                            }
                      }>
                      {validation().message ? validation().message : validation().job}
                    </div>
                  )}
                  <Form>
                    {/* <Button className="accnt-btn w-100" type="submit">
                      Search
                    </Button> */}
                    <Form.Group className="mb-3">
                      <Form.Label>Location</Form.Label>
                      <AsyncSelect
                        isMulti
                        isClearable={true}
                        // cacheOptions
                        // defaultOptions
                        onMenuClose={() => setLocationSearch(null)}
                        placeholder={`Mumbai, Kolkata, Delhi..`}
                        value={jobLocation}
                        menuIsOpen={locationSearch !== null ? true : false}
                        getOptionLabel={(e) => e.label}
                        getOptionValue={(e) => e.value}
                        loadOptions={loadJObLocationOption}
                        onInputChange={(newValue) => setLocationSearch(newValue)}
                        className="basic-single mt-1"
                        name="location"
                        onChange={(e) => {
                          setJobLocation(() => e);
                        }}
                      />
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Label>Company</Form.Label>
                      <AsyncSelect
                        isClearable={true}
                        // cacheOptions
                        // defaultOptions
                        isMulti
                        onMenuClose={() => setCompanySearch(null)}
                        placeholder={`e.g. Dell, Infosys, Etc.`}
                        value={company}
                        menuIsOpen={companySearch !== null ? true : false}
                        getOptionLabel={(e) => e.label}
                        getOptionValue={(e) => e.value}
                        loadOptions={loadCompanyListOption}
                        onInputChange={(newValue) => setCompanySearch(newValue)}
                        className="basic-single mt-1"
                        name="company"
                        onChange={(e) => {
                          setCompany(() => e);
                        }}
                      />
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Label>Skills</Form.Label>
                      {/* <Form.Control type="text" placeholder="e.g. Photoshop,ILLustrator" /> */}
                      <AsyncSelect
                        isMulti
                        cacheOptions
                        defaultOptions
                        value={techSkill}
                        getOptionLabel={(e) => e.label}
                        getOptionValue={(e) => e.value}
                        loadOptions={loadTechOptions}
                        className="basic-single mt-1"
                        name="location"
                        onChange={setTechSkill}
                        components={{
                          DropdownIndicator: () => null,
                          IndicatorSeparator: () => null
                        }}
                        menuIsOpen={techSearch !== '' ? true : false}
                        onInputChange={(newValue) => setTechSearch(newValue)}
                        placeholder="e.g. Photoshop, Illustrator"
                      />
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Label>Industry</Form.Label>
                      {/* <Select
                        isMulti
                        className="basic-single mt-1"
                        classNamePrefix="select"
                        isDisabled={false}
                        isLoading={false}
                        isClearable={false}
                        isRtl={false}
                        isSearchable={true}
                        options={industryOption}
                        value={selectedIndustryOption}
                        name="industry"
                        onChange={(e, option) => {
                          onChangeHandler(e, option);
                          setSelectedIndustryOption(e);
                        }}
                        placeholder="e.g. IT, Communication etc."
                      /> */}
                      <AsyncSelect
                        isMulti
                        cacheOptions
                        defaultOptions
                        value={industryName}
                        getOptionLabel={(e) => e.label}
                        getOptionValue={(e) => e.value}
                        loadOptions={loadMainCompanyIndustries}
                        onInputChange={(newValue) => setIndustrySearch(newValue)}
                        className="basic-single mt-1"
                        name="industry"
                        menuIsOpen={industrySearch !== null ? true : false}
                        onMenuClose={() => setIndustrySearch(null)}
                        onChange={setIndustryName}
                        components={{
                          DropdownIndicator: () => null,
                          IndicatorSeparator: () => null
                        }}
                        placeholder="e.g. IT, Communication etc..."
                      />
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Label>Work Experience (In Years)</Form.Label>
                      <div className="d-flex align-items-center">
                        <Select
                          name="colors"
                          className="basic-multi-select"
                          classNamePrefix="select"
                          options={WorkOptions1}
                          placeholder="Select"
                          onChange={(e) => {
                            setMinExp(e?.value);
                          }}
                        />
                        <div className="px-2">To</div>
                        <Select
                          name="colors"
                          className="basic-multi-select"
                          classNamePrefix="select"
                          options={WorkOptions2}
                          placeholder="Select"
                          onChange={(e) => {
                            setMaxExp(e?.value);
                          }}
                        />
                      </div>
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Label>Expected Salary (In Lacs/Annum)</Form.Label>
                      <div className="d-flex align-items-center">
                        <Select
                          name="colors"
                          className="basic-multi-select"
                          classNamePrefix="select"
                          options={PackageOptions}
                          placeholder="Select"
                          onChange={(e) => {
                            setMinCtc(e?.id);
                          }}
                        />
                        <div className="px-2">To</div>

                        <Select
                          name="colors"
                          className="basic-multi-select"
                          classNamePrefix="select"
                          options={PackageOptions1}
                          placeholder="Select"
                          onChange={(e) => {
                            setMaxCtc(e?.id);
                          }}
                        />
                      </div>
                    </Form.Group>
                  </Form>
                  <Button
                    className="accnt-btn w-100"
                    type="submit"
                    onClick={(e) => {
                      e.preventDefault();
                      handleSearch();
                    }}>
                    Search
                  </Button>
                </>
              )}

              {type === 'Alumnimap' && (
                <div className="job-map-frm">
                  <Form.Group className="mb-3">
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                      className="basic-single mt-1"
                      value={alumniName}
                      onChange={(e) => setAlumniName(e.target.value)}
                      name="alumniName"
                      placeholder="Enter name of alumni"
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Institute Name</Form.Label>
                    <AsyncSelect
                      isMulti
                      cacheOptions
                      components={{
                        DropdownIndicator: () => null,
                        IndicatorSeparator: () => null
                      }}
                      className="basic-single"
                      classNamePrefix="select"
                      onMenuClose={() => setInstituteSearch(null)}
                      isSearchable={true}
                      isClearable
                      name="institute_id"
                      menuIsOpen={instituteSearch !== null ? true : false}
                      loadOptions={loadInstituteOptions}
                      placeholder="e.g. DAV College etc..."
                      onInputChange={(newValue) => setInstituteSearch(newValue)}
                      value={instituteName}
                      onChange={(e) => {
                        setInstituteName(e);
                      }}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Year Of Graduation</Form.Label>
                    <Select
                      isMulti
                      className="basic-single"
                      classNamePrefix="select"
                      placeholder="e.g. Graduation Year..."
                      isSearchable
                      isClearable
                      options={yearOptions}
                      value={gradYear}
                      name="to_year"
                      onChange={setGradYear}
                      menuIsOpen={yearSearch !== '' ? true : false}
                      onInputChange={(newValue) => setYearSearch(newValue)}
                      components={{
                        DropdownIndicator: () => null,
                        IndicatorSeparator: () => null
                      }}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Company Name</Form.Label>
                    <AsyncSelect
                      isMulti
                      isClearable={true}
                      // cacheOptions
                      // defaultOptions
                      onMenuClose={() => setCompanySearch(null)}
                      value={company}
                      menuIsOpen={companySearch !== null ? true : false}
                      getOptionLabel={(e) => e.label}
                      getOptionValue={(e) => e.value}
                      loadOptions={loadCompanyListOption}
                      onInputChange={(newValue) => setCompanySearch(newValue)}
                      className="basic-single mt-1"
                      name="company"
                      onChange={(e) => {
                        setCompany(() => e);
                      }}
                      placeholder="e.g. Dell, Infosys etc..."
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Location</Form.Label>
                    <AsyncSelect
                      isMulti
                      isClearable={true}
                      cacheOptions
                      defaultOptions
                      value={jobLocation}
                      menuIsOpen={locationSearch !== null ? true : false}
                      onMenuClose={() => setLocationSearch(null)}
                      getOptionLabel={(e) => e.label}
                      getOptionValue={(e) => e.value}
                      loadOptions={loadJObLocationOption}
                      onInputChange={(newValue) => setLocationSearch(newValue)}
                      className="basic-single mt-1"
                      name="location"
                      onChange={(e) => {
                        setJobLocation(() => e);
                      }}
                      placeholder="e.g. Delhi, Mumbai etc..."
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Industry Name</Form.Label>
                    <AsyncSelect
                      isMulti
                      cacheOptions
                      defaultOptions
                      value={industryName}
                      getOptionLabel={(e) => e.label}
                      getOptionValue={(e) => e.value}
                      loadOptions={loadMainCompanyIndustries}
                      onInputChange={(newValue) => setIndustrySearch(newValue)}
                      className="basic-single mt-1"
                      name="industry"
                      menuIsOpen={industrySearch !== null ? true : false}
                      onMenuClose={() => setIndustrySearch(null)}
                      onChange={setIndustryName}
                      components={{
                        DropdownIndicator: () => null,
                        IndicatorSeparator: () => null
                      }}
                      placeholder="e.g. IT, Communication etc..."
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Technical Skills</Form.Label>
                    <Select
                      isMulti
                      className="basic-single mt-1"
                      classNamePrefix="select"
                      isDisabled={false}
                      isLoading={false}
                      isClearable={false}
                      isRtl={false}
                      isSearchable={true}
                      options={skillsOption}
                      name="skills"
                      value={selectedSkillsOption}
                      onChange={(e, option) => {
                        onChangeHandler(e, option);
                        setSelectedSkillsOption(e);
                      }}
                      placeholder="e.g Photoshop, Illustrator etc..."
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Business Skills</Form.Label>
                    <AsyncSelect
                      isMulti
                      cacheOptions
                      defaultOptions
                      value={businessSkill}
                      getOptionLabel={(e) => e.label}
                      getOptionValue={(e) => e.value}
                      loadOptions={loadBusinessOptions}
                      className="basic-single mt-1"
                      name="business"
                      onChange={setBusinessSkill}
                      components={{
                        DropdownIndicator: () => null,
                        IndicatorSeparator: () => null
                      }}
                      menuIsOpen={businessSearch !== null ? true : false}
                      onMenuClose={() => setBusinessSearch(null)}
                      onInputChange={(newValue) => setBusinessSearch(newValue)}
                      placeholder="e.g. Marketing etc..."
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>InterPersonal Skills</Form.Label>
                    <AsyncSelect
                      isMulti
                      cacheOptions
                      defaultOptions
                      value={interPersonalSkill}
                      getOptionLabel={(e) => e.label}
                      getOptionValue={(e) => e.value}
                      loadOptions={loadPersonalOptions}
                      className="basic-single mt-1"
                      name="personal"
                      onChange={setInterPersonalSkill}
                      components={{
                        DropdownIndicator: () => null,
                        IndicatorSeparator: () => null
                      }}
                      menuIsOpen={personalSearch !== null ? true : false}
                      onMenuClose={() => setPersonalSearch(null)}
                      onInputChange={(newValue) => setPersonalSearch(newValue)}
                      placeholder={`e.g. Decision Making etc...`}
                    />
                  </Form.Group>
                  <Button
                    className="accnt-btn w-100"
                    type="submit"
                    onClick={(e) => {
                      e.preventDefault();
                      handleSearch();
                    }}>
                    Search
                  </Button>
                </div>
              )}
            </div>

            <div className="map_action_link">
              <button
                className="search_map_input"
                type="button"
                onClick={() => {
                  if (activeSection.includes('FILTER')) {
                    // setActiveSection([]); //set blank to disapear from sidebar list
                    let result = activeSection.filter((item) => item !== 'FILTER');
                    setActiveSection(result);
                  } else {
                    setActiveSection([...activeSection, 'FILTER']);
                  }
                }}>
                <i className="icon"></i>
                <span>FILTER</span>
              </button>

              <button
                className="radius_map_input"
                type="button"
                onClick={(e) => {
                  if (activeSection == 'SEARCH') {
                    // setActiveSection([]);  // this is also required
                    let result = activeSection.filter((item) => item !== 'SEARCH');
                    setActiveSection(result);
                    // setActiveSection([]);
                  } else {
                    setActiveSection(['SEARCH']);
                  }

                  // create radius

                  createRadialSearch();
                }}>
                <i className="icon"></i>
                <span>RADIUS SEARCH</span>
              </button>
              {isloggedIn && (type === 'Jobsmap' || type == undefined) ? (
                <>
                  <button
                    className="shortlisted_map_input"
                    type="button"
                    onClick={async (e) => {
                      if (activeSection.includes('SHORTLISTED')) {
                        // setActiveSection([]);  // this is also required
                        let resp = await dispatch(getUserJobShortList());
                        if (resp.data) {
                          // active section
                          setActiveSection(['SHORTLISTED']);
                        }
                        let result = activeSection.filter((item) => item !== 'SHORTLISTED');
                        setActiveSection(result);
                        // dispatch(getUserJobShortList());
                      } else {
                        let resp = await dispatch(getUserJobShortList());
                        if (resp.data) {
                          // active section
                          setActiveSection(['SHORTLISTED']);
                        }
                        // setActiveSection(['SHORTLISTED']);
                      }
                    }}>
                    <i className="icon"></i>
                    <span>SHORTLISTED JOBS</span>
                  </button>
                </>
              ) : (
                ''
              )}

              <button
                className="reset_map_input"
                type="button"
                disabled={map === null}
                onClick={(e) => {
                  setActiveSection([]);
                  if (circle) {
                    resetRadialSearch();
                  } else {
                    clearMarkers(markers);
                    map?.setOptions({
                      minZoom: 2,
                      // initial scale
                      maxZoom: 12,
                      zoom: 4
                    });
                    setMapOptions({
                      ...mapOptions,
                      minZoom: 2,
                      // initial scale
                      maxZoom: 12,
                      zoom: 4
                    });
                    map?.setCenter(
                      new google.maps.LatLng(currentPosition.lat, currentPosition.lng)
                    );
                  }
                  // map.setOptions({
                  //   minZoom: 4,
                  //   maxZoom: 20,
                  //   zoom: 4
                  // });
                  // setMapOptions({
                  //   ...mapOptions,
                  //   minZoom: 4,
                  //   zoom: 4,
                  //   maxZoom: 20
                  // });
                }}>
                <i className="icon"></i>
                <span>RESET</span>
              </button>

              <button
                className="toggler_map_input"
                type="button"
                onClick={() => {
                  if (activeSection.includes('FILTER')) {
                    setActiveSection([]); //set blank to disapear from sidebar list
                  } else {
                    setActiveSection([...activeSection, 'FILTER']);
                  }
                }}>
                <i className="icon"></i>
              </button>

              <button
                className="help_map_input"
                type="button"
                onClick={() => {
                  if (activeSection.includes('HELP')) {
                    setActiveSection([]); //set blank to disapear from sidebar list
                  } else {
                    setActiveSection(['HELP']);
                  }
                }}>
                <span>HELP</span>
                <i className="icon"></i>
              </button>
            </div>
          </div>

          <div className="job_map_view job-map-model-hide">
            <iframe
              src="https://www.google.com/maps/place/AlmaBay+Networks+Private+Limited/@30.660436,76.860654,16z/data=!4m5!3m4!1s0x0:0xb667563103641761!8m2!3d30.660436!4d76.860654?hl=en-GB"
              width="100%"
              height="100%"
              allowFullScreen=""
              loading="lazy"
              title="googleFrame"></iframe>
          </div>
        </div>
        {isRadialSearchActive && (
          <div className="mr_large_padding_t map-bottom-card z_index">
            <Button
              onClick={() => {
                resetRadialSearch();
                setActiveSection([]);
              }}
              className="rounded-circle position-absolute close-radius">
              <i className="fas fa-times"></i>
            </Button>

            <div
              style={{ border: '1px solid #FBAE38' }}
              className="bg-white manage_jobs_radius mr_normal_padding">
              {jobData.length == 0 && (
                <h4 className="map-hd-card">
                  {type === 'Jobsmap' || type == undefined
                    ? 'No job(s) available. Increase radius to find Jobs.'
                    : 'No Alumni found. Increase radius to find alumni.'}
                </h4>
              )}
              {/* #bd362f */}
              <div className="flex align-items_flex-end">
                <div className="radius-frm">
                  <div className="row align-items-end">
                    <div className=" col-md-4 col-lg-3 offset-lg-2 offset-md-2">
                      <label className="me-2" htmlFor="radius">
                        <b>Radius</b>
                      </label>
                      <input
                        type={'text'}
                        className="w100 block radius-input"
                        value={unit == 'km' ? Math.round(radius) : Math.round(radius / 1.6)}
                        name="radius"
                        id="radius"
                        onChange={(e) => {
                          if (unit === 'km') {
                            updateCircleRadius(Number(e.target.value));
                            return;
                          } else {
                            updateCircleRadius(Number(e.target.value * 1.6));
                            return;
                          }
                        }}
                        autoComplete="off"
                      />
                    </div>
                    <div className=" col-md-4 col-lg-3 ">
                      <div className="rd-frm-select">
                        <label className="me-2" htmlFor="unit">
                          <b>Units</b>
                        </label>
                        <br />
                        <select
                          onChange={(e) => setUnit(e.target.value)}
                          name="unit"
                          id="unit"
                          value={unit}
                          className="w100 block rd-frm-select">
                          {/* <option value={``}>Select</option> */}
                          <option value="km">Kilometer</option>
                          <option value="miles">Miles</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-md-4 col-lg-3">
                      <div className="">
                        <Button
                          variant="primary"
                          onClick={() => {
                            handleRadialSearch();
                          }}>
                          Search
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {isRadialSearchActive && (
          <div className="searchBoxMap">
            <div></div>
            <Autocomplete
              placeholder="Search for place"
              className="w100"
              apiKey={process.env.REACT_APP_SEARCH_KEY}
              onChange={(e) => {
                // getResult(map.getZoom());
              }}
              onPlaceSelected={(props) => {
                // console.log('e', props);
                setAddressCoordinate((prev) => ({
                  ...prev,
                  lat: props?.geometry?.location?.lat(),
                  lng: props?.geometry?.location?.lng()
                }));
                handleRadialSearch(
                  props?.geometry?.location?.lat(),
                  props?.geometry?.location?.lng()
                );
              }}
            />
          </div>
        )}

        <Row>
          <Col lg={12}>
            <div className="mapStyle">
              <UserJobMap
                mapOptions={mapOptions}
                isPosition={isPosition}
                currentPosition={currentPosition}
                setMapState={setMapState}
                setMapsState={setMapsState}
                setIsZooming={setIsZooming}
                jobLocation={jobLocation}
                onZoomLevelChanged={onZoomLevelChanged}
                handleNetworkMarkerClick={handleNetworkMarkerClick}
                // onRadiusChange={onRadiusChange}
                radius={radius}
                handleMarkerClick={handleMarkerClick}
                addressCoordinate={addressCoordinate}
                setAddressCoordinate={setAddressCoordinate}
                type={type}
                alumniList={alumniList}
              />
            </div>
          </Col>
        </Row>
        {jobDetailsVisible && (
          <JobDetail
            jobDetails={jobDetails}
            onCloseJob={onCloseJob}
            onChangeHandle={onChangeHandle}
            setIsChecked={setIsChecked}
            isChecked={isChecked}
            submitHandler={submitHandler}
            applyForm={applyForm}
            show2={show2}
            handleClose2={handleClose2}
            // jobTitle={jobTitle}
            resumeSelect={resumeSelect}
            fileSizeValid={fileSizeValid}
            onApplyStateHandler={onApplyStateHandler}
            setJobDetailsVisible={setJobDetailsVisible}
            applied={applied}
            setApplied={setApplied}
            handleClose3={handleClose3}
            show3={show3}
            jobId={jobId}
            onRecommendClick={onRecommendClick}
          />
        )}
        {type === 'Alumnimap' && alumniCard && (
          <div className="found-network-card">
            <div className="alumni-count">
              <Link onClick={() => setAlumniCard(false)} className="alumni-close" to="#">
                <i className="fas fa-times"></i>
              </Link>
              <h4>{alumniList && alumniList?.totalAlumnis[0].total}</h4>
              <p>Alumni found in your network</p>
            </div>
            <div className="alumni-collz-list">
              <ul>
                {alumniList?.alumnisByInstitute?.map((elem, index) => {
                  let withLocationCount = Number(elem?.with_location);
                  let withoutLocationCount = Number(elem?.without_location);
                  if (withLocationCount > 1000) {
                    withLocationCount = Number((withLocationCount / 1000).toFixed(1));
                  }
                  if (withoutLocationCount > 1000) {
                    withoutLocationCount = Number((withoutLocationCount / 1000).toFixed(1));
                  }
                  return (
                    <li key={index}>
                      <div className="alumni-collz-card d-flex align-items-center">
                        {tooltip && elem?.institute_id == allIds?.institute_id && (
                          <div className="hasTooltip">
                            <div>
                              Total Alumni :
                              <strong className="ng-binding sky" title="8453">
                                {elem?.total}
                              </strong>
                            </div>
                            <div>
                              Alumni with Location :
                              <strong title="8453" className="ng-binding yellow">
                                {withLocationCount > 1000 ? withLocationCount : elem?.with_location}
                              </strong>
                            </div>
                            <div>
                              Alumni without Location :
                              <strong title="5823" className="ng-binding grey">
                                {withoutLocationCount > 1000
                                  ? withoutLocationCount
                                  : elem?.without_location}
                              </strong>
                            </div>
                          </div>
                        )}
                        <h4>
                          {withLocationCount > 1000 ? withLocationCount : elem?.with_location}
                        </h4>

                        <div
                          onMouseLeave={() => {
                            setTooltip(false);
                            setID('');
                          }}
                          onMouseEnter={() => {
                            setTooltip(true);
                            setID(elem?.institute_id);
                          }}
                          className="alumni-collz-dis">
                          <h5>{elem?.name}</h5>
                          <p>
                            <i className="fas fa-map-marker-alt me-1"></i>
                            {elem?.city}
                          </p>
                        </div>
                      </div>
                    </li>
                  );
                })}
              </ul>
            </div>
            <div className="already-connected">
              <p>0 Alumni are already connected </p>
            </div>
          </div>
        )}
      </section>
    </>
  );
}

export default JobsMap;
// End of Google Function //

//Start of circle Draw Function on google map //
/**
 * A distance widget that will display a circle that can be resized and will
 * provide the radius in km.
 *
 * @param {google.maps.Map} map The map on which to attach the distance widget.
 *
 * @constructor
 */
function DistanceWidget(map, radius, currentPosition) {
  map?.setCenter(currentPosition);
  this?.set('map', map);
  this?.set('position', map?.getCenter());

  // console.log('this', this);

  let marker = new window.google.maps.Marker({
    icon: {
      url: require('../../../assets/images/center-nod.png'),
      scaledSize: new window.google.maps.Size(22, 22),
      anchor: new window.google.maps.Point(8, 12)
    },
    title: 'Move me!',
    draggable: true,
    cursor: 'all-scroll'
  });

  // Bind the marker map property to the DistanceWidget map property
  marker.bindTo('map', this);

  // Bind the marker position property to the DistanceWidget position
  // property
  marker.bindTo('position', this);

  marker.bindTo('center', this, 'position');
  // Create a new radius widget
  radiusWidget = new RadiusWidget(radius);

  // Bind the radiusWidget map to the DistanceWidget map
  radiusWidget.bindTo('map', this);

  // Bind the radiusWidget center to the DistanceWidget position
  radiusWidget.bindTo('center', this, 'position');

  // Bind to the radiusWidgets' distance property
  this.bindTo('distance', radiusWidget);

  // Bind to the radiusWidgets' bounds property
  this.bindTo('bounds', radiusWidget);
}

/**
 * A radius widget that add a circle to a map and centers on a marker.
 *
 * @constructor
 */

function RadiusWidget(radius) {
  circle = new window.google.maps.Circle({
    strokeWeight: 1,
    strokeColor: '#FBAE38',
    strokeOpacity: 0.9,
    fillColor: '#FFCD81',
    draggable: true
  });

  // Set the distance property value, default to 20km.
  this.set('distance', radius);

  // Bind the RadiusWidget bounds property to the circle bounds property.
  this.bindTo('bounds', circle);

  // Bind the circle center to the RadiusWidget center property
  circle.bindTo('center', this);

  // Bind the circle map to the RadiusWidget map
  circle.bindTo('map', this);

  // Bind the circle radius property to the RadiusWidget radius property
  circle.bindTo('radius', this);

  this.addSizer();
}

const UserJobMap = ({
  isPosition,
  currentPosition,
  setMapState,
  setMapsState,
  setIsZooming,
  onZoomLevelChanged,
  mapOptions,
  handleMarkerClick,
  handleNetworkMarkerClick,
  type
}) => {
  useEffect(() => {
    if (type === 'Jobsmap' || type == undefined) {
      JOBAPISERVICES.getUserJobsForMap().then((resp) => {
        if (resp.status == 200 && resp.data.data) {
          clearMarkers(markers);
          const joblist = resp.data.data.jobs;
          const networkJobs = resp.data.data.networkJobs;
          joblist.forEach((job, i) => {
            markers.push(createMarker(map, job));
            for (let j = 0; j < networkJobs?.length; j++) {
              if (networkJobs[j].name == job.name) {
                let netmarker = createMarker(map, networkJobs[j], '', true, job.count);
                markers.push(netmarker);
              }
            }
          });
        }
      });
    } else {
      JOBAPISERVICES.getAlumniForMap().then((resp) => {
        if (resp.status == 200 && resp.data.data) {
          clearMarkers(markers);
          const alumniList = resp.data.data.alumni;

          alumniList.forEach((alumni, i) => {
            markers.push(createMarker(map, alumni));
          });
        }
      });
    }
  }, []);

  const clearMarkers = (markers) => {
    markers.forEach((marker) => {
      marker.setMap(null);
    });
    markers = [];
  };

  const createMarker = (map, result, text = '', network, networkCount) => {
    if (network) {
      let icon = {
        url: require('../../../assets/images/network-marker.png')
      };
      let labelsClass = 'networkLabels';
      let labelContent =
        '<label title="Jobs in your Network' + text + '">' + result.count + '</label>';
      let mainClass = '';
      if (result.count < 10) {
        labelsClass += ' nw10';
        mainClass = '10';
      } else if (result.count >= 10 && result.count < 100) {
        labelsClass += ' nw100';
        mainClass = '100';
      } else if (result.count >= 100 && result.count < 500) {
        labelsClass += ' lt500';
        mainClass = '500';
      } else if (result.count >= 500 && result.count < 1000) {
        labelsClass += ' nw1000';
        mainClass = '1000';
      } else if (result.count >= 1000 && result.count < 5000) {
        labelsClass += ' nw5000';
        mainClass = '5000';
      } else if (result.count >= 5000 && result.count < 10000) {
        labelsClass += ' nw10000';
        mainClass = '10000';
      }

      if (networkCount < 10) {
        labelsClass += ' marker' + mainClass + 'w10';
        icon.scaledSize = new window.google.maps.Size(20, 20);
      } else if (networkCount >= 10 && networkCount < 100) {
        labelsClass += ' marker' + mainClass + 'w100';
        icon.scaledSize = new window.google.maps.Size(20, 20);
      } else if (networkCount >= 100 && networkCount < 500) {
        icon.scaledSize = new window.google.maps.Size(20, 20);
        labelsClass += ' marker' + mainClass + 'w500';
      } else if (networkCount >= 500 && networkCount < 1000) {
        labelsClass += ' marker' + mainClass + 'w1000';
        icon.scaledSize = new window.google.maps.Size(20, 20);
      } else if (networkCount >= 1000 && networkCount < 5000) {
        labelsClass += ' marker' + mainClass + 'w5000';
        icon.scaledSize = new window.google.maps.Size(20, 20);
      } else if (networkCount >= 5000 && networkCount < 10000) {
        labelsClass += ' marker' + mainClass + 'w10000';
        icon.scaledSize = new window.google.maps.Size(20, 20);
      }
      let marker = new MarkerWithLabel({
        position: {
          lat: Number(result.latitude),
          lng: Number(result.longitude)
        },
        map: map,
        labelContent: labelContent,
        icon: icon,
        labelAnchor: { x: 7, y: 28 },
        title: result['name'],
        labelClass: labelsClass
      });
      marker.addListener('click', () => {
        // handleMarkerClick(result);
        handleNetworkMarkerClick(result);
      });
      return marker;
    } else {
      let labelsClass = 'marker_label_job_map';
      let icon = {
        url: require('../../../assets/images/big-marker.png')
      };
      if (result.count < 10) {
        icon.scaledSize = new window.google.maps.Size(40, 40);
        labelsClass += ' lt10';
      } else if (result.count >= 10 && result.count < 100) {
        icon.scaledSize = new window.google.maps.Size(45, 45);
        labelsClass += ' lt100';
      } else if (result.count >= 100 && result.count < 500) {
        icon.scaledSize = new window.google.maps.Size(55, 55);
        labelsClass += ' lt500';
      } else if (result.count >= 500 && result.count < 1000) {
        icon.scaledSize = new window.google.maps.Size(60, 60);
        labelsClass += ' lt1000';
      } else if (result.count >= 1000 && result.count < 5000) {
        icon.scaledSize = new window.google.maps.Size(68, 68);
        labelsClass += ' lt5000';
      } else if (result.count >= 5000 && result.count < 10000) {
        icon.scaledSize = new window.google.maps.Size(72, 72);
        labelsClass += ' lt10000';
      } else {
        icon.scaledSize = new window.google.maps.Size(100, 100);
        labelsClass += ' gt10000';
      }

      let marker = new MarkerWithLabel({
        position: {
          lat: Number(result.latitude),
          lng: Number(result.longitude)
        },
        map: map,
        labelContent:
          type === 'Alumnimap'
            ? '<span title="Total Alumnis in ' +
              result['name'] +
              text +
              '">' +
              result.count +
              '</span>'
            : '<span title="Total Jobs in ' +
              result['name'] +
              text +
              '">' +
              result.count +
              '</span>',
        icon: icon,
        labelAnchor: { x: 7, y: 28 },
        title: result['name'],
        labelClass: labelsClass
      });
      marker.addListener('click', () => {
        handleMarkerClick(result);
      });

      return marker;
    }
  };

  function checkBounds(map) {
    let latNorth = map.getBounds().getNorthEast().lat();
    let latSouth = map.getBounds().getSouthWest().lat();
    let newLat;

    if (latNorth < 85 && latSouth > -85) /* in both side -> it's ok */ return;
    else {
      if (latNorth > 85 && latSouth < -85) /* out both side -> it's ok */ return;
      else {
        if (latNorth > 85)
          newLat = map.getCenter().lat() - (latNorth - 85); /* too north, centering */
        if (latSouth < -85)
          newLat = map.getCenter().lat() - (latSouth + 85); /* too south, centering */
      }
    }
    if (newLat) {
      let newCenter = new window.google.maps.LatLng(newLat, map.getCenter().lng());
      map.setCenter(newCenter);
    }
  }

  const handleApiLoaded = async (mapp, maps) => {
    DistanceWidget.prototype = new window.google.maps.MVCObject();
    RadiusWidget.prototype = new window.google.maps.MVCObject();

    RadiusWidget.prototype.addSizer = function () {
      let sizer = new window.google.maps.Marker({
        icon: {
          url: require('../../../assets/images/resizer.png'),
          scaledSize: new window.google.maps.Size(24, 24),
          anchor: new window.google.maps.Point(13, 13)
        },
        title: 'Drag me!',
        draggable: true,
        cursor: 'e-resize',
        raiseOnDrag: false
      });

      sizer.bindTo('map', this);
      sizer.bindTo('position', this, 'sizer_position');
      let me = this;
      window.google.maps.event.addListener(sizer, 'drag', function () {
        me.setDistance();
      });
    };

    /**
     * Update the center of the circle and position the sizer back on the line.
     *
     * Position is bound to the DistanceWidget so this is expected to change when
     * the position of the distance widget is changed.
     */
    RadiusWidget.prototype.center_changed = function () {
      let bounds = this.get('bounds');
      // Bounds might not always be set so check that it exists first.
      if (bounds) {
        let lng = bounds.getNorthEast().lng();
        // Put the sizer at center, right on the circle.
        let position = new window.google.maps.LatLng(this.get('center').lat(), lng);
        this.set('sizer_position', position);
      }
    };

    /**
     * Calculates the distance between two latlng locations in km.
     * @see http://www.movable-type.co.uk/scripts/latlong.html
     *
     * @param {google.maps.LatLng} p1 The first lat lng point.
     * @param {google.maps.LatLng} p2 The second lat lng point.
     * @return {number} The distance between the two points in km.
     * @private
     */
    RadiusWidget.prototype.distanceBetweenPoints_ = function (p1, p2) {
      if (!p1 || !p2) {
        return 0;
      }
      let R = 6371; // Radius of the Earth in km
      let dLat = ((p2.lat() - p1.lat()) * Math.PI) / 180;
      let dLon = ((p2.lng() - p1.lng()) * Math.PI) / 180;
      let a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos((p1.lat() * Math.PI) / 180) *
          Math.cos((p2.lat() * Math.PI) / 180) *
          Math.sin(dLon / 2) *
          Math.sin(dLon / 2);
      let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      let d = R * c;
      return d;
    };

    /**
     * Set the distance of the circle based on the position of the sizer.
     */
    RadiusWidget.prototype.setDistance = function () {
      // As the sizer is being dragged, its position changes.  Because the
      // RadiusWidget's sizer_position is bound to the sizer's position, it will
      // change as well.
      let pos = this.get('sizer_position');
      let center = this.get('center');
      let distance = this.distanceBetweenPoints_(center, pos);
      if (distance >= 20) {
        // Set the distance property for any objects that are bound to it
        this.set('distance', distance);
      } else {
        // Set the distance property for any objects that are bound to it
        this.set('distance', 20);
        let bounds = this.get('bounds');
        let lng = bounds.getNorthEast().lng();

        // Put the sizer at center, right on the circle.
        let position = new window.google.maps.LatLng(this.get('center').lat(), lng);
        this.set('sizer_position', position);
      }
    };

    /**
     * Update the radius when the distance has changed.
     */
    RadiusWidget.prototype.distance_changed = function () {
      if (this.get('distance') >= 20) {
        this.set('radius', this.get('distance') * 1000);
      } else {
        this.set('radius', 20000);
      }
    };
    // eslint-disable-next-line no-console
    map = mapp; // asign to global map let
    setMapState(map);
    setMapsState(maps);

    map.addListener('center_changed', function () {
      checkBounds(map);
    });
    map.addListener('idle', function () {
      // on first load
    });
    map.addListener('zoom_changed', () => {
      // get data based on zoom level
      setIsZooming(true);

      onZoomLevelChanged(map.getZoom());
    });

    map.addListener('click', (e) => {
      // setActiveSection([]); // just set to blank to hide the dropdown
    });
  };
  /**
   * RETURN DISTANCE BETWEEN THE MARKER AND MOUSE POINTER
   * @param {*} pt
   * @param {*} mp
   * @returns
   */
  const distanceToMouse = (pt, mp) => {
    if (pt && mp) {
      return Math.sqrt((pt.x - mp.x) * (pt.x - mp.x) + (pt.y - mp.y) * (pt.y - mp.y));
    }
  };

  return (
    isPosition && (
      <GoogleMapReact
        bootstrapURLKeys={{
          key: process.env.REACT_APP_GOOGLE_MAP_KEY,
          language: 'en',
          region: 'US',
          libraries: ['places', 'geometry', 'drawing', 'visualization']
        }}
        defaultCenter={{ lat: currentPosition.lat, lng: currentPosition.lng }}
        defaultZoom={11}
        zoom={4}
        distanceToMouse={distanceToMouse}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={async ({ map, maps }) => {
          // intialize global map let
          handleApiLoaded(map, maps);
        }}
        options={mapOptions}></GoogleMapReact>
    )
  );
};

const JobDetail = ({
  jobDetails,
  onCloseJob,
  onApplyStateHandler,
  onChangeHandle,
  show2,
  handleClose2,
  fileSizeValid,
  resumeSelect,
  applyForm,
  submitHandler,
  isChecked,
  setIsChecked,
  handleClose3,
  show3,
  jobId,
  onRecommendClick,
  setJobDetailsVisible,
  applied
}) => {
  const userID = useSelector((state) => state?.user?.details?.id);
  return (
    <div className="shorlist-job-card">
      <div className="shortlist-hd d-flex">
        <h3>{jobDetails?.job_title}</h3>
        <div className="d-flex flex-grow-1 justify-content-end">
          <button
            style={{
              background: '#424c55',
              color: '#ffffff',
              marginTop: ' -5px',
              padding: '7px',
              border: 'none'
            }}
            onClick={() => {
              onCloseJob(false);
            }}>
            <i className="fa fa-times"></i>
          </button>
        </div>
      </div>
      <div className="shortlist-cntnt-card">
        <Tab.Container id="left-tabs-example" defaultActiveKey="first">
          <div className="">
            <Nav variant="pills" className="d-flex w-100 justify-content-center m-3">
              <Nav.Item>
                <Nav.Link
                  style={{
                    background: '#404a53',
                    color: 'white',
                    padding: '7px',
                    borderRadius: '0px',
                    fontSize: '13px'
                  }}
                  eventKey="first">
                  Job Details
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  style={{
                    background: '#57636d',
                    color: 'white',
                    padding: '7px',
                    borderRadius: '0px',
                    fontSize: '13px'
                  }}
                  eventKey="second">
                  Your Referral
                </Nav.Link>
              </Nav.Item>
            </Nav>
            <Tab.Content>
              <Tab.Pane eventKey="first">
                <hr />
                <div className="short-job-outr">
                  <div
                    style={{ padding: '10px 15px' }}
                    className="post-job-hd d-flex align-items-center">
                    <div style={{ padding: '10px' }} className="media_shorlist">
                      <img
                        style={{ height: '70px', width: '70px' }}
                        src={require('../../../assets/images/hall-ticketcompany-logo-default.png')}
                        alt="shortlist"
                      />
                    </div>
                    <div className="short-job-dis">
                      <h4>Company:{jobDetails?.company_name} </h4>

                      <p>
                        Posted By: <span>{jobDetails?.user?.first_name}</span>
                      </p>
                      <p>
                        Posted On: <span>{jobDetails?.created_on}</span>
                      </p>
                    </div>
                  </div>
                  <div className="short-lst-cntct">
                    <div className="d-flex justify-content-between">
                      {jobDetails?.city_name && (
                        <div
                          style={{ flex: 1 }}
                          className="d-flex justify-content-start align-items-center">
                          <div className="d-flex justify-content-start align-items-center">
                            <i className="fas fa-map-marker-alt me-2"></i>
                            <span>{jobDetails?.city_name}</span>
                          </div>
                        </div>
                      )}
                      {jobDetails?.contact_number && (
                        <div
                          style={{ flex: 1 }}
                          className="d-flex justify-content-start align-items-center">
                          <div className="d-flex justify-content-start align-items-center">
                            <i className="fas fa-phone me-2"></i>
                            <span>{jobDetails?.contact_number}</span>
                          </div>
                        </div>
                      )}
                      {jobDetails?.hr_email && (
                        <div
                          style={{ flex: 1 }}
                          className="d-flex justify-content-start align-items-center">
                          <div className="d-flex justify-content-start align-items-center">
                            <i className="fas fa-envelope me-2"></i>
                            <span>{jobDetails?.hr_email}</span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="expec-card">
                    <ul>
                      <li>
                        <h4>
                          {jobDetails?.min_exp}-{jobDetails?.max_exp} (yrs)
                        </h4>
                        <p>Experience</p>
                      </li>
                      <li>
                        <h4>
                          {jobDetails?.min_ctc}-{jobDetails?.max_ctc} (Lacs)
                        </h4>
                        <p>Package</p>
                      </li>
                      <li>
                        <h4>{jobDetails?.open_positions}</h4>
                        <p>Openings</p>
                      </li>
                    </ul>
                  </div>
                  <div className="short-disc">
                    <h3>{jobDetails?.job_title}</h3>
                    <p dangerouslySetInnerHTML={{ __html: jobDetails?.job_info }}></p>
                    {jobDetails?.key_skills[0] !== null && (
                      <>
                        {' '}
                        <h5
                          style={{
                            color: '#404040',
                            fontSize: '16px',
                            // fontFamily: 'roboto-light',
                            fontWeight: 700
                          }}>
                          Key Skills
                        </h5>
                        <div>
                          {jobDetails?.key_skills?.map((item) => {
                            // console.log('item', item);
                            return (
                              <div
                                style={{
                                  background: '#ececec',
                                  color: '#404040',
                                  padding: '5px 10px',
                                  marginRight: '10px',
                                  fontSize: '13px',
                                  display: ' inline-block',
                                  marginTop: '5px'
                                }}>
                                <span>{item.name}</span>
                              </div>
                            );
                          })}
                        </div>{' '}
                      </>
                    )}
                  </div>
                </div>
              </Tab.Pane>

              <Tab.Pane eventKey="second">
                <div style={{ background: '#e2e2e2', padding: '10px 12px' }}>
                  <span>
                    {' '}
                    At the moment, we can't find any referral for this job. Please check back later.{' '}
                  </span>
                </div>
              </Tab.Pane>
            </Tab.Content>
          </div>
        </Tab.Container>
      </div>
      <div className="short-ftr-btn">
        <ul>
          {jobDetails?.is_applied ? (
            <>
              {jobDetails.userid !== userID && (
                <li
                  style={{
                    backgroundColor: '#fbae38',
                    padding: '12px 0',
                    fontSize: '14px',
                    textAlign: 'center'
                  }}>
                  <Link to="">
                    <button
                      disabled
                      style={{
                        background: 'inherit',
                        border: 'none',
                        color: 'white',
                        textTransform: 'uppercase',
                        fontWeight: 'bold'
                      }}>
                      <i className="fa fa-check-circle me-2"></i>
                      Already applied
                    </button>
                  </Link>
                </li>
              )}
            </>
          ) : (
            // <button>
            // </button>
            // <button>
            <>
              {jobDetails?.userid !== userID && (
                <li
                  style={{
                    backgroundColor: '#fbae38',
                    padding: '12px 0',
                    fontSize: '14px',
                    textAlign: 'center'
                  }}>
                  <Link to="">
                    <button
                      onClick={() => {
                        onApplyStateHandler(jobDetails);
                      }}
                      style={{
                        background: 'inherit',
                        border: 'none',
                        color: 'white',
                        textTransform: 'uppercase',
                        fontWeight: 'bold'
                      }}>
                      <i className="fa fa-check-circle me-2"></i>
                      Apply Now
                    </button>
                  </Link>
                </li>
              )}
            </>
            // </button>
          )}

          <li
            style={{
              backgroundColor: '#2b4d71',
              padding: '12px 0',
              fontSize: '14px',
              textAlign: 'center'
            }}>
            <Link to="">
              <button
                onClick={(e) => {
                  onRecommendClick(e, jobDetails?.id);
                }}
                style={{
                  background: 'inherit',
                  border: 'none',
                  color: 'white',
                  textTransform: 'uppercase',
                  fontWeight: 'bold'
                }}>
                <i className="fa fa-thumbs-up me-2"></i>
                Recommend
              </button>
            </Link>
          </li>
        </ul>
      </div>
      <RecomendModal handleClose3={handleClose3} show3={show3} jobId={jobId} />
      <ApplyForm
        onChangeHandler={onChangeHandle}
        setIsChecked={setIsChecked}
        isChecked={isChecked}
        submitHandler={submitHandler}
        applyForm={applyForm}
        show2={show2}
        handleClose2={handleClose2}
        jobTitle={jobDetails?.job_title}
        resumeSelect={resumeSelect}
        fileSizeValid={fileSizeValid}
        getUserDetail={getUserDetail}
      />
    </div>
  );
};
