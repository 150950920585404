import { useEffect, useRef, useState } from 'react';
import { Button, Col, Dropdown, Row, Spinner } from 'react-bootstrap';
import CloseButton from 'react-bootstrap/CloseButton';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import docImg from '../../../../assets/images/doc1.png';
import odsImg from '../../../../assets/images/ods.png';
import odtImg from '../../../../assets/images/odt.png';
import pdfImg from '../../../../assets/images/pdf1.png';
import pptImg from '../../../../assets/images/ppt.png';
import xlsImg from '../../../../assets/images/xls.png';
import showNotification from '../../../../services/NotificationService';
import * as APISERVICES from '../../../../services/SocialServices';
import { addFriend } from '../../../../services/UserServices';
import NoDataFound from '../../../commoncomponents/NoDataFound';
import { truncate } from '../../../../globals/helper';
import OneEvent from './OneEvent';
let $ = require('jquery');

// import defaultImage from '../../../../assets/images/default-male-avatar.png';

function GroupPostLayout(props) {
  /* eslint-disable */
  const {
    checkPost,
    getPostListData,
    totalPages,
    onPostListData,
    pageNum,
    isPostLoading,
    onIncrementPageNo,
    setCheckPost,
    type,
    deleteFile
  } = props;

  const [checkComment, setCheckComment] = useState([]);
  const [checkLike, setCheckLike] = useState([]);
  const [checkFollow, setCheckFollow] = useState();

  const [postComment, setPostComment] = useState([]);
  const [modalComment, setModalComment] = useState('');
  const [modalImage, setModalImage] = useState([]);
  const [clickedImage, setClickedImage] = useState();

  const userDetail = useSelector((state) => state?.user?.details);

  const [show, setShow] = useState(false);
  const [showComment, setShowComment] = useState([]);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const [postId, setPostId] = useState('');
  const [loading, setLoading] = useState(false);
  const [commentId, setCommentId] = useState('');
  const [shareList, setShareList] = useState([]);
  const [peopleLikedData, setPeopleLikedData] = useState([]);

  const [showDelete, setShowDelete] = useState(false);
  const [singlePostId, setSinglePostId] = useState('');
  const [loadingState, setLoadingState] = useState('loading');
  const userId = useParams();
  useEffect(() => {
    if (postId !== '') {
      getPostSingleDetail(postId);
    }

    // return () => {
    //   getStoryList();
    // };
  }, []);

  const handleShow2 = (data, id) => {
    setShow2(true);

    setClickedImage(id);
    // setModalImage(data);
    getPostSingleDetail(data?.id);
    setPostId(data?.id);
  };
  const getPostSingleDetail = (id) => {
    setLoading(true);
    APISERVICES.getSinglePostDetail(id).then((res) => {
      if (res?.status === 200) {
        setLoading(false);
        setModalImage(res?.data?.data);
      } else {
        setLoading(false);
      }
    });
  };
  const getComment = (id, e) => {
    e.preventDefault();
    APISERVICES.getPostComment(id).then((res) => {
      if (res?.status === 200) {
        // getPostSingleDetail(postId ? postId : id);
        setCheckComment(res?.data?.data);
        // getPostListData();
      }
    });
  };
  const getPostLike = (e, id, type) => {
    setLoadingState('');
    e.preventDefault();

    APISERVICES.getLikeUnlike(id).then((res) => {
      if (res?.status === 200) {
        showNotification('success', res?.data?.message);
        setCheckLike(res?.data?.data);
        if (type == 'modal') {
          getPostSingleDetail(postId);
        }
        getPostListData(true);
      }
    });
  };

  const getCommentLike = (e, id, type) => {
    setLoadingState('');
    e.preventDefault();
    APISERVICES.getLikeUnlike(id).then((res) => {
      if (res?.status === 200) {
        showNotification('success', res?.data?.message);
        setCheckLike(res?.data?.data);
        getPostListData(true);
        if (type == 'modal') {
          getPostSingleDetail(postId);
        }
      }
    });
  };

  const deletedPost = () => {
    setLoadingState('');
    APISERVICES.deletePost(singlePostId).then((res) => {
      if (res?.status === 200) {
        showNotification('success', res?.data?.message);
        setShowDelete(false);
        let filterData = checkPost?.filter((data) => data?.id !== singlePostId);
        setCheckPost(filterData);
        // getPostListData('delete');
      }
    });
  };

  const deleteComment = (e, id) => {
    setLoadingState('');
    e.preventDefault();
    if (confirm('Do you want to delete your comment ?') == true) {
      APISERVICES.deletePost(id).then((res) => {
        if (res?.status === 200) {
          showNotification('success', res?.data?.message);
          getPostListData(true);
        }
      });
    } else {
      return;
    }
  };

  const isPostcomment = (id, type, e) => {
    setLoadingState('');
    e.preventDefault();
    APISERVICES.postComment(id, {
      text: type == 1 ? postComment : modalComment,
      recipient_id: ''
    }).then((res) => {
      if (res?.status === 200) {
        showNotification('success', res?.data?.message);
        setPostComment('');
        setModalComment('');
        if (type == 0) {
          getPostSingleDetail(type == 1 ? id : postId);
        }

        getPostListData(true);
      }
    });
  };

  const handleReportPost = (e, id) => {
    setLoadingState('');
    e.preventDefault();
    APISERVICES.reportPost(id).then((resp) => {
      if (resp?.status === 200) {
        showNotification('success', resp?.data?.data);
        getPostListData(true);
      }
    });
  };

  const isFollowUnfollow = (e, id, type) => {
    setLoadingState('');
    e.preventDefault();
    APISERVICES.postFollowUnfollow(id).then((res) => {
      if (res?.status === 200) {
        showNotification('success', res?.data?.data);
        setCheckFollow('');
        getPostListData(true);
        if (type == 'modal') {
          getPostSingleDetail(postId);
        }
      }
    });
  };

  // intersection
  // const [pageNum,setPageNum] =  useState(0);
  const [lastElement, setLastElement] = useState(null);
  // const [totalPages ,setTotalPages] = useState(0)
  const observer = useRef(
    new IntersectionObserver((entries) => {
      const first = entries[0];
      if (first.isIntersecting) {
        onIncrementPageNo();
      }
    })
  );

  // useEffect(() => {
  //   if (pageNum <= totalPages) {
  //     // callUser();
  //     // api call
  //     onPostListData();
  //   }
  // }, [pageNum]);

  useEffect(() => {
    const currentElement = lastElement;
    const currentObserver = observer.current;

    if (currentElement) {
      setLoadingState('');
      currentObserver.observe(currentElement);
    }

    return () => {
      if (currentElement) {
        setLoadingState('');
        currentObserver.unobserve(currentElement);
      }
    };
  }, [lastElement]);

  // const handleCommentChange = (e) => {
  //   const { name, value } = e.target;
  //   setPostComment({ ...postComment, [name]: value });
  // };
  const handlePostShare = (e, data) => {
    e.preventDefault();
    setLoadingState('');
    if (data?.via_type == '') {
      APISERVICES.sharePost(data?.id).then((res) => {
        if (res?.status === 200) {
          getPostListData('share');
          showNotification('success', 'Post has been shared successfully');
        }
      });
    } else {
      APISERVICES.shareSharedPost(data?.id).then((res) => {
        if (res?.status === 200) {
          getPostListData('share');
          showNotification('success', res?.data?.message);
        }
      });
    }
  };

  const handleShowlike = (e, pageId) => {
    setPostId(pageId);
    e.preventDefault();
    handleShow();
    setLoading(true);
    APISERVICES.getLikedByList(pageId).then((res) => {
      if (res?.status === 200) {
        setPeopleLikedData(res?.data?.data?.like_data);
        setLoading(false);
      } else {
        setLoading(false);
      }
    });
  };

  const resquestHandler = (e, id, type) => {
    e.preventDefault();
    setLoading(true);
    if (type == 'add') {
      let body = {
        following_id: id
      };
      addFriend(body).then((res) => {
        setLoading(false);
        if (res?.status == 200) {
          handleShowlike(e, postId);
        } else {
          showNotification('success', res?.data?.message);
        }
      });
    }

    if (type == 'remove') {
      if (confirm('Are you sure you want to unfriend?') === true) {
        let body = {
          following_id: id
        };
        addFriend(body).then((res) => {
          setLoading(false);
          if (res?.status == 200) {
            handleShowlike(e, postId);
          } else {
            showNotification('success', res?.data?.message);
          }
        });
      }
    }
    if (type == 'requested') {
      if (confirm('Do you want to remove request?') === true) {
        let body = {
          following_id: id
        };
        addFriend(body).then((res) => {
          setLoading(false);
          if (res?.status == 200) {
            handleShowlike(e, postId);
          } else {
            showNotification('success', res?.data?.message);
          }
        });
      }
    }
    // else {

    //   let body = {
    //     userData: id
    //   };
    //   removeFriend(body).then((res) => {
    //     setLoading(false);
    //     if (res?.status == 200) {
    //       handleShowlike(e, postId);
    //     } else {
    //       showNotification('success', res?.data?.message);
    //     }
    //   });
    // }
  };

  return (
    <>
      {isPostLoading ? (
        <>
          {' '}
          <div className="pofile-upload-card loading-skeleton profile-upload-loader">
            <div className="profile-upload-sec d-flex align-items-top mb-3">
              <Link to="/user/timeline/undefined">
                <span className="social-profile-icn me-3">
                  <img src={require('../../../../assets/images/birth4.png')} alt="img" />
                </span>
              </Link>
              <div className="social-profile-detail d-flex align-items-top justify-content-between ">
                <div className="social-profile-name">
                  <h4>
                    <Link to="#">
                      Coordinator<span>...</span>
                    </Link>
                    updated his profile picture
                  </h4>
                  <p>
                    <i className="far fa-clock me-2" />
                    42 minutes ago
                    <i className="fas fa-users ms-2" title="shared with everyone" />
                  </p>
                </div>
                <div className="drop-ellipse-actn">
                  <div className="ellipse-drop-card ms-3 dropdown">
                    <button
                      type="button"
                      id="dropdown-basic"
                      aria-expanded="false"
                      className="dropdown-toggle btn btn-success">
                      <i className="fas fa-ellipsis-v" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="profile-top-dis" />
            <div className="post_img d-flex flex-wrap">
              <Link className="collage_card" id="img1" to="#">
                <img src={require('../../../../assets/images/birth4.png')} alt="img" />
              </Link>
            </div>
            <div className="profile-upload-ftr">
              <div className="profile-likes-count d-flex align-items-center justify-content-between">
                <div className="likes-count">
                  <Link to="#">1 Likes</Link>
                  <Link to="#">0 Shares</Link>
                </div>
                <div className="cmmnts-count">1 Comments</div>
              </div>
              <div className="profile-like-btns">
                <ul>
                  <li>
                    <Link title="Like" href="#">
                      <i className="fas fa-thumbs-up me-2" />
                      Like
                    </Link>
                  </li>
                  <li>
                    <Link title="Comments" to="#">
                      <i className="fas fa-comments me-2" />
                      Comment
                    </Link>
                  </li>
                  <li>
                    <Link title="Share" to="#">
                      <i className="fas fa-share me-2" />
                      Share
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="profile-upload-cmmnt mt-3">
              <div className="profile-cmmnt-lst d-flex align-items-top mb-3">
                <span className="social-profile-icn me-3">
                  <img src={require('../../../../assets/images/birth4.png')} alt="img" />
                </span>
                <div className="profile-cmmnt-user ">
                  <div className="d-flex align-items-top justify-content-between">
                    <Link to="#">Coordinator</Link>
                    <div className="profile-cmmnt-time d-flex align-items-center ">
                      <span />
                      <div className="ellipse-drop-card ms-3 dropdown">
                        <button
                          type="button"
                          id="dropdown-basic"
                          aria-expanded="false"
                          className="dropdown-toggle btn btn-success">
                          <i className="fas fa-ellipsis-v" />
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="profile-cmmnt-dis">
                    <p>ffffff</p>
                    <div className="cmmnt-likes d-flex align-items-center">
                      <Link to="">
                        <i className="fas fa-thumbs-up me-1" />
                        Like <span>1</span>
                        likes
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="pofile-upload-card loading-skeleton profile-upload-loader">
            <div className="profile-upload-sec d-flex align-items-top mb-3">
              <Link to="/user/timeline/undefined">
                <span className="social-profile-icn me-3">
                  <img src={require('../../../../assets/images/birth4.png')} alt="img" />
                </span>
              </Link>
              <div className="social-profile-detail d-flex align-items-top justify-content-between ">
                <div className="social-profile-name">
                  <h4>
                    <Link to="#">
                      Coordinator<span>...</span>
                    </Link>
                    updated his profile picture
                  </h4>
                  <p>
                    <i className="far fa-clock me-2" />
                    42 minutes ago
                    <i className="fas fa-users ms-2" title="shared with everyone" />
                  </p>
                </div>
                <div className="drop-ellipse-actn">
                  <div className="ellipse-drop-card ms-3 dropdown">
                    <button
                      type="button"
                      id="dropdown-basic"
                      aria-expanded="false"
                      className="dropdown-toggle btn btn-success">
                      <i className="fas fa-ellipsis-v" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="profile-top-dis">
              <p>hlo this is post </p>
            </div>
            <div className="profile-upload-ftr">
              <div className="profile-likes-count d-flex align-items-center justify-content-between">
                <div className="likes-count">
                  <Link to="#">1 Likes</Link>
                  <Link to="#">0 Shares</Link>
                </div>
                <div className="cmmnts-count">1 Comments</div>
              </div>
              <div className="profile-like-btns">
                <ul>
                  <li>
                    <Link title="Like" href="#">
                      <i className="fas fa-thumbs-up me-2" />
                      Like
                    </Link>
                  </li>
                  <li>
                    <Link title="Comments" to="#">
                      <i className="fas fa-comments me-2" />
                      Comment
                    </Link>
                  </li>
                  <li>
                    <Link title="Share" to="#">
                      <i className="fas fa-share me-2" />
                      Share
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="profile-upload-cmmnt mt-3">
              <div className="profile-cmmnt-lst d-flex align-items-top mb-3">
                <span className="social-profile-icn me-3">
                  <img src={require('../../../../assets/images/birth4.png')} alt="img" />
                </span>
                <div className="profile-cmmnt-user ">
                  <div className="d-flex align-items-top justify-content-between">
                    <Link to="#">Coordinator</Link>
                    <div className="profile-cmmnt-time d-flex align-items-center ">
                      <span />
                      <div className="ellipse-drop-card ms-3 dropdown">
                        <button
                          type="button"
                          id="dropdown-basic"
                          aria-expanded="false"
                          className="dropdown-toggle btn btn-success">
                          <i className="fas fa-ellipsis-v" />
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="profile-cmmnt-dis">
                    <p>ffffff</p>
                    <div className="cmmnt-likes d-flex align-items-center">
                      <Link to="">
                        <i className="fas fa-thumbs-up me-1" />
                        Like <span>1</span>
                        likes
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="pofile-upload-card loading-skeleton profile-upload-loader">
            <div className="profile-upload-sec d-flex align-items-top mb-3">
              <Link to="/user/timeline/undefined">
                <span className="social-profile-icn me-3">
                  <img src={require('../../../../assets/images/birth4.png')} alt="img" />
                </span>
              </Link>
              <div className="social-profile-detail d-flex align-items-top justify-content-between ">
                <div className="social-profile-name">
                  <h4>
                    <Link to="#">
                      Coordinator<span>...</span>
                    </Link>
                    updated his profile picture
                  </h4>
                  <p>
                    <i className="far fa-clock me-2" />
                    42 minutes ago
                    <i className="fas fa-users ms-2" title="shared with everyone" />
                  </p>
                </div>
                <div className="drop-ellipse-actn">
                  <div className="ellipse-drop-card ms-3 dropdown">
                    <button
                      type="button"
                      id="dropdown-basic"
                      aria-expanded="false"
                      className="dropdown-toggle btn btn-success">
                      <i className="fas fa-ellipsis-v" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="profile-top-dis">
              <p>hlo this is post </p>
            </div>
            <div className="profile-upload-ftr">
              <div className="profile-likes-count d-flex align-items-center justify-content-between">
                <div className="likes-count">
                  <Link to="#">1 Likes</Link>
                  <Link to="#">0 Shares</Link>
                </div>
                <div className="cmmnts-count">1 Comments</div>
              </div>
              <div className="profile-like-btns">
                <ul>
                  <li>
                    <Link title="Like" href="#">
                      <i className="fas fa-thumbs-up me-2" />
                      Like
                    </Link>
                  </li>
                  <li>
                    <Link title="Comments" to="#">
                      <i className="fas fa-comments me-2" />
                      Comment
                    </Link>
                  </li>
                  <li>
                    <Link title="Share" to="#">
                      <i className="fas fa-share me-2" />
                      Share
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="profile-upload-cmmnt mt-3">
              <div className="profile-cmmnt-lst d-flex align-items-top mb-3">
                <span className="social-profile-icn me-3">
                  <img src={require('../../../../assets/images/birth4.png')} alt="img" />
                </span>
                <div className="profile-cmmnt-user ">
                  <div className="d-flex align-items-top justify-content-between">
                    <Link to="#">Coordinator</Link>
                    <div className="profile-cmmnt-time d-flex align-items-center ">
                      <span />
                      <div className="ellipse-drop-card ms-3 dropdown">
                        <button
                          type="button"
                          id="dropdown-basic"
                          aria-expanded="false"
                          className="dropdown-toggle btn btn-success">
                          <i className="fas fa-ellipsis-v" />
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="profile-cmmnt-dis">
                    <p>ffffff</p>
                    <div className="cmmnt-likes d-flex align-items-center">
                      <Link to="">
                        <i className="fas fa-thumbs-up me-1" />
                        Like <span>1</span>
                        likes
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <> </>
      )}
      {checkPost?.length > 0 && !isPostLoading ? (
        checkPost?.map((data, index) => {
          return (
            <>
              <div className="pofile-upload-card" key={index} ref={lastElement}>
                <div className="profile-upload-sec d-flex align-items-top mb-3">
                  <Link to={`/user/timeline/${data?.publisher?.encrypted_id}`}>
                    <span className="social-profile-icn me-3">
                      <img
                        alt="img"
                        src={
                          data?.publisher?.mediaObject
                            ? data?.publisher?.mediaObject?.path +
                              '.' +
                              data?.publisher?.mediaObject?.extension
                            : require('../../../../assets/images/birth4.png')
                        }
                      />
                    </span>
                  </Link>
                  <div className="social-profile-detail d-flex align-items-top justify-content-between ">
                    <div className="social-profile-name">
                      <h4 className="d-flex">
                        <Link to={`/user/timeline/${data?.publisher?.encrypted_id}`}>
                          <p title={data?.publisher?.name}>{data?.publisher?.name}</p>
                        </Link>
                        <i
                          style={{ color: '#929292' }}
                          className="fas fa-long-arrow-alt-right me-1 ms-1"></i>
                        <Link>
                          <p title={data?.recipient?.first_name}>{data?.recipient?.first_name}</p>
                        </Link>
                        {data?.activity_text == '' ? (
                          <>
                            {data?.is_share ? (
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: data?.is_share
                                }}
                              />
                            ) : (
                              ''
                            )}
                          </>
                        ) : (
                          <span
                            dangerouslySetInnerHTML={{
                              __html: data?.activity_text
                            }}
                          />
                        )}
                      </h4>
                      <p>
                        <i className="far fa-clock me-2"></i>
                        {/* {moment(data?.timestamp).fromNow()} */}
                        {data?.timestamp}

                        {/* <i className="fas fa-users ms-2" title="shared with everyone"></i> */}
                      </p>
                    </div>
                    <div className="drop-ellipse-actn">
                      <Dropdown className="ellipse-drop-card ms-3">
                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                          <i className="fas fa-ellipsis-v"></i>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item
                            onClick={(e) => {
                              setSinglePostId(data?.id);
                              setShowDelete(true);
                            }}>
                            Delete Post
                          </Dropdown.Item>

                          {data?.followed ? (
                            <Dropdown.Item
                              href="#/action-1"
                              onClick={(e) => {
                                isFollowUnfollow(e, data?.id);
                              }}>
                              Unfollow this post
                            </Dropdown.Item>
                          ) : (
                            <Dropdown.Item
                              href="#/action-1"
                              onClick={(e) => {
                                isFollowUnfollow(e, data?.id);
                              }}>
                              Follow this post
                            </Dropdown.Item>
                          )}
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </div>
                </div>
                {/* "profile-top-dis */}

                {(data?.text !== null || data?.text == '') && (
                  <div
                    className="profile-top-dis"
                    dangerouslySetInnerHTML={{
                      __html: data?.text
                    }}></div>
                )}

                {data?.shared_content_type == 'event' && (
                  <>
                    <OneEvent data={data} getPostListData={getPostListData} />
                  </>
                )}

                {data?.images_path?.length !== 0 && (
                  <div className="post_img d-flex flex-wrap">
                    {data?.images_path?.map((image, index) => (
                      <Link
                        className="collage_card"
                        id="img1"
                        onClick={() => handleShow2(data, index)}
                        key={index}>
                        {' '}
                        <img src={image} alt="img" />
                      </Link>
                    ))}
                  </div>
                )}

                {data?.files?.length !== 0 && (
                  <div className="post_img d-flex flex-wrap">
                    {data?.files?.map((files, index) => {
                      const pdfFile = files?.file_name?.includes('pdf');
                      const spreadSheetFile =
                        files?.file_name?.includes('xlsx') || files?.file_name?.includes('xls');
                      const odtFile = files?.file_name?.includes('odt');
                      const odsFile = files?.file_name?.includes('ods');
                      const docFile =
                        files?.file_name?.includes('doc') || files?.file_name?.includes('docx');
                      const pptFile =
                        files?.file_name?.includes('ppt') || files?.file_name?.includes('pptx');
                      if (pdfFile) {
                        return (
                          <div className="pdffile_card">
                            <img src={pdfImg} alt="" />
                            <div className="overlay">
                              <p className="ng-binding">{truncate(files?.file_name, 10)}</p>
                              <div
                                className={
                                  data?.files?.length > 1
                                    ? 'd-flex justify-content-between bottomrow'
                                    : 'd-flex justify-content-center bottomrow'
                                }>
                                <a href={files?.file_path} download>
                                  <i className="fa fa-download"></i>
                                </a>
                                {data?.files?.length > 1 &&
                                  data?.publisher?.encrypted_id === userDetail?.encrypted_id && (
                                    <a
                                      className="pull-right ng-scope"
                                      onClick={() => {
                                        deleteFile(files?.post_id, files?.media_id);
                                      }}
                                      href={`javascript:void(0)`}>
                                      <i className="fa fa-trash progress-icon"></i>
                                    </a>
                                  )}
                              </div>
                            </div>
                          </div>
                        );
                      } else if (spreadSheetFile) {
                        return (
                          <div className="pdffile_card">
                            <img src={xlsImg} alt="" />
                            <div className="overlay">
                              <p className="ng-binding">{truncate(files?.file_name, 10)}</p>
                              <div
                                className={
                                  data?.files?.length > 1
                                    ? 'd-flex justify-content-between bottomrow'
                                    : 'd-flex justify-content-center bottomrow'
                                }>
                                <a href={files?.file_path} download>
                                  <i className="fa fa-download"></i>
                                </a>
                                {data?.files?.length > 1 &&
                                  data?.publisher?.encrypted_id === userDetail?.encrypted_id && (
                                    <a
                                      className="pull-right ng-scope"
                                      onClick={() => {
                                        deleteFile(files?.post_id, files?.media_id);
                                      }}
                                      href={`javascript:void(0)`}>
                                      <i className="fa fa-trash progress-icon"></i>
                                    </a>
                                  )}
                              </div>
                            </div>
                          </div>
                        );
                      } else if (docFile) {
                        return (
                          <div className="pdffile_card">
                            <img src={docImg} alt="" />
                            <div className="overlay">
                              <p className="ng-binding">{truncate(files?.file_name, 10)}</p>
                              <div
                                className={
                                  data?.files?.length > 1
                                    ? 'd-flex justify-content-between bottomrow'
                                    : 'd-flex justify-content-center bottomrow'
                                }>
                                <a href={files?.file_path} download>
                                  <i className="fa fa-download"></i>
                                </a>
                                {data?.files?.length > 1 &&
                                  data?.publisher?.encrypted_id === userDetail?.encrypted_id && (
                                    <a
                                      className="pull-right ng-scope"
                                      onClick={() => {
                                        deleteFile(files?.post_id, files?.media_id);
                                      }}
                                      href={`javascript:void(0)`}>
                                      <i className="fa fa-trash progress-icon"></i>
                                    </a>
                                  )}
                              </div>
                            </div>
                          </div>
                        );
                      } else if (pptFile) {
                        return (
                          <div className="pdffile_card">
                            <img src={pptImg} alt="" />
                            <div className="overlay">
                              <p className="ng-binding">{truncate(files?.file_name, 10)}</p>
                              <div
                                className={
                                  data?.files?.length > 1
                                    ? 'd-flex justify-content-between bottomrow'
                                    : 'd-flex justify-content-center bottomrow'
                                }>
                                <a href={files?.file_path} download>
                                  <i className="fa fa-download"></i>
                                </a>
                                {data?.files?.length > 1 &&
                                  data?.publisher?.encrypted_id === userDetail?.encrypted_id && (
                                    <a
                                      className="pull-right ng-scope"
                                      onClick={() => {
                                        deleteFile(files?.post_id, files?.media_id);
                                      }}
                                      href={`javascript:void(0)`}>
                                      <i className="fa fa-trash progress-icon"></i>
                                    </a>
                                  )}
                              </div>
                            </div>
                          </div>
                        );
                      } else if (odsFile) {
                        return (
                          <div className="pdffile_card">
                            <img src={odsImg} alt="" />
                            <div className="overlay">
                              <p className="ng-binding">{truncate(files?.file_name, 10)}</p>
                              <div
                                className={
                                  data?.files?.length > 1
                                    ? 'd-flex justify-content-between bottomrow'
                                    : 'd-flex justify-content-center bottomrow'
                                }>
                                <a href={files?.file_path} download>
                                  <i className="fa fa-download"></i>
                                </a>
                                {data?.files?.length > 1 &&
                                  data?.publisher?.encrypted_id === userDetail?.encrypted_id && (
                                    <a
                                      className="pull-right ng-scope"
                                      onClick={() => {
                                        deleteFile(files?.post_id, files?.media_id);
                                      }}
                                      href={`javascript:void(0)`}>
                                      <i className="fa fa-trash progress-icon"></i>
                                    </a>
                                  )}
                              </div>
                            </div>
                          </div>
                        );
                      } else if (odtFile) {
                        return (
                          <div className="pdffile_card">
                            <img src={odtImg} alt="" />
                            <div className="overlay">
                              <p className="ng-binding">{truncate(files?.file_name, 10)}</p>
                              <div
                                className={
                                  data?.files?.length > 1
                                    ? 'd-flex justify-content-between bottomrow'
                                    : 'd-flex justify-content-center bottomrow'
                                }>
                                <a href={files?.file_path} download>
                                  <i className="fa fa-download"></i>
                                </a>
                                {data?.files?.length > 1 &&
                                  data?.publisher?.encrypted_id === userDetail?.encrypted_id && (
                                    <a
                                      className="pull-right ng-scope"
                                      onClick={() => {
                                        deleteFile(files?.post_id, files?.media_id);
                                      }}
                                      href={`javascript:void(0)`}>
                                      <i className="fa fa-trash progress-icon"></i>
                                    </a>
                                  )}
                              </div>
                            </div>
                          </div>
                        );
                      }
                    })}
                  </div>
                )}

                <div className="profile-upload-ftr">
                  <div className="profile-likes-count d-flex align-items-center justify-content-between">
                    <div className="likes-count">
                      <Link onClick={(e) => handleShowlike(e, data?.id)}>
                        {data?.likes_count} Likes
                      </Link>
                      {data?.via_type == '' && (
                        <Link to="#" onClick={handleShow1}>
                          {Number(data?.shares_count) > 1
                            ? data?.shares_count + ' Shares'
                            : data?.shares_count + ' Share'}
                        </Link>
                      )}
                    </div>
                    <div className="cmmnts-count">{data?.comments?.comments_count} Comments</div>
                  </div>
                  <div className="profile-like-btns">
                    <ul>
                      <li>
                        <Link
                          to="#"
                          title="Like"
                          onClick={(e) => {
                            getPostLike(e, data.id);
                          }}>
                          <i className="fas fa-thumbs-up me-2"></i>
                          Like
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="#"
                          title="Comment"
                          onClick={(e) => {
                            getComment(data.id, e);
                            setShowComment([...showComment, data?.id]);
                          }}>
                          <i className="fas fa-comments me-2"></i>
                          Comment
                        </Link>
                      </li>
                      <li>
                        <Link
                          onClick={(e) => handlePostShare(e, data)}
                          title={data?.shared_by_current_user ? 'Unshare' : 'Share'}>
                          <i className="fas fa-share me-2"></i>
                          Share
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>

                {data?.comments?.data?.length !== 0 ? (
                  <div className="profile-upload-cmmnt mt-3">
                    <>
                      {data?.comments?.data?.map((commentData, index) => {
                        return (
                          <>
                            <div
                              className="profile-cmmnt-lst d-flex align-items-top mb-3"
                              key={index}>
                              <span className="social-profile-icn me-3">
                                <img
                                  src={
                                    commentData?.user_image
                                      ? commentData?.user_image
                                      : require('../../../../assets/images/default-male-avatar.png')
                                  }
                                  alt="img"
                                />
                              </span>

                              <div className="profile-cmmnt-user ">
                                <div className="d-flex align-items-top justify-content-between">
                                  <Link to="#">{commentData?.publisher?.name}</Link>
                                  <div className="d-flex align-items-center">
                                    <p className="mb-0">{commentData?.timestamp}</p>
                                    <div className="profile-cmmnt-time d-flex align-items-center ">
                                      <span>{commentData?.latest_comment?.timestamp}</span>
                                      <Dropdown className="ellipse-drop-card ms-3">
                                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                                          <i className="fas fa-ellipsis-v"></i>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                          {commentData?.timeline_id == userDetail?.id ? (
                                            <Dropdown.Item
                                              href="#/action-1"
                                              onClick={(e) => {
                                                deleteComment(e, commentData?.id);
                                              }}>
                                              Delete comment
                                            </Dropdown.Item>
                                          ) : (
                                            <>no comments</>
                                          )}
                                        </Dropdown.Menu>
                                      </Dropdown>
                                    </div>
                                  </div>
                                </div>
                                <div className="profile-cmmnt-dis">
                                  <div className="d-flex justify-content-between">
                                    <p>{commentData?.text}</p>
                                  </div>
                                  <div className="cmmnt-likes d-flex align-items-center">
                                    <Link
                                      className="me-5"
                                      to="#"
                                      onClick={(e) => {
                                        getCommentLike(e, commentData?.id);
                                      }}>
                                      <i className="fas fa-thumbs-up me-1"></i>Like{' '}
                                    </Link>
                                    <span
                                      // id={'like_counts_' + data?.id}
                                      // is_self_like={true ? true : false}
                                      className="me-2">
                                      {commentData?.likeCount}
                                    </span>
                                    <span>likes</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        );
                      })}
                    </>
                  </div>
                ) : (
                  <></>
                )}

                {showComment?.includes(data?.id) ? (
                  <div className="profile-upload-cmmnt mt-3">
                    <div className="profile-cmmnt-input d-flex align-items-top">
                      <span className="social-profile-icn me-3">
                        <img
                          src={
                            userDetail?.avtar
                              ? process.env.REACT_APP_API_DOMAIN + '/' + userDetail?.avtar
                              : require('../../../../assets/images/default-male-avatar.png')
                          }
                          alt="img"
                        />
                      </span>
                      <div className="cmmnt-card">
                        <Form.Control
                          type="text"
                          onChange={(e) => {
                            setPostComment(e.target.value);
                            setCommentId(e.target.name);
                          }}
                          name={data?.id}
                          // onChange={handleCommentChange}
                          onKeyPress={(e) => {
                            e.key == 'Enter' ? isPostcomment(data?.id, 1, e) : '';
                          }}
                          value={data?.id == commentId ? postComment : ''}
                          placeholder="Write a comment... Press Enter to post"
                        />
                      </div>
                    </div>
                  </div>
                ) : (
                  ''
                )}
              </div>
              {/* modal for image view  */}
            </>
          );
        })
      ) : (
        <>
          {checkPost?.length == 0 && !isPostLoading && (
            <NoDataFound text={type !== 'all' && 'No Videos/Photos to show at the moment'} />
          )}
        </>
      )}
      {isPostLoading && (
        <div className="text-center">
          <Spinner animation="border" variant="secondary" className="text-center" />
        </div>
      )}
      {/* {
            <Spinner animation="border" variant="secondary"/>
        } */}
      <Modal show={show} onHide={handleClose} className="likes-modal">
        <Modal.Body className="modal-lst">
          {loading ? (
            <>
              <div className="loader-modal">
                <img src={require('../../../../assets/images/Loading_icon-small.gif')} alt="img" />
              </div>
            </>
          ) : (
            <>
              {' '}
              <div className="modal-hd d-flex align-items-center justify-content-between">
                <h4>
                  <i className="fas fa-share me-2"></i> People who liked this{' '}
                </h4>
                <CloseButton onClick={handleClose} />
              </div>
              <div className="likes-user-lst">
                {peopleLikedData?.length !== 0 ? (
                  <ul>
                    {peopleLikedData?.map((peopleData, index) => {
                      return (
                        <li key={index}>
                          <div className="lst-user-card d-flex align-items-center justify-content-between">
                            <div className="user-like-card d-flex align-items-center">
                              <span className="like-user-icn me-2">
                                <img
                                  src={
                                    peopleData?.mediaObject
                                      ? peopleData?.mediaObject?.path +
                                        '.' +
                                        peopleData?.mediaObject?.extension
                                      : require('../../../../assets/images/default-male-avatar.png')
                                  }
                                  alt="img"
                                />
                              </span>
                              <h4>
                                <Link to={`/user/timeline/${peopleData?.encrypted_id}`}>
                                  {peopleData?.name}
                                </Link>
                              </h4>
                            </div>
                            {peopleData?.id !== userDetail?.id && (
                              <>
                                {peopleData?.unfollow && (
                                  <Link
                                    className="wht-btn-grey "
                                    to=""
                                    onClick={(e) => resquestHandler(e, peopleData?.id, 'remove')}>
                                    Unfriend
                                  </Link>
                                )}
                                {peopleData?.requested && (
                                  <Link
                                    className="wht-btn-grey "
                                    to=""
                                    onClick={(e) =>
                                      resquestHandler(e, peopleData?.id, 'requested')
                                    }>
                                    Requested
                                  </Link>
                                )}
                                {peopleData?.follow == 1 && (
                                  <Link
                                    className="wht-btn-grey "
                                    to=""
                                    onClick={(e) => resquestHandler(e, peopleData?.id, 'add')}>
                                    Add Friend
                                  </Link>
                                )}
                              </>
                            )}
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                ) : (
                  <>
                    <h4 className="justify-content-center">No liked yet!</h4>
                  </>
                )}
              </div>
            </>
          )}
        </Modal.Body>
      </Modal>
      <Modal
        show={show2}
        onHide={handleClose2}
        backdrop="static"
        keyboard={false}
        className="timeline-modal"
        size="xl">
        <Modal.Body>
          {loading ? (
            <div className="loader-modal">
              <img src={require('../../../../assets/images/Loading_icon-small.gif')} alt="img" />
            </div>
          ) : (
            <div className="timeline-sec">
              <Row className="m-0">
                <Col className="p-0" md={8} sm={8}>
                  <div className="time-line-card bg-blck">
                    <Swiper
                      initialSlide={clickedImage}
                      spaceBetween={50}
                      slidesPerView={1}
                      modules={[Navigation]}
                      navigation={true}>
                      {Array.isArray(modalImage?.files) ? (
                        <>
                          {modalImage?.files?.map((item, index) => {
                            return (
                              <SwiperSlide key={index}>
                                <div className="timeline-slide-images">
                                  <img src={item} alt="img" />
                                </div>
                              </SwiperSlide>
                            );
                          })}
                        </>
                      ) : (
                        <>
                          {' '}
                          <SwiperSlide>
                            <div className="timeline-slide-images">
                              <img src={modalImage?.files} alt="img" />
                            </div>
                          </SwiperSlide>
                        </>
                      )}
                    </Swiper>
                  </div>
                </Col>
                <Col className="p-0" md={4} sm={4}>
                  <div className="time-line-cmmnts">
                    <Link className="close-modal-btn" to="#" onClick={handleClose2}>
                      <i className="fas fa-times"></i>
                    </Link>
                    <div className="pofile-upload-card">
                      <div className="profile-upload-sec d-flex align-items-top mb-3">
                        <span className="social-profile-icn me-3">
                          <img
                            src={require('../../../../assets/images/default-male-avatar.png')}
                            alt="img"
                          />
                        </span>
                        <div className="social-profile-detail d-flex align-items-top justify-content-between ">
                          <div className="social-profile-name">
                            <h4>
                              <Link to="#">{modalImage?.user?.first_name}</Link> updated his profile
                              picture{' '}
                            </h4>
                            <p>
                              <i className="far fa-clock me-2"></i>
                              {modalImage?.timestamp}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="profile-upload-ftr">
                        <div className="profile-likes-count d-flex align-items-center justify-content-between">
                          <div className="likes-count">
                            <Link to="#">{modalImage?.likes} Likes</Link>
                            <Link to="#">{modalImage?.shares} Shares</Link>
                          </div>
                        </div>
                        <div className="profile-like-btns">
                          <ul>
                            <li>
                              <Link
                                to="#"
                                title="Like"
                                onClick={(e) => {
                                  getPostLike(e, modalImage?.id, 'modal');
                                }}>
                                <i className="fas fa-thumbs-up me-2"></i>Like
                              </Link>
                            </li>
                            <li>
                              <Link to="#" title="Share">
                                <i className="fas fa-share me-2"></i>Share
                              </Link>
                            </li>
                            <li>
                              {modalImage?.is_follow == 1 ? (
                                <Link
                                  to="#"
                                  title="follow this post"
                                  onClick={(e) => {
                                    isFollowUnfollow(e, modalImage?.id, 'modal');
                                  }}>
                                  Unfollow this post
                                </Link>
                              ) : (
                                <Link
                                  to="#"
                                  title="follow this post"
                                  onClick={(e) => {
                                    isFollowUnfollow(e, modalImage?.id, 'modal');
                                  }}>
                                  Follow this post
                                </Link>
                              )}
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="profile-upload-cmmnt mt-3">
                        {modalImage?.latest_comment?.length ? (
                          <>
                            {modalImage?.latest_comment?.map((data, index) => (
                              <>
                                <div
                                  className="profile-cmmnt-lst d-flex align-items-top mb-3"
                                  key={index}>
                                  <span className="social-profile-icn me-3">
                                    <img
                                      src={process.env.REACT_APP_API_DOMAIN + data?.user?.avtar}
                                      alt="img"
                                    />
                                  </span>
                                  <div className="profile-cmmnt-user ">
                                    <div className="d-flex align-items-top justify-content-between">
                                      <Link to="#">{data?.user?.first_name}</Link>

                                      <div className="profile-cmmnt-time d-flex align-items-center ">
                                        <span>{data?.latest_comment?.timestamp}Comment</span>
                                      </div>
                                    </div>
                                    <div className="profile-cmmnt-dis">
                                      <p>{data?.text}</p>
                                      <div className="cmmnt-likes d-flex align-items-center">
                                        <Link
                                          to="#"
                                          onClick={(e) => {
                                            getCommentLike(e, data?.id, 'modal');
                                          }}>
                                          <i className="fas fa-thumbs-up me-1"></i>
                                          {/* {data?.likes} */}
                                          {checkLike?.count}
                                        </Link>
                                        likes
                                      </div>
                                    </div>
                                  </div>
                                  {data?.user?.id === userDetail?.id ? (
                                    <Link
                                      className="close-btn"
                                      to="#"
                                      onClick={() => {
                                        deletedPost(data?.id);
                                      }}>
                                      <i className="fas fa-times"></i>
                                    </Link>
                                  ) : (
                                    <>
                                      {' '}
                                      <></>
                                    </>
                                  )}
                                </div>
                              </>
                            ))}
                          </>
                        ) : (
                          <></>
                        )}
                        <div className="profile-cmmnt-input d-flex align-items-top">
                          <span className="social-profile-icn me-3">
                            <img
                              src={process.env.REACT_APP_API_DOMAIN + modalImage?.user?.avtar}
                              alt="img"
                            />
                          </span>
                          <div className="cmmnt-card">
                            <Form.Control
                              type="text"
                              onChange={(e) => {
                                setModalComment(e.target.value);
                              }}
                              onKeyPress={(e) => {
                                e.key == 'Enter' ? isPostcomment(modalImage?.id, 0, e) : '';
                              }}
                              value={modalComment}
                              placeholder="Write a comment... Press Enter to post"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          )}
        </Modal.Body>
      </Modal>

      {/* Share Modal */}
      <Modal
        show={show1}
        onHide={handleClose1}
        backdrop="static"
        keyboard={false}
        className="likes-modal">
        <Modal.Body>
          <div className="modal-hd d-flex align-items-center justify-content-between">
            <h4>
              <i className="fas fa-share me-2"></i> People who shared this
            </h4>
            <CloseButton variant="white" onClick={handleClose1} />
          </div>
          <div className="likes-user-lst">
            <ul>
              <li>
                <div className="lst-user-card d-flex align-items-center justify-content-between">
                  <div className="user-like-card d-flex align-items-center">
                    <span className="like-user-icn me-2">
                      <img
                        src={require('../../../../assets/images/default-male-avatar.png')}
                        alt="img"
                      />
                    </span>
                    <h4>
                      <Link to="#">userch one</Link>
                    </h4>
                  </div>
                  <Link className="wht-btn-grey " to="#">
                    Add as friend
                  </Link>
                </div>
              </li>
              <li>
                <div className="lst-user-card d-flex align-items-center justify-content-between">
                  <div className="user-like-card d-flex align-items-center">
                    <span className="like-user-icn me-2">
                      <img
                        src={require('../../../../assets/images/default-male-avatar.png')}
                        alt="img"
                      />
                    </span>
                    <h4>
                      <Link to="#">userch one</Link>
                    </h4>
                  </div>
                  <Link className="wht-btn-grey " to="#">
                    Add as friend
                  </Link>
                </div>
              </li>
              <li>
                <div className="lst-user-card d-flex align-items-center justify-content-between">
                  <div className="user-like-card d-flex align-items-center">
                    <span className="like-user-icn me-2">
                      <img
                        src={require('../../../../assets/images/default-male-avatar.png')}
                        alt="img"
                      />
                    </span>
                    <h4>
                      <Link to="#">userch one</Link>
                    </h4>
                  </div>
                  <Link className="wht-btn-grey " to="#">
                    Add as friend
                  </Link>
                </div>
              </li>
              <li>
                <div className="lst-user-card d-flex align-items-center justify-content-between">
                  <div className="user-like-card d-flex align-items-center">
                    <span className="like-user-icn me-2">
                      <img
                        src={require('../../../../assets/images/default-male-avatar.png')}
                        alt="img"
                      />
                    </span>
                    <h4>
                      <Link to="#">userch one</Link>
                    </h4>
                  </div>
                  <Link className="wht-btn-grey " to="#">
                    Add as friend
                  </Link>
                </div>
              </li>
            </ul>
          </div>
        </Modal.Body>
      </Modal>

      <Modal show={showDelete} onHide={() => setShowDelete(false)}>
        <Modal.Header>
          <span>Delete Post?</span>
          <i
            style={{ cursor: 'pointer' }}
            onClick={() => setShowDelete(false)}
            className="fas fa-times"></i>
        </Modal.Header>
        <Modal.Body>
          <Button className="me-2" variant="secondary" onClick={() => setShowDelete(false)}>
            Cancel
          </Button>
          <Button variant="primary" type="button" onClick={deletedPost}>
            Delete
          </Button>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default GroupPostLayout;
