import { useState, useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import Form from 'react-bootstrap/Form';

import HeaderLogin from '../../commoncomponents/HeaderLogin';
import TopTabbings from './TopTabbings';
import NewsSidebar from '../../commoncomponents/newsfeeds/NewsSidebar';
import GroupDiscover from '../../commoncomponents/newsfeeds/GroupDiscover';
import WhatsNew from '../../commoncomponents/newsfeeds/WhatsNew';
import Upcoming from '../../commoncomponents/newsfeeds/Upcoming';
import PeopleKnow from '../../commoncomponents/newsfeeds/PeopleKnow';
import CampusNews from '../../commoncomponents/newsfeeds/CampusNews';
import ArticalBlogs from '../../commoncomponents/newsfeeds/ArticalBlogs';
import CampusnewsText from '../../commoncomponents/newsfeeds/CampusnewsText';
import FollowCompany from '../../commoncomponents/newsfeeds/FollowCompany';

import * as APISERVICES from '../../../services/SocialServices';

import showNotification from '../../../services/NotificationService';
import { useSelector } from 'react-redux';

import PostLayout from './PostComponets/PostLayout';
import { useOnClickOutside } from '../../../globals/helper';
import { useRef } from 'react';
import useDocumentTitle from '../../../globals/useDocumentTitle';

function Hashtag() {
  useDocumentTitle('AlmaBay');
  const [latestPost, setLatestPost] = useState('');
  const [privacyList, setPrivacyList] = useState('');
  const [checkPost, setCheckPost] = useState([]);
  const [selectedFile, setSelectedFile] = useState([]);
  const [isSelected, setIsSelected] = useState(false);
  const [show, setShow] = useState(false);
  const [selectedData, setSelectedData] = useState([]);
  const [multiSelectData, setMultiSelectData] = useState([]);
  const [singleSelectName, setSingleSelectName] = useState('');
  const [multiSelectName, setmultiSelectName] = useState([]);
  const [loading, setLoading] = useState(false);

  const ref = useRef();
  const { name } = useParams();
  useOnClickOutside(ref, () => setShow(false));
  // const [multi, setMulti] = useState(false);
  const [loadingState, setLoadingState] = useState(false);
  const [showCard, setShowCard] = useState(true);
  const [totalPages, setTotalPages] = useState(0);
  const [pageNum, setPageNum] = useState(0);
  const [disableInputBox, setDisableInputBox] = useState(false);
  const isloggedIn = useSelector((state) => state?.user?.isloggedIn);

  useEffect(() => {
    if (isloggedIn) {
      getPostListData();
      isPrivacylist();
    }
  }, []);

  const getPostListData = (isTrue) => {
    setLoading(true);
    APISERVICES.getHashTagPostList(pageNum, name).then((res) => {
      if (res?.status === 200) {
        setLoading(false);
        if (isTrue) {
          let arra1 = checkPost.map((item) => {
            const item2 = res?.data?.data?.hashposts?.find((i2) => i2.id == item.id);
            return item ? { ...item, ...item2 } : item2;
          });
          setCheckPost(arra1);
        } else {
          let data = res?.data?.data?.hashposts ? res.data.data.hashposts : []
          setCheckPost([...checkPost, ...data]);
        }
        setTotalPages(res?.data?.pager.pageCount);
      } else {
        setLoading(false);
      }
    });
  };

  const isPrivacylist = () => {
    APISERVICES.postPrivacyList().then((res) => {
      if (res?.status == 200) {
        let arr = [];
        let data = res?.data?.data;
        for (let i in data) {
          arr.push({ id: i, label: data[i], value: data[i] });
        }
        setPrivacyList(arr);
      }
    });
  };

  const checkLatestpost = (e) => {
    e.preventDefault();
    let formData = new FormData();
    formData.append(`text`, latestPost.trim());
    formData.append(
      `recipient_id`,
      multiSelectData?.length !== 0 ? multiSelectData.toString() : selectedData.toString()
    );
    for (let i in selectedFile) {
      formData.append(`photos[]`, selectedFile[i]);
    }
    APISERVICES.postLatest(formData).then((res) => {
      if (res?.status === 200) {
        showNotification('success', res?.data?.message);
        setSelectedFile([]);
        setIsSelected(false);
        setSelectedData([]);
        setMultiSelectData([]);
        setmultiSelectName([]);
        setSingleSelectName('');
        setLatestPost('');
        getPostListData();
      }
    });
  };

  const makePost = (e) => {
    if (latestPost?.trim() !== '' || selectedFile.length !== 0) {
      checkLatestpost(e);
    } else {
      showNotification(
        'danger',
        'This status update appears to be blank. Please write something or attach photo to update your status.'
      );
    }
  };

  const settingPrivacySelect = (data, index) => {
    if (data?.id == 0 || data?.id == 4) {
      setMultiSelectData([]);
      setmultiSelectName([]);
      setSingleSelectName(data?.value);
      if (selectedData.includes(data?.id)) {
        setSelectedData([]);
        setSingleSelectName('');
      } else {
        setSelectedData([data?.id]);
      }
    } else {
      setSelectedData([]);
      setSingleSelectName('');
      if (multiSelectData.includes(data?.id)) {
        let filterData = multiSelectData?.filter((item) => item !== data?.id);
        let filterName = multiSelectName?.filter((item) => item !== data?.value);

        setMultiSelectData(filterData);
        setmultiSelectName(filterName);
      } else {
        setMultiSelectData([...multiSelectData, data?.id]);
        setmultiSelectName([...multiSelectName, data?.value]);
      }
    }
  };

  const privacyDropClassSingle = (data) => {
    if (selectedData?.length == 0) {
      return '';
    } else {
      if (selectedData?.includes(data?.id)) {
        return 'selected';
      } else {
        return 'disabled-component';
      }
    }
  };
  const privacyDropClassMulti = (data) => {
    if (multiSelectData?.length == 0) {
      return '';
    } else {
      if (multiSelectData?.includes(data?.id)) {
        return 'selected';
      } else {
        return '';
      }
    }
  };

  const selectedValue = () => {
    let name;
    if (multiSelectName?.length !== 0) {
      name = multiSelectName?.map((name) => name).join(',');

      return <Form.Control type="text" value={name} onClick={() => setShow(!show)} />;
    } else {
      return <Form.Control type="text" value={singleSelectName} onClick={() => setShow(!show)} />;
    }
  };

  const handleClosePopUp = (e) => {
    e.preventDefault();
    setShowCard(false);
  };

  const onPostListData = () => {
    getPostListData();
  };
  const onIncrementPageNo = () => {
    setPageNum((no) => no + 1);
  };

  return (
    <>
      <HeaderLogin loading={loading} />
      <div className="header-lnk-feeds">
        <div className="container">
          <TopTabbings state={'social'} />
        </div>
      </div>
      <section className="news-feed-sec bg-grey py-4">
        <Container>
          <Row>
            <Col xs={12} lg={2}>
              <NewsSidebar state="1" current="social" />
            </Col>
            <Col xs={12} lg={9} xl={7} className="mb-4">
              <PostLayout
                isPostLoading={loading}
                checkPost={checkPost}
                getPostListData={getPostListData}
                totalPages={totalPages}
                onIncrementPageNo={onIncrementPageNo}
                pageNum={pageNum}
                setCheckPost={setCheckPost}
                onPostListData={onPostListData}
                setLoadingState={setLoadingState}
                setDisableInputBox={setDisableInputBox}
                disableInputBox={disableInputBox}
              />
            </Col>
            <Col xl={{ span: 3, offset: 0 }} lg={{ span: 9, offset: 2 }}>
              <div>
                <PeopleKnow />
              </div>
              <div>
                <GroupDiscover />
              </div>
              <div>
                <FollowCompany />
              </div>
              <div>
                <CampusNews />
              </div>
              <div>
                <ArticalBlogs />
              </div>
              <div>
                <CampusnewsText />
              </div>
              <div>
                <WhatsNew />
              </div>
              <div>
                <Upcoming />
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}

export default Hashtag;
