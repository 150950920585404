//  @copyright : ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
// @author     : Shiv Charan Panjeta

// All Rights Reserved.
// Proprietary and confidential :  All information contained herein is, and remains
// the property of ToXSL Technologies Pvt. Ltd. and its partners.
// Unauthorized copying of this file, via any medium is strictly prohibited.

import { useEffect, useState } from 'react';
import { Button, Container } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Table from 'react-bootstrap/Table';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Loader from '../../components/commoncomponents/loader/loader-large';
import * as adminServices from '../../services/AuthServices';

export default function ListDepartments() {
  const [list, setList] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const id = location.state;
  console.log('id', id);

  const [loading, setLoading] = useState(false);
  const [searchedData, setSearchData] = useState({
    department_id: '',
    department_name: '',
    department_email: '',
    department_phone: '',
    department_status: '',
    created_by: ''
  });
  const [response, setResponse] = useState('');
  const [currentPage, setCurrentPage] = useState(0);

  useEffect(() => {
    if (id) {
      apiCall();
    }
  }, []);

  const apiCall = (
    page = '',
    department_id = '',
    department_name = '',
    department_email = '',
    department_phone = '',
    department_status = '',
    created_by = ''
  ) => {
    setLoading(true);
    setCurrentPage(page);
    let body = {
      id,
      page,
      department_id,
      department_name,
      department_email,
      department_phone,
      department_status,
      created_by
    };
    adminServices.getDepartmentList(body).then((res) => {
      setLoading(false);
      if (res?.status === 200) {
        setList(res?.data?.data?.viewlist);
        setResponse(res?.data?.data?.totalRecords);
      }
    });
  };

  const onChangeSearch = (e) => {
    const { name, value } = e.target;
    setSearchData({ ...searchedData, [name]: value });
  };

  const searchHandler = (e) => {
    e.preventDefault();
    apiCall(
      currentPage,
      searchedData.department_id,
      searchedData.department_name,
      searchedData.institute_affiliate,
      searchedData.institute_email,
      searchedData.institute_phone,
      searchedData.institute_city,
      searchedData.institute_status,
      searchedData.created_by
    );
  };

  const handlePageClick = (a) => {
    let page = a?.selected + 1;
    apiCall(
      page,
      searchedData.department_id,
      searchedData.department_name,
      searchedData.institute_affiliate,
      searchedData.institute_email,
      searchedData.institute_phone,
      searchedData.institute_city,
      searchedData.institute_status,
      searchedData.created_by
    );
  };

  return (
    <>
      <div className="dash-main-content">
        <Container fluid>
          <div className="dash-page-content">
            <div className="dash-page-title">
              <Row>
                <Col xs={12} sm={12} md={12}>
                  <div className="dash-title-bar d-flex align-items-center justify-content-between">
                    <div className="title-lft d-flex align-items-center">
                      <div className="dash-icn">
                        <i className="fas fa-university"></i>
                      </div>
                      <div className="dash-title-dis">
                        <h5>
                          Listing of Departments in <span>institute five</span>
                        </h5>
                        <h5>
                          Total Record(s): <span>{response}</span>
                        </h5>
                      </div>
                    </div>
                    <div className="title-rt-btn">
                      <Link
                        className="green_button_admin"
                        to={'/admin/institute/add-departments/' + id}>
                        Add Department
                      </Link>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="dash-content-card">
              <div className="table-responsive">
                <Table className="custom-table-data" striped bordered hover>
                  <thead>
                    <tr>
                      <th>
                        <Form.Check type="checkbox" id="checkbox" label="" />
                      </th>
                      <th>Id</th>
                      <th>Name</th>
                      <th>Email Id</th>
                      <th>Phone</th>
                      <th>Created By</th>
                      <th>Status</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="search-table">
                      <td></td>
                      <td>
                        <Form.Control
                          onChange={onChangeSearch}
                          name="department_id"
                          type="text"
                          placeholder="Id"
                        />
                      </td>
                      <td>
                        <Form.Control
                          onChange={onChangeSearch}
                          name="department_name"
                          type="text"
                          placeholder="Name"
                        />
                      </td>
                      <td>
                        <Form.Control
                          onChange={onChangeSearch}
                          name="department_email"
                          type="text"
                          placeholder="Email Id"
                        />
                      </td>
                      <td>
                        <Form.Control
                          onChange={onChangeSearch}
                          name="department_phone"
                          type="text"
                          placeholder="Phone"
                        />
                      </td>
                      <td>
                        <Form.Select
                          aria-label="Default select example"
                          onChange={onChangeSearch}
                          name="department_status">
                          <option value="">Select</option>
                          <option value="1">Admin</option>
                          <option value="0">Users</option>
                        </Form.Select>
                      </td>
                      <td>
                        <Form.Select
                          aria-label="Default select example"
                          name="created_by"
                          onChange={onChangeSearch}>
                          <option value="">Select</option>
                          <option value="1">Enable</option>
                          <option value="0">Disable</option>
                        </Form.Select>
                      </td>
                      <td className="serac-btns text-center">
                        <Button className="main-btn-grn" onClick={searchHandler}>
                          Search
                        </Button>
                      </td>
                    </tr>
                    {list && list?.length ? (
                      list?.map((data, index) => (
                        <tr key={index}>
                          <td>
                            <Form.Check type="checkbox" id="checkbox" label="" />
                          </td>
                          <td>{data?.institute_id}</td>
                          <td>{data?.name}</td>
                          <td>{data?.admin_email != '0' ? data.admin_email : '-'}</td>
                          <td>{data?.phone || '-'}</td>
                          <td>{data?.created_by == '1' ? 'Admin' : 'User'}</td>
                          <td>{data.status == '1' ? 'Enable' : 'Disable'}</td>
                          <td>
                            <Link
                              to={`/admin/institute/edit-departments/${id}/${data?.institute_id}`}
                              title="EDIT">
                              <i className="fa fa-edit m-2"></i>
                            </Link>
                            <Link
                              to={`/admin/institute/courses/${data?.institute_id}`}
                              state={id}
                              // target="_blank"
                              title="COURSES LIST">
                              <i className="fa fa-list"></i>
                            </Link>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr className="text-center">
                        <td colSpan={9}>Data Not Found</td>
                      </tr>
                    )}
                  </tbody>
                </Table>
                {/* <Pagination>{items}</Pagination> */}
              </div>
            </div>
          </div>
        </Container>
      </div>
      {loading ? <Loader /> : <></>}
    </>
  );
}
