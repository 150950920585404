import { Link } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function SocialMedia() {
  return (
    <>
      <div className="lgn-scl-card mt-3">
        <p>Login using:</p>
        <Row>
          <Col xs={4}>
            <Link className="btn-facebook" to="#">
              Facebook
            </Link>
          </Col>
          <Col xs={4}>
            <Link className="btn-google" to="#">
              Google
            </Link>
          </Col>
          <Col xs={4}>
            <Link className="btn-linkedin" to="#">
              LinkedIn
            </Link>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default SocialMedia;
