import { Link } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import HeaderInner from '../../commoncomponents/HeaderInner';
import FooterMain from '../../commoncomponents/FooterMain';
import Accordion from 'react-bootstrap/Accordion';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import * as ADMINSERVICES from '../../../services/AuthServices';
import { useState } from 'react';
import showNotification from '../../../services/NotificationService';
import { emailRegex } from '../../../globals/helper';
import useDocumentTitle from '../../../globals/useDocumentTitle';
import Loader from '../../commoncomponents/loader/loader-large';

function FaqPage() {
  useDocumentTitle(
    "FAQ's Almabay | Get Connected with your Alma Mater, Batch mates and friends. Register to find right jobs and mentors ! | AlmaBay"
  );
  const [detail, setDetail] = useState({
    email: '',
    question: ''
  });

  const [clicked, setClicked] = useState(false);
  const [response, setResponse] = useState('');
  const [loading, setLoading] = useState(false);

  const validate = () => {
    let error = {};
    if (detail?.email == '') {
      error.email = 'Please enter email';
    }
    if (!detail.email.trim()) {
      error.email = 'This field is required.';
    } else if (!emailRegex.test(detail.email)) {
      error.email = 'please enter valid email';
    }
    if (detail?.question == '') {
      error.question = 'Please enter question';
    }

    return error;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setDetail({
      ...detail,
      [name]: value
    });
  };

  const saveHandler = () => {
    const body = {
      email: detail.email,
      question: detail.question
    };
    setClicked(true);
    if (!Object.keys(validate()).length) {
      setLoading(true);
      setClicked(false);
      ADMINSERVICES.addFaqQuestion(body).then((res) => {
        setLoading(false);
        if (res?.status === 200) {
          showNotification('success', res?.data?.data);
          setDetail({ email: '', question: '' });
          setResponse('Thanks for asking a question');
        }
      });
    }
  };

  return (
    <>
      <HeaderInner />
      <section className="breddcrum py-5">
        <div className="container">
          <Row className="align-items-center">
            <Col md={8} lg={8}>
              <div className="bread-title">
                <h2>Frequently Asked Questions</h2>
              </div>
            </Col>
            <Col md={4} lg={4}>
              <div className="bread-rt text-end">
                <Link className="main-btn" to="#">
                  Ask a Question
                </Link>
              </div>
            </Col>
          </Row>
        </div>
      </section>
      <section className="faq-card py-5">
        <div className="container">
          <div className="faq-card-blk">
            <Row>
              <Col md={8} lg={8}>
                <div className="faq-lft-card">
                  <Accordion defaultActiveKey="0">
                    <Accordion.Item eventKey="0" className="mb-4">
                      <Accordion.Header>
                        I have forgotten password. How do I retrieve them?
                      </Accordion.Header>
                      <Accordion.Body>
                        You can reset your password using the online login retrieval system. Please
                        click on ‘Forgot Password’ link on login page and enter your registered
                        email address. You will receive an email with instructions to reset your
                        password.
                      </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="1" className="mb-4">
                      <Accordion.Header>
                        I have reset my password, but am still unable to login. Why?{' '}
                      </Accordion.Header>
                      <Accordion.Body>
                        Please make sure that you are using the correct email address, and that you
                        are entering your password exactly the same as when you reset it. Please
                        note that your password is case-sensitive. If you are still unable to login,
                        please write an email to assistance@almabay.com, and include the web address
                        of the page where you are trying to login, as well as the error message you
                        receive when you attempt to login.
                      </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="2" className="mb-4">
                      <Accordion.Header>
                        When I try to register, the system does not find my record. Why?{' '}
                      </Accordion.Header>
                      <Accordion.Body>
                        If your record does not appear in the registration system, it may mean that
                        our database lists your information differently from the way you are
                        entering it, or that you are not eligible to register. Please write to{' '}
                        <Link to="#">assistance@almabay.com</Link>.
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>
              </Col>
              <Col md={4} lg={4}>
                <div className="faq-rt-form">
                  <h4 className="mb-3">Still have a question?</h4>

                  {response ? <h5 className="alert alert-success    ">{response}</h5> : ''}

                  <Form>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Send us an email</Form.Label>
                      <Form.Control
                        type="email"
                        placeholder="Your email address"
                        name="email"
                        value={detail?.email}
                        onChange={handleChange}
                      />
                      <div className={'danger-frm'}>{clicked && validate()?.email}</div>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicPassword">
                      <Form.Control
                        as="textarea"
                        placeholder="Your question"
                        style={{ height: '90px' }}
                        name="question"
                        value={detail?.question}
                        onChange={handleChange}
                      />
                      <div className={'danger-frm'}>{clicked && validate()?.question}</div>
                    </Form.Group>
                  </Form>
                  <div className="text-end">
                    <Button className="grey-btn" type="submit" onClick={saveHandler}>
                      Send email
                    </Button>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </section>
      <FooterMain className={'colour-bg'} />
      {loading ? <Loader /> : ''}
    </>
  );
}

export default FaqPage;
