import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

const Autocomplete = ({
  value,
  placeholder,
  onChange,
  datafunction,
  optionsData
}) => {
  const autocomplete = useRef();

  const [query, setQuery] = useState(value);
  const [isShow, setIsShow] = useState(false);

  const handleInputChange = (v) => {
    setQuery(v);

    datafunction(v);
    // v === ''
    //   ? setOptionsData([])
    //   : setOptionsData([...options.filter((x) => x.toLowerCase().indexOf(v.toLowerCase()) > -1)]);
  };

  const handleClickOutSide = (e) => {
    if (!autocomplete.current.contains(e.target)) {
      setIsShow(false);
    }
  };

  //   const hilightSearchText = (text) => {
  //     var pattern = new RegExp('(' + query + ')', 'gi');
  //     var new_text = text.replace(pattern, `<b>${query}</b>`);
  //     return new_text;
  //   };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutSide);
    return () => {
      document.removeEventListener('mousedown', handleClickOutSide);
    };
  }, []);

  //   useEffect(() => {
  //     optionsData.length !== 0 ? setIsShow(true) : setIsShow(false);
  //   }, [optionsData]);

  return (
    <Wrapper ref={autocomplete}>
      <InputField
        type="search"
        placeholder={placeholder}
        isSearch={true}
        value={query}
        onChange={(e) => handleInputChange(e.target.value)}
        onClick={() => setIsShow(true)}
      />

      {isShow && (
        <ListWrapper>
          {optionsData.map((data, index) => (
            <ListItem
              onClick={() => {
                setQuery(data?.label);
                setIsShow(false);
                onChange(data);
              }}
              key={index}
            >
              {<div dangerouslySetInnerHTML={{ __html: data?.label }} />}
            </ListItem>
          ))}
        </ListWrapper>
      )}
    </Wrapper>
  );
};

export default Autocomplete;

const Wrapper = styled.div`
  position: relative;
  min-width: 100%;
`;

const InputField = styled.input`
  position: relative;

  font-size: 14px;
  color: #000;
  border: none;
  border-radius: 8px;
  padding: 20px 12px;
  height: 25px;
  outline: none;
  &:focus,
  &:active {
    border: 1px solid #2196f3;
  }
`;

const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-top: 0.5rem;
  position: absolute;
  top: 44px;
  z-index: 10;
  background: #fff;
  border-radius: 4px;
  width: 100%;
  max-height: 240px;
  overflow-y: auto;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
`;

const ListItem = styled.button`
  text-align: left;
  padding: 16px 8px;
  width: 100%;
  background: #fff;
  outline: none;
  border: none;

  &:hover {
    background: #e2e2e2;
  }
`;
