import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import HeaderInner from '../../commoncomponents/HeaderInner';
import FooterMain from '../../commoncomponents/FooterMain';
import { Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import useDocumentTitle from '../../../globals/useDocumentTitle';

function SmartSolutions() {
  useDocumentTitle('AlmaBay Smart Campus Solutions');
  return (
    <>
      <HeaderInner postion={'position-fixed'} />
      <section className="smart_compus_section">
        <Container>
          <Row>
            <Col md={5}>
              <div className="bnr_content_left mb-4">
                <div className="bnr_logo">
                  <img src={require('../../../assets/images/evolve.png')} alt="img" />
                </div>
                <h1>Smart</h1>
                <h2>Campus Solutions</h2>
                <div className="_get_quote flex-wrap">
                  <div className="toll_free">
                    <span>
                      <img src={require('../../../assets/images/toll-free-icn.png')} alt="img" />
                    </span>
                    <div className="toll_number">
                      <small>Toll Free Number</small>
                      <br />
                      <h4>1800 309 8780</h4>
                    </div>
                  </div>

                  <div className="">
                    <a href="mailto:info@almabay.com" className="quote_button">
                      Get a Quote
                    </a>
                  </div>
                </div>
              </div>
            </Col>
            <Col md={7}>
              <div className="banner-image text-end">
                <img src={require('../../../assets/images/screen.png')} alt="img" />
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="pro_about_section">
        <Container>
          <div className="about_content_area">
            <div className="about_img">
              <img src={require('../../../assets/images/about_img.png')} alt="about" />
            </div>
            <div className="about_content">
              <h3>About Us</h3>
              <p>
                AlmaBay is a fastest growing edtech company it is the brain child of a like-minded
                group of IIM A grads alumni. Having served our respective fields for more than 20
                years, We aim to provide products that facilitate a professional approach to provide
                greater interactivity and make learning a simple Process. We provide our customers
                with a vast range of hardware and software solutions like Interactive digital
                panels, digital teaching devices, video conferencing solution, video walls, digital
                podiums, alumni engagement solutions, training and placement solutions, digital
                signage solutions, AR &amp; VR solutions for classroom, corporate and government
                uses. We are focused on serving our nation by making world class technology products
                in India and offering them to the world. Our state of the art manufacring and
                reserch facilities in collaboration with top Ed-tech companies across the globe make
                products which make education and learning more effective with vision to make Inida
                a technology and knowledge hub, we work tirelessly towards excellence.
              </p>

              <a href="/about-us" className="read_more">
                Read More
              </a>
            </div>
          </div>
        </Container>
      </section>
      <section className="our_products_section">
        <h3 className="text-center">Our Products</h3>
        <p className="mb-4 text-center">
          We have most comprehensive range of products and services to provide 360 degree
          <br />
          solutions to our clients everywhere.
        </p>

        <div className="products_div">
          <div className="sec_products sec_products_img">
            <img src={require('../../../assets/images/flat-panel.jpg')} alt="img" />
          </div>
          <div className="sec_products sec_products_cont">
            <h3>Interactive Flat Panel Display</h3>
            <p>
              AlmaBay Interactive panel is a powerful touch monitor, it is a versatile tool that can
              revolutionise display, Interaction, Learning and Transmission of Knowledge. It allows
              a perfect view for interactive presentations/teaching, that are easy to use. All our
              panels come in True UHD / 4K resolution, modern design, metal frame, rounded corners,
              multiple Input/output ports. Built-in android player to surf the web and use any app,
              it is also having OPS / PC Slot for Pc based applications. All are panels are loaded
              with most powerful Interactive annotation and Learning software to make teaching,
              training, presentations and learning effective and simple
            </p>
          </div>
        </div>
        <div className="products_div products_row_reverse">
          <div className="sec_products sec_products_img">
            <img src={require('../../../assets/images/video-confrence.jpg')} alt="img" />
          </div>
          <div className="sec_products sec_products_cont">
            <h3>Video Conferencing Solution</h3>
            <p>
              Alma bays Video conferencing cameras allows multiple people to meet and collaborate
              face to face long distance by transmitting audio, video, text and presentations in
              real time through the internet. We are providing the Video Conferencing Camera for
              every field like Corporate, Education, Health, Training and Streaming. Our core
              competency lies in a deep understanding of the industry user needs. To fulfil the
              needs of the A/V industry, we deliver the USB/HDMI/SDI based up to 4K UHD PTZ video
              conference cameras. We have latest range of Conference room cameras, All in one Video
              Bar, Video conferencing camera system, portable camera and speakerphones. Our best in
              Class Video Conferencing Cameras are Compatible with the majority of cloud based
              videoconferencing software
            </p>
          </div>
        </div>
        <div className="products_div">
          <div className="sec_products sec_products_img">
            <img src={require('../../../assets/images/electric-lectern.png')} alt="img" />
          </div>
          <div className="sec_products sec_products_cont">
            <h3>Electronic Lectern</h3>
            <p>
              Almabays digital podium is a complete solution for any classroom, boardroom / training
              room and auditorium. It&nbsp;has a built-in sound system
              (amplifier,&nbsp;gooseneck&nbsp;Mic wireless microphone, etc. Digital podium is an all
              in one multimedia presentation device. With its easy to use motorized tilt
              touchscreen, it's never been more convenient to work through a presentation.
            </p>
          </div>
        </div>
        <div className="products_div products_row_reverse">
          <div className="sec_products sec_products_img">
            <img src={require('../../../assets/images/digital-kiosk.png')} alt="img" />
          </div>
          <div className="sec_products sec_products_cont">
            <h3>Digital Kiosks</h3>
            <p>
              Digital Kiosks are basically digital indoor freestanding display screens with concise
              design and reliable performance. It makes communication and advertisement more
              impressive and brings the content to life aided by its cutting-edge resolution.
            </p>
          </div>
        </div>

        <div className="products_div">
          <div className="sec_products sec_products_img">
            <img src={require('../../../assets/images/display-sol.png')} alt="img" />
          </div>
          <div className="sec_products sec_products_cont">
            <h3>Display Solutions</h3>
            <p>
              We have state of art display solutions for indoor and outdoor applications, i.e
              televisions, commercial displays, video Wall LED/LCD etc. These screens are known for
              concise design and reliable performance, is a perfect solution for advertising and
              public services. One doesn’t need to worry about its capability and flexibility since
              it is going to be applied for office receptions – waiting lounges, retail, banquets,
              restaurant, education, finance, Healthcare and transportation. You can display
              anything you choose to form news channels, digital images, video, streaming media, web
              pages, weather data, restaurant menu, text, twitter feeds, banners, photographs for
              advertisements.
            </p>
          </div>
        </div>
      </section>

      <section className="our_accessory">
        <Container>
          <h3 className="acc_heading">Accessories</h3>
          <Row>
            <Col lg={4} md={6}>
              <div className="accesory_list">
                <div className="acc-img">
                  <img src={require('../../../assets/images/ops.jpg')} alt="ops" />
                </div>
                <div className="accesry_name">
                  <h6>OPS/Mini Pc</h6>
                </div>
              </div>
            </Col>
            <Col lg={4} md={6}>
              <div className="accesory_list">
                <div className="acc-img">
                  <img src={require('../../../assets/images/speaker-phone.jpg')} alt="ops" />
                </div>
                <div className="accesry_name">
                  <h6>Speakerphone</h6>
                </div>
              </div>
            </Col>
            <Col lg={4} md={6}>
              <div className="accesory_list">
                <div className="acc-img">
                  <img src={require('../../../assets/images/floor-stand.jpg')} alt="ops" />
                </div>
                <div className="accesry_name">
                  <h6> Floor Stands </h6>
                </div>
              </div>
            </Col>
            <Col lg={4} md={6}>
              <div className="accesory_list">
                <div className="acc-img">
                  <img src={require('../../../assets/images/bracket.jpg')} alt="ops" />
                </div>
                <div className="accesry_name">
                  <h6>Brackets</h6>
                </div>
              </div>
            </Col>
            <Col lg={4} md={6}>
              <div className="accesory_list">
                <div className="acc-img">
                  <img src={require('../../../assets/images/cabels.jpg')} alt="ops" />
                </div>
                <div className="accesry_name">
                  <h6>Cables</h6>
                </div>
              </div>
            </Col>
            <Col lg={4} md={6}>
              <div className="accesory_list">
                <div className="acc-img">
                  <img src={require('../../../assets/images/safty-bracket.jpg')} alt="ops" />
                </div>
                <div className="accesry_name">
                  <h6> Safety Brackets </h6>
                </div>
              </div>
            </Col>
          </Row>
          <div className="quote_button">
            <Link to="#" className="get_quote">
              Get a Quote
            </Link>
          </div>
        </Container>
      </section>
      <section className="our_certificate ">
        <Container>
          <h3 className="acc_heading">Our Certifications</h3>
          <Row>
            <Col md={12}>
              <img src={require('../../../assets/images/certification.jpg')} alt="certificattion" />
            </Col>
          </Row>
        </Container>
      </section>

      <FooterMain className={'colour-bg'} state="d-none" />
    </>
  );
}

export default SmartSolutions;
