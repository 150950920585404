import { useState, useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { checkDomain } from '../../services/InstituteServices';

function InstituteFooter() {
  const [state, setState] = useState('');

  useEffect(() => {
    checkApi();
  }, []);

  const checkApi = () => {
    checkDomain().then((res) => {
      if (res?.status === 200) {
        setState(res?.data?.details);
      }
    });
  };

  return (
    <>
      <footer className="institute-footer">
        <Container>
          <Row>
            <Col md={6} lg={7}>
              <p>
                Copyright © {new Date().getFullYear()} {state?.name}
              </p>
            </Col>
            <Col md={6} lg={5}>
              <p className="text-end d-flex align-items-center justify-content-end">
                Powered by{' '}
                <img
                  className="ms-3"
                  src={require('../../assets/images/institue-footer-logo.png')}
                  alt="img"
                />
              </p>
            </Col>
          </Row>
        </Container>
      </footer>
    </>
  );
}

export default InstituteFooter;
