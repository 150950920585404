/**
@copyright  :   ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
@author     :   Shiv Charan Panjeta 
 
All Rights Reserved.
Proprietary and confidential :  All information contained here in is, and remains
the property of ToXSL Technologies Pvt. Ltd. and it's partners.
Unauthorized copying of this file, via any medium is strictly prohibited.
*/
import { useEffect, useState } from 'react';
import { Button, Container } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { useLocation, useNavigate } from 'react-router-dom';
import AsyncSelect from 'react-select/async';
import * as adminServices from '../../../services/AuthServices';
import showNotification from '../../../services/NotificationService';

export default function AddEventInvitation() {
  const [userSearch, setUserSearch] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const data = location.state;
  const [search, setSearch] = useState('q');
  const id = new URLSearchParams(location?.search).get('id');
  const event_id = new URLSearchParams(location?.search).get('event_id');
  const adminEventId = new URLSearchParams(location?.search).get('adminEventId');
  const [details, setDetails] = useState({
    user_id: ''
  });
  const [eventDetail, setEventDetail] = useState('');
  useEffect(() => {
    getDetail();
  }, []);

  const handleSave = () => {
    if (details?.user_id) {
      const body = {
        event_id: event_id,
        act_id: id,
        user_id: details.user_id
      };
      adminServices.addActivityInvitation(body).then((res) => {
        showNotification('success', res?.data?.message);
        navigate(`/admin/event/activity_user?event_id=${event_id}&id=${id}`);
      });
    } else {
      showNotification('danger', 'please select atleast one user from list');
    }
  };

  const getDetail = () => {
    adminServices.ViewSingleEventDetail(adminEventId).then((res) => {
      if (res?.status === 200) {
        const data = res?.data?.data?.details;
        setEventDetail(data);
      }
    });
  };

  const handleAdminInvite = () => {
    if (details.user_id === '') {
      return showNotification('danger', 'Select one user to send invitation');
    }
    const body = {
      event_id: adminEventId,
      user_id: details.user_id
    };
    adminServices.adminEventInvitation(body).then((res) => {
      showNotification('success', res?.data?.detail);
      navigate(-1);
    });
  };

  const loadAdminOption = (inputValue) => {
    return adminServices.getUserAdminList(inputValue).then((res) => {
      if (res?.status === 200) {
        let tempArr = [];
        if (res?.data?.data) {
          let data = res?.data?.data;

          data?.map((data) => {
            tempArr.push({
              id: data?.id,
              label: data?.name,
              value: data?.name
            });
          });
        }

        return tempArr;
      }
    });
  };

  return (
    <div className="dash-main-content">
      <Container fluid>
        <div className="dash-page-content">
          <div className="dash-page-title">
            <Row>
              <Col xs={12} sm={12} md={12}>
                <div className="dash-title-bar d-flex align-items-center justify-content-between">
                  <div className="title-lft d-flex align-items-center">
                    <div className="dash-icn">
                      <i className="fas fa-university"></i>
                    </div>
                    <div className="dash-title-dis">
                      <h5>Add Event Invitation</h5>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <div className="dash-content-card">
            <div className="cmmn-form">
              <Form>
                <Row>
                  <Col md={12}>
                    <h5 className="mb-4">
                      <b>Event Name: </b> {data?.title}
                    </h5>
                  </Col>
                  <Col md={12}>
                    <Form.Group className="mb-3" controlId="formBasicName">
                      <Form.Label>Select User From List</Form.Label>
                      <AsyncSelect
                        isClearable
                        isMulti
                        cacheOptions
                        defaultOptions
                        menuIsOpen={userSearch !== '' ? true : false}
                        getOptionLabel={(e) => e.label}
                        getOptionValue={(e) => e.value}
                        loadOptions={loadAdminOption}
                        onInputChange={(newValue) => setUserSearch(newValue)}
                        onChange={(e) => {
                          setDetails({ user_id: e.map((data) => data.id).join(',') });
                        }}
                        className="basic-single"
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <div className="form-btns d-flex align-items-center mt-3">
                  {adminEventId ? (
                    <Button className="main-btn-grn" onClick={handleAdminInvite}>
                      Save
                    </Button>
                  ) : (
                    <Button className="main-btn-grn" onClick={handleSave}>
                      Save
                    </Button>
                  )}

                  <strong>OR</strong>
                  <Button
                    className="main-btn-red"
                    onClick={() => {
                      navigate(-1);
                    }}>
                    Cancel
                  </Button>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}
