import CreatableSelect from 'react-select/creatable';

function CreatableSearch(props) {
  const { value, inputValue, Options, setValue, setInputValue, placeholder, isMulti, loadApiCall } =
    props;
  const components = {
    DropdownIndicator: null
  };

  const createOption = (label) => ({
    label,
    value: label
  });

  const handleKeyDown = (event) => {
    if (!inputValue) return;
    switch (event.key) {
      case 'Enter':
      case 'Tab':
        setValue((prev) => [...prev, createOption(inputValue)]);
        setInputValue('');
        event.preventDefault();
    }
  };
  return (
    <>
      <CreatableSelect
        options={Options}
        components={components}
        inputValue={inputValue}
        isClearable
        isMulti={isMulti}
        menuIsOpen={inputValue !== '' ? true : false}
        onChange={(newValue) => setValue(newValue)}
        onInputChange={(newValue) => {
          setInputValue(newValue);
          loadApiCall(newValue);
        }}
        onKeyDown={handleKeyDown}
        placeholder={placeholder}
        value={value}
      />
    </>
  );
}

export default CreatableSearch;
