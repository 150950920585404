import { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { getMentors } from '../../../services/InstituteServices';
import Loader from '../../commoncomponents/loader/loader-large';

function MentorshipInst() {
  const [mentor, setMentor] = useState([]);
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    getInfo();
  }, []);

  const getInfo = () => {
    getMentors().then((res) => {
      setLoading(false)
      if (res?.status == 200) {
        setMentor(res?.data);
      }
    });
  };

  return (
    <>
      <div className="wrappermain_outer">
        <section className="mentorship_hero_banner">
          <Container>
            <Row className="align-items-center">
              <Col lg={6}>
                <div className="banner-info">
                  <h1 className="mb-3">AlmaBay Recognised Mentors</h1>
                  <p className="mb-4">
                    Get mentorship from achievers whose achievements have to be recognized and
                    verified by AlmaBay.
                  </p>
                  <Link>learn More</Link>
                </div>
              </Col>
              <Col lg={6}>
                <img
                  src={require('../../../assets/images/institute-banner-img.png')}
                  alt="img"
                  width="100%"
                />
              </Col>
            </Row>
          </Container>
        </section>

        <section className="mentor-details">
          <Container>
            <Row>
              <Col md={4}>
                <div className="p-2">
                  <div className="single-mentor-detail text-center">
                    <img
                      src={require('../../../assets/images/mentors-icon.png')}
                      alt="icon"
                      width="50px"
                      className="mb-3"
                    />
                    <h3>{mentor?.mentorsCount}</h3>
                    <h6>Mentors</h6>
                  </div>
                </div>
              </Col>
              <Col md={4}>
                <div className="p-2">
                  <div className="single-mentor-detail text-center">
                    <img
                      src={require('../../../assets/images/mentorship-area-icon.png')}
                      alt="icon"
                      width="50px"
                      className="mb-3"
                    />
                    <h3>{mentor?.mentorShipCount}</h3>
                    <h6>Mentorship Areas</h6>
                  </div>
                </div>
              </Col>
              <Col md={4}>
                <div className="p-2">
                  <div className="single-mentor-detail text-center">
                    <img
                      src={require('../../../assets/images/location-area-icon.png')}
                      alt="icon"
                      width="30px"
                      className="mb-3"
                    />
                    <h3>{mentor?.locationCount}</h3>
                    <h6>Locations</h6>
                  </div>
                </div>
              </Col>
              <Col sm={12}>
                <h4 className="mentorsection-heading text-center mt-5 mb-4">
                  We will meet you where you are and take you where you want to go Faster.
                </h4>
              </Col>
              <Col md={4}>
                <div className="p-2 h-100">
                  <div className="mentor_take h-100">
                    <img
                      src={require('../../../assets/images/school-colleges.png')}
                      alt="scholl-collages"
                    />
                    <div className="p-2 text-center">
                      <p>Schools Colleges Universities Corporates</p>
                    </div>
                  </div>
                </div>
              </Col>
              <Col md={4}>
                <div className="p-2 h-100">
                  <div className="mentor_take h-100">
                    <img
                      src={require('../../../assets/images/established_professionals.png')}
                      alt="scholl-collages"
                    />
                    <div className="p-2 text-center">
                      <p>
                        Build a network with established professionals while at the start of your
                        career.
                      </p>
                    </div>
                  </div>
                </div>
              </Col>
              <Col md={4}>
                <div className="p-2 h-100">
                  <div className="mentor_take h-100">
                    <img
                      src={require('../../../assets/images/registration_process.png')}
                      alt="scholl-collages"
                    />
                    <div className="p-2 text-center">
                      <p>
                        The registration process will take you less than 5 minutes & you will be
                        granted full access to the virtual fair.
                      </p>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        <section className="mentorship_providers">
          <Container>
            <Row>
              <Col sm={12}>
                <h4 className="mentorsection-heading text-center mt-5 mb-4">
                  Mentorship Providers
                </h4>
              </Col>
              {mentor?.result && mentor?.result?.length ? (
                mentor?.result?.map((data, index) => (
                  <Col md={4} key={index}>
                    <div className="single-provider text-center" key={index}>
                      <div className="py-4 single-provider-detail">
                        <img
                          src={
                            data?.url && data?.extension
                              ? process.env.REACT_APP_API_DOMAIN +
                              data?.url + "." + data?.extension : data?.url && !data?.extension
                                ? process.env.REACT_APP_API_DOMAIN +
                                data?.url
                                : require('../../../assets/images/birth4.png')
                          }
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null; // prevents looping
                            currentTarget.src = require('../../../assets/images/birth4.png');
                          }}
                          alt="img"
                        />
                        <h4 className="mb-1">{data?.name}</h4>
                        <h6 className="mb-1">
                          {data?.work_details?.length ? data?.work_details[0]?.designation : ''}
                        </h6>
                        <p className="mb-0">
                          {data?.work_details?.length ? data?.work_details[0]?.location : ''}
                        </p>
                      </div>
                      <div className="followership p-1">
                        <div className="flex-grow-1">
                          <p className="text-muted mb-2">Followers</p>
                          <span className="counter">{data?.follower_count}</span>
                        </div>
                        <div className="flex-grow-1">
                          <p className="text-muted mb-2">Followings</p>
                          <span className="counter">{data?.followering_count}</span>
                        </div>
                        <div className="flex-grow-1">
                          <p className="text-muted mb-2">Views</p>
                          <span className="counter">{data?.views}</span>
                        </div>
                        <div className="flex-grow-1">
                          <p className="text-muted mb-2">Answers</p>
                          <span className="counter">{data?.answer}</span>
                        </div>
                      </div>
                    </div>
                  </Col>
                ))
              ) : (
                <div className={loading ? "d-none" : "text-center nodata"}>
                  <img src={require('../../../assets/images/nodata.png')} alt="noData" />
                  <p>Data Not Found</p>
                </div>
              )}
            </Row>
          </Container>
        </section>
      </div>

      <div className="lgn-link pb-4">
        <Container>
          <div className="logn-lnk-inr">
            <p>
              <Link to="#">Register</Link> / <Link to="/institute-login">Login</Link> to browse
              members by Year, Name, Company, Profession or Location
            </p>
          </div>
        </Container>
      </div>
      {loading ? <Loader /> : <></>}
    </>
  );
}

export default MentorshipInst;
