import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

export default function CKEditorComponent(props) {
  const { latestPost, setLatestPost, isToolBar, placeholder, onBlur } = props;
  return (
    <>
      <CKEditor
        name="description"
        data={latestPost}
        editor={ClassicEditor}
        onReady={(editor) => {
          // You can store the "editor" and use when it is needed.
        }}
        config={{
          toolbar: isToolBar
            ? ['bold', 'italic', 'numberedList', 'bulletedList', 'outdent', 'indent']
            : [],
          placeholder: placeholder ? placeholder : ''
        }}
        onChange={(event, editor) => {
          const data = editor.getData();
          setLatestPost(data);
        }}
        onBlur={(event, editor) => {
          onBlur && onBlur(event, editor);
        }}
        onFocus={(event, editor) => {}}
      />
    </>
  );
}
