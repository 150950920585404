import { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import docImg from '../../../assets/images/doc1.png';
import odsImg from '../../../assets/images/ods.png';
import odtImg from '../../../assets/images/odt.png';
import pdfImg from '../../../assets/images/pdf1.png';
import pptImg from '../../../assets/images/ppt.png';
import xlsImg from '../../../assets/images/xls.png';
import { truncate } from '../../../globals/helper';
import {
  checkClubDomain,
  getClubCountDetail,
  getClubDetail,
  getClubTimelineDetail
} from '../../../services/InstituteServices';
import NoDataFound from '../../commoncomponents/NoDataFound';
import ChapterCard from './components/ChapterCard';
import UpcomingCard from './components/UpcomingCard';
import WhatsNewCard from './components/WhatsNewCard';

function ClubDetails() {
  const userDetail = useSelector((state) => state?.user?.details);
  const [clubDetail, setClubDetail] = useState('');
  const [clubCount, setClubCount] = useState({});
  const [timelineData, setTimelineData] = useState('');
  const [clubDomain, setClubDomain] = useState("")

  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    getDetails();
  }, []);

  /**
   * function to get all details of club
   */
  const getDetails = async () => {
    try {
      // using Promise.all() method to set all requests at one time
      const response = await Promise.all([
        getClubDetail(id),
        getClubCountDetail(id),
        getClubTimelineDetail(id),
        checkClubDomain(id)
      ]);
      const data = response.map((res) => res.data);
      // destructuring response array
      const [dataOne, dataTwo, dataThree, dataFour] = data;
      setClubDetail(dataOne?.data);
      setClubCount(dataTwo);
      setTimelineData(dataThree?.data?.stories);
      setClubDomain(dataFour?.details)
    } catch (error) {
      console.error(error);
    }
  };

  const navigateHandler = (domain_name) => {
    const URL = window.location.host;
    var oldPath = URL;
    // console.log('oldPath', oldPath)
    var newPath = oldPath.replace(/^[^.]*/, domain_name);
    window.location.href = 'http://' + newPath + "/club/home";
  }


  return (
    <>
      <div className="wrappermain_outer bggreylight">
        <section className="club-hero-sec pt-4 pb-3">
          <Container>
            <button
              className="btn btn-back mb-4 mt-5"
              type="button"
              onClick={() => {
                navigate(-1);
              }}>
              Back
            </button>
            <div className="club-hero-inr bg-white">
              <img
                height={300}
                width={1200}
                style={{ objectFit: 'cover' }}
                src={
                  clubDetail?.cover_image
                    ? process.env.REACT_APP_API_DOMAIN +
                    'common/uploads/institute_logo/' +
                    clubDetail?.cover_image
                    : require('../../../assets/images/institute-img/club-1.png')
                }
                alt="club"
              />
              <span className="club-hero-logo">
                <img
                  src={
                    clubDetail?.logo
                      ? process.env.REACT_APP_API_DOMAIN +
                      'common/uploads/institute_logo/' +
                      clubDetail?.logo
                      : require('../../../assets/images/institute-img/club-1.png')
                  }
                  alt="club"
                />
              </span>
              <div className="club-hero-dis">
                <div className="club-dis-lft">
                  <h4>{clubDetail?.name}</h4>
                  <p className="close-club" style={{ textTransform: 'capitalize' }}>
                    {clubDetail?.club_privacy} Club{' '}
                    {clubDetail?.verified == 1 && (
                      <span className="verified-icn ms-1">
                        <img
                          src={require('../../../assets/images/institute-img/verfied.png')}
                          alt="club"
                        />
                      </span>
                    )}
                  </p>
                </div>
                <div className="club-dis-rt">
                  <Link
                    className="orange-btn me-3"
                    to="/institute-login"
                    state={`/user/club-detail/${id}`}>
                    Join Now
                  </Link>
                  {clubDomain?.domain_name != null && <Link
                    className="orange-btn"
                    to="#"
                    onClick={() => { navigateHandler(clubDomain?.domain_name) }}
                  >
                    Visit Now
                  </Link>}

                </div>
                {/* <div className="club-dis-rt">
               
                </div> */}
              </div>
            </div>
            <div className="club-dtl-ftr">
              <ul>
                <li>
                  <h4>Discussion</h4>
                  <p>{clubCount?.total_discussion}</p>
                </li>
                <li>
                  <h4>Videos</h4>
                  <p>{clubCount?.total_videos}</p>
                </li>
                <li>
                  <h4>Photos</h4>
                  <p>{clubCount?.total_photos}</p>
                </li>
                <li>
                  <h4>Members</h4>
                  <p>
                    {clubCount?.total_members} {clubCount?.total_members > 100 && '+'}
                  </p>
                </li>
              </ul>
            </div>
          </Container>
        </section>
        <section className="club-dtl-sec">
          <Container>
            <Row>
              <Col lg={8} md={12}>
                {timelineData && timelineData?.length !== 0 ? (
                  <>
                    {timelineData?.map((data, index) => {
                      return (
                        <div className="club-detail-card mb-4" key={index}>
                          <div className="club-hd-sec">
                            <div className="club-dtl-icn d-flex align-items-center">
                              <img
                                src={
                                  data?.recipient?.avatar_url
                                    ? data?.recipient?.avatar_url
                                    : require('../../../assets/images/institute-img/club-logo-3.png')
                                }
                                alt="club"
                              />
                            </div>
                            <div className="club-hd-dis">
                              <h4 style={{ textTransform: 'capitalize' }}>
                                {data?.recipient?.name} Club
                              </h4>
                              {/**
                               *for text
                               */}
                              {(data?.text !== null || data?.text == '') && (
                                <pre
                                  style={{ fontFamily: 'Lato', fontSize: 14 }}
                                  className="profile-top-dis"
                                  dangerouslySetInnerHTML={{
                                    __html: data?.text
                                  }}>
                                  {/* {data.text} */}
                                </pre>
                              )}
                              {/**
                               *for images
                               */}
                              {data?.images_path?.length !== 0 && (
                                <div className="post_img d-flex flex-wrap">
                                  {data?.images_path?.map((image, index) => {
                                    return (
                                      <Link
                                        to={`javascript:void(0)`}
                                        className="collage_card"
                                        id="img1"
                                        // onClick={(e) => handleShow2(e, data, index)}
                                        key={index}>
                                        {' '}
                                        <img src={image} alt="img" />
                                      </Link>
                                    );
                                  })}
                                </div>
                              )}
                              {/**
                               *for files
                               */}
                              {data?.files?.length !== 0 && (
                                <div className="post_img d-flex flex-wrap">
                                  {data?.files?.map((files, index) => {
                                    const pdfFile = files?.file_name?.includes('pdf');
                                    const spreadSheetFile =
                                      files?.file_name?.includes('xlsx') ||
                                      files?.file_name?.includes('xls');
                                    const odtFile = files?.file_name?.includes('odt');
                                    const odsFile = files?.file_name?.includes('ods');
                                    const docFile =
                                      files?.file_name?.includes('doc') ||
                                      files?.file_name?.includes('docx');
                                    const pptFile =
                                      files?.file_name?.includes('ppt') ||
                                      files?.file_name?.includes('pptx');
                                    if (pdfFile) {
                                      return (
                                        <div className="pdffile_card" key={index}>
                                          <img src={pdfImg} alt="" />
                                          <div className="overlay">
                                            <p className="ng-binding">
                                              {truncate(files?.file_name, 10)}
                                            </p>
                                            <div
                                              className={
                                                data?.files?.length > 1
                                                  ? 'd-flex justify-content-between bottomrow'
                                                  : 'd-flex justify-content-center bottomrow'
                                              }>
                                              {data?.files?.length > 1 &&
                                                data?.publisher?.encrypted_id ===
                                                userDetail?.encrypted_id && (
                                                  <a
                                                    className="pull-right ng-scope"
                                                    // onClick={() => {
                                                    //   deleteFile(files?.post_id, files?.media_id);
                                                    // }}
                                                    href={`javascript:void(0)`}>
                                                    <i className="fa fa-trash progress-icon"></i>
                                                  </a>
                                                )}
                                            </div>
                                          </div>
                                        </div>
                                      );
                                    } else if (spreadSheetFile) {
                                      return (
                                        <div className="pdffile_card" key={index}>
                                          <img src={xlsImg} alt="" />
                                          <div className="overlay">
                                            <p className="ng-binding">
                                              {truncate(files?.file_name, 10)}
                                            </p>
                                            <div
                                              className={
                                                data?.files?.length > 1
                                                  ? 'd-flex justify-content-between bottomrow'
                                                  : 'd-flex justify-content-center bottomrow'
                                              }>
                                              {data?.files?.length > 1 &&
                                                data?.publisher?.encrypted_id ===
                                                userDetail?.encrypted_id && (
                                                  <a
                                                    className="pull-right ng-scope"
                                                    // onClick={() => {
                                                    //   deleteFile(files?.post_id, files?.media_id);
                                                    // }}
                                                    href={`javascript:void(0)`}>
                                                    <i className="fa fa-trash progress-icon"></i>
                                                  </a>
                                                )}
                                            </div>
                                          </div>
                                        </div>
                                      );
                                    } else if (docFile) {
                                      return (
                                        <div className="pdffile_card" key={index}>
                                          <img src={docImg} alt="" />
                                          <div className="overlay">
                                            <p className="ng-binding">
                                              {truncate(files?.file_name, 10)}
                                            </p>
                                            <div
                                              className={
                                                data?.files?.length > 1
                                                  ? 'd-flex justify-content-between bottomrow'
                                                  : 'd-flex justify-content-center bottomrow'
                                              }>
                                              {data?.files?.length > 1 &&
                                                data?.publisher?.encrypted_id ===
                                                userDetail?.encrypted_id && (
                                                  <a
                                                    className="pull-right ng-scope"
                                                    href={`javascript:void(0)`}>
                                                    <i className="fa fa-trash progress-icon"></i>
                                                  </a>
                                                )}
                                            </div>
                                          </div>
                                        </div>
                                      );
                                    } else if (pptFile) {
                                      return (
                                        <div className="pdffile_card" key={index}>
                                          <img src={pptImg} alt="" />
                                          <div className="overlay">
                                            <p className="ng-binding">
                                              {truncate(files?.file_name, 10)}
                                            </p>
                                            <div
                                              className={
                                                data?.files?.length > 1
                                                  ? 'd-flex justify-content-between bottomrow'
                                                  : 'd-flex justify-content-center bottomrow'
                                              }>
                                              {data?.files?.length > 1 &&
                                                data?.publisher?.encrypted_id ===
                                                userDetail?.encrypted_id && (
                                                  <a
                                                    className="pull-right ng-scope"
                                                    // onClick={() => {
                                                    //   deleteFile(files?.post_id, files?.media_id);
                                                    // }}
                                                    href={`javascript:void(0)`}>
                                                    <i className="fa fa-trash progress-icon"></i>
                                                  </a>
                                                )}
                                            </div>
                                          </div>
                                        </div>
                                      );
                                    } else if (odsFile) {
                                      return (
                                        <div className="pdffile_card" key={index}>
                                          <img src={odsImg} alt="" />
                                          <div className="overlay">
                                            <p className="ng-binding">
                                              {truncate(files?.file_name, 10)}
                                            </p>
                                            <div
                                              className={
                                                data?.files?.length > 1
                                                  ? 'd-flex justify-content-between bottomrow'
                                                  : 'd-flex justify-content-center bottomrow'
                                              }>
                                              {data?.files?.length > 1 &&
                                                data?.publisher?.encrypted_id ===
                                                userDetail?.encrypted_id && (
                                                  <a
                                                    className="pull-right ng-scope"
                                                    // onClick={() => {
                                                    //   deleteFile(files?.post_id, files?.media_id);
                                                    // }}
                                                    href={`javascript:void(0)`}>
                                                    <i className="fa fa-trash progress-icon"></i>
                                                  </a>
                                                )}
                                            </div>
                                          </div>
                                        </div>
                                      );
                                    } else if (odtFile) {
                                      return (
                                        <div className="pdffile_card" key={index}>
                                          <img src={odtImg} alt="" />
                                          <div className="overlay">
                                            <p className="ng-binding">
                                              {truncate(files?.file_name, 10)}
                                            </p>
                                            <div
                                              className={
                                                data?.files?.length > 1
                                                  ? 'd-flex justify-content-between bottomrow'
                                                  : 'd-flex justify-content-center bottomrow'
                                              }>
                                              {data?.files?.length > 1 &&
                                                data?.publisher?.encrypted_id ===
                                                userDetail?.encrypted_id && (
                                                  <a
                                                    className="pull-right ng-scope"
                                                    // onClick={() => {
                                                    //   deleteFile(files?.post_id, files?.media_id);
                                                    // }}
                                                    href={`javascript:void(0)`}>
                                                    <i className="fa fa-trash progress-icon"></i>
                                                  </a>
                                                )}
                                            </div>
                                          </div>
                                        </div>
                                      );
                                    }
                                  })}
                                </div>
                              )}
                            </div>
                          </div>
                          {/* <div className="club-main-img">
                            <img
                              src={require('../../../assets/images/institute-img/club-main-1.png')}
                              alt="club"
                            />
                          </div> */}
                        </div>
                      );
                    })}
                  </>
                ) : (
                  <>
                    <NoDataFound />
                  </>
                )}
              </Col>
              <Col lg={4} md={12}>
                <div className="mb-3">
                  <ChapterCard type={'Club'} />
                </div>
                <div className="mb-3">
                  <WhatsNewCard />
                </div>
                <div className="mb-3">
                  <UpcomingCard />
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </div>
      <div className="lgn-link py-4">
        <Container>
          <div className="logn-lnk-inr">
            <p>
              <Link to="/institute-register">Register</Link> /{' '}
              <Link to="/institute-login">Login</Link> to browse members by Year, Name, Company,
              Profession or Location
            </p>
          </div>
        </Container>
      </div>

      {/* <InstituteFooter /> */}
    </>
  );
}

export default ClubDetails;
