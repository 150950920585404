import { useEffect, useState } from 'react';
import { Card, Container } from 'react-bootstrap';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { getSingleBlogDetail } from '../../../services/InstituteServices';

function BlogDetail() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [blogDetail, setBlogDetail] = useState([]);

  useEffect(() => {
    if (id) {
      //fetching id from slug i.e.. 13-title
      getBlog(id?.split('-')[0]);
    }
  }, []);

  const getBlog = (id) => {
    getSingleBlogDetail(id).then((res) => {
      if (res?.status == 200) {
        setBlogDetail(res?.data?.data);
      }
    });
  };

  return (
    <div className="wrappermain_outer bggreylight">
      <section className="blog-news-details">
        <Container className="p-0">
          <button
            className="btn btn-back mb-4 mt-5"
            type="button"
            onClick={() => {
              navigate(-1);
            }}
          >
            Back
          </button>

          <Card>
            <Card.Body>
              <div className="single_detail_card">
                <h1 className="mb-4">{blogDetail?.details?.title}</h1>
                {/* discription->innerHtml insetable with img */}
                <img
                  className="mb-4"
                  src={
                    blogDetail?.details?.image
                      ? process.env.REACT_APP_API_DOMAIN +
                      'common/uploads/blog/' +
                      blogDetail?.details?.image
                      : require('../../../assets/images/blag-detail.png')
                  }
                  alt="img"
                />
                {/* <p className="mb-4">{blogDetail?.details?.description}</p> */}
                <span dangerouslySetInnerHTML={{ __html: blogDetail?.details?.description }} />
                {/* <p className='mb-5'>We provide you with a platform that enables mentees to interact with their alumni as well as other industry experts. It provides an opportunity for the mentees to post generic queries (so that anyone may respond to them) or even address questions directly to a particular mentor. Mentors may choose to address any of the queries put across to them.</p> */}

                <div className="d-flex align-items-center justify-content-between navigators">
                  <Link
                    className={`previous position-relative ${blogDetail?.pre ? '' : 'prev-end'}`}
                    onClick={(e) => {
                      e.preventDefault();
                      getBlog(blogDetail?.pre?.id);
                    }}
                  >
                    <i className="fas fa-chevron-left previous-icon"></i>
                    <p className="mb-0">Prev</p>
                    <span className="title">{blogDetail?.pre?.title}</span>
                  </Link>

                  {blogDetail?.next ? (
                    <Link
                      className="next position-relative text-end"
                      onClick={(e) => {
                        e.preventDefault();
                        getBlog(blogDetail?.next?.id);
                      }}
                    >
                      <i className="fas fa-chevron-right next-icon"></i>
                      <p className="mb-0">Next</p>
                      <span className="title">{blogDetail?.next?.title}</span>
                    </Link>
                  ) : (
                    ''
                  )}
                </div>
              </div>
            </Card.Body>
          </Card>
        </Container>
      </section>
    </div>
  );
}

export default BlogDetail;
