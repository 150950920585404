/**
@copyright  :   ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
@author     :   Shiv Charan Panjeta 
 
All Rights Reserved.
Proprietary and confidential :  All information contained here in is, and remains
the property of ToXSL Technologies Pvt. Ltd. and it's partners.
Unauthorized copying of this file, via any medium is strictly prohibited.
*/

import { useEffect, useState } from 'react';
import { Button, Container } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Table from 'react-bootstrap/Table';
import { useLocation, useNavigate } from 'react-router';
import { Link } from 'react-router-dom';

import moment from 'moment';
import Loader from '../../../components/commoncomponents/loader/loader-large';
import * as adminServices from '../../../services/AuthServices';
import showNotification from '../../../services/NotificationService';

export default function ManageActivity() {
  const [list, setList] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  let id = location?.state;
  const [currentPage, setCurrentPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [orderBy, setOrderBy] = useState('DESC');
  const [shortOrderBy, setShortOrderBy] = useState('id');
  const [chekcedId, setCheckedId] = useState([]);

  const [searchData, setSearchData] = useState({
    event_id: '',
    title: ''
  });

  const [eventStatus, setEventStatus] = useState('');
  const [record, setrecord] = useState('');
  const [action, setAction] = useState('');
  const [allId, setAllId] = useState([]);

  const eventId = new URLSearchParams(location?.search).get('event_id');

  useEffect(() => {
    apiCall();
  }, []);

  let active = 2;
  let items = [];

  const apiCall = () => {
    setLoading(true);
    const body = {
      event_id: eventId,
      id: '',
      page: '',
      order: orderBy,
      activity_id: '',
      activity_title: '',
      activity_status: ''
    };
    adminServices.getManageActivities(body).then((resp) => {
      setLoading(false);
      if (resp?.status === 200) {
        setLoading(false);
        setCheckedId([]);
        setList(resp?.data?.detail.viewlist);
        setrecord(resp?.data?.detail.totalRecords);
        setAllId(resp?.data?.detail.viewlist.map((data) => data?.id));
      }
    });
  };

  const onChangeSearch = (e) => {
    const { name, value } = e.target;
    setSearchData({ ...searchData, [name]: value });
  };

  const searchHandler = (e) => {
    setLoading(true);
    const body = {
      id: id,
      page: 1,
      order: orderBy ? orderBy : 'DESC',
      sortfield: shortOrderBy ? shortOrderBy : 'id',
      event_id: searchData.event_id,
      activity_title: searchData.title
    };

    adminServices.getManageActivities(body).then((resp) => {
      setLoading(false);
      if (resp?.status === 200) {
        setList(resp?.data?.detail.viewlist);
        setAllId(resp?.data?.data?.viewlist.map((data) => data?.id));
      }
    });
  };

  const sortHandler = (e, type) => {
    e.preventDefault();
    let currentSort = orderBy === 'ASC' ? 'DESC' : 'ASC';
    setOrderBy(currentSort);
    setShortOrderBy(type);

    apiCall(currentPage, orderBy, shortOrderBy);
  };

  const handleSelectAll = (e) => {
    if (e.target.checked === true) {
      setCheckedId(allId);
    } else {
      setCheckedId([]);
    }
  };

  const handleAdd = (e) => {
    e.preventDefault();

    navigate(`/admin/event/AddActivity?event_id=${eventId}`);
  };

  const handlecheckbox = (id) => {
    if (chekcedId?.includes(id)) {
      let filterId = chekcedId.filter((data) => data !== id);
      setCheckedId(filterId);
    } else {
      setCheckedId([...chekcedId, id]);
    }
  };

  const selectManageActivities = (e) => {
    setAction(e.target.value);
    const body = {
      action: e.target.value,
      ids: chekcedId?.toString()
    };
    adminServices.selectedManageActivities(body).then((res) => {
      if (res?.status === 200) {
        showNotification('success', res?.data?.message);
        apiCall();
      }
    });
  };

  return (
    <>
      <div className="dash-main-content">
        <Container fluid>
          <div className="dash-page-content">
            <div className="dash-page-title">
              <Row>
                <Col xs={12} sm={12} md={12}>
                  <div className="dash-title-bar d-flex align-items-center justify-content-between">
                    <div className="title-lft d-flex align-items-center">
                      <div className="dash-icn">
                        <i className="fa fa-users"></i>
                      </div>
                      <div className="dash-title-dis">
                        <h5>
                          Event Activity <span>Listing</span>
                        </h5>
                        <h5>
                          Total Record(s): <span> {record || 0} </span>
                        </h5>
                      </div>
                    </div>

                    <div className="title-rt-btn d-flex align-items-center">
                      <Link className="add-btn-grn mx-2" onClick={(e) => handleAdd(e)}>
                        Add Activity
                      </Link>
                      {/* enable,disable,del,delImg */}
                      <div className="head-instite-sec">
                        <Form.Select
                          aria-label="Default select example "
                          onChange={selectManageActivities}>
                          <option>Choose Action</option>
                          <option value="active">Enable</option>
                          <option value="inactive">Disable</option>

                          {/* <option value="del">Delete</option> */}
                          {/* <option value="delImg">Delete Image Only </option> */}
                        </Form.Select>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="dash-content-card">
              <div className="table-responsive">
                <Table className="custom-table-data" striped bordered hover>
                  <thead>
                    <tr>
                      <th>
                        <Form.Check
                          type="checkbox"
                          id="checkbox"
                          name="checkall"
                          checked={allId?.length ? allId?.length === chekcedId?.length : false}
                          onChange={(e) => {
                            handleSelectAll(e);
                          }}
                          label=""
                        />
                      </th>
                      <th>
                        <Link
                          to=""
                          onClick={(e) => {
                            sortHandler(e, 'id');
                          }}>
                          Id
                          <img src={require('../../../assets/images/sort.png')} alt="sort" />
                        </Link>
                      </th>
                      <th>
                        <Link
                          to=""
                          onClick={(e) => {
                            sortHandler(e, 'title');
                          }}>
                          Title
                          <img src={require('../../../assets/images/sort.png')} alt="sort" />
                        </Link>
                      </th>
                      <th>
                        <Link to="">Description</Link>
                      </th>
                      <th>
                        <Link to="">Activity Dates</Link>
                      </th>
                      <th>
                        <Link
                          to=""
                          onClick={(e) => {
                            sortHandler(e, 'id');
                          }}>
                          Status
                          <img src={require('../../../assets/images/sort.png')} alt="sort" />
                        </Link>
                      </th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="search-table">
                      <td></td>
                      <td>
                        <Form.Control
                          type="text"
                          placeholder="Id"
                          value={searchData.event_id}
                          name="event_id"
                          onChange={(e) => {
                            onChangeSearch(e);
                          }}
                        />
                      </td>
                      <td>
                        <Form.Control
                          type="text"
                          placeholder="title"
                          value={searchData.title}
                          name="title"
                          onChange={(e) => {
                            onChangeSearch(e);
                          }}
                        />
                      </td>

                      <td>
                        <div className="d-flex align-items-center"></div>
                      </td>
                      <td></td>
                      <td>
                        <Form.Select
                          aria-label="Default select example"
                          name="status"
                          value={searchData?.event_status}
                          onChange={(e) => {
                            setEventStatus(e.target.value);
                          }}>
                          <option value="">Select</option>
                          <option value={1}>Enable</option>
                          <option value={2}>Disable</option>
                        </Form.Select>
                      </td>
                      <td className="serac-btns" colSpan={2}>
                        <Button
                          className="main-btn-grn"
                          onClick={(e) => {
                            searchHandler(e);
                          }}>
                          Search
                        </Button>
                      </td>
                    </tr>
                    {list && list?.length ? (
                      list?.map((data, index) => {
                        return (
                          <tr key={index}>
                            <td>
                              <Form.Check
                                type="checkbox"
                                id="checkbox"
                                label=""
                                checked={chekcedId?.includes(data?.id)}
                                onClick={() => handlecheckbox(data?.id)}
                              />
                            </td>
                            <td>{data.id}</td>
                            <td>{data?.title} </td>
                            <td>{data?.description} </td>
                            <td>
                              {' '}
                              {moment(data.from_event_date).format('Do MMMM YYYY')} -{' '}
                              {data.to_event_date == '0000-00-00 00:00:00'
                                ? 'N/A'
                                : moment(data.to_event_date).format('Do MMMM YYYY')}{' '}
                            </td>
                            <td>{data.status == '1' ? 'Enable' : 'Disable'}</td>
                            <td>
                              <div
                                className="search-btn pointer"
                                role="presentation"
                                onClick={() =>
                                  navigate(
                                    `/admin/event/ViewManageActivity?event_id=${eventId}&id=${data?.id}`
                                  )
                                }>
                                <i className="fas fa-search-plus" title="VIEW"></i>
                              </div>

                              <div
                                className="edit-btn pointer"
                                role="presentation"
                                onClick={() =>
                                  navigate(
                                    `/admin/event/AddActivity?event_id=${eventId}&id=${data?.id}`
                                  )
                                }>
                                <i className="fas fa-edit" title="EDIT"></i>
                              </div>

                              <div
                                role="presentation"
                                className="edit-btn pointer"
                                onClick={() =>
                                  navigate(
                                    `/admin/event/activity_user?event_id=${eventId}&id=${data?.id}`,
                                    { state: data }
                                  )
                                }>
                                <i className=" fa fa-users" title="View Participants"></i>
                              </div>
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr className="text-center">Data Not Found </tr>
                    )}
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
        </Container>
      </div>
      {loading ? <Loader /> : <></>}
    </>
  );
}
