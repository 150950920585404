import { CHAPTER_POST } from '../action';

const initialState = {
  posts: [],
  details: {}
};

export const chapterPostReducer = (state = initialState, action) => {
  switch (action.type) {
    case CHAPTER_POST:
      return {
        ...state,
        posts: action.payload.list,
        details: action.payload.about
      };
    default:
      return state;
  }
};
