//  @copyright : ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
// @author     : Shiv Charan Panjeta
// All Rights Reserved.
// Proprietary and confidential :  All information contained herein is, and remains
// the property of ToXSL Technologies Pvt. Ltd. and its partners.
// Unauthorized copying of this file, via any medium is strictly prohibited.

import { useEffect, useState } from 'react';
import * as adminService from '../../services/AuthServices';

import { Button, Container } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom';
import Select from 'react-select';
import AsyncCreatableSelect from 'react-select/async-creatable';
import CreatableSelect from 'react-select/creatable';
import DefaultLogo from '../../assets/images/institute-dummy.jpg';
import { getYearDropDown, restrictAlpha } from '../../globals/helper';
import useDocumentTitle from '../../globals/useDocumentTitle';
import showNotification from '../../services/NotificationService';

export default function AddEducation() {
  const location = useLocation();

  const id = location.state;

  const navigate = useNavigate();

  const [instituteId, setInstituteId] = useState();
  const [departmentList, setDepartmentList] = useState([]);
  const [departmentId, setDepartmentId] = useState();
  const [eduCourseList, setEduCourseList] = useState('');
  const [eduCourseId, setEduCourseId] = useState({});
  const [fieldList, setFieldList] = useState('');
  const [specializationList, setSpecializationList] = useState([]);
  const [yearOptions, setYearOptions] = useState();
  const [selectedYear, setSelectedYear] = useState('');
  const [clicked, setClicked] = useState(false);
  const [isNewCourse, setIsNewCourse] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [educationDetail, setEducationDetail] = useState({
    institute_id: '',
    department: '',
    course_name: '',
    qualification_type: '',
    field_of_study: '',
    specialization: '',
    reg_id: '',
    to_year: '',
    currently_studing_here: ''
  });
  const qualification = [
    {
      label: 'high school',
      value: 'High School'
    },
    {
      label: 'secondary school',
      value: 'Sr. Secondary School'
    },
    {
      label: 'bachelors degree',
      value: 'Bachelors Degree'
    },
    {
      label: 'masters degree',
      value: 'Masters Degree'
    },
    {
      label: 'doctorate',
      value: 'Doctorate/Phd'
    },
    {
      label: 'others',
      value: 'Others'
    }
  ];

  useEffect(() => {
    if (typeof location?.state === 'object') {
      setIsUpdate({
        user_id: location?.state?.user_id,
        ed_id: location?.state?.user_qualification_id
      });
      dataHandler(location?.state);
    }
    loadInstituteOptions();
    getCourseDropDown();
    getCourseDropDown();
    getFieldOfStudyDropDown();
    getSpecializationDropDown();
    getYearDropDown(setYearOptions);
  }, []);

  const dataHandler = (data) => {
    setEducationDetail({
      institute_id: data?.institute_id,
      department: data?.department_name == '0' ? null : data?.department_name,
      course_name: data?.course_name || '',
      qualification_type: data?.qualification_type || '',
      field_of_study: data?.field_name || '',
      specialization: data?.specialization || '',
      reg_id: data?.reg_id == '0' ? null : data?.reg_id,
      to_year: data?.to_year === '0' ? null : data?.to_year,
      currently_studing_here: data?.currently_studing_here
    });
    setInstituteId({
      id: data?.institute_id,
      label: data?.institute_name,
      value: data?.institute_name,
      logo: DefaultLogo
    });
    setDepartmentId({
      label: data?.department_name == '0' ? null : data?.department_name,
      value: data?.department_name == '0' ? null : data?.department_name,
      logo: DefaultLogo
    });
    setEduCourseId({
      label: data?.course_name == '0' ? null : data?.course_name,
      value: data?.course_id == '0' ? null : data?.course_id,
      logo: DefaultLogo
    });
    setSelectedYear({
      label: data?.to_year === '0' ? null : data?.to_year,
      value: data?.to_year === '0' ? null : data?.to_year
    });
  };

  const loadInstituteOptions = (inputValue) => {
    return adminService.getInstituteDrop(inputValue).then((res) => {
      if (res?.status === 200) {
        let tempArr = [];
        if (res?.data?.length) {
          const data = res?.data;
          data?.map((data) => {
            tempArr.push({
              id: data?.institute_id,
              label: data?.institutename,
              value: data?.institutename,
              logo: data?.logo
                ? process.env.REACT_APP_API_DOMAIN + '/common/uploads/institute_logo/' + data?.logo
                : DefaultLogo
            });
          });
        }
        return tempArr;
      }
    });
  };

  const loadDepartmentOptions = (inputValue) => {
    if (inputValue.length) {
      return adminService.getInsDepartDrop(inputValue, instituteId?.id || '').then((res) => {
        if (res?.status === 200) {
          let tempArr = [];
          if (res?.data?.length) {
            const data = res?.data;
            data?.map((data) => {
              tempArr.push({
                label: data?.institutename,
                value: data?.institutename
                // logo: DefaultLogo
              });
            });
          }
          return tempArr;
        }
      });
    }
  };

  // const getDepartmentDropDown = () => {
  //   adminService.getInsDepartDrop().then((res) => {
  //     if (res?.status === 200) {
  //       let list = res?.data;
  //       let tempArr = [];
  //       list?.map((data) => {
  //         tempArr.push({
  //           label: data?.institutename,
  //           value: data?.institutename,
  //           logo: data?.logo
  //             ? process.env.REACT_APP_API_DOMAIN + '/common/uploads/institute_logo/' + data?.logo
  //             : DefaultLogo
  //         });
  //       });
  //       setDepartmentList(tempArr);
  //     }
  //   });
  // };

  const getCourseDropDown = () => {
    adminService.getEduCourseDrop().then((res) => {
      if (res?.status === 200) {
        let list = res?.data;
        let tempArr = [];
        list?.map((data) => {
          tempArr.push({
            label: data?.course_name,
            value: data?.course_id
          });
        });
        setEduCourseList(tempArr);
      }
    });
  };

  const getFieldOfStudyDropDown = () => {
    adminService.fieldOfStudyDropDown().then((res) => {
      if (res?.status === 200) {
        let list = res?.data?.data;
        let arr = [];
        list?.map((data) => {
          arr.push({ id: data?.id, label: data?.name, value: data?.name });
        });
        setFieldList(arr);
      }
    });
  };

  const getSpecializationDropDown = (field = '') => {
    adminService.specializationDropDown('', field).then((res) => {
      if (res?.status === 200) {
        let list = res?.data;
        let arr = [];
        list?.map((data) => {
          arr.push({ label: data?.name, value: data?.name });
        });
        setSpecializationList(arr);
      }
    });
  };

  const createOption = (label) => ({
    id: '',
    label,
    value: label.toLowerCase().replace(/\W/g, ''),
    logo: DefaultLogo
  });

  const handleCreateIns = (inputValue) => {
    const newOption = createOption(inputValue);
    setInstituteId(newOption);
  };

  const handleCreateDep = (inputValue) => {
    const newOption = createOption(inputValue);
    setDepartmentId(newOption);
  };

  const onChangeHandler = (e, option) => {
    if (e?.__isNew__) {
      setEducationDetail({ ...educationDetail, [option?.name]: e });
    } else {
      if (option?.name === 'institute_id') {
        setEducationDetail({ ...educationDetail, [option?.name]: e?.id });
      } else {
        setEducationDetail({ ...educationDetail, [option?.name]: e?.value });
      }
    }
  };

  const submitHandler = (e) => {
    e.preventDefault();
    setClicked(true);
    if (!Object.keys(validate(educationDetail)).length) {
      setClicked(false);
      adminService.addEducationDetail(id, educationDetail).then((res) => {
        if (res?.status === 200) {
          showNotification('success', res?.data?.message);
          navigate(-1);
        }
      });
    }
  };

  const updateHandler = (e) => {
    e.preventDefault();
    setClicked(true);
    if (!Object.keys(validate(educationDetail)).length) {
      setClicked(false);
      adminService
        .editEducationDetail(isUpdate.user_id, isUpdate?.ed_id, educationDetail)
        .then((res) => {
          if (res?.status === 200) {
            showNotification('success', res?.data?.message);
            navigate(-1);
          }
        });
    }
  };

  const validate = (value) => {
    let errors = {};
    if (!value?.institute_id) {
      errors.institute_id = 'This field is required';
    }
    if (!value?.department) {
      errors.department = 'This field is required';
    }
    if (isNewCourse && !value?.qualification_type) {
      errors.qualification_type = 'This field is required';
    }

    if (educationDetail?.currently_studing_here == '0' && value?.to_year === null) {
      errors.to_year = 'Passout year is required. If your are not currently studing.';
    }
    return errors;
  };

  return (
    <div className="dash-main-content">
      <Container fluid>
        <div className="dash-page-content">
          <div className="dash-page-title">
            <Row>
              <Col xs={12} sm={12} md={12}>
                <div className="dash-title-bar d-flex align-items-center justify-content-between">
                  <div className="title-lft d-flex align-items-center">
                    <div className="dash-icn">
                      <i className="fas fa-graduation-cap"></i>
                    </div>
                    <div className="dash-title-dis">
                      {id
                        ? useDocumentTitle('Edit Education | AlmaBay')
                        : useDocumentTitle('Add  Education | AlmaBay')}

                      {<h5>{id ? 'Edit' : 'Add'} Education</h5>}
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <div className="dash-content-card">
            <div className="cmmn-form">
              <Form>
                <Row>
                  <Col md={6}>
                    <Form.Group className="mb-3" controlId="formBasicDesi">
                      <Form.Label>
                        Institute <sup className="text-danger">*</sup>{' '}
                      </Form.Label>
                      <AsyncCreatableSelect
                        cacheOptions
                        className="basic-single"
                        classNamePrefix="select"
                        isSearchable={true}
                        name="institute_id"
                        loadOptions={loadInstituteOptions}
                        placeholder="Select Intitute"
                        formatOptionLabel={(ins) => (
                          <div className="ins-option">
                            <img src={ins.logo} alt="logo" />
                            <span>{ins.label}</span>
                          </div>
                        )}
                        // options={options}
                        value={instituteId}
                        onChange={(e, option) => {
                          onChangeHandler(e, option);
                          setInstituteId(e);
                        }}
                        onCreateOption={(e) => {
                          handleCreateIns(e);
                          setEducationDetail({ ...educationDetail, ['institute_id']: e });
                        }}
                      />
                      <div
                        className={`${
                          clicked && validate(educationDetail).institute_id ? '' : 'd-none'
                        } danger-frm `}>
                        {clicked && validate(educationDetail).institute_id}
                      </div>
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="mb-3" controlId="formBasicDesi">
                      <Form.Label>
                        Department <sup className="text-danger">*</sup>
                      </Form.Label>
                      <AsyncCreatableSelect
                        cacheOptions
                        className="basic-single"
                        classNamePrefix="select"
                        isSearchable={true}
                        name="department"
                        loadOptions={loadDepartmentOptions}
                        formatOptionLabel={(ins) => (
                          <div className="ins-option">
                            <img src={DefaultLogo} alt="logo" />
                            <span>{ins.label}</span>
                          </div>
                        )}
                        onChange={(e, option) => {
                          onChangeHandler(e, option);
                          setDepartmentId(e);
                        }}
                        placeholder="Select Intitute"
                        // options={options}
                        value={departmentId}
                        onCreateOption={(e) => {
                          handleCreateDep(e);
                          setEducationDetail({ ...educationDetail, ['department']: e });
                        }}
                      />
                      <div
                        className={`${
                          clicked && validate(educationDetail).department ? '' : 'd-none'
                        } danger-frm `}>
                        {clicked && validate(educationDetail).department}
                      </div>
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="mb-3" controlId="formBasicDesi">
                      <Form.Label>Course / Program</Form.Label>
                      <CreatableSelect
                        className="basic-single"
                        classNamePrefix="select"
                        isDisabled={false}
                        isLoading={false}
                        isClearable={false}
                        isRtl={false}
                        isSearchable={true}
                        value={eduCourseId}
                        options={eduCourseList}
                        onChange={(e, option) => {
                          e?.__isNew__ ? setIsNewCourse(true) : setIsNewCourse(false);
                          setEduCourseId(e);
                          onChangeHandler(e, option);
                        }}
                        name="course_name"
                        placeholder="Select Course/Program"
                      />
                    </Form.Group>
                  </Col>
                  {isNewCourse ? (
                    <>
                      {' '}
                      <Col md={6}>
                        <Form.Group className="mb-3" controlId="formBasicDesi">
                          <Form.Label>
                            Qualification Level <sup className="text-danger">*</sup>
                          </Form.Label>
                          <Select
                            className="basic-single"
                            classNamePrefix="select"
                            isDisabled={false}
                            isLoading={false}
                            isClearable={false}
                            isRtl={false}
                            isSearchable={true}
                            options={qualification}
                            onChange={(e, option) => {
                              onChangeHandler(e, option);
                            }}
                            name="qualification_type"
                            placeholder="Select Qualification Level"
                          />
                        </Form.Group>
                      </Col>
                      <Col md={6}>
                        <Form.Group className="mb-3" controlId="formBasicDesi">
                          <Form.Label>Field of study</Form.Label>
                          <CreatableSelect
                            className="basic-single"
                            classNamePrefix="select"
                            isDisabled={false}
                            isLoading={false}
                            isClearable={false}
                            isRtl={false}
                            isSearchable={true}
                            options={fieldList}
                            onChange={(e, option) => {
                              getSpecializationDropDown(e.id);
                              onChangeHandler(e, option);
                            }}
                            // onChange={(e, option) => { onChangeHandler(e, option) }}
                            name="field_of_study"
                            placeholder="Enter your field of study"
                          />
                        </Form.Group>
                      </Col>
                      <Col md={6}>
                        <Form.Group className="mb-3" controlId="formBasicDesi">
                          <Form.Label>Specialization</Form.Label>
                          <CreatableSelect
                            className="basic-single"
                            classNamePrefix="select"
                            isDisabled={false}
                            isLoading={false}
                            isClearable={false}
                            isRtl={false}
                            onChange={(e, option) => {
                              onChangeHandler(e, option);
                            }}
                            isSearchable={true}
                            options={specializationList}
                            name="specialization"
                            placeholder="Enter your specialization"
                          />
                        </Form.Group>
                      </Col>
                    </>
                  ) : (
                    ''
                  )}
                  <Col md={6}>
                    <Form.Group className="mb-3" controlId="formBasicDesi">
                      <Form.Label>Registration Number</Form.Label>
                      <Form.Control
                        type="text"
                        name="reg_id"
                        value={educationDetail?.reg_id}
                        onKeyPress={(e) => restrictAlpha(e)}
                        onChange={(e) => {
                          setEducationDetail({ ...educationDetail, ['reg_id']: e.target.value });
                        }}
                        placeholder="Enter registration number (Optional)"
                      />
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    {educationDetail?.currently_studing_here == '1' ? (
                      <Form.Label>
                        Passout Batch <sup className="text-danger">*</sup>
                        <br /> <h>Present</h>
                      </Form.Label>
                    ) : (
                      <Form.Group className="mb-3" controlId="formBasicDesi">
                        <Form.Label>
                          Passout Batch <sup className="text-danger">*</sup>
                        </Form.Label>
                        <Select
                          className="basic-single"
                          classNamePrefix="select"
                          isDisabled={false}
                          isLoading={false}
                          isClearable={false}
                          isRtl={false}
                          isSearchable={true}
                          value={selectedYear}
                          options={yearOptions}
                          onChange={(e, option) => {
                            onChangeHandler(e, option);
                            setSelectedYear(e);
                          }}
                          name="to_year"
                          placeholder="Enter passout year"
                        />
                        <div
                          className={`${
                            clicked && validate(educationDetail).to_year ? '' : 'd-none'
                          } danger-frm `}>
                          {clicked && validate(educationDetail).to_year}
                        </div>
                      </Form.Group>
                    )}
                  </Col>
                  <Col md={12}>
                    <Form.Group className="mb-3 mt-3" controlId="formBasicCheckbox">
                      <Form.Check
                        type="checkbox"
                        checked={educationDetail?.currently_studing_here == '1'}
                        onChange={(e) => {
                          setEducationDetail({
                            ...educationDetail,
                            ['currently_studing_here']: e.target.checked ? 1 : 0
                          });
                        }}
                        name="currently_studing_here"
                        label="Currently studying here"
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <div className="form-btns d-flex align-items-center mt-3">
                  {isUpdate ? (
                    <Button className="main-btn-grn" onClick={updateHandler}>
                      Save
                    </Button>
                  ) : (
                    <Button className="main-btn-grn" onClick={submitHandler}>
                      Save
                    </Button>
                  )}
                  <strong>OR</strong>
                  <Button
                    className="main-btn-red"
                    onClick={(e) => {
                      e.preventDefault();
                      navigate(-1);
                    }}>
                    Cancel
                  </Button>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}
