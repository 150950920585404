import { Col, Container, Row } from 'react-bootstrap';
import HeaderLogin from '../../commoncomponents/HeaderLogin';
import TopTabbings from './TopTabbings';
import NewsSidebar from '../../commoncomponents/newsfeeds/NewsSidebar';
import ReactPollComponnet from '../../commoncomponents/ReactPollComponnet';

import WhatsNew from '../../commoncomponents/newsfeeds/WhatsNew';
import Upcoming from '../../commoncomponents/newsfeeds/Upcoming';
import PeopleKnow from '../../commoncomponents/newsfeeds/PeopleKnow';
import FollowCompany from '../../commoncomponents/newsfeeds/FollowCompany';
import CampusNews from '../../commoncomponents/newsfeeds/CampusNews';
import CampusnewsText from '../../commoncomponents/newsfeeds/CampusnewsText';
import ArticalBlogs from '../../commoncomponents/newsfeeds/ArticalBlogs';
import GroupDiscover from '../../commoncomponents/newsfeeds/GroupDiscover';
import { MiniFooter } from '../../MiniFooter';

function ResponsePoll() {
  return (
    <>
      <HeaderLogin />
      <div className="header-lnk-feeds">
        <div className="container">
          <TopTabbings state={'social'} />
        </div>
      </div>
      <section className="poll-sec bg-grey py-3">
        <Container>
          <Row>
            <Col xs={12} md={12} lg={2} xl={2}>
              <NewsSidebar state="1" current="polls" />
            </Col>
            <Col xs={12} md={12} lg={7} xl={7} className="mb-4">
              <div className="bg-white poll-card">
                <div className="poll-hd">
                  <h4>Poll Questions</h4>
                </div>
                <div className="poll-question-sec p-3">
                  <ReactPollComponnet />
                </div>
              </div>
            </Col>
            <Col xs={12} md={12} lg={3} xl={3} className="mb-4">
              <div>
                <PeopleKnow />
              </div>
              <div>
                <GroupDiscover />
              </div>
              <div>
                <FollowCompany />
              </div>
              <div>
                <CampusNews />
              </div>
              <div>
                <ArticalBlogs />
              </div>
              <div>
                <CampusnewsText />
              </div>
              <div>
                <WhatsNew />
              </div>
              <div>
                <Upcoming />
              </div>
              <MiniFooter />
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}

export default ResponsePoll;
