import { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import HeaderLogin from '../../commoncomponents/HeaderLogin';
import TopTabbings from './TopTabbings';

import Dropdown from 'react-bootstrap/Dropdown';
import { addFriendHandle } from '../../../globals/helper';
import useDocumentTitle from '../../../globals/useDocumentTitle';
import * as APISERVICES from '../../../services/SocialServices';
import { MiniFooter } from '../../MiniFooter';
import ArticalBlogs from '../../commoncomponents/newsfeeds/ArticalBlogs';
import CampusNews from '../../commoncomponents/newsfeeds/CampusNews';
import CampusnewsText from '../../commoncomponents/newsfeeds/CampusnewsText';
import FollowCompany from '../../commoncomponents/newsfeeds/FollowCompany';
import GroupDiscover from '../../commoncomponents/newsfeeds/GroupDiscover';
import Upcoming from '../../commoncomponents/newsfeeds/Upcoming';
import WhatsNew from '../../commoncomponents/newsfeeds/WhatsNew';
import WorkAnniversary from '../../commoncomponents/newsfeeds/WorkAnniversary';
import { FriendsSkelton } from '../../commoncomponents/skelton/allskelton';

function Pymk() {
  useDocumentTitle('People You May Know | AlmaBay');
  const [suggested, setSuggested] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [clickId, setClickId] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    getSuggestedFriends();
  }, []);

  const getSuggestedFriends = () => {
    setLoading(true);
    APISERVICES.getUserSuggestedFriendsApi().then((resp) => {
      setLoading(false);
      if (resp?.status === 200) {
        setSuggested(resp?.data?.data);
      }
    });
  };

  return (
    <>
      <HeaderLogin />
      <div className="header-lnk-feeds">
        <div className="container">
          <TopTabbings state={'social'} />
        </div>
      </div>
      <section className="news-feed-sec bg-grey py-4">
        <Container>
          <Row>
            <Col xs={12} md={12}>
              <div className="pmky_head_bar">
                <div className="heading-buttons">
                  <span className="info-box-icon pull-left">
                    <img src={require('../../../assets/images/user-people.png')} alt="user" />
                  </span>
                  <h4 className="innerTB margin-none">People You May Know</h4>
                </div>
              </div>
            </Col>

            <Col xs={12} lg={9} md={12}>
              <div className="pmky-inr-card">
                <Row>
                  {loading ? (
                    <>
                      {Array.from(Array(8), function (index) {
                        return (
                          <Col md={3} sm={6} xs={6}>
                            <FriendsSkelton />
                          </Col>
                        );
                      })}
                    </>
                  ) : suggested && suggested?.length > 0 ? (
                    suggested?.map((data, index) => {
                      return (
                        <Col md={3} sm={6} xs={6} key={index}>
                          <div className="pmky-pro-card">
                            <div className="pmky-img">
                              <img
                                src={
                                  process.env.REACT_APP_API_DOMAIN +
                                  data?.url +
                                  '.' +
                                  data.extension
                                }
                                alt="logo"
                              />
                            </div>
                            <div className="pmky-dis">
                              <h4>{data?.name}</h4>
                              <p>{data?.organisation}</p>
                              <Dropdown className="mt-2">
                                <Dropdown.Toggle variant="success" id="dropdown-basic">
                                  Your friend
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                  <Dropdown.Item
                                    href="#"
                                    onClick={() => navigate(`/user/timeline/${data?.id}`)}>
                                    View Profile
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    href="#"
                                    onClick={() => navigate(`/user/messages/${data?.id}`)}>
                                    Send Message
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    href="#"
                                    onClick={(e) => {
                                      addFriendHandle(
                                        e,
                                        data.ID,
                                        getSuggestedFriends,
                                        setIsLoading
                                      );
                                      setClickId(data?.id);
                                    }}>
                                    Add friend
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            </div>
                          </div>
                        </Col>
                      );
                    })
                  ) : (
                    <tr className="text-center">Data Not Found</tr>
                  )}
                </Row>
              </div>
              {suggested && suggested?.length > 10 && (
                <div className="load-more-btn py-3 text-center mt-5">
                  <Link to="#" className="read-more">
                    More Suggestions
                  </Link>
                </div>
              )}
            </Col>
            <Col lg={3} md={12}>
              <div>
                <WorkAnniversary />
              </div>
              <div>
                <GroupDiscover />
              </div>
              <div>
                <FollowCompany />
              </div>
              <div>
                <CampusNews />
              </div>
              <div>
                <ArticalBlogs />
              </div>
              <div>
                <CampusnewsText />
              </div>
              <div>
                <WhatsNew />
              </div>
              <div>
                <Upcoming />
              </div>
              <MiniFooter />
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}

export default Pymk;
