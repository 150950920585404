import { MENU_LIST_DND, MENU_LIST_FAILED, MENU_LIST_PENDING, MENU_LIST_SUCCESS } from '../action';

const initialState = {
  list: [],
  isPending: false,
  hasError: '',
  totalCount: 0,
  allID: []
};

export const menuListReducer = (state = initialState, action) => {
  switch (action.type) {
    case MENU_LIST_PENDING:
      return {
        ...state,
        isPending: true
      };
    case MENU_LIST_SUCCESS:
      return {
        ...state,
        isPending: false,
        list: action.payload?.data,
        totalCount: action.payload?.total_count,
        allID: action.payload?.data?.map((data) => data.menu_id)
      };
    case MENU_LIST_FAILED:
      return {
        ...state,
        list: [],
        hasError: action.payload,
        totalCount: 0,
        allID: []
      };

    case MENU_LIST_DND:
      return {
        ...state,
        list: action.payload,
        hasError: '',
        allID: action.payload.map((data) => data.menu_id)
      };

    default:
      return state;
  }
};
