import { Link } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import logo2 from '../../assets/images/almabay-logo-2.png';
import Dropdown from 'react-bootstrap/Dropdown';
import * as APISERVICES from '../../services/AuthServices';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { logout } from '../../redux/action';
import { useSelector } from 'react-redux';
import { Container } from 'react-bootstrap';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Form from 'react-bootstrap/Form';
import {
  deleteCartProducts,
  getSearchAllInsitute,
  viewCartProducts
} from '../../services/ShopServices';
import showNotification from '../../services/NotificationService';
import { useEffect, useState } from 'react';
import Loader from './loader/loader-large';
import { ZERO } from '../../globals/constants';

function HeaderShop(props) {
  const { state, deleteProductsFunc, deleteState, stateCart } = props;
  const navigate = useNavigate();
  const dipatch = useDispatch();
  const isloggedIn = useSelector((state) => state?.user?.isloggedIn);
  let getId = localStorage.getItem('user_id');
  const userDetail = useSelector((state) => state?.user?.details);
  const [totalCartCount, setTotalCartCount] = useState();
  const [search, setSearch] = useState('');
  const [cartProductsState, setCartProductsState] = useState();

  const [searchContent, setSearchContent] = useState('');

  const [loading, setLoading] = useState(false);
  const logOutHandler = (e) => {
    e?.preventDefault();
    APISERVICES.logOut().then((res) => {
      if (res?.status === 200) {
        localStorage.clear();
        dipatch(logout());
        navigate('/login');
      }
    });
  };
  useEffect(() => {
    cartProducts();
  }, [isloggedIn, totalCartCount, state, stateCart]);

  const handleNavigate = (type) => {
    navigate(`/shop/products/${type}`);
  };

  const cartProducts = () => {
    setLoading(true);
    viewCartProducts(getId).then((resp) => {
      setLoading(false);

      if (resp?.status == 200) {
        setCartProductsState(resp?.data?.cartInfo);
        setTotalCartCount(resp?.data?.cartCount);
        setLoading(false);
      } else {
        showNotification('err', resp?.data?.message);
      }
    });
  };

  const deleteProducts = (e, id) => {
    e.preventDefault();
    deleteCartProducts(id).then((resp) => {
      if (resp?.status == 200) {
        cartProducts();
      }
    });
  };

  const headerSearch = (e) => {
    e.preventDefault();
    if (!e.target.value?.trim()) {
      return;
    } else {
      let body = {
        keyword: e.target.value
      };

      getSearchAllInsitute(body).then((resp) => {
        if (resp?.status === 200) {
          setSearchContent(resp?.data?.data);
        }
      });
    }
  };

  return (
    <>
      <section className="header-shop pt-3 pb-1">
        <Container>
          <div className="header-shop-inr">
            <Row className="d-flex align-items-center">
              <Col md={2} lg={2}>
                <div className="header-login-lft d-flex align-items-center">
                  <div className="head-logo header-shoap-logo">
                    <Link to={`${isloggedIn ? '/jobs' : '/'}`}>
                      <img src={logo2} className="main-logo" alt="logo" />
                    </Link>
                  </div>
                </div>
              </Col>
              <Col md={6} lg={6}>
                {isloggedIn ? (
                  <div className="header-srch header-srch-shp">
                    <Form
                    // onSubmit={(e) => {
                    //   e.preventDefault();
                    //   headerSearch();
                    // }}
                    >
                      <Form.Group className="form-srch mb-0" controlId="exampleForm.ControlInput1">
                        <span className="all-btn">All</span>
                        <Form.Control
                          type="search"
                          placeholder="CHOOSE YOUR CAMPUS"
                          onChange={(e) => {
                            setSearch(e.target.value);
                            headerSearch(e);
                          }}
                          autoComplete="off"
                        />
                      </Form.Group>
                    </Form>

                    {search ? (
                      <div className="form-list">
                        <div className="search-header">Search Results </div>
                        <div className="search-list-wrapper">
                          {searchContent && searchContent?.length ? (
                            searchContent?.map((data, index) => (
                              <li className="media" key={index}>
                                <Link to={`/shop/products/${data?.shortcode}`} state={data?.name}>
                                  <div className="media-right">
                                    <h5>{data?.name}</h5>
                                  </div>
                                </Link>
                              </li>
                            ))
                          ) : (
                            // <i className={`${search ? 'fas fa-spinner fa-spin' : 'd-none'}`}></i>
                            <p className="no-result">No result found !</p>
                          )}
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                ) : (
                  ''
                )}
              </Col>
              <Col md={4} lg={4}>
                {isloggedIn ? (
                  <div className="shop-hd-usr d-flex align-items-center justify-content-end">
                    <div className="user-drop">
                      <Dropdown>
                        <Dropdown.Toggle id="dropdown-basic">
                          <span className="user-pro-drop me-2">
                            <img
                              src={
                                userDetail?.avtar
                                  ? process.env.REACT_APP_API_DOMAIN + userDetail?.avtar
                                  : require('../../assets/images/birth4.png')
                              }
                              onError={({ currentTarget }) => {
                                currentTarget.onerror = null; // prevents looping
                                currentTarget.src = require("../../assets/images/birth4.png")
                              }}
                              className="main-logo"
                              alt="logo"
                            />
                          </span>
                          <p> Hello {userDetail?.name || userDetail?.first_name}</p>
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <Dropdown.Item as={Link} to={'/shop/cart'}>
                            Cart ({totalCartCount ? totalCartCount : 0})
                          </Dropdown.Item>
                          <Dropdown.Item as={Link} to={'/shop/orders'}>
                            My Orders
                          </Dropdown.Item>
                          <Dropdown.Item
                            href="#"
                            onClick={(e) => {
                              logOutHandler(e);
                            }}
                          >
                            not {userDetail?.name || userDetail?.first_name}? Sign out
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                    <div className="shophd-rt-card ms-3">
                      <Dropdown>
                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                          <i className="fas fa-shopping-cart"></i>
                          <span className="cart-prdct-count">{totalCartCount}</span>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <div className="drop-cart-list">
                            <ul>
                              {cartProductsState && cartProductsState?.length !== ZERO ? (
                                cartProductsState?.map((data, index) => {
                                  return (
                                    <li key={index}>
                                      {/* <Link to="#"> */}
                                      <div className="drop-card-dis ">
                                        {deleteState ? (
                                          <Link
                                            className="close-btn"
                                            to="#"
                                            onClick={(e) => deleteProductsFunc(e, data?.id)}
                                          >
                                            <i className="fas fa-times"></i>
                                          </Link>
                                        ) : (
                                          <Link
                                            className="close-btn"
                                            to="#"
                                            onClick={(e) => deleteProducts(e, data?.id)}
                                          >
                                            <i className="fas fa-times"></i>
                                          </Link>
                                        )}

                                        <div className="drop-card-icn">
                                          <img
                                            src={
                                              process.env.REACT_APP_API_DOMAIN +
                                              'common/uploads/products/' +
                                              data?.cover_image
                                            }
                                            alt="logo"
                                          />
                                        </div>
                                        <div className="drop-dis">
                                          <Link to={`/shop/details/${data.product_id}`}>
                                            <h4>{data?.name}</h4>
                                          </Link>
                                          <p>
                                            <span className="price-calc">
                                              <i className="fas fa-rupee-sign me-1"></i>
                                              {data?.price}x{data?.quantity}=
                                            </span>
                                            <span className="price-calc-ttl">
                                              <i className="fas fa-rupee-sign me-1"></i>
                                              {data?.subtotal}
                                            </span>
                                          </p>
                                        </div>
                                      </div>
                                      {/* </Link> */}
                                    </li>
                                  );
                                })
                              ) : (
                                <p className="cart-empty">Empty Shopping cart</p>
                              )}
                            </ul>
                          </div>

                          <Link className="cart-link" to="/shop/cart">
                            <i className="fas fa-shopping-cart me-1"></i>
                            View cart
                          </Link>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </div>
                ) : (
                  <div className="header-shop-rt d-flex align-items-center justify-content-end">
                    <h4>
                      Have an account? <Link to="/login">Sign In </Link>
                    </h4>
                    <div className="shophd-rt-card ms-3">
                      <Dropdown>
                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                          <i className="fas fa-shopping-cart"></i>
                          <span className="cart-prdct-count">{totalCartCount}</span>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <div className="drop-cart-list">
                            <ul>
                              {cartProductsState && cartProductsState?.length !== ZERO ? (
                                cartProductsState?.map((data, index) => {
                                  return (
                                    <li key={index}>
                                      <Link to="#">
                                        <div className="drop-card-dis ">
                                          {deleteState ? (
                                            <Link
                                              className="close-btn"
                                              to="#"
                                              onClick={(e) => deleteProductsFunc(e, data?.id)}
                                            >
                                              <i className="fas fa-times"></i>
                                            </Link>
                                          ) : (
                                            <Link
                                              className="close-btn"
                                              to="#"
                                              onClick={(e) => deleteProducts(e, data?.id)}
                                            >
                                              <i className="fas fa-times"></i>
                                            </Link>
                                          )}
                                          <div className="drop-card-icn">
                                            <img
                                              src={
                                                process.env.REACT_APP_API_DOMAIN +
                                                'common/uploads/products/' +
                                                data?.cover_image
                                              }
                                              alt="logo"
                                            />
                                          </div>
                                          <div className="drop-dis">
                                            <Link to={`/shop/details/${data.product_id}`}>
                                              <h4>{data?.name}</h4>
                                            </Link>
                                            <p>
                                              <span className="price-calc">
                                                <i className="fas fa-rupee-sign me-1"></i>
                                                {data?.price}x{data?.quantity}=
                                              </span>
                                              <span className="price-calc-ttl">
                                                <i className="fas fa-rupee-sign me-1"></i>
                                                {data?.subtotal}
                                              </span>
                                            </p>
                                          </div>
                                        </div>
                                      </Link>
                                    </li>
                                  );
                                })
                              ) : (
                                <p className="cart-empty">Your Shopping Cart is empty.</p>
                              )}
                            </ul>
                          </div>

                          <Link className="cart-link" to="/shop/cart">
                            <i className="fas fa-shopping-cart me-1"></i>
                            View cart
                          </Link>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </div>
                )}
              </Col>
            </Row>
            <Navbar className="shop-navbar" bg="transparent" expand="lg">
              <Container className="justify-content-end justify-content-lg-between">
                <Navbar.Toggle aria-controls="navbarScroll" />
                <Navbar.Collapse id="navbarScroll">
                  <Nav className="me-auto my-2 my-lg-0" style={{ maxHeight: '100px' }} navbarScroll>
                    <NavDropdown title="Accessories" id="navbarScrollingDropdown">
                      <NavDropdown.Item onClick={() => handleNavigate('mugs')}>
                        Mugs
                      </NavDropdown.Item>

                      {/* <NavDropdown.Item href="#action4">Another action</NavDropdown.Item> */}
                    </NavDropdown>
                    <NavDropdown title="Apparel" id="navbarScrollingDropdown">
                      <NavDropdown.Item onClick={() => handleNavigate('hoodies')}>
                        Hoodies
                      </NavDropdown.Item>
                      <NavDropdown.Item onClick={() => handleNavigate('t-shirt')}>
                        T-Shirts
                      </NavDropdown.Item>
                    </NavDropdown>
                    <NavDropdown title="Office Utilities " id="navbarScrollingDropdown">
                      <NavDropdown.Item onClick={() => handleNavigate('diaries')}>
                        Diaries
                      </NavDropdown.Item>
                      {/* <NavDropdown.Item href="#action4">Another action</NavDropdown.Item> */}
                    </NavDropdown>
                    <Nav.Link href="https://blog.almabay.com/" target={'_blank'}>
                      Blog
                    </Nav.Link>
                  </Nav>
                </Navbar.Collapse>
              </Container>
            </Navbar>
          </div>
        </Container>
      </section>
      {loading ? <Loader /> : ''}
    </>
  );
}

export default HeaderShop;
