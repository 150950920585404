import { ADD_MENU_LIST_FAILED, ADD_MENU_LIST_PENDING, ADD_MENU_LIST_SUCCESS } from '../action';

const initialState = {
  message: '',
  isLoading: false,
  hasError: ''
};

export const addMenuListReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_MENU_LIST_PENDING:
      return {
        ...state,
        isLoading: true
      };

    case ADD_MENU_LIST_SUCCESS:
      return {
        ...state,
        message: action.payload,
        route: action.route,
        isLoading: false
      };

    case ADD_MENU_LIST_FAILED:
      return {
        ...state,
        message: '',
        route: '',
        isLoading: false,
        hasError: action.payload
      };

    default:
      return state;
  }
};
