import { Link } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import logo2 from '../../assets/images/almabay-logo-2.png';
import { Container, Navbar } from 'react-bootstrap';

function HeaderRecuirter() {
  return (
    <>
      <div className="header-rect-outr">
        <Container>
          <div className="header-recuirter w-100">
            <Row className="align-items-center">
              <Col md={6} lg={7}>
                <div className="header-inr-lft d-flex align-items-center">
                  <Navbar.Brand href="#home" className="head-logo">
                    <Link to="/">
                      <img src={logo2} className="main-logo" alt="logo" />
                    </Link>
                  </Navbar.Brand>
                </div>
              </Col>
              <Col md={4} lg={5}>
                <div className="header-recurt-rt d-flex align-items-center justify-content-end">
                  <Link to={`/jobs/search`}>For Jobseekers</Link>
                  <Link to={`/recruiter-solutions`}>Solutions</Link>
                  <button className="signup-recut">Sign Up</button>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
}

export default HeaderRecuirter;
