/**
 * @copyright : ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
@author    : Shiv Charan Panjeta 
 
All Rights Reserved.
Proprietary and confidential :  All information contained here in is, and remains
the property of ToXSL Technologies Pvt. Ltd. and it's partners.
Unauthorized copying of this file, via any medium is strictly prohibited.
 */

import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { useDispatch } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import useDocumentTitle from '../../../globals/useDocumentTitle';
import { loginInfo, logout } from '../../../redux/action/index';
import * as APISERVICE from '../../../services/AuthServices';
import showNotification from '../../../services/NotificationService';
import { getAttemptedPollResponse } from '../../../services/SocialServices';
import FooterMain from '../../commoncomponents/FooterMain';
import SocialMedia from '../../commoncomponents/socialMediaBtns/SocialMedia';

// import reactReferer from 'react-referer';

function LoginPage() {
  const navigate = useNavigate();
  const dipatch = useDispatch();
  const location = useLocation();

  useDocumentTitle('Login to AlmaBay | AlmaBay');
  const dispatch = useDispatch();
  const [loginDetails, setLoginDetails] = useState({
    email: '',
    password: ''
  });
  const [isRememberMe, setIsRemberMe] = useState(false);
  const [checkCred, setCheckCred] = useState(false);
  const [copyRoute, setCopyRoute] = useState('');
  //input-value
  const onChangeHandler = (e) => {
    const { name, value } = e.target;
    setLoginDetails({ ...loginDetails, [name]: value });
  };

  useEffect(() => {
    localStorage.clear();
    dipatch(logout());
  }, []);

  const validate = (values) => {
    let errors = {};
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    if (!values.email) {
      errors.email = 'Please enter your email';
    } else if (!regex.test(values.email)) {
      errors.email = 'Invalid email format';
    }
    if (!values.password) {
      errors.password = 'Please enter your password';
    } else if (values.password.length < 6) {
      errors.password = 'Password must be more than 6 characters';
    }
    return errors;
  };
  // onClick form submit-api call
  const submitHandler = (e) => {
    e.preventDefault();
    setCheckCred(true);
    if (!validate(loginDetails).password && !validate(loginDetails).email) {
      let formData = new FormData();
      for (let i in loginDetails) {
        formData.append(`${i}`, loginDetails[i]);
      }
      APISERVICE.Login(formData).then((res) => {
        if (res?.status === 200) {
          if (res?.data?.data) {
            dispatch(loginInfo(res?.data?.data));
            localStorage.setItem('token', res?.data?.access_token);
            localStorage.setItem('userDetail', JSON.stringify(res?.data?.data));
            hitPollApi();
            showNotification('success', res?.data?.message);
            if (location?.state === '/mentors-directory') {
              navigate('/mentors/directory');
              return;
            }
            if (location?.state?.name === 'event-details') {
              navigate(location?.state?.path);
              return;
            }

            if (
              /localhost:5557/.test(copyRoute) ||
              /localhost:5555/.test(copyRoute) ||
              /almabay.co.in/.test(copyRoute)
            ) {
              // navigate(copyRoute);
              window.location.href = copyRoute;
              setCopyRoute('');
            } else {
              if (
                res?.data?.data?.feed_section == '' ||
                res?.data?.data?.feed_section == 'social'
              ) {
                if (location?.state == null) {
                  navigate('/user');
                } else {
                  navigate(location?.state, { replace: true });
                }
              }

              if (res?.data?.data?.feed_section == 'professional') {
                if (location?.state == null) {
                  navigate('/jobs', { replace: true });
                  // return;
                } else {
                  navigate(location?.state, { replace: true });
                }
              }
              if (res?.data?.data?.feed_section == 'mentorship') {
                if (location?.state == null) {
                  navigate('/mentors/qafeeds');
                } else {
                  navigate(location?.state, { replace: true });
                }
              }
            }
          }
        }
      });
    }
  };
  // api for attempted polls of user
  const hitPollApi = () => {
    getAttemptedPollResponse().then((res) => {
      if (res?.status == 200) {
        localStorage.setItem('react-polls', JSON.stringify(res?.data?.data));
      }
    });
  };

  return (
    <>
      {/* <Loader/> */}
      <section className="login-card py-5 bg-blue align-items-center d-flex">
        <div className="container">
          <Row>
            <Col md={{ span: 4, offset: 4 }}>
              <div className="lgn-frm-card">
                <h4 className="mb-4">Login</h4>
                <div className="lgn-frm">
                  <Form>
                    <Form.Group className="mb-3 login-form-card" controlId="formBasicEmail">
                      <Form.Control
                        type="email"
                        placeholder="Enter email address "
                        name="email"
                        value={loginDetails?.email}
                        onChange={(e) => {
                          onChangeHandler(e);
                        }}
                        isInvalid={checkCred && validate(loginDetails)?.email}
                        autoComplete="off"
                      />
                      {checkCred && validate(loginDetails)?.email ? (
                        <span
                          className={
                            `${checkCred && validate(loginDetails)?.email}`
                              ? 'login-error'
                              : 'd-none'
                          }>
                          {checkCred && validate(loginDetails)?.email}
                        </span>
                      ) : (
                        ''
                      )}
                    </Form.Group>

                    <Form.Group className="mb-3 login-form-card" controlId="formBasicPassword">
                      <Form.Control
                        type="password"
                        placeholder="Password"
                        name="password"
                        onChange={(e) => {
                          onChangeHandler(e);
                        }}
                        isInvalid={checkCred && validate(loginDetails)?.password}
                      />
                      {checkCred && validate(loginDetails)?.password ? (
                        <span
                          className={
                            `${checkCred && validate(loginDetails)?.password}`
                              ? 'login-error'
                              : 'd-none'
                          }>
                          {checkCred && validate(loginDetails)?.password}
                        </span>
                      ) : (
                        ''
                      )}
                    </Form.Group>
                    <div className="forgot-card flex-wrap d-flex align-items-center justify-content-between">
                      <Form.Group controlId="formBasicCheckbox">
                        <Form.Check
                          type="checkbox"
                          label="Remember me"
                          onClick={() => {
                            setIsRemberMe(!isRememberMe);
                          }}
                        />
                      </Form.Group>
                      <Link to="/recover/password" className="forgot-password-link">
                        Forgot password?
                      </Link>
                    </div>
                    <div className="frm-btn mt-4">
                      <Button
                        className="cmmn-btn"
                        onClick={(e) => {
                          submitHandler(e);
                        }}>
                        Log In
                      </Button>
                    </div>
                  </Form>
                </div>
                <SocialMedia />
                {/* <div className="email-lnk-card mt-3 text-center">
                  <Link className="email-lnk" to="/register">
                    Sign-up using email
                  </Link>
                </div> */}
              </div>
            </Col>
          </Row>
        </div>
      </section>
      <div className="footer-outr">
        <FooterMain className={'colour-bg'} />
      </div>
    </>
  );
}

export default LoginPage;
