import { Link } from 'react-router-dom';

function CampusnewsText() {
  return (
    <>
      <div className="suggest-mentor artical-blog-card bg-white p-3 mb-3">
        <div className="d-flex align-items-top justify-content-between flex-wrap">
          <h4>Campus News</h4>
        </div>
        <div className="artical-text">
          <p>Be Almabay campus Journalist and win lots goodie bags and cash prizes.</p>
          <p>Capture the Buzz on your campus and win lots of goodie bags and cash prizes.</p>
          <p>Be an Almabay Campus Journalist!</p>
          <p>More news likes equal to more dollars</p>
        </div>
        <Link className="artical-btn" to="/user/campus/add_news">
          Add Campus News
        </Link>
      </div>
    </>
  );
}

export default CampusnewsText;
