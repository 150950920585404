import * as api from '../utils/requests';

export const getMentorshipProfile = async (mentor_id) => {
  return await api.getReq(`/mentorship/profile/myProfile?mentor_id=${mentor_id}`);
};

export const getMentorshipProfileQuestions = async (body) => {
  return await api.PostReq(`/mentorship/mentorContentDetails`, body);
};

export const getMentorshipFollowCompany = async (page = '', mentorId = '') => {
  return await api.getReq(`/mentorship/profile/followCompanies?page=${page}&user_id=${mentorId}`);
};

export const getMentorshipArticleList = async () => {
  return await api.getReq('/mentorship/article/list');
};

export const getMentorshipUserFollowing = async () => {
  return await api.getReq('/mentorship/profile/following');
};

export const postMentorshipProfileSummary = async (body) => {
  return await api.PostReq('/mentorship/profile/editSummary', body);
};

export const getMentorshipMentorSuggestion = async () => {
  return await api.getReq('/mentorship/mentor-by-suggestion');
};

export const postMentorshipExpertise = async (body) => {
  return await api.PostReq('/mentorship/profile/addUpdateExpertise', body);
};

export const postMentorshipKeenToLearn = async (body) => {
  return await api.PostReq('/mentorship/profile/addUpdateKeenToLearn', body);
};

export const getMentorsDirectoryList = async (offset = 0, limit = 6, userid) => {
  return await api.getReq(
    `/user/getMentorsDirectoryList?offset=${offset}&limit=${limit}&userid=${userid}`
  );
};
