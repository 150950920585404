/**
@copyright  :   ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
@author     :   Shiv Charan Panjeta 
 
All Rights Reserved.
Proprietary and confidential :  All information contained here in is, and remains
the property of ToXSL Technologies Pvt. Ltd. and it's partners.
Unauthorized copying of this file, via any medium is strictly prohibited.
*/

import { useEffect, useState } from 'react';
import { Col, Container, Form, Row, Table } from 'react-bootstrap';
import ReactPaginate from 'react-paginate';
import { useNavigate } from 'react-router';
import NoDataFound from '../../../components/commoncomponents/NoDataFound';
import Loader from '../../../components/commoncomponents/loader/loader-large';
import useDocumentTitle from '../../../globals/useDocumentTitle';
import * as adminServices from '../../../services/AuthServices';

export default function TodayBirthdays() {
  useDocumentTitle('List of Upcoming Birthday Users | AlmaBay');
  const navigate = useNavigate();
  const [list, setList] = useState([]);
  const [response, setResponse] = useState('');
  const [loading, setLoading] = useState(false);

  const [searchedData, setSearchData] = useState({
    user_id: '',
    name: '',
    email: '',
    status: ''
  });
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    apiCall(
      1,
      'upcoming',
      searchedData?.user_id,
      searchedData?.name,
      searchedData?.email,
      searchedData?.status
    );
  }, [searchedData]);

  /**
   * function to get birthday list
   * // accepting params = page, type, user_id, first_name, email, status
   * @param {*} page
   * @param {*} type
   * @param {*} user_id
   * @param {*} first_name
   * @param {*} email
   * @param {*} status
   */
  const apiCall = (
    page = '',
    type = '',
    user_id = '',
    first_name = '',
    email = '',
    status = ''
  ) => {
    setLoading(true);
    setCurrentPage(page);
    adminServices.getBirthdayList(page, type, user_id, first_name, email, status).then((res) => {
      setLoading(false);
      if (res?.status === 200) {
        setList(
          res?.data?.detail?.upcoming?.users
            ? res?.data?.detail?.upcoming?.users
            : res?.data?.detail?.upcoming
        );
        setResponse(res?.data?.detail?.upcoming?.count);
      }
    });
  };

  /**
   * function to call on change the value of search field
   * @param {*} e
   */
  const onChangeSearch = (e) => {
    const { name, value } = e.target;
    setSearchData({ ...searchedData, [name]: value });
  };

  /**
   * function to call for search
   * @param {*} e
   */
  const searchHandler = (e) => {
    e.preventDefault();
    apiCall(
      currentPage,
      '',
      searchedData.user_id,
      searchedData.first_name,
      searchedData.email,
      searchedData.status
    );
  };

  /**
   * function for select dropdown
   * @param {*} value
   * @param {*} id
   */
  const selectHandler = (value, id) => {
    switch (value) {
      case 'view':
        navigate(`/admin/users/view/${id}`);
        break;
      default:
        break;
    }
  };

  /**
   * function to reset all fields
   * @param {*} e
   */
  const resetHandler = (e) => {
    e.preventDefault();
    setSearchData({
      user_id: '',
      first_name: '',
      email: '',
      status: ''
    });
    apiCall();
  };

  return (
    <>
      <div className="dash-main-content">
        <Container fluid>
          <div className="dash-page-content">
            <div className="dash-page-title">
              <Row>
                <Col xs={12} sm={12} md={12}>
                  <div className="dash-title-bar d-flex align-items-center justify-content-between">
                    <div className="title-lft d-flex align-items-center">
                      <div className="dash-icn">
                        <i className="fa fa-birthday-cake"></i>
                      </div>
                      <div className="dash-title-dis">
                        <h5>
                          Upcoming Birthday Users <span>Listing</span>
                        </h5>
                        {list?.length !== 0 && (
                          <h5>
                            Total Users(s): <span>{list?.length ? list?.length : '0'}</span>
                          </h5>
                        )}
                      </div>
                    </div>
                    {/* <div className="title-rt-btn">
                      <div className="head-instite-sec">
                        <Form.Select aria-label="Default select example ">
                          <option>Choose Action</option>
                          <option value="1">Enable</option>
                          <option value="2">Disable</option>
                          <option value="2">Delete</option>
                        </Form.Select>
                      </div>
                    </div> */}
                  </div>
                </Col>
              </Row>
            </div>
            <div className="dash-content-card">
              <div className="table-responsive">
                <Table className="custom-table-data" striped bordered hover>
                  <thead>
                    <tr>
                      <th>Image</th>
                      <th>User ID</th>
                      <th>Name</th>
                      <th>Email</th>
                      <th>DOB</th>
                      <th>Status</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="search-table">
                      <td></td>
                      <td>
                        <Form.Control
                          type="text"
                          value={searchedData.user_id}
                          name="user_id"
                          placeholder="Id"
                          onChange={(e) => {
                            onChangeSearch(e);
                          }}
                        />
                      </td>
                      <td>
                        <Form.Control
                          type="text"
                          value={searchedData.first_name}
                          name="first_name"
                          placeholder="Name"
                          onChange={(e) => {
                            onChangeSearch(e);
                          }}
                        />
                      </td>
                      <td>
                        <div className="d-flex align-items-center">
                          <Form.Control
                            type="text"
                            value={searchedData?.email}
                            name="email"
                            placeholder="email"
                            onChange={(e) => {
                              onChangeSearch(e);
                            }}
                          />
                        </div>
                      </td>
                      <td></td>
                      <td>
                        <Form.Select
                          aria-label="Default select example"
                          name="status"
                          value={searchedData?.status}
                          onChange={(e) => {
                            onChangeSearch(e);
                          }}>
                          <option value="">Select</option>
                          <option value={'pending'}>pending</option>
                          <option value={'approved'}>approved</option>
                          <option value={'rejected'}>Rejected</option>
                        </Form.Select>
                      </td>
                      <td className="serac-btns" colSpan={2}>
                        <button
                          type="button"
                          className="green_button_admin"
                          onClick={(e) => {
                            searchHandler(e);
                          }}>
                          Search
                        </button>
                        {/* <Button
                          className="main-btn-red ms-2"
                          onClick={(e) => {
                            resetHandler(e);
                          }}>
                          Reset
                        </Button> */}
                      </td>
                    </tr>
                    {list && list?.length ? (
                      list?.map((data, index) => {
                        return (
                          <tr key={index}>
                            <td>
                              <img
                                src={require('../../../assets/images/birth4.png')}
                                alt="img_user"
                                className="profile-image"
                              />
                            </td>
                            <td>{data?.user_id || 'NA'}</td>
                            <td>{data?.name || 'NA'}</td>
                            <td>{data?.email || 'NA'} </td>
                            <td>{data?.db + ',' + data?.dob_y || 'NA'} </td>
                            <td>{data?.status || 'NA'}</td>
                            <td>
                              <Form.Select
                                aria-label="Default select example"
                                id="select"
                                value={''}
                                onChange={(e) => {
                                  selectHandler(e.target.value, data?.user_id);
                                }}>
                                <option value="">Select</option>
                                <option value="view">View</option>
                              </Form.Select>
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr className="text-center">
                        <td colSpan={8}>
                          <NoDataFound text="OOPS! We can't find any birthday of your friends. Try adding some friends." />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </div>
              {Math.ceil(response / 10) > 1 && (
                <div className="react-page d-flex justify-content-center">
                  <ReactPaginate
                    previousLabel={'Prev'}
                    nextLabel={'Next'}
                    activeClassName={'page-link active'}
                    onPageChange={(props) => {
                      setCurrentPage(props.selected + 1);
                    }}
                    pageCount={Math.ceil(response / 10)}
                  />
                </div>
              )}
            </div>
          </div>
        </Container>
      </div>
      {loading ? <Loader /> : ''}
    </>
  );
}
