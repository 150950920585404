/**
@copyright  :   ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
@author     :   Shiv Charan Panjeta 
 
All Rights Reserved.
Proprietary and confidential :  All information contained here in is, and remains
the property of ToXSL Technologies Pvt. Ltd. and it's partners.
Unauthorized copying of this file, via any medium is strictly prohibited.
*/
import { useEffect, useRef, useState } from 'react';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import { addAlternativeImages, getAlternativeImageDetail } from '../../../services/AuthServices';
import showNotification from '../../../services/NotificationService';

function AddImageProduct() {
  const imgRef = useRef();
  const navigate = useNavigate();
  const [colour, setColour] = useState('');
  const [image, setImage] = useState('');
  const [clicked, setClicked] = useState(false);
  const [imagePreview, setImagePreview] = useState('');
  let location = useLocation();
  const id = location?.state?.id;
  const pId = location?.state?.productId;

  const validate = () => {
    let errors = {};
    if (colour == '') {
      errors.colour = 'Select Color *';
    }
    return errors;
  };

  useEffect(() => {
    if (pId) {
      getImageDetail();
    }
  }, []);

  const getImageDetail = () => {
    getAlternativeImageDetail(pId, id).then((res) => {
      if (res?.status === 200) {
        setImagePreview(res?.data?.data?.info?.image_name);
        setColour(res?.data?.data?.info?.attribute_id);
      }
    });
  };

  const handleSubmit = () => {
    setClicked(true);
    if (!Object.keys(validate()).length) {
      // if (!imageValidator(image)) {
      //   return;
      // }
      let body = {
        attribute_id: colour,
        image_name: image
      };

      let formData = new FormData();
      for (let i in body) {
        formData.append(`${i}`, body[i]);
      }
      if (pId) {
        addAlternativeImages(pId, id, formData).then((res) => {
          if (res?.status === 200) {
            navigate(-1);
            showNotification('success', res?.data?.message);
          }
        });
      } else {
        addAlternativeImages(id, '', formData).then((res) => {
          if (res?.status === 200) {
            navigate(-1);
            showNotification('success', res?.data?.message);
          }
        });
      }
    }
  };

  const imageValidator = (data) => {
    const fileExtension = data.type.split('/')[1];
    let fileSizeMB = data?.size / 1024 ** 2;
    const validExtensions = ['png', 'jpeg', 'jpg'];
    if (!validExtensions.includes(fileExtension)) {
      imgRef.current.value = '';
      showNotification('danger', 'Please select png, jpeg, jpg format');
      return false;
    }
    if (fileSizeMB > 10) {
      imgRef.current.value = '';
      showNotification('danger', 'Please select image lower than 10 MB');
      return false;
    }
    return true;
  };

  return (
    <div className="dash-main-content">
      <Container fluid>
        <div className="dash-page-content">
          <div className="dash-page-title">
            <Row>
              <Col xs={12} sm={12} md={12}>
                <div className="dash-title-bar d-flex align-items-center justify-content-between">
                  <div className="title-lft d-flex align-items-center">
                    <div className="dash-title-dis">
                      <h5>Add Alternate Image</h5>
                    </div>
                  </div>{' '}
                </div>
              </Col>
            </Row>
          </div>
          <div className="dash-content-card">
            <div className="cmmn-form">
              <Form>
                <Row>
                  <Col xs={12} sm={12} md={12}>
                    <Form.Group className="mb-3" controlId="orderstatus">
                      <Form.Label>
                        Select Colour<span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Select
                        aria-label="Default select example"
                        name="subject"
                        value={colour}
                        onChange={(e) => setColour(e.target.value)}>
                        <option value="" selected="selected">
                          {' '}
                          -- Choose Color --{' '}
                        </option>
                        <option value="7">Black</option>
                        <option value="8">White</option>
                        <option value="12">Red</option>
                      </Form.Select>
                      <div className={'danger-frm'}>{clicked && validate().colour}</div>
                    </Form.Group>
                  </Col>

                  <Col xs={12} sm={12} md={12}>
                    <Form.Group className="mb-3" controlId="slugurl">
                      <Form.Label>Upload Image</Form.Label>
                      <Form.Control
                        ref={imgRef}
                        type="file"
                        onChange={(e) => {
                          if (!imageValidator(e.target.files[0])) {
                            return;
                          }
                          setImage(e.target.files[0]);
                          setImagePreview('');
                        }}
                      />
                    </Form.Group>
                  </Col>
                  {imagePreview && (
                    <div className="td-inner-img">
                      <img
                        src={
                          imagePreview
                            ? imagePreview
                            : require('../../../assets/images/default-male-avatar.png')
                        }
                        alt="img"
                        width="75"
                        height="75"
                      />
                    </div>
                  )}
                </Row>

                <div className="form-btns d-flex align-items-center mt-4">
                  <Button className="main-btn-grn" type="button" onClick={handleSubmit}>
                    Save
                  </Button>
                  <strong>OR</strong>
                  <Button className="main-btn-red" onClick={() => navigate(-1)}>
                    Cancel
                  </Button>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}

export default AddImageProduct;
