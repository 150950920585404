import { useEffect, useState } from 'react';
import { Button, Container } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import AsyncSelect from 'react-select/async';
import Loader from '../../../components/commoncomponents/loader/loader-large';
import * as adminServices from '../../../services/AuthServices';
import showNotification from '../../../services/NotificationService';

export default function TestimonialAdd() {
  const navigate = useNavigate();
  const location = useLocation();
  const id = location.state;
  const roleType = useSelector((state) => state?.user?.details);
  const [detail, setDetail] = useState({
    institute_id: roleType?.institute_id || '',
    name: '',
    description: '',
    designation: ''
  });
  const [value, setValue] = useState('');
  const [loading, setLoading] = useState(false);
  const [clicked, setClicked] = useState(false);
  const [attachment, setAttachMent] = useState('');
  const [previewImg, setPreviewImg] = useState(false);

  useEffect(() => {
    if (id) {
      getDetail(id.id);
    }
  }, []);

  const getDetail = (id) => {
    setLoading(true);
    adminServices.viewTestimonial(id).then((res) => {
      setLoading(false);
      if (res?.status === 200) {
        const data = res?.data?.details;
        setDetail({
          id: data?.id,
          institute_id: data?.institute_id,
          name: data?.name,
          description: data?.description,
          designation: data?.designation
        });
        if (data?.image) {
          setAttachMent(data?.image);
        }
        setValue({ label: data?.institute_name, value: data?.institute_id });
      }
    });
  };

  const onChangeHandler = (e) => {
    const { name, value } = e.target;
    setDetail({ ...detail, [name]: value });
  };

  const validate = (value) => {
    let errors = {};
    if (!value?.name.trim()) {
      errors.name = 'This field is required';
    }
    if (!value?.designation.trim()) {
      errors.designation = 'This field is required';
    }
    if (!value?.description.trim()) {
      errors.description = 'This field is required';
    }
    if (!value?.institute_id && roleType?.admin_type != 3) {
      errors.institute_id = 'This field is required';
    }
    return errors;
  };

  const submitHandler = (e) => {
    e.preventDefault();
    setClicked(true);
    if (!Object.keys(validate(detail)).length) {
      let formData = new FormData();
      for (let i in detail) {
        formData.append(`${i}`, detail[i]);
      }
      if (attachment) {
        formData.append('image_file', attachment);
      }
      adminServices.addTestimonial(formData).then((res) => {
        if (res?.status === 200) {
          showNotification('success', res?.data?.message);
          navigate(-1);
        }
      });
    }
  };

  const updateHandler = (e) => {
    e.preventDefault();
    setClicked(true);
    if (!Object.keys(validate(detail)).length) {
      let formData = new FormData();
      for (let i in detail) {
        formData.append(`${i}`, detail[i]);
      }
      if (attachment) {
        formData.append('image_file', attachment);
      }
      adminServices.editTestimonial(formData).then((res) => {
        if (res?.status === 200) {
          showNotification('success', res?.data?.message);
          navigate(-1);
        }
      });
    }
  };

  const loadInstituteOptions = (inputValue) => {
    return adminServices.getInstituteDrop(inputValue).then((res) => {
      if (res?.status === 200) {
        let tempArr = [];
        if (res?.data?.length) {
          const data = res?.data;
          data?.map((data) => {
            tempArr.push({
              label: data?.institutename,
              value: data?.institute_id
            });
          });
        }
        return tempArr;
      }
    });
  };

  return (
    <>
      <div className="dash-main-content">
        <Container fluid>
          <div className="dash-page-content">
            <div className="dash-page-title">
              <Row>
                <Col xs={12} sm={12} md={12}>
                  <div className="dash-title-bar d-flex align-items-center justify-content-between">
                    <div className="title-lft d-flex align-items-center">
                      <div className="dash-title-dis">
                        <h5>{id ? "Edit" : "Add"} Testimonial</h5>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="dash-content-card">
              <div className="cmmn-form">
                <Form>
                  {roleType?.admin_type == '3' ? (
                    <></>
                  ) : (
                    <Form.Group className="mb-3" controlId="formBasicName">
                      <Form.Label>
                        Select Institute <span className="text-danger">*</span>
                      </Form.Label>
                      <AsyncSelect
                        className="basic-single"
                        classNamePrefix="select"
                        isSearchable={true}
                        name="institute_id"
                        value={value}
                        loadOptions={loadInstituteOptions}
                        onChange={(e) => {
                          setValue(e), setDetail({ ...detail, ['institute_id']: e.value });
                        }}
                        placeholder="Search By Institute Name"
                      />
                      <div
                        className={`${clicked && validate(detail)?.institute_id ? '' : 'd-none'
                          } danger-frm `}>
                        {clicked && validate(detail)?.institute_id}
                      </div>
                    </Form.Group>
                  )}

                  <Form.Group className="mb-3" controlId="formBasicState">
                    <Form.Label>
                      Name<span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="name"
                      value={detail?.name}
                      onChange={(e) => {
                        onChangeHandler(e);
                      }}
                      placeholder="Enter name"
                    />
                    <div
                      className={`${clicked && validate(detail)?.name ? '' : 'd-none'
                        } danger-frm `}>
                      {clicked && validate(detail)?.name}
                    </div>
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formBasicState">
                    <Form.Label>
                      Designation<span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="designation"
                      value={detail?.designation}
                      onChange={(e) => {
                        onChangeHandler(e);
                      }}
                      placeholder="Enter designation"
                    />
                    <div
                      className={`${clicked && validate(detail)?.designation ? '' : 'd-none'
                        } danger-frm `}>
                      {clicked && validate(detail)?.designation}
                    </div>
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formBasicName">
                    <Form.Label>
                      Description<span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      type="description"
                      value={detail?.description}
                      onChange={(e) => {
                        onChangeHandler(e);
                      }}
                      name="description"
                      placeholder="Enter description"
                    />
                    <div
                      className={`${clicked && validate(detail)?.description ? '' : 'd-none'
                        } danger-frm `}>
                      {clicked && validate(detail)?.description}
                    </div>
                  </Form.Group>

                  <Form.Group controlId="formFile" className="attach-file-card">
                    <Form.Control
                      type="file"
                      name="image"
                      accept=".png,.jpg,.jpeg"
                      onChange={(e) => {
                        setAttachMent(e.target.files[0]);
                        setPreviewImg(URL.createObjectURL(e.target.files[0]));
                      }}
                    />
                    <span className="attach-file-inr">
                      <i className="fas fa-paperclip me-2"></i>
                      Attach Files
                    </span>
                    <div className={'danger-frm'}>{clicked && validate().attachment}</div>
                  </Form.Group>
                  <Row>
                    <Col xs={2} sm={2} md={2}>
                      <div className="">
                        {id && attachment && !previewImg ? (
                          <img
                            height={'100px'}
                            width={'100px'}
                            src={
                              process.env.REACT_APP_API_DOMAIN + 'common/uploads/blog/' + attachment
                            }
                            alt="img"
                          />
                        ) : attachment ? (
                          <img height={'100px'} width={'100px'} src={previewImg} alt="img" />
                        ) : (
                          ''
                        )}
                      </div>
                    </Col>
                  </Row>
                  <div className="form-btns d-flex align-items-center mt-5">
                    {id ? (
                      <Button
                        className="green_button_admin"
                        onClick={(e) => {
                          updateHandler(e);
                        }}>
                        Update
                      </Button>
                    ) : (
                      <Button
                        className="green_button_admin"
                        onClick={(e) => {
                          submitHandler(e);
                        }}>
                        Save
                      </Button>
                    )}
                    <strong>OR</strong>
                    <Button
                      className="main-btn-red"
                      onClick={(e) => {
                        navigate(-1);
                      }}>
                      Cancel
                    </Button>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </Container>
      </div>
      {loading ? <Loader /> : ''}
    </>
  );
}
