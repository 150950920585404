/** @copyright : ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
 @author     : Shiv Charan Panjeta 

 All Rights Reserved.
 Proprietary and confidential :  All information contained herein is, and remains
 the property of ToXSL Technologies Pvt. Ltd. and its partners.
 Unauthorized copying of this file, via any medium is strictly prohibited.
*/
import { useState } from 'react';
import { Container } from 'react-bootstrap';
import Carousel from 'react-bootstrap/Carousel';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import { Link } from 'react-router-dom';
import useDocumentTitle from '../../../globals/useDocumentTitle';
import FooterNewsLetter from '../../commoncomponents/FooterNewsLetter';
import HeaderShop from '../../commoncomponents/HeaderShop';
import BrowseInstitue from '../../commoncomponents/browseinstitute';
import CollegeProductGrid from '../../commoncomponents/productgrid/CollegeProductGrid';
import Memorialgrid from '../../commoncomponents/productgrid/MemorialGrid';

function Shop() {
  useDocumentTitle('Browse Products | AlmaBay');
  const [key, setKey] = useState('first');
  return (
    <>
      <HeaderShop />
      <section className="product-view-slider">
        <Carousel controls={false}>
          <Carousel.Item>
            <Link to={{ pathname: `/shop/catalog`, state: 'catalog' }} target="_blank">
              <img
                className="d-block w-100 product-img-slider"
                src={require('../../../assets/images/banner.jpg')}
                alt="Banner"
              />
            </Link>
          </Carousel.Item>
          <Carousel.Item>
            <Link to={{ pathname: `/shop/catalog`, state: 'catalog' }} target="_blank">
              <img
                className="d-block w-100 product-img-slider"
                src={require('../../../assets/images/banner.jpg')}
                alt="Banner"
              />
            </Link>
          </Carousel.Item>
        </Carousel>
      </section>
      <section className="exact-card py-5">
        <Container>
          <div className="title-card text-center mb-4">
            <h4 className="fw-light">Exactly the way you wanted it!</h4>
            <p>
              AlmaBay one stop shop for customized merchandise Get your t-shirts and hoodies
              customized
            </p>
          </div>

          <div className="shop-tab-sec">
            <Tab.Container
              id="left-tabs-example"
              defaultActiveKey="first"
              onSelect={(k) => setKey(k)}>
              <Row>
                <Col lg={12}>
                  <Nav variant="pills" className="justify-content-center mb-5">
                    <Nav.Item className={`store-tabs ${key !== 'first' && 'store-tabs-hover'}`}>
                      <Nav.Link
                        eventKey="first"
                        className={` ${key !== 'first' && 'nav-link-hover'}`}>
                        Browse Institutes
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item className={`store-tabs ${key !== 'second' && 'store-tabs-hover'} `}>
                      <Nav.Link
                        eventKey="second"
                        className={` ${key !== 'second' && 'nav-link-hover'}`}>
                        MOTIVATIONAL memorabilia
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item className={`store-tabs ${key !== 'third' && 'store-tabs-hover'}`}>
                      <Nav.Link
                        eventKey="third"
                        className={` ${key !== 'third' && 'nav-link-hover'}`}>
                        School & College
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                  <Tab.Content>
                    <Tab.Pane eventKey="first">
                      <BrowseInstitue />
                    </Tab.Pane>
                    <Tab.Pane eventKey="second">
                      <Memorialgrid />
                    </Tab.Pane>
                    <Tab.Pane eventKey="third">
                      <CollegeProductGrid />
                    </Tab.Pane>
                  </Tab.Content>
                </Col>
              </Row>
            </Tab.Container>
          </div>
        </Container>
      </section>

      <FooterNewsLetter className={'colour-bg'} />
    </>
  );
}

export default Shop;
