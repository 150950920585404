import { useEffect } from 'react';
import { Button, Form, Table } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { ZERO } from '../../../globals/constants';
import { useSelector } from 'react-redux';
import { singleProductsUpdateCart } from '../../../services/ShopServices';
import { HideAlert } from '../../../redux/action';
import { useDispatch } from 'react-redux';
import { Alert } from 'react-bootstrap';
import Loader from '../../commoncomponents/loader/loader-large';
import { NumberWithdecimal } from '../../../globals/helper';
export default function ReviewOrderCheckout({
  nextStep,
  activeTab,
  setReviewOrder,
  deleteProducts,
  notification,
  setNotification,
  cartProductsState,
  shippingAddress,
  subTotal,
  totalPrice,
  loading,
  cartProductsReview,
  setLoading,
  quantity,
  setQuantity
}) {
  const reduxShowAlert = useSelector((state) => state?.alert?.showAlertBox);
  const dispatch = useDispatch();
  /**useEffect Hook */
  const isloggedIn = useSelector((state) => state?.user?.isloggedIn);
  const navigate = useNavigate()
  useEffect(() => {
    if (isloggedIn) {
      cartProductsReview();
    }
  }, [isloggedIn]);

  useEffect(() => {
    const timeId = setTimeout(() => {
      // After 3 seconds set the show value to false

      setNotification(false);
    }, 2000);
    const timeId1 = setTimeout(() => {
      // After 3 seconds set the show value to false
      dispatch(HideAlert());
    }, 3000);
    return () => {
      clearTimeout(timeId);
      clearTimeout(timeId1);
    };
  }, [reduxShowAlert, notification]);

  const reviewOrderSubmit = (e) => {
    if (subTotal || totalPrice) {
      e.preventDefault();
      nextStep();
      setReviewOrder(true);
      activeTab('fourth');
    } else {
      navigate(`/shop/catalog`);
    }
  };

  const handleChange = (e, index) => {
    let item = [...quantity];
    item[index] = Number(e.target.value);
    setQuantity(item);
  };

  const cartUpdate = (e, id, qty, index) => {
    setLoading(true);
    let item = [...quantity];
    setQuantity(item[index] == 0 ? qty : item[index]);
    let body = {
      id,
      quantity: item[index] == 0 ? qty : item[index]
    };
    singleProductsUpdateCart(body).then((resp) => {
      setLoading(false);
      if (resp?.status == 200) {
        setLoading(false);
        cartProductsReview();
        setNotification(resp?.data?.message);
      }
    });
  };
  return (
    <div className="review-order">
      <div className="confrm-address-hd mb-4">
        <h4>Review Order</h4>
        <p>Our delivery time depends on your selected delivery option</p>
      </div>
      <Alert variant={'success'} className={`${notification ? '' : 'd-none'}`}>
        {notification}
      </Alert>
      <div className="shopp-cart-list table-responsive">
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Item Details</th>
              <th>List Price</th>
              <th>Selling Price</th>
              <th>Quantity</th>
              <th>Delete</th>
              <th>Subtotal</th>
            </tr>
          </thead>
          <tbody>
            {cartProductsState?.length !== ZERO
              ? cartProductsState?.map((data, index) => {
                return (
                  <tr key={index}>
                    <td>
                      <div className="cart-prd-view d-flex align-items-top">
                        <div className="cart-view-img me-3">
                          <Link to="#">
                            <img src={data?.product_image} alt="product-img" />
                          </Link>
                        </div>
                        <div className="cart-view-dis">
                          <h4>
                            <Link to={`/shop/details/${data.product_id}`}>
                              {data?.product_name}
                            </Link>
                          </h4>
                          {data?.attriGrpData?.map((attributes, index) => {
                            return (
                              <p key={index}>
                                {attributes?.groupId == '1'
                                  ? `Size :` + ' ' + attributes?.Size
                                  : ''}
                                &nbsp;
                                {attributes?.groupId == '2'
                                  ? `Color :` + ' ' + attributes?.Color
                                  : ''}
                              </p>
                            );
                          })}
                        </div>
                      </div>
                    </td>
                    <td>
                      <i className="fas fa-rupee-sign me-1"></i>
                      {data?.price}
                    </td>
                    <td>
                      <i className="fas fa-rupee-sign me-1"></i>{' '}
                      {data?.sale_price ? data?.sale_price : data?.price}
                    </td>
                    <td>
                      <div className="cart-input d-flex align-items-center">
                        <Form.Control
                          type="text"
                          placeholder="0"
                          name="quantity"
                          maxLength="4"
                          value={quantity[index]}
                          onChange={(e) => handleChange(e, index)}
                          onKeyDown={(e) => NumberWithdecimal(e)}
                        />

                        <Link className="refresh-btn ms-2">
                          <i
                            role="presentation"
                            className="fas fa-sync"
                            onClick={(e) => cartUpdate(e, data?.id, data?.quantity, index)}
                          ></i>
                        </Link>
                      </div>
                    </td>
                    <td>
                      <Link className="cross-btn" onClick={(e) => deleteProducts(e, data?.id)}>
                        <i className="fas fa-times"></i>
                      </Link>
                    </td>
                    <td>
                      <i className="fas fa-rupee-sign me-1"></i> {data?.subtotal}
                    </td>
                  </tr>
                );
              })
              : ''}
          </tbody>
        </Table>
      </div>
      <div className="subtotal-sec d-flex align-items-top justify-content-between">
        <h5 className="delivery-sec">Delivery and payment options can be selected later</h5>
        <div className="sub-total-rt">
          <ul>
            <li>
              Sub-total:{' '}
              <span>
                <i className="fas fa-rupee-sign me-1"></i> {subTotal}
              </span>
            </li>
            <li>
              Shipping Charges:{' '}
              <span>
                <i className="fas fa-rupee-sign me-1"></i> {shippingAddress}
              </span>
            </li>
          </ul>
          <h5>
            Total{' '}
            <span>
              <i className="fas fa-rupee-sign me-1"></i> {totalPrice}
            </span>
          </h5>
          <div className="text-end mt-3">
            <Button variant="primary" className="save-btn" onClick={(e) => reviewOrderSubmit(e)}>
              Continue
            </Button>
          </div>
        </div>
      </div>
      {loading ? <Loader /> : ''}
    </div>
  );
}
