import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import HeaderLogin from '../../commoncomponents/HeaderLogin';
import FooterMain from '../../commoncomponents/FooterMain';
import TopTabbings from './TopTabbings';
import NewsSidebar from '../../commoncomponents/newsfeeds/NewsSidebar';
import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';
import useDocumentTitle from '../../../globals/useDocumentTitle';
import { getRecommendations } from '../../../services/JobServices';
import {
  RecommandRecievedSimmer,
  RecommendSentSimmer
} from '../../commoncomponents/skelton/allskelton';

function Recommends() {
  useDocumentTitle('Recommended Jobs | AlmaBay');

  const [list, setList] = useState([]);
  const [totalPerson, setTotalPerson] = useState(0);
  const [totalJob, setTotalJob] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getResponse('sent');
    return () => {
      //clean up
    };
  }, []);

  const getResponse = (type = '') => {
    setLoading(true);
    getRecommendations(type).then((res) => {
      setLoading(false);
      if (res?.status === 200) {
        setList(res?.data?.data?.requestSent);
        setTotalJob(res?.data?.data?.totalJobs);
        setTotalPerson(res?.data?.data?.totalRequests);
      }
    });
  };

  return (
    <>
      <HeaderLogin />
      <div className="header-lnk-feeds">
        <div className="container">
          <TopTabbings state={'feed'} />
        </div>
      </div>
      <section className="feed-sec bg-grey py-4">
        <div className="container">
          <Row>
            <Col xs={12} lg={3} xl={2}>
              <NewsSidebar state="0" current="recommend" />
            </Col>
            <Col xs={12} lg={9} xl={10} className="mb-4">
              <div className="recommend bg-white">
                <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                  <Nav className="mb-2" variant="pills">
                    <Nav.Item
                      onClick={() => {
                        getResponse('sent');
                      }}
                    >
                      <Nav.Link eventKey="first">Recommendation Sent</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        eventKey="second"
                        onClick={() => {
                          getResponse('received');
                        }}
                      >
                        Recommendation Received
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                  <Tab.Content>
                    <Tab.Pane eventKey="first">
                      <div className="recommend-list p-4">
                        <div className="recomm-job-hd mb-4">
                          <p>
                            You have recommended {totalPerson} person(s) for {totalJob}{' '}
                            job(s)/Internships
                          </p>
                        </div>
                        {loading ? (
                          <>
                            <RecommendSentSimmer />
                            <RecommendSentSimmer />
                          </>
                        ) : (
                          <>
                            {Object.entries(list).map((data, index) => {
                              return (
                                <div className="recommnd-card" key={index}>
                                  <div className="recommnd-card-hd d-flex align-items-center justify-content-between">
                                    <h5>
                                      You have recommended {data[1]?.length} person(s) for{' '}
                                      <Link to={`/jobs/jobs-details/${data[0]}`}>
                                        {data[1][0]?.job_title}
                                      </Link>
                                    </h5>
                                    <span className="internship-tag">
                                      {data[1][0]?.job_type == 0
                                        ? 'Full Time'
                                        : data[1][0]?.job_type == 1
                                        ? 'Part Time'
                                        : 'InternShip'}
                                    </span>
                                  </div>
                                  {data[1]?.map((data, index) => (
                                    <div
                                      key={index}
                                      className="recomm-view-card d-flex flex-wrap align-items-center align-items-stretch"
                                    >
                                      <div className="recomm-icn">
                                        <Link to={`/user/timeline/${data?.recommended_to}`}>
                                          <img
                                            src={
                                              data?.url
                                                ? process.env.REACT_APP_API_DOMAIN + data?.url
                                                : require('../../../assets/images/default-male-avatar.png')
                                            }
                                            alt="profile"
                                          />
                                        </Link>
                                      </div>
                                      <div className="recomm-view-name">
                                        <h4>
                                          <Link to={`/user/timeline/${data?.recommended_to}`}>
                                            {data?.recommended_name}
                                          </Link>
                                        </h4>
                                      </div>
                                      <div className="recomm-view-date">
                                        <p>Recommended on: {data?.recommended_date}</p>
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              );
                            })}
                          </>
                        )}
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="second">
                      <div className="recommend-list p-4">
                        <div className="recomm-job-hd mb-4">
                          <p>
                            You have recommended by {totalPerson} person(s) for {totalJob}{' '}
                            job(s)/Internships
                          </p>
                        </div>

                        {loading ? (
                          <>
                            <RecommandRecievedSimmer />
                            <RecommandRecievedSimmer />
                          </>
                        ) : (
                          <>
                            {Object.entries(list).map((data, index) => {
                              return (
                                <div className="recommnd-card" key={index}>
                                  <div className="recommnd-card-hd d-flex align-items-center justify-content-between">
                                    <h5>
                                      You have recommended by {data[1]?.length} person(s) for{' '}
                                      <Link to={`/jobs/jobs-details/${data[0]}`}>
                                        {data[1][0]?.job_title}
                                      </Link>
                                    </h5>
                                    <span className="internship-tag">
                                      {data[1][0]?.job_type == 0
                                        ? 'Full Time'
                                        : data[1][0]?.job_type == 1
                                        ? 'Part Time'
                                        : 'InternShip'}
                                    </span>
                                  </div>
                                  {data[1]?.map((data, index) => (
                                    <div
                                      key={index}
                                      className="recomm-view-card d-flex flex-wrap align-items-center align-items-stretch"
                                    >
                                      <div className="recomm-icn">
                                        <Link to={`/user/timeline/${data?.recommended_to}`}>
                                          <img
                                            // src={require('../../../assets/images/default-male-avatar.png')}
                                            src={
                                              data?.url
                                                ? process.env.REACT_APP_API_DOMAIN + data?.url
                                                : require('../../../assets/images/default-male-avatar.png')
                                            }
                                            alt="profile"
                                          />
                                        </Link>
                                      </div>
                                      <div className="recomm-view-name">
                                        <h4>
                                          <Link to={`/user/timeline/${data?.recommended_to}`}>
                                            {data?.recommended_name}
                                          </Link>
                                        </h4>
                                      </div>
                                      <div className="recomm-view-date">
                                        <p>Recommended on: {data?.recommended_date}</p>
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              );
                            })}
                          </>
                        )}
                      </div>
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </div>
            </Col>
          </Row>
        </div>
      </section>
      <FooterMain className={'colour-bg'} />
      {/* {loading ? <Loader /> : ''} */}
    </>
  );
}

export default Recommends;
