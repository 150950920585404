import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditor } from '@ckeditor/ckeditor5-react';

const CKEditorForCommunication = ({ isToolbar, htmlContent, setHtmlContent }) => {
  return (
    <>
      <CKEditor
        name="description"
        data={htmlContent}
        editor={ClassicEditor}
        config={{
          toolbar: isToolbar
            ? ['bold', 'italic', 'numberedList', 'bulletedList', 'outdent', 'indent']
            : [],
          placeholder: 'Enter Description'
        }}
        onChange={(_, editor) => {
          const data = editor.getData();
          setHtmlContent(data);
        }}

      />
    </>
  );
};

export default CKEditorForCommunication;
