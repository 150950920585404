//  @copyright : ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
// @author     : Shiv Charan Panjeta

// All Rights Reserved.
// Proprietary and confidential :  All information contained herein is, and remains
// the property of ToXSL Technologies Pvt. Ltd. and its partners.
// Unauthorized copying of this file, via any medium is strictly prohibited.

//pages used for aading images of alumini
import { useEffect, useState } from 'react';
import { Button, Container } from 'react-bootstrap';
import Alert from 'react-bootstrap/Alert';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { useSelector } from 'react-redux';
import Loader from '../../components/commoncomponents/loader/loader-large';
import { imageExtension } from '../../globals/helper';
import * as adminServices from '../../services/AuthServices';
import showNotification from '../../services/NotificationService';

let intervalId;
export default function AddAluminiImg() {
  const ins_id = useSelector((state) => state?.user?.details?.admin_institute_id);
  const [loading, setLoading] = useState(false);
  const [clicked, setClicked] = useState(false);
  const [isEditable, SetIsEditable] = useState(false);
  const [images, setImages] = useState([]);
  const [imagesPreview, setImagesPreview] = useState('');
  const [showalert, setShowAlert] = useState(false);
  const [globalMsg, setGlobalMsg] = useState('');
  const [image, setImage] = useState('0');

  useEffect(() => {
    getDetail();
  }, []);

  /**
   * function to clear  interval and restet state
   */
  const alertHandler = () => {
    clearInterval(intervalId);
    intervalId = setInterval(function () {
      setShowAlert(false);
    }, 5000);
  };

  /**
   * get saved data
   */
  const getDetail = () => {
    adminServices.getAluminiImages().then((res) => {
      if (res?.status == 200) {
        if (res?.data?.data?.length) {
          SetIsEditable(true);
          let data = res?.data?.data;
          let tempArr = [];
          let arr = [];
          data?.map((data) => {
            tempArr.push({
              url: process.env.REACT_APP_API_DOMAIN + data?.url + '.' + data?.extension,
              id: data?.mainid
            });
            arr.push(data?.url);
          });
          setImagesPreview(tempArr ?? []);
          setImages(arr ?? []);
        }
      }
    });
  };

  /**
   * validation for form inputs
   * @param {state} value
   * @returns  error if accured
   */

  const validate = (value) => {
    let errors = {};
    if (!value) {
      errors.image = 'please select images';
    }
    // else if (value?.length < 10) {
    //   errors.image = 'please select atleast 14 images';
    // }

    return errors;
  };

  /**
   * for saving data
   * @param {*} e
   */

  const submitHandler = (e) => {
    e.preventDefault();
    setClicked(true);
    if (!Object.keys(validate(images)).length) {
      let formData = new FormData();
      if (image == 1) {
        for (let i in images) {
          formData.append('files[]', images[i]);
        }
      }
      formData.append('institute_id', ins_id);
      formData.append('type_id', image);
      setLoading(true);
      adminServices.addAluminiImg(formData).then((res) => {
        setLoading(false);
        if (res?.status === 200) {
          setGlobalMsg(res?.data?.message);
          setShowAlert(true);
          alertHandler();
          getDetail();
        }
      });
    }
  };

  const updateHandler = (e) => {
    e.preventDefault();
    setClicked(true);
    if (!Object.keys(validate(images)).length) {
      let formData = new FormData();
      for (let i in images) {
        formData.append('files[]', images[i]);
      }
      formData.append('institute_id', ins_id);
      formData.append('type_id', image);
      setLoading(true);
      adminServices.editAluminiImg(formData).then((res) => {
        setLoading(false);
        if (res?.status === 200) {
          setGlobalMsg(res?.data?.message);
          setShowAlert(true);
          alertHandler();
        }
      });
    }
  };

  /**
   * function for handling selected file and for preview
   * @param {*} e onChange
   * @returns
   */

  const handleFile = (e) => {
    for (let i = 0; i < e.target.files.length; i++) {
      if (imageExtension.includes(e.target.files[i]?.type)) {
        let file = e.target.files[i];
        let minfileSize = 500000; // 500kb
        if (file.size > minfileSize) {
          showNotification('danger', `File size should not be greater than 100 kb`);
          setImages([]);
        } else {
          const arr = [];
          const targetfile = e?.target?.files;
          const targetfileobj = [...targetfile];
          targetfileobj.map((file) => {
            arr.push({ url: URL.createObjectURL(file) });
          });
          setImages([...targetfileobj, ...images]);
          setImagesPreview([...arr, ...imagesPreview]);
        }
      } else {
        showNotification('danger', 'Please upload photos only');
        return;
      }
    }
  };

  /**
   * to delete selected and available images
   * @param {*} e
   * @param {*} id
   * @param {*} index
   * @returns
   */

  const deleteHandler = (e, id, index) => {
    e.preventDefault();
    let temp = false;

    if (confirm('Are you sure you want to delete this ?')) {
      let arr = [...imagesPreview];
      let tempArr = [...images];
      temp = true;

      if (id) {
        adminServices.deleteAluminiImg(id).then((res) => {
          if (res?.status == 200) {
            setGlobalMsg(res?.data?.message);
            setShowAlert(true);
            alertHandler();
          }
        });
      }

      if (index > -1) {
        arr.splice(index, 1);
        tempArr.splice(index, 1);
        setImagesPreview(arr);
        setImages(tempArr);
      }
      if (!arr?.length) {
        setImagesPreview('');
        setImages('');
      }
    } else {
      return;
    }
  };

  return (
    <>
      <div className="dash-main-content">
        <Container fluid>
          <div className="dash-page-content">
            <Alert variant={'success'} className={`${showalert ? '' : 'd-none'}`}>
              {globalMsg}
            </Alert>
            <div className="dash-page-title">
              <Row>
                <Col xs={12} sm={12} md={12}>
                  <div className="dash-title-bar d-flex align-items-center justify-content-between">
                    <div className="title-lft d-flex align-items-center">
                      <div className="dash-title-dis">
                        <h5>
                          {isEditable
                            ? 'Edit Alumini Background Images'
                            : 'Add Alumini Background Images'}
                        </h5>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="dash-content-card">
              <div className="cmmn-form">
                <div className=" d-flex flex-wrap">
                  <Form.Label className="d-flex me-5">
                    <Form.Check
                      value={'0'}
                      name="image"
                      onChange={(e) => {
                        setImage(e.target.value);
                      }}
                      type="radio"
                      checked={image == '0'}
                    />
                    &nbsp;&nbsp;&nbsp;Upload existing images
                  </Form.Label>
                  <Form.Label className="d-flex">
                    <Form.Check
                      value={'1'}
                      name="image"
                      onChange={(e) => {
                        setImage(e.target.value);
                      }}
                      type="radio"
                      checked={image == '1'}
                    />
                    &nbsp;&nbsp;&nbsp;Upload own images
                  </Form.Label>
                </div>
                <Form>
                  {image == 0 ? (
                    <>
                      {images?.length == 0 ? (
                        <button
                          type="button"
                          className="green_button_admin mt-5"
                          onClick={(e) => {
                            submitHandler(e);
                          }}>
                          Save
                        </button>
                      ) : (
                        <button
                          type="button"
                          className="green_button_admin mt-5"
                          onClick={(e) => {
                            updateHandler(e);
                          }}>
                          Update
                        </button>
                      )}
                    </>
                  ) : (
                    <>
                      <Row>
                        <Form.Group className="mb-3" controlId="formBasicState">
                          <Form.Label>
                            Select Images<sup className="text-danger">*</sup>
                          </Form.Label>
                          <Form.Control
                            type="file"
                            name="title"
                            accept="image/*"
                            multiple
                            onChange={(e) => {
                              handleFile(e);
                            }}
                          />
                          <div
                            className={`${
                              clicked && validate(images)?.image ? '' : 'd-none'
                            } danger-frm `}>
                            {clicked && validate(images)?.image}
                          </div>
                        </Form.Group>
                      </Row>
                      <Row>
                        {imagesPreview && isEditable
                          ? imagesPreview?.map((data, index) => (
                              <div className="image-area mb-2" key={index}>
                                <img src={data?.url} alt="Preview" />
                                <a
                                  className="remove-image"
                                  onClick={(e) => {
                                    deleteHandler(e, data?.id, index);
                                  }}
                                  href="#"
                                  style={{ display: 'inline' }}>
                                  &#215;
                                </a>
                              </div>
                            ))
                          : imagesPreview &&
                            imagesPreview?.map((data, index) => (
                              <div className="image-area mb-2" key={index}>
                                <img src={data.url} alt="Preview" />
                                <a
                                  className="remove-image"
                                  href="#"
                                  onClick={(e) => {
                                    deleteHandler(e, '', index);
                                  }}
                                  style={{ display: 'inline' }}>
                                  &#215;
                                </a>
                              </div>
                            ))}
                      </Row>
                      <Row>
                        <div className="form-btns d-flex align-items-center mt-5">
                          {isEditable ? (
                            <button
                              type="button"
                              className="green_button_admin"
                              onClick={(e) => {
                                updateHandler(e);
                              }}>
                              Update
                            </button>
                          ) : (
                            <button
                              type="button"
                              className="green_button_admin"
                              onClick={(e) => {
                                submitHandler(e);
                              }}>
                              Save
                            </button>
                          )}
                        </div>
                      </Row>
                    </>
                  )}
                </Form>
              </div>
            </div>
          </div>
        </Container>
      </div>

      {loading ? <Loader /> : ''}
    </>
  );
}
