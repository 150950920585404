/**
@copyright  :   ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
@author     :   Shiv Charan Panjeta 
 
All Rights Reserved.
Proprietary and confidential :  All information contained here in is, and remains
the property of ToXSL Technologies Pvt. Ltd. and it's partners.
Unauthorized copying of this file, via any medium is strictly prohibited.
*/

import moment from 'moment';
import { useEffect, useState } from 'react';
import { Button, Container } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Table from 'react-bootstrap/Table';
import ReactPaginate from 'react-paginate';
import { Link, useNavigate } from 'react-router-dom';
import useDocumentTitle from '../../globals/useDocumentTitle';
import * as ADMINSERVICES from '../../services/AuthServices';
import showNotification from '../../services/NotificationService';
export default function Reports() {
  useDocumentTitle('Manage Reports | AlmaBay');
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [currentPage, setCurrentPage] = useState(0);
  const [reportList, setReportList] = useState([]);
  const [record, setRecord] = useState('');
  const [viewData, setViewData] = useState();
  const [id, setId] = useState('');
  const [actionState, setActionState] = useState('');
  const [reporterId, setReporterId] = useState('');
  let navigate = useNavigate();

  useEffect(() => {
    reportsListing();
  }, []);

  const reportsListing = (page_number = '1') => {
    setCurrentPage(page_number);
    ADMINSERVICES.reportsList(page_number).then((resp) => {
      if (resp?.status === 200) {
        setReportList(resp?.data?.data);
        setRecord(resp?.data?.data?.totalRecords);
      }
    });
  };

  //paginate

  const handlePageClick = (a) => {
    let currentPage = a?.selected + 1;
    reportsListing(currentPage);
  };
  const handleView = (data) => {
    setViewData(data);
  };

  const reportsActionFunction = (e, id) => {
    e.preventDefault();
    let body = {
      status: e.target.value
    };
    ADMINSERVICES.actionReport(id, body).then((resp) => {
      if (resp?.status === 200) {
        document.getElementById('report').value = '';
        showNotification('success', resp.data.message);
        reportsListing();
      }
    });
  };

  // get status 1 mark safe 2 delete
  const Status = (status) => {
    switch (status) {
      case '0':
        return 'Pending';
      case '1':
        return 'Mark Safe';
      case '2':
        return 'Delete';
    }
  };

  const searchByReport = () => {
    let body = {
      re_id: id,
      reporter_status: actionState,
      reporter_id: reporterId
    };
    ADMINSERVICES.searchByReportId(body).then((resp) => {
      if (resp?.status === 200) {
        setReportList(resp?.data?.detail?.viewlist);
        setRecord(resp?.data?.detail?.totalRecords);
      }
    });
  };

  const emptyState = () => {
    setReporterId('');
    setActionState('');
    reportsListing();
  };

  const viewUserDetails = (id) => {
    navigate(`/admin/users/view/${id}`);
  };

  return (
    <>
      <div className="dash-main-content">
        <Container fluid>
          <div className="dash-page-content">
            <div className="dash-page-title">
              <Row>
                <Col xs={12} sm={12} md={12}>
                  <div className="dash-title-bar d-flex align-items-center justify-content-between">
                    <div className="title-lft d-flex align-items-center">
                      <div className="dash-icn">
                        <i className="fas fa-file"></i>
                      </div>
                      <div className="dash-title-dis">
                        <h5>
                          Post Reports <span>Listing</span>
                        </h5>
                        <h5>
                          Total Record(s):{' '}
                          <span>{reportList?.length ? reportList?.length : '0'}</span>
                        </h5>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="dash-content-card">
              <div className="table-responsive">
                <Table className="custom-table-data" striped bordered hover>
                  <thead>
                    <tr>
                      <th>Id</th>
                      <th>Post Content</th>
                      <th>Reported By</th>
                      <th>Status</th>
                      <th>Reported On</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="search-table">
                      <td>
                        <Form.Control
                          type="text"
                          placeholder="Id"
                          onChange={(e) => setId(e.target.value)}
                        />
                      </td>
                      <td></td>

                      <td>
                        <Form.Control
                          type="text"
                          placeholder="reporter_id"
                          name="re_id"
                          value={reporterId}
                          onChange={(e) => setReporterId(e.target.value)}
                        />
                      </td>
                      <td>
                        <Form.Select
                          aria-label="Default select example"
                          name="reporter_status"
                          value={actionState}
                          onChange={(e) => {
                            setActionState(e.target.value);
                          }}>
                          <option> - Select Status - </option>
                          <option value="0">Pending </option>
                          <option value="1"> Mark Safe</option>
                          <option value="2"> Deleted </option>
                        </Form.Select>
                      </td>
                      <td></td>
                      <td className="serac-btns text-center" colSpan={2}>
                        <button
                          type="button"
                          className="green_button_admin m-2"
                          onClick={searchByReport}>
                          Search
                        </button>
                        <Button className="main-btn-red m-2" onClick={emptyState}>
                          Reset
                        </Button>
                      </td>
                    </tr>
                    {reportList && reportList != 0
                      ? reportList?.map((data, index) => {
                          return (
                            <tr key={index}>
                              <td>{data?.id}</td>
                              <td>
                                <Link
                                  to=""
                                  onClick={() => {
                                    handleShow();
                                    handleView(data);
                                  }}>
                                  View Post
                                </Link>
                              </td>
                              <td>
                                <Link
                                  to="#"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    viewUserDetails(data?.id);
                                  }}>
                                  {data?.name}
                                </Link>
                              </td>
                              <td>{Status(data?.status)}</td>
                              <td>{moment(data?.timestamp).format('lll')}</td>
                              <td>
                                <Form.Select
                                  aria-label="Default select example"
                                  id="report"
                                  onChange={(e) => reportsActionFunction(e, data?.id)}>
                                  <option value=""> - Select - </option>
                                  <option value="1">Mark Safe</option>
                                  <option value="2">Delete</option>
                                </Form.Select>
                              </td>
                            </tr>
                          );
                        })
                      : 'no data found'}
                  </tbody>
                </Table>
                <div className="react-page d-flex">
                  {record / 10 > 1 ? (
                    <ReactPaginate
                      previousLabel={currentPage > 0 ? '<' : ''}
                      nextLabel=">"
                      onPageChange={handlePageClick}
                      pageCount={record / 10}
                    />
                  ) : (
                    ''
                  )}
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>View Reports Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="report-view">
            <Table className="custom-table-vertical" striped hover>
              <tbody>
                <tr>
                  <th>Posted By</th>
                  <td>:</td>
                  <td>{viewData?.name}</td>
                </tr>
                <tr>
                  <th>Text</th>
                  <td>:</td>
                  <td>Text</td>
                </tr>
                <tr>
                  <th>Posted On</th>
                  <td>:</td>
                  <td>{moment(viewData?.timestamp).format('lll')}</td>
                </tr>
              </tbody>
            </Table>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
