import { useEffect, useState } from 'react';
import { Alert, Container } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Table from 'react-bootstrap/Table';
import ReactPaginate from 'react-paginate';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import useDocumentTitle from '../../../globals/useDocumentTitle';
import { HideAlert } from '../../../redux/action';
import { getOrderStatusList, orderStatusUpdate } from '../../../services/AuthServices';
import showNotification from '../../../services/NotificationService';

function OrderStatus() {
  const reduxShowAlert = useSelector((state) => state?.alert?.showAlertBox);
  useDocumentTitle('Manage Orders | AlmaBay');
  const dispatch = useDispatch();
  const [orderStatusList, setOrderStatusList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [orderBy, setOrderBy] = useState('DESC');
  const [shortOrderBy, setShortOrderBy] = useState('id');
  const navigate = useNavigate();
  const [showalert1, setShowAlert1] = useState(false);

  const [showalert, setShowAlert] = useState(false);
  const getListOfStatus = (page, currentSort, type) => {
    getOrderStatusList(
      page ? page : currentPage,
      currentSort ? currentSort : shortOrderBy,
      type ? type : orderBy
    ).then((res) => {
      if (res?.status === 200) {
        setOrderStatusList(res?.data?.data);
      }
    });
  };

  const handleStatusUpdate = (id, value) => {
    orderStatusUpdate(id, value).then((res) => {
      document.getElementById("dropdown").value = ""
      if (res?.status === 200) {
        if (value == 1) {
          showNotification('success', `Order Status id-${id} has been enabled successfully`);
        }
        if (value == 0) {
          showNotification('success', `Order Status id-${id} has been disabled successfully`);
        }
        if (value == 2) {
          showNotification('success', `Order Status id-${id} has been deleted successfully`);
        }
        getListOfStatus();
      }
    });
  };

  useEffect(() => {
    getListOfStatus();
  }, []);
  useEffect(() => {
    const timeId1 = setTimeout(() => {
      dispatch(HideAlert());
    }, 3000);
    return () => {
      clearTimeout(timeId1);
    };
  }, [showalert, showalert1]);

  const selectHandler = (data, value) => {
    if (value === '') {
      return;
    } else {
      switch (value == 'edit' ? value : +value) {
        case 'edit':
          navigate(`/admin/shops/order/create`, { state: data });
          break;
        case 1:
          handleStatusUpdate(data?.id, value);
          break;
        case 0:
          handleStatusUpdate(data?.id, value);
          break;
        case 2:
          handleStatusUpdate(data?.id, value);
          break;
        default:
          break;
      }
    }
  };

  const sortHandler = (e, type) => {
    e.preventDefault();
    let currentSort = orderBy === 'ASC' ? 'DESC' : 'ASC';
    setOrderBy(currentSort);
    setShortOrderBy(type);
    getListOfStatus(currentPage, type, currentSort);
  };
  const handlePageClick = (a) => {
    let page = a?.selected + 1;
    setCurrentPage(page);
    getListOfStatus(page, shortOrderBy, orderBy);
  };
  return (
    <>
      <div className="dash-main-content">
        <Container fluid>
          <div className="dash-page-content">
            <div className="dash-page-title">
              <Row>
                <Col xs={12} sm={12} md={12}>
                  <div className="dash-title-bar d-flex align-items-center justify-content-between">
                    <div className="title-lft d-flex align-items-center">
                      <div className="dash-title-dis">
                        <h5>Manage Order Status</h5>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>

            <div className="dash-content-card">
              <div className="cmmn-form">
                <Form>
                  <Row className="mb-4 d-flex align-items-center">
                    <Col md={5} lg={4} xl={2}>
                      <Form>
                        <Link to="/admin/shops/order/create" className="green_button_admin">
                          Add New Status
                        </Link>
                      </Form>
                    </Col>
                    <Col md={4}> Total Record(s): {orderStatusList?.totalRecords}</Col>
                  </Row>
                </Form>
              </div>
              <Alert variant={'success'} className={`${reduxShowAlert ? '' : 'd-none'}`}>
                New status has been added successfully
              </Alert>
              <div className="table-responsive">
                <Table className="custom-table-data" striped bordered hover>
                  <thead>
                    <tr>
                      <th>
                        <Link
                          to=""
                          onClick={(e) => {
                            sortHandler(e, 'id');
                          }}>
                          Id
                          <img src={require('../../../assets/images/sort.png')} alt="sort" />
                        </Link>
                      </th>
                      <th>
                        <Link
                          to=""
                          onClick={(e) => {
                            sortHandler(e, 'order_short');
                          }}>
                          Short Name
                          <img src={require('../../../assets/images/sort.png')} alt="sort" />
                        </Link>
                      </th>
                      <th>
                        <Link
                          to=""
                          onClick={(e) => {
                            sortHandler(e, 'order_full');
                          }}>
                          Full Name
                          <img src={require('../../../assets/images/sort.png')} alt="sort" />
                        </Link>
                      </th>
                      <th>
                        <Link
                          to=""
                          onClick={(e) => {
                            sortHandler(e, 'status');
                          }}>
                          Status
                          <img src={require('../../../assets/images/sort.png')} alt="sort" />
                        </Link>
                      </th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  {orderStatusList?.viewlist?.length !== 0 ? (
                    <>
                      {' '}
                      <tbody>
                        {orderStatusList?.viewlist?.map((item, index) => {
                          return (
                            <tr key={index}>
                              <td>{item?.id}</td>
                              <td>{item?.order_short}</td>
                              <td>{item?.order_full}</td>
                              {item?.status == 2 ? (
                                <td className="danger-del-text">Deleted</td>
                              ) : (
                                <td>{item?.status == 1 ? 'Enable' : 'Disable'}</td>
                              )}
                              <td>
                                <Form.Select
                                  aria-label="Default select example"
                                  id="dropdown"
                                  onChange={(e) => selectHandler(item, e.target.value)}>
                                  <option value="">Action</option>
                                  <option value="edit">Edit</option>
                                  <option value={item?.status == 1 ? 0 : 1}>
                                    {item?.status == 1 ? 'Disable' : 'Enable'}
                                  </option>{' '}
                                  <option value={2}>Delete</option>
                                </Form.Select>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </>
                  ) : (
                    <>No data found</>
                  )}
                </Table>
              </div>
              <div className="react-page d-flex">
                {orderStatusList?.totalRecords / 10 > 1 ? (
                  <ReactPaginate
                    previousLabel={currentPage > 0 ? '<' : ''}
                    nextLabel=">"
                    onPageChange={handlePageClick}
                    activeClassName={'page-link active'}
                    pageCount={orderStatusList?.totalRecords / 10}
                  />
                ) : (
                  ''
                )}
              </div>
            </div>
          </div>
        </Container>
      </div>
    </>
  );
}

export default OrderStatus;
