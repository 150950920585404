import { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { MENTOR_STUDENT } from '../../../globals/constants';
import { getPageDetail } from '../../../services/InstituteServices';
import NoDataFound from '../../commoncomponents/NoDataFound';

function MentorStudent() {
  const [list, setList] = useState("");

  useEffect(() => {
    getPageDetailIns();
  }, []);

  const getPageDetailIns = () => {
    getPageDetail(MENTOR_STUDENT).then((res) => {
      if (res?.status == 200) {
        let data = res?.data?.details ? res?.data?.details[0] : "";
        setList(data);
      }
    });
  };

  return (
    <>
      <div className="wrappermain_outer">
        {list ? <><section className="breadcrum-banner">
          <Container>
            <Row>
              <Col sm={12}>
                <h1 className="Text-white">{list?.banner_title || 'Mentor a student'}</h1>
              </Col>
            </Row>
          </Container>
        </section>

          <section className="mentorship-program bg-white">
            <Container>
              <Row>
                {/* <Col sm={12}>
                <p className="top-detail mb-5">
                  We provide you with a platform that enables mentees to interact with their alumni as
                  well as other industry experts. It provides an opportunity for the mentees to post
                  generic queries (so that anyone may respond to them) or even address questions
                  directly to a particular mentor. Mentors may choose to address any of the queries
                  put across to them.
                </p>
              </Col> */}

                <Col sm={12}>
                  <h2 className="wavey-heading program-heading mb-5">
                    {list?.title || 'Mentorship Program'}
                  </h2>
                </Col>

                <Col lg={7} md={5}>
                  <img
                    src={
                      list?.image_file
                        ? process.env.REACT_APP_API_DOMAIN + list?.image_file
                        : require('../../../assets/images/amp-image.png')
                    }
                    alt="img"
                  />
                </Col>

                <Col lg={5} md={7}>
                  {list?.description && (
                    <span dangerouslySetInnerHTML={{ __html: list?.description }} />
                  )}
                  {/* <h5 className='amp-text'>
                  Alumni Mentorship Program (AMP) offers every student a unique opportunity to leverage their institute’s alumni network and build strong relations with professionals. Student gets a chance to get updated information and advice related to academic, professional, and personal development. This communication channel helps build a strong network for the alumni and students. Features and goals of the program
                </h5>

                <ul className='styled-bullets-list'>
                  <li>
                    Strong and secure tool to communicate with alumni.
                  </li>
                  <li>
                    Convenient way of linking students and mentors (alumni) based on common career goals and aspirations.
                  </li>
                  <li>
                    Continuous engagement between alumni and student through a secure and intuitive tool.
                  </li>
                  <li>
                    Helping the students to identify career and higher study opportunities across the globe.
                  </li>
                  <li>
                    Helping students find job and internship opportunities through alumni.
                  </li>
                  <li>
                    Creating enough alumni events for mentor and mentee to meet at campus or elsewhere.
                  </li>
                </ul> */}
                </Col>

                {/* <Col sm={12}>
                <p>
                  At the same time, AMP has certain expectations from both alumni and students. In order to achieve the maximum impact of this program alumni and students are expected to:
                </p>

                <p className='mb-4'>
                  Alumni will serve as role models and personal career advisers in an informal mentoring relationship. As a distinguished alumnus, you hold a wealth of information and knowledge that current students can benefit from. Your expertise and advice are more valuable than you may realize.
                </p>
              </Col>

              <Col lg={6}>
                <ul className='styled-bullets-list'>
                  <li>
                    Be committed to serving as a resource to the student.
                  </li>
                  <li>
                    Be committed to serving as a resource to the student.
                  </li>
                  <li>
                    Provide real-world feedback on jobs, industry knowledge, and career aspirations.
                  </li>
                  <li>
                    Handholding the student in meeting his or her career goals.
                  </li>
                  <li>
                    Assist the student’s personal growth.
                  </li>
                </ul>
              </Col> */}

                {/* <Col lg={6}>
              <ul className="styled-bullets-list">
                <li>Discuss the common fields of interest.</li>
                <li>Share experiences of working in a particular field.</li>
                <li>Encouragement with goal setting and achievement of those goals.</li>
                <li>Respond to the emails of the program administrator.</li>
              </ul>
            </Col> */}

                {/* <Col sm={12}>
                <div className="student-expected w-100 mb-4 mt-5">
                  <Row className="align-items-center">
                    <Col lg={6}>
                      <h3 className="wavey-heading mb-5">Students are expected to:</h3>
                      {list?.expected_desc && (
                        <p dangerouslySetInnerHTML={{ __html: list?.expected_desc }}></p>
                      )}
                      <h5>PUT THE TIME WITH MENTOR TO THE BEST USE.</h5>

                      <ul className="styled-bullets-list">
                        <li>Come to meetings with mentor prepared with planned topics.</li>
                        <li>
                          Respond in a timely manner to your mentor’s feedback. Respond to emails from
                          your mentor at most two days after receipt.
                        </li>
                        <li>
                          Be open and honest with your mentor about your challenges and weaknesses.
                        </li>
                        <li>
                          If something concerning the mentor needs to be discussed with others, it
                          should first be discussed within the mentoring relationship.
                        </li>

                        <h5>TAKE A PROACTIVE ROLE IN SHAPING UP THE RELATIONSHIP.</h5>

                        <li>
                          Understand what you want from the mentoring relationship and communicate
                          your goals and aspirations to your mentor. Balance personal and professional
                          relationships with your mentor.
                        </li>
                        <li>
                          Set aside time for the mentoring process and keep all scheduled appointments
                          with your mentor and display professional behavior.
                        </li>
                      </ul>
                    </Col>
                    <Col lg={6}>
                      <img src={require('../../../assets/images/student-expected.png')} alt="img" />
                    </Col>
                  </Row>
                </div>
              </Col> */}

                {/* <Col sm={12}>
                <h3 className="wavey-heading mb-5 mt-4">Students are not expected to:</h3>
                {list?.notexpected_desc && (
                  <span dangerouslySetInnerHTML={{ __html: list?.notexpected_desc }} />
                )}
                <ul className="styled-bullets-list mb-5">
                  <li>
                    Conducting themselves in a non-professional manner which brings the reputation of
                    their institute or mentors down.
                  </li>
                  <li>Seek financial support from a mentor.</li>
                  <li>Pressurize the mentor to search for a job for him or her.</li>
                  <li>Blame the mentor if his or her advice doesn’t work out.</li>
                  <li>Divulge the details provided by the mentor to others.</li>
                  <li>Call the mentor at an inappropriate time without a prior appointment.</li>
                </ul>
              </Col> */}
              </Row>
            </Container>
          </section></> : <><NoDataFound /></>}
      </div>
      <div className="lgn-link pb-4">
        <Container>
          <div className="logn-lnk-inr">
            <p>
              <Link to="/institute-register">Register</Link> /{' '}
              <Link to="/institute-login">Login</Link> to browse members by Year, Name, Company,
              Profession or Location
            </p>
          </div>
        </Container>
      </div>
    </>
  );
}

export default MentorStudent;
