/**
@copyright  :   ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
@author     :   Shiv Charan Panjeta 
 
All Rights Reserved.
Proprietary and confidential :  All information contained here in is, and remains
the property of ToXSL Technologies Pvt. Ltd. and it's partners.
Unauthorized copying of this file, via any medium is strictly prohibited.
*/
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { useEffect, useState } from 'react';
import { Button, Container } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { useNavigate } from 'react-router-dom';
import { AsyncPaginate } from 'react-select-async-paginate';
import AsyncSelect from 'react-select/async';
import { imageExtension } from '../../globals/helper';
import * as ADMINSERVICES from '../../services/AuthServices';
import showNotification from '../../services/NotificationService';
import { loadAllInstituteOptions } from '../AdminHelper';

export default function AddCampusnews() {
  const navigate = useNavigate();
  const [hiringSearch, setHiringSearch] = useState('');
  const [value, setValue] = useState([]);

  const components = {
    DropdownIndicator: null
  };
  const [clicked, setClicked] = useState(false);

  const id = new URLSearchParams(location?.search).get('id');

  useEffect(() => {
    if (id) {
      apiCall(id);
    }
  }, []);

  const [detail, setDetail] = useState({
    institute_id: '',
    title: '',
    description: '',
    attachment: ''
  });

  const validate = () => {
    let error = {};
    if (detail?.title == '') {
      error.title = 'Required Field';
    }
    if (detail?.description == '') {
      error.description = 'Required Field';
    }

    return error;
  };

  const loadOptions = async (search, loadedOptions, { page }) => {
    try {
      const resp = await ADMINSERVICES.getSkillsDropdown(search, page);
      return {
        options: resp?.data?.results ?? [],
        hasMore: loadedOptions.length === resp?.data?.more ? false : true,
        additional: {
          page: search ? page + 1 : page + 1
        }
      };
    } catch (error) {
      console.log('error', error);
    }
  };

  const saveHandler = () => {
    let body = {
      institute_id: detail?.institute_id?.id,
      title: detail?.title,
      description: detail?.description,
      attachment: detail?.attachment
    };
    let formData = new FormData();
    for (let i in body) {
      formData.append(`${i}`, body[i]);
    }
    formData.append('tags', value?.map((id) => id.name).join());
    ADMINSERVICES.addCampusNews(formData).then((res) => {
      setClicked(true);

      if (!Object.keys(validate()).length) {
        setClicked(false);

        if (res?.status === 200) {
          navigate('/admin/news/index/campus_news');
          showNotification('success', res?.data?.message);
        }
      }
    });
  };

  const updateHandler = () => {
    let body = {
      id: id,
      institute_id: detail?.institute_id?.id,
      title: detail?.title,
      description: detail?.description,
      attachment: detail?.attachment
    };
    let formData = new FormData();

    for (let i in body) {
      formData.append(`${i}`, body[i]);
    }
    formData.append('tags', value?.map((id) => id.name || id.label).join());

    setClicked(true);
    if (!Object.keys(validate(detail)).length) {
      ADMINSERVICES.editCampusNews(formData).then((res) => {
        setClicked(false);

        if (res?.status === 200) {
          navigate('/admin/news/index/campus_news');
          showNotification('success', res?.data?.message);
        }
      });
    }
  };

  const handleChange = (e, defaultName) => {
    if (e.target.type == 'file') {
      let file = e.target.files[0];
      let type = e.target.files[0].type;
      if (imageExtension.includes(type)) {
        setDetail({
          ...detail,
          [e.target.name]: file
        });
      } else {
        showNotification('danger', 'file format not supported !');
      }
    } else {
      if (defaultName == 'institute_id') {
        setDetail({ ...detail, [defaultName]: e });
      } else {
        setDetail({ ...detail, [e.target.name]: e.target.value });
      }
    }
  };

  const apiCall = (id) => {
    ADMINSERVICES.getCampusNewsView(id).then((res) => {
      if (res?.status === 200) {
        let data = res?.data?.data?.info;
        setDetail();
        setDetail({
          institute_id: {
            id: data?.institute_id,
            label: data?.name,
            value: data?.institute_id
          },
          attachment: data?.url,
          title: data?.title,
          description: data?.description
        });

        if (data?.tags) {
          let arr = [];

          data?.tags?.split(',')?.map((data) => {
            arr.push({ name: data });
          });

          setValue(arr);
        }
      }
    });
  };

  const handleChangeInstitute = (data) => {
    setDetail({ ...detail, ['institute_id']: data });
  };

  return (
    <div className="dash-main-content">
      <Container fluid>
        <div className="dash-page-content">
          <div className="dash-page-title">
            <Row>
              <Col xs={12} sm={12} md={12}>
                <div className="dash-title-bar d-flex align-items-center justify-content-between">
                  <div className="title-lft d-flex align-items-center">
                    <div className="dash-icn">
                      <i className="fas fa-newspaper"></i>
                    </div>
                    <div className="dash-title-dis">
                      <h5>Add Campus News</h5>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <div className="dash-content-card">
            <div className="cmmn-form">
              <Form>
                <Row>
                  <Col md={12}>
                    <Form.Group className="mb-3" controlId="formBasicName">
                      <Form.Label>Institute</Form.Label>

                      <AsyncSelect
                        components={components}
                        cacheOptions
                        defaultOptions
                        isClearable
                        value={detail?.institute_id}
                        getOptionLabel={(e) => e.label}
                        getOptionValue={(e) => e.value}
                        loadOptions={loadAllInstituteOptions}
                        // onInputChange={(newValue) => setHiringSearch(newValue)}
                        className="basic-single mt-1"
                        name="institute_id"
                        onChange={(e) => {
                          handleChangeInstitute(e);
                        }}
                      />
                    </Form.Group>
                  </Col>

                  <Col md={12}>
                    <Form.Group className="mb-3" controlId="formBasicName">
                      <Form.Label>
                        Title <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="News Title here."
                        name="title"
                        onChange={handleChange}
                        value={detail?.title}
                      />
                      <div className={'danger-frm'}>{clicked && validate()?.title}</div>
                    </Form.Group>
                  </Col>

                  <Col md={12}>
                    <Form.Group className="mb-3" controlId="formBasicDesi">
                      <Form.Label>
                        Description <span className="text-danger">*</span>{' '}
                      </Form.Label>
                      <CKEditor
                        name="description"
                        data={detail?.description}
                        editor={ClassicEditor}
                        onReady={(editor) => {}}
                        config={{
                          toolbar: [
                            'bold',
                            'italic',
                            'numberedList',
                            'bulletedList',
                            'outdent',
                            'indent'
                          ]
                        }}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          setDetail((prevState) => ({ ...prevState, ['description']: data }));
                        }}
                        onBlur={(event, editor) => {}}
                        onFocus={(event, editor) => {}}
                      />
                      <div className={'danger-frm'}>{clicked && validate()?.description}</div>
                    </Form.Group>
                  </Col>

                  <Col md={12}>
                    <Form.Group controlId="formFileMultiple" className="mb-3">
                      <Form.Label>
                        Tagging:
                        <sub className="ms-1">
                          <i className="fas fa-info-circle"></i> (Enter text tag e.g Computers,
                          Press Esc key for done tagging.)
                        </sub>
                      </Form.Label>
                      <AsyncPaginate
                        className="w100  p16 mr_normal_padding_l mr_medium_padding_r"
                        loadOptions={loadOptions}
                        id={Math.random()}
                        getOptionValue={(option) => {
                          return option.name;
                        }}
                        getOptionLabel={(option) => {
                          return option.name;
                        }}
                        value={value}
                        onChange={setValue}
                        additional={{
                          page: 1
                        }}
                        debounceTimeout={500}
                        isSearchable={true}
                        isMulti
                      />
                    </Form.Group>
                  </Col>

                  <Col md={12}>
                    <Form.Group controlId="formFileMultiple" className="mb-3">
                      <Form.Label>
                        Attach Files{' '}
                        <sub className="ms-1">
                          <i className="fas fa-info-circle"></i> (Image / Docs.)
                        </sub>
                      </Form.Label>
                      <Form.Control
                        type="file"
                        multiple
                        name="attachment"
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <div className="form-btns d-flex align-items-center mt-3">
                  {!id ? (
                    <Button className="main-btn-grn" onClick={saveHandler}>
                      Save
                    </Button>
                  ) : (
                    <Button className="main-btn-grn" onClick={updateHandler}>
                      Update
                    </Button>
                  )}

                  <strong>OR</strong>
                  <Button
                    className="main-btn-red"
                    onClick={() => {
                      navigate(-1);
                    }}>
                    Cancel
                  </Button>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}
