/**
@copyright  :   ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
@author     :   Shiv Charan Panjeta 
 
All Rights Reserved.
Proprietary and confidential :  All information contained here in is, and remains
the property of ToXSL Technologies Pvt. Ltd. and it's partners.
Unauthorized copying of this file, via any medium is strictly prohibited.
*/

import moment from 'moment';
import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { Link } from 'react-router-dom';
import { truncate } from '../../../globals/helper';
import useDocumentTitle from '../../../globals/useDocumentTitle';
import { getAppliedJobDetail } from '../../../services/JobServices';
import HeaderLogin from '../../commoncomponents/HeaderLogin';
import NewsSidebar from '../../commoncomponents/newsfeeds/NewsSidebar';
import * as index from '../../commoncomponents/skelton/allskelton';
import TopTabbings from './TopTabbings';

function JobsApplied() {
  useDocumentTitle('Applied Jobs | AlmaBay');
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [total, setTotal] = useState('');
  useEffect(() => {
    getAppliedJobs();
  }, []);

  const getAppliedJobs = (page) => {
    if (!page) {
      setLoading(true);
    }
    getAppliedJobDetail(page ? page : currentPage).then((res) => {
      if (res?.status === 200) {
        if (page) {
          setList([...list, ...(res?.data?.data ?? [])]);
        } else {
          setList(res?.data?.data ?? []);
        }
        setLoading(false);
        setTotal(res?.data?.pager?.total);
        setLoading(false);
      }
    });
  };
  const handleLoadMore = (e) => {
    e.preventDefault();
    setCurrentPage(currentPage + 1);
    getAppliedJobs(currentPage + 1);
  };

  const backgroundFunction = (key) => {
    switch (key) {
      case 'Pending':
        return '#BBBBBB';
      case 'Approved':
        return '#89BE83';
      case 'Declined':
        return '#D08686';
      case 'Declined Due to Job Expire':
        return '#BBBBBB';
      default:
        return '#BBBBBB';
    }
  };
  return (
    <>
      <HeaderLogin />
      <div className="header-lnk-feeds">
        <div className="container">
          <TopTabbings state={'feed'} />
        </div>
      </div>
      <section className="feed-sec bg-grey py-4">
        <div className="container">
          <Row>
            <Col xs={12} lg={2} xl={2}>
              <NewsSidebar state="0" current="applied" />
            </Col>

            <Col xs={12} lg={10} xl={10}>
              <div className="job-recomm-card bg-white">
                <h4>Applied Jobs</h4>
                <h6 className="intern-count-hd pt-3 px-3">You have applied {list?.length} jobs</h6>
                <div className="job-recommd-lst p-3">
                  {list &&
                    list?.map((data, index) => (
                      <div className="job-recomm-card-inr jobs-applied-card" key={index}>
                        <div className="job-recomm-dis applied-dis">
                          <Link
                            className="main-title"
                            to={`/jobs/jobs-details/${data?.id}`}
                            target="_blank">
                            {data?.job_title}
                          </Link>
                          <h5>{data?.company_name}</h5>
                          <div className="d-flex align-items-center justify-content-between">
                            <ul className="job-recomm-addrs mt-3 mb-3">
                              <li>
                                <i title="Location" className="fas fa-map-marker-alt"></i>
                                {data?.city_name}
                              </li>
                              {data?.contact_number && (
                                <li>
                                  <i title="Contact number" className="fas fa-phone-alt"></i>
                                  {data?.contact_number}
                                </li>
                              )}
                              {data?.hr_email && (
                                <li>
                                  <i title="Email ID" className="fas fa-envelope"></i>
                                  {data?.hr_email}
                                </li>
                              )}

                              <li>
                                <i title=" Positions open" className="fas fa-users"></i>
                                Positions open: {data?.open_positions}
                              </li>
                              <li>
                                <i className="far fa-clock"></i>
                                {moment(data?.created_on).format(`MMM DD ,Y`)}
                              </li>
                            </ul>
                            <span className="full-tag">{data?.job_types}</span>
                          </div>
                          <div className="applied-hd-card ">
                            <div>
                              {data?.job_info && (
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: truncate(data?.job_info, 250)
                                  }}
                                />
                              )}
                            </div>
                          </div>
                          <Link
                            className="view-dtl-btn"
                            to={`/jobs/jobs-details/${data?.id}`}
                            target="_blank">
                            View in Detail
                          </Link>
                          <div className="applide-ftr d-flex align-items-center flex-wrap justify-content-between">
                            <p>Applied on: {data?.applied_on}</p>

                            <span
                              className="applied-tag"
                              style={{
                                backgroundColor: backgroundFunction(data?.jobAppliedStatus)
                              }}>
                              {data?.jobAppliedStatus}
                            </span>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
                {loading && <index.JobsAppliedSkelton />}
              </div>
              {total !== undefined && list?.length != total && (
                <div className="load-more-btn text-center">
                  <Button variant="secondary" className="read-more" onClick={handleLoadMore}>
                    Load More
                  </Button>
                </div>
              )}
            </Col>
          </Row>
        </div>
      </section>
      {/* <FooterMain className={'colour-bg'} /> */}
    </>
  );
}

export default JobsApplied;
