import { Col, Container, Row } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import HeaderLogin from '../../../../commoncomponents/HeaderLogin';
import ProfileSidebar from '../../../../commoncomponents/profile/ProfileSidebar';
import ResetPassword from '../../../../commoncomponents/profile/ResetPassword';
import TopTabbings from '../../TopTabbings';
import useDocumentTitle from '../../../../../globals/useDocumentTitle';

function ChangePassword() {
  useDocumentTitle('My Account | AlmaBay');
  const navigate = useNavigate();
  return (
    <>
      <HeaderLogin state={'d-none'} />
      <TopTabbings />
      <section className="account-info-header">
        <Container
          onClick={() => {
            navigate('/user/profile');
          }}
        >
          <h4>
            {' '}
            <span className="user-icon"></span> Privacy Settings
          </h4>
        </Container>
      </section>
      <section className="bg-grey">
        <ProfileSidebar state={'change'} head={'privacy'} />
        <div className="pt-4 pb-5">
          <Container>
            <Row>
              <Col lg={7} md={12}>
                <div className="account-form">
                  <div className="section_details_heading">
                    <h2>Manage your account access and security settings</h2>
                    <p>
                      Control your password and account access alongwith backup options if your
                      Almabay account gets locked.
                    </p>
                  </div>
                  <div>
                    <ResetPassword />
                  </div>
                </div>
              </Col>
              <Col lg={5} md={12}>
                <div className="signing-almabay">
                  <div className="signing">
                    <img
                      className="img-responsive"
                      src={require('../../../../../assets/images/account-privacy-icon.png')}
                      alt="img"
                    />
                    <h2 className="heading">Signing in to AlmaBay</h2>
                    <p> Get everything you need to build a career by filling in the details. </p>
                    <ul>
                      <li className="d-flex">
                        <div>
                          <span className="user-thumb">1</span>
                        </div>
                        <div className="article-post">
                          <span> Change Password </span>
                          <p>
                            Change password as per your convenience to keep your account secured.
                          </p>
                        </div>
                      </li>
                      <li className="d-flex">
                        <div>
                          <span className="user-thumb">2</span>
                        </div>
                        <div className="article-post">
                          <span> Privacy Settings </span>
                          <p>
                            You can choose whom you want to share your posts, message and comments
                            with by selecting your privacy settings.
                          </p>
                        </div>
                      </li>
                    </ul>

                    <div className="mx-4">
                      <div className="terms-condition">
                        <ul className="d-flex mb-0 ps-0 ">
                          <li className="me-2 p-0">
                            <Link to="/privacy_policy" target="_blank">
                              Privacy Policy
                            </Link>
                          </li>
                          <li className="me-2 p-0">
                            <Link to="/terms_of_use" target="_blank">
                              Terms of Use
                            </Link>
                          </li>
                          <li className="p-0">
                            <Link to="/contact-us" target="_blank">
                              Contact Us
                            </Link>
                          </li>
                        </ul>
                      </div>
                      <div className="company-name">AlmaBay.com © {new Date().getFullYear()} </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </section>
    </>
  );
}

export default ChangePassword;
