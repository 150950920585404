import { Button, Form } from 'react-bootstrap';

const NotificationCommunication = ({
  dropdownValue,
  checkedName,
  communicatinDetails,
  templates,
  setSelectedIndex,
  handleChange,
  communication,
  content,
  setContent,
  communicate
}) => {
  return (
    <Form>
      <Form.Group className="mb-3" controlId="formBasicPassword">
        <Form.Select
          onChange={(e) => {
            handleChange(e);
            setSelectedIndex(communication === 'notification' && e.target.selectedIndex);
            setContent(templates[e.target.selectedIndex - 1]?.content);
          }}
          value={communicatinDetails?.template_id}
          aria-label="Default select example"
          name="status"
        >
          <option value="">Select Template</option>
          {templates?.map((data, index) => {
            return <option key={index} value={data?.id}>{data?.title}</option>;
          })}
        </Form.Select>
      </Form.Group>
      <p>Send Notification</p>
      <Form.Group className="mb-3" controlId="formBasicPassword">
        <Form.Label>
          To <span className="text-danger">*</span>
        </Form.Label>
        <div className="members_name_wrapper">
          <div className="members_name">
            {checkedName?.map((name, index) => {
              return <span key={index}>{name}</span>;
            })}
          </div>
        </div>
      </Form.Group>
      <Form.Group className="mb-3" controlId="formBasicPassword">
        <Form.Label>
          Content <span className="text-danger">*</span>
        </Form.Label>
        {/* <CKEditorForCommunication isToolbar={true} /> */}
        <textarea
          onChange={(e) => setContent(e.target.value)}
          name="content"
          id=""
          cols="30"
          style={{ width: '100%' }}
          value={content ? content : ''}
        ></textarea>
      </Form.Group>
      <div className="d-flex justify-content-between">
        <p>
          <sup>
            <b>*</b>
          </sup>
          Sending this notification to {dropdownValue() !== 'All' ? 0 : null} {dropdownValue()}
        </p>
        <Button
          onClick={(e) => {
            communicate(e);
          }}
        >
          Send
        </Button>
      </div>
    </Form>
  );
};

export default NotificationCommunication;
