import { Link } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import FooterMain from '../../commoncomponents/FooterMain';
import { Breadcrumb, BreadcrumbItem, Container } from 'react-bootstrap';

function BlogPage() {
  return (
    <>
      <section className="blog_header">
        <div className="text-center py-3 border-bottom border-grey">
          <Link to="/">
            <img src={require('../../../assets/images/alma.png')} alt="logo" />
          </Link>
        </div>
        <div className="border-bottom border-grey text-center">
          <ul className="d-flex justify-content-center align-items-center mb-0">
            <li className="">
              <Link className="border-end border-grey p-3 d-block" to="/">
                Home
              </Link>
            </li>
            <li className="">
              <Link className="p-3 d-block" to="/">
                Almabay
              </Link>
            </li>
          </ul>
        </div>
      </section>
      <section className="blog-content py-5">
        <Container>
          <Row>
            <Col md={8}>
              <div className="single_blog_card">
                <h2 className="entry-title">
                  <Link to="/">Life Coaching for High School Students</Link>
                </h2>
                <Breadcrumb>
                  <BreadcrumbItem href="#">June 13, 2017</BreadcrumbItem>
                  <BreadcrumbItem href="#">AlmaBay</BreadcrumbItem>
                  <BreadcrumbItem href="#">almabay</BreadcrumbItem>
                </Breadcrumb>
                <p className="mb-0">
                  college excite you? Of course, it does! With hundreds of new dreams and hopes, and
                  a sense of independence, high school students enter college every year. While
                  experience teaches us a lot, it is always wise to prepare in advance. Your high
                </p>
              </div>
              <div className="single_blog_card">
                <h2 className="entry-title">
                  <Link to="/">Life Coaching for High School Students</Link>
                </h2>
                <Breadcrumb>
                  <BreadcrumbItem href="#">June 13, 2017</BreadcrumbItem>
                  <BreadcrumbItem href="#">AlmaBay</BreadcrumbItem>
                  <BreadcrumbItem href="#">almabay</BreadcrumbItem>
                </Breadcrumb>
                <p className="mb-0">
                  college excite you? Of course, it does! With hundreds of new dreams and hopes, and
                  a sense of independence, high school students enter college every year. While
                  experience teaches us a lot, it is always wise to prepare in advance. Your high
                </p>
              </div>
              <div className="single_blog_card">
                <h2 className="entry-title">
                  <Link to="/">Life Coaching for High School Students</Link>
                </h2>
                <Breadcrumb>
                  <BreadcrumbItem href="#">June 13, 2017</BreadcrumbItem>
                  <BreadcrumbItem href="#">AlmaBay</BreadcrumbItem>
                  <BreadcrumbItem href="#">almabay</BreadcrumbItem>
                </Breadcrumb>
                <p className="mb-0">
                  college excite you? Of course, it does! With hundreds of new dreams and hopes, and
                  a sense of independence, high school students enter college every year. While
                  experience teaches us a lot, it is always wise to prepare in advance. Your high
                </p>
              </div>
              <div className="single_blog_card">
                <h2 className="entry-title">
                  <Link to="/">Life Coaching for High School Students</Link>
                </h2>
                <Breadcrumb>
                  <BreadcrumbItem href="#">June 13, 2017</BreadcrumbItem>
                  <BreadcrumbItem href="#">AlmaBay</BreadcrumbItem>
                  <BreadcrumbItem href="#">almabay</BreadcrumbItem>
                </Breadcrumb>
                <p className="mb-0">
                  college excite you? Of course, it does! With hundreds of new dreams and hopes, and
                  a sense of independence, high school students enter college every year. While
                  experience teaches us a lot, it is always wise to prepare in advance. Your high
                </p>
              </div>
            </Col>
            <Col md={4}>
              <div className="blog_side_options">
                <form>
                  <input placeholder="Search" className="form-control"></input>
                </form>
              </div>
              <div className="blog_side_options widget widget_archive">
                <h4 className="widget-title mt-0">Archives</h4>

                <ul>
                  <li>
                    <Link to="#">June 2017</Link>
                  </li>
                  <li>
                    <Link to="#">July 2017</Link>
                  </li>
                  <li>
                    <Link to="#">June 2017</Link>
                  </li>
                  <li>
                    <Link to="#">July 2017</Link>
                  </li>
                </ul>
              </div>

              <div className="blog_side_options widget widget_categories">
                <h4 className="widget-title mt-0">Categories</h4>

                <ul>
                  <li>
                    <Link to="#">almabay</Link>
                  </li>
                  <li>
                    <Link to="#">Alumni association</Link>
                  </li>
                  <li>
                    <Link to="#">almabay</Link>
                  </li>
                  <li>
                    <Link to="#">Alumni association</Link>
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <FooterMain className={'colour-bg'} />
    </>
  );
}

export default BlogPage;
