/**
@copyright  :   ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
@author     :   Shiv Charan Panjeta 
 
All Rights Reserved.
Proprietary and confidential :  All information contained here in is, and remains
the property of ToXSL Technologies Pvt. Ltd. and it's partners.
Unauthorized copying of this file, via any medium is strictly prohibited.
*/

import { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import { useLocation } from 'react-router-dom';
import EmailTemplate from '../ManageTemplate/TemplateComponents/EmailTemplate';
import NotificationTemplate from '../ManageTemplate/TemplateComponents/NotificationTemplate';
import SmsTemplate from '../ManageTemplate/TemplateComponents/SmsTemplate';
import TokenTemplate from '../ManageTemplate/TemplateComponents/TokenTemplate';
import WhatsappTemplate from '../ManageTemplate/TemplateComponents/WhatsappTemplate';
import WhatsappBussTemplate from './TemplateComponents/WhatsappBussTemplate';

export default function ManageTemplate() {
  const [selectedTab, setSelectedTab] = useState('token');
  let location = useLocation();

  useEffect(() => {
    if (location.state !== null) {
      setSelectedTab(location.state.active);
    }
  }, [location?.state]);

  return (
    <div className="dash-main-content">
      <Container fluid>
        <div className="dash-page-content">
          <div className="dash-page-title">
            <Row>
              <Col xs={12} sm={12} md={12}>
                <div className="dash-title-bar d-flex align-items-center justify-content-between">
                  <div className="title-lft d-flex align-items-center">
                    <div className="dash-icn">
                      <i className="fas fa-briefcase"></i>
                    </div>
                    <div className="dash-title-dis">
                      <h5>Manage Communication Template</h5>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <div className="dash-content-card">
            <div className="template-content-module">
              <Tab.Container id="left-tabs-example" activeKey={selectedTab}>
                <Row>
                  <Col sm={12}>
                    <Nav variant="pills" className="template-tabs mb-4">
                      <Nav.Item
                        onClick={() => {
                          setSelectedTab('token');
                        }}>
                        <Nav.Link eventKey="token">Token</Nav.Link>
                      </Nav.Item>
                      <Nav.Item
                        onClick={() => {
                          setSelectedTab('notification');
                        }}>
                        <Nav.Link eventKey="notification">Notification</Nav.Link>
                      </Nav.Item>
                      <Nav.Item
                        onClick={() => {
                          setSelectedTab('sms');
                        }}>
                        <Nav.Link eventKey="sms">SMS </Nav.Link>
                      </Nav.Item>
                      <Nav.Item
                        onClick={() => {
                          setSelectedTab('email');
                        }}>
                        <Nav.Link eventKey="email">Email</Nav.Link>
                      </Nav.Item>
                      <Nav.Item
                        onClick={() => {
                          setSelectedTab('whatsapp');
                        }}>
                        <Nav.Link eventKey="whatsapp">Whatsapp</Nav.Link>
                      </Nav.Item>
                      <Nav.Item
                        onClick={() => {
                          setSelectedTab('whatsapp-business');
                        }}>
                        <Nav.Link eventKey="whatsapp-business">Whatsapp Business</Nav.Link>
                      </Nav.Item>
                      {/* <Nav.Item>
                          <Nav.Link eventKey="seven">Offer Template</Nav.Link>
                        </Nav.Item> */}
                      {/* <Nav.Item>
                          <Nav.Link eventKey="eight">CV Token</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="nine">CV Template</Nav.Link>
                        </Nav.Item> */}
                    </Nav>
                  </Col>
                  <Col sm={12}>
                    <Tab.Content>
                      <Tab.Pane eventKey="token">
                        <TokenTemplate selectedTab={selectedTab} />
                      </Tab.Pane>
                      <Tab.Pane eventKey="notification">
                        <NotificationTemplate selectedTab={selectedTab} />
                      </Tab.Pane>
                      <Tab.Pane eventKey="sms">
                        <SmsTemplate selectedTab={selectedTab} />
                      </Tab.Pane>
                      <Tab.Pane eventKey="email">
                        <EmailTemplate selectedTab={selectedTab} />
                      </Tab.Pane>
                      <Tab.Pane eventKey="whatsapp">
                        <WhatsappTemplate selectedTab={selectedTab} />
                      </Tab.Pane>
                      <Tab.Pane eventKey="whatsapp-business">
                        <WhatsappBussTemplate selectedTab={selectedTab} />
                      </Tab.Pane>
                      {/* <Tab.Pane eventKey="seven">
                          <OfferTemplate selectedTab={selectedTab} />
                        </Tab.Pane> */}
                      {/* <Tab.Pane eventKey="eight">
                          <CvTokens />
                        </Tab.Pane>
                        <Tab.Pane eventKey="nine">
                          <CvTemplate />
                        </Tab.Pane> */}
                    </Tab.Content>
                  </Col>
                </Row>
              </Tab.Container>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}
