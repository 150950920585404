/**
 * @copyright : ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
@author    : Shiv Charan Panjeta 
 
All Rights Reserved.
Proprietary and confidential :  All information contained here in is, and remains
the property of ToXSL Technologies Pvt. Ltd. and it's partners.
Unauthorized copying of this file, via any medium is strictly prohibited.
 */

import { MarkerWithLabel } from '@googlemaps/markerwithlabel';
import GoogleMapReact from 'google-map-react';
import { useEffect, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import ReactGoogleAutocomplete from 'react-google-autocomplete';
import { useDispatch } from 'react-redux';
import MapCircleImage from '../../../../assets/images/map_circle.png';
import { allChapterList, allChaptersList } from '../../../../redux/action';
import { chapterMap } from '../../../../services/InstituteServices';
import { mapStyle } from '../../../../super-admin/map/mapStyle';
import useInfiniteScroller from '../../user-pages/useInfiniteScroller';

let markers = [];
let map = null;
let circle = null;
let distanceWidget;
let radiusWidget;

const ChapterMap = () => {
  let maxRadiusSize = 999;
  const dispatch = useDispatch();
  const [clickedPlace, setClickedPlace] = useState('');
  const [isRadialSearchActive, setIsRadialSearchActive] = useState(false);
  const [addressCoordinate, setAddressCoordinate] = useState({
    lat: null,
    lng: null
  });
  const [unit, setUnit] = useState('km');
  const [radius, setRadius] = useState(20); // default seems in km according to new code
  const [currentPosition, setCurrentPosition] = useState({
    lat: null,
    lng: null
  });
  const [mapState, setMapState] = useState(null);
  const [mapsState, setMapsState] = useState(null);
  const [mapOptions, setMapOptions] = useState({
    styles: mapStyle,
    minZoom: 2,
    // minZoomOverride: true,
    maxZoom: 12,
    rotateControl: true,
    mapTypeControl: false,
    streetViewControl: false,
    fullscreenControl: true,
    fullscreenControlOptions: {
      position: window.google.maps.ControlPosition.RIGHT_TOP
    },
    zoomControl: true,
    zoomControlOptions: {
      position: window.google.maps.ControlPosition.RIGHT_TOP
    }
  });
  const [isClicked, setIsClicked] = useState(false);
  const [zoomLevel, setZoomLevel] = useState(null);
  const [lastElement, setLastElement] = useState(null);
  const [shouldLoadMoreButtonVisible, setShouldLoadMoreButtonVisible] = useState(false);
  const [isActive, setActive] = useState(false);
  const [isPosition, setIsPosition] = useState(false);
  const [isZooming, setIsZooming] = useState(false);

  useEffect(() => {
    getLocation.then((position) => {
      setIsPosition(true);
      setCurrentPosition({
        lat: JSON.parse(position.coords.latitude),
        lng: JSON.parse(position.coords.longitude)
      });
    });
  }, [radius]);

  let getLocation = new Promise(function (myResolve, myReject) {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        myResolve(position);
      });
    } else {
      myReject();
    }
  });

  const handleRadialSearch = (lat, lng, e) => {
    if (lat && lng) {
      // calculate sizer position from center of the circle
      circle.setCenter(new window.google.maps.LatLng(lat, lng));
      map.setCenter(new window.google.maps.LatLng(lat, lng));
      // e.preventDefault();
    } else {
      getResult(map.getZoom(), lat, lng); // get results
    }
  };

  const clearMarkers = (markers) => {
    markers.forEach((marker) => {
      marker.setMap(null);
    });
    markers = [];
  };

  const createMarker = (map, result, text = '') => {
    let labelsClass = 'marker_label_job_map';
    let icon = {
      url: require('../../../../assets/images/big-marker.png')
    };

    if (result.count < 10) {
      icon.scaledSize = new window.google.maps.Size(40, 40);
      labelsClass += ' lt10';
    } else if (result.count >= 10 && result.count < 100) {
      icon.scaledSize = new window.google.maps.Size(45, 45);
      labelsClass += ' lt100';
    } else if (result.count >= 100 && result.count < 500) {
      icon.scaledSize = new window.google.maps.Size(55, 55);
      labelsClass += ' lt500';
    } else if (result.count >= 500 && result.count < 1000) {
      icon.scaledSize = new window.google.maps.Size(60, 60);
      labelsClass += ' lt1000';
    } else if (result.count >= 1000 && result.count < 5000) {
      icon.scaledSize = new window.google.maps.Size(68, 68);
      labelsClass += ' lt5000';
    } else if (result.count >= 5000 && result.count < 10000) {
      icon.scaledSize = new window.google.maps.Size(72, 72);
      labelsClass += ' lt10000';
    } else {
      icon.scaledSize = new window.google.maps.Size(100, 100);
      labelsClass += ' gt10000';
    }

    let marker = new MarkerWithLabel({
      position: {
        lat: Number(result.latitude),
        lng: Number(result.longitude)
      },
      map: map,
      labelContent:
        '<span title="Total Chapters in ' + result['name'] + text + '">' + result.count + '</span>',
      icon: icon,
      labelAnchor: { x: 7, y: 28 },
      title: result['name'],
      labelClass: labelsClass
    });

    marker.addListener('click', () => {
      handleMarkerClick(result);
    });

    return marker;
  };

  const handleSearch = async () => {
    let zoomLevel = map.getZoom();
    let newPlaceType = 'country';
    if (zoomLevel < 5) {
      newPlaceType = 'country';
    } else if (zoomLevel > 4 && zoomLevel < 7) {
      newPlaceType = 'state';
    } else if (zoomLevel > 6) {
      newPlaceType = 'city';
    }

    getResult(map.getZoom());
  };

  const createRadialSearch = () => {
    if (circle) {
      circle.setMap(null);
    }
    // create a circle
    distanceWidget = new DistanceWidget(map, radius, currentPosition);

    // Set the distance property value, default to 20km.
    // window.google.maps.setOptions({ minZoom: 8, zoom: 10 });
    setMapOptions({
      ...mapOptions,
      minZoom: 8,
      zoom: 10
    });
    map.setOptions({ minZoom: 8, zoom: 10 });
    distanceWidget.addListener('distance_changed', function () {
      let radd = distanceWidget.get('distance').toFixed();

      onRadiusChange(radd);
    });
    distanceWidget.addListener('position_changed', function () {
      let radius = distanceWidget.get('distance').toFixed();
    });
    setIsRadialSearchActive(true);
  };

  const getResult = async (zoom, lat, lng) => {
    let newPlaceType = 'country';
    if (zoom < 5) {
      newPlaceType = 'country';
    } else if (zoom > 4 && zoom < 7) {
      newPlaceType = 'state';
    } else if (zoom > 6) {
      newPlaceType = 'city';
    }

    let latitude = isRadialSearchActive ? radiusWidget.get('center').lat() : '';
    let longitude = isRadialSearchActive ? radiusWidget.get('center').lng() : '';

    let add_privacy = '';
    let chapter_privacy = '';
    let timeline_post_privacy = '';
    let institue_id = '';
    let location = '';
    let admin_id = '';
    let q = '';

    let resp;
    resp = await chapterMap(
      q,
      newPlaceType,
      add_privacy,
      chapter_privacy,
      timeline_post_privacy,
      institue_id,
      location,
      admin_id,
      isRadialSearchActive ? 'true' : 'false',
      isRadialSearchActive
        ? encodeURIComponent(
            `radius=${radius < 20 ? 20 : radius}&latitude=${
              lat != undefined ? lat : latitude
            }&radius_units=0&longitude=${lng != undefined ? lng : longitude}`
          )
        : ''
    );

    let data = resp.data.data;
    if (data) {
      clearMarkers(markers);
    }

    let chaptersList = data.chapters ?? [];
    dispatch(allChapterList(data?.chapters));
    chaptersList.forEach((item) => {
      markers.push(createMarker(map, item));
    });
  };

  const onZoomLevelChanged = (zoom) => {
    setZoomLevel(zoom);
  };

  useEffect(() => {
    if (zoomLevel) {
      getResult(zoomLevel); // just call
    }
  }, [zoomLevel, isRadialSearchActive]);

  const updateCircleRadius = (rad) => {
    // calculate sizer position from center of the circle
    let center = radiusWidget.get('center');
    let distance = rad;
    let latLongFromCenter = window.google.maps.geometry.spherical.computeOffset(
      center,
      distance * 1000,
      90
    );
    radiusWidget.set('sizer_position', latLongFromCenter);
    radiusWidget.setDistance();
    setRadius(rad > maxRadiusSize ? maxRadiusSize : rad);
  };

  const resetRadialSearch = () => {
    setIsRadialSearchActive(false); // effect will trigger
    // set circle map to  nulll
    if (circle) {
      clearMarkers(markers);
      circle.setMap(null);
      map.setOptions({
        minZoom: 2,
        // initial scale
        maxZoom: 12,
        zoom: 4
      });
      setMapOptions({
        ...mapOptions,
        minZoom: 2,
        // initial scale
        maxZoom: 12,
        zoom: 4
      });
    }
  };

  const onRadiusChange = (radd) => {
    setRadius(radd > maxRadiusSize ? maxRadiusSize : radd);
  };

  const handleMarkerClick = async (result) => {
    result && setClickedPlace(result?.name.trim());

    let add_privacy = '';
    let chapter_privacy = '';
    let timeline_post_privacy = '';
    let institue_id = '';
    let location = '';
    let admin_id = '';
    let q = '';

    let body = {
      place: result ? result.name.trim() : clickedPlace,
      pt: getMapViewPointBasedOnZoom(),
      add_privacy: add_privacy,
      chapter_privacy: chapter_privacy,
      timeline_post_privacy: timeline_post_privacy,
      institue_id: institue_id,
      location: location,
      admin_id: admin_id,
      q: q
    };

    await dispatch(allChaptersList(body));
  };

  const getMapViewPointBasedOnZoom = () => {
    let zoom = map.getZoom();
    let newPlaceType = 'country';
    if (zoom < 5) {
      newPlaceType = 'country';
    } else if (zoom > 4 && zoom < 7) {
      newPlaceType = 'state';
    } else if (zoom > 6) {
      newPlaceType = 'city';
    }
    return newPlaceType;
  };

  const onIntersecting = () => {
    setShouldLoadMoreButtonVisible(true);
  };

  const handleLoadMore = () => {
    // load more
    let body = {
      place: clickedPlace !== '' ? clickedPlace : 'India',
      pt: getMapViewPointBasedOnZoom()
      // filters and other stuff
    };
  };
  useInfiniteScroller(lastElement, onIntersecting);

  const showRadiusForm = () => {
    setActive(!isActive);
    setIsRadialSearchActive(true);
  };

  return (
    <>
      <div>
        {
          <div className="absolute_form">
            <Form
              className={`${
                isRadialSearchActive
                  ? 'd-block map-distance px-4 py-2'
                  : 'd-block map-distance px-4'
              }`}>
              {!isRadialSearchActive ? (
                <div
                  className="open_closeForm d-flex align-items-center justify-content-center"
                  role="presentation"
                  onClick={() => {
                    showRadiusForm();
                    createRadialSearch();
                  }}>
                  <img src={MapCircleImage} />
                </div>
              ) : (
                <div
                  className="open_closeForm d-flex align-items-center justify-content-center"
                  role="presentation"
                  onClick={() => {
                    setIsRadialSearchActive(false);
                    resetRadialSearch();
                  }}>
                  <i style={{ fontSize: 17 }} className="fas fa-times"></i>
                </div>
              )}
              {isRadialSearchActive && (
                <Row className="align-items-end">
                  <Col lg={10}>
                    <Row className="align-items-end mb-3 mb-lg-0">
                      <Col lg={4} md={6}>
                        <Form.Label>Location</Form.Label>
                        <ReactGoogleAutocomplete
                          style={{
                            background: '#F8F8F8',
                            borderRadius: '5px',
                            color: ' #959595',
                            fontSize: '16px',
                            padding: '0.375rem 0.75rem',
                            border: 'none'
                          }}
                          placeholder="Search for place"
                          className="w100"
                          apiKey={process.env.REACT_APP_SEARCH_KEY}
                          onPlaceSelected={(props) => {
                            setAddressCoordinate((prev) => ({
                              ...prev,
                              lat: props?.geometry?.location?.lat(),
                              lng: props?.geometry?.location?.lng()
                            }));
                            handleRadialSearch(
                              props?.geometry?.location?.lat(),
                              props?.geometry?.location?.lng()
                            );
                          }}
                        />
                      </Col>
                      <Col lg={4} md={6}>
                        <Form.Label>Radius</Form.Label>
                        <Form.Control
                          name="radius"
                          onChange={(e) => {
                            if (unit === 'km') {
                              updateCircleRadius(Number(e.target.value));
                              return;
                            } else {
                              updateCircleRadius(Number(e.target.value * 1.6));
                              return;
                            }
                          }}
                          autoComplete="off"
                          value={unit == 'km' ? Math.round(radius) : Math.round(radius / 1.6)}
                          placeholder="Enter Radius"
                          type="number"
                        />
                      </Col>
                      <Col lg={4} md={6}>
                        <Form.Label htmlFor="inputPassword6">Units</Form.Label>
                        <Form.Select
                          onChange={(e) => setUnit(e.target.value)}
                          name="unit"
                          id="unit"
                          value={unit}
                          aria-label="Default select example">
                          <option value="km">Kilometers</option>
                          <option value="miles">Miles</option>
                        </Form.Select>
                      </Col>
                    </Row>
                  </Col>
                  <Col lg={2}>
                    <Button
                      onClick={() => {
                        handleRadialSearch();
                      }}
                      className="btn btn-orange">
                      Search
                    </Button>
                  </Col>
                </Row>
              )}
            </Form>
          </div>
        }

        <Row>
          <Col lg="12">
            <MapView
              handleMarkerClick={handleMarkerClick}
              isPosition={isPosition}
              currentPosition={currentPosition}
              mapOptions={mapOptions}
              setMapState={setMapState}
              setMapsState={setMapsState}
              setIsZooming={setIsZooming}
              onZoomLevelChanged={onZoomLevelChanged}
              addressCoordinate={addressCoordinate}
              setAddressCoordinate={setAddressCoordinate}
            />
          </Col>
        </Row>
      </div>
    </>
  );
};

export default ChapterMap;

//Start of circle Draw Function on google map //
/**
 * A distance widget that will display a circle that can be resized and will
 * provide the radius in km.
 *
 * @param {google.maps.Map} map The map on which to attach the distance widget.
 *
 * @constructor
 */
function DistanceWidget(map, radius, currentPosition) {
  map?.setCenter(currentPosition);
  this?.set('map', map);
  this?.set('position', map?.getCenter());

  let marker = new window.google.maps.Marker({
    icon: {
      url: require('../../../../assets/images/center-nod.png'),
      scaledSize: new window.google.maps.Size(22, 22),
      anchor: new window.google.maps.Point(8, 12)
    },
    title: 'Move me!',
    draggable: true,
    cursor: 'all-scroll'
  });

  // Bind the marker map property to the DistanceWidget map property
  marker.bindTo('map', this);

  // Bind the marker position property to the DistanceWidget position
  // property
  marker.bindTo('position', this);

  marker.bindTo('center', this, 'position');
  // Create a new radius widget
  radiusWidget = new RadiusWidget(radius);

  // Bind the radiusWidget map to the DistanceWidget map
  radiusWidget.bindTo('map', this);

  // Bind the radiusWidget center to the DistanceWidget position
  radiusWidget.bindTo('center', this, 'position');

  // Bind to the radiusWidgets' distance property
  this.bindTo('distance', radiusWidget);

  // Bind to the radiusWidgets' bounds property
  this.bindTo('bounds', radiusWidget);
}

/**
 * A radius widget that add a circle to a map and centers on a marker.
 *
 * @constructor
 */

function RadiusWidget(radius) {
  circle = new window.google.maps.Circle({
    strokeWeight: 1,
    strokeColor: '#FBAE38',
    strokeOpacity: 0.9,
    fillColor: '#FFCD81',
    draggable: true
  });

  // Set the distance property value, default to 20km.
  this.set('distance', radius);

  // Bind the RadiusWidget bounds property to the circle bounds property.
  this.bindTo('bounds', circle);

  // Bind the circle center to the RadiusWidget center property
  circle.bindTo('center', this);

  // Bind the circle map to the RadiusWidget map
  circle.bindTo('map', this);

  // Bind the circle radius property to the RadiusWidget radius property
  circle.bindTo('radius', this);

  this.addSizer();
}

const MapView = ({
  handleMarkerClick,
  isPosition,
  currentPosition,
  mapOptions,
  setMapState,
  setMapsState,
  setIsZooming,
  onZoomLevelChanged
}) => {
  useEffect(() => {
    chapterMap().then((resp) => {
      if (resp?.status === 200 && resp?.data?.data) {
        clearMarkers(markers);
        const chapterList = resp?.data?.data.chapters
          ? resp?.data?.data?.chapters
          : resp?.data?.data;
        chapterList.forEach((item) => {
          markers.push(createMarker(map, item));
        });
      }
    });
  }, []);

  const clearMarkers = (markers) => {
    markers.forEach((item) => {
      item.setMap(null);
    });
    markers = [];
  };

  const createMarker = (map, result, text = '') => {
    let labelsClass = 'marker_label_job_map';
    let icon = {
      url: require('../../../../assets/images/big-marker.png')
    };

    if (result.count < 10) {
      icon.scaledSize = new window.google.maps.Size(40, 40);
      labelsClass += ' lt10';
    } else if (result.count >= 10 && result.count < 100) {
      icon.scaledSize = new window.google.maps.Size(45, 45);
      labelsClass += ' lt100';
    } else if (result.count >= 100 && result.count < 500) {
      icon.scaledSize = new window.google.maps.Size(55, 55);
      labelsClass += ' lt500';
    } else if (result.count >= 500 && result.count < 1000) {
      icon.scaledSize = new window.google.maps.Size(60, 60);
      labelsClass += ' lt1000';
    } else if (result.count >= 1000 && result.count < 5000) {
      icon.scaledSize = new window.google.maps.Size(68, 68);
      labelsClass += ' lt5000';
    } else if (result.count >= 5000 && result.count < 10000) {
      icon.scaledSize = new window.google.maps.Size(72, 72);
      labelsClass += ' lt10000';
    } else {
      icon.scaledSize = new window.google.maps.Size(100, 100);
      labelsClass += ' gt10000';
    }

    let marker = new MarkerWithLabel({
      position: {
        lat: Number(result.latitude),
        lng: Number(result.longitude)
      },
      map: map,
      labelContent:
        '<span title="Total Chapters in ' + result['name'] + text + '">' + result.count + '</span>',
      icon: icon,
      labelAnchor: { x: 7, y: 28 },
      title: result['name'],
      labelClass: labelsClass
    });

    marker.addListener('click', () => {
      handleMarkerClick(result);
    });

    return marker;
  };

  function checkBounds(map) {
    let latNorth = map.getBounds().getNorthEast().lat();
    let latSouth = map.getBounds().getSouthWest().lat();
    let newLat;

    if (latNorth < 85 && latSouth > -85) /* in both side -> it's ok */ return;
    else {
      if (latNorth > 85 && latSouth < -85) /* out both side -> it's ok */ return;
      else {
        if (latNorth > 85)
          newLat = map.getCenter().lat() - (latNorth - 85); /* too north, centering */
        if (latSouth < -85)
          newLat = map.getCenter().lat() - (latSouth + 85); /* too south, centering */
      }
    }
    if (newLat) {
      let newCenter = new window.google.maps.LatLng(newLat, map.getCenter().lng());
      map.setCenter(newCenter);
    }
  }

  const handleApiLoaded = async (mapp, maps) => {
    DistanceWidget.prototype = new window.google.maps.MVCObject();
    RadiusWidget.prototype = new window.google.maps.MVCObject();

    RadiusWidget.prototype.addSizer = function () {
      let sizer = new window.google.maps.Marker({
        icon: {
          url: require('../../../../assets/images/resizer.png'),
          scaledSize: new window.google.maps.Size(24, 24),
          anchor: new window.google.maps.Point(13, 13)
        },
        title: 'Drag me!',
        draggable: true,
        cursor: 'e-resize',
        raiseOnDrag: false
      });

      sizer.bindTo('map', this);
      sizer.bindTo('position', this, 'sizer_position');
      let me = this;
      window.google.maps.event.addListener(sizer, 'drag', function () {
        me.setDistance();
      });
    };

    /**
     * Update the center of the circle and position the sizer back on the line.
     *
     * Position is bound to the DistanceWidget so this is expected to change when
     * the position of the distance widget is changed.
     */
    RadiusWidget.prototype.center_changed = function () {
      let bounds = this.get('bounds');
      // Bounds might not always be set so check that it exists first.
      if (bounds) {
        let lng = bounds.getNorthEast().lng();
        // Put the sizer at center, right on the circle.
        let position = new window.google.maps.LatLng(this.get('center').lat(), lng);
        this.set('sizer_position', position);
      }
    };

    /**
     * Calculates the distance between two latlng locations in km.
     * @see http://www.movable-type.co.uk/scripts/latlong.html
     *
     * @param {google.maps.LatLng} p1 The first lat lng point.
     * @param {google.maps.LatLng} p2 The second lat lng point.
     * @return {number} The distance between the two points in km.
     * @private
     */
    RadiusWidget.prototype.distanceBetweenPoints_ = function (p1, p2) {
      if (!p1 || !p2) {
        return 0;
      }
      let R = 6371; // Radius of the Earth in km
      let dLat = ((p2.lat() - p1.lat()) * Math.PI) / 180;
      let dLon = ((p2.lng() - p1.lng()) * Math.PI) / 180;
      let a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos((p1.lat() * Math.PI) / 180) *
          Math.cos((p2.lat() * Math.PI) / 180) *
          Math.sin(dLon / 2) *
          Math.sin(dLon / 2);
      let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      let d = R * c;
      return d;
    };

    /**
     * Set the distance of the circle based on the position of the sizer.
     */
    RadiusWidget.prototype.setDistance = function () {
      // As the sizer is being dragged, its position changes.  Because the
      // RadiusWidget's sizer_position is bound to the sizer's position, it will
      // change as well.
      let pos = this.get('sizer_position');
      let center = this.get('center');
      let distance = this.distanceBetweenPoints_(center, pos);
      if (distance >= 20) {
        // Set the distance property for any objects that are bound to it
        this.set('distance', distance);
      } else {
        // Set the distance property for any objects that are bound to it
        this.set('distance', 20);
        let bounds = this.get('bounds');
        let lng = bounds.getNorthEast().lng();

        // Put the sizer at center, right on the circle.
        let position = new window.google.maps.LatLng(this.get('center').lat(), lng);
        this.set('sizer_position', position);
      }
    };

    /**
     * Update the radius when the distance has changed.
     */
    RadiusWidget.prototype.distance_changed = function () {
      if (this.get('distance') >= 20) {
        this.set('radius', this.get('distance') * 1000);
      } else {
        this.set('radius', 20000);
      }
    };
    // eslint-disable-next-line no-console
    map = mapp; // asign to global map let
    setMapState(map);
    setMapsState(maps);

    map.addListener('center_changed', function () {
      checkBounds(map);
    });
    map.addListener('idle', function () {
      // on first load
    });
    map.addListener('zoom_changed', () => {
      // get data based on zoom level
      setIsZooming(true);

      onZoomLevelChanged(map.getZoom());
    });

    map.addListener('click', (e) => {
      // setActiveSection([]); // just set to blank to hide the dropdown
    });
  };

  return (
    <>
      {isPosition && (
        <GoogleMapReact
          bootstrapURLKeys={{
            key: process.env.REACT_APP_GOOGLE_MAP_KEY,
            language: 'en',
            region: 'US',
            libraries: ['places', 'geometry', 'drawing', 'visualization']
          }}
          center={{ lat: currentPosition.lat, lng: currentPosition.lng }}
          defaultZoom={11}
          zoom={4}
          style={{ position: 'static' }}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={async ({ map, maps }) => {
            // intialize global map let
            handleApiLoaded(map, maps);
          }}
          options={mapOptions}
        />
      )}
    </>
  );
};
