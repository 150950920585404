import { useEffect, useState } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import {
  getCTCDropDown,
  getCTCDropDownWithZero,
  getWorkDropDown,
  getWorkDropDownWithOutZero
} from '../../../globals/helper';
import * as APISERVICE from '../../../services/JobServices';
import * as APISERVICES from '../../../services/UserServices';

function JobFilterSalary(props) {
  const {
    setSkillsFilterId,
    setCompanyFilterId,
    job_type,
    setIndustryFilterId,
    setLocationFilterId,
    setMinCtc,
    setMaxCtc,
    setMinExp,
    minExp,
    setMaxExp,
    resetCall,
    comp_id,
    maxExp,
    minCtc,
    maxCtc,
    techSkill,
    setTechSkill,
    setTechSearch,
    loadTechOptions,
    inputMsg,
    setInputMsg,
    loadInpMsg,
    locationFilterId
  } = props;

  const colourStyles = {
    option: (styles, { isFocused }) => {
      // const color = chroma(data.color);
      return {
        ...styles,
        backgroundColor: isFocused ? '#eaeaea' : null,
        color: '#212529'
      };
    },

    control: (base) => ({
      ...base,
      border: '0 !important',
      // This line disable the  border
      boxShadow: '0 !important',
      '&:hover': {
        border: '0 !important'
      }
    })
  };
  const [inputMessage, setInputMessage] = useState('Please enter atleast 1 character');
  const [loading, setLoading] = useState(false);
  const [loadInd, setLoadInd] = useState(false);
  const [loadLoc, setLoadLoc] = useState(false);
  const [location, setLocation] = useState([]);
  const [selectedCompanyOption, setSelectedCompanyOption] = useState([]);
  const [selectedIndustryOption, setSelectedIndustryOption] = useState([]);
  const { id } = useParams();

  const isloggedIn = useSelector((state) => state?.user?.isloggedIn);

  useEffect(() => {
    if (id) {
      getSingleJobIndustry(id);
    }
  }, [id]);

  const getSingleJobIndustry = (id) => {
    APISERVICE.jobIndustriesDetail(id).then((res) => {
      if (res?.status === 200) {
        const data = res?.data?.data;
        setSelectedIndustryOption({ label: data?.name, value: data?.name, id: data?.id });
      }
    });
  };

  const onChangeHandler = (e, option) => {
    let arr = [];

    if (option.name === 'skills') {
      e?.map((data) => {
        arr.push(data?.value);
      });
      setSkillsFilterId(arr.join());
    } else if (option.name === 'company') {
      e?.map((data) => {
        arr.push(data?.id);
      });

      setCompanyFilterId(arr.join());
    } else if (option.name === 'industry') {
      e?.map((data) => {
        arr.push(data?.id);
      });

      setIndustryFilterId(arr.join());
    } else if (option.name === 'location') {
      e?.map((data) => arr.push(data?.id));
      setLocation(e);
      setLocationFilterId(arr.join());
    } else if (option.name === 'city') {
      e?.map((data) => arr.push(data?.id));
      setLocationFilterId(arr.join());
    }
  };

  const resetInitial = () => {
    setTechSkill([]);
    setLocation([]);
    setSkillsFilterId('');
    setCompanyFilterId('');
    setIndustryFilterId('');
    setLocationFilterId('');
    setSelectedCompanyOption('');
    setSelectedIndustryOption('');
    setMinCtc('');
    setMaxCtc('');
    setMinExp('');
    setMaxExp('');
    resetCall('', true);
  };

  //check

  const PackageOptions = getCTCDropDownWithZero().map((item) => {
    return {
      id: item,
      label: item + ' ' + 'Lacs',
      value: item + ' ' + 'Lacs'
    };
  });
  const PackageOptionWithOne = getCTCDropDown().map((item) => {
    return {
      id: item,
      label: item + ' ' + 'Lacs',
      value: item + ' ' + 'Lacs'
    };
  });
  const PackageOptions1 = [
    ...[{ label: 'Select', value: 'Select' }],
    ...PackageOptionWithOne,
    ...[{ id: 51, label: 50 + '+' + ' ' + 'Lacks', value: 51 + '' + 'Lacks' }]
  ];

  const WorkOptions = getWorkDropDown().map((item) => {
    return {
      label: item,
      value: item
    };
  });
  const WorkOptionFormOne = getWorkDropDownWithOutZero().map((item) => {
    return {
      label: item,
      value: item
    };
  });
  const WorkOptions1 = [
    ...[{ label: 'Select', value: 'Select' }],
    ...WorkOptionFormOne,
    ...[{ label: 10 + '+', value: 11 }]
  ];

  // load options using API call
  const loadCompanyOption = (inputValue = '') => {
    if (inputValue?.length == 1 || inputValue?.length > 1) {
      setLoading(true);
      return APISERVICES.companyDropdown(inputValue, job_type, comp_id).then((res) => {
        setInputMessage('searching...');
        setLoading(false);
        if (res?.status === 200) {
          let tempArr = [];
          if (res?.data?.results) {
            const data = res?.data?.results;
            data?.map((data) => {
              tempArr.push({
                id: data?.id,
                label: data?.name,
                value: data?.name
              });
            });
          }
          if (res?.data?.results == '') {
            setInputMessage('no matches found ..');
          }
          return tempArr;
        }
      });
    }
  };

  // load options using API call
  const loadIndustryOption = (inputValue = '') => {
    if (inputValue?.length >= 1) {
      setLoadInd(true);
      return APISERVICES.industriesDropdown(inputValue, job_type, comp_id).then((res) => {
        setInputMessage('searching...');
        setLoadInd(false);

        if (res?.status === 200) {
          let tempArr = [];
          if (res?.data?.results) {
            const data = res?.data?.results;
            data?.map((data) => {
              tempArr.push({
                id: data?.id,
                label: data?.name,
                value: data?.name
              });
            });
          }
          if (res?.data?.results?.length == 0) {
            setInputMessage('no matches found ..');
          }
          return tempArr;
        }
      });
    }
  };

  //load options using API call
  const loadGetLocationTags = async (inputValue) => {
    if (inputValue?.length == 1 || inputValue?.length > 1) {
      setLoadLoc(true);
      return APISERVICES.getLocationTags(inputValue).then((res) => {
        setLoadLoc(false);

        if (res?.status === 200) {
          let tempArr = [];
          if (res?.data?.results) {
            const data = res?.data?.results;
            data?.map((data) => {
              tempArr.push({
                id: data?.id,
                label: data?.name,
                value: data?.name
              });
            });
          }
          if (res?.data?.results == '') {
            setInputMessage('no matches found ..');
          }
          return tempArr;
        }
      });
    }
  };

  const inputMessageHandler = (e) => {
    if (e?.length !== 1 && e?.length < 1) {
      setInputMessage('Please enter atleast 1 character');
      setInputMsg('Please enter atleast 1 character');
    }
  };
  return (
    <>
      <div className="fiter-card mb-4 mb-lg-0" id="filter-slidebar">
        <div className="filter-hd-card d-flex align-items-center justify-content-between">
          <div className="filter-hd">
            <h4>Filter By</h4>
          </div>
          <div className="sider-bar-filter p-3 text-end">
            <button
              className=" dark-blu-btn mx-2"
              type="button"
              onClick={() => {
                resetCall();
              }}>
              Search
            </button>
            {isloggedIn && (
              <button
                type="button"
                className="accnt-btn"
                onClick={() => {
                  resetInitial();
                }}>
                Reset
              </button>
            )}
          </div>
        </div>
        <div className="filter-select-lst">
          <Accordion defaultActiveKey={['0']} alwaysOpen flush>
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                Skills{' '}
                <span style={{ fontSize: '10px', marginLeft: '5px', fontWeight: 'lighter' }}>
                  (e.g. Php...)
                </span>
              </Accordion.Header>
              <Accordion.Body className="d-flex align-items-center justify-content-between">
                <div style={{ flex: 6 }}>
                  <AsyncSelect
                    isMulti
                    cacheOptions
                    // defaultOptions
                    value={techSkill}
                    getOptionLabel={(e) => e.label}
                    getOptionValue={(e) => e.value}
                    loadOptions={loadTechOptions}
                    className="basic-single mt-1"
                    onChange={setTechSkill}
                    components={{
                      DropdownIndicator: () => null,
                      IndicatorSeparator: () => null,
                      LoadingIndicator: () => null
                    }}
                    // menuIsOpen={techSearch !== '' ? true : false}
                    noOptionsMessage={() => inputMsg}
                    onInputChange={(newValue) => {
                      setTechSearch(newValue);
                      newValue?.length !== 2 && newValue?.length < 2
                        ? setInputMsg('Please enter atleast 2 characters.')
                        : '';
                    }}
                    styles={{
                      control: (base) => ({
                        ...base,
                        border: '0 !important',
                        // This line disable the  border
                        boxShadow: '0 !important',
                        '&:hover': {
                          border: '0 !important'
                        }
                      })
                    }}
                  />
                </div>
                {loadInpMsg && (
                  <div style={{ flex: 1 }}>
                    <i className="fa fa-spinner fa-pulse me-2"></i>
                  </div>
                )}
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
          <Accordion defaultActiveKey={['1', '2', '3', '4', '5']} alwaysOpen={true} flush>
            <Accordion.Item eventKey="1">
              <Accordion.Header>
                Company{' '}
                <span style={{ fontSize: '10px', marginLeft: '5px', fontWeight: 'lighter' }}>
                  (e.g. Dell, Infosys etc...)
                </span>
              </Accordion.Header>
              <Accordion.Body className="spinner-wrapper">
                <AsyncSelect
                  isMulti
                  className="basic-single mt-1"
                  classNamePrefix="select"
                  isDisabled={false}
                  noOptionsMessage={() => inputMessage}
                  // isLoading={false}
                  isClearable={false}
                  isRtl={false}
                  isSearchable={true}
                  loadOptions={loadCompanyOption}
                  value={selectedCompanyOption}
                  name="company"
                  onInputChange={(e) => inputMessageHandler(e)}
                  onChange={(e, option) => {
                    onChangeHandler(e, option);
                    setSelectedCompanyOption(e);
                  }}
                  components={{
                    DropdownIndicator: () => null,
                    IndicatorSeparator: () => null,
                    LoadingIndicator: () => null
                  }}
                  styles={colourStyles}
                  placeholder="Search.. e.g Dell, Infosys etc"
                />
                {loading && (
                  <div className="spinner-filter">
                    <i className="fa fa-spinner fa-pulse me-2 "></i>
                  </div>
                )}
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
              <Accordion.Header>
                Industry{' '}
                <span style={{ fontSize: '10px', marginLeft: '5px', fontWeight: 'lighter' }}>
                  {' '}
                  (e.g. IT, Communication etc...)
                </span>
              </Accordion.Header>
              <Accordion.Body className="spinner-wrapper">
                {/* <div style={{ flex: 6 }}> */}
                <AsyncSelect
                  isMulti
                  className="basic-single mt-1"
                  classNamePrefix="select"
                  noOptionsMessage={() => inputMessage}
                  isDisabled={false}
                  isLoading={false}
                  isClearable={false}
                  isRtl={false}
                  isSearchable={true}
                  onInputChange={(e) => inputMessageHandler(e)}
                  loadOptions={loadIndustryOption}
                  styles={colourStyles}
                  value={selectedIndustryOption}
                  name="industry"
                  onChange={(e, option) => {
                    onChangeHandler(e, option);
                    setSelectedIndustryOption(e);
                  }}
                  placeholder="Search.. e.g IT, Communication"
                  components={{
                    DropdownIndicator: () => null,
                    IndicatorSeparator: () => null,
                    LoadingIndicator: () => null
                  }}
                />
                {/* </div> */}
                {loadInd && (
                  <div className="spinner-filter">
                    <i className="fa fa-spinner fa-pulse me-2"></i>
                  </div>
                )}
              </Accordion.Body>
            </Accordion.Item>
            {/* location */}
            <Accordion.Item eventKey="3">
              <Accordion.Header>
                Location{' '}
                <span style={{ fontSize: '10px', marginLeft: '5px', fontWeight: 'lighter' }}>
                  (e.g Delhi, Pune etc...)
                </span>
              </Accordion.Header>
              <Accordion.Body className="spinner-wrapper">
                <AsyncSelect
                  cacheOptions
                  isMulti
                  defaultOptions
                  value={location}
                  placeholder="Search..e.g Delhi, Pune etc..."
                  getOptionLabel={(e) => e.label}
                  getOptionValue={(e) => e.value}
                  loadOptions={loadGetLocationTags}
                  className="basic-single mt-1 w-auto"
                  name="location"
                  components={{
                    DropdownIndicator: () => null,
                    IndicatorSeparator: () => null,
                    LoadingIndicator: () => null
                  }}
                  onChange={(e, option) => {
                    onChangeHandler(e, option);
                  }}
                  onInputChange={(e) => {
                    inputMessageHandler(e);
                  }}
                  noOptionsMessage={() => inputMessage}
                  styles={{
                    control: (base) => ({
                      ...base,
                      border: '0 !important',
                      // This line disable the  border
                      boxShadow: '0 !important',
                      '&:hover': {
                        border: '0 !important'
                      }
                    })
                  }}
                />
                {loadLoc && (
                  <div className="spinner-filter">
                    <i className="fa fa-spinner fa-pulse me-2"></i>
                  </div>
                )}
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="4">
              <Accordion.Header>
                Work Experience{' '}
                <span style={{ fontSize: '10px', marginLeft: '5px', fontWeight: 'lighter' }}>
                  (In Years)
                </span>
              </Accordion.Header>
              <Accordion.Body>
                <Row className="d-flex align-items-center">
                  <Col md={5}>
                    <Select
                      name="colors"
                      className="basic-multi-select"
                      classNamePrefix="select"
                      options={WorkOptions}
                      styles={colourStyles}
                      placeholder="Select"
                      value={minExp}
                      onChange={(e) => {
                        setMinExp(e);
                      }}
                    />
                  </Col>
                  <Col md={2}>to</Col>
                  <Col md={5}>
                    <Select
                      name="colors"
                      className="basic-multi-select"
                      classNamePrefix="select"
                      options={WorkOptions1}
                      styles={colourStyles}
                      value={maxExp}
                      placeholder="Select"
                      onChange={(e) => {
                        setMaxExp(e);
                      }}
                    />
                  </Col>
                </Row>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="5">
              <Accordion.Header>
                Expected Salary{' '}
                <span style={{ fontSize: '10px', marginLeft: '5px', fontWeight: 'lighter' }}>
                  (In Lacs/Annum)
                </span>
              </Accordion.Header>
              <Accordion.Body>
                <Row className="d-flex align-items-center">
                  <Col md={5}>
                    <Select
                      name="colors"
                      className="basic-multi-select"
                      classNamePrefix="select"
                      options={PackageOptions}
                      styles={colourStyles}
                      placeholder="Select"
                      value={minCtc}
                      onChange={(e) => {
                        setMinCtc(e);
                      }}
                    />
                  </Col>
                  <Col md={2}>to</Col>
                  <Col md={5}>
                    <Select
                      name="colors"
                      className="basic-multi-select"
                      classNamePrefix="select"
                      options={PackageOptions1}
                      styles={colourStyles}
                      placeholder="Select"
                      value={maxCtc}
                      onChange={(e) => {
                        setMaxCtc(e);
                      }}
                    />
                  </Col>
                </Row>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
          <div className="sider-bar-filter p-3 text-end">
            <button
              className="accnt-btn me-2"
              type="button"
              onClick={() => {
                resetCall();
              }}>
              Search
            </button>
            {isloggedIn && (
              <button
                className="accnt-btn"
                onClick={() => {
                  resetInitial();
                }}>
                Reset
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default JobFilterSalary;
