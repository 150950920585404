import { Col, Container, Row } from 'react-bootstrap';
import HeaderLogin from '../../commoncomponents/HeaderLogin';
import TopTabbings from './TopTabbings';
import { Link } from 'react-router-dom';
import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';
import ProgressBar from 'react-bootstrap/ProgressBar';
import Dropdown from 'react-bootstrap/Dropdown';
import Form from 'react-bootstrap/Form';

import WhatsNew from '../../commoncomponents/newsfeeds/WhatsNew';
import Upcoming from '../../commoncomponents/newsfeeds/Upcoming';
import PeopleKnow from '../../commoncomponents/newsfeeds/PeopleKnow';
import FollowCompany from '../../commoncomponents/newsfeeds/FollowCompany';
import WorkAnniversary from '../../commoncomponents/newsfeeds/WorkAnniversary';
import CampusNews from '../../commoncomponents/newsfeeds/CampusNews';
import CampusnewsText from '../../commoncomponents/newsfeeds/CampusnewsText';
import ArticalBlogs from '../../commoncomponents/newsfeeds/ArticalBlogs';
import GroupDiscover from '../../commoncomponents/newsfeeds/GroupDiscover';
import { Button } from 'react-bootstrap';
import { MiniFooter } from '../../MiniFooter';

function UserTimeline() {
  return (
    <>
      <HeaderLogin />
      <div className="header-lnk-feeds">
        <div className="container">
          <TopTabbings state={'social'} />
        </div>
      </div>
      <section className="birhtday-prof-dis">
        <Container>
          <div className="birhday-cover-sec">
            <img
              className="cover-img"
              src={require('../../../assets/images/default-cover.png')}
              alt="img"
            />
            <div className="birthday-profile">
              <div className="birth-pro-icn">
                <img src={require('../../../assets/images/birthday-profile-1.jpg')} alt="img" />
                <div className="birth-icn-upload-img">
                  <Form.Control type="file" />
                  <div className="upload-hd-img">
                    <i className="fas fa-camera me-2"></i>
                    Update Profile Picture
                  </div>
                </div>
              </div>
              <div className="birth-pro-hd">
                <h4>Paarth sood</h4>
                <p>
                  <span>Batch</span>2017
                </p>
                <p>
                  <span>Title</span>Business Head
                </p>
                <p></p>
                <span className="online"></span>
              </div>
            </div>
            <div className="upload-cover-btns">
              <Link to="#" className="change-cover cover-upload-btn me-2">
                <i className="fas fa-camera me-2"></i>
                Change Cover
              </Link>
              <Link to="#" className="change-cover">
                <i className="fas fa-arrows-alt me-2"></i>
                Reposition-cover
              </Link>
            </div>
          </div>
          <div className="birth-main-tabs">
            <Tab.Container id="left-tabs-example" defaultActiveKey="first">
              <div className="birthday-tabs-hd d-flex align-items-center justify-content-between">
                <div className="birthday-lft-tabs">
                  <Nav variant="pills">
                    <Nav.Item>
                      <Nav.Link eventKey="first">Timeline</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="second">About</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="third">Friends</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="fourth">Photos</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="five">Mentor Profile</Nav.Link>
                    </Nav.Item>
                  </Nav>
                </div>
                <div className="birthday-rt-cntrl">
                  <Button>Unfriend</Button>
                  {}
                  <Button>
                    <i className="fas fa-plus me-2"></i>
                    Follow Mentor
                  </Button>
                </div>
              </div>
              <Row>
                <Col lg={9} md={12} xs={12}>
                  <div className="birthday-tab-cnt">
                    <Tab.Content>
                      <Tab.Pane eventKey="first">
                        <Row>
                          <Col lg={5} md={12}>
                            <div className="birth-lft-timeline mb-3">
                              <div className="info-wrap">
                                <div className="info-icn">
                                  <img
                                    src={require('../../../assets/images/userbasic-icons.png')}
                                    alt="img"
                                  />
                                </div>
                                <div className="info-dis-card">
                                  <h3>Basic Information</h3>
                                  <p>
                                    Became friend with<Link to="#">VAIBHAV KALA</Link>&{' '}
                                    <Link to="#">2 Others</Link>
                                  </p>
                                  <p>Born on Feb 10, 2009</p>
                                  <p>Your Friends Since November, 2015</p>
                                </div>
                              </div>
                              <div className="info-wrap">
                                <div className="info-icn">
                                  <img
                                    src={require('../../../assets/images/education-icons.png')}
                                    alt="img"
                                  />
                                </div>
                                <div className="info-dis-card">
                                  <h3>Education</h3>
                                  <h4 className="edu-yr">2017</h4>
                                  <p>
                                    Studied at{' '}
                                    <Link to="#">
                                      {' '}
                                      University of Petroleum and Energy Studies (UPES)
                                    </Link>
                                  </p>
                                  <h4 className="edu-yr">2010</h4>
                                  <p>
                                    Studied at <Link to="#"> Guru Nanak College, Budhlada</Link>
                                  </p>
                                  <h4 className="edu-yr">2006</h4>
                                  <p>
                                    Studied at <Link to="#"> Kurukshetra University</Link>
                                  </p>
                                  <h4 className="edu-yr">2005</h4>
                                  <p>
                                    Studied at{' '}
                                    <Link to="#">
                                      {' '}
                                      Ludhiana College of Engineering and Technology (LCET),
                                      Ludhiana
                                    </Link>
                                  </p>
                                </div>
                              </div>
                              <div className="info-wrap">
                                <div className="info-icn">
                                  <img
                                    src={require('../../../assets/images/Professional-icons.png')}
                                    alt="img"
                                  />
                                </div>
                                <div className="info-dis-card">
                                  <h3>Professional</h3>
                                  <h4 className="edu-yr">2014 - Present</h4>
                                  <p>
                                    <Link to="#">Business Head</Link> at{' '}
                                    <Link to="#">AlmaBay Networks Pvt Ltd</Link>
                                  </p>
                                  <h4 className="edu-yr">2013 - Present</h4>
                                  <p>
                                    <Link to="#">Business Head</Link> at{' '}
                                    <Link to="#">CL educate Ltd</Link>
                                  </p>
                                  <h4 className="edu-yr">2012 - Present</h4>
                                  <p>
                                    <Link to="#">Wealth Manager</Link> at{' '}
                                    <Link to="#"> ICICI Bank</Link>
                                  </p>
                                  <h4 className="edu-yr">2010 - Present</h4>
                                  <p>
                                    <Link to="#">Marketing Manager</Link> at <Link to="#">LPU</Link>
                                  </p>
                                </div>
                              </div>
                              <div className="info-wrap">
                                <div className="info-icn">
                                  <img
                                    src={require('../../../assets/images/keyskill-icon.png')}
                                    alt="img"
                                  />
                                </div>
                                <div className="info-dis-card">
                                  <h3>Technical Skills</h3>
                                  <div className="inter-skill-progress mb-2">
                                    <p className="mb-1">Business Analyst</p>
                                    <ProgressBar>
                                      <ProgressBar striped variant="success" now={35} key={1} />
                                      <ProgressBar variant="warning" now={20} key={2} />
                                      <ProgressBar striped variant="danger" now={10} key={3} />
                                    </ProgressBar>
                                  </div>{' '}
                                  <div className="inter-skill-progress mb-2">
                                    <p className="mb-1">Business Education</p>
                                    <ProgressBar>
                                      <ProgressBar striped variant="success" now={35} key={1} />
                                      <ProgressBar variant="warning" now={20} key={2} />
                                      <ProgressBar striped variant="danger" now={10} key={3} />
                                    </ProgressBar>
                                  </div>{' '}
                                  <div className="inter-skill-progress mb-2">
                                    <p className="mb-1">Entrepreneurship</p>
                                    <ProgressBar>
                                      <ProgressBar striped variant="success" now={35} key={1} />
                                      <ProgressBar variant="warning" now={20} key={2} />
                                      <ProgressBar striped variant="danger" now={10} key={3} />
                                    </ProgressBar>
                                  </div>
                                  <div className="inter-skill-progress mb-2">
                                    <p className="mb-1">Leadership Skills</p>
                                    <ProgressBar>
                                      <ProgressBar striped variant="success" now={35} key={1} />
                                      <ProgressBar variant="warning" now={20} key={2} />
                                      <ProgressBar striped variant="danger" now={10} key={3} />
                                    </ProgressBar>
                                  </div>
                                </div>
                              </div>
                              <div className="info-wrap">
                                <div className="info-icn">
                                  <img
                                    src={require('../../../assets/images/keyskill-icon.png')}
                                    alt="img"
                                  />
                                </div>
                                <div className="info-dis-card">
                                  <h3>Business Skills</h3>
                                  <div className="inter-skill-progress mb-2">
                                    <p className="mb-1">Marketing</p>
                                    <ProgressBar>
                                      <ProgressBar striped variant="success" now={35} key={1} />
                                      <ProgressBar variant="warning" now={20} key={2} />
                                      <ProgressBar striped variant="danger" now={10} key={3} />
                                    </ProgressBar>
                                  </div>
                                </div>
                              </div>
                              <div className="info-wrap">
                                <div className="info-icn">
                                  <img
                                    src={require('../../../assets/images/keyskill-icon.png')}
                                    alt="img"
                                  />
                                </div>
                                <div className="info-dis-card">
                                  <h3>Inter-Personal Skills</h3>
                                  <div className="inter-skill-progress">
                                    <p className="mb-1">Business Management</p>
                                    <ProgressBar>
                                      <ProgressBar striped variant="success" now={35} key={1} />
                                      <ProgressBar variant="warning" now={20} key={2} />
                                      <ProgressBar striped variant="danger" now={10} key={3} />
                                    </ProgressBar>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="time-line-friends mb-3">
                              <div className="d-flex align-item-center justify-content-between">
                                <h4>
                                  <Link to="#">FRIENDS 3</Link>&nbsp;(2 Mutual)
                                </h4>
                                <Link className="text-orange" to="#">
                                  More
                                </Link>
                              </div>

                              <div className="friends-list">
                                <Link to="#">
                                  <div className="friend-lst-icn">
                                    <img
                                      src={require('../../../assets/images/birth1.jpg')}
                                      alt="img"
                                    />
                                  </div>
                                  <p>Alma Bay</p>
                                </Link>
                                <Link to="#">
                                  <div className="friend-lst-icn">
                                    <img
                                      src={require('../../../assets/images/birth2.jpg')}
                                      alt="img"
                                    />
                                  </div>
                                  <p>Alma Bay</p>
                                </Link>
                                <Link to="#">
                                  <div className="friend-lst-icn">
                                    <img
                                      src={require('../../../assets/images/birth3.jpg')}
                                      alt="img"
                                    />
                                  </div>
                                  <p>Alma Bay</p>
                                </Link>
                                <Link to="#">
                                  <div className="friend-lst-icn">
                                    <img
                                      src={require('../../../assets/images/birth4.jpg')}
                                      alt="img"
                                    />
                                  </div>
                                  <p>Alma Bay</p>
                                </Link>
                                <Link to="#">
                                  <div className="friend-lst-icn">
                                    <img
                                      src={require('../../../assets/images/birth6.jpg')}
                                      alt="img"
                                    />
                                  </div>
                                  <p>Alma Bay</p>
                                </Link>
                                <Link to="#">
                                  <div className="friend-lst-icn">
                                    <img
                                      src={require('../../../assets/images/birth7.jpg')}
                                      alt="img"
                                    />
                                  </div>
                                  <p>Alma Bay</p>
                                </Link>
                              </div>
                            </div>

                            <div className="time-line-friends">
                              <div className="d-flex align-item-center justify-content-between">
                                <h4>
                                  <Link to="#">PHOTOS</Link>
                                </h4>
                                <Link className="text-orange" to="#">
                                  More
                                </Link>
                              </div>

                              <div className="friends-list">
                                <Link to="#">
                                  <div className="friend-lst-icn">
                                    <img
                                      src={require('../../../assets/images/birth1.jpg')}
                                      alt="img"
                                    />
                                  </div>
                                </Link>
                                <Link to="#">
                                  <div className="friend-lst-icn">
                                    <img
                                      src={require('../../../assets/images/birth2.jpg')}
                                      alt="img"
                                    />
                                  </div>
                                </Link>
                                <Link to="#">
                                  <div className="friend-lst-icn">
                                    <img
                                      src={require('../../../assets/images/birth3.jpg')}
                                      alt="img"
                                    />
                                  </div>
                                </Link>
                                <Link to="#">
                                  <div className="friend-lst-icn">
                                    <img
                                      src={require('../../../assets/images/birth4.jpg')}
                                      alt="img"
                                    />
                                  </div>
                                </Link>
                                <Link to="#">
                                  <div className="friend-lst-icn">
                                    <img
                                      src={require('../../../assets/images/birth6.jpg')}
                                      alt="img"
                                    />
                                  </div>
                                </Link>
                                <Link to="#">
                                  <div className="friend-lst-icn">
                                    <img
                                      src={require('../../../assets/images/birth7.jpg')}
                                      alt="img"
                                    />
                                  </div>
                                </Link>
                              </div>
                            </div>
                          </Col>
                          <Col md={12} lg={7}>
                            <div className="birth-nws-feed">
                              <Tab.Container id="left-tabs-example" defaultActiveKey="social">
                                <div className="birth-feed-tabs">
                                  <Nav variant="pills" className="flex-row">
                                    <Nav.Item>
                                      <Nav.Link eventKey="social">Social</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                      <Nav.Link eventKey="professional">Professional</Nav.Link>
                                    </Nav.Item>
                                  </Nav>
                                </div>
                                <div className="birth-feed-cntent">
                                  <Tab.Content>
                                    <Tab.Pane eventKey="social"></Tab.Pane>
                                    <Tab.Pane eventKey="professional"></Tab.Pane>
                                  </Tab.Content>
                                </div>
                              </Tab.Container>
                            </div>
                          </Col>
                        </Row>
                      </Tab.Pane>
                      <Tab.Pane eventKey="second">
                        <div className="about-cntnt ">
                          <div className="bg-white mb-3">
                            <Tab.Container id="left-tabs-example" defaultActiveKey="about-1">
                              <Row>
                                <Col sm={3}>
                                  <Nav variant="pills" className="flex-column abt-lft-tabs">
                                    <Nav.Item>
                                      <Nav.Link eventKey="about-1">Basic Information</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                      <Nav.Link eventKey="about-2">Education</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                      <Nav.Link eventKey="about-3">Professional</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                      <Nav.Link eventKey="about-4">Technical Skills</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                      <Nav.Link eventKey="about-5">Business Skills</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                      <Nav.Link eventKey="about-6">Inter-Personal Skills</Nav.Link>
                                    </Nav.Item>
                                  </Nav>
                                </Col>
                                <Col sm={9}>
                                  <Tab.Content>
                                    <Tab.Pane eventKey="about-1">
                                      <div className="abt-rt-dis">
                                        <h4 className="basic-hd">BASIC INFORMATION</h4>
                                        <ul className="basic-usr-detail">
                                          <li>
                                            <i className="fas fa-venus-mars me-2"></i>
                                            Male
                                          </li>
                                          <li>
                                            <i className="far fa-heart me-2"></i>
                                            Single
                                          </li>
                                          <li>
                                            <i className="fas fa-building me-2"></i>
                                            Panchkula Haryana, India
                                          </li>
                                          <li>
                                            <i className="fas fa-envelope-open-text me-2"></i>
                                            paarth0209@gmail.com
                                          </li>
                                          <li>
                                            <i className="fas fa-birthday-cake me-2"></i>
                                            February 10, 2009
                                          </li>
                                        </ul>
                                      </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="about-2">
                                      <div className="abt-rt-dis">
                                        <h4 className="basic-hd">EDUCATION</h4>
                                        <div className="edu-collz-card d-flex align-items-center mb-4">
                                          <div className="edu-card-icn me-3">
                                            <img
                                              src={require('../../../assets/images/default-male-avatar.png')}
                                              alt="img"
                                            />
                                          </div>
                                          <div className="edu-collz-dis">
                                            <h4>DAV College, Chandigarh</h4>
                                            <p>Chandigarh, Chandigarh, India</p>
                                          </div>
                                        </div>
                                        <div className="edu-collz-card d-flex align-items-center mb-4">
                                          <div className="edu-card-icn me-3">
                                            <img
                                              src={require('../../../assets/images/default-male-avatar.png')}
                                              alt="img"
                                            />
                                          </div>
                                          <div className="edu-collz-dis">
                                            <h4>DAV College, Chandigarh</h4>
                                            <p>Chandigarh, Chandigarh, India</p>
                                          </div>
                                        </div>
                                        <div className="edu-collz-card d-flex align-items-center mb-4">
                                          <div className="edu-card-icn me-3">
                                            <img
                                              src={require('../../../assets/images/default-male-avatar.png')}
                                              alt="img"
                                            />
                                          </div>
                                          <div className="edu-collz-dis">
                                            <h4>DAV College, Chandigarh</h4>
                                            <p>Chandigarh, Chandigarh, India</p>
                                          </div>
                                        </div>
                                      </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="about-3">
                                      <div className="abt-rt-dis">
                                        <h4 className="basic-hd">PROFESSIONAL</h4>
                                        <div className="edu-collz-card d-flex align-items-center mb-4">
                                          <div className="edu-card-icn me-3">
                                            <img
                                              src={require('../../../assets/images/default-male-avatar.png')}
                                              alt="img"
                                            />
                                          </div>
                                          <div className="edu-collz-dis">
                                            <h4>Phi InfoSoft Pvt. Ltd.</h4>
                                            <p>
                                              Worked as PHP DEVELOPER From November, 2014 To June,
                                              2015
                                            </p>
                                          </div>
                                        </div>
                                        <div className="edu-collz-card d-flex align-items-center mb-4">
                                          <div className="edu-card-icn me-3">
                                            <img
                                              src={require('../../../assets/images/default-male-avatar.png')}
                                              alt="img"
                                            />
                                          </div>
                                          <div className="edu-collz-dis">
                                            <h4>Phi InfoSoft Pvt. Ltd.</h4>
                                            <p>
                                              Worked as PHP DEVELOPER From November, 2014 To June,
                                              2015
                                            </p>
                                          </div>
                                        </div>
                                        <div className="edu-collz-card d-flex align-items-center mb-4">
                                          <div className="edu-card-icn me-3">
                                            <img
                                              src={require('../../../assets/images/default-male-avatar.png')}
                                              alt="img"
                                            />
                                          </div>
                                          <div className="edu-collz-dis">
                                            <h4>Phi InfoSoft Pvt. Ltd.</h4>
                                            <p>
                                              Worked as PHP DEVELOPER From November, 2014 To June,
                                              2015
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="about-4">
                                      <div className="abt-rt-dis">
                                        <h4 className="basic-hd">TECHNICAL SKILLS</h4>
                                        <div className="skill-card mb-2">
                                          <h5>IT Administrative</h5>
                                          <ProgressBar>
                                            <ProgressBar
                                              striped
                                              variant="success"
                                              now={35}
                                              key={1}
                                            />
                                            <ProgressBar variant="warning" now={20} key={2} />
                                            <ProgressBar
                                              striped
                                              variant="danger"
                                              now={10}
                                              key={3}
                                            />
                                          </ProgressBar>
                                        </div>
                                      </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="about-5">
                                      <div className="abt-rt-dis">
                                        <h4 className="basic-hd">BUSINESS SKILLS</h4>
                                      </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="about-6">
                                      <div className="abt-rt-dis">
                                        <h4 className="basic-hd">INTER-PERSONAL SKILLS</h4>
                                      </div>
                                    </Tab.Pane>
                                  </Tab.Content>
                                </Col>
                              </Row>
                            </Tab.Container>
                          </div>

                          <div className="time-line-friends mb-3">
                            <div className="d-flex align-item-center justify-content-between">
                              <div className="frien-btn-hd">
                                <h4>
                                  <Link to="#">FRIENDS 3</Link>&nbsp;(2 Mutual)
                                </h4>
                                <Link className="ms-2" to="/">
                                  MENTOR
                                </Link>
                                <Link className="ms-2" to="/">
                                  MENTEES
                                </Link>
                              </div>
                              <Link className="text-orange" to="#">
                                More
                              </Link>
                            </div>
                            <div className="friends-list">
                              <Link to="#">
                                <div className="friend-lst-icn">
                                  <img
                                    src={require('../../../assets/images/birth1.jpg')}
                                    alt="img"
                                  />
                                </div>
                                <p>Alma Bay</p>
                              </Link>
                              <Link to="#">
                                <div className="friend-lst-icn">
                                  <img
                                    src={require('../../../assets/images/birth2.jpg')}
                                    alt="img"
                                  />
                                </div>
                                <p>Alma Bay</p>
                              </Link>
                              <Link to="#">
                                <div className="friend-lst-icn">
                                  <img
                                    src={require('../../../assets/images/birth3.jpg')}
                                    alt="img"
                                  />
                                </div>
                                <p>Alma Bay</p>
                              </Link>
                              <Link to="#">
                                <div className="friend-lst-icn">
                                  <img
                                    src={require('../../../assets/images/birth4.jpg')}
                                    alt="img"
                                  />
                                </div>
                                <p>Alma Bay</p>
                              </Link>
                              <Link to="#">
                                <div className="friend-lst-icn">
                                  <img
                                    src={require('../../../assets/images/birth6.jpg')}
                                    alt="img"
                                  />
                                </div>
                                <p>Alma Bay</p>
                              </Link>
                              <Link to="#">
                                <div className="friend-lst-icn">
                                  <img
                                    src={require('../../../assets/images/birth7.jpg')}
                                    alt="img"
                                  />
                                </div>
                                <p>Alma Bay</p>
                              </Link>
                            </div>
                          </div>

                          <div className="time-line-friends  mt-3">
                            <div className="d-flex align-item-center justify-content-between">
                              <h4>
                                <Link to="#">PHOTOS</Link>
                              </h4>
                              <Link className="text-orange" to="#">
                                More
                              </Link>
                            </div>

                            <div className="friends-list">
                              <Link to="#">
                                <div className="friend-lst-icn">
                                  <img
                                    src={require('../../../assets/images/birth1.jpg')}
                                    alt="img"
                                  />
                                </div>
                              </Link>
                              <Link to="#">
                                <div className="friend-lst-icn">
                                  <img
                                    src={require('../../../assets/images/birth2.jpg')}
                                    alt="img"
                                  />
                                </div>
                              </Link>
                              <Link to="#">
                                <div className="friend-lst-icn">
                                  <img
                                    src={require('../../../assets/images/birth3.jpg')}
                                    alt="img"
                                  />
                                </div>
                              </Link>
                              <Link to="#">
                                <div className="friend-lst-icn">
                                  <img
                                    src={require('../../../assets/images/birth4.jpg')}
                                    alt="img"
                                  />
                                </div>
                              </Link>
                              <Link to="#">
                                <div className="friend-lst-icn">
                                  <img
                                    src={require('../../../assets/images/birth6.jpg')}
                                    alt="img"
                                  />
                                </div>
                              </Link>
                              <Link to="#">
                                <div className="friend-lst-icn">
                                  <img
                                    src={require('../../../assets/images/birth7.jpg')}
                                    alt="img"
                                  />
                                </div>
                              </Link>
                            </div>
                          </div>
                        </div>
                      </Tab.Pane>
                      <Tab.Pane eventKey="third">
                        <div className="friend-card-sec bg-white">
                          <Tab.Container id="left-tabs-example" defaultActiveKey="friend1">
                            <Nav variant="pills" className="fried-card-tab mb-3">
                              <Nav.Item>
                                <Nav.Link eventKey="friend1">Friends</Nav.Link>
                              </Nav.Item>
                              <Nav.Item>
                                <Nav.Link eventKey="friend2">Mutual Friends</Nav.Link>
                              </Nav.Item>
                            </Nav>
                            <Tab.Content>
                              <Tab.Pane eventKey="friend1">
                                <div className="frirnd-hd d-flex align-items-center justify-content-between p-2">
                                  <h4>Friends</h4>
                                  <Form.Group className="mb-0" controlId="formBasicEmail">
                                    <Form.Control type="text" placeholder="Search" />
                                  </Form.Group>
                                </div>
                                <div className="mentees-inr-card p-3">
                                  <Row>
                                    <Col md={3} sm={3} xs={6}>
                                      <div className="mentees-pro-card">
                                        <div className="mentees-img">
                                          <img
                                            src={require('../../../assets/images/default-male-avatar.png')}
                                            alt="img"
                                          />
                                        </div>
                                        <div className="mentees-dis">
                                          <h4>shruti pathania</h4>
                                          <p> Barsar, Himachal Pradesh, India</p>
                                          <Dropdown className="mt-2">
                                            <Dropdown.Toggle variant="success" id="dropdown-basic">
                                              Your friend
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                              <Dropdown.Item href="#/action-1">
                                                View Profile
                                              </Dropdown.Item>
                                              <Dropdown.Item href="#/action-2">
                                                Send Message
                                              </Dropdown.Item>
                                              <Dropdown.Item href="#/action-3">
                                                Unfriend
                                              </Dropdown.Item>
                                            </Dropdown.Menu>
                                          </Dropdown>
                                        </div>
                                      </div>
                                    </Col>
                                    <Col md={3} sm={3} xs={6}>
                                      <div className="mentees-pro-card">
                                        <div className="mentees-img">
                                          <img
                                            src={require('../../../assets/images/default-male-avatar.png')}
                                            alt="img"
                                          />
                                        </div>
                                        <div className="mentees-dis">
                                          <h4>shruti pathania</h4>
                                          <p> Barsar, Himachal Pradesh, India</p>
                                          <Dropdown className="mt-2">
                                            <Dropdown.Toggle variant="success" id="dropdown-basic">
                                              Your friend
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                              <Dropdown.Item href="#/action-1">
                                                View Profile
                                              </Dropdown.Item>
                                              <Dropdown.Item href="#/action-2">
                                                Send Message
                                              </Dropdown.Item>
                                              <Dropdown.Item href="#/action-3">
                                                Unfriend
                                              </Dropdown.Item>
                                            </Dropdown.Menu>
                                          </Dropdown>
                                        </div>
                                      </div>
                                    </Col>
                                    <Col md={3} sm={3} xs={6}>
                                      <div className="mentees-pro-card">
                                        <div className="mentees-img">
                                          <img
                                            src={require('../../../assets/images/default-male-avatar.png')}
                                            alt="img"
                                          />
                                        </div>
                                        <div className="mentees-dis">
                                          <h4>shruti pathania</h4>
                                          <p> Barsar, Himachal Pradesh, India</p>
                                          <Dropdown className="mt-2">
                                            <Dropdown.Toggle variant="success" id="dropdown-basic">
                                              Your friend
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                              <Dropdown.Item href="#/action-1">
                                                View Profile
                                              </Dropdown.Item>
                                              <Dropdown.Item href="#/action-2">
                                                Send Message
                                              </Dropdown.Item>
                                              <Dropdown.Item href="#/action-3">
                                                Unfriend
                                              </Dropdown.Item>
                                            </Dropdown.Menu>
                                          </Dropdown>
                                        </div>
                                      </div>
                                    </Col>
                                    <Col md={3} sm={3} xs={6}>
                                      <div className="mentees-pro-card">
                                        <div className="mentees-img">
                                          <img
                                            src={require('../../../assets/images/default-male-avatar.png')}
                                            alt="img"
                                          />
                                        </div>
                                        <div className="mentees-dis">
                                          <h4>shruti pathania</h4>
                                          <p> Barsar, Himachal Pradesh, India</p>
                                          <Dropdown className="mt-2">
                                            <Dropdown.Toggle variant="success" id="dropdown-basic">
                                              Your friend
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                              <Dropdown.Item href="#/action-1">
                                                View Profile
                                              </Dropdown.Item>
                                              <Dropdown.Item href="#/action-2">
                                                Send Message
                                              </Dropdown.Item>
                                              <Dropdown.Item href="#/action-3">
                                                Unfriend
                                              </Dropdown.Item>
                                            </Dropdown.Menu>
                                          </Dropdown>
                                        </div>
                                      </div>
                                    </Col>
                                  </Row>
                                </div>
                              </Tab.Pane>
                              <Tab.Pane eventKey="friend2">
                                <div className="frirnd-hd d-flex align-items-center justify-content-between p-2">
                                  <h4>Mutual Friends</h4>
                                  <Form.Group className="mb-0" controlId="formBasicEmail">
                                    <Form.Control type="text" placeholder="Search" />
                                  </Form.Group>
                                </div>
                                <div className="mentees-inr-card p-3">
                                  <Row>
                                    <Col md={3} sm={3} xs={6}>
                                      <div className="mentees-pro-card">
                                        <div className="mentees-img">
                                          <img
                                            src={require('../../../assets/images/default-male-avatar.png')}
                                            alt="img"
                                          />
                                        </div>
                                        <div className="mentees-dis">
                                          <h4>shruti pathania</h4>
                                          <p> Barsar, Himachal Pradesh, India</p>
                                          <Dropdown className="mt-2">
                                            <Dropdown.Toggle variant="success" id="dropdown-basic">
                                              Your friend
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                              <Dropdown.Item href="#/action-1">
                                                View Profile
                                              </Dropdown.Item>
                                              <Dropdown.Item href="#/action-2">
                                                Send Message
                                              </Dropdown.Item>
                                              <Dropdown.Item href="#/action-3">
                                                Unfriend
                                              </Dropdown.Item>
                                            </Dropdown.Menu>
                                          </Dropdown>
                                        </div>
                                      </div>
                                    </Col>
                                    <Col md={3} sm={3} xs={6}>
                                      <div className="mentees-pro-card">
                                        <div className="mentees-img">
                                          <img
                                            src={require('../../../assets/images/default-male-avatar.png')}
                                            alt="img"
                                          />
                                        </div>
                                        <div className="mentees-dis">
                                          <h4>shruti pathania</h4>
                                          <p> Barsar, Himachal Pradesh, India</p>
                                          <Dropdown className="mt-2">
                                            <Dropdown.Toggle variant="success" id="dropdown-basic">
                                              Your friend
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                              <Dropdown.Item href="#/action-1">
                                                View Profile
                                              </Dropdown.Item>
                                              <Dropdown.Item href="#/action-2">
                                                Send Message
                                              </Dropdown.Item>
                                              <Dropdown.Item href="#/action-3">
                                                Unfriend
                                              </Dropdown.Item>
                                            </Dropdown.Menu>
                                          </Dropdown>
                                        </div>
                                      </div>
                                    </Col>
                                    <Col md={3} sm={3} xs={6}>
                                      <div className="mentees-pro-card">
                                        <div className="mentees-img">
                                          <img
                                            src={require('../../../assets/images/default-male-avatar.png')}
                                            alt="img"
                                          />
                                        </div>
                                        <div className="mentees-dis">
                                          <h4>shruti pathania</h4>
                                          <p> Barsar, Himachal Pradesh, India</p>
                                          <Dropdown className="mt-2">
                                            <Dropdown.Toggle variant="success" id="dropdown-basic">
                                              Your friend
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                              <Dropdown.Item href="#/action-1">
                                                View Profile
                                              </Dropdown.Item>
                                              <Dropdown.Item href="#/action-2">
                                                Send Message
                                              </Dropdown.Item>
                                              <Dropdown.Item href="#/action-3">
                                                Unfriend
                                              </Dropdown.Item>
                                            </Dropdown.Menu>
                                          </Dropdown>
                                        </div>
                                      </div>
                                    </Col>
                                    <Col md={3} sm={3} xs={6}>
                                      <div className="mentees-pro-card">
                                        <div className="mentees-img">
                                          <img
                                            src={require('../../../assets/images/default-male-avatar.png')}
                                            alt="male"
                                          />
                                        </div>
                                        <div className="mentees-dis">
                                          <h4>shruti pathania</h4>
                                          <p> Barsar, Himachal Pradesh, India</p>
                                          <Dropdown className="mt-2">
                                            <Dropdown.Toggle variant="success" id="dropdown-basic">
                                              Your friend
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                              <Dropdown.Item href="#/action-1">
                                                View Profile
                                              </Dropdown.Item>
                                              <Dropdown.Item href="#/action-2">
                                                Send Message
                                              </Dropdown.Item>
                                              <Dropdown.Item href="#/action-3">
                                                Unfriend
                                              </Dropdown.Item>
                                            </Dropdown.Menu>
                                          </Dropdown>
                                        </div>
                                      </div>
                                    </Col>
                                  </Row>
                                </div>
                              </Tab.Pane>
                            </Tab.Content>
                          </Tab.Container>
                        </div>
                      </Tab.Pane>
                      <Tab.Pane eventKey="fourth">
                        <div className="timeline-album bg-white">
                          <div className="album-hd p-2">
                            <h4>Albums</h4>
                          </div>
                          <div className="album-img-list">
                            <div className="album-img-card">
                              <Link to="#">
                                <img src={require('../../../assets/images/birth7.jpg')} alt="img" />
                                <h4>Timeline Photos</h4>
                              </Link>
                            </div>
                          </div>
                        </div>
                      </Tab.Pane>
                    </Tab.Content>
                  </div>
                </Col>
                <Col lg={3} md={12} xs={12}>
                  <div>
                    <PeopleKnow />
                  </div>
                  <div>
                    <WorkAnniversary />
                  </div>
                  <div>
                    <GroupDiscover />
                  </div>
                  <div>
                    <FollowCompany />
                  </div>
                  <div>
                    <CampusNews />
                  </div>
                  <div>
                    <ArticalBlogs />
                  </div>
                  <div>
                    <CampusnewsText />
                  </div>
                  <div>
                    <WhatsNew />
                  </div>
                  <div>
                    <Upcoming />
                  </div>
                  <MiniFooter />
                </Col>
              </Row>
            </Tab.Container>
          </div>
        </Container>
      </section>
    </>
  );
}

export default UserTimeline;
