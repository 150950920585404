import { SHARELINK_FAILED, SHARELINK_PENDING, SHARELINK_SUCCESS } from '../action';

const initialState = {
  links: {},
  isLoading: false,
  hasError: ''
};

export const sharelinkReducer = (state = initialState, action) => {
  switch (action.type) {
    case SHARELINK_PENDING:
      return {
        ...state,
        isLoading: true
      };

    case SHARELINK_SUCCESS:
      return {
        ...state,
        links: action.payload,
        isLoading: false
      };
    case SHARELINK_FAILED:
      return {
        ...state,
        links: {},
        isLoading: false,
        hasError: action.payload
      };

    default:
      return state;
  }
};
