/**
@copyright  :   ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
@author     :   Shiv Charan Panjeta 
 
All Rights Reserved.
Proprietary and confidential :  All information contained here in is, and remains
the property of ToXSL Technologies Pvt. Ltd. and it's partners.
Unauthorized copying of this file, via any medium is strictly prohibited.
*/
import { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Loader from '../../../components/commoncomponents/loader/loader-large.jsx';
import TinyMceEditor from '../../../components/commoncomponents/TinyMceEditor.jsx';
import showNotification from '../../../services/NotificationService.js';
import { getEmailToken, updateEmailTemplateDetail } from '../../../services/TemplateServices.js';
export default function WriteCode({ id, idAdd, templateData }) {
  const idToken = id ? id : idAdd;

  const [description, setDescription] = useState('');
  const [tokenList, setTokenList] = useState([]);
  // const [templateData, setTemplateData] = useState();

  const [loading, setLoading] = useState(false);

  const onChangeHandler = (e, select = false, name = '', data = '') => {
    setDescription(data);
  };

  useEffect(() => {
    if (idToken) {
      getTokenList(idToken);
    }
  }, [idToken]);

  function copyToClipBoard(text) {
    showNotification('success', 'Token Copied successfully');
    navigator.clipboard.writeText(text);
  }

  const getTokenList = () => {
    setLoading(true);
    getEmailToken(idToken).then((resp) => {
      if (resp?.status === 200) {
        setTokenList(resp?.data?.details);
        setLoading(false);
      } else {
        setLoading(false);
      }
    });
  };
  const checkCredentials = () => {
    if (description === '') {
      showNotification('danger', 'Please enter your own code');
      return false;
    }

    return true;
  };
  let navigate = useNavigate();

  const codeYourOwnUpdateFunc = async () => {
    if (checkCredentials()) {
      const body = {
        html: description,
        id: idToken
      };
      setLoading(true);
      try {
        const res = await updateEmailTemplateDetail(body);
        if (res?.status === 200) {
          setLoading(false);
          navigate('/admin/ManageTemplate', {
            state: {
              active: 'email'
            }
          });
          showNotification('success', res.data?.message);
        }
      } catch (error) {
        setLoading(false);
        console.error(error);
      }
    }
  };

  return (
    <div className="dashboard-card mr_large_padding">
      <form>
        <div className={`w100`}>
          <p className="p16 medium mr_normal_Margin_b">
            Write your code<span className="text-danger">*</span>
          </p>
          <TinyMceEditor
            state="emailValue"
            onChangeHandler={onChangeHandler}
            description={templateData ? templateData : description}
          />
        </div>
        <div className="w100">
          <div className="bg-blue4 mr_normal_padding  margin-bottom-50">
            <div className="fonts_inner d-flex align-item-center">
              {tokenList?.length !== 0
                ? tokenList?.map((data, index) => {
                    return (
                      <button
                        type="button"
                        key={index}
                        title="COPY"
                        className="green_button_admin mt-4 m-1"
                        onClick={() => copyToClipBoard(data)}>
                        {data}
                      </button>
                    );
                  })
                : ''}
            </div>
          </div>
        </div>
        <div className="form-btns d-flex align-items-center mt-4">
          {id ? (
            <button type="button" className="green_button_admin" onClick={codeYourOwnUpdateFunc}>
              Update
            </button>
          ) : (
            <button type="button" className="green_button_admin" onClick={codeYourOwnUpdateFunc}>
              Submit
            </button>
          )}
        </div>
      </form>
      {loading ? <Loader /> : ''}
    </div>
  );
}
