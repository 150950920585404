/** @copyright : ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
 @author     : Shiv Charan Panjeta 

 All Rights Reserved.
 Proprietary and confidential :  All information contained herein is, and remains
 the property of ToXSL Technologies Pvt. Ltd. and its partners.
 Unauthorized copying of this file, via any medium is strictly prohibited.
*/

import moment from 'moment';
import { useEffect, useState } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { ZERO } from '../../../globals/constants';
import useDocumentTitle from '../../../globals/useDocumentTitle';
import { myProductsOrders } from '../../../services/ShopServices';
import FooterGrey from '../../commoncomponents/FooterGrey';
import HeaderShop from '../../commoncomponents/HeaderShop';
import Loader from '../../commoncomponents/loader/loader-large';
import NoDataFound from '../../commoncomponents/NoDataFound';

function AllOrders() {
  useDocumentTitle('View Orders | AlmaBay');
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    getAllOrders();
  }, []);
  const getAllOrders = () => {
    setLoading(true);
    myProductsOrders().then((res) => {
      setLoading(false);
      if (res?.status === 200) {
        setLoading(false);
        setList(res?.data?.data);
      }
    });
  };
  // 1-Placed,2-Pending Payment,3-Delivered,4-Cancelled,5-Refund,6 -Payment Failed
  const orderStatus = (status) => {
    switch (status) {
      case '1':
        return 'Order Placed';
      case '2':
        return 'Pending Payment';
      case '3':
        return 'Delivered';
      case '4':
        return 'Cancelled';
      case '5':
        return 'Refund';
      case '6':
        return 'Payment Failed';
    }
  };

  return (
    <>
      <HeaderShop />

      <section className="py-5">
        <Container>
          <Row>
            <Col sm={9}>
              <h3 className="heading mb-4">Your Orders</h3>
            </Col>
            <Col sm={3}></Col>
            <Col sm={12}>
              {list && list?.length !== ZERO ? (
                list?.map((data, index) => {
                  return (
                    <div className="orders-main" key={index}>
                      <div className="orders-header">
                        <Row>
                          <Col lg={10}>
                            <Row>
                              <Col lg={3}>
                                <div className=" shipment-detail">
                                  <h3 className="custom-spacing">
                                    ({orderStatus(data?.order_status)})
                                  </h3>
                                  <h4 className="custom-spacing">
                                    {moment(data?.created).format('llll')}
                                  </h4>
                                </div>
                              </Col>
                              <Col lg={3}>
                                <div className=" shipment-detail">
                                  <h3 className="custom-spacing">TOTAL</h3>
                                  <h4 className="custom-spacing">
                                    {data?.grand_total ? `Rs. ${data?.grand_total}` : ''}
                                  </h4>
                                </div>
                              </Col>
                              <Col lg={3}>
                                <div className=" shipment-detail">
                                  <h3 className="custom-spacing">SHIP TO</h3>
                                  <h4 className="custom-spacing">{data?.ship_to}</h4>
                                </div>
                              </Col>
                            </Row>
                          </Col>

                          <Col lg={2}>
                            <div className=" shipment-detail">
                              <h3 className="custom-spacing">Order ID</h3>
                              <h4 className="custom-spacing">
                                {data?.id}
                                <span className="status">( {orderStatus(data?.order_status)})</span>
                              </h4>
                            </div>
                          </Col>
                        </Row>
                      </div>
                      <div className="orders-body">
                        <h1 className="shipment-name">
                          Shipment ({data?.total_products}) Delivery by{' '}
                          {moment(data?.delivered_date).format('llll')}
                        </h1>
                        <Row>
                          {data?.products?.map((productList, index) => {
                            return (
                              <Col lg={9} key={index}>
                                <div className="media d-flex mb-2">
                                  <div className="media-left images-border">
                                    <img
                                      className="media-object"
                                      src={productList?.product_image}
                                      alt="pic"
                                      width="100px"
                                    />
                                  </div>
                                  <div className="media-body">
                                    <Link
                                      to={`/shop/details/${productList.product_id}`}
                                      target="_blank">
                                      <h1 className="media-heading">{productList?.product_name}</h1>
                                    </Link>
                                    <h2>Code: {productList?.product_code}</h2>
                                    <h2>Qty: {productList?.product_quantity} </h2>
                                    <h2>Rs. {productList?.product_price}</h2>
                                  </div>
                                </div>
                              </Col>
                            );
                          })}

                          <Col lg={3}>
                            <Link
                              target="_blank"
                              className="btn btn-primary rounded-0 w-100 mb-3"
                              to={`/shop/orderdetail/${data?.id}`}>
                              View Details
                            </Link>
                            {data?.order_status == '1' || data?.order_status == '3' ? (
                              ''
                            ) : (
                              <button className="btn btn-primary rounded-0 w-100">
                                Complete Payment
                              </button>
                            )}
                          </Col>
                        </Row>
                      </div>
                    </div>
                  );
                })
              ) : (
                <div className="text-center">
                  <NoDataFound text="You haven't placed any order yet." />
                  <Link
                    className="white-btn me-2"
                    to={{ pathname: `/shop/catalog`, state: 'catalog' }}>
                    <Button>Shop Now</Button>
                  </Link>
                </div>
              )}
            </Col>
          </Row>
        </Container>
      </section>

      <FooterGrey />
      {loading ? <Loader /> : ''}
    </>
  );
}

export default AllOrders;
