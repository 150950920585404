import { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { NETWORK } from '../../../globals/constants';
import { getPageDetail } from '../../../services/InstituteServices';
import Loader from '../../commoncomponents/loader/loader-large';

function Networking() {
  const [list, setList] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getPageDetailIns();
  }, []);

  const getPageDetailIns = () => {
    getPageDetail(NETWORK).then((res) => {
      setLoading(false)
      if (res?.status == 200) {
        let data = res?.data?.details ? res?.data?.details[0] : "";
        setList(data);
      }
    });
  };

  return (
    <>
      <div className="wrappermain_outer">
        {list ? <>
          <section
            className="breadcrum-banner"
            style={{
              backgroundImage: `url(${list?.banner_file
                ? process.env.REACT_APP_API_URL + list?.banner_file
                : '/static/media/breadcrum-banner-img.1a6b2ed71ebb8768883c.png'
                })`
            }}
          >
            <Container>
              <Row>
                <Col sm={12}>
                  <h1 className="Text-white">{list?.banner_title || 'Networking'}</h1>
                </Col>
              </Row>
            </Container>
          </section>

          <section className="networking-section">
            <Container>
              <Row>
                <Col lg={5}>
                  <img
                    src={
                      list?.image_file
                        ? process.env.REACT_APP_API_DOMAIN + list?.image_file
                        : require('../../../assets/images/networking.png')
                    }
                    alt="img"
                  />
                </Col>
                <Col lg={7}>
                  {list?.description && (
                    <span dangerouslySetInnerHTML={{ __html: list?.description }} />
                  )}
                </Col>
              </Row>
            </Container>
          </section>
        </> : <div className="wrappermain_outer">
          <div className={loading ? "d-none" : "text-center nodata"}>
            <img src={require('../../../assets/images/nodata.png')} alt="no_data" />
            <p>Data Not Found</p>
          </div></div>}
      </div >
      <div className="lgn-link pb-4">
        <Container>
          <div className="logn-lnk-inr">
            <p>
              <Link to="/institute-register">Register</Link> /{' '}
              <Link to="/institute-login">Login</Link> to browse members by Year, Name, Company,
              Profession or Location
            </p>
          </div>
        </Container>
      </div>
      {loading ? <Loader /> : <></>}
    </>
  );
}

export default Networking;
