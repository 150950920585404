/**
@copyright  :   ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
@author     :   Shiv Charan Panjeta 
 
All Rights Reserved.
Proprietary and confidential :  All information contained here in is, and remains
the property of ToXSL Technologies Pvt. Ltd. and it's partners.
Unauthorized copying of this file, via any medium is strictly prohibited.
*/

import { useEffect, useState } from 'react';
import { Button, Container } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Pagination from 'react-bootstrap/Pagination';
import Row from 'react-bootstrap/Row';
import { useNavigate } from 'react-router-dom';
import Loader from '../../components/commoncomponents/loader/loader-large';
import { TEMPLATE_APPLICABLE_FOR_INSTITUTE } from '../../globals/constants';
import showNotification from '../../services/NotificationService';
import * as TemplateServices from '../../services/TemplateServices';

export default function AddManageTemplate() {
  let active = 2;
  let items = [];
  for (let number = 1; number <= 5; number++) {
    items.push(
      <Pagination.Item key={number} active={number === active}>
        {number}
      </Pagination.Item>
    );
  }
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [applicableFor, setApplicableFor] = useState('');
  const [assignUserList, setAssignUserList] = useState([]);
  const [assignUserId, setAssignUserId] = useState('');
  const [templateTitle, setTemplateTitle] = useState('');
  const [status, setStatus] = useState(1);
  const id = new URLSearchParams(location.search).get('id') ?? null;
  const [applicableForList, setApplicableForList] = useState([
    {
      id: TEMPLATE_APPLICABLE_FOR_INSTITUTE,
      name: 'INSTITUTE'
    }
  ]);

  useEffect(() => {
    if (id) {
      getTokenDetail(id);
    }
  }, [id]);

  /**
   * GET SINGLE TOKEN DETAIL
   * @param {*} page
   */

  const getTokenDetail = (id) => {
    setLoading(true);
    TemplateServices.getSingleTokenDetail(id).then((resp) => {
      if (resp?.status === 200) {
        setTemplateTitle(resp?.data?.detail?.title);
        setApplicableFor(resp?.data?.detail?.applicable_for);
        if (resp?.data?.detail?.applicable_for) {
          getListsForSelectOptions(resp?.data?.detail?.applicable_for);
          setAssignUserId(resp?.data?.detail?.attribute);
        }
        setStatus(resp?.data?.detail?.state_id);
        setLoading(false);
      } else {
        setLoading(false);
      }
    });
  };

  const getListsForSelectOptions = (type) => {
    setApplicableFor(type);
    setAssignUserId('');
    setLoading(true);

    TemplateServices.getStudentToken(TEMPLATE_APPLICABLE_FOR_INSTITUTE).then((res) => {
      setAssignUserList(res?.data?.data);
      setLoading(false);
    });

    // if (type == TEMPLATE_APPLICABLE_FOR_INSTITUTE) {
    //   TemplateServices.getInstituteToken(type).then((res) => {
    //     setAssignUserList(res?.data?.list);
    //     setLoading(false);
    //   });
    // }
    // if (type == TEMPLATE_APPLICABLE_FOR_COMPANY) {
    //   TemplateServices.getCompanyToken(type).then((res) => {
    //     setAssignUserList(res?.data?.list);
    //     setLoading(false);
    //   });
    // }
  };
  const checkCredentials = () => {
    if (templateTitle.trim() === '') {
      showNotification('danger', 'Token title field required');
      return false;
    }
    if (applicableFor === '') {
      showNotification('danger', 'Applicable for field required');
      return false;
    }
    if (assignUserId === '') {
      showNotification('danger', 'Attribute field required');
      return false;
    }
    return true;
  };
  /**
   * Add Record
   */
  const saveToken = () => {
    if (checkCredentials()) {
      setLoading(true);
      let formData = new FormData();
      formData.append('title', templateTitle);
      formData.append('applicable_for', applicableFor);
      formData.append('attribute', assignUserId);
      formData.append('state_id', status);
      TemplateServices.addTemplateToken(formData).then((res) => {
        if (res?.status == 200) {
          setLoading(false);
          showNotification('success', res?.data?.message);
          navigate('/admin/ManageTemplate', {
            state: {
              active: 'token'
            }
          });
        } else {
          setLoading(false);
        }
      });
    }
  };
  /**
   * Update Record
   */

  const updateToken = () => {
    if (checkCredentials()) {
      setLoading(true);
      let formData = new FormData();
      formData.append('id', id);
      formData.append('title', templateTitle);
      formData.append('applicable_for', applicableFor);
      formData.append('attribute', assignUserId);
      formData.append('state_id', status);
      TemplateServices.updateTokenDetail(formData).then((res) => {
        if (res?.status == 200) {
          setLoading(false);
          showNotification('success', res?.data?.message);
          navigate('/admin/ManageTemplate');
        } else {
          setLoading(false);
        }
      });
    }
  };

  return (
    <>
      <div className="dash-main-content">
        <Container fluid>
          <div className="dash-page-content">
            <div className="dash-page-title">
              <Row>
                <Col xs={12} sm={12} md={12}>
                  <div className="dash-title-bar d-flex align-items-center justify-content-between">
                    <div className="title-lft d-flex align-items-center">
                      <div className="dash-icn">
                        <i className="fas fa-briefcase"></i>
                      </div>
                      <div className="dash-title-dis">
                        <h5>{id ? 'Update' : 'Add'} Manage Communication Template</h5>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="dash-content-card">
              <div className="cmmn-form">
                <Form>
                  <Row>
                    <Col md={12}>
                      <Form.Group className="mb-3" controlId="formBasicName">
                        <Form.Label>
                          Token Title<span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          autoComplete="off"
                          type="text"
                          placeholder="Enter Token Title"
                          onChange={(e) => setTemplateTitle(e.target.value)}
                          value={templateTitle}
                        />
                      </Form.Group>
                    </Col>
                    <Col md={12}>
                      <Form.Group className="mb-3" controlId="formBasicName">
                        <Form.Label>
                          Applicable for<span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Select
                          aria-label="Default select example"
                          value={applicableFor}
                          disabled={id}
                          onChange={(e) => getListsForSelectOptions(e.target.value)}>
                          {applicableFor ? '' : <option>Select Applicable For </option>}
                          {applicableForList?.map((data, index) => {
                            return (
                              <>
                                <option key={index} value={data?.id}>
                                  {data?.name}
                                </option>
                              </>
                            );
                          })}
                        </Form.Select>
                      </Form.Group>
                    </Col>
                    <Col md={12}>
                      <Form.Group className="mb-3" controlId="formBasicName">
                        <Form.Label>
                          Attribute <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Select
                          aria-label="Default select example"
                          value={assignUserId}
                          onChange={(e) => setAssignUserId(e.target.value)}>
                          <option>Select Attribute of Applicable For </option>
                          {assignUserList &&
                            Object.entries(assignUserList).map((entries, index) => {
                              return (
                                <option key={index} value={entries[0]}>
                                  {entries[1]}
                                </option>
                              );
                            })}
                        </Form.Select>
                      </Form.Group>
                    </Col>
                    <Col md={12}>
                      <Form.Group className="mb-3" controlId="formBasicName">
                        <Form.Label>Status </Form.Label>
                        <Form.Select
                          aria-label="Default select example"
                          onChange={(e) => setStatus(e.target.value)}
                          value={status}>
                          <option>Select Status </option>
                          <option value="1">Enable</option>
                          <option value="2">Disable</option>
                        </Form.Select>
                      </Form.Group>
                    </Col>
                  </Row>
                  <div className="form-btns d-flex align-items-center mt-3">
                    {id ? (
                      <button type="button" className="green_button_admin" onClick={updateToken}>
                        Update
                      </button>
                    ) : (
                      <button type="button" className="green_button_admin" onClick={saveToken}>
                        Save
                      </button>
                    )}

                    <strong>OR</strong>
                    <Button className="main-btn-red" onClick={() => navigate(-1)}>
                      Cancel
                    </Button>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </Container>
      </div>

      {loading ? <Loader /> : <></>}
    </>
  );
}
