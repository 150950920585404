/**
@copyright  :   ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
@author     :   Shiv Charan Panjeta 
 
All Rights Reserved.
Proprietary and confidential :  All information contained here in is, and remains
the property of ToXSL Technologies Pvt. Ltd. and it's partners.
Unauthorized copying of this file, via any medium is strictly prohibited.
*/

import { useEffect, useState } from 'react';
import { Button, Container } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Pagination from 'react-bootstrap/Pagination';
import Row from 'react-bootstrap/Row';
import { useLocation, useNavigate } from 'react-router-dom';
import Select from 'react-select';
import { AsyncPaginate } from 'react-select-async-paginate';
import Loader from '../../components/commoncomponents/loader/loader-large';
import { TEMPLATE_APPLICABLE_FOR_INSTITUTE, constantType } from '../../globals/constants';
import { replaceStringFormat } from '../../globals/helper';
import { getInstAdminList, getInstituteList } from '../../services/AuthServices';
import showNotification from '../../services/NotificationService';
import {
  addSMSTemplate,
  getSingleSMSTemplateDetail,
  getTemplateTokenListByApplicable,
  updateSMSTemplateDetail
} from '../../services/TemplateServices';

export default function AddSMSTemplate() {
  let active = 2;
  let items = [];
  for (let number = 1; number <= 5; number++) {
    items.push(
      <Pagination.Item key={number} active={number === active}>
        {number}
      </Pagination.Item>
    );
  }
  let location = useLocation();
  const id = new URLSearchParams(location.search).get('id') ?? '';
  const navigate = useNavigate();
  const [isClearable, setIsClearable] = useState(true);
  const [isSearchable, setIsSearchable] = useState(true);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRtl, setIsRtl] = useState(false);
  const [applicableFor, setApplicableFor] = useState('');
  const [loading, setLoading] = useState(false);
  const [tokenList, setTokenList] = useState([]);
  const [content, setContent] = useState('');
  const [tokenId, setTokenId] = useState('');
  const [natureOfTemplate, setNatureOfTemplate] = useState('');
  const [templateTitle, setTemplateTitle] = useState('');
  const [dltTemplateId, setDltTemplateId] = useState('');
  const [senderId, setSenderId] = useState('');
  const [status, setStatus] = useState(1);
  const [applicableForOption, setApplicableForOption] = useState(false);
  const [applicableForList, setApplicableForList] = useState([
    {
      id: TEMPLATE_APPLICABLE_FOR_INSTITUTE,
      name: 'INSTITUTE'
    }
  ]);
  const [value, setValue] = useState(0);
  const [assignMemberList, setAssignMemberList] = useState([]);
  const [assignMemberList2, setAssignMemberList2] = useState([]);
  const [campus, setCampus] = useState([]);
  const [instID, setInstID] = useState();
  const [selectToken, setSelectToken] = useState(false);
  useEffect(() => {
    if (id) {
      getSMSTemplateDetail(id);
    }
  }, [id]);

  /**
   * GET SINGLE TOKEN DETAIL
   * @param {*} page
   */

  const getSMSTemplateDetail = (id) => {
    setLoading(true);
    getSingleSMSTemplateDetail(id).then((resp) => {
      if (resp?.status === 200) {
        setTemplateTitle(resp?.data?.detail?.title);
        setApplicableFor(resp?.data?.detail?.applicable_for);
        setDltTemplateId(resp?.data?.detail?.dlt_template_id);
        setNatureOfTemplate(resp?.data?.detail?.nature_of_template);
        setContent(resp?.data?.detail?.content);
        setTokenId(resp?.data?.detail?.content_token);
        setStatus(resp?.data?.detail?.state_id);
        setSenderId(resp?.data?.detail?.sender_id);
        applicableForFun(resp?.data?.detail?.applicable_for);
        setInstID(resp?.data?.detail?.campus_id);

        setValue({ name: resp?.data?.detail?.campus_name });
        setLoading(false);
      } else {
        setLoading(false);
      }
    });
  };
  const applicableForFun = (applicable_for_type) => {
    setApplicableForOption(true);
    setApplicableFor(applicable_for_type);
    setLoading(true);
    getTemplateTokenListByApplicable(applicable_for_type).then((resp) => {
      if (resp?.status === 200) {
        setLoading(false);
        setTokenList(resp?.data?.data);
      } else {
        setApplicableForOption(false);
      }
    });
  };
  const onTokenSelect = (e) => {
    setSelectToken(true);
    setTokenId(e.target.value);
    let selected_token = e.target.options[e.target.selectedIndex].text;
    if (e.target.value != '') {
      let updatedContent = content + replaceStringFormat(selected_token);
      setContent(updatedContent);
    } else {
      setSelectToken(false);
    }
  };
  /**
   * Form validation
   * @returns
   */

  const checkCredentials = () => {
    if (templateTitle.trim() === '' || templateTitle.trim().length <= 0) {
      showNotification('danger', 'Template title field required');
      return false;
    }

    if (applicableFor === '' || applicableFor <= 0) {
      showNotification('danger', 'Applicable for field required');
      return false;
    }

    if (dltTemplateId.trim() === '' || dltTemplateId.trim().length <= 0) {
      showNotification('danger', 'DLT Template Id field required');
      return false;
    }
    if (senderId.trim() === '' || senderId.trim().length <= 0) {
      showNotification('danger', 'Sender Id field required');
      return false;
    }
    if (natureOfTemplate === '') {
      showNotification('danger', 'Select Nature of Template');
      return false;
    }

    if (tokenId.trim() === '' || tokenId.trim().length <= 0) {
      showNotification('danger', 'Token field required');
      return false;
    }
    if (value == '') {
      showNotification('danger', 'Insitute field required');
      return false;
    }

    if (assignMemberList == '') {
      showNotification('danger', 'Assign user to field required');
      return false;
    }

    return true;
  };

  /**
   * Add Record
   */ const membersID = assignMemberList?.map((item) => {
    return item.id;
  });

  const saveSMSTemplate = () => {
    if (checkCredentials()) {
      setLoading(true);
      let formData = new FormData();
      formData.append('title', templateTitle);
      formData.append('applicable_for', applicableFor);
      formData.append('dlt_template_id', dltTemplateId);
      formData.append('nature_of_template', natureOfTemplate);
      formData.append('content', content);
      formData.append('content_token', tokenId);
      formData.append('sender_id', senderId);
      formData.append('campus_id', value.institute_id);
      formData.append('assign_user', membersID.toString());
      formData.append('state_id', status);

      addSMSTemplate(formData).then((res) => {
        if (res?.status == 200) {
          setLoading(false);
          showNotification('success', res?.data?.message);
          navigate('/admin/ManageTemplate', {
            state: {
              active: 'sms'
            }
          });
        } else {
          setLoading(false);
        }
      });
    }
  };
  /**
   * Update SMS Detail
   */

  const updateSMSTemplate = () => {
    if (checkCredentials()) {
      setLoading(true);
      let formData = new FormData();
      formData.append('id', id);
      formData.append('title', templateTitle);
      formData.append('applicable_for', applicableFor);
      formData.append('dlt_template_id', dltTemplateId);
      formData.append('nature_of_template', natureOfTemplate);
      formData.append('content', content);
      formData.append('content_token', tokenId);
      formData.append('sender_id', senderId);
      formData.append('state_id', status);
      formData.append('campus_id', value.institute_id ? value.institute_id : instID);
      formData.append('assign_user', membersID.toString());

      updateSMSTemplateDetail(formData).then((res) => {
        if (res?.status == 200) {
          setLoading(false);
          showNotification('success', res?.data?.message);
          navigate('/admin/ManageTemplate', {
            state: {
              active: 'sms'
            }
          });
        } else {
          setLoading(false);
        }
      });
    }
  };

  const loadOptions = async (search, loadedOptions, { page }) => {
    try {
      let body = {
        page: page,
        institute_name: search
      };
      const resp = await getInstituteList(body);
      setCampus(resp?.data?.data?.viewlist ?? []);
      return {
        options: resp?.data?.data?.viewlist ?? [],
        hasMore: loadedOptions.length === resp?.data?.data?.totalRecords ? false : true,
        additional: {
          page: search ? page : page + 1
        }
      };
    } catch (error) {
      console.log('error', error);
    }
  };
  useEffect(() => {
    (async () => {
      if (value.institute_id ? value?.institute_id : instID) {
        const resp = await getInstAdminList(value.institute_id ? value?.institute_id : instID);
        setLoading(true);
        if (resp?.status === 200) {
          if (value.institute_id) {
            showNotification('success', resp?.data?.message);
          }
          setLoading(false);
          const newArray = resp.data.data?.map((item) => {
            return {
              id: item?.user_id,
              value: item?.user_id,
              label: item.name
            };
          });
          setAssignMemberList(newArray);
          setAssignMemberList2(newArray);
        } else {
          setAssignMemberList2([]);
        }
      }
    })();
  }, [value?.institute_id ? value?.institute_id : instID]);

  const handleChange = (val) => {
    setAssignMemberList(val);
  };

  return (
    <>
      <div className="dash-main-content">
        <Container fluid>
          <div className="dash-page-content">
            <div className="dash-page-title">
              <Row>
                <Col xs={12} sm={12} md={12}>
                  <div className="dash-title-bar d-flex align-items-center justify-content-between">
                    <div className="title-lft d-flex align-items-center">
                      <div className="dash-icn">
                        <i className="fas fa-briefcase"></i>
                      </div>
                      <div className="dash-title-dis">
                        <h5>Add SMS Template</h5>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="dash-content-card">
              <div className="cmmn-form">
                <Form>
                  <Row>
                    <Col md={12}>
                      <Form.Group className="mb-3" controlId="formBasicName">
                        <Form.Label>
                          Template Title <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter Template Title"
                          onChange={(e) => setTemplateTitle(e.target.value)}
                          value={templateTitle}
                        />
                      </Form.Group>
                    </Col>

                    <Col md={12}>
                      <Form.Group className="mb-3" controlId="formBasicName">
                        <Form.Label>
                          Template Applicable for <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Select
                          aria-label="Default select example"
                          onChange={(e) => applicableForFun(e.target.value)}
                          value={applicableFor}>
                          {applicableForOption == true || applicableFor ? (
                            ''
                          ) : (
                            <option>Select Applicable For </option>
                          )}
                          {applicableForList?.map((data, index) => (
                            <>
                              <option key={index} value={data?.id}>
                                {data?.name}
                              </option>
                            </>
                          ))}
                        </Form.Select>
                      </Form.Group>
                    </Col>
                    <h5 className="mt-3">SMS Template</h5>
                    <Col md={12}>
                      <Form.Group className="mb-3" controlId="formBasicName">
                        <Form.Label>
                          DLT Template Id <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter DLT Template Id"
                          onChange={(e) => setDltTemplateId(e.target.value)}
                          value={dltTemplateId}
                        />
                      </Form.Group>
                    </Col>
                    <Col md={12}>
                      <Form.Group className="mb-3" controlId="formBasicName">
                        <Form.Label>
                          Sender Id <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter Sender Id"
                          onChange={(e) => setSenderId(e.target.value)}
                          value={senderId}
                        />
                      </Form.Group>
                    </Col>
                    <Col md={12}>
                      <Form.Group className="mb-3" controlId="formBasicName">
                        <Form.Label>
                          Nature of Template <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Select
                          aria-label="Default select example"
                          onChange={(e) => setNatureOfTemplate(e.target.value)}
                          value={natureOfTemplate}>
                          {natureOfTemplate ? '' : <option>Select Nature of Template </option>}
                          <option value={constantType.PROMOTIONAL}>Promotional</option>
                          <option value={constantType.TRANSACTIONAL}>Transactional</option>
                        </Form.Select>
                      </Form.Group>
                    </Col>
                    <Col md={12}>
                      <Form.Group className="mb-3" controlId="formBasicDesi">
                        <Form.Label>Content </Form.Label>
                        <Form.Control
                          onChange={(e) => {
                            setContent(e.target.value);
                          }}
                          value={content}
                          as="textarea"
                          rows={3}
                          placeholder="Enter your content"
                        />
                      </Form.Group>
                    </Col>

                    <Col md={6}>
                      <Form.Group className="mb-3" controlId="formBasicName">
                        <Form.Label>
                          Token <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Select
                          aria-label="Default select example"
                          onChange={(e) => onTokenSelect(e)}
                          value={tokenId}>
                          {selectToken == true || tokenId ? '' : <option>Select Token </option>}
                          {tokenList?.length > 0 &&
                            tokenList?.map((data, index) => (
                              <>
                                <option key={index} value={data?.id}>
                                  {data?.title}
                                </option>
                              </>
                            ))}
                        </Form.Select>
                      </Form.Group>
                    </Col>

                    <Col md={6}>
                      <Form.Group className="mb-3" controlId="formBasicName">
                        <Form.Label>
                          Institute <span className="text-danger">*</span>
                        </Form.Label>
                        <AsyncPaginate
                          className="w100  p16 mr_normal_padding_l mr_medium_padding_r"
                          loadOptions={loadOptions}
                          name="meal_id"
                          id={Math.random()}
                          getOptionValue={(option) => {
                            return option.institute_id;
                          }}
                          getOptionLabel={(option) => {
                            return option.name;
                          }}
                          value={value}
                          onChange={setValue}
                          additional={{
                            page: 1
                          }}
                          placeholder="Select Institute"
                          debounceTimeout={500}
                          isSearchable={true}
                        />
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group className="mb-3" controlId="formBasicName">
                        <Form.Label>
                          Assign User To <span className="text-danger">*</span>{' '}
                        </Form.Label>
                        <Select
                          isMulti
                          name="colors"
                          className="basic-multi-select"
                          classNamePrefix="select"
                          options={assignMemberList2}
                          placeholder="Select"
                          value={assignMemberList}
                          onChange={(e) => {
                            handleChange(e);
                          }}
                        />
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group className="mb-3" controlId="formBasicName">
                        <Form.Label>Status </Form.Label>
                        <Form.Select
                          aria-label="Default select example"
                          onChange={(e) => setStatus(e.target.value)}
                          value={status}>
                          <option>Select Status </option>
                          <option value="1">Enable</option>
                          <option value="2">Disable</option>
                        </Form.Select>
                      </Form.Group>
                    </Col>
                  </Row>
                  <div className="form-btns d-flex align-items-center mt-3">
                    {id ? (
                      <button
                        type="button"
                        className="green_button_admin"
                        onClick={updateSMSTemplate}>
                        Update
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="green_button_admin"
                        onClick={saveSMSTemplate}>
                        Save
                      </button>
                    )}

                    <strong>OR</strong>
                    <Button className="main-btn-red" onClick={() => navigate(-1)}>
                      Cancel
                    </Button>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </Container>
      </div>
      {loading ? <Loader /> : <></>}
    </>
  );
}
