import Header from './CommonComponents/Header';
import SideBar from './CommonComponents/Sidebar';
import Footer from './CommonComponents/Footer';
import { Button, Container } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const NotAllowed = () => {
  const navigate = useNavigate();
  return (
    <div className="dash-wrapper">
      <Header />
      <SideBar />
      <div className="dash-main-content">
        <Container fluid>
          <div
            style={{ height: '80vh' }}
            className="dash-page-content d-flex align-items-center justify-content-center"
          >
            <div style={{ marginBottom: 0, padding: '30px 200px' }} className="dash-content-card">
              <div style={{ gap: '10px' }} className="d-flex flex-column align-items-center">
                <i style={{ fontSize: '200px' }} className="fa fa-exclamation-triangle"></i>
                <span style={{ fontSize: '24px' }}>You are not allowed to perform this action</span>
                <Button
                  onClick={() => {
                    navigate('/admin/user/welcome');
                  }}
                >
                  Go Home
                </Button>
              </div>
            </div>
          </div>
        </Container>
      </div>
      <Footer />
    </div>
  );
};

export default NotAllowed;
