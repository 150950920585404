import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import HeaderLogin from '../../commoncomponents/HeaderLogin';
import FooterMain from '../../commoncomponents/FooterMain';
import TopTabbings from './TopTabbings';
import NewsSidebar from '../../commoncomponents/newsfeeds/NewsSidebar';
import * as APISERVICES from '../../../services/JobServices';
import { truncate } from '../../../globals/helper';
import showNotification from '../../../services/NotificationService';
import { useNavigate } from 'react-router-dom';
import useDocumentTitle from '../../../globals/useDocumentTitle';
import RecomendModal from '../common-professional-comp/RecomendModal';
import * as index from '../../commoncomponents/skelton/allskelton';
import moment from 'moment';

function PostedJob() {
  useDocumentTitle('Job Posted | AlmaBay');
  const [postedJob, setPostedJob] = useState([]);

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [jobId, setJobId] = useState('');

  const [show3, setShow3] = useState(false);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);
  const [page, setPage] = useState(1);
  const [allJobs, setAllJobs] = useState(null);
  console.log('allJobs', allJobs, postedJob);

  useEffect(() => {
    getPostedList();
  }, [page]);

  const getPostedList = () => {
    setLoading(true);
    APISERVICES.getPostedJobs(page).then((res) => {
      setLoading(false);
      if (res?.status === 200) {
        setPostedJob([...postedJob, ...res?.data?.data]);
        setAllJobs(res?.data?.pager?.total);
      }
    });
  };

  const closeJobHandler = (e, id) => {
    e.preventDefault();
    if (confirm('Do you want to close job?') == true) {
      APISERVICES.closeJob(id).then((res) => {
        if (res?.status === 200) {
          showNotification('success', res?.data?.message);
          setPage(1);
          getPostedList();
          window.location.reload();
        }
      });
    } else {
      return;
    }
  };

  const viewApplicationsHandler = (data) => {
    if (data?.applicaiton_count != 0) {
      navigate(`/jobs/job-application/${data?.id}`, { state: data?.job_title });
    } else {
      return;
    }
  };

  const onRecommendClick = (e, id) => {
    e.preventDefault();
    setJobId(id);
    handleShow3();
  };

  return (
    <>
      <HeaderLogin />
      <div className="header-lnk-feeds">
        <div className="container">
          <TopTabbings state={'feed'} />
        </div>
      </div>
      <section className="feed-sec bg-grey py-4">
        <div className="container">
          <Row>
            <Col xs={12} lg={2}>
              <NewsSidebar state="0" current="posted" />
            </Col>

            <Col xs={12} lg={9} xl={7} className="mb-4">
              <div className="job-recomm-card bg-white">
                <h4>Posted Jobs</h4>
                <h6 className="intern-count-hd pt-3 px-3">
                  You have posted {postedJob?.length || 0} job(s)
                </h6>
                <div className="job-recommd-lst p-3">
                  {loading
                    ? Array.from(Array(3), () => {
                        return <index.PostedJobSkelton />;
                      })
                    : postedJob &&
                      postedJob?.map((data, index) => {
                        return (
                          <div className="job-recomm-card-inr internship-card-inr" key={index}>
                            <div className="job-recomm-dis">
                              <h4>
                                <Link
                                  to={
                                    data?.status === '2'
                                      ? `/jobs`
                                      : `/jobs/jobs-details/${data?.id}`
                                  }>
                                  {data?.job_title}
                                </Link>
                              </h4>
                              <p>{data?.company_name || data?.job_company_meta}</p>
                              <ul className="job-recomm-addrs">
                                {data?.city_name ? (
                                  <li>
                                    <i title="Location" className="fas fa-map-marker-alt"></i>
                                    {data?.city_name}
                                  </li>
                                ) : (
                                  ''
                                )}
                                <li>
                                  <i title="Positions open" className="fas fa-users"></i>Positions
                                  Open: {data?.open_positions}
                                </li>
                                <li>
                                  <i className="far fa-clock"></i>
                                  <span
                                    title={moment(data?.created_on).format('YYYY-MM-DD hh:mm:ss')}>
                                    {data?.date_time}{' '}
                                  </span>
                                </li>
                                <li title="Experience required">
                                  <i className="fas fa-briefcase"></i>Experience: {data?.min_exp} to{' '}
                                  {data?.max_exp} (yrs)
                                </li>
                                <li title={data?.job_types}>
                                  <i className="fas fa-hourglass-start"></i>Job Type:{' '}
                                  {data?.job_types}
                                </li>
                              </ul>
                              <div
                                dangerouslySetInnerHTML={{ __html: truncate(data?.job_info, 200) }}
                              />
                              {data?.job_info?.length > 150 && (
                                <Link
                                  className="org-color"
                                  to={`/jobs/jobs-details/${data?.id}?posted=${'posted'}`}>
                                  View in Detail
                                </Link>
                              )}
                            </div>
                            <div className="job-recommd-ftr">
                              <ul>
                                {data?.status === '2' ? (
                                  <>
                                    <li>
                                      <Link className="apply-btn" to="#">
                                        View Applications (0)
                                      </Link>
                                    </li>
                                    <li>
                                      <Link className="job-closed disabled">Job Closed</Link>
                                    </li>
                                  </>
                                ) : (
                                  <>
                                    <li>
                                      <Link
                                        className="apply-btn"
                                        to="#"
                                        onClick={(e) => {
                                          e.preventDefault();
                                          viewApplicationsHandler(data);
                                        }}>
                                        View Applications ({data?.applicaiton_count})
                                      </Link>
                                    </li>
                                    <li>
                                      <Link
                                        className="close-btn"
                                        onClick={(e) => closeJobHandler(e, data?.id)}>
                                        Close Job
                                      </Link>
                                    </li>
                                    <li>
                                      <Link className="edit-btn" to={`/jobs/edit?id=${data?.id}`}>
                                        Edit Job
                                      </Link>
                                    </li>
                                    <li>
                                      <Link
                                        to="#"
                                        onClick={(e) => {
                                          onRecommendClick(e, data?.id);
                                        }}>
                                        <i className="fas fa-thumbs-up me-1"></i>Recommend
                                      </Link>
                                    </li>
                                  </>
                                )}
                              </ul>
                            </div>
                          </div>
                        );
                      })}
                </div>
              </div>
              {allJobs !== postedJob.length && (
                <div className="load_more_posted">
                  <button
                    onClick={() => {
                      setPage(page + 1);
                    }}>
                    Load More
                  </button>
                </div>
              )}
            </Col>

            <Col xl={{ span: 3, offset: 0 }} lg={{ span: 9, offset: 2 }}>
              {/* <div>
                <PeopleKnow />
              </div>
              <div>
                <WorkAnniversary />
              </div>
              <div>
                <GroupDiscover />
              </div>
              <div>
                <FollowCompany />
              </div>
              <div>
                <CampusNews />
              </div>
              <div>
                <CampusnewsText />
              </div>
              <div>
                <ArticalBlogs />
              </div>
              <div>
                <WhatsNew />
              </div>
              <div>
                <Upcoming />
              </div> */}
              {/* <MiniFooter /> */}
            </Col>
          </Row>
        </div>
      </section>
      {/* recommended job modal */}
      <RecomendModal handleClose3={handleClose3} show3={show3} jobId={jobId} />
      {/* end of modal */}
      <FooterMain className={'colour-bg'} />
    </>
  );
}

export default PostedJob;
