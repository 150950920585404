//  @copyright : ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
// @author     : Shiv Charan Panjeta

// All Rights Reserved.
// Proprietary and confidential :  All information contained herein is, and remains
// the property of ToXSL Technologies Pvt. Ltd. and its partners.
// Unauthorized copying of this file, via any medium is strictly prohibited.
import { useEffect, useState } from 'react';
import { Button, Container } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Select from 'react-select';
import useDocumentTitle from '../../globals/useDocumentTitle';
import { getInstituteDropdown } from '../../services/AuthServices';

export default function AddPrivilegeCard() {
  useDocumentTitle('Add Privilege Code Record | AlmaBay');
  const [instituteList, setInstituteList] = useState([]);
  const [selectedIns, setSelectedIns] = useState('');

  useEffect(() => {
    getInstituteDropdownData();
  }, []);

  const getInstituteDropdownData = () => {
    getInstituteDropdown().then((res) => {
      let arr = [];
      if (res?.status === 200) {
        let data = res?.data?.data?.allInstitutes;
        for (let i in data) {
          arr.push({ id: i, label: data[i], name: data[i] });
        }
      }
      setInstituteList(arr);
    });
  };

  return (
    <div className="dash-main-content">
      <Container fluid>
        <div className="dash-page-content">
          <div className="dash-page-title">
            <Row>
              <Col xs={12} sm={12} md={12}>
                <div className="dash-title-bar d-flex align-items-center justify-content-between">
                  <div className="title-lft d-flex align-items-center">
                    <div className="dash-icn">
                      <i className="fas fa-briefcase"></i>
                    </div>
                    <div className="dash-title-dis">
                      <h5>
                        Privilege Card Management <span> Panel </span> | Add
                      </h5>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <div className="dash-content-card">
            <div className="cmmn-form">
              <Form>
                <Row>
                  <Col xs={12} md={12} lg={12}>
                    <Form.Group className="mb-3">
                      <Form.Label>
                        Select Intitute:{' '}
                        <span>
                          <i className="fas fa-info-circle"></i>
                        </span>
                      </Form.Label>
                      <Select
                        className="basic-single mb-3"
                        placeholder="- Select institute - "
                        isSearchable={true}
                        name="institute_name"
                        options={instituteList}
                        getOptionValue={(option) => option.id}
                        value={selectedIns}
                        onChange={(e) => {
                          setSelectedIns(e);
                        }}
                      />
                    </Form.Group>
                  </Col>

                  <Col md={12}>
                    <Form.Group className="mb-3" controlId="formBasicName">
                      <Form.Label>Number of Cards : </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Please enter how many cards to generate."
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <div className="form-btns d-flex align-items-center mt-3">
                  <Button className="main-btn-grn">Save</Button>
                  <strong>OR</strong>
                  <Button className="main-btn-red">Cancel</Button>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}
