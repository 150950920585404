/**
@copyright  :   ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
@author     :   Shiv Charan Panjeta 
 
All Rights Reserved.
Proprietary and confidential :  All information contained here in is, and remains
the property of ToXSL Technologies Pvt. Ltd. and it's partners.
Unauthorized copying of this file, via any medium is strictly prohibited.
*/
import { useEffect, useState } from 'react';
import { Button, Container } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import AsyncSelect from 'react-select/async';
import Loader from '../../components/commoncomponents/loader/loader-large';
import useDocumentTitle from '../../globals/useDocumentTitle';
import * as adminServices from '../../services/AuthServices';
import showNotification from '../../services/NotificationService';

export default function FeaturedCompanies() {
  useDocumentTitle('Add New Featured Company | AlmaBay');
  const [selectedTagList, setSelectedTagList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [inputMessage, setInputMessage] = useState('Please enter atleast 2 characters');
  const [loadOpt, setLoadOpt] = useState(false);
  useEffect(() => {
    getFeaturedCompany();
  }, []);

  const getFeaturedCompany = () => {
    setLoading(true);
    adminServices.getCompanyTagList().then((res) => {
      setLoading(false);
      if (res?.status == 200) {
        let temp = [];
        res?.data?.data?.company_list?.map((data) => {
          temp.push({
            label: data?.text,
            value: data?.id
          });
        });
        setSelectedTagList(temp);
      }
    });
  };

  const submitHandler = (e) => {
    e.preventDefault();
    let body = {
      searchCompany: selectedTagList.map((data) => data?.value).join()
    };
    setLoading(true);
    adminServices.addCompanyTagList(body).then((res) => {
      setLoading(false);
      if (res?.status == 200) {
        showNotification('success', res?.data?.message);
      }
    });
  };

  const loadOptions = async (inputValue) => {
    if (inputValue?.length >= 2) {
      setLoadOpt(true);

      return adminServices.getTagsDropDown(inputValue).then((res) => {
        setLoadOpt(false);
        if (res?.status === 200) {
          let tempArr = [];
          if (res?.data?.data) {
            const data = res?.data?.data;
            data?.map((data) => {
              tempArr.push({
                label: data?.text,
                value: data?.id
              });
            });
          }
          if (res?.data?.data == '') {
            setInputMessage('No matches found ');
          }
          return tempArr;
        }
      });
    }
  };

  const inputMessageHandler = (e) => {
    if (e?.length !== 2 && e?.length < 2) {
      setInputMessage('Please enter atleast 2 characters.');
    }
  };
  return (
    <>
      <div className="dash-main-content">
        <Container fluid>
          <div className="dash-page-content">
            <div className="dash-page-title">
              <Row>
                <Col xs={12} sm={12} md={12}>
                  <div className="dash-title-bar d-flex align-items-center justify-content-between">
                    <div className="title-lft d-flex align-items-center">
                      <div className="dash-title-dis">
                        <h5>
                          Company <span>Listing</span>{' '}
                        </h5>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="dash-content-card">
              <div className="cmmn-form">
                <Form>
                  <Row>
                    <Col xs={12} sm={12} md={12}>
                      <Form.Group className="mb-3" controlId="formBasicPassword">
                        <Form.Label>Companies</Form.Label>
                        <AsyncSelect
                          className="basic-single mt-3 mb-3"
                          classNamePrefix="select"
                          name="searchCompany"
                          onChange={(e) => {
                            setSelectedTagList(e);
                          }}
                          isClearable={false}
                          isMulti
                          loadOptions={loadOptions}
                          value={selectedTagList}
                          id="company_id"
                          components={{
                            DropdownIndicator: () => null,
                            IndicatorSeparator: () => null,
                            LoadingIndicator: () => null
                          }}
                          noOptionsMessage={() => inputMessage}
                          onInputChange={(e) => {
                            inputMessageHandler(e);
                          }}
                        />
                        {loadOpt && (
                          <div
                            className="d-flex"
                            style={{
                              justifyContent: 'flex-end',
                              position: 'absolute',
                              right: '58px',
                              top: '215px'
                            }}>
                            <i className="fa fa-spinner fa-pulse me-2"></i>
                          </div>
                        )}
                      </Form.Group>
                    </Col>
                  </Row>
                  <button
                    type="button"
                    className="green_button_admin"
                    onClick={(e) => submitHandler(e)}>
                    Save
                  </button>
                </Form>
              </div>
            </div>
          </div>
        </Container>
      </div>
      {loading ? <Loader /> : ''}
    </>
  );
}
