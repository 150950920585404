/**  @copyright : ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
@author     : Shiv Charan Panjeta < shiv@toxsl.com
All Rights Reserved.
Proprietary and confidential :  All information contained herein is, and remains
the property of ToXSL Technologies Pvt. Ltd. and its partners.
Unauthorized copying of this file, via any medium is strictly prohibited.
**/
import { useEffect, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import { getYearDropDown } from '../../../globals/helper';
import useDocumentTitle from '../../../globals/useDocumentTitle';
import {
  getCourseOpt,
  getDepartmentOpt,
  instituteRegister
} from '../../../services/InstituteServices';
import { citiesDropDown } from '../../../services/UserServices';

function InstituteRegister() {
  useDocumentTitle('Register Page');
  const navigate = useNavigate();
  const [loginDetails, setLoginDetails] = useState({
    first_name: '',
    last_name: '',
    email: '',
    password: '',
    department_id: '',
    course_id: '',
    reg_id: '',
    to_year: '',
    type: '',
    current_city: ''
  });
  const [checkCred, setCheckCred] = useState(false);
  const [departmentId, setDepartmentId] = useState('');
  const [locationID, setLocationID] = useState('');
  const [courseList, setCourseList] = useState([]);
  const [typeList] = useState([
    {
      label: 'Student',
      value: 'student'
    },
    {
      label: 'Alumnus',
      value: 'alumni'
    },
    {
      label: 'Faculty',
      value: 'faculty'
    }
  ]);
  const [courseId, setCourseId] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [yearOptions, setYearOptions] = useState();

  useEffect(() => {
    getYearDropDown(setYearOptions);
  }, []);

  //load-options to get department list under institute
  const loadDepartmentOption = () => {
    return getDepartmentOpt().then((res) => {
      if (res?.status === 200) {
        let tempArr = [];
        if (res?.data?.data) {
          const data = res?.data?.data;
          data?.map((data) => {
            tempArr.push({ id: data?.institute_id, label: data?.name, value: data?.name });
          });
        }
        return tempArr;
      }
    });
  };

  const getCourseDropDown = (id) => {
    getCourseOpt(id).then((res) => {
      if (res?.status === 200) {
        let list = res?.data?.data;
        let arr = [];
        list?.map((data) => {
          arr.push({ id: data?.program_id, label: data?.program_name, value: data?.program_name });
        });
        setCourseList(arr);
      }
    });
  };

  //validation for email and password
  const validate = (values) => {
    let errors = {};
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    if (!values.email) {
      errors.email = 'Please enter your email';
    } else if (!regex.test(values.email)) {
      errors.email = 'Invalid email format';
    }
    if (!values.first_name?.trim()) {
      errors.first_name = 'Please enter your first name';
    }
    if (!values.current_city) {
      errors.current_city = 'Please enter your location';
    }
    if (!values.last_name?.trim()) {
      errors.last_name = 'Please enter your last name';
    }
    if (!values.course_id) {
      errors.course_id = 'Please select course';
    }
    if (!values.department_id) {
      errors.department_id = 'Please enter department';
    }
    if (!values.reg_id?.trim()) {
      errors.reg_id = 'Please enter registration';
    }
    if (values?.type && values?.type != 'student' && !values.to_year) {
      errors.to_year = 'Please enter Passout year';
    }
    if (!values.password?.trim()) {
      errors.password = 'Please enter your password';
    }
    if (values.password?.length < 8) {
      errors.password = 'Password must be 8 charachters long';
    }
    if (!confirmPassword?.trim()) {
      errors.confirmPassword = 'Please enter your confirm-password';
    }
    if (confirmPassword && values.password != confirmPassword) {
      errors.confirmPassword = 'confirm password must be same as password';
    }
    return errors;
  };

  //input-value
  const onChangeHandler = (e) => {
    const { name, value } = e.target;
    setLoginDetails({ ...loginDetails, [name]: value });
  };

  // onClick form submit-api call
  const submitHandler = (e) => {
    e.preventDefault();
    setCheckCred(true);
    if (!Object.keys(validate(loginDetails)).length) {
      instituteRegister(loginDetails).then((res) => {
        if (res?.status === 200 && res?.data?.data) {
          navigate('/institute-register/step/1/' + res?.data?.data);
          // navigate('/');
        }
      });
    }
  };

  const loadLocationOptions = (inputValue) => {
    return citiesDropDown(inputValue).then((res) => {
      if (res?.status === 200) {
        let tempArr = [];
        if (res?.data?.data) {
          const data = res?.data?.data;
          data?.map((data) => {
            tempArr.push({
              id: data?.city_id,
              label: data?.city,
              value: data?.city
            });
          });
        }
        return tempArr;
      }
    });
  };

  return (
    <div className="wrappermain_outer">
      <div className="h-100 d-flex justify-content-center align-items-center pt-5 pb-5 mt-5">
        {/* form start */}
        <Card className="square border d-flex institute register col-md-5 col-lg-4 mx-auto">
          <Card.Body>
            <Card.Title className="loginhead mb-3">Join</Card.Title>
            <div className="inst-login-frm">
              <Form>
                <Row>
                  <Col xs={12} sm={6} md={6} lg={6}>
                    <Form.Group className="mb-4" controlId="formBasicEmail">
                      <Form.Control
                        type="email"
                        placeholder="First Name"
                        name="first_name"
                        value={loginDetails?.first_name}
                        onChange={(e) => {
                          onChangeHandler(e);
                        }}
                        isInvalid={checkCred && validate(loginDetails)?.first_name}
                        autoComplete="off"
                      />
                      {checkCred && validate(loginDetails)?.first_name ? (
                        <span
                          className={
                            `${checkCred && validate(loginDetails)?.first_name}`
                              ? 'danger-frm'
                              : 'd-none'
                          }>
                          {checkCred && validate(loginDetails)?.first_name}
                        </span>
                      ) : (
                        ''
                      )}
                    </Form.Group>
                  </Col>
                  <Col xs={12} sm={6} md={6} lg={6}>
                    <Form.Group className="mb-4" controlId="formBasicPassword">
                      <Form.Control
                        type="text"
                        placeholder="Last name"
                        name="last_name"
                        onChange={(e) => {
                          onChangeHandler(e);
                        }}
                        isInvalid={checkCred && validate(loginDetails)?.last_name}
                      />
                      {checkCred && validate(loginDetails)?.last_name ? (
                        <span
                          className={
                            `${checkCred && validate(loginDetails)?.last_name}`
                              ? 'danger-frm'
                              : 'd-none'
                          }>
                          {checkCred && validate(loginDetails)?.last_name}
                        </span>
                      ) : (
                        ''
                      )}
                    </Form.Group>
                  </Col>
                </Row>
                <Form.Group className="mb-4" controlId="formBasicEmail">
                  <Form.Control
                    type="email"
                    placeholder="Email address"
                    name="email"
                    value={loginDetails?.email}
                    onChange={(e) => {
                      onChangeHandler(e);
                    }}
                    isInvalid={checkCred && validate(loginDetails)?.email}
                    autoComplete="off"
                  />
                  {checkCred && validate(loginDetails)?.email ? (
                    <span
                      className={
                        `${checkCred && validate(loginDetails)?.email}` ? 'danger-frm' : 'd-none'
                      }>
                      {checkCred && validate(loginDetails)?.email}
                    </span>
                  ) : (
                    ''
                  )}
                </Form.Group>

                <Form.Group className="mb-4" controlId="formBasicName">
                  <AsyncSelect
                    isSearchable={true}
                    loadOptions={loadLocationOptions}
                    className="basic-single mt-1"
                    name="current_city"
                    value={locationID}
                    onChange={(e) => {
                      setLoginDetails({
                        ...loginDetails,
                        ['current_city']: e?.id
                      });
                      setLocationID(e);
                    }}
                    placeholder="Select Current location"
                  />
                </Form.Group>

                {/* i am */}
                <Form.Group className="mb-4 " controlId="formBasicName">
                  <Select
                    name="type"
                    onChange={(e) => {
                      setLoginDetails({ ...loginDetails, ['type']: e.value });
                    }}
                    placeholder="I am a"
                    options={typeList}
                  />
                </Form.Group>

                {/* changes */}
                <Form.Group className="mb-4" controlId="formBasicName">
                  <AsyncSelect
                    isSearchable={true}
                    name="department_id"
                    value={departmentId}
                    onChange={(e) => {
                      setDepartmentId(e);
                      setLoginDetails({ ...loginDetails, ['department_id']: e.id });
                      getCourseDropDown(e.id);
                    }}
                    placeholder="Select Department"
                    loadOptions={loadDepartmentOption}
                  />
                  {checkCred && validate(loginDetails)?.department_id ? (
                    <span
                      className={
                        `${checkCred && validate(loginDetails)?.department_id}`
                          ? 'danger-frm'
                          : 'd-none'
                      }>
                      {checkCred && validate(loginDetails)?.department_id}
                    </span>
                  ) : (
                    ''
                  )}
                </Form.Group>

                {departmentId ? (
                  <Form.Group className="mb-4" controlId="formBasicName">
                    <Select
                      isSearchable={true}
                      name="course_id"
                      value={courseId}
                      onChange={(e) => {
                        setCourseId(e);
                        setLoginDetails({ ...loginDetails, ['course_id']: e.id });
                      }}
                      placeholder="Select Course"
                      options={courseList}
                    />
                    {checkCred && validate(loginDetails)?.course_id ? (
                      <span
                        className={
                          `${checkCred && validate(loginDetails)?.course_id}`
                            ? 'danger-frm'
                            : 'd-none'
                        }>
                        {checkCred && validate(loginDetails)?.course_id}
                      </span>
                    ) : (
                      ''
                    )}
                  </Form.Group>
                ) : (
                  <></>
                )}
                {loginDetails?.type && loginDetails?.type != 'student' ? (
                  <Form.Group className="mb-4" controlId="formBasicEmail">
                    <Select
                      classNamePrefix="select"
                      isSearchable={true}
                      options={yearOptions}
                      placeholder="Enter Passout Year"
                      name="to_year"
                      onChange={(e) => {
                        setLoginDetails({ ...loginDetails, ['to_year']: e.label });
                      }}
                    />
                    {checkCred && validate(loginDetails)?.to_year ? (
                      <span
                        className={
                          `${checkCred && validate(loginDetails)?.to_year}`
                            ? 'danger-frm'
                            : 'd-none'
                        }>
                        {checkCred && validate(loginDetails)?.to_year}
                      </span>
                    ) : (
                      ''
                    )}
                  </Form.Group>
                ) : (
                  <></>
                )}

                <Form.Group className="mb-4" controlId="formBasicEmail">
                  <Form.Control
                    type="text"
                    placeholder="Enter Registration Number "
                    name="reg_id"
                    value={loginDetails?.reg_id}
                    onChange={(e) => {
                      onChangeHandler(e);
                    }}
                    isInvalid={checkCred && validate(loginDetails)?.reg_id}
                    autoComplete="off"
                  />
                  {checkCred && validate(loginDetails)?.reg_id ? (
                    <span
                      className={
                        `${checkCred && validate(loginDetails)?.reg_id}` ? 'danger-frm' : 'd-none'
                      }>
                      {checkCred && validate(loginDetails)?.reg_id}
                    </span>
                  ) : (
                    ''
                  )}
                </Form.Group>

                <Form.Group className="mb-4" controlId="formBasicEmail">
                  <Form.Control
                    type="password"
                    placeholder="Password (8 or more characters)"
                    name="password"
                    value={loginDetails?.password}
                    onChange={(e) => {
                      onChangeHandler(e);
                    }}
                    isInvalid={checkCred && validate(loginDetails)?.password}
                    autoComplete="off"
                  />
                  {checkCred && validate(loginDetails)?.password ? (
                    <span
                      className={
                        `${checkCred && validate(loginDetails)?.password}` ? 'danger-frm' : 'd-none'
                      }>
                      {checkCred && validate(loginDetails)?.password}
                    </span>
                  ) : (
                    ''
                  )}
                </Form.Group>

                <Form.Group className="mb-4" controlId="formBasicEmail">
                  <Form.Control
                    type="password"
                    placeholder="Confirm Password (8 or more characters)"
                    name="password"
                    onChange={(e) => {
                      setConfirmPassword(e.target.value);
                    }}
                    isInvalid={checkCred && validate(loginDetails)?.confirmPassword}
                    autoComplete="off"
                  />
                  {checkCred && validate(loginDetails)?.confirmPassword ? (
                    <span
                      className={
                        `${checkCred && validate(loginDetails)?.confirmPassword}`
                          ? 'danger-frm'
                          : 'd-none'
                      }>
                      {checkCred && validate(loginDetails)?.confirmPassword}
                    </span>
                  ) : (
                    ''
                  )}
                </Form.Group>

                <Button
                  style={{ width: '100%' }}
                  className={`${checkCred && validate(loginDetails)?.password && validate(loginDetails)?.email
                    ? 'blk'
                    : ' '
                    } instit-login-btn mt-2`}
                  variant="primary"
                  type="button"
                  onClick={submitHandler}>
                  Register
                </Button>
                <Button
                  style={{ width: '100%' }}
                  className={`${checkCred && validate(loginDetails)?.password && validate(loginDetails)?.email
                    ? 'blk'
                    : ' '
                    } instit-login-btn mt-2`}
                  variant="light"
                  type="button"
                  onClick={() => {
                    navigate('/institute-login');
                  }}>
                  Login
                </Button>
              </Form>
            </div>
          </Card.Body>
        </Card>
        {/* end */}
      </div>
    </div>
  );
}

export default InstituteRegister;
