import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import HeaderLogin from '../../commoncomponents/HeaderLogin';
import FooterMain from '../../commoncomponents/FooterMain';
import TopTabbings from './TopTabbings';
import NewsSidebar from '../../commoncomponents/newsfeeds/NewsSidebar';
import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import useDocumentTitle from '../../../globals/useDocumentTitle';

import { getInstituteDrop } from '../../../services/AuthServices';
import DefaultLogo from '../../../assets/images/institute-dummy.jpg';
import AsyncSelect from 'react-select/async';
import showNotification from '../../../services/NotificationService';
import {
  addFriend,
  followFriend,
  leaveGroupFromWidget,
  searchCompanyPages,
  searchGroups,
  searchPages,
  searchPeople
} from '../../../services/UserServices';
import { Popover } from 'react-bootstrap';
import { OverlayTrigger } from 'react-bootstrap';
import { PageLikeUnLike, socialGroupJoin } from '../../../services/SocialServices';
import { FollowCompany, unFollowCompany } from '../../../services/CompanyServices';
import { useSearchParams } from 'react-router-dom';
import Loader from '../../commoncomponents/loader/loader-large';

function SearchPage() {
  useDocumentTitle('Browse Search | AlmaBay');

  const [instituteId, setInstituteId] = useState();
  const [groupSearch, setGroupSearch] = useState('');
  const [pageSearch, setPageSearch] = useState('');
  const [companySearch, setCompanySearch] = useState('');
  const [peopleSearch, setPeopleSearch] = useState('');
  const [groupFind, setGroupFind] = useState([]);
  const [pageFind, setPageFind] = useState([]);
  const [companyFind, setCompanyFind] = useState([]);
  const [peopleFind, setPeopleFind] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const search = searchParams.get('search');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    loadInstituteOptions();
    if (search) {
      setGroupSearch(search);
      callGroupSearch();
    }
  }, []);

  useEffect(() => {
    if (search) {
      setPageSearch(search);
    }
  }, []);

  useEffect(() => {
    if (search) {
      setCompanySearch(search);
    }
  }, []);

  useEffect(() => {
    if (search) {
      setPeopleSearch(search);
    }
  }, []);

  const callGroupSearch = (instiId) => {
    let body = {
      offset: 0,
      limit: 20,
      search_group: groupSearch ? groupSearch : search,
      search_college: instiId ? instiId : instituteId?.id
    };
    searchGroups(body).then((res) => {
      if (res?.status === 200) {
        setGroupFind(res?.data?.detail);
      }
    });
  };

  const callPageSearch = () => {
    let body = {
      offset: 0,
      limit: 20,
      search_page: pageSearch
    };
    searchPages(body).then((res) => {
      if (res?.status === 200) {
        setPageFind(res?.data?.detail ?? []);
      }
    });
  };
  const callCompanySearch = () => {
    let body = {
      offset: 0,
      limit: 20,
      search_company_page: companySearch
    };
    searchCompanyPages(body).then((res) => {
      if (res?.status === 200) {
        setCompanyFind(res?.data?.detail ?? []);
      }
    });
  };
  const callPeopleSearch = () => {
    let body = {
      offset: 0,
      limit: 20,
      search_people: peopleSearch
    };
    searchPeople(body).then((res) => {
      if (res?.status === 200) {
        setPeopleFind(res?.data?.detail ?? []);
      }
    });
  };

  const loadInstituteOptions = (inputValue) => {
    return getInstituteDrop(inputValue).then((res) => {
      if (res?.status === 200) {
        let tempArr = [];
        if (res?.data?.length) {
          const data = res?.data;
          data?.map((data) => {
            tempArr.push({
              id: data?.institute_id,
              label: data?.institutename,
              value: data?.institutename,
              logo: data?.logo
                ? process.env.REACT_APP_API_DOMAIN + '/common/uploads/institute_logo/' + data?.logo
                : DefaultLogo
            });
          });
        }
        return tempArr;
      }
    });
  };

  const handleGroupSearch = () => {
    if (groupSearch !== '') {
      callGroupSearch(instituteId?.id);
    } else {
      showNotification('danger', 'Please write something to make search.');
    }
  };
  const handlePageSearch = () => {
    if (pageSearch !== '') {
      callPageSearch();
    } else {
      showNotification('danger', 'Please write something to make search.');
    }
  };
  const handleCompanySearch = () => {
    if (companySearch !== '') {
      callCompanySearch();
    } else {
      showNotification('danger', 'Please write something to make search.');
    }
  };

  const handlePeopleSearch = () => {
    if (peopleSearch !== '') {
      callPeopleSearch();
    } else {
      showNotification('danger', 'Please write something to make search.');
    }
  };

  const renderPagePopover = (props) => {
    let popOverData = props?.popper?.state?.options?.page;
    return (
      <Popover className="group-loader-card" id={`popover-basic`} {...props}>
        {/* <Popover.Header as="h3">Popover right</Popover.Header> */}
        <Popover.Body>
          <div className="loader-tooltip ">
            <div
              className="loader-cover"
              style={{ position: 'relative', padding: 0, margin: 0, top: 0, left: 0 }}>
              <img
                src={
                  popOverData?.url
                    ? process.env.REACT_APP_API_URL +
                    popOverData?.url +
                    '.' +
                    popOverData?.extension
                    : require('../../../assets/images/default-male-avatar.png')
                }
                alt="img"
                className="loader-image"
              />
            </div>
            <div className="page-loader-head-sec d-flex align-items-top p-3">
              <div className="people-icn me-3">
                <img
                  src={
                    popOverData?.url
                      ? process.env.REACT_APP_API_URL +
                      popOverData?.url +
                      '.' +
                      popOverData?.extension
                      : require('../../../assets/images/default-male-avatar.png')
                  }
                  alt="img"
                />
              </div>
              <div className="page-loader-hd">
                <h3>{popOverData?.name}</h3>
                <div className="loader-like-sec">
                  <p>
                    <i className="fas fa-thumbs-up me-2"></i>Likes<span>{popOverData?.likes}</span>
                  </p>
                  <p>{popOverData?.category_name}</p>
                  <p>{popOverData?.about}</p>
                </div>
              </div>
            </div>
          </div>
        </Popover.Body>
      </Popover>
    );
  };

  const renderUserPopover = (props) => {
    let popOverData = props?.popper?.state?.options?.people;
    return (
      <Popover className="group-loader-card" id={`popover-basic`} {...props}>
        {/* <Popover.Header as="h3">Popover right</Popover.Header> */}
        <Popover.Body>
          <div className="loader-tooltip">
            <div
              className="loader-cover"
              style={{ position: 'relative', padding: 0, margin: 0, top: 0, left: 0 }}>
              <img
                src={
                  popOverData?.url
                    ? process.env.REACT_APP_API_URL +
                    popOverData?.url +
                    '.' +
                    popOverData?.extension
                    : require('../../../assets/images/default-male-avatar.png')
                }
                alt="img"
                className="loader-image"
              />
            </div>
          </div>
          <div className="page-loader-head-sec d-flex align-items-top p-3">
            <div className="people-icn me-3">
              <img
                src={
                  popOverData?.url
                    ? process.env.REACT_APP_API_URL +
                    popOverData?.url +
                    '.' +
                    popOverData?.extension
                    : require('../../../assets/images/default-male-avatar.png')
                }
                alt="img"
                className="loader-image-small"
              />
            </div>
          </div>
          <div className="people-knw-dis-popover">
            {/* <p>
                <i className="fas fa-graduation-cap me-1"></i> Studied at{' '}
                <Link to="#">SD COLLEGE Chandigarh</Link> in <Link to="#">2005</Link>
              </p> */}

            {popOverData?.designation && (
              <p>
                <i className="fas fa-briefcase me-1"></i>Worked as
                <Link to="#">{popOverData?.designation}</Link> {' at '}
                <Link to="#">{popOverData?.organisation}</Link>
              </p>
            )}
            <div className="popover-inr-btn">
              {/* <button
                className="popovr-blk-btn me-1"
                // onClick={(e) =>
                //   addFriendHandle(e, popOverData?.ID, getPeopleYouKNowData, setIsLoading)
                // }
              >
                Add Friend
              </button> */}
              {popOverData?.active == '' || popOverData?.active == null ? (
                <Button
                  className="popovr-blk-btn me-1"
                  onClick={(e) => requestHandler(e, popOverData?.id, 'friend')}>
                  Add Friend
                </Button>
              ) : popOverData?.active == 0 ? (
                <>
                  {' '}
                  <Button
                    className="wht-btn"
                    onClick={(e) => requestHandler(e, popOverData?.id, 'friend', 'requested')}>
                    Requested
                  </Button>
                </>
              ) : (
                <Button
                  className="popovr-blk-btn me-1"
                  onClick={(e) => requestHandler(e, popOverData?.id, 'friend', 'unfriend')}>
                  Unfriend
                </Button>
              )}
              {/* <button className="popovr-yellow-btn">Follow Mentor</button> */}
            </div>
          </div>
        </Popover.Body>
      </Popover>
    );
  };

  const leaveGroupsJoined = (e, id) => {
    setLoading(true);
    e.preventDefault();
    let body = {
      following_id: id
    };
    let formData = new FormData();
    for (let i in body) {
      formData.append(`${i}`, body[i]);
    }
    if (confirm('Do you want to leave from group?') == true) {
      leaveGroupFromWidget(formData).then((res) => {
        if (res?.status === 200) {
          setLoading(false);
          callGroupSearch();
        } else {
          showNotification('success', res?.data?.message);
        }
      });
    }
  };

  const handleJoinGroup = (e, id) => {
    setLoading(true);
    e.preventDefault();
    let body = {
      following_id: id
    };
    let formData = new FormData();
    for (let i in body) {
      formData.append(`${i}`, body[i]);
    }
    socialGroupJoin(formData).then((res) => {
      if (res?.status === 200) {
        callGroupSearch();
        setLoading(false);
      } else {
        showNotification('success', res?.data?.message);
      }
    });
  };

  const LikeDisLikePage = (e, id) => {
    setLoading(true);
    e.preventDefault();
    if (confirm('Do you want to like page?') == true) {
      let body = {
        following_id: id
      };
      let formData = new FormData();
      for (let i in body) {
        formData.append(`${i}`, body[i]);
      }
      PageLikeUnLike(formData).then((res) => {
        if (res?.status === 200) {
          setLoading(false);
          callPageSearch();
        } else {
          showNotification('success', 'Page liked successfully');
        }
      });
    }
  };
  const followCompanyPage = (e, id) => {
    e.preventDefault();
    setLoading(true);
    let body = {
      following_id: id
    };
    let formData = new FormData();
    for (let i in body) {
      formData.append(`${i}`, body[i]);
    }
    FollowCompany(formData).then((res) => {
      if (res?.status === 200) {
        callCompanySearch();
        setLoading(false);
      } else {
        showNotification('success', res?.data?.message);
      }
    });
  };
  // unFollowCompany
  const unFollowCompanyPage = (e, id) => {
    e.preventDefault();
    setLoading(true);
    let body = {
      following_id: id
    };
    let formData = new FormData();
    for (let i in body) {
      formData.append(`${i}`, body[i]);
    }
    unFollowCompany(formData).then((res) => {
      if (res?.status === 200) {
        callCompanySearch();
        setLoading(false);
      } else {
        showNotification('success', res?.data?.message);
      }
    });
  };

  const requestHandler = (e, id, type, unfriend) => {
    e.preventDefault();
    setLoading(true);
    if (type == 'friend') {
      let body = {
        following_id: id
      };
      if (unfriend) {
        if (
          confirm(
            unfriend == 'requested'
              ? 'Do you want to remove request ?'
              : 'Do you want to remove friend ?'
          ) === true
        ) {
          addFriend(body).then((res) => {
            if (res?.status == 200) {
              setLoading(false);
              callPeopleSearch();
            } else {
              setLoading(false);
              showNotification('success', res?.data?.message);
            }
          });
        }
      } else {
        addFriend(body).then((res) => {
          if (res?.status == 200) {
            setLoading(false);
            callPeopleSearch();
          } else {
            setLoading(false);
            showNotification('success', res?.data?.message);
          }
        });
      }
    } else {
      let body = {
        following_id: id
      };
      followFriend(body).then((res) => {
        if (res?.status == 200) {
          setLoading(false);
          callPeopleSearch();
        } else {
          setLoading(false);
          showNotification('success', res?.data?.message);
        }
      });
    }
  };

  return (
    <>
      <HeaderLogin />
      <div className="header-lnk-feeds">
        <div className="container">
          <TopTabbings state={'social'} />
        </div>
      </div>
      <section className="feed-sec bg-grey py-4">
        <div className="container">
          <Row>
            <Col xs={12} md={12} lg={2}>
              <NewsSidebar state="1" current="search" />
            </Col>
            <Col xs={12} md={12} lg={10}>
              <div className="search-page p-3 bg-white">
                <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                  <Row>
                    <Col sm={12}>
                      <Nav variant="pills" className="mb-4">
                        <Nav.Item>
                          <Nav.Link eventKey="first">
                            <i className="fas fa-users me-2"></i>Groups
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link
                            onClick={() => {
                              if (search) {
                                callPageSearch();
                              }
                            }}
                            eventKey="second">
                            <i className="fas fa-file-alt me-2"></i>Pages
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link
                            onClick={() => {
                              if (search) {
                                callCompanySearch();
                              }
                            }}
                            eventKey="third">
                            <i className="fas fa-university me-2"></i>Company Pages
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link
                            onClick={() => {
                              if (search) {
                                callPeopleSearch();
                              }
                            }}
                            eventKey="fourth">
                            <i className="fas fa-users me-2"></i> People
                          </Nav.Link>
                        </Nav.Item>
                      </Nav>
                    </Col>
                    <Col sm={12}>
                      <Tab.Content>
                        <Tab.Pane eventKey="first">
                          <div className="py-2 px-3">
                            <div className="serach-hd">
                              <h3>Find Groups</h3>
                              <p>Join new groups to grow your network</p>
                            </div>
                            <div className="search-frm-sec">
                              <Row>
                                <Col md={6}>
                                  <Form.Group className="mb-3" controlId="formBasicSearch">
                                    <Form.Label>Find by group name</Form.Label>
                                    <div className="search-card">
                                      <Form.Control
                                        type="Search"
                                        placeholder="Search Groups .."
                                        value={groupSearch}
                                        onChange={(e) => setGroupSearch(e.target.value)}
                                      />
                                      <Button className="search-btn" onClick={handleGroupSearch}>
                                        <i className="fas fa-search"></i>
                                      </Button>
                                    </div>
                                  </Form.Group>
                                </Col>
                                <Col md={6}>
                                  <Form.Group
                                    className="mb-3 inst-name-lst"
                                    controlId="formBasicSearch">
                                    <Form.Label>Find by institute name</Form.Label>
                                    <AsyncSelect
                                      cacheOptions
                                      components={{
                                        DropdownIndicator: () => null,
                                        IndicatorSeparator: () => null
                                      }}
                                      className="basic-single"
                                      classNamePrefix="select"
                                      isSearchable={true}
                                      isClearable
                                      name="institute_id"
                                      loadOptions={loadInstituteOptions}
                                      placeholder="Type college name..."
                                      formatOptionLabel={(ins) => (
                                        <div className="ins-option">
                                          <img src={ins.logo} alt="logo" />
                                          <span>{ins.label}</span>
                                        </div>
                                      )}
                                      // options={options}
                                      value={instituteId}
                                      onChange={(e) => {
                                        callGroupSearch(e?.id);
                                        setInstituteId(e);
                                      }}
                                    // onCreateOption={(e) => {
                                    //   handleCreateIns(e);
                                    //   setEducationDetail({
                                    //     ...educationDetail,
                                    //     ['institute_id']: e
                                    //   });
                                    // }}
                                    />
                                  </Form.Group>
                                </Col>
                              </Row>
                            </div>
                            <div className="search-list-sec mt-3">
                              {groupFind?.length !== 0 ? (
                                <ul>
                                  {groupFind?.map((group, index) => {
                                    return (
                                      <li key={index}>
                                        <div className="search-ints-card d-flex justify-content-between align-items-center">
                                          <div className="d-flex align-items-center">
                                            <Link
                                              to={`/user/group-detail/?id=${group?.id}`}
                                              className="search-inst-icn me-3">
                                              <img
                                                src={require('../../../assets/images/default-male-avatar.png')}
                                                alt="img"
                                              />
                                            </Link>
                                            <div className="seach-ins-dis">
                                              <h5 title={group?.name}>{group?.name}</h5>
                                            </div>
                                          </div>
                                          <div>
                                            {group?.active == 1 ? (
                                              <Button
                                                className="wht-btn primary"
                                                onClick={(e) => leaveGroupsJoined(e, group?.id)}>
                                                Leave
                                              </Button>
                                            ) : group?.active == 0 ? (
                                              <Button
                                                className="wht-btn primary"
                                                onClick={(e) => leaveGroupsJoined(e, group?.id)}>
                                                Requested
                                              </Button>
                                            ) : (
                                              <Button
                                                className="wht-btn"
                                                onClick={(e) => handleJoinGroup(e, group?.id)}>
                                                Join
                                              </Button>
                                            )}
                                          </div>
                                        </div>
                                      </li>
                                    );
                                  })}
                                </ul>
                              ) : (
                                <></>
                              )}
                            </div>
                          </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="second">
                          <div className="py-2 px-3">
                            <div className="serach-hd">
                              <h3>Find Pages</h3>
                              <p>Join to grow your network</p>
                            </div>
                            <div className="search-frm-sec">
                              <Row>
                                <Col md={6}>
                                  <Form.Group className="mb-0" controlId="formBasicSearch">
                                    <div className="search-card">
                                      <Form.Control
                                        type="Search"
                                        placeholder="Search Pages .."
                                        defaultValue={search}
                                        onChange={(e) => setPageSearch(e.target.value)}
                                      />
                                      <Button className="search-btn" onClick={handlePageSearch}>
                                        <i className="fas fa-search"></i>
                                      </Button>
                                    </div>
                                  </Form.Group>
                                </Col>
                              </Row>
                            </div>
                            <div className="search-list-sec mt-3">
                              {pageFind?.length !== 0 ? (
                                <>
                                  <ul>
                                    {pageFind?.map((page, index) => {
                                      return (
                                        <li key={index}>
                                          <div className="search-ints-card d-flex justify-content-between align-items-center">
                                            <div className="d-flex align-items-center">
                                              <Link
                                                to={`/user/page/page-detail/${page?.encryptid}`}
                                                className="search-inst-icn me-3">
                                                <OverlayTrigger
                                                  trigger={['hover', 'focus']}
                                                  placement="top"
                                                  delay={{ show: 250, hide: 400 }}
                                                  data={page}
                                                  popperConfig={{
                                                    page
                                                  }}
                                                  shouldUpdatePosition={true}
                                                  overlay={renderPagePopover}>
                                                  <img
                                                    src={
                                                      page?.url
                                                        ? process.env.REACT_APP_API_URL +
                                                        page?.url +
                                                        '.' +
                                                        page?.extension
                                                        : require('../../../assets/images/default-male-avatar.png')
                                                    }
                                                    alt="img"
                                                  />
                                                </OverlayTrigger>
                                              </Link>
                                              <div className="seach-ins-dis">
                                                <Link
                                                  to={`/user/page/page-detail/${page?.encryptid}`}>
                                                  {' '}
                                                  <h5 title={page?.name}>{page?.name}</h5>
                                                </Link>
                                              </div>
                                            </div>
                                            <div>
                                              {page?.active == 1 ? (
                                                <Button
                                                  className="wht-btn"
                                                  onClick={(e) => LikeDisLikePage(e, page?.id)}>
                                                  Unlike
                                                </Button>
                                              ) : (
                                                <Button
                                                  className="wht-btn"
                                                  onClick={(e) => LikeDisLikePage(e, page?.id)}>
                                                  Like
                                                </Button>
                                              )}
                                            </div>
                                          </div>
                                        </li>
                                      );
                                    })}
                                  </ul>
                                </>
                              ) : (
                                <></>
                              )}
                            </div>
                          </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="third">
                          <div className="py-2 px-3">
                            <div className="serach-hd">
                              <h3>Find Company Pages</h3>
                              <p>Follow to get career opportunity</p>
                            </div>
                            <div className="search-frm-sec">
                              <Row>
                                <Col md={6}>
                                  <Form.Group className="mb-0" controlId="formBasicSearch">
                                    <div className="search-card">
                                      <Form.Control
                                        type="Search"
                                        placeholder="Search Company Pages .."
                                        defaultValue={search}
                                        onChange={(e) => setCompanySearch(e.target.value)}
                                      />
                                      <Button className="search-btn" onClick={handleCompanySearch}>
                                        <i className="fas fa-search"></i>
                                      </Button>
                                    </div>
                                  </Form.Group>
                                </Col>
                              </Row>
                            </div>
                            <div className="search-list-sec mt-3">
                              {companyFind?.length != 0 ? (
                                <>
                                  {' '}
                                  <ul>
                                    {companyFind?.map((company, index) => {
                                      return (
                                        <li key={index}>
                                          <div className="search-ints-card d-flex justify-content-between align-items-center">
                                            <div className="d-flex align-items-center">
                                              <Link to="#" className="search-inst-icn me-3">
                                                <img
                                                  src={require('../../../assets/images/default-male-avatar.png')}
                                                  alt="img"
                                                />
                                              </Link>
                                              <div className="seach-ins-dis">
                                                <h5 title={company?.name}>{company?.name}</h5>
                                              </div>
                                            </div>
                                            <div>
                                              {company?.active == 1 ? (
                                                <Button
                                                  className="wht-btn"
                                                  onClick={(e) =>
                                                    unFollowCompanyPage(e, company?.id)
                                                  }>
                                                  Unfollow
                                                </Button>
                                              ) : (
                                                <Button
                                                  className="wht-btn"
                                                  onClick={(e) =>
                                                    followCompanyPage(e, company?.id)
                                                  }>
                                                  Follow
                                                </Button>
                                              )}
                                            </div>
                                          </div>
                                        </li>
                                      );
                                    })}
                                  </ul>
                                </>
                              ) : (
                                <></>
                              )}
                            </div>
                          </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="fourth">
                          <div className="py-2 px-3">
                            <div className="serach-hd">
                              <h3>Find People</h3>
                              <p>Connect with new people</p>
                            </div>
                            <div className="search-frm-sec">
                              <Row>
                                <Col md={6}>
                                  <Form.Group className="mb-0" controlId="formBasicSearch">
                                    <div className="search-card">
                                      <Form.Control
                                        type="Search"
                                        placeholder="Write a name"
                                        defaultValue={search}
                                        onChange={(e) => setPeopleSearch(e.target.value)}
                                      />
                                      <Button className="search-btn" onClick={handlePeopleSearch}>
                                        <i className="fas fa-search"></i>
                                      </Button>
                                    </div>
                                  </Form.Group>
                                </Col>
                              </Row>
                            </div>
                            <div className="search-list-sec mt-3">
                              {peopleFind?.length !== 0 ? (
                                <>
                                  {' '}
                                  <ul>
                                    {peopleFind?.map((people, index) => {
                                      return (
                                        <li key={index}>
                                          <div className="search-ints-card d-flex justify-content-between align-items-center">
                                            <div className="d-flex align-items-center">
                                              <Link
                                                to={`/user/timeline/${people?.encryptid}`}
                                                className="search-inst-icn me-3">
                                                <OverlayTrigger
                                                  trigger={['hover', 'focus']}
                                                  placement="top"
                                                  delay={{ show: 250, hide: 400 }}
                                                  data={people}
                                                  popperConfig={{
                                                    people
                                                  }}
                                                  shouldUpdatePosition={true}
                                                  overlay={renderUserPopover}>
                                                  <img
                                                    src={
                                                      people?.url
                                                        ? process.env.REACT_APP_API_URL +
                                                        people?.url +
                                                        '.' +
                                                        people?.extension
                                                        : require('../../../assets/images/default-male-avatar.png')
                                                    }
                                                    alt="img"
                                                  />
                                                </OverlayTrigger>
                                              </Link>
                                              <div className="seach-ins-dis">
                                                <Link to={`/user/timeline/${people?.encryptid}`}>
                                                  <h5 title={people?.name}>{people?.name}</h5>
                                                </Link>
                                              </div>
                                            </div>
                                            <div>
                                              {people?.active == '' || people?.active == null ? (
                                                <Button
                                                  className="blu-gradient-btn"
                                                  onClick={(e) =>
                                                    requestHandler(e, people?.id, 'friend')
                                                  }>
                                                  Add Friend
                                                </Button>
                                              ) : people?.active == 0 ? (
                                                <>
                                                  {' '}
                                                  <Button
                                                    className="wht-btn"
                                                    onClick={(e) =>
                                                      requestHandler(
                                                        e,
                                                        people?.id,
                                                        'friend',
                                                        'requested'
                                                      )
                                                    }>
                                                    Requested
                                                  </Button>
                                                </>
                                              ) : (
                                                <Button
                                                  className="blu-gradient-btn"
                                                  onClick={(e) =>
                                                    requestHandler(
                                                      e,
                                                      people?.id,
                                                      'friend',
                                                      'unfriend'
                                                    )
                                                  }>
                                                  Unfriend
                                                </Button>
                                              )}
                                            </div>
                                          </div>
                                        </li>
                                      );
                                    })}
                                  </ul>
                                </>
                              ) : (
                                <></>
                              )}
                            </div>
                          </div>
                        </Tab.Pane>
                      </Tab.Content>
                    </Col>
                  </Row>
                </Tab.Container>
              </div>
            </Col>
          </Row>
        </div>
      </section>
      {loading && <Loader />}
    </>
  );
}

export default SearchPage;
