/**
 * @copyright : ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
@author    : Shiv Charan Panjeta 
 
All Rights Reserved.
Proprietary and confidential :  All information contained here in is, and remains
the property of ToXSL Technologies Pvt. Ltd. and it's partners.
Unauthorized copying of this file, via any medium is strictly prohibited.
 */

import { useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import HeaderLogin from '../../commoncomponents/HeaderLogin';
import TopTabbings from './TopTabbings';
import { useNavigate } from 'react-router-dom';
import SuggestMentor from '../../commoncomponents/newsfeeds/SuggestMentor';
import GroupDiscover from '../../commoncomponents/newsfeeds/GroupDiscover';
import WhatsNew from '../../commoncomponents/newsfeeds/WhatsNew';
import Upcoming from '../../commoncomponents/newsfeeds/Upcoming';
import NewsSidebar from '../../commoncomponents/newsfeeds/NewsSidebar';
import Form from 'react-bootstrap/Form';
import CKEditorComponent from './PostComponets/CKEditorComponent';
import showNotification from '../../../services/NotificationService';
import * as APISERVICE from '../../../services/SocialServices';
import Loader from '../../commoncomponents/loader/loader-large';
import useDocumentTitle from '../../../globals/useDocumentTitle';
import { MiniFooter } from '../../MiniFooter';
import AsyncSelect from 'react-select/async';
import { getInstituteDrop } from '../../../services/AuthServices';

function AddCampus() {
  useDocumentTitle('AlmaBay');
  const [instituteName, setInstituteName] = useState([]);
  const [instituteId, setInstituteId] = useState('');
  const [latestPost, setLatestPost] = useState('');
  const [title, setTitle] = useState('');
  const [attachment, setAttachMent] = useState('');
  const [instituteSearch, setInstituteSearch] = useState(null);
  const [laoding, setLoading] = useState(false);
  const navigate = useNavigate();
  const [clicked, setClicked] = useState(false);
  const [inputMessage, setInputMessage] = useState('Please enter atleast 2 characters');
  const [loadOpt, setLoadOpt] = useState(false);
  const [buttonLoader, setButtonLoader] = useState(false);

  const loadInstituteOptions = async (inputValue) => {
    if (inputValue?.length >= 2) {
      setLoadOpt(true);
      return getInstituteDrop(inputValue).then((res) => {
        setLoadOpt(false);
        if (res?.status === 200) {
          let tempArr = [];
          if (res?.data?.length) {
            const data = res?.data;
            data?.map((data) => {
              tempArr.push({
                id: data?.institute_id,
                label: data?.institutename,
                value: data?.institutename
              });
            });
          }
          if (res?.data == '') {
            setInputMessage('no matches found');
          }
          return tempArr;
        }
      });
    }
  };

  const validate = () => {
    let error = {};
    if (instituteId == '' || instituteId == null) {
      error.instituteId = 'Campus is required field.';
    }
    if (title == '') {
      error.title = 'Title is required field.';
    }
    if (latestPost == '') {
      error.latestPost =
        'You forgot to write your description, kindly write some thing in description.';
    }
    return error;
  };

  const fileValidator = (file) => {
    const extension = file?.type?.split('/')[1];
    const validExtension = [
      'doc',
      'docx',
      'rtf',
      'txt',
      'xls',
      'xlsx',
      'pdf',
      'jpg',
      'jpeg',
      'png',
      'ppt',
      'pptx'
    ];
    if (!validExtension.includes(extension)) {
      showNotification(
        'danger',
        `Sorry, ${file?.name} is invalid, allowed file formats are as follows: .doc, .docx, .rtf, .txt, .xls, .xlsx, .pdf, .jpg, .jpeg, .png, .ppt, .pptx`
      );
    } else {
      setAttachMent(file);
    }
  };

  const addCampus = (e) => {
    e.preventDefault();
    setClicked(true);
    if (!Object.keys(validate()).length) {
      setLoading(true);
      setButtonLoader(true);
      let formData = new FormData();
      formData.append(`title`, title);
      formData.append(`description`, latestPost);
      formData.append(`institute_id`, instituteId?.id);
      formData.append(`institute_logo`, instituteId?.value);
      formData.append(`attachment`, attachment);
      APISERVICE.addCampusNews(formData).then((res) => {
        if (res?.status === 200) {
          showNotification(
            'success',
            'News added successfully. We will do further processing. Thank you.'
          );
          setLatestPost('');
          setInstituteId('');
          setTitle('');
          setAttachMent('');
          navigate('/user/campus/news_listing');
          setLoading(false);
          setButtonLoader(false);
        } else {
          setLoading(false);
        }
      });
    } else {
      return;
    }
  };

  return (
    <>
      <HeaderLogin />
      <div className="header-lnk-feeds">
        <div className="container">
          <TopTabbings state={'social'} />
        </div>
      </div>
      <section className="news-feed-sec bg-grey py-4">
        <Container>
          <Row>
            <Col xs={12} md={2}>
              <NewsSidebar state="1" current="add-news" />
            </Col>
            <Col xs={12} md={7}>
              <div className="write-article-frm bg-white p-3">
                <h4 className="mb-3">Add Campus News</h4>
                <Form.Group className="mb-3 spinner-wrapper ">
                  <AsyncSelect
                    cacheOptions
                    components={{
                      LoadingIndicator: () => null
                    }}
                    className="basic-single"
                    classNamePrefix="select"
                    onMenuOpen={() => setInputMessage('Please enter atleast 2 characters.')}
                    onMenuClose={() => setInstituteSearch(null)}
                    isSearchable={true}
                    isClearable
                    name="institute_id"
                    loadOptions={loadInstituteOptions}
                    placeholder="Select your campus from list."
                    onInputChange={(newValue) => {
                      if (newValue?.length <= 2) {
                        setInputMessage('Please enter atleast 2 characters.');
                      }

                      setInstituteSearch(newValue);
                    }}
                    value={instituteName}
                    onChange={(e) => {
                      setInstituteName(e);
                      setInstituteId(e);
                    }}
                    noOptionsMessage={() => inputMessage}
                  />
                  {loadOpt && (
                    <div className="spinner-filter" style={{ top: '15px' }}>
                      <i className="fa fa-spinner fa-pulse me-2"></i>
                    </div>
                  )}

                  <div className={'danger-frm px-1'}>{clicked && validate().instituteId}</div>
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Control
                    type="text"
                    name="title"
                    placeholder="Add a title.."
                    onChange={(e) => setTitle(e.target.value)}
                  />
                  <div className={'danger-frm px-1'}>{clicked && validate().title}</div>
                </Form.Group>
                <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                  <CKEditorComponent
                    latestPost={latestPost}
                    setLatestPost={setLatestPost}
                    isToolBar={true}
                    placeholder={'Description...'}
                  />
                  <div className={'danger-frm px-1'}>{clicked && validate().latestPost}</div>
                </Form.Group>
                <div className="attach-card-inr d-flex justify-content-between mb-3 px-1">
                  <div>
                    {' '}
                    <div>
                      <Form.Group controlId="formFile" className="attach-file-card">
                        <Form.Control
                          type="file"
                          name="attach_files"
                          accept=".png,.jpg,.jpeg"
                          onChange={(e) => {
                            fileValidator(e.target.files[0]);
                          }}
                        />
                        <span className="attach-file-inr">
                          <i className="fas fa-paperclip me-2"></i>
                          Attach Files
                        </span>
                      </Form.Group>
                    </div>
                    <span className="attach-file-name">{attachment?.name}</span>
                  </div>
                </div>
                <div className="cmmnt-btn text-end mt-3 px-2 pt-0">
                  <button className="accnt-btn" type="button" onClick={(e) => addCampus(e)}>
                    {buttonLoader ? (
                      <span>
                        <i className="fa fa-spinner fa-spin me-2"></i>SUBMIT
                      </span>
                    ) : (
                      'SUBMIT'
                    )}
                  </button>
                </div>
              </div>
            </Col>
            <Col md={3}>
              <div>
                <SuggestMentor />
              </div>
              <div>
                <GroupDiscover />
              </div>
              <div>
                <WhatsNew />
              </div>
              <div>
                <Upcoming />
              </div>
              <MiniFooter />
            </Col>
          </Row>
        </Container>
      </section>
      {/* {laoding ? <Loader /> : null} */}
    </>
  );
}

export default AddCampus;
