import { Link } from 'react-router-dom';

export const MiniFooter = () => {
  return (
    <div className="right-side-copyrt text-start">
      <ul>
        <li>
          <Link to="/privacy_policy" target="_blank">
            Privacy Policy
          </Link>
        </li>
        <li>
          <Link to="/terms_of_use" target="_blank">
            Terms of Use
          </Link>
        </li>
        <li>
          <Link to="/contact-us" target="_blank">
            Contact Us
          </Link>
        </li>
      </ul>
      <p>AlmaBay.com © {new Date().getFullYear()}</p>
    </div>
  );
};
