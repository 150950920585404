import moment from 'moment';
import { useEffect, useState } from 'react';
import { Col, Container, Form, Row, Table } from 'react-bootstrap';
import ReactPaginate from 'react-paginate';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import Loader from '../../components/commoncomponents/loader/loader-large';
import { communicationConstants } from '../../globals/constants';
import {
  communicationPlanList,
  communicationPlanListInst,
  updateAssignedPlan
} from '../../services/AuthServices';
import showNotification from '../../services/NotificationService';

const CommunicationPlanForInstitute = () => {
  const location = useLocation();
  const accessType = useSelector((state) => state?.user?.details?.access_type);
  const { state } = location;
  const [planList, setPlanList] = useState([]);
  const [totalCount, setTotalCount] = useState(null);
  const [status, setStatus] = useState();
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getCommunicationPlanList();
  }, [state, page]);

  const getCommunicationPlanList = async () => {
    try {
      setLoading(true);
      if (accessType === 'admin') {
        const response = await communicationPlanList(state, page);
        if (response?.status === 200) {
          setLoading(false);
          setPlanList(response.data?.data);
          setTotalCount(response?.data?.total);
        }
      } else {
        const response = await communicationPlanListInst();
        if (response?.status === 200) {
          setLoading(false);
          setPlanList(response.data?.data);
          setTotalCount(response?.data?.total);
        }
      }
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  const handleChange = async (value, id) => {
    if (value !== '') {
      try {
        let body = {
          state_id: value
        };
        setLoading(true);
        const response = await updateAssignedPlan(id, body);
        if (response?.status === 200) {
          setLoading(false);
          showNotification('success', response.data?.message);
          setPlanList(
            planList.map((item) => {
              if (item.id == id) {
                return {
                  ...item,
                  state_id: item.state_id === '0' ? '1' : '0'
                };
              }
              return item;
            })
          );
          setStatus('');
        }
      } catch (error) {
        setLoading(false);
        console.error(error);
      }
    }
  };

  const planStatus = (status_id) => {
    switch (status_id) {
      case '0':
        return 'Disabled';
      case '1':
        return 'Enabled';
      case '2':
        return 'Deleted';

      default:
        return;
    }
  };

  return (
    <>

      <div className="dash-main-content">
        <Container fluid>
          <div className="dash-page-content">
            <div className="dash-page-title">
              <Row>
                <Col xs={12} sm={12} md={12}>
                  <div className="dash-title-bar d-flex align-items-center justify-content-between">
                    <div className="title-lft d-flex align-items-center">
                      <div className="dash-icn">
                        <i className="fas fa-comments"></i>
                      </div>
                      <div className="dash-title-dis">
                        <h5>Manage Communication Plan</h5>
                        {planList?.length !== 0 && (
                          <h5>
                            Total Record(s): <span>{totalCount ?? 0}</span>
                          </h5>
                        )}
                      </div>
                    </div>
                    {accessType === 'admin' && (
                      <div className="title-rt-btn d-flex align-items-center">
                        <Link
                          className="add-btn-grn mx-2"
                          to="/admin/institute/add-communication-plan-for-institute"
                          state={state}
                        >
                          Add Plan
                        </Link>
                      </div>
                    )}
                  </div>
                </Col>
              </Row>
            </div>
            <div className="dash-content-card">
              <div className="table-responsive">
                <Table className="custom-table-data" striped bordered hover>
                  <thead>
                    <tr>
                      {/* <th>
                          <Form.Check type="checkbox" id="checkbox" label="" />
                        </th> */}
                      <th>
                        Id
                        <img src={require('../../assets/images/sort.png')} alt="sort" />
                      </th>
                      <th>
                        Name
                        <img src={require('../../assets/images/sort.png')} alt="sort" />
                      </th>
                      <th>
                        No. of Messages
                        <img src={require('../../assets/images/sort.png')} alt="sort" />
                      </th>
                      <th>
                        Plan Duration
                        <img src={require('../../assets/images/sort.png')} alt="sort" />
                      </th>
                      <th>
                        Plan Type
                        <img src={require('../../assets/images/sort.png')} alt="sort" />
                      </th>
                      <th>
                        Message Balance
                        <img src={require('../../assets/images/sort.png')} alt="sort" />
                      </th>
                      <th>
                        Expiry Date
                        <img src={require('../../assets/images/sort.png')} alt="sort" />
                      </th>
                      <th>
                        Status
                        <img src={require('../../assets/images/sort.png')} alt="sort" />
                      </th>
                      {accessType === 'admin' && (
                        <th>
                          Action
                          <img src={require('../../assets/images/sort.png')} alt="sort" />
                        </th>
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {planList?.length > 0 ? (
                      planList?.map((item, index) => {
                        return (
                          <tr key={index}>
                            {/* <td>
                                <Form.Check type="checkbox" label="" />
                              </td> */}
                            <td>{item?.id}</td>
                            <td>{item?.plan_name}</td>
                            <td>{item?.total_messages}</td>
                            <td>{item?.duration_name}</td>
                            <td>{item?.plan_type_name}</td>
                            <td>{item?.left_messages}</td>
                            <td>{moment(item?.expiry_date).format('LL')}</td>
                            <td>{planStatus(item?.state_id)}</td>
                            {accessType === 'admin' && (
                              <td>
                                <Form.Select
                                  onChange={(e) => {
                                    handleChange(e.target.value, item?.id);
                                  }}
                                  value={status}
                                  aria-label="Default select example"
                                  name="status"
                                >
                                  <option value="">Select</option>
                                  {item?.state_id == 1 && (
                                    <>
                                      <option value={communicationConstants.DISABLE}>
                                        Disable
                                      </option>
                                      {/* <option value={communicationConstants.DELETE}>Delete</option> */}
                                    </>
                                  )}
                                  {item?.state_id == 0 && (
                                    <>
                                      <option value={communicationConstants.ENABLE}>
                                        Enable
                                      </option>
                                      {/* <option value={communicationConstants.DELETE}>Delete</option> */}
                                    </>
                                  )}
                                </Form.Select>
                              </td>
                            )}
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td className="text-center" colSpan="9">
                          Data not found
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </div>
              {Math.ceil(totalCount / 10) > 1 && (
                <div className="react-page d-flex justify-content-center">
                  <ReactPaginate
                    previousLabel={'Prev'}
                    nextLabel={'Next'}
                    activeClassName={'page-link active'}
                    onPageChange={(props) => {
                      setPage(props.selected + 1);
                    }}
                    pageCount={Math.ceil(totalCount / 10)}
                  />
                </div>
              )}
            </div>
          </div>
        </Container>
      </div>

      {loading && <Loader />}
    </>
  );
};

export default CommunicationPlanForInstitute;
