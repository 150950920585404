/** @copyright : ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
 @author     : Shiv Charan Panjeta 

 All Rights Reserved.
 Proprietary and confidential :  All information contained herein is, and remains
 the property of ToXSL Technologies Pvt. Ltd. and its partners.
 Unauthorized copying of this file, via any medium is strictly prohibited.
*/

import { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Table from 'react-bootstrap/Table';
import { Link } from 'react-router-dom';
// import { getRoleList } from '../../services/AuthServices';
import ReactPaginate from 'react-paginate';
import { useNavigate } from 'react-router-dom';
import Loader from '../../components/commoncomponents/loader/loader-large';
import useDocumentTitle from '../../globals/useDocumentTitle';
import { getRoleList } from '../../services/AuthServices';

export default function Roles() {
  useDocumentTitle('Manage Users | AlmaBay');
  const [chekcedId, setCheckedId] = useState([]);
  const [allId, setAllId] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [orderBy, setOrderBy] = useState('DESC');
  const [shortOrderBy, setShortOrderBy] = useState('admin_id');

  const [list, setList] = useState([]);
  const [response, setResponse] = useState('');
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    apiCall();
  }, []);

  const apiCall = (Page, orderBy, shortOrderBy) => {
    setLoading(true);

    getRoleList(Page ? Page : currentPage, orderBy, shortOrderBy).then((res) => {
      setLoading(false);

      if (res?.status === 200) {
        setList(res?.data?.data);
        setResponse(res?.data?.pager);
        setAllId(res?.data?.data?.map((data) => data?.id));
      }
    });
  };

  const handleMultipleCheckBox = (id) => {
    if (chekcedId.includes(id)) {
      let filterId = chekcedId.filter((data) => data !== id);
      setCheckedId(filterId);
    } else {
      setCheckedId([...chekcedId, id]);
    }
  };

  const handleSelectAll = (e) => {
    if (e.target.checked === true) {
      setCheckedId(allId);
    } else {
      setCheckedId([]);
    }
  };

  const handlePageClick = (a) => {
    let page = a?.selected + 1;
    setCurrentPage(page);
    apiCall(page, orderBy, shortOrderBy);
  };

  const selectHandler = (value, id) => {
    switch (value) {
      case 'edit':
        navigate(`/admin/users/add-roles`, { state: id });
        break;
      case 'view':
        navigate('/admin/users/roleview', { state: id });
        break;

      default:
        break;
    }
  };
  return (
    <>
      <div className="dash-main-content">
        <Container fluid>
          <div className="dash-page-content">
            <div className="dash-page-title">
              <Row>
                <Col xs={12} sm={12} md={12}>
                  <div className="dash-title-bar d-flex align-items-center justify-content-between">
                    <div className="title-lft d-flex align-items-center">
                      <div className="dash-icn">
                        <i className="fas fa-building"></i>
                      </div>
                      <div className="dash-title-dis">
                        <h5>Roles</h5>
                        {list?.length !== 0 && (
                          <h5>
                            Total Users(s): <span>{response?.total}</span>
                          </h5>
                        )}
                      </div>
                    </div>
                    <div className="title-rt-btn">
                      <Link className="add-btn-grn" to="/admin/users/add-roles">
                        Add Role
                      </Link>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="dash-content-card">
              <div className="table-responsive">
                <Table className="custom-table-data" striped bordered hover>
                  <thead>
                    <tr>
                      {/* <th>
                          <Form.Check
                            type="checkbox"
                            id="checkbox"
                            label=""
                            checked={allId?.length === chekcedId?.length}
                            onChange={handleSelectAll}
                          />
                        </th> */}
                      <th>
                        <Link to="">
                          Id
                          <img src={require('../../assets/images/sort.png')} alt="sort" />
                        </Link>
                      </th>
                      <th>
                        <Link to="">
                          Name
                          <img src={require('../../assets/images/sort.png')} alt="sort" />
                        </Link>
                      </th>
                      <th>
                        <Link to="">
                          Status
                          <img src={require('../../assets/images/sort.png')} alt="sort" />
                        </Link>
                      </th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {list && list.length !== 0 ? (
                      <>
                        {list?.map((data, index) => {
                          return (
                            <tr key={index}>
                              {/* <td>
                                  <Form.Check
                                    type="checkbox"
                                    label=""
                                    id={`id${data?.id}`}
                                    checked={chekcedId?.includes(data?.id)}
                                    onChange={() => handleMultipleCheckBox(data?.id)}
                                  />
                                </td> */}
                              <td>{data?.id}</td>
                              <td>{data?.role_name}</td>
                              <td> {data?.status == 0 ? 'Active' : 'Inactive'} </td>
                              <td>
                                <Form.Select
                                  aria-label="Default select example"
                                  onChange={(e) => {
                                    selectHandler(e.target.value, data?.id);
                                  }}>
                                  <option value="">Action</option>
                                  <option value="edit">Edit</option>
                                  <option value="view">View</option>
                                </Form.Select>
                              </td>
                            </tr>
                          );
                        })}{' '}
                      </>
                    ) : (
                      <></>
                    )}
                  </tbody>
                </Table>
                <div className="react-page d-flex">
                  {response?.total > 10 && (
                    <ReactPaginate
                      previousLabel={currentPage > 0 ? '<' : ''}
                      nextLabel={response.total > 0 ? '>' : ''}
                      onPageChange={handlePageClick}
                      pageCount={response.total / 10}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
      {loading ? <Loader /> : null}
    </>
  );
}
