import moment from 'moment';
import { useEffect, useState } from 'react';
import { Alert, Button, Col, Container, Form, Row } from 'react-bootstrap';
import Table from 'react-bootstrap/Table';
import ReactPaginate from 'react-paginate';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import Loader from '../../components/commoncomponents/loader/loader-large';
import { STATUS_DELETE, STATUS_DISABLE, STATUS_ENABLE } from '../../globals/constants';
import useDocumentTitle from '../../globals/useDocumentTitle';
import {
  contactPersonListing,
  deleteContactPerson,
  multiActionContactPerson
} from '../../services/InstituteServices';
import showNotification from '../../services/NotificationService';

const ContactPerson = () => {
  useDocumentTitle('Contact Person | AlmaBay');
  const navigate = useNavigate();
  const [chekcedId, setCheckedId] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecords, setTotalRecord] = useState('');
  const [allId, setAllId] = useState([]);
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showalert, setShowAlert] = useState(false);
  const [messageView, setMessageView] = useState('');
  const [alertMsg, setAlertMsg] = useState('');
  const isAlert = useSelector((state) => state.alert);
  const [alert, setAlert] = useState(false);
  const [page, setPage] = useState('');
  const [filterState, setFilterState] = useState({
    title: '',
    name: '',
    email: '',
    status: ''
  });
  useEffect(() => {
    apiCall();
  }, []);
  useEffect(() => {
    const timeId = setTimeout(() => {
      setAlert(false);
      setAlertMsg('');
    }, 3000);
    return () => {
      clearTimeout(timeId);
    };
  }, [alert]);

  useEffect(() => {
    if (isAlert?.showAlertBox) {
      setAlertMsg(isAlert?.message);
      setAlert(true);
    }
  }, []);
  const globalStateHandler = () => {
    setShowAlert(true);
    // setShowAlert1(true);
  };
  const apiCall = (page) => {
    setLoading(true);
    let pageNo = page ? page : 0;
    setCurrentPage(pageNo);
    contactPersonListing(page, '', '', '', '').then((res) => {
      setLoading(false);

      if (res?.status === 200) {
        setList(res?.data?.data);
        setPage(res?.data?.pager);
        setTotalRecord(res?.data?.pager?.total);
        setAllId(res?.data?.data?.map((data) => data?.id));
      }
    });
  };
  const apiCallSearch = (page = '') => {
    setLoading(true);
    contactPersonListing(
      page,
      filterState?.title,
      filterState?.name,
      filterState?.email,
      filterState?.status
    ).then((res) => {
      setLoading(false);
      if (res?.status === 200) {
        setList(res?.data?.data);
        setAllId(res?.data?.data?.map((data) => data?.id));
        setTotalRecord(res?.data?.pager?.total);
      }
    });
  };
  const handlePageClick = (a) => {
    let page = a?.selected + 1;

    apiCallSearch(page);
  };
  useEffect(() => {
    const timeId = setTimeout(() => {
      // After 3 seconds set the show value to false
      setShowAlert(false);
      setMessageView('');
    }, 3000);

    return () => {
      clearTimeout(timeId);
    };
  }, [showalert]);
  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilterState({
      ...filterState,
      [name]: value
    });
  };

  const handleMultipleCheckBox = (id) => {
    if (chekcedId.includes(id)) {
      let filterId = chekcedId.filter((data) => data !== id);
      setCheckedId(filterId);
    } else {
      setCheckedId([...chekcedId, id]);
    }
  };
  const handleSelectAll = (e) => {
    if (e.target.checked === true) {
      setCheckedId(allId);
    } else {
      setCheckedId([]);
    }
  };
  const handlePerformAction = (e) => {
    if (chekcedId.length !== 0) {
      setShowAlert(false);

      let body = {
        ids: chekcedId.toString(),
        action: e.target.value
      };

      multiActionContactPerson(body).then((res) => {
        if (res?.status === 200) {
          setMessageView(res?.data?.message);
          setShowAlert(true);

          apiCall();
          setCheckedId([]);
        }
      });
    } else {
      globalStateHandler();
    }
  };

  //0-disable, 1-enable, 2-delete
  const statusName = (key) => {
    switch (key) {
      case '0':
        return 'Disable';
      case '1':
        return 'Enable';
      case '2':
        return 'Delete';
    }
  };
  const handelDelete = (id) => {
    setLoading(true);
    deleteContactPerson(id).then((resp) => {
      if (resp?.status == 200) {
        setLoading(false);
        document.getElementById('selector').value = '';
        showNotification('success', resp?.data?.message);
        apiCall();
      } else {
        setLoading(false);
      }
    });
  };
  const selectHandler = (value, id) => {
    switch (value) {
      case 'view':
        navigate(`/admin/view-contact-person`, { state: id });
        break;
      case 'edit':
        navigate(`/admin/edit-contact-person/${id}`);
        break;
    }
  };

  const handleResetFilter = () => {
    setFilterState({
      title: '',
      name: '',
      email: '',
      status: ''
    });
    apiCall();
  };

  return (
    <>
      <div className="dash-main-content">
        <Container fluid>
          <div className="dash-page-content">
            <div className="dash-page-title">
              <Row>
                <Col xs={12} sm={12} md={12}>
                  <div className="dash-title-bar d-flex align-items-center justify-content-between">
                    <div className="title-lft d-flex align-items-center">
                      <div className="dash-icn">
                        <i className="fas fa-university"></i>
                      </div>
                      <div className="dash-title-dis">
                        <h5>
                          Contact Person <span>Listing</span>
                        </h5>
                        <h5>
                          Total Record(s): <span>{totalRecords}</span>
                        </h5>
                      </div>
                    </div>

                    <div className="title-rt-btn">
                      <Link className="green_button_admin m-2" to="/admin/add-contact-person">
                        Add Contact Person
                      </Link>

                      <div className="head-instite-sec m-2">
                        <Form.Select
                          aria-label="Default select example"
                          onChange={handlePerformAction}>
                          <option>Choose Action</option>
                          <option value={STATUS_ENABLE}>Enable</option>
                          <option value={STATUS_DISABLE}>Disable</option>
                          <option value={STATUS_DELETE}>Delete</option>
                        </Form.Select>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="dash-content-card">
              <Alert variant={'success'} className={`${alert ? '' : 'd-none'}`}>
                {alertMsg}
              </Alert>
              <Alert
                variant={messageView ? 'success' : 'danger'}
                className={`${showalert ? '' : 'd-none'}`}>
                {messageView ? messageView : 'Please select any record'}
              </Alert>

              <div className="table-responsive">
                <Table className="custom-table-data" striped bordered hover>
                  <thead>
                    <tr>
                      <th>
                        <Form.Check
                          type="checkbox"
                          id="checkbox"
                          name="checkall"
                          checked={allId?.length === chekcedId?.length}
                          onChange={(e) => {
                            handleSelectAll(e);
                          }}
                          label=""
                        />
                      </th>
                      <th>Id</th>
                      <th>
                        <Link to="">Name</Link>
                      </th>
                      <th>
                        <Link to="">Designation</Link>
                      </th>
                      <th>
                        <Link to="">E-mail</Link>
                      </th>
                      <th>
                        <Link to="">Contact</Link>
                      </th>
                      <th>
                        <Link to="">Created At</Link>
                      </th>
                      <th>
                        <Link to="">
                          Status
                          <img src={require('../../assets/images/sort.png')} alt="sort" />
                        </Link>
                      </th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="search-table">
                      <td></td>
                      <td> </td>
                      <td>
                        <Form.Control
                          name="name"
                          type="text"
                          placeholder="Name"
                          value={filterState?.name}
                          onChange={handleFilterChange}
                        />
                      </td>
                      <td>
                        <Form.Control
                          name="title"
                          type="text"
                          placeholder="Title"
                          value={filterState?.title}
                          onChange={handleFilterChange}
                        />
                      </td>
                      <td>
                        <Form.Control
                          name="email"
                          type="email"
                          placeholder="E-mail"
                          value={filterState?.email}
                          onChange={handleFilterChange}
                        />
                      </td>
                      <td></td>
                      <td></td>
                      <td>
                        <Form.Select
                          aria-label="Default select example"
                          name="status"
                          value={filterState?.status}
                          onChange={handleFilterChange}>
                          <option>Select</option>
                          <option value="1">Enable</option>
                          <option value="0">Disable</option>
                          <option value="2">Delete</option>
                        </Form.Select>
                      </td>
                      <td className="serac-btns text-center" colSpan={2}>
                        <button
                          type="button"
                          className="green_button_admin"
                          onClick={() => apiCallSearch()}>
                          Search
                        </button>
                        <Button
                          className="main-btn-red ms-2"
                          onClick={() => {
                            handleResetFilter();
                          }}>
                          Reset
                        </Button>
                      </td>
                    </tr>
                    {list && list?.length ? (
                      list?.map((data, index) => (
                        <>
                          <tr key={index}>
                            <td>
                              {' '}
                              <Form.Check
                                type="checkbox"
                                id="checkbox"
                                name="checkall"
                                checked={allId?.length === chekcedId?.length}
                                onChange={(e) => {
                                  handleSelectAll(e);
                                }}
                                label=""
                              />
                            </td>
                            <td>{data?.id}</td>
                            <td>{data?.name}</td>
                            <td>{data?.designation}</td>
                            <td>{data?.email}</td>
                            <td>{data?.contact}</td>
                            <td>{moment(data?.created_at).format('llll')}</td>
                            <td>{statusName(data?.status)}</td>
                            <td>
                              <Form.Select
                                aria-label="Default select example"
                                id="selector"
                                onChange={(e) => {
                                  selectHandler(e.target.value, data?.id);
                                  if (e.target.value == 'delete') {
                                    handelDelete(data?.id);
                                  }
                                }}>
                                <option value="">Action</option>
                                <option value="view">View</option>
                                <option value="edit">Edit</option>
                                <option value="delete">Delete</option>
                              </Form.Select>
                            </td>
                          </tr>
                        </>
                      ))
                    ) : (
                      <tr className="text-center">
                        <td colSpan={9}>Data Not Found</td>
                      </tr>
                    )}
                  </tbody>
                </Table>
                <div className="react-page d-flex">
                  {totalRecords > 10 ? (
                    <ReactPaginate
                      previousLabel={currentPage > 0 ? '<' : ''}
                      nextLabel={page?.pageCount > 0 ? '>' : ''}
                      onPageChange={handlePageClick}
                      pageCount={page?.total / 10}
                      activeClassName={'page-link active'}
                    />
                  ) : (
                    ''
                  )}
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
      {loading ? <Loader /> : ''}
    </>
  );
};

export default ContactPerson;
