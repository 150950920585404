import { useEffect, useRef, useState } from 'react';
import { Col, Form, InputGroup, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import * as APISERVICES from '../../../services/UserServices';
import Select from 'react-select';
import Spinner from 'react-bootstrap/Spinner';
import showNotification from '../../../services/NotificationService';
import { useOnClickOutside } from '../../../globals/helper';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import moment from 'moment';
import AsyncSelect from 'react-select/async';
import { ProfileSimmer } from '../skelton/allskelton';
import { useDispatch, useSelector } from 'react-redux';
import { loginInfo } from '../../../redux/action';

function ProfileForm() {
  const [userInfo, setUserInfo] = useState({});
  const [locationSearch, setLocationSearch] = useState('');
  const [jobLocation, setJobLocation] = useState('');
  const [state, setState] = useState('');
  const [activeClass, setActiveClass] = useState(false);
  const [resumeDetail, setResumeDetail] = useState('');
  const [resumeAvailable, setResumeAvailable] = useState(false);
  const [genderData, setGenderData] = useState('');
  const [selectedGenderData, setSelectedGenderData] = useState([]);
  const [showMartialStatus, setShowMartialStatus] = useState([]);
  const [selectedMartialStatus, setSelectedMartialStatus] = useState('');
  const [allInterests, setAllInterests] = useState([]);
  const [selectedAllInterests, setSelectedAllInterests] = useState([]);
  const [allHobbies, setAllHobbies] = useState([]);
  const [selectedAllHobbies, setSelectedAllHobbies] = useState([]);
  const [allDefaultFeed, setAllDefaultFeed] = useState([]);
  const [selectedDefaultFeed, setSelectedDefaultFeed] = useState([]);
  const [feedMenu, setFeedMenu] = useState(false);
  const [genderMenu, setGenderMenu] = useState(false);
  const [maritalMenu, setMaritalMenu] = useState(false);
  const [loading, setLoading] = useState(false);
  const [inputMsg, setInputMsg] = useState('Please enter atleast 2 characters');
  const [loadInput, setLoadInput] = useState(false);
  const [miniLoader, setMiniLoader] = useState(false);
  const dispatch = useDispatch();
  const userDetail = useSelector((state) => state?.user?.details);
  const ref = useRef();
  useOnClickOutside(ref, (e) => handleUpdate(e));
  const handleChange = (e, select = false, name = '') => {
    if (select) {
      if (name == 'interests' || name == 'hobbies') {
        if (name === 'interests') {
          setSelectedAllInterests(e);
        } else if (name === 'hobbies') {
          setSelectedAllHobbies(e);
        }
        let arr = [];
        e?.map((data) => arr.push(data.label));
        setUserInfo({
          ...userInfo,
          [name]: arr.join(',  ')
        });
      } else {
        const { value } = e;
        if (name === 'gender') {
          setSelectedGenderData(e);
        }
        if (name === 'current_city_id') {
          setJobLocation(e);
        }
        if (name === 'marital_status') {
          setSelectedMartialStatus(e);
        }
        if (name === 'feed_section') {
          setSelectedDefaultFeed(e);
        }
        setUserInfo({
          ...userInfo,
          [name]: value
        });
      }
    } else {
      const { name, value, type } = e.target;
      if (type === 'file') {
        let file = e?.target?.files[0];
        if (fileValidator(file)) {
          handleUpdate(e, 'FILE_UPDATE', file);
        }
      } else {
        setUserInfo({
          ...userInfo,
          [name]: value
        });
      }
    }
  };

  useEffect(() => {
    getUserInfo();
    getOptionsList();
  }, []);

  useEffect(() => {
    if (activeClass) {
      document?.getElementById('firstname')?.focus();
    }
  }, [activeClass]);

  const getOptionsList = () => {
    setLoading(true);
    APISERVICES.martialStatusDropDown().then((resp) => {
      setLoading(false);
      if (resp?.status == 200) {
        let list = resp?.data?.data;
        let arr = [];
        for (let i in list) {
          arr.push({ label: list[i], value: list[i] });
        }
        setShowMartialStatus(arr);
      }
    });

    APISERVICES.getGender().then((resp) => {
      if (resp?.status == 200) {
        let list = resp?.data?.data;
        let arr = [];
        for (let i in list) {
          arr.push({ label: list[i], value: list[i] });
        }
        setGenderData(arr);
      }
    });

    APISERVICES.getPassionDropDown().then((resp) => {
      if (resp?.status == 200) {
        setAllInterests(
          resp?.data?.data?.map((item) => {
            return {
              label: item?.interests,
              value: item?.interests
            };
          }) ?? []
        );
      }
    });

    APISERVICES.getHobbiesDropdown().then((resp) => {
      if (resp?.status == 200) {
        setAllHobbies(
          resp?.data?.data?.map((hobbies) => {
            return {
              label: hobbies?.hobby,
              value: hobbies?.hobby
            };
          }) ?? []
        );
      }
    });

    APISERVICES.getDefaultFeedDropdown().then((resp) => {
      if (resp?.status == 200) {
        let data = resp?.data?.data;
        let arr = [];
        for (let i in data) {
          arr.push({ label: data[i], value: data[i] });
        }
        setAllDefaultFeed(arr);
      }
    });
  };

  //get user basic details api
  const getUserInfo = () => {
    APISERVICES.userBasicInfo().then((res) => {
      if (res?.status === 200) {
        let interestArr = [];
        let hobbiesArr = [];
        let temp = userDetail;
        temp.feed_section = res?.data?.details?.feed_section || '';
        dispatch(loginInfo(temp));
        setUserInfo({
          ...res?.data?.details,
          ['current_city_id']: res?.data?.details?.current_city
        });
        setState(res?.data?.details);
        setSelectedGenderData({
          label: res?.data?.details?.gender,
          value: res?.data?.details?.gender
        });
        setSelectedMartialStatus({
          label: res?.data?.details?.marital_status,
          value: res?.data?.details?.marital_status
        });
        setSelectedDefaultFeed({
          label: res?.data?.details?.feed_section,
          value: res?.data?.details?.feed_section
        });
        if (res?.data?.details?.interests?.length) {
          res?.data?.details?.interests?.split(',')?.map((data) => {
            interestArr.push({ label: data, value: data });
          });
        }
        setSelectedAllInterests(interestArr);
        if (res?.data?.details?.hobbies?.length) {
          res?.data?.details?.hobbies?.split(',')?.map((data) => {
            hobbiesArr.push({ label: data, value: data });
          });
        }
        setSelectedAllHobbies(hobbiesArr);
        if (res?.data?.resume) {
          setResumeDetail(res?.data?.resume);
          setResumeAvailable(res?.data?.is_resume);
        }
        setJobLocation({
          id: res?.data?.details?.current_city,
          label: res?.data?.details?.city_name,
          value: res?.data?.details?.current_city
        });
      }
    });
  };

  const addClassHandler = (current) => {
    setActiveClass(current);
  };

  const downloadHandler = (e, url) => {
    e.preventDefault();
    window.open(url);
  };

  //form-validation
  const validate = (values) => {
    let errors = {};
    if (!values?.first_name) {
      errors.first_name = 'This field is required';
    }
    if (!values?.last_name) {
      errors.last_name = 'This field is required';
    } else if (!values?.dob) {
      errors.dob = 'This field is required';
    }
    return errors;
  };

  const fileValidator = (data) => {
    const fileExtension = data.type.split('/')[1];
    const validExtensions = ['pdf', 'msword'];
    if (!validExtensions.includes(fileExtension)) {
      showNotification(
        'danger',
        'Invalid file selected. Please select valid files are of .pdf .docx type.'
      );
      return false;
    }
    return true;
  };

  const handleUpdate = (e, type, file) => {
    e.preventDefault();
    if (type == 'FILE_UPDATE') {
      if (!Object.keys(validate(userInfo)).length) {
        let formData = new FormData();
        for (let i in userInfo) {
          formData.append(`${i}`, userInfo[i]);
        }
        formData.append('resume', file);
        APISERVICES.updateBasicInfo(formData).then((resp) => {
          if (resp?.status === 200) {
            showNotification('success', resp?.data?.message);
            getUserInfo();
            setActiveClass({});
          }
        });
      } else {
        return;
      }
    } else {
      if (JSON.stringify(userInfo) !== JSON.stringify(state)) {
        if (!Object.keys(validate(userInfo)).length) {
          setMiniLoader(true);
          let formData = new FormData();
          for (let i in userInfo) {
            if (userInfo[i]) {
              formData.append(`${i}`, userInfo[i]);
            } else {
              formData.append(`${i}`, '');
            }
          }
          APISERVICES.updateBasicInfo(formData).then((resp) => {
            setMiniLoader(false);
            if (resp?.status === 200) {
              // showNotification('success', resp?.data?.message);
              getUserInfo();
              setActiveClass({});
            }
          });
        } else {
          return;
        }
      } else {
        setActiveClass({});
        return;
      }
    }
  };

  const loadLocationOptions = async (inputValue = '') => {
    if (inputValue.length >= 2) {
      setLoadInput(true);
      return APISERVICES.citiesDropDown(inputValue).then((res) => {
        setLoadInput(false);
        if (res?.status === 200) {
          let tempArr = [];
          if (res?.data?.data) {
            const data = res?.data?.data;
            data?.map((data) => {
              tempArr.push({
                id: data?.city_id,
                label: data?.city,
                value: data?.city
              });
            });
          }

          if (res?.data?.data == '') {
            setInputMsg('no match found');
          }
          return tempArr;
        }
      });
    }
  };

  return (
    <>
      <div className="detail_form_div">
        <div className="contact-details" id="" ref={ref}>
          <h2>Basic Details</h2>
          <div>
            {loading ? (
              <>
                <ProfileSimmer />
                <ProfileSimmer />
                <ProfileSimmer />
                <ProfileSimmer />
                <ProfileSimmer />
                <ProfileSimmer />
              </>
            ) : (
              <>
                <Row className={`form-group ${activeClass?.first ? 'active' : ''}`}>
                  <Col sm={4} xs={12}>
                    <label className="" htmlFor="firstname">
                      First Name
                    </label>
                  </Col>
                  <Col sm={6} xs={8}>
                    {activeClass?.first ? (
                      <>
                        <InputGroup className={`${activeClass?.first ? 'w-100' : 'd-none'}`}>
                          <Form.Control
                            id="firstname"
                            placeholder="First Name"
                            value={userInfo?.first_name}
                            name="first_name"
                            aria-label="firstname"
                            aria-describedby="basic-addon1"
                            onChange={handleChange}
                          />
                        </InputGroup>
                        <div
                          className={`${
                            activeClass?.first && validate(userInfo).first_name ? '' : 'd-none'
                          } danger-frm `}>
                          {activeClass.first && validate(userInfo).first_name}
                        </div>
                      </>
                    ) : (
                      <div
                        className="card-frm-detail"
                        role="presentation"
                        onClick={() => {
                          addClassHandler({ first: true });
                        }}>
                        <p>{userInfo?.first_name}</p>
                      </div>
                    )}
                  </Col>
                  <Col sm={2} xs={4}>
                    <Spinner
                      animation="border"
                      className={activeClass?.first && miniLoader ? 'me-2 d-block' : 'd-none'}
                      size="sm"
                      variant="warning"
                    />
                    <span className="green-frm-check d-none">
                      <i className="fas fa-check"></i>
                    </span>
                    {activeClass?.first && !miniLoader && (
                      <>
                        <Link
                          to={`javascript:void(0)`}
                          title="Cancel"
                          className="me-2 cross_btn"
                          onClick={() => {
                            addClassHandler({ first: false });
                          }}>
                          <i
                            role="presentation"
                            onClick={(e) => e.preventDefault()}
                            className="fas fa-times"></i>
                        </Link>
                        <Link
                          title="Save"
                          to={`javascript:void(0)`}
                          className="me-2 correct_btn"
                          onClick={(e) => {
                            handleUpdate(e);
                          }}>
                          <i className="fas fa-check"></i>
                        </Link>
                      </>
                    )}
                    <Link
                      to={`javascript:void(0)`}
                      onClick={() => {
                        addClassHandler({ first: true });
                      }}>
                      <i
                        role="presentation"
                        onClick={(e) => e.preventDefault()}
                        className="fa fa-chevron-right"
                        title="EDIT"></i>
                    </Link>
                  </Col>
                </Row>
                <Row className={`form-group ${activeClass?.last ? 'active' : ''}`}>
                  <Col sm={4} xs={12}>
                    <label className="" htmlFor="firstname">
                      Last Name
                    </label>
                  </Col>
                  <Col sm={6} xs={8}>
                    {activeClass?.last ? (
                      <>
                        <InputGroup className="w-100">
                          <Form.Control
                            placeholder="Last Name"
                            aria-label="Username"
                            aria-describedby="basic-addon1"
                            value={userInfo?.last_name}
                            name="last_name"
                            onChange={handleChange}
                            id="firstname"
                          />
                        </InputGroup>
                        <div
                          className={`${
                            activeClass?.last && validate(userInfo).last_name ? '' : 'd-none'
                          } danger-frm `}>
                          {activeClass?.last && validate(userInfo).last_name}
                        </div>
                      </>
                    ) : (
                      <div
                        className="card-frm-detail"
                        role="presentation"
                        onClick={() => {
                          addClassHandler({ last: true });
                        }}>
                        <p>{state?.last_name || ''}</p>
                      </div>
                    )}
                  </Col>
                  <Col sm={2} xs={4}>
                    <Spinner
                      animation="border"
                      className={activeClass?.last && miniLoader ? 'me-2 d-block' : 'd-none'}
                      size="sm"
                      variant="warning"
                    />
                    <span className="green-frm-check d-none">
                      <i className="fas fa-check"></i>
                    </span>
                    {activeClass?.last && !miniLoader && (
                      <>
                        <Link
                          to={`javascript:void(0)`}
                          title="Cancel"
                          className="me-2 cross_btn"
                          onClick={() => {
                            addClassHandler({ last: false });
                          }}>
                          <i
                            role="presentation"
                            onClick={(e) => e.preventDefault()}
                            className="fas fa-times"></i>
                        </Link>
                        <Link
                          to={`javascript:void(0)`}
                          title="Save"
                          className="me-2 correct_btn"
                          onClick={(e) => {
                            handleUpdate(e);
                          }}>
                          <i className="fas fa-check"></i>
                        </Link>
                      </>
                    )}

                    <Link
                      to={`javascript:void(0)`}
                      onClick={() => {
                        addClassHandler({ last: true });
                      }}>
                      <i
                        role="presentation"
                        onClick={(e) => e.preventDefault()}
                        className="fa fa-chevron-right"
                        title="EDIT"></i>
                    </Link>
                  </Col>
                </Row>
                <Row className={`form-group`}>
                  <Col sm={4} xs={12}>
                    <label className="" htmlFor="firstname">
                      Username
                    </label>
                  </Col>
                  <Col sm={6} xs={8}>
                    <div className="card-frm-detail blk" role="presentation">
                      <p>{state?.username || ''}</p>
                    </div>
                  </Col>
                  <Col sm={2} xs={4}></Col>
                </Row>
                <Row className={`form-group ${activeClass?.dob ? 'active' : ''}`}>
                  <Col sm={4} xs={12}>
                    <label className="" htmlFor="firstname">
                      Date of Birth
                    </label>
                  </Col>
                  <Col sm={8} xs={12}>
                    {/* <div className="date-cal-card">
                  <InputGroup className="w-100">
                    <Form.Control
                      placeholder="Date"
                      name="dob"
                      id="dobdate"
                      aria-label="date"
                      aria-describedby="basic-addon1"
                      type="date"
                      max={moment().format('YYYY-MM-DD')}
                      defaultValue={state?.dob}
                      onChange={handleChange}
                    />
                  </InputGroup>
                  <Link to="#" className="calender"></Link>
                </div> */}
                    {/* {activeClass?.dob ? ( */}

                    <>
                      <div className="date-cal-card">
                        <InputGroup className="w-100">
                          <Form.Control
                            placeholder="Date"
                            name="dob"
                            id="dobdate"
                            aria-label="date"
                            aria-describedby="basic-addon1"
                            type="date"
                            max={moment().format('YYYY-MM-DD')}
                            value={userInfo?.dob}
                            onChange={handleChange}
                          />
                        </InputGroup>

                        <Link to={`javascript:void(0)`} className="calender" title="EDIT"></Link>
                      </div>
                      <div
                        className={`${
                          activeClass?.dob && validate(userInfo).dob ? '' : 'd-none'
                        } danger-frm `}>
                        {activeClass?.dob && validate(userInfo).dob}
                      </div>
                    </>
                    {/* ) : (
                  <div
                    className="card-frm-detail"
                    role="presentation"
                    onClick={() => {
                      addClassHandler({ dob: true });
                    }}>
                    <p>{state?.dob}</p>
                  </div>
                )} */}

                    <Spinner
                      animation="border"
                      className={activeClass?.dob && miniLoader ? 'me-2 d-block' : 'd-none'}
                      size="sm"
                      variant="warning"
                    />
                    <span className="green-frm-check d-none">
                      <i className="fas fa-check"></i>
                    </span>
                    {activeClass?.dob && !miniLoader && (
                      <>
                        <Link to={`javascript:void(0)`} title="Cancel" className="me-2 cross_btn">
                          <i
                            role="presentation"
                            onClick={(e) => e.preventDefault()}
                            className="fas fa-times"></i>
                        </Link>
                        <Link
                          to={`javascript:void(0)`}
                          title="Save"
                          className="me-2 correct_btn"
                          onClick={(e) => {
                            handleUpdate(e);
                          }}>
                          <i className="fas fa-check"></i>
                        </Link>
                      </>
                    )}
                  </Col>
                </Row>
                <Row className={`form-group ${activeClass?.gender ? 'active' : ''}`}>
                  <Col sm={4} xs={12}>
                    <label className="" htmlFor="firstname">
                      Gender
                    </label>
                  </Col>
                  <Col sm={6} xs={12}>
                    {activeClass?.gender ? (
                      <Select
                        className="basic-single mt-3 mb-3"
                        classNamePrefix="select"
                        isClearable={false}
                        isSearchable={true}
                        // menuIsOpen={genderMenu}
                        name="gender"
                        onChange={(e) => {
                          handleChange(e, true, 'gender');
                          setGenderMenu(false);
                        }}
                        options={genderData}
                        value={selectedGenderData}
                        id="firstname"
                      />
                    ) : (
                      <div
                        className="card-frm-detail"
                        onClick={() => {
                          addClassHandler({ gender: true });
                        }}
                        role="presentation">
                        <p>{state?.gender || ''}</p>
                      </div>
                    )}
                  </Col>
                  <Col sm={2} xs={12}>
                    <Spinner
                      animation="border"
                      className={activeClass?.gender && miniLoader ? 'me-2 d-block' : 'd-none'}
                      size="sm"
                      variant="warning"
                    />
                    <span className="green-frm-check d-none">
                      <i className="fas fa-check"></i>
                    </span>

                    {activeClass?.gender && !miniLoader && (
                      <>
                        <Link
                          to={`javascript:void(0)`}
                          title="Cancel"
                          className="me-2 cross_btn"
                          onClick={() => {
                            addClassHandler({ gender: false });
                          }}>
                          <i
                            role="presentation"
                            onClick={(e) => e.preventDefault()}
                            className="fas fa-times"></i>
                        </Link>
                        <Link
                          to={`javascript:void(0)`}
                          title="Save"
                          className="me-2 correct_btn"
                          onClick={(e) => {
                            handleUpdate(e);
                          }}>
                          <i className="fas fa-check"></i>
                        </Link>
                      </>
                    )}

                    <Link
                      to={`javascript:void(0)`}
                      onClick={() => {
                        addClassHandler({ gender: true });
                      }}>
                      <i
                        role="presentation"
                        onClick={(e) => {
                          e.preventDefault();
                          setGenderMenu(true);
                        }}
                        className="fa fa-chevron-right"
                        title="EDIT"></i>
                    </Link>
                  </Col>
                </Row>
                <Row className={`form-group ${activeClass?.Marital ? 'active' : ''}`}>
                  <Col sm={4} xs={12}>
                    <label className="" htmlFor="firstname">
                      Marital Status
                    </label>
                  </Col>
                  <Col sm={6} xs={8}>
                    {activeClass?.Marital ? (
                      <Select
                        className="basic-single mt-3 mb-3"
                        classNamePrefix="select"
                        isClearable={false}
                        isSearchable={true}
                        // menuIsOpen={maritalMenu}
                        name="marital_status"
                        onChange={(e) => {
                          handleChange(e, true, 'marital_status');
                          setMaritalMenu(false);
                        }}
                        options={showMartialStatus}
                        value={selectedMartialStatus}
                      />
                    ) : (
                      <div
                        className="card-frm-detail"
                        role="presentation"
                        onClick={() => {
                          addClassHandler({ Marital: true });
                        }}>
                        <p>{state?.marital_status || ''}</p>
                      </div>
                    )}
                  </Col>
                  <Col sm={2} xs={4}>
                    <Spinner
                      animation="border"
                      className={activeClass?.Marital && miniLoader ? 'me-2 d-block' : 'd-none'}
                      size="sm"
                      variant="warning"
                    />
                    <span className="green-frm-check d-none">
                      <i className="fas fa-check"></i>
                    </span>
                    {activeClass?.Marital && !miniLoader && (
                      <>
                        <Link
                          to={`javascript:void(0)`}
                          title="Cancel"
                          className="me-2 cross_btn"
                          onClick={() => {
                            addClassHandler({ Marital: false });
                          }}>
                          <i
                            role="presentation"
                            onClick={(e) => e.preventDefault()}
                            className="fas fa-times"></i>
                        </Link>
                        <Link
                          to={`javascript:void(0)`}
                          title="Save"
                          className="me-2 correct_btn"
                          onClick={(e) => {
                            handleUpdate(e);
                          }}>
                          <i className="fas fa-check"></i>
                        </Link>
                      </>
                    )}

                    <Link
                      to={`javascript:void(0)`}
                      onClick={() => {
                        addClassHandler({ Marital: true });
                      }}>
                      <i
                        role="presentation"
                        onClick={(e) => {
                          e.preventDefault();
                          setMaritalMenu(true);
                        }}
                        className="fa fa-chevron-right"
                        title="EDIT"></i>
                    </Link>
                  </Col>
                </Row>
                <Row className={`form-group ${activeClass?.about ? 'active' : ''}`}>
                  <Col sm={10} xs={12}>
                    <label className="" htmlFor="firstname">
                      About Me
                    </label>
                  </Col>
                  <Col sm={2} xs={12}>
                    <Spinner
                      animation="border"
                      className={activeClass?.about && miniLoader ? 'me-2 d-block' : 'd-none'}
                      size="sm"
                      variant="warning"
                    />
                    <span className="green-frm-check d-none">
                      <i className="fas fa-check"></i>
                    </span>

                    {activeClass?.about && !miniLoader && (
                      <>
                        <Link
                          to={`javascript:void(0)`}
                          title="Cancel"
                          className="me-2 cross_btn"
                          onClick={() => {
                            addClassHandler({ about: false });
                          }}>
                          <i
                            role="presentation"
                            onClick={(e) => e.preventDefault()}
                            className="fas fa-times"></i>
                        </Link>
                        <Link
                          to={`javascript:void(0)`}
                          title="Save"
                          className="me-2 correct_btn"
                          onClick={(e) => {
                            handleUpdate(e);
                          }}>
                          <i className="fas fa-check"></i>
                        </Link>
                      </>
                    )}

                    <Link
                      to={`javascript:void(0)`}
                      onClick={() => {
                        addClassHandler({ about: true });
                      }}>
                      <i
                        role="presentation"
                        onClick={(e) => e.preventDefault()}
                        className="fa fa-chevron-right"
                        title="EDIT"></i>
                    </Link>
                  </Col>
                  <Col sm={12} xs={12}>
                    {activeClass?.about ? (
                      <CKEditor
                        name="description"
                        editor={ClassicEditor}
                        data={userInfo?.about}
                        config={{
                          toolbar: [
                            'bold',
                            'italic',
                            'numberedList',
                            'bulletedList',
                            'outdent',
                            'indent'
                          ]
                        }}
                        onChange={(event, editor) => {
                          const data = editor.getData();

                          setUserInfo({ ...userInfo, ['about']: data });
                        }}
                      />
                    ) : (
                      <div
                        className="card-frm-detail"
                        role="presentation"
                        onClick={() => {
                          addClassHandler({ about: true });
                        }}>
                        <div dangerouslySetInnerHTML={{ __html: state?.about }} />
                      </div>
                    )}
                  </Col>
                </Row>
                <Row className={`form-group ${activeClass?.location ? 'active' : ''}`}>
                  <Col sm={4} xs={12}>
                    <label className="" htmlFor="firstname">
                      Location
                    </label>
                  </Col>
                  <Col sm={6} xs={8}>
                    {activeClass?.location ? (
                      <AsyncSelect
                        cacheOptions
                        defaultOptions
                        value={jobLocation}
                        // menuIsOpen={locationSearch !== '' ? true : false}
                        getOptionLabel={(e) => e.label}
                        getOptionValue={(e) => e.value}
                        loadOptions={loadLocationOptions}
                        onInputChange={(newValue) => {
                          setLocationSearch(newValue);
                          newValue?.length <= 2 && setInputMsg('Please enter atleast 2 characters');
                        }}
                        className="basic-single mt-1"
                        name="current_city_id"
                        onChange={(e) => {
                          handleChange(e, true, 'current_city_id');
                          setUserInfo({
                            ...userInfo,
                            ['current_city_id']: e?.id
                          });
                        }}
                        placeholder="Search By Location"
                        noOptionsMessage={() => inputMsg}
                        components={{
                          DropdownIndicator: () => null,
                          IndicatorSeparator: () => null,
                          LoadingIndicator: () => null
                        }}
                        styles={{
                          control: (base) => ({
                            ...base,
                            border: '0 !important',
                            // This line disable the  border
                            boxShadow: '0 !important',
                            '&:hover': {
                              border: '0 !important'
                            }
                          })
                        }}
                      />
                    ) : (
                      <div
                        className="card-frm-detail"
                        role="presentation"
                        onClick={() => {
                          addClassHandler({ location: true });
                        }}>
                        <p>{state?.city_name || ''}</p>
                      </div>
                    )}

                    {loadInput && (
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row-reverse',
                          margin: 'auto',
                          marginTop: '-30px'
                        }}>
                        <i className="fa fa-spinner fa-pulse me-2"></i>
                      </div>
                    )}
                  </Col>
                  <Col sm={2} xs={4}>
                    <Spinner
                      animation="border"
                      className={activeClass?.location && miniLoader ? 'me-2 d-block' : 'd-none'}
                      size="sm"
                      variant="warning"
                    />
                    <span className="green-frm-check d-none">
                      <i className="fas fa-check"></i>
                    </span>
                    {activeClass?.location && !miniLoader && (
                      <>
                        <Link
                          to={`javascript:void(0)`}
                          title="Cancel"
                          className="me-2 cross_btn"
                          onClick={() => {
                            addClassHandler({ location: false });
                          }}>
                          <i
                            role="presentation"
                            onClick={(e) => e.preventDefault()}
                            className="fas fa-times"></i>
                        </Link>
                        <Link
                          to={`javascript:void(0)`}
                          title="Save"
                          className="me-2 correct_btn"
                          onClick={(e) => {
                            handleUpdate(e);
                          }}>
                          <i className="fas fa-check"></i>
                        </Link>
                      </>
                    )}
                    <Link
                      to={`javascript:void(0)`}
                      onClick={() => {
                        addClassHandler({ location: true });
                      }}>
                      <i
                        role="presentation"
                        onClick={(e) => e.preventDefault()}
                        className="fa fa-chevron-right"
                        title="EDIT"></i>
                    </Link>
                  </Col>
                </Row>
                <Row className={`form-group ${activeClass?.passion ? 'active' : ''}`}>
                  <Col sm={10} xs={12}>
                    <label className="" htmlFor="firstname">
                      Passions
                    </label>
                  </Col>
                  <Col sm={2} xs={12}>
                    <Spinner
                      animation="border"
                      className={activeClass?.passion && miniLoader ? 'me-2 d-block' : 'd-none'}
                      size="sm"
                      variant="warning"
                    />
                    <span className="green-frm-check d-none">
                      <i className="fas fa-check"></i>
                    </span>
                    {activeClass?.passion && !miniLoader && (
                      <>
                        <Link
                          to={`javascript:void(0)`}
                          title="Cancel"
                          className="me-2 cross_btn"
                          onClick={() => {
                            addClassHandler({ passion: false });
                          }}>
                          <i
                            role="presentation"
                            onClick={(e) => e.preventDefault()}
                            className="fas fa-times"></i>
                        </Link>
                        <Link
                          to={`javascript:void(0)`}
                          title="Save"
                          className="me-2 correct_btn"
                          onClick={(e) => handleUpdate(e)}>
                          <i className="fas fa-check"></i>
                        </Link>
                      </>
                    )}
                    <Link
                      to={`javascript:void(0)`}
                      onClick={() => {
                        addClassHandler({ passion: true });
                      }}>
                      <i
                        role="presentation"
                        onClick={(e) => e.preventDefault()}
                        className="fa fa-chevron-right"
                        title="EDIT"></i>
                    </Link>
                  </Col>
                  <Col sm={12} xs={12}>
                    {activeClass?.passion ? (
                      <Select
                        isMulti
                        className="basic-single mt-3 mb-3"
                        classNamePrefix="select"
                        isClearable={true}
                        isSearchable={true}
                        name="interests"
                        onChange={(e) => {
                          handleChange(e, true, 'interests');
                        }}
                        options={allInterests}
                        value={selectedAllInterests}
                      />
                    ) : (
                      <div
                        className="card-frm-detail"
                        role="presentation"
                        onClick={() => {
                          addClassHandler({ passion: true });
                        }}>
                        <p>{state?.interests || ''}</p>
                      </div>
                    )}
                  </Col>
                </Row>
                <Row className={`form-group ${activeClass?.Hobbies ? 'active' : ''}`}>
                  <Col sm={10} xs={12}>
                    <label className="" htmlFor="firstname">
                      Hobbies
                    </label>
                  </Col>
                  <Col sm={2} xs={12}>
                    <Spinner
                      animation="border"
                      className={activeClass?.Hobbies && miniLoader ? 'me-2 d-block' : 'd-none'}
                      size="sm"
                      variant="warning"
                    />
                    <span className="green-frm-check d-none">
                      <i className="fas fa-check"></i>
                    </span>
                    {activeClass?.Hobbies && !miniLoader && (
                      <>
                        <Link
                          to={`javascript:void(0)`}
                          title="Cancel"
                          className="me-2 cross_btn"
                          onClick={() => {
                            addClassHandler({ Hobbies: false });
                          }}>
                          <i
                            role="presentation"
                            onClick={(e) => e.preventDefault()}
                            className="fas fa-times"></i>
                        </Link>
                        <Link
                          to={`javascript:void(0)`}
                          title="Save"
                          className="me-2 correct_btn"
                          onClick={(e) => handleUpdate(e)}>
                          <i className="fas fa-check"></i>
                        </Link>
                      </>
                    )}
                    <Link
                      to={`javascript:void(0)`}
                      onClick={() => {
                        addClassHandler({ Hobbies: true });
                      }}>
                      <i
                        role="presentation"
                        onClick={(e) => e.preventDefault()}
                        className="fa fa-chevron-right"
                        title="EDIT"></i>
                    </Link>
                  </Col>
                  <Col sm={12} xs={12}>
                    {activeClass?.Hobbies ? (
                      <Select
                        isMulti
                        className="basic-single mt-3 mb-3"
                        classNamePrefix="select"
                        isClearable={false}
                        isSearchable={true}
                        name="hobbies"
                        onChange={(e) => {
                          handleChange(e, true, 'hobbies');
                        }}
                        options={allHobbies}
                        value={selectedAllHobbies}
                      />
                    ) : (
                      <div
                        className="card-frm-detail"
                        role="presentation"
                        onClick={() => {
                          addClassHandler({ Hobbies: true });
                        }}>
                        <p>{state?.hobbies || ''}</p>
                      </div>
                    )}
                  </Col>
                </Row>
                <Row className={`form-group `}>
                  <Col sm={4} xs={12}>
                    <label className="" htmlFor="resume">
                      Resume
                    </label>
                  </Col>
                  <Col sm={4} xs={8}>
                    {resumeAvailable === true && (
                      <div className="card-existing">
                        <Link
                          to={`javascript:void(0)`}
                          rel="noreferrer"
                          className="existing"
                          onClick={(e) => {
                            downloadHandler(e, resumeDetail);
                          }}>
                          Download Existing Resume
                        </Link>
                      </div>
                    )}
                  </Col>
                  <Col sm={4} xs={4}>
                    <div className="upload-resume-card">
                      <Form.Group controlId="formFile" className="opacity-0">
                        <Form.Control
                          type="file"
                          name="resume"
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          accept="application/pdf,application/msword"
                        />
                      </Form.Group>
                      <div className="upload-icn d-flex align-items-center">
                        <img
                          className="me-3"
                          src={require('../../../assets/images/upload-icon.png')}
                          alt=""
                        />
                        <p>{resumeDetail ? 'update resume' : 'upload resume'}</p>
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row className={`form-group ${activeClass?.default ? 'active' : ''}`}>
                  <Col sm={4} xs={12}>
                    <label className="" htmlFor="firstname">
                      Default Feed
                    </label>
                  </Col>
                  <Col sm={6} xs={8}>
                    {activeClass?.default ? (
                      <Select
                        className="basic-single mt-3 mb-3"
                        classNamePrefix="select"
                        // isClearable={false}
                        isSearchable={true}
                        // menuIsOpen={feedMenu}
                        name="feed_section"
                        onChange={(e) => {
                          handleChange(e, true, 'feed_section');
                          setFeedMenu(false);
                        }}
                        options={allDefaultFeed}
                        value={selectedDefaultFeed}
                      />
                    ) : (
                      <div
                        className="card-frm-detail"
                        role="presentation"
                        onClick={() => {
                          addClassHandler({ default: true });
                        }}>
                        <p>{state?.feed_section || ''}</p>
                      </div>
                    )}
                  </Col>
                  <Col sm={2} xs={4}>
                    <Spinner
                      animation="border"
                      className={activeClass?.default && miniLoader ? 'me-2 d-block' : 'd-none'}
                      size="sm"
                      variant="warning"
                    />
                    <span className="green-frm-check d-none">
                      <i className="fas fa-check"></i>
                    </span>
                    {activeClass?.default && !miniLoader && (
                      <>
                        <Link
                          to={`javascript:void(0)`}
                          title="Cancel"
                          className="me-2 cross_btn"
                          onClick={() => {
                            addClassHandler({ default: false });
                          }}>
                          <i
                            role="presentation"
                            onClick={(e) => e.preventDefault()}
                            className="fas fa-times"></i>
                        </Link>
                        <Link
                          to={`javascript:void(0)`}
                          title="Save"
                          className="me-2 correct_btn"
                          onClick={(e) => handleUpdate(e)}>
                          <i className="fas fa-check"></i>
                        </Link>
                      </>
                    )}
                    <Link
                      to={`javascript:void(0)`}
                      onClick={() => {
                        addClassHandler({ default: true });
                      }}>
                      <i
                        role="presentation"
                        onClick={(e) => {
                          e.preventDefault();
                          setFeedMenu(true);
                        }}
                        className="fa fa-chevron-right"
                        title="EDIT"></i>
                    </Link>
                  </Col>
                </Row>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default ProfileForm;
