import { useState } from 'react';
import { Col, Container, Dropdown, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import HeaderLogin from '../../commoncomponents/HeaderLogin';
import TopTabbings from './TopTabbings';
import Form from 'react-bootstrap/Form';
import NewsSidebar from '../../commoncomponents/newsfeeds/NewsSidebar';
import PeopleKnow from '../../commoncomponents/newsfeeds/PeopleKnow';
import GroupDiscover from '../../commoncomponents/newsfeeds/GroupDiscover';
import CampusNews from '../../commoncomponents/newsfeeds/CampusNews';
import ArticalBlogs from '../../commoncomponents/newsfeeds/ArticalBlogs';
import CampusnewsText from '../../commoncomponents/newsfeeds/CampusnewsText';
import WhatsNew from '../../commoncomponents/newsfeeds/WhatsNew';
import Upcoming from '../../commoncomponents/newsfeeds/Upcoming';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { useNavigate } from 'react-router-dom';

function NewsPageDetail() {
  const [detail, setDetail] = useState('');
  const [commentDetail, setCommentDetail] = useState([]);
  const [addComment, setAddComment] = useState('');
  const [toSendAttach, setToSendAttch] = useState([]);
  const [attachedFiles, setAttachedFiles] = useState([]);
  const [fileName, setFileName] = useState([]);
  const navigate = useNavigate();

  return (
    <>
      <HeaderLogin />
      <div className="header-lnk-feeds">
        <div className="container">
          <TopTabbings state={'mentor'} />
        </div>
      </div>
      <section className="news-feed-sec bg-grey py-4">
        <Container>
          <Row>
            <Col xs={12} lg={2}>
              <NewsSidebar state="2" current="feed" />
            </Col>
            <Col xs={12} lg={7} className="mb-4">
              <div className="articals_div news_outer_div">
                <div className="single_artical_card news_detail_card p-3">
                  <div className="news-detail-img mb-3">
                    <img src={require('../../../assets/images/15-movies.jpg')} alt="news" />
                  </div>
                  <div className="news-lst-hd d-flex align-items-center justify-content-between mb-2">
                    <h4 className="title">15 movies that inspire entrepreneurship</h4>
                  </div>
                  <div className="nws-detail-dis">
                    <h4>1. Startup.com</h4>
                    <p>
                      This 2001 documentary follows the rise and fall of a promising startup called
                      GoveWorks.com. Despite a lot of buzz and funding for the company, the founders
                      must deal with in-house bickering and getting too inflated too fast.
                    </p>
                    <p>
                      Why watch it? There are many takeaways from this documentary. For starters, it
                      illustrates just how detrimental it can be when partners aren't on the same
                      page. Another lesson learned is how to properly manage a budget after you've
                      secured funding.
                    </p>
                    <h4>2. Flash of Genius</h4>
                    <p>
                      Greg Kinnear portrays Bob Kearns, the inventor if intermittent windshield
                      wipers. Despite the Detroit automakers embracing the idea, Kearns nevers
                      receives credit. The rest of the film chronicles his battle against these
                      corporate heavyweights.
                    </p>
                    <p>
                      Why watch it? Having an important idea that can enhance the lives of others,
                      which Kearns accomplished, is important enough where it's not just about
                      receiving fame and fortune, it's also important to remember to protect your
                      idea and intellectual property.
                    </p>
                    <h4>3. Gosford Park</h4>
                    <p>
                      Robert Altman's 2001 murder mystery may not some like an obvious choice, but
                      it's an extremely entertaining film that will keep you on the edge of your
                      seat. But, most importantly...
                    </p>
                    <p>
                      Why watch it? Making people happy, like having outstanding customer service,
                      is a crucial part of any business. Helen Mirren's Mr.s Wilson states this
                      perfectly: "What gift do you think a good servant has that separates them from
                      the others? Its the gift of anticipation. And I'm a good servant; I'm better
                      than good, I'm the best; I'm the perfect servant. I know when they'll be
                      hungry, and the food is ready. I know when they'll be tired, and the bed is
                      turned down. I know it before they know it themselves."
                    </p>
                    <h4>4. Steve Jobs: One Last Thing</h4>
                    <p>
                      PBS released this documentary shortly after the passing of Steve Jobs in 2011.
                      It captures both the highs and lows of one of the most influential
                      entrepreneurs ever.
                    </p>
                    <p>
                      Why watch it? You can never learn enough from the life and career of Steve
                      Jobs.
                    </p>

                    <h4>5. The Godfather</h4>
                    <p>
                      Here's another film that everyone should view at least once. Francis Ford
                      Coppola's 1972 masterpiece is arguably one of the greatest films in cinema
                      history.
                    </p>
                    <p>
                      Why watch it? For a moment, forget the fact that this is a movie about
                      organized crime. Instead, learn how Michael Corleone (Al Pacino) took a small
                      family business and transformed it into one of the most powerful and
                      influential families in the country. The Godfather perfectly illustrates what
                      it take to get the top, and how to remain there.
                    </p>
                    <h4>6. Risky Business</h4>
                    <p>
                      In 1983, Tom Cruise became a pop-culture icon after sliding across the floor
                      in a pair of socks and button-down shirt. While he's a good time enjoying the
                      place to himself (literally), things get out of hand after he wrecks his
                      father's Porsche.
                    </p>
                    <p>
                      Why watch it? Cruise's character Joel has to think quickly on how to raise
                      some cash to fix the car. Sometimes the best ideas are formed when we're
                      desperate.
                    </p>
                    <h4>7. Beer Wars</h4>
                    <p>
                      This 2009 documentary follows a group of friends trying to take on the major
                      breweries by launching their own craft beer company.
                    </p>
                    <p>
                      Why watch it? It's challenging to succeed when jumping into an established
                      market. However, educating your audience on how much better your product is
                      than the competition is a valuable lesson we learned from Beer Wars.
                    </p>
                    <h4>8. Up in the Air</h4>
                    <p>
                      Both George Clooney and Anna Kendrick were phenomenal in 2009's Up in the Air.
                      The film focuses on Ryan Bingham, who is hired to travel across the country
                      and lay people off. Kendrick plays Natalie Keener, the nob who is trying to
                      change this tactic through technology.
                    </p>

                    <p>
                      Why watch it? Clooney's character is not only efficient, he is also driven by
                      a goal that doesn't involve giving people the axe. However, the main lesson
                      from Up in the Air is that sometimes it's best to understand a business model
                      before tinkering with it, which is what Kendrick's character discovers.
                    </p>
                    <h4>9. Session 9</h4>
                    <p>
                      In this 2001 horror/mystery, an entrepreneur in the asbestos removing business
                      takes a questionable job in an abandoned mental hospital. And, this is where
                      the crew, led by Phil (David Caruso) run into some chilling and frightening
                      events.
                    </p>
                    <p>
                      Why watch it? Sometimes, even if the money is good, you just can't accept
                      certain offers.
                    </p>

                    <h4>10. Call of the Entrepreneur</h4>
                    <p>
                      This inspiring 2007 documentary follows three driver men; a merchant banker, a
                      failing dairy farmer and a refugee from Communist China.
                    </p>
                    <p>
                      Why watch it? It doesn't get any more uplifting than watching these three
                      different men from different parts of the world risk everything to follow
                      their dreams. If they can do it, why can't you?
                    </p>

                    <h4>11. Cocktail</h4>
                    <p>
                      We know. Another Tom Cruise flick! But, what can we say? This is a guilty
                      pleasure from 1988 that follows young Brian Flanagan and his journey in
                      opening up his very own bar - despite all the bumps in the road.
                    </p>
                    <p>
                      Why watch it? For starters, it's just fun. But, as an entrepreneur there's a
                      lot to love about Cocktail. For starters, Flanagan tries to go by the books,
                      taking business classes and reading guides on how-to-start a successful
                      business. But the real lessons that he learns is from actually getting behind
                      the bar and learning what customers and how to become the best bartender in
                      town. He also has a mentor who isn't afraid to show Flanagan the tricks of the
                      trade, as well as how cutthroat the real world can be.
                    </p>
                  </div>
                  <div className="navigation-border">
                    <ul className="article-cmmnt-lnks d-flex ps-2">
                      <li className="share-drop-btn">
                        <Dropdown>
                          <Dropdown.Toggle className="pe-3" id="dropdown-basic">
                            <i className="fa fa-share"></i> Share
                          </Dropdown.Toggle>
                          <Dropdown.Menu className="p-2">
                            <Dropdown.Item href="#/action-1">Facebook</Dropdown.Item>
                            <Dropdown.Item href="#/action-2">Twitter</Dropdown.Item>
                            <Dropdown.Item href="#/action-3">Google</Dropdown.Item>
                            <Dropdown.Item href="#/action-3">Linkedin</Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="leave-cmmnt-card-outr">
                <h5 className="mb-4">Comments</h5>
                <div className="leave-cmmnt-lst">
                  <div className="leave-cmmnt-card bg-white d-flex align-items-top p-3 mb-2">
                    <Link className="close-btn" to="#">
                      <i className="fas fa-times"></i>
                    </Link>

                    <div className="leave-cmmnt-icn">
                      <img
                        src={require('../../../assets/images/default-male-avatar.png')}
                        alt="user_img"
                      />
                    </div>
                    <div className="leave-cmmnt-media">
                      <h4>
                        <Link to="#">Coordinator</Link>
                        <span>2023-02-24T15:04:51+0530</span>
                      </h4>
                      <div className="mt-2">
                        <p>
                          Why watch it? Again, money isn't the only thing in life, and Boiler Room
                          proves that succeeding financially isn't the end-all-be-all. However,
                          Boiler Room also shows how you can unleash your inner salesperson when
                          your driver by a goal. And, the film also displays how powerful a
                          passionate leader can be, just watch Ben Affleck round-up the troops
                          (warning NSFW).
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="leave-cmmnt-frm bg-white p-3">
                  <h4 className="mb-4">Leave a Comment</h4>
                  <Form.Group className="" controlId="exampleForm.ControlTextarea1">
                    <CKEditor
                      name="description"
                      data={addComment}
                      editor={ClassicEditor}
                      onReady={(editor) => {}}
                      config={{
                        toolbar: [
                          'bold',
                          'italic',
                          'numberedList',
                          'bulletedList',
                          'outdent',
                          'indent'
                        ]
                      }}
                      onChange={(event, editor) => {
                        const data = editor.getData();
                        setAddComment(data);
                      }}
                      onBlur={(event, editor) => {}}
                      onFocus={(event, editor) => {}}
                    />
                  </Form.Group>
                  <div className="pt-2 attach-card-inr text-end">
                    <div className="cmmnt-btn mt-2 pb-2">
                      <button className="accnt-btn">Comment</button>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <Col xs={12} lg={3}>
              <div>
                <PeopleKnow />
              </div>
              <div>
                <GroupDiscover />
              </div>
              <div>
                <CampusNews />
              </div>
              <div>
                <ArticalBlogs />
              </div>
              <div>
                <CampusnewsText />
              </div>
              <div>
                <WhatsNew />
              </div>
              <div>
                <Upcoming />
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}

export default NewsPageDetail;
