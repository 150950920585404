/**
 * @copyright : ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
@author    : Shiv Charan Panjeta 
 
All Rights Reserved.
Proprietary and confidential :  All information contained here in is, and remains
the property of ToXSL Technologies Pvt. Ltd. and it's partners.
Unauthorized copying of this file, via any medium is strictly prohibited.
 * 
 */

import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import HeaderLogin from '../../commoncomponents/HeaderLogin';
import TopTabbings from './TopTabbings';
import NewsSidebar from '../../commoncomponents/newsfeeds/NewsSidebar';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

import { useParams } from 'react-router-dom';
import {
  deleteAlbum,
  deleteImageAlbum,
  getAlbumDetail,
  getLikeUnlike,
  getSinglePostDetail,
  postComment,
  postFollowUnfollow,
  uploadImageInAlbum
} from '../../../services/SocialServices';
import { Modal } from 'react-bootstrap';
import showNotification from '../../../services/NotificationService';
import { useNavigate } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';
import { useSelector } from 'react-redux';
import { imageExtension } from '../../../globals/helper';

function AlbumManager() {
  const { albumId } = useParams();
  const [albumData, setAlbumData] = useState({});
  const [albumPics, setAlbumPics] = useState([]);
  const [selectedPreview, setSelectedPreview] = useState([]);
  const [showDelete, setShowDelete] = useState(false);
  const [show2, setShow2] = useState(false);
  const [loading, setIsLoading] = useState(false);
  const [clickedImage, setClickedImage] = useState('');
  const navigate = useNavigate();
  const [postId, setPostId] = useState('');
  const [progress, setProgress] = useState(0);
  const [showProgress, setShowProgress] = useState(false);
  const [deleteType, setDeleteType] = useState('');
  const [imgPostId, setImgPostId] = useState('');
  const [imageData, setImageData] = useState('');
  const [modalData, setModalData] = useState([]);
  const [modalImage, setModalImage] = useState([]);
  const [modalComment, setModalComment] = useState('');
  const userDetail = useSelector((state) => state?.user?.details);
  const [checkLike, setCheckLike] = useState([]);
  useEffect(() => {
    fetchSingleAlbumData();
  }, [albumId]);

  const fetchSingleAlbumData = () => {
    getAlbumDetail(albumId).then((res) => {
      if (res?.status == 200) {
        setAlbumData(res?.data?.data?.sk?.album);
        setAlbumPics(res?.data?.data?.sk?.photos);
      }
    });
  };

  const handleFile = (e) => {
    let rgxImage = /\.(image|png|gif|)$/i;
    if (imageExtension.includes(e.target.files[0].type)) {
      const arr = [];
      const targetfile = e?.target?.files;
      const targetfileobj = [...targetfile];
      targetfileobj.map((file) => {
        arr.push(URL.createObjectURL(file));
      });

      setSelectedPreview([...selectedPreview, ...arr]);
      setShowProgress(true);
      let formData = new FormData();
      formData.append(`album_id`, albumId);
      for (let i in targetfile) {
        formData.append(`photos[]`, targetfile[i]);
      }

      uploadImageInAlbum(formData, setProgress).then((res) => {
        if (res?.status == 200) {
          setShowProgress(false);
          showNotification('success', res?.data?.message);
          // navigate('/user/albums');
          fetchSingleAlbumData();
        }
      });
    } else {
      showNotification('danger', 'Please upload valid extension');
    }
  };

  const handleDeleteImage = (e) => {
    e.preventDefault();
    deleteImageAlbum(imgPostId).then((res) => {
      if (res?.status == 200) {
        fetchSingleAlbumData();
        setShowDelete(false);
      }
    });
  };

  const handleDeleteAlbum = (e) => {
    e.preventDefault();
    deleteAlbum(albumId).then((res) => {
      if (res?.status == 200) {
        showNotification('success', res?.data?.message);
        navigate('/user/albums');
      }
    });
  };

  const getPostSingleDetail = (id) => {
    getSinglePostDetail(id).then((res) => {
      if (res?.status === 200) {
        setModalImage(res?.data?.data);
      }
    });
  };
  // const handleGetComments = (i) => {
  //   albumPics?.map((data, index) => {
  //     if (index == i) {
  //       getPostSingleDetail(data?.id);
  //     }
  //   });
  // };
  const handleGetComments = (i) => {
    let id = albumPics?.filter((data, index) => {
      return index == i;
    });
    setPostId(id[0]?.post_id);
    getSinglePostDetail(id[0]?.post_id).then((res) => {
      if (res?.status === 200) {
        setModalImage(res?.data?.data);
      }
    });
  };

  const isPostcomment = (id, type, e) => {
    e.preventDefault();
    postComment(id, {
      text: type == 1 ? '' : modalComment,
      recipient_id: ''
    }).then((res) => {
      if (res?.status === 200) {
        // showNotification('success', res?.data?.message);
        setModalComment('');
        getPostSingleDetail(postId);
      }
    });
  };

  const handleClose2 = () => setShow2(false);
  const handleShow2 = (photosData, data, id) => {
    setShow2(true);
    setModalData(photosData);
    setClickedImage(id);
    getPostSingleDetail(data?.post_id);
    setPostId(data?.post_id);
  };

  const getPostLike = (e, id) => {
    e.preventDefault();
    getLikeUnlike(id).then((res) => {
      if (res?.status === 200) {
        getPostSingleDetail(postId);
        showNotification('success', res?.data?.message);
        setCheckLike(res?.data?.data);
        // getPostListData(true);
      }
    });
  };

  const getCommentLikes = (e, id, type) => {
    e.preventDefault();

    // setLoadingState('');
    getLikeUnlike(id).then((res) => {
      if (res?.status === 200) {
        showNotification('success', res?.data?.message);
        setCheckLike(res?.data?.data);
        // getPostListData(true);
        if (type == 'modal') {
          getPostSingleDetail(postId);
        }
      }
    });
  };
  const isFollowUnfollow = (e, id, type) => {
    // setLoadingState('');
    e.preventDefault();
    postFollowUnfollow(id).then((res) => {
      if (res?.status === 200) {
        showNotification('success', res?.data?.data);
        // setCheckFollow('');
        // getPostListData(true);
        if (type == 'modal') {
          getPostSingleDetail(postId);
        }
      }
    });
  };

  return (
    <>
      <HeaderLogin />
      <div className="header-lnk-feeds">
        <div className="container">
          <TopTabbings state={'social'} />
        </div>
      </div>
      <section className="feed-sec bg-grey py-4">
        <div className="container">
          <Row>
            <Col xs={12} lg={2} md={12}>
              <NewsSidebar state="1" current="album" />
            </Col>
            <Col xs={12} lg={10} md={12}>
              <div className="album-page bg-white p-0">
                <div className="module-hd d-flex align-items-center justify-content-between mb-3">
                  <h4>
                    Album Manager <i className="fas fa-users me-2"></i>
                  </h4>
                  <Link to="/user/create-album">
                    <i className="fas fa-plus me-2"></i>
                    Create an album
                  </Link>
                </div>
                <div className="album-time-hd d-flex align-items-center justify-content-between py-2 px-3 mb-2">
                  <div className="d-flex align-items-center">
                    <div className="drag-card p-1">
                      <i className="fas fa-heading me-2"></i>
                    </div>
                    <h5 className="mb-0">
                      {albumData?.name}
                      <span className="drag-card ms-2">
                        Drag and drop files or click below to upload
                      </span>
                    </h5>
                  </div>
                  {albumData?.user_album_type == 'album' && (
                    <Link to={`/user/edit_album/${albumData?.id}`}>
                      <i className="fas fa-plus me-2"></i>
                      Edit Album
                    </Link>
                  )}

                  <Link
                    to=""
                    onClick={() => {
                      setShowDelete(true);
                      setDeleteType('album');
                    }}>
                    <i className="fas fa-times me-2"></i>
                    Delete Album
                  </Link>
                </div>
                <div className="album-time-hd d-flex align-items-center  py-2 px-3 mb-2">
                  <i className="far fa-file-alt"></i> <div className="px-2">{albumData?.name}</div>
                </div>
                <div className="p-3">
                  <div className="album-drop-zone">
                    <Form.Group controlId="formFileMultiple" className="drop-card">
                      <Form.Control accept="image/*" type="file" multiple onChange={handleFile} />
                    </Form.Group>
                    {albumPics?.length !== 0 || selectedPreview?.length !== 0 ? (
                      <>
                        {/* <div className="albums-loader loading-skeleton">
                          <div className="album-img-lst position-relative">
                            <ul>
                              <li>
                                <Link className="album-img-card" to="#">
                                  <img
                                    src={require('../../../assets/images/default-male-avatar.png')}
                                    alt="img"
                                  />
                                </Link>
                                <Link
                                  className="album-img-del"
                                  to="#"
                                  // onClick={(e) => handleDeleteImage(e, data?.postid)}
                                >
                                  <i className="fas fa-trash-alt"></i>
                                </Link>
                              </li>
                            </ul>
                          </div>
                        </div> */}

                        <div className="album-img-lst">
                          <ul>
                            {albumPics?.map((data, index) => {
                              return (
                                <li key={index}>
                                  <Link
                                    className="album-img-card"
                                    to="#"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      handleShow2(albumPics, data, index);
                                    }}>
                                    <img src={data?.complete_url} alt="img" />
                                  </Link>
                                  <Link
                                    className="album-img-del"
                                    to="#"
                                    // onClick={(e) => handleDeleteImage(e, data?.postid)}
                                    onClick={() => {
                                      setShowDelete(true);
                                      setDeleteType('image');
                                      setImgPostId(data?.postid);
                                    }}>
                                    <i className="fas fa-trash-alt"></i>
                                  </Link>
                                </li>
                              );
                            })}
                            {selectedPreview?.length !== 0 &&
                              selectedPreview?.map((data, index) => {
                                return (
                                  <li key={index}>
                                    <Link className="album-img-card" to="#">
                                      <img src={data} alt="img" />
                                    </Link>
                                    {showProgress && (
                                      <div>
                                        <progress
                                          className="progress-bar-card"
                                          value={progress}
                                          max="100"
                                        />
                                      </div>
                                    )}
                                  </li>
                                );
                              })}
                          </ul>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="dz-default dz-message">
                          <span>Drop files here to upload</span>
                        </div>
                      </>
                    )}
                  </div>
                </div>
                <span className="drag-card ms-3 mb-3">
                  Drag and drop files or click above to upload
                </span>
              </div>
            </Col>
          </Row>
        </div>
      </section>
      {/* <FooterMain className={'colour-bg'} /> */}

      <Modal show={showDelete} onHide={() => setShowDelete(false)}>
        <Modal.Header>
          {deleteType == 'album'
            ? 'Delete Album' + '?'
            : ' Are you sure you want to delete this image ?'}
        </Modal.Header>
        <Modal.Body>
          <Button variant="secondary" className="me-2" onClick={() => setShowDelete(false)}>
            Close
          </Button>
          <Button
            variant="primary"
            type="button"
            onClick={deleteType == 'album' ? handleDeleteAlbum : handleDeleteImage}>
            Delete
          </Button>
        </Modal.Body>
      </Modal>

      {/* image lightbox modal */}
      <Modal
        show={show2}
        onHide={handleClose2}
        backdrop="static"
        keyboard={false}
        className="timeline-modal"
        size="xl">
        <Modal.Body>
          {loading ? (
            <div className="loader-modal">
              <img src={require('../../../assets/images/Loading_icon-small.gif')} alt="img" />
            </div>
          ) : (
            <div className="timeline-sec">
              <Row className="m-0">
                <Col className="p-0" md={8} sm={8}>
                  <div className="time-line-card bg-blck">
                    <Swiper
                      initialSlide={clickedImage}
                      spaceBetween={50}
                      slidesPerView={1}
                      modules={[Navigation]}
                      navigation={true}
                      onSlideChange={(swiper) => handleGetComments(swiper?.activeIndex)}>
                      {modalData?.map((item, index) => {
                        return (
                          <SwiperSlide key={index}>
                            <div className="timeline-slide-images">
                              <img src={item?.complete_url} alt="img" />
                            </div>
                          </SwiperSlide>
                        );
                      })}
                    </Swiper>
                  </div>
                </Col>
                <Col className="p-0" md={4} sm={4}>
                  <div className="time-line-cmmnts">
                    <Link className="close-modal-btn" to="#" onClick={handleClose2}>
                      <i className="fas fa-times"></i>
                    </Link>
                    <div className="pofile-upload-card">
                      <div className="profile-upload-sec d-flex align-items-top mb-3">
                        <span className="social-profile-icn me-3">
                          <img
                            src={process.env.REACT_APP_API_DOMAIN + modalImage?.user?.avtar}
                            // src={require('../../../assets/images/default-male-avatar.png')}
                            alt="img"
                          />
                        </span>
                        <div className="social-profile-detail d-flex align-items-top justify-content-between ">
                          <div className="social-profile-name">
                            <h4>
                              <Link to="#">{modalImage?.user?.first_name}</Link> updated his profile
                              picture{' '}
                            </h4>
                            <p>
                              <i className="far fa-clock me-2"></i>
                              {modalImage?.timestamp}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="profile-upload-ftr">
                        <div className="profile-likes-count d-flex align-items-center justify-content-between">
                          <div className="likes-count">
                            <Link to="#">{modalImage?.likes} Likes</Link>
                            <Link to="#">{modalImage?.shares} Shares</Link>
                          </div>
                        </div>
                        <div className="profile-like-btns">
                          <ul>
                            <li>
                              <Link
                                to=""
                                title="Like"
                                onClick={(e) => {
                                  getPostLike(e, modalImage?.id);
                                }}>
                                <i className="fas fa-thumbs-up me-2"></i>Like
                              </Link>
                            </li>
                            <li>
                              <Link to="#" title="Share">
                                <i className="fas fa-share me-2"></i>Share
                              </Link>
                            </li>
                            <li>
                              {modalImage?.is_follow == 1 ? (
                                <Link
                                  to="#"
                                  title="Unfollow this post"
                                  onClick={(e) => {
                                    isFollowUnfollow(e, modalImage?.id, 'modal');
                                  }}>
                                  Unfollow this post
                                </Link>
                              ) : (
                                <Link
                                  to="#"
                                  title="follow this post"
                                  onClick={(e) => {
                                    isFollowUnfollow(e, modalImage?.id, 'modal');
                                  }}>
                                  Follow this post
                                </Link>
                              )}
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="profile-upload-cmmnt mt-3">
                        {modalImage?.latest_comment?.length !== 0 ? (
                          <>
                            {modalImage?.latest_comment?.map((data, index) => (
                              <>
                                <div
                                  className="profile-cmmnt-lst d-flex align-items-top mb-3"
                                  key={index}>
                                  <span className="social-profile-icn me-3">
                                    <img
                                      src={process.env.REACT_APP_API_DOMAIN + data?.user?.avtar}
                                      alt="img"
                                    />
                                  </span>
                                  <div className="profile-cmmnt-user ">
                                    <div className="d-flex align-items-top justify-content-between">
                                      <Link to="#">{data?.user?.first_name}</Link>

                                      <div className="profile-cmmnt-time d-flex align-items-center ">
                                        <span>{data?.latest_comment?.timestamp}Comment</span>
                                      </div>
                                    </div>
                                    <div className="profile-cmmnt-dis">
                                      <p>{data?.text}</p>
                                      <div className="cmmnt-likes d-flex align-items-center">
                                        <Link
                                          to="#"
                                          onClick={(e) => {
                                            getCommentLikes(e, data?.id, 'modal');
                                          }}>
                                          <i className="fas fa-thumbs-up me-1"></i>
                                          {data?.likes}
                                          {/* {checkLike?.count} */}
                                        </Link>
                                        likes
                                      </div>
                                    </div>
                                  </div>
                                  {data?.user?.id === userDetail?.id ? (
                                    <Link
                                      className="close-btn"
                                      to="#"
                                      // onClick={() => {
                                      //   deletedPost(data?.id);
                                      // }}
                                    >
                                      <i className="fas fa-times"></i>
                                    </Link>
                                  ) : (
                                    <>
                                      {' '}
                                      <></>
                                    </>
                                  )}
                                </div>
                              </>
                            ))}
                          </>
                        ) : (
                          <></>
                        )}
                        {/* <div className="profile-cmmnt-lst d-flex align-items-top mb-3">
                          <span className="social-profile-icn me-3">
                            <img
                              src={require('../../../../assets/images/default-male-avatar.png')}
                              alt="img"
                            />
                          </span>
                          <div className="profile-cmmnt-user ">
                            <div className="d-flex align-items-top justify-content-between">
                              <Link to="#">userch one</Link>
                            </div>
                            <div className="profile-cmmnt-dis">
                              <p>Congratulations............</p>
                              <div className="cmmnt-likes d-flex align-items-center justify-content-between">
                                <span>3 years ago</span>
                                <div>
                                  <Link to="#">
                                    <i className="fas fa-thumbs-up me-1"></i>
                                    Likes
                                  </Link>
                                  - 10 Likes
                                </div>
                              </div>
                            </div>
                          </div>
                          <Link className="close-btn" to="#">
                            <i className="fas fa-times"></i>
                          </Link>
                        </div> */}

                        <div className="profile-cmmnt-input d-flex align-items-top">
                          <span className="social-profile-icn me-3">
                            <img
                              src={process.env.REACT_APP_API_DOMAIN + modalImage?.user?.avtar}
                              alt="img"
                            />
                          </span>
                          <div className="cmmnt-card">
                            <Form.Control
                              type="text"
                              onChange={(e) => {
                                setModalComment(e.target.value);
                              }}
                              onKeyPress={(e) => {
                                e.key == 'Enter' ? isPostcomment(modalImage?.id, 0, e) : '';
                              }}
                              value={modalComment}
                              placeholder="Write a comment... Press Enter to post"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
}

export default AlbumManager;
