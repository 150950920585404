import { useRef } from 'react';
import { Form, InputGroup, Col, Row } from 'react-bootstrap';
import { useOnClickOutside } from '../../../globals/helper';

function ChangeInviteForm(props) {
  const { setUpdatedCode, inviteCode, updateHandler, updatedCode, clicked, validate } = props;
  const ref = useRef();
  useOnClickOutside(ref, () => ref.current.classList.remove('active'));

  return (
    <>
      <div className="detail_form_div">
        <form className="contact-details" id="">
          <h2>Change Invite Code</h2>
          <div>
            <Row className={`form-group`}>
              <Col sm={4} xs={12}>
                <label className="" htmlFor="firstname">
                  Invite Code
                </label>
              </Col>
              <Col sm={6} xs={8}>
                <InputGroup className="w-100">
                  <Form.Control
                    placeholder="invite"
                    aria-label="Username"
                    aria-describedby="basic-addon1"
                    contentEditable={false}
                    value={inviteCode}
                  />
                </InputGroup>
              </Col>
              <Col sm={2} xs={4}></Col>
            </Row>{' '}
            <Row className={`form-group`} ref={ref}>
              <Col sm={4} xs={12}>
                <label className="" htmlFor="firstname">
                  Change Invite Code
                </label>
              </Col>
              <Col sm={6} xs={8}>
                <InputGroup className="w-100">
                  <Form.Control
                    placeholder="Enter New Invite Code"
                    aria-label="Username"
                    aria-describedby="basic-addon1"
                    onClick={() => {
                      ref.current.classList.add('active');
                    }}
                    value={updatedCode}
                    onChange={(e) => {
                      setUpdatedCode(e?.target?.value);
                    }}
                  />
                </InputGroup>
                <div className="danger-frm">{clicked && validate(updatedCode)?.message}</div>
              </Col>
              <Col sm={2} xs={4}></Col>
            </Row>{' '}
          </div>
          <div className="text-end py-3 px-3">
            {/* blk->class for ban type cursor */}
            <button
              className={`accnt-btn`}
              type="button"
              onClick={(e) => {
                updateHandler(e);
              }}
            >
              Update
            </button>
          </div>
        </form>
      </div>
    </>
  );
}

export default ChangeInviteForm;
