import moment from 'moment';
import { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { getEventsMonth, getInsEventList } from '../../../services/InstituteServices';
import Loader from '../../commoncomponents/loader/loader-large';

function EventInstitute() {
  const [eventList, setEventList] = useState([]);
  const [dateList, seDateList] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getInfo();
  }, []);

  const getInfo = () => {
    getInsEventList().then((res) => {
      setLoading(false)
      if (res?.status == 200) {
        setEventList(res?.data?.data);
      }
    });

    getEventsMonth().then((res) => {
      if (res?.status == 200) {
        seDateList(res?.data?.data?.output);
      }
    });
  };

  return (
    <>
      <div className="wrappermain_outer bggreylight">
        <section
          className="breadcrum-banner"
          style={{
            backgroundImage: `url(${eventList?.banner
              ? process.env.REACT_APP_API_URL + eventList?.banner?.url
              : '/static/media/breadcrum-banner-img.1a6b2ed71ebb8768883c.png'
              })`
          }}>
          <Container>
            <Row>
              <Col sm={12}>
                <h1 className="Text-white">{eventList?.banner?.name || 'Events'}</h1>
              </Col>
            </Row>
          </Container>
        </section>
        <section className="event-institute-rt py-5">
          <Container>
            <Row>
              <Col md={12} lg={9}>
                <div className="event-main-lst">
                  <ul>
                    {eventList?.list && eventList?.list?.length ? (
                      eventList?.list?.map((data, index) => {
                        return (
                          <li key={index}>
                            <Link to={`/event-detail-institute/${data?.slug}`} state={data?.id}>
                              <div className="event-page-card bg-white d-flex align-items-center">
                                <span className="event-date-card me-3">
                                  <h4> {moment(data?.from_event_date).format('MMM')}</h4>
                                  <p> {moment(data?.from_event_date).format('DD')}</p>
                                </span>
                                <div className="event-page-rt">
                                  <h5>{data?.name}</h5>
                                  <h4>{data?.venue}</h4>
                                </div>
                              </div>
                            </Link>
                          </li>
                        );
                      })
                    ) : (
                      <div className="text-center nodata">
                        <img src={require('../../../assets/images/nodata.png')} alt="nodata" />
                        <p>Data Not Found</p>
                      </div>
                    )}
                  </ul>
                </div>
              </Col>
              <Col md={12} lg={3}>
                <div className="archieve-card mb-3">
                  {dateList?.length ? <h4 className="arachive-hd mb-3">Archive</h4> : <></>}
                  <ul>
                    {dateList && dateList?.length ? (
                      dateList?.map((month, index) => (
                        <li key={index}>
                          <Link>
                            {month?.month_name} {month?.year_name} ({month?.count})
                          </Link>
                        </li>
                      ))
                    ) : (
                      <></>
                    )}
                  </ul>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </div>
      <div className="lgn-link py-4">
        <Container>
          <div className="logn-lnk-inr ">
            <p className=" text-start">
              <Link to="/institute-register">Register</Link> / <Link to="/institute-login">Login</Link> to browse
              members by Year, Name, Company, Profession or Location
            </p>
          </div>
        </Container>
      </div>
      {loading ? <Loader /> : <></>}
    </>
  );
}

export default EventInstitute;
