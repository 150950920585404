/**
@copyright  :   ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
@author     :   Shiv Charan Panjeta 
 
All Rights Reserved.
Proprietary and confidential :  All information contained here in is, and remains
the property of ToXSL Technologies Pvt. Ltd. and it's partners.
Unauthorized copying of this file, via any medium is strictly prohibited.
*/
import { Col, Nav, Row, Tab } from 'react-bootstrap';
import SecondEmailTempLayout from './SecondEmailTempLayout';
import WriteCode from './WriteCode';

function SecondTabEmailTemp({ nextStep, activeTab, id, idAdd, templateData, emailLayoutTab }) {
  return (
    <Tab.Container
      id="left-tabs-example"
      defaultActiveKey={emailLayoutTab == null && id ? 'third' : 'first'}>
      <Row>
        <Col sm={12}>
          {id && emailLayoutTab == null ? (
            <Nav variant="pills" className="template-tabs mb-4">
              <Nav.Item>
                <Nav.Link eventKey="third">Code your own</Nav.Link>
              </Nav.Item>
            </Nav>
          ) : (
            <Nav variant="pills" className="template-tabs mb-4">
              <Nav.Item>
                <Nav.Link eventKey="first">Layouts</Nav.Link>
              </Nav.Item>

              <Nav.Item>
                <Nav.Link eventKey="third">Code your own</Nav.Link>
              </Nav.Item>
            </Nav>
          )}
        </Col>
        <Col sm={12}>
          {id && emailLayoutTab == null ? (
            <Tab.Content>
              <Tab.Pane eventKey="third">
                <WriteCode
                  nextStep={nextStep}
                  activeTab={activeTab}
                  id={id}
                  idAdd={idAdd}
                  templateData={templateData}
                />
              </Tab.Pane>
            </Tab.Content>
          ) : (
            <Tab.Content>
              <Tab.Pane eventKey="first">
                <SecondEmailTempLayout
                  nextStep={nextStep}
                  activeTab={activeTab}
                  id={id}
                  idAdd={idAdd}
                />
              </Tab.Pane>
              <Tab.Pane eventKey="third">
                <WriteCode
                  nextStep={nextStep}
                  activeTab={activeTab}
                  id={id}
                  idAdd={idAdd}
                  templateData={templateData}
                />
              </Tab.Pane>
            </Tab.Content>
          )}
        </Col>
      </Row>
    </Tab.Container>
  );
}

export default SecondTabEmailTemp;
