import { useEffect, useState } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Dropdown from 'react-bootstrap/Dropdown';
import Form from 'react-bootstrap/Form';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Row from 'react-bootstrap/Row';
import { Link, useNavigate } from 'react-router-dom';
import useDocumentTitle from '../../../globals/useDocumentTitle';
import * as APISERVICES from '../../../services/MentorArticlesServices';
import FooterMain from '../../commoncomponents/FooterMain';
import HeaderLogin from '../../commoncomponents/HeaderLogin';
import NewsSidebar from '../../commoncomponents/newsfeeds/NewsSidebar';
import * as index from '../../commoncomponents/skelton/allskelton';
import TopTabbings from './TopTabbings';

function Friends() {
  useDocumentTitle('Friends List | AlmaBay');
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [friendData, setFriendData] = useState([]);
  const [searchLocation, setSearchLocation] = useState('');
  const [location, setLocation] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchbyName, setSearchByName] = useState('');
  const [cityId, setCityId] = useState([]);

  useEffect(() => {
    getLocationFriends();
  }, [cityId]);

  const getLocationFriends = (search, searchByName = '') => {
    setLoading(true);
    APISERVICES.getUserFriends(search).then((res) => {
      if (res?.status === 200) {
        setLoading(false);
        setLocation(res?.data?.data?.all_locations);
        if (cityId?.length > 0) {
          setFriendData(
            res?.data?.data?.friends?.filter((data) => {
              return cityId?.includes(data?.current_city_id);
            })
          );
        } else {
          setFriendData(
            searchByName?.length > 0
              ? res?.data?.data?.friends?.filter((data) => {
                  return data?.name?.toLowerCase().includes(searchByName.toLowerCase());
                })
              : res?.data?.data?.friends
          );
        }
      }
    });
  };

  const handleSearch = () => {};

  const handleSearchByLocation = (e) => {
    getLocationFriends(e.target.value);
    setSearchLocation(e.target.value);
  };

  const handleReset = () => {
    setSearchByName('');
    getLocationFriends('');
  };

  const handelSearchCity = (Id) => {
    if (cityId?.includes(Id)) {
      let filterId = cityId.filter((data) => data !== Id);
      setCityId(filterId);
      getLocationFriends();
    } else {
      setCityId([...cityId, Id]);
      getLocationFriends();
    }
  };

  const handleSearchByName = (e) => {
    setSearchByName(e.target.value);
    getLocationFriends('', e.target.value);
  };

  const handleRemoveFriend = (id) => {
    const body = {
      userData: id
    };
    const confirm = window.confirm('Do you want to remove your friend  ?');
    if (confirm) {
      APISERVICES.removeFriend(body).then((res) => {
        // getLocationFriends();
        window.location.reload()
      });
    }
  };

  const [locationArr, setLocationArr] = useState([]);
  const [searchName, setsearchName] = useState('');

  useEffect(() => {
    APISERVICES.filterfriend(searchName, locationArr.toString()).then((res) => {
      if (res?.status === 200) {
        setFriendData(res?.data?.data?.friends);
      }
    });
  }, [searchName, locationArr]);

  const getSearchedFilterData = (e, checkboxName) => {
    if (checkboxName == 'loc') {
      let updatedList = [...locationArr];
      if (e.target.checked) {
        updatedList = [...locationArr, e.target.value];
      } else {
        updatedList.splice(locationArr.indexOf(e.target.value), 1);
      }
      setLocationArr(updatedList);
    }
  };
  return (
    <>
      <HeaderLogin />
      <div className="header-lnk-feeds">
        <div className="container">
          <TopTabbings state={'feed'} />
        </div>
      </div>
      <section className="feed-sec bg-grey py-4">
        <div className="container">
          <Row>
            <Col xs={12} lg={2} md={12}>
              <NewsSidebar state="4" current="friend" />
            </Col>
            <Col xs={12} lg={7} md={12}>
              <div className="mentees-sec p-4 bg-white">
                <ul className="mentees-top-links mb-3">
                  <li>
                    <Link className="active" to="/user/friends">
                      Friends
                    </Link>
                  </li>
                  <li>
                    <Link to="/user/mentors">Mentors</Link>
                  </li>
                  <li>
                    <Link to="/user/mentees">Mentees</Link>
                  </li>
                </ul>
                {loading ? (
                  <>
                    <Row>
                      <Col md={4} sm={4} xs={6}>
                        <index.FriendsSkelton />
                      </Col>
                      <Col md={4} sm={4} xs={6}>
                        <index.FriendsSkelton />
                      </Col>
                      <Col md={4} sm={4} xs={6}>
                        <index.FriendsSkelton />
                      </Col>
                      <Col md={4} sm={4} xs={6}>
                        <index.FriendsSkelton />
                      </Col>
                      <Col md={4} sm={4} xs={6}>
                        <index.FriendsSkelton />
                      </Col>
                      <Col md={4} sm={4} xs={6}>
                        <index.FriendsSkelton />
                      </Col>
                    </Row>
                  </>
                ) : friendData && friendData?.length > 0 ? (
                  <div className="mentees-inr-card">
                    <Row>
                      {friendData?.map((data, index) => {
                        return (
                          <Col md={4} sm={4} xs={6} key={index}>
                            <div
                              style={{ height: 233 }}
                              className="mentees-pro-card d-flex flex-column justify-content-between"
                            >
                              <div className="mentees-img">
                                <Link to={`/user/timeline/${data?.encrypted_id}`}>
                                  <img
                                    src={
                                      data?.user_image
                                        ? data?.user_image
                                        : require('../../../assets/images/default-male-avatar.png')
                                    }
                                    alt="Profile Image"
                                  />
                                </Link>
                              </div>
                              <div className="mentees-dis">
                                <Link to={`/user/timeline/${data?.encrypted_id}`}>
                                  <h4>{data?.name}</h4>
                                </Link>
                                <p>{data?.current_city}</p>
                                <Dropdown className="mt-2">
                                  <Dropdown.Toggle variant="success" id="dropdown-basic">
                                    Your friend
                                  </Dropdown.Toggle>

                                  <Dropdown.Menu>
                                    <Dropdown.Item
                                      href="#"
                                      onClick={() =>
                                        navigate(`/user/timeline/${data?.encrypted_id}`)
                                      }
                                    >
                                      View Profile
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      href="#"
                                      onClick={() =>
                                        navigate(`/user/messages/${data?.encrypted_id}`)
                                      }
                                    >
                                      Send Message
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      href="#"
                                      onClick={() => handleRemoveFriend(data?.user_id)}
                                    >
                                      Unfriend
                                    </Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </div>
                            </div>
                          </Col>
                        );
                      })}
                    </Row>
                  </div>
                ) : (
                  <div className="upcoming_events p-3">
                    <div className="text-center">
                      <img src={require('../../../assets/images/small-error.png')} alt="img" />
                    </div>
                    <p className="text-center">
                      Oops, friends with current selection not available at the moment.
                    </p>
                  </div>
                )}
              </div>
            </Col>
            <Col xs={12} lg={3} md={12}>
              <Button
                className="mobile-filter-btn d-block d-lg-none "
                variant="primary"
                onClick={handleShow}
              >
                <i className="fas fa-filter"></i>
              </Button>

              <Offcanvas show={show} onHide={handleClose} className="mobile-filter">
                <Offcanvas.Body>
                  <div className="fiter-card">
                    <div className="filter-hd-card d-flex align-items-center justify-content-between">
                      <div className="filter-hd">
                        <h4>Filter By</h4>
                      </div>
                      <div className="filter-btn">
                        <Link to="#">
                          <i className="fas fa-filter"></i>
                        </Link>
                        <Link to="#">
                          <i className="fas fa-sync-alt"></i>
                        </Link>
                      </div>
                    </div>
                    <div className="filter-select-lst">
                      <Accordion defaultActiveKey={['0', '1', '2', '3']} alwaysOpen flush>
                        <Accordion.Item eventKey="0">
                          <Accordion.Header>By Name</Accordion.Header>
                          <Accordion.Body>
                            <Form.Group className="search-card" controlId="formBasicEmail">
                              <Form.Control
                                type="search"
                                placeholder="Search By Name"
                                onChange={(e) => setsearchName(e.target.value)}
                              />
                              <Button className="search-btn">
                                <i className="fas fa-search"></i>
                              </Button>
                            </Form.Group>
                          </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="1">
                          <Accordion.Header>Location</Accordion.Header>
                          <Accordion.Body>
                            <Form.Group className="search-card" controlId="formBasicEmail">
                              <Form.Control
                                onChange={handleSearchByLocation}
                                type="search"
                                placeholder="Search By Location"
                              />
                              <Button className="search-btn">
                                <i className="fas fa-search"></i>
                              </Button>
                            </Form.Group>

                            {location?.map((data, index) => {
                              return (
                                <div key={index}>
                                  <input
                                    type="checkbox"
                                    value={data?.city_id}
                                    onChange={(e) => getSearchedFilterData(e, 'loc')}
                                  />
                                  {data?.city}
                                </div>
                              );
                            })}
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    </div>
                  </div>
                </Offcanvas.Body>
              </Offcanvas>

              <div className="fiter-card d-none d-lg-block">
                <div className="filter-hd-card d-flex align-items-center justify-content-between">
                  <div className="filter-hd">
                    <h4>Filter Results</h4>
                  </div>
                  {/* <div className="filter-btn">
                    <Link to="#">
                      <i className="fas fa-filter"></i>
                    </Link>
                    <Link to="#">
                      <i className="fas fa-sync-alt"></i>
                    </Link>
                  </div> */}
                </div>
                <div className="filter-select-lst">
                  <Accordion defaultActiveKey={['0', '1', '2', '3']} alwaysOpen flush>
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>By Name</Accordion.Header>
                      <Accordion.Body>
                        <Form.Group className="search-card" controlId="formBasicEmail">
                          <Form.Control
                            type="search"
                            placeholder="Search By Name"
                            onChange={(e) => setsearchName(e.target.value)}
                          />
                          <Button className="search-btn">
                            <i className="fas fa-search"></i>
                          </Button>
                        </Form.Group>
                      </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="1">
                      <Accordion.Header>Location</Accordion.Header>
                      <Accordion.Body>
                        <Form.Group className="search-card" controlId="formBasicEmail">
                          <Form.Control
                            onChange={handleSearchByLocation}
                            type="search"
                            placeholder="Search By Location"
                          />
                          <Button className="search-btn">
                            <i className="fas fa-search"></i>
                          </Button>
                        </Form.Group>

                        {location?.map((data, index) => {
                          return (
                            <div key={index}>
                              <input
                                type="checkbox"
                                value={data?.city_id}
                                onChange={(e) => getSearchedFilterData(e, 'loc')}
                              />
                              {data?.city}
                            </div>
                          );
                        })}
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </section>
      <FooterMain className={'colour-bg'} />
    </>
  );
}

export default Friends;
