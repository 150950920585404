/**
@copyright  :   ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
@author     :   Shiv Charan Panjeta 
 
All Rights Reserved.
Proprietary and confidential :  All information contained here in is, and remains
the property of ToXSL Technologies Pvt. Ltd. and it's partners.
Unauthorized copying of this file, via any medium is strictly prohibited.
*/
import { useEffect, useState } from 'react';
import { Button, Container } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Pagination from 'react-bootstrap/Pagination';
import Row from 'react-bootstrap/Row';
import Table from 'react-bootstrap/Table';
import ReactPaginate from 'react-paginate';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { ZERO } from '../../../globals/constants';
import { listingManageSponsors, selectedSponser } from '../../../services/AuthServices';

export default function SponsorRequest() {
  let location = useLocation();
  let navigate = useNavigate();
  const eventId = location?.state;
  let active = 2;
  let items = [];
  for (let number = 1; number <= 5; number++) {
    items.push(
      <Pagination.Item key={number} active={number === active}>
        {number}
      </Pagination.Item>
    );
  }

  const [searchData, setSearchData] = useState({
    sponser_id: '',
    sponser_name: '',
    sponser_status: ''
  });
  const [action, setAction] = useState('');
  const [chekcedId, setCheckedId] = useState([]);
  const [allId, setAllId] = useState([]);
  const [list, setList] = useState([]);
  // const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const handleAddNavigate = (e) => {
    e.preventDefault();
    navigate('/admin/event/AddEventSponsor', { state: eventId });
  };
  useEffect(() => {
    apiCall();
  }, []);
  const apiCall = (
    page = 1,
    event_id = eventId,
    sponser_id = '',
    sponser_name = '',
    sponser_status = ''
  ) => {
    setCurrentPage(page);
    let body = {
      event_id,
      sponser_id,
      sponser_name,
      sponser_status
    };
    listingManageSponsors(body).then((resp) => {
      if (resp?.status === 200) {
        setList(resp?.data?.detail?.viewlist);
        setTotalCount(resp?.data?.detail?.totalRecords);
        setAllId(resp?.data?.detail.viewlist.map((data) => data?.id));
      }
    });
  };

  const onChangeSearch = (e) => {
    const { name, value } = e.target;
    setSearchData({ ...searchData, [name]: value });
  };

  const searchHandler = (e) => {
    e.preventDefault();
    apiCall(
      currentPage,
      eventId,
      searchData?.sponser_id,
      searchData?.sponser_name,
      searchData?.sponser_status
    );
  };
  // pagination
  const handlePageChange = (a) => {
    apiCall(a?.selected + 1);
  };

  const resetHandler = (e) => {
    e.preventDefault();
    setSearchData({
      event_id: '',
      sponser_id: '',
      sponser_name: '',
      sponser_status: ''
    });
    apiCall();
  };

  const handlecheckbox = (id) => {
    if (chekcedId?.includes(id)) {
      let filterId = chekcedId.filter((data) => data !== id);
      setCheckedId(filterId);
    } else {
      setCheckedId([...chekcedId, id]);
    }
  };

  const handleSelectAll = (e) => {
    if (e.target.checked === true) {
      setCheckedId(allId);
    } else {
      setCheckedId([]);
    }
  };

  const handleAction = (e) => {
    setAction(e.target.value);
    const body = {
      ids: chekcedId?.toString(),
      action: e.target.value
    };
    // setLoading(true);
    selectedSponser(body).then((resp) => {
      // setLoading(false);
      if (resp.status === 200) {
        apiCall();
        // showNotification('success', resp?.data?.message);
      }
    });
  };

  return (
    <div className="dash-main-content">
      <Container fluid>
        <div className="dash-page-content">
          <div className="dash-page-title">
            <Row>
              <Col xs={12} sm={12} md={12}>
                <div className="dash-title-bar d-flex align-items-center justify-content-between">
                  <div className="title-lft d-flex align-items-center">
                    <div className="dash-icn">
                      <i className="fa fa-users"></i>
                    </div>
                    <div className="dash-title-dis">
                      <h5>
                        Event Sponsors <span>Listing</span>
                      </h5>
                      <h5>
                        Total Record(s): <span>{totalCount ? totalCount : ''}</span>
                      </h5>
                    </div>
                  </div>
                  <div className="title-rt-btn ">
                    <Link
                      className="add-btn-grn"
                      to="#"
                      onClick={(e) => {
                        handleAddNavigate(e);
                      }}>
                      Add Sponsors
                    </Link>

                    <div className="head-instite-sec mx-2 ">
                      <Form.Select aria-label="Default select example " onChange={handleAction}>
                        <option>Choose Action</option>
                        <option value="active">Enable</option>
                        <option value="inactive">Disable</option>
                        {/* <option value="del">Delete</option>
                          <option value="delImg">Delete Image Only </option> */}
                      </Form.Select>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <div className="dash-content-card">
            <div className="table-responsive">
              <Table className="custom-table-data" striped bordered hover>
                <thead>
                  <tr>
                    <th>
                      <Form.Check
                        type="checkbox"
                        id="checkbox"
                        label=""
                        onChange={(e) => {
                          handleSelectAll(e);
                        }}
                      />
                    </th>
                    <th>
                      <Link to="">
                        Sponsor Logo
                        {/* <img src={require('../../assets/images/sort.png')} alt="sort" /> */}
                      </Link>
                    </th>
                    <th>
                      <Link to="">
                        Id
                        {/* <img src={require('../../assets/images/sort.png')} alt="sort" /> */}
                      </Link>
                    </th>
                    <th>
                      <Link to="">
                        Sponsor Name
                        {/* <img src={require('../../assets/images/sort.png')} alt="sort" /> */}
                      </Link>
                    </th>
                    <th>
                      Status
                      {/* <img src={require('../../assets/images/sort.png')} alt="sort" /> */}
                    </th>

                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="search-table">
                    <td></td>
                    <td></td>
                    <td>
                      <Form.Control
                        type="text"
                        placeholder="ID"
                        value={searchData?.sponser_id}
                        name="sponser_id"
                        onChange={(e) => {
                          onChangeSearch(e);
                        }}
                      />
                    </td>
                    <td>
                      <Form.Control
                        type="text"
                        value={searchData?.sponser_name}
                        name="sponser_name"
                        placeholder="Sponser name"
                        onChange={(e) => {
                          onChangeSearch(e);
                        }}
                      />
                    </td>

                    <td>
                      <Form.Select
                        aria-label="Default select example"
                        value={searchData?.sponser_status}
                        name="sponser_status"
                        placeholder="Sponser Status"
                        onChange={(e) => {
                          onChangeSearch(e);
                        }}>
                        <option> - Select - </option>
                        <option value="1">Active</option>
                        <option value="2">Inactive</option>
                      </Form.Select>
                    </td>

                    <td className="serac-btns">
                      <Button
                        className="main-btn-grn"
                        onClick={(e) => {
                          searchHandler(e);
                        }}>
                        Search
                      </Button>

                      <Button
                        className="main-btn-red ms-2"
                        onClick={(e) => {
                          resetHandler(e);
                        }}>
                        Reset
                      </Button>
                    </td>
                  </tr>

                  {list && list != ZERO
                    ? list?.map((data, index) => {
                        return (
                          <tr key={index}>
                            <td>
                              <Form.Check
                                type="checkbox"
                                id="checkbox"
                                label=""
                                checked={chekcedId?.includes(data?.id)}
                                onClick={() => handlecheckbox(data?.id)}
                              />
                            </td>
                            <td>{data?.sponser_logo || 'N/A'}</td>
                            <td>{data?.id}</td>
                            <td>{data?.sponser_name} </td>
                            <td>{data?.status == 1 ? 'Active ' : 'Inactive'}</td>

                            <td align="center">
                              <Link
                                to={{
                                  pathname: `/admin/event/editEventSponsor/${data?.event_id}/${data?.id}`,
                                  State: data?.event_id
                                }}
                                // target="_blank"
                                title="EDIT">
                                <i className="fa fa-edit m-2"></i>
                              </Link>

                              <Link
                                to={`/admin/event/sponsorRequestView/${data?.event_id}/${data?.id}`}
                                title="VIEW">
                                <i className="fa fa-eye" aria-hidden="true"></i>
                              </Link>
                            </td>
                          </tr>
                        );
                      })
                    : ''}
                </tbody>
              </Table>
              {list.length !== 0 && (
                <>
                  {totalCount > 10 && (
                    <div className="react-page d-flex">
                      <ReactPaginate
                        previousLabel={'<'}
                        nextLabel={'>'}
                        breakLabel={'...'}
                        pageCount={Math.ceil(totalCount / 10)}
                        breakClassName={'break-me'}
                        pageRangeDisplayed={3}
                        onPageChange={handlePageChange}
                        activeClassName={'page-link active'}
                      />
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}
