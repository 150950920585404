/**
@copyright  :   ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
@author     :   Shiv Charan Panjeta 
 
All Rights Reserved.
Proprietary and confidential :  All information contained here in is, and remains
the property of ToXSL Technologies Pvt. Ltd. and it's partners.
Unauthorized copying of this file, via any medium is strictly prohibited.
*/
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { Link, useNavigate, useParams } from 'react-router-dom';
import NoDataFound from '../../../components/commoncomponents/NoDataFound.jsx';
import Loader from '../../../components/commoncomponents/loader/loader-large.jsx';
import { ZERO } from '../../../globals/constants.js';
import useDocumentTitle from '../../../globals/useDocumentTitle';
import { viewEventBookingDetails } from '../../../services/AuthServices.js';

export default function ViewBookingDetails() {
  const [loading, setLoading] = useState(false);
  useDocumentTitle(`Ticket Booking Details | Almabay`);
  const { id } = useParams();
  useEffect(() => {
    apiCall();
  }, []);
  const [bookingBuyerInfoState, setBookingBuyerInfoState] = useState();
  const [eventDetailInfoState, setEventDetailInfoState] = useState();
  const [orderDetailInfoState, setOrderDetailInfoState] = useState();
  const [orderSummaryState, setOrderSummaryState] = useState();
  const apiCall = () => {
    setLoading(true);
    viewEventBookingDetails(id).then((resp) => {
      setLoading(false);
      if (resp?.status === 200) {
        setLoading(false);
        setBookingBuyerInfoState(resp?.data?.detail?.buyer_info);
        setEventDetailInfoState(resp?.data?.detail?.event_detail);
        setOrderDetailInfoState(resp?.data?.detail?.order_detail);
        setOrderSummaryState(resp?.data?.detail?.order_summary);
      }
    });
  };
  let navigate = useNavigate();
  return (
    <>
      <div className="dash-main-content">
        <Container fluid>
          <div className="dash-page-content">
            <div className="dash-page-title">
              <Row className="align-items-end">
                <Col xs={12} sm={12} md={12}>
                  <div className="dash-title-bar d-flex align-items-center justify-content-between">
                    <div className="title-lft d-flex align-items-center">
                      <div className="dash-title-dis">
                        <h5>
                          View Booking <span>Details</span>
                        </h5>
                      </div>
                    </div>
                    <div className="title-rt-btn d-flex align-items-center">
                      <Link
                        className="add-btn-grn"
                        to="#"
                        onClick={(e) => {
                          e.preventDefault();
                          navigate(-1);
                        }}>
                        Back To Listing
                      </Link>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="box_inner dash-content-card">
              <div id="order-detail">
                <div className="right-main">
                  <div className="row">
                    <div className="col-lg-4">
                      <div className="order">
                        <h2>Booking ID</h2>
                        <h3>{orderDetailInfoState?.id}</h3>
                      </div>
                    </div>

                    <div className="col-lg-4">
                      <div className="order">
                        <h2>Booking Date</h2>
                        <h3>{moment(orderDetailInfoState?.date_created).format('LLLL')}</h3>
                      </div>
                    </div>

                    <div className="col-lg-4">
                      <div className="order">
                        <h2>Total</h2>
                        <h3>
                          {orderDetailInfoState?.total_price
                            ? 'RS.' + orderDetailInfoState?.total_price
                            : ''}
                        </h3>
                      </div>
                    </div>

                    <div className="col-lg-4">
                      <div className="order">
                        <h2>Booking Status</h2>
                        <h3>Ticket Booked</h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="event_info">
                <div className="row">
                  <div className="col1">
                    <strong>Event Details : </strong>
                    <div>
                      <div className="row">
                        <div className="col1">
                          <strong>Name: </strong>
                          {eventDetailInfoState?.name}
                        </div>
                      </div>

                      <div className="row">
                        <div className="col4">
                          <strong>Privacy: </strong>
                          {eventDetailInfoState?.event_privacy}
                        </div>

                        <div className="col4">
                          <strong>Category: </strong>
                          {eventDetailInfoState?.category_name}
                        </div>
                      </div>

                      <div className="row">
                        <div className="col4">
                          <strong>Venue: </strong>
                          {eventDetailInfoState?.venue}
                        </div>

                        <div className="col4">
                          <strong>City: </strong>
                          {eventDetailInfoState?.city_name}
                        </div>

                        <div className="col2">
                          <strong>Event Date: </strong>
                          {moment(eventDetailInfoState?.from_event_date).format('LLL') +
                            '-' +
                            moment(eventDetailInfoState?.to_event_date).format('LLL')}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col1">
                  <strong>Ticket Buyer : </strong>
                  <div className="ticket_container panel_body">
                    <table
                      align="center"
                      width="100%"
                      border="1"
                      cellPadding="2"
                      cellSpacing="0"
                      bordercolor="#B4ACAC">
                      <tbody>
                        <tr height="35">
                          <td>
                            <table
                              className="registrationTable"
                              cellPadding="2"
                              cellSpacing="0"
                              border="0"
                              width="100%">
                              <tbody>
                                <tr>
                                  <td align="right" valign="top">
                                    <label htmlFor="first_name">First Name </label>
                                  </td>
                                  <td valign="top">
                                    <span className="error">&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                  </td>
                                  <td>{bookingBuyerInfoState?.first_name} </td>
                                </tr>
                                <tr>
                                  <td align="right" valign="top">
                                    <label htmlFor="last_name">Last Name </label>
                                  </td>
                                  <td valign="top">
                                    <span className="error">&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                  </td>
                                  <td>{bookingBuyerInfoState?.last_name} </td>
                                </tr>
                                <tr>
                                  <td align="right" valign="top">
                                    <label htmlFor="email_address">Email Address </label>
                                  </td>
                                  <td valign="top">
                                    <span className="error">&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                  </td>
                                  <td>{bookingBuyerInfoState?.email}</td>
                                </tr>
                                <tr>
                                  <td align="right" valign="top">
                                    <label htmlFor="confirm_email_address">Mobile </label>
                                  </td>
                                  <td valign="top">
                                    <span className="error">&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                  </td>
                                  <td>{bookingBuyerInfoState?.mobile}</td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col1">
                  <strong>Order Summary : </strong>
                  <table align="center" width="100%" border="1" cellPadding="2" cellSpacing="0">
                    <thead>
                      <tr height="35">
                        <th valign="middle">Ticket Name</th>
                        <th valign="middle">Type</th>
                        <th valign="middle">Price</th>
                        <th valign="middle">Quantity</th>
                        <th valign="middle">Sub Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      {orderSummaryState?.length !== ZERO ? (
                        orderSummaryState?.map((data, index) => (
                          <tr height="25" key={index}>
                            <td className="product">{data?.ticket_name}</td>
                            <td>{data?.ticket_type}</td>
                            <td>Rs. {data?.total_price} </td>
                            <td>{data?.quantity}</td>
                            <td>Rs. {data?.total_price}</td>
                          </tr>
                        ))
                      ) : (
                        <NoDataFound text="No order summary found" />
                      )}

                      <tr className="total-amount" height="25">
                        <td colSpan="4" align="right">
                          <b>Order total:</b>
                        </td>
                        <td align="left">
                          <b>Rs. {orderDetailInfoState?.total_price} </b>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
      {loading ? <Loader /> : ''}
    </>
  );
}
