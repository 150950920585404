import { useEffect } from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { resetChapterList } from '../../../redux/action';
import ChapterMap from './components/ChapterMap';

function Chapters() {
  const chapterDetails = useSelector((state) => state?.chapter);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    return () => dispatch(resetChapterList());
  }, []);

  return (
    <>
      <div className="wrappermain_outer">
        <section className="allchapters-section" style={{ backgroundColor: '#dedede' }}>
          <Container fluid>
            <Row>
              <Col lg={chapterDetails?.chapterList?.length > 0 ? 8 : 12}>
                <div style={{ paddingTop: 650 }} className={'chaptermap show_from'}>
                  <ChapterMap />
                </div>
              </Col>
              <Col lg={4}>
                <div className="chapter-container py-3">
                  {chapterDetails?.chapterList?.length > 0 && (
                    <p className="result">
                      {/* {chapterDetails?.chapterCount} Results */}
                      {chapterDetails?.count} Result -
                      <span className="ms-3">
                        {chapterDetails?.chapterCount} of {chapterDetails?.chapterCount}
                      </span>{' '}
                    </p>
                  )}
                  {chapterDetails?.chapterList?.map((item, i) => {
                    return (
                      <>
                        <Card key={i} className="mb-3">
                          <Card.Body>
                            <div className="d-flex align-items-center single-chapter">
                              <div className="me-4">
                                <img src={item.avatar_url} alt="img" width="80px" height="80px" />
                              </div>
                              <div
                                style={{ cursor: 'pointer' }}
                                className="single-chapter-detail"
                                role="presentation"
                                onClick={() => navigate('/chapter-details', { state: item })}>
                                {/* <p className="mb-2">1999-2000</p> */}
                                <h5 className="mb-2">{item.name}</h5>
                                <p
                                  style={{ textTransform: 'capitalize' }}
                                  className="close-club mb-0">
                                  {item.chapter_privacy} chapter
                                  {item.verified == 1 && (
                                    <span className="verified-icn ms-1">
                                      <img
                                        src={require('../../../assets/images/institute-img/verfied.png')}
                                        alt="club"
                                      />
                                    </span>
                                  )}
                                </p>
                              </div>
                            </div>
                          </Card.Body>
                        </Card>
                      </>
                    );
                  })}
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </div>
      <div
        style={{
          background: '#dedede'
        }}
        className="lgn-link py-4">
        <Container>
          <div className="logn-lnk-inr">
            <p>
              <Link to="/institute-register">Register</Link> / <Link to="/institute-login">Login</Link> to browse
              members by Year, Name, Company, Profession or Location
            </p>
          </div>
        </Container>
      </div>
    </>
  );
}

export default Chapters;
