//  @copyright : ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
// @author     : Shiv Charan Panjeta

// All Rights Reserved.
// Proprietary and confidential :  All information contained herein is, and remains
// the property of ToXSL Technologies Pvt. Ltd. and its partners.
// Unauthorized copying of this file, via any medium is strictly prohibited.
import { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Loader from '../../components/commoncomponents/loader/loader-large';
import { viewClubContactPerson } from '../../services/InstituteServices';

export default function ClubViewContactPerson() {
  const { id } = useParams();
  const [contactPersonDetail, setContactPersonDetail] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (id) {
      getDetail(id);
    }
  }, [id]);

  const getDetail = (id) => {
    setLoading(true);
    viewClubContactPerson(id).then((res) => {
      if (res?.status === 200) {
        setLoading(false);
        setContactPersonDetail(res?.data?.data);
      }
    });
  };

  const statusName = (key) => {
    switch (key) {
      case '0':
        return 'Disable';
      case '1':
        return 'Enable';
      case '2':
        return 'Delete';
    }
  };

  return (
    <>
      <div className="dash-main-content">
        <Container fluid>
          <div className="dash-page-content">
            <div className="dash-page-title">
              <Row>
                <Col xs={12} sm={12} md={12}>
                  <div className="dash-title-bar d-flex align-items-center justify-content-between">
                    <div className="title-lft d-flex align-items-center">
                      <div className="dash-icn">
                        <i className="fa fa-file"></i>
                      </div>
                      <div className="d-flex align-items-center justify-content-between">
                        <div className="dash-title-dis">
                          <h5>
                            Contact Person <span>Details</span>
                          </h5>
                        </div>
                      </div>
                    </div>
                    <div className="title-rt-btn">
                      <Link
                        className="text-dark font-weight-bold"
                        to=""
                        onClick={(e) => {
                          e.preventDefault();
                          navigate(-1);
                        }}>
                        Back To Listing
                      </Link>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="dash-content-card">
              <div className="view_detials_content">
                <div className="mb-3">
                  <strong>name:</strong> {contactPersonDetail?.name}
                </div>
                <div className="mb-3">
                  <strong>Designation:</strong> {contactPersonDetail?.designation}
                </div>
                <div className="mb-3">
                  <strong>Institute Name:</strong> {contactPersonDetail?.institute_name}
                </div>
                <div className="mb-3">
                  <strong>email:</strong> {contactPersonDetail?.email}
                </div>
                <div className="mb-3">
                  <strong>Address:</strong> {contactPersonDetail?.address}
                </div>
                <div className="mb-3">
                  <strong>Status:</strong> {statusName(contactPersonDetail?.status)}
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
      {loading ? <Loader /> : ''}
    </>
  );
}
