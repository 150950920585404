/**
 * @copyright : ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
@author    : Shiv Charan Panjeta 
 
All Rights Reserved.
Proprietary and confidential :  All information contained here in is, and remains
the property of ToXSL Technologies Pvt. Ltd. and it's partners.
Unauthorized copying of this file, via any medium is strictly prohibited.
*/
import { useEffect, useState } from 'react';
import { getInsNews } from '../../../../services/InstituteServices';
import { truncate } from '../../../../globals/helper';

function WhatsNewCard() {
  const [newsList, setNewsList] = useState([]);

  useEffect(() => {
    getInfo();
  }, []);
  /**
   * get what's new data based on institute domain
   */
  const getInfo = () => {
    getInsNews().then((res) => {
      if (res?.status == 200) {
        setNewsList(res?.data?.data);
      }
    });
  };

  return (
    <>
      <div className="whats-card-detail">
        <h4 className="whats-hd-sec">Whats New</h4>
        <ul className="whats-card-lst">
          {newsList?.list && newsList?.list?.length ? (
            newsList?.list?.slice(0, 3)?.map((data, index) => (
              <li key={index}>
                <div className="whats-inr-card d-flex align-items-center">
                  <div className="whats-icn">
                    <img
                      src={
                        data?.url
                          ? process.env.REACT_APP_API_DOMAIN + data?.url
                          : require('../../../../assets/images/institute-img/almabay-logo.png')
                      }
                      alt="club"
                    />
                  </div>
                  <div className="whats-card-dis">
                    <h4>{data?.title}</h4>
                    <span dangerouslySetInnerHTML={{ __html: truncate(data?.description, 100) }} />
                    {/* <p>Connect With Your Almamater So, you’re done with your graduation and are ...</p> */}
                  </div>
                </div>
              </li>
            ))
          ) : (
            <> No Data Found at this moment</>
          )}
        </ul>
      </div>
    </>
  );
}

export default WhatsNewCard;
