import * as api from '../utils/requests';

export const list = async (room_id = '') => {
  return await api.getReq(`/chat/index?room_id=${room_id}`);
};

export const add = async (room_id = '') => {
  return await api.getReq(`/chat/add?room_id=${room_id}`);
};

export const encUser = async (room_id = '') => {
  return await api.getReq(`/chat/getUserInfoByEncId?room_id=${room_id}`);
};

export const testing = async (data) => {
  return await api.PostReq('/admin/auth-login', data);
};
