/** @copyright : ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
 @author     : Shiv Charan Panjeta 

 All Rights Reserved.
 Proprietary and confidential :  All information contained herein is, and remains
 the property of ToXSL Technologies Pvt. Ltd. and its partners.
 Unauthorized copying of this file, via any medium is strictly prohibited.
*/
import { Col, Container, Row, Tab, Tabs } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import AllAlbums from './components/AllAlbums';
import AllContent from './components/AllContent';
import AllVideos from './components/AllVideos';
import ImagesContent from './components/ImagesContent';
import { getGalleryArchive } from '../../../services/InstituteServices';
import { useState, useEffect } from 'react';
import Loader from '../../commoncomponents/loader/loader-large';

function GalleryPage() {
  const [archive, SetArchive] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    getGalleryArchive().then((res) => {
      setLoading(false);
      if (res?.status === 200) {
        SetArchive(res?.data?.data?.output || []);
      }
    });
  };

  return (
    <>
      <div className="wrappermain_outer">
        <section className="breadcrum-banner">
          <Container>
            <Row>
              <Col sm={12}>
                <h1 className="Text-white">Gallery</h1>
              </Col>
            </Row>
          </Container>
        </section>

        <section className="gallery-section">
          <Container>
            <Row>
              <Col sm={12}></Col>

              <Col lg={8}>
                <Tabs defaultActiveKey="All" id="fill-tab-example" className="mb-3" fill>
                  <Tab eventKey="All" title="All">
                    <AllContent />
                  </Tab>
                  <Tab eventKey="Photos" title="Photos">
                    <ImagesContent />
                  </Tab>
                  <Tab eventKey="Albums" title="Albums">
                    <AllAlbums />
                  </Tab>
                  <Tab eventKey="Videos" title="Videos">
                    <AllVideos />
                  </Tab>
                </Tabs>
              </Col>

              <Col lg={4}>
                {archive?.length ? (
                  <div className="archive-section mt-5">
                    <h4>Archive</h4>
                    <ul>
                      {archive && archive?.length ? (
                        archive?.map((data, index) => (
                          <li key={index}>
                            <Link>
                              {data?.month_name} {data?.year_name} ({data?.count})
                            </Link>
                          </li>
                        ))
                      ) : (
                        <>Data Not Found</>
                      )}
                    </ul>
                  </div>
                ) : (
                  <></>
                )}
              </Col>
            </Row>
          </Container>
        </section>
      </div>
      <div className="lgn-link pb-4">
        <Container>
          <div className="logn-lnk-inr">
            <p>
              <Link to="/institute-register">Register</Link> /{' '}
              <Link to="/institute-login">Login</Link> to browse members by Year, Name, Company,
              Profession or Location
            </p>
          </div>
        </Container>
      </div>
      {loading ? <Loader /> : <></>}
    </>
  );
}

export default GalleryPage;
