import * as api from '../utils/requests';

/**
 * for get Image Url - https://img1.almabay.com/11358/AYAHb_11358_2e99b7de2efefc11e5f273f35e10e0c7_s_64.jpg
 *
 * Calling from helper.js
 *
 *  */
export const notificationsDropdown = async (t = 'notifications') => {
  return await api.getReq(`/user/headerNotifications?t=${t}`);
};

export const SK_getNotifications = async (page) => {
  return await api.getReq(`/user/allNotificationsList?page=${page}`);
};

export const userProfilePic = async (url, body = '') => {
  return await api.getReq(url);
};

export const userBasicInfo = async () => {
  return await api.getReq(`/user/userBasicInfo`);
};

export const updateBasicInfo = async (body) => {
  return await api.PostReq(`/user/updateUserBasicInfo`, body);
};
export const getGender = async () => {
  return await api.getReq(`/dropdown/gender`);
};

export const updateContactInfo = async (data) => {
  return await api.PostReq(`/user/updateContactInfo`, data);
};

export const updateUserAvatar = async (body) => {
  return await api.PostReq(`/user/updateAvatar`, body);
};

export const userInviteCode = async () => {
  return await api.getReq(`/user/userInviteCode`);
};

export const userUpdateCode = async (body) => {
  return await api.PostReq(`/user/updateInviteCode`, body);
};

// work detail
export const userWorkDetails = async () => {
  return await api.getReq(`/user/userWorkInfo`);
};

//education detail
export const userEducationDetails = async () => {
  return await api.getReq(`/user/educationInfoList`);
};

export const addWorkDetail = async (body) => {
  return await api.PostReq(`/user/createUserWorkInfo`, body);
};

export const updateWorkDetail = async (body) => {
  return await api.PostReq(`/user/updateUserWorkInfo?t=user&a=editinfonew`, body);
};

export const deleteWorkDetail = async (id) => {
  return await api.delReq(`/user/deleteUserWorkInfo?id=${id}`);
};

export const getUserNotification = async () => {
  return await api.getReq(`/user/notificationDetails`);
};

export const updateUserNotification = async (body) => {
  return await api.PostReq(`/user/updateNotification`, body);
};

export const addEducationDetail = async (body) => {
  return await api.PostReq(`/user/createEducationInfo`, body);
};

export const updatePrivacy = async (body) => {
  return await api.PostReq(`/user/updatePrivacyDetails`, body);
};

export const locationDetail = async (search = 'Punjab, India') => {
  return await api.getReq(`/dropdown/getGoogleCities?search=${search}`);
};

export const getLocationTags = async (search = '', page = '1', type = 'internship') => {
  return await api.getReq(
    `life_skills_api/getLocationTags?q=${search}&page=${page}&job_type=${type}&limit=50`
  );
};
export const getPrivacydetail = async () => {
  return await api.getReq(`/user/privacyDetails`);
};

/*
 *
 * dropdown-lists
 */

export const getOrganizationDropdown = async () => {
  return await api.getReq(`/dropdown/organisationType`);
};

export const getInstituteDropdown = async () => {
  return await api.getReq(`/dropdown/instituteList`); //can be used for company-dropdown
};

export const getWorkLevelDropdown = async () => {
  return await api.getReq(`/dropdown/workLevelList`);
};

export const getDepartmentDropdown = async (id) => {
  return await api.getReq(`/dropdown/departmentList?institute_id=${id}`);
};

export const getDeparDropdown = async (q = '') => {
  return await api.getReq(`/life-skills-api/instituteDepartmentsList?q=${q}`);
};

export const getCourseOptList = async (q = '') => {
  return await api.getReq(`/life-skills-api/getUserEducationCourseList?q=${q}`);
};

export const getPrivacyDropdown = async () => {
  return await api.getReq(`/dropdown/privacyOptions`);
};

export const getShortPrivacyOpt = async () => {
  return await api.getReq(`/dropdown/shortPrivacyOptions`);
};

export const martialStatusDropDown = async () => {
  return await api.getReq(`/dropdown/maritalStatus`);
};

export const getPassionDropDown = async () => {
  return await api.getReq(`/dropdown/passion`);
};

export const getHobbiesDropdown = async () => {
  return await api.getReq(`/dropdown/hobbie`);
};

export const getDefaultFeedDropdown = async () => {
  return await api.getReq(`/dropdown/feed`);
};

export const getSkillsKeywordsDropdown = async () => {
  return await api.getReq(`/dropdown/getSkillsKeywords`);
};

export const getBusinessSkillsDropdown = async () => {
  return await api.getReq(`/dropdown/getBusinessSkills`);
};

export const getInterpersonalSkillsDropdown = async () => {
  return await api.getReq(`/dropdown/getInterpersonalSkills`);
};

export const getMentorshipAreasDropdown = async () => {
  return await api.getReq(`/dropdown/getMentorshipAreas`);
};

export const getJobIndustriesDropdown = async () => {
  return await api.getReq(`/dropdown/getJobIndustries`);
};

export const getJobCompanyDropdown = async (companyName = '') => {
  return await api.getReq(`/jobs/getCompaniesList?company_name=${companyName}`);
};

export const getJobCategoriesDropdown = async () => {
  return await api.getReq(`/dropdown/getJobCategories`);
};

export const getOrganizationsDropdown = async (org_type, q) => {
  return await api.getReq(`/user/getOrganizationsList?org_type=${org_type}&q=${q}`);
};

export const getGoogleCitiesDropdown = async () => {
  return await api.getReq(`/dropdown/getGoogleCities`);
};

export const getCareerGoalsDropdown = async () => {
  return await api.getReq(`/dropdown/getCareerGoals`);
};

export const getCourseProgramDropdown = async (id) => {
  return await api.getReq(`/dropdown/courseList?institute_id=${id}`);
};
export const getPeopleYouMayKnow = async () => {
  return await api.getReq(`/user/peopleYouMayKnow`);
};

export const deleteEducationDetail = async (id) => {
  return await api.delReq(`/user/deleteEducationInfo?user_qualification_id=${id}`);
};

export const updateEducationInfo = async (body) => {
  return await api.PostReq(`/user/updateEducationInfo`, body);
};

export const getProficiencyDrop = async () => {
  return await api.getReq(`/dropdown/getProficiency`);
};

//TechnicalSkills
export const getTechnicalSkill = async () => {
  return await api.getReq(`life-skills-api/listTechnicalSkills`);
};

export const addTechSkills = async (body) => {
  return await api.PostReq(`/life-skills-api/addTechnicalSkills`, body);
};
export const userTechDetails = async () => {
  return await api.getReq(`/user/educationInfoList`);
};
export const getTechnicalSkillDrop = async (search = '') => {
  return await api.getReq(`/dropdown/getSkillsKeywords?search=${search}`);
};

export const updateTechInfo = async (body) => {
  return await api.PostReq(`/life-skills-api/updateTechnicalSkills`, body);
};

export const deleteUserSkill = async (id) => {
  return await api.delReq(`/life-skills-api/deleteUserSkills?id=${id}`);
};
//Business skill
export const getBusinessSkill = async () => {
  return await api.getReq(`/life-skills-api/listBusinessSkills`);
};
export const addBussinesSkills = async (body) => {
  return await api.PostReq(`/life-skills-api/addBusinessSkills`, body);
};

export const getBusinessSkillDrop = async (search = '') => {
  return await api.getReq(`/dropdown/getBusinessSkills?search=${search}`);
};

export const updateBusinessInfo = async (body) => {
  return await api.PostReq(`/life-skills-api/updateBusinessSkills`, body);
};

//InterPersonal skill
export const getInterpersonalSkill = async () => {
  return await api.getReq(`/life-skills-api/listInterpersonalSkills`);
};
export const addInterpersonalSkills = async (body) => {
  return await api.PostReq(`/life-skills-api/addInterpersonalSkills`, body);
};

export const getInterpersonalSkillDrop = async (search = '') => {
  return await api.getReq(`/dropdown/getInterpersonalSkills?search=${search}`);
};

export const updateInterpersonalInfo = async (body) => {
  return await api.PostReq(`/life-skills-api/updateInterpersonalSkills`, body);
};

//SWOT skill
export const getSWOT = async () => {
  return await api.getReq(`/life-skills-api/listSwotSkills`);
};
export const addUpdateSWOTInfo = async (body) => {
  return await api.PostReq(`/life-skills-api/updateSwotSkills`, body);
};
//Career skill

//
export const getMentorShipDrop = async (search = '') => {
  return await api.getReq(`/dropdown/getMentorshipAreas?search=${search}`);
};
export const getIndustryDrop = async (search = '') => {
  return await api.getReq(`/dropdown/getJobIndustries?search=${search}`);
};
export const getFunctionalAreaDrop = async (search = '') => {
  return await api.getReq(`/dropdown/getJobCategories?name=${search}`);
};
export const getJobLocationDrop = async (search = '') => {
  return await api.getReq(`/dropdown/getJobCategories?name=${search}`);
};

export const addUpdateCareerInfo = async (body) => {
  return await api.PostReq(`/life-skills-api/updateCareerSkills`, body);
};
export const getCareerSkill = async () => {
  return await api.getReq(`/life-skills-api/listCareerSkills`);
};
export const addFriend = async (body) => {
  return await api.PostReq(`/user/addFriend?t=follow&a=follow`, body);
};

// groups
export const createGroup = async (body) => {
  return await api.PostReq(`/group/createGroup`, body);
};

export const getManageGroups = async (filter = '', offset = '') => {
  return await api.getReq(`/group/manageGroups?filter=${filter}&offset=${offset}&limit=6`);
};
export const getUserGroups = async (filter = '', offset = '', limit = '6') => {
  return await api.getReq(`group/userGroups?filter=${filter}&offset=${offset}&limit=${limit}`);
};

export const leaveGroupFromWidget = async (body) => {
  return await api.PostReq(`/user/followUnfollow?t=follow&a=follow&without_html=1`, body);
};

export const createGroupPost = async (body) => {
  return await api.PostReq(`/group/createPost?t=post&a=new&without_html=1`, body);
};
export const getGroupPost = async (id, type = '', search = '', page = '') => {
  return await api.getReq(`group/posts?id=${id}&type=${type}&search=${search}&page=${page}`);
};
export const getShowGroup = async (id) => {
  return await api.getReq(`group/show?id=${id}`);
};

/*
 * end
 */

/**
 * filter dropdown api's
 */

export const industriesDropdown = async (q = '', job_type = '', jof = '') => {
  return await api.getReq(
    `/jobs/life_skills_api/getIndustryTags?q=${q}&page=1&limit=20&job_type=${job_type}&jof=${jof}`
  );
};

export const companyDropdown = async (q = '', job_type = '', jof = '') => {
  return await api.getReq(
    `/jobs/life_skills_api/getCompanyTags?q=${q}&page=1&limit=20&job_type=${job_type}&jof=${jof}`
  );
};

export const advanceSearchForJob = async (body) => {
  return await api.PostReq(`/jobs/advanceSearchingOneMoreTime`, body);
};

export const getUserJobsForMap = async (
  pt = '',
  location = '',
  company = '',
  skillset = '',
  category = '',
  min_exp = '',
  max_exp = '',
  min_ctc = '',
  max_ctc = '',
  radiusSearch = '',
  radiusData = ''
) => {
  return await api.getReq(
    `/user/jobmapdata?pt=${pt}&filter=${encodeURIComponent(
      `location=${location}&company=${company}&skillset=${skillset}&category=${category}&min_exp=${min_exp}&max_exp=${max_exp}&min_ctc=${min_ctc}&max_ctc=${max_ctc}`
    )}&radiusSearch=${radiusSearch}&radiusData=${radiusData}`
  );
};

export const getAlumniForMap = async (
  pt = '',
  alumni_name = '',
  institute = '',
  passoutYear = '',
  company = '',
  location = '',
  category = '',
  skillset = '',
  BusinessSkillset = '',
  PersonalSkillset = '',
  radiusSearch = '',
  radiusData = ''
) => {
  return await api.getReq(
    `/user/map?pt=${pt}&filter=${encodeURIComponent(
      `alumni_name=${alumni_name}&institute=${institute}&passoutYear=${passoutYear}&company=${company}&location=${location}&category=${category}&skillset=${skillset}&BusinessSkillset=${BusinessSkillset}&PersonalSkillset=${PersonalSkillset}`
    )}&radiusSearch=${radiusSearch}&radiusData=${radiusData}`
  );
};

export const getAlumniListing = async (
  place = '',
  pt,
  page = 0,
  offset = '',
  alumni_name = '',
  institute = '',
  passoutYear = '',
  company = '',
  location = '',
  category = '',
  skillset = '',
  BusinessSkillset = '',
  PersonalSkillset = ''
) => {
  return await api.getReq(
    `/user/listAlumnniprofile?place=${place}&pt=${pt}&page=${page}&filter=${encodeURIComponent(
      'alumni_name=' +
      alumni_name +
      '&institute=' +
      institute +
      '&passoutYear=' +
      passoutYear +
      '&company=' +
      company +
      '&location=' +
      location +
      '&category=' +
      category +
      '&skillset=' +
      skillset +
      '&BusinessSkillset=' +
      BusinessSkillset +
      '&PersonalSkillset=' +
      PersonalSkillset
    )}`
    // `/user/listJobs?place=${place}&pt=${pt}&filter=location=''&company=''&skillset=''&category=''&min_exp=''&max_exp=''&min_ctc=''&max_ctc`
  );
};

export const getJobListing = async (
  place = '',
  pt,
  page = 0,
  offset = '',
  location = '',
  company = '',
  skillset = '',
  category = '',
  min_exp = '',
  max_exp = '',
  min_ctc = '',
  max_ctc = ''
) => {
  return await api.getReq(
    `/user/listJobs?place=${place}&pt=${pt}&page=${page}&filter=${encodeURIComponent(
      'location=' +
      location +
      '&company=' +
      company +
      '&skillset=' +
      skillset +
      '&category=' +
      category +
      '&min_exp=' +
      min_exp +
      '&max_exp=' +
      max_exp +
      '&min_ctc=' +
      min_ctc +
      '&max_ctc=' +
      max_ctc
    )}`
    // `/user/listJobs?place=${place}&pt=${pt}&filter=location=''&company=''&skillset=''&category=''&min_exp=''&max_exp=''&min_ctc=''&max_ctc`
  );
};

export const getNetworkJobListing = async (
  place = '',
  pt,
  page = 0,
  offset = '',
  location = '',
  company = '',
  skillset = '',
  category = '',
  min_exp = '',
  max_exp = '',
  min_ctc = '',
  max_ctc = ''
) => {
  return await api.getReq(
    `/user/listJobs?network=true&place=${place}&pt=${pt}&page=${page}&filter=${encodeURIComponent(
      'location=' +
      location +
      '&company=' +
      company +
      '&skillset=' +
      skillset +
      '&category=' +
      category +
      '&min_exp=' +
      min_exp +
      '&max_exp=' +
      max_exp +
      '&min_ctc=' +
      min_ctc +
      '&max_ctc=' +
      max_ctc
    )}`
    // `/user/listJobs?place=${place}&pt=${pt}&filter=location=''&company=''&skillset=''&category=''&min_exp=''&max_exp=''&min_ctc=''&max_ctc`
  );
};

export const addToShortList = async (jobId, status) => {
  return await api.getReq(`/jobs/savelikejob?job_id=${jobId}&status=${status}`);
};
export const getJobShortListOfUser = async (filter = '') => {
  return await api.getReq(`/user/shortListedJobsOfUser?filter=${filter}`);
};

/**********************Event Calendar*******************/
export const userEvents = async () => {
  return await api.getReq('user/events_calendar');
};

/************************Poll*************************/

export const addPollQuestions = async (body) => {
  return await api.PostReq(`/poll/addQuestions`, body);
};

export const addPollAnswers = async (body) => {
  return await api.PostReq(`/poll/addAnswers`, body);
};

export const addPollResponse = async (body) => {
  return await api.PostReq(`/poll/sendResponse`, body);
};
export const showPollDetails = async (id) => {
  return await api.getReq(`/poll/showDetail?id=${id}`);
};

export const showallQuestions = async (limit = 10, page) => {
  return await api.getReq(`/poll/showDetail?limit=${limit}&page=${page}`);
};
export const getUserTimeLineInfo = async (id) => {
  return await api.getReq(`/user/userBasicInfoTimeLine?encryption_id=${id}`);
};

export const getUserTimeLineAllEducation = async (id) => {
  return await api.getReq(`/user/getTimeLineSchoolDetails?encryption_id=${id}`);
};

export const getUserTimeLineFriends = async (id, search = '') => {
  return await api.getReq(`/user/userTimelineFriendsAllData?encrypted_id=${id}&search=${search}`);
};

export const getUserConnectionsData = (id) => {
  return api.getReq(`/user/getUserConnectionsData?id=${id}`);
};

export const getUserTimeLineProfessional = async (id) => {
  return await api.getReq(`/user/getTimeLineWorkDetails?encryption_id=${id}`);
};

export const getUserTimeLineTech = async (id) => {
  return await api.getReq(`/user/getTimeLineSkillTechnicalAbout?encryption_id=${id}`);
};

export const getUserTimeLineBusiness = async (id) => {
  return await api.getReq(`/user/getTimeLineSkillBusinessAbout?encryption_id=${id}`);
};
export const getUseTimeLineInterPersonal = async (id) => {
  return await api.getReq(`/user/getTimeLineSkillInterPersonalAbout?encryption_id=${id}`);
};

export const getUseTimeLinePhotos = async (id) => {
  return await api.getReq(`/user/getTimeLineUserPhotos?encryption_id=${id}`);
};

// export const getUseTimeLinePhotos = async (id) => {
//   return await api.getReq(`/user/getTimeLineUserPhotos?encryption_id=${id}`);
// };

/**********************Search*******************/

export const searchGroups = async (body) => {
  return await api.PostReq(`/user/searchgroups`, body);
};

export const searchPages = async (body) => {
  return await api.PostReq(`/user/searchpages`, body);
};

export const searchCompanyPages = async (body) => {
  return await api.PostReq(`/user/searchcompanypages`, body);
};

export const searchPeople = async (body) => {
  return await api.PostReq(`/user/searchpeople`, body);
};

export const changeProfileImage = async (body) => {
  return await api.PostReq(`user/setProfileImage?t=avatar&a=new`, body);
};

export const findFriends = async (
  type = 'all',
  name = '',
  instituteId = '',
  years = '',
  company = '',
  location = '',
  category = '',
  skillset = '',
  bussinessSkill = '',
  personalSkills = '',
  offset = ''
) => {
  return await api.getReq(
    `/user/getUserDirectory?filter=${encodeURIComponent(
      `alumni=${type}&alumni-name=${name}&institute=${instituteId}&passoutYear=${years}&company=${company}&location=${location}&category=${category}&skillset=${skillset}&BusinessSkillset=${bussinessSkill}&PersonalSkillset=${personalSkills}`
    )}&offset=${offset}`
  );
};

export const getAvatarImage = async (id) => {
  return await api.getReq(`/smedia/ImageDetails?mid=${id}&w=60&mt=s&o=false&type=user`);
};

export const getUserTimeLineAlbums = async (id) => {
  return await api.getReq(`/user/getTimelineAlbums?encryption_id=${id}`);
};
export const photosPagesSocial = async (id, type) => {
  return await api.getReq(`/page/posts?id=${id}&type=${type}`);
};

export const changeCoverImage = async (body) => {
  return await api.PostReq(`/user/changeCover?t=cover&a=change_timeline_cover`, body);
};

export const removeCoverImage = async (body) => {
  return await api.PostReq(`/user/removeCover?t=cover&a=remove_cover`, body);
};

export const sendRequest = async (a = '', body) => {
  return await api.PostReq(`/user/follow-unfollow?t=follow&a=${a}`, body);
};

export const followFriend = async (body) => {
  return await api.PostReq(`/user/addFriend?t=follow&a=mentor`, body);
};

export const userCreateAlbum = async (body) => {
  return await api.PostReq(`/user/createTimeLineAlbum`, body);
};

// /jobs/cities?q=ab

export const citiesDropDown = async (search) => {
  return await api.getReq(`/jobs/cities?q=${search}`);
};
// user/removeMentor

export const unfollowMentor = async (body) => {
  return await api.PostReq(`/user/removeMentor`, body);
};

export const alumnisData = async () => {
  return await api.getReq(`/user/getallalumnisdata`);
};

export const changeCoverImgSocialProfile = async (body) => {
  return await api.PostReq(`user/setProfileImage?t=cover&a=new`, body);
};

export const changeCoverImgPostion = async (body) => {
  return await api.PostReq(`/user/changeCoverPosition?t=cover&a=reposition`, body);
};

// Header searching

export const headerMainSerach = async (q) => {
  return await api.getReq(`/user/mainSearch?t=search&a=header&q=${q}`);
};

export const changeCompanyCoverImage = async (body) => {
  return await api.PostReq(`/company/changeCompanyCover?t=cover&a=change_mentor_cover`, body);
};

export const removeCompanyCoverImage = async (body) => {
  return await api.PostReq(`/company/removeCompanyCover?t=cover&a=remove_Company_cover`, body);
};

export const changeCompanyImage = async (body, coverId) => {
  return await api.PostReq(`/company/setCompanyProfile?t=avatar&a=new&coverid=${coverId}`, body);
};

export const changeEventCoverImage = async (body) => {
  return await api.PostReq(`/event/changeEventCover?t=cover&a=change_event_cover`, body);
};
export const removeEventCoverImage = async (body) => {
  return await api.PostReq(`/event/removeEventCover?t=cover&a=remove_event_cover`, body);
};

// clubs api started

export const createClub = async (body) => {
  return await api.PostReq(`/clubs/createClub`, body);
};

export const getManageClubs = async (filter = '', offset = '') => {
  return await api.getReq(`/clubs/manageClubs?filter=${filter}&offset=${offset}&limit=6`);
};

export const getUserClubs = async (filter = '', offset = '', limit = '6') => {
  return await api.getReq(`/clubs/userClubs?filter=${filter}&offset=${offset}&limit=${limit}`);
};

export const getShowClubDetail = async (id) => {
  return await api.getReq(`/clubs/clubDetail?id=${id}`);
};

export const changeClubCoverImage = async (body) => {
  return await api.PostReq(`/clubs/changeCover?t=cover&a=change_timeline_cover`, body);
};

export const removeClubCoverImage = async (body) => {
  return await api.PostReq(`/clubs/removeCover?t=cover&a=remove_cover`, body);
};

export const changeEventImage = async (body) => {
  return await api.PostReq(`/ajax/socialAjax?t=avatar&a=change`, body);
};
