import {
  DELETE_MEDIA_FILE_PENDING,
  DELETE_MEDIA_FILE_SUCCESS,
  DELETE_MENU_LIST_FAILED
} from '../action';

const initialState = {
  message: '',
  isLoading: false,
  hasError: ''
};

export const deleteMediaFileReducer = (state = initialState, action) => {
  switch (action.type) {
    case DELETE_MEDIA_FILE_PENDING:
      return {
        ...state,
        isLoading: true
      };

    case DELETE_MEDIA_FILE_SUCCESS:
      return {
        ...state,
        message: action.payload,
        isLoading: false
      };

    case DELETE_MENU_LIST_FAILED:
      return {
        ...state,
        message: '',
        hasError: action.payload
      };

    default:
      return state;
  }
};
