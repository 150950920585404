import { Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import HeaderLogin from '../../commoncomponents/HeaderLogin';
import FooterMain from '../../commoncomponents/FooterMain';
import TopTabbings from './TopTabbings';
import NewsSidebar from '../../commoncomponents/newsfeeds/NewsSidebar';

function AppliedDetails() {
  return (
    <>
      <HeaderLogin />
      <div className="header-lnk-feeds">
        <div className="container">
          <TopTabbings state={'feed'} />
        </div>
      </div>
      <section className="feed-sec bg-grey py-4">
        <div className="container">
          <Row>
            <Col xs={12} md={2}>
              <NewsSidebar />
            </Col>
            <Col xs={12} md={7}>
              <div className="job-detail bg-white p-4">
                <div className="job-detail-hd d-flex align-items-center justify-content-between mb-3">
                  <div className="job-hd-lft">
                    <h3>Front End Executive</h3>
                    <h5>Company: Dfavo</h5>
                    <p>Posted By: Gunjit Trehan</p>
                  </div>
                  <div className="social-round">
                    <ul>
                      <li>
                        <Link className="facebook" to="/">
                          <i className="fab fa-facebook-f"></i>
                        </Link>
                      </li>
                      <li>
                        <Link className="twitter" to="/">
                          <i className="fab fa-twitter"></i>
                        </Link>
                      </li>
                      <li>
                        <Link className="google" to="/">
                          <i className="fab fa-google-plus-g"></i>
                        </Link>
                      </li>
                      <li>
                        <Link className="linkdin" to="/">
                          <i className="fab fa-linkedin-in"></i>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="job-btn mt-2 mb-4">
                  {/* <Link className="apply-btn" to="/">
                    <i className="fas fa-check me-1"></i>Apply
                  </Link> */}
                  <Link className="applied-btn" to="/">
                    <i className="fas fa-check me-1"></i>Already Applied
                  </Link>
                  <Link to="/">
                    <i className="fas fa-thumbs-up me-1"></i>Recommend
                  </Link>
                </div>
                <div className="mb-4">
                  <ul className="job-recomm-addrs">
                    <li>
                      <i className="fas fa-map-marker-alt"></i>Chandigarh
                    </li>
                    <li>
                      <i className="fas fa-phone-alt"></i>9216599595
                    </li>
                    <li>
                      <i className="fas fa-envelope"></i>biopharls@gmail.com
                    </li>
                    <li>
                      <i className="fas fa-users"></i>Positions open: 1
                    </li>
                    <li>
                      <i className="fas fa-briefcase"></i>Experience: 1 - 3(yrs)
                    </li>
                    <li>
                      <i className="fas fa-hourglass-end"></i>Job Type: Full Time
                    </li>
                  </ul>
                </div>
                <div className="job-detail-dis">
                  <h4>
                    <strong>Description:</strong>
                  </h4>
                  <p>
                    Dfavo, a fast growing Norway based EdTech company, is enabling and supporting
                    students to receive global education. It’s unique platform empowers the
                    international student recruitment industry by providing tools, expertise aided
                    with premium support by connecting educational institutions with recruitment
                    partners and students across the globe.
                  </p>
                  <p>
                    We are strengthening our team at our Chandigarh headquarters and are looking to
                    hire a confident and results driven Front Developer to design and build modern
                    user interface components to enhance the application performance. The incumbent
                    would be part of the IT team and would report directly into the Head of IT.
                  </p>
                  <h4>
                    <b>
                      Key responsibilities of the Front end developer will include(but not limited
                      to):
                    </b>
                  </h4>
                  <ul>
                    <li>
                      - Developing and implementing user interface components using React.js
                      concepts and such
                    </li>
                    <li>
                      - Developing and implementing user interface components using React.js
                      concepts and such
                    </li>
                    <li>
                      - Developing and implementing user interface components using React.js
                      concepts and such
                    </li>
                    <li>
                      - Developing and implementing user interface components using React.js
                      concepts and such
                    </li>
                    <li>
                      - Developing and implementing user interface components using React.js
                      concepts and such
                    </li>
                    <li>
                      - Developing and implementing user interface components using React.js
                      concepts and such
                    </li>
                    <li>
                      - Developing and implementing user interface components using React.js
                      concepts and such
                    </li>
                  </ul>
                  <h4>
                    <b>
                      Key responsibilities of the Front end developer will include(but not limited
                      to):
                    </b>
                  </h4>
                  <ul>
                    <li>
                      - Developing and implementing user interface components using React.js
                      concepts and such
                    </li>
                    <li>
                      - Developing and implementing user interface components using React.js
                      concepts and such
                    </li>
                    <li>
                      - Developing and implementing user interface components using React.js
                      concepts and such
                    </li>
                    <li>
                      - Developing and implementing user interface components using React.js
                      concepts and such
                    </li>
                    <li>
                      - Developing and implementing user interface components using React.js
                      concepts and such
                    </li>
                    <li>
                      - Developing and implementing user interface components using React.js
                      concepts and such
                    </li>
                    <li>
                      - Developing and implementing user interface components using React.js
                      concepts and such
                    </li>
                    <li>
                      - Developing and implementing user interface components using React.js
                      concepts and such
                    </li>
                    <li>
                      - Developing and implementing user interface components using React.js
                      concepts and such
                    </li>
                  </ul>
                  <p>
                    Disclaimer- Dfavo is Equal Opportunity Employers encouraging diversity in the
                    workplace. All qualified applicants will receive consideration for employment
                    without regard to race, national origin, gender identity/expression, age,
                    religion, disability, sexual orientation, genetics, veteran status, marital
                    status, or any other characteristic protected by law.
                  </p>

                  <div className="key-skill">
                    <h4>Key Skills</h4>
                    <ul className="key-skill-tag">
                      <li>react js</li>
                      <li>PHP [codeigniter / laravel]</li>
                      <li>php</li>
                    </ul>
                  </div>
                </div>
              </div>
            </Col>
            <Col xs={12} md={3}>
              <div className="similar-job-card bg-white p-2">
                <h4 className="social-hd">Similar jobs</h4>
                <ul className="similar-job-lst">
                  <li>
                    <Link to="/">
                      <h4>PHP WEB DEVELOPER</h4>
                      <p>
                        <i className="fas fa-map-marker-alt"></i> Delhi
                      </p>
                    </Link>
                  </li>
                  <li>
                    <Link to="/">
                      <h4>PHP WEB DEVELOPER</h4>
                      <p>
                        <i className="fas fa-map-marker-alt"></i> Delhi
                      </p>
                    </Link>
                  </li>
                  <li>
                    <Link to="/">
                      <h4>PHP WEB DEVELOPER</h4>
                      <p>
                        <i className="fas fa-map-marker-alt"></i> Delhi
                      </p>
                    </Link>
                  </li>
                  <li>
                    <Link to="/">
                      <h4>PHP WEB DEVELOPER</h4>
                      <p>
                        <i className="fas fa-map-marker-alt"></i> Delhi
                      </p>
                    </Link>
                  </li>{' '}
                  <li>
                    <Link to="/">
                      <h4>PHP WEB DEVELOPER</h4>
                      <p>
                        <i className="fas fa-map-marker-alt"></i> Delhi
                      </p>
                    </Link>
                  </li>{' '}
                  <li>
                    <Link to="/">
                      <h4>PHP WEB DEVELOPER</h4>
                      <p>
                        <i className="fas fa-map-marker-alt"></i> Delhi
                      </p>
                    </Link>
                  </li>{' '}
                  <li>
                    <Link to="/">
                      <h4>PHP WEB DEVELOPER</h4>
                      <p>
                        <i className="fas fa-map-marker-alt"></i> Delhi
                      </p>
                    </Link>
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
        </div>
      </section>
      <FooterMain className={'colour-bg'} />
    </>
  );
}

export default AppliedDetails;
