//  @copyright : ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
// @author     : Shiv Charan Panjeta

// All Rights Reserved.
// Proprietary and confidential :  All information contained herein is, and remains
// the property of ToXSL Technologies Pvt. Ltd. and its partners.
// Unauthorized copying of this file, via any medium is strictly prohibited.

import React, { useEffect, useState } from 'react';
import { Col, Container, Form, Row, Table } from 'react-bootstrap';
import ReactPaginate from 'react-paginate';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import AsyncSelect from 'react-select/async';
import NoDataFound from '../../../components/commoncomponents/NoDataFound';
import Loader from '../../../components/commoncomponents/loader/loader-large';
import useDocumentTitle from '../../../globals/useDocumentTitle';
import * as APISERVICES from '../../../services/AuthServices';
import { getUserInstituteList } from '../../AdminHelper';
import NotAllowed from '../../NotAllowed';

export default function ListGroup() {
  useDocumentTitle('Manage Groups | AlmaBay');
  const navigate = useNavigate();

  const [chekcedId, setCheckedId] = useState([]);
  const [sortType, setSortType] = useState('DESC');
  const [sortField, setSortField] = useState('group_id');
  const [list, setList] = useState([]);
  const [allId, setAllId] = useState([]);
  const [action, setAction] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [response, setResponse] = useState(0);
  const [showErrorComponent, setShowErrorComponent] = useState(false);
  const [insId, setInsId] = useState('');
  const [loading, setLoading] = useState(false);
  const [instiSearch, setInstiSearch] = useState('');
  const [groupData, setGroupData] = useState({
    group_id: '',
    group_name: '',
    group_institute: '',
    group_status: '',
    group_verified: ''
  });

  const paths = useSelector((state) => state?.adminPaths?.paths);
  const [showMsg, setShowMsg] = useState({
    empty: ''
  });

  useEffect(() => {
    apiCall();
    return () => {
      setShowErrorComponent(false);
    };
  }, []);

  const apiCall = (page) => {
    setLoading(true);
    const body = {
      page: page ? page : currentPage,
      group_id: '',
      group_name: '',
      group_institute: '',
      group_status: '',
      group_verified: ''
    };
    let pageNo = page ? page : 1;
    setCurrentPage(pageNo);
    APISERVICES.postAllGroupListing(body).then((resp) => {
      setLoading(false);
      if (resp?.status === 200) {
        setList(resp?.data?.data?.viewlist);
        setResponse(resp?.data?.data?.totalRecords);
        setAllId(resp?.data?.data?.viewlist.map((data) => data?.id));
      }
    });
  };

  const sortHandler = (e, type) => {
    e.preventDefault();
    let currentSort = sortType === 'ASC' ? 'DESC' : 'ASC';
    setSortType(currentSort);
    setSortType(type);
    apiCall(
      sortField,
      groupData.group_id,
      groupData.group_name,
      groupData.group_institute,
      groupData.group_status,
      groupData.group_verified
    );
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setGroupData({ ...groupData, [name]: value });
  };

  /**
   * global action handler
   * checking validation as wekk
   * @param {*} e
   */
  const globalActionHandler = async (e) => {
    if (chekcedId.length === 0) {
      setShowMsg((prevState) => ({
        ...prevState,
        empty: 'Please Select Any Record.'
      }));
      setTimeout(() => {
        setShowMsg((prevState) => ({
          ...prevState,
          empty: ''
        }));
      }, 3000);
    } else {
      if (e.target.value === '') {
        setShowMsg((prevState) => ({
          ...prevState,
          empty: 'Please choose any action.'
        }));
        setTimeout(() => {
          setShowMsg((prevState) => ({
            ...prevState,
            empty: ''
          }));
        }, 3000);
      } else {
        const body = {
          ids: chekcedId?.toString(),
          action: e.target.value
        };
        const resp = await APISERVICES.postGroupMultiSelected(body);
        if (resp.status === 200) {
          setShowMsg((prevState) => ({
            ...prevState,
            empty: resp.data?.message
          }));
          setTimeout(() => {
            setShowMsg((prevState) => ({
              ...prevState,
              empty: ''
            }));
          }, 3000);
          setCheckedId([]);
          e.target.value = '';
          apiCall();
        }
      }
    }
  };

  // const handleSelectAll = (e) => {
  //   if (e.target.checked === true) {
  //     setCheckedId(allId);
  //   } else {
  //     setCheckedId([]);
  //   }
  // };

  // const handleMultipleCheckBox = (id) => {
  //   if (chekcedId.includes(id)) {
  //     let filterId = chekcedId.filter((data) => data !== id);
  //     setCheckedId(filterId);
  //   } else {
  //     setCheckedId([...chekcedId, id]);
  //   }
  // };

  // const alertHandler = () => {
  //   clearInterval(intervalId);
  //   intervalId = setInterval(function () {
  //     setShowAlert(false);
  //   }, 5000);
  // };

  const selectHandler = (value, id) => {
    switch (value) {
      case 'edit':
        navigate(`/admin/groups/create`, { state: id });
        break;
      case 'members':
        navigate(`/admin/groups/manage/${id}`);
        break;
      default:
        break;
    }
  };

  const handleSearch = (page = '') => {
    setCurrentPage(page);
    setLoading(true);
    const body = {
      page: page,
      group_id: groupData.group_id,
      group_name: groupData.group_name,
      group_institute: groupData.group_institute?.id,
      group_status: groupData.group_status,
      group_verified: groupData.group_verified
    };
    APISERVICES.postAllGroupListing(body).then((resp) => {
      setLoading(false);
      if (resp?.status === 200) {
        setList(resp?.data?.data?.viewlist);
        setAllId(resp?.data?.data?.viewlist.map((data) => data?.id));
        setResponse(resp?.data?.data?.totalRecords);
      }
    });
  };

  const resetHandler = (e) => {
    e.preventDefault();
    setGroupData({
      group_id: '',
      group_name: '',
      group_institute: '',
      group_status: '',
      group_verified: ''
    });
    setInsId('');
    apiCall();
  };

  const handlePageClick = (a) => {
    let currentPage = a?.selected + 1;
    handleSearch(currentPage);
  };

  const handleClick = () => {
    if (paths?.includes('admin/groups/create')) {
      navigate('/admin/groups/create');
    } else {
      setShowErrorComponent(true);
    }
  };

  /**
   * function call after check all
   * @param {*} e
   */
  const handleSelectAll = (e) => {
    if (e.target.checked === true) {
      allId.forEach((id) => {
        if (!chekcedId.includes(id)) {
          setCheckedId((prev) => {
            return [...prev, id];
          });
        }
      });
    } else {
      setCheckedId([
        ...chekcedId.filter((id) => {
          return allId.indexOf(id) == -1;
        })
      ]);
    }
  };

  /**
   * function for individual chechbox
   * @param {*} e
   * @param {*} id
   */
  const handleMultipleCheckBox = (e, id) => {
    if (chekcedId.includes(id)) {
      setCheckedId(
        chekcedId.filter((data) => {
          return data !== id;
        })
      );
    } else {
      setCheckedId([...chekcedId, id]);
    }
  };

  return (
    <>
      {showErrorComponent ? (
        <NotAllowed />
      ) : (
        <>
          <div className="dash-main-content">
            <Container fluid>
              <div className="dash-page-content">
                <div className="dash-page-title">
                  <Row>
                    <Col xs={12} sm={12} md={12}>
                      <div className="dash-title-bar d-flex align-items-center justify-content-between">
                        <div className="title-lft d-flex align-items-center">
                          <div className="dash-icn">
                            <i className="fas fa-users"></i>
                          </div>
                          <div className="dash-title-dis">
                            <h5>
                              Group <span>Listing</span>
                            </h5>
                            {response > 0 && (
                              <h5>
                                Total Record(s): <span>{response}</span>
                              </h5>
                            )}
                          </div>
                        </div>
                        <div className="title-rt-btn d-flex align-items-center">
                          <button
                            type="button"
                            className="green_button_admin"
                            onClick={() => {
                              handleClick();
                            }}>
                            Add New Group
                          </button>
                          <div className="head-instite-sec ms-4">
                            <Form.Select
                              aria-label="Default select example"
                              id="global"
                              onChange={globalActionHandler}>
                              <option value="">Choose Action</option>
                              <option value="active">Active</option>
                              <option value="inactive">Inactive</option>
                              <option value="del">Delete</option>
                            </Form.Select>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
                <div className={`dash-content-card ${list?.length == 1 && 'single-group-table'}`}>
                  {showMsg?.empty !== '' && (
                    <div
                      className={
                        showMsg?.empty === 'Please Select Any Record.' ||
                        showMsg?.empty === 'Please choose any action.'
                          ? 'alert-danger'
                          : 'alert-success'
                      }>
                      <span>{showMsg?.empty}</span>
                    </div>
                  )}
                  <div className="table-responsive">
                    <Table className="custom-table-data" striped bordered hover>
                      <thead>
                        <tr>
                          <th>
                            <Form.Check
                              type="checkbox"
                              id="checkbox"
                              name="checkall"
                              // checked={allId?.length === chekcedId?.length}
                              // onChange={(e) => {
                              //   handleSelectAll(e);
                              // }}
                              checked={
                                allId?.every((elem) => {
                                  return chekcedId.includes(elem);
                                }) && list?.length > 0
                                  ? true
                                  : false
                              }
                              onChange={(e) => handleSelectAll(e)}
                              label=""
                            />
                          </th>
                          <th>
                            {/* <Link
                          to="#"
                        // onClick={(e) => {
                        //   sortHandler(e, 'id');
                        // }}
                        > */}
                            Id
                            {/* </Link> */}
                          </th>
                          <th>
                            {/* <Link
                          to="#"
                        // onClick={(e) => {
                        //   sortHandler(e, 'id');
                        // }}
                        > */}
                            Group Name
                            {/* </Link> */}
                          </th>
                          <th>
                            {/* <Link
                          to="#"
                        // onClick={(e) => {
                        //   sortHandler(e, 'id');
                        // }}
                        > */}
                            Institute
                            {/* </Link> */}
                          </th>
                          <th>Member</th>
                          <th>
                            {/* <Link
                          to="#"
                          onClick={(e) => {
                            sortHandler(e, 'id');
                          }}
                        > */}
                            Status
                            {/* </Link> */}
                          </th>
                          <th>
                            {/* <Link
                          to="#"
                          onClick={(e) => {
                            sortHandler(e, 'id');
                          }}
                        > */}
                            Verified
                            {/* </Link> */}
                          </th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr className="search-table">
                          <td></td>
                          <td>
                            <Form.Control
                              type="text"
                              placeholder="id"
                              name="group_id"
                              onChange={(e) => handleChange(e)}
                              value={groupData.group_id}
                            />
                          </td>
                          <td>
                            <Form.Control
                              type="text"
                              placeholder="Name"
                              name="group_name"
                              onChange={(e) => {
                                handleChange(e);
                              }}
                              value={groupData.group_name}
                            />
                          </td>

                          <td>
                            <AsyncSelect
                              loadOptions={getUserInstituteList}
                              isClearable
                              isSearchable
                              menuIsOpen={instiSearch !== '' ? true : false}
                              onInputChange={(newValue) => setInstiSearch(newValue)}
                              getOptionLabel={(e) => e.label}
                              getOptionValue={(e) => e.value}
                              className="basic-single mt-1 min-w-200"
                              placeholder="Select Institute List"
                              onChange={(e) => {
                                setInsId(e);
                                setGroupData({ ...groupData, ['group_institute']: e });
                              }}
                              value={insId}
                            />
                          </td>
                          <td></td>
                          <td>
                            <Form.Select
                              aria-label="Default select example"
                              name="group_status"
                              value={groupData.group_status}
                              onChange={(e) => handleChange(e)}>
                              <option value=""> - Select - </option>
                              <option value={1}>Active</option>
                              <option value={0}>Inactive</option>
                            </Form.Select>
                          </td>
                          <td>
                            <Form.Select
                              aria-label="Default select example"
                              name="group_verified"
                              value={groupData.group_verified}
                              onChange={(e) => handleChange(e)}>
                              <option value=""> - Select - </option>
                              <option value={1}>Yes</option>
                              <option value={0}>No</option>
                            </Form.Select>
                          </td>
                          <td className="serac-btns">
                            <button
                              className="green_button_admin"
                              type="button"
                              onClick={() => {
                                handleSearch(1);
                              }}>
                              Search
                            </button>
                            {/* <Button
                              className="main-btn-red ms-2"
                              onClick={(e) => {
                                resetHandler(e);
                              }}>
                              Reset
                            </Button> */}
                          </td>
                        </tr>
                        {list && list?.length !== 0 ? (
                          list?.map((data, index) => {
                            return (
                              <tr key={index}>
                                <td>
                                  <Form.Check
                                    type="checkbox"
                                    // name="id"
                                    // onChange={() => handleMultipleCheckBox(data?.id)}
                                    // label=""
                                    // checked={chekcedId?.includes(data?.id)}
                                    // id={`id${data?.group_id}`}
                                    name={data?.name}
                                    checked={chekcedId?.includes(data?.id)}
                                    onChange={(e) => {
                                      handleMultipleCheckBox(e, data?.id);
                                    }}
                                  />
                                </td>
                                <td>{data?.id || 'NA'}</td>
                                <td>{data?.name || 'NA'}</td>
                                <td>{data?.instituteName || 'NA'}</td>
                                <td>{data?.members_count || 0}</td>
                                <td>
                                  {data.active && data.active == 1 ? 'Active' : 'Inactive' || 'NA'}
                                </td>
                                <td>
                                  {data.verified && data.verified == 1 ? 'Yes' : 'No' || 'NA'}
                                </td>
                                <td>
                                  <Form.Select
                                    aria-label="Default select example"
                                    id="select"
                                    value={''}
                                    onChange={(e) => {
                                      selectHandler(e.target.value, data?.id);
                                    }}>
                                    <option>Action</option>

                                    {paths?.includes('admin/groups/create') && (
                                      <option value="edit">Edit</option>
                                    )}
                                    <option value="members">Manage Members</option>
                                  </Form.Select>
                                </td>
                              </tr>
                            );
                          })
                        ) : (
                          <tr>
                            <td colSpan={8}>
                              <NoDataFound />
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                    <div className="react-page d-flex">
                      {Math.ceil(response / 10) > 1 ? (
                        <ReactPaginate
                          previousLabel={'<'}
                          nextLabel=">"
                          onPageChange={handlePageClick}
                          pageCount={response / 10}
                          activeClassName={'page-link active'}
                          forcePage={currentPage - 1}
                        />
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </Container>
          </div>
          {loading ? <Loader /> : ''}
        </>
      )}
    </>
  );
}
