import { useState } from 'react';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { cronjobConstants } from '../../globals/constants';
import useDocumentTitle from '../../globals/useDocumentTitle';
import { addCronJob } from '../../services/AuthServices';
import showNotification from '../../services/NotificationService';

const AddCronJobs = () => {
  const navigate = useNavigate();
  const [cronJobs, setCronJobs] = useState({
    command: '',
    title: '',
    state: cronjobConstants.STATE_ACTIVE
  });
  const [errorValues, setErrorValues] = useState({});

  /**
   * destructing initail state of cronJobs
   */
  const { command, title, state } = cronJobs;

  /**
   * a validation function to validate form
   * @returns
   */
  const validation = () => {
    let errors = {};
    if (!command?.trim()) {
      errors.command = 'Command is required';
    } else if (!/^[a-zA-Z\s]*$/.test(command)) {
      errors.command = 'Command must be alphabet';
    }
    if (!title?.trim()) {
      errors.title = 'Title is required';
    } else if (!/^[a-zA-Z\s]*$/.test(title)) {
      errors.title = 'Title must be alphabet';
    }
    return Object.keys(errors).length > 0 ? errors : null;
  };

  /**
   * onChange function trigger after any chnages ain input box
   * Also set error in error object
   * @param {*} e
   */
  const handleChange = (e) => {
    e.preventDefault();
    setCronJobs((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value
    }));
    setErrorValues((prevState) => ({
      ...prevState,
      [e.target.name]: undefined
    }));
  };

  /**
   * a function to add cronjob
   * @param {*} e
   */
  const handleAdd = async (e) => {
    e.preventDefault();
    /**
     * checking validation
     */
    let errors = validation();
    if (errors) {
      setErrorValues(errors);
    } else {
      try {
        const body = {
          title: title,
          command: command,
          type_id: cronjobConstants.TYPE_ID,
          state_id: state
        };
        const response = await addCronJob(body);
        if (response?.status === 200) {
          showNotification('success', response.data?.message);
          navigate(`/admin/scheduler/cronjob`);
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  return (

    <div className="dash-main-content">
      <Container fluid>
        <div className="dash-page-content">
          <div className="dash-page-title">
            <Row>
              <Col xs={12} sm={12} md={12}>
                <div className="dash-title-bar d-flex align-items-center justify-content-between">
                  <div className="title-lft d-flex align-items-center">
                    <div className="icons_head">
                      <i className="fas fa-suitcase"></i>
                    </div>
                    {useDocumentTitle('Add Cronjob | AlmaBay')}
                    <span style={{ fontSize: '20px', fontWeight: 'bold' }} className="ms-3">
                      {`Add Cronjob`}
                    </span>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <div className="dash-content-card">
            <div className="cmmn-form">
              <Form>
                <Row>
                  <Col xs={12} md={6} lg={6}>
                    <Form.Group className="mb-3" controlId="formBasicPassword">
                      <Form.Label>
                        Command <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        as={`textarea`}
                        placeholder="Enter Command"
                        name="command"
                        rows={1}
                        value={command}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                      />
                      {errorValues.command && (
                        <span className="error_message">{errorValues.command}</span>
                      )}
                    </Form.Group>
                  </Col>
                  <Col xs={12} md={6} lg={6}>
                    <Form.Group className="mb-3" controlId="formBasicPassword">
                      <Form.Label>
                        Title <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Title"
                        name="title"
                        autoComplete="Off"
                        value={title}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                      />
                      {errorValues.title && (
                        <span className="error_message">{errorValues.title}</span>
                      )}
                    </Form.Group>
                  </Col>
                  <Col xs={12} md={6} lg={6}>
                    <Form.Group className="mb-3" controlId="formBasicPassword">
                      <Form.Label>
                        State <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Select
                        aria-label="Default select example"
                        name="state"
                        value={state}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                      >
                        <option value={cronjobConstants.STATE_ACTIVE}>Active</option>
                        <option value={cronjobConstants.STATE_INACTIVE}>Disable</option>
                        <option value={cronjobConstants.STATE_DELETED}>Deleted</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>
                </Row>
                <Button
                  onClick={(e) => {
                    handleAdd(e);
                  }}
                  type="submit"
                  className="main-btn-grn"
                >
                  Add
                </Button>
              </Form>
            </div>
          </div>
        </div>
      </Container>
    </div>

  );
};

export default AddCronJobs;
