import { Link } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import FooterMain from '../../commoncomponents/FooterMain';
import * as ADMINSERVICES from '../../../services/AuthServices';
import { Container } from 'react-bootstrap';
import showNotification from '../../../services/NotificationService';
import { emailRegex } from '../../../globals/helper';
import useDocumentTitle from '../../../globals/useDocumentTitle';
import HeaderRecuirter from '../../commoncomponents/HeaderRecuirter';
import Alert from 'react-bootstrap/Alert';
import Loader from '../../commoncomponents/loader/loader-large';
import { restrictAlpha } from '../../../globals/constants';

let intervalId;
function Recruiters() {
  useDocumentTitle('Recruitments | AlmaBay');
  const [searchedData, setSearchedData] = useState({
    name: '',
    email: '',
    phone: '',
    company: '',
    country: '',
    role: ''
  });
  const [clicked, setClicked] = useState(false);
  const [response, setResponse] = useState('');
  const [locationList, setLocationList] = useState('');
  const [showalert, setShowAlert] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getCountryList();
  }, []);

  const getCountryList = () => {
    ADMINSERVICES.getRecLocation(searchedData).then((res) => {
      if (res?.status === 200) {
        setLocationList(res?.data?.data);
      }
    });
  };

  const alertHandler = () => {
    clearInterval(intervalId);
    intervalId = setInterval(function () {
      setShowAlert(false);
    }, 5000);
  };

  const validate = () => {
    let errors = {};
    if (!searchedData.name.trim()) {
      errors.name = 'Please enter your name.';
    }
    if (!searchedData.email.trim()) {
      errors.email = 'Please enter your email.';
    } else if (!emailRegex.test(searchedData.email)) {
      errors.email = 'please enter valid email';
    }
    if (!searchedData.phone.trim()) {
      errors.phone = 'please enter your phone.';
    }
    if (!searchedData.company.trim()) {
      errors.company = 'please enter your company name.';
    }
    if (!searchedData.country) {
      errors.country = 'Please select your country.';
    }
    if (!searchedData.role) {
      errors.role = 'Please select your role.';
    }
    return errors;
  };

  const onChangeSearch = (e) => {
    const { name, value } = e.target;
    setSearchedData({ ...searchedData, [name]: value });
  };

  const handleSearch = (e) => {
    e.preventDefault;
    setClicked(true);
    if (!Object.keys(validate()).length) {
      setClicked(false);
      setLoading(true);
      let formData = new FormData();
      for (let i in searchedData) {
        formData.append(`${i}`, searchedData[i]);
      }
      ADMINSERVICES.getRecruiterSearch(searchedData).then((res) => {
        setLoading(false);
        if (res?.status === 200) {
          showNotification('success', res?.message);
          setSearchedData({ name: '', email: '', phone: '', country: '', company: '', role: '' });
          setResponse('Thanks for Submitting');
          setShowAlert(true);
          alertHandler();
        }
      });
    }
  };
  const myRef = useRef(null);

  const executeScroll = () => myRef.current?.scrollIntoView({ behavior: 'smooth' });

  return (
    <>
      <HeaderRecuirter />
      <section className="recruiters-banner">
        <div className="recruiters-blk  py-5">
          <div className="recruiters">
            <div className="pink-text">
              <span className="first-line">
                We help you to get the maximum out of your most important and critical asset:
              </span>
              <span className="big-words">Your People</span>
            </div>
          </div>
          <div className="next-block-btn">
            <Link
              to={'#'}
              onClick={(e) => {
                e.preventDefault();
                executeScroll();
              }}
            >
              <span className="chevron-btn"></span>
            </Link>
          </div>
        </div>
      </section>

      <section className="love-working" ref={myRef}>
        <Container className="position-relative">
          <div className="section-title">
            <h1>You’ll love working with AlmaBay</h1>
          </div>

          <div className="pull-right highlights">
            <ul>
              <li>
                <span className="icon-left">
                  <i className="home-icon check-on-doc"></i>
                </span>
                <div className="text-box">Hassle-free terms</div>
              </li>

              <li>
                <span className="icon-left">
                  <i className="home-icon thumb-up"></i>
                </span>
                <div className="text-box">Leading Guarantee</div>
              </li>

              <li>
                <span className="icon-left">
                  <i className="home-icon check"></i>
                </span>
                <div className="text-box">Find the right people faster</div>
              </li>

              <li>
                <span className="icon-left">
                  <i className="home-icon user"></i>
                </span>
                <div className="text-box">View and contact anyone on AlmaBay</div>
              </li>
            </ul>
          </div>
        </Container>
      </section>

      <section className="howitworks py-5">
        <Container>
          <Row>
            <Col md={12}>
              <h2>How it Works</h2>
            </Col>
            <Col md={4}>
              <h4>Global Approach</h4>
              <p>
                We at AlmaBay truly believe in the inter-connectivity of people and companies across
                the globe. Our infrastructure helps us provide solutions to our clients that start
                in one region, but leads to a wider solution applicable in different situations.
              </p>
            </Col>
            <Col md={4}>
              <h4>Value</h4>
              <p>
                With our programme, your organisation can be assured of value-based streamlining of
                core activities with most of the HR functions being managed by us, thus, improving
                overall efficiency. Our clients have seen high percentage reductions in cycle time,
                early turnover, average cost reduction and business effort in the hiring process.
              </p>
            </Col>
            <Col md={4}>
              <h4>End to end recruitment</h4>
              <p>
                Our dedicated team of recruitment advocates and consultants ensure that you get the
                best talent through precisely monitored logistics, and in the process, providing you
                a platform where all positions in your organisation are filled up without much
                delay.
              </p>
            </Col>
            <Col md={4}>
              <h4>Candidate sourcing solutions</h4>
              <p>
                Being a core part of our recruitment solutions, candidate sourcing solutions provide
                the top talent through the following means: Market research and analytics, social
                media and diversity strategies, postings and ad placement, database mining and
                resume screening, pipeline creation and management, outbound email and calling
                campaigns
              </p>
            </Col>
            <Col md={4}>
              <h4>Candidate assessment and selection</h4>
              <p>
                AlmaBay validates pre-employment testing are proven to: Improve on-the-job and
                training performance, Decrease turnover, Identify candidates with required "select
                for" skills, Pin-point "gems" in a large candidate pool, Reduce costs associated
                with poor-selection decisions, Improve on-the-job safety behavior.
              </p>
            </Col>
            <Col md={4}>
              <h4>Point recruitment solutions</h4>
              <p>
                We also offer more targeted solutions that address the major challenges of candidate
                sourcing, candidate assessment and selection, recruitment and administration
                support, and recruitment technology.
              </p>
            </Col>
          </Row>
          <div className="hiring-card">
            <Link
              className="we-hiring"
              to="#"
              onClick={(e) => {
                e.preventDefault();
                document.getElementById('form_id').scrollIntoView();
              }}
            >
              Start Hiring
            </Link>
          </div>
        </Container>
      </section>

      <section className="apply-block ">
        <Container>
          <Row>
            <Col md={6}>
              <h2 className="big-title">
                Better Candidates. <br /> Faster.
              </h2>
              <span className="form-label">Fill the form to get started.</span>
            </Col>
            <Col lg={5} offset-lg={1} md={6} xs={12}>
              <div className="apply-form" id={'form_id'}>
                <Alert variant={'success'} className={`${showalert ? '' : 'd-none'}`}>
                  {response}
                </Alert>
                {/* {response ? <h5 className="alert alert-success">{response}</h5> : ''} */}
                <Form>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Name"
                    name="name"
                    onChange={onChangeSearch}
                    value={searchedData.name}
                  />
                  <div className={`${clicked && validate().name ? '' : 'd-none'} danger-frm `}>
                    {clicked && validate().name}
                  </div>

                  <input
                    type="mail"
                    className="form-control"
                    placeholder="Email"
                    name="email"
                    onChange={onChangeSearch}
                    value={searchedData.email}
                  />
                  <div className={`${clicked && validate().email ? '' : 'd-none'} danger-frm `}>
                    {clicked && validate().email}
                  </div>

                  <input
                    type="text"
                    className="form-control"
                    placeholder="Phone"
                    onKeyPress={(e) => restrictAlpha(e)}
                    name="phone"
                    onChange={onChangeSearch}
                    value={searchedData.phone}
                    maxLength={10}
                  />
                  <div className={`${clicked && validate().phone ? '' : 'd-none'} danger-frm `}>
                    {clicked && validate().phone}
                  </div>

                  <input
                    type="text"
                    className="form-control"
                    placeholder="Company"
                    name="company"
                    onChange={onChangeSearch}
                    value={searchedData.company}
                  />

                  <div className={`${clicked && validate().company ? '' : 'd-none'} danger-frm `}>
                    {clicked && validate().company}
                  </div>

                  <select
                    className="form-control"
                    name="country"
                    onChange={onChangeSearch}
                    value={searchedData.country}
                  >
                    <option>select Country</option>
                    {locationList &&
                      locationList?.map((data) => (
                        <option value={data?.country_id} key={data?.country_id}>
                          {data?.name}
                        </option>
                      ))}
                  </select>
                  <div className={`${clicked && validate().country ? '' : 'd-none'} danger-frm `}>
                    {clicked && validate().country}
                  </div>
                  {/* your role */}
                  <select
                    className="form-control"
                    name="role"
                    onChange={onChangeSearch}
                    value={searchedData.role}
                  >
                    <option value="">-- Your role --</option>
                    <option value="Business Owner/Leader">Business Owner/Leader</option>
                    <option value="Head of Department - HR/Talent">
                      Head of Department - HR/Talent
                    </option>
                    <option value="Head of Department - Other">Head of Department - Other</option>
                    <option value="Director - HR/Talent">Director - HR/Talent</option>
                    <option value="Director - Other">Director - Other</option>
                    <option value="Manager - HR/Talent">Manager - HR/Talent</option>
                    <option value="Manager - Other">Manager - Other</option>
                    <option value="Social Media Manager">Social Media Manager</option>
                    <option value="Recruiter/Sourcer">Recruiter/Sourcer</option>
                    <option value="Student/Intern">Student/Intern</option>
                    <option value="Job Seeker">Job Seeker</option>
                    <option value="Others">Others</option>
                  </select>
                  <div className={`${clicked && validate().role ? '' : 'd-none'} danger-frm `}>
                    {clicked && validate().role}
                  </div>
                  {/* end */}
                </Form>
                <button
                  className="btn"
                  onClick={(e) => {
                    loading ? e.preventDefault() : handleSearch(e);
                  }}
                >
                  Submit Now
                  <span className="small-text">All fields required</span>
                </button>
              </div>
            </Col>
          </Row>
        </Container>
        {loading ? <Loader /> : ''}
      </section>
      <FooterMain className={'colour-bg'} state="d-none" />
    </>
  );
}

export default Recruiters;
