import { useState } from 'react';
import { Button, Container } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Pagination from 'react-bootstrap/Pagination';
import Row from 'react-bootstrap/Row';
import Table from 'react-bootstrap/Table';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import useDocumentTitle from '../../globals/useDocumentTitle';

export default function ManageCoupons() {
  useDocumentTitle('Privilege Card Management Panel | AlmaBay');
  let active = 2;
  let items = [];
  for (let number = 1; number <= 5; number++) {
    items.push(
      <Pagination.Item key={number} active={number === active}>
        {number}
      </Pagination.Item>
    );
  }

  const [isClearable] = useState(true);
  const [isSearchable] = useState(true);
  const [isDisabled] = useState(false);
  const [isLoading] = useState(false);
  const [isRtl] = useState(false);

  return (
      <div className="dash-main-content">
        <Container fluid>
          <div className="dash-page-content">
            <div className="dash-page-title">
              <Row className="align-items-end">
                <Col xs={12} sm={12} md={12}>
                  <div className="dash-title-bar d-flex align-items-center justify-content-between">
                    <div className="title-lft d-flex align-items-center">
                      <div className="dash-icn">
                        <i className="fas fa-flag"></i>
                      </div>
                      <div className="dash-title-dis">
                        <h5>
                          Coupon Management <span>Panel</span>
                        </h5>
                      </div>
                    </div>
                    <div className="title-rt-btn d-flex align-items-center">
                      <Link className="green_button_admin" to="">
                        <i className="fas fa-plus me-2"></i>
                        Add
                      </Link>
                      <Link className="green_button_admin ms-1" to="">
                        Export Privilege Card
                      </Link>
                      <Link className="green_button_admin ms-1" to="">
                        Download Privilege Card
                      </Link>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="dash-content-card">
              <div className="cmmn-form">
                <Form>
                  <Row className="mb-5 d-flex align-items-end">
                    <Col md={3}>
                      <Form.Group className="mb-3" controlId="formBasicName">
                        <Form.Label>
                          By Card Holder<span className="text-danger">*</span>
                        </Form.Label>
                        <Select
                          className="basic-single"
                          classNamePrefix="select"
                          isDisabled={isDisabled}
                          isLoading={isLoading}
                          isClearable={isClearable}
                          isRtl={isRtl}
                          isSearchable={isSearchable}
                          name="color"
                          placeholder="Select Privilege Card Number."
                        />
                      </Form.Group>
                    </Col>
                    <Col md={3}>
                      <Form.Group className="mb-3" controlId="formBasicName">
                        <Form.Label>
                          By Card Number<span className="text-danger">*</span>
                        </Form.Label>
                        <Select
                          className="basic-single"
                          classNamePrefix="select"
                          isDisabled={isDisabled}
                          isLoading={isLoading}
                          isClearable={isClearable}
                          isRtl={isRtl}
                          isSearchable={isSearchable}
                          name="color"
                          placeholder="Select Privilege Card Number."
                        />
                      </Form.Group>
                    </Col>
                    <Col md={3}>
                      <Form.Group className="mb-3" controlId="formBasicName">
                        <Form.Label>
                          By Institute:<span className="text-danger">*</span>
                        </Form.Label>
                        <Select
                          className="basic-single"
                          classNamePrefix="select"
                          isDisabled={isDisabled}
                          isLoading={isLoading}
                          isClearable={isClearable}
                          isRtl={isRtl}
                          isSearchable={isSearchable}
                          name="color"
                          placeholder="Select Privilege Card Number."
                        />
                      </Form.Group>
                    </Col>
                    <Col md={3}>
                      <div className="form-btns coupon-frm-btn d-flex align-items-center mb-3">
                        <button type="button" className="green_button_admin">
                          Save
                        </button>
                        <strong>OR</strong>
                        <Button className="main-btn-red">Cancel</Button>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </div>
              <div className="table-responsive">
                <Table className="custom-table-data" striped bordered hover>
                  <thead>
                    <tr>
                      <th></th>
                      <th>
                        <Link to="">
                          User Id
                          <img src={require('../../assets/images/sort.png')} alt="sort" />
                        </Link>
                      </th>
                      <th>
                        <Link to="">
                          Name
                          <img src={require('../../assets/images/sort.png')} alt="sort" />
                        </Link>
                      </th>
                      <th>
                        <Link to="">
                          Institute
                          <img src={require('../../assets/images/sort.png')} alt="sort" />
                        </Link>
                      </th>
                      <th>
                        <Link to="">
                          Email
                          <img src={require('../../assets/images/sort.png')} alt="sort" />
                        </Link>
                      </th>
                      <th>
                        <Link to="">
                          Card Number
                          <img src={require('../../assets/images/sort.png')} alt="sort" />
                        </Link>
                      </th>
                      <th>
                        <Link to="">
                          Card Status
                          <img src={require('../../assets/images/sort.png')} alt="sort" />
                        </Link>
                      </th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td></td>
                      <td>43948</td>
                      <td>Name 1</td>
                      <td>Institute</td>
                      <td>abc@gmail.com</td>
                      <td>9815055055</td>
                      <td>Inactive</td>
                      <td>
                        <Form.Select aria-label="Default select example">
                          <option>Action</option>
                          <option value="1">Edit</option>
                          <option value="2">View</option>
                          <option value="3">Delete</option>
                        </Form.Select>
                      </td>
                    </tr>
                    <tr>
                      <td></td>
                      <td>43948</td>
                      <td>Name 1</td>
                      <td>Institute</td>
                      <td>abc@gmail.com</td>
                      <td>9815055055</td>
                      <td>Inactive</td>
                      <td>
                        <Form.Select aria-label="Default select example">
                          <option>Action</option>
                          <option value="1">Edit</option>
                          <option value="2">View</option>
                          <option value="3">Delete</option>
                        </Form.Select>
                      </td>
                    </tr>
                    <tr>
                      <td></td>
                      <td>43948</td>
                      <td>Name 1</td>
                      <td>Institute</td>
                      <td>abc@gmail.com</td>
                      <td>9815055055</td>
                      <td>Inactive</td>
                      <td>
                        <Form.Select aria-label="Default select example">
                          <option>Action</option>
                          <option value="1">Edit</option>
                          <option value="2">View</option>
                          <option value="3">Delete</option>
                        </Form.Select>
                      </td>
                    </tr>
                    <tr>
                      <td></td>
                      <td>43948</td>
                      <td>Name 1</td>
                      <td>Institute</td>
                      <td>abc@gmail.com</td>
                      <td>9815055055</td>
                      <td>Inactive</td>
                      <td>
                        <Form.Select aria-label="Default select example">
                          <option>Action</option>
                          <option value="1">Edit</option>
                          <option value="2">View</option>
                          <option value="3">Delete</option>
                        </Form.Select>
                      </td>
                    </tr>
                    <tr>
                      <td></td>
                      <td>43948</td>
                      <td>Name 1</td>
                      <td>Institute</td>
                      <td>abc@gmail.com</td>
                      <td>9815055055</td>
                      <td>Inactive</td>
                      <td>
                        <Form.Select aria-label="Default select example">
                          <option>Action</option>
                          <option value="1">Edit</option>
                          <option value="2">View</option>
                          <option value="3">Delete</option>
                        </Form.Select>
                      </td>
                    </tr>
                  </tbody>
                </Table>
                {/* <Pagination>{items}</Pagination> */}
              </div>
            </div>
          </div>
        </Container>
      </div>
  );
}
