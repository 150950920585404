import { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { chapterPosts } from '../../../redux/action';
import { getCountOfChapter, singleChapterDetail } from '../../../services/InstituteServices';
import ChapterCard from './components/ChapterCard';
import UpcomingCard from './components/UpcomingCard';
import WhatsNewCard from './components/WhatsNewCard';

function ChapterDetails() {
  const location = useLocation();
  const dispatch = useDispatch();

  const chapterDetails = useSelector((state) => state?.chapterPost);
  const { details, posts } = chapterDetails;
  const [counts, setCounts] = useState('');

  useEffect(() => {
    // an IIFE to fetch posts and chapter details
    (async () => {
      try {
        const resp = await singleChapterDetail(location.state.encrypted_id);
        if (resp.status === 200) {
          dispatch(chapterPosts(resp.data.data));
        }
      } catch (error) {
        console.error(error);
      }
    })();
    (async () => {
      try {
        const resp = await getCountOfChapter(location.state.encrypted_id);
        if (resp.status === 200) {
          setCounts(resp?.data);
        }
      } catch (error) {
        console.error(error);
      }
    })();
  }, []);

  return (
    <>
      <div className="wrappermain_outer bggreylight">
        <section className="club-hero-sec pt-4 pb-3">
          <Container>
            <div className="club-hero-inr bg-white">
              <img
                style={{ objectFit: 'cover' }}
                height={300}
                width={1200}
                src={
                  details?.cover_image !== null
                    ? process.env.REACT_APP_API_DOMAIN + details.cover_image
                    : require('../../../assets/images/institute-img/club-1.png')
                }
                alt="club"
              />
              <span className="club-hero-logo">
                <img
                  src={
                    details?.cover_image !== null
                      ? process.env.REACT_APP_API_DOMAIN + details.cover_image
                      : require('../../../assets/images/institute-img/club-1.png')
                  }
                  alt="club"
                />
              </span>
              <div className="club-hero-dis">
                <div className="club-dis-lft">
                  <h4>{details.name}</h4>
                  <p style={{ textTransform: 'capitalize' }} className="close-club">
                    {details?.chapter_privacy} Chapter{' '}
                    {details?.verified == 1 && (
                      <span className="verified-icn ms-1">
                        <img
                          src={require('../../../assets/images/institute-img/verfied.png')}
                          alt="club"
                        />
                      </span>
                    )}
                  </p>
                </div>
                <div className="club-dis-rt">
                  <Link
                    className="orange-btn"
                    to="/institute-login"
                    state={`/user/chapter-detail/${location.state.encrypted_id}`}
                  >
                    Join Now
                  </Link>
                </div>
              </div>
            </div>
            <div className="club-dtl-ftr">
              <ul>
                <li>
                  <h4>Discussion</h4>
                  <p>{counts?.total_discussion}</p>
                </li>
                <li>
                  <h4>Videos</h4>
                  <p>{counts?.total_photos}</p>
                </li>
                <li>
                  <h4>Photos</h4>
                  <p>{counts?.total_videos}</p>
                </li>
                <li>
                  <h4>Members</h4>
                  <p>
                    {counts?.total_members}
                    {counts?.total_members > 100 && '+'}
                  </p>
                </li>
              </ul>
            </div>
          </Container>
        </section>
        <section className="club-dtl-sec">
          <Container>
            <Row>
              <Col lg={8} md={12}>
                {posts.map((item, i) => {
                  return (
                    <div key={i} className="club-detail-card mb-3">
                      <div className="club-hd-sec">
                        <div className="club-dtl-icn d-flex align-items-center">
                          <img
                            src={require('../../../assets/images/institute-img/club-logo-3.png')}
                            alt="club"
                          />
                        </div>
                        <div className="club-hd-dis">
                          <h4>{details.name}</h4>
                          <p dangerouslySetInnerHTML={{ __html: item?.text }}></p>
                        </div>
                      </div>
                      <div className="club-main-img">
                        {item.images.length > 0 &&
                          item.images.map((data, index) => {
                            return (
                              <img key={index} src={process.env.REACT_APP_API_DOMAIN + data.url} alt="club" />
                            );
                          })}
                      </div>
                    </div>
                  );
                })}
                {/* <div className="club-detail-card mb-3">
                <div className="club-hd-sec">
                  <div className="club-dtl-icn d-flex align-items-center">
                    <img
                      src={require('../../../assets/images/institute-img/club-logo-3.png')}
                      alt="club"
                    />
                  </div>
                  <div className="club-hd-dis">
                    <h4>GNDU Alumni Chapter</h4>
                    <p>
                      Ardass at Golden Temple, Shri Harmandir Sahib, Amritsar for the victims and
                      survivors of Turkey and Syria earthquake victims. What a noble gesture.
                      <Link to="#">#humanityfirst</Link>
                    </p>
                  </div>
                </div>
                <div className="club-main-img">
                  <img
                    src={require('../../../assets/images/institute-img/club-main-2.png')}
                    alt="club"
                  />
                </div>
              </div> */}
              </Col>
              <Col lg={4} md={12}>
                <div className="mb-3">
                  <ChapterCard details={details} posts={posts} type={"Chapter"} />
                </div>
                <div className="mb-3">
                  <WhatsNewCard />
                </div>
                <div className="mb-3">
                  <UpcomingCard />
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </div>
      <div className="lgn-link py-4">
        <Container>
          <div className="logn-lnk-inr">
            <p>
              <Link to="/institute-register">Register</Link> / <Link to="/institute-login">Login</Link> to browse
              members by Year, Name, Company, Profession or Location
            </p>
          </div>
        </Container>
      </div>
    </>
  );
}

export default ChapterDetails;
