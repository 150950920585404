/** @copyright : ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
 @author     : Shiv Charan Panjeta 

 All Rights Reserved.
 Proprietary and confidential :  All information contained herein is, and remains
 the property of ToXSL Technologies Pvt. Ltd. and its partners.
 Unauthorized copying of this file, via any medium is strictly prohibited.
*/

import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip
} from 'chart.js';
import moment from 'moment/moment';
import { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import { Chart } from 'react-google-charts';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import Loader from '../components/commoncomponents/loader/loader-large';
import useDocumentTitle from '../globals/useDocumentTitle';
import * as apiServices from '../services/AuthServices';
import showNotification from '../services/NotificationService';
import ReachAnalysis from './Dashboard/ReachAnalysis';
import AdminAlumniMap from './map/AdminAlumniMap';

export const options1 = {
  title: '',
  is3D: true
};

var optionsUserInstitute = {
  pieHole: 0.4,
  legend: { position: 'top', maxLines: 6, alignment: 'start' },
  chartArea: { left: 50, bottom: 10 },
  is3D: true
};

var optionsInstitutesByType = {
  pieHole: 0.4,
  legend: { position: 'top', maxLines: 6, alignment: 'start' },
  chartArea: { left: 50, bottom: 10 },
  is3D: true
};

export const passoutBatchOptions = {
  title: 'No of Alumni',
  curveType: 'function',
  legend: { position: 'bottom' }
};

export const data_tmp = [
  ['Year', 'Sales'],
  ['2004', 1],
  ['2015', 1],
  ['2020', 2],
  ['2022', 1]
];

function SuperAdminDashboard() {
  useDocumentTitle('Welcome To Admin Panel | AlmaBay');
  const userDetail = useSelector((state) => state?.user?.details);
  const [instituteId, setInstituteId] = useState('');
  const [yearId, setYearId] = useState('');
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [chartData, setChartData] = useState([]);
  const [chartUserInstituteData, setCharUserInstitutetData] = useState([]);
  const [chartInstitutesByTypeData, setChartInstitutesByTypeData] = useState([]);

  const [detail, setDetail] = useState('');
  const [birthdayList, setBirthdayList] = useState([]);
  const [aniversiryList, setAniversiryList] = useState([]);
  const [showMap, setShowMap] = useState(false);
  const [instituteList, setInstituteList] = useState([]);
  const [yearList, setYearList] = useState([]);
  const [userPassoutBatch, setUserPassoutBatch] = useState([]);

  const [selectedTabName, setSelectedTabName] = useState('first');

  useEffect(() => {
    callApi();
    getInstituteList();
    getMapYearList();
  }, []);

  const callApi = () => {
    setLoading(true);
    apiServices.dashCount().then((res) => {
      setLoading(false);

      // User Location

      setDetail(res?.data?.details);
      let arr = [['Task', 'Hours per Day']];
      let data = res?.data?.details?.cities;
      data?.map((data) => {
        arr.push([data?.location, Number(data?.n_total)]);
      });

      setChartData(arr);

      // User Passout Batch

      var todayDate = moment().format('YYYY');
      var lastYr = todayDate - 5;
      var emptyCondition = [['Year', 'Passout Batch/year']];

      if (res?.data?.details?.passout && res?.data?.details?.passout.length > 0) {
        res?.data?.details?.passout?.map((pass) => {
          emptyCondition.push([parseInt(pass['to_year']), parseInt(pass['total'])]);
        });
      } else {
        for (var i = lastYr, j = 1; i <= todayDate, j <= 5; i++, j++) {
          emptyCondition.push([i, j]);
        }
      }

      setUserPassoutBatch(emptyCondition);

      // User Institute

      let arr_user_institute = [['Institute Name', 'Number of User']];
      let user_institute = res?.data?.details?.user_institute;

      if (user_institute && user_institute.length > 0) {
        user_institute?.map((data) => {
          if (data?.name) {
            arr_user_institute.push([data?.name, Number(data?.total)]);
          }
        });
      } else {
        arr_user_institute[(0, 0)];
      }

      setCharUserInstitutetData(arr_user_institute);

      // Institutes by Type

      let arr_user_institute_by_type = [['City', 'Number of Alumni']];
      let user_institute_by_type = res?.data?.details?.campus_graph;

      if (user_institute_by_type && user_institute_by_type.length > 0) {
        user_institute_by_type?.map((data) => {
          if (data?.campus_type != '') {
            arr_user_institute_by_type.push([data?.campus_type, Number(data?.n_total)]);
          }
        });
      } else {
        arr_user_institute_by_type[(0, 0)];
      }

      setChartInstitutesByTypeData(arr_user_institute_by_type);
    });

    // For Birthdays Graph

    apiServices.getBirthdayList().then((res) => {
      setBirthdayList(res?.data?.detail);
    });

    // For Work Anniversary

    apiServices.getAniversiryList({ page: 1 }).then((res) => {
      setAniversiryList(res?.data?.detail?.users);
    });
  };

  // User Institute

  const getInstituteList = () => {
    apiServices.getAdminInstituteList().then((resp) => {
      if (resp?.status === 200) {
        const newArr = Object.entries(resp?.data?.data || {});
        const instList = newArr.map((item, i) => {
          return {
            id: item[0],
            name: item[1]
          };
        });
        setInstituteList(instList || []);
      }
    });
  };

  const getMapYearList = () => {
    apiServices.getMapYearList().then((resp) => {
      if (resp?.status === 200) {
        setYearList(resp?.data?.details);
      }
    });
  };

  const onChangeHandler = (q = '') => {
    if (q == 'state') {
      let arr = [['Task', 'Hours per Day']];
      let data = detail?.state_graph;
      data?.map((data) => {
        arr.push([data?.state_name, Number(data?.total)]);
      });
      setChartData(arr);
    } else if (q === 'country') {
      let arr = [['Task', 'Hours per Day']];
      let data = detail?.country_graph;
      data?.map((data) => {
        arr.push([data?.countryname, Number(data?.total)]);
      });
      setChartData(arr);
      setChartData(arr);
    } else if (q === 'city') {
      let arr = [['Task', 'Hours per Day']];
      let data = detail?.cities;
      data?.map((data) => {
        arr.push([data?.location, Number(data?.n_total)]);
      });
      setChartData(arr);
    }
  };

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [analysisLabels, setAnalysisLabel] = useState([]);
  const [almaBayCountDataSet, setAlmaBayCountDataSet] = useState([]);
  const [studentsDataSet, setStudentDataset] = useState([]);

  const [selectedInstitueName, setselectedInstituteName] = useState('');
  const getInstituteDataByIdHandler = (instituteId = 91) => {
    apiServices.getInstituteAnalysisData(instituteId).then((resp) => {
      // build graph data ...
      let labels = resp?.data.data.map((item) => item.year);
      let almaCount = resp?.data.data.map((item) => item.almabay_count);
      let studentDataSet = resp?.data.data.map((item) => item.students);
      setAnalysisLabel(labels);
      setAlmaBayCountDataSet(almaCount);
      setStudentDataset(studentDataSet);
    });
  };
  const data = {
    labels: analysisLabels,
    datasets: [
      {
        label: 'Students',
        data: studentsDataSet,
        backgroundColor: 'rgba(255, 99, 132, 0.5)'
      },
      {
        label: 'Almabay Actual Count',
        data: almaBayCountDataSet,
        backgroundColor: 'rgba(53, 162, 235, 0.5)'
      }
    ]
  };

  ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top'
      },
      title: {
        display: false,
        text: 'Chart.js Bar Chart'
      }
    }
  };

  const getYearOfStablishMentValue = () => {
    if (instituteId) {
      let result = instituteList?.filter((item) => item.id == instituteId);

      if (result.length > 0) {
        return result[0].name;
      }
    }
    // return 'Not provided';
    return '';
  };

  const handleSelectTab = (key) => {
    setSelectedTabName(key);
  };
  /**
   *
   * @param {*} userId
   */
  const sendBirthDayWish = (e, userId) => {
    e.preventDefault();

    let body = {
      id: userId,
      action: userDetail?.access_type == 'admin' ? 'almabay' : 'institute'
    };
    apiServices.brithDayWish(body).then((res) => {
      if (res?.status == 200) {
        apiServices.getBirthdayList().then((res) => {
          setBirthdayList(res?.data?.detail);
        });
      }
    });
  };

  /**
   *
   * @param {*} userData
   */
  const sendWorkWish = (e, userData) => {
    e.preventDefault();
    let data = JSON.stringify([userData]);
    let body = {
      data: data,
      action: 'AlmaBay'
    };
    apiServices.WrokDayWish(body).then((res) => {
      if (res?.status == 200) {
        showNotification('success', res?.data?.detail);
        apiServices.getAniversiryList({ page: 1 }).then((res) => {
          setAniversiryList(res?.data?.detail?.users);
        });
      }
    });
  };

  return (
    <>
      <div className="dash-main-content">
        <Container fluid>
          <div className="dash-page-content">
            <div className="dashboard-controls bg-white">
              <Tab.Container
                id="left-tabs-example"
                defaultActiveKey="first"
                onSelect={handleSelectTab}>
                <Row>
                  <Col sm={12}>
                    <Nav variant="pills" className="dash-tabs">
                      <Nav.Item>
                        <Nav.Link eventKey="first">Stats</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link onClick={() => setShowMap(true)} eventKey="second">
                          Alumni Map
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item
                        onClick={() => {
                          navigate('/admin/users/showallusers');
                        }}>
                        <Nav.Link>User Directory</Nav.Link>
                      </Nav.Item>
                      {/* <Nav.Item>
                          <Nav.Link eventKey="third">User Directory</Nav.Link>
                        </Nav.Item> */}
                      <Nav.Item>
                        <Nav.Link eventKey="fourth">Reach Analysis</Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </Col>
                  <Col sm={12}>
                    <Tab.Content className="dash-tab-content p-4">
                      <Tab.Pane eventKey="first">
                        <div className="dashboard-stat">
                          <Row>
                            <Col xl={3} lg={4} md={4}>
                              <div className="stat-card bg-purple">
                                <div className="stat-dis d-flex align-items-center justify-content-between">
                                  <div className="stat-inner-dis">
                                    <Link to="/admin/users/showallusers">
                                      <h4>{detail?.usertabdata?.total_users}</h4>
                                      <p>Total Users</p>
                                    </Link>
                                  </div>
                                  <span className="stat-icn">
                                    <i className="fas fa-users"></i>
                                  </span>
                                </div>
                              </div>
                            </Col>
                            <Col xl={3} lg={4} md={4}>
                              <div className="stat-card bg-orange">
                                <div className="stat-dis d-flex align-items-center justify-content-between">
                                  <div className="stat-inner-dis">
                                    <h4>{detail?.usertabdata?.numberOfAlumni}</h4>
                                    <p>Alumni</p>
                                  </div>
                                  <span className="stat-icn">
                                    <i className="fas fa-award"></i>
                                  </span>
                                </div>
                              </div>
                            </Col>
                            <Col xl={3} lg={4} md={4}>
                              <div className="stat-card bg-green">
                                <div className="stat-dis d-flex align-items-center justify-content-between">
                                  <div className="stat-inner-dis">
                                    <h4>{detail?.usertabdata?.total_student}</h4>
                                    <p>Student</p>
                                  </div>
                                  <span className="stat-icn">
                                    <i className="fas fa-user-graduate"></i>
                                  </span>
                                </div>
                              </div>
                            </Col>
                            <Col xl={3} lg={4} md={4}>
                              <div className="stat-card bg-blue-card">
                                <div className="stat-dis d-flex align-items-center justify-content-between">
                                  <div className="stat-inner-dis">
                                    <h4>{detail?.usertabdata?.total_faculity}</h4>
                                    <p>Faculty</p>
                                  </div>
                                  <span className="stat-icn">
                                    <i className="fas fa-user-tie"></i>
                                  </span>
                                </div>
                              </div>
                            </Col>
                            <Col xl={3} lg={4} md={4}>
                              <div className="stat-card bg-blue-card">
                                <div className="stat-dis d-flex align-items-center justify-content-between">
                                  <div className="stat-inner-dis">
                                    <Link to="/admin/jobs">
                                      <h4>{detail?.usertabdata?.listofjobs}</h4>
                                      <p>Total Jobs</p>
                                    </Link>
                                  </div>
                                  <span className="stat-icn">
                                    <i className="fas fa-briefcase"></i>
                                  </span>
                                </div>
                              </div>
                            </Col>
                            <Col xl={3} lg={4} md={4}>
                              <div className="stat-card bg-green">
                                <div className="stat-dis d-flex align-items-center justify-content-between">
                                  <div className="stat-inner-dis">
                                    <h4>{detail?.usertabdata?.jobsapplylist}</h4>
                                    <p>Jobs Apply</p>
                                  </div>
                                  <span className="stat-icn">
                                    <i className="fas fa-users"></i>
                                  </span>
                                </div>
                              </div>
                            </Col>
                            <Col xl={3} lg={4} md={4}>
                              <div className="stat-card bg-orange">
                                <div className="stat-dis d-flex align-items-center justify-content-between">
                                  <div className="stat-inner-dis">
                                    <h4>{detail?.constitute_user}</h4>
                                    <p>Users of Constituent Institutes</p>
                                  </div>
                                  <span className="stat-icn">
                                    <i className="fas fa-university"></i>
                                  </span>
                                </div>
                              </div>
                            </Col>
                            <Col xl={3} lg={4} md={4}>
                              <div className="stat-card bg-purple">
                                <div className="stat-dis d-flex align-items-center justify-content-between">
                                  <div className="stat-inner-dis">
                                    <h4>{detail?.afilated_user}</h4>
                                    <p>Users of Affiliated Institutes</p>
                                  </div>
                                  <span className="stat-icn">
                                    <i className="fas fa-school"></i>
                                  </span>
                                </div>
                              </div>
                            </Col>
                            <Col xl={3} lg={4} md={4}>
                              <div className="stat-card bg-orange">
                                <div className="stat-dis d-flex align-items-center justify-content-between">
                                  <div className="stat-inner-dis">
                                    <h4>{detail?.last_seven}</h4>
                                    <p>Active Users (7 Days)</p>
                                  </div>
                                  <span className="stat-icn">
                                    <i className="fas fa-users"></i>
                                  </span>
                                </div>
                              </div>
                            </Col>
                            <Col xl={3} lg={4} md={4}>
                              <div className="stat-card bg-purple">
                                <div className="stat-dis d-flex align-items-center justify-content-between">
                                  <div className="stat-inner-dis">
                                    <h4>{detail?.last_thirty}</h4>
                                    <p>Active Users (30 days)</p>
                                  </div>
                                  <span className="stat-icn">
                                    <i className="fas fa-users"></i>
                                  </span>
                                </div>
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col xl={6} lg={12} md={12}>
                              <div className="dash-page-title">
                                <div className="dash-title-bar d-flex align-items-center justify-content-between">
                                  <div className="title-lft d-flex align-items-center">
                                    <div className="dash-icn">
                                      <i className="fas fa-map-marker-alt"></i>
                                    </div>
                                    <div className="dash-title-dis">
                                      <h5>User Location</h5>
                                    </div>
                                  </div>
                                  <div>
                                    <Form.Select
                                      aria-label="Default select example"
                                      onChange={(e) => {
                                        onChangeHandler(e?.target?.value);
                                      }}>
                                      <option value="city">By City</option>
                                      <option value="state">By State</option>
                                      <option value="country">By Country</option>
                                    </Form.Select>
                                  </div>
                                </div>
                              </div>
                              <div className="dash-content-card">
                                <div className="location-chart">
                                  <Chart
                                    chartType="PieChart"
                                    data={chartData}
                                    options={options1}
                                    width={'100%'}
                                    height={'400px'}
                                  />
                                </div>
                              </div>
                            </Col>
                            <Col xl={6} lg={12} md={12}>
                              <div className="dash-page-title">
                                <div className="dash-title-bar d-flex align-items-center justify-content-between">
                                  <div className="title-lft d-flex align-items-center">
                                    <div className="dash-icn">
                                      <i className="fas fa-graduation-cap"></i>
                                    </div>
                                    <div className="dash-title-dis">
                                      <h5>User Passout Batch</h5>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="dash-content-card">
                                <div className="pass-out-chart">
                                  <Chart
                                    chartType="LineChart"
                                    width="100%"
                                    height="400px"
                                    data={userPassoutBatch}
                                    options={passoutBatchOptions}
                                  />
                                </div>
                              </div>
                            </Col>
                            <Col xl={6} lg={12} md={12}>
                              <div className="dash-page-title">
                                <div className="dash-title-bar d-flex align-items-center justify-content-between">
                                  <div className="title-lft d-flex align-items-center">
                                    <div className="dash-icn">
                                      <i className="fas fa-briefcase"></i>
                                    </div>
                                    <div className="dash-title-dis">
                                      <h5>User Institute</h5>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="dash-content-card">
                                <div className="dash-birth-lst">
                                  <Chart
                                    chartType="PieChart"
                                    data={chartUserInstituteData}
                                    options={optionsUserInstitute}
                                    width={'100%'}
                                    height={'400px'}
                                  />
                                </div>
                              </div>
                            </Col>
                            <Col xl={6} lg={12} md={12}>
                              <div className="dash-page-title">
                                <div className="dash-title-bar d-flex align-items-center justify-content-between">
                                  <div className="title-lft d-flex align-items-center">
                                    <div className="dash-icn">
                                      <i className="fas fa-briefcase"></i>
                                    </div>
                                    <div className="dash-title-dis">
                                      <h5>Institutes by Type</h5>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="dash-content-card">
                                <div className="dash-birth-lst">
                                  <Chart
                                    chartType="PieChart"
                                    data={chartInstitutesByTypeData}
                                    options={optionsInstitutesByType}
                                    width={'100%'}
                                    height={'400px'}
                                  />
                                </div>
                              </div>
                            </Col>
                            <Col xl={6} lg={12} md={12}>
                              <div className="dash-page-title">
                                <div className="dash-title-bar d-flex align-items-center justify-content-between">
                                  <div className="title-lft d-flex align-items-center">
                                    <div className="dash-icn">
                                      <i className="fas fa-briefcase"></i>
                                    </div>
                                    <div className="dash-title-dis">
                                      <h5>Work Anniversary</h5>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="dash-content-card">
                                <div className="dash-birth-lst">
                                  <ul>
                                    {aniversiryList && aniversiryList?.length ? (
                                      aniversiryList?.map((data, index) => (
                                        <li key={index}>
                                          <div className="birth-content d-flex align-items-center justify-content-between">
                                            <div className="d-flex align-items-center">
                                              <span className="birth-icon me-3">
                                                <img
                                                  // src={require('../assets/images/birth4.png')}
                                                  src={
                                                    data?.logo
                                                      ? process.env.REACT_APP_API_URL + data?.logo
                                                      : require('../assets/images/birth4.png')
                                                  }
                                                  alt="chatuser"
                                                />
                                              </span>
                                              <div className="birth-rt-content">
                                                <h4>{data?.username}</h4>
                                                <p>Completed {data?.no_of_years} years at</p>
                                                <strong> {data?.name} </strong>
                                              </div>
                                            </div>
                                            <div>
                                              <Link
                                                className="wish-btn"
                                                to=""
                                                onClick={(e) => sendWorkWish(e, data)}>
                                                <i className="fas fa-gift"></i>
                                              </Link>
                                            </div>
                                          </div>
                                        </li>
                                      ))
                                    ) : (
                                      <li>Data Not Found</li>
                                    )}
                                  </ul>
                                </div>
                              </div>
                            </Col>
                            <Col xl={6} lg={12} md={12}>
                              <Tab.Container id="left-tabs-example" defaultActiveKey="today">
                                <div className="dash-page-title">
                                  <div className="dash-title-bar d-flex align-items-center justify-content-between">
                                    <div className="title-lft d-flex align-items-center">
                                      <div className="dash-icn">
                                        <i className="fas fa-birthday-cake"></i>
                                      </div>
                                      <div className="d-flex align-items-center justify-content-between">
                                        <div className="dash-title-dis">
                                          <h5>Birthdays</h5>
                                        </div>
                                      </div>
                                    </div>
                                    <div>
                                      <Nav variant="pills" className="birth-controls">
                                        <Nav.Item>
                                          <Nav.Link eventKey="today">today</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                          <Nav.Link eventKey="upcoming">upcoming</Nav.Link>
                                        </Nav.Item>
                                      </Nav>
                                    </div>
                                  </div>
                                </div>
                                {/* tab */}
                                <div className="dash-content-card">
                                  <Tab.Content className="">
                                    <Tab.Pane eventKey="today">
                                      <div className="dash-birth-lst">
                                        <ul>
                                          {birthdayList && birthdayList?.today?.users?.length ? (
                                            birthdayList?.today?.users?.map((data, index) => (
                                              <li key={index}>
                                                <div className="birth-content d-flex align-items-center justify-content-between">
                                                  <div className="d-flex align-items-center">
                                                    <span className="birth-icon me-3">
                                                      <img
                                                        src={require('../assets/images/birth4.png')}
                                                        alt="chatuser"
                                                      />
                                                    </span>
                                                    <div className="birth-rt-content">
                                                      <h4>{data?.name}</h4>
                                                      <p>
                                                        <i className="fas fa-calendar-alt me-2"></i>
                                                        {data?.db + ',' + data?.dob_y}
                                                      </p>
                                                    </div>
                                                  </div>
                                                  <div>
                                                    <Link
                                                      className="wish-btn"
                                                      to="#"
                                                      onClick={(e) =>
                                                        sendBirthDayWish(e, data?.user_id)
                                                      }>
                                                      <i className="fas fa-gift"></i>
                                                    </Link>
                                                  </div>
                                                </div>
                                              </li>
                                            ))
                                          ) : (
                                            <p className="mt-2">Data Not Found</p>
                                          )}
                                        </ul>
                                      </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="upcoming">
                                      <div className="dash-birth-lst">
                                        <ul>
                                          {birthdayList && birthdayList?.upcoming?.users?.length ? (
                                            birthdayList?.upcoming?.users?.map((data, index) => (
                                              <li key={index}>
                                                <div className="birth-content d-flex align-items-center justify-content-between">
                                                  <div className="d-flex align-items-center">
                                                    <span className="birth-icon me-3">
                                                      <img
                                                        src={require('../assets/images/birth4.png')}
                                                        alt="chatuser"
                                                      />
                                                    </span>
                                                    <div className="birth-rt-content">
                                                      <h4>{data?.name}</h4>
                                                      <p>
                                                        <i className="fas fa-calendar-alt me-2"></i>
                                                        {data?.db + ',' + data?.dob_y}
                                                      </p>
                                                    </div>
                                                  </div>
                                                  <div>
                                                    <Link className="wish-btn" to="#">
                                                      <i className="fas fa-gift"></i>
                                                    </Link>
                                                  </div>
                                                </div>
                                              </li>
                                            ))
                                          ) : (
                                            <li>Data Not Found</li>
                                          )}
                                        </ul>
                                      </div>
                                    </Tab.Pane>
                                  </Tab.Content>
                                </div>
                              </Tab.Container>
                            </Col>
                          </Row>
                        </div>
                      </Tab.Pane>
                      <Tab.Pane eventKey="second">
                        <div className="dash-alumni-map">
                          {showMap && (
                            <>
                              {/* <Map instituteId={instituteId} yearId={yearId} /> */}
                              <AdminAlumniMap
                                instituteId={instituteId}
                                yearList={yearList}
                                instituteList={instituteList}
                                setYearId={setYearId}
                                yearId={yearId}
                                setInstituteId={setInstituteId}
                              />
                              {/* <AdminMap instituteId={instituteId} /> */}
                            </>
                          )}
                        </div>
                      </Tab.Pane>
                      <Tab.Pane eventKey="third">
                        <div className="user-directory-list">
                          <Row>
                            <Col md={3}>
                              <div className="user-lft-filter">
                                <div className="user-lst-hd d-flex align-items-center justify-content-between mb-3">
                                  <h4>REFINE RESULTS</h4>
                                  <Link className="filter-btn" to="#">
                                    Clear Filter
                                  </Link>
                                </div>
                                <div className="user-filter-form">
                                  <Form>
                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                      <Form.Label>FILTER CRITERIA</Form.Label>
                                      <Form.Select aria-label="Default select example">
                                        <option>AND</option>
                                        <option value="1">AND</option>
                                        <option value="2">OR</option>
                                      </Form.Select>
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="formBasicPassword">
                                      <Form.Label>SEARCH BY NAME</Form.Label>
                                      <Form.Control type="text" placeholder="Search By Name" />
                                    </Form.Group>
                                  </Form>
                                </div>
                                <div className="user-filter-accord">
                                  <Accordion defaultActiveKey="0">
                                    <Accordion.Item className="mb-3" eventKey="0">
                                      <Accordion.Header>Year of Graduation</Accordion.Header>
                                      <Accordion.Body>
                                        <div className="filter-accord-cntnt">
                                          <Form.Group className="mb-3" controlId="formBasicEmail">
                                            <Form.Control type="search" placeholder="Search" />
                                          </Form.Group>
                                          <ul className="annual-chk">
                                            <li>
                                              <Form.Check type="checkbox" label="2022 (1)" />
                                            </li>
                                            <li>
                                              <Form.Check type="checkbox" label="2020 (1)" />
                                            </li>
                                            <li>
                                              <Form.Check type="checkbox" label="2015 (1)" />
                                            </li>
                                            <li>
                                              <Form.Check type="checkbox" label="2014 (3)" />
                                            </li>
                                            <li>
                                              <Form.Check type="checkbox" label="2011 (2)" />
                                            </li>
                                            <li>
                                              <Form.Check type="checkbox" label="2006 (1)" />
                                            </li>
                                            <li>
                                              <Form.Check type="checkbox" label="2005 (1)" />
                                            </li>
                                            <li>
                                              <Form.Check type="checkbox" label="2004 (1)" />
                                            </li>
                                            <li>
                                              <Form.Check type="checkbox" label="2002 (1)" />
                                            </li>
                                          </ul>
                                        </div>
                                      </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item className="mb-3" eventKey="0">
                                      <Accordion.Header>Institute</Accordion.Header>
                                      <Accordion.Body>
                                        <div className="filter-accord-cntnt">
                                          <Form.Group className="mb-3" controlId="formBasicEmail">
                                            <Form.Control type="search" placeholder="Search" />
                                          </Form.Group>
                                          <ul className="annual-chk">
                                            <li>
                                              <Form.Check
                                                type="checkbox"
                                                label="Babu Banarasi Das University, Lucknow (1)"
                                              />
                                            </li>
                                            <li>
                                              <Form.Check
                                                type="checkbox"
                                                label="Chandigarh Group of Colleges (CGC), Landran, Landran (1)"
                                              />
                                            </li>
                                            <li>
                                              <Form.Check
                                                type="checkbox"
                                                label="DAV College, Chandigarh, Chandigarh (1)"
                                              />
                                            </li>
                                            <li>
                                              <Form.Check
                                                type="checkbox"
                                                label=" Gurukul Global School (GGS), Chandigarh, Chandigarh (1)"
                                              />
                                            </li>
                                            <li>
                                              <Form.Check
                                                type="checkbox"
                                                label="Sacred Heart Sr. Secondary School, Chandigarh, Chandigarh (1)"
                                              />
                                            </li>
                                            <li>
                                              <Form.Check
                                                type="checkbox"
                                                label="St. Stephen School, Chandigarh (1)"
                                              />
                                            </li>
                                            <li>
                                              <Form.Check
                                                type="checkbox"
                                                label=" Sacred Heart Sr. Secondary School, Chandigarh, Chandigarh (1)"
                                              />
                                            </li>
                                          </ul>
                                        </div>
                                      </Accordion.Body>
                                    </Accordion.Item>

                                    <Accordion.Item className="mb-3" eventKey="0">
                                      <Accordion.Header>Company</Accordion.Header>
                                      <Accordion.Body>
                                        <div className="filter-accord-cntnt">
                                          <Form.Group className="mb-3" controlId="formBasicEmail">
                                            <Form.Control type="search" placeholder="Search" />
                                          </Form.Group>
                                          <ul className="annual-chk">
                                            <li>
                                              <Form.Check
                                                type="checkbox"
                                                label="A & B ADVERTISING LLC(1)"
                                              />
                                            </li>
                                            <li>
                                              <Form.Check
                                                type="checkbox"
                                                label="AlmaBay Networks Pvt Ltd(1)"
                                              />
                                            </li>
                                            <li>
                                              <Form.Check
                                                type="checkbox"
                                                label="AlmaBay Solutions LLP(1)"
                                              />
                                            </li>
                                            <li>
                                              <Form.Check type="checkbox" label=" BASF(1)" />
                                            </li>
                                          </ul>
                                        </div>
                                      </Accordion.Body>
                                    </Accordion.Item>

                                    <Accordion.Item className="mb-3" eventKey="0">
                                      <Accordion.Header>Location</Accordion.Header>
                                      <Accordion.Body>
                                        <div className="filter-accord-cntnt">
                                          <Form.Group className="mb-3" controlId="formBasicEmail">
                                            <Form.Control type="search" placeholder="Search" />
                                          </Form.Group>
                                          <ul className="annual-chk">
                                            <li>
                                              <Form.Check
                                                type="checkbox"
                                                label="Barsar, Himachal Pradesh, India(1)"
                                              />
                                            </li>
                                            <li>
                                              <Form.Check
                                                type="checkbox"
                                                label="Chandigarh, Chandigarh, India(4)"
                                              />
                                            </li>
                                            <li>
                                              <Form.Check
                                                type="checkbox"
                                                label="Logan City, Queensland, Australia(1)"
                                              />
                                            </li>
                                          </ul>
                                        </div>
                                      </Accordion.Body>
                                    </Accordion.Item>
                                  </Accordion>
                                </div>
                              </div>
                            </Col>
                            <Col md={9}>
                              <div className="user-dir-users">
                                <div className="user-dir-hd">
                                  <h4>Found 7 People</h4>
                                </div>
                                <div className="user-dir-usrlst">
                                  <div className="user-dir-blk">
                                    <Row className="mt-3">
                                      <Col lg={6} md={6}>
                                        <div className="user-dir-card d-flex align-items-center ">
                                          <div className="user-dir-img">
                                            <img
                                              src={require('../assets/images/default-male-avatar.png')}
                                              alt="img"
                                            />
                                          </div>
                                          <div className="user-dir-dis">
                                            <h4>
                                              <Link to="#">User Sharma</Link>
                                            </h4>
                                            <p>
                                              {' '}
                                              <i className="fas fa-briefcase me-2"></i>Mintabie,
                                              South Australia, Australia
                                            </p>
                                            <p>
                                              <i className="fas fa-map-marker-alt me-2"></i>{' '}
                                              Mintabie, South Australia, Australia
                                            </p>
                                          </div>
                                        </div>
                                      </Col>
                                      <Col lg={6} md={6}>
                                        <div className="user-dir-card d-flex align-items-center ">
                                          <div className="user-dir-img">
                                            <img
                                              src={require('../assets/images/default-male-avatar.png')}
                                              alt="img"
                                            />
                                          </div>
                                          <div className="user-dir-dis">
                                            <h4>
                                              <Link to="#">User Sharma</Link>
                                            </h4>
                                            <p>
                                              {' '}
                                              <i className="fas fa-briefcase me-2"></i>Mintabie,
                                              South Australia, Australia
                                            </p>
                                            <p>
                                              <i className="fas fa-map-marker-alt me-2"></i>{' '}
                                              Mintabie, South Australia, Australia
                                            </p>
                                          </div>
                                        </div>
                                      </Col>
                                      <Col lg={6} md={6}>
                                        <div className="user-dir-card d-flex align-items-center ">
                                          <div className="user-dir-img">
                                            <img
                                              src={require('../assets/images/default-male-avatar.png')}
                                              alt="img"
                                            />
                                          </div>
                                          <div className="user-dir-dis">
                                            <h4>
                                              <Link to="#">User Sharma</Link>
                                            </h4>
                                            <p>
                                              {' '}
                                              <i className="fas fa-briefcase me-2"></i>Mintabie,
                                              South Australia, Australia
                                            </p>
                                            <p>
                                              <i className="fas fa-map-marker-alt me-2"></i>{' '}
                                              Mintabie, South Australia, Australia
                                            </p>
                                          </div>
                                        </div>
                                      </Col>
                                      <Col lg={6} md={6}>
                                        <div className="user-dir-card d-flex align-items-center ">
                                          <div className="user-dir-img">
                                            <img
                                              src={require('../assets/images/default-male-avatar.png')}
                                              alt="img"
                                            />
                                          </div>
                                          <div className="user-dir-dis">
                                            <h4>
                                              <Link to="#">User Sharma</Link>
                                            </h4>
                                            <p>
                                              {' '}
                                              <i className="fas fa-briefcase me-2"></i>Mintabie,
                                              South Australia, Australia
                                            </p>
                                            <p>
                                              <i className="fas fa-map-marker-alt me-2"></i>{' '}
                                              Mintabie, South Australia, Australia
                                            </p>
                                          </div>
                                        </div>
                                      </Col>
                                    </Row>
                                  </div>
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </Tab.Pane>

                      <Tab.Pane eventKey="fourth">
                        <ReachAnalysis
                          selectedTabName={selectedTabName}
                          getYearOfStablishMentValue={getYearOfStablishMentValue}
                        />
                      </Tab.Pane>
                    </Tab.Content>
                  </Col>
                </Row>
              </Tab.Container>
            </div>
          </div>
        </Container>
      </div>
      {loading ? <Loader /> : ''}
    </>
  );
}

const GoogleChart = () => {};
export default SuperAdminDashboard;
