/**
@copyright  :   ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
@author     :   Shiv Charan Panjeta 
 
All Rights Reserved.
Proprietary and confidential :  All information contained here in is, and remains
the property of ToXSL Technologies Pvt. Ltd. and it's partners.
Unauthorized copying of this file, via any medium is strictly prohibited.
*/
import { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Table from 'react-bootstrap/Table';
import { useParams } from 'react-router';
import { Link, useNavigate } from 'react-router-dom';
import NoDataFound from '../../components/commoncomponents/NoDataFound';
import Loader from '../../components/commoncomponents/loader/loader-large';
import * as adminServices from '../../services/AuthServices';
import { removeLeader, stateChangeForLeader } from '../../services/CompanyServices';
import showNotification from '../../services/NotificationService';

let intervalId;

export default function ManagLeaders() {
  // const { state } = props;
  const { id, tim_id } = useParams();
  const navigate = useNavigate();
  const [sortType, setSortType] = useState('DESC');
  const [sortField, setSortField] = useState('group_id');
  const [list, setList] = useState([]);
  const [record, setRecord] = useState();
  const [showalert, setShowAlert] = useState(false);
  const [loding, setLoading] = useState(false);
  const [groupData, setGroupData] = useState({
    id: '',
    name: '',
    email: ''
  });

  useEffect(() => {
    apiCall();
  }, []);

  const apiCall = () => {
    setLoading(true);
    adminServices.companyLeaderList(id).then((resp) => {
      setLoading(false);
      if (resp?.status === 200) {
        setList(resp?.data?.data?.viewlist);
        setRecord(resp?.data?.data);
      }
    });
  };

  const sortHandler = (e, type) => {
    e.preventDefault();
    let currentSort = sortType === 'ASC' ? 'DESC' : 'ASC';
    setSortType(currentSort);
    setSortType(type);
    apiCall(
      sortField,
      groupData.group_id,
      groupData.group_name,
      groupData.group_institute,
      groupData.group_status,
      groupData.group_verified
    );
  };

  const selectHandler = (value, admin_id) => {
    if (value == 'remove') {
      removeLeader(id, admin_id).then((resp) => {
        setLoading(false);
        if (resp?.status === 200) {
          document.getElementById('select').value = '';
          showNotification('danger', resp?.data?.message);
          apiCall();
        }
      });
    } else {
      let status = value === 'active' ? 1 : 0;
      stateChangeForLeader(id, admin_id, status).then((resp) => {
        setLoading(false);
        if (resp?.status === 200) {
          document.getElementById('select').value = '';
          showNotification('success', resp?.data?.message);
          apiCall();
        }
      });
    }
  };

  return (
    <>
      <div className="dash-main-content">
        <Container fluid>
          <div className="dash-page-content">
            <div className="dash-page-title">
              <Row>
                <Col xs={12} sm={12} md={12}>
                  <div className="dash-title-bar d-flex align-items-center justify-content-between">
                    <div className="title-lft d-flex align-items-center">
                      <div className="dash-icn">
                        <i className="fas fa-users"></i>
                      </div>
                      <div className="dash-title-dis">
                        <h5>
                          Company Page Admin
                          <span>Listing</span>
                        </h5>
                        {list?.length !== 0 && (
                          <h5>
                            Total Record(s): <span>{list?.length}</span>
                          </h5>
                        )}
                      </div>
                    </div>
                    <div className="title-rt-btn d-flex align-items-center">
                      <Link
                        className="add-btn-grn"
                        to={`/admin/companies/add_leaders/${id}/${tim_id}`}>
                        Add Leader
                      </Link>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="dash-content-card">
              <div className="table-responsive">
                <Table className="custom-table-data" striped bordered hover>
                  <thead>
                    <tr>
                      <th>Id</th>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Status</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {list && list?.length ? (
                      list?.map((data, index) => {
                        return (
                          <tr key={index}>
                            <td>{data?.user_id || 'NA'}</td>
                            <td>{data?.full_name || 'NA'}</td>
                            <td>{data?.email || 'NA'}</td>
                            {/* <td>{data?.memberType == false ? 'Member' : 'Admin'}</td> */}
                            <td>
                              {data.active && data.active == 1 ? 'Active' : 'Inactive' || 'NA'}
                            </td>
                            <td>
                              <Form.Select
                                aria-label="Default select example"
                                onChange={(e) => {
                                  selectHandler(e.target.value, data?.user_id);
                                }}
                                id="select">
                                <option value="">Action</option>
                                {data.active == 1 ? (
                                  <option value="inactive">InActive</option>
                                ) : (
                                  <option value="active">Active</option>
                                )}
                                <option value="remove">Remove Admin</option>
                              </Form.Select>
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr className="text-center">
                        <td colSpan={9}>
                          <NoDataFound />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
                {/* pagination */}
              </div>
            </div>
          </div>
        </Container>
      </div>
      {loding ? <Loader /> : ''}
    </>
  );
}
