const initialState = {
  data: [],
  count: null
};

export const communicationListReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'COMMUNICATION_LIST':
      return {
        ...state,
        data: action.payload?.data,
        count: action.payload?.total
      };

    default:
      return state;
  }
};
