import { useState } from 'react';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import { emailRegex } from '../../../globals/helper';
import showNotification from '../../../services/NotificationService';
import { newsLetterSignup } from '../../../services/ShopServices';
import Loader from '../../commoncomponents/loader/loader-large';

export default function NewsLetter() {
  const [newsLetterEmail, setNewsLetterEmail] = useState('');
  const [clicked, setClicked] = useState(false);
  const [loading, setLoading] = useState(false);
  const validate = () => {
    let errors = {};
    if (!newsLetterEmail) {
      errors.newsLetterEmail = 'This field is required.';
    }
    if (newsLetterEmail && !emailRegex.test(newsLetterEmail)) {
      errors.newsLetterEmail = 'Please enter a valid email address.';
    }
    return errors;
  };

  const newsLetterFunc = (e) => {
    e.preventDefault();
    setClicked(true);
    if (!Object.keys(validate(newsLetterEmail))?.length) {
      let body = {
        newsletter_email: newsLetterEmail
      };
      setLoading(true);
      newsLetterSignup(body).then((res) => {
        if (res?.status == 200) {
          setLoading(false);
          showNotification('success', res?.data?.message);
          setClicked(false);
          setNewsLetterEmail('');
        } else {
          showNotification('danger', res?.data?.message);
        }
      });
    }
  };

  return (
    <section className="news-letter-card py-5">
      <Container>
        <Row>
          <Col md={{ span: 6, offset: 3 }}>
            <div className="news-ltter-innr text-center">
              <h4 className="mb-4">Sign up for Newsletter</h4>
              <div className="nws-lttr-frm">
                <Form onSubmit={newsLetterFunc}>
                  <Form.Group className="search-group" controlId="formBasicEmail">
                    <Form.Control
                      type="email"
                      placeholder="Your email address"
                      value={newsLetterEmail}
                      onChange={(e) => setNewsLetterEmail(e.target.value)}
                    />
                    <div
                      className={`${
                        clicked && validate(newsLetterEmail).newsLetterEmail ? '' : 'd-none'
                      } danger-frm `}
                    >
                      {clicked && validate(newsLetterEmail).newsLetterEmail}
                    </div>
                    <Button className="search-btn" type="submit">
                      <i className="fas fa-check"></i>
                    </Button>
                  </Form.Group>
                </Form>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      {loading ? <Loader /> : ''}
    </section>
  );
}
