import { useEffect, useState } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { Link } from 'react-router-dom';
import useDocumentTitle from '../../../globals/useDocumentTitle';
import { getAllSocialAlbums } from '../../../services/SocialServices';
import HeaderLogin from '../../commoncomponents/HeaderLogin';
import Loader from '../../commoncomponents/loader/loader-large';
import NewsSidebar from '../../commoncomponents/newsfeeds/NewsSidebar';
import TopTabbings from './TopTabbings';

function Photos() {
  useDocumentTitle('List of Albums | AlmaBay');
  const [albumsData, setAlbumsData] = useState([]);
  const [loading, setLoading] = useState(false);

  /**
   * an useEffect hook to fetch data on first render with IIFE
   */
  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const response = await getAllSocialAlbums();
        if (response?.status === 200) {
          setAlbumsData(response?.data?.data?.sk?.albums);
          setLoading(false);
        }
      } catch (error) {
        console.error(error);
        setLoading(false);
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  return (
    <>
      <HeaderLogin />
      <div className="header-lnk-feeds">
        <div className="container">
          <TopTabbings state={'social'} />
        </div>
      </div>
      <section className="feed-sec bg-grey py-4">
        <div className="container">
          <Row>
            <Col xs={12} lg={2} md={12}>
              <NewsSidebar state="1" current="album" />
            </Col>
            <Col xs={12} lg={10} md={12}>
              <div className="album-page bg-white p-0">
                <div className="module-hd d-flex align-items-center justify-content-between mb-2">
                  <h4>Albums</h4>
                  <Link to="/user/create-album">
                    <i className="fas fa-plus me-2"></i>
                    Create an album
                  </Link>
                </div>
                {albumsData?.length !== 0 ? (
                  <>
                    <div className="photos-list p-3">
                      {/* <div className="albums-loader loading-skeleton">
                        <Col md={4}>
                          <div className="photo-card">
                            <Link to={`#`}>
                              <img
                                src={require('../../../assets/images/default-male-avatar.png')}
                                alt="img"
                              />
                              <div className="photos-card-hd">
                                <h4> </h4>
                              </div>
                            </Link>
                          </div>
                        </Col>
                      </div> */}

                      <Row>
                        {albumsData?.map((album, index) => {
                          return (
                            <Col md={4} key={index}>
                              <div className="photo-card">
                                <Link to={`/user/album/${album?.id}`}>
                                  <img
                                    src={
                                      album?.photo
                                        ? process.env.REACT_APP_API_DOMAIN + album?.photo
                                        : require('../../../assets/images/default-male-avatar.png')
                                    }
                                    // src={
                                    //   album?.photo
                                    //     ? album?.photo
                                    //     : require('../../../assets/images/default-male-avatar.png')
                                    // }
                                    alt="img"
                                  />
                                  <div className="photos-card-hd">
                                    <h4>{album?.name}</h4>
                                  </div>
                                </Link>
                              </div>
                            </Col>
                          );
                        })}
                      </Row>
                    </div>
                  </>
                ) : (
                  <>
                    {albumsData.length < 0 && !loading && (
                      <div className="upcoming_events p-3">
                        <div className="text-center">
                          <img src={require('../../../assets/images/small-error.png')} alt="img" />
                        </div>
                        <p className="text-center">Data not found.</p>
                      </div>
                    )}
                  </>
                )}
              </div>
            </Col>
          </Row>
        </div>
        {loading && <Loader />}
      </section>
      {/* <FooterMain className={'colour-bg'} /> */}
    </>
  );
}

export default Photos;
