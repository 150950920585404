/**
@copyright  :   ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
@author     :   Shiv Charan Panjeta 
 
All Rights Reserved.
Proprietary and confidential :  All information contained here in is, and remains
the property of ToXSL Technologies Pvt. Ltd. and it's partners.
Unauthorized copying of this file, via any medium is strictly prohibited.
*/

import { useEffect, useState } from 'react';
import { Button, Container } from 'react-bootstrap';
import Alert from 'react-bootstrap/Alert';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Pagination from 'react-bootstrap/Pagination';
import Row from 'react-bootstrap/Row';
import Table from 'react-bootstrap/Table';
import ReactPaginate from 'react-paginate';
import { Link, useNavigate } from 'react-router-dom';
import Select from 'react-select';
import NoDataFound from '../../../components/commoncomponents/NoDataFound';
import { NO, YES } from '../../../globals/constants';
import useDocumentTitle from '../../../globals/useDocumentTitle';
import * as adminServices from '../../../services/AuthServices';
import { getChaptersList } from '../../../services/AuthServices';

let intervalId;
export default function ListChapters() {
  useDocumentTitle('Manage Chapters | AlmaBay');
  const [insId, setInsId] = useState('');
  const navigate = useNavigate();
  const [chekcedId, setCheckedId] = useState([]);
  const [list, setList] = useState([]);
  const [instituteList, setInstituteList] = useState([]);
  const [allId, setAllId] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [response, setResponse] = useState(1);
  const [searchedData, setSearchData] = useState({
    group_id: '',
    group_name: '',
    group_institute: '',
    group_status: '',
    group_verified: ''
  });
  const [globalMsg, setGlobalMsg] = useState('Please Select Any Record');
  const [showalert, setShowAlert] = useState(false);
  useEffect(() => {
    apiCall();
    getInstituteDropdownData();
  }, []);

  const alertHandler = () => {
    clearInterval(intervalId);
    intervalId = setInterval(function () {
      setShowAlert(false);
    }, 5000);
  };
  const onChangeSearch = (e) => {
    const { name, value } = e.target;
    setSearchData({ ...searchedData, [name]: value });
  };

  const apiCall = (
    page = 1,
    sortfield = '',
    order = '',
    group_id = '',
    group_name = '',
    group_institute = '',
    group_status = '',
    group_verified = ''
  ) => {
    let body = {
      page: page ? page : currentPage,
      sortfield,
      order,
      group_id,
      group_name,
      group_institute,
      group_status,
      group_verified
    };
    let pageNo = page ? page : 1;
    setCurrentPage(pageNo);
    getChaptersList(body).then((res) => {
      if (res?.status === 200) {
        setList(res?.data?.data?.viewlist);
        setResponse(res?.data?.data?.totalRecords);
        setAllId(res?.data?.data?.viewlist?.map((data) => data?.id));
      }
    });
  };

  const globalActionHandler = (e) => {
    if (chekcedId.length !== 0) {
      setShowAlert(false);
      let body = {
        ids: chekcedId.join(),
        action: e.target.value
      };
      adminServices.globalStateChangeChapter(body).then((res) => {
        if (res?.status === 200) {
          setGlobalMsg(res?.data?.message);
          setShowAlert(true);
          setCheckedId([]);
          alertHandler();
          document.getElementById('global').value = '';
          apiCall();
        }
      });
    } else {
      setShowAlert(true);
      alertHandler();
    }
  };

  const handleSelectAll = (e) => {
    if (e.target.checked === true) {
      setCheckedId(allId);
    } else {
      setCheckedId([]);
    }
  };

  const getInstituteDropdownData = () => {
    adminServices.getInstituteDropdown().then((res) => {
      let arr = [];
      if (res?.status === 200) {
        let data = res?.data?.data?.allInstitutes;
        for (let i in data) {
          arr.push({ id: i, label: data[i], name: data[i] });
        }
      }
      setInstituteList(arr);
    });
  };

  const handleNaviagteEdit = (value, id) => {
    if (value === 'edit') {
      navigate('/admin/chapters/create', { state: id });
    } else {
      navigate(`/admin/chapter/manage/${id}`);
    }
  };

  let active = 2;
  let items = [];
  for (let number = 1; number <= 5; number++) {
    items.push(
      <Pagination.Item key={number} active={number === active}>
        {number}
      </Pagination.Item>
    );
  }

  const handleMultipleCheckBox = (id) => {
    if (chekcedId.includes(id)) {
      let filterId = chekcedId.filter((data) => data !== id);
      setCheckedId(filterId);
    } else {
      setCheckedId([...chekcedId, id]);
    }
  };

  const searchHandler = (page) => {
    apiCall(
      page,
      'id',
      'DESC',
      searchedData.group_id,
      searchedData.group_name,
      searchedData.group_institute,
      searchedData.group_status,
      searchedData.group_verified
    );
  };

  const resetHandler = (e) => {
    e.preventDefault();
    setSearchData({
      group_id: '',
      group_name: '',
      group_institute: '',
      group_status: '',
      group_verified: ''
    });
    setInsId('');
    apiCall();
  };
  const handlePageClick = (a) => {
    setCheckedId([]);
    let currentPage = a?.selected + 1;
    searchHandler(currentPage);
  };
  return (
    <div className="dash-main-content">
      <Container fluid>
        <div className="dash-page-content">
          <div className="dash-page-title">
            <Row>
              <Col xs={12} sm={12} md={12}>
                <div className="dash-title-bar d-flex align-items-center justify-content-between">
                  <div className="title-lft d-flex align-items-center">
                    <div className="dash-icn">
                      <i className="fas fa-users"></i>
                    </div>
                    <div className="dash-title-dis">
                      <h5>
                        Chapter <span>Listing</span>
                      </h5>
                    </div>
                  </div>

                  <div className="title-rt-btn d-flex align-items-center">
                    <Link className="green_button_admin mx-2" to="/admin/chapters/create">
                      Add New Chapter
                    </Link>
                    <div className="head-instite-sec">
                      <Form.Select
                        aria-label="Default select example"
                        id="global"
                        onChange={(e) => {
                          globalActionHandler(e);
                        }}>
                        <option value="">Choose Action</option>
                        <option value="active">Active </option>
                        <option value="inactive">Inactive</option>
                        <option value="del">Delete</option>
                      </Form.Select>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <div className="dash-content-card">
            <Alert variant={'success'} className={`${showalert ? '' : 'd-none'}`}>
              {globalMsg}
            </Alert>
            <div className="table-responsive">
              <Table className="custom-table-data" striped bordered hover>
                <thead>
                  <tr>
                    <th>
                      <Form.Check
                        type="checkbox"
                        id="checkbox"
                        name="checkall"
                        checked={allId?.length ? allId?.length === chekcedId?.length : false}
                        onChange={(e) => {
                          handleSelectAll(e);
                        }}
                        label=""
                      />
                    </th>
                    <th>Id</th>
                    <th>Chapter Name</th>
                    <th>Institute</th>
                    <th>Member</th>
                    <th>Status</th>
                    <th>Verified</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="search-table">
                    <td></td>
                    <td>
                      <Form.Control
                        type="text"
                        placeholder="Id"
                        name="group_id"
                        value={searchedData?.group_id}
                        onChange={(e) => {
                          onChangeSearch(e);
                        }}
                      />
                    </td>
                    <td>
                      <Form.Control
                        type="text"
                        placeholder="name"
                        name="group_name"
                        onChange={(e) => {
                          onChangeSearch(e);
                        }}
                        value={searchedData?.group_name}
                      />
                    </td>

                    <td>
                      <Select
                        className="basic-single"
                        isDisabled={false}
                        isLoading={false}
                        isClearable={false}
                        isRtl={false}
                        getOptionLabel={(e) => e.label}
                        isSearchable={true}
                        name="group_institute"
                        placeholder="Select Institute"
                        options={instituteList}
                        getOptionValue={(option) => option.id}
                        value={insId}
                        onChange={(e) => {
                          setSearchData({ ...searchedData, ['group_institute']: e.id });
                          setInsId(e);
                        }}
                      />
                    </td>
                    <td></td>

                    <td>
                      <Form.Select
                        aria-label="Default select example"
                        name="group_status"
                        value={searchedData?.group_status}
                        onChange={(e) => {
                          onChangeSearch(e);
                        }}>
                        <option value=""> - Select - </option>
                        <option value="1">Active</option>
                        <option value="0">Inactive</option>
                      </Form.Select>
                    </td>
                    <td>
                      <Form.Select
                        aria-label="Default select example"
                        name="group_verified"
                        value={searchedData?.verified}
                        onChange={(e) => {
                          onChangeSearch(e);
                        }}>
                        <option> - Select - </option>
                        <option value={YES}>Yes</option>
                        <option value={NO}>No</option>
                      </Form.Select>
                    </td>
                    <td className="serac-btns text-center">
                      <button
                        type="button"
                        className="green_button_admin"
                        onClick={(e) => {
                          searchHandler(e);
                        }}>
                        Search
                      </button>
                      <Button
                        className="main-btn-red ms-2"
                        onClick={(e) => {
                          resetHandler(e);
                        }}>
                        Reset
                      </Button>
                    </td>
                  </tr>
                  {list && list.length !== 0 ? (
                    <>
                      {list?.map((data, index) => {
                        return (
                          <tr key={index}>
                            <td>
                              <Form.Check
                                type="checkbox"
                                name="id"
                                id={`id${data?.user_id}`}
                                checked={chekcedId?.includes(data?.id)}
                                onChange={() => handleMultipleCheckBox(data?.id)}
                                label=""
                              />
                            </td>
                            <td>{data?.id}</td>
                            <td>{data?.name}</td>
                            <td>{data?.instituteName}</td>
                            <td>{data?.members_count}</td>
                            <td>
                              {data?.active == 1
                                ? 'Active'
                                : data?.active == 0
                                ? 'Inactive'
                                : 'Deleted'}
                            </td>
                            <td>{data?.verified == 1 ? 'yes' : 'No'}</td>
                            <td>
                              <Form.Select
                                aria-label="Default select example"
                                onChange={(e) => handleNaviagteEdit(e.target.value, data?.id)}>
                                <option>Action</option>
                                <option value="edit">Edit</option>
                                <option value="members">Manage Member</option>
                              </Form.Select>
                            </td>
                          </tr>
                        );
                      })}
                      {''}
                    </>
                  ) : (
                    <tr>
                      <td colSpan={8}>
                        <NoDataFound />
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
              <div className="react-page d-flex">
                {Math.ceil(response / 50) > 1 ? (
                  <ReactPaginate
                    previousLabel={currentPage > 0 ? '<' : ''}
                    nextLabel=">"
                    onPageChange={handlePageClick}
                    pageCount={response / 50}
                    activeClassName={'page-link active'}
                    forcePage={currentPage - 1}
                  />
                ) : (
                  ''
                )}
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}
