import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import * as APISERVICES from '../../../services/MentorArticlesServices';
import * as SOCIALSERVICES from '../../../services/SocialServices';
import { getUserGroups, leaveGroupFromWidget } from '../../../services/UserServices';
import { truncate } from '../../../globals/helper';
import showNotification from '../../../services/NotificationService';
import NoDataFound from '../NoDataFound';
import { Transition } from 'react-transition-group';

function GroupDiscover() {
  const [group, setGroup] = useState([]);
  const [discover, setDiscover] = useState([]);
  const [isScrollable, setIsScrollable] = useState(false);
  const [loadMore, setLoadMore] = useState(true);
  const [loading, setLoading] = useState(false);
  const [totalGroups, setTotalGroups] = useState(0);
  const [state, setState] = useState('');
  const [inProp, setInProp] = useState('');
  const duration = 1000;

  useEffect(() => {
    getGroupDiscover();
  }, []);

  const getGroupDiscover = (offSet, isTrue, leaveGrp, grpId) => {
    setLoading(true);
    if (leaveGrp === true) {
      let data = group.filter((item) => item?.id !== grpId);
      setGroup(data);
    } else {
      getUserGroups('', offSet ? offSet : '', 4).then((res) => {
        if (res?.status === 200) {
          setTotalGroups(res?.data?.total_groups);
          setLoading(false);

          if (isTrue == true) {
            setGroup([...group, ...res.data.data] ?? []);
          } else {
            setGroup(res?.data?.data ?? []);
          }
          if (res?.data?.data?.length < 4 || totalGroups < 4) {
            setLoadMore(true);
          }
        }
      });
    }

    APISERVICES.getDiscover().then((res) => {
      if (res?.status === 200) {
        setLoading(false);
        setDiscover(res?.data?.data || []);
      }
    });
  };

  const handleSkip = (e, id) => {
    e.preventDefault();
    setInProp(id);
    setTimeout(() => {
      let filterdata = discover.filter((data, index) => data?.id !== id);
      setDiscover(filterdata);
    }, 500);
  };

  const handleJoinGroup = (e, id) => {
    e.preventDefault();

    let formData = new FormData();
    formData.append('following_id', id);

    SOCIALSERVICES.socialGroupJoin(formData).then((res) => {
      if (res?.status === 200) {
        showNotification('success', 'Group joined successfully');
        getGroupDiscover();
      } else {
        showNotification('success', res?.data?.message);
      }
    });
  };

  const leaveGroup = (e, id) => {
    e.preventDefault();
    let body = {
      following_id: id
    };
    let formData = new FormData();
    for (let i in body) {
      formData.append(`${i}`, body[i]);
    }
    if (confirm('Do you want to leave from group?') == true) {
      leaveGroupFromWidget(formData).then((res) => {
        if (res?.status === 200) {
          showNotification('success', res?.data?.message);
          getGroupDiscover('', '', true, id);
        } else {
          showNotification('err', res?.data?.message);
        }
      });
    }
  };

  const handleLoadMore = (e) => {
    e.preventDefault();
    setIsScrollable(true);
    getGroupDiscover(group.length, true);
  };
  /* eslint-disable */

  const defaultStyle = {
    transition: `transform ${duration}ms ease-out`

    // opacity: 0
  };

  const transitionStyles = {
    entering: { opacity: 0 },
    entered: { opacity: 1 },
    exiting: { transform: 'translateX(-100%)', opacity: 1 },
    exited: { opacity: 0 }
  };

  return (
    <>
      <div className="group-discover bg-white p-3 mb-3">
        <Tabs defaultActiveKey="home" id="fill-tab-example" className="mb-3" fill title={state}>
          <Tab
            className="discover-outer"
            eventKey="home"
            onMouseMoveCapture={() => setState('Groups')}
            title="Groups">
            <Link className="more-btn" to="/user/groups" target="_blank"> 
              More
            </Link>
            <div className={isScrollable ? 'group-card' : ''}>
              <ul className="group-card-lst">
                {loading && (
                  <>
                    {Array.from(Array(3), function () {
                      return <Shimmer key={Math.random(3)} />;
                    })}
                  </>
                )}
                {/* with simmer */}
                {!loading && group?.length > 0 ? (
                  group?.map((data, index) => (
                    <li key={index}>
                      <div className="mentor-card d-flex">
                        <Link
                          to={`/user/group-detail/${data?.ID}`}
                          target="_blank"
                          className="mentor-icn me-2">
                          <img
                            src={
                              data?.avatar_path
                                ? data?.avatar_path
                                : require('../../../assets/images/birth6.jpg')
                            }
                            alt="img"
                          />
                        </Link>
                        <div className="mentor-rt-dis">
                          <h4>
                            <Link
                              title={data?.name}
                              target="_blank"
                              to={`/user/group-detail/${data?.ID}`}>
                              {data?.name}
                            </Link>
                          </h4>
                          <p>{data?.count ?? 0} Member</p>
                          <ul className="mentr-action-btn">
                            <li>
                              <Link to="#">{data?.group_privacy}</Link>
                            </li>
                            <li>
                              <Link to="#" onClick={(e) => leaveGroup(e, data?.id)}>
                                Leave
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </li>
                  ))
                ) : (
                  <>
                    {!loading && group.length == 0 && (
                      <NoDataFound text="No more Groups for you at the moment." />
                    )}
                  </>
                )}
              </ul>
            </div>

            {loadMore == true && totalGroups !== 0 ? (
              <div className="more-bottom-btn">
                <Link to="#" onClick={(e) => handleLoadMore(e)}>
                  More Group
                </Link>
              </div>
            ) : (
              ''
            )}
          </Tab>

          <Tab className="discover-outer" eventKey="profile" title="Discover">
            <Link className="more-btn" to="/user/groups" target="_blank">
              More
            </Link>

            <div className="dicover-card" style={{ height: '300px', overflow: 'hidden' }}>
              <ul className="group-card-lst">
                {discover && discover?.length ? (
                  discover?.map((data, index) => {
                    return (
                      <li key={index + data?.id}>
                        <Transition in={inProp == data?.id ? false : true} timeout={300}>
                          {(state) => (
                            <div
                              className="mentor-card discover-card-inr d-flex"
                              style={{
                                ...defaultStyle,
                                ...transitionStyles[state]
                              }}>
                              <Link to="#" className="mentor-icn me-2">
                                <img
                                  src={ 
                                    data?.logo
                                      ? process.env.REACT_APP_API_DOMAIN + data?.logo
                                      : require('../../../assets/images/default-male-avatar.png')
                                  }
                                  alt="img"
                                />
                              </Link>
                              <div className="mentor-rt-dis">
                                <h4>
                                  <Link
                                    title={data?.name}
                                    target="_blank"
                                    to={`/user/group-detail/${data?.id}`}>
                                    {truncate(data?.name, 20)}
                                  </Link>
                                </h4>
                                <p>{data?.total_members} Members</p>
                                <ul className="mentr-action-btn">
                                  <li>
                                    <Link to="#">{data?.group_privacy}</Link>
                                  </li>
                                  <li>
                                    <Link to="#" onClick={(e) => handleJoinGroup(e, data?.id)}>
                                      Join
                                    </Link>
                                  </li>
                                </ul>
                              </div>
                              <Link
                                className="close-btn"
                                to="#"
                                onClick={(e) => handleSkip(e, data?.id)}>
                                <i className="fas fa-times"></i>
                              </Link>
                            </div>
                          )}
                        </Transition>
                      </li>
                    );
                  })
                ) : (
                  <NoDataFound text="No more Groups for you at the moment." />
                )}
              </ul>
            </div>
          </Tab>
        </Tabs>
      </div>
    </>
  );
}

export default GroupDiscover;

const Shimmer = () => {
  return (
    <li>
      <div className="mentor-card d-flex mentor-loader loading-skeleton">
        <Link to="#" className="mentor-icn me-2">
          <img src={require('../../../assets/images/birth6.jpg')} alt="img" />
        </Link>
        <div className="mentor-rt-dis">
          <h4>
            <Link to="#">Details</Link>
          </h4>
          <p> Member</p>
          <ul className="mentr-action-btn">
            <li>
              <Link to="#">Skip</Link>
            </li>
            <li>
              <Link to="#">Leave</Link>
            </li>
          </ul>
        </div>
      </div>
    </li>
  );
};
