/**
@copyright  :   ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
@author     :   Shiv Charan Panjeta 
 
All Rights Reserved.
Proprietary and confidential :  All information contained here in is, and remains
the property of ToXSL Technologies Pvt. Ltd. and it's partners.
Unauthorized copying of this file, via any medium is strictly prohibited.
*/

import { Container } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { Link } from 'react-router-dom';

export default function AppliedJobs() {
  return (
    <div className="dash-main-content">
      <Container fluid>
        <div className="dash-page-content">
          <div className="dash-page-title mb-3">
            <Row>
              <Col xs={12} sm={12} md={12}>
                <div className="dash-title-bar d-flex align-items-center justify-content-between">
                  <div className="title-lft d-flex align-items-center">
                    <div className="dash-icn">
                      <i className="fas fa-briefcase"></i>
                    </div>
                    <div className="dash-title-dis">
                      <h5>
                        Applicants for &nbsp;
                        <span>
                          Web Application Developer with 5 years experience urgently required
                        </span>
                        &nbsp; Job
                      </h5>
                    </div>
                  </div>
                  <div className="title-rt-btn d-flex align-items-center">
                    <Link className="add-btn-grn" to="/admin/jobfest/job/create">
                      Back To Listing
                    </Link>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <div className="dash-content-card applied-cntnt">
            <div className="apllied-card d-flex align-items-top justify-content-between">
              <div className="applied-lft-dis">
                <div className="applied-prof d-flex align-items-center">
                  <div className="applied-prof-icn">
                    <img src={require('../../assets/images/birth8.jpg')} alt="Profile" />
                  </div>
                  <div className="applied-prof-name">
                    <Link to="#">Coordinator</Link>
                  </div>
                </div>
                <div className="applied-dis">
                  <p>
                    <b>Email : </b>almabaycareer@almabay.com
                  </p>
                  <p>
                    <b>Subject : </b>Job application for Web Application Developer with 5 years
                    experience urgently required
                  </p>
                  <p>
                    <b>Message : </b>Hello, please consider this application
                  </p>
                  <Link className="add-btn-grn mt-1" to="#">
                    Download Resume
                  </Link>
                </div>
              </div>
              <div className="applied-date-card">
                <p className="apply-date">Applied on: May 14, 2015</p>
                <span className="status-approved">Approved</span>
              </div>
            </div>
          </div>
          <div className="dash-content-card applied-cntnt">
            <div className="apllied-card d-flex align-items-top justify-content-between">
              <div className="applied-lft-dis">
                <div className="applied-prof d-flex align-items-center">
                  <div className="applied-prof-icn">
                    <img src={require('../../assets/images/birth8.jpg')} alt="Profile" />
                  </div>
                  <div className="applied-prof-name">
                    <Link to="#">Coordinator</Link>
                  </div>
                </div>
                <div className="applied-dis">
                  <p>
                    <b>Email : </b>almabaycareer@almabay.com
                  </p>
                  <p>
                    <b>Subject : </b>Job application for Web Application Developer with 5 years
                    experience urgently required
                  </p>
                  <p>
                    <b>Message : </b>Hello, please consider this application
                  </p>
                  <Link className="add-btn-grn mt-1" to="#">
                    Download Resume
                  </Link>
                </div>
              </div>
              <div className="applied-date-card">
                <p className="apply-date">Applied on: May 14, 2015</p>
                <span className="status-approved">Approved</span>
              </div>
            </div>
          </div>
          <div className="dash-content-card applied-cntnt">
            <div className="apllied-card d-flex align-items-top justify-content-between">
              <div className="applied-lft-dis">
                <div className="applied-prof d-flex align-items-center">
                  <div className="applied-prof-icn">
                    <img src={require('../../assets/images/birth8.jpg')} alt="Profile" />
                  </div>
                  <div className="applied-prof-name">
                    <Link to="#">Coordinator</Link>
                  </div>
                </div>
                <div className="applied-dis">
                  <p>
                    <b>Email : </b>almabaycareer@almabay.com
                  </p>
                  <p>
                    <b>Subject : </b>Job application for Web Application Developer with 5 years
                    experience urgently required
                  </p>
                  <p>
                    <b>Message : </b>Hello, please consider this application
                  </p>
                  <Link className="add-btn-grn mt-1" to="#">
                    Download Resume
                  </Link>
                </div>
              </div>
              <div className="applied-date-card">
                <p className="apply-date">Applied on: May 14, 2015</p>
                <span className="status-approved">Approved</span>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}
