import React, { useCallback, useEffect, useState } from 'react';
import { Container, TabContent } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import Cropper from 'react-easy-crop';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { getCroppedImg } from '../../../globals/canvasUtils';
import useDocumentTitle from '../../../globals/useDocumentTitle';
import showNotification from '../../../services/NotificationService';
import {
  SocialClubSetting,
  acceptRejectClubMemberRequest,
  addAdminForClub,
  addSocialClubPost,
  getClubMembers,
  getPublisherBox,
  getSocialClubPost,
  removeClubMember,
  socialClubAddMember,
  socialClubJoin,
  socialClubLeave
} from '../../../services/SocialServices';
import {
  changeClubCoverImage,
  getShowClubDetail,
  leaveGroupFromWidget,
  removeClubCoverImage
} from '../../../services/UserServices';
import HeaderLogin from '../../commoncomponents/HeaderLogin';
import NoDataFound from '../../commoncomponents/NoDataFound';
import NewsSidebar from '../../commoncomponents/newsfeeds/NewsSidebar';
import Upcoming from '../../commoncomponents/newsfeeds/Upcoming';
import WhatsNew from '../../commoncomponents/newsfeeds/WhatsNew';

import useDebounce from '../../../globals/useDebounce';
import { deleteMediaFileAction, youtubeVideoAction } from '../../../redux/action';
import PagePeopleList from './PagePeopleList';
import ClubPostLayout from './PostComponets/ClubPostLayout';
import PostBottomMedia from './PostComponets/PostBottomMedia';
import TopTabbings from './TopTabbings';
import {
  post_publisher_soundcloud_placeholder,
  post_publisher_youtube_placeholder
} from '../../../globals/constants';

function ClubDetail() {
  useDocumentTitle('Almabay');
  const dispatch = useDispatch();
  const loc = useLocation();
  const Isactive = loc?.state;

  const timeline_id = useParams()?.encrypted_id;
  const result = useSelector((state) => state?.youtubeVideoList?.videos);
  const userDetail = useSelector((state) => state?.user?.details);

  const [latestPost, setLatestPost] = useState('');
  const [checkPost, setCheckPost] = useState([]);
  const [selectedFile, setSelectedFile] = useState([]);
  const [uploadFiles, setUploadFiles] = useState([]);
  const [isSelected, setIsSelected] = useState(false);
  const [isSelectedFile, setIsSelectedFile] = useState(false);
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [pageNum, setPageNum] = useState(0);
  const [groupDetail, setGroupDetail] = useState([]);
  const [type, setType] = useState('all');
  const [clicked, setClicked] = useState(false);
  const [active, setActive] = useState('first');
  const [membersData, setMembersData] = useState(null);
  const [memberType, setMemberType] = useState('members');
  const [editBackGround, setEditBackGround] = useState(false);
  const [selectedImage, setSelectedImage] = useState('');
  const [imageSrc, setImageSrc] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const [recipientId, setrecipientId] = useState('');
  const [groupAdmin, setGroupAdmin] = useState(false);
  const [search, setSearch] = useState('');
  const [peopleList, setPeopleList] = useState([]);
  const [peopleCount, setPeopleCount] = useState(0);
  const [peoepleIds, setPeopleIds] = useState([]);
  const [membersName, setMembersName] = useState('');
  const [offset, setOffSet] = useState(6);
  const [membersLoading, setMembersLoading] = useState(false);
  const [postLoading, setPostLoading] = useState(false);
  const [emojiList, setEmojiList] = useState([]);
  const [showEmoji, setShowEmoji] = useState(false);
  const [followStatus, setFollowStatus] = useState('');
  const [soundCloud, setSoundCloud] = useState(false);
  const [youtubeVideoCloud, setYoutubeVideoCloud] = useState(false);
  const [postGoogleMapState, setPostGoogleMapState] = useState(false);
  const [disableInputBox, setDisableInputBox] = useState(false);
  const [setting, setSetting] = useState({
    name: '',
    about: '',
    club_privacy: '',
    add_privacy: 'members',
    timeline_post_privacy: '',
    timeline_id: ''
  });
  const [totalData, setTotalData] = useState('');
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    getDetail(timeline_id);

    getMembersAndAdmin('members');
    getEmojiIcons();

    if (Isactive == 'settings') {
      setActive('fifth');
    }
  }, [timeline_id]);

  useEffect(() => {
    getPostListData();
  }, [type]);

  useEffect(() => {
    if (pageNum <= totalPages) {
      getPostListData();
    }
  }, [pageNum]);

  useEffect(() => {
    if (active === 'first') {
      setType('all');
    } else if (active === 'second') {
      setType('videos');
      setCheckPost([]);
    } else if (active === 'third') {
      setType('photos');
      setCheckPost([]);
    } else {
      setType('all');
    }
  }, [active]);

  const validate = () => {
    let errors = {};
    if (!setting.name.trim()) {
      errors.name = 'This field is required.';
    }

    return errors;
  };
  const getPostListData = (isTrue, postId) => {
    if (isTrue !== 'delete' || isTrue !== 'share') {
      setLoading(true);
      setPostLoading(true);
      setDisableInputBox(true);
    }

    getSocialClubPost(timeline_id, pageNum, type).then((res) => {
      if (res?.status === 200) {
        setPostLoading(false);
        setLoading(false);
        setDisableInputBox(false);
        if (isTrue === true) {
          let arra1 = checkPost.map((item) => {
            const item2 = res.data?.data?.stories?.find((i2) => i2.id == item.id);
            return item ? { ...item, ...item2 } : item2;
          });
          setCheckPost(arra1);
          setPostLoading(false);
          setLatestPost('');
        } else {
          if (res?.data?.data) {
            setCheckPost(
              pageNum > 0 ? [...checkPost, ...res?.data?.data?.stories] : res?.data?.data?.stories
            );
          } else {
            setCheckPost([]);
          }

          if (isTrue == 'share') {
            let checkPostIDs = checkPost?.map((data) => data?.id);

            let arra1 = checkPost.map((item) => {
              const item2 = res.data?.data?.stories?.find((i2) => i2.id == item.id);
              return item ? { ...item, ...item2 } : item2;
            });
            getSocialClubPost(timeline_id, 1, type).then((res2) => {
              let filterData = res2.data?.data?.stories.filter((item) => {
                return !checkPostIDs.includes(item?.id);
              });
              setCheckPost([...filterData, ...arra1]);
            });
          }

          if (isTrue == 'unshare') {
            let arra1 = checkPost
              .map((item) => {
                const item2 = res.data?.data?.stories?.find((i2) => i2.id == item.id);
                return item ? { ...item, ...item2 } : item2;
              })
              ?.filter((data) => !(data?.post_id == postId && data?.via_type == 'share'));
            setCheckPost(arra1);
          }

          if (isTrue == 'makePost') {
            setCheckPost(res?.data?.data?.stories);
          }
        }

        setTotalPages(Math.ceil(res?.data?.data?.total_records / 10));
        setTotalData(res?.data?.data?.total_records);
      } else {
        setLoading(false);
        setDisableInputBox(false);
      }
    });
  };
  const handleSave = () => {
    setClicked(true);
    let body = {
      name: setting?.name,
      about: setting?.about,
      club_privacy: setting?.club_privacy,
      add_privacy: setting?.add_privacy,
      timeline_post_privacy: setting?.timeline_post_privacy,
      timeline_id: groupDetail?.id
    };

    if (!Object.keys(validate()).length) {
      SocialClubSetting(body).then((resp) => {
        if (resp?.status === 200) {
          showNotification('success', resp?.data?.message);

          getDetail();
        }
      });
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSetting({ ...setting, [name]: value });
  };

  const getMembersAndAdmin = (value, search = '') => {
    setMembersLoading(true);
    let body = {
      type: value
    };
    getClubMembers(body, timeline_id, search).then((res) => {
      if (res?.status === 200) {
        setMembersData(res?.data?.data ?? null);
        setPeopleIds(res?.data?.data?.peoples?.map((people) => people?.encrypted_id));
        setMembersLoading(false);
      } else {
        setMembersLoading(false);
      }
    });
  };

  const getDetail = () => {
    setLoading(true);
    getShowClubDetail(timeline_id)
      .then((res) => {
        setLoading(false);
        if (res?.status === 200) {
          const data = res?.data?.data?.sk?.club;
          setGroupDetail(data);
          setGroupAdmin(res?.data?.data?.sk?.admin);
          setSetting({
            name: data?.name,
            about: data?.about,
            club_privacy: data?.club_privacy,
            add_privacy: data?.add_privacy,
            timeline_post_privacy: data?.timeline_post_privacy
          });
          setrecipientId(data?.id);

          setFollowStatus(res?.data?.data?.sk?.follow_status);
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const checkLatestpost = (e) => {
    e.preventDefault();
    setPostLoading(true);

    let formData = new FormData();
    formData.append(`text`, latestPost?.trim());
    formData.append(`recipient_id`, recipientId);
    formData.append(`timeline_id`, '');
    formData.append(`post_privacy[]`, 5);
    for (let i in selectedFile) {
      formData.append(`photos[]`, selectedFile[i]);
    }
    for (let i in uploadFiles) {
      formData.append('files[]', uploadFiles[i]);
    }
    addSocialClubPost(formData).then((res) => {
      if (res?.status === 200) {
        showNotification('success', res?.data?.message);
        setSelectedFile([]);
        setUploadFiles([]);
        setIsSelected(false);
        setIsSelectedFile(false);
        getPostListData('makePost');
        setLatestPost('');
      }
    });
  };

  const makePost = (e) => {
    if (latestPost?.trim() !== '' || selectedFile.length !== 0 || uploadFiles.length !== 0) {
      checkLatestpost(e);
    } else {
      showNotification(
        'danger',
        'This status update appears to be blank. Please write something or attach photo to update your status.'
      );
    }
  };
  const onIncrementPageNo = () => {
    totalData > 10 && setPageNum((no) => no + 1);
  };

  const handleMembers = (e, value) => {
    e.preventDefault();
    setMemberType(value);
    getMembersAndAdmin(value);
  };

  const addAdmin = (e, id, value) => {
    e.preventDefault();
    let body = {
      club_id: groupDetail?.id,
      admin_id: id
    };
    let formData = new FormData();
    for (let i in body) {
      formData.append(`${i}`, body[i]);
    }
    addAdminForClub(formData).then((res) => {
      if (res?.status === 200) {
        showNotification('success', res?.data?.message);
        getDetail();
        getMembersAndAdmin(value);
      } else {
        showNotification('success', res?.data?.message);
      }
    });
  };

  const handleJoinGroup = (id) => {
    let formData = new FormData();
    formData.append('following_id', id);

    socialClubJoin(formData).then((res) => {
      if (res?.status === 200) {
        showNotification('success', 'Club joined successfully');
        getDetail(timeline_id);
      } else {
        showNotification('success', res?.data?.message);
      }
    });
  };

  const showCroppedImage = useCallback(
    async (croppedArea, croppedAreaPixels) => {
      try {
        const croppedImage = await getCroppedImg(imageSrc, croppedAreaPixels);
        setCroppedImage(croppedImage);
      } catch (e) {
        console.error(e);
      }
    },
    [imageSrc, croppedAreaPixels]
  );

  const onFileChange = async (e) => {
    try {
      let files = e?.target?.files;
      if (files && files.length > 0) {
        const targetfileobj = [...files];
        targetfileobj?.map(async (data) => {
          if (
            !data?.type?.includes('image') &&
            (data?.type?.includes('application') ||
              data?.type?.includes('audio') ||
              data?.type?.includes('video'))
          ) {
            showNotification('danger', 'Please use PNG or JPG types of images ', 'random');
            setImageSrc('');
            setSelectedImage('');
            return;
          } else {
            const file = files[0];
            let imageDataUrl = await readFile(file);
            setSelectedImage(file);
            setImageSrc(imageDataUrl);
          }
        });
      }
    } catch (error) {
      console.error(error);
    }
  };
  // for saving cover image of the club
  const handeSaveImage = (e) => {
    e.preventDefault();
    let formData = new FormData();
    formData.append(`image`, selectedImage);
    formData.append(`position`, crop?.y);
    formData.append(`timeline_id`, groupDetail?.id);
    if (selectedImage != '') {
      changeClubCoverImage(formData).then((res) => {
        if (res?.status == 200) {
          getDetail();
          setSelectedImage('');
          setEditBackGround(false);
        }
      });
    } else {
      setEditBackGround(false);
    }
  };

  //  remove cover image
  const removeImage = (e) => {
    e.preventDefault();
    let formData = new FormData();

    formData.append(`timeline_id`, groupDetail?.id);
    removeClubCoverImage(formData).then((res) => {
      if (res?.status == 200) {
        getDetail();
        setEditBackGround(false);
      }
    });
  };

  const checkIsFollower = () => {
    if (peoepleIds?.includes(userDetail?.encrypted_id)) {
      return true;
    } else {
      return false;
    }
  };

  /**
   * add group member
   * @param {*} e
   * @param {*} id
   */
  const addMember = (e, id) => {
    e.preventDefault();
    let body = {
      club_id: groupDetail?.id,
      member_id: id
    };
    let formData = new FormData();
    for (let i in body) {
      formData.append(`${i}`, body[i]);
    }
    socialClubAddMember(formData).then((res) => {
      if (res?.status === 200) {
        showNotification('success', res?.data?.message);
        getDetail();
        try {
          let body = {
            type: 'add_members'
          };
          getClubMembers(body, timeline_id, search).then((res) => {
            if (res?.status === 200) {
              setPeopleList(res?.data?.data ?? []);
              setPeopleCount(res?.data?.data?.total_count ?? 0);
              setLoading(false);
            } else {
              setLoading(false);
            }
          });
        } catch (error) {
          console.error(error);
        }
      } else {
        showNotification('success', res?.data?.message);
      }
    });
  };

  const handleLoadMore = async () => {
    setOffSet(peopleList?.peoples?.length + 6);
    try {
      let body = {
        type: 'add_members'
      };
      getClubMembers(body, timeline_id, search, offset).then((res) => {
        if (res?.status === 200) {
          setPeopleList(res?.data?.data ?? []);
          setPeopleCount(res?.data?.data?.total_count ?? 0);
          setLoading(false);
        } else {
          setLoading(false);
        }
      });
    } catch (error) {
      console.error(error);
    }
  };

  const searchAddMembers = async () => {
    try {
      let body = {
        type: 'add_members'
      };
      const res = await getClubMembers(body, timeline_id, membersName, offset);
      if (res?.status === 200) {
        setPeopleList(res?.data?.data ?? []);
        setPeopleCount(res?.data?.data?.total_count ?? 0);
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) { }
  };

  /**
   * get emoji list
   */
  const getEmojiIcons = () => {
    getPublisherBox().then((res) => {
      if (res?.status == 200) {
        let arr = [];
        let data = res?.data?.data?.emoticons;
        for (let i in data) {
          arr.push({ id: i, label: data[i], value: data[i] });
        }
        setEmojiList(arr);
      } else {
        setEmojiList([]);
      }
    });
  };

  const deleteFile = async (post_id, media_id) => {
    const message = window.confirm('Are you sure you want to remove this file?');
    if (message) {
      dispatch(deleteMediaFileAction(post_id, media_id));
      getPostListData();
    }
  };
  /**
   * leave club
   * @param {*} e
   * @param {*} id
   */
  const leaveClubJoined = (e, id) => {
    e.preventDefault();
    let body = {
      following_id: id
    };
    let formData = new FormData();
    for (let i in body) {
      formData.append(`${i}`, body[i]);
    }
    if (confirm('Do you want to leave from club?') == true) {
      leaveGroupFromWidget(formData).then((res) => {
        if (res?.status === 200) {
          showNotification('success', 'Club left successfully');
          getDetail();
        } else {
          showNotification('success', res?.data?.message);
        }
      });
    }
  };
  /**
   * removing admin
   * @param {*} e
   * @param {*} id
   */
  const removeAdmin = (e, id) => {
    e.preventDefault();
    let body = {
      club_id: groupDetail?.id,
      admin_id: id
    };
    let formData = new FormData();
    for (let i in body) {
      formData.append(`${i}`, body[i]);
    }
    if (confirm('Do you want to remove admin ?') == true) {
      socialClubLeave(formData).then((res) => {
        if (res?.status === 200) {
          showNotification('success', 'admin removed successfully');
          getDetail();
          getMembersAndAdmin('admins');
        } else {
          showNotification('success', res?.data?.message);
        }
      });
    }
  };
  /**
   * toggle media option function
   * @param {*} e
   * @param {*} id
   */
  const toggleMediaGroupFun = (chosen_input_selector = '') => {
    setSoundCloud(false);
    setYoutubeVideoCloud(false);
    setPostGoogleMapState(false);

    if (chosen_input_selector == 'soundcloud-wrapper') {
      setSoundCloud(true);
    } else if (chosen_input_selector == 'youtube-wrapper') {
      setYoutubeVideoCloud(!youtubeVideoCloud);
    } else if (chosen_input_selector == 'google-map-wrapper') {
      setPostGoogleMapState(true);
    }
  };
  const newParagraph = (e) => {
    if (e.key === 'Enter') {
    }
  };
  /**
   * debounce search
   * @param {*} e
   * @param {*} id
   */
  const debouncedSearchTerm = useDebounce(search, 500);

  useEffect(() => {
    if (debouncedSearchTerm) {
      dispatch(youtubeVideoAction(search));
    } else {
      dispatch(youtubeVideoAction());
    }
  }, [debouncedSearchTerm]);
  return (
    <>
      <HeaderLogin className={'colour-bg'} state={'d-none'} />
      <div className="header-lnk-feeds">
        <div className="container">
          <TopTabbings state={'social'} />
        </div>
      </div>
      <section className="feed-sec bg-grey main-ht">
        <Container>
          <Row>
            <Col xs={12} md={2}>
              <NewsSidebar state="social" current="page" />
            </Col>
            <Col md={10}>
              <div className="page-detail-sec py-3">
                <div className="page-detial-cover">
                  {selectedImage !== '' ? (
                    <div className="cropContainer">
                      <Cropper
                        image={imageSrc}
                        crop={crop}
                        zoom={zoom}
                        onCropChange={setCrop}
                        onCropComplete={showCroppedImage}
                        onZoomChange={setZoom}
                        showGrid={false}
                        objectFit={'horizontal-cover'}
                      />
                    </div>
                  ) : (
                    <img
                      src={
                        groupDetail?.actual_cover_url
                          ? groupDetail?.actual_cover_url
                          : require('../../../assets/images/default-cover.png')
                      }
                      alt="cover"
                      style={{ top: groupDetail?.cover_position * 2 + 'px' }}
                    />
                  )}

                  <div className="cover-disc d-flex align-items-center justify-content-between">
                    <div>
                      <h4>
                        <Link to="#">{groupDetail?.name}</Link>
                      </h4>
                      <p style={{ textTransform: 'capitalize' }} className="verified-check">
                        {groupDetail?.club_privacy} Club
                        {groupDetail?.verified == 1 && (
                          <span className="verification ms-2 mt-1">
                            <i className="fa fa-check fa-stack-1x fa-inverse"></i>
                            <i className="fas fa-certificate crfict"></i>
                          </span>
                        )}
                      </p>
                    </div>
                    {!groupAdmin && groupDetail?.club_privacy == 'open' && (
                      <>
                        {followStatus == 'not-following' && (
                          <Button
                            className="join-btn"
                            onClick={() => handleJoinGroup(groupDetail?.id)}>
                            Join Club
                          </Button>
                        )}
                        {followStatus == 'requested' && (
                          <Button className="join-btn">Requested Club</Button>
                        )}
                        {followStatus == 'following' && (
                          <Button
                            className="join-btn"
                            onClick={(e) => leaveClubJoined(e, groupDetail?.id)}>
                            Leave Club
                          </Button>
                        )}
                      </>
                    )}
                  </div>
                  {groupAdmin && (
                    <>
                      {editBackGround == false ? (
                        <Link
                          to="#"
                          className="page-cover-edit"
                          onClick={() => setEditBackGround(true)}>
                          <i className="fas fa-camera me-2"></i> Edit Background
                        </Link>
                      ) : (
                        <ul className="page-cover-function">
                          <li>
                            <Link to="" onClick={(e) => handeSaveImage(e)}>
                              Save
                            </Link>
                          </li>
                          <li>
                            <span className="change-img-card">Change Image</span>
                            <Form.Control type="file" onChange={onFileChange} />
                          </li>
                          <li>
                            <Link to="#" onClick={(e) => removeImage(e)}>
                              Remove Image
                            </Link>
                          </li>
                          <li>
                            <Link to="#" onClick={() => setEditBackGround(false)}>
                              <i className="fas fa-times"></i>
                            </Link>
                          </li>
                        </ul>
                      )}
                    </>
                  )}
                </div>
                <div className="pages-detail-tabs">
                  <Tab.Container id="left-tabs-example" activeKey={show == true ? '' : active}>
                    <div className="pages-detail-tabhd d-flex align-items-center justify-content-between mb-3">
                      <div>
                        <Nav variant="pills" className="">
                          <Nav.Item>
                            <Nav.Link
                              eventKey="first"
                              onClick={() => {
                                handleClose();
                                setActive('first');
                              }}>
                              Discussion
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link
                              eventKey="second"
                              onClick={() => {
                                handleClose();
                                setActive('second');
                                setPageNum(0);
                              }}>
                              Videos
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link
                              eventKey="third"
                              onClick={() => {
                                handleClose();
                                setActive('third');
                                setPageNum(0);

                                // getPeopleList()
                              }}>
                              Photos
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link
                              eventKey="fourth"
                              onClick={() => {
                                handleClose();
                                setActive('fourth');
                                getMembersAndAdmin('members');
                              }}>
                              Members
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            {groupAdmin && (
                              <Nav.Link
                                eventKey="fifth"
                                onClick={() => {
                                  handleClose();
                                  setActive('fifth');
                                }}>
                                Settings
                              </Nav.Link>
                            )}
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link
                              eventKey="sixth"
                              onClick={() => {
                                handleClose();
                                setActive('sixth');
                              }}>
                              Invite
                            </Nav.Link>
                          </Nav.Item>
                        </Nav>
                      </div>
                      <div className="text-end">
                        {groupDetail?.add_privacy == 'admins' && membersData?.admin && (
                          <Button
                            className="create-group-btn"
                            onClick={async () => {
                              handleShow();
                              setActive('');
                              try {
                                let body = {
                                  type: 'add_members'
                                };
                                getClubMembers(body, timeline_id, search, offset).then((res) => {
                                  if (res?.status === 200) {
                                    setPeopleList(res?.data?.data ?? []);
                                    setPeopleCount(res?.data?.data?.total_count ?? 0);
                                    setLoading(false);
                                  } else {
                                    setLoading(false);
                                  }
                                });
                              } catch (error) {
                                console.error(error);
                              }
                            }}>
                            + Add Members
                          </Button>
                        )}
                        {groupDetail?.add_privacy == 'members' && checkIsFollower() && (
                          <Button
                            className="create-group-btn"
                            onClick={async () => {
                              handleShow();
                              setActive('');
                              try {
                                let body = {
                                  type: 'add_members'
                                };
                                getClubMembers(body, timeline_id, search).then((res) => {
                                  if (res?.status === 200) {
                                    setPeopleList(res?.data?.data ?? []);
                                    setPeopleCount(res?.data?.data?.total_count ?? 0);
                                    setLoading(false);
                                  } else {
                                    setLoading(false);
                                  }
                                });
                              } catch (error) {
                                console.error(error);
                              }
                            }}>
                            + Add Members
                          </Button>
                        )}
                      </div>
                    </div>
                    {show ? (
                      <>
                        <TabContent>
                          <PagePeopleList
                            id={groupDetail?.encrypted_id}
                            group_id={groupDetail?.id}
                            peopleList={peopleList}
                            loading={loading}
                            addMember={addMember}
                            handleLoadMore={handleLoadMore}
                            peopleCount={peopleCount}
                            membersName={membersName}
                            setMembersName={setMembersName}
                            searchAddMembers={searchAddMembers}
                            setPeopleList={setPeopleList}
                            setPeopleCount={setPeopleCount}
                            setLoading={setLoading}
                            timeline_id={timeline_id}
                            offset={offset}
                          />
                        </TabContent>
                      </>
                    ) : (
                      <div className="detail-tab-cntnt">
                        <Tab.Content>
                          <Tab.Pane eventKey="first">
                            <Row>
                              <Col xs={12} md={8}>
                                {groupDetail?.add_privacy == 'admins' && membersData?.admin && (
                                  <div className="write-on-wall mb-3">
                                    <h4>
                                      <i className="fas fa-edit me-2"></i> What's going on?
                                    </h4>
                                    <textarea
                                      rows={5}
                                      style={{ width: '100%', resize: 'none' }}
                                      placeholder={'Write something... #hashtags'}
                                      onChange={(e) => {
                                        setLatestPost(e.target.value);
                                      }}
                                      disabled={postLoading}
                                      onKeyDown={(e) => newParagraph(e)}
                                      value={latestPost}></textarea>
                                    {soundCloud && (
                                      <div className="upload-img-detail d-flex align-items-center justify-content-between">
                                        <i className="fa fa-music"></i>
                                        <input
                                          className="soundcloud-input"
                                          type="text"
                                          autoFocus
                                          // onKeyUp={() => SK_searchSoundcloud()}
                                          placeholder={post_publisher_soundcloud_placeholder}
                                        />
                                        {/* <div className="input-result-wrapper"></div> */}
                                      </div>
                                    )}
                                    {youtubeVideoCloud && (
                                      <div className="upload-img-detail d-flex align-items-center justify-content-start">
                                        <i className="fa fa-film me-2"></i>
                                        <input
                                          className="youtube-input"
                                          type="text"
                                          onChange={(e) => {
                                            setSearch(e.target.value);
                                          }}
                                          placeholder={post_publisher_youtube_placeholder}
                                        />
                                        <div className="input-result-wrapper"></div>
                                      </div>
                                    )}
                                    {postGoogleMapState && (
                                      <div className="upload-img-detail d-flex align-items-center justify-content-between">
                                        <i className="fa fa-map-marker "></i>
                                        <input
                                          className="youtube-input"
                                          type="text"
                                          // onKeyUp={() => SK_searchYoutube()}
                                          placeholder={post_publisher_youtube_placeholder}
                                        />

                                        {/* <input id="autocomplete" className="google-map-input" name="google_map_name" type="text" value="" placeholder="<?php echo $this->lang->line('post_publisher_googlemap_placeholder'); ?>" apply-google-places ></input> */}
                                      </div>
                                    )}
                                    {isSelected && (
                                      <div className="upload-img-detail d-flex align-items-center justify-content-between">
                                        <p>
                                          {selectedFile?.length > 1
                                            ? `${selectedFile.length} photo(s) selected`
                                            : `${selectedFile.length} photo selected`}
                                        </p>
                                        <Link
                                          to="#"
                                          onClick={() => {
                                            setIsSelected(false);
                                            setIsSelectedFile(false);
                                            setSelectedFile([]);
                                            setUploadFiles([]);
                                          }}>
                                          <i className="fas fa-times"></i>
                                        </Link>
                                      </div>
                                    )}

                                    {isSelectedFile && (
                                      <div className="upload-img-detail d-flex align-items-center justify-content-between">
                                        <p>
                                          {uploadFiles?.length > 1
                                            ? `${uploadFiles.length} file(s) selected`
                                            : `${uploadFiles.length} file selected`}
                                        </p>
                                        <Link
                                          to="#"
                                          onClick={() => {
                                            setIsSelected(false);
                                            setIsSelectedFile(false);
                                            setSelectedFile([]);
                                            setUploadFiles([]);
                                          }}>
                                          <i className="fas fa-times"></i>
                                        </Link>
                                      </div>
                                    )}
                                    {showEmoji && (
                                      <div className="upload-img-detail d-flex align-items-center justify-content-between">
                                        {emojiList?.length !== 0 && (
                                          <>
                                            {emojiList?.map((data) => {
                                              return (
                                                <Link
                                                  to="#"
                                                  onClick={(e) => {
                                                    e.preventDefault();
                                                    setLatestPost(latestPost + data?.id);
                                                  }}>
                                                  <img src={data?.value} />
                                                </Link>
                                              );
                                            })}
                                          </>
                                        )}
                                      </div>
                                    )}
                                    {result.length > 0 && youtubeVideoCloud ? (
                                      <div className="input_result_wrapper">
                                        {result.map((item, i) => {
                                          return (
                                            <div className="api_data_wrapper">
                                              <table
                                                border={0}
                                                width={'100%'}
                                                cellSpacing={0}
                                                cellPadding={0}>
                                                <tbody>
                                                  <tr>
                                                    <td width={'40%'} align="left" valign="middle">
                                                      <img
                                                        className="thumbnail"
                                                        width={32}
                                                        height={32}
                                                        src={item.snippet.thumbnails.high.url}
                                                        alt="Youtube"
                                                      />
                                                    </td>
                                                    <td align="left" valign="middle">
                                                      <div className="name">
                                                        {item.snippet.title}
                                                      </div>
                                                    </td>
                                                  </tr>
                                                </tbody>
                                              </table>
                                            </div>
                                          );
                                        })}
                                      </div>
                                    ) : (
                                      search != '' && <span>No Result Found</span>
                                    )}
                                    <div className="write-wall-ftr d-flex align-items-center justify-content-between">
                                      <PostBottomMedia
                                        setSelectedFile={setSelectedFile}
                                        setUploadFiles={setUploadFiles}
                                        setIsSelected={setIsSelected}
                                        setIsSelectedFile={setIsSelectedFile}
                                        setShowEmoji={setShowEmoji}
                                        showEmoji={showEmoji}
                                        toggleMediaGroup={toggleMediaGroupFun}
                                      />
                                      <div className="wall-fltr-rght d-flex align-items">
                                        <button
                                          style={
                                            postLoading
                                              ? { cursor: 'not-allowed' }
                                              : { cursor: 'pointer' }
                                          }
                                          disabled={postLoading}
                                          className="accnt-btn ms-2"
                                          onClick={(e) => {
                                            makePost(e);
                                          }}>
                                          <i className="far fa-arrow-alt-circle-right me-1"></i>
                                          {postLoading ? 'Processing' : 'Post'}
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                )}
                                {groupDetail?.add_privacy == 'members' && checkIsFollower() && (
                                  <div className="write-on-wall mb-3">
                                    <h4>
                                      <i className="fas fa-edit me-2"></i> What's going on?
                                    </h4>
                                    <textarea
                                      rows={5}
                                      style={{ width: '100%', resize: 'none' }}
                                      placeholder={'Write something... #hashtags'}
                                      onChange={(e) => {
                                        setLatestPost(e.target.value);
                                      }}
                                      disabled={postLoading}
                                      onKeyDown={(e) => newParagraph(e)}
                                      value={latestPost}></textarea>
                                    {soundCloud && (
                                      <div className="upload-img-detail d-flex align-items-center justify-content-between">
                                        <i className="fa fa-music"></i>
                                        <input
                                          className="soundcloud-input"
                                          type="text"
                                          autoFocus
                                          // onKeyUp={() => SK_searchSoundcloud()}
                                          placeholder={post_publisher_soundcloud_placeholder}
                                        />
                                        {/* <div className="input-result-wrapper"></div> */}
                                      </div>
                                    )}
                                    {youtubeVideoCloud && (
                                      <div className="upload-img-detail d-flex align-items-center justify-content-start">
                                        <i className="fa fa-film me-2"></i>
                                        <input
                                          className="youtube-input"
                                          type="text"
                                          onChange={(e) => {
                                            setSearch(e.target.value);
                                          }}
                                          placeholder={post_publisher_youtube_placeholder}
                                        />
                                        <div className="input-result-wrapper"></div>
                                      </div>
                                    )}
                                    {postGoogleMapState && (
                                      <div className="upload-img-detail d-flex align-items-center justify-content-between">
                                        <i className="fa fa-map-marker "></i>
                                        <input
                                          className="youtube-input"
                                          type="text"
                                          // onKeyUp={() => SK_searchYoutube()}
                                          placeholder={post_publisher_youtube_placeholder}
                                        />

                                        {/* <input id="autocomplete" className="google-map-input" name="google_map_name" type="text" value="" placeholder="<?php echo $this->lang->line('post_publisher_googlemap_placeholder'); ?>" apply-google-places ></input> */}
                                      </div>
                                    )}
                                    {isSelected && (
                                      <div className="upload-img-detail d-flex align-items-center justify-content-between">
                                        <p>
                                          {selectedFile?.length > 1
                                            ? `${selectedFile.length} photo(s) selected`
                                            : `${selectedFile.length} photo selected`}
                                        </p>
                                        <Link
                                          to="#"
                                          onClick={() => {
                                            setIsSelected(false);
                                            setIsSelectedFile(false);
                                            setSelectedFile([]);
                                            setUploadFiles([]);
                                          }}>
                                          <i className="fas fa-times"></i>
                                        </Link>
                                      </div>
                                    )}

                                    {isSelectedFile && (
                                      <div className="upload-img-detail d-flex align-items-center justify-content-between">
                                        <p>
                                          {uploadFiles?.length > 1
                                            ? `${uploadFiles.length} file(s) selected`
                                            : `${uploadFiles.length} file selected`}
                                        </p>
                                        <Link
                                          to="#"
                                          onClick={() => {
                                            setIsSelected(false);
                                            setIsSelectedFile(false);
                                            setSelectedFile([]);
                                            setUploadFiles([]);
                                          }}>
                                          <i className="fas fa-times"></i>
                                        </Link>
                                      </div>
                                    )}
                                    {showEmoji && (
                                      <div className="upload-img-detail d-flex align-items-center justify-content-between">
                                        {emojiList?.length !== 0 && (
                                          <>
                                            {emojiList?.map((data) => {
                                              return (
                                                <Link
                                                  to="#"
                                                  onClick={(e) => {
                                                    e.preventDefault();
                                                    setLatestPost(latestPost + data?.id);
                                                  }}>
                                                  <img src={data?.value} />
                                                </Link>
                                              );
                                            })}
                                          </>
                                        )}
                                      </div>
                                    )}
                                    {result.length > 0 && youtubeVideoCloud ? (
                                      <div className="input_result_wrapper">
                                        {result.map((item, i) => {
                                          return (
                                            <div className="api_data_wrapper">
                                              <table
                                                border={0}
                                                width={'100%'}
                                                cellSpacing={0}
                                                cellPadding={0}>
                                                <tbody>
                                                  <tr>
                                                    <td width={'40%'} align="left" valign="middle">
                                                      <img
                                                        className="thumbnail"
                                                        width={32}
                                                        height={32}
                                                        src={item.snippet.thumbnails.high.url}
                                                        alt="Youtube"
                                                      />
                                                    </td>
                                                    <td align="left" valign="middle">
                                                      <div className="name">
                                                        {item.snippet.title}
                                                      </div>
                                                    </td>
                                                  </tr>
                                                </tbody>
                                              </table>
                                            </div>
                                          );
                                        })}
                                      </div>
                                    ) : (
                                      search != '' && <span>No Result Found</span>
                                    )}
                                    <div className="write-wall-ftr d-flex align-items-center justify-content-between">
                                      <PostBottomMedia
                                        setSelectedFile={setSelectedFile}
                                        setUploadFiles={setUploadFiles}
                                        setIsSelected={setIsSelected}
                                        setIsSelectedFile={setIsSelectedFile}
                                        setShowEmoji={setShowEmoji}
                                        showEmoji={showEmoji}
                                        toggleMediaGroup={toggleMediaGroupFun}
                                      />
                                      <div className="wall-fltr-rght d-flex align-items">
                                        <button
                                          style={
                                            postLoading
                                              ? { cursor: 'not-allowed' }
                                              : { cursor: 'pointer' }
                                          }
                                          disabled={postLoading}
                                          className="accnt-btn ms-2"
                                          onClick={(e) => {
                                            makePost(e);
                                          }}>
                                          <i className="far fa-arrow-alt-circle-right me-1"></i>
                                          {postLoading ? 'Processing' : 'Post'}
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                )}
                                <ClubPostLayout
                                  isPostLoading={postLoading}
                                  checkPost={checkPost}
                                  getPostListData={getPostListData}
                                  totalPages={totalPages}
                                  onIncrementPageNo={onIncrementPageNo}
                                  pageNum={pageNum}
                                  setCheckPost={setCheckPost}
                                  type="all"
                                  deleteFile={deleteFile}
                                />
                              </Col>
                              <Col xs={12} md={4}>
                                <div>
                                  <WhatsNew />
                                </div>
                                <div>
                                  <Upcoming />
                                </div>
                                <div className="right-side-copyrt text-start">
                                  <ul>
                                    <li>
                                      <Link to="#">Privacy Policy</Link>
                                    </li>
                                    <li>
                                      <Link to="#">Terms of Use</Link>
                                    </li>
                                    <li>
                                      <Link to="#">Contact Us</Link>
                                    </li>
                                  </ul>
                                  <p>AlmaBay.com © {new Date().getFullYear()}</p>
                                </div>
                              </Col>
                            </Row>
                          </Tab.Pane>
                          <Tab.Pane eventKey="second">
                            <ClubPostLayout
                              isPostLoading={postLoading}
                              checkPost={checkPost}
                              getPostListData={getPostListData}
                              totalPages={totalPages}
                              onIncrementPageNo={onIncrementPageNo}
                              pageNum={pageNum}
                              setCheckPost={setCheckPost}
                              type="video"
                              deleteFile={deleteFile}
                            />
                          </Tab.Pane>
                          <Tab.Pane eventKey="third">
                            <ClubPostLayout
                              isPostLoading={postLoading}
                              checkPost={checkPost}
                              getPostListData={getPostListData}
                              totalPages={totalPages}
                              onIncrementPageNo={onIncrementPageNo}
                              pageNum={pageNum}
                              setCheckPost={setCheckPost}
                              type="image"
                              deleteFile={deleteFile}
                            />
                          </Tab.Pane>
                          <Tab.Pane eventKey="fourth">
                            <div className="likes-tab-sec">
                              <div className="likes-top-hd flex-wrap d-flex align-items-center justify-content-between">
                                <div>
                                  {membersData !== null && membersData?.count_members && (
                                    <Link
                                      className={`${memberType === 'members' && 'active'}`}
                                      to="#"
                                      onClick={(e) => {
                                        setSearch('');
                                        handleMembers(e, 'members');
                                      }}>
                                      Members <span>{membersData?.count_members}</span>
                                    </Link>
                                  )}

                                  {(membersData !== null && membersData?.count_admins) !== 0 && (
                                    <Link
                                      className={`${memberType === 'admins' && 'active'} ms-3`}
                                      to="#"
                                      onClick={(e) => {
                                        setSearch('');
                                        handleMembers(e, 'admins');
                                      }}>
                                      Admins <span>{membersData?.count_admins}</span>
                                    </Link>
                                  )}

                                  {membersData?.admin &&
                                    membersData?.count_requests > 0 &&
                                    membersData !== null && (
                                      <Link
                                        className={`${memberType === 'requests' && 'active'} ms-3`}
                                        to="#"
                                        onClick={(e) => {
                                          setSearch('');
                                          handleMembers(e, 'requests');
                                        }}>
                                        Requests <span>{membersData?.count_requests}</span>
                                      </Link>
                                    )}
                                </div>
                                <div>
                                  <Form.Group className="search-card" controlId="formBasicEmail">
                                    <Form.Control
                                      type="search"
                                      value={search}
                                      placeholder="Search"
                                      onChange={(e) => {
                                        setSearch(e.target.value);
                                        getMembersAndAdmin(memberType, e.target.value);
                                      }}
                                    />
                                    <Button className="search-btn">
                                      <i className="fas fa-search"></i>
                                    </Button>
                                  </Form.Group>
                                </div>
                              </div>
                              {membersData?.length !== 0 ? (
                                <div className="like-page-card bg-white p-3">
                                  <Row>
                                    {membersData?.peoples?.map((data, index) => {
                                      return (
                                        <>
                                          {/* <Members /> */}
                                          <Col md={6} key={index}>
                                            <div className="like-card-detail member-card-detail d-flex">
                                              <div className="like-card-img">
                                                <Link to="#">
                                                  <img
                                                    src={
                                                      data?.avatar?.complete_url
                                                        ? data?.avatar?.complete_url
                                                        : require('../../../assets/images/default-male-avatar.png')
                                                    }
                                                    alt="img"
                                                  />
                                                </Link>
                                              </div>
                                              <div className="like-card-dis">
                                                <h4>
                                                  {data?.first_name} {data?.last_name}
                                                </h4>
                                                {data?.work_experience?.length !== 0 && (
                                                  <p>
                                                    Working as{' '}
                                                    {data?.work_experience[0]?.designation}
                                                    at {data?.work_experience[0]?.organisation}
                                                  </p>
                                                )}
                                                {memberType == 'members' && membersData?.admin && (
                                                  <>
                                                    <ul className="mentr-action-btn">
                                                      {data?.club_admin != true && (
                                                        <>
                                                          {' '}
                                                          <li>
                                                            <Link
                                                              to="#"
                                                              onClick={(e) =>
                                                                addAdmin(
                                                                  e,
                                                                  data?.id,
                                                                  'members',
                                                                  data?.encrypted_id
                                                                )
                                                              }>
                                                              Make Admin
                                                            </Link>
                                                          </li>
                                                        </>
                                                      )}

                                                      <li>
                                                        <Link
                                                          onClick={async () => {
                                                            let body = {
                                                              club_id: groupDetail?.id,
                                                              member_id: data?.id
                                                            };
                                                            try {
                                                              const response =
                                                                await removeClubMember(body);
                                                              if (response?.status === 200) {
                                                                showNotification(
                                                                  'success',
                                                                  'Member removed successfully!!'
                                                                );
                                                                getMembersAndAdmin('members');
                                                              }
                                                            } catch (error) {
                                                              console.error(error);
                                                            }
                                                          }}
                                                          to="#">
                                                          Remove Member
                                                        </Link>
                                                      </li>
                                                    </ul>
                                                  </>
                                                )}
                                                {memberType == 'admins' && membersData?.admin && (
                                                  <>
                                                    {' '}
                                                    <ul className="mentr-action-btn">
                                                      <li>
                                                        <Link
                                                          onClick={(e) => removeAdmin(e, data?.id)}
                                                          to="#">
                                                          Remove Admin
                                                        </Link>
                                                      </li>
                                                    </ul>
                                                  </>
                                                )}

                                                {memberType == 'requests' && membersData?.admin && (
                                                  <>
                                                    <ul className="mentr-action-btn">
                                                      <li>
                                                        <Link
                                                          onClick={async () => {
                                                            try {
                                                              const response =
                                                                await acceptRejectClubMemberRequest(
                                                                  groupDetail?.id,
                                                                  1
                                                                );
                                                              if (response?.status === 200) {
                                                                showNotification(
                                                                  'success',
                                                                  response?.data?.message
                                                                );
                                                              }
                                                            } catch (error) {
                                                              console.error(error);
                                                            }
                                                          }}
                                                          to="#">
                                                          Accept
                                                        </Link>
                                                      </li>
                                                      <li>
                                                        <Link
                                                          onClick={async () => {
                                                            try {
                                                              const response =
                                                                await acceptRejectClubMemberRequest(
                                                                  groupDetail?.id,
                                                                  0
                                                                );
                                                              if (response?.status === 200) {
                                                                showNotification(
                                                                  'success',
                                                                  response?.data?.message
                                                                );
                                                              }
                                                            } catch (error) {
                                                              console.error(error);
                                                            }
                                                          }}
                                                          to="#">
                                                          Reject
                                                        </Link>
                                                      </li>
                                                    </ul>
                                                  </>
                                                )}
                                              </div>
                                            </div>
                                          </Col>
                                        </>
                                      );
                                    })}
                                  </Row>
                                </div>
                              ) : (
                                <NoDataFound text="Anyone not joined this group at the moment." />
                              )}
                            </div>
                          </Tab.Pane>

                          <Tab.Pane eventKey="fifth">
                            <div className="group-settings-card bg-white p-4">
                              <h4 className="mb-5">Club Settings</h4>
                              <div className="post-job-form">
                                <Form>
                                  <Form.Group
                                    as={Row}
                                    className="mb-4"
                                    controlId="formPlaintextEmail">
                                    <Form.Label column sm="3">
                                      Username:
                                    </Form.Label>
                                    <Col sm="9">
                                      <Form.Control
                                        type="text"
                                        defaultValue={groupDetail?.username}
                                        readOnly
                                        disabled
                                      />
                                    </Col>
                                  </Form.Group>

                                  <Form.Group
                                    as={Row}
                                    className="mb-4"
                                    controlId="formPlaintextPassword">
                                    <Form.Label column sm="3">
                                      Name:
                                    </Form.Label>
                                    <Col sm="9">
                                      <Form.Control
                                        type="text"
                                        defaultValue={groupDetail?.name}
                                        placeholder="St Stephen School - Alumni Gro"
                                        Name="name"
                                        onChange={handleChange}
                                      />
                                    </Col>

                                    <div
                                      className={`${clicked && validate().name ? '' : 'd-none'
                                        } danger-frm `}>
                                      {clicked && validate().name}
                                    </div>
                                  </Form.Group>
                                  <Form.Group
                                    as={Row}
                                    className="mb-4"
                                    controlId="formPlaintextPassword">
                                    <Form.Label column sm="3">
                                      About:
                                    </Form.Label>
                                    <Col sm="9">
                                      <Form.Control
                                        type="text"
                                        as="textarea"
                                        name="about"
                                        defaultValue={groupDetail?.about}
                                        onChange={handleChange}
                                        placeholder="Write about your group..."
                                      />
                                    </Col>
                                  </Form.Group>
                                  <Form.Group
                                    as={Row}
                                    className="mb-4"
                                    controlId="formPlaintextPassword">
                                    <Form.Label column sm="3">
                                      Privacy:
                                    </Form.Label>
                                    <Col sm="9">
                                      <div className="privacy-radio-card">
                                        {['radio'].map((type) => (
                                          <div key={`inline-${type}`} className="mb-3">
                                            <div className="privacy-inr-card d-flex align-items-top mb-2">
                                              <Form.Check
                                                type={type}
                                                name="club_privacy"
                                                checked={setting?.club_privacy === 'open'}
                                                value="open"
                                                onChange={handleChange}
                                              />

                                              <div className="privacy-radio-dis ms-3" name="open">
                                                <h4>
                                                  Open Club
                                                  <i className="fas fa-globe-asia ms-1"></i>
                                                </h4>
                                                <p>Anyone can see and join the group.</p>
                                              </div>
                                            </div>
                                            <div className="privacy-inr-card d-flex align-items-top mb-2">
                                              <Form.Check
                                                type={type}
                                                name="club_privacy"
                                                value="closed"
                                                checked={setting?.club_privacy == 'closed'}
                                                onChange={handleChange}
                                              />
                                              <div className="privacy-radio-dis ms-2" name="close">
                                                <h4>
                                                  Closed Club <i className="fas fa-lock ms-1"></i>
                                                </h4>
                                                <p>
                                                  Anyone can see and request to join the group.
                                                  Requests can be accepted or declined by admins.
                                                </p>
                                              </div>
                                            </div>
                                            <div className="privacy-inr-card d-flex align-items-top mb-2">
                                              <Form.Check
                                                type={type}
                                                name="club_privacy"
                                                value="secret"
                                                checked={setting?.club_privacy == 'secret'}
                                                onChange={handleChange}
                                              />
                                              <div className="privacy-radio-dis ms-2" name="secret">
                                                <h4>
                                                  Secret Club
                                                  <i className="fas fa-shield-alt ms-1"></i>
                                                </h4>
                                                <p>Only members can access the group.</p>
                                              </div>
                                            </div>
                                          </div>
                                        ))}
                                      </div>
                                    </Col>
                                  </Form.Group>
                                  <Form.Group
                                    as={Row}
                                    className="mb-4"
                                    controlId="formPlaintextPassword">
                                    <Form.Label column sm="3">
                                      Who can add members to this group?:
                                    </Form.Label>
                                    <Col sm="9">
                                      <Form.Select
                                        aria-label="Default select example"
                                        Name="add_privacy"
                                        value={setting?.add_privacy}
                                        onChange={handleChange}>
                                        <option value="members">Members</option>
                                        <option value="admins">Admins</option>
                                      </Form.Select>
                                    </Col>
                                  </Form.Group>
                                  <Form.Group
                                    as={Row}
                                    className="mb-4"
                                    controlId="formPlaintextPassword">
                                    <Form.Label column sm="3">
                                      Who can post on this group:
                                    </Form.Label>
                                    <Col sm="9">
                                      <Form.Select
                                        aria-label="Default select example"
                                        Name="timeline_post_privacy"
                                        value={setting?.timeline_post_privacy}
                                        onChange={handleChange}>
                                        <option value="members">Members</option>
                                        <option value="admins">Admins</option>
                                      </Form.Select>
                                    </Col>
                                  </Form.Group>
                                  <Form.Group as={Row} className="mb-4">
                                    <Form.Label column sm="3"></Form.Label>
                                    <Col sm="9">
                                      <Button
                                        style={{
                                          backgroundColor: '#fbae38',
                                          borderColor: '#fbae38',
                                          fontWeight: 'normal'
                                        }}
                                        className="main-btn-grn"
                                        onClick={(e) => {
                                          handleSave(e);
                                        }}>
                                        Save Changes
                                      </Button>
                                    </Col>
                                  </Form.Group>
                                </Form>
                              </div>
                            </div>
                          </Tab.Pane>
                        </Tab.Content>
                      </div>
                    )}
                  </Tab.Container>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}

/**
 * file redaer function for converting this to in to a blob when we select photo from easy crop
 * @param {*} file
 * @returns
 */
function readFile(file) {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => resolve(reader.result), false);
    reader.readAsDataURL(file);
  });
}

export default ClubDetail;
