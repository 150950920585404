/**
@copyright  :   ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
@author     :   Shiv Charan Panjeta 
 
All Rights Reserved.
Proprietary and confidential :  All information contained here in is, and remains
the property of ToXSL Technologies Pvt. Ltd. and it's partners.
Unauthorized copying of this file, via any medium is strictly prohibited.
*/

import { useEffect, useState } from 'react';
import { Col, Container, Form, Row } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import useDocumentTitle from '../../../globals/useDocumentTitle';
import { addMenuList, parentMenu } from '../../../services/AuthServices';
import showNotification from '../../../services/NotificationService';

const AddMenuList = () => {
  const location = useLocation();
  const { state: locationData } = location;
  const navigate = useNavigate();
  const [menuName, setMenuName] = useState('');
  const [parentMenuId, setParentMenuId] = useState('');
  const [parent, setParent] = useState([]);

  useEffect(() => {
    parentMenuList();
    if (locationData) {
      setMenuName(locationData?.name);
    }
  }, []);

  const parentMenuList = async () => {
    try {
      const response = await parentMenu();
      if (response?.status === 200) {
        setParent(response.data?.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    let addBody = {
      name: menuName,
      parent: parentMenuId
    };

    let editBody = {
      menu_id: location?.state?.menu_id,
      name: menuName
    };

    try {
      const response = await addMenuList(location?.state === null ? addBody : editBody);
      if (response.status === 200) {
        showNotification('success', response?.data?.mesage);
        navigate(`/admin/menu-list`);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="dash-main-content">
      <Container fluid>
        <div className="dash-page-content">
          <div className="dash-page-title">
            <Row>
              <Col xs={12} sm={12} md={12}>
                <div className="dash-title-bar d-flex align-items-center justify-content-between">
                  <div className="title-lft d-flex align-items-center">
                    <div className="icons_head">
                      <i className="fas fa-bars"></i>
                    </div>
                    {location.state === null
                      ? useDocumentTitle('Add Menu | AlmaBay')
                      : useDocumentTitle('Edit Menu | AlmaBay')}
                    <h5 className="ms-2">{location.state === null ? 'Add Menu' : 'Edit Menu'}</h5>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <div className="dash-content-card">
            <div className="cmmn-form">
              <Form>
                <Row>
                  {location?.state === null && (
                    <>
                      <Col xs={12} md={6} lg={6}>
                        <Form.Group className="mb-3" controlId="formBasicPassword">
                          <Form.Label>
                            Parent Menu <span className="text-danger">*</span>
                          </Form.Label>

                          <Form.Select
                            aria-label="Default select example"
                            name="parentMenuId"
                            value={parentMenuId}
                            onChange={(e) => {
                              setParentMenuId(e.target.value);
                            }}>
                            <option value={null}>Select</option>
                            {parent?.map((data) => {
                              return (
                                <option key={data?.menu_id} value={data?.menu_id}>
                                  {data?.name}
                                </option>
                              );
                            })}
                          </Form.Select>
                        </Form.Group>
                      </Col>
                      <Col xs={12} md={6} lg={6}>
                        <Form.Group className="mb-3" controlId="formBasicPassword">
                          <Form.Label>
                            Name <span className="text-danger">*</span>
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter menu name"
                            name="menuName"
                            value={menuName}
                            onChange={(e) => {
                              setMenuName(e.target.value);
                            }}
                            autoComplete="Off"
                          />
                        </Form.Group>
                      </Col>
                    </>
                  )}
                  {location.state !== null && (
                    <Col xs={12} md={6} lg={6}>
                      <Form.Group className="mb-3" controlId="formBasicPassword">
                        <Form.Label>
                          Name <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter menu name"
                          name="menuName"
                          value={menuName}
                          onChange={(e) => {
                            setMenuName(e.target.value);
                          }}
                          autoComplete="Off"
                        />
                      </Form.Group>
                    </Col>
                  )}
                </Row>
                {locationData ? (
                  <button
                    className="green_button_admin"
                    type="button"
                    onClick={(e) => {
                      submitHandler(e);
                    }}>
                    Update
                  </button>
                ) : (
                  <button
                    className="green_button_admin"
                    type="button"
                    onClick={(e) => {
                      submitHandler(e);
                    }}>
                    Save
                  </button>
                )}
              </Form>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default AddMenuList;
