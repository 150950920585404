import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import FooterMain from '../../commoncomponents/FooterMain';
import useDocumentTitle from '../../../globals/useDocumentTitle';
import * as APISERVICES from '../../../services/AuthServices';
import { useState } from 'react';
import showNotification from '../../../services/NotificationService';
import { useNavigate, useParams } from 'react-router-dom';

function ResetPassword() {
  useDocumentTitle('AlmaBay');

  const [clicked, setClicked] = useState(false);
  const { token } = useParams();
  const navigate = useNavigate();
  const [details, setDetails] = useState({
    new_password: ''
  });

  const handlePassword = (e) => {
    setClicked(true);
    e.preventDefault();
    const body = {
      new_password: details.new_password
    };
    if (!Object.keys(validate()).length) {
      APISERVICES.resetPasssword(token, body).then((res) => {
        setClicked(false);
        if (res?.status === 200) {
          showNotification('danger', res?.data?.message);
          navigate('/login');
        }
      });
    }
  };

  const handleChange = (e, name = '') => {
    if (name) {
      setDetails({
        ...details,
        [name]: e.value
      });
    } else {
      const { name, value } = e.target;
      setDetails({
        ...details,
        [name]: value
      });
    }
  };

  const validate = () => {
    let errors = {};
    if (details?.new_password == '') {
      errors.new_password = 'Please enter your password.';
    }
    // else if (!emailValidate.test(details?.new_password)) {
    //     errors.new_password = 'Password must contain alphanumeric'
    // }
    return errors;
  };

  return (
    <>
      <section className="login-card py-5 bg-blue align-items-center d-flex">
        <div className="container">
          <Row>
            <Col md={{ span: 4, offset: 4 }}>
              <div className="lgn-frm-card">
                <h4 className="mb-3">Find your AlmaBay account</h4>
                <p className="mb-4">Reset Password</p>
                <div className="lgn-frm">
                  <Form>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Control
                        type="password"
                        placeholder="Enter new password"
                        name="new_password"
                        value={details.new_password}
                        onChange={handleChange}
                      />
                      <div className={'danger-frm'}>{clicked && validate()?.new_password}</div>
                    </Form.Group>
                    <div className="frm-btn d-flex align-items-center mt-4">
                      <Button className="cmmn-btn" onClick={(e) => handlePassword(e)}>
                        Reset password
                      </Button>
                    </div>
                  </Form>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </section>
      <div className="footer-outr">
        <FooterMain className={'colour-bg'} />
      </div>
    </>
  );
}

export default ResetPassword;
