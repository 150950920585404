import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Accordion } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import * as index from '../SvgIcons/allIcons';
import * as APISERVICES from '../../../services/MentorArticlesServices';
import { truncate } from '../../../globals/helper';

function NewsSidebar(props) {
  const { state, current } = props;
  const userDetail = useSelector((state) => state?.user?.details);

  const [friendCount, setFriendCount] = useState(0);
  const [mentorCount, setMentorCount] = useState(0);
  const [menteesCount, setMenteesCount] = useState(0);
  const [countLoading, setCountLoading] = useState(false);
  const [showAnswer, setShowAnswer] = useState(false);

  useEffect(() => {
    getCounts();
  }, []);

  const getCounts = () => {
    setCountLoading(true);
    APISERVICES.getFriendsCount().then((res) => {
      setCountLoading(false);
      if (res?.status === 200) {
        setFriendCount(res?.data?.count ?? 0);
      }
    });
    APISERVICES.getMentorCount().then((res) => {
      setCountLoading(false);
      if (res?.status === 200) {
        setMentorCount(res?.data?.count ?? 0);
      }
    });
    APISERVICES.getMenteesCount().then((res) => {
      setCountLoading(false);
      if (res?.status === 200) {
        setMenteesCount(res?.data?.count ?? 0);
      }
    });
  };

  return (
    <>
      <div className="nws-side-card p-2" id="news-slidebar">
        <div className="feed-client d-flex mb-4 align-items-center justify-content-between mb-1">
          <div className="d-flex align-items-center">
            <span className="feed-profile me-2">
              <img
                src={
                  userDetail?.avtar
                    ? process.env.REACT_APP_API_DOMAIN + userDetail?.avtar
                    : require('../../../assets/images/default-male-avatar.png')
                }
                alt="profile"
              />
            </span>
            <Link to={`/user/timeline/${userDetail?.encrypted_id}`}>
              <h4>
                {
                userDetail?.first_name && truncate(
                  userDetail?.name
                    ? userDetail?.name
                    : userDetail?.first_name + ' ' + userDetail?.last_name,
                  15
                ) }
              </h4>
            </Link>
          </div>
          <div className="d-block d-lg-none">
            <Link
              to="#"
              onClick={() => {
                document.getElementById('news-slidebar').classList.toggle('sidebar_closed');
              }}
            >
              <i className="fas fa-bars org-color"></i>
            </Link>
          </div>
        </div>
        {/* <div className="inr-side-slider mb-3">
          <Swiper
            spaceBetween={30}
            slidesPerView={1}
            navigation={true}
            modules={[Navigation]}
            breakpoints={{
              320: {
                slidesPerView: 1
              },
              576: {
                slidesPerView: 1
              },
              768: {
                slidesPerView: 1
              },
              992: {
                slidesPerView: 1
              },
              1200: {
                slidesPerView: 1
              }
            }}>
            <SwiperSlide>
              <Link className="slide-inst-lnk" to="#">
                <img src={require('../../../assets/images/toss-logo.png')} alt="institute" />
              </Link>
            </SwiperSlide>
            <SwiperSlide>
              <Link className="slide-inst-lnk" to="#">
                <img src={require('../../../assets/images/chd-logo.png')} alt="institute" />
              </Link>
            </SwiperSlide>
            <SwiperSlide>
              <Link className="slide-inst-lnk" to="#">
                <img src={require('../../../assets/images/toss-logo.png')} alt="institute" />
              </Link>
            </SwiperSlide>
            <SwiperSlide>
              <Link className="slide-inst-lnk" to="#">
                <img src={require('../../../assets/images/chd-logo.png')} alt="institute" />
              </Link>
            </SwiperSlide>
            <SwiperSlide>
              <Link className="slide-inst-lnk" to="#">
                <img src={require('../../../assets/images/toss-logo.png')} alt="institute" />
              </Link>
            </SwiperSlide>
          </Swiper>
        </div> */}
        <div className="feed-lft-lnks d-none d-lg-block">
          <Accordion defaultActiveKey={state}>
            <Accordion.Item className="mb-3" eventKey="0">
              <Accordion.Header> Professional</Accordion.Header>
              <Accordion.Body>
                <ul className="feed-lnks-lst">
                  <li>
                    <Link className={`${current === 'timeline' ? 'active' : ''}`} to="/jobs">
                      <index.Timeline /> Timeline
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/jobs/search/internship"
                      className={`${current === 'internships' ? 'active' : ''}`}
                    >
                      <index.Cap />
                      Internships
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/jobs/search/freshers"
                      className={`${current === 'freshers' ? 'active' : ''}`}
                    >
                      <index.JobsFreshers />
                      Jobs for Freshers
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/jobs/search/network"
                      className={`${current === 'network' ? 'active' : ''}`}
                    >
                      <index.JobsNetwork />
                      Jobs in your network
                    </Link>
                  </li>
                  <li>
                    <Link to="/jobs/add" className={`${current === 'post-job' ? 'active' : ''}`}>
                      <index.PostJob />
                      Post Job
                    </Link>
                  </li>
                  <li>
                    <Link to="/jobs/search" className={`${current === 'search' ? 'active' : ''}`}>
                      <index.JobSearch />
                      Search Jobs
                    </Link>
                  </li>
                  <li>
                    <Link to="/user/jobsmap" className={`${current === 'map' ? 'active' : ''}`}>
                      <index.Maps />
                      Jobs Map
                    </Link>
                  </li>
                  <li>
                    <Link to="/jobs/posted" className={`${current === 'posted' ? 'active' : ''}`}>
                      <index.PostedJob />
                      Posted Jobs{' '}
                    </Link>
                  </li>
                  <li>
                    <Link to="/jobs/applied" className={`${current === 'applied' ? 'active' : ''}`}>
                      <index.Star />
                      Applied Jobs
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/jobs/referrals"
                      className={`${current === 'referrals' ? 'active' : ''}`}
                    >
                      <index.Refarrow />
                      Referrals
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/jobs/recommends"
                      className={`${current === 'recommend' ? 'active' : ''}`}
                    >
                      <index.Recommends />
                      Recommends
                    </Link>
                  </li>
                  <li>
                    <Link to="/jobs/fair">
                      <index.Cap />
                      Jobfairs
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/jobs/jobfair"
                      className={`${current === 'job-fair-hall-tickets' ? 'active' : ''}`}
                    >
                      <index.Cap />
                      Jobfair Hall Tickets
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/jobs/companies"
                      className={`${current === 'companies' ? 'active' : ''}`}
                    >
                      <index.Companies />
                      Companies
                    </Link>
                  </li>
                </ul>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item className="mb-3" eventKey="1">
              <Accordion.Header>Social </Accordion.Header>
              <Accordion.Body>
                <ul className="feed-lnks-lst">
                  <li>
                    <Link to="/user" className={`${current === 'social' ? 'active' : ''}`}>
                      <index.Timeline />
                      Timeline
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/user/response-poll"
                      className={`${current === 'polls' ? 'active' : ''}`}
                    >
                      <i className="fas fa-poll me-2"></i>
                      Polls
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/user/profile"
                      className={`${current === 'my-account' ? 'active' : ''}`}
                    >
                      <index.MyAccount />
                      My Account
                    </Link>
                  </li>
                  <li>
                    <Link to="/user/alumnimap">
                      <index.Maps />
                      Alumni Map
                    </Link>
                  </li>
                  <li>
                    <Link to="/user/events">
                      <index.Event />
                      Events
                    </Link>
                  </li>
                  <li>
                    <Link
                      className={`${current === 'birthday' ? 'active' : ''}`}
                      to="/user/birthdays"
                    >
                      <index.Birthdays />
                      Birthdays
                    </Link>
                  </li>
                  <li>
                    <Link className={`${current === 'album' ? 'active' : ''}`} to="/user/albums">
                      <index.Photos />
                      Photos
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/user/messages"
                      className={`${current === 'messages' ? 'active' : ''}`}
                    >
                      <index.Messages />
                      Messages
                    </Link>
                  </li>
                  <li>
                    <Link to="/user/clubs">
                      <index.CampusNews />
                      Clubs
                    </Link>
                  </li>
                  <li>
                    <Link to="/user/chapters">
                      <index.CampusNews />
                      Chapters
                    </Link>
                  </li>
                  <li>
                    <Link to="/user/groups">
                      <index.Groups />
                      Groups
                    </Link>
                  </li>

                  <li>
                    <Link to="/user/pages">
                      <index.Pages />
                      Pages
                    </Link>
                  </li>
                  <li>
                    <Link
                      className={`${current === 'campus news' ? 'active' : ''}`}
                      to="/user/campus/news_listing"
                    >
                      <index.CampusNews />
                      Campus News
                    </Link>
                  </li>
                  <li>
                    {' '}
                    <Link
                      className={`${current === 'add-news' ? 'active' : ''}`}
                      to="/user/campus/add_news"
                    >
                      <index.CampusNews />
                      Add Campus News
                    </Link>
                  </li>
                  <li>
                    <Link to="/user/writeArticle">
                      <index.WriteArticle />
                      Write an Article
                    </Link>
                  </li>
                  <li>
                    <Link
                      className={`${current === 'search' ? 'active' : ''}`}
                      to="/user/search-page"
                    >
                      <index.Search />
                      Search
                    </Link>
                  </li>
                  <li>
                    <Link to="/user/find-friend" target="_blankMentorArticleDetails">
                      <index.Search />
                      Find Friends
                    </Link>
                  </li>
                </ul>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item className="mb-3" eventKey="2">
              <Accordion.Header> Mentorship</Accordion.Header>
              <Accordion.Body>
                <ul className="feed-lnks-lst">
                  <li>
                    <Link
                      target="_blank"
                      to="/mentors/directory"
                      className={`${current === 'mentors' ? 'active' : ''}`}
                    >
                      <index.FindMentor />
                      Find Mentors
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={'/mentors/profile/' + userDetail?.encrypted_id}
                      className={`${current === 'profile' ? 'active' : ''}`}
                    >
                      <index.MyAccount />
                      My Profile
                    </Link>
                  </li>
                  <li>
                    <Link to="/mentors/qafeeds" className={`${current === 'feed' ? 'active' : ''}`}>
                      <index.MyAccount />
                      Feed
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="#"
                      className={`${current === 'answer' ? 'active' : ''}`}
                      onClick={() => setShowAnswer(true)}
                    >
                      <index.Answers />
                      Answer
                    </Link>
                    {showAnswer && (
                      <ul className="feed-lnks-inr">
                        <li>
                          <Link
                            to="/user/answers"
                            onClick={() => setShowAnswer(false)}
                            className={`${current === 'answers' ? 'active' : ''}`}
                          >
                            <index.Answers />
                            Answers Requests
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/user/questionsforyou"
                            onClick={() => setShowAnswer(false)}
                            className={`${current === 'question-you' ? 'active' : ''}`}
                          >
                            <index.Answers />
                            Questions For you
                          </Link>
                        </li>
                      </ul>
                    )}
                  </li>
                  <li>
                    <Link
                      to="/user/askquestion"
                      className={`${current === 'askquestion' ? 'active' : ''}`}
                    >
                      <index.Question1 />
                      Ask Question
                    </Link>
                  </li>
                  <li>
                    <Link to="/user/qa" className={`${current === 'my-question' ? 'active' : ''}`}>
                      <index.Information />
                      My Questions
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/user/myAnswers"
                      className={`${current === 'myAnswers' ? 'active' : ''}`}
                    >
                      <index.Information />
                      My Answers
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/user/myArticles"
                      className={`${current === 'my-articles' ? 'active' : ''}`}
                    >
                      <index.MyArticle />
                      My Articles
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/user/writeArticle"
                      className={`${current === 'writeArticle' ? 'active' : ''}`}
                    >
                      <index.WriteArticle />
                      Write an Article
                    </Link>
                  </li>
                </ul>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item className="mb-3" eventKey="4">
              <Accordion.Header> Connections </Accordion.Header>
              <Accordion.Body>
                <ul className="feed-lnks-lst">
                  <li>
                    <Link className={`${current === 'mentors' ? 'active' : ''}`} to="/user/mentors">
                      <index.FindMentor />
                      Mentors
                      <span className={countLoading ? 'sidebar-spinner' : 'friend-count'}>
                        {countLoading ? <i className="fas fa-spinner fa-spin"></i> : mentorCount}
                      </span>
                    </Link>
                  </li>
                  <li>
                    <Link className={`${current === 'mentees' ? 'active' : ''}`} to="/user/mentees">
                      <index.Mentees />
                      Mentees
                      <span className={countLoading ? 'sidebar-spinner' : 'friend-count'}>
                        {countLoading ? <i className="fas fa-spinner fa-spin"></i> : menteesCount}
                      </span>
                    </Link>
                  </li>
                  <li>
                    <Link className={`${current === 'friend' ? 'active' : ''}`} to="/user/friends">
                      <index.Referrals />
                      Friends
                      <span className={countLoading ? 'sidebar-spinner' : 'friend-count'}>
                        {countLoading ? <i className="fas fa-spinner fa-spin"></i> : friendCount}
                      </span>
                    </Link>
                  </li>
                </ul>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      </div>
    </>
  );
}

export default NewsSidebar;
