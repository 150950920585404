import { useFormik } from 'formik';
import { Link, useNavigate, useParams } from 'react-router-dom';
import * as yup from 'yup';
import dummyUser from '../../../../assets/images/user-dummy-image.jpg';
import { saveStepFive } from '../../../../services/InstituteServices';
import './steps.scss';

function Step5() {
  const { userId } = useParams();
  const navigate = useNavigate();
  const { values, errors, handleSubmit, setFieldValue } = useFormik({
    initialValues: {
      photoID: ''
    },
    validationSchema: yup.object().shape({
      photoID: yup.mixed().test('type', 'Please select jpg, png, jpeg format', function (value) {
        return (
          value &&
          (value.type === 'image/jpg' || value.type === 'image/png' || value.type === 'image/jpeg')
        );
      })
    }),
    onSubmit: async (values) => {
      const paylaod = new FormData();
      paylaod.append('profile_pic', values?.photoID);
      paylaod.append('user_id', userId);
      try {
        const resp = await saveStepFive(paylaod);
        if (resp.status === 200) {
          navigate('/institute-login');
        }
      } catch (error) {}
    }
  });

  return (
    <>
      <section className="steps-area step-one-area pt-5 pb-5">
        <div className="row">
          <div className="col-sm-4 col-lg-3 left-area left-side">
            <div className="about-you">
              <h1 className="mb-0">About You</h1>
              <h6>Basic information</h6>
              <p>Your basic information let others find you more easily.</p>
            </div>
          </div>

          <div className="col-sm-8 col-lg-9 right-area">
            <div className="steps-block">
              <ul className="nav nav-tabs">
                <li>
                  <Link to={'/institute-register/step/1/' + userId}>
                    <span className="step-count">Step 1</span>
                    <span className="step-label">General information</span>
                  </Link>
                </li>
                <li>
                  <Link to={'/institute-register/step/2/' + userId}>
                    <span className="step-count">Step 2</span>
                    <span className="step-label">Career Goals</span>
                  </Link>
                </li>
                <li>
                  <Link to={'/institute-register/step/3/' + userId}>
                    <span className="step-count">Step 3</span>
                    <span className="step-label">Educational Information</span>
                  </Link>
                </li>
                <li>
                  <Link to={'/institute-register/step/4/' + userId}>
                    <span className="step-count">Step 4</span>
                    <span className="step-label">Professional Information</span>
                  </Link>
                </li>
                <li className="current">
                  <span className="step-count">Step 5</span>
                  <span className="step-label">Upload profile picture</span>
                </li>
              </ul>
            </div>
            <div className="data-box registeration-form">
              <form>
                <div className="form-inner clearfix">
                  <h4>Set your profile picture</h4>
                  <div className="upload-box clearfix">
                    <div className="image-box">
                      <img
                        src={
                          values?.photoID && values?.photoID?.type?.includes('image')
                            ? URL.createObjectURL(values?.photoID)
                            : dummyUser
                        }
                        className="img-responsive avatar"
                      />
                    </div>

                    <div className="upload-text">
                      <div className="media d-flex align-items-center">
                        <div className="media-left">
                          <span className="browse-image"></span>
                        </div>
                        &nbsp;
                        <div className="media-body">
                          <label
                            style={{
                              cursor: 'pointer'
                            }}>
                            <span style={{ fontSize: 'larger' }}>Upload a photo</span>
                            <input
                              type="file"
                              id="uploadPhoto"
                              name="photoID"
                              accept="image/*"
                              onChange={(e) => setFieldValue('photoID', e.target.files[0])}
                            />
                            <span className="inner-label">From your computer</span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <p style={{ fontSize: 12 }} className="text-danger">
                    {errors.photoID}
                  </p>
                </div>
                <div className="gray-footer clearfix">
                  <span className="float-start mandatory-label">
                    ( <span className="sign">*</span> ) Mandatory fields
                  </span>
                  <span className="float-end">
                    <button type="button" className="add-btn-grn skip-btn me-2">
                      Skip
                    </button>
                    <button className="add-btn-grn" type="button" onClick={handleSubmit}>
                      Save &amp; Continue
                    </button>
                  </span>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Step5;
