import moment from 'moment';
import { useEffect, useState } from 'react';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { resumeExtension, truncate } from '../../../globals/helper';
import useDocumentTitle from '../../../globals/useDocumentTitle';
import * as APISERVICES from '../../../services/JobServices';
import showNotification from '../../../services/NotificationService';
import * as APISERVICE from '../../../services/UserServices';
import { loadJObLocationOption } from '../../../super-admin/AdminHelper';
import HeaderLogin from '../../commoncomponents/HeaderLogin';
import * as index from '../../commoncomponents/skelton/allskelton';
import ApplyForm from '../common-professional-comp/ApplyForm';
import JobCategoryList from '../common-professional-comp/JobCategoryList';
import JobFilterSalary from '../common-professional-comp/JobFilterSalary';
import RecomendModal from '../common-professional-comp/RecomendModal';
import SocialShare from './SocialShare';
import TopTabbings from './TopTabbings';

function SearchJob() {
  useDocumentTitle('Browse Jobs | AlmaBay');

  const { id, comp_id } = useParams();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const navigate = useNavigate();
  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);
  const location = useLocation();
  const [techSkill, setTechSkill] = useState([]);
  const [techSearch, setTechSearch] = useState('');
  const [jobLocation, setJobLocation] = useState([]);
  const [locationSearch, setLocationSearch] = useState(null);
  const [loadInpMsg, setloadInpMsg] = useState(false);
  const [list, setList] = useState([]);

  const [applyForm, setApplyForm] = useState({
    to: ' ',
    subject: ' ',
    message: ' ',
    resume: ' '
  });
  const searchjob_id = new URLSearchParams(location?.search).get('');
  const [savedResume, setSavedResume] = useState('');
  const [jobId, setJobId] = useState('');
  const [jobTitle, setJobTitle] = useState('');
  const [isChecked, setIsChecked] = useState(false);
  const [resumeSelect, setResumeSelect] = useState(false);
  const [fileSizeValid, setFileSizeValid] = useState(false);
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [skillsFilterId, setSkillsFilterId] = useState('');
  const [companyFilterId, setCompanyFilterId] = useState('');
  const [industryFilterId, setIndustryFilterId] = useState('');
  const [locationFilterId, setLocationFilterId] = useState('');
  const [minCtc, setMinCtc] = useState('');
  const [maxCtc, setMaxCtc] = useState('');
  const [minExp, setMinExp] = useState('');
  const [maxExp, setMaxExp] = useState('');
  const [show3, setShow3] = useState(false);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);
  const [shareModelShow, setShareModelShow] = useState(false);
  const [socialShareData, setSocialShareData] = useState(false);
  const shareModelClose = () => {
    setShareModelShow(false);
  };

  const [inputMsg, setInputMsg] = useState('Please enter atleast 2 characters.');

  useEffect(() => {
    getSearchedFilterData('', false, '');
    if (comp_id) {
      getBrandJob();
    } else {
      getNetworkJob();
    }
    getUserDetail();
  }, []);

  //get job-in-network lists

  const getNetworkJob = (job_type = 'search') => {
    setLoading(true);
    APISERVICES.getSearchedJob(job_type).then((res) => {
      setLoading(false);
      if (res?.status === 200) {
        setList(res?.data?.data);
        setCount(res?.data?.pager);
      }
    });
  };

  const getBrandJob = () => {
    setLoading(true);
    APISERVICES.getBrandJobsList(comp_id).then((res) => {
      setLoading(false);
      if (res?.status === 200) {
        setList(res?.data?.detail?.result);
        setCount(res?.data?.pager);
      }
    });
  };

  const getSearchedFilterData = (cat_id = '', reset = false, istrue) => {
    if (
      cat_id ||
      techSkill?.length != 0 ||
      companyFilterId ||
      industryFilterId ||
      locationFilterId ||
      minExp ||
      maxExp ||
      minCtc ||
      maxCtc
    ) {
      setLoading(true);
      if (reset) {
        APISERVICES.getSearchedJob('search', '', '', '', '', '', '', '', '', '').then((res) => {
          setLoading(false);
          if (res?.status === 200) {
            setList(res?.data?.data);
            setCount(res?.data?.pager);
            if (istrue) {
              navigate(`/jobs/search/category/${cat_id}`, { state: 4 });
            }
          }
        });
      } else {
        APISERVICES.getSearchedJob(
          'search',
          techSkill?.map((data) => data?.label),
          companyFilterId,
          industryFilterId,
          locationFilterId,
          cat_id,
          minExp?.value,
          maxExp?.value,
          minCtc?.id,
          maxCtc?.id
        ).then((res) => {
          setLoading(false);
          if (res?.status === 200) {
            setList(res?.data?.data);
            setCount(res?.data?.pager);
            if (istrue) {
              navigate(`/jobs/search/category/${cat_id}`, { state: 4 });
            }
          }
        });
      }
    }
  };

  const advanceJobSearch = () => {
    if (
      techSkill?.length != 0 ||
      companyFilterId ||
      industryFilterId ||
      locationFilterId ||
      maxCtc ||
      maxExp ||
      minCtc ||
      minExp
    ) {
      setLoading(true);
      let body = {
        skills: techSkill?.map((data) => data?.value)?.toString(),
        companies: companyFilterId,
        industry: industryFilterId,
        // location: locationFilterId,
        jof: comp_id ? 'brand' : 'jobs',
        job_type: comp_id ? comp_id : 'search',
        max_ctc: maxCtc?.id,
        max_exp: maxExp?.value,
        min_ctc: minCtc?.id,
        min_exp: minExp?.value,
        offset: 0,
        type: ''
      };
      APISERVICE.advanceSearchForJob(body).then((res) => {
        setLoading(false);
        if (res?.status === 200) {
          setList(res?.data?.detail);
          setCount('');
        }
      });
    } else {
      showNotification('danger', 'Kindly select at least one filter condition to proceed');
    }
  };

  const getUserDetail = () => {
    APISERVICE.userBasicInfo().then((res) => {
      if (res?.status === 200) {
        setSavedResume(res?.data?.resume);
        setApplyForm({ ...applyForm, ['resume']: res?.data?.resume });
      }
    });
  };

  //on-Click Apply button to open_modal

  const onApplyStateHandler = (data) => {
    setJobId(data?.id);
    setJobTitle(data?.job_title);
    setApplyForm({
      to: data?.user
        ? data?.user?.first_name
          ? data?.user?.first_name + ' ' + data?.user?.last_name
          : data?.user?.username
        : data?.job_company_meta,
      subject: `Alma - Job application for ${data?.job_title}`,
      message: '',
      resume: savedResume
    });
    handleShow2();
  };

  const onChangeHandler = (e, rid = false) => {
    if (rid) {
      setApplyForm({ ...applyForm, ['resume']: savedResume });
    } else {
      const { name, value, type } = e.target;
      if (type == 'file') {
        let file = e.target.files[0];
        let fileSize = file.size / 1024;

        if (fileSize > 500) {
          setResumeSelect(false);
          setFileSizeValid(true);
          alert('Resume file size should be less than 500KB');
        } else {
          setFileSizeValid(false);
          if (resumeExtension.includes(e.target.files[0].type)) {
            setResumeSelect(true);
            setApplyForm({ ...applyForm, [name]: file });
          } else {
            setResumeSelect(false);
            alert(
              'Resume file format is not valid, kindly attach a valid file e.g doc,docx,rtf,odt,pdf'
            );
          }
        }
      } else {
        setApplyForm({ ...applyForm, [name]: value });
      }
    }
  };

  const submitHandler = () => {
    let formData = new FormData();
    for (let i in applyForm) {
      formData.append(`${i}`, applyForm[i]);
    }
    APISERVICES.applyForJob(jobId, formData).then((res) => {
      if (res?.status === 200) {
        handleClose2();
        getNetworkJob();
        showNotification('success', res?.data?.message);
      }
    });
  };

  const onRecommendClick = (e, id) => {
    e.preventDefault();
    setJobId(id);
    handleShow3();
  };

  const loadTechOptions = async (inputValue) => {
    if (inputValue.length >= 2) {
      setloadInpMsg(true);
      return APISERVICE.getTechnicalSkillDrop(inputValue).then((res) => {
        setloadInpMsg(false);
        setInputMsg('searching...');
        if (res?.status === 200) {
          let data = res?.data?.data;
          let arr = [];
          data?.map((data) => {
            arr.push({ label: data.name, value: data.id });
          });
          if (res?.data?.data == '') {
            setInputMsg('no matches found');
          }
          return arr;
        }
        if (!res) {
          setInputMsg('no matches found');
        }
      });
    }
  };

  return (
    <>
      <HeaderLogin />
      <div className="header-lnk-feeds">
        <div className="container">
          <TopTabbings state={'feed'} />
        </div>
      </div>
      <section className="feed-sec bg-grey py-4">
        <div className="container">
          <Row>
            <Col xs={12} lg={3} xl={3}>
              {/* search-filter */}
              <JobFilterSalary
                setSkillsFilterId={setSkillsFilterId}
                setCompanyFilterId={setCompanyFilterId}
                setIndustryFilterId={setIndustryFilterId}
                setLocationFilterId={setLocationFilterId}
                apicall={advanceJobSearch}
                resetCall={getSearchedFilterData}
                setMinCtc={setMinCtc}
                setMaxCtc={setMaxCtc}
                setMinExp={setMinExp}
                setMaxExp={setMaxExp}
                job_type={comp_id ? 'brand' : ''}
                minExp={minExp}
                maxExp={maxExp}
                minCtc={minCtc}
                maxCtc={maxCtc}
                comp_id={comp_id}
                techSkill={techSkill}
                locationFilterId={locationFilterId}
                setTechSkill={setTechSkill}
                techSearch={techSearch}
                setTechSearch={setTechSearch}
                loadTechOptions={loadTechOptions}
                loadJObLocationOption={loadJObLocationOption}
                jobLocation={jobLocation}
                setJobLocation={setJobLocation}
                locationSearch={locationSearch}
                setLocationSearch={setLocationSearch}
                inputMsg={inputMsg}
                setInputMsg={setInputMsg}
                loadInpMsg={loadInpMsg}
              />
              {/* end */}
            </Col>

            <Col xs={12} lg={9} xl={6} className="mb-4">
              <div className="job-recomm-card bg-white">
                {searchjob_id ? <h4> Filtered Jobs Results</h4> : <h4>Search Jobs </h4>}
                {list?.length > 0 && (
                  <h6 className="intern-count-hd pt-3 px-3">
                    Latest <b>{list?.length || 0} jobs </b> for you.
                  </h6>
                )}
                <div className="job-recommd-lst p-3">
                  {list && list?.length > 0 ? (
                    list?.map((data, index) => (
                      <div key={index} className="job-recomm-card-inr network-job-card">
                        <div className="top-time-line mb-3 d-flex align-items-center justify-content-between">
                          <p title={moment(data?.created_on).format('YYYY-MM-DD HH:MM:SS')}>
                            <i className="far fa-clock me-1"></i> Added on: {data?.date_time}
                          </p>
                          {comp_id ? (
                            ''
                          ) : (
                            <>
                              {location?.pathname != '/jobs/search' && (
                                <p>
                                  Posted By:<Link to="#"> {data?.user?.first_name}</Link>
                                </p>
                              )}
                            </>
                          )}
                        </div>
                        <div className="job-recomm-dis">
                          <h4>
                            <Link to={`/jobs/jobs-details/${data?.id}`}>{data?.job_title}</Link>
                          </h4>
                          <p>{data?.company_name || data?.companyName}</p>
                          {data?.job_keyword_meta?.length || data?.jobKeys?.length ? (
                            <p>
                              <strong>Keyskills:</strong>{' '}
                              {truncate(
                                data?.job_keyword_meta?.split('|')?.join() ||
                                  data?.jobKeys?.map((data) => data?.name).join(),
                                50
                              )}
                            </p>
                          ) : (
                            ''
                          )}
                          <ul className="job-recomm-addrs">
                            {data?.city_name ? (
                              <li>
                                <i title="Location" className="fas fa-map-marker-alt"></i>
                                {data?.city_name}
                              </li>
                            ) : (
                              ''
                            )}

                            <li>
                              <i title="Positions open" className="fas fa-users"></i>Positions:{' '}
                              {data?.open_positions}
                            </li>
                            {data?.max_exp > 0 && data?.max_exp ? (
                              <li>
                                <i title="Experience Required" className="fas fa-briefcase"></i>Exp
                                :{data.min_exp + '-' + data.max_exp + `(yrs)`}
                              </li>
                            ) : (
                              ''
                            )}
                            {data?.max_ctc > 0 && data?.max_ctc ? (
                              <li>
                                <i title="CTC" className="fas fa-rupee-sign"></i>CTC :
                                {data?.max_ctc
                                  ? data?.min_ctc + '-' + data?.max_ctc + `(Lacs)`
                                  : ''}
                              </li>
                            ) : (
                              ''
                            )}
                          </ul>
                          <div>
                            <div
                              dangerouslySetInnerHTML={{ __html: truncate(data?.job_info, 200) }}
                            />
                          </div>
                          <Link className="view-detail-btn" to={`/jobs/jobs-details/${data?.id}`}>
                            View Details
                          </Link>
                        </div>
                        <div className="job-recommd-ftr d-flex align-items-center justify-content-between">
                          <div>
                            <ul>
                              {data?.self_added ? (
                                ''
                              ) : (
                                <li>
                                  {!data?.is_applied ? (
                                    <Link
                                      className="apply-btn"
                                      onClick={() => {
                                        onApplyStateHandler(data);
                                      }}>
                                      <i className="fas fa-check me-1"></i>Apply
                                    </Link>
                                  ) : (
                                    <Link className="btn applied-btn">
                                      <i className="fas fa-check me-1"></i>Already Applied
                                    </Link>
                                  )}
                                </li>
                              )}
                              <li>
                                <Link
                                  to="#"
                                  onClick={(e) => {
                                    onRecommendClick(e, data?.id);
                                  }}>
                                  <i className="fas fa-thumbs-up me-1"></i>Recommend
                                </Link>
                              </li>
                              <li>
                                <Link
                                  onClick={() => {
                                    setShareModelShow(true);
                                    setSocialShareData(data);
                                  }}>
                                  <i className="fas fa-share me-1"></i> Share
                                </Link>
                              </li>
                            </ul>
                          </div>
                          {/* <Link className="referral-btn" to="#">
                            Request for referral
                            {['top'].map((placement) => (
                              <OverlayTrigger
                                key={placement}
                                placement={placement}
                                overlay={
                                  <Tooltip id={`tooltip-${placement}`}>
                                    Direct referral increases the chances of getting a job.
                                  </Tooltip>
                                }
                              >
                                <i className="fas fa-question-circle ms-2"></i>
                              </OverlayTrigger>
                            ))}
                          </Link> */}
                        </div>
                      </div>
                    ))
                  ) : (
                    <>
                      {list?.length < 0 && !loading && (
                        <div className="upcoming_events p-3">
                          <div className="text-center">
                            <img
                              src={require('../../../assets/images/small-error.png')}
                              alt="img"
                            />
                          </div>
                          <p className="text-center">Data not found.</p>
                        </div>
                      )}
                    </>
                  )}
                </div>
                {loading && (
                  <>
                    {Array.from(Array(3), () => {
                      return <index.SearchjobSkelton />;
                    })}
                  </>
                )}
              </div>
            </Col>

            <Col xl={{ span: 3, offset: 0 }} lg={{ span: 9, offset: 3 }}>
              <JobCategoryList callApi={getSearchedFilterData} state="4" />
            </Col>
          </Row>
        </div>
        <Modal show={shareModelShow} onHide={shareModelClose}>
          <Modal.Header closeButton>
            <Modal.Title>share to</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <SocialShare
              facebookOptions={{
                url: process.env.REACT_APP_DOMAIN + 'job/jobs-details' + '/' + socialShareData.id,
                quote: socialShareData?.job_title
              }}
              twitterOptions={{
                url: process.env.REACT_APP_DOMAIN + 'job/jobs-details' + '/' + socialShareData.id,
                title: socialShareData?.job_title,
                hashtags: ['jobs', 'trends']
              }}
              linkedInOptions={{
                title: socialShareData?.job_title,
                summary: socialShareData?.job_info,
                url: process.env.REACT_APP_DOMAIN + 'job/jobs-details' + '/' + socialShareData.id,
                source: process.env.REACT_APP_DOMAIN + 'job/jobs-details' + '/' + socialShareData.id
              }}
            />
          </Modal.Body>
        </Modal>
      </section>
      {/* recommended job modal */}
      <RecomendModal handleClose3={handleClose3} show3={show3} jobId={jobId} />
      {/* end of modal */}
      {/* <FooterMain className={'colour-bg'} /> */}
      {/* apply-job form */}
      <ApplyForm
        onChangeHandler={onChangeHandler}
        setIsChecked={setIsChecked}
        isChecked={isChecked}
        submitHandler={submitHandler}
        applyForm={applyForm}
        show2={show2}
        handleClose2={handleClose2}
        jobTitle={jobTitle}
        resumeSelect={resumeSelect}
        fileSizeValid={fileSizeValid}
        getUserDetail={getUserDetail}
      />
      {/* end */}
      {/* {loading ? <Loader /> : ''} */}
    </>
  );
}

export default SearchJob;
