import moment from 'moment';
import { useEffect, useState } from 'react';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import Loader from '../../components/commoncomponents/loader/loader-large';
import { communicationConstants } from '../../globals/constants';
import useDocumentTitle from '../../globals/useDocumentTitle';
import {
  assignPlan,
  communicationList,
  singleCommunicationPlan
} from '../../services/AuthServices';
import showNotification from '../../services/NotificationService';

const AddCommunicationPlanForInstitute = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { state } = location;
  const [planLists, setPlanLists] = useState([]);
  const [planDetails, setPlanDetails] = useState({
    planName: '',
    planDuration: '',
    expiryDate: '',
    planID: '',
    typeID: ''
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getPlanList();
  }, []);

  const getPlanList = async () => {
    try {
      setLoading(true);
      const response = await communicationList();
      if (response?.status === 200) {
        setLoading(false);
        setPlanLists(response.data.data ?? []);
      }
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  const handleChange = async (value) => {
    if (value !== '') {
      try {
        setLoading(true);
        const resp = await singleCommunicationPlan(value);
        let date = new Date();
        if (resp.status === 200) {
          setLoading(false);
          if (resp.data?.detail?.plan_duration == 1) {
            date.setMonth(date.getMonth() + 1);
            setPlanDetails({
              ...planDetails,
              expiryDate: date + 1,
              planDuration: resp.data?.detail?.plan_duration,
              planName: resp.data?.detail?.plan_name,
              planID: resp.data?.detail?.id,
              typeID: resp.data?.detail?.type_id
            });
          } else {
            date.setFullYear(date.getFullYear() + 1);
            setPlanDetails({
              ...planDetails,
              expiryDate: date + 1,
              planDuration: resp.data?.detail?.plan_duration,
              planName: resp.data?.detail?.plan_name,
              planID: resp.data?.detail?.id,
              typeID: resp.data?.detail?.type_id
            });
          }
        }
      } catch (error) {
        setLoading(false);
        console.error(error);
      }
    } else {
      setPlanDetails({
        ...planDetails,
        expiryDate: '',
        planDuration: '',
        planName: '',
        planID: ''
      });
    }
  };

  const addPlan = async (e) => {
    e.preventDefault();
    try {
      let body = {
        type_id: planDetails?.typeID,
        plan_id: planDetails.planID,
        expiry_date: moment(planDetails.expiryDate).format('YYYY-MM-DD hh:mm:ss'),
        assigned_date: moment(new Date()).format('YYYY-MM-DD hh:mm:ss')
      };
      const response = await assignPlan(state, body);
      if (response?.status === 200) {
        showNotification('success', response.data?.message);
        navigate('/admin/institute/communication-plan-for-institute', { state: state });
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <div className="dash-main-content">
        <Container fluid>
          <div className="dash-page-content">
            <div className="dash-page-title">
              <Row>
                <Col xs={12} sm={12} md={12}>
                  <div className="dash-title-bar d-flex align-items-center justify-content-between">
                    <div className="title-lft d-flex align-items-center">
                      <div className="icons_head">
                        <i className="fas fa-bars"></i>
                      </div>
                      {useDocumentTitle('Add Communication Plan | AlmaBay')}
                      <h5 className="ms-3">Add Communication Plan</h5>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="dash-content-card">
              <div className="cmmn-form">
                <Form>
                  <Row>
                    <Col xs={12} md={6} lg={6}>
                      <Form.Group className="mb-3" controlId="formBasicPassword">
                        <Form.Label>
                          Plan<span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Select
                          onChange={(e) => {
                            handleChange(e.target.value);
                          }}
                          aria-label="Default select example"
                          name="status"
                        >
                          <option value={''}>Select Plan</option>
                          {planLists?.length > 0 &&
                            planLists?.map((item, index) => {
                              return (
                                <option key={index} value={item?.id}>
                                  {item?.plan_name}
                                </option>
                              );
                            })}
                        </Form.Select>
                      </Form.Group>
                    </Col>
                    <Col xs={12} md={6} lg={6}>
                      <Form.Group className="mb-3" controlId="formBasicPassword">
                        <Form.Label>
                          Plan Duration <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Select
                          value={planDetails.planDuration}
                          disabled
                          aria-label="Default select example"
                          name="status"
                        >
                          <option value="">Select Plan Duration</option>
                          <option value={communicationConstants.MONTHLY}>Monthly</option>
                          <option value={communicationConstants.YEARLY}>Yearly</option>
                        </Form.Select>
                      </Form.Group>
                    </Col>
                    <Col xs={12} md={6} lg={6}>
                      <Form.Group className="mb-3" controlId="formBasicPassword">
                        <Form.Label>
                          Expiry Date <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          disabled
                          type="text"
                          placeholder="Expiry Date"
                          name="name"
                          autoComplete="Off"
                          value={
                            planDetails.expiryDate
                              ? moment(planDetails.expiryDate).format('LL')
                              : ''
                          }
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Button
                    onClick={(e) => {
                      addPlan(e);
                    }}
                    className="main-btn-grn"
                  >
                    Add
                  </Button>
                </Form>
              </div>
            </div>
          </div>
        </Container>
      </div>
      {loading && <Loader />}
    </>
  );
};

export default AddCommunicationPlanForInstitute;
