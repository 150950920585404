import { useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Container } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { Button } from 'react-bootstrap';
import Header from '../../CommonComponents/Header';
import SideBar from '../../CommonComponents/Sidebar';
import Footer from '../../CommonComponents/Footer';
import { addOrderStatus } from '../../../services/AuthServices';
import showNotification from '../../../services/NotificationService';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { ShowAlert } from '../../../redux/action';

export default function OrderCreate() {
  const location = useLocation();
  let editData = location?.state;
  const [orderStatus, setOrderStatus] = useState({
    statusName: editData ? editData?.order_full : '',
    slug: editData ? editData?.order_short : ''
  });
  const [clicked, setClicked] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleChange = (e) => {
    const { name, value } = e.target;
    setOrderStatus({ ...orderStatus, [name]: value });
  };

  const validate = () => {
    let errors = {};
    if (orderStatus?.statusName == '') {
      errors.status = 'This field is required.';
    }
    if (orderStatus?.slug == '') {
      errors.slug = 'This field is required.';
    }
    return errors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setClicked(true);

    if (!Object.keys(validate()).length) {
      let formData = new FormData();
      formData.append(`order_full`, orderStatus?.statusName);
      formData.append(`order_short`, orderStatus?.slug);
      if (editData) {
        formData.append(`id`, editData?.id);
      }

      addOrderStatus(formData).then((res) => {
        if (res?.status === 200) {
          showNotification('success', res?.data?.message);
          navigate('/admin/orders/status');
          dispatch(ShowAlert());
        }
      });
    }
  };

  return (
    <div className="dash-main-content">
      <Container fluid>
        <div className="dash-page-content">
          <div className="dash-page-title">
            <Row>
              <Col xs={12} sm={12} md={12}>
                <div className="dash-title-bar d-flex align-items-center justify-content-between">
                  <div className="title-lft d-flex align-items-center">
                    <div className="dash-title-dis">
                      <h5>Add Order Status</h5>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <div className="dash-content-card">
            <div className="cmmn-form">
              <Form>
                <Row>
                  <Col xs={12} sm={12} md={12}>
                    <Form.Group className="mb-3" controlId="orderstatus">
                      <Form.Label>
                        Status Name<span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter the order status name. Eg: Pending"
                        name="statusName"
                        onChange={handleChange}
                        value={orderStatus?.statusName}
                      />
                      <div className={'danger-frm'}>{clicked && validate().status}</div>
                    </Form.Group>
                  </Col>

                  <Col xs={12} sm={12} md={12}>
                    <Form.Group className="mb-3" controlId="slugurl">
                      <Form.Label>
                        Slug<span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter the unique order slug"
                        name="slug"
                        // onKeyDown={(e) => restrictAlpha(e)}
                        onChange={handleChange}
                        value={orderStatus?.slug}
                      />
                      <div className={'danger-frm'}>{clicked && validate().slug}</div>
                    </Form.Group>
                  </Col>
                </Row>

                <div className="form-btns d-flex align-items-center mt-4">
                  <Button className="main-btn-grn" type="button" onClick={handleSubmit}>
                    Save
                  </Button>
                  <strong>OR</strong>
                  <Button
                    className="main-btn-red"
                    onClick={() => navigate('/admin/orders/status')}
                  >
                    Cancel
                  </Button>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}
