/**
@copyright  :   ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
@author     :   Shiv Charan Panjeta 
 
All Rights Reserved.
Proprietary and confidential :  All information contained here in is, and remains
the property of ToXSL Technologies Pvt. Ltd. and it's partners.
Unauthorized copying of this file, via any medium is strictly prohibited.
*/
import { useEffect } from 'react';
import { useState } from 'react';
import { Row, Button } from 'react-bootstrap';
import { Col } from 'react-bootstrap';
import { Form } from 'react-bootstrap';
import { Table } from 'react-bootstrap';
import { Alert } from 'react-bootstrap';
import { Container } from 'react-bootstrap';
import ReactPaginate from 'react-paginate';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { ZERO } from '../../../globals/constants';
import { getProductImages, productImageAction } from '../../../services/AuthServices';
import Footer from '../../CommonComponents/Footer';
import Header from '../../CommonComponents/Header';
import SideBar from '../../CommonComponents/Sidebar';
import useDocumentTitle from '../../../globals/useDocumentTitle';

function ShowImages() {
  useDocumentTitle('Manage Product Alternate Images | AlmaBay');
  const navigate = useNavigate();
  const location = useLocation();
  const id = location?.state;
  const [proImages, setProImages] = useState([]);
  const [orderBy, setOrderBy] = useState('DESC');
  const [shortOrderBy, setShortOrderBy] = useState('id');
  const [currentPage, setCurrentPage] = useState(1);
  const [alrertMsg, setAltertMsg] = useState('');
  const [total, setTotal] = useState('');
  const [showalert, setShowAlert] = useState(false);

  useEffect(() => {
    if (id) {
      getImages(id);
    }
  }, []);

  useEffect(() => {
    const timeId = setTimeout(() => {
      // After 3 seconds set the show value to false
      setShowAlert(false);
    }, 3000);

    return () => {
      clearTimeout(timeId);
    };
  }, [showalert]);

  const getImages = (id, page, type, sort) => {
    getProductImages(
      id,
      page ? page : currentPage,
      type ? type : shortOrderBy,
      sort ? sort : orderBy
    ).then((res) => {
      if (res?.status === 200) {
        const data = res?.data?.data?.viewlist;
        setTotal(res?.data?.data?.total_rows);
        if (data !== null) {
          setProImages(data ?? []);
        } else {
          setProImages([]);
        }
      }
    });
  };
  const sortHandler = (e, type) => {
    e.preventDefault();
    let currentSort = orderBy === 'ASC' ? 'DESC' : 'ASC';
    setOrderBy(currentSort);
    setShortOrderBy(type);
    getImages(id, currentPage, type, currentSort);
  };
  const handlePageClick = (a) => {
    let page = a?.selected + 1;
    setCurrentPage(page);
    getImages(id, page, shortOrderBy, orderBy);
  };

  const selectHandler = (id, value, pId) => {
    switch (value) {
      case 'edit':
        navigate(`/admin/shops/product/add-image`, { state: { id: id, productId: pId } });
        break;
      case 'enable':
        handleStatusUpdate(id, value);
        break;
      case 'disable':
        handleStatusUpdate(id, value);
        break;
      case 'del':
        handleStatusUpdate(id, value);
        break;
      default:
        break;
    }
  };

  const handleStatusUpdate = (imgid, value) => {
    productImageAction(imgid, value).then((res) => {
      if (res?.status === 200) {
        setShowAlert(true);
        setAltertMsg(res?.data?.message);
        getImages(id, currentPage, shortOrderBy, orderBy);
      }
    });
  };
  return (
    <div className="dash-main-content">
      <Container fluid>
        <div className="dash-page-content">
          <div className="dash-page-title">
            <Row>
              <Col xs={12} sm={12} md={12}>
                <div className="dash-title-bar d-flex align-items-center justify-content-between">
                  <div className="title-lft d-flex align-items-center">
                    <div className="dash-icn">
                      <i className="fa fa-file"></i>
                    </div>
                    <div className="dash-title-dis ">
                      <h5>
                        Product Alternate<span> Images</span>
                      </h5>
                    </div>
                  </div>
                  <Link to="/admin/products">
                    <strong>Back to Product Listing</strong>
                  </Link>
                </div>
              </Col>
            </Row>
          </div>
          <div className="dash-content-card">
            <Button
              className="main-btn-grn"
              type="button"
              onClick={() => navigate('/admin/shops/product/add-image', { state: { id: id } })}>
              Add Image
            </Button>
            <Alert variant={'success'} className={`${showalert ? 'mt-2' : 'd-none'}`}>
              {alrertMsg}
            </Alert>
            <div className="view_detials_content mt-3">
              {' '}
              <div className="table-responsive">
                <Table className="custom-table-data" striped bordered hover>
                  <thead>
                    <tr>
                      <th>
                        <Link
                          to=""
                          onClick={(e) => {
                            sortHandler(e, 'id');
                          }}>
                          Id
                          <img src={require('../../../assets/images/sort.png')} alt="sort" />
                        </Link>
                      </th>
                      <th>Colour</th>
                      <th>Cover Image</th>

                      <th>
                        <Link
                          to=""
                          onClick={(e) => {
                            sortHandler(e, 'status');
                          }}>
                          Status
                          <img src={require('../../../assets/images/sort.png')} alt="sort" />
                        </Link>
                      </th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {proImages?.length !== ZERO ? (
                      <>
                        {proImages?.map((item, index) => {
                          return (
                            <tr key={index}>
                              <td>{item?.id}</td>
                              <td>{item?.colorName}</td>
                              <td>
                                {' '}
                                <div className="td-inner-img">
                                  <img
                                    src={
                                      item?.image_name
                                        ? process.env.REACT_APP_API_DOMAIN +
                                          `common/uploads/products/${id}/` +
                                          item?.image_name
                                        : require('../../../assets/images/default-male-avatar.png')
                                    }
                                    alt="img"
                                    width="75"
                                    height="75"
                                  />
                                </div>
                              </td>
                              <td>{item?.status == 1 ? 'Enable' : 'Disable'}</td>
                              <td>
                                <Form.Select
                                  aria-label="Default select example"
                                  id="select"
                                  value=""
                                  onChange={(e) => selectHandler(item?.id, e.target.value, id)}>
                                  <option value="">Action</option>
                                  <option value="edit">Edit</option>
                                  <option value={item?.status == 1 ? 'disable' : 'enable'}>
                                    {item?.status == 1 ? 'Disable' : 'Enable'}
                                  </option>
                                  <option value="del">Delete</option>
                                </Form.Select>
                              </td>
                            </tr>
                          );
                        })}
                      </>
                    ) : (
                      <>No data found</>
                    )}
                  </tbody>
                </Table>
              </div>
              {proImages?.length !== 0 && (
                <div className="react-page d-flex">
                  {total / 10 > 1 ? (
                    <ReactPaginate
                      previousLabel={currentPage > 0 ? '<' : ''}
                      nextLabel=">"
                      onPageChange={handlePageClick}
                      pageCount={total / 10}
                    />
                  ) : (
                    ''
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}

export default ShowImages;
