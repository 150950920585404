/**
@copyright : ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
@author    : Shiv Charan Panjeta 
All Rights Reserved.
Proprietary and confidential :  All information contained here in is, and remains
the property of ToXSL Technologies Pvt. Ltd. and it's partners.
Unauthorized copying of this file, via any medium is strictly prohibited.
* 
*/
import { useRef } from 'react';
import { Container } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { Link } from 'react-router-dom';
import { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import useDocumentTitle from '../../../globals/useDocumentTitle';

function CampusBrand() {
  useDocumentTitle(
    'CBA - Campus Brand Ambassador Program | Grow Professionally with your Almamater | AlmaBay'
  );

  const myRef = useRef(null);

  const executeScroll = () => myRef.current?.scrollIntoView({ behavior: 'smooth' });
  return (
    <>
      <section className="campus-brand-banner">
        <Container>
          <div className="campus-brand-inr">
            <div className="campus-brand-logo mb-4">
              <Link to="#">
                <img src={require('../../../assets/images/logo.png')} alt="logo" />
              </Link>
            </div>
            <div className="campus-brand-dis">
              <h3 className="mb-4">Campus Brand Ambassador Program(CBA)</h3>
              <div className="campus-dis-swipe mb-5">
                <Swiper navigation={true} modules={[Navigation]} className="mySwiper">
                  <SwiperSlide>
                    <div className="campus-dis-slide">
                      <p>
                        Becoming Campus Brand Ambassador for Almabay is the most exciting and fun
                        way of discovering the leader in you.
                      </p>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="campus-dis-slide">
                      <p>
                        As Almabay Campus Brand Ambassador (CBA) you get to be part of virtual
                        internships and exciting live projects happening with Almabay.
                      </p>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="campus-dis-slide">
                      <p>
                        CBA’s are invited for lunch meetings with top entrepreneurs and visionary
                        for face to face mentoring and honing their leadership skills.
                      </p>
                    </div>
                  </SwiperSlide>
                </Swiper>
              </div>
              {/* <Link
                activeClass="active"
                to="be-brand"
                spy={true}
                smooth={true}
                className="brand-btn"
              >
                BE A BRAND AMBASSADOR
              </Link> */}
            </div>
          </div>
        </Container>
        <Link
          className="mouse-event"
          activeClass="active"
          to={'#'}
          onClick={(e) => {
            e.preventDefault();
            executeScroll();
          }}>
          <img src={require('../../../assets/images/mouse-down.png')} alt="mouse-down" />
        </Link>
      </section>
      <section id="focus-card" className="focus-card py-5" ref={myRef}>
        <Container>
          <div className="focus-card-inr">
            <Row>
              <Col md={5} xs={12}>
                <div className="focus-lft-dis">
                  <h3>Aspiring For Future Leadership Roles</h3>
                  <p>
                    Are you a role model and leader to your friends? Are you a mentor to your
                    juniors? Do you feel you have the right ingredients to become a future leader?
                    AlmaBay Brand Ambassador Program is the way for you. In case you have it in you
                    and if you get through our grueling recruitment program, we will prepare you for
                    the future leadership roles.
                  </p>
                  <Link
                    activeClass="active"
                    to="be-brand"
                    spy={true}
                    smooth={true}
                    className="brand-btn mt-3">
                    APPLY NOW !
                  </Link>
                </div>
              </Col>
              <Col md={7} xs={12}>
                <div className="focus-rt-img">
                  <img src={require('../../../assets/images/focus-ball.png')} alt="logo" />
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </section>
      <section className="wondering-sec">
        <Container>
          <div className="wondering-inr">
            <div className="wondering-hd">
              <h3>Are you wondering ...</h3>
            </div>
            <div className="wondering-card-dis">
              <Row>
                <Col md={4} sm={4} xs={12}>
                  <div className="wonder-inr-card">
                    <div className="wondr-img-icn">
                      <img src={require('../../../assets/images/wonder-1.png')} alt="wonder" />
                    </div>
                    <div className="wondr-dis-card">
                      <h4>Who makes a good CBA</h4>
                      <ul className="wondr-dis-lst">
                        <li>Someone with strong leadership and team management skills.</li>
                        <li>Someone who can setup groups and encourage membership.</li>
                        <li>Someone who is well networked and respected on campus.</li>
                      </ul>
                    </div>
                  </div>
                </Col>
                <Col md={4} sm={4} xs={12}>
                  <div className="wonder-inr-card">
                    <div className="wondr-img-icn">
                      <img src={require('../../../assets/images/wonder-2.png')} alt="wonder" />
                    </div>
                    <div className="wondr-dis-card">
                      <h4>What will you do as a CBA</h4>
                      <ul className="wondr-dis-lst">
                        <li>Organizing events to help your friends find right career direction.</li>
                        <li>List campus events on the platform.</li>
                        <li>Be Point of contact for college with awesome mentors and leaders.</li>
                        <li>
                          Organizing technical and business workshops along with Fun events at
                          campus
                        </li>
                      </ul>
                    </div>
                  </div>
                </Col>
                <Col md={4} sm={4} xs={12}>
                  <div className="wonder-inr-card">
                    <div className="wondr-img-icn">
                      <img src={require('../../../assets/images/wonder-3.png')} alt="wonder" />
                    </div>
                    <div className="wondr-dis-card">
                      <h4>What do I get out of CBA</h4>
                      <ul className="wondr-dis-lst">
                        <li>
                          Awesome goodie bags from Almabay with cool T-shirts, hoodies, Mugs,
                          diaries supplied throughout the year.
                        </li>
                        <li>Mentoring and Networking with top gurus in different industries.</li>
                        <li>Lunch meeting and PDP programs at our office and top hotels.</li>
                        <li>Sponsorships to attend conferences in India and Singapore.</li>
                        <li>Awards and placement opportunities with Almabay.</li>
                      </ul>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </Container>
      </section>
      <section className="meet-ambasdor">
        <Container>
          <div className="meet-ambasdor-hd">
            <h3>meet our ambassadors</h3>
          </div>
          <div className="meet-ambasdor-inr">
            <Swiper
              navigation={true}
              modules={[Navigation]}
              className="myawsome"
              spaceBetween={50}
              slidesPerView={3}
              loop={true}
              centerMode={true}
              breakpoints={{
                320: {
                  slidesPerView: 1
                },
                576: {
                  slidesPerView: 2
                },
                768: {
                  slidesPerView: 3
                },
                1200: {
                  slidesPerView: 3
                },
                1500: {
                  slidesPerView: 3
                }
              }}>
              <SwiperSlide>
                <div className="ambasdor-dis">
                  <div className="ambasdor-icn">
                    <img src={require('../../../assets/images/amandeep-dav.jpg')} alt="wonder" />
                  </div>
                  <div className="ambasdor-cntnt">
                    <div className="ambasdor-name">
                      <h3>Amandeep Singh Chand</h3>
                      <h4>DAV College, Chandigarh</h4>
                    </div>
                    <p>
                      “ Almabay CBA program helped me find right perspective in my life. I could
                      clearly see my strengths. It gave me a chance to help my friends by putting
                      them up with great internship opportunities.”
                    </p>
                    <div className="college-icn">
                      <img
                        src={require('../../../assets/images/college-logo-2.png')}
                        alt="wonder"
                      />
                    </div>
                  </div>
                </div>
              </SwiperSlide>

              <SwiperSlide>
                <div className="ambasdor-dis">
                  <div className="ambasdor-icn">
                    <img src={require('../../../assets/images/ankit-mmu.jpg')} alt="wonder" />
                  </div>
                  <div className="ambasdor-cntnt">
                    <div className="ambasdor-name">
                      <h3>Ankit Bangrey</h3>
                      <h4>M.M.University, Mullana</h4>
                    </div>
                    <p>
                      “ Being Almabay CBA was fun and exciting at the same time. I never ever
                      thought you can learn so much while having so much fun.”
                    </p>
                    <div className="college-icn">
                      <img src={require('../../../assets/images/mmu.png')} alt="wonder" />
                    </div>
                  </div>
                </div>
              </SwiperSlide>

              <SwiperSlide>
                <div className="ambasdor-dis">
                  <div className="ambasdor-icn">
                    <img src={require('../../../assets/images/savleen-kcp.jpg')} alt="wonder" />
                  </div>
                  <div className="ambasdor-cntnt">
                    <div className="ambasdor-name">
                      <h3>Savleen Kaur</h3>
                      <h4>Khalsa College Patiala</h4>
                    </div>
                    <p>
                      “ Almabay CBA Program honed my leadership skills and helped me land my dream
                      job through mentors met on the program.”
                    </p>
                    <div className="college-icn">
                      <img src={require('../../../assets/images/kcp.png')} alt="wonder" />
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
        </Container>
      </section>
      <section className="awsome-feature">
        <Container>
          <div className="awsome-ftr-inr">
            <div className="awsome-ftr-hd">
              <h3>Some awesome features</h3>
              <p>
                Being part of AlmaBay community gives you access to unlimited career and networking
                opportunity. Our geolocation based algorithm helps to find perfect jobs and mentors.
              </p>
            </div>
            <div className="awsome-ftr-slide">
              <Swiper
                navigation={false}
                modules={[Navigation]}
                className="myawsome"
                spaceBetween={30}
                slidesPerView={4}
                loop={true}
                breakpoints={{
                  320: {
                    width: 320,
                    slidesPerView: 1
                  },
                  576: {
                    width: 576,
                    slidesPerView: 2
                  },
                  768: {
                    width: 768,
                    slidesPerView: 3
                  },
                  992: {
                    width: 992,
                    slidesPerView: 4
                  }
                }}>
                <SwiperSlide>
                  <div className="awsome-slide">
                    <img src={require('../../../assets/images/feature-1.png')} alt="feature" />
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="awsome-slide">
                    <img src={require('../../../assets/images/feature-2.png')} alt="feature" />
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="awsome-slide">
                    <img src={require('../../../assets/images/feature-3.png')} alt="feature" />
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="awsome-slide">
                    <img src={require('../../../assets/images/feature-4.png')} alt="feature" />
                  </div>
                </SwiperSlide>
              </Swiper>
            </div>
          </div>
        </Container>
      </section>
      <section className="mission-sec">
        <Container>
          <div className="mission-inr">
            <div className="mission-hd">
              <h3>mission and vision of the program</h3>
            </div>
            <Row>
              <Col md={6} sm={6} xs={12}>
                <div className="mission-dis">
                  <h4>VISION</h4>
                  <p>
                    Providing a Platform for students to sharpen their leadership/entrepreneurial
                    skills through networking & mentorship with top leaders/entrepreneurs.
                  </p>
                </div>
              </Col>
              <Col md={6} sm={6} xs={12}>
                <div className="mission-dis">
                  <h4>MISSION</h4>
                  <p>
                    To connect 1,00,000 institutes across the globe through brand ambassadors by
                    2020.To be the most trustworthy and engaging platform for youth to find dream
                    career and mentors in life.
                  </p>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </section>
      {/* <section id="be-brand" className="get-in-touch">
        <Container>
          <div className="get-touch-hd">
            <h4>GET IN TOUCH WITH US</h4>
            <h3>enter your info to get started</h3>
          </div>
          <Row className="justify-content-center">
            <Col md={4} xs={12}>
              <div className="get-touch-frm">
                <Form>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Control type="text" placeholder="Location" />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Control type="text" placeholder="Phone No" />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Control type="text" placeholder="First Name" />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Control type="text" placeholder="Last Name" />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Control type="text" placeholder="Your University" />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Control type="text" placeholder="Your Email Address" />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Control type="password" placeholder="Choose your AlmaBay password" />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formBasicDegree">
                    <Form.Control type="text" placeholder="What Degree you are Working towards" />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formBasicDegree">
                    <Form.Control
                      as="textarea"
                      type="text"
                      placeholder="Why do you think you will become a great CBA?"
                      style={{ height: '90px' }}
                    />
                  </Form.Group>
                  <Form.Group controlId="formFile" className="mb-3 select-cba-resume">
                    <Form.Control type="file" />
                    <Form.Label>
                      Select Resume
                    </Form.Label>
                  </Form.Group>
                  <Button className="brand-btn">Register as CBA</Button>
                </Form>
                <div className="term-card">
                  <p>
                    By Signing up you agree to our <a href="/terms_of_use" className='cursor-pointer'>terms of use</a>
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section> */}
    </>
  );
}

export default CampusBrand;
