import { Nav } from 'react-bootstrap';
import { ProgressBar } from 'react-bootstrap';
import { Row, Col } from 'react-bootstrap';
import { Tab } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import moment from 'moment/moment';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';

function UserAbout(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const userId = useParams()?.userId;
  const {
    basicData,
    educationData,
    workData,
    techData,
    bussinessData,
    personalData,
    photosData,
    friendsData,
    connectionsList,
    mutualFriends,
    setActiveTab
  } = props;

  const showClassName = (value) => {
    switch (value) {
      case '100':
        return '';
      case '80':
        return 'ng-hide-vgood';
      case '60':
        return 'ng-hide-good';
      case '40':
        return 'ng-hide-fair';
      case '20':
        return 'ng-hide';

      default:
        return '';
    }
  };
  return (
    <>
      {' '}
      <div className="about-cntnt ">
        <div className="bg-white mb-3">
          <Tab.Container id="left-tabs-example" defaultActiveKey="about-1">
            <Row>
              <Col sm={3}>
                <Nav variant="pills" className="flex-column abt-lft-tabs">
                  <Nav.Item>
                    <Nav.Link eventKey="about-1">Basic Information</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="about-2">Education</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="about-3">Professional</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="about-4">Technical Skills</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="about-5">Business Skills</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="about-6">Inter-Personal Skills</Nav.Link>
                  </Nav.Item>
                </Nav>
              </Col>
              <Col sm={9}>
                <Tab.Content>
                  <Tab.Pane eventKey="about-1">
                    <div className="abt-rt-dis">
                      <h4 className="basic-hd">BASIC INFORMATION</h4>
                      <ul className="basic-usr-detail">
                        <li>
                          <i className="fas fa-venus-mars me-2"></i>
                          {basicData?.userInfo?.gender}
                        </li>
                        <li>
                          <i className="far fa-heart me-2"></i>
                          Single
                        </li>
                        <li>
                          <i className="fas fa-building me-2"></i>
                          {basicData?.userInfo?.current_city}
                        </li>
                        <li>
                          <i className="fas fa-envelope-open-text me-2"></i>
                          {basicData?.userInfo?.email}
                        </li>
                        <li>
                          <i className="fas fa-birthday-cake me-2"></i>
                          {moment(basicData?.userInfo?.birthday).format('MMMM d, YYYY')}
                        </li>
                      </ul>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="about-2">
                    <div className="abt-rt-dis">
                      <h4 className="basic-hd">EDUCATION</h4>
                      {educationData?.length != 0 ? (
                        <>
                          {educationData?.map((education, index) => {
                            return (
                              <div
                                className="edu-collz-card d-flex align-items-center mb-4"
                                key={index}
                              >
                                <div className="edu-card-icn me-3">
                                  <img
                                    src={require('../../../../assets/images/default-male-avatar.png')}
                                    alt="img"
                                  />
                                </div>
                                <div className="edu-collz-dis">
                                  <h4>{education?.institute_name}</h4>
                                  <p>{education?.city_name}</p>
                                </div>
                              </div>
                            );
                          })}
                        </>
                      ) : (
                        <>No education added yet</>
                      )}
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="about-3">
                    <div className="abt-rt-dis">
                      <h4 className="basic-hd">PROFESSIONAL</h4>
                      {workData?.length !== 0 ? (
                        <>
                          {workData?.map((work, index) => {
                            return (
                              <div
                                className="edu-collz-card d-flex align-items-center mb-4"
                                key={index}
                              >
                                <div className="edu-card-icn me-3">
                                  <img
                                    src={require('../../../../assets/images/default-male-avatar.png')}
                                    alt="img"
                                  />
                                </div>
                                <div className="edu-collz-dis">
                                  <h4>{work?.organisation}</h4>
                                  <p>
                                    Worked as {work?.designation} From{' '}
                                    {moment(work?.month_from, 'M').format('MMMM')},{work?.year_from}{' '}
                                    To {moment(work?.month_to, 'M').format('MMMM')},{work?.year_to}
                                  </p>
                                </div>
                              </div>
                            );
                          })}
                        </>
                      ) : (
                        <>
                          {' '}
                          <p>No Professional details found at the moment.</p>
                        </>
                      )}
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="about-4">
                    <div className="abt-rt-dis">
                      <h4 className="basic-hd">TECHNICAL SKILLS</h4>
                      {techData?.length !== 0 ? (
                        <>
                          {techData?.map((data, index) => {
                            return (
                              <div className="skill-card mb-2" key={index}>
                                <p>{data?.skill_name ? data?.skill_name : 'Not Available'}</p>
                                <ProgressBar className={showClassName(data?.value)}>
                                  <ProgressBar
                                    striped
                                    className="progress-bar-improvement"
                                    now={data?.value / 5}
                                    key={3}
                                  />
                                  <ProgressBar
                                    striped
                                    className="progress-bar-fair"
                                    now={data?.value / 5}
                                    key={1}
                                  />
                                  <ProgressBar
                                    className="progress-bar-good"
                                    now={data?.value / 5}
                                    key={2}
                                  />
                                  <ProgressBar
                                    className="progress-bar-vgood"
                                    now={data?.value / 5}
                                    key={2}
                                  />
                                  <ProgressBar
                                    className="progress-bar-excellent"
                                    now={data?.value / 5}
                                    key={2}
                                  />
                                </ProgressBar>
                              </div>
                            );
                          })}
                        </>
                      ) : (
                        <>No Technical skills found at the moment.</>
                      )}
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="about-5">
                    <div className="abt-rt-dis">
                      <h4 className="basic-hd">BUSINESS SKILLS</h4>
                      {bussinessData?.length !== 0 ? (
                        <>
                          {bussinessData?.map((data, index) => {
                            return (
                              <div className="skill-card mb-2" key={index}>
                                <p>{data?.skill_name ? data?.skill_name : 'Not Available'}</p>
                                <ProgressBar className={showClassName(data?.value)}>
                                  <ProgressBar
                                    striped
                                    className="progress-bar-improvement"
                                    now={data?.value / 5}
                                    key={3}
                                  />
                                  <ProgressBar
                                    striped
                                    className="progress-bar-fair"
                                    now={data?.value / 5}
                                    key={1}
                                  />
                                  <ProgressBar
                                    className="progress-bar-good"
                                    now={data?.value / 5}
                                    key={2}
                                  />
                                  <ProgressBar
                                    className="progress-bar-vgood"
                                    now={data?.value / 5}
                                    key={2}
                                  />
                                  <ProgressBar
                                    className="progress-bar-excellent"
                                    now={data?.value / 5}
                                    key={2}
                                  />
                                </ProgressBar>
                              </div>
                            );
                          })}
                        </>
                      ) : (
                        <>No Business skills found at the moment.</>
                      )}
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="about-6">
                    <div className="abt-rt-dis">
                      <h4 className="basic-hd">INTER-PERSONAL SKILLS</h4>
                      {personalData?.length !== 0 ? (
                        <>
                          {personalData?.map((data, index) => {
                            return (
                              <div className="skill-card mb-2" key={index}>
                                <p>{data?.skill_name ? data?.skill_name : 'Not Available'}</p>
                                <ProgressBar className={showClassName(data?.value)}>
                                  <ProgressBar
                                    striped
                                    className="progress-bar-improvement"
                                    now={data?.value / 5}
                                    key={3}
                                  />
                                  <ProgressBar
                                    striped
                                    className="progress-bar-fair"
                                    now={data?.value / 5}
                                    key={1}
                                  />
                                  <ProgressBar
                                    className="progress-bar-good"
                                    now={data?.value / 5}
                                    key={2}
                                  />
                                  <ProgressBar
                                    className="progress-bar-vgood"
                                    now={data?.value / 5}
                                    key={2}
                                  />
                                  <ProgressBar
                                    className="progress-bar-excellent"
                                    now={data?.value / 5}
                                    key={2}
                                  />
                                </ProgressBar>
                              </div>
                            );
                          })}
                        </>
                      ) : (
                        <>No Inter-Personal skills found at the moment.</>
                      )}
                    </div>
                  </Tab.Pane>
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        </div>

        <div className="time-line-friends mb-3 ">
          <Tab.Container defaultActiveKey="friends">
            <div className="d-flex align-item-center justify-content-between">
              <Nav variant="pills" className="abt-lft-tabs">
                <Nav.Item>
                  <Nav.Link eventKey="friends">Friends</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="mentors">Mentors</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="mentees">Mentees</Nav.Link>
                </Nav.Item>
              </Nav>
              <Link className="text-orange" to={`/user/friends/${userId}`} target="_blank">
                More
              </Link>
            </div>
            <div className="friends-list ">
              <Tab.Content>
                <Tab.Pane eventKey={`friends`}>
                  {connectionsList?.friends?.users?.length !== 0 ? (
                    <>
                      {connectionsList?.friends?.users?.map((friend, index) => {
                        return (
                          <Link to={`javascript:void(0)`} key={index}>
                            <div className="friend-lst-icn">
                              <img
                                src={
                                  friend
                                    ? friend?.avatar?.complete_url
                                    : require('../../../../assets/images/birth1.jpg')
                                }
                                alt="img"
                              />
                            </div>
                            <p>{friend?.name}</p>
                          </Link>
                        );
                      })}{' '}
                    </>
                  ) : (
                    <>No Friends Found</>
                  )}
                </Tab.Pane>
                <Tab.Pane eventKey={`mentors`}>
                  {connectionsList?.mentors?.users?.length !== 0 ? (
                    <>
                      {connectionsList?.mentors?.users?.map((friend, index) => {
                        return (
                          <Link to={`javascript:void(0)`} key={index}>
                            <div className="friend-lst-icn">
                              <img
                                src={
                                  friend
                                    ? friend?.avatar?.complete_url
                                    : require('../../../../assets/images/birth1.jpg')
                                }
                                alt="img"
                              />
                            </div>
                            <p>{friend?.name}</p>
                          </Link>
                        );
                      })}{' '}
                    </>
                  ) : (
                    <>No Friends Found</>
                  )}
                </Tab.Pane>
                <Tab.Pane eventKey={`mentees`}>
                  {connectionsList?.mentees?.users?.length !== 0 ? (
                    <>
                      {connectionsList?.mentees?.users?.map((friend, index) => {
                        return (
                          <Link to={`javascript:void(0)`} key={index}>
                            <div className="friend-lst-icn">
                              <img
                                src={
                                  friend
                                    ? friend?.avatar?.complete_url
                                    : require('../../../../assets/images/birth1.jpg')
                                }
                                alt="img"
                              />
                            </div>
                            <p>{friend?.name}</p>
                          </Link>
                        );
                      })}{' '}
                    </>
                  ) : (
                    <>No Friends Found</>
                  )}
                </Tab.Pane>
              </Tab.Content>
            </div>
          </Tab.Container>
        </div>

        <div className="time-line-friends  mt-3">
          <div className="d-flex align-item-center justify-content-between">
            <h4>PHOTOS</h4>
            <Link className="text-orange" to={`/user/albums/${userId}`} target="_blank">
              More
            </Link>
          </div>

          <div className="friends-list">
            {photosData?.photos?.slice(0, 6)?.map((photo, index) => {
              return (
                <Link to="#" key={index}>
                  <div className="friend-lst-icn">
                    <img src={photo?.photo_url} alt="img" />
                  </div>
                </Link>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
}

export default UserAbout;
