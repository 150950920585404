/**
@copyright  :   ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
@author     :   Shiv Charan Panjeta 
 
All Rights Reserved.
Proprietary and confidential :  All information contained here in is, and remains
the property of ToXSL Technologies Pvt. Ltd. and it's partners.
Unauthorized copying of this file, via any medium is strictly prohibited.
*/

import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';
import Pagination from 'react-bootstrap/Pagination';
import {
  deleteWhatsaapBusinessListing,
  getWhatsAppBusinessListing,
  multiactionWhatsAppBusiness
} from '../../../services/TemplateServices';
import showNotification from '../../../services/NotificationService';
import ReactPaginate from 'react-paginate';
import Loader from '../../../components/commoncomponents/loader/loader-large';
import NoDataFound from '../../../components/commoncomponents/NoDataFound';
import { ZERO } from '../../../globals/constants';
export default function WhatsappTemplate({ selectedTab }) {
  let active = 2;
  let items = [];
  for (let number = 1; number <= 5; number++) {
    items.push(
      <Pagination.Item key={number} active={number === active}>
        {number}
      </Pagination.Item>
    );
  }

  let navigate = useNavigate();
  const [allWhattsAppTemplateList, setAllWhattsAppTemplateList] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [checkedId, setCheckedId] = useState([]);
  const [allId, setAllId] = useState([]);
  const [action, setAction] = useState('');
  const [totalRecords, setTotalRecord] = useState('');
  const [checkId, setChecked] = useState(false);
  const [msgState, setMsgState] = useState('');
  useEffect(() => {
    if (selectedTab == 'whatsapp-business') {
      getWhatsappTemplateList();
    }
  }, [selectedTab]);

  const getWhatsappTemplateList = (page = '1', state_id = '1') => {
    setLoading(true);
    setCurrentPage(page);
    getWhatsAppBusinessListing(page, state_id)
      .then((resp) => {
        if (resp?.status === 200) {
          setLoading(false);
          setAllWhattsAppTemplateList(resp?.data?.data);
          setAllId(resp?.data?.data?.map((data) => data?.id ?? []));
          setTotalRecord(resp?.data?.pager?.total);
          setMsgState(resp?.data?.message);
        } else {
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const handlePageClick = (a) => {
    setCheckedId([]);
    let page = a?.selected + 1;
    getWhatsappTemplateList(page);
  };

  const handelDelete = (id) => {
    setLoading(true);
    deleteWhatsaapBusinessListing(id).then((resp) => {
      if (resp?.status == 200) {
        setLoading(false);
        showNotification('success', resp?.data?.message);
        getWhatsappTemplateList(currentPage);
      } else {
        setLoading(false);
      }
    });
  };

  const handleStatusUpdate = (id, value) => {
    const formData = new FormData();
    formData.append('ids', id);
    formData.append('action', value);
    multiactionWhatsAppBusiness(formData).then((res) => {
      if (res?.status === 200) {
        showNotification('success', res?.data?.message);
        getWhatsappTemplateList();
      }
    });
  };
  /**
   * hnadle single checkbox
   * @param {*} e
   * @param {*} id
   */
  const handleMultipleCheckBox = (e, id) => {
    e.preventDefault();
    if (checkedId.includes(id)) {
      setCheckedId(checkedId.filter((data) => data !== id));
    } else {
      setCheckedId([...checkedId, id]);
    }
  };

  /**
   * hnadle select all checkbox
   * @param {*} e
   */
  const handleSelectAll = (e) => {
    if (e.target.checked === true) {
      setChecked(true);
      allId.forEach((id) => {
        if (!checkedId.includes(id)) {
          setCheckedId((prev) => [...prev, id]);
        }
      });
    } else {
      setCheckedId([
        ...checkedId.filter((id) => {
          return allId.indexOf(id) == -1;
        })
      ]);
      setChecked(false);
    }
  };

  const handlePerformAction = async (val) => {
    if (checkedId?.length > 0) {
      try {
        const body = {
          ids: checkedId.toString(),
          action: val
        };

        const resp = await multiactionWhatsAppBusiness(body);
        if (resp?.status === 200) {
          getWhatsappTemplateList();
        }
      } catch (error) {
        console.error();
      }
    }
  };

  return (
    <div className="manage-temp-content">
      <div className="manage-temp-hd d-flex align-items-center justify-content-between mb-3">
        <h4>WhatsApp Business Template</h4>
        <div className="manage-temp-btn">
          {checkId == true ? (
            <div className="head-instite-sec">
              <Form.Select
                onCh
                className=""
                value={action}
                aria-label="Default select example"
                onChange={(e) => {
                  setAction(e.target.value);
                  handlePerformAction(e.target.value);
                }}>
                <option>Choose Action</option>
                <option value="enable">Enable</option>
                <option value="disable">Disable</option>
                <option value="delete">Delete</option>
              </Form.Select>
            </div>
          ) : (
            ''
          )}

          <Link to="/admin/AddwhatsappBusiness/" state={selectedTab}>
            Add New
          </Link>
        </div>
      </div>
      <div className="table-responsive">
        <Table className="custom-table-data" striped bordered hover>
          <thead>
            <tr>
              {msgState == !'Data not found' || allWhattsAppTemplateList?.length > 0 ? (
                <Form.Check
                  type={'checkbox'}
                  checked={
                    allId?.every((elem) => {
                      return checkedId.includes(elem);
                    }) && allWhattsAppTemplateList?.length > 0
                      ? true
                      : false
                  }
                  onChange={(e) => handleSelectAll(e)}
                />
              ) : (
                <th></th>
              )}

              <th>Sr. No.</th>
              <th>Title</th>
              <th>Content</th>

              <th>Status</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr className="search-table">
              <td></td>
              <td></td>
              <td></td>
              <td></td>

              <td>
                <Form.Select
                  aria-label="Default select example"
                  name="status"
                  onChange={(e) => getWhatsappTemplateList(1, e.target.value)}>
                  <option value="">- Select Status -</option>
                  <option value="1">Enable</option>
                  <option value="0">Disable</option>
                  <option value="2">Delete</option>
                </Form.Select>
              </td>
              <td></td>
            </tr>
            {allWhattsAppTemplateList?.length !== ZERO ? (
              allWhattsAppTemplateList?.map((data, index) => {
                return (
                  <tr key={index}>
                    <Form.Check
                      name={data?.title}
                      id={data?.id}
                      checked={checkedId?.includes(data?.id)}
                      onChange={(e) => {
                        handleMultipleCheckBox(e, data?.id);
                      }}
                      type={'checkbox'}
                    />
                    <td>{data?.id}</td>
                    <td>{data?.title}</td>
                    <td>{data?.content}</td>

                    <td>
                      <Form.Select
                        aria-label="Default select example"
                        value={data?.state_id}
                        onChange={(e) => {
                          handleStatusUpdate(data.id, e.target.value == 1 ? 'enable' : 'disable');
                        }}>
                        <option>Select Status</option>
                        <option value="1">Enable</option>
                        <option value="0">Disable</option>
                      </Form.Select>
                    </td>
                    <td>
                      <Form.Select
                        aria-label="Default select example"
                        onChange={(e) => {
                          if (e.target.value == '1') {
                            navigate(`/admin/AddwhatsappBusiness?id=${data?.id}`);
                          }
                          if (e.target.value == '2') {
                            handelDelete(data?.id);
                          }
                        }}>
                        <option>Action</option>
                        <option value="1">Edit</option>
                        <option value="2">Delete</option>
                      </Form.Select>
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr className="text-center">
                <td colSpan={9}>
                  <NoDataFound />
                </td>
              </tr>
            )}
          </tbody>
        </Table>
        {totalRecords > 10 ? (
          <ReactPaginate
            previousLabel={currentPage > 0 ? '<' : ''}
            nextLabel=">"
            onPageChange={handlePageClick}
            pageCount={allWhattsAppTemplateList?.total / 10}
            activeClassName={'page-link active'}
          />
        ) : (
          ''
        )}
      </div>
      {loading ? <Loader /> : ''}
    </div>
  );
}
