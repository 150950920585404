/**  @copyright : ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
@author     : Shiv Charan Panjeta < shiv@toxsl.com
All Rights Reserved.
Proprietary and confidential :  All information contained herein is, and remains
the property of ToXSL Technologies Pvt. Ltd. and its partners.
Unauthorized copying of this file, via any medium is strictly prohibited.
**/
import { useState } from 'react';
import { Col, Container, Nav, Navbar, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import CallIcon from '../../../assets/images/call-union.png';
import { checkDomain } from '../../../services/InstituteServices';
import { useEffect } from 'react';
import { _insInfo } from '../../../redux/action';
import { truncate } from '../../../globals/helper';

function ClubHeader() {
    const [state, setState] = useState({});
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        checkApi();
    }, []);

    /**
     * a function to check domain name
     */
    const checkApi = () => {
        checkDomain().then((res) => {
            if (res?.status === 200) {
                if (res?.data?.message == 'Data not found!') {
                    navigate('/error-page');
                } else {
                    dispatch(_insInfo(res?.data?.details));
                    setState(res?.data?.details);
                }
            }
        });
    };


    return (
        <>
            <header className="institute-header">
                <div className="header-top">
                    <Container>
                        <Row>
                            <Col xs={6} md={6}>
                                <ul className="social-link">
                                    <li>
                                        <Link href="">
                                            <i className="fab fa-facebook-f"></i>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link href="">
                                            <i className="fab fa-instagram"></i>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link href="">
                                            <i className="fab fa-linkedin-in"></i>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link href="">
                                            <i className="fab fa-twitter"></i>
                                        </Link>
                                    </li>
                                </ul>
                            </Col>

                        </Row>
                    </Container>
                </div>
                <div className="header-middle">
                    <Container>
                        <Row className="align-items-center">
                            <Col lg={5}>
                                <div className="d-flex align-items-center pointer" role='presentation' onClick={() => navigate("/club/home")} >
                                    {state?.logo ? (
                                        <Link to="/club/home" className="">
                                            <img
                                                src={state?.logo}
                                                alt="logo"
                                                width={'100px'}
                                            />
                                        </Link>
                                    ) : (
                                        <></>
                                    )}

                                    <h3 className="institue-title" title={state?.name}>
                                        {truncate(state?.name, 20)}
                                        {/* Sri Guru Harkrishan Senior <br /> Secondary Public School */}
                                        <p>
                                            {state?.instituteName}
                                        </p>
                                    </h3>

                                </div>
                            </Col>
                            <Col lg={7} className="float-end">
                                <Row className="align-items-center">
                                    <Col md={5}>
                                        {state?.website ? (
                                            <div className="d-flex align-items-center">
                                                <i className="fas fa-map-marker-alt"></i>
                                                <p className="ps-2">{state?.website}</p>
                                            </div>
                                        ) : (
                                            <></>
                                        )}
                                    </Col>
                                    <Col md={3}>
                                        {state?.phone || state?.mobile ? (
                                            <div className="call-us position-relative">
                                                <div className="d-flex align-items-center">
                                                    <img src={CallIcon} alt="logo" />
                                                    <div>
                                                        <Link href="tel: 0183-2562649" className="ps-2 d-block">
                                                            {state?.phone}
                                                        </Link>
                                                        <Link href="tel : 0183-2212329" className="ps-2 d-block">
                                                            {state?.mobile}
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        ) : (
                                            <></>
                                        )}
                                    </Col>
                                    {/* <Col md={4}>
                                        <div className="d-flex align-items-center">
                                            <img src={MailIcon} alt="logo" /> 
                                            <i className="fa fa-envelope" aria-hidden="true"></i>
                                            <Link
                                                to="#"
                                                onClick={() =>
                                                    (window.location = `mailto:${state?.email || state?.admin_email}`)
                                                }
                                                className="ps-2"
                                            >
                                                {state?.email || state?.admin_email}
                                            </Link>
                                        </div>
                                    </Col> */}
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <div className="header-bottom">
                    <Navbar expand="lg">
                        <Container>
                            <Navbar.Toggle aria-controls="basic-navbar-nav" />
                            <Navbar.Collapse id="basic-navbar-nav">
                                <Nav key={0}>
                                    <NavLink className="nav-link" to={"/club/home"}>
                                        Home
                                    </NavLink>
                                    <NavLink className="nav-link" to={"/club/about"}>
                                        About
                                    </NavLink>
                                    <NavLink className="nav-link" to={"/club/members"}>
                                        Members
                                    </NavLink>
                                    <NavLink className="nav-link" to={"/club/gallery"}>
                                        Gallery
                                    </NavLink>
                                    <NavLink className="nav-link" to={"/club/blogs"}>
                                        Blogs
                                    </NavLink>
                                    <NavLink className="nav-link" to={"/club/news"}>
                                        News
                                    </NavLink>
                                    {/* <NavLink className="nav-link" to={"/club/events"}>
                                        Events
                                    </NavLink> */}
                                    <NavLink className="nav-link" to={"/club/contact"}>
                                        Contact us
                                    </NavLink>
                                </Nav>
                            </Navbar.Collapse>
                        </Container>
                    </Navbar>
                </div>
            </header>

        </>
    );
}

export default ClubHeader;
