import { useState } from 'react';
import { useEffect } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import showNotification from '../../../services/NotificationService';
import * as APISERVICES from '../../../services/UserServices';

function SwotskillForm() {
  const [swotDetail, setSWOTDetail] = useState({
    strength: '',
    weakness: '',
    opportunities: '',
    threats: ''
  });

  useEffect(() => {
    getSWOTDetail();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSWOTDetail({ ...swotDetail, [name]: value });
  };

  const getSWOTDetail = () => {
    APISERVICES.getSWOT().then((res) => {
      if (res?.status === 200) {
        setSWOTDetail({
          strength: res?.data?.data?.strength,
          weakness: res?.data?.data?.weakness,
          opportunities: res?.data?.data?.opportunities,
          threats: res?.data?.data?.threats
        });
      }
    });
  };

  const validation = () => {
    return true;
  };

  const submitHandler = (e) => {
    e.preventDefault();

    if (validation()) {
      let formData = new FormData();
      for (let i in swotDetail) {
        formData.append(`${i}`, swotDetail[i]);
      }

      APISERVICES.addUpdateSWOTInfo(formData).then((res) => {
        if (res?.status === 200) {
          showNotification('success', res?.data?.data);
          getSWOTDetail;
        }
      });
    }
  };

  return (
    <>
      <div className="detail_form_div">
        <form className="contact-details" id="">
          <h2>SWOT Analysis</h2>
          <div className="swot-frm">
            <Form>
              <div className="swot-frm-card">
                <Row className="m-0 p-0">
                  <Col className="p-0" sm={9} xs={9}>
                    <Form.Group className="" controlId="exampleForm.ControlTextarea1">
                      <Form.Control
                        as="textarea"
                        placeholder="Enter your strengths"
                        name="strength"
                        value={swotDetail?.strength}
                        onChange={handleChange}
                        maxLength={1000}
                        rows={3}
                      />
                    </Form.Group>
                  </Col>
                  <Col className="p-0" sm={3} xs={3}>
                    <div
                      className="swot-rt-card"
                      title="Add your strengths single strengths in single line.">
                      <h1>S</h1>
                      <p>Strengths</p>
                    </div>
                  </Col>
                </Row>
              </div>
              <div className="swot-frm-card">
                <Row className="m-0 p-0">
                  <Col className="p-0" sm={9} xs={9}>
                    <Form.Group className="orange-txt" controlId="exampleForm.ControlTextarea1">
                      <Form.Control
                        as="textarea"
                        placeholder="Enter your weakness."
                        rows={3}
                        name="weakness"
                        value={swotDetail?.weakness}
                        onChange={handleChange}
                        maxLength={1000}
                      />
                    </Form.Group>
                  </Col>
                  <Col className="p-0" sm={3} xs={3}>
                    <div
                      className="swot-rt-card orange"
                      title="Add your weakness single strengths in weakness line.">
                      <h1>W</h1>
                      <p>Weakness</p>
                    </div>
                  </Col>
                </Row>
              </div>
              <div className="swot-frm-card">
                <Row className="m-0 p-0">
                  <Col className="p-0" sm={9} xs={9}>
                    <Form.Group className="green-txt" controlId="exampleForm.ControlTextarea1">
                      <Form.Control
                        as="textarea"
                        placeholder="Enter opportunities."
                        rows={3}
                        name="opportunities"
                        value={swotDetail?.opportunities}
                        onChange={handleChange}
                        maxLength={1000}
                      />
                    </Form.Group>
                  </Col>
                  <Col className="p-0" sm={3} xs={3}>
                    <div
                      className="swot-rt-card green"
                      title="Add your opportunities single strengths in opportunities line.">
                      <h1>O</h1>
                      <p>Opportunities</p>
                    </div>
                  </Col>
                </Row>
              </div>
              <div className="swot-frm-card">
                <Row className="m-0 p-0">
                  <Col className="p-0" sm={9} xs={9}>
                    <Form.Group className="threat-txt" controlId="exampleForm.ControlTextarea1">
                      <Form.Control
                        as="textarea"
                        placeholder="Mention threats if any."
                        rows={3}
                        name="threats"
                        value={swotDetail?.threats}
                        onChange={handleChange}
                        maxLength={1000}
                      />
                    </Form.Group>
                  </Col>
                  <Col className="p-0" sm={3} xs={3}>
                    <div
                      className="swot-rt-card threat"
                      title="Add your threats single strengths in threats line.">
                      <h1>T</h1>
                      <p>Threats</p>
                    </div>
                  </Col>
                </Row>
              </div>
              <Row className="m-0 align-items-center py-3 px-4">
                <Col lg={12} sm={12} xs={12}>
                  <div className="accnt-lft-btn d-flex align-items-center justify-content-end">
                    <button
                      title={
                        swotDetail?.opportunities === '' &&
                        swotDetail?.strength === '' &&
                        swotDetail?.threats === '' &&
                        swotDetail?.weakness === ''
                          ? 'Disabled'
                          : 'Save'
                      }
                      className="accnt-btn txt-transfrm "
                      type="button"
                      onClick={submitHandler}
                      disabled={
                        swotDetail?.opportunities === '' &&
                        swotDetail?.strength === '' &&
                        swotDetail?.threats === '' &&
                        swotDetail?.weakness === ''
                      }>
                      Save
                    </button>
                  </div>
                </Col>
              </Row>
            </Form>
          </div>
        </form>
      </div>
    </>
  );
}

export default SwotskillForm;
