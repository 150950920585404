import * as api from '../utils/requests';

export const getCompanyManage = async () => {
  return await api.getReq(`/campus/news-list`);
};

export const getCompanyFollowed = async () => {
  return await api.getReq(`/jobs/getFollowedCompanies`);
};

export const followCompany = async () => {
  return await api.getReq(`/user/followCompany`);
};

export const unfollowCompany = async () => {
  return await api.getReq(`/user/unfollowCompany`);
};

export const getJobsInNetwork = async () => {
  return await api.getReq(`/jobs/jobsInYourNetwork`);
};

export const getSuggestedCompany = async (search = '', offset = '', limit = '12') => {
  return await api.getReq(
    `/company/getComapnySuggestions?search=${search}&page=${offset}&limit=${limit}`
  );
};

export const getManageCompany = async (search = '', offset = '', limit = '') => {
  return await api.getReq(
    `/jobs/myManageCompany?company_name=${search}&offset=${offset}&$limit=${limit}`
  );
};

export const getJoinCompany = async (search = '', offset = '', limit = '') => {
  return await api.getReq(
    `/company/getJoinedCompanies?company_name=${search}&offset=${offset}&$limit=${limit}`
  );
};

export const getCompanyDetailsById = async (companyName) => {
  return await api.getReq('company/getCompanyInfo?username=' + companyName);
};

// Add Admin

export const getCompanyAdminConnectionsForAdmin = async (body) => {
  return await api.PostReq(`/company/adminConnections`, body);
};

export const addAdminConnection = async (body) => {
  return await api.PostReq(`/company/addAdmins`, body);
};

export const removeAdmin = async (comp_id, admin_id) => {
  return await api.getReq(
    `/admin/companies/remove_admin?company_id=${comp_id}&admin_id=${admin_id}`
  );
};

export const removeLeader = async (comp_id, admin_id) => {
  return await api.getReq(
    `/admin/companies/remove_leader?company_id=${comp_id}&user_id=${admin_id}`
  );
};

export const stateChangeForAdmin = async (comp_id, admin_id, status) => {
  return await api.getReq(
    `/admin/companies/set_status?company_id=${comp_id}&admin_id=${admin_id}&status=${status}`
  );
};
export const stateChangeForLeader = async (comp_id, admin_id, status) => {
  return await api.getReq(
    `/admin/companies/set_leader_status?company_id=${comp_id}&user_id=${admin_id}&status=${status}`
  );
};

export const addAdminInCompany = async (id, body) => {
  return await api.PostReq(`/admin/companies/add_admins?company_id=${id}`, body);
};

export const addLeaderInCompany = async (id, body) => {
  return await api.PostReq(`/admin/companies/add_leaders?company_id=${id}`, body);
};

export const getAdminListforCompany = async (body) => {
  return await api.PostReq(`/company/getCompanyAdmins`, body);
};

export const getLeaderDropDownList = async (id, body) => {
  return await api.PostReq(`/company/leadersList?company_id=${id}`, body);
};

export const removeAdminListforCompany = async (company_id, admin_id) => {
  return await api.getReq(`/company/removeAdmin?company_id=${company_id}&admin_id=${admin_id}`);
};

// ADD leader
export const getConnectionsForLeader = async (id) => {
  return await api.PostReq(`/company/adminConnections?company_id=${id}`);
};

export const addLeaderConnection = async (body) => {
  return await api.PostReq(`/company/addLeaders`, body);
};

export const getJobsByCompanyName = async (companyName) => {
  return await api.getReq('/jobs/getCompanyJobsList?username=' + companyName);
};
export const getCompanyAboutById = async (companyName) => {
  return await api.getReq('company/getCompanyInfo?username=' + companyName);
};

export const getJobListCompany = async () => {
  return await api.getReq(`/company/getJobList?username=myOrganisation`);
};
export const unFollowCompany = async (body) => {
  return await api.PostReq(`company/unfollowCompany?t=follow&a=follow&without_html=1`, body);
};
export const FollowCompany = async (body) => {
  return await api.PostReq(`/company/followCompany?t=follow&a=follow&without_html=1`, body);
};

export const getProfessionalCompanyInfo = async (username = '') => {
  return await api.getReq(`/company/getCompanyInfo?username=${username}`);
};

export const editCompanyFromUser = async (id, body) => {
  return await api.PostReq(`/company/saveCompanyInfo?company_id=${id}`, body);
};

export const getProfessionalJobList = async (username = '') => {
  return await api.getReq(`/company/getJobList?username=${username}`);
};

export const getProfessionalRecent = async (
  id = '',
  section = 'company',
  timeline = 0,
  type = 'all'
) => {
  return await api.getReq(
    `/company/getCompanyUpdates?id=${id}&section=${section}&timeline=${timeline}&type=${type}`
  );
};

export const getCompanyPhotos = async (id = '', section = '', timeline = '', type = '') => {
  return await api.getReq(
    `/company/getCompanyUpdatesid=${id}&section=${section}&timeline=${timeline}&{type}=${type}`
  );
};

export const getRecent = async (id = '', section = '', timeline = '', type = '') => {
  return await api.getReq(
    `/company/getCompanyUpdates?id=${id}&section=${section}&timeline=${timeline}&type=${type}`
  );
};

export const followCompanySuggestion = async (body) => {
  return await api.PostReq(`/company/followCompany?t=follow&a=follow&without_html=1`, body);
};

export const postAJobInCompany = async (body) => {
  return await api.PostReq(`/company/addJob`, body);
};
export const addRecentPost = async (body) => {
  return await api.PostReq(`/postAdd?t=post&a=new&without_html=1`, body);
};

export const companyPostLikeUnlike = async (id) => {
  return await api.getReq(`/company/likeCompanyPost?t=post&a=like&without_html=1&post_id=${id}`);
};

export const compnayPostComment = async (id, body) => {
  return await api.PostReq(
    `/company/postCompanyComment?t=post&a=comment&without_html=1&post_id=${id}`,
    body
  );
};

export const deleteComanyPost = async (id) => {
  return await api.getReq(`/company/deleteCompanyPost?t=post&a=delete&post_id=${id}`);
};

export const comoanyFollowList = async (id) => {
  return await api.PostReq(`/company/getCompanyFollowersList?username=${id}&offset=0&limit=10`);
};
