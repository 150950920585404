import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import { Link, useSearchParams } from 'react-router-dom';
import Select from 'react-select';
import HeaderLogin from '../../commoncomponents/HeaderLogin';
import NewsSidebar from '../../commoncomponents/newsfeeds/NewsSidebar';
import Upcoming from '../../commoncomponents/newsfeeds/Upcoming';
import WhatsNew from '../../commoncomponents/newsfeeds/WhatsNew';
import TopTabbings from './TopTabbings';
let $ = require('jquery');

import { useCallback, useRef } from 'react';
import Cropper from 'react-easy-crop';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { getCroppedImg } from '../../../globals/canvasUtils';
import {
  post_publisher_soundcloud_placeholder,
  post_publisher_youtube_placeholder
} from '../../../globals/constants';
import { useOnClickOutside } from '../../../globals/helper';
import useDebounce from '../../../globals/useDebounce';
import useDocumentTitle from '../../../globals/useDocumentTitle';
import { deleteMediaFileAction, youtubeVideoAction } from '../../../redux/action';
import showNotification from '../../../services/NotificationService';
import {
  addSocialPagePost,
  getPagePeopleList,
  getPagesCategories,
  getPublisherBox,
  getSocialPageDetail,
  getSocialPages,
  PageLikeUnLike,
  removeMemberPages,
  socialPageAddAdmin,
  socialPageRemoveAdmin,
  socialPageSetting
} from '../../../services/SocialServices';
import { changeCoverImage, removeCoverImage } from '../../../services/UserServices';
import LikesCard from '../../commoncomponents/newsfeeds/LikesCard';
import NoDataFound from '../../commoncomponents/NoDataFound';
import { MiniFooter } from '../../MiniFooter';
import PageAdminRoles from './PageAdminRoles';
import PageGeneralSetting from './PageGeneralSetting';
import PagePrivacySetting from './PagePrivacySetting';
import PagePeopleList from './PostComponets/PagePeopleList';
import PagePostLayout from './PostComponets/PagePostLayout';
import PostBottomMedia from './PostComponets/PostBottomMedia';

function PagesDetail() {
  useDocumentTitle('Almabay');
  const location = useLocation();
  const [show1, setShow1] = useState(false);
  const dispatch = useDispatch();
  const [searchParms, setSearchParams] = useSearchParams();
  const event_id = searchParms.get('eventKey');
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);
  const eventKeySuggestionsPage = location?.hash;
  const [latestPost, setLatestPost] = useState('');
  const [active, setActive] = useState(event_id || 'first');
  const [checkPost, setCheckPost] = useState([]);
  const [selectedFile, setSelectedFile] = useState([]);
  const [isSelected, setIsSelected] = useState(false);
  const [multi, setMulti] = useState(true);
  const [show, setShow] = useState(false);
  const [type, setType] = useState('all');
  const [selectedData, setSelectedData] = useState([]);
  const [multiSelectData, setMultiSelectData] = useState([]);
  const [singleSelectName, setSingleSelectName] = useState('');
  const [multiSelectName, setmultiSelectName] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [pageNum, setPageNum] = useState(1);

  const [activeClassState, setIsActiveClassState] = useState('members');

  const ref = useRef();
  useOnClickOutside(ref, () => setShow(false));

  const [isDisabled, setIsDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isClearable, setIsClearable] = useState(true);
  const [isRtl, setIsRtl] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [isSearchable, setIsSearchable] = useState(true);
  const [pageData, setPageData] = useState('');
  const userDetail = useSelector((state) => state?.user?.details);
  const timeline_id = useParams()?.encrypted_id;
  const navigate = useNavigate();
  const [userType, setUserType] = useState('members');

  const [details, setDetails] = useState({
    timeline_post_privacy: '',
    message_privacy: ''
  });
  const [genSetting, setGenSetting] = useState({
    name: '',
    category_id: '',
    about: '',
    timeline_post_privacy: '',
    message_privacy: '',
    category_name: '',
    timeline_id: ''
  });
  const categoryName = genSetting?.category_name;
  const [clicked, setClicked] = useState(false);
  const [isGeneral, setIsGeneral] = useState(location?.pathname.includes('general_settings'));
  const [isPrivacy, setIsPrivacy] = useState(location?.pathname.includes('privacy_settings'));
  const [isAdminRole, setIsAdminRole] = useState(location?.pathname.includes('admin_roles'));
  // crop image states
  const [editBackGround, setEditBackGround] = useState(false);
  const [selectedImage, setSelectedImage] = useState('');
  const [imageSrc, setImageSrc] = React.useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [rotation, setRotation] = useState(0);
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const [recipientId, setrecipientId] = useState('');
  const [encryptedId, setencryptedId] = useState('');
  const [memberCount, setMemberCount] = useState('');
  const [peoepleIds, setPeopleIds] = useState([]);
  const [list, setList] = useState([]);
  const [isLiked, setIsLiked] = useState(false);
  const [allDetail, setAllDetail] = useState('');
  const [postLoading, setPostLoading] = useState(false);
  const [emojiList, setEmojiList] = useState([]);
  const [showEmoji, setShowEmoji] = useState(false);
  const [uploadFiles, setUploadFiles] = useState([]);
  const [isSelectedFile, setIsSelectedFile] = useState(false);
  const [soundCloud, setSoundCloud] = useState(false);
  const [youtubeVideoCloud, setYoutubeVideoCloud] = useState(false);
  const [postGoogleMapState, setPostGoogleMapState] = useState(false);
  const [loadingState, setLoadingState] = useState('loading');

  const result = useSelector((state) => state?.youtubeVideoList?.videos);
  const [disableInputBox, setDisableInputBox] = useState(false);
  const [search, setSearch] = useState('');
  const [likeLoader, setLikeLoader] = useState(false);

  const loggedinUserID = useSelector((state) => state?.user?.details?.encrypted_id);

  const handleShow = () => {
    setShow(true);
    setIsGeneral(false);
    setIsPrivacy(false);
    setIsAdminRole(false);
  };
  const handleClose = () => {
    setShow(false);
  };

  useEffect(() => {
    getDetail();
    // getPostListData();
    getEmojiIcons();
    getPostListData();

    if (isPrivacy == true || isAdminRole == true || isGeneral == true) {
      setActive('');
    }
  }, [timeline_id, type]);

  useEffect(() => {
    if (pageNum <= totalPages) {
      getPostListData();
    }
  }, [pageNum]);

  useEffect(() => {
    if (active === 'first' || active === 'fourth' || active === 'fifth' || active === 'six') {
      setType('all');
    } else if (active === 'second') {
      setType('videos');
      setCheckPost([]);
    } else if (active === 'third') {
      setType('photos');
      setCheckPost([]);
    }
  }, [active]);

  const validate = () => {
    let errors = {};
    if (!genSetting.name.trim()) {
      errors.name = 'This field is required.';
    }
    return errors;
  };

  const getPeopleList = (type, q = '') => {
    setLoading(true);
    let body = {
      type: type ? type : userType
    };

    getPagePeopleList(body, timeline_id, q).then((resp) => {
      setLoading(false);
      if (resp?.status === 200) {
        setLoading(false);
        setList(resp?.data?.data?.peoples);
        setPeopleIds(resp?.data?.data?.peoples?.map((people) => people?.encrypted_id));
        setMemberCount(resp?.data?.data);
        setIsLiked(resp?.data?.data?.is_liked);
      }
    });
  };

  //Make Admin and Make Editor

  const addAdmin = (e, id, type, q = '') => {
    e.preventDefault();
    let body = {
      page_id: pageData?.id,
      admin_id: id,
      admin_role: type
    };
    let formData = new FormData();
    for (let i in body) {
      formData.append(`${i}`, body[i]);
    }
    socialPageAddAdmin(formData).then((res) => {
      if (res?.status === 200) {
        showNotification('success', res?.data?.message);
        getPeopleList('admins', q);
      } else {
        showNotification('success', res?.data?.message);
      }
    });
  };

  // Remove functionality

  const handleRemove = (e, id, type) => {
    e.preventDefault();
    let body = {
      page_id: pageData?.id,
      admin_id: id
    };
    if (confirm('Do you want to Remove member ?') == true) {
      setLoading(true);
      socialPageRemoveAdmin(body).then((resp) => {
        setLoading(false);
        if (resp?.status == 200) {
          getPeopleList(type);
          showNotification('success', resp?.data?.message);
        }
      });
    } else {
      return;
    }
  };

  const handleRemoveMember = (e, id, type, encryptedId) => {
    e.preventDefault();
    let body = {
      page_id: pageData?.id,
      member_id: id
    };
    if (confirm('Do you want to remove member ?') == true) {
      setLoading(true);
      removeMemberPages(body).then((resp) => {
        setLoading(false);
        if (resp?.status == 200) {
          getPeopleList(type);
          showNotification('success', resp?.data?.message);
          if (encryptedId === loggedinUserID) {
            navigate('/user/pages');
          }
        }
      });
    } else {
      return;
    }
  };

  const handleSave = () => {
    setClicked(true);
    let body = {
      name: genSetting?.name,
      category_id: selectedCategory?.value,
      about: genSetting?.about,
      timeline_post_privacy: genSetting?.timeline_post_privacy,
      message_privacy: genSetting?.message_privacy,
      timeline_id: pageData?.id
    };

    if (!Object.keys(validate()).length) {
      socialPageSetting(body).then((resp) => {
        if (resp?.status === 200) {
          showNotification('success', resp?.data?.message);
          // navigate(-1);
        }
      });
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setGenSetting({ ...genSetting, [name]: value });
  };

  const getDetail = () => {
    getSocialPageDetail(timeline_id).then((res) => {
      if (res?.status === 200) {
        const data = res?.data?.data?.sk?.page;
        setPageData(data);
        setAllDetail(res?.data?.data?.sk);
        // setIsLiked(res?.data?.data?.sk?.is_liked)
        setDetails({
          timeline_post_privacy: data?.timeline_post_privacy,
          message_privacy: data?.message_privacy
        });
        setGenSetting({
          username: data?.username,
          name: data?.name,
          about: data?.about,
          address: data?.address,
          add_privacy: data?.add_privacy,
          timeline_post_privacy: data?.timeline_post_privacy,
          category_name: data?.category_name
        });
        setSelectedCategory({ label: data?.category_name, value: data?.category_id });

        setrecipientId(data?.id);
        getPeopleList(userType);
        setencryptedId(data);
      }
    });
  };

  const getPostListData = (isTrue, postId) => {
    if (isTrue !== 'delete' || isTrue !== 'share') {
      setLoading(true);
      setDisableInputBox(true);
    }
    getSocialPages(timeline_id, pageNum, type).then((res) => {
      if (res?.status === 200) {
        // if (res?.data?.data !== undefined) {
        setPostLoading(false);
        setLoading(false);
        setDisableInputBox(false);
        if (isTrue === true) {
          let arra1 = checkPost.map((item) => {
            const item2 = res.data?.data?.stories?.find((i2) => i2.id == item.id);
            return item ? { ...item, ...item2 } : item2;
          });
          setCheckPost(arra1);
          setPostLoading(false);
          setLatestPost('');
        } else {
          if (res?.data?.data) {
            setCheckPost(
              pageNum > 0 ? [...checkPost, ...res?.data?.data?.stories] : res?.data?.data?.stories
            );
          } else {
            setCheckPost([]);
          }

          if (isTrue == 'share') {
            let checkPostIDs = checkPost?.map((data) => data?.id);

            let arra1 = checkPost.map((item) => {
              const item2 = res.data?.data?.stories?.find((i2) => i2.id == item.id);
              return item ? { ...item, ...item2 } : item2;
            });
            getSocialPages(id, 1, type).then((res2) => {
              let filterData = res2.data?.data?.stories.filter((item) => {
                return !checkPostIDs.includes(item?.id);
              });
              setCheckPost([...filterData, ...arra1]);
            });
          }

          if (isTrue == 'unshare') {
            let arra1 = checkPost
              .map((item) => {
                const item2 = res.data?.data?.stories?.find((i2) => i2.id == item.id);
                return item ? { ...item, ...item2 } : item2;
              })
              ?.filter((data) => !(data?.post_id == postId && data?.via_type == 'share'));
            setCheckPost(arra1);
          }

          if (isTrue == 'makePost') {
            setCheckPost(res?.data?.data?.stories);
          }
        }

        setTotalPages(Math.ceil(res?.data?.data?.total_records / 10));
        // } else {
        //   setCheckPost([]);
        // }
      } else {
        setLoading(false);
        setDisableInputBox(false);
      }
    });
  };

  const checkLatestpost = (e) => {
    e.preventDefault();
    setPostLoading(true);

    let formData = new FormData();
    formData.append(`text`, latestPost.trim());
    formData.append(`recipient_id`, recipientId);
    formData.append(`timeline_id`, '');
    formData.append(`post_privacy[]`, 5);
    for (let i in selectedFile) {
      formData.append(`photos[]`, selectedFile[i]);
    }
    for (let i in uploadFiles) {
      formData.append('files[]', uploadFiles[i]);
    }
    addSocialPagePost(formData).then((res) => {
      if (res?.status === 200) {
        // setPostLoading(false);
        showNotification('success', res?.data?.message);
        setSelectedFile([]);
        setUploadFiles([]);
        setIsSelected(false);
        setIsSelectedFile(false);
        setSelectedData(['4']);
        setMultiSelectData([]);
        setmultiSelectName([]);
        setLatestPost('');
        // setLatestPost('');
        // APISERVICES.getTimeLineSocial(pageNum).then((res) => {
        //   if (res?.status === 200) {
        //     setLoading(false);
        //     setCheckPost(res?.data?.data?.stories);
        //     // setTotalPages(res?.data?.pager.pageCount);
        //     setTotalPages(2);
        //   } else {
        //     setLoading(false);
        //   }
        // });
        setPageNum(0);
        getPostListData('makePost');
      }
    });
  };

  const makePost = (e) => {
    if (latestPost?.trim() !== '' || selectedFile.length !== 0 || uploadFiles.length !== 0) {
      checkLatestpost(e);
    } else {
      showNotification(
        'danger',
        'This status update appears to be blank. Please write something or attach photo to update your status.'
      );
    }
  };

  const onPostListData = () => {
    getPostListData();
  };
  const onIncrementPageNo = () => {
    setPageNum((no) => no + 1);
  };
  const showCroppedImage = useCallback(
    async (croppedArea, croppedAreaPixels) => {
      try {
        const croppedImage = await getCroppedImg(imageSrc, croppedAreaPixels);
        setCroppedImage(croppedImage);
      } catch (e) {
        console.error(e);
      }
    },
    [imageSrc, croppedAreaPixels]
  );

  const onClose = useCallback(() => {
    setCroppedImage(null);
  }, []);

  const onFileChange = async (e) => {
    let files = e?.target?.files;

    if (files && files?.length > 0) {
      const targetfileobj = [...files];
      targetfileobj?.map(async (data) => {
        if (
          !data?.type?.includes('image') &&
          (data?.type?.includes('application') ||
            data?.type?.includes('audio') ||
            data?.type?.includes('video'))
        ) {
          showNotification('danger', ' Please use PNG or JPG types of images ', 'random');
          setImageSrc('');
          setSelectedImage('');
          return;
        } else {
          const file = files[0];
          let imageDataUrl = await readFile(file);
          setSelectedImage(file);
          setImageSrc(imageDataUrl);
        }
      });
    }
  };
  const handeSaveImage = (e) => {
    e.preventDefault();
    let formData = new FormData();
    formData.append(`image`, selectedImage);
    formData.append(`position`, crop?.y);
    formData.append(`timeline_id`, pageData?.id);

    if (selectedImage != '') {
      changeCoverImage(formData).then((res) => {
        if (res?.status == 200) {
          getDetail();
          setSelectedImage('');
          setEditBackGround(false);
        }
      });
    } else {
      setEditBackGround(false);
    }
  };

  const removeImage = (e) => {
    e.preventDefault();
    let formData = new FormData();

    formData.append(`timeline_id`, pageData?.id);
    // formData.append(`cover_id`, loginDetails[i]);
    removeCoverImage(formData).then((res) => {
      if (res?.status == 200) {
        getDetail();
        setEditBackGround(false);
      }
    });
  };

  useEffect(() => {
    // fetchPagesYouManage();
    getPagesCategories().then((resp) => {
      if (resp.status === 200) {
        let tempArr = [];
        let arr = [];
        if (resp?.data) {
          Object.entries(resp?.data)
            .map(([key, value]) => ({ key, value }))
            ?.map((insideData, index) => {
              tempArr.push({
                label: insideData?.key,
                options: Object.entries(insideData?.value)?.map(([key1, value1]) => {
                  return {
                    label: value1,
                    value: key1
                  };
                })
              });
            });
        }
        setCategoryOptions(tempArr);
      }
    });
  }, []);

  const getEmojiIcons = () => {
    getPublisherBox().then((res) => {
      if (res?.status == 200) {
        let arr = [];
        let data = res?.data?.data?.emoticons;
        for (let i in data) {
          arr.push({ id: i, label: data[i], value: data[i] });
        }
        setEmojiList(arr);
      } else {
        setEmojiList([]);
      }
    });
  };

  const LikeDisLikePageFunc = (e, type) => {
    e.preventDefault();
    if (type == 'unlike') {
      if (confirm('Do you want unlike this page?') == true) {
        setLikeLoader(true);
        let body = {
          following_id: pageData?.id
        };
        let formData = new FormData();
        for (let i in body) {
          formData.append(`${i}`, body[i]);
        }
        PageLikeUnLike(formData).then((res) => {
          if (res?.status === 200) {
            setLikeLoader(false);
            showNotification('success', 'Page unliked successfully');
            getDetail(timeline_id);
          } else {
            showNotification('err', res?.data?.message);
          }
        });
      } else return;
    } else {
      setLikeLoader(true);
      let body = {
        following_id: pageData?.id
      };
      let formData = new FormData();
      for (let i in body) {
        formData.append(`${i}`, body[i]);
      }
      PageLikeUnLike(formData).then((res) => {
        if (res?.status === 200) {
          setLikeLoader(false);
          showNotification('success', 'Page liked successfully');
          getDetail(timeline_id);
        } else {
          showNotification('err', res?.data?.message);
        }
      });
    }
  };

  const toggleMediaGroupFun = (chosen_input_selector = '') => {
    setSoundCloud(false);
    setYoutubeVideoCloud(false);
    setPostGoogleMapState(false);

    if (chosen_input_selector == 'soundcloud-wrapper') {
      setSoundCloud(true);
    } else if (chosen_input_selector == 'youtube-wrapper') {
      setYoutubeVideoCloud(!youtubeVideoCloud);
    } else if (chosen_input_selector == 'google-map-wrapper') {
      setPostGoogleMapState(true);
    }
  };
  const newParagraph = (e) => {
    if (e.key === 'Enter') {
    }
  };

  const debouncedSearchTerm = useDebounce(search, 500);

  useEffect(() => {
    if (debouncedSearchTerm) {
      dispatch(youtubeVideoAction(search));
    } else {
      dispatch(youtubeVideoAction());
    }
  }, [debouncedSearchTerm]);

  const deleteFile = async (post_id, media_id) => {
    const message = window.confirm('Are you sure you want to remove this file?');
    if (message) {
      dispatch(deleteMediaFileAction(post_id, media_id));
      getPostListData();
    }
  };

  return (
    <>
      <HeaderLogin state="d-none" />
      <div className="header-lnk-feeds">
        <div className="container">
          <TopTabbings state={'social'} />
        </div>
      </div>
      <section className="feed-sec bg-grey">
        <Container>
          <Row>
            <Col xs={12} md={2}>
              <NewsSidebar state="social" current="page" />
            </Col>
            <Col md={10}>
              <div className="page-detail-sec py-3">
                <div className="page-detial-cover">
                  {selectedImage !== '' ? (
                    <div className="cropContainer">
                      {' '}
                      <Cropper
                        image={imageSrc}
                        crop={crop}
                        zoom={zoom}
                        onCropChange={setCrop}
                        onCropComplete={showCroppedImage}
                        onZoomChange={setZoom}
                        showGrid={false}
                        objectFit={'horizontal-cover'}
                      />
                    </div>
                  ) : (
                    <img
                      src={
                        pageData?.cover_url
                          ? pageData?.cover_url
                          : require('../../../assets/images/default-cover.png')
                      }
                      alt="cover"
                      style={{ top: pageData?.cover_position * 2 + 'px' }}
                    />
                  )}

                  <div className="cover-disc d-flex align-items-center justify-content-between">
                    <div>
                      <h4>
                        <Link to={`/user/page/page-detail/${timeline_id}`}>{pageData?.name} </Link>
                        <p style={{ textTransform: 'capitalize' }} className="verified-check">
                          {pageData?.verified == true && (
                            <span className="verification ms-2 mt-1">
                              <i className="fa fa-check fa-stack-1x fa-inverse"></i>
                              <i className="fas fa-certificate crfict"></i>
                            </span>
                          )}
                        </p>
                      </h4>

                      <p>{pageData?.category_name}</p>
                    </div>
                    <div>
                      {isLiked == true ? (
                        likeLoader ? (
                          <Link className="main-btn like-btn blk " to="#" disabled={true}>
                            {likeLoader && <i className="fa fa-spinner fa-spin"></i>} Unlike
                          </Link>
                        ) : (
                          <Link
                            className="main-btn like-btn "
                            to="#"
                            onClick={(e) => LikeDisLikePageFunc(e, 'unlike')}>
                            Unlike
                          </Link>
                        )
                      ) : likeLoader ? (
                        <Link className="main-btn like-btn blk " to="#">
                          {likeLoader && <i className="fa fa-spinner fa-spin"></i>} Like
                        </Link>
                      ) : (
                        <Link
                          className="main-btn like-btn"
                          to="#"
                          onClick={(e) => LikeDisLikePageFunc(e, 'like')}>
                          Like
                        </Link>
                      )}
                    </div>
                  </div>
                  {allDetail?.admin === 'admin' && (
                    <>
                      {' '}
                      {editBackGround == false ? (
                        <Link
                          to="#"
                          className="page-cover-edit"
                          onClick={() => setEditBackGround(true)}>
                          <i className="fas fa-camera me-2"></i> Edit Background
                        </Link>
                      ) : (
                        <ul className="page-cover-function">
                          <li>
                            <Link to="" onClick={(e) => handeSaveImage(e)}>
                              Save
                            </Link>
                          </li>
                          <li>
                            <span className="change-img-card">Change Image</span>
                            <Form.Control type="file" onChange={onFileChange} />
                          </li>
                          <li>
                            <Link to="#" onClick={(e) => removeImage(e)}>
                              Remove Image
                            </Link>
                          </li>
                          <li>
                            <Link to="#" onClick={() => setEditBackGround(false)}>
                              <i className="fas fa-times"></i>
                            </Link>
                          </li>
                        </ul>
                      )}
                    </>
                  )}
                </div>
                <div className="pages-detail-tabs">
                  {' '}
                  <Tab.Container id="left-tabs-example" activeKey={show == true ? '' : active}>
                    <div className="pages-detail-tabhd  d-flex align-items-center justify-content-between mb-3">
                      <div>
                        {' '}
                        <Nav variant="pills" className="">
                          <Nav.Item>
                            <Nav.Link
                              eventKey="first"
                              onClick={() => {
                                handleClose();
                                setActive('first');
                                setType('all');
                                setPageNum(1);
                              }}>
                              Discussion
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link
                              eventKey="second"
                              onClick={() => {
                                handleClose();
                                setActive('second');
                                setType('videos');

                                setPageNum(1);
                              }}>
                              Videos
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link
                              eventKey="third"
                              onClick={() => {
                                handleClose();
                                setActive('third');
                                setType('photos');

                                setPageNum(1);
                                // getPostListData(2);
                              }}>
                              Photos
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link
                              eventKey="fourth"
                              onClick={() => {
                                handleClose();
                                setActive('fourth');
                                setType('all');

                                setPageNum(1);
                              }}>
                              Likes
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link
                              eventKey="fifth"
                              onClick={() => {
                                if (
                                  isGeneral ||
                                  isPrivacy ||
                                  isAdminRole ||
                                  allDetail?.message_privacy == 'everyone' ||
                                  allDetail?.admin !== 'admin'
                                ) {
                                  navigate(window.open(`/user/messages/${timeline_id}`)?.focus());
                                } else {
                                  handleClose();
                                  setActive('fifth');
                                  setPageNum(1);
                                }
                                setType('all');
                              }}>
                              Message
                            </Nav.Link>
                          </Nav.Item>
                          {isGeneral || isPrivacy || isAdminRole || event_id == 'fifth' ? (
                            <></>
                          ) : (
                            <>
                              {' '}
                              {allDetail?.admin == 'admin' && (
                                <Nav.Item>
                                  <Nav.Link
                                    eventKey="sixth"
                                    onClick={() => {
                                      handleClose();
                                      setActive('sixth');
                                      setType('all');
                                    }}>
                                    Settings
                                  </Nav.Link>
                                </Nav.Item>
                              )}
                            </>
                          )}

                          <Nav.Item>
                            <Nav.Link
                              target="_blank"
                              href={`/pages/pinvite/${timeline_id}`}
                            // eventKey="seven"
                            // onClick={() => {
                            //   handleClose();
                            //   setActive('seven');
                            // }}
                            >
                              Invite
                            </Nav.Link>
                          </Nav.Item>
                        </Nav>
                      </div>
                      {isGeneral || isPrivacy || isAdminRole ? (
                        <></>
                      ) : (
                        allDetail?.admin == 'admin' && (
                          <div className="text-end">
                            <Button
                              className="create-group-btn"
                              onClick={() => {
                                handleShow();
                                setActive('');
                              }}>
                              + Add Admin
                            </Button>
                          </div>
                        )
                      )}
                    </div>

                    <div className="detail-tab-cntnt">
                      {show || isGeneral || isPrivacy || isAdminRole ? (
                        <>
                          {isGeneral && (
                            <>
                              {' '}
                              <Tab.Content>
                                <PageGeneralSetting
                                  genSetting={genSetting}
                                  setGenSetting={setGenSetting}
                                  pageId={pageData?.id}
                                  renderFunction={getDetail}
                                />
                              </Tab.Content>
                            </>
                          )}
                          {isPrivacy && (
                            <>
                              {' '}
                              <Tab.Content>
                                <PagePrivacySetting
                                  details={details}
                                  setDetails={setDetails}
                                  pageId={pageData?.id}
                                  renderFunction={getDetail}
                                />
                              </Tab.Content>
                            </>
                          )}
                          {isAdminRole && (
                            <>
                              {' '}
                              <Tab.Content>
                                <PageAdminRoles pageId={pageData?.id} allDetail={allDetail} />
                              </Tab.Content>
                            </>
                          )}
                          {show && (
                            <Tab.Content>
                              <PagePeopleList
                                pageIdEncrypted={pageData?.encrypted_id}
                                pageId={pageData?.id}
                              />
                            </Tab.Content>
                          )}
                        </>
                      ) : (
                        <Tab.Content>
                          <Tab.Pane eventKey="first">
                            <Row>
                              <Col xs={12} md={8}>
                               
                                {pageData?.timeline_post_privacy == 'everyone' && (
                                  <div className="write-on-wall mb-3">
                                    <h4>
                                      <i className="fas fa-edit me-2"></i> What's going on?
                                    </h4>
                                    <textarea
                                      rows={5}
                                      style={{ width: '100%', resize: 'none' }}
                                      placeholder={'Write something... #hashtags'}
                                      onChange={(e) => {
                                        setLatestPost(e.target.value);
                                      }}
                                      disabled={postLoading}
                                      onKeyDown={(e) => newParagraph(e)}
                                      value={latestPost}></textarea>
                                    {soundCloud && (
                                      <div className="upload-img-detail d-flex align-items-center justify-content-between">
                                        <i className="fa fa-music"></i>
                                        <input
                                          className="soundcloud-input"
                                          type="text"
                                          autoFocus
                                          // onKeyUp={() => SK_searchSoundcloud()}
                                          placeholder={post_publisher_soundcloud_placeholder}
                                        />
                                        {/* <div className="input-result-wrapper"></div> */}
                                      </div>
                                    )}
                                    {/* https://www.youtube.com/watch?v=Z4Kgs9XiMGQ */}
                                    {youtubeVideoCloud && (
                                      <div className="upload-img-detail d-flex align-items-center justify-content-start">
                                        <i className="fa fa-film me-2"></i>
                                        <input
                                          className="youtube-input"
                                          type="text"
                                          onChange={(e) => {
                                            setSearch(e.target.value);
                                          }}
                                          placeholder={post_publisher_youtube_placeholder}
                                        />
                                        <div className="input-result-wrapper"></div>
                                      </div>
                                    )}
                                    {postGoogleMapState && (
                                      <div className="upload-img-detail d-flex align-items-center justify-content-between">
                                        <i className="fa fa-map-marker "></i>
                                        <input
                                          className="youtube-input"
                                          type="text"
                                          // onKeyUp={() => SK_searchYoutube()}
                                          placeholder={post_publisher_youtube_placeholder}
                                        />

                                        {/* <input id="autocomplete" className="google-map-input" name="google_map_name" type="text" value="" placeholder="<?php echo $this->lang->line('post_publisher_googlemap_placeholder'); ?>" apply-google-places ></input> */}
                                      </div>
                                    )}
                                    {/* <CKEditorComponent
                                  latestPost={latestPost}
                                  setLatestPost={setLatestPost}
                                  isToolBar={false}
                                  placeholder={'Write something... #hashtags'}
                                /> */}
                                    {isSelected && (
                                      <div className="upload-img-detail d-flex align-items-center justify-content-between">
                                        <p>
                                          {selectedFile?.length > 1
                                            ? `${selectedFile.length} photo(s) selected`
                                            : `${selectedFile.length} photo selected`}
                                        </p>
                                        <Link
                                          to="#"
                                          onClick={() => {
                                            setIsSelected(false);
                                            setIsSelectedFile(false);
                                            setSelectedFile([]);
                                            setUploadFiles([]);
                                          }}>
                                          <i className="fas fa-times"></i>
                                        </Link>
                                      </div>
                                    )}

                                    {isSelectedFile && (
                                      <div className="upload-img-detail d-flex align-items-center justify-content-between">
                                        <p>
                                          {uploadFiles?.length > 1
                                            ? `${uploadFiles.length} file(s) selected`
                                            : `${uploadFiles.length} file selected`}
                                        </p>
                                        <Link
                                          to="#"
                                          onClick={() => {
                                            setIsSelected(false);
                                            setIsSelectedFile(false);
                                            setSelectedFile([]);
                                            setUploadFiles([]);
                                          }}>
                                          <i className="fas fa-times"></i>
                                        </Link>
                                      </div>
                                    )}
                                    {showEmoji && (
                                      <div className="upload-img-detail d-flex align-items-center justify-content-between">
                                        {emojiList?.length !== 0 && (
                                          <>
                                            {emojiList?.map((data) => {
                                              return (
                                                <Link
                                                  to="#"
                                                  onClick={(e) => {
                                                    e.preventDefault();
                                                    setLatestPost(latestPost + data?.id);
                                                  }}>
                                                  <img src={data?.value} />
                                                </Link>
                                              );
                                            })}
                                          </>
                                        )}
                                      </div>
                                    )}
                                    {result.length > 0 && youtubeVideoCloud ? (
                                      <div className="input_result_wrapper">
                                        {result.map((item, i) => {
                                          return (
                                            <div className="api_data_wrapper">
                                              <table
                                                border={0}
                                                width={'100%'}
                                                cellSpacing={0}
                                                cellPadding={0}>
                                                <tbody>
                                                  <tr>
                                                    <td width={'40%'} align="left" valign="middle">
                                                      <img
                                                        className="thumbnail"
                                                        width={32}
                                                        height={32}
                                                        src={item.snippet.thumbnails.high.url}
                                                        alt="Youtube"
                                                      />
                                                    </td>
                                                    <td align="left" valign="middle">
                                                      <div className="name">
                                                        {item.snippet.title}
                                                      </div>
                                                    </td>
                                                  </tr>
                                                </tbody>
                                              </table>
                                            </div>
                                          );
                                        })}
                                      </div>
                                    ) : (
                                      search != '' && <span>No Result Found</span>
                                    )}
                                    {/* <textarea
                              type="text"
                              className="form-control"
                              id="yourmessage"
                              name="yourmessage"
                               onChange={(e) => {
                                setLatestPost(e.target.value);
                              }}
                              value={latestPost}
                              placeholder="Write something... #hashtags"
                              rows={3}
                            ></textarea> */}
                                    <div className="write-wall-ftr d-flex align-items-center justify-content-between">
                                      <PostBottomMedia
                                        setSelectedFile={setSelectedFile}
                                        setUploadFiles={setUploadFiles}
                                        setIsSelected={setIsSelected}
                                        setIsSelectedFile={setIsSelectedFile}
                                        setShowEmoji={setShowEmoji}
                                        showEmoji={showEmoji}
                                        toggleMediaGroup={toggleMediaGroupFun}
                                      />
                                      <div className="wall-fltr-rght d-flex align-items">
                                        <button
                                          style={
                                            postLoading
                                              ? { cursor: 'not-allowed' }
                                              : { cursor: 'pointer' }
                                          }
                                          disabled={postLoading}
                                          className="accnt-btn ms-2"
                                          onClick={(e) => {
                                            makePost(e);
                                          }}>
                                          <i className="far fa-arrow-alt-circle-right me-1"></i>
                                          {postLoading ? 'Processing' : 'Post'}
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                )}

                                <PagePostLayout
                                  isPostLoading={loading}
                                  checkPost={checkPost}
                                  getPostListData={getPostListData}
                                  totalPages={totalPages}
                                  onIncrementPageNo={onIncrementPageNo}
                                  pageNum={pageNum}
                                  onPostListData={onPostListData}
                                  setCheckPost={setCheckPost}
                                  setLoadingState={setLoadingState}
                                  loadingState={loadingState}
                                  disableInputBox={disableInputBox}
                                  setDisableInputBox={setDisableInputBox}
                                  deleteFile={deleteFile}
                                  type={type}
                                />
                              </Col>
                              <Col xs={12} md={4}>
                                <div>
                                  <LikesCard
                                    pageData={pageData}
                                    onClick={() => {
                                      setUserType('members');

                                      setIsActiveClassState('members');
                                    }}
                                    userType={userType}
                                    setActive={setActive}
                                  />
                                </div>
                                <div>
                                  <WhatsNew />
                                </div>
                                <div>
                                  <Upcoming />
                                </div>
                                <MiniFooter />
                              </Col>
                            </Row>
                          </Tab.Pane>
                          <Tab.Pane eventKey="second">
                            {checkPost?.length !== 0 ? (
                              <PagePostLayout
                                isPostLoading={loading}
                                checkPost={checkPost}
                                getPostListData={getPostListData}
                                totalPages={totalPages}
                                onIncrementPageNo={onIncrementPageNo}
                                pageNum={pageNum}
                                onPostListData={onPostListData}
                                setCheckPost={setCheckPost}
                                setLoadingState={setLoadingState}
                                loadingState={loadingState}
                                disableInputBox={disableInputBox}
                                setDisableInputBox={setDisableInputBox}
                                deleteFile={deleteFile}
                                type={type}
                              />
                            ) : (
                              <NoDataFound text="No Videos found" />
                            )}
                          </Tab.Pane>
                          <Tab.Pane eventKey="third">
                            {checkPost?.length !== 0 ? (
                              <PagePostLayout
                                isPostLoading={loading}
                                checkPost={checkPost}
                                getPostListData={getPostListData}
                                totalPages={totalPages}
                                onIncrementPageNo={onIncrementPageNo}
                                pageNum={pageNum}
                                onPostListData={onPostListData}
                                setCheckPost={setCheckPost}
                                setLoadingState={setLoadingState}
                                loadingState={loadingState}
                                disableInputBox={disableInputBox}
                                setDisableInputBox={setDisableInputBox}
                                deleteFile={deleteFile}
                                type={type}
                              />
                            ) : (
                              <NoDataFound text="No Photos found" />
                            )}
                          </Tab.Pane>
                          <Tab.Pane eventKey="fourth">
                            <div className="likes-tab-sec">
                              <div className="likes-top-hd flex-wrap d-flex align-items-center justify-content-between">
                                <div>
                                  <Link
                                    to="#"
                                    className={activeClassState == 'members' ? 'active' : ''}
                                    onClick={() => {
                                      setUserType('members');
                                      getPeopleList('members');
                                      setIsActiveClassState('members');
                                    }}>
                                    Likes <span>{memberCount?.count_members}</span>
                                  </Link>
                                  {memberCount?.admin == 'admin' && (
                                    <Link
                                      className={
                                        activeClassState == 'admins' ? 'active ms-3' : 'ms-3'
                                      }
                                      to="#"
                                      onClick={() => {
                                        setUserType('admins');
                                        getPeopleList('admins');
                                        setIsActiveClassState('admins');
                                      }}>
                                      Admins <span>{memberCount?.count_admins}</span>
                                    </Link>
                                  )}
                                </div>
                                <div>
                                  <Form.Group className="search-card" controlId="formBasicEmail">
                                    <Form.Control
                                      type="search"
                                      placeholder="Search"
                                      onChange={(e) => getPeopleList(userType, e.target.value)}
                                    />
                                    <Button className="search-btn">
                                      <i className="fas fa-search"></i>
                                    </Button>
                                  </Form.Group>
                                </div>
                              </div>
                              {list?.length !== 0 ? (
                                <div className="like-page-card bg-white p-3">
                                  <Row>
                                    {list?.map((people, index) => {
                                      return (
                                        <Col md={6} key={index} id={'people-' + people?.id}>
                                          <Link to={`/user/timeline/${people?.encrypted_id}`}>
                                            <div className="like-card-detail d-flex">
                                              <div className="like-card-img">
                                                <Link to={`/user/timeline/${people?.encrypted_id}`}>
                                                  <img
                                                    src={
                                                      people?.avatar_url
                                                        ? people?.avatar_url
                                                        : require('../../../assets/images/default-male-avatar.png')
                                                    }
                                                    alt="img"
                                                  />
                                                </Link>
                                              </div>
                                              <div className="like-card-dis">
                                                <h4>
                                                  {' '}
                                                  {people?.first_name} {people?.last_name}
                                                </h4>
                                                {people?.work_experience?.length !== 0 && (
                                                  <p>
                                                    Working as{' '}
                                                    {people?.work_experience[0]?.designation} at{' '}
                                                    {people?.work_experience[0]?.organisation}
                                                  </p>
                                                )}

                                                {memberCount?.admin == 'admin' &&
                                                  userType == 'admins' && (
                                                    <>
                                                      {people?.role != 'admin' ? (
                                                        <Link
                                                          to="#"
                                                          className="me-2"
                                                          onClick={(e) =>
                                                            addAdmin(e, people?.id, 'admin')
                                                          }>
                                                          Make Admin
                                                        </Link>
                                                      ) : (
                                                        <Link
                                                          to="#"
                                                          className="me-2"
                                                          onClick={(e) =>
                                                            addAdmin(e, people?.id, 'editor')
                                                          }>
                                                          Make Editor
                                                        </Link>
                                                      )}

                                                      {memberCount?.admin !== 'admin' ? (
                                                        <Link
                                                          to="#"
                                                          onClick={(e) => {
                                                            handleRemove(e, people?.id, 'members');
                                                            if (
                                                              people?.encrypted_id ===
                                                              loggedinUserID
                                                            ) {
                                                              navigate('/user/pages');
                                                            }
                                                          }}>
                                                          Remove Member
                                                        </Link>
                                                      ) : (
                                                        ''
                                                      )}
                                                    </>
                                                  )}

                                                <>
                                                  <Link
                                                    to="#"
                                                    onClick={(e) => {
                                                      handleRemoveMember(
                                                        e,
                                                        people?.id,
                                                        'members',
                                                        people?.encrypted_id
                                                      );
                                                    }}>
                                                    Remove
                                                  </Link>
                                                </>
                                              </div>
                                            </div>
                                          </Link>
                                        </Col>
                                      );
                                    })}
                                  </Row>
                                </div>
                              ) : (
                                <NoDataFound text="Anyone not joined this group at the moment." />
                              )}
                            </div>
                          </Tab.Pane>
                          <Tab.Pane eventKey="fifth">
                            {' '}
                            <NoDataFound text="No recipient found. Try adding some friends." />
                          </Tab.Pane>
                          <Tab.Pane eventKey="sixth">
                            <div className="group-settings-card bg-white p-4">
                              <h4 className="mb-5">Page Settings</h4>
                              <div className="post-job-form">
                                <Form>
                                  <Form.Group
                                    as={Row}
                                    className="mb-4"
                                    controlId="formPlaintextEmail">
                                    <Form.Label column sm="3">
                                      Username:
                                    </Form.Label>
                                    <Col sm="9">
                                      <Form.Control
                                        type="text"
                                        defaultValue={genSetting?.username}
                                        readOnly
                                      />
                                    </Col>
                                  </Form.Group>

                                  <Form.Group
                                    as={Row}
                                    className="mb-4"
                                    controlId="formPlaintextPassword">
                                    <Form.Label column sm="3">
                                      Name:
                                    </Form.Label>
                                    <Col sm="9">
                                      <Form.Control
                                        type="text"
                                        placeholder="St Stephen School - Alumni Gro"
                                        name="name"
                                        onChange={handleChange}
                                        value={genSetting?.name}
                                      />
                                    </Col>

                                    <div
                                      className={`${clicked && validate().name ? '' : 'd-none'
                                        } danger-frm `}>
                                      {clicked && validate().name}
                                    </div>
                                  </Form.Group>

                                  <Form.Group
                                    as={Row}
                                    className="mb-4"
                                    controlId="formPlaintextPassword">
                                    <Form.Label column sm="3">
                                      Category :
                                    </Form.Label>
                                    <Col sm="9">
                                      <Form.Group className="mb-3">
                                        <Select
                                          className="basic-single"
                                          classNamePrefix="select"
                                          isDisabled={isDisabled}
                                          isLoading={isLoading}
                                          isClearable={isClearable}
                                          isRtl={isRtl}
                                          isSearchable={isSearchable}
                                          options={categoryOptions}
                                          isMulti={false}
                                          name="category_name"
                                          placeholder="Select Category"
                                          onChange={(e) => {
                                            setSelectedCategory(e);
                                          }}
                                          value={selectedCategory}
                                        />

                                        <div className={'danger-frm'}>
                                          {clicked && validate().category}
                                        </div>
                                      </Form.Group>
                                    </Col>
                                  </Form.Group>

                                  <Form.Group
                                    as={Row}
                                    className="mb-4"
                                    controlId="formPlaintextPassword">
                                    <Form.Label column sm="3">
                                      Description:
                                    </Form.Label>
                                    <Col sm="9">
                                      <Form.Control
                                        type="text"
                                        as="textarea"
                                        name="about"
                                        defaultValue={genSetting?.about}
                                        onChange={handleChange}
                                        placeholder="Write about your group..."
                                      />
                                    </Col>
                                  </Form.Group>
                                  <Form.Group
                                    as={Row}
                                    className="mb-4"
                                    controlId="formPlaintextPassword">
                                    <Form.Label column sm="3">
                                      Allow people to post on Page's timeline :
                                    </Form.Label>
                                    <Col sm="9">
                                      <Form.Select
                                        aria-label="Default select example"
                                        name="timeline_post_privacy"
                                        onChange={handleChange}
                                        value={genSetting?.timeline_post_privacy}>
                                        <option value="everyone">Yes</option>
                                        <option value="none">No</option>
                                      </Form.Select>
                                    </Col>
                                  </Form.Group>
                                  <Form.Group
                                    as={Row}
                                    className="mb-4"
                                    controlId="formPlaintextPassword">
                                    <Form.Label column sm="3">
                                      Allow people's to send messages to page :
                                    </Form.Label>
                                    <Col sm="9">
                                      <Form.Select
                                        aria-label="Default select example"
                                        name="message_privacy"
                                        value={genSetting?.message_privacy}
                                        onChange={handleChange}>
                                        <option value="everyone">Yes</option>
                                        <option value="none">No</option>
                                      </Form.Select>
                                    </Col>
                                  </Form.Group>
                                  <Form.Group as={Row} className="mb-4">
                                    <Form.Label column sm="3"></Form.Label>
                                    <Col sm="9">
                                      <Button
                                        className="main-btn-grn"
                                        onClick={(e) => {
                                          handleSave(e);
                                        }}>
                                        Save
                                      </Button>
                                    </Col>
                                  </Form.Group>
                                </Form>
                              </div>
                            </div>
                          </Tab.Pane>
                          <Tab.Pane eventKey="seven"></Tab.Pane>
                        </Tab.Content>
                      )}
                    </div>
                  </Tab.Container>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      {/* <FooterMain className={'colour-bg'} /> */}

      {/* {loading ? <Loader /> : ''} */}
    </>
  );
}
function readFile(file) {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => resolve(reader.result), false);
    reader.readAsDataURL(file);
  });
}

export default PagesDetail;
