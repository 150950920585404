import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import HeaderLogin from '../../commoncomponents/HeaderLogin';
import TopTabbings from './TopTabbings';
import { Button } from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';
import Select from 'react-select';
import Form from 'react-bootstrap/Form';
import Offcanvas from 'react-bootstrap/Offcanvas';
import useDocumentTitle from '../../../globals/useDocumentTitle';
import AsyncSelect from 'react-select/async';
import { loadMainCompanyIndustries } from '../../../super-admin/AdminHelper';
import { getYearDropDown } from '../../../globals/helper';
import { getInstituteDrop } from '../../../services/AuthServices';
import {
  addFriend,
  companyDropdown,
  findFriends,
  followFriend,
  getAvatarImage,
  getBusinessSkillDrop,
  getInterpersonalSkillDrop,
  getTechnicalSkillDrop,
  locationDetail
} from '../../../services/UserServices';
import showNotification from '../../../services/NotificationService';
import NoDataFound from '../../commoncomponents/NoDataFound';
import { ZERO } from '../../../globals/constants';
import Loader from '../../commoncomponents/loader/loader-large';

function FindFriend() {
  useDocumentTitle('Browse Directory | AlmaBay');

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [loading, setLoading] = useState(false);
  const [userType, setUserType] = useState('all');
  const [name, setName] = useState('');
  const [instituteName, setInstituteName] = useState('');
  const [gradYear, setGradYear] = useState([]);
  const [companyName, setCompanyName] = useState([]);
  const [locations, setLocations] = useState([]);
  const [industryName, setIndustryName] = useState([]);
  const [techSkill, setTechSkill] = useState([]);
  const [businessSkill, setBusinessSkill] = useState([]);
  const [interPersonalSkill, setInterPersonalSkill] = useState([]);
  const [yearOptions, setYearOptions] = useState();
  const [yearSearch, setYearSearch] = useState('');
  const [techSearch, setTechSearch] = useState('');
  const [businessSearch, setBusinessSearch] = useState('');
  const [personalSearch, setPersonalSearch] = useState('');
  const [friendData, setFriendData] = useState([]);
  const [friendListCount, setFriendListCount] = useState(null);
  const [userImage, setUserImage] = useState('');
  let graduationYear = gradYear?.map((item) => item?.value);
  let ctaegoryId = industryName?.map((item) => item?.id);
  let techId = techSkill?.map((item) => item?.value);
  let businessIds = businessSkill?.map((item) => item?.value);
  let personalIds = interPersonalSkill?.map((item) => item?.value);
  const [companySearch, setCompanySearch] = useState('');
  let companyIds = companyName?.map((item) => item?.id);
  let locationIds = locations?.map((item) => item?.id);
  const [shimmerLoad, setShimmerLoad] = useState(false);
  const [inputMsg, setInputMsg] = useState('Please enter atleast 2 characters');

  useEffect(() => {
    getYearDropDown(setYearOptions);
    loadInstituteOptions();
    loadTechOptions();
    loadBusinessOptions();
    loadPersonalOptions();
    loadCompanyOption();
    loadLocationOptions();
    getFriends();
    window.scrollTo(0, 0);
  }, []);

  const loadInstituteOptions = async (inputValue) => {
    if (inputValue?.length >= 2) {
      return getInstituteDrop(inputValue).then((res) => {
        if (res?.status === 200) {
          let tempArr = [];
          if (res?.data?.length) {
            const data = res?.data;
            data?.map((data) => {
              tempArr.push({
                id: data?.institute_id,
                label: data?.institutename,
                value: data?.institutename
              });
            });
          }
          if (res?.data == '') {
            setInputMsg('No matches found');
          }
          return tempArr;
        }
      });
    }
  };

  const loadTechOptions = async (inputValue) => {
    return getTechnicalSkillDrop(inputValue).then((res) => {
      if (res?.status === 200) {
        let data = res?.data?.data;
        let arr = [];
        data?.map((data) => {
          arr.push({ label: data.name, value: data.id });
        });
        return arr;
      }
    });
  };
  const loadBusinessOptions = async (inputValue) => {
    return getBusinessSkillDrop(inputValue).then((res) => {
      if (res?.status === 200) {
        let data = res?.data?.data;
        let arr = [];
        data?.map((data) => {
          arr.push({ label: data.name, value: data.id });
        });
        return arr;
      }
    });
  };
  const loadPersonalOptions = async (inputValue) => {
    return getInterpersonalSkillDrop(inputValue).then((res) => {
      if (res?.status === 200) {
        let data = res?.data?.data;
        let arr = [];
        data?.map((data) => {
          arr.push({ label: data.name, value: data.id });
        });
        return arr;
      }
    });
  };

  const loadCompanyOption = async (inputValue = '') => {
    return companyDropdown(inputValue, '', '').then((res) => {
      if (res?.status === 200) {
        let tempArr = [];
        if (res?.data?.results) {
          const data = res?.data?.results;
          data?.map((data) => {
            tempArr.push({
              id: data?.id,
              label: data?.name,
              value: data?.name
            });
          });
        }
        return tempArr;
      }
    });
  };

  const loadLocationOptions = async (inputValue = 'Mohali') => {
    return locationDetail(inputValue).then((res) => {
      if (res?.status === 200) {
        let tempArr = [];
        if (res?.data?.data) {
          const data = res?.data?.data;
          data?.map((data) => {
            tempArr.push({
              id: data?.place_id,
              label: data?.description,
              value: data?.description
            });
          });
        }
        return tempArr;
      }
    });
  };

  const getFriends = (offsetVal = '') => {
    if (loading == false) {
      setShimmerLoad(true);
    }

    findFriends(
      userType,
      name.trim(),
      instituteName?.id,
      graduationYear?.toString(),
      companyIds?.toString(),
      locationIds?.toString(),
      ctaegoryId?.toString(),
      techId?.toString(),
      businessIds?.toString(),
      personalIds.toString(),
      offsetVal
    ).then((res) => {
      if (res?.status == 200) {
        setLoading(false);
        setShimmerLoad(false);
        if (offsetVal > 0) {
          setFriendData({
            ...friendData,
            users: [...friendData.users, ...res?.data?.detail.users]
          });
        } else {
          setFriendData(res?.data?.detail);
        }
        setFriendListCount(res?.data?.detail?.total);
        if (res?.data?.detail?.users) {
          let arr = [];
          res?.data?.detail?.users?.map((data) => {
            getAvatarImage(data?.avatar_id).then((res) => {
              if (res?.status == 200) {
                if (data?.avatar_id != 0) {
                  arr[data?.avatar_id] = res?.data?.detail[0].path;
                } else {
                  arr[data?.avatar_id] = res?.data?.detail;
                }
              }
              setUserImage({ ...userImage, arr });
            });
          });
        }
      }
    });
  };

  const resquestHandler = (e, id, type, unfriend) => {
    e.preventDefault();
    setLoading(true);
    if (type == 'friend') {
      let body = {
        following_id: id
      };
      if (unfriend) {
        if (confirm('Do you want to remove request ?') === true) {
          addFriend(body).then((res) => {
            if (res?.status == 200) {
              getFriends();
            } else {
              showNotification('success', res?.data?.message);
            }
          });
        }
      } else {
        addFriend(body).then((res) => {
          if (res?.status == 200) {
            getFriends();
          } else {
            showNotification('success', res?.data?.message);
          }
        });
      }
    } else {
      let body = {
        following_id: id
      };
      followFriend(body).then((res) => {
        if (res?.status == 200) {
          getFriends();
        } else {
          showNotification('success', res?.data?.message);
        }
      });
    }
  };

  const resetFilterItems = () => {
    setName('');
    setUserType('all');
    setInstituteName('');
    setGradYear([]);
    setCompanyName([]);
    setLocations([]);
    setIndustryName([]);
    setTechSkill([]);
    setBusinessSkill([]);
    setInterPersonalSkill([]);
    setShimmerLoad(true);
    findFriends().then((res) => {
      setLoading(false);
      if (res?.status == 200) {
        setShimmerLoad(false);
        setFriendData(res?.data?.detail);
        setFriendListCount(res?.data?.detail?.total);
        if (res?.data?.detail?.users) {
          let arr = [];
          res?.data?.detail?.users?.map((data) => {
            getAvatarImage(data?.avatar_id).then((res) => {
              if (res?.status == 200) {
                if (data?.avatar_id != 0) {
                  arr[data?.avatar_id] = res?.data?.detail[0].path;
                } else {
                  arr[data?.avatar_id] = res?.data?.detail;
                }
              }
              setUserImage({ ...userImage, arr });
            });
          });
        }
      }
    });
  };

  const handleClick = () => {
    setLoading(true);
    getFriends(friendData?.users?.length);
  };

  return (
    <>
      <HeaderLogin />
      <div className="header-lnk-feeds">
        <div className="container">
          <TopTabbings state={'social'} />
        </div>
      </div>
      <section className="feed-sec bg-grey py-4">
        <div className="container">
          <Row>
            <Col xs={12} lg={12}>
              <div className="find-friend-count mb-4">
                <span className="friend-count-icn">
                  <i className="fas fa-user-plus"></i>
                </span>
                <h3>
                  {' '}
                  {!loading && ` Found  ${friendData?.total ? friendData?.total : ZERO}  People`}
                </h3>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={12} lg={3} md={12}>
              <div className="fiter-card d-none d-lg-block ">
                <div className="filter-hd-card d-flex align-items-center justify-content-between">
                  <div className="filter-hd">
                    <h4>Filter By</h4>
                  </div>
                  <div className="filter-btn">
                    <Link to="#">
                      <i className="fas fa-filter"></i>
                    </Link>
                    <Link to="#">
                      <i className="fas fa-sync-alt"></i>
                    </Link>
                  </div>
                </div>
                <div className="filter-select-lst">
                  <Accordion
                    defaultActiveKey={['1', '2', '3', '4', '5', '6', '7', '8', '9', '10']}
                    alwaysOpen>
                    <Accordion.Item eventKey="1">
                      <Accordion.Header>User Type</Accordion.Header>
                      <Accordion.Body>
                        {['radio'].map((type) => (
                          <div className="mt-0" key={`inline-${type}`}>
                            <Form.Check
                              label="All Users"
                              name="group1"
                              type={type}
                              value={userType}
                              checked={userType == 'all' ? true : false}
                              id={`inline-${type}-3`}
                              className="mb-1"
                              onChange={() => setUserType('all')}
                            />
                            <Form.Check
                              label="All Alumni"
                              name="group1"
                              type={type}
                              checked={userType == 'alumni' ? true : false}
                              id={`inline-${type}-4`}
                              className="mb-1"
                              onChange={() => setUserType('alumni')}
                            />
                            <Form.Check
                              label="Connected Alumni"
                              name="group1"
                              type={type}
                              checked={userType == 'connected' ? true : false}
                              id={`inline-${type}-5`}
                              className="mb-1"
                              onChange={() => setUserType('connected')}
                            />
                          </div>
                        ))}
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                      <Accordion.Header>Name</Accordion.Header>
                      <Accordion.Body>
                        <Form.Group className="mb-0" controlId="formBasicName">
                          <Form.Control
                            type="text"
                            placeholder="Search By Name"
                            onChange={(e) => setName(e.target.value)}
                            value={name}
                          />
                        </Form.Group>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="3">
                      <Accordion.Header>Institute Name</Accordion.Header>
                      <Accordion.Body>
                        <AsyncSelect
                          cacheOptions
                          components={{
                            DropdownIndicator: () => null,
                            IndicatorSeparator: () => null
                          }}
                          onMenuOpen={() => setInputMsg('Please enter atleast 2 characters.')}
                          className="basic-single"
                          classNamePrefix="select"
                          isSearchable={true}
                          isClearable
                          name="institute_id"
                          loadOptions={loadInstituteOptions}
                          placeholder="Select Institute"
                          value={instituteName}
                          onChange={(e) => {
                            setInstituteName(e);
                          }}
                          onInputChange={(newValue) => {
                            if (newValue?.length <= 2) {
                              setInputMsg('Please enter atleast 2 characters.');
                            }
                          }}
                          noOptionsMessage={() => inputMsg}
                          styles={{
                            control: (base) => ({
                              ...base,
                              border: '0 !important',
                              // This line disable the  border
                              boxShadow: '0 !important',
                              '&:hover': {
                                border: '0 !important'
                              }
                            })
                          }}
                        />
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="4">
                      <Accordion.Header>Year of Graduation</Accordion.Header>
                      <Accordion.Body>
                        <Select
                          className="basic-single"
                          classNamePrefix="select"
                          placeholder="Search By Graduation Year"
                          isSearchable
                          isClearable
                          isMulti
                          options={yearOptions}
                          value={gradYear}
                          name="to_year"
                          onChange={setGradYear}
                          menuIsOpen={yearSearch !== '' ? true : false}
                          onInputChange={(newValue) => setYearSearch(newValue)}
                          components={{
                            DropdownIndicator: () => null,
                            IndicatorSeparator: () => null
                          }}
                        />
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="5">
                      <Accordion.Header>Company Name</Accordion.Header>
                      <Accordion.Body>
                        <AsyncSelect
                          isMulti
                          cacheOptions
                          defaultOptions
                          value={companyName}
                          menuIsOpen={companySearch !== '' ? true : false}
                          getOptionLabel={(e) => e.label}
                          getOptionValue={(e) => e.value}
                          loadOptions={loadCompanyOption}
                          onInputChange={(newValue) => setCompanySearch(newValue)}
                          className="basic-single mt-1"
                          name="location"
                          onChange={setCompanyName}
                          components={{
                            DropdownIndicator: () => null,
                            IndicatorSeparator: () => null
                          }}
                        />
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="6">
                      <Accordion.Header>Location</Accordion.Header>
                      <Accordion.Body>
                        <AsyncSelect
                          isMulti
                          cacheOptions
                          defaultOptions
                          value={locations}
                          getOptionLabel={(e) => e.label}
                          getOptionValue={(e) => e.value}
                          loadOptions={loadLocationOptions}
                          className="basic-single mt-1"
                          name="location"
                          onChange={setLocations}
                          components={{
                            DropdownIndicator: () => null,
                            IndicatorSeparator: () => null
                          }}
                        />
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="7">
                      <Accordion.Header>Industry Name</Accordion.Header>
                      <Accordion.Body>
                        <AsyncSelect
                          isMulti
                          cacheOptions
                          defaultOptions
                          value={industryName}
                          getOptionLabel={(e) => e.label}
                          getOptionValue={(e) => e.value}
                          loadOptions={loadMainCompanyIndustries}
                          className="basic-single mt-1"
                          name="location"
                          onChange={setIndustryName}
                          components={{
                            DropdownIndicator: () => null,
                            IndicatorSeparator: () => null
                          }}
                        />
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="8">
                      <Accordion.Header>Technical Skills</Accordion.Header>
                      <Accordion.Body>
                        <AsyncSelect
                          isMulti
                          cacheOptions
                          defaultOptions
                          value={techSkill}
                          getOptionLabel={(e) => e.label}
                          getOptionValue={(e) => e.value}
                          loadOptions={loadTechOptions}
                          className="basic-single mt-1"
                          name="location"
                          onChange={setTechSkill}
                          components={{
                            DropdownIndicator: () => null,
                            IndicatorSeparator: () => null
                          }}
                          menuIsOpen={techSearch !== '' ? true : false}
                          onInputChange={(newValue) => setTechSearch(newValue)}
                        />
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="9">
                      <Accordion.Header>Business Skills</Accordion.Header>
                      <Accordion.Body>
                        <AsyncSelect
                          isMulti
                          cacheOptions
                          defaultOptions
                          value={businessSkill}
                          getOptionLabel={(e) => e.label}
                          getOptionValue={(e) => e.value}
                          loadOptions={loadBusinessOptions}
                          className="basic-single mt-1"
                          name="location"
                          onChange={setBusinessSkill}
                          components={{
                            DropdownIndicator: () => null,
                            IndicatorSeparator: () => null
                          }}
                          menuIsOpen={businessSearch !== '' ? true : false}
                          onInputChange={(newValue) => setBusinessSearch(newValue)}
                        />
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="10">
                      <Accordion.Header>Inter-Personal Skills</Accordion.Header>
                      <Accordion.Body>
                        <AsyncSelect
                          isMulti
                          cacheOptions
                          defaultOptions
                          value={interPersonalSkill}
                          getOptionLabel={(e) => e.label}
                          getOptionValue={(e) => e.value}
                          loadOptions={loadPersonalOptions}
                          className="basic-single mt-1"
                          name="location"
                          onChange={setInterPersonalSkill}
                          components={{
                            DropdownIndicator: () => null,
                            IndicatorSeparator: () => null
                          }}
                          menuIsOpen={personalSearch !== '' ? true : false}
                          onInputChange={(newValue) => setPersonalSearch(newValue)}
                        />
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                  <div className="sider-bar-filter p-3 text-end">
                    <button
                      className="accnt-btn"
                      type="button"
                      onClick={(e) => {
                        e.preventDefault();
                        getFriends();
                      }}>
                      Search
                    </button>
                    <button onClick={resetFilterItems} className="dark-blu-btn ms-2">
                      Reset
                    </button>
                  </div>
                </div>
              </div>
              <Button
                className="mobile-filter-btn d-block d-lg-none "
                variant="primary"
                onClick={handleShow}>
                <i className="fas fa-filter"></i>
              </Button>

              <Offcanvas show={show} onHide={handleClose} className="mobile-filter">
                <Offcanvas.Body>
                  <div className="fiter-card ">
                    <div className="filter-hd-card d-flex align-items-center justify-content-between">
                      <div className="filter-hd">
                        <h4>Filter By</h4>
                      </div>
                      <div className="filter-btn">
                        <Link to="#">
                          <i className="fas fa-filter"></i>
                        </Link>
                        <Link to="#">
                          <i className="fas fa-sync-alt"></i>
                        </Link>
                      </div>
                    </div>
                    <div className="filter-select-lst">
                      <Accordion alwaysOpen flush>
                        <Accordion.Item eventKey="1">
                          <Accordion.Header>User Type</Accordion.Header>
                          <Accordion.Body>
                            {['radio'].map((type) => (
                              <div className="mt-0" key={`inline-${type}`}>
                                <Form.Check
                                  label="All Users"
                                  name="group1"
                                  type={type}
                                  defaultChecked
                                  id={`inline-${type}-3`}
                                  className="mb-1"
                                  onChange={() => setUserType('all')}
                                />
                                <Form.Check
                                  label="All Alumni"
                                  name="group1"
                                  type={type}
                                  id={`inline-${type}-4`}
                                  className="mb-1"
                                  onChange={() => setUserType('alumni')}
                                />
                                <Form.Check
                                  label="Connected Alumni"
                                  name="group1"
                                  type={type}
                                  id={`inline-${type}-5`}
                                  className="mb-1"
                                  onChange={() => setUserType('connected')}
                                />
                              </div>
                            ))}
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="2">
                          <Accordion.Header>Name</Accordion.Header>
                          <Accordion.Body>
                            <Form.Group className="mb-0" controlId="formBasicName">
                              <Form.Control
                                type="text"
                                placeholder="Search By Name"
                                onChange={(e) => setName(e.target.value)}
                              />
                            </Form.Group>
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="3">
                          <Accordion.Header>Institute Name</Accordion.Header>
                          <Accordion.Body>
                            <AsyncSelect
                              cacheOptions
                              components={{
                                DropdownIndicator: () => null,
                                IndicatorSeparator: () => null
                              }}
                              className="basic-single"
                              classNamePrefix="select"
                              isSearchable={true}
                              isClearable
                              name="institute_id"
                              loadOptions={loadInstituteOptions}
                              placeholder="Select Intitute"
                              value={instituteName}
                              onChange={(e) => {
                                setInstituteName(e);
                              }}
                            />
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="4">
                          <Accordion.Header>Year of Graduation</Accordion.Header>
                          <Accordion.Body>
                            <Select
                              className="basic-single"
                              classNamePrefix="select"
                              placeholder="Search By Graduation Year"
                              isSearchable
                              isClearable
                              isMulti
                              options={yearOptions}
                              value={gradYear}
                              name="to_year"
                              onChange={setGradYear}
                              menuIsOpen={yearSearch !== '' ? true : false}
                              onInputChange={(newValue) => setYearSearch(newValue)}
                              components={{
                                DropdownIndicator: () => null,
                                IndicatorSeparator: () => null
                              }}
                            />
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="5">
                          <Accordion.Header>Company Name</Accordion.Header>
                          <Accordion.Body>
                            <AsyncSelect
                              cacheOptions
                              defaultOptions
                              value={companyName}
                              menuIsOpen={companySearch !== '' ? true : false}
                              getOptionLabel={(e) => e.label}
                              getOptionValue={(e) => e.value}
                              loadOptions={loadCompanyOption}
                              onInputChange={(newValue) => setCompanySearch(newValue)}
                              className="basic-single mt-1"
                              name="location"
                              onChange={setCompanyName}
                              components={{
                                DropdownIndicator: () => null,
                                IndicatorSeparator: () => null
                              }}
                            />
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="6">
                          <Accordion.Header>Location</Accordion.Header>
                          <Accordion.Body>
                            <AsyncSelect
                              isMulti
                              cacheOptions
                              defaultOptions
                              value={locations}
                              getOptionLabel={(e) => e.label}
                              getOptionValue={(e) => e.value}
                              loadOptions={loadLocationOptions}
                              className="basic-single mt-1"
                              name="location"
                              onChange={setLocations}
                              components={{
                                DropdownIndicator: () => null,
                                IndicatorSeparator: () => null
                              }}
                            />
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="7">
                          <Accordion.Header>Industry Name</Accordion.Header>
                          <Accordion.Body>
                            <AsyncSelect
                              isMulti
                              cacheOptions
                              defaultOptions
                              value={industryName}
                              getOptionLabel={(e) => e.label}
                              getOptionValue={(e) => e.value}
                              loadOptions={loadMainCompanyIndustries}
                              className="basic-single mt-1"
                              name="location"
                              onChange={setIndustryName}
                              components={{
                                DropdownIndicator: () => null,
                                IndicatorSeparator: () => null
                              }}
                            />
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="8">
                          <Accordion.Header>Technical Skills</Accordion.Header>
                          <Accordion.Body>
                            <AsyncSelect
                              isMulti
                              cacheOptions
                              defaultOptions
                              value={techSkill}
                              getOptionLabel={(e) => e.label}
                              getOptionValue={(e) => e.value}
                              loadOptions={loadTechOptions}
                              className="basic-single mt-1"
                              name="location"
                              onChange={setTechSkill}
                              components={{
                                DropdownIndicator: () => null,
                                IndicatorSeparator: () => null
                              }}
                              menuIsOpen={techSearch !== '' ? true : false}
                              onInputChange={(newValue) => setTechSearch(newValue)}
                            />
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="9">
                          <Accordion.Header>Business Skills</Accordion.Header>
                          <Accordion.Body>
                            <AsyncSelect
                              isMulti
                              cacheOptions
                              defaultOptions
                              value={businessSkill}
                              getOptionLabel={(e) => e.label}
                              getOptionValue={(e) => e.value}
                              loadOptions={loadBusinessOptions}
                              className="basic-single mt-1"
                              name="location"
                              onChange={setBusinessSkill}
                              components={{
                                DropdownIndicator: () => null,
                                IndicatorSeparator: () => null
                              }}
                              menuIsOpen={businessSearch !== '' ? true : false}
                              onInputChange={(newValue) => setBusinessSearch(newValue)}
                            />
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="10">
                          <Accordion.Header>Inter-Personal Skills</Accordion.Header>
                          <Accordion.Body>
                            <AsyncSelect
                              isMulti
                              cacheOptions
                              defaultOptions
                              value={interPersonalSkill}
                              getOptionLabel={(e) => e.label}
                              getOptionValue={(e) => e.value}
                              loadOptions={loadPersonalOptions}
                              className="basic-single mt-1"
                              name="location"
                              onChange={setInterPersonalSkill}
                              components={{
                                DropdownIndicator: () => null,
                                IndicatorSeparator: () => null
                              }}
                              menuIsOpen={personalSearch !== '' ? true : false}
                              onInputChange={(newValue) => setPersonalSearch(newValue)}
                            />
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                      <div className="sider-bar-filter p-3 text-end">
                        <button
                          className="accnt-btn"
                          type="button"
                          onClick={(e) => {
                            e.preventDefault();
                            getFriends();
                          }}>
                          Search
                        </button>
                        <button className="dark-blu-btn ms-2">Reset</button>
                      </div>
                    </div>
                  </div>
                </Offcanvas.Body>
              </Offcanvas>
            </Col>

            <Col xs={12} lg={9} md={12}>
              {shimmerLoad && !loading ? (
                <>
                  {' '}
                  <div className="find-friend-list loading-skeleton">
                    <Row>
                      {Array.from(Array(4), () => {
                        return (
                          <Col xs={12} md={12} lg={6}>
                            <div className="find-friend-card d-flex align-items-top">
                              <div className="find-friend-icn me-3">
                                <Link>
                                  <img
                                    src={require('../../../assets/images/default-male-avatar.png')}
                                    alt="img"
                                  />
                                </Link>
                              </div>
                              <div className="find-friend-dis">
                                <Link>
                                  <h4> </h4>
                                </Link>

                                <p>
                                  <i className="fas fa-graduation-cap me-2"></i>
                                </p>
                                <p>
                                  <i className="fas fa-map-marker-alt me-2"></i>
                                </p>

                                <div className="find-friend-action">
                                  <Button className="add-frnd-btn me-1">
                                    <i className="fas fa-plus me-2"></i>
                                  </Button>
                                  <Button className="add-frnd-btn me-1">
                                    <i className="fas fa-plus me-2"></i>
                                  </Button>
                                </div>
                              </div>
                            </div>
                          </Col>
                        );
                      })}
                    </Row>
                  </div>
                </>
              ) : (
                <>
                  {' '}
                  {friendData?.users?.length !== 0 ? (
                    <div className="find-friend-list ">
                      <Row>
                        {friendData?.users?.map((data, index) => {
                          return (
                            <Col xs={12} md={12} lg={6} key={index}>
                              <div className="find-friend-card d-flex align-items-top">
                                <div className="find-friend-icn me-3">
                                  <Link to={`/user/timeLine/${data?.encrypt_userid}`}>
                                    <img
                                      src={
                                        userImage
                                          ? userImage['arr'][data?.avatar_id]
                                          : require('../../../assets/images/default-male-avatar.png')
                                      }
                                      // src={require('../../../assets/images/default-male-avatar.png')}
                                      alt="img"
                                    />
                                  </Link>
                                </div>
                                <div className="find-friend-dis">
                                  <Link to={`/user/timeLine/${data?.encrypt_userid}`}>
                                    <h4>{data?.name}</h4>
                                  </Link>
                                  {data?.institute_name && (
                                    <p>
                                      <i className="fas fa-graduation-cap me-2"></i>
                                      {data?.institute_name}{' '}
                                      {data?.to_year != 0 ? (
                                        <>in {data?.to_year}</>
                                      ) : (
                                        <>(Currently Studying)</>
                                      )}
                                    </p>
                                  )}
                                  {data?.location && (
                                    <p>
                                      <i className="fas fa-map-marker-alt me-2"></i>
                                      {data?.location}
                                    </p>
                                  )}
                                  <div className="find-friend-action">
                                    {data?.follow_status == '' || data?.follow_status == null ? (
                                      <Button
                                        className="add-frnd-btn me-1"
                                        onClick={(e) => {
                                          resquestHandler(e, data?.user_id, 'friend');
                                        }}>
                                        <i className="fas fa-plus me-2"></i>
                                        Add Friend
                                      </Button>
                                    ) : data?.follow_status == 0 ? (
                                      <Button
                                        className="add-frnd-btn me-1"
                                        onClick={(e) => {
                                          resquestHandler(e, data?.user_id, 'friend', 'unfriend');
                                        }}>
                                        <i className="fas fa-check me-2"></i>
                                        Requested
                                      </Button>
                                    ) : (
                                      <Button
                                        className="add-frnd-btn me-1"
                                        onClick={(e) => {
                                          resquestHandler(e, data?.user_id, 'friend');
                                        }}>
                                        <i className="fas fa-plus me-2"></i>
                                        Unfriend
                                      </Button>
                                    )}
                                    {data?.mentor_status == '' || data?.mentor_status == null ? (
                                      <Button
                                        className="follow-frnd-btn me-1"
                                        onClick={(e) => {
                                          resquestHandler(e, data?.user_id, 'mentor');
                                        }}>
                                        <i className="fas fa-plus me-2"></i>
                                        Follow
                                      </Button>
                                    ) : (
                                      <Button
                                        className="follow-frnd-btn me-1"
                                        onClick={(e) => {
                                          resquestHandler(e, data?.user_id, 'mentor');
                                        }}>
                                        Unfollow
                                      </Button>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </Col>
                          );
                        })}
                      </Row>

                      {friendListCount !== null &&
                        friendData?.users?.length !== friendListCount && (
                          <div className="text-center">
                            <button className="more-suggestion" onClick={handleClick}>
                              More Suggestions
                            </button>
                          </div>
                        )}
                    </div>
                  ) : (
                    <NoDataFound text="Oops, people with current selection not available at the moment." />
                  )}
                </>
              )}
            </Col>
          </Row>
        </div>
      </section>

      {loading && <Loader />}
    </>
  );
}

export default FindFriend;
