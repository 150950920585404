import { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import { confirmAddress, getConfirmAddress } from '../../../services/ShopServices';
import { restrictAlpha } from '../../../globals/constants';
import AsyncSelect from 'react-select/async';
import { loadCountryOption } from '../../../globals/helper';
import Loader from '../../commoncomponents/loader/loader-large';
import makeAnimated from 'react-select/animated';
export default function AddressDetailsCheckout({
  activeTab,
  nextStep,
  setDisabledStep,
  setAddressMark
}) {
  const [checkCred, setCheckCred] = useState(false);
  const [country, setCountry] = useState();

  const [loading, setLoading] = useState(false);

  const [addressDetail, setAddressDetail] = useState({
    pincode: '',
    fname: '',
    lname: '',
    address: '',
    landmark: '',
    city: '',
    state: '',
    mobile: ''
  });
  const [stateRadio, setStateRadio] = useState('Home');

  useEffect(() => {
    confirmAddressFunc();
  }, []);
  const confirmAddressFunc = () => {
    setLoading(true);
    getConfirmAddress().then((res) => {
      setLoading(false);
      if (res?.status === 200) {
        setLoading(false);
        const addressData = res?.data?.data?.addressInfo;
        setAddressDetail({
          pincode: addressData?.pincode,
          fname: addressData?.fname,
          lname: addressData?.lname,
          address: addressData?.address,
          landmark: addressData?.landmark,
          city: addressData?.city,
          state: addressData?.state,

          // country_name:addressData?.country_name,
          mobile: addressData?.mobile
        });
        setStateRadio(addressData?.address_type);

        setCountry({
          id: addressData?.country,
          label: addressData?.country_name,
          value: addressData?.country_name
        });
      }
    });
  };

  const handleInputData = (input) => (e) => {
    const { value } = e.target;
    if (e.target.name == 'mobile') {
      if (e.target.value?.length > 10) {
        return false;
      } else {
        setAddressDetail((prevState) => ({
          ...prevState,
          [input]: value
        }));
      }
    }
    setAddressDetail((prevState) => ({
      ...prevState,
      [input]: value
    }));
  };

  const loginValidate = (values) => {
    let errors = {};
    const regex = /^[a-z]+$/i;
    if (!values.pincode) {
      errors.pincode = 'Please enter your pin code';
    } else if (values.pincode.trim().length < 5) {
      errors.pincode = 'Please enter at least 5 characters.';
    }
    if (!values.fname?.trim()) {
      errors.fname = 'Please enter your first name';
    } else if (!regex.test(values.fname)) {
      errors.fname = 'Letters only please';
    }
    if (!values.lname) {
      errors.lname = 'Please enter your last  name';
    } else if (!regex.test(values.lname)) {
      errors.lname = 'Letters only please';
    }
    if (!values.address) {
      errors.address = 'Please enter your address';
    } else if (values.address.trim().length < 15) {
      errors.address = 'Please enter at least 15 characters.';
    }
    if (!values.landmark?.trim()) {
      errors.landmark = 'Please enter your landmark';
    }
    if (!values.city?.trim()) {
      errors.city = 'Please enter your city';
    }
    if (!values.state?.trim()) {
      errors.state = 'Please enter your state';
    }
    if (!country) {
      errors.country = 'Please enter your country';
    }
    if (!values.mobile) {
      errors.mobile = 'Please enter your mobile';
    }

    return errors;
  };

  const submitFormData = (e) => {
    e.preventDefault();
    setCheckCred(true);
    if (!Object.keys(loginValidate(addressDetail)).length) {
      let body = {
        addressType: 'createAddress',
        pincode: addressDetail?.pincode,
        fname: addressDetail?.fname,
        lname: addressDetail?.lname,
        address: addressDetail?.address,
        landmark: addressDetail?.landmark,
        city: addressDetail?.city,
        state: addressDetail?.state,
        country: country && country?.id,
        mobile: addressDetail?.mobile,
        address_type: stateRadio ? stateRadio : 'Home'
      };

      confirmAddress(body).then((res) => {
        if (res?.status === 200) {
          activeTab('third');
          setAddressMark(true);
          setDisabledStep(true);
          nextStep();
        }
      });
    }
  };
  const animatedComponents = makeAnimated();
  return (
    <div className="confirm-address">
      <div className="confrm-address-hd mb-4">
        <h4>Confirm Address</h4>
        <p className="test-confirm-address">We will deliver your order here</p>
      </div>
      <div className="confirm-addrs-card">
        <Form>
          <Form.Group as={Row} className="mb-4" controlId="formPlaintextZip">
            <Form.Label column sm="2">
              Zip Code
            </Form.Label>
            <Col sm="6">
              <Form.Control
                type="text"
                name="pincode"
                onChange={handleInputData('pincode')}
                onKeyPress={(e) => restrictAlpha(e)}
                value={addressDetail?.pincode}
              />
              {checkCred && loginValidate(addressDetail).pincode ? (
                <span
                  className={
                    `${checkCred && loginValidate(addressDetail).pincode}`
                      ? 'login-error'
                      : 'd-none'
                  }
                >
                  {checkCred && loginValidate(addressDetail)?.pincode}
                </span>
              ) : (
                ''
              )}
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-4" controlId="formPlaintextname">
            <Form.Label column sm="2">
              First Name
            </Form.Label>
            <Col sm="6">
              <Form.Control
                type="text"
                name="fname"
                defaultValue={addressDetail?.fname}
                onChange={handleInputData('fname')}
              />
              {checkCred && loginValidate(addressDetail).fname ? (
                <span
                  className={
                    `${checkCred && loginValidate(addressDetail).fname}` ? 'login-error' : 'd-none'
                  }
                >
                  {checkCred && loginValidate(addressDetail)?.fname}
                </span>
              ) : (
                ''
              )}
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-4" controlId="formPlaintextname">
            <Form.Label column sm="2">
              Last Name
            </Form.Label>
            <Col sm="6">
              <Form.Control
                type="text"
                name="lname"
                value={addressDetail?.lname}
                onChange={handleInputData('lname')}
              />
              {checkCred && loginValidate(addressDetail).lname ? (
                <span
                  className={
                    `${checkCred && loginValidate(addressDetail).lname}` ? 'login-error' : 'd-none'
                  }
                >
                  {checkCred && loginValidate(addressDetail)?.lname}
                </span>
              ) : (
                ''
              )}
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-4" controlId="formPlaintextaddress">
            <Form.Label column sm="2">
              Address
            </Form.Label>
            <Col sm="6">
              <textarea
                type="text"
                className="form-control"
                id="yourmessage"
                name="address"
                rows={4}
                value={addressDetail?.address}
                onChange={handleInputData('address')}
              ></textarea>
              {checkCred && loginValidate(addressDetail).address ? (
                <span
                  className={
                    `${checkCred && loginValidate(addressDetail).address}`
                      ? 'login-error'
                      : 'd-none'
                  }
                >
                  {checkCred && loginValidate(addressDetail)?.address}
                </span>
              ) : (
                ''
              )}
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-4" controlId="formPlaintextmark">
            <Form.Label column sm="2">
              Landmark
            </Form.Label>
            <Col sm="6">
              <Form.Control
                type="text"
                name="landmark"
                onChange={handleInputData('landmark')}
                value={addressDetail?.landmark}
              />
              {checkCred && loginValidate(addressDetail).landmark ? (
                <span
                  className={
                    `${checkCred && loginValidate(addressDetail).landmark}`
                      ? 'login-error'
                      : 'd-none'
                  }
                >
                  {checkCred && loginValidate(addressDetail)?.landmark}
                </span>
              ) : (
                ''
              )}
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-4" controlId="formPlaintextcity">
            <Form.Label column sm="2">
              City
            </Form.Label>
            <Col sm="6">
              <Form.Control
                type="text"
                name="city"
                onChange={handleInputData('city')}
                value={addressDetail?.city}
              />
              {checkCred && loginValidate(addressDetail).city ? (
                <span
                  className={
                    `${checkCred && loginValidate(addressDetail).city}` ? 'login-error' : 'd-none'
                  }
                >
                  {checkCred && loginValidate(addressDetail)?.city}
                </span>
              ) : (
                ''
              )}
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-4" controlId="formPlaintextstate">
            <Form.Label column sm="2">
              State
            </Form.Label>
            <Col sm="6">
              <Form.Control
                type="text"
                name="state"
                onChange={handleInputData('state')}
                value={addressDetail?.state}
              />
              {checkCred && loginValidate(addressDetail).state ? (
                <span
                  className={
                    `${checkCred && loginValidate(addressDetail).state}` ? 'login-error' : 'd-none'
                  }
                >
                  {checkCred && loginValidate(addressDetail)?.state}
                </span>
              ) : (
                ''
              )}
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-4" controlId="formPlaintextcountry">
            <Form.Label column sm="2">
              Country
            </Form.Label>

            <Col sm="6">
              <AsyncSelect
                placeholder="-Select Country-"
                isClearable={true}
                cacheOptions
                defaultOptions
                components={animatedComponents}
                // defaultInputValue={addressDetail?.country}
                value={country}
                // menuIsOpen={locationSearch !== null ? true : false}
                getOptionLabel={(e) => e.label}
                getOptionValue={(e) => e.value}
                loadOptions={loadCountryOption}
                className="basic-single mt-1"
                name="country"
                onChange={(e) => {
                  setCountry(() => e);
                }}
              />

              {checkCred && loginValidate(addressDetail).country ? (
                <span
                  className={
                    `${checkCred && loginValidate(addressDetail).country}`
                      ? 'login-error'
                      : 'd-none'
                  }
                >
                  {checkCred && loginValidate(addressDetail)?.country}
                </span>
              ) : (
                ''
              )}
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-4" controlId="formPlaintextMobile">
            <Form.Label column sm="2">
              Mobile Number
            </Form.Label>
            <Col sm="6">
              <Form.Control
                type="text"
                name="mobile"
                onChange={handleInputData('mobile')}
                onKeyPress={(e) => restrictAlpha(e)}
                value={addressDetail?.mobile}
              />
              {checkCred && loginValidate(addressDetail).mobile ? (
                <span
                  className={
                    `${checkCred && loginValidate(addressDetail).mobile}` ? 'login-error' : 'd-none'
                  }
                >
                  {checkCred && loginValidate(addressDetail)?.mobile}
                </span>
              ) : (
                ''
              )}
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-4" controlId="formPlaintextMobile">
            <Form.Label column sm="2">
              Address Type
            </Form.Label>

            <Col sm="6">
              {['radio'].map((type) => (
                <div key={`inline-${type}`} className="mb-3">
                  <Form.Check
                    inline
                    defaultChecked={'Home'}
                    label="Home"
                    name="group1"
                    type={type}
                    id={`inline-${type}-1`}
                  // onChange={() => setStateRadio('Home')}
                  // checked={stateRadio == 'Home' ? stateRadio : ''}
                  />
                  <Form.Check
                    inline
                    label="Office"
                    name="group1"
                    type={type}
                    id={`inline-${type}-2`}
                    onChange={() => setStateRadio('Office')}
                    checked={stateRadio == 'Office' ? stateRadio : ''}
                  />
                  <Form.Check
                    inline
                    name="group1"
                    label="Other"
                    type={type}
                    id={`inline-${type}-3`}
                    onChange={() => setStateRadio('Other')}
                    checked={stateRadio == 'Other' ? stateRadio : ''}
                  />
                </div>
              ))}
            </Col>
          </Form.Group>
          <div className="text-end mt-2">
            <Button
              variant="primary"
              className="save-btn"
              onClick={(e) => {
                submitFormData(e);
              }}
            >
              Save & Continue
            </Button>
          </div>
        </Form>
      </div>
      {loading ? <Loader /> : ''}
    </div>
  );
}
