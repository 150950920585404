import { useState } from 'react';
import { Col, Form, InputGroup, Row } from 'react-bootstrap';
import * as APISERVICES from '../../../services/AuthServices';
import { logout } from '../../../redux/action';
import { strongPassRegx } from '../../../globals/helper';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import showNotification from '../../../services/NotificationService';

function ResetPassword() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [passwordDetail, setPasswordDetail] = useState({
    oldPassword: '',
    newPassword: '',
    confirmPassword: ''
  });
  const [isClicked, setIsClicked] = useState(false);

  const onChangeHandler = (e) => {
    const { name, value } = e.target;
    setPasswordDetail({ ...passwordDetail, [name]: value });
  };

  const validate = (value) => {
    let error = {};
    if (value.oldPassword.trim() === '') {
      error.oldPassword = 'This field is required.';
    } else if (value.oldPassword.trim().length < 6) {
      error.oldPassword = 'password must be more than 6 characters';
    }
    if (value.newPassword.trim() === '') {
      error.newPassword = 'This field is required.';
    } else if (value.newPassword.trim().length < 6) {
      error.newPassword = 'password must be more than 6 characters';
    } else if (!strongPassRegx.test(value.newPassword)) {
      error.newPassword = 'password must contain alphanumeric with special character';
    }
    if (value.confirmPassword.trim() === '') {
      error.confirmPassword = 'This field is required.';
    } else if (value.confirmPassword !== value.newPassword) {
      error.confirmPassword = 'new password and confirm password are not matching';
    }
    return error;
  };

  const submitHandler = (e) => {
    e.preventDefault();
    setIsClicked(true);
    if (!Object.keys(validate(passwordDetail))?.length) {
      let formData = new FormData();
      for (let i in passwordDetail) {
        formData.append(`${i}`, passwordDetail[i]);
      }
      APISERVICES.changeUSerPassword(formData).then((resp) => {
        if (resp?.status === 200) {
          showNotification('success', resp?.data?.message);
          localStorage.clear();
          dispatch(logout());
          navigate('/');
        } else {
          showNotification('success', resp?.data?.message);
        }
      });
    }
  };

  //add class for particular div from id
  function addClass(id) {
    let element = document.getElementById(id);
    element.classList.add('active');
    removeClass(id);
  }

  function removeClass(id) {
    if (id === 'form1') {
      let element = document.getElementById('form2');
      let element1 = document.getElementById('form3');
      element.classList.remove('active');
      element1.classList.remove('active');
    } else if (id === 'form2') {
      let element = document.getElementById('form1');
      let element1 = document.getElementById('form3');
      element.classList.remove('active');
      element1.classList.remove('active');
    } else if (id === 'form3') {
      let element = document.getElementById('form2');
      let element1 = document.getElementById('form1');
      element.classList.remove('active');
      element1.classList.remove('active');
    }
  }

  return (
    <>
      <div className="detail_form_div">
        <form className="contact-details reset-password" id="">
          <h2>Change Password</h2>
          <div>
            <Row className="form-group" id="form1">
              <Col sm={12}>
                <label className="" htmlFor="firstname">
                  Current Password
                </label>{' '}
                <sup className="text-danger">*</sup>
              </Col>
              <Col sm={12}>
                <InputGroup className="w-100">
                  <Form.Control
                    placeholder="Type Here"
                    aria-label="Username"
                    name="oldPassword"
                    aria-describedby="basic-addon1"
                    type="password"
                    onChange={(e) => {
                      onChangeHandler(e);
                    }}
                    onClick={() => addClass('form1')}
                  />
                </InputGroup>
                <div
                  className={`${isClicked && validate(passwordDetail).oldPassword ? '' : 'd-none'
                    } danger-frm `}
                >
                  {isClicked && validate(passwordDetail).oldPassword}
                </div>
              </Col>
            </Row>{' '}
            <Row className="form-group " id="form2">
              <Col sm={12}>
                <label className="" htmlFor="firstname">
                  New Password
                </label>
                <sup className="text-danger">*</sup>
              </Col>
              <Col sm={12}>
                <InputGroup className="w-100">
                  <Form.Control
                    placeholder="Type Here"
                    aria-label="Username"
                    name="newPassword"
                    aria-describedby="basic-addon1"
                    type="password"
                    onChange={(e) => {
                      onChangeHandler(e);
                    }}
                    onClick={() => addClass('form2')}
                  />
                </InputGroup>
                <div
                  className={`${isClicked && validate(passwordDetail).newPassword ? '' : 'd-none'
                    } danger-frm `}
                >
                  {isClicked && validate(passwordDetail).newPassword}
                </div>
              </Col>
            </Row>{' '}
            <Row className="form-group " id="form3">
              <Col sm={12}>
                <label className="" htmlFor="firstname">
                  Re-enter New Password
                </label>
                <sup className="text-danger">*</sup>
              </Col>
              <Col sm={12}>
                <InputGroup className="w-100">
                  <Form.Control
                    placeholder="Type Here"
                    aria-label="Username"
                    name="confirmPassword"
                    aria-describedby="basic-addon1"
                    type="password"
                    onChange={(e) => {
                      onChangeHandler(e);
                    }}
                    onClick={() => addClass('form3')}
                  />
                </InputGroup>
                <div
                  className={`${isClicked && validate(passwordDetail).confirmPassword ? '' : 'd-none'
                    } danger-frm `}
                >
                  {isClicked && validate(passwordDetail).confirmPassword}
                </div>
              </Col>
            </Row>
          </div>
          <div className="text-end py-3 px-3">
            {/* blk->class for ban type cursor */}
            <button
              className={`accnt-btn`}
              onClick={(e) => {
                submitHandler(e);
              }}
            >
              SUBMIT
            </button>
          </div>
        </form>
      </div>
    </>
  );
}

export default ResetPassword;
