/**
@copyright  :   ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
@author     :   Shiv Charan Panjeta 
 
All Rights Reserved.
Proprietary and confidential :  All information contained here in is, and remains
the property of ToXSL Technologies Pvt. Ltd. and it's partners.
Unauthorized copying of this file, via any medium is strictly prohibited.
*/
import { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Table from 'react-bootstrap/Table';
import { Link, useLocation } from 'react-router-dom';
import Loader from '../../components/commoncomponents/loader/loader-large';
import { statusName } from '../../globals/helper';
import * as adminService from '../../services/AuthServices';

export default function ViewState() {
  const location = useLocation();
  const id = location.state;
  const [detail, setDetail] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (id) {
      getDetail(id);
    }
  }, []);

  const getDetail = (id) => {
    setLoading(true);
    adminService.stateDetail(id).then((res) => {
      setLoading(false);
      if (res?.status === 200) {
        setDetail(res?.data?.message);
      }
    });
  };

  return (
    <>
      <div className="dash-main-content">
        <Container fluid>
          <div className="dash-page-content">
            <div className="dash-page-title">
              <Row>
                <Col xs={12} sm={12} md={12}>
                  <div className="dash-title-bar d-flex align-items-center justify-content-between">
                    <div className="dash-title-dis">
                      <h5>View State Details</h5>
                    </div>
                    <div className="title-rt-btn">
                      <Link className="add-btn-grn" to="/admin/location/state">
                        Back To Listing
                      </Link>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="dash-content-card">
              <div className="table-responsive">
                <Table className="custom-table-vertical" striped hover>
                  <tbody>
                    <tr>
                      <th>State</th>
                      <td>:</td>
                      <td>{detail?.state}</td>
                    </tr>
                    <tr>
                      <th>Country</th>
                      <td>:</td>
                      <td>{detail?.country}</td>
                    </tr>
                    <tr>
                      <th>Latitude</th>
                      <td>:</td>
                      <td>{detail?.latitude}</td>
                    </tr>
                    <tr>
                      <th>Longitude</th>
                      <td>:</td>
                      <td>{detail?.longitude}</td>
                    </tr>
                    <tr>
                      <th>Status</th>
                      <td>:</td>
                      <td>{statusName(detail?.status)}</td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
        </Container>
      </div>
      {loading ? <Loader /> : ''}
    </>
  );
}
