const intitalState = {
  data: {}
};

const instituteInfo = (state = intitalState, action) => {
  switch (action.type) {
    case 'insInfo':
      return {
        ...state,
        data: action.payload
      };
    default:
      return state;
  }
};

export default instituteInfo;
