/**
 * @copyright : ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
@author    : Shiv Charan Panjeta 
 
All Rights Reserved.
Proprietary and confidential :  All information contained here in is, and remains
the property of ToXSL Technologies Pvt. Ltd. and it's partners.
Unauthorized copying of this file, via any medium is strictly prohibited.
 */
import { useEffect, useState } from 'react';
import { getHomepageLayout } from '../../../services/InstituteServices';
import InstituteLanding from './InstituteLanding';
import InstituteLandingOne from './InstituteLandingOne';
import InstituteLandingTwo from './InstituteLandingTwo';
import Loader from '../../commoncomponents/loader/loader-large';

function Institute() {
  const [layoutDetail, setLayoutDetail] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    checkApi();
  }, []);

  const checkApi = () => {
    setLoading(true);
    getHomepageLayout().then((res) => {
      setLoading(false);
      if (res?.status == 200) {
        setLayoutDetail(res?.data?.data);
      }
    });
  };

  return (
    <div className="wrappermain_outer">
      {loading ? (
        <Loader />
      ) : (
        <>
          {' '}
          {layoutDetail?.type_id == '1' ? (
            <InstituteLandingOne />
          ) : layoutDetail?.type_id == '2' ? (
            <InstituteLandingTwo />
          ) : (
            <InstituteLanding />
          )}
        </>
      )}
    </div>
  );
}

export default Institute;
