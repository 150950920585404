import { Col, Container, Row } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import HeaderLogin from '../../../../commoncomponents/HeaderLogin';
import InterpersonalForm from '../../../../commoncomponents/profile/InterpersonalForm';
import ProfileSidebar from '../../../../commoncomponents/profile/ProfileSidebar';
import TopTabbings from '../../TopTabbings';

function InterPersonalSkills() {
  const navigate = useNavigate();
  return (
    <>
      <HeaderLogin state={'d-none'} />
      <TopTabbings />
      <section className="account-info-header">
        <Container
          onClick={() => {
            navigate('/user/profile');
          }}
        >
          <h4>
            {' '}
            <span className="user-icon"></span> Life Skills
          </h4>
        </Container>
      </section>
      <section className="bg-grey">
        <ProfileSidebar state={'inter'} head={'life'} />
        <div className="pt-4 pb-5">
          <Container>
            <Row>
              <Col lg={7} md={12}>
                <div className="account-form">
                  <div className="section_details_heading">
                    <h2>Life Skills</h2>
                    <p>
                      Fill in the information related to your TECHNICAL SKILLS, BUSINESS SKILLS,
                      INTERPERSONAL SKILLS, CAREER GOALS, SWOT ANALYSIS in order of your proficiency
                      (the higher you rate yourself for some skill set put it on priority in your
                      skills) to get the most appropriate search results.
                    </p>
                  </div>
                  <div>
                    <InterpersonalForm />
                  </div>
                </div>
              </Col>
              <Col lg={5} md={12}>
                <div className="signing-almabay">
                  <div className="signing">
                    <img
                      className="img-responsive"
                      src={require('../../../../../assets/images/account-skill-icon.png')}
                      alt="img"
                    />
                    <h2 className="heading">Signing in to AlmaBay</h2>
                    <p> Get everything you need to build a career by filling in the details. </p>
                    <ul>
                      <li className="d-flex">
                        <div>
                          <span className="user-thumb">1</span>
                        </div>
                        <div className="article-post">
                          <span> Technical Skills</span>
                          <p>
                            Add your technical skills precisely and in order of priority. (the
                            higher you rate yourself for some skill set put it on priority in your
                            skill list) the better chances of you getting noticed and hired.
                          </p>
                        </div>
                      </li>
                      <li className="d-flex">
                        <div>
                          <span className="user-thumb">2</span>
                        </div>
                        <div className="article-post">
                          <span> Business Skills </span>
                          <p>
                            The key business skills you enter in your Profile need to reflect your
                            professional strengths. Be precise and mention your Technical skills,
                            soft skills, and tools and software that you know well.
                          </p>
                        </div>
                      </li>
                      <li className="d-flex">
                        <div>
                          <span className="user-thumb">3</span>
                        </div>
                        <div className="article-post">
                          <span>Interpersonal Skills</span>
                          <p>
                            To get an edge over your competitors fill in your Interpersonal skills.
                            Skills you are good at should be on top.
                          </p>
                        </div>
                      </li>
                      <li className="d-flex">
                        <div>
                          <span className="user-thumb">4</span>
                        </div>
                        <div className="article-post">
                          <span> Career Goals</span>
                          <p>
                            Update your network about your professional accomplishments and
                            progress.
                          </p>
                        </div>
                      </li>
                      <li className="d-flex">
                        <div>
                          <span className="user-thumb">5</span>
                        </div>
                        <div className="article-post">
                          <span>SWOT Analysis</span>
                          <p>
                            Analyse and Mention your strengths, weaknesses, opportunities and
                            threats. Will help your mentors and mentees.
                          </p>
                        </div>
                      </li>
                    </ul>

                    <div className="mx-4">
                      <div className="terms-condition">
                        <ul className="d-flex mb-0 ps-0 ">
                          <li className="me-2 p-0">
                            <Link to="/privacy_policy" target="_blank">
                              Privacy Policy
                            </Link>
                          </li>
                          <li className="me-2 p-0">
                            <Link to="/terms_of_use" target="_blank">
                              Terms of Use
                            </Link>
                          </li>
                          <li className="p-0">
                            <Link to="/contact-us" target="_blank">
                              Contact Us
                            </Link>
                          </li>
                        </ul>
                      </div>
                      <div className="company-name">AlmaBay.com © {new Date().getFullYear()} </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </section>
    </>
  );
}

export default InterPersonalSkills;
