import { useEffect, useState } from 'react';
import moment from 'moment/moment';
import { Dropdown } from 'react-bootstrap';
import * as constants from '../../../../globals/constants';
import { userEventActivity } from '../../../../services/SocialServices';
import showNotification from '../../../../services/NotificationService';

function OneEvent({ data, getPostListData }) {
  const [goingButtonShow, setGoingButtonShow] = useState(true);
  const [interstedBtn, setInterstedBtn] = useState(true);
  const [notButton, setNotButton] = useState('');

  const [button, setButton] = useState();
  const [loading, setIsLoading] = useState(false);

  useEffect(() => {
    if (data?.shared_content_details?.eventStats?.going_count > 0) {
      setGoingButtonShow(false);
      setButton('Going');
      setNotButton('Not Going');
      setInterstedBtn(false);
    }
    if (data?.shared_content_details?.eventStats?.interested_count > 0) {
      setInterstedBtn(false);
      setButton('Interested');
      setNotButton('Not Interested');
      setGoingButtonShow(false);
    }
    if (
      data?.shared_content_details?.eventStats?.interested_count == 0 &&
      data?.shared_content_details?.eventStats?.going_count == 0
    ) {
      setGoingButtonShow(true);
      setInterstedBtn(true);
    }
  }, [data]);

  const handleUserActivity = (userId, activity_type) => {
    if (activity_type == 2) {
      setGoingButtonShow(false);
    }
    userEventActivity(userId, activity_type).then((res) => {
      if (res?.status === 200) {
        getPostListData();
        // setGoingButtonShow(true);
        // setInterstedBtn(true);
        // setGoingButtonShow(true);
        showNotification('success', res?.data?.message);
      }
    });
  };

  return (
    <>
      <div className="one-event-card">
        <div className="event-media-lft">
          <img src={data?.shared_content_details?.avatar_url} alt="chatuser" />
        </div>
        <div className="event-media-rt">
          <div className="d-flex justify-content-between">
            <div>
              <h4 className="titlefont">{data?.shared_content_details?.event_data?.name}</h4>
              <ul className="public-event">
                <li>{data?.shared_content_details?.event_data?.event_privacy} event</li>
                <li> {data?.shared_content_details?.event_data?.category_name}</li>
              </ul>
            </div>
            <span className="event-date-hd position-relative">
              <h4>
                {moment(data?.shared_content_details?.event_data?.from_event_date).format('DD')}
              </h4>

              <p>
                {moment(data?.shared_content_details?.event_data?.from_event_date).format('MMM')}
              </p>
            </span>
          </div>

          <div className="date-sec">
            <p className="date">Date</p>
            <p className="address-time">
              {/* 13 Mar 2023 3:43 PM - 15 Jun 2023 3:43 PM */}
              {moment(data?.shared_content_details?.event_data?.from_event_date).format(
                'YYYY-MM-DD'
              ) +
                ' ' +
                moment(data?.shared_content_details?.event_data?.from_event_date).format('h:mm:ss')}
              -
              {moment(data?.shared_content_details?.event_data?.to_event_date).format(
                'YYYY-MM-DD'
              ) +
                ' ' +
                moment(data?.shared_content_details?.event_data?.to_event_date).format('h:mm:ss')}
            </p>
          </div>
          <div className="date-sec">
            <p className="date">Address</p>
            <p className="address-time">{data?.shared_content_details?.event_data?.venue}</p>
          </div>
          <ul className="public-event mt-5">
            <li>{data?.shared_content_details?.eventStats?.interested_count} interested</li>
            <li>{data?.shared_content_details?.eventStats?.going_count} going</li>
            <li>{data?.shared_content_details?.eventStats?.invite_count} invited</li>
          </ul>
          <div className="mt-3">
            {' '}
            {goingButtonShow && (
              <a
                className="invite-orange p-2 mx-2"
                onClick={() => {
                  handleUserActivity(
                    data?.shared_content_details?.event_data?.id,
                    constants.USERGOING
                  );

                  // setGoingButtonShow(false);
                }}
              >
                + GOING
                {/* {userActivity ? (userActivity?.going_count ? 'Going' : '') : '+ GOING'} */}
              </a>
            )}
            {
              // !userActivity?.interested_count || !userActivity?.going_count ?
              interstedBtn && goingButtonShow ? (
                <a
                  className="blue-interest p-2 m-1"
                  onClick={() => {
                    handleUserActivity(
                      data?.shared_content_details?.event_data?.id,
                      constants.USERINTERESTED
                    );

                    // setGoingButtonShow(false);
                  }}
                >
                  INTERESTED
                  {/* {interstedBtn ? 'INTERESTED' : 'INTERESTED'} */}
                </a>
              ) : (
                <Dropdown className="me-2">
                  <Dropdown.Toggle
                    className="blue-interest p-2 m-1"
                    variant="success"
                    id="dropdown-basic"
                  >
                    <i className="fas fa-check me-2"></i>

                    {button}
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item
                      // href="#"
                      onClick={() =>
                        handleUserActivity(
                          data?.shared_content_details?.event_data?.id,
                          constants.USERGOING
                        )
                      }
                    >
                      <div>
                        {' '}
                        <i
                          style={
                            button == 'Going' ? { visibility: 'visible' } : { visibility: 'hidden' }
                          }
                          className="fas fa-check me-2 icon-yellow"
                        ></i>
                        Going
                      </div>
                    </Dropdown.Item>
                    <Dropdown.Item
                      // href="#/action-2"
                      onClick={() => {
                        handleUserActivity(
                          data?.shared_content_details?.event_data?.id,
                          constants.USERINTERESTED
                        );

                        setGoingButtonShow(false);
                      }}
                    >
                      <div>
                        <div>
                          <i
                            style={
                              button == 'Interested'
                                ? { visibility: 'visible' }
                                : { visibility: 'hidden' }
                            }
                            className="fas fa-check me-2 icon-yellow "
                          ></i>
                          Interested
                        </div>
                      </div>
                    </Dropdown.Item>

                    <div style={{ borderBottom: '1px solid #BBBBBB' }}></div>
                    <Dropdown.Item
                      // href="#/action-3"
                      onClick={() => {
                        handleUserActivity(
                          data?.shared_content_details?.event_data?.id,
                          constants.USER_GOING_OR_NOT_GOING
                        );

                        // setGoingButtonShow(true);
                        // setInterstedBtn(true);
                        // setGoingButtonShow(true);
                      }}
                    >
                      {/* Not Interested */}

                      <div>
                        <i
                          style={{ visibility: 'hidden' }}
                          className="fas fa-check me-2 icon-yellow "
                        ></i>

                        {notButton}
                      </div>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              )
            }
          </div>
        </div>
      </div>{' '}
    </>
  );
}

export default OneEvent;
