import { Link, useNavigate, useParams } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useEffect, useState } from 'react';
import { productSingleDetail } from '../../../services/ShopServices';
import { ZERO } from '../../../globals/constants';
import NoDataFound from '../NoDataFound';
import ReactStars from 'react-stars';
function RelatedProduct() {
  const { id } = useParams();
  let navigate = useNavigate();
  const [relatedProductData, setReleatedProductData] = useState([]);

  useEffect(() => {
    getProductDetail();
  }, [id]);

  const getProductDetail = async () => {
    const resp = await productSingleDetail(id);

    try {
      setReleatedProductData(resp?.data?.message?.newproductinfo);
    } catch (error) {
      console.log(error);
    }
  };

  const handleNavigate = (e, id) => {
    e.preventDefault();
    navigate(`/shop/details/${id}`);
  };

  return (
    <>
      <div className="related-product py-4">
        <h4 className="mb-3">Related Products</h4>
        <div className="product-grid">
          <Row>
            {relatedProductData && relatedProductData != ZERO ? (
              relatedProductData?.map((data, index) => {
                return (
                  <Col md={3} lg={3} key={index}>
                    <div className="product-card">
                      <Link
                        to="#"
                        onClick={(e) => {
                          handleNavigate(e, data?.id);
                        }}
                      >
                        <div className="product-img">
                          <img src={data?.cover_image} alt="img" />
                        </div>
                        <div className="product-dis">
                          <a className="productName">{data?.name}</a>
                          <ul className="rating-star mb-2">
                            <ReactStars
                              className="star_rating"
                              size={30}
                              count={5}
                              value={
                                data?.ratings == [] || data?.ratings == undefined
                                  ? ' '
                                  : data?.ratings[0]?.ratings_score
                              }
                              color2={'#f7ad1c'}
                              edit={false}
                            />
                          </ul>

                          <div className="product-price-card d-flex align-items-center">
                            {data?.spl_price == null ? (
                              <h4>&#8377; {data?.price}</h4>
                            ) : (
                              <>
                                <h4>&#8377;{data?.price}</h4>
                                <del>&#8377;{data?.spl_price}</del>
                                <span>
                                  {' '}
                                  {data?.discountPercentage ? `${data?.discountPercentage} %` : ''}
                                </span>
                              </>
                            )}
                          </div>
                        </div>
                      </Link>
                    </div>
                  </Col>
                );
              })
            ) : (
              <div style={{ marginTop: 100 }}>
                <NoDataFound text="Sorry, we are unable to finding any product. You can explore other exciting features of AlmaBay." />
              </div>
            )}
          </Row>
        </div>
      </div>
    </>
  );
}

export default RelatedProduct;
