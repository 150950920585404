import { useEffect, useState } from 'react';
import { Col, Form, InputGroup, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import { getYearDropDown } from '../../../globals/helper';
import showNotification from '../../../services/NotificationService';
import * as APISERVICES from '../../../services/UserServices';
import AsyncSelect from 'react-select/async';
import { getInstituteDrop } from '../../../services/AuthServices';

function EducationForm() {
  const [detail, setDetail] = useState({
    institute_name: '',
    department: '',
    course_name: '',
    reg_id: '',
    to_year: '',
    currently_studing_here: false
  });
  const [inst_name, set_inst_name] = useState();
  const [showForm, setShowForm] = useState(false);
  const [educationInfoList, setEducationInfoList] = useState([]);
  const [yearOptions, setYearOptions] = useState();
  const [clicked, setClicked] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [inputMsg, setInputMsg] = useState('Please enter atleast 2 characters');
  const [loadInput, setLoadInput] = useState(false);

  useEffect(() => {
    getList();
    getYearDropDown(setYearOptions);
  }, []);

  const getList = () => {
    APISERVICES.userEducationDetails().then((res) => {
      if (res?.status === 200) {
        setEducationInfoList(res?.data?.data);
      }
    });
  };

  const loadDepOpt = (inputValue) => {
    return APISERVICES.getDeparDropdown(inputValue).then((res) => {
      if (res?.status === 200) {
        let tempArr = [];
        if (res?.data) {
          const data = res?.data;
          data?.map((data) => {
            tempArr.push({
              id: data?.institutename,
              label: data?.institutename,
              value: data?.institutename
            });
          });
        }
        return tempArr;
      }
    });
  };

  const loadCourseOpt = (inputValue) => {
    return APISERVICES.getCourseOptList(inputValue).then((res) => {
      if (res?.status === 200) {
        let tempArr = [];
        if (res?.data) {
          const data = res?.data;
          data?.map((data) => {
            tempArr.push({
              label: data?.course_name,
              value: data?.course_name
            });
          });
        }
        return tempArr;
      }
    });
  };

  const onChangeHandler = (e, select = false, name = '') => {
    if (select) {
      if (name === 'institute_name') {
        setDetail({ ...detail, [name]: e?.value });
        set_inst_name(e.value);
      } else {
        setDetail({ ...detail, [name]: e?.value });
      }
    } else {
      const { name, value } = e.target;
      setDetail({ ...detail, [name]: value });
    }
  };

  const validate = () => {
    let errors = {};
    if (detail?.institute_name == '') {
      errors.institute_name = 'This field is required';
    }
    if (detail?.department == '') {
      errors.department = 'This field is required';
    }
    if (detail?.course_name == '') {
      errors.course_name = 'This field is required';
    }
    if (detail?.currently_studing_here === false && detail?.to_year == '') {
      errors.to_year = 'This field is required';
    }
    return errors;
  };

  const submitHandler = (e) => {
    e.preventDefault();
    setClicked(true);
    if (!Object.keys(validate()).length) {
      let formData = new FormData();
      if (detail?.currently_studing_here === true) {
        delete detail.to_year;

        for (let i in detail) {
          formData.append(`${i}`, detail[i]);
        }
      } else {
        for (let i in detail) {
          formData.append(`${i}`, detail[i]);
        }
      }
      if (isEdit) {
        APISERVICES.updateEducationInfo(formData).then((res) => {
          if (res?.status === 200) {
            showNotification('success', res?.data?.message);
            setShowForm(false);
            setIsEdit(false);
            setDetail({
              institute_name: '',
              department: '',
              course_name: '',
              reg_id: '',
              to_year: '',
              currently_studing_here: false
            });
            getList();
          }
        });
      } else {
        APISERVICES.addEducationDetail(formData).then((res) => {
          if (res?.status === 200) {
            showNotification('success', res?.data?.message);
            setShowForm(false);
            getList();
          }
        });
      }
    }
  };

  const deleteHandler = (id) => {
    if (confirm('Do you want to remove your qualification?') === true) {
      APISERVICES.deleteEducationDetail(id).then((res) => {
        if (res?.status === 200) {
          showNotification('success', res?.data?.message);
          getList();
          setShowForm(false);
          setIsEdit(false);
          setDetail({
            institute_name: '',
            department: '',
            course_name: '',
            reg_id: '',
            to_year: '',
            currently_studing_here: false
          });
        }
      });
    } else {
      return;
    }
  };

  const handleEdit = (e, data) => {
    e.preventDefault();
    setIsEdit(true);
    set_inst_name(data?.institute_name),
      setDetail({
        user_qualification_id: data?.user_qualification_id,
        institute_name: data?.institute_name,
        department: data?.department,
        course_name: data?.course_name,
        reg_id: data?.reg_id,
        to_year: data?.currently_studing_here == 1 ? new Date().getFullYear() : data?.to_year,
        currently_studing_here: data?.currently_studing_here == 0 ? false : true
      });
  };

  const loadInstituteOptions = async (inputValue) => {
    if (inputValue.length >= 2) {
      setLoadInput(true);
      return getInstituteDrop(inputValue).then((res) => {
        setLoadInput(false);
        if (res?.status === 200) {
          let tempArr = [];
          if (res?.data?.length) {
            const data = res?.data;
            data?.map((data) => {
              tempArr.push({
                id: data?.institute_id,
                label: data?.institutename,
                value: data?.institutename
              });
            });
          }
          if (res?.data?.data == undefined) {
            setInputMsg('no match found');
          }
          return tempArr;
        }
      });
    }
  };
  const instituteOptions = {
    control: (base) => ({
      ...base,
      border: '0 !important',
      // This line disable the  border
      boxShadow: '0 !important',
      '&:hover': {
        border: '0 !important'
      }
    })
  };
  return (
    <>
      <div className="detail_form_div">
        <form className="contact-details" id="">
          <h2>Education details</h2>
          <div className="work-lst-sec">
            {educationInfoList && educationInfoList.length ? (
              educationInfoList?.map((data, index) => (
                <div className="work-lst-card px-3 py-4" key={index}>
                  {!showForm && (
                    <div className="work-lst-overlay">
                      <Link
                        to=""
                        onClick={(e) => {
                          e.preventDefault();
                          deleteHandler(data?.user_qualification_id);
                        }}>
                        <i className="fas fa-trash-alt"></i>
                      </Link>
                      <Link to="" onClick={(e) => handleEdit(e, data)}>
                        <i className="fas fa-pencil-alt"></i>
                      </Link>
                    </div>
                  )}

                  <Row className="m-0 align-items-center">
                    <Col lg={10} md={10}>
                      <div className="work-lft mb-2">
                        <h4>{data?.institute_name}</h4>
                        <p>{data?.state_name}</p>
                        <h5>
                          {data?.to_year == 0 ? 'Currently Studying in' : 'Studied'}{' '}
                          {data?.course_name}
                          {data?.to_year == 0 ? <></> : <> in {data?.to_year}</>}
                        </h5>
                      </div>
                    </Col>
                    <Col lg={2} md={2}>
                      <div className="work-icn">
                        <img
                          className="img-responsive"
                          src={require('../../../assets/images/account-manage-icon.png')}
                          alt="img"
                        />
                      </div>
                    </Col>
                  </Row>
                </div>
              ))
            ) : (
              <></>
            )}
            {!showForm && !isEdit ? (
              <div className="work-place-btn text-end  px-3 py-3">
                <Link
                  to="#"
                  onClick={(e) => {
                    e.preventDefault();
                    setShowForm(true);
                  }}>
                  + Add an Educational Information
                </Link>
              </div>
            ) : (
              <></>
            )}
          </div>

          {showForm || isEdit ? (
            <>
              <div>
                <h2>Fill Education Information</h2>
                <Row className="form-group">
                  <Col sm={10} xs={12}>
                    <label className="" htmlFor="firstname">
                      Institute / University Name
                    </label>
                    <sup className="text-danger">*</sup>
                  </Col>
                  <Col sm={2} xs={4}>
                    <Link to="#" className="me-2 correct_btn">
                      <i className="fas fa-check"></i>
                    </Link>
                  </Col>
                  <Col sm={12} xs={12}>
                    {isEdit ? (
                      <AsyncSelect
                        className="basic-single mt-3 mb-3"
                        classNamePrefix="select"
                        isSearchable={true}
                        name="institute_name"
                        loadOptions={loadInstituteOptions}
                        onMenuOpen={() => {
                          setDetail((prevState) => ({
                            ...prevState,
                            institute_name: ''
                          }));
                        }}
                        onMenuClose={() => {
                          setDetail((prevState) => ({
                            ...prevState,
                            institute_name: inst_name
                          }));
                        }}
                        value={{
                          label: (
                            <div>
                              {/* <img
                                src={
                                  process.env.REACT_APP_API_URL +
                                  '/common/uploads/institute_logo/' +
                                  logo
                                }
                                alt="logo"
                                height="30px"
                                width="30px"
                              /> */}
                              {`\u00A0\u00A0\u00A0${detail?.institute_name}`}
                            </div>
                          ),
                          value: detail?.institute_name
                        }}
                        onChange={(e) => {
                          onChangeHandler(e, true, 'institute_name');
                        }}
                        onInputChange={(newValue) => {
                          newValue?.length <= 2 && setInputMsg('Please enter atleast 2 characters');
                        }}
                        noOptionsMessage={() => inputMsg}
                        components={{
                          DropdownIndicator: () => null,
                          IndicatorSeparator: () => null,
                          LoadingIndicator: () => null
                        }}
                        styles={instituteOptions}
                      />
                    ) : (
                      <AsyncSelect
                        className="basic-single mt-3 mb-3"
                        classNamePrefix="select"
                        isClearable={true}
                        isSearchable={true}
                        name="institute_name"
                        loadOptions={loadInstituteOptions}
                        onChange={(e) => {
                          if (!e) {
                            return;
                          }

                          onChangeHandler(e, true, 'institute_name');
                        }}
                        onInputChange={(newValue) => {
                          newValue?.length <= 2 && setInputMsg('Please enter atleast 2 characters');
                        }}
                        noOptionsMessage={() => inputMsg}
                        components={{
                          DropdownIndicator: () => null,
                          IndicatorSeparator: () => null,
                          LoadingIndicator: () => null
                        }}
                        styles={instituteOptions}
                      />
                    )}
                    {loadInput && (
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row-reverse',
                          margin: 'auto',
                          marginTop: '-40px'
                        }}>
                        <i className="fa fa-spinner fa-pulse me-2"></i>
                      </div>
                    )}

                    <div className={'danger-frm'}>{clicked && validate().institute_name}</div>
                  </Col>
                </Row>
                <Row className="form-group">
                  <Col sm={10} xs={12}>
                    <label className="" htmlFor="firstname">
                      Department
                    </label>
                  </Col>
                  <Col sm={2} xs={4}>
                    <Link to="#" className="me-2 correct_btn">
                      <i className="fas fa-check"></i>
                    </Link>
                  </Col>
                  <Col sm={12} xs={12}>
                    {isEdit ? (
                      <AsyncSelect
                        className="basic-single mt-3 mb-3"
                        classNamePrefix="select"
                        isClearable={true}
                        isSearchable={true}
                        name="department"
                        loadOptions={loadDepOpt}
                        value={{
                          label: detail?.department,
                          value: detail?.department
                        }}
                        onChange={(e) => {
                          onChangeHandler(e, true, 'department');
                        }}
                      />
                    ) : (
                      <AsyncSelect
                        className="basic-single mt-3 mb-3"
                        classNamePrefix="select"
                        isClearable={true}
                        isSearchable={true}
                        name="department"
                        loadOptions={loadDepOpt}
                        onChange={(e) => {
                          onChangeHandler(e, true, 'department');
                        }}
                      />
                    )}
                    {/* <div className={'danger-frm'}>{clicked && validate().department}</div> */}
                  </Col>
                </Row>
                <Row className="form-group">
                  <Col sm={10} xs={12}>
                    <label className="" htmlFor="firstname">
                      Course / Program
                    </label>
                  </Col>
                  <Col sm={2} xs={4}>
                    <Link to="#" className="me-2 correct_btn">
                      <i className="fas fa-check"></i>
                    </Link>
                  </Col>
                  <Col sm={12} xs={12}>
                    {isEdit ? (
                      <AsyncSelect
                        className="basic-single mt-3 mb-3"
                        classNamePrefix="select"
                        isClearable={true}
                        isSearchable={true}
                        name="course_name"
                        loadOptions={loadCourseOpt}
                        value={{
                          label: detail?.course_name,
                          value: detail?.course_name
                        }}
                        onChange={(e) => {
                          onChangeHandler(e, true, 'course_name');
                        }}
                        // onChangeHandler
                      />
                    ) : (
                      <AsyncSelect
                        className="basic-single mt-3 mb-3"
                        classNamePrefix="select"
                        isClearable={true}
                        isSearchable={true}
                        name="course_name"
                        loadOptions={loadCourseOpt}
                        onChange={(e) => {
                          onChangeHandler(e, true, 'course_name');
                        }}
                        // onChangeHandler
                      />
                    )}
                    {/* <div className={'danger-frm'}>{clicked && validate().course_name}</div> */}
                  </Col>
                </Row>
                <Row className="form-group">
                  <Col sm={10} xs={12}>
                    <label className="" htmlFor="firstname">
                      Registration Number
                    </label>
                  </Col>
                  <Col sm={2} xs={4}>
                    <Link to="#" className="me-2 correct_btn">
                      <i className="fas fa-check"></i>
                    </Link>
                  </Col>
                  <Col sm={12} xs={12}>
                    <InputGroup className="w-100">
                      <Form.Control
                        id="firstname"
                        placeholder="Enter registration number (optional)"
                        name="reg_id"
                        aria-label="firstname"
                        aria-describedby="basic-addon1"
                        value={detail?.reg_id}
                        onChange={onChangeHandler}
                      />
                    </InputGroup>
                  </Col>
                </Row>
                <Row className="form-group">
                  <Col sm={10} xs={12}>
                    <label className="" htmlFor="firstname">
                      Passout Batch
                    </label>
                    <sup className="text-danger">*</sup>
                  </Col>
                  <Col sm={2} xs={4}>
                    <Link to="#" className="me-2 correct_btn">
                      <i className="fas fa-check"></i>
                    </Link>
                  </Col>
                  {!detail?.currently_studing_here ? (
                    <Col sm={12} xs={12}>
                      {isEdit ? (
                        <Select
                          className="basic-single mt-3 mb-3"
                          classNamePrefix="select"
                          isClearable={true}
                          isSearchable={true}
                          options={yearOptions}
                          value={{
                            label: detail?.to_year,
                            value: detail?.to_year
                          }}
                          name="to_year"
                          onChange={(e) => {
                            onChangeHandler(e, true, 'to_year');
                          }}
                        />
                      ) : (
                        <Select
                          className="basic-single mt-3 mb-3"
                          classNamePrefix="select"
                          isClearable={true}
                          isSearchable={true}
                          options={yearOptions}
                          name="to_year"
                          onChange={(e) => {
                            onChangeHandler(e, true, 'to_year');
                          }}
                        />
                      )}
                      {/* <div className={'danger-frm'}>{clicked && validate().to_year}</div> */}
                    </Col>
                  ) : (
                    'Present'
                  )}
                </Row>
                <Row className="m-0 align-items-center py-3 px-4">
                  <Col lg={6} sm={6} xs={12}>
                    <div className="accnt-rt-check">
                      <Form.Check
                        inline
                        onChange={(e) => {
                          setDetail({ ...detail, [e.target.name]: e.target.checked });
                        }}
                        label="I currently studying here."
                        name="currently_studing_here"
                        checked={detail?.currently_studing_here}
                      />
                    </div>
                  </Col>
                  <Col lg={6} sm={6} xs={12}>
                    <div className="accnt-lft-btn d-flex align-items-center justify-content-end">
                      {/* blk blk */}
                      <button
                        className="accnt-btn txt-transfrm "
                        type="button"
                        onClick={submitHandler}>
                        Save
                      </button>
                      <button
                        type="button"
                        className="wht-btn txt-transfrm  ms-2"
                        onClick={(e) => {
                          e.preventDefault();
                          setShowForm(false);
                          setIsEdit(false);
                          setDetail({
                            institute_name: '',
                            department: '',
                            course_name: '',
                            reg_id: '',
                            to_year: '',
                            currently_studing_here: false
                          });
                        }}>
                        Cancel
                      </button>
                    </div>
                  </Col>
                </Row>
              </div>
            </>
          ) : (
            <></>
          )}
        </form>
      </div>
    </>
  );
}

export default EducationForm;
