/**
@copyright  :   ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
@author     :   Shiv Charan Panjeta 
 
All Rights Reserved.
Proprietary and confidential :  All information contained here in is, and remains
the property of ToXSL Technologies Pvt. Ltd. and it's partners.
Unauthorized copying of this file, via any medium is strictly prohibited.
*/

import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';
import {
  delTemplateEmail,
  getMultiActionEmail,
  getTemplateEmailList
} from '../../../services/TemplateServices';
import Loader from '../../../components/commoncomponents/loader/loader-large';
import ReactPaginate from 'react-paginate';
import showNotification from '../../../services/NotificationService';
import { useSelector } from 'react-redux';
import { Alert } from 'react-bootstrap';
import { ZERO } from '../../../globals/constants';
import NoDataFound from '../../../components/commoncomponents/NoDataFound';
export default function EmailTemplate({ selectedTab }) {
  const navigate = useNavigate();
  const isAlert = useSelector((state) => state.alert);
  const [allEmailTemplateList, setAllEmailTemplateList] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [totalRecords, setTotalRecord] = useState('');
  const [checkedId, setCheckedId] = useState([]);
  const [allId, setAllId] = useState([]);
  const [action, setAction] = useState('');
  const [checkId, setChecked] = useState(false);
  const [msgState, setMsgState] = useState('');
  const [messageView, setMessageView] = useState('');
  const [showalert, setShowAlert] = useState(false);
  const [alert, setAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState('');
  const [showalert1, setShowAlert1] = useState(false);
  useEffect(() => {
    if (selectedTab == 'email') {
      getEmailTemplateList();
    }
  }, [selectedTab]);
  /**
   * hnadle single checkbox
   * @param {*} e
   * @param {*} id
   */
  const handleMultipleCheckBox = (e, id) => {
    if (checkedId.includes(id)) {
      setCheckedId(checkedId.filter((data) => data !== id));
    } else {
      setCheckedId([...checkedId, id]);
    }
  };
  useEffect(() => {
    const timeId = setTimeout(() => {
      setAlert(false);
      setAlertMsg('');
      setMessageView('');
      setShowAlert(false);
      setShowAlert1(false);
    }, 3000);
    return () => {
      clearTimeout(timeId);
    };
  }, [alert, messageView, showalert]);
  useEffect(() => {
    if (isAlert?.showAlertBox) {
      setAlertMsg(isAlert?.message);
      setAlert(true);
    }
  }, []);
  /**
   * hnadle select all checkbox
   * @param {*} e
   */
  const handleSelectAll = (e) => {
    if (e.target.checked === true) {
      setChecked(true);
      allId?.forEach((id) => {
        if (!checkedId?.includes(id)) {
          setCheckedId((prev) => [...prev, id]);
        }
      });
    } else {
      setCheckedId([
        ...checkedId?.filter((id) => {
          return allId?.indexOf(id) == -1;
        })
      ]);
      setChecked(false);
    }
  };

  /**
   * Get All EmailTemplate List
   */

  const getEmailTemplateList = (page = '1', state_id = '') => {
    setLoading(true);
    setCurrentPage(page);
    getTemplateEmailList(page, state_id)
      .then((resp) => {
        if (resp?.status === 200) {
          setAllEmailTemplateList(resp?.data?.data);
          setAllId(resp?.data?.data?.map((data) => data?.id ?? []));
          setTotalRecord(resp?.data?.pager?.total);
          setMsgState(resp?.data?.message);
          setLoading(false);
        } else {
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const getApplicable = (type) => {
    switch (type) {
      case '1':
        return 'Institute';
      case '2':
        return 'Student';
      case '3':
        return 'Company';
      default:
        return 'N/A';
    }
  };

  const handlePageClick = (a) => {
    setCheckedId([]);
    let page = a?.selected + 1;
    getEmailTemplateList(page);
    setChecked(false);
  };
  const globalStateHandler = () => {
    setShowAlert(true);
  };
  const handlePerformAction = async (val) => {
    if (checkedId.length > 0) {
      try {
        const body = {
          ids: checkedId.toString(),
          action: val
        };
        setShowAlert(false);
        const resp = await getMultiActionEmail(body);
        if (resp.status === 200) {
          getEmailTemplateList();
          setMessageView(resp?.data?.message);
          setShowAlert1(true);
          setCheckedId([]);
        } else {
          globalStateHandler();
        }
      } catch (error) {
        console.error();
      }
    }
  };
  const handleStatusUpdate = (id, value) => {
    const formData = new FormData();
    formData.append('ids', id);
    formData.append('action', value);
    getMultiActionEmail(formData).then((res) => {
      if (res?.status === 200) {
        showNotification('success', res?.data?.message);
        getEmailTemplateList();
      }
    });
  };
  const handelDelete = (id) => {
    setLoading(true);
    delTemplateEmail(id).then((resp) => {
      if (resp?.status == 200) {
        setLoading(false);
        showNotification('success', resp?.data?.message);
        getEmailTemplateList();
      } else {
        setLoading(false);
      }
    });
  };

  return (
    <div className="manage-temp-content">
      <div className="manage-temp-hd d-flex align-items-center justify-content-between mb-3">
        <h4>Email Template</h4>
        <div className="manage-temp-btn">
          {checkId == true ? (
            <div className="head-instite-sec">
              <Form.Select
                onCh
                className=""
                value={action}
                aria-label="Default select example"
                onChange={(e) => {
                  setAction(e.target.value);
                  handlePerformAction(e.target.value);
                }}>
                <option>Choose Action</option>
                <option value="enable">Enable</option>
                <option value="disable">Disable</option>
                <option value="delete">Delete</option>
              </Form.Select>
            </div>
          ) : (
            ''
          )}
          <Link to="/admin/AddEmailTemplate" state={selectedTab}>
            Add New
          </Link>
        </div>
      </div>
      <Alert variant={'danger'} className={`${showalert ? '' : 'd-none'}`}>
        Please select any record
      </Alert>
      <Alert variant={'success'} className={`${showalert1 ? '' : 'd-none'}`}>
        {messageView}
      </Alert>
      <div className="table-responsive">
        <Table className="custom-table-data" striped bordered hover>
          <thead>
            <tr>
              {msgState == !'Data not found' || allEmailTemplateList?.length > 0 ? (
                <Form.Check
                  type={'checkbox'}
                  checked={
                    allId?.every((elem) => {
                      return checkedId.includes(elem);
                    }) && allEmailTemplateList?.length > 0
                      ? true
                      : false
                  }
                  onChange={(e) => handleSelectAll(e)}
                />
              ) : (
                <th></th>
              )}

              <th>Sr. No.</th>
              <th>Title</th>
              <th>Applicable For</th>
              <th>Status</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr className="search-table">
              <td></td>
              <td></td>
              <td></td>
              <td></td>

              <td>
                {' '}
                <Form.Select
                  aria-label="Default select example"
                  name="status"
                  onChange={(e) => getEmailTemplateList(1, e.target.value)}>
                  <option value="">- Select Status -</option>
                  <option value="1">Enable</option>
                  <option value="0">Disable</option>
                  <option value="2">Delete</option>
                </Form.Select>
              </td>
              <td></td>
            </tr>
            {allEmailTemplateList && allEmailTemplateList?.length != ZERO ? (
              allEmailTemplateList?.map((data, index) => {
                return (
                  <tr key={index}>
                    <Form.Check
                      name={data?.title}
                      id={data?.id}
                      checked={checkedId?.includes(data?.id)}
                      onChange={(e) => {
                        handleMultipleCheckBox(e, data?.id);
                      }}
                      type={'checkbox'}
                    />
                    <td>{data?.id}</td>
                    <td>{data?.title}</td>

                    <td>{getApplicable(data?.applicable_for)}</td>
                    <td>
                      <Form.Select
                        aria-label="Default select example"
                        value={data?.state_id}
                        onChange={(e) => {
                          handleStatusUpdate(data.id, e.target.value == 1 ? 'enable' : 'disable');
                        }}>
                        <option>Select Status</option>
                        <option value="1">Enable</option>
                        <option value="0">Disable</option>
                        <option value="2">Delete</option>
                      </Form.Select>
                    </td>
                    <td>
                      <Form.Select
                        aria-label="Default select example"
                        onChange={(e) => {
                          if (e.target.value == '1') {
                            navigate(`/admin/AddEmailTemplate?id=${data?.id}`);
                          }
                          if (e.target.value == '2') {
                            handelDelete(data?.id);
                          }
                        }}>
                        <option hidden>Action</option>
                        <option value="1">Edit</option>
                        <option value="2">Delete</option>
                      </Form.Select>
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan={7} className="text-center">
                  <NoDataFound />
                </td>
              </tr>
            )}
          </tbody>
        </Table>
        <div className="react-page d-flex">
          {totalRecords > 10 ? (
            <ReactPaginate
              previousLabel={currentPage > 0 ? '<' : '<'}
              nextLabel=">"
              onPageChange={handlePageClick}
              activeClassName={'page-link active'}
              pageCount={Math.ceil(totalRecords / 10)}
            />
          ) : (
            ''
          )}
        </div>
      </div>
      {loading ? <Loader /> : <></>}
    </div>
  );
}
