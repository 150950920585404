//  @copyright : ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
// @author     : Shiv Charan Panjeta

// All Rights Reserved.
// Proprietary and confidential :  All information contained herein is, and remains
// the property of ToXSL Technologies Pvt. Ltd. and its partners.
// Unauthorized copying of this file, via any medium is strictly prohibited.

import { useEffect, useState } from 'react';
import { Button, Container } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Table from 'react-bootstrap/Table';
import ReactPaginate from 'react-paginate';
import { Link, useNavigate } from 'react-router-dom';
import NoDataFound from '../../../components/commoncomponents/NoDataFound';
import Loader from '../../../components/commoncomponents/loader/loader-large';
import useDocumentTitle from '../../../globals/useDocumentTitle';
import { getSocialPageList, globalStateChangeinPage } from '../../../services/AuthServices';
import showNotification from '../../../services/NotificationService';

let intervalId;

export default function ListPage() {
  useDocumentTitle('Manage Pages | AlmaBay');
  const [chekcedId, setCheckedId] = useState([]);
  const [allId, setAllId] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [sortType, setSortType] = useState('DESC');
  const [sortField, setSortField] = useState('id');
  const [showalert, setShowAlert] = useState(false);
  const [list, setList] = useState([]);
  const [response, setResponse] = useState('');
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const [searchedData, setSearchData] = useState({
    pageid: '',
    page_name: '',
    page_status: '',
    page_verified: ''
  });

  useEffect(() => {
    apiCall();
  }, []);

  const apiCall = (
    page = '1',
    sortfield = 'id',
    order = 'DESC',
    pageid = '',
    page_name = '',
    page_status = '',
    page_verified = ''
  ) => {
    // setLoading(true);
    getSocialPageList(page, sortfield, order, pageid, page_name, page_status, page_verified).then(
      (res) => {
        // setLoading(false);
        if (res?.status === 200) {
          setList(res?.data?.detail?.viewlist);
          setResponse(res?.data?.detail?.totalRecords);
          setAllId(res?.data?.detail?.viewlist?.map((data) => data?.id));
        }
      }
    );
  };

  const onChangeSearch = (e) => {
    const { name, value } = e.target;
    setSearchData({ ...searchedData, [name]: value });
  };

  const searchHandler = (e) => {
    e.preventDefault();
    apiCall(
      currentPage,
      sortField,
      sortType,
      searchedData?.pageid,
      searchedData?.page_name,
      searchedData?.page_status,
      searchedData?.page_verified
    );
  };

  const handleMultipleCheckBox = (id) => {
    if (chekcedId.includes(id)) {
      let filterId = chekcedId.filter((data) => data !== id);
      setCheckedId(filterId);
    } else {
      setCheckedId([...chekcedId, id]);
    }
  };

  const handleSelectAll = (e) => {
    if (e.target.checked === true) {
      setCheckedId(allId);
    } else {
      setCheckedId([]);
    }
  };
  const handlePageClick = (e) => {
    setCheckedId([]);
    setCurrentPage(e.selected + 1);
    apiCall(
      e.selected + 1,
      sortField,
      sortType,
      searchedData?.pageid,
      searchedData?.page_name,
      searchedData?.page_status,
      searchedData?.page_verified
    );
  };

  const selectHandler = (e, id) => {
    switch (e) {
      case 'edit':
        navigate(`/admin/social/pages/edit_page/${id}`);
        break;

      case 'manage':
        navigate('/admin/pages/manage/' + id);
        break;

      default:
        break;
    }
  };

  const globalActionHandler = (e) => {
    if (chekcedId.length !== 0) {
      setShowAlert(false);
      let body = {
        ids: chekcedId.join(),
        action: e.target.value
      };
      if (confirm('Do you want to continue ?') === true) {
        globalStateChangeinPage(body).then((res) => {
          if (res?.status === 200) {
            setCheckedId([]);
            showNotification('success', res?.data?.message);
            document.getElementById('global').value = '';
            apiCall();
          }
        });
      } else {
        return;
      }
    } else {
      setShowAlert(true);
      alertHandler();
    }
  };

  const alertHandler = () => {
    clearInterval(intervalId);
    intervalId = setInterval(function () {
      setShowAlert(false);
    }, 5000);
  };

  const resetHandler = (e) => {
    e.preventDefault();
    setSearchData({
      pageid: '',
      page_name: '',
      page_status: '',
      page_verified: ''
    });
    setSortType('DESC');
    apiCall();
  };

  const sortHandler = (e, type) => {
    e.preventDefault();
    let currentSort = sortType === 'ASC' ? 'DESC' : 'ASC';
    setSortType(currentSort);
    setSortField(type);
    apiCall(
      currentPage,
      type,
      currentSort,
      searchedData?.pageid,
      searchedData?.page_name,
      searchedData?.page_status,
      searchedData?.page_verified
    );
  };

  return (
    <>
      <div className="dash-main-content">
        <Container fluid>
          <div className="dash-page-content">
            <div className="dash-page-title">
              <Row>
                <Col xs={12} sm={12} md={12}>
                  <div className="dash-title-bar d-flex align-items-center justify-content-between">
                    <div className="title-lft d-flex align-items-center">
                      <div className="dash-icn">
                        <i className="fas fa-flag"></i>
                      </div>
                      <div className="dash-title-dis">
                        <h5>
                          Page <span>Listing</span>
                        </h5>
                        {list?.length !== 0 && (
                          <h5>
                            Total Record(s): <span>{list?.length}</span>
                          </h5>
                        )}
                      </div>
                    </div>
                    <div className="title-rt-btn">
                      <Link className="green_button_admin" to="/admin/pages/add_page">
                        Add Page
                      </Link>
                      <div className="head-instite-sec  mx-2">
                        <Form.Select
                          aria-label="Default select example"
                          id="global"
                          onChange={(e) => {
                            globalActionHandler(e);
                          }}>
                          <option value="">Choose Action</option>
                          <option value="active">Active</option>
                          <option value="inactive">Inactive</option>
                          <option value="del">Delete</option>
                        </Form.Select>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="dash-content-card">
              <div className="table-responsive">
                <Table className="custom-table-data" striped bordered hover>
                  <thead>
                    <tr>
                      <th>
                        <Form.Check
                          type="checkbox"
                          id="checkbox"
                          label=""
                          checked={allId?.length != 0 ? allId?.length === chekcedId?.length : false}
                          onChange={handleSelectAll}
                        />
                      </th>
                      <th>
                        <Link
                          to="#"
                          onClick={(e) => {
                            sortHandler(e, 'id');
                          }}>
                          Id
                          <img src={require('../../../assets/images/sort.png')} alt="sort" />
                        </Link>
                      </th>
                      <th>
                        <Link
                          onClick={(e) => {
                            sortHandler(e, 'id');
                          }}>
                          Page Name
                          <img src={require('../../../assets/images/sort.png')} alt="sort" />
                        </Link>
                      </th>
                      <th>Likes</th>
                      <th>
                        <Link
                          to=""
                          onClick={(e) => {
                            sortHandler(e, 'id');
                          }}>
                          Status
                          <img src={require('../../../assets/images/sort.png')} alt="sort" />
                        </Link>
                      </th>
                      <th>Verified</th>
                      <th>Actions</th>
                    </tr>
                    <tr className="search-table">
                      <td>
                        <></>
                      </td>
                      <td>
                        <Form.Control
                          type="text"
                          value={searchedData.id}
                          name="pageid"
                          placeholder="Id"
                          onChange={(e) => {
                            onChangeSearch(e);
                          }}
                        />
                      </td>
                      <td>
                        <Form.Control
                          type="text"
                          value={searchedData.page_name}
                          name="page_name"
                          onChange={(e) => {
                            onChangeSearch(e);
                          }}
                          placeholder="Name"
                        />
                      </td>
                      <td></td>
                      <td>
                        <Form.Select
                          aria-label="Default select example"
                          value={searchedData.page_status}
                          name="page_status"
                          onChange={(e) => {
                            onChangeSearch(e);
                          }}>
                          <option value=""> - Select - </option>
                          <option value="1">Active</option>
                          <option value="0">Inactive</option>
                        </Form.Select>
                      </td>
                      <td>
                        <Form.Select
                          aria-label="Default select example"
                          value={searchedData?.page_verified}
                          name="page_verified"
                          onChange={(e) => {
                            onChangeSearch(e);
                          }}>
                          <option value=""> - Select - </option>
                          <option value="1">Yes</option>
                          <option value="0">No</option>
                        </Form.Select>
                      </td>
                      <td className="serac-btns text-center">
                        <button
                          className="green_button_admin"
                          type="button"
                          onClick={searchHandler}>
                          Search
                        </button>
                        <Button
                          className="main-btn-red ms-2"
                          onClick={(e) => {
                            resetHandler(e);
                          }}>
                          Reset
                        </Button>
                      </td>
                    </tr>
                  </thead>

                  <tbody>
                    {list && list.length !== 0 ? (
                      <>
                        {list?.map((data, index) => {
                          return (
                            <tr key={index}>
                              <td>
                                <Form.Check
                                  type="checkbox"
                                  id={`id${data?.id}`}
                                  checked={chekcedId?.includes(data?.id)}
                                  onChange={() => handleMultipleCheckBox(data?.id)}
                                  label=""
                                />
                              </td>
                              <td>{data?.id}</td>
                              <td>{data?.name}</td>
                              <td>{data?.likes_count}</td>
                              <td>{data?.active == '1' ? 'Active' : 'Inactive'}</td>
                              <td>{data?.verified == '1' ? 'Yes' : 'No'}</td>
                              <td>
                                <Form.Select
                                  aria-label="Default select example"
                                  onChange={(e) => {
                                    selectHandler(e.target.value, data?.id);
                                  }}>
                                  <option>Action</option>
                                  <option value="edit">Edit</option>
                                  {/* <option value="2">View</option> */}
                                  <option value="manage">Manage Likes</option>
                                </Form.Select>
                              </td>
                            </tr>
                          );
                        })}
                      </>
                    ) : (
                      <tr>
                        <td colSpan={8}>
                          <NoDataFound />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
                <div className="react-page d-flex">
                  {response > 10 ? (
                    <ReactPaginate
                      previousLabel={currentPage > 0 ? '<' : ''}
                      nextLabel=">"
                      onPageChange={handlePageClick}
                      pageCount={Math.ceil(response / 10)}
                      activeClassName={'page-link active'}
                      forcePage={currentPage - 1}
                    />
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
      {loading ? <Loader /> : ''}
    </>
  );
}
