import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import HeaderInner from '../../commoncomponents/HeaderInner';
import FooterMain from '../../commoncomponents/FooterMain';
import * as APISERVICES from '../../../services/AuthServices';
import useDocumentTitle from '../../../globals/useDocumentTitle';
import Loader from '../../commoncomponents/loader/loader-large';

function WhatIsAlmabay() {
  useDocumentTitle(
    ' Alumni Engagement Web Portal - Almabay. Get connected with your Batch Mates and find the right mentors!| AlmaBay'
  );
  const [content, setContent] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getWhatIsAlmabay('what-is-almabay');
  }, []);

  const getWhatIsAlmabay = (page = '') => {
    setLoading(true);

    APISERVICES.staticWebPages(page).then((res) => {
      setLoading(false);
      if (res?.status == 200) {
        setContent(res?.data?.data?.page_content);
      }
    });
  };

  return (
    <>
      <HeaderInner />
      <section className="about-blk py-5">
        <div className="container">
          <div className="about-inr">
            <Row>
              <Col md={3} lg={3}>
                <div className="abt-lft-lnks">
                  <ul>
                    <li>
                      <Link to="/about-us">About Company</Link>
                    </li>
                    <li>
                      <Link to="/what-is-almabay">What is AlmaBay?</Link>
                    </li>
                    <li>
                      <Link to="/how-almabay-can-help-you">How we help you</Link>
                    </li>
                  </ul>
                </div>
              </Col>
              <Col md={9} lg={9}>
                <div className="abt-rt-dis">
                  <div className="title-card mb-2">
                    <h3 className="title-hd"> What is AlmaBay? </h3>
                  </div>
                  <span dangerouslySetInnerHTML={{ __html: content }} />
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </section>
      <FooterMain className={'colour-bg'} />

      {loading ? <Loader /> : ''}
    </>
  );
}

export default WhatIsAlmabay;
