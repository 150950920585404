import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
// import AsyncSelect from 'react-select/async';
import Select from 'react-select';
import AsyncCreatableSelect from 'react-select/async-creatable';
import * as yup from 'yup';
import instDummy from '../../../../assets/images/institute-dummy.jpg';
import {
  fieldOfStudyDropDown,
  getInstituteDrop,
  specializationDropDown
} from '../../../../services/AuthServices';
import { getUser, saveStepThree } from '../../../../services/InstituteServices';
import { getCourseOptList, getDeparDropdown } from '../../../../services/UserServices';
import './steps.scss';
import apiBase from '../../../../globals/config';

function Step3() {
  const { userId } = useParams();
  const navigate = useNavigate();
  const [show1, setShow1] = useState(false);
  const [educationDetails, setEducationDetails] = useState([]);
  const [inputMsg, setInputMsg] = useState('Please enter atleast 2 characters');
  const [loadInput, setLoadInput] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        const response = await getUser(userId);
        if (response.status === 200) {
          setEducationDetails(response.data?.data?.user_qualification);
        }
      } catch (error) {
        console.error(error);
      }
    })();
  }, [userId]);

  const {
    values,
    errors,
    touched,
    handleSubmit,
    setFieldValue,
    setFieldTouched,
    resetForm,
    handleBlur,
    handleChange
  } = useFormik({
    initialValues: {
      instituteName: '',
      dept: '',
      course: '',
      qualification: '',
      fieldOfStudy: '',
      specialization: '',
      registrationNumber: '',
      currentlyStydying: false,
      passoutYear: ''
    },
    validationSchema: yup.object().shape({
      instituteName: yup.object().shape({
        value: yup.string().required('This field is required'),
        label: yup.string().required()
      }),
      registrationNumber: yup.number().integer().typeError('Invalid registration number'),
      qualification: yup.string().when('course', {
        is: (value) => value?.__isNew__,
        then: () =>
          yup.object().shape({
            value: yup.string().required('This field is required'),
            label: yup.string().required()
          })
      }),
      passoutYear: yup.string().when('currentlyStydying', {
        is: (value) => !value,
        then: () =>
          yup.object().shape({
            value: yup.string().required('This field is required'),
            label: yup.string().required()
          })
      })
    }),
    onSubmit: async (values) => {
      const payload = new FormData();
      payload.append('user_id', userId);
      payload.append('instituteName', values?.instituteName?.label);
      payload.append('instituteType', values?.instituteName?.value);
      payload.append('program', values?.course?.value);
      payload.append('reg_id', values.registrationNumber);
      payload.append('curr_study', values.currentlyStydying);
      payload.append('passoutYear', values?.passoutYear?.value);
      payload.append('field_of_study', values.fieldOfStudy?.value ?? '');
      payload.append('specialization', values?.specialization?.value ?? '');
      payload.append('department', values.dept?.value);
      payload.append('qualification_type', values.qualification?.value);
      try {
        const resp = await saveStepThree(payload);
        if (resp.status === 200) {
          (async () => {
            try {
              const response = await getUser(userId);
              if (response.status === 200) {
                setEducationDetails(response.data?.data?.user_qualification);
                setShow1(false);
                resetForm();
              }
            } catch (error) {
              console.error(error);
            }
          })();
        }
      } catch (error) {
        console.error(error);
      }
    }
  });

  console.log('errors', errors);

  const loadInstOptions = async (inputValue) => {
    if (inputValue.length >= 2) {
      setLoadInput(true);
      try {
        const resp = inputValue && (await getInstituteDrop(inputValue));
        setLoadInput(false);
        if (resp.data?.length) {
          let array = await resp?.data?.map((data) => {
            return {
              label: data?.institutename,
              value: data?.institute_id,
              logo:
                data?.logo !== '' || data?.logo !== '0'
                  ? process.env.REACT_APP_API_URL + `common/uploads/institute_logo/` + data?.logo
                  : instDummy
            };
          });
          return array;
        } else {
          setInputMsg('No match found');
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoadInput(false);
      }
    }
  };

  const loadDeptOptions = async (inputVal) => {
    try {
      const res = await getDeparDropdown(inputVal);
      const newArr = await res?.data?.map((data) => {
        return {
          label: data?.institutename,
          value: data?.institute_id,
          logo: data?.logo !== '' ? data?.logo : instDummy
        };
      });
      return newArr;
    } catch (error) {
      console.error(error);
    }
  };

  const loadCourseOptions = async (inputVal) => {
    try {
      const resp = await getCourseOptList(inputVal);
      const newArr = await resp?.data?.map((data) => {
        return {
          label: data?.course_name,
          value: data?.course_id
        };
      });
      return newArr;
    } catch (error) {
      console.error(error);
    }
  };

  const loadFieldOfStudyOptions = async (inputVal) => {
    try {
      const resp = await fieldOfStudyDropDown(
        values?.instituteName?.value ? values?.instituteName?.value : '',
        values?.course?.value ? values?.course?.value : '',
        inputVal ? inputVal : ''
      );
      const newArr = await resp?.data?.data?.map((data) => {
        return {
          label: data?.name,
          value: data?.id
        };
      });
      return newArr;
    } catch (error) {
      console.error(error);
    }
  };

  const loadSpecializationOptions = async (inputVal) => {
    try {
      const resp = await specializationDropDown(
        inputVal ? inputVal : '',
        values.fieldOfStudy?.value ? values.fieldOfStudy?.value : ''
      );
      const newArr = await resp?.data?.map((data) => {
        return {
          label: data?.name,
          value: data?.id
        };
      });
      return newArr;
    } catch (error) {
      console.error(error);
    }
  };

  const qualificationLevelDropdown = [
    {
      label: 'High School',
      value: '0'
    },
    {
      label: 'Sr. Secondary School',
      value: '1'
    },
    {
      label: 'Bachelors Degree',
      value: '2'
    },
    {
      label: 'Masters Degree',
      value: '3'
    },
    {
      label: 'Doctorate/PhD',
      value: '4'
    },
    {
      label: 'Others',
      value: '5'
    }
  ];

  const theDates = [];
  for (let year = new Date().getFullYear(); year !== null; ) {
    for (let i = 0; i < 1; i++, year = year > 1900 ? year - 1 : null) {
      theDates.push({ label: year, value: year });
    }
  }

  // const editDetails = (data) => {
  //   console.log('data', data);
  //   setValues({
  //     ...values,
  //     instituteName: {
  //       label: data?.institute_name,
  //       value: data?.institute_id
  //     },
  //     dept: {
  //       label: data?.department_name,
  //       value: data?.department_id
  //     },
  //     course: {
  //       label: data?.program_name,
  //       value: data?.program_id
  //     },
  //     qualification: data?.qualification_type
  //     // fieldOfStudy: {
  //     //   label: data?.,
  //     //   value: data?.
  //     // },
  //     // specialization: {
  //     //   label: data?.,
  //     //   value: data?.
  //     // },
  //     // registrationNumber: data?.,
  //     // currentlyStydying: false,
  //     // passoutYear: {
  //     //   label: data?.,
  //     //   value: data?.
  //     // }
  //   });
  // };

  // console.log('qualification_type', values);

  return (
    <section className="steps-area step-one-area pt-5 pb-5">
      <div className="row">
        <div className="col-sm-4 col-lg-3 left-area left-side">
          <div className="about-you">
            <h1 className="mb-0">About You</h1>
            <h6>Basic information</h6>
            <p>Your basic information let others find you more easily.</p>
          </div>
        </div>

        <div className="col-sm-8 col-lg-9 right-area">
          <div className="steps-block">
            <ul className="nav nav-tabs">
              <li>
                <Link to={'/institute-register/step/1/' + userId}>
                  <span className="step-count">Step 1</span>
                  <span className="step-label">General information</span>
                </Link>
              </li>
              <li className="career-goals">
                <Link to={'/institute-register/step/2/' + userId}>
                  <span className="step-count">Step 2</span>
                  <span className="step-label">Career Goals</span>
                </Link>
              </li>
              <li className="current">
                <span className="step-count">Step 3</span>
                <span className="step-label">Educational Information</span>
              </li>
              <li>
                <Link to={'/institute-register/step/4/' + userId}>
                  <span className="step-count">Step 4</span>
                  <span className="step-label">Professional Information</span>
                </Link>
              </li>
              <li>
                <Link to={'/institute-register/step/5/' + userId}>
                  <span className="step-count">Step 5</span>
                  <span className="step-label">Upload profile picture</span>
                </Link>
              </li>
            </ul>
          </div>
          <div id="mentor-profile">
            {educationDetails?.length > 0 &&
              educationDetails?.map((data, index) => {
                return (
                  <div
                    key={index}
                    className="inner-box data-box registeration-form p-0"
                    id="education-details">
                    <div className="form-inner clearfix custom-tab">
                      <div className="box animated clearfix ng-scope" id="school-112020">
                        <div className="box-content d-flex align-items-center justify-content-between">
                          <div className="pull-left">
                            <h4 className="degree-mention ng-binding ng-scope">
                              {data?.currently_studing_here == 1 &&
                                'Currently Studying in ' + data?.course_name + ' at'}
                              {data?.currently_studing_here == 0 &&
                                'Studied ' + data?.course_name + ' at'}
                            </h4>
                            <h5 className="ng-binding ng-scope">{data?.institute_name}</h5>

                            <h6>
                              <span className="ng-binding ng-scope">
                                {data?.currently_studing_here == 1 && 'PRESENT'}{' '}
                                {data?.currently_studing_here == 1 && '|'}
                                {data?.city_name}
                              </span>
                            </h6>
                          </div>
                          <div
                            className="pull-right ng-scope"
                            title="Institute of Management Technology, Hyderabad">
                            <img
                              className="logo ng-scope"
                              height={50}
                              width={50}
                              src={
                                data?.logo !== ''
                                  ? apiBase + 'common/uploads/institute_logo/' + data?.logo
                                  : instDummy
                              }
                              alt="Institute of Management Technology, Hyderabad"
                            />
                          </div>
                          {/* <div className="inner-box">
                            <div className="recycle-icon">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                x="0px"
                                y="0px"
                                width="100"
                                height="100"
                                viewBox="0 0 48 48">
                                <path d="M 20.5 4 A 1.50015 1.50015 0 0 0 19.066406 6 L 14.640625 6 C 12.796625 6 11.086453 6.9162188 10.064453 8.4492188 L 7.6972656 12 L 7.5 12 A 1.50015 1.50015 0 1 0 7.5 15 L 40.5 15 A 1.50015 1.50015 0 1 0 40.5 12 L 40.302734 12 L 37.935547 8.4492188 C 36.913547 6.9162187 35.202375 6 33.359375 6 L 28.933594 6 A 1.50015 1.50015 0 0 0 27.5 4 L 20.5 4 z M 8.9726562 18 L 11.125 38.085938 C 11.425 40.887937 13.77575 43 16.59375 43 L 31.40625 43 C 34.22325 43 36.574 40.887938 36.875 38.085938 L 39.027344 18 L 8.9726562 18 z"></path>
                              </svg>
                            </div>
                            <div
                              className="edit-icon"
                              onClick={() => {
                                editDetails(data);
                                setShow1(!show1);
                              }}>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                x="0px"
                                y="0px"
                                width="100"
                                height="100"
                                viewBox="0 0 50 50">
                                <path d="M 43.125 2 C 41.878906 2 40.636719 2.488281 39.6875 3.4375 L 38.875 4.25 L 45.75 11.125 C 45.746094 11.128906 46.5625 10.3125 46.5625 10.3125 C 48.464844 8.410156 48.460938 5.335938 46.5625 3.4375 C 45.609375 2.488281 44.371094 2 43.125 2 Z M 37.34375 6.03125 C 37.117188 6.0625 36.90625 6.175781 36.75 6.34375 L 4.3125 38.8125 C 4.183594 38.929688 4.085938 39.082031 4.03125 39.25 L 2.03125 46.75 C 1.941406 47.09375 2.042969 47.457031 2.292969 47.707031 C 2.542969 47.957031 2.90625 48.058594 3.25 47.96875 L 10.75 45.96875 C 10.917969 45.914063 11.070313 45.816406 11.1875 45.6875 L 43.65625 13.25 C 44.054688 12.863281 44.058594 12.226563 43.671875 11.828125 C 43.285156 11.429688 42.648438 11.425781 42.25 11.8125 L 9.96875 44.09375 L 5.90625 40.03125 L 38.1875 7.75 C 38.488281 7.460938 38.578125 7.011719 38.410156 6.628906 C 38.242188 6.246094 37.855469 6.007813 37.4375 6.03125 C 37.40625 6.03125 37.375 6.03125 37.34375 6.03125 Z"></path>
                              </svg>
                            </div>
                          </div> */}
                        </div>
                        {/* {show ? (
                          <form className="edit-form edit-place">
                            <div className="form-elements">
                              <div className="row">
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label for="instituteName">
                                      Name of the Institute or University{' '}
                                      <span className="required-field">*</span>
                                    </label>
                                    <select type="select" className="form-control">
                                      <option>Institute</option>
                                      <option>Institute</option>
                                    </select>
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label for="instituteName">Name of the Department</label>
                                    <select type="select" className="form-control">
                                      <option>Department</option>
                                      <option>Department</option>
                                    </select>
                                  </div>
                                </div>
                              </div>
  
                              <div className="row">
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label for="program">Course / Program</label>
                                    <select type="select" className="form-control">
                                      <option>Course</option>
                                      <option>Course</option>
                                    </select>
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label for="passoutYear">Registration Number</label>
                                    <input
                                      type="text"
                                      id="reg_id"
                                      className="form-control"
                                      placeholder="Enter registration number (Optional)"
                                      value="0"
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group" id="passoutYearBlock">
                                    <label for="passoutYear">
                                      Passout year <span className="required-field">*</span>
                                    </label>
                                    <div id="year_from_present"></div>
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-md-6 col-xs-12">
                                  <span className="present input-field">Present</span>
                                  <div className="form-group">
                                    <div className="custom-checkbox">
                                      <input
                                        type="checkbox"
                                        name="currently_studing_here"
                                        id="cb_112020"
                                        className="currently_studing_here"
                                        value="1"
                                        checked="checked"
                                      />
                                      <label for="cb_112020">
                                        <b>I currently studying here.</b>
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-md-12 col-xs-12">
                                  <div className="action-btns">
                                    <a
                                      className="add-btn-grn mt-0 mb-2 me-3"
                                      href="javascript:void(0)">
                                      Save
                                    </a>
                                    <a
                                      className="btn btn-default add-btn-grn mt-0 mb-2"
                                      href="javascript:void(0)">
                                      Cancel
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </form>
                        ) : null} */}
                      </div>
                    </div>
                  </div>
                );
              })}

            <div className="gray-footer clearfix">
              <div className="gray-link float-start">
                <a
                  style={{
                    cursor: 'pointer'
                  }}
                  onClick={(e) => {
                    e.preventDefault();
                    resetForm();
                    setShow1(!show1);
                  }}
                  className="add-Workplace education-workspace float-end">
                  + Add an Educational Information
                </a>
              </div>

              {show1 ? (
                <form className="add-education edit-place pb-4">
                  <div className="form-elements">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label for="instituteName">
                            Name of the Institute or University{' '}
                            <span className="required-field">*</span>
                          </label>
                          <AsyncCreatableSelect
                            isSearchable={true}
                            loadOptions={loadInstOptions}
                            className="basic-single mt-1"
                            value={values.instituteName}
                            onChange={(e) => {
                              setFieldValue('instituteName', e);
                            }}
                            onBlur={() => {
                              setFieldTouched('instituteName', true);
                            }}
                            formatOptionLabel={(ins) => {
                              return (
                                <div className="ins-option">
                                  <img src={ins.logo ? ins.logo : instDummy} alt="logo" />
                                  <span>{ins.label}</span>
                                </div>
                              );
                            }}
                            placeholder="Select your institute"
                            onInputChange={(newValue) => {
                              newValue?.length <= 2 &&
                                setInputMsg('Please enter atleast 2 characters');
                            }}
                            noOptionsMessage={() => inputMsg}
                            components={{
                              DropdownIndicator: () => null,
                              IndicatorSeparator: () => null,
                              LoadingIndicator: () => null
                            }}
                          />
                          {loadInput && (
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'row-reverse',
                                margin: 'auto',
                                marginTop: '-25px'
                              }}>
                              <i className="fa fa-spinner fa-pulse me-2"></i>
                            </div>
                          )}
                          <p className="text-danger">
                            {touched.instituteName && errors.instituteName?.value}
                          </p>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label for="instituteName">Name of the Department</label>
                          <AsyncCreatableSelect
                            loadOptions={loadDeptOptions}
                            defaultOptions
                            className="basic-single mt-1"
                            name="dept"
                            value={values.dept}
                            onChange={(e) => {
                              setFieldValue('dept', e);
                            }}
                            formatOptionLabel={(dept) => {
                              return (
                                <div className="ins-option">
                                  <img src={dept.logo ? dept.logo : instDummy} alt="logo" />
                                  <span>{dept.label}</span>
                                </div>
                              );
                            }}
                            components={{
                              DropdownIndicator: () => null,
                              IndicatorSeparator: () => null,
                              LoadingIndicator: () => null
                            }}
                            placeholder="Select your department"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label for="program">Course / Program</label>
                          <AsyncCreatableSelect
                            loadOptions={loadCourseOptions}
                            defaultOptions
                            className="basic-single mt-1"
                            name="course"
                            value={values.course}
                            onChange={(e) => {
                              setFieldValue('course', e);
                            }}
                            components={{
                              DropdownIndicator: () => null,
                              IndicatorSeparator: () => null,
                              LoadingIndicator: () => null
                            }}
                            placeholder="Select your department"
                          />
                        </div>
                      </div>
                      {values?.course?.__isNew__ && (
                        <div className="col-md-6">
                          <div className="form-group">
                            <label for="passoutYear">Qualification Level</label>
                            <span className="required-field">*</span>
                            <Select
                              options={qualificationLevelDropdown}
                              className="basic-single mt-1"
                              name="qualification"
                              value={values.qualification}
                              onChange={(e) => {
                                setFieldValue('qualification', e);
                              }}
                              components={{
                                DropdownIndicator: () => null,
                                IndicatorSeparator: () => null,
                                LoadingIndicator: () => null
                              }}
                              onBlur={() => {
                                setFieldTouched('qualification', true);
                              }}
                              placeholder="Select education level"
                            />
                            <p className="text-danger">
                              {touched.qualification && errors.qualification?.value}
                            </p>
                          </div>
                        </div>
                      )}

                      {values?.course?.__isNew__ && (
                        <div className="col-md-6">
                          <label for="passoutYear">Field of study</label>
                          <div className="form-group">
                            <AsyncCreatableSelect
                              loadOptions={loadFieldOfStudyOptions}
                              defaultOptions
                              className="basic-single mt-1"
                              name="fieldOfStudy"
                              value={values.fieldOfStudy}
                              onChange={(e) => {
                                setFieldValue('fieldOfStudy', e);
                                loadSpecializationOptions(e?.label);
                              }}
                              components={{
                                DropdownIndicator: () => null,
                                IndicatorSeparator: () => null,
                                LoadingIndicator: () => null
                              }}
                              placeholder="Enter your field of study"
                            />
                          </div>
                        </div>
                      )}

                      {values?.course?.__isNew__ && (
                        <div className="col-md-6">
                          <label for="passoutYear">Specialization</label>
                          <div className="form-group">
                            <AsyncCreatableSelect
                              loadOptions={loadSpecializationOptions}
                              defaultOptions
                              className="basic-single mt-1"
                              name="specialization"
                              value={values.specialization}
                              onChange={(e) => {
                                setFieldValue('specialization', e);
                              }}
                              components={{
                                DropdownIndicator: () => null,
                                IndicatorSeparator: () => null,
                                LoadingIndicator: () => null
                              }}
                              placeholder="Enter your specialization"
                            />
                          </div>
                        </div>
                      )}

                      <div className="col-md-6">
                        <label for="passoutYear">Registration Number</label>
                        <div className="form-group">
                          <input
                            type="text"
                            id="reg_id"
                            className="form-control"
                            placeholder="Enter registration number (Optional)"
                            name="registrationNumber"
                            value={values.registrationNumber}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          <p className="text-danger">
                            {touched.registrationNumber && errors.registrationNumber}
                          </p>
                        </div>
                      </div>

                      {values.currentlyStydying === false ? (
                        <div className="col-md-6">
                          <div className="form-group" id="passoutYearBlock">
                            <label for="passoutYear">
                              Passout year <span className="required-field">*</span>
                            </label>
                            <Select
                              options={theDates}
                              className="basic-single mt-1"
                              name="passoutYear"
                              value={values.passoutYear}
                              onChange={(e) => {
                                setFieldValue('passoutYear', e);
                              }}
                              components={{
                                DropdownIndicator: () => null,
                                IndicatorSeparator: () => null,
                                LoadingIndicator: () => null
                              }}
                              placeholder="Select passout year"
                            />
                            <p className="text-danger">
                              {touched.passoutYear && errors.passoutYear?.value}
                            </p>
                            <div id="year_from_present"></div>
                          </div>
                        </div>
                      ) : (
                        <div className="col-md-6">
                          <label>
                            Passout year <span className="required-field">*</span>
                          </label>
                          <br />
                          <span className="present">Present</span>
                        </div>
                      )}
                    </div>
                    <div className="row">
                      <div className="col-md-6 col-xs-12">
                        <div className="form-group">
                          <div className="custom-checkbox">
                            <input
                              type="checkbox"
                              name="currentlyStydying"
                              id="cbe1"
                              className="currently_studing_here"
                              value={values.currentlyStydying}
                              onChange={(e) => {
                                handleChange(e);
                                if (e.target.checked == true) {
                                  setFieldValue('passoutYear', '');
                                }
                              }}
                              onBlur={handleBlur}
                              // checked={values.currentlyStydying}
                            />
                            &nbsp;
                            <label for="cbe1">I currently studying here.</label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12 col-xs-12">
                        <div className="action-btns">
                          <button
                            className="add-btn-grn mt-0 mb-2 me-3"
                            type="button"
                            onClick={handleSubmit}>
                            Save
                          </button>
                          <button
                            onClick={() => {
                              setShow1(false);
                              resetForm();
                            }}
                            className="btn btn-default add-btn-grn mt-0 mb-2">
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              ) : null}
              <span className="float-end">
                <button
                  type="button"
                  className="add-btn-grn skip-btn me-2"
                  onClick={() => {
                    navigate('/institute-register/step/4/' + userId);
                  }}>
                  Skip
                </button>
                <button
                  className="add-btn-grn"
                  type="button"
                  onClick={() => {
                    navigate('/institute-register/step/4/' + userId);
                  }}>
                  Next
                </button>
              </span>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Step3;
