import moment from 'moment';
import { useEffect, useState } from 'react';
import { Col, Container, Dropdown, Modal, OverlayTrigger, Popover, Row } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { truncate } from '../../../globals/helper';
import * as APISERVICES from '../../../services/MentorArticlesServices';
import showNotification from '../../../services/NotificationService';
import { MiniFooter } from '../../MiniFooter';
import HeaderLogin from '../../commoncomponents/HeaderLogin';
import GroupDiscover from '../../commoncomponents/newsfeeds/GroupDiscover';
import NewsSidebar from '../../commoncomponents/newsfeeds/NewsSidebar';
import SuggestMentor from '../../commoncomponents/newsfeeds/SuggestMentor';
import Upcoming from '../../commoncomponents/newsfeeds/Upcoming';
import WhatsNew from '../../commoncomponents/newsfeeds/WhatsNew';
import { MentorshipSkelton } from '../../commoncomponents/skelton/allskelton';
import SocialShare from './SocialShare';
import TopTabbings from './TopTabbings';

function MyArticles() {
  const [list, setList] = useState([]);
  const [pageNum, setPageNum] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [listData, setListData] = useState([]);
  const loginUserDetails = useSelector((data) => data?.user?.details);

  const [loading, setLoading] = useState(false);
  const [shareModelShow, setShareModelShow] = useState(false);
  const [socialShareData, setSocialShareData] = useState(false);
  const shareModelClose = () => {
    setShareModelShow(false);
  };
  useEffect(() => {
    if (pageNum <= totalPages) {
      getMyArticleList();
    }
  }, [pageNum]);

  const getMyArticleList = (isTrue) => {
    setLoading(true);
    APISERVICES.MyArticle(pageNum).then((res) => {
      setLoading(false);
      if (res?.status === 200) {
        if (isTrue && isTrue != 'rid') {
          let arra1 = list?.map((item) => {
            const item2 = res.data?.detail?.find((i2) => i2.id == item.id);
            return item ? { ...item, ...item2 } : item2;
          });
          setList(arra1);
        } else if (isTrue == 'rid') {
          setList(res.data.detail);
          return;
        } else {
          setList([...list, ...res.data.detail]);
        }
        setListData(res?.data?.detail); // used for searching purpose
        setTotalPages(res?.data?.totalRecords && Math.ceil(res?.data?.totalRecords / 20));
      }
    });
  };
  const deleteHandler = (id) => {
    if (confirm('Are you sure you want to delete article ?') === true) {
      APISERVICES.deleteMyArticle(id).then((res) => {
        if (res?.status === 200) {
          getMyArticleList('rid');
        }
      });
    } else {
      return;
    }
  };

  const HelpFullHandler = async (id, e) => {
    try {
      e.preventDefault();
      let body = {
        q_id: id,
        answer_id: '0',
        type: 'like'
      };
      let formData = new FormData();

      for (let i in body) {
        formData.append(`${i}`, body[i]);
      }

      const response = await APISERVICES.articleHelpful(formData);
      if (response?.status === 200) {
        getMyArticleList(true);
      }
    } catch (error) {
      console.error(error);
    } finally {
      showNotification('success', 'Marked as helpful, Success!');
    }
  };

  const handleSearch = (e) => {
    let value = e.target.value.toLowerCase();
    if (value !== '') {
      let result = listData.filter((data) => {
        return (
          data.title.toLowerCase().includes(value) ||
          data?.description.toLowerCase().includes(value)
        );
      });
      setList(result);
    } else {
      setList(listData);
    }
  };
  const loadMore = (e) => {
    e.preventDefault();
    setPageNum((no) => no + 1);
  };

  const renderPopover = (props) => {
    let popOverData = props?.popper?.state?.options?.loginUserDetails
      ? props?.popper?.state?.options?.loginUserDetails
      : props?.popper?.state?.options?.loginUserDetails;

    return (
      <Popover className="group-loader-card" id={`popover-basic`} {...props}>
        {/* <Popover.Header as="h3">Popover right</Popover.Header> */}
        <Popover.Body>
          <div className="loader-tooltip">
            <div className="loader-cover">
              <img
                src={process.env.REACT_APP_API_DOMAIN + popOverData?.avtar}
                alt="img"
                className="loader-image"
              />
            </div>
            <div className="people-know-loader-dis d-flex align-items-top">
              <div className="people-knw-top d-flex align-items-center">
                <div className="people-icn me-3">
                  <img
                    src={process.env.REACT_APP_API_DOMAIN + popOverData?.avtar}
                    alt="img"
                    className="loader-image"
                  />
                </div>
                <div className="">
                  <h3>{popOverData?.name}</h3>
                </div>
              </div>
            </div>
            <div className="people-knw-dis-popover">
              <p>
                <i className="fas fa-graduation-cap me-1"></i> Studied at{' '}
                <Link to="#">SD COLLEGE Chandigarh</Link> in <Link to="#">2005</Link>
              </p>
              {/* *have to add key for this, key not added from backend */}
              <p>
                <i className="fas fa-briefcase me-1"></i>Worked as{' '}
                <Link to="#">Software developer</Link> {' at '}{' '}
                {/* *have to add key for this, key not added from backend */}
                <Link to="#">TCS PVT LTD</Link>{' '}
                {/* *have to add key for this, key not added from backend */}
              </p>
            </div>
          </div>
        </Popover.Body>
      </Popover>
    );
  };

  return (
    <>
      <HeaderLogin showInvite={true} />
      <div className="header-lnk-feeds">
        <div className="container">
          <TopTabbings state={'mentor'} />
        </div>
      </div>
      <section className="news-feed-sec bg-grey py-4">
        <Container>
          <Row>
            <Col xs={12} lg={2} md={12}>
              <NewsSidebar state="2" current="my-articles" />
            </Col>
            <Col xs={12} lg={7} md={12}>
              <div className="articals_div mb-2">
                <div className="serch-mentors-data mb-2">
                  <Form.Control
                    type="search"
                    placeholder="Search in mentor's data..."
                    onChange={handleSearch}
                  />
                </div>

                {loading ? (
                  <>
                    {Array.from(Array(3), () => {
                      return <MentorshipSkelton />;
                    })}
                  </>
                ) : list && list?.length > 0 ? (
                  list?.map((data, index) => (
                    <div className="single_artical_card leave-ques-card p-3" key={index}>
                      <div className="d-flex align-items-center mb-3">
                        <div className="drop-ellipse-actn">
                          <Dropdown className="ellipse-drop-card ms-3">
                            <Dropdown.Toggle variant="success" id="dropdown-basic">
                              <i className="fas fa-angle-down"></i>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <Dropdown.Item as={Link} to={`/user/writeArticle?id=${data?.id}`}>
                                Edit{' '}
                              </Dropdown.Item>
                              <Dropdown.Item
                                href="#"
                                onClick={() => {
                                  deleteHandler(data?.id);
                                }}
                              >
                                Delete{' '}
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>

                        <div className="media_img">
                          <OverlayTrigger
                            trigger={['hover', 'focus']}
                            placement="bottom"
                            delay={{ show: 500, hide: 1000 }}
                            data={loginUserDetails}
                            popperConfig={{
                              loginUserDetails
                            }}
                            shouldUpdatePosition={true}
                            overlay={renderPopover}
                          >
                            <img
                              src={
                                loginUserDetails?.user
                                  ? process.env.REACT_APP_API_DOMAIN + loginUserDetails?.user?.avtar
                                  : process.env.REACT_APP_API_DOMAIN + loginUserDetails?.avtar
                              }
                              alt="user_img"
                              className="me-3"
                            />
                          </OverlayTrigger>

                          {/* <img
                            src={process.env.REACT_APP_API_DOMAIN + loginUserDetails?.avtar}
                            alt="user_img"
                            className="me-3"
                          /> */}
                        </div>
                        <div className="media-body">
                          <h4>
                            <Link to="#" className="myArticleName">
                              {loginUserDetails?.name}
                            </Link>
                          </h4>
                          <p title={data?.added_on}>{moment(data?.added_on).fromNow()}</p>
                        </div>
                      </div>
                      <div>
                        <h4 className="title">
                          <Link
                            to={`${data?.content_type === 'question'
                                ? `/user/viewanswer/${data?.id}`
                                : `/user/mentor-article/${data?.id}`
                              }`}
                          >
                            {data?.title}
                          </Link>
                        </h4>
                        <span
                          dangerouslySetInnerHTML={{ __html: truncate(data?.description, 500) }}
                        />
                      </div>
                      <div>
                        <Link
                          className="read-more"
                          to={`${data?.content_type === 'question'
                              ? `/user/viewanswer/${data?.id}`
                              : `/user/mentor-article/${data?.id}`
                            }`}
                        >
                          Read more...
                        </Link>
                      </div>
                      <div className="navigation-border">
                        <ul className="article-cmmnt-lnks d-flex ps-2">
                          <li className=" pe-3">
                            {data?.liked_by_current_user ? (
                              <Link className='active-helpful' title="You marked as helpful">
                                <i className="fa fa-heart "></i> Helpful{' '}
                                <span>
                                  ({data?.likes}) {'  '}
                                </span>
                              </Link>
                            ) : (
                              <Link
                                to="#"
                                className="pe-3"
                                title="Mark as helpful"
                                onClick={(e) => {
                                  HelpFullHandler(data?.id, e);
                                }}
                              >
                                <i className="fa fa-heart " title="Mark as helpful"></i> Helpful{' '}
                                <span>
                                  ({data?.likes}) {'  '}
                                </span>
                              </Link>
                            )}
                          </li>
                          <li>
                            <Link
                              to={`${data?.content_type === 'question'
                                  ? `/user/viewanswer/${data?.id}`
                                  : `/user/mentor-article/${data?.id}`
                                }`}
                              className="pe-3"
                            >
                              <i className="fa fa-comment"></i> {'  '}{' '}
                              {data?.content_type === 'question' ? 'Answers' : 'Comments'}{' '}
                              <span>({data?.comments})</span>
                            </Link>
                          </li>

                          <li className="share-drop-btn">
                            <Dropdown>
                              <Dropdown.Toggle className="pe-3" id="dropdown-basic">
                                <i className="fa fa-share"></i> Share
                              </Dropdown.Toggle>
                              <Dropdown.Menu className="p-2">
                                <Dropdown.Item
                                  as={Link}
                                  target="_blank"
                                  to={`https://www.facebook.com/sharer/sharer.php?u=${process.env.REACT_APP_DOMAIN +
                                    'user/mentor-article' +
                                    '/' +
                                    data?.id
                                    }`}
                                >
                                  Facebook
                                </Dropdown.Item>
                                <Dropdown.Item
                                  as={Link}
                                  target="_blank"
                                  to={`https://twitter.com/intent/tweet?url=${process.env.REACT_APP_DOMAIN +
                                    'user/mentor-article' +
                                    '/' +
                                    data?.id
                                    }`}
                                >
                                  Twitter
                                </Dropdown.Item>
                                <Dropdown.Item
                                  as={Link}
                                  target="_blank"
                                  to={`https://plus.google.com/share?url=${process.env.REACT_APP_DOMAIN +
                                    'user/mentor-article' +
                                    '/' +
                                    data?.id
                                    }`}
                                >
                                  Google
                                </Dropdown.Item>
                                <Dropdown.Item
                                  as={Link}
                                  target="_blank"
                                  to={`https://www.linkedin.com/shareArticle?url=${process.env.REACT_APP_DOMAIN +
                                    'user/mentor-article' +
                                    '/' +
                                    data?.id
                                    }&mini=true&title=${data?.title}&source=AlmaBay`}
                                >
                                  Linkedin
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </li>

                        </ul>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="p-5 bg-white text-center">
                    <img
                      src={require('../../../assets/images/small-error.png')}
                      width="105px"
                      alt="img"
                    />
                    <p>You have not posted any article at the moment.</p>
                  </div>
                )}
                {
                  // show this button only when last div is displayed and length is > 10
                }
                {
                  pageNum + 1 < totalPages && (
                    // list.length > 10 && (
                    <div className="load-more-btn py-3 text-center">
                      <Link to="#" className="read-more" onClick={(e) => loadMore(e)}>
                        Load More
                      </Link>
                    </div>
                  )
                  // )
                }
              </div>
            </Col>
            <Col lg={3} md={12}>
              <div>
                <SuggestMentor />
              </div>
              <div>
                <GroupDiscover />
              </div>
              <div>
                <WhatsNew />
              </div>
              <div>
                <Upcoming />
              </div>
              <MiniFooter />
            </Col>
          </Row>
        </Container>
        <Modal show={shareModelShow} onHide={shareModelClose}>
          <Modal.Header closeButton>
            <Modal.Title>share to</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <SocialShare
              facebookOptions={{
                url:
                  process.env.REACT_APP_DOMAIN + 'user/mentor-article' + '/' + socialShareData.id,
                quote: socialShareData?.title
              }}
              twitterOptions={{
                url:
                  process.env.REACT_APP_DOMAIN + 'user/mentor-article' + '/' + socialShareData.id,
                title: socialShareData?.title
              }}
              linkedInOptions={{
                title: socialShareData?.title,
                summary: socialShareData?.description,
                url:
                  process.env.REACT_APP_DOMAIN + 'user/mentor-article' + '/' + socialShareData.id,
                source:
                  process.env.REACT_APP_DOMAIN + 'user/mentor-article' + '/' + socialShareData.id
              }}
            />
          </Modal.Body>
        </Modal>
      </section>
    </>
  );
}

export default MyArticles;
