import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import logo from '../../../assets/images/almabay-logo-light.png';
import FooterMain from '../../commoncomponents/FooterMain';
import Header from '../../commoncomponents/HeaderMain';
import withErrorBoundary from '../../commoncomponents/withErrorBoundary';
// import reactReferer from 'react-referer';

function LandingPage() {
  // useEffect(() => {

  //   console.log("document", reactReferer);

  // }, []);

  return (
    <>
      <Header />
      <section className="home-main py-5">
        <div className="container">
          <Row>
            <Col md={{ span: 6, offset: 3 }}>
              <div className="home-lft-card">
                <div className="home-main-logo text-center mb-5">
                  <img src={logo} className="App-logo" alt="logo" />
                </div>
                <div className="home-lft-dis">
                  <h1>Connect & Grow with your Alma Mater on AlmaBay</h1>
                  <ul>
                    <li>
                      <div className="media">
                        <div className="media-left">
                          <img
                            className="media-object"
                            src={require('../../../assets/images/group-icon.png')}
                            alt=""
                          />
                        </div>
                        <div className="media-body">
                          Connect with your previous institutions to find friends and classmates
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="media">
                        <div className="media-left">
                          <img
                            className="media-object"
                            src={require('../../../assets/images/jobs-icon.png')}
                            alt=""
                          />
                        </div>
                        <div className="media-body">
                          Get best job opportunities by people already working in companies
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="media">
                        <div className="media-left">
                          <img
                            className="media-object"
                            src={require('../../../assets/images/group-icon.png')}
                            alt=""
                          />
                        </div>
                        <div className="media-body">
                          Engage alumni and student body for professional networking.
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
                <div className="play-store text-center">
                  <a
                    href="https://play.google.com/store/apps/details?id=com.almabay"
                    rel="noreferrer"
                    target={'_blank'}
                  >
                    <img src={require('../../../assets/images/google-play-btn.png')} alt="play" />
                  </a>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </section>
      <FooterMain />
    </>
  );
}

export default withErrorBoundary(LandingPage);
