import { Button, Form } from 'react-bootstrap';
import { EmailEditor } from 'react-email-editor';
import CKEditorForCommunication from './CKEditorForCommunication';

const EmailCommunication = ({
  dropdownValue,
  checkedName,
  emailEditorRef,
  onLoad,
  content,
  setContent,
  subject,
  setSubject,
  communicatinDetails,
  templates,
  setSelectedIndex,
  htmlContent,
  setHtmlContent,
  handleChange,
  communication,
  communicate
}) => {
  return (
    <Form>
      <Form.Group className="mb-3" controlId="formBasicPassword">
        <Form.Select
          onChange={(e) => {
            handleChange(e);
            setSelectedIndex(communication === 'email' && e.target.selectedIndex);
            setSubject(templates[e.target.selectedIndex - 1]?.subject ?? '');
            setContent(templates[e.target.selectedIndex - 1]?.html_template ?? '');
          }}
          value={communicatinDetails?.template_id}
          aria-label="Default select example"
          name="status"
        >
          <option value="">Select Template</option>
          {templates?.map((data, index) => {
            return <option key={index} value={data?.id}>{data?.title}</option>;
          })}
        </Form.Select>
      </Form.Group>
      <p>Send Email</p>
      <Form.Group className="mb-3" controlId="formBasicPassword">
        <Form.Label>
          To <span className="text-danger">*</span>
        </Form.Label>
        <div className="members_name_wrapper">
          <div className="members_name">
            {checkedName?.map((name, index) => {
              return <span key={index}>{name}</span>;
            })}
          </div>
        </div>
      </Form.Group>
      <Form.Group className="mb-3" controlId="formBasicPassword">
        <Form.Label>
          Subject <span className="text-danger">*</span>
        </Form.Label>
        <Form.Control
          onChange={(e) => {
            setSubject(e.target.value);
          }}
          value={subject}
          type="text"
          placeholder="Enter subject"
          name="subject"
          autoComplete="Off"
        />
      </Form.Group>
      <Form.Group className="mb-3" controlId="formBasicPassword">
        <Form.Label>
          Description <span className="text-danger">*</span>
        </Form.Label>
        {content?.length > 0 ? (
          <EmailEditor ref={emailEditorRef} onReady={onLoad} />
        ) : (
          <CKEditorForCommunication
            isToolbar={true}
            htmlContent={htmlContent}
            setHtmlContent={setHtmlContent}
          />
        )}
      </Form.Group>
      <div className="d-flex justify-content-between">
        <p>
          <sup>
            <b>*</b>
          </sup>
          Sending this mail to {dropdownValue() !== 'All' ? 0 : null} {dropdownValue()}
        </p>
        <Button
          onClick={(e) => {
            communicate(e);
          }}
        >
          Send
        </Button>
      </div>
    </Form>
  );
};

export default EmailCommunication;
