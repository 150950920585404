/**
@copyright  :   ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
@author     :   Shiv Charan Panjeta 
 
All Rights Reserved.
Proprietary and confidential :  All information contained here in is, and remains
the property of ToXSL Technologies Pvt. Ltd. and it's partners.
Unauthorized copying of this file, via any medium is strictly prohibited.
*/

import { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';

import Nav from 'react-bootstrap/Nav';

import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import showNotification from '../../services/NotificationService';
import {
  addEmailTemplate,
  getSingleEmailTemplateDetail,
  getTemplateTokenListByApplicable
} from '../../services/TemplateServices';

import { getInstAdminList } from '../../services/AuthServices';
import AddEmailBasicSettings from './AddEmailBasicSettings';
import BuildEmailTemplate from './BuildEmailTemplate';
import SecondTabEmailTemp from './TemplateComponents/SecondTabEmailTemp';

export default function AddEmailTemplate() {
  const id = new URLSearchParams(location.search).get('id') ?? '';
  const [loading, setLoading] = useState(false);
  const [step, setstep] = useState(1);
  const [templateTitle, setTemplateTitle] = useState('');
  const [applicableFor, setApplicableFor] = useState('');
  const [nameOfTemplate, setNameOfTemplate] = useState('');
  const [content, setContent] = useState('');
  const [selectedFile, setSelectedFile] = useState('');
  const [tokenId, setTokenId] = useState('');
  const [documentType, setDocumentType] = useState('');
  const [status, setStatus] = useState(1);
  const [show, setShow] = useState(false);
  const [value, setValue] = useState(0);
  const [assignMemberList, setAssignMemberList] = useState([]);
  const [assignMemberList2, setAssignMemberList2] = useState([]);
  const [idAdd, setIdAdd] = useState('');
  const [instID, setInstID] = useState();
  const [isSelectedFile, setIsSelectedFile] = useState(false);
  const [tokenList, setTokenList] = useState([]);
  const [savedDesign, setSavedDesign] = useState();
  const [templateData, setTemplateData] = useState();

  const [emailLayoutTab, setEmailLayoutTab] = useState();
  const [applicableForOption, setApplicableForOption] = useState(false);
  const idToken = id ? id : idAdd;

  useEffect(() => {
    if (idToken) {
      getEmailTemplateDetail(idToken);
    }
  }, [idToken, id, idAdd]);
  const handleClose = () => {
    setShow(false);
  };
  const nextStep = () => {
    setstep(step + 1);
  };
  const [active, setActive] = useState('first');

  const activeTab = (state) => {
    setActive(state);
  };

  const membersID = assignMemberList?.map((item) => {
    return item.id;
  });
  const insituteMembersList = async () => {
    const resp = await getInstAdminList(value.institute_id ? value?.institute_id : instID);
    if (resp.status === 200) {
      setLoading(false);
      if (value.institute_id) {
        showNotification('success', resp?.data?.message);
      }

      const newArray = resp.data.data?.map((item) => {
        return {
          id: item?.user_id,
          value: item?.user_id,
          label: item.name
        };
      });
      setAssignMemberList(newArray);
      setAssignMemberList2(newArray);
    } else {
      setAssignMemberList2([]);
    }
  };

  const checkCredentials = () => {
    if (templateTitle.trim() === '' || templateTitle.trim().length <= 0) {
      showNotification('danger', 'Template title field required');
      return false;
    }
    if (applicableFor === '') {
      showNotification('danger', 'Applicable for field required');
      return false;
    }
    if (nameOfTemplate.trim() === '') {
      showNotification('danger', 'Name Of Template field required');
      return false;
    }
    if (tokenId === '') {
      showNotification('danger', 'Subject Token field required');
      return false;
    }
    if (value == '') {
      showNotification('danger', 'Institute field required');
      return false;
    }
    if (membersID == '') {
      showNotification('danger', 'Select assign users');
      return false;
    }
    return true;
  };
  const saveEmailTemplate = () => {
    if (checkCredentials()) {
      setLoading(true);
      let formData = new FormData();
      formData.append('title', templateTitle);
      formData.append('applicable_for', applicableFor);
      formData.append('template_name', nameOfTemplate);
      formData.append('subject', content);
      formData.append('document_file', selectedFile);
      formData.append('subject_token', tokenId);
      formData.append('document_type', documentType);
      formData.append('state_id', status);
      formData.append('campus_id', value.institute_id);
      formData.append('assign_user', membersID.toString());
      addEmailTemplate(formData)
        .then((res) => {
          if (res?.status == 200) {
            activeTab('second');
            setLoading(false);
            setIdAdd(res?.data?.details?.id);
            showNotification('success', res?.data?.message);
            // navigate('/admin/ManageTemplate');
            nextStep();
          } else {
            setLoading(false);
          }
        })
        .catch((err) => {
          setLoading(false);
        });
    }
  };
  const applicableForFun = (applicable_for_type) => {
    setApplicableForOption(true);
    setApplicableFor(applicable_for_type);
    setLoading(true);
    getTemplateTokenListByApplicable(applicable_for_type).then((resp) => {
      if (resp?.status === 200) {
        setLoading(false);
        setTokenList(resp?.data?.data);
      } else {
        setApplicableForOption(false);
      }
    });
  };

  const onEditSetStateValue = async (data, actionType = 'update', stateValue = null) => {
    // setEmailLayout(data?.email_layout);
    applicableForFun(data?.applicable_for);
  };

  const getEmailTemplateDetail = () => {
    setLoading(true);
    getSingleEmailTemplateDetail(idToken).then((resp) => {
      if (resp?.status === 200) {
        setTemplateTitle(resp?.data?.detail?.title);
        setApplicableFor(resp?.data?.detail?.applicable_for);
        setContent(resp?.data?.detail?.subject);
        setTokenId(resp?.data?.detail?.subject_token);
        setStatus(resp?.data?.detail?.state_id);
        applicableForFun(resp?.data?.detail?.applicable_for);
        setNameOfTemplate(resp?.data?.detail?.template_name);
        setDocumentType(resp?.data?.detail?.document_type);
        setSelectedFile(resp?.data?.detail?.document_file || '');
        setInstID(resp?.data?.detail?.campus_id);
        setTemplateData(resp?.data?.detail?.html);
        setValue({ name: resp?.data?.detail?.institute_name });

        setIsSelectedFile(true);
        setSavedDesign(resp?.data?.detail?.html_template);
        onEditSetStateValue(resp.data.detail);
        setEmailLayoutTab(resp?.data?.detail?.email_layout);
        setLoading(false);
      } else {
        setLoading(false);
      }
    });
  };

  const switchMultiStep = () => {
    switch (step) {
      case 1:
        return (
          <AddEmailBasicSettings
            nextStep={nextStep}
            activeTab={activeTab}
            id={id}
            idAdd={idAdd}
            templateTitle={templateTitle}
            setTemplateTitle={setTemplateTitle}
            setApplicableFor={setApplicableFor}
            applicableFor={applicableFor}
            setContent={setContent}
            content={content}
            setTokenId={setTokenId}
            tokenId={tokenId}
            setStatus={setStatus}
            status={status}
            setNameOfTemplate={setNameOfTemplate}
            nameOfTemplate={nameOfTemplate}
            setSelectedFile={setSelectedFile}
            selectedFile={selectedFile}
            setDocumentType={setDocumentType}
            documentType={documentType}
            saveEmailTemplate={saveEmailTemplate}
            setLoading={setLoading}
            loading={loading}
            value={value}
            setValue={setValue}
            insituteMembersList={insituteMembersList}
            setAssignMemberList={setAssignMemberList}
            setAssignMemberList2={setAssignMemberList2}
            assignMemberList={assignMemberList}
            assignMemberList2={assignMemberList2}
            setInstID={setInstID}
            instID={instID}
            checkCredentials={checkCredentials}
            membersID={membersID}
            isSelectedFile={isSelectedFile}
            setIsSelectedFile={setIsSelectedFile}
            applicableForFun={applicableForFun}
            tokenList={tokenList}
            setTokenList={setTokenList}
            getEmailTemplateDetail={getEmailTemplateDetail}
            emailLayoutTab={emailLayoutTab}
            applicableForOption={applicableForOption}
          />
        );

      case 2:
        return (
          <SecondTabEmailTemp
            nextStep={nextStep}
            activeTab={activeTab}
            id={id}
            idAdd={idAdd}
            templateData={templateData}
            emailLayoutTab={emailLayoutTab}
          />
        );
      case 3:
        return (
          <BuildEmailTemplate
            nextStep={nextStep}
            activeTab={activeTab}
            id={id}
            idAdd={idAdd}
            savedDesign={savedDesign}
            getEmailTemplateDetail={getEmailTemplateDetail}
            tokenList={tokenList}
            setTokenList={setTokenList}
            onEditSetStateValue={onEditSetStateValue}
          />
        );

      default:
        return (
          <AddEmailBasicSettings
            nextStep={nextStep}
            loading={loading}
            setLoading={setLoading}
            activeTab={activeTab}
            id={id}
            idAdd={idAdd}
            templateTitle={templateTitle}
            setTemplateTitle={setTemplateTitle}
            setApplicableFor={setApplicableFor}
            applicableFor={applicableFor}
            setContent={setContent}
            content={content}
            setTokenId={setTokenId}
            tokenId={tokenId}
            setStatus={setStatus}
            status={status}
            setNameOfTemplate={setNameOfTemplate}
            nameOfTemplate={nameOfTemplate}
            setSelectedFile={setSelectedFile}
            selectedFile={selectedFile}
            setDocumentType={setDocumentType}
            documentType={documentType}
            saveEmailTemplate={saveEmailTemplate}
            value={value}
            setValue={setValue}
            insituteMembersList={insituteMembersList}
            setAssignMemberList={setAssignMemberList}
            setAssignMemberList2={setAssignMemberList2}
            assignMemberList={assignMemberList}
            assignMemberList2={assignMemberList2}
            setInstID={setInstID}
            instID={instID}
            checkCredentials={checkCredentials}
            membersID={membersID}
            isSelectedFile={isSelectedFile}
            setIsSelectedFile={setIsSelectedFile}
            applicableForFun={applicableForFun}
            tokenList={tokenList}
            setTokenList={setTokenList}
            getEmailTemplateDetail={getEmailTemplateDetail}
            emailLayoutTab={emailLayoutTab}
            applicableForOption={applicableForOption}
          />
        );
    }
  };

  useEffect(() => {
    if (active == 'first') {
      <AddEmailBasicSettings
        nextStep={nextStep}
        activeTab={activeTab}
        idAdd={idAdd}
        id={id}
        loading={loading}
        setLoading={setLoading}
        templateTitle={templateTitle}
        setTemplateTitle={setTemplateTitle}
        setApplicableFor={setApplicableFor}
        applicableFor={applicableFor}
        setContent={setContent}
        content={content}
        setTokenId={setTokenId}
        tokenId={tokenId}
        setStatus={setStatus}
        status={status}
        setNameOfTemplate={setNameOfTemplate}
        nameOfTemplate={nameOfTemplate}
        setSelectedFile={setSelectedFile}
        selectedFile={selectedFile}
        setDocumentType={setDocumentType}
        documentType={documentType}
        saveEmailTemplate={saveEmailTemplate}
        value={value}
        setValue={setValue}
        insituteMembersList={insituteMembersList}
        setAssignMemberList={setAssignMemberList}
        setAssignMemberList2={setAssignMemberList2}
        assignMemberList={assignMemberList}
        assignMemberList2={assignMemberList2}
        setInstID={setInstID}
        instID={instID}
        checkCredentials={checkCredentials}
        membersID={membersID}
        isSelectedFile={isSelectedFile}
        setIsSelectedFile={setIsSelectedFile}
        applicableForFun={applicableForFun}
        tokenList={tokenList}
        setTokenList={setTokenList}
        emailLayoutTab={emailLayoutTab}
        applicableForOption={applicableForOption}
      />;
    } else if (active == 'second') {
      <SecondTabEmailTemp
        nextStep={nextStep}
        activeTab={activeTab}
        id={id}
        idAdd={idAdd}
        emailLayoutTab={emailLayoutTab}
        templateData={templateData}
      />;
    } else if (active === 'third') {
      <BuildEmailTemplate
        nextStep={nextStep}
        activeTab={activeTab}
        id={id}
        idAdd={idAdd}
        savedDesign={savedDesign}
        getEmailTemplateDetail={getEmailTemplateDetail}
        tokenList={tokenList}
        setTokenList={setTokenList}
        onEditSetStateValue={onEditSetStateValue}
      />;
    }
  }, []);

  return (
    <div className="dash-main-content">
      <Container fluid>
        <div className="dash-page-content">
          <div className="dash-page-title">
            <Row>
              <Col xs={12} sm={12} md={12}>
                <div className="dash-title-bar d-flex align-items-center justify-content-between">
                  <div className="title-lft d-flex align-items-center">
                    <div className="dash-icn">
                      <i className="fas fa-briefcase"></i>
                    </div>
                    <div className="dash-title-dis">
                      {id ? <h5>Update Email Template</h5> : <h5>Add Email Template</h5>}
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <div className="dash-content-card">
            <Tab.Container id="left-tabs-example" activeKey={show == true ? '' : active}>
              <Row>
                <Col sm={12}>
                  <Nav variant="pills" className="template-top-btn mb-4">
                    <Nav.Item>
                      <Nav.Link
                        disabled
                        eventKey="first"
                        onClick={(e) => {
                          e.preventDefault();
                          switchMultiStep(1);
                        }}>
                        1
                      </Nav.Link>
                    </Nav.Item>

                    <Nav.Item>
                      <Nav.Link
                        disabled
                        eventKey="second"
                        onClick={(e) => {
                          e.preventDefault();
                          switchMultiStep(2);
                        }}>
                        2
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        disabled
                        eventKey="third"
                        onClick={(e) => {
                          e.preventDefault();
                          switchMultiStep(3);
                        }}>
                        3
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                </Col>
                <Col sm={12}>
                  <Tab.Content>
                    <Tab.Pane eventKey="first">
                      <AddEmailBasicSettings
                        nextStep={nextStep}
                        activeTab={activeTab}
                        id={id}
                        loading={loading}
                        setLoading={setLoading}
                        templateTitle={templateTitle}
                        setTemplateTitle={setTemplateTitle}
                        setApplicableFor={setApplicableFor}
                        applicableFor={applicableFor}
                        setContent={setContent}
                        content={content}
                        setTokenId={setTokenId}
                        tokenId={tokenId}
                        setStatus={setStatus}
                        status={status}
                        setNameOfTemplate={setNameOfTemplate}
                        nameOfTemplate={nameOfTemplate}
                        setSelectedFile={setSelectedFile}
                        selectedFile={selectedFile}
                        setDocumentType={setDocumentType}
                        documentType={documentType}
                        saveEmailTemplate={saveEmailTemplate}
                        value={value}
                        setValue={setValue}
                        insituteMembersList={insituteMembersList}
                        setAssignMemberList={setAssignMemberList}
                        setAssignMemberList2={setAssignMemberList2}
                        assignMemberList={assignMemberList}
                        assignMemberList2={assignMemberList2}
                        idAdd={idAdd}
                        setInstID={setInstID}
                        instID={instID}
                        checkCredentials={checkCredentials}
                        membersID={membersID}
                        isSelectedFile={isSelectedFile}
                        setIsSelectedFile={setIsSelectedFile}
                        applicableForFun={applicableForFun}
                        tokenList={tokenList}
                        setTokenList={setTokenList}
                        getEmailTemplateDetail={getEmailTemplateDetail}
                        emailLayoutTab={emailLayoutTab}
                        applicableForOption={applicableForOption}
                      />
                    </Tab.Pane>
                    {emailLayoutTab == 1 ? (
                      <Tab.Pane eventKey="third">
                        <BuildEmailTemplate
                          nextStep={nextStep}
                          activeTab={activeTab}
                          id={id}
                          idAdd={idAdd}
                          savedDesign={savedDesign}
                          getEmailTemplateDetail={getEmailTemplateDetail}
                          tokenList={tokenList}
                          setTokenList={setTokenList}
                          onEditSetStateValue={onEditSetStateValue}
                        />
                      </Tab.Pane>
                    ) : (
                      <>
                        <Tab.Pane eventKey="second">
                          <SecondTabEmailTemp
                            nextStep={nextStep}
                            activeTab={activeTab}
                            id={id}
                            idAdd={idAdd}
                            emailLayoutTab={emailLayoutTab}
                            templateData={templateData}
                          />
                        </Tab.Pane>
                        <Tab.Pane eventKey="third">
                          <BuildEmailTemplate
                            nextStep={nextStep}
                            activeTab={activeTab}
                            id={id}
                            idAdd={idAdd}
                            savedDesign={savedDesign}
                            getEmailTemplateDetail={getEmailTemplateDetail}
                            tokenList={tokenList}
                            setTokenList={setTokenList}
                            onEditSetStateValue={onEditSetStateValue}
                          />
                        </Tab.Pane>
                      </>
                    )}
                  </Tab.Content>
                </Col>
              </Row>
            </Tab.Container>
          </div>
        </div>
      </Container>
    </div>
  );
}
