import moment from 'moment';
import { useEffect, useState } from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { getBlogDetail, getDatesData, getTagsData } from '../../../services/InstituteServices';
import Loader from '../../commoncomponents/loader/loader-large';

function BlogPage() {
  const [blogList, setBlogList] = useState([]);
  const [tagList, setTagList] = useState([]);
  const [dateList, seDateList] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    getBlog();
    getTags();
    getDates();
  }, []);

  const getBlog = () => {
    getBlogDetail().then((res) => {
      setLoading(false)
      if (res?.status == 200) {
        setBlogList(res?.data?.data);
      }
    });
  };

  const getDates = () => {
    getDatesData().then((res) => {
      if (res?.status == 200) {
        seDateList(res?.data?.data?.output);
      }
    });
  };

  const getTags = () => {
    getTagsData().then((res) => {
      if (res?.status == 200) {
        setTagList(res?.data?.details);
      }
    });
  };

  return (
    <>
      <div className="wrappermain_outer">
        <section
          className="breadcrum-banner"
          style={{
            backgroundImage: `url(${blogList?.banner?.url
              ? process.env.REACT_APP_API_URL + blogList?.banner?.url
              : '/static/media/breadcrum-banner-img.1a6b2ed71ebb8768883c.png'
              })`
          }}
        >
          <Container>
            <Row>
              <Col sm={12}>
                <h1 className="Text-white">{blogList?.banner?.name || 'Blogs'}</h1>
              </Col>
            </Row>
          </Container>
        </section>
        <section className="gallery-section">
          <Container>
            <Row>
              <Col sm={12}></Col>

              <Col lg={8}>
                <div className="blog_container">
                  <ul>
                    {blogList?.details && blogList?.details?.length ? (
                      blogList?.details?.map((data, index) => (
                        <li className="mb-3" key={index}>
                          <Link to={`/blog-details/${data?.slug}`}>
                            <Card className="p-3">
                              <div className="media">
                                {/* src={process.env.REACT_APP_API_DOMAIN + "common/uploads/blog/" + attachment} */}
                                <img
                                  src={
                                    data?.image
                                      ? process.env.REACT_APP_API_DOMAIN +
                                      'common/uploads/blog/' +
                                      data?.image
                                      : require('../../../assets/images/institute-blog-img.png')
                                  }
                                  alt="blog"
                                  className="me-3"
                                />
                                <div className="media-body">
                                  <p className="mb-0">
                                    {data?.created_on
                                      ? moment(data?.created_on).format('MMM DD,YYYY')
                                      : ''}
                                  </p>
                                  <h5 className="mb-0"> {data?.title}</h5>
                                </div>
                              </div>
                            </Card>
                          </Link>
                        </li>
                      ))
                    ) : (
                      <div className={loading ? "d-none" : "text-center nodata"}>
                        <img src={require('../../../assets/images/nodata.png')} alt="blog" />
                        <p>Data Not Found</p>
                      </div>
                    )}
                  </ul>
                </div>
              </Col>
              {dateList?.length || tagList?.length ? (
                <Col lg={4}>
                  <div className="archive-section mb-4">
                    <h4>Archive</h4>
                    <ul>
                      {dateList && dateList?.length ? (
                        dateList?.map((month, index) => (
                          <li key={index}>
                            <Link>
                              {month?.month_name} {month?.year_name} ({month?.count})
                            </Link>
                          </li>
                        ))
                      ) : (
                        <div className={loading ? "d-none" : "text-center nodata"}>
                          <img alt="blog" src={require('../../../assets/images/nodata.png')} />
                          <p>Data Not Found</p>
                        </div>
                      )}
                    </ul>
                  </div>

                  <div className="tags_container mb-5">
                    <h5>Tags</h5>
                    <div className="d-flex flex-wrap">
                      {tagList && tagList?.length ? (
                        tagList?.map((data, index) => (
                          <span className="single-tag m-2" key={index}>
                            {data?.title}
                          </span>
                        ))
                      ) : (
                        <div className={loading ? "d-none" : "text-center nodata"}>
                          <img alt="blog" src={require('../../../assets/images/nodata.png')} />
                          <p>Data Not Found</p>
                        </div>
                      )}
                    </div>
                  </div>
                </Col>
              ) : (
                <></>
              )}
            </Row>
          </Container>
        </section>
      </div>
      <div className="lgn-link py-4">
        <Container>
          <div className="logn-lnk-inr">
            <p>
              <Link to="/institute-register">Register</Link> /{' '} <Link to="/institute-login">Login</Link> to browse
              members by Year, Name, Company, Profession or Location
            </p>
          </div>
        </Container>
      </div>
      {loading ? <Loader /> : <></>}
    </>
  );
}

export default BlogPage;
