/*
@copyright : ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
@author    : Shiv Charan Panjeta 
 
All Rights Reserved.
Proprietary and confidential :  All information contained here in is, and remains
the property of ToXSL Technologies Pvt. Ltd. and it's partners.
Unauthorized copying of this file, via any medium is strictly prohibited.
*/

import { Route, Routes } from 'react-router-dom';
import PageNotFound from '../../components/commoncomponents/PageNotFound';
import AddCampus from '../../components/webpages/user-pages/AddCampus';
import AddPollQuestion from '../../components/webpages/user-pages/AddPollQuestion';
import AlbumManager from '../../components/webpages/user-pages/AlbumManager';
import AnswersRequests from '../../components/webpages/user-pages/AnswersRequests';
import AppliedDetails from '../../components/webpages/user-pages/AppliedDetails';
import AskQuestion from '../../components/webpages/user-pages/AskQuestion';
import BirthdayDetails from '../../components/webpages/user-pages/BirthdayDetails';
import BirthdayPage from '../../components/webpages/user-pages/BirthdayPage';
import CampusNews from '../../components/webpages/user-pages/CampusNews';
import CampusnewsDetails from '../../components/webpages/user-pages/CampusnewsDetails';
import ChapterDetail from '../../components/webpages/user-pages/ChapterDetail';
import ChaptersDiscover from '../../components/webpages/user-pages/ChaptersDiscover';
import ClubDetail from '../../components/webpages/user-pages/ClubDetail';
import ClubPage from '../../components/webpages/user-pages/ClubPage';
import CreateAlbum from '../../components/webpages/user-pages/CreateAlbum';
import EditAnswerPage from '../../components/webpages/user-pages/EditAnswerPage';
import EventTicketSummary from '../../components/webpages/user-pages/EventTicketSummary';
import EventBookingCompleted from '../../components/webpages/user-pages/Events/EventBookingCompleted';
import EventsCalendar from '../../components/webpages/user-pages/Events/EventCalendar';
import EventsDetail from '../../components/webpages/user-pages/Events/EventsDetail';
import EventsPage from '../../components/webpages/user-pages/Events/EventsPage';
import FindFriend from '../../components/webpages/user-pages/FindFriend';
import FriendRequests from '../../components/webpages/user-pages/FriendRequests';
import Friends from '../../components/webpages/user-pages/Friends';
import GroupDetail from '../../components/webpages/user-pages/GroupDetails';
import GroupsPage from '../../components/webpages/user-pages/GroupsPage';
import Hashtag from '../../components/webpages/user-pages/Hashtag';
import JobfairDetails from '../../components/webpages/user-pages/JobfairDetails';
import JobsMap from '../../components/webpages/user-pages/JobsMap';
import Mentees from '../../components/webpages/user-pages/Mentees';
import MentorArticleDetails from '../../components/webpages/user-pages/MentorArticleDetails';
import MentorQuestionDetail from '../../components/webpages/user-pages/MentorQuestionDetail';
import Mentors from '../../components/webpages/user-pages/Mentors';
import MessagesPage from '../../components/webpages/user-pages/MessagesPage';
import MyAnswers from '../../components/webpages/user-pages/MyAnswers';
import MyArticles from '../../components/webpages/user-pages/MyArticles';
import MyQuestion from '../../components/webpages/user-pages/MyQuestion';
import NewsPage from '../../components/webpages/user-pages/NewsPage';
import NewsPageDetail from '../../components/webpages/user-pages/NewsPageDetail';
import NotificationPage from '../../components/webpages/user-pages/NotificationPage';
import PageDiscover from '../../components/webpages/user-pages/PageDiscover';
import PageNotfound from '../../components/webpages/user-pages/PageNotfound';
import PagesDetail from '../../components/webpages/user-pages/PagesDetail';
import Photos from '../../components/webpages/user-pages/Photos';
import PostedDetail from '../../components/webpages/user-pages/PostedDetail';
import Pymk from '../../components/webpages/user-pages/Pymk';
import QuestionforYou from '../../components/webpages/user-pages/QuestionforYou';
import ResponsePoll from '../../components/webpages/user-pages/ResponsePoll';
import SearchPage from '../../components/webpages/user-pages/SearchPage';
import Social from '../../components/webpages/user-pages/Social';
import SocialProfile from '../../components/webpages/user-pages/SocialProfile.jsx';
import UserTimeline from '../../components/webpages/user-pages/UserTimeline';
import WriteArticle from '../../components/webpages/user-pages/WriteArticle';
import AccountSettings from '../../components/webpages/user-pages/account-setting/AccountSettings';
import BusinessSkills from '../../components/webpages/user-pages/account-setting/life-skills/BusinessSkills';
import CareerSkills from '../../components/webpages/user-pages/account-setting/life-skills/CareerSkills';
import InterPersonalSkills from '../../components/webpages/user-pages/account-setting/life-skills/InterPersonalSkills';
import SwotSkills from '../../components/webpages/user-pages/account-setting/life-skills/SwotSkills';
import TechnicalSkills from '../../components/webpages/user-pages/account-setting/life-skills/TechnicalSkills';
import BasicDetails from '../../components/webpages/user-pages/account-setting/my-account/BasicDetails';
import ContactDetails from '../../components/webpages/user-pages/account-setting/my-account/ContactDetails';
import EducatioDetails from '../../components/webpages/user-pages/account-setting/my-account/EducationDetail';
import Notification from '../../components/webpages/user-pages/account-setting/my-account/Notification';
import WorkDetails from '../../components/webpages/user-pages/account-setting/my-account/WorkDetails';
import ChangeInviteCode from '../../components/webpages/user-pages/account-setting/privacy-settings/ChangeInviteCode';
import ChangePassword from '../../components/webpages/user-pages/account-setting/privacy-settings/ChangePassword';
import PrivacySetting from '../../components/webpages/user-pages/account-setting/privacy-settings/PrivacySetting';

function UserRoutes() {
  return (
    <Routes>
      <Route path="/requests" element={<FriendRequests />} />
      {/* profesional */}
      <Route path={`/jobsmap`} element={<JobsMap type="Jobsmap" />} />
      <Route path={`/alumnimap`} element={<JobsMap type="Alumnimap" />} />
      <Route path="/notifications" element={<NotificationPage />} />
      {/* map component routes */}
      <Route path="/applied-details" element={<AppliedDetails />} />
      <Route path="/posted-detail" element={<PostedDetail />} />
      <Route path="/mentees" element={<Mentees />} />
      <Route path="/mentors" element={<Mentors />} />
      <Route path="/friends" element={<Friends />} />
      <Route path="/error-page" element={<PageNotfound />} />
      <Route path="/news-page" element={<NewsPage />} />
      <Route path="/news_page_detail" element={<NewsPageDetail />} />
      {/* end */}
      <Route path="/campus/add_news" element={<AddCampus />} />
      <Route path="/campus/news_listing" element={<CampusNews />} />
      <Route path="/campus-detail/:id" element={<CampusnewsDetails />} />
      <Route path="/events" element={<EventsPage />} />
      <Route path="/events_calendar" element={<EventsCalendar />} />
      <Route path="/events-detail/:userId/:state" element={<EventsDetail />} />
      <Route path="/events-detail/:userId/:state/:editId" element={<EventsDetail />} />
      <Route path="/event/proceed_to_pay/:orderId" element={<EventTicketSummary />} />
      <Route path="/completed" element={<EventBookingCompleted />} />
      <Route path="/birthdays" element={<BirthdayPage />} />
      <Route path="/birthday-details" element={<BirthdayDetails />} />
      <Route path="/current/:id" element={<UserTimeline />} />
      <Route path="/response-poll" element={<ResponsePoll />} />
      <Route path="/add-poll-question" element={<AddPollQuestion />} />
      <Route path="/albums" element={<Photos />} />
      <Route path="/create-album" element={<CreateAlbum />} />
      <Route path="/album/:albumId" element={<AlbumManager />} />
      <Route path="/edit_album/:albumId" element={<CreateAlbum />} />
      <Route path="/messages" element={<MessagesPage />} />
      <Route path="/messages/:timeline_id" element={<MessagesPage />} />
      <Route path="/groups" element={<GroupsPage />} />
      <Route path="/group-detail/:encrypted_id" element={<GroupDetail />} />
      <Route path="/chapters" element={<ChaptersDiscover />} />
      {/* <Route path="/chapter-detail/:id" element={<ChapterDetail />} /> */}
      <Route path="/chapter-detail/:encrypted_id" element={<ChapterDetail />} />
      <Route path="/pages" element={<PageDiscover />} />
      <Route path="/page/page-detail/:encrypted_id" element={<PagesDetail />} />
      <Route path="/pages/messages/:encrypted_id" element={<PagesDetail />} />
      <Route path="/pages/general_settings/:encrypted_id" element={<PagesDetail />} />
      <Route path="/pages/privacy_settings/:encrypted_id" element={<PagesDetail />} />
      <Route path="/pages/admin_roles/:encrypted_id" element={<PagesDetail />} />
      <Route path="/search-page" element={<SearchPage />} />
      <Route path="/find-friend" element={<FindFriend />} />
      <Route path="/answers" element={<AnswersRequests />} />
      <Route path="/questionsforyou" element={<QuestionforYou />} />
      <Route path="/hashtag/:name" element={<Hashtag />} />

      {/**Mentorship */}
      {/* <Route path="/qafeeds" element={<Qafeeds />} /> */}
      <Route path="/" element={<Social />} />
      <Route path="/mentor-article/:id" element={<MentorArticleDetails />} />
      <Route path="/articleComment/:id" element={<MentorArticleDetails />} />
      <Route path="/viewanswer/:id" element={<MentorQuestionDetail />} />
      <Route path="/qa" element={<MyQuestion />} />
      <Route path="/myAnswers" element={<MyAnswers />} />
      <Route path="/my-articles" element={<MyArticles />} />
      <Route path="/myArticles" element={<MyArticles />} />
      <Route path="/write-article" element={<WriteArticle />} />
      <Route path="/writeArticle" element={<WriteArticle />} />
      <Route path="/askquestion" element={<AskQuestion />} />
      <Route path="/edit-answer/:id" element={<EditAnswerPage />} />
      {/* end */}
      {/* account setting */}
      <Route path="/profile" element={<AccountSettings />} />
      {/* my-account */}
      <Route path="/profile/basic-details" element={<BasicDetails />} />
      <Route path="/profile/contact-details" element={<ContactDetails />} />
      <Route path="/profile/work-details" element={<WorkDetails />} />
      <Route path="/profile/education-details" element={<EducatioDetails />} />
      <Route path="/profile/account-notifications" element={<Notification />} />
      {/* end */}
      {/* life-skills */}
      <Route path="/profile/technical-skills" element={<TechnicalSkills />} />
      <Route path="/profile/business-skills" element={<BusinessSkills />} />
      <Route path="/profile/interpersonal-skills" element={<InterPersonalSkills />} />
      <Route path="/profile/career-goals" element={<CareerSkills />} />
      <Route path="/profile/swot-analysis" element={<SwotSkills />} />
      {/* end */}
      {/* privacy-settings */}
      <Route path="/profile/change-password" element={<ChangePassword />} />
      <Route path="/profile/privacy-settings" element={<PrivacySetting />} />
      <Route path="/profile/change-invite-code" element={<ChangeInviteCode />} />
      <Route path="/social-profile" element={<SocialProfile />} />
      <Route path="/timeline/:userId" element={<SocialProfile />} />
      <Route path="/about/:userId" element={<SocialProfile />} />
      <Route path="/friends/:userId" element={<SocialProfile />} />
      <Route path="/albums/:userId" element={<SocialProfile />} />
      <Route path="/collection/:albumId/:userId" element={<SocialProfile />} />
      <Route path="/friend-requests" element={<FriendRequests />} />
      <Route path="/jobfairDetails/:id" element={<JobfairDetails />} />
      {/*clubs */}
      <Route path="/clubs" element={<ClubPage />} />
      <Route path="/club-detail/:encrypted_id" element={<ClubDetail />} />
      {/* <Route path="/home/recruitments" element={<Recruiters />} /> */}
      {/* end */}
      <Route path="/pmky" element={<Pymk />} />
      <Route path="/*" element={<PageNotFound />} />
    </Routes>
  );
}

export default UserRoutes;
