/**
 * @copyright : ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
@author    : Shiv Charan Panjeta 
 
All Rights Reserved.
Proprietary and confidential :  All information contained here in is, and remains
the property of ToXSL Technologies Pvt. Ltd. and it's partners.
Unauthorized copying of this file, via any medium is strictly prohibited.
*/

import { Link } from 'react-router-dom';

function ThankyouPage() {
  return (
    <div>
      <div className="right-payment text-center">
        <span className="correct-icon">
          <i className="fas fa-check"></i>
        </span>
        <h3 className="mt-5">Thank you for your Order!</h3>
        <p>
          You will receive an email with the order summary and upon delivery you will be notified by
          email.
        </p>
        <Link to={{ pathname: `/shop/catalog`, state: 'catalog' }} className="btn btn-primary">
          Continue Shopping
        </Link>
      </div>
    </div>
  );
}

export default ThankyouPage;
