//  @copyright : ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
// @author     : Shiv Charan Panjeta

// All Rights Reserved.
// Proprietary and confidential :  All information contained herein is, and remains
// the property of ToXSL Technologies Pvt. Ltd. and its partners.
// Unauthorized copying of this file, via any medium is strictly prohibited.
import { useEffect, useState } from 'react';
import { Button, Container } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { useNavigate, useParams } from 'react-router-dom';
import Loader from '../../components/commoncomponents/loader/loader-large';
import { addClubDomain, editClubDomain, getClubDomainDetail } from '../../services/AuthServices';
import showNotification from '../../services/NotificationService';

export default function ClubDomainConfig() {
  const navigate = useNavigate();
  const { club_id } = useParams();
  const [clicked, setClicked] = useState(false);
  const [loading, setLoading] = useState(false);
  const [editable, setEditable] = useState(false);
  const [detail, setDetail] = useState({
    domain_name: '',
    club_id: club_id
  });

  useEffect(() => {
    if (club_id) {
      getDetail(club_id);
    }
  }, []);

  const validate = () => {
    let errors = {};
    if (!detail?.domain_name.trim()) {
      errors.domain_name = 'This field is required';
    }
    return errors;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setDetail({ ...detail, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setClicked(true);
    if (!Object.keys(validate())?.length) {
      // setLoading(true);
      addClubDomain(detail).then((res) => {
        setLoading(false);
        if (res?.status === 200) {
          showNotification('success', res?.data?.message);
          navigate(-1);
        }
      });
    }
  };

  const updateHandler = (e) => {
    e.preventDefault();
    setClicked(true);
    if (!Object.keys(validate())?.length) {
      setLoading(true);
      editClubDomain(club_id, { domain_name: detail?.domain_name }).then((res) => {
        setLoading(false);
        if (res?.status === 200) {
          showNotification('success', res?.data?.message);
          navigate(-1);
        }
      });
    }
  };

  const getDetail = (id = '') => {
    let body = {
      club_id: id
    };
    getClubDomainDetail(body).then((res) => {
      if (res?.status === 200) {
        const data = res?.data?.details?.length > 0 ? res?.data?.details[0] : {};
        if (data?.domain_name) {
          setEditable(true);
        }
        setDetail({
          domain_name: data?.domain_name,
          club_id: club_id
        });
      }
    });
  };

  return (
    <>
      <div className="dash-main-content">
        <Container fluid>
          <div className="dash-page-content">
            <div className="dash-page-title">
              <Row>
                <Col xs={12} sm={12} md={12}>
                  <div className="dash-title-bar d-flex align-items-center justify-content-between">
                    <div className="title-lft d-flex align-items-center">
                      <div className="dash-icn">
                        <i className="fas fa-university"></i>
                      </div>
                      <div className="dash-title-dis">
                        {/* {id ? <h5>Update Domain Configuration</h5> : <h5>Domain Configuration</h5>}
                         */}
                        <h5>Domain Configuration</h5>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="dash-content-card">
              <div className="cmmn-form">
                <Form>
                  <Row>
                    <Col md={12}>
                      <Form.Group className="mb-3" controlId="formBasicName">
                        <Form.Label>
                          Domain Name<span className="text-danger"> *</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter domain name"
                          name="domain_name"
                          value={detail?.domain_name}
                          onChange={(e) => {
                            handleChange(e);
                          }}
                        />
                        <div className={'danger-frm'}>{clicked && validate().name}</div>
                      </Form.Group>
                    </Col>
                    <Col>
                      {detail?.domain_name && (
                        <h5>{detail?.domain_name + '.' + process.env.REACT_APP_DEFAULT_LOCAL}</h5>
                      )}
                    </Col>
                  </Row>
                  <div className="form-btns d-flex align-items-center mt-3">
                    {editable ? (
                      <Button className="main-btn-grn" onClick={updateHandler}>
                        update
                      </Button>
                    ) : (
                      <Button className="main-btn-grn" onClick={handleSubmit}>
                        Save
                      </Button>
                    )}
                    <strong>OR</strong>
                    <Button
                      className="main-btn-red"
                      onClick={() => {
                        navigate(-1);
                      }}>
                      Cancel
                    </Button>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </Container>
      </div>
      {loading ? <Loader /> : ''}
    </>
  );
}
