/*
@copyright : ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
@author    : Shiv Charan Panjeta 
 
All Rights Reserved.
Proprietary and confidential :  All information contained here in is, and remains
the property of ToXSL Technologies Pvt. Ltd. and it's partners.O
Unauthorized copying of this file, via any medium is strictly prohibited.
*/

let apiBase = process.env.REACT_APP_API_URL;

export default apiBase;
