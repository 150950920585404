/** @copyright : ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
 @author     : Shiv Charan Panjeta 

 All Rights Reserved.
 Proprietary and confidential :  All information contained herein is, and remains
 the property of ToXSL Technologies Pvt. Ltd. and its partners.
 Unauthorized copying of this file, via any medium is strictly prohibited.
*/

import { useEffect } from 'react';
import { Link } from 'react-router-dom';

function LeftSidebarPrivacyPolicy() {
  useEffect(() => {}, []);
  const today = new Date();
  const year = today.getFullYear();

  return (
    <div className="right-side-copyrt text-start">
      <ul>
        <li>
          <Link to="/privacy_policy">Privacy Policy</Link>
        </li>
        <li>
          <Link to="/terms_of_use">Terms of Use</Link>
        </li>
        <li>
          <Link to="/Contactus">Contact Us</Link>
        </li>
      </ul>
      <p>AlmaBay.com © {year} </p>
    </div>
  );
}

export default LeftSidebarPrivacyPolicy;
