import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { useEffect, useState } from 'react';
import { Alert } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { AsyncPaginate } from 'react-select-async-paginate';
import AsyncSelect from 'react-select/async';
import AsyncCreatableSelect from 'react-select/async-creatable';
import { createSelectDropDownList, restrictAlpha } from '../../../globals/helper';
import useDocumentTitle from '../../../globals/useDocumentTitle';
import * as APISERVICE from '../../../services/JobServices';
import { getJobCategory } from '../../../services/JobServices';
import { loadJObLocationOption } from '../../../super-admin/AdminHelper';
import { MiniFooter } from '../../MiniFooter';
import FooterMain from '../../commoncomponents/FooterMain';
import HeaderLogin from '../../commoncomponents/HeaderLogin';
import Loader from '../../commoncomponents/loader/loader-large';
import ArticalBlogs from '../../commoncomponents/newsfeeds/ArticalBlogs';
import CampusNews from '../../commoncomponents/newsfeeds/CampusNews';
import CampusnewsText from '../../commoncomponents/newsfeeds/CampusnewsText';
import FollowCompany from '../../commoncomponents/newsfeeds/FollowCompany';
import GroupDiscover from '../../commoncomponents/newsfeeds/GroupDiscover';
import NewsSidebar from '../../commoncomponents/newsfeeds/NewsSidebar';
import PeopleKnow from '../../commoncomponents/newsfeeds/PeopleKnow';
import Upcoming from '../../commoncomponents/newsfeeds/Upcoming';
import WhatsNew from '../../commoncomponents/newsfeeds/WhatsNew';
import WorkAnniversary from '../../commoncomponents/newsfeeds/WorkAnniversary';
import TopTabbings from './TopTabbings';

function JobpostPage() {
  useDocumentTitle('Add New Job | AlmaBay');
  const [search] = useSearchParams();
  const navigate = useNavigate();
  const id = search.get('id');
  const [keyWordList, setKeywordsList] = useState([]);
  const [selectedkeyWord, setSelectedKeyword] = useState('');
  const [categoryList, setCategoryList] = useState([]);
  const [selectedHiring, setSelectedHiring] = useState('');
  const [location, setLocation] = useState('');
  const [clicked, setClicked] = useState(false);
  const [loading, setLoading] = useState(false);
  const [postJobDetail, setPostDetail] = useState({
    job_title: '',
    job_info: '',
    job_keyword_meta: '',
    open_positions: '1',
    min_exp: '',
    max_exp: '',
    min_ctc: '',
    max_ctc: '',
    company_hiring_for: '',
    company_type: '1',
    hr_email: '',
    contact_number: '',
    job_type: '0',
    is_referred: 1
  });
  const [minPackage, setMinimumPackage] = useState([]);
  const [maxPackage, setMaximumPackage] = useState([]);
  const [value, setValue] = useState(0);
  const [inputMessage, setInputMessage] = useState('Please enter atleast 2 characters.');
  const [loadSkill, setLoadSkill] = useState(false);
  const [showalert, setShowAlert] = useState(false);
  const [messageView, setMessageView] = useState('');
  const [jobLocation, setJobLocation] = useState('');
  const [locationSearch, setLocationSearch] = useState(null);

  useEffect(() => {
    getDropDowns();
    if (id) {
      getJobDetail(id);
    }
  }, []);

  /**useEffect for alert message */

  useEffect(() => {
    const timeId = setTimeout(() => {
      // After 3 seconds set the show value to false
      setShowAlert(false);
      setMessageView('');
    }, 3000);

    return () => {
      clearTimeout(timeId);
    };
  }, [showalert]);

  const getJobDetail = (id) => {
    setLoading(true);
    APISERVICE.getSingleJobDetail(id).then((res) => {
      setLoading(false);
      if (res?.status === 200) {
        const {
          job_info,
          job_referred,
          city_name,
          key_skills,
          company_id,
          company_name,
          max_exp,
          contact_number,
          hr_email,
          max_ctc,
          min_ctc,
          job_category_id,
          open_positions,
          company_type,
          min_exp,
          job_type,
          job_title,
          job_category,
          location
        } = res.data.data;
        let tempArr = [];
        if (key_skills?.length) {
          key_skills?.map((data) => {
            tempArr.push({
              id: data?.id,
              label: data?.name,
              value: data?.id
            });
          });
        }
        //
        setLocation(city_name);
        setPostDetail((previous) => {
          return {
            ...previous,
            job_title: job_title,
            job_info,
            job_category: job_category_id,
            job_keyword_meta: key_skills?.map((data) => data?.id).join(),
            open_positions,
            min_exp,
            max_exp,
            min_ctc,
            max_ctc,
            company_hiring_for: company_id,
            company_type: company_type,
            location: '',
            hr_email,
            contact_number,
            job_type: job_type,
            is_referred: job_referred
          };
        });
        setSelectedHiring({ id: company_id, value: company_name, label: company_name });
        setSelectedKeyword(tempArr);
        setValue({
          id: job_category_id,
          name: job_category
        });
        setJobLocation({ id: location, label: city_name, value: city_name });
      }
    });
  };

  const getDropDowns = () => {
    APISERVICE.getJobCategory().then((res) => {
      if (res?.status === 200) {
        const data = res?.data?.data;
        setCategoryList(createSelectDropDownList(data));
      }
    });

    APISERVICE.getKeyWords().then((res) => {
      if (res?.status === 200) {
        const data = res?.data?.data;
        setKeywordsList(createSelectDropDownList(data));
      }
    });

    APISERVICE.getMinCtc().then((res) => {
      if (res?.status === 200) {
        setMinimumPackage(res?.data?.data);
      }
    });

    APISERVICE.getMaxCtc().then((res) => {
      if (res?.status === 200) {
        setMaximumPackage(res?.data?.data);
      }
    });
  };

  const loadOptions = async (inputValue) => {
    return APISERVICE.getCompanyHiring(inputValue).then((res) => {
      if (res?.status === 200) {
        let tempArr = [];
        let data = res?.data?.data;
        if (data) {
          data?.map((data) => {
            tempArr.push({ id: data.Company_id, label: data.name, value: data.name });
          });
        }
        return tempArr;
      }
    });
  };

  const colourStyles = {
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      // const color = chroma(data.color);
      return {
        ...styles,
        backgroundColor: isFocused ? '#eaeaea' : null,
        color: '#212529'
      };
    }
  };
  const JobtypeOptions = [
    { id: 0, value: 'Full Time' },
    { id: 1, value: 'Part Time' },
    { id: 2, value: 'Internship' }
  ];

  const CompanytypeOptions = [
    { id: 1, value: 'Corporate' },
    { id: 2, value: 'Educational Institute' },
    { id: 3, value: 'Non-Govt Oraganisation' },
    { id: 4, value: 'Non Profit Organisation' },
    { id: 5, value: 'Govt Agency' }
  ];

  const PositionsOptions = [
    { id: 1, value: '1' },
    { id: 2, value: '2' },
    { id: 3, value: '3' },
    { id: 4, value: '4' },
    { id: 5, value: '5' },
    { id: 5, value: '6' },
    { id: 5, value: '7' },
    { id: 5, value: '8' },
    { id: 5, value: '9' },
    { id: 5, value: '10' }
  ];

  const WorkOptions = [
    { id: 1, value: 0 },
    { id: 2, value: 1 },
    { id: 3, value: 2 },
    { id: 4, value: 3 },
    { id: 5, value: 4 },
    { id: 6, value: 5 },
    { id: 7, value: 6 },
    { id: 8, value: 7 },
    { id: 9, value: 8 },
    { id: 10, value: 9 },
    { id: 11, value: 10 }
  ];

  const WorkOptions1 = [
    { id: 2, value: 1, label: '1' },
    { id: 3, value: 2, label: '2' },
    { id: 4, value: 3, label: '3' },
    { id: 5, value: 4, label: '4' },
    { id: 6, value: 5, label: '5' },
    { id: 7, value: 6, label: '6' },
    { id: 8, value: 7, label: '7' },
    { id: 9, value: 8, label: '8' },
    { id: 10, value: 9, label: '9' },
    { id: 11, value: 10, label: '10' },
    { id: 12, value: 11, label: '10+' }
  ];

  const loadKeywords = async (inputValue) => {
    // if (inputValue?.length >= 2) {
      // setLoadSkill(true);
      // setInputMessage('searching...');
      return APISERVICE.getKeyWordsNew(inputValue).then((res) => {
        setLoadSkill(false);
        if (res?.status === 200) {
          let tempArr = [];
          if (res?.data?.detail) {
            const data = res?.data?.detail;
            data?.map((data) => {
              tempArr.push({
                id: data?.id,
                label: data?.text,
                value: data?.id
              });
            });
          }
          if (res?.data?.detail?.length == 0) {
            setInputMessage('No matches found ');
          }
          return tempArr;
        }
      });
    // }
  };

  const onChangeHandler = (e, option) => {
    if (option) {
      if (option?.name == 'job_keyword_meta') {
        let keyWordId = [];

        e?.map((data) => {
          keyWordId.push(data.id);
          if (data?.id == undefined) {
            keyWordId.push(data.value);
          }
        });
        setPostDetail({ ...postJobDetail, [option.name]: keyWordId.join() });
      } else {
        setPostDetail({ ...postJobDetail, [option.name]: e.id });
      }
    } else {
      const { name, value } = e.target;
      setPostDetail({ ...postJobDetail, [name]: value });
    }
  };

  const validate = (value) => {
    let errors = {};
    if (!value.job_title.trim()) {
      errors.job_title = 'Please enter job title';
    }
    if (!value.job_info.trim()) {
      errors.job_info = 'Please enter job description';
    }
    if (!value.job_keyword_meta) {
      errors.job_keyword_meta = 'Please enter job job_keyword_meta';
    }
    if (!value.min_exp) {
      errors.min_exp = 'Please enter minimum experience';
    }
    if (!value.max_exp) {
      errors.max_exp = 'Please enter maximum experience';
    } else if (+value.max_exp < +value.min_exp) {
      errors.max_exp = 'Max exp should be greater then Min exp';
    }
    if (+value.min_ctc && +value.min_ctc > +value.max_ctc) {
      errors.min_ctc = 'Min CTC should be less than Max CTC';
    }
    if (+value.max_ctc && +value.max_ctc < +value.min_ctc) {
      errors.max_ctc = 'Max CTC should be greater than Min CTC';
    }
    if (!value.company_hiring_for) {
      errors.company_hiring_for = 'Please enter company hiring for';
    }
    if (jobLocation === null) {
      errors.location = 'Please select location';
    }
    return errors;
  };

  const submitHandler = (e) => {
    e.preventDefault();
    setClicked(true);
    if (!Object.keys(validate(postJobDetail)).length) {
      setLoading(true);
      let formData = new FormData();
      for (let i in postJobDetail) {
        formData.append(`${i}`, postJobDetail[i]);
      }
      formData.append('job_category', value.id);
      formData.append('location', jobLocation.id);
      APISERVICE.postAJob(formData).then((res) => {
        setLoading(false);
        if (res?.status === 200) {
          setPostDetail({
            job_title: '',
            job_info: '',
            job_keyword_meta: '',
            open_positions: '1',
            min_exp: '',
            max_exp: '',
            min_ctc: '',
            max_ctc: '',
            company_hiring_for: '',
            company_type: '1',
            location: '10',
            hr_email: '',
            contact_number: '',
            job_type: '1',
            is_referred: ''
          });
          setClicked(false);
          setMessageView('Job Posted Successfully');
          setShowAlert(true);
          setTimeout(() => {
            navigate('/jobs/posted');
          }, 1000);
        }
      });
    }
  };

  const updateHandler = (e) => {
    e.preventDefault();
    setClicked(true);
    if (!Object.keys(validate(postJobDetail)).length) {
      setLoading(true);
      let formData = new FormData();
      for (let i in postJobDetail) {
        formData.append(`${i}`, postJobDetail[i]);
      }
      formData.append('job_category', value.id);
      formData.append('location', jobLocation.id);
      APISERVICE.editJob(id, formData).then((res) => {
        setLoading(false);
        if (res?.status === 200) {
          const JobId = res?.data?.data?.id;
          setMessageView('Job has been edited successfully');
          setShowAlert(true);
          setTimeout(() => {
            navigate(`/jobs/details/${JobId}`);
          }, 1000);
          setClicked(false);
        }
      });
    }
  };

  const loadCategoryOption = async (search, loadedOptions, { page }) => {
    try {
      const resp = await getJobCategory(page);
      return {
        options: resp.data.data ?? [],
        hasMore: loadedOptions.length === resp?.data?.pager?.total ? false : true,
        additional: {
          page: search ? page + 1 : page + 1
        }
      };
    } catch (error) {
      console.error(error);
    }
  };

  const inputMessageHandler = (e) => {
    if (e?.length !== 1 && e?.length < 1) {
      setInputMessage('Please enter atleast 2 characters.');
    }
  };

  return (
    <>
      <div className="sticky_topbar">
        <HeaderLogin />
      </div>
      <div className="header-lnk-feeds">
        <div className="container">
          <TopTabbings state={'feed'} />
        </div>
      </div>
      <section className="feed-sec bg-grey py-4">
        <div className="container">
          <Row>
            <Col xs={12} lg={2}>
              <NewsSidebar state="0" current="post-job" />
            </Col>
            <Col xs={12} lg={9} xl={7} className="mb-4">
              <div className="job-recomm-card bg-white">
                <h4>{id ? 'Edit Job' : 'Post Job'}</h4>
                <Alert
                  variant={messageView ? 'success' : 'danger'}
                  className={`${showalert ? '' : 'd-none'}`}>
                  {messageView ? messageView : 'No Update'}
                </Alert>
                <div className="post-job-form py-4 px-4">
                  <Form>
                    <Form.Group as={Row} className="mb-4" controlId="formHorizontalTitle">
                      <Form.Label column sm={4}>
                        Job Title
                        <sup className="text-danger">*</sup>
                      </Form.Label>
                      <Col sm={8}>
                        <Form.Control
                          autoComplete="off"
                          value={postJobDetail?.job_title}
                          type="text"
                          placeholder="Enter Job Title here."
                          name="job_title"
                          onChange={(e) => {
                            onChangeHandler(e);
                          }}
                        />
                        <div
                          className={`${
                            clicked && validate(postJobDetail).job_title ? '' : 'd-none'
                          } danger-frm `}>
                          {clicked && validate(postJobDetail).job_title}
                        </div>
                      </Col>
                    </Form.Group>
                    <Form.Group
                      as={Row}
                      className="mb-4"
                      name="job_info"
                      controlId="formHorizontalPassword">
                      <Form.Label column sm={4}>
                        Job Description
                        <sup className="text-danger">*</sup>
                      </Form.Label>
                      <Col sm={8}></Col>
                      <div>
                        {/* <textarea
                          value={postJobDetail?.job_info}
                          name="job_info"
                          id=""
                          cols="30"
                          rows="10"></textarea> */}
                        <CKEditor
                          name="job_info"
                          editor={ClassicEditor}
                          data={postJobDetail?.job_info}
                          // onReady={(editor) => {
                          //   // You can store the "editor" and use when it is needed.
                          // }}
                          onReady={(editor) => {
                            // You can store the "editor" and use when it is needed.
                            editor.editing.view.change((writer) => {
                              writer.setStyle(
                                'height',
                                '200px',
                                editor.editing.view.document.getRoot()
                              );
                            });
                          }}
                          config={{
                            toolbar: [
                              'bold',
                              'italic',
                              'numberedList',
                              'bulletedList',
                              'outdent',
                              'indent'
                            ]
                          }}
                          onChange={(event, editor) => {
                            const data = editor.getData();
                            setPostDetail((previous) => {
                              return {
                                ...previous,
                                ['job_info']: data
                              };
                            });
                          }}
                          onBlur={(event, editor) => {}}
                          onFocus={(event, editor) => {}}
                        />
                        <div
                          style={{
                            borderBottom: '1px solid #ced4da',
                            borderLeft: '1px solid #ced4da',
                            borderRight: '1px solid #ced4da'
                          }}
                          className="d-flex justify-content-between pt-3 pe-3">
                          <p></p>
                          <p>
                            Words:{' '}
                            {postJobDetail.job_info != ''
                              ? postJobDetail.job_info?.split(' ').length
                              : 0}
                          </p>
                        </div>
                      </div>
                      <div
                        className={`${
                          clicked && validate(postJobDetail).job_info ? '' : 'd-none'
                        } danger-frm `}>
                        {clicked && validate(postJobDetail).job_info}
                      </div>
                    </Form.Group>

                    <Form.Group as={Row} className="mb-4" controlId="formHorizontalCategory">
                      <Form.Label column sm={4}>
                        Job Category
                      </Form.Label>
                      <Col sm={8}>
                        <AsyncPaginate
                          className="w100  p16 mr_normal_padding_l mr_medium_padding_r"
                          loadOptions={loadCategoryOption}
                          name="meal_id"
                          id={Math.random()}
                          getOptionValue={(option) => {
                            return option.id;
                          }}
                          getOptionLabel={(option) => {
                            return option.name;
                          }}
                          value={value}
                          onChange={setValue}
                          additional={{
                            page: 1
                          }}
                          placeholder="Select Category"
                          debounceTimeout={500}
                          isSearchable={true}
                        />
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row} className="mb-4" controlId="formHorizontalKeywords">
                      <Form.Label column sm={4}>
                        Keywords
                        <sup className="text-danger">*</sup>
                      </Form.Label>
                      <Col sm={8} className="spinner-wrapper">
                        <AsyncCreatableSelect
                          cacheOptions
                          isMulti
                          className="basic-single"
                          classNamePrefix="select"
                          isSearchable={true}
                          name="job_keyword_meta"
                          loadOptions={loadKeywords}
                          value={selectedkeyWord}
                          onChange={(e, option) => {
                            onChangeHandler(e, option);
                            setSelectedKeyword(e);
                          }}
                          placeholder="Select skillset"
                          noOptionsMessage={() => inputMessage}
                          onInputChange={(e) => inputMessageHandler(e)}
                          components={{
                            DropdownIndicator: () => null,
                            IndicatorSeparator: () => null,
                            LoadingIndicator: () => null
                          }}
                        />
                        {/* <AsyncSelect
                        cacheOptions
                        defaultOptions
                        value={selectedkeyWord}
                        getOptionLabel={(e) => e.label}
                        getOptionValue={(e) => e.value}
                        // inputValue={inputValue}
                        onChange={setSelectedKeyword}
                        // placeholder={'Search here '}
                        isMulti={true}
                        loadOptions={loadKeywords}
                        placeholder="Select skillset"
                        // name="job_keyword_meta"
                      /> */}

                        {loadSkill && (
                          <div className="spinner-filter-job-post">
                            <i className="fa fa-spinner fa-pulse me-2"></i>
                          </div>
                        )}
                        <div
                          className={`${
                            clicked && validate(postJobDetail).job_keyword_meta ? '' : 'd-none'
                          } danger-frm `}>
                          {clicked && validate(postJobDetail).job_keyword_meta}
                        </div>
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row} className="mb-4" controlId="formHorizontalPostions">
                      <Form.Label column sm={4}>
                        Open positions
                        <sup className="text-danger">*</sup>
                      </Form.Label>
                      <Col sm={8}>
                        <Form.Control
                          as="select"
                          name="open_positions"
                          onChange={(e) => {
                            onChangeHandler(e);
                          }}>
                          {PositionsOptions &&
                            PositionsOptions?.map((data, index) => (
                              <option key={index} value={data?.value}>
                                {data?.value}
                              </option>
                            ))}
                        </Form.Control>
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row} className="mb-4" controlId="formHorizontalWork">
                      <Form.Label column sm={4}>
                        Work experience (in yrs)
                        <sup className="text-danger">*</sup>
                      </Form.Label>
                      <Col sm={8}>
                        <Row className="d-flex align-items-center">
                          <Col sm={5}>
                            <Form.Control
                              as="select"
                              name="min_exp"
                              value={postJobDetail?.min_exp}
                              onChange={(e) => {
                                onChangeHandler(e);
                              }}>
                              <option value={' '}>Minimum</option>
                              {WorkOptions &&
                                WorkOptions?.map((data, index) => (
                                  <option key={index} value={data?.value}>
                                    {data?.value}
                                  </option>
                                ))}
                            </Form.Control>
                          </Col>
                          <Col sm={1}>to</Col>
                          <Col sm={5}>
                            <Form.Control
                              as="select"
                              name="max_exp"
                              value={postJobDetail?.max_exp}
                              onChange={(e) => {
                                onChangeHandler(e);
                              }}>
                              <option value={' '}>Maximum</option>
                              {WorkOptions1 &&
                                WorkOptions1?.map((data, index) => (
                                  <option key={index} value={data?.value}>
                                    {data?.label}
                                  </option>
                                ))}
                            </Form.Control>
                          </Col>
                          <div
                            className={`${
                              clicked && validate(postJobDetail).min_exp ? '' : 'd-none'
                            } danger-frm `}>
                            {clicked && validate(postJobDetail).min_exp}
                          </div>
                          <div
                            className={`${
                              clicked && validate(postJobDetail).max_exp ? '' : 'd-none'
                            } danger-frm `}>
                            {clicked && validate(postJobDetail).max_exp}
                          </div>
                        </Row>
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-4" controlId="formHorizontalPassword">
                      <Form.Label column sm={4}>
                        CTC
                      </Form.Label>
                      <Col sm={8}>
                        <Row className="d-flex align-items-center">
                          <Col sm={5}>
                            <Form.Control
                              as="select"
                              name="min_ctc"
                              value={postJobDetail?.min_ctc}
                              onChange={(e) => {
                                onChangeHandler(e);
                              }}>
                              <option value={''}>Minimum</option>
                              {minPackage &&
                                minPackage?.map((data, index) => (
                                  <option key={index} value={index}>
                                    {data} Lacs
                                  </option>
                                ))}
                            </Form.Control>
                          </Col>
                          <Col sm={1}>to</Col>
                          <Col sm={5}>
                            <Form.Control
                              as="select"
                              name="max_ctc"
                              value={postJobDetail?.max_ctc}
                              onChange={(e) => {
                                onChangeHandler(e);
                              }}>
                              <option value={''}>Maximum</option>
                              {maxPackage &&
                                maxPackage?.map((data, index) => (
                                  <option key={index} value={data}>
                                    {data} Lacs
                                  </option>
                                ))}
                              <option value={51}>50+ Lacs</option>
                            </Form.Control>
                          </Col>
                          <div
                            className={`${
                              clicked && validate(postJobDetail).min_ctc ? '' : 'd-none'
                            } danger-frm `}>
                            {clicked && validate(postJobDetail).min_ctc}
                          </div>
                          <div
                            className={`${
                              clicked && validate(postJobDetail).max_ctc ? '' : 'd-none'
                            } danger-frm `}>
                            {clicked && validate(postJobDetail).max_ctc}
                          </div>
                        </Row>
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row} className="mb-4" controlId="formHorizontalHirig">
                      <Form.Label column sm={4}>
                        Company hiring for
                        <sup className="text-danger">*</sup>
                      </Form.Label>
                      <Col sm={8}>
                        <AsyncSelect
                          className="basic-single"
                          isSearchable={true}
                          loadOptions={loadOptions}
                          styles={colourStyles}
                          value={selectedHiring}
                          onChange={(e, option) => {
                            onChangeHandler(e, option), setSelectedHiring(e);
                          }}
                          name="company_hiring_for"
                          placeholder=""
                        />
                        <div
                          className={`${
                            clicked && validate(postJobDetail).company_hiring_for ? '' : 'd-none'
                          } danger-frm `}>
                          {clicked && validate(postJobDetail).company_hiring_for}
                        </div>
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row} className="mb-4" controlId="formHorizontalType">
                      <Form.Label column sm={4}>
                        Company Type
                      </Form.Label>
                      <Col sm={8}>
                        <Form.Control
                          as="select"
                          name="company_type"
                          value={postJobDetail?.company_type}
                          onChange={(e, option) => {
                            onChangeHandler(e);
                          }}>
                          {CompanytypeOptions &&
                            CompanytypeOptions?.map((data, index) => (
                              <option key={index} value={data?.value}>
                                {data?.value}
                              </option>
                            ))}
                        </Form.Control>
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row} className="mb-4" controlId="formHorizontalLocation">
                      <Form.Label column sm={4}>
                        Location
                        <sup className="text-danger">*</sup>
                      </Form.Label>
                      <Col sm={8}>
                        {/* <AsyncLocationSelect
                          location={location}
                          onChangeHandler={onChangeHandler}
                          placeholder="Enter the job location"
                          inputMessage={inputMessage}
                          inputMessageHandler={inputMessageHandler}
                          setInputMessage={setInputMessage}
                          loadLoc={loadLoc}
                          setLoadLoc={setLoadLoc}
                        /> */}
                        <AsyncSelect
                          // isMulti
                          isClearable={true}
                          // cacheOptions
                          // defaultOptions
                          onMenuClose={() => setLocationSearch(null)}
                          placeholder={`Mumbai, Kolkata, Delhi..`}
                          value={jobLocation}
                          menuIsOpen={locationSearch !== null ? true : false}
                          getOptionLabel={(e) => e.label}
                          getOptionValue={(e) => e.value}
                          loadOptions={loadJObLocationOption}
                          onInputChange={(newValue) => setLocationSearch(newValue)}
                          className="basic-single mt-1"
                          name="location"
                          onChange={(e) => {
                            setJobLocation(() => e);
                          }}
                        />

                        <div
                          className={`${
                            clicked && validate(postJobDetail).location ? '' : 'd-none'
                          } danger-frm `}>
                          {clicked && validate(postJobDetail).location}
                        </div>
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row} className="mb-4" controlId="formHorizontalEmail">
                      <Form.Label column sm={4}>
                        HR email
                      </Form.Label>
                      <Col sm={8}>
                        <Form.Control
                          type="text"
                          name="hr_email"
                          value={postJobDetail?.hr_email}
                          onChange={(e) => {
                            onChangeHandler(e);
                          }}
                          placeholder="Enter the email ID to whom user can contact directly"
                        />
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row} className="mb-4" controlId="formHorizontalNumber">
                      <Form.Label column sm={4}>
                        Contact number
                      </Form.Label>
                      <Col sm={8}>
                        <Form.Control
                          type="text"
                          onKeyPress={restrictAlpha}
                          value={postJobDetail?.contact_number}
                          name="contact_number"
                          onChange={(e) => {
                            onChangeHandler(e);
                          }}
                          maxLength={10}
                          placeholder="Mobile number to contact"
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-4" controlId="formHorizontalJob">
                      <Form.Label column sm={4}>
                        Job Type
                      </Form.Label>
                      <Col sm={8}>
                        <Form.Control
                          as="select"
                          name="job_type"
                          value={postJobDetail?.job_type}
                          onChange={(e) => {
                            onChangeHandler(e);
                          }}>
                          {JobtypeOptions &&
                            JobtypeOptions?.map((data, index) => (
                              <option key={index} value={data?.id}>
                                {data?.value}
                              </option>
                            ))}
                        </Form.Control>
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3" controlId="formHorizontalCheck">
                      <Col sm={10}>
                        <Form.Check
                          name="is_referred"
                          checked={Number(postJobDetail?.is_referred)}
                          onChange={(e) => {
                            setPostDetail({
                              ...postJobDetail,
                              [e.target.name]: e.target.checked ? '1' : '0'
                            });
                          }}
                          label="Allow job finder to get referred from other people working in the same company "
                        />
                      </Col>
                    </Form.Group>
                    <Row>
                      <Col sm={12}>
                        <div className="accnt-lft-btn d-flex align-items-center justify-content-end">
                          {id ? (
                            <button className="accnt-btn" type="button" onClick={updateHandler}>
                              Update Job
                            </button>
                          ) : (
                            <button className="accnt-btn" type="button" onClick={submitHandler}>
                              Save Job
                            </button>
                          )}
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </div>
              </div>
            </Col>
            <Col xl={{ span: 3, offset: 0 }} lg={{ span: 9, offset: 2 }}>
              <div>
                <PeopleKnow />
              </div>
              <div>
                <WorkAnniversary />
              </div>
              <div>
                <GroupDiscover />
              </div>
              <div>
                <FollowCompany />
              </div>
              <div>
                <CampusNews />
              </div>
              <div>
                <CampusnewsText />
              </div>
              <div>
                <ArticalBlogs />
              </div>
              <div>
                <WhatsNew />
              </div>
              <div>
                <Upcoming />
              </div>
              <MiniFooter />
            </Col>
          </Row>
        </div>
      </section>
      <FooterMain className={'colour-bg'} />
      {loading ? <Loader /> : ''}
    </>
  );
}

export default JobpostPage;
