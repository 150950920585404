/**
@copyright  :   ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
@author     :   Shiv Charan Panjeta 
 
All Rights Reserved.
Proprietary and confidential :  All information contained here in is, and remains
the property of ToXSL Technologies Pvt. Ltd. and it's partners.
Unauthorized copying of this file, via any medium is strictly prohibited.
*/

import { useEffect, useState } from 'react';
import { Button, Container } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Table from 'react-bootstrap/Table';
import ReactPaginate from 'react-paginate';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import Loader from '../../components/commoncomponents/loader/loader-large';
import useDocumentTitle from '../../globals/useDocumentTitle';
import * as ADMINSERVICES from '../../services/AuthServices';

export default function CompousNews() {
  useDocumentTitle('Manage Campus News | AlmaBay');
  const navigate = useNavigate();
  const roleType = useSelector((state) => state?.user?.details?.admin_type);

  const [newsDetail, setNewsDetail] = useState([]);
  const [record, setRecord] = useState('');
  const [loading, setLoading] = useState(false);
  const [sortType, setSortType] = useState('DESC');
  const [sortField, setSortField] = useState('id');
  const [currentPage, setCurrentPage] = useState(1);
  const [detail, setDetail] = useState({
    id: '',
    title: '',
    status: ''
  });

  useEffect(() => {
    apiCall();
  }, []);

  const apiCall = (type = 'campus_news', page_number = '1') => {
    setCurrentPage(page_number);
    ADMINSERVICES.getOtherWhatNews(type, page_number).then((resp) => {
      if (resp.status === 200) {
        setNewsDetail(resp?.data?.data?.viewlist);
        setRecord(resp?.data?.data?.totalRecords);
      }
    });
  };

  const sortHandler = (e, type) => {
    e.preventDefault();
    let currentSort = sortType === 'ASC' ? 'DESC' : 'ASC';
    setSortType(currentSort);
    setSortField(type);
    apiCall('campus_news', currentPage, type, currentSort);
  };

  const handleChange = (e) => {
    setDetail({ ...detail, [e.target.name]: e.target.value });
  };

  const handleSearch = (type = 'campus_news', page = 1, sort_field = 'id', order = 'DESC') => {
    setLoading(true);
    setCurrentPage(page);
    ADMINSERVICES.getOtherWhatNews(
      'campus_news',
      page,
      sort_field,
      order,
      detail.id,
      detail.title,
      detail.status
    ).then((resp) => {
      setLoading(false);
      if (resp?.status === 200) {
        setNewsDetail(resp?.data?.data?.viewlist);
        setRecord(resp?.data?.data?.totalRecords);
      }
    });
  };

  const selectHandler = (value, id) => {
    switch (value) {
      case 'view':
        navigate(`/admin/news/index/view_campus_news`, { state: id });
        break;
      case 'edit':
        navigate(`/admin/news/index/add_campus_news?id=${id}`);
        break;

      default:
        break;
    }
  };

  const handlePageClick = (a) => {
    let page = a?.selected + 1;
    handleSearch('campus_news', page, sortField, sortType);
  };

  const resetHandler = () => {
    setDetail({
      id: '',
      title: '',
      status: ''
    });
    apiCall();
  };

  return (
    <>
      <div className="dash-main-content">
        <Container fluid>
          <div className="dash-page-content">
            <div className="dash-page-title">
              <Row>
                <Col xs={12} sm={12} md={12}>
                  <div className="dash-title-bar d-flex align-items-center justify-content-between">
                    <div className="title-lft d-flex align-items-center">
                      <div className="dash-icn">
                        <i className="fas fa-newspaper"></i>
                      </div>
                      <div className="dash-title-dis">
                        <h5>
                          Campus News <span>Listing</span>
                        </h5>
                        <h5>
                          Total Record(s): <span> {record} </span>
                        </h5>
                      </div>
                    </div>
                    <div className="title-rt-btn">
                      <Link className="green_button_admin" to="/admin/news/index/add_campus_news">
                        Add Campus New
                      </Link>
                      {roleType == '3' && (
                        <Link className="green_button_admin mx-2" to="/admin/add-banner?id=6">
                          Add Banner
                        </Link>
                      )}
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="dash-content-card">
              <div className="table-responsive">
                <Table className="custom-table-data" striped bordered hover>
                  <thead>
                    <tr>
                      <th>
                        <Link
                          to=""
                          onClick={(e) => {
                            sortHandler(e, 'id');
                          }}>
                          Id
                          <img src={require('../../assets/images/sort.png')} alt="sort" />
                        </Link>
                      </th>

                      <th>
                        <Link
                          to=""
                          onClick={(e) => {
                            sortHandler(e, 'title');
                          }}>
                          title
                          <img src={require('../../assets/images/sort.png')} alt="sort" />
                        </Link>
                      </th>
                      <th>Image</th>
                      <th>
                        {/* <Link
                          to=""
                          onClick={(e) => {
                            sortHandler(e, 'id');
                          }}>
                          status
                          <img src={require('../../assets/images/sort.png')} alt="sort" />
                        </Link> */}
                        status
                      </th>

                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="search-table">
                      <td>
                        <Form.Control
                          type="text"
                          placeholder="Id"
                          name="id"
                          onChange={handleChange}
                          value={detail?.id}
                        />
                      </td>
                      <td>
                        <Form.Control
                          type="text"
                          placeholder="Title"
                          name="title"
                          onChange={handleChange}
                          value={detail?.title}
                        />
                      </td>
                      <td></td>
                      <td>
                        <Form.Select
                          aria-label="Default select example"
                          name="status"
                          value={detail?.status}
                          onChange={handleChange}>
                          <option> - Select Status - </option>
                          <option value="0">Disable</option>
                          <option value="1">Enable</option>
                          <option value="2">Delete</option>
                        </Form.Select>
                      </td>
                      <td className="serac-btns text-center">
                        <button
                          className="green_button_admin"
                          type="button"
                          onClick={(e) => {
                            handleSearch(e);
                          }}>
                          Search
                        </button>
                        <Button
                          className="main-btn-red ms-2"
                          onClick={() => {
                            resetHandler();
                          }}>
                          Reset
                        </Button>
                      </td>
                    </tr>
                    {newsDetail &&
                      newsDetail?.map((data, index) => (
                        <tr key={index}>
                          <td> {data.id}</td>
                          <td> {data.title} </td>

                          <td>
                            <img
                              src={process.env.REACT_APP_API_DOMAIN + data?.imageUrl}
                              alt="img"
                              width="180"
                              height="125"
                            />
                          </td>
                          <td> {data.status == 1 ? 'Enable' : 'Disable'} </td>
                          <td>
                            <Form.Select
                              aria-label="Default select example"
                              onChange={(e) => {
                                selectHandler(e.target.value, data?.id);
                              }}>
                              <option>Action</option>
                              <option value="edit">Edit</option>
                              <option value="view">View</option>
                            </Form.Select>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
                {Math.ceil(record / 10) > 1 ? (
                  <div className="react-page d-flex justify-content-center">
                    <ReactPaginate
                      previousLabel={currentPage > 0 ? '<' : ''}
                      nextLabel={record > 0 ? '>' : ''}
                      onPageChange={handlePageClick}
                      activeClassName={'page-link active'}
                      pageCount={Math.ceil(record / 10)}
                      forcePage={parseInt(currentPage) - 1}
                    />
                  </div>
                ) : (
                  ''
                )}
              </div>
            </div>
          </div>
        </Container>
      </div>
      {loading ? <Loader /> : ''}
    </>
  );
}
