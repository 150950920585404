import { useEffect, useState } from 'react';
import { Button, Col, Container, Form, Row, Table } from 'react-bootstrap';
import { Link, useNavigate, useParams } from 'react-router-dom';
import NoDataFound from '../../../components/commoncomponents/NoDataFound';
import { getUserPageLiked } from '../../../services/AuthServices';

const ManagePages = () => {
  const [chekcedId, setCheckedId] = useState([]);
  const [allId, setAllId] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [orderBy, setOrderBy] = useState('DESC');
  const [shortOrderBy, setShortOrderBy] = useState('id');

  const [list, setList] = useState([]);
  const [response, setResponse] = useState('');
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [searchId, setSearchId] = useState('');
  const [searchName, setSearchName] = useState('');
  const { id } = useParams();
  const [searchedData, setSearchData] = useState({
    id: '',
    name: '',
    email: ''
  });

  useEffect(() => {
    apiCall();
  }, [id]);

  const apiCall = (page, sort, order) => {
    setLoading(true);
    getUserPageLiked(id, page ? page : currentPage, sort, order).then((res) => {
      setLoading(false);
      if (res?.status === 200) {
        setList(res?.data?.detail?.viewlist);
        setResponse(res?.data?.detail?.totalRecords);
        setAllId(res?.data?.detail?.viewlist?.map((data) => data?.id));
      }
    });
  };

  const handleMultipleCheckBox = (id) => {
    if (chekcedId.includes(id)) {
      let filterId = chekcedId.filter((data) => data !== id);
      setCheckedId(filterId);
    } else {
      setCheckedId([...chekcedId, id]);
    }
  };

  const handleSelectAll = (e) => {
    if (e.target.checked === true) {
      setCheckedId(allId);
    } else {
      setCheckedId([]);
    }
  };

  const selectHandler = (e, id) => {
    switch (e) {
      case 'edit':
        navigate('/admin/users/create', { state: id });
        break;
      case 'view': {
        navigate(`/admin/users/view/${id}`);
        break;
      }
      default:
        break;
    }
  };
  /**
   * reset function for api call
   */
  const resetHandler = () => {
    setSearchData({
      id: '',
      name: '',
      email: ''
    });
    apiCall(currentPage, shortOrderBy, orderBy);
  };
  /**
   * sort function for api call
   */
  const sortHandler = (e, type) => {
    e.preventDefault();
    let currentSort = orderBy === 'ASC' ? 'DESC' : 'ASC';
    setOrderBy(currentSort);
    setShortOrderBy(type);
    apiCall(currentPage, type, currentSort);
  };
  /**
   * onchage  function for filteration
   */
  const onChangeSearch = (e) => {
    const { name, value } = e.target;
    setSearchData({ ...searchedData, [name]: value });
  };
  /**
   * search  function for filteration
   */
  const searchHandler = (e) => {
    e.preventDefault();

    getUserPageLiked(id, currentPage, shortOrderBy, orderBy, searchedData).then((res) => {
      setLoading(false);
      if (res?.status === 200) {
        setList(res?.data?.detail?.viewlist);
        setResponse(res?.data?.detail?.totalRecords);
        setAllId(res?.data?.detail?.viewlist?.map((data) => data?.id));
      }
    });
  };
  return (
    <div className="dash-main-content">
      <Container fluid>
        <div className="dash-page-content">
          <div className="dash-page-title">
            <Row>
              <Col xs={12} sm={12} md={12}>
                <div className="dash-title-bar d-flex align-items-center justify-content-between">
                  <div className="title-lft d-flex align-items-center">
                    <div className="dash-icn">
                      <i className="fas fa-flag"></i>
                    </div>
                    <div className="dash-title-dis">
                      <h5>
                        Manage <span>Likes</span>
                      </h5>
                      {response !== 0 && (
                        <h5>
                          Total Record(s): <span>{response}</span>
                        </h5>
                      )}
                    </div>
                  </div>
                  <div className="title-rt-btn">
                    <div className="head-instite-sec  mx-2">
                      <Form.Select aria-label="Default select example" id="global">
                        <option value="">Choose Action</option>
                        <option value="active">Remove Follower</option>
                      </Form.Select>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <div className="dash-content-card">
            <div className="table-responsive">
              <Table className="custom-table-data" striped bordered hover>
                <thead>
                  <tr>
                    <th>
                      <Form.Check
                        type="checkbox"
                        id="checkbox"
                        label=""
                        checked={allId?.length != 0 ? allId?.length === chekcedId?.length : false}
                        onChange={handleSelectAll}
                      />
                    </th>
                    <th>
                      <Link
                        to=""
                        onClick={(e) => {
                          sortHandler(e, 'id');
                        }}
                      >
                        Id
                        <img src={require('../../../assets/images/sort.png')} alt="sort" />
                      </Link>
                    </th>
                    <th>
                      <Link
                        to=""
                        onClick={(e) => {
                          sortHandler(e, 'name');
                        }}
                      >
                        Name
                        <img src={require('../../../assets/images/sort.png')} alt="sort" />
                      </Link>
                    </th>
                    <th
                      onClick={(e) => {
                        sortHandler(e, 'email');
                      }}
                    >
                      Email <img src={require('../../../assets/images/sort.png')} alt="sort" />
                    </th>
                    <th>
                      <Link to="">Type</Link>
                    </th>
                    <th>Action</th>
                  </tr>
                  <tr className="search-table">
                    <td>
                      <></>
                    </td>
                    <td>
                      <Form.Control
                        type="text"
                        value={searchedData?.id}
                        name="id"
                        placeholder="Id"
                        onChange={(e) => {
                          onChangeSearch(e);
                        }}
                      />
                    </td>
                    <td>
                      <Form.Control
                        type="text"
                        value={searchedData?.name}
                        name="name"
                        onChange={(e) => {
                          onChangeSearch(e);
                        }}
                        placeholder="Name"
                      />
                    </td>
                    <td>
                      {' '}
                      <Form.Control
                        type="text"
                        placeholder="email"
                        name="email"
                        value={searchedData.email}
                        onChange={(e) => {
                          onChangeSearch(e);
                        }}
                      />
                    </td>
                    <td></td>

                    <td className="serac-btns text-center">
                      <Button className="main-btn-grn me-2" onClick={searchHandler}>
                        Search
                      </Button>
                      <Button
                        className="main-btn-grn main-btn-red btn btn-primary"
                        onClick={() => {
                          resetHandler();
                        }}
                      >
                        Reset
                      </Button>
                    </td>
                  </tr>
                </thead>

                <tbody>
                  {list && list.length !== 0 ? (
                    <>
                      {list?.map((data, index) => {
                        return (
                          <tr key={index}>
                            <td>
                              <Form.Check
                                type="checkbox"
                                id={`id${data?.id}`}
                                checked={chekcedId?.includes(data?.id)}
                                onChange={() => handleMultipleCheckBox(data?.id)}
                                label=""
                              />
                            </td>
                            <td>{data?.id}</td>
                            <td>{data?.name}</td>
                            <td>{data?.email}</td>
                            <td>Inactive</td>

                            <td>
                              <Form.Select
                                aria-label="Default select example"
                                onChange={(e) => {
                                  selectHandler(e.target.value, data?.id);
                                }}
                              >
                                <option>Action</option>
                                <option value="edit">Edit</option>
                                <option value="view">View</option>
                              </Form.Select>
                            </td>
                          </tr>
                        );
                      })}{' '}
                    </>
                  ) : <>
                    <tr className="text-center">
                      <td colSpan={8}>
                        <NoDataFound text="No Data Found." />
                      </td>
                    </tr>
                  </>

                  }
                </tbody>
              </Table>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default ManagePages;
