/**
 * @copyright : ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
@author    : Shiv Charan Panjeta 
 
All Rights Reserved.
Proprietary and confidential :  All information contained here in is, and remains
the property of ToXSL Technologies Pvt. Ltd. and it's partners.
Unauthorized copying of this file, via any medium is strictly prohibited.
*/

import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Container } from 'react-bootstrap';
import ReactImageMagnify from 'react-image-magnify';
import { useState, useEffect, useRef } from 'react';
import Modal from 'react-bootstrap/Modal';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import RelatedProduct from '../../commoncomponents/relatedproduct';
import FooterGrey from '../../commoncomponents/FooterGrey';
import msgStyles from '../user-pages/message.module.scss';
import {
  addRatings,
  buyNowProducts,
  productSingleDetail,
  productsReview
} from '../../../services/ShopServices';
import { Swiper, SwiperSlide } from 'swiper/react';
import { FreeMode, Navigation, Thumbs } from 'swiper';
import showNotification from '../../../services/NotificationService';
import { useSelector } from 'react-redux';
import HeaderShop from '../../commoncomponents/HeaderShop';

import { isAttributes, noAttributes } from '../../../globals/constants';
import Loader from '../../commoncomponents/loader/loader-large';
import ReactStars from 'react-stars';
import useDocumentTitle from '../../../globals/useDocumentTitle';
import { v4 as uuid } from 'uuid';
import ReviewProducts from './ReviewProducts';
import NewsLetter from './NewsLetter';

function ShopDetails() {
  useDocumentTitle('Product Detail | AlmaBay');
  let navigate = useNavigate();
  const modalRef = useRef();
  const closeRef = useRef();
  const { id } = useParams();

  const [show, setShow] = useState(false);
  const [showImage, setShowImage] = useState(false);
  const [alternateShowImage, setShowAlternateImage] = useState(false);
  const [starcountState, setStarCountState] = useState([]);
  const [allstarcountState, setAllStarCountState] = useState([]);
  const [reviewsCount, setReviewCount] = useState();
  const [nameData, setNameData] = useState(false);

  const [tempUser, setTempUser] = useState();
  const [modalImg, setModalImg] = useState('');

  const [buyProductState, setBuyProductState] = useState({
    attribute_size: '',
    attribute_color: ''
  });

  let location = useLocation();

  const shortCode = location?.state;
  /**onChange for buy product */

  const onChangeBuyProducts = (e) => {
    const { name, value } = e.target;
    setBuyProductState({
      ...buyProductState,
      [name]: value
    });
  };

  /**end onchange buy product */
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleCloseImage = () => setShowImage(false);
  const handleShowImage = () => setShowImage(true);
  const handleAlternateCloseImage = () => setShowAlternateImage(false);
  const handleAlternateShowImage = () => setShowAlternateImage(true);
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const [reviewState, setReviewState] = useState([]);
  const getProductsReview = () => {
    productsReview(id).then((resp) => {
      if (resp?.status === 200) {
        setReviewState(resp?.data?.data);
      }
    });
  };
  useEffect(() => {
    if (localStorage.getItem('session_id_cart')) {
      setTempUser(localStorage.getItem('session_id_cart'));
    } else {
      const unique_id = uuid();
      localStorage.setItem('session_id_cart', unique_id);
      setTempUser(unique_id);
    }

    getProductDetail();
    getProductsReview();
  }, [id]);

  const isloggedIn = useSelector((state) => state?.user?.isloggedIn);

  const [clickedBuyProduct, setClickedBuyProduct] = useState(false);
  const [productData, setProductData] = useState([]);

  const [product, setProduct] = useState([]);

  const [sizeState, setSizeState] = useState([]);

  const [loading, setLoading] = useState(false);
  const [alternateImage, setAlternateImage] = useState();

  const getProductDetail = async () => {
    setLoading(true);
    const resp = await productSingleDetail(id);

    setLoading(false);
    try {
      setProductData(resp?.data?.message?.detail);
      setProduct(resp?.data?.message?.detail);
      setAlternateImage(resp?.data?.message?.alterImages);
      setSizeState(resp?.data?.message?.attributes);
      setStarCountState(resp?.data?.message?.ratings_sql[0]);
      setAllStarCountState(resp?.data?.message?.allstarcount);
      setReviewCount(resp?.data?.message?.reviews_count[0]);

      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  /**Rating add */
  const ratingChanged = (newRating) => {
    let body = {
      pid: id,
      score: newRating
    };
    addRatings(body).then((resp) => {
      if (resp?.status == 200) {
        getProductDetail(id);
        showNotification('success', resp?.data?.message);
      } else {
        showNotification('danger', resp?.data?.message);
      }
    });
  };

  /**Rating add end */

  /**Buy Now */
  const buyNowProductCart = (e) => {
    e.preventDefault();
    let isExist = false;
    let isColorIExist = false;
    let existIndex = 0;
    let colorExistIndex = 0;
    if (sizeState?.length) {
      for (let i = 0; i < sizeState.length; i++) {
        const elem = sizeState[i];
        if (elem.name == 'Size') {
          isExist = true;
          existIndex = i;
        }
        if (elem.name == 'Color') {
          isColorIExist = true;
          colorExistIndex = i;
        }
      }
    } else {
      isExist = false;

      isColorIExist = false;
    }

    let body = {
      attribute_size: buyProductState?.attribute_size
        ? buyProductState?.attribute_size
        : isExist
        ? sizeState[existIndex]?.attributeIds && sizeState[existIndex]?.attributeIds.split(',')[0]
        : '',
      attribute_color: buyProductState?.attribute_color
        ? buyProductState?.attribute_color
        : isColorIExist
        ? sizeState[colorExistIndex]?.attributeIds &&
          sizeState[colorExistIndex]?.attributeIds.split(',')[0]
        : '',
      product_id: id,
      attributes: buyProductState ? isAttributes : noAttributes,
      temp_user: tempUser
    };

    buyNowProducts(body).then((resp) => {
      if (resp?.status == 200) {
        localStorage.setItem('user_id', resp?.data?.user_id);
        showNotification('success', resp?.data?.message);
        setClickedBuyProduct(false);
        navigate(`/shop/cart`);
      } else {
        showNotification('err', resp?.data?.message);
      }
    });
    // }
  };
  /**End Buy Now */

  const showModal = (src) => {
    let modal = document.getElementById('myModal');
    modal.style.display = 'block';
    setModalImg(src);
  };

  return (
    <>
      <HeaderShop />
      <section className="breadcrum py-4">
        <Container>
          <Breadcrumb>
            <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
            <Breadcrumb.Item href="/shop">Alma Store</Breadcrumb.Item>
            {shortCode ? <Breadcrumb.Item>{shortCode}</Breadcrumb.Item> : ''}
            {productData?.name ? <Breadcrumb.Item active>{productData?.name}</Breadcrumb.Item> : ''}
          </Breadcrumb>
        </Container>
      </section>
      <section className="product-detail-view py-5">
        <Container>
          <Row>
            <Col md={12} lg={6}>
              <div className="shop-lft-view">
                <div>
                  <Swiper
                    style={{
                      overflow: 'visible',
                      '--swiper-navigation-color': '#fff',
                      '--swiper-pagination-color': '#fff'
                    }}
                    spaceBetween={10}
                    navigation={true}
                    thumbs={{
                      swiper: thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null
                    }}
                    modules={[FreeMode, Navigation, Thumbs]}
                    className="mySwiper2">
                    <SwiperSlide>
                      <a data-fancybox="gallery" href="#">
                        <div
                          style={{
                            width: 500,
                            height: 300,
                            zIndex: 9999
                          }}>
                          <ReactImageMagnify
                            {...{
                              smallImage: {
                                isFluidWidth: true,
                                src: modalImg != '' ? modalImg : productData?.cover_image,
                                width: '100%',
                                height: '100%'
                              },
                              largeImage: {
                                src: modalImg != '' ? modalImg : productData?.cover_image,
                                width: 1000,
                                height: 480
                              },
                              enlargedImageContainerDimensions: {
                                width: '100%',
                                height: '100%'
                              },
                              enlargedImageContainerStyle: {
                                zIndex: '1500'
                              }
                            }}
                          />
                        </div>
                      </a>
                    </SwiperSlide>
                  </Swiper>
                </div>

                <div>
                  <Swiper
                    onSwiper={setThumbsSwiper}
                    spaceBetween={10}
                    slidesPerView={4}
                    freeMode={true}
                    watchSlidesProgress={true}
                    modules={[FreeMode, Navigation, Thumbs]}
                    className="mySwiper">
                    <SwiperSlide
                      onClick={() => {
                        showModal(productData?.cover_image);
                      }}>
                      <img src={productData?.cover_image} alt="" />
                    </SwiperSlide>

                    {alternateImage?.map((data, index) => {
                      return (
                        <SwiperSlide key={index} onClick={handleAlternateShowImage}>
                          <>
                            <img
                              src={data?.image_name}
                              id="myImg"
                              alt="img"
                              onClick={() => {
                                showModal(data?.image_name);
                              }}
                            />
                          </>
                        </SwiperSlide>
                      );
                    })}
                  </Swiper>
                </div>
              </div>
              <div></div>
            </Col>

            {/* Image Modal */}
            <Modal show={showImage} onHide={handleCloseImage} size="s">
              <Modal.Body>
                <div className="fancy-modal-img">
                  <img src={productData?.cover_image} alt="" />
                  <Button className="close-zoom-btn" variant="secondary" onClick={handleCloseImage}>
                    <i className="fas fa-times"></i>
                  </Button>
                </div>
              </Modal.Body>
            </Modal>
            {/***end */}

            <div ref={modalRef} id="myModal" className={msgStyles.modal}>
              <div className={msgStyles.fancyboxSkin}>
                <div
                  ref={closeRef}
                  onClick={() => {
                    let modal = document.getElementById('myModal');
                    modal.style.display = 'none';
                  }}
                  className={msgStyles.close}>
                  <span>
                    <i className="fas fa-times"></i>
                  </span>
                </div>
                <img src={modalImg} className={msgStyles.modalContent} id="img01" alt="almabay" />
                <div id="caption" className={msgStyles.fancyboxTitle}></div>
              </div>
            </div>
            <Col md={12} lg={6}>
              <div className="product-rt-detail">
                <div className="product-hd-title mb-4">
                  <h4>{productData?.name}</h4>
                  <div className="review-card d-flex align-items-center">
                    <div className="star-rating-card">
                      <div id="full-stars-example">
                        <div className="rating-group">
                          <ReactStars
                            className="star_rating"
                            size={30}
                            count={5}
                            value={starcountState?.score}
                            onChange={ratingChanged}
                            color2={'#f7ad1c'}
                          />
                        </div>
                      </div>
                    </div>

                    <Link to="">
                      {' '}
                      {starcountState?.count ? `(${starcountState?.count}) | ` : ''}
                    </Link>
                    <Link
                      to="#"
                      onClick={(e) => {
                        e.preventDefault();
                        document.getElementById('review').scrollIntoView();
                      }}>
                      Review {reviewsCount?.reviws ? reviewsCount?.reviws : 0}
                    </Link>
                  </div>
                </div>
                <div className="product-detail-price">
                  <p>
                    <span>Price </span>&#8377;{productData && productData?.price}
                  </p>

                  {(productData && productData?.spl_price == null) ||
                  productData?.spl_price == 0 ? (
                    ''
                  ) : (
                    <>
                      <p>
                        <span>Save</span>&#8377; {productData?.save}{' '}
                        {productData?.discountPercentage
                          ? `[${productData?.discountPercentage}] % OFF`
                          : ''}
                      </p>
                      <h3>
                        <span>Price </span>&#8377;
                        {productData?.spl_price}
                      </h3>
                    </>
                  )}
                </div>

                {sizeState?.map((data, index) => {
                  let valname = data?.attributeValName?.split(',');

                  let ids = data?.attributeIds?.split(',');

                  let newArr = valname?.map((item, index) => {
                    return {
                      id: ids[index],
                      name: item
                    };
                  });

                  return data?.name == 'Size ' && data?.name == 'Color' ? (
                    <></>
                  ) : (
                    <>
                      {data?.name == 'Color' && data?.attributeValName !== null ? (
                        <div className="selct-drop mt-5 mb-4 d-flex align-items-center">
                          <Form.Label>
                            <b>Choose Color:</b>
                          </Form.Label>
                          <Form.Select
                            aria-label="Default select example"
                            name="attribute_color"
                            onChange={onChangeBuyProducts}
                            defaultValue={newArr ? newArr[0]?.name : ''}>
                            {/* <option>Open</option> */}
                            {newArr?.map((dataValName, index) => {
                              return (
                                <option value={dataValName?.id} key={index}>
                                  {dataValName?.name}
                                </option>
                              );
                            })}
                          </Form.Select>
                        </div>
                      ) : (
                        ''
                      )}
                      {data?.name == 'Size' && data?.attributeValName !== null ? (
                        <div className="selct-drop mt-5 mb-4 d-flex align-items-center">
                          <Form.Label>
                            <b>Choose Size:</b>
                          </Form.Label>
                          <Form.Select
                            aria-label="Default select example"
                            name="attribute_size"
                            onChange={onChangeBuyProducts}
                            defaultValue={newArr ? newArr[0]?.name : ''}>
                            {/* <option>Open</option> */}
                            {newArr?.map((dataValName, index) => {
                              return (
                                <option value={dataValName?.id} key={index}>
                                  {dataValName?.name}
                                </option>
                              );
                            })}
                          </Form.Select>
                        </div>
                      ) : (
                        ''
                      )}
                    </>
                  );
                })}
              </div>
              <div className="product-detail-item">
                <Link to="#" className="main-btn mb-4" onClick={(e) => buyNowProductCart(e)}>
                  Buy Now
                </Link>
                {productData?.description ? (
                  <>
                    {' '}
                    <h4>Item Details</h4>
                    <p>{productData && productData?.description}</p>
                  </>
                ) : (
                  ''
                )}
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <RelatedProduct />
            </Col>
          </Row>
        </Container>
      </section>

      <ReviewProducts
        productData={productData}
        handleShow={handleShow}
        handleClose={handleClose}
        isloggedIn={isloggedIn}
        nameData={nameData}
        getProductDetail={getProductDetail}
        id={id}
        show={show}
        setNameData={setNameData}
        starcountState={starcountState}
        allstarcountState={allstarcountState}
        getProductsReview={getProductsReview}
        reviewState={reviewState}
        loading={loading}
        setLoading={setLoading}
      />
      <NewsLetter />

      <FooterGrey />
      {loading ? <Loader /> : ''}
    </>
  );
}

export default ShopDetails;
