//  @copyright : ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
// @author     : Shiv Charan Panjeta

// All Rights Reserved.
// Proprietary and confidential :  All information contained herein is, and remains
// the property of ToXSL Technologies Pvt. Ltd. and its partners.
// Unauthorized copying of this file, via any medium is strictly prohibited.

import { useEffect, useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Footer from '../../CommonComponents/Footer';
import SideBar from '../../CommonComponents/Sidebar';
import Header from '../../CommonComponents/Header';
import { Container } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { Button } from 'react-bootstrap';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import { useNavigate } from 'react-router-dom';
import showNotification from '../../../services/NotificationService';
import * as adminServices from '../../../services/AuthServices';
import { useLocation } from 'react-router-dom';
import { loadAllInstituteOptionsForGroup } from '../../AdminHelper';
import Loader from '../../../components/commoncomponents/loader/loader-large';
import useDocumentTitle from '../../../globals/useDocumentTitle';

export default function AddChapter() {
  useDocumentTitle('Add New Group | AlmaBay');
  const location = useLocation();
  const id = location.state;

  useEffect(() => {
    getAdminListing();
    if (id) {
      getDetail(id);
    }
  }, []);

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [insId, setInsId] = useState('');
  const [adminId, setAdminId] = useState('');
  const [adminOption, setAdminOption] = useState([]);
  const [clicked, setClicked] = useState(false);
  const [detail, setDetail] = useState({
    group_name: '',
    group_about: '',
    institute_id: '',
    group_admin: '',
    group_verified: '',
    group_privacy: ''
  });

  const getAdminListing = () => {
    adminServices.getPageAdminListDrop().then((res) => {
      if (res?.status === 200) {
        const data = res?.data?.message?.users?.map((items) => {
          return {
            label: items?.name,
            value: items?.id
          };
        });
        setAdminOption(data);
      }
    });
  };

  const validate = () => {
    let error = {};
    if (detail?.group_name == '') {
      error.group_name = 'Please enter group name';
    }
    if (detail?.institute_id == '') {
      error.institute_id = 'Please select institute';
    }
    if (detail?.group_admin == '') {
      error.group_admin = 'Please select group admin';
    }
    if (detail?.group_verified == '') {
      error.group_verified = 'Please select verify group';
    }
    if (detail?.group_privacy == '') {
      error.group_privacy = 'Please select group privacy';
    }
    return error;
  };

  const handleChange = (e, name = '') => {
    if (name) {
      setDetail({
        ...detail,
        [name]: e.value
      });
    } else {
      const { name, value } = e.target;
      setDetail({
        ...detail,
        [name]: value
      });
    }
  };

  const handleSave = (e) => {
    e.preventDefault();
    setClicked(true);
    if (!Object.keys(validate()).length) {
      adminServices.adminGroup(detail).then((res) => {
        if (res?.status === 200) {
          showNotification('success', res?.data?.message);
          navigate('/admin/groups');
        }
      });
    }
  };

  const getDetail = () => {
    setLoading(true);
    adminServices.getDetailGroup(id).then((res) => {
      setLoading(false);
      if (res?.status === 200) {
        const data = res?.data?.data?.group;
        setDetail({
          id: id,
          group_name: data?.name,
          group_about: data?.about,
          institue_id: data?.institue_id,
          group_verified: data?.verified,
          group_admin: data?.admin_ids[0],
          group_privacy: data?.group_privacy
        });
        if (data?.admin_ids) {
          const ids = [];
          data?.admin_ids?.map((id) => {
            ids.push(id);
          });
          getSelectedAdmin(ids);
        }
        getSelectedInstitute(data?.institue_id);
      }
    });
  };

  const getSelectedAdmin = (id) => {
    adminServices.getAdminById(id).then((res) => {
      let arr = [];
      if (res?.status === 200) {
        let data = res?.data?.data;
        data?.map((data) => {
          arr.push({ label: data?.name, value: data?.value });
        });
      }
      setAdminId(arr);
    });
  };

  const getSelectedInstitute = (id) => {
    adminServices.getInstituteListing('', id).then((res) => {
      let arr = [];
      if (res?.status === 200) {
        let data = res?.data?.message;
        data?.map((data) => {
          arr.push({ id: data?.id, label: data?.name, value: data?.name });
        });
      }
      setInsId(arr);
    });
  };

  const updateHandler = (e) => {
    e.preventDefault();
    setClicked(true);
    if (!Object.keys(validate(detail)).length) {
      adminServices.postEditGroup(detail).then((res) => {
        if (res?.status === 200) {
          showNotification('success', res?.data?.message);
          navigate(-1);
        }
      });
    }
  };

  return (
    <>
      <div className="dash-main-content">
        <Container fluid>
          <div className="dash-page-content">
            <div className="dash-page-title">
              <Row>
                <Col xs={12} sm={12} md={12}>
                  <div className="dash-title-bar d-flex align-items-center justify-content-between">
                    <div className="title-lft d-flex align-items-center">
                      <div className="dash-icn">
                        <i className="fas fa-users"></i>
                      </div>
                      <div className="dash-title-dis">
                        {id ? <h5>Update Group</h5> : <h5>Add Group</h5>}
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="dash-content-card">
              <div className="cmmn-form">
                <Form>
                  <Row>
                    <Col md={12}>
                      <Form.Group className="mb-3" controlId="formBasicName">
                        <Form.Label>
                          Name <sup className="text-danger">*</sup>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Name of your group"
                          name="group_name"
                          value={detail?.group_name}
                          onChange={handleChange}
                        />
                        <div className={'danger-frm'}>{clicked && validate()?.group_name}</div>
                      </Form.Group>
                    </Col>

                    <Col md={12}>
                      <Form.Group className="mb-3" controlId="formBasicDesi">
                        <Form.Label>About </Form.Label>
                        <Form.Control
                          as="textarea"
                          placeholder="Write about your group..."
                          rows={3}
                          value={detail?.group_about}
                          name="group_about"
                          onChange={handleChange}
                        />
                      </Form.Group>
                    </Col>

                    <Col xs={12} md={6} lg={12}>
                      <Form.Group className="mb-3">
                        <Form.Label>
                          Institute Name <span className="text-danger">*</span>
                        </Form.Label>
                        <AsyncSelect
                          cacheOptions
                          value={insId}
                          placeholder={'select institute'}
                          loadOptions={loadAllInstituteOptionsForGroup}
                          className="basic-single"
                          name="institute_id"
                          onChange={(e) => {
                            setDetail({ ...detail, ['institute_id']: e.id });
                            setInsId(e);
                          }}
                        />
                        <div className={'danger-frm'}>{clicked && validate()?.institute_id}</div>
                      </Form.Group>
                    </Col>
                    <Col md={12}>
                      <Form.Group className="mb-3" controlId="formBasicName">
                        <Form.Label>
                          Select Admin For Group <sup className="text-danger">*</sup>
                        </Form.Label>
                        <Select
                          isMulti
                          isClearable={false}
                          isRtl={false}
                          isSearchable={true}
                          placeholder="Select Admins From List"
                          options={adminOption}
                          onChange={(e) => {
                            setDetail({
                              ...detail,
                              ['group_admin']: e.map((data) => data?.value).join()
                            });
                            setAdminId(e);
                          }}
                          value={adminId}
                        />
                        <div className={'danger-frm'}>{clicked && validate()?.group_admin}</div>
                      </Form.Group>
                    </Col>

                    <Col md={12}>
                      <Form.Group className="mb-3" controlId="formBasicName">
                        <Form.Label>
                          Verify Group<sup className="text-danger">*</sup>
                        </Form.Label>
                        <Form.Select
                          aria-label="Default select example"
                          name="group_verified"
                          value={detail?.group_verified}
                          onChange={handleChange}>
                          <option value="">Select Option</option>
                          <option value="1">Official</option>
                          <option value="0">Non-Official</option>
                        </Form.Select>
                        <div className={'danger-frm'}>{clicked && validate()?.group_verified}</div>
                      </Form.Group>
                    </Col>

                    <Col md={12}>
                      <Form.Label>
                        Privacy <sup className="text-danger">*</sup>
                        <div className={'danger-frm'}>{clicked && validate()?.group_privacy}</div>
                      </Form.Label>

                      <div>
                        {['radio'].map((type) => (
                          <div key={`inline-${type}`} className="privcacy-radio mb-3">
                            <div>
                              <Form.Check
                                label="Open Group"
                                name="group_privacy"
                                value="open"
                                type={type}
                                checked={detail?.group_privacy == 'open'}
                                onChange={(e) => {
                                  handleChange(e);
                                }}
                                id={`inline-${type}-1`}
                              />
                              <span className="radio-span">Anyone can see and join the Group</span>
                            </div>
                            <div>
                              <Form.Check
                                label="Closed Group"
                                name="group_privacy"
                                type={type}
                                value="closed"
                                checked={detail?.group_privacy == 'closed'}
                                // onChange={(e) => setPrivacy(e.target.value)}
                                onChange={(e) => {
                                  handleChange(e);
                                }}
                                id={`inline-${type}-2`}
                              />
                              <span className="radio-span">
                                Anyone can see and request to join the group. Requests can be
                                accepted or declined by admins
                              </span>
                            </div>
                            <div>
                              <Form.Check
                                label="Secret Group"
                                name="group_privacy"
                                checked={detail?.group_privacy == 'secret'}
                                type={type}
                                value="secret"
                                // onChange={(e) => setPrivacy(e.target.value)}
                                onChange={(e) => {
                                  handleChange(e);
                                }}
                                id={`inline-${type}-3`}
                              />
                              <span className="radio-span">Only members can access the group</span>
                            </div>
                          </div>
                        ))}
                      </div>
                    </Col>
                  </Row>
                  <div className="form-btns d-flex align-items-center mt-3">
                    {id ? (
                      <Button
                        className="green_button_admin"
                        onClick={(e) => {
                          updateHandler(e);
                        }}>
                        Update
                      </Button>
                    ) : (
                      <Button
                        className="green_button_admin"
                        onClick={(e) => {
                          handleSave(e);
                        }}>
                        Save
                      </Button>
                    )}
                    <strong>OR</strong>
                    <Button
                      className="main-btn-red"
                      onClick={() => {
                        navigate(-1);
                      }}>
                      Cancel
                    </Button>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </Container>
      </div>
      {loading ? <Loader /> : ''}
    </>
  );
}
