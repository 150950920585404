/**
@copyright  :   ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
@author     :   Shiv Charan Panjeta 
 
All Rights Reserved.
Proprietary and confidential :  All information contained here in is, and remains
the property of ToXSL Technologies Pvt. Ltd. and it's partners.
Unauthorized copying of this file, via any medium is strictly prohibited.
*/
import { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { useNavigate, useParams } from 'react-router-dom';
import AsyncSelect from 'react-select/async';
import { companyAdminsList } from '../../services/AuthServices';
import {
  addAdminInCompany,
  getCompanyAdminConnectionsForAdmin
} from '../../services/CompanyServices';
import showNotification from '../../services/NotificationService';

export default function AddAdmins() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [adminIds, setAdminIds] = useState([]);
  const [clicked, setClicked] = useState(false);

  const [loding, setLoading] = useState(false);

  useEffect(() => {
    apiCall();
  }, []);

  const validate = () => {
    let error = {};
    if (adminIds.length == 0) {
      error.adminIds = 'Please select atleast one admin from list';
    }

    return error;
  };

  const addConenctionAsAdmin = (e) => {
    e.preventDefault();
    setClicked(true);
    if (!Object.keys(validate()).length) {
      let body = {
        organisation_page_admin: adminIds.map((data) => data?.value).join()
      };

      addAdminInCompany(id, body).then((resp) => {
        if (resp?.status == 200) {
          setClicked(false);
          showNotification('success', resp?.data?.detail);
          navigate(-1);
        }
      });
    }
  };

  const apiCall = () => {
    setLoading(true);

    companyAdminsList(id).then((resp) => {
      setLoading(false);
      if (resp?.status === 200) {
        const data = resp?.data?.data?.viewlist;
        let arr = [];
        data?.map((data) => {
          arr.push({ label: data?.full_name, value: data?.admin_id });
        });
        setAdminIds(arr);
      }
    });
  };

  // load options using API call
  const loadOptions = (inputValue) => {
    if (inputValue?.length > 1) {
      let body = {
        q: inputValue
      };
      return getCompanyAdminConnectionsForAdmin(body).then((res) => {
        if (res?.status === 200) {
          let tempArr = [];
          if (res?.data?.data) {
            const data = res?.data?.data;
            data?.map((data) => {
              tempArr.push({ label: data?.name, value: data?.id });
            });
          }
          return tempArr;
        }
      });
    } else {
      return;
    }
  };

  return (
    <div className="dash-main-content">
      <Container fluid>
        <div className="post-job more-option-detail bg-white">
          <h4> Add Admins </h4>
          <div className="p-4">
            <Form.Group as={Row} className="mb-3" controlId="formPlaintextEmail">
              <Col sm="12">
                <AsyncSelect
                  isMulti
                  className="basic-single"
                  classNamePrefix="select"
                  name="color"
                  loadOptions={loadOptions}
                  onChange={(e) => {
                    setAdminIds(e);
                  }}
                  value={adminIds}
                />
              </Col>
              {/* <div className={'danger-frm'}>{clicked ? error : ''}</div> */}
              <div className={'danger-frm'}>{clicked && validate()?.adminIds}</div>
            </Form.Group>
            <div className="accnt-lft-btn d-flex align-items-center justify-content-end">
              <button
                className="accnt-btn "
                onClick={(e) => {
                  addConenctionAsAdmin(e);
                }}
                type="button">
                Save
              </button>
              <button
                className="wht-btn   ms-2"
                onClick={() => {
                  navigate(-1);
                }}>
                Cancel
              </button>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}
