/**
@copyright  :   ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
@author     :   Shiv Charan Panjeta 
 
All Rights Reserved.
Proprietary and confidential :  All information contained here in is, and remains
the property of ToXSL Technologies Pvt. Ltd. and it's partners.
Unauthorized copying of this file, via any medium is strictly prohibited.
*/

import { useEffect, useState } from 'react';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { resumeExtension, subDomain, truncate } from '../../../globals/helper';
import useDocumentTitle from '../../../globals/useDocumentTitle';
import { loginInfo, shareLinkAction } from '../../../redux/action';
import { checkLogin } from '../../../services/AuthServices';
import * as APISERVICES from '../../../services/JobServices';
import showNotification from '../../../services/NotificationService';
import * as APISERVICE from '../../../services/UserServices';
import { MiniFooter } from '../../MiniFooter';
import HeaderLogin from '../../commoncomponents/HeaderLogin';
import MemberShipPOPUP from '../../commoncomponents/MemberShipPOPup/MemberShipPOPUP';
import ArticalBlogs from '../../commoncomponents/newsfeeds/ArticalBlogs';
import CampusNews from '../../commoncomponents/newsfeeds/CampusNews';
import CampusnewsText from '../../commoncomponents/newsfeeds/CampusnewsText';
import FollowCompany from '../../commoncomponents/newsfeeds/FollowCompany';
import GroupDiscover from '../../commoncomponents/newsfeeds/GroupDiscover';
import NewsSidebar from '../../commoncomponents/newsfeeds/NewsSidebar';
import PeopleKnow from '../../commoncomponents/newsfeeds/PeopleKnow';
import Upcoming from '../../commoncomponents/newsfeeds/Upcoming';
import WhatsNew from '../../commoncomponents/newsfeeds/WhatsNew';
import * as index from '../../commoncomponents/skelton/allskelton';
import ApplyForm from '../common-professional-comp/ApplyForm';
import RecomendModal from '../common-professional-comp/RecomendModal';
import TopTabbings from './TopTabbings';
import moment from 'moment';

function LoginFeeds({ socialProfileProfessional }) {
  if (subDomain()) {
    useDocumentTitle(subDomain());
  } else {
    useDocumentTitle('Job Timeline | AlmaBay');
  }

  const dispatch = useDispatch();

  const result = useSelector((state) => state?.shareLink?.links);
  const loginInfo = useSelector((state) => console.log('state', state));
  // loginInfo
  const [searchParams, setSearchParams] = useSearchParams();
  const token = searchParams.get('token');
  const dipatch = useDispatch();
  const navigate = useNavigate();
  const [applyForm, setApplyForm] = useState({
    to: ' ',
    subject: ' ',
    message: ' ',
    resume: ' '
  });
  const [savedResume, setSavedResume] = useState('');
  const [jobId, setJobId] = useState('');
  const [jobTitle, setJobTitle] = useState('');
  const [isChecked, setIsChecked] = useState(false);
  const [showMendorShipCard, setShowMendorShipCard] = useState(true);
  const [resumeSelect, setResumeSelect] = useState(false);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  const [show3, setShow3] = useState(false);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);

  const [fileSizeValid, setFileSizeValid] = useState(false);

  const [list, setList] = useState([]);

  const [loading, setLoading] = useState(false);
  const isloggedIn = useSelector((state) => state?.user?.isloggedIn);
  const [currentPage, setCurrentPage] = useState(1);
  const [total, setTotal] = useState('');

  useEffect(() => {
    if (isloggedIn) {
      getJobList();
      getUserDetail();
    }
    if (token) {
      localStorage.setItem('token', token);
      checkUserToken();
    }
  }, [token]);

  const checkUserToken = () => {
    checkLogin().then((res) => {
      if (res?.status === 200) {
        //save data on redux
        dipatch(loginInfo(res?.data?.data));
        navigate('/jobs');
      }
    });
  };

  const getJobList = (page) => {
    if (!page) {
      setLoading(true);
    }

    APISERVICES.newBrowseJob(page ? page : currentPage).then((res) => {
      setLoading(false);
      if (res?.status === 200) {
        if (page) {
          setList([...list, ...(res?.data?.data?.list ?? [])]);
        } else {
          setList(res?.data?.data?.list ?? []);
        }

        setTotal(res?.data?.pager);
      }
    });
  };

  const getUserDetail = () => {
    APISERVICE.userBasicInfo().then((res) => {
      if (res?.status === 200) {
        setSavedResume(res?.data?.resume);
        setApplyForm({ ...applyForm, ['resume']: res?.data?.resume });
      }
    });
  };

  const onChangeHandler = (e, rid = false) => {
    if (rid) {
      setApplyForm({ ...applyForm, ['resume']: savedResume });
    } else {
      const { name, value, type } = e.target;
      if (type == 'file') {
        let file = e.target.files[0];
        let fileSize = file.size / 1024;

        if (fileSize > 500) {
          setResumeSelect(false);
          setFileSizeValid(true);
          alert('Resume file size should be less than 500KB');
        } else {
          setFileSizeValid(false);
          if (resumeExtension.includes(e.target.files[0].type)) {
            setResumeSelect(true);
            setApplyForm({ ...applyForm, [name]: file });
          } else {
            setResumeSelect(false);
            alert(
              'Resume file format is not valid, kindly attach a valid file e.g doc,docx,rtf,odt,pdf'
            );
          }
        }
      } else {
        setApplyForm({ ...applyForm, [name]: value });
      }
    }
  };

  const submitHandler = () => {
    let formData = new FormData();
    for (let i in applyForm) {
      formData.append(`${i}`, applyForm[i]);
    }
    APISERVICES.applyForJob(jobId, formData).then((res) => {
      if (res?.status === 200) {
        handleClose2();
        showNotification('success', res?.data?.message);
        getJobList();
      }
    });
  };

  const onApplyStateHandler = (data) => {
    setJobId(data?.id);
    setJobTitle(data?.job_title);
    setApplyForm({
      // to: data?.user
      //   ? data?.user?.first_name
      //     ? data?.user?.first_name + ' ' + data?.user?.last_name
      //     : data?.user?.username
      //   : data?.job_company_meta,
      to: data?.username ? data?.username : data?.user?.username,
      subject: `Alma - Job application for ${data?.job_title}`,
      message: '',
      resume: savedResume
    });
    handleShow2();
  };
  const handleClosePopUp = (e) => {
    e.preventDefault();
    setShowMendorShipCard(false);
  };

  const onRecommendClick = (e, id) => {
    e.preventDefault();
    setJobId(id);
    handleShow3();
  };

  const handleLoadMore = (e) => {
    e.preventDefault();
    setCurrentPage(currentPage + 1);
    getJobList(currentPage + 1);
  };

  /**
   * a function using switch case to display job type
   * @param {*} job_type
   * @returns
   */
  const jobType = (job_type) => {
    switch (job_type) {
      case '0':
        return 'Full Time';
      case '1':
        return 'Part Time';
      case '2':
        return 'Internship';
      default:
        break;
    }
  };

  return (
    <>
      {socialProfileProfessional ? (
        <></>
      ) : (
        <>
          <HeaderLogin />
          <div className="header-lnk-feeds">
            <div className="container">
              <TopTabbings state={'feed'} />
            </div>
          </div>
        </>
      )}

      <section className="feed-sec bg-grey py-4">
        <div className="container">
          <Row>
            {socialProfileProfessional ? (
              <></>
            ) : (
              <Col xs={12} lg={2}>
                <NewsSidebar state="0" current="timeline" />
              </Col>
            )}
            <Col xs={12} lg={9} xl={7} className="mb-4">
              {showMendorShipCard && <MemberShipPOPUP handleClosePopUp={handleClosePopUp} />}
              <div className="job-recomm-card bg-white">
                <h4>Recommended jobs</h4>
                <div className="job-recommd-lst p-3">
                  {loading ? (
                    <index.LoginSkelton />
                  ) : (
                    list.length > 0 &&
                    list?.map((data, index) => {
                      return (
                        <div className="job-recomm-card-inr" key={index}>
                          <div className="top-time-line mb-3">
                            <p>
                              <i className="fas fa-user me-1"></i>
                              <Link
                                target="_blank"
                                className="fst-name"
                                to={`/jobs/search/brand/${data?.company_id}`}>
                                {/* {data?.job_company_meta || data?.user?.first_name || data?.user?.username||data?.username} */}
                                {data?.added_by == 1
                                  ? data?.user?.username
                                    ? data?.user?.username
                                    : data?.companyName
                                  : data?.added_by == 0
                                  ? data?.user?.at(0)?.first_name
                                    ? data?.user?.at(0)?.first_name
                                    : data?.companyName ?? ''
                                  : ''}
                              </Link>
                              &nbsp;posted a job under
                              <Link to={`/jobs/search/category/${data?.job_category}`}>
                                &nbsp;{data?.job_category_name || data?.job_industry_meta}
                              </Link>{' '}
                              category
                            </p>
                            <span title={data?.created_on}>
                              <i className="far fa-clock me-1"></i>
                              {moment(data?.date_time).fromNow()}
                            </span>
                          </div>
                          <div className="job-recomm-dis">
                            <h4>
                              <Link to={`/jobs/jobs-details/${data?.id}`}>{data?.job_title}</Link>
                            </h4>
                            <ul className="job-recomm-addrs">
                              {data?.job_location_meta ? (
                                <li>
                                  <i title="Location" className="fas fa-map-marker-alt"></i>
                                  Location: {data?.job_location_meta}
                                </li>
                              ) : (
                                ''
                              )}

                              <li>
                                <i title="Positions open" className="fas fa-users"></i>Positions
                                open: {data?.open_positions}
                              </li>
                              {data?.max_ctc != 0 ? (
                                <li>
                                  <i title="CTC" className="fas fa-rupee-sign"></i>CTC:{' '}
                                  {data?.min_ctc} -{data?.max_ctc} (Lacs)
                                </li>
                              ) : (
                                ''
                              )}
                              <li>
                                <i title="Experience Required" className="fas fa-briefcase"></i>
                                Experience:{data?.min_exp} -{data?.max_exp} (yrs).
                              </li>
                              <li>
                                <i
                                  title={jobType(data?.job_type)}
                                  className="fas fa-hourglass-end"></i>
                                Job Type: {jobType(data?.job_type)}
                              </li>
                            </ul>
                            <span
                              dangerouslySetInnerHTML={{
                                __html: truncate(data?.job_info, 200)
                              }}></span>
                            <Link className="view-detail-btn" to={`/jobs/jobs-details/${data?.id}`}>
                              View Details
                            </Link>
                          </div>
                          <div className="job-recommd-ftr">
                            <ul>
                              <li>
                                {!data?.is_applied ? (
                                  <Link
                                    className="apply-btn"
                                    onClick={() => {
                                      onApplyStateHandler(data);
                                    }}>
                                    <i className="fas fa-check me-1"></i>Apply
                                  </Link>
                                ) : (
                                  <Link className="btn applied-btn">
                                    <i className="fas fa-check me-1"></i>Already Applied
                                  </Link>
                                )}
                                {/* pop-up */}
                              </li>
                              <li>
                                <Link
                                  to="#"
                                  onClick={(e) => {
                                    onRecommendClick(e, data?.id);
                                  }}>
                                  <i className="fas fa-thumbs-up me-1"></i>Recommend
                                </Link>
                              </li>
                              <li>
                                <Link
                                  onClick={() => {
                                    handleShow();
                                    // getShareLink();
                                    dispatch(shareLinkAction(data.id));
                                  }}>
                                  <i className="fas fa-share me-1"></i> Share
                                </Link>
                              </li>
                            </ul>
                          </div>
                        </div>
                      );
                    })
                  )}
                </div>
              </div>
              {total && list?.length !== total && (
                <div className="load-more-btn text-center">
                  <Button variant="secondary" className="read-more" onClick={handleLoadMore}>
                    Load More
                  </Button>
                </div>
              )}
            </Col>
            {socialProfileProfessional ? (
              <></>
            ) : (
              <Col xl={{ span: 3, offset: 0 }} lg={{ span: 9, offset: 2 }}>
                <div>
                  <PeopleKnow />
                </div>
                <div>
                  <GroupDiscover />
                </div>
                <div>
                  <FollowCompany />
                </div>
                <div>
                  <CampusNews />
                </div>
                <div>
                  <ArticalBlogs />
                </div>
                <div>
                  <CampusnewsText />
                </div>
                <div>
                  <WhatsNew />
                </div>
                <div>
                  <Upcoming />
                </div>
                <MiniFooter />
              </Col>
            )}
          </Row>
        </div>
      </section>

      {/* recommended job modal */}
      <RecomendModal handleClose3={handleClose3} show3={show3} jobId={jobId} />
      {/* end of modal */}
      {/* <FooterMain className={'colour-bg'} /> */}
      {/* apply-job pop-up form */}
      <ApplyForm
        onChangeHandler={onChangeHandler}
        setIsChecked={setIsChecked}
        isChecked={isChecked}
        submitHandler={submitHandler}
        applyForm={applyForm}
        show2={show2}
        handleClose2={handleClose2}
        jobTitle={jobTitle}
        resumeSelect={resumeSelect}
        fileSizeValid={fileSizeValid}
        getUserDetail={getUserDetail}
        getNetworkJob={getJobList}
      />
      {/*  end of modal */}
      {/* share modal */}
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Share this job</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="share-job-card text-center">
            <h4 className="mb-4">Share it on</h4>
            <div className="social-round">
              <ul>
                <li>
                  <Link target="_blank" className="facebook" to={result.facebook}>
                    <i className="fab fa-facebook-f"></i>
                  </Link>
                </li>
                <li>
                  <Link target="_blank" className="twitter" to={result.twitter}>
                    <i className="fab fa-twitter"></i>
                  </Link>
                </li>
                <li>
                  <Link target="_blank" className="google" to={result.google_plus}>
                    <i className="fab fa-google-plus-g"></i>
                  </Link>
                </li>
                <li>
                  <Link target="_blank" className="linkdin" to={result.linkdin}>
                    <i className="fab fa-linkedin-in"></i>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/* end */}
      {/* friend-list */}
      {/* {loading ? <Loader /> : <></>} */}
    </>
  );
}

export default LoginFeeds;
