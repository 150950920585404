import { useEffect, useState } from 'react';
import { Button, OverlayTrigger, Popover } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { getPeopleListForChepterAdd } from '../../../services/SocialServices';
import { addFriend, followFriend } from '../../../services/UserServices';
import showNotification from '../../../services/NotificationService';

function MembersCard({ chapterData, handleActive }) {
  const [membersData, setMembersData] = useState([]);
  const [count, setCount] = useState('');
  const id = useParams()?.encrypted_id;
  const userDetail = useSelector((state) => state?.user?.details);

  useEffect(() => {
    getPeopleList();

    return () => {
      getPeopleList();
    };
  }, []);

  const getPeopleList = () => {
    let body = {
      type: 'members'
    };
    getPeopleListForChepterAdd(id, '', body).then((resp) => {
      if (resp.statusText === 'OK') {
        setCount(resp?.data?.data?.count_members);

        setMembersData(
          resp?.data?.data?.peoples?.filter(
            (item) => item?.encrypted_id !== userDetail?.encrypted_id
          )
        );
      } else {
        setMembersData([]);
      }
    });
  };

  const renderPopover = (props) => {
    let popOverData = props?.popper?.state?.options?.data;
    
    return (
      <Popover className="group-loader-card" id={`popover-basic`} {...props}>
        {/* <Popover.Header as="h3">Popover right</Popover.Header> */}
        <Popover.Body>
          <div className="loader-tooltip">
            <div className="loader-cover">
              <img
                src={
                  popOverData?.actual_cover_url
                    ? popOverData?.actual_cover_url
                    : require('../../../assets/images/default-male-avatar.png')
                }
                alt="img"
                className="loader-image"
              />
            </div>
            <div className="people-know-loader-dis d-flex align-items-top">
              <div className="people-knw-top d-flex align-items-center">
                <div className="people-icn me-3">
                  <img
                    src={
                      popOverData?.avatar
                        ? popOverData?.avatar?.complete_url
                        : require('../../../assets/images/default-male-avatar.png')
                    }
                    alt="img"
                    className="loader-image"
                  />
                </div>
                <div className="people-hd">
                  <h3>{popOverData?.name}</h3>
                </div>
              </div>
            </div>
            <div className="people-knw-dis-popover pt-3">
              {/* <p>
                <i className="fas fa-graduation-cap me-1"></i> Studied at{' '}
                <Link to="#">SD COLLEGE Chandigarh</Link> in <Link to="#">2005</Link>
              </p> */}
              {popOverData?.work_experience?.length != 0 && (
                <p>
                  <i className="fas fa-briefcase me-1"></i> Worked as{' '}
                  <Link to="#">{popOverData?.work_experience[0]?.designation}</Link> {' at '}
                  <Link to="#">{popOverData?.work_experience[0]?.organisation}</Link>
                </p>
              )}

              <div className="popover-inr-btn">
                {popOverData?.active == '' || popOverData?.active == null ? (
                  <Button
                    className="popovr-blk-btn me-1"
                    onClick={(e) => requestHandler(e, popOverData?.id, 'friend')}>
                    Add Friend
                  </Button>
                ) : popOverData?.active == 0 ? (
                  <>
                    {' '}
                    <Button
                      className="wht-btn"
                      onClick={(e) => requestHandler(e, popOverData?.id, 'friend', 'requested')}>
                      Requested
                    </Button>
                  </>
                ) : (
                  <Button
                    className="popovr-blk-btn me-1"
                    onClick={(e) => requestHandler(e, popOverData?.id, 'friend', 'unfriend')}>
                    Unfriend
                  </Button>
                )}
                <button className="popovr-yellow-btn">Follow Mentor</button>
              </div>
            </div>
          </div>
        </Popover.Body>
      </Popover>
    );
  };

  const requestHandler = (e, id, type, unfriend) => {
    e.preventDefault();

    if (type == 'friend') {
      let body = {
        following_id: id
      };
      if (unfriend) {
        if (
          confirm(
            unfriend == 'requested'
              ? 'Do you want to remove request ?'
              : 'Do you want to remove friend ?'
          ) === true
        ) {
          addFriend(body).then((res) => {
            if (res?.status == 200) {
              getPeopleList();
            } else {
              showNotification('success', res?.data?.message);
            }
          });
        }
      } else {
        addFriend(body).then((res) => {
          if (res?.status == 200) {
            getPeopleList();
          } else {
            showNotification('success', res?.data?.message);
          }
        });
      }
    } else {
      let body = {
        following_id: id
      };
      followFriend(body).then((res) => {
        if (res?.status == 200) {
          getPeopleList();
        } else {
          showNotification('success', res?.data?.message);
        }
      });
    }
  };

  return (
    <>
      <div className="whats-new-sec bg-white p-3 mb-3">
        <div className="d-flex align-items-top justify-content-between flex-wrap">
          <h4>Members</h4>
          <Link className="more-btn" to="#" onClick={handleActive}>
            {count} Members
          </Link>
        </div>
        <div className="membr-inr-card">
          <ul className="member-lst-card">
            {membersData?.length !== 0 ? (
              membersData?.map((data, index) => {
                return (
                  <li key={index}>
                    <Link to={data?.url}>
                      <OverlayTrigger
                        trigger={['hover', 'focus']}
                        placement="bottom"
                        delay={{ show: 500, hide: 1000 }}
                        data={data}
                        popperConfig={{
                          data
                        }}
                        shouldUpdatePosition={true}
                        overlay={renderPopover}>
                        <img
                          src={
                            data?.avatar?.complete_url
                              ? data?.avatar?.complete_url
                              : require('../../../assets/images/default-male-avatar.png')
                          }
                          alt="img"
                        />
                      </OverlayTrigger>
                    </Link>
                  </li>
                );
              })
            ) : (
              <></>
            )}
          </ul>

          <div className="member-card-dis">
            <h4>Description</h4>
            <p>{chapterData?.about}</p>
            <h4>Chapter Type</h4>
            <p style={{ textTransform: 'capitalize' }}>{chapterData?.chapter_privacy} Chapter</p>
          </div>
        </div>
      </div>
    </>
  );
}

export default MembersCard;
