import { useEffect, useRef, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  post_publisher_soundcloud_placeholder,
  post_publisher_youtube_placeholder
} from '../../../globals/constants';
import { useOnClickOutside } from '../../../globals/helper';
import useDebounce from '../../../globals/useDebounce';
import useDocumentTitle from '../../../globals/useDocumentTitle';
import { deleteMediaFileAction, youtubeVideoAction } from '../../../redux/action';
import showNotification from '../../../services/NotificationService';
import * as APISERVICES from '../../../services/SocialServices';
import { MiniFooter } from '../../MiniFooter';
import HeaderLogin from '../../commoncomponents/HeaderLogin';
import MemberShipPOPUP from '../../commoncomponents/MemberShipPOPup/MemberShipPOPUP';
import ArticalBlogs from '../../commoncomponents/newsfeeds/ArticalBlogs';
import CampusNews from '../../commoncomponents/newsfeeds/CampusNews';
import CampusnewsText from '../../commoncomponents/newsfeeds/CampusnewsText';
import FollowCompany from '../../commoncomponents/newsfeeds/FollowCompany';
import GroupDiscover from '../../commoncomponents/newsfeeds/GroupDiscover';
import NewsSidebar from '../../commoncomponents/newsfeeds/NewsSidebar';
import PeopleKnow from '../../commoncomponents/newsfeeds/PeopleKnow';
import Upcoming from '../../commoncomponents/newsfeeds/Upcoming';
import WhatsNew from '../../commoncomponents/newsfeeds/WhatsNew';
import AddPollQuestion from '../user-pages/AddPollQuestion';
import PostBottomMedia from './PostComponets/PostBottomMedia';
import PostLayout from './PostComponets/PostLayout';
import TopTabbings from './TopTabbings';
import WorkAnniversary from '../../commoncomponents/newsfeeds/WorkAnniversary';

function Social() {
  /* eslint-disable */
  const ref = useRef();
  const dispatch = useDispatch();
  useOnClickOutside(ref, () => setShow(false));
  useDocumentTitle('AlmaBay');
  const isloggedIn = useSelector((state) => state?.user?.isloggedIn);
  const userDetail = useSelector((state) => state?.user?.details);
  const result = useSelector((state) => state?.youtubeVideoList?.videos);

  const [latestPost, setLatestPost] = useState('');
  const [privacyList, setPrivacyList] = useState('');
  const [checkPost, setCheckPost] = useState([]);
  const [selectedFile, setSelectedFile] = useState([]);
  const [uploadFiles, setUploadFiles] = useState([]);
  const [isSelected, setIsSelected] = useState(false);
  const [isSelectedFile, setIsSelectedFile] = useState(false);
  const [show, setShow] = useState(false);
  const [selectedData, setSelectedData] = useState(['4']);
  const [multiSelectData, setMultiSelectData] = useState([]);
  const [singleSelectName, setSingleSelectName] = useState('Everyone');
  const [multiSelectName, setmultiSelectName] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingState, setLoadingState] = useState('loading');
  const [showCard, setShowCard] = useState(true);
  const [totalPages, setTotalPages] = useState(0);
  const [pageNum, setPageNum] = useState(0);
  const [emojiList, setEmojiList] = useState([]);
  const [showEmoji, setShowEmoji] = useState(false);
  const [disableInputBox, setDisableInputBox] = useState(false);
  const [soundCloud, setSoundCloud] = useState(false);
  const [youtubeVideoCloud, setYoutubeVideoCloud] = useState(false);
  const [postGoogleMapState, setPostGoogleMapState] = useState(false);
  const [isShowPoll, setIsShowPoll] = useState(false);
  const [postLoading, setPostLoading] = useState(false);
  const [search, setSearch] = useState('');
  const [attemptPoll, setAttemptPoll] = useState(false);
  const [totalData, setTotalData] = useState('');

  useEffect(() => {
    if (pageNum <= totalPages) {
      getPostListData();
    }
  }, [pageNum, isShowPoll, attemptPoll]);

  useEffect(() => {
    if (isloggedIn) {
      isPrivacylist();
      getEmojiIcons();
    }
  }, []);

  /**
   * GET STORIES FUNCTION STARTS
   */

  /**
   * @param {*} isTrue
   * @param {*} postId
   */
  const getPostListData = (isTrue, postId) => {
    if (isTrue !== 'delete' || isTrue !== 'share') {
      setLoading(true);
      setDisableInputBox(true);
    }

    APISERVICES.getTimeLineSocial(pageNum).then((res) => {
      if (res?.status === 200) {
        setPostLoading(false);
        setLoading(false);
        setDisableInputBox(false);
        setAttemptPoll(false);
        /**
         * in this condition passing is true from post layout component when infity scroll is working
         * so here we find the object and replace it's to response object if id is matched and  again stored in to the checkpost
         */
        if (isTrue === true) {
          let arra1 = checkPost.map((item) => {
            const item2 = res.data?.data?.stories?.find((i2) => i2.id == item.id);
            return item ? { ...item, ...item2 } : item2;
          });
          setCheckPost(arra1);
          setPostLoading(false);
          setLatestPost('');
        } else {
          if (res?.data?.data) {
            setCheckPost(
              pageNum > 0 ? [...checkPost, ...res?.data?.data?.stories] : res?.data?.data?.stories
            );
          } else {
            setCheckPost([]);
          }
          /**
           * this condition is for share data when we hit share button from the post layout and we are not the first page (infinite scroll)
           * then we have to attach data to the current check post state
           */
          if (isTrue == 'share') {
            let checkPostIDs = checkPost?.map((data) => data?.id);

            let arra1 = checkPost.map((item) => {
              const item2 = res.data?.data?.stories?.find((i2) => i2.id == item.id);
              return item ? { ...item, ...item2 } : item2;
            });
            /**
             * hitting this story api again for the shared data response and merge in to the checkpost state.
             */
            APISERVICES.getTimeLineSocial(1).then((res2) => {
              let filterData = res2.data?.data?.stories.filter((item) => {
                return !checkPostIDs.includes(item?.id);
              });
              setCheckPost([...filterData, ...arra1]);
            });
          }
          /**
           * this condition or the unshare the post to find filter from the checkpost
           */
          if (isTrue == 'unshare') {
            let arra1 = checkPost
              .map((item) => {
                const item2 = res.data?.data?.stories?.find((i2) => i2.id == item.id);
                return item ? { ...item, ...item2 } : item2;
              })
              ?.filter((data) => !(data?.post_id == postId && data?.via_type == 'share'));
            setCheckPost(arra1);
          }

          if (isTrue == 'makePost') {
            setCheckPost(res?.data?.data?.stories);
          }
        }

        setTotalPages(Math.ceil(res?.data?.data?.total_records / 10));
        setTotalData(res?.data?.data?.total_records);
      } else {
        setLoading(false);
        setDisableInputBox(false);
      }
    });
  };

  /**
   * GET STORIES FUNCTION ENDS
   */

  const isPrivacylist = () => {
    APISERVICES.postPrivacyList().then((res) => {
      if (res?.status == 200) {
        let arr = [];
        let data = res?.data?.data;
        for (let i in data) {
          arr.push({ id: i, label: data[i], value: data[i] });
        }
        setPrivacyList(arr);
      }
    });
  };

  const checkLatestpost = (e) => {
    e.preventDefault();
    setPostLoading(true);
    if (multiSelectData?.length !== 0 || selectedData.length !== 0) {
      let formData = new FormData();
      formData.append(`text`, latestPost?.trim());
      formData.append(`recipient_id`, '');
      formData.append(`timeline_id`, userDetail?.id);
      formData.append(
        `post_privacy[]`,
        multiSelectData?.length !== 0 ? multiSelectData.toString() : selectedData.toString()
      );
      for (let i in selectedFile) {
        formData.append(`photos[]`, selectedFile[i]);
      }
      for (let i in uploadFiles) {
        formData.append('files[]', uploadFiles[i]);
      }
      APISERVICES.postLatest(formData).then((res) => {
        if (res?.status === 200) {
          // setPostLoading(false);
          setLatestPost('');
          showNotification('success', res?.data?.message);
          setSelectedFile([]);
          setUploadFiles([]);
          setIsSelected(false);
          setIsSelectedFile(false);
          setSelectedData(['4']);
          setMultiSelectData([]);
          setmultiSelectName([]);
          setSingleSelectName('Everyone');
          setPageNum(0)
          getPostListData('makePost');
        }
      });
    } else {
      showNotification('danger', 'Please select privacy before publishing post');
    }
  };

  const makePost = (e) => {
    if (latestPost?.trim() !== '' || selectedFile.length !== 0 || uploadFiles.length !== 0) {
      checkLatestpost(e);
    } else {
      showNotification(
        'danger',
        'This status update appears to be blank. Please write something or attach photo to update your status.'
      );
    }
  };

  const settingPrivacySelect = (e, data, index) => {
    e.preventDefault();
    if (data?.id == 0 || data?.id == 4) {
      setMultiSelectData([]);
      setmultiSelectName([]);
      setSingleSelectName(data?.value);
      if (selectedData.includes(data?.id)) {
        setSelectedData([]);
        setSingleSelectName('');
      } else {
        setSelectedData([data?.id]);
      }
    } else {
      setSelectedData([]);
      setSingleSelectName('');
      if (multiSelectData.includes(data?.id)) {
        let filterData = multiSelectData?.filter((item) => item !== data?.id);
        let filterName = multiSelectName?.filter((item) => item !== data?.value);

        setMultiSelectData(filterData);
        setmultiSelectName(filterName);
      } else {
        setMultiSelectData([...multiSelectData, data?.id]);
        setmultiSelectName([...multiSelectName, data?.value]);
      }
    }
  };

  const privacyDropClassSingle = (data) => {
    if (selectedData?.length == 0) {
      return '';
    } else {
      if (selectedData?.includes(data?.id)) {
        return 'selected';
      } else {
        return 'disabled-component';
      }
    }
  };
  const privacyDropClassMulti = (data) => {
    if (multiSelectData?.length == 0) {
      return '';
    } else {
      if (multiSelectData?.includes(data?.id)) {
        return 'selected';
      } else {
        return '';
      }
    }
  };

  const selectedValue = () => {
    let name;
    if (multiSelectData?.length !== 0 || singleSelectName !== '') {
      if (multiSelectName?.length !== 0) {
        name = multiSelectName?.map((name) => name).join(',');

        return <Form.Control type="text" value={name} onClick={() => setShow(!show)} />;
      } else {
        return <Form.Control type="text" value={singleSelectName} onClick={() => setShow(!show)} />;
      }
    } else {
      return <Form.Control type="text" value={'Nothing selected'} onClick={() => setShow(!show)} />;
    }
  };

  const handleClosePopUp = (e) => {
    e.preventDefault();
    setShowCard(false);
  };

  const onPostListData = () => {
    getPostListData();
  };

  const onIncrementPageNo = () => {
    totalData > 10 && setPageNum((no) => no + 1);
  };

  const getEmojiIcons = () => {
    APISERVICES.getPublisherBox().then((res) => {
      if (res?.status == 200) {
        let arr = [];
        let data = res?.data?.data?.emoticons;
        for (let i in data) {
          arr.push({ id: i, label: data[i], value: data[i] });
        }
        setEmojiList(arr);
      } else {
        setEmojiList([]);
      }
    });
  };

  const newParagraph = (e) => {
    if (e.key === 'Enter') {
    }
  };

  const toggleMediaGroupFun = (chosen_input_selector = '') => {
    setSoundCloud(false);
    setYoutubeVideoCloud(false);
    setPostGoogleMapState(false);

    if (chosen_input_selector == 'soundcloud-wrapper') {
      setSoundCloud(true);
    } else if (chosen_input_selector == 'youtube-wrapper') {
      setYoutubeVideoCloud(!youtubeVideoCloud);
    } else if (chosen_input_selector == 'google-map-wrapper') {
      setPostGoogleMapState(true);
    }
  };

  const debouncedSearchTerm = useDebounce(search, 500);

  useEffect(() => {
    if (debouncedSearchTerm) {
      dispatch(youtubeVideoAction(search));
    } else {
      dispatch(youtubeVideoAction());
    }
  }, [debouncedSearchTerm]);

  const deleteFile = async (post_id, media_id) => {
    const message = window.confirm('Are you sure you want to remove this file?');
    if (message) {
      dispatch(deleteMediaFileAction(post_id, media_id));
      getPostListData();
    }
  };

  return (
    <>
      <HeaderLogin />
      <div className="header-lnk-feeds">
        <div className="container">
          <TopTabbings state={'social'} />
        </div>
      </div>{' '}
      <section className="news-feed-sec bg-grey py-4">
        <Container>
          <Row>
            <Col xs={12} lg={2}>
              <NewsSidebar state="1" current="social" />
            </Col>
            <Col xs={12} lg={9} xl={7} className="mb-4">
              {showCard ? (
                <>
                  <MemberShipPOPUP handleClosePopUp={handleClosePopUp} />
                </>
              ) : (
                <></>
              )}

              {/* post -social page- */}
              {loading && loadingState !== '' ? (
                <>
                  {' '}
                  {Array.from(Array(3), (index) => {
                    return <Shimmer key={index} />;
                  })}
                </>
              ) : (
                <>
                  {' '}
                  {!isShowPoll ? (
                    <div className="write-on-wall mb-3">
                      <h4>
                        <i className="fas fa-edit me-2"></i>
                        What's going on?
                      </h4>
                      <textarea
                        rows={5}
                        style={{ width: '100%', resize: 'none' }}
                        placeholder={'Write something... #hashtags'}
                        onChange={(e) => {
                          setLatestPost(e.target.value);
                        }}
                        disabled={postLoading}
                        onKeyDown={(e) => newParagraph(e)}
                        value={latestPost}></textarea>
                      {soundCloud && (
                        <div className="upload-img-detail d-flex align-items-center justify-content-between">
                          <i className="fa fa-music"></i>
                          <input
                            className="soundcloud-input"
                            type="text"
                            autoFocus
                            // onKeyUp={() => SK_searchSoundcloud()}
                            placeholder={post_publisher_soundcloud_placeholder}
                          />
                          {/* <div className="input-result-wrapper"></div> */}
                        </div>
                      )}
                      {youtubeVideoCloud && (
                        <div className="upload-img-detail d-flex align-items-center justify-content-start">
                          <i className="fa fa-film me-2"></i>
                          <input
                            className="youtube-input"
                            type="text"
                            onChange={(e) => {
                              setSearch(e.target.value);
                            }}
                            placeholder={post_publisher_youtube_placeholder}
                          />
                          <div className="input-result-wrapper"></div>
                        </div>
                      )}
                      {postGoogleMapState && (
                        <div className="upload-img-detail d-flex align-items-center justify-content-between">
                          <i className="fa fa-map-marker "></i>
                          <input
                            className="youtube-input"
                            type="text"
                            // onKeyUp={() => SK_searchYoutube()}
                            placeholder={post_publisher_youtube_placeholder}
                          />

                          {/* <input id="autocomplete" className="google-map-input" name="google_map_name" type="text" value="" placeholder="<?php echo $this->lang->line('post_publisher_googlemap_placeholder'); ?>" apply-google-places ></input> */}
                        </div>
                      )}
                      {/* <CKEditorComponent
                      latestPost={latestPost}
                      setLatestPost={setLatestPost}
                      isToolBar={false}
                      placeholder={'Write something... #hashtags'}
                    /> */}
                      {isSelected && (
                        <div className="upload-img-detail d-flex align-items-center justify-content-between">
                          <p>
                            {selectedFile?.length > 1
                              ? `${selectedFile.length} photo(s) selected`
                              : `${selectedFile.length} photo selected`}
                          </p>
                          <Link
                            to="#"
                            onClick={() => {
                              setIsSelected(false);
                              setIsSelectedFile(false);
                              setSelectedFile([]);
                              setUploadFiles([]);
                            }}>
                            <i className="fas fa-times"></i>
                          </Link>
                        </div>
                      )}

                      {isSelectedFile && (
                        <div className="upload-img-detail d-flex align-items-center justify-content-between">
                          <p>
                            {uploadFiles?.length > 1
                              ? `${uploadFiles.length} file(s) selected`
                              : `${uploadFiles.length} file selected`}
                          </p>
                          <Link
                            to="#"
                            onClick={() => {
                              setIsSelected(false);
                              setIsSelectedFile(false);
                              setSelectedFile([]);
                              setUploadFiles([]);
                            }}>
                            <i className="fas fa-times"></i>
                          </Link>
                        </div>
                      )}
                      {showEmoji && (
                        <div className="upload-img-detail d-flex align-items-center justify-content-between">
                          {emojiList?.length !== 0 && (
                            <>
                              {emojiList?.map((data) => {
                                return (
                                  <Link
                                    to="#"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      setLatestPost(latestPost + data?.id);
                                    }}>
                                    <img src={data?.value} />
                                  </Link>
                                );
                              })}
                            </>
                          )}
                        </div>
                      )}
                      {result.length > 0 && youtubeVideoCloud ? (
                        <div className="input_result_wrapper">
                          {result.map((item, i) => {
                            return (
                              <div className="api_data_wrapper">
                                <table border={0} width={'100%'} cellSpacing={0} cellPadding={0}>
                                  <tbody>
                                    <tr>
                                      <td width={'40%'} align="left" valign="middle">
                                        <img
                                          className="thumbnail"
                                          width={32}
                                          height={32}
                                          src={item.snippet.thumbnails.high.url}
                                          alt="Youtube"
                                        />
                                      </td>
                                      <td align="left" valign="middle">
                                        <div className="name">{item.snippet.title}</div>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            );
                          })}
                        </div>
                      ) : (
                        search != '' && <span>No Result Found</span>
                      )}
                      <div className="write-wall-ftr d-flex align-items-center justify-content-between">
                        <PostBottomMedia
                          setSelectedFile={setSelectedFile}
                          setUploadFiles={setUploadFiles}
                          setIsSelected={setIsSelected}
                          setIsSelectedFile={setIsSelectedFile}
                          state={'social-timeline'}
                          setShowEmoji={setShowEmoji}
                          showEmoji={showEmoji}
                          toggleMediaGroup={toggleMediaGroupFun}
                          setIsShowPoll={setIsShowPoll}
                        />
                        <div className="wall-fltr-rght d-flex align-items" ref={ref}>
                          <div className="wall-ftr-rt">
                            <Form.Group className="mb-0" controlId="formBasictext">
                              {selectedValue()}
                            </Form.Group>
                            {show && (
                              <ul className="wall-lst">
                                {privacyList?.length !== 0 ? (
                                  privacyList?.map((data, index) => {
                                    return (
                                      <li key={index}>
                                        <Link
                                          className={
                                            multiSelectData?.length !== 0
                                              ? privacyDropClassMulti(data)
                                              : privacyDropClassSingle(data)
                                          }
                                          onClick={(e) => settingPrivacySelect(e, data, index)}>
                                          {data?.value}
                                        </Link>
                                      </li>
                                    );
                                  })
                                ) : (
                                  <li>
                                    <Link className="selected disabled-component">
                                      No data found
                                    </Link>
                                  </li>
                                )}
                              </ul>
                            )}
                          </div>
                          <button
                            style={postLoading ? { cursor: 'not-allowed' } : { cursor: 'pointer' }}
                            disabled={postLoading}
                            className="accnt-btn ms-2"
                            onClick={(e) => {
                              makePost(e);
                            }}>
                            <i className="far fa-arrow-alt-circle-right me-1"></i>
                            {postLoading ? 'Processing' : 'Post'}
                          </button>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <AddPollQuestion
                      setIsShowPoll={setIsShowPoll}
                      getPostListData={getPostListData}
                      setAttemptPoll={setAttemptPoll}
                    />
                  )}
                  <PostLayout
                    isPostLoading={loading}
                    checkPost={checkPost}
                    getPostListData={getPostListData}
                    totalPages={totalPages}
                    onIncrementPageNo={onIncrementPageNo}
                    pageNum={pageNum}
                    // onPostListData={onPostListData}
                    setCheckPost={setCheckPost}
                    setLoadingState={setLoadingState}
                    loadingState={loadingState}
                    disableInputBox={disableInputBox}
                    setDisableInputBox={setDisableInputBox}
                    deleteFile={deleteFile}
                    setAttemptPoll={setAttemptPoll}
                  />
                </>
              )}
            </Col>
            <Col xl={{ span: 3, offset: 0 }} lg={{ span: 9, offset: 2 }}>
              <div>
                <PeopleKnow />
              </div>
              <div>
                <WorkAnniversary />
              </div>
              <div>
                <GroupDiscover />
              </div>
              <div>
                <FollowCompany />
              </div>
              <div>
                <CampusNews />
              </div>
              <div>
                <ArticalBlogs />
              </div>
              <div>
                <CampusnewsText />
              </div>
              <div>
                <WhatsNew />
              </div>
              <div>
                <Upcoming />
              </div>
              <MiniFooter />
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}

export default Social;

const Shimmer = () => {
  return (
    <div className="pofile-upload-card loading-skeleton profile-upload-loader">
      <div className="profile-upload-sec d-flex align-items-top mb-3">
        <Link to="/user/timeline/undefined">
          <span className="social-profile-icn me-3">
            <img src={require('../../../assets/images/birth4.png')} alt="img" />
          </span>
        </Link>
        <div className="social-profile-detail d-flex align-items-top justify-content-between ">
          <div className="social-profile-name">
            <h4>
              <Link to="#">
                Coordinator<span>...</span>
              </Link>
              updated his profile picture
            </h4>
            <p>
              <i className="far fa-clock me-2" />
              42 minutes ago
              <i className="fas fa-users ms-2" title="shared with everyone" />
            </p>
          </div>
          <div className="drop-ellipse-actn">
            <div className="ellipse-drop-card ms-3 dropdown">
              <button
                type="button"
                id="dropdown-basic"
                aria-expanded="false"
                className="dropdown-toggle btn btn-success">
                <i className="fas fa-ellipsis-v" />
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="profile-top-dis">
        <p>hlo this is post </p>
      </div>
      <div className="profile-upload-ftr">
        <div className="profile-likes-count d-flex align-items-center justify-content-between">
          <div className="likes-count">
            <Link to="#">1 Likes</Link>
            <Link to="#">0 Shares</Link>
          </div>
          <div className="cmmnts-count">1 Comments</div>
        </div>
        <div className="profile-like-btns">
          <ul>
            <li>
              <Link title="Like" href="#">
                <i className="fas fa-thumbs-up me-2" />
                Like
              </Link>
            </li>
            <li>
              <Link title="Comments" to="#">
                <i className="fas fa-comments me-2" />
                Comment
              </Link>
            </li>
            <li>
              <Link title="Share" to="#">
                <i className="fas fa-share me-2" />
                Share
              </Link>
            </li>
          </ul>
        </div>
      </div>
      <div className="profile-upload-cmmnt mt-3">
        <div className="profile-cmmnt-lst d-flex align-items-top mb-3">
          <span className="social-profile-icn me-3">
            <img src={require('../../../assets/images/birth4.png')} alt="img" />
          </span>
          <div className="profile-cmmnt-user ">
            <div className="d-flex align-items-top justify-content-between">
              <Link to="#">Coordinator</Link>
              <div className="profile-cmmnt-time d-flex align-items-center ">
                <span />
                <div className="ellipse-drop-card ms-3 dropdown">
                  <button
                    type="button"
                    id="dropdown-basic"
                    aria-expanded="false"
                    className="dropdown-toggle btn btn-success">
                    <i className="fas fa-ellipsis-v" />
                  </button>
                </div>
              </div>
            </div>
            <div className="profile-cmmnt-dis">
              <p>ffffff</p>
              <div className="cmmnt-likes d-flex align-items-center">
                <Link to="">
                  <i className="fas fa-thumbs-up me-1" />
                  Like <span>1</span>
                  likes
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
