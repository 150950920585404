import { useEffect, useState } from 'react';
import { Container, Button, Modal, Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { ZERO } from '../../../globals/constants';
import { createProductReview } from '../../../services/ShopServices';
import * as APISERVICE from '../../../services/AuthServices';
import { useDispatch } from 'react-redux';
import { loginInfo } from '../../../redux/action/index';
import showNotification from '../../../services/NotificationService';
export default function ReviewProducts({
  productData,
  handleShow,
  isloggedIn,
  nameData,
  handleClose,
  id,
  getProductDetail,
  show,
  setNameData,
  starcountState,
  allstarcountState,
  getProductsReview,
  reviewState,
  setLoading
}) {
  const [addReview, setReview] = useState({
    pdid: id,
    review_name: '',
    reviews_title: '',
    review_holder: ''
  });
  const [clicked, setClicked] = useState(false);
  const dispatch = useDispatch();
  const [checkCred, setCheckCred] = useState(false);

  const details =
    localStorage.getItem('userDetail') && JSON.parse(localStorage.getItem('userDetail'))?.name;
  useEffect(() => {
    const detail =
      localStorage.getItem('userDetail') && JSON.parse(localStorage.getItem('userDetail'))?.name;
    if (detail) {
      setReview((prev) => ({
        ...prev,
        review_name: detail
      }));
    }
  }, [nameData]);
  const validate = (value) => {
    let error = {};
    if (!value.review_name) {
      error.review_name = 'Please enter user name';
    }
    if (!value.reviews_title) {
      error.reviews_title = 'Please enter title';
    } else if (value.reviews_title.trim().length < 10) {
      error.reviews_title = 'Review title should be atleast 10 characters long.';
    }
    if (!value.review_holder) {
      error.review_holder = 'Please enter reviews';
    } else if (value.review_holder.trim().length < 100) {
      error.review_holder = 'Review should be atleast 100 characters long';
    }

    return error;
  };

  const submitProductReview = (e) => {
    e.preventDefault();
    setClicked(true);

    if (
      !validate(addReview).review_name &&
      !validate(addReview).reviews_title &&
      !validate(addReview).review_holder
    ) {
      let formData = new FormData();
      for (let i in addReview) {
        formData.append(`${i}`, addReview[i]);
      }
      createProductReview(formData).then((res) => {
        if (res?.status === 200) {
          handleClose();
          showNotification('success', res?.data?.detail?.message);
          setReview({
            pdid: id,
            review_name: details,
            reviews_title: '',
            review_holder: ''
          });
          getProductDetail(id);
          setLoading(false);
          setClicked(false);
          getProductsReview(id);
        }
      });
    }
  };
  const [loginDetails, setLoginDetails] = useState({
    email: '',
    password: ''
  });

  const onChangeHandler = (e) => {
    const { name, value } = e.target;
    setLoginDetails({ ...loginDetails, [name]: value });
  };

  /**Login Validation */
  const loginValidate = (values) => {
    let errors = {};
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    if (!values.email) {
      errors.email = 'Please enter your email';
    } else if (!regex.test(values.email)) {
      errors.email = 'Invalid email format';
    }
    if (!values.password) {
      errors.password = 'Please enter your password';
    } else if (values.password.length < 6) {
      errors.password = 'Password must be more than 6 characters';
    }
    return errors;
  };

  const LoginsubmitHandlers = (e) => {
    e.preventDefault();
    setCheckCred(true);
    if (!loginValidate(loginDetails).password && !loginValidate(loginDetails).email) {
      let formData = new FormData();
      for (let i in loginDetails) {
        formData.append(`${i}`, loginDetails[i]);
      }
      APISERVICE.Login(formData).then((res) => {
        if (res?.status === 200) {
          dispatch(loginInfo(res?.data?.data));
          localStorage.setItem('token', res?.data?.access_token);
          localStorage.setItem('userDetail', JSON.stringify(res?.data?.data));
          showNotification('success', res?.data?.message);
          setNameData(true);
        }
      });
    }
  };
  const onChangeReview = (e) => {
    const { name, value } = e.target;
    setReview({ ...addReview, [name]: value });
  };
  /**average rating count */
  const getRatingsCount = (data) => {
    let rating = allstarcountState?.find((rating) => rating.ratings_score == data.toString());
    if (rating != null) {
      return rating.COUNT;
    }
    return 0;
  };
  return (
    <div>
      {' '}
      <section className="review-lst py-5">
        <Container>
          <div className="review-blk p-5">
            <div className="review-hd mb-4 d-flex align-items-center justify-content-between">
              <h4>Reviews of {productData?.name}</h4>
              <span className="btn-default btn btn-white" onClick={handleShow}>
                Write a Review
              </span>
              {isloggedIn ? (
                <Modal show={show} onHide={handleClose}>
                  <Modal.Header closeButton>
                    <Modal.Title>Product Reviews</Modal.Title>
                  </Modal.Header>

                  <Modal.Body>
                    <Form>
                      <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label>
                          <b>Your name(*)</b>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="review_name"
                          value={addReview.review_name}
                          onChange={(e) => {
                            onChangeReview(e);
                          }}
                        />
                        <div
                          className={`${clicked && validate(addReview).review_name ? '' : 'd-none'
                            } danger-frm `}
                        >
                          {!addReview.review_name && (
                            <>{clicked && validate(addReview).review_name}</>
                          )}
                        </div>
                      </Form.Group>
                      <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label>
                          <b>Review Title(*)</b>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="reviews_title"
                          value={addReview.reviews_title}
                          onChange={onChangeReview}
                        />
                        <div
                          className={`${clicked && validate(addReview).reviews_title ? '' : 'd-none'
                            } danger-frm `}
                        >
                          {clicked && validate(addReview).reviews_title}
                        </div>
                      </Form.Group>
                      <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label>
                          <b> Write Review(*)</b>
                        </Form.Label>
                        <textarea
                          className="form-control"
                          value={addReview.review_holder}
                          name="review_holder"
                          onChange={onChangeReview}
                          id="exampleFormControlTextarea1"
                          placeholder="Enter product review (min 100 characters)"
                          rows="3"
                        />
                        <div
                          className={`${clicked && validate(addReview).review_holder ? '' : 'd-none'
                            } danger-frm `}
                        >
                          {clicked && validate(addReview).review_holder}
                        </div>
                      </Form.Group>
                    </Form>
                  </Modal.Body>

                  <Modal.Footer>
                    <Button variant="primary" onClick={(e) => submitProductReview(e)}>
                      Submit
                    </Button>
                    <Button variant="secondary" onClick={handleClose}>
                      Close
                    </Button>
                  </Modal.Footer>
                </Modal>
              ) : (
                <Modal show={show} onHide={handleClose}>
                  <Modal.Header closeButton>
                    <Modal.Title>Product Reviews</Modal.Title>
                  </Modal.Header>

                  <Modal.Body>
                    <Form>
                      <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label>
                          <b>Email (*)</b>
                        </Form.Label>
                        <Form.Control
                          type="email"
                          placeholder="Enter your email address"
                          name="email"
                          value={loginDetails?.email}
                          onChange={(e) => {
                            onChangeHandler(e);
                          }}
                          isInvalid={checkCred && loginValidate(loginDetails)?.email}
                          autoComplete="off"
                        />
                        {checkCred && loginValidate(loginDetails)?.email ? (
                          <span
                            className={
                              `${checkCred && loginValidate(loginDetails)?.email}`
                                ? 'login-error'
                                : 'd-none'
                            }
                          >
                            {checkCred && loginValidate(loginDetails)?.email}
                          </span>
                        ) : (
                          ''
                        )}
                      </Form.Group>
                      <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label>
                          <b>Password (*)</b>
                        </Form.Label>
                        <Form.Control
                          type="password"
                          placeholder="Enter your password"
                          name="password"
                          value={loginDetails?.password}
                          onChange={(e) => {
                            onChangeHandler(e);
                          }}
                          isInvalid={checkCred && loginValidate(loginDetails)?.password}
                        />
                        {checkCred && loginValidate(loginDetails)?.password ? (
                          <span
                            className={
                              `${checkCred && loginValidate(loginDetails)?.password}`
                                ? 'login-error'
                                : 'd-none'
                            }
                          >
                            {checkCred && loginValidate(loginDetails)?.password}
                          </span>
                        ) : (
                          ''
                        )}
                      </Form.Group>
                    </Form>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button
                      variant="primary"
                      onClick={(e) => {
                        LoginsubmitHandlers(e);
                      }}
                    >
                      Submit
                    </Button>
                    <Button variant="secondary" onClick={handleClose}>
                      Cancel
                    </Button>
                  </Modal.Footer>
                </Modal>
              )}
            </div>
            <div className="average-star-card d-flex align-items-center" id="review">
              <div className="average-lft">
                <div className="avg-star-card">
                  <p> {starcountState?.score ? Number(starcountState?.score).toFixed(1) : 0} </p>
                </div>
                <p>Average Rating</p>
                <p>Based on {starcountState?.count ? starcountState?.count : 0} ratings </p>
                <h4>Reviews</h4>
              </div>
              <div className="review-lst">
                <ul>
                  {' '}
                  {[1, 2, 3, 4, 5].reverse().map((data) => {
                    return (
                      <>
                        <li title={`Read ${data} star reviews`}>
                          <Link to="javascript:void(0);">
                            {data} Star
                            <span className="star-count">{getRatingsCount(data)}</span>
                          </Link>
                        </li>
                      </>
                    );
                  })}
                </ul>
              </div>
            </div>

            {reviewState && reviewState?.length !== ZERO ? (
              reviewState?.map((data, index) => {
                return (
                  <div className="store-client-review" key={index}>
                    <div className="store-client-hd">
                      <p>{data?.reviews_title}</p>
                      <h4>
                        By <b>{data?.review_name}</b> , {data?.date}
                      </h4>
                    </div>
                    <div className="store-client-dis">
                      <p>{data?.prodect_reviews}</p>
                    </div>
                    <hr className="dash-hr" />
                  </div>
                );
              })
            ) : (
              <div className="review-ftr mt-3">
                <p>No reviews available.</p>
                <p>Be the first to write a Review</p>
              </div>
            )}
          </div>
        </Container>
      </section>
    </div>
  );
}
