const intitalState = {
  paths: []
};

const adminSidebarPathArray = (state = intitalState, action) => {
  switch (action.type) {
    case 'ACCESS_ROUTES':
      return {
        ...state,
        paths: action.payload
      };
    default:
      return state;
  }
};

export default adminSidebarPathArray;
