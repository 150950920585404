import {
  SINGLE_MENU_LIST_DETAIL_FAILED,
  SINGLE_MENU_LIST_DETAIL_PENDING,
  SINGLE_MENU_LIST_DETAIL_SUCCESS
} from '../action';

const initialState = {
  data: [],
  isLoading: false,
  hasError: ''
};

export const singleMenuListDetailReducer = (state = initialState, action) => {
  switch (action.type) {
    case SINGLE_MENU_LIST_DETAIL_PENDING:
      return {
        ...state,
        isLoading: true
      };

    case SINGLE_MENU_LIST_DETAIL_SUCCESS:
      return {
        ...state,
        data: action.payload ?? [],
        isLoading: false
      };

    case SINGLE_MENU_LIST_DETAIL_FAILED:
      return {
        ...state,
        data: {},
        isLoading: false,
        hasError: action.payload
      };

    default:
      return state;
  }
};
