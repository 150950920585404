import { useState, useEffect } from 'react';
import { Col, Container, Dropdown, Row } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import HeaderLogin from '../../commoncomponents/HeaderLogin';
import GroupDiscover from '../../commoncomponents/newsfeeds/GroupDiscover';
import WhatsNew from '../../commoncomponents/newsfeeds/WhatsNew';
import Upcoming from '../../commoncomponents/newsfeeds/Upcoming';
import NewsSidebar from '../../commoncomponents/newsfeeds/NewsSidebar';
import Form from 'react-bootstrap/Form';
import showNotification from '../../../services/NotificationService';
import Loader from '../../commoncomponents/loader/loader-large';
import TopTabbings from '../../webpages/user-pages/TopTabbings';
import * as APISERVICES from '../../../services/NewsServices';
import { useSelector } from 'react-redux';
import HeaderInner from '../HeaderInner';
import { MiniFooter } from '../../MiniFooter';
import useDocumentTitle from '../../../globals/useDocumentTitle';
import moment from 'moment/moment';
import ArticalBlogs from './ArticalBlogs';
import CampusnewsText from './CampusnewsText';
import FollowCompany from './FollowCompany';
import WorkAnniversary from './WorkAnniversary';
import PeopleKnow from './PeopleKnow';
import { subDomain } from '../../../globals/helper';

function NewsView() {
  if (subDomain()) {
    useDocumentTitle(subDomain());
  } else {
    useDocumentTitle('Connect With Your Almamater | AlmaBay');
  }
  const { id } = useParams();
  const [loading] = useState(false);
  const [detail, setDetail] = useState('');
  const isloggedIn = useSelector((state) => state?.user?.isloggedIn);
  const [comment, setComment] = useState('');
  const [commentList, setCommentList] = useState([]);
  const [error, setError] = useState('');
  const loginUser = JSON.parse(localStorage.getItem('userDetail'));

  useEffect(() => {
    getNewsDetail();
    getComment();
  }, [id]);

  const getNewsDetail = () => {
    APISERVICES.getWhatsNewDetail(id).then((res) => {
      if (res?.status === 200) {
        setDetail(res?.data?.data);
      }
    });
  };

  const saveHandler = () => {
    const body = {
      QComment: comment,
      q_id: id
    };

    if (isloggedIn) {
      APISERVICES.addWhatsNewsComment('whats-new', body).then((res) => {
        if (res?.status === 200) {
          setComment('');
          getComment();
          getNewsDetail();

          showNotification('success', 'Your comment posted successfully');
        }
      });
    } else {
      setError('Please Sign in or Sign up to comment.');
    }
  };

  const getComment = () => {
    APISERVICES.getCommentList(id).then((res) => {
      if (res?.status === 200) {
        setCommentList(res?.data?.detail);
      }
    });
  };
  const deleteComment = (e, id) => {
    e.preventDefault();
    const confirm = window.confirm('Do You Want to Delete Your Comment ?');

    if (confirm) {
      APISERVICES.removeNewsComment(id).then((res) => {
        if (res?.status === 200) {
          getComment();
          getNewsDetail();

          showNotification('success', 'comment removed successfully');
        }
      });
    }
  };
  return (
    <>
      {isloggedIn ? (
        <>
          <HeaderLogin />
          <div className="header-lnk-feeds">
            <div className="container">
              <TopTabbings />
            </div>
          </div>
          <section className="news-feed-sec bg-grey py-4">
            <Container>
              <Row>
                <Col xs={12} lg={2} md={12}>
                  <NewsSidebar />
                </Col>
                <Col xs={12} lg={7} md={12}>
                  <div className="articals_div">
                    <div className="single_artical_card leave-ques-card p-3">
                      <img
                        src={process.env.REACT_APP_API_URL + detail?.image}
                        alt="user_img"
                        title={detail?.title}
                      // className="me-3"
                      />

                      <div className="d-flex align-items-center mb-3">
                        <div className="media_img"></div>
                        <div className="media-body">
                          <h4>
                            <Link className="text-orange" to="#"></Link>
                            {detail?.title}
                          </h4>

                          <span dangerouslySetInnerHTML={{ __html: detail?.descp }} />
                        </div>
                      </div>
                      <div>
                        <hr className="seprator"></hr>
                        <Dropdown>
                          <Dropdown.Toggle className="pe-3" id="dropdown-basic">
                            <i className="fa fa-share"></i> Share
                          </Dropdown.Toggle>
                          <Dropdown.Menu className="p-2">
                            <Dropdown.Item
                              as={Link}
                              target="_blank"
                              to={`https://www.facebook.com/sharer/sharer.php?u=${process.env.REACT_APP_DOMAIN + 'news/show' + '/' + detail.id
                                }`}
                            >
                              Facebook
                            </Dropdown.Item>
                            <Dropdown.Item
                              as={Link}
                              target="_blank"
                              to={`https://twitter.com/intent/tweet?url=${process.env.REACT_APP_DOMAIN + 'news/show' + '/' + detail.id
                                }`}
                            >
                              Twitter
                            </Dropdown.Item>
                            <Dropdown.Item
                              as={Link}
                              target="_blank"
                              to={`https://plus.google.com/share?url=${process.env.REACT_APP_DOMAIN + 'news/show' + '/' + detail.id
                                }`}
                            >
                              Google
                            </Dropdown.Item>
                            <Dropdown.Item
                              as={Link}
                              target="_blank"
                              to={`https://www.linkedin.com/shareArticle?url=${process.env.REACT_APP_DOMAIN + 'news/show' + '/' + detail.id
                                }&mini=true&title=${detail?.title}&source=AlmaBay`}
                            >
                              Linkedin
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </div>
                    <h5 className="mb-4">Comments</h5>
                    <div className="leave-cmmnt-card-outr">
                      <div className="leave-cmmnt-lst">
                        {commentList && commentList
                          ? commentList?.map((data, index) => {
                            return (
                              <div className="leave-cmmnt-card bg-white d-flex align-items-top p-3 mb-2" key={index}>
                                {data?.commented_by == loginUser?.user?.id ||
                                  data?.commented_by == loginUser?.id ? (
                                  <Link
                                    className="close-btn"
                                    onClick={(e) => deleteComment(e, data?.id)}
                                  >
                                    <i className="fas fa-times"></i>
                                  </Link>
                                ) : (
                                  ''
                                )}

                                <div className="leave-cmmnt-icn">
                                  <img
                                    src={
                                      data?.url
                                        ? process.env.REACT_APP_API_DOMAIN + data?.url
                                        : require('../../../assets/images/default-male-avatar.png')
                                    }
                                    alt="user_img"
                                  />
                                </div>

                                <div className="leave-cmmnt-media w-100 ">
                                  <h4>
                                    <Link to={`/user/timeline/${data?.encrypted_id}`}>
                                      {data?.name}
                                    </Link>
                                    <span title={data?.added_on}>
                                      {moment(data?.added_on).fromNow()}
                                    </span>
                                  </h4>
                                  <span>{data?.QComment}</span>
                                </div>
                              </div>

                              // <div
                              //   className="mt-2  leave-cmmnt-media d-flex justify-content-between"
                              //   key={index}>
                              //   <div>
                              //     <p>{data?.name}</p>
                              //     <span> {data?.QComment} </span>
                              //   </div>
                              //   <Link onClick={(e) => deleteComment(e, data?.id)}>
                              //     <i className="fas fa-times"></i>
                              //   </Link>
                              // </div>
                            );
                          })
                          : ''}
                      </div>
                    </div>

                    <div className="leave-cmmnt-frm bg-white p-3">
                      <h4 className="mb-4"> Leave a Comment </h4>
                      <Form.Group className="mb-3" controlId="formBasicDegree">
                        <Form.Control
                          as="textarea"
                          type="text"
                          placeholder="write a comment"
                          style={{ height: '90px' }}
                          onChange={(e) => setComment(e.target.value)}
                          value={comment}
                        />
                      </Form.Group>

                      <div className="pt-2 attach-card-inr ">
                        <div className="cmmnt-btn mt-2 pb-2">
                          <button className="accnt-btn" onClick={saveHandler}>
                            Comment
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>

                <Col lg={3} md={12}>
                  <div>
                    <PeopleKnow />
                  </div>
                  <div>
                    <WorkAnniversary />
                  </div>
                  <div>
                    <GroupDiscover />
                  </div>
                  <div>
                    <FollowCompany />
                  </div>
                  <div>
                    <ArticalBlogs />
                  </div>
                  <div>
                    <CampusnewsText />
                  </div>
                  <div>
                    <WhatsNew />
                  </div>
                  <div>
                    <Upcoming />
                  </div>
                  <MiniFooter />
                </Col>
              </Row>
            </Container>
          </section>
        </>
      ) : (
        <>
          <HeaderInner />
          <div className="header-lnk-feeds">
            <div className="container">{/* <TopTabbings /> */}</div>
          </div>
          <section className="news-feed-sec bg-grey py-4">
            <Container>
              <Row>
                <Col xs={12} lg={2} md={12}>
                  {/* <NewsSidebar /> */}
                </Col>
                <Col xs={12} lg={7} md={12}>
                  <div className="articals_div">
                    <div className="single_artical_card leave-ques-card p-3">
                      <img
                        src={process.env.REACT_APP_API_URL + detail?.image}
                        alt="user_img"
                      // className="me-3"
                      />

                      <div className="d-flex align-items-center mb-3">
                        <div className="media_img"></div>
                        <div className="media-body">
                          <h4>
                            <Link className="text-orange" to="#"></Link>
                            {detail?.title}
                          </h4>

                          <span dangerouslySetInnerHTML={{ __html: detail?.descp }} />
                          <p></p>
                        </div>
                      </div>
                      <div>
                        <hr className="seprator"></hr>
                        <Dropdown>
                          <Dropdown.Toggle className="pe-3" id="dropdown-basic">
                            <i className="fa fa-share"></i> Share
                          </Dropdown.Toggle>
                          <Dropdown.Menu className="p-2">
                            <Dropdown.Item
                              as={Link}
                              target="_blank"
                              to={`https://www.facebook.com/sharer/sharer.php?u=${process.env.REACT_APP_DOMAIN + 'news/show' + '/' + detail.id
                                }`}
                            >
                              Facebook
                            </Dropdown.Item>
                            <Dropdown.Item
                              as={Link}
                              target="_blank"
                              to={`https://twitter.com/intent/tweet?url=${process.env.REACT_APP_DOMAIN + 'news/show' + '/' + detail.id
                                }`}
                            >
                              Twitter
                            </Dropdown.Item>
                            <Dropdown.Item
                              as={Link}
                              target="_blank"
                              to={`https://plus.google.com/share?url=${process.env.REACT_APP_DOMAIN + 'news/show' + '/' + detail.id
                                }`}
                            >
                              Google
                            </Dropdown.Item>
                            <Dropdown.Item
                              as={Link}
                              target="_blank"
                              to={`https://www.linkedin.com/shareArticle?url=${process.env.REACT_APP_DOMAIN + 'news/show' + '/' + detail.id
                                }&mini=true&title=${detail?.title}&source=AlmaBay`}
                            >
                              Linkedin
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </div>
                    <h5 className="mb-4">Comments</h5>

                    <div className="leave-cmmnt-card-outr">
                      <div className="leave-cmmnt-lst">
                        {commentList && commentList
                          ? commentList?.map((data, index) => {
                            return (
                              <div className="leave-cmmnt-card bg-white d-flex align-items-top p-3 mb-2" key={index}>
                                <Link
                                  className="close-btn"
                                  onClick={(e) => deleteComment(e, data?.id)}
                                >
                                  <i className="fas fa-times"></i>
                                </Link>

                                <div className="leave-cmmnt-icn">
                                  <img
                                    src={require('../../../assets/images/default-male-avatar.png')}
                                    alt="user_img"
                                  />
                                </div>

                                <div className="leave-cmmnt-media w-100 ">
                                  <h4>
                                    <Link>{data?.name}</Link>
                                    <span>{moment(data?.added_on).fromNow()}</span>
                                  </h4>
                                  <span className="noborder-left">{data?.QComment} </span>
                                </div>
                              </div>

                              // <div
                              //   className="mt-2  leave-cmmnt-media d-flex justify-content-between"
                              //   key={index}>
                              //   <div>
                              //     <p>{data?.name}</p>
                              //     <span> {data?.QComment} </span>
                              //   </div>
                              //   <Link onClick={(e) => deleteComment(e, data?.id)}>
                              //     <i className="fas fa-times"></i>
                              //   </Link>
                              // </div>
                            );
                          })
                          : ''}
                      </div>
                    </div>

                    <div className="leave-cmmnt-frm bg-white p-3">
                      <h4 className="mb-4"> Leave a Comment </h4>
                      <Form.Group className="mb-3" controlId="formBasicDegree">
                        <Form.Control
                          as="textarea"
                          type="text"
                          placeholder="write a comment"
                          style={{ height: '90px' }}
                          onChange={(e) => setComment(e.target.value)}
                          value={comment}
                        />
                      </Form.Group>

                      <div className="pt-2 attach-card-inr ">
                        <div className="cmmnt-btn mt-2 pb-2">
                          <button className="accnt-btn" onClick={saveHandler}>
                            Comment
                          </button>
                          <span className="text-danger"> {error} </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col lg={3} md={12}>
                  <MiniFooter />
                </Col>
              </Row>
            </Container>
          </section>
        </>
      )}

      {/* loader */}
      {loading ? <Loader /> : ''}
    </>
  );
}

export default NewsView;
