import { useState, useEffect, useRef } from 'react';
import { Col, Container, Dropdown, Modal, OverlayTrigger, Popover, Row } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import HeaderLogin from '../../commoncomponents/HeaderLogin';
import TopTabbings from './TopTabbings';
import * as APISERVICES from '../../../services/MentorArticlesServices';
import SuggestMentor from '../../commoncomponents/newsfeeds/SuggestMentor';
import GroupDiscover from '../../commoncomponents/newsfeeds/GroupDiscover';
import WhatsNew from '../../commoncomponents/newsfeeds/WhatsNew';
import Upcoming from '../../commoncomponents/newsfeeds/Upcoming';
import NewsSidebar from '../../commoncomponents/newsfeeds/NewsSidebar';
import { truncate } from '../../../globals/helper';
import showNotification from '../../../services/NotificationService';
import MemberShipPOPUP from '../../commoncomponents/MemberShipPOPup/MemberShipPOPUP';
import useDocumentTitle from '../../../globals/useDocumentTitle';
import SocialShare from './SocialShare';
import moment from 'moment';
import { MentorshipSkelton } from '../../commoncomponents/skelton/allskelton';
import QuestionSideBar from './QuestionSidebar';
import { getMentorshipProfileQuestions } from '../../../services/ProfileServices';
import { useSelector } from 'react-redux';
import HeaderInner from '../../commoncomponents/HeaderInner';
import { MiniFooter } from '../../MiniFooter';

function MentorShip() {
  useDocumentTitle('AlmaBay');
  const [list, setList] = useState([]);
  const [showMendorShipCard, setShowMendorShipCard] = useState(true);
  const [loading, setLoading] = useState(false);
  const [pageNum, setPageNum] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [shareModelShow, setShareModelShow] = useState(false);
  const { mentorId } = useParams();
  const isloggedIn = useSelector((state) => state?.user?.isloggedIn);
  const [mentorInfo, setMentorInfo] = useState('');

  useEffect(() => {
    if (pageNum <= totalPages) {
      getArticleList();
    }
  }, [pageNum]);

  useEffect(() => {
    if (mentorId) {
      isMentorshipProfile();
    }
  }, []);

  const shareModelClose = () => {
    setShareModelShow(false);
  };

  const getArticleList = (isTrue) => {
    setLoading(true);
    APISERVICES.articleList(pageNum).then((res) => {
      setLoading(false);
      if (res?.status === 200) {
        if (isTrue) {
          let arra1 = list?.map((item) => {
            const item2 = res?.data?.detail?.find((i2) => i2.id == item.id);
            return item ? { ...item, ...item2 } : item2;
          });
          setList(arra1);
        } else {
          setList([...list, ...res?.data?.detail]);
        }
        setTotalPages(res?.data?.count && Math.ceil(res?.data?.count / 20));
      }
    });
  };

  const HelpFullHandler = (e, id) => {
    e.preventDefault();
    if (isloggedIn) {
      let body = {
        q_id: id,
        answer_id: '0',
        type: 'like'
      };
      let formData = new FormData();

      for (let i in body) {
        formData.append(`${i}`, body[i]);
      }
      APISERVICES.articleHelpful(formData).then((res) => {
        if (res?.status === 200) {
          showNotification('success', 'Marked as helpful, Success!');
          getArticleList(true);
        }
      });
    }
  };

  const handleClosePopUp = (e) => {
    e.preventDefault();
    setShowMendorShipCard(false);
  };

  const loadMore = (e) => {
    e.preventDefault();
    setPageNum((no) => no + 1);
  };
  const [lastElement, setLastElement] = useState(null);
  const [loadMoreButtonVisible, setLoadMoreButtonVisible] = useState(false);
  const observer = useRef(
    new IntersectionObserver((entries) => {
      const first = entries[0];
      if (first.isIntersecting) {
        // make loadmore button visible
        setLoadMoreButtonVisible(true);
      }
    })
  );

  useEffect(() => {
    const currentElement = lastElement;
    const currentObserver = observer.current;
    if (currentElement) {
      currentObserver.observe(currentElement);
    }

    return () => {
      if (currentElement) {
        currentObserver.unobserve(currentElement);
      }
    };
  }, [lastElement]);

  const isMentorshipProfile = () => {
    const body = {
      uid: mentorId,
      content_type: 'articles'
    };
    getMentorshipProfileQuestions(body).then((res) => {
      if (res?.status === 200) {
        setList(res?.data?.detail);
        setMentorInfo(res?.data?.mentorInfo);
      }
    });
  };
  const renderPopover = (props) => {
    let popOverData = props?.popper?.state?.options?.data?.asked_by
      ? props?.popper?.state?.options?.data?.asked_by
      : props?.popper?.state?.options?.data?.asked_by;
    return (
      <Popover className="group-loader-card" id={`popover-basic`} {...props}>
        {/* <Popover.Header as="h3">Popover right</Popover.Header> */}
        <Popover.Body>
          <div className="loader-tooltip">
            <div className="loader-cover">
              <img
                src={
                  popOverData?.actual_cover_url
                    ? popOverData?.actual_cover_url
                    : popOverData?.actual_cover_url
                }
                alt="img"
                className="loader-image"
              />
            </div>
            <div className="people-know-loader-dis d-flex align-items-top">
              <div className="people-knw-top d-flex align-items-center">
                <div className="people-icn me-3">
                  <img
                    src={popOverData?.avatar_url ? popOverData?.avatar_url : ''}
                    alt="img"
                    className="loader-image"
                  />
                </div>
                <div className="people-hd">
                  <h3>{popOverData?.name}</h3>
                </div>
              </div>
            </div>
            <div className="people-knw-dis-popover">
              {/* need to fetch data from backend */}
              {/* <p>
                <i className="fas fa-graduation-cap me-1"></i> Studied at{' '}
                <Link to="#">SD COLLEGE Chandigarh</Link> in <Link to="#">2005</Link>
              </p> */}
              <p>
                <i className="fas fa-briefcase me-1"></i>Worked as
                <Link to="#">{/* need to fetch data from backend */}</Link>
              </p>
            </div>
          </div>
        </Popover.Body>
      </Popover>
    );
  };

  return (
    <>
      {!isloggedIn ? <HeaderInner /> : <HeaderLogin />}
      {isloggedIn ? (
        <div className="header-lnk-feeds">
          <div className="container">
            <TopTabbings state={'mentor'} />
          </div>
        </div>
      ) : (
        ''
      )}

      <section className="news-feed-sec bg-grey py-4">
        <Container>
          <Row>
            <Col xs={12} md={mentorId ? 3 : 2}>
              {mentorId ? <QuestionSideBar /> : <NewsSidebar state="2" current="feed" />}
            </Col>
            <Col xs={12} md={mentorId ? 6 : isloggedIn ? 7 : 10}>
              {isloggedIn &&
                (showMendorShipCard ? (
                  <MemberShipPOPUP handleClosePopUp={handleClosePopUp} />
                ) : (
                  <></>
                ))}
              <div className="articals_div">
                {/* Simmer loader */}
                {loading ? (
                  <>
                    <MentorshipSkelton />
                    <MentorshipSkelton />
                    <MentorshipSkelton />
                  </>
                ) : list && list.length > 0 ? (
                  list?.map((data, index) => (
                    <div
                      className="single_artical_card qafeeds p-3"
                      key={index}
                      ref={setLastElement}>
                      <div className="d-flex align-items-center mb-3">
                        <Link
                          // to={`/user/timeline/${data?.asked_by?.encrypted_id}`}
                          className="media_img">
                          <OverlayTrigger
                            trigger={['hover', 'focus']}
                            placement="bottom"
                            delay={{ show: 500, hide: 1500 }}
                            data={data}
                            popperConfig={{
                              data
                            }}
                            shouldUpdatePosition={true}
                            overlay={renderPopover}>
                            <img
                              src={
                                data?.asked_by?.avatar?.complete_url
                                  ? data?.asked_by?.avatar?.complete_url
                                  : data?.asked_by?.avatar_url
                              }
                              alt="user_img"
                              className="me-3"
                            />
                          </OverlayTrigger>
                        </Link>
                        <div className="media-body">
                          <h4>
                            <Link
                              to={`/user/timeline/${data?.asked_by?.encrypted_id}`}
                              className="user-link">
                              {data?.asked_by?.name}
                            </Link>
                            <span>
                              {data?.content_type === 'question'
                                ? 'asked a question'
                                : 'shared an article'}
                            </span>
                          </h4>
                          <p title={data?.added_on}>{moment(data?.added_on).fromNow()}</p>
                        </div>
                      </div>
                      <div>
                        <h4 className="title">
                          {isloggedIn ? (
                            <Link
                              to={`${
                                data?.content_type === 'question'
                                  ? `/user/viewanswer/${data?.id}`
                                  : `/user/mentor-article/${data?.id}`
                              }`}>
                              {data?.title}
                            </Link>
                          ) : (
                            <Link
                              to={`${
                                data?.content_type === 'question'
                                  ? `/user/articleComment/${data?.id}`
                                  : `/user/mentor-article/${data?.id}`
                              }`}>
                              {data?.title}
                            </Link>
                          )}
                        </h4>
                        <span
                          dangerouslySetInnerHTML={{ __html: truncate(data?.description, 500) }}
                        />
                        {/* <p className="description">{truncate(data?.description, 500)}</p> */}
                      </div>
                      <div>
                        <Link
                          className="read-more"
                          to={`${
                            data?.content_type === 'question'
                              ? `/user/viewanswer/${data?.id}`
                              : `/user/mentor-article/${data?.id}`
                          }`}>
                          Read more...
                        </Link>
                      </div>
                      <div className="navigation-border">
                        <ul className="article-cmmnt-lnks d-flex ps-2">
                          <li className="active-helpful pe-3">
                            {data?.liked_by_current_user ? (
                              <>
                                <i className="fa fa-heart "></i> Helpful{' '}
                                <span>
                                  ({data?.likes}) {'  '}
                                </span>
                              </>
                            ) : (
                              <Link
                                to="#"
                                className="pe-0"
                                onClick={(e) => {
                                  HelpFullHandler(e, data?.id);
                                }}>
                                <i className="fa fa-heart "></i> Helpful{' '}
                                <span>
                                  ({data?.likes}) {'  '}
                                </span>
                              </Link>
                            )}
                          </li>
                          <li>
                            <Link
                              to={`${
                                data?.content_type === 'question'
                                  ? `/user/viewanswer/${data?.id}`
                                  : `/user/mentor-article/${data?.id}`
                              }`}
                              className="pe-3">
                              <i className="fa fa-comment active-helpful "></i> {'  '}{' '}
                              {data?.content_type === 'question' ? 'Answers' : 'Comments'}{' '}
                              <span>
                                (
                                {data?.content_type === 'question' ? data?.answers : data?.comments}
                                )
                              </span>
                            </Link>
                          </li>
                          <li className="share-drop-btn">
                            <Dropdown>
                              <Dropdown.Toggle className="pe-3" id="dropdown-basic">
                                <Link>
                                  <i className="fa fa-share"></i> Share
                                </Link>
                              </Dropdown.Toggle>

                              <Dropdown.Menu className="p-2">
                                <Dropdown.Item
                                  as={Link}
                                  target="_blank"
                                  to={`https://www.facebook.com/sharer/sharer.php?u=${
                                    process.env.REACT_APP_DOMAIN +
                                    '/mentors/qafeeds' +
                                    '/' +
                                    data?.id
                                  }`}>
                                  Facebook
                                </Dropdown.Item>
                                <Dropdown.Item
                                  as={Link}
                                  target="_blank"
                                  to={`https://twitter.com/intent/tweet?url=${
                                    process.env.REACT_APP_DOMAIN +
                                    'mentors/qafeeds' +
                                    '/' +
                                    data?.id
                                  }`}>
                                  Twitter
                                </Dropdown.Item>
                                <Dropdown.Item
                                  as={Link}
                                  target="_blank"
                                  to={`https://plus.google.com/share?url=${
                                    process.env.REACT_APP_DOMAIN +
                                    'mentors/qafeeds' +
                                    '/' +
                                    data?.id
                                  }`}>
                                  Google
                                </Dropdown.Item>
                                <Dropdown.Item
                                  as={Link}
                                  target="_blank"
                                  to={`https://www.linkedin.com/shareArticle?url=${
                                    process.env.REACT_APP_DOMAIN +
                                    'mentors/qafeeds' +
                                    '/' +
                                    data?.id
                                  }&mini=true&title=${data?.title}&source=AlmaBay`}>
                                  Linkedin
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </li>
                        </ul>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="p-5 bg-white text-center">
                    <img
                      src={require('../../../assets/images/small-error.png')}
                      width="105px"
                      alt="img"
                    />
                    <p>We do not have feed suggestions for you at the moment.</p>
                  </div>
                )}
                {
                  // show this button only when last div is displayed and length is > 10
                }

                {
                  pageNum + 1 < totalPages && (
                    // list.length > 10 && (
                    <div className="load-more-btn py-3 text-center">
                      <Link to="#" className="read-more" onClick={(e) => loadMore(e)}>
                        Load More
                      </Link>
                    </div>
                  )
                  // )
                }
              </div>
            </Col>
            {isloggedIn && (
              <Col lg={3}>
                <div>
                  <SuggestMentor />
                </div>
                <div>
                  <GroupDiscover />
                </div>
                <div>
                  <WhatsNew />
                </div>
                <div>
                  <Upcoming />
                </div>
                <MiniFooter />
              </Col>
            )}
          </Row>
        </Container>
      </section>
    </>
  );
}

export default MentorShip;
