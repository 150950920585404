import moment from 'moment';
import { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { getClubGallery } from '../../../services/InstituteServices';

function AllAlbums() {
  const [list, setList] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    getClubGallery('album').then((res) => {
      if (res?.status === 200) {
        setList(res?.data?.data || []);
      }
    });
  };

  return (
    <>
      <Row>
        {list && list?.length ? (
          list?.map((data, index) => (
            <Col sm={6} md={4} key={index}>
              <Link
                to={`/club/gallery-view/${data?.media_id}`}
                className="h-100 single-img-content text-center album-link"
              >
                <img
                  src={
                    data?.album_list[0]?.url
                      ? data?.album_list[0]?.url
                      : require('../../../assets/images/imageicon.png')
                  }
                  alt="img"
                  width="100%"
                  className="mb-3"
                />
                <p className="mb-1">{data?.title}</p>
                <p className="text-muted mb-4">
                  Photos: <span className="text-black me-3">{data?.album_list?.length}</span> &nbsp;
                  Date:{' '}
                  <span className="text-black">{moment(data?.created).format('DD MMM YYYY')}</span>{' '}
                </p>
              </Link>
            </Col>
          ))
        ) : (
          <Col sm={12} md={4} lg={3}>
            <div className="text-center nodata">
              <img src={require('../../../assets/images/nodata.png')} alt="" />
              <p>Data Not Found</p>
            </div>
          </Col>
        )}
      </Row>
    </>
  );
}

export default AllAlbums;
