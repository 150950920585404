import React from 'react';
import showNotification from '../../../../services/NotificationService';
import { Link, useNavigate } from 'react-router-dom';
import { restrictAlpha } from '../../../../globals/helper';
import * as constants from '../../../../globals/constants';
import Tab from 'react-bootstrap/Tab';
import Table from 'react-bootstrap/Table';
import NoDataFound from '../../../commoncomponents/NoDataFound';
import { Form } from 'react-bootstrap';
import { getEventBookingTicket } from '../../../../services/SocialServices';
export default function EventTickets({ handleView, bookEventTicketList, quantityUpdate,setQuantityUpdate,userId }) {
  let navigate = useNavigate();

  const handleChangeQuantity = (e, index) => {
    setQuantityUpdate(
      quantityUpdate.map((item, ind) => {
        if (ind == index) {
          return {
            ...item,
            quantity: e.target.value
          };
        }
        return item;
      })
    );
  };

  function increment(index) {
    setQuantityUpdate(
      quantityUpdate.map((item, ind) => {
        if (ind == index) {
          return {
            ...item,
            quantity: item.quantity + 1
          };
        }
        return item;
      })
    );
  }

  function decrement(index) {
    setQuantityUpdate(
      quantityUpdate.map((item, ind) => {
        if (ind == index) {
          return {
            ...item,
            quantity: item.quantity == 0 ? 0 : item.quantity - 1
          };
        }
        return item;
      })
    );
  }

  /**Proceed to pay function */
  const proceedToPayDetail = (e) => {
    e.preventDefault();
    let body = {
      tickets: JSON.stringify(
        quantityUpdate.map((book) => {
          return {
            ticket_id: book.id,
            quantity: book.quantity
          };
        })
      )
    };
    getEventBookingTicket(userId, body).then((resp) => {
      if (resp?.status === 200) {
        navigate(`/user/event/proceed_to_pay/${resp?.data?.order_id}`);
        showNotification('success', resp?.data?.detail);
      } else {
        showNotification('err', resp?.data?.detail);
      }
    });
  };

  return (
    <Tab.Pane eventKey="five">
      <div className="book-event-ticket bg-white">
        <div className="add-head-car border-bottom d-flex align-items-center justify-content-between px-3 py-3">
          <h4 className="add-event-hd">Book Event Tickets </h4>
          <Link onClick={(e) => handleView(e)}>
            <i className="fas fa-search-plus ms-1"></i>
            View Details
          </Link>
        </div>
        <div className="eventticket-card table-responsive p-3">
          <Table striped>
            <thead>
              <tr>
                <th>Ticket Name</th>
                <th>Remaining</th>
                <th>Type</th>
                <th>Limit</th>
                <th>Price</th>
                <th>Quantity</th>
              </tr>
            </thead>
            <tbody>
              {bookEventTicketList?.length !== constants.ZERO ? (
                bookEventTicketList?.map((data, index) => {
                  return (
                    <tr key={index}>
                      <td>{data?.ticket_name}</td>
                      <td>
                        {data?.avaliable_stock ? data?.avaliable_stock + ' ' + 'Tickets' : ''}
                      </td>
                      <td>{data?.ticket_type}</td>
                      <td>{data?.limit ? data?.limit + ' ' + 'Tickets' : ''}</td>
                      <td>
                        {data?.price ? <i className="fas fa-rupee-sign me-1">{data?.price}</i> : ''}
                      </td>
                      <td>
                        <div className="counter-card d-flex align-items-center">
                          <button className="counter-btn" onClick={() => decrement(index)}>
                            <i className="fas fa-minus"></i>
                          </button>

                          <Form.Control
                            type="text"
                            placeholder="1"
                            onKeyPress={(e) => restrictAlpha(e)}
                            name="quantity"
                            value={quantityUpdate[index].quantity}
                            defaultValue={quantityUpdate[index].quantity}
                            maxlength="4"
                            onChange={(e) => {
                              handleChangeQuantity(e, index);
                            }}
                          />

                          <button className="counter-btn" onClick={() => increment(index)}>
                            <i className="fas fa-plus"></i>
                          </button>
                        </div>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={8}>
                    <NoDataFound text="No Event Tickets Found" />
                  </td>
                </tr>
              )}
            </tbody>
          </Table>

          {bookEventTicketList?.length !== constants.ZERO ? (
            <div className="d-flex align-items-center justify-content-end p-2">
              <Link to="#" className="proceed-btn" onClick={(e) => proceedToPayDetail(e)}>
                <i className="fas fa-check me-2"></i>
                Proceed to Pay
              </Link>
            </div>
          ) : (
            ''
          )}
        </div>
      </div>
    </Tab.Pane>
  );
}
