import { Link, useNavigate } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import { Container } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import FooterGrey from '../../commoncomponents/FooterGrey';
import HeaderShop from '../../commoncomponents/HeaderShop';
import CheckoutPageEmailStep from '../store-page/CheckoutPageEmailStep';
import RegisterCheckoutPage from '../store-page/RegisterCheckoutPage';
import AddressDetailsCheckout from '../store-page/AddressDetailsCheckout';
import ReviewOrderCheckout from '../store-page/ReviewOrderCheckout';
import { useSelector } from 'react-redux';
import PaymentStore from '../store-page/PaymentStore';
import useDocumentTitle from '../../../globals/useDocumentTitle';
import ThankyouPage from '../store-page/ThankyouPage';
import {
  deleteCartProducts,
  saveOrderStore,
  viewCartProductsCheckout
} from '../../../services/ShopServices';
import showNotification from '../../../services/NotificationService';
import NewsLetter from '../store-page/NewsLetter';
function CheckoutPage() {
  useDocumentTitle('Checkout | AlmaBay');

  const [step, setstep] = useState(1);

  const [disabledStep, setDisabledStep] = useState(false);
  const nextStep = () => {
    setstep(step + 1);
  };
  const nextSteps = () => {
    setstep(3);
  };
  const isloggedIn = useSelector((state) => state?.user?.isloggedIn);
  const [loginMark, setLoginMark] = useState(isloggedIn ? true : false);
  const [addressMark, setAddressMark] = useState(false);
  const [reviewOrder, setReviewOrder] = useState(false);
  const [paymentMark, setPaymentMark] = useState(false);
  const [active, setActive] = useState(isloggedIn ? 'second' : 'first');
  const [quantity, setQuantity] = useState();

  const prevStep = () => {
    setstep(step - 1);
  };

  const activeTab = (state) => {
    setActive(state);
  };

  const [show, setShow] = useState(false);
  const handleShow = () => {
    setShow(true);
  };
  const handleClose = () => {
    setShow(false);
  };
  const [formData, setFormData] = useState({
    email: '',
    first_name: '',
    last_name: '',
    password: ''
  });

  const handleInputData = (input) => (e) => {
    const { value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [input]: value
    }));
  };

  const [paymentMode, setPaymentMode] = useState(1);
  const [loading, setLoading] = useState(false);
  const [totalPrice, setTotalPrice] = useState();
  const [paymentModeValue, setPaymentModeValue] = useState(null);
  const [cartProductsState, setCartProductsState] = useState();
  const [subTotal, setSubTotal] = useState();
  const [shippingAddress, setShippingAddress] = useState();
  const [stateCart, setStateCart] = useState('');
  useEffect(() => {
    if (isloggedIn) {
      cartProductsReview();
    }
  }, [isloggedIn]);

  let navigate = useNavigate();
  const cartProductsReview = () => {
    setLoading(true);
    let body = {
      temp_user: localStorage.getItem('session_id_cart')
    };
    viewCartProductsCheckout(body).then((resp) => {
      setLoading(false);
      if (resp?.status == 200) {
        setLoading(false);
        setQuantity(
          resp?.data?.list?.cartInfo.map((item) => {
            return Number(item.quantity);
          })
        );
        setCartProductsState(resp?.data?.list?.cartInfo);
        setShippingAddress(resp?.data?.list?.shipping_cost);
        setSubTotal(resp?.data?.list?.subTotal);
        setTotalPrice(resp?.data?.list?.total);
        if (resp?.data?.message == 'No Item Found in Cart') {
          navigate(`/shop/catalog`);
        }
      } else {
        setNotification(resp?.data?.message);
      }
    });
  };

  const saveOrderFunction = (e) => {
    setLoading(true);
    e.preventDefault();

    let body = {
      cod_payment: paymentMode
    };
    saveOrderStore(body).then((res) => {
      setLoading(false);
      if (res?.status === 200) {
        setLoading(false);
        setPaymentMark(true);
        showNotification(res?.data?.message);
        setPaymentModeValue(res?.data?.list?.orderInfo[0]?.payment_method);
        setStateCart(res?.data?.message);
        if (paymentModeValue == 1) {
          nextStep();
          activeTab('fourth');
        }
      }
    });
  };
  
  const [state, setState] = useState('');
  const [notification, setNotification] = useState(false);
  const deleteProducts = (e, id) => {
    e.preventDefault();
    deleteCartProducts(id).then((resp) => {
      if (resp?.status == 200) {
        cartProductsReview();
        setState(resp?.data?.message);
        setNotification(resp?.data?.message);
      }
    });
  };

  const switchMultiStep = () => {
    switch (step) {
      case 1:
        return (
          <CheckoutPageEmailStep
            nextStep={nextStep}
            handleFormData={handleInputData}
            formData={formData}
            nextSteps={nextSteps}
            activeTab={activeTab}
            setDisabledStep={setDisabledStep}
            setLoginMark={setLoginMark}
          />
        );

      case 2:
        return (
          <RegisterCheckoutPage
            nextStep={nextStep}
            prevStep={prevStep}
            handleFormData={handleInputData}
            formData={formData}
            setDisabledStep={setDisabledStep}
            setLoginMark={setLoginMark}
          />
        );
      case 3:
        return (
          <AddressDetailsCheckout
            nextStep={nextStep}
            activeTab={activeTab}
            formData={formData}
            setDisabledStep={setDisabledStep}
            setLoginMark={setLoginMark}
            setAddressMark={setAddressMark}
          />
        );
      case 4:
        return (
          <ReviewOrderCheckout
            nextStep={nextStep}
            activeTab={activeTab}
            setReviewOrder={setReviewOrder}
            deleteProducts={deleteProducts}
            notification={notification}
            setNotification={setNotification}
            cartProductsReview={cartProductsReview}
            cartProductsState={cartProductsState}
            shippingAddress={shippingAddress}
            subTotal={subTotal}
            totalPrice={totalPrice}
            loading={loading}
            setLoading={setLoading}
            setQuantity={setQuantity}
            quantity={quantity}
          />
        );

      case 5:
        return (
          <PaymentStore
            setPaymentMode={setPaymentMode}
            loading={loading}
            totalPrice={totalPrice}
            cartProductsReview={cartProductsReview}
            saveOrderFunction={saveOrderFunction}
          />
        );
      case 6:
        return <ThankyouPage nextStep={nextStep} />;

      default:
        return (
          <CheckoutPageEmailStep
            nextStep={nextStep}
            prevStep={prevStep}
            handleFormData={handleInputData}
            formData={formData}
          />
        );
    }
  };

  useEffect(() => {
    if (active === 'first') {
      <CheckoutPageEmailStep
        nextStep={nextStep}
        handleFormData={handleInputData}
        formData={formData}
        nextSteps={nextSteps}
        activeTab={activeTab}
        setDisabledStep={setDisabledStep}
        setLoginMark={setLoginMark}
      />;
    } else if (active === 'first') {
      <RegisterCheckoutPage
        nextStep={nextStep}
        prevStep={prevStep}
        handleFormData={handleInputData}
        formData={formData}
        setLoginMark={setLoginMark}
      />;
    } else if (active === 'second') {
      <AddressDetailsCheckout
        nextStep={nextStep}
        activeTab={activeTab}
        formData={formData}
        setDisabledStep={setDisabledStep}
        setAddressMark={setAddressMark}
      />;
    } else if (active === 'third') {
      <ReviewOrderCheckout
        nextStep={nextStep}
        activeTab={activeTab}
        setReviewOrder={setReviewOrder}
        deleteProducts={deleteProducts}
        notification={notification}
        setNotification={setNotification}
        cartProductsReview={cartProductsReview}
        cartProductsState={cartProductsState}
        shippingAddress={shippingAddress}
        subTotal={subTotal}
        totalPrice={totalPrice}
        loading={loading}
        setLoading={setLoading}
        setQuantity={setQuantity}
        quantity={quantity}
      />;
    } else if (active === 'fourth') {
      <PaymentStore
        setPaymentMode={setPaymentMode}
        loading={loading}
        totalPrice={totalPrice}
        cartProductsReview={cartProductsReview}
        saveOrderFunction={saveOrderFunction}
      />;
    } else if (active === 'fourth') {
      <ThankyouPage nextStep={nextStep} />;
    }
  }, []);
  return (
    <>
      <HeaderShop state={state} deleteProducts={deleteProducts} stateCart={stateCart} />
      <section className="checkout-page py-5">
        <Container>
          <div className="checkout-inr-page">
            <Tab.Container id="left-tabs-example" activeKey={show == true ? '' : active}>
              <Row>
                <Col xs={12} sm={12} md={12} lg={3}>
                  <Nav variant="pills" className="flex-column">
                    <Nav.Item>
                      <Nav.Link
                        disabled
                        eventKey="first"
                        onClick={(e) => {
                          e.preventDefault();
                          switchMultiStep(1);
                          handleClose();
                          activeTab('first');
                        }}
                      >
                        {loginMark ? (
                          <>
                            <span className="num-circle">
                              <i className="fa fa-check"></i>
                            </span>
                            LOG IN / REGISTER
                          </>
                        ) : (
                          <>
                            <span className="num-circle">1</span>LOG IN / REGISTER{' '}
                          </>
                        )}
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        disabled
                        eventKey="second"
                        onClick={(e) => {
                          e.preventDefault();
                          switchMultiStep(3);
                          handleClose();
                          activeTab('second');
                        }}
                      >
                        {addressMark ? (
                          <>
                            <span className="num-circle">
                              <i className="fa fa-check"></i>
                            </span>{' '}
                            Confirm Address{' '}
                          </>
                        ) : (
                          <>
                            <span className="num-circle">2</span>Confirm Address
                          </>
                        )}
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        disabled
                        eventKey="third"
                        onClick={(e) => {
                          e.preventDefault();
                          switchMultiStep(4);
                          handleClose();
                          activeTab('third');
                        }}
                      >
                        {reviewOrder ? (
                          <>
                            <span className="num-circle">
                              <i className="fa fa-check"></i>
                            </span>{' '}
                            Review Order{' '}
                          </>
                        ) : (
                          <>
                            <span className="num-circle">3</span>Review Order
                          </>
                        )}
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        disabled
                        eventKey="fourth"
                        onClick={(e) => {
                          e.preventDefault();
                          switchMultiStep(4);
                          handleClose();
                          activeTab('fourth');
                        }}
                      >
                        {paymentMark ? (
                          <>
                            <span className="num-circle">
                              <i className="fa fa-check"></i>
                            </span>{' '}
                            Make Payment{' '}
                          </>
                        ) : (
                          <>
                            <span className="num-circle">4</span>Make Payment
                          </>
                        )}
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                </Col>
                <Col xs={12} sm={12} md={12} lg={9}>
                  <Tab.Content>
                    <Tab.Pane eventKey="first">
                      <Row className="login_register_checkout align-items-center ">
                        <Col md={5}>
                          <div className="login-left mx-3">
                            {step == 1 ? (
                              <CheckoutPageEmailStep
                                nextStep={nextStep}
                                handleFormData={handleInputData}
                                formData={formData}
                                step={step}
                                nextSteps={nextSteps}
                                activeTab={activeTab}
                                setDisabledStep={setDisabledStep}
                                setLoginMark={setLoginMark}
                              />
                            ) : step == 2 ? (
                              <RegisterCheckoutPage
                                nextStep={nextStep}
                                handleFormData={handleInputData}
                                formData={formData}
                                step={step}
                                prevStep={prevStep}
                                activeTab={activeTab}
                                setDisabledStep={setDisabledStep}
                                setLoginMark={setLoginMark}
                              />
                            ) : (
                              ''
                            )}
                          </div>
                        </Col>
                        <Col md={3}>
                          <div className="diveider mx-5 d-none d-md-block text-center">
                            <img
                              src={require('../../../assets/images/diveider.png')}
                              alt="divider"
                            />
                          </div>
                        </Col>
                        <Col md={4}>
                          <div className="login-right mx-3 text-center">
                            <h5>Login using</h5>
                            <div className="btn-group">
                              <Link className="btn login-using-btn almabay-btn" to="/login">
                                <img
                                  src="https://www.almabay.com/common/favicon/favicon-16x16.png"
                                  className="me-2"
                                  alt="img"
                                />
                                AlmaBay
                              </Link>
                            </div>
                            <div className="btn-group">
                              <Link className="btn login-using-btn facebook-btn">
                                <i className="fab fa-facebook-f me-2"></i>
                                Facebook
                              </Link>
                            </div>
                            <div className="btn-group">
                              <Link className="btn login-using-btn google-plus-btn">
                                <i className="fab fa-google-plus-g me-2"></i>
                                Google
                              </Link>
                            </div>
                            <div className="btn-group">
                              <Link className="btn login-using-btn linkdin-btn">
                                <i className="fab fa-linkedin-in me-2"></i>
                                LinkedIn
                              </Link>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </Tab.Pane>

                    {isloggedIn || disabledStep == true ? (
                      <Tab.Pane eventKey="second">
                        {step == 3 ? (
                          <AddressDetailsCheckout
                            nextStep={nextStep}
                            activeTab={activeTab}
                            setDisabledStep={setDisabledStep}
                            formData={formData}
                            setLoginMark={setLoginMark}
                            setAddressMark={setAddressMark}
                          />
                        ) : (
                          <AddressDetailsCheckout
                            nextStep={nextStep}
                            activeTab={activeTab}
                            setDisabledStep={setDisabledStep}
                            formData={formData}
                            setLoginMark={setLoginMark}
                            setAddressMark={setAddressMark}
                          />
                        )}
                      </Tab.Pane>
                    ) : (
                      ''
                    )}

                    <Tab.Pane eventKey="third">
                      <ReviewOrderCheckout
                        nextStep={nextStep}
                        activeTab={activeTab}
                        setReviewOrder={setReviewOrder}
                        deleteProducts={deleteProducts}
                        notification={notification}
                        setNotification={setNotification}
                        cartProductsReview={cartProductsReview}
                        cartProductsState={cartProductsState}
                        shippingAddress={shippingAddress}
                        subTotal={subTotal}
                        totalPrice={totalPrice}
                        loading={loading}
                        setLoading={setLoading}
                        setQuantity={setQuantity}
                        quantity={quantity}
                      />
                    </Tab.Pane>
                    {paymentModeValue == 1 ? (
                      <Tab.Pane eventKey="fourth">
                        <ThankyouPage />
                      </Tab.Pane>
                    ) : (
                      <Tab.Pane eventKey="fourth">
                        <PaymentStore
                          setPaymentMode={setPaymentMode}
                          loading={loading}
                          totalPrice={totalPrice}
                          cartProductsReview={cartProductsReview}
                          saveOrderFunction={saveOrderFunction}
                        />
                      </Tab.Pane>
                    )}
                  </Tab.Content>
                </Col>
              </Row>
            </Tab.Container>
          </div>
        </Container>
      </section>
      <NewsLetter />
      <FooterGrey />
    </>
  );
}

export default CheckoutPage;
