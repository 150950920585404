import { useEffect, useRef, useState } from 'react';
import { Col, Container, Form, Row, Table } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import useDebounce from '../../../globals/useDebounce';
import {
  getAllList,
  getAlumnisList,
  getFacultyList,
  getStudentList,
  sendCommunication,
  templateList
} from '../../../services/AuthServices';
import showNotification from '../../../services/NotificationService';
import sample from '../../../super-admin/ManageTemplate/TemplateConfig/template_2_config.json';
import EmailCommunication from './EmailCommunication';
import NotificationCommunication from './NotificationCommunication';
import SMSCommunication from './SMSCommunication';

const CommunicationPage = () => {
  const navigate = useNavigate();
  const emailEditorRef = useRef(null);
  const [communication, setCommunication] = useState('email');
  const [showSearch, setShowSearch] = useState(false);
  const [value, setValue] = useState('all');
  const [membersList, setMembersList] = useState([]);
  const [allId, setAllId] = useState([]);
  const [checkedId, setCheckedId] = useState([]);
  const [allName, setAllName] = useState([]);
  const [checkedName, setCheckedName] = useState([]);
  const [templates, setTemplates] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [htmlContent, setHtmlContent] = useState('');
  const [count, setCount] = useState(0);
  const [subject, setSubject] = useState('');
  const [content, setContent] = useState('');
  const [item, setItem] = useState('');
  const [search, setSearch] = useState('');
  const [communicationDetails, setCommunicatinDetails] = useState({
    token: '',
    subject: '',
    content: '',
    template_id: ''
  });
  const debouncedSearchTerm = useDebounce(search, 500);

  useEffect(() => {
    if (debouncedSearchTerm) {
      if (value == 'all') {
        allList();
      }
      if (value == 'students') {
        studentList();
      }
      if (value == 'alumni') {
        alumniList();
      }
      if (value == 'faculty') {
        facultyList();
      }
    } else {
      if (value == 'all') {
        allList();
      }
      if (value == 'students') {
        studentList();
      }
      if (value == 'alumni') {
        alumniList();
      }
      if (value == 'faculty') {
        facultyList();
      }
    }
  }, [value, debouncedSearchTerm]);

  useEffect(() => {
    getTemplateList();
  }, [communication]);

  useEffect(() => {
    if (communication === 'email' && content?.length > 0) {
      emailEditorRef.current?.editor?.exportHtml((data) => {
        setItem(data?.html);
      });
    }
  }, [selectedIndex, content, item]);

  /**
   * function to get template list
   */
  const getTemplateList = async () => {
    try {
      const response = await templateList(communication);
      if (response?.status === 200) {
        setTemplates(response.data?.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  /**
   * get student list
   */
  const studentList = async () => {
    try {
      const response = await getStudentList(search);
      if (response?.status === 200) {
        setMembersList(response.data?.data);
        setCount(response.data?.count);
        setAllId(response.data?.data?.map((item) => item?.user_id));
        setAllName(response.data?.data?.map((item) => item?.name));
      }
    } catch (error) {
      console.error(error);
    }
  };

  /**
   * get alumni list
   */
  const alumniList = async () => {
    try {
      const response = await getAlumnisList(search);
      if (response?.status === 200) {
        setMembersList(response.data?.data);
        setCount(response.data?.count);
        setAllId(response.data?.data?.map((item) => item?.user_id));
        setAllName(response.data?.data?.map((item) => item?.name));
      }
    } catch (error) {
      console.error(error);
    }
  };

  /**
   * get faculty list
   */
  const facultyList = async () => {
    try {
      const response = await getFacultyList(search);
      if (response?.status === 200) {
        setMembersList(response.data?.data);
        setCount(response.data?.count);
        setAllId(response.data?.data?.map((item) => item?.user_id));
        setAllName(response.data?.data?.map((item) => item?.name));
      }
    } catch (error) {
      console.error(error);
    }
  };

  /**
   * get all list
   */
  const allList = async () => {
    try {
      const response = await getAllList(search);
      if (response?.status === 200) {
        setMembersList(response.data?.data);
        setCount(response.data?.count);
        setAllId(response.data?.data?.map((item) => item?.user_id));
        setAllName(response.data?.data?.map((item) => item?.name));
      }
    } catch (error) {
      console.error(error);
    }
  };

  /**
   * function to select all checkbox
   * @param {*} e
   */
  const handleSelectAll = (e) => {
    if (e.target.checked === true) {
      allId.forEach((id) => {
        if (!checkedId.includes(id)) {
          setCheckedId((prev) => {
            return [...prev, id];
          });
        }
      });
      allName.forEach((name) => {
        if (!checkedName.includes(name)) {
          setCheckedName((prev) => {
            return [...prev, name];
          });
        }
      });
    } else {
      setCheckedId([
        ...checkedId.filter((id) => {
          return allId.indexOf(id) == -1;
        })
      ]);
      setCheckedName([
        ...checkedName.filter((id) => {
          return allName.indexOf(id) == -1;
        })
      ]);
    }
  };

  /**
   * function to select each checkbox individually
   * @param {*} e
   * @param {*} id
   */
  const handleMultipleCheckBox = (e, id) => {
    if (checkedId.includes(id)) {
      setCheckedId(
        checkedId.filter((data) => {
          return data !== id;
        })
      );
      setCheckedName(
        checkedName.filter((data) => {
          return data !== e?.target?.name;
        })
      );
    } else {
      setCheckedId([...checkedId, id]);
      setCheckedName([...checkedName, e?.target?.name]);
    }
  };

  /**
   * set dropdownvalue
   * @returns
   */
  const dropdownValue = () => {
    if (value === 'students') {
      return 'Students';
    } else if (value === 'faculty') {
      return 'Faculties';
    } else if (value === 'all') {
      return 'All';
    } else {
      return 'Alumnies';
    }
  };

  /**
   * function to call when email editor loads
   */
  const onLoad = () => {
    emailEditorRef?.current?.editor.addEventListener('design:loaded');
    emailEditorRef?.current?.editor.loadDesign(content ? JSON.parse(content) : sample);
  };

  /**
   * function to call after dropdown change
   * @param {*} e
   */
  const handleChange = (e) => {
    const { name, value } = e.target;
    setCommunicatinDetails({
      ...communicationDetails,
      [name]: value
    });
    setSubject('');
    setContent('');
  };

  /**
   * send message to all
   * @param {*} e
   */
  const communicate = async (e) => {
    e.preventDefault();
    // console.log('communication', communication === 'email', content?.length > 0);
    // emailEditorRef.current.editor.exportHtml((data) => {
    //   const { design, html } = data;
     
    // });
    // return;
    try {
      if (communication === 'email' && content?.length > 0) {
        emailEditorRef.current.editor.exportHtml( (data) => {
          let emailBody = {
            to_persons: checkedId?.toString(),
            template_id: communicationDetails?.status,
            type: communication,
            subject: subject,
            content: data?.html
          };
          const response =  sendCommunication(communication === 'email' && emailBody);
          if (response?.status === 200) {
            showNotification('success', response?.data?.message);
            navigate(`/admin/communication`);
          }
        });
      } else if (communication === 'email') {
        let emailBody = {
          to_persons: checkedId?.toString(),
          template_id: communicationDetails?.status,
          type: communication,
          subject: subject,
          content: htmlContent
        };
        const response = await sendCommunication(communication === 'email' && emailBody);
        if (response?.status === 200) {
          showNotification('success', response?.data?.message);
          navigate(`/admin/communication`);
        }
      } else {
        let otherBody = {
          to_persons: checkedId?.toString(),
          template_id: communicationDetails?.status,
          type: communication,
          subject: subject,
          content: content
        };
        const response = await sendCommunication(otherBody);
        if (response?.status === 200) {
          showNotification('success', response?.data?.message);
          navigate(`/admin/communication`);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <div className="dash-main-content communication_wrapper">
        <Container fluid>
          <div className="dash-page-content">
            <div className="dash-page-title">
              <Row>
                <Col xs={12} sm={12} md={12}>
                  <div className="dash-title-bar d-flex align-items-center justify-content-between">
                    <div className="title-lft d-flex align-items-center">
                      <div className="dash-icn">
                        <i className="fas fa-comments"></i>
                      </div>
                      <div className="dash-title-dis">
                        <h5>
                          Communicate with <b>{dropdownValue()}</b>
                        </h5>
                        <h5>{/* Total Record(s): <span>{planList?.data?.length}</span> */}</h5>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
          <div className="communication_box">
            <Row>
              <Col xl={4} lg={5} md={6} sm={12} xs={12}>
                <div className="communication_table me-2">
                  <Form>
                    <div className="mb-3 d-flex justify-content-between align-items-center">
                      <Form.Group controlId="formBasicPassword">
                        <Form.Select
                          onChange={(e) => {
                            setValue(e.target.value);
                          }}
                          aria-label="Default select example"
                          name="status">
                          <option value={'all'}>All {value === 'all' && ' - ' + count}</option>
                          <option value={'alumni'}>
                            Alumni {value === 'alumni' && ' - ' + count}
                          </option>
                          <option value={'faculty'}>
                            Faculty {value === 'faculty' && ' - ' + count}
                          </option>
                          <option value={'students'}>
                            Students {value === 'students' && ' - ' + count}
                          </option>
                        </Form.Select>
                      </Form.Group>
                      {showSearch && (
                        <Form.Control
                          className="search_box"
                          type="text"
                          placeholder="Enter Member Name"
                          name="search"
                          autoComplete="Off"
                          value={search}
                          onChange={(event) => {
                            setSearch(event.target.value);
                          }}
                        />
                      )}
                      <i
                        role="presentation"
                        onClick={() => {
                          setShowSearch(!showSearch);
                        }}
                        className="fas fa-search"
                      />
                    </div>
                    <div className="table-responsive">
                      <Table className="custom-table-data" striped bordered hover>
                        <thead>
                          <tr>
                            <th>
                              <Form.Check
                                type="checkbox"
                                id="checkbox"
                                label=""
                                checked={
                                  allId?.every((elem) => {
                                    return checkedId.includes(elem);
                                  }) && membersList?.length > 0
                                    ? true
                                    : false
                                }
                                onChange={(e) => handleSelectAll(e)}
                              />
                            </th>
                            <th>Sn</th>
                            <th>Name</th>
                            <th>Email</th>
                          </tr>
                        </thead>
                        <tbody>
                          {membersList?.length > 0 ? (
                            membersList?.map((member, index) => {
                              return (
                                <tr key={index}>
                                  <td>
                                    <Form.Check
                                      type="checkbox"
                                      id="checkbox"
                                      label=""
                                      name={member?.name}
                                      checked={checkedId?.includes(member?.user_id)}
                                      onChange={(e) => {
                                        handleMultipleCheckBox(e, member?.user_id);
                                      }}
                                    />
                                  </td>
                                  <td>{index + 1}</td>
                                  <td>{member?.name}</td>
                                  <td>{member?.email}</td>
                                </tr>
                              );
                            })
                          ) : (
                            <tr>
                              <td className="text-center" colSpan={4}>
                                No data found!
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </Table>
                    </div>
                  </Form>
                </div>
              </Col>
              <Col xl={8} lg={7} md={6} sm={12} xs={12}>
                <div className="communication_module">
                  <div className="communication_checkbox">
                    <div className="me-3 mb-2 d-flex">
                      <Form.Check
                        type="checkbox"
                        id="checkbox"
                        label=""
                        name="communication"
                        value={`email`}
                        checked={communication === 'email'}
                        onChange={(e) => {
                          setCommunication(e.target.value);
                          setCommunicatinDetails({ ...communicationDetails, status: '' });
                          setContent('');
                          setSubject('');
                        }}
                      />
                      <Form.Label>Email</Form.Label>
                    </div>
                    <div className="me-3 mb-2 d-flex">
                      <Form.Check
                        type="checkbox"
                        id="checkbox"
                        label=""
                        name="communication"
                        value={`sms`}
                        checked={communication === 'sms'}
                        onChange={(e) => {
                          setCommunication(e.target.value);
                          setCommunicatinDetails({ ...communicationDetails, status: '' });
                          setContent('');
                          setSubject('');
                        }}
                      />
                      <Form.Label>SMS</Form.Label>
                    </div>
                    <div className="me-3 mb-2 d-flex">
                      <Form.Check
                        type="checkbox"
                        id="checkbox"
                        label=""
                        name="communication"
                        value={`notification`}
                        checked={communication === 'notification'}
                        onChange={(e) => {
                          setCommunication(e.target.value);
                          setCommunicatinDetails({ ...communicationDetails, status: '' });
                          setContent('');
                          setSubject('');
                        }}
                      />
                      <Form.Label>Notification</Form.Label>
                    </div>
                  </div>

                  <div>
                    {communication === 'email' && (
                      <EmailCommunication
                        dropdownValue={dropdownValue}
                        checkedName={checkedName}
                        communicationDetails={communicationDetails}
                        setCommunicatinDetails={setCommunicatinDetails}
                        emailEditorRef={emailEditorRef}
                        onLoad={onLoad}
                        templates={templates}
                        selectedIndex={selectedIndex}
                        setSelectedIndex={setSelectedIndex}
                        setHtmlContent={setHtmlContent}
                        htmlContent={htmlContent}
                        subject={subject}
                        setSubject={setSubject}
                        content={content}
                        setContent={setContent}
                        handleChange={handleChange}
                        communication={communication}
                        communicate={communicate}
                        setItem={setItem}
                      />
                    )}
                  </div>
                  <div>
                    {communication === 'sms' && (
                      <SMSCommunication
                        dropdownValue={dropdownValue}
                        checkedName={checkedName}
                        communicationDetails={communicationDetails}
                        setCommunicatinDetails={setCommunicatinDetails}
                        templates={templates}
                        selectedIndex={selectedIndex}
                        setSelectedIndex={setSelectedIndex}
                        subject={subject}
                        setSubject={setSubject}
                        content={content}
                        setContent={setContent}
                        handleChange={handleChange}
                        communication={communication}
                        communicate={communicate}
                      />
                    )}
                  </div>
                  <div>
                    {communication === 'notification' && (
                      <NotificationCommunication
                        dropdownValue={dropdownValue}
                        checkedName={checkedName}
                        communicationDetails={communicationDetails}
                        setCommunicatinDetails={setCommunicatinDetails}
                        templates={templates}
                        selectedIndex={selectedIndex}
                        setSelectedIndex={setSelectedIndex}
                        subject={subject}
                        setSubject={setSubject}
                        content={content}
                        setContent={setContent}
                        handleChange={handleChange}
                        communication={communication}
                        communicate={communicate}
                      />
                    )}
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
};

export default CommunicationPage;
