/**
@copyright  :   ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
@author     :   Shiv Charan Panjeta 
 
All Rights Reserved.
Proprietary and confidential :  All information contained here in is, and remains
the property of ToXSL Technologies Pvt. Ltd. and it's partners.
Unauthorized copying of this file, via any medium is strictly prohibited.
*/

import { useEffect, useState } from 'react';
import { Button, Container } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { useLocation, useNavigate } from 'react-router';
import CKEditorComponent from '../../../components/webpages/user-pages/PostComponets/CKEditorComponent';
import { restrictNum } from '../../../globals/helper';
import useDocumentTitle from '../../../globals/useDocumentTitle';
import {
  addCms,
  cmsDetail,
  getPageListForDropInCms,
  getTemplateListForDropInCms,
  updateCmspage
} from '../../../services/AuthServices';
import showNotification from '../../../services/NotificationService';

export default function AddCMsPage() {
  useDocumentTitle('Add New Page | AlmaBay');
  const location = useLocation();
  const id = location.state;
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [clicked, setClicked] = useState(false);
  const [tempList, setTempList] = useState([]);
  const [detail, setDetail] = useState({
    page_title: '',
    meta_title: '',
    page_slug: '',
    page_parent: '',
    page_template: '',
    meta_keywords: '',
    meta_description: ''
  });
  const [page_content, setPage_Content] = useState('');

  useEffect(() => {
    getPageListDropDown();
    getTemplateListDropDown();
    if (id) {
      getDetail(id);
    }
  }, []);

  // pagelistDropdown
  const [pageList, setPageList] = useState([]);
  const getPageListDropDown = () => {
    getPageListForDropInCms().then((resp) => {
      if (resp?.status == 200) {
        setPageList(resp?.data?.data);
        // showNotification('success', resp?.data?.message);
      }
    });
  };

  // templateDropDown

  const getTemplateListDropDown = () => {
    getTemplateListForDropInCms().then((resp) => {
      if (resp?.status == 200) {
        setTempList(resp?.data?.data);
        // showNotification('success', resp?.data?.message);
      }
    });
  };

  const onChangeHandler = (e) => {
    const { name, value } = e.target;
    setDetail({ ...detail, [name]: value });
  };

  const getDetail = (id) => {
    setLoading(true);
    cmsDetail(id).then((res) => {
      setLoading(false);
      if (res?.status === 200) {
        setDetail({
          id: res?.data?.data?.id,
          page_title: res?.data?.data?.page_title,
          meta_title: res?.data?.data?.meta_title,
          page_slug: res?.data?.data?.page_slug,
          page_parent: res?.data?.data?.page_parent,
          page_template: res?.data?.data?.page_template,
          meta_keywords: res?.data?.data?.meta_keywords,
          meta_description: res?.data?.data?.meta_description
        });
        setPage_Content(res?.data?.data?.page_content);
      }
    });
  };

  const submitHandler = (e) => {
    e.preventDefault();
    setClicked(true);
    if (!Object.keys(validate(detail)).length) {
      setClicked(false);
      let formData = new FormData();
      for (let i in detail) {
        formData.append(`${i}`, detail[i]);
      }
      formData.append('page_content', page_content);

      addCms(formData).then((res) => {
        if (res?.status === 200) {
          showNotification('success', res?.data?.message);
          navigate('/admin/cms/list');
        }
      });
    }
  };

  const updateHandler = (e) => {
    e.preventDefault();
    setClicked(true);
    if (!Object.keys(validate(detail)).length) {
      let formData = new FormData();
      for (let i in detail) {
        formData.append(`${i}`, detail[i]);
      }
      formData.append('page_content', page_content);
      updateCmspage(detail?.id, formData).then((res) => {
        if (res?.status === 200) {
          showNotification('success', res?.data?.message);
          // navigate(-1);
          navigate('/admin/cms/list');
        }
      });
    }
  };

  const validate = () => {
    let error = {};
    if (detail?.page_title == '') {
      error.page_title = 'Please enter page title';
    }
    if (detail?.page_slug == '') {
      error.page_slug = 'Please enter unique slug.';
    }
    return error;
  };

  return (
    <div className="dash-main-content">
      <Container fluid>
        <div className="dash-page-content">
          <div className="dash-page-title">
            <Row>
              <Col xs={12} sm={12} md={12}>
                <div className="dash-title-bar d-flex align-items-center justify-content-between">
                  <div className="title-lft d-flex align-items-center">
                    <div className="dash-icn">
                      <i className="fas fa-newspaper"></i>
                    </div>
                    <div className="dash-title-dis">
                      <h5>{id ? <h5> Update Page</h5> : <h5>Add New Page</h5>}</h5>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <div className="dash-content-card">
            <div className="cmmn-form">
              <Form>
                <Row>
                  <Col md={12}>
                    <Form.Group className="mb-3" controlId="formBasicName">
                      <Form.Label>Select Page</Form.Label>
                      <Form.Select
                        aria-label="Default select example"
                        value={detail?.page_parent}
                        name="page_parent"
                        onChange={(e) => {
                          onChangeHandler(e);
                        }}>
                        <option value="">New Page</option>
                        {pageList &&
                          pageList.map((data, index) => (
                            <option key={index} value={data?.page_title}>
                              {data?.page_title}
                            </option>
                          ))}
                      </Form.Select>
                    </Form.Group>
                  </Col>

                  <Col md={12}>
                    <Form.Group className="mb-3" controlId="formBasicName">
                      <Form.Label>Select Page Template</Form.Label>
                      <Form.Select
                        aria-label="Default select example"
                        value={detail?.page_template}
                        name="page_template"
                        onChange={(e) => {
                          onChangeHandler(e);
                        }}>
                        <option value="">Simple Content</option>
                        {tempList &&
                          tempList.map((data, index) => (
                            <option key={index} value={data?.template_name}>
                              {data?.template_name}
                            </option>
                          ))}
                      </Form.Select>
                    </Form.Group>
                  </Col>

                  <Col md={12}>
                    <Form.Group className="mb-3" controlId="formBasicName">
                      <Form.Label>
                        Page Title <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Page Title"
                        value={detail?.page_title}
                        onChange={(e) => {
                          onChangeHandler(e);
                        }}
                        name="page_title"
                        onKeyPress={restrictNum}
                      />
                      <div className={'danger-frm'}>{clicked && validate()?.page_title}</div>
                    </Form.Group>
                  </Col>

                  <Col md={12}>
                    <Form.Group className="mb-3" controlId="formBasicName">
                      <Form.Label>
                        Page Slug <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="page_slug"
                        placeholder="Enter Page Slug"
                        value={detail?.page_slug}
                        onChange={(e) => {
                          onChangeHandler(e);
                        }}
                      />
                      <div className={'danger-frm'}>{clicked && validate()?.page_slug}</div>
                    </Form.Group>
                  </Col>

                  <Col md={12}>
                    {/* <Form.Group className="mb-3" controlId="formBasicDesi">
                        <Form.Label>
                          Page Content <span className="text-danger">*</span>{' '}
                        </Form.Label>
                        <Form.Control
                          as="textarea"
                          placeholder=""
                          rows={3}
                          name="page_content"
                          value={detail?.page_content}
                          onChange={(e) => {
                            onChangeHandler(e);
                          }}
                        />
                      </Form.Group> */}

                    <Form.Group className="mb-3" controlId="formBasicDesi">
                      <Form.Label>
                        Page Content
                        <span className="text-danger">*</span>
                      </Form.Label>
                      <CKEditorComponent
                        isToolBar={true}
                        setLatestPost={setPage_Content}
                        latestPost={page_content}
                      />
                    </Form.Group>
                  </Col>

                  <Col md={12}>
                    <Form.Group className="mb-3" controlId="formBasicDesi">
                      <Form.Label>Meta Title </Form.Label>
                      <Form.Control
                        as="textarea"
                        placeholder="Enter Meta Title"
                        name="meta_title"
                        value={detail?.meta_title}
                        onChange={(e) => {
                          onChangeHandler(e);
                        }}
                        rows={3}
                      />
                    </Form.Group>
                  </Col>

                  <Col md={12}>
                    <Form.Group className="mb-3" controlId="formBasicDesi">
                      <Form.Label>Meta Keywords </Form.Label>
                      <Form.Control
                        as="textarea"
                        placeholder="Enter Meta Keywords"
                        rows={3}
                        name="meta_keywords"
                        value={detail?.meta_keywords}
                        onChange={(e) => {
                          onChangeHandler(e);
                        }}
                      />
                    </Form.Group>
                  </Col>

                  <Col md={12}>
                    <Form.Group className="mb-3" controlId="formBasicDesi">
                      <Form.Label>Meta Description </Form.Label>
                      <Form.Control
                        as="textarea"
                        placeholder="Enter Meta Description"
                        name="meta_description"
                        value={detail?.meta_description}
                        onChange={(e) => {
                          onChangeHandler(e);
                        }}
                        rows={3}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </Form>
              <div className="form-btns d-flex align-items-center mt-3">
                {id ? (
                  <Button
                    className="main-btn-grn"
                    onClick={(e) => {
                      updateHandler(e);
                    }}>
                    Update
                  </Button>
                ) : (
                  <Button
                    className="main-btn-grn"
                    onClick={(e) => {
                      submitHandler(e);
                    }}>
                    Save
                  </Button>
                )}

                <strong>OR</strong>
                <Button
                  className="main-btn-red"
                  type="button"
                  onClick={() => {
                    navigate(-1);
                  }}>
                  Cancel
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}
