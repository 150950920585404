/**
@copyright  :   ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
@author     :   Shiv Charan Panjeta 
 
All Rights Reserved.
Proprietary and confidential :  All information contained here in is, and remains
the property of ToXSL Technologies Pvt. Ltd. and it's partners.
Unauthorized copying of this file, via any medium is strictly prohibited.
*/

import { useEffect, useState } from 'react';
import { Button, Container } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Table from 'react-bootstrap/Table';
import ReactPaginate from 'react-paginate';
import { Link, useNavigate } from 'react-router-dom';
import NoDataFound from '../../components/commoncomponents/NoDataFound';
import useDocumentTitle from '../../globals/useDocumentTitle';
import * as APISERVICES from '../../services/AuthServices';

export default function WhatsNew() {
  useDocumentTitle('Manage Whats New | AlmaBay');
  const navigate = useNavigate();

  useEffect(() => {
    getOtherNew(1);
  }, []);

  const [details, setDetails] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState('');

  const [newData, setNewData] = useState({
    id: '',
    title: '',
    status: ''
  });

  const [sortType, setSortType] = useState('DESC');
  const [sortField, setSortField] = useState('id');
  const [currentPage, setCurrentPage] = useState(1);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewData({ ...newData, [name]: value.trim() });
  };

  const getOtherNew = (page) => {
    setCurrentPage(page);
    APISERVICES.getOtherWhatNews().then((resp) => {
      if (resp?.status === 200) {
        setDetails(resp?.data?.data?.viewlist);
        //setResponse(resp?.data?.data?.totalRecords);
        setTotalRecords(resp?.data?.data?.totalRecords);
      }
    });
  };

  const handleSearch = (page = 1, sort_field = 'id', order = 'DESC') => {
    setLoading(true);
    setCurrentPage(page);
    APISERVICES.getOtherWhatNews(
      'whatsnew',
      page,
      sort_field,
      order,
      newData.id,
      newData.title,
      newData.status
    ).then((resp) => {
      setLoading(false);
      if (resp?.status === 200) {
        setDetails(resp?.data?.data?.viewlist);
        setTotalRecords(resp?.data?.data?.totalRecords);
      }
    });
  };

  const handlePageClick = (a) => {
    let page = a?.selected + 1;
    handleSearch(page, sortField, sortType);
  };

  const sortHandler = (e, type) => {
    e.preventDefault();
    let currentSort = sortType === 'ASC' ? 'DESC' : 'ASC';
    setSortType(currentSort);
    setSortField(type);
    handleSearch(currentPage, type, currentSort);
  };

  const selectHandler = (value, id) => {
    switch (value) {
      case 'view':
        navigate(`/admin/news/index/view_what_news?id=${id}`);
        break;
      case 'edit':
        navigate(`/admin/news/index/what-add-news?id=${id}`);
        break;
      default:
        break;
    }
  };

  const resetHandler = () => {
    setNewData({
      id: '',
      title: '',
      status: ''
    });
    getOtherNew(1);
  };

  return (
    <div className="dash-main-content">
      <Container fluid>
        <div className="dash-page-content">
          <div className="dash-page-title">
            <Row>
              <Col xs={12} sm={12} md={12}>
                <div className="dash-title-bar d-flex align-items-center justify-content-between">
                  <div className="title-lft d-flex align-items-center">
                    <div className="dash-icn">
                      <i className="fas fa-newspaper"></i>
                    </div>
                    <div className="dash-title-dis">
                      <h5>
                        What's New <span>Listing</span>
                      </h5>
                      <h5>
                        Total Record(s): <span>{totalRecords}</span>
                      </h5>
                    </div>
                  </div>
                  <div className="title-rt-btn">
                    <Link className="green_button_admin" to="/admin/news/index/what-add-news">
                      Add What's New
                    </Link>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <div className="dash-content-card">
            <div className="table-responsive">
              <Table className="custom-table-data" striped bordered hover>
                <thead>
                  <tr>
                    <th>
                      <Link
                        to="#"
                        onClick={(e) => {
                          sortHandler(e, 'id');
                        }}>
                        Id
                        <img src={require('../../assets/images/sort.png')} alt="sort" />
                      </Link>
                    </th>
                    <th>
                      <Link
                        to="#"
                        onClick={(e) => {
                          sortHandler(e, 'title');
                        }}>
                        Title
                        <img src={require('../../assets/images/sort.png')} alt="sort" />
                      </Link>
                    </th>
                    <th>Image</th>
                    <th>
                      <Link
                        to="#"
                        onClick={(e) => {
                          sortHandler(e, 'status');
                        }}>
                        Status
                        <img src={require('../../assets/images/sort.png')} alt="sort" />
                      </Link>
                    </th>

                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="search-table">
                    <td>
                      <Form.Control
                        type="text"
                        placeholder="Id"
                        name="id"
                        onChange={(e) => handleChange(e)}
                        value={newData?.id}
                      />
                    </td>
                    <td>
                      <Form.Control
                        type="text"
                        placeholder="Title"
                        name="title"
                        onChange={(e) => handleChange(e)}
                        value={newData?.title}
                      />
                    </td>
                    <td></td>
                    <td>
                      <Form.Select
                        aria-label="Default select example"
                        name="status"
                        value={newData?.status}
                        onChange={(e) => handleChange(e)}>
                        <option> - Select Status - </option>
                        <option value={0}>Disable</option>
                        <option value={1}>Enable</option>
                        <option value={2}>Delete</option>
                      </Form.Select>
                    </td>
                    <td className="serac-btns text-center">
                      <button
                        className="green_button_admin"
                        type="button"
                        onClick={() => handleSearch()}>
                        Search
                      </button>
                      <Button
                        className="main-btn-red ms-2"
                        onClick={() => {
                          resetHandler();
                        }}>
                        Reset
                      </Button>
                    </td>
                  </tr>
                  {details && details?.length ? (
                    details?.map((data, index) => {
                      return (
                        <tr key={index}>
                          <td>{data?.id || 'NA'}</td>
                          <td>{data?.title || 'NA'}</td>
                          <td>
                            <img
                              src={process.env.REACT_APP_API_URL + data?.imageUrl}
                              alt="img"
                              width="180"
                              height="125"
                            />
                          </td>
                          <td>
                            {data?.status == 1
                              ? 'Enable'
                              : data?.status == 0
                              ? 'Disable'
                              : 'Delete'}
                          </td>
                          <td>
                            <Form.Select
                              aria-label="Default select example"
                              onChange={(e) => {
                                selectHandler(e.target.value, data?.id);
                              }}>
                              <option>Action</option>
                              <option value="edit">Edit</option>
                              <option value="view">view</option>
                            </Form.Select>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr className="text-center">
                      <td colSpan={8}>
                        <NoDataFound />
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
              <div className="react-page d-flex">
                {totalRecords && totalRecords > 10 ? (
                  <ReactPaginate
                    previousLabel={currentPage > 0 ? '<' : ''}
                    nextLabel=">"
                    onPageChange={handlePageClick}
                    pageCount={Math.ceil(totalRecords / 10)}
                    activeClassName={'page-link active'}
                    forcePage={parseInt(currentPage) - 1}
                  />
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}
