/**
@copyright  :   ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
@author     :   Shiv Charan Panjeta 
 
All Rights Reserved.
Proprietary and confidential :  All information contained here in is, and remains
the property of ToXSL Technologies Pvt. Ltd. and it's partners.
Unauthorized copying of this file, via any medium is strictly prohibited.
*/
import { useEffect, useRef, useState } from 'react';
import { Alert, Button, Col, Container, Dropdown, Form, Row, Table } from 'react-bootstrap';
import ReactPaginate from 'react-paginate';
import { Link } from 'react-router-dom';
import NoDataFound from '../../components/commoncomponents/NoDataFound';
import Loader from '../../components/commoncomponents/loader/loader-large';
import { useOnClickOutside } from '../../globals/helper';
import useDocumentTitle from '../../globals/useDocumentTitle';
import { addCompanyActions, getCompaniesForAdmin } from '../../services/AuthServices';
import showNotification from '../../services/NotificationService';

export default function ListCompanies() {
  useDocumentTitle('Manage Companies | AlmaBay');
  const [sortedFields, setSortedFields] = useState({
    fieldName: 'id',
    order: 'DESC'
  });
  const [searchFields, setSearchFields] = useState({
    id: '',
    companyName: '',
    companyType: ''
  });
  const [showalert, setShowAlert] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [companiesList, setCompaniesList] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [selectedCheckbox, setSelectedCheckbox] = useState([]);
  const [type, setType] = useState('');
  const ref = useRef();
  const [orderBy, setOrderBy] = useState('DESC');
  const [shortOrderBy, setShortOrderBy] = useState('id');
  const [allId, setAllId] = useState([]);
  const [checkedId, setCheckedId] = useState([]);

  useEffect(() => {
    document.getElementById('root').scrollIntoView();
    fetchCompaniesForAdmin();
  }, [currentPage]);

  useEffect(() => {
    const timeId = setTimeout(() => {
      // After 3 seconds set the show value to false
      setShowAlert(false);
    }, 5000);

    return () => {
      clearTimeout(timeId);
    };
  }, [showalert]);

  const fetchCompaniesForAdmin = (page, type, order) => {
    let searchParms = {
      search_companyid: searchFields.id,
      search_companyname: searchFields.companyName,
      institute_type: searchFields.companyType
    };
    let sortedParams = {
      sort_field: type ? type : shortOrderBy,
      order: order ? order : orderBy
    };
    setIsLoading(true);
    getCompaniesForAdmin(page ? page : currentPage, searchParms, sortedParams).then((res) => {
      if (res?.status == 200) {
        setIsLoading(false);
        const companydata = res.data.data.company_list.map((v) => ({ ...v, isDrop: false }));
        setCompaniesList(companydata);
        setTotalCount(res?.data?.data?.totalRecords);
        setAllId(res.data.data.company_list?.map((data) => data?.id));
      } else {
        setIsLoading(false);
      }
    });
  };
  const handlePageClick = (a) => {
    let page = a?.selected;

    setCurrentPage(page);
    // fetchCompaniesForAdmin(page);
  };
  const handleCheckBoxSelection = (id, action) => {
    if (action) {
      // select all
      if (action == 'all') {
        let result = companiesList.map((item) => item.id);
        setSelectedCheckbox(result);
        return;
      }
      if (action == 'reset') {
        setSelectedCheckbox([]);
        return;
      }
    }

    if (selectedCheckbox.includes(id)) {
      let results = selectedCheckbox.filter((item) => item != id);
      setSelectedCheckbox(results);
    } else {
      setSelectedCheckbox([...selectedCheckbox, id]);
    }
  };
  const handleMainActions = async (e) => {
    if (checkedId.length === 0) {
      showNotification('danger', 'Please Select Any Record');
    } else {
      if (e.target.value === '') {
        showNotification('danger', 'Please select an option');
      } else {
        let body = {
          ids: checkedId.toString(),
          action: e.target.value
        };
        const response = await addCompanyActions(body);
        if (response?.status === 200) {
          showNotification('success', response.data.message);
          setCheckedId([]);
          e.target.value = '';
          fetchCompaniesForAdmin(currentPage);
        }
      }
    }
  };

  const handleSingleAction = (type, id) => {
    setType(type);
    setIsDisabled(true);
    let body = {
      ids: id,
      action: type
    };
    addCompanyActions(body).then((resp) => {
      if (resp.status === 200) {
        setShowAlert(true);
        setIsDisabled(false);

        fetchCompaniesForAdmin(currentPage);
      }
    });
  };

  const handleDrop = (e, index) => {
    e.preventDefault();

    // setProficiency({
    //   id: data.proficiency_id,
    //   label: data.proficiency_name,
    //   value: data.proficiency_name
    // });

    companiesList.forEach((a, i) => {
      if (i === index) {
        setIsDisabled(true);
        companiesList[index].isDrop = true;
      } else {
        companiesList[i].isDrop = false;
      }
    });
  };
  useOnClickOutside(ref, (e) => hideAllDrop(e));

  const hideAllDrop = (e) => {
    e.preventDefault();
    setIsDisabled(false);
    companiesList.forEach((a, i) => {
      companiesList[i].isDrop = false;
    });
  };

  const renderCompanyStatus = (status) => {
    status = Number(status);
    switch (status) {
      case 0: {
        return 'Disabled';
      }
      case 1: {
        return 'Enabled';
      }
      case 2: {
        return 'Deleted';
      }
      default: {
        return 'N/A';
      }
    }
  };

  const handleSearchChange = (e) => {
    setSearchFields({
      ...searchFields,
      [e.target.name]: e.target.value
    });
  };

  const searchHandler = (e) => {
    e.preventDefault();
    fetchCompaniesForAdmin(1); // later when search is implemented
    setCurrentPage(1);
  };

  const resetHandler = () => {
    setSortedFields({
      fieldName: 'id',
      order: 'DESC'
    });
    setSearchFields({
      id: '',
      companyName: '',
      companyType: ''
    });
    let searchParms = {
      search_companyid: '',
      search_companyname: '',
      institute_type: ''
    };
    let sortedParams = {
      sort_field: 'id',
      order: 'DESC'
    };

    getCompaniesForAdmin(0, searchParms, sortedParams).then((res) => {
      if (res?.status == 200) {
        const companydata = res.data.data.company_list.map((v) => ({ ...v, isDrop: false }));
        setCompaniesList(companydata);
        setTotalCount(res?.data?.data?.totalRecords);
      }
    }); // later when search is implemented
  };

  const sortHandler = (e, type) => {
    e.preventDefault();
    let currentSort = orderBy === 'ASC' ? 'DESC' : 'ASC';
    setOrderBy(currentSort);
    setShortOrderBy(type);
    fetchCompaniesForAdmin(currentPage, type, currentSort);
  };

  const handleShowInstiType = (key) => {
    switch (key) {
      case 'company':
        return 'Corporate';

      case 'ngo':
        return 'Non Government';
      case 'npo':
        return 'Non Profit';

      case 'gov_agency':
        return 'Govt Agencies';

      case 'professionals':
        return 'Professionals';

      case 'entrepreneur':
        return 'Entrepreneur';

      default:
        return 'N/A';
    }
  };

  /**
   * function call after check all
   * @param {*} e
   */
  const handleSelectAll = (e) => {
    if (e.target.checked === true) {
      allId.forEach((id) => {
        if (!checkedId.includes(id)) {
          setCheckedId((prev) => {
            return [...prev, id];
          });
        }
      });
    } else {
      setCheckedId([
        ...checkedId.filter((id) => {
          return allId.indexOf(id) == -1;
        })
      ]);
    }
  };

  /**
   * function for individual chechbox
   * @param {*} e
   * @param {*} id
   */
  const handleMultipleCheckBox = (e, id) => {
    if (checkedId.includes(id)) {
      setCheckedId(
        checkedId.filter((data) => {
          return data !== id;
        })
      );
    } else {
      setCheckedId([...checkedId, id]);
    }
  };

  return (
    <>
      <div className="dash-main-content">
        <Container fluid>
          <div className="dash-page-content">
            <div className="dash-page-title">
              <Row>
                <Col xs={12} sm={12} md={12}>
                  <div className="dash-title-bar d-flex align-items-center justify-content-between">
                    <div className="title-lft d-flex align-items-center">
                      <div className="dash-icn">
                        <i className="fa fa-building"></i>
                      </div>
                      <div className="dash-title-dis">
                        <h5>
                          Company <span>Listing</span>
                        </h5>
                        {companiesList?.length !== 0 && (
                          <h5>
                            Total Record(s): <span>{totalCount}</span>
                          </h5>
                        )}
                      </div>
                    </div>

                    <div className="title-rt-btn d-flex align-items-center">
                      <Link className="green_button_admin mx-2" to="/admin/companies/add">
                        Add Company
                      </Link>
                      <div className="head-instite-sec">
                        <Form.Select
                          aria-label="Default select example "
                          onChange={(e) => {
                            handleMainActions(e);
                          }}>
                          <option value="">Choose Action</option>
                          <option value="enable">Enable</option>
                          <option value="disable">Disable</option>
                          <option value="delete">Delete</option>
                        </Form.Select>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="dash-content-card">
              <Alert variant={'success'} className={`${showalert ? '' : 'd-none'}`}>
                Company has been{' '}
                {type == 'enable' ? 'enabled' : type == 'disable' ? 'disabled' : 'deleted'}{' '}
                successfully
              </Alert>
              <div className="table-responsive">
                <Table className="custom-table-data" striped bordered hover>
                  <thead>
                    <tr>
                      <th>
                        <Form.Check
                          type="checkbox"
                          id="checkbox"
                          label=""
                          checked={
                            allId?.every((elem) => {
                              return checkedId.includes(elem);
                            }) && companiesList?.length > 0
                              ? true
                              : false
                          }
                          onChange={(e) => handleSelectAll(e)}
                        />
                      </th>
                      <th>
                        <Link
                          to="#"
                          onClick={(e) => {
                            if (sortedFields.fieldName == 'id') {
                              if (sortedFields.order == 'ASC') {
                                setSortedFields({
                                  ...sortedFields,
                                  fieldName: 'id',
                                  order: 'DESC'
                                });
                              } else {
                                setSortedFields({
                                  ...sortedFields,
                                  fieldName: 'id',
                                  order: 'ASC'
                                });
                              }
                            } else {
                              setSortedFields({
                                ...sortedFields,
                                fieldName: 'id',
                                order: 'ASC'
                              });
                            }
                            sortHandler(e, 'id');
                            setCurrentPage(0);
                          }}>
                          Id
                          <img src={require('../../assets/images/sort.png')} alt="sort" />
                        </Link>
                      </th>
                      <th>
                        <Link
                          to="#"
                          onClick={(e) => {
                            if (sortedFields.fieldName == 'name') {
                              if (sortedFields.order == 'ASC') {
                                setSortedFields({
                                  ...sortedFields,
                                  fieldName: 'name',
                                  order: 'DESC'
                                });
                              } else {
                                setSortedFields({
                                  ...sortedFields,
                                  fieldName: 'name',
                                  order: 'ASC'
                                });
                              }
                            } else {
                              setSortedFields({
                                ...sortedFields,
                                fieldName: 'name',
                                order: 'ASC'
                              });
                            }

                            sortHandler(e, 'name');
                            setCurrentPage(0);
                          }}>
                          Name
                          <img src={require('../../assets/images/sort.png')} alt="sort" />
                        </Link>
                      </th>
                      <th>
                        <Link to="">Company Type</Link>
                      </th>
                      <th>
                        <Link to="">Company Page</Link>
                      </th>
                      <th>Status</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="search-table">
                      <td></td>
                      <td>
                        <Form.Control
                          type="text"
                          value={searchFields.id}
                          name="id"
                          placeholder="Id"
                          onChange={handleSearchChange}
                        />
                      </td>
                      <td>
                        <Form.Control
                          type="text"
                          placeholder="Company Name"
                          value={searchFields.companyName}
                          name="companyName"
                          onChange={handleSearchChange}
                        />
                      </td>
                      <td>
                        <Form.Select
                          aria-label="Default select example"
                          value={searchFields?.companyType}
                          onChange={(e) => {
                            setSearchFields({
                              ...searchFields,
                              companyType: e.target.value
                            });
                          }}>
                          <option value="">Select Organization Type</option>
                          <option value="company">Corporate </option>
                          <option value="ngo">Non Government</option>
                          <option value="npo">Non Profit</option>
                          <option value="gov_agency">Govt Agencies</option>
                          <option value="professionals">Professionals</option>
                          <option value="entrepreneur">Entrepreneur</option>
                        </Form.Select>
                      </td>
                      <td></td>
                      <td></td>
                      <td className="serac-btns" colSpan={2}>
                        <button className="green_button_admin me-2" onClick={searchHandler}>
                          Search
                        </button>
                        <Button className="main-btn-red" onClick={() => resetHandler()}>
                          Reset
                        </Button>
                      </td>
                    </tr>
                    {companiesList.length > 0 ? (
                      companiesList?.map((company, index) => {
                        return (
                          <tr key={company.id}>
                            <td>
                              <Form.Check
                                type="checkbox"
                                id={`checkbox${index}`}
                                label=""
                                name={company?.name}
                                checked={checkedId?.includes(company?.id)}
                                onChange={(e) => {
                                  handleMultipleCheckBox(e, company?.id);
                                }}
                              />
                            </td>
                            <td>{company?.id}</td>
                            <td title={company?.name}>{company?.name} </td>
                            <td>{handleShowInstiType(company?.institute_type)} </td>
                            <td>{company?.timeline_id == 0 ? 'No' : 'Yes'}</td>
                            <td>{renderCompanyStatus(company?.status)}</td>
                            <td>
                              <Dropdown
                                id={`id${index}`}
                                className="cs_dropdown text-center"
                                onClick={(e) => handleDrop(e, index)}>
                                <Dropdown.Toggle variant="success" id="dropdown-basic">
                                  Action
                                </Dropdown.Toggle>
                                {company?.isDrop == true ? (
                                  <div ref={ref}>
                                    {' '}
                                    <Dropdown.Menu>
                                      <>
                                        {' '}
                                        <Link
                                          className="dropdown-item"
                                          to={`/admin/companies/add?companyId=${company?.id}`}>
                                          Edit
                                        </Link>
                                        {company?.status == '0' ? (
                                          <Link
                                            className="dropdown-item"
                                            to="#"
                                            onClick={(e) => {
                                              handleSingleAction('enable', company?.id);
                                            }}>
                                            Enable
                                          </Link>
                                        ) : (
                                          <Link
                                            className="dropdown-item"
                                            to="#"
                                            onClick={(e) => {
                                              handleSingleAction('disable', company?.id);
                                            }}>
                                            Disable
                                          </Link>
                                        )}
                                        <Link
                                          className="dropdown-item"
                                          to="#"
                                          onClick={(e) => {
                                            handleSingleAction('delete', company?.id);
                                          }}>
                                          Delete
                                        </Link>
                                        {company?.timeline_id == 0 ? (
                                          ''
                                        ) : (
                                          <>
                                            <Link
                                              className="dropdown-item"
                                              to={`/admin/companies/manage_leaders/${company?.id}/${company?.timeline_id}`}>
                                              Manage Leaders
                                            </Link>
                                            <Link
                                              className="dropdown-item"
                                              to={`/admin/companies/manage_admins/${company?.id}`}>
                                              Manage Admins
                                            </Link>
                                          </>
                                        )}
                                      </>
                                    </Dropdown.Menu>
                                  </div>
                                ) : (
                                  ''
                                )}
                              </Dropdown>
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr className="text-center">
                        <td colSpan={9}>
                          <NoDataFound />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </div>
              {companiesList.length !== 0 && (
                <>
                  {Math.ceil(totalCount / 10) > 1 && (
                    <div className="react-page d-flex justify-content-center">
                      <ReactPaginate
                        previousLabel={'<'}
                        forcePage={currentPage - 1}
                        nextLabel={'>'}
                        activeClassName={'page-link active'}
                        onPageChange={(props) => {
                          setCurrentPage(props.selected + 1);
                        }}
                        pageCount={Math.ceil(totalCount / 10)}
                      />
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </Container>
      </div>
      {isLoading && <Loader />}
    </>
  );
}
