/**  @copyright : ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
@author     : Shiv Charan Panjeta < shiv@toxsl.com
All Rights Reserved.
Proprietary and confidential :  All information contained herein is, and remains
the property of ToXSL Technologies Pvt. Ltd. and its partners.
Unauthorized copying of this file, via any medium is strictly prohibited.
**/
import { useEffect, useState } from 'react';
import { Button, Container } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { useNavigate, useParams } from 'react-router';
import { useLocation } from 'react-router-dom';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import AsyncCreatableSelect from 'react-select/async-creatable';
import CreatableSelect from 'react-select/creatable';
import DefaultLogo from '../../assets/images/institute-dummy.jpg';
import { disableFutureMonth } from '../../globals/helper';
import useDocumentTitle from '../../globals/useDocumentTitle';
import * as adminService from '../../services/AuthServices';
import showNotification from '../../services/NotificationService';
import { loadJObLocationOption } from '../AdminHelper';

export default function AddWork() {
  const location = useLocation();
  const Data = location.state;
  const navigate = useNavigate();

  const { work_id, user_id } = useParams();

  const [companyId, setCompanyId] = useState();

  const [organisationList, setOrganisationList] = useState([]);
  const [organisationId, setOrganisationId] = useState('');

  const [industryList, setIndustryList] = useState('');
  const [industryId, setIndustryId] = useState('');

  const [functionalAreaList, setFunctionalAreaList] = useState('');
  const [functionalId, setFunctionalId] = useState('');

  const [gradeLevelList, setGradeLevelList] = useState([]);
  const [gradeLevelId, setGradeLevelId] = useState('');

  const [clicked, setClicked] = useState(false);

  const [workDetail, setWorkDetail] = useState({
    institute_type: '',
    company_name: '',
    industry: '',
    functional_area: '',
    level_name: '',
    position: '',
    job_description: '',
    city: '',
    year_from: '',
    year_to: '',
    currently_working_here: 0
  });
  const [jobLocation, setJobLocation] = useState('');

  useEffect(() => {
    if (user_id) {
      organisationDropDown();
      industryDropDown();
      functionalArea();
      gradeLevel();
    }
    if (work_id) {
      dataHandler(Data);
    }
  }, []);

  const dataHandler = (data) => {
    setJobLocation({
      id: data?.city,
      label: data?.city_name,
      value: data?.city_name
    });
    setWorkDetail({
      institute_type: data?.org_type,
      company_name: data?.company_id,
      industry: data?.industry,
      functional_area: data?.functional_area_name,
      level_name: data?.level_name,
      position: data?.designation,
      job_description: data?.job_profile,
      city: data?.city,
      year_from: `${data?.year_from}-${data?.month_from < 9 ? '0' + data?.month_from : data?.month_from
        }`,
      year_to: `${data?.year_to}-${data?.month_to < 9 ? '0' + data?.month_to : data?.month_to}`,
      currently_working_here: Number(data?.currently_working_here)
    });

    setCompanyId({
      label: data?.organisation,
      value: data?.company_id,
      logo: DefaultLogo
    });

    setOrganisationId({
      label: data?.org_type,
      value: data?.org_type
    });

    setIndustryId({
      label: data?.industry_name,
      value: data?.industry
    });

    setFunctionalId({
      label: data?.functional_area_name,
      value: data?.functional_area_name
    });

    setGradeLevelId({
      label: data?.level_name,
      value: data?.level_name
    });
  };

  const organisationDropDown = () => {
    adminService.getOrganizationsDrop().then((res) => {
      if (res?.status === 200) {
        let list = res?.data?.data;
        let tempArr = [];
        for (let i in list) {
          tempArr.push({
            label: list[i],
            value: i
          });
        }
        setOrganisationList(tempArr);
      }
    });
  };

  const loadCompanyOption = (inputValue) => {
    return adminService.getCompanyDropDown(inputValue).then((res) => {
      if (res?.status === 200) {
        let tempArr = [];
        if (res?.data?.data?.length) {
          const data = res?.data?.data;
          data?.map((data) => {
            tempArr.push({
              label: data?.name,
              value: data?.id,
              logo: data?.logo
                ? process.env.REACT_APP_API_DOMAIN + '/common/uploads/institute_logo/' + data?.logo
                : DefaultLogo
            });
          });
        }
        return tempArr;
      }
    });
  };

  const industryDropDown = () => {
    adminService.getJobIndustryDropDown().then((res) => {
      if (res?.status === 200) {
        let list = res?.data;
        let tempArr = [];
        list?.map((data) => {
          tempArr.push({
            label: data?.name,
            value: data?.id
          });
        });
        setIndustryList(tempArr);
      }
    });
  };

  //create
  const functionalArea = () => {
    adminService.getFunctionalAreaDropDown().then((res) => {
      if (res?.status === 200) {
        let list = res?.data;
        let tempArr = [];
        list?.map((data) => {
          tempArr.push({
            label: data?.name,
            value: data?.name
          });
        });
        setFunctionalAreaList(tempArr);
      }
    });
  };

  const gradeLevel = () => {
    adminService.getGradeDropDown().then((res) => {
      if (res?.status === 200) {
        let list = res?.data?.data;
        let tempArr = [];
        list?.map((data) => {
          tempArr.push({
            label: data?.name,
            value: data?.name
          });
        });
        setGradeLevelList(tempArr);
      }
    });
  };

  //validate
  const validate = (value) => {
    let errors = {};
    if (!value?.institute_type) {
      errors.institute_type = 'This field is required';
    }
    if (!value?.company_name) {
      errors.company_name = 'This field is required';
    }
    if (!value?.industry) {
      errors.industry = 'This field is required';
    }
    if (!value?.functional_area) {
      errors.functional_area = 'This field is required';
    }
    if (!value?.level_name) {
      errors.level_name = 'This field is required';
    }
    if (!value?.position) {
      errors.position = 'This field is required';
    }
    if (!value?.year_from) {
      errors.year_from = 'This field is required';
    }
    if (value.currently_working_here != 1 && value?.year_to && value?.year_to < value.year_from) {
      errors.year_to = 'To date should be greater date from start date';
    }
    return errors;
  };

  //save data
  const submitHandler = (e) => {
    e.preventDefault();
    setClicked(true);
    if (!Object.keys(validate(workDetail)).length) {
      setClicked(false);
      adminService.addWorkDetail(user_id, workDetail).then((res) => {
        if (res?.status === 200) {
          showNotification('success', res?.data?.message);
          navigate(-1);
        }
      });
    }
  };

  const updateHandler = (e) => {
    e.preventDefault();
    setClicked(true);
    if (!Object.keys(validate(workDetail)).length) {
      setClicked(false);
      adminService.editWorkDetail(user_id, work_id, workDetail).then((res) => {
        if (res?.status === 200) {
          showNotification('success', res?.data?.message);
          navigate(-1);
        }
      });
    }
  };

  const onChangeHandler = (e, option) => {
    if (e?.__isNew__) {
      setWorkDetail({ ...workDetail, [option.name]: e });
    } else {
      setWorkDetail({ ...workDetail, [option.name]: e.value });
    }
  };

  const createOption = (label) => ({
    label,
    value: label.toLowerCase().replace(/\W/g, ''),
    logo: DefaultLogo
  });

  const handleCreate = (inputValue) => {
    const newOption = createOption(inputValue);
    setWorkDetail({ ...workDetail, ['company_name']: inputValue });
    setCompanyId(newOption);
  };

  return (
    <div className="dash-main-content">
      <Container fluid>
        <div className="dash-page-content">
          <div className="dash-page-title">
            <Row>
              <Col xs={12} sm={12} md={12}>
                <div className="dash-title-bar d-flex align-items-center justify-content-between">
                  <div className="title-lft d-flex align-items-center">
                    <div className="dash-icn">
                      <i className="fas fa-briefcase"></i>
                    </div>
                    <div className="dash-title-dis">
                      {work_id
                        ? useDocumentTitle('Edit Work | Almabay')
                        : useDocumentTitle('Add Work | Almabay')}

                      {<h5>{work_id ? 'Add' : 'Edit'} Work</h5>}
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <div className="dash-content-card">
            <div className="cmmn-form">
              <Form>
                <Row>
                  <Col md={4}>
                    <Form.Group className="mb-3" controlId="formBasicDesi">
                      <Form.Label>
                        Organisation Type <sup className="text-danger">*</sup>
                      </Form.Label>
                      <Select
                        className="basic-single"
                        classNamePrefix="select"
                        isSearchable
                        name="institute_type"
                        options={organisationList}
                        value={organisationId}
                        onChange={(e, option) => {
                          onChangeHandler(e, option);
                          setOrganisationId(e);
                        }}
                        placeholder="Select Organisation Type"
                      />
                      <div
                        className={`${clicked && validate(workDetail).institute_type ? '' : 'd-none'
                          } danger-frm `}
                      >
                        {clicked && validate(workDetail).institute_type}
                      </div>
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group className="mb-3" controlId="formBasicDesi">
                      <Form.Label>
                        Enter name of your company <sup className="text-danger">*</sup>
                      </Form.Label>
                      <AsyncCreatableSelect
                        cacheOptions
                        className="basic-single"
                        classNamePrefix="select"
                        isSearchable={true}
                        name="company_name"
                        loadOptions={loadCompanyOption}
                        onChange={(e, option) => {
                          onChangeHandler(e, option);
                          setCompanyId(e);
                        }}
                        placeholder="Select Organization name from list"
                        formatOptionLabel={(ins) => (
                          <div className="ins-option">
                            <img src={ins.logo || DefaultLogo} alt="logo" />
                            <span>{ins.label}</span>
                          </div>
                        )}
                        // options={options}
                        value={companyId}
                        onCreateOption={(e) => {
                          handleCreate(e);
                        }}
                      />
                      <div
                        className={`${clicked && validate(workDetail).company_name ? '' : 'd-none'
                          } danger-frm `}
                      >
                        {clicked && validate(workDetail).company_name}
                      </div>
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group className="mb-3" controlId="formBasicDesi">
                      <Form.Label>
                        Industry <sup className="text-danger">*</sup>
                      </Form.Label>
                      <Select
                        className="basic-single"
                        classNamePrefix="select"
                        isSearchable
                        name="industry"
                        options={industryList}
                        value={industryId}
                        onChange={(e, option) => {
                          setIndustryId(e), onChangeHandler(e, option);
                        }}
                        placeholder="Select Industry from list"
                      />
                      <div
                        className={`${clicked && validate(workDetail).industry ? '' : 'd-none'
                          } danger-frm `}
                      >
                        {clicked && validate(workDetail).industry}
                      </div>
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group className="mb-3" controlId="formBasicDesi">
                      <Form.Label>
                        Department/Functional Area <sup className="text-danger">*</sup>
                      </Form.Label>
                      <CreatableSelect
                        className="basic-single"
                        classNamePrefix="select"
                        isSearchable
                        value={functionalId}
                        options={functionalAreaList}
                        onChange={(e, option) => {
                          setFunctionalId(e);
                          onChangeHandler(e, option);
                        }}
                        name="functional_area"
                        placeholder="Select Department/Functional Area"
                      />
                      <div
                        className={`${clicked && validate(workDetail).functional_area ? '' : 'd-none'
                          } danger-frm `}
                      >
                        {clicked && validate(workDetail).functional_area}
                      </div>
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group className="mb-3" controlId="formBasicDesi">
                      <Form.Label>
                        Grade/Level <sup className="text-danger">*</sup>
                      </Form.Label>
                      <CreatableSelect
                        className="basic-single"
                        classNamePrefix="select"
                        isSearchable
                        value={gradeLevelId}
                        options={gradeLevelList}
                        onChange={(e, option) => {
                          setGradeLevelId(e);
                          onChangeHandler(e, option);
                        }}
                        name="level_name"
                        placeholder="Select Grade/Level"
                      />
                      <div
                        className={`${clicked && validate(workDetail).level_name ? '' : 'd-none'
                          } danger-frm `}
                      >
                        {clicked && validate(workDetail).level_name}
                      </div>
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group className="mb-3" controlId="formBasicDesi">
                      <Form.Label>
                        Designation/Position <sup className="text-danger">*</sup>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        value={workDetail?.position}
                        onChange={(e) => {
                          setWorkDetail({ ...workDetail, [e.target.name]: e.target.value });
                        }}
                        placeholder="Enter Designation"
                        name="position"
                      />
                      <div
                        className={`${clicked && validate(workDetail).position ? '' : 'd-none'
                          } danger-frm `}
                      >
                        {clicked && validate(workDetail).position}
                      </div>
                    </Form.Group>
                  </Col>
                  <Col md={12}>
                    <Form.Group className="mb-3" controlId="formBasicDesi">
                      <Form.Label>Description</Form.Label>
                      <Form.Control
                        as="textarea"
                        type="text"
                        name="job_description"
                        value={workDetail?.job_description}
                        placeholder="Write about your profession..."
                        onChange={(e) => {
                          setWorkDetail({ ...workDetail, [e.target.name]: e.target.value });
                        }}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group className="mb-3" controlId="formBasicDesi">
                      <Form.Label>
                        Location <sup className="text-danger">*</sup>
                      </Form.Label>
                      <AsyncSelect
                        cacheOptions
                        defaultOptions
                        value={jobLocation}
                        getOptionLabel={(e) => e.label}
                        getOptionValue={(e) => e.value}
                        loadOptions={loadJObLocationOption}
                        className="basic-single mt-1"
                        name="location"
                        onChange={(e) => {
                          setJobLocation(e);
                          setWorkDetail({
                            ...workDetail,
                            ['city']: e.id
                          });
                        }}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group className="mb-3" controlId="formBasicDesi">
                      <Form.Label>
                        Time Period From <sup className="text-danger">*</sup>
                      </Form.Label>
                      <Form.Control
                        defaultValue={workDetail?.year_from}
                        max={disableFutureMonth()}
                        type="month"
                        onChange={(e) => {
                          setWorkDetail({ ...workDetail, [e.target.name]: e.target.value });
                        }}
                        name="year_from"
                      />
                      <div
                        className={`${clicked && validate(workDetail).year_from ? '' : 'd-none'
                          } danger-frm `}
                      >
                        {clicked && validate(workDetail).year_from}
                      </div>
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    {!workDetail?.currently_working_here ? (
                      <>
                        <Form.Group className="mb-3" controlId="formBasicDesi">
                          <Form.Label>To</Form.Label>
                          <Form.Control
                            type="month"
                            defaultValue={workDetail?.year_to}
                            name="year_to"
                            max={disableFutureMonth()}
                            onChange={(e) => {
                              setWorkDetail({ ...workDetail, [e.target.name]: e.target.value });
                            }}
                          />
                        </Form.Group>
                        <div
                          className={`${clicked && validate(workDetail).year_to ? '' : 'd-none'
                            } danger-frm `}
                        >
                          {clicked && validate(workDetail).year_to}
                        </div>
                      </>
                    ) : (
                      <Form.Label>
                        To <p>Present</p>
                      </Form.Label>
                    )}
                  </Col>
                  <Col md={12}>
                    <Form.Group className="mb-3" controlId="formBasicCheckbox">
                      <Form.Check
                        type="checkbox"
                        checked={workDetail?.currently_working_here}
                        onChange={(e) => {
                          setWorkDetail({
                            ...workDetail,
                            [e.target.name]: e.target.checked ? 1 : 0
                          });
                        }}
                        label="Currently Working here"
                        name="currently_working_here"
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <div className="form-btns d-flex align-items-center mt-3">
                  {work_id ? (
                    <Button className="main-btn-grn" onClick={updateHandler}>
                      Save
                    </Button>
                  ) : (
                    <Button className="main-btn-grn" onClick={submitHandler}>
                      Save
                    </Button>
                  )}
                  <strong>OR</strong>
                  <Button
                    className="main-btn-red"
                    onClick={(e) => {
                      e.preventDefault();
                      navigate(-1);
                    }}
                  >
                    Cancel
                  </Button>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}
