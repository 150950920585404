/** @copyright : ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
 @author     : Shiv Charan Panjeta 

 All Rights Reserved.
 Proprietary and confidential :  All information contained herein is, and remains
 the property of ToXSL Technologies Pvt. Ltd. and its partners.
 Unauthorized copying of this file, via any medium is strictly prohibited.
*/
import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { emailRegex } from '../../globals/helper';
import showNotification from '../../services/NotificationService';
import { newsLetterSignup } from '../../services/ShopServices';

function FooterNewsLetter() {
  const isloggedIn = useSelector((state) => state?.user?.isloggedIn);
  const [email, setEmail] = useState('');
  const [clicked, setClicked] = useState(false);

  const validateNewsLetter = () => {
    let errors = {};
    if (!email) {
      errors.email = 'This field is required.';
    }
    if (email && !emailRegex.test(email)) {
      errors.email = 'Please enter a valid email address.';
    }
    return errors;
  };

  const newsLetterFunc = (e) => {
    e.preventDefault();
    setClicked(true);
    if (!Object.keys(validateNewsLetter(email))?.length) {
      let body = {
        newsletter_email: email
      };

      newsLetterSignup(body).then((res) => {
        if (res?.status == 200) {
          showNotification('success', res?.data?.message);
          setClicked(false);
          setEmail('');
        } else {
          showNotification('danger', res?.data?.message);
        }
      });
    }
  };

  return (
    <>
      <section id="footer" className={`footer-card footer-blk pd-35`}>
        <div className="container">
          <Row>
            <Col xs={12} md={6} lg={2}>
              <div className="navigation-box mb-5">
                <div className="navigation-heading Roboto-black">SOCIAL</div>
                <ul className="nav navigation-list">
                  <li>
                    <Link to="/"></Link>
                  </li>
                  <li>
                    {isloggedIn ? (
                      <Link to="/user/find-friend">Find Alumni</Link>
                    ) : (
                      <Link to="/login">Find Alumni</Link>
                    )}
                  </li>

                  <li>
                    {isloggedIn ? (
                      <Link to="/user/alumnimap">Alumni Map</Link>
                    ) : (
                      <Link to="/login">Alumni Map</Link>
                    )}

                    {/* <Link to="/login">Alumni map</Link> */}
                  </li>
                  <li>
                    <a href="https://blog.almabay.com" rel="noreferrer" target={'_blank'}>
                      Blog
                    </a>
                    {/* <Link to="/Termsofuse">blog</Link> */}
                  </li>
                </ul>
              </div>
            </Col>

            <Col xs={12} md={6} lg={2}>
              <div className="navigation-box">
                <div className="navigation-heading Roboto-black">PROFESSIONAL</div>
                <ul className="nav navigation-list">
                  <li>
                    <Link to="/jobs/search">Browse jobs </Link>
                  </li>
                  <li>
                    <Link to="/home/recruitments">Recruitment advocacy program</Link>
                  </li>
                  <li>
                    <Link to="/recruiter-solutions">Recruitment Solutions </Link>
                  </li>
                  <li>
                    <Link to="/user/jobsmap">Browse jobs on map </Link>
                  </li>
                </ul>
              </div>
            </Col>
            <Col xs={12} md={6} lg={2}>
              <div className="navigation-box">
                <div className="navigation-heading Roboto-black">ALMA STORE</div>
                <ul className="nav navigation-list">
                  <li>
                    <Link to="/shop">Browse memorabilia</Link>
                  </li>
                  <li>
                    <Link to="/delivery-information">Delivery information</Link>
                  </li>
                  <li>
                    <Link to="/return-policy">Return policy </Link>
                  </li>
                  {isloggedIn ? (
                    <li>
                      <Link to="/shop/orders"> My Orders </Link>
                    </li>
                  ) : (
                    <li>
                      <Link to="/shop"> My Orders </Link>
                    </li>
                  )}
                </ul>
              </div>
            </Col>
            <Col xs={12} md={6} lg={2}>
              <div className="navigation-box">
                <div className="navigation-heading Roboto-black">HELP</div>
                <ul className="nav navigation-list">
                  <li>
                    <Link to="/faq">Faq's</Link>
                  </li>
                  <li>
                    <Link to="/advancesearch/directory/A">Find your institute</Link>
                  </li>
                </ul>
              </div>
            </Col>
            <Col xs={12} md={6} lg={4}>
              <div className="sign-up  text-white ftr-lnks">
                <div className="navigation-heading Roboto-black">NEWSLETTER SIGN UP</div>

                <Form id="newsletter" className="form-inline clearfix">
                  <Form.Control
                    type="email"
                    name="username"
                    placeholder="Email address"
                    value={email}
                    className={`${
                      validateNewsLetter(email).email ? 'text-danger' : 'text-secondary'
                    } '' `}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <Button onClick={newsLetterFunc} className="btn-default go-btn text-white mx-1">
                    GO
                  </Button>
                </Form>
                <div
                  className={`${
                    clicked && validateNewsLetter(email).email ? '' : 'd-none'
                  } danger-frm `}>
                  {clicked && validateNewsLetter(email).email}
                </div>

                <div className="contact-number text-warning py-3 fs-4">
                  <i className="fas fa-phone-alt me-2"></i>+91 -82880-25661
                </div>
              </div>
            </Col>
          </Row>

          <div className="sub-footer">
            <div className="pull-left  copyright-text">
              <p>AlmaBay © {new Date().getFullYear()}. All Rights Reserved.</p>
            </div>
            <div className="pull-right card-images">
              <ul className="footer-img">
                <li>
                  <img src={require('../../assets/images/visa-img.png')} alt="img" />
                </li>
                <li>
                  <img src={require('../../assets/images/american.png')} alt="img" />
                </li>
                <li>
                  <img src={require('../../assets/images/master-card.png')} alt="img" />
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default FooterNewsLetter;
