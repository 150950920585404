import {
  DELETE_MENU_LIST_FAILED,
  DELETE_MENU_LIST_PENDING,
  DELETE_MENU_LIST_SUCCESS
} from '../action';

const initialState = {
  message: '',
  isLoading: false,
  hasError: ''
};

export const deleteMenuListReducer = (state = initialState, action) => {
  switch (action.type) {
    case DELETE_MENU_LIST_PENDING:
      return {
        ...state,
        isLoading: true
      };

    case DELETE_MENU_LIST_SUCCESS:
      return {
        ...state,
        message: action.payload,
        isLoading: false
      };

    case DELETE_MENU_LIST_FAILED:
      return {
        ...state,
        message: '',
        hasError: action.payload
      };

    default:
      return state;
  }
};
