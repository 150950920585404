/**
 * @copyright : ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
@author    : Shiv Charan Panjeta 
 
All Rights Reserved.
Proprietary and confidential :  All information contained here in is, and remains
the property of ToXSL Technologies Pvt. Ltd. and it's partners.
Unauthorized copying of this file, via any medium is strictly prohibited.
 */

import { useState } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import Col from 'react-bootstrap/Col';
import { Link } from 'react-router-dom';
import AsyncSelect from 'react-select/async';
import * as APISERVICES from '../../../services/UserServices';

function JobFilterSearch(props) {
  const {
    setSkillsFilterId,
    setCompanyFilterId,
    advanceJobSearch,
    setIndustryFilterId,
    setLocationFilterId,
    onResetPageNum,
    job_type,
    setTechSkill,
    loadTechOptions,
    inputMsg,
    setInputMsg,
    loadInpMsg,
    techSkill,
    getInternJobList
  } = props;

  const colourStyles = {
    option: (styles, { isFocused }) => {
      // const color = chroma(data.color);
      return {
        ...styles,
        backgroundColor: isFocused ? '#eaeaea' : null,
        color: '#212529'
      };
    }
  };
  const [selectedCompanyOption, setSelectedCompanyOption] = useState([]);
  const [selectedIndustryOption, setSelectedIndustryOption] = useState([]);
  const [inputMessage, setInputMessage] = useState('Please enter atleast 1 character');
  const [loading, setLoading] = useState(false);
  const [loadInd, setLoadInd] = useState(false);
  const [loadLoc, setLoadLoc] = useState(false);
  const [value, setValue] = useState([]);

  // load options using API call
  const loadCompanyOption = (inputValue = '') => {
    if (inputValue?.length == 1 || inputValue?.length > 1) {
      setLoading(true);
      return APISERVICES.companyDropdown(inputValue, job_type).then((res) => {
        setLoading(false);
        setInputMessage('searching...');
        if (res?.status === 200) {
          let tempArr = [];
          if (res?.data?.results) {
            const data = res?.data?.results;
            data?.map((data) => {
              tempArr.push({
                id: data?.id,
                label: data?.name,
                value: data?.name
              });
            });
          }
          if (res?.data?.results == '') {
            setInputMessage('no matches found ..');
          }
          return tempArr;
        }
      });
    }
  };

  // load options using API call
  const loadIndustryOption = (inputValue = '') => {
    if (inputValue?.length >= 1) {
      setLoadInd(true);
      return APISERVICES.industriesDropdown(inputValue, job_type).then((res) => {
        setInputMessage('searching...');
        setLoadInd(false);
        if (res?.status === 200) {
          let tempArr = [];
          if (res?.data?.results) {
            const data = res?.data?.results;
            data?.map((data) => {
              tempArr.push({
                id: data?.id,
                label: data?.name,
                value: data?.name
              });
            });
          }
          if (res?.data?.results?.length == 0) {
            setInputMessage('no matches found');
          }

          return tempArr;
        }
      });
    }
  };

  const onChangeHandler = (e, option) => {
    let arr = [];
    if (option.name === 'skills') {
      e?.map((data) => {
        arr.push(data?.value);
      });
      setSkillsFilterId(arr.join());
    } else if (option.name === 'company') {
      e?.map((data) => {
        arr.push(data?.id);
      });
      setCompanyFilterId(arr.join());
    } else if (option.name === 'industry') {
      e?.map((data) => {
        arr.push(data?.id);
      });

      setIndustryFilterId(arr.join());
    } else if (option.name === 'location') {
      e?.map((data) => arr.push(data?.id));
      setLocationFilterId(arr.join());
    }
  };

  const loadGetLocationTags = async (inputValue) => {
    if (inputValue?.length == 1 || inputValue?.length > 1) {
      setLoadLoc(true);
      return APISERVICES.getLocationTags(inputValue).then((res) => {
        setLoadLoc(false);
        if (res?.status === 200) {
          let tempArr = [];
          if (res?.data?.results) {
            const data = res?.data?.results;
            data?.map((data) => {
              tempArr.push({
                id: data?.id,
                label: data?.name,
                value: data?.name
              });
            });
          }
          if (res?.data?.results == '') {
            setInputMessage('no matches found ..');
          }
          return tempArr;
        }
      });
    }
  };

  const resetInitial = () => {
    setSkillsFilterId('');
    setCompanyFilterId('');
    setIndustryFilterId('');
    setLocationFilterId([]);
    setValue([]);
    setSelectedCompanyOption('');
    setSelectedIndustryOption('');
    onResetPageNum();
    setTechSkill([]);
    getInternJobList();
  };

  const inputMessageHandler = (e) => {
    if (e?.length !== 1 && e?.length < 1) {
      setInputMessage('Please enter atleast 1 character');
      // setInputMsg('Please enter atleast 1 character');
    }
  };

  return (
    <>
      <Col xs={12} lg={4} xl={3} stateFilter="jobFilterSearch">
        <div className="fiter-card mb-4 mb-lg-0" id="filter-slidebar">
          <div className="filter-hd-card d-flex align-items-center justify-content-between">
            <div className="filter-hd">
              <h4>Filter By</h4>
            </div>
            <div className="filter-btn d-flex">
              {/* <Link to="#">
                <i className="fas fa-filter"></i>
              </Link> */}
              <button className="dark-blu-btn" type="button" onClick={advanceJobSearch}>
                Search
              </button>
              {/* <Link to="#">
                <i className="fas fa-sync-alt"></i>
              </Link> */}
              <button
                className="dark-blu-btn ms-2"
                onClick={() => {
                  resetInitial();
                }}>
                Reset
              </button>
              <Link
                className="d-block d-lg-none"
                onClick={() => {
                  document.getElementById('filter-slidebar').classList.toggle('sidebar_closed');
                }}>
                <i className="fas fa-bars org-color"></i>
              </Link>
            </div>
          </div>
          <div className="filter-select-lst d-none d-lg-block">
            <Accordion defaultActiveKey="0" alwaysOpen flush>
              <Accordion.Item eventKey="0">
                <Accordion.Body className="spinner-wrapper">
                  {/* <Select
                    isMulti
                    className="basic-single mt-1"
                    classNamePrefix="select"
                    isDisabled={false}
                    isLoading={false}
                    isClearable={false}
                    isRtl={false}
                    isSearchable={true}
                    options={skillsOption}
                    styles={colourStyles}
                    name="skills"
                    value={selectedSkillsOption}
                    onChange={(e, option) => {
                      onChangeHandler(e, option);
                      setSelectedSkillsOption(e);
                    }}
                    placeholder="Search.. e.g Skills, designation, job role"
                  /> */}

                  <AsyncSelect
                    isMulti
                    cacheOptions
                    defaultOptions
                    value={techSkill}
                    getOptionLabel={(e) => e.label}
                    getOptionValue={(e) => e.value}
                    loadOptions={loadTechOptions}
                    className="basic-single mt-1"
                    name="location"
                    onChange={setTechSkill}
                    components={{
                      DropdownIndicator: () => null,
                      IndicatorSeparator: () => null,
                      LoadingIndicator: () => null
                    }}
                    // menuIsOpen={techSearch !== '' ? true : false}

                    placeholder="e.g. Photoshop, Illustrator"
                    noOptionsMessage={() => inputMsg}
                    onInputChange={(newValue) => {
                      newValue?.length !== 2 && newValue?.length < 2
                        ? setInputMsg('Please enter atleast 2 characters.')
                        : '';
                    }}
                    styles={{
                      control: (base) => ({
                        ...base,
                        border: '0 !important',
                        // This line disable the  border
                        boxShadow: '0 !important',
                        '&:hover': {
                          border: '0 !important'
                        }
                      })
                    }}
                  />
                  {loadInpMsg && (
                    <div className="spinner-filter">
                      <i className="fa fa-spinner fa-pulse me-2"></i>
                    </div>
                  )}
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
            <Accordion defaultActiveKey={['1', '2', '3']} alwaysOpen={true} flush>
              <Accordion.Item eventKey="1">
                <Accordion.Header>
                  Company <sub>(e.g. Dell, Infosys etc...)</sub>
                </Accordion.Header>
                <Accordion.Body className="spinner-wrapper">
                  <AsyncSelect
                    isMulti
                    className="basic-single mt-1"
                    classNamePrefix="select"
                    isDisabled={false}
                    isLoading={false}
                    isClearable={false}
                    isRtl={false}
                    isSearchable={true}
                    loadOptions={loadCompanyOption}
                    value={selectedCompanyOption}
                    components={{
                      DropdownIndicator: () => null,
                      IndicatorSeparator: () => null,
                      LoadingIndicator: () => null
                    }}
                    name="company"
                    onChange={(e, option) => {
                      onChangeHandler(e, option);
                      setSelectedCompanyOption(e);
                    }}
                    styles={colourStyles}
                    placeholder="Search.. e.g Dell, Infosys etc"
                    onInputChange={(e) => inputMessageHandler(e)}
                    noOptionsMessage={() => inputMessage}
                  />
                  {loading && (
                    <div className="spinner-filter">
                      <i className="fa fa-spinner fa-pulse me-2 "></i>
                    </div>
                  )}
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2">
                <Accordion.Header>
                  Industry <sub> (e.g. IT, Communication etc...)</sub>
                </Accordion.Header>
                <Accordion.Body className="spinner-wrapper">
                  <AsyncSelect
                    isMulti
                    className="basic-single mt-1"
                    classNamePrefix="select"
                    isDisabled={false}
                    isLoading={false}
                    isClearable={false}
                    isRtl={false}
                    isSearchable={true}
                    loadOptions={loadIndustryOption}
                    components={{
                      DropdownIndicator: () => null,
                      IndicatorSeparator: () => null,
                      LoadingIndicator: () => null
                    }}
                    styles={colourStyles}
                    value={selectedIndustryOption}
                    name="industry"
                    onChange={(e, option) => {
                      onChangeHandler(e, option);
                      setSelectedIndustryOption(e);
                    }}
                    placeholder="Search.. e.g IT, Communication"
                    onInputChange={(e) => inputMessageHandler(e)}
                    noOptionsMessage={() => inputMessage}
                  />
                  {loadInd && (
                    <div className="spinner-filter">
                      <i className="fa fa-spinner fa-pulse me-2"></i>
                    </div>
                  )}
                </Accordion.Body>
              </Accordion.Item>
              {/* location */}
              <Accordion.Item eventKey="3">
                <Accordion.Header>
                  Location <sub>(e.g Delhi, Pune etc...)</sub>
                </Accordion.Header>
                <Accordion.Body className="spinner-wrapper">
                  {/* <AsyncLocationSelect onChangeHandler={onChangeHandler} location="" /> */}
                  <AsyncSelect
                    cacheOptions
                    isMulti
                    defaultOptions
                    value={value}
                    placeholder="Search..e.g Delhi, Pune etc..."
                    getOptionLabel={(e) => e.label}
                    getOptionValue={(e) => e.value}
                    loadOptions={loadGetLocationTags}
                    className="basic-single"
                    name="location"
                    onChange={(e, option) => {
                      onChangeHandler(e, option);
                      setValue(e);
                    }}
                    components={{
                      DropdownIndicator: () => null,
                      IndicatorSeparator: () => null,
                      LoadingIndicator: () => null
                    }}
                    onInputChange={(e) => inputMessageHandler(e)}
                    noOptionsMessage={() => inputMessage}
                    styles={{
                      control: (base) => ({
                        ...base,
                        border: '0 !important',
                        // This line disable the  border
                        boxShadow: '0 !important',
                        '&:hover': {
                          border: '0 !important'
                        }
                      })
                    }}
                  />
                  {loadLoc && (
                    <div className="spinner-filter">
                      <i className="fa fa-spinner fa-pulse me-2"></i>
                    </div>
                  )}
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        </div>
        <div className="filter-select-lst d-none d-lg-block">
          <div className="sider-bar-filter p-3 text-end">
            <button className="accnt-btn" type="button" onClick={advanceJobSearch}>
              Search
            </button>
            <button
              className="dark-blu-btn ms-2"
              onClick={() => {
                resetInitial();
              }}>
              Reset
            </button>
          </div>
        </div>
      </Col>
    </>
  );
}

export default JobFilterSearch;
