import { Link } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import HeaderLogin from '../../commoncomponents/HeaderLogin';
import TopTabbings from './TopTabbings';
import Dropdown from 'react-bootstrap/Dropdown';
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Select from 'react-select';
import WhatsNew from '../../commoncomponents/newsfeeds/WhatsNew';
import Upcoming from '../../commoncomponents/newsfeeds/Upcoming';
import PeopleKnow from '../../commoncomponents/newsfeeds/PeopleKnow';
import FollowCompany from '../../commoncomponents/newsfeeds/FollowCompany';
import WorkAnniversary from '../../commoncomponents/newsfeeds/WorkAnniversary';
import CampusNews from '../../commoncomponents/newsfeeds/CampusNews';
import CampusnewsText from '../../commoncomponents/newsfeeds/CampusnewsText';
import ArticalBlogs from '../../commoncomponents/newsfeeds/ArticalBlogs';
import {
  PageLikeUnLike,
  addPage,
  getDescoverPages,
  getPagesCategories,
  getPagesYouManage,
  getUserLikePages
} from '../../../services/SocialServices';
import showNotification from '../../../services/NotificationService';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { truncate } from '../../../globals/helper';
import useDocumentTitle from '../../../globals/useDocumentTitle';
import { MiniFooter } from '../../MiniFooter';
import NoDataFound from '../../commoncomponents/NoDataFound';
import { CompanySimmer } from '../../commoncomponents/skelton/allskelton';
import { addFriend, followFriend } from '../../../services/UserServices';

function PageDiscover() {
  useDocumentTitle('List of Pages | AlmaBay');
  useEffect(() => {
    // fetchPagesYouManage();
    getPagesCategories().then((resp) => {
      if (resp.status === 200) {
        let tempArr = [];
        let arr = [];
        if (resp?.data) {
          Object.entries(resp?.data)
            .map(([key, value]) => ({ key, value }))
            ?.map((insideData, index) => {
              tempArr.push({
                label: insideData?.key,
                options: Object.entries(insideData?.value)?.map(([key1, value1]) => {
                  return {
                    label: value1,
                    value: key1
                  };
                })
              });
            });
        }
        setCategoryOptions(tempArr);
      }
    });
    fetchLikedPages();
    fetchPagesYouManage();
    fetchDescoverPages();
  }, []);

  const [isActive, setIsActive] = useState(false);
  const handleClick = (event) => {
    setIsActive((current) => !current);
    setIsActive1(false);
  };

  const [isActive1, setIsActive1] = useState(false);
  const handleClick2 = (event) => {
    setIsActive1((current) => !current);
    setIsActive(false);
  };

  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
    setClicked(false);
  };

  const handleShow = () => setShow(true);
  const [isClearable, setIsClearable] = useState(true);
  const [isSearchable, setIsSearchable] = useState(true);
  const [isDisabled, setIsDisabled] = useState(false);

  const [isRtl, setIsRtl] = useState(false);
  const [isActive2, setIsActive2] = useState(false);
  const [selected2, setIsSelected2] = useState('Choose one');
  const [pageName, setPageName] = useState('');
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [description, setDescription] = useState('');
  const [clicked, setClicked] = useState(false);
  const [managedPages, setManagedPages] = useState([]);
  const [likedPages, setLikedPages] = useState([]);

  const [descoverPages, setDiscoverPages] = useState([]);
  const [shouldLoadMoreButtonVisible, setShouldLoadMoreButtonVisible] = useState(true);
  const [shouldLoadMoreLikes, setShouldLoadMoreLikes] = useState(true);
  const [isScrollable, setIsScrollable] = useState(false);
  const [isScrollableLikes, setIsScrollableLikes] = useState(false);

  const [offset, setOffSet] = useState(6);
  const [isManageLoading, setIsManageLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [likedLoader, setLikedLoader] = useState(false);
  const [isClikedId, setIsClickedId] = useState('');
  const [disable, setDisable] = useState(false);
  const navigate = useNavigate();

  const fetchLikedPages = (search, offset, isTrue) => {
    if (offset || search) {
      setLoading(false);
    } else {
      setLoading(true);
    }

    getUserLikePages(search, offset ? offset : '').then((res) => {
      if (res?.status === 200) {
        setLoading(false);
        if (isTrue == true) {
          setLikedPages([...likedPages, ...res?.data?.data?.userpages] ?? []);
        } else {
          setLikedPages(res?.data?.data?.userpages ?? []);
        }
        if (res?.data?.data?.userpages?.length < 6) {
          setShouldLoadMoreLikes(false);
        }
      } else {
        setShouldLoadMoreLikes(false);
        setLikedPages([]);
      }
    });
  };
  const fetchDescoverPages = (type) => {
    type === 'LIKED' ? setIsLoading(false) : setIsLoading(true);

    getDescoverPages().then((resp) => {
      if (resp?.status == 200) {
        setIsLoading(false);
        setLikedLoader(false);
        setDiscoverPages(resp?.data?.detail?.pagesSuggestions ?? []);
      }
    });
  };
  const validate = () => {
    let errors = {};
    if (pageName === '') {
      errors.pageName = 'Page name is Required.';
    }
    if (selectedCategory == null || selectedCategory == '') {
      errors.category = 'Page category is required';
    }
    return errors;
  };
  const handleNavigateToDetailPage = (id, managePage, type) => {
    navigate(window.open(`/user/page/page-detail/${id}`, { state: managePage?.id, type })?.focus());
  };

  const addPageHandler = (e) => {
    // e.preventDefault();
    setClicked(true);
    if (Object.keys(validate()).length === 0) {
      let formData = new FormData();
      formData.append(`page_name`, pageName);
      formData.append(`page_about`, description);
      formData.append(`page_category_id`, selectedCategory.value);

      addPage(formData).then((res) => {
        if (res?.status === 200) {
          showNotification('success', res?.data?.message);
          setSelectedCategory(null);
          setPageName('');
          setDescription('');
          handleClose();
          fetchPagesYouManage();

          //  setLoading(false);
          navigate(`/user/page/page-detail/${res?.data?.page_data?.encrypted_id}`);
        }
      });
      // } else {
      //   return;
    }
  };

  const handleLoadMore = () => {
    setIsScrollable(true);
    setOffSet(managedPages?.length + 6);
    fetchPagesYouManage('', managedPages?.length, true);
  };
  const handleLoadMoreLikes = () => {
    setIsScrollableLikes(true);
    setOffSet(likedPages?.length + 6);
    fetchLikedPages('', likedPages?.length, true);
  };
  const fetchPagesYouManage = (search, offset, isTrue) => {
    if (offset || search) {
      setIsManageLoading(false);
    } else {
      setIsManageLoading(true);
    }

    getPagesYouManage(search, offset ? offset : '').then((resp) => {
      if (resp?.status === 200) {
        setIsManageLoading(false);
        if (isTrue == true) {
          setManagedPages([...managedPages, ...resp.data.data.userpages] ?? []);
        } else {
          setManagedPages(resp?.data?.data?.userpages ?? []);
        }
        if (resp?.data?.data?.userpages?.length < 6) {
          setShouldLoadMoreButtonVisible(false);
        }
      } else {
        setIsManageLoading(false);
        setShouldLoadMoreButtonVisible(false);
        setManagedPages([]);
      }
    });
  };

  const handleSearch = (search) => {
    if (search !== '') {
      setShouldLoadMoreButtonVisible(false);
    } else {
      setShouldLoadMoreButtonVisible(true);
    }

    fetchPagesYouManage(search, '', false);
  };

  const renderPopover = (props) => {
    if (props?.show) {
      setDisable(true);
    } else {
      setDisable(false);
    }
    let popOverData = props?.popper?.state?.options?.managePage;

    return (
      <Popover className="group-loader-card" id={`popover-basic`} {...props}>
        {/* <Popover.Header as="h3">Popover right</Popover.Header> */}
        <Popover.Body>
          <div className="loader-tooltip ">
            <div
              className="loader-cover"
              style={{ position: 'relative', padding: 0, margin: 0, top: 0, left: 0 }}>
              <img
                src={
                  popOverData?.actual_cover_url
                    ? popOverData?.actual_cover_url
                    : require('../../../assets/images/default-male-avatar.png')
                }
                alt="img"
                className="loader-image"
              />
            </div>
            <div className="page-loader-head-sec d-flex align-items-top p-3">
              <div className="people-icn me-3">
                <img
                  src={
                    popOverData?.actual_cover_url
                      ? popOverData?.actual_cover_url
                      : require('../../../assets/images/default-male-avatar.png')
                  }
                  alt="img"
                />
              </div>

              <div className="page-loader-hd">
                <h3>{popOverData?.name}</h3>
                <div className="loader-like-sec d-flex align-items-center">
                  <div className="d-flex me-3">
                    <span className="me-1">
                      {' '}
                      <i className="fas fa-thumbs-up me-2"></i>
                      {popOverData?.likes}
                    </span>
                    Likes
                  </div>
                  <div className="d-flex align-items-center">
                    <p className="mb-0">
                      {' '}
                      <i className="fas fa-flag me-2"></i> {popOverData?.category_name}
                    </p>
                  </div>
                </div>
                {popOverData?.about && (
                  <div className="mb-1">
                    {' '}
                    <p className="mb-0">
                      {' '}
                      <i className="fas fa-info me-2"></i> {truncate(popOverData?.about, 50)}
                    </p>
                  </div>
                )}

                {popOverData?.is_following ? (
                  <Link
                    className="unlike-btn"
                    to="#"
                    onClick={(e) =>
                      LikeDisLikePageFunc(
                        e,
                        popOverData.id,

                        'unlike'
                      )
                    }>
                    Unlike
                  </Link>
                ) : (
                  <Link
                    className="main-btn like-btn"
                    to="#"
                    onClick={(e) =>
                      LikeDisLikePageFunc(
                        e,
                        popOverData.id,

                        'like'
                      )
                    }>
                    <i className="fa fa-thumbs-up"></i> Like
                  </Link>
                )}
              </div>
            </div>
          </div>
        </Popover.Body>
      </Popover>
    );
  };

  const renderPagePopover = (props) => {
    if (props?.show) {
      setDisable(true);
    } else {
      setDisable(false);
    }
    let popOverData = props?.popper?.state?.options?.page;
    return (
      <Popover className="group-loader-card" id={`popover-basic`} {...props}>
        {/* <Popover.Header as="h3">Popover right</Popover.Header> */}
        <Popover.Body>
          <div className="loader-tooltip ">
            <div
              className="loader-cover"
              style={{ position: 'relative', padding: 0, margin: 0, top: 0, left: 0 }}>
              <img
                src={
                  popOverData?.cover_image
                    ? popOverData?.cover_image?.actual_cover
                    : require('../../../assets/images/default-male-avatar.png')
                }
                alt="img"
                className="loader-image"
              />
            </div>
            <div className="page-loader-head-sec d-flex align-items-top p-3">
              <div className="people-icn me-3">
                <img
                  src={
                    popOverData?.cover_image
                      ? popOverData?.cover_image?.actual_cover
                      : require('../../../assets/images/default-male-avatar.png')
                  }
                  alt="img"
                />
              </div>

              <div className="page-loader-hd">
                <h3>{popOverData?.name}</h3>
                <div className="loader-like-sec d-flex align-items-center">
                  <div className="d-flex me-3">
                    <span>
                      <i className="fas fa-thumbs-up me-2"></i>
                    </span>
                    <span className="me-1">{popOverData?.likes}</span>Likes
                  </div>
                  <div className="d-flex align-items-center">
                    <i className="fas fa-flag me-2"></i>{' '}
                    <p className="mb-0">{popOverData?.category_name}</p>
                  </div>
                </div>
                {popOverData?.about && (
                  <div className="mb-1">
                    {' '}
                    <p className="mb-0">
                      {' '}
                      <i className="fas fa-info me-2"></i> {truncate(popOverData?.about, 50)}
                    </p>
                  </div>
                )}
                {popOverData?.is_following ? (
                  <Link
                    className="unlike-btn"
                    to="#"
                    onClick={(e) =>
                      LikeDisLikePageFunc(
                        e,
                        popOverData.id,

                        'unlike'
                      )
                    }>
                    Unlike
                  </Link>
                ) : (
                  <Link
                    className="main-btn like-btn"
                    to="#"
                    onClick={(e) =>
                      LikeDisLikePageFunc(
                        e,
                        popOverData.id,

                        'like'
                      )
                    }>
                    <i className="fa fa-thumbs-up"></i> Like
                  </Link>
                )}
              </div>
            </div>
          </div>
        </Popover.Body>
      </Popover>
    );
  };

  const target = useRef(null);

  const LikeDisLikePageFunc = (e, id, type) => {
    e.preventDefault();
    if (type == 'like') {
      let body = {
        following_id: id
      };
      let formData = new FormData();
      for (let i in body) {
        formData.append(`${i}`, body[i]);
      }
      PageLikeUnLike(formData).then((res) => {
        if (res?.status === 200) {
          showNotification('success', 'Page liked successfully');
          fetchPagesYouManage('', '', false);
          fetchLikedPages();
          fetchDescoverPages('LIKED');
        } else {
          showNotification('err', res?.data?.message);
        }
      });
    } else {
      if (confirm('Do you want unlike this page?') == true) {
        let body = {
          following_id: id
        };
        let formData = new FormData();
        for (let i in body) {
          formData.append(`${i}`, body[i]);
        }
        PageLikeUnLike(formData).then((res) => {
          if (res?.status === 200) {
            showNotification('success', 'Page unliked successfully');
            fetchLikedPages();
            fetchPagesYouManage('', '', false);
            fetchDescoverPages('LIKED');
          } else {
            showNotification('err', res?.data?.message);
          }
        });
      } else return;
    }
  };

  const renderUserPopover = (props) => {
    if (props?.show) {
      setDisable(true);
    } else {
      setDisable(false);
    }
    let popOverData = props?.popper?.state?.options?.data;

    return (
      <Popover className="group-loader-card" id={`popover-basic`} {...props}>
        {/* <Popover.Header as="h3">Popover right</Popover.Header> */}
        <Popover.Body>
          <div className="loader-tooltip">
            <div className="loader-cover">
              <img
                src={
                  popOverData?.cover_url
                    ? popOverData?.cover_url
                    : require('../../../assets/images/default-cover.png')
                }
                alt="img"
                className="loader-image"
              />
            </div>
            <div className="people-know-loader-dis d-flex align-items-top">
              <div className="people-knw-top d-flex align-items-center">
                <div className="people-icn me-3">
                  <img
                    src={
                      popOverData?.avatar_url
                        ? popOverData?.avatar_url
                        : require('../../../assets/images/default-male-avatar.png')
                    }
                    alt="img"
                    className="loader-image"
                  />
                </div>
                <div className="people-hd">
                  <h3>{popOverData?.name}</h3>
                </div>
              </div>
            </div>
            <div className="people-knw-dis-popover pt-3">
              {/* <p>
                <i className="fas fa-graduation-cap me-1"></i> Studied at{' '}
                <Link to="#">SD COLLEGE Chandigarh</Link> in <Link to="#">2005</Link>
              </p> */}
              <p>
                <i className="fas fa-briefcase me-1"></i>Worked as
                <Link to="#">{popOverData?.designation}</Link> {' at '}
                <Link to="#">{popOverData?.organisation}</Link>
              </p>
              <div className="popover-inr-btn">
                <button
                  className="popovr-blk-btn me-1"
                  onClick={(e) => {
                    let body = {
                      following_id: popOverData?.id
                    };
                    if (confirm('Do you want to remove friend ?') === true) {
                      addFriend(body).then((res) => {
                        setLoading(false);
                        if (res?.status == 200) {
                          fetchDescoverPages();
                        } else {
                          showNotification('success', res?.data?.message);
                        }
                      });
                    }
                  }}>
                  Unfriend
                </button>
                {popOverData?.is_mentor ? (
                  <button
                    className="popovr-yellow-btn"
                    onClick={(e) => resquestHandler(e, popOverData?.id, 'unfolllow')}>
                    Unfollow Mentor
                  </button>
                ) : (
                  <button
                    className="popovr-yellow-btn"
                    onClick={(e) => resquestHandler(e, popOverData?.id, 'folllow')}>
                    Follow Mentor
                  </button>
                )}
              </div>
            </div>
          </div>
        </Popover.Body>
      </Popover>
    );
  };
  const resquestHandler = (e, id, type) => {
    e.preventDefault();
    setLoading(true);
    if (type == 'unfolllow') {
      let body = {
        following_id: id
      };
      followFriend(body).then((res) => {
        setLoading(false);
        if (res?.status == 200) {
          showNotification('success', 'Mentor unfollowed succesfully');
          fetchDescoverPages();
        }
      });
    } else {
      let body = {
        following_id: id
      };
      followFriend(body).then((res) => {
        setLoading(false);
        if (res?.status == 200) {
          showNotification('success', 'Mentor followed succesfully');
          fetchDescoverPages();
        }
      });
    }
  };
  /* eslint-disable */
  return (
    <>
      <HeaderLogin state="d-none" />
      <div className="header-lnk-feeds">
        <div className="container">
          <TopTabbings state={'social'} />
        </div>
      </div>

      <section className="feed-sec bg-grey">
        <Tab.Container id="left-tabs-example" defaultActiveKey="first-pages">
          <div className="company-tab-top bg-white mb-3">
            <div className="container">
              <Row className="align-items-center">
                <Col sm={6} xs={7} md={6}>
                  <div className="d-flex align-items-center">
                    <Nav variant="pills">
                      <Nav.Item>
                        <Nav.Link eventKey="first-pages">Pages</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="first">Discover</Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </div>
                </Col>
                <Col sm={6} xs={5} md={6}>
                  <div className="text-end">
                    <Button className="create-group-btn" onClick={handleShow}>
                      + Create Page
                    </Button>
                  </div>
                </Col>
              </Row>
            </div>
          </div>

          <div className="container">
            <Row>
              <Col xs={12} md={9}>
                <Tab.Content>
                  <Tab.Pane eventKey="first-pages">
                    <div className="company-lft-dis mb-3">
                      <div className="company-lst bg-white p-4">
                        <div className="company-hd d-flex align-items-center justify-content-between mb-3">
                          <h4>PAGES YOU MANAGE</h4>
                          {!isManageLoading && (
                            <div
                              className={
                                isActive1 ? 'comp-hd-search blk-search' : 'comp-hd-search'
                              }>
                              <Link onClick={handleClick2} to="#">
                                <i className="fas fa-search"></i>
                              </Link>
                              <Form.Group className="search-card" controlId="formBasicSearch">
                                <Form.Control
                                  type="search"
                                  placeholder="Search Pages"
                                  onChange={(e) => {
                                    handleSearch(e.target.value);
                                  }}
                                />
                              </Form.Group>
                            </div>
                          )}
                        </div>
                        {isManageLoading ? (
                          <div className="company-follow">
                            {Array.from(Array(6), () => {
                              return <CompanySimmer />;
                            })}
                          </div>
                        ) : (
                          <>
                            <div
                              className={
                                isScrollable
                                  ? 'company-follow compnay-card-scroll'
                                  : 'company-follow'
                              }>
                              {managedPages?.length !== 0 ? (
                                managedPages?.map((managePage) => {
                                  return (
                                    <div
                                      className="company-follow-card d-flex justify-content-between"
                                      key={managePage.id}>
                                      <div
                                        className="d-flex align-items-top"
                                        onClick={() =>
                                          disable
                                            ? ''
                                            : handleNavigateToDetailPage(
                                                managePage?.encrypted_id,
                                                managePage
                                              )
                                        }>
                                        <div className="company-icn me-3">
                                          <OverlayTrigger
                                            trigger={['hover', 'focus']}
                                            // target={target.current}
                                            placement="bottom"
                                            delay={{ show: 500, hide: 1500 }}
                                            data={managePage}
                                            popperConfig={{
                                              managePage: managePage
                                            }}
                                            shouldUpdatePosition={true}
                                            overlay={renderPopover}>
                                            <Link to="#">
                                              {' '}
                                              <img
                                                src={managePage?.actual_cover_url}
                                                alt="img"
                                                // ref={target}
                                              />
                                            </Link>
                                          </OverlayTrigger>
                                        </div>
                                        <div className="company-discr">
                                          <h4>
                                            <Link to="#">{managePage?.name}</Link>
                                          </h4>
                                          <p>{managePage?.likes} Likes</p>
                                          <p>{managePage?.category_name}</p>
                                        </div>
                                      </div>

                                      <Dropdown>
                                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                                          <i className="fas fa-ellipsis-v"></i>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                          <Dropdown.Item
                                            target="_blank"
                                            onClick={() => {
                                              navigate(
                                                `/user/pages/messages/${managePage?.encrypted_id}?eventKey=fifth`,
                                                {
                                                  state: managePage?.id
                                                }
                                              );
                                            }}>
                                            Messages
                                          </Dropdown.Item>
                                          <Dropdown.Item
                                            onClick={() => {
                                              navigate(
                                                window
                                                  .open(
                                                    `/user/pages/general_settings/${managePage?.encrypted_id}?eventKey=sixth`,
                                                    {
                                                      state: managePage?.id
                                                    }
                                                  )
                                                  ?.focus()
                                              );
                                            }}>
                                            General Settings
                                          </Dropdown.Item>
                                          <Dropdown.Item
                                            onClick={() => {
                                              navigate(
                                                window
                                                  .open(
                                                    `/user/pages/privacy_settings/${managePage?.encrypted_id}?eventKey=sixth`,
                                                    {
                                                      state: managePage?.id
                                                    }
                                                  )
                                                  ?.focus()
                                              );
                                            }}>
                                            Privacy Settings
                                          </Dropdown.Item>
                                          <Dropdown.Item
                                            onClick={() =>
                                              navigate(
                                                window
                                                  .open(
                                                    `/user/pages/admin_roles/${managePage?.encrypted_id}#/`
                                                  )
                                                  ?.focus()
                                              )
                                            }>
                                            Admin Roles
                                          </Dropdown.Item>
                                          {/* <Dropdown.Item >
                                        <Link to={`/user/setting-privacy?timelineId=${managePage?.id}`} >
                                          Privacy Settings
                                        </Link>
                                      </Dropdown.Item> */}
                                        </Dropdown.Menu>
                                      </Dropdown>
                                    </div>
                                  );
                                })
                              ) : (
                                <div className="w-100">
                                  <NoDataFound text="No pages found!" />
                                </div>
                              )}
                            </div>

                            {managedPages?.length !== 0
                              ? shouldLoadMoreButtonVisible && (
                                  <div className="load-more-btn text-center">
                                    <Button
                                      variant="secondary"
                                      className="read-more"
                                      onClick={handleLoadMore}>
                                      Load More
                                    </Button>
                                  </div>
                                )
                              : ''}
                          </>
                        )}
                      </div>
                    </div>
                    <div className="company-lft-dis">
                      <div className="company-lst bg-white p-4">
                        <div className="company-hd d-flex align-items-center justify-content-between mb-3">
                          <h4>PAGES YOU LIKED</h4>
                          {!loading && (
                            <div
                              className={isActive ? 'comp-hd-search blk-search' : 'comp-hd-search'}>
                              <Link onClick={handleClick} to="#">
                                <i className="fas fa-search"></i>
                              </Link>
                              <Form.Group className="search-card" controlId="formBasicEmail">
                                <Form.Control
                                  type="search"
                                  placeholder="Search Pages"
                                  onChange={(e) => {
                                    fetchLikedPages(e.target.value);
                                  }}
                                />
                              </Form.Group>
                            </div>
                          )}
                        </div>
                        {loading ? (
                          <div className="company-follow">
                            {Array.from(Array(6), () => {
                              return <CompanySimmer />;
                            })}
                          </div>
                        ) : (
                          <>
                            {' '}
                            <div
                              className={
                                isScrollableLikes
                                  ? 'company-follow compnay-card-scroll'
                                  : 'company-follow'
                              }>
                              {likedPages.length !== 0 ? (
                                likedPages?.map((likedPage) => {
                                  return (
                                    <div
                                      className="company-follow-card d-flex justify-content-between"
                                      key={likedPage.id}>
                                      <div
                                        className="d-flex align-items-top"
                                        onClick={() => {
                                          disable
                                            ? ''
                                            : navigate(
                                                window
                                                  .open(
                                                    `/user/page/page-detail/${likedPage.encrypted_id}?#/page-discussion`
                                                  )
                                                  ?.focus()
                                              );
                                        }}>
                                        <div className="company-icn me-3">
                                          <OverlayTrigger
                                            trigger={['hover', 'focus']}
                                            // target={target.current}
                                            placement="auto"
                                            delay={{ show: 500, hide: 1500 }}
                                            data={likedPage}
                                            popperConfig={{
                                              managePage: likedPage
                                            }}
                                            shouldUpdatePosition={true}
                                            overlay={renderPopover}
                                            // onEnter={() => {
                                            //   displayPopOverData(likedPage);
                                            // }}
                                          >
                                            <Link to="#">
                                              <img
                                                src={
                                                  likedPage?.actual_cover_url
                                                    ? likedPage?.actual_cover_url
                                                    : likedPage?.mentor_cover_url
                                                }
                                                alt="discover"
                                              />
                                            </Link>
                                          </OverlayTrigger>
                                        </div>
                                        <div className="company-discr">
                                          <h4>
                                            <Link to="#">{likedPage?.name}</Link>
                                          </h4>
                                          <p>{likedPage?.likes} Likes</p>
                                          <p>{likedPage?.category_name}</p>
                                        </div>
                                      </div>
                                      <Dropdown>
                                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                                          <i className="fas fa-ellipsis-v"></i>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                          <Dropdown.Item
                                            onClick={() => {
                                              navigate(
                                                window
                                                  .open(
                                                    `/user/page/page-detail/${likedPage.encrypted_id}?#/page-discussion`
                                                  )
                                                  ?.focus()
                                              );
                                            }}>
                                            Check Page's Posts
                                          </Dropdown.Item>
                                          <Dropdown.Item
                                            onClick={(e) =>
                                              LikeDisLikePageFunc(
                                                e,
                                                likedPage.id,

                                                'unlike'
                                              )
                                            }>
                                            Unlike Page
                                          </Dropdown.Item>
                                        </Dropdown.Menu>
                                      </Dropdown>
                                    </div>
                                  );
                                })
                              ) : (
                                <div className="w-100">
                                  <NoDataFound text="No Pages found!" />
                                </div>
                              )}
                            </div>
                            {likedPages?.length !== 0
                              ? shouldLoadMoreLikes && (
                                  <div className="load-more-btn text-center">
                                    <Button
                                      variant="secondary"
                                      className="read-more"
                                      onClick={handleLoadMoreLikes}>
                                      Load More
                                    </Button>
                                  </div>
                                )
                              : ''}
                          </>
                        )}
                      </div>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="first" state={'#/page-discussion'}>
                    <div className="company-lst bg-white p-4">
                      <div className="company-hd flex-wrap d-flex align-items-center justify-content-between mb-4">
                        <h4>PAGES SUGGESTIONS</h4>
                      </div>
                      {isLoading ? (
                        <>
                          {' '}
                          <div className="company-follow">
                            {Array.from(Array(6), () => {
                              return <CompanySimmer />;
                            })}
                          </div>
                        </>
                      ) : (
                        <>
                          {' '}
                          <div className="company-follow">
                            {descoverPages?.length > 0 ? (
                              descoverPages?.map((page) => {
                                return (
                                  <div
                                    className="company-follow-card d-flex justify-content-between"
                                    key={page.id}>
                                    <div
                                      className="d-flex align-items-top"
                                      onClick={() => {
                                        disable == false
                                          ? navigate(
                                              `/user/page/page-detail/${page?.encrypted_id}?#/page-discussion`
                                            )
                                          : '';
                                      }}>
                                      <div className="company-icn me-3">
                                        <Link to="#">
                                          <OverlayTrigger
                                            trigger={['hover']}
                                            placement="top"
                                            delay={{ show: 250, hide: 1500 }}
                                            data={page}
                                            popperConfig={{
                                              page
                                            }}
                                            shouldUpdatePosition={true}
                                            overlay={renderPagePopover}>
                                            <img
                                              src={
                                                page?.cover_image?.actual_cover
                                                  ? page?.cover_image?.actual_cover
                                                  : require('../../../assets/images/default-male-avatar.png')
                                              }
                                              alt="img"
                                            />
                                          </OverlayTrigger>
                                        </Link>
                                      </div>

                                      <div className="company-discr">
                                        <h4>
                                          <Link to="#">{truncate(page?.name, 20)}</Link>
                                        </h4>
                                        <p>
                                          {page?.friendcount !== 0 && page?.friendcount ? (
                                            `${page?.friendcount}  friends - `
                                          ) : (
                                            <></>
                                          )}
                                          {page?.likes} Likes
                                        </p>
                                        {page?.friendData && page?.friendData?.length !== 0 ? (
                                          <>
                                            {page?.friendData?.map((data) => {
                                              return (
                                                <OverlayTrigger
                                                  trigger={['hover', 'focus']}
                                                  placement="bottom"
                                                  delay={{ show: 500, hide: 1500 }}
                                                  data={data}
                                                  popperConfig={{
                                                    data
                                                  }}
                                                  shouldUpdatePosition={true}
                                                  overlay={renderUserPopover}>
                                                  <img
                                                    className="media-object-userimage"
                                                    src={
                                                      data?.avatar_url
                                                        ? data?.avatar_url
                                                        : require('../../../assets/images/default-male-avatar.png')
                                                    }
                                                  />
                                                </OverlayTrigger>
                                              );
                                            })}
                                          </>
                                        ) : (
                                          <></>
                                        )}
                                      </div>
                                    </div>
                                    <Button
                                      className="follow-btn-grey"
                                      onClick={(e) => {
                                        LikeDisLikePageFunc(e, page?.id, 'like');
                                        setLikedLoader(true);
                                        setIsClickedId(page?.id);
                                      }}>
                                      {isClikedId == page?.id && likedLoader ? (
                                        <>
                                          <i className="fa fa-spinner fa-spin"></i>
                                        </>
                                      ) : (
                                        <>
                                          {' '}
                                          <i className="fas fa-plus me-1"></i>
                                          Like
                                        </>
                                      )}
                                    </Button>
                                  </div>
                                );
                              })
                            ) : (
                              <NoDataFound text="No more Pages Suggestions at the moment." />
                            )}
                          </div>
                        </>
                      )}
                    </div>
                  </Tab.Pane>
                </Tab.Content>
              </Col>
              <Col xs={12} md={3}>
                <div>
                  <PeopleKnow />
                </div>
                <div>
                  <WorkAnniversary />
                </div>
                <div>
                  <FollowCompany />
                </div>
                <div>
                  <CampusNews />
                </div>
                <div>
                  <CampusnewsText />
                </div>
                <div>
                  <ArticalBlogs />
                </div>
                <div>
                  <WhatsNew />
                </div>
                <div>
                  <Upcoming />
                </div>
                <MiniFooter />
              </Col>
            </Row>
          </div>
        </Tab.Container>
      </section>
      {/* <FooterMain className={'colour-bg'} state={'d-none'} /> */}

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        className="create-group-modal">
        <Modal.Header closeButton>
          <Modal.Title>Create New Page</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="formBasicName">
              <Form.Label>Name :</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter page name"
                onChange={(e) => setPageName(e.target.value)}
                value={pageName}
              />
              <div className={'danger-frm'}>{clicked && validate().pageName}</div>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Category :</Form.Label>
              <Select
                className="basic-single"
                classNamePrefix="select"
                isDisabled={isDisabled}
                isClearable={isClearable}
                isRtl={isRtl}
                isSearchable={isSearchable}
                options={categoryOptions}
                isMulti={false}
                name="colordscs"
                placeholder="Select Category"
                onChange={setSelectedCategory}
                value={selectedCategory}
              />
              <div className={'danger-frm'}>{clicked && validate().category}</div>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicPrivacy">
              <Form.Label>Description :</Form.Label>
              <Form.Control
                as="textarea"
                placeholder="Write about your page..."
                rows={3}
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </Form.Group>
            <div className="text-end">
              <Button variant="primary" type="button" onClick={addPageHandler}>
                Create
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
      {/* {loading ? <Loader /> : ''} */}
    </>
  );
}

export default PageDiscover;
