/**
@copyright  :   ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
@author     :   Shiv Charan Panjeta 
 
All Rights Reserved.
Proprietary and confidential :  All information contained here in is, and remains
the property of ToXSL Technologies Pvt. Ltd. and it's partners.
Unauthorized copying of this file, via any medium is strictly prohibited.
*/
import { useState } from 'react';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
function EventsParticipatePeople({
  handleRemoveInvite,
  type1,
  state,
  location,
  handleView,
  eventPeople
}) {
  const [removeCardDesign, setRemoveCardDesign] = useState(false);
  const [buttonState, setButtonState] = useState(null);

  return (
    <div className="event-discussion bg-white">
      <div>
        <div className="p-3 d-flex justify-content-between">
          <h4>
            <b>
              List of
              {state == 'invited-people'
                ? ' invited '
                : state == 'going-people'
                ? ' going '
                : state == 'interested-people'
                ? ' interested '
                : ` participants in ${location?.state}  `}
              People
            </b>
          </h4>
          <Link onClick={(e) => handleView(e)}>
            <i className="fas fa-search-plus ms-1"></i>&nbsp;&nbsp; View Details
          </Link>
        </div>
        {eventPeople?.length > 0 ? (
          eventPeople?.map((data, index) => {
            return (
              <div
                className={
                  removeCardDesign && buttonState == data?.id
                    ? 'event-details-remove d-flex justify-content-between'
                    : 'event-details d-flex justify-content-between'
                }
                key={index}>
                <div className=" d-flex justify-content-start">
                  {data?.url && (
                    <div className="event-img">
                      <img
                        title={data?.name}
                        src={process.env.REACT_APP_API_URL + data?.url + '.' + data?.extension}
                        alt="list"
                      />
                    </div>
                  )}
                  <p>
                    &nbsp; {data?.name}
                    &nbsp;{data?.designation}
                  </p>
                </div>
                {/* <div>{data?.companyName}</div> */}
                {type1 == 'invited' ? (
                  <div className="d-flex align-item-center justify-content-center">
                    <div>
                      <Button
                        className="btn-outline-primary"
                        onClick={(e) => {
                          handleRemoveInvite(e, data?.id);
                          setButtonState(data?.id);
                          setRemoveCardDesign(true);
                        }}>
                        Remove
                      </Button>
                    </div>
                  </div>
                ) : (
                  ''
                )}
              </div>
            );
          })
        ) : (
          <div>
            <div className="text-center pb-3">
              <img src={require('../../../../assets/images/small-error.png')} alt="img" />
              <h4 className="text-center">
                {state === 'participants-people'
                  ? 'No participants found '
                  : state === 'invited-people'
                  ? 'No one invited for this event '
                  : state === 'interested-people'
                  ? 'No one interested for this event '
                  : state === 'going-people'
                  ? 'No one going for this event '
                  : ''}
                at the moment
              </h4>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
export default EventsParticipatePeople;
