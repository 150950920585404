/** @copyright : ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
 @author     : Shiv Charan Panjeta 

 All Rights Reserved.
 Proprietary and confidential :  All information contained herein is, and remains
 the property of ToXSL Technologies Pvt. Ltd. and its partners.
 Unauthorized copying of this file, via any medium is strictly prohibited.
*/
import moment from 'moment/moment';
import { useEffect, useState } from 'react';
import { Modal, SplitButton } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Dropdown from 'react-bootstrap/Dropdown';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import logo2 from '../../assets/images/almabay-logo-2.png';
import { ZERO } from '../../globals/constants';
import { SK_source, emailRegex, restrictAlpha, truncate } from '../../globals/helper';
import { loginInfo, logout } from '../../redux/action';
import * as APISERVICES from '../../services/AuthServices';
import { addFeedback } from '../../services/AuthServices';
import showNotification from '../../services/NotificationService';
import { viewCartProducts } from '../../services/ShopServices';
import { headerMainSerach, notificationsDropdown } from '../../services/UserServices';
import MessageNotification from './Notification/MessageNotification';
import FriendList from './chat/FriendList';
import HelperClass from './common/HelperClass';
import Loader from './loader/loader-large';
let getId = localStorage.getItem('user_id');

function HeaderLogin(props) {
  let HelperClassObj = new HelperClass();
  const { loading, state } = props;
  const navigate = useNavigate();
  const location = useLocation();
  const dipatch = useDispatch();
  const [friendReqList, setFriendReqList] = useState([]);
  const [messageNotificationList, setMessageNotificationList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [notification, setNotification] = useState([]);
  const userDetail = useSelector((state) => state?.user?.details);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [isSubDomain, setIsSubdomain] = useState(false);
  const [search, setSearch] = useState('');
  const [searchContent, setSearchContent] = useState('');
  const [loadingSpinner, setIsLoadingSpinner] = useState(false);
  const [clicked, setClicked] = useState(false);
  const [totalCartCount, setTotalCartCount] = useState(0);
  useEffect(() => {
    const getData = setTimeout(() => {
      headerSearch();
    }, 500);
    getNotificationsDropDown();
    return () => clearTimeout(getData);
  }, [search]);

  const getNotificationsDropDown = () => {
    setIsLoadingSpinner(true);
    notificationsDropdown().then((resp) => {
      if (resp?.status === 200) {
        setIsLoadingSpinner(false);
        setNotification(resp?.data?.notificationsData);
      }
    });
  };

  const headerSearch = () => {
    setIsLoadingSpinner(true);
    headerMainSerach(search).then((resp) => {
      if (resp?.status === 200) {
        setIsLoadingSpinner(false);
        setSearchContent(resp?.data?.count?.html);
      }
    });
  };

  useEffect(() => {
    setIsSubdomain(HelperClassObj.checkDomain());

    if (userDetail) {
      checkUserToken();
      friendRequest();
    }
  }, [props?.friendReqList]);
  const URL = window.location.host;
  const subDomain = URL?.split('.')[0];

  const checkUserToken = () => {
    APISERVICES.checkLogin().then((res) => {
      if (res?.status === 200) {
        //save data on redux
        dipatch(loginInfo(res?.data?.data));
      } else {
        localStorage.clear();
        dipatch(logout());
        navigate('/login');
      }
    });
  };

  const logOutHandler = (e) => {
    e?.preventDefault();
    APISERVICES.logOut().then((res) => {
      if (res?.status === 200) {
        localStorage.clear();
        dipatch(logout());

        if (isSubDomain) {
          navigate('/institute-login');
        } else {
          navigate('/login');
        }
      }
    });
  };

  const friendRequest = () => {
    APISERVICES.getFriendRequestList().then((res) => {
      if (res?.status === 200) {
        setFriendReqList(res?.data?.data?.friend ?? []);
      }
    });
  };

  const confirmHandler = (id) => {
    APISERVICES.acceptFriendRequest({ follower_id: id }).then((res) => {
      if (res?.status === 200) {
        friendRequest();
        showNotification('success', res?.data?.message);
      }
    });
  };

  const deleteHandler = (id) => {
    if (confirm('Are you sure you want to delete this ?') === true) {
      APISERVICES.rejectFriendRequest({ follower_id: id }).then((res) => {
        if (res?.status === 200) {
          friendRequest();
          showNotification('success', res?.data?.message);
        }
      });
    } else {
      return;
    }
  };

  const SK_openMessageNotifications = () => {
    setIsLoading(true);
    APISERVICES.callAjaxGetMethod(SK_source(), {
      t: 'message',
      a: 'get_messages_notifications',
      recipient_id: 0
    }).then((res) => {
      setIsLoading(false);

      if (res?.status === 200) {
        if (res?.data?.html.length == 0) {
          return;
        } else {
          setMessageNotificationList(res?.data?.html);
        }
      }
    });
  };

  const [addFeedBack, setAddFeedback] = useState({
    name: '',
    email: '',
    subject: 'Share an idea',
    mobile: '',
    message: ''
  });
  const validate = () => {
    let errors = {};
    if (!addFeedBack.name.trim()) {
      errors.name = 'This field is required.';
    }
    if (!addFeedBack.email.trim()) {
      errors.email = 'This field is required.';
    } else if (!emailRegex.test(addFeedBack.email)) {
      errors.email = 'please enter valid email';
    }
    if (!addFeedBack.mobile.trim()) {
      errors.mobile = 'This field is required';
    } else if (addFeedBack.mobile.length <= 9) {
      errors.mobile = 'please enter atleast 10 digit';
    }
    if (!addFeedBack.message.trim()) {
      errors.message = 'This field is required.';
    }
    return errors;
  };

  const handleChange = (sub_domain = '') => {
    if (sub_domain != subDomain) {
      let token = localStorage.getItem('token');
      var oldPath = URL;
      var newPath = oldPath.replace(/^[^.]*/, sub_domain);
      window.location.href = 'http://' + newPath + '/jobs' + '?token=' + token;
    } else {
      return;
    }
  };

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setAddFeedback({ ...addFeedBack, [name]: value });
  };

  const handleSubmit = () => {
    setClicked(true);
    if (!Object.keys(validate()).length) {
      setIsLoading(true);
      setClicked(false);
      addFeedback(addFeedBack).then((res) => {
        showNotification('success', res?.data?.detail);
        setIsLoading(false);
        handleClose();
      });
    }
  };
  useEffect(() => {
    cartProducts();
  }, []);
  const cartProducts = () => {
    viewCartProducts(getId ? getId : '').then((resp) => {
      if (resp?.status == 200) {
        setTotalCartCount(resp?.data?.cartCount);
      } else {
        showNotification('err', resp?.data?.message);
      }
    });
  };

  const redirectUser = (e) => {
    e.preventDefault();
    if (
      userDetail?.feed_section == '' ||
      userDetail?.feed_section == 'social' ||
      userDetail?.feed_section == 'Social'
    ) {
      navigate('/user');
    } else if (
      userDetail?.feed_section == 'professional' ||
      userDetail?.feed_section == 'Professional'
    ) {
      navigate('/jobs');
    } else if (
      userDetail?.feed_section == 'mentorship' ||
      userDetail?.feed_section == 'Mentorship'
    ) {
      navigate('/mentors/qafeeds');
    } else {
      navigate('/jobs');
    }
  };

  const checkOtherDomain = (detail) => {
    let temp = [];
    detail?.map((data) => {
      if (data?.domain_name != null) {
        temp.push(data?.domain_name);
      }
    });
    return temp;
  };

  return (
    <>
      <section className="headcard-wht py-0">
        <div className="container">
          <div className="header-wht-inr">
            <Row className="align-items-center">
              <Col lg={7}>
                <div className="header-login-lft d-flex align-items-center justify-content-center flex-wrap">
                  <div className="head-logo mb-3 mb-sm-0">
                    <Link to="#" onClick={(e) => redirectUser(e)}>
                      <img
                        src={
                          userDetail?.institute_logo
                            ? process.env.REACT_APP_API_DOMAIN +
                              'common/uploads/institute_logo/' +
                              userDetail?.institute_logo
                            : logo2
                        }
                        className="main-logo"
                        alt="logo"
                      />
                    </Link>
                  </div>
                  <div className="login-search ms-lg-4">
                    <Form
                      onSubmit={(e) => {
                        e.preventDefault();
                        headerSearch();
                      }}>
                      <Form.Group className="search-group" controlId="formBasicEmail">
                        <Form.Control
                          type="search"
                          placeholder="Search for people, trends, pages and groups"
                          onChange={(e) => {
                            setSearch(e.target.value);
                          }}
                          // onKeyPress={onKeyPressOnInput}
                          autoComplete="off"
                        />
                        <Button className="search-btn" type="submit">
                          <i className="fas fa-search"></i>
                        </Button>
                      </Form.Group>
                    </Form>
                    {search ? (
                      <div className="form-list">
                        <div className="search-header">Search Results </div>
                        {/* {loadingSpinner ? <Spinner animation="border" variant="warning" /> : ''} */}
                        {searchContent && searchContent?.length ? (
                          searchContent?.map((data, index) => (
                            <div className="search-list-wrapper" key={index}>
                              {data?.tag ? (
                                <p
                                  className="tags cursor"
                                  role="presentation"
                                  onClick={() => {
                                    window.open(
                                      data?.userlink ? data?.userlink : data?.tag_url,
                                      '__blank'
                                    );
                                  }}>
                                  #{data?.tag}
                                </p>
                              ) : (
                                ''
                              )}
                              {data?.title ? (
                                <li className="media">
                                  <a target="__blank" href={data?.userlink} className="">
                                    <div className="media-left">
                                      <img
                                        src={data?.img}
                                        className="me-2"
                                        width="50px"
                                        height="50px"
                                        alt="images"
                                      />
                                    </div>
                                    <div className="media-right">
                                      <h5>{data?.title}</h5>
                                      <p className="mb-0">{data?.other_data}</p>
                                    </div>
                                  </a>
                                </li>
                              ) : (
                                <></>
                              )}
                            </div>
                          ))
                        ) : (
                          <i className={`${search ? 'fas fa-spinner fa-spin' : 'd-none'}`}></i>
                        )}
                        <div className="text-center">
                          <Link className="page-link" to={`/user/search-page?search=${search}`}>
                            See all results
                          </Link>
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </Col>
              <Col lg={5}>
                <div className="feed-login-rt">
                  <ul className="feed-login-lnk">
                    <li className="drop-notifi">
                      <Link className="home-login active" to="/user">
                        <i className="fas fa-home" title="Home"></i>
                      </Link>
                    </li>
                    <li className="drop-notifi">
                      <Dropdown className="notification-drop">
                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                          <i className="fas fa-user-plus" title="Pending requests"></i>
                          {friendReqList?.length !== 0 && (
                            <span className="headr-count">{friendReqList?.length}</span>
                          )}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          {friendReqList &&
                            friendReqList?.slice(0, 3)?.map((data, index) => (
                              <Dropdown.Item href="#/action-1" key={index}>
                                <div className="notifi-dis-card d-flex align-items-center">
                                  <span className="notifi-icn">
                                    <img
                                      src={require('../../assets/images/default-male-avatar.png')}
                                      alt="img"
                                    />
                                  </span>
                                  <div className="notifi-dis">
                                    <span className="time-slot">
                                      {' '}
                                      {moment(data?.timestamp).fromNow()}
                                    </span>
                                    <h4>{data?.name}</h4>
                                    <div className="d-flex align-items-center justify-content-between">
                                      <p>wants to be your friend </p>
                                      <div className="request-accept-btns">
                                        <Button
                                          onClick={() => {
                                            confirmHandler(data?.id);
                                          }}>
                                          <i className="fas fa-check" title="Accept"></i>
                                        </Button>
                                        <Button
                                          onClick={() => {
                                            deleteHandler(data?.id);
                                          }}>
                                          <i className="fas fa-times" title="Decline"></i>
                                        </Button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </Dropdown.Item>
                            ))}

                          <Link className="view-lst-btn" to="/user/requests">
                            <i className="fas fa-list me-2"></i>View all requests
                          </Link>
                        </Dropdown.Menu>
                      </Dropdown>
                    </li>
                    <li className="drop-notifi">
                      <Dropdown className="notification-drop">
                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                          <i
                            role="none"
                            className="fas fa-comments"
                            onClick={() => SK_openMessageNotifications()}
                            title="Messages"></i>
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <MessageNotification
                            messageNotificationList={messageNotificationList}
                            isLoading={isLoading}
                          />

                          <Link className="view-lst-btn" to="/user/messages">
                            <i className="fas fa-list me-2"></i>View all messages
                          </Link>
                        </Dropdown.Menu>
                      </Dropdown>
                    </li>
                    <li className="drop-notifi">
                      <Dropdown className="notification-drop">
                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                          <i className="fas fa-bell" title="Notifications"></i>
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <div className="notificationsContainer">
                            {loadingSpinner ? <i className="fas fa-spinner fa-spin"></i> : ''}
                            {notification && notification?.length > ZERO ? (
                              notification?.map((data, index) => {
                                return (
                                  <Dropdown.Item href="#/action-2" key={index}>
                                    <div className="notifi-dis-card d-flex align-items-center">
                                      <span className="notifi-icn">
                                        <img
                                          src={
                                            data?.user_image
                                              ? data?.user_image
                                              : require('../../assets/images/default-male-avatar.png')
                                          }
                                          alt="img"
                                        />
                                      </span>
                                      <div className="notifi-dis">
                                        <h4 className="font-weight-bold">
                                          <Link to={'#'}>{data?.name}</Link>
                                        </h4>
                                        <Link
                                          to="#"
                                          // to={data?.userLink}
                                        >
                                          <p className="text-dark">
                                            {truncate(data?.notification_text, 50)}
                                          </p>
                                        </Link>
                                        <span className="time-slot">{data?.time}</span>
                                      </div>
                                    </div>
                                  </Dropdown.Item>
                                );
                              })
                            ) : (
                              <p>You don't have any Notification at the moment.</p>
                            )}
                          </div>

                          <Link className="view-lst-btn" to="/user/notifications">
                            <i className="fas fa-list me-2"></i>View all notifications
                          </Link>
                        </Dropdown.Menu>
                      </Dropdown>
                    </li>
                    <li className="drop-notifi user-drop login-lst-drop">
                      <Dropdown>
                        <Dropdown.Toggle
                          id="dropdown-basic"
                          className="d-flex align-items-center text-muted">
                          <span className="user-pro-drop">
                            <img
                              src={process.env.REACT_APP_API_DOMAIN + userDetail?.avtar}
                              className="main-logo"
                              alt="logo"
                              onError={({ currentTarget }) => {
                                currentTarget.onerror = null; // prevents looping
                                currentTarget.src = require('../../assets/images/birth4.png');
                              }}
                            />
                          </span>
                          <span className="ms-3">{truncate(userDetail?.name, 10)}</span>
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <Dropdown.Item as={Link} to={'/shop'}>
                            Alma Store
                          </Dropdown.Item>
                          <Dropdown.Item as={Link} to={'/shop/cart'}>
                            Cart ({totalCartCount})
                          </Dropdown.Item>
                          <Dropdown.Item as={Link} to={'/shop/orders'}>
                            My Orders
                          </Dropdown.Item>
                          <Dropdown.Item as={Link} to={'/user/profile'}>
                            Account Settings
                          </Dropdown.Item>

                          {/* {userDetail?.user_qualification?.length ? <Form.Select
                            aria-label="Default select example"
                            name="subject"
                            defaultValue={subDomain}
                            onChange={(e) => { handleChange(e.target.value) }}
                          >
                            {userDetail?.user_qualification?.map((data, index) => <option key={index} value={data?.domain_name}>{data?.domain_name}</option>)}
                          </Form.Select> : <></>} */}
                          {checkOtherDomain(userDetail?.user_qualification).length > 1 ? (
                            <SplitButton
                              key={'end'}
                              id={`dropdown-button-drop-end`}
                              drop={'end'}
                              variant="light"
                              title={`Switch Account`}>
                              {userDetail?.user_qualification?.map((data, index) =>
                                data?.domain_name != subDomain ? (
                                  <Dropdown.Item
                                    key={index}
                                    onClick={(e) => {
                                      handleChange(e.target.id);
                                    }}
                                    id={data?.domain_name}
                                    eventKey={data?.domain_name}>
                                    {data?.domain_name}
                                  </Dropdown.Item>
                                ) : (
                                  ''
                                )
                              )}
                            </SplitButton>
                          ) : (
                            ''
                          )}

                          <Dropdown.Item
                            href="#"
                            onClick={(e) => {
                              logOutHandler(e);
                            }}>
                            Logout
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </li>
                  </ul>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </section>
      {userDetail && !location.pathname.includes('/user/profile') ? <FriendList /> : ''}
      {loading ? <Loader /> : null}
      {!location.pathname.includes('/user/profile') && (
        <div className={state ? state : 'feedback-card'}>
          <Link to="/invite">Invite Friends</Link>
          <Link to="" onClick={handleShow}>
            Feedback
          </Link>
        </div>
      )}

      <Modal show={show} onHide={handleClose} className="feedback-modal ">
        <Modal.Header closeButton>
          <Modal.Title>Share your feedback</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group as={Row} className="mb-4" controlId="formHorizontalName">
              <Form.Label column sm={3}>
                Your Name<sup className="text-danger">*</sup>
              </Form.Label>
              <Col sm={9}>
                <Form.Control
                  type="text"
                  name="name"
                  // value={addFeedBack?.name}
                  onChange={handleOnChange}
                />
                <div className={`${clicked && validate().name ? '' : 'd-none'} danger-frm `}>
                  {clicked && validate().name}
                </div>
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-4" controlId="formHorizontalEmail">
              <Form.Label column sm={3}>
                Email address <sup className="text-danger">*</sup>
              </Form.Label>

              <Col sm={9}>
                <Form.Control
                  type="email"
                  // value={addFeedBack?.email}
                  placeholder="info@example.com"
                  name="email"
                  onChange={handleOnChange}
                />
                <div className={`${clicked && validate().email ? '' : 'd-none'} danger-frm `}>
                  {clicked && validate().email}
                </div>
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-4" controlId="formHorizontalMobile">
              <Form.Label column sm={3}>
                Mobile No. <sup className="text-danger">*</sup>
              </Form.Label>

              <Col sm={9}>
                <Form.Control
                  type="text"
                  onKeyPress={restrictAlpha}
                  placeholder="XXXXXXXXXX"
                  name="mobile"
                  maxLength={15}
                  onChange={handleOnChange}
                />
                <div className={`${clicked && validate().mobile ? '' : 'd-none'} danger-frm `}>
                  {clicked && validate().mobile}
                </div>
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-4" controlId="formHorizontalName">
              <Form.Label column sm={3}>
                I want to
              </Form.Label>
              <Col sm={9}>
                <Form.Select
                  aria-label="Default select example"
                  name="subject"
                  onChange={handleOnChange}>
                  <option value="Share an idea">Share an idea</option>
                  <option value="Ask a question">Ask a question</option>
                  <option value="Report an issue">Report an issue</option>
                  <option value="Just say Hello">Just say Hello</option>
                </Form.Select>
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-4" controlId="formHorizontalName">
              <Form.Label column sm={3}>
                Message<sup className="text-danger">*</sup>
              </Form.Label>

              <Col sm={9}>
                <Form.Control
                  as="textarea"
                  // placeholder="Your question"
                  style={{ height: '100px' }}
                  name="message"
                  onChange={handleOnChange}
                />
                <div className={`${clicked && validate().message ? '' : 'd-none'} danger-frm `}>
                  {clicked && validate().message}
                </div>
              </Col>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleSubmit}>
            Submit
          </Button>
          {/* <Button variant="primary" onClick={handleClose}>
            Close
          </Button> */}
        </Modal.Footer>
      </Modal>
      {/* {isLoading ? <Loader /> : ''} */}
    </>
  );
}

export default HeaderLogin;
