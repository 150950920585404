import { combineReducers } from 'redux';
import { addMenuListReducer } from './addMenuListReducer';
import adminSidebarPathArray from './adminSidebarPathArray';
import albumManagerReducer from './albumManagerReducer';
import alertReducer from './alertReducer';
import { chapterPostReducer } from './chapterPostReducer';
import { chapterReducer } from './chapterReducer';
import { clusterReducer } from './clusterReducer';
import { communicationListReducer } from './communicationListReducer';
import { deleteMediaFileReducer } from './deleteMediaFileReducer';
import { deleteMenuListReducer } from './deleteMenuListReducer';
import { emojiReducer } from './emojiReducer';
import instituteInfo from './instituteInfo';
import { menuListReducer } from './menuListReducer';
import { sharelinkReducer } from './sharelinkReducer';
import { singleMenuListDetailReducer } from './singleMenuListDetailReducer';
import { userConnectionDataReducer } from './userConnectionDataReducer';
import userReducer from './userDetails';
import { youtubeVideoReducer } from './youtubeVideoReducer';

const rootReducer = combineReducers({
  user: userReducer,
  cluster: clusterReducer,
  alert: alertReducer,
  album: albumManagerReducer,
  insInfo: instituteInfo,
  userConnectionData: userConnectionDataReducer,
  emoji: emojiReducer,
  chapter: chapterReducer,
  chapterPost: chapterPostReducer,
  youtubeVideoList: youtubeVideoReducer,
  shareLink: sharelinkReducer,
  menuList: menuListReducer,
  addMenuList: addMenuListReducer,
  singleMenuListDetail: singleMenuListDetailReducer,
  deleteMenuList: deleteMenuListReducer,
  planList: communicationListReducer,
  adminPaths: adminSidebarPathArray,
  deleteMedia: deleteMediaFileReducer
});

export default rootReducer;
