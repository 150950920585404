import { useEffect, useState } from 'react';
import { Col, Container, Row, Tab, Table, Tabs } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import Loader from '../../components/commoncomponents/loader/loader-large';
import { getSinglelogDetail } from '../../services/AuthServices';

const LogDetail = () => {
  const location = useLocation();
  const { id, cronjob } = location?.state ? location.state : {};
  const [logDetail, setLogDetail] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    /**
     * an IIFE to invoke after effect runs and runs right after invocation
     */
    (async () => {
      try {
        setLoading(true);
        const response = await getSinglelogDetail(id);
        if (response?.status === 200) {
          setLogDetail(response.data?.detail);
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        console.error(error);
      }
    })();
  }, []);

  /**
   * a switch case to display status of the logs
   * @param {*} state_id
   * @returns
   */
  const logStatus = (state_id) => {
    switch (state_id) {
      case '0':
        return 'Pending';
      case '1':
        return 'In-Progress';
      case '2':
        return 'Completed';
      default:
        return;
    }
  };
  return (
    <>
      <div className="dash-main-content">
        <Container fluid>
          <div className="dash-page-content">
            <div className="dash-page-title">
              <Row>
                <Col xs={12} sm={12} md={12}>
                  <div className="dash-title-bar d-flex align-items-center justify-content-between">
                    <div className="title-lft d-flex align-items-center">
                      <div className="dash-icn">
                        <i className="fas fa-suitcase"></i>
                      </div>
                      <div className="dash-title-dis">
                        <h5>Cronjobs</h5>
                        <h5>{/* Total Record(s): <span>{planList?.count}</span> */}</h5>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
            {/** single log detail starts */}

            <div className="dash-content-card">
              <div className="table-responsive">
                <Table className="custom-table-data" striped bordered hover>
                  <tr className="cronjob_detail_table">
                    <th>ID</th>
                    <td>{logDetail?.id}</td>
                  </tr>
                  <tr className="cronjob_detail_table">
                    <th>Cronjob</th>
                    <td>{cronjob}</td>
                  </tr>
                  <tr className="cronjob_detail_table">
                    <th>Scheduled On</th>
                    <td>{logDetail?.scheduled_on}</td>
                  </tr>
                  <tr className="cronjob_detail_table">
                    <th>Executed On</th>
                    <td>{logDetail?.executed_on}</td>
                  </tr>
                  <tr className="cronjob_detail_table">
                    <th>Created On</th>
                    <td>{logDetail?.created_on}</td>
                  </tr>
                </Table>
              </div>
            </div>
            {/** single log detail ends */}
            {/** job log detail starts */}
            <Tabs defaultActiveKey={1} id="uncontrolled-tab-example">
              <Tab eventKey={1} title="Result">
                <div className="dash-content-card" style={{ fontSize: '18px' }}>
                  {logDetail?.result}
                </div>
              </Tab>
              <Tab eventKey={2} title="Activities">
                <div className="dash-content-card">
                  <div className="table-responsive">
                    <Table className="custom-table-data" striped bordered hover>
                      <thead>
                        <tr>
                          <th>ID</th>
                          <th>Sheet Types</th>
                          <th>Download File</th>
                          <th>Percentage</th>
                          <th>State</th>
                          <th>Created On</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr key={logDetail?.cron_data?.id}>
                          <td>{logDetail?.cron_data?.id}</td>
                          <td>{logDetail?.cron_data?.sheet_type}</td>
                          <td>
                            <a href={logDetail?.cron_data?.file_path} download>
                              Download
                            </a>
                          </td>
                          <td>{+logDetail?.cron_data?.percentage + ' %'}</td>
                          <td>{logStatus(logDetail?.cron_data?.state_id)}</td>
                          <td>{logDetail?.cron_data?.created_on}</td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </div>
              </Tab>
            </Tabs>
            {/** job log detail ends */}
          </div>
        </Container>
      </div>
      {loading && <Loader />}
    </>
  );
};

export default LogDetail;
