//  @copyright : ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
// @author     : Shiv Charan Panjeta

// All Rights Reserved.
// Proprietary and confidential :  All information contained herein is, and remains
// the property of ToXSL Technologies Pvt. Ltd. and its partners.
// Unauthorized copying of this file, via any medium is strictly prohibited.

import { useEffect, useState } from 'react';
import { Col, Container, Row, Table } from 'react-bootstrap';
import ReactPaginate from 'react-paginate';
import { useNavigate } from 'react-router-dom';
import useDocumentTitle from '../../globals/useDocumentTitle';
import { getCrashList } from '../../services/AuthServices';

export default function CrashReport() {
  useDocumentTitle('List of Crash Report | AlmaBay');
  const navigate = useNavigate();
  const [crashList, setCrashList] = useState({
    list: [],
    total: null
  });
  const [page, setPage] = useState(1);

  useEffect(() => {
    // using an IIFE to fetch crash list from server
    (async () => {
      const response = await getCrashList();
      if (response?.status === 200) {
        // setting fetched data in local state
        // can use that state data further
        setCrashList((prevState) => ({
          ...prevState,
          list: response.data?.data?.list,
          total: response.data?.data?.totalCount
        }));
      }
    })();
  }, [page]);

  return (
    <div className="dash-main-content">
      <Container fluid>
        <div className="dash-page-content">
          <div className="dash-page-title">
            <Row>
              <Col xs={12} sm={12} md={12}>
                <div className="dash-title-bar d-flex align-items-center justify-content-between">
                  <div className="title-lft d-flex align-items-center">
                    <div className="dash-icn">
                      <i className="fab fa-android"></i>
                    </div>
                    <div className="dash-title-dis">
                      <h5>
                        Crash <span>Listing</span>
                      </h5>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <div className="dash-content-card">
            <div className="table-responsive">
              <Table className="custom-table-data" striped bordered hover>
                <thead>
                  <tr>
                    <th>Id</th>
                    <th>User ID</th>
                    <th>Device Info</th>
                    <th>Crash Info</th>
                    <th>Added On</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {crashList?.list?.length > 0 ? (
                    crashList?.list?.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>{item?.id}</td>
                          <td>{item?.user_id}</td>
                          <td>{item?.device_info}</td>
                          <td>{item?.crash_info}</td>
                          <td>{item?.added_on}</td>
                          <td>
                            <i
                              onClick={() => {
                                navigate(`/admin/app/view/${item?.id}`);
                              }}
                              className="far fa-eye cursor-pointer"></i>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td className="text-center" colSpan={10}>
                        Data not found
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
              {Math.ceil(crashList?.total / 10) > 1 && (
                <div className="react-page d-flex justify-content-center">
                  <ReactPaginate
                    previousLabel={'Prev'}
                    nextLabel={'Next'}
                    activeClassName={'page-link active'}
                    onPageChange={(props) => {
                      setPage(props.selected + 1);
                    }}
                    pageCount={Math.ceil(crashList?.total / 10)}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}
