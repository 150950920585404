import { useEffect, useRef, useState } from 'react';
import { Col, Container, Row, Tab } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams, useLocation } from 'react-router-dom';
import {
  post_publisher_soundcloud_placeholder,
  post_publisher_youtube_placeholder
} from '../../../../globals/constants';
import { useOnClickOutside } from '../../../../globals/helper';
import useDebounce from '../../../../globals/useDebounce';
import useDocumentTitle from '../../../../globals/useDocumentTitle';
import { youtubeVideoAction } from '../../../../redux/action';
import showNotification from '../../../../services/NotificationService';
import * as APISERVICES from '../../../../services/SocialServices';
import AddPollQuestion from '../../user-pages/AddPollQuestion';
import PostBottomMedia from '../PostComponets/PostBottomMedia';
import PostLayout from '../PostComponets/PostLayout.jsx';

function EventDiscussions() {
  /* eslint-disable */
  const ref = useRef();
  const { userId, editId, state } = useParams();
  const dispatch = useDispatch();
  useOnClickOutside(ref, () => setShow(false));
  useDocumentTitle('AlmaBay');
  const isloggedIn = useSelector((state) => state?.user?.isloggedIn);
  const [latestPost, setLatestPost] = useState('');
  const [privacyList, setPrivacyList] = useState('');
  const [checkPost, setCheckPost] = useState([]);
  const [selectedFile, setSelectedFile] = useState([]);
  const [uploadFiles, setUploadFiles] = useState([]);
  const [isSelected, setIsSelected] = useState(false);
  const [isSelectedFile, setIsSelectedFile] = useState(false);
  const [show, setShow] = useState(false);
  const [selectedData, setSelectedData] = useState(['4']);
  const [multiSelectData, setMultiSelectData] = useState([]);
  const [singleSelectName, setSingleSelectName] = useState('Everyone');
  const [multiSelectName, setmultiSelectName] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingState, setLoadingState] = useState('loading');
  const [totalPages, setTotalPages] = useState(1);
  const [pageNum, setPageNum] = useState(1);
  const [emojiList, setEmojiList] = useState([]);
  const [showEmoji, setShowEmoji] = useState(false);
  const [disableInputBox, setDisableInputBox] = useState(false);
  const [sharePost, setSharePost] = useState(false);
  const [soundCloud, setSoundCloud] = useState(false);
  const [youtubeVideoCloud, setYoutubeVideoCloud] = useState(false);
  const [postGoogleMapState, setPostGoogleMapState] = useState(false);
  const [isShowPoll, setIsShowPoll] = useState(false);
  const [postLoading, setPostLoading] = useState(false);
  const [search, setSearch] = useState('');

  const result = useSelector((state) => state?.youtubeVideoList?.videos);
  const location = useLocation();
  const [totalData, setTotalData] = useState('');

  useEffect(() => {
    if (pageNum <= totalPages) {
      getPostListData();
    }
  }, [pageNum]);

  useEffect(() => {
    if (isloggedIn) {
      isPrivacylist();
      getEmojiIcons();
      getPostListData();
    }
  }, []);

  const getPostListData = (isTrue, postId) => {
    setLoading(true);
    if (isTrue !== 'delete' || isTrue !== 'share') {
      setDisableInputBox(true);
    }
    APISERVICES.getSocialEventPost(location?.state, pageNum, 'all').then((res) => {
      if (res?.status === 200) {
        setPostLoading(false);
        setLoading(false);
        setDisableInputBox(false);
        if (isTrue === true) {
          let arra1 = checkPost.map((item) => {
            const item2 = res.data?.data?.stories?.find((i2) => i2.id == item.id);
            return item ? { ...item, ...item2 } : item2;
          });
          setCheckPost(arra1);
          setPostLoading(false);
          setLatestPost('');
        } else {
          setCheckPost(
            pageNum > 1
              ? res?.data?.data?.stories
                ? [...checkPost, ...res?.data?.data?.stories]
                : [...checkPost]
              : res?.data?.data?.stories
          );
          if (isTrue == 'share') {
            let checkPostIDs = checkPost?.map((data) => data?.id);

            let arra1 = checkPost.map((item) => {
              const item2 = res.data?.data?.stories?.find((i2) => i2.id == item.id);
              return item ? { ...item, ...item2 } : item2;
            });
            APISERVICES.getSocialEventPost(location?.state, 1, 'all').then((res2) => {
              let filterData = res2.data?.data?.stories.filter((item) => {
                return !checkPostIDs.includes(item?.id);
              });
              setCheckPost([...filterData, ...arra1]);
            });
          }

          /**
           * this condition or the unshare the post to find filter from the checkpost
           */
          if (isTrue == 'unshare') {
            let arra1 = checkPost
              .map((item) => {
                const item2 = res.data?.data?.stories?.find((i2) => i2.id == item.id);
                return item ? { ...item, ...item2 } : item2;
              })
              ?.filter((data) => !(data?.post_id == postId && data?.via_type == 'share'));
            setCheckPost(arra1);
          }

          if (isTrue == 'makePost') {
            setCheckPost(res?.data?.data?.stories);
          }
        }

        setTotalPages(Math.ceil(res?.data?.data?.total_records / 10));
        setTotalData(res?.data?.data?.total_records);
      } else {
        setLoading(false);
        setDisableInputBox(false);
      }
    });
  };

  const isPrivacylist = () => {
    APISERVICES.postPrivacyList().then((res) => {
      if (res?.status == 200) {
        let arr = [];
        let data = res?.data?.data;
        for (let i in data) {
          arr.push({ id: i, label: data[i], value: data[i] });
        }
        setPrivacyList(arr);
      }
    });
  };

  const checkLatestpost = (e) => {
    e.preventDefault();
    setPostLoading(true);
    if (multiSelectData?.length !== 0 || selectedData.length !== 0) {
      let formData = new FormData();
      formData.append(`text`, latestPost.trim());
      formData.append(`recipient_id`, userId);
      formData.append(`timeline_id`, '');
      formData.append(
        `post_privacy[]`,
        multiSelectData?.length !== 0 ? multiSelectData.toString() : selectedData.toString()
      );
      for (let i in selectedFile) {
        formData.append(`photos[]`, selectedFile[i]);
      }
      for (let i in uploadFiles) {
        formData.append('files[]', uploadFiles[i]);
      }
      APISERVICES.postLatest(formData).then((res) => {
        if (res?.status === 200) {
          // setPostLoading(false);
          showNotification('success', res?.data?.message);
          setSelectedFile([]);
          setUploadFiles([]);
          setIsSelected(false);
          setIsSelectedFile(false);
          setSelectedData(['4']);
          setMultiSelectData([]);
          setmultiSelectName([]);
          setSingleSelectName('Everyone');
          setLatestPost('');
          getPostListData('makePost');
        }
      });
    } else {
      showNotification('danger', 'Please select privacy before publishing post');
    }
  };
  const makePost = (e) => {
    if (latestPost?.trim() !== '' || selectedFile.length !== 0 || uploadFiles.length !== 0) {
      checkLatestpost(e);
    } else {
      showNotification(
        'danger',
        'This status update appears to be blank. Please write something or attach photo to update your status.'
      );
    }
  };

  const onPostListData = () => {
    getPostListData();
  };
  const onIncrementPageNo = () => {
    totalData > 10 && setPageNum((no) => no + 1);
  };

  const getEmojiIcons = () => {
    APISERVICES.getPublisherBox().then((res) => {
      if (res?.status == 200) {
        let arr = [];
        let data = res?.data?.data?.emoticons;
        for (let i in data) {
          arr.push({ id: i, label: data[i], value: data[i] });
        }
        setEmojiList(arr);
      } else {
        setEmojiList([]);
      }
    });
  };

  const newParagraph = (e) => {
    if (e.key === 'Enter') {
    }
  };

  const toggleMediaGroupFun = (chosen_input_selector = '') => {
    setSoundCloud(false);
    setYoutubeVideoCloud(false);
    setPostGoogleMapState(false);

    if (chosen_input_selector == 'soundcloud-wrapper') {
      setSoundCloud(true);
    } else if (chosen_input_selector == 'youtube-wrapper') {
      setYoutubeVideoCloud(!youtubeVideoCloud);
    } else if (chosen_input_selector == 'google-map-wrapper') {
      setPostGoogleMapState(true);
    }
  };

  const debouncedSearchTerm = useDebounce(search, 500);

  useEffect(() => {
    if (debouncedSearchTerm) {
      dispatch(youtubeVideoAction(search));
    } else {
      dispatch(youtubeVideoAction());
    }
  }, [debouncedSearchTerm]);

  const deleteFile = async (post_id, media_id) => {
    const message = window.confirm('Are you sure you want to remove this file?');
    if (message) {
      try {
        const response = await APISERVICES.deleteFileFromPost(post_id, media_id);
        if (response?.status === 200) {
          showNotification('success', response?.data?.message);
          getPostListData();
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  return (
    <Tab.Pane eventKey="second">
      <section className="news-feed-sec bg-grey py-4">
        <Container>
          <Row>
            <Col xs={12} lg={9} xl={12} className="mb-4">
              {loading && loadingState !== '' ? (
                <>
                  {' '}
                  {Array.from(Array(3), (index) => {
                    return <Shimmer key={index} />;
                  })}
                </>
              ) : (
                <>
                  {' '}
                  {!isShowPoll ? (
                    <div className="write-on-wall mb-3">
                      <h4>
                        <i className="fas fa-edit me-2"></i> What's going on?
                      </h4>
                      <textarea
                        rows={5}
                        style={{ width: '100%', resize: 'none' }}
                        placeholder={'Write something... #hashtags'}
                        onChange={(e) => {
                          setLatestPost(e.target.value);
                        }}
                        disabled={postLoading}
                        onKeyDown={(e) => newParagraph(e)}
                        value={latestPost}></textarea>
                      {soundCloud && (
                        <div className="upload-img-detail d-flex align-items-center justify-content-between">
                          <i className="fa fa-music"></i>
                          <input
                            className="soundcloud-input"
                            type="text"
                            autoFocus
                            // onKeyUp={() => SK_searchSoundcloud()}
                            placeholder={post_publisher_soundcloud_placeholder}
                          />
                          {/* <div className="input-result-wrapper"></div> */}
                        </div>
                      )}

                      {youtubeVideoCloud && (
                        <div className="upload-img-detail d-flex align-items-center justify-content-start">
                          <i className="fa fa-film me-2"></i>
                          <input
                            className="youtube-input"
                            type="text"
                            onChange={(e) => {
                              setSearch(e.target.value);
                            }}
                            placeholder={post_publisher_youtube_placeholder}
                          />
                          <div className="input-result-wrapper"></div>
                        </div>
                      )}
                      {postGoogleMapState && (
                        <div className="upload-img-detail d-flex align-items-center justify-content-between">
                          <i className="fa fa-map-marker "></i>
                          <input
                            className="youtube-input"
                            type="text"
                            // onKeyUp={() => SK_searchYoutube()}
                            placeholder={post_publisher_youtube_placeholder}
                          />
                        </div>
                      )}

                      {isSelected && (
                        <div className="upload-img-detail d-flex align-items-center justify-content-between">
                          <p>
                            {selectedFile?.length > 1
                              ? `${selectedFile.length} photo(s) selected`
                              : `${selectedFile.length} photo selected`}
                          </p>
                          <Link
                            to="#"
                            onClick={() => {
                              setIsSelected(false);
                              setIsSelectedFile(false);
                              setSelectedFile([]);
                              setUploadFiles([]);
                            }}>
                            <i className="fas fa-times"></i>
                          </Link>
                        </div>
                      )}

                      {isSelectedFile && (
                        <div className="upload-img-detail d-flex align-items-center justify-content-between">
                          <p>
                            {uploadFiles?.length > 1
                              ? `${uploadFiles.length} file(s) selected`
                              : `${uploadFiles.length} file selected`}
                          </p>
                          <Link
                            to="#"
                            onClick={() => {
                              setIsSelected(false);
                              setIsSelectedFile(false);
                              setSelectedFile([]);
                              setUploadFiles([]);
                            }}>
                            <i className="fas fa-times"></i>
                          </Link>
                        </div>
                      )}
                      {showEmoji && (
                        <div className="upload-img-detail d-flex align-items-center justify-content-between">
                          {emojiList?.length !== 0 && (
                            <>
                              {emojiList?.map((data) => {
                                return (
                                  <Link
                                    to="#"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      setLatestPost(latestPost + data?.id);
                                    }}>
                                    <img src={data?.value} />
                                  </Link>
                                );
                              })}
                            </>
                          )}
                        </div>
                      )}
                      {result.length > 0 && youtubeVideoCloud ? (
                        <div className="input_result_wrapper">
                          {result.map((item, i) => {
                            return (
                              <div className="api_data_wrapper">
                                <table border={0} width={'100%'} cellSpacing={0} cellPadding={0}>
                                  <tbody>
                                    <tr>
                                      <td width={'40%'} align="left" valign="middle">
                                        <img
                                          className="thumbnail"
                                          width={32}
                                          height={32}
                                          src={item.snippet.thumbnails.high.url}
                                          alt="Youtube"
                                        />
                                      </td>
                                      <td align="left" valign="middle">
                                        <div className="name">{item.snippet.title}</div>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            );
                          })}
                        </div>
                      ) : (
                        search != '' && <span>No Result Found</span>
                      )}

                      <div className="write-wall-ftr d-flex align-items-center justify-content-between">
                        <PostBottomMedia
                          setSelectedFile={setSelectedFile}
                          setUploadFiles={setUploadFiles}
                          setIsSelected={setIsSelected}
                          setIsSelectedFile={setIsSelectedFile}
                          state={'event'}
                          setShowEmoji={setShowEmoji}
                          showEmoji={showEmoji}
                          toggleMediaGroup={toggleMediaGroupFun}
                          setIsShowPoll={setIsShowPoll}
                        />
                        <div className="wall-fltr-rght d-flex align-items" ref={ref}>
                          <button
                            style={postLoading ? { cursor: 'not-allowed' } : { cursor: 'pointer' }}
                            disabled={postLoading}
                            className="accnt-btn ms-2"
                            onClick={(e) => {
                              makePost(e);
                            }}>
                            <i className="far fa-arrow-alt-circle-right me-1"></i>
                            {postLoading ? 'Processing' : 'Post'}
                          </button>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <AddPollQuestion
                      setIsShowPoll={setIsShowPoll}
                      getPostListData={getPostListData}
                    />
                  )}
                  <PostLayout
                    isPostLoading={loading}
                    checkPost={checkPost}
                    getPostListData={getPostListData}
                    totalPages={totalPages}
                    onIncrementPageNo={onIncrementPageNo}
                    pageNum={pageNum}
                    onPostListData={onPostListData}
                    setCheckPost={setCheckPost}
                    setLoadingState={setLoadingState}
                    loadingState={loadingState}
                    disableInputBox={disableInputBox}
                    setDisableInputBox={setDisableInputBox}
                    deleteFile={deleteFile}
                  />
                </>
              )}
            </Col>
          </Row>
        </Container>
      </section>
    </Tab.Pane>
  );
}

export default EventDiscussions;

const Shimmer = () => {
  return (
    <div className="pofile-upload-card loading-skeleton profile-upload-loader">
      <div className="profile-upload-sec d-flex align-items-top mb-3">
        <Link to="/user/timeline/undefined">
          <span className="social-profile-icn me-3">
            <img src={require('../../../../assets/images/birth4.png')} alt="img" />
          </span>
        </Link>
        <div className="social-profile-detail d-flex align-items-top justify-content-between ">
          <div className="social-profile-name">
            <h4>
              <Link to="#">
                Coordinator<span>...</span>
              </Link>
              updated his profile picture
            </h4>
            <p>
              <i className="far fa-clock me-2" />
              42 minutes ago
              <i className="fas fa-users ms-2" title="shared with everyone" />
            </p>
          </div>
          <div className="drop-ellipse-actn">
            <div className="ellipse-drop-card ms-3 dropdown">
              <button
                type="button"
                id="dropdown-basic"
                aria-expanded="false"
                className="dropdown-toggle btn btn-success">
                <i className="fas fa-ellipsis-v" />
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="profile-top-dis" />
      <div className="post_img d-flex flex-wrap">
        <Link className="collage_card" id="img1" to="#">
          <img src={require('../../../../assets/images/birth4.png')} alt="img" />
        </Link>
      </div>
      <div className="profile-upload-ftr">
        <div className="profile-likes-count d-flex align-items-center justify-content-between">
          <div className="likes-count">
            <Link to="#">1 Likes</Link>
            <Link to="#">0 Shares</Link>
          </div>
          <div className="cmmnts-count">1 Comments</div>
        </div>
        <div className="profile-like-btns">
          <ul>
            <li>
              <Link title="Like" href="#">
                <i className="fas fa-thumbs-up me-2" />
                Like
              </Link>
            </li>
            <li>
              <Link title="Comments" to="#">
                <i className="fas fa-comments me-2" />
                Comment
              </Link>
            </li>
            <li>
              <Link title="Share" to="#">
                <i className="fas fa-share me-2" />
                Share
              </Link>
            </li>
          </ul>
        </div>
      </div>
      <div className="profile-upload-cmmnt mt-3">
        <div className="profile-cmmnt-lst d-flex align-items-top mb-3">
          <span className="social-profile-icn me-3">
            <img src={require('../../../../assets/images/birth4.png')} alt="img" />
          </span>
          <div className="profile-cmmnt-user ">
            <div className="d-flex align-items-top justify-content-between">
              <Link to="#">Coordinator</Link>
              <div className="profile-cmmnt-time d-flex align-items-center ">
                <span />
                <div className="ellipse-drop-card ms-3 dropdown">
                  <button
                    type="button"
                    id="dropdown-basic"
                    aria-expanded="false"
                    className="dropdown-toggle btn btn-success">
                    <i className="fas fa-ellipsis-v" />
                  </button>
                </div>
              </div>
            </div>
            <div className="profile-cmmnt-dis">
              <p></p>
              <div className="cmmnt-likes d-flex align-items-center">
                <Link to="">
                  <i className="fas fa-thumbs-up me-1" />
                  Like <span>1</span>
                  likes
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
