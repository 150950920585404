import { useEffect, useState } from 'react';
import HeaderInner from '../../commoncomponents/HeaderInner';
import FooterMain from '../../commoncomponents/FooterMain';
import * as APISERVICES from '../../../services/AuthServices';
import useDocumentTitle from '../../../globals/useDocumentTitle';
import Loader from '../../commoncomponents/loader/loader-large';

function PrivacyPolicy() {
  useDocumentTitle(
    'Almabay - Grow professionally with your Almamater. Online Job Search Portal for Alumni. Register to choose mentors and be a mentee!| AlmaBay'
  );
  const [content, setContent] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getPrivacyPolicy('privacy_policy');
  }, []);

  const getPrivacyPolicy = (page = '') => {
    setLoading(true);
    APISERVICES.staticWebPages(page).then((res) => {
      setLoading(false);
      if (res?.status == 200) {
        setContent(res?.data?.data?.page_content);
      }
    });
  };
  return (
    <>
      <HeaderInner />
      <section className="terms-condition privacy-policy py-5">
        <div className="container">
          <div className="terms-condition-inr policy-inr">
            <div className="title-card mb-2">
              <h3 className="title-hd">Privacy Policy</h3>
            </div>
            <span dangerouslySetInnerHTML={{ __html: content }} />
          </div>
        </div>
      </section>
      <FooterMain className={'colour-bg'} />
      {loading ? <Loader /> : ''}
    </>
  );
}

export default PrivacyPolicy;
