import { useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { imageExtension } from '../../../../globals/helper';
import showNotification from '../../../../services/NotificationService';
import HeaderLogin from '../../../commoncomponents/HeaderLogin';
import TopTabbings from '../TopTabbings';
import * as APISERVICES from '../../../../services/UserServices';
import { useDispatch } from 'react-redux';
import { loginInfo } from '../../../../redux/action';
import useDocumentTitle from '../../../../globals/useDocumentTitle';

function AccountSettings() {
  useDocumentTitle('My Account | AlmaBay');
  const navigate = useNavigate();
  const [imagePreview, setImagePreview] = useState('');
  const dispatch = useDispatch();
  const userDetail = useSelector((state) => state?.user?.details);

  const imageChangeHandler = (e) => {
    let file = e?.target?.files[0];
    if (imageExtension.includes(file?.type)) {
      setImagePreview(URL.createObjectURL(e?.target?.files[0]));
      let formData = new FormData();
      formData.append('image', file);
      APISERVICES.updateUserAvatar(formData).then((res) => {
        if (res?.status === 200) {
          showNotification('success', res?.data?.message);
          dispatch(loginInfo(res?.data?.data));
        }
      });
    } else if (!imageExtension.includes(file?.type) && file) {
      showNotification('danger', 'invalid format !');
    }
  };

  const clickHandler = (path) => {
    navigate(path);
  };

  return (
    <>
      <HeaderLogin />
      <TopTabbings state="social" />
      <section className="bg-grey account-settings pt-4 pb-5">
        <Container>
          <Row>
            <Col md={12}>
              <div className="profile_div text-center">
                <div className="d-flex justify-content-center mb-3">
                  <div className="profile_img">
                    {imagePreview ? (
                      <img src={imagePreview} alt="img" />
                    ) : (
                      <img src={process.env.REACT_APP_API_DOMAIN + userDetail?.avtar} alt="img"
                        onError={({ currentTarget }) => {
                          currentTarget.onerror = null; // prevents looping
                          currentTarget.src = require("../../../../assets/images/birth4.png")
                        }}
                      />
                    )}
                    <div className="img_upload">
                      <label htmlFor="upload-profile-img">
                        <i className="fas fa-camera"></i>
                      </label>
                      <input
                        type="file"
                        id="upload-profile-img"
                        className="d-none"
                        accept=".jpg,.jpeg,.png"
                        onChange={(e) => {
                          imageChangeHandler(e);
                        }}
                      />
                    </div>
                  </div>
                </div>
                <p>
                  Welcome,&nbsp;
                  <span>
                    {userDetail?.name
                      ? userDetail?.name
                      : userDetail?.first_name + ' ' + userDetail?.last_name}
                  </span>
                </p>
              </div>
              <div className="text-center hero-title mb-5">
                <h2 className="title">
                  Control, protect, and secure your account, all in one place
                </h2>
                <p>
                  My Account gives you quick access to settings and tools that let you safeguard
                  your data, protect your privacy, and decide how your information can make AlmaBay
                  services work better for you.
                </p>
              </div>
            </Col>
            <Col lg={4} md={12}>
              <div className="p-4 single_card mt-3">
                <div
                  className="d-flex justify-content-between"
                  role="button"
                  tabIndex="0"
                  onClick={() => {
                    clickHandler('/user/profile/basic-details');
                  }}
                >
                  <h2>
                    <span className="account-icon"></span> My Account
                  </h2>
                  <div className="move-icon"></div>
                </div>
                <p>
                  Manage your personal and professional details to make your profile impressive.
                </p>
                <ul className="p-0 custom-nav mb-0">
                  <li>
                    <Link to="/user/profile/basic-details">Basic Details</Link>
                  </li>
                  <li>
                    <Link to="/user/profile/contact-details">Contact Details</Link>
                  </li>
                  <li>
                    <Link to="/user/profile/work-details">Work</Link>
                  </li>
                  <li>
                    <Link to="/user/profile/education-details">Education</Link>
                  </li>
                  <li>
                    <Link to="/user/profile/account-notifications">Notifications</Link>
                  </li>
                </ul>
              </div>
            </Col>
            <Col lg={4} md={12}>
              <div className="p-4 single_card mt-3">
                <div
                  role="button"
                  tabIndex="0"
                  onClick={() => {
                    clickHandler('/user/profile/technical-skills');
                  }}
                  className="d-flex justify-content-between"
                >
                  <h2>
                    <span className="skills-icon"></span> Life Skills
                  </h2>
                  <div className="move-icon"></div>
                </div>
                <p>
                  Manage your skills and career goals to get the most appropriate search results.
                </p>
                <ul className="p-0 custom-nav mb-0">
                  <li>
                    <Link to="/user/profile/technical-skills">Technical Skills</Link>
                  </li>
                  <li>
                    <Link to="/user/profile/business-skills">Business Skills</Link>
                  </li>
                  <li>
                    <Link to="/user/profile/interpersonal-skills">Interpersonal Skills</Link>
                  </li>
                  <li>
                    <Link to="/user/profile/career-goals">Career Goals</Link>
                  </li>
                  <li>
                    <Link to="/user/profile/swot-analysis">Swot Analysis</Link>
                  </li>
                </ul>
              </div>
            </Col>
            <Col lg={4} md={12}>
              <div className="p-4 single_card mt-3">
                <div
                  className="d-flex justify-content-between"
                  role="button"
                  tabIndex="0"
                  onClick={() => {
                    clickHandler('/user/profile/change-password');
                  }}
                >
                  <h2>
                    <span className="privacy-icon"></span> Privacy Settings
                  </h2>
                  <div className="move-icon"></div>
                </div>
                <p>Manage your account access and security settings.</p>
                <ul className="p-0 custom-nav mb-0">
                  <li>
                    <Link to="/user/profile/change-password">Change your Password</Link>
                  </li>
                  <li>
                    <Link to="/user/profile/privacy-settings">Privacy Settings</Link>
                  </li>
                  <li>
                    <Link to="/user/profile/change-invite-code">Change Invite Code</Link>
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}

export default AccountSettings;
