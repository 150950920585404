/**  @copyright : ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
@author     : Shiv Charan Panjeta < shiv@toxsl.com
All Rights Reserved.
Proprietary and confidential :  All information contained herein is, and remains
the property of ToXSL Technologies Pvt. Ltd. and its partners.
Unauthorized copying of this file, via any medium is strictly prohibited.
**/
import { useState } from 'react';
import { Card } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { useDispatch } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import useDocumentTitle from '../../../globals/useDocumentTitle';
import { loginInfo } from '../../../redux/action';
import { instituteLogin } from '../../../services/InstituteServices';
import showNotification from '../../../services/NotificationService';
import Loader from '../../commoncomponents/loader/loader-large';

function InstituteLogin() {
  useDocumentTitle('Login Page');
  const location = useLocation();
  const id = location.state;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loginDetails, setLoginDetails] = useState({
    email: '',
    password: ''
  });
  const [checkCred, setCheckCred] = useState(false);
  const [isRememberMe, setIsRemberMe] = useState(false);
  const [loading, setLoading] = useState(false);

  //validation for email and password
  const validate = (values) => {
    let errors = {};
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    if (!values.email) {
      errors.email = 'Please enter your email';
    } else if (!regex.test(values.email)) {
      errors.email = 'Invalid email format';
    }
    if (!values.password) {
      errors.password = 'Please enter your password';
    } else if (values.password.length < 6) {
      errors.password = 'Password must be more than 6 characters';
    }
    return errors;
  };

  //input-value
  const onChangeHandler = (e) => {
    const { name, value } = e.target;
    setLoginDetails({ ...loginDetails, [name]: value });
  };

  // onClick form submit-api call
  const submitHandler = (e) => {
    // navigate("/institute-login/step/1")
    // return
    e.preventDefault();
    setCheckCred(true);
    if (!validate(loginDetails)?.password && !validate(loginDetails)?.email) {
      setLoading(true);
      instituteLogin(loginDetails).then((res) => {
        setLoading(false);
        if (res?.status === 200) {
          dispatch(loginInfo(res?.data?.data));
          localStorage.setItem('token', res?.data?.access_token);
          localStorage.setItem('userDetail', JSON.stringify(res?.data?.data));
          showNotification('success', res?.data?.message);
          if (id) {
            if (location?.state !== null) {
              navigate(location?.state, { replace: true });
            } else {
              navigate(`/jobs/jobs-details/${id}`);
            }
          } else {
            navigate('/jobs');
          }
        }
      });
    }
  };

  return (
    <>
      <div className="wrappermain_outer">
        <div className="h-100 d-flex justify-content-center align-items-center pt-130 pb-130">
          {/* form start */}
          <Card className="square border d-flex institute register col-md-5 col-lg-4 mx-auto">
            <Card.Body>
              <Card.Title className="loginhead mb-3">Login</Card.Title>
              <div className="inst-login-frm ">
                <Form>
                  <Form.Group className="mb-4" controlId="formBasicEmail">
                    <Form.Control
                      type="email"
                      placeholder="Enter email address"
                      name="email"
                      value={loginDetails?.email}
                      onChange={(e) => {
                        onChangeHandler(e);
                      }}
                      isInvalid={checkCred && validate(loginDetails)?.email}
                      autoComplete="off"
                    />
                    {checkCred && validate(loginDetails)?.email ? (
                      <span
                        className={
                          `${checkCred && validate(loginDetails)?.email}` ? 'danger-frm' : 'd-none'
                        }>
                        {checkCred && validate(loginDetails)?.email}
                      </span>
                    ) : (
                      ''
                    )}
                  </Form.Group>
                  <Form.Group className="mb-4" controlId="formBasicPassword">
                    <Form.Control
                      type="password"
                      placeholder="Password"
                      name="password"
                      onChange={(e) => {
                        onChangeHandler(e);
                      }}
                      isInvalid={checkCred && validate(loginDetails)?.password}
                    />
                    {checkCred && validate(loginDetails)?.password ? (
                      <span
                        className={
                          `${checkCred && validate(loginDetails)?.password}`
                            ? 'danger-frm'
                            : 'd-none'
                        }>
                        {checkCred && validate(loginDetails)?.password}
                      </span>
                    ) : (
                      ''
                    )}
                  </Form.Group>
                  <div className="d-flex align-items-top justify-content-between mb-2">
                    <Form.Group className="mb-3 textblack" controlId="formBasicCheckbox">
                      <Form.Check
                        type="checkbox"
                        label="Remember me"
                        onClick={() => {
                          setIsRemberMe(!isRememberMe);
                        }}
                      />
                    </Form.Group>
                  </div>
                  <Button
                    style={{ width: '100%' }}
                    className={`${
                      checkCred && validate(loginDetails)?.password && validate(loginDetails)?.email
                        ? 'blk'
                        : ' '
                    } instit-login-btn mb-3`}
                    variant="primary"
                    type="button"
                    onClick={submitHandler}>
                    Login
                  </Button>
                  <Link className="forgot-btn mx-auto text-center d-block text-underline" to="#">
                    Forgot Password ?
                  </Link>
                </Form>
              </div>
            </Card.Body>
          </Card>
          {/* end */}
        </div>
      </div>
      {loading ? <Loader /> : <></>}
    </>
  );
}

export default InstituteLogin;
