/**
@copyright : ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
@author     : Shiv Charan Panjeta 
 
All Rights Reserved.
Proprietary and confidential :  All information contained herein is, and remains
the property of ToXSL Technologies Pvt. Ltd. and its partners.
Unauthorized copying of this file, via any medium is strictly prohibited.
*/

import { useEffect, useState } from 'react';
import { Alert, Container } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Table from 'react-bootstrap/Table';
import ReactPaginate from 'react-paginate';
import { useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Loader from '../../components/commoncomponents/loader/loader-large';
import useDocumentTitle from '../../globals/useDocumentTitle';
import * as adminServices from '../../services/AuthServices';

let intervalId;
function ManageAdminUsers() {
  useDocumentTitle('Manage Admin Users | AlmaBay');
  const navigate = useNavigate();
  const isAlert = useSelector((state) => state?.alert);
  const location = useLocation();
  const [currentPage, setCurrentPage] = useState(1);
  const [orderBy, setOrderBy] = useState('DESC');
  const [shortOrderBy, setShortOrderBy] = useState('admin_id');
  const [list, setList] = useState([]);
  const [response, setResponse] = useState('');
  const [loading, setLoading] = useState(false);
  const [chekcedId, setCheckedId] = useState([]);
  const [allId, setAllId] = useState([]);
  const [showalert, setShowAlert] = useState(false);
  const [alert, setAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState('');

  useEffect(() => {
    apiCall();
  }, []);

  useEffect(() => {
    const timeId = setTimeout(() => {
      setAlert(false);
    }, 3000);
    return () => {
      clearTimeout(timeId);
    };
  }, [alert]);

  useEffect(() => {
    if (isAlert?.showAlertBox) {
      setAlertMsg(isAlert?.message);
      setAlert(true);
    }
  }, []);

  const apiCall = (Page, orderBy, shortOrderBy) => {
    setLoading(true);
    adminServices.showAllAdmins(Page ? Page : currentPage, orderBy, shortOrderBy).then((res) => {
      setLoading(false);
      if (res?.status === 200) {
        setList(res?.data?.data);
        setResponse(res?.data?.pager);
        setAllId(res?.data?.data?.map((data) => data?.admin_id));
      }
    });
  };

  const site_url = () => {};

  // ACTIONS

  const selectHandler = (value, id) => {
    switch (value) {
      case 'edit':
        navigate(`/admin/users/add-adminUsers`, { state: id });
        break;
      case 'view':
        //navigate('', { state: id })
        break;
      case '0':
        stateHandler(value, id);
        break;
      case '1':
        stateHandler(value, id);
        break;
      case '2':
        stateHandler(value, id);
        break;
      default:
        break;
    }
  };

  const stateHandler = (value, id) => {
    setLoading(true);

    adminServices.setAdminStatus(id, value).then((res) => {
      if (res?.status === 200) {
        setLoading(false);
        document.getElementById('select-action').value = '';
        if (value == '2' || value == '1' || value == '0') {
          setAlert(true);
          setAlertMsg(res?.data?.message);
        }
        apiCall();
      } else {
        setLoading(false);
      }
    });
  };
  const sortHandler = (e, type) => {
    e.preventDefault();
    let currentSort = orderBy === 'ASC' ? 'DESC' : 'ASC';
    setOrderBy(currentSort);
    setShortOrderBy(type);
    apiCall(currentPage, orderBy, shortOrderBy);
  };
  const handlePageClick = (a) => {
    let page = a?.selected + 1;
    setCurrentPage(page);
    apiCall(page, orderBy, shortOrderBy);
  };

  const handleMultipleCheckBox = (id) => {
    if (chekcedId.includes(id)) {
      let filterId = chekcedId.filter((data) => data !== id);
      setCheckedId(filterId);
    } else {
      setCheckedId([...chekcedId, id]);
    }
  };

  const handleSelectAll = (e) => {
    if (e.target.checked === true) {
      setCheckedId(allId);
    } else {
      setCheckedId([]);
    }
  };
  const globalStateHandler = () => {
    setShowAlert(true);
    intervalId = setInterval(function () {
      setShowAlert(false);
    }, 5000);

    setTimeout(function () {
      clearInterval(intervalId);
    }, 3000);
  };

  const handlePerformAction = (e) => {
    if (chekcedId.length !== 0) {
      setShowAlert(false);
      let body = {
        ids: chekcedId.toString(),
        selectedAction: e.target.value
      };
      let formData = new FormData();
      for (let i in body) {
        formData.append(`${i}`, body[i]);
      }
      adminServices.setMultipleAdminStatus(formData).then((res) => {
        if (res?.status === 200) {
          setAlertMsg(res?.data?.message);
          setAlert(true);
          document.getElementById('select').value = '';
          setCheckedId([]);
          apiCall();
        }
      });
    } else {
      globalStateHandler();
    }
  };

  // END ACTIONS

  return (
    <>
      <div className="dash-main-content">
        <Container fluid>
          <div className="dash-page-content">
            <div className="dash-page-title">
              <Row>
                <Col xs={12} sm={12} md={12}>
                  <div className="dash-title-bar d-flex align-items-center justify-content-between">
                    <div className="title-lft d-flex align-items-center">
                      <div className="dash-icn">
                        <i className="fas fa-building"></i>
                      </div>
                      <div className="dash-title-dis">
                        <h5>
                          Manage <span>Admins</span>
                        </h5>

                        <h5>
                          Total Record(s): <span> {response?.total} </span>
                        </h5>
                      </div>
                    </div>
                    <div className="title-rt-btn d-flex align-items-center">
                      <Link className="add-btn-grn" to="/admin/users/add-adminUsers">
                        Add New Admin
                      </Link>
                      <div className="head-instite-sec ms-4">
                        <Form.Select
                          aria-label="Default select example"
                          id="select"
                          onChange={(e) => {
                            handlePerformAction(e);
                          }}>
                          <option value="">Choose Action</option>
                          <option value="active">Active</option>
                          <option value="Inactive">Inactive</option>
                          <option value="del">Delete</option>
                        </Form.Select>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="dash-content-card">
              <Alert variant={'danger'} className={`${showalert ? '' : 'd-none'}`}>
                Please Select Any Record
              </Alert>
              <Alert variant={'success'} className={`${alert ? '' : 'd-none'}`}>
                {alertMsg}
              </Alert>
              <div className="table-responsive">
                <Table className="custom-table-data" striped bordered hover>
                  <thead>
                    <tr>
                      <th>
                        <input
                          type="checkbox"
                          name="checkall"
                          checked={allId?.length ? allId?.length === chekcedId?.length : false}
                          onChange={handleSelectAll}
                        />
                      </th>

                      <th>
                        <Link
                          to=""
                          onClick={(e) => {
                            sortHandler(e, 'admin_id');
                          }}>
                          Id
                          <img src={require('../../assets/images/sort.png')} alt="sort" />
                        </Link>
                      </th>
                      <th>
                        <Link
                          to=""
                          onClick={(e) => {
                            sortHandler(e, 'username');
                          }}>
                          {' '}
                          Username
                          <img src={require('../../assets/images/sort.png')} alt="sort" />
                        </Link>
                      </th>
                      <th>
                        <Link
                          to=""
                          onClick={(e) => {
                            sortHandler(e, 'admin_name');
                          }}>
                          {' '}
                          Name
                          <img src={require('../../assets/images/sort.png')} alt="sort" />
                        </Link>
                      </th>
                      <th>
                        <Link
                          to=""
                          onClick={(e) => {
                            sortHandler(e, 'admin_type');
                          }}>
                          Type
                          <img src={require('../../assets/images/sort.png')} alt="sort" />
                        </Link>
                      </th>
                      <th>Institutes</th>
                      <th>
                        <Link
                          to=""
                          onClick={(e) => {
                            sortHandler(e, 'admin_status');
                          }}>
                          Status
                          <img src={require('../../assets/images/sort.png')} alt="sort" />
                        </Link>
                      </th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {/* loop */}
                    {list && list?.length ? (
                      list?.map((data, index) => (
                        <tr key={index}>
                          <td>
                            <input
                              type="checkbox"
                              name="ids[]"
                              id={`id${data?.admin_id}`}
                              checked={chekcedId?.includes(data?.admin_id)}
                              onChange={() => handleMultipleCheckBox(data?.admin_id)}
                            />
                          </td>
                          <td> {data?.admin_id} </td>
                          <td> {data?.username} </td>
                          <td> {data?.admin_name} </td>

                          <td>
                            {data?.admin_type == 2
                              ? 'Super'
                              : data?.admin_type != 1
                              ? 'Sub Super'
                              : 'Admin'}
                          </td>
                          <td>{data?.institute_details ? data?.institute_details[0] : 'N/A'}</td>

                          <td> {data?.admin_status == 1 ? 'Active' : 'Inactive'} </td>

                          <td>
                            <Form.Select
                              key={index}
                              aria-label="Default select example"
                              id="select-action"
                              onChange={(e) => {
                                selectHandler(e.target.value, data?.admin_id);
                              }}>
                              <option value="">Action</option>

                              {/* <option value="view">View</option> */}
                              {data?.admin_status === '0' ? (
                                <option value="1">Active</option>
                              ) : (
                                <option value="0">Inactive</option>
                              )}
                              <option value="edit">Edit</option>
                              <option value="2">Delete</option>
                            </Form.Select>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr className="text-center">
                        <td colSpan={9}>Data Not Found</td>{' '}
                      </tr>
                    )}
                  </tbody>
                </Table>
                <div className="react-page d-flex">
                  {response?.total > 10 && (
                    <ReactPaginate
                      previousLabel={currentPage > 0 ? '<' : ''}
                      nextLabel={response.total > 0 ? '>' : ''}
                      onPageChange={handlePageClick}
                      pageCount={response.total / 10}
                      activeClassName={'page-link active'}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>

      {loading ? <Loader /> : ''}
    </>
  );
}

export default ManageAdminUsers;
