/**
@copyright  :   ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
@author     :   Shiv Charan Panjeta 
 
All Rights Reserved.
Proprietary and confidential :  All information contained here in is, and remains
the property of ToXSL Technologies Pvt. Ltd. and it's partners.
Unauthorized copying of this file, via any medium is strictly prohibited.
*/
import { useEffect, useState } from 'react';
import { Alert, Container } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Table from 'react-bootstrap/Table';
import ReactPaginate from 'react-paginate';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import AsyncSelect from 'react-select/async';
import NoDataFound from '../../components/commoncomponents/NoDataFound';
import Loader from '../../components/commoncomponents/loader/loader-large';
import {
  JOB_APPLIED,
  JOB_NON_APPLIED,
  JOB_STATUS_DELETE,
  JOB_STATUS_DISABLE,
  JOB_STATUS_ENABLE
} from '../../globals/constants';
import useDocumentTitle from '../../globals/useDocumentTitle';
import { getJobList, setMultipleJobsStatus } from '../../services/AuthServices';
import showNotification from '../../services/NotificationService';
import { loadAllInstituteOptions } from '../AdminHelper';
import NotAllowed from '../NotAllowed';

export default function ListJobs() {
  useDocumentTitle('Manage Jobs | AlmaBay');
  const components = {
    DropdownIndicator: null
  };
  const [chekcedId, setCheckedId] = useState([]);
  const [allId, setAllId] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [orderBy, setOrderBy] = useState('DESC');
  const [shortOrderBy, setShortOrderBy] = useState('id');
  const [list, setList] = useState([]);
  const [showErrorComponent, setShowErrorComponent] = useState(false);
  const [totalRecords, setTotalRecords] = useState('');
  const [response, setResponse] = useState('');
  const [page, setPage] = useState(1);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [filterState, setFilterState] = useState({
    job_id: '',
    job_title: '',
    job_company_name: '',
    applied: '',
    job_type: '',
    job_status: ''
  });
  const [hiringSearch, setHiringSearch] = useState('');

  const [showalert, setShowAlert] = useState(false);
  const paths = useSelector((state) => state?.adminPaths?.paths);
  const roleType = useSelector((state) => state?.user?.details?.admin_type);

  useEffect(() => {
    window.scrollTo(0, 0);
    apiCall();
    return () => {
      setShowErrorComponent(false);
    };
  }, [page]);

  useEffect(() => {
    const timeId = setTimeout(() => {
      // After 3 seconds set the show value to false
      setShowAlert(false);
    }, 3000);

    return () => {
      clearTimeout(timeId);
    };
  }, [showalert]);

  const apiCall = (sort = 'DESC') => {
    setLoading(true);
    let body = {
      page: page,
      sortfield: shortOrderBy ? shortOrderBy : 'id',
      order: sort,
      job_id: filterState?.job_id,
      job_type: filterState?.job_type,
      job_status: filterState?.job_status,
      job_company_name:
        filterState?.job_company_name !== undefined ? filterState?.job_company_name.trim() : '',
      job_title: filterState?.job_title.trim(),
      applied: filterState?.applied
    };

    getJobList(body).then((res) => {
      setLoading(false);
      if (res?.status === 200) {
        setList(res?.data?.data?.viewlist);
        setTotalRecords(res?.data?.data?.totalRecords);
        setResponse(res?.data?.data?.totalJobs);
        setAllId(res?.data?.data?.viewlist?.map((data) => data?.id));
      }
    });
  };

  const handleFilterChange = (e, defaultName) => {
    if (defaultName == 'job_company_name') {
      setFilterState({
        ...filterState,
        [defaultName]: e
      });
    } else {
      const { name, value } = e.target;
      setFilterState({
        ...filterState,
        [name]: value
      });
    }
  };

  const handleMultipleCheckBox = (id) => {
    if (chekcedId.includes(id)) {
      let filterId = chekcedId.filter((data) => data !== id);
      setCheckedId(filterId);
    } else {
      setCheckedId([...chekcedId, id]);
    }
  };

  const handleSelectAll = (e) => {
    if (e.target.checked === true) {
      setCheckedId(allId);
    } else {
      setCheckedId([]);
    }
  };

  const handlePageClick = (a) => {
    let page = a?.selected + 1;
    setCheckedId([]);
    setCurrentPage(page);
    apiCall(page, orderBy, shortOrderBy);
  };
  const sortHandler = (e, type) => {
    e.preventDefault();
    let currentSort = orderBy === 'ASC' ? 'DESC' : 'ASC';
    setOrderBy(currentSort);
    setShortOrderBy(type);
    apiCall(currentPage, currentSort);
  };

  const globalStateHandler = () => {
    setShowAlert(true);
  };
  const handlePerformAction = (e) => {
    if (chekcedId.length !== 0) {
      setShowAlert(false);
      let body = {
        ids: chekcedId.toString(),
        action: e.target.value
      };
      let formData = new FormData();
      for (let i in body) {
        formData.append(`${i}`, body[i]);
      }
      if (confirm('Are you sure you want to change state ?') === true) {
        setMultipleJobsStatus(formData).then((res) => {
          if (res?.status === 200) {
            // showNotification(res?.data?.detail);
            document.getElementById('global').value = '';
            showNotification('success', res?.data?.message);
            apiCall();
            setCheckedId([]);
          }
        });
      }
    } else {
      globalStateHandler();
    }
  };

  const selectHandler = (value, id) => {
    switch (value) {
      case 'edit':
        navigate(`/admin/jobs/create`, { state: id });
        break;
      case 'view':
        navigate('/admin/jobs/detials', { state: id });
        break;
      default:
        break;
    }
  };

  const resetHandler = (e) => {
    e.preventDefault();
    setFilterState({
      job_id: '',
      job_title: '',
      job_company_name: '',
      applied: '',
      job_type: '',
      job_status: ''
    });
    apiCall();
  };

  const handleClick = () => {
    if (paths?.includes('admin/jobs/create')) {
      navigate('/admin/jobs/create');
    } else {
      setShowErrorComponent(true);
    }
  };

  return showErrorComponent ? (
    <NotAllowed />
  ) : (
    <>
      <div className="dash-main-content">
        <Container fluid>
          <div className="dash-page-content">
            <div className="dash-page-title">
              <Row>
                <Col xs={12} sm={12} md={12}>
                  <div className="dash-title-bar d-flex align-items-center justify-content-between">
                    <div className="title-lft d-flex align-items-center">
                      <div className="dash-icn">
                        <i className="fa fa-users"></i>
                      </div>
                      <div className="dash-title-dis">
                        <h5>
                          Jobs <span>Listing</span>
                        </h5>
                        <h5>
                          Total Record(s): <span>{response}</span>
                        </h5>
                      </div>
                    </div>
                    <div className="title-rt-btn d-flex align-items-center">
                      <button
                        type="button"
                        className="green_button_admin mx-2"
                        onClick={() => {
                          handleClick();
                        }}
                        // to={paths?.includes('admin/jobs/create') ? '/admin/jobs/create' : '/admin'}
                      >
                        Add New Job
                      </button>
                      {/* {roleType == '3' && (
                        <Link className="green_button_admin mx-2" to="/admin/add-banner?id=10">
                          Add Banner
                        </Link>
                      )} */}
                      <div className="head-instite-sec">
                        <Form.Select
                          id="global"
                          aria-label="Default select example"
                          onChange={(e) => {
                            handlePerformAction(e);
                          }}>
                          <option value="">Choose Action</option>
                          <option value={JOB_STATUS_ENABLE}>Enable</option>
                          <option value={JOB_STATUS_DISABLE}>Disable</option>
                          <option value={JOB_STATUS_DELETE}>Closed</option>
                        </Form.Select>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="dash-content-card">
              <Alert variant={'danger'} className={`${showalert ? '' : 'd-none'}`}>
                Please select any record
              </Alert>
              <div className="table-responsive">
                <Table className="custom-table-data" striped bordered hover>
                  <thead>
                    <tr>
                      <th>
                        <Form.Check
                          type="checkbox"
                          id="checkbox"
                          label=""
                          checked={allId?.length ? allId?.length === chekcedId?.length : false}
                          onChange={handleSelectAll}
                        />
                      </th>
                      <th>
                        <Link
                          to=""
                          onClick={(e) => {
                            sortHandler(e, 'id');
                          }}>
                          Id
                          <img src={require('../../assets/images/sort.png')} alt="sort" />
                        </Link>
                      </th>
                      <th>
                        <Link
                          to=""
                          onClick={(e) => {
                            sortHandler(e, 'job_title');
                          }}>
                          Job Title
                          <img src={require('../../assets/images/sort.png')} alt="sort" />
                        </Link>
                      </th>
                      <th>
                        <Link to="">Company</Link>
                      </th>
                      <th className="field-width">
                        <Link to="">Applicants</Link>
                      </th>
                      <th>
                        <Link to="">Posted By</Link>
                      </th>
                      <th>
                        <Link to="">Posted On</Link>
                      </th>
                      <th className="field-width">
                        <Link to="">Job Type</Link>
                      </th>
                      <th className="field-width">Status</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="search-table">
                      <td></td>
                      <td>
                        <Form.Control
                          name="job_id"
                          type="text"
                          placeholder="Id"
                          onChange={handleFilterChange}
                        />
                      </td>
                      <td>
                        <Form.Control
                          name="job_title"
                          type="text"
                          placeholder="job title"
                          onChange={handleFilterChange}
                        />
                      </td>
                      <td>
                        <AsyncSelect
                          isClearable
                          components={components}
                          menuIsOpen={hiringSearch !== '' ? true : false}
                          getOptionLabel={(e) => e.label}
                          getOptionValue={(e) => e.value}
                          loadOptions={loadAllInstituteOptions}
                          onInputChange={(newValue) => setHiringSearch(newValue)}
                          className="basic-single"
                          name="job_company_name"
                          onChange={(e) => handleFilterChange(e?.id, 'job_company_name')}
                          placeholder="company"
                        />
                        {/* <Form.Control name="job_company_name" type="email" placeholder="company" /> */}
                      </td>
                      <td>
                        <Form.Select
                          aria-label="Default select example"
                          name="applied"
                          onChange={handleFilterChange}>
                          <option value="">Select Jobs</option>
                          <option value={JOB_APPLIED}>Applied </option>
                          <option value={JOB_NON_APPLIED}>Non Applied</option>
                        </Form.Select>
                      </td>
                      <td></td>
                      <td></td>
                      <td>
                        <Form.Select
                          aria-label="Default select example"
                          name="job_type"
                          onChange={handleFilterChange}>
                          <option value="">Select Job title</option>
                          <option value="0">Full time </option>
                          <option value="1">Part Time</option>
                          <option value="2">Internship</option>
                        </Form.Select>
                      </td>
                      <td>
                        <Form.Select
                          aria-label="Default select example"
                          name="job_status"
                          onChange={handleFilterChange}>
                          <option value="">Select Status</option>
                          <option value="1"> Enabled </option>
                          <option value="0"> Disable </option>
                          <option value="2"> Close Job </option>
                          <option value="3"> Expire Job </option>paths?.includes
                        </Form.Select>
                      </td>
                      <td className="serac-btns" colSpan={2}>
                        <button
                          className="green_button_admin"
                          type="button"
                          onClick={() => {
                            setPage(1);
                            apiCall();
                          }}>
                          Search
                        </button>
                        {/* <Button
                          className="main-btn-red ms-2"
                          onClick={(e) => {
                            resetHandler(e);
                          }}>
                          Reset
                        </Button> */}
                      </td>
                    </tr>
                    {list && list.length !== 0 ? (
                      <>
                        {list?.map((data, index) => {
                          return (
                            <tr key={index}>
                              <td>
                                <Form.Check
                                  type="checkbox"
                                  label=""
                                  id={`id${data?.id}`}
                                  checked={chekcedId?.includes(data?.id)}
                                  onChange={() => handleMultipleCheckBox(data?.id)}
                                />
                              </td>
                              <td>{data?.id}</td>
                              <td>{data?.job_title} </td>
                              <td>{data?.name} </td>
                              <td>
                                <span className={data?.applyCount == 0 ? '' : 'red-color'}>
                                  {data?.applyCount} Applicants
                                </span>
                              </td>
                              <td>{data?.username}</td>
                              <td>{data?.date_time}</td>
                              <td>
                                {data?.job_type == '0'
                                  ? 'Full Time'
                                  : data?.job_type == '1'
                                  ? 'Part Time'
                                  : 'Internship'}{' '}
                              </td>
                              {/* 0-disable 1-enable 2-closed, 3-expire	 */}
                              <td>
                                {' '}
                                {data?.status == '0'
                                  ? 'Disabled'
                                  : data?.status == '1'
                                  ? 'Enabled'
                                  : data?.status == '2'
                                  ? 'Closed'
                                  : 'Expire'}
                              </td>

                              <td>
                                <Form.Select
                                  key={index}
                                  aria-label="Default select example"
                                  id="select"
                                  onChange={(e) => {
                                    selectHandler(e.target.value, data?.id);
                                  }}>
                                  <option value="">Action</option>
                                  {paths?.includes('admin/jobs/create') && (
                                    <option value="edit">Edit</option>
                                  )}
                                  <option value="view">View</option>
                                </Form.Select>
                              </td>
                            </tr>
                          );
                        })}{' '}
                      </>
                    ) : (
                      <tr>
                        <td colSpan={10}>
                          <NoDataFound text="No Jobs Found" />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
                <div className="react-page d-flex">
                  {/* {response > 10 ? (
                    <ReactPaginate
                      previousLabel={currentPage > 0 ? '<' : ''}
                      nextLabel=">"
                      onPageChange={handlePageClick}
                      pageCount={response / 10}
                      activeClassName={'page-link active'}
                      // forcePage={currentPage}
                    />
                  ) : (
                    ''
                  )} */}
                  {Math.ceil(response / 10) > 1 && (
                    <div className="react-page d-flex justify-content-center">
                      <ReactPaginate
                        previousLabel={'Prev'}
                        nextLabel={'Next'}
                        activeClassName={'page-link active'}
                        onPageChange={(props) => {
                          setPage(props.selected + 1);
                        }}
                        pageCount={Math.ceil(response / 10)}
                        forcePage={parseInt(page) - 1}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
      {loading ? <Loader /> : ''}
    </>
  );
}
