/**
@copyright  :   ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
@author     :   Shiv Charan Panjeta 
 
All Rights Reserved.
Proprietary and confidential :  All information contained here in is, and remains
the property of ToXSL Technologies Pvt. Ltd. and it's partners.
Unauthorized copying of this file, via any medium is strictly prohibited.
*/

import React, { useEffect, useState } from 'react';
import { Col, Container, Form, Row } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import { editSubMenu } from '../../../services/AuthServices';
import showNotification from '../../../services/NotificationService';

const EditChildMenuList = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [name, setName] = useState('');

  useEffect(() => {
    if (location?.state?.menu) {
      setName(location?.state?.menu?.name);
    }
  }, []);

  const submitHandler = async (e) => {
    e.preventDefault();
    let body = {
      menu_id: location?.state?.menu?.menu_id,
      name: name,
      parent: location?.state?.parentData?.menu_id
    };
    try {
      const response = await editSubMenu(body);
      if (response.status === 200) {
        showNotification('success', response?.data?.message);
        navigate(`/admin/child-menu-list/${location?.state?.parentData?.menu_id}`);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="dash-main-content">
      <Container fluid>
        <div className="dash-page-content">
          <div className="dash-page-title">
            <Row>
              <Col xs={12} sm={12} md={12}>
                <div className="dash-title-bar d-flex align-items-center justify-content-between">
                  <div className="title-lft d-flex align-items-center">
                    <div className="dash-icn">
                      <i className="fas fa-bars"></i>
                    </div>
                    <div className="dash-title-dis">
                      <h5>Edit Child Menu</h5>
                      <h5>{/* Total User(s): <span>{response}</span> */}</h5>
                    </div>
                    {/* <div className="title-rt-btn d-flex align-items-center">
                      <Link className="green_button_admin mx-2" to="/admin/add-open-page-content">
                        Add Content
                      </Link>
                      <Link className="green_button_admin mx-2" to="/admin/add-menu-list">
                        Add Menu
                      </Link>
                    </div> */}
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <div className="dash-content-card">
            <div className="cmmn-form">
              <Form>
                <Row>
                  <Col xs={12} md={6} lg={6}>
                    <Form.Group className="mb-3" controlId="formBasicPassword">
                      <Form.Label>
                        Parent Menu <span className="text-danger">*</span>
                      </Form.Label>

                      <Form.Control
                        type="text"
                        disabled
                        aria-label="Default select example"
                        value={location.state?.parentData?.name}></Form.Control>
                    </Form.Group>
                  </Col>
                  <Col xs={12} md={6} lg={6}>
                    <Form.Group className="mb-3" controlId="formBasicPassword">
                      <Form.Label>
                        Name <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        value={name}
                        name={'name'}
                        onChange={(e) => setName(e.target.value)}
                        type="text"
                        placeholder="Enter menu name"
                        autoComplete="Off"
                      />
                    </Form.Group>
                  </Col>
                </Row>
                {location?.state && (
                  <button
                    className="green_button_admin"
                    type="button"
                    onClick={(e) => {
                      submitHandler(e);
                    }}>
                    Update
                  </button>
                )}
              </Form>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default EditChildMenuList;
