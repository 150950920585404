import { ALL_CHAPTER_LIST, CHAPTER_LIST, RESET_CHAPTER_LIST } from '../action';

const initalState = {
  chapterList: [],
  chapterCount: 0,
  isEmpty: true,
  count: null
};

export const chapterReducer = (state = initalState, action) => {
  switch (action.type) {
    case CHAPTER_LIST:
      return {
        ...state,
        chapterList: action.payload?.jobList,
        chapterCount: action.payload?.total,
        isEmpty: false
      };

    case RESET_CHAPTER_LIST:
      return {
        ...state,
        chapterList: [],
        chapterCount: 0,
        isEmpty: true
      };

    case ALL_CHAPTER_LIST:
      return {
        ...state,
        count: action?.payload?.reduce((acc, curr) => {
          return acc + +curr?.count;
        }, 0)
      };
    default:
      return state;
  }
};
