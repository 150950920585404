import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { useState } from 'react';
import showNotification from '../../../services/NotificationService';
import * as APISERVICE from '../../../services/MentorArticlesServices';
import TinyMceEditor from '../../commoncomponents/TinyMceEditor';

function AskQuestionModal({ handleClose2, show2, mentorId, setMentorId }) {
  const [detail, setDetail] = useState({
    title: '',
    description: ''
  });

  const [toSendAttach, setToSendAttch] = useState([]);
  const [fileName, setFileName] = useState([]);
  const [isClicked, setIsClicked] = useState(false);
  const [loading, setLoading] = useState(false);

  const onChangeHandler = (e, select = false, name = '', data = '') => {
    if (select) {
      setDetail({ ...detail, [name]: data });
    } else {
      const { name, value, type } = e.target;
      if (type === 'file') {
        setFileName(e.target.files[0]);
        let fileData = e.target.files;
        for (let i = 0; i < fileData.length; i++) {
          toSendAttach.push(fileData[i]);
        }
        setToSendAttch(toSendAttach);
      } else {
        setDetail({ ...detail, [name]: value });
      }
    }
  };

  const validate = (value) => {
    let errors = {};
    if (!value.title.trim()) {
      errors.title = 'Question title is required field';
    } else if (value.title.trim().length < 5) {
      errors.title = 'Title must contain 6 character';
    }
    // if (!value.description) {
    //   errors.description = 'This field is required';
    // }
    return errors;
  };

  const submitHandler = (e) => {
    e.preventDefault();
    setIsClicked(true);

    if (!Object.keys(validate(detail)).length) {
      setLoading(true);
      let formData = new FormData();
      for (let i in detail) {
        formData.append(`${i}`, detail[i]);
      }
      formData.append(`mentor_id`, mentorId);
      formData.append(`content_type`, 'question');

      for (let i in toSendAttach) {
        formData.append(`qdocs[]`, toSendAttach[i]);
      }
      APISERVICE.askAQuestion(formData).then((res) => {
        setLoading(false);
        if (res?.status === 200) {
          showNotification('success', res?.data?.message);
          handleClose2();
          setMentorId && setMentorId('');
          setDetail({
            title: '',
            description: ''
          });
        }
      });
    }
  };

  const handleCloseModal = () => {
    handleClose2();
    setDetail({
      title: '',
      description: ''
    });
  };

  return (
    <>
      <Modal show={show2} onHide={handleCloseModal} className="event-add-modal">
        <Modal.Header closeButton>
          Send your question to your mentor. | Be specific while sending question and get instant
          response.
        </Modal.Header>
        <Modal.Body>
          <div className="write-article-frm bg-white p-3 mb-2">
            <h4 className="mb-3">{'Ask Mentor'}</h4>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Control
                type="text"
                value={detail?.title}
                name="title"
                onChange={onChangeHandler}
                placeholder="Enter your question here"
              />
              <div
                className={`${isClicked && validate(detail)?.title ? '' : 'd-none'} danger-frm `}
              >
                {isClicked && validate(detail)?.title}
              </div>
            </Form.Group>

            <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
              <TinyMceEditor
                onChangeHandler={onChangeHandler}
                description={detail?.description}
                placeholder="Description"
              />
              <div
                className={`${
                  isClicked && validate(detail)?.description ? '' : 'd-none'
                } danger-frm `}
              >
                {isClicked && validate(detail)?.description}
              </div>
            </Form.Group>

            <div className="attach-card-inr d-flex justify-content-between mb-3">
              <div>
                <div>
                  <Form.Group controlId="formFile" className="attach-file-card">
                    <Form.Control
                      type="file"
                      name="attach_files"
                      onChange={onChangeHandler}
                      accept=".png,.jpg,.jpeg"
                      multiple={true}
                    />
                    <span className="attach-file-inr">
                      <i className="fas fa-paperclip me-2"></i>
                      Attach Files
                    </span>
                  </Form.Group>
                </div>
                <span className="attach-file-name">{fileName?.name}</span>
              </div>
            </div>

            <div className="cmmnt-btn text-end mt-3 px-2 pt-3">
              <button
                className={`${!loading ? 'accnt-btn' : 'accnt-btn blk'}`}
                type="button"
                onClick={(e) => {
                  loading ? '' : submitHandler(e);
                }}
              >
                Submit Question
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default AskQuestionModal;
