import { useState } from 'react';
import { Button } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import * as APISERVICE from '../../services/AuthServices';
import showNotification from '../../services/NotificationService';
import { useDispatch } from 'react-redux';
import { loginInfo } from '../../redux/action/index';
import { emailRegex } from '../../globals/helper';
import withErrorBoundary from './withErrorBoundary';

function HeaderMain() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loginDetails, setLoginDetails] = useState({
    email: '',
    password: ''
  });
  const [checkCred, setCheckCred] = useState(false);
  //input-value
  const onChangeHandler = (e) => {
    const { name, value } = e.target;
    setLoginDetails({ ...loginDetails, [name]: value });
  };

  const validate = (values) => {
    let errors = {};
    if (!values.email) {
      errors.email = 'Please enter your email';
    } else if (!emailRegex.test(values.email)) {
      errors.email = 'Please enter valid email';
    }
    if (!values.password) {
      errors.password = 'Please enter your password';
    } else if (values.password.length < 6) {
      errors.password = 'Password must be more than 6 characters';
    }
    return errors;
  };

  // onClick form submit-api call
  const submitHandler = (e) => {
    e.preventDefault();
    setCheckCred(true);
    if (!validate(loginDetails)?.password && !validate(loginDetails)?.email) {
      let formData = new FormData();
      for (let i in loginDetails) {
        formData.append(`${i}`, loginDetails[i]);
      }
      APISERVICE.Login(formData).then((res) => {
        if (res?.status === 200) {
          dispatch(loginInfo(res?.data?.data));
          localStorage.setItem('token', res?.data?.access_token);
          localStorage.setItem('userDetail', JSON.stringify(res?.data?.data));
          showNotification('success', res?.data?.message);
          if (res?.data?.data?.feed_section == 'professional') {
            navigate('/jobs', { replace: true });
            return;
            // window.location = '/jobs';
          } else if (
            res?.data?.data?.feed_section == '' ||
            res?.data?.data?.feed_section == 'social'
          ) {
            navigate('/user', { replace: true });
          } else if (res?.data?.data?.feed_section == 'mentorship') {
            navigate('/mentors/qafeeds', { replace: true });
          }
        }
      });
    }
  };

  return (
    <>
      <section className="header-card-outr py-4 py-sm-2">
        <div className="container">
          <Row className="d-flex align-items-center">
            <Col lg={5}>
              <div className="head-lft-lnks">
                <ul>
                  <li>
                    <Link to="/jobs/search">Browse Jobs</Link>
                  </li>
                  <li>
                    <Link to="/advancesearch/directory/A">Find institute</Link>
                  </li>
                  <li>
                    <Link to="/shop">Alma Store</Link>
                  </li>
                  <li>
                    <a href="https://blog.almabay.com/" rel="noreferrer" target={'_blank'}>
                      Blog
                    </a>
                  </li>
                </ul>
              </div>
            </Col>
            <Col lg={7}>
              <div className="head-rt-lgn align-items-center d-none d-sm-flex justify-content-end">
                <div className="head-rt-frm">
                  <Form className="d-flex align-items-center" onSubmit={submitHandler}>
                    <Form.Group className="form-grp">
                      <Form.Label>Email address</Form.Label>
                      <Form.Control
                        type="email"
                        onChange={(e) => {
                          onChangeHandler(e);
                        }}
                        name="email"
                        autoComplete="off"
                      />
                      {checkCred && validate(loginDetails)?.email ? (
                        <span>{validate(loginDetails)?.email}</span>
                      ) : (
                        <></>
                      )}
                      <div className="remeber-chk d-flex align-items-center">
                        <Form.Check type="checkbox" id="checkbox" />
                        <Form.Label className="ms-2" htmlFor="checkbox">
                          Remember me
                        </Form.Label>
                      </div>
                    </Form.Group>

                    <Form.Group className="form-grp">
                      <Form.Label>Password</Form.Label>
                      <Form.Control
                        type="password"
                        onChange={(e) => {
                          onChangeHandler(e);
                        }}
                        name="password"
                      />
                      {checkCred && validate(loginDetails)?.password ? (
                        <span>{validate(loginDetails)?.password}</span>
                      ) : (
                        <></>
                      )}
                      <Link to="/recover/password">Forgot password?</Link>
                    </Form.Group>
                    <Button
                      className="lgn-btn"
                      type="submit"
                      onClick={(e) => {
                        submitHandler(e);
                      }}
                    >
                      Log In
                    </Button>
                  </Form>
                </div>
                {/* <div className="or-card">
                  <img
                    className="media-object"
                    src={require('../../assets/images/top-row-sep.png')}
                    alt=""
                  />
                </div>
                <div className="mb-4">
                  <div className="head-social">
                    <span className="label-title">Login using:</span>
                    <ul>
                      <li>
                        <Link className="facebook" to="#">
                          <i className="img-icon-facebook-login"></i>
                        </Link>
                      </li>
                      <li>
                        <Link className="google" to="#">
                          <i className="img-icon-gplus-login"></i>
                        </Link>
                      </li>
                      <li>
                        <Link className="linkedin" to="#">
                          <i className="img-icon-linkedin-login"></i>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div> */}
              </div>
            </Col>
          </Row>
        </div>
      </section>
    </>
  );
}

export default withErrorBoundary(HeaderMain);
