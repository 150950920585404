/** @copyright : ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
 @author     : Shiv Charan Panjeta 

 All Rights Reserved.
 Proprietary and confidential :  All information contained herein is, and remains
 the property of ToXSL Technologies Pvt. Ltd. and its partners.
 Unauthorized copying of this file, via any medium is strictly prohibited.
*/
import { useEffect } from 'react';
import { useState } from 'react';
import Select from 'react-select';
import * as APISERVICES from '../../../services/UserServices';
import { createSelectDropDownWithOutLogo } from '../../../globals/helper';

function ProficiencyDrop(props) {
  const { setState, state, isEdit } = props;
  const [marksOption, setMarksOption] = useState([]);

  useEffect(() => {
    getDropDownList();
  }, []);

  const getDropDownList = () => {
    APISERVICES.getProficiencyDrop().then((res) => {
      if (res?.status === 200) {
        let data = res?.data?.data;
        setMarksOption(createSelectDropDownWithOutLogo(data));
      }
    });
  };
  return (
    <>
      {isEdit ? (
        <Select
          className="basic-single"
          placeholder="Select proficiency"
          classNamePrefix="select"
          isSearchable={true}
          isClearable={true}
          name="color"
          defaultValue={state}
          options={marksOption}
          onChange={setState}
        />
      ) : (
        <Select
          className="basic-single"
          placeholder="Select proficiency"
          classNamePrefix="select"
          isSearchable={true}
          isClearable={true}
          name="color"
          options={marksOption}
          onChange={setState}
        />
      )}
    </>
  );
}

export default ProficiencyDrop;
