/**
@copyright  :   ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
@author     :   Shiv Charan Panjeta 
 
All Rights Reserved.
Proprietary and confidential :  All information contained here in is, and remains
the property of ToXSL Technologies Pvt. Ltd. and it's partners.
Unauthorized copying of this file, via any medium is strictly prohibited.
**/
import { useEffect, useState } from 'react';
import { Button, Container } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { useNavigate, useSearchParams } from 'react-router-dom';
import AsyncSelect from 'react-select/async';
import defaultUser from '../../assets/images/default-male-avatar.png';
import CKEditorComponent from '../../components/webpages/user-pages/PostComponets/CKEditorComponent';
import useDocumentTitle from '../../globals/useDocumentTitle';
import {
  addCompanyFromAdmin,
  getAdminList,
  getCompaniesCityDropDown,
  getCompanyCompanyDetail,
  getCompanySpecialityDropDown,
  updateCompanyFromAdmin
} from '../../services/AuthServices';
import showNotification from '../../services/NotificationService';
import {
  isValidURL,
  loadCompanySpecialitiesOptions,
  loadMainCompanyIndustries
} from '../AdminHelper';

export default function AddCompany() {
  useDocumentTitle('Add New Company | AlmaBay');
  const navigate = useNavigate();

  const [values, setValues] = useState({
    name: '',
    logo_img: '',
    description: '',
    companyType: '',
    companySize: '',
    companyWebsiteUrl: '',
    yearFounded: '',
    companyAddress: '',
    city: '',
    pinCode: '',
    company_page: '0',
    timeline_id: '0',
    company_slug: '',
    organisation_page_admin: '',
    promote: '0',
    audience_type: '0'
  });
  const [touched, setTouched] = useState({
    name: false,
    logoImage: false,
    description: false,
    companyType: false,
    companySize: false,
    companyWebsiteUrl: false,
    mainCompanyIndustry: false,
    companyFunctionalAreas: false,
    yearFounded: false,
    companyAddress: false,
    city: false,
    pinCode: false,
    company_page: false,
    company_slug: false,
    organisation_page_admin: false,
    promote: false,
    audience_type: false
  });

  const [isFirstTimeClicked, setIsFirstTimeClicked] = useState(false);
  const [locationSearch, setLocationSearch] = useState('');
  const [jobLocation, setJobLocation] = useState('');
  const [mainIndustrySearch, setMainIndustrySearch] = useState('');
  const [mainIndustry, setMainIndustry] = useState('');
  const [companySpecialTies, setCompanySpecialTies] = useState([]);
  const [companySpecialtiesSearch, setCompanySpecialtiesSearch] = useState('');
  const [description, setDescription] = useState('');
  const [mode, setMode] = useState('ADD');
  const [imageType, setImageType] = useState('');
  const [adminIds, setAdminIds] = useState([]);
  const [companyImage, setCompanyImage] = useState('');
  const [inputMessage, setInputMessage] = useState('Please enter atleast 2 characters');
  const [loadOpt, setLoadOpt] = useState(false);
  let yearOptions = [];
  for (let i = new Date().getFullYear(); i >= 1900; i--) {
    yearOptions.push(i);
  }
  const handleChange = (e) => {
    setValues({
      ...values,
      [e.target.name]: e.target.value
    });
  };

  const validation = (values) => {
    // if (ind == index) {
    let errrObj = {};
    const image_extension = ['image/jpg', 'image/jpeg', 'image/png', 'image/gif'];
    if (companyImage == '' && !image_extension.includes(imageType?.type)) {
      errrObj.logo_img = 'Only jpg, jpeg, gif format allowed.';
    }
    if (values?.name == '') {
      errrObj.name = 'Please enter name';
    }
    if (companyImage == '' && imageType == '' && mode != 'EDIT') {
      errrObj.logo_img = 'Please select company logo';
    }
    // if (description == '') {
    //   errrObj.description = 'Please enter description';
    // }
    if (values?.companyType == '') {
      errrObj.companyType = 'Please select company type';
    }
    if (values?.companySize == '') {
      errrObj.companySize = 'Please select company size';
    }
    if (values?.companyWebsiteUrl == '') {
      errrObj.companyWebsiteUrl = 'Please enter company website url';
    } else if (!isValidURL(values?.companyWebsiteUrl)) {
      errrObj.companyWebsiteUrl = 'Please enter valid company website url';
    }
    if (mainIndustry == '') {
      errrObj.mainCompanyIndustry = 'Please select company industry';
    }
    if (companySpecialTies.length == 0) {
      errrObj.companyFunctionalAreas = 'Please enter company functional areas';
    }
    if (values?.yearFounded == '') {
      errrObj.yearFounded = 'Please select company founded year';
    }
    if (values?.companyAddress == '') {
      errrObj.companyAddress = 'Please select company address';
    }
    if (jobLocation == '') {
      errrObj.city = 'Please select company location';
    }
    if (values?.pinCode == '') {
      errrObj.pinCode = 'Please enter company postal code';
    }
    if (values?.company_page == '') {
      errrObj.company_page = 'Please select company create';
    }
    if (values?.company_page == '1') {
      if (values?.company_slug == '') {
        errrObj.company_slug = 'Please enter company page slug';
      }
      if (adminIds.length == 0 && values?.timeline_id == '0') {
        errrObj.organisation_page_admin = 'Please select admins from list';
      }
      // if (values.promote == '') {
      //   errrObj.promote = 'Please select compnay promote';
      // } else {
      //   errrObj.promote = '';
      // }
      // if (values?.promote == '1') {
      //   if (values.audience_type == '') {
      //     errrObj.audience_type = 'Please select target audience';
      //   }
      //   return
      // }
    }
    return errrObj;
  };

  const [searchParms, setSearchParams] = useSearchParams();
  useEffect(() => {
    const getData = async () => {
      let companyId = searchParms.get('companyId');
      if (companyId) {
        // edit mode
        setMode('EDIT');
        getCompanyCompanyDetail(companyId).then((resp) => {
          let data = resp.data.data?.company_data;
          if (data?.logo !== '') {
            setCompanyImage(data?.logo);
          }

          let { company_data: companyData } = resp.data.data;
          if (companyData.functional_areas !== '') {
            companyData.functional_areas.split(',').forEach((area) => {
              if (area) {
                getCompanySpecialityDropDown('', area).then((resp) => {
                  let specialityData = resp.data[0];
                  setCompanySpecialTies((prev) => {
                    return [
                      ...prev,
                      {
                        id: specialityData?.id,
                        value: specialityData?.id,
                        label: specialityData?.name
                      }
                    ];
                  });
                });
              }
            });
          }
          if (data.city && data.city != '') {
            getCompaniesCityDropDown('', data.city).then((resp) => {
              if (resp?.status == 200) {
                let cityData = resp.data.data[0];
                setJobLocation({
                  id: cityData?.city_id,
                  label: cityData?.city,
                  value: cityData?.city_id
                });
              }
            });
          }
          setValues({
            name: data?.name,
            companyAddress: data?.address,
            audience_type: data?.audience_type == '1' ? '1' : '0',
            companySize: data?.company_size,
            yearFounded: data?.estyear,
            companyWebsiteUrl: data?.website,
            pinCode: data?.pincode,
            companyType: data?.institute_type,
            company_page: data?.timeline_id == '0' ? '0' : '1',
            timeline_id: data?.timeline_id || '0',
            company_slug: data?.company_slug,
            // organisation_page_admin: data?.organisation_page_admin,
            promote: data?.audience_type == '' ? '1' : '0',
            logo_Image: data?.logo
          });
          setDescription(data.description ? data?.description : '');
          setCompanyImage(data?.logo);
          setMainIndustry({
            id: data.industry_id,
            label: data.industry_name,
            value: data.industry_id
          });
        });
      } else {
        clearFields();
      }
    };
    getData();
  }, [searchParms]);

  const handleSubmit = () => {
    setIsFirstTimeClicked(true);
    if (!Object.keys(validation(values)).length) {
      let body = {
        name: values.name,
        description: description,
        institute_type: values.companyType,
        company_size: values.companySize,
        website: values.companyWebsiteUrl,
        industry_id: mainIndustry.id,
        functional_areas: companySpecialTies.map((data) => data.id).join(','),
        estyear: values.yearFounded,
        address: values.companyAddress,
        city: jobLocation.id,
        pincode: values.pinCode,
        _wysihtml5_mode: 1,
        timeline_id: values.timeline_id || '',
        promotion: '',
        company_page: values.company_page,
        company_slug: values.company_slug,
        promote: values.promote,
        audience_type: values.audience_type,
        logo_img: imageType ? imageType : values.logo_img
      };

      let formData = new FormData();
      Object.keys(body).map((key) => {
        formData.append(key, body[key]);
      });
      if (values?.timeline_id == '0' && values?.company_page == '1') {
        formData.append('organisation_page_admin', adminIds.map((data) => data?.value).join());
      } else if (values?.timeline_id == undefined && values?.company_page == '1') {
        formData.append('organisation_page_admin', adminIds.map((data) => data?.value).join());
      }
      if (mode == 'ADD') {
        // formData.delete("timeline_id")
        addCompanyFromAdmin(formData).then((resp) => {
          if (resp?.status == 200) {
            showNotification('success', resp?.data?.message);
            clearFields();
            navigate('/admin/companies/showlist');
          }
        });
      }
      if (mode == 'EDIT') {
        // apend companyId
        formData.append('companyid', searchParms.get('companyId'));
        updateCompanyFromAdmin(formData).then((resp) => {
          if (resp?.status == 200) {
            showNotification('success', 'Company updated successfully');
            clearFields();
            navigate('/admin/companies/showlist');
          }
        });
      }
    } else {
      let id = Object.keys(validation()) ? Object.keys(validation())[0] : '';
      if (document.getElementById(id)) {
        document.getElementById(id).scrollIntoView({ behavior: 'smooth', block: 'start' });
      } else {
        return;
      }
    }
  };

  const clearFields = () => {
    setCompanyImage('');
    setValues({
      name: '',
      logo_img: '',
      description: '',
      companyType: '',
      companySize: '',
      companyWebsiteUrl: '',
      mainCompanyIndustry: '',
      companyFunctionalAreas: '',
      yearFounded: '',
      companyAddress: '',
      city: '',
      pinCode: '',
      isCreateCompany: 'no',
      companySlug: '',
      companyAdmin: '',
      isCompanyPromote: 'no',
      targetAudience: ''
    });
    setDescription('');
    setCompanySpecialtiesSearch('');
    setCompanySpecialTies([]);
    setMainIndustry('');
    setMainIndustrySearch('');
    setJobLocation('');
    setLocationSearch('');

    setTouched({
      name: false,
      logoImage: false,
      description: false,
      companyType: false,
      companySize: false,
      companyWebsiteUrl: false,
      mainCompanyIndustry: false,
      companyFunctionalAreas: false,
      yearFounded: false,
      companyAddress: false,
      city: false,
      pinCode: false,
      isCreateCompany: false,
      companySlug: false,
      companyAdmin: false,
      isCompanyPromote: false,
      targetAudience: false
    });
    setIsFirstTimeClicked(false);
  };
  const handleBlur = (e) => {
    if (isFirstTimeClicked) {
      setTouched({
        ...touched,
        [e.target.name]: true
      });
    }
  };
  const onDescriptionBlur = () => {
    setTouched({
      ...touched,
      description: true
    });
  };

  // load options using API call
  const loadOptions = (inputValue) => {
    if (inputValue?.length > 1) {
      return getAdminList(inputValue).then((res) => {
        if (res?.status === 200) {
          let tempArr = [];
          if (res?.data?.data) {
            const data = res?.data?.data;
            data?.map((data) => {
              tempArr.push({
                label: (
                  <>
                    <img
                      width="50px"
                      height="40px"
                      src={data?.avatar_id != '0' ? data?.user_image : defaultUser}
                      alt=""
                    />
                    <span> {data.name}</span>
                    <span> ({data?.email}) </span>
                  </>
                ),
                value: data?.id,
                name: data?.name,
                email: data?.email
              });
            });
          }
          return tempArr;
        }
      });
    } else {
      return;
    }
  };

  const loadCompanyCities = async (inputValue) => {
    if (inputValue?.length >= 2) {
      setLoadOpt(true);
      return getCompaniesCityDropDown(inputValue).then((res) => {
        setLoadOpt(false);
        if (res?.status === 200) {
          let tempArr = [];
          if (res?.data?.data) {
            let data = res?.data?.data;
            data?.map((data) => {
              tempArr.push({
                id: data?.city_id,
                label: data?.city,
                value: data?.city_id
              });
            });
          }
          if (res?.data?.data == '') {
            setInputMessage('No matches found ');
          }
          return tempArr;
        }
      });
    }
  };

  return (
    <div className="dash-main-content">
      <Container fluid>
        <div className="dash-page-content">
          <div className="dash-page-title">
            <Row>
              <Col xs={12} sm={12} md={12}>
                <div className="dash-title-bar d-flex align-items-center justify-content-between">
                  <div className="title-lft d-flex align-items-center">
                    <div className="dash-title-dis">
                      {}
                      {searchParms.get('companyId') ? (
                        <h5>Update Company</h5>
                      ) : (
                        <h5>Add Company</h5>
                      )}
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <div className="dash-content-card">
            <div className="cmmn-form">
              <Form>
                <Row>
                  <Col xs={12} sm={12} md={12}>
                    <Form.Group className="mb-3" controlId="formBasicPassword">
                      <Form.Label>
                        Name<span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        name="name"
                        type="text"
                        placeholder="Enter Name"
                        value={values?.name}
                        id="name"
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <div className={'danger-frm'}>
                        {isFirstTimeClicked && validation(values)?.name}
                      </div>
                    </Form.Group>
                  </Col>

                  <Col xs={12} sm={12} md={12}>
                    <Form.Group className="mb-3" controlId="formBasicPassword">
                      <Form.Label>
                        Logo <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="file"
                        id="logo_img"
                        accept="image/*"
                        onChange={(e) => {
                          setImageType(e.target.files[0]);
                          // setCompanyImage(e.target.files[0])
                        }}
                      />
                      <div className={'danger-frm'}>
                        {isFirstTimeClicked && validation(values)?.logo_img}
                      </div>
                      {values?.logo_img}
                      {companyImage && (
                        <img src={companyImage} width="100px" height="70px" alt="" />
                      )}
                    </Form.Group>
                  </Col>

                  <Col xs={12} sm={12} md={12}>
                    <Form.Group className="mb-3" controlId="formBasicPassword">
                      <Form.Label>Description </Form.Label>
                      <CKEditorComponent
                        onBlur={onDescriptionBlur}
                        isToolBar={true}
                        setLatestPost={setDescription}
                        latestPost={description}
                      />
                    </Form.Group>
                  </Col>

                  <Col xs={12} sm={12} md={12}>
                    <Form.Group className="mb-3" controlId="formBasicPassword">
                      <Form.Label>
                        Company Type <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Select
                        aria-label="Default select example"
                        value={values.companyType}
                        id="companyType"
                        onChange={(e) => {
                          setValues({
                            ...values,
                            companyType: e.target.value
                          });
                        }}
                        onBlur={(e) => {
                          setTouched({
                            ...touched,
                            companyType: true
                          });
                        }}>
                        <option value="">-Select Company Type-</option>
                        <option value="company">Corporate </option>
                        <option value="ngo">Non Government</option>
                        <option value="npo">Non Profit</option>
                        <option value="gov_agency">Govt Agencies</option>
                      </Form.Select>
                      <div className={'danger-frm'}>
                        {isFirstTimeClicked && validation(values)?.companyType}
                      </div>
                    </Form.Group>
                  </Col>

                  <Col xs={12} sm={12} md={12}>
                    <Form.Group className="mb-3" controlId="formBasicPassword">
                      <Form.Label>
                        Company Size <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Select
                        id="companySize"
                        aria-label="Default select example"
                        value={values.companySize}
                        onChange={(e) => {
                          setValues({
                            ...values,
                            companySize: e.target.value
                          });
                        }}
                        onBlur={(e) => {
                          setTouched({
                            ...touched,
                            companySize: true
                          });
                        }}>
                        <option value="">Select Company Size</option>
                        <option value="1-10">1-10 Employees</option>
                        <option value="11-50">11-50 Employees</option>
                        <option value="51-200">51-200 Employees</option>
                        <option value="201-500">201-500 Employees</option>
                        <option value="501-1000">501-1000 Employees</option>
                        <option value="1001-5000">1001-5000 Employees</option>
                        <option value="5001-10000">5001-10000 Employees</option>
                        <option value="10000 +">10000 + Employees</option>
                      </Form.Select>
                      <div className={'danger-frm'}>
                        {isFirstTimeClicked && validation(values)?.companySize}
                      </div>
                    </Form.Group>
                  </Col>

                  <Col xs={12} sm={12} md={12}>
                    <Form.Group className="mb-3" controlId="formBasicPassword">
                      <Form.Label>
                        Company Website URL <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        onChange={handleChange}
                        placeholder="Enter company URL"
                        name="companyWebsiteUrl"
                        id="companyWebsiteUrl"
                        value={values.companyWebsiteUrl}
                        onBlur={handleBlur}
                      />
                      <div className={'danger-frm'}>
                        {isFirstTimeClicked && validation(values)?.companyWebsiteUrl}
                      </div>
                    </Form.Group>
                  </Col>

                  <Col xs={12} sm={12} md={12}>
                    <Form.Group className="mb-3" controlId="formBasicPassword">
                      <Form.Label id="mainCompanyIndustry">
                        Main Company Industry <span className="text-danger">*</span>
                      </Form.Label>
                      {/* <Form.Select
                          aria-label="Default select example"
                          onChange={(e) => {
                            setValues({
                              ...values,
                              mainCompanyIndustry: e.target.value
                            });
                          }}
                          onBlur={(e) => {
                            setTouched({
                              ...touched,
                              mainCompanyIndustry: true
                            });
                          }}>
                          <option>Select Industry from list</option>
                          <option value="0">Accounting/Finance</option>
                          <option value="1">Advertising/PR/MR/Events</option>
                          <option value="2">Agriculture/Dairy</option>
                          <option value="3">Auto/Auto Ancillary</option>
                        </Form.Select> */}

                      <AsyncSelect
                        cacheOptions
                        defaultOptions
                        value={mainIndustry}
                        getOptionLabel={(e) => e.label}
                        getOptionValue={(e) => e.value}
                        loadOptions={loadMainCompanyIndustries}
                        onInputChange={(newValue) => setMainIndustrySearch(newValue)}
                        className="basic-single mt-1"
                        name="location"
                        placeholder="Select industry"
                        onChange={setMainIndustry}
                        onBlur={() => {
                          setTouched({
                            ...touched,
                            mainCompanyIndustry: true
                          });
                        }}
                      />
                      <div className={'danger-frm'}>
                        {isFirstTimeClicked && validation(values)?.mainCompanyIndustry}
                      </div>
                    </Form.Group>
                  </Col>

                  <Col xs={12} sm={12} md={12}>
                    <Form.Group className="mb-3" controlId="formBasicPassword">
                      <Form.Label>
                        Company Functional Areas / Specialities{' '}
                        <span className="text-danger">*</span>
                      </Form.Label>
                      <AsyncSelect
                        cacheOptions
                        defaultOptions
                        value={companySpecialTies}
                        getOptionLabel={(e) => e.label}
                        getOptionValue={(e) => e.value}
                        isMulti
                        placeholder="Select functional Area"
                        loadOptions={loadCompanySpecialitiesOptions}
                        onInputChange={(newValue) => setCompanySpecialtiesSearch(newValue)}
                        className="basic-single mt-1"
                        name="location"
                        onChange={setCompanySpecialTies}
                        onBlur={() => {
                          setTouched({
                            ...touched,
                            companyFunctionalAreas: true
                          });
                        }}
                      />
                      <div className={'danger-frm'}>
                        {isFirstTimeClicked && validation(values)?.companyFunctionalAreas}
                      </div>
                    </Form.Group>
                  </Col>

                  <Col xs={12} sm={12} md={12}>
                    <Form.Group className="mb-3" controlId="formBasicPassword">
                      <Form.Label>
                        Year Founded <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Select
                        aria-label="Default select example"
                        value={values.yearFounded}
                        onChange={(e) => {
                          setValues({
                            ...values,
                            yearFounded: e.target.value
                          });
                        }}
                        onBlur={(e) => {
                          setTouched({
                            ...touched,
                            yearFounded: true
                          });
                        }}>
                        <option>Select year founded in</option>
                        {yearOptions.map((year) => {
                          return (
                            <option key={year} value={year?.toString()}>
                              {year}
                            </option>
                          );
                        })}
                      </Form.Select>

                      <div className={'danger-frm'}>
                        {isFirstTimeClicked && validation(values)?.yearFounded}
                      </div>
                    </Form.Group>
                  </Col>

                  <Col xs={12} sm={12} md={12}>
                    <Form.Group className="mb-3" controlId="comaddress">
                      <Form.Label>
                        Company Address <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter company address"
                        value={values.companyAddress}
                        name="companyAddress"
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <div className={'danger-frm'}>
                        {isFirstTimeClicked && validation(values)?.companyAddress}
                      </div>
                    </Form.Group>
                  </Col>

                  <Col xs={12} sm={12} md={12}>
                    <Form.Group className="mb-3" controlId="cityselect">
                      <Form.Label>
                        City <span className="text-danger">*</span>
                      </Form.Label>
                      {/* <Form.Select
                          aria-label="Default select example"
                          onChange={(e) => {
                            setValues({
                              ...values,
                              city: e.target.value
                            });
                          }}
                          onBlur={(e) => {
                            setTouched({
                              ...touched,
                              city: true
                            });
                          }}>
                          <option>Select company location</option>
                        </Form.Select> */}

                      <AsyncSelect
                        isClearable={true}
                        getOptionLabel={(e) => e?.label}
                        getOptionValue={(e) => e?.value}
                        loadOptions={loadCompanyCities}
                        value={jobLocation}
                        className="basic-single mt-1"
                        name="city"
                        onChange={(e) => {
                          setJobLocation(e);
                          setTouched({ ...touched, ['city']: e?.id });
                        }}
                        placeholder="Select event city"
                        components={{
                          DropdownIndicator: () => null,
                          IndicatorSeparator: () => null,
                          LoadingIndicator: () => null
                        }}
                        noOptionsMessage={() => inputMessage}
                        onInputChange={(e) => {
                          e?.length < 2
                            ? setInputMessage('Please enter atleast 2 characters.')
                            : '';
                        }}
                      />
                      {loadOpt && (
                        <div
                          className="d-flex"
                          style={{ justifyContent: 'flex-end', marginTop: '-25px' }}>
                          <i className="fa fa-spinner fa-pulse me-2"></i>
                        </div>
                      )}

                      <div className={'danger-frm'}>
                        {isFirstTimeClicked && validation(values)?.city}
                      </div>
                    </Form.Group>
                  </Col>

                  <Col xs={12} sm={12} md={12}>
                    <Form.Group className="mb-3" controlId="comaddress">
                      <Form.Label>
                        Pin Code / ZIP Code <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter postal code"
                        value={values.pinCode}
                        name="pinCode"
                        onChange={(e) => {
                          const re = /^[0-9\b]+$/;
                          // if value is not blank, then test the regex
                          if (e.target.value === '' || re.test(e.target.value)) {
                            handleChange(e);
                          }
                        }}
                        onBlur={handleBlur}
                      />
                      <div className={'danger-frm'}>
                        {' '}
                        {isFirstTimeClicked && validation(values)?.pinCode}
                      </div>
                    </Form.Group>
                  </Col>

                  <Col xs={12} sm={12} md={12}>
                    <Form.Label className="d-inline-block me-4 mb-3">
                      Do you want to create page for your company?
                    </Form.Label>
                    <Form.Check
                      id="isCreateCompany1"
                      inline
                      label="yes"
                      type="radio"
                      checked={values.company_page == '1'}
                      onChange={(e) => {
                        setValues({
                          ...values,
                          company_page: e.target.value == 'on' ? '1' : ''
                        });
                      }}
                    />
                    <Form.Check
                      id="isCreateCompany2"
                      inline
                      label="No"
                      type="radio"
                      // defaultChecked
                      checked={values.company_page == '0'}
                      onChange={(e) => {
                        setValues({
                          ...values,
                          company_page: e.target.value == 'on' ? '0' : ''
                        });
                      }}
                    />
                    {values.company_page == '1' && (
                      <>
                        <div>
                          <Form.Group className="mb-3" controlId="comaddress">
                            <Form.Label>
                              Enter slug for your company page{' '}
                              <span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Control
                              type="text"
                              onChange={handleChange}
                              placeholder="Enter company page slug"
                              value={values.company_slug}
                              // onKeyPress={restrictAlpha}
                              name="company_slug"
                            />
                            <div className={'danger-frm'}>
                              {isFirstTimeClicked && validation(values)?.company_slug}
                            </div>
                          </Form.Group>
                        </div>
                        {values?.timeline_id == '0' || mode == 'ADD' ? (
                          <div>
                            <Form.Group className="mb-3" controlId="comaddress">
                              <Form.Label>
                                Select Admin For Company Page:{' '}
                                <span className="text-danger">*</span>
                              </Form.Label>
                              {/* async select */}
                              {/* <Form.Control
                                type="text"
                                placeholder="Enter name or email to select admins"
                                onChange={handleChange}
                                value={values.organisation_page_admin}
                                name="organisation_page_admin"
                                onBlur={handleBlur}
                              /> */}
                              <AsyncSelect
                                isMulti
                                className="basic-single"
                                classNamePrefix="select"
                                name="adminIds"
                                placeholder="Enter name or email to select admins"
                                loadOptions={loadOptions}
                                onChange={(e) => {
                                  setAdminIds(
                                    e.map((data) => {
                                      return {
                                        label: data?.name,
                                        value: data?.value
                                      };
                                    })
                                  );
                                }}
                                value={adminIds}
                              />

                              <div className={'danger-frm'}>
                                {isFirstTimeClicked && validation(values)?.organisation_page_admin}
                              </div>
                            </Form.Group>
                          </div>
                        ) : (
                          <></>
                        )}
                      </>
                    )}
                  </Col>
                  {values.company_page == '1' && (
                    <Col xs={12} sm={12} md={12}>
                      <Form.Label className="d-inline-block me-4 mt-3">
                        {' '}
                        Do you want to promote your company page?
                      </Form.Label>
                      <Form.Check
                        id="checkone"
                        inline
                        label="yes"
                        checked={values.promote == '1'}
                        type="radio"
                        onChange={(e) => {
                          setValues({
                            ...values,
                            promote: e.target.value == 'on' ? '1' : ''
                          });
                        }}
                      />
                      <Form.Check
                        id="check2"
                        inline
                        defaultChecked
                        label="No"
                        type="radio"
                        checked={values.promote == '0'}
                        onChange={(e) => {
                          setValues({
                            ...values,
                            promote: e.target.value == 'on' ? '0' : ''
                          });
                        }}
                      />
                      {values.promote == '1' && (
                        <div className="mt-3">
                          <Form.Label className="d-inline-block me-4">
                            {' '}
                            Target Audience : <span className="text-danger">*</span>
                          </Form.Label>
                          <Form.Check
                            id="checkone"
                            inline
                            label="Show to All"
                            type="radio"
                            checked={values.audience_type == '1'}
                            onChange={(e) => {
                              setValues({
                                ...values,
                                audience_type: e.target.value == 'on' ? '1' : ''
                              });
                            }}
                          />
                          <Form.Check
                            id="check2"
                            inline
                            label="On basis of functional areas"
                            type="radio"
                            checked={values.audience_type == '0'}
                            defaultChecked
                            onChange={(e) => {
                              setValues({
                                ...values,
                                audience_type: e.target.value == 'on' ? '0' : ''
                              });
                            }}
                          />
                          <div className={'danger-frm'}>
                            {isFirstTimeClicked && validation(values)?.audience_type}
                          </div>
                        </div>
                      )}
                    </Col>
                  )}
                </Row>

                <div className="form-btns d-flex align-items-center mt-4">
                  <button type="button" className="green_button_admin" onClick={handleSubmit}>
                    {mode == 'EDIT' ? 'Update' : 'Save'}
                  </button>
                  <strong>OR</strong>
                  <Button
                    className="main-btn-red"
                    onClick={() => navigate('/admin/companies/showlist')}>
                    Cancel
                  </Button>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}
