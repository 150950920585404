import { useState, useEffect } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import HeaderLogin from '../../commoncomponents/HeaderLogin';
import TopTabbings from './TopTabbings';
import NewsSidebar from '../../commoncomponents/newsfeeds/NewsSidebar';
import moment from 'moment';
import * as UserServices from '../../../services/UserServices';
import Loader from '../../commoncomponents/loader/loader-large';
import useDocumentTitle from '../../../globals/useDocumentTitle';

function NotificationPage() {
  useDocumentTitle('Almabay');
  const [response, setResponse] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [total, setTotal] = useState('');
  let navigate = useNavigate();
  useEffect(() => {
    defaultData();
  }, []);

  const defaultData = (page) => {
    if (!page) {
      setLoading(true);
    }
    setLoading(true);
    UserServices.SK_getNotifications(page ? page : currentPage).then((resp) => {
      setLoading(false);

      if (resp?.status === 200) {
        if (page) {
          setResponse([...response, ...(resp?.data?.data?.sk?.notifications ?? [])]);
        } else {
          setResponse(resp?.data?.data?.sk?.notifications);
        }
        setTotal(resp?.data?.data?.sk?.notifications_count);
      }
    });
  };

  /** Load More Functionality */

  const handleLoadMore = (e) => {
    e.preventDefault();
    setCurrentPage(currentPage + 1);
    defaultData(currentPage + 1);
  };

  const getApplicable = (value, id) => {
    switch (value) {
      case 'communication':
        navigate(`/shop`);
        break;
      case 'job_apply':
        navigate(`/jobs/jobs-details/${id}`);
        break;
      case 'job_recommend':
        navigate(`/jobs/details/${id}`);
        break;
      case 'job_referral_status_approved':
        navigate(`/jobs/details/${id}`);
        break;
      case 'job_referral':
        navigate(`/jobs/referrals`, { state: 'second' });
        break;
      case 'birthday':
        navigate(`/user/birthdays`);
        break;
      default:
        break;
    }
  };

  return (
    <>
      <HeaderLogin />
      <div className="header-lnk-feeds">
        <div className="container">
          <TopTabbings state={'mentor'} />
        </div>
      </div>
      <section className="news-feed-sec bg-grey py-4">
        <Container>
          <Row>
            <Col xs={12} lg={2} xl={2}>
              <NewsSidebar state="2" />
            </Col>

            <Col xs={12} lg={10} xl={10}>
              <div className="notification-sec bg-white">
                <div className="notification-hd mb-3">
                  <h4>Notifications</h4>
                </div>
                <div className="notification-lst">
                  <ul>
                    {response &&
                      response?.length > 0 &&
                      response?.map((notification) => {
                        return (
                          <li>
                            <div className="notification-card d-flex align-items-top">
                              <Link
                                className="me-3 notifi-prof"
                                to={`/user/timeline/${notification?.notifier?.encrypted_id}`}
                              >
                                <img
                                  src={
                                    notification?.notifier?.mediaObject?.path +
                                    '.' +
                                    notification?.notifier?.mediaObject?.extension
                                  }
                                  alt="img"
                                />
                              </Link>
                              <div className="notifi-dis">
                                <h4>
                                  <Link
                                    to={`/user/timeline/${notification?.notifier?.encrypted_id}`}
                                  >
                                    <span className="notification_name_text m-1">
                                      {notification?.notifier?.name}
                                    </span>
                                  </Link>
                                  <Link
                                    to="#"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      getApplicable(notification?.type, notification?.post_id);
                                    }}
                                  >
                                    {' '}
                                    {notification?.text}
                                  </Link>
                                </h4>
                                <ul className="no tification-time">
                                  <li>
                                    <i className="far fa-clock me-2"></i>
                                    {moment(notification?.timestamp).format('  HH:mm:ss a')}
                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                    <i className="fas fa-calendar-alt me-2"></i>{' '}
                                    {moment(notification?.timestamp).format('MM-DD-YYYY')}
                                  </li>
                                  <li></li>
                                </ul>
                              </div>
                            </div>
                          </li>
                        );
                      })}
                  </ul>
                </div>
              </div>
              {total && response?.length !== total && (
                <div className="load-more-btn text-center">
                  <Button variant="secondary" className="read-more" onClick={handleLoadMore}>
                    Load More
                  </Button>
                </div>
              )}
            </Col>
          </Row>
        </Container>
      </section>
      {loading ? <Loader /> : ''}
    </>
  );
}

export default NotificationPage;
