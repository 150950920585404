//  @copyright : ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
// @author     : Shiv Charan Panjeta

// All Rights Reserved.
// Proprietary and confidential :  All information contained herein is, and remains
// the property of ToXSL Technologies Pvt. Ltd. and its partners.
// Unauthorized copying of this file, via any medium is strictly prohibited.

import { useEffect, useState } from 'react';
import { Button, Container } from 'react-bootstrap';
import Alert from 'react-bootstrap/Alert';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Table from 'react-bootstrap/Table';
import ReactPaginate from 'react-paginate';
import { Link, useNavigate } from 'react-router-dom';
import AsyncSelect from 'react-select/async';
import Loader from '../../components/commoncomponents/loader/loader-large';
import { statusName } from '../../globals/helper';
import useDocumentTitle from '../../globals/useDocumentTitle';
import * as adminServices from '../../services/AuthServices';
import showNotification from '../../services/NotificationService';

let intervalId;
export default function ListInstitutes() {
  useDocumentTitle('Manage Institutes | AlmaBay');
  const [list, setList] = useState([]);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [searchedData, setSearchData] = useState({
    institute_id: '',
    institute_name: '',
    institute_affiliate: '',
    institute_email: '',
    institute_city: '',
    institute_phone: '',
    created_by: '',
    institute_status: ''
  });

  const [response, setResponse] = useState(0);
  const [chekcedId, setCheckedId] = useState([]);
  const [allId, setAllId] = useState([]);
  const [sortType, setSortType] = useState('DESC');
  const [sortField, setSortField] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [showalert, setShowAlert] = useState(false);
  const [globalMsg, setGlobalMsg] = useState('Please Select Any Record');
  const [instiSearch, setInstiSearch] = useState('');
  const [insId, setInsId] = useState('');
  const [citySearch, setCitySearch] = useState('');
  const [cityId, setCityId] = useState('');
  useEffect(() => {
    apiCall();
  }, []);

  const apiCall = (
    page = '',
    institute_id = '',
    institute_name = '',
    institute_affiliate = '',
    institute_email = '',
    institute_phone = '',
    institute_city = '',
    institute_status = '',
    created_by = '',
    order = '',
    sortfield = ''
  ) => {
    setLoading(true);
    let pageNo = page ? page : 1;
    setCurrentPage(pageNo);
    let body = {
      page,
      institute_id,
      institute_name,
      institute_affiliate,
      institute_email,
      institute_phone,
      institute_city,
      institute_status,
      created_by,
      order,
      sortfield
    };
    adminServices.getInstituteList(body).then((res) => {
      setLoading(false);
      if (res?.status === 200) {
        setList(res?.data?.data?.viewlist);
        setAllId(res?.data?.data?.viewlist?.map((data) => data?.institute_id));
        setResponse(res?.data?.data?.totalRecords);
      } else {
        showNotification(res?.data?.message);
      }
    });
  };

  const loadAffiliatedOpt = (inputValue) => {
    return adminServices.getAffiliatedTo(inputValue).then((res) => {
      if (res?.status === 200) {
        let tempArr = [];
        if (res?.data?.data) {
          const data = res?.data?.data;
          data?.map((data) => {
            tempArr.push({
              id: data?.institute_id,
              label: data?.name,
              value: data?.name
            });
          });
        }
        return tempArr;
      }
    });
  };

  const loadCityOpt = (inputValue) => {
    return adminServices.getLocationDropDown(inputValue).then((res) => {
      if (res?.status === 200) {
        let tempArr = [];
        if (res?.data?.data) {
          const data = res?.data?.data;
          data?.map((data) => {
            tempArr.push({
              id: data?.id,
              label: data?.location,
              value: data?.location
            });
          });
        }
        return tempArr;
      }
    });
  };

  const selectHandler = (value, id, data) => {
    switch (value) {
      case 'edit':
        navigate(`/admin/edit-institute/${id}`);
        break;
      case 'department':
        navigate('/admin/institute/listDepartments', { state: id });
        break;
      case 'course':
        navigate('/admin/institute/list-courses', { state: id });
        break;
      case 'addEvent':
        navigate(`/admin/event/create`, { state: id });
        break;
      case 'view':
        navigate('/admin/users/showallusers', { state: id });
        break;
      case 'domain':
        navigate('/admin/institute/domain-config/' + id);
        break;
      case 'communication':
        navigate(`/admin/institute/communication-plan-for-institute`, { state: id });
        break;
      default:
        navigate(`/admin/institute/showAll`);
        break;
    }
  };

  const globalStateHandler = () => {
    setShowAlert(true);
    setInterval(function () {
      setShowAlert(false);
    }, 3000);
  };

  const sortHandler = (e, type) => {
    e.preventDefault();
    let currentSort = sortType === 'ASC' ? 'DESC' : 'ASC';
    setSortType(currentSort);
    setSortField(type);

    apiCall(
      currentPage,
      searchedData.institute_id,
      searchedData.institute_name.trim(),
      searchedData.institute_affiliate?.id,
      searchedData.institute_email.trim(),
      searchedData.institute_phone,
      searchedData.institute_city?.id,
      searchedData.institute_status,
      searchedData.created_by,
      currentSort,
      type
    );
  };

  const onChangeSearch = (e) => {
    const { name, value } = e.target;
    setSearchData({ ...searchedData, [name]: value });
  };

  const searchHandler = (page = '1') => {
    setCurrentPage(page);
    apiCall(
      page,
      searchedData.institute_id,
      searchedData.institute_name.trim(),
      searchedData.institute_affiliate?.id,
      searchedData.institute_email.trim(),
      searchedData.institute_phone,
      searchedData.institute_city?.id,
      searchedData.institute_status,
      searchedData.created_by,
      sortType,
      sortField
    );
  };

  const resetHandler = (e) => {
    e.preventDefault();
    setSearchData({
      institute_id: '',
      institute_name: '',
      institute_affiliate: '',
      institute_email: '',
      institute_city: '',
      institute_phone: '',
      created_by: '',
      institute_status: ''
    });
    setSortType('DESC');
    setInsId('');
    setCityId('');
    setSortField('');
    apiCall();
    setCurrentPage(1);
  };

  const handlePageClick = (a) => {
    let page = a?.selected + 1;
    searchHandler(page);
  };

  const globalActionHandler = (e) => {
    if (chekcedId.length !== 0) {
      setLoading(true);
      setShowAlert(true);
      let body = {
        ids: chekcedId.join(),
        action: e.target.value
      };
      if (confirm('Are you sure you want to change state  ?') === true) {
        adminServices.instituteMultiAction(body).then((res) => {
          setLoading(false);
          setShowAlert(false);
          if (res?.status === 200) {
            setGlobalMsg(res?.data?.message);
            setShowAlert(true);
            alertHandler();
            setCheckedId([]);
            document.getElementById('global').value = '';
            apiCall();
          }
        });
      } else {
        return;
      }
    } else {
      setShowAlert(true);
      alertHandler();
    }
  };

  const alertHandler = () => {
    clearInterval(intervalId);
    intervalId = setInterval(function () {
      setShowAlert(false);
    }, 5000);
  };

  /**
   * select all checkbox
   * @param {*} e
   */
  const handleSelectAll = (e) => {
    if (e.target.checked === true) {
      allId.forEach((id) => {
        if (!chekcedId.includes(id)) {
          setCheckedId((prev) => {
            return [...prev, id];
          });
        }
      });
    } else {
      setCheckedId([
        ...chekcedId.filter((id) => {
          return allId.indexOf(id) == -1;
        })
      ]);
    }
  };

  const handleMultipleCheckBox = (e, id) => {
    if (chekcedId?.includes(id)) {
      setCheckedId(
        chekcedId?.filter((data) => {
          return data !== id;
        })
      );
    } else {
      setCheckedId([...chekcedId, id]);
    }
  };

  return (
    <>
      <div className="dash-main-content">
        <Container fluid>
          <div className="dash-page-content">
            <div className="dash-page-title">
              <Row>
                <Col xs={12} sm={12} md={12}>
                  <div className="dash-title-bar d-flex align-items-center justify-content-between">
                    <div className="title-lft d-flex align-items-center">
                      <div className="dash-icn">
                        <i className="fas fa-university"></i>
                      </div>
                      <div className="dash-title-dis">
                        <h5>
                          Institute <span>Listing</span>
                        </h5>
                        <h5>
                          Total Record(s): <span>{response}</span>
                        </h5>
                      </div>
                    </div>
                    <div className="title-rt-btn">
                      <div className="head-instite-sec">
                        <Form.Select
                          aria-label="Default select example"
                          id="global"
                          onChange={(e) => {
                            globalActionHandler(e);
                          }}>
                          <option value="">Choose Action</option>
                          <option value="enable">Enable</option>
                          <option value="disable">Disable</option>
                          <option value="delete">Delete</option>
                        </Form.Select>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="dash-content-card">
              <Alert variant={'success'} className={`${showalert ? '' : 'd-none'}`}>
                {globalMsg}
              </Alert>
              <div className="table-responsive">
                <Table className="custom-table-data" striped bordered hover>
                  <thead>
                    <tr>
                      <th>
                        <Form.Check
                          type="checkbox"
                          id="checkbox"
                          name="checkall"
                          checked={
                            allId?.every((elem) => {
                              return chekcedId?.includes(elem);
                            }) && list?.length > 0
                              ? true
                              : false
                          }
                          onChange={(e) => handleSelectAll(e)}
                          label=""
                        />
                      </th>
                      <th>
                        <Link
                          to="#"
                          onClick={(e) => {
                            sortHandler(e, 'institute_id');
                          }}>
                          Id
                          <img src={require('../../assets/images/sort.png')} alt="sort" />
                        </Link>
                      </th>
                      <th>
                        <Link
                          to="#"
                          onClick={(e) => {
                            sortHandler(e, 'name');
                          }}>
                          Institute Name
                          <img src={require('../../assets/images/sort.png')} alt="sort" />
                        </Link>
                      </th>
                      <th>
                        <Link
                          to="#"
                          //  onClick={(e) => { sortHandler(e, "affiliated_to") }}
                        >
                          Affiliate To
                          <img src={require('../../assets/images/sort.png')} alt="sort" />
                        </Link>
                      </th>
                      <th>
                        <Link
                          to="#"
                          // onClick={(e) => { sortHandler(e, "email") }}
                        >
                          Email Id
                          <img src={require('../../assets/images/sort.png')} alt="sort" />
                        </Link>
                      </th>
                      <th>
                        <Link
                          to="#"
                          // onClick={(e) => { sortHandler(e, "city") }}
                        >
                          City
                          <img src={require('../../assets/images/sort.png')} alt="sort" />
                        </Link>
                      </th>
                      <th>
                        <Link to="#">Created By</Link>
                      </th>
                      <th>
                        <Link to="#">Status</Link>
                      </th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="search-table">
                      <td></td>
                      <td>
                        <Form.Control
                          onChange={onChangeSearch}
                          name="institute_id"
                          type="text"
                          placeholder="Id"
                        />
                      </td>
                      <td>
                        <Form.Control
                          onChange={onChangeSearch}
                          name="institute_name"
                          type="text"
                          placeholder="Name"
                          value={searchedData?.institute_name}
                        />
                      </td>
                      <td>
                        <AsyncSelect
                          isClearable
                          isSearchable
                          menuIsOpen={instiSearch !== '' ? true : false}
                          onInputChange={(newValue) => setInstiSearch(newValue)}
                          getOptionLabel={(e) => e.label}
                          getOptionValue={(e) => e.value}
                          loadOptions={loadAffiliatedOpt}
                          className="basic-single"
                          name="institute_affiliate"
                          onChange={(e) => {
                            setInsId(e);
                            setSearchData({ ...searchedData, ['institute_affiliate']: e });
                          }}
                          value={insId}
                        />
                      </td>
                      <td>
                        <Form.Control
                          onChange={onChangeSearch}
                          name="institute_email"
                          type="text"
                          placeholder="Email"
                          value={searchedData.institute_email}
                        />
                      </td>
                      <td>
                        <AsyncSelect
                          isClearable={true}
                          menuIsOpen={citySearch !== '' ? true : false}
                          onInputChange={(newValue) => setCitySearch(newValue)}
                          getOptionLabel={(e) => e.label}
                          getOptionValue={(e) => e.value}
                          loadOptions={loadCityOpt}
                          className="basic-single"
                          name="institute_city"
                          onChange={(e) => {
                            setCityId(e);
                            setSearchData({ ...searchedData, ['institute_city']: e });
                          }}
                          value={cityId}
                        />
                      </td>
                      <td>
                        <Form.Select
                          onChange={onChangeSearch}
                          name="created_by"
                          aria-label="Default select example">
                          <option value="">Select</option>
                          <option value="1">Admin</option>
                          <option value="0">Users</option>
                        </Form.Select>
                      </td>
                      <td>
                        <Form.Select
                          onChange={onChangeSearch}
                          name="institute_status"
                          aria-label="Default select example">
                          <option value="">Select</option>
                          <option value="1">Enable</option>
                          <option value="0">Disable</option>
                        </Form.Select>
                      </td>
                      <td className="serac-btns text-center" colSpan={2}>
                        <button
                          type="button"
                          className="green_button_admin"
                          onClick={() => {
                            searchHandler();
                          }}>
                          Search
                        </button>
                        <Button
                          className="main-btn-red ms-2"
                          onClick={(e) => {
                            resetHandler(e);
                          }}>
                          Reset
                        </Button>
                      </td>
                    </tr>
                    {list && list?.length ? (
                      list?.map((data, index) => (
                        <tr key={index}>
                          <td>
                            <Form.Check
                              type="checkbox"
                              name="id"
                              id={`id${data?.institute_id}`}
                              checked={chekcedId?.includes(data?.institute_id)}
                              onChange={(e) => {
                                handleMultipleCheckBox(e, data?.institute_id);
                              }}
                              label=""
                            />
                          </td>
                          <td>{data?.institute_id}</td>
                          <td>{data?.name}</td>
                          <td>{data?.affiliate_name}</td>
                          <td>{data?.admin_email}</td>
                          <td>{data?.city}</td>
                          <td>{data?.created_by == 1 ? 'Admin' : 'User'}</td>
                          <td>{statusName(data?.status)}</td>
                          <td>
                            <Form.Select
                              aria-label="Default select example"
                              onChange={(e) => {
                                selectHandler(e.target.value, data?.institute_id, data);
                              }}>
                              <option>Action</option>
                              <option value="visit">Visit Alumni Site</option>
                              <option value="view">View Alumni</option>
                              <option value="edit">Edit</option>
                              <option value="addEvent">Add Event</option>
                              <option value="department">Departments List</option>
                              <option value="course">Courses List</option>
                              <option value="domain">Domain Config</option>
                              <option value="communication">Communication Plans</option>
                            </Form.Select>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr className="text-center">
                        <td colSpan={9}>Data Not Found</td>
                      </tr>
                    )}
                  </tbody>
                </Table>
                {Math.ceil(response / 10) > 1 ? (
                  <div className="react-page d-flex justify-content-center">
                    <ReactPaginate
                      previousLabel={currentPage > 0 ? '<' : ''}
                      nextLabel={response > 0 ? '>' : ''}
                      onPageChange={handlePageClick}
                      activeClassName={'page-link active'}
                      pageCount={Math.ceil(response / 10)}
                      forcePage={parseInt(currentPage) - 1}
                    />
                  </div>
                ) : (
                  ''
                )}
              </div>
            </div>
          </div>
        </Container>
      </div>
      {loading ? <Loader /> : ''}
    </>
  );
}
