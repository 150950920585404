import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { HideAlbumManager, ShowAlbumManger } from '../../../../redux/action';
import AlbumManager from '../../../commoncomponents/album_manager';
import { Row } from 'react-bootstrap';

function UserAlbums(props) {
  const userId = useParams()?.userId;
  const dispatch = useDispatch();
  const userDetail = useSelector((state) => state?.user?.details);
  const showManger = useSelector((state) => state?.album?.showCollection);
  const location = useLocation();

  window.onpopstate = () => {
    dispatch(HideAlbumManager());
  };

  return (
    <>
      {showManger ? (
        <AlbumManager />
      ) : (
        <>
          {' '}
          <div className="timeline-album bg-white">
            <div className="d-flex align-items-center justify-content-between mb-3 album-hd p-2">
              <h4>Albums</h4>
              {userDetail?.encrypted_id == userId && (
                <Link to={`/user/create-album?id=${userId}`}>
                  <i className="fas fa-plus me-2"></i>
                  Create an album
                </Link>
              )}
            </div>
            {props.albumData?.length !== 0 ? (
              <>
                <div className="album-img-list">
                  {props.albumData?.map((album, index) => {
                    return (
                      <div className="album-img-card" key={album?.id}>
                        <Link
                          to={`/user/collection/${album?.id}/${userId}`}
                          onClick={() => dispatch(ShowAlbumManger())}>
                          <img
                            src={
                              album?.photo
                                ? process.env.REACT_APP_API_URL + album?.photo
                                : require('../../../../assets/images/birth7.jpg')
                            }
                            alt="img"
                          />
                          <h4>{album?.name}</h4>
                        </Link>
                      </div>
                    );
                  })}
                </div>
              </>
            ) : (
              <>No albums Found</>
            )}
          </div>
        </>
      )}
    </>
  );
}

export default UserAlbums;
