/**
 * @copyright : ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
   @author     : Shiv Charan Panjeta 

  All Rights Reserved.
  Proprietary and confidential :  All information contained herein is, and remains
  the property of ToXSL Technologies Pvt. Ltd. and its partners.
  Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 */
import { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Table from 'react-bootstrap/Table';
import ReactPaginate from 'react-paginate';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Loader from '../../components/commoncomponents/loader/loader-large';
import { ALBUMS, PHOTOS, VIDEOS } from '../../globals/constants';
import { statusName } from '../../globals/helper';
import * as adminServices from '../../services/AuthServices';
import showNotification from '../../services/NotificationService';

export default function ClubGallery() {
  const [list, setList] = useState([]);
  const { club_id, id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [searchedData, setSearchData] = useState({
    country_id: '',
    country_code: '',
    country_name: ''
  });
  const [response, setResponse] = useState('');
  const [sortType, setSortType] = useState('DESC');
  const [sortField, setSortField] = useState('country_id');
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    apiCall();
  }, []);

  const apiCall = () => {
    setLoading(true);
    let body = {
      club_id
    };
    adminServices.getClubGalleryList(body).then((res) => {
      setLoading(false);
      if (res?.status === 200) {
        setList(res?.data?.data);
        setResponse(res?.data?.total);
      }
    });
  };

  const selectHandler = (value, data) => {
    switch (value) {
      case 'edit':
        navigate(`/admin/club/edit-gallery/${club_id}/${data?.id}`, { state: data });
        break;
      case '2':
        handleDelete(data?.media_id);
        break;
      default:
        break;
    }
  };

  const searchHandler = (e) => {
    e.preventDefault();
    apiCall(
      currentPage,
      sortField,
      sortType,
      searchedData.country_id,
      searchedData.country_code,
      searchedData.country_name
    );
  };

  const handlePageClick = (a) => {
    let page = a?.selected + 1;
    apiCall(
      page,
      sortField,
      sortType,
      searchedData.country_id,
      searchedData.country_code,
      searchedData.country_name
    );
  };

  const showLayoutType = (key) => {
    switch (key) {
      case PHOTOS:
        return 'Photos';
      case VIDEOS:
        return 'Videos';
      case ALBUMS:
        return 'Album';

      default:
        return 'N/A';
    }
  };
  const handleDelete = (id) => {
    adminServices.deleteGallery(id).then((res) => {
      if (res?.status == 200) {
        showNotification('success', res?.data?.message);
        setList(
          list?.filter((data) => {
            return data?.media_id !== id;
          })
        );
      }
    });
  };

  return (
    <>
      <div className="dash-main-content">
        <Container fluid>
          <div className="dash-page-content">
            <div className="dash-page-title">
              <Row>
                <Col xs={12} sm={12} md={12}>
                  <div className="dash-title-bar d-flex align-items-center justify-content-between">
                    <div className="title-lft d-flex align-items-center">
                      <div className="dash-icn">
                        <i className="fas fa-building"></i>
                      </div>
                      <div className="dash-title-dis">
                        <h5>
                          Gallery <span>Listing</span>
                        </h5>
                        <h5>
                          Total Record(s): <span>{response}</span>
                        </h5>
                      </div>
                    </div>
                    <div className="title-rt-btn">
                      <Link
                        className="green_button_admin"
                        to={`/admin/club/add-gallery/${club_id}`}>
                        Add New Gallery
                      </Link>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="dash-content-card">
              <div className="table-responsive">
                <Table className="custom-table-data" striped bordered hover>
                  <thead>
                    <tr>
                      <th>
                        <Link to="#">Id</Link>
                      </th>

                      <th>
                        <Link to="#">Name</Link>
                      </th>

                      <th>
                        <Link to="#">Club Name</Link>
                      </th>
                      <th>
                        <Link to="#">Type</Link>
                      </th>
                      <th>Status</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {list && list?.length
                      ? list?.map((data, index) => (
                          <tr key={index}>
                            <td>{data?.id}</td>
                            <td>{data?.title ? data?.title : 'N/A'}</td>
                            <td>{data?.club_name}</td>
                            <td>{showLayoutType(data?.type)}</td>
                            <td>{statusName(data?.status)}</td>
                            <td>
                              <Form.Select
                                aria-label="Default select example"
                                value={''}
                                id="select"
                                onChange={(e) => {
                                  selectHandler(e.target.value, data);
                                }}>
                                <option value="">Action</option>
                                <option value="edit">Edit</option>
                                <option value="2">Delete</option>
                              </Form.Select>
                            </td>
                          </tr>
                        ))
                      : ''}
                  </tbody>
                </Table>
              </div>
              {Math.ceil(response / 10) > 1 && (
                <div className="react-page d-flex justify-content-center">
                  <ReactPaginate
                    previousLabel={'Prev'}
                    nextLabel={'Next'}
                    activeClassName={'page-link active'}
                    onPageChange={(props) => {
                      setCurrentPage(props.selected + 1);
                    }}
                    pageCount={Math.ceil(response / 10)}
                  />
                </div>
              )}
            </div>
          </div>
        </Container>
      </div>
      {loading ? <Loader /> : ''}
    </>
  );
}
