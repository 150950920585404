import { useEffect, useState } from 'react';

import * as APISERVICES from '../../../services/ProfileServices';

import { Dropdown } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { followFriend, unfollowMentor } from '../../../services/UserServices';
import showNotification from '../../../services/NotificationService';

function QuestionSideBar() {
  const [checkProfile, setCheckProfile] = useState('');
  const [mentorQuestions, setMentorQuestions] = useState('');
  const [mentorAnswers, setMentorAnswers] = useState('');
  const [mentorArticles, setMentorArticles] = useState('');
  const [mentorViews, setMentorViews] = useState('');
  const [mentorFollowing, setMentorFollowing] = useState('');
  const [mentorFollowers, setMentorFollowers] = useState('');
  const [professionalInfo, setProfessionalInfo] = useState([]);
  const { mentorId } = useParams();
  const isloggedIn = useSelector((state) => state?.user?.isloggedIn);
  const userDetail = useSelector((state) => state?.user?.details);
  const [relation, setRelation] = useState('');

  const navigate = useNavigate();
  useEffect(() => {
    isMentorshipProfile();
  }, []);

  const isMentorshipProfile = () => {
    const body = {
      uid: mentorId,
      content_type: 'questions'
    };
    APISERVICES.getMentorshipProfileQuestions(body).then((res) => {
      if (res?.status === 200) {
        setMentorQuestions(res?.data?.questions);
        setMentorAnswers(res?.data?.answers);
        setMentorArticles(res?.data?.articles);
        setMentorViews(res?.data?.views);
        setMentorFollowing(res?.data?.mentors);
        setMentorFollowers(res?.data?.mentees);
        setCheckProfile(res?.data?.mentorInfo);
        setProfessionalInfo(res?.data?.professionalInfo);
        setRelation(res?.data?.mentorMenteeRelation);
      }
    });
  };

  const resquestHandler = (e, id, type) => {
    e.preventDefault();
    if (type == 'follow') {
      let body = {
        following_id: id
      };
      followFriend(body).then((res) => {
        if (res?.status === 200) {
          isMentorshipProfile();
        } else {
          showNotification('success', res?.data?.message);
        }
      });
    } else {
      var r = confirm('Do you want to remove Mentor?');
      if (r) {
        let body = {
          user_id: id
        };
        unfollowMentor(body).then((res) => {
          if (res?.status === 200) {
            isMentorshipProfile();
          } else {
            showNotification('success', res?.data?.message);
          }
        });
      }
    }
  };

  const gotoMessagePage = () => {
    navigate(`/user/messages`, { state: userDetail?.id });
  };

  return (
    <>
      <div className="question-lft-card bg-white p-3">
        <div className="qusetion-profile">
          <img
            src={
              checkProfile?.avatar
                ? checkProfile?.avatar?.complete_url
                : require('../../../assets/images/default-male-avatar.png')
            }
            alt="img"
          />
        </div>
        <div className="question-pro-dis">
          <h4>{checkProfile?.first_name}</h4>
          <p>
            <i className="fas fa-briefcase me-1"></i>
            {professionalInfo[0]?.organisation ? professionalInfo[0]?.organisation : 'N/A'},
            {professionalInfo[0]?.designation ? professionalInfo[0]?.designation : ''}
          </p>
          {checkProfile?.current_city && (
            <p className="org-color">
              <i className="fas fa-map-marker-alt me-1"></i>
              {checkProfile?.current_city}
            </p>
          )}
        </div>
        <div className="questn-mid-btn">
          {mentorId !== userDetail?.encrypted_id && (
            <Button onClick={() => gotoMessagePage()} className="main-btn ">
              Send Message
            </Button>
          )}
          <Button className="dark-blu-btn" onClick={() => navigate(`/user/timeline/${mentorId}`)}>
            Social Profile
          </Button>
        </div>
        <div className="question-dis-list">
          <ul>
            <li>
              {mentorQuestions && mentorQuestions
                ? mentorQuestions?.map((data, index) => {
                  return (
                    <p key={index}>
                      Questions<span>{data?.MentorTotalQuestions}</span>
                    </p>
                  );
                })
                : '0'}
            </li>
            <li>
              {mentorArticles && mentorArticles
                ? mentorArticles?.map((data, index) => {
                  return (
                    <p key={index}>
                      Articles<span>{data?.MentorTotalArticles}</span>
                    </p>
                  );
                })
                : '0'}
            </li>
            <li>
              {mentorAnswers && mentorAnswers
                ? mentorAnswers?.map((data, index) => {
                  return (
                    <p key={index}>
                      Answered<span>{data?.MentorTotalAnswers}</span>
                    </p>
                  );
                })
                : '0'}
            </li>
            <li>
              <p>
                Followings
                {mentorFollowing && mentorFollowing
                  ? mentorFollowing?.map((data, index) => {
                    return (
                      <span key={index}>
                        <h4>{data?.mentors}</h4>
                      </span>
                    );
                  })
                  : '0'}
              </p>
            </li>
            <li>
              <p>
                Followers
                {mentorFollowers && mentorFollowers
                  ? mentorFollowers?.map((data, index) => {
                    return (
                      <span key={index}>
                        <h4>{data?.mentees}</h4>
                      </span>
                    );
                  })
                  : '0'}
              </p>
            </li>
          </ul>
        </div>
        <div className="following-btn-card">
          {isloggedIn ? (
            <>
              {relation[0]?.active == 1 ? (
                <Dropdown>
                  <Dropdown.Toggle variant="success" id="dropdown-basic" className="following-btn">
                    {userDetail?.encrypted_id == mentorId ? 'YOUR FOLLOWERS' : 'FOLLOWING'}|
                    <span>
                      {' '}
                      {mentorFollowing && mentorFollowing
                        ? mentorFollowing?.map((data, index) => {
                          return data?.mentors;
                        })
                        : '0'}
                    </span>
                  </Dropdown.Toggle>
                  {userDetail?.encrypted_id != mentorId && (
                    <Dropdown.Menu>
                      <Dropdown.Item href="#/action-1">Send Message</Dropdown.Item>
                      <Dropdown.Item href="#/action-2">Ask Question</Dropdown.Item>
                      <Dropdown.Item href="#/action-3">Social Profile</Dropdown.Item>
                      <Dropdown.Item
                        onClick={(e) => resquestHandler(e, checkProfile?.id, 'unfollow')}
                      >
                        Unfollow Mentor
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  )}
                </Dropdown>
              ) : (
                <Dropdown>
                  <Dropdown.Toggle
                    variant="success"
                    id="dropdown-basic"
                    className="following-btn"
                    onClick={(e) => resquestHandler(e, checkProfile?.id, 'follow')}
                  >
                    Add Mentor |
                    <span>
                      {' '}
                      {mentorFollowing
                        ? mentorFollowing?.map((data, index) => {
                          return data?.mentors;
                        })
                        : '0'}
                    </span>
                  </Dropdown.Toggle>
                </Dropdown>
              )}
            </>
          ) : (
            <Button className="following-btn" onClick={() => navigate('/login')}>
              ADD MENTOR |
              <span>
                {' '}
                {mentorFollowing && mentorFollowing
                  ? mentorFollowing?.map((data, index) => {
                    return data?.mentors;
                  })
                  : '0'}
              </span>
            </Button>
          )}
        </div>
        <div className="answer-ques-card">
          <div className="answer-card d-flex align-items-center">
            <div className="answer-count">{/* <h3>7</h3> */}</div>
            <div className="answer">
              <h4>Answered Question</h4>
              {mentorAnswers && mentorAnswers
                ? mentorAnswers?.map((data, index) => {
                  return (
                    <p key={index}>
                      <span>{data?.MentorTotalAnswers}</span>
                    </p>
                  );
                })
                : '0'}
            </div>
          </div>
          <div className="answer-card d-flex align-items-center">
            <div className="answer-count">
              <h3> {checkProfile?.views}</h3>
            </div>
            <div className="answer">
              {mentorViews && mentorViews
                ? mentorViews?.map((data, index) => {
                  return (
                    <p key={index}>
                      Views<h4>{data?.MentorTotalViews}</h4>
                    </p>
                  );
                })
                : '0'}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default QuestionSideBar;
