//  @copyright : ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
// @author     : Shiv Charan Panjeta

// All Rights Reserved.
// Proprietary and confidential :  All information contained herein is, and remains
// the property of ToXSL Technologies Pvt. Ltd. and its partners.
// Unauthorized copying of this file, via any medium is strictly prohibited.

import { useEffect, useState } from 'react';
import { Button, Container } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { useNavigate, useParams } from 'react-router-dom';
import Select from 'react-select';
import Loader from '../../components/commoncomponents/loader/loader-large';
import * as adminService from '../../services/AuthServices';
import showNotification from '../../services/NotificationService';

export default function AddDepartments() {
  const { id, depId } = useParams();
  console.log('id', id);
  const navigate = useNavigate();
  const [departmentList, setDepartmentList] = useState([]);
  const [departmentId, setDepartmentId] = useState([]);
  const [clicked, setClicked] = useState(false);
  const [departmentDetail, setDepartmentDetail] = useState({
    id: id,
    department: '',
    shortcode: '',
    adminemail: '',
    description: '',
    phone: '',
    fax: '',
    website: '',
    logo_img: ''
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (depId) {
      getDetail();
    }
    getDepartmentDropDown();
  }, []);

  const getDepartmentDropDown = () => {
    adminService.departmentDropDown().then((res) => {
      if (res?.status === 200) {
        let list = res?.data?.data;
        let arr = [];
        list?.map((data) => {
          arr.push({ label: data?.name, value: data?.name });
        });
        setDepartmentList(arr);
      }
    });
  };

  const getDetail = () => {
    adminService.getDepartmentDetail(id, depId).then((res) => {
      if (res?.status === 200) {
        const data = res?.data?.data;
        setDepartmentDetail({
          institute_id: id,
          departement_id: depId,
          instituteid_hidden: id,
          department: data?.department?.id,
          shortcode: data?.institute_data?.shortcode,
          adminemail: data?.institute_data?.admin_email,
          description: data?.institute_data?.description,
          phone: data?.institute_data?.phone,
          fax: data?.institute_data?.fax,
          website: data?.institute_data?.website,
          logo_img: data?.institute_data?.logo || ''
        });
        setDepartmentId({ label: data?.department?.id, value: data?.department?.id });
      }
    });
  };

  const onChangeHandler = (e, option) => {
    console.log('e.value', e);
    if (option?.name === 'department') {
      setDepartmentDetail({ ...departmentDetail, ['department']: e.value });
    } else {
      const { name, value, type } = e.target;
      if (type === 'file') {
        let file = e?.target?.files[0];
        setDepartmentDetail({ ...departmentDetail, [name]: file });
      } else {
        setDepartmentDetail({ ...departmentDetail, [name]: value });
      }
    }
  };

  const validate = (value) => {
    let errors = {};
    if (!value.department) {
      errors.department = 'Please enter department name';
    }
    if (!value.shortcode?.trim()) {
      errors.shortcode = 'Please enter your shortcode';
    }
    return errors;
  };

  const submitHandler = (e) => {
    e.preventDefault();
    setClicked(true);
    if (!Object.keys(validate(departmentDetail)).length) {
      setLoading(true);
      adminService.addDepartment(departmentDetail).then((res) => {
        if (res?.status === 200) {
          setLoading(false);
          showNotification('success', res?.data?.message);
          navigate(-1);
        }
      });
    }
  };

  const updateHandler = (e) => {
    e.preventDefault();
    setClicked(true);
    if (!Object.keys(validate(departmentDetail)).length) {
      setLoading(true);
      adminService.editDepartment(departmentDetail).then((res) => {
        if (res?.status === 200) {
          setLoading(false);
          showNotification('success', res?.data?.message);
          navigate(-1);
        }
      });
    }
  };

  return (
    <>
      <div className="dash-main-content">
        <Container fluid>
          <div className="dash-page-content">
            <div className="dash-page-title">
              <Row>
                <Col xs={12} sm={12} md={12}>
                  <div className="dash-title-bar d-flex align-items-center justify-content-between">
                    <div className="title-lft d-flex align-items-center">
                      <div className="dash-icn">
                        <i className="fas fa-university"></i>
                      </div>
                      <div className="dash-title-dis">
                        <h5>
                          Department <span>Details</span>
                        </h5>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="dash-content-card">
              <div className="cmmn-form">
                <Form>
                  <Row>
                    <Col md={4}>
                      <Form.Group className="mb-3" controlId="formBasicName">
                        <Form.Label>
                          Department Name<sup className="text-danger">*</sup>
                        </Form.Label>
                        <Select
                          className="basic-single"
                          classNamePrefix="select"
                          isDisabled={false}
                          isLoading={false}
                          isClearable={false}
                          isRtl={false}
                          isSearchable={true}
                          options={departmentList}
                          name="department"
                          value={departmentId}
                          onChange={(e, option) => {
                            onChangeHandler(e, option);
                            setDepartmentId(e);
                          }}
                        />
                      </Form.Group>
                      <div
                        className={`${
                          clicked && validate(departmentDetail).department ? '' : 'd-none'
                        } danger-frm `}>
                        {clicked && validate(departmentDetail).department}
                      </div>
                    </Col>
                    <Col md={4}>
                      <Form.Group className="mb-3" controlId="formBasicName">
                        <Form.Label>
                          Department ShortCode<sup className="text-danger">*</sup>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="shortcode"
                          value={departmentDetail?.shortcode}
                          onChange={onChangeHandler}
                          placeholder="Enter the shotcode for department. Eg: CESA"
                        />
                      </Form.Group>
                      <div
                        className={`${
                          clicked && validate(departmentDetail).shortcode ? '' : 'd-none'
                        } danger-frm `}>
                        {clicked && validate(departmentDetail).shortcode}
                      </div>
                    </Col>
                    <Col md={4}>
                      <Form.Group className="mb-3" controlId="formBasicName">
                        <Form.Label>Admin email</Form.Label>
                        <Form.Control
                          type="text"
                          name="adminemail"
                          value={departmentDetail?.adminemail}
                          onChange={onChangeHandler}
                          placeholder="Enter the email id of department"
                        />
                      </Form.Group>
                    </Col>

                    <Col md={12}>
                      <Form.Group className="mb-3" controlId="formBasicDesi">
                        <Form.Label>Description</Form.Label>
                        <Form.Control
                          as="textarea"
                          name="description"
                          onChange={onChangeHandler}
                          placeholder="Enter the description of Department."
                          rows={3}
                          value={departmentDetail?.description}
                        />
                      </Form.Group>
                    </Col>

                    <Col md={4}>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Phone</Form.Label>
                        <Form.Control
                          type="tel"
                          name="phone"
                          value={departmentDetail?.phone}
                          onChange={onChangeHandler}
                          maxLength={10}
                          placeholder="Enter the landline number."
                        />
                      </Form.Group>
                    </Col>
                    <Col md={4}>
                      <Form.Group className="mb-3" controlId="formBasicName">
                        <Form.Label>Fax</Form.Label>
                        <Form.Control
                          type="text"
                          name="fax"
                          onChange={onChangeHandler}
                          value={departmentDetail?.fax}
                          placeholder="Enter the Fax number."
                        />
                      </Form.Group>
                    </Col>
                    <Col md={4}>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Website</Form.Label>
                        <Form.Control
                          type="email"
                          name="website"
                          onChange={onChangeHandler}
                          value={departmentDetail?.website}
                          placeholder="Enter the institute website."
                        />
                      </Form.Group>
                    </Col>
                    <Col md={4}>
                      <Form.Group controlId="formFile" className="mb-3">
                        <Form.Label>Logo Image</Form.Label>
                        <Form.Control
                          onChange={onChangeHandler}
                          name="logo_img"
                          accept="image/*"
                          type="file"
                        />
                      </Form.Group>
                    </Col>
                    <Col md={4}>
                      {departmentDetail?.logo_img && id && (
                        <div className="d-flex flex-wrap align-item-center">
                          <div className="border me-2">
                            <img
                              width="100px"
                              height="100px"
                              src={
                                process.env.REACT_APP_API_DOMAIN +
                                'common/uploads/institute_logo/' +
                                departmentDetail?.logo_img
                              }
                              alt="logo"
                            />
                          </div>
                        </div>
                      )}
                    </Col>
                  </Row>
                  <div className="form-btns d-flex align-items-center mt-3">
                    {depId ? (
                      <button
                        type="button"
                        className="green_button_admin"
                        onClick={(e) => {
                          updateHandler(e);
                        }}>
                        Save
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="green_button_admin"
                        onClick={(e) => {
                          submitHandler(e);
                        }}>
                        Save
                      </button>
                    )}

                    <strong>OR</strong>
                    <Button
                      className="main-btn-red"
                      onClick={() => {
                        navigate(-1);
                      }}>
                      Cancel
                    </Button>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </Container>
      </div>
      {loading ? <Loader /> : ''}
    </>
  );
}
