import moment from 'moment/moment';
import { useCallback, useEffect, useState } from 'react';
import { Button, Col, Container, Modal, Row } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import ProgressBar from 'react-bootstrap/ProgressBar';
import Tab from 'react-bootstrap/Tab';
import Cropper from 'react-easy-crop';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { imageExtension } from '../../../globals/helper';
import useDocumentTitle from '../../../globals/useDocumentTitle';
import { loginInfo } from '../../../redux/action';
import { checkLogin } from '../../../services/AuthServices';
import showNotification from '../../../services/NotificationService';
import {
  getLikeUnlike,
  getSinglePostDetail,
  postComment,
  postFollowUnfollow
} from '../../../services/SocialServices';
import {
  addFriend,
  changeCoverImgPostion,
  changeCoverImgSocialProfile,
  changeProfileImage,
  followFriend,
  getUseTimeLineInterPersonal,
  getUseTimeLinePhotos,
  getUserConnectionsData,
  getUserTimeLineAlbums,
  getUserTimeLineAllEducation,
  getUserTimeLineBusiness,
  getUserTimeLineFriends,
  getUserTimeLineInfo,
  getUserTimeLineProfessional,
  getUserTimeLineTech,
  unfollowMentor
} from '../../../services/UserServices';
import { MiniFooter } from '../../MiniFooter';
import HeaderLogin from '../../commoncomponents/HeaderLogin';
import NoDataFound from '../../commoncomponents/NoDataFound';
import ArticalBlogs from '../../commoncomponents/newsfeeds/ArticalBlogs';
import CampusNews from '../../commoncomponents/newsfeeds/CampusNews';
import CampusnewsText from '../../commoncomponents/newsfeeds/CampusnewsText';
import FollowCompany from '../../commoncomponents/newsfeeds/FollowCompany';
import GroupDiscover from '../../commoncomponents/newsfeeds/GroupDiscover';
import PeopleKnow from '../../commoncomponents/newsfeeds/PeopleKnow';
import Upcoming from '../../commoncomponents/newsfeeds/Upcoming';
import WhatsNew from '../../commoncomponents/newsfeeds/WhatsNew';
import WorkAnniversary from '../../commoncomponents/newsfeeds/WorkAnniversary';
import LoginFeeds from './LoginFeeds';
import TopTabbings from './TopTabbings';
import UserTimeLinePost from './UserTimeLinePost';
import UserAbout from './user-timeline-pages/UserAbout';
import UserAlbums from './user-timeline-pages/UserAlbums';
import UserFriends from './user-timeline-pages/UserFriends';

function SocialProfile() {
  useDocumentTitle('Almabay');
  const { userId } = useParams();
  const navigate = useNavigate();
  const [timeLineData, setTimeLineData] = useState('');
  const [educationData, setEducationData] = useState([]);
  const [workData, setWorkData] = useState('');
  const [techData, setTechData] = useState([]);
  const [bussinessData, setBusinesData] = useState([]);
  const [personalData, setPersonalData] = useState([]);
  const [photosData, setPhotosData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [profileImage, setProfileImage] = useState('');
  const dispatch = useDispatch();
  const [searchParms, setSearchParams] = useSearchParams();
  const event_id = searchParms.get('eventKey');
  const [active, setActive] = useState(event_id || 'social');
  const [show, setShow] = useState(false);
  const userDetail = useSelector((state) => state?.user?.details);
  const [albumData, setAlbumData] = useState([]);
  const location = useLocation();
  const [activeTab, setActiveTab] = useState('first');
  const [expandTech, setExpandTech] = useState(false);
  const [expandBus, setExpandBus] = useState(false);
  const [expandPer, setExpandPer] = useState(false);
  const [coverImg, setCoverImg] = useState('');
  const [isReposition, setIsReposition] = useState(false);
  const [friendsData, setFriendsData] = useState([]);
  const [mutualFriends, setMutualFriends] = useState([]);
  const [isFriendLoading, setIsFriendLoading] = useState(false);
  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const [postId, setPostId] = useState('');
  const [loading, setLoading] = useState(false);
  const [clickedImage, setClickedImage] = useState();
  const [modalImage, setModalImage] = useState([]);
  const [modalComment, setModalComment] = useState('');
  const [modalData, setModalData] = useState([]);
  const [connectionsList, setConnectionsList] = useState();
  const [processing, setProcessing] = useState('');

  useEffect(() => {
    window.scrollTo(0, 0);
    checkUserToken();
  }, []);

  const checkUserToken = () => {
    checkLogin().then((res) => {
      if (res?.status === 200) {
        //save data on redux
        dispatch(loginInfo(res?.data?.data));
        localStorage.setItem('userDetail', JSON.stringify(res.data?.data));
      }
    });
  };

  const handleClose = () => {
    setShow(false);
  };
  const handleShow = () => setShow(true);
  useEffect(() => {
    if (userId) {
      getUserTimelineDetail();
    }
  }, [userId]);

  useEffect(() => {
    getAlbums();
    getUserTimelineDetailFriends();
    if (
      location?.pathname.includes('/user/albums') ||
      location?.pathname.includes('/user/collection')
    ) {
      setActiveTab('fourth');
    } else if (location?.pathname.includes('/user/friends')) {
      setActiveTab('third');
    } else if (location?.pathname.includes('/user/about')) {
      setActiveTab('second');
    }
  }, []);

  // useEffect(() => {
  //   if (postId !== '') {
  //     getPostSingleDetail(postId);
  //   }
  // }, [postId]);

  const getUserTimelineDetail = async () => {
    setIsLoading(true);
    const response = await Promise.all([
      getUserTimeLineInfo(userId),
      getUserTimeLineAllEducation(userId),
      getUserTimeLineProfessional(userId),
      getUserTimeLineTech(userId),
      getUserTimeLineBusiness(userId),
      getUseTimeLineInterPersonal(userId),
      getUseTimeLinePhotos(userId),
      getUserTimelineDetailFriends()
    ]);

    const data = response.map((res) => res?.data);

    const [resOne, resTwo, resThree, resFour, resFive, resSix, resSeven] = data;
    setTimeLineData(resOne?.details);
    setEducationData(resTwo?.detail);
    setWorkData(resThree?.detail);
    setTechData(resFour?.data);
    setBusinesData(resFive?.data);
    setPersonalData(resSix?.data);
    setPhotosData(resSeven?.data);
    setIsLoading(false);
  };

  const handleChangeProfileImage = (e) => {
    setProcessing('100% Uploaded');
    setTimeout(() => {
      let file = e.target.files[0];
      if (imageExtension.includes(file?.type)) {
        let formData = new FormData();
        formData.append(`image`, file);
        formData.append('timeline_id', userId);
        changeProfileImage(formData).then((res) => {
          setProcessing('processing...');
          setTimeout(() => {
            setProcessing('Please Wait');
            if (res?.status == 200) {
              setTimeout(() => {
                setProfileImage(res?.data?.url);
                getUserTimelineDetail();
                checkUserToken();
                setProcessing('');
              }, 500);
            }
          }, 500);
        });
      } else if (!imageExtension.includes(file?.type) && file) {
        showNotification('danger', 'invalid format !');
      }
    }, 500);
  };

  // const checkUserToken = () => {
  //   checkLogin().then((res) => {
  //     if (res?.status === 200) {
  //       //save data on redux
  //       dispatch(loginInfo(res?.data?.data));
  //     } else {
  //       dispatch(logout());
  //       navigate('/');
  //     }
  //   });
  // };

  const getAlbums = () => {
    getUserTimeLineAlbums(userId).then((res) => {
      if (res?.status == 200) {
        setAlbumData(res?.data?.data?.sk?.albums);
      }
    });
  };

  const getUserTimelineDetailFriends = (search) => {
    setIsFriendLoading(true);

    getUserTimeLineFriends(userId, search).then((res) => {
      if (res?.status === 200) {
        setIsFriendLoading(false);
        setFriendsData(res?.data?.detail?.friends ?? []);
        // mutual_friends array
        setMutualFriends(res?.data?.detail?.mutual_friends);
      }
    });
  };

  const showClassName = (value) => {
    switch (value) {
      case '100':
        return '';
      case '80':
        return 'ng-hide-vgood';
      case '60':
        return 'ng-hide-good';
      case '40':
        return 'ng-hide-fair';
      case '20':
        return 'ng-hide';

      default:
        return '';
    }
  };
  let lastFriend = timeLineData?.userInfo?.friendInfo?.slice(-1)[0];

  const resquestHandler = (e, id, type) => {
    e.preventDefault();

    if (type == 'follow') {
      let body = {
        following_id: id
      };
      followFriend(body).then((res) => {
        if (res?.status == 200) {
          getUserTimelineDetail();
        } else {
          showNotification('success', res?.data?.message);
        }
      });
    } else {
      if (confirm('Do you want to remove mentor?') === true) {
        let body = {
          user_id: id
        };
        unfollowMentor(body).then((res) => {
          if (res?.status == 200) {
            getUserTimelineDetail();
          } else {
            showNotification('success', res?.data?.message);
          }
        });
      }
    }
  };

  const resquestHanderFriend = (e, id, type) => {
    e.preventDefault();

    if (type == 'add') {
      let body = {
        following_id: id
      };
      addFriend(body).then((res) => {
        if (res?.status == 200) {
          getUserTimelineDetail();
          getUserTimelineDetailFriends();
        } else {
          showNotification('success', res?.data?.message);
        }
      });
    }

    if (type == 'remove') {
      if (confirm('Do you want to remove your friend?') === true) {
        let body = {
          following_id: id
        };
        addFriend(body).then((res) => {
          if (res?.status == 200) {
            getUserTimelineDetail();
            getUserTimelineDetailFriends();
          } else {
            showNotification('success', res?.data?.message);
          }
        });
      }
    }
    if (type == 'requested') {
      if (confirm('Do you want to remove request?') === true) {
        let body = {
          following_id: id
        };
        addFriend(body).then((res) => {
          if (res?.status == 200) {
            getUserTimelineDetail();
            getUserTimelineDetailFriends();
          } else {
            showNotification('success', res?.data?.message);
          }
        });
      }
    }
    // else {

    //   let body = {
    //     userData: id
    //   };
    //   removeFriend(body).then((res) => {
    //     setLoading(false);
    //     if (res?.status == 200) {
    //       handleShowlike(e, postId);
    //     } else {
    //       showNotification('success', res?.data?.message);
    //     }
    //   });
    // }
  };

  const handleChangeCoverImage = (e) => {
    let file = e.target.files[0];
    if (imageExtension.includes(file?.type)) {
      let formData = new FormData();
      formData.append(`image`, file);
      changeCoverImgSocialProfile(formData).then((res) => {
        if (res?.status == 200) {
          setCoverImg(res?.data?.url);
          getUserTimelineDetail();
          checkUserToken();
        }
      });
    } else if (!imageExtension.includes(file?.type) && file) {
      showNotification('danger', 'invalid format !');
    }
  };

  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [rotation, setRotation] = useState(0);
  const [zoom, setZoom] = useState(1);

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => { }, []);

  const setPostionForCover = () => {
    let body = {
      pos: crop?.y,
      width: '970',
      timeline_id: userDetail?.id
    };
    changeCoverImgPostion(body).then((res) => {
      if (res?.status === 200) {
        setIsReposition(false);
        getUserTimelineDetail();
      }
    });
  };

  const handleShow2 = (photosData, data, id) => {
    setShow2(true);
    setModalData(photosData);
    setClickedImage(id);
    getPostSingleDetail(data?.post_id);
    setPostId(data?.post_id);
  };

  const getPostSingleDetail = (id) => {
    setLoading(true);
    getSinglePostDetail(id).then((res) => {
      if (res?.status === 200) {
        setLoading(false);
        setModalImage(res?.data?.data);
      } else {
        setLoading(false);
      }
    });
  };

  const handleGetComments = (i) => {
    let id = modalData?.photos?.filter((data, index) => {
      return index == i;
    });
    setPostId(id[0]?.post_id);
    getSinglePostDetail(id[0]?.post_id).then((res) => {
      if (res?.status === 200) {
        setLoading(false);
        setModalImage(res?.data?.data);
      } else {
        setLoading(false);
      }
    });
  };

  const isPostcomment = (id, type, e) => {
    e.preventDefault();
    postComment(id, {
      text: type == 1 ? '' : modalComment,
      recipient_id: ''
    }).then((res) => {
      if (res?.status === 200) {
        showNotification('success', res?.data?.message);
        setModalComment('');
        getPostSingleDetail(postId);
      }
    });
  };

  useEffect(() => {
    const userConnectionsData = async () => {
      try {
        const response = await getUserConnectionsData(userId);
        if (response?.status === 200) {
          setConnectionsList(response?.data?.detail);
        }
      } catch (error) {
        console.error(error);
      }
    };
    userConnectionsData();
  }, []);

  // useEffect(() => {
  //   dispatch(userConnectionsData(userId))
  // }, []);
  const getPostLike = (e, id) => {
    e.preventDefault();
    getLikeUnlike(id).then((res) => {
      if (res?.status === 200) {
        getPostSingleDetail(postId);
        showNotification('success', res?.data?.message);
      }
    });
  };
  const isFollowUnfollow = (e, id, type) => {
    e.preventDefault();
    postFollowUnfollow(id).then((res) => {
      if (res?.status === 200) {
        showNotification('success', res?.data?.data);

        if (type == 'modal') {
          getPostSingleDetail(postId);
        }
      }
    });
  };

  const coverPhotoFunc = () => {
    if (timeLineData?.userInfo?.actual_cover_url) {
      return timeLineData?.userInfo?.actual_cover_url;
    } else if (userDetail?.cover?.length > 0) {
      return (
        process.env.REACT_APP_API_DOMAIN +
        userDetail?.cover[0]?.url +
        '.' +
        userDetail?.cover[0]?.extension
      );
    } else {
      return require('../../../assets/images/default-cover.png');
    }
  };

  return (
    <>
      <HeaderLogin />
      <div className="header-lnk-feeds">
        <div className="container">
          <TopTabbings state={'social'} />
        </div>
      </div>
      <section className="birhtday-prof-dis">
        <Container>
          <div className="birhday-cover-sec">
            {isReposition ? (
              <div className="cropContainer">
                <Cropper
                  image={timeLineData?.userInfo?.actual_cover_url}
                  crop={crop}
                  zoom={zoom}
                  onCropChange={setCrop}
                  onCropComplete={onCropComplete}
                  onZoomChange={setZoom}
                  showGrid={false}
                  objectFit={'horizontal-cover'}
                />
              </div>
            ) : (
              <img
                className="cover-img"
                src={coverPhotoFunc()}
                alt="img"
              // style={{ top: -timeLineData?.userInfo?.cover_position * 2 + 'px' }}
              />
            )}
            {!isReposition && (
              <>
                {' '}
                <div className="birthday-profile">
                  <div className="mb-2"> {processing}</div>

                  <div className="birth-pro-icn">
                    <img
                      src={
                        timeLineData?.userInfo?.avatar
                          ? timeLineData?.userInfo?.avatar?.complete_url
                          : userDetail?.avtar
                            ? process.env.REACT_APP_API_DOMAIN + userDetail?.avtar
                            : require('../../../assets/images/birth4.png')
                      }
                      alt="img"
                    />
                    {userDetail?.encrypted_id == userId && (
                      <div className="birth-icn-upload-img">
                        <Form.Control
                          type="file"
                          onChange={handleChangeProfileImage}
                          accept=".jpg,.jpeg,.png"
                        />
                        {processing == '' && (
                          <div className="upload-hd-img">
                            <i className="fas fa-camera me-2"></i>
                            Update Profile Picture
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                  <div className="birth-pro-hd">
                    <h4>
                      {userDetail?.encrypted_id == userId ? userDetail?.name : timeLineData?.name}
                    </h4>
                    <span className="online"></span>
                  </div>
                  <div>{educationData?.length > 0 ? `BATCH ${educationData[0]?.to_year}` : ''}</div>
                </div>
              </>
            )}

            {userDetail?.encrypted_id == userId && (
              <div className="upload-cover-btns mb-2">
                {isReposition ? (
                  <>
                    {' '}
                    <Link
                      to={`javascript:void(0)`}
                      className="change-cover me-2"
                      onClick={setPostionForCover}>
                      <i className="fa fa-thumb-tack me-2"></i>
                      Save Position
                    </Link>
                    <Link
                      to={`javascript:void(0)`}
                      className="change-cover"
                      onClick={() => setIsReposition(false)}>
                      <i className="fas fa-times me-2"></i>
                      Cancel
                    </Link>
                  </>
                ) : (
                  <>
                    {' '}
                    <span className="change-cover  cover-upload-btn me-2">
                      <i className="fas fa-camera me-2"></i>
                      Change Cover
                      <Form.Control type="file" onChange={handleChangeCoverImage} />
                    </span>
                    <Link to="#" className="change-cover" onClick={() => setIsReposition(true)}>
                      <i className="fas fa-arrows-alt me-2"></i>
                      Reposition-cover
                    </Link>
                  </>
                )}
              </div>
            )}
          </div>
          <div className="birth-main-tabs">
            <Tab.Container id="left-tabs-example" activeKey={activeTab}>
              <div className="birthday-tabs-hd d-flex align-items-center justify-content-between">
                <div className="birthday-lft-tabs">
                  <Nav variant="pills">
                    <Nav.Item>
                      <Nav.Link
                        eventKey="first"
                        onClick={() => {
                          navigate(`/user/timeline/${userId}`), setActiveTab('first');
                        }}>
                        Timeline
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        eventKey="second"
                        onClick={() => {
                          navigate(`/user/about/${userId}`), setActiveTab('second');
                        }}>
                        About
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        eventKey="third"
                        onClick={() => {
                          navigate(`/user/friends/${userId}`), setActiveTab('third');
                        }}>
                        Friends
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        eventKey="fourth"
                        onClick={() => {
                          navigate(`/user/albums/${userId}`), setActiveTab('fourth');
                        }}>
                        Photos
                      </Nav.Link>
                    </Nav.Item>
                    {timeLineData?.mentor_profile == '1' && (
                      <Nav.Item>
                        <Nav.Link
                          eventKey="fifth"
                          onClick={() => {
                            navigate(`/mentors/profile/${userId}`), setActiveTab('fifth');
                          }}>
                          Mentor Profile
                        </Nav.Link>
                      </Nav.Item>
                    )}
                  </Nav>
                </div>
                {userDetail?.encrypted_id !== userId && (
                  <div className="birthday-rt-cntrl">
                    {timeLineData?.userInfo?.getFollowButton ? (
                      <Button>Unfriend</Button>
                    ) : (
                      <Button>
                        {' '}
                        <i className="fas fa-plus me-2"></i>
                        Add Friend
                      </Button>
                    )}

                    {timeLineData?.userInfo?.isMentor ? (
                      <Button
                        onClick={(e) => resquestHandler(e, timeLineData?.userInfo?.id, 'unfollow')}>
                        Unfollow Mentor
                      </Button>
                    ) : (
                      <Button
                        onClick={(e) => resquestHandler(e, timeLineData?.userInfo?.id, 'follow')}>
                        <i className="fas fa-plus me-2"></i>
                        Follow Mentor
                      </Button>
                    )}
                  </div>
                )}
              </div>
              <Row>
                <Col md={9} xs={12}>
                  <div className="birthday-tab-cnt">
                    <Tab.Content>
                      <Tab.Pane eventKey="first">
                        <Row>
                          <Col md={5}>
                            <div className="birth-lft-timeline mb-3">
                              {timeLineData && (
                                <div className="info-wrap">
                                  <div className="info-icn">
                                    <img
                                      src={require('../../../assets/images/userbasic-icons.png')}
                                      alt="img"
                                    />
                                  </div>

                                  <div className="info-dis-card">
                                    <h3>Basic Information</h3>
                                    {timeLineData?.userInfo?.friendInfo?.length !== 0 && (
                                      <p>
                                        Became friend with{' '}
                                        <Link to={`/user/timeline/${lastFriend?.encrypted_id}`}>
                                          {lastFriend?.name}
                                        </Link>
                                        {timeLineData?.userInfo?.friendInfo?.length > 1 && (
                                          <>
                                            {' '}
                                            &
                                            {timeLineData?.userInfo?.friendInfo?.length > 1 ? (
                                              <Link to="#">
                                                {timeLineData?.userInfo?.friendInfo?.length - 1}{' '}
                                                Others
                                              </Link>
                                            ) : (
                                              <Link to="#">
                                                {timeLineData?.userInfo?.friendInfo?.length} Other
                                              </Link>
                                            )}
                                          </>
                                        )}
                                      </p>
                                    )}

                                    <p>
                                      Born on{' '}
                                      {moment(timeLineData?.userInfo?.birthday).format(
                                        'MMMM d, YYYY'
                                      )}
                                    </p>
                                    {timeLineData?.userInfo?.friendsSince && (
                                      <p>
                                        Your Friends Since{' '}
                                        {timeLineData?.userInfo?.friendsSince?.sinceFrom}
                                      </p>
                                    )}
                                    {timeLineData?.userInfo?.current_city && (
                                      <p>{timeLineData?.userInfo?.current_city}</p>
                                    )}
                                  </div>
                                </div>
                              )}
                              <div className="info-wrap">
                                <div className="info-icn">
                                  <img
                                    src={require('../../../assets/images/education-icons.png')}
                                    alt="img"
                                  />
                                </div>
                                <div className="info-dis-card">
                                  <h3>Education</h3>
                                  {educationData?.length !== 0 ? (
                                    <>
                                      {educationData?.map((data, index) => {
                                        return (
                                          <p key={index}>
                                            Studied at <Link to="#">{data?.institute_name}</Link>
                                          </p>
                                        );
                                      })}
                                    </>
                                  ) : (
                                    <p>No eductaion added yet</p>
                                  )}
                                </div>
                              </div>
                              <div className="info-wrap">
                                <div className="info-icn">
                                  <img
                                    src={require('../../../assets/images/Professional-icons.png')}
                                    alt="img"
                                  />
                                </div>
                                <div className="info-dis-card">
                                  <h3>Professional</h3>
                                  {workData?.length !== 0 ? (
                                    <>
                                      {workData?.map((work, index) => {
                                        return (
                                          <>
                                            {' '}
                                            <p key={index}>
                                              {work?.year_from}-{work?.year_to}
                                            </p>
                                            <p>
                                              {' '}
                                              <Link>{work?.designation}</Link>at {''}{' '}
                                              <Link to="#">{work?.organisation}</Link>
                                            </p>
                                          </>
                                        );
                                      })}
                                    </>
                                  ) : (
                                    <p> No Professional details found at the moment.</p>
                                  )}
                                </div>
                              </div>
                              <div className="info-wrap">
                                <div className="info-icn">
                                  <img
                                    src={require('../../../assets/images/keyskill-icon.png')}
                                    alt="img"
                                  />
                                </div>
                                <div className="info-dis-card">
                                  <h3>Technical Skills</h3>
                                  {techData?.length !== 0 ? (
                                    <>
                                      {expandTech ? (
                                        <>
                                          {' '}
                                          {techData?.map((data, index) => {
                                            return (
                                              <div className="skill-card mb-2" key={index}>
                                                <p>
                                                  {data?.skill_name
                                                    ? data?.skill_name
                                                    : 'Not Available'}
                                                </p>
                                                <ProgressBar className={showClassName(data?.value)}>
                                                  <ProgressBar
                                                    striped
                                                    className="progress-bar-improvement"
                                                    now={data?.value / 5}
                                                    key={3}
                                                  />
                                                  <ProgressBar
                                                    striped
                                                    className="progress-bar-fair"
                                                    now={data?.value / 5}
                                                    key={1}
                                                  />
                                                  <ProgressBar
                                                    className="progress-bar-good"
                                                    now={data?.value / 5}
                                                    key={2}
                                                  />
                                                  <ProgressBar
                                                    className="progress-bar-vgood"
                                                    now={data?.value / 5}
                                                    key={2}
                                                  />
                                                  <ProgressBar
                                                    className="progress-bar-excellent"
                                                    now={data?.value / 5}
                                                    key={2}
                                                  />
                                                </ProgressBar>
                                              </div>
                                            );
                                          })}
                                        </>
                                      ) : (
                                        <>
                                          {' '}
                                          {techData?.slice(0, 6).map((data, index) => {
                                            return (
                                              <div className="skill-card mb-2" key={index}>
                                                <p>
                                                  {data?.skill_name
                                                    ? data?.skill_name
                                                    : 'Not Available'}
                                                </p>
                                                <ProgressBar className={showClassName(data?.value)}>
                                                  <ProgressBar
                                                    striped
                                                    className="progress-bar-improvement"
                                                    now={data?.value / 5}
                                                    key={3}
                                                  />
                                                  <ProgressBar
                                                    striped
                                                    className="progress-bar-fair"
                                                    now={data?.value / 5}
                                                    key={1}
                                                  />
                                                  <ProgressBar
                                                    className="progress-bar-good"
                                                    now={data?.value / 5}
                                                    key={2}
                                                  />
                                                  <ProgressBar
                                                    className="progress-bar-vgood"
                                                    now={data?.value / 5}
                                                    key={2}
                                                  />
                                                  <ProgressBar
                                                    className="progress-bar-excellent"
                                                    now={data?.value / 5}
                                                    key={2}
                                                  />
                                                </ProgressBar>
                                              </div>
                                            );
                                          })}
                                        </>
                                      )}

                                      {techData?.length > 6 && (
                                        <>
                                          {' '}
                                          {expandTech ? (
                                            <Link
                                              className="Expand-btn"
                                              to="#"
                                              onClick={(e) => {
                                                e.preventDefault(), setExpandTech(false);
                                              }}>
                                              Collapse{' '}
                                              <i className="fas fa-angle-double-up  ms-1"></i>
                                            </Link>
                                          ) : (
                                            <Link
                                              className="Expand-btn"
                                              to="#"
                                              onClick={(e) => {
                                                e.preventDefault(), setExpandTech(true);
                                              }}>
                                              Expand{' '}
                                              <i className="fas fa-angle-double-down ms-1"></i>
                                            </Link>
                                          )}
                                        </>
                                      )}
                                    </>
                                  ) : (
                                    <>No Technical skills found at the moment.</>
                                  )}
                                </div>
                              </div>
                              <div className="info-wrap">
                                <div className="info-icn">
                                  <img
                                    src={require('../../../assets/images/keyskill-icon.png')}
                                    alt="img"
                                  />
                                </div>
                                <div className="info-dis-card">
                                  <h3>Business Skills</h3>
                                  {bussinessData?.length !== 0 ? (
                                    <>
                                      {expandBus ? (
                                        <>
                                          {bussinessData?.map((data, index) => {
                                            return (
                                              <div className="skill-card mb-2" key={index}>
                                                <p>
                                                  {data?.skill_name
                                                    ? data?.skill_name
                                                    : 'Not Available'}
                                                </p>
                                                <ProgressBar className={showClassName(data?.value)}>
                                                  <ProgressBar
                                                    striped
                                                    className="progress-bar-improvement"
                                                    now={data?.value / 5}
                                                    key={3}
                                                  />
                                                  <ProgressBar
                                                    striped
                                                    className="progress-bar-fair"
                                                    now={data?.value / 5}
                                                    key={1}
                                                  />
                                                  <ProgressBar
                                                    className="progress-bar-good"
                                                    now={data?.value / 5}
                                                    key={2}
                                                  />
                                                  <ProgressBar
                                                    className="progress-bar-vgood"
                                                    now={data?.value / 5}
                                                    key={2}
                                                  />
                                                  <ProgressBar
                                                    className="progress-bar-excellent"
                                                    now={data?.value / 5}
                                                    key={2}
                                                  />
                                                </ProgressBar>
                                              </div>
                                            );
                                          })}
                                        </>
                                      ) : (
                                        <>
                                          {' '}
                                          {bussinessData?.slice(0, 6)?.map((data, index) => {
                                            return (
                                              <div className="skill-card mb-2" key={index}>
                                                <p>
                                                  {data?.skill_name
                                                    ? data?.skill_name
                                                    : 'Not Available'}
                                                </p>
                                                <ProgressBar className={showClassName(data?.value)}>
                                                  <ProgressBar
                                                    striped
                                                    className="progress-bar-improvement"
                                                    now={data?.value / 5}
                                                    key={3}
                                                  />
                                                  <ProgressBar
                                                    striped
                                                    className="progress-bar-fair"
                                                    now={data?.value / 5}
                                                    key={1}
                                                  />
                                                  <ProgressBar
                                                    className="progress-bar-good"
                                                    now={data?.value / 5}
                                                    key={2}
                                                  />
                                                  <ProgressBar
                                                    className="progress-bar-vgood"
                                                    now={data?.value / 5}
                                                    key={2}
                                                  />
                                                  <ProgressBar
                                                    className="progress-bar-excellent"
                                                    now={data?.value / 5}
                                                    key={2}
                                                  />
                                                </ProgressBar>
                                              </div>
                                            );
                                          })}
                                        </>
                                      )}

                                      {bussinessData?.length > 6 && (
                                        <>
                                          {' '}
                                          {expandBus ? (
                                            <Link
                                              className="Expand-btn"
                                              to="#"
                                              onClick={(e) => {
                                                e.preventDefault(), setExpandBus(false);
                                              }}>
                                              Collapse{' '}
                                              <i className="fas fa-angle-double-up  ms-1"></i>
                                            </Link>
                                          ) : (
                                            <Link
                                              className="Expand-btn"
                                              to="#"
                                              onClick={(e) => {
                                                e.preventDefault(), setExpandBus(true);
                                              }}>
                                              Expand{' '}
                                              <i className="fas fa-angle-double-down ms-1"></i>
                                            </Link>
                                          )}
                                        </>
                                      )}
                                    </>
                                  ) : (
                                    <>No Business skills found at the moment.</>
                                  )}
                                </div>
                              </div>
                              <div className="info-wrap">
                                <div className="info-icn">
                                  <img
                                    src={require('../../../assets/images/keyskill-icon.png')}
                                    alt="img"
                                  />
                                </div>
                                <div className="info-dis-card">
                                  <h3>Inter-Personal Skills</h3>
                                  {personalData?.length !== 0 ? (
                                    <>
                                      {expandPer ? (
                                        <>
                                          {personalData?.map((data, index) => {
                                            return (
                                              <div className="skill-card mb-2" key={index}>
                                                <p>
                                                  {data?.skill_name
                                                    ? data?.skill_name
                                                    : 'Not Available'}
                                                </p>
                                                <ProgressBar className={showClassName(data?.value)}>
                                                  <ProgressBar
                                                    striped
                                                    className="progress-bar-improvement "
                                                    now={data?.value / 5}
                                                    key={3}
                                                  />
                                                  <ProgressBar
                                                    striped
                                                    className="progress-bar-fair"
                                                    now={data?.value / 5}
                                                    key={1}
                                                  />
                                                  <ProgressBar
                                                    className="progress-bar-good"
                                                    now={data?.value / 5}
                                                    key={2}
                                                  />
                                                  <ProgressBar
                                                    className="progress-bar-vgood"
                                                    now={data?.value / 5}
                                                    key={2}
                                                  />
                                                  <ProgressBar
                                                    className="progress-bar-excellent "
                                                    now={data?.value / 5}
                                                    key={2}
                                                  />
                                                </ProgressBar>
                                              </div>
                                            );
                                          })}
                                        </>
                                      ) : (
                                        <>
                                          {' '}
                                          {personalData?.slice(0, 6)?.map((data, index) => {
                                            return (
                                              <div className="skill-card mb-2" key={index}>
                                                <p>
                                                  {data?.skill_name
                                                    ? data?.skill_name
                                                    : 'Not Available'}
                                                </p>
                                                <ProgressBar className={showClassName(data?.value)}>
                                                  <ProgressBar
                                                    striped
                                                    className="progress-bar-improvement"
                                                    now={data?.value / 5}
                                                    key={3}
                                                  />
                                                  <ProgressBar
                                                    striped
                                                    className="progress-bar-fair"
                                                    now={data?.value / 5}
                                                    key={1}
                                                  />
                                                  <ProgressBar
                                                    className="progress-bar-good"
                                                    now={data?.value / 5}
                                                    key={2}
                                                  />
                                                  <ProgressBar
                                                    className="progress-bar-vgood"
                                                    now={data?.value / 5}
                                                    key={2}
                                                  />
                                                  <ProgressBar
                                                    className="progress-bar-excellent"
                                                    now={data?.value / 5}
                                                    key={2}
                                                  />
                                                </ProgressBar>
                                              </div>
                                            );
                                          })}
                                        </>
                                      )}

                                      {personalData?.length > 6 && (
                                        <>
                                          {' '}
                                          {expandPer ? (
                                            <Link
                                              className="Expand-btn"
                                              to="#"
                                              onClick={(e) => {
                                                e.preventDefault(), setExpandPer(false);
                                              }}>
                                              Collapse{' '}
                                              <i className="fas fa-angle-double-up  ms-1"></i>
                                            </Link>
                                          ) : (
                                            <Link
                                              className="Expand-btn"
                                              to="#"
                                              onClick={(e) => {
                                                e.preventDefault(), setExpandPer(true);
                                              }}>
                                              Expand{' '}
                                              <i className="fas fa-angle-double-down ms-1"></i>
                                            </Link>
                                          )}
                                        </>
                                      )}
                                    </>
                                  ) : (
                                    <>No Inter-Personal skills found at the moment.</>
                                  )}
                                </div>
                              </div>
                            </div>

                            <div className="time-line-friends mb-3">
                              <div className="d-flex align-item-center justify-content-between">
                                <h4>
                                  <Link to={`javascript:void(0)`}>
                                    FRIENDS {connectionsList?.friends?.friends_count}
                                  </Link>
                                  {userDetail?.encrypted_id !== userId &&
                                    mutualFriends?.length != 0 && (
                                      <>&nbsp;({mutualFriends?.length} Mutual)</>
                                    )}
                                </h4>
                                <Link
                                  className="text-orange"
                                  // to={`/user/friends/${userId}`}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    navigate(`/user/friends/${userId}`);
                                    setActiveTab('third');
                                  }}>
                                  More
                                </Link>
                              </div>

                              <div className="friends-list">
                                {friendsData?.length !== 0 ? (
                                  <>
                                    {friendsData?.slice(0, 6)?.map((item, index) => {
                                      return (
                                        <>
                                          <div className="friend-lst-icn timeline-friend">
                                            <img
                                              src={
                                                item?.avatar?.complete_url
                                                  ? item?.avatar?.complete_url
                                                  : item?.avatar_url
                                              }
                                              alt="img"
                                            />
                                            <Link
                                              to={`/user/timeline/${item?.encrypted_id}`}
                                              target="_blank">
                                              {item?.name}
                                            </Link>
                                          </div>
                                        </>
                                      );
                                    })}{' '}
                                  </>
                                ) : (
                                  <div className="d-flex w-100 justify-content-center">
                                    <NoDataFound text="Oops, No friends found at the moment." />
                                  </div>
                                )}
                              </div>
                            </div>

                            <div className="time-line-friends">
                              <div className="d-flex align-item-center justify-content-between">
                                <h4>
                                  <Link to="#">PHOTOS</Link>
                                </h4>
                                <Link
                                  className="text-orange"
                                  to=""
                                  onClick={(e) => {
                                    e.preventDefault();
                                    navigate(`/user/albums/${userId}`);
                                    setActiveTab('fourth');
                                  }}>
                                  More
                                </Link>
                              </div>

                              <div className="friends-list">
                                {photosData?.photos?.length > 0 ? (
                                  photosData?.photos?.slice(0, 6)?.map((photo, index) => {
                                    return (
                                      <Link
                                        to="#"
                                        key={index}
                                        onClick={() => handleShow2(photosData, photo, index)}>
                                        <div className="friend-lst-icn">
                                          <img src={photo?.photo_url} alt="img" />
                                        </div>
                                      </Link>
                                    );
                                  })
                                ) : (
                                  <div className="d-flex w-100 justify-content-center">
                                    <NoDataFound text="Oops, No photos found at the moment." />
                                  </div>
                                )}
                              </div>
                            </div>
                          </Col>
                          <Col md={7}>
                            <div className="birth-nws-feed">
                              <Tab.Container
                                id="left-tabs-example"
                                activeKey={show == true ? '' : active}>
                                <div className="birth-feed-tabs">
                                  <Nav variant="pills" className="flex-row">
                                    <Nav.Item>
                                      <Nav.Link
                                        eventKey="social"
                                        onClick={() => {
                                          handleClose();
                                          setActive('social');
                                        }}>
                                        Social
                                      </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                      <Nav.Link
                                        eventKey="professional"
                                        onClick={() => {
                                          handleClose();
                                          setActive('professional');
                                        }}>
                                        Professional
                                      </Nav.Link>
                                    </Nav.Item>
                                  </Nav>
                                </div>
                                <div className="birth-feed-cntent">
                                  <Tab.Content>
                                    <Tab.Pane eventKey="social">
                                      <div className="social-innr-card">
                                        <UserTimeLinePost timeLineData={timeLineData} />
                                      </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="professional">
                                      <div className="social-innr-card">
                                        <LoginFeeds socialProfileProfessional={true} />
                                      </div>
                                    </Tab.Pane>
                                  </Tab.Content>
                                </div>
                              </Tab.Container>
                            </div>
                          </Col>

                          <Tab.Pane eventKey="social">
                            <p>No videos to show at the moment</p>
                          </Tab.Pane>
                        </Row>
                      </Tab.Pane>
                      <Tab.Pane eventKey="second">
                        <UserAbout
                          basicData={timeLineData}
                          educationData={educationData}
                          workData={workData}
                          techData={techData}
                          bussinessData={bussinessData}
                          personalData={personalData}
                          photosData={photosData}
                          friendsData={friendsData}
                          connectionsList={connectionsList}
                          mutualFriends={mutualFriends}
                          setActiveTab={setActiveTab}
                        />
                      </Tab.Pane>
                      <Tab.Pane eventKey="third">
                        <UserFriends
                          resquestHanderFriend={resquestHanderFriend}
                          friendsData={friendsData}
                          // connectionsList={connectionsList}
                          mutualFriends={mutualFriends}
                          loading={isFriendLoading}
                          setActiveTab={setActiveTab}
                          handleSearch={getUserTimelineDetailFriends}
                        />
                      </Tab.Pane>
                      <Tab.Pane eventKey="fourth">
                        <UserAlbums albumData={albumData} />
                        {/* <AlbumManager /> */}
                      </Tab.Pane>
                    </Tab.Content>
                  </div>
                </Col>
                <Col md={3} xs={12}>
                  <div>
                    <PeopleKnow />
                  </div>
                  <div>
                    <WorkAnniversary />
                  </div>
                  <div>
                    <GroupDiscover />
                  </div>
                  <div>
                    <FollowCompany />
                  </div>
                  <div>
                    <CampusNews />
                  </div>
                  <div>
                    <ArticalBlogs />
                  </div>
                  <div>
                    <CampusnewsText />
                  </div>
                  <div>
                    <WhatsNew />
                  </div>
                  <div>
                    <Upcoming />
                  </div>
                  <MiniFooter />
                </Col>
              </Row>
            </Tab.Container>
          </div>
        </Container>
      </section>
      {/* {isLoading ? <Loader /> : null} */}
      <Modal
        show={show2}
        onHide={handleClose2}
        backdrop="static"
        keyboard={false}
        className="timeline-modal"
        size="xl">
        <Modal.Body>
          {loading ? (
            <div className="loader-modal">
              <img src={require('../../../assets/images/Loading_icon-small.gif')} alt="img" />
            </div>
          ) : (
            <div className="timeline-sec">
              <Row className="m-0">
                <Col className="p-0" md={8} sm={8}>
                  <div className="time-line-card bg-blck">
                    <Swiper
                      initialSlide={clickedImage}
                      spaceBetween={50}
                      slidesPerView={1}
                      modules={[Navigation]}
                      navigation={true}
                      onSlideChange={(swiper) => handleGetComments(swiper?.activeIndex)}>
                      {modalData?.photos?.map((item, index) => {
                        return (
                          <SwiperSlide key={index}>
                            <div className="timeline-slide-images">
                              <img src={item?.photo_url} alt="img" />
                            </div>
                          </SwiperSlide>
                        );
                      })}
                    </Swiper>
                  </div>
                </Col>
                <Col className="p-0" md={4} sm={4}>
                  <div className="time-line-cmmnts">
                    <Link className="close-modal-btn" to="#" onClick={handleClose2}>
                      <i className="fas fa-times"></i>
                    </Link>
                    <div className="pofile-upload-card">
                      <div className="profile-upload-sec d-flex align-items-top mb-3">
                        <span className="social-profile-icn me-3">
                          <img
                            src={
                              timeLineData?.userInfo?.mediaObject
                                ? timeLineData?.userInfo?.mediaObject?.path +
                                '.' +
                                timeLineData?.userInfo?.mediaObject?.extension
                                : require('../../../assets/images/default-male-avatar.png')
                            }
                            alt="img"
                          />
                        </span>
                        <div className="social-profile-detail d-flex align-items-top justify-content-between ">
                          <div className="social-profile-name">
                            <h4>
                              <Link to="#">{modalImage?.user?.first_name}</Link> updated his profile
                              picture{' '}
                            </h4>
                            <p>
                              <i className="far fa-clock me-2"></i>
                              {modalImage?.timestamp}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="profile-upload-ftr">
                        <div className="profile-likes-count d-flex align-items-center justify-content-between">
                          <div className="likes-count">
                            <Link to="#">{modalImage?.likes} Likes</Link>
                            <Link to="#">{modalImage?.shares} Shares</Link>
                          </div>
                        </div>
                        <div className="profile-like-btns">
                          <ul>
                            <li>
                              <Link
                                to=""
                                title="Like"
                                onClick={(e) => {
                                  getPostLike(e, modalImage?.id);
                                }}>
                                <i className="fas fa-thumbs-up me-2"></i>Like
                              </Link>
                            </li>
                            <li>
                              <Link to="#" title="Share">
                                <i className="fas fa-share me-2"></i>Share
                              </Link>
                            </li>
                            <li>
                              {modalImage?.is_follow == 1 ? (
                                <Link
                                  to="#"
                                  title="Unfollow this post"
                                  onClick={(e) => {
                                    isFollowUnfollow(e, modalImage?.id, 'modal');
                                  }}>
                                  Unfollow this post
                                </Link>
                              ) : (
                                <Link
                                  to="#"
                                  title="follow this post"
                                  onClick={(e) => {
                                    isFollowUnfollow(e, modalImage?.id, 'modal');
                                  }}>
                                  Follow this post
                                </Link>
                              )}
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="profile-upload-cmmnt mt-3">
                        {modalImage?.latest_comment?.length !== 0 ? (
                          <>
                            {modalImage?.latest_comment?.map((data, index) => (
                              <>
                                <div
                                  className="profile-cmmnt-lst d-flex align-items-top mb-3"
                                  key={index}>
                                  <span className="social-profile-icn me-3">
                                    <img
                                      src={process.env.REACT_APP_API_DOMAIN + data?.user?.avtar}
                                      alt="img"
                                    />
                                  </span>
                                  <div className="profile-cmmnt-user ">
                                    <div className="d-flex align-items-top justify-content-between">
                                      <Link to="#">{data?.user?.first_name}</Link>

                                      <div className="profile-cmmnt-time d-flex align-items-center ">
                                        <span>{data?.latest_comment?.timestamp}Comment</span>
                                      </div>
                                    </div>
                                    <div className="profile-cmmnt-dis">
                                      <p>{data?.text}</p>
                                      <div className="cmmnt-likes d-flex align-items-center">
                                        <Link to="#">
                                          <i className="fas fa-thumbs-up me-1"></i>
                                          {data?.likes}
                                        </Link>
                                        likes
                                      </div>
                                    </div>
                                  </div>
                                  {data?.user?.id === userDetail?.id ? (
                                    <Link className="close-btn" to="#">
                                      <i className="fas fa-times"></i>
                                    </Link>
                                  ) : (
                                    <>
                                      {' '}
                                      <></>
                                    </>
                                  )}
                                </div>
                              </>
                            ))}
                          </>
                        ) : (
                          <></>
                        )}
                        {/* <div className="profile-cmmnt-lst d-flex align-items-top mb-3">
                          <span className="social-profile-icn me-3">
                            <img
                              src={require('../../../../assets/images/default-male-avatar.png')}
                              alt="img"
                            />
                          </span>
                          <div className="profile-cmmnt-user ">
                            <div className="d-flex align-items-top justify-content-between">
                              <Link to="#">userch one</Link>
                            </div>
                            <div className="profile-cmmnt-dis">
                              <p>Congratulations............</p>
                              <div className="cmmnt-likes d-flex align-items-center justify-content-between">
                                <span>3 years ago</span>
                                <div>
                                  <Link to="#">
                                    <i className="fas fa-thumbs-up me-1"></i>
                                    Likes
                                  </Link>
                                  - 10 Likes
                                </div>
                              </div>
                            </div>
                          </div>
                          <Link className="close-btn" to="#">
                            <i className="fas fa-times"></i>
                          </Link>
                        </div> */}

                        <div className="profile-cmmnt-input d-flex align-items-top">
                          <span className="social-profile-icn me-3">
                            <img
                              src={process.env.REACT_APP_API_DOMAIN + modalImage?.user?.avtar}
                              alt="img"
                            />
                          </span>
                          <div className="cmmnt-card">
                            <Form.Control
                              type="text"
                              onChange={(e) => {
                                setModalComment(e.target.value);
                              }}
                              onKeyPress={(e) => {
                                e.key == 'Enter' ? isPostcomment(modalImage?.id, 0, e) : '';
                              }}
                              value={modalComment}
                              placeholder="Write a comment... Press Enter to post"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
}

export default SocialProfile;
