import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import HeaderLogin from '../../commoncomponents/HeaderLogin';
import FooterMain from '../../commoncomponents/FooterMain';
import TopTabbings from './TopTabbings';
import NewsSidebar from '../../commoncomponents/newsfeeds/NewsSidebar';
import { acceptReject, viewApplicationForJobs } from '../../../services/JobServices';
import { useParams } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import showNotification from '../../../services/NotificationService';
import moment from 'moment';
import * as APISERVICES from '../../../services/JobServices';

function JobApplication() {
  const [list, setList] = useState([]);
  const navigate = useNavigate();
  const { id } = useParams();
  const location = useLocation();
  const [detail, setDetail] = useState('');

  useEffect(() => {
    getApplicationsList();
    getJobDetails(id);
  }, []);

  const getJobDetails = (id) => {
    APISERVICES.getSingleJobDetail(id).then((res) => {
      if (res?.status === 200) {
        setDetail(res?.data?.data);
      }
    });
  };

  const getApplicationsList = () => {
    viewApplicationForJobs(id).then((res) => {
      if (res?.status === 200) {
        setList(res?.data?.data);
      }
    });
  };

  const approveDeclineHandler = (id = '', status = '') => {
    acceptReject(id, { status: status }).then((res) => {
      if (res?.status === 200) {
        showNotification('success', res?.data?.message);
        getApplicationsList();
      }
    });
  };

  const downloadHandler = (data) => {
    window.open(
      `${process.env.REACT_APP_API_DOMAIN}jobs/resumeDownload?resume_name=${data?.resume}&user_id=${data?.user_id}`
    );
  };

  return (
    <>
      <HeaderLogin />
      <div className="header-lnk-feeds">
        <div className="container">
          <TopTabbings state={'feed'} />
        </div>
      </div>
      <section className="feed-sec bg-grey py-4">
        <div className="container">
          <Row>
            <Col xs={12} lg={2} md={12}>
              <NewsSidebar state="0" current="posted" />
            </Col>
            <Col xs={12} lg={8} md={12}>
              <div className="job-application-sec bg-white">
                <div className="job-app-mainhd d-flex align-items-center justify-content-between">
                  <h4>{location?.state || detail?.job_title}</h4>
                  <Link to={`/jobs/jobs-details/${id}`} target={'_blank'}>
                    <i className="fas fa-search-plus me-1"></i> View Job Details
                  </Link>
                </div>
                <div className="job-mainhd-sec d-flex align-items-center justify-content-between ">
                  <h6 className="intern-count-hd">
                    {list?.length || 0} person(s) applied for this job.
                  </h6>
                  <Link
                    to="#"
                    onClick={(e) => {
                      e.preventDefault();
                      navigate(-1);
                    }}
                  >
                    Back to listing
                  </Link>
                </div>
                {list &&
                  list?.map((data, index) => (
                    <div className="job-applications-list" key={index}>
                      <div className="job-application-card">
                        <div className="job-application-detail d-flex align-items-top">
                          <Link
                            to={`/user/timeline/${data?.user_id}`}
                            className="job-applc-icn me-3"
                          >
                            {/* <img src={} alt="img" /> */}
                            <img
                              src={
                                data?.users?.avtar
                                  ? process.env.REACT_APP_API_DOMAIN + data?.users?.avtar
                                  : require('../../../assets/images/default-male-avatar.png')
                              }
                              alt="img"
                            />
                          </Link>
                          <div className="job-application-rt">
                            <div className="job-application-dis">
                              <h4>
                                <Link to={`/user/timeline/${data?.user_id}`}>
                                  {data?.users?.first_name} ~
                                </Link>
                              </h4>
                              <h5>
                                <b>Subject:</b>
                                {data?.subject}
                              </h5>
                              <h5>
                                <b>Message:</b>
                                {data?.message}
                              </h5>
                            </div>
                            <div className="down-resume-card d-flex align-items-center justify-content-between">
                              <div className="resume-file d-flex align-items-center">
                                <a
                                  className=""
                                  onClick={(e) => {
                                    e.preventDefault();
                                    downloadHandler(data);
                                  }}
                                  href="/#"
                                  // href={`${process.env.REACT_APP_API_DOMAIN}jobs/resumeDownload?resume_name=${data?.resume}&user_id=${data?.user_id}`}
                                  // target="_blank"
                                  rel="noreferrer"
                                // download
                                >
                                  Download Resume
                                </a>
                                <p>
                                  <b>Applied on:</b> {moment(data?.created || data?.applied_on).format('MMMM Do, YYYY')}
                                </p>
                              </div>
                              <div className=" job-app-approve d-flex align-items-center">
                                {data?.jobAppliedStatus === 'Pending' ? (
                                  <>
                                    <Link
                                      className="approve-btn"
                                      to="#"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        approveDeclineHandler(data?.id, 1);
                                      }}
                                    >
                                      Approve
                                    </Link>
                                    <Link
                                      className="decline-btn ms-2"
                                      to="#"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        approveDeclineHandler(data?.id, 2);
                                      }}
                                    >
                                      Decline
                                    </Link>
                                  </>
                                ) : (
                                  <></>
                                )}
                              </div>
                            </div>
                          </div>
                          <div>
                            {data?.jobAppliedStatus === 'Approved' ? (
                              <span className={`full-tag btn btn-success`}>
                                {data?.jobAppliedStatus}
                              </span>
                            ) : data?.jobAppliedStatus === 'Pending' ? (
                              <span className={`full-tag btn btn-warning`}>
                                {data?.jobAppliedStatus}
                              </span>
                            ) : (
                              <span className={`full-tag btn btn-danger`}>
                                {data?.jobAppliedStatus}
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </Col>
          </Row>
        </div>
      </section>
      <FooterMain className={'colour-bg'} />
    </>
  );
}

export default JobApplication;
