/** @copyright : ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
 @author     : Shiv Charan Panjeta 

 All Rights Reserved.
 Proprietary and confidential :  All information contained herein is, and remains
 the property of ToXSL Technologies Pvt. Ltd. and its partners.
 Unauthorized copying of this file, via any medium is strictly prohibited.
*/
import { useEffect, useState } from 'react';
import { Button, Container } from 'react-bootstrap';
import Alert from 'react-bootstrap/Alert';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import ReactPlayer from 'react-player';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import AsyncSelect from 'react-select/async';
import { ALBUMS, PHOTOS, PRIVATE, PUBLIC, VIDEOS } from '../../globals/constants';
import { imageExtension, videoExtension } from '../../globals/helper';
import {
  addClubGallery,
  deleteSingleImage,
  editGallery,
  getInstituteDrop,
  getSingleDetailGallery
} from '../../services/AuthServices';
import showNotification from '../../services/NotificationService';

let intervalId;
let ytRegex = /^(https?\:\/\/)?(www\.youtube\.com|youtu\.be)\/.+$/i;

export default function AddClubGallery() {
  const { club_id } = useParams();
  const [galleryDetail, setGalleryDetail] = useState({
    club_id: club_id,
    type: PHOTOS,
    title: '',
    privacy: PUBLIC,
    album_name: '',
    banner_title: '',
    location: ''
  });
  const [photos, setPhotos] = useState([]);
  const [previewPhotos, setPreviewPhotos] = useState('');
  const [videos, setVideos] = useState([]);
  const [videosPreview, setVideosPreview] = useState('');
  const [thumbnail, setThumbnail] = useState('');
  const [thumbnailPreview, setThumbnailPreview] = useState('');
  const [album, setAlbums] = useState([]);
  const [albumPreview, setAlbumPreview] = useState('');
  const [value, setValue] = useState('');
  const [clicked, setClicked] = useState(false);
  const [showalert, setShowAlert] = useState(false);
  const [globalMsg, setGlobalMsg] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const [videoType, setVideoType] = useState('browse');
  const [videoUrl, setVideoUrl] = useState('');
  const [videoMultiUrl, setMultiVideoUrl] = useState('');
  const data = location.state;
  const roleType = useSelector((state) => state?.user?.details);

  const alertHandler = () => {
    clearInterval(intervalId);
    intervalId = setInterval(function () {
      setShowAlert(false);
    }, 5000);
  };

  useEffect(() => {
    if (data?.media_id) {
      getDetail(data?.media_id);
    }
  }, [data?.media_id]);

  /**
   * GET SINGLE EMAIL DETAIL
   * @param {*} page
   */

  const getDetail = (id) => {
    getSingleDetailGallery(id).then((resp) => {
      if (resp?.status === 200) {
        let data = resp?.data?.data ? resp?.data?.data : {};
        setValue({
          label: data?.album_details?.name,
          value: data?.album_details?.institute_id
        });
        setGalleryDetail({
          type: data?.album_details?.type,
          title: data?.album_details?.title,
          privacy: data?.album_details?.privacy,
          album_name: data?.album_details?.album_name,
          location: data?.album_details?.location
        });
        if (data?.album_details?.type == 'photo' && data?.list?.length) {
          let arr = [];
          arr.push(data?.list[0].url);
          setPhotos(arr);
          setPreviewPhotos(data?.list[0]?.url);
        }

        if (data?.album_details?.type == 'album' && data?.list?.length) {
          let tempArr = [];
          let arr = [];
          data?.list?.map((data) => {
            tempArr.push({ url: data?.url, id: data?.mainid });
            arr.push(data?.url);
          });
          setAlbumPreview(tempArr);
          setAlbums(arr);
        }

        if (data?.album_details?.type == 'video') {
          //thumbnail
          let arr = [];
          let temp = [];
          let tempArr = [];
          data?.list?.map((data) => {
            if (data?.media_url_link) {
              tempArr.push(data?.media_url_link);
            }
            arr.push(data?.url);
            if (data?.url) {
              temp.push({ url: data?.url, id: data?.mainid });
            }
          });
          setVideosPreview(temp);
          setVideos(arr);
          setMultiVideoUrl(tempArr);
          setThumbnail(data?.album_details?.thumbnail || '');
          if (data?.album_details?.thumbnail) {
            setThumbnailPreview(data?.album_details?.thumbnail);
          }
        }
      }
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setGalleryDetail({
      ...galleryDetail,
      [name]: value
    });
    if (name == 'type') {
      setMultiVideoUrl('');
    }
  };

  const handleFile = (e) => {
    if (galleryDetail?.type == PHOTOS) {
      for (let i = 0; i < e.target.files.length; i++) {
        if (imageExtension.includes(e.target.files[i]?.type)) {
          const arr = [];
          const targetfile = e?.target?.files;
          const targetfileobj = [...targetfile];
          targetfileobj.map((file) => {
            arr.push(URL.createObjectURL(file));
          });
          setPreviewPhotos(arr);
          setPhotos(targetfileobj);
        } else {
          showNotification('danger', 'Please upload photos only');
        }
      }
    }

    if (galleryDetail?.type == VIDEOS) {
      for (let i = 0; i < e.target.files.length; i++) {
        if (videoExtension.includes(e.target.files[i]?.type)) {
          const arr = [];
          const targetfile = e?.target?.files;
          const targetfileobj = [...targetfile];
          targetfileobj.map((file) => {
            arr.push({ url: URL.createObjectURL(file), id: '' });
          });
          setVideos([...targetfileobj, ...videos]);
          setVideosPreview([...arr, ...videosPreview]);
        } else {
          showNotification('danger', 'Please upload videos only');
        }
      }
    }
    if (galleryDetail?.type == ALBUMS) {
      for (let i = 0; i < e.target.files.length; i++) {
        if (imageExtension.includes(e.target.files[i]?.type)) {
          const arr = [];
          const targetfile = e?.target?.files;
          const targetfileobj = [...targetfile];

          targetfileobj.map((file) => {
            arr.push({ url: URL.createObjectURL(file) });
          });

          setAlbumPreview([...albumPreview, ...arr]);
          setAlbums([...album, ...targetfileobj]);
        } else {
          showNotification('danger', 'Please upload valid extenstion');
        }
      }
    }
  };

  const loadInstituteOptions = (inputValue) => {
    return getInstituteDrop(inputValue).then((res) => {
      if (res?.status === 200) {
        let tempArr = [];
        if (res?.data?.length) {
          const data = res?.data;
          data?.map((data) => {
            tempArr.push({
              label: data?.institutename,
              value: data?.institute_id,
              id: data?.institute_id
            });
          });
        }
        return tempArr;
      }
    });
  };

  const validate = () => {
    let errors = {};
    if (value == '' && roleType?.admin_type != '3') {
      errors.institutename = 'This field is required';
    }
    if (galleryDetail?.title?.trim() == '' && galleryDetail?.type != ALBUMS) {
      errors.title = 'This field is required';
    }
    if (galleryDetail?.title?.trim() == '' && galleryDetail?.type == ALBUMS) {
      errors.title = 'This field is required';
    }
    if (photos?.length == 0 && galleryDetail?.type == PHOTOS) {
      errors.photos = 'This field is required';
    }
    if (album?.length == 0 && galleryDetail?.type == ALBUMS) {
      errors.photos = 'This field is required';
    }
    if (videos?.length == 0 && galleryDetail?.type == VIDEOS && videoType == 'browse') {
      errors.photos = 'This field is required';
    }
    if (galleryDetail?.type == VIDEOS && !videoMultiUrl && !videoUrl && videoType != 'browse') {
      errors.photos = 'This field is required';
    } else if (galleryDetail?.type == VIDEOS && !videoMultiUrl && videoType != 'browse') {
      errors.photos = 'click on Add icon to add url';
    }

    return errors;
  };

  const submitHandler = (e) => {
    e.preventDefault();
    setClicked(true);
    if (!Object.keys(validate()).length) {
      let body = {
        club_id: club_id,
        title: galleryDetail?.title,
        album_name: galleryDetail?.album_name,
        privacy: galleryDetail?.privacy,
        type: galleryDetail?.type,
        location: galleryDetail?.location
      };
      let formData = new FormData();
      for (let i in body) {
        formData.append(`${i}`, body[i]);
      }
      if (galleryDetail?.type == PHOTOS) {
        for (let i in photos) {
          formData.append('files[]', photos[i]);
        }
      }
      if (galleryDetail?.type == VIDEOS) {
        for (let i in videos) {
          formData.append('files[]', videos[i]);
        }
        formData.append('thumbnail', thumbnail);
        if (videoMultiUrl) {
          formData.append('url_link', videoMultiUrl.join());
        }
      }
      if (galleryDetail?.type == ALBUMS) {
        for (let i in album) {
          formData.append('files[]', album[i]);
        }
      }

      addClubGallery(formData).then((res) => {
        if (res?.status === 200) {
          showNotification('success', res?.data?.message);
          navigate(-1);
        }
      });
    }
  };

  const UpdateHandler = (e) => {
    e.preventDefault();
    setClicked(true);
    if (!Object.keys(validate()).length) {
      let body = {
        title: galleryDetail?.title,
        album_name: galleryDetail?.album_name,
        privacy: galleryDetail?.privacy,
        type: galleryDetail?.type,
        location: galleryDetail?.location,
        club_id: club_id
      };

      let formData = new FormData();
      for (let i in body) {
        formData.append(`${i}`, body[i]);
      }
      if (galleryDetail?.type == PHOTOS) {
        for (let i in photos) {
          formData.append('files[]', photos[i]);
        }
      }
      if (galleryDetail?.type == VIDEOS) {
        for (let i in videos) {
          formData.append('files[]', videos[i]);
        }
        formData.append('thumbnail[]', thumbnail);
        if (videoMultiUrl) {
          formData.append('url_link', videoMultiUrl.join());
        }
      }
      if (galleryDetail?.type == ALBUMS) {
        for (let i in album) {
          formData.append('files[]', album[i]);
        }
      }

      editGallery(data?.id, value?.value, formData).then((res) => {
        if (res?.status === 200) {
          showNotification('success', res?.data?.message);
          navigate(-1);
        }
      });
    }
  };

  const deleteHandler = (e, id, type, index) => {
    e.preventDefault();
    let temp = false;
    if (type == 'album') {
      if (confirm('Are you sure you want to delete this ?')) {
        let arr = [...albumPreview];
        let tempArr = [...album];
        temp = true;
        if (id) {
          deleteSingleImage(data?.media_id, id).then((res) => {
            if (res?.status == 200) {
              setGlobalMsg(res?.data?.message);
              setShowAlert(true);
              alertHandler();
            }
          });
        }

        if (index > -1) {
          arr.splice(index, 1);
          tempArr.splice(index, 1);
          setAlbumPreview(arr);
          setAlbums(tempArr);
        }
        if (!arr?.length) {
          setAlbumPreview('');
          setAlbums('');
        }
      } else {
        return;
      }
    }
    if (type == 'video') {
      let arr = [...videosPreview];
      let tempArr = [...videos];
      if (id) {
        deleteSingleImage(data?.media_id, id).then((res) => {
          if (res?.status == 200) {
            setGlobalMsg(res?.data?.message);
            setShowAlert(true);
            alertHandler();
          }
        });
      }
      if (index > -1) {
        arr.splice(index, 1);
        tempArr.splice(index, 1);
        setVideosPreview(arr);
        setVideos(tempArr);
      }
      if (!arr?.length) {
        setVideosPreview('');
        setVideos('');
      }
    }

    if (type == 'photos') {
      setPreviewPhotos('');
      setPhotos('');
    }
  };

  const videoTypeHandler = (e) => {
    setVideoUrl('');
    if (!data?.media_id) {
      setVideos('');
      setVideosPreview('');
    }
    setVideoType(e.target.value);
  };

  /**
   * for youtube link
   * @param {*} e
   */
  const addVideoUrlHandler = (e) => {
    e.preventDefault();
    if (!videoUrl) {
      showNotification('danger', 'please enter url');
    } else if (!ytRegex.test(videoUrl)) {
      showNotification('danger', 'please enter valid url');
    } else {
      setMultiVideoUrl([...videoMultiUrl, videoUrl]);
      setVideoUrl('');
    }
  };
  /**
   * for youtube link >delete based on indexing
   * @param {*} e
   */
  const deleteVideoUrlHandler = (e, index) => {
    const rows = [...videoMultiUrl];
    rows.splice(index, 1);
    setMultiVideoUrl(rows);
    if (!rows.length) {
      setMultiVideoUrl('');
    }
  };

  return (
    <>
      <div className="dash-main-content">
        <Container fluid>
          <div className="dash-page-content">
            <div className="dash-page-title">
              <Row>
                <Col xs={12} sm={12} md={12}>
                  <div className="dash-title-bar d-flex align-items-center justify-content-between">
                    <div className="title-lft d-flex align-items-center">
                      <div className="dash-icn">
                        <i className="fas fa-university"></i>
                      </div>
                      <div className="dash-title-dis">
                        <h5>
                          {data?.id ? 'Update' : 'Add'} <span>Gallery</span>
                        </h5>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="dash-content-card">
              <Alert variant={'danger'} className={`${showalert ? '' : 'd-none'}`}>
                {globalMsg}
              </Alert>
              <div className="cmmn-form">
                <Form>
                  <Row>
                    {roleType?.admin_type == '3' ? (
                      <></>
                    ) : (
                      <Col md={4}>
                        {' '}
                        <Form.Group className="mb-3" controlId="formBasicName">
                          <Form.Label>
                            Select Institute <sup className="text-danger">*</sup>
                          </Form.Label>
                          <AsyncSelect
                            className="basic-single"
                            classNamePrefix="select"
                            isSearchable={true}
                            name="institute_id"
                            value={value}
                            loadOptions={loadInstituteOptions}
                            onChange={(e) => {
                              setValue(e);
                            }}
                            placeholder="Search By Institute Name"
                          />
                          <div
                            className={`${
                              clicked && validate()?.institutename ? '' : 'd-none'
                            } danger-frm `}>
                            {clicked && validate()?.institutename}
                          </div>
                        </Form.Group>
                      </Col>
                    )}

                    <Col md={4}>
                      <Form.Group className="mb-3" controlId="formBasicName">
                        <Form.Label>Gallery Type</Form.Label>
                        <Form.Select
                          aria-label="Default select example"
                          name="type"
                          value={galleryDetail?.type}
                          onChange={handleChange}>
                          <option value={PHOTOS}>Photos</option>
                          <option value={VIDEOS}>Videos</option>
                          <option value={ALBUMS}>Album</option>
                        </Form.Select>
                      </Form.Group>
                    </Col>

                    {galleryDetail?.type == ALBUMS ? (
                      <Col md={4}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label>
                            Album Name<sup className="text-danger">*</sup>
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter Album Name"
                            name="title"
                            value={galleryDetail?.title}
                            onChange={handleChange}
                          />
                          <div
                            className={`${
                              clicked && validate()?.title ? '' : 'd-none'
                            } danger-frm `}>
                            {clicked && validate()?.title}
                          </div>
                        </Form.Group>
                      </Col>
                    ) : (
                      <Col md={4}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label>
                            Title <sup className="text-danger">*</sup>
                          </Form.Label>
                          <Form.Control
                            type="title"
                            placeholder="Enter the title"
                            name="title"
                            value={galleryDetail?.title}
                            onChange={handleChange}
                          />
                          <div
                            className={`${
                              clicked && validate()?.title ? '' : 'd-none'
                            } danger-frm `}>
                            {clicked && validate()?.title}
                          </div>
                        </Form.Group>
                      </Col>
                    )}

                    <Col md={4}>
                      <Form.Group className="mb-3" controlId="formBasicName">
                        <Form.Label>Location</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter Location"
                          value={galleryDetail?.location}
                          onChange={handleChange}
                          name="location"
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    {' '}
                    <Col md={4}>
                      <Form.Group className="mb-3" controlId="formBasicName">
                        <Form.Label>Privacy Type</Form.Label>
                        <Form.Select
                          aria-label="Default select example"
                          name="privacy"
                          value={galleryDetail?.privacy}
                          onChange={handleChange}>
                          <option value={PUBLIC}>Public</option>
                          <option value={PRIVATE}>Private</option>
                        </Form.Select>
                      </Form.Group>
                    </Col>
                    <Col md={4}>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Select Thumbnail Image </Form.Label>
                        <Form.Control
                          type="file"
                          placeholder="Enter the title"
                          accept=".png,.jpg,.jpeg"
                          onChange={(e) => {
                            setThumbnailPreview(URL.createObjectURL(e.target.files[0]));
                            setThumbnail(e.target.files[0]);
                          }}
                        />
                      </Form.Group>
                    </Col>
                    <Col md={4} className={`${thumbnailPreview ? '' : 'd-none'}`}>
                      {thumbnailPreview && data?.media_id ? (
                        <>
                          <div className="image-area">
                            <img src={thumbnailPreview} alt="Preview" />
                            {/* <a className="remove-image" onClick={(e) => { deleteHandler(e, "", "video") }} href="#" style={{ display: "inline" }}>&#215;</a> */}
                          </div>
                        </>
                      ) : (
                        thumbnailPreview && (
                          <>
                            <div className="image-area">
                              <img src={thumbnailPreview} alt="Preview" />
                              {/* <a className="remove-image" href="#" onClick={(e) => { deleteHandler(e, "", "video") }} style={{ display: "inline" }}>&#215;</a> */}
                            </div>
                          </>
                        )
                      )}
                    </Col>
                  </Row>

                  <Row>
                    {galleryDetail?.type == VIDEOS && (
                      <Col md={4}>
                        <Form.Group className="mb-3" controlId="formBasicName">
                          <Form.Label>Video option</Form.Label>
                          <Form.Select
                            aria-label="Default select example"
                            name="video_type"
                            defaultValue={'browse'}
                            onChange={(e) => {
                              videoTypeHandler(e);
                            }}>
                            <option value={'youtube'}>Youtube URL</option>
                            {/* <option value={'other'}>Other URL</option> */}
                            <option value={'browse'}>Browse</option>
                          </Form.Select>
                        </Form.Group>
                      </Col>
                    )}
                    {galleryDetail?.type == ALBUMS && (
                      <>
                        <Col md={4}>
                          <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>
                              Select Files <sup className="text-danger">*</sup>
                            </Form.Label>
                            <Form.Control
                              type="file"
                              placeholder="Enter the title"
                              onChange={handleFile}
                              id="album_img"
                              accept=".png,.jpg,.jpeg"
                              multiple
                            />
                            <div
                              className={`${
                                clicked && validate()?.photos ? '' : 'd-none'
                              } danger-frm `}>
                              {clicked && validate()?.photos}
                            </div>
                          </Form.Group>
                        </Col>

                        {albumPreview && data?.media_id
                          ? albumPreview?.map((data, index) => (
                              <div className="image-area mb-2" key={index}>
                                <img src={data?.url} alt="Preview" />
                                <a
                                  className="remove-image"
                                  onClick={(e) => {
                                    deleteHandler(e, data?.id, 'album', index);
                                  }}
                                  href="#"
                                  style={{ display: 'inline' }}>
                                  &#215;
                                </a>
                              </div>
                            ))
                          : albumPreview &&
                            albumPreview?.map((data, index) => (
                              <div className="image-area mb-2" key={index}>
                                <img src={data.url} alt="Preview" />
                                <a
                                  className="remove-image"
                                  href="#"
                                  onClick={(e) => {
                                    deleteHandler(e, '', 'album', index);
                                  }}
                                  style={{ display: 'inline' }}>
                                  &#215;
                                </a>
                              </div>
                            ))}
                      </>
                    )}

                    {galleryDetail?.type == VIDEOS && (
                      <>
                        {videoType == 'browse' ? (
                          <Col md={4}>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                              <Form.Label>
                                Select Videos <sup className="text-danger">*</sup>
                              </Form.Label>
                              <Form.Control
                                type="file"
                                placeholder="Enter the title"
                                accept="video/*"
                                onChange={handleFile}
                                multiple
                              />
                              <div
                                className={`${
                                  clicked && validate()?.photos ? '' : 'd-none'
                                } danger-frm `}>
                                {clicked && validate()?.photos}
                              </div>
                            </Form.Group>
                          </Col>
                        ) : (
                          <Col md={4}>
                            <Form.Group
                              className="mb-3 position-relative"
                              controlId="formBasicEmail">
                              <Form.Label>
                                Video Url <sup className="text-danger">*</sup>
                              </Form.Label>
                              <div className="d-flex">
                                <Form.Control
                                  type="text"
                                  placeholder="Enter url"
                                  id="vid_url"
                                  value={videoUrl}
                                  onChange={(e) => {
                                    // setVideosPreview([{ url: e.target.value }]);
                                    setVideoUrl(e.target.value);
                                  }}
                                />
                                <div
                                  onClick={(e) => {
                                    addVideoUrlHandler(e);
                                  }}
                                  className="ms-2 addicon main-btn-grn btn btn-primary">
                                  +
                                </div>
                              </div>
                              <div
                                className={`${
                                  clicked && validate()?.photos ? '' : 'd-none'
                                } danger-frm `}>
                                {clicked && validate()?.photos}
                              </div>
                            </Form.Group>
                          </Col>
                        )}

                        <Col md={4}>
                          {videosPreview && data?.media_id ? (
                            <>
                              {videosPreview &&
                                videosPreview?.map((data, index) => (
                                  <div className="image-area mt-2" key={index}>
                                    {/* <img src={videosPreview} alt="Preview" /> */}
                                    <ReactPlayer
                                      width="200"
                                      height="150"
                                      url={data?.url}
                                      controls
                                    />
                                    <a
                                      className="remove-video"
                                      href="#"
                                      onClick={(e) => {
                                        deleteHandler(e, data?.id, 'video', index);
                                      }}
                                      style={{ display: 'inline' }}>
                                      &#215;
                                    </a>
                                  </div>
                                ))}
                            </>
                          ) : (
                            videosPreview && (
                              <>
                                {videosPreview &&
                                  videosPreview?.map((data, index) => (
                                    <div className="image-area" key={index}>
                                      {/* <img src={videosPreview} alt="Preview" /> */}
                                      <ReactPlayer
                                        width="200"
                                        height="150"
                                        url={data?.url}
                                        controls
                                      />
                                      <a
                                        className="remove-video"
                                        href="#"
                                        onClick={(e) => {
                                          deleteHandler(e, data?.id, 'video', index);
                                        }}
                                        style={{ display: 'inline' }}>
                                        &#215;
                                      </a>
                                    </div>
                                  ))}
                              </>
                            )
                          )}
                        </Col>
                      </>
                    )}

                    {galleryDetail?.type == PHOTOS && (
                      <>
                        <Col md={4}>
                          <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>
                              Select Photos <sup className="text-danger">*</sup>
                            </Form.Label>
                            <Form.Control
                              type="file"
                              placeholder="Enter the title"
                              onChange={handleFile}
                              accept=".png,.jpg,.jpeg"
                            />
                            <div
                              className={`${
                                clicked && validate()?.photos ? '' : 'd-none'
                              } danger-frm `}>
                              {clicked && validate()?.photos}
                            </div>
                          </Form.Group>
                        </Col>

                        <Col md={4}>
                          {previewPhotos && data?.media_id ? (
                            <>
                              <div className="image-area">
                                <img src={previewPhotos} alt="Preview" />
                                <a
                                  className="remove-image"
                                  href="#"
                                  onClick={(e) => {
                                    deleteHandler(e, '', 'photos');
                                  }}
                                  style={{ display: 'inline' }}>
                                  &#215;
                                </a>
                              </div>
                            </>
                          ) : (
                            previewPhotos && (
                              <>
                                <div className="image-area">
                                  <img src={previewPhotos} alt="Preview" />
                                  <a
                                    className="remove-image"
                                    href="#"
                                    onClick={(e) => {
                                      deleteHandler(e, '', 'photos');
                                    }}
                                    style={{ display: 'inline' }}>
                                    &#215;
                                  </a>
                                </div>
                              </>
                            )
                          )}
                        </Col>
                      </>
                    )}

                    <Col md={4}>
                      {videoMultiUrl &&
                        videoMultiUrl.map((data, index) => (
                          <Form.Group className="mb-3" controlId="formBasicEmail" key={index}>
                            <div className="d-flex">
                              <Form.Control type="text" id="links" value={data} />
                              <div
                                className="ms-2 removeicon main-btn-red btn btn-primary"
                                onClick={(e) => {
                                  deleteVideoUrlHandler(e, index);
                                }}>
                                -
                              </div>
                            </div>
                          </Form.Group>
                        ))}
                    </Col>
                  </Row>

                  <div className="form-btns d-flex align-items-center mt-3">
                    {data?.id ? (
                      <Button className="main-btn-grn" onClick={UpdateHandler}>
                        Update
                      </Button>
                    ) : (
                      <Button className="main-btn-grn" onClick={submitHandler}>
                        Save
                      </Button>
                    )}
                    <strong>OR</strong>
                    <Button
                      className="main-btn-red"
                      onClick={() => {
                        navigate(-1);
                      }}>
                      Cancel
                    </Button>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </>
  );
}
