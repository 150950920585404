/**
 * @copyright : ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
@author    : Shiv Charan Panjeta 
 
All Rights Reserved.
Proprietary and confidential :  All information contained here in is, and remains
the property of ToXSL Technologies Pvt. Ltd. and it's partners.
Unauthorized copying of this file, via any medium is strictly prohibited.
*/

import moment from 'moment';
import { useEffect, useState } from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import LandingBackground from '../../../assets/images/landing_one_banner.png';
import { ABOUT } from '../../../globals/constants';
import { truncate } from '../../../globals/helper';

import {
  checkDomain,
  getAluminiBackground,
  getBlogDetail,
  getHomepageLayout,
  getInsEventList,
  getInsNews,
  getJobList,
  getPageDetail,
  getTestimonial,
  quickLinks
} from '../../../services/InstituteServices';

function InstituteLandingOne() {
  const [state, setState] = useState();
  const [list, setList] = useState([]);
  const [quickLinksList, setQuickLinksList] = useState([]);
  const [blogList, setBlogList] = useState([]);
  const [newsList, setNewsList] = useState([]);
  const [eventList, setEventList] = useState([]);
  const [testimonialList, setTestimonialList] = useState([]);
  const [layoutDetail, setLayoutDetail] = useState('');
  const [about, setAbout] = useState('');
  const [aluminiImg, setAluminiImg] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    checkApi();
    getInfo();
    getAluminiImages();
  }, []);

  const checkApi = () => {
    checkDomain().then((res) => {
      if (res?.status === 200) {
        setState(res?.data?.details);
      }
    });

    getHomepageLayout().then((res) => {
      if (res?.status == 200) {
        setLayoutDetail(res?.data?.data);
      }
    });
  };

  // to get informations
  const getInfo = () => {
    getPageDetail(ABOUT).then((res) => {
      if (res?.status == 200) {
        let data = res?.data?.details ? res?.data?.details[0] : [];
        setAbout(data);
      }
    });

    getJobList().then((res) => {
      if (res?.status == 200) {
        setList(res?.data?.data?.list || []);
      }
    });

    getBlogDetail().then((res) => {
      if (res?.status == 200) {
        setBlogList(res?.data?.data?.details);
      }
    });

    getInsNews().then((res) => {
      if (res?.status == 200) {
        setNewsList(res?.data?.data);
      }
    });

    getInsEventList().then((res) => {
      if (res?.status == 200) {
        setEventList(res?.data?.data?.list);
      }
    });

    getTestimonial().then((res) => {
      if (res?.status == 200) {
        setTestimonialList(res?.data?.data);
      }
    });

    quickLinks().then((res) => {
      if (res?.status == 200) {
        setQuickLinksList(res?.data?.data);
      }
    });
  };

  /**
   * get alumini images for background coverImg
   */
  const getAluminiImages = () => {
    getAluminiBackground().then((res) => {
      if (res?.status == 200) {
        let arr = [];
        let data = res?.data?.data;
        if (res?.data?.data?.length) {
          data?.map((data) => {
            arr.push(process.env.REACT_APP_API_DOMAIN + data?.url + '.' + data?.extension);
          });
          setAluminiImg(arr);
        }
      }
    });
  };

  return (
    <div className="landing-one bg-white">
      {/* <InstituteHeader /> */}

      <Container>
        <section className="institue-banner-landing landing_one">
          <Swiper pagination={true} modules={[Pagination]} className="mySwiper">
            <SwiperSlide>
              <Col sm={12}>
                <div className="banner-img">
                  <img
                    src={
                      layoutDetail?.slider_banner_img
                        ? process.env.REACT_APP_API_URL + layoutDetail?.slider_banner_img
                        : LandingBackground
                    }
                    alt=""
                  />
                </div>
                <Row className="jusitfy-content-center">
                  <Col lg={6} xs={12} sm={12} md={10}>
                    <div className="banner-content text-center">
                      <h1> {layoutDetail?.title || 'PERFECT PLACE TO CONNECT WITH BATCHMATES!'}</h1>
                      {layoutDetail?.sub_title ? (
                        <span dangerouslySetInnerHTML={{ __html: layoutDetail?.sub_title }} />
                      ) : (
                        'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.'
                      )}
                    </div>
                  </Col>
                </Row>
              </Col>
            </SwiperSlide>
          </Swiper>
        </section>
      </Container>

      {about && about?.description && (
        <Container>
          <section className="welcome-section mt-4 px-4">
            <Row>
              <Col md={6} lg={5} sm={12}>
                <div className="welcome-images">
                  <img
                    src={
                      about.banner_file
                        ? process.env.REACT_APP_API_DOMAIN + about.banner_file
                        : require('../../../assets/images/landingone_welcomeimg.png')
                    }
                    alt="img"
                    className="w-100"
                  />
                </div>
              </Col>

              <Col md={6} lg={7} sm={12}>
                <div className="welcome-cont pt-2">
                  <h2>Welcome to</h2>
                  <h3 className="welcome-title">{state?.name}</h3>
                  <span dangerouslySetInnerHTML={{ __html: truncate(about?.description, 150) }} />
                  <Link href="#" className="btn explore">
                    Explore
                  </Link>
                </div>
              </Col>
            </Row>
          </section>
        </Container>
      )}

      <Container>
        <section className="alumni-sec-card py-5">
          <div className="alumni-sec-inner">
            <div className="see_all-box">
              <p>Our</p>
              <h4>Alumni</h4>
              <Link to="/alumni">See All</Link>
            </div>
            <ul className="alumni-list">
              {aluminiImg && aluminiImg?.length ? (
                aluminiImg?.slice(0, 16)?.map((data, index) => (
                  <li key={index}>
                    <img src={data} alt="img" />
                  </li>
                ))
              ) : (
                <>
                  <li>
                    <img
                      src={require('../../../assets/images/institute-img/alumni-1.png')}
                      alt="img"
                    />
                  </li>
                  <li>
                    <img
                      src={require('../../../assets/images/institute-img/alumni-2.png')}
                      alt="img"
                    />
                  </li>
                  <li>
                    <img
                      src={require('../../../assets/images/institute-img/alumni-3.png')}
                      alt="img"
                    />
                  </li>
                  <li>
                    <img
                      src={require('../../../assets/images/institute-img/alumni-4.png')}
                      alt="img"
                    />
                  </li>
                  <li>
                    <img
                      src={require('../../../assets/images/institute-img/alumni-5.png')}
                      alt="img"
                    />
                  </li>
                  <li>
                    <img
                      src={require('../../../assets/images/institute-img/alumni-6.png')}
                      alt="img"
                    />
                  </li>
                  <li>
                    <img
                      src={require('../../../assets/images/institute-img/alumni-7.png')}
                      alt="img"
                    />
                  </li>
                  <li>
                    <img
                      src={require('../../../assets/images/institute-img/alumni-8.png')}
                      alt="img"
                    />
                  </li>
                  <li>
                    <img
                      src={require('../../../assets/images/institute-img/alumni-9.png')}
                      alt="img"
                    />
                  </li>

                  <li>
                    <img
                      src={require('../../../assets/images/institute-img/alumni-10.png')}
                      alt="img"
                    />
                  </li>
                  <li>
                    <img
                      src={require('../../../assets/images/institute-img/alumni-11.png')}
                      alt="img"
                    />
                  </li>
                  <li>
                    <img
                      src={require('../../../assets/images/institute-img/alumni-12.png')}
                      alt="img"
                    />
                  </li>
                  <li>
                    <img
                      src={require('../../../assets/images/institute-img/alumni-13.png')}
                      alt="img"
                    />
                  </li>
                  <li>
                    <img
                      src={require('../../../assets/images/institute-img/alumni-1.png')}
                      alt="img"
                    />
                  </li>
                  <li>
                    <img
                      src={require('../../../assets/images/institute-img/alumni-2.png')}
                      alt="img"
                    />
                  </li>
                  <li>
                    <img
                      src={require('../../../assets/images/institute-img/alumni-3.png')}
                      alt="img"
                    />
                  </li>
                </>
              )}
            </ul>
          </div>
        </section>
      </Container>

      <Container>
        <section className="events-section">
          <Row>
            <Col sm={12} md={6} lg={6}>
              <div className="events-box pt-4">
                <h2 className="pt-4 pe-4 ps-4">Events</h2>
                <ul className="events-list pt-4 pe-4 ps-4">
                  {eventList && eventList?.length ? (
                    eventList?.slice(0, 5)?.map((data, index) => (
                      <li key={index}>
                        <div
                          className="d-flex align-items-center mb-4 pointer"
                          role="presentation"
                          onClick={() => {
                            navigate(`/event-detail-institute/${data?.slug}`);
                          }}>
                          <div className="event-date-box">
                            <h4 className="month">{moment(data?.from_event_date).format('MMM')}</h4>
                            <p className="date">{moment(data?.from_event_date).format('DD')}</p>
                          </div>
                          <div className="event-name-box">
                            <h3>{data?.name}</h3>
                            <p>{data?.venue}</p>
                          </div>
                        </div>
                      </li>
                    ))
                  ) : (
                    <li>Data Not Found</li>
                  )}
                </ul>

                {eventList?.length > 0 && <Link to="/event-institute">See All</Link>}
              </div>
            </Col>
            <Col sm={12} md={6} lg={6}>
              <div className="job-board-box pt-4">
                <h2 className="pt-4 pe-4 ps-4">Job Board</h2>
                <div className="events-list pt-4 pe-4 ps-4">
                  {list && list?.length ? (
                    list?.slice(0, 3)?.map((data, index) => (
                      <Card key={index}>
                        <Card.Body>
                          <div className="d-flex align-items-center justify-content-between mb-3">
                            <div className="job-detail-box">
                              <h3>{data?.job_title}</h3>
                              <p>{data?.company_name}</p>
                            </div>
                            {data?.company_image ? (
                              <div className="job-logo-box">
                                <img
                                  src={
                                    data?.company_image
                                      ? process.env.REACT_APP_API_DOMAIN +
                                        'common/uploads/institute_logo/' +
                                        data?.company_image
                                      : require('../../../assets/images/intitute-jobboard-img.png')
                                  }
                                  alt="img"
                                />
                              </div>
                            ) : (
                              <></>
                            )}
                          </div>
                          <ul className="job-list">
                            <li>
                              {/* <img src={BreifcaseIcon} alt="logo" /> */}
                              <p>{data?.min_exp + '-' + data?.max_exp} years</p>
                            </li>
                            {data?.location != 0 ? (
                              <li>
                                {/* <img src={LocationIcon} alt="logo" /> */}
                                <p>{data?.location}</p>
                              </li>
                            ) : (
                              ''
                            )}
                            <li>
                              {/* <img src={RupeeIcon} alt="logo" /> */}
                              <p>
                                {data?.max_ctc != 0 ? (
                                  <>{data?.min_ctc + '-' + data?.max_ctc} Lacs</>
                                ) : (
                                  <>Not Disclosed</>
                                )}
                              </p>
                            </li>
                          </ul>
                          <p className="posted-time">
                            Posted: {data?.date_time} Job Applicants: {data?.applicaiton_count}
                          </p>
                        </Card.Body>
                      </Card>
                    ))
                  ) : (
                    <div className="text-center nodata">
                      <img src={require('../../../assets/images/nodata.png')} alt="img" />
                      <p>Data Not Found</p>
                    </div>
                  )}
                </div>
                {list?.length > 0 && <Link to="/jobs-board">See All</Link>}
              </div>
            </Col>
          </Row>
        </section>
      </Container>
      <Container>
        <section className="news-update-section position-relative">
          <Row>
            <Col lg={6} md={6} sm={12}>
              <div className="news">
                <div className="p-3">
                  <h3 className="main-title mb-0">News & Updates</h3>
                  {newsList?.list && newsList?.list?.length ? (
                    newsList?.list?.slice(0, 5)?.map((data, index) => (
                      <Card
                        key={index}
                        className="mt-4 pt-2 pointer"
                        role="presentation"
                        onClick={() => {
                          navigate(`/news-detail-page/${data?.slug}`);
                        }}>
                        <Card.Body>
                          <h3 className="subtitle">
                            {moment(data?.date_modified).format('MMM DD,YYYY')}
                          </h3>
                          <h4>{data?.title}</h4>
                        </Card.Body>
                      </Card>
                    ))
                  ) : (
                    <Card className="mt-4 pt-2">
                      <Card.Body>
                        <h4>No Data Found at this moment</h4>
                      </Card.Body>
                    </Card>
                  )}
                </div>
                {newsList?.list?.length && <Link to="/news">See All </Link>}
              </div>
            </Col>
            <Col lg={6} md={6} sm={12} className="pe-lg-0">
              <div className="gallery-img-box position-relative w-100 h-100">
                <div className="cont-box see_all-box w-50">
                  <h4 className="text-center">Gallery</h4>
                  <Link to="/gallery" className="btn see-all mt-0">
                    See All
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
        </section>
      </Container>

      <Container>
        <section className="blog-section">
          <div className="d-flex align-items-center justify-content-between mb-3">
            <h2>Our Blogs</h2>
            {blogList?.length ? <Link to={'/blog'}>See All </Link> : ''}
          </div>
          <Row>
            {blogList && blogList?.length ? (
              blogList?.slice(0, 4)?.map((data, index) => (
                <Col sm={12} md={4} lg={3} key={index}>
                  <Card className="mb-3 mb-lg-0">
                    {/* <img src={require('../../../assets/images/institute-blog-img.png')} alt="img" /> */}
                    <img
                      src={
                        data?.image
                          ? process.env.REACT_APP_API_DOMAIN + 'common/uploads/blog/' + data?.image
                          : require('../../../assets/images/institute-blog-img.png')
                      }
                      alt="img"
                    />
                    <Card.Body>
                      <h3 className="subtitle">
                        {data?.created_on ? moment(data?.created_on).format('MMM DD,YYYY') : ''}
                      </h3>
                      <h4 className="card-title">{data?.title}</h4>
                      <span
                        className="card-text"
                        dangerouslySetInnerHTML={{ __html: truncate(data?.description, 100) }}
                      />
                      <Link to={`/blog-details/${data?.slug}`} className="btn explore mt-0">
                        Explore
                      </Link>
                    </Card.Body>
                  </Card>
                </Col>
              ))
            ) : (
              <Col sm={12} md={4} lg={3}>
                <div className="text-center nodata text-white">
                  <img src={require('../../../assets/images/nodata.png')} alt="img" />
                  <p>Data Not Found</p>
                </div>
              </Col>
            )}
          </Row>
        </section>
      </Container>

      <Container>
        <section className="testimonial-section">
          <Row>
            <Col sm={12} md={6} lg={6}>
              <h4 className="testimonials-title">Few Words from our Alumni</h4>
              <p>
                Alumni Association has been an exceptional effort by the Sri Guru Harkrishan Senior
                Secondary Public School to provide a lifelong link for each one of us to stay
                connected.
              </p>

              <div className="inst-testi-slider mt-lg-3">
                <Swiper
                  spaceBetween={50}
                  slidesPerView={1}
                  modules={[Navigation]}
                  navigation={true}>
                  {testimonialList && testimonialList?.length ? (
                    testimonialList?.map((data, index) => (
                      <SwiperSlide key={index}>
                        <div className="testimonial-s-box">
                          <div className="mb-4">
                            {/*  <img src={require('../../../assets/images/testimonial-slider-img1.png')} alt="img" /> */}
                            <img
                              src={
                                data?.image_file
                                  ? process.env.REACT_APP_API_URL +
                                    'common/uploads/institute_banner/' +
                                    data?.image_file
                                  : require('../../../assets/images/testimonial-slider-img1.png')
                              }
                              alt="img"
                            />
                            <h2 className="client-name">{data?.name}</h2>
                            <p className="client-ocupation">{data?.designation}</p>
                          </div>
                          <p className="client-feedback mb-4">{data?.description}</p>
                        </div>
                      </SwiperSlide>
                    ))
                  ) : (
                    <>No Data Found</>
                  )}
                </Swiper>
              </div>
            </Col>
            <Col lg={{ span: 5, offset: 1 }} md={6} sm={12} className="pe-lg-0">
              <div className="quick-link-box">
                <div className="quick-links">
                  <div className="d-flex align-items-center justify-content-between mb-3">
                    <h3>Quick Links</h3>
                  </div>
                  <ul className="quick-link-list">
                    {quickLinksList?.length > 0 &&
                      quickLinksList?.map((list, index) => {
                        return (
                          <li key={index}>
                            <Link to={list?.slug?.includes('home') ? list?.slug : '/' + list?.slug}>
                              <i className="fas fa-chevron-right me-3"></i>
                              {list?.name}
                            </Link>
                          </li>
                        );
                      })}
                  </ul>
                </div>
                <div className="quick-link-bottom">
                  <div className="d-flex align-items-center">
                    {state?.logo && (
                      <Link to="#">
                        <img
                          src={
                            state?.logo
                              ? process.env.REACT_APP_API_DOMAIN +
                                'common/uploads/institute_logo/' +
                                state?.logo
                              : require('../../../assets/images/institute_logo.png')
                          }
                          alt="logo"
                          width="100px"
                        />
                      </Link>
                    )}

                    <h3 className="institue-title">{state?.name}</h3>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </section>
      </Container>
    </div>
  );
}

export default InstituteLandingOne;
