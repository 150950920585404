/** @copyright : ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
 @author     : Shiv Charan Panjeta 

 All Rights Reserved.
 Proprietary and confidential :  All information contained herein is, and remains
 the property of ToXSL Technologies Pvt. Ltd. and its partners.
 Unauthorized copying of this file, via any medium is strictly prohibited.
*/
import { useEffect, useState } from 'react';
import { Button, Container } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import AsyncSelect from 'react-select/async';
import { emailRegex, restrictNum, restrictSpecialChar } from '../../globals/helper';
import useDocumentTitle from '../../globals/useDocumentTitle';
import { ShowAlert } from '../../redux/action';
import { addAdmin, adminDetail, updateAdmin } from '../../services/AuthServices';
import {
  getAllAdminAccessType,
  getAllAdminRole,
  loadAllInstituteOptions,
  loadDepInstituteOptions,
  loadDeprtmentOptions,
  loadUniOptions
} from '../AdminHelper';

export default function AddadminUser() {
  useDocumentTitle('Add Admin  | AlmaBay');
  const dispatch = useDispatch();
  const [newAdminDetail, setNewAdminDetail] = useState({
    username: '',
    admin_name: '',
    admin_email: '',
    admin_password: '',
    admin_type: '',
    access_type: 'admin'
  });
  const [accessTypeDrop, setAccessTypeDrop] = useState([]);
  const [roleDropData, setRoleDropData] = useState([]);
  const [university, setUniversity] = useState('');
  const [institute, setInstitute] = useState([]);
  const [departInstitute, setDepartInstitute] = useState('');
  const [department, setDepartment] = useState('');
  const [clicked, setClicked] = useState(false);
  const [selectLogo, setSelectedLogo] = useState('');
  const [editPassCheck, setEditPassCheck] = useState(false);
  const institute_ids = institute?.map((data) => data?.id);
  const location = useLocation();
  const id = location.state;
  const navigate = useNavigate();
  useEffect(() => {
    getAllAdminAccessType(setAccessTypeDrop);
    getAllAdminRole(setRoleDropData);
    if (id) {
      getDetail(id);
    }
  }, []);

  const getDetail = (id) => {
    adminDetail(id).then((res) => {
      if (res?.status === 200) {
        const data = res?.data?.data?.details;
        let dep = res?.data?.data?.department_ids;
        let uni = res?.data?.data?.university_ids;

        if (dep?.length) {
          setDepartment({
            id: dep[0]?.institute_id,
            label: dep[0]?.name,
            value: dep[0]?.name
          });
        }

        if (uni?.length) {
          setUniversity({
            id: uni[0]?.institute_id,
            label: uni[0]?.name,
            value: uni[0]?.name
          });
        }

        if (res?.data?.data?.institute_ids?.length) {
          if (res?.data?.data?.details?.access_type == 'department') {
            let data = res?.data?.data?.institute_ids[0];
            setDepartInstitute({
              id: data?.institute_id,
              label: data?.name,
              value: data?.name
            });
          } else {
            let arr = [];
            res?.data?.data?.institute_ids?.map((data) => {
              arr.push({
                id: data?.institute_id,
                label: data?.name,
                value: data?.name
              });
            });
            setInstitute(arr);
          }
        }
        setNewAdminDetail({
          username: data?.username,
          admin_heading: data?.admin_heading,
          admin_name: data?.admin_name,
          admin_email: data?.admin_email,
          admin_password: data?.admin_password,
          admin_type: data?.admin_type,
          access_type: data?.access_type,
          id: data?.admin_id
        });
      }
    });
  };

  const handleChange = (e) => {
    let { name, value } = e.target;
    setNewAdminDetail({
      ...newAdminDetail,
      [name]: value
    });
  };

  const handleFile = (e) => {
    setSelectedLogo(e.target.files[0]);
  };

  function hasWhiteSpace(s) {
    return s.indexOf(' ') >= 0;
  }

  const validate = () => {
    let errors = {};
    if (newAdminDetail?.username == '') {
      errors.username = 'Please enter username';
    } else if (hasWhiteSpace(newAdminDetail?.username)) {
      errors.username = 'Please enter username without space';
    }
    if (newAdminDetail?.admin_name == '') {
      errors.admin_name = 'Please enter name';
    }
    if (newAdminDetail?.admin_email == '') {
      errors.admin_email = 'Please enter your email';
    } else if (!emailRegex.test(newAdminDetail?.admin_email)) {
      errors.admin_email = 'Please enter valid email';
    }
    if (newAdminDetail?.admin_password == '') {
      errors.admin_password = 'Please enter your password';
    }
    if (newAdminDetail?.admin_password !== '' && newAdminDetail?.admin_password?.length < 6) {
      errors.admin_password = 'Password should be more than 6 digits';
    }
    if (newAdminDetail?.admin_type == '') {
      errors.admin_type = 'Please select admin role';
    }
    if ((newAdminDetail?.access_type == 'university' && university == '') || university?.id == 0) {
      errors.university = 'Please select university ';
    }
    if (newAdminDetail?.access_type == 'institute' && institute?.length == 0) {
      errors.institute = 'Please select institute';
    }

    if (newAdminDetail?.access_type == 'department' && departInstitute == '') {
      errors.departInstitute = 'Please select institute';
    }
    if (newAdminDetail?.access_type == 'department' && department == '') {
      errors.department = 'Please select department';
    }
    return errors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setClicked(true);

    if (!Object.keys(validate()).length) {
      let body = {};
      if (newAdminDetail?.access_type == 'admin') {
        if (selectLogo == '') {
          body = newAdminDetail;
        } else {
          body = Object.assign(
            {},
            {
              username: newAdminDetail?.username,
              admin_name: newAdminDetail?.admin_name,
              admin_email: newAdminDetail?.admin_email,
              admin_password: newAdminDetail?.admin_password,
              admin_type: newAdminDetail?.admin_type,
              access_type: 'admin'
            },
            { admin_logo: selectLogo }
          );
        }
      }

      if (newAdminDetail?.access_type == 'university') {
        if (selectLogo == '') {
          body = { ...newAdminDetail, university_ids: university?.id };
        } else {
          body = Object.assign(
            {},
            {
              username: newAdminDetail?.username,
              admin_name: newAdminDetail?.admin_name,
              admin_email: newAdminDetail?.admin_email,
              admin_password: newAdminDetail?.admin_password,
              admin_type: newAdminDetail?.admin_type,
              access_type: newAdminDetail?.access_type,
              university_ids: university?.id
            },
            { admin_logo: selectLogo }
          );
        }
      }

      if (newAdminDetail?.access_type == 'institute') {
        if (selectLogo == '') {
          body = { ...newAdminDetail, institute_ids };
        } else {
          body = Object.assign(
            {},
            {
              username: newAdminDetail?.username,
              admin_name: newAdminDetail?.admin_name,
              admin_email: newAdminDetail?.admin_email,
              admin_password: newAdminDetail?.admin_password,
              admin_type: newAdminDetail?.admin_type,
              access_type: newAdminDetail?.access_type,
              institute_ids
            },
            { admin_logo: selectLogo }
          );
        }
      }

      if (newAdminDetail?.access_type == 'department') {
        if (selectLogo == '') {
          body = {
            ...newAdminDetail,
            institute_ids: departInstitute?.id,
            department: department?.id
          };
        } else {
          body = Object.assign(
            {},
            {
              username: newAdminDetail?.username,
              admin_name: newAdminDetail?.admin_name,
              admin_email: newAdminDetail?.admin_email,
              admin_password: newAdminDetail?.admin_password,
              admin_type: newAdminDetail?.admin_type,
              access_type: newAdminDetail?.access_type,
              institute_ids: departInstitute?.id,
              department: department?.id
            },
            { admin_logo: selectLogo }
          );
        }
      }

      let formData = new FormData();
      for (let i in body) {
        formData.append(`${i}`, body[i]);
      }

      addAdmin(formData).then((res) => {
        if (res?.status === 200) {
          dispatch(ShowAlert(res?.data?.message));
          navigate('/admin/users/showAllAdmins');
        }
      });
    }
  };
  const handleUpdate = (e) => {
    e.preventDefault();
    setClicked(true);

    if (!Object.keys(validate()).length) {
      if (editPassCheck === false) {
        delete newAdminDetail?.admin_password;
      }
      let body = {};
      if (newAdminDetail?.access_type == 'admin') {
        if (selectLogo == '') {
          body = newAdminDetail;
        } else {
          body = Object.assign(
            {},
            {
              username: newAdminDetail?.username,
              admin_name: newAdminDetail?.admin_name,
              admin_email: newAdminDetail?.admin_email,
              admin_password: newAdminDetail?.admin_password,
              admin_type: newAdminDetail?.admin_type,
              access_type: 'admin'
            },
            { admin_logo: selectLogo }
          );
        }
      }

      if (newAdminDetail?.access_type == 'university') {
        if (selectLogo == '') {
          body = { ...newAdminDetail, university_ids: university?.id };
        } else {
          body = Object.assign(
            {},
            {
              username: newAdminDetail?.username,
              admin_name: newAdminDetail?.admin_name,
              admin_email: newAdminDetail?.admin_email,
              admin_password: newAdminDetail?.admin_password,
              admin_type: newAdminDetail?.admin_type,
              access_type: newAdminDetail?.access_type,
              university_ids: university?.id
            },
            { admin_logo: selectLogo }
          );
        }
      }

      if (newAdminDetail?.access_type == 'institute') {
        if (selectLogo == '') {
          body = { ...newAdminDetail, institute_ids };
        } else {
          body = Object.assign(
            {},
            {
              username: newAdminDetail?.username,
              admin_name: newAdminDetail?.admin_name,
              admin_email: newAdminDetail?.admin_email,
              admin_password: newAdminDetail?.admin_password,
              admin_type: newAdminDetail?.admin_type,
              access_type: newAdminDetail?.access_type,
              institute_ids
            },
            { admin_logo: selectLogo }
          );
        }
      }

      if (newAdminDetail?.access_type == 'department') {
        if (selectLogo == '') {
          body = {
            ...newAdminDetail,
            institute_ids: departInstitute?.id,
            department: department?.id
          };
        } else {
          body = Object.assign(
            {},
            {
              username: newAdminDetail?.username,
              admin_name: newAdminDetail?.admin_name,
              admin_email: newAdminDetail?.admin_email,
              admin_password: newAdminDetail?.admin_password,
              admin_type: newAdminDetail?.admin_type,
              access_type: newAdminDetail?.access_type,
              institute_ids: departInstitute?.id,
              department: department?.id
            },
            { admin_logo: selectLogo }
          );
        }
      }

      let formData = new FormData();
      for (let i in body) {
        formData.append(`${i}`, body[i]);
      }

      updateAdmin(formData).then((res) => {
        if (res?.status === 200) {
          dispatch(ShowAlert(res?.data?.message));
          navigate('/admin/users/showAllAdmins');
        }
      });
    }
  };

  return (
    <div className="dash-main-content">
      <Container fluid>
        <div className="dash-page-content">
          <div className="dash-page-title">
            <Row>
              <Col xs={12} sm={12} md={12}>
                <div className="dash-title-bar d-flex align-items-center justify-content-between">
                  <div className="title-lft d-flex align-items-center">
                    <div className="dash-title-dis">
                      <h5>{id ? 'Edit' : 'Add New'} Admin</h5>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <div className="dash-content-card">
            <div className="cmmn-form">
              <Form>
                <Form.Group className="mb-3" controlId="formBasicName">
                  <Form.Label>
                    Username <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Username"
                    name="username"
                    onChange={handleChange}
                    onKeyPress={restrictSpecialChar}
                    value={newAdminDetail?.username}
                  />
                  <div className={'danger-frm'}>{clicked && validate().username}</div>
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicName">
                  <Form.Label>
                    Full Name <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Name"
                    name="admin_name"
                    onChange={handleChange}
                    onKeyDown={(e) => restrictNum(e)}
                    value={newAdminDetail?.admin_name}
                  />
                  <div className={'danger-frm'}>{clicked && validate().admin_name}</div>
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>
                    Email <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Email"
                    name="admin_email"
                    onChange={handleChange}
                    value={newAdminDetail?.admin_email}
                  />
                  <div className={'danger-frm'}>{clicked && validate().admin_email}</div>
                </Form.Group>
                {id && (
                  <Form.Group className="mb-3" controlId="formBasicCheckbox">
                    <Form.Check
                      type="checkbox"
                      label="Do you want to change Password?"
                      onChange={() => setEditPassCheck(!editPassCheck)}
                    />
                  </Form.Group>
                )}
                {!id || editPassCheck ? (
                  <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label>
                      Password <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Password"
                      name="admin_password"
                      onChange={handleChange}
                    />
                    <div className={'danger-frm'}>{clicked && validate().admin_password}</div>
                  </Form.Group>
                ) : (
                  <></>
                )}

                <Form.Group className="mb-3" controlId="formBasicState">
                  <Form.Label>
                    Select Admin Role <span className="text-danger">*</span>{' '}
                  </Form.Label>
                  <Form.Select
                    aria-label="Default select example"
                    name="admin_type"
                    value={newAdminDetail?.admin_type}
                    onChange={handleChange}>
                    {roleDropData?.length !== 0 ? (
                      <>
                        <option value="">Select Type</option>
                        {roleDropData?.map((data, index) => {
                          return (
                            <option key={index} value={data?.id}>
                              {data?.role_name}
                            </option>
                          );
                        })}
                      </>
                    ) : (
                      <option disabled>No data found</option>
                    )}
                  </Form.Select>
                  <div className={'danger-frm'}>{clicked && validate().admin_type}</div>
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicState">
                  <Form.Label>
                    Access Type <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Select
                    aria-label="Default select example"
                    name="access_type"
                    onChange={handleChange}
                    value={newAdminDetail?.access_type}>
                    {accessTypeDrop?.length !== 0 ? (
                      <>
                        {accessTypeDrop?.map((data, index) => {
                          return (
                            <option key={index} value={data?.access_slug}>
                              {data?.access_name}
                            </option>
                          );
                        })}
                      </>
                    ) : (
                      <option disabled>No data found</option>
                    )}
                  </Form.Select>
                </Form.Group>
                {newAdminDetail?.access_type !== 'admin' ? (
                  <>
                    <Form.Group className="mb-3" controlId="formBasicState">
                      {newAdminDetail?.access_type === 'university' && (
                        <>
                          <Form.Label>
                            Select University <span className="text-danger">*</span>
                          </Form.Label>
                          <AsyncSelect
                            isClearable={true}
                            cacheOptions
                            defaultOptions
                            value={university}
                            getOptionLabel={(e) => e.label}
                            getOptionValue={(e) => e.value}
                            loadOptions={loadUniOptions}
                            className="basic-single"
                            name="location"
                            onChange={setUniversity}
                          />
                          <div className={'danger-frm'}>{clicked && validate().university}</div>
                        </>
                      )}
                    </Form.Group>
                    {newAdminDetail?.access_type === 'institute' && (
                      <>
                        <Form.Group className="mb-3" controlId="formBasicState">
                          <Form.Label>
                            Select institute <span className="text-danger">*</span>
                          </Form.Label>
                          <AsyncSelect
                            isMulti
                            isClearable={true}
                            defaultOptions
                            value={institute}
                            getOptionLabel={(e) => e.label}
                            getOptionValue={(e) => e.value}
                            loadOptions={loadAllInstituteOptions}
                            className="basic-single"
                            placeholder="select institute from list"
                            name="location"
                            onChange={setInstitute}
                          />
                          <div className={'danger-frm'}>{clicked && validate().institute}</div>
                        </Form.Group>
                      </>
                    )}
                    {newAdminDetail?.access_type === 'department' && (
                      <>
                        <Form.Group className="mb-3" controlId="formBasicState">
                          <Form.Label>
                            Select institute <span className="text-danger">*</span>
                          </Form.Label>
                          <AsyncSelect
                            isClearable={true}
                            cacheOptions
                            defaultOptions
                            value={departInstitute}
                            getOptionLabel={(e) => e.label}
                            getOptionValue={(e) => e.value}
                            loadOptions={loadDepInstituteOptions}
                            className="basic-single"
                            name="location"
                            onChange={(e) => {
                              setDepartInstitute(e);
                              loadDeprtmentOptions('', e.id);
                            }}
                          />
                          <div className={'danger-frm'}>
                            {clicked && validate().departInstitute}
                          </div>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicState">
                          <Form.Label>
                            Select department <span className="text-danger">*</span>
                          </Form.Label>
                          <AsyncSelect
                            isClearable={true}
                            defaultOptions
                            value={department}
                            getOptionLabel={(e) => e.label}
                            getOptionValue={(e) => e.value}
                            loadOptions={(e) => loadDeprtmentOptions(e, departInstitute?.id)}
                            className="basic-single"
                            name="location"
                            onChange={setDepartment}
                          />
                          <div className={'danger-frm'}>{clicked && validate().department}</div>
                        </Form.Group>
                      </>
                    )}
                  </>
                ) : (
                  <></>
                )}

                <Form.Group className="mb-3" controlId="formBasicHeading">
                  <Form.Label>Logo Heading</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Heading"
                    value={newAdminDetail?.admin_heading}
                    name="admin_heading"
                    onChange={handleChange}
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicLogo">
                  <Form.Label>Logo</Form.Label>
                  <Form.Control type="file" onChange={handleFile} />
                </Form.Group>
                <div className="form-btns d-flex align-items-center mt-5">
                  <Button
                    className="main-btn-grn"
                    type="button"
                    onClick={id ? handleUpdate : handleSubmit}>
                    Save
                  </Button>
                  <strong>OR</strong>
                  <Button
                    className="main-btn-red"
                    onClick={() => navigate('/admin/users/showAllAdmins')}>
                    Cancel
                  </Button>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}
