import HeaderInner from '../../commoncomponents/HeaderInner';
import FooterMain from '../../commoncomponents/FooterMain';
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';

function AlmabayCanHelp() {
  return (
    <>
      <HeaderInner />
      <section className="how_can_almabayhelp">
        <div className="title-block">
          <h1>How AlmaBay Can Help You?</h1>
        </div>
      </section>
      <section className=" almabay_Benefits py-5">
        <Container>
          <div className="text-center">
            <h2>Benefits for Alumni And Students</h2>
          </div>
          <h3>Why should you have or register on an Alumni Network?</h3>
          <p>
            You and your fellow alumni are emotionally connected and would always be ready to help
            each other and your almamater. Almabay helps form, strengthen and nurture the alumni
            networks. Almabay helps you:
          </p>
          <div className="icons-set">
            <ul className="list-unstyled">
              <li>
                <div className="d-flex align-items-start">
                  <span className="why-icons users">&nbsp;</span>
                  <div className="media-body">
                    <h4 className="media-heading">Connect with Your Ex-Batch Mates</h4>
                    <p>
                      Register and browse profiles and get to know what your fellow alumni are upto.
                      Get connected through our social networking tool and get to know each other.
                      Join your Alumni group and start a discussion.
                    </p>
                  </div>
                </div>
              </li>
              <li>
                <div className="d-flex align-items-start">
                  <span className="why-icons relive">&nbsp;</span>
                  <div className="media-body">
                    <h4 className="media-heading">Relive your Memories</h4>
                    <p>
                      Share your memories and relive them. Share your pictures and videos and relive
                      old days among your friends.
                    </p>
                  </div>
                </div>
              </li>
              <li>
                <div className="d-flex align-items-start">
                  <span className="why-icons jobs">&nbsp;</span>
                  <div className="media-body">
                    <h4 className="media-heading">Leverage your Network And Grab opportunities</h4>
                    <p>
                      Search people by Company, Profession and Location. Find Them on Google maps.
                      Post jobs, seek advice, request mentoring. Register to Grab opportunities.
                    </p>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </Container>
      </section>
      <section className=" almabay_Benefits py-5">
        <Container>
          <div className="text-center">
            <h2>Benefits for Schools &amp; Colleges</h2>
          </div>
          <h3>Why should you build and invest in an Alumni Network?</h3>
          <p>
            Alumni share a strong emotional bonding and are always looking for channels to connect
            and give back to their almamater. Almabay enables a healthy alumni network that will
            help you:
          </p>
          <div className="icons-set">
            <ul className="list-unstyled">
              <li>
                <div className="d-flex align-items-start">
                  <span className="why-icons database">&nbsp;</span>
                  <div className="media-body">
                    <h4 className="media-heading">Create and Maintain an Updated Database</h4>
                    <p>
                      Alumni Profiles synced with Facebook &amp; Linkedin. Get an updated database.
                    </p>
                  </div>
                </div>
              </li>
              <li>
                <div className="d-flex align-items-start">
                  <span className="why-icons funds">&nbsp;</span>
                  <div className="media-body">
                    <h4 className="media-heading">Raise Funds For Your Almamater</h4>
                    <p>
                      Create fundraising campaigns and collect donations for various initiatives in
                      the institution. Sell Official Memorabilia through the integrated Store to
                      raise additional funds.
                    </p>
                  </div>
                </div>
              </li>
              <li>
                <div className="d-flex align-items-start">
                  <span className="why-icons referral">&nbsp;</span>
                  <div className="media-body">
                    <h4 className="media-heading">Boost Placements Through Referrals</h4>
                    <p>
                      Search Alumni by Company and Role. Alumni can help to boost job and internship
                      opportunities for the current students. Also, Alumni can be mentors to
                      students and also be invited for guest lectures. Effective alumni engagement
                      enhances overall student development.
                    </p>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </Container>
      </section>
      <section className="register-now-section">
        <Container>
          <h2>Register Now With AlmaBay</h2>
          <div className="reg-icons">
            <Link className="btn-custom btn-orange one-button" to="/register">
              Register as Alumni
            </Link>
            <Link className="btn-custom btn-orange one-button" to="/contact-us" target="_blank">
              Register as Institute
            </Link>
          </div>
        </Container>
      </section>
      <section className="who_using_almabay">
        <Container>
          <h2>Who is Using AlmaBay?</h2>
          <span className="subtext">
            30,000 Alumni &amp; 40 Institutes use AlmaBay to stay connected.
          </span>
          <Row className="mt-5">
            <Col sm={3}>
              <div className="one-logo">
                <div className="logo-img">
                  <img src={require('../../../assets/images/bhawan.png')} alt="" />
                </div>
                <div className="client-name">Bhawan Old Students Association, Panchkula</div>
              </div>
            </Col>
            <Col sm={3}>
              <div className="one-logo">
                <div className="logo-img">
                  <img src={require('../../../assets/images/shes.png')} alt="" />
                </div>
                <div className="client-name">Sacred Heart, Chandigarh</div>
              </div>
            </Col>
            <Col sm={3}>
              <div className="one-logo">
                <div className="logo-img">
                  <img src={require('../../../assets/images/bhawan.png')} alt="" />
                </div>
                <div className="client-name">Bhawan Old Students Association, Panchkula</div>
              </div>
            </Col>
            <Col sm={3}>
              <div className="one-logo">
                <div className="logo-img">
                  <img src={require('../../../assets/images/shes.png')} alt="" />
                </div>
                <div className="client-name">Sacred Heart, Chandigarh</div>
              </div>
            </Col>
            <Col sm={3}>
              <div className="one-logo">
                <div className="logo-img">
                  <img src={require('../../../assets/images/bhawan.png')} alt="" />
                </div>
                <div className="client-name">Bhawan Old Students Association, Panchkula</div>
              </div>
            </Col>
            <Col sm={3}>
              <div className="one-logo">
                <div className="logo-img">
                  <img src={require('../../../assets/images/shes.png')} alt="" />
                </div>
                <div className="client-name">Sacred Heart, Chandigarh</div>
              </div>
            </Col>
            <Col sm={3}>
              <div className="one-logo">
                <div className="logo-img">
                  <img src={require('../../../assets/images/bhawan.png')} alt="" />
                </div>
                <div className="client-name">Bhawan Old Students Association, Panchkula</div>
              </div>
            </Col>
            <Col sm={3}>
              <div className="one-logo">
                <div className="logo-img">
                  <img src={require('../../../assets/images/shes.png')} alt="" />
                </div>
                <div className="client-name">Sacred Heart, Chandigarh</div>
              </div>
            </Col>
          </Row>
          <div className="pt-5">
            <Link className="btn-custom btn-orange one-button" to="/contact-us" target="_blank">
              Register as Institute
            </Link>
          </div>
        </Container>
      </section>

      <FooterMain className={'colour-bg'} />
    </>
  );
}

export default AlmabayCanHelp;
