import { useState, useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import HeaderLogin from '../../commoncomponents/HeaderLogin';
import TopTabbings from './TopTabbings';
import { Link } from 'react-router-dom';
import NewsSidebar from '../../commoncomponents/newsfeeds/NewsSidebar';

import WhatsNew from '../../commoncomponents/newsfeeds/WhatsNew';
import Upcoming from '../../commoncomponents/newsfeeds/Upcoming';
import PeopleKnow from '../../commoncomponents/newsfeeds/PeopleKnow';
import FollowCompany from '../../commoncomponents/newsfeeds/FollowCompany';
import WorkAnniversary from '../../commoncomponents/newsfeeds/WorkAnniversary';
import CampusNews from '../../commoncomponents/newsfeeds/CampusNews';
import CampusnewsText from '../../commoncomponents/newsfeeds/CampusnewsText';
import ArticalBlogs from '../../commoncomponents/newsfeeds/ArticalBlogs';
import GroupDiscover from '../../commoncomponents/newsfeeds/GroupDiscover';
import * as APISERVICES from '../../../services/SocialServices';
import useDocumentTitle from '../../../globals/useDocumentTitle';
import { MiniFooter } from '../../MiniFooter';
import Loader from '../../commoncomponents/loader/loader-large';

function JobfairApplication() {
  useDocumentTitle('AlmaBay');
  const [jobApplication, setJobApplication] = useState([]);
  const [loading, setIsLoading] = useState(false);
  useEffect(() => {
    getJobFairApplication();
  }, []);

  const getJobFairApplication = () => {
    setIsLoading(true);
    APISERVICES.getApplicationOfFair().then((res) => {
      if (res?.status === 200) {
        setIsLoading(false);
        const data = res?.data?.data?.jobfair;
        setJobApplication(data);
      } else {
        setIsLoading(false);
      }
    });
  };

  const downloadHallTicket = (e, id) => {
    e.preventDefault();
    setIsLoading(true);
    APISERVICES.getHallTicket(id).then((res) => {
      if (res?.status === 200) {
        setIsLoading(false);
        window.open(`${process.env.REACT_APP_API_URL}${res?.data?.data}`);
        // const data = res?.data?.data?.jobfair;
        // setJobApplication(data);
      } else {
        setIsLoading(false);
      }
    });
  };
  return (
    <>
      <HeaderLogin />
      <div className="header-lnk-feeds">
        <div className="container">
          <TopTabbings state={'feed'} />
        </div>
      </div>
      <section className="news-feed-sec bg-grey py-4">
        <Container>
          <Row>
            <Col xs={12} lg={2}>
              <NewsSidebar state="0" current="job-fair-hall-tickets" />
            </Col>
            <Col xs={12} lg={9} xl={7} className="mb-4">
              <div className="job_application">
                <div className="job_application_card bg-white mb-3">
                  <div className="job_application_head">
                    <h4>Jobfair Applications</h4>
                  </div>
                  {jobApplication?.length !== 0 ? (
                    <>
                      {jobApplication?.map((data, index) => {
                        return (
                          <div className="job_application_details" key={index}>
                            <div className="d-flex justify-content-between align-items-start">
                              <h5 className="anchor_hover fw-bold">
                                <Link to="#">{data?.title}</Link>
                              </h5>

                              <Link
                                to="#"
                                className="d_btn_layout"
                                onClick={(e) => downloadHallTicket(e, data?.id)}
                              >
                                Hall Ticket
                              </Link>
                            </div>

                            <p> venue :{data?.venue}</p>
                            <p>Start Date :{data?.start_date}</p>
                            <p>End date :{data?.end_date},</p>

                            <Link to={`/user/jobfairDetails/${data?.id}`}>
                              <h5 className="fw-bold anchor_normal">read more</h5>
                            </Link>
                          </div>
                        );
                      })}
                    </>
                  ) : (
                    <div className="p-5 text-center">
                      <img
                        src={require('../../../assets/images/small-error.png')}
                        width="105px"
                        alt="img"
                      />
                      <p>No Job Application found at the moment.</p>
                    </div>
                  )}

                  <div className="nav_border"></div>
                </div>
              </div>
            </Col>
            <Col xl={{ span: 3, offset: 0 }} lg={{ span: 9, offset: 2 }}>
              <div>
                <PeopleKnow />
              </div>
              <div>
                <WorkAnniversary />
              </div>
              <div>
                <GroupDiscover />
              </div>
              <div>
                <FollowCompany />
              </div>
              <div>
                <CampusNews />
              </div>
              <div>
                <ArticalBlogs />
              </div>
              <div>
                <CampusnewsText />
              </div>
              <div>
                <WhatsNew />
              </div>
              <div>
                <Upcoming />
              </div>
              <MiniFooter />
            </Col>
          </Row>
        </Container>
      </section>
      {loading ? <Loader /> : null}
    </>
  );
}

export default JobfairApplication;
