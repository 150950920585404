/**
@copyright  :   ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
@author     :   Shiv Charan Panjeta 
 
All Rights Reserved.
Proprietary and confidential :  All information contained here in is, and remains
the property of ToXSL Technologies Pvt. Ltd. and it's partners.
Unauthorized copying of this file, via any medium is strictly prohibited.
*/

import { useEffect, useState } from 'react';
import { Button, Container } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Table from 'react-bootstrap/Table';
import ReactPaginate from 'react-paginate';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import Loader from '../../../components/commoncomponents/loader/loader-large';
import useDocumentTitle from '../../../globals/useDocumentTitle';
import { cmsUdateStatus, searchPageDatalist } from '../../../services/AuthServices';

export default function ListCMsPages() {
  useDocumentTitle('Manage Cms | AlmaBay');
  const navigate = useNavigate();
  useEffect(() => {
    apiCall();
  }, []);
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState([]);
  const [record, setRecord] = useState([]);
  const [searchedData, setSearchedData] = useState({
    id: '',
    page_title: '',
    page_slug: '',
    page_status: ''
  });
  const [currentPage, setCurrentPage] = useState(1);

  const apiCall = (page = 1, id = '', page_title = '', page_slug = '', page_status = '') => {
    setCurrentPage(page);
    let body = {
      id,
      page_title,
      page_slug,
      page_status
    };
    setLoading(true);
    searchPageDatalist(page, body).then((resp) => {
      setLoading(false);
      if (resp?.status == 200) {
        setList(resp?.data?.detail?.viewlist);
        setRecord(resp?.data?.detail?.totalRecords);
      }
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSearchedData({ ...searchedData, [name]: value });
  };

  const handleReset = (e) => {
    e.preventDefault();
    setSearchedData({
      id: '',
      page_title: '',
      page_slug: '',
      page_status: ''
    });
    apiCall();
  };

  const stateHandler = (value, id) => {
    setLoading(true);
    let status = value;
    cmsUdateStatus(id, { status }).then((res) => {
      setLoading(false);
      if (res?.status === 200) {
        document.getElementById('select').value = '';
        apiCall();
      }
    });
  };

  const selectHandler = (value, id) => {
    switch (value) {
      case 'edit':
        navigate('/admin/cms/list/create', { state: id });
        break;
      case 'view':
        navigate('/admin/cms/view', { state: id });
        break;
      case '0':
        stateHandler(value, id);
        break;
      case '1':
        stateHandler(value, id);
        break;
      case '2':
        stateHandler(value, id);
        break;
      default:
        break;
    }
  };

  const handlePageClick = (a) => {
    let currentPage = a?.selected + 1;
    apiCall(currentPage);
  };

  const searchHandler = (e) => {
    e.preventDefault();
    apiCall(
      currentPage,
      searchedData.id,
      searchedData?.page_title.trim(),
      searchedData?.page_slug.trim(),
      searchedData?.page_status
    );
  };

  return (
    <>
      <div className="dash-main-content">
        <Container fluid>
          <div className="dash-page-content">
            <div className="dash-page-title">
              <Row>
                <Col xs={12} sm={12} md={12}>
                  <div className="dash-title-bar d-flex align-items-center justify-content-between">
                    <div className="title-lft d-flex align-items-center">
                      <div className="dash-icn">
                        <i className="fas fa-newspaper"></i>
                      </div>
                      <div className="dash-title-dis">
                        <h5>
                          Cms <span>Listing</span>
                        </h5>
                        <h5>
                          Total Record(s): <span>{record}</span>
                        </h5>
                      </div>
                    </div>
                    <div className="title-rt-btn">
                      <Link className="green_button_admin" to="/admin/cms/list/create">
                        Add New Cms
                      </Link>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="dash-content-card">
              <div className="table-responsive">
                <Table className="custom-table-data" striped bordered hover>
                  <thead>
                    <tr>
                      <th>Id</th>
                      <th>Page Title</th>
                      <th>Page Slug</th>
                      <th>Page Status</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="search-table">
                      <td>
                        <Form.Control
                          type="text"
                          placeholder="Id"
                          onChange={handleChange}
                          name="id"
                          value={searchedData?.id}
                        />
                      </td>
                      <td>
                        <Form.Control
                          type="text"
                          placeholder="Page Title"
                          onChange={handleChange}
                          name="page_title"
                          value={searchedData?.page_title}
                        />
                      </td>
                      <td>
                        <Form.Control
                          type="text"
                          placeholder="Page Slug"
                          onChange={handleChange}
                          name="page_slug"
                          value={searchedData?.page_slug}
                        />
                      </td>
                      <td>
                        <Form.Select
                          aria-label="Default select example"
                          onChange={handleChange}
                          name="page_status"
                          value={searchedData?.page_status}>
                          <option value=""> - Select Status - </option>
                          <option value="0">Disable</option>
                          <option value="1">Enable</option>
                          <option value="2">Delete</option>
                        </Form.Select>
                      </td>
                      <td className="serac-btns text-center">
                        <button
                          type="button"
                          className="green_button_admin"
                          onClick={(e) => {
                            searchHandler(e);
                          }}>
                          Search
                        </button>
                        <Button className="main-btn-red ms-2" onClick={handleReset}>
                          Reset
                        </Button>
                      </td>
                    </tr>

                    {list && list?.length ? (
                      list?.map((data, index) => {
                        return (
                          <tr key={index}>
                            <td>{data?.id}</td>
                            <td>{data?.page_title}</td>
                            <td>{data?.page_slug}</td>
                            <td>
                              {data?.page_status == '0'
                                ? 'Disable'
                                : data?.page_status == '2'
                                ? 'Deleted'
                                : 'Enable'}
                            </td>
                            <td>
                              <Form.Select
                                aria-label="Default select example"
                                id="select"
                                value={''}
                                onChange={(e) => {
                                  selectHandler(e.target.value, data?.id);
                                }}>
                                <option value="">Action</option>
                                <option value="edit">Edit</option>
                                <option value="view">View</option>
                                {data?.page_status == '1' ? (
                                  <option value="0">Disable</option>
                                ) : (
                                  <option value="1">Enable</option>
                                )}
                                <option value="2">Delete</option>
                              </Form.Select>
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr className="text-center">
                        <td colSpan={9}>Data Not Found.</td>
                      </tr>
                    )}
                  </tbody>
                </Table>
                {/* pagination */}
                <div className="react-page d-flex">
                  {Math.ceil(record / 10) > 1 ? (
                    <ReactPaginate
                      previousLabel={currentPage > 0 ? '<' : ''}
                      nextLabel=">"
                      onPageChange={handlePageClick}
                      pageCount={Math.ceil(record / 10)}
                      activeClassName={'page-link active'}
                    />
                  ) : (
                    ''
                  )}
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
      {loading ? <Loader /> : ''}
    </>
  );
}
