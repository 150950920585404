//  @copyright : ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
// @author     : Shiv Charan Panjeta

// All Rights Reserved.
// Proprietary and confidential :  All information contained herein is, and remains
// the property of ToXSL Technologies Pvt. Ltd. and its partners.
// Unauthorized copying of this file, via any medium is strictly prohibited.

import { useEffect, useState } from 'react';
import { Button, Container } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import Loader from '../../components/commoncomponents/loader/loader-large';
import * as adminServices from '../../services/AuthServices';
import showNotification from '../../services/NotificationService';
import { imageExtension } from '../../globals/helper';

export default function AddClub() {
  const location = useLocation();
  const id = location.state;

  const roleType = useSelector((state) => state?.user?.details);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [insId, setInsId] = useState('');
  const [adminId, setAdminId] = useState('');
  const [adminOption, setAdminOption] = useState([]);
  const [clicked, setClicked] = useState(false);
  const [detail, setDetail] = useState({
    club_name: '',
    club_about: '',
    institute_id: '',
    club_admin: '',
    group_verified: '',
    club_privacy: '',
    cover_image: null,
    logo_img: null
  });
  const [coverPrev, setCoverPrev] = useState('');
  const [logoPrev, setLogoPrev] = useState('');

  useEffect(() => {
    getAdminList();
    if (id) {
      getDetail(id);
    }
  }, []);

  const getAdminList = () => {
    adminServices.getPageAdminListDrop().then((res) => {
      if (res?.status === 200) {
        const data = res?.data?.message?.users?.map((items) => {
          return {
            label: items?.name,
            value: items?.id
          };
        });
        setAdminOption(data);
      }
    });
  };

  const validate = () => {
    let error = {};
    if (detail?.club_name?.trim() == '') {
      error.club_name = 'Please enter club name';
    }
    if (detail?.institute_id == '' && roleType?.admin_type != 3) {
      error.institute_id = 'Please select institute';
    }
    if (detail?.club_admin == '') {
      error.club_admin = 'Please select club admin';
    }
    if (detail?.group_verified == '') {
      error.group_verified = 'Please select verify club';
    }
    if (detail?.club_privacy == '') {
      error.club_privacy = 'Please select club privacy';
    }
    if (!detail?.logo_img) {
      error.logo_img = 'Please select club image';
    }
    if (!detail?.cover_image) {
      error.cover_image = 'Please select club cover image';
    }
    return error;
  };

  const handleChange = (e) => {
    const { name, value, type } = e.target;
    if (type == 'file') {
      let file = e.target.files[0];
      if (file) {
        if (imageExtension.includes(file?.type)) {
          setDetail({
            ...detail,
            [name]: file
          });
          if (name === 'logo_img') {
            setLogoPrev(URL.createObjectURL(file));
          } else if (name === 'cover_image') {
            setCoverPrev(URL.createObjectURL(file));
          }
        } else if (!imageExtension.includes(file?.type) && file) {
          showNotification('danger', 'invalid format !');
        }
      } else {
        setLogoPrev(null);
        setCoverPrev(null);
        setDetail({
          ...detail,
          [name]: ''
        });
      }
    } else {
      setDetail({
        ...detail,
        [name]: value
      });
    }
  };

  const handleSave = (e) => {
    e.preventDefault();
    setClicked(true);
    if (!Object.keys(validate()).length) {
      setLoading(true);
      if (roleType?.institute_id) {
        detail.institute_id = roleType?.institute_id;
      }
      let formData = new FormData();
      for (let i in detail) {
        formData.append(`${i}`, detail[i]);
      }

      adminServices.addClub(formData).then((res) => {
        setLoading(false);
        if (res?.status === 200) {
          showNotification('success', res?.data?.message);
          navigate('/admin/club');
        }
      });
    }
  };

  const getDetail = () => {
    setLoading(true);
    adminServices.getClubDetail(id).then((res) => {
      setLoading(false);
      if (res?.status === 200) {
        const data = res?.data?.data?.group;
        setDetail({
          id: id,
          club_name: data?.name,
          club_about: data?.about,
          institute_id: data?.institue_id,
          group_verified: data?.verified,
          club_admin: data?.admin_ids?.map((data) => data)?.join(),
          club_privacy: data?.club_privacy,
          cover_image: data?.cover_image,
          logo_img: data?.logo
        });
        setCoverPrev(
          data?.cover_image &&
            process.env.REACT_APP_API_DOMAIN + 'common/uploads/institute_logo/' + data?.cover_image
        );
        setLogoPrev(
          data?.logo &&
            process.env.REACT_APP_API_DOMAIN + 'common/uploads/institute_logo/' + data?.logo
        );

        setInsId({
          id: data?.institue_id,
          label: data?.institute_name,
          value: data?.institute_name
        });
        if (data?.admin_ids) {
          const ids = [];
          data?.admin_ids?.map((id) => {
            ids.push(id);
          });
          getSelectedAdmin(ids);
        }
      }
    });
  };

  const getSelectedAdmin = (id) => {
    adminServices.getAdminById(id).then((res) => {
      const arr = [];
      if (res?.status === 200) {
        let data = res?.data?.data;
        data?.map((data) => {
          arr.push({ label: data?.name, value: data?.id });
        });
      }
      setAdminId(arr);
    });
  };

  const updateHandler = (e) => {
    e.preventDefault();
    setClicked(true);
    if (!Object.keys(validate(detail)).length) {
      setLoading(true);
      let formData = new FormData();
      for (let i in detail) {
        formData.append(`${i}`, detail[i]);
      }

      adminServices.editClub(formData).then((res) => {
        setLoading(false);
        if (res?.status === 200) {
          showNotification('success', res?.data?.message);
          navigate(-1);
        }
      });
    }
  };

  const loadInstituteOptions = (inputValue) => {
    if (inputValue?.length > 2) {
      return adminServices.getInstituteDrop(inputValue).then((res) => {
        if (res?.status === 200) {
          let tempArr = [];
          if (res?.data?.length) {
            const data = res?.data;
            data?.map((data) => {
              tempArr.push({
                label: data?.institutename,
                value: data?.institute_id
              });
            });
          }
          return tempArr;
        }
      });
    }
  };

  return (
    <>
      <div className="dash-main-content">
        <Container fluid>
          <div className="dash-page-content">
            <div className="dash-page-title">
              <Row>
                <Col xs={12} sm={12} md={12}>
                  <div className="dash-title-bar d-flex align-items-center justify-content-between">
                    <div className="title-lft d-flex align-items-center">
                      <div className="dash-icn">
                        <i className="fas fa-users"></i>
                      </div>
                      <div className="dash-title-dis">
                        {id ? <h5>Update Club</h5> : <h5>Add Club</h5>}
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="dash-content-card">
              <div className="cmmn-form">
                <Form>
                  <Row>
                    {roleType?.admin_type == '3' ? (
                      <></>
                    ) : (
                      <>
                        <Col xs={12} md={6} lg={12}>
                          <Form.Group className="mb-3">
                            <Form.Label>
                              Institute Name <span className="text-danger">*</span>
                            </Form.Label>
                            <AsyncSelect
                              className="basic-single"
                              classNamePrefix="select"
                              isSearchable={true}
                              name="institute_id"
                              value={insId}
                              loadOptions={loadInstituteOptions}
                              onChange={(e) => {
                                setInsId(e), setDetail({ ...detail, ['institute_id']: e.value });
                              }}
                              placeholder="Search By Institute Name"
                            />
                            <div className={'danger-frm'}>
                              {clicked && validate()?.institute_id}
                            </div>
                          </Form.Group>
                        </Col>
                      </>
                    )}

                    <Col md={12}>
                      <Form.Group className="mb-3" controlId="formBasicName">
                        <Form.Label>
                          Name <sup className="text-danger">*</sup>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Name of your Club"
                          name="club_name"
                          value={detail?.club_name}
                          onChange={handleChange}
                        />
                        <div className={'danger-frm'}>{clicked && validate()?.club_name}</div>
                      </Form.Group>
                    </Col>

                    <Col md={12}>
                      <Form.Group className="mb-3" controlId="formBasicDesi">
                        <Form.Label>About </Form.Label>
                        <Form.Control
                          as="textarea"
                          placeholder="Write about your club..."
                          rows={3}
                          value={detail?.club_about}
                          name="club_about"
                          onChange={handleChange}
                        />
                      </Form.Group>
                    </Col>

                    <Col md={12}>
                      <Form.Group className="mb-3" controlId="formBasicName">
                        <Form.Label>
                          Select Admin For Club
                          <span className="text-danger">*</span>
                        </Form.Label>
                        <Select
                          isMulti
                          isClearable={false}
                          isRtl={false}
                          isSearchable={true}
                          placeholder="Select Admins From List"
                          options={adminOption}
                          onChange={(e) => {
                            setDetail({
                              ...detail,
                              ['club_admin']: e.map((data) => data?.value).join()
                            });
                            setAdminId(e);
                          }}
                          value={adminId}
                        />
                        <div className={'danger-frm'}>{clicked && validate()?.club_admin}</div>
                      </Form.Group>
                    </Col>

                    {/* <Col md={12}>
                      <Form.Group className="mb-3" controlId="formBasicName">
                        <Form.Label>
                          BackGround Image <sup className="text-danger">*</sup>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Name of your Club"
                          name="club_name"
                          value={detail?.club_name}
                          onChange={handleChange}
                        />
                        <div className={'danger-frm'}>{clicked && validate()?.club_name}</div>
                      </Form.Group>
                    </Col> */}

                    <Col md={12}>
                      <Form.Group className="mb-3" controlId="formBasicName">
                        <Form.Label>
                          Verify Club <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Select
                          aria-label="Default select example"
                          name="group_verified"
                          value={detail?.group_verified}
                          onChange={handleChange}>
                          <option>Select</option>
                          <option value="1">Official</option>
                          <option value="0">Non-Official</option>
                        </Form.Select>
                        <div className={'danger-frm'}>{clicked && validate()?.group_verified}</div>
                      </Form.Group>
                    </Col>

                    <Col md={12}>
                      <Form.Group className="mb-3" controlId="formBasicName">
                        <Form.Label>
                          Logo<span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          type="file"
                          accept=".jpg,.jpeg,.png"
                          name="logo_img"
                          onChange={handleChange}
                        />
                        <div className={'danger-frm'}>{clicked && validate()?.logo_img}</div>
                      </Form.Group>
                    </Col>
                    {logoPrev && (
                      <div className="d-flex flex-wrap align-item-center">
                        <div className="border me-2">
                          <img width="100px" height="100px" src={logoPrev} alt="img1" />
                        </div>
                      </div>
                    )}

                    <Col md={12}>
                      <Form.Group className="mb-3" controlId="formBasicName">
                        <Form.Label>
                          Cover Image<span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          type="file"
                          accept=".jpg,.jpeg,.png"
                          name="cover_image"
                          onChange={handleChange}
                        />
                        <div className={'danger-frm'}>{clicked && validate()?.cover_image}</div>
                      </Form.Group>
                    </Col>
                    {coverPrev && (
                      <div className="d-flex flex-wrap align-item-center">
                        <div className="border me-2">
                          <img width="100px" height="100px" src={coverPrev} alt="img1" />
                        </div>
                      </div>
                    )}

                    <Col md={12}>
                      <Form.Label>
                        Privacy <span className="text-danger">*</span>
                        <div className={'danger-frm'}>{clicked && validate()?.club_privacy}</div>
                      </Form.Label>
                      <div>
                        {['radio'].map((type) => (
                          <div key={`inline-${type}`} className="privcacy-radio mb-3">
                            <div>
                              <Form.Check
                                label="Open Club"
                                name="club_privacy"
                                value="open"
                                type={type}
                                checked={detail?.club_privacy == 'open'}
                                onChange={(e) => {
                                  handleChange(e);
                                }}
                                id={`inline-${type}-1`}
                              />
                              <span className="radio-span">Anyone can see and join the Club</span>
                            </div>
                            <div>
                              <Form.Check
                                label="Closed Club"
                                name="club_privacy"
                                type={type}
                                value="closed"
                                checked={detail?.club_privacy == 'closed'}
                                // onChange={(e) => setPrivacy(e.target.value)}
                                onChange={(e) => {
                                  handleChange(e);
                                }}
                                id={`inline-${type}-2`}
                              />
                              <span className="radio-span">
                                Anyone can see and request to join the club. Requests can be
                                accepted or declined by admins
                              </span>
                            </div>
                            <div>
                              <Form.Check
                                label="Secret Club"
                                name="club_privacy"
                                checked={detail?.club_privacy == 'secret'}
                                type={type}
                                value="secret"
                                // onChange={(e) => setPrivacy(e.target.value)}
                                onChange={(e) => {
                                  handleChange(e);
                                }}
                                id={`inline-${type}-3`}
                              />
                              <span className="radio-span">Only members can access the club</span>
                            </div>
                          </div>
                        ))}
                      </div>
                    </Col>
                  </Row>
                  <div className="form-btns d-flex align-items-center mt-3">
                    {id ? (
                      <button
                        type="button"
                        className="green_button_admin"
                        onClick={(e) => {
                          updateHandler(e);
                        }}>
                        Update
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="green_button_admin"
                        onClick={(e) => {
                          handleSave(e);
                        }}>
                        Save
                      </button>
                    )}
                    <strong>OR</strong>
                    <Button
                      className="main-btn-red"
                      onClick={() => {
                        navigate(-1);
                      }}>
                      Cancel
                    </Button>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </Container>
      </div>
      {loading ? <Loader /> : ''}
    </>
  );
}
