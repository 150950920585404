import * as moment from 'moment';
import { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import AsyncSelect from 'react-select/async';
import defaultEvent from '../../../../assets/images/event.png';
import useDocumentTitle from '../../../../globals/useDocumentTitle';
import { getLocationDropDown } from '../../../../services/AuthServices';
import { getEventCategoryDrop, getEvents } from '../../../../services/SocialServices';
import HeaderLogin from '../../../commoncomponents/HeaderLogin';
import NoDataFound from '../../../commoncomponents/NoDataFound';
import SocialShare from '../SocialShare';
import TopTabbings from '../TopTabbings';
import CreateEventModal from './CreateEventModal';

function EventsPage() {
  /* eslint-disable */
  useDocumentTitle('List of Events | AlmaBay');

  const [isLoading, setIsLoading] = useState(false);
  const [inputMsg, setInputMsg] = useState('Please enter atleast 2 characters');
  const [shareModelShow, setShareModelShow] = useState(false);
  const [socialShareData, setSocialShareData] = useState('');
  const isloggedIn = useSelector((state) => state?.user?.isloggedIn);
  const shareModelClose = () => {
    setShareModelShow(false);
  };

  const [show3, setShow3] = useState(false);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [instituteSearch, setInstituteSearch] = useState(null);
  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);
  const navigate = useNavigate();
  const [activeClassState, setIsActiveClassState] = useState('lst-view');
  const [eventType, setEventType] = useState('all');
  const [eventData, setEventData] = useState([]);
  const [category, setJobCategory] = useState([]);
  const [selectedCat, setSelectedCat] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchFilter, setSearchFilter] = useState({
    startDate: '',
    endDate: '',
    name: '',
    venue: '',
    city: ''
  });
  const { startDate, endDate, name, venue } = searchFilter;
  useEffect(() => {
    getEventList('');
    loadEventCategoryOptions();
    loadCityOpt();
  }, []);

  const getEventList = (type) => {
    setIsLoading(true);

    var type = type ? type : eventType;
    var city = searchFilter?.city?.id ? searchFilter?.city?.id : '';
    var category = selectedCat.toString() ? selectedCat.toString() : '';

    let paramsData = `search=${name}
      &event-type=${type}&from_event_date=${startDate}&to_event_date=${endDate}&event-name=${name}&event-venue=${venue}&city=${city}&categories=${category}`;

    getEvents(currentPage, encodeURIComponent(paramsData)).then((res) => {
      if (res?.status === 200) {
        setIsLoading(false);
        setEventData(res?.data?.data);
      }
    });
  };

  const loadCityOpt = (inputValue) => {
    if (inputValue?.length >= 2) {
      return getLocationDropDown(inputValue).then((res) => {
        if (res?.status === 200) {
          let tempArr = [];
          if (res?.data?.data) {
            const data = res?.data?.data;
            data?.map((data) => {
              tempArr.push({
                id: data?.id,
                label: data?.location,
                value: data?.location
              });
            });
          }
          if (res?.data?.data == '') {
            setInputMsg('No matches found');
          }
          return tempArr;
        }
      });
    }
  };

  const loadEventCategoryOptions = (inputValue) => {
    return getEventCategoryDrop(inputValue).then((res) => {
      if (res?.status === 200) {
        let tempArr = [];
        if (res?.data?.results) {
          const data = res?.data?.results;
          data?.map((data, index) => {
            tempArr.push({
              id: data?.id,
              label: data?.name,
              value: data?.name
            });
          });
        }
        setJobCategory(tempArr);
      }
    });
  };

  const handleFilterChange = (e, defaultName) => {
    if (defaultName == 'city') {
      setSearchFilter({ ...searchFilter, [defaultName]: e });
    } else {
      const { name, value } = e.target;
      setSearchFilter({ ...searchFilter, [name]: value });
    }
  };
  const handleSetCheckedCategory = (id) => {
    if (selectedCat?.includes(id)) {
      let filterSkills = selectedCat.filter((data) => data !== id);
      setSelectedCat(filterSkills);
    } else {
      setSelectedCat([...selectedCat, id]);
    }
  };

  const handleResetFilter = () => {
    setEventType('all');
    setSearchFilter({ startDate: '', endDate: '', name: '', venue: '', city: '' });
    setSelectedCat([]);
    getEvents().then((res) => {
      if (res?.status === 200) {
        setEventData(res?.data?.data);
      }
    });
  };

  return (
    <>
      {isloggedIn ? (
        <>
          <HeaderLogin />

          <div className="header-lnk-feeds">
            <div className="container">
              <TopTabbings state={'social'} />
            </div>
          </div>
          <section className="events-top-hd text-end bg-white py-2">
            <Container>
              <Button
                className="all-evnt-btn blu-btn"
                onClick={() => {
                  setEventType('all');
                  getEventList('all');
                }}>
                All Events
              </Button>
              <Button
                className="all-evnt-btn org-btn"
                onClick={() => {
                  setEventType('hosted');
                  getEventList('hosted');
                }}>
                My Events
              </Button>
              <Button className="all-evnt-btn green-btn" onClick={handleShow2}>
                + Create Event
              </Button>
            </Container>
          </section>
        </>
      ) : (
        ''
      )}

      <section className="news-feed-sec bg-grey py-4">
        <Container>
          <Row>
            <Col xs={12} lg={3} md={12}>
              <div className="fiter-card d-none d-lg-block">
                <div className="filter-hd-card d-flex align-items-center justify-content-between">
                  <div className="filter-hd">
                    <h4>Filter Results</h4>
                  </div>
                  <div className="filter-btn">
                    <Link to="#" onClick={() => getEventList('')}>
                      <i className="fas fa-filter"></i>
                    </Link>
                    <Link to="#" onClick={handleResetFilter}>
                      <i className="fas fa-sync-alt"></i>
                    </Link>
                  </div>
                </div>
                <div className="filter-select-lst">
                  <Accordion defaultActiveKey={['0', '1', '6', '2', '3', '4']} alwaysOpen flush>
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>Event Category</Accordion.Header>
                      <Accordion.Body>
                        <Form.Group className="search-card" controlId="formBasicEmail">
                          <Form.Control
                            type="search"
                            placeholder="Search By Event Category"
                            onChange={(e) => loadEventCategoryOptions(e.target.value)}
                          />
                          <Button className="search-btn">
                            <i className="fas fa-search"></i>
                          </Button>
                        </Form.Group>
                        <div className="event-cat-scroll">
                          {category?.map((data, index) => {
                            return (
                              <div key={index}>
                                <input
                                  checked={selectedCat?.includes(data?.id)}
                                  type="checkbox"
                                  onChange={() => {
                                    handleSetCheckedCategory(data?.id);
                                  }}
                                />
                                {data?.label}
                              </div>
                            );
                          })}
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                    {isloggedIn && (
                      <Accordion.Item eventKey="1">
                        <Accordion.Header>Event Type</Accordion.Header>
                        <Accordion.Body>
                          {['radio'].map((type) => (
                            <div key={`inline-${type}`}>
                              <Form.Check
                                label="All Events"
                                name="group1"
                                type={type}
                                checked={eventType == 'all' ? true : false}
                                id={`inline-${type}-1`}
                                className="mb-1"
                                onChange={() => setEventType('all')}
                              />
                              <Form.Check
                                label="My Events"
                                name="group1"
                                type={type}
                                checked={eventType == 'hosted' ? true : false}
                                id={`inline-${type}-2`}
                                className="mb-1"
                                onChange={() => setEventType('hosted')}
                              />
                              <Form.Check
                                label="Institute Events"
                                name="group1"
                                type={type}
                                id={`inline-${type}-3`}
                                className="mb-1"
                                onChange={() => setEventType('institute')}
                              />
                              <Form.Check
                                label="Going Events"
                                name="group1"
                                type={type}
                                id={`inline-${type}-4`}
                                className="mb-1"
                                onChange={() => setEventType('going')}
                              />
                              <Form.Check
                                label="Interested Events"
                                name="group1"
                                type={type}
                                id={`inline-${type}-5`}
                                className="mb-1"
                                onChange={() => setEventType('interested')}
                              />
                              <Form.Check
                                label="Invited Events"
                                name="group1"
                                type={type}
                                id={`inline-${type}-6`}
                                className="mb-1"
                                onChange={() => setEventType('invitations')}
                              />
                            </div>
                          ))}
                        </Accordion.Body>
                      </Accordion.Item>
                    )}

                    <Accordion.Item eventKey="6">
                      <Accordion.Header>Event Date</Accordion.Header>
                      <Accordion.Body>
                        <div className="d-flex align-items-center">
                          <Form.Control
                            type="date"
                            name="startDate"
                            onChange={handleFilterChange}
                            value={searchFilter?.startDate}
                          />
                          <div className="date-seprator">-</div>
                          <Form.Control
                            type="date"
                            name="endDate"
                            onChange={handleFilterChange}
                            value={searchFilter?.endDate}
                            min={searchFilter?.startDate}
                          />
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                      <Accordion.Header>Event Name</Accordion.Header>
                      <Accordion.Body>
                        <div className="position-relative">
                          <Form.Control
                            type="search"
                            placeholder="Search By Name"
                            name="name"
                            onChange={handleFilterChange}
                            value={searchFilter?.name}
                          />
                          <span className="search">
                            <i className="fas fa-search"></i>
                          </span>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="3">
                      <Accordion.Header>Event Venue</Accordion.Header>
                      <Accordion.Body>
                        <div className="position-relative">
                          <Form.Control
                            type="search"
                            placeholder="Search By Venue"
                            name="venue"
                            value={searchFilter?.venue}
                            onChange={handleFilterChange}
                          />
                          <span className="search">
                            <i className="fas fa-search"></i>
                          </span>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="4">
                      <Accordion.Header>Event City</Accordion.Header>
                      <Accordion.Body>
                        <AsyncSelect
                          isClearable
                          value={searchFilter?.city}
                          cacheOptions
                          getOptionLabel={(e) => e.label}
                          getOptionValue={(e) => e.value}
                          loadOptions={loadCityOpt}
                          className="basic-single mt-1"
                          onMenuOpen={() => setInputMsg('Please enter atleast 2 characters.')}
                          placeholder="Select Event City"
                          onChange={(e) => handleFilterChange(e, 'city')}
                          components={{
                            DropdownIndicator: () => null,
                            IndicatorSeparator: () => null,
                            LoadingIndicator: () => null
                          }}
                          onInputChange={(newValue) => {
                            if (newValue?.length <= 2) {
                              setInputMsg('Please enter atleast 2 characters.');
                            }
                          }}
                          noOptionsMessage={() => inputMsg}
                          styles={{
                            control: (base) => ({
                              ...base,
                              border: '0 !important',
                              // This line disable the  border
                              boxShadow: '0 !important',
                              '&:hover': {
                                border: '0 !important'
                              }
                            })
                          }}
                        />
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                  <div className="sider-bar-filter p-3 text-end">
                    <button className="accnt-btn" type="button" onClick={() => getEventList('')}>
                      Search
                    </button>
                    <button className="dark-blu-btn ms-2" type="button" onClick={handleResetFilter}>
                      Reset
                    </button>
                  </div>
                </div>
              </div>

              <Button
                className="mobile-filter-btn d-block d-lg-none "
                variant="primary"
                onClick={handleShow3}>
                <i className="fas fa-filter"></i>
              </Button>

              <Offcanvas show={show3} onHide={handleClose3} className="mobile-filter">
                <Offcanvas.Body>
                  <div className="fiter-card ">
                    <div className="filter-hd-card d-flex align-items-center justify-content-between">
                      <div className="filter-hd">
                        <h4>Filter By</h4>
                      </div>
                      <div className="filter-btn">
                        <Link to="#">
                          <i className="fas fa-filter"></i>
                        </Link>
                        <Link to="#">
                          <i className="fas fa-sync-alt"></i>
                        </Link>
                      </div>
                    </div>
                    <div className="filter-select-lst">
                      <Accordion defaultActiveKey={['0', '1', '6', '2', '3', '4']} alwaysOpen flush>
                        <Accordion.Item eventKey="0">
                          <Accordion.Header>Event Category</Accordion.Header>
                          <Accordion.Body>
                            <Form.Group className="search-card" controlId="formBasicEmail">
                              <Form.Control
                                type="search"
                                placeholder="Search By Category"
                                onChange={(e) => loadEventCategoryOptions(e.target.value)}
                              />
                              <Button className="search-btn">
                                <i className="fas fa-search"></i>
                              </Button>
                            </Form.Group>
                            <div className="event-cat-scroll">
                              {category?.map((data, index) => {
                                return (
                                  <div key={index}>
                                    <input
                                      checked={selectedCat?.includes(data?.id)}
                                      type="checkbox"
                                      onChange={() => {
                                        handleSetCheckedCategory(data?.id);
                                      }}
                                    />
                                    {data?.label}
                                  </div>
                                );
                              })}
                            </div>
                          </Accordion.Body>
                        </Accordion.Item>
                        {isloggedIn ? (
                          <Accordion.Item eventKey="1">
                            <Accordion.Header>Event Type</Accordion.Header>
                            <Accordion.Body>
                              {['radio'].map((type) => (
                                <div key={`inline-${type}`}>
                                  <Form.Check
                                    label="All Events"
                                    name="group1"
                                    type={type}
                                    checked={eventType == 'all' ? true : false}
                                    id={`inline-${type}-1`}
                                    className="mb-1"
                                    onChange={() => setEventType('all')}
                                  />
                                  <Form.Check
                                    label="My Events"
                                    name="group1"
                                    type={type}
                                    checked={eventType == 'hosted' ? true : false}
                                    id={`inline-${type}-2`}
                                    className="mb-1"
                                    onChange={() => setEventType('hosted')}
                                  />
                                  <Form.Check
                                    label="Institute Events"
                                    name="group1"
                                    type={type}
                                    id={`inline-${type}-3`}
                                    className="mb-1"
                                    onChange={() => setEventType('institute')}
                                  />
                                  <Form.Check
                                    label="Going Events"
                                    name="group1"
                                    type={type}
                                    id={`inline-${type}-4`}
                                    className="mb-1"
                                    onChange={() => setEventType('going')}
                                  />
                                  <Form.Check
                                    label="Interested Events"
                                    name="group1"
                                    type={type}
                                    id={`inline-${type}-5`}
                                    className="mb-1"
                                    onChange={() => setEventType('interested')}
                                  />
                                  <Form.Check
                                    label="Invited Events"
                                    name="group1"
                                    type={type}
                                    id={`inline-${type}-6`}
                                    className="mb-1"
                                    onChange={() => setEventType('invitations')}
                                  />
                                </div>
                              ))}
                            </Accordion.Body>
                          </Accordion.Item>
                        ) : (
                          ''
                        )}

                        <Accordion.Item eventKey="6">
                          <Accordion.Header>Event Date</Accordion.Header>
                          <Accordion.Body>
                            <div className="d-flex align-items-center">
                              <Form.Control
                                type="date"
                                name="startDate"
                                onChange={handleFilterChange}
                                value={searchFilter?.startDate}
                              />
                              <div className="date-seprator">-</div>
                              <Form.Control
                                type="date"
                                name="endDate"
                                onChange={handleFilterChange}
                                value={searchFilter?.endDate}
                                min={searchFilter?.startDate}
                              />
                            </div>
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="2">
                          <Accordion.Header>Event Name</Accordion.Header>
                          <Accordion.Body>
                            <Form.Control
                              type="search"
                              placeholder="Search By Name"
                              name="name"
                              onChange={handleFilterChange}
                              value={searchFilter?.name}
                            />
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="3">
                          <Accordion.Header>Event Venue</Accordion.Header>
                          <Accordion.Body>
                            <Form.Control
                              type="search"
                              placeholder="Search By Venue"
                              name="venue"
                              value={searchFilter?.venue}
                              onChange={handleFilterChange}
                            />
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="4">
                          <Accordion.Header>Event City</Accordion.Header>
                          <Accordion.Body>
                            <AsyncSelect
                              isClearable
                              value={searchFilter?.city}
                              cacheOptions
                              defaultOptions
                              getOptionLabel={(e) => e.label}
                              getOptionValue={(e) => e.value}
                              loadOptions={loadCityOpt}
                              className="basic-single mt-1"
                              placeholder="Select Event City"
                              onChange={(e) => handleFilterChange(e, 'city')}
                            />
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                      <div className="sider-bar-filter p-3 text-end">
                        <button
                          className="accnt-btn"
                          type="button"
                          onClick={() => getEventList('')}>
                          Search
                        </button>
                        <button
                          className="dark-blu-btn ms-2"
                          type="button"
                          onClick={handleResetFilter}>
                          Reset
                        </button>
                      </div>
                    </div>
                  </div>
                </Offcanvas.Body>
              </Offcanvas>
            </Col>
            <Col xs={12} lg={9} md={12}>
              <div className="events_listing p-3">
                <Row className="border-bottom pb-2 mb-4">
                  <Col md={6} xs={6}>
                    <h4 className="heading">Upcoming Events</h4>
                  </Col>
                  <Col md={6} xs={6}>
                    <ul className="evnet-top-btn d-flex justify-content-end">
                      <li className="me-3">
                        <Link
                          title="Grid View"
                          to="#"
                          className={activeClassState == 'lst-view' ? 'active' : ''}
                          onClick={(e) => {
                            e?.preventDefault();
                            let element = document.getElementById('lst-id');
                            element.classList.remove('list-blk-card');
                            setIsActiveClassState('lst-view');
                          }}>
                          {/* <img src={require('../../../../assets/images/thumb.png')} alt="img" /> */}

                          <i className="fas fa-th-large"></i>
                        </Link>
                      </li>
                      <li className="">
                        <Link
                          title="List View"
                          className={activeClassState == 'list-blk-card' ? 'active' : ''}
                          onClick={(e) => {
                            e?.preventDefault();
                            let element = document.getElementById('lst-id');
                            element.classList.add('list-blk-card');
                            setIsActiveClassState('list-blk-card');
                          }}>
                          {/* <img src={require('../../../../assets/images/shorting.png')} alt="img" /> */}
                          <i className="fas fa-list"></i>
                        </Link>
                      </li>
                    </ul>
                  </Col>
                </Row>
                <div className="events-page-list">
                  <Row className="lst-view" id="lst-id">
                    {eventData?.length > 0 ? (
                      <>
                        {eventData &&
                          eventData?.map((item, index) => {
                            return (
                              <Col lg={4} md={6} key={index}>
                                <div className="event-card">
                                  <Link
                                    target="_blank"
                                    to={
                                      isloggedIn
                                        ? `/user/events-detail/${item?.id}/overview`
                                        : `/events-detail/${item?.id}/overview`
                                    }>
                                    <div
                                      className="event-card-img"
                                      // onClick={(e) => handleNavigate(e, item?.id)}
                                    >
                                      <img
                                        src={
                                          item?.url
                                            ? process.env.REACT_APP_API_URL +
                                              item?.url +
                                              '.' +
                                              item?.extension
                                            : defaultEvent
                                        }
                                        alt="img"
                                      />
                                    </div>
                                  </Link>
                                  <div className="event-page-dis text-start">
                                    <Link
                                      to={
                                        isloggedIn
                                          ? `/user/events-detail/${item?.id}/overview`
                                          : `/events-detail/${item?.id}/overview`
                                      }>
                                      <h4
                                        // onClick={(e) => handleNavigate(e, item?.id)}
                                        title={item?.name}>
                                        {item?.name}
                                      </h4>
                                    </Link>

                                    {/* <h6>{item?.category_name}</h6> */}
                                    <h6 title={item.city_name}>{item.city_name}</h6>

                                    <div className="d-flex justify-content-between">
                                      <p>
                                        <i className="fas fa-calendar-alt me-1"></i>
                                        {moment(item?.from_event_date).format('Do MMMM YYYY')}
                                        {item?.to_event_date == '0000-00-00 00:00:00'
                                          ? ''
                                          : '-' +
                                            moment(item?.to_event_date).format('Do MMMM YYYY')}
                                      </p>
                                      <Link
                                        className="share-btn ms-3"
                                        // onClick={handleShow}
                                        onClick={() => {
                                          setShareModelShow(true);
                                          setSocialShareData(item);
                                        }}>
                                        <i className="fas fa-share-alt" title="Share Event"></i>
                                      </Link>
                                    </div>
                                  </div>
                                </div>
                              </Col>
                            );
                          })}
                      </>
                    ) : (
                      <NoDataFound text="No events found at the moment" />
                    )}
                  </Row>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <Modal show={shareModelShow} onHide={shareModelClose} contentClassName="share-model-events">
        <Modal.Header closeButton>
          <Modal.Title>share to</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <SocialShare
            facebookOptions={{
              url: process.env.REACT_APP_DOMAIN + 'user/events' + '/' + socialShareData.id,
              quote: socialShareData?.job_title
            }}
            twitterOptions={{
              url: process.env.REACT_APP_DOMAIN + 'user/events' + '/' + socialShareData.id,
              title: socialShareData?.job_title,
              hashtags: ['events', 'trends']
            }}
            linkedInOptions={{
              title: socialShareData?.job_title,
              summary: socialShareData?.job_info,
              url: process.env.REACT_APP_DOMAIN + 'user/events' + '/' + socialShareData.id,
              source: process.env.REACT_APP_DOMAIN + 'user/events' + '/' + socialShareData.id
            }}
            eventmodule={'eventModule'}
          />
        </Modal.Body>
      </Modal>

      {/* add event modal */}
      <CreateEventModal
        handleClose2={handleClose2}
        show={show}
        show2={show2}
        renderFunction={getEventList}
        handleClose={handleClose}
      />
    </>
  );
}

export default EventsPage;
